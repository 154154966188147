import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import TaskTypeSubtypeEdit from './edit';

const TaskTypeSubtypeIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('task_service/codebooks/subtype');

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const columns = [
        {
            title: geti18nText('task.typeSubtype.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('task.typeSubtype.table.column.code'),
            dataIndex: 'code',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('task.typeSubtype.table.column.name'),
            dataIndex: 'name',
            width: '30%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('task.typeSubtype.table.column.description'),
            dataIndex: 'description',
            width: '50%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record.description}>
                            <div className={'overview-cards-paragraph-div'}>
                                <p className={'overview-cards-paragraph'}>{record.description}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('task.typeSubtype.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="tasks-task-type-subcategory-table"
            url={CONSTANTS_REQ.TASK_TYPE_SUBTYPE.LIST}
            addNewButtonText={geti18nText('task.typeSubtype.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={TaskTypeSubtypeEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            nyTestId="task-type-category-data-table"
            selectOnSave={true}
            headerTitle={geti18nText('menu.task.service.subtype')}
        />
    );
};

export default TaskTypeSubtypeIndex;
