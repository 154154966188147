import { CheckOutlined, CloseOutlined, DeleteOutlined, HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Tabs } from 'antd';
import React, { ReactText, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TaskTemplateIndex from '.';
import AdditionalInfo from '../../../../components/additional-info';
import NyHistory from '../../../../components/ny-history/NyHistory';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getEmployeeSelectOption,
    getEnumArray,
    getEnumBooleanArray,
    getSearchFormat,
    okNotification,
    setSearchFormat,
} from '../../../../utils/Utils';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import TaskTypeCategorySearch from '../task-type-category/search';
import TaskTypeSubtypeIndex from '../task-type-subtype';
import TeamEdit from '../team/edit';
import TeamSearch from '../team/search';
import UserIndex from '../user';
import ObjectMetadataEdit from './MetadataTemplateEdit';
import TaskTemplateApprovalIndex from './approval';
import TaskTypeSubtypeSearch from '../task-type-subtype/search';

const TaskTemplateEdit = (props: any) => {
    const table = useTableSettings();
    const enTaskType = useEnum('TASK_TYPE');
    const enMetadataType = useEnum('METADATA_TYPE');

    const [editHeader, setEditHeader] = useState(geti18nText('task.template.edit.new'));
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [forAssignedAsset, setForAssignedAsset] = useState<boolean>(false);
    const [dataForm, setDataForm] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);
    const [fileList, setFileList] = useState<any>([]);
    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const { TextArea } = Input;
    const focusInput = useRef<any>(null);
    const history = useHistory();
    const [activeKey, setActiveKey] = useState('1');
    const [isCreate, setIsCreate] = useState(true);
    const [allEmployees, setAllEmployees] = useState<boolean>(true);
    const [enableEvaluation, setEnableEvaluation] = useState<boolean>(false);
    const [directSolvingAllowed, setDirectSolvingAllowed] = useState<boolean>(false);
    const [requiredForClosure, setRequiredForClosure] = useState<boolean>(false);
    const [taskType, setTaskType] = useState<any>(
        props?.editProps?.taskType ? props.editProps.taskType : enTaskType.SERVICE
    );
    const [refreshTable, setRefreshTable] = useState(1);

    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [selectedValuesAddGroup, setSelectedValuesAddGroup] = useState<any>([]);
    const [hasSelectedAddGroup, setHasSelectedAddGroup] = useState(false);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);

    const [selectedValuesDeleteTeam, setSelectedValuesDeleteTeam] = useState<any>([]);
    const [hasSelectedDeleteTeam, setHasSelectedDeleteTeam] = useState(false);
    const [selectedValuesAddTeam, setSelectedValuesAddTeam] = useState<any>([]);
    const [hasSelectedAddTeam, setHasSelectedAddTeam] = useState(false);
    const [isAddListModalVisibleTeam, setIsAddListModalVisibleTeam] = useState(false);
    const [loadingModalTeam, setLoadingModalTeam] = useState(false);
    const [fetchWhenChange, setFetchWhenChange] = useState<any>(0);
    const [data, setData] = useState<any>(0);

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const hideButtons = () => {
        return !canCreate() || Object.values(['2', '3', '4', '5']).includes(activeKey);
    };

    const getTaskType = () => {
        return props?.editProps?.taskType ? props.editProps.taskType : enTaskType.SERVICE;
    };

    const getParentId = () => {
        return props?.editProps?.taskTemplateId ? props.editProps.taskTemplateId : null;
    };

    const onModalClose = () => {
        form.resetFields();
        setShow(false);
        setFileList([]);
        setActiveKey('1');
        setForAssignedAsset(false);
        setIsCreate(false);
        setDataForm(null);
        setCreatedBy(null);
        setCreatedDate(null);
        setUpdatedDate(null);
        setUpdatedBy(null);
        setEditHeader(geti18nText('task.template.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (id == undefined) {
            setTimeout(() => {
                setShow(true);
            }, 50);
        }
    };

    const defaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'metadataType', condition: 'equals', value: enMetadataType.TASK_TYPE },
        ];
    };

    const activeFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.created) {
            setCreatedDate(dataForm.created);
        }

        if (dataForm.createdBy) {
            setCreatedBy(dataForm.createdBy);
        }

        if (dataForm.updated) {
            setUpdatedDate(dataForm.updated);
        }

        if (dataForm.updatedBy) {
            setUpdatedBy(dataForm.updatedBy);
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('task.template.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.taskTypeCategory && dataForm.taskTypeCategory.name != null) {
            dataForm.taskTypeCategory = setSearchFormat(dataForm.taskTypeCategory, 'name', 'name');
        }

        if (dataForm.taskTypeSubtype && dataForm.taskTypeSubtype.name != null) {
            dataForm.taskTypeSubtype = setSearchFormat(dataForm.taskTypeSubtype, 'name', 'name');
        }

        if (dataForm.assignedTeam && dataForm.assignedTeam.name != null) {
            dataForm.assignedTeam = setSearchFormat(dataForm.assignedTeam, 'name', 'name');
        }

        if (dataForm.escalateTeam && dataForm.escalateTeam.name != null) {
            dataForm.escalateTeam = setSearchFormat(dataForm.escalateTeam, 'name', 'name');
        }

        if (dataForm.assignedUser && dataForm.assignedUser.person != null) {
            dataForm.assignedUser = getEmployeeSelectOption(dataForm.assignedUser);
        }

        if (dataForm.escalateUser && dataForm.escalateUser != null) {
            let value: any = getEmployeeSelectOption(dataForm.escalateUser);
            if (value?.text?.props?.children[0].props?.children != 'undefined undefined') {
                dataForm.escalateUser = getEmployeeSelectOption(dataForm.escalateUser);
            }
        }

        if (dataForm.forAssignedAsset) {
            setForAssignedAsset(dataForm.forAssignedAsset);
        }

        if (dataForm.enableEvaluation) {
            setEnableEvaluation(dataForm.enableEvaluation);
        }

        if (dataForm.directSolvingAllowed) {
            setDirectSolvingAllowed(dataForm.directSolvingAllowed);
        }

        if (dataForm.requiredForClosure) {
            setRequiredForClosure(dataForm.requiredForClosure);
        }

        setAllEmployees(dataForm.allEmployees);

        if (dataForm.fileId) {
            let filesImage = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: dataForm.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(filesImage);
        }

        if (dataForm.type) {
            setTaskType(dataForm.type);
        }

        setTimeout(() => {
            setShow(true);
        }, 100);

        setData(dataForm);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    let clearKeysDeleteGroup: any;

    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    let clearKeysAddGroup: any;

    const setSelectedValuesFuncAddGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddGroup(hasSelected);
        setSelectedValuesAddGroup(selectedRowKeys);
        clearKeysAddGroup = clearSelectedRowKeys;
    };

    const saveToGroup = (e: any) => {
        e.preventDefault();
        setLoadingModalGroup(true);

        NyRequestResolver.requestPost(
            CONSTANTS_REQ.TASK_TEMPLATE.METADATA_ADD.replace('{taskTemplateId}', form.getFieldValue('id')),
            undefined,
            {
                taskTemplateId: form.getFieldValue('id'),
                objectMetadataIds: selectedValuesAddGroup,
            }
        ).then((result: any) => {
            if (setLoadingModalGroup) setLoadingModalGroup(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddGroup) {
                    clearKeysAddGroup();
                }

                okNotification();
                setIsAddListModalVisibleGroup(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromGroup = () => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.objectMetadataId;
        });

        NyRequestResolver.requestPost(
            CONSTANTS_REQ.TASK_TEMPLATE.METADATA_DELETE.replace('{taskTemplateId}', form.getFieldValue('id')),
            undefined,
            {
                taskTemplateId: form.getFieldValue('id'),
                objectMetadataIds: res,
            }
        ).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    // Teams start

    let clearKeysDeleteTeam: any;

    const setSelectedValuesFuncDeleteTeam = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteTeam(hasSelected);
        setSelectedValuesDeleteTeam(selectedRows);
        clearKeysDeleteTeam = clearSelectedRowKeys;
    };

    let clearKeysAddTeam: any;

    const setSelectedValuesFuncAddTeam = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddTeam(hasSelected);
        setSelectedValuesAddTeam(selectedRowKeys);
        clearKeysAddTeam = clearSelectedRowKeys;
    };

    const saveToTeam = (e: any) => {
        e.preventDefault();
        setLoadingModalTeam(true);

        NyRequestResolver.requestPost(
            CONSTANTS_REQ.TASK_TEMPLATE.TEAMS_ADD.replace('{taskTemplateId}', form.getFieldValue('id')),
            undefined,
            {
                taskTemplateId: form.getFieldValue('id'),
                teamIds: selectedValuesAddTeam,
            }
        ).then((result: any) => {
            if (setLoadingModalTeam) setLoadingModalTeam(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddTeam) {
                    clearKeysAddTeam();
                }

                okNotification();
                setIsAddListModalVisibleTeam(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromTeam = () => {
        const res = selectedValuesDeleteTeam.map((value: any) => {
            return value?.team?.id;
        });

        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TASK_TEMPLATE.TEAMS_REMOVE.replace('{taskTemplateId}', form.getFieldValue('id')),
            undefined,
            {
                taskTemplateId: form.getFieldValue('id'),
                teamIds: res,
            }
        ).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteTeam) {
                    clearKeysDeleteTeam();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    // Teams end

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '1' && (
                <div style={{ float: 'left', display: 'inline-flex' }}>
                    <AdditionalInfo
                        created={createdDate}
                        createdBy={createdBy}
                        updated={updatedDate}
                        updatedBy={updatedBy}
                    />
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={<HistoryOutlined />}
                        onClick={() => setHistoryModalVisible(true)}
                    >
                        {geti18nText('app.default.change.history')}
                    </Button>
                </div>
            )}
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('meal.edit.add.meal.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('task.template.edit.tab.remove.from.group')}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('meal.edit.remove.meal.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
            {activeKey === '3' && !allEmployees && !isCreate && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setIsAddListModalVisibleTeam(true);
                        }}
                    >
                        {geti18nText('meal.edit.add.meal.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('task.template.edit.tab.remove.from.team')}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        disabled={!hasSelectedDeleteTeam}
                        onConfirm={removeFromTeam}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteTeam}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('meal.edit.remove.meal.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    const refreshView = () => {
        if (props && props.editProps && props.editProps.setRefresh) {
            props.editProps.setRefresh((refresh: any) => refresh + 1);
        }
    };

    const normalize = (values: any) => {
        values.type = getTaskType();
        values.parent = getParentId();
        values.dependencies = [];
        values.forAssignedAsset = forAssignedAsset;
        values.enableEvaluation = enableEvaluation;
        values.directSolvingAllowed = directSolvingAllowed;
        values.requiredForClosure = requiredForClosure;
        values.allEmployees = allEmployees;
        if (values.taskTypeCategory) {
            if (values?.taskTypeCategory?.id < 0) {
                delete values.taskTypeCategory;
            } else {
                values.taskTypeCategory = getSearchFormat(values.taskTypeCategory);
            }
        }
        if (values.taskTypeSubtype) {
            if (values?.taskTypeSubtype?.id < 0) {
                delete values.taskTypeSubtype;
            } else {
                values.taskTypeSubtype = getSearchFormat(values.taskTypeSubtype);
            }
        }
        if (values.assignedTeam) {
            if (values?.assignedTeam?.id < 0) {
                delete values.assignedTeam;
            } else {
                values.assignedTeam = getSearchFormat(values.assignedTeam);
            }
        }
        if (values.assignedUser) {
            if (values?.assignedUser?.id < 0) {
                delete values.assignedUser;
            } else {
                values.assignedUser = getSearchFormat(values.assignedUser);
            }
        }
        if (values.escalateTeam) {
            if (values?.escalateTeam?.id < 0) {
                delete values.escalateTeam;
            } else {
                values.escalateTeam = getSearchFormat(values.escalateTeam);
            }
        }
        if (values.escalateUser) {
            if (values?.escalateUser?.id < 0) {
                delete values.escalateUser;
            } else {
                values.escalateUser = getSearchFormat(values.escalateUser);
            }
        }
        if (fileList.length > 0 && fileList[0]) {
            values.fileId = fileList[0].id;
        }

        return values;
    };

    const saveForm = () => {
        if (data) {
            form.validateFields().then(normalize(data));
        }
        setFetchWhenChange((prevValue: any) => prevValue + 1);
        NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_TEMPLATE.EDIT + '/' + dataForm, undefined, data).then(
            (result: any) => {
                if (result.status == RESPONSE.CREATED) {
                    okNotification();
                } else {
                    if (result.data && result.data.error) {
                        if (geti18nText(result.data.error) != undefined) {
                            errorNotification(geti18nText(result.data.error));
                        } else {
                            errorNotification(JSON.stringify(result.data.error));
                        }
                    } else {
                        errorNotification();
                    }
                }
            }
        );
    };

    useEffect(() => {
        if (dataForm) {
            saveForm();
        }
    }, [allEmployees]);

    const formProps = { labelCol: { offset: 0, span: 24 }, wrapperCol: { offset: 0, span: 24 } };

    return (
        <React.Fragment>
            <NyDataEdit
                layout="vertical"
                formProps={formProps}
                editHeader={editHeader}
                closeModalButtonText={geti18nText('app.default.button.close')}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                onModalOpen={onModalOpen}
                url={CONSTANTS_REQ.TASK_TEMPLATE.EDIT}
                width={900}
                form={form}
                goBack={() => history.goBack()}
                hideSubmitButton={hideButtons()}
                hideActivationButtons={hideButtons()}
                paramsId={id}
                setValues={setValues}
                setIsCreate={setIsCreate}
                {...props}
                shortcuts={true}
                onSaveAndGetID={refreshView}
                fetchWhenChange={fetchWhenChange}
                checkIsFormChanged={true}
                normalize={normalize}
                leaveNewModalOpen={true}
                customFooterContent={getCustomFooterContent}
            >
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        setActiveKey(key);
                    }}
                >
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.general')} key="1">
                        <Col span={24} offset={show ? 0 : 1}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <NyImageUpload files={fileList} setFiles={setFileList} saveOnUpload={true} />
                                </Col>
                                <Col span={16}>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item name="id" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="position" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={geti18nText('task.template.table.column.code')}
                                                name="code"
                                            >
                                                <Input autoFocus={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('task.template.table.column.name')}
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('task.template.table.column.description')}
                                        name="description"
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <TaskTypeCategorySearch />
                                </Col>
                                <Col span={12}>
                                    <TaskTypeSubtypeSearch />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <TeamSearch
                                        label={geti18nText('task.template.table.column.assignedTeam')}
                                        name="assignedTeam"
                                    />
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('task.template.table.column.assignedEmployee')}
                                        name="assignedUser"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.USER.SEARCH}
                                            map={{
                                                id: 'id',
                                                label: 'text',
                                                employmentRecordId: 'employmentRecordId',
                                                businessUnit: 'businessUnit',
                                            }}
                                            searchBy="user"
                                            itemName={[
                                                ['person', 'firstName'],
                                                ['person', 'lastName'],
                                            ]}
                                            renderOption={customEmployeeRenderOption}
                                            customItemNameLabel={'firstName lastName'}
                                            SearchPopupComponent={<UserIndex disabled={true} />}
                                            style={{ width: '100%' }}
                                            addNewItem={false}
                                            order="person.last_name, person.first_name"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <TeamSearch
                                        label={geti18nText('task.template.table.column.escalateTeam')}
                                        name="escalateTeam"
                                        nyTestId="escalateTeam"
                                    />
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('task.template.table.column.escalateUser')}
                                        name="escalateUser"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.USER.SEARCH}
                                            map={{
                                                id: 'id',
                                                label: 'text',
                                                employmentRecordId: 'employmentRecordId',
                                                businessUnit: 'businessUnit',
                                            }}
                                            searchBy="user"
                                            itemName={[
                                                ['person', 'firstName'],
                                                ['person', 'lastName'],
                                            ]}
                                            renderOption={customEmployeeRenderOption}
                                            customItemNameLabel={'firstName lastName'}
                                            SearchPopupComponent={<UserIndex disabled={true} />}
                                            style={{ width: '100%' }}
                                            addNewItem={false}
                                            order="person.last_name, person.first_name"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                {taskType != enTaskType.SUBTASK && (
                                    <Col span={6}>
                                        <Form.Item name="forAssignedAsset">
                                            <Checkbox
                                                checked={forAssignedAsset}
                                                onChange={(e: any) => {
                                                    setForAssignedAsset(e.target.checked);
                                                }}
                                            >
                                                {geti18nText('task.template.table.column.assignedAsset')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                )}
                                {taskType != enTaskType.SUBTASK && (
                                    <Col span={6}>
                                        <Form.Item name="enableEvaluation">
                                            <Checkbox
                                                checked={enableEvaluation}
                                                onChange={(e: any) => {
                                                    setEnableEvaluation(e.target.checked);
                                                }}
                                            >
                                                {geti18nText('task.template.edit.enableEvaluation')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                )}
                                {taskType != enTaskType.SUBTASK && (
                                    <Col span={6}>
                                        <Form.Item name="directSolvingAllowed">
                                            <Checkbox
                                                style={{ marginTop: '4px' }}
                                                checked={directSolvingAllowed}
                                                onChange={(e: any) => {
                                                    setDirectSolvingAllowed(e.target.checked);
                                                }}
                                            >
                                                {geti18nText('task.template.edit.directSolvingAllowed')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                )}
                                {taskType != enTaskType.SERVICE && (
                                    <Col span={6}>
                                        <Form.Item name="requiredForClosure">
                                            <Checkbox
                                                checked={requiredForClosure}
                                                onChange={(e: any) => {
                                                    setRequiredForClosure(e.target.checked);
                                                }}
                                            >
                                                {geti18nText('task.template.table.column.requiredForClosure')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.addedData')} key="2" disabled={isCreate}>
                        {!isCreate && (
                            <NyDataTable
                                nyId="tasks-task-template-metadata-table"
                                rowKey="id"
                                url={CONSTANTS_REQ.TASK_TEMPLATE.METADATA.replace(
                                    '{taskTemplateId}',
                                    form.getFieldValue('id')
                                )}
                                showRowSelection
                                readonly
                                hideButtons
                                scroll={props?.editProps?.scroll}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                rowSelectionType={'checkbox'}
                                fetchWhenChange={refreshTable}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                                setDefaultFilterValue={activeFilter}
                                columns={[
                                    {
                                        title: geti18nText('objectMetadata.table.column.id'),
                                        dataIndex: ['objectMetadata', 'id'],
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('objectMetadata.table.column.name'),
                                        dataIndex: ['objectMetadata', 'name'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('objectMetadata.table.column.dataType'),
                                        dataIndex: ['objectMetadata', 'dataType'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearchOption(getEnumArray('OBJECT_METADATA_TYPE')),
                                        render: (text: any, record: any) => {
                                            if (record?.objectMetadata?.dataType) {
                                                return geti18nText(
                                                    'app.enum.OBJECT_METADATA_TYPE.' + record.objectMetadata.dataType
                                                );
                                            }
                                        },
                                    },
                                    {
                                        title: geti18nText('objectMetadata.table.column.mandatory'),
                                        dataIndex: ['objectMetadata', 'mandatory'],
                                        sorter: (a: any, b: any) => {},
                                        render: (text: any, record: any) => {
                                            if (record?.objectMetadata?.mandatory) {
                                                return <CheckOutlined style={{ color: 'green', marginLeft: '6px' }} />;
                                            } else {
                                                return <CloseOutlined style={{ color: 'red', marginLeft: '6px' }} />;
                                            }
                                        },
                                        ...getColumnSearchOption(getEnumBooleanArray()),
                                    },
                                    {
                                        title: geti18nText('objectMetadata.table.column.format'),
                                        dataIndex: ['objectMetadata', 'pattern'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('objectMetadata.table.column.active'),
                                        dataIndex: ['objectMetadata', 'active'],
                                        width: '10%',
                                        render: (text: any, record: { active: any }) => {
                                            if (record.active) {
                                                return <CheckOutlined style={{ color: 'green', marginLeft: '6px' }} />;
                                            } else {
                                                return <CloseOutlined style={{ color: 'red', marginLeft: '6px' }} />;
                                            }
                                        },
                                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                                    },
                                ]}
                            />
                        )}
                        {isAddListModalVisibleGroup && (
                            <Modal
                                title={geti18nText('app.default.modal.select')}
                                visible={isAddListModalVisibleGroup}
                                okText={geti18nText('app.default.button.select')}
                                okButtonProps={{ disabled: !hasSelectedAddGroup }}
                                onOk={(e: any) => saveToGroup(e)}
                                confirmLoading={loadingModalGroup}
                                width={900}
                                onCancel={() => {
                                    setIsAddListModalVisibleGroup(false);
                                }}
                            >
                                <NyDataTable
                                    nyId="tasks-task-template-object-metadata-table"
                                    rowKey="id"
                                    url={CONSTANTS_REQ.OBJECT_METADATA.LIST}
                                    showRecordModal={true}
                                    hideNewButton={!canCreate()}
                                    showRowSelection
                                    scroll={props?.editProps?.scroll}
                                    setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                    rowSelectionType={'checkbox'}
                                    setDefaultPageSize={table.setDefaultPageSize()}
                                    rowSelectionModal={setSelectedValuesFuncAddGroup as any}
                                    modalComponent={ObjectMetadataEdit}
                                    editProps={{ metadataType: enMetadataType.TASK_TYPE }}
                                    fetchWhenChange={refreshTable}
                                    setDefaultFilterValue={defaultFilterValue}
                                    onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                    readonly
                                    columns={[
                                        {
                                            title: geti18nText('objectMetadata.table.column.id'),
                                            dataIndex: 'id',
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                        {
                                            title: geti18nText('objectMetadata.table.column.name'),
                                            dataIndex: 'name',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText('objectMetadata.table.column.dataType'),
                                            dataIndex: 'dataType',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchOption(getEnumArray('OBJECT_METADATA_TYPE')),
                                            render: (text: any, recrod: any) => {
                                                if (recrod.dataType) {
                                                    return geti18nText(
                                                        'app.enum.OBJECT_METADATA_TYPE.' + recrod.dataType
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('objectMetadata.table.column.mandatory'),
                                            dataIndex: 'mandatory',
                                            sorter: (a: any, b: any) => {},
                                            render: (text: any, record: any) => {
                                                if (record.mandatory) {
                                                    return (
                                                        <CheckOutlined style={{ color: 'green', marginLeft: '6px' }} />
                                                    );
                                                } else {
                                                    return (
                                                        <CloseOutlined style={{ color: 'red', marginLeft: '6px' }} />
                                                    );
                                                }
                                            },
                                            ...getColumnSearchOption(getEnumBooleanArray()),
                                        },
                                        {
                                            title: geti18nText('objectMetadata.table.column.format'),
                                            dataIndex: 'pattern',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText('objectMetadata.table.column.active'),
                                            dataIndex: 'active',
                                            width: '10%',
                                            render: (text: any, record: { active: any }) => {
                                                if (record.active) {
                                                    return (
                                                        <CheckOutlined style={{ color: 'green', marginLeft: '6px' }} />
                                                    );
                                                } else {
                                                    return (
                                                        <CloseOutlined style={{ color: 'red', marginLeft: '6px' }} />
                                                    );
                                                }
                                            },
                                            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                                        },
                                    ]}
                                />
                            </Modal>
                        )}
                    </Tabs.TabPane>
                    {taskType != enTaskType.SUBTASK && (
                        <Tabs.TabPane tab={geti18nText('task.template.edit.tab.teams')} key="3" disabled={isCreate}>
                            <Col span={6} style={{ marginBottom: !allEmployees ? '-42px' : '0px' }}>
                                <Form.Item name="allEmployees">
                                    <Checkbox
                                        checked={allEmployees}
                                        onChange={(e: any) => {
                                            setAllEmployees(e.target.checked);
                                        }}
                                    >
                                        {geti18nText('task.template.edit.allEmployees')}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            {!allEmployees && (
                                <NyDataTable
                                    nyId="tasks-task-template-team-table"
                                    rowKey="id"
                                    url={CONSTANTS_REQ.TASK_TEMPLATE.TEAMS_LIST.replace(
                                        '{taskTemplateId}',
                                        form.getFieldValue('id')
                                    )}
                                    showRowSelection
                                    readonly
                                    hideButtons
                                    scroll={props?.editProps?.scroll}
                                    setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                    rowSelectionType={'checkbox'}
                                    fetchWhenChange={refreshTable}
                                    setDefaultPageSize={table.setDefaultPageSize()}
                                    rowSelectionModal={setSelectedValuesFuncDeleteTeam as any}
                                    setDefaultFilterValue={activeFilter}
                                    columns={[
                                        {
                                            title: geti18nText('teams.table.column.id'),
                                            dataIndex: 'id',
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                        {
                                            title: geti18nText('teams.table.column.name'),
                                            dataIndex: ['team', 'name'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                    ]}
                                />
                            )}
                            {isAddListModalVisibleTeam && (
                                <Modal
                                    title={geti18nText('app.default.modal.select')}
                                    visible={isAddListModalVisibleTeam}
                                    okText={geti18nText('app.default.button.add')}
                                    okButtonProps={{ disabled: !hasSelectedAddTeam }}
                                    onOk={(e: any) => saveToTeam(e)}
                                    confirmLoading={loadingModalTeam}
                                    width={900}
                                    onCancel={() => {
                                        setIsAddListModalVisibleTeam(false);
                                    }}
                                >
                                    <NyDataTable
                                        nyId="tasks-task-template-team-not-in-list-table"
                                        rowKey="id"
                                        url={CONSTANTS_REQ.TASK_TEMPLATE.TEAMS_NOT_IN_LIST.replace(
                                            '{taskTemplateId}',
                                            form.getFieldValue('id')
                                        )}
                                        showRecordModal={true}
                                        hideNewButton={!canCreate()}
                                        showRowSelection
                                        scroll={props?.editProps?.scroll}
                                        setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                        rowSelectionType={'checkbox'}
                                        setDefaultPageSize={table.setDefaultPageSize()}
                                        rowSelectionModal={setSelectedValuesFuncAddTeam as any}
                                        modalComponent={TeamEdit}
                                        fetchWhenChange={refreshTable}
                                        setDefaultFilterValue={activeFilter}
                                        onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                        columns={[
                                            {
                                                title: geti18nText('teams.table.column.id'),
                                                dataIndex: 'id',
                                                width: '10%',
                                                sorter: (a: any, b: any) => {},
                                                ...getColumnSearch('number'),
                                            },
                                            {
                                                title: geti18nText('teams.table.column.name'),
                                                dataIndex: 'name',
                                                sorter: (a: any, b: any) => {},
                                                ...getColumnSearch('string'),
                                            },
                                        ]}
                                    />
                                </Modal>
                            )}
                        </Tabs.TabPane>
                    )}
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.tasks')} key="4" disabled={isCreate}>
                        <TaskTemplateIndex
                            filter={[
                                {
                                    field: 'active',
                                    condition: 'equals_bool',
                                    value: 1,
                                },
                                {
                                    field: 'parentId',
                                    condition: 'equals',
                                    value: dataForm,
                                },
                            ]}
                            idWidth={'9%'}
                            activeWidth={'9%'}
                            taskAdminWidth={'14%'}
                            taskTemplateId={dataForm}
                            taskType={enTaskType.SUBTASK}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.approval')} key="5" disabled={isCreate}>
                        <TaskTemplateApprovalIndex taskTemplateId={dataForm} />
                    </Tabs.TabPane>
                </Tabs>
            </NyDataEdit>
            {historyModalVisible && (
                <Modal
                    title={geti18nText('task.edit.history')}
                    visible={historyModalVisible}
                    onCancel={() => {
                        setHistoryModalVisible(false);
                    }}
                    okButtonProps={{ hidden: true }}
                    maskClosable={false}
                    width={1200}
                >
                    <NyHistory id={dataForm} link={'tasks.list.column'} />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default TaskTemplateEdit;
