import { PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyInputNumber, NySearchField, NySession } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import NyNoteModal from '../../components/note-modal';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getSearchFormat, setSearchFormat, userIsNotificationRecipient } from '../../utils/Utils';
import TravelVehiclePersonalIndex from '../../views/travel-warrant/views/travel-vehicle-personal';
import TravelVehiclePersonalEdit from '../../views/travel-warrant/views/travel-vehicle-personal/edit';
import TravelWarrantMap from '../travel-warrant-map/TravelWarrantMap';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';

const TravelWarrantRelationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelWarrantRelation.edit.new'));
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const { employee } = useSelector((state: RootState) => state.employee);
    const vehileReimbursmentPrice = useApplicationSetting('TRAVEL_WARRANT_VEHICLE_REIMBURSMENT_PRICE');
    const distanceAllowedDeviation = useApplicationSetting('TRAVEL_WARRANT_VEHICLE_ALLOWED_DEVIATION');
    const travelWarrantStartingPoint = useApplicationSetting('TRAVEL_WARRANT_DEFAULT_STARTING_POINT');
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');

    const [form] = Form.useForm();
    const history = useHistory();
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [initialWaypoints, setInitialWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [mapDistance, setMapDistance] = useState<any>(0);
    const [distanceDeviation, setDistanceDeviation] = useState<Boolean>(false);
    const [isCreate, setIsCreate] = useState<any>(null);
    const [startCounter, setStartCounter] = useState<any>(undefined);
    const [endCounter, setEndCounter] = useState<any>(undefined);
    const [mapDistancePrev, setMapDistancePrev] = useState<any>(0);
    const [deviationAllowed, setDeviationAllowed] = useState<any>(distanceAllowedDeviation);
    const callCmpanyWaypoints = useRef<any>(true);
    const [distance, setDistance] = useState<any>(undefined);
    const [taxable, setTaxable] = useState<boolean>(false);
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const disableForm: boolean = props?.editProps?.disabled;
    const [dataForm, setDataForm] = useState<any>(null);
    const [manualWaypointChange, setManualWaypointChange] = useState<boolean>(false);

    const isUser = () => {
        return TravelWarrantRights.canCreateTravelWarrantOnlyUser();
    };

    const isAdmin = () => {
        return (
            TravelWarrantRights.canCreateTravelWarrantAdmin() ||
            (TravelWarrantRights.canCreateTravelWarrantUser() &&
                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT))
        );
    };

    const canCreate = () => {
        return (isUser() || isAdmin()) && !disableForm;
    };

    const hasMapDistance = () => {
        return mapDistance && mapDistance > 0;
    };

    const hasMapDistancePrev = () => {
        return mapDistancePrev && mapDistancePrev > 0;
    };

    const showCounterValidation = () => {
        return (
            !isAdmin() &&
            ((form.getFieldValue(['counterStart']) == 0 && form.getFieldValue(['counterEnd']) == 0) ||
                form.getFieldValue(['counterStart']) == form.getFieldValue(['counterEnd']))
        );
    };

    const showDistanceValidation = () => {
        return distanceDeviation && deviationAllowed;
    };

    const getMapDistance = (value: any) => {
        if (isAdmin()) {
            return mapDistancePrev > 0 && !hasMapDistance()
                ? mapDistancePrev
                : value && !hasMapDistance()
                ? value
                : mapDistance;
        } else {
            return mapDistance;
        }
    };

    useEffect(() => {
        if (hasMapDistancePrev()) {
            form.setFieldsValue({ mapDistance: mapDistancePrev });
        }
    }, [mapDistancePrev]);

    useEffect(() => {
        if (!distance || manualWaypointChange) {
            let mapValue = mapDistance;

            if (isAdmin() && hasMapDistancePrev() && !hasMapDistance()) {
                mapValue = mapDistancePrev;
            }
            if (hasMapDistancePrev()) {
                form.setFieldsValue({ mapDistance: mapDistancePrev ?? 0 });
            } else {
                form.setFieldsValue({ mapDistance: mapValue ?? 0 });
            }
            if (!isAdmin()) {
                setDeviationAllowed(mapValue > 0 ? distanceAllowedDeviation : false);
            }

            form.setFieldsValue({ distance: mapValue });
            form.setFieldsValue({ counterEnd: mapValue + form.getFieldValue(['counterStart']) });
            calculateAmount();
        }
    }, [mapDistance]);

    useEffect(() => {
        if (isCreate != null && !isCreate) {
            getCompanyWaypoints();
        }
    }, [isCreate]);

    const getCompanyWaypoints = async () => {
        let waypoints: any = [JSON.parse(travelWarrantStartingPoint)];
        if (waypoints.length === 0) {
            waypoints.push({ id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' });
        }
        waypoints.push({ id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' });

        setInitialWaypoints(waypoints.slice());
        setDefaultWaypoints(waypoints.slice());
        if (callCmpanyWaypoints.current == true) {
            setWaypoints(JSON.parse(JSON.stringify(waypoints)));
        }
    };

    const getDestinationName = (name: any) => {
        const returnName = name.replaceAll(' - Granični prijelaz', '');
        return returnName;
    };

    function setValues(dataForm: any) {
        setDataForm(dataForm);
        callCmpanyWaypoints.current = false;
        if (dataForm.travelVehicle) {
            dataForm.travelVehicle = setSearchFormat(dataForm.travelVehicle, 'name', 'name');
        }

        if (dataForm.relationJson) {
            let relation = JSON.parse(dataForm.relationJson);
            let newRelation: any = [];
            relation.map((element: any) => {
                if (element != '-' && element.name != '') {
                    newRelation.push(element);
                }
            });

            newRelation = newRelation.filter(
                (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
            );

            const lastItem = newRelation[newRelation.length - 1];
            let initList = [];
            setReturnRoute(false);
            if (lastItem?.id == 9999) {
                const firstItem = newRelation[0];
                setReturnRoute(true);
                const returnList = newRelation.filter((x: any) => x.id >= 9000 && x.id != 9999);
                initList = JSON.parse(JSON.stringify(returnList));
                returnIntermediateWaypointsInit.current = initList;
                newRelation = newRelation.slice(0, newRelation.length - 1);
                newRelation = newRelation.filter((x: any) => x.id < 9000);
                let list = [firstItem].concat(returnList).concat(lastItem);
                setInitialWaypoints(newRelation);
                setWaypoints(newRelation);
                setReturnWaypoints(JSON.parse(JSON.stringify(list)));
            } else {
                setInitialWaypoints(newRelation);
                setWaypoints(newRelation);
            }
            setMaxWaypointsKey(newRelation.length);
        }

        if (dataForm.counterStart) {
            setStartCounter(dataForm.counterStart);
        }
        if (dataForm.counterEnd) {
            setEndCounter(dataForm.counterEnd);
        }
        if (dataForm.mapDistance) {
            setMapDistancePrev(dataForm.mapDistance);
        }
        if (dataForm.distance) {
            setDistance(dataForm.distance);
        }
        if (dataForm.name) {
            dataForm.name = getDestinationName(dataForm.name);
        }
        setEditHeader(geti18nText('travelWarrantRelation.edit.title') + ' - ' + dataForm.name);
        setNote(dataForm.note ? dataForm.note : undefined);
        setTaxable(dataForm.taxable);
        setDataForm(dataForm);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
        //   travelVehicleOnChange(dataForm.travelVehicle);
    }
    function onRouteNameChange(name: any) {
        setEditHeader(geti18nText('travelWarrantRelation.edit.title') + ' - ' + name);
    }

    const onModalClose = () => {
        form.resetFields();
        setNote(undefined);
        setEditHeader(geti18nText('travelWarrantRelation.edit.new'));
        setWaypoints(JSON.parse(JSON.stringify(defaultWaypoints)));
        setInitialWaypoints(JSON.parse(JSON.stringify(defaultWaypoints)));
        setMaxWaypointsKey(2);
        setMapDistance(0);
        setMapDistancePrev(0);
        setDistanceDeviation(false);
        setReturnWaypoints([]);
        setReturnRoute(false);
        setIsCreate(null);
        setDataForm(null);
    };

    const reset = async () => {
        let waypointsTemp: any = [];
        if (dataForm) {
            waypointsTemp = initialWaypoints;
        } else {
            waypointsTemp = [
                { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
                { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
            ];
        }
        form.resetFields();
        setWaypoints(JSON.parse(JSON.stringify(waypointsTemp)));
        if (dataForm) {
            setValues(dataForm);
        }
    };

    const getCustomFooterContent = (
        <>
            <div className="ny-modal-footer-content">
                <NyNoteModal note={note} />
            </div>
            <div style={{ float: 'left', marginLeft: '35px' }}>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button key={'print'} icon={<PrinterOutlined />}>
                                {geti18nText('travelWarrant.action.pdf.short')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(true);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(false);
                    }}
                    content={() => componentRef.current}
                    pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                />
            </div>
            <Button onClick={reset} style={{ marginRight: '15px' }}>
                {geti18nText('travel.warrant.edit.map.reset.waypoints')}
            </Button>
        </>
    );

    const travelVehicleOnChange = (value: any) => {
        if (value && value.id > 0) {
            form.setFieldsValue({ price: vehileReimbursmentPrice });
            calculateAmount();
        } else {
            form.setFieldsValue({ price: 0, amount: 0 });
        }
    };

    const calculateDistance = () => {
        setDistanceDeviation(false);
        let currentDistance = 0;
        let counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        let counterEnd = form.getFieldValue(['counterEnd']) ? form.getFieldValue(['counterEnd']) : 0;

        if (counterEnd > 0) {
            currentDistance = Math.abs(counterEnd - counterStart);
        } else {
            currentDistance = Math.abs(counterEnd);
        }
        form.setFieldsValue({ distance: currentDistance });
        calculateAmount();
        if (distanceAllowedDeviation && mapDistance) {
            let minMapDistance = mapDistance - mapDistance * (distanceAllowedDeviation / 100.0);
            let maxMapDistance = mapDistance + mapDistance * (distanceAllowedDeviation / 100.0);
            if (currentDistance < minMapDistance || currentDistance > maxMapDistance) {
                setDistanceDeviation(true);
            }
        }
    };

    const calculateAmount = () => {
        let amount = 0;
        let price = form.getFieldValue(['price']) ?? 0;
        let distance = form.getFieldValue(['distance']) ?? 0;
        // if (isAdmin() && (!hasMapDistance() || (!hasMapDistancePrev() && hasMapDistance()))) {
        //     distanceValue = mapDistancePrev > 0 ? mapDistancePrev : form.getFieldValue(['mapDistance']);
        // }
        amount = distance * price;
        form.setFieldsValue({ amount: amount > 0 ? amount : 0 });
    };

    const counterStartOnChange = () => {
        const currentDistance = form.getFieldValue(['distance'])
            ? form.getFieldValue(['distance'])
            : mapDistance
            ? mapDistance
            : 0;
        const counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        if (startCounter === undefined || startCounter !== counterStart) {
            setStartCounter(counterStart);
            if (currentDistance > 0 && counterStart >= 0) {
                form.setFieldsValue({ counterEnd: counterStart + currentDistance });
            }
        }
    };

    const counterEndOnChange = () => {
        const currentDistance = form.getFieldValue(['distance'])
            ? form.getFieldValue(['distance'])
            : mapDistance
            ? mapDistance
            : 0;
        let counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        let counterEnd = form.getFieldValue(['counterEnd']) ? form.getFieldValue(['counterEnd']) : 0;
        if (currentDistance > 0 && counterEnd > 0 && counterEnd < counterStart) {
            if (counterEnd - currentDistance >= 0) {
                form.setFieldsValue({ counterStart: counterEnd - currentDistance });
            } else {
                form.setFieldsValue({ counterStart: 0 });
            }
        }
        if (counterEnd == 0) {
            form.setFieldsValue({ distance: 0 });
        }
        calculateDistance();
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TRAVEL_WARRANT_RELATION.EDIT}
            setValues={setValues}
            width={1200}
            setIsCreate={setIsCreate}
            form={form}
            goBack={() => history.goBack()}
            paramsId={props && props.addedData && props.addedData.id}
            {...props}
            normalize={(values: any) => {
                if (values.travelVehicle) values.travelVehicle = getSearchFormat(values.travelVehicle);

                values.isAdmin = isAdmin() && !props?.editProps?.isWizard ? true : false;

                if (returnRoute == true) {
                    let waypointsList = waypoints.filter(
                        (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
                    );
                    let waypointsRes = JSON.parse(JSON.stringify(waypointsList));

                    let waypointsListReturn = returnWaypoints.filter(
                        (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
                    );
                    let returnWaypointsRes = JSON.parse(JSON.stringify(waypointsListReturn));
                    if (
                        waypointsRes[0] != undefined &&
                        waypointsRes[0].lat == returnWaypointsRes[returnWaypointsRes.length - 1]?.lat &&
                        waypointsRes[0].lng == returnWaypointsRes[returnWaypointsRes.length - 1]?.lng
                    ) {
                        let i = 9000;
                        returnWaypointsRes.forEach((element: any) => {
                            element.id = i;
                            i = i + 1;
                        });

                        returnWaypointsRes = returnWaypointsRes.splice(1);
                        returnWaypointsRes[returnWaypointsRes.length - 1].id = 9999;
                        waypointsRes = waypointsRes.concat(returnWaypointsRes);
                    }
                    const result = waypointsRes.filter((point: any) => {
                        return point.name != '';
                    });

                    values.relationJson = JSON.stringify(result);
                } else {
                    const result = waypoints.filter((point: any) => {
                        return point.name != '';
                    });
                    values.relationJson = JSON.stringify(result);
                }

                values.travelWarrant = { id: props && props.addedData && props.addedData.id };
                values.mapDistance = getMapDistance(values.mapDistance);
                values.taxable = taxable;
                return values;
            }}
            customFooterContent={getCustomFooterContent}
            shortcuts={true}
            nyTestId="transport-data-edit"
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            modalStyle={props?.editProps?.isWizard ? { top: '50px' } : {}}
        >
            <Row gutter={24} style={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                    <TravelWarrantMap
                        showMap={true}
                        form={form}
                        waypoints={waypoints}
                        setWaypoints={setWaypoints}
                        maxWaypointsKey={maxWaypointsKey}
                        setMaxWaypointsKey={setMaxWaypointsKey}
                        setMapDistance={setMapDistance}
                        disabled={false}
                        returnRoute={returnRoute}
                        returnWaypoints={returnWaypoints}
                        setReturnWaypoints={setReturnWaypoints}
                        returnIntermediateWaypointsInit={returnIntermediateWaypointsInit.current}
                        onRouteNameChange={onRouteNameChange}
                        componentRefProp={componentRef}
                        printPdfProp={printPdf}
                        setPrintPdfProp={setPrintPdf}
                        printPdfHeader={editHeader}
                        hidePrint={true}
                        setManualWaypointChange={setManualWaypointChange}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantRelation.edit.travelRelation')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantRelation.edit.travelVehicleName')}
                                name="travelVehicle"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'name',
                                    }}
                                    searchBy="name"
                                    SearchPopupComponent={<TravelVehiclePersonalIndex vehicleType={1} sort={true} />}
                                    AddNewModalComponent={TravelVehiclePersonalEdit}
                                    nyTestId="travel-vehicle"
                                    onChange={travelVehicleOnChange}
                                    setDefaultFilterValue={() => [
                                        { field: 'active', condition: 'equals_bool', value: 1 },
                                        { field: 'vehicle_type', condition: 'equals', value: 1 },
                                        { field: 'employeeId', condition: 'equals', value: employee.id },
                                    ]}
                                    order="sort"
                                    orderType="asc"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantRelation.edit.counterStart')}
                                name="counterStart"
                                validateStatus={
                                    form.getFieldValue('counterStart') == 0
                                        ? 'error'
                                        : showCounterValidation()
                                        ? 'warning'
                                        : 'validating'
                                }
                                help={
                                    form.getFieldValue('counterStart') == 0
                                        ? geti18nText('travelWarrantRelation.edit.startCounter.min')
                                        : showCounterValidation()
                                        ? geti18nText('travelWarrantRelation.edit.startEndDeviation')
                                        : undefined
                                }
                            >
                                <NyInputNumber
                                    onChange={counterStartOnChange}
                                    style={{ width: '100%' }}
                                    nyTestId="counter-start"
                                    min={0}
                                    autoFocus
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantRelation.edit.counterEnd')}
                                name="counterEnd"
                                validateStatus={showCounterValidation() ? 'warning' : 'validating'}
                                help={
                                    showCounterValidation()
                                        ? geti18nText('travelWarrantRelation.edit.startEndDeviation')
                                        : undefined
                                }
                            >
                                <NyInputNumber
                                    min={form.getFieldValue('counterStart') + 1}
                                    onChange={counterEndOnChange}
                                    style={{ width: '100%' }}
                                    nyTestId="counter-end"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={10}>
                            <Form.Item
                                label={geti18nText('travelWarrantRelation.edit.distance')}
                                name="distance"
                                validateStatus={showDistanceValidation() ? 'warning' : 'validating'}
                                help={
                                    showDistanceValidation()
                                        ? geti18nText('travelWarrantRelation.edit.distanceDeviation') +
                                          ' ' +
                                          (distanceAllowedDeviation ? distanceAllowedDeviation + '%' : '')
                                        : undefined
                                }
                            >
                                <NyInputNumber
                                    onChange={calculateAmount}
                                    style={{ width: '100%' }}
                                    nyTestId="distance"
                                    disabled={hasMapDistance() || isAdmin()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('travelWarrantRelation.edit.price')}
                                name="price"
                                initialValue={vehileReimbursmentPrice}
                            >
                                <NyInputNumber
                                    onChange={calculateAmount}
                                    isDecimal={true}
                                    style={{ width: '100%' }}
                                    nyTestId="price"
                                    disabled={
                                        props?.editProps?.isWizard ||
                                        TravelWarrantRights.disablePriceEdit(employee, notificationType)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label={geti18nText('travelWarrantRelation.edit.amount')} name="amount">
                                <NyInputNumber
                                    isDecimal={true}
                                    style={{ width: '100%' }}
                                    nyTestId="amount"
                                    disabled={!props?.addedData?.isAdmin}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3} style={{ display: 'flex', left: '-10px', top: '28px' }}>
                            {defaultCurrencyISO}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        {isAdmin() && !hasMapDistance() && (
                            <Col span={10}>
                                <Form.Item
                                    label={geti18nText('travelWarrantRelation.edit.distance.admin')}
                                    name="mapDistance"
                                >
                                    <NyInputNumber
                                        onChange={calculateAmount}
                                        style={{ width: '100%' }}
                                        nyTestId="mapDistance"
                                        disabled={false}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {isAdmin() && !props?.editProps?.isWizard && (
                            <Col span={10}>
                                <Form.Item
                                    label={geti18nText('travelWarrantWageRecapitulation.taxable')}
                                    name="taxable"
                                >
                                    <Checkbox
                                        checked={taxable}
                                        onChange={(val: any) => {
                                            setTaxable(val.target.checked);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelWarrantRelationEdit;
