import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';
import { Form } from 'antd';

import CountyIndex from '.';
import CountyEdit from './edit';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { AdministrationRights } from '../../../../rights/administrationRights';

const CountySearch = ({
    label = geti18nText('settlement.edit.county'),
    name = 'county',
    onChange,
    nyTestId = 'county',
}: any) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    return (
        <Form.Item label={label} name={name}>
            <NySearchField
                url={CONSTANTS_REQ.COUNTY.SEARCH}
                map={{ id: 'id', label: 'text' }}
                searchBy="name"
                SearchPopupComponent={canCreate() ? <CountyIndex /> : null}
                AddNewModalComponent={canCreate() ? CountyEdit : null}
                nyTestId={nyTestId}
                onChange={onChange}
                setDefaultFilterValue={setDefaultFilterValue}
            />
        </Form.Item>
    );
};

export default CountySearch;
