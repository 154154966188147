import { NyDataTable, getColumnSearch, geti18nText } from '@nybble/nyreact';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple, getColumnSearchByCodebookWorkplace } from '../../../../utils/Filters';
import DetailEdit from './DetailEdit';

const WorkplaceDetailsIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    filter = undefined,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    useHelper('human/codebooks/workplace_details');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('workplaceDetails.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('workplaceDetails.table.column.vocation'),
            dataIndex: "concat(vocation.name, ' ', vocationDescription.name)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookWorkplace(),
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        record.vocation.name +
                        (record.vocationDescription ? ' - ' + record.vocationDescription.name : '')
                    );
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-workplace-details-table"
            url={CONSTANTS_REQ.WORKPLACE_DETAILS.LIST}
            addNewButtonText={geti18nText('workplaceDetails.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={DetailEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            setDefaultFilterValue={() =>
                filter == undefined
                    ? table.setDefaultFilterValue(setDefaultFilterValue())
                    : table.setDefaultFilterValue(filter)
            }
            columns={columns}
            hideNewButton={!canCreate()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.workplaceDetails')}
            colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default WorkplaceDetailsIndex;
