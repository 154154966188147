import { NySession, NySpinner } from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { setModalOpen } from '../../slices/modalOpenSlice';
import LayoutIndex from '../layout';
import NyNotifNoteModal from '../notif-note-modal';

const ApprovalIndex = () => {
    const history = useHistory();
    const location: any = useLocation();
    let { search } = useLocation();
    const dispatch = useDispatch();
    const { employee } = useSelector((state: RootState) => state.employee);

    const query: any = new URLSearchParams(search);
    const [loading, setLoading] = useState<any>(true);
    const [loginRedirectTab, setLoginRedirectTab] = useState<any>('/');

    // const { tabs } = useSelector((state: RootState) => state.tabs);
    // const [tab, setTab] = useState<any>([]);
    const [noteModalData, setNoteModalData] = useState([]);
    const [noteModalVisible, setNoteModalVisible] = useState(false);
    const [noteModalTitle, setNoteModalTitle] = useState<any>('');
    const [noteModalKey, setNoteModalKey] = useState<any>(undefined);

    useEffect(() => {
        if (location && (location.pathname || location.search)) {
            const locationSearch = location.search;
            const locationPathname = location.pathname;
            if (locationPathname != '/open') {
                if (locationPathname) {
                    const dataSubstring = locationPathname.replace('/open/data=', '');
                    if (dataSubstring) {
                        parseData(dataSubstring);
                    }
                }
            } else {
                if (locationSearch) {
                    const dataSubstring = locationSearch.replace('?data=', '');
                    if (dataSubstring) {
                        parseData(dataSubstring);
                    }
                }
            }
        } else {
            parseData(query.get('data'));
        }
    }, [location]);

    const parseData = async (data: any) => {
        setLoading(true);
        const dataParsed = data && JSON.parse(atob(data));
        if (dataParsed) {
            localStorage.removeItem('loginRedirectTab');
            await getLink(data, dataParsed);
        }
        setLoading(false);
    };

    const getLink = (data: any, link: any) => {
        setLoginRedirectTab(JSON.stringify(data));
        if (link?.function === 'openNoteInModal') {
            openNoteInModal(link?.item, link?.title, link?.key);
        }
    };

    const openNoteInModal = (item: any, title: any, key: any = undefined) => {
        setNoteModalData(item);
        setNoteModalKey(key);
        setNoteModalVisible(true);
        setNoteModalTitle(title);
    };

    if (loading || (!employee?.id && NySession.isUserAuthenticated())) {
        return <NySpinner />;
    } else if (!NySession.isUserAuthenticated()) {
        if (loginRedirectTab != undefined) {
            localStorage.setItem('loginRedirectTab', loginRedirectTab);
        }
        return <Redirect to="/login" />;
    } else {
        return (
            <>
                <LayoutIndex />
                {noteModalVisible && (
                    <NyNotifNoteModal
                        modalVisible={noteModalVisible}
                        setModalVisible={setNoteModalVisible}
                        title={noteModalTitle}
                        data={noteModalData}
                        setData={setNoteModalData}
                        notifKey={noteModalKey}
                        setNotifKey={setNoteModalKey}
                    />
                )}
            </>
        );
    }
};

export default ApprovalIndex;
