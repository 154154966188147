import { createSlice } from '@reduxjs/toolkit';

interface IModalOpenState {
    visible: boolean;
    modal: string | undefined;
    item: any;
    onSave: any;
    onSaveAndGetID: any;
    onSaveAndGetData: any;
    openModalData:any;
}

const initialState: IModalOpenState = {
    visible: false,
    modal: undefined,
    item: undefined,
    onSave: true,
    onSaveAndGetID: true,
    onSaveAndGetData: true,
    openModalData:undefined,
};

const modalOpen = createSlice({
    name: 'helper',
    initialState,
    reducers: {
        modalOpenInit(state) {
            state.visible = false;
            state.modal = undefined;
            state.item = undefined;
            state.onSave = true;
            state.onSaveAndGetID = true;
            state.onSaveAndGetData = true;
            state.openModalData = undefined;
        },
        setModalOpen(state, action) {
            state.visible = action.payload.visible;
            state.modal = action.payload.modal;
            state.item = action.payload.item;
            state.onSave = action.payload.onSave;
            state.onSaveAndGetID = action.payload.onSaveAndGetID;
            state.onSaveAndGetData = action.payload.onSaveAndGetData;
            state.openModalData = action.payload.openModalData;
        },
        setModalVisible(state, action) {
            state.visible = action.payload;
            state.modal = undefined;
            state.item = undefined;
            state.onSave = true;
            state.onSaveAndGetID = true;
            state.onSaveAndGetData = true;
            state.openModalData = undefined;
        },
    },
});

export const { modalOpenInit, setModalOpen, setModalVisible } = modalOpen.actions;

export default modalOpen.reducer;
