import { useState } from 'react';
import { Col, Row, Modal, Tooltip, Button, Table } from 'antd';
import { geti18nText } from '@nybble/nyreact';
import { HistoryOutlined } from '@ant-design/icons';
import { getDateFormat } from '../../utils/Utils';

const NyHistoryModal = ({
    columns,
    dataSource,
    customColumns = false,
    buttonStyle = { float: 'left', marginRight: '12px' },
}: any) => {
    const [visible, setVisible] = useState<boolean>(false);

    const initialColumns = [
        {
            title: geti18nText('employee.payment.history.created'),
            dataIndex: 'created',
            render: (from: any) => {
                if (from) {
                    return getDateFormat(from, 'DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('employee.payment.history.createdBy'),
            dataIndex: 'createdByName',
        },
        {
            title: geti18nText('employee.payment.history.updated'),
            dataIndex: 'updated',
            render: (from: any) => {
                if (from) {
                    return getDateFormat(from, 'DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('employee.payment.history.updatedBy'),
            dataIndex: 'updatedByName',
        },
    ];

    return (
        <>
            <Tooltip title={geti18nText('app.default.history')}>
                <Button
                    style={buttonStyle}
                    icon={<HistoryOutlined />}
                    onClick={() => {
                        setVisible(true);
                    }}
                />
            </Tooltip>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('app.default.history')}
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    width={1000}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Table
                                pagination={false}
                                size={'small'}
                                dataSource={dataSource}
                                columns={customColumns ? columns : [...columns.slice(0, -1), ...initialColumns]}
                                rowClassName={(record, index) =>
                                    record.active ? 'table-row-active' : 'table-row-inactive'
                                }
                            />
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default NyHistoryModal;
