import { useEffect, useState } from 'react';
import { geti18nText, NyDataEdit, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, Modal, Result, Row, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import EmployeePaymentBrut from './EmployeePaymentBrut';
import EmployeePaymentGeneral from './EmployeePaymentGeneral';
import EmployeePaymentExpense from './EmployeePaymentExpense';
import EmployeePaymentInsurance from './EmployeePaymentInsurance';
import EmployeePaymentTaxDeduction from './EmployeePaymentTaxDeduction';
import EmployeePaymentTax from './EmployeePaymentTax';
import EmployeePaymentTownshipTax from './EmployeePaymentTownshipTax';
import EmployeePaymentAddition from './EmployeePaymentAddition';
import EmployeePaymentDeduction from './EmployeePaymentDeduction';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { refreshItemList } from '../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { okNotification, errorNotification, numberFormat } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const { TabPane } = Tabs;

const EmployeePaymentEdit = (props: any) => {
    const dispatch = useDispatch();
    const [editHeader, setEditHeader] = useState(geti18nText('employee.payment.edit.new'));
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [refresh, setRefresh] = useState(0);
    const [triggerSave, setTrigerSave] = useState(0);

    const [employeeId, setEmployeeId] = useState(undefined);
    const [person, setPerson] = useState<any>({});
    const [address, setAddress] = useState<any>({});
    const [postOffice, setPostOffice] = useState<any>({});
    const [workHours, setWorkHours] = useState<any>();
    const [hourlyRate, setHourlyRate] = useState<any>();
    const [lock, setLock] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState('employeePaymentGeneral');
    const [nextTabKey, setNextTabKey] = useState('');
    const [visibleDirtyModal, setVisibleDirtyModal] = useState(false);
    const [showModalSaveButton, setShowModalSaveButton] = useState(false);
    const [continueSave, setContinueSave] = useState(false);
    const [calcType, setCalcType] = useState(1);

    const [employeeGeneralData, setEmployeeGeneralData] = useState([]);
    const [employeePaymentId, setEmployeePaymentId] = useState(undefined);
    const [employeePaymentBruts, setEmployeePaymentBruts] = useState<any>([]);
    const [employeePaymentExpense, setEmployeePaymentExpense] = useState<any>([]);
    const [employeePaymentInsurances, setEmployeePaymentInsurances] = useState<any>([]);
    const [employeePaymentTaxDeductions, setEmployeePaymentTaxDeductions] = useState<any>([]);
    const [employeePaymentTaxes, setEmployeePaymentTaxes] = useState<any>([]);
    const [employeePaymentTownshipTaxes, setEmployeePaymentTownshipTaxes] = useState<any>([]);
    const [employeePaymentAdditions, setEmployeePaymentAdditions] = useState<any>([]);
    const [employeePaymentDeductions, setEmployeePaymentDeductions] = useState<any>([]);

    const [defaultData, setDefaultData] = useState<any>(undefined);
    const [historyData, setHistoryData] = useState<any>(undefined);

    const calculationStep = [
        'employeePaymentGeneral',
        'employeePaymentBruts',
        'employeePaymentExpense',
        'employeePaymentInsurances',
        'employeePaymentTaxDeductions',
        'employeePaymentTaxes',
        'employeePaymentTownshipTaxes',
        'employeePaymentAdditions',
        'employeePaymentDeductions',
    ];

    const [dirty, setDirty] = useState<any>({
        employeePaymentGeneral: false,
        employeePaymentBruts: false,
        employeePaymentExpense: false,
        employeePaymentInsurances: false,
        employeePaymentTaxDeductions: false,
        employeePaymentTaxes: false,
        employeePaymentTownshipTaxes: false,
        employeePaymentAdditions: false,
        employeePaymentDeductions: false,
    });

    useEffect(() => {
        if (employeePaymentId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_PAYMENT.EDIT + '/' + employeePaymentId).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        setDefaultData(result.data);
                    }
                }
            );

            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_PAYMENT.HISTORY + '/' + employeePaymentId).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        setHistoryData(result.data);
                    }
                }
            );
        }
    }, [employeePaymentId, refresh]);

    useEffect(() => {
        if (
            employeePaymentBruts !== undefined &&
            employeePaymentBruts !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentBruts', employeePaymentBruts);
        }
    }, [employeePaymentBruts]);

    useEffect(() => {
        if (
            employeePaymentExpense !== undefined &&
            employeePaymentExpense !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentExpense', employeePaymentExpense);
        }
    }, [employeePaymentExpense]);

    useEffect(() => {
        if (
            employeePaymentInsurances !== undefined &&
            employeePaymentInsurances !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentInsurances', employeePaymentInsurances);
        }
    }, [employeePaymentInsurances]);

    useEffect(() => {
        if (
            employeePaymentTaxDeductions !== undefined &&
            employeePaymentTaxDeductions !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentTaxDeductions', employeePaymentTaxDeductions);
        }
    }, [employeePaymentTaxDeductions]);

    useEffect(() => {
        if (
            employeePaymentTaxes !== undefined &&
            employeePaymentTaxes !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentTaxes', employeePaymentTaxes);
        }
    }, [employeePaymentTaxes]);

    useEffect(() => {
        if (
            employeePaymentTownshipTaxes !== undefined &&
            employeePaymentTownshipTaxes !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentTownshipTaxes', employeePaymentTownshipTaxes);
        }
    }, [employeePaymentTownshipTaxes]);

    useEffect(() => {
        if (
            employeePaymentAdditions !== undefined &&
            employeePaymentAdditions !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentAdditions', employeePaymentAdditions);
        }
    }, [employeePaymentAdditions]);

    useEffect(() => {
        if (
            employeePaymentDeductions !== undefined &&
            employeePaymentDeductions !== null &&
            defaultData !== undefined &&
            defaultData !== null
        ) {
            checkIsDirty('employeePaymentDeductions', employeePaymentDeductions);
        }
    }, [employeePaymentDeductions]);

    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    const checkIsDirty = (type: string, data: any) => {
        data = cleanEmployeePaymentData(type, data);
        let isChanged = NyUtils.dataHasChanged(data, defaultData[type] ? defaultData[type] : []);
        let tmpDirty = { ...dirty };
        tmpDirty[type] = isChanged;
        setDirty(tmpDirty);
    };

    const cleanEmployeePaymentData = (type: string, data: any) => {
        let outData: any = [];
        switch (type) {
            case 'employeePaymentBruts':
                //outData = data.filter((val: any) => val.payrollBrut && val.payrollBrut.id > 0 && val.amount > 0);
                outData = data.filter((val: any) => val.payrollBrut && val.payrollBrut.id > 0);
                break;
            case 'employeePaymentExpense':
                outData = data.filter((val: any) => val.payrollExpense && val.payrollExpense.id > 0 && val.amount > 0);
                break;
            case 'employeePaymentInsurances':
                outData = data.filter(
                    (val: any) => val.payrollInsurance && val.payrollInsurance.id > 0 && val.amount > 0
                );
                break;
            case 'employeePaymentTaxDeductions':
                outData = data.filter(
                    (val: any) => val.payrollTaxDeduction && val.payrollTaxDeduction.id > 0 && val.amount > 0
                );
                break;
            case 'employeePaymentTaxes':
                outData = data.filter((val: any) => val.payrollTax && val.payrollTax.id > 0 && val.amount > 0);
                break;
            case 'employeePaymentTownshipTaxes':
                outData = data.filter((val: any) => val.townshipTax && val.townshipTax.id > 0 && val.amount > 0);
                break;
            case 'employeePaymentAdditions':
                outData = data.filter(
                    (val: any) => val.payrollAddition && val.payrollAddition.id > 0 && val.amount > 0
                );
                break;
            case 'employeePaymentDeductions':
                outData = data.filter(
                    (val: any) => val.employeePayrollDeduction && val.employeePayrollDeduction.id > 0 && val.amount > 0
                );
                break;
        }

        return outData;
    };

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setEmployeePaymentId(dataForm.id);

        if (dataForm.employee) {
            setEmployeeId(dataForm.employee.id);
            if (dataForm.employee.person) {
                setPerson(dataForm.employee.person);
            }
            if (dataForm.employee.address) {
                setAddress(dataForm.employee.address);
                if (dataForm.employee.address.postOffice) {
                    setPostOffice(dataForm.employee.address.postOffice);
                }
            }
        }

        if (dataForm.workHours) {
            setWorkHours(dataForm.workHours);
        }
        if (dataForm.hourlyRate) {
            setHourlyRate(dataForm.hourlyRate);
        }

        if (dataForm.employeePaymentBruts) {
            setEmployeePaymentBruts(dataForm.employeePaymentBruts);
        }
        if (dataForm.employeePaymentExpense) {
            setEmployeePaymentExpense(dataForm.employeePaymentExpense);
        }
        if (dataForm.employeePaymentInsurances) {
            setEmployeePaymentInsurances(dataForm.employeePaymentInsurances);
        }
        if (dataForm.employeePaymentTaxDeductions) {
            setEmployeePaymentTaxDeductions(dataForm.employeePaymentTaxDeductions);
        }
        if (dataForm.employeePaymentTaxes) {
            setEmployeePaymentTaxes(dataForm.employeePaymentTaxes);
        }
        if (dataForm.employeePaymentTownshipTaxes) {
            setEmployeePaymentTownshipTaxes(dataForm.employeePaymentTownshipTaxes);
        }
        if (dataForm.employeePaymentAdditions) {
            setEmployeePaymentAdditions(dataForm.employeePaymentAdditions);
        }
        if (dataForm.employeePaymentDeductions) {
            setEmployeePaymentDeductions(dataForm.employeePaymentDeductions);
        }
        let paymentCalcType = 1;
        if (
            dataForm.payment_calculation &&
            dataForm.payment_calculation.calcType !== null &&
            dataForm.payment_calculation.calcType !== undefined
        ) {
            paymentCalcType = dataForm.payment_calculation.calcType;
            setCalcType(dataForm.payment_calculation.calcType);
        }

        let calcLog: any = {};
        if (dataForm.calcLog) {
            dataForm.calcLog.split(';').forEach((element: any) => {
                calcLog[element] = true;
            });
        }

        let generalData: any = [
            {
                title: 'brut',
                value: dataForm.brut ? dataForm.brut : 0,
                warning: calcLog && calcLog['brut'] ? 'brut' : null,
            },
        ];

        if (paymentCalcType === 4) {
            generalData = [
                ...generalData,
                {
                    title: 'expense',
                    value: dataForm.expense ? dataForm.expense : 0,
                    warning: calcLog && calcLog['expense'] ? 'expense' : null,
                },
            ];
        }

        generalData = [
            ...generalData,
            {
                title: 'healthInsurance',
                value: dataForm.healthInsurance ? dataForm.healthInsurance : 0,
                warning:
                    calcLog && calcLog['insuranceBase'] && calcLog['healthInsurance']
                        ? 'insuranceBase;healthInsurance'
                        : calcLog['healthInsurance']
                        ? 'healthInsurance'
                        : null,
            },
            {
                title: 'retirementInsurance',
                value: dataForm.retirementInsurance ? dataForm.retirementInsurance : 0,
                warning:
                    calcLog && calcLog['insuranceBase'] && calcLog['retirementInsurance']
                        ? 'insuranceBase;retirementInsurance'
                        : calcLog['retirementInsurance']
                        ? 'retirementInsurance'
                        : null,
            },
        ];

        if (paymentCalcType === 1 || paymentCalcType === 2) {
            generalData = [
                ...generalData,
                {
                    title: 'taxDeduction',
                    value: dataForm.taxDeduction ? dataForm.taxDeduction : 0,
                    warning: calcLog && calcLog['taxDeduction'] ? 'taxDeduction' : null,
                },
            ];
        }

        generalData = [
            ...generalData,
            {
                title: 'tax',
                value: dataForm.tax ? dataForm.tax : 0,
                warning: calcLog && calcLog['tax'] ? 'tax' : null,
            },
            {
                title: 'townshipTax',
                value: dataForm.townshipTax ? dataForm.townshipTax : 0,
                warning:
                    calcLog && calcLog['townshipTaxBase'] && calcLog['townshipTax']
                        ? 'townshipTaxBase;townshipTax'
                        : calcLog['townshipTax']
                        ? 'townshipTax'
                        : null,
            },
            {
                title: 'net',
                value: dataForm.net ? dataForm.net : 0,
                warning: calcLog && calcLog['net'] ? 'net' : null,
            },
        ];

        if (paymentCalcType === 1) {
            generalData = [
                ...generalData,
                {
                    title: 'addition',
                    value: dataForm.addition ? dataForm.addition : 0,
                    warning: calcLog && calcLog['addition'] ? 'addition' : null,
                },
                {
                    title: 'deduction',
                    value: dataForm.deduction ? dataForm.deduction : 0,
                    warning: calcLog && calcLog['deduction'] ? 'deduction' : null,
                },
                {
                    title: 'payment',
                    value: dataForm.payment ? dataForm.payment : 0,
                    warning:
                        calcLog && calcLog['executionUnprotectedPayment'] && calcLog['payment']
                            ? 'executionUnprotectedPayment;payment'
                            : calcLog['payment']
                            ? 'payment'
                            : null,
                },
                {
                    title: 'protectedPayment',
                    value: dataForm.protectedPayment ? dataForm.protectedPayment : 0,
                    warning: calcLog && calcLog['protectedPayment'] ? 'protectedPayment' : null,
                },
            ];
        }
        setEmployeeGeneralData(generalData);

        setLock(dataForm.payment_calculation.locked ? true : false);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEmployeePaymentId(undefined);
        setEmployeePaymentBruts([]);
        setEmployeePaymentExpense([]);
        setEmployeePaymentInsurances([]);
        setEmployeePaymentTaxDeductions([]);
        setEmployeePaymentTaxes([]);
        setEmployeePaymentTownshipTaxes([]);
        setEmployeePaymentAdditions([]);
        setEmployeePaymentDeductions([]);
        setDefaultData(undefined);
        setDirty({
            employeePaymentGeneral: false,
            employeePaymentBruts: false,
            employeePaymentExpense: false,
            employeePaymentInsurances: false,
            employeePaymentTaxDeductions: false,
            employeePaymentTaxes: false,
            employeePaymentTownshipTaxes: false,
            employeePaymentAdditions: false,
            employeePaymentDeductions: false,
        });
        setTabActiveKey('employeePaymentGeneral');
        setEditHeader(geti18nText('employee.payment.edit.new'));
    };

    const getCustomFooterContent = canCreate() && (
        <div style={{ float: 'left' }}>
            <Button disabled={lock} onClick={() => employeePaymentCalculate()}>
                {geti18nText('app.default.calculate')}
            </Button>
        </div>
    );

    const tabOnChange = (activeKey: any) => {
        if (!dirty[tabActiveKey]) {
            setTabActiveKey(activeKey);
        } else {
            setNextTabKey(activeKey);
            setVisibleDirtyModal(true);
        }
    };

    const onDirtyModalCancel = () => {
        setVisibleDirtyModal(false);
        setShowModalSaveButton(false);
    };

    const onDirtyModalContinue = () => {
        if (dirty[tabActiveKey]) {
            let tmpData = defaultData[tabActiveKey] ? [...defaultData[tabActiveKey]] : [];
            switch (tabActiveKey) {
                case 'employeePaymentBruts':
                    setEmployeePaymentBruts(tmpData);
                    break;
                case 'employeePaymentExpense':
                    setEmployeePaymentExpense(tmpData);
                    break;
                case 'employeePaymentInsurances':
                    setEmployeePaymentInsurances(tmpData);
                    break;
                case 'employeePaymentTaxDeductions':
                    setEmployeePaymentTaxDeductions(tmpData);
                    break;
                case 'employeePaymentTaxes':
                    setEmployeePaymentTaxes(tmpData);
                    break;
                case 'employeePaymentTownshipTaxes':
                    setEmployeePaymentTownshipTaxes(tmpData);
                    break;
                case 'employeePaymentAdditions':
                    setEmployeePaymentAdditions(tmpData);
                    break;
                case 'employeePaymentDeductions':
                    setEmployeePaymentDeductions(tmpData);
                    break;
            }
        }
        setTabActiveKey(nextTabKey);
        setVisibleDirtyModal(false);
    };

    const onDirtyModalCalculate = () => {
        employeePaymentCalculate();
        setShowModalSaveButton(false);
    };

    const onDirtyModalSave = () => {
        setVisibleDirtyModal(false);
        setShowModalSaveButton(false);
        setContinueSave(true);
        setTrigerSave((val) => val + 1);
    };

    const updateDefaultData = () => {
        let newData = { ...defaultData };
        if (dirty[tabActiveKey]) {
            switch (tabActiveKey) {
                case 'employeePaymentBruts':
                    newData[tabActiveKey] = employeePaymentBruts;
                    break;
                case 'employeePaymentExpense':
                    newData[tabActiveKey] = employeePaymentExpense;
                    break;
                case 'employeePaymentInsurances':
                    newData[tabActiveKey] = employeePaymentInsurances;
                    break;
                case 'employeePaymentTaxDeductions':
                    newData[tabActiveKey] = employeePaymentTaxDeductions;
                    break;
                case 'employeePaymentTaxes':
                    newData[tabActiveKey] = employeePaymentTaxes;
                    break;
                case 'employeePaymentTownshipTaxes':
                    newData[tabActiveKey] = employeePaymentTownshipTaxes;
                    break;
                case 'employeePaymentAdditions':
                    newData[tabActiveKey] = employeePaymentAdditions;
                    break;
                case 'employeePaymentDeductions':
                    newData[tabActiveKey] = employeePaymentDeductions;
                    break;
            }
        }
        return newData;
    };

    const employeePaymentCalculate = () => {
        if (employeePaymentId) {
            setVisibleDirtyModal(false);
            setLoading(true);
            let calcStep = calculationStep.indexOf(tabActiveKey);
            let employeePayment = updateDefaultData();
            NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_CALCULATION.CALCULATE, undefined, {
                instance: employeePayment,
                calcStep: calcStep,
            }).then((result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    setDefaultData(undefined);
                    setDirty({
                        employeePaymentGeneral: false,
                        employeePaymentBruts: false,
                        employeePaymentExpense: false,
                        employeePaymentInsurances: false,
                        employeePaymentTaxDeductions: false,
                        employeePaymentTaxes: false,
                        employeePaymentTownshipTaxes: false,
                        employeePaymentAdditions: false,
                        employeePaymentDeductions: false,
                    });
                    okNotification();
                    setRefresh((val) => val + 1);
                    dispatch(refreshItemList());
                } else if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const checkBeforeSave = () => {
        if (dirty[tabActiveKey] && !continueSave) {
            setShowModalSaveButton(true);
            setVisibleDirtyModal(true);
            return false;
        } else {
            setContinueSave(false);
            return true;
        }
    };

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_PAYMENT.EDIT}
                setValues={setValues}
                width={1000}
                form={form}
                goBack={() => history.goBack()}
                paramsId={id}
                fetchWhenChange={refresh}
                {...props}
                normalize={(values: any) => {
                    values = { ...defaultData };
                    values.employeePaymentBruts = cleanEmployeePaymentData(
                        'employeePaymentBruts',
                        employeePaymentBruts
                    );

                    values.employeePaymentExpense = cleanEmployeePaymentData(
                        'employeePaymentExpense',
                        employeePaymentExpense
                    );

                    values.employeePaymentInsurances = cleanEmployeePaymentData(
                        'employeePaymentInsurances',
                        employeePaymentInsurances
                    );

                    values.employeePaymentTaxDeductions = cleanEmployeePaymentData(
                        'employeePaymentTaxDeductions',
                        employeePaymentTaxDeductions
                    );

                    values.employeePaymentTaxes = cleanEmployeePaymentData(
                        'employeePaymentTaxes',
                        employeePaymentTaxes
                    );

                    values.employeePaymentTownshipTaxes = cleanEmployeePaymentData(
                        'employeePaymentTownshipTaxes',
                        employeePaymentTownshipTaxes
                    );

                    values.employeePaymentAdditions = cleanEmployeePaymentData(
                        'employeePaymentAdditions',
                        employeePaymentAdditions
                    );

                    values.employeePaymentDeductions = cleanEmployeePaymentData(
                        'employeePaymentDeductions',
                        employeePaymentDeductions
                    );

                    if (dirty[tabActiveKey]) {
                        values['payrollDirtyStep'] = calculationStep.indexOf(tabActiveKey);
                    }

                    return values;
                }}
                shortcuts={true}
                hideActivationButtons
                hideSubmitButton={lock || !canCreate()}
                customFooterContent={getCustomFooterContent}
                checkBeforeSave={checkBeforeSave}
                triggerSave={triggerSave}
            >
                <Row gutter={24} style={{ marginBottom: '12px' }}>
                    <Col span={24}>
                        <Descriptions title={<h3>{geti18nText('employee.payment.basicData')}</h3>} column={2}>
                            <Descriptions.Item
                                label={
                                    <span style={{ fontWeight: 'bold' }}>
                                        {geti18nText('employee.payment.employee')}
                                    </span>
                                }
                            >
                                {person && person.firstName && person.lastName
                                    ? person.firstName + ' ' + person.lastName
                                    : ''}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <span style={{ fontWeight: 'bold' }}>{geti18nText('employee.payment.oib')}</span>
                                }
                            >
                                {person && person.oib ? person.oib : ''}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <span style={{ fontWeight: 'bold' }}>
                                        {geti18nText('employee.payment.address')}
                                    </span>
                                }
                            >
                                {address && address.settlement && address.settlement.name
                                    ? address.settlement.name + ', '
                                    : ''}
                                {address && address.street ? address.street : ''}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <span style={{ fontWeight: 'bold' }}>
                                        {geti18nText('employee.payment.postOffice')}
                                    </span>
                                }
                            >
                                {postOffice && postOffice.zipCode ? postOffice.zipCode + ' ' : ''}
                                {postOffice && postOffice.name ? postOffice.name : ''}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <span style={{ fontWeight: 'bold' }}>
                                        {geti18nText('employee.payment.workHours')}
                                    </span>
                                }
                            >
                                <span>{workHours ? workHours : ''}</span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <span style={{ fontWeight: 'bold' }}>
                                        {geti18nText('employee.payment.hourlyRate')}
                                    </span>
                                }
                            >
                                <span>{hourlyRate ? numberFormat(hourlyRate) : ''}</span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Tabs type="card" className="tabs-sticky" activeKey={tabActiveKey} onChange={tabOnChange}>
                            <TabPane
                                tab={geti18nText('employee.payment.text.employeePaymentGeneral')}
                                key="employeePaymentGeneral"
                            >
                                <EmployeePaymentGeneral generalData={employeeGeneralData} />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {geti18nText('employee.payment.text.employeePaymentBruts')}
                                        {dirty['employeePaymentBruts'] ? (
                                            <ExclamationCircleTwoTone
                                                twoToneColor="#faad14"
                                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                }
                                key="employeePaymentBruts"
                            >
                                <EmployeePaymentBrut
                                    dataSource={employeePaymentBruts}
                                    setDataSource={setEmployeePaymentBruts}
                                    lock={lock}
                                    historyData={
                                        historyData && historyData['employeePaymentBruts']
                                            ? historyData['employeePaymentBruts']
                                            : null
                                    }
                                />
                            </TabPane>
                            {calcType === 4 && (
                                <TabPane
                                    tab={
                                        <span>
                                            {geti18nText('employee.payment.text.employeePaymentExpense')}{' '}
                                            {dirty['employeePaymentExpense'] ? (
                                                <ExclamationCircleTwoTone
                                                    twoToneColor="#faad14"
                                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    }
                                    key="employeePaymentExpense"
                                >
                                    <EmployeePaymentExpense
                                        dataSource={employeePaymentExpense}
                                        setDataSource={setEmployeePaymentExpense}
                                        lock={lock}
                                        historyData={
                                            historyData && historyData['employeePaymentExpense']
                                                ? historyData['employeePaymentExpense']
                                                : null
                                        }
                                    />
                                </TabPane>
                            )}
                            <TabPane
                                tab={
                                    <span>
                                        {geti18nText('employee.payment.text.employeePaymentInsurances')}{' '}
                                        {dirty['employeePaymentInsurances'] ? (
                                            <ExclamationCircleTwoTone
                                                twoToneColor="#faad14"
                                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                }
                                key="employeePaymentInsurances"
                            >
                                <EmployeePaymentInsurance
                                    dataSource={employeePaymentInsurances}
                                    setDataSource={setEmployeePaymentInsurances}
                                    lock={lock}
                                    historyData={
                                        historyData && historyData['employeePaymentInsurances']
                                            ? historyData['employeePaymentInsurances']
                                            : null
                                    }
                                />
                            </TabPane>
                            {(calcType === 1 || calcType === 2) && (
                                <TabPane
                                    tab={
                                        <span>
                                            {geti18nText('employee.payment.text.employeePaymentTaxDeductions')}{' '}
                                            {dirty['employeePaymentTaxDeductions'] ? (
                                                <ExclamationCircleTwoTone
                                                    twoToneColor="#faad14"
                                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    }
                                    key="employeePaymentTaxDeductions"
                                >
                                    <EmployeePaymentTaxDeduction
                                        dataSource={employeePaymentTaxDeductions}
                                        setDataSource={setEmployeePaymentTaxDeductions}
                                        lock={lock}
                                        historyData={
                                            historyData && historyData['employeePaymentTaxDeductions']
                                                ? historyData['employeePaymentTaxDeductions']
                                                : null
                                        }
                                    />
                                </TabPane>
                            )}
                            <TabPane
                                tab={
                                    <span>
                                        {geti18nText('employee.payment.text.employeePaymentTaxes')}{' '}
                                        {dirty['employeePaymentTaxes'] ? (
                                            <ExclamationCircleTwoTone
                                                twoToneColor="#faad14"
                                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                }
                                key="employeePaymentTaxes"
                            >
                                <EmployeePaymentTax
                                    dataSource={employeePaymentTaxes}
                                    setDataSource={setEmployeePaymentTaxes}
                                    lock={lock}
                                    historyData={
                                        historyData && historyData['employeePaymentTaxes']
                                            ? historyData['employeePaymentTaxes']
                                            : null
                                    }
                                />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {geti18nText('employee.payment.text.employeePaymentTownshipTaxes')}{' '}
                                        {dirty['employeePaymentTownshipTaxes'] ? (
                                            <ExclamationCircleTwoTone
                                                twoToneColor="#faad14"
                                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                }
                                key="employeePaymentTownshipTaxes"
                            >
                                <EmployeePaymentTownshipTax
                                    dataSource={employeePaymentTownshipTaxes}
                                    setDataSource={setEmployeePaymentTownshipTaxes}
                                    lock={lock}
                                    historyData={
                                        historyData && historyData['employeePaymentTownshipTaxes']
                                            ? historyData['employeePaymentTownshipTaxes']
                                            : null
                                    }
                                />
                            </TabPane>
                            {calcType === 1 && (
                                <>
                                    <TabPane
                                        tab={
                                            <span>
                                                {geti18nText('employee.payment.text.employeePaymentAdditions')}{' '}
                                                {dirty['employeePaymentAdditions'] ? (
                                                    <ExclamationCircleTwoTone
                                                        twoToneColor="#faad14"
                                                        style={{ paddingLeft: '5px', marginRight: '0px' }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        }
                                        key="employeePaymentAdditions"
                                    >
                                        <EmployeePaymentAddition
                                            dataSource={employeePaymentAdditions}
                                            setDataSource={setEmployeePaymentAdditions}
                                            lock={lock}
                                            historyData={
                                                historyData && historyData['employeePaymentAdditions']
                                                    ? historyData['employeePaymentAdditions']
                                                    : null
                                            }
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                {geti18nText('employee.payment.text.employeePaymentDeductions')}{' '}
                                                {dirty['employeePaymentDeductions'] ? (
                                                    <ExclamationCircleTwoTone
                                                        twoToneColor="#faad14"
                                                        style={{ paddingLeft: '5px', marginRight: '0px' }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        }
                                        key="employeePaymentDeductions"
                                    >
                                        <EmployeePaymentDeduction
                                            dataSource={employeePaymentDeductions}
                                            setDataSource={setEmployeePaymentDeductions}
                                            employee_id={employeeId}
                                            lock={lock}
                                            historyData={
                                                historyData && historyData['employeePaymentDeductions']
                                                    ? historyData['employeePaymentDeductions']
                                                    : null
                                            }
                                        />
                                    </TabPane>
                                </>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </NyDataEdit>
            {visibleDirtyModal && (
                <Modal visible={visibleDirtyModal} width={420} footer={null} closable={false} maskClosable={false}>
                    <Row gutter={24}>
                        <Result
                            style={{ width: '100%', padding: '20px 0px' }}
                            status="warning"
                            title={
                                geti18nText('app.default.calculate.dirty1') +
                                ' "' +
                                geti18nText('employee.payment.text.' + tabActiveKey) +
                                '" ' +
                                geti18nText('app.default.calculate.dirty2')
                            }
                            subTitle={geti18nText('app.default.calculate.dirty.desc')}
                            extra={[
                                <Button onClick={onDirtyModalCalculate}>{geti18nText('app.default.calculate')}</Button>,
                                <Button onClick={onDirtyModalCancel}>
                                    {geti18nText('app.default.button.cancel')}
                                </Button>,
                                <Button type="primary" onClick={onDirtyModalContinue} hidden={showModalSaveButton}>
                                    {geti18nText('app.default.button.continue')}
                                </Button>,
                                <Button type="primary" onClick={onDirtyModalSave} hidden={!showModalSaveButton}>
                                    {geti18nText('app.default.button.save')}
                                </Button>,
                            ]}
                        />
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default EmployeePaymentEdit;
