import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import MobilizationDocuments from '../../../../components/mobilization-documents/MobilizationDocuments';

const SystematizationDocumentsIndex = () => {
    useHelper('systematizationDocuments/codebooks/mobilizationDocuments');

    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    return (
        <MobilizationDocuments documentType={documentTypeEnum.MILITARY_SYSTEMATIZATION} scroll={{ y: 620, x: 800 }} />
    );
};

export default SystematizationDocumentsIndex;
