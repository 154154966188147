import { geti18nText, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Descriptions, Form, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getEnumArrayStatus,
    getEnumFormat,
    okNotification,
    setEnumFormat,
} from '../../../../../utils/Utils';
import { AssetRights } from '../../../../../rights/assetRights';

const AssetInventoryItemEdit = ({ item, setItem, isModalVisible, setIsModalVisible, setRefresh }: any) => {
    const [itemForm] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState<any>(false);

    useEffect(() => {
        if (item) {
            if (item.inventoryAssetItemStatus) {
                itemForm.setFieldsValue({
                    inventoryAssetItemStatus: setEnumFormat(
                        'INVENTORY_ASSET_ITEM_STATUS',
                        item.inventoryAssetItemStatus
                    ),
                });
            }
        }
    }, [item]);

    const canCreate = () => {
        return AssetRights.canCreateInventory();
    };

    return (
        <>
            {isModalVisible && (
                <Modal
                    title={geti18nText('assetInventory.tab.2')}
                    visible={isModalVisible}
                    maskClosable={false}
                    closable={true}
                    width={350}
                    onCancel={() => {
                        setIsModalVisible(false);
                        setItem(undefined);
                    }}
                    okText={geti18nText('app.default.button.save')}
                    okButtonProps={{ style: { display: canCreate() ? 'inline' : 'none' } }}
                    confirmLoading={confirmLoading}
                    onOk={() => {
                        itemForm
                            .validateFields()
                            .then((values: any) => {
                                setConfirmLoading(true);
                                if (values.inventoryAssetItemStatus) {
                                    values.inventoryAssetItemStatus = getEnumFormat(values.inventoryAssetItemStatus);
                                }
                                if (values.inventoryAssetItemStatus === -1) {
                                    setConfirmLoading(false);
                                    return;
                                }
                                values.ids = [item.id];
                                NyRequestResolver.requestPost(
                                    CONSTANTS_REQ.INVENTORY_ASSET_ITEM.CHANGE_STATUS,
                                    undefined,
                                    values
                                ).then((result: any) => {
                                    if (result && result.status === RESPONSE.OK) {
                                        okNotification();
                                        setItem(undefined);
                                        setIsModalVisible(false);
                                        setRefresh((refresh: number) => refresh + 1);
                                    } else {
                                        errorNotification();
                                    }
                                    setConfirmLoading(false);
                                });
                            })
                            .catch((errorInfo: any) => {
                                console.log(errorInfo);
                            });
                    }}
                >
                    <Form
                        form={itemForm}
                        onFinishFailed={({ errorFields }) => {
                            itemForm.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Descriptions column={1} bordered size={'small'}>
                                    <Descriptions.Item
                                        label={
                                            <span style={{ fontWeight: 'bold' }}>
                                                {geti18nText('assetRelease.table.column.type')}
                                            </span>
                                        }
                                    >
                                        <span>{item && item.assetType ? item.assetType.name : ''}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span style={{ fontWeight: 'bold' }}>
                                                {geti18nText('asset.table.column.assetModel')}
                                            </span>
                                        }
                                    >
                                        <span>{item && item.assetModel ? item.assetModel.name : ''}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span style={{ fontWeight: 'bold' }}>
                                                {geti18nText('asset.table.column.availabilityStatus')}
                                            </span>
                                        }
                                    >
                                        <span>
                                            {item && item.availabilityStatus
                                                ? geti18nText(
                                                      'app.enum.ASSET_AVAILABILITY_STATUS.' + item.availabilityStatus
                                                  )
                                                : ''}
                                        </span>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span style={{ fontWeight: 'bold' }}>
                                                {geti18nText('asset.table.column.serialNumber')}
                                            </span>
                                        }
                                    >
                                        <span>{item && item.serialNumber ? item.serialNumber : ''}</span>
                                    </Descriptions.Item>
                                    {item && item.employee && (
                                        <Descriptions.Item
                                            label={
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {geti18nText('asset.table.column.employee')}
                                                </span>
                                            }
                                        >
                                            <span>
                                                {item && item.employee && item.employee.person
                                                    ? item.employee.person.firstName +
                                                      ' ' +
                                                      item.employee.person.lastName
                                                    : ''}
                                            </span>
                                        </Descriptions.Item>
                                    )}
                                    {item && item.businessUnit && (
                                        <Descriptions.Item
                                            label={
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {geti18nText('asset.table.column.businessUnit')}
                                                </span>
                                            }
                                        >
                                            <span>{item && item.businessUnit ? item.businessUnit.name : ''}</span>
                                        </Descriptions.Item>
                                    )}
                                    <Descriptions.Item
                                        label={
                                            <span style={{ fontWeight: 'bold' }}>
                                                {geti18nText(
                                                    'assetInventoryItem.table.column.inventoryAssetItemStatus'
                                                )}
                                            </span>
                                        }
                                    >
                                        <Form.Item
                                            name="inventoryAssetItemStatus"
                                            style={{ marginBottom: '0px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                options={getEnumArrayStatus('INVENTORY_ASSET_ITEM_STATUS')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                nyTestId="input-status"
                                                style={{ width: '100%' }}
                                                className={''}
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AssetInventoryItemEdit;
