import { CheckCircleOutlined, IssuesCloseOutlined, ShoppingCartOutlined, TabletOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Badge, Card, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssetRights } from '../../../rights/assetRights';
import { RootState } from '../../../rootReducer';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType } from '../../../utils/Utils';

const AssetOrderLightWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);
    const widgetColor = getColorFromNotificationType(settings, 'ASSET_ORDER');

    const hasAssetReleaseRole = () => {
        return AssetRights.canCreateRelease();
    };

    function getAssertKeys() {
        let assertKeys = [];
        assertKeys.push('myAsset');
        assertKeys.push('myOrderAsset');
        assertKeys.push('orderAssetRequested');
        if (hasAssetReleaseRole()) {
            assertKeys.push('orderAssetApproved');
        }
        return {
            keys: assertKeys,
        };
    }

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(CONSTANTS_REQ.DASHBOARD.GET_STATISTICS, undefined, getAssertKeys(), true, false, {
        myAsset: 0,
        myOrderAsset: 0,
        orderAssetRequested: -1,
        orderAssetApproved: -1,
    });

    const gridStyle: any = {
        width: '25%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '8px',
        height: '100%',
        display: 'inline-block',
    };

    return (
        <Card
            className="ny-widget-card"
            title={geti18nText('menu.asset')}
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            bodyStyle={{ height: '55%', overflowY: 'hidden', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
            type="inner"
            extra={
                <Tooltip placement="top" title={geti18nText('menu.webshopAsset')}>
                    <ShoppingCartOutlined
                        className="ny-card-icon"
                        onClick={() => {
                            history.push('/webshop/webshop-asset');
                        }}
                    />
                </Tooltip>
            }
        >
            {loading ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : (
                <>
                    <div style={gridStyle}>
                        <Tooltip title={geti18nText('dashboard.statistic.employee.asset.count')}>
                            <Badge count={statisticData['myAsset']} showZero size="small">
                                <TabletOutlined
                                    style={{ fontSize: '2em' }}
                                    className="statistic-card-light"
                                    onClick={() => history.push('/webshop/webshop-asset/my-asset')}
                                />
                            </Badge>
                        </Tooltip>
                    </div>
                    <div style={gridStyle}>
                        <Tooltip title={geti18nText('dashboard.statistic.asset.order.count')}>
                            <Badge count={statisticData['myOrderAsset']} showZero size="small">
                                <ShoppingCartOutlined
                                    style={{ fontSize: '2em' }}
                                    className="statistic-card-light"
                                    onClick={() => history.push('/webshop/webshop-asset/my-asset-order')}
                                />
                            </Badge>
                        </Tooltip>
                    </div>
                    {statisticData && statisticData['orderAssetRequested'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('dashboard.statistic.asset.order.requested.count')}>
                                <Badge count={statisticData['orderAssetRequested']} showZero size="small">
                                    <IssuesCloseOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => history.push('/webshop/webshop-asset/asset-order')}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {statisticData && statisticData['orderAssetApproved'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('dashboard.statistic.asset.order.approved.count')}>
                                <Badge count={statisticData['orderAssetApproved']} showZero size="small">
                                    <CheckCircleOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => history.push('/webshop/webshop-asset/asset-order')}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

export default AssetOrderLightWidget;
