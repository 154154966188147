import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import {
    getColumnDateOption,
    getColumnSearchCheckbox,
    getColumnSearchOption,
} from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Popover } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import {
    GetEmployeeEducationStatusIcon,
    GetEmployeeLeaveStatusIcon,
    errorNotification,
    getEnumArrayStatus,
    okNotification,
    userIsNotificationRecipient,
} from '../../../../../utils/Utils';
import EmployeeEducationEdit from './edit/edit';
import {
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookSimple,
    getColumnSearchWithCodeAndDescription,
} from '../../../../../utils/Filters';
import RowAction from '../../../../../components/row-action';
import StatusHistoryIndex from '../../../../../components/status-history/StatusHistory';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import useEnum from '../../../../../hooks/useEnum';

const EmployeeEducationIndex = ({
    header = null,
    showHeader = true,
    employeeOverview = false,
    search = undefined,
    activeList = 1,
    refreshView,
    showRowSelection,
    moduleAccessRight,
    employee,
    employeeId,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const [refresh, setRefresh] = useState(0);
    const [visible, setVisible] = useState<boolean>(false);
    const [value, setValue] = useState<any>(null);
    const table = useTableSettings();
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const employeeEducationStatus = useEnum('EMPLOYEE_EDUCATION_STATUS');

    const canViewAll = () => {
        return (
            HumanResourcesRights.isEducationUser() &&
            userIsNotificationRecipient(employee, notificationType.EMPLOYEE_EDUCATION_APPROVAL)
        );
    };

    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const changeStatus = (recordId: any, status: any) => {
        if (recordId) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.EMPLOYEE_EDUCATION.CHANGE_STATUS + '/' + recordId, undefined, {
                id: recordId,
                status: status,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefresh((refresh: any) => refresh + 1);
                    okNotification(geti18nText('employee.education.change.status.success'));
                } else {
                    if (result.data && result.data.error) {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification();
                    }
                }
            });
        }
    };

    const getEducationHistory = (record: any) => {
        return (
            <div>
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="bottomLeft"
                    content={
                        <StatusHistoryIndex
                            id={record.id}
                            type="education"
                            url={CONSTANTS_REQ.EMPLOYEE_EDUCATION_STATUS_HISTORY.LIST_OF_STATUS_HISTORY}
                            params={{ educationEmployeeId: record.id }}
                        />
                    }
                    style={{ marginLeft: '4rem' }}
                >
                    {GetEmployeeEducationStatusIcon(record.status)}
                </Popover>
            </div>
        );
    };

    const columns = [
        ...(!employeeOverview
            ? [
                  {
                      title: geti18nText('employee.education.table.column.id'),
                      dataIndex: 'id',
                      width: '5%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
              ]
            : []),
        ...[
            ...(activeList != 1
                ? [
                      {
                          title: geti18nText('employee.education.table.column.employee'),
                          dataIndex:
                              "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                          sorter: (a: any, b: any) => {},
                          ...getColumnSearchByCodebookEmployee(),
                          render: (person: any, record: any) => {
                              if (record?.employee?.person) {
                                  return (
                                      <>
                                          {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                                          {record.employee &&
                                              record.employee.employmentRecordId &&
                                              ` (${record.employee.employmentRecordId})`}
                                      </>
                                  );
                              }
                          },
                      },
                  ]
                : []),

            {
                title: geti18nText('employee.education.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('employee.education.table.column.organizer'),
                dataIndex: 'organizer',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('employee.education.table.column.educationForm'),
                dataIndex: ['educationForm', 'description'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchWithCodeAndDescription(CONSTANTS_REQ.EDUCATION_FORM.SEARCH, 'educationForm.id'),
                render: (created: any, record: any) => {
                    if (record.educationForm) {
                        return (
                            record.educationForm.description +
                            (record.educationForm.code ? ' (' + record.educationForm.code + ')' : '')
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.education.table.column.applicationDueDate'),
                dataIndex: 'applicationDueDate',
                width: '20%',
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.dateTo) {
                        return moment(record.dateTo).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('employee.education.table.column.dateFrom'),
                dataIndex: 'dateFrom',
                width: '10%',
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.dateFrom) {
                        return moment(record.dateFrom).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('employee.education.table.column.dateTo'),
                dataIndex: 'dateTo',
                width: '10%',
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.dateTo) {
                        return moment(record.dateTo).format('DD.MM.YYYY');
                    }
                },
            },
            ...(activeList != 3
                ? [
                      {
                          title: geti18nText('employee.education.table.column.status'),
                          dataIndex: 'status',
                          width: '270px',
                          sorter: (a: any, b: any) => {},
                          ...getColumnSearchCheckbox(getEnumArrayStatus('EMPLOYEE_EDUCATION_STATUS')),
                          render: (status: any, record: any) => {
                              return getEducationHistory(record);
                          },
                      },
                  ]
                : []),
            ...(!employeeOverview
                ? [
                      {
                          title: geti18nText('employee.education.table.column.active'),
                          dataIndex: 'active',
                          width: '10%',
                          render: (text: any, record: { active: any }) => {
                              if (record.active) {
                                  return <CheckOutlined style={{ color: 'green' }} />;
                              } else {
                                  return <CloseOutlined style={{ color: 'red' }} />;
                              }
                          },
                          ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                          defaultFilteredValue: table.setDefaultFilterValue(),
                      },
                  ]
                : []),
            ...(activeList == 3
                ? [
                      {
                          title: geti18nText('app.default.actions'),
                          dataIndex: 'actions',
                          width: '100px',
                          render: (text: any, record: any) => {
                              if (record?.id && record?.status == employeeEducationStatus.DRAFT && canViewAll()) {
                                  return (
                                      <RowAction
                                          component={'EmployeeEducationApproval'}
                                          record={record}
                                          changeStatus={changeStatus}
                                      />
                                  );
                              }
                          },
                      },
                  ]
                : []),
        ],
    ];

    const addedButtons = () => {
        return (
            <>
                {(activeList == 1 || activeList == 2) && (
                    <Button
                        onClick={() => {
                            setValue(null);
                            setVisible(true);
                        }}
                        type="primary"
                        style={{ float: 'right' }}
                    >
                        {geti18nText('employee.education.add')}
                    </Button>
                )}
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId={'human-education-employee-education-table' + activeList}
                key={activeList}
                url={CONSTANTS_REQ.EMPLOYEE_EDUCATION.LIST}
                headerTitle={showHeader && (header ? header : geti18nText('employee.education.header'))}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                showRowSelection={showRowSelection}
                onRowSelect={(record: any) => {
                    setValue(record);
                    setVisible(true);
                }}
                hideNewButton={true}
                addedButtons={addedButtons}
                shortcuts={true}
                nyTestId="employee-education-table"
                fetchWhenChange={refresh || refreshView}
                exportCSV={true}
                CSVFileName={geti18nText('menu.employee.education')}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
            />
            {visible && (
                <EmployeeEducationEdit
                    visible={visible}
                    setVisible={setVisible}
                    value={value}
                    setValue={setValue}
                    setRefresh={setRefresh}
                    isPersonalView={activeList == 1}
                    moduleAccessRight={moduleAccessRight}
                    employee={employee}
                    employeeId={employeeId}
                />
            )}
        </>
    );
};
export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('employee.education.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('employee.education.table.column.employee'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('employee.education.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('employee.education.table.column.organizer'),
            dataIndex: 'organizer',
        },
        {
            title: geti18nText('employee.education.table.column.educationForm'),
            dataIndex: 'educationForm',
        },
        {
            title: geti18nText('employee.education.table.column.applicationDueDate'),
            dataIndex: 'applicationDueDate',
        },
        {
            title: geti18nText('employee.education.table.column.dateFrom'),
            dataIndex: 'dateFrom',
        },
        {
            title: geti18nText('employee.education.table.column.dateTo'),
            dataIndex: 'dateTo',
        },
        {
            title: geti18nText('employee.education.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('employee.education.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            employee: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            educationForm: (value: any) => {
                return value?.description + ' ' + (value?.code ? ' (' + value.code + ')' : '');
            },
        },
        {
            dateFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            applicationDueDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeEducationIndex;
