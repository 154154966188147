import { useState } from 'react';
import { Modal, Button } from 'antd';
import { NySession, geti18nText } from '@nybble/nyreact';
import { QuestionCircleOutlined } from '@ant-design/icons';

const DocumentationModal = ({ documentUrl, marginRight = '24px' }: any) => {
    const [helpModalVisible, setHelpModalVisible] = useState(false);

    const helpButton = () => {
        setHelpModalVisible(true);
    };

    return (
        <>
            <div style={{ display: 'block', float: 'right', marginRight: marginRight, marginTop: '-2.7px' }}>
                {documentUrl != undefined && (
                    <Button onClick={helpButton} icon={<QuestionCircleOutlined />} style={{ border: 'none' }} />
                )}
            </div>
            {helpModalVisible && (
                <Modal
                    maskClosable={false}
                    title={geti18nText('app.default.help')}
                    visible={helpModalVisible}
                    width={1400}
                    centered={true}
                    onOk={() => setHelpModalVisible(false)}
                    onCancel={() => setHelpModalVisible(false)}
                    footer={null}
                >
                    {documentUrl !== undefined && (
                        <iframe
                            style={{ width: '100%', height: '80vh', border: 'none' }}
                            src={NySession.getSetting('docs') + documentUrl}
                        ></iframe>
                    )}
                </Modal>
            )}
        </>
    );
};

export default DocumentationModal;
