import {
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    getColumnTimeOption,
    geti18nText,
    NyDataTable,
} from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getDateFormat, getEnumArray } from '../../../../utils/Utils';
import moment from 'moment';
import useTableSettings from '../../../../hooks/useTableSettings';

const EmployeeAttendance = ({ employeeId, periodFrom, periodTo, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: employeeId },
            {
                field: 'date',
                condition: 'date_range',
                value: `${getDateFormat(periodFrom, 'yyyy-MM-DD')}, ${getDateFormat(periodTo, 'yyyy-MM-DD')}`,
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('attendance.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.from'),
            dataIndex: 'from',
            ...getColumnTimeOption('HH:mm'),
            render: (from: any) => {
                if (from) {
                    return getDateFormat(from, 'HH:mm');
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.to'),
            dataIndex: 'to',
            ...getColumnTimeOption('HH:mm'),
            render: (to: any) => {
                if (to) {
                    return getDateFormat(to, 'HH:mm');
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.fromReal'),
            dataIndex: 'fromReal',
            ...getColumnTimeOption('HH:mm'),
            render: (fromReal: any) => {
                if (fromReal) {
                    return getDateFormat(fromReal, 'HH:mm');
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.toReal'),
            dataIndex: 'toReal',
            ...getColumnTimeOption('HH:mm'),
            render: (toReal: any) => {
                if (toReal) {
                    return getDateFormat(toReal, 'HH:mm');
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.hours'),
            dataIndex: 'hours',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('ATTENDANCE_TYPE')),
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.ATTENDANCE_TYPE.' + record.type);
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-employee-attendance-table"
            url={CONSTANTS_REQ.ATTENDANCE.LIST}
            showRecordModal={false}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={columns}
            shortcuts={true}
            hideNewButton={true}
            setDefaultPageSize={table.setDefaultPageSize()}
            readonly={true}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
        />
    );
};

export default EmployeeAttendance;
