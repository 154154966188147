import { NyDataTable, NySession, getColumnDateOption, geti18nText } from '@nybble/nyreact';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useEnum from '../../../../../../hooks/useEnum';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { getColumnSearchByHumanCodebookSimple } from '../../../../../../utils/Filters';
import { getDateFormat } from '../../../../../../utils/Utils';
import EmployeeVaccinationEdit from './EmployeeVaccinationEdit';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeVaccinationIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    employeeId,
    showHeader = true,
    scroll = { y: 580, x: 800 },
    sortOrder,
    readonly = false,
}: any) => {
    const table = useTableSettings();
    const { id } = useParams<any>();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [refresh, setRefresh] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const canExportCSV = () => {
        return false;
    };

    const setDefaultFilterValue = () => {
        if (employeeId) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee.id', condition: 'equals', value: id ? id : employeeId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns: any = [
        {
            title: geti18nText('employee.vaccination.table.column.disease'),
            dataIndex: ['disease', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.DISEASE, 'disease.id'),
            render: (text: any, record: any) => {
                if (record.disease) {
                    if (record.disease.code) {
                        return record.disease.name + ' (' + record.disease.code + ') ';
                    } else {
                        return record.disease.name;
                    }
                }
            },
        },
        {
            title: geti18nText('employee.vaccination.table.column.vaccine'),
            dataIndex: ['vaccine', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.VACCINE, 'vaccine.id'),
            render: (text: any, record: any) => {
                if (record.vaccine) {
                    if (record.vaccine.code) {
                        return record.vaccine.name + ' (' + record.vaccine.code + ') ';
                    } else {
                        return record.vaccine.name;
                    }
                }
            },
        },
        {
            title: geti18nText('employee.vaccination.table.column.vaccinationDate'),
            dataIndex: 'vaccinationDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.vaccinationDate) {
                    return getDateFormat(record.vaccinationDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.vaccination.table.column.effectiveUntil'),
            dataIndex: 'effectiveUntil',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.vaccinationDate) {
                    return getDateFormat(record.effectiveUntil, 'DD.MM.YYYY');
                }
            },
        },
    ];

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <NyDataTable
            nyId="human-employee-vaccination-table"
            headerTitle={showHeader ? geti18nText('employee.vaccination.table.header') : null}
            url={CONSTANTS_REQ.EMPLOYEE_VACCINATION.LIST}
            addNewButtonText={geti18nText('employee.vaccination.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            fetchWhenChange={employeeId || id || refresh}
            hideNewButton={!canCreate()}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            modalComponent={EmployeeVaccinationEdit}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            readonly={readonly}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('employee.vaccination.table.header')}
            colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            vaccinationDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            effectiveUntil: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export default EmployeeVaccinationIndex;
