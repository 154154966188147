import { Button, Col, Form, Modal, Row } from 'antd';
import { ENUMS, geti18nText, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getSearchFormat,
    okNotification,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import { useState } from 'react';
import { PlusSquareOutlined } from '@ant-design/icons';

const PayrollInsuranceModal = () => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    const save = () => {
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.employeeIds = [];
                if (values.employees) {
                    values.employeeIds = values.employees.map((val: any) => val.id);
                    delete values.employees;
                }
                values.payrollInsurance = getSearchFormat(values.payrollInsurance);
                if (values.payrollInsurance) {
                    values.payrollInsuranceId = values.payrollInsurance.id;
                    delete values.payrollInsurance;
                }
                values.active = getSearchFormat(values.active);
                if (values.active) {
                    values.active = parseInt(values.active.id);
                }

                NyRequestResolver.requestPost(
                    CONSTANTS_REQ.EMPLOYEE_PAYROLL_INSURANCE.CHANGE_ACTIVE,
                    undefined,
                    values
                ).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        setModalVisible(false);
                        form.resetFields();
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };
    return (
        <>
            <Button
                style={{ float: 'left' }}
                icon={<PlusSquareOutlined />}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                {geti18nText('payrollInsurance.modal.button')}
            </Button>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('payrollInsurance.modal.title')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={() => save()}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.modal.payrollInsurance')}
                                    name="payrollInsurance"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        style={{ width: '100%' }}
                                        url={CONSTANTS_REQ.PAYROLL_INSURANCE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        className=""
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.modal.active')}
                                    name="active"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        options={ENUMS.ACTIVE()}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        style={{ width: '100%' }}
                                        className={''}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('payrollInsurance.modal.employee')} name="employees">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        mode={'multiple'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="payroll-insurance-modal"/>}
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default PayrollInsuranceModal;
