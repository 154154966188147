import { FilePdfOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { NyDataTable, NyUtils, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { MealsRights } from '../../../../../rights/mealsRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { fileDownload, getEnumArrayStatus, getMealOrderStatusIcon } from '../../../../../utils/Utils';
import MealOrderWizard from '../meals-overview/wizard';

const MealOrderIndex = ({
    search,
    listView,
    setListView,
    refreshView,
    setRefreshView,
    employeeId,
    allowOrdersToday = null,
    mealOrderEntryTime = null,
    mealOrderCancelTime = null,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const table = useTableSettings();
    const [orderMealModal, setOrderMealModal] = useState(false);
    const [mealOrder, setMealOrder] = useState<any>(null);
    const enDeliveryType = useEnum('DELIVERY_TYPE');

    useHelper('meals/codebooks/meal-order');

    const canCreate = () => {
        return MealsRights.isMealsAdminOrUser();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdminOrUser();
    };

    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('meal.order.table.column.orderDate'),
                dataIndex: 'orderDate',
                width: '10%',
                sorter: (a: any, b: any) => {},
                render: (text: any, record: any) => {
                    if (record.orderDate) {
                        return moment(record.orderDate).format('DD.MM.YYYY');
                    }
                },
                ...getColumnDateOption(false),
            },
        ],
        ...(AdministrationRights.isAdmin()
            ? [
                  {
                      title: geti18nText('meal.order.table.column.orderNumber'),
                      dataIndex: ['orderNumber'],
                      sorter: (a: any, b: any) => {},

                      ...getColumnSearch('number'),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('meal.order.table.column.restaurant'),
                dataIndex: ['restaurant', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(
                    CONSTANTS_REQ.RESTAURANT.SEARCH,
                    'delivery_spot_restaurant.id',
                    'name',
                    'name',
                    {
                        id: 'id',
                        label: 'name',
                    }
                ),
            },
            {
                title: geti18nText('meal.order.table.column.deliverySpot'),
                dataIndex: [`concat(deliverySpot.name,' ', restaurant.name)`],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (name: any, record: any) => {
                    if (record?.deliverySpotTime?.type == enDeliveryType.DELIVERY && record?.deliverySpot?.name) {
                        return record.deliverySpot.name;
                    } else if (record?.deliverySpotTime?.type == enDeliveryType.PICKUP && record?.restaurant?.name) {
                        return record.restaurant.name;
                    }
                },
            },
            {
                title: geti18nText('meal.order.table.column.deliverySpotTime'),
                dataIndex: ['deliverySpotTime', 'time'],
                sorter: (a: any, b: any) => {},
                width: '12%',
                ...getColumnSearch('string'),
            },
        ],

        ...(!mealSyncTypeHNB
            ? [
                  {
                      title: geti18nText('meal.delivery.table.column.deliverySpot.type'),
                      dataIndex: ['deliverySpotTime', 'type'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchCheckbox(getEnumArrayStatus('DELIVERY_TYPE')),
                      render: (name: any, record: any) => {
                          if (record && record.deliverySpotTime && record.deliverySpotTime.type) {
                              return geti18nText('app.enum.DELIVERY_TYPE.' + record.deliverySpotTime.type);
                          }
                      },
                  },
                  {
                      title: geti18nText('meal.order.table.column.status'),
                      dataIndex: 'status',
                      width: '170px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchCheckbox(getEnumArrayStatus('MEAL_ORDER_STATUS')),
                      render: (status: any) => {
                          return getMealOrderStatusIcon(status);
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('meal.order.table.column.status'),
                      dataIndex: 'status',
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchCheckbox(getEnumArrayStatus('MEAL_ORDER_STATUS')),
                      render: (status: any) => {
                          return getMealOrderStatusIcon(status);
                      },
                  },
              ]),
        ...(mealSyncTypeHNB
            ? [
                  {
                      title: geti18nText('meal.order.table.column.amount'),
                      dataIndex: 'totalAdvance',
                      align: 'right',
                      width: '7%',
                      ...getColumnSearch('number'),
                      render: (text: any, record: any) => {
                          return (
                              NyUtils.formatNumber(record.totalAdvance ?? 0, 2) +
                              ' ' +
                              (record.currency && record.currency.isoCode ? record.currency.isoCode : '')
                          );
                      },
                  },
                  {
                      title: geti18nText('meal.delivery.table.column.bill'),
                      dataIndex: 'billFileId',
                      width: '7%',
                      render: (text: any, record: any) => {
                          if (record) {
                              return (
                                  <div style={{ textAlign: 'center' }}>
                                      {record.billFileId && (
                                          <Tooltip title={geti18nText('meal.delivery.table.column.deliverySpot.bill')}>
                                              <Button
                                                  icon={<FilePdfOutlined />}
                                                  onClick={() => {
                                                      fileDownload(
                                                          CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.billFileId,
                                                          null,
                                                          geti18nText('meal.delivery.table.column.deliverySpot.bill')
                                                      );
                                                  }}
                                              >
                                                  {geti18nText('meal.delivery.table.column.deliverySpot.bill')}
                                              </Button>
                                          </Tooltip>
                                      )}
                                  </div>
                              );
                          }
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('meal.order.table.column.amount'),
                      dataIndex: 'totalPrice',
                      align: 'right',
                      width: '7%',
                      ...getColumnSearch('number'),
                      render: (text: any, record: any) => {
                          return (
                              NyUtils.formatNumber(record.totalPrice ?? 0, 2) +
                              ' ' +
                              (record.currency && record.currency.isoCode ? record.currency.isoCode : '')
                          );
                      },
                  },
              ]),
    ];

    const addedButtons = (onSave: () => void) => {
        return (
            <div style={{ float: 'left', paddingLeft: '8px' }}>
                <Button
                    type="primary"
                    onClick={() => {
                        setListView(0);
                        setRefreshView((refreshView: any) => refreshView + 1);
                    }}
                >
                    <LeftCircleOutlined />
                    {geti18nText('meals.back')}
                </Button>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="meal-order"
                url={CONSTANTS_REQ.MEAL_ORDER.LIST}
                addedButtons={addedButtons}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                editProps={{ employeeId: employeeId }}
                onRowSelect={(data: any) => {
                    setOrderMealModal(true);
                    setMealOrder(data);
                }}
                columns={columns}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? { order: 'orderDate', orderType: 'desc' }}
                hideNewButton={true}
                shortcuts={true}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.meals.order.menu')}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={mealSyncTypeHNB ? csvColumnsHNB() : csvColumns()}
                headerTitle={geti18nText('menu.meals.order.menu')}
            />
            {orderMealModal && (
                <MealOrderWizard
                    visible={orderMealModal}
                    setVisible={setOrderMealModal}
                    refresh={listView}
                    setRefresh={setListView}
                    value={mealOrder}
                    setValue={setMealOrder}
                    allowOrdersToday={allowOrdersToday}
                    mealOrderEntryTime={mealOrderEntryTime}
                    mealOrderCancelTime={mealOrderCancelTime}
                    isAdminForm={false}
                />
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            orderDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.MEAL_ORDER_STATUS.' + value);
            },
        },
        {
            deliverySpotTime: (value: any) => {
                return value != undefined && value.time;
            },
        },
        {
            totalPrice: (value: any) => {
                return NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            totalAdvance: (value: any) => {
                return NyUtils.formatNumber(value ?? 0, 2);
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('meal.order.table.column.orderDate'),
            dataIndex: 'orderDate',
        },
        {
            title: geti18nText('meal.order.table.column.restaurant'),
            dataIndex: ['restaurant', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpot'),
            dataIndex: ['deliverySpot', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpotTime'),
            dataIndex: 'deliverySpotTime',
        },
        {
            title: geti18nText('meal.order.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('meal.order.table.column.amount'),
            dataIndex: 'totalPrice',
        },
    ];
};

export const csvColumnsHNB: any = () => {
    return [
        {
            title: geti18nText('meal.order.table.column.orderDate'),
            dataIndex: 'orderDate',
        },
        {
            title: geti18nText('meal.order.table.column.restaurant'),
            dataIndex: ['restaurant', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpot'),
            dataIndex: ['deliverySpot', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpotTime'),
            dataIndex: 'deliverySpotTime',
        },
        {
            title: geti18nText('meal.order.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('meal.order.table.column.amount'),
            dataIndex: 'totalAdvance',
        },
    ];
};

export default MealOrderIndex;
