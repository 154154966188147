import { MenuOutlined } from '@ant-design/icons';
import { geti18nText, NyColorPicker, NyLanguageSelector, NySession } from '@nybble/nyreact';
import { Col, Row, Switch, Tooltip } from 'antd';
import { forwardRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const Item = forwardRef((props: any, ref) => {
    const [color, setColor] = useState(props.color);
    const [mail, setMail] = useState(props.mail);
    const [mobile, setMobile] = useState(props.mobile);
    const [web, setWeb] = useState(props.web);
    const [language, setLanguage] = useState(props.language);

    const wide = useMediaQuery({ query: '(min-width: 1800px)' });

    useEffect(() => {
        if (props.defNotificationLanguage != '') {
            setLanguage(props.defNotificationLanguage);
        }
    }, [props.defNotificationLanguage]);

    useEffect(() => {
        if (language && language != props.defNotificationLanguage) {
            props.setDefNotificationLanguage('');
        }
    }, [language]);

    return (
        <Row
            className="sortable-item"
            {...props}
            style={{ padding: '0px', cursor: 'grab', ...props.style, alignItems: 'center' }}
            ref={ref}
            gutter={24}
            justify={'end'}
        >
            {props.name && (
                <>
                    <Col span={1}>
                        <span
                            onClick={() => props.setColorChange(true)}
                            style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                            <NyColorPicker
                                popover={true}
                                value={color}
                                onChange={(value: any) => {
                                    setColor(value);
                                    props.handleChangeColor(value, props.id);
                                }}
                                defaultColor={props.color ? props.color : '#d9d9d9'}
                            />
                        </span>
                    </Col>
                    <Col span={9}>
                        <span>{props.name}</span>
                    </Col>
                </>
            )}
            <Col xs={3} sm={3} md={3} lg={3} xl={4} className="ny-sort-switch">
                <Tooltip
                    title={
                        wide
                            ? ''
                            : web
                            ? geti18nText('notification.edit.web')
                            : geti18nText('notification.edit.not.web')
                    }
                >
                    <Switch
                        checked={web}
                        checkedChildren={wide ? geti18nText('notification.edit.web') : ''}
                        unCheckedChildren={wide ? geti18nText('notification.edit.not.web') : ''}
                        onChange={(value: any) => {
                            setWeb(value);
                            props.handleChangeWeb(value, props.id);
                        }}
                    />
                </Tooltip>
            </Col>
            <Col span={4} className="ny-sort-switch">
                <Tooltip
                    title={
                        wide
                            ? ''
                            : mobile
                            ? geti18nText('notification.edit.mobile')
                            : geti18nText('notification.edit.not.mobile')
                    }
                >
                    <Switch
                        checked={mobile}
                        checkedChildren={wide ? geti18nText('notification.edit.mobile') : ''}
                        unCheckedChildren={wide ? geti18nText('notification.edit.not.mobile') : ''}
                        onChange={(value: any) => {
                            setMobile(value);
                            props.handleChangeMobile(value, props.id);
                        }}
                    />
                </Tooltip>
            </Col>
            <Col span={5} className="ny-sort-switch">
                <Tooltip
                    title={
                        wide
                            ? ''
                            : mail
                            ? geti18nText('notification.edit.mail')
                            : geti18nText('notification.edit.not.mail')
                    }
                >
                    <Switch
                        checked={mail}
                        checkedChildren={wide ? geti18nText('notification.edit.mail') : ''}
                        unCheckedChildren={wide ? geti18nText('notification.edit.not.mail') : ''}
                        onChange={(value: any) => {
                            setMail(value);
                            props.handleChangeMail(value, props.id);
                        }}
                    />
                </Tooltip>
                <NyLanguageSelector
                    style={{ marginLeft: '10px', width: '70px', textAlign: 'start' }}
                    disabled={!mail}
                    value={language}
                    languages={NySession.getAppValue('I18N').language.available}
                    onSelect={(value: any) => {
                        setLanguage(value.lang);
                        props.handleChangeLanguage(value, props.id);
                    }}
                />
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={1} />
            <span className="sorted-item-span">
                <MenuOutlined className="ny-sort-icon" {...props.listeners} />
            </span>
        </Row>
    );
});
