import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import TravelFuelTypeIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TravelFuelTypeEdit from './edit';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelFuelTypeSearch = ({ required = true, onChange, disabled = false }: any) => {
    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    return (
        <Form.Item
            label={geti18nText('travel.fuel.type.edit.title')}
            name="travelFuelType"
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.TRAVEL_FUEL_TYPE.SEARCH}
                onChange={onChange ? onChange : null}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                AddNewModalComponent={canCreate() ? TravelFuelTypeEdit : null}
                SearchPopupComponent={<TravelFuelTypeIndex readonly={!canCreate()} />}
                autoFocus={true}
                nyTestId="travelFuelType"
                style={{ width: '100%' }}
                disabled={disabled}
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
            />
        </Form.Item>
    );
};

export default TravelFuelTypeSearch;
