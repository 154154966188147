import {
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    MenuOutlined,
    PlusCircleOutlined,
    SaveTwoTone,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Dropdown, Form, Input, Row, Slider, Space, Table, Tabs } from 'antd';
import Tooltip from 'antd/es/tooltip';
import { arrayMoveImmutable } from 'array-move';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SortEnd, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    getEnumArray,
    getEnumArrayStatus,
    getEnumFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import { BusinessUnitSearch } from '../business-unit/search';
import VocationDescriptionSearch from '../vocation-description/search';
import VocationSearch from '../vocation/search';
import DetailEdit from './DetailEdit';
import EditableCell from './EditableCell';

const WorkplaceEdit = (props: any) => {
    const user = NySession.getUser();
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState(geti18nText('workplace.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [workplace, setWorkplace] = useState<any>(null);
    const [detailEditVisible, setDetailEditVisible] = useState(false);
    const focusInput = useRef<any>(null);

    const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [isCreate, setIsCreate] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const [items, setItems] = useState<any[][]>([[], [], [], [], [], [], [], []]);
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;
    const [nextId, setNextId] = useState<number>(0);
    const [newItemTable, setNewItemTable] = useState<number>(-1);
    const [status, setStatus] = useState<any>(undefined);
    const [createdBy, setCreatedBy] = useState<any>(undefined);
    //points
    const minPointValue: any = 0;
    const maxPointValue: any = 1000;
    const [pointFrom, setPointFrom] = useState<any>(minPointValue);
    const [pointTo, setPointTo] = useState<any>(minPointValue);

    const canCreateAdmin = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canCreateUser = () => {
        return HumanResourcesRights.canOnlyCreateWorkplace();
    };

    const canCreate = () => {
        return canCreateAdmin() || canCreateUser();
    };

    const disabledTabs = () => {
        return isCreate;
    };
    const hideActions = () => {
        return (
            !isCreate &&
            (!canCreate() ||
                activeKey != '1' ||
                (((status && status != enWorkplaceStatus.DRAFT) || user?.id != createdBy?.id) && !canCreateAdmin()))
        );
    };

    const dropdownItems = _.sortBy(getEnumArray('WORKPLACE_CONDITIONS_TYPE'), 'id').map((val: any) => {
        return { label: val.text, key: val.id - 1 };
    });

    const onBusinessUnitChange = (val: any) => {
        if (val && val.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.BUSINESS_UNIT.EDIT + '/' + val?.id, undefined).then(
                (response: any) => {
                    if (response && response.status == RESPONSE.OK && response.data) {
                        form.resetFields(['portfolio', 'sector']);
                        if (
                            response.data.dtoWorkplaceBusinessUnit?.personFirstName &&
                            response.data.dtoWorkplaceBusinessUnit?.personLastName
                        ) {
                            form.setFieldValue(
                                'portfolio',
                                response.data.dtoWorkplaceBusinessUnit.personFirstName +
                                    ' ' +
                                    response.data.dtoWorkplaceBusinessUnit.personLastName
                            );
                        } else if (response.data?.portfolio?.employee?.person) {
                            form.setFieldValue(
                                'portfolio',
                                response.data?.portfolio?.employee?.person?.firstName +
                                    ' ' +
                                    response.data?.portfolio?.employee?.person?.lastName
                            );
                        }
                        if (response.data.businessUnitType?.name) {
                            form.setFieldValue('sector', response.data.businessUnitType.name);
                        }
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (!isCreate && dataForm) {
            let url = CONSTANTS_REQ.WORKPLACE_CONDITIONS.LIST_BY_WORKPLACE;
            url = url.replace('{workplaceId}', dataForm);
            let nextid = 0;
            let items: any[][] = [[], [], [], [], [], [], [], []];
            NyRequestResolver.requestGet(
                url,
                {
                    max: 1000,
                    search: encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }])),
                    order: 'sort',
                    orderType: 'asc',
                },
                true
            ).then((result: any) => {
                if (result && result.status == RESPONSE.OK && result.data.content) {
                    result.data.content.map((val: any) => {
                        const table = val.workplaceConditionsType - 1;
                        val.key = items[table].length;
                        items[table].push(val);
                        nextid = nextid < val.id ? val.id : nextid;
                    });
                }
                setItems([...items]);
                setNextId(nextid + 1);
            });
        }
    }, [isCreate, dataForm]);

    const cancel = (record: any, table: number) => {
        setEditingKey('');
        if (!(record.workplace && record.workplace.id)) {
            deleteRow(record.id, table, true);
            setNewItemTable(-1);
        }
    };

    const deleteRow = (id: any, table: number, isNew: boolean) => {
        if (id) {
            if (!isNew) {
                NyRequestResolver.requestPut(CONSTANTS_REQ.WORKPLACE_CONDITIONS.DEACTIVATE + '/' + id, undefined, {
                    id: id,
                });
            }
            let newItems = items;
            let newData = items[table].filter((row: any) => row.id !== id);
            newItems[table] = newData;
            setItems([...newItems]);
        }
    };

    const save = async (id: any, table: number) => {
        try {
            let row = await tableForm.validateFields();
            const newData = [...items[table]];
            const index = newData.findIndex((item) => id === item.id);

            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });

            row.workplaceConditionsType = table + 1;
            row.workplace = { id: dataForm };
            if (items[table][index].workplaceConditionsType) {
                row.id = id;
                NyRequestResolver.requestPut(CONSTANTS_REQ.WORKPLACE_CONDITIONS.EDIT + '/' + id, undefined, row).then(
                    (response: any) => {
                        if (response.status === RESPONSE.CREATED) {
                            let newItems = items;
                            newItems[table] = newData;
                            setItems([...newItems]);
                            setEditingKey('');
                            NyRequestResolver.requestPost(CONSTANTS_REQ.WORKPLACE_CONDITIONS.CHANGE_SORT, undefined, {
                                workplaceConditionsList: newData.map((value) => {
                                    return { id: value.id };
                                }),
                            });
                        } else {
                            errorNotification();
                        }
                    }
                );
            } else {
                NyRequestResolver.requestPost(CONSTANTS_REQ.WORKPLACE_CONDITIONS.EDIT, undefined, row).then(
                    (response: any) => {
                        if (response.status === RESPONSE.CREATED && response?.data) {
                            newData[index].workplaceConditionsType = response.data.workplaceConditionsType;
                            newData[index].id = response.data.id;
                            newData[index].key = index;
                            let newItems = items;
                            newItems[table] = newData;
                            setItems([...newItems]);
                            setEditingKey('');
                            setNewItemTable(-1);
                        } else {
                            errorNotification();
                        }
                    }
                );
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record, table }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id, table)} disabled={isCreate}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <NyModalConfirm
                        title={geti18nText('app.default.cancel.confirm')}
                        onConfirm={() => {
                            cancel(record, table);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                            <Button type="link" disabled={isCreate}>
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record, table }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                        <Button type="link" disabled={editingKey !== '' || isCreate} onClick={() => edit(record)}>
                            <EditTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                            />
                        </Button>
                    </Tooltip>
                    <NyModalConfirm
                        title={geti18nText('app.default.delete.confirm')}
                        onConfirm={() => {
                            deleteRow(record.id, table, false);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <Button type="link" disabled={editingKey !== '' || isCreate}>
                                <DeleteTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#ff7875"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const conditionsColumns = (type: number) => {
        let columns: any = [];
        switch (type) {
            case 0:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.job'),
                        dataIndex: 'job',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.purpose'),
                        dataIndex: 'purpose',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.responsibility'),
                        dataIndex: 'responsibility',
                        className: 'drag-visible',
                    },
                ];
                break;
            case 1:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.professionalKnowledge'),
                        dataIndex: 'professionalKnowledge',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.level'),
                        dataIndex: 'level',
                        inputType: 'skillLevel',
                        className: 'drag-visible',
                        render: (text: any, record: any) => {
                            return text?.name;
                        },
                    },
                ];
                break;
            case 2:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.skill'),
                        dataIndex: 'skill',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.level'),
                        dataIndex: 'level',
                        inputType: 'skillLevel',
                        className: 'drag-visible',
                        render: (text: any, record: any) => {
                            return text?.name;
                        },
                    },
                ];
                break;
            case 3:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.formalEducation'),
                        dataIndex: 'formalEducation',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.direction'),
                        dataIndex: 'direction',
                        className: 'drag-visible',
                    },
                ];
                break;
            case 4:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.certificates'),
                        dataIndex: 'certificates',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.level'),
                        dataIndex: 'level',
                        inputType: 'skillLevel',
                        className: 'drag-visible',
                        render: (text: any, record: any) => {
                            return text?.name;
                        },
                    },
                ];
                break;
            case 5:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.language'),
                        dataIndex: 'language',
                        inputType: 'language',
                        className: 'drag-visible',
                        render: (text: any, record: any) => {
                            return text?.name;
                        },
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.level'),
                        dataIndex: 'level',
                        inputType: 'languageLevel',
                        className: 'drag-visible',
                        render: (text: any, record: any) => {
                            return text?.name;
                        },
                    },
                ];
                break;
            case 6:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.program'),
                        dataIndex: 'program',
                        className: 'drag-visible',
                    },
                    {
                        title: geti18nText('workplace.conditions.table.column.level'),
                        dataIndex: 'level',
                        inputType: 'programLevel',
                        className: 'drag-visible',
                        render: (text: any, record: any) => {
                            return text?.name;
                        },
                    },
                ];
                break;
            case 7:
                columns = [
                    {
                        title: geti18nText('workplace.conditions.table.column.computerSkills'),
                        dataIndex: 'computerSkills',
                        className: 'drag-visible',
                    },
                ];
                break;
            default:
                columns = [];
        }
        if (canCreateAdmin()) {
            columns = [
                ...columns,
                {
                    title: geti18nText('settings.shortcuts.action'),
                    key: 'action',
                    width: 110,
                    editable: false,
                    render: (text: any, record: any) => {
                        const editable = isEditing(record);
                        return editable ? (
                            <ActionEditable text={text} record={record} table={type} />
                        ) : (
                            <ActionNotEditable text={text} record={record} table={type} />
                        );
                    },
                },
            ];
        }
        return [
            {
                title: '',
                dataIndex: '',
                width: 30,
                className: 'drag-visible',
                editable: false,
                render: () => (type !== newItemTable && canCreateAdmin() ? <DragHandle /> : null),
            },
            ...columns,
        ];
    };
    const mergedColumns = (type: number) =>
        conditionsColumns(type).map((col: any) => {
            if (col.editable === false || !canCreateAdmin()) {
                return col;
            }

            return {
                ...col,
                onCell: (record: any) => {
                    return {
                        record,
                        inputType: col.inputType,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: isEditing(record),
                        form: tableForm,
                    };
                },
            };
        });

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };

        tableForm.resetFields();
        tableForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    function addCondition(type: number) {
        const newData = {
            id: nextId,
        };
        setNextId((prev) => prev + 1);
        let newItems = items;
        newItems[type] = [...items[type], newData];
        setItems([...newItems]);
        edit(newData);
        setNewItemTable(type);
    }

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm?.businessUnit?.name && dataForm?.vocation?.name) {
            setEditHeader(
                geti18nText('workplace.edit.title') +
                    ' - ' +
                    dataForm?.businessUnit?.name +
                    ' - ' +
                    dataForm?.vocation?.name
            );
        }
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }
        onBusinessUnitChange(dataForm.businessUnit);
        if (dataForm.vocation) {
            dataForm.vocation = setSearchFormat(dataForm.vocation, 'name', 'name');
        }
        if (dataForm.vocationDescription) {
            dataForm.vocationDescription = setSearchFormat(dataForm.vocationDescription, 'name', 'name');
        }
        if (dataForm.createdBy) {
            setCreatedBy(dataForm.createdBy);
        }
        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('WORKPLACE_STATUS', dataForm.status);
        }
        setPointFrom(dataForm.pointFrom);
        setPointTo(dataForm.pointTo);
        dataForm.rangePoints = [dataForm.pointFrom, dataForm.pointTo];
        setWorkplace(dataForm);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        tableForm.resetFields();
        setEditingKey('');
        setStatus(undefined);
        setCreatedBy(undefined);
        setNewItemTable(-1);
        setWorkplace(null);
        setEditHeader(geti18nText('workplace.edit.new'));
        setPointFrom(minPointValue);
        setPointTo(minPointValue);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getSearchFormatWithName = function (value: any) {
        if (value === undefined || value === null) {
            return null;
        }

        return value.constructor.name === 'Object'
            ? value.hasOwnProperty('id') && value.id >= 0
                ? { id: value.id, name: value.name }
                : null
            : { id: value };
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && canCreateAdmin() && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => setDetailEditVisible(true)}
                    >
                        {geti18nText('app.default.button.add')}
                    </Button>
                </div>
            )}
        </React.Fragment>
    );

    const clearSearchField = () => {
        form.setFieldsValue({ vocation: null });
    };

    const clearVocationDescriptionSearchField = () => {
        form.setFieldsValue({ vocationDescription: null });
    };
    const onSortEnd = ({ oldIndex, newIndex }: SortEnd, table: number) => {
        if (oldIndex !== newIndex) {
            let newItems = [...items];
            const newData = arrayMoveImmutable(items[table], oldIndex, newIndex);
            newItems[table] = newData;
            setItems([...newItems]);
            NyRequestResolver.requestPost(CONSTANTS_REQ.WORKPLACE_CONDITIONS.CHANGE_SORT, undefined, {
                workplaceConditionsList: newData.map((value) => {
                    return { id: value.id };
                }),
            });
        }
    };

    const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
        <tbody {...props} />
    ));

    const DraggableContainer = (props: any) => {
        const type = props.children[1][0].props.record.workplaceConditionsType;
        const table = type ? type - 1 : newItemTable;
        return (
            <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortEnd={(SortEnd) => onSortEnd(SortEnd, table)}
                {...props}
            />
        );
    };
    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        const type = restProps.children[0].props.record.workplaceConditionsType;
        const index = items[type ? type - 1 : newItemTable]?.findIndex((x: any) => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <NyDataEdit
            layout="vertical"
            // formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.WORKPLACE.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            hideSubmitButton={hideActions()}
            hideActivationButtons={hideActions()}
            setIsCreate={setIsCreate}
            leaveNewModalOpen={canCreateAdmin()}
            normalize={(values: any) => {
                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }
                if (values.businessUnit) {
                    values.businessUnit = getSearchFormatWithName(values.businessUnit);
                }
                if (values.vocation) {
                    values.vocation = getSearchFormatWithName(values.vocation);
                }
                if (values.vocationDescription) {
                    values.vocationDescription = getSearchFormatWithName(values.vocationDescription);
                }
                values.status = getEnumFormat(!canCreateAdmin() ? enWorkplaceStatus.DRAFT : values.status);
                values.pointFrom = pointFrom;
                values.pointTo = pointTo;
                delete values.portfolio;
                delete values.sector;
                delete values.rangePoints;
                return values;
            }}
            customFooterContent={getCustomFooterContent}
        >
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <Tabs.TabPane tab={geti18nText('workplace.tab.general')} key="1">
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={12}>
                            <VocationSearch
                                label={geti18nText('workplace.edit.vocation')}
                                map={{ id: 'id', label: 'name' }}
                                mustGetPopupContainer={false}
                                name="vocation"
                                required={true}
                                SearchPopupDisabled={true}
                                clearSearchField={() => clearSearchField()}
                                disabled={hideActions()}
                            />
                        </Col>
                        <Col span={12}>
                            <BusinessUnitSearch
                                formItemName={'businessUnit'}
                                required={true}
                                onChange={onBusinessUnitChange}
                                disabled={hideActions()}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <VocationDescriptionSearch
                                label={geti18nText('workplace.edit.vocationDescription')}
                                mustGetPopupContainer={false}
                                name="vocationDescription"
                                SearchPopupDisabled={true}
                                clearSearchField={() => clearVocationDescriptionSearchField()}
                                disabled={hideActions()}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('workplace.edit.status')}
                                name="status"
                                initialValue={setEnumFormat('WORKPLACE_STATUS', enWorkplaceStatus.DRAFT)}
                            >
                                <NySearchField
                                    options={getEnumArrayStatus('WORKPLACE_STATUS')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    style={{ width: '100%' }}
                                    disabled={!canCreateAdmin() || hideActions()}
                                    className={''}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('workplace.edit.neededQualification')}
                                name="neededQualification"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('workplace.edit.neededPractice')} name="neededPractice">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} style={{ marginBottom: '45px' }}>
                            <Form.Item
                                name={'rangePoints'}
                                label={
                                    geti18nText('workplace.edit.pointFrom') +
                                    ' (' +
                                    pointFrom +
                                    ') - ' +
                                    geti18nText('workplace.edit.pointTo') +
                                    ' (' +
                                    pointTo +
                                    ')'
                                }
                            >
                                <Slider
                                    range
                                    min={minPointValue}
                                    max={maxPointValue}
                                    value={[pointFrom, pointTo]}
                                    defaultValue={[pointFrom, pointTo]}
                                    onChange={(value: any) => {
                                        if (value) {
                                            if (value[0]) {
                                                setPointFrom(value[0]);
                                            }
                                            if (value[1]) {
                                                setPointTo(value[1]);
                                            }
                                        }
                                    }}
                                    //tooltip={{ open: activeKey == '1', placement: 'bottom' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={'portfolio'} label={geti18nText('workplace.edit.portfolio')}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'sector'} label={geti18nText('workplace.table.column.sector')}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('workplace.tab.details')} key="2" disabled={disabledTabs()}>
                    <NyDataTable
                        nyId="human-workplace-details"
                        rowKey="id"
                        url={CONSTANTS_REQ.WORKPLACE_DETAILS.LIST}
                        showRecordModal={true}
                        modalComponent={canCreateAdmin() ? DetailEdit : undefined}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        addedData={{ workplaceId: dataForm, workplace: workplace }}
                        editProps={{ disabled: true }}
                        hideButtons
                        scroll={props?.editProps?.scroll}
                        setDefaultFilterValue={() => [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'workplace.id', condition: 'equals', value: dataForm },
                        ]}
                        fetchWhenChange={refreshTable}
                        setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                        columns={[
                            {
                                title: geti18nText('workplaceDetails.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.firstName'),
                                dataIndex: ['person', 'firstName'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.lastName'),
                                dataIndex: ['person', 'lastName'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.employmentRecordId'),
                                dataIndex: ['employee', 'employmentRecordId'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                        ]}
                    />
                    {React.createElement(
                        DetailEdit,
                        {
                            isModal: true,
                            visible: detailEditVisible,
                            setVisible: setDetailEditVisible,
                            onSaveAndGetData: () => setRefreshTable(refreshTable + 1),
                            addedData: { workplaceId: dataForm, workplace: workplace },
                            editProps: { disabled: true },
                        },
                        null
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('workplace.tab.conditions')} key="3" disabled={disabledTabs()}>
                    {canCreateAdmin() && (
                        <div style={{ height: '32px' }}>
                            <Dropdown
                                trigger={['click']}
                                menu={{
                                    items: dropdownItems,
                                    onClick: (item) => addCondition(Number(item.key)),
                                    disabled: editingKey !== '',
                                }}
                            >
                                <Button icon={<PlusCircleOutlined />} style={{ float: 'right' }}>
                                    <span style={{ margin: '0 50px' }}>
                                        {geti18nText('workplace.edit.conditions.add')}
                                    </span>
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                    <div style={{ maxHeight: '500px', overflowX: 'hidden', overflowY: 'scroll' }}>
                        <Row gutter={[24, 24]}>
                            {items.map((val: Array<any>, idx: number) => {
                                let url = CONSTANTS_REQ.WORKPLACE_CONDITIONS.LIST_BY_TYPE;
                                url = url.replace('{workplaceId}', dataForm);
                                url = url.replace('{workplaceConditionsType}', idx + 1);
                                return val.length ? (
                                    <Col span={24}>
                                        <Form form={tableForm} component={false}>
                                            <Table
                                                components={{
                                                    body: {
                                                        wrapper: DraggableContainer,
                                                        row: DraggableBodyRow,
                                                        cell: EditableCell,
                                                    },
                                                }}
                                                tableLayout="fixed"
                                                size="small"
                                                dataSource={items[idx]}
                                                columns={mergedColumns(idx)}
                                                rowClassName="editable-row"
                                                pagination={false}
                                            />
                                        </Form>
                                    </Col>
                                ) : null;
                            })}
                        </Row>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default WorkplaceEdit;
