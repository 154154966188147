import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';

interface IShortcutsSettingsState {
    keyMap: any;
}

const initialState: IShortcutsSettingsState = {
    keyMap: {},
};

const shortcutsSettings = createSlice({
    name: 'shortcutsSettings',
    initialState,
    reducers: {
        shortcutsSettingsInit(state) {
            state.keyMap = {};
        },
        setShortcutsSettings(state, action: PayloadAction<IShortcutsSettingsState>) {
            const { keyMap } = action.payload;
            state.keyMap = keyMap;
        },
    },
});

export const { shortcutsSettingsInit, setShortcutsSettings } = shortcutsSettings.actions;

export default shortcutsSettings.reducer;

export const fetchShortcutsSettings = (): AppThunk => async (dispatch) => {
    const settingKey = 'shortcuts';
    const result: any = await NyRequestResolver.requestGet(
        CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey,
        { settingKey: settingKey }
    );
    if (result.status == RESPONSE.OK) {
        let data = result.data;
        if (data && data.settingValue) {
            let settingsValue = JSON.parse(data.settingValue);
            delete settingsValue.id;
            dispatch(setShortcutsSettings(settingsValue));
        }
    }
};
