import { geti18nText, NyDataEdit, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHelper from '../../../../../hooks/useHelper';
import EmployeeVaccinationIndex from './employee_vaccination/EmployeeVaccinationIndex';
import EmployeeBloodDonationIndex from './blood-donation';
import EmployeeEmploymentInjuryIndex from './employee-work-injury/EmployeeEmploymentInjuryIndex';
import EmployeeDisabilityIndex from './employee-disability';
import EmployeeHealthExaminationIndex from './employee-health-examination';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification } from '../../../../../utils/Utils';

const { TabPane } = Tabs;

const EmployeeHealthCheckup = ({ readonly = false }) => {
    const { id } = useParams<any>();
    useHelper('human/employee');
    const [tabKey, setTabKey] = useState<any>('examination');
    const [form] = Form.useForm();
    const [dataForm, setDataForm] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [securityNumber, setSecurityNumber] = useState('');

    function setValues(dataForm: any) {
        form.setFieldsValue(dataForm);
    }

    useEffect(() => {
        fetchEmployeeSecurityNumber();
    }, [id]);

    const fetchEmployeeSecurityNumber = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setSecurityNumber(result.data.healthSecurityNumber);
                }
            } else {
                errorNotification();
            }
        });
    };

    return (
        <>
            <p>
                {geti18nText('employee.edit.healthSecurityNumber') + ': '} <b>{securityNumber ?? ' - '}</b>
            </p>
            {/*
            <NyDataEdit
                setLoading={setLoading}
                loading={loading}
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                url={CONSTANTS_REQ.EMPLOYEE.EDIT}
                setValues={setValues}
                // width={600}
                form={form}
                hideCancelButton={true}
                hideActivationButtons={true}
                paramsId={id}
                normalize={(values: any) => {
                    return values;
                }}
                nyTestId="employee-checkup-edit"
                setData
            >
                <Row gutter={24}>
                    <Form.Item name={'id'} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={3}>
                        <Form.Item name={'health_security_number'} label={'HZZO broj'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label={'posebni uvjeti(zakon)'}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label={'posebni uvjeti(HNB)'}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label={'rad na racunalu'}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
            </NyDataEdit>
             */}
            <Tabs
                activeKey={tabKey}
                type="card"
                className="tabs-sticky"
                id="employee-edit-tabs"
                onChange={(key: any) => setTabKey(key)}
            >
                <TabPane tab={geti18nText('employee.tab.healthExamination')} key="examination">
                    <EmployeeHealthExaminationIndex employeeId={id} showHeader={false} readonly={readonly} />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.disability')} key="disability">
                    <EmployeeDisabilityIndex employeeId={id} showHeader={false} readonly={readonly} />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.employmentInjury')} key="workInjury">
                    <EmployeeEmploymentInjuryIndex employeeId={id} readonly={readonly} />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.vaccination')} key="vaccination">
                    <EmployeeVaccinationIndex employeeId={id} showHeader={false} readonly={readonly} />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.blood.donation')} key="bloodDonation">
                    <EmployeeBloodDonationIndex id={id} readonly={readonly} />
                </TabPane>
            </Tabs>
        </>
    );
};

export default EmployeeHealthCheckup;
