import { StarFilled, UserOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, List, Row, Input, Card, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../rootReducer';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import EmployeeCard from './EmployeeCard';

const { Search } = Input;

let timeout: any = null;

const DirectoryWidget = () => {
    const history = useHistory();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const dispatch = useDispatch();
    const [showFavorite, setShowFavorite] = useState<any>(false);
    const { employees } = useSelector((state: RootState) => state.favorite);
    const [loading, setLoading] = useState<any>(false);
    const [employeeList, setEmployeeList] = useState<any>([]);
    const [filterSearch, setFilterSearch] = useState<any>(null);
    const [statisticData, setStatisticData] = useState({
        direcotryCount: 0,
    });

    useEffect(() => {
        getStatistics();
    }, []);

    useEffect(() => {
        setEmployeeList(showFavorite ? employees : []);
    }, [showFavorite]);

    useEffect(() => {
        if (showFavorite) {
            setEmployeeList(employees);
        }
    }, [employees]);

    useEffect(() => {
        setShowFavorite(false);
        if (filterSearch) {
            fetch();
        } else {
            setEmployeeList([]);
        }
    }, [filterSearch]);

    const getStatistics = () => {
        // Directory
        NyRequestResolver.requestPost(CONSTANTS_REQ.DASHBOARD.GET_STATISTICS, undefined, ['direcotryCount']).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data) {
                        setStatisticData(result.data);
                    }
                }
            }
        );
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const fetch = () => {
        setLoading(true);
        let defaultFilter = setDefaultFilterValue();
        if (filterSearch) {
            defaultFilter.push({
                field: "concat(person.first_name,' ', person.last_name)",
                condition: 'contains',
                value: filterSearch,
            });
        }

        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.DIRECTORY_LIST, {
            search: encodeURI(JSON.stringify(defaultFilter)),
            offset: 0,
            max: 100,
            order: "concat(person.last_name,' ', person.first_name)",
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    const responseData: any = result.data;
                    setEmployeeList(responseData.content);
                }
            }
            setLoading(false);
        });
    };

    const onSearch = (value: any) => {
        timeoutClear();
        setFilterSearch(value);
    };

    const onChange = (value: any) => {
        timeoutClear();
        timeout = setTimeout(() => {
            setFilterSearch(value.target.value);
        }, 1000);
    };

    const timeoutClear = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '10px',
    };

    return (
        <Card
            className="ny-widget-card"
            title={geti18nText('menu.directory')}
            bodyStyle={{ padding: '5px 5px 0px 5px' }}
            type="inner"
        >
            <Card.Grid
                style={{
                    width: '49.5%',
                    marginRight: '0.5%',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    marginBottom: '5px',
                    padding: '10px',
                }}
                className={`statistic-card`}
                onClick={() => history.push('/directory')}
            >
                <Statistic value={statisticData['direcotryCount']} prefix={<UserOutlined />} />
            </Card.Grid>
            <Card.Grid
                style={{
                    width: '49.5%',
                    marginLeft: '0.5%',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    marginBottom: '5px',
                    padding: '10px',
                }}
                className={`statistic-card`}
                onClick={() => setShowFavorite((showFavorite: any) => !showFavorite)}
            >
                <Statistic
                    value={employees ? employees.length : 0}
                    prefix={<StarFilled style={{ color: '#ffd903' }} />}
                />
            </Card.Grid>
            <Card.Grid className="ny-card-static" style={gridStyle}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Search
                            onSearch={onSearch}
                            onChange={onChange}
                            placeholder={geti18nText('app.default.search')}
                            allowClear
                            style={{
                                width: '100%',
                                marginBottom: employeeList && employeeList.length > 0 ? '10px' : '0px',
                            }}
                        />
                        <List
                            style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}
                            grid={{
                                gutter: 12,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 1,
                                xl: 1,
                                xxl: 1,
                            }}
                            loading={loading}
                            dataSource={employeeList}
                            renderItem={(employee: any) => (
                                <List.Item className="ny-list-item">
                                    <EmployeeCard
                                        favorite={true}
                                        employee={employee}
                                        cardStyle={{ width: '100%', height: '100%' }}
                                    />
                                </List.Item>
                            )}
                            className="ny-directory-widget-list"
                        />
                    </Col>
                </Row>
            </Card.Grid>
        </Card>
    );
};

export default DirectoryWidget;
