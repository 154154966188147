import { NySession } from '@nybble/nyreact';
export const MealsRights = (function () {
    return {
        isMealsAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_MEALS_ADMIN']);
        },
        isMealsUser: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_MEALS']);
        },
        isOnlyMealsUser: () => {
            return NySession.hasAnyRole(['ROLE_MEALS']);
        },
        isMealsAdminOrUser: () => {
            return MealsRights.isMealsAdmin() || MealsRights.isMealsUser();
        },
        mealSyncRight: () => {
            return NySession.hasAnyRole(['ROLE_MEALS_SYNC_EDIT']);
        },
        ///////////////////
        //menu
        isOnlyMealsUserMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_MEALS_ADMIN', 'ROLE_MEALS'];
        },
        isMealsAdminMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_MEALS_ADMIN'];
        },
    };
})();
