import { CloudDownloadOutlined, DeleteOutlined, EditTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Row, Space, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    fileDownload,
    formatJoppdCodeSearch,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const PayrollBrutAdditionEdit = (props: any) => {
    const [isCreate, setIsCreate] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const history = useHistory();
    const { id } = useParams<any>();
    const [form] = Form.useForm();
    const [employeeForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const brutValueTypeEnum = useEnum('PAYROLL_BRUT_VALUE_TYPE');
    const [valueType, setValueType] = useState(null);
    const [editHeader, setEditHeader] = useState(geti18nText('payrollBrut.addition.edit.new'));
    const [dataForm, setDataForm] = useState<any>(null);
    const [isUpdated, setIsUpdated] = useState<any>(null);

    const [allEmployees, setAllEmployees] = useState<boolean>(false);
    const [showOnContract, setShowOnContract] = useState<boolean>(false);
    const [excludeForNonWorkActivity, setExcludeForNonWorkActivity] = useState<boolean>(false);

    const [selectedValuesDelete, setSelectedValuesDelete] = useState<any>([]);
    const [hasSelectedDelete, setHasSelectedDelete] = useState(false);
    const [selectedValuesAdd, setSelectedValuesAdd] = useState<any>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(false);
    const [isAddListModalVisible, setIsAddListModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [employeeEditTitle, setEmployeeEditTitle] = useState(geti18nText('payrollBrut.table.column.employee'));

    const [loadingModal, setLoadingModal] = useState(false);
    const [activeKey, setAcitveKey] = useState('1');
    const [isFormChange, setIsFormChange] = useState(false);
    const [isFilesChanged, setIsFilesChanged] = useState(false);

    const [files, setFiles] = useState<any>([]);
    const [existingFiles, setExistingFiles] = useState<any>([]);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [employeeId, setEmployeeId] = useState<any>(null);
    const table = useTableSettings();

    const showAllExtraData = () => {
        //hide employees tabs and allEmployees checkbox - pointAdditionDecision component in use
        return false;
    };

    const valueTypeIsCoefficient = () => {
        return valueType != null && valueType == brutValueTypeEnum.COEFFICIENT;
    };

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks() || HumanResourcesRights.canCreateCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('payrollBrut.addition.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '6.2.');
        }
        if (dataForm.joppdEmployeeCode) {
            dataForm.joppdEmployeeCode = await formatJoppdCodeSearch(dataForm.joppdEmployeeCode, 2, '6.1.');
        }
        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom, 'yyyy-MM-DD');
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo, 'yyyy-MM-DD');
        }
        if (dataForm.valueType) {
            dataForm.valueType = setEnumFormat('PAYROLL_BRUT_VALUE_TYPE', dataForm.valueType);
        }
        if (dataForm.baseType) {
            dataForm.baseType = setEnumFormat('PAYROLL_BRUT_BASE_TYPE', dataForm.baseType);
        }
        setAllEmployees(dataForm.allEmployees ? true : false);
        setShowOnContract(dataForm.showOnContract ? true : false);
        setExcludeForNonWorkActivity(dataForm.excludeForNonWorkActivity ? true : false);

        if (dataForm.createdBy) {
            dataForm.createdBy = dataForm.createdBy?.person?.firstName + ' ' + dataForm.createdBy?.person?.lastName;
        }
        if (dataForm.updatedBy) {
            setIsUpdated(true);
            dataForm.updatedBy = dataForm.updatedBy?.person?.firstName + ' ' + dataForm.updatedBy?.person?.lastName;
        }
        if (dataForm.created) {
            dataForm.created = setDateFormat(dataForm.created);
        }
        if (dataForm.updated) {
            dataForm.updated = setDateFormat(dataForm.updated);
        }
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payrollBrut.addition.edit.new'));
        setDataForm(null);
        setValueType(null);
        setAllEmployees(false);
        setShowOnContract(false);
        setExcludeForNonWorkActivity(false);
        setIsUpdated(false);
        setRefreshTable((refreshTable) => refreshTable + 1);
        setAcitveKey('1');
        setIsFormChange(false);
    };

    const setJoppdDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '6.2.');
    };

    const setJoppdEmployeeDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '6.1.');
    };

    const onValueTypeChange = (value: any) => {
        setValueType(value && value.id ? value.id : brutValueTypeEnum.COEFFICIENT);
    };

    const disabledValidFromDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return moment(current) > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    const disabledValidToDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const disabledValidFromDateEmployee = (current: any) => {
        if (employeeForm.getFieldValue('validTo')) {
            return moment(current) > moment(employeeForm.getFieldValue('validTo')).endOf('day');
        }
    };

    const disabledValidToDateEmployee = (current: any) => {
        if (employeeForm.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(employeeForm.getFieldValue('validFrom')).endOf('day');
        }
    };

    let clearKeysDelete: any;

    const setSelectedValuesFuncDelete = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDelete(hasSelected);
        setSelectedValuesDelete(selectedRows);
        clearKeysDelete = clearSelectedRowKeys;
    };

    let clearKeysAdd: any;

    const setSelectedValuesFuncAdd = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAdd(hasSelected);
        setSelectedValuesAdd(selectedRowKeys);
        clearKeysAdd = clearSelectedRowKeys;
    };

    const save = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.PAYROLL_BRUT.ADD_EMPLOYEES, undefined, {
            payrollBrutId: form.getFieldValue('id'),
            employeesId: selectedValuesAdd,
        }).then((result: any) => {
            if (setLoadingModal) setLoadingModal(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAdd) {
                    clearKeysAdd();
                }
                okNotification();
                setIsAddListModalVisible(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromGroup = () => {
        const res = selectedValuesDelete.map((value: any) => {
            return value.employee.id;
        });

        NyRequestResolver.requestPut(CONSTANTS_REQ.PAYROLL_BRUT.REMOVE_EMPLOYEES, undefined, {
            payrollBrutId: form.getFieldValue('id'),
            employeesId: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDelete) {
                    clearKeysDelete();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const saveEmployee = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);
        employeeForm
            .validateFields()
            .then((values: any) => {
                let newValues: any = {};
                newValues.id = values.id;
                newValues.employee = { id: employeeId };
                newValues.payrollBrut = { id: dataForm };
                newValues.fileId = files && files[0] && files[0].fileId ? files[0].fileId : null;
                if (values.validFrom) newValues.validFrom = getDateFormat(values.validFrom, 'yyyy-MM-DD HH:mm');
                if (values.validTo) newValues.validTo = getDateFormat(values.validTo, 'yyyy-MM-DD HH:mm');

                NyRequestResolver.requestPut(
                    CONSTANTS_REQ.PAYROLL_BRUT.EMPLOYEE + '/' + values.id,
                    undefined,
                    newValues
                ).then((result: any) => {
                    if (setLoadingModal) setLoadingModal(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        closeEmployeeModal();
                        setRefreshTable((refreshTable) => refreshTable + 1);
                    } else {
                        errorNotification(geti18nText('app.default.save.nok'));
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    const edit = (record: any) => {
        setIsFilesChanged(false);
        if (record && record.id) {
            setEmployeeId(record.employee && record.employee.id ? record.employee.id : null);
            NyRequestResolver.requestGet(CONSTANTS_REQ.PAYROLL_BRUT.EMPLOYEE + '/' + record.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        setIsEditModalVisible(true);
                        let valuesEmployee: any = {};
                        valuesEmployee.id = record.id;
                        if (result.data.validFrom)
                            valuesEmployee.validFrom = setDateFormat(result.data.validFrom, 'yyyy-MM-DD');
                        if (result.data.validTo)
                            valuesEmployee.validTo = setDateFormat(result.data.validTo, 'yyyy-MM-DD');

                        let employeeTitle = employeeEditTitle;
                        if (record && record.person && record.person.firstName && record.person.lastName) {
                            employeeTitle =
                                employeeTitle + ' ' + record.person.firstName + ' ' + record.person.lastName;
                        }
                        if (record && record.employee && record.employee.employmentRecordId) {
                            employeeTitle = employeeTitle + ' (' + record.employee.employmentRecordId + ')';
                        }
                        setEmployeeEditTitle(employeeTitle);
                        if (record.employee && record.employee.id) setValuesFiles(dataForm, record.employee.id);
                        employeeForm.setFieldsValue(valuesEmployee);
                    }
                }
            );
        }
    };

    const closeEmployeeModal = () => {
        setIsEditModalVisible(false);
        setFiles([]);
        setExistingFiles([]);
        setRefreshFiles(0);
        setEmployeeId(null);
        setEmployeeEditTitle(geti18nText('payrollBrut.table.column.employee'));
        employeeForm.resetFields();
    };

    const setDefaultFilterValueFiles = (dataId: any, employeeId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'ref_id', condition: 'equals', value: dataId },
            { field: 'employee_id', condition: 'equals', value: employeeId },
            { field: 'document_type', condition: 'equals', value: documentTypeEnum.PAYROLL_BRUT_EMPLOYEE },
        ];
    };

    function setValuesFiles(dataId: any, employeeId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(dataId, employeeId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(result.data.content);
                    setExistingFiles(result.data.content);
                } else {
                    setFiles([]);
                    setExistingFiles([]);
                }
            }
        });
    }

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && showAllExtraData() && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisible(true);
                        }}
                    >
                        {geti18nText('payrollBrut.table.addEmployee')}
                    </Button>
                    <Popconfirm
                        title={geti18nText('payrollBrut.table.remove.confirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDelete}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDelete}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('payrollBrut.table.remove')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    const getGeneralEditData = (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }} hidden>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('payrollBrut.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.edit.joppdEmployeeCode')}
                                name="joppdEmployeeCode"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                    setDefaultFilterValue={setJoppdEmployeeDefaultFilterValue}
                                    map={{ id: 'id', label: 'code' }}
                                    searchBy="code"
                                    nyTestId="joppd-employee-code"
                                    SearchPopupComponent={
                                        <JoppdCodeIndex defaultFilteredValue={setJoppdEmployeeDefaultFilterValue} />
                                    }
                                    itemName="code"
                                    addNewItem={true}
                                    order="code"
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.edit.joppdCode')}
                                name="joppdCode"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                    setDefaultFilterValue={setJoppdDefaultFilterValue}
                                    map={{ id: 'id', label: 'code' }}
                                    searchBy="code"
                                    nyTestId="joppd-income-code"
                                    SearchPopupComponent={
                                        <JoppdCodeIndex defaultFilteredValue={setJoppdDefaultFilterValue} />
                                    }
                                    itemName="code"
                                    addNewItem={true}
                                    order="code"
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {dataForm && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollBrut.edit.created')} name="created">
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        disabled
                                        format="DD.MM.YYYY HH:mm"
                                        mustGetPopupContainer={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollBrut.edit.createdBy')} name="createdBy">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            {isUpdated && (
                                <Col span={12}>
                                    <Form.Item label={geti18nText('payrollBrut.edit.updated')} name="updated">
                                        <NyDatePicker
                                            style={{ width: '100%' }}
                                            disabled
                                            format="DD.MM.YYYY HH:mm"
                                            mustGetPopupContainer={false}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {isUpdated && (
                                <Col span={12}>
                                    <Form.Item label={geti18nText('payrollBrut.edit.updatedBy')} name="updatedBy">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('payrollBrut.addition.edit.description')} name="description">
                                <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.addition.edit.validFrom')}
                                name="validFrom"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NyDatePicker
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }}
                                    disabledDate={disabledValidFromDate}
                                    defaultPickerValue={moment()}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('payrollBrut.addition.edit.validTo')} name="validTo">
                                <NyDatePicker
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }}
                                    disabledDate={disabledValidToDate}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.addition.edit.valueType')}
                                name="valueType"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    ref={focusInput}
                                    options={getEnumArray('PAYROLL_BRUT_VALUE_TYPE')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    className=""
                                    onChange={onValueTypeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.addition.edit.value')}
                                name="value"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={showAllExtraData() ? 12 : 24}>
                            <Form.Item
                                label={geti18nText('payrollBrut.addition.edit.baseType')}
                                name="baseType"
                                rules={[
                                    {
                                        required: valueTypeIsCoefficient(),
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    ref={focusInput}
                                    options={getEnumArray('PAYROLL_BRUT_BASE_TYPE')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    className=""
                                />
                            </Form.Item>
                        </Col>
                        {showAllExtraData() && (
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollBrut.addition.edit.allEmployees')}
                                    name={'allEmployees'}
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        checked={allEmployees}
                                        onChange={(e) => {
                                            setAllEmployees(e.target.checked);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.addition.edit.showOnContract')}
                                name={'showOnContract'}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    checked={showOnContract}
                                    onChange={(e) => {
                                        setShowOnContract(e.target.checked);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollBrut.addition.edit.excludeForNonWorkActivity')}
                                name={'excludeForNonWorkActivity'}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    checked={excludeForNonWorkActivity}
                                    onChange={(e) => {
                                        setExcludeForNonWorkActivity(e.target.checked);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );

    const getEmployeData: any = (
        <>
            {' '}
            <NyDataTable
                nyId="human-payroll-brut-addition-table"
                rowKey="id"
                url={CONSTANTS_REQ.PAYROLL_BRUT.EMPLOYEES}
                addedData={{ payrollBrutId: form.getFieldValue('id') }}
                showRowSelection
                readonly
                hideButtons
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                scroll={{ y: 400, x: 500 }}
                rowSelectionType={'checkbox'}
                rowSelectionModal={setSelectedValuesFuncDelete as any}
                fetchWhenChange={refreshTable}
                columns={[
                    {
                        title: geti18nText('payrollBrut.table.column.id'),
                        dataIndex: ['employee', 'id'],
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payrollBrut.table.column.employee'),
                        dataIndex:
                            "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (person: any, record: any) => {
                            if (record && record.person) {
                                return (
                                    <>
                                        {`${record.person.firstName} ${record.person.lastName} `}
                                        {record.employee &&
                                            record.employee.employmentRecordId &&
                                            ` (${record.employee.employmentRecordId})`}
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('payrollBrut.table.column.validFrom'),
                        dataIndex: 'validFrom',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(false),
                        render: (validFrom: any, record: any) => {
                            if (record.validFrom) {
                                return getDateFormat(record.validFrom, 'DD.MM.YYYY');
                            }
                        },
                    },
                    {
                        title: geti18nText('payrollBrut.table.column.validTo'),
                        dataIndex: 'validTo',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(false),
                        render: (validTo: any, record: any) => {
                            if (record.validTo) {
                                return getDateFormat(record.validTo, 'DD.MM.YYYY');
                            }
                        },
                    },
                    {
                        title: geti18nText('fileList.table.column.file.name'),
                        dataIndex: 'fileId',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: any, record: any) => {
                            if (record.fileId) {
                                return (
                                    <React.Fragment>
                                        <Space size="middle" style={{ textAlign: 'right' }}>
                                            <Tooltip title={geti18nText('app.default.download')}>
                                                <CloudDownloadOutlined
                                                    style={{ marginLeft: '10px', fontSize: '16px' }}
                                                    onClick={() => {
                                                        fileDownload(
                                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                                            null,
                                                            editHeader +
                                                                '_' +
                                                                record?.person?.firstName +
                                                                '_' +
                                                                record?.person?.lastName
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                        </Space>
                                    </React.Fragment>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('settings.shortcuts.action'),
                        key: 'action',
                        width: '100px',
                        render: (text: any, record: any) => {
                            if (record) {
                                return (
                                    <React.Fragment>
                                        <Space size="middle" style={{ textAlign: 'right' }}>
                                            <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                                <Button type="link" onClick={() => edit(record)}>
                                                    <EditTwoTone
                                                        style={{
                                                            fontSize: '20px',
                                                        }}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Space>
                                    </React.Fragment>
                                );
                            }
                        },
                    },
                ]}
            />
            {isAddListModalVisible && (
                <Modal
                    title={geti18nText('payrollBrut.table.addEmployee')}
                    visible={isAddListModalVisible}
                    okText={geti18nText('app.default.button.add')}
                    okButtonProps={{ disabled: !hasSelectedAdd }}
                    onOk={(e) => save(e)}
                    confirmLoading={loadingModal}
                    width={600}
                    onCancel={() => {
                        setIsAddListModalVisible(false);
                    }}
                >
                    <NyDataTable
                        nyId="human-payroll-brut-not-employees-table"
                        rowKey="id"
                        url={CONSTANTS_REQ.PAYROLL_BRUT.NOT_EMPLOYEES}
                        addedData={{ payrollBrutId: form.getFieldValue('id') }}
                        showRowSelection
                        hideButtons
                        setDefaultPageSize={table.setDefaultPageSize()}
                        readonly
                        setDefaultSortOrder={table.setDefaultSortOrder()}
                        scroll={{ y: 400, x: 500 }}
                        rowSelectionType={'checkbox'}
                        rowSelectionModal={setSelectedValuesFuncAdd as any}
                        fetchWhenChange={refreshTable}
                        columns={[
                            {
                                title: geti18nText('payrollBrut.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('payrollBrut.table.column.employee'),
                                dataIndex:
                                    "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employment_record_id as varchar),'')) ",
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                                render: (person: any, record: any) => {
                                    if (record.person) {
                                        if (record.person && record.employmentRecordId) {
                                            return (
                                                record.person.firstName +
                                                ' ' +
                                                record.person.lastName +
                                                ' (' +
                                                record.employmentRecordId +
                                                ')'
                                            );
                                        } else {
                                            return record.person.firstName + ' ' + record.person.lastName;
                                        }
                                    }
                                },
                            },
                        ]}
                    />
                </Modal>
            )}
            {isEditModalVisible && (
                <Modal
                    title={employeeEditTitle}
                    visible={isEditModalVisible}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(e) => saveEmployee(e)}
                    confirmLoading={loadingModal}
                    width={600}
                    onCancel={closeEmployeeModal}
                >
                    <Form form={employeeForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }} hidden>
                                <Input />
                            </Form.Item>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollBrut.addition.edit.validFrom')} name="validFrom">
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        disabledDate={disabledValidFromDateEmployee}
                                        defaultPickerValue={moment()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollBrut.addition.edit.validTo')} name="validTo">
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        disabledDate={disabledValidToDateEmployee}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Divider>
                                    <span>
                                        {geti18nText('app.default.files')}
                                        {isFilesChanged ? (
                                            <ExclamationCircleTwoTone
                                                twoToneColor="#faad14"
                                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </Divider>
                                {/* <FileListIndex
                                            isAdmin={canCreate}
                                            files={files}
                                            setFiles={setFiles}
                                            documentType={documentTypeEnum.PAYROLL_BRUT_EMPLOYEE}
                                            id={employeeId}
                                            saveAndDeleteOnUpload={true}
                                            setFileListRefresh={setRefreshFiles}
                                            loading={loading}
                                            setLoading={setLoading}
                                            refId={dataForm}
                                            deleteValidationField={false}
                                            canDelete={canCreate}
                                            showDocumentTypeColumn={false}
                                            addNewFile={canCreate() && files.length < 1}
                                            doReturnFileId={true}
                                            setIsFilesChanged={setIsFilesChanged}
                                        /> */}
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );

    const getTabsView: any = (
        <Tabs type="card" className="tabs-sticky" defaultActiveKey={activeKey} onChange={(key) => setAcitveKey(key)}>
            <Tabs.TabPane
                key="1"
                tab={
                    <span>
                        {geti18nText('payrollBrut.edit.general')}
                        {isFormChange ? (
                            <ExclamationCircleTwoTone
                                twoToneColor="#faad14"
                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                            />
                        ) : (
                            ''
                        )}
                    </span>
                }
            >
                {getGeneralEditData}
            </Tabs.TabPane>
            <Tabs.TabPane tab={geti18nText('payrollBrut.edit.employees')} key="2" disabled={isCreate || allEmployees}>
                {getEmployeData}
            </Tabs.TabPane>
        </Tabs>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            paramsId={id}
            form={form}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PAYROLL_BRUT.EDIT}
            setValues={setValues}
            setIsCreate={setIsCreate}
            width={1200}
            goBack={() => history.goBack()}
            shortcuts={true}
            onModalOpen={onModalOpen}
            hideSubmitButton={!canCreate() || activeKey != '1'}
            hideActivationButtons={!canCreate() || activeKey != '1'}
            checkIsFormChanged={true}
            {...props}
            customFooterContent={getCustomFooterContent}
            shouldCloseModal={!isCreate}
            fetchWhenChange={refreshTable}
            setIsFormChange={setIsFormChange}
            onSaveAndGetData={(data) => {
                setValues(data);
                if (props && props.record) {
                    props.record.active = true;
                }
                setRefreshTable((refreshTable) => refreshTable + 1);

                setIsCreate(false);
                onModalClose();

                if (props?.editProps?.setRefreshTable && props?.editProps?.refreshTable) {
                    props.editProps.setRefreshTable(props.editProps.refreshTable + 1);
                }
            }}
            normalize={(values: any) => {
                if (values.joppdCode) values.joppdCode = values.joppdCode.code;
                if (values.joppdEmployeeCode) values.joppdEmployeeCode = values.joppdEmployeeCode.code;
                if (values.validFrom) values.validFrom = getDateFormat(values.validFrom, 'yyyy-MM-DD HH:mm:ss');
                if (values.validTo) values.validTo = getDateFormat(values.validTo, 'yyyy-MM-DD HH:mm:ss');
                if (values.valueType) values.valueType = getEnumFormat(values.valueType);
                if (values.valueType == -1) delete values.valueType;
                if (values.baseType) values.baseType = getEnumFormat(values.baseType);
                if (values.valueType == -1) delete values.baseType;

                values.allEmployees = allEmployees ? true : false;
                values.showOnContract = showOnContract ? true : false;
                values.excludeForNonWorkActivity = excludeForNonWorkActivity ? true : false;

                values.addition = true;
                delete values.created;
                delete values.updated;
                delete values.createdBy;
                delete values.updatedBy;
                return values;
            }}
        >
            {showAllExtraData() ? getTabsView : getGeneralEditData}
        </NyDataEdit>
    );
};

export default PayrollBrutAdditionEdit;
