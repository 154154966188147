import { geti18nText, NyDataEdit, NyInputNumber, NySearchField, NyUtils } from '@nybble/nyreact';
import { Checkbox, Col, Descriptions, Form, Input, Row, Tabs } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import ItemIndex from '../../../administration/views/item';
import ItemEdit from '../../../administration/views/item/edit';
import WarehouseIndex from '../warehouse';
import WarehouseEdit from '../warehouse/edit';
import WarehouseItemInputOutputIndex from './WarehouseItemInputOutputIndex';

const { TabPane } = Tabs;

const WarehouseItemEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('warehouseItem.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [edit, setEdit] = useState<any>(false);
    const [warehouseItemId, setWarehouseItemId] = useState<any>(null);
    const [globalData, setGlobalData] = useState<any>(null);
    const [dataForm, setDataForm] = useState<any>(null);

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseItem();
    };

    function setValues(dataForm: any) {
        setGlobalData(dataForm);
        setWarehouseItemId(dataForm.id);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        setEdit(false);
        if (dataForm.hasOwnProperty('id')) {
            setEdit(true);
            setEditHeader(geti18nText('warehouseItem.edit.title'));
        }
        if (dataForm.warehouse) {
            dataForm.warehouse = setSearchFormat(dataForm.warehouse, 'name', 'name');
        }
        if (dataForm.item) {
            dataForm.item = setSearchFormat(dataForm.item, 'name', 'name');
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEdit(false);
        setGlobalData(null);
        setDataForm(null);
        setEditHeader(geti18nText('warehouseItem.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getDescriptions = () => (
        <>
            <Descriptions column={1} bordered size={'small'} labelStyle={{ width: '250px' }}>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.abbreviation')}>
                    <span>{globalData?.item?.measureUnit?.abbreviation}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.quantity')}>
                    <span>{globalData?.quantity}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.amount')}>
                    <span>{globalData?.amount ? NyUtils.formatNumber(globalData.amount, 2) : ''}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.inReservation')}>
                    <span>{globalData?.inReservation}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.inOrder')}>
                    <span>{globalData?.inOrder}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.inProduction')}>
                    <span>{globalData?.inProduction}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.available')}>
                    <span>{globalData?.available}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('warehouseItem.edit.availableOnStock')}>
                    <span>{globalData?.availableOnStock}</span>
                </Descriptions.Item>
            </Descriptions>
        </>
    );

    const getFormItems = () => (
        <>
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item label={geti18nText('warehouseItem.edit.warehouse')} name="warehouse">
                        <NySearchField
                            url={CONSTANTS_REQ.WAREHOUSE.SEARCH}
                            setDefaultFilterValue={setDefaultFilterValue}
                            map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                            searchBy="name || ' ' || code"
                            itemName={'name'}
                            customItemNameLabel={'(code) text'}
                            renderOption={(option: any) =>
                                `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                            }
                            AddNewModalComponent={WarehouseEdit}
                            SearchPopupComponent={<WarehouseIndex />}
                            addNewItem={false}
                            disabled={!canCreate() || edit}
                            mustGetPopupContainer={false}
                            customListWidth={'1200px'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {/*    <ItemSearch
                                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                ref={focusInput}
                                itemName={[['text'], ['code']]}
                                customItemNameLabel={'(code) text'}
                                renderOption={(option: any) =>
                                    `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                                }
                                itemIndexDisabled={isItemEditDisabled()}
                                -- ostavljeno, disable polje ne radi
                            /> */}
                    <Form.Item label={geti18nText('warehouseItem.edit.item')} name="item">
                        <NySearchField
                            url={CONSTANTS_REQ.ITEM.SEARCH}
                            setDefaultFilterValue={setDefaultFilterValue}
                            map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                            searchBy="name"
                            itemName={'name'}
                            customItemNameLabel={'(code) text'}
                            renderOption={(option: any) =>
                                `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                            }
                            AddNewModalComponent={ItemEdit}
                            SearchPopupComponent={<ItemIndex />}
                            addNewItem={false}
                            disabled={!canCreate() || edit}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('warehouseItem.edit.safetyStock')} name="safetyStock">
                        <NyInputNumber disabled={!canCreate()} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ marginTop: '15px' }}>
                    <Form.Item name="belowSafetyStock" valuePropName={'checked'} initialValue={false}>
                        <Checkbox>{geti18nText('warehouseItem.edit.bellowSafetyStock')}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.WAREHOUSE_ITEM.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            nyTestId="warehouse-item-edit"
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.warehouse) {
                    values.warehouse = getSearchFormat(values.warehouse);
                    if (values.warehouse.id < 1) {
                        delete values.warehouse;
                    }
                }
                if (values.item) {
                    values.item = getSearchFormat(values.item);
                    if (values.item.id < 1) {
                        delete values.item;
                    }
                }
                if (values.measureUnit) {
                    values.item.measureUnit = getSearchFormat(values.measureUnit);
                    delete values.measureUnit;
                    if (values.item.measureUnit.id < 1) {
                        delete values.item.measureUnit;
                    }
                }
                return values;
            }}
        >
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('warehouseItem.tab.1')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>{getDescriptions()}</Col>
                        <Col span={12}>{getFormItems()}</Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('warehouseItem.tab.2')} key="2" disabled={!dataForm}>
                    <WarehouseItemInputOutputIndex warehouseItemId={warehouseItemId} />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default WarehouseItemEdit;
