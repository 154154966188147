import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getDateFormat, getMealPriceWithSubvention } from '../../../../../utils/Utils';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import PriceListEdit from './edit';
import { MealsRights } from '../../../../../rights/mealsRights';

const PriceListIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const appSettingMealSubvention = useApplicationSetting('MEALS_SUBVENTION');
    const table = useTableSettings();
    useHelper('meals/codebooks/price-list');
    const now = moment().format('DD.MM.YYYY');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        ...[
            {
                title: geti18nText('price.list.table.column.id'),
                dataIndex: 'id',
                width: '4%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('price.list.table.column.refId'),
                dataIndex: ['meal', 'refId'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('price.list.table.column.meal'),
                dataIndex: ['meal', 'name'],
                width: '30%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.MEAL.SEARCH, 'meal.id', 'name', 'name', {
                    id: 'id',
                    label: 'name',
                }),
            },
            {
                title: geti18nText('price.list.table.column.validFrom'),
                dataIndex: 'validFrom',
                sorter: (a: any, b: any) => {},
                render: (text: any, record: any) => {
                    if (record.validFrom) {
                        return moment(record.validFrom).format('DD.MM.YYYY');
                    }
                },
                ...getColumnDateOption(false),
            },
            {
                title: geti18nText('price.list.table.column.validTo'),
                dataIndex: 'validTo',
                sorter: (a: any, b: any) => {},
                render: (text: any, record: any) => {
                    if (record.validTo) {
                        return moment(record.validTo).format('DD.MM.YYYY');
                    }
                },
                ...getColumnDateOption(false),
            },
            {
                title: geti18nText('price.list.table.column.price'),
                dataIndex: ['price'],
                align: 'right',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                render: (text: any, record: any) => {
                    if (record) {
                        return (
                            (record.price ? NyUtils.formatNumber(record.price, 2) : '0,00') +
                            ' ' +
                            (record.currency && record.currency.isoCode ? record.currency.isoCode : '')
                        );
                    }
                },
            },
        ],
        ...(mealSyncTypeHNB === true
            ? [
                  {
                      title: geti18nText('price.list.table.column.priceWithSubvention'),
                      dataIndex: ['priceWithSubvention'],
                      align: 'right',
                      render: (text: any, record: any) => {
                          if (
                              record &&
                              ((record.validTo &&
                                  moment(getDateFormat(record.validTo, 'DD.MM.YYYY'), 'DD.MM.YYYY').isSameOrAfter(
                                      moment(now, 'DD.MM.YYYY')
                                  )) ||
                                  !record.validTo)
                          ) {
                              return (
                                  getMealPriceWithSubvention(
                                      appSettingMealSubvention,
                                      record?.meal?.allowedDiscount ?? 0,
                                      record?.price ?? 0
                                  ) +
                                  ' ' +
                                  (record.currency && record.currency.isoCode ? record.currency.isoCode : '')
                              );
                          }
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('price.list.table.column.active'),
                dataIndex: 'active',
                width: '10%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
            },
        ],
    ];

    return (
        <NyDataTable
            nyId="price-list"
            url={CONSTANTS_REQ.PRICE_LIST.LIST}
            addNewButtonText={geti18nText('price.list.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={PriceListEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? { order: 'validFrom', orderType: 'desc' }}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.meals.meal.priceList')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.meals.meal.priceList')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            price: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default PriceListIndex;
