import {
    AppstoreOutlined,
    CalculatorOutlined,
    CarOutlined,
    DashboardOutlined,
    ReconciliationOutlined,
    ShoppingCartOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { TasksRights } from '../../rights/tasksRights';
import { AssetRights } from '../../rights/assetRights';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { WarehouseBookkeepingRights } from '../../rights/warehouseBookkeepingRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { FixedAssetRights } from '../../rights/fixedAssetRights';
import { AdministrationRights } from '../../rights/administrationRights';

const defaultMenu = [
    {
        key: 'menu.myPortal',
        path: '/my-portal',
        icon: <AppstoreOutlined />,
        i18n: 'menu.myPortal',
        role: ['ROLE_ADMIN', 'ROLE_DASHBOARD_MENU'],
        isForEmployeesOnly:true,
    },
    {
        key: 'menu.dashboard',
        path: '/',
        icon: <DashboardOutlined />,
        i18n: 'menu.dashboard',
        role: AdministrationRights.canViewDashboardMenu(),
    },
    {
        key: 'menu.webshop',
        path: '/webshop',
        icon: <ShoppingCartOutlined />,
        i18n: 'menu.webshop',
        role: AssetRights.canViewWebshopOverviewModule(),
    },
    {
        key: 'menu.human',
        icon: <TeamOutlined />,
        i18n: 'menu.human',
        path: '/human',
        role: HumanResourcesRights.canViewHumanOverviewModule(),
        notificationType: 4,
    },
    {
        key: 'menu.warehouse.bookkeeping',
        path: '/warehouse',
        icon: <ReconciliationOutlined />,
        i18n: 'menu.warehouse.bookkeeping',
        role: WarehouseBookkeepingRights.canViewWarehouseOverviewModule(),
    },
    {
        key: 'menu.travel.warrant',
        icon: <CarOutlined />,
        path: '/travel-warrant',
        i18n: 'menu.travel.warrant',
        notificationType: 12,
        role: TravelWarrantRights.canViewTravelWarrantModuleMenu(),
    },
    {
        key: 'menu.accounting',
        path: '/accounting',
        icon: <CalculatorOutlined />,
        i18n: 'menu.accounting',
        role: FixedAssetRights.canViewAccountingOverview(),
    },
    {
        key: 'menu.translation-editor',
        path: '/translation-editor',
        icon: <DashboardOutlined />,
        i18n: 'menu.translationEditor',
        role: ['ROLE_ADMIN'],
    },
    {
        key: 'menu.task.service',

        icon: <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-headset" />,
        path: '/tasks',
        i18n: 'menu.task.service',
        role: TasksRights.canViewTasksMenu(),
    },
    {
        key: 'menu.administration',
        path: '/administration',
        icon: <AppstoreOutlined />,
        i18n: 'menu.administration',
        role: AdministrationRights.canViewAdministrationOverview(),
    },
];

export default defaultMenu;
