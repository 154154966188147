import { geti18nText, NyRequestResolver, NySession, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Button, Result } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import { AdministrationRights } from '../../rights/administrationRights';
import { TasksRights } from '../../rights/tasksRights';
import { RootState } from '../../rootReducer';
import { propInit, setProp, setPropOverviewTasks } from '../../slices/propSlice';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { userIsNotificationRecipient } from '../../utils/Utils';

const ApprovalIndex = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location: any = useLocation();
    let { search } = useLocation();
    const notificationType = useEnum('NOTIFICATION_TYPE');

    const query: any = new URLSearchParams(search);
    const { employee } = useSelector((state: RootState) => state.employee);
    const [loading, setLoading] = useState<any>(true);
    const [type, setType] = useState<any>('/');
    const [ord, setOrd] = useState<any>(undefined);
    const [success, setSuccess] = useState<any>(false);
    const [status, setStatus] = useState<any>(undefined);
    const [errorData, setErrorData] = useState<any>(undefined);
    const [approvalModal, setApprovalModal] = useState<any>(false);
    const [makeDecisionType, setMakeDecisionType] = useState<any>(null);
    const [propValue, setPropValue] = useState<any>(null);
    const [taskType, setTaskType] = useState<any>(null);
    const [tType, setTType] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(true);
    const [loginRedirectUrl, setLoginRedirectUrl] = useState<any>(undefined);

    const typeText: any = {
        '/webshop/webshop-item/inventory-order': geti18nText('inventoryOrder.title'),
        '/webshop/webshop-asset/asset-order': geti18nText('report.assetOrder'),
        '/webshop/webshop-services/services-order': geti18nText('servicesOrder.title'),
        '/travel-warrant': geti18nText('travelWarrant.edit.title'),
        '/travelWarrantPaymentApproval': geti18nText('travelWarrant.edit.title'),
        '/travelWarrantModalReportFix': geti18nText('travelWarrant.edit.title'),
        '/human/employee-leave-overview': geti18nText('employeeLeave.edit.title'),
        '/webshop/meals': geti18nText('meal.order.edit.tab.header'),
        '/human/work-obligation/call/work-obligation-call': geti18nText('menu.work.obligation.call.mobilization'),
        '/tasks': geti18nText('task.approval.text') + ' ' + geti18nText('task.approval.text.' + taskType),
    };

    const buttonTypeText: any = {
        '/webshop/webshop-item/inventory-order': geti18nText('menu.inventory.order'),
        '/webshop/webshop-asset/asset-order': geti18nText('menu.asset.order'),
        '/webshop/webshop-services/services-order': geti18nText('menu.services.order'),
        '/travel-warrant': geti18nText('menu.travelWarrant'),
        '/travelWarrantPaymentApproval': geti18nText('menu.travelWarrant'),
        '/travelWarrantModalReportFix': geti18nText('menu.travelWarrant'),
        '/human/employee-leave-overview': geti18nText('menu.absence'),
        '/webshop/meals': geti18nText('menu.meals'),
        '/human/work-obligation/call/work-obligation-call': geti18nText('menu.work.obligation.call.mobilization'),
        '/tasks': geti18nText('task.approval.text'),
    };

    const canMakeDecision = async (makeDecisionType: any) => {
        if (makeDecisionType) {
            if (makeDecisionType == notificationType.TASK_APPROVAL) {
                return TasksRights.isTasksApprover();
            }
            if (!employee?.id) {
                const response: any = await NyRequestResolver.requestGet(
                    CONSTANTS_REQ.EMPLOYEE.GET_EMPLOYEE_BY_USER,
                    {}
                );
                return (
                    AdministrationRights.isAdmin() ||
                    (response?.status === RESPONSE.OK &&
                        response?.data?.id &&
                        userIsNotificationRecipient(response.data, makeDecisionType))
                );
            } else {
                return AdministrationRights.isAdmin() || userIsNotificationRecipient(employee, makeDecisionType);
            }
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (location && (location.pathname || location.search)) {
            const locationSearch = location.search;
            const locationPathname = location.pathname;
            if (locationPathname != '/approval') {
                if (locationPathname) {
                    const dataSubstring = locationPathname.replace('/approval/data=', '');
                    if (dataSubstring) {
                        parseData(dataSubstring);
                    }
                }
            } else {
                if (locationSearch) {
                    const dataSubstring = locationSearch.replace('?data=', '');
                    if (dataSubstring) {
                        parseData(dataSubstring);
                    }
                }
            }
        } else {
            parseData(query.get('data'));
        }
    }, []);

    const parseData = (dataValue: any) => {
        setIsLoading(true);
        if (dataValue != undefined && JSON.parse(atob(dataValue)) && notificationType) {
            getData(dataValue, JSON.parse(atob(dataValue)));
        }
    };

    const checkResult = (result: any) => {
        setLoading(false);
        if (result?.data?.error) {
            setErrorData(result.data.error);
            setSuccess(false);
            setStatus('error');
            return true;
        } else if (result && result.data && result.data instanceof String) {
            setErrorData(result.data);
            setSuccess(false);
            setStatus('error');
            return true;
        } else {
            return false;
        }
    };

    const openModalData = (
        url: any,
        historyUrl: any,
        title: any,
        props: any,
        dispatchProp: any = null,
        mustBeStatus: any,
        okStatusList: any = [],
        setPropBasedOnEmployee: number[] = []
    ) => {
        localStorage.removeItem('loginRedirectUrl');
        setLoading(true);
        var params = undefined;
        if (props.advance != null) {
            params = { advance: props.advance, status: props.checkStatus };
        }
        NyRequestResolver.requestGet(url + '/' + props.id, params, props).then((result: any) => {
            if (
                result &&
                result.data &&
                ((result.data.status == mustBeStatus && mustBeStatus != null) || mustBeStatus == null)
            ) {
                if (!checkResult(result)) {
                    setApprovalModal(true);
                    if (setPropBasedOnEmployee && setPropBasedOnEmployee.length == 2) {
                        if (employee.id == result.data.employee?.id) {
                            dispatch(setProp(setPropBasedOnEmployee[0]));
                        } else {
                            dispatch(setProp(setPropBasedOnEmployee[1]));
                        }
                    } else if (dispatchProp) dispatch(setProp(dispatchProp));
                    setLoading(false);

                    // dispatch(addTab({ title: title, path: historyUrl }));
                    history.push({
                        pathname: historyUrl,
                        state: { openModal: true, id: props.id },
                    });
                }
            } else {
                setSuccess(false);
                setErrorData(
                    okStatusList && Object.values(okStatusList).includes(props.status)
                        ? 'app.default.status.has.already.been.changed'
                        : 'app.default.travelWarrant.error'
                );
                setLoading(false);
            }
        });
    };

    const getData = async (queryData: any, data: any) => {
        if (data) {
            let returnType = '/';
            let propValue = null;
            let type = data.type ? data.type : '/';
            let id = data.id ? data.id : null;
            let url = undefined;
            let isModal = false;
            setType(type);
            setOrd(data.ord);
            let props: any = {
                id: data.id,
                status: data.status,
                mail: 1,
            };
            switch (type) {
                case 'inventoryOrder':
                    returnType = '/webshop/webshop-item/inventory-order';
                    setType('/webshop/webshop-item/inventory-order');
                    url = CONSTANTS_REQ.INVENTORY_ORDER.CHANGE_STATUS;
                    break;
                case 'assetOrder':
                    returnType = '/webshop/webshop-asset/asset-order';
                    setType('/webshop/webshop-asset/asset-order');
                    url = CONSTANTS_REQ.ASSET_ORDER.CHANGE_STATUS;
                    break;
                case 'servicesOrder':
                    returnType = '/webshop/webshop-services/services-order';
                    setType('/webshop/webshop-services/services-order');
                    url = CONSTANTS_REQ.SERVICES_ORDER.CHANGE_STATUS;
                    break;
                case 'travelWarrantApproval':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    propValue = 3;
                    url = CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS;
                    break;
                case 'travelWarrantModalApproval':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    propValue = 3;
                    isModal = true;
                    setMakeDecisionType(notificationType.TRAVEL_WARRANT_APPROVAL);
                    if (await canMakeDecision(notificationType.TRAVEL_WARRANT_APPROVAL)) {
                        openModalData(
                            CONSTANTS_REQ.TRAVEL_WARRANT.EDIT,
                            '/travel-warrant/travelWarrant',
                            'menu.travelWarrant',
                            props,
                            3,
                            1,
                            [2, 3]
                        );
                    }
                    break;
                case 'travelWarrantReportApproval':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    propValue = 6;
                    url = CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS;
                    break;
                case 'travelWarrantModalReportApproval':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    propValue = 6;
                    isModal = true;
                    setMakeDecisionType(notificationType.TRAVEL_WARRANT_APPROVAL);
                    if (await canMakeDecision(notificationType.TRAVEL_WARRANT_APPROVAL)) {
                        openModalData(
                            CONSTANTS_REQ.TRAVEL_WARRANT.EDIT,
                            '/travel-warrant/travelWarrant',
                            'menu.travelWarrant',
                            props,
                            6,
                            6,
                            [7, 8]
                        );
                    }
                    break;
                case 'travelWarrantOrganisation':
                case 'travelWarrantModalOrganisation':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    isModal = true;
                    propValue = 5;
                    setMakeDecisionType(notificationType.TRAVEL_WARRANT_ORGANISATION);
                    if (await canMakeDecision(notificationType.TRAVEL_WARRANT_ORGANISATION)) {
                        openModalData(
                            CONSTANTS_REQ.TRAVEL_WARRANT.EDIT,
                            '/travel-warrant/travelWarrant',
                            'menu.travelWarrant',
                            props,
                            5,
                            4,
                            [5]
                        );
                    }
                    break;
                case 'travelWarrantAdvancePayment':
                    returnType = '/travel-warrant';
                    setType('/travelWarrantPaymentApproval');
                    propValue = 4;
                    props.advance = true;
                    props.checkStatus = 2;
                    url = CONSTANTS_REQ.TRAVEL_WARRANT_PAYMENT.CHANGE_STATUS;
                    break;
                case 'travelWarrantModalAdvancePayment':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    propValue = 4;
                    isModal = true;
                    props.advance = true;
                    props.checkStatus = 2;
                    setMakeDecisionType(notificationType.TRAVEL_WARRANT_PAYMENT);
                    if (await canMakeDecision(notificationType.TRAVEL_WARRANT_PAYMENT)) {
                        openModalData(
                            CONSTANTS_REQ.TRAVEL_WARRANT.GET_FIRST_PAYMENT_BY_ID,
                            '/travel-warrant/travelWarrant',
                            'menu.travelWarrant',
                            props,
                            4,
                            2,
                            [3, 4, 5]
                        );
                    }
                    break;
                case 'travelWarrantCompensationPayment':
                    //propValue = 7;
                    returnType = '/travel-warrant/' + id;
                    setType('/travel-warrant');
                    url = CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS;
                    break;
                case 'travelWarrantModalCompensationPayment':
                    //propValue = 7;
                    returnType = '/travel-warrant/' + id;
                    break;
                case 'travelWarrantModalPaymentPayOut':
                    returnType = '/travel-warrant';
                    setType('/travel-warrant');
                    propValue = 10;
                    isModal = true;
                    props.advance = false;
                    props.checkStatus = 2;
                    setMakeDecisionType(notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT);
                    if (await canMakeDecision(notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)) {
                        openModalData(
                            CONSTANTS_REQ.TRAVEL_WARRANT.GET_FIRST_PAYMENT_BY_ID,
                            '/travel-warrant/travelWarrant',
                            'menu.travelWarrant',
                            props,
                            10,
                            2,
                            [4, 5]
                        );
                    }
                    break;
                case 'travelWarrantPaymentApproval':
                    returnType = '/travel-warrant';
                    setType('/travelWarrantPaymentApproval');
                    propValue = 9;
                    url = CONSTANTS_REQ.TRAVEL_WARRANT_PAYMENT.CHANGE_STATUS;
                    break;
                case 'travelWarrantModalPaymentApproval':
                    returnType = '/travel-warrant';
                    setType('/travelWarrantPaymentApproval');
                    propValue = 9;
                    isModal = true;
                    props.advance = false;
                    props.checkStatus = 1;
                    setMakeDecisionType(notificationType.TRAVEL_WARRANT_PAYMENT_APPROVAL);
                    if (await canMakeDecision(notificationType.TRAVEL_WARRANT_PAYMENT_APPROVAL)) {
                        openModalData(
                            CONSTANTS_REQ.TRAVEL_WARRANT.GET_FIRST_PAYMENT_BY_ID,
                            '/travel-warrant/travelWarrant',
                            'menu.travelWarrant',
                            props,
                            9,
                            1,
                            [2, 3]
                        );
                    }
                    break;
                case 'travelWarrantModalReportFix':
                    returnType = '/travel-warrant';
                    setType('/travelWarrantModalReportFix');
                    propValue = 1;
                    isModal = true;
                    props.advance = null;
                    props.checkStatus = null;
                    openModalData(
                        CONSTANTS_REQ.TRAVEL_WARRANT.EDIT,
                        '/travel-warrant/travelWarrant',
                        'menu.travelWarrant',
                        props,
                        1,
                        null,
                        [],
                        [1, 2]
                    );
                    break;

                case 'employeeLeave':
                    setMakeDecisionType(notificationType.EMPLOYEE_ANNUAL_LEAVE_APPROVAL);
                    returnType = '/human/employee-leave-overview';
                    setType('/human/employee-leave-overview');
                    url = CONSTANTS_REQ.EMPLOYEE_LEAVE.CHANGE_STATUS;
                    setOrd(data.id);
                    break;
                case 'mealsOrder':
                case 'mealsMenuChanged':
                    returnType = '/webshop/meals';
                    setType('/webshop/meals');
                    setOrd(data.id);
                    isModal = true;
                    openModalData(
                        CONSTANTS_REQ.MEAL_ORDER.EDIT,
                        '/webshop/meals',
                        'menu.meals.meal.orders',
                        props,
                        null,
                        null,
                        null
                    );
                    break;
                case 'mobilization_approval':
                    setMakeDecisionType(notificationType.MOBILIZATION_APPROVAL);
                    returnType = '/human/work-obligation/call/work-obligation-call';
                    setType('/human/work-obligation/call/work-obligation-call');
                    setOrd(data.id);
                    url = CONSTANTS_REQ.MOBILIZATION.CHANGE_STATUS;
                    break;
                case 'taskApproval':
                    // setMakeDecisionType(notificationType.TASK_APPROVAL);
                    returnType = '/tasks';
                    setType('/tasks');
                    setOrd(data.id);
                    setTaskType(data.taskType);
                    setTType(data.type);
                    url = CONSTANTS_REQ.TASK.CHANGE_TASK_APPROVAL_STATUS;
                    break;
            }

            const returnValue: any = [{ returnType: returnType, propValue: propValue, id: id, queryData: queryData }];
            const storageValue = JSON.stringify(returnValue);
            localStorage.setItem('loginRedirectUrl', storageValue);
            setPropValue(propValue);
            setLoginRedirectUrl(storageValue);
            setIsLoading(false);

            if (!isModal) {
                NyRequestResolver.requestPut(url + '/' + data.id, undefined, props).then((result: any) => {
                    setLoading(false);
                    setStatus(data.status);
                    setSuccess(result && result.status === RESPONSE.OK);
                    setErrorData(result && result.data && result.data.error ? result.data.error : undefined);
                });
                if (employee?.id && NySession.isUserAuthenticated()) {
                    localStorage.removeItem('loginRedirectUrl');
                }
            }
        }
    };
    const translations = (data: any) => {
        switch (type) {
            case '/webshop/webshop-asset/asset-order':
            case '/webshop/webshop-item/inventory-order':
            case '/webshop/webshop-services/services-order': {
                if (status != undefined && status === 3) {
                    return geti18nText('approval.default.webshop.approved');
                } else {
                    return geti18nText('approval.default.webshop.rejected');
                }
            }
            case '/travel-warrant': {
                if (status && status === 2) {
                    return geti18nText('app.default.travelWarrant.approved');
                } else if (status && status === 5) {
                    return geti18nText('app.default.travelWarrant.organized');
                } else {
                    return geti18nText('app.default.travelWarrant.rejected');
                }
            }
            case '/travelWarrantPaymentApproval': {
                if (status != undefined && status === 2) {
                    return geti18nText('app.default.travelWarrantPayment.approved');
                } else if (status != undefined && status === 3) {
                    return geti18nText('app.default.travelWarrantPayment.rejected');
                } else if (status != undefined && status === 4) {
                    return geti18nText('app.default.travelWarrantPayment.paid');
                } else {
                    return geti18nText('app.default.travelWarrantPayment.canceled');
                }
            }
            case '/human/employee-leave-overview': {
                if (status != undefined && status === 2) {
                    return geti18nText('app.default.employeeLeave.approved');
                } else {
                    return geti18nText('app.default.employeeLeave.rejected');
                }
            }
            case '/human/work-obligation/call/work-obligation-call': {
                if (status != undefined && status === 3) {
                    return geti18nText('app.default.mobilization.approved');
                } else {
                    return geti18nText('app.default.mobilization.rejected');
                }
            }
            case '/tasks': {
                if (status != undefined && status === 3) {
                    return (
                        geti18nText('app.default.task.task.' + taskType) +
                        ' ' +
                        geti18nText('app.default.task.approved')
                    );
                } else {
                    return (
                        geti18nText('app.default.task.task.' + taskType) +
                        ' ' +
                        geti18nText('app.default.task.rejected')
                    );
                }
            }
            default: {
                if (status != undefined && status === 3) {
                    return geti18nText('approval.default.approved');
                } else {
                    return geti18nText('approval.default.rejected');
                }
            }
        }
    };

    const returnToUrl = (url: string = '/', tab: any = null) => {
        if (tab) {
            dispatch(setPropOverviewTasks(tab));
        }
        // dispatch(setActiveTab(url));
        history.push(url);
    };

    const buttons = [
        <Button
            type="primary"
            onClick={() => {
                localStorage.removeItem('loginRedirectUrl');

                if (propValue) {
                    dispatch(setProp(propValue));
                } else {
                    dispatch(propInit);
                }
                if (type == '/travelWarrantPaymentApproval' || type == '/travelWarrantModalReportFix') {
                    // dispatch(setActiveTab('/travelWarrant'));
                    history.push('/travel-warrant/travelWarrant');
                } else if (type == '/tasks') {
                    if (tType == 'taskApproval') {
                        if (TasksRights.isTasksApprover()) {
                            if (TasksRights.canCreateAdminOrAgent()) {
                                returnToUrl('/tasks', 4);
                            } else if (TasksRights.isTasksUser()) {
                                returnToUrl('/approval-task-list');
                            } else {
                                returnToUrl();
                            }
                        } else {
                            returnToUrl();
                        }
                    } else {
                        if (TasksRights.canCreateAdminOrAgent()) {
                            returnToUrl('/tasks', 1);
                        } else if (TasksRights.isTasksUser()) {
                            returnToUrl('/my-task-list');
                        } else {
                            returnToUrl();
                        }
                    }
                } else {
                    // dispatch(setActiveTab(type));
                    history.push(type);
                }
            }}
        >
            {buttonTypeText[type]}
        </Button>,
    ];

    if (!NySession.isUserAuthenticated()) {
        if (loginRedirectUrl != undefined) {
            localStorage.setItem('loginRedirectUrl', loginRedirectUrl);
        }
        return <Redirect to="/login" />;
    } else if (!canMakeDecision(makeDecisionType)) {
        return (
            <Result
                status="error"
                title={geti18nText('app.default.user.has.no.right.to.approve')}
                extra={buttons}
            ></Result>
        );
    } else {
        return (
            <>
                {isLoading ? (
                    <NySpinner />
                ) : approvalModal ? (
                    <div></div>
                ) : (
                    <>
                        {loading ? (
                            <NySpinner />
                        ) : (
                            <>
                                {success ? (
                                    <Result
                                        status="success"
                                        title={translations(type)}
                                        subTitle={`${typeText[type]}: ` + ord}
                                        extra={buttons}
                                    />
                                ) : errorData && geti18nText(errorData) != undefined ? (
                                    <Result
                                        status="warning"
                                        title={geti18nText(errorData)}
                                        subTitle={`${typeText[type]}: ` + ord}
                                        extra={buttons}
                                    />
                                ) : (
                                    <Result
                                        status="error"
                                        title={geti18nText('app.default.change.status.error')}
                                        subTitle={`${typeText[type]}: ` + ord}
                                        extra={buttons}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
};

export default ApprovalIndex;
