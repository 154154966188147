import { NyDataEdit, NyDatePicker, NySearchField, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd';
import L, { LatLngLiteral } from 'leaflet';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customAttendanceRenderOption,
    getDateFormat,
    getEnumFormat,
    getSearchFormat,
    setDateFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import UserIndex from '../../../tasks/views/user';
import WorkTypeSearch from '../attendance-work-type/search';
import LocationSearch from '../location/search';

const AttendanceRegistrationEdit = (props: any) => {
    const enAttendanceRegistrationType = useEnum('ATTENDANCE_REGISTRATION_TYPE');
    const [editHeader, setEditHeader] = useState(geti18nText('attendance.edit.new'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [mapVisible, setMapVisible] = useState<boolean>(false);
    const [mapView, setMapView] = useState<any>(1);
    const [locationIn, setLocationIn] = useState<LatLngLiteral>({ lat: 0, lng: 0 });
    const [locationOut, setLocationOut] = useState<LatLngLiteral>({ lat: 0, lng: 0 });
    const [map, setMap] = useState<any>(null);
    const [visibleLocationIn, setVisibleLocationIn] = useState<any>(false);
    const [visibleLocationOut, setVisibleLocationOut] = useState<any>(false);
    const [timeFrom, setTimeFrom] = useState<any>(null);
    const [timeTo, setTimeTo] = useState<any>(null);
    const [attendanceEmployee, setAttendanceEmployee] = useState<any>(null);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);

    const canCreate = () => {
        return (
            HumanResourcesRights.canCreateAttendanceAdminAndRegistration() ||
            (HumanResourcesRights.canCreateAttendanceRegistrationOnlyUser() &&
                isCreate &&
                moduleAccessRight?.length > 0) ||
            (
                HumanResourcesRights.canCreateAttendanceRegistrationOnlyUser() &&
                !isCreate &&
                attendanceEmployee &&
                moduleAccessRight?.length > 0 &&
                moduleAccessRight.filter((item: any) => item?.employee?.id == attendanceEmployee?.id && !item.realOnly)
            )?.length > 0
        );
    };

    const hasNegativeTimeDifference = () => {
        return (
            (timeFrom && timeTo && moment(timeFrom) && moment(timeTo) && moment(timeTo).isBefore(moment(timeFrom))) ||
            !timeFrom
        );
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    useEffect(() => {
        setModuleAccessRight(props?.moduleAccessRight);
    }, [props]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('attendance.edit.title'));

        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }
        if (dataForm.from) {
            setTimeFrom(dataForm.from);
            setVisibleLocationIn(true);
            dataForm.from = setDateFormat(dataForm.from);
        }
        if (dataForm.to) {
            setTimeTo(dataForm.to);
            setVisibleLocationOut(true);
            dataForm.to = setDateFormat(dataForm.to);
        }
        if (dataForm.employee) {
            setAttendanceEmployee(dataForm.employee);
        }

        if (dataForm.user) {
            dataForm.user = {
                id: dataForm.user.person.id,
                text: `${dataForm.user.person.firstName}                   
                    ${dataForm.user.person.lastName} 
                    ${dataForm.employee?.employmentRecordId ? ' (' + dataForm.employee.employmentRecordId + ')' : ''}`,
            };
        }

        if (dataForm.registrationIn) {
            dataForm.registrationIn = setEnumFormat('ATTENDANCE_REGISTRATION_TYPE', dataForm.registrationIn);
            dataForm.registrationInText = dataForm.registrationIn.text;
        }
        if (dataForm.registrationOut) {
            dataForm.registrationOut = setEnumFormat('ATTENDANCE_REGISTRATION_TYPE', dataForm.registrationOut);
            dataForm.registrationOutText = dataForm.registrationOut.text;
        }
        if (dataForm.attendanceWorkType) {
            dataForm.attendanceWorkType.text = (
                <div style={{ display: 'inline-flex' }}>
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '4px',
                            border: '2px solid rgba(0, 0, 0, 0.2)',
                            background: dataForm.attendanceWorkType.color,
                            marginRight: '5px',
                            marginTop: '3px',
                        }}
                    />
                    <span>{dataForm.attendanceWorkType.name}</span>
                </div>
            );
        }
        setLocationIn({ lat: dataForm.latIn || 0, lng: dataForm.lngIn || 0 });
        setLocationOut({ lat: dataForm.latOut || 0, lng: dataForm.lngOut || 0 });

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (props.addedData != undefined) {
            setValues(props.addedData);
        }
    };

    const blueIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    function LocationMarker() {
        return <Marker position={mapView == 1 ? locationIn : locationOut} icon={blueIcon}></Marker>;
    }

    const mapOptions = {
        center: [mapView == 1 ? locationIn?.lat : locationOut?.lat, mapView == 1 ? locationIn?.lng : locationOut?.lng],
        zoom: 12,
        doubleClickZoom: false,
        trackResize: false,
        zoomControl: true,
        touchZoom: true,
        keyboard: false,
        gestureHandling: true,
        attributionControl: false,
    };

    useEffect(() => {
        if (locationIn && locationOut && map) {
            if (mapView == 1) {
                map.setView(locationIn, map.getZoom());
            } else {
                map.setView(locationOut, map.getZoom());
            }
        }
        if (map && mapVisible) {
            setTimeout(() => {
                map.invalidateSize();
            }, 100);
        }
    }, [mapView, map, mapVisible]);

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('attendance.edit.new'));
        setMapView(1);
        setLocationIn({ lat: 0, lng: 0 });
        setLocationOut({ lat: 0, lng: 0 });
        setAttendanceEmployee(null);
        setTimeFrom(null);
        setTimeTo(null);
    };

    const validateDateDiffFrom = (rule: any, value: any, callback: any) => {
        if (value && form.getFieldValue('to') && moment(form.getFieldValue('to')).isBefore(moment(value))) {
            return callback(geti18nText('validate.time.not.correct'));
        } else {
            return callback();
        }
    };

    const validateDateDiffTo = (rule: any, value: any, callback: any) => {
        if (value && form.getFieldValue('from') && moment(value).isBefore(moment(form.getFieldValue('from')))) {
            return callback(geti18nText('validate.time.not.correct'));
        } else {
            return callback();
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ATTENDANCE_REGISTRATION.EDIT}
            setValues={setValues}
            setIsCreate={setIsCreate}
            width={600}
            form={form}
            buttonsClassName="buttons-sticky"
            disableSubmitButton={hasNegativeTimeDifference()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => {
                history.goBack();
            }}
            onModalOpen={onModalOpen}
            fetchWhenChange={id}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.date) {
                    let date = values.date.clone();
                    values.date = getDateFormat(values.date);

                    if (values.from) {
                        let time = moment(values.from, 'HH:mm');
                        values.from = getDateFormat(
                            date.clone().set({ hour: time.get('hour'), minute: time.get('minute') })
                        );
                    }
                    if (values.to) {
                        let time = moment(values.to, 'HH:mm');
                        values.to = getDateFormat(
                            date.clone().set({ hour: time.get('hour'), minute: time.get('minute') })
                        );
                    }
                }

                if (values.locationOut == null || values.locationOut.id == -1) {
                    delete values.locationOut;
                }

                if (values.user) {
                    values.user = getSearchFormat(values.user);
                }

                if (values.attendanceWorkType) {
                    values.attendanceWorkType = getSearchFormat(values.attendanceWorkType);
                }

                values.registrationIn = values.registrationIn
                    ? getEnumFormat(values.registrationIn)
                    : enAttendanceRegistrationType.EDIT;

                values.registrationOut = values.registrationOut
                    ? getEnumFormat(values.registrationOut)
                    : enAttendanceRegistrationType.EDIT;

                delete values.registrationInText;
                delete values.registrationOutText;

                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="registrationIn" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="registrationOut" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('attendance.edit.employee')}
                        name="user"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.ATTENDANCE_REGISTRATION_SEARCH}
                            mustGetPopupContainer={false}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            disabled={!isCreate || !canCreate()}
                            renderOption={customAttendanceRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={
                                <UserIndex disabled={true} url={CONSTANTS_REQ.USER.ATTENDANCE_REGISTRATION_LIST} />
                            }
                            setDefaultFilterValue={setDefaultFilterValue}
                            order="person.last_name, person.first_name"
                            onChange={(value: any) => {
                                if (value.id === -1) {
                                    form.setFieldsValue({ user: undefined });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('attendance.edit.date')}
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            nyTestId="date"
                            disabled={!isCreate || !canCreate()}
                            defaultPickerValue={moment()}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <WorkTypeSearch
                        name="attendanceWorkType"
                        required
                        onChange={(value: any) => {
                            if (value.id === -1) form.setFieldsValue({ attendanceWorkType: undefined });
                        }}
                        disabled={!canCreate()}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('attendance.edit.registrationInType')} name="registrationInText">
                        <Input
                            disabled
                            defaultValue={geti18nText(
                                'app.enum.ATTENDANCE_REGISTRATION_TYPE.' + enAttendanceRegistrationType.EDIT
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('attendance.edit.registrationOutType')} name="registrationOutText">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('attendance.edit.from')}
                        name="from"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                validator: validateDateDiffFrom,
                            },
                        ]}
                    >
                        <NyDatePicker
                            mustGetPopupContainer={false}
                            mode={'time'}
                            showTime
                            format="HH:mm"
                            style={{ width: '100%' }}
                            nyTestId="time-from"
                            onChange={(value: any) => {
                                setTimeFrom(value);
                                setVisibleLocationIn(value != null);
                                form.validateFields();
                            }}
                            disabled={!canCreate()}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('attendance.edit.to')}
                        name="to"
                        rules={[
                            {
                                validator: validateDateDiffTo,
                            },
                        ]}
                    >
                        <NyDatePicker
                            mustGetPopupContainer={false}
                            mode={'time'}
                            showTime
                            format="HH:mm"
                            style={{ width: '100%' }}
                            nyTestId="time-to"
                            onChange={(value: any) => {
                                setTimeTo(value);
                                setVisibleLocationOut(value != null);
                                form.setFieldsValue({
                                    registrationOutText:
                                        value != null
                                            ? geti18nText(
                                                  'app.enum.ATTENDANCE_REGISTRATION_TYPE.' +
                                                      enAttendanceRegistrationType.EDIT
                                              )
                                            : undefined,
                                });
                                form.validateFields();
                            }}
                            disabled={!canCreate()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    {visibleLocationIn && (
                        <LocationSearch
                            label={geti18nText('attendance.registration.edit.locationIn')}
                            name="locationIn"
                            disabled={!canCreate()}
                        />
                    )}
                </Col>
                <Col span={12}>
                    {visibleLocationOut && (
                        <LocationSearch
                            label={geti18nText('attendance.registration.edit.locationOut')}
                            name="locationOut"
                            disabled={!canCreate()}
                        />
                    )}
                </Col>
                <Col span={24}>
                    {visibleLocationOut && (
                        <Form.Item
                            label={geti18nText('attendance.break.type')}
                            name="type"
                            rules={[
                                {
                                    required: false,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.ATTENDANCE_BREAK.SEARCH}
                                mustGetPopupContainer={false}
                                className=""
                                map={{
                                    id: 'id',
                                    label: 'name',
                                }}
                                searchBy="name"
                                itemName="name"
                                disabled={!canCreate()}
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            {((locationIn.lat != 0 && locationIn.lng != 0) || (locationOut.lat != 0 && locationOut.lng != 0)) && (
                <>
                    <Row gutter={24}>
                        <Col span={8}>
                            <div style={{ fontWeight: '500', marginBottom: '5px' }}>
                                {geti18nText('attendance.registration.edit.mapTitle')}
                            </div>
                            <Button onClick={() => setMapVisible(true)}>
                                {geti18nText('attendance.registration.edit.mapButton')}
                            </Button>
                        </Col>
                    </Row>
                    <Modal
                        title={geti18nText('attendance.registration.edit.mapTitle')}
                        forceRender={true}
                        maskClosable={false}
                        footer={null}
                        onCancel={() => setMapVisible(false)}
                        open={mapVisible}
                        width={900}
                    >
                        <div style={{ marginBottom: '15px' }}>
                            <Radio.Group
                                optionType="button"
                                onChange={(e: any) => {
                                    setMapView(e.target.value);
                                }}
                                value={mapView}
                            >
                                <Radio.Button value={1}>
                                    {geti18nText('attendance.registration.edit.locationIn')}
                                </Radio.Button>
                                <Radio.Button value={2}>
                                    {geti18nText('attendance.registration.edit.locationOut')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                        <Row gutter={24} justify={'center'}>
                            <Col span={24}>
                                <MapContainer
                                    style={{ height: '600px', width: '100%' }}
                                    {...(mapOptions as any)}
                                    whenCreated={setMap}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <LocationMarker />
                                </MapContainer>
                            </Col>
                        </Row>
                    </Modal>
                </>
            )}
        </NyDataEdit>
    );
};

export default AttendanceRegistrationEdit;
