import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, PlusCircleOutlined, SaveTwoTone } from '@ant-design/icons';
import {
    geti18nText,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import { useState } from 'react';
import NyHistoryModal from '../../../../components/history';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, numberFormat } from '../../../../utils/Utils';
import PayrollAdditionSearch from '../payroll-addition/search';
import { PayrollRights } from '../../../../rights/payrollRights';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
    const getAmount = (value: any) => {
        if (value) {
            var parms: any = getSearchFormat(value);
            if (parms.id > 0) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.PAYROLL_ADDITION.EDIT + '/' + parms.id).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            if (result.data) {
                                restProps.form.setFieldsValue({
                                    amount: result.data.value ? result.data.value : 0,
                                });
                            }
                        }
                    }
                );
            }
        }
    };

    const inputNode =
        inputType === 'number' ? (
            <NyInputNumber style={{ width: '100%' }} />
        ) : inputType === 'decimalNumber' ? (
            <NyInputNumber style={{ width: '100%' }} isDecimal />
        ) : (
            <PayrollAdditionSearch isForm={false} style={{ width: '100%' }} onChange={getAmount} />
        );
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: inputType === 'searchField' || dataIndex === 'amount' ? true : false,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EmployeePaymentAddition = ({ dataSource, setDataSource, lock, historyData }: any) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;

    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    const edit = (record: any) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (!record.payrollAddition || record.payrollAddition.id === -1 || !record.amount) {
            deleteRow(record.id);
        }
    };

    const deleteRow = (id: any) => {
        if (id) {
            setDataSource(dataSource.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await form.validateFields();
            const newData = [...dataSource];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setDataSource(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDataSource(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const addNewRow = (event: any) => {
        event.stopPropagation();
        let newId =
            dataSource.length > 0
                ? dataSource.reduce(
                      (max: any, element: any) => (element.id > max ? element.id : max),
                      dataSource[0].id
                  ) + 1
                : 1;
        const newData = {
            id: newId,
            payrollAddition: undefined,
            amount: null,
        };
        setDataSource([...dataSource, newData]);
        edit(newData);
    };

    const columns = [
        {
            title: geti18nText('employee.payment.addition.payrollAddition.name'),
            dataIndex: 'payrollAddition',
            width: '300px',
            editable: true,
            inputType: 'searchField',
            render: (payrollAddition: any) => {
                return payrollAddition && payrollAddition.name ? payrollAddition.name : '';
            },
        },
        {
            title: geti18nText('employee.payment.addition.amount'),
            dataIndex: 'amount',
            editable: true,
            inputType: 'decimalNumber',
            render: (amount: any) => {
                return numberFormat(amount);
            },
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            width: '100px',
            render: (text: any, record: any) => {
                if (dataSource.length >= 1 && !lock && canCreate()) {
                    const editable = isEditing(record);
                    return (
                        <div>
                            {editable ? (
                                <Space size="middle" style={{ textAlign: 'right' }}>
                                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                                        <Button type="link" onClick={() => save(record.id)}>
                                            <SaveTwoTone
                                                onClick={() => save(record.id)}
                                                style={{
                                                    fontSize: '20px',
                                                }}
                                                twoToneColor="#52c41a"
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                                        <NyModalConfirm
                                            title={geti18nText('app.default.cancel.confirm')}
                                            onConfirm={() => {
                                                cancel(record);
                                            }}
                                        >
                                            <Button type="link">
                                                <CloseCircleTwoTone
                                                    style={{
                                                        fontSize: '20px',
                                                    }}
                                                    twoToneColor="#faad14"
                                                />
                                            </Button>
                                        </NyModalConfirm>
                                    </Tooltip>
                                </Space>
                            ) : (
                                <Space size="middle" style={{ textAlign: 'right' }}>
                                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                        <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                                            <EditTwoTone
                                                style={{
                                                    fontSize: '20px',
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                        <NyModalConfirm
                                            title={geti18nText('app.default.delete.confirm')}
                                            onConfirm={() => {
                                                deleteRow(record.id);
                                            }}
                                        >
                                            <Button type="link">
                                                <DeleteTwoTone
                                                    style={{
                                                        fontSize: '20px',
                                                    }}
                                                    twoToneColor="#ff7875"
                                                />
                                            </Button>
                                        </NyModalConfirm>
                                    </Tooltip>
                                </Space>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                form: form,
            }),
        };
    });

    return (
        <>
            <Row gutter={24} style={{ height: '36px' }}>
                <Col span={24}>
                    {!lock && canCreate() && (
                        <Tooltip title={geti18nText('employee.payment.addition.addNew')} placement="topRight">
                            <Button
                                style={{ float: 'right', marginRight: '12px' }}
                                icon={<PlusCircleOutlined />}
                                onClick={addNewRow}
                            />
                        </Tooltip>
                    )}
                    <NyHistoryModal columns={columns} dataSource={historyData} />
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={dataSource}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                        />
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default EmployeePaymentAddition;
