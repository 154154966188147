import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Form } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getEnumBookedArray } from '../../../../../utils/Utils';
import AssetCustomTableFilter from '../CustomFilter';

const AssetInitialStateIndex = ({ scroll = { y: 480, x: 800 }, sortOrder }: any) => {
    const location = useLocation();
    const table = useTableSettings();
    const [filterForm] = Form.useForm();
    const [refresh, setRefresh] = useState(0);
    useHelper('assets/documents/assetInitialState');

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetInitialState();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCsvExportAssetInitialState();
    };

    let defaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }];

    const setDefaultFilterValue = () => {
        let currentDefaultFilterValue = [...defaultFilterValue];
        if (filterForm.getFieldValue('asset')) {
            const assetID = filterForm.getFieldValue('asset').id;
            if (assetID != -1) {
                currentDefaultFilterValue.push({
                    field: 'asset.id',
                    condition: 'equals',
                    value: assetID.toString(),
                });
            }
        }
        if (filterForm.getFieldValue('serial_number')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number').toString(),
            });
        }
        if (filterForm.getFieldValue('serial_number2')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number2',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number2').toString(),
            });
        }
        return currentDefaultFilterValue;
    };

    const columns = [
        {
            title: geti18nText('asset.acquisition.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.ord'),
            dataIndex: 'ord',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.table.column.document'),
            dataIndex: 'documentInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.table.column.booked'),
            dataIndex: 'booked',
            width: '10%',
            render: (text: any, record: { booked: any }) => {
                if (record.booked) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBookedArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('asset.acquisition.table.column.bookedGeneralLedger'),
            dataIndex: 'bookedGeneralLedger',
            width: '10%',
            render: (text: any, record: { bookedGeneralLedger: any }) => {
                if (record.bookedGeneralLedger) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBookedArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('asset.acquisition.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <>
            <AssetCustomTableFilter setRefresh={setRefresh} refresh={refresh} form={filterForm} />
            <NyDataTable
                nyId="asset-initial-state-table"
                url={CONSTANTS_REQ.ASSET_INITIAL_STATE.LIST}
                addNewButtonText={geti18nText('asset.initialState.table.add')}
                buttonsClassName="buttons-sticky"
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                fetchWhenChange={refresh}
                hideNewButton={!canCreate()}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.assets.initialState')}
                colCSVCustomization={csvColumnCustomisation()}
                headerTitle={geti18nText('menu.assets.initialState')}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            booked: (value: any) => {
                return value ? geti18nText('app.enum.BOOKED_TYPE.1') : geti18nText('app.enum.BOOKED_TYPE.0');
            },
        },
        {
            bookedGeneralLedger: (value: any) => {
                return value ? geti18nText('app.enum.BOOKED_TYPE.1') : geti18nText('app.enum.BOOKED_TYPE.0');
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AssetInitialStateIndex;
