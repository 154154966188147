import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Collapse, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    formatCurrencyIsoCodeSearch,
    getDateFormat,
    getEnumArray,
    getEnumArrayStatus,
    getEnumFormat,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
} from '../../../../../utils/Utils';
import { IBANCheck, validateIBAN } from '../../../../../utils/Validation';
import CurrencySearch from '../../../../administration/views/currency/search';
import { PayrollRights } from '../../../../../rights/payrollRights';

const { Panel } = Collapse;

const PaymentOrdersIndividualEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('payment.orders.individual.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2', '3']);
    const [companyId, setCompanyId] = useState<any>(undefined);

    const [currencyCode, setCurrencyCode] = useState<any>('HRK');

    useEffect(() => {
        getAppSettings();
    }, []);

    useEffect(() => {
        if (!props.value && props.visible) {
            getCompanyInfo();
        }
    }, [props]);

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 1 },
            { field: 'settingGroup', condition: 'equals', value: 'COMPANY_INFO' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_CURRENCY_ISO_CODE') {
                                getCurrencyIsoCode(element.value ? element.value : element.defaultValue);
                            } else if (element.settingKey === 'COMPANY_ID') {
                                setCompanyId(element.value ? element.value : element.defaultValue);
                            }
                        });
                    }
                }
            }
        );
    };

    function getCompanyInfo() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.PAYMENT_ORDER.GET_DEBTOR, {}).then((result: any) => {
            if (result.data) {
                let debtorData: any = {};
                debtorData['debtorName'] = result.data.name ? result.data.name : '';
                debtorData['debtorAddressLine1'] = result.data.addressLine1 ? result.data.addressLine1 : '';
                debtorData['debtorAddressLine2'] = result.data.addressLine2 ? result.data.addressLine2 : '';
                debtorData['debtorAccountIban'] = result.data.accountIban
                    ? { id: result.data.accountId, iban: result.data.accountIban }
                    : '';
                debtorData['debtorReference'] = result.data.reference ? result.data.reference : '';

                form.setFieldsValue(debtorData);
            }
        });
    }

    const creditorAccountIbanChange = (value: any) => {
        if (IBANCheck(value.target.value)) {
            getCreditorInfo(value.target.value);
        }
    };

    function getCreditorInfo(iban: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.PAYMENT_ORDER.CREDITOR_INFO, { account: iban }).then(
            (result: any) => {
                if (result.data) {
                    let creditorData: any = {};
                    creditorData['creditorName'] = result.data.name ? result.data.name : '';
                    creditorData['creditorAddressLine1'] = result.data.addressLine1 ? result.data.addressLine1 : '';
                    creditorData['creditorAddressLine2'] = result.data.addressLine2 ? result.data.addressLine2 : '';
                    creditorData['creditorReference'] = result.data.reference ? result.data.reference : '';

                    form.setFieldsValue(creditorData);
                }
            }
        );
    }

    async function getCurrencyIsoCode(code: any) {
        setCurrencyCode(await formatCurrencyIsoCodeSearch(code));
    }

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('debtorName')) {
            setEditHeader(geti18nText('payment.orders.individual.edit.title') + ' - ' + dataForm.debtorName);
        }

        if (dataForm.dueDate) dataForm.dueDate = setDateFormat(dataForm.dueDate);
        if (dataForm.created) dataForm.created = setDateFormat(dataForm.created);
        if (dataForm.completedDate) dataForm.completedDate = setDateFormat(dataForm.completedDate);

        if (dataForm.debtorAccountIban) dataForm.debtorAccountIban = { id: -1, iban: dataForm.debtorAccountIban };

        if (dataForm.purposeCode !== null && dataForm.purposeCode !== undefined) {
            dataForm.purposeCode = setEnumFormat('SEPA_EXTERNAL_PURPOSE_TYPE_CODE', dataForm.purposeCode);
        }
        if (dataForm.priority !== null && dataForm.priority !== undefined) {
            dataForm.priority = setEnumFormat('SEPA_PRIORITY_TYPE_CODE', dataForm.priority);
        }
        if (dataForm.status) {
            dataForm.status = setEnumFormat('PAYMENT_ORDER_STATUS_TYPE', dataForm.status);
        }
        if (dataForm.currencyCode) {
            dataForm.currencyCode = await formatCurrencyIsoCodeSearch(dataForm.currencyCode);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payment.orders.individual.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.PAYMENT_ORDER.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            shortcuts={true}
            checkIsFormChanged={true}
            nyTestId="payment-order-individual"
            {...props}
            normalize={(values: any) => {
                if (values.dueDate) values.dueDate = getDateFormat(values.dueDate);
                if (values.created) delete values.created;
                if (values.completedDate) values.completedDate = getDateFormat(values.completedDate);
                if (values.purposeCode) values.purposeCode = getEnumFormat(values.purposeCode);
                if (values.priority) values.priority = getEnumFormat(values.priority);
                if (values.status) values.status = getEnumFormat(values.status);
                if (values.currencyCode) values.currencyCode = values.currencyCode.isoCode;
                if (values.debtorAccountIban) values.debtorAccountIban = values.debtorAccountIban.iban;

                if (!values['payment_order_group'].id) {
                    delete values['payment_order_group'];
                }

                return values;
            }}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('payment.orders.individual.edit.debtorData')}
                            key="1"
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.debtorName')}
                                        name="debtorName"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.debtorAddressLine')}
                                        name="debtorAddressLine1"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.debtorAddressLine2')}
                                        name="debtorAddressLine2"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.debtorAccountIban')}
                                        name="debtorAccountIban"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.SEARCH}
                                            setDefaultFilterValue={() => [
                                                { field: 'companyId', condition: 'equals', value: companyId },
                                            ]}
                                            map={{ id: 'id', label: 'iban' }}
                                            searchBy="bankAccount.iban"
                                            nyTestId="debtor-iban"
                                            className={''}
                                            order="bankAccount.iban"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.debtorReference')}
                                        name="debtorReference"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>

                <Col span={12}>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('payment.orders.individual.edit.creditorData')}
                            key="2"
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.creditorName')}
                                        name="creditorName"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.creditorAddressLine')}
                                        name="creditorAddressLine1"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.creditorAddressLine2')}
                                        name="creditorAddressLine2"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.creditorAccountIban')}
                                        name="creditorAccountIban"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                            {
                                                validator: validateIBAN,
                                            },
                                        ]}
                                    >
                                        <Input onChange={creditorAccountIbanChange} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.creditorReference')}
                                        name="creditorReference"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('payment.orders.individual.edit.paymentData')}
                            key="3"
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.amount')}
                                        name="amount"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} nyTestId="amount" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <CurrencySearch
                                        label={geti18nText('payment.orders.individual.edit.currencyCode')}
                                        name="currencyCode"
                                        required={true}
                                        initialValue={currencyCode}
                                        noClassName={true}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.purposeCode')}
                                        name="purposeCode"
                                    >
                                        <NySearchField
                                            options={getEnumArray('SEPA_EXTERNAL_PURPOSE_TYPE_CODE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="purpose-code"
                                            style={{ width: '100%' }}
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.priority')}
                                        name="priority"
                                        initialValue={setEnumFormat('SEPA_PRIORITY_TYPE_CODE', 0)}
                                    >
                                        <NySearchField
                                            options={getEnumArray('SEPA_PRIORITY_TYPE_CODE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="priority-code"
                                            style={{ width: '100%' }}
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.dueDate')}
                                        name="dueDate"
                                    >
                                        <NyDatePicker style={{ width: '100%' }} nyTestId="due-date" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.creditorRemittanceInfo')}
                                        name="creditorRemittanceInfo"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <TextArea onKeyDown={onKeyDownTextAreaCustom} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={12}>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('payment.orders.individual.edit.otherData')}
                            key="4"
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item name={['payment_order_group', 'id']} style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.paymentGroup')}
                                        name={['payment_order_group', 'paymentInformationId']}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.created')}
                                        name="created"
                                    >
                                        <NyDatePicker style={{ width: '100%' }} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.completedDate')}
                                        name="completedDate"
                                    >
                                        <NyDatePicker style={{ width: '100%' }} disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.individual.edit.status')}
                                        name="status"
                                        initialValue={setEnumFormat('PAYMENT_ORDER_STATUS_TYPE', 1)}
                                    >
                                        <NySearchField
                                            options={getEnumArrayStatus('PAYMENT_ORDER_STATUS_TYPE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="input-payment-order-status"
                                            style={{ width: '100%' }}
                                            disabled
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PaymentOrdersIndividualEdit;
