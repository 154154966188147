import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import EmployeeSalarySupplementsEdit from './EmployeeSalarySupplementsEdit';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';

const EmployeeSalarySupplementsIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    scroll = { y: 580, x: 800 },
    sortOrder,
    idProps,
}: any) => {
    const { id } = useParams<any>();
    const table = useTableSettings();
    const [refresh, setRefresh] = useState(0);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id ? id : idProps },
        ];
    };

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <NyDataTable
            nyId="human-employee-salary-supplements-table"
            url={CONSTANTS_REQ.EMPLOYEE_PAYROLL_ADDITION.LIST}
            addNewButtonText={geti18nText('employeeSalarySupplements.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            fetchWhenChange={id || refresh}
            readonly={readonly}
            hideButtons={readonly}
            hideNewButton={!canCreate()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultPageSize={table.setDefaultPageSize()}
            modalComponent={EmployeeSalarySupplementsEdit}
            addedData={{ id: id }}
            setDefaultFilterValue={setDefaultFilterValue}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={[
                {
                    title: geti18nText('employeeSalarySupplements.table.column.id'),
                    dataIndex: 'id',
                    width: '5%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('employeeSalarySupplements.table.column.name'),
                    dataIndex: ['payrollAddition', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchByCodebookSimple(
                        CONSTANTS_REQ.PAYROLL_ADDITION.SEARCH,
                        'payrollAddition.id',
                        'name',
                        'name',
                        {
                            id: 'id',
                            label: 'name',
                        }
                    ),
                },
                {
                    title: geti18nText('employeeSalarySupplements.table.column.value'),
                    dataIndex: 'value',
                    width: '15%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('employeeSalarySupplements.table.column.validFrom'),
                    dataIndex: 'validFrom',
                    width: '12%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(),
                    render: (text: any, record: any) => {
                        if (record.validFrom) {
                            return moment(record.validFrom).format('DD.MM.YYYY');
                        }
                    },
                },
                {
                    title: geti18nText('employeeSalarySupplements.table.column.validTo'),
                    dataIndex: 'validTo',
                    width: '12%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(),
                    render: (text: any, record: any) => {
                        if (record.validTo) {
                            return moment(record.validTo).format('DD.MM.YYYY');
                        }
                    },
                },
            ]}
            shortcuts={true}
            nyTestId="employee-salary-supplements-table"
        />
    );
};

export default EmployeeSalarySupplementsIndex;
