import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Col, Descriptions, Form, Input, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import SettlementIndex from '../../views/administration/views/settlement';
import SettlementEdit from '../../views/administration/views/settlement/edit';
import SettlementDistrictSearch from '../../views/administration/views/settlement-district/search';
import PostOfficeSearch from '../../views/administration/views/post-office/search';
import TownshipDistrictSearch from '../../views/administration/views/township-district/search';
import SettlementSearch from '../../views/administration/views/settlement/search';

const { Text } = Typography;

const Address = ({ form, settlementId, settlementResidenceId, disabled = false, employeeForm = false }: any) => {
    const [settlementData, setSettlementData] = useState<any>();
    const [settlementResidenceData, setSettlementResidenceData] = useState<any>();
    const [selectedSettlementId, setSelectedSettlementId] = useState<any>(settlementId ? settlementId : undefined);
    const [allowDistrict, setAllowDistrict] = useState<any>(false);
    const [townshipAllowDistrict, setTownshipAllowDistrict] = useState<any>(false);
    const [allowDistrictSettlement, setAllowDistrictSettlement] = useState<any>(false);
    const [townshipAllowDistrictSettlement, setTownshipAllowDistrictSettlement] = useState<any>(false);

    const [selectedSettlementResidenceId, setSelectedSettlementResidenceId] = useState<any>(
        settlementResidenceId ? settlementResidenceId : undefined
    );

    useEffect(() => {
        setSelectedSettlementId(settlementId);
    }, [settlementId]);

    const setTownshipDistrictFilterValue = () => {
        return [{ field: 'township.id', condition: 'equals', value: settlementResidenceData?.township?.id }];
    };
    const setTownshipDistrictFilterValueSettlement = () => {
        return [{ field: 'township.id', condition: 'equals', value: settlementData?.township?.id }];
    };

    useEffect(() => {
        setSelectedSettlementResidenceId(settlementResidenceId);
    }, [settlementResidenceId]);

    useEffect(() => {
        getSettlement(selectedSettlementId);
    }, [selectedSettlementId]);

    useEffect(() => {
        setAllowDistrict(settlementResidenceData?.allowDistrict);
        setTownshipAllowDistrict(settlementResidenceData?.township?.allowDistrict);
    }, [settlementResidenceData]);

    useEffect(() => {
        setAllowDistrictSettlement(settlementData?.allowDistrict);
        setTownshipAllowDistrictSettlement(settlementData?.township?.allowDistrict);
    }, [settlementData]);

    const customRenderOption = (option: any) => {
        return (
            <div custom-data={option}>
                <span style={{ display: 'block' }}>{option?.text && option.text}</span>
            </div>
        );
    };

    const clearTownshipDistrictField = () => {
        form.setFieldsValue({ address: { townshipDistrict: '' } });
    };

    const clearSettlementDistrictField = () => {
        form.setFieldsValue({ address: { settlementDistrict: '' } });
    };
    useEffect(() => {
        getSettlementResidence(selectedSettlementResidenceId);
    }, [selectedSettlementResidenceId]);

    const getSettlement = (id: any) => {
        setSettlementData(null);
        if (id && id != -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.SETTLEMENT.EDIT + '/' + id).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setSettlementData(result?.data);
                }
            });
        }
    };

    const getSettlementResidence = (id: any) => {
        setSettlementResidenceData(null);
        if (id && id != -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.SETTLEMENT.EDIT + '/' + id).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setSettlementResidenceData(result?.data);
                }
            });
        }
    };

    const getAddressForm = () => (
        <>
            <Row gutter={24}>
                {townshipAllowDistrictSettlement == true ? (
                    <Col span={12}>
                        <TownshipDistrictSearch
                            selectedTownshipId={settlementData?.township?.id}
                            setDefaultFilterValue={setTownshipDistrictFilterValueSettlement}
                            disabled={disabled}
                        />
                    </Col>
                ) : null}

                {allowDistrictSettlement ? (
                    <Col span={12}>
                        <SettlementDistrictSearch
                            label={geti18nText('settlement.district.district')}
                            required={false}
                            selectedSettlementId={selectedSettlementId}
                            disabled={disabled}
                        />
                    </Col>
                ) : null}
            </Row>
            <Row gutter={24}>
                <Col span={employeeForm ? 8 : 12}>
                    <Form.Item name={['address', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('employee.edit.address')} name={['address', 'street']}>
                        <Input disabled={disabled} />
                    </Form.Item>
                </Col>
                {employeeForm && (
                    <Col span={4}>
                        <Form.Item label={geti18nText('employee.edit.houseNumber')} name={['address', 'houseNumber']}>
                            <Input disabled={disabled} />
                        </Form.Item>
                    </Col>
                )}
                <Col span={12}>
                    <SettlementSearch
                        label={geti18nText('employee.edit.settlement')}
                        name={['address', 'settlement']}
                        onChange={(e: any) => {
                            setSelectedSettlementId(e?.id);
                            e?.id == -1 ? setSettlementData(undefined) : getSettlement(e?.id);
                            clearTownshipDistrictField();
                            clearSettlementDistrictField();
                        }}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            {selectedSettlementId && settlementData && (
                <Descriptions column={1} bordered size="small" labelStyle={{ width: '110px' }}>
                    <Descriptions.Item label={geti18nText('employee.edit.residence.postOffice')}>
                        {settlementData?.postOffice?.id && (
                            <Text>
                                {(settlementData?.postOffice?.zipCode ? settlementData?.postOffice?.zipCode : '') +
                                    ' ' +
                                    settlementData?.postOffice?.name}
                            </Text>
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('employee.edit.residence.township')}>
                        <Text>{settlementData?.township?.name ?? ''}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('employee.edit.residence.county')}>
                        <Text>{settlementData?.county?.name ?? ''}</Text>
                    </Descriptions.Item>
                </Descriptions>
            )}
        </>
    );

    const getResidenceForm = () => (
        <>
            <Row gutter={24}>
                {townshipAllowDistrict == true ? (
                    <Col span={12}>
                        <TownshipDistrictSearch
                            selectedTownshipId={settlementResidenceData?.township?.id}
                            setDefaultFilterValue={setTownshipDistrictFilterValue}
                            disabled={disabled}
                            name={['employeeAddress', 'address', 'townshipDistrict']}
                        />
                    </Col>
                ) : null}

                {allowDistrict ? (
                    <Col span={12}>
                        <SettlementDistrictSearch
                            label={geti18nText('settlement.district.district')}
                            name={['employeeAddress', 'address', 'settlementDistrict']}
                            required={false}
                            selectedSettlementId={selectedSettlementResidenceId}
                            disabled={disabled}
                        />
                    </Col>
                ) : null}

                {/* <Col span={12}>
                    <PostOfficeSearch disabled={disabled} />
                </Col> */}
            </Row>
            <Row gutter={24}>
                <Col span={employeeForm ? 8 : 12}>
                    <Form.Item name={['employeeAddress', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['employeeAddress', 'address', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['employeeAddress', 'address', 'type']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('employee.edit.residence.street')}
                        name={['employeeAddress', 'address', 'street']}
                    >
                        <Input disabled={disabled} />
                    </Form.Item>
                </Col>
                {employeeForm && (
                    <Col span={4}>
                        <Form.Item
                            label={geti18nText('employee.edit.houseNumber')}
                            name={['employeeAddress', 'address', 'houseNumber']}
                        >
                            <Input disabled={disabled} />
                        </Form.Item>
                    </Col>
                )}
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.edit.residence.settlement')}
                        name={['employeeAddress', 'address', 'settlement']}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.SETTLEMENT.SEARCH}
                            map={{ id: 'id', label: 'text', allowDistrict: 'allowDistrict', township: 'township' }}
                            searchBy="name"
                            SearchPopupComponent={<SettlementIndex />}
                            AddNewModalComponent={SettlementEdit}
                            nyTestId="settlement"
                            onChange={(e: any) => {
                                if (e.text?.props && e.text?.props['custom-data']) {
                                    setAllowDistrict(e.text?.props['custom-data']?.allowDistrict);
                                    setTownshipAllowDistrict(e.text?.props['custom-data']?.township?.allowDistrict);
                                } else {
                                    setAllowDistrict(false);
                                    setTownshipAllowDistrict(false);
                                }
                                setSelectedSettlementResidenceId(e.id);
                            }}
                            renderOption={customRenderOption}
                            style={{ height: '26px' }}
                            disabled={disabled}
                            customListWidth={'1200px'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}></Row>
            {selectedSettlementResidenceId && settlementResidenceData && (
                <Descriptions column={1} bordered size="small" labelStyle={{ width: '110px' }}>
                    <Descriptions.Item label={geti18nText('employee.edit.residence.postOffice')}>
                        {settlementResidenceData?.postOffice?.id && (
                            <Text>
                                {(settlementResidenceData?.postOffice?.zipCode
                                    ? settlementResidenceData?.postOffice?.zipCode
                                    : '') +
                                    ' ' +
                                    settlementResidenceData?.postOffice?.name}
                            </Text>
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('employee.edit.residence.township')}>
                        <Text>{settlementResidenceData?.township?.name ?? ''}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('employee.edit.residence.county')}>
                        <Text>{settlementResidenceData?.county?.name ?? ''}</Text>
                    </Descriptions.Item>
                </Descriptions>
            )}
        </>
    );

    return (
        <React.Fragment>
            {settlementId ? getAddressForm() : settlementResidenceId ? getResidenceForm() : <div></div>}
        </React.Fragment>
    );
};

export default Address;
