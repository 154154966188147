import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button } from 'antd';
import { ReactText, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { PayrollRights } from '../../../../../rights/payrollRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getDateFormat, getEnumArray, getEnumBooleanArray, numberFormat } from '../../../../../utils/Utils';
import AddToBulkModal from './AddToBulkModal';
import PaymentOrdersGroupEdit from './edit';

const PaymentOrdersGroupIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    defaultFilterValue,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const table = useTableSettings();

    useHelper('payment_order/group');

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    const columns = [
        {
            title: geti18nText('payment.orders.group.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.paymentInformation'),
            dataIndex: 'paymentInformationId',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.paymentMethod'),
            dataIndex: 'paymentMethod',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArray('SEPA_PAYMENT_METHOD_TYPE_CODE'), 'in', undefined),
            render: (text: any, record: any) => {
                if (record.paymentMethod !== undefined) {
                    return geti18nText('app.enum.SEPA_PAYMENT_METHOD_TYPE_CODE.' + record.paymentMethod);
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.batchBooking'),
            dataIndex: 'batchBooking',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { batchBooking: any }) => {
                if (record.batchBooking) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('payment.orders.group.table.column.numberOfTransactions'),
            dataIndex: 'numberOfTransactions',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.controlSum'),
            dataIndex: 'controlSum',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (controlSum: any) => {
                if (controlSum) {
                    return numberFormat(controlSum);
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.priority'),
            dataIndex: 'priority',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('SEPA_PRIORITY_TYPE_CODE'), 'equals'),
            render: (text: any, record: any) => {
                if (record.priority !== undefined) {
                    return geti18nText('app.enum.SEPA_PRIORITY_TYPE_CODE.' + record.priority);
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.requestedExecutionDate'),
            dataIndex: 'requestedExecutionDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.requestedExecutionDate) {
                    return getDateFormat(record.requestedExecutionDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.debtorName'),
            dataIndex: 'debtorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.debtorAccountIban'),
            dataIndex: 'debtorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
    ];

    let clearKeys: any;

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    const AddToGroup = (onSave: () => void) => {
        const [modalVisible, setModalVisible] = useState(false);
        return (
            <>
                {hasSelected && (
                    <>
                        <Button onClick={() => setModalVisible(true)}>
                            {geti18nText('payment.orders.group.AddToBulk.title')}
                        </Button>
                        <AddToBulkModal
                            onSave={onSave}
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                            selectedValues={selectedValues}
                            clearKeys={clearKeys}
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <NyDataTable
            nyId="payment-orders-group"
            url={CONSTANTS_REQ.PAYMENT_ORDER_GROUP.LIST}
            addNewButtonText={geti18nText('payment.orders.group.table.add.new')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={PaymentOrdersGroupEdit}
            showRowSelection={showRowSelection ? showRowSelection : true}
            rowSelectionType={rowSelectionType ? rowSelectionType : 'checkbox'}
            rowSelectionModal={rowSelectionModal ? rowSelectionModal : (setSelectedValuesFunc as any)}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(defaultFilterValue)}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            rowSelectionProps={
                showRowSelection
                    ? undefined
                    : (record: any) => ({
                          disabled:
                              !record.paymentOrderBulk &&
                              record.numberOfTransactions &&
                              record.numberOfTransactions > 0 &&
                              record.paymentMethod === 1
                                  ? false
                                  : true, // Column configuration not to be checked
                      })
            }
            addedButtons={AddToGroup}
            fetchWhenChange={refreshItem}
            selectOnSave={false}
            hideNewButton={!canCreate()}
        />
    );
};

export default PaymentOrdersGroupIndex;
