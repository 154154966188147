import {
    getColumnSearch,
    getColumnSearchCheckbox,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
} from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const UsersGroupsIndex = ({ fetchWhenChange }: any) => {
    const [modules, setModules] = useState<any[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const table = useTableSettings();

    useEffect(() => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER_AUTH.MODULES, { userId: 1 }, true).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    let data = result.data;
                    setModules(
                        data.map((logicalModule: any) => {
                            return { id: logicalModule.id, text: logicalModule.name };
                        })
                    );
                    setGroups(
                        data
                            .flatMap((logicalModule: any) => {
                                return logicalModule.groupList?.map((group: any) => {
                                    return { id: group.id, text: group.name };
                                });
                            })
                            .filter(Boolean)
                    );
                }
            }
            setLoading(false);
        });
    }, []);

    const columns = [
        {
            title: geti18nText('user.table.column.username'),
            dataIndex: ['users', 'username'],
            width: '18%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                return record.user?.username;
            },
        },
        {
            title: geti18nText('user.table.column.firstName'),
            dataIndex: ['users', 'firstName'],
            width: '18%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                return record.user?.firstName;
            },
        },
        {
            title: geti18nText('user.table.column.lastName'),
            dataIndex: ['users', 'lastName'],
            width: '18%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                return record.user?.lastName;
            },
        },
        {
            title: geti18nText('user.auth.groups.table.column.group'),
            dataIndex: ['groups', 'id'],
            width: '18%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(groups, 'in'),
            render: (text: any, record: any) => {
                return record.group?.name;
            },
        },
        {
            title: geti18nText('user.auth.groups.table.column.module'),
            dataIndex: ['logicalModule', 'id'],
            width: '18%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(modules, 'in'),
            render: (text: any, record: any) => {
                return record.logicalModule?.name;
            },
        },
    ];

    return loading ? (
        <NySpinner />
    ) : (
        <NyDataTable
            nyId={'user-modules-administration'}
            rowKey={''}
            url={CONSTANTS_REQ.USER_AUTH.MODULES_USERS_LIST}
            columns={columns}
            scroll={{ y: 640 }}
            hideNewButton={true}
            fetchWhenChange={fetchWhenChange}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
        ></NyDataTable>
    );
};

export default UsersGroupsIndex;
