import { NyDataEdit, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customRenderNameOption, setSearchFormat } from '../../../../utils/Utils';
import EducationalInstitutionIndex from '../educational-institution';
import EducationalInstitutionEdit from '../educational-institution/edit';
import EducationalInstitutionSearch from '../educational-institution/search';
import EducationalTitleIndex from '../educational-title';
import EducationalTitleEdit from '../educational-title/edit';
import QualificationLevelIndex from '../qualification-level';
import QualificationLevelEdit from '../qualification-level/edit';

const EducationalProgramEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('educational.program.edit.new'));
    const [loading, setLoading] = useState(false);
    const [qualificationLevelGroup, setQualificationLevelGroup] = useState<any>();

    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    useEffect(() => {
        if (props?.editProps) {
            setValues();
        }
    }, []);

    function setValues(dataForm?: any, isForFormMerge?: boolean) {
        let addedValues = props?.addedData?.form?.getFieldsValue()
            ? props?.addedData?.form?.getFieldsValue()
            : props?.editProps?.form?.getFieldsValue();

        let filteredMergedData: any = dataForm ? dataForm : {};

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (!isForFormMerge) {
            if (addedValues?.qualificationLevel) {
                filteredMergedData.qualificationLevel = setSearchFormat(addedValues.qualificationLevel, 'name', 'name');
            }

            if (addedValues?.educationalInstitution) {
                if (addedValues?.educationalInstitution?.name?.props) {
                    addedValues.educationalInstitution.name =
                        addedValues.educationalInstitution.name.props['custom-data'].name;
                    filteredMergedData.educationalInstitution = setSearchFormat(
                        addedValues.educationalInstitution,
                        'name',
                        'name'
                    );
                } else {
                    filteredMergedData.educationalInstitution = setSearchFormat(
                        addedValues.educationalInstitution,
                        'name',
                        'name'
                    );
                }
            }

            if (addedValues?.educationalTitle) {
                form.setFieldsValue({
                    educationalTitle: {
                        id: addedValues.educationalTitle.id,
                        name: addedValues.educationalTitle.name,
                        shortName: addedValues.educationalTitle.shortName,
                    },
                });
            }
            if (addedValues?.ects) {
                filteredMergedData.ects = addedValues.ects;
            }
        }

        if (isForFormMerge) {
            const oldFormData = form.getFieldsValue();
            const mergedData = { ...form.getFieldsValue(), ...dataForm };

            Object.keys(mergedData).forEach((key) => {
                if (mergedData[key] !== null && mergedData[key] !== undefined) {
                    filteredMergedData[key] = mergedData[key];
                }
            });
        }

        if (filteredMergedData.qualificationLevel) {
            filteredMergedData.qualificationLevel = setSearchFormat(
                filteredMergedData.qualificationLevel,
                'name',
                'name'
            );
        }

        if (filteredMergedData.educationalInstitution) {
            filteredMergedData.educationalInstitution = setSearchFormat(
                filteredMergedData.educationalInstitution,
                'name',
                'name'
            );
        }

        if (filteredMergedData.educationalTitle) {
            filteredMergedData.educationalTitle = setSearchFormat(filteredMergedData.educationalTitle, 'name', 'name');
        }

        if (filteredMergedData.hasOwnProperty('name')) {
            setEditHeader(geti18nText('educational.program.tab') + ' - ' + filteredMergedData.name);
        }

        delete filteredMergedData.active;
        form.setFieldsValue(filteredMergedData);
    }

    const onModalClose = () => {
        props?.addedData?.setRefresh(props?.addedData?.refresh + 1);

        form.resetFields();
        setEditHeader(geti18nText('menu.professional.qualification'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.EDUCATIONAL_PROGRAM.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            onBeforeSave={(e: any) => {
                props?.addedData?.setMainFormValues(
                    { ...form.getFieldsValue(), ...{ qualificationLevelGroup: qualificationLevelGroup } },
                    true
                );
                props?.editProps?.setMainFormValues(
                    { ...form.getFieldsValue(), ...{ qualificationLevelGroup: qualificationLevelGroup } },
                    true
                );
            }}
            goBack={() => history.goBack()}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            {...props}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.qualificationLevel) {
                    values.qualificationLevel = setSearchFormat(values.qualificationLevel, 'name', 'name');
                }

                if (values.educationalInstitution) {
                    values.educationalInstitution = setSearchFormat(values.educationalInstitution, 'name', 'name');
                }

                if (values.educationalTitle) {
                    values.educationalTitle = setSearchFormat(values.educationalTitle, 'name', 'name');
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('qualification.level.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('educational.program.edit.nr.years')}
                        name="years"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('educational.program.edit.ects')}
                        name="ects"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('educational.program.edit.nr.semester')}
                        name="semester"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Col span={24}>
                <EducationalInstitutionSearch
                    required={true}
                    AddNewModalComponent={canCreate() ? EducationalInstitutionEdit : null}
                    AddNewModalComponentAddedData={{
                        form: form,
                        setMainFormValues: setValues,
                        educationLevel: props?.addedData?.form?.getFieldValue('educationLevel'),
                    }}
                />
            </Col>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('educational.program.edit.educational.title')}
                        name={'educationalTitle'}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EDUCATIONAL_TITLE.SEARCH}
                            map={{ id: 'id', label: 'name', name: 'name', shortName: 'shortName' }}
                            searchBy="name"
                            renderOption={customRenderNameOption}
                            onChange={(e: any) => {
                                e?.name?.props &&
                                    e?.name?.props['custom-data']?.name &&
                                    form.setFieldsValue({
                                        educationalTitle: {
                                            id: e.id,
                                            name: e?.name?.props['custom-data']?.name,
                                            shortName: e?.name?.props['custom-data']?.shortName,
                                        },
                                    });
                            }}
                            SearchPopupComponent={<EducationalTitleIndex />}
                            AddNewModalComponent={canCreate() ? EducationalTitleEdit : null}
                            nyTestId="educationalTitle"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('educational.program.edit.qualification.level')}
                        name={'qualificationLevel'}
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.QUALIFICATION_LEVEL.SEARCH}
                            map={{
                                id: 'id',
                                label: 'name',
                                name: 'name',
                                code: 'code',
                                qualificationLevelGroup: 'qualificationLevelGroup',
                            }}
                            customItemNameLabel={'name'}
                            searchBy="name"
                            renderOption={customRenderNameOption}
                            onChange={(e: any) => {
                                if (e?.name?.props) {
                                    form.setFieldsValue({
                                        qualificationLevel: {
                                            id: e?.id,
                                            name: e?.name?.props && e?.name?.props['custom-data']?.name,
                                        },
                                    });
                                    setQualificationLevelGroup(
                                        e?.name?.props && e?.name?.props['custom-data']?.qualificationLevelGroup
                                    );
                                }
                            }}
                            AddNewModalComponentAddedData={{
                                form: form,
                                setMainFormValues: setValues,
                                qualificationLevelGroup: qualificationLevelGroup,
                                setQualificationLevelGroup: setQualificationLevelGroup,
                            }}
                            SearchPopupComponent={<QualificationLevelIndex />}
                            customListWidth={'1200px'}
                            AddNewModalComponent={canCreate() ? QualificationLevelEdit : null}
                            nyTestId="qualificationLevel"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EducationalProgramEdit;
