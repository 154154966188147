import { DeleteTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Descriptions, Row, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getErrorNotificationMessage, okNotification } from '../../../../../utils/Utils';
import TaskTemplateApprovalUserIndex from './approvalUserIndex';

const TaskTemplateApprovalEdit = ({
    taskTemplateId = null,
    item = undefined,
    items,
    setItems,
    setRefresh,
    selectedTeam = 998,
}: any) => {
    const [addNewItem, setAddNewItem] = useState(0);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);

    const showTeam = () => {
        return selectedTeam == 999;
    };

    const deleteRow = (record: any) => {
        if (record?.id) {
            record.taskTemplate = { id: taskTemplateId };
            record.active = false;
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TASK_TEMPLATE_APPROVAL.EDIT + '/' + record.id,
                undefined,
                record
            ).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    setRefresh((refresh: any) => refresh + 1);
                } else {
                    getErrorNotificationMessage(result);
                }
            });
        } else {
            setItems(items.filter((level: any) => level?.approvalItems.filter((item: any) => item.id !== null)));
        }
    };

    const addUser = () => {
        setAddNewItem((addNewItem) => addNewItem + 1);
        setAddItemInProcess(true);
    };

    const ActionEditable = (item: any) => {
        return (
            <Space size="small" style={{ width: '65px' }}>
                <Tooltip placement="top" title={geti18nText('task.template.approval.add.user')}>
                    <Button type="link" onClick={addUser} disabled={addItemInProcess}>
                        <PlusCircleOutlined
                            style={{
                                fontSize: '20px',
                            }}
                            twoToneColor="#52c41a"
                        />
                    </Button>
                </Tooltip>
                <NyModalConfirm
                    title={geti18nText('app.default.delete.confirm')}
                    onConfirm={() => {
                        deleteRow(item);
                    }}
                >
                    <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                        <Button type="link">
                            <DeleteTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#ff7875"
                            />
                        </Button>
                    </Tooltip>
                </NyModalConfirm>
            </Space>
        );
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Descriptions column={showTeam() ? 3 : 2} bordered size="small">
                        {showTeam() && (
                            <Descriptions.Item
                                label={geti18nText('task.template.approval.team')}
                                labelStyle={{ width: '50px' }}
                                contentStyle={{ width: '200px' }}
                            >
                                {item?.team?.name ?? ''}
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item
                            label={geti18nText('task.template.approval.approvalType')}
                            labelStyle={{ width: '150px' }}
                            contentStyle={{ width: '200px' }}
                        >
                            {item?.taskApprovalType
                                ? geti18nText('app.enum.TASK_APPROVAL_TYPE.' + item?.taskApprovalType)
                                : ''}
                        </Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '0px' }} contentStyle={{ width: '65px' }}>
                            {ActionEditable(item)}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row
                gutter={24}
                // style={{
                //     display: item?.taskTemplateApprovalUserList?.length > 0 || addNewItem > 0 ? 'block' : 'none',
                // }}
            >
                <Col span={24}>
                    <TaskTemplateApprovalUserIndex
                        taskTemplateApprovalId={item?.id}
                        addNewItem={addNewItem}
                        addItemInProcess={addItemInProcess}
                        setAddItemInProcess={setAddItemInProcess}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TaskTemplateApprovalEdit;
