import { NySpinner } from '@nybble/nyreact';
import { Form, Radio } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getDateFormat, getEnumFormat } from '../../utils/Utils';
import TravelWarrantAdditionalInfoEdit from './edit';

const TravelWarrantAdditionalInfoIndex = ({
    data,
    setData,
    refresh,
    saveFormValuesOnNext,
    setSaveFormValuesOnNext,
    travelWarrantEmployeeId,
    isPlane = false,
    needTransport = false,
}: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [destinations, setDestinations] = useState<any>([]);
    const [destination, setDestination] = useState<any>(null);
    const [item, setItem] = useState<any>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        getData();
    }, [refresh]);

    useEffect(() => {
        if (saveFormValuesOnNext > 0) {
            saveFormValues(null);
            setSaveFormValuesOnNext(0);
        }
    }, [saveFormValuesOnNext]);

    const getData = () => {
        setLoading(true);
        if (data?.length > 0) {
            const newData: any = data.map((dataItem: any) => dataItem.destination);
            const tempData = newData != undefined && newData[0] != undefined ? newData : data;
            setDestinations(tempData);
            onChangeDestination(tempData[0]);
        } else {
            setDestinations([]);
            onChangeDestination(null);
        }
    };

    const onChangeDestination = (value: any) => {
        setLoading(true);
        if (item?.destination) {
            saveFormValues(value);
        } else {
            setItemValue(value);
        }
        setLoading(false);
    };

    const setItemValue = (value: any) => {
        if (value) {
            setDestination(value);
            const index = data.findIndex((dat: any) => dat?.destination === value);
            setItem(index > -1 && data[index] ? data[index] : data[0] ? data[0] : null);
        } else {
            setDestination(null);
            setItem(null);
        }
    };

    const saveFormValues = (value: any) => {
        const values: any = normalizeValues(form.getFieldsValue());
        const newData = [...data];
        const index = newData.findIndex((dat: any) => dat?.destination === item?.destination);
        if (index > -1) {
            values.destination = newData[index].destination;
            newData.splice(index, 1, values);
            setData(newData);
            if (value) {
                setItemValue(value);
            }
        }
        form.resetFields();
    };

    const normalizeValues = (values: any) => {
        if (values.needAccommodation) {
            values.needAccommodation = getEnumFormat(values.needAccommodation);
        }

        if (values.needTransport) {
            values.needTransport = getEnumFormat(values.needTransport);
        }

        if (values.baggageType) {
            values.baggageType = getEnumFormat(values.baggageType);
        }

        if (values.arrivalDate) {
            values.arrivalDate = getDateFormat(moment(values.arrivalDate).format('yyyy-MM-DD HH:mm'));
        }

        if (values.returnDate) {
            values.returnDate = getDateFormat(moment(values.returnDate).format('yyyy-MM-DD HH:mm'));
        }

        if (values.arrivalDayPart) {
            values.arrivalDayPart = getEnumFormat(values.arrivalDayPart);
        }

        if (values.returnDayPart) {
            values.returnDayPart = getEnumFormat(values.returnDayPart);
        }
        if (values.travelVehicle) {
            if (values.vehicleType) {
                values.travelVehicle.vehicleType = getEnumFormat(values.vehicleType);
            }
        }

        if (values.travelVehicle?.id < 0) {
            delete values.travelVehicle;
        }

        values.showTime = values.showTime ? 1 : 0;
        return values;
    };

    return (
        <React.Fragment>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    {data?.length > 1 && (
                        <Radio.Group
                            options={destinations}
                            value={destination}
                            onChange={(e: any) => onChangeDestination(e?.target?.value)}
                            buttonStyle="solid"
                            optionType="button"
                            style={{ marginBottom: '15px' }}
                        />
                    )}
                    <TravelWarrantAdditionalInfoEdit
                        item={item}
                        form={form}
                        travelWarrantEmployeeId={travelWarrantEmployeeId}
                        isPlane={isPlane}
                        needTransport={needTransport}
                    />
                </>
            )}
        </React.Fragment>
    );
};

export default TravelWarrantAdditionalInfoIndex;
