import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import TaskListIndexGeneral from './TaskListIndexGeneral';

const TaskListIndexWatched = ({ setRefresh, refresh, listType, type }: any) => {
    const setDefaultFilterValue = [
        {
            field: 'active',
            condition: 'equals_bool',
            value: 1,
        },
    ];
    return (
        <TaskListIndexGeneral
            setRefresh={setRefresh}
            refresh={refresh}
            listType={listType}
            type={type}
            url={CONSTANTS_REQ.TASK_WATCHERS.WATCHED_TASKS_LIST}
            setDefaultFilterValue={setDefaultFilterValue}
            watcherList={true}
        />
    );
};

export default TaskListIndexWatched;
