import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, List, message, Modal, PageHeader, Row, Tooltip, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum } from '../../../../utils/Enums';
import { downloadFile, getEnumArray } from '../../../../utils/Utils';
import ReportSettingsTable from './ReportSettingsTable';

const { Panel } = Collapse;

const PrintSettingsIndex = ({ canCreate }: any) => {
    const [loading, setLoading] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [subreportSettings, setSubreportSettings] = useState([]);
    const [subreportTypeEnums] = useState(GetEnum({ enumName: 'SUBREPORT_TYPE' }));
    const [subreportHeaderDimensions] = useState(
        getEnumArray('SUBREPORT_HEADER_DIMENSIONS', 'text', 'id', 'SUBREPORT_DIMENSIONS')
    );
    const [subreportFooterDimensions] = useState(
        getEnumArray('SUBREPORT_FOOTER_DIMENSIONS', 'text', 'id', 'SUBREPORT_DIMENSIONS')
    );
    const [pdfVisible, setPdfVisible] = useState(false);
    const [pdfFile, setPdfFile] = useState<any>(undefined);
    const [pdfTitle, setPdfTitle] = useState<any>(undefined);

    useEffect(() => {
        getPrintSettings();
    }, []);

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const setDefaultSubreportFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'type',
                condition: 'in',
                value: `${subreportTypeEnums['HEADER']},${subreportTypeEnums['FOOTER']}`,
            },
        ];
    };

    const getPrintSettings = () => {
        let subreportSettings: any = [];
        const headerType = {
            id: subreportTypeEnums['HEADER'],
            text: geti18nText('app.enum.SUBREPORT_TYPE.' + subreportTypeEnums['HEADER']),
        };
        const footerType = {
            id: subreportTypeEnums['FOOTER'],
            text: geti18nText('app.enum.SUBREPORT_TYPE.' + subreportTypeEnums['FOOTER']),
        };
        subreportHeaderDimensions.map((dimensions: any, index: number) => {
            subreportSettings.push({ type: headerType, dimensions: dimensions });
        });
        subreportFooterDimensions.map((dimensions: any, index: number) => {
            subreportSettings.push({ type: footerType, dimensions: dimensions });
        });

        setSubreportSettings(subreportSettings);
        NyRequestResolver.requestGet(CONSTANTS_REQ.SUBREPORT.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultSubreportFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    let newSubreportSettings = subreportSettings.map((subreportSetting: any) => {
                        let newSubreportSetting = { ...subreportSetting };
                        result.data.map((subreport: any) => {
                            if (
                                subreportSetting.type.id === subreport.type &&
                                subreportSetting.dimensions.id == subreport.dimensions
                            ) {
                                newSubreportSetting = {
                                    ...newSubreportSetting,
                                    id: subreport.id,
                                    hasContent: subreport.hasContent,
                                };
                            }
                        });
                        return newSubreportSetting;
                    });
                    setSubreportSettings(newSubreportSettings);
                }
            }
        });
    };

    const upload = {
        name: 'file',
        accept: '.jrxml,.jasper',
        action: CONSTANTS_REQ.SUBREPORT.UPLOAD,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                if (info.file.response) {
                    const result = info.file.response;
                    let newSubreportSettings: any = subreportSettings.map((subreportSetting: any) => {
                        let newSubreportSetting = { ...subreportSetting };
                        if (
                            result.xmlString &&
                            result.type === subreportSetting.type.id &&
                            result.dimensions === subreportSetting.dimensions.id
                        ) {
                            newSubreportSetting.hasContent = true;
                        }
                        return newSubreportSetting;
                    });
                    setSubreportSettings(newSubreportSettings);
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    const preview = (type: number, dimensions: number) => {
        setLoading(true);
        message.loading(geti18nText('import.text.loading'));
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.SUBREPORT.PREVIEW,
            { type: type, dimensions: dimensions, lang: NyUtils.getSelectedLanguage() },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            message.destroy();
            if (result.status === RESPONSE.OK && result.data) {
                setPdfFile(window.URL.createObjectURL(result.data.file));
                setPdfTitle(
                    `${geti18nText('app.enum.SUBREPORT_TYPE.' + type)} (${geti18nText(
                        'app.enum.SUBREPORT_DIMENSIONS.' + dimensions
                    )})`
                );
                setPdfVisible(true);
            }
        });
    };

    const download = (type: number, dimensions: number) => {
        setLoading(true);
        message.loading(geti18nText('import.text.loading'), 0);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.SUBREPORT.DOWNLOAD,
            { type: type, dimensions: dimensions },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = `${geti18nText('app.enum.SUBREPORT_TYPE.' + type)}_${geti18nText(
                    'app.enum.SUBREPORT_DIMENSIONS.' + dimensions
                )}.jrxml`;
                downloadFile(result);
            }
            message.destroy();
        });
    };

    return (
        <>
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.REPORTS')} key="1">
                            <ReportSettingsTable canCreate={canCreate} />
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.SUBREPORTS')} key="2">
                            {pdfVisible && (
                                <Modal
                                    centered={true}
                                    width={'800px'}
                                    maskClosable={false}
                                    visible={pdfVisible}
                                    title={pdfTitle}
                                    onOk={(event: any) => {
                                        setPdfVisible(false);
                                        setPdfTitle(undefined);
                                        setPdfFile(undefined);
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                    forceRender={true}
                                    closable={false}
                                    cancelButtonProps={{ style: { display: 'none' } }}
                                >
                                    {pdfFile && (
                                        <object
                                            style={{ height: '600px', width: '100%' }}
                                            data={pdfFile}
                                            type={'application/pdf'}
                                        ></object>
                                    )}
                                </Modal>
                            )}
                            <List>
                                {subreportSettings &&
                                    subreportSettings.map((subreportSetting: any, index: number) => {
                                        return (
                                            <List.Item>
                                                <PageHeader
                                                    style={{ width: '100%' }}
                                                    title={subreportSetting.type.text}
                                                    subTitle={subreportSetting.dimensions.text}
                                                    extra={
                                                        subreportSetting.hasContent
                                                            ? [
                                                                  <Upload
                                                                      {...upload}
                                                                      data={{
                                                                          type: subreportSetting.type.id,
                                                                          dimensions: subreportSetting.dimensions.id,
                                                                      }}
                                                                  >
                                                                      {canCreate() && (
                                                                          <Button disabled={loading}>
                                                                              {geti18nText(
                                                                                  'app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_UPLOAD'
                                                                              )}
                                                                          </Button>
                                                                      )}
                                                                  </Upload>,
                                                                  <Button
                                                                      disabled={loading}
                                                                      onClick={() =>
                                                                          preview(
                                                                              subreportSetting.type.id,
                                                                              subreportSetting.dimensions.id
                                                                          )
                                                                      }
                                                                  >
                                                                      {geti18nText(
                                                                          'app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_PREVIEW'
                                                                      )}
                                                                  </Button>,
                                                                  <Button
                                                                      disabled={loading}
                                                                      onClick={() =>
                                                                          download(
                                                                              subreportSetting.type.id,
                                                                              subreportSetting.dimensions.id
                                                                          )
                                                                      }
                                                                  >
                                                                      {geti18nText(
                                                                          'app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_DOWNLOAD'
                                                                      )}
                                                                  </Button>,
                                                              ]
                                                            : [
                                                                  <Upload
                                                                      {...upload}
                                                                      data={{
                                                                          type: subreportSetting.type.id,
                                                                          dimensions: subreportSetting.dimensions.id,
                                                                      }}
                                                                  >
                                                                      {canCreate() && (
                                                                          <Button disabled={loading}>
                                                                              {geti18nText(
                                                                                  'app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_UPLOAD'
                                                                              )}
                                                                          </Button>
                                                                      )}
                                                                  </Upload>,
                                                              ]
                                                    }
                                                ></PageHeader>
                                            </List.Item>
                                        );
                                    })}
                            </List>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                </Col>
            </Row>
        </>
    );
};

export default PrintSettingsIndex;
