import { geti18nText, NyRequestResolver, NySession, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Button, Result } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AdministrationRights } from '../../rights/administrationRights';
import { setProp } from '../../slices/propSlice';
// import { setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';

const TravelWarrantApprovalIndex = () => {
    const history = useHistory();
    let { search } = useLocation();
    const query: any = new URLSearchParams(search);
    const [loading, setLoading] = useState<any>(true);
    const [type, setType] = useState<any>('travelWarrant');
    const [ord, setOrd] = useState<any>(undefined);
    const [success, setSuccess] = useState<any>(true);
    const [changeStatus, setChangeStatus] = useState<any>(undefined);
    const [status, setStatus] = useState<any>(undefined);
    const dispatch = useDispatch();

    const canChangeOrderStatus = () => {
        return AdministrationRights.isAdmin();
    };

    useEffect(() => {
        setChangeStatus(true);
    }, []);

    useEffect(() => {
        if (changeStatus) {
            if (query.get('warrant') != undefined) {
                var warrant = JSON.parse(atob(query.get('warrant')));
                changeTravelWarrantStatus(warrant);
            }
        }
    }, [changeStatus]);

    const changeTravelWarrantStatus = (warrant: any) => {
        if (warrant) {
            setOrd(warrant.ord);

            let url = undefined;
            url = CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS;
            setType('/travelWarrant');
            if (warrant.status === 5) {
                dispatch(setProp(5));
            } else if (warrant.status == 2) {
                dispatch(setProp(4));
            } else {
                dispatch(setProp(2));
            }

            NyRequestResolver.requestPut(url + '/' + warrant.id, undefined, {
                id: warrant.id,
                status: warrant.status,
            }).then((result: any) => {
                setLoading(false);
                setStatus(warrant.status);
                setSuccess(result && result.status === RESPONSE.OK);
            });
        }
    };

    const buttons = [
        <Button
            type="primary"
            onClick={() => {
                // dispatch(setActiveTab(type));
                history.push(type);
            }}
        >
            {geti18nText('menu.travelWarrant')}
        </Button>,
    ];

    if (!NySession.isUserAuthenticated()) {
        // dispatch(setActiveTab(history.location.pathname + history.location.search));
        return <Redirect to="/login" />;
    } else {
        return (
            <>
                {loading ? (
                    <NySpinner />
                ) : (
                    <>
                        {success ? (
                            <Result
                                status="success"
                                title={
                                    status && status === 2
                                        ? geti18nText('app.default.travelWarrant.approved')
                                        : status === 3
                                        ? geti18nText('app.default.travelWarrant.rejected')
                                        : status === 5
                                        ? geti18nText('app.default.travelWarrant.confirmed')
                                        : status === 11
                                        ? geti18nText('app.default.travelWarrant.paid')
                                        : geti18nText('app.default.travelWarrant.error')
                                }
                                subTitle={`${geti18nText('travelWarrant.edit.title')}: ` + ord}
                                extra={buttons}
                            />
                        ) : (
                            <Result
                                status="error"
                                title={geti18nText('app.default.travelWarrant.error')}
                                subTitle={`${geti18nText('travelWarrant.edit.title')}: ` + ord}
                                extra={buttons}
                            />
                        )}
                    </>
                )}
            </>
        );
    }
};

export default TravelWarrantApprovalIndex;
