import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import moment from 'moment';
import RowAction from '../../../../../components/row-action';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import AssetReturnEdit from './edit';
import useEnum from '../../../../../hooks/useEnum';

const EmployeeTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const table = useTableSettings();

    const canCreate = () => {
        return AssetRights.canCreateReturn();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'is_not_null', value: '' },
        ];
    };

    const columns = [
        {
            title: geti18nText('assetReturn.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('assetReturn.table.column.ord'),
            dataIndex: [`concat(_ar.ord, '/', date_part('year', _ar.date_return))`],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.dateReturn) {
                    return record.ord + '/' + moment(record.dateReturn).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.edit.employee'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record.employee?.person) {
                    return (
                        <div>
                            {record.employee.person?.firstName +
                                ' ' +
                                record.employee.person?.lastName +
                                (record.employee.employmentRecordId
                                    ? ' (' + record.employee.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('assetReturn.table.column.dateReturn'),
            dataIndex: 'dateReturn',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateReturn) {
                    return moment(record.dateReturn).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'Return'} record={record}></RowAction>;
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="asset-return-employee-table"
            url={CONSTANTS_REQ.ASSET_RETURN.LIST}
            addNewButtonText={geti18nText('assetReturn.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={AssetReturnEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            addedData={{ type: enAssetReleaseSpecType.EMPLOYEE }}
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('assetReturn.table.header')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            employee: (value: any) => {
                return (
                    value != undefined &&
                    value.person != undefined &&
                    value.person.firstName + ' ' + value.person.lastName
                );
            },
        },
        {
            dateReturn: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY HH:mm');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetReturn.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('assetReturn.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('inventoryOrder.edit.employee'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('assetReturn.table.column.dateReturn'),
            dataIndex: 'dateReturn',
        },
    ];
};

export default EmployeeTable;
