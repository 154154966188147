import {
    BookOutlined,
    CloseCircleTwoTone,
    DeleteTwoTone,
    DownloadOutlined,
    EditTwoTone,
    EllipsisOutlined,
    PlusCircleOutlined,
    SaveTwoTone,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Divider, Dropdown, Form, Input, Menu, Row, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../../components/note-modal';
import { InventoryRights } from '../../../../../rights/inventoryRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    fileDownload,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import EmployeeIndex from '../../../../human/views/employee';
import RoomSearch from '../../../../human/views/room/search';
import WarehouseIndex from '../../../../warehouse/views/warehouse';
import EditableCell from './EditableCell';

const InventoryReleaseEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('inventoryRelease.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [editingKey, setEditingKey] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(true);
    const [file, setFile] = useState(undefined);
    const [isBooked, setIsBooked] = useState(false);
    const [warehouse, setWarehouse] = useState<any>(undefined);
    const [orderViewId, setOrderViewId] = useState<any>(
        props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null
    );
    const isEditing = (record: any) => record.id === editingKey;
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);

    const canCreate = () => {
        return InventoryRights.canCreateRelease();
    };

    const showFileAction = () => {
        return file !== undefined;
    };
    const showBookAction = () => {
        return canCreate() && !isCreate && !isBooked;
    };

    useEffect(() => {
        getWarehouse(orderViewId);
    }, [orderViewId]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getWarehouseSearchUrl = () => {
        return orderViewId > 0
            ? CONSTANTS_REQ.WAREHOUSE.SEARCH_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString())
            : CONSTANTS_REQ.WAREHOUSE.SEARCH;
    };

    const getWarehouse = (orderViewId: any) => {
        if (orderViewId > 0) {
            setWarehouse(null);
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.WAREHOUSE.LIST_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString()),
                {
                    search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                }
            ).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.content?.length > 0 && result?.data.content[0]) {
                    const warehouseValue: any = setSearchFormat(result.data.content[0], 'name', 'name', 'code');
                    setWarehouse(warehouseValue);
                    form.setFieldsValue({ warehouse: warehouseValue });
                }
            });
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setIsCreate(false);
        setIsBooked(dataForm.bookedStock ? true : false);
        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setEditHeader(
                geti18nText('assetRelease.edit.title') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
        } else {
            setEditHeader(geti18nText('assetRelease.edit.title'));
        }
        if (dataForm.file != undefined) {
            setFile(dataForm.file.id);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        setNote(dataForm.note ? dataForm.note : undefined);
        if (dataForm?.inventoryReleaseItem) {
            setItems(dataForm.inventoryReleaseItem);
        }
        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }
        if (dataForm.orderView && dataForm.orderView.id) {
            setOrderViewId(dataForm.orderView.id);
        }
        if (dataForm.warehouse) {
            setWarehouse(setSearchFormat(dataForm.warehouse, 'name', 'name', 'code'));
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        assetForm.resetFields();
        setIsCreate(true);
        setIsBooked(false);
        setItems([]);
        setEditingKey('');
        setFile(undefined);
        setNote(undefined);
        setWarehouse(null);
        setOrderViewId(undefined);
        setEditHeader(geti18nText('inventoryRelease.edit.new'));
        setOrderViewId(null);
    };

    const onModalOpen = () => {
        setOrderViewId(props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (record.item == undefined && record.quantity == undefined) {
            deleteRow(record.id);
        }
        setAddItemInProcess(false);
    };

    async function getItemData(id: any) {
        const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + id, undefined);
        if (result.status === RESPONSE.OK) {
            if (result.data) {
                return result.data;
            }
        }
        return undefined;
    }

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await assetForm.validateFields();
            const newData = [...items];
            const index = newData.findIndex((item) => id === item.id);

            if (row && row.item) {
                let itemId = row.item.id;
                let itemData: any = await getItemData(itemId);
                if (itemData) {
                    row.item = itemData;
                }
            }

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }
            setAddItemInProcess(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)} disabled={!isCreate}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <NyModalConfirm
                        title={geti18nText('app.default.cancel.confirm')}
                        onConfirm={() => {
                            cancel(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                            <Button type="link" disabled={!isCreate}>
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {isCreate && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={editingKey !== '' || !isCreate} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record.id);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={editingKey !== '' || !isCreate}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        {
            title: geti18nText('webshopItem.edit.code'),
            dataIndex: ['item', 'code'],
            editable: false,
        },
        {
            title: geti18nText('webshopItem.edit.name'),
            dataIndex: 'item',
            editable: true,
            inputType: 'item',
            width: '40%',
            render: (text: any, record: any) => {
                if (record?.item?.name) {
                    return record.item.name;
                }
            },
        },
        {
            title: geti18nText('webshopItem.edit.measureUnit'),
            dataIndex: ['item', 'measureUnit', 'abbreviation'],
            editable: false,
            width: '15%',
        },
        {
            title: geti18nText('webshopItem.edit.quantity'),
            dataIndex: 'quantity',
            editable: true,
            inputType: 'quantity',
            width: '12%',
        },
        ...(props.editing
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '15%',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: assetForm,
                    type: props.type ? props.type : 8,
                    warehouse: warehouse,
                };
            },
        };
    });

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };
        assetForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const addNewRowAsset = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            item: undefined,
            quantity: undefined,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const book = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.INVENTORY_RELEASE.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setIsBooked(true);
                    okNotification(geti18nText('inventoryRelease.button.book.success'));
                    props?.editProps?.setRefresh((prev: number) => prev + 1);
                    onModalClose();
                    setTriggerCloseModal(true);
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {showFileAction() && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file,
                                    null,
                                    geti18nText('app.default.assetReleasePrint', [moment().format('yyyyMMDD')])
                                );
                            }}
                        >
                            {geti18nText('assetRelease.button.downloadNew')}
                        </Button>
                    </div>
                )}
                {showBookAction() && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <NyModalConfirm
                            title={geti18nText('assetRelease.popconfirm.booking.cancel')}
                            onConfirm={() => {
                                book();
                            }}
                        >
                            <Button icon={<BookOutlined />} disabled={isBooked} loading={loading}>
                                {geti18nText('assetRelease.button.booking')}
                            </Button>
                        </NyModalConfirm>
                    </div>
                )}
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px' }}>
                <NyNoteModal disabled={!isCreate} note={note} />
            </div>
            {(showFileAction() || showBookAction()) && (
                <div style={{ float: 'left', paddingLeft: '15px', marginLeft: '20px' }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )}
        </React.Fragment>
    );

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            if (result.data.room) {
                                form.setFieldsValue({ room: result.data.room });
                            }
                        }
                    }
                }
            );
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.INVENTORY_RELEASE.EDIT}
            setValues={setValues}
            width={900}
            hideActivationButtons={!isCreate || !canCreate()}
            hideSubmitButton={!canCreate() || !isCreate || !(items.length > 0) || editingKey !== ''}
            form={form}
            // setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={false}
            customFooterContent={canCreate() ? getCustomFooterContent : undefined}
            triggerCloseModal={triggerCloseModal}
            normalize={(values: any) => {
                values.warehouse = warehouse ? getSearchFormat(warehouse) : null;
                values.employee = getSearchFormat(values.employee);
                values.room = getSearchFormat(values.room);
                values.date = getDateFormat(moment());

                if (orderViewId != null) values.orderView = { id: orderViewId };
                if (values.orderViewId) {
                    delete values.orderViewId;
                }

                const itemsBasket: any = [];
                if (items != undefined) {
                    items.map((value: any) => {
                        let obj: any = {};
                        obj.item = { id: value.item.id };
                        obj.quantity = value.quantity;
                        itemsBasket.push(obj);
                    });
                }

                values.inventoryReleaseItem = itemsBasket;
                if (props.servicesOrderId) {
                    values.servicesOrder = { id: props.servicesOrderId };
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('inventoryRelease.edit.date')}
                        name="date"
                        initialValue={moment()}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            disabled={!isCreate}
                            ref={focusInput}
                            style={{ width: '100%' }}
                            format={'DD.MM.YYYY HH:mm'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('inventoryRelease.edit.warehouse')}
                        name="warehouse"
                        initialValue={warehouse}
                    >
                        <NySearchField
                            style={{ width: '100%' }}
                            url={getWarehouseSearchUrl()}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            disabled={true}
                            mustGetPopupContainer={false}
                            defaultValue={warehouse}
                            SearchPopupComponent={<WarehouseIndex orderViewId={orderViewId} />}
                            setDefaultFilterValue={setDefaultFilterValue}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('inventoryRelease.edit.employee')}
                        name="employee"
                        initialValue={props.employee ? props.employee : undefined}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="inventory-release-edit" />}
                            disabled={!isCreate}
                            onChange={onEmployeeChange}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <RoomSearch
                        disabled={!isCreate}
                        initialValue={props.room ? props.room : undefined}
                        label={geti18nText('inventoryRelease.edit.room')}
                        map={{ id: 'id', label: 'name' }}
                        name="room"
                        searchBy="name"
                    />
                </Col>
            </Row>
            <Divider>{geti18nText('inventoryOrder.divider.1')}</Divider>
            {isCreate && (
                <Row gutter={24} style={{ height: '36px' }}>
                    <Col span={24}>
                        <Button
                            style={{ float: 'right', marginRight: '12px' }}
                            icon={<PlusCircleOutlined />}
                            onClick={addNewRowAsset}
                            disabled={addItemInProcess}
                        >
                            {geti18nText('inventoryRelease.button.addItem')}
                        </Button>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={assetForm} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={items}
                            scroll={{ y: 680, x: 800 }}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                        />
                    </Form>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default InventoryReleaseEdit;
