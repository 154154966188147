import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnDateOption, getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useEnum from '../../hooks/useEnum';
import useTableSettings from '../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../utils/Constants';
import { getEnumArray, getEnumBooleanArray, getHumanCodebooksUrlByType } from '../../utils/Utils';
import HumanCodebooksEdit from './edit';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';

const HumanCodebooksIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    codebooksType = 0,
    disabled = false,
    search = undefined,
    showHeader = true,
    exportCsv = true,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    additionalRoles = undefined,
}: any) => {
    const table = useTableSettings();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const [headerTitle, setHeaderTitle] = useState(geti18nText('human.codebooks.table.header'));
    const [addNewButtonText, setAddNewButtonText] = useState(geti18nText('human.codebooks.table.add'));

    const enumOptions = [
        { id: 0, text: geti18nText('employee.evaluation.competency.type.resultsOrientation') },
        { id: 1, text: geti18nText('employee.evaluation.competency.type.leadership') },
        { id: 2, text: geti18nText('employee.evaluation.competency.type.collaboration') },
    ];

    const canCreate = () => {
        if (additionalRoles) {
            return HumanResourcesRights.canCreateCodebooks() || NySession.hasAnyRole(additionalRoles);
        } else return HumanResourcesRights.canCreateCodebooks();
    };

    const canExportCSV = () => {
        if (additionalRoles) {
            return exportCsv && (HumanResourcesRights.canCsvExportCodebooks() || NySession.hasAnyRole(additionalRoles));
        } else {
            return exportCsv && HumanResourcesRights.canCsvExportCodebooks();
        }
    };

    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    useEffect(() => {
        getHumanCodebooksView(codebooksType);
    }, [codebooksType]);

    const getHumanCodebooksView = (codebooksType: any = 0) => {
        if (codebooksType && codebooksType > 0) {
            setHeaderTitle(geti18nText('human.codebooks.table.header.' + codebooksType));
            setAddNewButtonText(geti18nText('human.codebooks.table.add.' + codebooksType));
        } else {
            setHeaderTitle(geti18nText('human.codebooks.table.header'));
            setAddNewButtonText(geti18nText('human.codebooks.table.add'));
        }
    };

    const isBooleanValue = () => {
        return (
            codebooksType &&
            Object.values([
                enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION,
                enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE,
            ]).includes(codebooksType)
        );
    };

    const isSelectValue = () => {
        return false;
    };

    const getCodeTitle = () => {
        return codebooksType == enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE
            ? geti18nText('human.codebooks.table.column.evaluationLeader')
            : codebooksType == enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION
            ? geti18nText('human.codebooks.table.column.needComment')
            : geti18nText('human.codebooks.table.column.code');
    };

    const initialColumns = [
        {
            title: geti18nText('human.codebooks.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('human.codebooks.table.column.codebookType'),
            dataIndex: 'codebookType',
            render: (text: any, record: any) => {
                if (record.codebookType !== undefined) {
                    return geti18nText('app.enum.HUMAN_CODEBOOKS.' + record.codebookType);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('HUMAN_CODEBOOKS')),
        },
        {
            title: geti18nText('human.codebooks.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('human.codebooks.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('human.codebooks.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('human.codebooks.table.column.toDate'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('human.codebooks.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    const shortColumns = [
        ...[
            {
                title: geti18nText('human.codebooks.table.column.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('human.codebooks.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(isBooleanValue()
            ? [
                  {
                      title: getCodeTitle(),
                      dataIndex: 'code',
                      render: (code: any) => {
                          return code == '1'
                              ? geti18nText('app.default.button.yes')
                              : geti18nText('app.default.button.no');
                      },
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_string'),
                  },
              ]
            : isSelectValue()
            ? [
                  {
                      title: getCodeTitle(),
                      dataIndex: 'code',
                      ...getColumnSearchOption(enumOptions, 'equals_string'),
                      render: (code: any) => {
                          return enumOptions[Number(code)].text;
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('human.codebooks.table.column.code'),
                      dataIndex: 'code',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
              ]),
    ];

    return (
        <NyDataTable
            nyId="human-codecooks"
            url={getHumanCodebooksUrlByType(CONSTANTS_REQ.HUMAN_CODEBOOKS.LIST, codebooksType.toString())}
            addNewButtonText={addNewButtonText ? addNewButtonText : geti18nText('human.codebooks.table.add')}
            headerTitle={showHeader ? (headerTitle ? headerTitle : geti18nText('human.codebooks.table.header')) : ''}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={HumanCodebooksEdit}
            editProps={{
                codebooksType: codebooksType,
                additionalRoles: additionalRoles,
            }}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={codebooksType && codebooksType > 0 ? shortColumns : initialColumns}
            setDefaultFilterValue={setDefaultFilterValue}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            hideButtons={disabled}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={headerTitle}
            colCSVCustomization={
                isBooleanValue()
                    ? csvColumnCustomisationCodeBoolean()
                    : isSelectValue()
                    ? csvColumnCustomisationCodeSelect()
                    : csvColumnCustomisation()
            }
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumnCustomisationCodeBoolean: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            code: (value: any) => {
                return value && value == '1'
                    ? geti18nText('app.default.button.yes')
                    : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumnCustomisationCodeSelect: any = () => {
    const enumOptions = [
        { id: 0, text: geti18nText('employee.evaluation.competency.type.resultsOrientation') },
        { id: 1, text: geti18nText('employee.evaluation.competency.type.leadership') },
        { id: 2, text: geti18nText('employee.evaluation.competency.type.collaboration') },
    ];
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            code: (value: any) => {
                return value && enumOptions[Number(value)].text;
            },
        },
    ];
};

export default HumanCodebooksIndex;
