import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICompanyState {
    company?: any;
}

const initialState: ICompanyState = {
    company: undefined,
};

const company = createSlice({
    name: 'company',
    initialState,
    reducers: {
        companyInit(state) {
            state.company = undefined;
        },
        setCompany(state, action: PayloadAction<ICompanyState>) {
            const { company } = action.payload;
            state.company = company;
        },
    },
});

export const { companyInit, setCompany } = company.actions;

export default company.reducer;
