import { ClockCircleOutlined, ExclamationCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { NySession, geti18nText } from '@nybble/nyreact';
import { Button, Card, Col, Empty, Popover, Row, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useHelper from '../../../../../hooks/useHelper';
import { RootState } from '../../../../../rootReducer';
import { getLocationStateOpenModal, getLoginRedirectUrl } from '../../../../../utils/Utils';
import MealsMenuWidget from '../../../../dashboard/MealsMenuWidget';
import MealOrderAdvanceLightWidget from '../../../../dashboard/light-widgets/MealOrderAdvanceLightWidget';
import MealOrderIndex from '../meal-order';
import MealsNyFullCalendar from './MealsNyFullCalendar';
import MealOrderWizard from './wizard';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealsOverviewIndex = () => {
    const location: any = useLocation();
    const { employee } = useSelector((state: RootState) => state.employee);

    const [listView, setListView] = useState<any>(0);
    const [refresh, setRefresh] = useState<any>(0);
    const [amountExists, setAmountExists] = useState<any>(false);
    const [orderMealModal, setOrderMealModal] = useState(false);
    const [order, setOrder] = useState<any>(undefined);

    const mealSubventionValue = useApplicationSetting('MEALS_SUBVENTION');
    const allowOrdersToday = Boolean(parseInt(useApplicationSetting('MEALS_ORDER_TODAY_ENTRY_ALLOW')));
    const mealOrderEntryTime = useApplicationSetting('MEALS_ORDER_ENTRY_TIME');
    const mealOrderCancelTime = useApplicationSetting('MEALS_ORDER_CANCELLATION_TIME');
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);

    useHelper('meals/meals-overview');

    useEffect(() => {
        getLocationStateOpenModal(location, setOrderMealModal);
        const id = getLoginRedirectUrl();
        if (id) {
            setOrder({ id: id });
            setOrderMealModal(true);
        }
    }, [location.state]);

    const canCreate = () => {
        return MealsRights.isMealsUser();
    };

    const canCreateAdmin = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterMyOrders = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: employee && employee.id },
        ];
    };

    return (
        <>
            {listView == 0 ? (
                <>
                    <Row gutter={24} style={{ marginBottom: '20px' }}>
                        <Col span={20}>
                            <Card
                                style={{ width: '100%' }}
                                className={`ny-widget-card`}
                                bodyStyle={{
                                    maxHeight: '640px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    padding: '0px 5px 0px 5px',
                                    marginBottom: '5px',
                                }}
                                type="inner"
                            >
                                <MealsNyFullCalendar
                                    keys={['meals']}
                                    height={'630px'}
                                    showHeaderToolbar={false}
                                    employee={employee}
                                    refreshView={refresh}
                                    setRefreshView={setRefresh}
                                    canCreate={canCreate() || canCreateAdmin()}
                                    canCreateAdmin={canCreateAdmin()}
                                    allowOrdersToday={allowOrdersToday}
                                    mealOrderEntryTime={mealOrderEntryTime}
                                    mealOrderCancelTime={mealOrderCancelTime}
                                    mealSyncTypeHNB={mealSyncTypeHNB}
                                    mealSubventionValue={mealSubventionValue}
                                />
                            </Card>
                        </Col>
                        <Col span={4}>
                            {mealSyncTypeHNB && (
                                <Row style={{ marginBottom: '12px' }}>
                                    <Col span={24}>
                                        {
                                            <MealOrderAdvanceLightWidget
                                                refresh={refresh}
                                                setAmountExists={setAmountExists}
                                            />
                                        }
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col
                                    span={24}
                                    style={{ height: mealSyncTypeHNB ? (amountExists ? '514px' : '423px') : '640px' }}
                                >
                                    <MealsMenuWidget
                                        overviewIndex={true}
                                        style={{
                                            height: mealSyncTypeHNB ? (amountExists ? '514px' : '423px') : '640px',
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{ margin: '0 10px 10px 10px' }}>
                            <Tag icon={<ExclamationCircleOutlined />} color="default">
                                {geti18nText('meals.overview.description')}
                            </Tag>
                        </Col>
                    </Row>
                </>
            ) : listView == 1 ? (
                <>
                    <MealOrderIndex
                        search={setDefaultFilterMyOrders()}
                        listView={listView}
                        setListView={setListView}
                        refreshView={refresh}
                        setRefreshView={setRefresh}
                        employeeId={employee && employee.id}
                        allowOrdersToday={allowOrdersToday}
                        mealOrderEntryTime={mealOrderEntryTime}
                        mealOrderCancelTime={mealOrderCancelTime}
                    />
                </>
            ) : (
                <Empty />
            )}
            {orderMealModal && (
                <MealOrderWizard
                    visible={orderMealModal}
                    setVisible={setOrderMealModal}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    value={order}
                    setValue={setOrder}
                    mealSubventionValue={mealSubventionValue}
                    allowOrdersToday={allowOrdersToday}
                    mealOrderEntryTime={mealOrderEntryTime}
                    mealOrderCancelTime={mealOrderCancelTime}
                    isAdminForm={false}
                />
            )}
            {listView == 0 && (
                <div className="overview-buttons-sticky" style={{ zIndex: '2', bottom: '-10px' }}>
                    <Popover
                        title={geti18nText('meals.overview.legend')}
                        placement="topLeft"
                        content={
                            <div style={{ fontSize: '14px' }}>
                                <ul>
                                    <li>
                                        {geti18nText('meals.overview.mealOrderTodayEntryAllowed')}:{' '}
                                        {allowOrdersToday
                                            ? geti18nText('app.default.button.yes')
                                            : geti18nText('app.default.button.no')}
                                    </li>
                                    <li>
                                        {geti18nText('meals.overview.mealOrderEntryTime')}: {mealOrderEntryTime ?? ''}
                                    </li>
                                    <li>
                                        {geti18nText('meals.overview.mealOrderCancelTime')}: {mealOrderCancelTime ?? ''}
                                    </li>
                                </ul>
                            </div>
                        }
                        style={{ marginLeft: '4rem' }}
                    >
                        <Button
                            size="large"
                            style={{ margin: '0.6rem' }}
                            icon={<ClockCircleOutlined className="travel-warrant-overview-legend" />}
                        />
                    </Popover>
                    <div style={{ width: '100%', justifyContent: 'end', display: 'flex' }}>
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => setListView(1)}
                            icon={<OrderedListOutlined />}
                            hidden={listView === 1}
                        >
                            {geti18nText('meals.list')}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default MealsOverviewIndex;
