import { NyDataEdit, NyInputNumber, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const CurrencyEdit = (props: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [editHeader, setEditHeader] = useState(geti18nText('currency.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('currency.edit.title') + ' - ' + dataForm.name);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('currency.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.CURRENCY.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.downloadExchangeRate = values.downloadExchangeRate ?? false;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('currency.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('currency.edit.isoNumber')}
                        name="isoNumber"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                type: 'integer',
                                message: geti18nText('app.default.input.error'),
                                min: 0,
                            },
                        ]}
                    >
                        <NyInputNumber
                            nyTestId="iso-number"
                            leftToRight={numbersWriting}
                            formatter={false}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('currency.edit.isoCode')}
                        name="isoCode"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                            { max: 3, message: geti18nText('app.default.max.length') + ' 3' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('currency.edit.symbol')}
                        name="symbol"
                        rules={[{ max: 4, message: geti18nText('app.default.max.length') + ' 4' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('currency.edit.downloadExchangeRate')}
                        name="downloadExchangeRate"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default CurrencyEdit;
