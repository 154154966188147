import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISelectedItemState {
    item: any;
    refreshItem: number;
    employeeId: any;
}

const initialState: ISelectedItemState = {
    item: undefined,
    refreshItem: 0,
    employeeId: undefined,
};

const selectedItem = createSlice({
    name: 'selectedItem',
    initialState,
    reducers: {
        selectedItemInit(state) {
            state.item = {};
            state.refreshItem = 0;
            state.employeeId = undefined;
        },
        selectedItemSet(state, action: PayloadAction<ISelectedItemState>) {
            const { item, refreshItem } = action.payload;
            state.item = item;
            state.refreshItem = refreshItem;
        },
        refreshItemList(state) {
            state.refreshItem++;
        },
        employeeIdSet(state, action) {
            const { employeeId } = action.payload;
            state.employeeId = employeeId;
        },
    },
});

export const { selectedItemInit, selectedItemSet, refreshItemList, employeeIdSet } = selectedItem.actions;

export default selectedItem.reducer;
