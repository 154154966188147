import { createSlice } from '@reduxjs/toolkit';

interface IHistoryLink {
    path: string;
    i18n: { key: string; hasComponent: boolean; args?: [string] }[];
}

interface IHistorySlice {
    links?: IHistoryLink[];
}

const initialState: IHistorySlice = {
    links: [],
};

const historySlice = createSlice({
    name: 'historyslice',
    initialState,
    reducers: {
        historyInit(state) {
            state.links = [];
        },
        addHistory(state, action) {
            state.links = state.links?.filter((value) => {
                return value.i18n[0].key !== '';
            });
            state.links = [action.payload, ...(state.links ? state.links.slice(0, 9) : [])];
        },
        updateHistoryI18n(state, action) {
            state.links?.map((val: any) => {
                if (val.path == action.payload.path) {
                    let last = val.i18n.pop();
                    last.args = action.payload.args;
                    val.i18n = [...val.i18n, last];
                }
            });
        },
        clearHistory(state) {
            state.links = [];
        },
    },
});

export const { historyInit, addHistory, updateHistoryI18n, clearHistory } = historySlice.actions;

export default historySlice.reducer;
