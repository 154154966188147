import { geti18nText } from '@nybble/nyreact';
import { Button, Col, Empty, Radio, Row } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useHelper from '../../hooks/useHelper';

import { CONSTANTS_REQ } from '../../utils/Constants';

import { useSelector } from 'react-redux';
import { TasksRights } from '../../rights/tasksRights';
import { RootState } from '../../rootReducer';
import TaskListUserTasksIndex from './TaskListUserTasksIndex';

const TaskListUserIndex = () => {
    const history = useHistory();

    const [refresh, setRefresh] = useState<any>(0);
    const { propOverviewTasks } = useSelector((state: RootState) => state.prop);

    const getPropValue = () => {
        if (propOverviewTasks == 4 && TasksRights.canViewTaskApproval()) {
            return 4;
        } else {
            return propOverviewTasks ?? 1;
        }
    };
    const [activeList, setActiveList] = useState<any>(getPropValue());

    useHelper('task_service/task-list/user');

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        setRefresh((prevValue: any) => prevValue + 1);
    };

    return (
        <>
            <Row gutter={24}>
                <Radio.Group
                    value={activeList}
                    buttonStyle="solid"
                    optionType="button"
                    style={{ margin: '5px 5px 20px 10px' }}
                >
                    <Radio.Button value={1} onChange={onChangeActiveList}>
                        {geti18nText('task.edit.edit.myTasks')}
                    </Radio.Button>
                    <Radio.Button value={2} onChange={onChangeActiveList}>
                        {geti18nText('tasks.list.radioButton.5')}
                    </Radio.Button>
                </Radio.Group>
            </Row>
            <Row gutter={24}>
                {activeList == 1 ? (
                    <Col>
                        <TaskListUserTasksIndex setRefresh={setRefresh} refresh={refresh} />
                    </Col>
                ) : activeList == 2 ? (
                    <Col>
                        <div>
                            <TaskListUserTasksIndex
                                setRefresh={setRefresh}
                                refresh={refresh}
                                url={CONSTANTS_REQ.TASK_WATCHERS.WATCHED_TASKS_LIST}
                            />
                        </div>
                    </Col>
                ) : (
                    <Empty />
                )}
            </Row>
        </>
    );
};

export default TaskListUserIndex;
