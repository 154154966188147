import { geti18nText, NyDataEdit, NyInputNumber } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getSearchFormat, onKeyDownTextAreaCustom, setSearchFormat } from '../../utils/Utils';
import TravelVehiclePersonalSearch from '../../views/travel-warrant/views/travel-vehicle-personal/search';

const TravelWarrantCo2Edit = (props: any) => {
    const { employee } = useSelector((state: RootState) => state.employee);
    const [editHeader, setEditHeader] = useState(geti18nText('travelWarrantCo2.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [travelWarrantId, setTravelWarrantId] = useState(props?.editProps?.travelWarrantId);
    const focusInput = useRef<any>(null);
    const enVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const disabledForm: boolean = props?.editProps?.disabled;

    const canCreate = () => {
        return (
            TravelWarrantRights.canCreateCo2(
                props?.editProps?.travelWarrantStatus,
                employee,
                travelWarrantStatusEnum,
                notificationType
            ) && !disabledForm
        );
    };

    const getVehicleType = () => {
        return [
            enVehicleType.PLANE,
            enVehicleType.BOAT,
            enVehicleType.BUS,
            enVehicleType.TRAIN,
            enVehicleType.OTHER_PUBLIC,
            enVehicleType.OTHER,
        ];
    };

    const getTravelWarrantEmployee = () => {
        return props?.editProps?.employeeTravelWarrantId ?? null;
    };

    const setDefaultFilterValueVehicle = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'vehicleType', condition: 'in', value: getVehicleType().toString() },
            { field: 'employeeId', condition: 'equals', value: getTravelWarrantEmployee() },
        ];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.travelVehicle) {
            setEditHeader(geti18nText('travelWarrantCo2.edit.title') + ' - ' + dataForm.travelVehicle.name);
            dataForm.travelVehicle = setSearchFormat(dataForm.travelVehicle, 'name', 'name');
        }
        if (dataForm?.travelWarrant?.id) {
            setTravelWarrantId(dataForm?.travelWarrant?.id);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('travelWarrantCo2.edit.new'));
        setTravelWarrantId(null);
        setDataForm(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TRAVEL_WARRANT_CO2.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.travelVehicle) {
                    values.travelVehicle = getSearchFormat(values.travelVehicle);
                }
                values.travelWarrant = { id: travelWarrantId ?? props?.editProps?.travelWarrantId };
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <TravelVehiclePersonalSearch
                        required={true}
                        disabled={!canCreate()}
                        setDefaultFilterValueVehicle={setDefaultFilterValueVehicle}
                        vehicleType={getVehicleType()}
                        travelWarrantEmployee={getTravelWarrantEmployee()}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantCo2.table.column.amount')}
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            isDecimal={true}
                            decimalPlaces={2}
                            max={99999}
                            min={0}
                            disabled={!canCreate()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('travelWarrantCo2.table.column.note')} name="description">
                        <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} disabled={!canCreate()} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelWarrantCo2Edit;
