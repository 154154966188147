import { DeleteOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Tabs, Tooltip } from 'antd';
import React, { ReactText, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, okNotification } from '../../../../../utils/Utils';
import MealEdit from './edit';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('meal.group.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(undefined);
    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState<any>(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const [activeKey, setActiveKey] = useState('1');
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);

    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [selectedValuesAddGroup, setSelectedValuesAddGroup] = useState<any>([]);
    const [hasSelectedAddGroup, setHasSelectedAddGroup] = useState(false);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);

    const [teamCreated, setTeamCreated] = useState(false);
    const table = useTableSettings();

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const activeFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('meal.group.edit.title') + ' - ' + dataForm.name);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setActiveKey('1');
        setEditHeader(geti18nText('meal.group.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    let clearKeysDeleteGroup: any;

    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    let clearKeysAddGroup: any;

    const setSelectedValuesFuncAddGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddGroup(hasSelected);
        setSelectedValuesAddGroup(selectedRowKeys);
        clearKeysAddGroup = clearSelectedRowKeys;
    };

    const saveToGroup = (e: any) => {
        e.preventDefault();
        setLoadingModalGroup(true);
        const url = CONSTANTS_REQ.MEAL_GROUP.ADD_MEAL;
        const urlWithMealGroupId = url.replace('{groupId}', dataForm);

        NyRequestResolver.requestPost(urlWithMealGroupId, undefined, {
            groupId: form.getFieldValue('id'),
            mealIds: selectedValuesAddGroup,
        }).then((result: any) => {
            if (setLoadingModalGroup) setLoadingModalGroup(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddGroup) {
                    clearKeysAddGroup();
                }

                okNotification();
                setIsAddListModalVisibleGroup(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromGroup = () => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.id;
        });
        const url = CONSTANTS_REQ.MEAL_GROUP.REMOVE_MEAL;
        const urlWithMealGroupId = url.replace('{groupId}', form.getFieldValue('id'));

        NyRequestResolver.requestPost(urlWithMealGroupId, undefined, {
            groupId: form.getFieldValue('id'),
            mealIds: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const resolveMin = () => {
        let maxOrder = form.getFieldValue(['maxOrder']);
        let minOrder = form.getFieldValue(['minOrder']);
        if (maxOrder && maxOrder < minOrder) {
            form.setFieldsValue({ minOrder: maxOrder });
        }
    };

    const resolveMax = () => {
        let maxOrder = form.getFieldValue(['maxOrder']);
        let minOrder = form.getFieldValue(['minOrder']);
        if (minOrder && maxOrder < minOrder) {
            form.setFieldsValue({ maxOrder: minOrder });
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('meal.edit.add.meal.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('meal.edit.remove.meal.from.group.comfirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('meal.edit.remove.meal.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.MEAL_GROUP.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            setIsCreate={setIsCreate}
            leaveNewModalOpen={true}
            hideActivationButtons={!canCreate() || teamCreated || activeKey != '1'}
        >
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <Tabs.TabPane tab={geti18nText('meal.group.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('meal.group.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('meal.group.edit.sortBy')} name="sortBy">
                                <InputNumber style={{ width: '100%' }} min={1} step={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('meal.group.edit.minOrder')} name="minOrder" initialValue={1}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={resolveMin}
                                    min={0.1}
                                    step={0.1}
                                    decimalSeparator={','}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('meal.group.edit.maxOrder')} name="maxOrder" initialValue={1}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={resolveMax}
                                    min={0.1}
                                    step={0.1}
                                    decimalSeparator={','}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {mealSyncTypeHNB ? (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('meal.group.edit.refId')} name="refId">
                                    <Input style={{ width: '100%' }} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('meal.group.tab.meal')} key="2" disabled={!dataForm}>
                    {!isCreate && (
                        <NyDataTable
                            nyId="meals-group-in-group"
                            rowKey="id"
                            url={CONSTANTS_REQ.MEAL.IN_GROUP + '/' + dataForm}
                            addedData={{ mealId: form.getFieldValue('id') }}
                            showRowSelection
                            readonly
                            hideButtons
                            scroll={props?.editProps?.scroll}
                            setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                            rowSelectionType={'checkbox'}
                            rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                            setDefaultFilterValue={activeFilter}
                            fetchWhenChange={refreshTable}
                            setDefaultPageSize={table.setDefaultPageSize()}
                            columns={[
                                ...[
                                    {
                                        title: geti18nText('meal.table.column.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                ],
                                ...(mealSyncTypeHNB === true
                                    ? [
                                          {
                                              title: geti18nText('meal.table.column.refId'),
                                              dataIndex: 'refId',
                                              sorter: (a: any, b: any) => {},
                                              ...getColumnSearch('string'),
                                          },
                                      ]
                                    : []),
                                ...[
                                    {
                                        title: geti18nText('meal.table.column.name'),
                                        dataIndex: 'name',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('meal.table.column.allergen'),
                                        dataIndex: 'allergen',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                        render: (allergen: any, record: any) => {
                                            if (record && record.allergen) {
                                                return (
                                                    <Tooltip
                                                        placement="topLeft"
                                                        overlayStyle={{ maxWidth: '200px' }}
                                                        title={record.allergen}
                                                    >
                                                        <div className={'overview-cards-paragraph-div'}>
                                                            <p className={'overview-cards-paragraph'}>
                                                                {record && record.allergen}
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                );
                                            }
                                        },
                                    },
                                    {
                                        title: geti18nText('meal.table.column.description'),
                                        dataIndex: 'description',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                        render: (description: any, record: any) => {
                                            if (record && record.description) {
                                                return (
                                                    <Tooltip
                                                        placement="topLeft"
                                                        overlayStyle={{ maxWidth: '200px' }}
                                                        title={record.description}
                                                    >
                                                        <div className={'overview-cards-paragraph-div'}>
                                                            <p className={'overview-cards-paragraph'}>
                                                                {record && record.description}
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                );
                                            }
                                        },
                                    },
                                ],
                            ]}
                        />
                    )}
                    {isAddListModalVisibleGroup && (
                        <Modal
                            title={geti18nText('meal.edit.add.meal.in.group.add')}
                            visible={isAddListModalVisibleGroup}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAddGroup }}
                            onOk={(e) => saveToGroup(e)}
                            confirmLoading={loadingModalGroup}
                            width={900}
                            onCancel={() => {
                                setIsAddListModalVisibleGroup(false);
                            }}
                        >
                            <NyDataTable
                                nyId="meals-group-not-in-group"
                                rowKey="id"
                                url={CONSTANTS_REQ.MEAL.NOT_IN_GROUP + '/' + dataForm}
                                addedData={{ mealId: form.getFieldValue('id') }}
                                addNewButtonText={geti18nText('meal.table.add')}
                                modalComponent={MealEdit}
                                showRecordModal={true}
                                hideNewButton={!canCreate()}
                                showRowSelection
                                readonly
                                setDefaultPageSize={table.setDefaultPageSize()}
                                scroll={props?.editProps?.scroll}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncAddGroup as any}
                                setDefaultFilterValue={activeFilter}
                                fetchWhenChange={refreshTable}
                                onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                columns={[
                                    ...[
                                        {
                                            title: geti18nText('meal.table.column.id'),
                                            dataIndex: 'id',
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                    ],
                                    ...(mealSyncTypeHNB === true
                                        ? [
                                              {
                                                  title: geti18nText('meal.table.column.refId'),
                                                  dataIndex: 'refId',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearch('string'),
                                              },
                                          ]
                                        : []),
                                    ...[
                                        {
                                            title: geti18nText('meal.table.column.name'),
                                            dataIndex: 'name',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText('meal.table.column.allergen'),
                                            dataIndex: 'allergen',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                            render: (allergen: any, record: any) => {
                                                if (record && record.allergen) {
                                                    return (
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ maxWidth: '200px' }}
                                                            title={record.allergen}
                                                        >
                                                            <div className={'overview-cards-paragraph-div'}>
                                                                <p className={'overview-cards-paragraph'}>
                                                                    {record && record.allergen}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('meal.table.column.description'),
                                            dataIndex: 'description',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                            render: (description: any, record: any) => {
                                                if (record && record.description) {
                                                    return (
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ maxWidth: '200px' }}
                                                            title={record.description}
                                                        >
                                                            <div className={'overview-cards-paragraph-div'}>
                                                                <p className={'overview-cards-paragraph'}>
                                                                    {record && record.description}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                }
                                            },
                                        },
                                    ],
                                ]}
                            />
                        </Modal>
                    )}
                </Tabs.TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default MealGroupEdit;
