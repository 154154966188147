import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Descriptions, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getDateFormat, setDateFormat, setEnumFormat } from '../../utils/Utils';

const UserInfo = (id: any) => {
    const [resultData, setResultData] = useState<any>();
    const personSexType: any = setEnumFormat('SEX_TYPE', resultData?.person?.sex);
    const birthDate: any = setDateFormat(resultData?.person?.birthDate);
    const employeeStatusValidFrom: any = setDateFormat(resultData?.employeeStatusValidFrom);
    const employeeStatusValidTo: any = setDateFormat(resultData?.employeeStatusValidTo);
    const employmentDate: any = moment(resultData?.employmentDate).format('DD.MM.yyyy.');

    useEffect(() => {
        if (id?.id) {
            fetchEmployeeData();
        }
    }, [id]);

    const fetchEmployeeData = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + id.id).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setResultData(result.data);
                }
            }
        });
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={11} offset={1}>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {geti18nText('employee.mobilization.edit.info2')}
                    </div>
                    <Row gutter={24}>
                        <Descriptions
                            bordered
                            layout="horizontal"
                            column={1}
                            size="small"
                            contentStyle={{ fontWeight: 'bold' }}
                            style={{ width: '100%' }}
                        >
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.lastParentFirstName')}>
                                {resultData?.person &&
                                    resultData?.person?.lastName +
                                        ', ' +
                                        (resultData?.person?.parentName ? resultData?.person?.parentName + ', ' : '') +
                                        resultData?.person?.firstName}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.employmentRecordId')}>
                                {resultData?.employmentRecordId}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.businessUnit')}>
                                {resultData &&
                                    resultData?.businessUnits &&
                                    resultData?.businessUnits
                                        .map((businessUnit: any) => {
                                            return (
                                                <p style={{ marginBottom: '0px' }}>
                                                    {(businessUnit.code ? `[${businessUnit.code}] ` : '') +
                                                        (businessUnit.name ? businessUnit.name : '')}
                                                </p>
                                            );
                                        })
                                        .reverse()}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.nationality')}>
                                {resultData?.person?.citizenship?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.sex')}>
                                {personSexType?.text}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.dob')}>
                                {resultData?.person?.birthDate && getDateFormat(birthDate, 'DD.MM.yyyy.')}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.oib')}>
                                {resultData?.person?.oib}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={geti18nText('employee.mobilization.edit.professionalQualification')}
                            >
                                {resultData?.formalEducation?.qualificationLevel?.qualification}
                            </Descriptions.Item>
                            {/*   <Descriptions.Item // za kasniju doradu
                                label={
                                    resultData?.person?.sex == sexEnum.MALE
                                        ? geti18nText('employee.mobilization.edit.completionYear.male') 
                                        : geti18nText('employee.mobilization.edit.completionYear.female')
                                }
                            >
                                {resultData?.person?.completionYear}
                            </Descriptions.Item> */}
                        </Descriptions>
                    </Row>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {geti18nText('employee.mobilization.edit.info3')}
                    </div>
                    <Row gutter={24}>
                        <Descriptions
                            bordered
                            layout="horizontal"
                            column={1}
                            size="small"
                            contentStyle={{ fontWeight: 'bold' }}
                            style={{ width: '100%' }}
                        >
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.county')}>
                                {resultData?.address?.settlement?.county?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.township')}>
                                {resultData?.address?.settlement?.township?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.settlement')}>
                                {resultData?.address?.settlement?.name}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label={geti18nText('employee.mobilization.edit.settlementDistrict')}>{resultData?.settlementDistrict}</Descriptions.Item> */}
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.street')}>
                                {resultData?.address?.street}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>
                </Col>
                <Col span={10} offset={1}>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {geti18nText('employee.edit.educationData')}
                    </div>
                    <Row gutter={24}>
                        <Descriptions
                            bordered
                            layout="horizontal"
                            column={1}
                            size="small"
                            contentStyle={{ fontWeight: 'bold' }}
                            style={{ width: '100%' }}
                        >
                            <Descriptions.Item label={geti18nText('employee.edit.professional.institute')}>
                                {resultData?.formalEducation?.educationalInstitution?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.professional.education')}>
                                {resultData?.formalEducation?.educationalProgram?.educationalTitle?.name}
                                {resultData?.formalEducation?.educationalTitle
                                    ? ' (' +
                                      resultData?.formalEducation?.educationalProgram?.educationalTitle?.shortName +
                                      ')'
                                    : ''}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {geti18nText('employee.edit.employeeData')}
                    </div>
                    <Row gutter={24}>
                        <Descriptions
                            bordered
                            layout="horizontal"
                            column={1}
                            size="small"
                            contentStyle={{ fontWeight: 'bold' }}
                            style={{ width: '100%' }}
                        >
                            <Descriptions.Item label={geti18nText('employee.edit.type')}>
                                {resultData?.type?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.healthSecurityNumber')}>
                                {resultData?.healthSecurityNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.employment.date')}>
                                {resultData?.employmentDate ? employmentDate : null}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.employment.type')}>
                                {resultData?.employmentType?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.employment.service')}>
                                {resultData?.empServiceYmd}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.work.hours.full')}>
                                {resultData?.workHoursFull}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.work.hours.weekly')}>
                                {resultData?.workHoursWeekly}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.employee.group')}>
                                {resultData?.employeeGroup?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.joppdCode')}>
                                {resultData?.joppdCode}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.edit.joppdWorkHoursCode')}>
                                {resultData?.joppdWorkHoursCode}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.employeeStatus')}>
                                {resultData?.employeeStatus?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employmentContract.edit.validFrom')}>
                                {resultData?.employeeStatusValidFrom &&
                                    getDateFormat(employeeStatusValidFrom, 'DD.MM.yyyy.')}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employmentContract.edit.validTo')}>
                                {resultData?.employeeStatusValidTo &&
                                    getDateFormat(employeeStatusValidTo, 'DD.MM.yyyy.')}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.mobilization.edit.statusDescription')}>
                                <Tooltip
                                    title={resultData?.employeeStatusDescription}
                                    overlayStyle={{ maxWidth: '600px' }}
                                >
                                    <div
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '625px',
                                        }}
                                    >
                                        {resultData?.employeeStatusDescription}
                                    </div>
                                </Tooltip>
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default UserInfo;
