import { getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import { getColumnDateOption } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button } from 'antd';
import { useState } from 'react';
import NyErrorLogModal from '../../components/error-log-modal';
import useHelper from '../../hooks/useHelper';
import useTableSettings from '../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { fileDownload } from '../../utils/Utils';

import {
    getColumnSearchByCodebookAssetCategory,
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookSimple,
} from '../../utils/Filters';

import moment from 'moment';
import { AssetRights } from '../../rights/assetRights';

const AssetAssignHistoryIndex = ({ id, isHistoryView = false, scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const [errorLogModalData, setErrorLogModalData] = useState(undefined);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');

    const table = useTableSettings();
    useHelper('components/assetAssignHistory');

    const canCreate = () => {
        return AssetRights.canCreateCodebooks();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const setDefaultFilterValue = () => {
        if (isHistoryView) {
            return [{ field: 'asset.id', condition: 'equals', value: id }];
        } else {
            return [{ field: 'id', condition: 'is_not_null', value: '' }];
        }
    };

    const initialColumns = [
        ...(!isHistoryView == true
            ? [
                  {
                      title: geti18nText('assetAssignHistory.table.column.assetCategory'),
                      dataIndex: ['assetCategory', 'name'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebookAssetCategory(),
                      render: (text: any, record: any) => {
                          if (record.asset.assetCategory) {
                              return (
                                  record.asset.assetCategory.name +
                                  (record.asset.assetCategory.code ? ' (' + record.asset.assetCategory.code + ')' : '')
                              );
                          }
                      },
                  },
                  {
                      title: geti18nText('assetAssignHistory.table.column.assetType'),
                      dataIndex: ['assetType', 'name'],
                      sorter: (a: any, b: any) => {},
                      render: (text: any, record: any) => {
                          if (record?.asset?.assetType?.name) {
                              return (
                                  record?.asset?.assetType?.name +
                                  (record?.asset?.assetType?.code ? ' (' + record?.asset?.assetType?.code + ')' : '')
                              );
                          }
                      },
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.ASSET_TYPE.SEARCH,
                          'assetType.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          }
                      ),
                  },
                  {
                      title: geti18nText('assetAssignHistory.table.column.assetModel'),
                      dataIndex: ['assetModel', 'name'],
                      sorter: (a: any, b: any) => {},
                      render: (text: any, record: any) => {
                          if (record?.asset?.assetModel?.name) {
                              return record?.asset?.assetModel?.name;
                              // +  (record?.asset?.assetType?.code ? ' (' + record?.asset?.assetType?.code + ')' : '')
                          }
                      },
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.ASSET_MODEL.SEARCH,
                          'assetModel.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          }
                      ),
                  },
                  {
                      title: geti18nText('assetAssignHistory.table.column.serialNumber'),
                      dataIndex: ['asset', 'serialNumber'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
              ]
            : []),
        {
            title: geti18nText('assetAssignHistory.table.column.employee'),
            dataIndex: 'employee.id',
            sorter: (a: any, b: any) => {},
            width: isHistoryView ? '16%' : '12%',
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record.employee && record.employeePerson) {
                    return (
                        `${record.employeePerson.firstName} ${record.employeePerson.lastName}` +
                        (record.employee.employmentRecordId ? ` (${record.employee.employmentRecordId})` : '')
                    );
                }
            },
        },
        {
            title: geti18nText('assetAssignHistory.table.column.bussiness.unit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            width: isHistoryView ? '22%' : '12%',
            render: (text: any, record: any) => {
                if (record.businessUnit && (record?.employee?.id == undefined || record?.employee?.id == null)) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '')
                    );
                }
            },

            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
        },
        {
            title: geti18nText('assetAssignHistory.table.column.releaseDate'),
            dataIndex: 'releaseDate',
            width: isHistoryView ? '14%' : '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.releaseDate) {
                    return moment(record.releaseDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetAssignHistory.table.assetRelease'),
            dataIndex: ['assetRelease'],
            width: isHistoryView ? '10%' : '6%',
            render: (text: any, record: any) => {
                if (record && record.assetRelease && record.assetRelease.id) {
                    let assetReleaseText =
                        record.assetRelease.ord && record.releaseDate
                            ? record.assetRelease.ord + '/' + moment(record.releaseDate).format('YYYY')
                            : record.assetRelease.id;
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.assetRelease.fileId,
                                    null,
                                    geti18nText('app.default.assetReleasePrint', [
                                        moment(record?.releaseDate).format('yyyyMMDD'),
                                    ])
                                );
                            }}
                        >
                            {assetReleaseText}
                        </Button>
                    );
                }
            },
        },
        {
            title: geti18nText('assetAssignHistory.table.column.returnDate'),
            dataIndex: 'returnDate',
            width: isHistoryView ? '14%' : '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.returnDate) {
                    return moment(record.returnDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetAssignHistory.table.assetReturn'),
            dataIndex: ['assetReturn'],
            width: isHistoryView ? '10%' : '6%',
            render: (text: any, record: any) => {
                if (record && record.assetReturn && record.assetReturn.id) {
                    let assetReturnText =
                        record.assetReturn.ord && record.returnDate
                            ? record.assetReturn.ord + '/' + moment(record.returnDate).format('YYYY')
                            : record.assetReturn.id;
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.assetReturn.fileId,
                                    null,
                                    geti18nText('app.default.assetReturnPrint', [
                                        moment(record?.returnDate).format('yyyyMMDD'),
                                    ])
                                );
                            }}
                        >
                            {assetReturnText}
                        </Button>
                    );
                }
            },
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="asset-assign-history"
                url={CONSTANTS_REQ.ASSET_ASSIGN_HISTORY.LIST}
                hideButtons
                onRowSelect={() => null}
                columns={initialColumns}
                setDefaultSortOrder={
                    sortOrder ?? {
                        order: 'releaseDate',
                        orderType: 'desc',
                    }
                }
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={isHistoryView ? 10 : table.setDefaultPageSize()}
                shortcuts={true}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('assetAssignHistory.csv.title')}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
            />
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            employeePerson: (value: any) => {
                return (
                    value != undefined &&
                    value.firstName != undefined &&
                    value.lastName != undefined &&
                    value.firstName + ' ' + value.lastName
                );
            },
        },
        {
            assetType: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            assetModel: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            assetCategory: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            releaseDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            returnDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetAssignHistory.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('assetAssignHistory.table.column.assetCategory'),
            dataIndex: ['asset', 'assetCategory', 'name'],
        },
        {
            title: geti18nText('assetAssignHistory.table.column.assetType'),
            dataIndex: ['asset', 'assetType', 'name'],
        },
        {
            title: geti18nText('assetAssignHistory.table.column.assetModel'),
            dataIndex: ['asset', 'assetModel', 'name'],
        },
        {
            title: geti18nText('assetAssignHistory.table.column.serialNumber'),
            dataIndex: ['asset', 'serialNumber'],
        },
        {
            title: geti18nText('inventoryOrder.table.column.name'),
            dataIndex: 'employeePerson',
        },
        {
            title: geti18nText('employee.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('assetAssignHistory.table.column.releaseDate'),
            dataIndex: 'releaseDate',
        },
        {
            title: geti18nText('assetAssignHistory.table.assetRelease'),
            dataIndex: ['assetRelease'],
        },
        {
            title: geti18nText('assetAssignHistory.table.column.returnDate'),
            dataIndex: 'returnDate',
        },
        {
            title: geti18nText('assetAssignHistory.table.assetReturn'),
            dataIndex: ['assetReturn'],
        },
    ];
};

export default AssetAssignHistoryIndex;
