import { PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NyModalConfirm, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Descriptions, Divider, Form, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import TravelWarrantSummaryIndex from '../../../../../components/travel-warrant-summary';
import useEnum from '../../../../../hooks/useEnum';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    okNotification,
    onKeyDownTextAreaCustom,
    warningNotification,
} from '../../../../../utils/Utils';

const OrganizationTravelWarrantModal = ({
    modalVisible,
    setModalVisible,
    travelWarrantId,
    setTravelWarrantId,
    refresh,
    setRefresh,
}: any) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const enVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(travelWarrantStatusEnum.DRAFT);
    const [travelWarrant, setTravelWarrant] = useState<any>(null);
    const [sendNotification, setSendNotification] = useState(true);
    const [employeeTravelWarrant, setEmployeeTravelWarrant] = useState<any>(null);
    const [isPlane, setIsPlane] = useState(false);
    const [currency, setCurrency] = useState<any>(undefined);
    const [organizationOrdinal, setOrganizationOrdinal] = useState<any>(null);
    const [ordinal, setOrdinal] = useState<any>('');
    const componentRef = useRef<any>();

    const [printPdf, setPrintPdf] = useState(false);
    const [dataFormId, setDataFormId] = useState<any>(undefined);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [success, setSuccess] = useState<any>(true);
    const [canContinue, setCanContinue] = useState(false);
    const [vehicleType, setVehicleType] = useState<any>(0);

    const setDefaultFilterValueFiles = (travelWarrantId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
        ];
    };

    useEffect(() => {
        if (travelWarrantId) {
            setTravelWarrantValues(travelWarrantId);
            setValuesFiles(travelWarrantId);
        }
    }, [travelWarrantId]);

    useEffect(() => {
        if (dataFormId && dataFormId > 0) {
            setValuesFiles(dataFormId);
        }
    }, [refreshFiles]);

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false);
        setIsPlane(false);
        setFiles([]);
        setTravelWarrantStatus(travelWarrantStatusEnum.DRAFT);
        setTravelWarrant(null);
        setEmployeeTravelWarrant(null);
        setSendNotification(true);
        setCurrency(null);
        setOrdinal('');
        setSuccess(true);
        if (setTravelWarrantId) {
            setTravelWarrantId(null);
        }
        setCanContinue(false);
    };

    const setTravelWarrantValues = (travelWarrantId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + travelWarrantId, undefined).then(
            (result: any) => {
                if (result && result.status === RESPONSE.OK && result.data) {
                    setValues(result.data);
                }
            }
        );
    };

    const setValues = (data: any) => {
        if (data.status > travelWarrantStatusEnum.DRAFT) {
            setOrdinal(
                data.ordinal && data.warrantDate ? data.ordinal + '/' + moment(data.warrantDate).format('YYYY') : null
            );
        }
        setOrganizationOrdinal(
            data && data.additionalInfoOrdinal && data.created
                ? data.additionalInfoOrdinal + '/' + moment(data.created).format('YYYY')
                : null
        );
        setTravelWarrantStatus(data.status ? data.status : travelWarrantStatusEnum.DRAFT);
        setDataFormId(data.id && data.id);
        const advancePaymentCurrency = data.advancePaymentCurrency;
        if (advancePaymentCurrency != undefined && advancePaymentCurrency.id && advancePaymentCurrency.isoCode) {
            setCurrency({ id: advancePaymentCurrency.id, text: advancePaymentCurrency.isoCode });
        }

        setIsPlane(false);
        if (data.travelVehicle && data.travelVehicle.vehicleType == 3) {
            setIsPlane(true);
        }
        setTravelWarrant(data);
        if (data.employee && data.employee.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + data.employee.id, undefined).then(
                (result: any) => {
                    if (result && result.status === RESPONSE.OK && result.data) {
                        setEmployeeTravelWarrant(result.data);
                    }
                }
            );
        }
        setVehicleType(data?.travelVehicle?.vehicleType);
    };

    function setValuesFiles(travelWarrantId: any) {
        setFiles([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(travelWarrantId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(result.data.content);
                } else {
                    setFiles([]);
                }
            }
        });
    }

    const save = (event: any, selectedDecision?: any) => {
        if (event) event.preventDefault();
        setLoading(true);
        let message = form.getFieldValue(['message']) ? form.getFieldValue(['message']) : null;
        if (sendNotification) {
            if (selectedDecision) {
                changeStatus(travelWarrantId, selectedDecision, message);
            } else {
                changeStatus(travelWarrantId, travelWarrantStatusEnum.ORGANIZED, message);
            }
        }
    };

    const changeStatus = (recordId: any, status: any, message: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: message,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (status == travelWarrantStatusEnum.ORGANIZED) {
                    okNotification(geti18nText('travelWarrantOrganization.confirm.ok'));
                }
                if (setRefresh) setRefresh(() => refresh + 1);
                if (setLoading) setLoading(false);
                // filesUpload(travelWarrantId);
                closeModal();
            } else {
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const getOrdinal = () => {
        let returnHeader = '';
        if (ordinal) {
            returnHeader = returnHeader + ' - ' + ordinal;
        }
        if (organizationOrdinal) {
            returnHeader = returnHeader + ' - ' + organizationOrdinal;
        }
        return returnHeader;
    };

    const openDetails = () => {
        history.push('/travel-warrant/travelWarrant/' + travelWarrantId);
    };

    const getCustomFooterContent = (
        <>
            <div>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button key={'print'} icon={<PrinterOutlined />}>
                                {geti18nText('travelWarrant.action.pdf.short')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(true);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(false);
                    }}
                    content={() => componentRef.current}
                    pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                />
                <Button type="primary" onClick={openDetails}>
                    {geti18nText('travelWarrant.overview.details')}
                </Button>
                <Button onClick={closeModal}>{geti18nText('app.default.button.cancel')}</Button>
                {
                    <NyModalConfirm
                        title={geti18nText('travelWarrant.overview.cancel.confirm')}
                        onConfirm={() => {
                            save(null, travelWarrantStatusEnum.CANCELLED);
                        }}
                    >
                        <Button key="reject" style={{ color: 'white', backgroundColor: 'red' }}>
                            {geti18nText('menu.travel.warrant.cancel')}
                        </Button>
                    </NyModalConfirm>
                }
                {success && (
                    <NyModalConfirm
                        title={geti18nText('travelWarrantPersonal.send.organization.confirm')}
                        onConfirm={() => {
                            if (canContinue) {
                                save(null);
                            } else {
                                warningNotification(geti18nText('travel.warrant.no.files.for.status.organized'));
                            }
                        }}
                    >
                        <Button className="green-button" type="primary">
                            {geti18nText('app.default.button.organize')}
                        </Button>
                    </NyModalConfirm>
                )}
            </div>
        </>
    );

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={
                        organizationOrdinal
                            ? geti18nText('travelWarrantOrganization.header') + ' - ' + organizationOrdinal
                            : geti18nText('travelWarrantOrganization.header')
                    }
                    okText={geti18nText('travelWarrantOrganization.save')}
                    onOk={(e) => save(e)}
                    onCancel={closeModal}
                    confirmLoading={loading}
                    width={900}
                    maskClosable={false}
                    footer={getCustomFooterContent}
                >
                    {' '}
                    <Row
                        gutter={24}
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '70vh',
                        }}
                    >
                        <Col span={24}>
                            <Form
                                layout="vertical"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                form={form}
                                onFinishFailed={({ errorFields }) => {
                                    form.scrollToField(errorFields[0].name);
                                }}
                            >
                                <TravelWarrantSummaryIndex
                                    componentRefProp={componentRef}
                                    printPdfProp={printPdf}
                                    setPrintPdfProp={setPrintPdf}
                                    employeeTravelWarrant={employeeTravelWarrant}
                                    globalForm={travelWarrant}
                                    formSummary={form}
                                    isPlane={isPlane}
                                    currency={currency}
                                    files={files}
                                    setFiles={setFiles}
                                    isOrganizationModal={true}
                                    printPdfHeader={geti18nText('travelWarrantOrganization.header') + getOrdinal()}
                                    showPrintButton={false}
                                    id={travelWarrantId}
                                    travelWarrantStatus={travelWarrantStatus}
                                    setRefreshFiles={setRefreshFiles}
                                    showCo2={
                                        travelWarrant?.travelVehicle?.vehicleType == enVehicleType.PRIVATE_CAR ||
                                        travelWarrant?.travelVehicle?.vehicleType == enVehicleType.OFFICIAL_CAR
                                            ? false
                                            : true
                                    }
                                    setCanContinue={setCanContinue}
                                    message={form.getFieldValue(['message'])}
                                />
                                {!printPdf && (
                                    <>
                                        <Divider orientation="left" plain>
                                            {geti18nText('travelWarrantOrganization.table.column.note')}
                                        </Divider>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item name="message">
                                                    <TextArea
                                                        rows={4}
                                                        onKeyDown={onKeyDownTextAreaCustom}
                                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                                    ></TextArea>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="sendNotification" valuePropName="checked" initialValue={true}>
                                            <Checkbox
                                                onChange={(event: any) => {
                                                    setSendNotification(event.target.checked);
                                                }}
                                                disabled={true}
                                            >
                                                {geti18nText('travelWarrantOrganization.table.column.sendNotification')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default OrganizationTravelWarrantModal;
