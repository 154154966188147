import { HistoryOutlined } from '@ant-design/icons';
import { NyDataTable, geti18nText } from '@nybble/nyreact';
import { Modal, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import useTableSettings from '../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../utils/Constants';

const AddressHistoryIndex = ({
    employeeId,
    type,
    buttonStyle = {},
    extraHeader = null,
    scroll = { y: 580, x: 800 },
    sortOrder,
}: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(0);

    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 0 },
            { field: 'employee.id', condition: 'equals', value: employeeId },
            { field: 'type', condition: 'equals', value: type },
        ];
    };

    return (
        <>
            <Tooltip title={geti18nText('app.default.history')}>
                <HistoryOutlined
                    style={buttonStyle}
                    className="ny-note-modal-icon"
                    onClick={() => {
                        setVisible(true);
                    }}
                />
            </Tooltip>
            {visible && (
                <Modal
                    visible={visible}
                    title={
                        <>
                            {geti18nText('app.default.history')}
                            {extraHeader && ' - ' + extraHeader}
                        </>
                    }
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    width={1200}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    <NyDataTable
                        nyId="address-history"
                        url={CONSTANTS_REQ.EMPLOYEE_ADDRESS.LIST}
                        buttonsClassName="buttons-sticky"
                        fetchWhenChange={refresh}
                        readonly
                        hideButtons
                        hideNewButton
                        hidePagination
                        showCleanTableFilters={false}
                        nyTestId="employee-address-table"
                        setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                        scroll={scroll}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        setDefaultFilterValue={setDefaultFilterValue}
                        columns={[
                            {
                                title: geti18nText('employeeAddress.table.column.address'),
                                dataIndex: ['address', 'street'],
                            },
                            {
                                title: geti18nText('employeeAddress.table.column.settlement'),
                                dataIndex: ['address', 'settlement', 'name'],
                            },
                            {
                                title: geti18nText('employeeAddress.table.column.postOffice'),
                                dataIndex: ['address', 'settlement', 'postOffice', 'name'],
                                render: (text: any, record: any) => {
                                    return (
                                        <>
                                            {record?.address?.settlement?.postOffice?.id && (
                                                <>
                                                    {(record?.address?.settlement?.postOffice?.zipCode
                                                        ? record?.address?.settlement?.postOffice?.zipCode
                                                        : '') +
                                                        ' ' +
                                                        record?.address?.settlement?.postOffice?.name}
                                                </>
                                            )}
                                        </>
                                    );
                                },
                            },
                            {
                                title: geti18nText('employeeAddress.table.column.township'),
                                dataIndex: ['address', 'settlement', 'township', 'name'],
                            },
                            {
                                title: geti18nText('employeeAddress.table.column.county'),
                                dataIndex: ['address', 'settlement', 'county', 'name'],
                            },
                            {
                                title: geti18nText('employeeAddress.table.column.dateFrom'),
                                dataIndex: 'dateFrom',
                                width: '8%',
                                render: (text: any, record: any) => {
                                    if (record.dateFrom) {
                                        return moment(record.dateFrom).format('DD.MM.YYYY');
                                    }
                                },
                            },
                            {
                                title: geti18nText('employeeAddress.table.column.dateTo'),
                                dataIndex: 'dateTo',
                                width: '8%',
                                render: (text: any, record: any) => {
                                    if (record.dateTo) {
                                        return moment(record.dateTo).format('DD.MM.YYYY');
                                    }
                                },
                            },
                        ]}
                    />
                </Modal>
            )}
        </>
    );
};

export default AddressHistoryIndex;
