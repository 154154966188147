import { NySettings, NyUtils, geti18nText } from '@nybble/nyreact';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import hrHR from 'antd/lib/locale/hr_HR';
import moment from 'moment';
import 'moment/locale/hr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store, { persistor } from './store';
import { APPLICATION_KEY, DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, URL_PREFIX } from './utils/Constants';

moment.locale(NyUtils.getSelectedLocale() === 'hr-HR' ? 'hr-HR' : 'en_US', {
    week: {
        dow: 1,
    },
});

moment.updateLocale(NyUtils.getSelectedLocale() === 'hr-HR' ? 'hr' : 'en', {
    invalidDate: geti18nText('app.default.invalidDate'),
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/* <ErrorBoundary> */}
                <ConfigProvider locale={NyUtils.getSelectedLocale() === 'hr-HR' ? hrHR : enUS}>
                    <NySettings
                        applicationKey={APPLICATION_KEY}
                        urlPreffix={URL_PREFIX}
                        urlRefresh={URL_PREFIX + 'auth/refresh'}
                        globalTableSettings={{
                            defaultPageSize: DEFAULT_PAGE_SIZE,
                            defaultInitialPage: DEFAULT_INITIAL_PAGE
                        }}
                    />
                    <App />
                </ConfigProvider>
                {/* </ErrorBoundary> */}
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
