import {
    CalculatorOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    DownOutlined,
    FilePdfOutlined,
    FlagOutlined,
    ForwardOutlined,
    FrownOutlined,
    HistoryOutlined,
    LockOutlined,
    PrinterOutlined,
    RightCircleOutlined,
    SendOutlined,
    ToolOutlined,
    UploadOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Dropdown, Form, Menu, Modal, Radio, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useEnum from '../../hooks/useEnum';
import { AdministrationRights } from '../../rights/administrationRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumValueForName } from '../../utils/Enums';
import {
    ResolveEmployeeEducationStatusIcon,
    fileDownload,
    isAdmin,
    onKeyDownTextAreaCustom,
    userIsNotificationRecipient,
} from '../../utils/Utils';
import AssetFlowSpecificationReportModal from '../asset-flow-specification-report-modal';
import NyHistory from '../ny-history/NyHistory';
import NyReportButton from '../report-button';

const RowAction = ({
    component,
    record,
    changeStatus,
    changeStatusUnpaid,
    download,
    downloadLabel = '',
    downloadIcon,
    doAction,
    fetchAccessRights = false,
    //TravelWarrant
    calculate,
    calculateAdminForm,
    organize,
    approveReport,
    travelWarrantCardButton = false,
    showInEdit = false,
    showTravelWizard,
    showTravelAction = false,
    setWizard = false,
    setWarrantID = null,
    //Tasks
    setRecord,
    setAssignModalVisible,
    setStartTaskVisible,
    setSelectedTaskId,
    setCancelTaskVisible,
    setResolveTaskVisible,
    setCannotResolveTaskVisible,
    setPartiallyResolveTaskVisible,
    setCloseTaskVisible,
    setAddDescriptionVisible,
    //Reports
    reportUrl,
    reportCustomParms,
    reportSubreportType,
    reportText,
    reportFileNameEnum,
    //Assets
    setAssetFlowSpecificationReportModalVisible,
    setHistoryModalVisible,
}: any) => {
    const [cancelTripModalVisible, setCancelTripModalVisible] = useState<boolean>(false); // travelWarrantCancelationVisible
    const [travelWarrantCancelationVisible, setTravelWarrantCancelationVisible] = useState<boolean>(false);
    const [cancelationType, setCancelationType] = useState<string>('');
    const [isPersonalView, setIsPersonalView] = useState<boolean>(false);
    const [visibleButton, setVisibleButton] = useState<boolean>(false);
    const [recordId, setRecordId] = useState(null);
    const [cancelTripForm] = Form.useForm();
    const { employee } = useSelector((state: RootState) => state.employee);

    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const type = useEnum('MODULE_ACCESS_RIGHT');
    const enTaskAction = useEnum('TASK_ACTION');
    const employeeEducationStatus = useEnum('EMPLOYEE_EDUCATION_STATUS');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const mobilizationStatus = useEnum('MOBILIZATION_STATUS');

    const canTravelWarrantActions = () => {
        return TravelWarrantRights.canCreateTravelWarrant();
    };

    const isUserRole = () => {
        return TravelWarrantRights.canCreateTravelWarrantOnlyUser();
    };

    const canTravelWarrantActionsAdmin = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const canTravelWarrantPrints = () => {
        return TravelWarrantRights.canCreateTravelWarrantAll();
    };

    const getModuleAccessRights = () => {
        const url = CONSTANTS_REQ.EMPLOYEE.ACCESS_RIGHT_FOR;
        const urltype = url.replace('{type}', type.TRAVEL_WARRANT);
        const urlreplaced = urltype.replace('{employeeId}', record?.employee?.id);
        record?.employee?.id &&
            NyRequestResolver.requestGet(urlreplaced).then((result: any) => {
                setVisibleButton(result?.status === RESPONSE.OK);
            });
    };

    const getRowActionMenu = () => {
        setIsPersonalView(false);
        switch (component) {
            case 'TravelWarrant':
                return travelWarrantRowActions();
            case 'TravelWarrantAction':
                return travelWarrantAction();
            case 'TravelWarrantPrint':
                return travelWarrantPrint();
            case 'TravelWarrantPersonal':
                setIsPersonalView(true);
                return travelWarrantPersonalRowActions();
            case 'TravelWarrantPersonalPrint':
                setIsPersonalView(true);
                return travelWarrantPersonalPrint();
            case 'TravelWarrantPersonalAction':
                setIsPersonalView(true);
                return travelWarrantPersonalAction();
            case 'Release':
                return releaseRowActions();
            case 'InventoryOrder':
                return inventoryOrderRowActions();
            case 'MyInventoryOrder':
                return myInventoryOrderRowActions();
            case 'AssetOrder':
                return assetOrderRowActions();
            case 'ServicesOrder':
                return servicesOrderRowActions();
            case 'Return':
                return returnRowActions();
            case 'Download':
                return downloadRowActions();
            case 'TravelWarrantUnpaid':
                return travelWarrantUnpaidActions(false);
            case 'TravelWarrantUnpaidWaitingForApproval':
                return travelWarrantUnpaidActions(true);
            case 'TravelWarrantCancelRequest':
                return TravelWarrantCancelRequest();
            case 'EmployeeAnnualLeave':
                return employeeAnnualLeaveRowActions();
            case 'EmployeeAnnualLeaveChangeStatus':
                return employeeAnnualLeaveChangeStatusRowActions();
            case 'MobilizationRequest':
                return mobilizationRequestRowActions();
            case 'MobilizationApproval':
                return mobilizationApprovalRowActions();
            case 'MobilizationCall':
                return generateMobilizationCall();
            case 'MobilizationCallBusinessUnit':
                return generateMobilizationCallBusinessUnit();
            case 'TaskList':
                return taskListRowActions();
            case 'Tasks':
                return generateTasks();
            case 'EmployeeHealthExamination':
                return healthExamination();
            case 'EmployeeEducationApproval':
                return EmployeeEducationApprovalRowActions();
            case 'EmployeeEvaluation':
                return EmployeeEvaluationRowActions();
            case 'DownloadReport':
                return downloadReport();
            case 'FixedAsset':
                return FixedAssetRowActions();
            default:
                return defaultRowActions();
        }
    };

    const defaultRowActions = () => <div></div>;

    const iconStyle: any = {
        fontSize: '16px',
        paddingRight: '10px',
        paddingTop: '2px',
        float: 'left',
    };

    const canCancel = () => {
        return (
            AdministrationRights.isAdmin() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION)
        );
    };

    const travelWarrantActions = () => {
        if (
            record.status == travelWarrantStatusEnum.PAID ||
            record.status == travelWarrantStatusEnum.CANCELLED ||
            record.status == travelWarrantStatusEnum.CALCULATED ||
            record.status == travelWarrantStatusEnum.WAITING_FOR_CANCELLATION
        ) {
            return null;
        }
        return (
            <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record &&
                        changeStatus != undefined &&
                        (record.status == travelWarrantStatusEnum.DRAFT ||
                            record.status == travelWarrantStatusEnum.REJECTED) &&
                        canTravelWarrantActionsAdmin() &&
                        sendToApprovalAction()}
                    {record &&
                        record.id &&
                        showTravelAction != undefined &&
                        record.status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL &&
                        (canTravelWarrantActionsAdmin() ||
                            (isUserRole() &&
                                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL))) && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    icon={<CheckOutlined style={iconStyle} />}
                                    onClick={showTravelAction}
                                    style={{ width: '100%' }}
                                >
                                    {geti18nText('travelWarrant.action.approve')}
                                </Button>
                            </div>
                        )}
                    {record &&
                        record.id &&
                        showTravelWizard != undefined &&
                        (record.status == travelWarrantStatusEnum.APPROVED ||
                            record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ||
                            record.status == travelWarrantStatusEnum.ORGANIZED ||
                            record.status == travelWarrantStatusEnum.REPORT_FIX_NEEDED) &&
                        (employee?.id == record?.employee?.id || canTravelWarrantActionsAdmin() || visibleButton) &&
                        completeTripAction()}
                    {/* calculate */}
                    {record &&
                        record.id &&
                        (calculate != undefined || calculateAdminForm != undefined) &&
                        (record.status == travelWarrantStatusEnum.REPORT_APPROVED ||
                            record.status == travelWarrantStatusEnum.NOT_CALCULATED) &&
                        (canTravelWarrantActionsAdmin() ||
                            (isUserRole() &&
                                userIsNotificationRecipient(
                                    employee,
                                    notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT
                                ))) && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                {calculateAdminForm ? (
                                    <Button
                                        icon={<CalculatorOutlined style={iconStyle} />}
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            calculateAdminForm(record.id);
                                        }}
                                    >
                                        {geti18nText('travelWarrant.table.calculate')}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    {/* organize */}
                    {record &&
                        record.id &&
                        organize != undefined &&
                        record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION &&
                        (canTravelWarrantActionsAdmin() ||
                            (isUserRole() &&
                                userIsNotificationRecipient(
                                    employee,
                                    notificationType.TRAVEL_WARRANT_ORGANISATION
                                ))) && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    icon={<CheckCircleOutlined style={iconStyle} />}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        organize(record.id);
                                    }}
                                >
                                    {geti18nText('travelWarrant.action.organize')}
                                </Button>
                            </div>
                        )}
                    {/* approve report */}
                    {record &&
                        record.id &&
                        approveReport != undefined &&
                        record.status == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL &&
                        (canTravelWarrantActionsAdmin() ||
                            (isUserRole() &&
                                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL))) && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    icon={<CheckCircleOutlined style={iconStyle} />}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        approveReport(record.id);
                                    }}
                                >
                                    {geti18nText('travelWarrant.action.tripReport')}
                                </Button>
                            </div>
                        )}
                    {record &&
                        (record.status == travelWarrantStatusEnum.APPROVED ||
                            record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ||
                            record.status == travelWarrantStatusEnum.ORGANIZED) &&
                        canTravelWarrantActionsAdmin() &&
                        cancelTripAction() && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyModalConfirm
                                    title={geti18nText('travelWarrantPersonal.cancelTrip.popconfirm')}
                                    onConfirm={() => {
                                        setCancelTripModalVisible(true);
                                        setRecordId(record.id);
                                    }}
                                >
                                    <Button icon={<CloseOutlined style={iconStyle} />} style={{ width: '100%' }}>
                                        {geti18nText('travelWarrantPersonal.cancelTrip')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                    {/* isplata predujma */}
                    {record &&
                        record.id &&
                        changeStatusUnpaid != undefined &&
                        (record.status == travelWarrantStatusEnum.APPROVED ||
                            record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ||
                            record.status == travelWarrantStatusEnum.ORGANIZED) &&
                        record.advancePaymentInCurrency != undefined &&
                        record.advancePaymentInCurrency > 0 &&
                        record.waitingAdvance &&
                        (canTravelWarrantActionsAdmin() ||
                            (isUserRole() &&
                                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT))) && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    icon={<SendOutlined style={iconStyle} />}
                                    style={{ width: '100%' }}
                                    onClick={changeStatusUnpaid}
                                >
                                    {geti18nText('travelWarrant.action.payAdvance')}
                                </Button>
                            </div>
                        )}
                    {/* // */}
                </div>
            </Menu.ItemGroup>
        );
    };

    const travelWarrantPrints = () => {
        return (
            canTravelWarrantPrints() && (
                <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        {record && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyReportButton
                                    url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT}
                                    subreportType={'TRAVEL_WARRANT'}
                                    customParms={{ id: Number(record.id) }}
                                    buttoni18nText={'travelWarrant.action.pdf'}
                                    fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT)}
                                    fileNameAddition={record.ordinal}
                                    type={'button'}
                                />
                            </div>
                        )}
                        {record &&
                            (record.status == travelWarrantStatusEnum.APPROVED ||
                                (record.status > travelWarrantStatusEnum.REJECTED &&
                                    record.status < travelWarrantStatusEnum.WAITING_FOR_CANCELLATION)) && (
                                <div style={{ display: 'block', margin: '5px' }}>
                                    <NyReportButton
                                        url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION}
                                        subreportType={'TRAVEL_WARRANT_CALCULATION'}
                                        customParms={{ id: Number(record.id) }}
                                        buttoni18nText={'travelWarrant.action.pdf.calculation'}
                                        fileName={geti18nText(
                                            'app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT_CALCULATION
                                        )}
                                        fileNameAddition={record.ordinal}
                                        type={'button'}
                                    />
                                </div>
                            )}
                    </div>
                </Menu.ItemGroup>
            )
        );
    };

    const travelWarrantRowActions = () => (
        <Menu>
            {travelWarrantActions()}
            {travelWarrantPrints()}
        </Menu>
    );

    const travelWarrantAction = () => (
        <div className="menu-added-box-shadow">
            <Menu className="menu-added-box-shadow">{travelWarrantActions()}</Menu>
        </div>
    );

    const travelWarrantPrint = () => (
        <div className="menu-added-box-shadow">
            <Menu>{travelWarrantPrints()}</Menu>
        </div>
    );

    const sendToApprovalAction = () => {
        return (
            <div style={{ display: 'block', margin: '5px' }}>
                <Button
                    onClick={() => {
                        setWizard(true);
                        setWarrantID(record.id);
                    }}
                    icon={<CheckOutlined style={iconStyle} />}
                    style={{ width: '100%' }}
                >
                    {geti18nText('travelWarrantPersonal.sendToApproval')}
                </Button>
            </div>
        );
    };
    const completeTripAction = () => {
        return (
            <div style={{ display: 'block', margin: '5px' }}>
                <Button
                    onClick={() => showTravelWizard(record.id)}
                    icon={<RightCircleOutlined style={iconStyle} />}
                    style={{ width: '100%' }}
                >
                    {geti18nText('travelWarrant.overview.complete')}
                </Button>
            </div>
        );
    };

    const cancelTripAction = () => {
        return (
            <div style={{ display: 'block', margin: '5px' }}>
                <NyModalConfirm
                    title={geti18nText('travelWarrantPersonal.cancelTrip.popconfirm')}
                    onConfirm={() => {
                        setCancelTripModalVisible(true);
                        setRecordId(record.id);
                    }}
                >
                    <Button icon={<CloseOutlined style={iconStyle} />} style={{ width: '100%' }}>
                        {geti18nText('travelWarrantPersonal.cancelTrip')}
                    </Button>
                </NyModalConfirm>
            </div>
        );
    };

    const travelWarrantPersonalActions = () => {
        if (
            record.status == travelWarrantStatusEnum.PAID ||
            record.status == travelWarrantStatusEnum.CANCELLED ||
            record.status == travelWarrantStatusEnum.CALCULATED ||
            record.status == travelWarrantStatusEnum.WAITING_FOR_CANCELLATION ||
            record.status == travelWarrantStatusEnum.REPORT_APPROVED ||
            record.status == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL ||
            record.status == travelWarrantStatusEnum.NOT_CALCULATED
        ) {
            return null;
        }

        return (
            canTravelWarrantActions() && (
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        {record &&
                            (record.status == travelWarrantStatusEnum.DRAFT ||
                                record.status == travelWarrantStatusEnum.REJECTED) &&
                            sendToApprovalAction()}
                        {record &&
                            (record.status == travelWarrantStatusEnum.APPROVED ||
                                record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ||
                                record.status == travelWarrantStatusEnum.ORGANIZED ||
                                record.status == travelWarrantStatusEnum.REPORT_FIX_NEEDED) &&
                            (employee?.id == record?.employee?.id || canTravelWarrantActionsAdmin() || visibleButton) &&
                            completeTripAction()}
                        {record &&
                            (record.status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL ||
                                record.status == travelWarrantStatusEnum.APPROVED ||
                                record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ||
                                record.status == travelWarrantStatusEnum.ORGANIZED) &&
                            cancelTripAction()}
                    </div>
                </Menu.ItemGroup>
            )
        );
    };

    const travelWarrantPersonalRowActions = () => (
        <Menu>
            {travelWarrantPersonalActions()}
            {travelWarrantPrints()}
        </Menu>
    );

    const travelWarrantPersonalAction = () => (
        <div className="menu-added-box-shadow">
            <Menu className="menu-added-box-shadow">{travelWarrantPersonalActions()}</Menu>
        </div>
    );

    const travelWarrantPersonalPrint = () => (
        <div className="menu-added-box-shadow">
            <Menu>{travelWarrantPrints()}</Menu>
        </div>
    );

    const releaseRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.file && record.file.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                icon={<FilePdfOutlined style={iconStyle} />}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    fileDownload(
                                        CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.file.id,
                                        null,
                                        geti18nText('app.default.assetReleasePrint', [moment().format('yyyyMMDD')])
                                    );
                                }}
                            >
                                {geti18nText('assetRelease.button.downloadNew')}
                            </Button>
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const myInventoryOrderRowActions = () => (
        <Menu>
            {record != undefined && record.status == 1 && (
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                icon={<CloseCircleOutlined style={iconStyle} />}
                                style={{ width: '100%' }}
                                onClick={() => changeStatus(record.id)}
                            >
                                {geti18nText('inventoryOrder.cancel.action.title')}
                            </Button>
                        </div>
                    </div>
                </Menu.ItemGroup>
            )}
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.INVENTORY_ORDER}
                                customParms={{ inventoryOrderId: Number(record.id) }}
                                subreportType={'INVENTORY_ORDER'}
                                buttoni18nText={'report.inventoryOrder'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.INVENTORY_ORDER)}
                                type={'button'}
                                allowExportTypeChange={true}
                            />
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const inventoryOrderRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.INVENTORY_ORDER}
                                customParms={{ inventoryOrderId: Number(record.id) }}
                                subreportType={'INVENTORY_ORDER'}
                                buttoni18nText={'report.inventoryOrder'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.INVENTORY_ORDER)}
                                type={'button'}
                                allowExportTypeChange={true}
                            />
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const EmployeeEvaluationRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.EMPLOYEE_EVALUATION}
                                customParms={{ employeeEvaluationId: Number(record.id) }}
                                subreportType={'EMPLOYEE_EVALUATION'}
                                buttoni18nText={'report.evaluation'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.EMPLOYEE_EVALUATION)}
                                customDateFormat={moment(record?.employeeEvaluationPeriod?.dateFrom).year()}
                                type={'button'}
                            />
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const servicesOrderRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.SERVICES_ORDER}
                                customParms={{ servicesOrderId: Number(record.id), status: record.status }}
                                subreportType={'SERVICES_ORDER'}
                                buttoni18nText={'report.servicesOrder'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.SERVICES_ORDER)}
                                type={'button'}
                                allowExportTypeChange={true}
                            />
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const assetOrderRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.ASSET_ORDER}
                                customParms={{ assetOrderId: Number(record.id) }}
                                subreportType={'ASSET_ORDER'}
                                buttoni18nText={'report.assetOrder'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_ORDER)}
                                type={'button'}
                                allowExportTypeChange={true}
                            />
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const returnRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.file && record.file.id && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                icon={<FilePdfOutlined style={iconStyle} />}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    fileDownload(
                                        CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.file.id,
                                        null,
                                        geti18nText('app.default.assetReturnPrint', [moment().format('yyyyMMDD')])
                                    );
                                }}
                            >
                                {geti18nText('assetReturn.button.downloadNew')}
                            </Button>
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const employeeAnnualLeaveRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && record.employee && record.employee.id && record.annualLeaveYear && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.APPROVED_ANNUAL_LEVE_REPORT}
                                subreportType={'APPROVED_ANNUAL_LEAVE_FORM'}
                                customParms={{
                                    employeeId: record.employee.id,
                                    year: record.annualLeaveYear,
                                }}
                                buttoni18nText={'employeeAnnualLeave.edit.vacation.pdf'}
                                fileName={geti18nText(
                                    'app.enum.SUBREPORT_TYPE.' + EnSubreportType.APPROVED_ANNUAL_LEAVE_FORM
                                )}
                                type={'button'}
                            />
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const employeeAnnualLeaveChangeStatusRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            icon={<CheckCircleOutlined style={iconStyle} />}
                            style={{ width: '100%' }}
                            onClick={() => changeStatus(record, employeeLeaveStatus.CONFIRMED)}
                        >
                            {geti18nText('employeeAnnualLeave.edit.approve')}
                        </Button>
                    </div>
                </div>

                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            icon={<CloseCircleOutlined style={iconStyle} />}
                            style={{ width: '100%' }}
                            onClick={() => changeStatus(record, employeeLeaveStatus.REJECTED)}
                        >
                            {geti18nText('employeeAnnualLeave.edit.reject')}
                        </Button>
                    </div>
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const mobilizationRequestRowActions = () => {
        return (
            <Menu>
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    {record.status == mobilizationStatus.PLAN && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyModalConfirm
                                    title={geti18nText('menu.work.obligation.call.table.sendForApproval.confirm')}
                                    onConfirm={() => {
                                        changeStatus(record.id, mobilizationStatus.WAITING_FOR_APPROVAL);
                                    }}
                                >
                                    <Button icon={<SendOutlined style={iconStyle} />} style={{ width: '100%' }}>
                                        {geti18nText('menu.work.obligation.call.table.sendForApproval')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        </div>
                    )}
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const mobilizationApprovalRowActions = () => {
        return (
            <Menu>
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('menu.work.obligation.call.table.approve.confirm')}
                                onConfirm={() => {
                                    changeStatus(record.id, mobilizationStatus.APPROVED);
                                }}
                            >
                                <Button icon={<CheckCircleOutlined style={iconStyle} />} style={{ width: '100%' }}>
                                    {geti18nText('menu.work.obligation.call.table.approve')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    </div>

                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('menu.work.obligation.call.table.reject.confirm')}
                                onConfirm={() => {
                                    changeStatus(record.id, mobilizationStatus.REJECTED);
                                }}
                            >
                                <Button icon={<CloseCircleOutlined style={iconStyle} />} style={{ width: '100%' }}>
                                    {geti18nText('menu.work.obligation.call.table.reject')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    </div>
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const generateMobilizationCall = () => (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {record?.employee?.id && (
                    <>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.MOBILIZATION_CALL}
                                customParms={{
                                    employeeId: record?.employee?.id,
                                }}
                                buttoni18nText={'employeeMilitaryRecords.generateMobilizationCall.generate.employee'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.MOBILIZATION_CALL)}
                                type={'button'}
                            />
                        </div>
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    icon={<UploadOutlined style={iconStyle} />}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        doAction(record?.employee?.id);
                                    }}
                                >
                                    {geti18nText('employeeMilitaryRecords.upload.signed.mobilizationCall')}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Menu>
    );

    const generateMobilizationCallBusinessUnit = () => (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {record?.id && (
                    <>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.MOBILIZATION_CALL_FOR_BUSINESS_UNIT}
                                subreportType={'MOBILIZATION_CALL'}
                                customParms={{
                                    businessUnitId: record.id,
                                }}
                                buttoni18nText={'employeeMilitaryRecords.generateMobilizationCall.fileName.employee'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.MOBILIZATION_CALL)}
                                type={'button'}
                            />
                        </div>
                    </>
                )}
            </div>
        </Menu>
    );

    const generateTasks = () => (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {record?.id && record?.status && (
                    <>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                icon={<CheckOutlined style={iconStyle} />}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    changeStatus(record);
                                }}
                            >
                                {geti18nText('app.default.button.approve')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Menu>
    );

    const healthExamination = () => (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {record && record.fileId && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            icon={<FilePdfOutlined style={iconStyle} />}
                            style={{ width: '100%' }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                    null,
                                    geti18nText('employee.healthExamination.edit.header') +
                                        '_' +
                                        record.fileId.toString()
                                );
                            }}
                        >
                            {geti18nText('employee.healthExamination.downloadFile')}
                        </Button>
                    </div>
                )}
            </div>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {record?.id && (
                    <>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.EMPLOYEE_HEALTH_EXAMINATION}
                                customParms={{
                                    employeeHealthExaminationId: record.id,
                                }}
                                buttoni18nText={'employee.healthExamination.generatePdf'}
                                fileName={record?.healthExaminationType?.name}
                                type={'button'}
                            />
                        </div>
                    </>
                )}
            </div>
        </Menu>
    );

    const downloadRowActions = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {record && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button icon={downloadIcon} style={{ width: '100%' }} onClick={() => download(record)}>
                                {geti18nText(downloadLabel)}
                            </Button>
                        </div>
                    )}
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const downloadReport = () => (
        <Menu>
            <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <NyReportButton
                        url={reportUrl}
                        customParms={reportCustomParms}
                        subreportType={reportSubreportType}
                        buttoni18nText={reportText}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + reportFileNameEnum)}
                    />
                </div>
            </Menu.ItemGroup>
        </Menu>
    );

    const travelWarrantUnpaidActions = (waitingForApproval: any) => (
        <Menu>
            {record != undefined && (
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                icon={<SendOutlined style={iconStyle} />}
                                style={{ width: '100%' }}
                                onClick={changeStatusUnpaid}
                            >
                                {geti18nText(
                                    waitingForApproval
                                        ? 'travelWarrant.table.approvePayment'
                                        : 'travelWarrant.table.paysoff'
                                )}
                            </Button>
                        </div>
                    </div>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                icon={<CloseCircleOutlined style={iconStyle} />}
                                style={{ width: '100%' }}
                                onClick={changeStatusUnpaid}
                            >
                                {geti18nText(
                                    waitingForApproval
                                        ? 'travelWarrant.table.rejectPayment'
                                        : 'travelWarrant.table.cancelPayment'
                                )}
                            </Button>
                        </div>
                    </div>
                    {canCancel() &&
                        (record.status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL ||
                            record.status == travelWarrantStatusEnum.APPROVED ||
                            record.status == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ||
                            record.status == travelWarrantStatusEnum.ORGANIZED) && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyModalConfirm
                                    title={geti18nText('travelWarrantPersonal.cancelTrip.popconfirm')}
                                    onConfirm={() => {
                                        setCancelTripModalVisible(true);
                                        setRecordId(record.id);
                                    }}
                                >
                                    <Button icon={<CloseOutlined style={iconStyle} />} style={{ width: '100%' }}>
                                        {geti18nText('travelWarrantPersonal.cancelTrip')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                </Menu.ItemGroup>
            )}
        </Menu>
    );

    const TravelWarrantCancelRequest = () => (
        <Menu>
            {record != undefined && (
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    {record.advanceRequested &&
                        !record.advanceConfirmed &&
                        (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                            userIsNotificationRecipient(
                                employee,
                                GetEnumValueForName({
                                    enumName: 'NOTIFICATION_TYPE',
                                    name: 'TRAVEL_WARRANT_PAYMENT',
                                })
                            )) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', margin: '5px' }}>
                                    <Button
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            setRecordId(record.id);
                                            setCancelationType('advance');
                                            setTravelWarrantCancelationVisible(true);
                                        }}
                                    >
                                        {geti18nText('travelWarrant.table.column.returnAdvance')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    {record.organizationRequested &&
                        !record.organizationConfirmed &&
                        (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                            userIsNotificationRecipient(
                                employee,
                                GetEnumValueForName({
                                    enumName: 'NOTIFICATION_TYPE',
                                    name: 'TRAVEL_WARRANT_ORGANISATION',
                                })
                            )) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', margin: '5px' }}>
                                    <Button
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            setRecordId(record.id);
                                            setCancelationType('organization');
                                            setTravelWarrantCancelationVisible(true);
                                        }}
                                    >
                                        {geti18nText('travelWarrant.table.column.returnOrganization')}
                                    </Button>
                                </div>
                            </div>
                        )}
                </Menu.ItemGroup>
            )}
        </Menu>
    );

    const taskListRowActions = () => (
        <Menu>
            {setRecord(record)}
            {record != undefined && record?.actions?.length > 0 && (
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    {record?.actions?.includes(enTaskAction.ASSIGN) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        setSelectedTaskId(record.id);
                                        setAssignModalVisible(true);
                                    }}
                                    icon={<UserAddOutlined style={iconStyle} />}
                                >
                                    {geti18nText('task.status.action.1')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.actions?.includes(enTaskAction.START) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => setStartTaskVisible(true)}
                                    icon={<ForwardOutlined style={iconStyle} />}
                                >
                                    {geti18nText('task.status.action.2')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.actions?.includes(enTaskAction.RESOLVE) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        setAddDescriptionVisible(true);
                                        setResolveTaskVisible(true);
                                    }}
                                    icon={<FlagOutlined style={iconStyle} />}
                                >
                                    {geti18nText('task.status.action.3')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.actions?.includes(enTaskAction.CANNOT_RESOLVE) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        setCannotResolveTaskVisible(true);
                                        setAddDescriptionVisible(true);
                                    }}
                                    icon={<FrownOutlined style={iconStyle} />}
                                >
                                    {geti18nText('task.status.action.4')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.actions?.includes(enTaskAction.PARTIALLY_RESOLVE) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        setPartiallyResolveTaskVisible(true);
                                        setAddDescriptionVisible(true);
                                    }}
                                    icon={<ToolOutlined style={iconStyle} />}
                                >
                                    {geti18nText('task.status.action.5')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.actions?.includes(enTaskAction.CLOSE) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        setCloseTaskVisible(true);
                                    }}
                                    icon={<LockOutlined style={iconStyle} />}
                                >
                                    {geti18nText('task.status.action.6')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {record?.actions?.includes(enTaskAction.CANCEL) && (
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    danger
                                    style={{ width: '100%' }}
                                    icon={<CloseOutlined style={iconStyle} />}
                                    onClick={() => setCancelTaskVisible(true)}
                                >
                                    {geti18nText('task.status.action.7')}
                                </Button>
                            </div>
                        </div>
                    )}
                </Menu.ItemGroup>
            )}
        </Menu>
    );

    const EmployeeEducationApprovalRowActions = () => {
        return (
            <Menu>
                {record != undefined && (
                    <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyModalConfirm
                                    title={geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.0')}
                                    onConfirm={() => {
                                        changeStatus(record.id, null);
                                    }}
                                >
                                    <Button
                                        icon={ResolveEmployeeEducationStatusIcon(null, iconStyle, '')}
                                        style={{ width: '100%' }}
                                        type="primary"
                                    >
                                        {geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.change.0')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        </div>
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyModalConfirm
                                    title={geti18nText(
                                        'app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.' +
                                            employeeEducationStatus.DRAFT_APPROVED
                                    )}
                                    onConfirm={() => {
                                        changeStatus(record.id, employeeEducationStatus.DRAFT_APPROVED);
                                    }}
                                >
                                    <Button
                                        icon={ResolveEmployeeEducationStatusIcon(
                                            employeeEducationStatus.DRAFT_APPROVED,
                                            iconStyle,
                                            ''
                                        )}
                                        type="primary"
                                        className="green-button"
                                        style={{ width: '100%' }}
                                    >
                                        {geti18nText(
                                            'app.enum.EMPLOYEE_EDUCATION_STATUS.change.' +
                                                employeeEducationStatus.DRAFT_APPROVED
                                        )}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        </div>
                        <div style={{ padding: '0px', textAlign: 'left' }}>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <NyModalConfirm
                                    title={geti18nText(
                                        'app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.' +
                                            employeeEducationStatus.DRAFT_REJECTED
                                    )}
                                    onConfirm={() => {
                                        changeStatus(record.id, employeeEducationStatus.DRAFT_REJECTED);
                                    }}
                                >
                                    <Button
                                        icon={ResolveEmployeeEducationStatusIcon(
                                            employeeEducationStatus.DRAFT_REJECTED,
                                            iconStyle,
                                            ''
                                        )}
                                        danger
                                        type={'primary'}
                                        style={{ width: '100%' }}
                                    >
                                        {geti18nText(
                                            'app.enum.EMPLOYEE_EDUCATION_STATUS.change.' +
                                                employeeEducationStatus.DRAFT_REJECTED
                                        )}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        </div>
                    </Menu.ItemGroup>
                )}
            </Menu>
        );
    };

    const FixedAssetRowActions = () => {
        return (
            <Menu>
                <Menu.ItemGroup title={geti18nText('app.default.actions')}>
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                block
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    setAssetFlowSpecificationReportModalVisible?.(true);
                                }}
                            >
                                {geti18nText('asset.report.assetFlowSpecification')}
                            </Button>
                        </div>
                    </div>
                    <div style={{ padding: '0px', textAlign: 'center' }}>
                        <div style={{ display: 'grid', margin: '5px' }}>
                            <Button icon={<HistoryOutlined />} onClick={() => setHistoryModalVisible(true)}>
                                {geti18nText('app.default.change.history')}
                            </Button>
                        </div>
                    </div>
                </Menu.ItemGroup>
            </Menu>
        );
    };

    return (
        <>
            <Space size="middle">
                <Dropdown
                    overlay={getRowActionMenu}
                    onOpenChange={(open) => {
                        if (open && fetchAccessRights) getModuleAccessRights();
                    }}
                >
                    {!showInEdit ? (
                        travelWarrantCardButton ? (
                            component === 'TravelWarrantPersonalPrint' || component === 'TravelWarrantPrint' ? (
                                <PrinterOutlined style={{ fontSize: '18px', marginTop: '2px' }} />
                            ) : (
                                <ToolOutlined style={{ fontSize: '18px', marginTop: '2px' }} />
                            )
                        ) : (
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                icon={<ToolOutlined style={{ fontSize: '14px' }} />}
                                style={{ cursor: 'pointer' }}
                            >
                                <DownOutlined />
                            </Button>
                        )
                    ) : component === 'TravelWarrantPersonalPrint' || component === 'TravelWarrantPrint' ? (
                        <Button>
                            <PrinterOutlined /> {geti18nText('app.default.button.print')}
                        </Button>
                    ) : (
                        <Button>
                            <ToolOutlined /> {geti18nText('app.default.actions')}
                        </Button>
                    )}
                </Dropdown>
            </Space>
            {cancelTripModalVisible && (
                <Modal
                    visible={cancelTripModalVisible}
                    title={geti18nText('travelWarrantPersonal.cancelTrip')}
                    okText={geti18nText('app.default.button.ok')}
                    onOk={(event: any) => {
                        event.stopPropagation();
                        event.preventDefault();

                        if (
                            record.status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL &&
                            userIsNotificationRecipient(
                                employee,
                                GetEnumValueForName({
                                    enumName: 'NOTIFICATION_TYPE',
                                    name: 'TRAVEL_WARRANT_APPROVAL',
                                })
                            ) &&
                            !isPersonalView
                        ) {
                            changeStatus(
                                recordId,
                                travelWarrantStatusEnum.REJECTED,
                                cancelTripForm.getFieldValue(['message'])
                                    ? cancelTripForm.getFieldValue(['message'])
                                    : null
                            );
                        } else {
                            changeStatus(
                                recordId,
                                travelWarrantStatusEnum.CANCELLED,
                                cancelTripForm.getFieldValue(['message'])
                                    ? cancelTripForm.getFieldValue(['message'])
                                    : null
                            );
                        }
                        setCancelTripModalVisible(false);
                        cancelTripForm.setFieldsValue({ message: null });
                    }}
                    onCancel={() => {
                        setCancelTripModalVisible(false);
                        cancelTripForm.setFieldsValue({ message: null });
                    }}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form form={cancelTripForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="message"
                                    label={geti18nText('travelWarrantOrganization.table.column.message')}
                                >
                                    <TextArea
                                        rows={4}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                    ></TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
            {travelWarrantCancelationVisible && (
                <Modal
                    visible={travelWarrantCancelationVisible}
                    title={
                        record && record.advanceRequested && cancelationType == 'advance'
                            ? geti18nText('travelWarrant.table.column.returnAdvance')
                            : record && record.organizationRequested && cancelationType == 'organization'
                            ? geti18nText('travelWarrant.table.column.returnOrganization')
                            : ''
                    }
                    onCancel={() => {
                        setTravelWarrantCancelationVisible(false);
                        setCancelationType('');
                        cancelTripForm.setFieldsValue({ note: null });
                    }}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    footer={[
                        <>
                            <Button
                                onClick={() => {
                                    setTravelWarrantCancelationVisible(false);
                                    setCancelationType('');
                                }}
                            >
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <NyModalConfirm
                                title={
                                    record && cancelationType == 'advance'
                                        ? geti18nText('travelWarrant.popconfirm.advanceReturned')
                                        : record && cancelationType == 'organization'
                                        ? geti18nText('travelWarrant.popconfirm.organizationCancelled')
                                        : ''
                                }
                                onConfirm={() => {
                                    changeStatus(
                                        recordId,
                                        cancelTripForm.getFieldValue(['note'])
                                            ? cancelTripForm.getFieldValue(['note'])
                                            : null,
                                        record && record.organizationRequested && cancelationType == 'organization'
                                            ? true
                                            : false,
                                        record && record.advanceRequested && cancelationType == 'advance' ? true : false
                                    );

                                    setTravelWarrantCancelationVisible(false);
                                    cancelTripForm.setFieldsValue({ note: null });
                                }}
                            >
                                <Button type="primary" danger>
                                    {record && record.advanceRequested && cancelationType == 'advance'
                                        ? geti18nText('travelWarrant.table.column.returnAdvance')
                                        : record && record.organizationRequested && cancelationType == 'organization'
                                        ? geti18nText('travelWarrant.table.column.returnOrganization')
                                        : ''}
                                </Button>
                            </NyModalConfirm>
                        </>,
                    ]}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form form={cancelTripForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="note"
                                    label={geti18nText('travelWarrantOrganization.table.column.message')}
                                >
                                    <TextArea
                                        rows={4}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                    ></TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default RowAction;
