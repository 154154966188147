import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColorFromNotificationType, getEnumBooleanArray } from '../../../../utils/Utils';
import ShiftEdit from './edit';

const ShiftIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    useHelper('human/codebooks/shift');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const days = [
        { value: 1, label: geti18nText('shift.edit.mon') },
        { value: 2, label: geti18nText('shift.edit.tue') },
        { value: 3, label: geti18nText('shift.edit.wed') },
        { value: 4, label: geti18nText('shift.edit.thu') },
        { value: 5, label: geti18nText('shift.edit.fri') },
        { value: 6, label: geti18nText('shift.edit.sat') },
        { value: 7, label: geti18nText('shift.edit.sun') },
    ];

    const columns = [
        {
            title: geti18nText('shift.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('shift.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('shift.table.column.abbreviation'),
            dataIndex: 'abbreviation',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('shift.table.column.hoursFrom'),
            dataIndex: 'hours_from',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('shift.table.column.hoursTo'),
            dataIndex: 'hours_to',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('shift.table.column.workdays'),
            dataIndex: 'workdays',
            sorter: (a: any, b: any) => {},
            render: (workdays: any) => {
                let data = workdays.split('');
                let tags: any = [];
                data.forEach((element: any) => {
                    tags.push(
                        <Tag style={{ marginBottom: '2px', marginTop: '2px' }} key={element}>
                            {days.find((day: any) => day.value + '' === element)?.label}
                        </Tag>
                    );
                });

                return tags;
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('shift.table.column.holidays'),
            dataIndex: 'holidays',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (holidays: any) => {
                if (holidays) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('shift.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-shift-table"
            url={CONSTANTS_REQ.SHIFT.LIST}
            addNewButtonText={geti18nText('shift.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={ShiftEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.shift')}
            headerTitle={geti18nText('menu.shift')}
            colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    const days = [
        { value: 1, label: geti18nText('shift.edit.mon') },
        { value: 2, label: geti18nText('shift.edit.tue') },
        { value: 3, label: geti18nText('shift.edit.wed') },
        { value: 4, label: geti18nText('shift.edit.thu') },
        { value: 5, label: geti18nText('shift.edit.fri') },
        { value: 6, label: geti18nText('shift.edit.sat') },
        { value: 7, label: geti18nText('shift.edit.sun') },
    ];
    return [
        {
            workdays: (value: any) => {
                let data = value.split('');
                let tags: any = [];
                data.forEach((element: any) => {
                    tags.push(days.find((day: any) => day.value + '' === element)?.label);
                });

                return tags;
            },
        },
        {
            holidays: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ShiftIndex;
