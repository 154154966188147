import { DeleteOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyDatePicker,
    NyIsDirtyModal,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Image, Input, Modal, Popconfirm, Popover, Row, Select, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import React, { ReactText, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import MealEdit from './edit';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealMenuEdit = (props: any) => {
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState(geti18nText('meal.menu.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [isCreate, setIsCreate] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [refreshTable, setRefreshTable] = useState(1);
    const [activeKey, setActiveKey] = useState('1');
    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [selectedValuesAddGroup, setSelectedValuesAddGroup] = useState<any>([]);
    const [hasSelectedAddGroup, setHasSelectedAddGroup] = useState(false);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);
    const [isDateOption, setIsDateOption] = useState<boolean>(true);
    const [isFormChange, setisFormChange] = useState<any>(false);
    const [showDirtyDataModal, setShowDirtyDataModal] = useState<any>(false);

    const [teamCreated, setTeamCreated] = useState(false);
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const [dateFrom, setDateFrom] = useState<any>(moment());
    const [dateTo, setDateTo] = useState<any>(undefined);

    useEffect(() => {
        if (isFormChange) {
            setShowDirtyDataModal(true);
        }
    }, [activeKey]);

    useEffect(() => {
        generateName();
    }, [isDateOption]);

    const dayOptions = [
        {
            value: 1,
            label: geti18nText('app.enum.MENU_DAY.' + 1),
        },
        {
            value: 2,
            label: geti18nText('app.enum.MENU_DAY.' + 2),
        },
        {
            value: 3,
            label: geti18nText('app.enum.MENU_DAY.' + 3),
        },
        {
            value: 4,
            label: geti18nText('app.enum.MENU_DAY.' + 4),
        },
        {
            value: 5,
            label: geti18nText('app.enum.MENU_DAY.' + 5),
        },
        {
            value: 6,
            label: geti18nText('app.enum.MENU_DAY.' + 6),
        },
        {
            value: 7,
            label: geti18nText('app.enum.MENU_DAY.' + 7),
        },
    ];

    const menuType = [
        {
            value: 1,
            label: geti18nText('app.enum.MENU_TYPE.' + 1),
        },
        {
            value: 2,
            label: geti18nText('app.enum.MENU_TYPE.' + 2),
        },
    ];

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const activeFilter = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            /*  { field: 'id', condition: 'equals', value: form.getFieldValue('id') }, */
        ];
    };

    const generateName = () => {
        const restaurant = form.getFieldValue('restaurant');
        const validFrom = form.getFieldValue('validFrom');
        const validTo = form.getFieldValue('validTo');
        const day = form.getFieldValue('day');

        const dayName = dayOptions.find((e) => {
            return e.value == day;
        });

        let name = '';
        if (restaurant && restaurant.name) {
            name += restaurant.name;
        }

        if (isDateOption && isDateOption != undefined) {
            if (validFrom) {
                if (validTo) {
                    if (getDateFormat(validFrom, 'DD.MM.YYYY') != getDateFormat(validTo, 'DD.MM.YYYY')) {
                        name +=
                            ' (' +
                            getDateFormat(validFrom, 'DD.MM.YYYY') +
                            ' - ' +
                            getDateFormat(validTo, 'DD.MM.YYYY') +
                            ')';
                    } else if (getDateFormat(validFrom, 'DD.MM.YYYY') == getDateFormat(validTo, 'DD.MM.YYYY')) {
                        name += ' (' + getDateFormat(validFrom, 'DD.MM.YYYY') + ')';
                    }
                } else {
                    name += ' (' + getDateFormat(validFrom, 'DD.MM.YYYY') + ')';
                }
            }
        } else {
            if (dayName) {
                name += ' (' + dayName.label + ')';
            }
        }

        form.setFieldsValue({ name: name });
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('meal.menu.tab.header') + ' - ' + dataForm.name);
        }
        if (dataForm?.restaurant && dataForm?.restaurant?.name) {
            dataForm.restaurant = setSearchFormat(dataForm.restaurant, 'name', 'name');
        }
        if (dataForm.day) {
            dataForm.day = dayOptions.find((e) => {
                return e.value == dataForm.day;
            });
        }

        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
            setDateFrom(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
            setDateTo(dataForm.validTo);
        }

        if (dataForm.created) dataForm.created = setDateFormat(dataForm.created);
        if (dataForm.updated) dataForm.updated = setDateFormat(dataForm.updated);

        if (dataForm.updated) dataForm.updated = setDateFormat(dataForm.updated);

        if (dataForm.type) {
            if (dataForm.type == 2) {
                setIsDateOption(true);
            } else {
                setIsDateOption(false);
            }
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setActiveKey('1');
        setEditHeader(geti18nText('meal.menu.edit.new'));
        setDataForm(null);
        setIsDateOption(false);
        if (props && props.editProps && props.editProps.setValue) {
            props.editProps.setValue(null);
        }
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    let clearKeysDeleteGroup: any;

    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    let clearKeysAddGroup: any;

    const setSelectedValuesFuncAddGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddGroup(hasSelected);
        setSelectedValuesAddGroup(selectedRowKeys);
        clearKeysAddGroup = clearSelectedRowKeys;
    };

    const onEndDateChange = (value: any) => {
        setDateTo(value);
        if (value && value.isBefore(dateFrom)) {
            setDateTo(null);
            form.setFieldsValue({ validTo: null });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setDateFrom(moment(value) ? moment(value, 'DD.MM.YYYY') : null);
        if (dateTo && dateTo.isBefore(moment(value))) {
            setDateTo(null);
            form.setFieldsValue({ validTo: null });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    const saveToGroup = (e: any) => {
        e.preventDefault();
        setLoadingModalGroup(true);

        NyRequestResolver.requestPost(CONSTANTS_REQ.MEAL_MENU.ADD_MEALS, undefined, {
            menuId: dataForm,
            mealIds: selectedValuesAddGroup,
        }).then((result: any) => {
            if (setLoadingModalGroup) setLoadingModalGroup(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddGroup) {
                    clearKeysAddGroup();
                }

                okNotification();
                setIsAddListModalVisibleGroup(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromGroup = () => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.id;
        });

        NyRequestResolver.requestPost(CONSTANTS_REQ.MEAL_MENU.REMOVE_MEALS, undefined, {
            menuId: dataForm,
            mealIds: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('meal.edit.add.meal.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('meal.menu.remove.from.group.comfirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.no')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('meal.edit.remove.meal.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    const refreshView = () => {
        if (props && props.editProps && props.editProps.setRefresh) {
            props.editProps.setRefresh((refresh: any) => refresh + 1);
        }
        if (props && props.editProps && props.editProps.setValue) {
            props.editProps.setValue(null);
        }
        setisFormChange(false);
    };

    useEffect(() => {
        if (props && props.editProps && props.editProps.selectedDateMenu) {
            form.setFieldsValue({ validFrom: moment(props.editProps.selectedDateMenu) });
        }
    });

    const callRefresh = async (data: any) => {
        setDataForm(data.id);
        setEditHeader(geti18nText('meal.menu.tab.header') + ' - ' + data.name);
        setIsCreate(false);
        if (props?.editProps?.setRefreshTable) {
            props?.editProps?.setRefreshTable(props?.editProps?.refreshTable + 1);
        }

        return true;
    };

    const [nexActiveTab, setNextActiveTab] = useState<any>(undefined);
    const [fetching, setFetching] = useState<any>(false);

    const onTabChange = (activeKey: any) => {
        setNextActiveTab(activeKey);
        if (isFormChange) {
            setShowDirtyDataModal(true);
        } else {
            setFetching(true);
            setActiveKey(activeKey);
        }
    };

    const onContinue = () => {
        setShowDirtyDataModal(false);
        setValues(dataForm);
        if (nexActiveTab) {
            setActiveKey(nexActiveTab);
        }
        setisFormChange(false);
    };

    return (
        <NyDataEdit
            value={props && props.editProps && props.editProps.value}
            visible={props && props.editProps && props.editProps.visible}
            setVisible={props && props.editProps && props.editProps.setVisible}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            isModal={true}
            url={CONSTANTS_REQ.MEAL_MENU.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate() || activeKey === '2' ? true : false}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            setIsFormChange={setisFormChange}
            customFooterContent={getCustomFooterContent}
            onSaveAndGetID={refreshView}
            normalize={(values: any) => {
                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }
                if (values.validFrom) values.validFrom = moment(values.validFrom).format('yyyy-MM-DD HH:mm');
                if (values.validTo) values.validTo = moment(values.validTo).format('yyyy-MM-DD HH:mm');
                if (values.restaurant) {
                    values.restaurant = getSearchFormat(values.restaurant);
                    if (values.restaurant.id < 1) {
                        delete values.restaurant;
                    }
                }
                if (values.day) {
                    values.day.value != undefined ? (values.day = values.day.value) : (values.day = values.day);
                }

                if (isDateOption) {
                    delete values.day;
                    values.type = 2;
                } else {
                    values.type = 1;
                }
                return values;
            }}
            setIsCreate={setIsCreate}
            leaveNewModalOpen={true}
            hideActivationButtons={!canCreate() || teamCreated || activeKey != '1'}
            modalStyle={{ top: activeKey == '2' ? '5vh' : null }}
        >
            <Tabs type="card" className="tabs-sticky" activeKey={activeKey} onChange={onTabChange}>
                <Tabs.TabPane
                    key="1"
                    tab={
                        <span>
                            {geti18nText('meal.tab.general')}{' '}
                            {isFormChange && (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            )}
                        </span>
                    }
                >
                    <Row gutter={24} style={{ margin: '0px' }}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('meal.menu.edit.type')}
                                name="type"
                                rules={[
                                    {
                                        required: false,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Select
                                    options={menuType}
                                    autoFocus={true}
                                    ref={focusInput}
                                    style={{ width: '100%' }}
                                    defaultValue={2}
                                    onChange={(e) => {
                                        if (e == 2) {
                                            setIsDateOption(true);
                                            form.setFieldsValue({ validFrom: moment() });
                                            form.setFieldsValue({ day: null });
                                        } else {
                                            setIsDateOption(false);
                                            form.setFieldsValue({ validFrom: null });
                                        }
                                        generateName();
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('meal.menu.edit.restaurant')}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                name="restaurant"
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.RESTAURANT.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="nameOrCode"
                                    className=""
                                    setDefaultFilterValue={() => [
                                        { field: 'active', condition: 'equals_bool', value: 1 },
                                    ]}
                                    onChange={() => generateName()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ margin: '0px' }}>
                        <Col span={12}>
                            <Form.Item
                                hidden={isDateOption}
                                label={geti18nText('meal.menu.edit.day')}
                                rules={[
                                    {
                                        required: !isDateOption,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                name="day"
                            >
                                <Select
                                    disabled={isDateOption}
                                    options={dayOptions}
                                    autoFocus={true}
                                    style={{ width: '100%' }}
                                    allowClear
                                    onChange={() => generateName()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ margin: '0px' }}>
                        <Col span={12}>
                            <Form.Item
                                initialValue={moment(Date.now())}
                                label={geti18nText('payrollTaxReduction.edit.validFrom')}
                                rules={[
                                    {
                                        required: isDateOption,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                name="validFrom"
                            >
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => {
                                        onStartDateChange(value);
                                        generateName();
                                    }}
                                    disabledDate={disabledStartDate}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('payrollTaxReduction.edit.validTo')}
                                rules={[
                                    {
                                        required: false,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                name="validTo"
                                initialValue={null}
                            >
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => {
                                        onEndDateChange(value);
                                        generateName();
                                    }}
                                    disabledDate={disabledendDate}
                                    nyTestId="valid-to"
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ margin: '0px' }}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('meal.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input autoFocus={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('meal.menu.edit.meals')} key="2" disabled={isCreate}>
                    {!isCreate && (
                        <div style={{ maxHeight: '75vh' }}>
                            <NyDataTable
                                nyId="meal-menus-in-menu"
                                rowKey="id"
                                url={CONSTANTS_REQ.MEAL_MENU.IN_MENU}
                                addedData={{ menuId: dataForm }}
                                showRowSelection
                                readonly
                                hideButtons
                                scroll={props?.editProps?.scroll}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                                setDefaultFilterValue={activeFilter}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                fetchWhenChange={refreshTable}
                                // cancelRowClick={true} -- implementacija kasnije
                                mustGetPopupContainer={false}
                                columns={[
                                    ...[
                                        {
                                            title: geti18nText('meal.menu.group.table.id'),
                                            dataIndex: 'id',
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                    ],
                                    ...(mealSyncTypeHNB === true
                                        ? [
                                              {
                                                  title: geti18nText('meal.table.column.refId'),
                                                  dataIndex: 'refId',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearch('string'),
                                                  width: '120px',
                                              },
                                          ]
                                        : []),
                                    ...[
                                        {
                                            title: geti18nText('meal.menu.group.table.image'),
                                            dataIndex: 'fileId',
                                            width: '9%',
                                            render: (fileId: any, record: any) => {
                                                if (record && record.fileId) {
                                                    return (
                                                        <Popover
                                                            title={record?.name}
                                                            placement="right"
                                                            content={
                                                                <Image
                                                                    style={{
                                                                        objectFit: 'cover',
                                                                        height: '464px',
                                                                        width: '464px',
                                                                        borderRadius: '5px',
                                                                    }}
                                                                    preview={false}
                                                                    src={
                                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                        '/' +
                                                                        record.fileId +
                                                                        '?tenant=' +
                                                                        NySession.getUser().tenantId
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            <Image
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    height: '64px',
                                                                    width: '64px',
                                                                    borderRadius: '5px',
                                                                }}
                                                                preview={false}
                                                                src={
                                                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                    '/' +
                                                                    record.fileId +
                                                                    '?tenant=' +
                                                                    NySession.getUser().tenantId
                                                                }
                                                            />
                                                        </Popover>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('meal.menu.group.table.name'),
                                            dataIndex: 'name',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                            render: (name: any, record: any) => {
                                                if (record && record.name) {
                                                    return (
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ maxWidth: '300px' }}
                                                            title={record.name}
                                                        >
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        marginTop: '-0.4rem',
                                                                        marginBottom: '-1rem',
                                                                    }}
                                                                >
                                                                    {record.name}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('meal.menu.group.table.desc'),
                                            dataIndex: 'description',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                            width: '150px',
                                            render: (description: any, record: any) => {
                                                if (record && record.description) {
                                                    return (
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ maxWidth: '300px' }}
                                                            title={record.description}
                                                        >
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        marginTop: '-0.4rem',
                                                                        marginBottom: '-1rem',
                                                                    }}
                                                                >
                                                                    {record.description}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('meal.menu.group.table.allergen'),
                                            dataIndex: 'allergen',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                            render: (allergen: any, record: any) => {
                                                if (record && record.allergen) {
                                                    return (
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ maxWidth: '300px' }}
                                                            title={record.allergen}
                                                        >
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        marginTop: '-0.4rem',
                                                                        marginBottom: '-1rem',
                                                                    }}
                                                                >
                                                                    {record.allergen}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                }
                                            },
                                        },
                                    ],
                                ]}
                            />
                        </div>
                    )}
                    {isAddListModalVisibleGroup && (
                        <Modal
                            title={geti18nText('meal.menu.group.table.add.title')}
                            visible={isAddListModalVisibleGroup}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAddGroup }}
                            onOk={(e) => saveToGroup(e)}
                            confirmLoading={loadingModalGroup}
                            width={900}
                            onCancel={() => {
                                setIsAddListModalVisibleGroup(false);
                            }}
                        >
                            <NyDataTable
                                nyId="meal-menus-not-in-menu"
                                rowKey="id"
                                url={CONSTANTS_REQ.MEAL_MENU.NOT_IN_MENU}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                addedData={{ menuId: dataForm }}
                                addNewButtonText={geti18nText('meal.menu.group.table.add.meal')}
                                modalComponent={MealEdit}
                                showRecordModal={true}
                                hideNewButton={!canCreate()}
                                showRowSelection
                                scroll={props?.editProps?.scroll}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                editProps={{
                                    scroll: { y: 400, x: 500 },
                                    sortOrder: null,
                                }}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncAddGroup as any}
                                fetchWhenChange={refreshTable}
                                onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                //  cancelRowClick={true} -- implementacija kasnije
                                columns={[
                                    ...[
                                        {
                                            title: geti18nText('meal.menu.group.table.id'),
                                            dataIndex: 'id',
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                    ],
                                    ...(mealSyncTypeHNB === true
                                        ? [
                                              {
                                                  title: geti18nText('meal.table.column.refId'),
                                                  dataIndex: 'refId',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearch('string'),
                                              },
                                          ]
                                        : []),
                                    ...[
                                        {
                                            title: geti18nText('meal.menu.group.table.image'),
                                            dataIndex: 'fileId',
                                            width: '9%',
                                            render: (fileId: any, record: any) => {
                                                if (record && record.fileId) {
                                                    return (
                                                        <Image
                                                            style={{
                                                                objectFit: 'cover',
                                                                height: '64px',
                                                                width: '64px',
                                                                borderRadius: '5px',
                                                            }}
                                                            preview={true}
                                                            src={
                                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                '/' +
                                                                record.fileId +
                                                                '?tenant=' +
                                                                NySession.getUser().tenantId
                                                            }
                                                        />
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('meal.menu.group.table.name'),
                                            dataIndex: 'name',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText('meal.menu.group.table.desc'),
                                            dataIndex: 'description',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText('meal.menu.group.table.allergen'),
                                            dataIndex: 'allergen',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                    ],
                                ]}
                            />
                        </Modal>
                    )}
                </Tabs.TabPane>
            </Tabs>
            {showDirtyDataModal && (
                <NyIsDirtyModal
                    visible={showDirtyDataModal}
                    onContinue={onContinue}
                    onCancel={() => setShowDirtyDataModal(false)}
                />
            )}
        </NyDataEdit>
    );
};

export default MealMenuEdit;
