import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookSimple,
    getColumnSearchByHumanCodebookSimple,
} from '../../../../utils/Filters';
import { getColorFromNotificationType, getDateFormat, getEnumArray } from '../../../../utils/Utils';
import EmployeeEvaluationNeedsPlanEdit from './edit';
import useEnum from '../../../../hooks/useEnum';
import moment from 'moment';
import { Tooltip } from 'antd';

const EmployeeEvaluationNeedsPlanIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);

    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const table = useTableSettings();
    useHelper('human/codebooks/employeeEvaluationNeedsPlan');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const columns = [
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.employee'),
            dataIndex: 'employee.id',
            sorter: (a: any, b: any) => {},
            width: '16%',
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record.employee && record.employeePerson) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '200px' }}
                            title={
                                `${record.employeePerson.firstName} ${record.employeePerson.lastName}` +
                                (record.employee.employmentRecordId ? ` (${record.employee.employmentRecordId})` : '')
                            }
                        >
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>
                                    {record &&
                                        `${record.employeePerson.firstName} ${record.employeePerson.lastName}` +
                                            (record.employee.employmentRecordId
                                                ? ` (${record.employee.employmentRecordId})`
                                                : '')}
                                </p>
                            </div>
                        </Tooltip>
                    );

                    /*   return (
                        `${record.employeePerson.firstName} ${record.employeePerson.lastName}` +
                        (record.employee.employmentRecordId ? ` (${record.employee.employmentRecordId})` : '')
                    ); */
                }
            },
        },
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.type'),
            dataIndex: ['type', 'name'],
            render: (text: any, record: any) => {
                if (record?.type && record.type?.name) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={`${record.type.name}`}>
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>{record && `${record.type.name}`}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.EDUCATION_LEVEL, 'type.id'),
        },
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.field'),
            dataIndex: ['field'],
            render: (text: any, record: any) => {
                if (record?.field) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={`${record.field}`}>
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>{record && `${record.field}`}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.support'),
            dataIndex: ['support'],
            render: (text: any, record: any) => {
                if (record?.support) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={`${record.support}`}>
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>{record && `${record.support}`}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.dueDate'),
            dataIndex: 'dueDate',
            render: (dueDate: any, record: any) => {
                if (record.dueDate) {
                    return getDateFormat(record.dueDate, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('employee.evaluation.needs.plan.table.column.active'),
            dataIndex: 'active',
            width: '4%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-employee-evaluation-needs-plan"
            url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_NEEDS_PLAN.LIST}
            addNewButtonText={geti18nText('employee.evaluation.needs.plan.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={EmployeeEvaluationNeedsPlanEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={/* !canCreate() */ true}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.employeeEvaluationNeedsPlan')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.employeeEvaluationNeedsPlan')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            employee: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            dueDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.LOCATION_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeEvaluationNeedsPlanIndex;
