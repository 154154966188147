import { geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import ProfessionalSpecialtyIndex from '.';
import ProfessionalSpecialtyEdit from './edit';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const ProfessionalSpecialtySearch = ({
    label = geti18nText('employee.mobilization.edit.professionalSpecialty'),
    name = 'professionalSpecialty',
    canCreate = false,
    required = false,
    disabled = false,
    autoFocus = false,
    onChange,
    setDefaultFilterValue = [],
    mustGetPopupContainer = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: required,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.PROFESSIONAL_SPECIALTY.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                nyTestId="professionalSpecialty"
                SearchPopupComponent={<ProfessionalSpecialtyIndex />}
                AddNewModalComponent={canCreate ? ProfessionalSpecialtyEdit : null}
                autoFocus={autoFocus}
                disabled={disabled}
                setDefaultFilterValue={setDefaultFilterValue}
                style={{ width: '100%' }}
                onChange={onChange}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default ProfessionalSpecialtySearch;
