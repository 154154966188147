import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getDateFormat, getEnumArray, numberFormat } from '../../../../utils/Utils';
import useTableSettings from '../../../../hooks/useTableSettings';

const WarehouseItemInputOutputIndex = ({ warehouseItemId, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        return [{ field: 'warehouse_item_id', condition: 'equals', value: warehouseItemId }];
    };

    const initialColumns = [
        {
            title: geti18nText('warehouseItemInputOutput.table.column.id'),
            dataIndex: 'id',
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.date'),
            dataIndex: 'date',
            ...getColumnDateOption(false),
            render: (date: any) => {
                if (date) {
                    return getDateFormat(date, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.ord'),
            dataIndex: 'ord',
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.documentType'),
            dataIndex: 'documentType',
            render: (text: any, record: any) => {
                if (record.documentType !== undefined) {
                    return geti18nText('app.enum.WAREHOUSE_DOCUMENT_TYPE.' + record.documentType);
                }
            },
            ...getColumnSearchOption(getEnumArray('WAREHOUSE_DOCUMENT_TYPE')),
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.refDocumentId'),
            dataIndex: 'refDocumentItemId',
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.inputQuantity'),
            dataIndex: 'inputQuantity',
            width: '10%',
            render: (inputQuantity: any) => {
                if (inputQuantity) {
                    return numberFormat(inputQuantity, 4);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.outputQuantity'),
            dataIndex: 'outputQuantity',
            width: '10%',
            render: (outputQuantity: any) => {
                if (outputQuantity) {
                    return numberFormat(outputQuantity, 4);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItemInputOutput.table.column.cumulativeQuantity'),
            dataIndex: 'cumulativeQuantity',
            width: '10%',
            render: (cumulativeQuantity: any) => {
                if (cumulativeQuantity !== undefined || cumulativeQuantity !== null) {
                    return numberFormat(cumulativeQuantity, 4);
                }
            },
            ...getColumnSearch('number'),
        },
    ];

    return (
        <NyDataTable
            nyId="warehouse-input-output-table"
            url={CONSTANTS_REQ.WAREHOUSE_ITEM.INPUT_OUTPUT_LIST}
            buttonsClassName={''}
            showRecordModal={false}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={initialColumns}
            hideNewButton={true}
            shortcuts={false}
            setDefaultPageSize={table.setDefaultPageSize()}
            readonly={true}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
        />
    );
};

export default WarehouseItemInputOutputIndex;
