import {
    BookOutlined,
    CarOutlined,
    CheckCircleOutlined,
    CoffeeOutlined,
    FileProtectOutlined,
    IssuesCloseOutlined,
    ShoppingCartOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { NySession, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Col, Divider, List, Row, Tooltip } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MyPortalBox from '../../components/my-portal-box/MyPortalBox';
import useEnum from '../../hooks/useEnum';
import { AssetRights } from '../../rights/assetRights';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { InventoryRights } from '../../rights/inventoryRights';
import { MealsRights } from '../../rights/mealsRights';
import { ServicesRights } from '../../rights/servicesRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DirectoryWidget from '../dashboard/DirectoryWidget';
import LinksWidget from '../dashboard/LinksWidget';
import NotificationWidget from '../dashboard/NotificationWidget';
import PersonalNotesWidget from '../dashboard/PersonalNotesWidget';
import ContractsCTRIndex from '../human/views/contract/contractsCTR';
import EmployeeEvaluationIndex from '../human/views/employee-evaluation/employee-evaluation-overview';
import EmployeeLeaveOverView from '../human/views/employee-leave-overview';
import TravelWarrantOverview from '../travel-warrant/views/travel-warrant-overview';
import MealsOverviewIndex from '../webshop/views/meals/meals-overview';
import WebshopAssetIndex from '../webshop/views/webshop-asset';
import WebshopItemIndex from '../webshop/views/webshop-item';
import WebshopServicesIndex from '../webshop/views/webshop-services';
import MyPortalHumanResourcesOverview from './MyPortalHumanResourcesOverview';

const MyPortalIndex = () => {
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const [selectedItem, setSelectedItem] = useState(0);
    const { collapsed } = useSelector((state: RootState) => state.menu);

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(
        CONSTANTS_REQ.DASHBOARD.GET_STATISTICS,
        undefined,
        { keys: ['myTravelWarrantRequested', 'myTravelWarrantsApproved', 'myTravelWarrantsConfirmed'] },
        true,
        false,
        {
            myTravelWarrantRequested: 0,
            myTravelWarrantsApproved: -1,
            myTravelWarrantsConfirmed: -1,
        }
    );

    const items: any = [<DirectoryWidget />, <LinksWidget />, <PersonalNotesWidget />, <NotificationWidget />];
    const defaultWidgets = () => {
        return (
            <Row gutter={24}>
                <Col xs={10} sm={14} md={16} lg={18} xl={21}>
                    <List
                        grid={{
                            gutter: 12,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 4,
                        }}
                        dataSource={items}
                        renderItem={(item: any) => <List.Item>{item}</List.Item>}
                    ></List>
                </Col>
            </Row>
        );
    };

    const renderComponent = () => {
        switch (selectedItem) {
            case notificationType.ATTENDANCE_LOGOUT_NOTIFICATION:
                return <MyPortalHumanResourcesOverview />;
            case notificationType.TRAVEL_WARRANT:
                return <TravelWarrantOverview hideRadioButtons />;
            case notificationType.ASSET_ORDER:
                return <WebshopAssetIndex myPortal />;
            case notificationType.INVENTORY_ORDER:
                return <WebshopItemIndex myPortal />;
            case notificationType.SERVICES_ORDER:
                return <WebshopServicesIndex myPortal />;
            case notificationType.MEALS_ORDER:
                return <MealsOverviewIndex />;
            case notificationType.EMPLOYEE_ANNUAL_LEAVE:
                return <EmployeeLeaveOverView hideRadioButtons />;
            case notificationType.EMPLOYEE_CONTRACT_EXPIRATION:
                return <ContractsCTRIndex />;
            case notificationType.EMPLOYEE_EVALUATION_STATUS_CHANGE:
                return <EmployeeEvaluationIndex myPortal hideRadioButtons />;
            default:
                return null;
        }
    };

    const canViewHumanResources = () => {
        return NySession.hasAnyRole(HumanResourcesRights.canViewHumanCodebooksMenu());
    };
    const canViewTravelWarrant = () => {
        return NySession.hasAnyRole(TravelWarrantRights.canViewTravelWarrantModuleMenu());
    };
    const canViewAssetOrder = () => {
        return NySession.hasAnyRole(AssetRights.canViewRequestMenu());
    };
    const canViewInventoryOrder = () => {
        return NySession.hasAnyRole(InventoryRights.canViewRequestMenu());
    };
    const canViewServicesOrder = () => {
        return NySession.hasAnyRole(ServicesRights.canViewRequestMenu());
    };
    const canViewMealsOrder = () => {
        return NySession.hasAnyRole(MealsRights.isOnlyMealsUserMenu());
    };
    const canViewEmployeeAnnualLeave = () => {
        return NySession.hasAnyRole(HumanResourcesRights.canViewMenuEmployeeLeave());
    };
    const canViewContracts = () => {
        return NySession.hasAnyRole(HumanResourcesRights.canViewContractsMenuAll());
    };
    const canViewEmployeeEvaluation = () => {
        return NySession.hasAnyRole(HumanResourcesRights.canViewEvaluation());
    };

    const canViewSideBar = () => {
        return (
            canViewHumanResources() ||
            canViewTravelWarrant() ||
            canViewAssetOrder() ||
            canViewInventoryOrder() ||
            canViewServicesOrder() ||
            canViewMealsOrder() ||
            canViewEmployeeAnnualLeave() ||
            canViewContracts() ||
            canViewEmployeeEvaluation()
        );
    };

    return (
        <div style={{ display: 'inline-flex' }}>
            {canViewSideBar() && (
                <div className="ag-format-container" style={{ width: '194px', height: '92.9vh' }}>
                    <div className="ag-courses_box" style={{ scrollbarGutter: 'stable' }}>
                        {canViewHumanResources() && (
                            <MyPortalBox
                                icon={<UserOutlined />}
                                notificationType={notificationType.ATTENDANCE_LOGOUT_NOTIFICATION}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.ATTENDANCE_LOGOUT_NOTIFICATION
                                            ? 0
                                            : notificationType.ATTENDANCE_LOGOUT_NOTIFICATION
                                    )
                                }
                                selected={selectedItem === notificationType.ATTENDANCE_LOGOUT_NOTIFICATION}
                            />
                        )}
                        {canViewTravelWarrant() && (
                            <MyPortalBox
                                icon={<CarOutlined />}
                                notificationType={notificationType.TRAVEL_WARRANT}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.TRAVEL_WARRANT
                                            ? 0
                                            : notificationType.TRAVEL_WARRANT
                                    )
                                }
                                selected={selectedItem === notificationType.TRAVEL_WARRANT}
                                additionalText={
                                    <div style={{ paddingTop: '4px', display: 'flex', justifyContent: 'center' }}>
                                        <Tooltip title={geti18nText('travelWarrantPersonal.requests')}>
                                            <CarOutlined
                                                style={{ fontSize: '1.5em' }}
                                                className="statistic-card-light"
                                            />
                                            <span
                                                style={{
                                                    fontSize: '16px',
                                                    paddingRight: '10px',
                                                    paddingLeft: '2px',
                                                }}
                                            >
                                                {statisticData && statisticData['myTravelWarrantRequested']}
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={geti18nText('travelWarrantPersonal.approved')}>
                                            <CheckCircleOutlined
                                                style={{ fontSize: '1.5em' }}
                                                className={`statistic-card-light statistic-card-warning-light`}
                                            />
                                            <span
                                                style={{
                                                    fontSize: '16px',
                                                    paddingRight: '10px',
                                                    paddingLeft: '2px',
                                                }}
                                            >
                                                {statisticData && statisticData['myTravelWarrantsApproved']}
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={geti18nText('travelWarrantPersonal.confirmed')}>
                                            <IssuesCloseOutlined
                                                style={{ fontSize: '1.5em' }}
                                                className={`statistic-card-light statistic-card-warning-light`}
                                            />
                                            <span style={{ fontSize: '16px', paddingLeft: '2px' }}>
                                                {statisticData && statisticData['myTravelWarrantsConfirmed']}
                                            </span>
                                        </Tooltip>
                                    </div>
                                }
                            />
                        )}
                        {canViewAssetOrder() && (
                            <MyPortalBox
                                icon={<ShoppingCartOutlined />}
                                notificationType={notificationType.ASSET_ORDER}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.ASSET_ORDER ? 0 : notificationType.ASSET_ORDER
                                    )
                                }
                                selected={selectedItem === notificationType.ASSET_ORDER}
                            />
                        )}
                        {canViewInventoryOrder() && (
                            <MyPortalBox
                                icon={<ShoppingCartOutlined />}
                                notificationType={notificationType.INVENTORY_ORDER}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.INVENTORY_ORDER
                                            ? 0
                                            : notificationType.INVENTORY_ORDER
                                    )
                                }
                                selected={selectedItem === notificationType.INVENTORY_ORDER}
                            />
                        )}
                        {canViewServicesOrder() && (
                            <MyPortalBox
                                icon={<ShoppingCartOutlined />}
                                notificationType={notificationType.SERVICES_ORDER}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.SERVICES_ORDER
                                            ? 0
                                            : notificationType.SERVICES_ORDER
                                    )
                                }
                                selected={selectedItem === notificationType.SERVICES_ORDER}
                            />
                        )}
                        {canViewMealsOrder() && (
                            <MyPortalBox
                                icon={<i className="las la-utensils" />}
                                notificationType={notificationType.MEALS_ORDER}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.MEALS_ORDER ? 0 : notificationType.MEALS_ORDER
                                    )
                                }
                                selected={selectedItem === notificationType.MEALS_ORDER}
                            />
                        )}
                        {canViewEmployeeAnnualLeave() && (
                            <MyPortalBox
                                icon={<CoffeeOutlined />}
                                notificationType={notificationType.EMPLOYEE_ANNUAL_LEAVE}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.EMPLOYEE_ANNUAL_LEAVE
                                            ? 0
                                            : notificationType.EMPLOYEE_ANNUAL_LEAVE
                                    )
                                }
                                selected={selectedItem === notificationType.EMPLOYEE_ANNUAL_LEAVE}
                            />
                        )}
                        {canViewContracts() && (
                            <MyPortalBox
                                icon={<FileProtectOutlined />}
                                notificationType={notificationType.EMPLOYEE_CONTRACT_EXPIRATION}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.EMPLOYEE_CONTRACT_EXPIRATION
                                            ? 0
                                            : notificationType.EMPLOYEE_CONTRACT_EXPIRATION
                                    )
                                }
                                selected={selectedItem === notificationType.EMPLOYEE_CONTRACT_EXPIRATION}
                            />
                        )}
                        {canViewEmployeeEvaluation() && (
                            <MyPortalBox
                                icon={<BookOutlined />}
                                notificationType={notificationType.EMPLOYEE_EVALUATION_STATUS_CHANGE}
                                toggleVisibility={() =>
                                    setSelectedItem(
                                        selectedItem === notificationType.EMPLOYEE_EVALUATION_STATUS_CHANGE
                                            ? 0
                                            : notificationType.EMPLOYEE_EVALUATION_STATUS_CHANGE
                                    )
                                }
                                selected={selectedItem === notificationType.EMPLOYEE_EVALUATION_STATUS_CHANGE}
                            />
                        )}
                    </div>
                </div>
            )}
            <Divider type="vertical" style={{ height: '92.9vh', backgroundColor: '#d0d0d0', marginLeft: '0px' }} />
            <div className={collapsed ? 'portal-content-collapsed' : 'portal-content'} style={{ margin: '20px' }}>
                <div style={{ display: !selectedItem ? 'block' : 'none' }}>{!selectedItem && defaultWidgets()}</div>
                <div>{renderComponent()}</div>
            </div>
        </div>
    );
};

export default MyPortalIndex;
