import { NyDataEdit, NyRequestResolver, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import { validateIBAN } from '../../../../utils/Validation';
import BankSearch from '../bank/search';

const BankAccountEdit = (props: any) => {
    const { item } = useSelector((state: RootState) => state.selectedItem);

    const [editHeader, setEditHeader] = useState(geti18nText('bankAccount.edit.new'));
    const [loading, setLoading] = useState(false);
    const [requiredSwift, setrequiredSwift] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (item) {
            getCompanyData(item);
        }
    }, [item]);

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    const getCompanyData = (companyId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EDIT + '/' + companyId, {}).then((result: any) => {
            if (result.data && result.data.marketType) {
                setrequiredSwift(result.data.marketType === 1 ? false : true);
            }
        });
    };

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);

        if (dataForm.bankAccount) {
            dataForm.bankAccount.bank = setSearchFormat(dataForm.bankAccount.bank);
        }

        delete dataForm.active;
        dataForm.company = { id: item };
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('bankAccount.edit.new'));
    };

    const onBankChange = (value: any) => {};

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.bankAccount.bank) values.bankAccount.bank = getSearchFormat(values.bankAccount.bank);
                values.company = { id: id };
                values.main = values.main ?? false;
                return values;
            }}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            nyTestId="company-bank-account-edit"
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['bankAccount', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <BankSearch
                        label={geti18nText('bankAccount.edit.bank')}
                        name={['bankAccount', 'bank']}
                        required={false}
                        onChange={onBankChange}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('bankAccount.edit.iban')}
                        name={['bankAccount', 'iban']}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                            {
                                validator: validateIBAN,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={16}>
                    <Form.Item
                        label={geti18nText('bankAccount.edit.swift')}
                        name="swift"
                        rules={[
                            {
                                required: requiredSwift,
                                message: geti18nText('app.default.required'),
                                whitespace: requiredSwift,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('bankAccount.edit.main')} name="main" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default BankAccountEdit;
