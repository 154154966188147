import { EyeInvisibleOutlined, EyeOutlined, PrinterOutlined, SelectOutlined } from '@ant-design/icons';
import {
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    NySpinner,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Empty, Form, Modal, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ, URL_PREFIX } from '../../utils/Constants';
import {
    GetEmployeeAnnualLeaveStatusIcon,
    JsonData,
    customEmployeeRenderOption,
    getContractStatusIcon,
    getDateFormat,
    getTaskStatusIcon,
    getTravelWarrantStatusIcon,
    setEnumFormat,
} from '../../utils/Utils';
import ContractMetadataHistory from '../../views/human/views/contract/contract-metadata/ContractMetadataHistory';
import EmployeeIndex from '../../views/human/views/employee';
import TravelWarrantMap from '../travel-warrant-map/TravelWarrantMap';
import TravelWarrantSummaryIndex from '../travel-warrant-summary';
import NyHistoryCustomTable from './NyHistoryCustomTable';

const { Text } = Typography;
const { Panel } = Collapse;

const NyHistory = ({ id, link, type, refresh, isPeriod = false }: any) => {
    const [loading, setLoading] = useState(false);
    const [historyData, setHistoryData] = useState<any>(null);
    //Text area
    const [modalTextAreaVisible, setModalTextAreaVisible] = useState(false);
    const [valueTextArea, setValueTextArea] = useState<any>(null);
    const [titleTextArea, setTitleTextArea] = useState<any>('');
    //Metadata values
    const [metadataVisible, setMetadataVisible] = useState(false);
    const [metadataValues, setMetadataValues] = useState<any>(null);
    const [titleMetadata, setTitleMetadata] = useState<any>('');

    const [outerCollapseActiveKeys, setOuterCollapseActiveKeys] = useState<any>(['1']);
    const [innerCollapseActiveKeys, setInnerCollapseActiveKeys] = useState<any>([]);
    const [innerCollapseAllKeys, setInnerCollapseAllKeys] = useState<any>([]);
    const [collapseAll, setCollapseAll] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState(false);

    //Destination Json
    const [modalRelationVisible, setModalRelationVisible] = useState(false);
    const [titleRelation, setTitleRelation] = useState<any>('');
    const [form] = Form.useForm();
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [isPlane, setIsPlane] = useState(false);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const [returnRouteWaypoints, setReturnRouteWaypoints] = useState<any>([]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [returnFields, setReturnFields] = useState(false);
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const [validFrom, setValidFrom] = useState<any>(null);
    const [validTo, setValidTo] = useState<any>(null);
    const [user, setUser] = useState<any>(null);

    const [visibleLog, setVisibleLog] = useState(false);
    const [log, setLog] = useState<any>(undefined);

    const [visibleTravelWarrant, setVisibleTravelWarrant] = useState(false);
    const [travelWarrant, setTravelWarrant] = useState<any>(undefined);
    const enVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const [files, setFiles] = useState<any>([]);
    const [additionalData, setAdditionalData] = useState<any>([]);

    useEffect(() => {
        if (id) {
            getHistory(validFrom, validTo, user);
        }
    }, [id, refresh, validFrom, validTo, isPeriod, user]);

    useEffect(() => {
        setReturnFields(returnRoute);
    }, [returnRoute]);

    useEffect(() => {
        setInnerCollapseAllKeys([]);
        setInnerCollapseActiveKeys([]);
        setOuterCollapseActiveKeys([]);
        getHistory(validFrom, validTo, user);
    }, [type]);

    useEffect(() => {
        if (historyData != undefined && historyData.length > 0) {
            Object.entries(historyData[0].changes).map((index: any, value: any) => {
                innerCollapseAllKeys.push(value);
            });
        }
    }, [historyData]);

    const showDefaultHeader = () => {
        return Object.values(['asset', 'fixed.asset', 'mobilization', 'contract']).includes(link);
    };

    const getPropertyMobilization = (changedEl: any) => {
        if (changedEl.property == 'mobilizationFunction') {
            if (changedEl.new != null) {
                const en: any = setEnumFormat('MOBILIZATION_FUNCTION', changedEl.new);
                changedEl.new = en?.text;
            }
            if (changedEl.old != null) {
                const en: any = setEnumFormat('MOBILIZATION_FUNCTION', changedEl.old);
                changedEl.old = en?.text;
            }

            changedEl.propertyType = 'string';
        }
        if (changedEl.property == 'mobilizationTime') {
            if (changedEl.new != null) {
                changedEl.new = geti18nText('app.enum.MOBILIZATION_TIME.' + changedEl.new);
            }
            if (changedEl.old != null) {
                changedEl.old = geti18nText('app.enum.MOBILIZATION_TIME.' + changedEl.old);
            }
            changedEl.propertyType = 'string';
        }
        return changedEl;
    };

    const hideProperties = (dataList: any, propertyToHide: any, type?: any) => {
        dataList?.forEach((element: any) => {
            const changes = Object.entries(element.changes);
            if (changes != null) {
                changes.forEach((ch: any) => {
                    const chElement = ch[1];
                    if (chElement != null) {
                        let newListChanges: any = [];

                        chElement.listChanges?.forEach((changedEl: any) => {
                            if (type == 'mobilization') {
                                changedEl = getPropertyMobilization(changedEl);
                            }
                            if (!propertyToHide.includes(changedEl.property)) {
                                newListChanges.push(changedEl);
                            }
                        });
                        chElement.listChanges = newListChanges;
                    }
                });
            }
        });
        return dataList;
    };

    const setHistoryDataFunction = (data: any = null) => {
        setHistoryData(data ?? []);
        setLoading(false);
    };

    const resolveHistoryData = (resultData: any) => {
        if (link == 'travelWarrant' && resultData.travelWarrant) {
            const propertyToHide = ['advancePaymentExchangeRate', 'travelWarrantWageCalculationJson'];
            const dataList = hideProperties(resultData.travelWarrant, propertyToHide);
            setHistoryDataFunction(dataList);
        } else if (link == 'employeeAnnualLeave' && resultData.length > 0 && resultData[0] != undefined) {
            resultData[0].objectType = 'travelWarrantPersonal.tab.history';
            setHistoryDataFunction(resultData);
        } else if (link == 'contract' && resultData.length > 0 && resultData[0] != undefined) {
            const propertyToHide = ['employeeId', 'contractDocumentTemplateId', 'contractTypeId'];
            const dataList = hideProperties(resultData, propertyToHide);
            setHistoryDataFunction(dataList);
        }
    };

    const showTableView = (row: any) => {
        return (
            row?.property &&
            Object.values([
                'travelWarrantTotalsList',
                'travelWarrantWageList',
                'travelWarrantWageExpensesList',
                'travelWarrantWageRecapitulationList',
                'travelWarrantAdditionalInfoList',
                'travelWarrantExpenseList',
                'travelWarrantPaymentList',
                'travelWarrantPlanList',
                'travelWarrantRelationList',
                'travelWarrantNoteList',
                'travelWarrantFileList',
                'travelWarrantCo2List',
            ]).includes(row.property)
        );
    };

    const propertiesPeriod = [
        { value: 1, label: 'id' },
        { value: 2, label: 'travelWarrant' },
        { value: 3, label: 'travelWarrantAdditionalInfoList' },
        { value: 4, label: 'travelWarrantCo2List' },
        { value: 5, label: 'travelWarrantNoteList' },
        { value: 6, label: 'travelWarrantPlanList' },
        { value: 7, label: 'travelWarrantWageList' },
        { value: 8, label: 'travelWarrantWageExpensesList' },
        { value: 9, label: 'travelWarrantRelationList' },
        { value: 10, label: 'travelWarrantExpenseList' },
        { value: 11, label: 'travelWarrantPaymentList' },
        { value: 12, label: 'travelWarrantFileList' },
        { value: 13, label: 'travelWarrantWageRecapitulationList' },
        { value: 14, label: 'travelWarrantTotalsList' },
    ];

    function compare(a: any, b: any) {
        const numberValueFromPropertyA: any = propertiesPeriod.find((prop: any) => a?.property === prop?.label) ?? 15;
        const numberValueFromPropertyB: any = propertiesPeriod.find((prop: any) => b?.property === prop?.label) ?? 15;
        if (numberValueFromPropertyA?.value < numberValueFromPropertyB?.value) {
            return -1;
        }
        if (numberValueFromPropertyA?.value > numberValueFromPropertyB?.value) {
            return 1;
        }
        return 0;
    }

    const getHistory = (validFrom: any, validTo: any, user: any) => {
        setLoading(true);
        setHistoryDataFunction([]);
        switch (link) {
            case 'travelWarrant':
                const url = isPeriod
                    ? CONSTANTS_REQ.TRAVEL_WARRANT.GET_SNAPSHOT + '/' + id
                    : URL_PREFIX + 'api/' + link + '/history/' + id;
                const paramsTravel = isPeriod
                    ? {
                          id: id,
                          from: validFrom ? getDateFormat(validFrom, 'yyyy-MM-DD HH:mm') : '',
                          to: validTo ? getDateFormat(validTo, 'yyyy-MM-DD HH:mm') : '',
                          author: user ?? '',
                      }
                    : {
                          id: id,
                      };
                NyRequestResolver.requestGet(url, paramsTravel).then((result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (!isPeriod) {
                            resolveHistoryData(result?.data?.travelWarrant?.length > 0 ? result.data : []);
                        } else {
                            setHistoryDataFunction(
                                result?.data?.travelWarrant?.length > 0 ? result.data.travelWarrant : []
                            );
                        }
                    }
                });
                break;
            case 'employeeAnnualLeave':
                NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.CHANGELOG + '/' + id).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            resolveHistoryData(result?.data ?? []);
                        }
                    }
                );
                break;
            case 'asset' || 'fixed.asset':
                let params = undefined;
                if (type == 2) {
                    params = { module: 'asset' };
                } else if (type == 1) {
                    params = { onlyAssignedAssetChanges: true };
                } else if (type == 4) {
                    params = { module: 'fixed-asset' };
                }
                NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET.EDIT_HISTORY + '/' + id, params).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            setHistoryDataFunction(result?.data?.asset ?? []);
                        }
                    }
                );
                break;
            case 'task.edit':
                NyRequestResolver.requestGet(CONSTANTS_REQ.TASK.HISTORY + '/' + id).then((result: any) => {
                    if (result.status === RESPONSE.OK) {
                        setHistoryDataFunction(result?.data?.task ?? []);
                    }
                });
                break;
            case 'tasks.list.column':
                NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TEMPLATE.HISTORY + '/' + id).then((result: any) => {
                    if (result.status === RESPONSE.OK) {
                        setHistoryDataFunction(result?.data?.taskTemplate ?? []);
                    }
                });
                break;
            case 'mobilization':
                NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.HISTORY + '/' + id).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            const propertyToHide = [
                                'mobilizationLocationId',
                                'securityCertificateLevelId',
                                'employeeId',
                                'defenseDepartmentId',
                                'defenseSectionId',
                                'professionalSpecialtyId',
                                'reserveRankId',
                                'businessUnitId',
                                'vocationId',
                                'militaryWorkApprovalId',
                                'contactId',
                                'executorId',
                            ];
                            const dataList = hideProperties(result.data.list, propertyToHide, 'mobilization');
                            setHistoryDataFunction(dataList);
                        }
                    }
                );
                break;
            case 'contract':
                NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_CTR.HISTORY + '/' + id).then((result: any) => {
                    if (result.status === RESPONSE.OK) {
                        resolveHistoryData(result?.data?.contract ?? []);
                    }
                });
                break;
            default:
                setHistoryDataFunction([]);
                break;
        }
        setLoading(false);
    };

    const openModalTextArea = (property: any, value: any) => {
        setTitleTextArea(property ? property : '');
        setValueTextArea(value);
        setModalTextAreaVisible(true);
    };

    const openModalRelation = (property: any, value: any) => {
        setTitleRelation(property ? property : '');
        setDestinationValues(JSON.parse(value));
        setModalRelationVisible(true);
    };

    const closeModalRelation = () => {
        setModalRelationVisible(false);
        setTitleRelation('');
        setWaypoints(JSON.parse(JSON.stringify(defaultWaypoints)));
        setMaxWaypointsKey(2);
        setIsPlane(false);
    };

    const openModalMetadata = (property: any, value: any) => {
        setTitleMetadata(
            property && geti18nText(link + '.edit.' + property)
                ? geti18nText(link + '.edit.' + property)
                : geti18nText('menu.metadata')
        );
        setMetadataValues(value);
        setMetadataVisible(true);
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setOuterCollapseActiveKeys([]);
            setInnerCollapseActiveKeys([]);
        } else {
            setOuterCollapseActiveKeys(historyData.at(0).objectType); //**('1')**
            setInnerCollapseActiveKeys(innerCollapseAllKeys);
        }

        setCollapseAll(!collapseAll);
    };

    const setDestinationValues = (destinationJson: any) => {
        let waypointsList: any = [];
        destinationJson.forEach((element: any) => {
            const waypoint = JSON.parse(element?.relationJson);
            waypointsList = waypointsList.concat(waypoint);
            setIsPlane(
                element.travelVehicle &&
                    element.travelVehicle.vehicleType &&
                    (element.travelVehicle.vehicleType === 3 ||
                        element.travelVehicle.vehicleType === 4 ||
                        element.travelVehicle.vehicleType === 6)
            );
        });

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        let initList = [];
        setReturnRoute(false);
        if (lastItem?.id == 9999) {
            const firstItem = waypointsList[0];
            setReturnRoute(true);
            const returnList = waypointsList.filter((x: any) => x.id >= 9000 && x.id != 9999);
            initList = JSON.parse(JSON.stringify(returnList));
            returnIntermediateWaypointsInit.current = initList;
            let list = [firstItem].concat(returnList).concat(lastItem);

            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
            form.setFieldsValue({ returnRoute: true });

            setReturnWaypoints(JSON.parse(JSON.stringify(list)));
            setReturnRouteWaypoints(JSON.parse(JSON.stringify(list)));
        }
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);
    };

    const resolveProperty = (property: any) => {
        let value;
        switch (link) {
            case 'asset' || 'fixed.asset':
                value = geti18nText(link + '.edit.' + property)
                    ? geti18nText(link + '.edit.' + property)
                    : geti18nText('fixed.' + link + '.edit.' + property);
                return value;
            case 'tasks.list.column':
                value = geti18nText(link + '.' + property)
                    ? geti18nText(link + '.' + property)
                    : console.log('Missing text value ', link + '.' + property);
                return value;
            default:
                value = geti18nText(link + '.edit.' + property)
                    ? geti18nText(link + '.edit.' + property)
                    : console.log('Missing text value ', link + '.edit.' + property);
                return value;
        }
    };

    const resolveDate = (value: any) => {
        let date = value
            ? moment().set({
                  year: value[0],
                  months: value[1] - 1,
                  date: value[2],
                  hour: value[3],
                  minute: value[4],
                  second: value[5],
              })
            : null;

        return (
            <>
                {date
                    ? moment(date).hours() > 0 && type == undefined
                        ? getDateFormat(moment(date), 'DD.MM.YYYY HH:mm')
                        : getDateFormat(moment(date), 'DD.MM.YYYY')
                    : ''}
            </>
        );
    };

    const resolveStatusField = (value: any) => {
        if (link == 'travelWarrant') {
            return getTravelWarrantStatusIcon(value);
        }
        if (link == 'asset' || link == 'fixed.asset') {
            return geti18nText('app.enum.ASSET_STATUS.' + value);
        }
        if (link == 'task.edit' || link == 'tasks.list.column') {
            return getTaskStatusIcon(value);
        }
        if (link == 'employeeAnnualLeave') {
            return GetEmployeeAnnualLeaveStatusIcon(value);
        }
        if (link == 'contract') {
            return getContractStatusIcon(value);
        }
    };

    const resolveTypeField = (value: any) => {
        if (link == 'task.edit' || link == 'tasks.list.column') {
            return geti18nText('app.enum.TASK_TYPE.' + value);
        }
    };

    const openLog = (log: any) => {
        setLog(log);
        setVisibleLog(true);
    };

    const resolveJsonObjectContent = (item: any) => {
        return (
            <Tooltip placement="top" title={geti18nText('history.details')}>
                <Button
                    type="link"
                    icon={<SelectOutlined style={{ marginRight: '2px' }} />}
                    onClick={(value: any) => {
                        openLog(item);
                    }}
                    style={{ maxHeight: '10px', marginTop: '-5px', marginLeft: '-5px' }}
                >
                    {
                        <div style={{ display: 'inline-flex', maxWidth: '200px' }}>
                            <span
                                className={'overview-cards-paragraph'}
                                style={{ textDecorationLine: 'underline', color: '#177ddc' }}
                            >
                                {item?.id ? item.id + ' ' : ''} {geti18nText('history.details')}
                            </span>
                        </div>
                    }
                </Button>
            </Tooltip>
        );
    };

    const openTravelWarrant = (value: any, listChanges: any) => {
        const filesTravelWarrant =
            listChanges?.length > 0
                ? listChanges.find((change: any) => change?.property === 'travelWarrantFileList')
                : [];
        setFiles(filesTravelWarrant?.new?.length > 0 ? filesTravelWarrant.new : []);

        const additionalDataTravelWarrant =
            listChanges?.length > 0
                ? listChanges.find((change: any) => change?.property === 'travelWarrantAdditionalInfoList')
                : [];
        setAdditionalData(additionalDataTravelWarrant?.new?.length > 0 ? additionalDataTravelWarrant.new : []);

        setTravelWarrant(value);
        setVisibleTravelWarrant(true);
    };

    const resolveTravelWarrantObjectContent = (item: any, listChanges: any) => {
        return (
            <Tooltip placement="top" title={geti18nText('history.details')}>
                <Button
                    type="link"
                    icon={<SelectOutlined style={{ marginRight: '2px' }} />}
                    onClick={(value: any) => {
                        openTravelWarrant(item, listChanges);
                    }}
                    style={{ maxHeight: '10px', marginTop: '-5px', marginLeft: '-5px' }}
                >
                    {
                        <div style={{ display: 'inline-flex', maxWidth: '200px' }}>
                            <span
                                className={'overview-cards-paragraph'}
                                style={{ textDecorationLine: 'underline', color: '#177ddc' }}
                            >
                                {item?.id ? item.id + ' ' : ''} {geti18nText('history.details')}
                            </span>
                        </div>
                    }
                </Button>
            </Tooltip>
        );
    };

    const resolveValue = (row: any, value: any, listChanges: any = null) => {
        if (row.property == 'contractMetadataValues') {
            return (
                <Tooltip placement="top" title={geti18nText('history.details')}>
                    <Button
                        type="link"
                        icon={<SelectOutlined style={{ marginRight: '2px' }} />}
                        onClick={() => openModalMetadata(row && row.property, value)}
                        style={{ maxHeight: '10px', marginTop: '-5px', marginLeft: '-5px' }}
                    >
                        {
                            <div style={{ display: 'inline-flex', maxWidth: '200px' }}>
                                <span
                                    className={'overview-cards-paragraph'}
                                    style={{ textDecorationLine: 'underline', color: '#177ddc' }}
                                >
                                    {geti18nText('history.details')}
                                </span>
                            </div>
                        }
                    </Button>
                </Tooltip>
            );
        } else if (row.property == 'destinationJson') {
            return (
                <Tooltip placement="top" title={geti18nText('history.details')}>
                    <Button
                        type="link"
                        icon={<SelectOutlined style={{ marginRight: '2px' }} />}
                        onClick={() => openModalRelation(row && row.property, value)}
                        style={{ maxHeight: '10px', marginTop: '-5px', marginLeft: '-5px' }}
                    >
                        {
                            <div style={{ display: 'inline-flex', maxWidth: '200px' }}>
                                <span
                                    className={'overview-cards-paragraph'}
                                    style={{ textDecorationLine: 'underline', color: '#177ddc' }}
                                >
                                    {geti18nText('history.details')}
                                </span>
                            </div>
                        }
                    </Button>
                </Tooltip>
            );
        } else if (row.property == 'status') {
            return resolveStatusField(value);
        } else if (row.property == 'type' && (link == 'task.edit' || link == 'tasks.list.column')) {
            return resolveTypeField(value);
        } else if (row.property == 'assign' && link == 'mobilization') {
            return geti18nText('app.enum.EMPLOYEE_MILITARY_RECORDS_ASSIGNED.' + value);
        } else if (row.property == 'travelWarrant') {
            return resolveTravelWarrantObjectContent(value, listChanges);
        } else {
            switch (row.propertyType) {
                case 'string':
                    if (value.length > 20) {
                        return (
                            <Tooltip placement="top" title={geti18nText('history.details')}>
                                <Button
                                    type="link"
                                    onClick={() => openModalTextArea(row && row.property, value)}
                                    style={{ maxHeight: '10px', marginTop: '-5px', marginLeft: '-5px' }}
                                >
                                    {
                                        <div
                                            style={{
                                                display: 'inline-flex',
                                                maxWidth: '200px',
                                                marginLeft: '-5px',
                                                marginRight: '-5px',
                                            }}
                                        >
                                            <span
                                                className={'overview-cards-paragraph'}
                                                style={{ textDecorationLine: 'underline', color: '#177ddc' }}
                                            >
                                                {value}
                                            </span>
                                        </div>
                                    }
                                </Button>
                            </Tooltip>
                        );
                    } else {
                        return <span style={{ textDecorationLine: 'underline' }}>{value}</span>;
                    }
                case 'number':
                    return <span style={{ textDecorationLine: 'underline' }}>{NyUtils.formatNumber(value, 2)}</span>;
                case 'date':
                    return <span style={{ textDecorationLine: 'underline' }}>{resolveDate(value)}</span>;
                case 'boolean':
                    return (
                        <span style={{ textDecorationLine: 'underline' }}>
                            {value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no')}
                        </span>
                    );
                default:
                    if (value?.length > 20) {
                        return (
                            <Tooltip placement="top" title={geti18nText('history.details')}>
                                <Button
                                    type="link"
                                    onClick={() => openModalTextArea(row && row.property, value)}
                                    style={{ maxHeight: '10px', marginTop: '-5px', marginLeft: '-5px' }}
                                >
                                    {
                                        <div style={{ display: 'inline-flex', maxWidth: '200px' }}>
                                            <span
                                                className={'overview-cards-paragraph'}
                                                style={{ textDecorationLine: 'underline', color: '#177ddc' }}
                                            >
                                                {value}
                                            </span>
                                        </div>
                                    }
                                </Button>
                            </Tooltip>
                        );
                    } else {
                        return <span style={{ textDecorationLine: 'underline' }}>{value}</span>;
                    }
            }
        }
    };

    const getTableView = (row: any) => (
        <Collapse>
            <Panel key={row && row.property} header={<span>{resolveProperty(row && row.property)}</span>}>
                <NyHistoryCustomTable row={row} />
            </Panel>
        </Collapse>
    );

    const getDefaultView = (row: any, listChanges: any) => (
        <li style={{ marginBottom: '-10px' }}>
            <span style={{ fontWeight: 'bold' }}>{resolveProperty(row && row.property)} </span>
            {row.old && (
                <span>
                    {geti18nText('history.from')} {resolveValue(row, row.old, listChanges)}{' '}
                    {geti18nText('history.setTo')}{' '}
                </span>
            )}
            <span>{resolveValue(row, row.new, listChanges)}</span>
        </li>
    );

    const getChanges = (row: any, listChanges: any) => {
        return (
            <>
                {row && row.new != null && row.new != undefined && (
                    <>{showTableView(row) ? getTableView(row) : getDefaultView(row, listChanges)}</>
                )}
            </>
        );
    };

    const getCustomFooterContent = (
        <div>
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button key={'print'} icon={<PrinterOutlined />}>
                            {geti18nText('travelWarrant.action.pdf.short')}
                        </Button>
                    );
                }}
                onBeforeGetContent={() => {
                    setPrintPdf(true);
                    return Promise.resolve();
                }}
                onAfterPrint={() => {
                    setPrintPdf(false);
                }}
                content={() => componentRef.current}
                pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
            />
            <Button onClick={closeModalRelation}>{geti18nText('app.default.button.cancel')}</Button>
            <Button type="primary" onClick={closeModalRelation}>
                {geti18nText('app.default.button.ok')}
            </Button>
        </div>
    );

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const checkUser = (value: any) => {
        setUser('');
        if (value?.id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_IS_USER + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result?.status === RESPONSE.OK && result?.data?.id && result?.data?.username) {
                        setUser(result.data.username);
                    }
                }
            );
        }
    };

    const getDateFilters = () => (
        <>
            {isPeriod && (
                <Form layout="vertical" form={form}>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item label={geti18nText('contract.edit.validFrom')} name="validFrom">
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => setValidFrom(value ? moment(value) : null)}
                                    disabledDate={disabledStartDate}
                                    defaultPickerValue={validFrom}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={geti18nText('contract.edit.validTo')} name="validTo">
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => setValidTo(value ? moment(value) : null)}
                                    disabledDate={disabledendDate}
                                    defaultPickerValue={validTo}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={geti18nText('notification.message.user.person.user.name')} name="user">
                                <NySearchField
                                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        employmentRecordId: 'employmentRecordId',
                                        businessUnit: 'businessUnit',
                                    }}
                                    searchBy="person.first_name || ' ' || person.last_name"
                                    itemName={[
                                        ['person', 'firstName'],
                                        ['person', 'lastName'],
                                    ]}
                                    renderOption={customEmployeeRenderOption}
                                    customItemNameLabel={'firstName lastName'}
                                    SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="ny-history"/>}
                                    style={{ width: '100%' }}
                                    placeholder={geti18nText('travelWarrant.edit.employee')}
                                    onChange={checkUser}
                                    order="person.last_name, person.first_name"
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );

    const closeTravelModal = () => {
        setVisibleTravelWarrant(false);
        setTravelWarrant(null);
        setFiles([]);
        setAdditionalData([]);
    };

    return (
        <>
            {getDateFilters()}
            <Row gutter={24}>
                <Col span={24}>
                    {loading || !historyData ? (
                        <NySpinner />
                    ) : historyData && historyData.length > 0 ? (
                        <>
                            {historyData
                                .sort((a: any, b: any) => Number(a) - Number(b))
                                .map((object: any) => {
                                    return (
                                        <>
                                            {object && object.changes && (
                                                <Collapse
                                                    activeKey={outerCollapseActiveKeys}
                                                    onChange={(key: any) => {
                                                        setOuterCollapseActiveKeys(key);
                                                    }}
                                                    accordion
                                                >
                                                    <Panel
                                                        header={
                                                            <Text type="success">
                                                                {showDefaultHeader()
                                                                    ? geti18nText('app.default.change.history')
                                                                    : geti18nText(object.objectType)}
                                                            </Text>
                                                        }
                                                        key={object.objectType}
                                                    >
                                                        {Object.entries(object.changes).map((key: any, value: any) => {
                                                            if (key && key[1] && key[1].listChanges?.length > 0) {
                                                                return (
                                                                    <Collapse
                                                                        activeKey={innerCollapseActiveKeys}
                                                                        onChange={(key: any) => {
                                                                            if (
                                                                                !innerCollapseActiveKeys.includes(value)
                                                                            ) {
                                                                                setInnerCollapseActiveKeys([
                                                                                    ...innerCollapseActiveKeys,
                                                                                    value,
                                                                                ]);
                                                                            } else {
                                                                                setInnerCollapseActiveKeys(
                                                                                    innerCollapseActiveKeys.filter(
                                                                                        (active: any) =>
                                                                                            active !== value
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Panel
                                                                            key={innerCollapseAllKeys.at(value)}
                                                                            header={
                                                                                <span>
                                                                                    {geti18nText('history.updatedBy')}{' '}
                                                                                    <span style={{ color: '#177ddc' }}>
                                                                                        {key[1].author}
                                                                                    </span>{' '}
                                                                                    {geti18nText('history.updatedDate')}{' '}
                                                                                    <span style={{ color: '#177ddc' }}>
                                                                                        {key[1].date}
                                                                                    </span>
                                                                                </span>
                                                                            }
                                                                        >
                                                                            <>
                                                                                {isPeriod ? (
                                                                                    <>
                                                                                        {key[1] &&
                                                                                            key[1].listChanges?.length >
                                                                                                0 &&
                                                                                            key[1].listChanges
                                                                                                .sort(compare)
                                                                                                .map((row: any) => {
                                                                                                    return (
                                                                                                        <ul>
                                                                                                            {getChanges(
                                                                                                                row,
                                                                                                                key[1]
                                                                                                                    .listChanges
                                                                                                            )}
                                                                                                        </ul>
                                                                                                    );
                                                                                                })}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {key[1] &&
                                                                                            key[1].listChanges?.length >
                                                                                                0 &&
                                                                                            key[1].listChanges.map(
                                                                                                (row: any) => {
                                                                                                    return (
                                                                                                        <ul>
                                                                                                            {getChanges(
                                                                                                                row,
                                                                                                                key[1]
                                                                                                                    .listChanges
                                                                                                            )}
                                                                                                        </ul>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        </Panel>
                                                                    </Collapse>
                                                                );
                                                            }
                                                        })}
                                                    </Panel>
                                                </Collapse>
                                            )}
                                        </>
                                    );
                                })}
                        </>
                    ) : (
                        <Empty />
                    )}
                </Col>
            </Row>
            <div
                style={{
                    float: 'left',
                    padding: '4px',
                    position: 'sticky',
                    bottom: '0px',
                    height: '28px',
                    right: '0',
                }}
            >
                {historyData && historyData.length > 0 ? (
                    <Tooltip
                        placement="top"
                        visible={showTooltip}
                        title={geti18nText('app.default.shortcuts.collapse')}
                    >
                        {!collapseAll ? (
                            <>
                                <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                            </>
                        ) : (
                            <>
                                <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                            </>
                        )}
                    </Tooltip>
                ) : (
                    <></>
                )}
            </div>
            {modalTextAreaVisible && (
                <Modal
                    visible={modalTextAreaVisible}
                    title={resolveProperty(titleTextArea)}
                    onCancel={() => {
                        setModalTextAreaVisible(false);
                        setValueTextArea(null);
                        setTitleTextArea('');
                    }}
                    onOk={() => {
                        setModalTextAreaVisible(false);
                        setValueTextArea(null);
                        setTitleTextArea('');
                    }}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <span>{valueTextArea}</span>
                        </Col>
                    </Row>
                </Modal>
            )}
            {metadataVisible && (
                <Modal
                    visible={metadataVisible}
                    title={titleMetadata}
                    onCancel={() => {
                        setMetadataVisible(false);
                        setMetadataValues(null);
                        setTitleMetadata('');
                    }}
                    onOk={() => {
                        setMetadataVisible(false);
                        setMetadataValues(null);
                        setTitleMetadata('');
                    }}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <ContractMetadataHistory metadata={metadataValues} />
                        </Col>
                    </Row>
                </Modal>
            )}
            {modalRelationVisible && (
                <Modal
                    visible={modalRelationVisible}
                    title={resolveProperty(titleRelation)}
                    width={900}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={getCustomFooterContent}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <TravelWarrantMap
                                showMap={true}
                                form={form}
                                disabled={true}
                                waypoints={waypoints}
                                setWaypoints={setWaypoints}
                                maxWaypointsKey={maxWaypointsKey}
                                setMaxWaypointsKey={setMaxWaypointsKey}
                                nyTestId="map"
                                isPlane={isPlane}
                                returnRoute={returnFields}
                                returnWaypoints={returnWaypoints}
                                setReturnWaypoints={setReturnWaypoints}
                                returnIntermediateWaypointsInit={returnIntermediateWaypointsInit.current}
                                componentRefProp={componentRef}
                                printPdfProp={printPdf}
                                setPrintPdfProp={setPrintPdf}
                                printPdfHeader={geti18nText('history.details') + ' ' + resolveProperty(titleRelation)}
                                hidePrint={true}
                            />
                        </Col>
                    </Row>
                </Modal>
            )}
            {visibleLog && (
                <Modal
                    visible={visibleLog}
                    okText={geti18nText('app.default.button.ok')}
                    title={geti18nText('meals.meal.logs.error.object')}
                    width={900}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    maskClosable={false}
                    onOk={() => {
                        setVisibleLog(false);
                        setLog(null);
                    }}
                    onCancel={() => {
                        setVisibleLog(false);
                        setLog(null);
                    }}
                >
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            maxHeight: '600px',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <JsonData data={log}></JsonData>
                    </div>
                </Modal>
            )}
            {visibleTravelWarrant && (
                <Modal
                    visible={visibleTravelWarrant}
                    okText={geti18nText('app.default.button.ok')}
                    title={geti18nText('hr.nybble.micronaut.travel.domain.dto.DtoTravelWarrantSnapshot')}
                    width={900}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    maskClosable={false}
                    onOk={closeTravelModal}
                    onCancel={closeTravelModal}
                    footer={
                        <>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button key={'print'} icon={<PrinterOutlined />}>
                                            {geti18nText('travelWarrant.action.pdf.short')}
                                        </Button>
                                    );
                                }}
                                onBeforeGetContent={() => {
                                    setPrintPdf(true);
                                    return Promise.resolve();
                                }}
                                onAfterPrint={() => {
                                    setPrintPdf(false);
                                }}
                                content={() => componentRef.current}
                                pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                            />
                            <Button type="primary" onClick={closeTravelModal}>
                                {geti18nText('app.default.button.ok')}
                            </Button>
                        </>
                    }
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <TravelWarrantSummaryIndex
                                componentRefProp={componentRef}
                                printPdfProp={printPdf}
                                setPrintPdfProp={setPrintPdf}
                                globalForm={travelWarrant}
                                showFiles={true}
                                showPrintButton={false}
                                files={files}
                                setFiles={setFiles}
                                printPdfHeader={geti18nText(
                                    'hr.nybble.micronaut.travel.domain.dto.DtoTravelWarrantSnapshot'
                                )}
                                travelWarrantAdditionalInfo={additionalData}
                                showCo2={
                                    travelWarrant?.travelVehicle?.vehicleType == enVehicleType.PRIVATE_CAR ||
                                    travelWarrant?.travelVehicle?.vehicleType == enVehicleType.OFFICIAL_CAR
                                        ? false
                                        : true
                                }
                                disabled={true}
                                isNewWizard={true}
                            ></TravelWarrantSummaryIndex>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default NyHistory;
