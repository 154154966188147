import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyDataEdit, NySession, geti18nText } from '@nybble/nyreact';
import { Alert, Col, Form, Row, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getMobilizationStatusIcon } from '../../../../utils/Utils';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { MobilizationRights } from '../../../../rights/mobilizationRights';

const WorkObligationCallEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('menu.work.obligation.call.add.new.title'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isCreated, setIsCreated] = useState<boolean>(false);
    const [canUploadFiles, setCanUploadFiles] = useState<boolean>(true);
    const [form] = Form.useForm();
    const history = useHistory();
    const enMobilizationStatus = useEnum('MOBILIZATION_STATUS');
    const uploadFilesRef = useRef<any>(null);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const { employee } = useSelector((state: RootState) => state.employee);

    const canCreate = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('status')) {
            getEditHeaderTitle(dataForm.status);
            setCanUploadFiles(
                dataForm.status != enMobilizationStatus.APPROVED && dataForm.status != enMobilizationStatus.REJECTED
            );
        }

        if (dataForm.hasOwnProperty('id')) {
            setIsCreated(true);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        setDataForm(dataForm.id);
    }

    const onModalClose = () => {
        setCanUploadFiles(true);
        form.resetFields();
        setEditHeader(geti18nText('menu.work.obligation.call.add.new.title'));
    };

    const getEditHeaderTitle = (status: any) => {
        setEditHeader(
            <div style={{ display: 'flex' }}>
                <div> {geti18nText('menu.work.obligation.call.mobilization') + ' - '}</div>
                <div style={{ marginLeft: '5px' }}>{getMobilizationStatusIcon(status)}</div>
            </div>
        );
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={true} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!true ? (
                        <EyeOutlined className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
        </>
    );

    return (
        <>
            <NyDataEdit
                width={1200}
                layout="vertical"
                formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                onModalOpen={onModalOpen}
                url={CONSTANTS_REQ.MOBILIZATION.EDIT}
                setValues={setValues}
                form={form}
                goBack={() => history.goBack()}
                shortcuts={true}
                hideSubmitButton={isCreated}
                {...props}
                hideActivationButtons={true}
                checkIsFormChanged={false}
                getCustomFooterContent={getCustomFooterContent}
                onSaveAndGetID={(id: any) => {
                    if (uploadFilesRef?.current) {
                        uploadFilesRef.current.filesUpload(id);
                    }
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Alert
                            message={geti18nText('menu.work.obligation.call.modal.info')}
                            type="info"
                            showIcon
                            style={{ marginBottom: '4px' }}
                        />
                    </Col>
                </Row>
                <NyFilesUpload
                    url={CONSTANTS_REQ.MOBILIZATION_FILE.LIST}
                    editUrl={CONSTANTS_REQ.MOBILIZATION_FILE.EDIT}
                    manageUploadUrl={CONSTANTS_REQ.MOBILIZATION_FILE.EDIT}
                    defaultFilesFilter={[
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'mobilization_id', condition: 'equals', value: dataForm },
                    ]}
                    ref={uploadFilesRef}
                    canAddNewFile={canCreate() && canUploadFiles}
                    canDelete={canCreate() && canUploadFiles}
                    canEdit={canCreate() && canUploadFiles}
                    id={dataForm}
                    refId={dataForm}
                    showDocumentTypeColumn={true}
                    customUploadButton={[
                        {
                            label: geti18nText('menu.work.obligation.call.file.execution'),
                            documentTypeValue: documentTypeEnum.MOBILIZATION_DECISION,
                        },
                        {
                            label: geti18nText('menu.work.obligation.call.file.organization'),
                            documentTypeValue: documentTypeEnum.MOBILIZATION_ORGANIZATION,
                        },
                    ]}
                    employee={employee}
                    maxHeight={'205px'}
                />
            </NyDataEdit>
        </>
    );
};

export default WorkObligationCallEdit;
