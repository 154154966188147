import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import AssetSimpleIndex from '../../../../../components/asset-simple/assetSimple';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customAssetRenderOption } from '../../../../../utils/Utils';

const EditableCellAssetSelect = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxQuantity = undefined,
    form,
    mandatoryReceipt = false,
    item,
    assetIds,
    ...restProps
}: any) => {
    let inputNode: any = undefined;

    const setDefaultFilterAsset = () => {
        if (assetIds?.length > 0) {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'item.id', condition: 'equals', value: item?.id },
                { field: 'id', condition: 'not_in', value: assetIds.toString() },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'item.id', condition: 'equals', value: item?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
    };

    switch (inputType) {
        case 'asset':
            inputNode = (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ASSET.SEARCH}
                    map={{
                        id: 'id',
                        label: 'label',
                        text: 'text',
                        name: 'name',
                        serialNumber: 'serialNumber',
                        inventoryNumber: 'inventoryNumber',
                        assetModel: 'assetModel',
                        assetType: 'assetType',
                        item: 'item',
                        measureUnit: 'measureUnit',
                        quantity: 'quantity',
                    }}
                    searchBy="assetModel.name || serialNumber || inventoryNumber"
                    renderOption={customAssetRenderOption}
                    setDefaultFilterValue={setDefaultFilterAsset}
                    order="assetModel.name"
                    customListWidth={'1200px'}
                    mustGetPopupContainer={false}
                    SearchPopupComponent={<AssetSimpleIndex defaultFilterValue={setDefaultFilterAsset()} disabled />}
                />
            );
            break;
        case 'dateTo':
            inputNode = <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />;
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <>
            <td {...restProps}>
                {editing && inputType ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: inputType == 'asset',
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        </>
    );
};

export default EditableCellAssetSelect;
