import { ProfileOutlined } from '@ant-design/icons';
import { getColumnSearch, geti18nText, NyDataTable, NySearchField } from '@nybble/nyreact';
import { Form, Tooltip } from 'antd';
import { useState } from 'react';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import { getEnumArray, getEnumBooleanArray, isValidJson } from '../../../../utils/Utils';
import ApplicationSettingsEdit from './ApplicationSettingsEdit';

const ApplicationSettingsTable = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = { y: 460, x: 800 },
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [settingModule, setSettingModule] = useState(-1);
    const [counter, setCounter] = useState(0);

    const onSettingModalChange = (value: any) => {
        setSettingModule(value.id);
        setCounter(counter + 1);
    };

    const settingKeyFilter = () => {
        return Array('PAYMENT_CALCULATION_RUNNING', 'JOPPD_FORM');
    };

    const setDefaultFilterValue = () => {
        if (settingModule === -1) {
            return [
                {
                    field: 'settingKey',
                    condition: 'not_in',
                    value: settingKeyFilter().toString(),
                },
            ];
        }
        return [
            { field: 'settingModule', condition: 'equals', value: settingModule },
            {
                field: 'settingKey',
                condition: 'not_in',
                value: settingKeyFilter().toString(),
            },
        ];
    };

    const setValue = (val: any, dataType: Number, sourceType: any, pattern: any) => {
        let en = GetEnumNameForValue({
            enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
            value: dataType,
        });
        if (val) {
            if (en == 'ENUM') {
                val = geti18nText('app.enum.' + sourceType + '.' + val);
            } else if (en == 'ENUM_MULTI') {
                val = val
                    .split(',')
                    .map(
                        (value: any, index: any) =>
                            (index ? ', ' : '') + geti18nText('app.enum.' + sourceType + '.' + value)
                    );
            } else if (en == 'BOOL') {
                let enumBoolArray = getEnumBooleanArray();
                let boolVal = enumBoolArray.find((data) => data.id === Number(val));
                if (boolVal) {
                    val = boolVal.text;
                }
            } else if (en == 'CODEBOOK') {
                if (isValidJson(val)) {
                    val = JSON.parse(val)[pattern];
                } else {
                    val = val;
                }
            }
        }

        return val;
    };

    const initialColumns = [
        {
            title: geti18nText('app.setting.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('app.setting.table.column.group'),
            width: '20%',
            dataIndex: 'settingGroup',
            render: (settingGroup: any, record: any) => {
                if (record.settingModule) {
                    let sModule = GetEnumNameForValue({
                        enumName: 'APPLICATION_SETTINGS_MODULE_TYPE',
                        value: record.settingModule,
                    });
                    return geti18nText('app.setting.group.name.' + sModule + '.' + settingGroup);
                }
                return '';
            },
        },
        {
            title: geti18nText('app.setting.table.column.name'),
            dataIndex: 'settingKey',
            render: (settingKey: any, record: any) => {
                if (record.settingModule) {
                    let sModule = GetEnumNameForValue({
                        enumName: 'APPLICATION_SETTINGS_MODULE_TYPE',
                        value: record.settingModule,
                    });
                    return geti18nText('app.setting.name.' + sModule + '.' + record.settingGroup + '.' + settingKey);
                }
                return '';
            },
        },
        {
            title: geti18nText('app.setting.table.column.value'),
            dataIndex: 'value',
            render: (value: any, row: any, index: any) => {
                return (
                    <Tooltip placement="topRight" overlayStyle={{ maxWidth: '900px' }} title={value}>
                        <div className={'overview-cards-paragraph-div'}>
                            <p className={'overview-cards-paragraph'}>
                                {setValue(value ?? row.defaultValue, row.dataType, row.sourceType, row.pattern)}
                            </p>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('app.setting.table.column.description'),
            width: '10%',
            dataIndex: 'description',
            render: (settingKey: any, record: any) => {
                let pathDescription: string = '';
                if (record.settingModule) {
                    let sModule = GetEnumNameForValue({
                        enumName: 'APPLICATION_SETTINGS_MODULE_TYPE',
                        value: record.settingModule,
                    });
                    pathDescription =
                        'app.setting.description.' + sModule + '.' + record.settingGroup + '.' + settingKey;
                }
                return pathDescription && geti18nText(pathDescription) ? (
                    <Tooltip title={geti18nText(pathDescription)}>
                        <ProfileOutlined style={{ fontSize: '17px' }} />
                    </Tooltip>
                ) : (
                    <></>
                );
            },
        },
    ];

    return (
        <>
            <Form.Item label={geti18nText('app.setting.table.module')} style={{ width: '20%' }}>
                <NySearchField
                    options={getEnumArray('APPLICATION_SETTINGS_MODULE_TYPE')}
                    map={{ id: 'id', label: 'text' }}
                    searchBy="text"
                    onChange={onSettingModalChange}
                    autoFocus={true}
                    mustGetPopupContainer={false}
                />
            </Form.Item>
            <NyDataTable
                nyId="application-settings-table"
                url={CONSTANTS_REQ.APPLICATION_SETTINGS.LIST}
                addNewButtonText={geti18nText('app.setting.table.add')}
                //buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={ApplicationSettingsEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                fetchWhenChange={counter}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                hideNewButton
                shortcuts={true}
            />
        </>
    );
};

export default ApplicationSettingsTable;
