import { CarOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrGet, geti18nText } from '@nybble/nyreact';
import { Card, Empty, Statistic, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { setProp } from '../../slices/propSlice';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getColorFromNotificationType } from '../../utils/Utils';

const { Title } = Typography;

const TravelWarrantWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const widgetColor = getColorFromNotificationType(settings, 'TRAVEL_WARRANT_APPROVAL');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const {
        data: countWaitingForApproval,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
    });

    const {
        data: travelWarrantsApprovedAdvance,
        isError: errorWarrantsApprovedAdvance,
        isLoading: loadingWarrantsApprovedAdvance,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.APPROVED,
        advanceApproved: true,
    });

    const {
        data: travelWarrantsApproved,
        isError: errorWarrantsApproved,
        isLoading: loadingWarrantsApproved,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION,
    });

    const {
        data: travelWarrantsNotCalculated,
        isError: errorWarrantsNotCalculated,
        isLoading: loadingWarrantsNotCalculated,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL,
    });

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px 10px',
    };

    return (
        <Card
            className="ny-widget-card"
            title={geti18nText('menu.travelWarrant')}
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            bodyStyle={{
                height: 'calc(100% - 55px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '4px 5px 0px 5px',
                marginTop: '1px',
            }}
            type="inner"
            extra={
                <>
                    {canCreate() && (
                        <Tooltip placement="top" title={geti18nText('travelWarrant.table.add')}>
                            <CarOutlined
                                className="ny-card-icon"
                                onClick={() => {
                                    history.push('/travel-warrant/travelWarrant/create');
                                }}
                            />
                        </Tooltip>
                    )}
                </>
            }
        >
            {loading || loadingWarrantsApprovedAdvance || loadingWarrantsApproved || loadingWarrantsNotCalculated ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : error ||
              errorWarrantsApprovedAdvance ||
              errorWarrantsApproved ||
              errorWarrantsNotCalculated ||
              (countWaitingForApproval &&
                  countWaitingForApproval.ALL < 0 &&
                  travelWarrantsApprovedAdvance &&
                  travelWarrantsApprovedAdvance.ALL < 0 &&
                  travelWarrantsApproved &&
                  travelWarrantsApproved.ALL < 0 &&
                  travelWarrantsNotCalculated &&
                  travelWarrantsNotCalculated.ALL < 0) ? (
                <div style={{ paddingTop: 10 }}>
                    <Empty
                        className="ny-dashboard-empty"
                        imageStyle={{ width: '100%' }}
                        description={<Title level={5}>{geti18nText('app.default.nodata')}</Title>}
                    />
                </div>
            ) : (
                <>
                    {countWaitingForApproval && countWaitingForApproval.ALL >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className="statistic-card"
                            onClick={() => {
                                dispatch(setProp(3));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travelWarrantPersonal.requests')}
                                value={countWaitingForApproval && countWaitingForApproval.ALL}
                                prefix={<CarOutlined />}
                            />
                        </Card.Grid>
                    )}
                    {travelWarrantsApprovedAdvance && travelWarrantsApprovedAdvance.ALL >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => {
                                dispatch(setProp(4));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travel.warrant.approved.advances')}
                                value={travelWarrantsApprovedAdvance && travelWarrantsApprovedAdvance.ALL}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                    {travelWarrantsApproved && travelWarrantsApproved.ALL >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => {
                                dispatch(setProp(5));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travel.warrant.approved')}
                                value={travelWarrantsApproved && travelWarrantsApproved.ALL}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}

                    {travelWarrantsNotCalculated && travelWarrantsNotCalculated.ALL >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => {
                                dispatch(setProp(6));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travel.warrant.waitingForReportApproval')}
                                value={travelWarrantsNotCalculated && travelWarrantsNotCalculated.ALL}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                </>
            )}
        </Card>
    );
};

export default TravelWarrantWidget;
