import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import useHelper from '../../../../../hooks/useHelper';
import BusinessUnitTable from './BusinessUnitTable';
import EmployeeTable from './EmployeeTable';

const { TabPane } = Tabs;

const AssetReleaseIndex = () => {
    useHelper('asset/asset_release');

    return (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('assetRelease.tab.1')} key="1">
                    <EmployeeTable scroll={{ y: 540, x: 800 }} />
                </TabPane>
                <TabPane tab={geti18nText('assetRelease.tab.2')} key="2">
                    <BusinessUnitTable scroll={{ y: 540, x: 800 }} />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default AssetReleaseIndex;
