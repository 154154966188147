import {
    BankOutlined,
    HomeOutlined,
    MailOutlined,
    MobileOutlined,
    OrderedListOutlined,
    PhoneOutlined,
    SettingOutlined,
    SolutionOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Avatar, Button, Card, Col, Collapse, Form, Input, Layout, Modal, Row, Tabs } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import GeneralIndex from './GeneralIndex';
import SettingsIndex from './settings';
import UserInfo from '../../components/user-info';
import { errorNotification, okNotification } from '../../utils/Utils';
import React from 'react';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const MyProfileIndex = (props: any) => {
    const location = useLocation();
    const { employee } = useSelector((state: RootState) => state.employee);
    const [resultData, setResultData] = useState<any>();
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const [passwordModalVisible, setPasswordModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [passwordsMatch, setPasswordsMatch] = useState<any>(false);

    useEffect(() => {
        employee && fetchEmployeeData();
    }, []);

    const fetchEmployeeData = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + employee?.id).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setResultData(result.data);
                }
            } else {
                errorNotification();
            }
        });
    };

    const employeeDescription = (
        <div style={{ color: theme == 'light' || theme == 'default' ? 'black' : 'white' }}>
            {resultData ? (
                <>
                    <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div>
                            <BankOutlined /> {geti18nText('employee.mobilization.edit.businessUnit')}:{' '}
                            <strong>{resultData && resultData.businessUnit && resultData.businessUnit.name}</strong>
                        </div>
                        <div>
                            <OrderedListOutlined /> {geti18nText('employee.edit.eployment.record')}:{' '}
                            <strong>{resultData && resultData.employmentRecordId}</strong>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div>
                            <SolutionOutlined /> {geti18nText('employee.edit.professional.vocation')}:{' '}
                            <strong>{resultData && resultData.vocation && resultData.vocation.name}</strong>
                        </div>
                        <div>
                            <HomeOutlined /> {geti18nText('employee.edit.employment.room')}:{' '}
                            <strong>{resultData && resultData.room && resultData.room.name}</strong>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div>
                            <PhoneOutlined /> {geti18nText('employee.contact.edit.phone')}:{' '}
                            <strong>
                                {resultData && resultData.contact && resultData.contact.phone
                                    ? resultData.contact.phone
                                    : '-'}
                            </strong>
                        </div>
                        <div>
                            <MobileOutlined /> {geti18nText('employee.contact.edit.mobile')}:{' '}
                            <strong>
                                {resultData && resultData.contact && resultData.contact.mobile
                                    ? resultData.contact.mobile
                                    : '-'}
                            </strong>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div>
                            <MailOutlined /> {geti18nText('employee.contact.edit.email')}:{' '}
                            <strong>
                                {resultData && resultData.contact && resultData.contact.email
                                    ? resultData.contact.email
                                    : '-'}
                            </strong>
                        </div>
                    </div>
                </>
            ) : (
                NySession.getUser() && (
                    <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div>
                            <UserOutlined /> {geti18nText('app.default.login.username.placeholder')}:{' '}
                            <strong>{NySession.getUser().username}</strong>
                        </div>
                    </div>
                )
            )}
            <Button
                type="primary"
                onClick={() => {
                    setPasswordModalVisible(true);
                }}
            >
                {geti18nText('user.auth.edit.password.change.button')}
            </Button>
        </div>
    );

    const checkPasswordMatch = (rule: any, value: string, callback: any) => {
        if (!value || value === form.getFieldValue('password')) {
            setPasswordsMatch(true);
            callback();
        } else {
            setPasswordsMatch(false);
            callback(geti18nText('user.auth.edit.passwordsDontMatch'));
        }
    };

    return (
        <>
            <Layout>
                <React.Fragment>
                    <div>
                        <Card>
                            <Meta
                                avatar={
                                    resultData && resultData.person && resultData.person.fileId ? (
                                        <Avatar
                                            size={64}
                                            src={CONSTANTS_REQ.FILES.DOWNLOAD + '/' + resultData.person.fileId}
                                        />
                                    ) : (
                                        <Avatar size={64} icon={<UserOutlined />} />
                                    )
                                }
                                title={
                                NySession.getUser()?.fullName ??
                                (resultData &&
                                    resultData.person &&
                                    resultData.person.firstName + ' ' + resultData.person.lastName)
                                }
                                description={employeeDescription}
                                style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                }}
                            />
                        </Card>
                    </div>
                </React.Fragment>
                <Content>
                    <Tabs
                        centered
                        type="card"
                        className="tabs-sticky"
                        id="my-profile-tabs"
                        style={{ overflowY: 'auto', maxHeight: '680px' }}
                    >
                    {employee && (
                        <>
                            <TabPane tab={geti18nText('travelWarrantWizard.step.6')} key="overview">
                                <Card>
                                    <UserInfo id={employee?.id} />
                                </Card>
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                {' '}
                                        <HomeOutlined />
                                        {geti18nText('my.profile.general')}
                                    </span>
                                }
                                key="general"
                            >
                                <GeneralIndex employee={employee} />
                            </TabPane>
                        </>
                    )}

                        <TabPane
                            tab={
                                <span>
                                    <SettingOutlined />
                                    {geti18nText('settings.title')}
                                </span>
                            }
                            key="settings"
                        >
                            <SettingsIndex />
                        </TabPane>
                    </Tabs>
                </Content>
            </Layout>
            <Modal
                title={geti18nText('user.auth.edit.password.change')}
                open={passwordModalVisible}
                okButtonProps={{ disabled: !passwordsMatch }}
                okText={geti18nText('user.auth.edit.change.button')}
                onCancel={() => {
                    form.resetFields();
                    setPasswordModalVisible(false);
                    setPasswordsMatch(false);
                }}
                onOk={() => {
                    NyRequestResolver.requestPost(CONSTANTS_REQ.USER_AUTH.PASSWORD_CHANGE, undefined, {
                        password: form.getFieldValue('password'),
                    }).then((result: any) => {
                        if (result && result.status === RESPONSE.OK) {
                            okNotification(geti18nText('user.auth.edit.password.change.success'), '');
                        } else {
                            errorNotification(geti18nText('app.default.save.nok'));
                        }
                    });
                }}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="password"
                                label={geti18nText('user.auth.edit.newPassword')}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="passwordConfirm"
                                label={geti18nText('user.auth.edit.newPassword')}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: checkPasswordMatch,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
export default MyProfileIndex;
