import { ExclamationCircleOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySession,
} from '@nybble/nyreact';
import { Col, Form, Input, Modal, Row, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import GroupOrdersTable from './GroupOrdersTable';
import PaymentOrdersTable from './PaymentOrdersTable';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    setEnumFormat,
    setDateFormat,
    getDateFormat,
    getEnumFormat,
    getEnumArrayStatus,
} from '../../../../../utils/Utils';
import { validateBIC } from '../../../../../utils/Validation';
import { PayrollRights } from '../../../../../rights/payrollRights';

const { TabPane } = Tabs;
const { confirm } = Modal;

const PaymentOrdersInitializationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('payment.orders.initialization.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isFormChange, setIsFormChange] = useState(false);
    const [disabledTable, setDisabledTable] = useState(true);
    const [bulkId, setBulkId] = useState(undefined);
    const [status, setStatus] = useState<any>(setEnumFormat('PAYMENT_ORDER_STATUS_TYPE', 1));
    const [disabledStatus, setDisabledStatus] = useState(1);
    const [companyId, setCompanyId] = useState(props.companyId);
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);

    useEffect(() => {
        getCompanyInfo();
    }, []);

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    function getCompanyInfo() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.PAYMENT_ORDER.GET_DEBTOR, {}).then((result: any) => {
            if (result.data) {
                setCompanyId(result.data.id);
                let debtorData: any = {};
                debtorData['initiatingPartyName'] = result.data.name ? result.data.name : '';

                form.setFieldsValue(debtorData);
            }
        });
    }

    async function setValues(dataForm: any) {
        if (dataForm.id) {
            setDisabledTable(false);
        }
        setBulkId(dataForm.id);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('messageId')) {
            setEditHeader(geti18nText('payment.orders.initialization.edit.title') + ' - ' + dataForm.messageId);
        }

        if (dataForm.created) dataForm.created = setDateFormat(dataForm.created);
        if (dataForm.requestedExecutionDate)
            dataForm.requestedExecutionDate = setDateFormat(dataForm.requestedExecutionDate);
        if (dataForm.completedDate) dataForm.completedDate = setDateFormat(dataForm.completedDate);

        if (dataForm.status) {
            setDisabledStatus(dataForm.status);
            dataForm.status = setEnumFormat('PAYMENT_ORDER_STATUS_TYPE', dataForm.status);
            setStatus(dataForm.status);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setDisabledTable(true);
        setIsFormChange(false);
        form.resetFields();
        setEditHeader(geti18nText('payment.orders.initialization.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const statusOnChange = (value: any) => {
        if (value.id > -1) {
            if (value.id >= status.id) {
                setStatus(value);
            } else {
                showConfirm(value);
            }
        }
    };

    function showConfirm(val: any) {
        confirm({
            okText: geti18nText('app.default.button.ok'),
            title: geti18nText('payment.orders.initialization.edit.status.comfirm'),
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setStatus(val);
            },
            onCancel() {
                form.setFieldsValue({ status: status });
            },
        });
    }

    const getAccountData = (iban: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.BANK_ACCOUNT.INFO, { iban: iban }).then((result: any) => {
            if (result.data && result.data.bank && result.data.bank.bic) {
                form.setFieldsValue({ debtorAgentBic: result.data.bank.bic });
            }
        });
    };

    const debtorAccountIbanOnChange = (value: any) => {
        if (value.iban) {
            getAccountData(value.iban);
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.PAYMENT_ORDER_INITIALIZATION.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            setIsFormChange={setIsFormChange}
            {...props}
            normalize={(values: any) => {
                if (values.created) delete values.created;
                if (values.requestedExecutionDate)
                    values.requestedExecutionDate = getDateFormat(values.requestedExecutionDate);
                if (values.completedDate) values.completedDate = getDateFormat(values.completedDate);
                if (values.debtorAccountIban) values.debtorAccountIban = values.debtorAccountIban.iban;

                if (values.status) values.status = getEnumFormat(values.status);
                return values;
            }}
            hideActivationButtons={disabledStatus === 3 || !canCreate()}
            hideSubmitButton={disabledStatus === 3 || !canCreate()}
            fetchWhenChange={refreshItem}
        >
            <Tabs type="card">
                <TabPane
                    tab={
                        <span>
                            {geti18nText('payment.orders.initialization.edit.general')}
                            {isFormChange ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="general"
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={geti18nText('payment.orders.initialization.edit.name')} name="name">
                                <Input ref={focusInput} disabled={disabledStatus === 3} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.messageId')}
                                name="messageId"
                                rules={[
                                    {
                                        required: disabledTable ? false : true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: disabledTable ? false : true,
                                    },
                                    { max: 30, message: geti18nText('app.default.max.length') + ' 30' },
                                ]}
                            >
                                <Input disabled={disabledTable || disabledStatus !== 1} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.status')}
                                name="status"
                                initialValue={setEnumFormat('PAYMENT_ORDER_STATUS_TYPE', 1)}
                            >
                                <NySearchField
                                    options={getEnumArrayStatus('PAYMENT_ORDER_STATUS_TYPE')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    nyTestId="input-payment-order-status"
                                    style={{ width: '100%' }}
                                    className={''}
                                    onChange={statusOnChange}
                                    disabled={disabledStatus === 3}
                                    value={status}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.initiatingPartyName')}
                                name="initiatingPartyName"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={geti18nText('payment.orders.initialization.edit.created')} name="created">
                                <NyDatePicker style={{ width: '100%' }} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.numberOfTransactions')}
                                name="numberOfTransactions"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.controlSum')}
                                name="controlSum"
                            >
                                <NyInputNumber isDecimal style={{ width: '100%' }} disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.requestedExecutionDate')}
                                name="requestedExecutionDate"
                            >
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    disabled={disabledStatus && disabledStatus === 1 ? false : true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.completedDate')}
                                name="completedDate"
                            >
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    disabled={disabledStatus && disabledStatus === 2 ? false : true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.debtorAccountIban')}
                                name="debtorAccountIban"
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.SEARCH}
                                    setDefaultFilterValue={() => [
                                        { field: 'companyId', condition: 'equals', value: companyId },
                                    ]}
                                    map={{ id: 'id', label: 'iban' }}
                                    searchBy="bankAccount.iban"
                                    nyTestId="debtorAccountIban"
                                    className={''}
                                    disabled={disabledStatus && disabledStatus === 1 ? false : true}
                                    onChange={debtorAccountIbanOnChange}
                                    order="bankAccount.iban"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('payment.orders.initialization.edit.debtorAgentBic')}
                                name="debtorAgentBic"
                                rules={[
                                    {
                                        validator: validateBIC,
                                    },
                                ]}
                            >
                                <Input disabled={disabledStatus && disabledStatus === 1 ? false : true} />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane
                    tab={geti18nText('payment.orders.initialization.edit.group')}
                    key="group"
                    disabled={disabledTable}
                >
                    <GroupOrdersTable bulkId={bulkId} status={disabledStatus} form={form} />
                </TabPane>
                <TabPane
                    tab={geti18nText('payment.orders.initialization.edit.orders')}
                    key="orders"
                    disabled={disabledTable}
                >
                    <PaymentOrdersTable bulkId={bulkId} />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default PaymentOrdersInitializationEdit;
