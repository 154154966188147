import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColorFromNotificationType, getEnumArray, setEnumFormat } from '../../../../utils/Utils';
import EmployeeGroupEdit from './edit';

const EmployeeGroupIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    hideNewButton = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    useHelper('human/codebooks/employee_group');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const columns = [
        {
            title: geti18nText('employeeGroup.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeGroup.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeGroup.table.column.shiftType'),
            dataIndex: 'shift_type',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (shift_type: any) => {
                let type: any = setEnumFormat('EMPLOYEE_GROUP_SHIFT_TYPE', shift_type);
                return type ? type.text : '';
            },
            ...getColumnSearchOption(getEnumArray('EMPLOYEE_GROUP_SHIFT_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('employeeGroup.table.column.shift'),
            dataIndex: 'shiftList',
            render: (shiftList: any) => {
                let tags: any = [];
                if (shiftList) {
                    shiftList.forEach((element: any) => {
                        tags.push(
                            <Tag style={{ marginBottom: '2px', marginTop: '2px' }} key={element.sort}>
                                {element.abbreviation}
                            </Tag>
                        );
                    });
                }

                return tags;
            },
        },
        {
            title: geti18nText('employeeGroup.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-employee-group-table"
            url={CONSTANTS_REQ.EMPLOYEE_GROUP.LIST}
            addNewButtonText={geti18nText('employeeGroup.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            modalComponent={EmployeeGroupEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideNewButton={hideNewButton || !canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.employeeGroup')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.employeeGroup')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            shift_type: (value: any) => {
                let type: any = setEnumFormat('EMPLOYEE_GROUP_SHIFT_TYPE', value);
                return type !== undefined && type.text !== undefined && type.text;
            },
        },
        {
            shiftList: (value: any) => {
                let tags: any = [];
                if (value) {
                    value.forEach((element: any) => {
                        tags.push(element.abbreviation);
                    });
                }

                return tags;
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeGroupIndex;
