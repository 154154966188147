import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdditionalInfo from '../../../../components/additional-info';
import { TasksRights } from '../../../../rights/tasksRights';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const TaskTypeSubtypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('task.typeSubtype.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);
    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const { TextArea } = Input;
    const focusInput = useRef<any>(null);
    const history = useHistory();

    const enTaskType = useEnum('TASK_TYPE');

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('task.typeSubtype.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.created) {
            setCreatedDate(dataForm.created);
        }

        if (dataForm.createdBy) {
            setCreatedBy(dataForm.createdBy);
        }

        if (dataForm.updated) {
            setUpdatedDate(dataForm.updated);
        }

        if (dataForm.updatedBy) {
            setUpdatedBy(dataForm.updatedBy);
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('task.typeSubtype.edit.title') + ' - ' + dataForm.name);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const getCustomFooterContent = (
        <>
            {dataForm ? (
                <div style={{ float: 'left' }}>
                    <AdditionalInfo
                        created={createdDate}
                        createdBy={createdBy}
                        updated={updatedDate}
                        updatedBy={updatedBy}
                    />
                </div>
            ) : null}
        </>
    );

    return (
        <NyDataEdit
            closeModalButtonText={geti18nText('app.default.button.close')}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TASK_TYPE_SUBTYPE.EDIT}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            setValues={setValues}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.type = enTaskType.SERVICE;
                return values;
            }}
            customFooterContent={getCustomFooterContent}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('task.typeSubtype.table.column.code')} name="code">
                        <Input autoFocus={true} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('task.typeSubtype.table.column.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('task.typeSubtype.table.column.description')} name="description">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TaskTypeSubtypeEdit;
