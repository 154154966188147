import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../rootReducer';
import { useState } from 'react';
import { NySpinner } from '@nybble/nyreact';
import React from 'react';
import { Empty, Radio } from 'antd';
import Mobilization3hTable from './Mobilization3hTable';
import Mobilization8hTable from './Mobilization8hTable';
import Mobilization24hTable from './Mobilization24hTable';
import { geti18nText } from '@nybble/nyreact/build/types/NyGlobalConstants';

const CallingForPerformanceOfDutyIndex = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { prop } = useSelector((state: RootState) => state.prop);

    const [loading, setLoading] = useState(false);
    const [activeList, setActiveList] = useState<any>(1);
    const [refresh, setRefresh] = useState(0);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        // dispatch(setProp(e.target.value > 0 ? e.target.value : 1));
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                value={activeList}
                defaultValue={1}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('calling.for.performance.of.duty.tab.1')}
                </Radio.Button>
                <Radio.Button value={2} onChange={onChangeActiveList}>
                    {geti18nText('calling.for.performance.of.duty.tab.2')}
                </Radio.Button>
                <Radio.Button value={3} onChange={onChangeActiveList}>
                    {geti18nText('calling.for.performance.of.duty.tab.3')}
                </Radio.Button>
            </Radio.Group>

            {activeList == 1 ? (
                <Mobilization3hTable scroll={{ y: 580, x: 3200 }} />
            ) : activeList == 2 ? (
                <Mobilization8hTable scroll={{ y: 580, x: 3200 }} />
            ) : activeList == 3 ? (
                <Mobilization24hTable scroll={{ y: 580, x: 3200 }} />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export default CallingForPerformanceOfDutyIndex;
