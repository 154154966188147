import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationIndex from '../payment-calculation';

const ContractIndex = () => {
    useHelper('payroll/calculation/contract');

    return <PaymentCalculationIndex type={3} />;
};

export default ContractIndex;
