import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { Form } from 'antd';
import TaskTypeSubtypeIndex from '.';
import TaskTypeSubtypeEdit from './edit';
import { TasksRights } from '../../../../rights/tasksRights';

const TaskTypeSubtypeSearch = ({
    label = geti18nText('task.template.table.column.taskTypeSubtype'),
    name = 'taskTypeSubtype',
    searchBy = 'name',
    map = { id: 'id', label: 'name' },
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    disabled = false,
    required = false,
}: any) => {
    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.TASK_TYPE_SUBTYPE.SEARCH}
                map={map}
                searchBy={searchBy}
                setDefaultFilterValue={setDefaultFilterValue}
                disabled={disabled}
                SearchPopupComponent={<TaskTypeSubtypeIndex />}
                AddNewModalComponent={canCreate() ? TaskTypeSubtypeEdit : null}
                nyTestId="task-type-subtype"
            />
        </Form.Item>
    );
};

export default TaskTypeSubtypeSearch;
