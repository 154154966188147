import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getEnumArray } from '../../../../utils/Utils';
import ItemEdit from './edit';
import useTableSettings from '../../../../hooks/useTableSettings';

const ItemByWarehouseIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSortOrder,
    setDefaultSelectedRowKeys,
    type = 0,
    itemGroupType = null,
    disabled = false,
    warehouseId = undefined,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: type },
            { field: 'warehouse_item.warehouse_id', condition: 'equals', value: warehouseId },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('item.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('item.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.itemGroup'),
            dataIndex: ['itemGroup', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            render: (text: any, record: any) => {
                if (record.availabilityStatus !== undefined) {
                    return geti18nText('app.enum.ITEM_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
            ...getColumnSearchOption(getEnumArray('ITEM_AVAILABILITY_STATUS')),
        },
        {
            title: geti18nText('item.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="item-by-warehouse"
                url={CONSTANTS_REQ.ITEM.ORDER_VIEW_LIST}
                addNewButtonText={geti18nText('item.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={ItemEdit}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                shortcuts={true}
                hideNewButton={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                addedData={{ type: type, itemGroupType: itemGroupType }}
                setDefaultPageSize={table.setDefaultPageSize()}
                hideButtons={disabled}
                readonly={disabled}
            />
        </>
    );
};

export default ItemByWarehouseIndex;
