import { EllipsisOutlined, FilePdfOutlined, FileSyncOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySession,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnDateOption, getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Dropdown, Form, Menu, Modal } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import {
    errorNotification,
    fileDownload,
    getDateFormat,
    getEnumArrayStatus,
    okNotification,
} from '../../../../../utils/Utils';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealGroupOrderIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();

    const table = useTableSettings();
    const [visible, setVisible] = useState<boolean>(false);
    const [restaurantName, setRestaurantName] = useState<any>();
    const [type, setType] = useState<any>();
    const [forDate, setForDate] = useState<any>();
    const [id, setId] = useState<any>();
    const [refresh, setRefresh] = useState<any>(0);
    const [date, setDate] = useState<any>(moment().add(1, 'day'));
    const [recapitulationModalVisible, setRecapitulationModalVisible] = useState<boolean>(false);

    const EnMealsGroupOrderType = useEnum('MEALS_GROUP_ORDER_TYPE');
    const enDeliveryType = useEnum('DELIVERY_TYPE');

    useHelper('meals/codebooks/meal');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'type',
                condition: 'in',
                value: [EnMealsGroupOrderType.SPECIFICATION, EnMealsGroupOrderType.RECAPITULATION].toString(),
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('meal.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.delivery.table.column.restaurant'),
            dataIndex: [`restaurant.name`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.RESTAURANT.SEARCH, 'restaurant.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
            render: (name: any, record: any) => {
                if (record && record.restaurant && record.restaurant.name) {
                    return record.restaurant.name;
                }
            },
        },
        {
            title: geti18nText('meal.group.order.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(
                getEnumArrayStatus('MEALS_GROUP_ORDER_TYPE').filter(
                    (s: any) => s && s.id != EnMealsGroupOrderType.BILL
                ),
                'in',
                [EnMealsGroupOrderType.SPECIFICATION, EnMealsGroupOrderType.RECAPITULATION].toString()
            ),
            render: (type: any, record: any) => {
                if (record && record.type) {
                    return geti18nText('app.enum.MEALS_GROUP_ORDER_TYPE.' + record.type);
                }
            },
        },
        {
            title: geti18nText('meal.group.order.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (created: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('meal.group.order.table.column.for.date'),
            dataIndex: 'forDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (forDate: any, record: any) => {
                if (record.forDate) {
                    return getDateFormat(record.forDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('meal.group.order.table.column.pdf'),
            dataIndex: 'fileId',
            width: '8%',
            render: (fileId: any, record: any) => {
                if (record && record.fileId) {
                    let nameFile =
                        record.restaurant.name +
                        ' - ' +
                        geti18nText('app.enum.MEALS_GROUP_ORDER_TYPE.' + record.type) +
                        ' ' +
                        geti18nText('meal.group.order') +
                        ' - ' +
                        getDateFormat(record.forDate, 'yyyyMMDD');
                    return (
                        <Button
                            type="primary"
                            ghost
                            icon={<FilePdfOutlined />}
                            style={{
                                marginLeft: '2px',
                            }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                    null,
                                    nameFile.toString()
                                );
                            }}
                        >
                            {geti18nText('meal.group.order.table.download.pdf')}
                        </Button>
                    );
                }
            },
        },
    ];

    const modalColumns = [
        {
            title: geti18nText('meal.menu.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.delivery.table.column.person'),
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            render: (text: any, record: any) => {
                if (record.person) {
                    return (
                        record.person.firstName +
                        ' ' +
                        record.person.lastName +
                        (record.employee && record.employee.employmentRecordId
                            ? ' (' + record.employee?.employmentRecordId + ')'
                            : '')
                    );
                }
            },
        },

        {
            title: geti18nText('meal.delivery.table.column.orderDate'),
            dataIndex: 'orderDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (name: any, record: any) => {
                if (record && record.orderDate) {
                    return moment(record.orderDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot'),
            dataIndex: [`concat(deliverySpot.name,' ', restaurant.name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (name: any, record: any) => {
                if (record?.deliverySpotTime?.type == enDeliveryType.DELIVERY && record?.deliverySpot?.name) {
                    return record.deliverySpot.name;
                } else if (record?.deliverySpotTime?.type == enDeliveryType.PICKUP && record?.restaurant?.name) {
                    return record.restaurant.name;
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.time'),
            dataIndex: ['deliverySpotTime', 'time'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (name: any, record: any) => {
                if (record && record.deliverySpotTime && record.deliverySpotTime.time) {
                    return record.deliverySpotTime.time;
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.type'),
            dataIndex: ['deliverySpotTime', 'type'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('DELIVERY_TYPE')),
            render: (name: any, record: any) => {
                if (record && record.deliverySpotTime && record.deliverySpotTime.type) {
                    return geti18nText('app.enum.DELIVERY_TYPE.' + record.deliverySpotTime.type);
                }
            },
        },
    ];

    const specification = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.GROUP_ORDER.SPECIFICATION, undefined, {}).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                okNotification();
                setRefresh(refresh + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const disabledDate = (current: any) => {
        return current < moment().subtract(1, 'day');
    };

    const recapitulation = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.GROUP_ORDER.RECAPITULATION, undefined, {
            ordersForDate: moment(date).format('yyyy-MM-DD'),
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                okNotification();
                setRefresh(refresh + 1);
                setRecapitulationModalVisible(false);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const addedButtons = () => {
        const actionsMenu = (
            <Menu>
                <div style={{ display: 'flex', margin: '5px', flexDirection: 'column' }}>
                    <Button style={{ width: '100%' }} onClick={specification} icon={<FileSyncOutlined />}>
                        {geti18nText('meal.group.order.button.generateGroupOrderSpecification')}
                    </Button>
                    <Button
                        style={{ width: '100%', marginTop: '5px' }}
                        onClick={() => setRecapitulationModalVisible(true)}
                        icon={<FileSyncOutlined />}
                    >
                        {geti18nText('meal.group.order.button.generateGroupOrderRecapitulation')}
                    </Button>
                </div>
            </Menu>
        );
        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="meals-group-orders"
                url={CONSTANTS_REQ.GROUP_ORDER.LIST}
                addNewButtonText={geti18nText('menu.meals.group.order.men')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                fetchWhenChange={refresh}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                setDefaultFilterValue={() => setDefaultFilterValue()}
                onDataLoaded={table.onDataLoaded}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={!canCreate()}
                shortcuts={true}
                onRowSelect={(e: any) => {
                    setVisible(true);
                    setRestaurantName(e.restaurant?.name);
                    setForDate(getDateFormat(e.forDate, 'DD.MM.YYYY'));
                    setId(e.id);
                    setType(e.type);
                }}
                addedButtons={addedButtons}
                headerTitle={geti18nText('menu.meals.group.order.menu')}
            />
            {visible && (
                <Modal
                    visible={visible}
                    title={
                        (type == EnMealsGroupOrderType.RECAPITULATION
                            ? geti18nText('meal.group.order.edit.header.grouped')
                            : geti18nText('meal.group.order.edit.header.detailed')) +
                        ' - ' +
                        restaurantName +
                        ' - ' +
                        forDate
                    }
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    width={1000}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    <NyDataTable
                        nyId="meals-group-order-list"
                        url={CONSTANTS_REQ.GROUP_ORDER.ORDER_LIST.replace('{groupOrderId}', id)}
                        addNewButtonText={geti18nText('menu.meals.group.order.menu')}
                        buttonsClassName="buttons-sticky"
                        showRecordModal={true}
                        columns={modalColumns}
                        scroll={{ y: 680, x: 800 }}
                        onDataLoaded={table.onDataLoaded}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        setDefaultCurrentPage={table.setDefaultCurrentPage()}
                        setDefaultSortOrder={table.setDefaultSortOrder()}
                        hideNewButton={true}
                        shortcuts={true}
                        readonly={true}
                    />
                </Modal>
            )}
            {recapitulationModalVisible && (
                <Modal
                    visible={recapitulationModalVisible}
                    title={geti18nText('meal.group.order.edit.header.grouped')}
                    onOk={() => {
                        recapitulation();
                    }}
                    onCancel={() => {
                        setRecapitulationModalVisible(false);
                    }}
                    width={300}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    okText={geti18nText('app.default.button.generate')}
                    cancelText={geti18nText('app.default.button.cancel')}
                >
                    <Form layout="vertical">
                        <Form.Item label={geti18nText('meal.group.order.date.generateGroupOrderRecapitulation')}>
                            <NyDatePicker
                                disabledDate={disabledDate}
                                style={{ width: '100%' }}
                                onChange={(date: any) => {
                                    setDate(date);
                                }}
                                defaultPickerValue={date}
                                value={date}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default MealGroupOrderIndex;
