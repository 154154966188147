import {ContainerOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {geti18nText, NyRequestResolver, RESPONSE} from '@nybble/nyreact';
import {Alert, Popover, Table, Tooltip} from 'antd';
import moment from 'moment';
import {useEffect, useState} from 'react';
import useEnum from '../../hooks/useEnum';
import {CONSTANTS_REQ} from '../../utils/Constants';
import {getDateFormat} from '../../utils/Utils';

const EmployeeLeaveAvailableDaysInfoIndex = ({ card }: any) => {
    const [data, setData] = useState<any>([]);
    const employeeLeaveType = useEnum('EMPLOYEE_LEAVE_TYPE');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');

    const setPayedLeaveFilterValue = (employeeId: any, year:any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: employeeLeaveType.PAID_LEAVE },
            { field: 'employee_id', condition: 'equals', value: employeeId },
            { field: 'status', condition: 'equals', value: employeeLeaveStatus.CONFIRMED },
            {
                field: 'created',
                condition: 'date_range',
                value: `${getDateFormat(moment(year).startOf('year'), 'yyyy-MM-DD')}, ${getDateFormat(
                    moment(year).endOf('year'),
                    'yyyy-MM-DD'
                )}`,
            },
        ];
    };

    useEffect(() => {
        if (card) {
            importData();
        }
    }, [card]);

    const importData = () => {
        const employeeId = card && card.employee && card.employee.id;
        const year=card && card.dateFrom;
        if (employeeId && card && card.type==employeeLeaveType.PAID_LEAVE) {
            getPayedLeaveData(employeeId, year);
        }
    };

    const getPayedLeaveData = (employeeId: any, year:any) => {
        setData([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_LEAVE.LIST, {
            max: 100,
            orderType: 'desc',
            search: encodeURI(JSON.stringify(setPayedLeaveFilterValue(employeeId, year))),
        }).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content) {
                setData(result.data.content);
            }
        });
    };

    const columns: any = [
        {
            title: geti18nText('employeeLeave.table.column.workingDays'),
            dataIndex: 'workingDays',
            render: (workingDays: any, record: any) => {
                if (record.workingDays) {
                    return workingDays;
                }
            },
        },
        {
            title: geti18nText('employee.leave.from'),
            dataIndex: 'dateFrom',
            render: (dateFrom: any, record: any) => {
                if (record.dateFrom) {
                    return getDateFormat(record.dateFrom, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.leave.to'),
            dataIndex: 'dateTo',
            render: (dateTo: any, record: any) => {
                if (record.dateTo) {
                    return getDateFormat(record.dateTo, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.leave.note'),
            dataIndex: 'description',
            render: (description: any) => {
                return (
                    <Tooltip placement="top" title={description}>
                        {' '}
                        <div style={{ display: 'inline-flex', maxWidth: '400px' }}>
                            <span className={'overview-cards-paragraph'}>{description}</span>
                        </div>
                    </Tooltip>
                );
            },
        },
    ];

    const getLeftDaysCount = () => {
        return (card && card.availableDays ? card.availableDays : 0) - (card && card.workingDays ? card.workingDays : 0);
    };

   return (
        <>
            {/* paid lave history for current year*/}
            {(card && card.type == employeeLeaveType.PAID_LEAVE && data && data.length > 0) && (
                <div>
                    <Popover
                        title={geti18nText('employee.leave.paid.leave.history')}
                        placement="bottomLeft"
                        content={<Table size={'small'} dataSource={data} columns={columns} rowClassName="editable-row" pagination={false} />}
                        style={{ marginLeft: '4rem' }}
                    >
                        <div
                            className={'overview-cards-paragraph-div'}
                            style={{ display: 'flex', margin: '0px' }}
                        >
                            <b style={{ font: 'bold', paddingRight: '4px' }}>
                                <ContainerOutlined style={{ fontSize: '22px' }} />
                            </b>
                            <div className={'overview-cards-paragraph-div'}>
                                <p
                                    className={'overview-cards-paragraph'}
                                    style={{ fontSize: '14px', maxWidth: '300px' }}
                                >
                                    {geti18nText('history.header')}
                                </p>
                            </div>
                        </div>
                    </Popover>
                </div>
            )}
            {/* number of leave days left after confirmation*/}
            {(card && card.employee && card.employee.id && getLeftDaysCount() >= 0 && card.type == employeeLeaveType.ANNUAL_LEAVE) && (
                <div
                    className={'overview-cards-paragraph-div'}
                    style={{ display: 'flex', margin: '0px' }}
                >
                    <>
                        <b style={{ font: 'bold', paddingRight: '4px' }}>
                            <Tooltip
                                placement="top"
                                title={geti18nText('employeeLeave.left.after.approval')}
                            >
                                <ExclamationCircleOutlined style={{ fontSize: '22px' }} />
                            </Tooltip>
                        </b>
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                            title={geti18nText('employeeLeave.left.after.approval') + ': ' + getLeftDaysCount()}
                        >
                            <div className={'overview-cards-paragraph-div'}>
                                <p
                                    className={'overview-cards-paragraph'}
                                    style={{ fontSize: '14px' }}
                                >
                                    {geti18nText('employeeLeave.left.after.approval') + ': ' + getLeftDaysCount()}
                                </p>
                            </div>
                        </Tooltip>
                    </>
                </div>
            )}
            {/* number of exceeded leave days*/}
            {(card && card.workingDays >= 0 && card.availableDays >= 0 && card.workingDays > card.availableDays && card.type == employeeLeaveType.ANNUAL_LEAVE) && (
                <div>
                    <Alert
                        message={geti18nText('employee.leave.exceededDays.warning')}
                        description={geti18nText('employee.leave.days') + ': ' + (card.workingDays - card.availableDays)}
                        type="warning"
                        showIcon={false}
                    />
                </div>
            )}
        </>
    )
};

export default EmployeeLeaveAvailableDaysInfoIndex;
