import {
    BookOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    FilePdfOutlined,
    HourglassOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    geti18nText,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Avatar, Card, Col, Divider, Empty, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EmployeeLeaveAvailableDaysInfoIndex from '../../../../components/employee-leave-available-days-info';
import useEnum from '../../../../hooks/useEnum';
import { refreshItemList } from '../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ, getReportDocumentExtension } from '../../../../utils/Constants';
import {
    errorNotification,
    fileDownload,
    getDateFormat,
    GetEmployeeLeaveTypeCardColor,
    getEmployeeLeaveTypeIcon,
    okNotification,
} from '../../../../utils/Utils';
import EmployeeLeaveReqEdit from '../employee-leave/EmployeeLeaveReqEdit';

const ActionsView = ({ refresh, setRefresh }: any) => {
    const enExportType = useEnum('EXPORT_TYPE');
    const [approvalCardsData, setApprovalCardsData] = useState<any>(undefined);
    const [summaryModalVisible, setSummaryModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [itemValue, setItemValue] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const employeeLeaveType = useEnum('EMPLOYEE_LEAVE_TYPE');

    useEffect(() => {
        getCardsForApprovalData();
    }, [refresh]);

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '0px!important',
        padding: '5px 10px',
    };

    const getCardsForApprovalData = async () => {
        setLoading(true);
        setApprovalCardsData([]);
        await NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_LEAVE.LIST_ACTION, {
            max: 5,
        }).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content) {
                setApprovalCardsData(result.data.content);
            }
            setLoading(false);
        });
    };

    const saveDecision = (values: any, status: any) => {
        const valuesNew = {
            id: values.id,
            status: status,
        };
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.EMPLOYEE_LEAVE.CHANGE_STATUS + '/' + values.id,
            undefined,
            valuesNew
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                setRefresh((refresh: any) => refresh + 1);
                dispatch(refreshItemList());
            } else {
                errorNotification(geti18nText('employee.leave.update.error'));
            }
        });
    };

    const getAvailableDaysInfo = (card: any) => {
        return <EmployeeLeaveAvailableDaysInfoIndex card={card} />;
    };

    const downloadReport = async (card: any) => {
        const employeeId = card?.employee?.id ? card.employee.id : null;
        if (employeeId && card?.annualLeaveYear != undefined) {
            downloadFile(employeeId, card.annualLeaveYear);
        } else {
            errorNotification();
        }
    };

    const getDocumentExtension = () => {
        return getReportDocumentExtension(enExportType.PDF);
    };

    const downloadFile = (employeeId: any, year: any) => {
        let parms = {
            lang: NyUtils.getSelectedLanguage(),
            subreportId: undefined,
            useDefault: false,
            exportType: enExportType.PDF,
            ...{
                employeeId: employeeId,
                year: year,
            },
        };
        let addition = moment().format('yyyyMMDD');
        const fileDownloadName =
            `${geti18nText('employeeAnnualLeave.edit.title')}_${addition}.` + getDocumentExtension();
        fileDownload(CONSTANTS_REQ.REPORT.APPROVED_ANNUAL_LEVE_REPORT, parms, fileDownloadName);
    };

    if (loading) {
        return <NySpinner />;
    } else {
        return (
            <>
                {approvalCardsData && approvalCardsData.length > 0 ? (
                    <Col span={24}>
                        <Row justify="start">
                            {approvalCardsData.map((card: any) => {
                                return (
                                    <Col span={4} className={'travel-warrant-cards-widgets-mid'}>
                                        <Card
                                            headStyle={{
                                                borderBottom: `2px solid ${GetEmployeeLeaveTypeCardColor(card.type)}`,
                                                background: `${GetEmployeeLeaveTypeCardColor(card.type)}11`,
                                            }}
                                            className="ny-travel-warrant-card"
                                            title={[
                                                <>
                                                    {getEmployeeLeaveTypeIcon(card.type, '20px')}{' '}
                                                    {geti18nText('app.enum.EMPLOYEE_LEAVE_TYPE.' + card.type)}
                                                    {card && card.type == employeeLeaveType.ANNUAL_LEAVE && (
                                                        <Tooltip
                                                            title={geti18nText('employeeAnnualLeave.edit.vacation.pdf')}
                                                        >
                                                            <FilePdfOutlined
                                                                style={{
                                                                    marginRight: '5px',
                                                                    float: 'right',
                                                                    marginTop: '10px',
                                                                }}
                                                                className="ny-card-icon"
                                                                onClick={() => {
                                                                    downloadReport(card);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </>,
                                            ]}
                                            bodyStyle={{
                                                overflowY: 'auto',
                                                overflowX: 'hidden',
                                                padding: '4px 5px 0px 5px',
                                                marginTop: '1px',
                                                minHeight: '350px',
                                            }}
                                            type="inner"
                                            actions={[
                                                <NyModalConfirm
                                                    title={geti18nText('employee.leave.cancel.' + card.type)}
                                                    okText={geti18nText('app.default.button.yes')}
                                                    cancelText={geti18nText('app.default.button.no')}
                                                    onConfirm={() => {
                                                        saveDecision(card, employeeLeaveStatus.REJECTED);
                                                    }}
                                                >
                                                    <CloseCircleTwoTone
                                                        twoToneColor="#f54747"
                                                        style={{
                                                            width: '5rem',
                                                            fontSize: '22px',
                                                            color: 'red',
                                                            border: '0rem',
                                                        }}
                                                    />
                                                </NyModalConfirm>,
                                                <NyModalConfirm
                                                    title={geti18nText('employee.leave.confirm.' + card.type)}
                                                    okText={geti18nText('app.default.button.yes')}
                                                    cancelText={geti18nText('app.default.button.no')}
                                                    onConfirm={() => {
                                                        saveDecision(card, employeeLeaveStatus.CONFIRMED);
                                                    }}
                                                >
                                                    <CheckCircleTwoTone
                                                        twoToneColor="#52c41a"
                                                        style={{
                                                            width: '5rem',
                                                            fontSize: '22px',
                                                            color: 'green',
                                                            border: '0rem',
                                                        }}
                                                    />
                                                </NyModalConfirm>,
                                            ]}
                                        >
                                            <Card.Grid
                                                hoverable={false}
                                                style={gridStyle}
                                                className="statistic-card-items"
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                        <Tooltip
                                                            placement="top"
                                                            title={geti18nText('travelWarrant.table.column.employee')}
                                                        >
                                                            {card.person && card.person.fileId ? (
                                                                <Avatar
                                                                    size={24}
                                                                    src={
                                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                        '/' +
                                                                        card.person.fileId +
                                                                        '?tenant=' +
                                                                        NySession.getUser().tenantId
                                                                    }
                                                                />
                                                            ) : (
                                                                <UserOutlined style={{ fontSize: '22px' }} />
                                                            )}
                                                        </Tooltip>
                                                    </b>
                                                    <p
                                                        className={'overview-cards-paragraph'}
                                                        style={{ fontSize: '14px' }}
                                                    >
                                                        {' '}
                                                        {card && ` ${card.person.firstName} ${card.person.lastName}`}
                                                    </p>
                                                </div>
                                                <div
                                                    className={'overview-cards-paragraph-div'}
                                                    style={{ display: 'flex', margin: '0px' }}
                                                >
                                                    <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                        <Tooltip
                                                            placement="top"
                                                            title={geti18nText('holiday.edit.date')}
                                                        >
                                                            <CalendarOutlined style={{ fontSize: '22px' }} />
                                                        </Tooltip>
                                                    </b>
                                                    <Tooltip
                                                        placement="topLeft"
                                                        overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                        title={
                                                            card &&
                                                            card.dateFrom &&
                                                            card.dateTo &&
                                                            getDateFormat(card.dateFrom, 'DD.MM.YYYY') +
                                                                ' - ' +
                                                                getDateFormat(card.dateTo, 'DD.MM.YYYY')
                                                        }
                                                    >
                                                        <div className={'overview-cards-paragraph-div'}>
                                                            <p
                                                                className={'overview-cards-paragraph'}
                                                                style={{ fontSize: '14px' }}
                                                            >
                                                                {card &&
                                                                    getDateFormat(card.dateFrom, 'DD.MM.YYYY') +
                                                                        ' - ' +
                                                                        getDateFormat(card.dateTo, 'DD.MM.YYYY')}
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <div
                                                    className={'overview-cards-paragraph-div'}
                                                    style={{ display: 'flex', margin: '0px' }}
                                                >
                                                    <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                        <Tooltip
                                                            placement="top"
                                                            title={geti18nText(
                                                                'employeeLeave.table.column.workingDays'
                                                            )}
                                                        >
                                                            <HourglassOutlined style={{ fontSize: '22px' }} />
                                                        </Tooltip>
                                                    </b>
                                                    <Tooltip
                                                        placement="topLeft"
                                                        overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                        title={card && card.workingDays}
                                                    >
                                                        <div className={'overview-cards-paragraph-div'}>
                                                            <p
                                                                className={'overview-cards-paragraph'}
                                                                style={{ fontSize: '14px' }}
                                                            >
                                                                {geti18nText('employeeLeave.table.column.workingDays')}{' '}
                                                                {': '}
                                                                {card.workingDays ? card.workingDays : '0'}
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <div
                                                    className={'overview-cards-paragraph-div'}
                                                    style={{ display: 'flex', margin: '0px' }}
                                                >
                                                    <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                        <Tooltip
                                                            placement="top"
                                                            title={geti18nText(
                                                                'employeeAnnualLeave.edit.availableDays'
                                                            )}
                                                        >
                                                            <CheckCircleOutlined
                                                                style={{ fontSize: '22px', color: 'green' }}
                                                            />
                                                        </Tooltip>
                                                    </b>
                                                    <Tooltip
                                                        placement="topLeft"
                                                        overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                        title={card && card.availableDays}
                                                    >
                                                        <div className={'overview-cards-paragraph-div'}>
                                                            <p
                                                                className={'overview-cards-paragraph'}
                                                                style={{ fontSize: '14px' }}
                                                            >
                                                                {geti18nText('employeeAnnualLeave.edit.availableDays')}{' '}
                                                                {': '}
                                                                {card.availableDays >= 0 ? card.availableDays : '0'}
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <Divider plain style={{ marginTop: '1px' }}></Divider>
                                                {card && card.description && (
                                                    <div
                                                        className={'overview-cards-description-div'}
                                                        style={{ display: 'flex', margin: '0px' }}
                                                    >
                                                        <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                            <Tooltip
                                                                placement="top"
                                                                title={geti18nText('employee.leave.note')}
                                                            >
                                                                <BookOutlined style={{ fontSize: '22px' }} />
                                                            </Tooltip>
                                                        </b>
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                            title={card && card.description}
                                                        >
                                                            <div className={'overview-cards-description-div'}>
                                                                <p
                                                                    className={'overview-cards-description-div-inner'}
                                                                    style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                                                                >
                                                                    {card && card.description}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                                {getAvailableDaysInfo(card)}
                                            </Card.Grid>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                ) : (
                    <Empty />
                )}

                <EmployeeLeaveReqEdit
                    value={itemValue}
                    isModal={true}
                    visible={summaryModalVisible}
                    setVisible={setSummaryModalVisible}
                    onSaveAndGetData={undefined}
                ></EmployeeLeaveReqEdit>
            </>
        );
    }
};

export default ActionsView;
