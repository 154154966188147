import { geti18nText } from '@nybble/nyreact';
import { Col, Descriptions, Modal, Row } from 'antd';
import { useState } from 'react';
import { getDateFormat } from '../../../../../utils/Utils';
import ContractJobDocumentIndex from './ContractJobDocumentIndex';

const ContractJobsEdit = ({ job, setJob, contractTypeId, visible, setVisible }: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('contract.jobs.edit.title'));

    function setValues(dataForm: any) {
        if (dataForm.id) {
            setEditHeader(geti18nText('contract.jobs.edit.title') + ' - ' + dataForm.id);
        }
    }

    const onModalClose = () => {
        setVisible(false);
        setJob(null);
        setEditHeader(geti18nText('contract.jobs.edit.title'));
    };

    const onModalOpen = () => {
        setValues(job);
    };

    const getJobDocumentData = () => <ContractJobDocumentIndex jobId={job?.id} />;

    return (
        <Modal
            visible={visible}
            title={editHeader}
            onOk={onModalClose}
            onCancel={onModalClose}
            width={900}
            forceRender={true}
            closable={true}
            maskClosable={false}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText={geti18nText('app.default.button.ok')}
        >
            <>
                <Row gutter={24}>
                    <Col span={24}>
                        <Descriptions column={1} bordered size="small">
                            <Descriptions.Item label={geti18nText('contract.jobs.edit.column.template')}>
                                {job?.contractDocumentTemplate?.name ? job?.contractDocumentTemplate?.name : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('contract.jobs.edit.column.start')}>
                                {job?.start ? getDateFormat(job?.start, 'DD.MM.YYYY HH:mm:ss') : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('contract.jobs.edit.column.end')}>
                                {job?.end ? getDateFormat(job?.end, 'DD.MM.YYYY HH:mm:ss') : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('contract.jobs.edit.column.user')}>
                                {job?.person ? job?.person?.firstName + ' ' + job?.person?.lastName : '-'}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>{getJobDocumentData()}</Col>
                </Row>
            </>
        </Modal>
    );
};

export default ContractJobsEdit;
