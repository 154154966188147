import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import VocationIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import VocationEdit from './edit';

const VocationSearch = ({
    label = geti18nText('employee.edit.professional.vocation'),
    map = { id: 'id', label: 'name', professionalQualification: 'professionalQualification' },
    mustGetPopupContainer = true,
    name = 'vocation',
    required = false,
    SearchPopupDisabled = false,
    clearSearchField,
    disabled = false,
    mode = 'default',
    onChange,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.VOCATION.SEARCH}
                map={map}
                searchBy="name"
                itemName="name"
                mode={mode}
                AddNewModalComponent={VocationEdit}
                SearchPopupComponent={<VocationIndex disabled={SearchPopupDisabled} />}
                AddNewModalComponentAddedData={{ clearSearchField: clearSearchField }}
                mustGetPopupContainer={mustGetPopupContainer}
                disabled={disabled}
                onChange={onChange}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default VocationSearch;
