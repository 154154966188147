import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySearchField,
    NyUtils,
} from '@nybble/nyreact';
import { ExternalFiltersProps } from '@nybble/nyreact/build/ny-data-table/types';
import { Button, Form } from 'antd';
import moment from 'moment';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchPayrollBrut,
    getColumnSearchPointAdditionDecision,
} from '../../../../utils/Filters';
import { customEmployeeRenderOption, fileDownload, getEnumBooleanArray } from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import PointAdditionDecisionEdit from './edit';

const PointAdditionDecisionIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    defaultFilter,
    id,
    nyId = 'point-addition-decision',
    employeeId,
    isPersonal,
    readonly = false,
}: any) => {
    const table = useTableSettings();
    const [filterForm] = Form.useForm();
    useHelper('payroll/codebooks/pointAdditionDecision');

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks() || HumanResourcesRights.canCreateCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks() || HumanResourcesRights.canCreateCodebooks();
    };

    const setDefaultFilter = () => {
        if (id) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'id', condition: 'not_equals', value: id.toString() },
            ];
        } else if (employeeId && !isPersonal) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns = [
        {
            title: geti18nText('pointAdditionDecision.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: true,
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.decisionNumber'),
            dataIndex: 'decisionNumber',
            sorter: true,
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.payrollBrut'),
            dataIndex: ['payrollBrut', 'name'],
            sorter: true,
            ...getColumnSearchPayrollBrut(true),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.validFrom'),
            dataIndex: 'validFrom',
            sorter: true,
            ...getColumnDateOption(true),
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.workRelated'),
            dataIndex: 'workRelated',
            sorter: true,
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            render: (variable: any) => {
                if (variable) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.amount'),
            dataIndex: 'amount',
            width: '5%',
            sorter: true,
            ...getColumnSearch('number'),
            render: (amount: any, record: any) => {
                return <div style={{ float: 'right' }}>{NyUtils.formatNumber(record.amount ?? 0, 2)}</div>;
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record?.businessUnit?.name) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.replacementDecision'),
            dataIndex: ['replacementDecision', 'decisionNumber'],
            sorter: true,
            ...getColumnSearchPointAdditionDecision(),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.fileId'),
            dataIndex: 'fileId',
            width: '7%',
            render: (text: any, record: any) => {
                if (record.fileId) {
                    return (
                        <Button
                            type="primary"
                            ghost
                            icon={<FilePdfOutlined />}
                            style={{
                                marginLeft: '2px',
                            }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                    null,
                                    geti18nText('pointAdditionDecision.edit.title')
                                );
                            }}
                        >
                            {geti18nText('meal.group.order.table.download.pdf')}
                        </Button>
                    );
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (_text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
        },
    ];

    const columnsEmployee = [
        {
            title: geti18nText('pointAdditionDecision.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: true,
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.decisionNumber'),
            dataIndex: ['pointAdditionDecision', 'decisionNumber'],
            sorter: true,
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.payrollBrut'),
            dataIndex: ['payrollBrut', 'name'],
            sorter: true,
            ...getColumnSearchPayrollBrut(true),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.validFrom'),
            dataIndex: ['pointAdditionDecision', 'validFrom'],
            sorter: true,
            ...getColumnDateOption(true),
            render: (text: any, record: any) => {
                if (record?.pointAdditionDecision?.validFrom) {
                    return moment(record.pointAdditionDecision.validFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.workRelated'),
            dataIndex: ['pointAdditionDecision', 'workRelated'],
            sorter: true,
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            render: (variable: any) => {
                if (variable) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.amount'),
            dataIndex: ['pointAdditionDecision', 'amount'],
            sorter: true,
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                return (
                    <div style={{ float: 'right' }}>
                        {NyUtils.formatNumber(record?.pointAdditionDecision?.amount ?? 0, 2)}
                    </div>
                );
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.replacementDecision'),
            dataIndex: ['pointAdditionDecision', 'replacementDecision', 'decisionNumber'],
            sorter: true,
            ...getColumnSearchPointAdditionDecision(),
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.fileId'),
            dataIndex: ['pointAdditionDecision', 'fileId'],
            width: '7%',
            render: (text: any, record: any) => {
                if (record?.pointAdditionDecision?.fileId) {
                    return (
                        <Button
                            type="primary"
                            ghost
                            icon={<FilePdfOutlined />}
                            style={{
                                marginLeft: '2px',
                            }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.pointAdditionDecision.fileId,
                                    null,
                                    geti18nText('pointAdditionDecision.edit.title')
                                );
                            }}
                        >
                            {geti18nText('meal.group.order.table.download.pdf')}
                        </Button>
                    );
                }
            },
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (_text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
        },
    ];

    const customFilterEmployee = (triggerFiltering: any) => {
        return (
            <Form.Item key={'employeeId'} name={'employeeId'} label={geti18nText('workplace.table.column.employee')}>
                <NySearchField
                    onChange={triggerFiltering}
                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                    map={{
                        id: 'id',
                        label: 'text',
                        employmentRecordId: 'employmentRecordId',
                        businessUnit: 'businessUnit',
                    }}
                    searchBy="person.first_name || ' ' || person.last_name"
                    itemName={[
                        ['person', 'firstName'],
                        ['person', 'lastName'],
                    ]}
                    renderOption={customEmployeeRenderOption}
                    customItemNameLabel={'firstName lastName'}
                    SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="point-addition-decision" />}
                    style={{ width: '100%' }}
                    placeholder={geti18nText('travelWarrant.edit.employee')}
                    mode={'multiple'}
                    order="person.last_name, person.first_name"
                    setDefaultFilterValue={() => table.setDefaultFilterValue()}
                    mustGetPopupContainer={false}
                    customListWidth={'1200px'}
                />
            </Form.Item>
        );
    };

    const externalFiltersConfig: ExternalFiltersProps = {
        align: 'middle',
        rowGutter: 24,
        justifyContent: 'start',
        fields: [
            {
                name: 'employeeId',
                render: (triggerFiltering: any) => customFilterEmployee(triggerFiltering),
                condition: 'in',
                colspan: 12,
                order: 0,
                offset: 0,
            },
        ],
    };

    return (
        <NyDataTable
            nyId={nyId}
            url={
                isPersonal
                    ? CONSTANTS_REQ.POINT_ADDITION_DECISION_EMPLOYEE.MY_LIST
                    : employeeId
                    ? CONSTANTS_REQ.POINT_ADDITION_DECISION_EMPLOYEE.LIST
                    : CONSTANTS_REQ.POINT_ADDITION_DECISION.LIST
            }
            addNewButtonText={geti18nText('pointAdditionDecision.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={PointAdditionDecisionEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={employeeId ? columnsEmployee : columns}
            addedData={{ employeeId: employeeId }}
            editProps={{ isPersonal: isPersonal }}
            scroll={scroll}
            readonly={readonly}
            hideButtons={readonly}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilter}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.pointAdditionDecision')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={employeeId ? csvColumnsEmployee() : csvColumns()}
            headerTitle={!employeeId && geti18nText('menu.pointAdditionDecision')}
            externalFiltersConfig={!employeeId ? externalFiltersConfig : undefined}
            filterFormInstance={filterForm}
        />
    );
};
export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('pointAdditionDecision.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.decisionNumber'),
            dataIndex: 'decisionNumber',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.payrollBrut'),
            dataIndex: ['payrollBrut', 'name'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.validFrom'),
            dataIndex: 'validFrom',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.workRelated'),
            dataIndex: 'workRelated',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.amount'),
            dataIndex: 'amount',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.replacementDecision'),
            dataIndex: ['replacementDecision', 'decisionNumber'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnsEmployee: any = () => {
    return [
        {
            title: geti18nText('pointAdditionDecision.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.decisionNumber'),
            dataIndex: ['pointAdditionDecision', 'decisionNumber'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.payrollBrut'),
            dataIndex: ['payrollBrut', 'name'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.validFrom'),
            dataIndex: ['pointAdditionDecision', 'validFrom'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.workRelated'),
            dataIndex: ['pointAdditionDecision', 'workRelated'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.amount'),
            dataIndex: ['pointAdditionDecision', 'amount'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.replacementDecision'),
            dataIndex: ['pointAdditionDecision', 'replacementDecision', 'decisionNumber'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.fileId'),
            dataIndex: ['pointAdditionDecision', 'fileId'],
        },
        {
            title: geti18nText('pointAdditionDecision.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            amount: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            workRelated: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default PointAdditionDecisionIndex;
