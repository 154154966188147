import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import GroupEdit from './edit';

const AuthGroupsIndex = ({ editProps }: any) => {
    const table = useTableSettings();
    const columns = [
        {
            title: geti18nText('user.auth.groups.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('user.auth.groups.table.column.group'),
            dataIndex: ['groups', 'name'],
            width: '40%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('user.auth.groups.table.column.module'),
            dataIndex: ['logicalModule', 'name'],
            width: '40%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('user.auth.groups.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId={'user-groups-administration'}
            url={CONSTANTS_REQ.USER_AUTH.MODULES_LIST}
            setDefaultSortOrder={{ order: 'logicalModule.name', orderType: 'asc' }}
            columns={columns}
            modalComponent={GroupEdit}
            editProps={editProps}
            showRecordModal={true}
            scroll={{ y: 640 }}
            hideNewButton={true}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
        ></NyDataTable>
    );
};

export default AuthGroupsIndex;
