import useHelper from '../../../../../hooks/useHelper';
import { DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import ItemGroupIndex from '../../../../administration/views/item-group';

const ItemGroupMaterial = () => {
    useHelper('inventory/codebooks/item-group-material');

    return <ItemGroupIndex type={2} scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />;
};

export default ItemGroupMaterial;
