import { IApplicationSetting, GetApplicationSetting } from '../utils/ApplicationSettings';

const useApplicationSetting = (
    applicationSettingName: IApplicationSetting['applicationSettingName'],
    returnBoolValue?: boolean
) => {
    return GetApplicationSetting({ applicationSettingName: applicationSettingName }, returnBoolValue);
};

export default useApplicationSetting;
