import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import CurrencyIndex from '.';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import CurrencyEdit from './edit';

const CurrencySearch = ({
    initialValue,
    label = geti18nText('travelWarrantPayment.edit.isoCode'),
    name = 'currency',
    required = false,
    readonly = false,
    onChange,
    nyTestId = 'currency',
    disabled = false,
    noClassName = false,
    mode = 'default',
    search = true,
    edit = true,
    map = { id: 'id', label: 'text', name: 'name' },
    style = {},
    mustGetPopupContainer = false,
}: any) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    return (
        <Form.Item
            initialValue={initialValue ? initialValue : mode == 'multiple' ? [] : null}
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
            style={style}
        >
            <NySearchField
                url={CONSTANTS_REQ.CURRENCY.SEARCH}
                map={map}
                searchBy="nameOrCode"
                SearchPopupComponent={<CurrencyIndex readonly={readonly || !canCreate()} />}
                AddNewModalComponent={canCreate() ? CurrencyEdit : null}
                onChange={onChange}
                nyTestId={nyTestId}
                order="isoCode"
                setDefaultFilterValue={setDefaultFilterValue}
                disabled={disabled}
                className={noClassName ? '' : undefined}
                mode={mode}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default CurrencySearch;
