import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getEnumArray } from '../../../../utils/Utils';
import OrderViewWarehouseEdit from './edit';
import useTableSettings from '../../../../hooks/useTableSettings';

const OrderViewWarehouseIndex = ({
    orderViewId,
    length,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
    setRefresh,
}: any) => {
    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'orderViewId',
                condition: 'equals',
                value: orderViewId,
            },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('warehouse.table.column.id'),
            dataIndex: ['warehouse', 'id'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouse.table.column.code'),
            dataIndex: ['warehouse', 'code'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouse.table.column.name'),
            dataIndex: ['warehouse', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouse.table.column.type'),
            dataIndex: ['warehouse', 'type'],
            render: (text: any, record: any) => {
                if (record.warehouse.type !== undefined) {
                    return geti18nText('app.enum.WAREHOUSE_TYPE.' + record.warehouse.type);
                }
            },
            ...getColumnSearchOption(getEnumArray('WAREHOUSE_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('warehouse.table.column.active'),
            dataIndex: 'active',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="order-view-warehouse"
            url={CONSTANTS_REQ.ORDER_VIEW_WAREHOUSE.LIST}
            hideNewButton={true}
            setDefaultPageSize={table.setDefaultPageSize()}
            fetchWhenChange={length}
            buttonsClassName={'ny-order-view-access-settings-buttons'}
            showRecordModal={true}
            modalComponent={OrderViewWarehouseEdit}
            onSaveAndGetID={(id: any) => {
                if (setRefresh && id) {
                    setRefresh((refresh: any) => refresh + 1);
                }
            }}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={initialColumns}
            shortcuts={true}
            addedData={{ orderViewId: orderViewId }}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
        />
    );
};

export default OrderViewWarehouseIndex;
