import { NySpinner, geti18nText } from '@nybble/nyreact';
import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import TravelWarrantAdditionalInfoViewEdit from './edit';

const TravelWarrantAdditionalInfoViewIndex = ({ data, printPdf, travelWarrantOrdinal, isPlane = false }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [destinations, setDestinations] = useState<any>([]);
    const [destination, setDestination] = useState<any>(null);
    const [item, setItem] = useState<any>(null);

    useEffect(() => {
        getData();
    }, [data]);

    const getData = () => {
        setLoading(true);
        if (data?.length > 0) {
            const newData: any = data.map((dataItem: any) => dataItem.destination);
            setDestinations(newData);
            setItemValue(newData[0]);
        } else {
            setDestinations([]);
            setItemValue(null);
        }
    };

    const setItemValue = (value: any) => {
        if (value) {
            setDestination(value);
            const index = data.findIndex((dat: any) => dat.destination === value);
            setItem(index > -1 && data[index] ? data[index] : null);
        } else {
            setDestination(null);
            setItem(null);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    <div style={{ fontSize: '1.17em', color: printPdf ? 'black' : undefined, marginBottom: '0.5em' }}>
                        <b>{geti18nText('travelWarrantWizard.step.3')}</b>
                    </div>
                    {data?.length > 1 && (
                        <Radio.Group
                            options={destinations}
                            value={destination}
                            onChange={(e: any) => setItemValue(e?.target?.value)}
                            buttonStyle="solid"
                            optionType="button"
                            style={{ marginBottom: '15px' }}
                        />
                    )}
                    <TravelWarrantAdditionalInfoViewEdit
                        item={item}
                        printPdf={printPdf}
                        travelWarrantOrdinal={travelWarrantOrdinal}
                        isPlane={isPlane}
                    />
                </>
            )}
        </React.Fragment>
    );
};

export default TravelWarrantAdditionalInfoViewIndex;
