import { Tooltip } from 'antd';

const NyDescription = ({ title = '', maxWidth = '200px' }: any) => {
    return (
        <>
            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: maxWidth }} title={title}>
                <div style={{ maxWidth: maxWidth, height: '2.5vh' }}>
                    <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</p>
                </div>
            </Tooltip>
        </>
    );
};

export default NyDescription;
