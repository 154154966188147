import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, formatJoppdCodeSearch, okNotification } from '../../../../utils/Utils';

const { Panel } = Collapse;

const PaymentOrderIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();

    const [dataForm, setDataForm] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});
    const [currentData, setCurrentData] = useState<any>(undefined);

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
        }

        setCollapseAll(!collapseAll);
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['paymentOrder'] = isChanged;
        setDirty(tmpDirty);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'PAYMENT_ORDER' }];
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                }
            }
        });
    };

    useEffect(() => {
        if (currentData) {
            setDataForm([...dataForm, ...currentData]);
        }
    }, [currentData]);

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setCurrentData(dataForm);
        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 3) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            } else if (element.dataType === 8 && newDataForm[element.settingKey] != undefined) {
                newDataForm[element.settingKey] = JSON.parse(newDataForm[element.settingKey]);
            } else if (element.dataType === 9 && element.defaultFilter) {
                let defFilter = JSON.parse(element.defaultFilter);
                newDataForm[element.settingKey] = await formatJoppdCodeSearch(
                    newDataForm[element.settingKey],
                    defFilter.page,
                    defFilter.node
                );
            }
        }

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        params.push({ id: item.id, value: value });
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(geti18nText(result.data.error));
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.group.name.NY_ERP_PAYROLL.PAYMENT_ORDER')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_NET_DBTR_REFERENCE'
                                        )}
                                        name="PAYMENT_NET_DBTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_NET_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_NET_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_NET_PROTECTED_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_NET_PROTECTED_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_NET_UNPROTECTED_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_NET_UNPROTECTED_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_CONTRACTOR_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_CONTRACTOR_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_TAX_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_TAX_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_TOWNSHIP_TAX_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_TOWNSHIP_TAX_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_SICK_LEAVE_DBTR_REFERENCE'
                                        )}
                                        name="PAYMENT_SICK_LEAVE_DBTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_SICK_LEAVE_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_SICK_LEAVE_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_TRAVEL_WARRANT_CRTR_REFERENCE'
                                        )}
                                        name="PAYMENT_TRAVEL_WARRANT_CRTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYMENT_ORDER.PAYMENT_TRAVEL_WARRANT_DBTR_REFERENCE'
                                        )}
                                        name="PAYMENT_TRAVEL_WARRANT_DBTR_REFERENCE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    {canCreate() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default PaymentOrderIndex;
