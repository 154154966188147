import { PlusCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Badge, Button, Col, Divider, Row, Timeline, Tooltip } from 'antd';
import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import ReactToPrint from 'react-to-print';
import LineMap from './LineMap';
import TimelineItem from './TimelineItem';

const TravelWarrantMap = ({
    form,
    waypoints,
    setWaypoints,
    maxWaypointsKey,
    setMaxWaypointsKey,
    showMap,
    firstIndex = 1,
    setMapDistance = undefined,
    isPlane = false,
    displayOnly = false,
    disabled = false,
    returnRoute = false,
    returnWaypoints = [],
    setReturnWaypoints,
    returnIntermediateWaypointsInit = undefined,
    onRouteNameChange = undefined,
    hidePrint = false,
    componentRefProp,
    printPdfProp,
    setPrintPdfProp,
    printPdfHeader,
    setChanged,
    showSetChanged = false,
    disableEdit = false,
    isWizard = false,
    getDefaultStartingPoint,
    showCheckboxesDestinationName = false,
    initDestinationNameValues = null,
    destination = '',
    setInitWaypoints,
    setManualWaypointChange,
}: any) => {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    const [map, setMap] = useState<any>(null);
    const returnRoutingMachineRef = useRef<any>();
    const [returnRoutingMachine, setReturnRoutingMachine] = useState<any>(null);

    const routingMachineRef = useRef<any>();
    const [routingMachine, setRoutingMachine] = useState<any>(null);

    const [returnIntermediateWaypoints, setReturnIntermediateWaypoints] = useState<any>([]);
    const [printPdf, setPrintPdf] = useState(false);
    const componentRef = useRef<any>();

    useEffect(() => {
        if (initDestinationNameValues?.length > 0 && destination?.length > 0) {
            const array = initDestinationNameValues.map((item: any) => {
                item.showDestinationName = destination.includes(item.shortName);
                return item;
            });
            setWaypoints(array);
            onSelectRouteName(array, undefined);
            setInitWaypoints(undefined);
        }
    }, [initDestinationNameValues]);

    useEffect(() => {
        setPrintPdf(printPdfProp);
    }, [printPdfProp]);

    useEffect(() => {
        if (returnIntermediateWaypointsInit != null) {
            updateReturnWaypoints(returnIntermediateWaypointsInit);
        }
    }, [returnIntermediateWaypointsInit]);

    useEffect(() => {
        if (returnRoute == true && returnIntermediateWaypointsInit == null) {
            updateReturnWaypoints([]);
        }
        if (returnRoutingMachine != null && map != null) {
            if (returnRoute == false) {
                map.removeControl(returnRoutingMachineRef.current);
            } else {
                map.addControl(returnRoutingMachineRef.current);
            }
        }
    }, [returnRoute]);

    useEffect(() => {
        const res: any = waypoints
            .filter((waypoint: any) => waypoint.lat && waypoint.lng)
            .map((waypoint: any) => L.latLng(waypoint.lat, waypoint.lng));
        if (routingMachineRef.current != null) {
            //routingMachineRef.current.setWaypoints(null);
            routingMachineRef.current.setWaypoints(res);
            setTimeout(FitBounds, 1200);
        }
    }, [waypoints]);

    useEffect(() => {
        if (map != null) {
            setTimeout(FitBounds, 1200);
        }
    }, [map]);

    function FitBounds() {
        setFitBounds(waypoints);
    }
    function setFitBounds(waypoints: any) {
        if (map != null && waypoints != null) {
            var bounds: L.LatLngBounds | undefined = undefined;
            if (Array.isArray(waypoints)) {
                for (let pinData of waypoints) {
                    if (pinData.lat != undefined && pinData.lng != undefined) {
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                        else bounds.extend([pinData.lat, pinData.lng]);
                    }
                }
            }
            if (bounds != undefined) {
                map.fitBounds(bounds);
            }
        }
    }

    const blueIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const mapOptions = {
        center: [45.75219336063106, 15.952148437500002],
        zoom: 7,
        style: { height: '400px', width: '100%' },
        doubleClickZoom: false,
        trackResize: false,
        zoomControl: false,
        touchZoom: true,
        keyboard: false,
        gestureHandling: true,
        attributionControl: false,
    };

    const [distance, setDistance] = useState<any>(0);
    const [distanceReturn, setDistanceReturn] = useState<any>(0);

    useEffect(() => {
        if (returnRoutingMachine != null && map != null) {
            if (isPlane == false) {
                map.removeControl(returnRoutingMachineRef.current);
            }
        }
        if (routingMachine) {
            setRoutingMachine(null);
            routingMachineRef.current = createRoutineMachineLayer();
            setRoutingMachine(routingMachineRef.current);
        }
    }, [isPlane]);

    useEffect(() => {
        let counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        let counterEnd = form.getFieldValue(['counterEnd']) ? form.getFieldValue(['counterEnd']) : 0;
        if (setMapDistance) {
            let dist = 0;
            if (returnRoute == false) {
                setMapDistance(distance);
                dist = distance;
            } else {
                setMapDistance(distance + distanceReturn);
                dist = distance + distanceReturn;
            }
            if (counterStart === 0 && counterEnd === 0) {
                form.setFieldsValue({ distance: dist });
                form.setFieldsValue({ counterEnd: counterStart + dist });
                calculateAmount();
            }
        }
    }, [distance, distanceReturn, returnRoute]);

    function createRoutineMachineLayer() {
        const plan = L.routing.plan(
            waypoints
                .filter((waypoint: any) => waypoint.lat && waypoint.lng)
                .map((waypoint: any) => L.latLng(waypoint.lat, waypoint.lng)),
            {
                createMarker: function (i: any, wp: any, nWps: any) {
                    return L.marker(wp.latLng, { icon: blueIcon });
                },
                routeWhileDragging: true,
            }
        );

        const instance = L.Routing.control({
            router: L.Routing.osrmv1({
                profile: 'car',
            }),
            ...(isPlane && { lineOptions: { styles: { opacity: 0 }, missingRouteStyles: { opacity: 0 } } }),
            show: false,
            addWaypoints: false,
            routeWhileDragging: true,
            fitSelectedRoutes: false,
            showAlternatives: false,
            plan: plan,
        });

        instance.on('routesfound', function (e: any) {
            let distance = Math.floor(e.routes[0].summary.totalDistance / 1000);
            setDistance(distance);
        });

        return instance;
    }

    const calculateAmount = () => {
        let amount = 0;
        let distance = form.getFieldValue(['distance']);
        let price = form.getFieldValue(['price']);
        if (distance && price) {
            amount = distance * price;
        }
        form.setFieldsValue({ amount: amount });
    };

    function createReturnRoutineMachineLayer() {
        const plan = L.routing.plan(
            returnWaypoints
                .filter((waypoint: any) => waypoint.lat && waypoint.lng)
                .map((waypoint: any) => L.latLng(waypoint.lat, waypoint.lng)),
            {
                createMarker: function (i: any, wp: any, nWps: any) {
                    return L.marker(wp.latLng, { icon: blueIcon });
                },
                routeWhileDragging: true,
            }
        );

        const instance = L.Routing.control({
            router: L.Routing.osrmv1({
                profile: 'car',
            }),
            ...(isPlane && { lineOptions: { styles: { opacity: 0 }, missingRouteStyles: { opacity: 0 } } }),
            show: false,
            addWaypoints: false,
            routeWhileDragging: true,
            fitSelectedRoutes: false,
            showAlternatives: false,
            plan: plan,
            lineOptions: {
                styles: [{ color: 'orange' }],
            },
        });

        instance.on('routesfound', function (e: any) {
            let distance = Math.floor(e.routes[0].summary.totalDistance / 1000);
            setDistanceReturn(distance);
        });

        return instance;
    }

    useEffect(() => {
        if (!map) return;

        routingMachineRef.current = createRoutineMachineLayer();
        setRoutingMachine(routingMachineRef.current);

        if (!isPlane) {
            returnRoutingMachineRef.current = createReturnRoutineMachineLayer();
            setReturnRoutingMachine(returnRoutingMachineRef.current);
            if (returnIntermediateWaypointsInit != null) {
                updateReturnWaypoints(returnIntermediateWaypointsInit);
            }
        }
    }, [map, waypoints]);

    useEffect(() => {
        if (!returnRoutingMachine) return;
        if (returnRoutingMachine && returnRoute == true) {
            returnRoutingMachine.addTo(map);
        }
    }, [returnRoutingMachine]);

    useEffect(() => {
        if (!routingMachine) return;
        if (routingMachine) {
            routingMachine.addTo(map);
        }
    }, [routingMachine]);

    const updateManualChange = () => {
        if (setManualWaypointChange) {
            setManualWaypointChange(true);
        }
    };

    // SEARCH
    const onSelect = (value: any, option: any) => {
        if (value && option.lat && option.lng) {
            let tmpWaypoints = [...waypoints];
            const index = tmpWaypoints.findIndex((item: any) => item.id === option.waypointId);
            if (index > -1) {
                if (tmpWaypoints[index] === '-') {
                    tmpWaypoints[index] = {};
                }
                if (typeof tmpWaypoints[index] === 'string' || tmpWaypoints[index] instanceof String) {
                    tmpWaypoints[index] = {};
                }
                tmpWaypoints[index].lat = option.lat;
                tmpWaypoints[index].lng = option.lng;
                tmpWaypoints[index].name = option.name;
                let tmpShortName = option.name.split(',');
                tmpWaypoints[index].shortName = tmpShortName[0];
                tmpWaypoints[index].placeId = option.placeId;
                tmpWaypoints[index].countryCode = option.countryCode;
                tmpWaypoints[index].city =
                    option?.address?.city ?? option?.address?.town ?? option?.address?.municipality ?? '';
                tmpWaypoints[index].showDestinationName = option.showDestinationName;
                setWaypoints(tmpWaypoints);
                if (showSetChanged) {
                    setChanged(true);
                }
                updateReturnWaypoints(returnIntermediateWaypointsInit);
                updateManualChange();
            }
            onSelectRouteName();
        }
    };

    const onSelectReturn = (value: any, option: any) => {
        if (value && option.lat && option.lng) {
            let tmpWaypoints = [...returnIntermediateWaypoints];
            const index = tmpWaypoints.findIndex((item: any) => item.id === option.waypointId);
            if (index > -1) {
                if (tmpWaypoints[index] === '-') {
                    tmpWaypoints[index] = {};
                }
                if (typeof tmpWaypoints[index] === 'string' || tmpWaypoints[index] instanceof String) {
                    tmpWaypoints[index] = {};
                }
                tmpWaypoints[index].lat = option.lat;
                tmpWaypoints[index].lng = option.lng;
                tmpWaypoints[index].name = option.name;
                let tmpShortName = option.name.split(',');
                tmpWaypoints[index].shortName = tmpShortName[0];
                tmpWaypoints[index].placeId = option.placeId;
                tmpWaypoints[index].countryCode = option.countryCode;
                tmpWaypoints[index].city =
                    option?.address?.city ?? option?.address?.town ?? option?.address?.municipality ?? '';
                tmpWaypoints[index].showDestinationName = option.showDestinationName;
                updateReturnWaypoints(tmpWaypoints);
                if (showSetChanged) {
                    setChanged(true);
                }
            }
        }
    };

    const onClear = (waypointId: any) => {
        const array = waypoints.filter((row: any) => row.id !== waypointId);
        setWaypoints(array);
        onSelectRouteName(array, undefined);
        updateManualChange();
    };

    const onClearReturn = (waypointId: any) => {
        updateReturnWaypoints(returnIntermediateWaypoints.filter((row: any) => row.id !== waypointId));
        onSelectRouteName(
            undefined,
            returnIntermediateWaypoints.filter((row: any) => row.id !== waypointId)
        );
    };

    const onWaypointCheckboxChange = (waypointId: any, value: any) => {
        const array = waypoints.map((item: any) => {
            if (item.id == waypointId) {
                item.showDestinationName = value;
            }
            return item;
        });
        setWaypoints(array);
        onSelectRouteName(array, undefined);
        updateManualChange();
    };

    const onWaypointCheckboxChangeReturn = (waypointId: any, value: any) => {
        const array = returnIntermediateWaypoints.map((item: any) => {
            if (item.id == waypointId) {
                item.waypointCheckedDestinationName = value;
            }
            return item;
        });
        updateReturnWaypoints(array);
        onSelectRouteName(array, undefined);
    };

    function updateReturnWaypoints(points: any) {
        setReturnIntermediateWaypoints(points != null ? points : []);
        const start = waypoints[0] != null ? waypoints[0] : [];
        const end = waypoints[waypoints.length - 1] ? waypoints[waypoints.length - 1] : [];
        if (setReturnWaypoints) {
            const pointsList = points == null ? [] : points;
            const waypoints = [end].concat(pointsList).concat([start]);
            setReturnWaypoints(waypoints);
            const res = waypoints
                .filter((waypoint: any) => waypoint.lat && waypoint.lng)
                .map((waypoint: any) => L.latLng(waypoint.lat, waypoint.lng));
            if (returnRoutingMachineRef.current != null) {
                returnRoutingMachineRef.current.setWaypoints(res);
            }
        }
        onSelectRouteName();
    }

    const addDestination = (event: any, index: any) => {
        event.stopPropagation();
        if (waypoints.length - 1 === index) {
            const lastElement = waypoints.at(-1);
            waypoints.pop();
            waypoints.push({
                id: maxWaypointsKey + 1,
                placeId: undefined,
                lat: undefined,
                lng: undefined,
                name: '',
            });

            setWaypoints([...waypoints, lastElement]);
            setMaxWaypointsKey(maxWaypointsKey + 1);
        } else {
            setWaypoints([
                ...waypoints,
                {
                    id: maxWaypointsKey,
                    placeId: undefined,
                    lat: undefined,
                    lng: undefined,
                    name: '',
                },
            ]);
            setMaxWaypointsKey(maxWaypointsKey + 1);
        }
        updateManualChange();
    };

    const capitalize = (string: any) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    function onNameChange(value: any, index: number, isReturnPoint: boolean) {
        value = value === '' ? ' ' : capitalize(value);
        let name = routName();
        let travelRelationArray = name.split(' - ');
        if (isReturnPoint == false) {
            travelRelationArray[index] = value;
            if (index == 0 && returnRoute == true) {
                travelRelationArray[travelRelationArray.length - 1] = value;
            }
        } else {
            if (index === -1) {
                travelRelationArray[travelRelationArray.length - 1] = value;
                travelRelationArray[0] = value;
            }
        }

        form.setFieldsValue({ name: travelRelationArray.join(' - ') });
    }
    function onSelectRouteName(arra1: any = undefined, arra2: any = undefined) {
        const route: any = routName(arra1, arra2);
        if (onRouteNameChange != undefined) {
            onRouteNameChange(route);
        }
        form.setFieldsValue({ name: route });
    }

    function routName(arra1: any = undefined, arra2: any = undefined) {
        arra1 = arra1 != undefined ? arra1 : waypoints;
        arra2 = arra2 != undefined ? arra2 : returnIntermediateWaypoints;

        let travelRelationArray1 = arra1.map((x: any) => x.shortName);
        let travelRelationArray = travelRelationArray1.filter((x: any) => x != undefined);
        let res = travelRelationArray.join(' - ');

        if (returnRoute == true) {
            let travelReturnRelationArray1 = arra2.map((x: any) => x.shortName);
            let travelReturnRelationArray = travelReturnRelationArray1.filter((x: any) => x != undefined);
            if (travelReturnRelationArray.length > 0) {
                res += ' - ';
                res += travelReturnRelationArray.join(' - ');
            }
            res += ' - ' + travelRelationArray[0];
        }

        return res;
    }

    const getResetButton = () => (
        <>
            {getDefaultStartingPoint && !disableEdit && !disabled && !(printPdf || printPdfProp) && (
                <Button onClick={getDefaultStartingPoint}>
                    {geti18nText('travel.warrant.edit.map.reset.waypoints')}
                </Button>
            )}
        </>
    );

    return (
        <>
            <div
                ref={componentRefProp ? componentRefProp : componentRef}
                className={printPdf || printPdfProp ? 'travel-warrant-card-print-margin' : ''}
            >
                {(printPdf || printPdfProp) && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Divider orientation="left">
                                <b>{printPdfHeader}</b>
                            </Divider>
                        </Col>
                    </Row>
                )}
                {showMap && (
                    <Row gutter={24} justify={'center'}>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={displayOnly || printPdf || printPdfProp ? 24 : 15}
                            xl={displayOnly || printPdf || printPdfProp ? 24 : 15}
                        >
                            <MapContainer {...(mapOptions as any)} whenCreated={setMap}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LineMap waypoints={waypoints} isPlane={isPlane} />
                            </MapContainer>
                            <Badge status="error" text={geti18nText('travelWarrantRelation.legend.departureRoute')} />
                            {returnRoute == true && (
                                <Badge
                                    status="warning"
                                    text={geti18nText('travelWarrantRelation.legend.returnRoute')}
                                    style={{ marginLeft: '20px' }}
                                />
                            )}
                        </Col>
                        {!displayOnly && (
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={printPdf || printPdfProp ? 24 : 9}
                                xl={printPdf || printPdfProp ? 24 : 9}
                            >
                                <div style={{ marginBottom: '30px' }}>
                                    <Timeline
                                        style={{
                                            paddingTop: '15px',
                                            marginLeft: printPdf || printPdfProp ? '8px' : '',
                                            marginRight: printPdf || printPdfProp ? '24px' : '',
                                        }}
                                    >
                                        {waypoints.map((value: any, index: any) => (
                                            <>
                                                {!disabled && waypoints.length - 1 === index && (
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            height: '40px',
                                                            marginBottom: '30px',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={geti18nText(
                                                                'travelWarrantRelation.addIntermediateDestination'
                                                            )}
                                                        >
                                                            <Button
                                                                style={{ float: 'left', left: '-9px' }}
                                                                icon={
                                                                    printPdf || printPdfProp ? (
                                                                        ''
                                                                    ) : (
                                                                        <PlusCircleOutlined />
                                                                    )
                                                                }
                                                                onClick={(event: any) => {
                                                                    addDestination(event, index);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}

                                                <TimelineItem
                                                    waypointId={value.id}
                                                    onSelect={onSelect}
                                                    onClear={onClear}
                                                    last={waypoints.length - 1 === index}
                                                    form={form}
                                                    index={index}
                                                    onNameChange={onNameChange}
                                                    defaultValue={{
                                                        waypointId: value.waypointId,
                                                        key: value.placeId,
                                                        value: value.placeId,
                                                        lat: value.lat,
                                                        lng: value.lng,
                                                        name: value.name,
                                                        label: value.name,
                                                        placeId: value.placeId,
                                                    }}
                                                    disabled={disabled}
                                                    isPrint={printPdf || printPdfProp}
                                                    disableEdit={disableEdit}
                                                    showCheckboxesDestinationName={showCheckboxesDestinationName}
                                                    waypointShowDestinationName={value.showDestinationName}
                                                    onWaypointCheckboxChange={onWaypointCheckboxChange}
                                                />
                                            </>
                                        ))}
                                    </Timeline>
                                    {returnRoute == true && (
                                        <Timeline
                                            style={{
                                                paddingTop: '15px',
                                                marginLeft: printPdf || printPdfProp ? '8px' : '',
                                                marginRight: printPdf || printPdfProp ? '24px' : '',
                                            }}
                                        >
                                            {!displayOnly && !disabled && (
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        height: '40px',
                                                        marginBottom: '30px',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title={geti18nText('travelWarrantRelation.addReturnPoint')}
                                                    >
                                                        <Button
                                                            style={{ float: 'left', left: '-9px', color: 'orange' }}
                                                            icon={
                                                                printPdf || printPdfProp ? '' : <PlusCircleOutlined />
                                                            }
                                                            onClick={(event: any) => {
                                                                event.stopPropagation();

                                                                setReturnIntermediateWaypoints([
                                                                    ...returnIntermediateWaypoints,
                                                                    {
                                                                        id: maxWaypointsKey,
                                                                        placeId: undefined,
                                                                        lat: undefined,
                                                                        lng: undefined,
                                                                        name: '',
                                                                    },
                                                                ]);
                                                                setMaxWaypointsKey(maxWaypointsKey + 1);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            {returnIntermediateWaypoints &&
                                                returnIntermediateWaypoints.map((value: any, index: any) => (
                                                    <TimelineItem
                                                        waypointId={value.id}
                                                        onSelect={onSelectReturn}
                                                        onClear={onClearReturn}
                                                        last={waypoints.length - 1 === index}
                                                        form={form}
                                                        index={index}
                                                        onNameChange={onNameChange}
                                                        defaultValue={{
                                                            waypointId: value.waypointId,
                                                            key: value.placeId,
                                                            value: value.placeId,
                                                            lat: value.lat,
                                                            lng: value.lng,
                                                            name: value.name,
                                                            label: value.name,
                                                            placeId: value.placeId,
                                                            type: 'return',
                                                        }}
                                                        disabled={disabled}
                                                        returnRoute={true}
                                                        isPrint={printPdf || printPdfProp}
                                                        disableEdit={disableEdit}
                                                    />
                                                ))}
                                            <TimelineItem
                                                waypointId={waypoints[0]?.id}
                                                onSelect={onSelect}
                                                onClear={onClear}
                                                last={false}
                                                form={form}
                                                index={-1}
                                                returnRoute={true}
                                                onNameChange={onNameChange}
                                                defaultValue={{
                                                    waypointId: waypoints[0]?.waypointId,
                                                    key: waypoints[0]?.placeId,
                                                    value: waypoints[0]?.placeId,
                                                    lat: waypoints[0]?.lat,
                                                    lng: waypoints[0]?.lng,
                                                    name: waypoints[0]?.name,
                                                    label: waypoints[0]?.name,
                                                    placeId: waypoints[0]?.placeId,
                                                }}
                                                disabled={disabled}
                                                isPrint={printPdf || printPdfProp}
                                                disableEdit={disableEdit}
                                            />
                                        </Timeline>
                                    )}
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '0px',
                                        marginLeft: printPdf || printPdfProp ? '34px' : '',
                                    }}
                                >
                                    <h4>
                                        {geti18nText('travelWarrantRelation.edit.road.distance') + ': '}{' '}
                                        {returnRoute == true ? distance + distanceReturn : distance} km
                                    </h4>
                                    {getResetButton()}
                                </div>
                            </Col>
                        )}
                    </Row>
                )}{' '}
                {!showMap && (
                    <>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Timeline
                                    style={{
                                        paddingTop: '15px',
                                        marginLeft: printPdf || printPdfProp ? '8px' : '',
                                        marginRight: printPdf || printPdfProp ? '24px' : '',
                                    }}
                                >
                                    {waypoints.map((value: any, index: any) => (
                                        <>
                                            {!disabled && waypoints.length - 1 === index && (
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        height: '40px',
                                                        marginBottom: '30px',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title={geti18nText('travelWarrantRelation.addDestination')}
                                                    >
                                                        <Button
                                                            style={{ float: 'left', left: '-9px' }}
                                                            icon={
                                                                printPdf || printPdfProp ? '' : <PlusCircleOutlined />
                                                            }
                                                            onClick={(event: any) => {
                                                                addDestination(event, index);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <TimelineItem
                                                waypointId={value.id}
                                                onSelect={onSelect}
                                                onClear={onClear}
                                                last={waypoints.length - 1 === index}
                                                form={form}
                                                index={index - firstIndex}
                                                onNameChange={onNameChange}
                                                defaultValue={{
                                                    waypointId: value.waypointId,
                                                    key: value.placeId,
                                                    value: value.placeId,
                                                    lat: value.lat,
                                                    lng: value.lng,
                                                    name: value.name,
                                                    label: value.name,
                                                    placeId: value.placeId,
                                                }}
                                                disabled={disabled}
                                                isPrint={printPdf || printPdfProp}
                                                disableEdit={disableEdit}
                                                showCheckboxesDestinationName={showCheckboxesDestinationName}
                                                waypointShowDestinationName={value.showDestinationName}
                                                onWaypointCheckboxChange={onWaypointCheckboxChange}
                                            />
                                        </>
                                    ))}
                                </Timeline>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>{getResetButton()} </Col>
                        </Row>
                    </>
                )}
            </div>

            {!hidePrint && (
                <div>
                    <ReactToPrint
                        trigger={() => {
                            return (
                                <Button key={'print'} style={{ float: 'right' }} icon={<PrinterOutlined />}>
                                    {geti18nText('travelWarrant.action.pdf.short')}
                                </Button>
                            );
                        }}
                        onBeforeGetContent={() => {
                            setPrintPdf(true);
                            if (printPdfProp && setPrintPdfProp) setPrintPdfProp(true);
                            return Promise.resolve();
                        }}
                        onAfterPrint={() => {
                            setPrintPdf(false);
                            if (printPdfProp && setPrintPdfProp) setPrintPdfProp(false);
                        }}
                        content={() => componentRef.current}
                        pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  
                  }`}
                    />
                </div>
            )}
        </>
    );
};

export default TravelWarrantMap;
