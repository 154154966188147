import { geti18nText, NySearchField } from '@nybble/nyreact';
import EducationalInstitutionIndex from './index';
import EducationalInstitutionEdit from './edit';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const EducationalInstitutionSearch = ({
    classname = undefined,
    disabled = false,
    formStyle = null,
    label = geti18nText('formal.education.edit.educational.institution'),
    map = { id: 'id', label: 'name' },
    mustGetPopupContainer = true,
    SearchPopupComponent = <EducationalInstitutionIndex />,
    AddNewModalComponent = EducationalInstitutionEdit,
    name = 'educationalInstitution',
    nyTestId = 'educationalInstitution',
    renderOption = undefined,
    required = false,
    setDefaultFilterValue = null,
    style = undefined,
    customListWidth = '1200px',
    dropdownMatchSelectWidth = undefined,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            style={formStyle}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.EDUCATIONAL_INSTITUTION.SEARCH}
                map={map}
                searchBy="name"
                mustGetPopupContainer={mustGetPopupContainer}
                SearchPopupComponent={SearchPopupComponent}
                AddNewModalComponent={AddNewModalComponent}
                nyTestId={nyTestId}
                setDefaultFilterValue={setDefaultFilterValue}
                disabled={disabled}
                className={classname}
                renderOption={renderOption}
                style={style}
                customListWidth={customListWidth}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            />
        </Form.Item>
    );
};

export default EducationalInstitutionSearch;
