import { EllipsisOutlined, UserAddOutlined } from '@ant-design/icons';
import { NyDataEdit, NyInputNumber, NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Divider, Dropdown, Form, Input, Menu, Row } from 'antd';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import UsersEdit from '../../../administration/views/users/edit';
import { BusinessUnitSearch } from '../business-unit/search';
import EmployeeDeleveragingReportModal from '../employee/EmployeeDeleveragingReportModal';
import RoomSearch from '../room/search';
import VocationSearch from '../vocation/search';
import { AdministrationRights } from '../../../../rights/administrationRights';

const EmployeeSimpleEdit = (props: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [editHeader, setEditHeader] = useState(geti18nText('employee.edit.new'));
    const [dataForm, setDataForm] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [isUser, setIsUser] = useState<any>(true);
    const [isFormChange, setIsFormChange] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [stateIndex, setStateIndex] = useState(0);
    const [helperUrl, setHelperUrl] = useState<any>(undefined);
    const [hasEmail, setHasEmail] = useState(false);
    const [fileList, setFileList] = useState<any>([]);
    const fileUploadRef = useRef<any>();
    const [employeeId, setEmployeeId] = useState();
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const [userFrameVisible, setUserFrameVisible] = useState(false);
    const [employee, setEmployee] = useState<any>(null);

    const getEnableField = () => {
        return !(
            NySession.hasAnyRole(['ROLE_MOBILIZATION_EMPLOYEE_VIEW']) &&
            !NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_CODEBOOKS_EDIT'])
        );
    };

    const enableField = getEnableField();

    const canCreateAttendance = () => {
        return HumanResourcesRights.canCreateAttendanceRegistration();
    };

    const canCreateEmployee = () => {
        return HumanResourcesRights.isAdmin() || HumanResourcesRights.canCreateEmployee();
    };

    const canCreateUser = () => {
        return AdministrationRights.isAuthUser();
    };

    const canCreate = () => {
        return canCreateEmployee() || canCreateAttendance();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        let object: any = {};
        let externalUserData: any = {};

        checkUser(dataForm.id);
        setEmployeeId(dataForm.id);
        externalUserData.employeeId = dataForm.id;
        externalUserData.applicationKey = NySession.getSetting('application-key');

        if (dataForm.person) {
            setEditHeader(
                geti18nText('employee.edit.title') + ' - ' + `${dataForm.person.firstName} ${dataForm.person.lastName}`
            );
            object.firstName = dataForm.person.firstName;
            object.lastName = dataForm.person.lastName;
        }
        if (dataForm.employmentRecordId) {
            externalUserData.refId = dataForm.employmentRecordId;
        }

        setHasEmail(dataForm.contact && dataForm.contact.email ? true : false);

        if (dataForm.contact && dataForm.contact.email) {
            object.email = dataForm.contact.email;
        }

        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }

        if (dataForm.person && dataForm.person.fileId) {
            let files = [
                {
                    id: dataForm.person.fileId,
                    uid: dataForm.person.fileId,
                    name: dataForm.person.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.person.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(files);
        }

        if (NySession.getUser().tenantId != null) {
            externalUserData.tenantId = NySession.getUser().tenantId;
        }

        object.externalUserData = externalUserData;
        setHelperUrl(object);
        setEmployee({ ...dataForm, employee: dataForm, employeeId: dataForm.id });
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const checkUser = (id: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_IS_USER + '/' + id, undefined).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (
                    result.data != undefined &&
                    result.data != null &&
                    result.data.id != undefined &&
                    result.data.id != null
                ) {
                    setIsUser(true);
                } else {
                    setIsUser(false);
                }
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setEditHeader(geti18nText('employee.edit.new'));
        setIsUser(true);
        setFileList([]);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const actionsMenu = (
        <Menu>
            {canCreateUser() && (
                <div style={{ padding: '0px', textAlign: 'center' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            onClick={() => setUserFrameVisible(true)}
                            icon={<UserAddOutlined />}
                            style={{ width: '100%' }}
                            type={'primary'}
                        >
                            {geti18nText('employee.edit.createUser')}
                        </Button>
                    </div>
                </div>
            )}
            {canCreate() && (
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <EmployeeDeleveragingReportModal employeeId={dataForm} />
                    </div>
                </div>
            )}
        </Menu>
    );

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            {(canCreate() || canCreateUser()) && !isUser && id !== 'create' && (
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']} overlayStyle={{ zIndex: '1000' }}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            )}
            {canCreateEmployee() && (
                <Button
                    type="primary"
                    onClick={() => {
                        if (dataForm) {
                            history.push('/human/employee/' + dataForm);
                        } else {
                            history.push('/human/employee/create');
                        }
                        onModalClose();
                    }}
                >
                    {geti18nText('employee.edit.skip')}
                </Button>
            )}
        </div>
    );

    return (
        <React.Fragment>
            <NyDataEdit
                layout="vertical"
                editHeader={editHeader}
                width={900}
                loading={loading}
                setLoading={setLoading}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_SIMPLE.EDIT}
                setValues={setValues}
                fetchWhenChange={id}
                checkIsFormChanged={false}
                form={form}
                buttonsClassName="buttons-sticky"
                setIsFormChange={setIsFormChange}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate()}
                paramsId={id}
                {...props}
                onSaveAndGetID={(id: any) => {
                    if (fileUploadRef?.current) {
                        fileUploadRef.current.filesUpload(id);
                    }
                }}
                normalize={(values: any) => {
                    if (fileList.length > 0 && fileList[0]) {
                        values.person.fileId = fileList[0].id;
                    }

                    if (values.vocation) {
                        if (values.vocation.id === -1) {
                            delete values.vocation;
                        } else {
                            values.vocation = getSearchFormat(values.vocation);
                        }
                    }
                    if (values.businessUnit) {
                        if (values.businessUnit.id === -1) {
                            delete values.businessUnit;
                        } else {
                            values.businessUnit = getSearchFormat(values.businessUnit);
                        }
                    }
                    if (values.room) {
                        if (values.room.id === -1) {
                            delete values.room;
                        } else {
                            values.room = getSearchFormat(values.room);
                        }
                    }

                    return values;
                }}
                customFooterContent={getCustomFooterContent}
                shortcuts={true}
            >
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <NyImageUpload
                                    disabled={!enableField}
                                    documentType={documentTypeEnum.PERSON_IMAGE}
                                    files={fileList}
                                    setFiles={setFileList}
                                    cropShape={'round'}
                                    ref={fileUploadRef}
                                />
                            </Col>
                            <Col span={12}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item name={['person', 'id']} style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('person.edit.firstName')}
                                            name={['person', 'firstName']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input ref={focusInput} disabled={!enableField} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('person.edit.lastName')}
                                            name={['person', 'lastName']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input disabled={!enableField} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Divider>{geti18nText('employeeSimple.divider.1')}</Divider>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <VocationSearch
                                    label={geti18nText('employee.edit.professional.vocation')}
                                    map={{ id: 'id', label: 'name' }}
                                    name={'vocation'}
                                    disabled={!enableField}
                                />
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employee.edit.eployment.record')}
                                    name={'employmentRecordId'}
                                >
                                    <NyInputNumber
                                        leftToRight={numbersWriting}
                                        min={0}
                                        nyTestId="employment-record-id"
                                        style={{ width: '100%' }}
                                        formatter={false}
                                        disabled={!enableField}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <BusinessUnitSearch formItemName={'businessUnit'} disabled={!enableField} />
                            </Col>
                            <Col span={12}>
                                <RoomSearch
                                    label={geti18nText('employee.edit.employment.room')}
                                    map={{ id: 'id', label: 'name' }}
                                    name={['room']}
                                    searchBy="name"
                                    disabled={!enableField}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Divider>{geti18nText('employeeSimple.divider.2')}</Divider>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('employee.contact.edit.email')}
                                    name={['contact', 'email']}
                                    rules={[
                                        {
                                            type: 'email',
                                            message: geti18nText('app.default.valid.email'),
                                        },
                                    ]}
                                >
                                    <Input disabled={!enableField} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('employee.contact.edit.mobile')}
                                    name={['contact', 'mobile']}
                                >
                                    <Input disabled={!enableField} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('employee.contact.edit.phone')}
                                    name={['contact', 'phone']}
                                >
                                    <Input disabled={!enableField} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('employee.contact.edit.contact')}
                                    name={['contact', 'shortNumber']}
                                >
                                    <Input disabled={!enableField} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </NyDataEdit>
            <UsersEdit
                isModal
                visible={userFrameVisible}
                setVisible={setUserFrameVisible}
                addedData={{ employee: employee, employeeId: employee?.id }}
            />
        </React.Fragment>
    );
};
export default EmployeeSimpleEdit;
