import {
    NyDataTable,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat, getEnumArray, getEnumArrayStatus, getTaskApprovalStatusIcon } from '../../../../../utils/Utils';
import TaskApprovalModal from '../modal/TaskApprovalModal';

const TaskListIndexApproval = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    columnsType = 'my',
    type = 2,
    scroll = { y: 540, x: 1450 },
    sortOrder,
}: any) => {
    const location: any = useLocation();
    const history = useHistory();
    const table = useTableSettings();
    const enTaskApprovalStatus = useEnum('TASK_APPROVAL_STATUS');
    const [selectedTaskApprovalUser, setSelectedTaskApprovalUser] = useState<any>(null);
    const [visible, setVisible] = useState(false);
    const [refreshTable, setRefreshTable] = useState<any>(0);
    const [data, setData] = useState<any>(null);

    useHelper('task_service/task-list');

    useEffect(() => {
        if (location?.state?.id && data?.length > 0) {
            setSelectedTaskApprovalUser(data.filter((item: any) => item.id === location?.state?.id).at(0));
            setVisible(true);
            delete location.state;
        } /* else { */
        //     const tab = tabs.filter((tab: any) => tab.path === active.toString()).at(0);
        //     if (tab?.state?.id && data?.length > 0) {
        //         dispatch(updateTab({ title: tab.title, path: tab.path, key: tab.key, state: null }));
        //         setSelectedTaskApprovalUser(data.filter((item: any) => item.id === tab?.state?.id).at(0));
        //         setVisible(true);
        //     }
        // }
    }, [location, /* active, tabs, */ data]);

    useEffect(() => {
        setRefreshTable((prevValue: any) => prevValue + 1);
    }, [refresh]);

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const changeStatus = (record: any) => {
        setSelectedTaskApprovalUser(record);
        setVisible(true);
    };

    const columns = [
        ...[
            {
                title: geti18nText('tasks.list.column.id'),
                dataIndex: 'id',
                width: '4%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('tasks.list.column.taskApprovalTaskName.approval'),
                dataIndex: ['taskApprovalTask', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record?.taskApprovalTask) {
                        return record.taskApprovalTask.name;
                    }
                },
            },
            {
                title: geti18nText('tasks.list.column.taskApprovalTaskDescription.approval'),
                dataIndex: ['taskApprovalTask', 'description'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (description: any, record: any) => {
                    if (record?.taskApprovalTask?.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '200px' }}
                                title={record.taskApprovalTask.description}
                            >
                                <div className={'ny-table-paragraph-div'}>
                                    <p className={'ny-table-paragraph'}>
                                        {record && record.taskApprovalTask.description}
                                    </p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('tasks.list.column.taskApprovalTaskType'),
                dataIndex: ['taskApprovalTask', 'type'],
                width: '8%',
                render: (text: any, record: any) => {
                    if (record?.taskApprovalTask?.type) {
                        return geti18nText('app.enum.SERVICE_TASK_TYPES.' + record?.taskApprovalTask?.type);
                    }
                },
                ...getColumnSearchCheckbox(getEnumArray('SERVICE_TASK_TYPES')),
            },
        ],
        ...(columnsType == 'all'
            ? [
                  {
                      title: geti18nText('tasks.list.column.taskApprovalTaskUser'),
                      dataIndex: [`concat(usersPerson.first_name,' ', usersPerson.last_name)`],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.usersPerson) {
                              return record.userEmployee
                                  ? record.usersPerson?.firstName +
                                        ' ' +
                                        record.usersPerson?.lastName +
                                        ' (' +
                                        record.userEmployee?.employmentRecordId +
                                        ')'
                                  : record.usersPerson?.firstName + ' ' + record.usersPerson?.lastName;
                          }
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('tasks.list.column.taskApprovalTaskLevel'),
                dataIndex: ['taskApproval', 'level'],
                sorter: (a: any, b: any) => {},
                width: '8%',
                ...getColumnSearch('number'),
                render: (text: any, record: any) => {
                    if (record.taskApproval) {
                        return record.taskApproval.level;
                    }
                },
            },
            {
                title: geti18nText('tasks.list.column.taskApprovalTaskCreated'),
                dataIndex: 'created',
                sorter: (a: any, b: any) => {},
                width: '10%',
                ...getColumnDateOption(),
                render: (text: any, record: any) => {
                    if (record.created) {
                        return getDateFormat(record?.created, 'DD.MM.YYYY HH:mm');
                    }
                },
            },
            {
                title: geti18nText('tasks.list.column.taskApprovalTaskUpdated'),
                dataIndex: 'updated',
                sorter: (a: any, b: any) => {},
                width: '10%',
                ...getColumnDateOption(),
                render: (text: any, record: any) => {
                    if (record.updated) {
                        return getDateFormat(record?.updated, 'DD.MM.YYYY HH:mm');
                    }
                },
            },
            {
                title: geti18nText('tasks.list.column.status'),
                dataIndex: ['taskApproval', 'status'],
                width: '178px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchCheckbox(getEnumArrayStatus('TASK_APPROVAL_STATUS')),
                render: (text: any, record: any) => {
                    if (record?.taskApproval?.status) {
                        return getTaskApprovalStatusIcon(record?.taskApproval.status);
                    }
                },
            },
            {
                title: geti18nText('app.default.actions'),
                dataIndex: 'actions',
                width: '70px',
                render: (text: any, record: any) => {
                    if (record?.id && record?.taskApproval?.status == enTaskApprovalStatus.WAITING_FOR_APPROVAL) {
                        return <RowAction component={'Tasks'} record={record} changeStatus={changeStatus}></RowAction>;
                    }
                },
            },
        ],
    ];

    const addedButtons = () => {
        return (
            <>
                <Button type="primary" onClick={() => history.push('/task-service')} style={{ float: 'right' }}>
                    {geti18nText('menu.task.service')}
                </Button>
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="tasks-task-approval-table"
                url={CONSTANTS_REQ.TASK.APPROVAL_LIST}
                addNewButtonText={geti18nText('tasks.list.add')}
                fetchWhenChange={refreshTable}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                onRowSelect={(data: any) => {
                    setSelectedTaskApprovalUser(data);
                    setVisible(true);
                }}
                onDataLoaded={(data: any) => setData(data)}
                editProps={{ setRefreshTable: setRefreshTable, refreshTable: refreshTable }}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
                scroll={scroll}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                shortcuts={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                addedButtons={addedButtons}
            />
            {selectedTaskApprovalUser?.taskApproval?.id && visible && (
                <TaskApprovalModal
                    taskApprovalUser={selectedTaskApprovalUser}
                    setTaskApprovalUser={setSelectedTaskApprovalUser}
                    visible={visible}
                    setVisible={setVisible}
                    refresh={refreshTable}
                    setRefresh={setRefreshTable}
                    type={type}
                />
            )}
        </>
    );
};

export default TaskListIndexApproval;
