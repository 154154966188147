import { EllipsisOutlined, FileExcelOutlined } from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Button, Dropdown, Menu } from 'antd';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookBusinessUnitEmployee,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchByCodebookWorkplace,
} from '../../../../utils/Filters';
import { fileDownload, getDateFormat } from '../../../../utils/Utils';
import { useState } from 'react';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const Mobilization24hTable = ({
    scroll = { y: 620, x: 3200 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const table = useTableSettings();

    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const mobilizationTime = useEnum('MOBILIZATION_TIME');
    const employeeMilitaryRecordsAssigned = useEnum('EMPLOYEE_MILITARY_RECORDS_ASSIGNED');
    const mobilizationReportType = useEnum('MOBILIZATION_REPORT_TYPE');
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            style={{ width: '100%' }}
                            icon={<FileExcelOutlined />}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                    { type: mobilizationReportType.MOB_4_3_3 },
                                    geti18nText('app.enum.MOB_4_3_3.5') + '.xlsx'
                                );
                            }}
                        >
                            {geti18nText('app.enum.MOB_4_3_3.5')}
                        </Button>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            style={{ width: '100%' }}
                            icon={<FileExcelOutlined />}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                    { type: mobilizationReportType.MOB_4_4_3 },
                                    geti18nText('app.enum.MOB_4_4_3.8') + '.xlsx'
                                );
                            }}
                        >
                            {geti18nText('app.enum.MOB_4_4_3.8')}
                        </Button>
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('schedule.suggestions.generate.document')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    const columns = [
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            render: (text: any, record: any) => {
                if (record.employee.businessUnit) {
                    return (
                        (record.employee.businessUnit.code ? '(' + record.employee.businessUnit.code + ') ' : '') +
                        record.employee.businessUnit.name
                    );
                }
            },
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnitEmployee(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.name.lastname'),
            dataIndex: "concat(person.last_name,' ', person.first_name)",
            width: '12%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.employee)}>
                            {(record.employee.person && record.employee.person.lastName
                                ? record.employee.person.lastName + ', '
                                : '') +
                                (record.employee.person && record.employee.person.parentName
                                    ? record.employee.person.parentName + ', '
                                    : '') +
                                (record.employee.person && record.employee.person.firstName
                                    ? record.employee.person.firstName
                                    : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.work.place'),
            //width: '18%',
            dataIndex: ['vocation', 'name'],
            render: (text: any, record: any) => {
                if (record?.vocation?.name) {
                    return (
                        <div>
                               {record?.vocation?.name}
                               {record?.vocationDescription?.name ? ' - ' + record.vocationDescription.name : ' '}
                        </div>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookWorkplace(),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.birthdate'),
            dataIndex: ['person', 'birthDate'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.birthDate) {
                    return getDateFormat(record.employee.person.birthDate, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
            dataIndex: ['person', 'oib'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (created: any, record: any) => {
                if (record?.employee?.person?.oib) {
                    return record?.employee?.person?.oib;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.qualifications'),
            dataIndex: ['employeeProfessionalQualification', 'name'],
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                'employeeVocationProfessionalQualification.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                    code: 'code',
                }
            ),
           render: (created: any, record: any) => {
                if (record.employee && record?.employee?.vocation?.professionalQualification) {
                    return record.employee.vocation.professionalQualification;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.department.defense'),
            dataIndex: ['defenseDepartment', 'name'],
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseDepartment.id',
                setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'),
            dataIndex: ['defenseSection', 'name'],
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseSection.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        // {
        //     title: geti18nText('schedule.suggestions.modal.employees.table.column.address'),
        //     dataIndex: 'address',
        //     sorter: (a: any, b: any) => {},
        //     render: (created: any, record: any) => {
        //         if (record.address) {
        //             return (
        //                 <div>
        //                     {(record.address.street ? record.address.street + ',' : '') +
        //                         (record.address.settlement ? record.address.settlement.name : '')}
        //                 </div>
        //             );
        //         }
        //     },
        //     ...getColumnSearch('string'),
        // },
        // {
        //     title: geti18nText('schedule.suggestions.modal.employees.table.column.mobile'),
        //     dataIndex: ['contact', 'mobile'],
        //     sorter: (a: any, b: any) => {},
        //     ...getColumnSearch('string'),
        // },
        // {
        //     title: geti18nText('schedule.suggestions.modal.employees.table.column.email'),
        //     dataIndex: ['contact', 'email'],
        //     sorter: (a: any, b: any) => {},
        //     ...getColumnSearch('string'),
        // },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'assign',
                condition: 'equals',
                value: employeeMilitaryRecordsAssigned.YES,
            },
            { field: 'mobilizationTime', condition: 'equals', value: mobilizationTime.M24 },
        ];
    };

    return (
        <>
            <NyDataTable
                nyId="human-call-for-performance-of-duty-24-table"
                url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.LIST}
                buttonsClassName="buttons-sticky"
                columns={columns}
                fetchWhenChange={refresh}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                addedButtons={addedButtons}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default Mobilization24hTable;
