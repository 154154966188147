import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { getColumnDateOption } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import VocationGroupEdit from './edit';
import moment from 'moment';
import { getColumnSearchByCodebookVocationGroup } from '../../../../utils/Filters';
import { Tooltip } from 'antd';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const VocationGroupIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    customIdWidth,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    useHelper('human/codebooks/professionGroup');

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const columns = [
        {
            title: geti18nText('vocation.group.table.column.id'),
            dataIndex: 'id',
            width: customIdWidth ? customIdWidth : '100px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('vocation.group.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('vocation.group.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.name !== undefined) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={record?.name}>
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>{record?.name}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
            ...getColumnSearchByCodebookVocationGroup('id'),
        },
        {
            title: geti18nText('vocation.group.table.column.parent.code'),
            dataIndex: ['parent', 'code'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookVocationGroup('parent.id'),
        },
        {
            title: geti18nText('vocation.group.table.column.validFrom'),
            dataIndex: 'validFrom',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('vocation.group.table.column.validTo'),
            dataIndex: 'validTo',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('vocation.group.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-vocation-group-table"
            url={CONSTANTS_REQ.VOCATION_GROUP.LIST}
            addNewButtonText={geti18nText('vocation.group.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={VocationGroupEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideButtons={disabled}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.vocation.group')}
            colCSVCustomization={csvColumnCustomisation()}
            expandedRows={true}
            headerTitle={geti18nText('menu.vocation.group')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default VocationGroupIndex;
