import { UserOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Col, Row } from 'antd';
import ActionsWidget from '../../components/actions-widget';
import ChartAgeRange from '../human/views/human/charts/ChartAgeRange';
import ChartBusinessUnitEmployees from '../human/views/human/charts/ChartBusinessUnitEmployees';
import PieChartGenderCard from '../human/views/human/charts/PieChartGender';
import PersonalCalendarWidget from '../dashboard/PersonalCalendarWidget';
import DirectoryWidget from '../dashboard/DirectoryWidget';

const MyPortalHumanResourcesOverview = () => {
    const actions = [
        {
            label: geti18nText('my.profile.header'),
            path: '/human/employeePersonal',
            icon: <UserOutlined />,
        },
    ];

    return (
        <div>
            <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col span={16}>
                    <PersonalCalendarWidget />
                </Col>
                <Col span={4}>
                    <DirectoryWidget />
                </Col>
                <Col span={4}>
                    <ActionsWidget actions={actions} />
                </Col>
            </Row>
            <Row gutter={24}>
                <ChartAgeRange width={'525px'} height={250} myPortal />
                <ChartBusinessUnitEmployees width={'525px'} myPortal height={248} />
                <PieChartGenderCard width={'525px'} myPortal height={250} />
            </Row>
        </div>
    );
};

export default MyPortalHumanResourcesOverview;
