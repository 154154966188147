import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NyUtils, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { numberFormat } from '../../../../utils/Utils';
import TravelWageEdit from './edit';

const TravelWageExpenseIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('travel_order/codebooks/travelWageExpense');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canExportCSV = () => {
        return TravelWarrantRights.canCsvExportTravelWarrantCodebooks();
    };

    const columns = [
        {
            title: geti18nText('travelWageExpense.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWageExpense.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWageExpense.table.column.sort'),
            dataIndex: 'sort',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWageExpense.table.column.percent'),
            dataIndex: 'percent',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.percent) {
                    return numberFormat(record.percent);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('travelWageExpense.table.column.amount'),
            dataIndex: 'amount',
            align: 'right',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                return <div style={{ float: 'right', textAlign: 'right' }}>{numberFormat(amount)}</div>;
            },
        },
        {
            title: geti18nText('travelWageExpense.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-wage-expense"
            url={CONSTANTS_REQ.TRAVEL_WAGE_EXPENSE.LIST}
            addNewButtonText={geti18nText('travelWageExpense.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={TravelWageEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            nyTestId="travel-wage-expense-table"
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.travelWageExpense')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.travelWageExpense')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            percent: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            amount: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
    ];
};

export default TravelWageExpenseIndex;
