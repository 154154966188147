import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getEnumArrayStatus } from '../../../../../utils/Utils';
import EmployeeAssetProblemNotification from '../../../../dashboard/EmployeeAssetProblemNotification';

const EmployeeAssetIndex = ({ idProps, readonly = false, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const { id } = useParams<any>();
    const { employeeId } = useSelector((state: RootState) => state.attendance);
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const [refresh, setRefresh] = useState(0);
    const dispatch = useDispatch();
    const table = useTableSettings();

    useHelper('employee_asset');

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: id ? id : idProps ? idProps : employeeId },
        ];
    };
    const initialColumns = [
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.asset.releaseDate'),
            dataIndex: 'releaseDate',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.releaseDate) {
                    return moment(record.releaseDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.ASSET_STATUS.' + record.status);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('ASSET_STATUS'), 'equals', undefined),
        },
        {
            title: geti18nText('assetRelease.table.column.order'),
            dataIndex: ['assetOrder'],
            width: '10%',
            render: (text: any, record: any) => {
                if (record && record.assetOrder && record.assetOrder.id) {
                    let assetOrderText =
                        record.assetOrder.ord && record.assetOrder.date
                            ? record.assetOrder.ord + '/' + moment(record.assetOrder.date).format('YYYY')
                            : record.assetOrder.id;
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'assetOrderEdit',
                                        visible: true,
                                        item: record.assetOrder.id,
                                    })
                                )
                            }
                        >
                            {assetOrderText}
                        </Button>
                    );
                }
            },
        },
        {
            title: geti18nText('assetRelease.edit.title'),
            dataIndex: ['assetRelease'],
            width: '10%',
            render: (text: any, record: any) => {
                if (record && record.assetRelease && record.assetRelease.id) {
                    let assetReleaseText =
                        record.assetRelease.ord && record.assetRelease.dateRelease
                            ? record.assetRelease.ord + '/' + moment(record.assetRelease.dateRelease).format('YYYY')
                            : record.assetRelease.id;
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'assetReleaseEdit',
                                        visible: true,
                                        item: record.assetRelease.id,
                                    })
                                )
                            }
                        >
                            {assetReleaseText}
                        </Button>
                    );
                }
            },
        },
    ];

    const addedButtons = (onSave: () => void) => {
        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <EmployeeAssetProblemNotification />
            </div>
        );
    };

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <div style={{ marginTop: '8px' }}>
            <NyDataTable
                nyId="webshop-asset-employee-assets"
                rowKey="id"
                url={CONSTANTS_REQ.ASSET.DASHBOARD_LIST}
                addNewButtonText={geti18nText('asset.table.add')}
                buttonsClassName="buttons-sticky"
                readonly={readonly}
                hideButtons={readonly}
                hideNewButton={true}
                onRowSelect={() => null}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                shortcuts={true}
                exportCSV
                CSVFileName={geti18nText('menu.employeeAsset')}
                addedButtons={addedButtons}
                fetchWhenChange={id || refreshItem || refresh}
                onDataLoaded={table.onDataLoaded}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
            />
        </div>
    );
};

export default EmployeeAssetIndex;
