import { BookOutlined, ContainerOutlined, FileOutlined, ReadOutlined, ShopOutlined } from '@ant-design/icons';
import { WarehouseBookkeepingRights } from '../../rights/warehouseBookkeepingRights';

const WarehouseModuleMenu = [
    {
        key: 'menu.warehouse',
        path: '/warehouse',
        icon: <ShopOutlined />,
        i18n: 'menu.warehouse',
        role: WarehouseBookkeepingRights.canViewCodebooksMenu(),
    },
    {
        key: 'menu.warehouse-receipt',
        path: '/warehouse/warehouse-receipt',
        icon: <FileOutlined />,
        i18n: 'menu.warehouseReceipt',
        role: WarehouseBookkeepingRights.canViewReceiptMenu(),
    },
    {
        key: 'menu.warehouse.bookkeeping.warehouse-item',
        path: '/warehouse/warehouse-item',
        icon: <ReadOutlined />,
        i18n: 'menu.warehouseItem',
        role: WarehouseBookkeepingRights.canViewItemMenu(),
    },
    {
        key: 'menu.warehouse.bookkeeping.codebooks',
        path: '/warehouse/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.codebooks',
        role: WarehouseBookkeepingRights.canViewCodebooksMenu(),
        submenu: [
            {
                key: 'menu.warehouse-document',
                path: '/warehouse/codebooks/warehouse-document',
                icon: <ContainerOutlined />,
                i18n: 'menu.warehouseDocument',
                role: WarehouseBookkeepingRights.canViewCodebooksMenu(),
            },
        ],
    },
];
export default WarehouseModuleMenu;
