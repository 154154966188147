import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Collapse, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getEmployeeSelectOption,
    setDateFormat,
    setEnumFormat,
    okNotification,
    errorNotification,
    getSearchFormat,
    getEnumFormat,
    getEnumArrayNotificationSettings,
    customEmployeeRenderOption,
    setSearchFormat,
} from '../../../../utils/Utils';
import { BusinessUnitSearch } from '../../../human/views/business-unit/search';
import UserIndex from '../../../tasks/views/user';
import OrderViewIndex from '../order-view';
import OrderViewEdit from '../order-view/edit';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';

const { Panel } = Collapse;

const NotificationSettingsEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('notificationSettings.edit.new'));
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [allEmployee, setAllEmployee] = useState(true);
    const [notificationType, setNotificationType] = useState(null);
    const [selectedBussinessUnit, setSelectedBussinessUnit] = useState(false);
    const [selectedNotificationUser, setSelectedNotificationUser] = useState(false);
    const [businessUnit, setBusinessUnit] = useState<any>(null);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const { moduleType } = props.editProps ? props.editProps : { moduleType: undefined };

    const canCreate = () => {
        if (AdministrationRights.isAdmin()) {
            return true;
        } else if (moduleType == 1) {
            return ServicesRights.canCreateSettings();
        } else if (moduleType == 2) {
            return InventoryRights.canCreateSettings();
        } else if (moduleType == 3) {
            return AssetRights.canCreateSettings();
        } else if (moduleType == 4) {
            return TravelWarrantRights.canOnlyCreateSettings();
        } else if (moduleType == 5) {
            return HumanResourcesRights.canCreateSettings();
        } else if (moduleType == 8) {
            return MobilizationRights.canOnlyCreateSettings();
        } else {
            return AdministrationRights.canCreateSettings();
        }
    };

    const onModalClose = () => {
        form.resetFields();
        setIsActive(false);
        setAllEmployee(true);
        setNotificationType(null);
        setIsCreate(false);
        setSelectedBussinessUnit(false);
        setSelectedNotificationUser(false);
        setDataForm(null);
        setEditHeader(geti18nText('notificationSettings.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    useEffect(() => {
        if (allEmployee) {
            form.setFieldsValue({ businessUnit: [], employee: [] });
        }
    }, [allEmployee]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setDataForm(dataForm.id);
        setEditHeader(geti18nText('notificationSettings.edit.title') + ' - ' + dataForm.id);

        if (dataForm.active) {
            setIsActive(true);
        }

        if (dataForm.user) {
            dataForm.user = getEmployeeSelectOption(dataForm.user);
        }

        if (dataForm.notificationUser) {
            dataForm.notificationUser = getEmployeeSelectOption(dataForm.notificationUser);
        }

        if (dataForm.createdBy) {
            dataForm.createdBy = dataForm.createdBy.person.firstName + ' ' + dataForm.createdBy.person.lastName;
        }

        if (dataForm.updatedBy) {
            dataForm.updatedBy = dataForm.updatedBy.person.firstName + ' ' + dataForm.updatedBy.person.lastName;
        }

        if (dataForm.created) {
            dataForm.created = setDateFormat(dataForm.created);
        }

        if (dataForm.updated) {
            dataForm.updated = setDateFormat(dataForm.updated);
        }
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            setBusinessUnit(dataForm.businessUnit.name);
        }

        if (dataForm.notificationType) {
            setNotificationType(dataForm.notificationType);
            dataForm.notificationType = setEnumFormat(
                'NOTIFICATION_TYPE_SUPERVISOR',
                dataForm.notificationType,
                'NOTIFICATION_TYPE_REQUEST'
            );
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        setRightCollapseActiveKeys([]);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const deleteSetting = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.DELETE, undefined, {
            ids: [dataForm],
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                onModalClose();
                props.setVisible(false);
                props.editProps.setRefreshTable(props.editProps.refreshTable + 1);
            } else {
                errorNotification();
            }
        });
    };

    const onNotificationTypeChange = (type: any) => {
        if (type && type.id) {
            if (notificationType != type.id) {
                form.setFieldsValue({ orderView: undefined });
            }
            setNotificationType(type.id);
        } else {
            form.setFieldsValue({ orderView: undefined });
            setNotificationType(null);
        }
    };

    const orderViewFilter =
        notificationType == 7
            ? [{ field: 'type', condition: 'equals', value: 2 }]
            : notificationType == 8
            ? [{ field: 'type', condition: 'equals', value: 1 }]
            : notificationType == 9
            ? [{ field: 'type', condition: 'equals', value: 3 }]
            : [];

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.EDIT}
            width={600}
            form={form}
            customButtons={
                isActive
                    ? [
                          {
                              onClick: deleteSetting,
                              text: geti18nText('app.default.button.deactivate'),
                              loading: props.loading,
                              style: { backgroundColor: '#c70000', color: 'white' },
                              className: '',
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.destructive.confirm'),
                              popConfirmOk: geti18nText('app.default.button.yes'),
                              popConfirmCancel: geti18nText('app.default.button.no'),
                          },
                      ]
                    : []
            }
            hideActivationButtons={true}
            hideSubmitButton={!canCreate() || !isCreate}
            goBack={() => history.goBack()}
            {...props}
            onModalOpen={onModalOpen}
            setValues={setValues}
            setIsCreate={setIsCreate}
            normalize={(values: any) => {
                if (values.user) {
                    values.user = getSearchFormat(values.user);
                    if (values.user.id >= 1) {
                        values.userId = values.user.id;
                    }
                    delete values.user;
                }
                if (values.notificationUser) {
                    values.notificationUser = getSearchFormat(values.notificationUser);
                    if (values.notificationUser.id >= 1) {
                        values.notificationUserId = values.notificationUser.id;
                    }
                    delete values.notificationUser;
                }

                if (values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    if (values.businessUnit.id < 1) {
                        delete values.businessUnit;
                    }
                }

                if (values.orderView) {
                    values.orderView = getSearchFormat(values.orderView);
                    if (values.orderView.id < 1) {
                        delete values.orderView;
                    }
                }

                if (values.notificationType) values.notificationType = getEnumFormat(values.notificationType);
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('notificationSettings.edit.notificationType')}
                        name="notificationType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArrayNotificationSettings(moduleType)}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            disabled={!isCreate}
                            className=""
                            onChange={onNotificationTypeChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('notificationSettings.edit.user')}
                        name="user"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            disabled={!isCreate}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <BusinessUnitSearch
                        formItemName={'businessUnit'}
                        onChange={(item: any) => {
                            setSelectedBussinessUnit(item && item.id >= 0);
                        }}
                        disabled={!isCreate || selectedNotificationUser}
                        placeholder={
                            !isCreate ? (
                                <span style={{ color: isDarkTheme ? 'white' : 'black' }}>{businessUnit}</span>
                            ) : null
                        }
                    />
                </Col>
                {(notificationType == 7 || notificationType == 8 || notificationType == 9) && (
                    <Col span={12}>
                        <Form.Item label={geti18nText('notificationSettings.edit.orderView')} name="orderView">
                            <NySearchField
                                url={CONSTANTS_REQ.ORDER_VIEW.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                itemName="name"
                                disabled={!isCreate}
                                addedFilter={orderViewFilter}
                                AddNewModalComponent={OrderViewEdit}
                                SearchPopupComponent={<OrderViewIndex notificationType={notificationType} />}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('notificationSettings.edit.notificationUser')}
                        name="notificationUser"
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            disabled={!isCreate || selectedBussinessUnit}
                            onChange={(item: any) => {
                                setSelectedNotificationUser(item && item.id >= 0);
                            }}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            {dataForm && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('notificationSettings.edit.created')} name="created">
                            <NyDatePicker style={{ width: '100%' }} disabled format="DD.MM.YYYY HH:mm" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('notificationSettings.edit.createdBy')} name="createdBy">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    {!isActive && (
                        <Col span={12}>
                            <Form.Item label={geti18nText('notificationSettings.edit.updated')} name="updated">
                                <NyDatePicker style={{ width: '100%' }} disabled format="DD.MM.YYYY HH:mm" />
                            </Form.Item>
                        </Col>
                    )}
                    {!isActive && (
                        <Col span={12}>
                            <Form.Item label={geti18nText('notificationSettings.edit.updatedBy')} name="updatedBy">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            )}
        </NyDataEdit>
    );
};

export default NotificationSettingsEdit;
