import { geti18nText } from '@nybble/nyreact';
import { Switch } from 'antd';
import { useState } from 'react';
import WarSystematizationEmployeeTable from './WarSystematizationEmployeeTable';
import WarSystematizationTable from './WarSystematizationTable';

const WarSystematizationIndex = () => {
    const [grouped, setGrouped] = useState<boolean>(false);

    return (
        <>
            <br />
            <Switch onChange={(checked: boolean) => setGrouped(checked)} />{' '}
            {geti18nText('systematization.of.jobs.tab.switch.1')}
            {grouped ? <WarSystematizationEmployeeTable /> : <WarSystematizationTable />}
        </>
    );
};

export default WarSystematizationIndex;
