import { NyDatePicker, NyInputNumber, NySearchField, NySpinner, geti18nText } from '@nybble/nyreact';
import { Alert, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import moment from 'moment';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEnumArrayStatus,
    onKeyDownTextAreaCustom,
    setEnumFormat,
} from '../../../../../../utils/Utils';
import NyImageUpload from '../../../../../../utils/ny-file-upload-image';
import CountrySearch from '../../../../../administration/views/country/search';
import EmployeeIndex from '../../../employee';
import EducationFormSearch from '../../form/search';
import EducationInstitutionSearch from '../../institution/search';
import ProgramCatalogSelectIndex from '../../program-catalog/ProgramCatalogSelectIndex';
import ProgramCatalogSearch from '../../program-catalog/search';
import EducationPurposeSearch from '../../purpose/search';
import EducationEstimatedExpenses from './educationEstimatedExpenses';
import EducationExpenses from './educationExpenses';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';

const EmployeeEducationNewWizardSteps = ({
    form,
    current = 0,
    isPersonalView = false,
    status,
    //programCatalog
    programCatalog,
    setProgramCatalog,
    refresh,
    //education
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    guestLecturer,
    setGuestLecturer,
    presentation,
    setPresentation,
    //files
    files,
    setFiles,
    //estimatedExpenses
    estimatedExpenses,
    setEstimatedExpenses,
    //expenses
    companyCost,
    setCompanyCost,
    projectCost,
    setProjectCost,
    expenses,
    setExpenses,
    //admin data
    postEducationObligation,
    setPostEducationObligation,
    signedStatement,
    setSignedStatement,
    fillEvaluation,
    setFillEvaluation,
    isAdmin = false,
    //realization
    realized,
    setRealized,
    loading,
    setNameHolder,
    nameHolder,
    setDateFromHolder,
    dateFromHolder,
    setDateToHolder,
    dateToHolder,
}: any) => {
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const employeeEducationStatus = useEnum('EMPLOYEE_EDUCATION_STATUS');
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const canCreateCodebooks = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const getProgramCatalog = () => (
        <Row gutter={24} style={current != 0 ? { display: 'none' } : {}}>
            <Col span={24}>
                <ProgramCatalogSearch hide />
                <ProgramCatalogSelectIndex
                    form={form}
                    programCatalog={programCatalog}
                    setProgramCatalog={setProgramCatalog}
                    refresh={refresh}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    setFiles={setFiles}
                    disabled={status}
                />
            </Col>
        </Row>
    );

    const onEndDateChange = (value: any) => {
        setDateTo && setDateTo(value ? moment(value) : moment());
        setDateToHolder(moment(value));
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('dateFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('dateFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setDateFrom && setDateFrom(value ? moment(value) : moment());
        setDateFromHolder(moment(value));
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('dateTo')) {
            return current > moment(form.getFieldValue('dateTo')).endOf('day');
        }
    };

    const getEducationStep = () => (
        <>
            <div
                style={
                    current != 1
                        ? {
                              visibility: 'hidden',
                              height: '0px',
                              width: '0px',
                              margin: '0px',
                              padding: '0px',
                          }
                        : {
                              maxHeight: '450px',
                              overflowY: 'auto',
                              overflowX: 'hidden',
                              marginTop: '10px',
                              paddingRight: '5px',
                          }
                }
            >
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="status" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                {!isPersonalView && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('employee.education.edit.employee')}
                                name="employee"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                style={current != 1 ? { display: 'none' } : {}}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        employmentRecordId: 'employmentRecordId',
                                    }}
                                    searchBy="person.first_name || ' ' || person.last_name"
                                    itemName={[
                                        ['person', 'firstName'],
                                        ['person', 'lastName'],
                                    ]}
                                    renderOption={customEmployeeRenderOption}
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    customItemNameLabel={'firstName lastName'}
                                    SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="wizard-steps"/>}
                                    order="person.last_name, person.first_name"
                                    // onChange={employeeOnChange}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.name')} name="name">
                            <Input
                                onBlur={(e) => {
                                    setNameHolder(e?.target?.value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employee.education.edit.applicationDueDate')}
                            name="applicationDueDate"
                            style={current != 1 ? { display: 'none' } : {}}
                        >
                            <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.place')} name="place">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <CountrySearch
                            label={geti18nText('employee.education.edit.country')}
                            name="country"
                            setDefaultFilterValue={setDefaultFilterValue}
                            edit={canCreateCodebooks()}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.dateFrom')} name="dateFrom">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                onChange={onStartDateChange}
                                disabledDate={disabledStartDate}
                                defaultPickerValue={dateFromHolder ? dateFromHolder : dateFrom}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.dateTo')} name="dateTo">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                onChange={onEndDateChange}
                                disabledDate={disabledendDate}
                                defaultPickerValue={dateToHolder ? dateToHolder : dateTo}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={24}>
                    <Col span={12}>
                        <HumanCodebooksSearch
                            label={geti18nText('employee.education.edit.type')}
                            codebooksType={enCodebookType.EMPLOYEE_EDUCATION_TYPE}
                            formItemName={'type'}
                        />
                    </Col>
                    <Col span={12}>
                        <EducationFormSearch />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.organizer')} name="organizer">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.guestLecturer')} name="guestLecturer">
                            <Checkbox
                                checked={guestLecturer}
                                onChange={(val: any) => {
                                    setGuestLecturer(val.target.checked);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={geti18nText('employee.education.edit.remark')} name="remark">
                            <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} onKeyDown={onKeyDownTextAreaCustom} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <HumanCodebooksSearch
                            label={geti18nText('employee.education.edit.educationField')}
                            codebooksType={enCodebookType.EDUCATION_FIELD}
                            formItemName={'educationField'}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.education.edit.presentation')} name="presentation">
                            <Checkbox
                                checked={presentation}
                                onChange={(val: any) => {
                                    setPresentation(val.target.checked);
                                    if (!val.target.checked) {
                                        form.setFieldsValue({ presentationDate: null });
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {presentation && (
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employee.education.edit.presentationDate')}
                                name="presentationDate"
                            >
                                <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <EducationInstitutionSearch />
                    </Col>
                    <Col span={12}>
                        <EducationPurposeSearch />
                    </Col>
                </Row>
            </div>
        </>
    );

    const getFiles = () => (
        <Row gutter={24} style={current != 2 ? { display: 'none' } : {}}>
            <Col span={24}>
                <div style={{ paddingBottom: '15px' }}>
                    <Alert message={geti18nText('employee.education.files.info')} type="info" showIcon />
                </div>
                <NyImageUpload
                    files={files}
                    setFiles={setFiles}
                    saveOnUpload={true}
                    accept={'*'}
                    cropShape={null}
                    listType={'text'}
                />
            </Col>
        </Row>
    );

    const getEstimatedExpensesStep = () => (
        <Row gutter={24} style={current != 3 ? { display: 'none' } : {}}>
            <Col span={24}>
                <EducationEstimatedExpenses items={estimatedExpenses} setItems={setEstimatedExpenses} />
            </Col>
        </Row>
    );

    const getAdditionalData = () => (
        <div style={current != 4 ? { display: 'none' } : {}}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.education.edit.status')}
                        name="status"
                        initialValue={
                            isAdmin
                                ? setEnumFormat('EMPLOYEE_EDUCATION_STATUS', employeeEducationStatus.APPROVED)
                                : null
                        }
                        rules={[
                            {
                                required: isAdmin,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArrayStatus('EMPLOYEE_EDUCATION_STATUS')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            className=""
                            style={{ width: '100%' }}
                            disabled={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="postEducationObligation"
                        label={geti18nText('employee.education.edit.postEducationObligation')}
                    >
                        <Checkbox
                            checked={postEducationObligation}
                            onChange={(value: any) => setPostEducationObligation(value.target.checked)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.education.edit.obligationDuration')}
                        name="obligationDuration"
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            isDecimal={false}
                            min={0}
                            disabled={!postEducationObligation}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.education.edit.obligationRefundAmount')}
                        name="obligationRefundAmount"
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            isDecimal={true}
                            decimalPlaces={2}
                            max={99999}
                            min={0}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('employee.education.edit.contractFileName')} name="contractFileName">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('employee.education.edit.contractFileType')} name="contractFileType">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="signedStatement" label={geti18nText('employee.education.edit.signedStatement')}>
                        <Checkbox
                            checked={signedStatement}
                            onChange={(value: any) => setSignedStatement(value.target.checked)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="fillEvaluation" label={geti18nText('employee.education.edit.fillEvaluation')}>
                        <Checkbox
                            checked={fillEvaluation}
                            onChange={(value: any) => setFillEvaluation(value.target.checked)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const getRealization = () => (
        <div style={current != 5 ? { display: 'none' } : {}}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="realized" label={geti18nText('employee.education.edit2.realized')}>
                        <Checkbox checked={realized} onChange={(value: any) => setRealized(value.target.checked)} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        label={geti18nText('employee.education.edit2.unrealizationReason')}
                        codebooksType={enCodebookType.UNREALIZATION_REASON}
                        formItemName={'unrealizationReason'}
                        disabled={realized}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name="unrealizationRemark"
                        label={geti18nText('employee.education.edit2.unrealizationRemark')}
                    >
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const getExpensesStep = () => (
        <Row gutter={24} style={current != 6 ? { display: 'none' } : {}}>
            <Col span={24}>
                <EducationExpenses
                    // form={form}
                    items={expenses}
                    setItems={setExpenses}
                    companyCost={companyCost}
                    setCompanyCost={setCompanyCost}
                    projectCost={projectCost}
                    setProjectCost={setProjectCost}
                />
            </Col>
        </Row>
    );

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <Form
                    layout="vertical"
                    form={form}
                    onFinishFailed={({ errorFields }) => {
                        form.scrollToField(errorFields[0].name);
                    }}
                >
                    <>
                        {getProgramCatalog()}
                        {getEducationStep()}
                        {/* {getFiles()} */}
                        {getEstimatedExpensesStep()}
                        {getAdditionalData()}
                        {getRealization()}
                        {getExpensesStep()}
                    </>
                </Form>
            )}
        </>
    );
};
export default EmployeeEducationNewWizardSteps;
