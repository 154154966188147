import { EllipsisOutlined, FileSyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySession,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnDateOption, getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Col, Dropdown, Form, Menu, Modal, Popover } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { GetEnum } from '../../../../../utils/Enums';
import {
    errorNotification,
    getBillingStatusIcon,
    getDateFormat,
    getEnumArrayStatus,
    okNotification,
} from '../../../../../utils/Utils';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealPaymentIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const table = useTableSettings();
    const [visible, setVisible] = useState<boolean>(false);
    const [generatePaymentVisible, setGeneratePaymentVisible] = useState<boolean>(false);
    const [generatePaymentDate, setGeneratePaymentDate] = useState<boolean>(false);
    const [restaurantName, setRestaurantName] = useState<any>();
    const [forDate, setForDate] = useState<any>();
    const [id, setId] = useState<any>();
    const [refresh, setRefresh] = useState<any>(0);
    const mealGroupOrdersType = GetEnum({ enumName: 'MEALS_GROUP_ORDER_TYPE' });
    const billingStatusEnum = GetEnum({ enumName: 'BILLING_STATUS' });

    useHelper('meals/groupOrder');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'type',
                condition: 'in',
                value: [mealGroupOrdersType.BILL].toString(),
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('meal.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.group.order.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (created: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('meal.group.order.table.column.for.date'),
            dataIndex: 'forDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (forDate: any, record: any) => {
                if (record.forDate) {
                    return getDateFormat(record.forDate, 'DD.MM.YYYY');
                }
            },
        },
    ];

    const modalColumns = [
        {
            title: geti18nText('meal.menu.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.delivery.table.column.person'),
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            render: (text: any, record: any) => {
                if (record.person) {
                    return (
                        record.person.firstName +
                        ' ' +
                        record.person.lastName +
                        (record.employee && record.employee.employmentRecordId
                            ? ' (' + record.employee?.employmentRecordId + ')'
                            : '')
                    );
                }
            },
        },

        {
            title: geti18nText('meal.delivery.table.column.orderDate'),
            dataIndex: 'orderDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (name: any, record: any) => {
                if (record && record.orderDate) {
                    return moment(record.orderDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot'),
            dataIndex: ['deliverySpot', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (name: any, record: any) => {
                if (record && record.deliverySpot && record.deliverySpot.name) {
                    return record.deliverySpot.name;
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.status'),
            dataIndex: ['billingStatus'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('BILLING_STATUS')),
            render: (name: any, record: any) => {
                if (record && record.billingStatus) {
                    return record.billingStatus == billingStatusEnum?.ERROR ? (
                        <Popover content={record?.billingResponseError} title={geti18nText('app.default.error')}>
                            {geti18nText('app.enum.BILLING_STATUS.' + record.billingStatus)}
                            <InfoCircleOutlined style={{ marginLeft: '6px', marginTop: '4px', float: 'right' }} />
                        </Popover>
                    ) : (
                        getBillingStatusIcon(record.billingStatus)
                    );
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.time'),
            dataIndex: ['deliverySpotTime', 'time'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (name: any, record: any) => {
                if (record && record.deliverySpotTime && record.deliverySpotTime.time) {
                    return record.deliverySpotTime.time;
                }
            },
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.type'),
            dataIndex: ['deliverySpotTime', 'type'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('DELIVERY_TYPE')),
            render: (name: any, record: any) => {
                if (record && record.deliverySpotTime && record.deliverySpotTime.type) {
                    return geti18nText('app.enum.DELIVERY_TYPE.' + record.deliverySpotTime.type);
                }
            },
        },
    ];

    const generatePayment = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.GROUP_ORDER.GENERATE_PAYMENT, undefined, {
            date: getDateFormat(generatePaymentDate, 'YYYY-MM-DD'),
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                okNotification();
                setRefresh(refresh + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getTitle = () => {
        return restaurantName != undefined
            ? geti18nText('meal.group.order.edit.view.order.group') + ' - ' + restaurantName + ' - ' + forDate
            : geti18nText('meal.group.order.edit.view.order.group') + ' - ' + forDate;
    };

    const addedButtons = () => {
        const actionsMenu = (
            <Menu>
                <div style={{ display: 'flex', margin: '5px', flexDirection: 'column' }}>
                    <Button
                        style={{ width: '100%' }}
                        onClick={() => setGeneratePaymentVisible(true)}
                        icon={<FileSyncOutlined />}
                    >
                        {geti18nText('meal.group.order.button.generateGroupOrderPayment')}
                    </Button>
                </div>
            </Menu>
        );
        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="meal-payment"
                url={CONSTANTS_REQ.GROUP_ORDER.LIST}
                addNewButtonText={geti18nText('menu.meals.group.order.men')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                fetchWhenChange={refresh}
                columns={columns}
                scroll={scroll}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                onDataLoaded={table.onDataLoaded}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultFilterValue={setDefaultFilterValue}
                hideNewButton={!canCreate()}
                shortcuts={true}
                onRowSelect={(e: any) => {
                    setVisible(true);
                    setRestaurantName(e.restaurant?.name);
                    setForDate(getDateFormat(e.forDate, 'DD.MM.YYYY'));
                    setId(e.id);
                }}
                addedButtons={addedButtons}
                headerTitle={geti18nText('menu.meals.group.order.menu')}
            />
            {visible && (
                <Modal
                    visible={visible}
                    title={getTitle()}
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    okText={geti18nText('app.default.button.cancel')}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    width={1000}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    okButtonProps={{ type: 'default' }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <NyDataTable
                        nyId="meal-payment-item"
                        url={CONSTANTS_REQ.GROUP_ORDER.ORDER_LIST.replace('{groupOrderId}', id)}
                        addNewButtonText={geti18nText('menu.meals.group.order.men')}
                        buttonsClassName="buttons-sticky"
                        showRecordModal={true}
                        columns={modalColumns}
                        scroll={{ y: 680, x: 800 }}
                        onDataLoaded={table.onDataLoaded}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        setDefaultCurrentPage={table.setDefaultCurrentPage()}
                        setDefaultSortOrder={table.setDefaultSortOrder()}
                        hideNewButton={true}
                        shortcuts={true}
                        readonly={true}
                    />
                </Modal>
            )}
            {generatePaymentVisible && (
                <Modal
                    visible={generatePaymentVisible}
                    title={geti18nText('meal.group.order.modal.payment.title')}
                    onOk={(event: any) => {
                        generatePayment();
                        setGeneratePaymentVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setGeneratePaymentVisible(false);
                    }}
                    width={400}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    okText={geti18nText('meal.group.order.modal.payment.start')}
                >
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('meal.group.order.modal.payment.date.select')}
                            name="dateTo"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={moment()}
                        >
                            <NyDatePicker
                                format="DD.MM.YYYY"
                                style={{ width: '100%' }}
                                onChange={(value: any) => {
                                    setGeneratePaymentDate(value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Modal>
            )}
        </>
    );
};

export default MealPaymentIndex;
