import {
    EllipsisOutlined,
    FieldTimeOutlined,
    HistoryOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    QuestionOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { NyRequestResolver, NySession, NySpinner, RESPONSE, geti18nText, NyAccessibilityWidget } from '@nybble/nyreact';
import {
    Avatar,
    Breadcrumb,
    Button,
    Col,
    Dropdown,
    Image,
    Input,
    Layout,
    Menu,
    MenuProps,
    Modal,
    PageHeader,
    Popconfirm,
    Popover,
    Result,
    Row,
    Tooltip,
} from 'antd';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';
import { AdministrationRights } from '../../rights/administrationRights';
import { TasksRights } from '../../rights/tasksRights';
import { RootState } from '../../rootReducer';
import { fetchApplicationSettingsSlice } from '../../slices/applicationSettingsSlice';
import {
    attendanceInit,
    fetchAttendanceStatus,
    setAttendance,
    setDontShowModal,
    setEmployeeId,
} from '../../slices/attendanceSlice';
import { setCompany } from '../../slices/companySlice';
import { setEmployee } from '../../slices/employeeSlice';
import { fetchUserFavorites } from '../../slices/favoriteSlice';
import { fetchGeneralSettings } from '../../slices/generalSettingsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getLocationWithIp } from '../../utils/Location';
import { clearCache, userIsNotificationRecipient } from '../../utils/Utils';
import { InitSlices } from '../../utils/slices';
import EmployeeCard from '../../views/dashboard/EmployeeCard';
import LayoutMenu from '../layout-menu';
import NotificationsIndex from '../notifications';
import './index.css';
import defaultMenu from './menu';
import ModalViewIndex from './modal-view';
import { mainRoutes } from './routes';
import { addHistory } from '../../slices/historySlice';
import { fetchMyMenu } from '../../slices/menuSlice';
import LayoutSearch from '../layout-search';
import WorkAttendanceRegistration from '../work-attendance-registration';
import BhubLogo from '../../assets/images/BhubLogo.png';
import BhubFavicon from '../../assets/images/BhubFavicon.png';
const { Content } = Layout;
let timeout: any = null;

const LayoutIndex = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { workLoggedIn, hasLoggedInToday, dontShowModal, attendanceDate } = useSelector(
        (state: RootState) => state.attendance
    );
    // const { active } = useSelector((state: any) => state.tabs);

    const { helperUrl } = useSelector((state: RootState) => state.helper);
    const { collapsed } = useSelector((state: RootState) => state.menu);
    const { pinedNotifications } = useSelector((state: RootState) => state.pinedNotifications);
    const { employee } = useSelector((state: RootState) => state.employee);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const { links } = useSelector((state: RootState) => state.history);

    const [menu, setMenu] = useState(defaultMenu);
    const [helpModalVisible, setHelpModalVisible] = useState(false);
    const [employeeId, setEmpoloyeeId] = useState(undefined);
    const [employeeCard, setEmployeeCard] = useState<any>(undefined);
    const [attendanceModalVisible, setAttendanceModalVisible] = useState(false);
    const [time, setTime] = useState<any>();
    const [isWorkLogout, setIsWorkLogout] = useState(false);
    const [appLogo, setAppLogo] = useState<any>(undefined);
    const [paths, setPaths] = useState<any>([]);

    const [routes, setRoutes] = useState<any>([]);

    useEffect(() => {
        setRoutes(generateRoutes(mainRoutes));
    }, [employee]);

    // useEffect(() => {
    //     if (history.location.pathname !== active && NySession.isUserAuthenticated()) {
    //         history.push(active);
    //     }
    // }, [active]);

    useEffect(() => {
        if (pathname != undefined) {
            setPaths(pathname.split('/').slice(1));
            // slice(1): '/' -> ['']
            // filter(Boolean): '/' -> [] -> ne radi Breadcrumb
        }
        let menuItem = menu.find((val: any) => val.path == pathname);
        menuItem &&
            dispatch(
                addHistory({
                    path: location.pathname,
                    i18n: [{ key: menuItem?.i18n, hasComponent: true }],
                })
            );
    }, [pathname]);

    const hasRoleWorkLogin = () => {
        return AdministrationRights.hasRoleWorkLogin();
    };

    const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

    useEffect(() => {
        getAppSettings();

        if (!('Notification' in window)) {
            console.log('This browser does not support desktop notification');
        } else {
            Notification.requestPermission();
        }

        dispatch(fetchGeneralSettings());
        dispatch(fetchApplicationSettingsSlice());
        dispatch(fetchUserFavorites());
        dispatch(fetchMyMenu());
        if (attendanceDate === undefined || !moment(attendanceDate).isSame(moment(moment().format('yyyy-MM-DD')))) {
            dispatch(attendanceInit());
        }
        if (NySession.isUserAuthenticated()) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_EMPLOYEE_BY_USER, {}).then((result: any) => {
                if (result.status == RESPONSE.OK && result.data) {
                    setEmployeeCard(result.data);
                    if (result.data.id) {
                        setEmpoloyeeId(result.data.id);
                        dispatch(setEmployee({ employee: result.data }));
                        dispatch(setEmployeeId({ employeeId: result.data.id }));
                        if (hasRoleWorkLogin()) {
                            dispatch(fetchAttendanceStatus(result.data.id));
                            setTime(new Date().toLocaleTimeString());
                        }
                    }
                }
            });

            setRoutes(generateRoutes(mainRoutes));
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname === '/') {
            document.body.style.setProperty('--dashboardHeight', 'calc(100vh - 64px)');
            document.body.style.setProperty('--dashboardMaxHeight', 'unset');
            document.body.style.setProperty('--dashboardMarginBottom', 'unset');
        } else {
            document.body.style.setProperty('--dashboardHeight', 'unset');
            document.body.style.setProperty('--dashboardMaxHeight', 'calc(100vh - 64px)');
            document.body.style.setProperty('--dashboardMarginBottom', '0px');
        }
    }, [history.location.pathname]);

    useEffect(() => {
        if (employeeId && !dontShowModal && hasRoleWorkLogin()) {
            // Svakih 15 min = 900000 ms
            const interval = setInterval(() => {
                if (!hasLoggedInToday && !dontShowModal) {
                    setAttendanceModalVisible(true);
                }
            }, 900000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [time]);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 1 },
            { field: 'settingGroup', condition: 'equals', value: 'COMPANY_INFO' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_APP_LOGO') {
                                setAppLogo(element.value ? element.value : undefined);
                            } else if (element.settingKey === 'DEFAULT_APP_FAVICON') {
                                setFavIcon(element.value);
                            } else if (element.settingKey === 'COMPANY_ID' && element.value) {
                                getCompanyData(element.value);
                            }
                        });
                    }
                }
            }
        );
    };

    const getCompanyData = (companyId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EDIT + '/' + companyId, {}).then((result: any) => {
            if (result.status == RESPONSE.OK && result.data) {
                if (result.data) {
                    dispatch(setCompany({ company: result.data }));
                }
            }
        });
    };

    const setFavIcon = (iconName: any) => {
        var link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = iconName
            ? CONSTANTS_REQ.FILES.DOWNLOAD + '/' + iconName + '?tenant=' + NySession.getUser().tenantId
            : BhubFavicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    };

    const helpButton = () => {
        setHelpModalVisible(true);
    };

    function hasAnyRouteRole(roles: string[], userRoles: any) {
        let res = false;
        roles.forEach((role: any) => {
            if (userRoles.includes(role)) {
                res = true;
                return res;
            }
        });
        return res;
    }

    function generateRoutes(routes: any) {
        let retValue = [];
        let route: any;

        const userRoles: any = NySession.getUser().roles;
        const isUserAuthenticated = NySession.isUserAuthenticated();
        for (route of routes) {
            if (
                ((route.role !== undefined &&
                    isUserAuthenticated &&
                    userRoles &&
                    hasAnyRouteRole(route.role, userRoles)) ||
                    userIsNotificationRecipient(employee, route.notificationType)) &&
                (!route.isForEmployeesOnly || employee)
            ) {
                retValue.push(
                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                );
            }
        }
        return retValue;
    }

    const logout = () => {
        clearCache();
        NySession.logoutUser();
        storage.removeItem('persist:root');
        InitSlices(dispatch);
        history.push('/login');
    };

    const openMyProfile = () => {
        history.push('/my-profile');
    };

    const openTaskService = () => {
        history.push('/task-service');
    };

    const MenuLogo = () => {
        return (
            <React.Fragment>
                <div className="menu-search">
                    <LayoutSearch />
                </div>
            </React.Fragment>
        );
    };

    const customFooterContent = () => {
        return (
            <Row gutter={8} style={{ paddingTop: '5px' }}>
                <Col span={22} offset={1}>
                    <Button
                        type="default"
                        icon={<UserOutlined />}
                        size="small"
                        style={{ width: '100%', borderRadius: '30px' }}
                        onClick={openMyProfile}
                    >
                        {geti18nText('my.profile.header')}
                    </Button>
                </Col>
            </Row>
        );
    };

    const userDropdown = (
        <>
            {employeeId ? (
                <EmployeeCard
                    employee={employeeCard}
                    cardStyle={{ width: '300px', border: '0px' }}
                    customFooterContent={customFooterContent}
                    avatar={false}
                />
            ) : (
                <Menu>
                    <Menu.Item>
                        <a onClick={openMyProfile}>
                            <UserOutlined />
                            {geti18nText('my.profile.header')}
                        </a>
                    </Menu.Item>
                    <Menu.Item>
                        <a onClick={logout}>
                            <LogoutOutlined />
                            {geti18nText('navbar.user.logout')}
                        </a>
                    </Menu.Item>
                </Menu>
            )}
        </>
    );

    const workLogin = async () => {
        const location = await getLocationWithIp();
        NyRequestResolver.requestPost(CONSTANTS_REQ.ATTENDANCE.LOGIN, undefined, {
            employeeId: employeeId,
            location: location,
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                updateAttendanceStatus(result.data);
            }
        });
    };

    const workLogout = async () => {
        const location = await getLocationWithIp();
        NyRequestResolver.requestPost(CONSTANTS_REQ.ATTENDANCE.LOGOUT, undefined, {
            employeeId: employeeId,
            location: location,
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                setIsWorkLogout(true);
                updateAttendanceStatus(result.data);
            }
        });
    };

    function updateAttendanceStatus(attendance: any) {
        if (
            attendance.fromReal != undefined &&
            moment().isAfter(moment(attendance.fromReal)) &&
            (attendance.toReal == undefined || moment().isBefore(moment(attendance.fromTo)))
        ) {
            dispatch(setAttendance({ workLoggedIn: true, hasLoggedInToday: true }));
        } else {
            dispatch(setAttendance({ workLoggedIn: false, hasLoggedInToday: true }));
        }
    }

    const mobileMenu = () => {
        return (
            <Menu>
                <div style={{ padding: '5px', textAlign: 'center' }}>
                    {hasRoleWorkLogin() && employeeId && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            {workLoggedIn ? (
                                <Button className={'button-warning'} onClick={workLogout}>
                                    {geti18nText('navbar.attendance.logout')}
                                </Button>
                            ) : (
                                !hasLoggedInToday && (
                                    <Button className={'button-success'} onClick={workLogin}>
                                        {geti18nText('navbar.attendance.login')}
                                    </Button>
                                )
                            )}
                        </div>
                    )}
                    <div style={{ display: 'block', margin: '5px' }}>
                        {employeeId ? (
                            <Popover
                                placement="topLeft"
                                content={userDropdown}
                                trigger="click"
                                overlayClassName="ny-popover"
                            >
                                <Button>
                                    <UserOutlined />
                                    {NySession.getUser().fullName}
                                </Button>
                            </Popover>
                        ) : (
                            <Dropdown overlay={userDropdown} trigger={['click']}>
                                <Button>
                                    <UserOutlined />
                                    {NySession.getUser().fullName}
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        {helperUrl != undefined && <Button onClick={helpButton} icon={<QuestionCircleOutlined />} />}
                    </div>
                </div>
            </Menu>
        );
    };

    const DropdownMenu = () => (
        <Dropdown key="more" overlay={mobileMenu} trigger={['click']}>
            <Button
                style={{
                    border: 'none',
                    padding: 0,
                }}
            >
                <EllipsisOutlined
                    style={{
                        fontSize: 20,
                        verticalAlign: 'top',
                    }}
                />
            </Button>
        </Dropdown>
    );

    function itemRender(route: any, params: any, routes: any) {
        const idx = params.idx % routes.length;
        params.idx += 1;
        const last = idx === routes.length - 1;
        const path = '/' + routes.slice(0, idx + 1).join('/');
        const link = links?.find((val: any) => val.path == '/' + routes.join('/'));
        const i18n = link?.i18n[idx];
        return last || !i18n?.hasComponent ? (
            <span>{link?.i18n ? geti18nText(i18n?.key ?? '', i18n?.args) : ''}</span>
        ) : (
            <Link to={path}>{link?.i18n ? geti18nText(i18n?.key ?? '', i18n?.args) : ''}</Link>
        );
    }

    // const items = () => {
    //     let itemsHistory = [];
    //     links.map((item: any) => {
    //         itemsHistory.push({ label: item, key: item });
    //     });

    //     return links;
    // };ž

    const items = links?.map((link: any) => {
        const tooltip = link.i18n
            .slice(0, -1)
            .map((val: any) => geti18nText(val.key))
            .join(' > ');
        const i18n = [...link.i18n].pop();
        return {
            key: link.path,
            label: (
                <Tooltip placement={'left'} title={tooltip}>
                    <Link to={link.path}>{geti18nText(i18n.key, i18n.args)}</Link>
                </Tooltip>
            ),
        };
    });

    if (!NySession.isUserAuthenticated()) {
        dispatch(addHistory({ path: pathname, i18n: [{ key: '', hasComponent: false }] }));
        return <Redirect to="/login" />;
    } else {
        return (
            <Layout>
                {/* <div className="logo"></div> */}
                <PageHeader
                    className="ny-header header-background"
                    title={
                        <div
                            className="ny-logo"
                            style={{
                                textAlign: 'center',
                                transition: 'width .5s cubic-bezier(.2,0,0,1) 0s',
                            }}
                        >
                            {appLogo ? (
                                <img
                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    src={
                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                        '/' +
                                        appLogo +
                                        '?tenant=' +
                                        NySession.getUser().tenantId
                                    }
                                />
                            ) : (
                                <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={BhubLogo} />
                            )}
                        </div>
                    }
                    tags={
                        <div
                            style={{
                                transition: 'margin .5s cubic-bezier(.2,0,0,1) 0s',
                                marginLeft: collapsed ? '70px' : '190px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Dropdown menu={{ items }}>
                                <HistoryOutlined />
                            </Dropdown>
                            <Breadcrumb
                                separator=">"
                                itemRender={itemRender}
                                routes={paths}
                                params={{ idx: 0 }}
                                style={{ marginLeft: '10px' }}
                            />
                        </div>
                    }
                    extra={
                        //here
                        isMobile ? (
                            [<NotificationsIndex />, <DropdownMenu />]
                        ) : (
                            <>
                                {MenuLogo()}
                                {hasRoleWorkLogin() &&
                                    employeeId &&
                                    (workLoggedIn ? (
                                        <Button className={'button-warning'} onClick={workLogout}>
                                            {geti18nText('navbar.attendance.logout')}
                                        </Button>
                                    ) : (
                                        !hasLoggedInToday && (
                                            <Button className={'button-success'} onClick={workLogin}>
                                                {geti18nText('navbar.attendance.login')}
                                            </Button>
                                        )
                                    ))}
                                <>
                                    {helperUrl != undefined && (
                                        <Button
                                            onClick={helpButton}
                                            icon={<QuestionOutlined />}
                                            style={{ borderRadius: '50%' }}
                                        />
                                    )}
                                    <NotificationsIndex />

                                    {TasksRights.canCreateAdminOrUser() && (
                                        <Button
                                            onClick={openTaskService}
                                            icon={
                                                <i
                                                    style={{ fontSize: '17px', position: 'relative', top: '1px' }}
                                                    className="las la-headset"
                                                />
                                            }
                                            style={{ borderRadius: '50%' }}
                                        />
                                    )}
                                    <WorkAttendanceRegistration />

                                    {employeeId ? (
                                        <Popover
                                            placement="topLeft"
                                            content={userDropdown}
                                            trigger="hover"
                                            overlayClassName="ny-popover"
                                        >
                                            <div style={{ display: 'inline-flex' }}>
                                                <div>
                                                    {employeeCard?.person?.fileId ? (
                                                        <Avatar
                                                            size={28}
                                                            style={{ top: '1px' }}
                                                            src={
                                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                '/' +
                                                                employee?.person?.fileId +
                                                                '?tenant=' +
                                                                NySession.getUser().tenantId
                                                            }
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            size={28}
                                                            style={{ top: '1px' }}
                                                            icon={<UserOutlined />}
                                                        />
                                                    )}
                                                </div>
                                                {employeeCard && employeeCard.businessUnits ? (
                                                    <div style={{ marginTop: '-6px' }}>
                                                        <div
                                                            style={{
                                                                marginTop: '2px',
                                                                paddingTop: '1px',
                                                                marginBottom: '-4px',
                                                            }}
                                                        >
                                                            {NySession.getUser().fullName}
                                                        </div>
                                                        <div style={{ fontSize: '10px' }}>
                                                            <p style={{ marginBottom: '0px' }}>
                                                                {(employeeCard?.businessUnits[0]?.code
                                                                    ? `[${employeeCard?.businessUnits[0]?.code}] `
                                                                    : '') +
                                                                    (employeeCard?.businessUnits[0]?.name
                                                                        ? employeeCard?.businessUnits[0]?.name
                                                                        : '')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginTop: '2px',
                                                            paddingTop: '1px',
                                                            marginBottom: '-4px',
                                                        }}
                                                    >
                                                        {NySession.getUser().fullName}
                                                    </div>
                                                )}
                                            </div>
                                        </Popover>
                                    ) : (
                                        <Dropdown overlay={userDropdown} trigger={['hover']}>
                                            <div style={{ display: 'inline-flex' }}>
                                                <div>
                                                    {employeeCard?.person?.fileId ? (
                                                        <Avatar
                                                            size={28}
                                                            src={
                                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                '/' +
                                                                employee?.person?.fileId +
                                                                '?tenant=' +
                                                                NySession.getUser().tenantId
                                                            }
                                                        />
                                                    ) : (
                                                        <Avatar size={28} icon={<UserOutlined />} />
                                                    )}
                                                </div>
                                                {employeeCard && employeeCard.businessUnits ? (
                                                    <div style={{ marginTop: '-6px' }}>
                                                        <div
                                                            style={{
                                                                marginTop: '2px',
                                                                paddingTop: '1px',
                                                                marginBottom: '-4px',
                                                            }}
                                                        >
                                                            {NySession.getUser().fullName}
                                                        </div>
                                                        <div style={{ fontSize: '10px' }}>
                                                            {employeeCard &&
                                                                employeeCard.businessUnits &&
                                                                employeeCard.businessUnits
                                                                    .map((businessUnit: any) => {
                                                                        return (
                                                                            <p style={{ marginBottom: '0px' }}>
                                                                                {(businessUnit.code
                                                                                    ? `[${businessUnit.code}] `
                                                                                    : '') +
                                                                                    (businessUnit.name
                                                                                        ? businessUnit.name
                                                                                        : '')}
                                                                            </p>
                                                                        );
                                                                    })
                                                                    .reverse()}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginTop: '2px',
                                                            paddingTop: '1px',
                                                            marginBottom: '-4px',
                                                        }}
                                                    >
                                                        {NySession.getUser().fullName}
                                                    </div>
                                                )}
                                            </div>
                                        </Dropdown>
                                    )}
                                    <NyAccessibilityWidget
                                        rootId="root"
                                        defaultFont='-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                                    />
                                    <Popconfirm
                                        title={geti18nText('app.default.logout.confirm')}
                                        onConfirm={() => {
                                            logout();
                                        }}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                    >
                                        <Button style={{ borderRadius: '50%' }} icon={<LogoutOutlined />} />
                                    </Popconfirm>
                                </>
                            </>
                        )
                    }
                />
                <Layout>
                    <LayoutMenu
                        menuItems={menu}
                        menuHistory={history}
                        menuLocation={location}
                        menuTheme={theme != undefined && (theme === 'dark' || theme === 'default') ? 'dark' : 'light'}
                        siderClassName="ny-sider"
                        menuClassName="ny-menu"
                        // menuLogo={MenuLogo()}
                    />
                    <Layout
                        className="main-layout"
                        style={{
                            transition: 'margin .3s cubic-bezier(.2,0,0,1) 0s',
                            marginLeft: collapsed ? '80px' : '200px',
                            marginRight: pinedNotifications == 1 ? '0px' : pinedNotifications == 2 ? '30px' : '0px',
                            padding: '3px',
                        }}
                    >
                        <Content className="main-content">
                            <Suspense fallback={<NySpinner />}>
                                <Switch>
                                    {routes}
                                    <Route
                                        path="*"
                                        render={() => (
                                            <Result
                                                status="404"
                                                title="404"
                                                subTitle={geti18nText('app.default.404')}
                                                extra={
                                                    <Button type="primary">
                                                        {geti18nText('app.default.404.back')}
                                                    </Button>
                                                }
                                            />
                                        )}
                                    />
                                </Switch>
                            </Suspense>
                            {helpModalVisible && (
                                <Modal
                                    maskClosable={false}
                                    title={geti18nText('app.default.help')}
                                    open={helpModalVisible}
                                    width={1400}
                                    centered={true}
                                    onOk={() => setHelpModalVisible(false)}
                                    onCancel={() => setHelpModalVisible(false)}
                                    footer={null}
                                >
                                    {helperUrl !== undefined && (
                                        <iframe
                                            style={{
                                                width: '100%',
                                                height: '80vh',
                                                border: 'none',
                                                background: 'white',
                                            }}
                                            src={NySession.getSetting('docs') + helperUrl + '/'}
                                        ></iframe>
                                    )}
                                </Modal>
                            )}
                            {attendanceModalVisible && employeeId && !workLoggedIn && !dontShowModal && !isWorkLogout && (
                                <Modal
                                    title={geti18nText('navbar.attendance.login')}
                                    visible={
                                        attendanceModalVisible &&
                                        employeeId &&
                                        !workLoggedIn &&
                                        !dontShowModal &&
                                        !isWorkLogout
                                    }
                                    maskClosable={false}
                                    width={400}
                                    centered={true}
                                    onOk={() => {
                                        setTime(new Date().toLocaleTimeString());
                                        setAttendanceModalVisible(false);
                                    }}
                                    onCancel={() => {
                                        setTime(new Date().toLocaleTimeString());
                                        setAttendanceModalVisible(false);
                                    }}
                                    footer={null}
                                >
                                    <Result
                                        status="warning"
                                        title={
                                            <>
                                                <h4 style={{ marginBottom: '0px' }}>
                                                    {geti18nText('navbar.attendance.modal.title')}
                                                </h4>
                                                <span> {geti18nText('navbar.attendance.modal.text')}</span>
                                            </>
                                        }
                                        extra={
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        setAttendanceModalVisible(false);
                                                        dispatch(setDontShowModal({ dontShowModal: true }));
                                                    }}
                                                >
                                                    {geti18nText('navbar.attendance.noShow')}
                                                </Button>
                                                <Button className={'button-success'} onClick={workLogin}>
                                                    {geti18nText('navbar.attendance.login')}
                                                </Button>
                                            </>
                                        }
                                    />
                                </Modal>
                            )}
                            {/* <SetupWizard /> */}
                            <ModalViewIndex />
                        </Content>
                    </Layout>
                    {pinedNotifications > 0 ? (
                        <NotificationsIndex key={'pinned'} isPinned={true}></NotificationsIndex>
                    ) : (
                        <div></div>
                    )}
                </Layout>
            </Layout>
        );
    }
};

export default LayoutIndex;
