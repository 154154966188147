import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';

interface IApplicationSettingsState {
    applicationSettings: any;
    mealSyncTypeHNB: any;
}

const initialState: IApplicationSettingsState = {
    applicationSettings: {},
    mealSyncTypeHNB: false,
};

const applicationSettingsSlice = createSlice({
    name: 'applicationSettings',
    initialState,
    reducers: {
        applicationSettingsInit(state) {
            state.applicationSettings = {};
        },
        setApplicationSettingsSlice(state, action: PayloadAction<IApplicationSettingsState>) {
            state.applicationSettings = action.payload;
        },
        setIsSyncTypeHNB(state, action: any) {
            state.mealSyncTypeHNB = action.payload;
        },
    },
});

export const { applicationSettingsInit, setApplicationSettingsSlice, setIsSyncTypeHNB } =
    applicationSettingsSlice.actions;

export default applicationSettingsSlice.reducer;

export const fetchApplicationSettingsSlice = (): AppThunk => async (dispatch) => {
    const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { undefined });
    if (result.status === RESPONSE.OK) {
        if (result.data) {
            let data = result.data;
            let settingsValue = data;
            dispatch(setApplicationSettingsSlice(settingsValue));
            dispatch(
                setIsSyncTypeHNB(
                    settingsValue &&
                        Object.entries(settingsValue).length !== 0 &&
                        settingsValue.find((item: any) => item.settingKey === 'MEALS_SYNC_TYPE').value == 'hnb'
                        ? true
                        : false
                )
            );
        }
    }
};
