import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyDatePicker,
    NySearchField,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { ExternalFiltersProps } from '@nybble/nyreact/build/ny-data-table/types';
import { Button, Col, Form, Modal, Row } from 'antd';
import { useState } from 'react';
import NyReportButton from '../../../../components/report-button';
import RowAction from '../../../../components/row-action';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookPerson,
    getColumnSearchByCodebookSimple,
} from '../../../../utils/Filters';
import {
    GetWorkplaceStatusIcon,
    customEmployeeRenderOption,
    getDateFormat,
    getEnumArrayStatus,
} from '../../../../utils/Utils';
import { BusinessUnitSearch } from '../business-unit/search';
import EmployeeIndex from '../employee';
import PortfolioIndex from '../portfolio';
import WorkplaceEdit from './edit';
import HierarchyTableFilter from './hierarchyTableFilter';
import WorkplaceSearch from './search';
import moment from 'moment';
import PDFPreview from '../../../../components/pdf-preview';

const WorkplaceIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = { y: 400, x: 800 },
    sortOrder,
    defaultFilterValue,
    isSearchPopupComponent = false,
}: any) => {
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);
    const [reportModal2Visible, setReportModal2Visible] = useState<boolean>(false);
    const [selectedWorkplace, setSelectedWorkplaces] = useState<any>(null);
    const [minWorkplaceDate, setMinWorkplaceDate] = useState<any>(null);
    const [maxWorkplaceDate, setMaxWorkplaceDate] = useState<any>(null);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const table = useTableSettings();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [filterForm] = Form.useForm();
    const [filterFormBusinessUnit] = Form.useForm();

    useHelper('human/codebooks/workplace');

    const canCreate = () => {
        return HumanResourcesRights.canCreateWorkplace();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportWorkplace();
    };

    const setDefaultFilterValue = () => {
        return defaultFilterValue ? defaultFilterValue : table.setDefaultFilterValue();
    };

    const disabledStartDate = (current: any) => {
        if (moment(minWorkplaceDate).startOf('day') > moment(form.getFieldValue('date'))) {
            setDate(getDateFormat(minWorkplaceDate));
            form.setFieldsValue({ date: moment(minWorkplaceDate) });
        }

        if (current) {
            return (
                current < moment(minWorkplaceDate).startOf('day') || current > moment(maxWorkplaceDate).startOf('day')
            );
        }
    };

    const columns = [
        {
            title: geti18nText('workplace.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('workplace.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('workplace.table.column.vocation'),
            dataIndex: ['vocation', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('workplace.table.column.vocationDescription'),
            dataIndex: ['vocationDescription', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                'vocationDescription.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('workplace.table.column.sector'),
            dataIndex: ['dtoWorkplaceBusinessUnit', 'businessUnitTypeName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.BUSINESS_UNIT_TYPE.SEARCH,
                'dtoWorkplaceBusinessUnit.businessUnitTypeCode',
                'name',
                'name',
                { id: 'code', label: 'name', text: 'name' }
            ),
        },
        {
            title: geti18nText('workplace.table.column.portfolio'),
            dataIndex: "concat(person.firstName, ' ', person.lastName)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookPerson(),
            render: (text: any, record: any) => {
                if (record.person) {
                    return record.person.firstName + ' ' + record.person.lastName;
                }
            },
        },
        {
            title: geti18nText('workplace.table.column.status'),
            dataIndex: 'status',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(
                getEnumArrayStatus('WORKPLACE_STATUS'),
                'in',
                defaultFilterValue ? [enWorkplaceStatus.CONFIRMED].toString() : undefined
            ),
            render: (status: any, record: any) => {
                return GetWorkplaceStatusIcon(record.status);
            },
            defaultFilteredValue: setDefaultFilterValue(),
        },
        {
            title: geti18nText('workplace.table.column.active'),
            dataIndex: 'active',
            width: '6%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: setDefaultFilterValue(),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '80px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <RowAction
                            component="DownloadReport"
                            record={record}
                            reportUrl={CONSTANTS_REQ.REPORT.WORKPLACE}
                            reportCustomParms={{ workplaceId: record.id }}
                            reportSubreportType={'WORKPLACE'}
                            reportText={'workplace.button.generateReport.action'}
                            reportFileNameEnum={EnSubreportType.WORKPLACE}
                        />
                    );
                }
            },
        },
    ];

    const addedButtons = () => {
        return (
            <>
                {!isSearchPopupComponent && (
                    <div style={{ float: 'left' }}>
                        <Button
                            onClick={() => {
                                setReportModalVisible(true);
                                setDate(getDateFormat(moment()));
                                form.setFieldsValue({ date: moment() });
                            }}
                            style={{ marginRight: '15px' }}
                            icon={
                                <FilePdfOutlined
                                    style={{
                                        fontSize: '16px',
                                        paddingRight: '10px',
                                        paddingTop: '2px',
                                        float: 'left',
                                    }}
                                />
                            }
                        >
                            {geti18nText('workplace.button.generateReport.action')}
                        </Button>
                        <Button
                            onClick={() => setReportModal2Visible(true)}
                            icon={
                                <FilePdfOutlined
                                    style={{
                                        fontSize: '16px',
                                        paddingRight: '10px',
                                        paddingTop: '2px',
                                        float: 'left',
                                    }}
                                />
                            }
                        >
                            {geti18nText('workplace.button.generateReport.action2')}
                        </Button>
                    </div>
                )}
            </>
        );
    };

    const onCancel = () => {
        setReportModalVisible(false);
        setReportModal2Visible(false);
        form.setFieldsValue({ workplaces: [] });
        form2.setFieldsValue({ businessUnit: null });
        form.setFieldsValue({ date: null });
        form2.setFieldsValue({ date: null });
        setMinWorkplaceDate(null);
        setMaxWorkplaceDate(null);
    };

    const customFooter = (
        <div style={{ display: 'inline-flex' }}>
            <Button onClick={onCancel} style={{ marginRight: '15px' }}>
                {geti18nText('app.default.button.cancel')}
            </Button>
            <PDFPreview
                url={
                    reportModalVisible
                        ? CONSTANTS_REQ.REPORT.WORKPLACE
                        : CONSTANTS_REQ.REPORT.DECISIONS_ON_WORKPLACE_DESCRIPTION
                }
                customParms={
                    reportModalVisible
                        ? { workplaceId: selectedWorkplace, date: date }
                        : { businessUnitId: selectedBusinessUnit, date: date }
                }
                subreportType={reportModalVisible ? 'WORKPLACE' : 'DECISION_ON_WORKPLACE_DESCRIPTION'}
                buttoni18nText={'app.default.button.download.pdf'}
                fileName={
                    reportModalVisible
                        ? geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.WORKPLACE)
                        : geti18nText('app.enum.SUBREPORT_TYPE.48')
                }
                customDateFormat={moment(date).format('yyyyMMDD')}
            />
        </div>
    );

    const customFilterPortfolio = (triggerFiltering: any) => {
        return (
            <Form.Item
                key={'dto_workplace_business_unit.portfolioId'}
                name={'dto_workplace_business_unit.portfolioId'}
                label={geti18nText('workplace.table.column.portfolio.customLabel')}
            >
                <NySearchField
                    onChange={triggerFiltering}
                    url={CONSTANTS_REQ.PORTFOLIO.SEARCH}
                    searchBy="name"
                    order="name"
                    itemName={'id'}
                    map={{ id: 'id', label: 'name' }}
                    mode={'multiple'}
                    placeholder={geti18nText('workplace.table.column.portfolio.customLabel')}
                    setDefaultFilterValue={setDefaultFilterValue}
                    SearchPopupComponent={<PortfolioIndex disabled={true} />}
                    mustGetPopupContainer={false}
                />
            </Form.Item>
        );
    };

    const customFilterEmployee = (triggerFiltering: any) => {
        return (
            <Form.Item key={'employee_id'} name={'employee_id'} label={geti18nText('workplace.table.column.employee')}>
                <NySearchField
                    onChange={triggerFiltering}
                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                    map={{
                        id: 'id',
                        label: 'text',
                        employmentRecordId: 'employmentRecordId',
                        businessUnit: 'businessUnit',
                    }}
                    searchBy="person.first_name || ' ' || person.last_name"
                    itemName={[
                        ['person', 'firstName'],
                        ['person', 'lastName'],
                    ]}
                    renderOption={customEmployeeRenderOption}
                    customItemNameLabel={'firstName lastName'}
                    SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="workplace" />}
                    style={{ width: '100%' }}
                    placeholder={geti18nText('travelWarrant.edit.employee')}
                    mode={'multiple'}
                    order="person.last_name, person.first_name"
                    setDefaultFilterValue={setDefaultFilterValue}
                    mustGetPopupContainer={false}
                    customListWidth={'1200px'}
                />
            </Form.Item>
        );
    };

    const externalFiltersConfig: ExternalFiltersProps = {
        align: 'middle',
        rowGutter: 24,
        justifyContent: 'start',
        fields: [
            {
                name: 'hierarchy_business_unit_id',
                render: (triggerFiltering: any) =>
                    HierarchyTableFilter(
                        triggerFiltering,
                        filterForm,
                        filterFormBusinessUnit,
                        'hierarchy_business_unit_id',
                        '',
                        'in',
                        setDefaultFilterValue
                    ),
                condition: 'in',
                colspan: 24,
                order: 0,
                offset: 0,
            },
            {
                name: 'dto_workplace_business_unit.portfolioId',
                render: (triggerFiltering: any) => customFilterPortfolio(triggerFiltering),
                condition: 'in',
                colspan: 12,
                order: 0,
                offset: 0,
            },
            {
                name: 'employee_id',
                render: (triggerFiltering: any) => customFilterEmployee(triggerFiltering),
                condition: 'in',
                colspan: 12,
                order: 0,
                offset: 0,
            },
        ],
    };
    return (
        <>
            <NyDataTable
                nyId="workplaceTable"
                url={CONSTANTS_REQ.WORKPLACE.LIST}
                addNewButtonText={geti18nText('workplace.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={WorkplaceEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={columns}
                hideNewButton={!canCreate()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                shortcuts={true}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.workplace')}
                colCSVCustomization={csvColumnCustomisation()}
                headerTitle={geti18nText('menu.workplace')}
                addedButtons={addedButtons}
                externalFiltersConfig={externalFiltersConfig}
                filterFormInstance={filterForm}
                hideButtons={disabled}
                readonly={disabled}
            />
            <Modal
                open={reportModalVisible}
                title={geti18nText('workplace.button.generateReport.action')}
                onCancel={onCancel}
                footer={customFooter}
                width={900}
            >
                <Form layout="vertical" form={form}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <WorkplaceSearch
                                onChange={(value: any) => {
                                    const selectedWorkplaceIDs = value.map((item: any) => {
                                        return item.id;
                                    });
                                    setSelectedWorkplaces([selectedWorkplaceIDs]);

                                    if (value && value.length > 0) {
                                        setMinWorkplaceDate(
                                            value
                                                .map((item: any) => item?.label?.props['custom-data'].dateFrom)
                                                .reduce((minDate: number, currentDate: number) => {
                                                    return currentDate < minDate ? currentDate : minDate;
                                                })
                                        );

                                        setMaxWorkplaceDate(
                                            value
                                                .map((item: any) => item?.label?.props['custom-data'].dateTo)
                                                .reduce((maxDate: number | null, currentDate: number | undefined) => {
                                                    if (!currentDate) {
                                                        return null;
                                                    }
                                                    return maxDate === null || currentDate > maxDate
                                                        ? currentDate
                                                        : maxDate;
                                                }, null)
                                        );
                                    } else {
                                        setMinWorkplaceDate(null);
                                        setMaxWorkplaceDate(null);
                                    }
                                }}
                                formItemName="workplaces"
                                mode="multiple"
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="date" label={geti18nText('exchangeRate.edit.date')}>
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => {
                                        setDate(getDateFormat(value));
                                    }}
                                    disabledDate={disabledStartDate}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                open={reportModal2Visible}
                title={geti18nText('workplace.button.generateReport.action2')}
                onCancel={onCancel}
                footer={customFooter}
                width={900}
            >
                <Form layout="vertical" form={form2}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <BusinessUnitSearch
                                onChange={(value: any) => {
                                    if (value !== null) {
                                        setSelectedBusinessUnit(value.id);
                                    }
                                }}
                                formItemName="businessUnit"
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="date" label={geti18nText('exchangeRate.edit.date')}>
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => {
                                        setDate(getDateFormat(value));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.WORKPLACE_STATUS.' + value);
            },
        },
    ];
};

export default WorkplaceIndex;
