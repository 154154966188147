import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';
import { NyUtils } from '@nybble/nyreact';

interface IGeneralSettingsState {
    language: String;
    theme: String;
    font: Number;
    numbersWriting: any;
    itemGroupView: any;
    version: any;
}

const initialState: IGeneralSettingsState = {
    language: 'hr',
    theme: 'default',
    font: 14,
    numbersWriting: true,
    itemGroupView: 'tree',
    version: '1.0.0',
};

const generalSettings = createSlice({
    name: 'generalSettings',
    initialState,
    reducers: {
        generalSettingsInit(state) {
            state.language = 'hr';
            state.theme = 'default';
            state.font = 14;
            state.itemGroupView = 'tree';
            state.numbersWriting = true;
            state.version = '1.0.0';
        },
        setGeneralSettings(state, action: PayloadAction<IGeneralSettingsState>) {
            const { language, theme, numbersWriting, itemGroupView } = action.payload;
            state.language = language;
            state.theme = theme;
            state.numbersWriting = numbersWriting === 'true' ? true : false;
            state.itemGroupView = itemGroupView;
            NyUtils.saveDefaults({ lang: language, theme: theme });
        },
        setTheme(state, action: PayloadAction<IGeneralSettingsState>) {
            const { theme } = action.payload;
            state.theme = theme;
        },
        setFont(state, action: PayloadAction<IGeneralSettingsState>) {
            const { font } = action.payload;
            state.font = font;
        },
        setVersion(state, action) {
            state.version = action.payload;
        },
    },
});

export const { generalSettingsInit, setGeneralSettings, setTheme, setFont, setVersion } = generalSettings.actions;

export default generalSettings.reducer;

export const fetchGeneralSettings = (): AppThunk => async (dispatch) => {
    const settingKey = 'general';
    const result: any = await NyRequestResolver.requestGet(
        CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey,
        { settingKey: settingKey }
    );
    if (result.status == RESPONSE.OK) {
        let data = result.data;
        if (data) {
            let settingsValue = JSON.parse(data.settingValue);
            delete settingsValue.id;
            dispatch(setGeneralSettings(settingsValue));
        }
    }
};
