import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { Popover, Image } from 'antd';
import ReserveRankEdit from './edit';
import { useLocation } from 'react-router-dom';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const ReserveRankIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const location = useLocation();

    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const columns = [
        {
            title: geti18nText('reserve.rank.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('reserve.rank.table.column.fileId'),
            dataIndex: 'fileId',
            width: '10%',
            render: (fileId: any, record: any) => {
                if (record && record.fileId) {
                    return (
                        <Popover
                            title={record?.name}
                            placement="right"
                            content={
                                <Image
                                    style={{
                                        objectFit: 'cover',
                                        width: '300px',
                                        borderRadius: '5px',
                                    }}
                                    preview={false}
                                    src={
                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                        '/' +
                                        record.fileId +
                                        '?tenant=' +
                                        NySession.getUser().tenantId
                                    }
                                />
                            }
                        >
                            <Image
                                style={{
                                    objectFit: 'cover',
                                    height: '64px',
                                    borderRadius: '5px',
                                }}
                                preview={false}
                                src={
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    record.fileId +
                                    '?tenant=' +
                                    NySession.getUser().tenantId
                                }
                            />
                        </Popover>
                    );
                }
            },
        },
        {
            title: geti18nText('reserve.rank.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('reserve.rank.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-reserve-rank-table"
            url={CONSTANTS_REQ.RESERVE_RANK.LIST}
            addNewButtonText={geti18nText('reserve.rank.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={ReserveRankEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            // exportCSV={canExportCSV()}
            // CSVFileName={geti18nText('menu.work.obligation.reserve.rank')}
            // colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.work.obligation.reserve.rank')}
        />
    );
};

export default ReserveRankIndex;
