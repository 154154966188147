import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import ProgramCatalogIndex from '.';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import EducationProgramCatalogEdit from './edit';

const ProgramCatalogSearch = ({
    label = geti18nText('employee.education.edit.programCatalog'),
    name = 'programCatalog',
    required = false,
    onChange,
    autoFocus,
    disabled = false,
    setDefaultFilterValue,
    hide = false,
    renderOption = (option: any) => `${option.text}${option.code ? ' (' + option.code + ')' : ''}`,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateEducationCodebooks();
    };
    return (
        <Form.Item
            style={hide ? { display: 'none' } : {}}
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.PROGRAM_CATALOG.SEARCH}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                searchBy="name,code"
                itemName={[['code'], ['name']]}
                customItemNameLabel={'name (code)'}
                SearchPopupComponent={<ProgramCatalogIndex />}
                AddNewModalComponent={canCreate() ? EducationProgramCatalogEdit : null}
                nyTestId="programCatalog"
                onChange={onChange}
                autoFocus={autoFocus}
                disabled={disabled}
                setDefaultFilterValue={setDefaultFilterValue ? setDefaultFilterValue : null}
                mustGetPopupContainer={false}
            />
        </Form.Item>
    );
};

export default ProgramCatalogSearch;
