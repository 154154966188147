import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Col, Row, Switch } from 'antd';
import { useState } from 'react';

import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookBusinessUnitEmployee,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchByCodebookWorkplace,
} from '../../../../utils/Filters';
import { getDateFormat } from '../../../../utils/Utils';
import DepartmentSearch from '../defense-department/department/search';
import SectionSearch from '../defense-department/section/search';
import NyDescription from '../../../../components/ny-description';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const InsideCompanyTable = ({
    addedButtons,
    obligated,
    scroll = { y: 550, x: 3200 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const table = useTableSettings();
    const [searchVisible, setSearchVisible] = useState<any>(false);
    const [region, setRegion] = useState<any>(undefined);
    const [departmentId, setDepartmentId] = useState<any>(-1);
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const [defenseSection, setDefenseSection] = useState<any>(undefined);
    const [defenseSectionId, setDefenseSectionId] = useState<any>(-1);

    const onChange = (checked: boolean) => {
        if (checked) {
            departmentId != -1 && setRefresh((prevValue: any) => prevValue + 1);
            setDepartmentId(-1);
            setDefenseSectionId(-1);
            setRegion(undefined);
            setDefenseSection(undefined);
        }
        setSearchVisible(!checked);
    };

    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const employeeMilitaryRecordsAssigned = useEnum('EMPLOYEE_MILITARY_RECORDS_ASSIGNED');

    const isMobilizationAdmin = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const isHumanResourcesAdmin = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const columns = [
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit.id'),
            dataIndex: ['businessUnit','code'],
            render: (text: any, record: any) => {
                return record?.employee?.businessUnit?.code;
            },
            width: '70px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnitEmployee(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit'),
            dataIndex: ['businessUnit', 'name'],
            render: (text: any, record: any) => {
                return record?.employee?.businessUnit?.name;
            },
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnitEmployee(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.name.lastname'),
            dataIndex: "concat(person.last_name,' ', person.first_name)",
            width: '120px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.employee)}>
                            {(record.employee.person && record.employee.person.lastName
                                ? record.employee.person.lastName + ', '
                                : '') +
                                (record.employee.person && record.employee.person.parentName
                                    ? record.employee.person.parentName + ', '
                                    : '') +
                                (record.employee.person && record.employee.person.firstName
                                    ? record.employee.person.firstName
                                    : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.work.place'),
            //width: '18%',
            width: '200px',
            dataIndex: ['vocation', 'name'],
            render: (text: any, record: any) => {
                if (record?.vocation?.name) {
                    return (
                        <div>
                               {record?.vocation?.name}
                               {record?.vocationDescription?.name ? ' - ' + record.vocationDescription.name : ' '}
                        </div>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookWorkplace(),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.oib) {
                    return record.employee.person.oib;
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.birthdate'),
            dataIndex: ['person', 'birthDate'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.birthDate) {
                    return getDateFormat(record.employee.person.birthDate, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.qualifications'),
            dataIndex: ['employee','vocation','professionalQualification', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                'employeeVocationProfessionalQualification.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                    code: 'code',
                }
            ),
           render: (created: any, record: any) => {
                if (record.employee && record?.employee?.vocation?.professionalQualification) {
                    return record.employee.vocation.professionalQualification;
                }
            }, 
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.professionalSpecialty'),
            dataIndex: ['professionalSpecialty', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return record?.professionalSpecialty?.name;
            },
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.PROFESSIONAL_SPECIALTY.SEARCH,
                'professionalSpecialty.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.department.defense'),
            dataIndex: ['defenseDepartment', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseDepartment.id',
                setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'),
            dataIndex: ['defenseSection', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseSection.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.address.full'),
            dataIndex: [`concat(address.street,' ', settlement.name)`],
            width: '120px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.address) {
                    return (
                        <div>
                            {(record.address.street ? record.address.street + ', ' : '') +
                                (record.address.settlement ? record.address.settlement.name : '')}
                        </div>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobilization.location'),
            dataIndex: ['mobilizationLocation', 'name'],
            width: '160px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                return record?.mobilizationLocation?.name;
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobile.full'),
            dataIndex: ['contact', 'mobile'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.email.full'),
            dataIndex: ['contact', 'email'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.remark'),
            dataIndex: 'remark',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (remark: any, record: any) => {
                if (record?.remark) {
                    return <NyDescription title={record?.remark} maxWidth={'150px'} />;
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    const getSectionFilter = () => {
        if (departmentId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: defenseDepartmentType.SECTION,
                },
                { field: 'parentId', condition: 'equals', value: departmentId },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: defenseDepartmentType.SECTION,
                },
            ];
        }
    };

    const setDepartmentFilterValue = () => {
        return departmentId != -1 && defenseSectionId != -1
            ? [
                  { field: 'active', condition: 'equals_bool', value: 1 },
                  {
                      field: 'assign',
                      condition: 'equals',
                      value: employeeMilitaryRecordsAssigned.YES,
                  },
                  { field: 'obligated', condition: 'equals_bool', value: obligated },
                  { field: 'defenseDepartment.id', condition: 'equals', value: departmentId },
                  { field: 'defenseSection.id', condition: 'equals', value: defenseSectionId },
              ]
            : departmentId != -1
            ? [
                  { field: 'active', condition: 'equals_bool', value: 1 },
                  {
                      field: 'assign',
                      condition: 'equals',
                      value: employeeMilitaryRecordsAssigned.YES,
                  },
                  { field: 'obligated', condition: 'equals_bool', value: obligated },
                  { field: 'defenseDepartment.id', condition: 'equals', value: departmentId },
              ]
            : [
                  { field: 'active', condition: 'equals_bool', value: 1 },
                  {
                      field: 'assign',
                      condition: 'equals',
                      value: employeeMilitaryRecordsAssigned.YES,
                  },
                  { field: 'obligated', condition: 'equals_bool', value: obligated },
              ];
    };

    return (
        <>
            <Row>
                <Col span={24} style={{ marginLeft: '1rem', marginBottom: '-24px' }}>
                    <Row>
                        <Switch defaultChecked={true} onChange={onChange} />
                        <div style={{ marginLeft: '1rem' }}>
                            {geti18nText('schedule.suggestions.modal.employees.switch.total')}
                        </div>
                        <div style={{ marginLeft: '1rem' }}>
                            {' ' + geti18nText('schedule.suggestions.modal.employees.search.region')}
                        </div>

                        <Col span={4} style={{ marginLeft: '1rem', top: '-2px', marginBottom: '8px' }}>
                            <DepartmentSearch
                                isFormItem={false}
                                disabled={!searchVisible}
                                canCreate={false}
                                value={region}
                                onChange={(e: any) => {
                                    setDepartmentId(e?.id);
                                    setRefresh((prevValue: any) => prevValue + 1);
                                    setRegion(e);
                                    if (e?.id == -1) {
                                        setDefenseSection(undefined);
                                        setDefenseSectionId(-1);
                                    }
                                    setDefenseSection(undefined);
                                }}
                            />
                        </Col>
                        <div style={{ marginLeft: '1rem' }}>
                            {' ' + geti18nText('mobilization.edit.defenseSectionName')}
                        </div>
                        <Col span={4} style={{ marginLeft: '1rem', top: '-2px', marginBottom: '8px' }}>
                            <SectionSearch
                                isFormItem={false}
                                disabled={!searchVisible || departmentId == -1}
                                setDefaultFilterValue={getSectionFilter}
                                canCreate={isMobilizationAdmin() || isHumanResourcesAdmin()}
                                departmentId={departmentId}
                                value={defenseSection}
                                onChange={(e: any) => {
                                    setDefenseSectionId(e?.id);
                                    setRefresh((prevValue: any) => prevValue + 1);
                                    setDefenseSection(e);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <NyDataTable
                nyId="human-inside-company-table"
                url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.LIST}
                buttonsClassName="buttons-sticky"
                columns={columns}
                fetchWhenChange={refresh}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDepartmentFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                addedButtons={addedButtons}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default InsideCompanyTable;
