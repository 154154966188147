import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { numberFormat } from '../../../../../utils/Utils';
import { AdministrationRights } from '../../../../../rights/administrationRights';

const ContractHrIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('human/codebooks/contracts/contract-hr');

    const canExportCSV = () => {
        return AdministrationRights.isAdmin();
    };

    const columns = [
        {
            title: geti18nText('contract.hr.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('contract.hr.table.column.contract'),
            dataIndex: ['contract', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.CONTRACT_CTR.SEARCH, 'contract.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('contract.hr.table.column.employee'),
            dataIndex: "concat(person.first_name,' ', person.last_name)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record?.person) {
                    return (
                        <div>
                            {record.person?.firstName +
                                ' ' +
                                record.person?.lastName +
                                (record?.employee?.employmentRecordId
                                    ? ' (' + record.employee.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('contract.hr.table.column.gross'),
            dataIndex: 'gross',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (gross: any) => {
                return gross && numberFormat(gross);
            },
        },
        {
            title: geti18nText('contract.hr.table.column.point'),
            dataIndex: 'point',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (point: any) => {
                return point && numberFormat(point);
            },
        },
        {
            title: geti18nText('contract.hr.table.column.coefficient'),
            dataIndex: 'coefficient',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (point: any) => {
                return point && numberFormat(point);
            },
        },
        {
            title: geti18nText('contract.hr.table.column.hourly'),
            dataIndex: 'hourly',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (point: any) => {
                return point && numberFormat(point);
            },
        },
        {
            title: geti18nText('contract.hr.table.column.validFrom'),
            dataIndex: 'validFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('contract.hr.table.column.validTo'),
            dataIndex: 'validTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('contract.hr.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-contract-contract-hr-table"
            url={CONSTANTS_REQ.CONTRACT_HR.LIST}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.contract.hr')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.contract.hr')}
            csvColumns={csvColumns()}
        />
    );
};
export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.hr.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.hr.table.column.contract'),
            dataIndex: ['contract', 'name'],
        },
        {
            title: geti18nText('contract.hr.table.column.employee'),
            dataIndex: 'person',
        },
        {
            title: geti18nText('contract.hr.table.column.gross'),
            dataIndex: 'gross',
        },
        {
            title: geti18nText('contract.hr.table.column.point'),
            dataIndex: 'point',
        },
        {
            title: geti18nText('contract.hr.table.column.coefficient'),
            dataIndex: 'coefficient',
        },
        {
            title: geti18nText('contract.hr.table.column.hourly'),
            dataIndex: 'hourly',
        },
        {
            title: geti18nText('contract.hr.table.column.validFrom'),
            dataIndex: 'validFrom',
        },
        {
            title: geti18nText('contract.hr.table.column.validTo'),
            dataIndex: 'validTo',
        },
        {
            title: geti18nText('contract.hr.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            person: (value: any) => {
                return value ? value?.firstName + ' ' + value?.lastName : '';
            },
        },
        {
            gross: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            point: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            coefficient: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            hourly: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ContractHrIndex;
