import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { numberFormat } from '../../../../utils/Utils';
import MealEdit from './edit';
import { MealsRights } from '../../../../rights/mealsRights';

const MealIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('meals/codebooks/meal');
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const [refreshTable, setRefreshTable] = useState<any>(1);

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        ...[
            {
                title: geti18nText('meal.table.column.id'),
                dataIndex: 'id',
                width: '4%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
        ],
        ...(mealSyncTypeHNB === true
            ? [
                  {
                      title: geti18nText('meal.table.column.refId'),
                      dataIndex: 'refId',
                      width: '8%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('meal.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('meal.table.column.allergen'),
                dataIndex: 'allergen',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (allergen: any, record: any) => {
                    if (record && record.allergen) {
                        return (
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record.allergen}>
                                <div className={'overview-cards-paragraph-div'}>
                                    <p className={'overview-cards-paragraph'}>{record && record.allergen}</p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('meal.table.column.description'),
                dataIndex: 'description',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (description: any, record: any) => {
                    if (record && record.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '200px' }}
                                title={record.description}
                            >
                                <div className={'overview-cards-paragraph-div'}>
                                    <p className={'overview-cards-paragraph'}>{record && record.description}</p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('meal.table.column.minOrder'),
                dataIndex: 'minOrder',
                ...getColumnSearch('number'),
                render: (minOrder: any) => {
                    return minOrder ? numberFormat(minOrder) : '';
                },
            },
            {
                title: geti18nText('meal.table.column.active'),
                dataIndex: 'active',
                width: '4%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
            },
        ],
    ];

    return (
        <NyDataTable
            nyId="meals-table"
            url={CONSTANTS_REQ.MEAL.LIST}
            addNewButtonText={geti18nText('meal.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideButtons={readonly}
            modalComponent={MealEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.meals.meal')}
            colCSVCustomization={csvColumnCustomisation()}
            editProps={{ setRefreshTable, refreshTable, scroll: { y: 400, x: 500 }, sortOrder: null }}
            fetchWhenChange={refreshTable}
            headerTitle={geti18nText('menu.meals.meal')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default MealIndex;
