import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Layout, Layouts } from 'react-grid-layout';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';
import { getErrorNotificationMessage, okNotification, stringGenerator } from '../utils/Utils';
import { TravelWarrantRights } from '../rights/travelWarrantRights';
import { HumanResourcesRights } from '../rights/humanResourcesRights';
import { AssetRights } from '../rights/assetRights';
import { InventoryRights } from '../rights/inventoryRights';
import { ServicesRights } from '../rights/servicesRights';
import { MealsRights } from '../rights/mealsRights';

const allWidgets = [
    {
        key: 'PersonalCalendarWidget',
        component: 'PersonalCalendarWidget',
        dataGrid: { w: 4, h: 6, x: 0, y: 0, minW: 3, minH: 6, maxW: 12, maxH: 6, static: false },
        onResizeRedraw: true,
    },
    {
        key: 'DirectoryWidget',
        component: 'DirectoryWidget',
        dataGrid: { w: 4, h: 6, x: 0, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
    },
    {
        key: 'LinksWidget',
        component: 'LinksWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
    },
    {
        key: 'NotificationWidget',
        component: 'NotificationWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
    },
    {
        key: 'PersonalNotesWidget',
        component: 'PersonalNotesWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
    },
    {
        key: 'TravelWarrantWidget',
        component: 'TravelWarrantWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantAdminRoutes(),
    },
    {
        key: 'TravelWarrantPersonalWidget',
        component: 'TravelWarrantPersonalWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantUserRoutes(),
    },
    {
        key: 'EmployeeLeavePersonalWidget',
        component: 'EmployeeLeavePersonalWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: HumanResourcesRights.canViewMenuEmployeeLeaveUser(),
    },
    {
        key: 'AssetOrderWidget',
        component: 'AssetOrderWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: AssetRights.canViewAssetRequestRoutes(),
    },
    {
        key: 'InventoryOrderWidget',
        component: 'InventoryOrderWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: InventoryRights.canViewInventoryRequestRoutes(),
    },
    {
        key: 'ServicesOrderWidget',
        component: 'ServicesOrderWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 0, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: ServicesRights.canViewServicesRequestRoutes(),
    },
    {
        key: 'TravelWarrantLightWidget',
        component: 'TravelWarrantLightWidget',
        dataGrid: { w: 2, h: 2, x: 0, y: 0, minW: 2, minH: 2, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantAdminRoutes(),
    },
    {
        key: 'TravelWarrantPersonalLightWidget',
        component: 'TravelWarrantPersonalLightWidget',
        dataGrid: { w: 2, h: 2, x: 0, y: 0, minW: 2, minH: 2, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantUserRoutes(),
    },
    {
        key: 'EmployeeLeavePersonalLightWidget',
        component: 'EmployeeLeavePersonalLightWidget',
        dataGrid: { w: 2, h: 2, x: 0, y: 0, minW: 2, minH: 2, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: HumanResourcesRights.canViewMenuEmployeeLeaveUser(),
    },
    {
        key: 'AssetLightWidget',
        component: 'AssetLightWidget',
        dataGrid: { w: 2, h: 2, x: 0, y: 0, minW: 2, minH: 2, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: AssetRights.canViewAssetRequestRoutes(),
    },
    {
        key: 'InventoryOrderLightWidget',
        component: 'InventoryOrderLightWidget',
        dataGrid: { w: 2, h: 2, x: 0, y: 0, minW: 2, minH: 2, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: InventoryRights.canViewInventoryRequestRoutes(),
    },
    {
        key: 'ServicesOrderLightWidget',
        component: 'ServicesOrderLightWidget',
        dataGrid: { w: 2, h: 2, x: 0, y: 0, minW: 2, minH: 2, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: ServicesRights.canViewServicesRequestRoutes(),
    },
    {
        key: 'TravelWarrantExtraLightWidget',
        component: 'TravelWarrantExtraLightWidget',
        dataGrid: { w: 1, h: 1, x: 0, y: 0, minW: 1, minH: 1, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantAdminRoutes(),
    },
    {
        key: 'TravelWarrantPersonalExtraLightWidget',
        component: 'TravelWarrantPersonalExtraLightWidget',
        dataGrid: { w: 1, h: 1, x: 0, y: 0, minW: 1, minH: 1, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantUserRoutes(),
    },
    {
        key: 'EmployeeLeavePersonalExtraLightWidget',
        component: 'EmployeeLeavePersonalExtraLightWidget',
        dataGrid: { w: 1, h: 1, x: 0, y: 0, minW: 1, minH: 1, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: HumanResourcesRights.canViewMenuEmployeeLeaveUser(),
    },
    {
        key: 'AssetOrderExtraLightWidget',
        component: 'AssetOrderExtraLightWidget',
        dataGrid: { w: 1, h: 1, x: 0, y: 0, minW: 1, minH: 1, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: AssetRights.canViewAssetRequestRoutes(),
    },
    {
        key: 'InventoryOrderExtraLightWidget',
        component: 'InventoryOrderExtraLightWidget',
        dataGrid: { w: 1, h: 1, x: 0, y: 0, minW: 1, minH: 1, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: InventoryRights.canViewInventoryRequestRoutes(),
    },
    {
        key: 'ServicesOrderExtraLightWidget',
        component: 'ServicesOrderExtraLightWidget',
        dataGrid: { w: 1, h: 1, x: 0, y: 0, minW: 1, minH: 1, maxW: 2, maxH: 2, static: false },
        onResizeRedraw: true,
        role: ServicesRights.canViewServicesRequestRoutes(),
    },
    {
        key: 'KioskWidget',
        component: 'KioskWidget',
        dataGrid: { w: 4, h: 6, x: 0, y: 12, minW: 2, minH: 6, maxW: 12, maxH: 6, static: false },
        onResizeRedraw: true,
    },
    {
        key: 'MealsMenuWidget',
        component: 'MealsMenuWidget',
        dataGrid: { w: 2, h: 6, x: 4, y: 12, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: MealsRights.isOnlyMealsUserMenu(),
    },
];

const defaultWidgets = [
    {
        key: 'PersonalCalendarWidget',
        component: 'PersonalCalendarWidget',
        dataGrid: { w: 4, h: 6, x: 0, y: 0, minW: 3, minH: 6, maxW: 12, maxH: 6, static: true },
        onResizeRedraw: true,
    },
    {
        key: 'DirectoryWidget',
        component: 'DirectoryWidget',
        dataGrid: { w: 2, h: 6, x: 4, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
    },
    {
        key: 'LinksWidget',
        component: 'LinksWidget',
        dataGrid: { w: 2, h: 6, x: 6, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
    },
    {
        key: 'NotificationWidget',
        component: 'NotificationWidget',
        dataGrid: { w: 2, h: 6, x: 8, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
    },
    {
        key: 'PersonalNotesWidget',
        component: 'PersonalNotesWidget',
        dataGrid: { w: 2, h: 6, x: 10, y: 0, minW: 2, minH: 6, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
    },
    {
        key: 'AssetOrderWidget',
        component: 'AssetOrderWidget',
        dataGrid: { w: 2, h: 6, x: 0, y: 6, minW: 2, minH: 3, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: AssetRights.canViewAssetRequestRoutes(),
    },
    {
        key: 'TravelWarrantLightWidget',
        component: 'TravelWarrantLightWidget',
        dataGrid: { w: 2, h: 2, x: 2, y: 6, minW: 2, minH: 2, maxW: 2, maxH: 2, static: true },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantAdminRoutes(),
    },
    {
        key: 'TravelWarrantPersonalWidget',
        component: 'TravelWarrantPersonalWidget',
        dataGrid: { w: 2, h: 4, x: 2, y: 8, minW: 2, minH: 3, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: TravelWarrantRights.canViewTravelWarrantUserRoutes(),
    },
    {
        key: 'InventoryOrderWidget',
        component: 'InventoryOrderWidget',
        dataGrid: { w: 2, h: 6, x: 4, y: 6, minW: 2, minH: 3, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: InventoryRights.canViewInventoryRequestRoutes(),
    },
    {
        key: 'ServicesOrderWidget',
        component: 'ServicesOrderWidget',
        dataGrid: { w: 2, h: 6, x: 6, y: 6, minW: 2, minH: 3, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: ServicesRights.canViewServicesRequestRoutes(),
    },
    {
        key: 'EmployeeLeavePersonalWidget',
        component: 'EmployeeLeavePersonalWidget',
        dataGrid: { w: 2, h: 6, x: 8, y: 6, minW: 2, minH: 3, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.canViewMenuEmployeeLeaveUser(),
    },
    {
        key: 'MealsMenuWidget',
        component: 'MealsMenuWidget',
        dataGrid: { w: 2, h: 6, x: 10, y: 6, minW: 2, minH: 3, maxW: 6, maxH: 6, static: false },
        onResizeRedraw: true,
        role: MealsRights.isOnlyMealsUserMenu(),
    },
];

interface IEmployeeState {
    widgets?: any;
    allWidgets?: any;
    layouts?: Layouts;
    lastLayout?: Layout[];
    lock?: Boolean;
    key?: string;
}

const initialState: IEmployeeState = {
    widgets: [],
    allWidgets: [...allWidgets],
    layouts: {},
    lastLayout: [],
    lock: true,
    key: '',
};

const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        dashboardInit(state) {
            state.allWidgets = [...allWidgets];
            state.widgets = [];
            state.layouts = {};
            state.lastLayout = [];
            state.lock = true;
            state.key = '';
        },
        widgetsInit(state) {
            state.widgets = [...defaultWidgets];
        },
        widgetsSetDefaultValue(state, action: PayloadAction<IEmployeeState>) {
            const { widgets } = action.payload;
            state.widgets = widgets;
        },
        setLayouts(state, action: PayloadAction<IEmployeeState>) {
            const { layouts } = action.payload;
            state.layouts = layouts;
        },
        setLastLayouts(state, action: PayloadAction<IEmployeeState>) {
            const { lastLayout } = action.payload;
            state.lastLayout = lastLayout;
        },
        setLock(state, action: PayloadAction<IEmployeeState>) {
            state.lock = !state.lock;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }
            if (state.lastLayout) {
                let newLastLayout = state.lastLayout.map((val) => {
                    return { ...val, static: !val.static };
                });
                state.lastLayout = newLastLayout;
                if (state.lock) {
                    save(
                        state.lastLayout,
                        action.payload && action.payload.widgets,
                        action.payload && action.payload.allWidgets
                    );
                }
            }
        },
        setDefaultLayout(state, action: PayloadAction<IEmployeeState>) {
            state.lock = true;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }

            if (state.lastLayout) {
                let newLastLayout = state.lastLayout.map((val) => {
                    return { ...val, static: !val.static };
                });
                state.lastLayout = newLastLayout;
                if (state.lock) {
                    save(
                        state.lastLayout,
                        action.payload && action.payload.widgets,
                        action.payload && action.payload.allWidgets
                    );
                }
            }
        },
        defineDefaultLayout(state, action: PayloadAction<IEmployeeState>) {
            state.lock = true;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }

            if (state.lastLayout) {
                let newLastLayout = state.lastLayout.map((val) => {
                    return { ...val, static: !val.static };
                });
                state.lastLayout = newLastLayout;
                if (state.lock) {
                    defineDefault(
                        state.lastLayout,
                        action.payload && action.payload.widgets,
                        action.payload && action.payload.allWidgets
                    );
                }
            }
        },
        removeWidget(state, action: PayloadAction<IEmployeeState>) {
            const { key } = action.payload;
            state.widgets = state.widgets.filter((wid: any) => wid.key !== key);
        },
        addWidget(state, action) {
            const { key, data } = action.payload;
            let widget = allWidgets.find((item: any) => item.key === key);
            if (widget) {
                let newWidget: any = _.cloneDeep(widget);
                newWidget.key = newWidget.key + '_' + stringGenerator(5);
                if (data) {
                    newWidget.data = data;
                }
                state.widgets = [...state.widgets, newWidget];
            }
        },
        layoutChange(state, action: PayloadAction<IEmployeeState>) {
            const { lastLayout, layouts } = action.payload;
            state.lastLayout = lastLayout;
            state.layouts = layouts;
            let widgetList = [...state.widgets];
            widgetList.forEach((w: any) => {
                let position = lastLayout && lastLayout.find(({ i }) => i === w.key);
                let clonePosition: { [index: string]: any } = { ...position };
                if (clonePosition.i != null) delete clonePosition.i;
                w.dataGrid = clonePosition;
            });
            state.widgets = widgetList;
        },
        setLayoutForUser(state, action: PayloadAction<IEmployeeState>) {
            const widgetsForUser = action.payload;
            state.widgets = widgetsForUser;
        },
        setLayoutDefault(state, action: PayloadAction<IEmployeeState>) {
            const widgetsForUser = action.payload ?? defaultWidgets;
            let lastLayout: any = action.payload ?? defaultWidgets;
            state.widgets = widgetsForUser;
            state.allWidgets = allWidgets;
            state.lock = true;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }
            if (lastLayout) {
                let newLastLayout = lastLayout.map((val: any) => {
                    return { ...val, static: !val.static };
                });
                lastLayout = newLastLayout;
                state.lastLayout = newLastLayout;

                if (state.lock) {
                    save(lastLayout, widgetsForUser, allWidgets, true);
                }
            }
        },
    },
});

const normalizeDefault = (lastLayout: any, widgets: any, allWidgets: any) => {
    const settingsValue: any = [];
    if (lastLayout && widgets && allWidgets) {
        lastLayout.map((layout: any) => {
            let widgetData = widgets.find((item: any) => item.key === layout.key);
            const component = widgetData.component;
            let widget = allWidgets.find((item: any) => item.component === component);
            let data = layout.data ?? null;
            if (widget) {
                let role = widget.role ?? null;
                let layoutValue: any = {
                    key: layout.key,
                    component: component,
                    dataGrid: layout.dataGrid,
                    role: role,
                    data: data,
                    onResizeRedraw: true,
                };
                if (layoutValue.role == null) {
                    delete layoutValue.role;
                }
                if (layoutValue.data == null) {
                    delete layoutValue.data;
                }
                settingsValue.push(layoutValue);
            }
        });
    }
    return settingsValue;
};

const normalize = (lastLayout: any, widgets: any, allWidgets: any) => {
    const settingsValue: any = [];
    if (lastLayout && widgets && allWidgets) {
        lastLayout.map((layout: any) => {
            let widgetData = widgets.find((item: any) => item.key === layout.i);
            const component = widgetData.component;
            let widget = allWidgets.find((item: any) => item.component === component);
            let data = widgetData.data ?? null;
            if (widget) {
                let role = widget.role ?? null;
                let layoutValue: any = {
                    key: layout.i,
                    component: component,
                    dataGrid: {
                        w: layout.w,
                        h: layout.h,
                        x: layout.x,
                        y: layout.y,
                        minW: layout.minW,
                        minH: layout.minH,
                        maxW: layout.maxW,
                        maxH: layout.maxH,
                        static: layout.static,
                    },
                    role: role,
                    data: data,
                    onResizeRedraw: true,
                };
                if (layoutValue.role == null) {
                    delete layoutValue.role;
                }
                if (layoutValue.data == null) {
                    delete layoutValue.data;
                }
                settingsValue.push(layoutValue);
            }
        });
    }
    return settingsValue;
};

const save = (lastLayout: any, widgets: any, allWidgets: any, defaultLayout: boolean = false) => {
    let settingsValue: any = [];
    if (defaultLayout) {
        settingsValue = normalizeDefault(lastLayout, widgets, allWidgets);
    } else {
        settingsValue = normalize(lastLayout, widgets, allWidgets);
    }
    if (settingsValue) {
        let parms: any = {};
        parms.settingKey = 'dashboard';
        parms.settingValue = JSON.stringify(settingsValue);
        NyRequestResolver.requestPost(CONSTANTS_REQ.USER_SESTTINGS.EDIT, undefined, parms).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification();
            }
        });
    }
};

const defineDefault = (lastLayout: any, widgets: any, allWidgets: any) => {
    const settingsValue: any = normalize(lastLayout, widgets, allWidgets);
    if (settingsValue) {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result.data && result.data[0]) {
                    let values = _.cloneDeep(result.data[0]);
                    if (values && values.id) {
                        values.value = JSON.stringify(settingsValue);
                        if (values.value) {
                            NyRequestResolver.requestPut(
                                CONSTANTS_REQ.APPLICATION_SETTINGS.EDIT + '/' + values.id,
                                undefined,
                                values
                            ).then((result: any) => {
                                if (result && result.status === RESPONSE.CREATED) {
                                    okNotification();
                                } else {
                                    getErrorNotificationMessage(result);
                                }
                            });
                        }
                    }
                }
            }
        );
    }
};

export const {
    dashboardInit,
    widgetsInit,
    setLayouts,
    setLastLayouts,
    setLock,
    defineDefaultLayout,
    removeWidget,
    addWidget,
    layoutChange,
    setLayoutForUser,
    setLayoutDefault,
} = dashboard.actions;

export default dashboard.reducer;

const setAppSettingsFilterValue = () => {
    return [
        { field: 'settingModule', condition: 'equals', value: 1 },
        { field: 'settingGroup', condition: 'equals', value: 'GENERAL' },
        { field: 'settingKey', condition: 'equals', value: 'DEFAULT_DASHBOARD' },
    ];
};

export const setDefaultWidgetsValue = (): AppThunk => async (dispatch) => {
    dispatch(dashboardInit());
    const stringUri = encodeURI(JSON.stringify(setAppSettingsFilterValue()));
    NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
        (result: any) => {
            if (result.status === RESPONSE.OK) {
                let settingsValue: any = null;
                if (result.data && result.data[0] && (result.data[0].value || result.data[0].defaultValue)) {
                    settingsValue = JSON.parse(result.data[0].value ?? result.data[0].defaultValue);
                    if (settingsValue) {
                        dispatch(setLayoutDefault(settingsValue));
                    }
                } else {
                    dispatch(setLayoutDefault(settingsValue));
                }
            } else {
                getErrorNotificationMessage(result);
            }
        }
    );
};

export const getDefaultWidgetsValue = (): AppThunk => async (dispatch) => {
    dispatch(dashboardInit());
    const stringUri = encodeURI(JSON.stringify(setAppSettingsFilterValue()));
    NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
        (result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data[0] && (result.data[0].value || result.data[0].defaultValue)) {
                    let settingsValue = JSON.parse(result.data[0].value ?? result.data[0].defaultValue);
                    if (settingsValue) {
                        dispatch(setLayoutForUser(settingsValue));
                    }
                } else {
                    dispatch(widgetsInit());
                }
            } else {
                getErrorNotificationMessage(result);
            }
        }
    );
};

export const getDashboardWidgetsForUser = (): AppThunk => async (dispatch) => {
    dispatch(dashboardInit());
    const settingKey = 'dashboard';
    NyRequestResolver.requestGet(CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey, {
        settingKey: settingKey,
    }).then((result: any) => {
        if (result.status == RESPONSE.OK && result.data && result.data.settingValue) {
            let settingsValue = JSON.parse(result.data.settingValue);
            if (settingsValue) {
                dispatch(setLayoutForUser(settingsValue));
            } else {
                dispatch(getDefaultWidgetsValue());
            }
        } else {
            dispatch(getDefaultWidgetsValue());
        }
    });
};
