import { EditTwoTone, InfoCircleTwoTone, MenuOutlined } from '@ant-design/icons';
import { arrayMove } from '@dnd-kit/sortable';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Popover,
    Row,
    Switch,
    Table,
    Tag,
    Tooltip,
    TransferProps,
    Typography,
} from 'antd';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import Transfer, { TransferDirection, TransferItem } from 'antd/es/transfer';
import { arrayMoveImmutable } from 'array-move';
import { difference } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import ContractMetadataEdit from './edit';

const { Title } = Typography;

interface RecordType {
    key: string;
    title: string;
    description: string;
    disabled: boolean;
    tag: string;
}

interface DataType {
    key: string;
    title: string;
    description: string;
    disabled: boolean;
    tag: string;
}

interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[];
    leftColumns: ColumnsType<DataType>;
    rightColumns: ColumnsType<DataType>;
}

const ContractMetadataIndex = ({
    usedMetadataKeys,
    selectedMetadataKeys,
    setSelectedMetadataKeys,
    triggerGetKeys,
}: any) => {
    const [metaDataListAll, setMetaDataListAll] = useState<any>(undefined);
    const [originTargetKeys, setOriginTargetKeys] = useState<any>();
    const [targetKeys, setTargetKeys] = useState<string[]>(originTargetKeys);
    const [disabled, setDisabled] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [editMetadataVisible, setEditMetadataVisible] = useState<boolean>(false);
    const [popupValue, setPopupValue] = useState<any>(undefined);
    //modal
    const [editContractTypeMetadataVisible, setEditContractTypeMetadataVisible] = useState<boolean>(false);
    const [editContractTypeMetadataTitle, setEditContractTypeMetadataTitle] = useState<any>(false);
    const [editContractTypeMetadataForm] = Form.useForm();
    //search
    const { Search } = Input;
    let timeout: any = null;
    const [searchFilteredMetaDataList, setSearchFilteredMetaDataList] = useState<any>([true]);
    const [formSearch] = Form.useForm();
    //search
    const tableRef: any = useRef();
    const [editTransferActive, setEditTransferActive] = useState<boolean>(false);
    const timer = useRef<any>(null);
    const preventClick = useRef<any>(false);

    const onModalOk = () => {
        const positionItem = editContractTypeMetadataForm.getFieldsValue();
        if (positionItem) {
            const newData = [...metaDataListAll];
            const index = newData.findIndex((item) => positionItem.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...positionItem });
                setMetaDataListAll(newData);
                setEditContractTypeMetadataVisible(false);
            }
        }
    };

    useEffect(() => {
        setTargetKeys(
            usedMetadataKeys?.map((item: any) => {
                return item?.contractMetadata?.id;
            })
        );
        // tu samo maknuti originTargetKeys...?
    }, [usedMetadataKeys /* originTargetKeys */]);

    useEffect(() => {
        fetchGetAllMetadata();
    }, []);

    /*     useEffect(() => {
        console.log('current scroll top use effect ', currentScrollTop);
        if (tableRef.current) {
            setTimeout(() => {
                tableRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    }, [currentScrollTop]); */

    const fetchGetAllMetadata = () => {
        let params = {
            max: 999,
            search: encodeURI(
                JSON.stringify([
                    {
                        field: 'active',
                        condition: 'equals_bool',
                        value: 1,
                    },
                ])
            ),
        };
        NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_METADATA.LIST, params).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const data = result?.data?.content?.map((item: any) => {
                    const foundMetadata = usedMetadataKeys?.find(
                        (usedItem: any) => usedItem?.contractMetadata?.id === item?.id
                    );

                    return {
                        ...(item.key = item.id),
                        ...item,
                        mandatory: foundMetadata ? foundMetadata?.mandatory : false,
                        documentPlaceholder: foundMetadata
                            ? foundMetadata?.documentPlaceholder
                            : item.documentPlaceholder,
                    };
                });
                setMetaDataListAll(data);
            }
        });
    };

    let transferItems: any = metaDataListAll
        ?.filter((item: any) => targetKeys?.includes(item.id))
        .sort((a: any, b: any) => {
            const indA = targetKeys.indexOf(a.id);
            const indB = targetKeys.indexOf(b.id);

            return indA - indB;
        });

    const toggleMandatory = (itemId: any) => {
        const itemIndex = transferItems.findIndex((item: any) => item.key === itemId);

        if (itemIndex !== -1) {
            const updatedMetaDataList = [...transferItems];
            updatedMetaDataList[itemIndex] = {
                ...updatedMetaDataList[itemIndex],
                mandatory: !updatedMetaDataList[itemIndex].mandatory,
            };

            const filteredMetadataList: any = updatedMetaDataList.filter((item) => targetKeys.includes(item.id));

            setTargetKeys(
                filteredMetadataList.map((item: any) => {
                    return item.key;
                })
            );

            setSelectedMetadataKeys(updatedMetaDataList.filter((item) => targetKeys.includes(item.id)));

            setMetaDataListAll([
                ...updatedMetaDataList,
                ...metaDataListAll.filter(
                    (item: any) => !updatedMetaDataList.some((updatedItem: any) => updatedItem.id == item.id)
                ),
            ]);
        }
    };

    const modalComponentOnSaveAndGetData = (positionItem: any) => {
        if (positionItem && metaDataListAll) {
            const newData = [...metaDataListAll];
            const index = newData.findIndex((item) => positionItem.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...positionItem });
                transferItems = newData;
                setMetaDataListAll(newData);
            }
        }
        fetchGetAllMetadata();
    };

    useEffect(() => {
        setOriginTargetKeys(metaDataListAll?.map((item: any) => item.key));
    }, [metaDataListAll]);

    useEffect(() => {}, [originTargetKeys]);

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const onSearch = (value: any) => {
        timeoutClear();
        setIsSearch(true);
        itemSearch(value);
    };

    const onChangeS = (value: any) => {
        timeoutClear();
        if (value.target.value === '') {
            setIsSearch(false);
        } else {
            timeout = setTimeout(() => {
                onSearch(value);
            }, 1000);
        }
    };

    const onKeyDown = (value: any) => {
        if (value.key === 'Enter') {
            if (value.target.value === '') {
            } else {
                onSearch(value);
            }
        }
    };

    const timeoutClear = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    function copy(o: any) {
        return Object.assign({}, o);
    }

    const itemSearch = (e: any, filterKeys?: any) => {
        if (e && e.target && e.target.value !== '') {
            let res = metaDataListAll.map(copy).filter(function f(o: any) {
                if (o?.name.toLowerCase().includes(e.target.value.toLowerCase())) return true;
            });

            setSearchFilteredMetaDataList(
                filterKeys
                    ? res.filter((item: any) => {
                          return !filterKeys.includes(item.key);
                      })
                    : res.filter((item: any) => {
                          return !targetKeys.includes(item.key);
                      })
            );
        }
    };

    const metadataColumnsLeft: any = [
        {
            title: geti18nText('metadata.table.name'),
            dataIndex: 'name',
            width: '200px',
            render: (text: any, record: any) => {
                if (record.name !== undefined) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record?.name}>
                            <div className={'description-div-small'}>
                                <p className="description-paragraph">{record?.name}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('metadata.table.dataType'),
            dataIndex: 'dataType',
            render: (text: any, record: any) => {
                if (record.dataType) {
                    return <Tag color="blue"> {geti18nText('app.enum.CONTRACT_METADATA.' + record.dataType)}</Tag>;
                }
            },
        },
        {
            title: geti18nText('metadata.table.documentPlaceholder'),
            dataIndex: 'documentPlaceholder',
            render: (text: any, record: any) => {
                if (record.documentPlaceholder !== undefined) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '200px' }}
                            title={record?.documentPlaceholder}
                        >
                            <div className={'ny-table-paragraph-div'}>
                                <p className={'ny-table-paragraph'}>{record?.documentPlaceholder}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
    ];

    const metadataColumnsRight: any = [
        {
            title: geti18nText('metadata.table.order'),
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: geti18nText('metadata.table.name'),
            dataIndex: 'name',
            width: '200px',
            render: (text: any, record: any) => {
                if (record.name !== undefined) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record?.name}>
                            <div className={'description-div-small'}>
                                <p className="description-paragraph">{record?.name}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('metadata.table.mandatory'),
            dataIndex: 'mandatory',
            render: (text: any, record: { mandatory: any; id: any }) => {
                return (
                    <Switch
                        className={record.mandatory ? 'switch-green-background' : 'switch-red-background'}
                        checkedChildren={geti18nText('app.default.button.yes')}
                        unCheckedChildren={geti18nText('app.default.button.no')}
                        defaultChecked={record.mandatory ? true : false}
                        onChange={(e: any) => {
                            const currentScrollTopInFunc = tableRef.current.scrollTop;
                            toggleMandatory(record.id);

                            setTimeout(() => {
                                tableRef.current.scrollTop = currentScrollTopInFunc;
                            });
                        }}
                    />
                );
            },
        },
        {
            title: geti18nText('metadata.table.dataType'),
            dataIndex: 'dataType',
            render: (text: any, record: any) => {
                if (record.dataType) {
                    return <Tag color="blue"> {geti18nText('app.enum.CONTRACT_METADATA.' + record.dataType)}</Tag>;
                }
            },
        },

        {
            title: geti18nText('metadata.table.documentPlaceholder'),
            dataIndex: 'documentPlaceholder',
            render: (text: any, record: any) => {
                if (record.documentPlaceholder !== undefined) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '200px' }}
                            title={record?.documentPlaceholder}
                        >
                            <div className={'ny-table-paragraph-div'}>
                                <p className={'ny-table-paragraph'}>{record?.documentPlaceholder}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
    ];

    const metadataColumnsView: any = [
        {
            title: geti18nText('metadata.table.name'),
            dataIndex: 'name',
            width: '25%',
            render: (text: any, record: any) => {
                if (record.name !== undefined) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={record?.name}>
                            <div className={'description-div-large'}>
                                <p className="description-paragraph">{record?.name}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('metadata.table.mandatory'),
            dataIndex: 'mandatory',
            render: (text: any, record: { mandatory: any }) => {
                if (record.mandatory) {
                    return <Tag color="blue">{geti18nText('app.default.button.yes')}</Tag>;
                } else {
                    return <Tag>{geti18nText('app.default.button.no')}</Tag>;
                }
            },
        },
        {
            title: geti18nText('metadata.table.dataType'),
            dataIndex: 'dataType',

            render: (text: any, record: any) => {
                if (record.dataType) {
                    return <Tag color="blue"> {geti18nText('app.enum.CONTRACT_METADATA.' + record.dataType)}</Tag>;
                }
            },
        },

        {
            title: geti18nText('metadata.table.documentPlaceholder'),
            dataIndex: 'documentPlaceholder',
            render: (text: any, record: any) => {
                if (record.documentPlaceholder !== undefined) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '200px' }}
                            title={record?.documentPlaceholder}
                        >
                            <div className={'ny-metadata-wide-right-paragraph-div'}>
                                <p className={'ny-table-paragraph'}>{record?.documentPlaceholder}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
    ];

    const [isSearch, setIsSearch] = useState<any>();

    const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
    const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
        <tbody {...props} />
    ));

    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
        <Transfer
            titles={[geti18nText('metadata.table.notUsed'), geti18nText('metadata.table.used')]}
            showSelectAll={false}
            {...restProps}
        >
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns: any = direction === 'left' ? leftColumns : rightColumns;

                transferItems = filteredItems;

                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys as string[], selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key as string, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
                    if (oldIndex !== newIndex) {
                        const newData = arrayMoveImmutable(filteredItems.slice(), oldIndex, newIndex).filter(
                            (el: any) => !!el
                        );
                        transferItems = newData;
                        const updatedTargetKeys = arrayMove(targetKeys, oldIndex, newIndex);
                        setTargetKeys(updatedTargetKeys);
                    }
                };

                const DraggableContainer = (props: SortableContainerProps) => (
                    <SortableBody
                        useDragHandle
                        disableAutoscroll
                        helperClass="row-dragging"
                        onSortEnd={onSortEnd}
                        {...props}
                    />
                );

                const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
                    const index = transferItems.findIndex((x: any) => x.key === restProps['data-row-key']);
                    return <SortableItem index={index} {...restProps} />;
                };

                return (
                    <Table
                        ref={tableRef}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={
                            direction != 'left' ? transferItems : isSearch ? searchFilteredMetaDataList : transferItems
                        }
                        size="small"
                        style={{
                            pointerEvents: listDisabled ? 'none' : undefined,
                        }}
                        className={'edit-transfer-view-table-width-visible scrollbar-on-hover'}
                        scroll={{ x: 40 /*  y: '200px!important' */ }}
                        onRow={({ record, key, disabled: itemDisabled }) => ({
                            onDoubleClick: () => {
                                clearTimeout(timer.current);
                                preventClick.current = true;
                                setPopupValue(key);

                                direction == 'left' && setEditMetadataVisible(true);
                                if (direction == 'right') {
                                    setEditContractTypeMetadataVisible(true);

                                    setEditContractTypeMetadataTitle(
                                        transferItems.find((item: any) => item.key == key).name ? (
                                            <div className="ny-text-line-overflow-wrap">
                                                {geti18nText('metadata.edit.title') +
                                                    ' - ' +
                                                    transferItems.find((item: any) => item.key == key).name}{' '}
                                            </div>
                                        ) : (
                                            geti18nText('metadata.edit.title')
                                        )
                                    );

                                    editContractTypeMetadataForm.setFieldsValue(
                                        transferItems.find((item: any) => item.key == key)
                                    );
                                }
                            },
                            onClick: () => {
                                timer.current = setTimeout(() => {
                                    if (!preventClick.current) {
                                        if (itemDisabled || listDisabled) return;
                                        onItemSelect(key as string, !listSelectedKeys.includes(key as string));
                                    }
                                    preventClick.current = false;
                                }, 200);
                            },
                        })}
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                        pagination={false}
                    />
                );
            }}
        </Transfer>
    );

    useEffect(() => {
        setSelectedMetadataKeys(
            transferItems.length !== 0
                ? transferItems
                : metaDataListAll?.filter((item: any) => targetKeys?.includes(item.id))
        ); // filter za id
    }, [triggerGetKeys, targetKeys, metaDataListAll]);

    const onChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
        if (isSearch && direction == 'right') {
            setSearchFilteredMetaDataList(
                searchFilteredMetaDataList.filter((item: any) => !nextTargetKeys.includes(item.key))
            );
        }

        if (isSearch && direction == 'left') {
            itemSearch({ target: { value: formSearch.getFieldValue('input') } }, nextTargetKeys);
        }

        setTargetKeys(nextTargetKeys);
    };

    const metadataSearchComponent = () => {
        return (
            editTransferActive && (
                <React.Fragment>
                    <div className="metadata-search">
                        <Form form={formSearch}>
                            <Form.Item name="input">
                                <Search
                                    id="search-menu"
                                    placeholder={geti18nText('app.default.button.search')}
                                    onSearch={onSearch}
                                    onChange={onChangeS}
                                    onKeyDown={onKeyDown}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </React.Fragment>
            )
        );
    };

    return (
        <>
            <Divider plain orientation="left" style={{ marginBottom: '8px' }}>
                <div style={{ display: 'flex' }}>
                    {metadataSearchComponent()}
                    {
                        <Button
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                            icon={
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            }
                            onClick={() => {
                                const documentElement = document.getElementById('leftTableTest');
                                if (documentElement) {
                                    documentElement.style.display = 'none';
                                }
                                setEditTransferActive(!editTransferActive);
                            }}
                        />
                    }
                    <Title level={5} style={{ marginTop: '4px' }}>
                        {geti18nText('metadata.divider.title')}
                    </Title>
                    <Popover
                        content={geti18nText('metadata.table.info')}
                        placement="top"
                        overlayStyle={{ maxWidth: '200px' }}
                    >
                        <InfoCircleTwoTone style={{ marginLeft: '8px', marginBottom: '4px' }} />
                    </Popover>
                </div>
            </Divider>
            <Row gutter={24}>
                <Col span={24}></Col>
            </Row>
            <Row>
                <div style={{ maxHeight: '250px' }}>
                    <Col
                        className={
                            !editTransferActive == false
                                ? 'edit-transfer-view-transfer-width-visible'
                                : 'edit-transfer-view-transfer-width-hidden'
                        }
                    >
                        <TableTransfer
                            dataSource={metaDataListAll}
                            targetKeys={targetKeys}
                            disabled={disabled}
                            showSearch={showSearch}
                            onChange={onChange}
                            filterOption={(inputValue, item) =>
                                item?.title!.indexOf(inputValue) !== -1 || item?.tag?.indexOf(inputValue) !== -1
                            }
                            leftColumns={metadataColumnsLeft}
                            rightColumns={metadataColumnsRight}
                            className="scrollbar-on-hover"
                            listStyle={{
                                width: 860,
                            }}
                            selectAllLabels={[
                                ({ selectedCount, totalCount }) => (
                                    <span>
                                        {selectedCount} / {!isSearch ? totalCount : searchFilteredMetaDataList.length}
                                        {' ' + geti18nText('metadata.table.item')}
                                    </span>
                                ),
                                ({ selectedCount, totalCount }) => (
                                    <span>
                                        {selectedCount} / {totalCount}
                                        {' ' + geti18nText('metadata.table.item')}
                                    </span>
                                ),
                            ]}
                        />
                    </Col>

                    <Col
                        className={
                            editTransferActive == false
                                ? 'edit-transfer-view-table-width-visible scrollbar-on-hover'
                                : 'edit-transfer-view-table-width-hidden'
                        }
                    >
                        <Table
                            columns={metadataColumnsView}
                            dataSource={metaDataListAll
                                ?.filter((item: any) => targetKeys?.includes(item.id))
                                .sort((a: any, b: any) => {
                                    const indA = targetKeys.indexOf(a.id);
                                    const indB = targetKeys.indexOf(b.id);

                                    return indA - indB;
                                })}
                            size="small"
                            style={{ pointerEvents: 'none', width: '1745px' }}
                            pagination={false}
                        />
                    </Col>
                </div>
            </Row>
            {editTransferActive && (
                <Button
                    style={{ marginTop: '32px' }}
                    onClick={() => {
                        setEditMetadataVisible(true);
                        setPopupValue(undefined);
                    }}
                    type="primary"
                >
                    {geti18nText('metadata.table.add')}
                </Button>
            )}

            {
                <Modal
                    visible={editContractTypeMetadataVisible}
                    width={600}
                    centered={true}
                    onOk={onModalOk}
                    title={editContractTypeMetadataTitle}
                    onCancel={() => setEditContractTypeMetadataVisible(false)}
                    okText={geti18nText('app.default.button.save')}
                    cancelText={geti18nText('app.default.button.cancel')}
                    maskClosable={false}
                    closable={true}
                >
                    <Form
                        form={editContractTypeMetadataForm}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="key" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="mandatory" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="name" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('metadata.edit.documentPlaceholder')}
                                    name="documentPlaceholder"
                                    rules={[
                                        {
                                            required: false,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
            {editMetadataVisible &&
                React.createElement(
                    ContractMetadataEdit,
                    {
                        isModal: true,
                        visible: editMetadataVisible,
                        setVisible: setEditMetadataVisible,
                        value: popupValue ? { id: popupValue } : undefined,
                        onSaveAndGetData: modalComponentOnSaveAndGetData,
                    },
                    null
                )}
        </>
    );
};

export default ContractMetadataIndex;
