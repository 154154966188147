import { geti18nText } from '@nybble/nyreact';
import { Card, Empty } from 'antd';
import { Axis, Chart, Coordinate, Interaction, Interval, Legend, Tooltip } from 'bizcharts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';

const EmployeeEvaluationPie = ({ data, title, portal = false }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';

    return (
        <div
            style={{
                width: portal ? '38vw' : '800px',
                paddingLeft: '12px',
                paddingRight: '12px',
                marginTop: '8px',
                height: '500px',
            }}
        >
            <Card
                className="ny-widget-card"
                title={title ? title : geti18nText('employee.evaluation.graph.label.employeeEvaluations')}
                bodyStyle={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '4px 5px 0px 5px',
                    marginTop: '1px',
                }}
                type="inner"
            >
                {data?.length > 0 ? (
                    <Chart height={400} data={data} autoFit>
                        <Coordinate type="theta" radius={0.8} />
                        <Tooltip showTitle={false} />
                        <Axis visible={false} />
                        <Legend
                            itemName={{
                                style: {
                                    fill: isDarkTheme ? 'white' : 'black',
                                },
                            }}
                            itemStates={{
                                active: {
                                    nameStyle: {
                                        opacity: 0.8,
                                    },
                                },
                                unchecked: {
                                    nameStyle: {
                                        fill: 'gray',
                                    },
                                    markerStyle: {
                                        fill: 'gray',
                                    },
                                },
                            }}
                        />
                        <Interval
                            position="count"
                            adjust="stack"
                            color={['item', data.map((item: any) => item.color)]}
                            style={{
                                lineWidth: 1,
                                stroke: '#fff',
                            }}
                            label={[
                                'count',
                                {
                                    layout: { type: 'pie-spider', cfg: { action: 'ellipsis' } },
                                    content: (data) => {
                                        return `${data.item}: ${data.count}`;
                                    },
                                    style: { fill: isDarkTheme ? 'white' : 'black' },
                                },
                            ]}
                            state={{
                                selected: {
                                    style: (t) => {},
                                },
                            }}
                        />
                        <Interaction type="element-single-selected" />
                    </Chart>
                ) : (
                    <Empty />
                )}
            </Card>
        </div>
    );
};

export default EmployeeEvaluationPie;
