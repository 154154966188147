import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CoffeeOutlined,
    MedicineBoxOutlined,
    PlayCircleOutlined,
    ReadOutlined,
} from '@ant-design/icons';
import { NySpinner, UseRequestSwrGet, geti18nText } from '@nybble/nyreact';
import { Avatar, Card, Empty, Popover, Statistic, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HumanResourcesRights } from '../../../rights/humanResourcesRights';
import { RootState } from '../../../rootReducer';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType } from '../../../utils/Utils';

const { Title, Text } = Typography;

const EmployeeLeavePersonalExtraLightWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);
    const { employee } = useSelector((state: RootState) => state.employee);
    const widgetColor = getColorFromNotificationType(settings, 'EMPLOYEE_LEAVE');
    const [lostDays, setLostDays] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeLeave();
    };

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrGet(CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.GET_AVAILABLE_DAYS, {
        active: true,
        employeeId: employee?.id,
    });

    useEffect(() => {
        if (statisticData) {
            if (!statisticData.availableDays) statisticData.availableDays = 0;
            if (!statisticData.approvedDays) statisticData.approvedDays = 0;
            if (!statisticData.approvedOldDays) statisticData.approvedOldDays = 0;
            if (!statisticData.usedDays) statisticData.usedDays = 0;
            if (!statisticData.usedOldDays) statisticData.usedOldDays = 0;

            if (statisticData.oldDaysLimit && moment(statisticData.oldDaysLimit).isBefore(moment())) {
                const lostDaysCount = statisticData.approvedDays - statisticData.usedDaysOld - statisticData.usedDays;
                setLostDays(lostDaysCount >= 0 ? lostDaysCount : 0);
            }
        }
    }, [statisticData]);

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px 10px',
    };

    const popoverContent = (
        <div style={{ width: '200px' }}>
            <Card
                className="ny-widget-card"
                headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
                title={geti18nText('employee.leave.personal')}
                bodyStyle={{ height: '55%', overflowY: 'auto', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
                type="inner"
                extra={
                    <>
                        {canCreate() && (
                            <>
                                <Tooltip placement="top" title={geti18nText('employee.leave.add.vacation')}>
                                    <CoffeeOutlined
                                        style={{ marginRight: '5px' }}
                                        className="ny-card-icon"
                                        onClick={() => {
                                            history.push({
                                                pathname: '/human/employee-leave-overview',
                                                state: { openModal: true, isVacation: true },
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip placement="top" title={geti18nText('employee.leave.add.medical.leave')}>
                                    <MedicineBoxOutlined
                                        className="ny-card-icon"
                                        onClick={() => {
                                            history.push({
                                                pathname: '/human/employee-leave-overview',
                                                state: { openModal: true, isVacation: false },
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </>
                }
            >
                {loading ? (
                    <div style={{ paddingTop: 20 }}>
                        <NySpinner />
                    </div>
                ) : error ||
                  (statisticData != null &&
                      !statisticData['availableDays'] &&
                      !statisticData['approvedDays'] &&
                      !statisticData['approvedOldDays'] &&
                      !statisticData['usedDays'] &&
                      !statisticData['usedOldDays']) ||
                  !statisticData ? (
                    <div style={{ paddingTop: 10 }}>
                        <Empty
                            className="ny-dashboard-empty"
                            imageStyle={{ width: '100%' }}
                            description={<Title level={5}>{geti18nText('app.default.nodata')}</Title>}
                        />
                    </div>
                ) : (
                    <>
                        {statisticData && (
                            <>
                                <Card.Grid style={gridStyle} className="statistic-card">
                                    <Statistic
                                        title={
                                            <>
                                                {geti18nText('employee.leave.days.available')}
                                                <br />
                                                {statisticData['annualLeaveYear'] &&
                                                    '(' +
                                                        geti18nText('employee.leave.days.annualLeaveYear') +
                                                        ' ' +
                                                        statisticData['annualLeaveYear'] +
                                                        ')'}
                                            </>
                                        }
                                        value={statisticData['availableDays'] ? statisticData['availableDays'] : 0}
                                        prefix={<CheckCircleOutlined style={{ color: 'green' }} />}
                                    />
                                </Card.Grid>
                                <Card.Grid style={gridStyle} className={`statistic-card`}>
                                    <Statistic
                                        title={
                                            <>
                                                {geti18nText('employee.leave.days.used')}
                                                <br />
                                                {statisticData['annualLeaveYear'] &&
                                                    '(' +
                                                        geti18nText('employee.leave.days.annualLeaveYear') +
                                                        ' ' +
                                                        statisticData['annualLeaveYear'] +
                                                        ')'}
                                            </>
                                        }
                                        value={
                                            (statisticData['usedDays'] ? statisticData['usedDays'] : 0) +
                                            (statisticData['usedOldDays'] ? statisticData['usedOldDays'] : 0)
                                        }
                                        prefix={<CloseCircleOutlined style={{ color: 'red' }} />}
                                    />
                                </Card.Grid>
                                <Card.Grid style={gridStyle} className={`statistic-card`}>
                                    <Popover
                                        content={
                                            <>
                                                {geti18nText('employee.leave.days.approved')}
                                                <br />
                                                {statisticData['annualLeaveYear'] &&
                                                    '(' +
                                                        geti18nText('employee.leave.days.annualLeaveYear') +
                                                        ' ' +
                                                        statisticData['annualLeaveYear'] +
                                                        ')'}
                                                <div>
                                                    {statisticData['approvedDays'] > 0 && (
                                                        <div>
                                                            {statisticData['approvedDays'] > 1
                                                                ? statisticData['approvedDays'] +
                                                                  ' ' +
                                                                  geti18nText('employee.leave.days.available.hover')
                                                                : statisticData['approvedDays'] +
                                                                  ' ' +
                                                                  geti18nText('employee.leave.days.available.hover.1')}
                                                        </div>
                                                    )}{' '}
                                                </div>
                                                {statisticData['approvedOldDays'] > 0 && (
                                                    <div>
                                                        {statisticData['approvedOldDays'] > 1
                                                            ? statisticData['approvedOldDays'] +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.old.hover')
                                                            : statisticData['approvedOldDays'] +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.old.hover.1')}
                                                    </div>
                                                )}
                                                {lostDays > 0 && (
                                                    <div>
                                                        {lostDays > 1
                                                            ? lostDays +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.lost.hover')
                                                            : lostDays +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.lost.hover.1')}
                                                    </div>
                                                )}
                                                {statisticData['oldDaysLimit'] &&
                                                    moment(statisticData['oldDaysLimit']) && (
                                                        <div>
                                                            {geti18nText('employee.leave.days.expire') +
                                                                ' ' +
                                                                moment(statisticData['oldDaysLimit']).format(
                                                                    'DD.MM.YYYY'
                                                                )}
                                                        </div>
                                                    )}
                                            </>
                                        }
                                    >
                                        <Statistic
                                            title={
                                                <>
                                                    {geti18nText('employee.leave.days.approved')}
                                                    <br />
                                                    {statisticData['annualLeaveYear'] &&
                                                        '(' +
                                                            geti18nText('employee.leave.days.annualLeaveYear') +
                                                            ' ' +
                                                            statisticData['annualLeaveYear'] +
                                                            ')'}
                                                </>
                                            }
                                            value={statisticData['approvedDays'] ? statisticData['approvedDays'] : 0}
                                            suffix={
                                                statisticData['approvedOldDays'] > 0 &&
                                                ' + ' + statisticData['approvedOldDays']
                                            }
                                            prefix={<PlayCircleOutlined />}
                                        />
                                    </Popover>
                                </Card.Grid>
                            </>
                        )}
                    </>
                )}
            </Card>
        </div>
    );

    return (
        <>
            <Popover
                placement="topLeft"
                content={popoverContent}
                trigger="click"
                className={`ny-widget-card statistic-card-light`}
                overlayClassName="ny-popover-widget"
            >
                <Tooltip placement="top" title={geti18nText('employee.leave.personal')}>
                    <Avatar
                        shape="square"
                        className="ny-widget-card-extra-light"
                        icon={<ReadOutlined />}
                        style={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
                    />
                </Tooltip>
            </Popover>
        </>
    );
};

export default EmployeeLeavePersonalExtraLightWidget;
