import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, NySession, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Modal, Row, Steps, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useEnum from '../../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import {
    ResolveEmployeeEducationStatusIcon,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumFormat,
    getErrorNotificationMessage,
    getSearchFormat,
    numberFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    userIsNotificationRecipient,
    warningNotification,
} from '../../../../../../utils/Utils';
import EmployeeEducationNewWizardSteps from './newWizardSteps';
import NyImageUpload from '../../../../../../utils/ny-file-upload-image';
import useApplicationSetting from '../../../../../../hooks/useApplicationSetting';

const { Step } = Steps;
const { TabPane } = Tabs;

const EmployeeEducationNewWizard = ({
    id = undefined,
    employeeEducation = undefined,
    setEmployeeEducation = null,
    setRefresh = null,
    isPersonalView = false,
    visible,
    setVisible,
    setValue,
    isAdminForm = false,
    employeeId,
}: any) => {
    const [form] = Form.useForm();
    const focusInput = useRef<any>(null);
    const [defaultCurrency, setDefaultCurrency] = useState<any>(undefined);
    const { employee } = useSelector((state: RootState) => state.employee);
    const employeeEducationStatus = useEnum('EMPLOYEE_EDUCATION_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');

    const [editHeader, setEditHeader] = useState(geti18nText('employee.education.new'));
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [refreshCatalog, setRefreshCatalog] = useState(0);

    const [statusActions, setStatusActions] = useState<any>([]);
    const [estimatedExpenses, setEstimatedExpenses] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);
    const [dateFrom, setDateFrom] = useState<any>(null);
    const [dateTo, setDateTo] = useState<any>(null);
    const [guestLecturer, setGuestLecturer] = useState<boolean>(false);
    const [presentation, setPresentation] = useState<boolean>(false);
    const [status, setStatus] = useState<any>(null);
    const [programCatalog, setProgramCatalog] = useState<any>(null);

    //admin form
    const [expenses, setExpenses] = useState<any>([]);
    const [activeKey, setActiveKey] = useState('1');
    const [realized, setRealized] = useState<boolean>(false);
    const [projectCost, setProjectCost] = useState<boolean>(false);
    const [companyCost, setCompanyCost] = useState<boolean>(false);
    const [postEducationObligation, setPostEducationObligation] = useState<boolean>(false);
    const [signedStatement, setSignedStatement] = useState<boolean>(false);
    const [fillEvaluation, setFillEvaluation] = useState<boolean>(false);
    const [initialExpenses, setInitialExpenses] = useState<any>();
    const [initialExpectedExpenses, setInitialExpectedExpenses] = useState<any>();

    //
    const [nameHolder, setNameHolder] = useState<any>(undefined);
    const [dateFromHolder, setDateFromHolder] = useState<any>(undefined);
    const [dateToHolder, setDateToHolder] = useState<any>(undefined);

    const isUser = () => {
        return HumanResourcesRights.isEducationUser();
    };

    const canViewAll = () => {
        return isUser() && userIsNotificationRecipient(employee, notificationType.EMPLOYEE_EDUCATION_APPROVAL);
    };

    const isAdmin = () => {
        return HumanResourcesRights.isEducationAdmin() || isAdminForm;
    };

    const disabled: boolean = !isUser() && !isAdmin();

    useEffect(() => {
        getCurrencyIsoCode(defaultCurrencyISO);
    }, []);

    async function getCurrencyIsoCode(code: any) {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.ISO_CODE, { code }).then((result: any) => {
            if (result.data.id) {
                setDefaultCurrency({ id: result.data.id, text: result.data.isoCode });
            } else {
                setDefaultCurrency({ id: 0, text: code });
            }
        });
    }

    useEffect(() => {
        if (!visible) setVisible(true);
        if (id && employeeEducation?.id && employeeEducation?.id == id) {
            setValues(employeeEducation);
            //  resolveStatusActions();
            getEstimatedExpenses(id);
            if (isAdmin()) {
                getExpenses(id);
            }
        }
    }, [id]);

    useEffect(() => {
        if (realized) {
            form.setFieldsValue({ unrealizationReason: null });
        }
    }, [realized]);

    function setValues(dataForm: any) {
        setLoading(true);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('menu.employee.education') + ' - ' + dataForm.name);
        }
        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('EMPLOYEE_EDUCATION_STATUS', dataForm.status);
            if (isAdmin()) {
                setActiveKey('5');
            }
        }

        setProgramCatalog(dataForm.programCatalog);
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        if (dataForm.directManager) {
            dataForm.directManager = getEmployeeSelectOption(dataForm.directManager);
        }
        if (dataForm.superiorManager) {
            dataForm.superiorManager = getEmployeeSelectOption(dataForm.superiorManager);
        }

        if (dataForm.country) {
            dataForm.country = setSearchFormat(dataForm.country);
        }
        if (dataForm.type) {
            dataForm.type = setSearchFormat(dataForm.type, 'name', 'name');
        }
        if (dataForm.educationForm) {
            dataForm.educationForm = setSearchFormat(dataForm.educationForm, 'description', 'description');
        }
        if (dataForm.programCatalog) {
            dataForm.programCatalog = setSearchFormat(dataForm.programCatalog, 'name', 'name');
        }
        if (dataForm.educationPurpose) {
            dataForm.educationPurpose = setSearchFormat(dataForm.educationPurpose, 'description', 'description');
        }
        if (dataForm.educationInstitution) {
            dataForm.educationInstitution = setSearchFormat(dataForm.educationInstitution, 'name', 'name');
        }
        if (dataForm.educationField) {
            dataForm.educationField = setSearchFormat(dataForm.educationField, 'name', 'name');
        }

        if (dataForm.applicationDueDate) {
            dataForm.applicationDueDate = setDateFormat(dataForm.applicationDueDate);
        }
        if (dataForm.presentationDate) {
            dataForm.presentationDate = setDateFormat(dataForm.presentationDate);
        }
        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
            setDateFrom(dataForm.dateFrom);
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
            setDateTo(dataForm.dateTo);
        }
        setGuestLecturer(dataForm.guestLecturer ? true : false);
        setPresentation(dataForm.presentation ? true : false);
        setRealized(dataForm.realized ? true : false);
        setProjectCost(dataForm.projectCost ? true : false);
        setCompanyCost(dataForm.companyCost ? true : false);
        setPostEducationObligation(dataForm.postEducationObligation ? true : false);
        setSignedStatement(dataForm.signedStatement ? true : false);
        setFillEvaluation(dataForm.fillEvaluation ? true : false);

        if (dataForm.fileId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.FILES.INFO + '/' + dataForm.fileId, undefined, undefined).then(
                (result: any) => {
                    if (result && result.status == RESPONSE.OK && result.data) {
                        let filesImage = [
                            {
                                id: dataForm.fileId,
                                uid: dataForm.fileId,
                                name: result.data.name,
                                url:
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    dataForm.fileId +
                                    '?lastmod=' +
                                    new Date().valueOf() +
                                    '&tenant=' +
                                    NySession.getUser().tenantId,
                            },
                        ];
                        setFiles(filesImage);
                    }
                }
            );
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        setLoading(false);
    }

    const resolveStatusActions = () => {
        setStatusActions([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EDUCATION.ACTION_LIST + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setStatusActions(result.data);
            }
        });
    };

    const setExpensesFilter = (id: any, estimation: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_education_id', condition: 'equals', value: id },
            { field: 'estimation', condition: 'equals_bool', value: estimation },
        ];
    };

    const getEstimatedExpenses = (id: any) => {
        setEstimatedExpenses([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EDUCATION_EXPENSE.LIST, {
            search: encodeURI(JSON.stringify(setExpensesFilter(id, 1))),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content) {
                setEstimatedExpenses(
                    result.data.content.map((element: any) => {
                        element.tempId = element.id;
                        return element;
                    })
                );
                setInitialExpectedExpenses(
                    result.data.content.map((element: any) => {
                        element.tempId = element.id;
                        return element;
                    })
                );
            }
        });
    };

    const getExpenses = (id: any) => {
        setExpenses([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EDUCATION_EXPENSE.LIST, {
            search: encodeURI(JSON.stringify(setExpensesFilter(id, 0))),
            order: 'id',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content) {
                setExpenses(
                    result.data.content.map((element: any) => {
                        element.tempId = element.id;
                        return element;
                    })
                );
                setInitialExpenses(
                    result.data.content.map((element: any) => {
                        element.tempId = element.id;
                        return element;
                    })
                );
            }
        });
    };

    const handleCancel = () => {
        setVisible(false);
        setValue && setValue(null);
        setEditHeader(geti18nText('employee.education.new'));
        setEmployeeEducation && setEmployeeEducation(null);
        setCurrent(0);
        setDateFrom(null);
        setDateTo(null);
        setFiles([]);
        setGuestLecturer(false);
        setPresentation(false);
        setStatus(null);
        setProgramCatalog(null);
        setStatusActions([]);
        setEstimatedExpenses([]);
        setExpenses([]);
        setRealized(false);
        setProjectCost(false);
        setCompanyCost(false);
        setPostEducationObligation(false);
        setSignedStatement(false);
        setFillEvaluation(false);
    };

    const normalize = (values: any) => {
        if (employeeId) {
            values.employee = { id: employeeId };
        } else if (isPersonalView) {
            values.employee = { id: employee?.id };
        } else if (values.employee) {
            values.employee = getSearchFormat(values.employee);
        }

        if (values.directManager) values.directManager = getSearchFormat(values.directManager);
        if (values.superiorManager) values.superiorManager = getSearchFormat(values.superiorManager);
        if (values.educationForm) values.educationForm = getSearchFormat(values.educationForm);
        if (values.programCatalog) values.programCatalog = getSearchFormat(values.programCatalog);
        if (values.country) values.country = getSearchFormat(values.country);
        if (values.educationPurpose) values.educationPurpose = getSearchFormat(values.educationPurpose);
        if (values.educationInstitution) values.educationInstitution = getSearchFormat(values.educationInstitution);
        if (values.type) values.type = getSearchFormat(values.type);
        if (values.educationField) values.educationField = getSearchFormat(values.educationField);

        if (values.applicationDueDate) values.applicationDueDate = getDateFormat(values.applicationDueDate);
        if (values.presentationDate) values.presentationDate = getDateFormat(values.presentationDate, 'yyyy-MM-DD');
        if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
        if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);

        values.guestLecturer = guestLecturer;
        values.presentation = presentation;

        if (files.length > 0 && files[0]) {
            values.fileId = files[0].id;
        } else {
            values.fileId = null;
        }
        //realization
        values.realized = realized;
        values.projectCost = projectCost;
        values.companyCost = companyCost;
        values.postEducationObligation = postEducationObligation;
        values.signedStatement = signedStatement;
        values.fillEvaluation = fillEvaluation;

        if (!realized) {
            values.unrealizationReason = { id: getEnumFormat(values.unrealizationReason) };
        }
        return values;
    };

    const afterSaveAction = (result: any) => {
        if (result && (result.status === RESPONSE.CREATED || result.status === RESPONSE.OK)) {
            if (result?.data?.id && estimatedExpenses?.length > 0) {
                saveExpenses(estimatedExpenses, result.data.id, true, initialExpectedExpenses);
            }
            if (result?.data?.id && expenses?.length > 0 && isAdmin()) {
                saveExpenses(expenses, result.data.id, false, initialExpenses);
            }
            okNotification();
            handleCancel();
            setRefresh && setRefresh((refresh: any) => refresh + 1);
        } else {
            getErrorNotificationMessage(result);
        }
    };

    const saveExpenses = async (
        expensesList: any = [],
        employeeEducationId: any,
        estimation: boolean,
        initialList: any = []
    ) => {
        if (expensesList?.length > 0) {
            expensesList.map(async (estimatedExpense: any) => {
                let values = {
                    id: estimatedExpense?.id,
                    amount: estimatedExpense.amount,
                    type: { id: estimatedExpense?.type?.id },
                    employeeEducation: { id: employeeEducationId },
                    estimation: estimation,
                    participationAmount: estimatedExpense.participationAmount
                        ? estimatedExpense.participationAmount.toFixed(2)
                        : null,
                    participation: estimatedExpense.participationAmount ? true : false,
                    currency: { id: defaultCurrency?.id },
                    taxIncluded: estimatedExpense.taxIncluded,
                    active: true,
                };
                if (values?.id) {
                    await NyRequestResolver.requestPut(
                        CONSTANTS_REQ.EMPLOYEE_EDUCATION_EXPENSE.EDIT + '/' + values.id,
                        undefined,
                        values
                    ).then((result: any) => {
                        if (result?.status != RESPONSE.CREATED) {
                            getErrorNotificationMessage(result);
                        }
                    });
                } else {
                    await NyRequestResolver.requestPost(
                        CONSTANTS_REQ.EMPLOYEE_EDUCATION_EXPENSE.EDIT,
                        undefined,
                        values
                    ).then((result: any) => {
                        if (result?.status != RESPONSE.CREATED) {
                            getErrorNotificationMessage(result);
                        }
                    });
                }
            });
        }

        //logika za brisanje stavka....
        const removeExpenses = initialList.filter(
            (expense: any) => !expensesList.map((expense: any) => expense.id).includes(expense.id)
        );
        removeExpenses.map(async (removeExpense: any) => {
            let values = {
                id: removeExpense?.id,
                amount: removeExpense.amount,
                type: { id: removeExpense?.type?.id },
                employeeEducation: { id: employeeEducationId },
                estimation: estimation,
                participationAmount: removeExpense.participationAmount
                    ? removeExpense.participationAmount.toFixed(2)
                    : null,
                participation: removeExpense.participationAmount ? true : false,
                currency: { id: defaultCurrency?.id },
                taxIncluded: removeExpense.taxIncluded,
                active: false,
            };
            if (values?.id) {
                await NyRequestResolver.requestPut(
                    CONSTANTS_REQ.EMPLOYEE_EDUCATION_EXPENSE.EDIT + '/' + values.id,
                    undefined,
                    values
                ).then((result: any) => {
                    if (result?.status != RESPONSE.CREATED) {
                        getErrorNotificationMessage(result);
                    }
                });
            }
        });
    };

    const normalizeProgramCatalog = async (values: any) => {
        let programCatalogId: any = programCatalog?.id;
        if (!programCatalogId) {
            let data: any = {
                name: values?.name,
                dateFrom: values?.dateFrom,
                dateTo: values?.dateTo,
                location: values?.location,
                place: values?.place,
                country: values?.country,
            };
            await NyRequestResolver.requestPost(CONSTANTS_REQ.PROGRAM_CATALOG.EDIT, undefined, { data }).then(
                (result: any) => {
                    programCatalogId = result?.data?.id;
                }
            );
        }
        return programCatalogId;
    };

    const save = (status: any = null) => {
        setLoading(true);
        form.validateFields().then(async (values: any) => {
            if (isAdmin()) {
                values.status = values.status ? getEnumFormat(values.status) : employeeEducationStatus.APPROVED;
            } else {
                values.status = status;
            }
            values = normalize(values);
            //program catalog
            const programCatalogId: any =
                isAdmin() && status ? programCatalog?.id : await normalizeProgramCatalog(values);
            values.programCatalog = { id: programCatalogId };
            if (values.id) {
                const urlPut = !isAdmin()
                    ? CONSTANTS_REQ.EMPLOYEE_EDUCATION.EMPLOYEE_UPDATE
                    : CONSTANTS_REQ.EMPLOYEE_EDUCATION.EDIT;
                NyRequestResolver.requestPut(urlPut + '/' + values.id, undefined, values).then((result: any) => {
                    afterSaveAction(result);
                });
            } else {
                NyRequestResolver.requestPost(
                    !isAdmin() ? CONSTANTS_REQ.EMPLOYEE_EDUCATION.EMPLOYEE_SAVE : CONSTANTS_REQ.EMPLOYEE_EDUCATION.EDIT,
                    undefined,
                    values
                ).then((result: any) => {
                    afterSaveAction(result);
                });
            }
        });
        setLoading(false);
    };

    const prev = () => {
        if (current == 1) {
            setRefreshCatalog(refreshCatalog + 1);
        }
        setCurrent(current - 1);
    };

    const next = () => {
        if (current == 1 && !isPersonalView) {
            const values = form.getFieldsValue();
            if (!values?.employee?.id) {
                warningNotification(
                    geti18nText('employee.education.notification.form'),
                    geti18nText('employee.education.notification.form.explained')
                );
            } else {
                setCurrent(current + 1);
            }
        } else {
            setCurrent(current + 1);
        }
    };

    const steps = [
        {
            title: geti18nText('employee.education.step.1'),
        },
        {
            title: geti18nText('employee.education.step.2'),
        },
        {
            title: geti18nText('employee.education.step.3'),
        },
        {
            title: geti18nText('employee.education.step.4'),
        },
    ];

    const iconStyle: any = {
        fontSize: '16px',
        paddingRight: '10px',
        paddingTop: '2px',
        float: 'left',
    };

    const getStatusActions = () => (
        <>
            {!status ? (
                <>
                    {isUser() && (
                        <>
                            <NyModalConfirm
                                title={geti18nText('employee.education.save.draft.confirm')}
                                onConfirm={() => {
                                    save(null);
                                }}
                            >
                                <Button
                                    icon={ResolveEmployeeEducationStatusIcon(null, iconStyle, '')}
                                    style={{ display: 'inline' }}
                                    type="primary"
                                    loading={loading}
                                    disabled={disabled}
                                >
                                    {geti18nText('employee.education.save.draft')}
                                </Button>
                            </NyModalConfirm>
                            <NyModalConfirm
                                title={geti18nText('employee.education.save.request.confirm')}
                                onConfirm={() => {
                                    save(employeeEducationStatus.DRAFT);
                                }}
                            >
                                <Button
                                    style={{ display: 'inline' }}
                                    icon={ResolveEmployeeEducationStatusIcon(
                                        employeeEducationStatus.DRAFT,
                                        iconStyle,
                                        ''
                                    )}
                                    type="primary"
                                    className="green-button"
                                    loading={loading}
                                    disabled={disabled}
                                >
                                    {geti18nText('employee.education.save.request')}
                                </Button>
                            </NyModalConfirm>
                        </>
                    )}
                </>
            ) : (
                canViewAll() && (
                    <>
                        <NyModalConfirm
                            title={geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.0')}
                            onConfirm={() => {
                                save(null);
                            }}
                        >
                            <Button
                                icon={ResolveEmployeeEducationStatusIcon(null, iconStyle, '')}
                                loading={loading}
                                disabled={disabled}
                                type="primary"
                                style={{ display: 'inline' }}
                            >
                                {geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.change.0')}
                            </Button>
                        </NyModalConfirm>
                        <NyModalConfirm
                            title={geti18nText(
                                'app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.' +
                                    employeeEducationStatus.DRAFT_REJECTED
                            )}
                            onConfirm={() => {
                                save(employeeEducationStatus.DRAFT_REJECTED);
                            }}
                        >
                            <Button
                                icon={ResolveEmployeeEducationStatusIcon(
                                    employeeEducationStatus.DRAFT_REJECTED,
                                    iconStyle,
                                    ''
                                )}
                                type="primary"
                                loading={loading}
                                disabled={disabled}
                                danger
                                style={{ display: 'inline' }}
                            >
                                {geti18nText(
                                    'app.enum.EMPLOYEE_EDUCATION_STATUS.change.' +
                                        employeeEducationStatus.DRAFT_REJECTED
                                )}
                            </Button>
                        </NyModalConfirm>
                        <NyModalConfirm
                            title={geti18nText(
                                'app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.' +
                                    employeeEducationStatus.DRAFT_APPROVED
                            )}
                            onConfirm={() => {
                                save(employeeEducationStatus.DRAFT_APPROVED);
                            }}
                        >
                            <Button
                                icon={ResolveEmployeeEducationStatusIcon(
                                    employeeEducationStatus.DRAFT_APPROVED,
                                    iconStyle,
                                    ''
                                )}
                                type="primary"
                                loading={loading}
                                disabled={disabled}
                                className="green-button"
                                style={{ display: 'inline' }}
                            >
                                {geti18nText(
                                    'app.enum.EMPLOYEE_EDUCATION_STATUS.change.' +
                                        employeeEducationStatus.DRAFT_APPROVED
                                )}
                            </Button>
                        </NyModalConfirm>
                        {/* {statusActions?.length > 0 &&
                        statusActions.reverse().map((item: any) => (
                            <>
                                <NyModalConfirm
                                    title={geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.change.confirm.' + item)}
                                    onConfirm={() => {
                                        save(item);
                                    }}
                                >
                                    <Button
                                        icon={ResolveEmployeeEducationStatusIcon(item, iconStyle, '')}
                                        style={{ display: 'inline', float: 'right' }}
                                        type="primary"
                                        danger={Object.values([
                                            employeeEducationStatus.DRAFT_REJECTED,
                                            employeeEducationStatus.REJECTED,
                                        ]).includes(item)}
                                        loading={loading}
                                        disabled={disabled}
                                    >
                                        {geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.change.' + item)}
                                    </Button>
                                </NyModalConfirm>
                            </>
                        ))} */}
                    </>
                )
            )}
        </>
    );

    const getCustomFooterContent = (
        <div style={{ marginBottom: '30px' }}>
            <div style={{ float: 'left' }}></div>
            <div style={{ float: 'right' }}>
                <Button onClick={handleCancel}>{geti18nText('app.default.button.cancel')}</Button>
                {!isAdmin() ? (
                    <>
                        {current > 0 && (
                            <Button onClick={() => prev()} icon={<CaretLeftOutlined />}>
                                {geti18nText('travelWarrantWizard.previous')}
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                                {geti18nText('travelWarrantWizard.next')}
                                <CaretRightOutlined />
                            </Button>
                        )}
                        {current === steps.length - 1 && <>{getStatusActions()}</>}
                    </>
                ) : (
                    <NyModalConfirm
                        title={geti18nText('employee.education.edit.save.confirm')}
                        onConfirm={() => {
                            save(null);
                        }}
                    >
                        <Button style={{ display: 'inline' }} type="primary" loading={loading} disabled={disabled}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </NyModalConfirm>
                )}
            </div>
        </div>
    );

    const getWizardStep = (step: any) => (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    <EmployeeEducationNewWizardSteps
                        form={form}
                        current={step}
                        isPersonalView={isPersonalView}
                        files={files}
                        setFiles={setFiles}
                        dateFrom={dateFrom}
                        setDateFrom={setDateFrom}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        guestLecturer={guestLecturer}
                        setGuestLecturer={setGuestLecturer}
                        presentation={presentation}
                        setPresentation={setPresentation}
                        programCatalog={programCatalog}
                        setProgramCatalog={setProgramCatalog}
                        refresh={refreshCatalog}
                        setRefresh={setRefreshCatalog}
                        estimatedExpenses={estimatedExpenses}
                        setEstimatedExpenses={setEstimatedExpenses}
                        loading={loading}
                        status={status}
                        //adminForm
                        realized={realized}
                        setRealized={setRealized}
                        expenses={expenses}
                        setExpenses={setExpenses}
                        companyCost={companyCost}
                        setCompanyCost={setCompanyCost}
                        projectCost={projectCost}
                        setProjectCost={setProjectCost}
                        postEducationObligation={postEducationObligation}
                        setPostEducationObligation={setPostEducationObligation}
                        signedStatement={signedStatement}
                        setSignedStatement={setSignedStatement}
                        fillEvaluation={fillEvaluation}
                        setFillEvaluation={setFillEvaluation}
                        isAdmin={isAdmin()}
                        setNameHolder={setNameHolder}
                        nameHolder={nameHolder}
                        setDateFromHolder={setDateFromHolder}
                        dateFromHolder={dateFromHolder}
                        setDateToHolder={setDateToHolder}
                        dateToHolder={dateToHolder}
                    />
                </>
            )}
        </>
    );

    const getFiles = () => (
        <Row gutter={24}>
            <Col span={24}>
                <div style={{ paddingBottom: '15px' }}>
                    <Alert message={geti18nText('employee.education.files.info')} type="info" showIcon />
                </div>
                <NyImageUpload
                    files={files}
                    setFiles={setFiles}
                    saveOnUpload={true}
                    accept={'*'}
                    cropShape={null}
                    listType={'text'}
                />
            </Col>
        </Row>
    );

    return (
        <>
            <Modal
                title={editHeader}
                open={visible}
                width={900}
                onCancel={handleCancel}
                footer={getCustomFooterContent}
                maskClosable={false}
                style={{ overflow: 'hidden' }}
            >
                <>
                    {isAdmin() ? (
                        <>
                            {loading ? (
                                <NySpinner />
                            ) : (
                                <Tabs
                                    defaultActiveKey={activeKey}
                                    activeKey={activeKey}
                                    onChange={(key) => setActiveKey(key)}
                                    type="card"
                                >
                                    {status && (
                                        <TabPane tab={geti18nText('employee.education.step.5')} key="5">
                                            {getWizardStep(4)}
                                        </TabPane>
                                    )}
                                    <TabPane tab={geti18nText('employee.education.step.1')} key="1">
                                        {getWizardStep(0)}
                                    </TabPane>
                                    <TabPane tab={geti18nText('employee.education.step.2')} key="2">
                                        {getWizardStep(1)}
                                    </TabPane>
                                    <TabPane tab={geti18nText('employee.education.step.3')} key="3">
                                        {getFiles()}
                                    </TabPane>
                                    <TabPane tab={geti18nText('employee.education.step.4')} key="4">
                                        {getWizardStep(3)}
                                    </TabPane>
                                    {!status && (
                                        <TabPane tab={geti18nText('employee.education.step.5')} key="5">
                                            {getWizardStep(4)}
                                        </TabPane>
                                    )}
                                    <TabPane tab={geti18nText('employee.education.step.7')} key="7">
                                        {getWizardStep(5)}
                                    </TabPane>
                                    <TabPane tab={geti18nText('employee.education.step.6')} key="6">
                                        {getWizardStep(6)}
                                    </TabPane>
                                </Tabs>
                            )}
                        </>
                    ) : (
                        <>
                            <Steps current={current} size="small" progressDot>
                                {steps.map((item: any) => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                            <div className="steps-content">
                                {loading ? (
                                    <NySpinner />
                                ) : (
                                    <>
                                        <EmployeeEducationNewWizardSteps
                                            form={form}
                                            current={current}
                                            isPersonalView={isPersonalView}
                                            files={files}
                                            setFiles={setFiles}
                                            dateFrom={dateFrom}
                                            setDateFrom={setDateFrom}
                                            dateTo={dateTo}
                                            setDateTo={setDateTo}
                                            guestLecturer={guestLecturer}
                                            setGuestLecturer={setGuestLecturer}
                                            presentation={presentation}
                                            setPresentation={setPresentation}
                                            programCatalog={programCatalog}
                                            setProgramCatalog={setProgramCatalog}
                                            refresh={refreshCatalog}
                                            setRefresh={setRefreshCatalog}
                                            estimatedExpenses={estimatedExpenses}
                                            setEstimatedExpenses={setEstimatedExpenses}
                                            loading={loading}
                                            status={status}
                                        />
                                    </>
                                )}
                                {current == 2 && <> {getFiles()}</>}
                            </div>
                        </>
                    )}
                </>
            </Modal>
        </>
    );
};
export default EmployeeEducationNewWizard;
