import { geti18nText, NyDataEdit, NySearchField, NySession } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, getSearchFormat, setEnumFormat, setSearchFormat } from '../../../../utils/Utils';
import BusinessUnitIndex from '../../../human/views/business-unit';
import BusinessUnitEdit from '../../../human/views/business-unit/edit';
import { BusinessUnitSearch } from '../../../human/views/business-unit/search';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';

const WarehouseEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('warehouse.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('warehouse.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.type) {
            dataForm.type = setEnumFormat('WAREHOUSE_TYPE', dataForm.type);
        }
        if (dataForm.warehouseValueType) {
            dataForm.warehouseValueType = setEnumFormat('WAREHOUSE_VALUE_TYPE', dataForm.warehouseValueType);
        }
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('warehouse.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.WAREHOUSE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            nyTestId="warehouse-edit"
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.type) values.type = getEnumFormat(values.type);
                if (values.warehouseValueType) values.warehouseValueType = getEnumFormat(values.warehouseValueType);
                if (values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    if (values.businessUnit.id < 1) {
                        delete values.businessUnit;
                    }
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('warehouse.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('warehouse.edit.code')} name="code">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <BusinessUnitSearch formItemName={'businessUnit'} />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('warehouse.edit.type')}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('WAREHOUSE_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('warehouse.edit.warehouseValueType')}
                        name="warehouseValueType"
                        initialValue={setEnumFormat('WAREHOUSE_VALUE_TYPE', 1)}
                    >
                        <NySearchField
                            options={getEnumArray('WAREHOUSE_VALUE_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default WarehouseEdit;
