import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getEnumArray } from '../../../../utils/Utils';
import LocationEdit from './edit';
import { getColumnFilterIsNullCheck } from './CustomLocationIsNullFilter';

const LocationIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('human/codebooks/location');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canViewAttendanceRegistrationCodebooks();
    };

    const columns = [
        {
            title: geti18nText('location.table.column.id'),
            dataIndex: 'id',
            width: '60px',
            sorter: true,
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('location.table.column.name'),
            dataIndex: 'name',
            sorter: true,
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('location.table.column.street'),
            dataIndex: ['address', 'street'],
            sorter: true,
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('location.table.column.postoffice'),
            dataIndex: ['postOffice', 'zipCode'],
            sorter: true,
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.POST_OFFICE.SEARCH, 'postOffice.id', 'zipCode'),
            render: (text: any, record: any) => {
                return record?.address?.postOffice?.zipCode;
            },
        },
        {
            title: geti18nText('location.table.column.settlement'),
            dataIndex: ['settlement', 'name'],
            sorter: true,
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.SETTLEMENT.SEARCH, 'settlement.id', 'name'),
            render: (text: any, record: any) => {
                return record?.address?.settlement?.name;
            },
        },
        {
            title: geti18nText('location.table.column.coordinates'),
            dataIndex: 'lng',
            width: '8%',
            sorter: true,
            render: (_text: any, record: any) => {
                if (record.lat || record.lng) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnFilterIsNullCheck(),
        },
        {
            title: geti18nText('location.table.column.ipAddress'),
            dataIndex: 'ipAddress',
            sorter: true,
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('location.table.column.type'),
            dataIndex: 'type',
            sorter: true,
            render: (_text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.LOCATION_TYPE.' + record.type);
                }
            },
            ...getColumnSearchOption(getEnumArray('LOCATION_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('location.table.column.active'),
            dataIndex: 'active',
            render: (_text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-location-table"
            url={CONSTANTS_REQ.LOCATION.LIST}
            addNewButtonText={geti18nText('location.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={LocationEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.location')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.location')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value !== undefined && geti18nText('app.enum.LOCATION_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            lng: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default LocationIndex;
