import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import LinksEdit from './edit';

const LinksIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('common/links');

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportAdministrationCodebook();
    };

    const columns = [
        {
            title: geti18nText('links.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('links.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('links.table.column.url'),
            dataIndex: 'url',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('links.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="links-table"
            url={CONSTANTS_REQ.LINKS.LIST}
            addNewButtonText={geti18nText('links.table.add')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={LinksEdit}
            columns={columns}
            hideNewButton={!canCreate()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.links')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.links')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default LinksIndex;
