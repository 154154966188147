import { geti18nText } from '@nybble/nyreact';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { MobilizationRights } from '../../rights/mobilizationRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_FILTER, DEFAULT_PAGE_SIZE, DEFAULT_TABLE_SCROLL } from '../../utils/Constants';
import NyFilesUpload from '../../utils/ny-file-upload-custom';

const MobilizationDocuments = ({ documentType, scroll = DEFAULT_TABLE_SCROLL }: any) => {
    const uploadFilesRef = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);

    const canExportCSV = () => {
        return MobilizationRights.canExportCodebooks();
    };

    return (
        <NyFilesUpload
            documentType={documentType}
            url={CONSTANTS_REQ.MOBILIZATION_FILE.LIST}
            editUrl={CONSTANTS_REQ.MOBILIZATION_FILE.EDIT}
            manageUploadUrl={CONSTANTS_REQ.MOBILIZATION_FILE.EDIT}
            deactivateUrl={CONSTANTS_REQ.MOBILIZATION_FILE.EDIT}
            defaultFilesFilter={[
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'document_type', condition: 'equals', value: documentType },
            ]}
            ref={uploadFilesRef}
            defaultType={'nyDataTable'}
            showSwitchView={false}
            showHeader={false}
            hasNoRefId={true}
            actionDeactivateRecords={true}
            CSVFileName={geti18nText('systematization.documents.tab')}
            exportCSV={canExportCSV()}
            employee={employee}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultFilter={DEFAULT_FILTER}
            columnStyleClass={'description-div-large'}
            scroll={scroll}
        />
    );
};

export default MobilizationDocuments;
