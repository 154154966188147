import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getDeviceTypeTag, getEnumArrayStatus } from '../../../../utils/Utils';
import LocationEdit from './edit';

const DeviceIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const table = useTableSettings();
    useHelper('human/codebooks/device');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canViewAttendanceRegistrationCodebooks();
    };

    const columns = [
        {
            title: geti18nText('device.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: true,
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('device.table.column.ipAddress'),
            dataIndex: 'ipAddress',
            width: '10%',
            sorter: true,
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.port'),
            dataIndex: 'port',
            width: '10%',
            sorter: true,
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('device.table.column.address'),
            dataIndex: ['location', 'name'],
            sorter: true,
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.LOCATION.SEARCH, 'location.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('device.table.column.type'),
            dataIndex: 'type',
            sorter: true,
            render: (_text: any, record: any) => {
                if (record.type !== undefined) {
                    return getDeviceTypeTag(record.type);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('DEVICE_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('device.table.column.lastActivity'),
            dataIndex: 'lastActivity',
            sorter: true,
            render: (_text: any, record: any) => {
                if (record.lastActivity) {
                    return moment(record.lastActivity).format('DD.MM.YYYY HH:mm:ss');
                }
            },
            ...getColumnDateOption(true),
        },
        {
            title: geti18nText('device.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (_text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-device-table"
            url={CONSTANTS_REQ.DEVICE.LIST}
            addNewButtonText={geti18nText('device.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={LocationEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.device')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.device')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            type: (value: any) => {
                return value !== undefined && geti18nText('app.enum.DEVICE_TYPE.' + value);
            },
        },
        {
            lastActivity: (value: any) => {
                return value !== undefined && moment(value).format('DD.MM.YYYY HH:mm:ss');
            },
        },
    ];
};

export default DeviceIndex;
