import { geti18nText, NyDataEdit, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Form, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEmployeeSelectOption } from '../../../../utils/Utils';
import GeneralEditTab from './GeneralEditTab';
import ModulesEditTab from './ModulesEditTab';

const UsersEdit = (props: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [isCreate, setIsCreate] = useState<boolean>(true);
    const [editHeader, setEditHeader] = useState(geti18nText('user.auth.edit.header.new'));
    const [employeeId, setEmployeeId] = useState(undefined);
    const [defaultEmployee, setDefaultEmployee] = useState<any>(undefined);
    const [activeKey, setActiveKey] = useState('1');
    const [modules, setModules] = useState<any[]>([]);
    const [triggerSaveModules, setTriggerSaveModules] = useState<number>(0);
    const [triggerCloseModal, setTriggerCloseModal] = useState<number>(0);
    const goToOtherTabOnSaveRef = useRef<Function>(() => Promise.resolve(undefined));

    useEffect(() => {
        if (activeKey === '2' && !isCreate) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.USER_AUTH.MODULES,
                { userId: form.getFieldValue('id') },
                true
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setModules(result.data);
                    }
                }
            });
        }
    }, [activeKey]);

    useEffect(() => {
        if (props?.addedData?.employee) setValues(props?.addedData?.employee);
    }, [props]);

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('user.auth.edit.header') + (dataForm.username ? ' - ' + dataForm.username : ''));
        if (dataForm?.person?.firstName) {
            dataForm.firstName = dataForm.person.firstName;
        }
        if (dataForm?.person?.lastName) {
            dataForm.lastName = dataForm.person.lastName;
        }
        if (dataForm?.employee) {
            dataForm.employeeId = getEmployeeSelectOption({
                id: dataForm.employee?.id,
                key: dataForm.employee?.id,
                text:
                    dataForm.employee?.personFirstName ??
                    dataForm.employee?.person.firstName + ' ' + dataForm.employee?.personLastName ??
                    dataForm.employee?.person.lastName,
                person: {
                    firstName: dataForm.employee?.personFirstName ?? dataForm.employee?.person.firstName,
                    lastName: dataForm.employee?.personLastName ?? dataForm.employee?.person.lastName,
                },
                employmentRecordId: dataForm.employee?.employmentRecordId,
                businessUnit: {
                    name: dataForm.employee?.businessUnitName,
                    code: dataForm.employee?.businessUnitCode,
                },
            });
            setEmployeeId(dataForm.employee?.id);
        }
        setDefaultEmployee({ ...dataForm?.employeeId, firstName: dataForm.firstName, lastName: dataForm.lastName });

        if (dataForm?.authEmail) {
            dataForm.email = dataForm.authEmail;
        } else if (dataForm?.contact?.email) {
            dataForm.email = dataForm.contact.email;
        }
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            setIsCreate={setIsCreate}
            url={CONSTANTS_REQ.USER_AUTH.EDIT}
            setValues={setValues}
            visible={true}
            width={900}
            form={form}
            shortcuts={true}
            leaveNewModalOpen={true}
            hideActivationButtons={activeKey === '2'}
            hideSubmitButton={activeKey === '2'}
            customFooterContent={
                activeKey === '2' && (
                    <Button
                        style={{ marginRight: '1em' }}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            setTriggerSaveModules((prev: number) => prev + 1);
                        }}
                    >
                        {geti18nText('app.default.button.save')}
                    </Button>
                )
            }
            triggerCloseModal={triggerCloseModal}
            normalize={(values: any) => {
                if (values.active === undefined) values.active = true;
                if (values.employeeId?.id == -1 || values.active === false) {
                    values.employeeId = null;
                    values.deleteEmployee = true;
                } else {
                    values.employeeId = props?.addedData?.employeeId ?? values.employeeId?.id;
                    values.mergeEmployee = true;
                }
                if (isCreate) {
                    values.redirectApplicationUrl = document.location.origin;
                }
                return values;
            }}
            {...props}
            onBeforeSave={() => {
                goToOtherTabOnSaveRef.current = () => Promise.resolve(isCreate);
            }}
            onSave={() => {
                goToOtherTabOnSaveRef.current().then((isCreate: any) => {
                    if (isCreate) {
                        setActiveKey('2');
                    }
                });
                return props?.onSave();
            }}
        >
            <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)} type="card">
                <TabPane tab={geti18nText('user.auth.edit.tab.1')} key="1">
                    <div style={{ maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                        <GeneralEditTab
                            employeeId={employeeId}
                            setEmployeeId={setEmployeeId}
                            defaultEmployee={defaultEmployee}
                            isCreate={isCreate}
                            form={form}
                        />
                    </div>
                </TabPane>
                <TabPane tab={geti18nText('user.auth.edit.tab.2')} key="2" disabled={isCreate}>
                    <div style={{ maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                        <ModulesEditTab
                            modules={modules}
                            userId={form.getFieldValue('id')}
                            triggerSaveModules={triggerSaveModules}
                            setTriggerCloseModal={setTriggerCloseModal}
                            setFetchUsersGroupsWhenChange={props?.editProps?.setFetchUsersGroupsWhenChange}
                        ></ModulesEditTab>
                    </div>
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};
export default UsersEdit;
