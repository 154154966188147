import { createSlice } from '@reduxjs/toolkit';

interface IFavoriteState {
    webshopItemBasket: any;
    webshopItemBasketLength: any;
    webshopItemNote: any;
    webshopItemOrderView: any;
    webshopAssetBasket: any;
    webshopAssetBasketLength: any;
    webshopAssetNote: any;
    webshopAssetOrderView: any;
    webshopServicesBasket: any;
    webshopServicesBasketLength: any;
    webshopServicesNote: any;
    webshopServicesOrderView: any;
}

const initialState: IFavoriteState = {
    webshopItemBasket: [],
    webshopItemBasketLength: 0,
    webshopItemNote: undefined,
    webshopItemOrderView: undefined,
    webshopAssetBasket: [],
    webshopAssetBasketLength: 0,
    webshopAssetNote: undefined,
    webshopAssetOrderView: undefined,
    webshopServicesBasket: [],
    webshopServicesBasketLength: 0,
    webshopServicesNote: undefined,
    webshopServicesOrderView: undefined,
};

const webshop = createSlice({
    name: 'webshop',
    initialState,
    reducers: {
        webshopInit(state) {
            state.webshopItemBasket = [];
            state.webshopItemBasketLength = 0;
            state.webshopItemNote = undefined;
            state.webshopItemOrderView = undefined;
            state.webshopAssetBasket = [];
            state.webshopAssetBasketLength = 0;
            state.webshopAssetNote = undefined;
            state.webshopAssetOrderView = undefined;
            state.webshopServicesBasket = [];
            state.webshopServicesBasketLength = 0;
            state.webshopServicesNote = undefined;
            state.webshopServicesOrderView = undefined;
        },
        setItemBasket(state, action) {
            state.webshopItemBasket = action.payload;
        },
        setItemBasketLength(state, action) {
            state.webshopItemBasketLength = action.payload;
        },
        setItemNote(state, action) {
            state.webshopItemNote = action.payload;
        },
        setAssetBasket(state, action) {
            state.webshopAssetBasket = action.payload;
        },
        setAssetBasketLength(state, action) {
            state.webshopAssetBasketLength = action.payload;
        },
        setAssetNote(state, action) {
            state.webshopAssetNote = action.payload;
        },
        setServicesBasket(state, action) {
            state.webshopServicesBasket = action.payload;
        },
        setServicesBasketLength(state, action) {
            state.webshopServicesBasketLength = action.payload;
        },
        setServicesNote(state, action) {
            state.webshopServicesNote = action.payload;
        },
        setItemOrderView(state, action) {
            state.webshopItemOrderView = action.payload;
        },
        setAssetOrderView(state, action) {
            state.webshopAssetOrderView = action.payload;
        },
        setServicesOrderView(state, action) {
            state.webshopServicesOrderView = action.payload;
        },
    },
});

export const {
    webshopInit,
    setItemBasket,
    setItemBasketLength,
    setItemNote,
    setAssetBasket,
    setAssetBasketLength,
    setAssetNote,
    setServicesBasket,
    setServicesBasketLength,
    setServicesNote,
    setItemOrderView,
    setAssetOrderView,
    setServicesOrderView,
} = webshop.actions;

export default webshop.reducer;
