import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookPerson, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import TeamEdit from './edit';

const TeamIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState<any>(1);

    useHelper('team');

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('team.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('team.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.code) {
                    return record.code;
                }
            },
        },
        {
            title: geti18nText('team.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.name) {
                    return record.name;
                }
            },
        },
        {
            title: geti18nText('team.table.column.lead'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookPerson(),
            render: (text: any, record: any) => {
                if (record && record.person) {
                    return record.person.firstName + ' ' + record.person.lastName;
                }
            },
        },
        {
            title: geti18nText('team.table.column.description'),
            dataIndex: 'description',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record.description}>
                            <div className={'ny-table-paragraph-div'}>
                                <p className={'ny-table-paragraph'}>{record && record.description}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('team.table.column.parent.team'),
            dataIndex: ['parentTeam', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TEAM.SEARCH, 'parentTeam.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                name: 'name',
                code: 'code',
            }),
            render: (text: any, record: any) => {
                if (record?.parentTeam?.name) {
                    return record?.parentTeam?.name;
                }
            },
        },
        {
            title: geti18nText('team.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <NyDataTable
            nyId="tasks-team-members-items-table"
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            url={CONSTANTS_REQ.TEAM.LIST}
            addNewButtonText={geti18nText('team.table.add')}
            fetchWhenChange={refreshTable}
            showRecordModal={true}
            modalComponent={TeamEdit}
            editProps={{ setRefreshTable, refreshTable, scroll: scroll, sortOrder: sortOrder }}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
            hideNewButton={!canCreate()}
            shortcuts={true}
            headerTitle={geti18nText('menu.team')}
        />
    );
};

export default TeamIndex;
