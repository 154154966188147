import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyDataEdit, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Modal, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, getEnumArray, getEnumFormat, okNotification, setEnumFormat } from '../../../../utils/Utils';
import WarehouseIndex from '../../../warehouse/views/warehouse';
import WarehouseEdit from '../../../warehouse/views/warehouse/edit';
import OrderViewAccessSettingsIndex from '../order-view-access-settings';
import OrderViewWarehouseIndex from '../order-view-warehouse';

const { Panel } = Collapse;

const OrderViewEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('orderView.edit.new'));
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['1']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [form] = Form.useForm();
    const focusInput = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isFormChange, setIsFormChange] = useState(false);
    const [orderViewId, setOrderViewId] = useState(undefined);
    const [warehouseModalVisible, setWarehouseModalVisible] = useState(false);
    const [warehouseId, setWarehouseId] = useState<any>(null);
    const [warehouses, setWarehouses] = useState<any>([]);
    const { id } = useParams<any>();
    const [refresh, setRefresh] = useState(0);
    const maxWarehouseCount: number = 1;
    useHelper('orderView');
    const { orderViewType } = props.editProps ? props.editProps : { orderViewType: undefined };

    const canAddWarehouse = () => {
        return warehouses?.length < maxWarehouseCount || !warehouses?.length;
    };

    const canCreate = () => {
        if (AdministrationRights.isAdmin()) {
            return true;
        } else if (orderViewType == 1) {
            return AssetRights.canCreateSettings();
        } else if (orderViewType == 2) {
            return InventoryRights.canCreateSettings();
        } else if (orderViewType == 3) {
            return ServicesRights.canCreateSettings();
        } else {
            return AdministrationRights.canCreateOrderView();
        }
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    useEffect(() => {
        getWarehouses(dataForm);
    }, [refresh]);

    const getWarehouses = (orderViewId: any) => {
        if (orderViewId) {
            setWarehouses([]);
            const filter = encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'orderViewId', condition: 'equals', value: orderViewId },
                ])
            );
            NyRequestResolver.requestGet(CONSTANTS_REQ.ORDER_VIEW_WAREHOUSE.LIST, {
                search: filter,
            }).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.size > 0 && result?.data?.content) {
                    setWarehouses(result.data.content);
                }
            });
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('orderView.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.type) {
            dataForm.type = setEnumFormat('ORDER_VIEW_TYPE', dataForm.type);
        }

        setCanEdit(true);
        setLeftCollapseActiveKeys(['1']);
        if (dataForm.id) {
            setOrderViewId(dataForm.id);
            setCanEdit(false);
            setRightCollapseActiveKeys(['2']);
            setLeftCollapseActiveKeys(['1', '3']);
            getWarehouses(dataForm.id);
        }

        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '3']);
            setRightCollapseActiveKeys(['2']);
        }
        setCollapseAll(!collapseAll);
    };

    const onModalClose = () => {
        setWarehouses([]);
        setCanEdit(true);
        setLeftCollapseActiveKeys(['1']);
        setRightCollapseActiveKeys([]);
        form.resetFields();
        setWarehouseId(null);
        setEditHeader(geti18nText('orderView.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const closeWarehouseModal = () => {
        setWarehouseModalVisible(false);
        setWarehouseId(null);
        form.setFieldsValue({ warehouse: null });
    };

    const saveOrderViewWarehouse = () => {
        const newData = [...warehouses];
        if (newData && warehouseId && warehouseId > 0) {
            const index = newData.findIndex((item: any) => item?.warehouse?.id == warehouseId);
            if (index > -1) {
                errorNotification(geti18nText('orderView.warehouse.exists'));
            } else {
                if (warehouseId) {
                    const newValues: any = { orderView: { id: orderViewId }, warehouse: { id: warehouseId } };
                    NyRequestResolver.requestPost(CONSTANTS_REQ.ORDER_VIEW_WAREHOUSE.EDIT, undefined, newValues).then(
                        (result: any) => {
                            if (result && result.status === RESPONSE.CREATED) {
                                getWarehouses(dataForm);
                                okNotification();
                                closeWarehouseModal();
                            } else {
                                errorNotification();
                            }
                        }
                    );
                } else {
                    closeWarehouseModal();
                }
            }
        } else {
            errorNotification();
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            editHeader={editHeader}
            url={CONSTANTS_REQ.ORDER_VIEW.EDIT}
            paramsId={id}
            setValues={setValues}
            width={1600}
            form={form}
            customFooterContent={getCustomFooterContent}
            buttonsClassName="buttons-sticky"
            changeCollapseVisibility={changeCollapseVisibility}
            checkIsFormChanged={true}
            setIsFormChange={setIsFormChange}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
            normalize={(values: any) => {
                if (values.type) values.type = getEnumFormat(values.type);
                return values;
            }}
            shortcuts={true}
        >
            {' '}
            <Form form={form} component={false}>
                <Row gutter={24}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={leftCollapseActiveKeys}
                            onChange={(key: any) => {
                                setLeftCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('orderView.divider.1')} key="1">
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('orderView.edit.name')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input ref={focusInput} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('orderView.edit.type')}
                                            name="type"
                                            initialValue={
                                                orderViewType != undefined
                                                    ? setEnumFormat('ORDER_VIEW_TYPE', orderViewType)
                                                    : getEnumArray('ORDER_VIEW_TYPE').at(0)
                                            }
                                        >
                                            <NySearchField
                                                options={getEnumArray('ORDER_VIEW_TYPE')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                style={{ width: '100%' }}
                                                disabled={orderViewType != undefined}
                                                className={''}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel
                                header={geti18nText('orderView.divider.3')}
                                disabled={!canCreate() || canEdit}
                                key="3"
                            >
                                <OrderViewAccessSettingsIndex orderViewId={orderViewId} scroll={{ y: 320, x: 400 }} />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Collapse
                            activeKey={rightCollapseActiveKeys}
                            onChange={(key: any) => {
                                setRightCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('orderView.divider.2')}
                                disabled={!canCreate() || canEdit}
                                key="2"
                            >
                                <OrderViewWarehouseIndex
                                    orderViewId={orderViewId}
                                    length={warehouses.length}
                                    scroll={{ y: 520, x: 400 }}
                                    setRefresh={setRefresh}
                                />
                                {canAddWarehouse() && (
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            onClick={() => setWarehouseModalVisible(true)}
                                            type="primary"
                                            style={{ marginLeft: '5px', marginTop: '20px' }}
                                        >
                                            {geti18nText('warehouse.table.add')}
                                        </Button>
                                    </div>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                {warehouseModalVisible && (
                    <Modal
                        title={geti18nText('warehouse.edit.new')}
                        okText={geti18nText('warehouse.table.add')}
                        visible={warehouseModalVisible}
                        onOk={saveOrderViewWarehouse}
                        maskClosable={false}
                        onCancel={closeWarehouseModal}
                        width={400}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <label>{geti18nText('warehouse.edit.title')}</label>
                                <Form.Item name="warehouse">
                                    <NySearchField
                                        url={CONSTANTS_REQ.WAREHOUSE.SEARCH}
                                        map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                        addNewItem={false}
                                        searchBy="name, code"
                                        renderOption={(option: any) => {
                                            return `${option.code ? '(' + option.code + ') ' : ''}${
                                                option.text ?? option.name
                                            }`;
                                        }}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        AddNewModalComponent={WarehouseEdit}
                                        SearchPopupComponent={<WarehouseIndex />}
                                        onChange={(data: any) => {
                                            setWarehouseId(data && data.id > 0 ? data.id : null);
                                        }}
                                        customListWidth={'1200px'}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>
                )}
            </Form>
        </NyDataEdit>
    );
};

export default OrderViewEdit;
