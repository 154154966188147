import {
    ENUMS,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    geti18nText,
    NyDataTable,
} from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, Modal, Popconfirm, Popover, Row, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import StatusHistoryIndex from '../../../../../components/status-history/StatusHistory';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookCreatedUser, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import {
    getDateFormat,
    getEnumArrayStatus,
    getErrorNotificationMessage,
    getTaskStatusIcon,
    okNotification,
    setTaskWatch,
    unsetTaskWatch,
} from '../../../../../utils/Utils';
import CreateTaskIndex from '../CreateTaskIndex';
import {
    EyeInvisibleOutlined,
    EyeOutlined,
} from '@ant-design/icons';

const TaskListIndexGeneral = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    listType,
    type,
    setDefaultFilterValue,
    url = undefined,
    watcherList = false,
    scroll = { y: 540, x: 1400 },
}: any) => {
    const location: any = useLocation();
    const table = useTableSettings();
    const taskStatusEnum = useEnum('TASK_STATUS');
    const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const [record, setRecord] = useState<any>({});
    const [cancelTaskVisible, setCancelTaskVisible] = useState(false);
    const [startTaskVisible, setStartTaskVisible] = useState(false);
    const [resolveTaskVisible, setResolveTaskVisible] = useState(false);
    const [cannotResolveTaskVisible, setCannotResolveTaskVisible] = useState(false);
    const [partiallyResolveTaskVisible, setPartiallyResolveTaskVisible] = useState(false);
    const [closeTaskVisible, setCloseTaskVisible] = useState(false);
    const [addDescriptionVisible, setAddDescriptionVisible] = useState(false);
    const [form] = Form.useForm();
    const focusInput = useRef<any>(null);
    const focusInput2 = useRef<any>(null);
    const [data, setData] = useState<any>(null);
    const [solutionDescriptionList, setSolutionDescriptionList] = useState<any>([]);
    const [refreshTable, setRefreshTable] = useState(0);

    useHelper('task_service/task-list');

    useEffect(() => {
        if (location?.state?.id && data?.length > 0) {
            setSelectedTaskId(data.filter((item: any) => item.id === location?.state?.id).at(0));
            setVisible(true);
            delete location.state;
        }
    }, [location /* active , tabs */, , data]);

    const getTaskStatusChangeHistory = (id: any, status: any) => {
        return (
            <div>
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="bottomLeft"
                    content={
                        <StatusHistoryIndex
                            id={id}
                            url={CONSTANTS_REQ.TASK.TASK_STATUS_HISTORY + '/' + id}
                            type="taskStatus"
                        />
                    }
                    style={{ marginLeft: '4rem' }}
                >
                    <div style={{ marginLeft: '5px' }}>{getTaskStatusIcon(status)}</div>
                </Popover>
            </div>
        );
    };

    const columns =
        listType === 'personal' || listType === 'all'
            ? [
                  {
                      title: geti18nText('tasks.list.column.id'),
                      dataIndex: 'id',
                      width: '4%',
                      sorter: true,
                      ...getColumnSearch('number'),
                  },
                  {
                      title: geti18nText('tasks.list.column.parent'),
                      dataIndex: ['parent', 'name'],
                      sorter: true,
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.parent) {
                              return record.parent.name;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.name'),
                      dataIndex: 'name',
                      sorter: true,
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.name) {
                              return record.name;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.description'),
                      dataIndex: 'description',
                      sorter: true,
                      ...getColumnSearch('string'),
                      render: (description: any, record: any) => {
                          if (record && record.description) {
                              return (
                                  <Tooltip
                                      placement="topLeft"
                                      overlayStyle={{ maxWidth: '200px' }}
                                      title={record.description}
                                  >
                                      <div className={'ny-table-paragraph-div'}>
                                          <p className={'ny-table-paragraph'}>{record && record.description}</p>
                                      </div>
                                  </Tooltip>
                              );
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.assignedTeam'),
                      dataIndex: ['assignedTeam', 'name'],
                      sorter: true,
                      width: '12%',
                      ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TEAM.SEARCH, 'assignedTeam.id', 'name', 'name', {
                          id: 'id',
                          label: 'name',
                          name: 'name',
                          code: 'code',
                      }),
                      render: (text: any, record: any) => {
                          if (record.assignedTeam) {
                              return record.assignedTeam.name + ' (' + record.assignedTeam.id + ')';
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.assignedUser'),
                      dataIndex: [`concat(assignedPerson.first_name,' ', assignedPerson.last_name)`],
                      width: '12%',
                      ...getColumnSearchByCodebookCreatedUser(
                          'assignedPerson.id',
                          'assignedPerson.lastName assignedPerson.firstName',
                          [
                              ['assignedPerson', 'firstName'],
                              ['assignedPerson', 'lastName'],
                          ]
                      ),
                      sorter: true,
                      render: (text: any, record: any) => {
                          if (record.assignedPerson) {
                              return record.assignedEmployee
                                  ? record.assignedPerson?.firstName +
                                        ' ' +
                                        record.assignedPerson?.lastName +
                                        ' (' +
                                        record.assignedEmployee?.employmentRecordId +
                                        ')'
                                  : record.assignedPerson?.firstName + ' ' + record.assignedPerson?.lastName;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.taskPriority'),
                      dataIndex: ['taskPriority', 'name'],
                      sorter: true,
                      width: '8%',
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.TASK_PRIORITY.SEARCH,
                          'taskPriority.id',
                          'name',
                          'name',
                          { id: 'id', label: 'name' }
                      ),
                      render: (text: any, record: any) => {
                          if (record.taskPriority) {
                              return record.taskPriority.name;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.status'),
                      dataIndex: 'status',
                      width: '188px',
                      sorter: true,
                      ...getColumnSearchCheckbox(getEnumArrayStatus('TASK_STATUS')),
                      render: (text: any, record: any) => {
                          if (record.status) {
                              return getTaskStatusChangeHistory(record.id, record.status);
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.created'),
                      dataIndex: 'created',
                      sorter: true,
                      width: '8%',
                      ...getColumnDateOption(),
                      render: (text: any, record: any) => {
                          if (record.created) {
                              return getDateFormat(record?.created, 'DD.MM.YYYY HH:mm');
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.createdBy'),
                      dataIndex: 'createdBy',
                      sorter: true,
                      width: '10%',
                      ...getColumnSearchByCodebookCreatedUser(
                          'createdByPerson.id',
                          'createdByPerson.lastName createdByPerson.firstName',
                          [
                              ['createdByPerson', 'firstName'],
                              ['createdByPerson', 'lastName'],
                          ]
                      ),
                      render: (text: any, record: any) => {
                          if (record.createdByPerson) {
                              return record.createdByEmployee
                                  ? record.createdByPerson?.firstName +
                                        ' ' +
                                        record.createdByPerson?.lastName +
                                        ' (' +
                                        record.createdByEmployee?.employmentRecordId +
                                        ')'
                                  : record.createdByPerson?.firstName + ' ' + record.createdByPerson?.lastName;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.watch'),
                      dataIndex: 'isWatched',
                      width: '66px',
                      render: (text: any, record: { isWatched: any; id: any }) => {
                          if (record.isWatched || watcherList) {
                              return (
                                  <Tooltip title={geti18nText('tasks.list.column.watch.hover.stop')}>
                                      <Popconfirm
                                          title={geti18nText('tasks.popup.watched.message.cancel')}
                                          okText={geti18nText('app.default.button.yes')}
                                          cancelText={geti18nText('app.default.button.no')}
                                          onConfirm={() => {
                                              unsetTaskWatch(record?.id, setRefresh);
                                          }}
                                      >
                                          <EyeOutlined
                                              style={{
                                                  color: 'green',
                                                  fontSize: '18px',
                                                  marginLeft: '12px',
                                                  marginTop: '5px',
                                              }}
                                          />
                                      </Popconfirm>
                                  </Tooltip>
                              );
                          } else {
                              return (
                                  <Tooltip title={geti18nText('tasks.list.column.watch.hover')}>
                                      <EyeInvisibleOutlined
                                          style={{
                                              color: '#1890ff',
                                              fontSize: '18px',
                                              marginLeft: '12px',
                                              marginTop: '5px',
                                          }}
                                          onClick={() => {
                                              setTaskWatch(record?.id, setRefresh);
                                          }}
                                      />
                                  </Tooltip>
                              );
                          }
                      },
                  },
                  {
                      title: geti18nText('app.default.actions'),
                      width: '4%',
                      dataIndex: 'actions',
                      render: (text: any, record: any) => {
                          if (record?.id) {
                              return (
                                  record?.actions?.length && (
                                      <RowAction
                                          component={'TaskList'}
                                          record={record}
                                          setRecord={setRecord}
                                          setAssignModalVisible={setVisible}
                                          setStartTaskVisible={setStartTaskVisible}
                                          setSelectedTaskId={setSelectedTaskId}
                                          setCancelTaskVisible={setCancelTaskVisible}
                                          setResolveTaskVisible={setResolveTaskVisible}
                                          setCannotResolveTaskVisible={setCannotResolveTaskVisible}
                                          setPartiallyResolveTaskVisible={setPartiallyResolveTaskVisible}
                                          setCloseTaskVisible={setCloseTaskVisible}
                                          setAddDescriptionVisible={setAddDescriptionVisible}
                                      />
                                  )
                              );
                          }
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('tasks.list.column.id'),
                      dataIndex: 'id',
                      width: '4%',
                      sorter: true,
                      ...getColumnSearch('number'),
                  },
                  {
                      title: geti18nText('tasks.list.column.name'),
                      dataIndex: 'name',
                      sorter: true,
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.name) {
                              return record.name;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.description'),
                      dataIndex: 'description',
                      sorter: true,
                      ...getColumnSearch('string'),
                      render: (description: any, record: any) => {
                          if (record && record.description) {
                              return (
                                  <Tooltip
                                      placement="topLeft"
                                      overlayStyle={{ maxWidth: '200px' }}
                                      title={record.description}
                                  >
                                      <div className={'ny-table-paragraph-div'}>
                                          <p className={'ny-table-paragraph'}>{record && record.description}</p>
                                      </div>
                                  </Tooltip>
                              );
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.taskTemplate'),
                      dataIndex: ['taskTemplate', 'name'],
                      sorter: true,
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.TASK_TEMPLATE.SEARCH,
                          'taskTemplate.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                              name: 'name',
                          }
                      ),
                      render: (text: any, record: any) => {
                          if (record.name) {
                              return record.name;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.assignedTeam'),
                      dataIndex: ['assignedTeam', 'name'],
                      sorter: true,
                      width: '12%',
                      ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TEAM.SEARCH, 'assignedTeam.id', 'name', 'name', {
                          id: 'id',
                          label: 'name',
                          name: 'name',
                          code: 'code',
                      }),
                      render: (text: any, record: any) => {
                          if (record.assignedTeam) {
                              return record.assignedTeam.name + ' (' + record.assignedTeam.id + ')';
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.assignedUser'),
                      dataIndex: [`concat(assignedPerson.first_name,' ', assignedPerson.last_name)`],
                      width: '12%',
                      ...getColumnSearchByCodebookCreatedUser(
                          'assignedPerson.id',
                          'assignedPerson.lastName assignedPerson.firstName',
                          [
                              ['assignedPerson', 'firstName'],
                              ['assignedPerson', 'lastName'],
                          ]
                      ),
                      sorter: true,
                      render: (text: any, record: any) => {
                          if (record.assignedPerson) {
                              return record.assignedEmployee
                                  ? record.assignedPerson?.firstName +
                                        ' ' +
                                        record.assignedPerson?.lastName +
                                        ' (' +
                                        record.assignedEmployee?.employmentRecordId +
                                        ')'
                                  : record.assignedPerson?.firstName + ' ' + record.assignedPerson?.lastName;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.taskPriority'),
                      dataIndex: ['taskPriority', 'name'],
                      sorter: true,
                      width: '12%',
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.TASK_PRIORITY.SEARCH,
                          'taskPriority.id',
                          'name',
                          'name',
                          { id: 'id', label: 'name' }
                      ),
                      render: (text: any, record: any) => {
                          if (record.taskPriority) {
                              return record.taskPriority.name;
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.status'),
                      dataIndex: 'status',
                      width: '188px',
                      sorter: true,
                      ...getColumnSearchCheckbox(
                          getEnumArrayStatus('TASK_STATUS'),
                          'in',
                          [taskStatusEnum.NEW, taskStatusEnum.ASSIGNED, taskStatusEnum.IN_PROGRESS].toString()
                      ),
                      render: (text: any, record: any) => {
                          if (record.status) {
                              return getTaskStatusChangeHistory(record.id, record.status);
                          }
                      },
                  },
                  {
                      title: geti18nText('tasks.list.column.watch'),
                      dataIndex: 'isWatched',
                      width: '66px',
                      render: (text: any, record: { isWatched: any; id: any }) => {
                          if (record.isWatched) {
                              return (
                                  <Tooltip title={geti18nText('tasks.list.column.watch.hover.stop')}>
                                      <Popconfirm
                                          title={geti18nText('tasks.popup.watched.message.cancel')}
                                          okText={geti18nText('app.default.button.yes')}
                                          cancelText={geti18nText('app.default.button.no')}
                                          onConfirm={() => {
                                              unsetTaskWatch(record?.id, setRefresh);
                                          }}
                                      >
                                          <EyeOutlined
                                              style={{
                                                  color: 'green',
                                                  fontSize: '18px',
                                                  marginLeft: '12px',
                                                  marginTop: '5px',
                                              }}
                                          />
                                      </Popconfirm>
                                  </Tooltip>
                              );
                          } else {
                              return (
                                  <Tooltip title={geti18nText('tasks.list.column.watch.hover')}>
                                      <EyeInvisibleOutlined
                                          style={{
                                              color: '#1890ff',
                                              fontSize: '18px',
                                              marginLeft: '12px',
                                              marginTop: '5px',
                                          }}
                                          onClick={() => {
                                              setTaskWatch(record?.id, setRefresh);
                                          }}
                                      />
                                  </Tooltip>
                              );
                          }
                      },
                  },
                  {
                      title: geti18nText('app.default.actions'),
                      width: '4%',
                      dataIndex: 'actions',
                      render: (text: any, record: any) => {
                          if (record?.id) {
                              return (
                                  record?.actions?.length && (
                                      <RowAction
                                          component={'TaskList'}
                                          record={record}
                                          setRecord={setRecord}
                                          setAssignModalVisible={setVisible}
                                          setStartTaskVisible={setStartTaskVisible}
                                          setSelectedTaskId={setSelectedTaskId}
                                          setCancelTaskVisible={setCancelTaskVisible}
                                          setResolveTaskVisible={setResolveTaskVisible}
                                          setCannotResolveTaskVisible={setCannotResolveTaskVisible}
                                          setPartiallyResolveTaskVisible={setPartiallyResolveTaskVisible}
                                          setCloseTaskVisible={setCloseTaskVisible}
                                          setAddDescriptionVisible={setAddDescriptionVisible}
                                      />
                                  )
                              );
                          }
                      },
                  },
              ];

    const addedButtons = () => {
        return (
            <div style={{ float: 'right' }}>
                <Button type="primary" onClick={() => history.push('/task-service')}>
                    {geti18nText('menu.task.service')}
                </Button>
                {Object.values(['personal', 'all']).includes(listType) && (
                    <Button type="primary" onClick={() => setVisible(true)}>
                        {geti18nText('task.table.add')}
                    </Button>
                )}
            </div>
        );
    };

    const modifyTask = (params: any, status: any) => {
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TASK.CHANGE_STATUS + '/' + (params.id ? params.id : selectedTaskId),
            undefined,
            {
                id: params.id ? params.id : selectedTaskId,
                status: status,
                info: form.getFieldValue(['info']),
                solutionDescription: form.getFieldValue(['solutionDescription']),
            }
        ).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                form.setFieldsValue({ solutionDescription: null });
                form.setFieldsValue({ info: null });
                okNotification();
                if (cancelTaskVisible) {
                    setCancelTaskVisible(false);
                }
                if (startTaskVisible) {
                    setStartTaskVisible(false);
                }
                if (resolveTaskVisible) {
                    setResolveTaskVisible(false);
                }
                if (cannotResolveTaskVisible) {
                    setCannotResolveTaskVisible(false);
                }
                if (partiallyResolveTaskVisible) {
                    setPartiallyResolveTaskVisible(false);
                }
                if (closeTaskVisible) {
                    setCloseTaskVisible(false);
                }
                if (refresh && setRefresh) {
                    setRefresh((refresh: any) => refresh + 1);
                } else if (refreshTable && setRefreshTable) {
                    setRefreshTable((refreshTable: any) => refreshTable + 1);
                }
                if (visible && setVisible) {
                    setVisible(false);
                }
            } else {
                getErrorNotificationMessage(result);
            }
        });
    };

    const onOk = () => {
        if (cancelTaskVisible) {
            modifyTask(record, taskStatusEnum.CANCELLED);
        }
        if (startTaskVisible) {
            modifyTask(record, taskStatusEnum.IN_PROGRESS);
        }
        if (resolveTaskVisible) {
            modifyTask(record, taskStatusEnum.RESOLVED);
        }
        if (cannotResolveTaskVisible) {
            modifyTask(record, taskStatusEnum.NOT_RESOLVED);
        }
        if (partiallyResolveTaskVisible) {
            modifyTask(record, taskStatusEnum.PARTIALLY_RESOLVED);
        }
        if (closeTaskVisible) {
            modifyTask(record, taskStatusEnum.CLOSED);
        }
    };

    const onCancel = () => {
        form.setFieldsValue({ solutionDescription: null });
        form.setFieldsValue({ info: null });
        if (cancelTaskVisible) {
            setCancelTaskVisible(false);
        }
        if (startTaskVisible) {
            setStartTaskVisible(false);
        }
        if (resolveTaskVisible) {
            setResolveTaskVisible(false);
        }
        if (cannotResolveTaskVisible) {
            setCannotResolveTaskVisible(false);
        }
        if (partiallyResolveTaskVisible) {
            setPartiallyResolveTaskVisible(false);
        }
        if (closeTaskVisible) {
            setCloseTaskVisible(false);
        }
    };

    useEffect(() => {
        if (addDescriptionVisible) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.TASK.GET_SOLUTION_DESCRIPTION.replace('{taskId}', record.id),
                undefined
            ).then((result: any) => {
                setSolutionDescriptionList(result.status === RESPONSE.OK && result.data ? result.data : []);
            });
        }
    }, [addDescriptionVisible]);

    const onOkDescription = () => {
        form.setFieldsValue({
            solutionDescription:
                solutionDescriptionList?.length > 0
                    ? solutionDescriptionList
                          .map((data: any) => {
                              return data.solutionDescription;
                          })
                          .join('\n')
                    : null,
            info: null,
        });
        setAddDescriptionVisible(false);
    };

    const onCancelDescription = () => {
        form.setFieldsValue({ solutionDescription: null, info: null });
        setAddDescriptionVisible(false);
    };

    useEffect(() => {
        if (cancelTaskVisible || startTaskVisible || closeTaskVisible) {
            setTimeout(() => {
                if (focusInput.current) {
                    focusInput.current.focus();
                }
            });
        }
        if (resolveTaskVisible || cannotResolveTaskVisible || partiallyResolveTaskVisible) {
            setTimeout(() => {
                if (focusInput2.current) {
                    focusInput2.current.focus();
                }
            });
        }
    }, [
        cancelTaskVisible,
        startTaskVisible,
        resolveTaskVisible,
        cannotResolveTaskVisible,
        partiallyResolveTaskVisible,
        closeTaskVisible,
    ]);

    return (
        <>
            <NyDataTable
                nyId="tasks-task-index-table"
                url={url ? url : CONSTANTS_REQ.TASK.LIST.replace('{type}', type)}
                addNewButtonText={geti18nText('tasks.list.add')}
                fetchWhenChange={refresh || refreshTable}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                onRowSelect={(data: any) => {
                    setSelectedTaskId(data && data.id);
                    setVisible(true);
                }}
                editProps={{
                    setRefreshTable: setRefresh,
                    refreshTable: refresh,
                }}
                columns={columns}
                setDefaultSortOrder={{ order: 'id', orderType: 'desc' }}
                scroll={scroll}
                onDataLoaded={(data: any) => setData(data)}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue)}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                shortcuts={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                addedButtons={addedButtons}
            />
            {(selectedTaskId || visible) && (
                <CreateTaskIndex
                    taskType={type}
                    selectedTaskId={selectedTaskId}
                    setSelectedTaskId={setSelectedTaskId}
                    refreshTable={refresh}
                    setRefreshTable={setRefresh}
                    visible={visible}
                    setVisible={setVisible}
                    getTaskStatusChangeHistory={getTaskStatusChangeHistory}
                    setAssignModalVisible={setVisible}
                    setStartTaskVisible={setStartTaskVisible}
                    setCancelTaskVisible={setCancelTaskVisible}
                    setResolveTaskVisible={setResolveTaskVisible}
                    setCannotResolveTaskVisible={setCannotResolveTaskVisible}
                    setPartiallyResolveTaskVisible={setPartiallyResolveTaskVisible}
                    setAddDescriptionVisible={setAddDescriptionVisible}
                    setCloseTaskVisible={setCloseTaskVisible}
                    onOkAction={onOk}
                    onCancelAction={onCancel}
                    taskActionForm={form}
                    listType={listType}
                />
            )}
            {(cancelTaskVisible ||
                startTaskVisible ||
                resolveTaskVisible ||
                cannotResolveTaskVisible ||
                partiallyResolveTaskVisible ||
                closeTaskVisible) && (
                <Modal
                    open={
                        cancelTaskVisible ||
                        startTaskVisible ||
                        resolveTaskVisible ||
                        cannotResolveTaskVisible ||
                        partiallyResolveTaskVisible ||
                        closeTaskVisible
                    }
                    title={
                        cancelTaskVisible
                            ? geti18nText('tasks.modal.message.statusCancel')
                            : startTaskVisible ||
                              resolveTaskVisible ||
                              cannotResolveTaskVisible ||
                              partiallyResolveTaskVisible
                            ? geti18nText(
                                  listType === 'personal' || listType === 'all'
                                      ? 'tasks.modal.message.statusChange'
                                      : 'tasks.modal.message.request.statusChange',
                                  [
                                      record.id,
                                      geti18nText('app.enum.TASK_STATUS.' + record.status),
                                      geti18nText(
                                          'app.enum.TASK_STATUS.' +
                                              (startTaskVisible
                                                  ? taskStatusEnum.IN_PROGRESS
                                                  : resolveTaskVisible
                                                  ? taskStatusEnum.RESOLVED
                                                  : cannotResolveTaskVisible
                                                  ? taskStatusEnum.NOT_RESOLVED
                                                  : taskStatusEnum.PARTIALLY_RESOLVED)
                                      ),
                                  ]
                              )
                            : geti18nText('tasks.modal.message.statusClose')
                    }
                    onOk={async () => {
                        try {
                            await form.validateFields();
                            onOk();
                        } catch (e) {}
                    }}
                    onCancel={onCancel}
                    width={600}
                    forceRender={true}
                    closable={false}
                    maskClosable={false}
                    cancelText={geti18nText('app.default.button.no')}
                    okText={geti18nText('app.default.button.yes')}
                >
                    <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        {(resolveTaskVisible || cannotResolveTaskVisible || partiallyResolveTaskVisible) && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="solutionDescription"
                                        label={geti18nText('task.edit.edit.solution')}
                                        rules={[
                                            {
                                                required:
                                                    resolveTaskVisible ||
                                                    cannotResolveTaskVisible ||
                                                    partiallyResolveTaskVisible,
                                                message: geti18nText('app.default.required'),
                                                whitespace:
                                                    resolveTaskVisible ||
                                                    cannotResolveTaskVisible ||
                                                    partiallyResolveTaskVisible,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 5 }} ref={focusInput2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="info"
                                    label={geti18nText('travelWarrantOrganization.table.column.message')}
                                    rules={[
                                        {
                                            required: cancelTaskVisible || startTaskVisible,
                                            message: geti18nText('app.default.required'),
                                            whitespace: cancelTaskVisible || startTaskVisible,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 5 }} ref={focusInput} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
            {addDescriptionVisible && solutionDescriptionList?.length > 1 && (
                <Modal
                    open={addDescriptionVisible && solutionDescriptionList?.length > 1}
                    title={geti18nText('tasks.modal.message.addDescription')}
                    onOk={onOkDescription}
                    onCancel={onCancelDescription}
                    width={900}
                    forceRender={true}
                    closable={false}
                    maskClosable={false}
                    cancelText={geti18nText('app.default.button.no')}
                    okText={geti18nText('app.default.button.yes')}
                >
                    <>
                        <Descriptions
                            column={1}
                            bordered
                            size="small"
                            labelStyle={{ width: '10rem' }}
                            layout="vertical"
                        >
                            {solutionDescriptionList.map((solutionDescription: any) => {
                                return (
                                    <Descriptions.Item
                                        label={
                                            <b>
                                                {geti18nText('task.edit.edit.solutionDescription.task') +
                                                    ' (ID:' +
                                                    solutionDescription?.id +
                                                    ')'}
                                            </b>
                                        }
                                    >
                                        {solutionDescription?.solutionDescription}
                                    </Descriptions.Item>
                                );
                            })}
                        </Descriptions>
                    </>
                </Modal>
            )}
        </>
    );
};

export default TaskListIndexGeneral;
