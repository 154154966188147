import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import EducationalProgramEdit from './edit';

const EducationalProgramIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    educationalInstitutionId,
    addedData,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const setEducationalProgramFilterValue = () => {
        if (educationalInstitutionId != undefined && educationalInstitutionId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'educationalInstitutionId', condition: 'equals', value: educationalInstitutionId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const table = useTableSettings();

    useHelper('human/codebooks/educationalProgram');

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('qualification.level.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('qualification.level.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            render: (name: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record?.name}>
                        <div className={'ny-table-paragraph-div'}>
                            <p className={'ny-table-paragraph'}>{record?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educational.program.table.column.qualification.level'),
            dataIndex: ['qualificationLevel', 'name'],
            render: (qualificationLevel: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '2000px' }}
                        title={record?.qualificationLevel?.name}
                    >
                        <div className={'ny-table-paragraph-div'}>
                            <p className={'ny-table-paragraph'}>{record?.qualificationLevel?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.QUALIFICATION_LEVEL.SEARCH,
                'qualificationLevel.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('educational.program.table.column.educational.institution'),
            dataIndex: ['educationalInstitution', 'name'],
            render: (educationalInstitution: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '200px' }}
                        title={record?.educationalInstitution?.name}
                    >
                        <div className={'ny-table-paragraph-div'}>
                            <p className={'ny-table-paragraph'}>{record?.educationalInstitution?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},

            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.EDUCATIONAL_INSTITUTION.SEARCH,
                'educationalInstitution.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('educational.program.table.column.educational.title'),
            dataIndex: ['educationalTitle', 'name'],
            render: (educationalTitle: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '200px' }}
                        title={record?.educationalTitle?.name}
                    >
                        <div className={'ny-table-paragraph-div'}>
                            <p className={'ny-table-paragraph'}>{record?.educationalTitle?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.EDUCATIONAL_TITLE.SEARCH,
                'educationalTitle.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('educational.program.table.column.nr.years'),
            dataIndex: 'nr_years',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (years: any, record: any) => {
                return record?.years;
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('educational.program.table.column.nr.semester'),
            dataIndex: 'nr_semester',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (semester: any, record: any) => {
                return record?.semester;
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('educational.program.table.column.ects'),
            dataIndex: 'ects',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('professional.qualification.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <NyDataTable
            nyId="human-educational-program-table"
            url={CONSTANTS_REQ.EDUCATIONAL_PROGRAM.LIST}
            addNewButtonText={geti18nText('educational.program.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={EducationalProgramEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setEducationalProgramFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            hideButtons={!canCreate()}
            editProps={addedData}
            shortcuts={true}
            headerTitle={geti18nText('menu.educational.program')}
        />
    );
};

export default EducationalProgramIndex;
