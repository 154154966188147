import { NyDatePicker, NyInputNumber, NySearchField, NySpinner, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import { setDateFormat, setSearchFormat } from '../../../../../utils/Utils';
import BusinessUnitIndex from '../../business-unit';
import EducationalInstitutionIndex from '../../educational-institution';
import VocationIndex from '../../vocation';
import ProfessionalQualificationIndex from '../../professional-qualification';
import QualificationLevelIndex from '../../qualification-level';
import EmploymentTypeIndex from '../../employment-type';
import EducationalTitleIndex from '../../educational-title';

const ContractSelectMetadataIndex = ({
    objectMetadataForm,
    metadata,
    metadataTemplate,
    employeeMetadata,
    personalView = false,
    contractEmployeeId,
    newEmployeeId,
    annex = false,
}: any) => {
    const [loading, setLoading] = useState<any>(false);
    const enContractMetadataCodebook = useEnum('CONTRACT_METADATA_CODEBOOK');
    const enContractMetadata = useEnum('CONTRACT_METADATA');
    const isDisabledMetadata = () => {
        return false;
    };

    useEffect(() => {
        if (metadataTemplate !== undefined && metadataTemplate.length > 0 && metadata && metadata.length > 0) {
            setLoading(true);
            const metadataForm: any = {};
            metadata.map((item: any) => {
                if (item.contractMetadata !== undefined) {
                    const findItem: any = metadataTemplate.find(
                        (row: any) => row?.contractMetadata?.id === item?.contractMetadata?.id
                    );
                    if (findItem?.contractMetadata) {
                        if (findItem.contractMetadata?.dataType === enContractMetadata.DATE) {
                            metadataForm[findItem.contractMetadata.name] = setDateFormat(item.value);
                        } else if (findItem.contractMetadata?.dataType === enContractMetadata.CODEBOOK) {
                            let value =
                                item.codebookValue && JSON.parse(item.codebookValue)
                                    ? JSON.parse(item.codebookValue)
                                    : item.value
                                    ? { id: 1, name: item.value }
                                    : undefined;
                            value.text = value.name;
                            value.label = value.name;
                            metadataForm[findItem.contractMetadata.name] = setSearchFormat(value, 'name', 'name');
                        } else {
                            metadataForm[findItem.contractMetadata.name] = item.value;
                        }
                    }
                }
            });
            objectMetadataForm.setFieldsValue(metadataForm);
            setLoading(false);
        }
    }, [metadataTemplate, metadata]);

    useEffect(() => {
        if (newEmployeeId === -1) {
            objectMetadataForm.resetFields();
        } else if (
            metadataTemplate !== undefined &&
            metadataTemplate.length > 0 &&
            employeeMetadata?.length > 0 &&
            (contractEmployeeId != newEmployeeId || annex)
        ) {
            setLoading(true);
            objectMetadataForm.resetFields();
            const metadataForm: any = {};
            employeeMetadata.map((item: any) => {
                if (item.contractMetadata !== undefined) {
                    const findItem: any = metadataTemplate.find(
                        (row: any) => row?.contractMetadata?.id === item?.contractMetadata?.id
                    );
                    if (findItem?.contractMetadata) {
                        if (findItem.contractMetadata?.dataType === enContractMetadata.DATE) {
                            metadataForm[findItem.contractMetadata.name] = setDateFormat(item.value);
                        } else if (findItem.contractMetadata?.dataType === enContractMetadata.CODEBOOK) {
                            let value =
                                item.codebookValue && JSON.parse(item.codebookValue)
                                    ? JSON.parse(item.codebookValue)
                                    : item.value
                                    ? { id: 1, name: item.value }
                                    : undefined;
                            value.text = value.name;
                            value.label = value.name;
                            metadataForm[findItem.contractMetadata.name] = setSearchFormat(value, 'name', 'name');
                        } else {
                            metadataForm[findItem.contractMetadata.name] = item.value;
                        }
                    }
                }
            });
            objectMetadataForm.setFieldsValue(metadataForm);
            setLoading(false);
        }
    }, [employeeMetadata]);

    const getDataTypeCodebooksInput = (data: any) => {
        let item = { ...data };
        let enumDataCodebookType = item?.contractMetadata?.codebook ?? '';
        let enumDataCodebookTypeUrl =
            CONSTANTS_REQ.URL_PREFIX +
            (item?.contractMetadata?.codebook ? item.contractMetadata.codebook.substring(1) + '/search' : '');
        switch (enumDataCodebookType) {
            case enContractMetadataCodebook.BUSINESS_UNIT:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.BUSINESS_UNIT.SEARCH}
                        map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                        searchBy="name,code"
                        itemName={[['code'], ['name']]}
                        customItemNameLabel={'name (code)'}
                        renderOption={(option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`}
                        SearchPopupComponent={<BusinessUnitIndex readonly={true} />}
                        disabled={isDisabledMetadata()}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        mustGetPopupContainer={false}
                        style={{ width: '100%' }}
                    />
                );
            case enContractMetadataCodebook.EDUCATIONAL_INSTITUTION:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.EDUCATIONAL_INSTITUTION.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        mustGetPopupContainer={false}
                        SearchPopupComponent={<EducationalInstitutionIndex readonly />}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                    />
                );
            case enContractMetadataCodebook.VOCATION:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.VOCATION.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        mustGetPopupContainer={false}
                        SearchPopupComponent={<VocationIndex disabled />}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                    />
                );
            case enContractMetadataCodebook.PROFESSIONAL_QUALIFICATION:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        mustGetPopupContainer={false}
                        SearchPopupComponent={<ProfessionalQualificationIndex readonly />}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                    />
                );
            case enContractMetadataCodebook.QUALIFICATION_LEVEL:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.QUALIFICATION_LEVEL.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        mustGetPopupContainer={false}
                        SearchPopupComponent={<QualificationLevelIndex readonly />}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                    />
                );
            case enContractMetadataCodebook.EMPLOYMENT_TYPE:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.EMPLOYMENT_TYPE.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        mustGetPopupContainer={false}
                        SearchPopupComponent={<EmploymentTypeIndex readonly />}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                    />
                );
            case enContractMetadataCodebook.EDUCATIONAL_TITLE:
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.EDUCATIONAL_TITLE.SEARCH}
                        map={{ id: 'id', label: 'name', shortName: 'shortName', text: 'name' }}
                        searchBy="name,shortName"
                        customItemNameLabel={'name (shortName)'}
                        renderOption={(option: any) =>
                            `${option.text} ${option.shortName ? '(' + option.shortName + ') ' : ''}`
                        }
                        SearchPopupComponent={<EducationalTitleIndex readonly />}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                        mustGetPopupContainer={false}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                    />
                );
            default:
                return (
                    <NySearchField
                        url={enumDataCodebookTypeUrl}
                        map={{ id: 'id', label: 'text' }}
                        searchBy="name"
                        mustGetPopupContainer={false}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        disabled={isDisabledMetadata()}
                        style={{ width: '100%' }}
                    />
                );
        }
    };

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'CONTRACT_METADATA',
            value: item?.contractMetadata?.dataType,
        });
        switch (enumDataType) {
            case 'TEXT':
                return <Input disabled={isDisabledMetadata()} />;
            case 'NUMBER':
                let patternN;
                if (item?.contractMetadata?.pattern) {
                    patternN = JSON.parse(item.contractMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                        disabled={isDisabledMetadata()}
                    />
                );
            case 'BOOL':
                return <Checkbox disabled={isDisabledMetadata()} />;
            case 'DATE':
                return (
                    <NyDatePicker
                        format={item?.contractMetadata?.pattern}
                        style={{ width: '100%' }}
                        disabled={isDisabledMetadata()}
                        mustGetPopupContainer={false}
                    />
                );
            case 'CODEBOOK':
                return getDataTypeCodebooksInput(item);
            default:
                return <Input disabled={isDisabledMetadata()} />;
        }
    };

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <Form form={objectMetadataForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Row gutter={24}>
                        {metadataTemplate &&
                            metadataTemplate.map((item: any) => {
                                return (
                                    <Col span={12}>
                                        <Form.Item
                                            label={item?.contractMetadata?.name}
                                            name={item?.contractMetadata?.name}
                                            rules={[
                                                {
                                                    required: item?.mandatory,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            valuePropName={
                                                item?.contractMetadata?.dataType === enContractMetadata.BOOL
                                                    ? 'checked'
                                                    : 'value'
                                            }
                                        >
                                            {getDataTypeInput(item)}
                                        </Form.Item>
                                    </Col>
                                );
                            })}
                    </Row>
                </Form>
            )}
        </>
    );
};

export default ContractSelectMetadataIndex;
