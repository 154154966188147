import { geti18nText } from '@nybble/nyreact';
import { Radio } from 'antd';
import { useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import MealLogTableIndex from '../../../webshop/views/meals/meal-log-table';
import HumanLogIndex from './HumanLogIndex';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const LogsIndex = () => {
    const [selectedLog, setSelectedLog] = useState<any>(1);

    useHelper('common/logs');

    return (
        <>
            <Radio.Group
                value={selectedLog}
                onChange={(e: any) => setSelectedLog(e.target.value)}
                optionType="button"
                buttonStyle="solid"
            >
                <Radio value={1}>{geti18nText('logs.human.button')}</Radio>
                <Radio value={2}>{geti18nText('logs.point.button')}</Radio>
                <Radio value={3}>{geti18nText('logs.meals.button')}</Radio>
            </Radio.Group>

            {selectedLog == 1 && <HumanLogIndex />}
            {selectedLog == 2 && <MealLogTableIndex url={CONSTANTS_REQ.LOGS.POINT_LIST} type={'pointSync'} />}
            {selectedLog == 3 && <MealLogTableIndex />}
        </>
    );
};

export default LogsIndex;
