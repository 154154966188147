import { ShareAltOutlined } from '@ant-design/icons';
import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption, onKeyDownTextAreaCustom, toBinary } from '../../../../../utils/Utils';
import BusinessUnitIndex from '../../../../human/views/business-unit';
import EmployeeIndex from '../../../../human/views/employee';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';

const InventoryLink = ({ inventoryAssetId, businessUnitId = undefined }: any) => {
    const url = window.location.origin + '/#/mobile/asset-inventory/search=';
    const [linkForm] = Form.useForm();

    let defaultFilterValue = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'nfc_tag', condition: 'equals_bool', value: 1 },
        { field: 'inventory_asset_item_status', condition: 'equals', value: 1 },
        { field: 'inventory_asset_id', condition: 'equals', value: inventoryAssetId },
    ];

    useEffect(() => {
        setNewUrlLink();
    }, []);

    const setNewUrlLink = () => {
        let currentDefaultFilterValue = [...defaultFilterValue];
        if (linkForm.getFieldValue('availabilityStatus')) {
            currentDefaultFilterValue.push({ field: 'availability_status', condition: 'equals', value: 1 });
        }
        if (linkForm.getFieldValue('employee') && linkForm.getFieldValue('employee').length > 0) {
            let ids = linkForm.getFieldValue('employee').map((val: any) => val.id);
            currentDefaultFilterValue.push({ field: 'employee_id', condition: 'in', value: ids.toString() });
        }
        if (linkForm.getFieldValue('businessUnit') && linkForm.getFieldValue('businessUnit').length > 0) {
            let ids = linkForm.getFieldValue('businessUnit').map((val: any) => val.id);
            currentDefaultFilterValue.push({ field: 'business_unit_id', condition: 'in', value: ids.toString() });
            currentDefaultFilterValue.push({
                field: 'employee.business_unit_id',
                condition: 'in',
                value: ids.toString(),
            });
        }

        const stringUri = btoa(toBinary(JSON.stringify(currentDefaultFilterValue)));
        linkForm.setFieldsValue({ link: url + stringUri });
    };

    const onChange = (values: any) => {
        setNewUrlLink();
    };

    return (
        <Form form={linkForm} layout="vertical">
            <Row gutter={24}>
                <Col span={4}>
                    <Form.Item
                        label={geti18nText('assetInventory.inventoryLink.availabilityStatus')}
                        name="availabilityStatus"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox onChange={onChange} disabled={businessUnitId} />
                    </Form.Item>
                </Col>{' '}
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <BusinessUnitSearch
                        formItemName={'businessUnit'}
                        onChange={onChange}
                        mode={'multiple'}
                        setDefaultFilterValue={
                            businessUnitId
                                ? () => {
                                      return [{ field: 'parent_id', condition: 'equals', value: businessUnitId }];
                                  }
                                : undefined
                        }
                    />
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('assetInventory.inventoryLink.employee')} name="employee">
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            mode={'multiple'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="inventory-link"/>}
                            onChange={onChange}
                            setDefaultFilterValue={
                                businessUnitId
                                    ? () => {
                                          return [
                                              { field: 'business_unit_id', condition: 'equals', value: businessUnitId },
                                          ];
                                      }
                                    : undefined
                            }
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('assetInventory.inventoryLink.link')} name="link">
                        <Input.TextArea rows={5} onKeyDown={onKeyDownTextAreaCustom} readOnly />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default InventoryLink;
