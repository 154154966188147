import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Tabs, Tooltip } from 'antd';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getEmployeeSelectOption,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    setEnumFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';
import TravelFuelTypeSearch from '../travel-fuel-type/search';

const { TabPane } = Tabs;

const TravelVehicleEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelVehicle.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isPrivate, setIsPrivate] = useState<any>(false);
    const [hideFields, setHideFields] = useState<any>(false);
    const [showTravelFuelType, setShowTravelFuelType] = useState<boolean>(false);
    const [isOfficialCar, setIsOfficialCar] = useState<boolean>(false);
    const enTravelVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const enableCarbonFootprint = useApplicationSetting('TRAVEL_WARRANT_ENABLE_CARBON_FOOTPRINT');
    const [activeKey, setAcitveKey] = useState('1');
    const [isAddListModalVisible, setIsAddListModalVisible] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const [isCreate, setIsCreate] = useState(false);
    const [selectedValuesDelete, setSelectedValuesDelete] = useState<any>([]);
    const [hasSelectedDelete, setHasSelectedDelete] = useState(false);
    const [selectedValuesAdd, setSelectedValuesAdd] = useState<any>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelVehicle();
    };

    const canEdit = () => {
        return TravelWarrantRights.canEditTravelVehicle();
    };

    const hideButtons = () => {
        return (!dataForm && !canCreate()) || (dataForm && !canEdit());
    };

    const table = useTableSettings();

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('travelVehicle.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }

        if (dataForm.vehicleType) {
            setIsPrivate(dataForm.vehicleType === enTravelVehicleType.PRIVATE_CAR);
            setHideFields(
                Object.values([
                    enTravelVehicleType.PLANE,
                    enTravelVehicleType.BOAT,
                    enTravelVehicleType.TRAIN,
                    enTravelVehicleType.BUS,
                    enTravelVehicleType.PROVIDED_BY_ORGANIZER,
                    enTravelVehicleType.OTHER_PUBLIC,
                    enTravelVehicleType.OTHER,
                ]).includes(dataForm.vehicleType)
            );
            setShowTravelFuelType(
                Object.values([enTravelVehicleType.PRIVATE_CAR, enTravelVehicleType.OFFICIAL_CAR]).includes(
                    dataForm.vehicleType
                )
            );
            setIsOfficialCar(Object.values([enTravelVehicleType.OFFICIAL_CAR]).includes(dataForm.vehicleType));
            dataForm.vehicleType = setEnumFormat('TRAVEL_VEHICLE_TYPE', dataForm.vehicleType);
        }
        if (dataForm.travelFuelType) {
            dataForm.travelFuelType = setSearchFormat(dataForm.travelFuelType, 'name', 'name');
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setIsPrivate(false);
        setHideFields(false);
        setDataForm(null);
        setEditHeader(geti18nText('travelVehicle.edit.new'));
        setDataForm(null);
    };

    let clearKeysDelete: any;

    const setSelectedValuesFuncDelete = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDelete(hasSelected);
        setSelectedValuesDelete(selectedRows);
        clearKeysDelete = clearSelectedRowKeys;
    };

    let clearKeysAdd: any;

    const setSelectedValuesFuncAdd = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAdd(hasSelected);
        setSelectedValuesAdd(selectedRowKeys);
        clearKeysAdd = clearSelectedRowKeys;
    };

    const removeFromGroup = () => {
        const res = selectedValuesDelete.map((value: any) => {
            return value.id;
        });

        NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_VEHICLE.REMOVE_EMPLOYEE, undefined, {
            travelVehicleId: form.getFieldValue('id'),
            employees: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDelete) {
                    clearKeysDelete();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const save = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);

        NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_VEHICLE.ADD_EMPLOYEE, undefined, {
            travelVehicleId: form.getFieldValue('id'),
            employees: selectedValuesAdd,
        }).then((result: any) => {
            if (setLoadingModal) setLoadingModal(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAdd) {
                    clearKeysAdd();
                }

                okNotification();
                //    setIsModalVisible(false);
                setIsAddListModalVisible(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const generateVehicleName = () => {
        const type = form.getFieldValue('vehicleType');
        const model = form.getFieldValue('model');
        const licencePlate = form.getFieldValue('licencePlate');

        let name = '';
        if (type && type.text) {
            name += type.text;
        }
        if (model) {
            if (name) {
                name += ' - ';
            }
            name += model;
        }
        if (licencePlate) {
            if (name) {
                name += ' ';
            }
            name += '[' + licencePlate + ']';
        }
        form.setFieldsValue({ name: name });
    };

    const capitalizeLicencePlate = (e: any) => {
        const type = form.getFieldValue('vehicleType');
        const model = form.getFieldValue('model');
        if (e.target.value) {
            form.setFieldsValue({ licencePlate: e.target.value.toUpperCase() });
        }

        let name = '';
        if (type?.text) {
            name += type.text;
        }
        if (model) {
            if (name) {
                name += ' - ';
            }
            name += model;
        }
        if (e.target.value) {
            if (name) {
                name += ' ';
            }
            name += '[' + e.target.value.toUpperCase() + ']';
        }

        form.setFieldsValue({ name: name });
    };

    const licencePlateText = () => {
        return (
            <Tooltip title={geti18nText('travelVehicle.edit.licensePlate.tooltip')}>
                {geti18nText('travelVehicle.edit.licencePlate')}
                <Button icon={<InfoCircleOutlined />} type="text" size="small" style={{ pointerEvents: 'none' }} />
            </Tooltip>
        );
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisible(true);
                        }}
                    >
                        {geti18nText('employeeLeave.additional.days.tab.add.employee')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('employeeLeave.additional.days.tab.out.remove.confirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDelete}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDelete}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('employeeLeave.additional.days.tab.out.remove')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TRAVEL_VEHICLE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            setIsCreate={setIsCreate}
            shortcuts={true}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            normalize={(values: any) => {
                if (values.vehicleType) {
                    values.vehicleType = getEnumFormat(values.vehicleType);
                }
                if (values.employee) values.employee = getSearchFormat(values.employee);
                if (
                    Object.values([enTravelVehicleType.PRIVATE_CAR, enTravelVehicleType.OFFICIAL_CAR]).includes(
                        values.vehicleType
                    )
                ) {
                    values.sort = null;
                    if (values.travelFuelType) {
                        values.travelFuelType = getSearchFormat(values.travelFuelType);
                    }
                }

                return values;
            }}
            hideSubmitButton={!canCreate() || activeKey != '1'}
            hideActivationButtons={!canCreate() || activeKey != '1'}
        >
            <Tabs type="card" defaultActiveKey={activeKey} onChange={(key) => setAcitveKey(key)}>
                <TabPane tab={geti18nText('travelVehicle.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('travelVehicle.edit.vehicleType')}
                                name="vehicleType"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    ref={focusInput}
                                    options={getEnumArray('TRAVEL_VEHICLE_TYPE')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    className=""
                                    onChange={(val: any) => {
                                        if (val && val.id) {
                                            setIsPrivate(val.id && val.id === enTravelVehicleType.PRIVATE_CAR);
                                            setShowTravelFuelType(
                                                Object.values([
                                                    enTravelVehicleType.PRIVATE_CAR,
                                                    enTravelVehicleType.OFFICIAL_CAR,
                                                ]).includes(val.id)
                                            );
                                            setIsOfficialCar(
                                                Object.values([enTravelVehicleType.OFFICIAL_CAR]).includes(val.id)
                                            );
                                            if (
                                                Object.values([
                                                    enTravelVehicleType.PLANE,
                                                    enTravelVehicleType.BOAT,
                                                    enTravelVehicleType.TRAIN,
                                                    enTravelVehicleType.BUS,
                                                    enTravelVehicleType.PROVIDED_BY_ORGANIZER,
                                                    enTravelVehicleType.OTHER_PUBLIC,
                                                    enTravelVehicleType.OTHER,
                                                ]).includes(val.id)
                                            ) {
                                                setHideFields(true);
                                                form.setFieldsValue({ model: undefined });
                                                form.setFieldsValue({ licencePlate: undefined });
                                                form.setFieldsValue({ employee: undefined });
                                                form.setFieldsValue({ travelFuelType: undefined });
                                            } else {
                                                setHideFields(false);
                                            }
                                            if (
                                                Object.values([
                                                    enTravelVehicleType.PRIVATE_CAR,
                                                    enTravelVehicleType.OFFICIAL_CAR,
                                                ]).includes(val.id)
                                            ) {
                                                form.setFieldsValue({ sort: 0 });
                                            }
                                        }
                                        generateVehicleName();
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {!hideFields && (
                            <Col span={12}>
                                <Form.Item label={geti18nText('travelVehicle.edit.model')} name="model">
                                    <Input onChange={() => generateVehicleName()} />
                                </Form.Item>
                            </Col>
                        )}
                        {!hideFields && (
                            <Col span={12}>
                                <Form.Item
                                    label={licencePlateText()}
                                    name="licencePlate"
                                    rules={[
                                        {
                                            //validator: validateLicencePlate,
                                        },
                                    ]}
                                >
                                    <Input
                                        onBlur={(e) => capitalizeLicencePlate(e)}
                                        maxLength={8}
                                        defaultValue={''}
                                        onChange={() => generateVehicleName()}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelVehicle.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        {!showTravelFuelType && (
                            <Col span={12}>
                                {
                                    <Form.Item label={geti18nText('travelVehicle.edit.sort')} name="sort">
                                        <NyInputNumber style={{ width: '100%' }} min={1} nyTestId="sort" />
                                    </Form.Item>
                                }
                            </Col>
                        )}

                        {!(hideFields || isOfficialCar) && (
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('travelVehicle.edit.employee')}
                                    name="employee"
                                    rules={[
                                        {
                                            required: isPrivate,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                            businessUnit: 'businessUnit',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={
                                            <EmployeeIndex disabled={true} parentKey="travel-warrant-vehicle-edit" />
                                        }
                                        nyTestId="employee"
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    {showTravelFuelType && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <TravelFuelTypeSearch required={enableCarbonFootprint == 1} />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('travelVehicle.edit.averageFuelConsumption')}
                                    name="averageFuelConsumption"
                                    rules={[
                                        {
                                            required: enableCarbonFootprint == 1,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        style={{ width: '100%' }}
                                        min={0}
                                        max={99.99}
                                        isDecimal={true}
                                        decimalPlaces={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {!hideFields && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('travelVehicle.edit.weight')} name="weight">
                                    <NyInputNumber
                                        style={{ width: '100%' }}
                                        min={0}
                                        max={9999.99}
                                        isDecimal={true}
                                        decimalPlaces={2}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label={geti18nText('travelVehicle.edit.power')} name="power">
                                    <NyInputNumber
                                        style={{ width: '100%' }}
                                        min={0}
                                        max={999.99}
                                        isDecimal={true}
                                        decimalPlaces={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {!hideFields && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('travelVehicle.edit.co2.emission')}
                                    tooltip={
                                        <div style={{ marginLeft: '5px' }}>
                                            {geti18nText('travelVehicle.edit.co2.emission.label')}
                                        </div>
                                    }
                                    name="co2Emission"
                                >
                                    <NyInputNumber
                                        style={{ width: '100%' }}
                                        min={0}
                                        max={999.99}
                                        isDecimal={true}
                                        decimalPlaces={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </TabPane>
                <TabPane tab={geti18nText('travelVehicle.tab.employees')} key="2" disabled={isCreate || !isOfficialCar}>
                    <NyDataTable
                        nyId="travel-warrant-travel-vehicle-employee"
                        rowKey="id"
                        url={CONSTANTS_REQ.TRAVEL_VEHICLE.EMPLOYEE_LIST.replace('{id}', dataForm)}
                        showRowSelection
                        readonly
                        hideButtons
                        setDefaultFilterValue={table.setDefaultFilterValue}
                        setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                        scroll={props?.editProps?.scroll}
                        rowSelectionType={'checkbox'}
                        rowSelectionModal={setSelectedValuesFuncDelete as any}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        fetchWhenChange={refreshTable}
                        columns={[
                            {
                                title: geti18nText('travelVehicle.edit.employee.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('travelVehicle.edit.employee'),
                                dataIndex:
                                    "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                                render: (person: any, record: any) => {
                                    if (record && record.person) {
                                        return (
                                            <>
                                                {`${record.person.firstName} ${record.person.lastName} `}
                                                {record.employee &&
                                                    record.employee.employmentRecordId &&
                                                    ` (${record.employee.employmentRecordId})`}
                                            </>
                                        );
                                    }
                                },
                            },
                        ]}
                    />
                    {isAddListModalVisible && (
                        <Modal
                            title={geti18nText('travelVehicle.edit.employee.add')}
                            visible={isAddListModalVisible}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAdd }}
                            onOk={(e) => save(e)}
                            confirmLoading={loadingModal}
                            width={600}
                            onCancel={() => {
                                setIsAddListModalVisible(false);
                            }}
                        >
                            <NyDataTable
                                nyId="travel-warrant-travel-vehicle-not-employee"
                                rowKey="id"
                                url={CONSTANTS_REQ.TRAVEL_VEHICLE.NOT_EMPLOYEE_LIST.replace('{id}', dataForm)}
                                showRowSelection
                                hideButtons
                                setDefaultFilterValue={table.setDefaultFilterValue}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                readonly
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                scroll={props?.editProps?.scroll}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncAdd as any}
                                fetchWhenChange={refreshTable}
                                columns={[
                                    {
                                        title: geti18nText('travelVehicle.edit.employee.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },

                                    {
                                        title: geti18nText('travelVehicle.edit.employee'),
                                        dataIndex:
                                            "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employment_record_id as varchar),'')) ",
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                        render: (person: any, record: any) => {
                                            if (record.person) {
                                                if (record.person && record.employmentRecordId) {
                                                    return (
                                                        record.person.firstName +
                                                        ' ' +
                                                        record.person.lastName +
                                                        ' (' +
                                                        record.employmentRecordId +
                                                        ')'
                                                    );
                                                } else {
                                                    return record.person.firstName + ' ' + record.person.lastName;
                                                }
                                            }
                                        },
                                    },
                                ]}
                            />
                        </Modal>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default TravelVehicleEdit;
