import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import { Alert, Button, List, Modal, Table, Tooltip } from 'antd';
// import { addTab } from '../../slices/tabsSlice';
import { getTravelWarrantStatusIcon } from '../../utils/Utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TravelWarrantList = ({ url, id }: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [count, setCount] = useState<any>(0);
    const [data, setData] = useState<any>(null);
    const [modalVisible, setModalVisible] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [pagination, setPagination] = useState<any>({
        defaultCurrent: 1,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });

    useEffect(() => {
        if (id) {
            getTravelWarrantList();
        }
    }, [id]);

    const getTravelWarrantList = () => {
        NyRequestResolver.requestGet(url.replace('{id}', id), { max: 9999 })
            .then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setCount(result?.data?.content?.length);
                        setData(result?.data?.content);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const openModal = () => {
        setModalVisible(true);
        setLoading(true);
        getTravelWarrantList();
    };

    const columns = [
        {
            render: (text: any, record: any) => (
                <span>
                    {geti18nText('travelWarrantPersonal.edit.title.short')}
                    {': '}
                    <Tooltip title={geti18nText('menu.travel.warrant.details')}>
                        <Button
                            type="link"
                            style={{
                                textDecoration: 'underline',
                                marginLeft: '-10px',
                                marginRight: '-10px',
                            }}
                            onClick={() =>
                                // dispatch(
                                //     addTab({
                                //         title: 'travelWarrantPersonal.edit.title.short' + '-',
                                //         addedData: record.id,
                                //         path: '/travelWarrant/' + record.id,
                                //     })
                                // )
                                history.push('/travel-warrant/travelWarrant/' + record.id)
                            }
                        >
                            {record.ordinal}
                        </Button>
                    </Tooltip>{' '}
                    {getTravelWarrantStatusIcon(record.status)}
                </span>
            ),
        },
    ];

    return (
        <>
            <Alert
                message={geti18nText('travelWarrantList.disabled.info')}
                type="info"
                showIcon
                style={{ margin: '5px' }}
                description={
                    <>
                        <p style={{ fontSize: '14px', marginBottom: '4px' }}>
                            {geti18nText('travelWarrantList.count.info')} {count}{' '}
                            <a style={{ textDecorationLine: 'underline' }} onClick={openModal}>
                                {geti18nText('travelWarrantList.count.button')}
                            </a>
                        </p>

                        <Modal
                            title={geti18nText('travelWarrant.table.header')}
                            open={modalVisible}
                            onOk={() => setModalVisible(false)}
                            onCancel={() => setModalVisible(false)}
                            okText={geti18nText('app.default.button.ok')}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            bodyStyle={{ maxHeight: '75vh', overflow: 'auto' }}
                        >
                            {loading ? (
                                <NySpinner />
                            ) : (
                                <Table
                                    size="small"
                                    dataSource={data}
                                    columns={columns}
                                    rowKey="id"
                                    showHeader={false}
                                    pagination={pagination}
                                />
                            )}
                        </Modal>
                        {<p style={{ fontSize: '14px' }}>{geti18nText('travelWarrantList.disabled.info.action')}</p>}
                    </>
                }
            />
        </>
    );
};

export default TravelWarrantList;
