import { NySession } from '@nybble/nyreact';
export const PayrollRights = (function () {
    return {
        //codebooks
        canCreatePayrollCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_PAYROLL_CODEBOOKS_EDIT']);
        },
        canCsvExportPayrollCodebook: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_PAYROLL_CODEBOOKS_EXPORT']);
        },
        //calculation
        canCreateCalculation: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_PAYROLL_CALCULATION_EDIT']);
        },
        //joppd
        canCreateJoppd: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_PAYROLL_JOPPD_EDIT']);
        },
        //order
        canCreatePaymentOrder: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_PAYMENT_ORDERS_EDIT']);
        },
        ///////////////////
        //menu
        canViewPayrollMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_PAYROLL_CODEBOOKS_MENU',
                'ROLE_PAYROLL_CALCULATION_MENU',
                'ROLE_PAYROLL_JOPPD_MENU',
            ];
        },
        canViewPayrollCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_CODEBOOKS/PAYROLL_MENU'];
        },
        canViewJoppdMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_PAYROLL_JOPPD_MENU'];
        },
        canViewCalculationMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_PAYROLL_CALCULATION_MENU'];
        },
        //routes
        canViewPayrollRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_CODEBOOKS/PAYROLL_VIEW'];
        },
        canViewPayrollJoppdRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_PAYROLL_JOPPD_VIEW'];
        },
        canViewCalculationRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_PAYROLL_CALCULATION_VIEW'];
        },
    };
})();
