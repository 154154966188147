import { EllipsisOutlined } from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Button, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import RowAction from '../../../../../components/row-action';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import AssetReportModal from '../../../../webshop/views/webshop-asset/asset-release/AssetReportModal';
import AssetReleaseEdit from '../../../../webshop/views/webshop-asset/asset-release/edit';

const AssetRentalReleaseIndex = ({
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
    refreshFilters,
    setDefaultFilterValue,
}: any) => {
    const table = useTableSettings();
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const enableAssetRental = useApplicationSetting('ENABLE_ASSET_RENTAL');
    const [refresh, setRefresh] = useState<any>(0);

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetRent();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCreateAssetRent();
    };

    const setDefaultSortOrder = { order: 'dateRelease', orderType: 'desc' };

    const columns = [
        {
            title: geti18nText('assetRelease.table.column.id'),
            width: '5%',
            dataIndex: 'id',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('assetRelease.table.column.ord'),
            dataIndex: 'ord',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.ord && record.dateRelease) {
                    return record.ord + '/' + moment(record.dateRelease).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.company'),
            dataIndex: ['company', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COMPANY.SEARCH, 'company.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
            render: (company: any, record: any) => {
                if (record.company) {
                    if (record.company && record.company.name) {
                        return record.company.name;
                    }
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.dateRelease.rental'),
            dataIndex: 'dateRelease',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateRelease) {
                    return moment(record.dateRelease).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.dateTo.rental'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.createdBy'),
            dataIndex: [`concat(userPerson.first_name,' ', userPerson.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (person: any, record: any) => {
                if (record.userPerson) {
                    return record.userPerson.firstName + ' ' + record.userPerson.lastName;
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'Release'} record={record}></RowAction>;
                }
            },
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <AssetReportModal
                            reportType={'ASSET_RELEASE_SPECIFICATION'}
                            reportTitle={'assetRelease.report.assetReleaseSpecification'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_RELEASE_SPECIFICATION
                            )}
                            url={CONSTANTS_REQ.REPORT.ASSET_RELEASE_SPECIFICATION}
                            searchFieldType={enAssetReleaseSpecType.COMPANY}
                        />
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <AssetReportModal
                            reportType={'ASSET_RELEASE_RECAPITULATION'}
                            reportTitle={'assetRelease.report.assetReleaseRecapitulation'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_RELEASE_RECAPITULATION
                            )}
                            url={CONSTANTS_REQ.REPORT.ASSET_RELEASE_RECAPITULATION}
                            searchFieldType={enAssetReleaseSpecType.COMPANY}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <NyDataTable
            nyId="rent-asset-table"
            url={CONSTANTS_REQ.ASSET_RELEASE.LIST}
            addNewButtonText={geti18nText('assetRelease.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={AssetReleaseEdit}
            columns={columns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
            setDefaultFilterValue={setDefaultFilterValue}
            onDataLoaded={table.onDataLoaded}
            setDefaultPageSize={table.setDefaultPageSize()}
            editProps={{ refresh: refresh, setRefresh: setRefresh }}
            fetchWhenChange={refresh || refreshFilters}
            shortcuts={true}
            addedData={{ type: enAssetReleaseSpecType.COMPANY }}
            hideNewButton={!canCreate() || enableAssetRental !== '1'}
            addedButtons={addedButtons}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.asset.rental.release')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            userPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            dateRelease: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetRelease.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('assetRelease.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('assetRelease.table.column.company'),
            dataIndex: ['company', 'name'],
        },
        {
            title: geti18nText('assetRelease.table.column.dateRelease.rental'),
            dataIndex: 'dateRelease',
        },
        {
            title: geti18nText('assetRelease.table.column.dateTo.rental'),
            dataIndex: 'dateTo',
        },
        {
            title: geti18nText('assetRelease.table.column.createdBy'),
            dataIndex: 'userPerson',
        },
    ];
};

export default AssetRentalReleaseIndex;
