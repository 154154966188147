import { geti18nText, NyDataTable, NyModalConfirm, NyRequestResolver, NySearchField } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { Button, Checkbox, Divider, Tooltip } from 'antd';
import { CheckOutlined, CloseCircleTwoTone, CloseOutlined, EditTwoTone, SaveTwoTone } from '@ant-design/icons';

const Table = ({ type, setEditingKey, editingKey, scroll = DEFAULT_TABLE_SCROLL }: any) => {
    const isEditing = (record: any) => record.id === editingKey;

    const ActionEditable = ({ text, record }: any) => {
        return (
            <>
                <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                    <Button
                        type="link"
                        onClick={() => {
                            save(record);
                        }}
                    >
                        <SaveTwoTone
                            style={{
                                fontSize: '20px',
                            }}
                            twoToneColor="#52c41a"
                        />
                    </Button>
                </Tooltip>
                <NyModalConfirm
                    title={geti18nText('app.default.cancel.confirm')}
                    onConfirm={() => {
                        cancel();
                    }}
                >
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button type="link">
                            <CloseCircleTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#faad14"
                            />
                        </Button>
                    </Tooltip>
                </NyModalConfirm>
            </>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                <Button
                    type="link"
                    disabled={editingKey !== ''}
                    onClick={() => {
                        edit(record);
                    }}
                >
                    <EditTwoTone
                        style={{
                            fontSize: '20px',
                        }}
                    />
                </Button>
            </Tooltip>
        );
    };

    const Check = (editing: any, checked: any, onChange: any) => {
        return editing ? (
            <Checkbox defaultChecked={checked} onChange={onChange} />
        ) : checked ? (
            <CheckOutlined style={{ color: 'green' }} />
        ) : (
            <CloseOutlined style={{ color: 'red' }} />
        );
    };

    const columns: any = [
        {
            title: geti18nText('task.settings.header.status'),
            width: '362px',
            dataIndex: 'status',
            render: (status: any) => {
                return geti18nText('app.enum.TASK_STATUS.' + status);
            },
        },
        {
            title: geti18nText('task.settings.header.autor'),
            dataIndex: 'notifyCreator',
            render: (notifyCreator: any, record: any) => {
                return Check(isEditing(record), notifyCreator, () => (record.notifyCreator = !notifyCreator));
            },
        },
        {
            title: geti18nText('task.settings.header.user'),
            dataIndex: 'notifyUser',
            render: (notifyUser: any, record: any) => {
                return Check(isEditing(record), notifyUser, () => (record.notifyUser = !notifyUser));
            },
        },
        {
            title: geti18nText('task.settings.header.assigned.admin'),
            dataIndex: 'notifyAssignedAdmin',
            render: (notifyAssignedAdmin: any, record: any) => {
                return Check(
                    isEditing(record),
                    notifyAssignedAdmin,
                    () => (record.notifyAssignedAdmin = !notifyAssignedAdmin)
                );
            },
        },
        {
            title: geti18nText('task.settings.header.assigned.team'),
            dataIndex: 'notifyAssignedTeam',
            render: (notifyAssignedTeam: any, record: any) => {
                return Check(
                    isEditing(record),
                    notifyAssignedTeam,
                    () => (record.notifyAssignedTeam = !notifyAssignedTeam)
                );
            },
        },
        {
            title: geti18nText('task.settings.header.team'),
            dataIndex: 'notifyTeam',
            render: (notifyTeam: any, record: any) => {
                return isEditing(record) ? (
                    <NySearchField
                        url={CONSTANTS_REQ.TEAM.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        defaultValue={notifyTeam?.name}
                        onChange={(team: any) => {
                            record.notifyTeam = team;
                        }}
                        style={{ width: '100%' }}
                    />
                ) : (
                    notifyTeam?.name
                );
            },
        },
        {
            title: geti18nText('task.settings.header.actions'),
            render: (text: any, record: any) => {
                const editing = isEditing(record);
                return editing ? (
                    <ActionEditable text={text} record={record} />
                ) : (
                    <ActionNotEditable text={text} record={record} />
                );
            },
        },
    ];

    const edit = (record: any) => {
        setEditingKey(record.id);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const save = async (record: any) => {
        await NyRequestResolver.requestPut(
            CONSTANTS_REQ.TASK_NOTIFICATION_SETTINGS.EDIT + '/' + record.id,
            undefined,
            record
        );
        setEditingKey('');
    };

    return (
        <>
            <Divider>{geti18nText('task.settings.title.' + type)}</Divider>
            <NyDataTable
                nyId="tasks-task-notification-settings"
                showSizeChanger={false}
                hidePagination={true}
                setDefaultPageSize={100}
                url={CONSTANTS_REQ.TASK_NOTIFICATION_SETTINGS.LIST}
                columns={columns}
                hideNewButton={true}
                onRowSelect={() => {}}
                showCleanTableFilters={false}
                scroll={scroll}
                setDefaultFilterValue={() => [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'type', condition: 'equals', value: type },
                ]}
            ></NyDataTable>
        </>
    );
};

export default Table;
