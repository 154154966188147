import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchByCodebookWorkplace,
} from '../../../../utils/Filters';
import { getDateFormat } from '../../../../utils/Utils';
import NyDescription from '../../../../components/ny-description';
import { useState } from 'react';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const MilitaryWithoutScheduledIndex = ({
    scroll = { y: 540, x: 4000 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const location = useLocation();
    const table = useTableSettings();

    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const militaryRecordAssigned = useEnum('EMPLOYEE_MILITARY_RECORDS_ASSIGNED');
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'assign', condition: 'equals', value: militaryRecordAssigned.NO },
        ];
    };
    const columns = [
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit.id'),
            dataIndex: ['businessUnit', 'code'],
            render: (text: any, record: any) => {
                return record?.businessUnit?.code;
            },
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit'),
            dataIndex: ['businessUnit', 'name'],
            render: (text: any, record: any) => {
                return record?.businessUnit?.name;
            },
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.name.lastname'),
            dataIndex: "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name ) ",
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.employee)}>
                            {(record.employee.person && record.employee.person.lastName
                                ? record.employee.person.lastName + ', '
                                : '') +
                                (record.employee.person && record.employee.person.parentName
                                    ? record.employee.person.parentName + ', '
                                    : '') +
                                (record.employee.person && record.employee.person.firstName
                                    ? record.employee.person.firstName
                                    : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.work.place'),
            width: '10%',
            dataIndex: ['vocation', 'name'],
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record?.vocation?.name) {
                    return (
                        <div>
                            {record?.vocation?.name}
                            {record?.vocationDescription?.name ? ' - ' + record.vocationDescription.name : ' '}
                        </div>
                    );
                }
            },
            ...getColumnSearchByCodebookWorkplace(),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (created: any, record: any) => {
                if (record?.employee?.person?.oib) {
                    return record?.employee?.person?.oib;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.birthdate'),
            dataIndex: ['person', 'birthDate'],
            width: '5%',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.birthDate) {
                    return getDateFormat(record.employee.person.birthDate, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.qualifications'),
            dataIndex: ['employeeProfessionalQualification', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                'employeeVocationProfessionalQualification.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                    code: 'code',
                }
            ),
           render: (created: any, record: any) => {
                if (record.employee && record?.employee?.vocation?.professionalQualification) {
                    return record.employee.vocation.professionalQualification;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.professionalSpecialty'),
            dataIndex: ['professionalSpecialty', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return record?.professionalSpecialty?.name;
            },
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.PROFESSIONAL_SPECIALTY.SEARCH,
                'professionalSpecialty.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            )
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.department.defense'),
            dataIndex: ['defenseDepartment', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseDepartment.id',
                setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'),
            dataIndex: ['defenseSection', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseSection.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.address.full'),
            width: '10%',
            dataIndex: [`concat(address.street,' ', settlement.name)`],
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.address) {
                    return (
                        <div>
                            {(record.address.street ? record.address.street + ', ' : '') +
                                (record.address.settlement ? record.address.settlement.name : '')}
                        </div>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobilization.location'),
            dataIndex: ['mobilizationLocation', 'name'],
            width: '11%',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                return record?.mobilizationLocation?.name;
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobile.full'),
            width: '8%',
            dataIndex: ['contact', 'mobile'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.email.full'),
            width: '7%',
            dataIndex: ['contact', 'email'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('military.does.not.obligee.no.assign'),
            dataIndex: 'noAssign',
            width: '6%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { noAssign: any }) => {
                if (record.noAssign) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(
                [
                    { id: 0, text: geti18nText('app.enum.boolean.no') },
                    { id: 1, text: geti18nText('app.enum.boolean.yes') },
                ],
                'equals_bool'
            ),
        },
        {
            title: geti18nText('military.does.not.obligee.assign.reason'),
            dataIndex: 'noAssignReason',
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.remark'),
            dataIndex: 'remark',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (remark: any, record: any) => {
                if (record?.remark) {
                    return <NyDescription title={record?.remark} maxWidth={'150px'} />;
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    return (
        <>
            <NyDataTable
                nyId="human-military-not-scheduled"
                url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.LIST}
                buttonsClassName="buttons-sticky"
                columns={columns}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? { order: 'business_unit.code', orderType: 'asc' }}
                hideNewButton={true}
                headerTitle={geti18nText('menu.work.obligation.military.without.scheduled')}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default MilitaryWithoutScheduledIndex;
