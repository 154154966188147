import { NySession, NySpinner } from '@nybble/nyreact';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, useParams } from 'react-router-dom';
import { RootState } from '../../../rootReducer';
import { setModalOpen } from '../../../slices/modalOpenSlice';

const ReRouteEmployeeEvaluation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (NySession.isUserAuthenticated()) {
            history.push('/human/employee-evaluation');
            dispatch(
                setModalOpen({
                    modal: 'employeeEvaluationEdit',
                    visible: true,
                    item: id,
                })
            );
            localStorage.removeItem('loginRedirectCustomUrl');
        }
    }, []);

    if (!NySession.isUserAuthenticated()) {
        localStorage.setItem('loginRedirectCustomUrl', '/human/employee-evaluation/' + id.toString());
        return <Redirect to="/login" />;
    } else {
        return <></>;
    }
};

export default ReRouteEmployeeEvaluation;
