import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';

interface IMenuSlice {
    collapsed?: boolean;
    myMenu?: any;
}

const initialState: IMenuSlice = {
    collapsed: true,
    myMenu: [],
};

const menuSlice = createSlice({
    name: 'menuSlice',
    initialState,
    reducers: {
        setMenuCollapsed(state, action) {
            state.collapsed = action.payload;
        },
        setMyMenu(state, action) {
            state.myMenu = action.payload;
        },
        addToMyMenu(state, action) {
            if (state.myMenu.filter((val: any) => val.key == action.payload.key).length == 0) {
                delete action.payload.icon;
                state.myMenu.push(action.payload);
            }
            saveMyMenu(state.myMenu);
        },
        removeFromMyMenu(state, action) {
            state.myMenu.splice(action.payload, 1);
            saveMyMenu(state.myMenu);
        },
    },
});

const saveMyMenu = (MyMenu: any) => {
    let parms: any = {};
    parms.settingKey = 'myMenu';
    parms.settingValue = JSON.stringify(MyMenu);
    NyRequestResolver.requestPost(CONSTANTS_REQ.USER_SESTTINGS.EDIT, undefined, parms);
};

export const fetchMyMenu = (): AppThunk => async (dispatch) => {
    const settingKey = 'myMenu';
    const result: any = await NyRequestResolver.requestGet(
        CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey,
        {
            settingKey: settingKey,
        }
    );
    if (result.status == RESPONSE.OK) {
        let data = result.data;
        if (data && data.settingValue) {
            let settingsValue = JSON.parse(data.settingValue);
            dispatch(setMyMenu(settingsValue));
        }
    }
};

export const { setMenuCollapsed, addToMyMenu, setMyMenu, removeFromMyMenu } = menuSlice.actions;

export default menuSlice.reducer;
