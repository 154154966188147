import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import TownshipIndex from '../township';
import TownshipEdit from '../township/edit';

const TownshipDistrictEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('township.district.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    useEffect(() => {
        if (props?.addedData?.settlement?.township) {
            form.setFieldsValue({ township: setSearchFormat(props.addedData.settlement.township) });
        }
    }, [props?.addedData?.settlement?.township]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('township.district.district') + ' - ' + dataForm.name);
        }

        if (dataForm.township) dataForm.township = setSearchFormat(dataForm.township);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('township.district.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const defaultFilterValue = { field: 'allowDistrict', condition: 'equals_bool', value: 1 };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TOWNSHIP_DISTRICT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
            normalize={(values: any) => {
                if (values.township) values.township = getSearchFormat(values.township);

                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('township.district.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('township.district.edit.township')}
                        name="township"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.TOWNSHIP.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={
                                <TownshipIndex filter={true} setDefaultFilterValue={defaultFilterValue} />
                            }
                            AddNewModalComponent={TownshipEdit}
                            nyTestId="township"
                            addedFilter={defaultFilterValue}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TownshipDistrictEdit;
