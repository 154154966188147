import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IEmployeeState {
    travelWarrantData?: any;
}

const initialState: IEmployeeState = {
    travelWarrantData: {},
};

const travelWarrant = createSlice({
    name: 'travelWarrant',
    initialState,
    reducers: {
        travelWarrantInit(state) {
            state.travelWarrantData = {};
        },
        setTravelWarrantData(state, action: PayloadAction<IEmployeeState>) {
            const { travelWarrantData } = action.payload;
            state.travelWarrantData = travelWarrantData;
        },
    },
});

export const { travelWarrantInit, setTravelWarrantData } = travelWarrant.actions;

export default travelWarrant.reducer;
