import { MedicineBoxOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyFileUpload,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Alert, Button, Col, Divider, Form, Input, message, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import 'moment/locale/hr';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

import {
    customEmployeePaidLeaveTypeRenderOption,
    customEmployeeRenderOption,
    errorNotification,
    getEmployeePaidLeaveTypeSelectOption,
    getEmployeeSelectOption,
    getEnumArray,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    getWorkingDays,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    userIsNotificationRecipient,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import EmployeePaidLeaveTypeIndex from '../employee-paid-leave-type';
import EmployeePaidLeaveTypeEdit from '../employee-paid-leave-type/edit';
import EmployeeSickLeaveEdit from '../employee-sick-leave/edit';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EmployeeLeaveReqEdit = (props: any) => {
    const uploadFilesRef = useRef<any>(null);
    const [editHeader, setEditHeader] = useState(props && props.editProps && props.editProps.editHeader);
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const employeeLeaveType = useEnum('EMPLOYEE_LEAVE_TYPE');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const notificationType = useEnum('NOTIFICATION_TYPE');

    const [fromDate, setFromDate] = useState<any>(undefined);
    const [toDate, setToDate] = useState<any>(undefined);
    const [holidays, setHolidays] = useState([]);
    const [type, setType] = useState<any>(undefined);
    const { employee } = useSelector((state: RootState) => state.employee);
    const { openModalData } = useSelector((state: RootState) => state.modalOpen);
    const [leaveStatus, setLeaveStatus] = useState<any>(null);
    const [sickLeaveEditVisible, setSickLeaveEditVisible] = useState<boolean>(false);
    const [employeeLeaveObject, setEmployeeLeaveObject] = useState<any>(undefined);
    const [employeeSickLeave, setEmployeeSickLeave] = useState<any>(null);
    const [sendEmployee, setSendEmployee] = useState<any>(employee);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const resolveIsAdmin = () => {
        return (props && props.editProps && props.editProps.canCreateAdmin) || openModalData?.canCreateAdmin;
    };

    const resolveIsHuman = () => {
        return (props && props.editProps && props.editProps.isHumanResources) || openModalData?.isHumanResources;
    };

    const resolveDisabled = () => {
        if (dataForm) {
            return true;
        } else {
            return false;
        }
    };

    const resolveUser = () => {
        if (resolveIsAdmin() || resolveIsHuman()) {
            return false;
        } else {
            return true;
        }
    };

    const resolveView = () => {
        return (props && props.editProps && !props.editProps.isVacation) || type == employeeLeaveType.SICK_LEAVE;
    };

    const resolveIsNotNew = () => {
        return (props && props.editProps && props.editProps.value) || dataForm;
    };

    const refreshView = () => {
        setTriggerCloseModal(true);
        if (props && props.editProps) {
            if (props.editProps.setRefresh) {
                props.editProps.setRefresh((refresh: any) => refresh + 1);
            }
        }
    };

    const showCreateSickLeave = () => {
        return (
            props &&
            props.editProps &&
            props.editProps.canCreateAdmin &&
            resolveView() &&
            leaveStatus == employeeLeaveStatus.CONFIRMED &&
            !employeeSickLeave
        );
    };

    useEffect(() => {
        fetchHolidays();
    }, []);

    useEffect(() => {
        setEmployeeValues(employee);
    }, [employee, props && props.editProps && props.editProps.visible]);

    useEffect(() => {
        fetchHolidays();
        calculateWorkingDays();
    }, [fromDate, toDate]);

    const setEmployeeValues = (employeeData: any) => {
        if (employeeData) {
            let formUser: any = {};
            formUser.employee = {};
            formUser.employee = getEmployeeSelectOption(employeeData);
            if (employeeData && employeeData.vocation && employeeData.vocation.name) {
                formUser.employeeVocation = employeeData.vocation.name;
            }
            form.setFieldsValue(formUser);
            employeeOnChange(employeeData);
        }
    };

    const fetchHolidays = () => {
        let active = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        NyRequestResolver.requestGet(CONSTANTS_REQ.HOLIDAY.LIST_ALL, {
            search: active,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setHolidays(
                        result.data.map((day: any) => {
                            return {
                                date: moment(day.date).format('DD.MM.YYYY.'),
                                name: day.name,
                                active: day.active,
                            };
                        })
                    );
                }
            }
        });
    };

    function setValues(dataForm: any) {
        setEmployeeLeaveObject(dataForm);
        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('employeeLeave.edit.title') + ' - ' + dataForm.id);
        }
        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
            setFromDate(setDateFormat(dataForm.dateFrom, 'yyyy-MM-DD'));
        }
        if (dataForm.dateTo) dataForm.dateTo = setDateFormat(dataForm.dateTo);

        if (dataForm.employee) {
            setSendEmployee(dataForm.employee);
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        if (dataForm.type) {
            setType(dataForm.type);
            dataForm.type = setEnumFormat('EMPLOYEE_LEAVE_TYPE', dataForm.type);
            if (editHeader && dataForm.type && dataForm.type.text) {
                setEditHeader((editHeader: any) => editHeader + ' - ' + dataForm.type.text);
            }
        }
        if (dataForm.status) {
            setLeaveStatus(dataForm.status);
            dataForm.status = setEnumFormat('EMPLOYEE_LEAVE_STATUS', dataForm.status);
        }
        if (dataForm.employeePaidLeaveType) {
            dataForm.employeePaidLeaveType = getEmployeePaidLeaveTypeSelectOption(dataForm.employeePaidLeaveType);
        }
        if (dataForm.employeeSickLeave) {
            setEmployeeSickLeave(dataForm.employeeSickLeave);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setEditHeader(props && props.editProps && props.editProps.editHeader);
        if (!resolveView()) {
            if (!type) {
                setType(employeeLeaveType.ANNUAL_LEAVE);
                form.setFieldsValue({ type: setEnumFormat('EMPLOYEE_LEAVE_TYPE', employeeLeaveType.ANNUAL_LEAVE) });
            }
        } else {
            if (!type) {
                setType(employeeLeaveType.SICK_LEAVE);
                form.setFieldsValue({ type: setEnumFormat('EMPLOYEE_LEAVE_TYPE', employeeLeaveType.SICK_LEAVE) });
            }
        }
    };

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setSendEmployee(null);
        setType(undefined);
        setFromDate(undefined);
        setToDate(undefined);
        setLeaveStatus(null);
        setEmployeeLeaveObject(undefined);
        setEmployeeSickLeave(null);
        setHolidays([]);
        setTriggerCloseModal(false);
    };

    const disabledDateFrom = (current: any) => {
        if (toDate) {
            return current > moment(toDate);
        }
    };

    const disabledDateTo = (current: any) => {
        if (fromDate) {
            return current.isBefore(fromDate.clone());
        } else {
            return current;
        }
    };

    const calculateWorkingDays = () => {
        if (fromDate && toDate) {
            let days = getWorkingDays(toDate.clone(), fromDate.clone());
            let numberOfHolidays = 0;
            for (let tmpDate = fromDate.clone(); tmpDate.isSameOrBefore(toDate.clone()); tmpDate.add(1, 'day')) {
                let day = tmpDate.day() === 0 ? 7 : tmpDate.day();
                if (day < 6) {
                    let holiday = checkIsHoliday(tmpDate.clone());
                    if (holiday) {
                        numberOfHolidays++;
                    }
                }
            }
            let workingDays = days - numberOfHolidays;
            form.setFieldsValue({ workingDays: workingDays });
        }
    };

    const checkIsHoliday = (value: any) => {
        const holiday: any = holidays.find((day: any) => {
            const holidayFormatted = moment(day.date, 'DD.MM.yyyy.');
            const valueFormatted = moment(value, 'DD.MM.yyyy.');

            return holidayFormatted.isSame(valueFormatted, 'day');
        });

        if (holiday && holiday.active) {
            return holiday;
        }
        return null;
    };

    const employeeOnChange = (value: any) => {
        if (value.id > 0) {
            setSendEmployee(value);
            var parms: any = {
                employeeId: value.id,
            };
            let availableDaysCount = 0;
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.GET_AVAILABLE_DAYS, parms).then(
                async (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data.availableDays) {
                            availableDaysCount =
                                result.data && result.data.availableDays ? result.data.availableDays : 0;
                        }
                    }
                    form.setFieldsValue({ availableDays: availableDaysCount });
                }
            );
        } else {
            form.setFieldsValue({ availableDays: 0 });
        }
    };

    const onTypeChange = (value: any) => {
        if (value && value.id !== -1) {
            setType(value.id);
        }
        form.setFieldsValue({ description: null });
    };

    const onPaidLeaveTypeChange = (value: any) => {
        if (value && value.id !== -1 && value.name) {
            const children =
                value && value.name && value.name.props && value.name.props.children ? value.name.props.children : null;
            if (children) {
                const valueName =
                    children[0] && children[0].props && children[0].props.children ? children[0].props.children : '';
                const valueDays =
                    children[1] && children[1].props && children[1].props.children
                        ? ' - ' + children[1].props.children
                        : '';
                const descriptionValue = valueName + valueDays;
                form.setFieldsValue({ description: descriptionValue });
            }
        } else {
            form.setFieldsValue({ description: null });
        }
    };

    const saveEmployeeSickRelation = (employeeSickLeaveId: any) => {
        if (employeeSickLeaveId && dataForm) {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.EMPLOYEE_LEAVE.UPDATE_EMPLOYEE_SICK_LEAVE + '/' + dataForm,
                undefined,
                {
                    id: dataForm,
                    employeeSickLeaveId: employeeSickLeaveId,
                }
            ).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    refreshView();
                } else {
                    errorNotification();
                }
            });
        }
    };

    const saveDecision = () => {
        let values2: any = {};
        values2 = {
            id: dataForm,
            status: employeeLeaveStatus.CONFIRMED,
        };

        NyRequestResolver.requestPut(
            CONSTANTS_REQ.EMPLOYEE_LEAVE.CHANGE_STATUS + '/' + dataForm,
            undefined,
            values2
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
            } else {
                errorNotification(geti18nText('employee.leave.update.error'));
            }
        });
    };

    const cancelLeave = () => {
        let values2: any = {};
        values2 = {
            id: dataForm,
            status: employeeLeaveStatus.REJECTED,
        };

        NyRequestResolver.requestPut(
            CONSTANTS_REQ.EMPLOYEE_LEAVE.CHANGE_STATUS + '/' + dataForm,
            undefined,
            values2
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
            } else {
                errorNotification(geti18nText('employee.leave.update.error'));
            }
        });
    };

    const setCancelLeave = () => {
        cancelLeave();
        if (props && props.setVisible) {
            props.setVisible(false);
        }
        onModalClose();
        if (props && props.editProps && props.editProps.setRefresh) {
            props.editProps.setRefresh((prevValue: any) => prevValue + 1);
        }
    };

    const setSubmitLeave = () => {
        saveDecision();
        if (props && props.setVisible) {
            props.setVisible(false);
        }
        onModalClose();
        if (props && props.editProps && props.editProps.setRefresh) {
            props.editProps.setRefresh((prevValue: any) => prevValue + 1);
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {showCreateSickLeave() && (
                <div style={{ float: 'left' }}>
                    <Button icon={<MedicineBoxOutlined />} type="primary" onClick={() => setSickLeaveEditVisible(true)}>
                        {geti18nText('employeeLeave.create.sickLeave')}
                    </Button>
                </div>
            )}
            {leaveStatus == employeeLeaveStatus.REQUESTED &&
                (resolveIsAdmin() ||
                    resolveIsHuman() ||
                    userIsNotificationRecipient(employee, notificationType?.EMPLOYEE_PAID_LEAVE_APPROVAL) ||
                    userIsNotificationRecipient(employee, notificationType?.EMPLOYEE_ANNUAL_LEAVE_APPROVAL)) && (
                    <div style={{ float: 'right', marginLeft: '12px' }}>
                        <NyModalConfirm
                            zIndex={1012}
                            title={geti18nText('employeeLeave.create.save.confirm')}
                            onConfirm={() => {
                                setSubmitLeave();
                            }}
                        >
                            <Button type="primary" style={{ background: 'green' }}>
                                {geti18nText('employeeLeave.create.save')}
                            </Button>
                        </NyModalConfirm>
                    </div>
                )}
            {leaveStatus == employeeLeaveStatus.REQUESTED && resolveIsNotNew() && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm
                        zIndex={1012}
                        title={geti18nText(
                            employee?.id === sendEmployee?.id
                                ? 'employeeLeave.create.cancel.confirm.employee'
                                : 'employeeLeave.create.cancel.confirm'
                        )}
                        onConfirm={() => {
                            setCancelLeave();
                        }}
                    >
                        <Button type="primary" danger>
                            {geti18nText(
                                employee?.id === sendEmployee?.id
                                    ? 'employeeLeave.create.cancel.employee'
                                    : 'employeeLeave.create.cancel'
                            )}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <>
            <NyDataEdit
                value={props && props.editProps && props.editProps.value}
                visible={props && props.editProps && props.editProps.visible}
                setVisible={props && props.editProps && props.editProps.setVisible}
                isModal={true}
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                submitButtonText={
                    !dataForm
                        ? props && props.editProps && props.editProps.submitButtonText
                        : geti18nText('app.default.button.save')
                }
                loading={loading}
                setLoading={setLoading}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_LEAVE.EDIT}
                setValues={setValues}
                hideSubmitButton={
                    (props && props.editProps && !props.editProps.canCreate) ||
                    (!resolveIsHuman() && !resolveIsAdmin() && dataForm)
                }
                hideActivationButtons={true}
                width={900}
                form={form}
                buttonsClassName="buttons-sticky"
                goBack={history.goBack}
                fetchWhenChange={id}
                paramsId={id}
                {...props}
                normalize={(values: any) => {
                    if (values.dateFrom) values.dateFrom = moment(values.dateFrom).format('yyyy-MM-DD HH:mm');
                    if (values.dateTo) values.dateTo = moment(values.dateTo).format('yyyy-MM-DD HH:mm');
                    if (values.type) values.type = getEnumFormat(values.type);
                    if (values.employee) values.employee = getSearchFormat(values.employee);
                    values.status = values.status ? getEnumFormat(values.status) : leaveStatus;
                    delete values.files;
                    delete values.paidLeaveType;
                    return values;
                }}
                shortcuts={true}
                nyTestId="employee-leave-edit"
                onSaveAndGetID={(id: any) => {
                    if (uploadFilesRef?.current) {
                        uploadFilesRef.current.filesUpload(id);
                    }
                }}
                deactivateButtonText={geti18nText('employeeLeave.edit.cancel')}
                customFooterContent={getCustomFooterContent}
                triggerCloseModal={triggerCloseModal}
                zIndex={openModalData?.zIndex}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        {showCreateSickLeave() && (
                            <Alert
                                showIcon
                                type="warning"
                                message={geti18nText('employeeLeave.create.info')}
                                style={{ fontSize: '9px!important', marginBottom: '5px' }}
                            />
                        )}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={resolveView() && !resolveIsHuman() ? 24 : 12}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.EMPLOYEE_LEAVE_SEARCH}
                                map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={
                                    <EmployeeIndex
                                        disabled={true}
                                        filteredForEmployeeLeave
                                        height={400}
                                        parentKey="employee-leave-req"
                                    />
                                }
                                nyTestId="employee"
                                autoFocus={true}
                                onChange={employeeOnChange}
                                order="person.last_name, person.first_name"
                                disabled={resolveDisabled()}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.type')}
                            name="type"
                            rules={[
                                {
                                    required: !resolveView(),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={
                                !resolveView()
                                    ? setEnumFormat('EMPLOYEE_LEAVE_TYPE', employeeLeaveType.ANNUAL_LEAVE)
                                    : setEnumFormat('EMPLOYEE_LEAVE_TYPE', employeeLeaveType.SICK_LEAVE)
                            }
                            hidden={resolveView() && !resolveIsHuman()}
                        >
                            <NySearchField
                                options={
                                    resolveIsHuman()
                                        ? getEnumArray('EMPLOYEE_LEAVE_TYPE')
                                        : getEnumArray('EMPLOYEE_LEAVE_TYPE').filter(
                                              (filteredEnum: any) =>
                                                  filteredEnum.id != employeeLeaveType.SICK_LEAVE &&
                                                  filteredEnum.id != employeeLeaveType.UNPAID_LEAVE
                                          )
                                }
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                nyTestId="employee-leave-type"
                                className=""
                                disabled={resolveDisabled()}
                                onChange={onTypeChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.status')}
                            name="status"
                            initialValue={setEnumFormat('EMPLOYEE_LEAVE_STATUS', 1)}
                        >
                            <NySearchField
                                options={getEnumArrayStatus('EMPLOYEE_LEAVE_STATUS')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                nyTestId="employee-leave-status"
                                className=""
                                disabled={resolveUser()}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.paidLeaveType')}
                            name="paidLeaveType"
                            hidden={type != employeeLeaveType.PAID_LEAVE || resolveView()}
                        >
                            <NySearchField
                                map={{ id: 'id', label: 'name', days: 'days', description: 'description' }}
                                searchBy="name"
                                customItemNameLabel={'name'}
                                itemName="name"
                                renderOption={customEmployeePaidLeaveTypeRenderOption}
                                onChange={onPaidLeaveTypeChange}
                                disabled={resolveDisabled()}
                                url={CONSTANTS_REQ.EMPLOYEE_PAYED_LEAVE_TYPE.SEARCH}
                                SearchPopupComponent={<EmployeePaidLeaveTypeIndex />}
                                AddNewModalComponent={EmployeePaidLeaveTypeEdit}
                                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                                nyTestId="employee-paid-leave-type"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={resolveView() ? 12 : 6}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.from')}
                            name="dateFrom"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                mustGetPopupContainer={false}
                                style={{ width: '100%' }}
                                onChange={(date: any) => {
                                    setFromDate(date);
                                    if (!toDate) {
                                        setToDate(date);
                                        form.setFieldsValue({ dateTo: date });
                                    }
                                }}
                                nyTestId="date-from"
                                disabled={
                                    (type != employeeLeaveType.SICK_LEAVE && dataForm) || (!canCreate() && dataForm)
                                }
                                disabledDate={disabledDateFrom}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={resolveView() ? 12 : 6}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.to')}
                            name="dateTo"
                            rules={[
                                {
                                    required: !resolveView(),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                mustGetPopupContainer={false}
                                style={{ width: '100%' }}
                                disabledDate={disabledDateTo}
                                onChange={(date: any) => setToDate(date)}
                                nyTestId="date-to"
                                disabled={
                                    (type != employeeLeaveType.SICK_LEAVE && dataForm) || (!canCreate() && dataForm)
                                }
                                defaultPickerValue={fromDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.days')}
                            name="workingDays"
                            hidden={resolveView()}
                        >
                            <NyInputNumber style={{ width: '100%' }} disabled />
                        </Form.Item>
                    </Col>
                    {(leaveStatus == employeeLeaveStatus.REQUESTED || type == employeeLeaveType.ANNUAL_LEAVE) && (
                        <Col span={6}>
                            <Form.Item label={geti18nText('employeeLeave.edit.availableDays')} name="availableDays">
                                <NyInputNumber style={{ width: '100%' }} disabled />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            labelAlign={'left'}
                            label={geti18nText('employeeLeave.edit.note')}
                            rules={[
                                {
                                    required:
                                        resolveView() ||
                                        type == employeeLeaveType.PAID_LEAVE ||
                                        type == employeeLeaveType.UNPAID_LEAVE,
                                    message: geti18nText('app.default.required'),
                                    whitespace:
                                        resolveView() ||
                                        type == employeeLeaveType.PAID_LEAVE ||
                                        type == employeeLeaveType.UNPAID_LEAVE,
                                },
                            ]}
                        >
                            <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} disabled={resolveDisabled()} />
                        </Form.Item>
                    </Col>
                </Row>
                {(resolveView() || type != employeeLeaveType.ANNUAL_LEAVE) &&
                    ((sendEmployee && sendEmployee.id) || (employee && employee.id)) && (
                        <NyFilesUpload
                            id={sendEmployee && sendEmployee.id ? sendEmployee.id : employee.id}
                            refId={dataForm}
                            url={CONSTANTS_REQ.EMPLOYEE_FILE.LIST}
                            editUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                            documentType={documentTypeEnum.EMPLOYEE_LEAVE}
                            defaultFilesFilter={[
                                { field: 'active', condition: 'equals_bool', value: 1 },
                                { field: 'ref_id', condition: 'equals', value: dataForm },
                            ]}
                            canAddNewFile={!resolveDisabled()}
                            canDelete={!resolveDisabled()}
                            canEdit={!resolveDisabled()}
                            ref={uploadFilesRef}
                            employee={employee}
                            maxHeight={'205px'}
                        />
                    )}
            </NyDataEdit>
            {sickLeaveEditVisible && (
                <EmployeeSickLeaveEdit
                    isModal={true}
                    visible={sickLeaveEditVisible}
                    setVisible={setSickLeaveEditVisible}
                    addedData={{ value: employeeLeaveObject }}
                    onSaveAndGetID={saveEmployeeSickRelation}
                />
            )}
        </>
    );
};

export default EmployeeLeaveReqEdit;
