import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEmployeeSelectOption,
    getEnumArray,
    setDateFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import { BusinessUnitSearch } from '../../../human/views/business-unit/search';
import EmployeeIndex from '../../../human/views/employee';
import UserIndex from '../../../tasks/views/user';
import OrderViewEdit from '../order-view/edit';

const AccessRightEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('menu.access.rights.edit'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [hiddenFields, setHiddenFields] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const moduleAccessRightsEnum = useEnum('MODULE_ACCESS_RIGHT');

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [employeeListRequired, setEmployeeListRequired] = useState(true);
    const [businessUnitRequired, setBusinessUnitRequired] = useState(true);
    const { accessRightType } = props.editProps ? props.editProps : { accessRightType: undefined };

    useEffect(() => {
        form.validateFields(['employeeList']);
    }, [employeeListRequired]);

    const canCreate = () => {
        if (AdministrationRights.isAdmin()) {
            return true;
        } else if (accessRightType == moduleAccessRightsEnum.TRAVEL_WARRANT) {
            return TravelWarrantRights.canCreateAppSettings();
        } else if (
            accessRightType == moduleAccessRightsEnum.LEAVE ||
            accessRightType == moduleAccessRightsEnum.ATTENDANCE_REGISTRATION
        ) {
            return HumanResourcesRights.canCreateAttendanceandLeaveSettings();
        } else {
            return AdministrationRights.canCreateSettings();
        }
    };

    function setValues(dataForm: any) {
        setHiddenFields(true);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.accessRightType) {
            dataForm.accessRightType = setEnumFormat('MODULE_ACCESS_RIGHT', dataForm.accessRightType);
        }

        setEditHeader(geti18nText('menu.access.rights.edit'));

        if (dataForm.date) dataForm.date = setDateFormat(dataForm.date);

        if (dataForm.businessUnit) {
            dataForm.businessUnitList = [
                { id: dataForm.businessUnit.id, key: dataForm.businessUnit.id, label: dataForm.businessUnit.name },
            ];
            setEmployeeListRequired(false);
            setBusinessUnitRequired(true);
        }

        if (dataForm.user) {
            const listUsers = [];
            listUsers.push(dataForm.user);
            dataForm.userList = getEmployeeSelectOption(listUsers);
            delete dataForm.user;
        }

        if (dataForm.employee) {
            const list = [];
            list.push(dataForm.employee);
            dataForm.employeeList = getEmployeeSelectOption(list);
            setEmployeeListRequired(true);
            setBusinessUnitRequired(false);
        }

        if (dataForm.employeeGroup) dataForm.employeeGroup = dataForm.employeeGroup;

        if (dataForm.readOnly) dataForm.readOnly = dataForm.readOnly;

        if (!dataForm.active) {
            setIsActive(false);
        }

        if (dataForm.createdBy && dataForm.createdBy.person) {
            dataForm.createdText = dataForm.createdBy.person.firstName + ' ' + dataForm.createdBy.person.lastName;

            if (dataForm.created) {
                dataForm.createdText += ' ' + moment(dataForm.created).format('DD.MM.YYYY HH:mm');
            }
        }

        if (dataForm.updatedBy && dataForm.updatedBy.person) {
            dataForm.updatedText = dataForm.updatedBy.person.firstName + ' ' + dataForm.updatedBy.person.lastName;

            if (dataForm.updated) {
                dataForm.updatedText += ' ' + moment(dataForm.updated).format('DD.MM.YYYY HH:mm');
            }
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setHiddenFields(false);
        setEditHeader(geti18nText('menu.access.rights.table.new'));
        setBusinessUnitRequired(true);
        setEmployeeListRequired(true);
        setIsActive(true);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onChangeEmployee = (value: any) => {
        if (value && value.length >= 1) {
            setEmployeeListRequired(true);
            setBusinessUnitRequired(false);
        } else {
            setEmployeeListRequired(false);
            setBusinessUnitRequired(true);
        }
    };

    const onChangeBusinessUnit = (value: any) => {
        if (value && value.length >= 1) {
            setEmployeeListRequired(false);
            setBusinessUnitRequired(true);
        } else {
            setEmployeeListRequired(true);
            setBusinessUnitRequired(false);
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            // mode="multiple"
            setValues={setValues}
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ACCESS_RIGHTS.EDIT}
            width={800}
            form={form}
            hideActivationButtons={!canCreate() || !isActive}
            hideSubmitButton={!canCreate() || !isActive}
            goBack={() => history.goBack()}
            {...props}
            onModalOpen={onModalOpen}
            normalize={(values: any) => {
                if (values.accessRightType) {
                    values.accessRightType = values.accessRightType.id;
                }
                return values;
            }}
        >
            <Row gutter={24} justify="center">
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={22}>
                    <Form.Item
                        label={geti18nText('menu.access.rights.table.type')}
                        name="accessRightType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={
                            accessRightType != undefined
                                ? setEnumFormat('MODULE_ACCESS_RIGHT', accessRightType)
                                : getEnumArray('MODULE_ACCESS_RIGHT').at(0)
                        }
                    >
                        <NySearchField
                            options={getEnumArray('MODULE_ACCESS_RIGHT')}
                            searchBy="text"
                            disabled={hiddenFields || accessRightType != undefined}
                        />
                    </Form.Item>

                    <Form.Item
                        label={geti18nText('menu.access.rights.table.viewed.by.employee')}
                        name="userList"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            style={{ width: '100%' }}
                            placeholder={geti18nText('travelWarrant.edit.employee')}
                            AddNewModalComponent={OrderViewEdit}
                            addNewItem={false}
                            mode={'multiple'}
                            disabled={hiddenFields}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>

                    <Form.Item
                        label={geti18nText('menu.access.rights.module.employee.getting.viewed')}
                        name="employeeList"
                        rules={[
                            {
                                required: employeeListRequired,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="asset-right" />}
                            style={{ width: '100%' }}
                            placeholder={geti18nText('travelWarrant.edit.employee')}
                            AddNewModalComponent={OrderViewEdit}
                            addNewItem={true}
                            mode={'multiple'}
                            disabled={hiddenFields}
                            onChange={(data: any) => {
                                onChangeEmployee(data);
                            }}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                    <BusinessUnitSearch
                        label={geti18nText('menu.access.rights.module.unit.getting.viewed')}
                        formItemName={'businessUnitList'}
                        isWorkObligation={true}
                        onChange={onChangeBusinessUnit}
                        disabled={hiddenFields}
                        mode={'multiple'}
                    />
                    <Form.Item
                        label={geti18nText('menu.access.rights.table.readOnly')}
                        name="readOnly"
                        valuePropName={'checked'}
                    >
                        <Checkbox />
                    </Form.Item>
                    {hiddenFields && (
                        <Form.Item name="createdText" label={geti18nText('menu.access.rights.table.created.by')}>
                            <Input disabled />
                        </Form.Item>
                    )}
                    {hiddenFields && !isActive && (
                        <Form.Item name="updatedText" label={geti18nText('menu.access.rights.table.deactivated.by')}>
                            <Input disabled />
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AccessRightEdit;
