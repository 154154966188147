import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { Tooltip } from 'antd';
import { getEnumArrayStatus, getQualificationLevelStatusIcon } from '../../../../utils/Utils';
import { getColumnSearchByHumanCodebookSimple } from '../../../../utils/Filters';
import useEnum from '../../../../hooks/useEnum';
import QualificationLevelEdit from './edit';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const QualificationLevelIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    qualificationLevelGroupId,
    addedData,
    readonly = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const setQualificationLevelFilterValue = () => {
        if (qualificationLevelGroupId != undefined && qualificationLevelGroupId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'qualificationLevelGroupId', condition: 'equals', value: qualificationLevelGroupId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const table = useTableSettings();

    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    useHelper('human/codebooks/qualificationLevel');

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('qualification.level.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('qualification.level.table.column.level.group'),
            dataIndex: ['qualificationLevelGroup', 'name'],
            width: '15%',
            render: (qualificationLevelGroup: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '200px' }}
                        title={record?.qualificationLevelGroup?.name}
                    >
                        <div className={'ny-table-paragraph-div'}>
                            <p className={'ny-table-paragraph'}>{record?.qualificationLevelGroup?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(
                enCodebookType.QUALIFICATION_LEVEL_GROUP,

                'qualificationLevelGroupId'
            ),
        },
        {
            title: geti18nText('qualification.level.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('qualification.level.table.column.description'),
            dataIndex: 'description',
            render: (description: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record?.description}>
                        <div className={'ny-table-paragraph-div'}>
                            <p className={'ny-table-paragraph'}>{record?.description}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('qualification.level.table.column.level'),
            dataIndex: 'level',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('qualification.level.table.column.qualification'),
            dataIndex: 'qualification',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('qualification.level.table.column.ects'),
            dataIndex: 'ects',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('qualification.level.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            render: (type: any) => {
                return getQualificationLevelStatusIcon(type);
            },
            ...getColumnSearchCheckbox(getEnumArrayStatus('QUALIFICATION_LEVEL_TYPE')),
        },
        {
            title: geti18nText('qualification.level.table.column.sort'),
            dataIndex: 'sort',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('professional.qualification.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <NyDataTable
            nyId="human-qualification-level-table"
            url={CONSTANTS_REQ.QUALIFICATION_LEVEL.LIST}
            addNewButtonText={geti18nText('qualification.level.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={QualificationLevelEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setQualificationLevelFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            hideButtons={!canCreate()}
            editProps={addedData}
            shortcuts={true}
            readonly={readonly}
            headerTitle={geti18nText('menu.qualification.level')}
        />
    );
};

export default QualificationLevelIndex;
