import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByHumanCodebookSimple } from '../../../../utils/Filters';
import { getColorFromNotificationType } from '../../../../utils/Utils';
import LocationEdit from './edit';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EducationalDomainIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const table = useTableSettings();

    useHelper('human/codebooks/educational-domain');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const columns = [
        {
            title: geti18nText('educational.domain.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('educational.domain.table.column.scope'),
            dataIndex: ['scope', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.SCOPE, 'scopeId'),
            render: (text: any, record: any) => {
                return record.scopeName;
            },
        },
        {
            title: geti18nText('educational.domain.table.column.field'),
            dataIndex: ['field', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.FIELD, 'fieldId'),
            render: (text: any, record: any) => {
                return record.fieldName;
            },
        },
        {
            title: geti18nText('educational.domain.table.column.branch'),
            dataIndex: ['branch', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.BRANCH, 'branchId'),
            render: (text: any, record: any) => {
                return record.branchName;
            },
        },
        {
            title: geti18nText('educational.domain.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-educational-domain-table"
            url={CONSTANTS_REQ.EDUCATIONAL_DOMAIN.LIST}
            addNewButtonText={geti18nText('educational.domain.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={LocationEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.educational.domain')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            headerTitle={geti18nText('menu.educational.domain')}
        />
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('ducational.domain.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('educational.domain.table.column.scope'),
            dataIndex: 'scopeName',
        },
        {
            title: geti18nText('educational.domain.table.column.field'),
            dataIndex: 'fieldName',
        },
        {
            title: geti18nText('educational.domain.table.column.branch'),
            dataIndex: 'branchName',
        },
        {
            title: geti18nText('educational.domain.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EducationalDomainIndex;
