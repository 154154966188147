import { createSlice } from '@reduxjs/toolkit';

interface IPropState {
    prop: any;
    propOverviewTravelWarrant: any;
    propOverviewEmployeeLeave: any;
    propOverviewTasks: any;
}

const initialState: IPropState = {
    prop: 1,
    propOverviewTravelWarrant: 1,
    propOverviewEmployeeLeave: 1,
    propOverviewTasks: 1,
};

const prop = createSlice({
    name: 'prop',
    initialState,
    reducers: {
        propsInit(state) {
            state.prop = 1;
            state.propOverviewTravelWarrant = 1;
            state.propOverviewEmployeeLeave = 1;
            state.propOverviewTasks = 1;
        },
        propInit(state) {
            state.prop = 1;
        },
        setProp(state, action) {
            state.prop = action.payload;
        },
        propOverviewTravelWarrantInit(state) {
            state.propOverviewTravelWarrant = 1;
        },
        setPropOverviewTravelWarrant(state, action) {
            state.propOverviewTravelWarrant = action.payload;
        },
        propOverviewEmployeeLeaveInit(state) {
            state.propOverviewEmployeeLeave = 1;
        },
        setPropOverviewEmployeeLeave(state, action) {
            state.propOverviewEmployeeLeave = action.payload;
        },
        propOverviewTasksInit(state) {
            state.propOverviewTasks = 1;
        },
        setPropOverviewTasks(state, action) {
            state.propOverviewTasks = action.payload;
        },
    },
});

export const {
    propsInit,
    propInit,
    setProp,
    propOverviewTravelWarrantInit,
    setPropOverviewTravelWarrant,
    propOverviewEmployeeLeaveInit,
    setPropOverviewEmployeeLeave,
    propOverviewTasksInit,
    setPropOverviewTasks,
} = prop.actions;

export default prop.reducer;
