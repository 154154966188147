import { geti18nText, NyDataEdit, NyInputNumber, NySearchField, NySession } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdditionalInfo from '../../../../components/additional-info';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, onKeyDownTextAreaCustom, setEnumFormat } from '../../../../utils/Utils';
import { useDispatch } from 'react-redux';
import TravelWarrantList from '../../../../components/travel-warrant-list';
import { GetEnum } from '../../../../utils/Enums';
import DocumentationModal from '../../../../components/documentation-modal';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelWarrantTypeEdit = (props: any) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<any>();
    const focusInput = useRef<any>(null);
    const [editHeader, setEditHeader] = useState(geti18nText('travelWarrantType.edit.new'));
    const [loading, setLoading] = useState(false);
    const [createdDate, setCreatedDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);
    const [dataForm, setDataForm] = useState(null);
    const [isNonstandard, setIsNonstandard] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disabledDailyWageOptions, setDisabledDailyWageOptions] = useState(false);
    const [disabledTaxableTravelVehicleExpenses, setDisabledTaxableTravelVehicleExpenses] = useState(false);
    const [disabledTaxableTravelExpenses, setDisabledTaxableTravelExpenses] = useState(false);
    const [disabledWageNumberByOvernightStay, setDisabledWageNumberByOvernightStay] = useState(true);
    const [hideEducationTaxableOverDays, setHideEducationTaxableOverDays] = useState(true);

    const enWageType: any = GetEnum({ enumName: 'TRAVEL_WARRANT_WAGE_TYPE' });

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.created) {
            setCreatedDate(dataForm.created);
        }

        if (dataForm.createdBy) {
            setCreatedBy(dataForm.createdBy);
        }

        if (dataForm.updated) {
            setUpdatedDate(dataForm.updated);
        }
        if (dataForm.includeNonStandard) {
            setIsNonstandard(dataForm.includeNonStandard);
        }

        if (dataForm.updatedBy) {
            setUpdatedBy(dataForm.updatedBy);
        }

        if (dataForm.wageType) {
            dataForm.wageType = setEnumFormat('TRAVEL_WAGE_TYPE', dataForm.wageType);
            if (dataForm.wageType?.id != enWageType.PER_DIEM) {
                setDisabledWageNumberByOvernightStay(true);
                form.setFieldsValue({ wageNumberByOvernightStay: false });
            } else {
                setDisabledWageNumberByOvernightStay(false);
            }
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('travelWarrantType.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.includeWage === false) {
            setDisabledDailyWageOptions(true);
        }

        if (dataForm.includeTravelVehicleExpenses === false) {
            setDisabledTaxableTravelVehicleExpenses(true);
        }

        if (dataForm.includeTravelExpenses === false) {
            setDisabledTaxableTravelExpenses(true);
        }

        if (dataForm.includeEducationDate === true) {
            setHideEducationTaxableOverDays(false);
        }

        setIsDisabled(dataForm.travelWarrantCount > 0 ? true : false);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('travelWarrantType.edit.new'));
        setCreatedDate(null);
        setCreatedBy(null);
        setUpdatedDate(null);
        setUpdatedBy(null);
        setDataForm(null);
        setIsNonstandard(false);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {dataForm && (
                <div style={{ float: 'left' }}>
                    <AdditionalInfo
                        created={createdDate}
                        createdBy={createdBy}
                        updated={updatedDate}
                        updatedBy={updatedBy}
                    />
                </div>
            )}
        </React.Fragment>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={
                <>
                    {editHeader}{' '}
                    <DocumentationModal documentUrl={'travel_order/travelWarrantType/'} marginRight={'10px'} />
                </>
            }
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.EDIT}
            setValues={setValues}
            width={1060}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            normalize={(values: any) => {
                if (values.includeWage == null) {
                    delete values.includeWage;
                }
                if (values.taxableWage == null) {
                    delete values.taxableWage;
                }
                if (values.includeWageExpenses == null) {
                    delete values.includeWageExpenses;
                }
                if (values.includeTravelVehicleExpenses == null) {
                    delete values.includeTravelVehicleExpenses;
                }
                if (values.taxableTravelVehicleExpenses == null) {
                    delete values.taxableTravelVehicleExpenses;
                }
                if (values.includeTravelExpenses == null) {
                    delete values.includeTravelExpenses;
                }
                if (values.payoutByAnotherOrganization == null) {
                    delete values.payoutByAnotherOrganization;
                }
                if (values.includeEducationDate == null) {
                    delete values.includeEducationDate;
                }
                if (values.wageNumberByOvernightStay == null) {
                    delete values.wageNumberByOvernightStay;
                }
                if (values.taxableTravelExpenses == null) {
                    values.taxableTravelExpenses = false;
                }
                if (values.includeNonStandard == null) {
                    values.includeNonStandard = false;
                }
                values.wageType = getEnumFormat(values.wageType);
                if (values.wageType < 1) {
                    delete values.wageType;
                }

                return values;
            }}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate() || isDisabled}
            hideActivationButtons={!canCreate() || isDisabled}
            customFooterContent={getCustomFooterContent}
        >
            {isDisabled && (
                <Row gutter={24}>
                    <Col span={24}>
                        <TravelWarrantList url={CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.TW_LIST} id={dataForm} />
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="travelWarrantList" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} disabled={isDisabled} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.wageType')}
                        name={'wageType'}
                        initialValue={setEnumFormat('TRAVEL_WARRANT_WAGE_TYPE', 1)}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('TRAVEL_WARRANT_WAGE_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            // defaultValue={geti18nText('app.enum.TRAVEL_WARRANT_WAGE_TYPE.1')}
                            className=""
                            disabled={isNonstandard || isDisabled}
                            onChange={(e: any) => {
                                if (e?.id != enWageType.PER_DIEM) {
                                    setDisabledWageNumberByOvernightStay(true);
                                    form.setFieldsValue({ wageNumberByOvernightStay: false });
                                } else {
                                    setDisabledWageNumberByOvernightStay(false);
                                }
                            }}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={2} style={{ display: 'none' }}>
                    <Form.Item label={geti18nText('task.typeCategory.table.column.sort')} name="sort">
                        <NyInputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.table.column.educationTaxableOverDays')}
                        name="educationTaxableOverDays"
                        hidden={hideEducationTaxableOverDays}
                    >
                        <NyInputNumber
                            style={{ width: '97%' }}
                            defaultValue={''}
                            isDecimal={false}
                            min={0}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('item.edit.description')} name="description">
                        <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 3 }}
                            onKeyDown={onKeyDownTextAreaCustom}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('travelWarrantType.edit.info')} name="info">
                        <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 3 }}
                            onKeyDown={onKeyDownTextAreaCustom}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('travelWarrantType.edit.info2')} name="info2">
                        <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 3 }}
                            onKeyDown={onKeyDownTextAreaCustom}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.include.wage')}
                        name="includeWage"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            disabled={isDisabled}
                            onChange={(event: any) => {
                                setDisabledDailyWageOptions(!event.target.checked);
                                if (!event.target.checked) {
                                    form.setFieldsValue({
                                        taxableWage: false,
                                        includeWageExpenses: false,
                                        includeNonStandard: false,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.taxable.wage')}
                        name="taxableWage"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={isDisabled || disabledDailyWageOptions} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.include.wage.expenses')}
                        name="includeWageExpenses"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox disabled={isDisabled || disabledDailyWageOptions} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.includeNonStandard')}
                        name="includeNonStandard"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e: any) => {
                                setIsNonstandard(e.target.checked);
                            }}
                            disabled={isDisabled || disabledDailyWageOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.include.travel.vehicle.expenses')}
                        name="includeTravelVehicleExpenses"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            disabled={isDisabled}
                            onChange={(event: any) => {
                                setDisabledTaxableTravelVehicleExpenses(!event.target.checked);
                                if (!event.target.checked) {
                                    form.setFieldsValue({
                                        taxableTravelVehicleExpenses: false,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.taxable.travel.vehicle.expenses')}
                        name="taxableTravelVehicleExpenses"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={isDisabled || disabledTaxableTravelVehicleExpenses} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.include.travel.expenses')}
                        name="includeTravelExpenses"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            disabled={isDisabled}
                            onChange={(event: any) => {
                                setDisabledTaxableTravelExpenses(!event.target.checked);
                                if (!event.target.checked) {
                                    form.setFieldsValue({
                                        taxableTravelExpenses: false,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.include.taxable.travel.expenses')}
                        name="taxableTravelExpenses"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox disabled={isDisabled || disabledTaxableTravelExpenses} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.payout.by.another.organization')}
                        name="payoutByAnotherOrganization"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={isDisabled} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.include.education.date')}
                        name="includeEducationDate"
                        valuePropName="checked"
                    >
                        <Checkbox
                            disabled={isDisabled}
                            onChange={(event: any) => {
                                setHideEducationTaxableOverDays(!event.target.checked);
                                if (!event.target.checked) {
                                    form.setFieldsValue({
                                        educationTaxableOverDays: null,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('travelWarrantType.edit.wage.number.by.overnight.stay')}
                        name="wageNumberByOvernightStay"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={isDisabled || disabledWageNumberByOvernightStay} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelWarrantTypeEdit;
