import { NyDatePicker, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { refreshItemList } from '../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, getDateFormat, okNotification } from '../../../../utils/Utils';

const GenerateEmployeeTravelRelationPaymentModal = ({ modalVisible, setModalVisible }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState<any>(undefined);
    const [toDate, setToDate] = useState<any>(undefined);

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false);
        setFromDate(undefined);
        setToDate(undefined);
    };

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                if (values.calculationDate) values.calculationDate = getDateFormat(values.calculationDate);
                if (values.periodFrom) values.periodFrom = getDateFormat(values.periodFrom);
                if (values.periodTo) values.periodTo = getDateFormat(values.periodTo);

                NyRequestResolver.requestPost(
                    CONSTANTS_REQ.EMPLOYEE_TRAVEL_RELATION.GENERATE_PAYMENT,
                    undefined,
                    values
                ).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        closeModal();
                        dispatch(refreshItemList());
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    const disabledDateFrom = (current: any) => {
        if (toDate) {
            return current > moment(toDate);
        }
    };

    const disabledDateTo = (current: any) => {
        if (fromDate) {
            return current.isBefore(fromDate.clone());
        } else {
            return null;
        }
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('employeeTravelRelation.generateEmployeeTravelRelationPayment')}
                    okText={geti18nText('app.default.button.generate')}
                    onOk={(e) => save(e)}
                    onCancel={closeModal}
                    confirmLoading={loading}
                    width={400}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employeeTravelRelationPayment.edit.calculationDate')}
                                    name="calculationDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment()}
                                >
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        nyTestId="calculation-date"
                                        autoFocus={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeeTravelRelationPayment.edit.periodFrom')}
                                    name="periodFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        nyTestId="period-from"
                                        onChange={(date: any) => {
                                            setFromDate(date);
                                            if (!toDate) {
                                                setToDate(date);
                                                form.setFieldsValue({ dateTo: date });
                                            }
                                        }}
                                        disabledDate={disabledDateFrom}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeeTravelRelationPayment.edit.periodTo')}
                                    name="periodTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        nyTestId="period-to"
                                        disabledDate={disabledDateTo}
                                        onChange={(date: any) => setToDate(date)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default GenerateEmployeeTravelRelationPaymentModal;
