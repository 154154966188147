import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyDataEdit, NyInputNumber, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getEnumArrayStatus, getEnumFormat, setEnumFormat } from '../../../../../utils/Utils';
import AccountingEntityAccountIndex from '../../accounting-entity-account';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';

const { Panel } = Collapse;

const DepreciationGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('depreciation.group.edit.new'));
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const isInternalRateEnabled = useApplicationSetting('ENABLE_DEPRECIATION_INTERNAL_RATE');

    const canCreate = () => {
        return FixedAssetRights.canCreateFixedAssetCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('depreciation.group.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.depreciationStartType) {
            dataForm.depreciationStartType = setEnumFormat('DEPRECIATION_START_TYPE', dataForm.depreciationStartType);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setDataForm(null);
        form.resetFields();
        setEditHeader(geti18nText('depreciation.group.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onDepreciationTimeChange = (value: any) => {
        if (value && value >= 0) {
            const year = value / 12;
            const rate = 100 / year;
            const increasedRate = 2 * rate;

            form.setFieldsValue({ depreciationRate: rate });
            form.setFieldsValue({ depreciationIncreasedRate: increasedRate });
        }
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            editHeader={editHeader}
            url={CONSTANTS_REQ.DEPRECIATION_GROUP.EDIT}
            paramsId={id}
            setValues={setValues}
            width={1200}
            form={form}
            customFooterContent={getCustomFooterContent}
            buttonsClassName="buttons-sticky"
            changeCollapseVisibility={changeCollapseVisibility}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            {...props}
            shortcuts={true}
            normalize={(values: any) => {
                if (values.depreciationStartType)
                    values.depreciationStartType = getEnumFormat(values.depreciationStartType);
                return values;
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('orderView.divider.1')} key="1">
                            <Form form={form} component={false}>
                                <Row gutter={24}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('depreciation.group.edit.name')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input ref={focusInput} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('depreciation.group.edit.code')} name="code">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('depreciation.group.edit.period')}
                                            name="depreciationTime"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                style={{ width: '100%' }}
                                                onChange={onDepreciationTimeChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('depreciation.group.edit.depreciationStartType')}
                                            name="depreciationStartType"
                                        >
                                            <NySearchField
                                                options={getEnumArrayStatus('DEPRECIATION_START_TYPE')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                style={{ width: '100%' }}
                                                className={''}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('depreciation.group.edit.anual')}
                                            name="depreciationRate"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('depreciation.group.edit.accelerated')}
                                            name="depreciationIncreasedRate"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    {isInternalRateEnabled == 1 ? (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('depreciation.group.edit.internal')}
                                                name="depreciationInternalRate"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} isDecimal={true} />
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        ''
                                    )}
                                </Row>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('fixed.asset.panel.account')} key="2">
                            {dataForm ? (
                                <AccountingEntityAccountIndex
                                    refId={dataForm}
                                    refType={'DEPRECIATION_GROUP'}
                                    showRowSelection={true}
                                ></AccountingEntityAccountIndex>
                            ) : (
                                <div></div>
                            )}
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default DepreciationGroupEdit;
