import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { geti18nText } from '@nybble/nyreact';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectedItemSet } from '../../../../slices/selectedItemSlice';
import BankAccountIndex from './BankAccountIndex';
import ContactIndex from './ContactIndex';
import GeneralEditTab from './GeneralEditTab';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import useHelper from '../../../../hooks/useHelper';

const { TabPane } = Tabs;

const CompanyEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('company.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isCreate, setIsCreate] = useState(false);
    const [isFormChange, setIsFormChange] = useState(false);

    const { id } = useParams<any>();
    const dispatch = useDispatch();

    const location = useLocation();
    useHelper('common/company');

    useEffect(() => {
        dispatch(selectedItemSet({ item: dataForm, refreshItem: 0, employeeId: undefined }));
    }, [dataForm]);

    return (
        <Tabs type="card" id="company-edit-tabs">
            <TabPane
                tab={
                    <span>
                        {geti18nText('company.tab.general')}
                        {isFormChange ? (
                            <ExclamationCircleTwoTone
                                twoToneColor="#faad14"
                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                            />
                        ) : (
                            ''
                        )}
                    </span>
                }
                key="general"
            >
                <GeneralEditTab
                    props={props}
                    companyId={id}
                    setEditHeader={setEditHeader}
                    setDataForm={setDataForm}
                    setLoading={setLoading}
                    loading={loading}
                    setIsCreate={setIsCreate}
                    setIsFormChange={setIsFormChange}
                />
            </TabPane>
            <TabPane tab={geti18nText('company.tab.contact')} key="contact" disabled={isCreate || loading}>
                <ContactIndex props={props} companyId={id} />
            </TabPane>
            <TabPane tab={geti18nText('company.tab.bankAccounts')} key="bank-account" disabled={isCreate || loading}>
                <BankAccountIndex />
            </TabPane>
        </Tabs>
    );
};

export default CompanyEdit;
