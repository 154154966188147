import {
    geti18nText,
    NyDataEdit,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySpinner,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AssetSimpleIndex from '../../../../../../components/asset-simple/assetSimple';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { customAssetExtraRenderOption, getSearchFormat } from '../../../../../../utils/Utils';
import { validateNegativeNumber } from '../../../../../../utils/Validation';
import AssetEdit from '../../../../../webshop/views/webshop-asset/asset/edit';

const AssetWriteOffItemsEdit = (props: any) => {
    const [form] = Form.useForm();
    const [editHeader, setEditHeader] = useState(geti18nText('asset.acquisition.item.table.header'));
    const [loading, setLoading] = useState(false);
    const [searchDataLoading, setSearchDataLoading] = useState<boolean>(false);
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [assetWriteOffId, setAssetWriteOffId] = useState(null);

    useEffect(() => {
        setAssetWriteOffId(props?.editProps?.assetWriteOffId ?? null);
    }, [props]);

    const canCreate = () => {
        return props?.editProps?.canCreate;
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm?.asset?.assetModel?.name) {
            setEditHeader(dataForm.asset.assetModel.name);
        }
        if (dataForm.asset.aquisitionPrice == undefined) {
            dataForm.asset.aquisitionPrice = dataForm.acquisitionCostValue / dataForm.quantity;
        }
        dataForm.asset.label = dataForm?.asset?.assetModel?.name;
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('asset.acquisition.item.table.header'));
        setAssetWriteOffId(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onAssetChange = (value: any) => {
        if (value && value.id !== -1) {
            setSearchDataLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET.EDIT + '/' + value.id, undefined).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setSearchDataLoading(false);
                        setFormValues(result.data);
                    }
                } else {
                    setSearchDataLoading(false);
                }
            });
        }
    };

    const setFormValues = (data: any) => {
        let formItem: any = {};
        formItem.asset = {};
        formItem.asset = data;
        formItem.asset.aquisitionPrice = data.aquisitionPrice ? data.aquisitionPrice : 0;
        formItem.acquisitionCostValue = data.aquisitionCostValue ? data.aquisitionCostValue : 0;
        formItem.valueIncrease = data.valueIncrease ? data.valueIncrease : 0;
        formItem.valueDecrease = data.valueDecrease ? data.valueDecrease : 0;
        formItem.acumulatedDepreciation = data.accumulatedDepreciation ? data.accumulatedDepreciation : 0;
        formItem.amount = data.netBookValue ? data.netBookValue : 0;
        formItem.quantity = data.quantity ? data.quantity : 0;
        form.setFieldsValue(formItem);
    };

    const changeAmount = () => {
        const quantity = form.getFieldValue(['quantity']) ? form.getFieldValue(['quantity']) : 0;
        const asset = form.getFieldValue(['asset']) ? form.getFieldValue(['asset']) : null;
        const price = asset && asset.aquisitionPrice ? asset.aquisitionPrice : 0;
        form.setFieldsValue({ acquisitionCostValue: quantity * price >= 0 ? quantity * price : 0 });
        changeNetBookValue();
    };

    const changeNetBookValue = () => {
        const acquisitionCostValue = form.getFieldValue(['acquisitionCostValue'])
            ? form.getFieldValue(['acquisitionCostValue'])
            : 0;
        const valueIncrease = form.getFieldValue(['valueIncrease']) ? form.getFieldValue(['valueIncrease']) : 0;
        const valueDecrease = form.getFieldValue(['valueDecrease']) ? form.getFieldValue(['valueDecrease']) : 0;
        const acumulatedDepreciation = form.getFieldValue(['acumulatedDepreciation'])
            ? form.getFieldValue(['acumulatedDepreciation'])
            : 0;
        const asset = form.getFieldValue(['asset']) ? form.getFieldValue(['asset']) : null;
        const depreciation = asset && asset.depreciation ? asset.depreciation : 0;
        const amount = acquisitionCostValue + valueIncrease - valueDecrease - acumulatedDepreciation - depreciation;
        form.setFieldsValue({ amount: amount >= 0 ? amount : 0 });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ASSET_WRITE_OFF_ITEM.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            hideSubmitButton={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            isModal={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.assetWriteOff = { id: assetWriteOffId };
                values.asset = getSearchFormat(values.asset);
                return values;
            }}
            hideActivationButtons={true}
            onSaveAndGetID={() => {
                props?.setRefreshTable && props.setRefreshTable((prevValue: any) => prevValue + 1);
                props?.editProps?.setGetTotalsRefresh &&
                    props?.editProps?.setGetTotalsRefresh((prevValue: any) => prevValue + 1);
            }}
        >
            <Row gutter={24}>
                {!searchDataLoading ? (
                    <Col span={24}>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="asset" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.acquisition.item.table.column.asset')}
                                    name="asset"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        style={{ width: '100%' }}
                                        url={CONSTANTS_REQ.ASSET.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'label',
                                            text: 'text',
                                            name: 'name',
                                            serialNumber: 'serialNumber',
                                            inventoryNumber: 'inventoryNumber',
                                            assetModel: 'assetModel',
                                            assetType: 'assetType',
                                            item: 'item',
                                            measureUnit: 'measureUnit',
                                            quantity: 'quantity',
                                        }}
                                        searchBy="assetModel.name || serialNumber || inventoryNumber"
                                        renderOption={customAssetExtraRenderOption}
                                        SearchPopupComponent={<AssetSimpleIndex isFixedAsset={true} />}
                                        AddNewModalComponent={AssetEdit}
                                        AddNewModalComponentAddedData={{ isModal: true }}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        autoFocus
                                        order="assetModel.name"
                                        customListWidth={'1200px'}
                                        mustGetPopupContainer={false}
                                        disabled={!canCreate()}
                                        onChange={onAssetChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.acquisition.item.table.column.quantity')}
                                    name="quantity"
                                    initialValue={1}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        disabled={!canCreate()}
                                        style={{ width: '100%' }}
                                        onChange={changeAmount}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.acquisition.item.table.column.price')}
                                    name={['asset', 'aquisitionPrice']}
                                    initialValue={0}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        disabled={!canCreate()}
                                        style={{ width: '100%' }}
                                        isDecimal
                                        onChange={changeAmount}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.acquisition.item.table.column.amount')}
                                    name="acquisitionCostValue"
                                    initialValue={0}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        disabled={!canCreate()}
                                        style={{ width: '100%' }}
                                        isDecimal
                                        onChange={changeNetBookValue}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.valueIncrease')}
                                    name="valueIncrease"
                                    initialValue={0}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        disabled={!canCreate()}
                                        style={{ width: '100%' }}
                                        isDecimal
                                        onChange={changeNetBookValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.valueDecrease')}
                                    name="valueDecrease"
                                    initialValue={0}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        disabled={!canCreate()}
                                        style={{ width: '100%' }}
                                        isDecimal
                                        onChange={changeNetBookValue}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.acumulatedDepreciation')}
                                    name="acumulatedDepreciation"
                                    initialValue={0}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        disabled={!canCreate()}
                                        style={{ width: '100%' }}
                                        isDecimal
                                        onChange={changeNetBookValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.writeOff.item.table.column.amount')}
                                    name="amount"
                                    initialValue={0}
                                    rules={[
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber disabled={!canCreate()} style={{ width: '100%' }} isDecimal />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                ) : (
                    <Col span={24} style={{ height: '350px', alignContent: 'center' }}>
                        <NySpinner />
                    </Col>
                )}
            </Row>
        </NyDataEdit>
    );
};

export default AssetWriteOffItemsEdit;
