import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Popover, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NyNoteModal from '../../components/note-modal';
import useEnum from '../../hooks/useEnum';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    getDateFormat,
    getEnumArray,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    getTravelWarrantPaymentStatusIcon,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    userIsNotificationRecipient,
    warningNotification,
} from '../../utils/Utils';
import StatusHistoryIndex from '../status-history/StatusHistory';
import CurrencySearch from '../../views/administration/views/currency/search';

const TravelWarrantPaymentEdit = (props: any) => {
    const { employee } = useSelector((state: RootState) => state.employee);
    const [editHeader, setEditHeader] = useState(geti18nText('travelWarrantPayment.edit.new'));
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [defaultPaymentType, setDefaultPaymentType] = useState<any>(undefined);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantPaymentStatus = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');
    const [taxable, setTaxable] = useState<boolean>(false);

    const [form] = Form.useForm();
    const history = useHistory();

    const canSeePaymentCheckVisible = () => {
        return TravelWarrantRights.canSeePaymentCheckBox(employee, notificationType);
    };

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const canSeeStatus = () => {
        return (
            TravelWarrantRights.canCreateTravelWarrantAdmin() ||
            (TravelWarrantRights.canCreateTravelWarrantOnlyUser() &&
                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT))
        );
    };

    const canSubmit = () => {
        //TRAVEL_WARRANT_PAYMENT 14, TRAVEL_WARRANT_COMPENSATION_PAYMENT 15
        return (
            canCreate() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const getTravelWarrantPaymentHistory = (id: any, dataFormStatus: any) => {
        return (
            <div>
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="bottomLeft"
                    content={
                        <StatusHistoryIndex
                            id={id}
                            url={CONSTANTS_REQ.TRAVEL_WARRANT.TRAVEL_WARRANT_PAYMENT_HISTORY + '/' + id}
                            type="travelWarrantPayment"
                        />
                    }
                    style={{ marginLeft: '4rem' }}
                >
                    <div style={{ marginLeft: '5px' }}>
                        {getTravelWarrantPaymentStatusIcon(dataFormStatus.id, travelWarrantPaymentStatus)}
                    </div>
                </Popover>
            </div>
        );
    };

    const getExchangeRate = () => {
        let date = form.getFieldValue(['paymentDate']);
        let currency = form.getFieldValue(['currency']);

        if (date && currency && currency.id > 0) {
            var parms: any = { currencyId: currency.id, date: getDateFormat(date) };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EXCHANGE_RATE.GET_EXCHANGE_RATE, parms).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    if (response.data && response.data.middleRate) {
                        form.setFieldsValue({ exchangeRate: response.data.middleRate });
                        calculateAmount();
                    }
                } else {
                    form.setFieldsValue({ exchangeRate: 0 });
                    warningNotification(geti18nText('exchangeRate.not.found'));
                }
            });
        }
    };

    function setValues(dataForm: any) {
        if (dataForm.paymentDate) {
            dataForm.paymentDate = setDateFormat(dataForm.paymentDate);
        }
        if (dataForm.paymentType) {
            dataForm.paymentType = setEnumFormat('TRAVEL_WARRANT_PAYMENT_TYPE', dataForm.paymentType);
        }
        if (dataForm.currency) {
            dataForm.currency = setSearchFormat(dataForm.currency, 'isoCode');
        }
        if (dataForm.status) {
            dataForm.status = setEnumFormat('TRAVEL_WARRANT_PAYMENT_STATUS', dataForm.status);
        }

        setTaxable(dataForm.taxable);

        setNote(dataForm.note ? dataForm.note : undefined);
        setEditHeader(
            <div style={{ display: 'flex' }}>
                {geti18nText('travelWarrantPayment.edit.title') + ' - ' + dataForm.id}
                {getTravelWarrantPaymentHistory(dataForm.id, dataForm.status)}
            </div>
        );

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setNote(undefined);
        setEditHeader(geti18nText('travelWarrantPayment.edit.new'));
    };

    const getCustomFooterContent = (
        <div className="ny-modal-footer-content">
            <NyNoteModal note={note} />
        </div>
    );

    const calculateAmount = () => {
        let amount = 0;
        let exchangeRate = form.getFieldValue(['exchangeRate']);
        let amountInCurrency = form.getFieldValue(['amountInCurrency']);
        if (exchangeRate && amountInCurrency) {
            amount = amountInCurrency * exchangeRate;
        }
        form.setFieldsValue({ amount: amount });
    };

    useEffect(() => {
        getAppSettings();
    }, []);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 2 },
            { field: 'settingGroup', condition: 'equals', value: 'TRAVEL_WARRANT' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_PAYMENT_TYPE') {
                                form.setFieldsValue({
                                    paymentType: setEnumFormat(
                                        'TRAVEL_WARRANT_PAYMENT_TYPE',
                                        Number(element.value ? element.value : element.defaultValue)
                                    ),
                                });
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TRAVEL_WARRANT_PAYMENT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={props && props.addedData && props.addedData.id}
            {...props}
            normalize={(values: any) => {
                if (values.status) values.status = getEnumFormat(values.status);
                if (values.paymentDate) values.paymentDate = getDateFormat(values.paymentDate);
                if (values.paymentType) values.paymentType = getEnumFormat(values.paymentType);
                if (values.currency) values.currency = getSearchFormat(values.currency);
                values.advance = values.advance ?? false;
                values.taxable = taxable;

                values.travelWarrant = { id: props && props.addedData && props.addedData.id };
                return values;
            }}
            onSave={() => {
                if (!props?.editProps?.isWizard) {
                    props?.editProps?.setRefresh((prev: number) => prev + 1);
                }
                props?.editProps?.setRefreshTable((prev: number) => prev + 1);
            }}
            customFooterContent={getCustomFooterContent}
            shortcuts={true}
            nyTestId="payment-data-edit"
            hideSubmitButton={!canSubmit()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantPayment.edit.paymentType')}
                        name="paymentType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('TRAVEL_WARRANT_PAYMENT_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            nyTestId="payment-type"
                            autoFocus={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantPayment.edit.paymentDate')}
                        name="paymentDate"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            onChange={getExchangeRate}
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            nyTestId="payment-date"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantPayment.edit.advance')}
                        name="advance"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={!canCreate() && canSubmit()} />
                    </Form.Item>
                </Col>
                {canSeePaymentCheckVisible() && !props?.editProps?.isWizard && (
                    <Col span={12}>
                        <Form.Item label={geti18nText('travelWarrantWageRecapitulation.taxable')} name="taxable">
                            <Checkbox
                                checked={taxable}
                                onChange={(val: any) => {
                                    setTaxable(val.target.checked);
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={24} style={canSeeStatus() ? {} : { display: 'none' }}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('travelWarrantPayment.edit.status')}
                        name="status"
                        initialValue={setEnumFormat('TRAVEL_WARRANT_PAYMENT_STATUS', -1)}
                    >
                        <NySearchField
                            options={getEnumArrayStatus('TRAVEL_WARRANT_PAYMENT_STATUS')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            nyTestId="travel-warrant-payment-status"
                            className=""
                            style={{ width: '100%' }}
                            disabled={false}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('travelWarrantPayment.edit.description')} name="description">
                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} onKeyDown={onKeyDownTextAreaCustom} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <CurrencySearch
                        label={geti18nText('travelWarrantPayment.edit.isoCode')}
                        name="currency"
                        required={true}
                        onChange={getExchangeRate}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantPayment.edit.amountInCurrency')}
                        name="amountInCurrency"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            isDecimal={true}
                            style={{ width: '100%' }}
                            onChange={calculateAmount}
                            nyTestId="amount-in-currency"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWarrantPayment.edit.exchangeRate')} name="exchangeRate">
                        <NyInputNumber
                            isDecimal={true}
                            style={{ width: '100%' }}
                            onChange={calculateAmount}
                            nyTestId="exchange-rate"
                            decimalPlaces={6}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantPayment.edit.amount')}
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} nyTestId="amount" />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelWarrantPaymentEdit;
