import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Image, Modal, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../utils/Constants';

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const ItemDetails = ({ item, addItemInBasket }: any) => {
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [itemData, setItemData] = useState<any>(undefined);
    const [metadata, setMetadata] = useState([]);

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'itemGroup.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.METADATA, {
            max: 100,
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setMetadata(result.data.content);
                }
            }
        });
    };

    const openDetails = (value: any) => {
        setDetailsVisible(true);
        fetchMetadata(value.itemGroup.id);
        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + value.id, undefined).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data) {
                    setItemData(result.data);
                    setDetailsVisible(true);
                }
            }
        });
    };

    const closeModal = () => {
        setDetailsVisible(false);
        setItemData(undefined);
        setMetadata([]);
    };

    return (
        <React.Fragment>
            <Tooltip placement="top" title={item.name}>
                <div className="webshop-card-title" onClick={() => openDetails(item)}>
                    {item.name}
                </div>
            </Tooltip>
            {detailsVisible && (
                <Modal
                    width={900}
                    title={itemData && itemData.name + ' - ' + itemData.code}
                    maskClosable={false}
                    footer={[
                        <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => {
                                addItemInBasket(item);
                                closeModal();
                            }}
                            size="small"
                            type="primary"
                            style={{ marginLeft: '5px' }}
                        >
                            {geti18nText('webshopItem.addToBasket')}
                        </Button>,
                    ]}
                    visible={detailsVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                >
                    <Row gutter={24}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Collapse activeKey={['1']}>
                                <Panel header={geti18nText('item.tab.1')} key="1">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            {itemData && itemData.fileId ? (
                                                <Image
                                                    width={120}
                                                    height={120}
                                                    style={{ marginBottom: '10px', border: '1px solid #f0f0f0' }}
                                                    src={
                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                        '/' +
                                                        item.fileId +
                                                        '?tenant=' +
                                                        NySession.getUser().tenantId
                                                    }
                                                />
                                            ) : (
                                                <Image
                                                    width={120}
                                                    height={120}
                                                    style={{ marginBottom: '10px', border: '1px solid #f0f0f0' }}
                                                    src="error"
                                                    fallback={FILE_FALLBACK}
                                                />
                                            )}
                                        </Col>
                                        <Col span={16}>
                                            {itemData && itemData.name && (
                                                <Row>
                                                    <Paragraph>
                                                        <Text strong>{geti18nText('item.edit.name')}: </Text>{' '}
                                                        {itemData.name}
                                                    </Paragraph>
                                                </Row>
                                            )}
                                            {itemData && itemData.code && (
                                                <Row>
                                                    <Paragraph>
                                                        <Text strong>{geti18nText('item.edit.code')}: </Text>{' '}
                                                        {itemData.code}
                                                    </Paragraph>
                                                </Row>
                                            )}
                                            {itemData && itemData.itemGroup && (
                                                <Row>
                                                    <Paragraph>
                                                        <Text strong>{geti18nText('item.edit.itemGroup')}: </Text>{' '}
                                                        {itemData.itemGroup.name}
                                                    </Paragraph>
                                                </Row>
                                            )}
                                            {itemData && itemData.measureUnit && (
                                                <Row>
                                                    <Paragraph>
                                                        <Text strong>{geti18nText('item.edit.measureUnit')}: </Text>
                                                        {itemData.measureUnit.name}
                                                    </Paragraph>
                                                </Row>
                                            )}
                                            {itemData && itemData.secondMeasureUnit && (
                                                <Row>
                                                    <Paragraph>
                                                        <Text strong>
                                                            {geti18nText('item.edit.secondMeasureUnit')}:{' '}
                                                        </Text>
                                                        {itemData.secondMeasureUnit.name}
                                                    </Paragraph>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Collapse activeKey={['2']}>
                                <Panel header={geti18nText('item.tab.2')} key="2">
                                    <Row gutter={24}>
                                        {itemData && itemData.description && (
                                            <Col span={24}>
                                                <Paragraph>
                                                    <Text strong>{geti18nText('item.edit.description')}: </Text>{' '}
                                                    {itemData.description}
                                                </Paragraph>
                                            </Col>
                                        )}
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse activeKey={['3']}>
                                <Panel header={geti18nText('item.tab.3')} key="3">
                                    <Col span={24}>
                                        {metadata &&
                                            itemData &&
                                            itemData.itemMetadata &&
                                            itemData.itemMetadata.map((itemMeta: any) => {
                                                if (itemMeta.item_group_metadata !== undefined) {
                                                    const findItem: any = metadata.find(
                                                        (row: any) => row.id === itemMeta.item_group_metadata.id
                                                    );
                                                    if (
                                                        findItem !== undefined &&
                                                        findItem.objectMetadata !== undefined
                                                    ) {
                                                        return (
                                                            <Row>
                                                                <Paragraph>
                                                                    <Text strong>{findItem.objectMetadata.name}: </Text>{' '}
                                                                    {findItem.objectMetadata.dataType === 4
                                                                        ? moment(itemMeta.value).format(
                                                                              'HH:mm DD.MM.yyyy'
                                                                          )
                                                                        : itemMeta.value}
                                                                </Paragraph>
                                                            </Row>
                                                        );
                                                    }
                                                }
                                            })}
                                    </Col>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default ItemDetails;
