import { Checkbox, Col, Form, Row } from 'antd';
import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';
import EmployeeIndex from '../employee';
import EmployeeGroupSearch from '../employee-group/search';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customEmployeeRenderOption } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const AddEmployee = ({
    allEmployee,
    setAllEmployee,
    lock,
    showAllEmployee,
    multiple = true,
    allEmployeeInitialValue = true,
}: any) => {
    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    return multiple ? (
        <>
            {showAllEmployee && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('payment.calculation.edit.allEmployee')}
                            name="allEmployee"
                            valuePropName="checked"
                            initialValue={allEmployeeInitialValue}
                        >
                            <Checkbox
                                onChange={(event: any) => {
                                    setAllEmployee(event.target.checked);
                                }}
                                disabled={lock || !canCreate()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <EmployeeGroupSearch
                        disabled={lock || (showAllEmployee && allEmployee) || !canCreate()}
                        label={geti18nText('payment.calculation.edit.employeeGroup')}
                        mode={'multiple'}
                        name={'employeeGroup'}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('payment.calculation.edit.employee')} name="employee">
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            mode={'multiple'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="payment-calc-add-employee"/>}
                            disabled={lock || (showAllEmployee && allEmployee) || !canCreate()}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    ) : (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('payment.calculation.edit.employee')} name="employee">
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} />}
                            disabled={lock || (showAllEmployee && allEmployee) || !canCreate()}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default AddEmployee;
