import { CopyOutlined, EllipsisOutlined, FilePdfOutlined } from '@ant-design/icons';
import { NyRequestResolver, NyUtils, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Dropdown, Menu, Tooltip, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ, getReportDocumentExtension } from '../../utils/Constants';
import { GetEnum } from '../../utils/Enums';
import { fileDownload } from '../../utils/Utils';
import ReportExportType from '../report-export-type/ReportExportType';

const NyReportButton = ({
    url,
    customParms = {},
    subreportType = null,
    buttoni18nText,
    fileName = 'report',
    fileNameAddition,
    type = 'button',
    disabled = false,
    checkBeforeSave = undefined,
    showLink = false,
    columns = true,
    customIconStyle,
    customDateFormat = undefined,
    showTooltip = false,
    allowExportTypeChange = false,
    selectedExportType,
}: any) => {
    const [subreportTypeEnums] = useState(GetEnum({ enumName: 'SUBREPORT_TYPE' }));
    const [customReports, setCustomReports] = useState([]);
    const enExportType = useEnum('EXPORT_TYPE');
    const [exportType, setExportType] = useState<any>(selectedExportType ? selectedExportType : enExportType.PDF);
    const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);
    const [selectedSubreport, setSelectedSubreport] = useState<any>(null);

    useEffect(() => {
        if (selectedExportType) {
            setExportType(selectedExportType);
        }
    }, [selectedExportType]);

    useEffect(function () {
        if (subreportType != null && subreportType != undefined) {
            getReports();
        }
    }, []);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'type',
                condition: 'equals',
                value: subreportTypeEnums[subreportType],
            },
            {
                field: 'defaultReport',
                condition: 'equals_bool',
                value: 0,
            },
        ];
    };

    const getReports = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.SUBREPORT.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setCustomReports(result.data);
                }
            }
        });
    };

    const getDocumentExtension = () => {
        return getReportDocumentExtension(exportType);
    };

    const downloadReport = async (subreportId?: number, useDefault?: boolean) => {
        if (url) {
            if (checkBeforeSave) {
                const resChechBeforeSave = await checkBeforeSave();
                if (!resChechBeforeSave) {
                    return;
                }
            }
            let parms = {
                lang: NyUtils.getSelectedLanguage(),
                subreportId:
                    subreportId && Number(subreportId)
                        ? subreportId
                        : selectedSubreport && Number(selectedSubreport)
                        ? selectedSubreport
                        : '',
                useDefault: useDefault ? true : false,
                exportType: exportType ? exportType : enExportType.PDF,
                ...customParms,
            };
            let addition = customDateFormat ?? moment().format('yyyyMMDD');
            if (
                (subreportType == 'TRAVEL_WARRANT' || subreportType == 'TRAVEL_WARRANT_CALCULATION') &&
                fileNameAddition
            ) {
                addition = fileNameAddition;
            }
            const fileDownloadName = `${fileName}_${addition}` + getDocumentExtension();
            fileDownload(url, parms, fileDownloadName);
        }
    };

    const openReportModal = (report: any) => {
        if (allowExportTypeChange) {
            setReportModalVisible(true);
            if (report?.id) {
                setSelectedSubreport(report?.id);
            }
        } else {
            downloadReport(report?.id ? report.id : null);
        }
    };

    const reportMenu = () => {
        return (
            <Menu>
                {/* <Menu.Item onClick={() => downloadReport(undefined, true)}>
                    {geti18nText('report.defaultForm')}
                </Menu.Item> */}
                {customReports.map((report: any) => {
                    return (
                        <Menu.Item
                            onClick={() => {
                                openReportModal(report);
                            }}
                        >
                            {report.name}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    };

    const iconStyle: any = {
        fontSize: '16px',
        paddingRight: '10px',
        paddingTop: '2px',
        float: 'left',
    };

    return (
        <>
            {type === 'button' ? (
                <>
                    {columns == true ? (
                        <Col style={{ width: '100%', display: 'inline-flex' }}>
                            <Button
                                block
                                icon={<FilePdfOutlined style={customIconStyle ?? iconStyle} />}
                                style={{ marginRight: '0rem' }}
                                onClick={() => {
                                    openReportModal(null);
                                }}
                                disabled={disabled}
                            >
                                {showTooltip ? (
                                    <Tooltip title={geti18nText(buttoni18nText)}>
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {geti18nText(buttoni18nText)}
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <>{geti18nText(buttoni18nText)}</>
                                )}
                            </Button>
                            {customReports && customReports.length > 0 && (
                                <Dropdown
                                    overlay={() => reportMenu()}
                                    trigger={['click']}
                                    placement="topLeft"
                                    disabled={disabled}
                                >
                                    <Button style={{ padding: '3px', marginRight: '0rem' }} disabled={disabled}>
                                        <EllipsisOutlined style={{ fontSize: '16px', paddingTop: '2px' }} />
                                    </Button>
                                </Dropdown>
                            )}
                        </Col>
                    ) : (
                        <Button
                            icon={<FilePdfOutlined style={customIconStyle ?? iconStyle} />}
                            style={{ marginRight: '12px', float: 'left' }}
                            onClick={() => {
                                openReportModal(null);
                            }}
                            disabled={disabled}
                        >
                            {geti18nText(buttoni18nText)}
                        </Button>
                    )}

                    {showLink && (
                        <Col style={{ width: '100%', display: 'inline-flex' }}>
                            <Button
                                style={{ marginTop: '5px' }}
                                block
                                icon={<CopyOutlined style={customIconStyle ?? iconStyle} />}
                                onClick={(event: any) => {
                                    event.preventDefault();

                                    var inp = document.createElement('input');
                                    document.body.appendChild(inp);
                                    inp.value = `${window.location.host}/#/download?data=${btoa(
                                        JSON.stringify({ ...customParms, url: url })
                                    )}`;
                                    inp.select();
                                    document.execCommand('copy', false);
                                    inp.remove();
                                    message.success(geti18nText('app.default.message.copied'));
                                }}
                                disabled={disabled}
                            >
                                {showTooltip ? (
                                    <Tooltip title={geti18nText('app.default.button.copyLink')}>
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {geti18nText('app.default.button.copyLink')}
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <>{geti18nText('app.default.button.copyLink')}</>
                                )}
                            </Button>
                        </Col>
                    )}
                </>
            ) : (
                <>
                    <Tooltip placement="topRight" title={geti18nText(buttoni18nText)}>
                        <FilePdfOutlined
                            onClick={() => {
                                openReportModal(null);
                            }}
                            style={{ fontSize: '16px', paddingRight: '10px' }}
                        />
                    </Tooltip>
                    {customReports && customReports.length > 0 && (
                        <Tooltip placement="topRight" title={geti18nText(buttoni18nText)}>
                            <Dropdown overlay={() => reportMenu()} trigger={['click']}>
                                <EllipsisOutlined style={{ fontSize: '16px', paddingRight: '10px' }} />
                            </Dropdown>
                        </Tooltip>
                    )}
                </>
            )}
            <ReportExportType
                exportType={exportType}
                setExportType={setExportType}
                isModal={true}
                visible={reportModalVisible}
                setVisible={setReportModalVisible}
                onOk={downloadReport}
                onCancelExtraAction={() => setSelectedSubreport(null)}
                title={fileName}
            />
        </>
    );
};

export default NyReportButton;
