import { createSlice } from '@reduxjs/toolkit';

interface IHelperState {
    helperUrl: string | undefined;
}

const initialState: IHelperState = {
    helperUrl: undefined,
};

const helper = createSlice({
    name: 'helper',
    initialState,
    reducers: {
        helperInit(state) {
            state.helperUrl = undefined;
        },
        setHelperUrl(state, action) {
            state.helperUrl = action.payload;
        },
    },
});

export const { helperInit, setHelperUrl } = helper.actions;

export default helper.reducer;
