import { createSlice } from '@reduxjs/toolkit';
interface IWorkAttendanceState {
    workTimeRegistration: any;
}

const initialState: IWorkAttendanceState = {
    workTimeRegistration: null,
};

const workAttendance = createSlice({
    name: 'workAttendance',
    initialState,
    reducers: {
        workAttendanceInit(state) {
            state.workTimeRegistration = null;
        },
        saveUserWorkTimeRegistration(state, action) {
            state.workTimeRegistration = action.payload;
        },
    },
});

export const { workAttendanceInit, saveUserWorkTimeRegistration } = workAttendance.actions;

export default workAttendance.reducer;
