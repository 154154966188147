import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import ContractTypeGroupIndex from '.';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import ContractTypeGroupEdit from './edit';

const ContractTypeGroupSearch = ({
    label = geti18nText('contract.type.edit.contractTypeGroup'),
    name = 'contractTypeGroup',
    required = false,
    disabled = false,
    onChange = null,
    mustGetPopupContainer = true,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.CONTRACT_TYPE_GROUP.SEARCH}
                map={{
                    id: 'id',
                    label: 'text',
                    text: 'name',
                    name: 'name',
                }}
                searchBy="name"
                order="name"
                SearchPopupComponent={<ContractTypeGroupIndex />}
                AddNewModalComponent={canCreate() ? ContractTypeGroupEdit : null}
                nyTestId="contractTypeGroup"
                disabled={disabled}
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                onChange={onChange}
                itemName={'name'}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default ContractTypeGroupSearch;
