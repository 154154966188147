import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Alert, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { numberFormat } from '../../utils/Utils';
import { wageIconSmall } from '../travel-warrant-icons';
import TravelWarrantWageSummaryIndexTable from './recapitulationTable';
import TravelWarrantWageSummaryIndexTotals from './recapitulationTotals';

const TravelWarrantWageSummaryIndex = ({
    travelWarrantId,
    refreshWageSummary,
    setRefreshWageSummary,
    printPdf = false,
    setRecapitulationDataLoaded,
    showRecapitulationTable = true,
    showIsDirtyWage = false,
}: any) => {
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [loading, setLoading] = useState<boolean>(false);
    const [recapitulationData, setRecapitulationData] = useState<any>(undefined);

    useEffect(() => {
        getRecapitulationData(travelWarrantId);
    }, [travelWarrantId, refreshWageSummary]);

    const getRecapitulationData = (travelWarrantId: any) => {
        setLoading(true);
        setRecapitulationData(undefined);
        if (travelWarrantId) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.TRAVEL_WARRANT.GET_RECAPITULATION_DATA + '/' + travelWarrantId
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setRecapitulationData(setValues(result.data));
                    if (setRecapitulationDataLoaded) {
                        setRecapitulationDataLoaded(true);
                    }
                }
                setLoading(false);
            });
        }
    };

    function setValues(values: any) {
        if (values?.currency?.id) {
            values.currency = { id: values.currency.id, text: values.currency.isoCode };
        } else {
            values.currency = { id: 0, text: defaultCurrencyIsoCode };
        }

        if (values?.travelWarrantPayments?.length > 0) {
            values.travelWarrantPaymentsAll = values.travelWarrantPayments;
            values.travelWarrantPayments = values.travelWarrantPayments.filter(
                (payment: any) => payment.calculated && payment.active
            );
        }
        return values;
    }

    const getExchangeRateFromTotalsType = (type: any) => {
        const totals: any = recapitulationData?.travelWarrant?.travelWarrantTotalsList;
        const travelType: any = recapitulationData?.travelWarrant?.travelWarrantType;
        if (totals?.length > 0) {
            let items;
            if (travelType?.payoutByAnotherOrganization) {
                items = totals.filter((item: any) => item.totalType === type && !item.taxable);
            } else {
                items = totals.filter((item: any) => item.totalType === type);
            }
            const value = items.map((item: any) => {
                return (
                    <>
                        {item && item.exchangeRate ? numberFormat(item.exchangeRate, 6) : numberFormat(1, 6)}
                        <br />
                    </>
                );
            });
            return value && value.length > 0 ? value : <span>{numberFormat(1, 6)}</span>;
        }
        return <span>{numberFormat(1, 6)}</span>;
    };

    const getTotalFromType = (type: any, showCurrency: boolean, inCurrency: boolean = false) => {
        const totals: any = recapitulationData?.travelWarrant?.travelWarrantTotalsList;
        const travelType: any = recapitulationData?.travelWarrant?.travelWarrantType;
        if (totals?.length > 0) {
            if (travelType?.payoutByAnotherOrganization) {
                const items = totals.filter((item: any) => item.totalType === type && !item.taxable);
                const value = items.map((item: any) => {
                    return (
                        <span>
                            {item && (inCurrency ? item.totalValueInCurrency : item.totalValue)
                                ? numberFormat(inCurrency ? item.totalValueInCurrency : item.totalValue)
                                : '0,00'}{' '}
                            {!showCurrency
                                ? ''
                                : item && item.currency && item.currency.isoCode && inCurrency
                                ? item.currency.isoCode
                                : defaultCurrencyIsoCode}
                            <br />
                        </span>
                    );
                });
                return value && value.length > 0 ? (
                    value
                ) : (
                    <span>
                        {'0,00'} {!showCurrency ? '' : defaultCurrencyIsoCode}
                    </span>
                );
            } else {
                const items = totals.filter((item: any) => item.totalType === type);
                const value = items.map((item: any) => {
                    return (
                        <>
                            <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div>{item && item.taxable && wageIconSmall}</div>
                                <>
                                    {item && (inCurrency ? item.totalValueInCurrency : item.totalValue)
                                        ? numberFormat(inCurrency ? item.totalValueInCurrency : item.totalValue)
                                        : '0,00'}{' '}
                                    {!showCurrency
                                        ? ''
                                        : item && item.currency && item.currency.isoCode && inCurrency
                                        ? item.currency.isoCode
                                        : defaultCurrencyIsoCode}
                                </>
                                <br />
                            </span>
                        </>
                    );
                });
                return value && value.length > 0 ? (
                    <> {value}</>
                ) : (
                    <span>
                        {'0,00'} {!showCurrency ? '' : defaultCurrencyIsoCode}
                    </span>
                );
            }
        }

        return (
            <span>
                {'0,00'} {!showCurrency ? '' : defaultCurrencyIsoCode}
            </span>
        );
    };

    return (
        <React.Fragment>
            <Row gutter={24}>
                <>
                    {!loading ? (
                        <Col span={24}>
                            <TravelWarrantWageSummaryIndexTable
                                recapitulationData={recapitulationData}
                                printPdf={printPdf}
                                refreshWageSummary={refreshWageSummary}
                                setRefreshWageSummary={setRefreshWageSummary}
                                getTotalFromType={getTotalFromType}
                                getExchangeRateFromTotalsType={getExchangeRateFromTotalsType}
                                loading={loading}
                                showRecapitulationTable={showRecapitulationTable}
                                showIsDirtyWage={showIsDirtyWage}
                            />
                            <TravelWarrantWageSummaryIndexTotals
                                recapitulationData={recapitulationData}
                                printPdf={printPdf}
                                getTotalFromType={getTotalFromType}
                                getExchangeRateFromTotalsType={getExchangeRateFromTotalsType}
                                showRecapitulationTable={showRecapitulationTable}
                            />

                            <p>
                                <Alert
                                    message={geti18nText('travelWarrantRecapitulation.table.taxable.wage.info')}
                                    type="info"
                                    showIcon
                                    style={{ margin: '15px 5px 0 5px' }}
                                />
                            </p>
                        </Col>
                    ) : (
                        <Col span={24}>
                            <NySpinner />
                        </Col>
                    )}
                </>
            </Row>
        </React.Fragment>
    );
};

export default TravelWarrantWageSummaryIndex;
