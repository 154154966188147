import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import EducationInstitutionIndex from '.';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import EducationInstitutionEdit from './edit';

const EducationInstitutionSearch = ({
    label = geti18nText('employee.education.edit.educationInstitution'),
    name = 'educationInstitution',
    required = false,
    onChange,
    autoFocus,
    disabled = false,
    setDefaultFilterValue,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateEducationCodebooks();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.EDUCATION_INSTITUTION.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                SearchPopupComponent={<EducationInstitutionIndex searchComponent />}
                AddNewModalComponent={canCreate() ? EducationInstitutionEdit : null}
                nyTestId="educationInstitution"
                onChange={onChange}
                autoFocus={autoFocus}
                disabled={disabled}
                setDefaultFilterValue={setDefaultFilterValue ? setDefaultFilterValue : null}
                mustGetPopupContainer={false}
            />
        </Form.Item>
    );
};

export default EducationInstitutionSearch;
