import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySession, NyUtils, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CompanySearch from '../company/search';
import CurrencySearch from '../currency/search';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { formatCurrencyIsoCodeSearch, okNotification, errorNotification } from '../../../../utils/Utils';
import { oibCheck } from '../../../../utils/Validation';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import { AdministrationRights } from '../../../../rights/administrationRights';

const { Panel } = Collapse;

const CompanyInformationIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();
    const history = useHistory();

    const [dataForm, setDataForm] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});
    const [companyId, setCompanyId] = useState<any>(undefined);

    const [fileListAppLogo, setFileListAppLogo] = useState<any>([]);
    const [fileListAppFavicon, setFileListAppFavicon] = useState<any>([]);

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['companyInformation'] = isChanged;
        setDirty(tmpDirty);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'COMPANY_INFO' }];
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    setDefaultData(result.data);
                }
            }
        });
    };

    const formatCompanySearch = async function (id: any) {
        let temp;
        await NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EDIT + '/' + id, {}).then((result: any) => {
            if (result.data.id) {
                temp = { id: result.data['id'], name: result.data['name'] };
            } else {
                temp = { id: 0, name: id };
            }
        });
        return temp;
    };

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setDataForm(dataForm);

        const companyName = dataForm.find((item: any) => item.settingKey === 'COMPANY_NAME')?.value;
        const companyOib = dataForm.find((item: any) => item.settingKey === 'COMPANY_OIB')?.value;
        const companyVatNo = dataForm.find((item: any) => item.settingKey === 'COMPANY_VAT_NO')?.value;
        const companyAddress = dataForm.find((item: any) => item.settingKey === 'COMPANY_ADDRESS')?.value;
        const companyMb = dataForm.find((item: any) => item.settingKey === 'COMPANY_MB')?.value;
        const companyMbs = dataForm.find((item: any) => item.settingKey === 'COMPANY_MBS')?.value;
        const companyNkd = dataForm.find((item: any) => item.settingKey === 'COMPANY_NKD')?.value;
        const companyActivityCode = dataForm.find((item: any) => item.settingKey === 'COMPANY_ACTIVITY_CODE')?.value;
        const companyRetirementInsurance = dataForm.find(
            (item: any) => item.settingKey === 'COMPANY_RETIREMENT_INSURANCE_LIABILITY_ID'
        )?.value;
        const companyHealthInsurance = dataForm.find(
            (item: any) => item.settingKey === 'COMPANY_HEALTH_INSURANCE_LIABILITY_ID'
        )?.value;

        const defaultCurrencyIsoCode = dataForm.find(
            (item: any) => item.settingKey === 'DEFAULT_CURRENCY_ISO_CODE'
        )?.value;
        if (defaultCurrencyIsoCode)
            newDataForm.DEFAULT_CURRENCY_ISO_CODE = await formatCurrencyIsoCodeSearch(defaultCurrencyIsoCode);

        const alternativeCurrencyIsoCode = dataForm.find(
            (item: any) => item.settingKey === 'ALTERNATIVE_CURRENCY_ISO_CODE'
        )?.value;
        if (alternativeCurrencyIsoCode)
            newDataForm.ALTERNATIVE_CURRENCY_ISO_CODE = await formatCurrencyIsoCodeSearch(alternativeCurrencyIsoCode);

        const companyId = dataForm.find((item: any) => item.settingKey === 'COMPANY_ID')?.value;
        if (companyId) {
            newDataForm.COMPANY_ID = await formatCompanySearch(companyId);
            setCompanyId(newDataForm.COMPANY_ID);
        }
        const appLogo = dataForm.find((item: any) => item.settingKey === 'DEFAULT_APP_LOGO')?.value;
        const appFavicon = dataForm.find((item: any) => item.settingKey === 'DEFAULT_APP_FAVICON')?.value;

        if (appLogo) {
            const filesAppLogoImage = [
                {
                    id: appLogo,
                    uid: appLogo,
                    name: appLogo,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        appLogo +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileListAppLogo(filesAppLogoImage);
        }
        if (appFavicon) {
            const filesAppFaviconImage = [
                {
                    id: appFavicon,
                    uid: appFavicon,
                    name: appFavicon,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        appFavicon +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileListAppFavicon(filesAppFaviconImage);
        }

        if (companyName) newDataForm.COMPANY_NAME = companyName;
        if (companyOib) newDataForm.COMPANY_OIB = companyOib;
        if (companyVatNo) newDataForm.COMPANY_VAT_NO = companyVatNo;
        if (companyAddress) newDataForm.COMPANY_ADDRESS = companyAddress;
        if (companyMb) newDataForm.COMPANY_MB = companyMb;
        if (companyMbs) newDataForm.COMPANY_MBS = companyMbs;
        if (companyNkd) newDataForm.COMPANY_NKD = companyNkd;
        if (companyActivityCode) newDataForm.COMPANY_ACTIVITY_CODE = companyActivityCode;
        if (companyRetirementInsurance)
            newDataForm.COMPANY_RETIREMENT_INSURANCE_LIABILITY_ID = companyRetirementInsurance;
        if (companyHealthInsurance) newDataForm.COMPANY_HEALTH_INSURANCE_LIABILITY_ID = companyHealthInsurance;
        if (appLogo) newDataForm.DEFAULT_APP_LOGO = appLogo;
        if (appFavicon) newDataForm.DEFAULT_APP_FAVICON = appFavicon;

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);

                    if (value != undefined && value != null && item?.id != undefined) {
                        let val: any = value;
                        if (val.constructor.name === 'Object') {
                            if (val.isoCode) {
                                params.push({ id: item.id, value: val.isoCode });
                            } else if (val.id >= 0) {
                                params.push({ id: item.id, value: val.id });
                            } else {
                                params.push({ id: item.id, value: '' });
                            }
                        } else {
                            params.push({ id: item.id, value: value });
                        }
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'username_exist':
                                        errorNotification(geti18nText('app.default.username_exist'));
                                        return;
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(geti18nText(result.data.error));
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const validateOib = (rule: any, value: any, callback: any) => {
        if (value != undefined && value != '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.oib.not.correct'));
            }
        } else {
            callback();
        }
    };

    const validateLocalVatNumber = (rule: any, value: any, callback: any) => {
        if (value != undefined && value != '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('company.edit.vat.not.correct'));
            }
        } else {
            callback();
        }
    };

    const openSelectedCompany = () => {
        let temp = form.getFieldValue('COMPANY_ID');
        if (temp && temp.id) {
            history.push('/administration/codebooks/company/' + temp.id);
        }
    };

    const canEditCompany = () => {
        return AdministrationRights.canCreateCompanySettings();
    };

    const saveFaviconFile = (value: any = null) => {
        form.setFieldsValue({ DEFAULT_APP_FAVICON: value });
    };

    const saveLogoFile = (value: any = null) => {
        form.setFieldsValue({ DEFAULT_APP_LOGO: value });
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.companyInformation')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <CompanySearch
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY')}
                                        formName={'COMPANY_ID'}
                                        onChange={(e: any) => setCompanyId(e)}
                                        isModal={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    {canEditCompany() && (
                                        <Button
                                            style={{ marginTop: '24px' }}
                                            type="primary"
                                            disabled={!companyId || !companyId.id || companyId.id <= 0}
                                            onClick={(e) => openSelectedCompany()}
                                        >
                                            {geti18nText('app.default.button.open')}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_NAME')}
                                        name="COMPANY_NAME"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_ADDRESS'
                                        )}
                                        name="COMPANY_ADDRESS"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_OIB')}
                                        name="COMPANY_OIB"
                                        rules={[
                                            {
                                                validator: validateOib,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_VAT_NO'
                                        )}
                                        name="COMPANY_VAT_NO"
                                        rules={[
                                            {
                                                validator: validateLocalVatNumber,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_MB')}
                                        name="COMPANY_MB"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_MBS')}
                                        name="COMPANY_MBS"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_NKD')}
                                        name="COMPANY_NKD"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_ACTIVITY_CODE'
                                        )}
                                        name="COMPANY_ACTIVITY_CODE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.generalData')} key="2">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_COMMON.GENERAL.DEFAULT_APP_LOGO')}
                                        name="DEFAULT_APP_LOGO"
                                    >
                                        {canCreate() && (
                                            <NyImageUpload
                                                files={fileListAppLogo}
                                                setFiles={setFileListAppLogo}
                                                saveOnUpload={true}
                                                customAction={saveLogoFile}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.GENERAL.DEFAULT_APP_FAVICON'
                                        )}
                                        name="DEFAULT_APP_FAVICON"
                                    >
                                        {canCreate() && (
                                            <NyImageUpload
                                                files={fileListAppFavicon}
                                                setFiles={setFileListAppFavicon}
                                                saveOnUpload={true}
                                                customAction={saveFaviconFile}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_RETIREMENT_INSURANCE_LIABILITY_ID'
                                        )}
                                        name="COMPANY_RETIREMENT_INSURANCE_LIABILITY_ID"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.COMPANY_INFO.COMPANY_HEALTH_INSURANCE_LIABILITY_ID'
                                        )}
                                        name="COMPANY_HEALTH_INSURANCE_LIABILITY_ID"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <CurrencySearch
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.GENERAL.DEFAULT_CURRENCY_ISO_CODE'
                                        )}
                                        name="DEFAULT_CURRENCY_ISO_CODE"
                                        editable={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <CurrencySearch
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.COMPANY_INFO.ALTERNATIVE_CURRENCY_ISO_CODE'
                                        )}
                                        name="ALTERNATIVE_CURRENCY_ISO_CODE"
                                        readonly={true}
                                        nyTestId="post-office"
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    {canCreate() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default CompanyInformationIndex;
