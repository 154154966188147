import { PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TravelWarrantMap from './TravelWarrantMap';

const TravelWarrantMapModal = ({
    form,
    waypoints,
    setWaypoints,
    maxWaypointsKey,
    setMaxWaypointsKey,
    destination = '',
    setDestination,
    setTravelWarrantPlans,
    modalHeader,
    defaultWaypoints,
    isPlane = false,
    showInfoMessage = false,
    isWizard = false,
    getDefaultStartingPoint,
    showCheckboxesDestinationName = false,
    initDestinationNameValues,
    setInitWaypoints,
    returnWaypoints,
    setReturnWaypoints,
    returnRoute = false,
    returnIntermediateWaypointsInit,
}: any) => {
    const [mapModalVisible, setMapModalVisible] = useState(false);
    const [formMap] = Form.useForm();
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);

    const [waypointsMap, setWaypointsMap] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);

    useEffect(() => {
        if (mapModalVisible) {
            setWaypointsMap(_.cloneDeep(waypoints));
        }
    }, [waypoints]);

    const openMapModal = () => {
        setWaypointsMap(_.cloneDeep(waypoints));
        setMapModalVisible(true);
    };

    const saveMap = () => {
        formMap.validateFields().then((values: any) => {
            let waypointsList = waypointsMap.filter(
                (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
            );
            setWaypoints(waypointsList);
            setWaypointsMap(waypointsList);
            const first = waypointsList.at(0);
            const last = waypointsList.at(-1);

            const isFirstNotEmpty = first && first.placeId != undefined;

            let name: any = '';

            if (isFirstNotEmpty && last) {
                waypointsList.map((item: any) => {
                    const isLast = item.id == last.id;

                    if (
                        item.id != 0 &&
                        (isLast ||
                            (!isLast &&
                                (!showCheckboxesDestinationName ||
                                    (showCheckboxesDestinationName && item.showDestinationName))))
                    ) {
                        if (item.city != item.shortName) {
                            let city = item.city ? item.city : '';
                            let short = item.shortName ? item.shortName : '';
                            name += city != '' ? city + ' - ' + short : short;
                        } else {
                            name += item.city;
                        }

                        if (!isLast) {
                            name += ', ';
                        }
                    }
                });
            }
            if (isFirstNotEmpty && name == '') {
                name = values.name;
            }

            setDestination(name);
            form.setFieldsValue({ destination: name });
            setMapModalVisible(false);
            if (waypointsList != undefined && waypointsList.length > 0) {
                waypointsList.map(async (element: any) => {
                    if (element.countryCode) {
                        let filter = encodeURI(
                            JSON.stringify([
                                { field: 'active', condition: 'equals_bool', value: 1 },
                                { field: 'isoCode', condition: 'equals', value: element.countryCode.toUpperCase() },
                            ])
                        );
                        const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.COUNTRY.LIST, {
                            max: 1,
                            search: filter,
                        });
                        if (result.status === RESPONSE.OK) {
                            if (result.data && result.data.content) {
                                element.countryId = result.data.content[0].id;
                            }
                        }
                    }
                });
                setTravelWarrantPlans(waypointsList);
            }
        });
    };

    const closeModal = () => {
        formMap.resetFields();
        setMapModalVisible(false);
        setMaxWaypointsKey(2);
    };

    const getCustomFooterContent = (
        <div>
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button key={'print'} icon={<PrinterOutlined />}>
                            {geti18nText('travelWarrant.action.pdf.short')}
                        </Button>
                    );
                }}
                onBeforeGetContent={() => {
                    setPrintPdf(true);
                    return Promise.resolve();
                }}
                onAfterPrint={() => {
                    setPrintPdf(false);
                }}
                content={() => componentRef.current}
                pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
            />
            <Button onClick={closeModal}>{geti18nText('app.default.button.cancel')}</Button>
            {getDefaultStartingPoint && (
                <Button onClick={getDefaultStartingPoint}>
                    {geti18nText('travel.warrant.edit.map.reset.waypoints')}
                </Button>
            )}
            <Button
                type="primary"
                onClick={saveMap}
                disabled={
                    waypointsMap.filter(
                        (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
                    ).length < 2
                }
            >
                {geti18nText('app.default.button.save')}
            </Button>
        </div>
    );

    return (
        <React.Fragment>
            <Button onClick={openMapModal} icon={<PlusOutlined />}></Button>
            {mapModalVisible && (
                <Modal
                    width={900}
                    title={modalHeader}
                    visible={mapModalVisible}
                    onOk={saveMap}
                    onCancel={closeModal}
                    maskClosable={false}
                    footer={getCustomFooterContent}
                >
                    <Form form={formMap}>
                        <Row
                            gutter={24}
                            style={{ marginBottom: '-34px', maxHeight: '75vh', overflowY: 'auto', overflowX: 'hidden' }}
                        >
                            <Col span={24}>
                                {showInfoMessage && (
                                    <Alert
                                        message={geti18nText('travelWarrantRelation.info')}
                                        type="info"
                                        showIcon
                                        style={{ marginBottom: '5px' }}
                                    />
                                )}
                                <Form.Item label={modalHeader} name="name" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <TravelWarrantMap
                                        showMap={true}
                                        form={formMap}
                                        waypoints={waypointsMap}
                                        setWaypoints={setWaypointsMap}
                                        maxWaypointsKey={maxWaypointsKey}
                                        setMaxWaypointsKey={setMaxWaypointsKey}
                                        nyTestId="map"
                                        isPlane={isPlane}
                                        componentRefProp={componentRef}
                                        printPdfProp={printPdf}
                                        setPrintPdfProp={setPrintPdf}
                                        printPdfHeader={modalHeader}
                                        hidePrint={true}
                                        isWizard={isWizard}
                                        showCheckboxesDestinationName={showCheckboxesDestinationName}
                                        initDestinationNameValues={initDestinationNameValues}
                                        destination={destination}
                                        setInitWaypoints={setInitWaypoints}
                                        returnWaypoints={returnWaypoints}
                                        setReturnWaypoints={setReturnWaypoints}
                                        returnRoute={returnRoute}
                                        returnIntermediateWaypointsInit={returnIntermediateWaypointsInit}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default TravelWarrantMapModal;
