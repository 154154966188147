import { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'antd';
import { NyInputNumber, geti18nText } from '@nybble/nyreact';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L, { LatLngLiteral } from 'leaflet';
import { ILocationPicker } from './types';
import { ReloadOutlined } from '@ant-design/icons';

const NyLocationPicker = ({
    location,
    setLocation,
    setLocationValues,
    lngName = 'lng',
    latName = 'lat',
}: ILocationPicker) => {
    const [map, setMap] = useState<any>(null);
    const defaultLocationValue: LatLngLiteral = { lat: 45.75219336063106, lng: 15.952148437500002 };

    const blueIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    function LocationMarker() {
        useMapEvents({
            click(e: any) {
                if (setLocation) {
                    setLocation(e?.latlng);
                }
                if (setLocationValues) {
                    setLocationValues({ lat: e?.latlng.lat, lng: e?.latlng?.lng });
                }
            },
        });

        return location ? <Marker position={location} icon={blueIcon}></Marker> : null;
    }

    const mapOptions = {
        center: [45.75219336063106, 15.952148437500002],
        zoom: 7,
        style: { height: '240px', width: '100%' },
        doubleClickZoom: false,
        trackResize: false,
        zoomControl: false,
        touchZoom: true,
        keyboard: false,
        gestureHandling: true,
        attributionControl: false,
    };

    useEffect(() => {
        if (location && map) {
            map.flyTo(location, map.getZoom());
        }
    }, [location]);

    return (
        <>
            <Col span={24}>
                <Row gutter={24}>
                    <Col span={12} style={{ display: 'inline-flex' }}>
                        <Form.Item
                            style={{ width: '100%' }}
                            label={geti18nText('location.edit.longitude')}
                            name={lngName}
                        >
                            <NyInputNumber
                                isDecimal={true}
                                decimalPlaces={9}
                                max={180}
                                min={-180}
                                style={{ width: '100%' }}
                                onChange={(value: number) => {
                                    location && setLocation({ lat: location.lat, lng: value });
                                }}
                            />
                        </Form.Item>
                        <Button
                            style={{ marginTop: '24px' }}
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                location && setLocation({ lat: location.lat, lng: defaultLocationValue.lng });
                                location &&
                                    setLocationValues &&
                                    setLocationValues({ lat: location.lat, lng: defaultLocationValue.lng });
                            }}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: '12px', display: 'inline-flex' }}>
                        <Form.Item
                            style={{ width: '100%' }}
                            label={geti18nText('location.edit.latitude')}
                            name={latName}
                        >
                            <NyInputNumber
                                isDecimal={true}
                                decimalPlaces={9}
                                max={90}
                                min={-90}
                                style={{ width: '100%' }}
                                onChange={(value: number) => {
                                    location && setLocation({ lat: value, lng: location.lng });
                                }}
                            />
                        </Form.Item>
                        <Button
                            style={{ marginTop: '24px' }}
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                location && setLocation({ lat: defaultLocationValue.lat, lng: location.lng });
                                location &&
                                    setLocationValues &&
                                    setLocationValues({ lat: defaultLocationValue.lat, lng: location.lng });
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <MapContainer {...(mapOptions as any)} whenCreated={setMap}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <LocationMarker />
                        </MapContainer>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default NyLocationPicker;
