import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Badge, Button, Empty, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { fileDownload, getCanCreateContract, getUrlContract } from '../../../../../utils/Utils';
import ContractTypeEdit from './edit';

const ContractTypeIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    filter = undefined,
    annex = false,
    contractTypeId = null,
    mark = 'HR',
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const [refresh, setRefresh] = useState<any>(0);
    const [url, setUrl] = useState<any>(undefined);
    const [canCreate, setCanCreate] = useState(false);

    useHelper('human/codebooks/contracts/contract-type');

    useEffect(() => {
        getUrl();
    }, [mark]);

    const getUrl = async () => {
        getUrlContract(setUrl, mark, CONSTANTS_REQ.CONTRACT_TYPE.LIST, CONSTANTS_REQ.CONTRACT_TYPE.LIST_MARK);
    };

    useEffect(() => {
        getCanCreate();
    }, []);

    const getCanCreate = async () => {
        getCanCreateContract(setCanCreate, mark, 'HUMAN_RESOURCES');
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const setDefaultFilterValue = () => {
        if (filter) {
            return filter;
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'annex', condition: 'equals_bool', value: 0 },
            ];
        }
    };

    const openContracts = (contractType: any) => {
        history.push({
            pathname: '/human/contracts/contractCTR',
            state: {
                contractType: contractType,
            },
        });
    };

    const gridStyleIndex: any = {
        width: '30%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '5px',
        height: '100%',
        display: 'inline-block',
    };

    const getCounts = (record: any) => (
        <div style={{ maxWidth: '200px' }}>
            <div style={gridStyleIndex}>
                <Tooltip title={geti18nText('contract.type.edit.contractCount')}>
                    <Badge count={record.contractCount} showZero size="small">
                        <i
                            onClick={() => openContracts(record)}
                            style={{ fontSize: '2em', marginLeft: '-3px', marginTop: '-2px' }}
                            className={`las la-file-signature statistic-card-light`}
                        ></i>
                    </Badge>
                </Tooltip>
            </div>
            <div style={gridStyleIndex}>
                <Tooltip title={geti18nText('contract.type.edit.differentDocumentTemplateContractCount')}>
                    <Badge count={record.differentDocumentTemplateContractCount} showZero size="small">
                        <i
                            style={{ fontSize: '2em', marginLeft: '-3px', marginTop: '-2px' }}
                            className={`las la-file-archive statistic-card-light`}
                        ></i>
                    </Badge>
                </Tooltip>
            </div>
            <div style={gridStyleIndex}>
                <Tooltip title={geti18nText('contract.type.edit.differentMetadataVersionContractCount')}>
                    <Badge count={record.differentMetadataVersionContractCount} showZero size="small">
                        <i
                            style={{ fontSize: '2em', marginLeft: '-3px', marginTop: '-2px' }}
                            className={`las la-file-medical-alt statistic-card-light`}
                        ></i>
                    </Badge>
                </Tooltip>
            </div>
        </div>
    );

    const columns = [
        ...[
            {
                title: geti18nText('contract.type.table.column.id'),
                dataIndex: 'id',
                width: '5%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('contract.type.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(!annex
            ? [
                  {
                      title: geti18nText('contract.type.table.column.contractTypeGroup'),
                      dataIndex: ['contractTypeGroup', 'name'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.CONTRACT_TYPE_GROUP.SEARCH,
                          'contractTypeGroup.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          }
                      ),
                  },
                  {
                      title: geti18nText('contract.type.table.column.contractCount'),
                      dataIndex: 'contractCount',
                      width: '15%',
                      render: (text: any, record: any) => {
                          return getCounts(record);
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('contract.type.table.column.active'),
                dataIndex: 'active',
                width: '7%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(),
            },
            {
                title: geti18nText('app.default.actions'),
                dataIndex: 'actions',
                width: '8%',
                render: (text: any, record: any) => {
                    let uploadedFileId = record?.documentTemplate?.fileId;
                    let uploadedFileName = record?.documentTemplate?.name;
                    if (uploadedFileId) {
                        return (
                            <Tooltip title={geti18nText('contract.edit.download')}>
                                <Button
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + uploadedFileId,
                                            undefined,
                                            uploadedFileName
                                        );
                                    }}
                                >
                                    {geti18nText('app.default.download')}
                                </Button>
                            </Tooltip>
                        );
                    }
                },
            },
        ],
    ];

    return (
        <>
            {url ? (
                <NyDataTable
                    nyId="human-contract-contract-type-table"
                    url={url}
                    addNewButtonText={
                        annex
                            ? geti18nText('contract.type.table.add.annex.type')
                            : geti18nText('contract.type.table.add')
                    }
                    buttonsClassName="buttons-sticky"
                    showRecordModal={true}
                    modalComponent={ContractTypeEdit}
                    editProps={{
                        annex: annex,
                        contractTypeId: contractTypeId,
                        setRefresh: setRefresh,
                        refresh: refresh,
                        mark: mark,
                    }}
                    fetchWhenChange={refresh}
                    columns={columns}
                    setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                    scroll={scroll}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    hideNewButton={!canCreate}
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    shortcuts={true}
                    exportCSV={canExportCSV()}
                    CSVFileName={
                        annex ? geti18nText('contract.type.table.add.annex.type') : geti18nText('menu.contract.type')
                    }
                    colCSVCustomization={csvColumnCustomisation()}
                    headerTitle={!annex && geti18nText('menu.contract.type')}
                />
            ) : (
                <Empty />
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ContractTypeIndex;
