import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getDateFormat, getEnumArray, numberFormat } from '../../../../utils/Utils';
import PayrollBrutAdditionEdit from './PayrollBrutAdditionEdit';
import PayrollBrutEdit from './PayrollBrutEdit';
import { PayrollRights } from '../../../../rights/payrollRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const PayrollBrutTable = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    addition = false,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('payroll/codebooks/brut');
    const [refreshTable, setRefreshTable] = useState(1);

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks() || HumanResourcesRights.canCreateCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'addition', condition: 'equals_bool', value: addition ? 1 : 0 },
        ];
    };

    const columnsBrut = [
        {
            title: geti18nText('payrollBrut.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payrollBrut.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payrollBrut.table.column.coefficient'),
            dataIndex: 'coefficient',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (coefficient: any) => {
                return numberFormat(coefficient);
            },
        },
        {
            title: geti18nText('payrollBrut.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    const columnsAddition = [
        {
            title: geti18nText('payrollBrut.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payrollBrut.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payrollBrut.table.column.validFrom'),
            dataIndex: 'validFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (validFrom: any, record: any) => {
                if (record.validFrom) {
                    return getDateFormat(record.validFrom, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payrollBrut.table.column.validTo'),
            dataIndex: 'validTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (validTo: any, record: any) => {
                if (record.validTo) {
                    return getDateFormat(record.validTo, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payrollBrut.table.column.valueType'),
            dataIndex: 'valueType',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.valueType !== undefined) {
                    return geti18nText('app.enum.PAYROLL_BRUT_VALUE_TYPE.' + record.valueType);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('PAYROLL_BRUT_VALUE_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('payrollBrut.table.column.value'),
            dataIndex: 'value',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payrollBrut.table.column.baseType'),
            dataIndex: 'baseType',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.baseType !== undefined) {
                    return geti18nText('app.enum.PAYROLL_BRUT_BASE_TYPE.' + record.baseType);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('PAYROLL_BRUT_BASE_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('payrollBrut.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <NyDataTable
            nyId="human-payroll-brut-table"
            url={CONSTANTS_REQ.PAYROLL_BRUT.LIST}
            addNewButtonText={
                addition ? geti18nText('payrollBrut.addition.table.add') : geti18nText('payrollBrut.table.add')
            }
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={addition ? PayrollBrutAdditionEdit : PayrollBrutEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={addition ? columnsAddition : columnsBrut}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            fetchWhenChange={refreshTable}
            editProps={{
                setRefreshTable: setRefreshTable,
                refreshTable: refreshTable,
            }}
        />
    );
};

export default PayrollBrutTable;
