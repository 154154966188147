import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import moment from 'moment';
import { useSelector } from 'react-redux';
import RowAction from '../../../../../components/row-action';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_PAGE_SIZE } from '../../../../../utils/Constants';
import { getOrderStatusIcon } from '../../../../../utils/Utils';
import ServicesOrderEdit from './edit';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { ServicesRights } from '../../../../../rights/servicesRights';

const ServicesOrderApproval = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const table = useTableSettings();
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);

    const canCreate = () => {
        return false;
    };

    const canExportCSV = () => {
        return ServicesRights.canCsvExportCodebooks();
    };

    let setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    const initialColumns = [
        {
            title: geti18nText('servicesOrder.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('servicesOrder.table.column.ord'),
            dataIndex: [`concat(_so.ord, '/', date_part('year', _so.date))`],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('servicesOrder.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.firstName;
                }
            },
        },
        {
            title: geti18nText('servicesOrder.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.orderView'),
            dataIndex: ['orderView', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ORDER_VIEW.SEARCH, 'orderView.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('servicesOrder.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            render: (status: any) => {
                return getOrderStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'ServicesOrder'} record={record}></RowAction>;
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="webshop-services-other-services-approveal"
            url={CONSTANTS_REQ.SERVICES_ORDER.LIST_REQUESTED}
            addNewButtonText={geti18nText('servicesOrder.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={ServicesOrderEdit}
            columns={initialColumns}
            setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
            scroll={scroll}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            fetchWhenChange={refreshItem}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.webshopServices')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            date: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY HH:mm');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + value);
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('servicesOrder.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('servicesOrder.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('servicesOrder.table.column.firstName'),
            dataIndex: ['employee', 'person', 'firstName'],
        },
        {
            title: geti18nText('servicesOrder.table.column.lastName'),
            dataIndex: ['employee', 'person', 'lastName'],
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('inventoryOrder.table.column.orderView'),
            dataIndex: ['orderView', 'name'],
        },
        {
            title: geti18nText('servicesOrder.table.column.date'),
            dataIndex: 'date',
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
        },
    ];
};

export default ServicesOrderApproval;
