import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    FormOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Alert, Button, Checkbox, Col, Form, Modal, Popover, Row, Tag, Tooltip } from 'antd';
import type { BaseSelectRef } from 'rc-select';
import { useRef, useState } from 'react';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookUserPersonWithAlias } from '../../../../utils/Filters';
import { errorNotification, getDateFormat, getEnumArray, okNotification } from '../../../../utils/Utils';
import DepartmentSearch from '../defense-department/department/search';
import SectionSearch from '../defense-department/section/search';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';
import ScheduleSuggestionsEdit from './edit';

const ScheduleSuggestionsIndex = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const militaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const militaryWorkApprovalStatus = useEnum('MILITARY_WORK_APPROVAL_STATUS');
    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const [departmentId, setDepartmentId] = useState<any>([]);
    const [defenseSectionId, setDefenseSectionId] = useState<any>([]);
    const focusInput = useRef<BaseSelectRef>(null);
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    //morh  modal
    const [openModalGenereateApprovalForMorh, setOpenModalGenereateApprovalForMorh] = useState<any>(false);
    const [saveLoadingPlanToMorh, setSaveLoadingPlanToMorh] = useState<any>(false);
    const [formMorh] = Form.useForm();
    //Governor modal
    const [openModalGenerateForGovernor, setOpenModalGenerateForGovernor] = useState<any>(false);
    const [saveLoadingPlanToGovernor, setSaveLoadingPlanToGovernor] = useState<any>(false);
    const [formGovernor] = Form.useForm();

    setTimeout(() => {
        if (focusInput.current) {
            focusInput.current.focus();
        }
    });

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const onAction = (record: any) => {
        let data = {
            active: !record.active,
        };
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.MILITARY_WORK_APPROVAL.ACTIVATE_STATUS + '/' + record.id,
            undefined,
            data
        ).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'));
                setRefreshTable((refresh: any) => refresh + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getEnumMilitaryTypeArray = function () {
        return [
            { id: 1, text: geti18nText('app.enum.MILITARY.TYPE.1') },
            { id: 2, text: geti18nText('app.enum.MILITARY.TYPE.2') },
        ];
    };

    const content = (item: any) => {
        return (
            <div>
                {item.sentObjectName && (
                    <p>{geti18nText('schedule.suggestions.edit.approval.name') + ': ' + item.sentObjectName} </p>
                )}
                {item.sentClassification && (
                    <p>{geti18nText('schedule.suggestions.edit.classification') + ': ' + item.sentClassification} </p>
                )}
                {item.sentSubmissionNumber && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.registry.number') +
                            ': ' +
                            item.sentSubmissionNumber}{' '}
                    </p>
                )}
                {item.sentInputDate && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.date.document.input') +
                            ': ' +
                            getDateFormat(item.sentInputDate, 'DD.MM.YYYY')}{' '}
                    </p>
                )}
                {item.sentOutputDate && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.date.document.output') +
                            ': ' +
                            getDateFormat(item.sentOutputDate, 'DD.MM.YYYY')}{' '}
                    </p>
                )}
            </div>
        );
    };

    const formStatusTagColor = (status: any) => {
        switch (status) {
            case 0:
                return 'blue';
            case 1:
                return 'gold';
            case 2:
                return 'green';
        }
    };

    const displayAdditionalInfo = (item: any) => {
        return (
            <div>
                {item.objectName && (
                    <p>{geti18nText('schedule.suggestions.edit.approval.name') + ': ' + item.objectName} </p>
                )}
                {item.classification && (
                    <p>{geti18nText('schedule.suggestions.edit.classification') + ': ' + item.classification} </p>
                )}
                {item.submissionNumber && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.registry.number') +
                            ': ' +
                            item.submissionNumber}{' '}
                    </p>
                )}
                {item.inputDate && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.date.document.input') +
                            ': ' +
                            getDateFormat(item.inputDate, 'DD.MM.YYYY')}{' '}
                    </p>
                )}
                {item.outputDate && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.date.document.output') +
                            ': ' +
                            getDateFormat(item.outputDate, 'DD.MM.YYYY')}{' '}
                    </p>
                )}

                {(item.sentObjectName ||
                    item.sentClassification ||
                    item.sentSubmissionNumber ||
                    item.sentInputDate ||
                    item.sentOutputDate) && (
                    <p>{geti18nText('schedule.suggestions.approved.details.selected.as.sent.to.morh')}</p>
                )}
                {item.sentObjectName && (
                    <p>{geti18nText('schedule.suggestions.edit.approval.name') + ': ' + item.sentObjectName} </p>
                )}
                {item.sentClassification && (
                    <p>{geti18nText('schedule.suggestions.edit.classification') + ': ' + item.sentClassification} </p>
                )}
                {item.sentSubmissionNumber && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.registry.number') +
                            ': ' +
                            item.sentSubmissionNumber}{' '}
                    </p>
                )}
                {item.sentInputDate && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.date.document.input') +
                            ': ' +
                            getDateFormat(item.sentInputDate, 'DD.MM.YYYY')}{' '}
                    </p>
                )}
                {item.sentOutputDate && (
                    <p>
                        {geti18nText('schedule.suggestions.edit.approval.date.document.output') +
                            ': ' +
                            getDateFormat(item.sentOutputDate, 'DD.MM.YYYY')}{' '}
                    </p>
                )}
            </div>
        );
    };

    const columns = [
        ...[
            {
                title: geti18nText('schedule.suggestions.table.column.type'),
                dataIndex: 'type',

                sorter: (a: any, b: any) => {},
                render: (created: any, record: any) => {
                    if (record.type) {
                        return (
                            <div>
                                {geti18nText('app.enum.MILITARY.TYPE.' + record.type) +
                                    (record.type === militaryWorkApprovalType.MORH
                                        ? record.defenseDepartment
                                            ? ' - ' + record.defenseDepartment.name
                                            : record.defenseSection
                                            ? ' - ' + record.defenseSection.name
                                            : ''
                                        : '')}
                            </div>
                        );
                    }
                },
                ...getColumnSearchOption(getEnumMilitaryTypeArray(), 'equals'),
            },
        ],
        {
            title: geti18nText('schedule.suggestions.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm:ss');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.table.column.user'),
            dataIndex: 'createdBy',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.createdBy) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.createdEmployee)}>
                            {(record.createdBy.firstName ? record.createdBy.firstName + ' ' : '') +
                                (record.createdBy.lastName ? record.createdBy.lastName : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookUserPersonWithAlias('createdBy.id'),
        },
        {
            title: geti18nText('schedule.suggestions.table.column.approved'),
            dataIndex: 'approved',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.approved) {
                    return getDateFormat(record.approved, 'DD.MM.YYYY HH:mm:ss');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.table.column.user.approved'),
            dataIndex: 'approvedBy',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.approvedByPerson) {
                    return (
                        <a
                            style={{ textDecoration: 'underline' }}
                            onClick={() => modalVisible(record.approvedEmployee)}
                        >
                            {(record.approvedByPerson.firstName ? record.approvedByPerson.firstName + ' ' : '') +
                                (record.approvedByPerson.lastName ? record.approvedByPerson.lastName : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookUserPersonWithAlias('approvedBy.id'),
        },
        {
            title: geti18nText('menu.work.obligation.schedule.suggestion.approvals.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return (
                        <Tag style={{ marginLeft: '10px' }} color={formStatusTagColor(record.status)}>
                            {geti18nText('app.enum.MILITARY_WORK_APPROVAL_STATUS.' + record.status)}
                        </Tag>
                    );
                }
            },
            ...getColumnSearchCheckbox(
                getEnumArray('MILITARY_WORK_APPROVAL_STATUS', undefined, undefined, 'MILITARY_WORK_APPROVAL_STATUS')
            ),
        },
        {
            title: geti18nText('menu.work.obligation.schedule.suggestion.sent.details'),
            dataIndex: 'sentDetails',
            render: (created: any, record: any) => {
                if (record.status && record.status === militaryWorkApprovalStatus.SENT) {
                    return (
                        <div>
                            <Popover content={content(record)} placement="top" style={{ width: '50%' }}>
                                <Button style={{ marginRight: '10px' }} icon={<InfoCircleOutlined />} />
                            </Popover>
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('menu.work.obligation.schedule.suggestion.approvals.details'),
            dataIndex: 'approvalsDetails',
            render: (created: any, record: any) => {
                if (record.status && record.status === militaryWorkApprovalStatus.APPROVAL) {
                    return (
                        <div>
                            <Popover content={displayAdditionalInfo(record)} placement="top" style={{ width: '50%' }}>
                                <Button style={{ marginRight: '10px' }} icon={<InfoCircleOutlined />} />
                            </Popover>
                        </div>
                    );
                }
            },
        },

        {
            title: geti18nText('schedule.suggestions.table.column.active'),
            dataIndex: 'active',
            width: '100px',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id && record.status !== militaryWorkApprovalStatus.APPROVAL) {
                    return record.active === true ? (
                        <NyModalConfirm
                            title={geti18nText('schedule.suggestions.deactivate.plan')}
                            onConfirm={() => {
                                onAction(record);
                            }}
                        >
                            <Tooltip title={geti18nText('app.default.button.deactivate')}>
                                <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
                            </Tooltip>
                        </NyModalConfirm>
                    ) : (
                        <NyModalConfirm
                            title={geti18nText('schedule.suggestions.activate.plan')}
                            onConfirm={() => {
                                onAction(record);
                            }}
                        >
                            <Tooltip title={geti18nText('app.default.button.activate')}>
                                <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
                            </Tooltip>
                        </NyModalConfirm>
                    );
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    const generateScheduleSuggestion = (type: any) => {
        let params: any = { type: type };
        if (defenseSectionId && defenseSectionId.length > 0) {
            const secId = defenseSectionId.map((value: any) => value.id);
            params.sectionIds = secId;
        } else if (departmentId && departmentId.length > 0 && !defenseSectionId) {
            const depId = departmentId.map((value: any) => value.id);
            params.departmentsIds = depId;
        }
        if (type === militaryWorkApprovalType.MORH) {
            setSaveLoadingPlanToMorh(true);
            params.addEmployees = formMorh.getFieldValue('addEmployees');
        } else if (type === militaryWorkApprovalType.GOVERNOR) {
            setSaveLoadingPlanToGovernor(true);
            params.addEmployees = formGovernor.getFieldValue('addEmployees');
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CREATE, undefined, params).then(
            (result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    if (type === militaryWorkApprovalType.MORH) {
                        setSaveLoadingPlanToMorh(false);
                        setOpenModalGenereateApprovalForMorh(false);
                        setDefenseSectionId(undefined);
                        setDepartmentId(undefined);
                        formMorh.resetFields();
                    } else if (type === militaryWorkApprovalType.GOVERNOR) {
                        setSaveLoadingPlanToGovernor(false);
                        setOpenModalGenerateForGovernor(false);
                        formGovernor.resetFields();
                    }
                    okNotification(
                        geti18nText('app.default.save.ok'),
                        geti18nText('schedule.suggestions.generate.plan.success')
                    );
                    setRefreshTable((refresh: any) => refresh + 1);
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                    setOpenModalGenereateApprovalForMorh(false);
                    setSaveLoadingPlanToGovernor(false);
                }
            }
        );
    };

    const addedButtons = () => {
        return (
            <div style={{ flex: 0.5, display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <Button
                        icon={<FormOutlined />}
                        onClick={() => {
                            setOpenModalGenerateForGovernor(true);
                            formGovernor.setFieldsValue({ addEmployees: true });
                        }}
                    >
                        {geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenModalGenereateApprovalForMorh(true);
                            formMorh.setFieldsValue({ addEmployees: true });
                        }}
                        icon={<FormOutlined />}
                    >
                        {geti18nText('schedule.suggestions.create.new.suggestion.compeneten.regional.department')}
                    </Button>
                </div>
            </div>
        );
    };

    const getSectionFilter = () => {
        if (departmentId != undefined) {
            const depId = departmentId.map((value: any) => value.id);
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: defenseDepartmentType.SECTION,
                },
                { field: 'parentId', condition: 'in', value: depId.join(',') },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: defenseDepartmentType.SECTION,
                },
            ];
        }
    };

    const onCloseModalGeneratePlanMorh = () => {
        setOpenModalGenereateApprovalForMorh(false);
        setDefenseSectionId(undefined);
        setDepartmentId(undefined);
        formMorh.resetFields();
    };

    const onCloseModalGeneratePlanGovernor = () => {
        setOpenModalGenerateForGovernor(false);
        formGovernor.resetFields();
    };

    const getAddEmployeesCheckbox = () => (
        <Row gutter={24}>
            <Col span={24}>
                <Form.Item
                    label={geti18nText('schedule.suggestions.create.new.suggestion.addEmployees')}
                    name="addEmployees"
                    valuePropName="checked"
                >
                    <Checkbox defaultChecked />
                </Form.Item>
            </Col>
        </Row>
    );

    return (
        <>
            <NyDataTable
                nyId="human-schedule-suggestions-military-approval-table"
                url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.LIST}
                addedButtons={addedButtons}
                fetchWhenChange={refreshTable}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                modalComponent={ScheduleSuggestionsEdit}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                editProps={{ scroll: { y: 400, x: 3200 }, sortOrder: null }}
            />
            <Modal
                okButtonProps={{ loading: saveLoadingPlanToMorh }}
                width={900}
                onOk={() => generateScheduleSuggestion(militaryWorkApprovalType.MORH)}
                destroyOnClose={true}
                onCancel={() => onCloseModalGeneratePlanMorh()}
                okText={geti18nText('schedule.suggestions.genereate.plan.create.label')}
                title={geti18nText('schedule.suggestions.genereate.plan.modal.title')}
                visible={openModalGenereateApprovalForMorh}
            >
                <Form layout="vertical" form={formMorh}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('schedule.suggestions.genereate.plan.info.alert')}
                                type="info"
                                showIcon
                                style={{ margin: '5px', marginBottom: 30 }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={16}>
                            <DepartmentSearch
                                label={geti18nText('employee.mobilization.edit.defenseDepartment')}
                                canCreate={false}
                                value={departmentId}
                                onChange={(selectedItems: any) => {
                                    setTimeout(() => {
                                        if (focusInput.current) {
                                            focusInput.current.focus();
                                        }
                                    });
                                    setDepartmentId(selectedItems);
                                    setDefenseSectionId(undefined);
                                }}
                                ref={focusInput}
                                mode="multiple"
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={16}>
                            <SectionSearch
                                label={geti18nText('mobilization.edit.defenseSectionName')}
                                setDefaultFilterValue={getSectionFilter}
                                mode={'multiple'}
                                value={defenseSectionId}
                                departmentId={departmentId && departmentId.map((value: any) => value.id)}
                                onChange={(e: any) => {
                                    setDefenseSectionId(e);
                                }}
                            />
                        </Col>
                    </Row>
                    {getAddEmployeesCheckbox()}
                </Form>
            </Modal>
            <Modal
                okButtonProps={{ loading: saveLoadingPlanToGovernor }}
                width={400}
                onOk={() => generateScheduleSuggestion(militaryWorkApprovalType.GOVERNOR)}
                destroyOnClose={true}
                onCancel={() => onCloseModalGeneratePlanGovernor()}
                okText={geti18nText('schedule.suggestions.genereate.plan.create.label')}
                title={geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                open={openModalGenerateForGovernor}
            >
                <Form layout="vertical" form={formGovernor}>
                    {getAddEmployeesCheckbox()}
                </Form>
            </Modal>
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default ScheduleSuggestionsIndex;
