import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import NationalityIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NationalityEdit from './edit';
import { AdministrationRights } from '../../../../rights/administrationRights';

const NationalitySearch = ({
    label = geti18nText('employee.edit.birth.nationality'),
    name = ['person', 'nationality'],
    searchBy = 'name',
    map = { id: 'id', label: 'text', text: 'name' },
    required = false,
    disabled = false,
}: any) => {
    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooksAndAdmin();
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.NATIONALITY.SEARCH}
                map={map}
                searchBy={searchBy}
                SearchPopupComponent={<NationalityIndex />}
                AddNewModalComponent={canCreate() ? NationalityEdit : null}
                nyTestId="nationality"
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default NationalitySearch;
