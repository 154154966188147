import { NyDatePicker, NyRequestResolver, NySearchField, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Collapse, Descriptions, Form, Input, Row, Switch, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';

import moment from 'moment';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, okNotification } from '../../../../utils/Utils';
import TownshipDistrictIndex from '../../../administration/views/township-district';
import TownshipDistrictEdit from '../../../administration/views/township-district/edit';
import { BusinessUnitSearch } from '../business-unit/search';
import DepartmentSearch from '../defense-department/department/search';
import SectionSearch from '../defense-department/section/search';
import LocationMobilizationEdit from '../location-mobilization/edit';
import MobilizationLocationSearch from '../location-mobilization/search';
import ProfessionalSpecialtySearch from '../professional-specialty/search';
import ReserveRankSearch from '../reserve-rank/search';
import SecurityCertificateLevelIndex from '../security-certificate-level';
import SecurityCertificateLevelEdit from '../security-certificate-level/edit';
import WorkplaceSearch from '../workplace/search';

const MilitaryRecords = ({
    employeeId,
    collapseActiveKeys,
    setCollapseActiveKeys,
    form,
    isEmployeeForm = false,
    setWorkplace = undefined,
    businessUnit = undefined,
    setBusinessUnit = undefined,
    obligated,
    setObligated,
    enrolled,
    setEnrolled,
    loading,
    haveSecurityCheck,
    setHaveSecurityCheck,
    needSecurityCertification,
    renewalDate,
    scheduleDate,
    county,
    township,
    settlement,
    street,
    allowDistrict,
    checked,
    setChecked,
    assignChecked,
    setAssignChecked,
    departmentId,
    setDepartmentId,
    needSecurityCheck,
    setNeedSecurityCheck,
    publishDate,
    setPublishDate,
    expirationDate,
    setExpirationDate,
    publishDateCertification,
    setPublishDateCertification,
    expirationDateCertification,
    setExpirationDateCertification,
    renewalDateCertification,
}: any) => {
    const { Panel } = Collapse;
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const EnDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const EnProfessionalSpecialtyType = useEnum('PROFESSIONAL_SPECIALTY_TYPE');
    const enEmployeeMilitaryRecordsAssigned = useEnum('EMPLOYEE_MILITARY_RECORDS_ASSIGNED');

    const isMobilizationAdmin = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const isHumanResourcesAdmin = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const getSectionFilter = () => {
        if (departmentId) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: EnDepartmentType.SECTION,
                },
                { field: 'parentId', condition: 'equals', value: departmentId },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: EnDepartmentType.SECTION,
                },
            ];
        }
    };

    useEffect(() => {
        if (checked) {
            form.setFieldsValue({
                assign: { id: 2, text: 'Ne' },
                mobilizationLocation: undefined,
                militaryWorkApproval: undefined,
            });
        }
    }, [checked]);

    const addressTooltip = (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={'address'} style={{ display: 'none' }} />
                    <Form.Item name={['address', 'settlement', 'county']} style={{ display: 'none' }} />
                </Col>
                <Col span={12}>
                    <Form.Item name={['address', 'settlement', 'township']} style={{ display: 'none' }} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={['address', 'settlement']} style={{ display: 'none' }} />
                </Col>
                <Col span={12}>
                    <Form.Item name={['address', 'street']} style={{ display: 'none' }} />
                </Col>
            </Row>
            <Descriptions column={1} contentStyle={{ color: 'white' }} labelStyle={{ color: 'white' }}>
                <Descriptions.Item label={geti18nText('employee.mobilization.edit.county')}>
                    {county != null || county != undefined ? county : null}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('employee.mobilization.edit.township')}>
                    {township != null || township != undefined ? township.name : null}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('employee.mobilization.edit.settlement')}>
                    {settlement != null || settlement != undefined ? settlement.name : null}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('employee.mobilization.edit.street')}>
                    {street != null || street != undefined ? street : null}
                </Descriptions.Item>
            </Descriptions>
        </>
    );

    const townshipFilter = [
        {
            field: 'township.id',
            condition: 'equals',
            value: township?.id,
        },
    ];

    const onChangeBusinessUnit = (value: any) => {
        setBusinessUnit(value);
        form.setFieldsValue({ workplace: undefined });
        setWorkplace(null);
    };

    const isBusinessUnitSelected = () => {
        return businessUnit?.id > 0;
    };

    const onExpirationDateChange = (value: any) => {
        setExpirationDate(value ? moment(value) : moment());
        if (form.getFieldValue('publishDate')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('publishDate').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledExpirationDate = (current: any) => {
        if (form.getFieldValue('publishDate')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('publishDate')).endOf('day');
        }
    };

    const onPublishDateChange = (value: any) => {
        setPublishDate(value ? moment(value) : moment());
        if (form.getFieldValue('expirationDate')) {
            form.setFieldsValue({
                duration: Math.abs(
                    form.getFieldValue('expirationDate').startOf('day').diff(value.startOf('day'), 'days') + 1
                ),
            });
        }
    };

    const disabledPublishDate = (current: any) => {
        if (form.getFieldValue('expirationDate')) {
            return current > moment(form.getFieldValue('expirationDate')).endOf('day');
        }
    };

    const onExpirationCertificationDateChange = (value: any) => {
        setExpirationDateCertification(value ? moment(value) : moment());
    };

    const disabledExpirationCertificationDate = (current: any) => {
        if (form.getFieldValue('publishDateCertification')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('publishDateCertification')).endOf('day');
        }
    };

    const onPublishCertificationDateChange = (value: any) => {
        setPublishDateCertification(value ? moment(value) : moment());
    };

    const disabledPublishCertificationDate = (current: any) => {
        if (form.getFieldValue('expirationDateCertification')) {
            return current > moment(form.getFieldValue('expirationDateCertification')).endOf('day');
        }
    };

    return (
        <Form form={form} layout="vertical">
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                        accordion={isEmployeeForm}
                    >
                        <Panel header={geti18nText('employee.mobilization.edit.systematization')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item name="id" style={{ display: 'none' }}></Form.Item>
                                    <BusinessUnitSearch
                                        formItemName={'businessUnit'}
                                        isWorkObligation={true}
                                        onChange={onChangeBusinessUnit}
                                    />
                                </Col>
                                <Col span={12}>
                                    <WorkplaceSearch
                                        label={geti18nText('employee.mobilization.edit.vocation')}
                                        onChange={(value: any) => setWorkplace(value)}
                                        setDefaultFilterValue={() => [
                                            {
                                                field: 'businessUnit.id',
                                                condition: 'equals',
                                                value: businessUnit?.id,
                                            },
                                            {
                                                field: 'status',
                                                condition: 'in',
                                                value: [enWorkplaceStatus.CONFIRMED].toString(),
                                            },
                                            { field: 'active', condition: 'equals_bool', value: 1 },
                                        ]}
                                        disabled={!isBusinessUnitSelected()}
                                    />
                                </Col>
                            </Row>
                            {isMobilizationAdmin() && (
                                <>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.assign')}
                                                name="assign"
                                            >
                                                <NySearchField
                                                    options={getEnumArray('EMPLOYEE_MILITARY_RECORDS_ASSIGNED')}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    className=""
                                                    disabled={checked}
                                                    onChange={(value: any) => {
                                                        if (
                                                            value?.id ==
                                                            enEmployeeMilitaryRecordsAssigned.OUTSIDE_ORGANIZATION
                                                        ) {
                                                            setAssignChecked(true);
                                                        } else {
                                                            setAssignChecked(false);
                                                            form.setFieldsValue({ mobilizationLocation: undefined });
                                                        }
                                                    }}
                                                    mustGetPopupContainer={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.militaryWorkApproval')}
                                                name="militaryWorkApproval"
                                            >
                                                <NySearchField
                                                    disabled
                                                    url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.SEARCH}
                                                    map={{
                                                        id: 'id',
                                                        label: 'text',
                                                        submissionNumber: 'submissionNumber',
                                                        inputDate: 'inputDate',
                                                    }}
                                                    searchBy="id"
                                                    className=""
                                                    mustGetPopupContainer={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.noAssign')}
                                                name="noAssign"
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    defaultChecked={false}
                                                    onChange={(event: any) => {
                                                        setChecked(event.target.checked);
                                                        if (event.target.checked) {
                                                            setAssignChecked(false);
                                                        } else {
                                                            form.setFieldsValue({ noAssignReason: null });
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <MobilizationLocationSearch
                                                disabled={checked || !assignChecked}
                                                AddNewModalComponent={
                                                    isMobilizationAdmin() || isHumanResourcesAdmin()
                                                        ? LocationMobilizationEdit
                                                        : null
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.noAssignReason')}
                                                name="noAssignReason"
                                            >
                                                <Input disabled={!checked} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Panel>
                    </Collapse>
                    {isMobilizationAdmin() && (
                        <Collapse
                            accordion={isEmployeeForm}
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('employee.mobilization.edit.securityCertificate')} key="4">
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.securityCertificateLevel')}
                                            name="securityCertificateLevel"
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.SECURITY_CERTIFICATE_LEVEL.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'name',
                                                }}
                                                searchBy="name"
                                                itemName="name"
                                                SearchPopupComponent={<SecurityCertificateLevelIndex />}
                                                AddNewModalComponent={
                                                    isMobilizationAdmin() || isHumanResourcesAdmin()
                                                        ? SecurityCertificateLevelEdit
                                                        : null
                                                }
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.securityCheck')}
                                            name="needSecurityCheck"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                checkedChildren={geti18nText('app.default.button.yes')}
                                                unCheckedChildren={geti18nText('app.default.button.no')}
                                                checked={needSecurityCheck}
                                                disabled={!isEmployeeForm}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.certificateNeeded')}
                                            name="needSecurityCertification"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                checkedChildren={geti18nText('app.default.button.yes')}
                                                unCheckedChildren={geti18nText('app.default.button.no')}
                                                checked={needSecurityCertification}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.publishDate')}
                                            name="publishDateCertification"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                onChange={onPublishCertificationDateChange}
                                                disabledDate={disabledPublishCertificationDate}
                                                defaultPickerValue={publishDateCertification}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.containsSecurityCheck')}
                                            name="haveSecurityCheck"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                checked={haveSecurityCheck}
                                                onChange={(checked: any) => {
                                                    setHaveSecurityCheck(checked);
                                                }}
                                                checkedChildren={geti18nText('app.default.button.yes')}
                                                unCheckedChildren={geti18nText('app.default.button.no')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.publishDateCertification')}
                                            name="publishDate"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                onChange={onPublishDateChange}
                                                disabledDate={disabledPublishDate}
                                                defaultPickerValue={publishDate}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.expirationDate')}
                                            name="expirationDate"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                onChange={onExpirationCertificationDateChange}
                                                disabledDate={disabledExpirationCertificationDate}
                                                defaultPickerValue={expirationDateCertification}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.renewalDate')}
                                            name="renewalDateCertification"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                defaultPickerValue={renewalDateCertification}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText(
                                                'employee.mobilization.edit.expirationDateCertification'
                                            )}
                                            name="expirationDateCertification"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                onChange={onExpirationDateChange}
                                                disabledDate={disabledExpirationDate}
                                                defaultPickerValue={expirationDate}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.renewalDateCertification')}
                                            name="renewalDate"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                defaultPickerValue={renewalDate}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    )}
                    <Collapse
                        accordion={isEmployeeForm}
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('employee.mobilization.edit.militaryRecord')} key="2">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employee.mobilization.edit.enrolled')}
                                        name="enrolled"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            checked={enrolled}
                                            checkedChildren={geti18nText('app.default.button.yes')}
                                            unCheckedChildren={geti18nText('app.default.button.no')}
                                            onChange={(checked: any) => {
                                                setEnrolled(checked);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employee.mobilization.edit.obligated')}
                                        name="obligated"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            checked={obligated}
                                            checkedChildren={geti18nText('app.default.button.yes')}
                                            unCheckedChildren={geti18nText('app.default.button.no')}
                                            onChange={(checked: any) => {
                                                setObligated(checked);
                                                setEnrolled(checked);
                                                form.setFieldsValue({ enrolled: checked });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <DepartmentSearch
                                        label={geti18nText('employee.mobilization.edit.defenseDepartment')}
                                        name={'defenseDepartment'}
                                        canCreate={isMobilizationAdmin() || isHumanResourcesAdmin()}
                                        onChange={(value: any) => {
                                            setDepartmentId(value && value.id > 0 ? value.id : null);
                                            form.setFieldsValue({ defenseSection: null });
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <SectionSearch
                                        setDefaultFilterValue={getSectionFilter}
                                        canCreate={isMobilizationAdmin() || isHumanResourcesAdmin()}
                                        departmentId={departmentId}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <ProfessionalSpecialtySearch
                                        canCreate={isMobilizationAdmin() || isHumanResourcesAdmin()}
                                        setDefaultFilterValue={() => [
                                            { field: 'active', condition: 'equals_bool', value: 1 },
                                            {
                                                field: 'type',
                                                condition: 'equals',
                                                value: EnProfessionalSpecialtyType.MILITARY,
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <ReserveRankSearch canCreate={isMobilizationAdmin() || isHumanResourcesAdmin()} />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                {allowDistrict ? (
                                    <Tooltip title={addressTooltip} zIndex={1000}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.townshipDistrict')}
                                                name={['address', 'townshipDistrict']}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.TOWNSHIP_DISTRICT.SEARCH}
                                                    map={{ id: 'id', label: 'name' }}
                                                    searchBy="name"
                                                    nyTestId="townshipDistrict"
                                                    SearchPopupComponent={
                                                        <TownshipDistrictIndex selectedTownshipId={township?.id} />
                                                    }
                                                    AddNewModalComponent={
                                                        isMobilizationAdmin() || isHumanResourcesAdmin()
                                                            ? TownshipDistrictEdit
                                                            : null
                                                    }
                                                    setDefaultFilterValue={() => townshipFilter}
                                                    AddNewModalComponentAddedData={{
                                                        township: township,
                                                    }}
                                                    mustGetPopupContainer={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Tooltip>
                                ) : null}
                                {/* {isMobilizationAdmin() && (
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.remark')}
                                            name="remark"
                                            labelAlign={'left'}
                                        >
                                            <TextArea rows={2} />
                                        </Form.Item>
                                    </Col>
                                )} */}
                            </Row>
                            <Row gutter={24}>
                                {isMobilizationAdmin() && (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.remark')}
                                                name="remark"
                                                labelAlign={'left'}
                                            >
                                                <TextArea rows={2} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.mobilization.edit.scheduleDate')}
                                                name="scheduleDate"
                                            >
                                                <NyDatePicker
                                                    style={{ width: '100%' }}
                                                    defaultPickerValue={scheduleDate}
                                                    mustGetPopupContainer={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Panel>
                    </Collapse>
                </>
            )}
        </Form>
    );
};

export default MilitaryRecords;
