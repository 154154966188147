import { NySession } from '@nybble/nyreact';
export const MobilizationRights = (function () {
    return {
        //mobilization
        isMobilizationAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_MOBILIZATION']);
        },
        isMobilizationExecutor: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_MOBILIZATION_EXECUTION']);
        },
        canCreateMobilization: () => {
            return MobilizationRights.isMobilizationAdmin() || MobilizationRights.isMobilizationExecutor();
        },
        isHumanResourcesAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_CODEBOOKS_EDIT']);
        },
        //codebooks
        canCreateMobilizationCodebooks: () => {
            return NySession.hasAnyRole([
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_MOBILIZATION',
                'ROLE_HR_CODEBOOKS_EDIT',
            ]);
        },
        canViewMobilizationCodebooks: () => {
            return (
                MobilizationRights.canCreateMobilizationCodebooks() || NySession.hasAnyRole(['ROLE_HR_CODEBOOKS_VIEW'])
            );
        },
        canExportCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_CODEBOOKS_EXPORT']);
        },
        //settings
        canCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_MOBILIZATION_SETTINGS']);
        },
        canOnlyCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_MOBILIZATION_SETTINGS']);
        },
        ///////////////////
        //menu
        isMobilizationViewMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_MOBILIZATION'];
        },
        isMobilizationViewHumanMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_MOBILIZATION'];
        },
        isMobilizationViewUserMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_MOBILIZATION'];
        },
        isMobilizationViewExecutorMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_MOBILIZATION', 'ROLE_MOBILIZATION_EXECUTION'];
        },
        isMobilizationViewSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_MOBILIZATION_SETTINGS'];
        },
    };
})();
