import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import useHelper from '../../../../hooks/useHelper';
import EmployeeTravelRelationPaymentTable from './EmployeeTravelRelationPaymentTable';
import EmployeeTravelRelationTable from './EmployeeTravelRelationTable';

const { TabPane } = Tabs;

const EmployeeTravelRelationIndex = () => {
    useHelper('travel_order/employee_travel_relation');

    return (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('employeeTravelRelation.tab.1')} key="1">
                    <EmployeeTravelRelationTable disabled={false} />
                </TabPane>
                <TabPane tab={geti18nText('employeeTravelRelation.tab.2')} key="2">
                    <EmployeeTravelRelationPaymentTable />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default EmployeeTravelRelationIndex;
