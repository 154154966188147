import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySession,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Tabs } from 'antd';
import { ReactText, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdditionalInfo from '../../../../components/additional-info';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, okNotification } from '../../../../utils/Utils';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import ObjectMetadataEdit from '../../../object-metadata/edit';
import TaskTypeCategorySearch from './search';

const { TabPane } = Tabs;

const TaskTypeCategoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('task.typeCategory.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);
    const [fileList, setFileList] = useState<any>([]);
    const [activeKey, setActiveKey] = useState('1');
    const [allEmployees, setAllEmployees] = useState<boolean>(true);
    const [data, setData] = useState<any>(null);
    const [isFormChanged, setIsFormChanged] = useState<any>(0);

    const table = useTableSettings();

    const [selectedValuesDeleteTeam, setSelectedValuesDeleteTeam] = useState<any>([]);
    const [hasSelectedDeleteTeam, setHasSelectedDeleteTeam] = useState(false);
    const [selectedValuesAddTeam, setSelectedValuesAddTeam] = useState<any>([]);
    const [hasSelectedAddTeam, setHasSelectedAddTeam] = useState(false);
    const [isAddListModalVisibleTeam, setIsAddListModalVisibleTeam] = useState(false);
    const [loadingModalTeam, setLoadingModalTeam] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);

    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const { TextArea } = Input;
    const focusInput = useRef<any>(null);
    const history = useHistory();
    const enTaskType = useEnum('TASK_TYPE');

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const onModalClose = () => {
        form.resetFields();
        setFileList([]);
        setEditHeader(geti18nText('task.typeCategory.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const activeFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.created) {
            setCreatedDate(dataForm.created);
        }

        if (dataForm.createdBy) {
            setCreatedBy(dataForm.createdBy);
        }

        if (dataForm.updated) {
            setUpdatedDate(dataForm.updated);
        }

        if (dataForm.updatedBy) {
            setUpdatedBy(dataForm.updatedBy);
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('task.typeCategory.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.fileId) {
            let filesImage = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: dataForm.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(filesImage);
        }

        setAllEmployees(dataForm?.allEmployees);

        setData(dataForm);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    useEffect(() => {
        if (dataForm) {
            save();
        }
    }, [allEmployees]);

    let clearKeysDeleteTeam: any;

    const setSelectedValuesFuncDeleteTeam = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteTeam(hasSelected);
        setSelectedValuesDeleteTeam(selectedRows);
        clearKeysDeleteTeam = clearSelectedRowKeys;
    };

    let clearKeysAddTeam: any;

    const setSelectedValuesFuncAddTeam = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddTeam(hasSelected);
        setSelectedValuesAddTeam(selectedRowKeys);
        clearKeysAddTeam = clearSelectedRowKeys;
    };

    const save = () => {
        setIsFormChanged((prevValue: any) => prevValue + 1);
        if (data) {
            form.validateFields().then(normalize(data));
        }
        setLoading(true);
        NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_TYPE_CATEGORY.EDIT + '/' + dataForm, undefined, data)
            .then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const normalize = (values: any) => {
        values.type = enTaskType.SERVICE;
        values.allEmployees = allEmployees;

        if (fileList.length > 0 && fileList[0]) {
            values.fileId = fileList[0].id;
        }
        if (values?.parent?.id < 0) {
            delete values.parent;
        }
        return values;
    };

    const saveToTeam = (e: any) => {
        e.preventDefault();
        setLoadingModalTeam(true);

        NyRequestResolver.requestPost(
            CONSTANTS_REQ.TASK_TYPE_CATEGORY.TEAMS_ADD.replace('{taskTypeCategoryId}', form.getFieldValue('id')),
            undefined,
            {
                taskTypeCategoryId: form.getFieldValue('id'),
                teamIds: selectedValuesAddTeam,
            }
        ).then((result: any) => {
            if (setLoadingModalTeam) setLoadingModalTeam(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddTeam) {
                    clearKeysAddTeam();
                }

                okNotification();
                setIsAddListModalVisibleTeam(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromTeam = () => {
        const res = selectedValuesDeleteTeam.map((value: any) => {
            return value?.team?.id;
        });

        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TASK_TYPE_CATEGORY.TEAMS_REMOVE.replace('{taskTypeCategoryId}', form.getFieldValue('id')),
            undefined,
            {
                taskTypeCategoryId: form.getFieldValue('id'),
                teamIds: res,
            }
        ).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteTeam) {
                    clearKeysDeleteTeam();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getCustomFooterContent = (
        <>
            {dataForm ? (
                <div style={{ float: 'left' }}>
                    <AdditionalInfo
                        created={createdDate}
                        createdBy={createdBy}
                        updated={updatedDate}
                        updatedBy={updatedBy}
                    />
                </div>
            ) : null}
            {activeKey == '2' && !allEmployees && dataForm ? (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setIsAddListModalVisibleTeam(true);
                        }}
                        loading={loading}
                    >
                        {geti18nText('meal.edit.add.meal.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('task.typeCategory.edit.tab.remove.from.team')}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        disabled={!hasSelectedDeleteTeam}
                        onConfirm={removeFromTeam}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteTeam}
                            danger
                            icon={<DeleteOutlined />}
                            loading={loading}
                        >
                            {geti18nText('meal.edit.remove.meal.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            ) : null}
        </>
    );

    return (
        <NyDataEdit
            closeModalButtonText={geti18nText('app.default.button.close')}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TASK_TYPE_CATEGORY.EDIT}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate() || activeKey == '2'}
            hideActivationButtons={!canCreate() || !dataForm || activeKey == '2'}
            paramsId={id}
            setValues={setValues}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            normalize={normalize}
            leaveNewModalOpen={true}
            fetchWhenChange={isFormChanged}
        >
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <TabPane tab={geti18nText('task.template.edit.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={5} offset={1}>
                            <NyImageUpload files={fileList} setFiles={setFileList} saveOnUpload={true} />
                        </Col>
                        <Col span={18}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="position" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label={geti18nText('task.typeCategory.table.column.code')} name="code">
                                        <Input autoFocus={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('task.typeCategory.table.column.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('task.typeCategory.table.column.description')}
                                name="description"
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <TaskTypeCategorySearch
                                label={geti18nText('task.typeCategory.table.column.parent')}
                                name="parent"
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('task.typeCategory.edit.tab.teams')} key="2" disabled={!dataForm}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('task.typeCategory.edit.allEmployees')} name="allEmployees">
                                <Checkbox
                                    checked={allEmployees}
                                    onChange={(e: any) => {
                                        setAllEmployees(e.target.checked);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            {!allEmployees && dataForm && (
                                <NyDataTable
                                    nyId="tasks-task-type-category-table"
                                    rowKey="id"
                                    url={CONSTANTS_REQ.TASK_TYPE_CATEGORY.TEAMS_LIST.replace(
                                        '{taskTypeCategoryId}',
                                        form.getFieldValue('id')
                                    )}
                                    showRowSelection
                                    readonly
                                    hideButtons
                                    scroll={props?.editProps?.scroll}
                                    setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                    rowSelectionType={'checkbox'}
                                    fetchWhenChange={refreshTable}
                                    setDefaultPageSize={table.setDefaultPageSize()}
                                    rowSelectionModal={setSelectedValuesFuncDeleteTeam as any}
                                    setDefaultFilterValue={activeFilter}
                                    columns={[
                                        {
                                            title: geti18nText('teams.table.column.id'),
                                            dataIndex: 'id',
                                            width: '12%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                        {
                                            title: geti18nText('teams.table.column.name'),
                                            dataIndex: ['team', 'name'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                    ]}
                                />
                            )}
                        </Col>
                    </Row>
                    {isAddListModalVisibleTeam && (
                        <Modal
                            title={geti18nText('app.default.modal.select')}
                            visible={isAddListModalVisibleTeam}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAddTeam }}
                            onOk={(e) => saveToTeam(e)}
                            confirmLoading={loadingModalTeam}
                            width={900}
                            onCancel={() => {
                                setIsAddListModalVisibleTeam(false);
                            }}
                        >
                            <NyDataTable
                                nyId="tasks-task-type-category-not-in-list-table"
                                rowKey="id"
                                url={CONSTANTS_REQ.TASK_TYPE_CATEGORY.TEAMS_NOT_IN_LIST.replace(
                                    '{taskTypeCategoryId}',
                                    form.getFieldValue('id')
                                )}
                                showRecordModal={true}
                                hideNewButton={!canCreate()}
                                showRowSelection
                                scroll={props?.editProps?.scroll}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                rowSelectionType={'checkbox'}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                rowSelectionModal={setSelectedValuesFuncAddTeam as any}
                                modalComponent={ObjectMetadataEdit}
                                // fetchWhenChange={refreshTable}
                                setDefaultFilterValue={activeFilter}
                                onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                columns={[
                                    {
                                        title: geti18nText('teams.table.column.id'),
                                        dataIndex: 'id',
                                        width: '12%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('teams.table.column.name'),
                                        dataIndex: 'name',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                ]}
                            />
                        </Modal>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default TaskTypeCategoryEdit;
