import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { useParams } from 'react-router-dom';
import { refreshEmployeeGroupTable } from '../../../../slices/employeeGroupSlice';
import { errorNotification, okNotification } from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const DeleteFromGroup = (hasSelected: any, selectedRowKeys: any, onPopupSave: any, clearSelectedRowKeys: any) => {
    const { id } = useParams<any>();
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const handleOk = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE.DELETE_FROM_GROUP, undefined, {
            employeeGroupId: Number(id),
            employees: selectedRowKeys,
        }).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                setVisible(false);
                okNotification();
                clearSelectedRowKeys();
                dispatch(refreshEmployeeGroupTable());
            } else {
                errorNotification();
            }
        });
    };

    return (
        canCreate() && (
            <React.Fragment>
                <Button type="primary" danger disabled={!hasSelected} onClick={showModal}>
                    {geti18nText('employeeGroup.deleteFromGroup.button')}
                </Button>

                {visible && (
                    <Modal
                        title={geti18nText('employeeGroup.deleteFromGroup.modal.title')}
                        visible={visible}
                        onCancel={handleCancel}
                        okButtonProps={{ danger: true }}
                        okText={geti18nText('employeeGroup.deleteFromGroup.modal.okText')}
                        onOk={handleOk}
                        maskClosable={false}
                    >
                        {geti18nText('employeeGroup.deleteFromGroup.modal.text')}
                    </Modal>
                )}
            </React.Fragment>
        )
    );
};

export default DeleteFromGroup;
