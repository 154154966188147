import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Col, DatePicker, Form, Radio, Row, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum } from '../../../../utils/Enums';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
} from '../../../../utils/Filters';
import { getDateFormat, getEnumArrayStatus, setEnumFormat } from '../../../../utils/Utils';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';
import MobilizationLocationSearch from '../location-mobilization/search';
import EmployeeAnalyzeTable55 from './EmployeeAnalyzeTable55';
import EmployeeAnalyzeTable60 from './EmployeeAnalyzeTable60';
import EmployeeAnalyzeTable65 from './EmployeeAnalyzeTable65';

const EmployeeAnalyzeWomenIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = { y: 500, x: 800 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const years: any = [
        { value: 1, label: '55 ' + geti18nText('seniority.table.years') },
        { value: 2, label: '60 ' + geti18nText('seniority.table.years') },
        { value: 3, label: '65 ' + geti18nText('seniority.table.years') },
    ];
    const defaultYears: any = [moment().subtract(55, 'years').format('yyyy')];
    const [birthYear, setBirthYear] = useState<any>(defaultYears);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [isNew, setIsNew] = useState(false);
    const [item, setItem] = useState<any>();
    const [refresh, setRefresh] = useState<any>(0);
    const [activeListSubTabs, setActiveListSubTabs] = useState<any>(1);
    const [activeListTabs, setActiveListTabs] = useState<any>(1);
    const [showObligated, setShowObligated] = useState<boolean>(false);
    const enSexType: any = GetEnum({ enumName: 'SEX_TYPE' });
    const [date, setDate] = useState<any>(null);
    const [date2, setDate2] = useState<any>(null);
    const [mobilizationLocationId, setMobilizationLocationId] = useState<any>(null);
    const [yearPickerTitle, setYearPickerTitle] = useState<any>(geti18nText('employeeAnalyze.table.datePicker2'));
    const [yearPickerName, setYearPickerName] = useState<any>(null);
    const [filterForm] = Form.useForm();

    useHelper('employeeAnalyze');

    useEffect(() => {
        setDate(null);
        setRefresh((refresh: any) => refresh + 1);
    }, [activeListSubTabs, activeListTabs, mobilizationLocationId]);

    const onChangeActiveList = (e: any) => {
        setActiveListSubTabs(e.target.value > 0 ? e.target.value : 1);
        let selectedYears = [];
        const value = e?.target?.value;

        switch (value) {
            case years[0].value:
                selectedYears = defaultYears;
                break;
            case years[1].value:
                selectedYears = [moment().subtract(60, 'years').format('yyyy')];
                break;
            case years[2].value:
                selectedYears = [moment().subtract(65, 'years').format('yyyy')];
                break;
            default:
                selectedYears = defaultYears;
                break;
        }
        setBirthYear(selectedYears);
    };

    const onDatePickerChange = (value: any) => {
        setDate(value?.clone() ?? null);
        let selectedYears = [];
        if (value) {
            switch (activeListSubTabs) {
                case 1:
                    selectedYears = [value.clone().subtract(55, 'years').format('yyyy')];
                    break;
                case 2:
                    selectedYears = [value.clone().subtract(60, 'years').format('yyyy')];
                    break;
                case 3:
                    selectedYears = [value.clone().subtract(65, 'years').format('yyyy')];
                    break;
                default:
                    selectedYears = [value.clone().subtract(55, 'years').format('yyyy')];
                    break;
            }
        } else {
            switch (activeListSubTabs) {
                case 1:
                    selectedYears = [moment().subtract(55, 'years').format('yyyy')];
                    break;
                case 2:
                    selectedYears = [moment().subtract(60, 'years').format('yyyy')];
                    break;
                case 3:
                    selectedYears = [moment().subtract(65, 'years').format('yyyy')];
                    break;
                default:
                    selectedYears = [moment().subtract(55, 'years').format('yyyy')];
                    break;
            }
        }
        setBirthYear(selectedYears);
        setRefresh((prevValue: any) => prevValue + 1);
    };

    const onDatePickerChange2 = (value: any) => {
        setDate2(value?.clone() ?? null);
        setRefresh((prevValue: any) => prevValue + 1);
    };

    const switchYearPickerTitle = (value: any) => {
        switch (value) {
            case 2:
                {
                    setYearPickerTitle(geti18nText('employeeAnalyze.table.datePicker2'));
                    setYearPickerName('expirationDateYear');
                }
                break;
            case 3:
                {
                    setYearPickerTitle(geti18nText('employeeAnalyze.table.datePicker3'));
                    setYearPickerName('expirationDateCertificationYear');
                }
                break;
            case 4:
                {
                    setYearPickerTitle(geti18nText('employeeAnalyze.table.datePicker4'));
                    setYearPickerName('scheduleExpirationYear');
                }
                break;
            default:
                setYearPickerTitle(geti18nText('employeeAnalyze.table.datePicker2'));
                setYearPickerName(null);
                break;
        }
    };

    const setDefaultFilterValue = () => {
        let filters: any = [{ field: 'active', condition: 'equals_bool', value: 1 }];

        if (activeListTabs == 1) {
            filters.push(
                {
                    field: 'birthYear',
                    condition: 'in',
                    value: birthYear.toString(),
                },
                {
                    field: 'employeeMilitaryRecords.obligated',
                    condition: 'equals_bool',
                    value: showObligated ? 1 : 0,
                }
            );
            if (activeListSubTabs != 1) {
                filters.push({
                    field: 'person.sex',
                    condition: 'equals',
                    value: enSexType.MALE,
                });
            }
        } else if (activeListTabs == 2) {
            if (date2) {
                filters.push({
                    field: 'expirationDateYear',
                    condition: 'equals',
                    value: date2.format('yyyy').toString(),
                });
            } else {
                filters.push({
                    field: 'employeeMilitaryRecords.haveSecurityCheck',
                    condition: 'equals_bool',
                    value: 1,
                });
            }
        } else if (activeListTabs == 3) {
            if (date2) {
                filters.push({
                    field: 'expirationDateCertificationYear',
                    condition: 'equals',
                    value: date2.format('yyyy').toString(),
                });
            } else {
                filters.push({
                    field: 'employeeMilitaryRecords.needSecurityCertification',
                    condition: 'equals_bool',
                    value: 1,
                });
            }
        } else if (activeListTabs == 4) {
            filters.push({
                field: 'employeeMilitaryRecords.obligated',
                condition: 'equals_bool',
                value: 1,
            });
            if (date2) {
                filters.push({
                    field: 'scheduleExpirationYear',
                    condition: 'equals',
                    value: date2.format('yyyy').toString(),
                });
            } else {
                filters.push({
                    field: 'employeeMilitaryRecords.scheduleDate',
                    condition: 'is_not_null',
                    value: '',
                });
            }
        } else if (activeListTabs == 5) {
            if (mobilizationLocationId) {
                filters.push({
                    field: 'mobilizationLocation.id',
                    condition: 'equals',
                    value: mobilizationLocationId,
                });
            } else {
                filters.push({
                    field: 'mobilizationLocation.id',
                    condition: 'is_not_null',
                    value: '',
                });
            }
        }

        return filters;
    };

    const columns = [
        {
            title: geti18nText('employeeAnalyze.table.column.employmentRecordId'),
            dataIndex: 'employmentRecordId',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.employmentRecordId) {
                    return (
                        <a
                            onClick={() => {
                                setModalVisible(true);
                                setItem(record.id);
                            }}
                        >
                            {record.employmentRecordId}
                        </a>
                    );
                }
            },
        },
        {
            title: geti18nText('employeeAnalyze.table.column.name'),
            dataIndex: "concat(person.first_name, ' ', person.last_name) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
            render: (text: any, record: any) => {
                if (record.person) {
                    return record.person.parentName
                        ? (record.person.lastName ? record.person.lastName + ',' : '') +
                              ' ' +
                              record.person.parentName +
                              ', ' +
                              (record.person.firstName ? record.person.firstName : '')
                        : (record.person.lastName ? record.person.lastName : '') +
                              ' ' +
                              (record.person.firstName ? record.person.firstName : '');
                }
            },
        },
        {
            title: geti18nText('employeeAnalyze.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.code,' ', coalesce(businessUnit.name, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
            render: (text: any, record: any) => {
                if (record?.businessUnit) {
                    return (
                        (record?.businessUnit?.code ? '(' + record?.businessUnit?.code + ') ' : '') +
                        record?.businessUnit?.name
                    );
                }
            },
        },
        {
            title: geti18nText('employeeAnalyze.table.column.professionalEducation'),
            dataIndex: ['vocation', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('employeeAnalyze.table.column.vocationDescription'),
            dataIndex: ['vocationDescription', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                'vocationDescription.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('employeeAnalyze.table.column.dob'),
            dataIndex: ['person', 'birthDate'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.person && record.person.birthDate) {
                    return getDateFormat(record.person.birthDate, 'DD.MM.YYYY.');
                }
            },
        },
        {
            title: geti18nText('employeeAnalyze.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },

        ...[
            ...(activeListSubTabs == 1
                ? [
                      {
                          title: geti18nText('employeeAnalyze.table.column.sex'),
                          dataIndex: ['person', 'sex'],
                          width: '8%',
                          sorter: (a: any, b: any) => {},
                          ...getColumnSearchOption(getEnumArrayStatus('SEX_TYPE'), 'equals', undefined),
                          render: (text: any, record: any) => {
                              const sex: any = setEnumFormat('SEX_TYPE', record?.person?.sex);
                              return sex?.text ? sex.text : '';
                          },
                      },
                  ]
                : [
                      {
                          title: geti18nText('employeeAnalyze.table.column.sex'),
                          dataIndex: ['person', 'sex'],
                          width: '8%',
                          render: (text: any, record: any) => {
                              const sex: any = setEnumFormat('SEX_TYPE', record?.person?.sex);
                              return sex?.text ? sex.text : '';
                          },
                      },
                  ]),
        ],
        {
            title: geti18nText('employeeAnalyze.table.column.scheduleExpiration'),
            dataIndex: ['employeeMilitaryRecords', 'expirationDate'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record?.employeeMilitaryRecords?.expirationDate) {
                    return getDateFormat(record.employeeMilitaryRecords.expirationDate, 'DD.MM.YYYY.');
                }
            },
        },
        {
            title: geti18nText('employeeAnalyze.table.column.expirationDateCertification'),
            dataIndex: ['employeeMilitaryRecords', 'expirationDateCertification'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record?.employeeMilitaryRecords?.expirationDateCertification) {
                    return getDateFormat(record.employeeMilitaryRecords.expirationDateCertification, 'DD.MM.YYYY.');
                }
            },
        },
        {
            title: geti18nText('employeeAnalyze.table.column.mobilizationLocation'),
            dataIndex: ['mobilizationLocation', 'name'],
            width: '160px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                return record?.mobilizationLocation?.name;
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeAnalyze.table.column.scheduleDate'),
            dataIndex: ['employeeMilitaryRecords', 'scheduleExpiration'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record?.employeeMilitaryRecords?.scheduleExpiration) {
                    return getDateFormat(record.employeeMilitaryRecords.scheduleExpiration, 'DD.MM.YYYY.');
                }
            },
        },
    ];

    return (
        <>
            <React.Fragment>
                <Row gutter={24}>
                    <Col span={24}>
                        <Radio.Group
                            value={activeListTabs}
                            defaultValue={1}
                            buttonStyle="solid"
                            optionType="button"
                            style={{ marginBottom: '20px' }}
                            onChange={(e: any) => {
                                setActiveListTabs(e.target.value);

                                if (e.target.value != 1) {
                                    setActiveListSubTabs(0);
                                } else {
                                    setActiveListSubTabs(1);
                                }
                                switchYearPickerTitle(e.target.value);
                            }}
                        >
                            <Radio.Button value={1}>{geti18nText('employeeAnalyze.table.button.years')}</Radio.Button>
                            <Radio.Button value={2}>
                                {geti18nText('employeeAnalyze.table.button.securityCheck')}
                            </Radio.Button>
                            <Radio.Button value={3}>
                                {geti18nText('employeeAnalyze.table.button.securityCertificate')}
                            </Radio.Button>
                            <Radio.Button value={4}>
                                {geti18nText('employeeAnalyze.table.button.obligation')}
                            </Radio.Button>
                            <Radio.Button value={5}>
                                {geti18nText('employeeAnalyze.table.button.legalEntity')}
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {activeListTabs == 1 && (
                        <div style={{ marginLeft: '12px' }}>
                            <Radio.Group
                                value={activeListSubTabs}
                                defaultValue={1}
                                buttonStyle="solid"
                                optionType="button"
                                style={{ marginBottom: '20px' }}
                            >
                                <Radio.Button value={years[0].value} onChange={onChangeActiveList}>
                                    {years[0].label}
                                </Radio.Button>
                                <Radio.Button value={years[1].value} onChange={onChangeActiveList}>
                                    {years[1].label}
                                </Radio.Button>
                                <Radio.Button value={years[2].value} onChange={onChangeActiveList}>
                                    {years[2].label}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    )}
                    {activeListTabs == 1 && (
                        <div style={{ marginLeft: '22px' }}>
                            {geti18nText('employeeAnalyze.table.datePicker1')}
                            <DatePicker
                                picker="year"
                                format="YYYY"
                                onChange={onDatePickerChange}
                                value={date}
                                style={{ marginLeft: '5px' }}
                            />
                        </div>
                    )}
                    <div style={{ marginLeft: '22px', display: activeListTabs == 5 ? 'block' : 'none' }}>
                        <MobilizationLocationSearch
                            style={{ width: '434px', marginBottom: '0px' }}
                            onChange={(value: any) => {
                                if (value.id != -1) {
                                    setMobilizationLocationId(value.id);
                                } else {
                                    setMobilizationLocationId(null);
                                }
                            }}
                        />
                    </div>
                    {activeListTabs != 5 && activeListTabs != 1 && (
                        <div style={{ marginLeft: '22px' }}>
                            {yearPickerTitle}
                            <DatePicker
                                picker="year"
                                format="YYYY"
                                onChange={onDatePickerChange2}
                                value={date2}
                                style={{ marginLeft: '5px' }}
                            />
                        </div>
                    )}
                    {activeListTabs == 1 && (
                        <div style={{ marginTop: '4px', marginLeft: '22px' }}>
                            {geti18nText('work.obligation.employee.table.column.obligated')}
                            <Switch
                                checked={showObligated}
                                onChange={(checked: any) => {
                                    setShowObligated(checked);
                                    setRefresh((prevValue: any) => prevValue + 1);
                                }}
                                style={{ marginLeft: '5px' }}
                                checkedChildren={geti18nText('app.default.button.yes')}
                                unCheckedChildren={geti18nText('app.default.button.no')}
                            />
                        </div>
                    )}
                </Row>
            </React.Fragment>
            {activeListSubTabs == 1 ? (
                <EmployeeAnalyzeTable55
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : activeListSubTabs == 2 ? (
                <EmployeeAnalyzeTable60
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : activeListSubTabs == 3 ? (
                <EmployeeAnalyzeTable65
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : (
                <></>
            )}
            {activeListTabs == 2 ? (
                <EmployeeAnalyzeTable55
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : activeListTabs == 3 ? (
                <EmployeeAnalyzeTable60
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : activeListTabs == 4 ? (
                <EmployeeAnalyzeTable65
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : activeListTabs == 5 ? (
                <EmployeeAnalyzeTable65
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={columns}
                    sortOrder={sortOrder}
                    setDefaultFilterValue={setDefaultFilterValue}
                    refresh={refresh}
                    scroll={scroll}
                    activeListSubTabs={activeListSubTabs}
                    activeListTabs={activeListTabs}
                />
            ) : (
                <></>
            )}
            {modalVisible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={modalVisible}
                    setIsModalVisible={setModalVisible}
                    id={item}
                    isNew={isNew}
                    setIsNew={setIsNew}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeAnalyzeWomenIndex;
