import { geti18nText } from '@nybble/nyreact';
import { Alert, Col, Input, Row } from 'antd';
import { every } from 'lodash';
import { useEffect, useState } from 'react';
import { regexCheck } from '../../../../utils/Validation';

export const CronInput = ({ value, setValue, onChange, cronErrors, setCronErrors, setCronChanged, autoFocus }: any) => {
    const [cronFocusIdx, setCronFocusIdx] = useState<any>(0);

    const cronPattern =
        '^([1-5]?[0-9]|\\*)[/[1-9][0-9]*]? (1?[0-9]|2[0-3]|\\*)[/[1-9][0-9]*]? ([1-2]?[0-9]|3[0-1]|\\*)[/[1-9][0-9]*]? ([0-9]|1[0-2]|\\*)[/[1-9][0-9]*]? ([0-6]|\\*)[/[1-9][0-9]*]?$';
    const cronInputRanges = [
        [0, 59],
        [0, 23],
        [1, 31],
        [1, 12],
        [0, 6],
    ];
    let valueArray = value ? value.split(' ') : ['0', '0', '*', '*', '*'];

    useEffect(() => {
        if (!regexCheck(value, cronPattern)) {
            if (setValue) {
                setValue('0 0 * * *');
            }
            valueArray = ['0', '0', '*', '*', '*'];
        }
    }, []);

    return (
        <>
            <Row gutter={8}>
                {[0, 1, 2, 3, 4].map((i) => {
                    return (
                        <Col span={3}>
                            <Input
                                value={valueArray[i]}
                                autoFocus={autoFocus && i == 0}
                                onFocus={() => {
                                    setCronFocusIdx(i);
                                }}
                                onChange={(val: any) => {
                                    let value = val.target.value.replaceAll(' ', '').replaceAll('.', '');
                                    let range = cronInputRanges[cronFocusIdx];
                                    let errors = cronErrors;
                                    value === '' ||
                                    !(
                                        value === '*' ||
                                        (value.split('/').length <= 2 &&
                                            every(
                                                value.split('/').map((value: any, idx: any) => {
                                                    return idx == 0
                                                        ? value == '*' ||
                                                              (value !== '' &&
                                                                  Number(value) >= range[0] &&
                                                                  Number(value) <= range[1])
                                                        : Number(value) > 0;
                                                })
                                            ))
                                    )
                                        ? (errors = errors | (1 << cronFocusIdx)) // dodaj error
                                        : (errors = errors & ~(1 << cronFocusIdx)); // makni error
                                    setCronErrors(errors);
                                    valueArray[i] = value;
                                    val = valueArray.join(' ');
                                    setCronChanged(true);
                                    onChange(val);
                                }}
                            ></Input>
                        </Col>
                    );
                })}
            </Row>
            <Alert
                message={geti18nText('app.setting.cron.info.message.' + String(cronFocusIdx))}
                description={
                    <>
                        {geti18nText('app.setting.cron.info.desc.' + String(cronFocusIdx))
                            .split('\n')
                            .map((line: any) => {
                                return (
                                    <>
                                        {String(line).startsWith(' ') ? <span style={{ marginLeft: 20 }} /> : <></>}
                                        {line}
                                        <br />
                                    </>
                                );
                            })}
                    </>
                }
                type={cronErrors & (1 << cronFocusIdx) ? 'error' : 'info'}
                showIcon
                style={{ marginTop: '10px' }}
            />
        </>
    );
};
