import { NyDataEdit, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArrayStatus, getEnumFormat, setEnumFormat } from '../../../../utils/Utils';
import LocationSearch from '../location/search';

const { Paragraph, Text } = Typography;

const DeviceEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('device.edit.new'));
    const [loading, setLoading] = useState(false);
    const [lastActivity, setLastActivity] = useState<string>();
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('device.edit.title') + ' - ' + dataForm.id);
        }

        if (dataForm.lastActivity) {
            setLastActivity(moment(dataForm.lastActivity).format('DD.MM.YYYY HH:mm:ss'));
        }

        if (dataForm.type) {
            dataForm.type = setEnumFormat('DEVICE_TYPE', dataForm.type);
        }

        console.log('dataFomr vals', dataForm);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('device.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.type) values.type = getEnumFormat(values.type);
                if (values.lastActivity) delete values.lastActivity;
                return values;
            }}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('device.edit.ipAddress')}
                        name="ipAddress"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                pattern: new RegExp(
                                    '^(([01]?[0-9]?[0-9]|2([0-4][0-9]|5[0-5])).){3}([01]?[0-9]?[0-9]|2([0-4][0-9]|5[0-5]))$'
                                ),
                                message: geti18nText('app.default.validation.ipAddress'),
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('device.edit.port')} name="port">
                        <InputNumber style={{ width: '100%' }} max={65535} min={0} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <LocationSearch label={geti18nText('device.edit.location')} name="location" required={true} />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('device.edit.type')}
                        name={'type'}
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArrayStatus('DEVICE_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            nyTestId={'type'}
                            className=""
                            renderOption={(option: any) => `${option.text}`}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item label={geti18nText('device.edit.lastActivity')} name="lastActivity">
                        <Title level={5}>{lastActivity} </Title>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default DeviceEdit;
