import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getEnumArray } from '../../../../utils/Utils';
import OrderViewAccessSettingsEdit from './edit';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookEmployee } from '../../../../utils/Filters';
import useTableSettings from '../../../../hooks/useTableSettings';

const OrderViewAccessSettingsIndex = ({ orderViewId, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'orderView.id',
                condition: 'equals',
                value: orderViewId,
            },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('orderViewAccessSettings.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('orderViewAccessSettings.table.column.type'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.ORDER_VIEW_ACCESS_TYPE.' + record.type);
                }
            },
            ...getColumnSearchOption(getEnumArray('ORDER_VIEW_ACCESS_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('orderViewAccessSettings.table.column.employee'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return record.employee.person.firstName + ' ' + record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('orderViewAccessSettings.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('orderViewAccessSettings.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="order-view-access-settings"
            url={CONSTANTS_REQ.ORDER_VIEW_ACCESS_SETTINGS.LIST}
            addNewButtonText={geti18nText('orderViewAccessSettings.table.add')}
            buttonsClassName={'ny-order-view-access-settings-buttons'}
            showRecordModal={true}
            modalComponent={OrderViewAccessSettingsEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            columns={initialColumns}
            shortcuts={true}
            addedData={{ orderViewId: orderViewId }}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
        />
    );
};

export default OrderViewAccessSettingsIndex;
