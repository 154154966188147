import { useState } from 'react';
import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, okNotification } from '../../../../../utils/Utils';
import PaymentOrdersInitializationIndex from '../payment-orders-initialization';
import PaymentOrdersInitializationEdit from '../payment-orders-initialization/edit';

const AddToBulkModal = ({ onSave, modalVisible, setModalVisible, selectedValues, clearKeys }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const defaultFilterValue = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'status', condition: 'equals_float', value: 1 },
    ];

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.paymentOrderBulkId = values.paymentOrderBulkId.id;
                values.paymentGroups = selectedValues;
                NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_ORDER_GROUP.ADD_TO_BULK, undefined, values).then(
                    (result: any) => {
                        if (setLoading) setLoading(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            okNotification();
                            setModalVisible(false);
                            clearKeys();
                            onSave();
                            form.resetFields();
                        } else {
                            errorNotification();
                        }
                    }
                );
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    const customBulkRenderOption = (option: any) => (
        <>
            <span>
                {option.text}
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>[{option.messageId}]</span>
            </span>
        </>
    );

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('payment.orders.group.AddToBulk.title')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.orders.group.AddToBulk.bulk')}
                                    name={'paymentOrderBulkId'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.PAYMENT_ORDER_INITIALIZATION.SEARCH}
                                        map={{ id: 'id', label: 'name', messageId: 'messageId' }}
                                        searchBy="name"
                                        autoFocus={true}
                                        SearchPopupComponent={
                                            <PaymentOrdersInitializationIndex defaultFilterValue={defaultFilterValue} />
                                        }
                                        itemName={'name'}
                                        AddNewModalComponent={PaymentOrdersInitializationEdit}
                                        renderOption={customBulkRenderOption}
                                        nyTestId="input-group"
                                        setDefaultFilterValue={() => defaultFilterValue}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AddToBulkModal;
