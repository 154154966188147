import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyDatePicker } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NyReportButton from '../../../../components/report-button';
import { useEffect, useState } from 'react';
import { getDateFormat } from '../../../../utils/Utils';
import useEnum from '../../../../hooks/useEnum';

const R1Modal = ({ modalVisible, setModalVisible, employeeId, initialDateFrom, initialDateTo }: any) => {
    const [form] = Form.useForm();
    const [dateFrom, setDateFrom] = useState<any>(undefined);
    const [dateTo, setDateTo] = useState<any>(undefined);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useEffect(() => {
        if (initialDateFrom) {
            setDateFrom(initialDateFrom);
            form.setFieldsValue({ dateFrom: initialDateFrom });
        }
    }, [initialDateFrom]);

    useEffect(() => {
        if (initialDateTo) {
            setDateTo(initialDateTo);
            form.setFieldsValue({ dateTo: initialDateTo });
        }
    }, [initialDateTo]);

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    const disabledDateTo = (current: any) => {
        if (dateFrom && current) {
            return current.isBefore(dateFrom.clone());
        } else {
            return current;
        }
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('report.r1Download')}
                    maskClosable={false}
                    footer={
                        <NyReportButton
                            url={CONSTANTS_REQ.REPORT.R1}
                            subreportType={'R1'}
                            customParms={{
                                employeeId: employeeId,
                                dateFrom: getDateFormat(dateFrom),
                                dateTo: getDateFormat(dateTo),
                            }}
                            buttoni18nText={'report.r1Download'}
                            fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.R1)}
                            checkBeforeSave={checkBeforeSave}
                        />
                    }
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    width={400}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeeSickLeave.edit.from')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={initialDateFrom}
                                >
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        nyTestId="date-from"
                                        onChange={(value: any) => {
                                            setDateFrom(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeeSickLeave.edit.to')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={initialDateTo}
                                >
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        nyTestId="date-to"
                                        onChange={(value: any) => {
                                            setDateTo(value);
                                        }}
                                        disabledDate={disabledDateTo}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default R1Modal;
