import TaskListIndexGeneral from './TaskListIndexGeneral';

const TaskListIndexPersonal = ({ setRefresh, refresh, listType, type }: any) => {
    const setDefaultFilterValue = [
        {
            field: 'active',
            condition: 'equals_bool',
            value: 1,
        },
        { field: 'myTasks', condition: 'equals_bool', value: 1 },
    ];
    return (
        <TaskListIndexGeneral
            setRefresh={setRefresh}
            refresh={refresh}
            listType={listType}
            type={type}
            setDefaultFilterValue={setDefaultFilterValue}
        />
    );
};

export default TaskListIndexPersonal;
