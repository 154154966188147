import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../utils/Constants';
import { getEnumArray, getEnumBooleanArray } from '../../utils/Utils';
import AssetModelEdit from './edit';
import useTableSettings from '../../hooks/useTableSettings';

const ObjectMetadataIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    defaultFilterValue,
    metadataType,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        if (metadataType) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'metadataType', condition: 'equals', value: metadataType },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns = [
        {
            title: geti18nText('objectMetadata.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('objectMetadata.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('objectMetadata.table.column.dataType'),
            dataIndex: 'dataType',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('OBJECT_METADATA_TYPE')),
            render: (text: any, recrod: any) => {
                if (recrod.dataType) {
                    return geti18nText('app.enum.OBJECT_METADATA_TYPE.' + recrod.dataType);
                }
            },
        },
        {
            title: geti18nText('objectMetadata.table.column.mandatory'),
            dataIndex: 'mandatory',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.mandatory) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('objectMetadata.table.column.format'),
            dataIndex: 'pattern',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('objectMetadata.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="object-metadata"
            url={CONSTANTS_REQ.OBJECT_METADATA.LIST}
            showRecordModal={true}
            modalComponent={AssetModelEdit}
            editProps={{ metadataType: metadataType }}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultFilterValue={setDefaultFilterValue}
            shortcuts={true}
        />
    );
};

export default ObjectMetadataIndex;
