import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { isValidJson } from '../../../../utils/Utils';

const CodebookInitializationEdit = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
    const [form] = Form.useForm();

    const dataType = props.dataType;

    function setValues(dataForm: any) {
        if (dataForm.content) {
            dataForm.content = JSON.stringify(dataForm.content, null, 2);
        }
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            editHeader={
                geti18nText('codebook.data.edit.header') +
                ' - ' +
                geti18nText('app.enum.CODEBOOK_DATA_TYPE.' + dataType)
            }
            form={form}
            isModal={true}
            setValues={setValues}
            customFetchUrl={CONSTANTS_REQ.CODEBOOK_DATA.GET}
            url={CONSTANTS_REQ.CODEBOOK_DATA.EDIT}
            loading={loading}
            setLoading={setLoading}
            value={{ id: dataType }}
            hideActivationButtons={true}
            disableSubmitButton={saveDisabled}
            normalize={(values: any) => {
                values.content = JSON.parse(values.content);
                return values;
            }}
            {...props}
            modalStyle={{ top: '20px' }}
            width={'75hv'}
        >
            <Form.Item name={'id'} style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name={'type'} style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item
                name={'content'}
                label={geti18nText('codebook.data.edit.json')}
                rules={[
                    {
                        validator: (_, value, callback) => {
                            if (isValidJson(value)) {
                                setSaveDisabled(false);
                                return callback();
                            } else {
                                setSaveDisabled(true);
                                return callback(geti18nText('codebook.data.edit.json.invalid'));
                            }
                        },
                    },
                ]}
            >
                <TextArea
                    rows={35}
                    autoFocus
                    onBlur={(_) => {
                        try {
                            form.setFieldsValue({
                                content: JSON.stringify(JSON.parse(form.getFieldValue('content')), null, 2),
                            });
                        } catch (e) {}
                    }}
                ></TextArea>
            </Form.Item>
        </NyDataEdit>
    );
};

export default CodebookInitializationEdit;
