import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, ConfigProvider, Form, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import useTableSettings from '../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getEnumArray,
    getEnumArrayStatus,
    getEnumBooleanArray,
    getTravelWarrantPaymentSortColor,
    getTravelWarrantPaymentStatusIcon,
    numberFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    userIsNotificationRecipient,
} from '../../utils/Utils';
import TravelWarrantPaymentEdit from './edit';

const TravelWarrantPaymentIndex = ({
    edit,
    id,
    forUnpaid = false,
    hideButtons = false,
    tab,
    travelWarrantStatus,
    status,
    activeKey,
    printPdf = false,
    setRefresh,
    readonly = false,
    isWizard = true,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const paymentInDeclaredCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [countDraftPayment, setCountDraftPayment] = useState<any>(0);
    const [refreshTable, setRefreshTable] = useState<any>(0);
    const [form] = Form.useForm();
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const travelWarrantPaymentStatusEnum = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');
    const travelWarrantPaymentSortEnum = useEnum('TRAVEL_WARRANT_PAYMENT_SORT');
    const [paymentVisible, setPaymentVisible] = useState<any>(false);
    const [returnVisible, setReturnVisible] = useState<any>(false);
    const [paymentModalVisible, setPaymentModalVisible] = useState<any>(false);
    const [data, setData] = useState<any>(undefined);
    const customizeRenderEmpty = () => <div style={{ textAlign: 'center' }}>{geti18nText('app.default.nodata')}</div>;

    useEffect(() => {
        setRefreshTable((prevValue: any) => prevValue + 1);
    }, [id, activeKey]);

    useEffect(() => {
        if (activeKey == 'payment' && data != undefined) {
            openModals();
        }
    }, [data, activeKey]);

    const openModals = () => {
        const res = data.filter((payment: any) => payment.status == travelWarrantPaymentStatusEnum.DRAFT);

        const tmplist1 = data.filter(
            (payment: any) =>
                payment.status == travelWarrantPaymentStatusEnum.DRAFT &&
                payment.sort == travelWarrantPaymentSortEnum.RETURN
        );
        if (
            tmplist1 &&
            tmplist1.length >= 1 &&
            travelWarrantStatus === travelWarrantStatusEnum.CALCULATED &&
            (canEdit() || canEditPersonal()) &&
            res.length > 0
        ) {
            setPaymentModalVisible(true);
            setReturnVisible(true);
        } else {
            setReturnVisible(false);
        }

        const tmplist2 = data.filter(
            (payment: any) =>
                payment.status == travelWarrantPaymentStatusEnum.DRAFT &&
                payment.sort == travelWarrantPaymentSortEnum.PAYMENT
        );
        if (
            tmplist2 &&
            tmplist2.length >= 1 &&
            travelWarrantStatus === travelWarrantStatusEnum.CALCULATED &&
            (canEdit() || canEditPersonal()) &&
            res.length > 0
        ) {
            setPaymentModalVisible(true);
            setPaymentVisible(true);
        } else {
            setPaymentVisible(false);
        }
    };

    const canEdit = () => {
        return (
            TravelWarrantRights.canCreateTravelWarrantAdmin() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const canEditPersonal = () => {
        return (
            TravelWarrantRights.canCreateTravelWarrantUser() &&
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const canSubmit = () => {
        return (
            canEdit() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT_APPROVAL)
        );
    };

    const setDefaultFilterValue = () => {
        if (tab == 4) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'advance', condition: 'equals_bool', value: 1 },
                { field: 'status', condition: 'equals', value: 2 },
                { field: 'travelWarrant.id', condition: 'equals', value: id },
            ];
        }

        if (tab == 9) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'advance', condition: 'equals_bool', value: 0 },
                { field: 'status', condition: 'equals', value: 1 },
                { field: 'travelWarrant.id', condition: 'equals', value: id },
            ];
        }

        if (tab == 10) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'advance', condition: 'equals_bool', value: 0 },
                { field: 'status', condition: 'equals', value: 2 },
                { field: 'travelWarrant.id', condition: 'equals', value: id },
            ];
        }

        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travelWarrant.id', condition: 'equals', value: id },
        ];
    };

    const initialColumns = [
        ...[
            {
                title: geti18nText('travelWarrantPayment.table.column.id'),
                dataIndex: 'id',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.paymentType'),
                dataIndex: 'paymentType',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(getEnumArray('TRAVEL_WARRANT_PAYMENT_TYPE')),
                render: (text: any, recrod: any) => {
                    if (recrod.paymentType) {
                        return geti18nText('app.enum.TRAVEL_WARRANT_PAYMENT_TYPE.' + recrod.paymentType);
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.paymentDate'),
                dataIndex: 'paymentDate',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (paymentDate: any, record: any) => {
                    if (record.paymentDate) {
                        return getDateFormat(record.paymentDate, 'DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.advance'),
                dataIndex: 'advance',
                sorter: (a: any, b: any) => {},
                render: (text: any, record: { advance: any }) => {
                    if (record.advance) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(
                    getEnumBooleanArray(),
                    'equals_bool',
                    tab == 4 ? 1 : tab == 9 || tab == 10 ? 0 : undefined
                ),
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.sort'),
                dataIndex: 'sort',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(getEnumArrayStatus('TRAVEL_WARRANT_PAYMENT_SORT')),
                render: (text: any, record: any) => {
                    if (record.sort) {
                        return getTravelWarrantPaymentSortColor(record.sort, travelWarrantPaymentSortEnum);
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.description'),
                dataIndex: 'description',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, recrod: any) => {
                          if (recrod.currency) {
                              return recrod.currency.isoCode;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (exchangeRate: any) => {
                          return exchangeRate ? (
                              <div style={{ float: 'right' }}>{numberFormat(exchangeRate, 6)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      width: '92px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amount: any) => {
                          return amount ? <div style={{ float: 'right' }}>{numberFormat(amount)}</div> : '';
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, recrod: any) => {
                          if (recrod.currency) {
                              return recrod.currency.isoCode;
                          }
                      },
                  },
              ]),
        ...[
            {
                title: geti18nText('travelWarrantPayment.table.column.status'),
                dataIndex: 'status',
                width: '250px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(getEnumArrayStatus('TRAVEL_WARRANT_PAYMENT_STATUS')),
                render: (text: any, record: any) => {
                    if (record.status) {
                        return getTravelWarrantPaymentStatusIcon(record.status, travelWarrantPaymentStatusEnum);
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.active'),
                dataIndex: 'active',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                defaultFilteredValue: setDefaultFilterValue(),
            },
        ],
    ];

    const unpaidColumns = [
        ...[
            {
                title: geti18nText('travelWarrantPayment.table.column.paymentDate'),
                dataIndex: 'paymentDate',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (paymentDate: any, record: any) => {
                    if (record.paymentDate) {
                        return getDateFormat(record.paymentDate, 'DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.paymentType'),
                dataIndex: 'paymentType',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(getEnumArray('TRAVEL_WARRANT_PAYMENT_TYPE')),
                render: (text: any, recrod: any) => {
                    if (recrod.paymentType) {
                        return geti18nText('app.enum.TRAVEL_WARRANT_PAYMENT_TYPE.' + recrod.paymentType);
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.description'),
                dataIndex: 'description',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, recrod: any) => {
                          if (recrod.currency) {
                              return recrod.currency.isoCode;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (exchangeRate: any) => {
                          return exchangeRate ? (
                              <div style={{ float: 'right' }}>{numberFormat(exchangeRate, 6)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amount'),
                      dataIndex: 'amount',
                      width: '92px',
                      align: 'right',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amount: any) => {
                          return amount ? <div style={{ float: 'right' }}>{numberFormat(amount)}</div> : '';
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, recrod: any) => {
                          if (recrod.currency) {
                              return recrod.currency.isoCode;
                          }
                      },
                  },
              ]),
        ...[],
    ];

    const onDataLoaded = (data: any) => {
        if (data != undefined) {
            const res = data.filter((payment: any) => payment.status == travelWarrantPaymentStatusEnum.DRAFT);
            setData(data);
            if (res != undefined) {
                setCountDraftPayment(res.length);
            }
        }
    };

    const travelWarrantPaymentChangeStatus = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT_PAYMENT.CHANGE_STATUS + '/' + id, undefined, {
            id: id,
            status: travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
            mail: false,
            info: form.getFieldValue(['message']) ? form.getFieldValue(['message']) : null,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                setRefreshTable(refreshTable + 1);
                if (setRefresh) setRefresh((prevValue: any) => prevValue + 1);
                closeModal();
            } else {
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const addedButtons = () => {
        return (
            <>
                {travelWarrantStatus === travelWarrantStatusEnum.CALCULATED &&
                    !readonly &&
                    (canEdit() || canEditPersonal()) &&
                    countDraftPayment > 0 && (
                        <Button onClick={() => setModalVisible(true)} type="primary" style={{ float: 'right' }}>
                            {geti18nText('travelWarrantPayment.table.sendToApproval')}
                        </Button>
                    )}
            </>
        );
    };

    const closeModal = () => {
        setModalVisible(false);
        form.setFieldsValue({ message: null });
    };

    const onReturn = () => {
        setPaymentModalVisible(false);
        setModalVisible(true);
    };

    const closePaymentModal = () => {
        setPaymentModalVisible(false);
    };

    return (
        <>
            <ConfigProvider renderEmpty={printPdf && customizeRenderEmpty}>
                <NyDataTable
                    nyId="travel-warrent-payment"
                    url={CONSTANTS_REQ.TRAVEL_WARRANT_PAYMENT.LIST}
                    addNewButtonText={geti18nText('travelWarrantPayment.table.add')}
                    addedButtons={addedButtons}
                    showRecordModal={true}
                    buttonsClassName={'buttons-sticky'}
                    onDataLoaded={onDataLoaded}
                    scroll={scroll}
                    setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                    fetchWhenChange={refreshTable}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    modalComponent={!canSubmit() ? undefined : TravelWarrantPaymentEdit}
                    editProps={{
                        isWizard: isWizard,
                        setRefresh: setRefresh,
                        setRefreshTable: setRefreshTable,
                    }}
                    setDefaultFilterValue={setDefaultFilterValue}
                    addedData={{ id: id, status: status }}
                    hideButtons={edit || hideButtons || printPdf}
                    columns={forUnpaid ? unpaidColumns : initialColumns}
                    shortcuts={true}
                    readonly={readonly}
                    nyTestId="travel-payment-table"
                    hideNewButton={!canEdit() || printPdf || readonly}
                    showCleanTableFilters={!printPdf}
                    hidePagination={printPdf}
                />
                {modalVisible && (
                    <Modal
                        visible={modalVisible}
                        title={geti18nText('travelWarrantPayment.table.sendToApproval')}
                        onOk={(event: any) => {
                            event.stopPropagation();
                            event.preventDefault();
                            travelWarrantPaymentChangeStatus();
                        }}
                        okText={geti18nText('app.default.button.ok')}
                        onCancel={closeModal}
                        width={600}
                        forceRender={true}
                        closable={true}
                        maskClosable={false}
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="message"
                                        label={geti18nText('travelWarrantOrganization.table.column.message')}
                                    >
                                        <TextArea
                                            rows={4}
                                            onKeyDown={onKeyDownTextAreaCustom}
                                            autoSize={{ minRows: 4, maxRows: 5 }}
                                        ></TextArea>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                )}
                {paymentModalVisible && travelWarrantStatus === travelWarrantStatusEnum.CALCULATED && (
                    <Modal
                        visible={paymentModalVisible}
                        okText={
                            paymentVisible
                                ? geti18nText('travelWarrantPayment.table.sendToApproval')
                                : geti18nText('app.default.button.ok')
                        }
                        cancelText={paymentVisible ? geti18nText('app.default.button.cancel') : null}
                        onCancel={() => {
                            setPaymentModalVisible(false);
                        }}
                        width={600}
                        onOk={paymentVisible ? onReturn : closePaymentModal}
                        forceRender={true}
                        closable={true}
                        maskClosable={false}
                        cancelButtonProps={{
                            style: {
                                display: paymentVisible || (returnVisible && paymentVisible) ? undefined : 'none',
                            },
                        }}
                    >
                        <br />
                        {returnVisible && paymentVisible ? (
                            <>
                                <p style={{ fontWeight: '500' }}>
                                    {geti18nText('travelWarrantPayment.table.return.message')}
                                </p>
                                <br />
                                <p style={{ fontWeight: '500' }}>
                                    {geti18nText('travelWarrantPayment.table.payment.message')}
                                </p>
                            </>
                        ) : returnVisible && !paymentVisible ? (
                            <p style={{ fontWeight: '500' }}>
                                {geti18nText('travelWarrantPayment.table.return.message')}
                            </p>
                        ) : !returnVisible && paymentVisible ? (
                            <p style={{ fontWeight: '500' }}>
                                {geti18nText('travelWarrantPayment.table.payment.message')}
                            </p>
                        ) : null}
                    </Modal>
                )}
            </ConfigProvider>
        </>
    );
};

export default TravelWarrantPaymentIndex;
