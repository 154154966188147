import { SearchOutlined } from '@ant-design/icons';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Select, SelectProps } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../rootReducer';
import AccountingModuleMenu from '../../views/accounting/menu';
import AdministrationModuleMenu from '../../views/administration/menu';
import HumanMenu from '../../views/human/menu';
import TasksModuleMenu from '../../views/tasks/menu';
import TravelWarrantModuleMenu from '../../views/travel-warrant/menu';
import WarehouseModuleMenu from '../../views/warehouse/menu';
import WebshopModuleMenu from '../../views/webshop/menu';
import defaultMenu from '../layout/menu';

const LayoutSearch = () => {
    const history = useHistory();
    const [value, setValue] = useState('');
    const searchRef = useRef<any>();
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);

    const allRoutes = useMemo(
        () => [
            ...generateRoutesFromMenu(WebshopModuleMenu, geti18nText('menu.webshop')),
            ...generateRoutesFromMenu(HumanMenu, geti18nText('menu.human')),
            ...generateRoutesFromMenu(WarehouseModuleMenu, geti18nText('menu.warehouse')),
            ...generateRoutesFromMenu(TravelWarrantModuleMenu, geti18nText('menu.travelWarrant')),
            ...generateRoutesFromMenu(AccountingModuleMenu, geti18nText('menu.accounting')),
            ...generateRoutesFromMenu(TasksModuleMenu, geti18nText('menu.task.service')),
            ...generateRoutesFromMenu(AdministrationModuleMenu, geti18nText('menu.administration')),
        ],
        []
    );

    function generateRoutesFromMenu(menu: any[], i18nPrefix: string = ''): { i18n: string; path: string }[] {
        const routes = menu.map((value: any) => {
            const i18n = i18nPrefix + ' > ' + geti18nText(value.i18n);
            if (value.submenu) {
                return generateRoutesFromMenu(value.submenu, i18n);
            }
            if (value.isForHnb === true) {
                if (mealSyncTypeHNB && NySession.hasAnyRole(value.role)) {
                    return { i18n, path: value.path };
                }
            }
            if (NySession.hasAnyRole(value.role)) {
                return { i18n, path: value.path };
            }
            return { i18n: '', path: '' };
        });
        return routes.flat().filter((item) => {
            return item?.i18n !== '';
        });
    }

    const options: SelectProps['options'] = allRoutes.map((route: { i18n: string; path: string }) => {
        const text = route.i18n;
        const index = text.toLowerCase().indexOf(value.toLowerCase());
        const label = (
            <>
                {text.slice(0, index)}
                <strong>{text.slice(index, index + value.length)}</strong>
                {text.slice(index + value.length)}
            </>
        );
        return {
            value: route.path,
            label,
            text,
            path: route.path,
        };
    });

    return (
        <Select
            suffixIcon={<SearchOutlined />}
            ref={searchRef}
            showSearch
            optionFilterProp={'text'}
            placeholder={geti18nText('app.default.search')}
            onChange={(value: any, option: any) => {
                history.push(option.path);
                setValue('');
                searchRef.current.blur();
            }}
            onSearch={(value) => {
                setValue(value);
            }}
            dropdownMatchSelectWidth={false}
            placement={'bottomRight'}
            notFoundContent={null}
            options={value ? options : []}
            value={null}
            style={{
                width: '100%',
            }}
        />
    );
};
export default LayoutSearch;
