import { EllipsisOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Dropdown, Menu, Row, Switch } from 'antd';
import { useEffect, useState } from 'react';

import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchByCodebookWorkplace,
} from '../../../../utils/Filters';
import { fileDownload, getDateFormat } from '../../../../utils/Utils';
import DepartmentSearch from '../defense-department/department/search';
import SectionSearch from '../defense-department/section/search';
import NyDescription from '../../../../components/ny-description';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const MilitaryNotObligeesIndex = ({
    scroll = { y: 580, x: 3200 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const table = useTableSettings();
    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const mobilizationReportType = useEnum('MOBILIZATION_REPORT_TYPE');
    const [departments, setDepartments] = useState<any>(undefined);
    const [sections, setSections] = useState<any>(undefined); //odsjek

    const [searchVisible, setSearchVisible] = useState<any>(false);
    const [region, setRegion] = useState<any>(undefined);
    const [departmentId, setDepartmentId] = useState<any>(-1);
    const [refresh, setRefresh] = useState<any>(0);

    const [defenseSection, setDefenseSection] = useState<any>(undefined);
    const [defenseSectionId, setDefenseSectionId] = useState<any>(-1);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const isMobilizationAdmin = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const isHumanResourcesAdmin = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const onChange = (checked: boolean) => {
        if (checked) {
            departmentId != -1 && setRefresh((prevValue: any) => prevValue + 1);
            setDepartmentId(-1);
            setDefenseSectionId(-1);
            setRegion(undefined);
            setDefenseSection(undefined);
        }
        setSearchVisible(!checked);
    };

    //useUpdateTab('menu.work.obligation.military.not.obligees', location.pathname);

    useEffect(() => {
        fetchDepartments(setDefaultFilterDepartmensValue());
        fetchDepartments(setDefaultFilterSectionsValue());
    }, []);

    const setDefaultFilterDepartmensValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: defenseDepartmentType.DEPARTMENT },
        ];
    };

    const setDefaultFilterSectionsValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'type',
                condition: 'equals',
                value: defenseDepartmentType.SECTION,
            },
        ];
    };

    const fetchDepartments = (filter: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEFENSE_DEPARTMENT.LIST, {
            max: 100,
            orderType: 'desc',
            search: encodeURI(JSON.stringify(filter)),
        }).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content) {
                filter[1].value == defenseDepartmentType.DEPARTMENT && setDepartments(result.data.content);
                filter[1].value == defenseDepartmentType.SECTION && setSections(result.data.content);
            }
        });
    };

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const downloadDocument = (type: any, fileName: any, departmentId?: any) => {
        let params: any = { type: type };
        if (departmentId) {
            params.refId = departmentId;
        }
        departments.map((department: any) => {
            if (department.id == departmentId) {
                fileDownload(
                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                    params,
                    fileName + ' - ' + department.name + '.xlsx'
                );
            }
        });
    };

    const downloadSectionDocument = (type: any, fileName: any, sectionId?: any) => {
        let params: any = { type: type };
        if (sectionId) {
            params.refId = sectionId;
        }
        sections.map((section: any) => {
            if (section.id == sectionId) {
                fileDownload(
                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                    params,
                    fileName + ' - ' + section.name + '.xlsx'
                );
            }
        });
    };

    const addedButtons = (onSave: () => void) => {
        const departmentsMenu = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                {departments &&
                    departments.length > 0 &&
                    departments.map((department: any) => {
                        return (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    block
                                    icon={<FileExcelOutlined />}
                                    onClick={() =>
                                        downloadDocument(
                                            mobilizationReportType.EV_RO_2,
                                            geti18nText('military.does.not.obligees.report.type.4') +
                                                ' - ' +
                                                department.name,
                                            department.id
                                        )
                                    }
                                >
                                    {geti18nText('military.does.not.obligees.report.type.4') + ' - ' + department.name}
                                </Button>
                            </div>
                        );
                    })}
            </Menu>
        );

        const sectionsMenu = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                {sections &&
                    sections.length > 0 &&
                    sections.map((section: any) => {
                        return (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    block
                                    icon={<FileExcelOutlined />}
                                    onClick={() =>
                                        downloadSectionDocument(
                                            mobilizationReportType.EV_RO_2,
                                            geti18nText('military.does.not.obligees.report.type.4.1') +
                                                ' - ' +
                                                section.name,
                                            section.id
                                        )
                                    }
                                >
                                    {geti18nText('military.does.not.obligees.report.type.4.1') + ' - ' + section.name}
                                </Button>
                            </div>
                        );
                    })}
            </Menu>
        );

        const actionsMenu = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            block
                            icon={<FilePdfOutlined />}
                            onClick={() =>
                                fileDownload(
                                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                    { type: mobilizationReportType.EV_RO_6 },
                                    geti18nText('military.does.not.obligees.report.type.1') + '.xlsx'
                                )
                            }
                        >
                            {geti18nText('military.does.not.obligees.report.type.1')}
                        </Button>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            block
                            icon={<FilePdfOutlined />}
                            onClick={() =>
                                fileDownload(
                                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                    { type: mobilizationReportType.EV_RO_7 },
                                    geti18nText('military.does.not.obligees.report.type.2') + '.xlsx'
                                )
                            }
                        >
                            {geti18nText('military.does.not.obligees.report.type.2')}
                        </Button>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            block
                            icon={<FilePdfOutlined />}
                            onClick={() =>
                                fileDownload(
                                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                    { type: mobilizationReportType.EV_RO_8 },
                                    geti18nText('military.does.not.obligees.report.type.3') + '.xlsx'
                                )
                            }
                        >
                            {geti18nText('military.does.not.obligees.report.type.3')}
                        </Button>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Dropdown key="more" overlay={departmentsMenu} trigger={['click']}>
                            <Button icon={<FileExcelOutlined />} block>
                                {geti18nText('military.does.not.obligees.report.type.4')}
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Dropdown key="more" overlay={sectionsMenu} trigger={['click']}>
                            <Button icon={<FileExcelOutlined />} block>
                                {geti18nText('military.does.not.obligees.report.type.4.1')}
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('schedule.suggestions.generate.document.schedule.sugestions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    const getSectionFilter = () => {
        if (departmentId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: defenseDepartmentType.SECTION,
                },
                { field: 'parentId', condition: 'equals', value: departmentId },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: defenseDepartmentType.SECTION,
                },
            ];
        }
    };

    const setDepartmentFilterValue = () => {
        return departmentId != -1 && defenseSectionId != -1
            ? [
                  { field: 'active', condition: 'equals_bool', value: 1 },
                  { field: 'obligated', condition: 'equals_bool', value: 0 },
                  { field: 'defenseDepartment.id', condition: 'equals', value: departmentId },
                  { field: 'defenseSection.id', condition: 'equals', value: defenseSectionId },
              ]
            : departmentId != -1
            ? [
                  { field: 'active', condition: 'equals_bool', value: 1 },

                  { field: 'obligated', condition: 'equals_bool', value: 0 },
                  { field: 'defenseDepartment.id', condition: 'equals', value: departmentId },
              ]
            : [
                  { field: 'active', condition: 'equals_bool', value: 1 },

                  { field: 'obligated', condition: 'equals_bool', value: 0 },
              ];
    };

    const columns = [
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit.id'),
            dataIndex: ['businessUnit', 'code'],
            render: (text: any, record: any) => {
                return record?.businessUnit?.code;
            },
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit'),
            dataIndex: ['businessUnit', 'name'],
            render: (text: any, record: any) => {
                return record?.businessUnit?.name;
            },
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.name.lastname'),
            dataIndex: "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name )",
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.employee)}>
                            {(record.employee.person && record.employee.person.lastName
                                ? record.employee.person.lastName + ', '
                                : '') +
                                (record.employee.person && record.employee.person.parentName
                                    ? record.employee.person.parentName + ', '
                                    : '') +
                                (record.employee.person && record.employee.person.firstName
                                    ? record.employee.person.firstName
                                    : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.work.place'),
            width: '10%',
            dataIndex: ['vocation', 'name'],
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record?.vocation?.name) {
                    return (
                        <div>
                            {record?.vocation?.name}
                            {record?.vocationDescription?.name ? ' - ' + record.vocationDescription.name : ' '}
                        </div>
                    );
                }
            },
            ...getColumnSearchByCodebookWorkplace(),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (created: any, record: any) => {
                if (record?.employee?.person?.oib) {
                    return record?.employee?.person?.oib;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.birthdate'),
            dataIndex: ['person', 'birthDate'],
            width: '5%',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.birthDate) {
                    return getDateFormat(record.employee.person.birthDate, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.qualifications'),
            dataIndex: ['employeeProfessionalQualification', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                'employeeVocationProfessionalQualification.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                    code: 'code',
                }
            ),
            render: (created: any, record: any) => {
                if (record.employee && record?.employee?.vocation?.professionalQualification) {
                    return record.employee.vocation.professionalQualification;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.professionalSpecialty'),
            dataIndex: ['professionalSpecialty', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return record?.professionalSpecialty?.name;
            },
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.PROFESSIONAL_SPECIALTY.SEARCH,
                'professionalSpecialty.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.department.defense'),
            dataIndex: ['defenseDepartment', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseDepartment.id',
                setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'),
            dataIndex: ['defenseSection', 'name'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseSection.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.address.full'),
            width: '10%',
            dataIndex: [`concat(address.street,' ', settlement.name)`],
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.address) {
                    return (
                        <div>
                            {(record.address.street ? record.address.street + ', ' : '') +
                                (record.address.settlement ? record.address.settlement.name : '')}
                        </div>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobilization.location'),
            dataIndex: ['mobilizationLocation', 'name'],
            width: '11%',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                return record?.mobilizationLocation?.name;
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobile.full'),
            width: '8%',
            dataIndex: ['contact', 'mobile'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.email.full'),
            width: '7%',
            dataIndex: ['contact', 'email'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.remark'),
            dataIndex: 'remark',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (remark: any, record: any) => {
                if (record?.remark) {
                    return <NyDescription title={record?.remark} maxWidth={'150px'} />;
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    return (
        <>
            <Row>
                <Col span={24} style={{ marginLeft: '1rem', marginBottom: '-24px' }}>
                    <Row>
                        <Switch defaultChecked={true} onChange={onChange} />
                        <div style={{ marginLeft: '1rem' }}>
                            {geti18nText('schedule.suggestions.modal.employees.switch.total')}
                        </div>
                        <div style={{ marginLeft: '1rem' }}>
                            {' ' + geti18nText('schedule.suggestions.modal.employees.search.region')}
                        </div>

                        <Col span={4} style={{ marginLeft: '1rem', top: '-2px', marginBottom: '8px' }}>
                            <DepartmentSearch
                                isFormItem={false}
                                disabled={!searchVisible}
                                canCreate={false}
                                value={region}
                                onChange={(e: any) => {
                                    setDepartmentId(e?.id);
                                    setRefresh((prevValue: any) => prevValue + 1);
                                    setRegion(e);
                                    if (e?.id == -1) {
                                        setDefenseSection(undefined);
                                        setDefenseSectionId(-1);
                                    }
                                    setDefenseSection(undefined);
                                }}
                            />
                        </Col>
                        <div style={{ marginLeft: '1rem' }}>
                            {' ' + geti18nText('mobilization.edit.defenseSectionName')}
                        </div>
                        <Col span={4} style={{ marginLeft: '1rem', top: '-2px', marginBottom: '8px' }}>
                            <SectionSearch
                                isFormItem={false}
                                disabled={!searchVisible || departmentId == -1}
                                setDefaultFilterValue={getSectionFilter}
                                canCreate={isMobilizationAdmin() || isHumanResourcesAdmin()}
                                departmentId={departmentId}
                                value={defenseSection}
                                onChange={(e: any) => {
                                    setDefenseSectionId(e?.id);
                                    setRefresh((prevValue: any) => prevValue + 1);
                                    setDefenseSection(e);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <NyDataTable
                nyId="human-military-not-obligees"
                url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.LIST}
                buttonsClassName="buttons-sticky"
                columns={columns}
                fetchWhenChange={refresh}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDepartmentFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? { order: 'business_unit.code', orderType: 'asc' }}
                hideNewButton={true}
                addedButtons={addedButtons}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default MilitaryNotObligeesIndex;
