import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getColorFromNotificationType, getLocationStateOpenModal } from '../../../../utils/Utils';
import EmployeeSickLeaveEdit from './edit';

const EmployeeSickLeaveIndex = () => {
    const location: any = useLocation();
    const [visible, setVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(0);
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    useEffect(() => {
        getLocationStateOpenModal(location, setVisible, false);
    }, [location.state]);

    useHelper('human/employee_sick_leave');

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const columns = [
        {
            title: geti18nText('employeeSickLeave.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.oib'),
            dataIndex: ['person', 'oib'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.name'),
            dataIndex: ['sickLeave', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.SICK_LEAVE.SEARCH, 'sickLeave.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                code: 'remittance',
            }),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.from'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.to'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('employeeSickLeave.table.column.days'),
            dataIndex: 'workingDays',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="human-emplyee-sick-leave-list"
                url={CONSTANTS_REQ.EMPLOYEE_SICK_LEAVE.LIST}
                addNewButtonText={geti18nText('employeeSickLeave.table.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                modalComponent={EmployeeSickLeaveEdit}
                columns={columns}
                scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                hideNewButton={!canCreate()}
                shortcuts={true}
                fetchWhenChange={refresh}
                headerTitle={geti18nText('menu.employeeSickLeave')}
            />
            <EmployeeSickLeaveEdit
                isModal={true}
                visible={visible}
                setVisible={setVisible}
                addedData={{ employee: location && location.state && location.state.employee }}
                onSaveAndGetID={() => setRefresh((refresh) => refresh + 1)}
            />
        </>
    );
};

export default EmployeeSickLeaveIndex;
