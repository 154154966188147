import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Result, Row, Tabs, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import NyHistory from '../../../../components/ny-history/NyHistory';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getDateFormat,
    getSearchFormat,
    okNotification,
} from '../../../../utils/Utils';
import EmployeeInfo from './EmployeeInfo';
import MilitaryRecords from './MilitaryRecords';
import Mobilization from './Mobilization';

const EmployeeMobilizationNew = ({
    isModalVisible,
    setIsModalVisible,
    id,
    refresh,
    setRefresh,
    isNew,
    setIsNew,
    isEmployeeForm = false,
    activeListTabs = 1,
}: any) => {
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState('1');
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2', '3', '4']);
    const [showTooltip, setShowTooltip] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [employee, setEmployee] = useState<any>(undefined);
    const [obligated, setObligated] = useState<any>(false);
    const [enrolled, setEnrolled] = useState<any>(false);
    const [workplace, setWorkplace] = useState<any>(null);
    const [workplaceDetails, setWorkplaceDetails] = useState<any>(null);
    const [businessUnit, setBusinessUnit] = useState<any>(undefined);
    const [loading, setLoading] = useState<any>(false);
    const [haveSecurityCheck, setHaveSecurityCheck] = useState<any>(false);
    const [needSecurityCertification, setNeedSecurityCertification] = useState<any>(false);
    const [renewalDate, setRenewalDate] = useState<any>(null);
    const [scheduleDate, setScheduleDate] = useState<any>(null);
    const [county, setCounty] = useState<any>();
    const [township, setTownship] = useState<any>();
    const [settlement, setSettlement] = useState<any>();
    const [street, setStreet] = useState<any>();
    const [allowDistrict, setAllowDistrict] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);
    const [assignChecked, setAssignChecked] = useState(false);
    const [departmentId, setDepartmentId] = useState<any>(null);
    const [needSecurityCheck, setNeedSecurityCheck] = useState<boolean>(false);
    const [publishDate, setPublishDate] = useState<any>(null);
    const [expirationDate, setExpirationDate] = useState<any>(null);
    const [executorVisbile, setExecutorVisible] = useState(false);

    const [publishDateCertification, setPublishDateCertification] = useState<any>(null);
    const [expirationDateCertification, setExpirationDateCertification] = useState<any>(null);
    const [renewalDateCertification, setRenewalDateCertification] = useState<any>(null);

    const canCreate = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3', '4']);
        }
        setCollapseAll(!collapseAll);
    };

    useEffect(() => {
        if (isNew) {
            setActiveKey('1');
            form.resetFields();
        }
    }, []);

    const onCancel = () => {
        onModalClose();
    };

    const saveWorkplaceDetails = async (employeeId: any) => {
        let workplaceDetailsId = null;
        if (employeeId > 0) {
            if (workplaceDetails?.id) {
                let data: any =
                    workplace?.id > 0
                        ? {
                              employee: { id: employeeId },
                              id: workplaceDetails.id,
                              workplace: { id: workplace.id },
                              active: businessUnit?.id > 0,
                          }
                        : {
                              employee: { id: employeeId },
                              id: workplaceDetails.id,
                              workplace: { id: workplaceDetails?.workplace?.id },
                              active: false,
                          };
                if (
                    ((!workplace?.id || workplace?.id < 1) && workplaceDetails?.workplace?.id) ||
                    workplace?.id != workplaceDetails?.workplace?.id
                ) {
                    await NyRequestResolver.requestPut(
                        CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT + '/' + workplaceDetails.id,
                        undefined,
                        data
                    ).then((result: any) => {
                        if (result?.status == RESPONSE.CREATED && result.data) {
                            workplaceDetailsId = result.data.id;
                        } else {
                            errorNotification(geti18nText('app.default.save.nok'));
                        }
                    });
                }
            } else if (workplace?.id > 0) {
                await NyRequestResolver.requestPost(CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT, undefined, {
                    employee: { id: employeeId },
                    workplace: { id: workplace.id },
                }).then((result: any) => {
                    if (result?.status == RESPONSE.CREATED && result.data) {
                        workplaceDetailsId = result.data.id;
                    } else {
                        errorNotification(geti18nText('app.default.save.nok'));
                    }
                });
            }
        }
        return workplaceDetailsId;
    };

    const normalizeValues = (values: any) => {
        values.employee = { id: employee?.id };
        values.address = { townshipDistrict: { id: values?.address?.townshipDistrict?.id }, id: values?.address?.id };

        if (values?.defenseDepartment?.id < 0) {
            delete values.defenseDepartment;
        }
        if (values?.defenseSection?.id < 0) {
            delete values.defenseSection;
        }
        if (values?.professionalSpecialty?.id < 0) {
            delete values.professionalSpecialty;
        }
        if (values?.reserveRank?.id < 0) {
            delete values.reserveRank;
        }
        if (values?.workplaceDetails?.id < 0) {
            delete values.workplaceDetails;
        }
        if (values?.mobilizationLocation?.id < 0) {
            delete values.mobilizationLocation;
        }
        if (values?.securityCertificateLevel?.id < 0) {
            delete values.securityCertificateLevel;
        }
        if (values?.businessUnit?.id < 0) {
            delete values.businessUnit;
        }
        if (values.mobilizationTime != null) {
            values.mobilizationTime = values.mobilizationTime.id;
        }
        if (values.mobilizationFunction != null) {
            values.mobilizationFunction = values.mobilizationFunction.id;
        }
        if (values.assign != null) {
            values.assign = values.assign.id;
        }
        if (values.executor != null) {
            if (values?.executor?.id < 0) {
                delete values.executor;
            } else {
                getSearchFormat(values.executor);
            }
        }
        if (values.publishDate) values.publishDate = getDateFormat(values.publishDate, 'yyyy-MM-DD HH:mm');
        if (values.expirationDate) values.expirationDate = getDateFormat(values.expirationDate, 'yyyy-MM-DD HH:mm');
        if (values.scheduleDate) values.scheduleDate = getDateFormat(values.scheduleDate, 'yyyy-MM-DD HH:mm');
        if (values.renewalDate) values.renewalDate = getDateFormat(values.renewalDate, 'yyyy-MM-DD HH:mm');
        if (values.publishDateCertification)
            values.publishDateCertification = getDateFormat(values.publishDateCertification, 'yyyy-MM-DD HH:mm');
        if (values.expirationDateCertification)
            values.expirationDateCertification = getDateFormat(values.expirationDateCertification, 'yyyy-MM-DD HH:mm');
        if (values.renewalDateCertification)
            values.renewalDateCertification = getDateFormat(values.renewalDateCertification, 'yyyy-MM-DD HH:mm');

        values.enrolled = enrolled;
        values.obligated = obligated;
        values.active = true;
        delete values.workplace;
        return values;
    };

    const onSaveNew = async () => {
        form.validateFields().then(async (values: any) => {
            values = normalizeValues(values);
            //workplaceDetails
            const workplaceDetailsId = await saveWorkplaceDetails(employee?.id);
            values.workplaceDetails = { id: workplaceDetailsId };

            NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.EDIT, undefined, {
                ...values,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    form.resetFields();
                    onModalClose();
                    setRefresh((refresh: number) => refresh + 1);
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
            });
        });
    };

    const getCustomFooterContent = (
        <>
            {activeKey == '2' && (
                <div style={{ float: 'left' }}>
                    <Tooltip
                        placement="top"
                        visible={showTooltip}
                        title={geti18nText('app.default.shortcuts.collapse')}
                    >
                        {!collapseAll ? (
                            <EyeOutlined
                                onClick={changeCollapseVisibility}
                                className="ny-custom-button"
                                style={{ marginRight: '20px' }}
                            />
                        ) : (
                            <EyeInvisibleOutlined
                                onClick={changeCollapseVisibility}
                                className="ny-custom-button"
                                style={{ marginRight: '20px' }}
                            />
                        )}
                    </Tooltip>
                </div>
            )}
            <Button onClick={onCancel}>{geti18nText('app.default.button.cancel')}</Button>
            {activeKey == '2' && canCreate() && (
                <Button onClick={onSaveNew} type="primary">
                    {geti18nText('app.default.button.save')}
                </Button>
            )}
        </>
    );

    const onModalClose = () => {
        form.resetFields();
        setIsModalVisible(false);
        setIsNew(false);
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    return (
        <>
            {isModalVisible && (
                <Modal
                    visible={isModalVisible}
                    maskClosable={false}
                    closable={true}
                    width={1200}
                    onCancel={() => {
                        onModalClose();
                    }}
                    footer={activeKey == '2' ? getCustomFooterContent : getCustomFooterContent}
                >
                    <Tabs
                        type="card"
                        className="tabs-sticky"
                        activeKey={activeKey}
                        onChange={(key: any) => {
                            setActiveKey(key);
                        }}
                        style={{ maxHeight: '75vh' }}
                    >
                        <Tabs.TabPane tab={geti18nText('employee.mobilization.edit.tab.1')} key="1">
                            {employee == undefined ? (
                                <Row gutter={24}>
                                    <Col offset={1} span={23} style={{ paddingTop: '50px' }}>
                                        <Result
                                            status="403"
                                            title={geti18nText('employee.mobilization.new.chooseEmployee')}
                                        />
                                        <Form layout="vertical" style={{ height: '100px' }}>
                                            <Form.Item label={geti18nText('attendance.edit.employee')} name="employee">
                                                <NySearchField
                                                    url={CONSTANTS_REQ.EMPLOYEE.FOR_MILITARY_RECORD}
                                                    map={{
                                                        id: 'id',
                                                        label: 'text',
                                                        employmentRecordId: 'employmentRecordId',
                                                    }}
                                                    searchBy="person.first_name || ' ' || person.last_name || ' ' || employment_record_id "
                                                    itemName={[
                                                        ['person', 'firstName'],
                                                        ['person', 'lastName'],
                                                    ]}
                                                    renderOption={customEmployeeRenderOption}
                                                    setDefaultFilterValue={setDefaultFilterValue}
                                                    customItemNameLabel={'firstName lastName'}
                                                    nyTestId="employee"
                                                    onChange={(value: any) => {
                                                        setEmployee(value);
                                                    }}
                                                    autoFocus={true}
                                                    order="person.last_name, person.first_name"
                                                    mustGetPopupContainer={true}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            ) : (
                                <EmployeeInfo employeeId={employee?.id} disabled={isNew ? true : false} />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={geti18nText('employee.mobilization.edit.tab.2')}
                            key="2"
                            style={{ maxHeight: '75vh' }}
                            disabled={employee === undefined}
                        >
                            <MilitaryRecords
                                employeeId={employee?.id}
                                collapseActiveKeys={collapseActiveKeys}
                                setCollapseActiveKeys={setCollapseActiveKeys}
                                form={form}
                                save={onSaveNew}
                                isNew={isNew}
                                onModalClose={onModalClose}
                                isEmployeeForm={isEmployeeForm}
                                obligated={obligated}
                                setObligated={setObligated}
                                enrolled={enrolled}
                                setEnrolled={setEnrolled}
                                activeListTabs={activeListTabs}
                                setWorkplace={setWorkplace}
                                setWorkplaceDetails={setWorkplaceDetails}
                                businessUnit={businessUnit}
                                setBusinessUnit={setBusinessUnit}
                                loading={loading}
                                setLoading={setLoading}
                                haveSecurityCheck={haveSecurityCheck}
                                setHaveSecurityCheck={setHaveSecurityCheck}
                                needSecurityCertification={needSecurityCertification}
                                renewalDate={renewalDate}
                                scheduleDate={scheduleDate}
                                county={county}
                                township={township}
                                settlement={settlement}
                                street={street}
                                allowDistrict={allowDistrict}
                                checked={checked}
                                setChecked={setChecked}
                                assignChecked={assignChecked}
                                setAssignChecked={setAssignChecked}
                                departmentId={departmentId}
                                setDepartmentId={setDepartmentId}
                                needSecurityCheck={needSecurityCheck}
                                setNeedSecurityCheck={setNeedSecurityCheck}
                                publishDate={publishDate}
                                setPublishDate={setPublishDate}
                                expirationDate={expirationDate}
                                setExpirationDate={setExpirationDate}
                                publishDateCertification={publishDateCertification}
                                setPublishDateCertification={setPublishDateCertification}
                                expirationDateCertification={expirationDateCertification}
                                setExpirationDateCertification={setExpirationDateCertification}
                                renewalDateCertification={renewalDateCertification}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={geti18nText('employee.mobilization.edit.tab.3')}
                            key="3"
                            disabled={employee === undefined}
                        >
                            <Mobilization
                                setExecutorVisible={setExecutorVisible}
                                executorVisbile={executorVisbile}
                                form={form}
                                loading={loading}
                                setLoading={setLoading}
                                employeeId={id}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Modal>
            )}
            {historyModalVisible && (
                <Modal
                    title={geti18nText('mobilization.history.info')}
                    visible={historyModalVisible}
                    onCancel={() => {
                        setHistoryModalVisible(false);
                    }}
                    okButtonProps={{ hidden: true }}
                    maskClosable={false}
                    width={1200}
                >
                    <NyHistory id={id} link={'mobilization'} type={'mobilization'} />
                </Modal>
            )}
        </>
    );
};
export default EmployeeMobilizationNew;
