import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import SettlementIndex from '../settlement';
import SettlementEdit from '../settlement/edit';
import SettlementSearch from '../settlement/search';

const SettlementDistrictEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('settlement.district.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (props?.addedData?.settlement) {
            form.setFieldsValue({ settlement: setSearchFormat(props.addedData.settlement) });
        }
    }, [props?.addedData?.settlement]);

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('settlement.district.district') + ' - ' + dataForm.name);
        }

        if (dataForm.settlement) dataForm.settlement = setSearchFormat(dataForm.settlement);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('settlement.district.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.SETTLEMENT_DISTRICT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
            normalize={(values: any) => {
                if (values.settlement) values.settlement = getSearchFormat(values.settlement);

                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('settlement.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <SettlementSearch label={geti18nText('company.edit.settlement')} name={['settlement']} />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default SettlementDistrictEdit;
