import { attendanceInit } from '../slices/attendanceSlice';
import { companyInit } from '../slices/companySlice';
import { dashboardInit } from '../slices/dashboardSlice';
import { employeeGroupInit } from '../slices/employeeGroupSlice';
import { employeeInit } from '../slices/employeeSlice';
import { favoriteInit } from '../slices/favoriteSlice';
import { helperInit } from '../slices/helperSlice';
import { modalOpenInit } from '../slices/modalOpenSlice';
import { notificationInit } from '../slices/notificationSlice';
import { pinedNotificationsInit } from '../slices/pinedNotificationsSlice';
import { propsInit } from '../slices/propSlice';
import { selectedItemInit } from '../slices/selectedItemSlice';
import { shortcutsSettingsInit } from '../slices/shortcutsSettingsSlice';
// import { tabsInit } from '../slices/tabsSlice';
import { travelWarrantInit } from '../slices/travelWarrantSlice';
import { webshopInit } from '../slices/webshopSlice';
import { applicationSettingsInit } from '../slices/applicationSettingsSlice';
import { workAttendanceInit } from '../slices/workAttendanceSlice';

export function InitSlices(dispatch: any) {
    dispatch(attendanceInit());
    dispatch(employeeGroupInit());
    dispatch(helperInit());
    dispatch(modalOpenInit());
    dispatch(notificationInit());
    dispatch(selectedItemInit());
    dispatch(shortcutsSettingsInit());
    // dispatch(tabsInit());
    dispatch(webshopInit());
    dispatch(favoriteInit());
    dispatch(pinedNotificationsInit());
    dispatch(dashboardInit());
    dispatch(companyInit());
    dispatch(propsInit());
    dispatch(travelWarrantInit());
    dispatch(employeeInit());
    dispatch(applicationSettingsInit());
    dispatch(workAttendanceInit());
}
