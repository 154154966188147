import useHelper from '../../../../../hooks/useHelper';
import ItemIndex from '../../../../administration/views/item';

const ItemAsset = () => {
    useHelper('asset/codebooks/assets');

    return <ItemIndex type={4} itemGroupType={1} scroll={{ y: 540, x: 800 }} />;
};

export default ItemAsset;
