import { geti18nText, NySession } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import useHelper from '../../../../../hooks/useHelper';
import MealsGeneralSettingsIndex from './mealsGeneralSettingsIndex';
import { MealsRights } from '../../../../../rights/mealsRights';

const { TabPane } = Tabs;

const MealsSettingsIndex = () => {
    useHelper('meals');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    return (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('meals.general.tab.title')} key="1">
                    <MealsGeneralSettingsIndex dirty={false} canCreate={canCreate} />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default MealsSettingsIndex;
