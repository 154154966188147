import {
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Image, Popconfirm, Tooltip } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import useHelper from '../../hooks/useHelper';
import useTableSettings from '../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../utils/Constants';
import { getDateFormat, getEnumArrayStatus, getTaskStatusIcon, setTaskWatch, unsetTaskWatch } from '../../utils/Utils';
import CreateTaskUserIndex from './CreateTaskUserIndex';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const TaskListUserTasksIndex = ({ setRefresh, refresh, url, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const location: any = useLocation();
    const history = useHistory();
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState<any>(1);

    const taskTypeEnum = useEnum('TASK_TYPE');
    const [selectedTaskId, setSelectedTaskId] = useState<any>(null);

    useHelper('task_service/task-list/user');

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
            { field: 'myTasks', condition: 'equals_bool', value: 1 },
        ];
    };

    const shortColumns = [
        {
            title: geti18nText('team.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: '',
            dataIndex: 'fileId',
            width: '100px',
            render: (fileId: any, record: any) => {
                if (record && record.fileId) {
                    return (
                        <div
                            style={{
                                alignContent: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                style={{
                                    objectFit: 'cover',
                                    height: '48px',
                                    width: '48px',
                                    borderRadius: '5px',
                                }}
                                preview={true}
                                src={
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    record.fileId +
                                    '?tenant=' +
                                    NySession.getUser().tenantId
                                }
                            />
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('team.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.name) {
                    return record.name;
                }
            },
        },
        {
            title: geti18nText('team.table.column.description'),
            dataIndex: 'description',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record.description}>
                            <div className={'ny-table-paragraph-div'}>
                                <p className={'ny-table-paragraph'}>{record && record.description}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.status'),
            dataIndex: 'status',
            width: '188px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TASK_STATUS')),
            render: (text: any, record: any) => {
                if (record.status) {
                    return getTaskStatusIcon(record.status);
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.watch'),
            dataIndex: 'isWatched',
            width: '66px',
            render: (text: any, record: { isWatched: any; id: any }) => {
                if (record.isWatched || url) {
                    return (
                        <Tooltip title={geti18nText('tasks.list.column.watch.hover.stop')}>
                            <Popconfirm
                                title={geti18nText('tasks.popup.watched.message.cancel')}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onConfirm={() => {
                                    unsetTaskWatch(record?.id, setRefreshTable);
                                }}
                            >
                                <EyeOutlined
                                    style={{
                                        color: 'green',
                                        fontSize: '18px',
                                        marginLeft: '12px',
                                        marginTop: '5px',
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title={geti18nText('tasks.list.column.watch.hover')}>
                            <EyeInvisibleOutlined
                                style={{
                                    color: '#1890ff',
                                    fontSize: '18px',
                                    marginLeft: '12px',
                                    marginTop: '5px',
                                }}
                                onClick={() => {
                                    setTaskWatch(record?.id, setRefreshTable);
                                }}
                            />
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.created'),
            dataIndex: 'created',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record?.created) {
                    return getDateFormat(record?.created, 'DD.MM.YYYY HH:mm');
                }
            },
        },
    ];

    const addedButtons = () => {
        return (
            <>
                <Button type="primary" onClick={() => history.push('/task-service')} style={{ float: 'right' }}>
                    {geti18nText('menu.task.service')}
                </Button>
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="task-service-user-tasks-index-table"
                url={url ? url : CONSTANTS_REQ.TASK.LIST.replace('{type}', taskTypeEnum.SERVICE)}
                addNewButtonText={geti18nText('tasks.list.add')}
                fetchWhenChange={refreshTable}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                onRowSelect={(data: any) => {
                    setSelectedTaskId(data && data.id);
                }}
                editProps={{ setRefreshTable, refreshTable }}
                columns={shortColumns}
                setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() =>
                    url ? table.setDefaultFilterValue() : table.setDefaultFilterValue(setDefaultFilterValue())
                }
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                shortcuts={true}
                addedButtons={addedButtons}
            />
            {selectedTaskId && (
                <>
                    <CreateTaskUserIndex
                        selectedTaskId={selectedTaskId}
                        setSelectedTaskId={setSelectedTaskId}
                        refreshTable={refreshTable}
                        setRefreshTable={setRefreshTable}
                    />
                </>
            )}
        </>
    );
};

export default TaskListUserTasksIndex;
