import React, { useRef, useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Select, Divider } from 'antd';
import { geti18nText, NyDataEdit, NyDatePicker, NySearchField, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import moment from 'moment';
import {
    formatJoppdCodeSearch,
    getDateFormat,
    getEnumArray,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';
import BankAccountEdit from '../../../administration/views/bank/BankAccountEdit';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { PayrollRights } from '../../../../rights/payrollRights';

const PayrollInsuranceEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('payrollInsurance.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '7.1.');
        }
        if (dataForm.validFrom) dataForm.validFrom = moment(dataForm.validFrom);
        if (dataForm.validTo) dataForm.validTo = moment(dataForm.validTo);
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('payrollInsurance.edit.title') + ' - ' + dataForm.name);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payrollInsurance.edit.new'));
    };

    const setDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '7.1.');
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PAYROLL_INSURANCE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                return {
                    id: values.id,
                    name: values.name,
                    percent: values.percent,
                    baseMin: values.baseMin,
                    baseMax: values.baseMax,
                    type: values.type,
                    baseType: values.baseType,
                    joppdCode: values.joppdCode ? values.joppdCode.code : null,
                    validFrom: values.validFrom ? getDateFormat(values.validFrom) : null,
                    validTo: values.validTo ? getDateFormat(values.validTo) : null,
                    active: values.active,
                    bankAccount: values.bankAccount,
                    remittance: values.remittance,
                    payrollInsuranceBankAccountId: values.payrollInsuranceBankAccountId,
                };
            }}
            shortcuts={true}
            nyTestId="payroll-insurance-edit"
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.edit.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input ref={focusInput} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.edit.percent')}
                                    name="percent"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.edit.validFrom')}
                                    name="validFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker format="DD.MM.YYYY" nyTestId="insurance-valid-from" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollInsurance.edit.validTo')} name="validTo">
                                    <NyDatePicker format="DD.MM.YYYY" nyTestId="insurance-valid-to" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollInsurance.edit.baseMin')} name="baseMin">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollInsurance.edit.baseMax')} name="baseMax">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.edit.type')}
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Select options={getEnumArray('PAYROLL_INSURANCE_TYPE', 'label', 'value')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollInsurance.edit.baseType')} name="baseType">
                                    <Select options={getEnumArray('PAYROLL_INSURANCE_BASE_TYPE', 'label', 'value')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('payrollInsurance.edit.joppdCode')} name="joppdCode">
                                    <NySearchField
                                        url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        map={{ id: 'id', label: 'code' }}
                                        searchBy="code"
                                        nyTestId="joppdCode"
                                        SearchPopupComponent={
                                            <JoppdCodeIndex defaultFilteredValue={setDefaultFilterValue} />
                                        }
                                        itemName="code"
                                        addNewItem={true}
                                        order="code"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider plain>{geti18nText('payrollInsurance.edit.bankAccount')}</Divider>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={'payrollInsuranceBankAccountId'} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.edit.bankAccount.account')}
                                    name={'bankAccount'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.PAYROLL_INSURANCE_BANK_ACCOUNT.SEARCH}
                                        map={{ id: 'id', label: 'iban' }}
                                        searchBy="iban"
                                        AddNewModalComponent={BankAccountEdit}
                                        itemName={'iban'}
                                        nyTestId="iban"
                                        order="iban"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payrollInsurance.edit.bankAccount.remittance')}
                                    name={'remittance'}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default PayrollInsuranceEdit;
