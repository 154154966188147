import { ExclamationCircleOutlined, FormOutlined, ToolOutlined, WarningOutlined } from '@ant-design/icons';
import { NyDataEdit, NyRequestResolver, NySearchField, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Tabs, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { normalize } from 'path';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    GetEmployeeEvaluationStatusIcon,
    ResolveEmployeeEvaluationStatusIcon,
    errorNotification,
    getEmployeeSelectOption,
    getEnumArrayStatus,
    getEnumFormat,
    getErrorNotificationMessage,
    getHumanCodebooksUrlByType,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setEnumFormat,
} from '../../../../../utils/Utils';
import CompetencyListIndex from './competencyListIndex';
import EvaluationSummary from './evaluationSummary';
import GoalPlanIndex from './goalPlanIndex';
import NeedsPlanIndex from './needsPlanIndex';
import ResultListIndex from './resultListIndex';

const { TabPane } = Tabs;
const { confirm } = Modal;

const EmployeeEvaluationEditModal = (props: any) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const enEmployeeEvaluationStatus = useEnum('EMPLOYEE_EVALUATION_STATUS');
    const enEmployeeEvaluationEstimation = useEnum('EMPLOYEE_EVALUATION_ESTIMATION');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [editHeader, setEditHeader] = useState(geti18nText('employee.evaluation.edit.new'));
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState('general');
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [dataFormObject, setDataFormObject] = useState(null);
    const [evaluation, setEvaluation] = useState<any>(null);
    const [status, setStatus] = useState<any>(null);
    const [actionsList, setActionsList] = useState<any>(null);
    const [overallEstimation, setOverallEstimation] = useState<any>(null);
    const [overallEstimationManager, setOverallEstimationManager] = useState<any>(null);
    const [superiorManagerIntermediary, setSuperiorManagerIntermediary] = useState<boolean>(false);
    const [reminderModalVisible, setReminderModalVisilbe] = useState<boolean>(false);
    const [evaluationEmployee, setEvaluationEmployee] = useState<any>(null);
    const [directManager, setDirectManager] = useState<any>(null);
    const [superiorManager, setSuperiorManager] = useState<any>(null);
    const [typeList, setTypeList] = useState<any>([]);
    const [showAlert, setShowAlert] = useState<any>(false);
    const [showAlert2, setShowAlert2] = useState<any>(false);
    const [showAlert2Employee, setShowAlert2Employee] = useState<any>(false);
    const [resultManagerComment, setResultManagerComment] = useState<any>(null);
    const [remark, setRemark] = useState<any>(null);
    const [dateFrom, setDateFrom] = useState<any>(null);
    const [loadingAction, setLoadingAction] = useState<any>(false);

    const isAdmin = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const hasUserRole = () => {
        return HumanResourcesRights.isEvaluationUser() && !isAdmin();
    };

    const isUser = () => {
        return employee && evaluationEmployee && employee.id == evaluationEmployee.id && hasUserRole();
    };

    const isManager = () => {
        return employee && directManager && employee.id == directManager.id && hasUserRole();
    };

    const isSuperiorManager = () => {
        return employee && superiorManager && employee.id == superiorManager.id && hasUserRole();
    };

    const canSave = () => {
        return isUser() || isManager();
    };

    const isIntermediation = () => {
        return status &&
            status == enEmployeeEvaluationStatus.INTERMEDIATION &&
            superiorManagerIntermediary &&
            isSuperiorManager()
            ? true
            : false;
    };

    const canViewIntermediation = () => {
        return superiorManagerIntermediary && status && status >= enEmployeeEvaluationStatus.UNDER_REVIEW;
    };

    const disabledForm = () => {
        return (
            (status == enEmployeeEvaluationStatus.ESTIMATION && !(isManager() || isUser())) ||
            (status == enEmployeeEvaluationStatus.PARTIALLY_FILLED && !isUser()) ||
            status == enEmployeeEvaluationStatus.UNDER_REVIEW ||
            isAdmin() ||
            Object.values([
                enEmployeeEvaluationStatus.CANCELLED,
                enEmployeeEvaluationStatus.COMPLETED,
                enEmployeeEvaluationStatus.DRAFT,
                enEmployeeEvaluationStatus.WRONG_MANAGER,
                enEmployeeEvaluationStatus.INTERMEDIATION,
            ]).includes(status)
        );
    };

    const disabledGoals = () => {
        return status == enEmployeeEvaluationStatus.PARTIALLY_FILLED && isUser();
    };

    const canChangeManagers = (superior: boolean, direct: boolean) => {
        if (superior) {
            return (
                isAdmin() &&
                (Object.values([enEmployeeEvaluationStatus.DRAFT, enEmployeeEvaluationStatus.WRONG_MANAGER]).includes(
                    status
                ) ||
                    (Object.values([
                        enEmployeeEvaluationStatus.UNDER_REVIEW,
                        enEmployeeEvaluationStatus.INTERMEDIATION,
                    ]).includes(status) &&
                        superiorManagerIntermediary))
            );
        } else if (direct) {
            return (
                isAdmin() &&
                Object.values([enEmployeeEvaluationStatus.DRAFT, enEmployeeEvaluationStatus.WRONG_MANAGER]).includes(
                    status
                )
            );
        }
    };

    function setValues(dataForm: any) {
        if (dataForm.id) {
            setEditHeader(
                <>
                    {geti18nText('employee.evaluation.edit.title')} - {dataForm?.employee?.person?.firstName}{' '}
                    {dataForm?.employee?.person?.lastName} (
                    {moment(dataForm?.employeeEvaluationPeriod?.dateFrom).format('DD.MM.YYYY.')} -{' '}
                    {moment(dataForm?.employeeEvaluationPeriod?.dateTo).format('DD.MM.YYYY.')}){' '}
                    {GetEmployeeEvaluationStatusIcon(dataForm.status?.id ?? dataForm.status)}
                </>
            );
        }
        if (dataForm?.employeeEvaluationPeriod?.dateFrom) {
            setDateFrom(dataForm?.employeeEvaluationPeriod?.dateFrom);
        }
        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('EMPLOYEE_EVALUATION_STATUS', dataForm.status);
        }
        if (dataForm.employee) {
            setEvaluationEmployee(dataForm.employee);
        }
        if (dataForm.directManager) {
            setDirectManager(dataForm.directManager);
            dataForm.directManager = getEmployeeSelectOption(dataForm.directManager);
        }
        if (dataForm.superiorManager) {
            setSuperiorManager(dataForm.superiorManager);
            dataForm.superiorManager = getEmployeeSelectOption(dataForm.superiorManager);
        }
        if (dataForm.overallEvaluationEstimation) {
            setOverallEstimation(dataForm.overallEvaluationEstimation);
            dataForm.overallEvaluationEstimation = setEnumFormat(
                'EMPLOYEE_EVALUATION_ESTIMATION',
                dataForm.overallEvaluationEstimation
            );
        }
        if (dataForm.directManagerEvaluationEstimation) {
            setOverallEstimationManager(dataForm.directManagerEvaluationEstimation);
            dataForm.overallEvaluationEstimation = setEnumFormat(
                'EMPLOYEE_EVALUATION_ESTIMATION',
                dataForm.directManagerEvaluationEstimation
            );
        }
        if (dataForm.superiorManagerIntermediary) {
            setSuperiorManagerIntermediary(dataForm.superiorManagerIntermediary);
        }
        if (dataForm.superiorManagerEvaluationEstimation) {
            dataForm.superiorManagerEvaluationEstimation = setEnumFormat(
                'EMPLOYEE_EVALUATION_ESTIMATION',
                dataForm.superiorManagerEvaluationEstimation
            );
        }

        if (dataForm.resultManagerComment) {
            setResultManagerComment(dataForm.resultManagerComment);
        }
        if (dataForm.remark) {
            setRemark(dataForm.remark);
        }
        if (employee && employee.id == dataForm.employee?.id && hasUserRole()) {
            dataForm.employeeEvaluationCompetencyList = dataForm.employeeEvaluationCompetencyList.filter(
                (item: any) => item?.employeeEvaluationCompetencyTemplate?.type?.code != '1'
            );
        }
        setEvaluation(dataForm);
        setDataForm(dataForm.id);
        setDataFormObject(dataForm);
        setAlerts(dataForm);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
        resolveStatusActions(dataForm.id, dataForm.status.id);
    }

    const isResultListManagerCommentMandatory = (item: any) => {
        return (
            (item?.resultEstimation?.id != enEmployeeEvaluationEstimation.EFFECTIVE ||
                (item?.resultEstimation != enEmployeeEvaluationEstimation.EFFECTIVE && !item?.resultEstimation?.id)) &&
            (item?.managerComment?.length < 0 || !item?.managerComment)
        );
    };

    const isCompetencyListManagerCommentMandatory = (item: any) => {
        return item?.managerEstimation?.code == '1' && (item?.managerComment?.length < 0 || !item?.managerComment);
    };

    const setAlerts = (dataForm: any) => {
        if (dataForm.employeeEvaluationResultList) {
            let allFilledResult: boolean = true;
            dataForm.employeeEvaluationResultList?.every((item: any) => {
                if (!item.resultEstimation || isResultListManagerCommentMandatory(item)) {
                    allFilledResult = false;
                }
                return item;
            });

            if (employee && employee.id == dataForm.directManager?.id && hasUserRole()) {
                setShowAlert(!allFilledResult);
            }
        }

        if (dataForm.employeeEvaluationCompetencyList) {
            let allFilledCompetency: boolean = true;
            dataForm.employeeEvaluationCompetencyList?.every((item: any) => {
                if (!item.managerEstimation?.id || isCompetencyListManagerCommentMandatory(item)) {
                    allFilledCompetency = false;
                }
                return item;
            });
            if (employee && employee.id == dataForm.directManager?.id && hasUserRole()) {
                setShowAlert2(!allFilledCompetency);
            }

            const showItemAlert2Employee: any = dataForm.employeeEvaluationCompetencyList?.every((item: any) => {
                return item.employeeEstimation !== undefined;
            });
            if (employee && employee.id == dataForm?.employee?.id && hasUserRole()) {
                setShowAlert2Employee(!showItemAlert2Employee);
            }
        }
    };

    const getCompetencyType = () => {
        setTypeList([]);
        NyRequestResolver.requestGet(
            getHumanCodebooksUrlByType(
                CONSTANTS_REQ.HUMAN_CODEBOOKS.LIST,
                enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE.toString()
            ),
            undefined
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content?.length > 0) {
                setTypeList(result.data.content);
            }
        });
    };

    const onModalClose = () => {
        props?.editProps?.setRefresh && props.editProps.setRefresh((refresh: any) => refresh + 1);
        setTriggerCloseModal(true);
        setEvaluation(null);
        setDataForm(null);
        setDataFormObject(null);
        setStatus(null);
        setOverallEstimation(null);
        setSuperiorManagerIntermediary(false);
        setDirectManager(null);
        setSuperiorManager(null);
        setEvaluationEmployee(null);
        setOverallEstimation(null);
        setOverallEstimationManager(null);
        setRemark(null);
        form.resetFields();
        setEditHeader(geti18nText('employee.evaluation.edit.new'));
    };

    const onModalOpen = () => {
        getCompetencyType();
    };

    const resolveStatusActions = (id: any, currentStatus: any) => {
        setActionsList(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION.ACTION_LIST + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const filteredList = currentStatus
                    ? result.data.filter((element: any) => element != currentStatus)
                    : result.data;
                setActionsList(filteredList);
            }
        });
    };

    const changeStatus = (recordId: any, status: any) => {
        if (recordId) {
            setLoadingAction(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.EMPLOYEE_EVALUATION.CHANGE_STATUS + '/' + recordId, undefined, {
                id: recordId,
                status: status,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification(geti18nText('employee.evaluation.change.status.success'));
                    setLoadingAction(false);
                    onModalClose();
                } else {
                    if (result.data && result.data.error) {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification();
                    }
                    setLoadingAction(true);
                }
            });
        }
    };

    function showConfirm(action: any) {
        const message = geti18nText('employee.evaluation.edit.status.actions');
        const content = (
            <div style={{ fontSize: '14px' }}>
                <p style={{ marginBottom: '5px' }}>{GetEmployeeEvaluationStatusIcon(action)}</p>
                {geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.change.confirm.' + action)}
            </div>
        );
        confirm({
            title: message,
            content: content,
            okText: geti18nText('app.default.button.yes'),
            cancelText: geti18nText('app.default.button.no'),
            icon: <ExclamationCircleOutlined />,
            onCancel() {},
            onOk() {
                changeStatus(dataForm, action);
            },
        });
    }

    const getActionsOverlay = () => (
        <>
            {actionsList?.length > 0 && (
                <Menu>
                    <Menu.ItemGroup title={geti18nText('employee.evaluation.edit.status.actions')}>
                        {actionsList.map((action: any) => {
                            return (
                                <div style={{ padding: '0px', textAlign: 'left' }}>
                                    <div style={{ display: 'block', margin: '5px' }}>
                                        <Button
                                            icon={ResolveEmployeeEvaluationStatusIcon(
                                                action,
                                                {
                                                    fontSize: '16px',
                                                    paddingRight: '10px',
                                                    paddingTop: '2px',
                                                    float: 'left',
                                                },
                                                ''
                                            )}
                                            style={{ width: '100%', textAlign: 'left' }}
                                            onClick={() => showConfirm(action)}
                                            loading={loadingAction}
                                        >
                                            {geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.change.' + action)}
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                    </Menu.ItemGroup>
                </Menu>
            )}
        </>
    );

    const button = (action: any, disabled: any) => {
        return (
            <Button
                icon={ResolveEmployeeEvaluationStatusIcon(
                    action,
                    {
                        fontSize: '16px',
                        paddingRight: '10px',
                        paddingTop: '2px',
                        float: 'left',
                    },
                    ''
                )}
                style={{ width: '100%', textAlign: 'left' }}
                onClick={() => {
                    if (action === 15) {
                        setReminderModalVisilbe(true);
                    } else {
                        showConfirm(action);
                    }
                }}
                type={'primary'}
                danger={Object.values([
                    enEmployeeEvaluationStatus.CANCELLED,
                    enEmployeeEvaluationStatus.WRONG_MANAGER,
                ]).includes(action)}
                disabled={disabled}
                loading={loadingAction}
            >
                {geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.change.' + action)}
            </Button>
        );
    };

    const getActionList = () => (
        <>
            {actionsList ? (
                <>
                    {actionsList?.length > 3 ? (
                        <Dropdown overlay={getActionsOverlay()} placement="top">
                            <Button>
                                <ToolOutlined /> {geti18nText('app.default.actions')}
                            </Button>
                        </Dropdown>
                    ) : (
                        actionsList.map((action: any) => {
                            let disabled =
                                (action === enEmployeeEvaluationStatus.UNDER_REVIEW && showAlert2Employee) ||
                                (action === enEmployeeEvaluationStatus.PARTIALLY_FILLED &&
                                    (showAlert ||
                                        showAlert2 ||
                                        !resultManagerComment ||
                                        !overallEstimation ||
                                        (((overallEstimationManager &&
                                            overallEstimation &&
                                            overallEstimationManager != overallEstimation) ||
                                            (overallEstimationManager && !overallEstimation) ||
                                            overallEstimation == enEmployeeEvaluationEstimation.UNDEFINED ||
                                            overallEstimationManager == enEmployeeEvaluationEstimation.UNDEFINED) &&
                                            !remark)));

                            return (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '12px',
                                    }}
                                >
                                    {disabled ? (
                                        <Tooltip title={geti18nText('app.default.required.fields')}>
                                            {button(action, disabled)}
                                        </Tooltip>
                                    ) : (
                                        button(action, disabled)
                                    )}
                                </div>
                            );
                        })
                    )}
                </>
            ) : (
                <NySpinner />
            )}
        </>
    );

    useEffect(() => {
        if (reminderModalVisible) {
            setLoadingAction(true);
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.EMPLOYEE_EVALUATION.GET_REMINDER_MESSAGE_TEXT + '/' + evaluation?.employee?.id
            ).then((response: any) => {
                if (response?.status === RESPONSE.OK && response?.data && response?.data[0]) {
                    form.setFieldsValue({ message: response?.data[0] });
                    setLoadingAction(false);
                } else {
                    setLoadingAction(false);
                }
            });
        }
    }, [reminderModalVisible]);

    const reminderModalClose = () => {
        setReminderModalVisilbe(false);
        form.setFieldsValue({ message: null });
    };

    const sendReminder = () => {
        setLoadingAction(true);
        const value = { employeeEvaluationId: evaluation.id, message: form.getFieldValue(['message']) };
        NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_EVALUATION.SEND_REMINDER, undefined, value).then(
            (result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification(geti18nText('employee.evaluation.edit.reminder.send.ok'));
                    reminderModalClose();
                    setLoadingAction(false);
                } else {
                    errorNotification(geti18nText('employee.evaluation.edit.reminder.send.error'));
                    setLoadingAction(false);
                }
            }
        );
    };

    const getIntermediationForm = () => {
        return (
            <>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="intermediaryReasons"
                            label={geti18nText('employee.evaluation.edit.intermediation.column.intermediaryReasons')}
                            rules={[
                                {
                                    required: isIntermediation(),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <TextArea
                                autoFocus
                                autoSize={{ minRows: 3, maxRows: 4 }}
                                onKeyDown={onKeyDownTextAreaCustom}
                                disabled={!isIntermediation()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="superiorManagerDecision"
                            label={geti18nText(
                                'employee.evaluation.edit.intermediation.column.superiorManagerDecision'
                            )}
                            rules={[
                                {
                                    required: isIntermediation(),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 4 }}
                                onKeyDown={onKeyDownTextAreaCustom}
                                disabled={!isIntermediation()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText(
                                'employee.evaluation.edit.intermediation.column.superiorManagerEvaluationEstimation'
                            )}
                            name="superiorManagerEvaluationEstimation"
                            rules={[
                                {
                                    required: isIntermediation(),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                options={getEnumArrayStatus('EMPLOYEE_EVALUATION_ESTIMATION')}
                                map={{ id: 'id', label: 'text' }}
                                onChange={(value: any) => setOverallEstimationManager(value?.id ? value.id : null)}
                                searchBy="text"
                                renderOption={(option: any) => `${option.text}`}
                                nyTestId="superiorManagerEvaluationEstimation"
                                className=""
                                disabled={!isIntermediation()}
                                allowClear={false}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    };

    const getEditForm = () => (
        <>
            <Tabs
                type="card"
                className="tabs-sticky"
                id="travel-warrant-edit-tabs"
                style={{ overflowY: 'auto', paddingBottom: '10px' }}
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <TabPane tab={geti18nText('employee.evaluation.tab.general')} key="general">
                    <EvaluationSummary
                        id={dataForm}
                        evaluation={evaluation}
                        form={form}
                        superiorManagerIntermediary={superiorManagerIntermediary}
                        superiorManager={superiorManager}
                        setSuperiorManager={setSuperiorManager}
                        setDirectManager={setDirectManager}
                        canChangeManagers={canChangeManagers}
                        save={save}
                    />
                </TabPane>
                <TabPane tab={geti18nText('employee.evaluation.tab.result')} key="result">
                    <ResultListIndex
                        id={dataForm}
                        form={form}
                        isUser={isUser()}
                        isManager={isManager()}
                        disabledForm={disabledForm()}
                        activeKey={activeKey}
                        overallEstimation={overallEstimation}
                        setOverallEstimation={setOverallEstimation}
                        overallEstimationManager={overallEstimationManager}
                        setOverallEstimationManager={setOverallEstimationManager}
                        superiorManagerIntermediary={superiorManagerIntermediary}
                        setSuperiorManagerIntermediary={setSuperiorManagerIntermediary}
                        setShowAlert={setShowAlert}
                        showAlert={showAlert}
                        disabledGoals={disabledGoals()}
                        saveEvaluation={save}
                        superiorManager={superiorManager}
                        isResultListManagerCommentMandatory={isResultListManagerCommentMandatory}
                        status={status}
                        dataFormObject={dataFormObject}
                    />
                </TabPane>
                <TabPane tab={geti18nText('employee.evaluation.tab.competency')} key="competency">
                    <CompetencyListIndex
                        id={dataForm}
                        form={form}
                        isUser={isUser()}
                        isManager={isManager()}
                        disabledForm={disabledForm()}
                        activeKey={activeKey}
                        typeList={typeList}
                        setShowAlert={setShowAlert2}
                        showAlert={showAlert2}
                        setShowAlertEmployee={setShowAlert2Employee}
                        showAlertEmployee={showAlert2Employee}
                        isCompetencyListManagerCommentMandatory={isCompetencyListManagerCommentMandatory}
                        status={status}
                        dataFormObject={dataFormObject}
                    />
                </TabPane>
                <TabPane tab={geti18nText('employee.evaluation.tab.goalPlan')} key="goalPlan">
                    <GoalPlanIndex
                        id={dataForm}
                        form={form}
                        isUser={isUser()}
                        isManager={isManager()}
                        disabledForm={disabledForm()}
                        activeKey={activeKey}
                        disabledGoals={disabledGoals()}
                        saveEvaluation={save}
                        dataFormObject={dataFormObject}
                    />
                </TabPane>
                <TabPane tab={geti18nText('employee.evaluation.tab.needsPlan')} key="needsPlan">
                    <NeedsPlanIndex
                        id={dataForm}
                        form={form}
                        isUser={isUser()}
                        isManager={isManager()}
                        disabledForm={disabledForm()}
                        activeKey={activeKey}
                        disabledGoals={disabledGoals()}
                        saveEvaluation={save}
                        dataFormObject={dataFormObject}
                    />
                </TabPane>
                {canViewIntermediation() && (
                    <TabPane tab={geti18nText('employee.evaluation.tab.intermediation')} key="intermediation">
                        {getIntermediationForm()}
                    </TabPane>
                )}
            </Tabs>
        </>
    );

    const normalizeValues = (
        values: any,
        superiorManagerIntermediaryField: any = null,
        overallEvaluationEstimationManagerField: any = null
    ) => {
        if (isIntermediation() && isSuperiorManager() && superiorManagerIntermediary) {
            values.status = enEmployeeEvaluationStatus.UNDER_REVIEW;
        } else {
            values.status = status;
        }

        if (values.superiorManagerEvaluationEstimation) {
            values.superiorManagerEvaluationEstimation = getEnumFormat(values.superiorManagerEvaluationEstimation);
        }
        values.overallEvaluationEstimation = overallEstimation;
        values.directManagerEvaluationEstimation =
            overallEvaluationEstimationManagerField > 0
                ? overallEvaluationEstimationManagerField
                : overallEstimationManager;
        values.superiorManagerIntermediary =
            superiorManagerIntermediaryField == true || superiorManagerIntermediaryField == false
                ? superiorManagerIntermediaryField
                : superiorManagerIntermediary;
        values.directManager = getSearchFormat(values.directManager);
        values.superiorManager = getSearchFormat(values.superiorManager);

        return values;
    };

    const save = async (
        superiorManagerIntermediaryField: any = null,
        overallEvaluationEstimationManagerField: any = null,
        setLoadingIntermediary: any = null
    ) => {
        if (setLoadingIntermediary) {
            setLoadingIntermediary(true);
        }
        setLoading(true);
        let values: any = form.getFieldsValue();
        values = await normalizeValues(
            values,
            superiorManagerIntermediaryField,
            overallEvaluationEstimationManagerField
        );
        if (values.id) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.EMPLOYEE_EVALUATION.EDIT + '/' + values.id, undefined, values)
                .then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED && result.data) {
                        okNotification();
                        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION.EDIT + '/' + values.id).then(
                            (res: any) => {
                                if (res?.status === RESPONSE.OK && res?.data) {
                                    setValues(res.data);
                                }
                            }
                        );
                        if (setLoadingIntermediary) {
                            setLoadingIntermediary(false);
                        }
                    } else {
                        getErrorNotificationMessage(result);
                        if (setLoadingIntermediary) {
                            setLoadingIntermediary(false);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const getCustomFooterContent = (
        <>
            {status && status == enEmployeeEvaluationStatus.COMPLETED && (
                <div style={{ float: 'left' }}>
                    <div style={{ display: 'block', marginLeft: '5px', marginRight: '5px' }}>
                        <NyReportButton
                            url={CONSTANTS_REQ.REPORT.EMPLOYEE_EVALUATION}
                            customParms={{ employeeEvaluationId: Number(dataForm) }}
                            subreportType={'EMPLOYEE_EVALUATION'}
                            buttoni18nText={'report.evaluation'}
                            fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.EMPLOYEE_EVALUATION)}
                            customDateFormat={moment(dateFrom).year()}
                            type={'button'}
                        />
                    </div>
                </div>
            )}
            <div style={{ display: 'inline-block', marginRight: actionsList?.length > 3 ? '12px' : undefined }}>
                {!isIntermediation() && getActionList()}
            </div>
        </>
    );

    const getEditHeader = () => (
        <>
            {editHeader}
            {superiorManagerIntermediary && (
                <div style={{ float: 'right', color: 'red' }}>
                    <WarningOutlined style={{ fontSize: '14px' }} />{' '}
                    {geti18nText('employee.evaluation.edit.intermediation.complete.header')}
                </div>
            )}
        </>
    );

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={getEditHeader()}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_EVALUATION.EDIT}
                setValues={setValues}
                customFooterContent={getCustomFooterContent}
                hideSubmitButton={
                    !(
                        status == enEmployeeEvaluationStatus.INTERMEDIATION &&
                        isSuperiorManager() &&
                        superiorManagerIntermediary
                    )
                }
                width={1900}
                form={form}
                goBack={() => history.goBack()}
                paramsId={id}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={true}
                triggerCloseModal={triggerCloseModal}
                disableSubmitButton={isIntermediation() ? !isSuperiorManager() : false}
                hideActivationButtons={true}
                submitButtonText={
                    isIntermediation()
                        ? geti18nText('employee.evaluation.edit.intermediation.save')
                        : geti18nText('app.default.button.save')
                }
                closeModalButtonText={
                    isIntermediation()
                        ? geti18nText('app.default.button.close')
                        : geti18nText('app.default.button.cancel')
                }
                normalize={(values: any) => {
                    values = normalizeValues(values);
                    return values;
                }}
            >
                <>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <Form
                            layout="vertical"
                            form={form}
                            style={{ maxHeight: activeKey === 'general' ? undefined : '82vh' }}
                        >
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="status" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="employee" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="employeeEvaluationPeriod" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="dueDate" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="directManager" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>

                            {getEditForm()}
                        </Form>
                    )}
                </>
            </NyDataEdit>
            <Modal
                open={reminderModalVisible}
                title={geti18nText('employee.evaluation.edit.sendReminder')}
                okText={geti18nText('app.default.button.send')}
                cancelText={geti18nText('app.default.button.close')}
                onCancel={reminderModalClose}
                onOk={sendReminder}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form form={form} layout="vertical">
                            <Form.Item
                                label={geti18nText('employee.evaluation.edit.message')}
                                name="message"
                                labelAlign={'left'}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default EmployeeEvaluationEditModal;
