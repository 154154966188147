import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import AssetSimpleIndex from '../../../../components/asset-simple/assetSimple';
import TravelWarrantExpensesSearch from '../../../../components/travel-warrant-expenses/search';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumValueForName } from '../../../../utils/Enums';
import { customAssetRenderOption } from '../../../../utils/Utils';
import DepreciationGroupIndex from '../assets/asset-depreciation-group';
import DepreciationGroupEdit from '../assets/asset-depreciation-group/edit';
import TravelWageSearch from '../../../travel-warrant/views/travel-wage/search';
import AssetEdit from '../../../webshop/views/webshop-asset/asset/edit';

const AccountEntity = ({
    selectedGroup,
    selectedType,
    refIdValue,
    refTypeValue,
    extraRefIdValue,
    extraRefTypeValue,
    canCreate,
    onAssetChange,
    form,
}: any) => {
    const enAccountEntityTypeGroup = useEnum('ACCOUNTING_ENTITY_TYPE_GROUP');
    const enAccountEntityType = useEnum('ACCOUNTING_ENTITY_TYPE');

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const disabledForm = () => {
        return refIdValue != null || !canCreate;
    };

    const getGroupCondition = (groupValue: any) => {
        let value: any = refTypeValue
            ? GetEnumValueForName({
                  enumName: 'ACCOUNTING_ENTITY_TYPE_GROUP',
                  name: refTypeValue == 'DEPRECIATION_GROUP' ? 'ASSET_DEPRECIATION_GROUP' : refTypeValue,
              })
            : null;
        if (value?.value) {
            value = value.value;
        }
        return Object.values([value, selectedGroup]).includes(groupValue);
    };

    const getEntityCondition = (entityValue: any) => {
        let value: any = extraRefTypeValue
            ? GetEnumValueForName({
                  enumName: 'ACCOUNTING_ENTITY_TYPE',
                  name: extraRefTypeValue,
              })
            : null;
        if (value?.value) {
            value = value.value;
        }
        return (
            Object.values([value, selectedType]).includes(entityValue) ||
            Object.values([value, selectedType]).includes(entityValue?.value)
        );
    };

    return (
        <>
            {getGroupCondition(enAccountEntityTypeGroup.ASSET) ? (
                <Form.Item label={geti18nText('accountingEntityAccount.edit.ref')} name="asset">
                    <NySearchField
                        style={{ width: '100%' }}
                        url={CONSTANTS_REQ.ASSET.SEARCH}
                        map={{ id: 'id', label: 'label', text: 'text', name: 'name', serialNumber: 'serialNumber' }}
                        renderOption={customAssetRenderOption}
                        searchBy="assetModel.name || serialNumber || inventoryNumber"
                        setDefaultFilterValue={setDefaultFilterValue}
                        AddNewModalComponent={AssetEdit}
                        SearchPopupComponent={<AssetSimpleIndex />}
                        onChange={onAssetChange}
                        disabled={disabledForm()}
                        order="assetModel.name"
                        customListWidth={'1200px'}
                        mustGetPopupContainer={false}
                    />
                </Form.Item>
            ) : getGroupCondition(enAccountEntityTypeGroup.ASSET_DEPRECIATION_GROUP) ? (
                <Form.Item label={geti18nText('accountingEntityAccount.edit.ref')} name="depreciationGroup">
                    <NySearchField
                        url={CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        itemName="name"
                        AddNewModalComponent={DepreciationGroupEdit}
                        setDefaultFilterValue={setDefaultFilterValue}
                        SearchPopupComponent={<DepreciationGroupIndex />}
                        disabled={disabledForm()}
                        mustGetPopupContainer={false}
                    />
                </Form.Item>
            ) : getGroupCondition(enAccountEntityTypeGroup.TRAVEL_WARRANT) ? (
                <>
                    {getEntityCondition(enAccountEntityType.TRAVEL_EXPENSE) ? (
                        <TravelWarrantExpensesSearch
                            disabled={disabledForm()}
                            mustGetPopupContainer={false}
                            label={geti18nText('accountingEntityAccount.edit.ref')}
                            required={false}
                        />
                    ) : getEntityCondition(enAccountEntityType.TRAVEL_WAGE) ? (
                        <TravelWageSearch
                            map={disabledForm() ? { id: 'id', label: 'name' } : undefined}
                            disabled={disabledForm()}
                            mustGetPopupContainer={false}
                            label={geti18nText('accountingEntityAccount.edit.ref')}
                            required={false}
                        />
                    ) : (
                        <div></div>
                    )}
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default AccountEntity;
