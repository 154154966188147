import {
    NyDataTable,
    NyExportCsv,
    NyRequestResolver,
    NySpinner,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Empty, Popover, Radio } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RowAction from '../../../../../components/row-action';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { RootState } from '../../../../../rootReducer';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookCreatedEmployee,
    getColumnSearchByCodebookEmployee,
} from '../../../../../utils/Filters';
import { getEnumArrayStatus, getOrderStatusIcon, isAdmin } from '../../../../../utils/Utils';
import AssetOrderEdit from './edit';

const AssetOrderTable = ({ scroll = { y: 620, x: 800 }, sortOrder, getItemsContent }: any) => {
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const table = useTableSettings();
    const dispatch = useDispatch();
    const [orderViewId, setOrderViewId] = useState<any>(null);
    const [orderViewLabel, setOrderViewLabel] = useState<any>('');
    const [orderViewList, setOrderViewList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [refreshTable, setRefreshTable] = useState<number>(1);
    const [exportFilter, setExportFilter] = useState<any>([]);

    useEffect(() => {
        setLoading(true);
        fetchOrderView();
    }, []);

    useEffect(() => {
        setRefreshTable(refreshTable + 1);
    }, [refreshItem, orderViewId]);

    const canCreate = () => {
        return AssetRights.canCreateOrder();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    let setDefaultFilterValue = () => {
        if (orderViewId != undefined) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'orderView.id',
                    condition: 'equals',
                    value: orderViewId,
                },
            ];
        }

        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setCsvFilter = (search: any) => {
        const filterParsed = JSON.parse(decodeURI(search.search));
        const filter2 = [{ field: 'forExport', condition: 'equals_bool', value: 1 }];

        const newObj = {
            search: encodeURI(JSON.stringify([...filterParsed, ...filter2])),
            max: search.max,
            sort: search.sort,
        };

        setExportFilter(newObj);
    };

    const setDefaultFilterValueOrderView = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 1 },
            { field: 'orderViewAccessType', condition: 'equals', value: 2 },
        ];
    };

    const fetchOrderView = () => {
        const emptyData: any = [];
        if (orderViewList == emptyData || orderViewList.length < 1) {
            let defaultFilterOrder = setDefaultFilterValueOrderView();
            NyRequestResolver.requestGet(CONSTANTS_REQ.ORDER_VIEW.USER_SEARCH, {
                search: encodeURI(JSON.stringify(defaultFilterOrder)),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data[0]) {
                            if (result.data[0].id) setOrderViewId(result.data[0].id);
                            if (result.data[0].name) setOrderViewLabel(result.data[0].name);
                        }

                        let oView: any = [];
                        for (let key in result.data) {
                            if (result.data.hasOwnProperty(key)) {
                                let order = { value: undefined, label: undefined, number: undefined };
                                order.value = result.data[key].id;
                                order.label = result.data[key].name;
                                oView.push(order);
                            }
                        }
                        setOrderViewList(oView);
                    }
                }
                setLoading(false);
            });
        }
    };

    const onChangeOrderView = (e: any) => {
        setOrderViewId(e.target.value > 0 ? e.target.value : null);
    };

    const initialColumns = [
        {
            title: geti18nText('inventoryOrder.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                return (
                    <Popover
                        placement="right"
                        content={getItemsContent(record)}
                        trigger="hover"
                        overlayClassName="ny-popover"
                    >
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'assetOrderEdit',
                                        visible: true,
                                        item: record.id,
                                    })
                                )
                            }
                        >
                            {record.id}
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.ord'),
            dataIndex: [`concat(_ao.ord, '/', date_part('year', _ao.date))`],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.edit.employee'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record.employee?.person) {
                    return (
                        <div>
                            {record.employee.person?.firstName +
                                ' ' +
                                record.employee.person?.lastName +
                                (record.employee.employmentRecordId
                                    ? ' (' + record.employee.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.createdByPerson'),
            dataIndex: [`concat(created_by_person.first_name,' ', created_by_person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookCreatedEmployee(),
            render: (text: any, record: any) => {
                if (record.createdByPerson) {
                    return record.createdByPerson.firstName + ' ' + record.createdByPerson.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.approvedByPerson'),
            dataIndex: [`concat(approved_by_person.first_name,' ', approved_by_person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookCreatedEmployee('approvedByEmployee.id'),
            render: (text: any, record: any) => {
                if (record.approvedByPerson) {
                    return record.approvedByPerson.firstName + ' ' + record.approvedByPerson.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.date'),
            dataIndex: 'date',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.date.approved'),
            dataIndex: 'approved',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.approved) {
                    return moment(record.approved).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(
                getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE').filter(
                    (item: any) => item.id != 5 && item.id != 6 && item.id != 9
                )
            ),
            render: (status: any) => {
                return getOrderStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'AssetOrder'} record={record}></RowAction>;
                }
            },
        },
    ];

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                options={orderViewList}
                value={orderViewId}
                onChange={onChangeOrderView}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '15px' }}
            />
            {isAdmin() || (orderViewList && orderViewList.length > 0) ? (
                <>
                    <div style={{ top: '10px', right: '30px' }}>
                        <NyExportCsv
                            nyId="asset-order-csv-export"
                            url={CONSTANTS_REQ.ASSET_ORDER.LIST}
                            fetchParams={exportFilter}
                            columns={initialColumns}
                            paginationTotal={1000}
                            exportCSV={canExportCSV()}
                            CSVFileName={geti18nText('asset.table.csv')}
                            colCSVCustomization={csvColumnCustomisation()}
                            csvColumns={csvColumns()}
                        />
                    </div>
                    <NyDataTable
                        nyId="asset-order-table"
                        url={CONSTANTS_REQ.ASSET_ORDER.LIST}
                        addNewButtonText={geti18nText('inventoryOrder.table.add')}
                        buttonsClassName="buttons-sticky"
                        showRecordModal={true}
                        modalComponent={AssetOrderEdit}
                        columns={initialColumns}
                        setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                        scroll={scroll}
                        setDefaultFilterValue={setDefaultFilterValue}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        shortcuts={true}
                        onDataLoaded={(data: any, settings: any) => {
                            setCsvFilter(settings);
                        }}
                        hideNewButton={!canCreate()}
                        fetchWhenChange={refreshTable}
                        addedData={{ orderViewId: orderViewId }}
                        idOpenLink={false}
                    />
                </>
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + value);
            },
        },
        {
            employee: (value: any) => {
                return (
                    value != undefined &&
                    value.person != undefined &&
                    value.person.firstName + ' ' + value.person.lastName
                );
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            createdByPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            approvedByPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            approved: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('inventoryOrder.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('inventoryOrder.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('inventoryOrder.table.column.name'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('inventoryOrder.table.column.createdByPerson'),
            dataIndex: 'createdByPerson',
        },
        {
            title: geti18nText('inventoryOrder.table.column.approvedByPerson'),
            dataIndex: 'approvedByPerson',
        },
        {
            title: geti18nText('inventoryOrder.table.column.date'),
            dataIndex: 'date',
        },
        {
            title: geti18nText('inventoryOrder.table.column.date.approved'),
            dataIndex: 'approved',
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('webshopItem.item'),
            dataIndex: 'items',
        },
    ];
};

export default AssetOrderTable;
