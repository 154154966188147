import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Alert, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TravelWarrantPlanStepsDescription from './TravelWarrantPlanStepDescription';

const { Step } = Steps;

const TravelWarrantPlanSteps = ({ travelWarrantId, isPlane = false, isWizard = false, startDate, endDate }: any) => {
    const [loading, setLoading] = useState<any>(false);
    const [TravelWarrantPlansData, setTravelWarrantPlansData] = useState<any>([]);

    useEffect(() => {
        fetch();
    }, []);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travelWarrant.id', condition: 'equals', value: travelWarrantId },
        ];
    };

    const fetch = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_PLAN.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data) {
                    setTravelWarrantPlansData(result.data);
                }
            }
            setLoading(false);
        });
    };

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    {TravelWarrantPlansData && TravelWarrantPlansData.length > 1 && isWizard && (
                        <Alert
                            message={geti18nText('travelWarrantPlan.table.info.wizard')}
                            type="info"
                            showIcon
                            style={{ margin: '5px' }}
                        />
                    )}
                    {isPlane && isWizard && (
                        <>
                            {TravelWarrantPlansData && TravelWarrantPlansData.length > 1 ? (
                                <Alert
                                    message={geti18nText('travelWarrantPlan.table.info.plane')}
                                    type="info"
                                    showIcon
                                    style={{ margin: '5px' }}
                                />
                            ) : (
                                <Alert
                                    message={geti18nText('travelWarrantWizard.step.8')}
                                    type="info"
                                    showIcon
                                    style={{ margin: '5px' }}
                                />
                            )}
                        </>
                    )}
                    <Steps className="travel-warrant-plan-steps" labelPlacement="vertical" direction="vertical">
                        {TravelWarrantPlansData &&
                            TravelWarrantPlansData.map((travelWarrantPlan: any, index: any, array: any) => {
                                let countryName =
                                    travelWarrantPlan.country && travelWarrantPlan.country.name
                                        ? travelWarrantPlan.country.name
                                        : '';
                                let status: any =
                                    travelWarrantPlan.startDate && travelWarrantPlan.endDate ? 'finish' : 'error';

                                return (
                                    <Step
                                        status={status}
                                        title={countryName}
                                        description={
                                            <TravelWarrantPlanStepsDescription
                                                travelWarrantPlan={travelWarrantPlan}
                                                setTravelWarrantPlansData={setTravelWarrantPlansData}
                                                index={index}
                                                maxIndex={array.length - 1}
                                                isWizard={isWizard}
                                                startDate={startDate}
                                                endDate={endDate}
                                                isFirst={
                                                    travelWarrantPlan &&
                                                    TravelWarrantPlansData &&
                                                    TravelWarrantPlansData[0] &&
                                                    travelWarrantPlan.id == TravelWarrantPlansData[0].id
                                                }
                                                isLast={
                                                    travelWarrantPlan &&
                                                    TravelWarrantPlansData &&
                                                    TravelWarrantPlansData.at(-1) &&
                                                    travelWarrantPlan.id == TravelWarrantPlansData.at(-1).id
                                                }
                                            />
                                        }
                                    />
                                );
                            })}
                    </Steps>
                </>
            )}
        </>
    );
};

export default TravelWarrantPlanSteps;
