import { geti18nText, NyInputNumber } from '@nybble/nyreact';
import { Form, Input, InputNumber } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
    let inputNode: any = undefined;
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);

    switch (inputType) {
        case 'quantity':
            inputNode = <InputNumber defaultValue={1} min={1} />;

            break;
        default:
            inputNode = <Input disabled />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
