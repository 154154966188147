import { PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NySpinner } from '@nybble/nyreact';
import { Button, Col, Descriptions, Divider, Form, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { AdministrationRights } from '../../rights/administrationRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumNameForValue } from '../../utils/Enums';
import NyFilesUpload from '../../utils/ny-file-upload-custom';
import { getDateFormat, numberFormat } from '../../utils/Utils';
import TravelWarrantAdditionalInfoViewIndex from '../travel-warrant-additional-info-view';
import TravelWarrantAdditionalInfoViewEdit from '../travel-warrant-additional-info-view/edit';
import TravelWarrantCo2Index from '../travel-warrant-co2';

const { Text } = Typography;

const TravelWarrantSummaryIndex = ({
    componentRefProp,
    printPdfProp,
    setPrintPdfProp,
    employeeTravelWarrant,
    globalForm,
    formSummary,
    isPlane = false,
    currency,
    files = undefined,
    setFiles,
    isOrganizationModal = false,
    printPdfHeader,
    showPrintButton = true,
    id = undefined,
    travelWarrantStatus = undefined,
    refId = undefined,
    setRefreshFiles,
    datePadding = false,
    isNewWizard = false,
    showCo2 = false,
    travelWarrantAdditionalInfo = [],
    setCanContinue = null,
    message = null,
    showFiles = true,
    disabled = false,
}: any) => {
    const { employee } = useSelector((state: RootState) => state.employee);
    const [printPdf, setPrintPdf] = useState(false);
    const componentRef = useRef<any>();
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);
    const enableCarbonFootprint = useApplicationSetting('TRAVEL_WARRANT_ENABLE_CARBON_FOOTPRINT');
    const travelEventNumber = useApplicationSetting('TRAVEL_WARRANT_SHOW_EVENT_NUMBER');
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [travelWarrantAdditionalData, setTravelWarrantAdditionalData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;

    const isDisabledPaidWarrant = () => {
        return (!AdministrationRights.isAdmin() && travelWarrantStatus == travelWarrantStatusEnum.PAID) || disabled;
    };

    const disabledFiles = () => {
        return isDisabledPaidWarrant() || disabled;
    };

    useEffect(() => {
        setPrintPdf(printPdfProp);
    }, [printPdfProp]);

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    useEffect(() => {
        setLoading(true);
        if (!isNewWizard) {
            setAdditionalData(globalForm);
        }
        setLoading(false);
    }, [globalForm]);

    useEffect(() => {
        setLoading(true);
        if (isNewWizard) {
            setTravelWarrantAdditionalData(travelWarrantAdditionalInfo ?? []);
        }
        setLoading(false);
    }, [travelWarrantAdditionalInfo]);

    const setAdditionalData = (globalForm: any) => {
        if (globalForm?.destinationJson) {
            const destinationJson = JSON.parse(globalForm.destinationJson);
            let waypointsList: any = [];
            if (destinationJson != undefined) {
                destinationJson.forEach((element: any) => {
                    if (element && element.relationJson) {
                        const waypoint = JSON.parse(element?.relationJson);
                        waypointsList = waypointsList.concat(waypoint);
                    } else {
                        waypointsList = defaultWaypoints;
                    }
                });
            }
            waypointsList = waypointsList.filter(
                (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
            );
            const lastItem = waypointsList[waypointsList.length - 1];
            if (lastItem?.id == 9999) {
                waypointsList = waypointsList.slice(0, waypointsList.length - 1);
                waypointsList = waypointsList.filter((x: any) => x.id < 9000);
            }

            if (waypointsList.length > 0) {
                let newData = globalForm?.travelWarrantAdditionalInfoList
                    ? [...globalForm.travelWarrantAdditionalInfoList]
                    : [];
                if (newData?.length > 0 && waypointsList?.length > 0) {
                    const newSortedData = waypointsList
                        .filter(
                            (waypoint: any) =>
                                waypoint?.id > 0 &&
                                waypoint?.shortName != '' &&
                                waypoint.placeId != undefined &&
                                waypoint?.id < 9000
                        )
                        .map((item: any) => {
                            const destinationExists: any = newData.find(
                                (dest: any) => dest?.destination == item?.shortName
                            );
                            if (destinationExists && destinationExists?.destination != '') {
                                return destinationExists;
                            } else if (item?.shortName != '') {
                                return { destination: item?.shortName, ...item };
                            }
                        });
                    setTravelWarrantAdditionalData(newSortedData);
                }
            }
        }
    };

    const showCo2Component = () => {
        return (
            TravelWarrantRights.canViewCo2(travelWarrantStatus, employee, notificationType, travelWarrantStatusEnum) &&
            enableCarbonFootprint == 1 &&
            showCo2 &&
            id
        );
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }
    return (
        <React.Fragment>
            {loading ? (
                <NySpinner />
            ) : (
                <div ref={componentRefProp ? componentRefProp : componentRef}>
                    <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={formSummary}>
                        {(printPdf || printPdfProp) && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Divider orientation="left">
                                        <b>{printPdfHeader}</b>
                                    </Divider>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={24}>
                            <Col span={12}>
                                <Descriptions
                                    column={1}
                                    bordered
                                    title={geti18nText('travelWarrantWizard.step.1')}
                                    size="small"
                                    style={{ color: isDarkTheme ? 'white' : 'black' }}
                                    className={printPdf ? 'ant-typography-print-title' : ''}
                                >
                                    {isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print' : ''}
                                            label={geti18nText('travelWarrantOrganization.table.column.ordinal')}
                                        >
                                            <Text>{globalForm && globalForm.ordinal ? globalForm.ordinal : '-'}</Text>
                                        </Descriptions.Item>
                                    )}
                                    {isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print' : ''}
                                            label={geti18nText(
                                                'travelWarrantOrganization.table.column.businessUnit.ordinal'
                                            )}
                                        >
                                            <Text>
                                                {globalForm && globalForm.additionalInfoOrdinal && globalForm.created
                                                    ? globalForm.additionalInfoOrdinal +
                                                      '/' +
                                                      moment(globalForm.created).format('YYYY')
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {isOrganizationModal && (
                                        <Descriptions.Item
                                            label={geti18nText('travelWarrantOrganization.table.column.warrantDate')}
                                        >
                                            <Text>
                                                {globalForm && globalForm.warrantDate
                                                    ? getDateFormat(globalForm.warrantDate, 'DD.MM.YYYY')
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {isOrganizationModal && (
                                        <Descriptions.Item
                                            label={geti18nText('travelWarrantOrganization.table.column.status')}
                                        >
                                            <Text>
                                                {globalForm && globalForm.status
                                                    ? geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + globalForm.status)
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}

                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantOrganization.table.column.employee')}
                                    >
                                        <Text className={'print-style-blue'} style={{ fontWeight: '700' }}>
                                            {employeeTravelWarrant &&
                                            employeeTravelWarrant.person &&
                                            employeeTravelWarrant.person.firstName &&
                                            employeeTravelWarrant.person.lastName &&
                                            employeeTravelWarrant.employmentRecordId
                                                ? employeeTravelWarrant.person.firstName +
                                                  ' ' +
                                                  employeeTravelWarrant.person.lastName +
                                                  ' (' +
                                                  employeeTravelWarrant.employmentRecordId +
                                                  ')'
                                                : employeeTravelWarrant &&
                                                  employeeTravelWarrant.person &&
                                                  employeeTravelWarrant.person.firstName &&
                                                  employeeTravelWarrant.person.lastName
                                                ? employeeTravelWarrant.person.firstName +
                                                  ' ' +
                                                  employeeTravelWarrant.person.lastName
                                                : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                    {isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrantOrganization.table.column.email')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                {employeeTravelWarrant &&
                                                employeeTravelWarrant.contact &&
                                                employeeTravelWarrant.contact.email ? (
                                                    <a href={`mailto:${employeeTravelWarrant.contact.email}`}>
                                                        {employeeTravelWarrant.contact.email}
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrantOrganization.table.column.mobile')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-text' : ''}>
                                                {employeeTravelWarrant &&
                                                employeeTravelWarrant.contact &&
                                                employeeTravelWarrant.contact.mobile
                                                    ? employeeTravelWarrant.contact.mobile
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {!isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrantWizard.table.column.vocation')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                {globalForm && globalForm.employeeVocation
                                                    ? globalForm.employeeVocation
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {!isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrantOrganization.table.column.businessUnit')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                {employeeTravelWarrant &&
                                                employeeTravelWarrant.businessUnit &&
                                                employeeTravelWarrant.businessUnit.name &&
                                                employeeTravelWarrant.businessUnit.code
                                                    ? employeeTravelWarrant.businessUnit.name +
                                                      ' (' +
                                                      employeeTravelWarrant.businessUnit.code +
                                                      ')'
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {!isOrganizationModal &&
                                        globalForm &&
                                        globalForm.accountingCostCenter &&
                                        globalForm.accountingCostCenter.name && (
                                            <Descriptions.Item
                                                className={printPdf ? 'ant-typography-print-desc' : ''}
                                                label={geti18nText('travelWarrantOrganization.table.column.costCenter')}
                                            >
                                                <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                    {globalForm && globalForm.accountingCostCenter
                                                        ? globalForm.accountingCostCenter.name
                                                        : '-'}
                                                </Text>
                                            </Descriptions.Item>
                                        )}
                                    {!isOrganizationModal &&
                                        globalForm &&
                                        globalForm.accountingCostObject &&
                                        globalForm.accountingCostObject.name && (
                                            <Descriptions.Item
                                                className={printPdf ? 'ant-typography-print-desc' : ''}
                                                label={geti18nText('travelWarrantOrganization.table.column.costObject')}
                                            >
                                                <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                    {globalForm && globalForm.accountingCostObject
                                                        ? globalForm.accountingCostObject.name
                                                        : '-'}
                                                </Text>
                                            </Descriptions.Item>
                                        )}
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantOrganization.table.column.startDate')}
                                    >
                                        <Text style={{ fontWeight: '700' }} className={'print-style-blue'}>
                                            {globalForm && globalForm.startDate
                                                ? getDateFormat(globalForm.startDate, 'DD.MM.YYYY')
                                                : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantOrganization.table.column.endDate')}
                                    >
                                        <Text style={{ fontWeight: '700' }} className={'print-style-blue'}>
                                            {globalForm && globalForm.endDate
                                                ? getDateFormat(globalForm.endDate, 'DD.MM.YYYY')
                                                : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantPersonal.edit.duration')}
                                    >
                                        <Text style={{ fontWeight: '700' }} className={'print-style-blue'}>
                                            {globalForm && globalForm.duration ? globalForm.duration : '0'}
                                        </Text>
                                    </Descriptions.Item>
                                    {!isOrganizationModal && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrantOrganization.table.column.purpose')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                {globalForm && globalForm.purposeInfo ? globalForm.purposeInfo : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    {travelEventNumber > 0 ? (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrant.edit.travelEventNumber')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                {globalForm && globalForm?.travelEventNumber?.name
                                                    ? globalForm?.travelEventNumber?.name +
                                                      (globalForm?.travelEventNumber?.code
                                                          ? ' (' + globalForm?.travelEventNumber?.code + ')'
                                                          : '')
                                                    : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    ) : null}
                                </Descriptions>
                            </Col>
                            {((!printPdf && !printPdfProp) || travelWarrantAdditionalData?.length == 1) && (
                                <Col span={12}>
                                    <TravelWarrantAdditionalInfoViewIndex
                                        data={travelWarrantAdditionalData}
                                        printPdf={printPdf}
                                        travelWarrantOrdinal={
                                            globalForm && globalForm.additionalInfoOrdinal && globalForm.created
                                                ? globalForm.additionalInfoOrdinal +
                                                  '/' +
                                                  moment(globalForm.created).format('YYYY')
                                                : '-'
                                        }
                                        isPlane={isPlane}
                                    />
                                </Col>
                            )}
                        </Row>
                        {(printPdf || printPdfProp) && travelWarrantAdditionalData?.length > 1 && (
                            <Row gutter={24}>
                                <Col span={12}>
                                    <>
                                        {travelWarrantAdditionalData.map((row: any) => {
                                            return (
                                                <>
                                                    <b>{row.destination}</b>
                                                    <TravelWarrantAdditionalInfoViewEdit
                                                        item={row}
                                                        printPdf={printPdf}
                                                        travelWarrantOrdinal={
                                                            globalForm &&
                                                            globalForm.additionalInfoOrdinal &&
                                                            globalForm.created
                                                                ? globalForm.additionalInfoOrdinal +
                                                                  '/' +
                                                                  moment(globalForm.created).format('YYYY')
                                                                : '-'
                                                        }
                                                        isPlane={isPlane}
                                                    />
                                                </>
                                            );
                                        })}
                                    </>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={24}>
                            <Col span={12}>
                                <Descriptions
                                    style={{ marginTop: '10px' }}
                                    column={1}
                                    bordered
                                    title={geti18nText('travelWarrantWizard.step.2')}
                                    size="small"
                                    className={printPdf ? 'ant-typography-print-title' : ''}
                                >
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantOrganization.table.column.vehicle')}
                                    >
                                        <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                            {globalForm && globalForm.travelVehicle && globalForm.travelVehicle.name
                                                ? globalForm.travelVehicle.name
                                                : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                    {globalForm && globalForm.travelVehicle2 && globalForm.travelVehicle2.id > 0 && (
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText('travelWarrantOrganization.table.column.vehicle2')}
                                        >
                                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                                {globalForm.travelVehicle2.name ? globalForm.travelVehicle2.name : '-'}
                                            </Text>
                                        </Descriptions.Item>
                                    )}
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantOrganization.table.column.startingPoint')}
                                    >
                                        <Text className={'print-style-blue'} style={{ fontWeight: '700' }}>
                                            {globalForm && globalForm.startingPoint ? globalForm.startingPoint : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={geti18nText('travelWarrantOrganization.table.column.destination')}
                                    >
                                        <Text className={'print-style-blue'} style={{ fontWeight: '700' }}>
                                            {globalForm && globalForm.destination ? globalForm.destination : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                            {!isOrganizationModal && (
                                <Col span={12}>
                                    <Descriptions
                                        style={{ marginTop: '10px' }}
                                        column={1}
                                        bordered
                                        title={geti18nText('travelWarrantWizard.step.4')}
                                        size="small"
                                        className={printPdf ? 'ant-typography-print-title' : ''}
                                    >
                                        <Descriptions.Item
                                            className={printPdf ? 'ant-typography-print-desc' : ''}
                                            label={geti18nText(
                                                'travelWarrantWizard.table.column.advancePaymentInCurrency'
                                            )}
                                        >
                                            <Text className={'print-style-blue'} style={{ fontWeight: '700' }}>
                                                {globalForm && globalForm.advancePaymentInCurrency
                                                    ? numberFormat(globalForm.advancePaymentInCurrency)
                                                    : '0,00'}{' '}
                                                {currency != undefined && currency != null && currency.text
                                                    ? currency.text
                                                    : ''}
                                            </Text>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            )}
                        </Row>
                        {showCo2Component() && showCo2 && (
                            <>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Descriptions
                                            style={{ marginTop: '10px' }}
                                            column={1}
                                            bordered
                                            title={geti18nText('travelWarrantCo2.table.header')}
                                            size="small"
                                            className={printPdf ? 'ant-typography-print-title' : ''}
                                        ></Descriptions>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <TravelWarrantCo2Index
                                            travelWarrantId={id}
                                            employeeTravelWarrantId={employeeTravelWarrant?.id}
                                            travelWarrantStatus={travelWarrantStatus}
                                            printPdf={printPdf || printPdfProp}
                                            travelVehicle={globalForm?.travelVehicle}
                                            travelAdditionalData={travelWarrantAdditionalData}
                                            isBoldText
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row gutter={24}>
                            <Col span={24}>
                                <Descriptions
                                    style={{ marginTop: '10px' }}
                                    column={1}
                                    bordered
                                    title={geti18nText('travelWarrantWizard.step.5')}
                                    size="small"
                                    className={printPdf ? 'ant-typography-print-title' : ''}
                                >
                                    <Descriptions.Item
                                        className={printPdf ? 'ant-typography-print-desc' : ''}
                                        label={
                                            <span style={{ fontWeight: '500' }}>
                                                {geti18nText('travelWarrantOrganization.table.column.note')}
                                            </span>
                                        }
                                    >
                                        <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                            {globalForm && globalForm.note && globalForm.note.note
                                                ? globalForm.note.note
                                                : '-'}
                                        </Text>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        {showFiles && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <NyFilesUpload
                                        editUrl={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.EDIT}
                                        documentType={
                                            isOrganizationModal
                                                ? documentTypeEnum.TRAVEL_WARRANT_ORGANIZATION
                                                : documentTypeEnum.TRAVEL_WARRANT_FILE
                                        }
                                        dataSourceFiles={files}
                                        setDataSourceFiles={setFiles}
                                        ref={uploadFilesRef}
                                        id={id}
                                        refId={refId}
                                        canAddNewFile={
                                            travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL &&
                                            ((!TravelWarrantRights.canCreateTravelWarrantActions(
                                                employee,
                                                notificationType
                                            ) &&
                                                !isNewWizard) ||
                                                isNewWizard)
                                        }
                                        canEdit={
                                            (!TravelWarrantRights.canCreateTravelWarrantActions(
                                                employee,
                                                notificationType
                                            ) &&
                                                !isNewWizard) ||
                                            isNewWizard
                                        }
                                        canDelete={
                                            (!TravelWarrantRights.canCreateTravelWarrantActions(
                                                employee,
                                                notificationType
                                            ) &&
                                                !isNewWizard) ||
                                            isNewWizard
                                        }
                                        employee={employee}
                                        employeeTravelWarrantId={employeeTravelWarrant?.id}
                                        setRefreshDataSourceFiles={isNewWizard ? null : setRefreshFiles}
                                        showDocumentTypeColumn={true}
                                        isTravelWarrantEdit={true}
                                        datePadding={datePadding}
                                        isOrganizationModal={isOrganizationModal}
                                        travelWarrantAdditionalData={travelWarrantAdditionalData}
                                        hasNoRefId={isOrganizationModal}
                                        printPdf={printPdf || printPdfProp}
                                        setCanContinue={setCanContinue}
                                        maxHeight={'205px'}
                                        isTravelWarrant={true}
                                        moduleAccessRight={moduleAccessRight}
                                        readonly={disabledFiles()}
                                    />
                                </Col>
                            </Row>
                        )}
                        {(printPdf || printPdfProp) && message && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Descriptions
                                        column={1}
                                        bordered
                                        size="small"
                                        style={{ color: isDarkTheme ? 'white' : 'black' }}
                                        className={printPdf ? 'ant-typography-print-title' : ''}
                                    >
                                        <Descriptions.Item
                                            label={geti18nText('travelWarrantOrganization.table.column.actions.note')}
                                        >
                                            {message}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
            )}
            <ReactToPrint
                trigger={() => {
                    return (
                        <>
                            {showPrintButton && (
                                <Button key={'print'} style={{ float: 'right' }} icon={<PrinterOutlined />}>
                                    {geti18nText('travelWarrant.action.pdf.short')}
                                </Button>
                            )}
                        </>
                    );
                }}
                onBeforeGetContent={() => {
                    const timeout = setTimeout(() => {
                        setPrintPdf(true);
                        if (printPdfProp && setPrintPdfProp) setPrintPdfProp(true);
                        return Promise.resolve();
                    }, 2000);
                }}
                onAfterPrint={() => {
                    setPrintPdf(false);
                    if (printPdfProp && setPrintPdfProp) setPrintPdfProp(false);
                }}
                content={() => (componentRefProp ? componentRefProp.current : componentRef.current)}
                pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
            />
        </React.Fragment>
    );
};

export default TravelWarrantSummaryIndex;
