import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';

interface IAttendanceState {
    workLoggedIn?: boolean | undefined;
    hasLoggedInToday?: boolean;
    dontShowModal?: boolean;
    attendanceDate?: any;
    employeeId?: any;
}

const initialState: IAttendanceState = {
    workLoggedIn: undefined,
    hasLoggedInToday: false,
    dontShowModal: false,
    attendanceDate: moment().format('yyyy-MM-DD'),
    employeeId: undefined,
};

const attendance = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        attendanceInit(state) {
            state.workLoggedIn = undefined;
            state.hasLoggedInToday = false;
            state.dontShowModal = false;
            state.attendanceDate = moment().format('yyyy-MM-DD');
            state.employeeId = undefined;
        },
        setAttendance(state, action: PayloadAction<IAttendanceState>) {
            const { workLoggedIn, hasLoggedInToday } = action.payload;
            state.hasLoggedInToday = hasLoggedInToday;
            state.workLoggedIn = workLoggedIn;
        },
        setWorkLoggedIn(state, action: PayloadAction<IAttendanceState>) {
            const { workLoggedIn } = action.payload;
            state.workLoggedIn = workLoggedIn;
        },
        setHasLoggedInToday(state, action: PayloadAction<IAttendanceState>) {
            const { hasLoggedInToday } = action.payload;
            state.hasLoggedInToday = hasLoggedInToday;
        },
        setLoggedInToday(state, action: PayloadAction<IAttendanceState>) {
            const { hasLoggedInToday } = action.payload;
            state.hasLoggedInToday = hasLoggedInToday;
        },
        setDontShowModal(state, action: PayloadAction<IAttendanceState>) {
            const { dontShowModal } = action.payload;
            state.dontShowModal = dontShowModal;
        },
        setEmployeeId(state, action: PayloadAction<IAttendanceState>) {
            const { employeeId } = action.payload;
            state.employeeId = employeeId;
        },
    },
});

export const {
    attendanceInit,
    setAttendance,
    setLoggedInToday,
    setWorkLoggedIn,
    setDontShowModal,
    setHasLoggedInToday,
    setEmployeeId,
} = attendance.actions;

export default attendance.reducer;

export const fetchAttendanceStatus =
    (employeeId: any): AppThunk =>
    async (dispatch) => {
        dispatch(setEmployeeId({ employeeId: employeeId }));
        NyRequestResolver.requestGet(CONSTANTS_REQ.ATTENDANCE.LOGGED_IN, {
            employeeId: employeeId,
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                dispatch(setWorkLoggedIn({ workLoggedIn: result.data }));
            }
        });

        NyRequestResolver.requestGet(CONSTANTS_REQ.ATTENDANCE.HAS_LOGGED_IN_TODAY, {
            employeeId: employeeId,
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                dispatch(setHasLoggedInToday({ hasLoggedInToday: result.data }));
            }
        });
    };
