import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { setModalVisible } from '../../slices/modalOpenSlice';
import EmployeeAnnualLeaveEdit from '../../views/human/views/employee-leave/EmployeeAnnualLeaveEdit';
import EmployeeLeaveReqEdit from '../../views/human/views/employee-leave/EmployeeLeaveReqEdit';
import EmployeePaymentEdit from '../../views/human/views/employee-payment/edit';
import EmployeeFamilyMemberEdit from '../../views/human/views/employee/EmployeeFamilyMemberEdit';
import CreateTaskIndex from '../../views/tasks/views/task-list/CreateTaskIndex';
import CreateTaskUserIndex from '../../views/task-service/CreateTaskUserIndex';
import TravelWarrantEdit from '../../views/travel-warrant/views/travel-warrant/edit';
import TravelWarrantPersonalWizardNew from '../../views/travel-warrant/views/travel-warrant/wizard/wizard-new';
import TaskApprovalModal from '../../views/tasks/views/task-list/modal/TaskApprovalModal';
import InventoryOrderEdit from '../../views/webshop/views/webshop-item/inventory-order/edit';
import AssetOrderEdit from '../../views/webshop/views/webshop-asset/asset-order/edit';
import InventoryReleaseEdit from '../../views/webshop/views/webshop-item/inventory-release/edit';
import AssetReleaseEdit from '../../views/webshop/views/webshop-asset/asset-release/edit';
import AssetReturnEdit from '../../views/webshop/views/webshop-asset/asset-return/edit';
import AssetEdit from '../../views/webshop/views/webshop-asset/asset/edit';
import MealOrderWizard from '../../views/webshop/views/meals/meals-overview/wizard';
import ServicesOrderEdit from '../../views/webshop/views/webshop-services/services-order/edit';
import EmployeeSimpleEdit from '../../views/human/views/employee-simple/edit';
import BusinessUnitEdit from '../../views/human/views/business-unit/edit';
import WorkplaceEdit from '../../views/human/views/workplace/edit';
import EmployeeEducationNewWizard from '../../views/human/views/education/employee-education/edit/educationNewWizard';
import EmployeeEducationSummary from '../../views/human/views/education/employee-education/edit/educationSummary';
import EmployeeEducationEdit from '../../views/human/views/education/employee-education/edit/edit';
import EmployeeEvaluationEditModal from '../../views/human/views/employee-evaluation/edit-modal/edit';
import EmployeeWorkObligationEdit from '../../views/human/views/employee-mobilization/edit';

const ModalViewIndex = () => {
    const dispatch = useDispatch();
    const { visible, item, modal, onSave, onSaveAndGetID, onSaveAndGetData } = useSelector(
        (state: RootState) => state.modalOpen
    );

    const Modals: any = {
        employeeLeaveEdit: EmployeeLeaveReqEdit,
        employeePaymentEdit: EmployeePaymentEdit,
        inventoryOrderEdit: InventoryOrderEdit,
        servicesOrderEdit: ServicesOrderEdit,
        assetOrderEdit: AssetOrderEdit,
        inventoryReleaseEdit: InventoryReleaseEdit,
        assetReleaseEdit: AssetReleaseEdit,
        assetReturnEdit: AssetReturnEdit,
        assetEdit: AssetEdit,
        travelWarrantEdit: TravelWarrantEdit,
        travelWarrantPersonalWizardNew: TravelWarrantPersonalWizardNew,
        employeeAnnualLeaveEdit: EmployeeAnnualLeaveEdit,
        mealOrderWizard: MealOrderWizard,
        createTaskIndex: CreateTaskIndex,
        createTaskUserIndex: CreateTaskUserIndex,
        taskApprovalModal: TaskApprovalModal,
        employeeFamilyMemberEdit: EmployeeFamilyMemberEdit,
        employeeSimpleEdit: EmployeeSimpleEdit,
        businessUnitEdit: BusinessUnitEdit,
        workplaceEdit: WorkplaceEdit,
        employeeEducationEdit: EmployeeEducationEdit,
        employeeEvaluationEdit: EmployeeEvaluationEditModal,
        employeeWorkObligationEdit: EmployeeWorkObligationEdit,
    };

    return (
        <>
            {item !== undefined &&
                modal != undefined &&
                Modals[modal] &&
                visible &&
                React.createElement(
                    Modals[modal],
                    {
                        isModal: true,
                        visible: visible,
                        setVisible: (visible: any) => dispatch(setModalVisible(visible)),
                        value: item ? { id: item } : undefined,
                        onSave: onSave ? () => window.location.reload() : undefined,
                        onSaveAndGetID: onSaveAndGetID ? () => window.location.reload() : undefined,
                        onSaveAndGetData: onSaveAndGetData ? () => window.location.reload() : undefined,
                        addedData: undefined,
                    },
                    null
                )}
        </>
    );
};

export default ModalViewIndex;
