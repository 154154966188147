import {
    BookOutlined,
    BulbOutlined,
    ContainerOutlined,
    ControlOutlined,
    CreditCardOutlined,
    EuroOutlined,
    FileDoneOutlined,
    FileOutlined,
    HomeOutlined,
    LaptopOutlined,
    MobileOutlined,
    SelectOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    SwapOutlined,
    TabletOutlined,
    TagOutlined,
    TagsOutlined,
    ToolOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { AdministrationRights } from '../../rights/administrationRights';
import { AssetRights } from '../../rights/assetRights';
import { InventoryRights } from '../../rights/inventoryRights';
import { MealsRights } from '../../rights/mealsRights';
import { ServicesRights } from '../../rights/servicesRights';

const WebshopModuleMenu = [
    {
        key: 'menu.webshop',
        path: '/webshop',
        icon: <HomeOutlined />,
        i18n: 'menu.home',
        role: AssetRights.canViewWebshopMenu(),
    },
    {
        path: '/webshop/webshop-asset',
        icon: <ShopOutlined />,
        i18n: 'menu.asset',
        notificationType: 8,
        role: AssetRights.canViewRequestMenu(),
        submenu: [
            {
                key: 'menu.webshopAsset',
                path: '/webshop/webshop-asset',
                icon: <ShoppingCartOutlined />,
                i18n: 'menu.webshop',
                role: AssetRights.canViewRequestMenu(),
            },
            {
                key: 'menu.asset.order',
                path: '/webshop/webshop-asset/asset-order',
                icon: <ShoppingOutlined />,
                i18n: 'menu.orders',
                notificationType: 8,
                role: AssetRights.canViewOrderViewMenu(),
            },
            {
                key: 'menu.asset.release',
                path: '/webshop/webshop-asset/asset-release',
                icon: <SwapOutlined />,
                i18n: 'menu.releases',
                role: AssetRights.canViewReleaseMenu(),
            },
            {
                key: 'menu.asset.return',
                path: '/webshop/webshop-asset/asset-return',
                icon: <SelectOutlined />,
                i18n: 'menu.asset.return',
                role: AssetRights.canViewReturnMenu(),
            },
            {
                key: 'menu.asset.overview',
                path: '/webshop/webshop-asset/asset-overview',
                icon: <UnorderedListOutlined />,
                i18n: 'menu.asset.overview',
                role: AssetRights.canViewOverviewMenu(),
            },
            {
                key: 'menu.asset.inventory',
                path: '/webshop/webshop-asset/asset-inventory',
                icon: <FileDoneOutlined />,
                i18n: 'menu.asset.inventory',
                role: AssetRights.canViewInventoryMenu(),
            },
            {
                key: 'menu.asset.settings',
                path: '/webshop/webshop-asset/settings',
                icon: <SettingOutlined />,
                i18n: 'menu.settings',
                role: AssetRights.canCreateSettingsMenu(),
                submenu: [
                    {
                        key: 'menu.asset.order.view',
                        path: '/webshop/webshop-asset/settings/asset-order-view',
                        icon: <ToolOutlined />,
                        i18n: 'menu.order.view',
                        role: AssetRights.canCreateSettingsMenu(),
                        width: 85,
                    },
                    {
                        key: 'menu.asset.notification.settings',
                        path: '/webshop/webshop-asset/settings/asset-notification-settings',
                        icon: <ControlOutlined />,
                        i18n: 'menu.notification.settings',
                        role: AssetRights.canCreateSettingsMenu(),
                    },
                ],
            },
        ],
    },
    {
        path: '/webshop/webshop-item',
        icon: <ShopOutlined />,
        i18n: 'menu.material',
        notificationType: 7,
        role: InventoryRights.canViewRequestMenu(),
        submenu: [
            {
                key: 'menu.webshopItem',
                path: '/webshop/webshop-item',
                icon: <ShoppingCartOutlined />,
                i18n: 'menu.webshop',
                role: InventoryRights.canViewRequestMenu(),
            },
            {
                key: 'menu.inventory.order',
                path: '/webshop/webshop-item/inventory-order',
                icon: <ShoppingOutlined />,
                i18n: 'menu.orders',
                notificationType: 7,
                role: InventoryRights.canViewOrderViewMenu(),
            },
            {
                key: 'menu.inventory.release',
                path: '/webshop/webshop-item/inventory-release',
                icon: <SwapOutlined />,
                i18n: 'menu.releases',
                role: InventoryRights.canViewReleaseMenu(),
            },
            {
                key: 'menu.inventory.settings',
                path: '/webshop/webshop-item/settings',
                icon: <SettingOutlined />,
                i18n: 'menu.settings',
                role: InventoryRights.canCreateSettingsMenu(),
                submenu: [
                    {
                        key: 'menu.inventory.order.view',
                        path: '/webshop/webshop-item/settings/inventory-order-view',
                        icon: <ToolOutlined />,
                        i18n: 'menu.order.view',
                        role: InventoryRights.canCreateSettingsMenu(),
                    },
                    {
                        key: 'menu.inventory.notification.settings',
                        path: '/webshop/webshop-item/settings/inventory-notification-settings',
                        icon: <ControlOutlined />,
                        i18n: 'menu.notification.settings',
                        role: InventoryRights.canCreateSettingsMenu(),
                    },
                ],
            },
        ],
    },
    {
        path: '/webshop/webshop-services',
        icon: <ShopOutlined />,
        i18n: 'menu.services',
        notificationType: 9,
        role: ServicesRights.canViewRequestMenu(),
        submenu: [
            {
                key: 'menu.webshopServices',
                path: '/webshop/webshop-services',
                icon: <ShoppingCartOutlined />,
                i18n: 'menu.webshop',
                role: ServicesRights.canViewRequestMenu(),
            },
            {
                key: 'menu.services.order',
                path: '/webshop/webshop-services/services-order',
                icon: <ShoppingOutlined />,
                i18n: 'menu.orders',
                notificationType: 9,
                role: ServicesRights.canViewOrderViewMenu(),
            },
            {
                key: 'menu.services.release',
                path: '/webshop/webshop-services/services-release',
                icon: <SwapOutlined />,
                i18n: 'menu.releases',
                role: ServicesRights.canViewReleaseMenu(),
            },
            {
                key: 'menu.services.settings',
                path: '/webshop/webshop-services/settings',
                icon: <SettingOutlined />,
                i18n: 'menu.settings',
                role: ServicesRights.canCreateSettingsMenu(),
                submenu: [
                    {
                        key: 'menu.services.order.view',
                        path: '/webshop/webshop-services/settings/services-order-view',
                        icon: <ToolOutlined />,
                        i18n: 'menu.order.view',
                        role: ServicesRights.canCreateSettingsMenu(),
                    },
                    {
                        key: 'menu.services.notification.settings',
                        path: '/webshop/webshop-services/settings/services-notification-settings',
                        icon: <ControlOutlined />,
                        i18n: 'menu.notification.settings',
                        role: ServicesRights.canCreateSettingsMenu(),
                    },
                ],
            },
        ],
    },
    {
        path: '/webshop/meals',
        icon: <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-utensils" />,
        i18n: 'menu.meals',
        role: MealsRights.isOnlyMealsUserMenu(),
        notificationType: 26,
        submenu: [
            {
                key: 'menu.meals',
                path: '/webshop/meals',
                icon: <ShoppingCartOutlined />,
                i18n: 'menu.webshop',
                notificationType: 26,
                role: MealsRights.isOnlyMealsUserMenu(),
            },
            {
                key: 'menu.meals.payment',
                path: '/webshop/meals/meal-payment',
                icon: <CreditCardOutlined />,
                i18n: 'menu.meals.payment',
                role: MealsRights.isMealsAdminMenu(),
                isForHnb: true,
            },
            {
                key: 'menu.meals.meal.orders',
                path: '/webshop/meals/meal-orders',
                icon: <ShoppingOutlined />,
                i18n: 'menu.meals.meal.orders',
                role: MealsRights.isMealsAdminMenu(),
            },
            {
                key: 'menu.meals.group.order.menu',
                path: '/webshop/meals/meal-group-orders',
                icon: <ToolOutlined />,
                i18n: 'menu.meals.group.order.menu',
                role: MealsRights.isMealsAdminMenu(),
            },
            {
                key: 'menu.meals.meal.logs',
                path: '/webshop/meals/meal-log-table',
                icon: <UnorderedListOutlined />,
                i18n: 'menu.meals.meal.logs',
                role: AdministrationRights.isAdminMenu(),
            },
            {
                key: 'menu.meals.settings',
                path: '/webshop/meals/meals-settings',
                icon: <SettingOutlined />,
                i18n: 'menu.meals.settings',
                role: MealsRights.isMealsAdminMenu(),
            },
        ],
    },
    {
        key: 'menu.webshop.codebooks',
        path: '/webshop/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.codebooks',
        role: AssetRights.canViewWebshopCodebooksMenu(),
        submenu: [
            {
                key: 'menu.material.codebooks',
                path: '/webshop/codebooks/webshop-item',
                icon: <ShopOutlined />,
                i18n: 'menu.material',
                role: InventoryRights.canViewInventoryCodebooksMenu(),
                submenu: [
                    {
                        key: 'menu.material.item.group',
                        path: '/webshop/codebooks/webshop-item/item-group-material',
                        icon: <TagsOutlined />,
                        i18n: 'menu.item.group',
                        role: InventoryRights.canViewInventoryCodebooksMenu(),
                    },
                    {
                        key: 'menu.material.item',
                        path: '/webshop/codebooks/webshop-item/item-material',
                        icon: <TagOutlined />,
                        i18n: 'menu.item',
                        role: InventoryRights.canViewInventoryCodebooksMenu(),
                    },
                ],
            },
            {
                key: 'menu.asset.codebooks',
                path: '/webshop/codebooks/webshop-asset',
                icon: <ShopOutlined />,
                i18n: 'menu.asset',
                role: AssetRights.canViewAssetCodebooksMenu(),
                submenu: [
                    {
                        key: 'menu.asset.type',
                        path: '/webshop/codebooks/webshop-asset/asset-type',
                        icon: <MobileOutlined />,
                        i18n: 'menu.asset.type',
                        role: AssetRights.canViewAssetCodebooksMenu(),
                    },
                    {
                        key: 'menu.asset.model',
                        path: '/webshop/codebooks/webshop-asset/asset-model',
                        icon: <TabletOutlined />,
                        i18n: 'menu.asset.model',
                        role: AssetRights.canViewAssetCodebooksMenu(),
                    },
                    {
                        key: 'menu.asset.codebook',
                        path: '/webshop/codebooks/webshop-asset/asset',
                        icon: <LaptopOutlined />,
                        i18n: 'menu.asset',
                        role: AssetRights.canViewAssetCodebooksMenu(),
                    },
                    {
                        key: 'menu.asset.category',
                        path: '/webshop/codebooks/webshop-asset/asset-category',
                        icon: <ContainerOutlined />,
                        i18n: 'menu.asset.category',
                        role: AssetRights.canViewAssetCodebooksMenu(),
                    },
                    {
                        key: 'menu.asset.item.group',
                        path: '/webshop/codebooks/webshop-asset/item-group-asset',
                        icon: <TagsOutlined />,
                        i18n: 'menu.item.group',
                        role: AssetRights.canViewAssetCodebooksMenu(),
                    },
                    {
                        key: 'menu.asset.item',
                        path: '/webshop/codebooks/webshop-asset/item-asset',
                        icon: <TagOutlined />,
                        i18n: 'menu.item',
                        role: AssetRights.canViewAssetCodebooksMenu(),
                    },
                ],
            },
            {
                key: 'menu.services.codebooks',
                path: '/webshop/codebooks/webshop-services',
                icon: <ShopOutlined />,
                i18n: 'menu.services',
                role: ServicesRights.canViewServicesCodebooksMenu(),
                submenu: [
                    {
                        key: 'menu.services.item.group',
                        path: '/webshop/codebooks/webshop-services/item-group-service',
                        icon: <TagsOutlined />,
                        i18n: 'menu.item.group',
                        role: ServicesRights.canViewServicesCodebooksMenu(),
                    },
                    {
                        key: 'menu.services.item',
                        path: '/webshop/codebooks/webshop-services/item-service',
                        icon: <TagOutlined />,
                        i18n: 'menu.item',
                        role: ServicesRights.canViewServicesCodebooksMenu(),
                    },
                ],
            },
            {
                key: 'menu.meals.codebooks',
                path: '/webshop/codebooks/meals',
                icon: (
                    <i
                        style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                        className="las la-utensils"
                    />
                ),
                i18n: 'menu.meals',
                role: MealsRights.isMealsAdminMenu(),
                submenu: [
                    {
                        key: 'menu.meals.restaurant',
                        path: '/webshop/codebooks/meals/restaurant',
                        icon: <FileOutlined />,
                        i18n: 'menu.meals.restaurant',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                    {
                        key: 'menu.meals.deliverySpot',
                        path: '/webshop/codebooks/meals/delivery-spot',
                        icon: <FileOutlined />,
                        i18n: 'menu.meals.deliverySpot',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                    {
                        key: 'menu.meals.meal',
                        path: '/webshop/codebooks/meals/meal',
                        icon: <TagOutlined />,
                        i18n: 'menu.meals.meal',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                    {
                        key: 'menu.meals.meal.group',
                        path: '/webshop/codebooks/meals/meal-group',
                        icon: <TagsOutlined />,
                        i18n: 'menu.meals.meal.group',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                    {
                        key: 'menu.meals.meal.priceList',
                        path: '/webshop/codebooks/meals/price-list',
                        icon: <EuroOutlined />,
                        i18n: 'menu.meals.meal.priceList',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                    {
                        key: 'menu.meals.meal.menu',
                        path: '/webshop/codebooks/meals/meal-menus',
                        icon: <i className="las la-file-invoice" />,
                        i18n: 'menu.meals.meal.menu',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                    {
                        key: 'menu.meals.notWorkingDays',
                        path: '/webshop/codebooks/meals/not-working-days',
                        icon: <BulbOutlined />,
                        i18n: 'menu.meals.notWorkingDays',
                        role: MealsRights.isMealsAdminMenu(),
                    },
                ],
            },
        ],
    },
];

export default WebshopModuleMenu;
