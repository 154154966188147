import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Modal, Row, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const BookModal = ({ dataForm, title, icon, onConfirm }: any) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [depreciationStartDateChecked, setDepreciationStartDateChecked] = useState(false);

    useEffect(() => {
        fetchAppSettings();
    }, [dataForm]);

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'ASSET_DEPRECIATION' },
            { field: 'setting_key', condition: 'equals', value: 'ASSET_DEPRECIATION_STARTS_WITH_ACQUISITION' },
        ];
    };

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    let value =
                        result.data[0] && result.data[0].value
                            ? result.data[0].value
                            : result.data[0].defaultValue
                            ? result.data[0].defaultValue
                            : '0';
                    setDepreciationStartDateChecked(value == '1' ? true : false);
                }
            }
        });
    };

    const save = () => {
        form.validateFields().then((values: any) => {
            let instance: any = {};
            instance.acquisitionDate = values.acquisitionDate ? values.acquisitionDate : false;
            instance.acquisitionCompanyId = values.acquisitionCompanyId ? values.acquisitionCompanyId : false;
            instance.depreciationStartDate = values.depreciationStartDate ? values.depreciationStartDate : false;
            instance.assetDepreciationStatus = values.assetDepreciationStatus ? values.assetDepreciationStatus : false;
            instance.assetDepreciationGroup = values.assetDepreciationGroup ? values.assetDepreciationGroup : false;
            onConfirm(instance);
            closeModal();
        });
    };

    const closeModal = () => {
        setVisible(false);
        setDepreciationStartDateChecked(false);
        form.resetFields();
    };

    return (
        <>
            <Button style={{ width: '100%' }} icon={icon} onClick={() => setVisible(true)}>
                {title}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    maskClosable={false}
                    title={title}
                    onOk={save}
                    onCancel={(event: any) => {
                        closeModal();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <Form
                        form={form}
                        component={false}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                {title == geti18nText('asset.acquisition.button.booking') ? (
                                    <Alert
                                        message={geti18nText('asset.initialState.book.info')}
                                        type="info"
                                        showIcon
                                        style={{ margin: '5px' }}
                                    />
                                ) : (
                                    <Alert
                                        message={geti18nText('asset.initialState.debook.info')}
                                        type="info"
                                        showIcon
                                        style={{ margin: '5px' }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.acquisitionDate')}
                                    name={'acquisitionDate'}
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.company')}
                                    name={'acquisitionCompanyId'}
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.depreciationStartDate')}
                                    name={'depreciationStartDate'}
                                    valuePropName="checked"
                                    initialValue={depreciationStartDateChecked}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.assetDepreciationStatus')}
                                    name={'assetDepreciationStatus'}
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={geti18nText('asset.initialState.item.table.column.assetDepreciationGroup')}
                                    name={'assetDepreciationGroup'}
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default BookModal;
