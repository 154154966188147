import { EyeInvisibleOutlined, EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Input, Modal, Radio, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import AssetFlowSpecificationReportModal from '../../../../../components/asset-flow-specification-report-modal';
import NyNoteModal from '../../../../../components/note-modal';
import NyHistory from '../../../../../components/ny-history/NyHistory';
import useEnum from '../../../../../hooks/useEnum';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import {
    customAssetDepreciationGroupRenderOption,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import ItemIndex from '../../../../administration/views/item';
import RoomSearch from '../../../../human/views/room/search';
import AssetCategoryIndex from '../../../../webshop/views/webshop-asset/asset-category';
import AssetCategoryEdit from '../../../../webshop/views/webshop-asset/asset-category/edit';
import AssetModelIndex from '../../../../webshop/views/webshop-asset/asset-model';
import AssetModelEdit from '../../../../webshop/views/webshop-asset/asset-model/edit';
import AssetTypeIndex from '../../../../webshop/views/webshop-asset/asset-type';
import AssetTypeEdit from '../../../../webshop/views/webshop-asset/asset-type/edit';
import AccountingEntityAccountIndex from '../../accounting-entity-account';
import DepreciationGroupIndex from '../asset-depreciation-group';
import DepreciationGroupEdit from '../asset-depreciation-group/edit';

const { Panel } = Collapse;

const FixedAssetEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2', '3', '4', '5']);
    const [collapseAll, setCollapseAll] = useState<boolean>(false);
    const history = useHistory();
    const [note, setNote] = useState<any>(undefined);
    const [form] = Form.useForm();
    const [objectMetadataForm] = Form.useForm();
    const [metadata, setMetadata] = useState([]);
    const [assetType, setAssetType] = useState(undefined);
    const [refresh, setRefresh] = useState(0);
    const { id } = useParams<any>();
    const [depreciationGroup, setDepreciationGroup] = useState(null);
    const [groupIsSet, setGroupIsSet] = useState(false);
    const [disabledInventoryNumber, setDisabledInventoryNumber] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [historyType, setHistoryType] = useState<any>(4);
    const [editHeader, setEditHeader] = useState<any>(geti18nText('fixed.asset.edit.new'));
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const [employeeText, setEmployeeText] = useState<any>('');
    const [businessUnitText, setBusinessUnitText] = useState<any>('');
    const [companyText, setCompanyText] = useState<any>('');
    const [isFormChange, setIsFormChange] = useState(false);

    const canCreate = () => {
        return FixedAssetRights.canCreateFixedAssetCodebooks();
    };

    const isAdmin = () => {
        return AdministrationRights.isAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'ASSET_DEPRECIATION' },
            { field: 'setting_key', condition: 'equals', value: 'AUTO_ASSIGN_INVENTORY_NUMBER' },
        ];
    };

    useEffect(() => {
        fetchAppSettings();
    }, [dataForm]);

    useEffect(() => {
        if (metadata !== undefined && metadata.length > 0) {
            const metadataForm: any = {};

            if (form.getFieldValue('assetMetadata') !== undefined) {
                form.getFieldValue('assetMetadata').map((item: any) => {
                    if (item.asset_type_metadata !== undefined) {
                        const findItem: any = metadata.find((row: any) => row.id === item.asset_type_metadata.id);
                        if (findItem !== undefined && findItem.objectMetadata !== undefined) {
                            metadataForm[findItem.objectMetadata.name] = item.value;
                        }
                    }
                });
            }

            objectMetadataForm.setFieldsValue(metadataForm);
        }
    }, [metadata]);

    useEffect(() => {
        if (assetType !== undefined) {
            fetchMetadata(assetType);
        }
    }, [assetType]);

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    let value =
                        result.data[0] && result.data[0].value
                            ? result.data[0].value
                            : result.data[0].defaultValue
                            ? result.data[0].defaultValue
                            : '0';
                    setDisabledInventoryNumber(value == '1' ? true : false);
                }
            }
        });
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3', '4', '5']);
        }

        setCollapseAll(!collapseAll);
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.item) {
            dataForm.item = setSearchFormat(dataForm.item, 'name', 'name');
        }
        if (dataForm.employee) {
            setEmployeeText(
                <strong>
                    {`${dataForm?.employee?.person?.firstName} ${dataForm?.employee?.person?.lastName} (${dataForm?.employee?.employmentRecordId})`}
                    {' - '}
                    {`${dataForm?.employee?.room?.name}`}
                </strong>
            );
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        if (dataForm.room) {
            dataForm.room = setSearchFormat(dataForm.room, 'name', 'name');
        }
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };
            setBusinessUnitText(
                <strong>
                    {dataForm.businessUnit.code
                        ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                        : dataForm.businessUnit.name}
                </strong>
            );
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }
        if (dataForm.company) {
            setCompanyText(<strong>{dataForm?.company?.name ? dataForm.company.name : ''}</strong>);
            dataForm.company = setSearchFormat(dataForm.company, 'name', 'name');
        }
        if (dataForm.assetDepreciationGroup) {
            setDepreciationGroup(dataForm.assetDepreciationGroup.id);
            setGroupIsSet(true);
            dataForm.assetDepreciationGroup = setSearchFormat(dataForm.assetDepreciationGroup, 'name', 'name');
        } else {
            setGroupIsSet(true);
        }
        if (dataForm.aquisitionDate) {
            dataForm.aquisitionDate = setDateFormat(dataForm.aquisitionDate);
        }
        if (dataForm.releaseDate) {
            dataForm.releaseDate = setDateFormat(dataForm.releaseDate);
        }
        if (dataForm.returnDate) {
            dataForm.returnDate = setDateFormat(dataForm.returnDate);
        }
        if (dataForm.expendedDate) {
            dataForm.expendedDate = setDateFormat(dataForm.expendedDate);
        }
        if (dataForm.writeOfDate) {
            dataForm.writeOfDate = setDateFormat(dataForm.writeOfDate);
        }
        if (dataForm.depreciationStartDate) {
            dataForm.depreciationStartDate = setDateFormat(dataForm.depreciationStartDate);
        }
        if (dataForm.depreciationEndDate) {
            dataForm.depreciationEndDate = setDateFormat(dataForm.depreciationEndDate);
        }
        if (dataForm.status) {
            dataForm.status = setEnumFormat('ASSET_STATUS', dataForm.status);
        }
        if (dataForm.assetDepreciationStatus) {
            dataForm.assetDepreciationStatus = setEnumFormat(
                'ASSET_DEPRECIATION_STATUS',
                dataForm.assetDepreciationStatus
            );
        }
        if (dataForm.depreciationStartType) {
            dataForm.depreciationStartType = setEnumFormat('DEPRECIATION_START_TYPE', dataForm.depreciationStartType);
        }
        if (dataForm.assetType) {
            setAssetType(dataForm.assetType.id);
        }

        if (dataForm.id) {
            setEditHeader(geti18nText('fixed.asset.edit.title'));
        }
        setNote(dataForm.note ? dataForm.note : undefined);
        setDataForm(dataForm.id);
        setIsFormChange(false);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDepreciationGroup(null);
        setGroupIsSet(false);
        objectMetadataForm.resetFields();
        setAssetType(undefined);
        setEditHeader(geti18nText('fixed.asset.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ paddingRight: '15px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ paddingRight: '15px' }}
                        />
                    )}
                </Tooltip>
            </div>

            {dataForm && (
                <>
                    <div style={{ float: 'left', display: 'inline-flex' }}>
                        <AssetFlowSpecificationReportModal
                            assetId={dataForm}
                            reportType={'ASSET_FLOW_SPECIFICATION'}
                            reportTitle={'asset.report.assetFlowSpecification'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_FLOW_SPECIFICATION
                            )}
                            url={CONSTANTS_REQ.REPORT.ASSET_FLOW_SPECIFICATION}
                        />
                        <Button icon={<HistoryOutlined />} onClick={() => setHistoryModalVisible(true)}>
                            {geti18nText('app.default.change.history')}
                        </Button>
                    </div>
                </>
            )}
        </>
    );

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} onKeyDown={onKeyDownTextAreaCustom} />;
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                    />
                );
            case 'BOOL':
                return <Checkbox />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} />;
            default:
                return <Input />;
        }
    };

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'assetType.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_TYPE.METADATA, {
            max: 100,
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setMetadata(result.data.content);
                }
            }
        });
    };

    const onTypeChange = (value: any) => {
        if (value && value.id > 0) {
            fetchMetadata(value.id);
        }
    };

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
        form.resetFields();
    }, [id]);

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.ASSET.EDIT}
                editHeader={editHeader}
                addedData={{ module: 'fixed-asset' }}
                setValues={setValues}
                width={1200}
                form={form}
                fetchWhenChange={id || refresh}
                buttonsClassName="buttons-sticky"
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate()}
                customFooterContent={getCustomFooterContent}
                paramsId={id}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={true}
                disableSubmitButton={!isFormChange}
                setIsFormChange={setIsFormChange}
                goBack={() => {
                    setIsFormChange(false);
                    history.goBack();
                }}
                onSaveAndGetID={() => {
                    setIsFormChange(false);
                }}
                {...props}
                normalize={(values: any) => {
                    if (values.item) values.item = getSearchFormat(values.item);
                    if (values.employee) values.employee = getSearchFormat(values.employee);
                    if (values.room) values.room = getSearchFormat(values.room);
                    if (values.businessUnit) values.businessUnit = getSearchFormat(values.businessUnit);
                    if (values.company) values.company = getSearchFormat(values.company);
                    if (values.assetDepreciationGroup) {
                        values.assetDepreciationGroup = {
                            id: values.assetDepreciationGroup.id,
                            name:
                                typeof values.assetDepreciationGroup?.name === 'string'
                                    ? values.assetDepreciationGroup.name
                                    : values.assetDepreciationGroup?.name?.props?.children?.props?.children[2],
                        };
                    }

                    if (values.aquisitionDate) values.aquisitionDate = getDateFormat(values.aquisitionDate);
                    if (values.releaseDate) values.releaseDate = getDateFormat(values.releaseDate);
                    if (values.returnDate) values.returnDate = getDateFormat(values.returnDate);
                    if (values.expendedDate) values.expendedDate = getDateFormat(values.expendedDate);
                    if (values.writeOfDate) values.writeOfDate = getDateFormat(values.writeOfDate);
                    if (values.depreciationStartDate)
                        values.depreciationStartDate = getDateFormat(values.depreciationStartDate);
                    if (values.depreciationEndDate)
                        values.depreciationEndDate = getDateFormat(values.depreciationEndDate);

                    if (values.status) values.status = getEnumFormat(values.status);
                    if (values.assetDepreciationStatus)
                        values.assetDepreciationStatus = getEnumFormat(values.assetDepreciationStatus);

                    if (values.depreciationStartType)
                        values.depreciationStartType = getEnumFormat(values.depreciationStartType);

                    const metadataValues = objectMetadataForm.getFieldsValue();
                    let metadataReturn = [];

                    for (const [key, value] of Object.entries(metadataValues)) {
                        const metadataItem: any = metadata.find(
                            (item: any) => item.objectMetadata && item.objectMetadata.name === key
                        );
                        metadataReturn.push({ asset_type_metadata: { id: metadataItem.id }, value: value });
                    }

                    values.module = 'fixed-asset';

                    values.assetMetadata = metadataReturn;
                    setIsFormChange(false);
                    return values;
                }}
            >
                <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Collapse
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel
                                    header={geti18nText('fixed.asset.panel.generalData')}
                                    key="1"
                                    extra={
                                        <NyNoteModal
                                            note={note}
                                            modalTitle={
                                                geti18nText('asset.acquisition.edit.title') +
                                                ' - ' +
                                                geti18nText('app.default.note')
                                            }
                                        />
                                    }
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item label={geti18nText('fixed.asset.edit.id')} name="id">
                                                <Input disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.serialNumber')}
                                                name="serialNumber"
                                                rules={[
                                                    {
                                                        required: !disabledInventoryNumber,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: !disabledInventoryNumber,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled={disabledInventoryNumber && !dataForm}
                                                    ref={focusInput}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('asset.edit.assetType')}
                                                name="assetType"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                                    map={{ id: 'id', label: 'name' }}
                                                    searchBy="name"
                                                    itemName="name"
                                                    AddNewModalComponent={AssetTypeEdit}
                                                    SearchPopupComponent={<AssetTypeIndex />}
                                                    onChange={onTypeChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.name')}
                                                name="assetModel"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.ASSET_MODEL.SEARCH}
                                                    map={{ id: 'id', label: 'name' }}
                                                    searchBy="name"
                                                    itemName="name"
                                                    SearchPopupComponent={<AssetModelIndex />}
                                                    AddNewModalComponent={AssetModelEdit}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.assetCategory')}
                                                name="assetCategory"
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.ASSET_CATEGORY.SEARCH}
                                                    map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                                    searchBy="name || ' ' || code"
                                                    itemName={[['text'], ['code']]}
                                                    customItemNameLabel={'text (code)'}
                                                    renderOption={(option: any) =>
                                                        `${option.text}${option.code ? ' (' + option.code + ')' : ''}`
                                                    }
                                                    addNewItem={true}
                                                    AddNewModalComponent={AssetCategoryEdit}
                                                    SearchPopupComponent={<AssetCategoryIndex />}
                                                    setDefaultFilterValue={() => [
                                                        { field: 'active', condition: 'equals_bool', value: 1 },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.inventoryNumber')}
                                                name="serialNumber2"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item label={geti18nText('fixed.asset.edit.item')} name="item">
                                                <NySearchField
                                                    url={CONSTANTS_REQ.ITEM.SEARCH}
                                                    setDefaultFilterValue={setDefaultFilterValue}
                                                    map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                                    searchBy="name || ' ' || code"
                                                    itemName={[['text'], ['code']]}
                                                    customItemNameLabel={'(code) text'}
                                                    renderOption={(option: any) =>
                                                        `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                                                    }
                                                    SearchPopupComponent={<ItemIndex />}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <RoomSearch
                                                customItemNameLabel={'name'}
                                                itemName={[['id'], ['name']]}
                                                label={geti18nText('fixed.asset.edit.location')}
                                                name="room"
                                                setDefaultFilterValue={setDefaultFilterValue}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.depreciationRate')}
                                                name="assetDepreciationGroup"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH}
                                                    searchBy="name"
                                                    map={{
                                                        id: 'id',
                                                        label: 'name',
                                                        depreciationRate: 'depreciationRate',
                                                    }}
                                                    itemName="name"
                                                    renderOption={customAssetDepreciationGroupRenderOption}
                                                    setDefaultFilterValue={setDefaultFilterValue}
                                                    AddNewModalComponent={DepreciationGroupEdit}
                                                    SearchPopupComponent={<DepreciationGroupIndex />}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.depreciationStartType')}
                                                name="depreciationStartType"
                                            >
                                                <NySearchField
                                                    options={getEnumArrayStatus('DEPRECIATION_START_TYPE')}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    style={{ width: '100%' }}
                                                    className={''}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.assetDepreciationStatus')}
                                                name="assetDepreciationStatus"
                                            >
                                                <NySearchField
                                                    options={getEnumArrayStatus('ASSET_DEPRECIATION_STATUS')}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    style={{ width: '100%' }}
                                                    className={''}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.releaseDate')}
                                                name="releaseDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.returnDate')}
                                                name="returnDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.depreciationStartDate')}
                                                name="depreciationStartDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.depreciationEndDate')}
                                                name="depreciationEndDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.valueDepreciationDate')}
                                                name="expendedDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.writeOffDate')}
                                                name="writeOfDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Collapse
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('fixed.asset.panel.release.rent')} key="5">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.employee') + ':'}
                                                name="employee"
                                            >
                                                {employeeText}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.organisationUnit') + ':'}
                                                name="businessUnit"
                                            >
                                                {businessUnitText}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.company') + ':'}
                                                name={'company'}
                                            >
                                                {companyText}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('fixed.asset.panel.details')} key="3">
                                    <Form form={objectMetadataForm} component={false}>
                                        <Row gutter={24}>
                                            {metadata &&
                                                metadata.map((item: any) => {
                                                    return (
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label={item.objectMetadata.name}
                                                                name={item.objectMetadata.name}
                                                                rules={[
                                                                    {
                                                                        required: item.objectMetadata.mandatory,
                                                                        message: geti18nText('app.default.required'),
                                                                    },
                                                                ]}
                                                                valuePropName={
                                                                    item.objectMetadata.dataType === 3
                                                                        ? 'checked'
                                                                        : 'value'
                                                                }
                                                            >
                                                                {getDataTypeInput(item)}
                                                            </Form.Item>
                                                        </Col>
                                                    );
                                                })}
                                        </Row>
                                    </Form>
                                </Panel>
                            </Collapse>
                            <Collapse
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('fixed.asset.panel.extra')} key="2">
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.aquisitionCostValue')}
                                                name="aquisitionCostValue"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} isDecimal disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.acquisitionDate')}
                                                name="aquisitionDate"
                                            >
                                                <NyDatePicker style={{ width: '100%' }} disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.valueIncrease')}
                                                name="valueIncrease"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} isDecimal disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.valueDecrease')}
                                                name="valueDecrease"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} isDecimal disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.acumulatedDepreciation')}
                                                name="accumulatedDepreciation"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} isDecimal disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('fixed.asset.edit.netBookValue')}
                                                name="netBookValue"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} isDecimal disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('fixed.asset.panel.account')} key="4">
                                    {id != 'create' && groupIsSet ? (
                                        <AccountingEntityAccountIndex
                                            refId={id}
                                            refType={'ASSET'}
                                            extraRefId={depreciationGroup}
                                            extraRefType={'ASSET_DEPRECIATION_GROUP'}
                                            showRowSelection={true}
                                        ></AccountingEntityAccountIndex>
                                    ) : (
                                        <div></div>
                                    )}
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </div>
            </NyDataEdit>
            {historyModalVisible && (
                <Modal
                    title={geti18nText('asset.history.info')}
                    visible={historyModalVisible}
                    onCancel={() => {
                        setHistoryModalVisible(false);
                    }}
                    okButtonProps={{ hidden: true }}
                    maskClosable={false}
                    width={1200}
                >
                    <Radio.Group
                        value={historyType}
                        buttonStyle="solid"
                        optionType="button"
                        onChange={(e: any) => {
                            setHistoryType(e.target.value);
                        }}
                        style={{ marginBottom: '8px' }}
                    >
                        <Radio.Button value={4}>{geti18nText('asset.history.fixed.asset.changes.info')}</Radio.Button>
                        {isAdmin() && (
                            <Radio.Button value={3}>{geti18nText('asset.history.all.changes.info')}</Radio.Button>
                        )}
                    </Radio.Group>
                    <NyHistory id={dataForm} link={'fixed.asset'} type={historyType} />
                </Modal>
            )}
            <Prompt message={''} when={isFormChange} />
        </>
    );
};

export default FixedAssetEdit;
