import { geti18nText, NyInputNumber, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, formatJoppdCodeSearch, okNotification } from '../../../../utils/Utils';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const EmployeeLeaveSettingsIndex = ({ setDirty, dirty }: any) => {
    const [form] = Form.useForm();

    const [dataForm, setDataForm] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['1', '2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setRightCollapseActiveKeys([]);
        } else {
            setRightCollapseActiveKeys(['1', '2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['travelWarrantSettings'] = isChanged;
        if (setDirty) setDirty(tmpDirty);
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'in', value: ['PAYED_LEAVE', 'ATTENDANCE'].toString() }];
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    setDefaultData(result.data);
                }
            }
        });
    };

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setDataForm(dataForm);

        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 3) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            } else if (element.dataType === 8 && newDataForm[element.settingKey] != undefined) {
                newDataForm[element.settingKey] = JSON.parse(newDataForm[element.settingKey]);
            } else if (element.dataType === 9) {
                let defFilter = JSON.parse(element.defaultFilter);
                newDataForm[element.settingKey] = await formatJoppdCodeSearch(
                    newDataForm[element.settingKey],
                    defFilter.page,
                    defFilter.node
                );
            }
        }
        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        let val: any = value;
                        if (item.dataType === 3) {
                            val = val ? '1' : '0';
                        } else if (item.dataType === 9) {
                            val = val.code;
                        } else if (item.dataType === 8) {
                            val = JSON.stringify(val);
                        }
                        params.push({ id: item.id, value: val ? String(val) : '' });
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        if (setDirty) setDirty(false);
                        okNotification();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'username_exist':
                                        errorNotification(geti18nText('app.default.username_exist'));
                                        return;
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(geti18nText(result.data.error));
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.PAYED_LEAVE')} key="1">
                            <>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.PAYED_LEAVE.PAYED_LEAVE_CHILD_INCREMENT'
                                            )}
                                            name="PAYED_LEAVE_CHILD_INCREMENT"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.PAYED_LEAVE.PAYED_LEAVE_CHILD_INCREMENT_AGE'
                                            )}
                                            name="PAYED_LEAVE_CHILD_INCREMENT_AGE"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.PAYED_LEAVE.PAYED_LEAVE_SINGLE_PARENT_INCREMENT'
                                            )}
                                            name="PAYED_LEAVE_SINGLE_PARENT_INCREMENT"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.PAYED_LEAVE.PAYED_LEAVE_SINGLE_PARENT_INCREMENT_AGE'
                                            )}
                                            name="PAYED_LEAVE_SINGLE_PARENT_INCREMENT_AGE"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.PAYED_LEAVE.PAYED_LEAVE_DEVELOPMENTAL_DISABILITIES_INCREMENT'
                                            )}
                                            name="PAYED_LEAVE_DEVELOPMENTAL_DISABILITIES_INCREMENT"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.PAYED_LEAVE.PAYED_LEAVE_DEVELOPMENTAL_DISABILITIES_INCREMENT_AGE'
                                            )}
                                            name="PAYED_LEAVE_DEVELOPMENTAL_DISABILITIES_INCREMENT_AGE"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.ATTENDANCE')} key="2">
                            <>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'app.setting.name.NY_ERP_HUMAN.ATTENDANCE.ATTENDANCE_MINIMUM_LOGGED_MINUTES'
                                            )}
                                            name="ATTENDANCE_MINIMUM_LOGGED_MINUTES"
                                        >
                                            <NyInputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    {HumanResourcesRights.canCreateLeave() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default EmployeeLeaveSettingsIndex;
