import { geti18nText, NyInputNumber, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Modal, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customItemRenderOption, errorNotification } from '../../../../utils/Utils';
import { validateItemCode, validateNegativeNumber } from '../../../../utils/Validation';
import ItemSearch from '../../../administration/views/item/search';

const { Panel } = Collapse;

const WarehouseItemModal = ({
    itemTitle,
    setItemTitle,
    items,
    setItems,
    warehouseReceiptItem,
    setWarehouseReceiptItem,
    itemModalVisible,
    setItemModalVisible,
}: any) => {
    const [dataForm] = Form.useForm();
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>([]);

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseReceipt();
    };

    useEffect(() => {
        if (itemModalVisible) {
            dataForm.setFieldsValue(warehouseReceiptItem);
        }
    }, [itemModalVisible]);

    const onKeyDown = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (event.keyCode === 13 && event.target.nodeName === 'INPUT') {
            event.preventDefault();
            event.stopImmediatePropagation();
            var form = event.target.form;
            if (form !== null) {
                var index = Array.prototype.indexOf.call(form, event.target);

                do {
                    if (event.shiftKey) {
                        index--;
                        if (index < 0) {
                            index = 0;
                            break;
                        }
                    } else {
                        index++;
                        if (index >= form.elements.length) {
                            index = form.elements.length - 1;
                            break;
                        }
                    }
                } while (
                    form.elements[index].nodeName !== 'INPUT' ||
                    form.elements[index].id.includes('_id') ||
                    form.elements[index].id.toLowerCase().endsWith('id') ||
                    form.elements[index].disabled === true
                );

                form.elements[index].focus();
            }
        }
    };

    const onKeyUp = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    const changeAmount = () => {
        const quantity = dataForm.getFieldValue(['quantity']) ? dataForm.getFieldValue(['quantity']) : 0;
        const price = dataForm.getFieldValue(['invoicedPrice']) ? dataForm.getFieldValue(['invoicedPrice']) : 0;
        const discount = dataForm.getFieldValue(['discount']) ? dataForm.getFieldValue(['discount']) : 0;
        dataForm.setFieldsValue({ netAmount: quantity * price * ((100 - discount) / 100) });
    };

    const changePrice = () => {
        const quantity = dataForm.getFieldValue(['quantity']) ? dataForm.getFieldValue(['quantity']) : 0;
        const amount = dataForm.getFieldValue(['netAmount']) ? dataForm.getFieldValue(['netAmount']) : 0;
        const discount = dataForm.getFieldValue(['discount']) ? dataForm.getFieldValue(['discount']) : 0;
        if (quantity > 0) {
            dataForm.setFieldsValue({ invoicedPrice: (amount * ((100 + discount) / 100)) / quantity });
        }
    };

    const onWarehouseItemChange = (value: any) => {
        if (value && value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + value.id, undefined).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    let formItem: any = {};
                    formItem.item = {};
                    formItem.item = result.data;
                    dataForm.setFieldsValue(formItem);
                }
            });
        }
    };

    const closeModal = (newData: any) => {
        setItems(newData);
        setItemModalVisible(false);
        dataForm.resetFields();
    };

    const save = async () => {
        try {
            const row = dataForm.getFieldsValue();

            let newData = [...items];
            const index = newData.findIndex((object) => row.item.id == object.item.id);

            if (index > -1 && warehouseReceiptItem && warehouseReceiptItem.item.id == row.item.id) {
                const warehouseItem = newData[index];
                newData.splice(index, 1, { ...warehouseItem, ...row });
                closeModal(newData);
            } else if (index > -1 && warehouseReceiptItem && warehouseReceiptItem.item.id != row.item.id) {
                errorNotification(geti18nText('app.default.save.item.exists'));
            } else if (
                warehouseReceiptItem == undefined ||
                (index == -1 && warehouseReceiptItem.item.id != row.item.id)
            ) {
                newData.push(row);
                closeModal(newData);
            } else if (index == -1 && warehouseReceiptItem && warehouseReceiptItem.item.id == row.item.id) {
                const warehouseItem = newData[index];
                newData.splice(index, 1, { ...warehouseItem, ...row });
                closeModal(newData);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const getFooter = (
        <div
            className="ant-modal-footer"
            style={{
                position: 'absolute',
                left: '0px',
                width: '100%',
                marginTop: '16px',
                padding: '8px 16px',
                textAlign: 'right',
                borderRadius: '0 0 2px 2px',
            }}
        >
            <Tooltip placement="top" title={geti18nText('app.default.shortcuts.cancel')}>
                <Button
                    ny-test-id={'-cancel'}
                    style={{ marginRight: '1em' }}
                    key="back"
                    onClick={() => {
                        setItemTitle(undefined);
                        dataForm.resetFields();
                        setWarehouseReceiptItem(undefined);
                        setItemModalVisible(false);
                    }}
                >
                    {geti18nText('app.default.button.cancel')}
                </Button>
            </Tooltip>
            <Tooltip placement="top" title={geti18nText('app.default.shortcuts.save')}>
                <Button ny-test-id={'-save'} style={{ marginRight: '1em' }} key="submit" type="primary" onClick={save}>
                    {geti18nText('app.default.button.save')}
                </Button>
            </Tooltip>
        </div>
    );

    return (
        <>
            {itemModalVisible && (
                <Modal
                    title={itemTitle ? itemTitle : geti18nText('item.edit.new.item')}
                    visible={itemModalVisible}
                    maskClosable={false}
                    width={400}
                    onCancel={() => {
                        setItemTitle(undefined);
                        dataForm.resetFields();
                        setWarehouseReceiptItem(undefined);
                        setItemModalVisible(false);
                    }}
                    footer={null}
                    getContainer={false}
                    destroyOnClose={true}
                >
                    <Form layout="vertical" form={dataForm}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Collapse
                                    activeKey={leftCollapseActiveKeys}
                                    onChange={(key: any) => {
                                        setLeftCollapseActiveKeys(key);
                                    }}
                                >
                                    <Panel header={geti18nText('warehouseReceipt.item.tab.1')} key="1">
                                        <Col span={24} hidden>
                                            <Form.Item name="id" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={geti18nText('warehouseReceipt.edit.suplierItem')}
                                                name="supplierItemCode"
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={geti18nText('warehouseReceipt.edit.quantity.2')}
                                                name="secondQuantity"
                                            >
                                                <NyInputNumber disabled={!canCreate()} style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item
                                                label={geti18nText('warehouseReceipt.edit.priceInCurrency')}
                                                name="invoicedPriceInCurrency"
                                            >
                                                <NyInputNumber disabled={!canCreate()} style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item
                                                label={geti18nText('warehouseReceipt.edit.amountInCurrency')}
                                                name="netAmountInCurrency"
                                            >
                                                <NyInputNumber disabled={!canCreate()} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <ItemSearch
                                                    label={geti18nText('warehouseReceipt.edit.item.object')}
                                                    name={['item']}
                                                    required={true}
                                                    validator={validateItemCode}
                                                    map={{
                                                        id: 'id',
                                                        label: 'name',
                                                        code: 'code',
                                                        text: 'name',
                                                        measureUnit: 'measureUnit',
                                                    }}
                                                    itemName={[['text'], ['code'], ['measureUnit']]}
                                                    customItemNameLabel={'(code) text measureUnit'}
                                                    renderOption={customItemRenderOption}
                                                    onChange={onWarehouseItemChange}
                                                    autoFocus={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.quantity')}
                                                    name="quantity"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyInputNumber
                                                        isDecimal={true}
                                                        decimalPlaces={0}
                                                        maxDecimalPlaces={12}
                                                        disabled={!canCreate()}
                                                        onChange={changeAmount}
                                                        style={{ width: '100%' }}
                                                        min={1}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.priceNumeric')}
                                                    name="invoicedPrice"
                                                >
                                                    <NyInputNumber
                                                        isDecimal={true}
                                                        decimalPlaces={2}
                                                        maxDecimalPlaces={4}
                                                        disabled={!canCreate()}
                                                        onChange={changeAmount}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.discount')}
                                                    name="discount"
                                                >
                                                    <NyInputNumber
                                                        isDecimal={true}
                                                        decimalPlaces={2}
                                                        disabled={!canCreate()}
                                                        onChange={changeAmount}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.amountNumeric')}
                                                    name="netAmount"
                                                    rules={[
                                                        {
                                                            validator: validateNegativeNumber,
                                                        },
                                                    ]}
                                                >
                                                    <NyInputNumber
                                                        isDecimal={true}
                                                        decimalPlaces={2}
                                                        disabled={!canCreate()}
                                                        // onChange={changePrice}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Collapse
                                    style={{ marginTop: '1rem' }}
                                    activeKey={rightCollapseActiveKeys}
                                    onChange={(key: any) => {
                                        setRightCollapseActiveKeys(key);
                                    }}
                                >
                                    <Panel header={geti18nText('warehouseReceipt.item.tab.2')} key="2">
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.netWeight')}
                                                    name="netWeight"
                                                >
                                                    <NyInputNumber
                                                        disabled={!canCreate()}
                                                        style={{ width: '100%' }}
                                                        isDecimal={true}
                                                        decimalPlaces={4}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.grossWeight')}
                                                    name="grossWeight"
                                                >
                                                    <NyInputNumber
                                                        disabled={!canCreate()}
                                                        style={{ width: '100%' }}
                                                        isDecimal={true}
                                                        decimalPlaces={4}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        {getFooter}
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default WarehouseItemModal;
