import { SearchOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const GroupEdit = (props: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const [editHeader, setEditHeader] = useState<string>(geti18nText('user.auth.group.edit.header'));
    const [users, setUsers] = useState<any>([]);
    const [search, setSearch] = useState<string>('');

    function setValues(dataForm: any) {
        setEditHeader(dataForm.groups?.name ? dataForm.groups.name : geti18nText('user.auth.group.edit.header'));
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.USER_AUTH.GET_USERS_BY_GROUP + '/' + props?.value?.id,
            undefined,
            true
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    let sorted = result.data
                        .sort((a: any, b: any) => {
                            let sortByHasGroup = (b.groupId ?? 0) - (a.groupId ?? 0);
                            if (sortByHasGroup === 0) {
                                return a.username.localeCompare(b.username);
                            }
                            return sortByHasGroup;
                        })
                        .map((value: any) => {
                            value.searchString = value.username + ' ' + value.firstName + ' ' + value.lastName;
                            return value;
                        });
                    setUsers(sorted);
                }
            }
            setLoading(false);
        });
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            url={CONSTANTS_REQ.USER_AUTH.ADD_USERS_TO_GROUP}
            fetchWhenChange={'create'}
            setValues={setValues}
            width={900}
            form={form}
            shortcuts={true}
            hideActivationButtons={true}
            normalize={(values: any) => {
                const users = Object.entries(values)
                    .filter((value) => {
                        return value[1];
                    })
                    .map((value) => {
                        return Number(value[0]);
                    });
                values = { groupId: props?.value?.id, users: users };
                return values;
            }}
            {...props}
            setData={props?.value}
            value={undefined}
            onSaveAndGetData={() => {
                props?.editProps?.setFetchUsersGroupsWhenChange((prev: number) => prev + 1);
                return props?.onSaveAndGetData();
            }}
        >
            <Form.Item name={'search'}>
                <Input
                    suffix={<SearchOutlined />}
                    onChange={(event: any) => {
                        setSearch(event.target.value);
                    }}
                    placeholder={geti18nText('app.default.button.search')}
                />
            </Form.Item>
            {loading ? (
                <NySpinner />
            ) : (
                <div style={{ maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                    {users.map((user: any) => {
                        return (
                            <Row
                                gutter={24}
                                style={{
                                    display: user.searchString.toLowerCase().includes(search.toLowerCase())
                                        ? ''
                                        : 'none',
                                }}
                            >
                                <Col span={24}>
                                    <Form.Item
                                        name={user.id}
                                        valuePropName={'checked'}
                                        initialValue={user.groupId !== undefined}
                                    >
                                        <Checkbox defaultChecked={user.groupId !== undefined}>
                                            {user.username} &nbsp; (<b>{user.firstName + ' ' + user.lastName}</b>)
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            )}
        </NyDataEdit>
    );
};
export default GroupEdit;
