import { NyDataTable, NySession, getColumnDateOption, geti18nText, getColumnSearchOption } from '@nybble/nyreact';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useEnum from '../../../../../../hooks/useEnum';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { getColumnSearchByHumanCodebookSimple } from '../../../../../../utils/Filters';
import { useEffect, useState } from 'react';
import EmployeeHealthExaminationEdit from './edit';
import { getEnumArray } from '../../../../../../utils/Utils';
import RowAction from '../../../../../../components/row-action';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeHealthExaminationIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    employeeId,
    showHeader = true,
    scroll = { y: 580, x: 800 },
    sortOrder,
    readonly = false,
}: any) => {
    const table = useTableSettings();
    const { id } = useParams<any>();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [refresh, setRefresh] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id ? id : employeeId },
        ];
    };

    const columns: any = [
        {
            title: geti18nText('employee.healthExamination.table.column.category'),
            dataIndex: 'healthExaminationCategory',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('HEALTH_EXAMINATION_CATEGORY')),
            render: (text: any, record: any) => {
                return geti18nText('app.enum.HEALTH_EXAMINATION_CATEGORY.' + text);
            },
        },
        {
            title: geti18nText('employee.healthExamination.table.column.type'),
            dataIndex: ['healthExaminationType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.HEALTH_EXAMINATION_TYPE, 'healthExaminationType.id'),
            render: (text: any, record: any) => {
                return record?.healthExaminationType?.name;
            },
        },
        {
            title: geti18nText('employee.healthExamination.table.column.inspectionDate'),
            dataIndex: 'inspectionDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (text: any, record: any) => {
                if (record.inspectionDate) {
                    return moment(record.inspectionDate).format('DD.MM.YYYY');
                }
            },
        },
        // {
        //     title: geti18nText('employee.healthExamination.table.column.created'),
        //     dataIndex: 'created',
        //     sorter: (a: any, b: any) => {},
        //     ...getColumnDateOption(true),
        //     render: (text: any, record: any) => {
        //         if (record.created) {
        //             return moment(record.created).format('DD.MM.YYYY');
        //         }
        //     },
        // },
        {
            title: geti18nText('employee.healthExamination.table.column.nextControlExaminationDate'),
            dataIndex: 'nextControlExaminationDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (text: any, record: any) => {
                if (record.nextControlExaminationDate) {
                    return moment(record.nextControlExaminationDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.healthExamination.table.column.evaluationMedicalCapacity'),
            dataIndex: ['evaluationMedicalCapacity', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(
                enCodebookType.EVALUATION_MEDICAL_CAPACITY,
                'evaluationMedicalCapacity.id'
            ),
            render: (text: any, record: any) => {
                return record?.evaluationMedicalCapacity?.name;
            },
        },
        // {
        //     title: geti18nText('employee.healthExamination.table.column.remark'),
        //     dataIndex: 'remark',
        //     sorter: (a: any, b: any) => {},
        //     ...getColumnSearchByHumanCodebookSimple(
        //         enCodebookType.EVALUATION_MEDICAL_CAPACITY,
        //         'evaluationMedicalCapacity.id'
        //     ),
        //     render: (text: any, record: any) => {
        //         return text?.name;
        //     },
        // },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <RowAction
                            component={'EmployeeHealthExamination'}
                            record={record}
                            printTravelWarrant={true}
                        ></RowAction>
                    );
                }
            },
        },
    ];

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id, employeeId]);

    return (
        <NyDataTable
            nyId="human-employee-health-examination-table"
            headerTitle={showHeader ? geti18nText('employee.healthExamination.table.header') : null}
            url={CONSTANTS_REQ.EMPLOYEE_HEALTH_EXAMINATION.LIST}
            addNewButtonText={geti18nText('employee.healthExamination.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            fetchWhenChange={refresh}
            readonly={readonly}
            hideNewButton={!canCreate()}
            setDefaultFilterValue={setDefaultFilterValue}
            modalComponent={EmployeeHealthExaminationEdit}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            shortcuts={true}
            CSVFileName={geti18nText('employee.healthExamination.table.header')}
            editProps={{ employeeId: id, setRefreshTable: setRefresh, refreshTable: refresh }}
        />
    );
};

export default EmployeeHealthExaminationIndex;
