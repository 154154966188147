import { CaretRightOutlined, DeleteTwoTone, MenuOutlined, WarningTwoTone } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Modal, Tooltip } from 'antd';
import { forwardRef, useState } from 'react';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import ShiftSearch from '../shift/search';

export const Item = forwardRef((props: any, ref) => {
    const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);
    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    return (
        <>
            <div className="sortable-item" style={{ cursor: 'grab', ...props.style }} {...props} ref={ref}>
                {props.name ? (
                    <>
                        <CaretRightOutlined
                            style={{ paddingTop: '2px', color: props.color ? props.color : '#d9d9d9' }}
                        />
                        <span>{props.name}</span>
                    </>
                ) : (
                    <ShiftSearch
                        isForm={false}
                        onChange={(value: any) => props.handleChangeShift(value, props.id)}
                        style={{ width: '20vw' }}
                        mustGetPopupContainer={false}
                    />
                )}
                {props.overlaps ? (
                    <Tooltip placement="top" title={geti18nText('app.default.overlap.warning')}>
                        <WarningTwoTone twoToneColor="#fa1414" style={{ paddingLeft: '5px', marginRight: '0px' }} />
                    </Tooltip>
                ) : (
                    ''
                )}
                {canCreate() && (
                    <span className="sorted-item-span">
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <DeleteTwoTone
                                className="ny-delete-icon"
                                twoToneColor="#ff7875"
                                onClick={() => setIsPopconfirmOpen(true)}
                            />
                        </Tooltip>
                        <MenuOutlined className="ny-sort-icon" {...props.listeners} />
                    </span>
                )}
            </div>
            {isPopconfirmOpen && (
                <Modal
                    visible={isPopconfirmOpen}
                    title={geti18nText('app.default.delete.confirm')}
                    okText={geti18nText('app.default.button.yes')}
                    cancelText={geti18nText('app.default.button.no')}
                    onOk={() => {
                        props.handleDeleteShift(props.id);
                        setIsPopconfirmOpen(false);
                    }}
                    onCancel={() => {
                        setIsPopconfirmOpen(false);
                    }}
                />
            )}
        </>
    );
});
