import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import LocationSearch from '../location/search';

const RoomEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('room.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('room.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('room.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ROOM.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.businessUnit) {
                    if (values.businessUnit.id < 1) {
                        delete values.businessUnit;
                    } else {
                        values.businessUnit = getSearchFormat(values.businessUnit);
                    }
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('room.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <LocationSearch required={true} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('room.edit.description')} name="description">
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default RoomEdit;
