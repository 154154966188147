import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import EducationalInstitutionEdit from './edit';

const EducationalInstitutionIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    filteredForTravel = false,
    filteredForEmployeeLeave = false,
    readonly = false,
    educationLevelId,
    addedData,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    const setEducationalInstitutionFilterValue = () => {
        if (educationLevelId != undefined && educationLevelId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'educationLevelId', condition: 'equals', value: educationLevelId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    useHelper('human/employee');

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const columns = [
        {
            title: geti18nText('educational.institution.table.column.id'),
            dataIndex: 'id',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('educational.institution.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educational.institution.table.column.educationLevel'),
            dataIndex: ['educationLevel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educational.institution.table.column.settlement'),
            dataIndex: ['settlement', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educational.institution.table.column.university'),
            dataIndex: ['university', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educational.institution.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-educational-institution-table"
            url={CONSTANTS_REQ.EDUCATIONAL_INSTITUTION.LIST}
            addNewButtonText={geti18nText('educational.institution.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={EducationalInstitutionEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            hideNewButton={!canCreate()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setEducationalInstitutionFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            CSVFileName={geti18nText('menu.educational.institution')}
            readonly={readonly}
            hideButtons={readonly}
            editProps={addedData}
            headerTitle={geti18nText('menu.educational.institution')}
        />
    );
};

export default EducationalInstitutionIndex;
