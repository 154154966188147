import { CheckOutlined, CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyDataEdit,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    getColumnSearchCheckbox,
    NySearchField,
} from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import EmployeeFamilyMemberEdit from './EmployeeFamilyMemberEdit';
import useEnum from '../../../../hooks/useEnum';
import { getEmployeeFamilyMemberTypeIcon } from '../../../../utils/Utils';
import { getEnumArrayStatus, getEnumBooleanArray } from '../../../../utils/Utils';
import { NyRequestResolver, RESPONSE, NySpinner } from '@nybble/nyreact';
import { Form, Row, Col, Checkbox, Input, Button } from 'antd';
import HumanCodebooksSearch from '../../../../components/human-codebooks/search';
import { getEmployeeSelectOption } from '../../../../utils/Utils';
import { customEmployeeRenderOption, getSearchFormat } from '../../../../utils/Utils';

import EmployeeIndex from '.';
import { iResponse } from '@nybble/nyreact/build/utils/Types';
import { GetEnum } from '../../../../utils/Enums';
type useParamsType = {
    id: string;
};
const EmployeeFamilyMemberIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly,
    propsId,
    idProps,
    scroll = { y: 180, x: 100 },
    sortOrder,
}: any) => {
    const { id } = useParams<useParamsType>();
    const { visible } = useSelector((state: RootState) => state.modalOpen);
    const table = useTableSettings();
    const [resultData, setResultData] = useState<any>();
    const [form] = Form.useForm();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchDataTrigger, setFetchDataTrigger] = useState<number>(0);
    const [triggerSave, setTrigerSave] = useState<number>(0);
    const [isFormChange, setIsFormChange] = useState<boolean>(false);
    const enSexType: any = GetEnum({ enumName: 'SEX_TYPE' });

    useEffect(() => {
        if (idProps) {
            fetchEmployeeData();
        }
    }, [idProps]);

    const fetchEmployeeData = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + idProps).then((result: iResponse) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setResultData(result.data);
                }
            }
        });
    };

    const getCustomFooterContent = (
        <>
            {
                <>
                    <Button
                        key="submit"
                        loading={loading}
                        style={{ float: 'left', marginRight: '8px', visibility: isFormChange ? 'visible' : 'hidden' }}
                        onClick={() => {
                            form.resetFields();
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(false);
                            }, 300);

                            setFetchDataTrigger((val) => val + 1);
                        }}
                    >
                        {geti18nText('app.default.button.cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        style={{ float: 'left', visibility: isFormChange ? 'visible' : 'hidden' }}
                        onClick={() => {
                            setTrigerSave((val) => val + 1);
                        }}
                    >
                        {geti18nText('app.default.button.save')}
                    </Button>
                </>
            }
        </>
    );

    const normalize = (data: any) => {
        if (data.partnerInOrganization && data.partnerInOrganization?.id != -1) {
            data.partnerInOrganization = getSearchFormat(data.partnerInOrganization);
        }

        for (const key in data) {
            if (data[key] !== undefined && data[key] !== '') {
                if (resultData[key] && typeof data[key] === 'object' && typeof resultData[key] === 'object') {
                    resultData[key] = mergeObjects(resultData[key], data[key]);
                } else {
                    resultData[key] = data[key];
                }
            }
        }

        return resultData;
    };

    function mergeObjects(obj1: any, obj2: any): any {
        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (obj1.hasOwnProperty(key) && typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                    obj1[key] = mergeObjects(obj1[key], obj2[key]);
                } else {
                    obj1[key] = obj2[key];
                }
            }
        }
        return obj1;
    }

    function setValues(dataForm: any) {
        if (dataForm) {
            if (dataForm.partnerInOrganization) {
                dataForm.partnerInOrganization = getEmployeeSelectOption(dataForm.partnerInOrganization);
            }

            form.setFieldsValue(dataForm);
        }
    }

    const employeeFamilyMemberTypeEnum = useEnum('EMPLOYEE_FAMILY_MEMBER_TYPE');

    const [refresh, setRefresh] = useState<number>(1);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id ? id : idProps },
        ];
    };

    useEffect(() => {
        if (visible || id != undefined) {
            setRefresh((prevValue: any) => prevValue + 1);
        }
    }, [id, visible]);

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    return (
        <>
            <div style={{ marginBottom: '-24px' }}>
                <NyDataEdit
                    layout="vertical"
                    modalStyle={{ marginBottom: '12px' }}
                    loading={loading}
                    setLoading={setLoading}
                    url={CONSTANTS_REQ.EMPLOYEE.EDIT}
                    setValues={setValues}
                    width={820}
                    form={form}
                    setData={() => {}}
                    hideSubmitButton={true}
                    hideActivationButtons={true}
                    hideCancelButton={true}
                    paramsId={idProps}
                    shortcuts={true}
                    checkIsFormChanged={true}
                    setIsFormChange={setIsFormChange}
                    fetchWhenChange={fetchDataTrigger}
                    normalize={normalize}
                    triggerSave={triggerSave}
                    customFooterContent={getCustomFooterContent}
                >
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        {!readonly && (
                            <Col span={10}>
                                {loading ? (
                                    <div style={{ height: '162px', alignContent: 'center' }}>
                                        <NySpinner />
                                    </div>
                                ) : (
                                    <>
                                        <Row gutter={24}>
                                            <Col span={10}>
                                                <HumanCodebooksSearch
                                                    required={false}
                                                    label={geti18nText('employeeFamilyMember.edit.marriageStatus')}
                                                    renderOption={(option: any) => `${option.text ?? option.name}`}
                                                    customItemNameLabel={'name'}
                                                    codebooksType={enCodebookType.PERSON_MARRIAGE_STATUS}
                                                    formItemName={['person', 'marriageStatus']}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item
                                                    label={geti18nText('employeeFamilyMember.edit.adoptiveParent')}
                                                    name={['person', 'adoptiveParent']}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox></Checkbox>
                                                </Form.Item>
                                            </Col>
                                            {resultData?.person?.sex == enSexType.FEMALE && (
                                                <Col span={4}>
                                                    <Form.Item
                                                        label={geti18nText('employeeFamilyMember.edit.pregnant')}
                                                        name={['person', 'pregnant']}
                                                        valuePropName="checked"
                                                    >
                                                        <Checkbox></Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={10}>
                                                <Form.Item
                                                    label={geti18nText('employeeFamilyMember.edit.partner')}
                                                    name="partnerInOrganization"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NySearchField
                                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                        map={{
                                                            id: 'id',
                                                            label: 'text',
                                                            employmentRecordId: 'employmentRecordId',
                                                        }}
                                                        searchBy="person.first_name || ' ' || person.last_name"
                                                        itemName={[
                                                            ['person', 'firstName'],
                                                            ['person', 'lastName'],
                                                        ]}
                                                        renderOption={customEmployeeRenderOption}
                                                        customItemNameLabel={'firstName lastName'}
                                                        SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                                        nyTestId="employee"
                                                        autoFocus={true}
                                                        order="person.last_name, person.first_name"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item
                                                    label={geti18nText('employeeFamilyMember.edit.singleParent')}
                                                    name={'singleParent'}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox></Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        )}
                    </Row>
                </NyDataEdit>
            </div>

            <NyDataTable
                nyId="human-emplyee-family-member-table"
                url={CONSTANTS_REQ.EMPLOYEE_FAMILY_MEMBER.LIST}
                addNewButtonText={geti18nText('employeeFamilyMember.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                fetchWhenChange={refresh}
                readonly={readonly}
                hideButtons={readonly}
                hideNewButton={!canCreate()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                setDefaultPageSize={table.setDefaultPageSize()}
                modalComponent={EmployeeFamilyMemberEdit}
                editProps={{ employeeId: id ? id : idProps, setRefresh: setRefresh }}
                setDefaultFilterValue={setDefaultFilterValue}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={[
                    {
                        title: geti18nText('employeeFamilyMember.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.firstName'),
                        dataIndex: ['person', 'firstName'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.lastName'),
                        dataIndex: ['person', 'lastName'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.oib'),
                        dataIndex: ['person', 'oib'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.type'),
                        dataIndex: 'type',
                        width: '10%',
                        render: (text: any, record: { type: number }) => {
                            return getEmployeeFamilyMemberTypeIcon(record.type);
                        },
                        ...getColumnSearchCheckbox(getEnumArrayStatus('EMPLOYEE_FAMILY_MEMBER_TYPE')),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.adopted'),
                        dataIndex: 'adopted',
                        width: '10%',
                        render: (text: any, record: { adopted: any; type: number }) => {
                            if (record.type == employeeFamilyMemberTypeEnum.CHILD) {
                                if (record.adopted) {
                                    return <CheckOutlined style={{ color: 'green' }} />;
                                } else {
                                    return <CloseOutlined style={{ color: 'red' }} />;
                                }
                            } else {
                                return <MinusCircleOutlined style={{ color: 'orange' }} />;
                            }
                        },
                        ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.developmentalDisabilities'),
                        dataIndex: 'developmentalDisabilities',
                        width: '10%',
                        render: (text: any, record: { developmentalDisabilities: any; type: number }) => {
                            if (record.type == employeeFamilyMemberTypeEnum.CHILD) {
                                if (record.developmentalDisabilities) {
                                    return <CheckOutlined style={{ color: 'green' }} />;
                                } else {
                                    return <CloseOutlined style={{ color: 'red' }} />;
                                }
                            } else {
                                return <MinusCircleOutlined style={{ color: 'orange' }} />;
                            }
                        },
                        ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.singleParent'),
                        dataIndex: 'singleParent',
                        width: '10%',
                        render: (text: any, record: { singleParent: any; type: number }) => {
                            if (record.singleParent) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                    },
                    {
                        title: geti18nText('employeeFamilyMember.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                    },
                ]}
                shortcuts={true}
            />
        </>
    );
};

export default EmployeeFamilyMemberIndex;
