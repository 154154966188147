import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import MobilizationDocuments from '../../../../components/mobilization-documents/MobilizationDocuments';

const WorkObligationDocumentsIndex = () => {
    useHelper('workObligation/codebooks/mobilizationDocuments');

    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    return <MobilizationDocuments documentType={documentTypeEnum.MOBILIZATION_PLAN} scroll={{ y: 620, x: 800 }} />;
};

export default WorkObligationDocumentsIndex;
