import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../../hooks/useEnum';
import { MobilizationRights } from '../../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import DepartmentSearch from '../department/search';

const SectionEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('defense.department.section.header'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);

    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const history = useHistory();
    const focusInput = useRef<any>(null);

    const EnDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');

    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('defense.department.section.header') + ' - ' + dataForm.name);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('defense.department.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.DEFENSE_DEPARTMENT.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            normalize={(values: any) => {
                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }
                values.type = EnDepartmentType.SECTION;
                return values;
            }}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('defense.department.edit.section.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <DepartmentSearch label={geti18nText('defense.department.edit.section.section')} required={true} />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default SectionEdit;
