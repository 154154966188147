import { UserOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Card, List } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { setModalOpen } from '../../slices/modalOpenSlice';

const ActionsWidget = ({
    actions,
    maxHeight = undefined,
}: {
    actions: { label: string; path?: string; modalName?: string; icon?: any; state?: any }[];
    maxHeight?: any;
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const linkGridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '10px',
    };

    return (
        <>
            <Card
                title={geti18nText('app.default.actions')}
                className="ny-widget-card"
                bodyStyle={{ padding: '0px' }}
                type="inner"
            >
                <List
                    style={{
                        maxHeight: maxHeight ? maxHeight : '325px',
                        padding: '4px 5px 0px 5px',
                        marginTop: '1px',
                    }}
                    grid={{
                        gutter: 12,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={actions}
                    renderItem={(action: any) => {
                        return (
                            <List.Item className="ny-notification-widget-list-item">
                                <div
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        action.path && history.push({ pathname: action.path, state: action.state });
                                        action.modalName &&
                                            dispatch(
                                                setModalOpen({
                                                    modal: action.modalName,
                                                    visible: true,
                                                    item: action.item ?? null,
                                                })
                                            );
                                    }}
                                >
                                    <Card.Grid className="ny-card-link" style={linkGridStyle}>
                                        {action.icon}
                                        {action.icon && <span style={{ marginRight: '15px' }} />}
                                        {action.label}
                                    </Card.Grid>
                                </div>
                            </List.Item>
                        );
                    }}
                    className={`ny-widget-list scrollbar-on-hover`}
                />
            </Card>
        </>
    );
};

export default ActionsWidget;
