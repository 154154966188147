import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, MergeCellsOutlined, SaveTwoTone } from '@ant-design/icons';
import { NyDatePicker, NyInputNumber, NySearchField, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Input, Modal, Row, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AssetSimpleIndex from '../../../../../components/asset-simple/assetSimple';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customAssetRenderOption, errorNotification, getDateFormat } from '../../../../../utils/Utils';
import EditableCellAssetSelect from './EditableCellAssetSelect';
import ItemIndex from '../../../../administration/views/item';
import ItemSearch from '../../../../administration/views/item/search';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxQuantity = undefined,
    form,
    mandatoryReceipt = false,
    dateTo,
    warehouse = null,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [mergeAssetModalVisible, setMergeAssetModalVisible] = useState<any>(false);
    const [releaseQuantityValue, setReleaseQuantityValue] = useState<any>(null);
    const [itemsAsset, setItemsAsset] = useState<any>(null);
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;
    const [assetListForm] = Form.useForm();

    useEffect(() => {
        initItems(record, releaseQuantityValue);
    }, [releaseQuantityValue]);

    const mergeAsset = () => {
        const quantity = form.getFieldValue('releaseQuantity');
        setReleaseQuantityValue(quantity);
        setMergeAssetModalVisible(true);
    };

    const closeAssetModal = () => {
        setMergeAssetModalVisible(false);
        setEditingKey('');
    };

    const initItems = (record: any, newQuantity: any) => {
        let newData: any = [];
        for (let key: any = 1; key <= releaseQuantityValue; key++) {
            newData.push({ id: key, asset: null, dateTo: dateTo });
        }
        setItemsAsset(newData);
    };

    const saveAssetList = () => {
        if (itemsAsset?.length > 0 && itemsAsset?.length <= maxQuantity && itemsAsset?.length == releaseQuantityValue) {
            record.assetList = [...itemsAsset];
            closeAssetModal();
        } else {
            errorNotification(geti18nText('assetRelease.save.noAsset'));
        }
    };

    const cancel = (record: any) => {
        setEditingKey('');
        assetListForm.resetFields();
    };

    const deleteRow = (id: any) => {
        if (id) {
            setItemsAsset(itemsAsset.filter((row: any) => row.id !== id));
            const tempQuant = releaseQuantityValue - 1;
            form.setFieldsValue({ releaseQuantity: tempQuant });
            setReleaseQuantityValue(tempQuant);
            if (tempQuant < 1) {
                setMergeAssetModalVisible(false);
            }
        }
    };

    const save = async (id: any) => {
        try {
            const row = await assetListForm.validateFields();
            const newData = [...itemsAsset];
            const index = newData.findIndex((item) => id === item?.id);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItemsAsset(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItemsAsset(newData);
                setEditingKey('');
            }
            assetListForm.resetFields();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };
        assetListForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const columnsAsset: any = [
        {
            title: geti18nText('assetRelease.table.column.asset'),
            dataIndex: 'asset',
            editable: true,
            inputType: 'asset',
            render: (text: any, record: any) => {
                if (record?.asset?.name?.name?.props?.children) {
                    return (
                        (record.asset.name.name.props.children[0] &&
                            record.asset.name.name.props.children[0]?.props?.children) ??
                        ''
                    );
                } else if (record?.asset?.label) {
                    return record.asset.label;
                } else if (record?.asset?.name) {
                    return record.asset.name;
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.dateTo'),
            dataIndex: 'dateTo',
            editable: true,
            inputType: 'dateTo',
            width: '200px',
            render: (text: any, record: any) => {
                if (text) {
                    return getDateFormat(text, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            width: '100px',
            render: (text: any, record: any) => {
                if (itemsAsset.length >= 1) {
                    const editable = isEditing(record);
                    return editable ? (
                        <ActionEditable text={text} record={record} />
                    ) : (
                        <ActionNotEditable text={text} record={record} />
                    );
                }
            },
        },
    ];

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Button type="link" onClick={() => save(record.id)}>
                        <SaveTwoTone
                            style={{
                                fontSize: '20px',
                            }}
                            twoToneColor="#52c41a"
                        />
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            cancel(record);
                        }}
                    >
                        <CloseCircleTwoTone
                            style={{
                                fontSize: '20px',
                            }}
                            twoToneColor="#faad14"
                        />
                    </Button>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                    <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                        <EditTwoTone
                            style={{
                                fontSize: '20px',
                            }}
                        />
                    </Button>
                    <Button
                        type="link"
                        disabled={editingKey !== ''}
                        onClick={() => {
                            deleteRow(record.id);
                        }}
                    >
                        <DeleteTwoTone
                            style={{
                                fontSize: '20px',
                            }}
                            twoToneColor="#ff7875"
                        />
                    </Button>
                </Space>
            </React.Fragment>
        );
    };

    const getAssetIds = () => {
        let assetIds: any = [];
        itemsAsset?.length > 0 &&
            itemsAsset.map((item: any) => {
                if (item?.asset?.id) {
                    assetIds.push(item.asset.id);
                }
            });
        return assetIds;
    };
    const mergedColumnsAsset = columnsAsset.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (recordItem: any) => {
                return {
                    recordItem,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(recordItem),
                    maxQuantity: maxQuantity,
                    form: assetListForm,
                    mandatoryReceipt: mandatoryReceipt,
                    item: record?.item,
                    assetIds: getAssetIds(),
                };
            },
        };
    });

    const releaseQuantityInputNode = (
        <NyInputNumber
            leftToRight={numbersWriting}
            style={{ width: '100%' }}
            min={1}
            max={maxQuantity}
            onChange={(val: any) => setReleaseQuantityValue(val)}
        />
    );

    const assetFilter = () => {
        if (warehouse) {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'item.id', condition: 'equals', value: record?.item?.id },
                { field: 'warehouse.id', condition: 'equals', value: warehouse?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'item.id', condition: 'equals', value: record?.item?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
    };

    const itemFilter = () => {
        if (warehouse) {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'type', condition: 'equals', value: 4 },
                { field: 'warehouse.id', condition: 'equals', value: warehouse?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'type', condition: 'equals', value: 4 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
    };

    switch (inputType) {
        case 'item':
            inputNode = (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ITEM.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                    setDefaultFilterValue={itemFilter}
                    mustGetPopupContainer={false}
                    SearchPopupComponent={<ItemIndex defaultFilterValue={itemFilter()} />}
                />
            );
            break;
        case 'quantity':
            inputNode = <NyInputNumber leftToRight={numbersWriting} style={{ width: '100%' }} min={1} />;
            break;
        case 'releaseQuantity':
            inputNode = releaseQuantityInputNode;
            break;
        case 'assetList':
            inputNode = (
                <Tooltip placement="top" title={geti18nText('inventoryOrder.edit.item.asset.merge')}>
                    <Button type="link" onClick={mergeAsset}>
                        <MergeCellsOutlined
                            style={{
                                fontSize: '20px',
                            }}
                        />
                    </Button>
                </Tooltip>
            );
            break;
        case 'asset':
            inputNode = (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ASSET.SEARCH}
                    map={{
                        id: 'id',
                        label: 'label',
                        text: 'text',
                        name: 'name',
                        serialNumber: 'serialNumber',
                        inventoryNumber: 'inventoryNumber',
                        assetModel: 'assetModel',
                        assetType: 'assetType',
                        item: 'item',
                        measureUnit: 'measureUnit',
                        quantity: 'quantity',
                    }}
                    searchBy="assetModel.name || serialNumber || inventoryNumber"
                    renderOption={customAssetRenderOption}
                    setDefaultFilterValue={assetFilter}
                    order="assetModel.name"
                    mustGetPopupContainer={false}
                    customListWidth={'1200px'}
                    SearchPopupComponent={<AssetSimpleIndex defaultFilterValue={assetFilter()} disabled />}
                />
            );
            break;
        case 'dateTo':
            inputNode = <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />;
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <>
            <td {...restProps}>
                {editing && inputType == 'releaseQuantity' ? (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : editing && inputType == 'assetList' ? (
                    inputNode
                ) : editing && inputType == 'item' ? (
                    <div style={{ marginTop: '15px' }}>
                        <ItemSearch setDefaultFilterValue={itemFilter} label="" required />
                    </div>
                ) : editing && inputType ? (
                    <Form.Item
                        rules={[
                            {
                                required: inputType == 'asset',
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
            {mergeAssetModalVisible && (
                <Modal
                    visible={mergeAssetModalVisible}
                    onCancel={closeAssetModal}
                    width={900}
                    title={geti18nText('inventoryOrder.edit.item.asset.merge')}
                    footer={[
                        <>
                            <Button key="back" onClick={closeAssetModal}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button key="submit" type="primary" onClick={saveAssetList}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                        </>,
                    ]}
                >
                    <Alert
                        style={{ marginBottom: '8px' }}
                        message={geti18nText('inventoryOrder.edit.item.asset.merge.info')}
                        type="info"
                        showIcon
                    />
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form form={assetListForm} layout="vertical">
                                <Table
                                    rowKey={'id'}
                                    components={{
                                        body: {
                                            cell: EditableCellAssetSelect,
                                        },
                                    }}
                                    size={'small'}
                                    dataSource={itemsAsset}
                                    columns={mergedColumnsAsset}
                                    rowClassName="editable-row"
                                    pagination={false}
                                    sticky
                                    scroll={{ y: 500, x: 500 }}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default EditableCell;
