import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import ContractGlobalConstantsEdit from './edit';
import { Alert, Tabs } from 'antd';
import { useState } from 'react';

const { TabPane } = Tabs;

const ContractGlobalConstantsIndex = ({
    url = CONSTANTS_REQ.CONTRACT_GLOBAL_CONSTANTS.LIST,
    defaultFilterValue = undefined,
    contractId,
    showHeader = true,
    buttonsClassName = 'buttons-sticky',
    showAlert = false,
    showDates = true,
    button2 = false,
    refresh = 0,
    setRefresh,
    showTabs = true,
    showNewButton = true,
    alertMessage = geti18nText('contract.global.constants.header'),
    scroll = { y: 520, x: 800 },
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [activeTab, setActiveTab] = useState('contract_column_null');
    const [refresh2, setRefresh2] = useState<any>(0);

    useHelper('human/codebooks/contracts/contract-global-constants');

    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const setDefaultFilterValue = () => {
        if (activeTab === 'contract_column_null') {
            if (defaultFilterValue) {
                return defaultFilterValue;
            } else {
                return [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'contract_column', condition: 'is_null', value: '' },
                ];
            }
        } else {
            if (defaultFilterValue) {
                return defaultFilterValue;
            } else {
                return [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'name', condition: 'is_null', value: '' },
                ];
            }
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('contract.global.constants.table.column.id'),
                dataIndex: 'id',
                width: '72px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('contract.global.constants.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('contract.global.constants.table.column.documentPlaceholder'),
                dataIndex: 'documentPlaceholder',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('contract.global.constants.table.column.value'),
                dataIndex: 'value',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(showDates
            ? [
                  {
                      title: geti18nText('contract.global.constants.table.column.validFrom'),
                      dataIndex: 'validFrom',
                      width: '12%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnDateOption(),
                      render: (text: any, record: any) => {
                          if (record.validFrom) {
                              return moment(record.validFrom).format('DD.MM.YYYY');
                          }
                      },
                  },
                  {
                      title: geti18nText('contract.global.constants.table.column.validTo'),
                      dataIndex: 'validTo',
                      width: '12%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnDateOption(),
                      render: (text: any, record: any) => {
                          if (record.validTo) {
                              return moment(record.validTo).format('DD.MM.YYYY');
                          }
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('contract.global.constants.table.column.active'),
                dataIndex: 'active',
                width: showDates ? '5%' : '8%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(),
            },
        ],
    ];

    const columns2 = [
        ...[
            {
                title: geti18nText('contract.global.constants.table.column.id'),
                dataIndex: 'id',
                width: '72px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('contract.global.constants.table.column.contractDocument'),
                dataIndex: 'contractColumn',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record.contractColumn) {
                        return geti18nText('app.enum.CONTRACT_CONSTANTS_CONTRACT_COLUMN.' + record.contractColumn);
                    }
                },
            },
            {
                title: geti18nText('contract.global.constants.table.column.documentPlaceholder'),
                dataIndex: 'documentPlaceholder',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(showDates
            ? [
                  {
                      title: geti18nText('contract.global.constants.table.column.validFrom'),
                      dataIndex: 'validFrom',
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnDateOption(),
                      render: (text: any, record: any) => {
                          if (record.validFrom) {
                              return moment(record.validFrom).format('DD.MM.YYYY');
                          }
                      },
                  },
                  {
                      title: geti18nText('contract.global.constants.table.column.validTo'),
                      dataIndex: 'validTo',
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnDateOption(),
                      render: (text: any, record: any) => {
                          if (record.validTo) {
                              return moment(record.validTo).format('DD.MM.YYYY');
                          }
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('contract.global.constants.table.column.active'),
                dataIndex: 'active',
                width: '8%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(),
            },
        ],
    ];

    const DataTable = (
        <NyDataTable
            nyId="human-contract-contract-global-contracts-table"
            url={url}
            addNewButtonText={
                showNewButton
                    ? button2
                        ? geti18nText('contract.global.constants.table.add2')
                        : geti18nText('contract.global.constants.table.add')
                    : undefined
            }
            buttonsClassName={buttonsClassName}
            editProps={{
                contractId: contractId,
                refresh: refresh,
                setRefresh: setRefresh,
                activeTab: activeTab,
                setRefresh2: setRefresh2,
            }}
            showRecordModal={true}
            modalComponent={ContractGlobalConstantsEdit}
            columns={activeTab === 'contract_column_null' ? columns : columns2}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.contract.global.constants')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            headerTitle={showHeader && geti18nText('menu.contract.global.constants')}
            fetchWhenChange={refresh || refresh2}
        />
    );

    const onTabChange = (activeKey: any) => {
        setActiveTab(activeKey);
        setRefresh2((prevValue: any) => prevValue + 1);
    };

    return (
        <>
            {showTabs ? (
                <>
                    <Tabs type="card" className="tabs-sticky" onChange={onTabChange} activeKey={activeTab}>
                        <TabPane tab={geti18nText('contract.global.constants.tab.1')} key={'contract_column_null'}>
                            {showAlert && (
                                <>
                                    <Alert message={alertMessage} type="warning" showIcon={true} />
                                    <br />
                                </>
                            )}
                            {DataTable}
                        </TabPane>
                        <TabPane tab={geti18nText('contract.global.constants.tab.2')} key={'not_null'}>
                            {showAlert && (
                                <>
                                    <Alert
                                        message={geti18nText('contract.global.constants.header')}
                                        type="warning"
                                        showIcon={true}
                                    />
                                    <br />
                                </>
                            )}
                            {DataTable}
                        </TabPane>
                    </Tabs>
                </>
            ) : (
                <>
                    {showAlert && (
                        <>
                            <Alert
                                message={geti18nText('contract.global.constants.header')}
                                type="warning"
                                showIcon={true}
                            />
                            <br />
                        </>
                    )}
                    {DataTable}
                </>
            )}
        </>
    );
};
export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.global.constants.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.global.constants.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('contract.global.constants.table.column.documentPlaceholder'),
            dataIndex: 'documentPlaceholder',
        },
        {
            title: geti18nText('contract.global.constants.table.column.value'),
            dataIndex: 'value',
        },
        {
            title: geti18nText('contract.global.constants.table.column.validFrom'),
            dataIndex: 'validFrom',
        },
        {
            title: geti18nText('contract.global.constants.table.column.validTo'),
            dataIndex: 'validTo',
        },
        {
            title: geti18nText('contract.global.constants.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ContractGlobalConstantsIndex;
