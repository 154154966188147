import { NyColorPicker, NyDataEdit, NyDatePicker, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, setDateFormat } from '../../../../utils/Utils';

const ShiftEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('shift.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const [selectedDays, setSelectedDays] = useState<any>([1, 2, 3, 4, 5]);

    const days = [
        { value: 1, label: geti18nText('shift.edit.mon') },
        { value: 2, label: geti18nText('shift.edit.tue') },
        { value: 3, label: geti18nText('shift.edit.wed') },
        { value: 4, label: geti18nText('shift.edit.thu') },
        { value: 5, label: geti18nText('shift.edit.fri') },
        { value: 6, label: geti18nText('shift.edit.sat') },
        { value: 7, label: geti18nText('shift.edit.sun') },
    ];

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('shift.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.hours_from) dataForm.hours_from = setDateFormat(dataForm.hours_from, 'HH:mm');
        if (dataForm.hours_to) dataForm.hours_to = setDateFormat(dataForm.hours_to, 'HH:mm');

        if (dataForm.workdays) {
            let workdays = dataForm.workdays.split('');
            dataForm.workdays = workdays.map((day: any) => parseInt(day));
            setSelectedDays(workdays);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('shift.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.SHIFT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            normalize={(values: any) => {
                if (values.hours_from) values.hours_from = getDateFormat(values.hours_from, 'HH:mm');
                if (values.hours_to) values.hours_to = getDateFormat(values.hours_to, 'HH:mm');
                values.workdays = selectedDays.join('');
                if (values.holidays === null || values.holidays === undefined) {
                    values.holidays = false;
                }

                return values;
            }}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={16}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('shift.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('shift.edit.abbreviation')}
                        name="abbreviation"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input maxLength={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('shift.edit.hoursFrom')}
                        name="hours_from"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker mode="time" format="HH:mm" style={{ width: '100%' }} nyTestId="shift-time-from" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('shift.edit.hoursTo')}
                        name="hours_to"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker mode="time" format="HH:mm" style={{ width: '100%' }} nyTestId="shift-time-to" />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label={geti18nText('shift.edit.color')} name="color">
                        <NyColorPicker value="color" onChange={() => {}} ref={document.body} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={16}>
                    <Form.Item
                        label={geti18nText('shift.edit.workdays')}
                        name="workdays"
                        initialValue={[1, 2, 3, 4, 5]}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Select
                            options={days}
                            mode="multiple"
                            onChange={(value: any) => {
                                setSelectedDays(value);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('shift.edit.holidays')} name="holidays" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>{' '}
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ShiftEdit;
