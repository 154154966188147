import { geti18nText, NyDataEdit, NyDatePicker, NySession } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import {
    getDateFormat,
    getSearchFormat,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setSearchFormat,
} from '../../../../../../utils/Utils';
import TextArea from 'antd/lib/input/TextArea';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeVaccinationEdit = (props: any) => {
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [editHeader, setEditHeader] = useState(geti18nText('employee.vaccination.edit.new'));
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        if (dataForm.disease) {
            setEditHeader(geti18nText('employee.vaccination.edit.header') + ' - ' + dataForm.disease.name);
            dataForm.disease = setSearchFormat(dataForm.disease, 'name', 'name', 'code');
        }
        if (dataForm.vaccine) {
            dataForm.vaccine = setSearchFormat(dataForm.vaccine, 'name', 'name', 'code');
        }
        if (dataForm.vaccinationDate) {
            dataForm.vaccinationDate = setDateFormat(dataForm.vaccinationDate);
        }
        if (dataForm.effectiveUntil) {
            dataForm.effectiveUntil = setDateFormat(dataForm.effectiveUntil);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.vaccination.edit.new'));
    };

    const onVaccinationDateChange = (value: any) => {
        const vaccinationDate = value;
        const effectiveUntil = form.getFieldValue('effectiveUntil');
        if (effectiveUntil && moment(effectiveUntil).isBefore(moment(vaccinationDate))) {
            form.setFieldsValue({ effectiveUntil: value });
        }
    };

    const disabledEffectiveUntilDate = (current: any) => {
        if (form.getFieldValue('vaccinationDate')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('vaccinationDate')).endOf('day');
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_VACCINATION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.disease) {
                    values.disease = getSearchFormat(values.disease);
                }
                if (values.vaccine) {
                    values.vaccine = getSearchFormat(values.vaccine);
                }
                if (values.vaccinationDate) {
                    values.vaccinationDate = getDateFormat(values.vaccinationDate);
                }
                if (values.effectiveUntil) {
                    values.effectiveUntil = getDateFormat(values.effectiveUntil);
                }
                values.employee = { id: id };
                return values;
            }}
            shortcuts={true}
            nyTestId="employee-vaccination-edit"
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('employee.vaccination.edit.disease')}
                        codebooksType={enCodebookType.DISEASE}
                        renderOption={(option: any) => `${option.text ?? option.name}`}
                        formItemName={'disease'}
                        disabled={!canCreate()}
                    />
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('employee.vaccination.edit.vaccine')}
                        codebooksType={enCodebookType.VACCINE}
                        renderOption={(option: any) => `${option.text ?? option.name}`}
                        formItemName={'vaccine'}
                        disabled={!canCreate()}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.vaccination.edit.vaccinationDate')}
                        name="vaccinationDate"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            nyTestId="vaccinationDate"
                            disabled={!canCreate()}
                            onChange={onVaccinationDateChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('employee.vaccination.edit.effectiveUntil')} name="effectiveUntil">
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            disabledDate={disabledEffectiveUntilDate}
                            nyTestId="effectiveUntil"
                            disabled={!canCreate()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('employee.vaccination.edit.note')} name="description">
                        <TextArea
                            rows={4}
                            onKeyDown={onKeyDownTextAreaCustom}
                            disabled={!canCreate()}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeVaccinationEdit;
