import EmployeeAnalyzeTable from './table';

const EmployeeAnalyzeTable55 = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    columns,
    sortOrder,
    setDefaultFilterValue,
    refresh,
    scroll,
    activeListSubTabs,
    activeListTabs,
    externalFiltersConfig,
    filterFormInstance,
}: any) => {
    return (
        <EmployeeAnalyzeTable
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            sortOrder={sortOrder}
            setDefaultFilterValue={setDefaultFilterValue}
            refresh={refresh}
            scroll={scroll}
            activeListSubTabs={activeListSubTabs}
            activeListTabs={activeListTabs}
            externalFiltersConfig={externalFiltersConfig}
            filterFormInstance={filterFormInstance}
        />
    );
};

export default EmployeeAnalyzeTable55;
