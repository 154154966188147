import { geti18nText } from '@nybble/nyreact';
import { Row, Steps } from 'antd';
import {
    getEmployeeEducationIcon,
    getEmployeeLeaveIcon,
    getEmployeeLeaveTypeIcon,
    getEnumArrayStatus,
    getTravelWarrantIcon,
} from '../../utils/Utils';

const { Step } = Steps;

const IconsModal = ({ enumType, textType }: any) => {
    const resolveIcon = (enumId: any) => {
        switch (enumType) {
            case 'TRAVEL_WARRANT_STATUS':
                return getTravelWarrantIcon(enumId, '20px');
            case 'EMPLOYEE_LEAVE_STATUS':
                return getEmployeeLeaveIcon(enumId, '20px');
            case 'EMPLOYEE_LEAVE_TYPE':
                return getEmployeeLeaveTypeIcon(enumId, '20px');
            case 'EMPLOYEE_EDUCATION_STATUS':
                return getEmployeeEducationIcon(enumId, '20px');
        }
    };

    const getText = (travelEnumId: any) => {
        return (
            geti18nText(textType + travelEnumId)
                .charAt(0)
                .toUpperCase(),
            geti18nText(textType + travelEnumId)
        );
    };

    const generateStatusSteps = () => {
        var result = (
            <Row className="steps-container">
                <Steps current={-1} className="travel-warrant-steps" responsive labelPlacement="vertical">
                    {getEnumArrayStatus(enumType) &&
                        getEnumArrayStatus(enumType).map((enumValue: any) => {
                            return (
                                <Step
                                    title={
                                        <div style={{ width: '50px', padding: '0', lineHeight: '120%' }}>
                                            {getText(enumValue.id)}
                                        </div>
                                    }
                                    icon={<div className="travel-warrant-steps-icon">{resolveIcon(enumValue.id)}</div>}
                                />
                            );
                        })}
                </Steps>
            </Row>
        );
        return result;
    };

    return <div className="travel-warrant-popover">{generateStatusSteps()}</div>;
};

export default IconsModal;
