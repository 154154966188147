import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat, getErrorNotificationMessage, okNotification } from '../../../../../utils/Utils';

const MealsGenerateBillsReportModal = ({
    url = CONSTANTS_REQ.GROUP_ORDER.GENERATE_BILLS,
    buttonTitle = 'meal.delivery.table.generate.bills.report.action',
    setRefresh,
}: any) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [date, setDate] = useState<any>(moment());

    const closeModal = () => {
        setVisible(false);
        form.resetFields();
    };

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    const generateBills = async () => {
        if (await checkBeforeSave()) {
            NyRequestResolver.requestGet(url, {
                date: getDateFormat(date, 'yyyy-MM-DD'),
            }).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    okNotification(geti18nText('meal.delivery.table.generate.bills.report.success'));
                    if (setRefresh) {
                        setRefresh((refresh: any) => refresh + 1);
                    }
                } else {
                    getErrorNotificationMessage(result);
                }
            });
        }
    };

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText(buttonTitle)}
            </Button>
            {visible && (
                <Modal
                    open={visible}
                    title={geti18nText(buttonTitle)}
                    maskClosable={false}
                    onCancel={closeModal}
                    footer={[
                        <Button
                            style={{ width: '100%' }}
                            onClick={generateBills}
                            disabled={!date}
                            icon={<FilePdfOutlined />}
                        >
                            {geti18nText(buttonTitle)}
                        </Button>,
                    ]}
                    width={300}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('meal.delivery.table.generate.bill.report.date')}
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={moment()}
                                    >
                                        <NyDatePicker
                                            format="DD.MM.YYYY"
                                            style={{ width: '100%' }}
                                            mustGetPopupContainer={false}
                                            onChange={(value: any) => setDate(value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default MealsGenerateBillsReportModal;
