import { CheckCircleOutlined, IssuesCloseOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Badge, Card, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ServicesRights } from '../../../rights/servicesRights';
import { RootState } from '../../../rootReducer';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType } from '../../../utils/Utils';

const ServicesOrderLightWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const widgetColor = getColorFromNotificationType(settings, 'SERVICES_ORDER');

    const hasServicesReleaseRole = () => {
        return ServicesRights.canCreateOrder();
    };

    function getServiceOrderKeys() {
        let keys = ['myServicesOrder'];
        if (hasServicesReleaseRole()) {
            keys.push('servicesOrderApproved');
            keys.push('servicesOrderRequested');
        }
        let parms = {
            keys: keys,
        };
        return parms;
    }

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(CONSTANTS_REQ.DASHBOARD.GET_STATISTICS, undefined, getServiceOrderKeys(), true, false, {
        myServicesOrder: 0,
        servicesOrderRequested: -1,
        servicesOrderApproved: -1,
    });

    const gridStyle: any = {
        width: '25%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '8px',
        height: '100%',
        display: 'inline-block',
    };

    return (
        <Card
            className="ny-widget-card"
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            title={geti18nText('menu.services')}
            bodyStyle={{ height: '55%', overflowY: 'hidden', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
            type="inner"
            extra={
                <Tooltip placement="top" title={geti18nText('menu.webshopServices')}>
                    <ShoppingCartOutlined
                        className="ny-card-icon"
                        onClick={() => {
                            history.push('/webshop/webshop-services');
                        }}
                    />
                </Tooltip>
            }
        >
            {loading ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : (
                <>
                    <div style={gridStyle}>
                        <Tooltip title={geti18nText('dashboard.statistic.services.order.count')}>
                            <Badge count={statisticData['myServicesOrder']} showZero size="small">
                                <ShoppingCartOutlined
                                    style={{ fontSize: '2em' }}
                                    className="statistic-card-light"
                                    onClick={() => history.push('/webshop/webshop-services/my-services-order')}
                                />
                            </Badge>
                        </Tooltip>
                    </div>
                    {statisticData && statisticData['servicesOrderRequested'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('dashboard.statistic.services.order.requested.count')}>
                                <Badge count={statisticData['servicesOrderRequested']} showZero size="small">
                                    <IssuesCloseOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => history.push('/webshop/webshop-services/services-order')}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {statisticData && statisticData['servicesOrderApproved'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('dashboard.statistic.services.order.approved.count')}>
                                <Badge count={statisticData['servicesOrderApproved']} showZero size="small">
                                    <CheckCircleOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => history.push('/webshop/webshop-services/services-order')}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

export default ServicesOrderLightWidget;
