import { AdministrationRights } from '../../rights/administrationRights';
import { AssetRights } from '../../rights/assetRights';
import { FixedAssetRights } from '../../rights/fixedAssetRights';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { InventoryRights } from '../../rights/inventoryRights';
import { ServicesRights } from '../../rights/servicesRights';
import { TasksRights } from '../../rights/tasksRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { WarehouseBookkeepingRights } from '../../rights/warehouseBookkeepingRights';
import AccountingIndex from '../../views/accounting';
import AdministrationIndex from '../../views/administration';
import DashboardIndex from '../../views/dashboard';
import DirectoryIndex from '../../views/dashboard/DirectoryIndex';
import HumanIndex from '../../views/human';
import MyPortalIndex from '../../views/my-portal/MyPortalIndex';
import MyProfileIndex from '../../views/my-profile';
import TaskServiceIndex from '../../views/task-service';
import TaskListUserIndex from '../../views/task-service/TaskListUserIndex';
import TasksIndex from '../../views/tasks';
import TaskListIndexApproval from '../../views/tasks/views/task-list/tables/TaskListIndexApproval';
import TranslationEditorIndex from '../../views/translation-editor';
import TravelWarrantsIndex from '../../views/travel-warrant';
import WarehouseIndex from '../../views/warehouse';
import WebshopIndex from '../../views/webshop';
import ApprovalIndex from '../approval';
import ReRouteAsset from './re-routes/ReRouteAsset';
import ReRouteAssetOrder from './re-routes/ReRouteAssetOrder';
import ReRouteAssetRelease from './re-routes/ReRouteAssetRelease';
import ReRouteAssetReturn from './re-routes/ReRouteAssetReturn';
import ReRouteEmployeeEvaluation from './re-routes/ReRouteEmployeeEvaluation';
import ReRouteInventoryOrder from './re-routes/ReRouteInventoryOrder';
import ReRouteServicesOrder from './re-routes/ReRouteServicesOrder';

export const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: DashboardIndex,
        role: AdministrationRights.canViewDashboardRoutes(),
    },
    {
        path: '/my-portal',
        exact: true,
        component: MyPortalIndex,
        role: AdministrationRights.canViewMyPortalRoutes(),
        isForEmployeesOnly:true
    },
    {
        path: '/directory',
        exact: true,
        component: DirectoryIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/my-profile',
        exact: true,
        component: MyProfileIndex,
        role: AdministrationRights.isAdminOrUserMenu(),
    },
    {
        path: '/administration',
        component: AdministrationIndex,
        role: HumanResourcesRights.canViewMenuEmployeeCompanyLeave(),
    },
    {
        path: '/human',
        component: HumanIndex,
        role: HumanResourcesRights.canViewHumanOverviewModule(),
    },
    {
        path: '/travel-warrant',
        component: TravelWarrantsIndex,
        role: TravelWarrantRights.canViewTravelWarrantRoutes(),
    },
    {
        path: '/webshop',
        component: WebshopIndex,
        role: AssetRights.canViewWebshopRoutes(),
    },
    {
        path: '/warehouse',
        component: WarehouseIndex,
        role: WarehouseBookkeepingRights.canViewWarehouseRoutes(),
    },
    {
        path: '/accounting',
        component: AccountingIndex,
        role: FixedAssetRights.canViewCodebooksCompanyRoutes(),
    },
    {
        path: '/approval',
        exact: true,
        component: ApprovalIndex,
        role: [],
    },
    {
        path: '/tasks',
        component: TasksIndex,
        role: TasksRights.canViewTasksMenuUser(),
    },
    {
        path: '/task-service',
        exact: true,
        component: TaskServiceIndex,
        role: TasksRights.canViewTasksMenuUser(),
    },
    {
        path: '/my-task-list',
        exact: true,
        component: TaskListUserIndex,
        role: TasksRights.canViewTasksMenuUser(),
    },
    {
        path: '/approval-task-list',
        exact: true,
        component: TaskListIndexApproval,
        role: TasksRights.canViewTasksMenuApproval(),
    },
    {
        path: '/translation-editor',
        exact: true,
        component: TranslationEditorIndex,
        role: ['ROLE_ADMIN'],
    },
    {
        path: '/asset/:id',
        exact: true,
        component: ReRouteAsset,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/asset-order/:id',
        component: ReRouteAssetOrder,
        role: AssetRights.canViewOrderMenu(),
    },
    {
        path: '/asset-release/:id',
        exact: true,
        component: ReRouteAssetRelease,
        role: AssetRights.canViewReleaseRoutes(),
    },
    {
        path: '/asset-return/:id',
        exact: true,
        component: ReRouteAssetReturn,
        role: AssetRights.canViewReturnRoutes(),
    },
    {
        path: '/inventory-order/:id',
        component: ReRouteInventoryOrder,
        role: InventoryRights.canViewOrderMenu(),
    },
    {
        path: '/services-order/:id',
        exact: true,
        component: ReRouteServicesOrder,
        notificationType: 9,
        role: ServicesRights.canViewOrderMenu(),
    },
    {
        path: '/employee-evaluation/employeeEvaluation/:id',
        exact: true,
        component: ReRouteEmployeeEvaluation,
        role: HumanResourcesRights.canViewEvaluation(),
    },
    {
        path: '/human/employee-evaluation/:id',
        exact: true,
        component: ReRouteEmployeeEvaluation,
        role: HumanResourcesRights.canViewEvaluation(),
    },
];
