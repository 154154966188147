import { geti18nText, NyDatePicker, NyInputNumber } from '@nybble/nyreact';
import { Checkbox, Col, Empty, Form, Input, Modal, Row } from 'antd';
import { useEffect } from 'react';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import { getDateFormat, onKeyDownTextAreaCustom, setDateFormat } from '../../../../../utils/Utils';

const MetadataModal = ({
    itemTitle,
    metadataModalVisible,
    setMetadataModalVisible,
    disabelMetadata,
    metadata,
    setEditingKey,
    items,
    setItems,
}: any) => {
    const [objectMetadataForm] = Form.useForm();

    useEffect(() => {
        if (metadata !== undefined && metadata.length > 0) {
            const metadataForm: any = {};

            if (itemTitle !== undefined && itemTitle.servicesOrderItemMetadata) {
                itemTitle.servicesOrderItemMetadata.map((item: any) => {
                    if (item.item_group_metadata !== undefined) {
                        const findItem: any = metadata.find((row: any) => row.id === item.item_group_metadata.id);
                        if (findItem !== undefined && findItem.objectMetadata !== undefined) {
                            if (findItem.objectMetadata.dataType === 4) {
                                metadataForm[findItem.objectMetadata.name] = setDateFormat(item.value);
                            } else {
                                metadataForm[findItem.objectMetadata.name] = item.value;
                            }
                        }
                    }
                });
            }

            objectMetadataForm.setFieldsValue(metadataForm);
        }
    }, [metadata]);

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return (
                    <Input.TextArea
                        disabled={disabelMetadata}
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        onKeyDown={onKeyDownTextAreaCustom}
                    />
                );
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                        disabled={disabelMetadata}
                    />
                );
            case 'BOOL':
                return <Checkbox disabled={disabelMetadata} />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} disabled={disabelMetadata} />;
            default:
                return <Input disabled={disabelMetadata} />;
        }
    };

    const save = async () => {
        objectMetadataForm
            .validateFields()
            .then((metadataValues: any) => {
                try {
                    const newData = [...items];
                    const index = newData.findIndex((item) => itemTitle.id === item.id);
                    let metadataReturn = [];

                    for (const [key, value] of Object.entries(metadataValues)) {
                        const metadataItem: any = metadata.find(
                            (item: any) => item.objectMetadata && item.objectMetadata.name === key
                        );
                        if (metadataItem.objectMetadata.dataType === 4) {
                            metadataReturn.push({
                                item_group_metadata: { id: metadataItem.id },
                                value: getDateFormat(value, 'yyyy-MM-DD HH:mm:ss'),
                            });
                        } else {
                            metadataReturn.push({ item_group_metadata: { id: metadataItem.id }, value: value });
                        }
                    }

                    if (index > -1) {
                        newData.splice(index, 1, {
                            ...itemTitle,
                            servicesOrderItemMetadata: metadataReturn,
                            quantity: 1,
                        });
                        setItems(newData);
                        setEditingKey('');
                    }

                    setMetadataModalVisible(false);
                    objectMetadataForm.resetFields();
                } catch (errInfo) {
                    console.log('Validate Failed:', errInfo);
                }
            })
            .catch((errorInfo: any) => {
                console.log('Validate Failed:', errorInfo);
            });
    };

    return (
        <>
            {metadataModalVisible && (
                <Modal
                    title={itemTitle && itemTitle.item && itemTitle.item.name}
                    visible={metadataModalVisible}
                    okText={geti18nText('app.default.button.save')}
                    onOk={save}
                    onCancel={() => {
                        setMetadataModalVisible(false);
                        objectMetadataForm.resetFields();
                    }}
                    okButtonProps={{ style: { display: disabelMetadata ? 'none' : 'unset' } }}
                    maskClosable={false}
                >
                    <Form layout="vertical" form={objectMetadataForm}>
                        <Row gutter={24}>
                            {metadata &&
                                metadata.length >= 1 &&
                                metadata.map((item: any) => {
                                    return (
                                        <Col span={24}>
                                            <Form.Item
                                                label={item.objectMetadata.name}
                                                name={item.objectMetadata.name}
                                                rules={[
                                                    {
                                                        required: item.objectMetadata.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                                valuePropName={item.objectMetadata.dataType === 3 ? 'checked' : 'value'}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            {(!metadata || metadata.length === 0) && (
                                <Empty style={{ margin: 'auto' }} description={geti18nText('objectMetadata.empty')} />
                            )}
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default MetadataModal;
