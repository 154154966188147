import { useState, useRef } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { geti18nText, NyDataEdit, NySession } from '@nybble/nyreact';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import useEnum from '../../../../hooks/useEnum';
import { MobilizationRights } from '../../../../rights/mobilizationRights';

const ProfessionalSpecialtyEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('menu.work.obligation.professional.specialty'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();

    const EnProfessionalSpecialtyType = useEnum('PROFESSIONAL_SPECIALTY_TYPE');

    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('menu.work.obligation.professional.specialty') + ' - ' + dataForm.name);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('currency.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.PROFESSIONAL_SPECIALTY.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            {...props}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.type = EnProfessionalSpecialtyType.MILITARY;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('currency.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ProfessionalSpecialtyEdit;
