import { CaretLeftOutlined, CaretRightOutlined, InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import {
    NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Avatar, Button, Card, Col, Empty, Form, Image, List, Popover, Row, Tag, Tooltip, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import { MealsRights } from '../../rights/mealsRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    checkIsWeekend,
    customMenuWidgetRenderOption,
    getColorFromNotificationType,
    getDateFormat,
    getMealPriceWithSubvention,
    setSearchFormat,
} from '../../utils/Utils';
import './index.scss';

const { Title, Text } = Typography;

const MealsMenuWidget = (props: any, { overviewIndex = false }: any) => {
    const { settings } = useSelector((state: RootState) => state.notification);
    const [deliverySpotForm] = Form.useForm();
    const [menuList, setMenuList] = useState<any>([]);
    const [menuListGrouped, setMenuListGrouped] = useState<any>([]);
    const [currentDay, setCurrentDay] = useState(1);
    const [todayDate, setTodayDate] = useState<any>(
        moment().locale(NyUtils.getSelectedLocale() === 'hr-HR' ? 'hr' : 'en')
    );
    const [selectedDeliverySpot, setSelectedDeliverySpot] = useState<any>(null);
    const widgetColor = getColorFromNotificationType(settings, 'MEALS_ORDER');
    const [deliveryType, setDeliveryType] = useState<any>(undefined);
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const appSettingMealSubvention = useApplicationSetting('MEALS_SUBVENTION');

    const canView = () => {
        return MealsRights.isMealsAdminOrUser();
    };

    useEffect(() => {
        setCurrentDay(todayDate);
        getDeliverySpotData();
    }, []);

    useEffect(() => {
        if (selectedDeliverySpot) {
            getDeliverySpotMenuData(selectedDeliverySpot, todayDate);
        }
    }, [selectedDeliverySpot, todayDate]);

    const prev = () => {
        setTodayDate(todayDate.subtract(1, 'days'));
        getDeliverySpotMenuData(selectedDeliverySpot, todayDate);
    };

    const next = () => {
        setTodayDate(todayDate.add(1, 'days'));
        getDeliverySpotMenuData(selectedDeliverySpot, todayDate);
    };

    const gridStyle: any = {
        width: '20%',
        cursor: 'pointer',
        paddingTop: '4px',
        height: '100%',
        display: 'inline-block',
        margin: '5px 0px 10px 5px',
    };

    const gridStyleText: any = {
        width: '52%',
        cursor: 'pointer',
        paddingTop: '4px',
        height: '100%',
        fontSize: '14px',
        display: 'inline-block',
        textAlign: 'center',
    };

    const getDateDay = () => {
        let day = todayDate.format('dddd');
        let dayFirstChar = day.charAt(0);
        let dayCap = dayFirstChar.toUpperCase();
        let otherChars = day.slice(1);
        let dayCapped = dayCap + otherChars;
        return dayCapped;
    };

    const defaultActiveFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getDeliverySpotData = () => {
        let lastDeliverySpot: any = {};

        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.LAST_DELIVERY_SPOT).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.deliverySpotId) {
                setSelectedDeliverySpot(result.data.deliverySpotId);
                setDeliveryType(result?.data?.type);
                getDeliverySpotMenuData(selectedDeliverySpot, todayDate);
                lastDeliverySpot = { deliverySpotId: result.data.deliverySpotId, type: result.data.type };
            }
        });
        if (currentDay) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.MEAL_DELIVERY_SPOT.SEARCH + '/' + todayDate.format('YYYY-MM-DD'),
                {
                    search: encodeURI(JSON.stringify(defaultActiveFilter())),
                }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    const findItem: any = result.data.find(
                        (item: any) =>
                            item.deliverySpotId === lastDeliverySpot.deliverySpotId &&
                            item.type === lastDeliverySpot.type
                    );
                    if (findItem) {
                        deliverySpotForm.setFieldsValue({
                            deliverySpot: setSearchFormat(
                                {
                                    id: findItem.id,
                                    name: findItem.delivery.name,
                                },
                                'name',
                                'name'
                            ),
                        });
                    }
                }
            });
        }
    };

    const getDeliverySpotMenuData = (deliverySpot: any, todayDate: any) => {
        setMenuList([]);
        setMenuListGrouped(undefined);
        if (deliverySpot > -1 && currentDay) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.MEAL_MENU.EDIT + '/' + deliverySpot + '/' + todayDate.format('YYYY-MM-DD'),
                {
                    deliverySpot: deliverySpot,
                    date: todayDate.format('YYYY-MM-DD'),
                    deliveryType: deliveryType,
                }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setMenuList(result.data);
                    const emptyData: any = [];
                    const mealsWithNoGroupList: any = [];
                    let data = _.cloneDeep(result.data);
                    let noGroupHasMeals = false;
                    data = data
                        .filter(
                            (element: any) =>
                                element.mealGroup?.id == null ||
                                element.mealGroup?.id == undefined ||
                                element.mealGroup == null ||
                                element.mealGroup == undefined ||
                                element.mealGroup == emptyData
                        )
                        .map((value: any) => {
                            mealsWithNoGroupList.push(value);
                            noGroupHasMeals = true;
                        });

                    const mealsGroupOther: any = { group: 'Ostalo', meals: mealsWithNoGroupList };

                    const groupedList: any = _(result.data)
                        .filter((item: any) => item.mealGroup?.id != null && item.mealGroup?.id != undefined)
                        .groupBy((x) => x.mealGroup.name)
                        .map((value: any, key: any) => {
                            return {
                                group: key,
                                meals: value,
                            };
                        })
                        .value();
                    if (noGroupHasMeals) groupedList.push(mealsGroupOther);
                    setMenuListGrouped(groupedList);
                }
            });
        }
    };

    const onDeliverySpotChange = (value: any) => {
        if (value && value.deliverySpotId !== -1 && value.name) {
            let spotID = value.deliverySpotId.substring(0, value.deliverySpotId.indexOf('-'));
            let deliveryTypeSubstring = value.deliverySpotId.slice(value.deliverySpotId.indexOf('-') + 1);
            setDeliveryType(deliveryTypeSubstring);
            setSelectedDeliverySpot(spotID);

            const deliverySpotName =
                value && value.name && value.name.props && value.name.props.children ? value.name.props.children : null;
            if (deliverySpotName) {
                deliverySpotForm.setFieldsValue({
                    deliverySpot: setSearchFormat({ id: spotID, name: deliverySpotName }, 'name', 'name'),
                });
            }
        } else {
            deliverySpotForm.setFieldsValue({
                deliverySpot: null,
            });
        }
    };

    const getPopover = (item: any) => {
        let priceWithSubvention: any = getMealPriceWithSubvention(
            appSettingMealSubvention,
            item.meal.allowedDiscount,
            item.priceList.price
        );
        return (
            <>
                <Popover
                    placement="topLeft"
                    overlayStyle={{ maxWidth: '400px' }}
                    content={
                        <Card>
                            <Meta
                                avatar={
                                    <div>
                                        {item && item.meal && item.meal.fileId ? (
                                            <Popover
                                                title={item.meal.name}
                                                placement="right"
                                                content={
                                                    <Image
                                                        style={{
                                                            objectFit: 'cover',
                                                            height: '464px',
                                                            width: '464px',
                                                            borderRadius: '5px',
                                                        }}
                                                        preview={false}
                                                        src={
                                                            CONSTANTS_REQ.FILES.DOWNLOAD +
                                                            '/' +
                                                            item.meal.fileId +
                                                            '?tenant=' +
                                                            NySession.getUser().tenantId
                                                        }
                                                    />
                                                }
                                            >
                                                <Avatar
                                                    src={
                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                        '/' +
                                                        item.meal.fileId +
                                                        '?tenant=' +
                                                        NySession.getUser().tenantId
                                                    }
                                                />
                                            </Popover>
                                        ) : (
                                            <Avatar icon={<UserOutlined />} />
                                        )}
                                    </div>
                                }
                                title={
                                    <>
                                        <div
                                            style={{
                                                textOverflow: 'clip',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {item.meal && item.meal.name}
                                        </div>
                                        <br />
                                        {mealSyncTypeHNB ? (
                                            <>
                                                <Tooltip title={geti18nText('meal.order.priceWithoutSubvention')}>
                                                    <Tag>
                                                        {NyUtils.formatNumber(item.priceList.price, 2) +
                                                            ' ' +
                                                            (item.priceList.currencyIsoCode
                                                                ? item.priceList.currencyIsoCode
                                                                : '')}
                                                    </Tag>
                                                </Tooltip>
                                                /{'   '}
                                                <Tooltip title={geti18nText('meal.order.priceWithSubvention')}>
                                                    <Tag color="green">
                                                        {NyUtils.formatNumber(priceWithSubvention, 2) +
                                                            ' ' +
                                                            (item.priceList.currencyIsoCode
                                                                ? item.priceList.currencyIsoCode
                                                                : '')}
                                                    </Tag>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                {item.priceList &&
                                                    item.priceList.price &&
                                                    NyUtils.formatNumber(item.priceList.price, 2)}{' '}
                                                {item.priceList && item.priceList.currencyIsoCode}
                                            </>
                                        )}
                                    </>
                                }
                                description={
                                    <>
                                        {!mealSyncTypeHNB && (
                                            <>
                                                {item.menu && item.menu.name}
                                                <br />
                                                {item.mealGroup && item.mealGroup.name}
                                                <br />
                                                {item.meal && item.meal.description}
                                                <br />
                                            </>
                                        )}
                                    </>
                                }
                            />
                        </Card>
                    }
                    trigger="hover"
                    overlayClassName="ny-popover-widget"
                >
                    <div>
                        {item.meal && item.meal.name}
                        <InfoCircleOutlined
                            style={{
                                float: 'right',
                                fontSize: '14px',
                                marginRight: '5px',
                            }}
                        />
                    </div>
                </Popover>
            </>
        );
    };

    return (
        <Card
            //  style={{ maxHeight: '400px', overflow: 'hidden' }}
            className="ny-widget-card"
            title={
                <Form form={deliverySpotForm} style={{ display: 'inline', backgroundColor: 'red' }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div style={{ marginTop: '-12px', marginLeft: '4px', fontSize: '11px' }}>
                                {geti18nText('menu.meals.deliverySpot')}
                                {':'}
                            </div>
                            <div style={{ marginTop: '-2px' }}>
                                <Form.Item name="deliverySpot">
                                    <NySearchField
                                        className="ny-search-field-no-margin"
                                        url={
                                            CONSTANTS_REQ.DELIVERY_SPOT.SEARCH_ALL +
                                            '/' +
                                            getDateFormat(todayDate, 'yyyy-MM-DD')
                                        }
                                        map={{
                                            id: 'deliverySpotId',
                                            label: 'name',
                                            delivery: 'delivery',
                                            type: 'type',
                                        }}
                                        searchBy={'name'}
                                        onChange={(e: any) => {
                                            onDeliverySpotChange(e);
                                        }}
                                        mode={'makeKey'}
                                        placeholder={geti18nText('menu.meals.deliverySpot')}
                                        setDefaultFilterValue={defaultActiveFilter}
                                        mustGetPopupContainer={false}
                                        renderOption={(e: any) => customMenuWidgetRenderOption(e, overviewIndex)}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            }
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33`, height: '50px' }}
            bodyStyle={{
                height: 'calc(100% - 55px)',
                overflowY: 'scroll',
                overflowX: 'hidden',
                padding: '0px 5px 0px 5px',
                marginBottom: '5px',
                maxHeight: props?.maxWidgetHeight ? props?.maxWidgetHeight : 'auto',
            }}
            type="inner"
        >
            <>
                <div>
                    <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', marginBottom: '10px' }}>
                        <Button onClick={() => prev()} icon={<CaretLeftOutlined />} style={gridStyle} />
                        <Text style={gridStyleText}>
                            {getDateDay()} {todayDate.format('DD.MM.')}
                        </Text>
                        <Button onClick={() => next()} icon={<CaretRightOutlined />} style={gridStyle} />
                    </div>

                    {(!menuList || !menuListGrouped) && selectedDeliverySpot > 0 ? (
                        <div style={{ paddingTop: 20 }}>
                            <NySpinner />
                        </div>
                    ) : (menuList && menuList.length == 0) ||
                      (menuListGrouped && menuListGrouped.length == 0) ||
                      selectedDeliverySpot == -1 ||
                      checkIsWeekend(moment(todayDate)) ||
                      !canView() ? (
                        <div
                            style={{
                                paddingTop: 10,
                                height: mealSyncTypeHNB ? '30vh' : '60vh',
                                verticalAlign: 'middle',
                            }}
                        >
                            <Empty
                                className="ny-dashboard-empty"
                                imageStyle={{ width: '100%' }}
                                description={<Title level={5}>{geti18nText('app.default.nodata')}</Title>}
                            />
                        </div>
                    ) : (
                        <Row gutter={24}>
                            <Col span={24}>
                                <List
                                    grid={{
                                        gutter: 24,
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 1,
                                        xl: 1,
                                        xxl: 1,
                                    }}
                                    dataSource={menuListGrouped}
                                    renderItem={(mainMealGroup: any) => (
                                        <Card>
                                            <Meta
                                                title={mainMealGroup.group}
                                                description={
                                                    <>
                                                        <List
                                                            grid={{
                                                                gutter: 24,
                                                                xs: 1,
                                                                sm: 1,
                                                                md: 1,
                                                                lg: 1,
                                                                xl: 1,
                                                                xxl: 1,
                                                            }}
                                                            dataSource={mainMealGroup.meals}
                                                            renderItem={(item: any) => {
                                                                let priceWithSubvention: any =
                                                                    getMealPriceWithSubvention(
                                                                        appSettingMealSubvention,
                                                                        item.meal.allowedDiscount,
                                                                        item.priceList.price
                                                                    );
                                                                return (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            title={
                                                                                <div className="statistic-card-light">
                                                                                    {getPopover(item)}
                                                                                </div>
                                                                            }
                                                                            description={
                                                                                <>
                                                                                    {/* {item.priceList &&
                                                                                        item.priceList.price &&
                                                                                        NyUtils.formatNumber(
                                                                                            item.priceList.price,
                                                                                            2
                                                                                        )}{' '}
                                                                                    {item.priceList &&
                                                                                        item.priceList
                                                                                            .currencyIsoCode}{' '}
                                                                                    /{' '}
                                                                                    {item.priceList &&
                                                                                        item.priceList.price &&
                                                                                        NyUtils.formatNumber(
                                                                                            priceWithSubvention,
                                                                                            2
                                                                                        )}{' '}
                                                                                    {item.priceList &&
                                                                                        item.priceList.currencyIsoCode} */}
                                                                                    {mealSyncTypeHNB ? (
                                                                                        <>
                                                                                            {' '}
                                                                                            <Tooltip
                                                                                                title={geti18nText(
                                                                                                    'meal.order.priceWithoutSubvention'
                                                                                                )}
                                                                                            >
                                                                                                <Tag>
                                                                                                    {NyUtils.formatNumber(
                                                                                                        item.priceList
                                                                                                            .price,
                                                                                                        2
                                                                                                    ) +
                                                                                                        ' ' +
                                                                                                        (item.priceList
                                                                                                            .currencyIsoCode
                                                                                                            ? item
                                                                                                                  .priceList
                                                                                                                  .currencyIsoCode
                                                                                                            : '')}
                                                                                                </Tag>
                                                                                            </Tooltip>
                                                                                            /{'   '}
                                                                                            <Tooltip
                                                                                                title={geti18nText(
                                                                                                    'meal.order.priceWithSubvention'
                                                                                                )}
                                                                                            >
                                                                                                <Tag color="green">
                                                                                                    {NyUtils.formatNumber(
                                                                                                        priceWithSubvention,
                                                                                                        2
                                                                                                    ) +
                                                                                                        ' ' +
                                                                                                        (item.priceList
                                                                                                            .currencyIsoCode
                                                                                                            ? item
                                                                                                                  .priceList
                                                                                                                  .currencyIsoCode
                                                                                                            : '')}
                                                                                                </Tag>
                                                                                            </Tooltip>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {item.priceList &&
                                                                                                item.priceList.price &&
                                                                                                NyUtils.formatNumber(
                                                                                                    item.priceList
                                                                                                        .price,
                                                                                                    2
                                                                                                )}{' '}
                                                                                            {item.priceList &&
                                                                                                item.priceList
                                                                                                    .currencyIsoCode}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                        />
                                                                    </List.Item>
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                }
                                            />
                                        </Card>
                                    )}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
            </>
        </Card>
    );
};

export default MealsMenuWidget;
