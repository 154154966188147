import { geti18nText, NySession } from '@nybble/nyreact';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../../hooks/useHelper';
import { RootState } from '../../../../../rootReducer';
import { getColorFromNotificationType, userIsNotificationRecipient } from '../../../../../utils/Utils';
import ServicesOrderApproval from './ServicesOrderApproval';
import ServicesOrderTable from './ServicesOrderTable';
import { ServicesRights } from '../../../../../rights/servicesRights';
import { AdministrationRights } from '../../../../../rights/administrationRights';

const { TabPane } = Tabs;

const ServicesOrderIndex = () => {
    const location = useLocation();

    const { employee } = useSelector((state: RootState) => state.employee);
    const { settings } = useSelector((state: RootState) => state.notification);

    useHelper('service/services_order');

    return (
        <Tabs type="card">
            {ServicesRights.canViewOrder() && (
                <TabPane tab={geti18nText('servicesOrder.tab.1')} key="1">
                    <ServicesOrderTable scroll={{ y: 540, x: 800 }} />
                </TabPane>
            )}
            {(AdministrationRights.isAdmin() || userIsNotificationRecipient(employee, 9)) && (
                <TabPane tab={geti18nText('servicesOrder.tab.2')} key="2">
                    <ServicesOrderApproval scroll={{ y: 583, x: 800 }} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default ServicesOrderIndex;
