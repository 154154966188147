import { ClockCircleOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Modal, Row, Select, Statistic } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    GetEmployeeEvaluationStatusIcon,
    errorNotification,
    okNotification,
    warningNotification,
} from '../../../../../utils/Utils';
import { BusinessUnitSearch } from '../../business-unit/search';

const { confirm } = Modal;

const SendEvaluationModal = ({
    sendEvaluationModalVisible,
    setSendEvaluationModalVisible,
    setRefresh,
    defaultPeriod,
}: any) => {
    const [form] = Form.useForm();
    const employeeEvaluationStatusEnum = useEnum('EMPLOYEE_EVALUATION_STATUS');
    const [countDraft, setCountDraft] = useState<any>(0);
    const [periods, setPeriods] = useState<any>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<any>(null);

    useEffect(() => {
        if (sendEvaluationModalVisible) {
            if (defaultPeriod?.id) {
                const dateFrom = defaultPeriod?.dateFrom ? moment(defaultPeriod.dateFrom).format('DD.MM.YYYY.') : '';
                const dateTo = defaultPeriod?.dateTo ? moment(defaultPeriod.dateTo).format('DD.MM.YYYY.') : '';
                const dates = dateFrom + ' - ' + dateTo;
                defaultPeriod.text = dates;
                defaultPeriod.label = dates;
                form.setFieldsValue({ period: defaultPeriod });
                setSelectedPeriod(defaultPeriod);
                getPeriods(null, defaultPeriod);
            } else {
                form.setFieldsValue({ period: null });
                setSelectedPeriod(null);
                getPeriods(null, defaultPeriod);
            }
        }
    }, [defaultPeriod, sendEvaluationModalVisible]);

    useEffect(() => {
        if (sendEvaluationModalVisible) {
            getPeriods(selectedBusinessUnit?.id ? selectedBusinessUnit.id : null);
        }
    }, [selectedBusinessUnit, sendEvaluationModalVisible]);

    useEffect(() => {
        if (sendEvaluationModalVisible) {
            setCountDraft(selectedPeriod?.count ?? 0);
        }
    }, [selectedPeriod, sendEvaluationModalVisible]);

    const closeModal = () => {
        setSendEvaluationModalVisible(false);
        form.resetFields();
        setSelectedPeriod(null);
        setSelectedBusinessUnit(null);
        setCountDraft(0);
    };

    const getPeriods = (selectedBusinessUnitId: any, defaultPeriod: any = null) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.GET_DISTINCT_EVALUATION_PERIODS, {
            businessUnitId: selectedBusinessUnitId,
            draftOnly: true,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.length > 0) {
                const formattedDates = result.data.map((element: any) => {
                    const dateFrom = element?.dateFrom ? moment(element.dateFrom).format('DD.MM.YYYY.') : '';
                    const dateTo = element?.dateTo ? moment(element.dateTo).format('DD.MM.YYYY.') : '';
                    const dates = dateFrom + ' - ' + dateTo;
                    element.name = dates;

                    return element;
                });
                setPeriods(formattedDates);
                if (selectedPeriod || defaultPeriod) {
                    let period = selectedPeriod ?? defaultPeriod;
                    let newCountList = formattedDates.filter((item: any) => item.id == period.id);
                    let newCount = newCountList && newCountList[0] && newCountList[0].count;
                    setSelectedPeriod({ ...period, count: newCount });
                }
            } else {
                setPeriods([]);
                if (selectedPeriod) {
                    setSelectedPeriod({ ...selectedPeriod, count: 0 });
                }
            }
        });
    };

    const onSave = (status: any) => {
        form.validateFields().then((values: any) => {
            NyRequestResolver.requestPut(CONSTANTS_REQ.EMPLOYEE_EVALUATION.SEND_MULTIPLE_EVALUATIONS, undefined, {
                businessUnitId: selectedBusinessUnit?.businessUnit?.id
                    ? selectedBusinessUnit.businessUnit?.id
                    : selectedBusinessUnit?.id
                    ? selectedBusinessUnit.id
                    : null,
                employeeEvaluationPeriodId: selectedPeriod?.id,
                status: status,
            }).then((result: any) => {
                if (result?.status === RESPONSE.CREATED || result?.status === RESPONSE.OK) {
                    okNotification();
                    setRefresh((refresh: any) => refresh + 1);
                    closeModal();
                } else {
                    errorNotification();
                }
            });
        });
    };

    const getSelectedPeriodDates = () => {
        if (selectedPeriod?.name) {
            return '(' + selectedPeriod?.name + ')';
        } else {
            return '';
        }
    };

    const footer = (
        <div style={{ float: 'right', display: 'contents' }}>
            <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
            <Button
                type="primary"
                danger
                disabled={countDraft < 1}
                icon={<CloseOutlined />}
                onClick={() =>
                    showConfirm(
                        employeeEvaluationStatusEnum.CANCELLED,
                        geti18nText('employee.evaluation.edit.button.cancel.confirm')
                    )
                }
            >
                {geti18nText('employee.evaluation.edit.button.cancel')}
            </Button>
            <Button
                type="primary"
                disabled={countDraft < 1}
                icon={<ClockCircleOutlined />}
                onClick={() =>
                    showConfirm(
                        employeeEvaluationStatusEnum.ESTIMATION,
                        geti18nText('employee.evaluation.edit.button.sendToEvaluation.confirm')
                    )
                }
            >
                {geti18nText('employee.evaluation.edit.button.sendToEvaluation')}
            </Button>
        </div>
    );

    function showConfirm(status: any, message: any) {
        if (selectedPeriod) {
            const content = (
                <>
                    <Statistic
                        title={
                            <>
                                {geti18nText('employee.evaluation.edit.button.sendToEvaluation.count')}{' '}
                                {getSelectedPeriodDates()}
                            </>
                        }
                        value={countDraft}
                        prefix={GetEmployeeEvaluationStatusIcon(employeeEvaluationStatusEnum.DRAFT)}
                    />
                </>
            );
            confirm({
                title: message,
                content: content,
                okText: geti18nText('app.default.button.yes'),
                cancelText: geti18nText('app.default.button.no'),
                icon: <ExclamationCircleOutlined />,
                onCancel() {},
                onOk() {
                    onSave(status);
                },
                width: 600,
            });
        } else {
            warningNotification(geti18nText('app.default.required.fields'));
        }
    }

    return (
        <Modal
            open={sendEvaluationModalVisible}
            onCancel={closeModal}
            maskClosable={false}
            title={
                <>
                    {defaultPeriod
                        ? geti18nText('employee.evaluation.edit.button.sendToEvaluation') +
                          ' (' +
                          moment(defaultPeriod?.dateFrom).format('DD.MM.YYYY.') +
                          ' - ' +
                          moment(defaultPeriod?.dateTo).format('DD.MM.YYYY.') +
                          ')'
                        : geti18nText('employee.evaluation.edit.button.sendToEvaluation')}
                </>
            }
            width={600}
            forceRender={true}
            closable={true}
            footer={footer}
        >
            <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: '10px' }}>
                        <Alert
                            message={geti18nText('employee.evaluation.edit.button.sendToEvaluation.info')}
                            type="info"
                            showIcon
                            style={{ margin: '5px' }}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <BusinessUnitSearch
                            style={{ width: '100%' }}
                            searchPopupComponent={false}
                            formItemName="businessUnit"
                            onChange={(value: any) => setSelectedBusinessUnit(value?.id > 0 ? value : null)}
                        />
                    </Col>
                </Row>
                {!defaultPeriod && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="period"
                                label={geti18nText('employee.evaluation.table.column.dateFromTo')}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value: any, option: any) => setSelectedPeriod(option)}
                                    notFoundContent={periods.length > 0 ? true : false}
                                    filterOption={false}
                                    value={selectedPeriod}
                                    allowClear
                                >
                                    {periods.map((option: any) => (
                                        <Select.Option
                                            key={option.id}
                                            id={option.id}
                                            value={option.id}
                                            name={option.name}
                                            count={option.count}
                                        >
                                            {option?.name ?? ''}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {selectedPeriod && (
                    <Row gutter={24}>
                        <Col span={24}>
                            {geti18nText('employee.evaluation.edit.button.sendToEvaluation.count') + ': ' + countDraft}
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};

export default SendEvaluationModal;
