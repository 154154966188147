import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../../../components/report-button';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat } from '../../../../utils/Utils';
import ItemGroupIndex from '../../../administration/views/item-group';
import WarehouseIndex from '../warehouse';
import useEnum from '../../../../hooks/useEnum';
import { NySession } from '@nybble/nyreact';
import WarehouseEdit from '../warehouse/edit';
import { InventoryRights } from '../../../../rights/inventoryRights';
import ItemGroupEdit from '../../../administration/views/item-group/edit';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';
import ItemGroupSearch from '../../../administration/views/item-group/search';

const WarehouseItemReportModal = (props: any) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('month'));
    const [dateTo, setDateTo] = useState<any>(moment());
    const [warehouseId, setWarehouseId] = useState<any>(null);
    const [itemGroup, setItemGroupId] = useState<any>(null);

    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values.dateFrom));
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText('warehouseItem.report.warehouseItemRecapitulation')}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('warehouseItem.report.warehouseItemRecapitulation')}
                    maskClosable={false}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        setWarehouseId(null);
                        setItemGroupId(null);
                        setDateFrom(moment().startOf('month'));
                        setDateTo(moment());
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    footer={[
                        <NyReportButton
                            url={CONSTANTS_REQ.REPORT.WAREHOUSE_ITEM_RECAPITULATION}
                            customParms={{
                                dateFrom: getDateFormat(dateFrom),
                                dateTo: getDateFormat(dateTo),
                                warehouseId: warehouseId,
                                itemGroupId: itemGroup,
                            }}
                            subreportType={'WAREHOUSE_ITEM_RECAPITULATION'}
                            buttoni18nText={'warehouseItem.report.warehouseItemRecapitulation'}
                            fileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.WAREHOUSE_ITEM_RECAPITULATION
                            )}
                            checkBeforeSave={checkBeforeSave}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseItem.report.dateFrom')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().startOf('month')}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateFrom(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseItem.report.dateTo')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment()}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateTo(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('warehouseItem.report.warehouse')} name="warehouse">
                                    <NySearchField
                                        url={CONSTANTS_REQ.WAREHOUSE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        //  addNewItem={true}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        SearchPopupComponent={<WarehouseIndex />}
                                        onChange={(value: any) => {
                                            setWarehouseId(value && value.id > 0 ? value.id : null);
                                        }}
                                        AddNewModalComponent={
                                            WarehouseBookkeepingRights.canCreateWarehouseCodebooks()
                                                ? WarehouseEdit
                                                : null
                                        }
                                        customListWidth={'1200px'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <ItemGroupSearch
                                    label={geti18nText('warehouseItem.report.itemGroup')}
                                    name="parent"
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    AddNewModalComponent={InventoryRights.canCreateCodebooks()}
                                    AddNewModalComponentAddedData={{ type: 0 }}
                                    onChange={(value: any) => {
                                        setItemGroupId(value && value.id > 0 ? value.id : null);
                                    }}
                                    addedData={{ type: 0 }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default WarehouseItemReportModal;
