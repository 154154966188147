import { geti18nText, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { wait } from '@testing-library/react';
import { Col, Divider, Form, Modal, Radio, RadioChangeEvent, Row, Select, Space, Image } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { addWidget } from '../../slices/dashboardSlice';
import { CONSTANTS_REQ, KIOSK } from '../../utils/Constants';
import { warningNotification } from '../../utils/Utils';
//==================
import AssetOrderLightWidgetDark from './images/AssetOrderLightWidgetDark.PNG';
import AssetOrderWidgetDark from './images/AssetOrderWidgetDark.PNG';
import AssetOrderExtraLightWidgetDark from './images/AssetOrderExtraLightWidgetDark.PNG';
import InventoryOrderLightWidgetDark from './images/InventoryOrderLightWidgetDark.PNG';
import InventoryOrderWidgetDark from './images/InventoryOrderWidgetDark.PNG';
import InventoryOrderExtraLightWidgetDark from './images/InventoryOrderExtraLightWidgetDark.PNG';
import ServicesOrderLightWidgetDark from './images/ServicesOrderLightWidgetDark.PNG';
import ServicesOrderWidgetDark from './images/ServicesOrderWidgetDark.PNG';
import ServicesOrderExtraLightWidgetDark from './images/ServicesOrderExtraLightWidgetDark.PNG';
import TravelWarrantPersonalLightWidgetDark from './images/TravelWarrantPersonalLightWidgetDark.PNG';
import TravelWarrantPersonalWidgetDark from './images/TravelWarrantPersonalWidgetDark.PNG';
import TravelWarrantPersonalExtraLightWidgetDark from './images/TravelWarrantPersonalExtraLightWidgetDark.PNG';
import TravelWarrantLightWidgetDark from './images/TravelWarrantLightWidgetDark.PNG';
import TravelWarrantWidgetDark from './images/TravelWarrantWidgetDark.PNG';
import TravelWarrantExtraLightWidgetDark from './images/TravelWarrantExtraLightWidgetDark.PNG';
import EmployeeLeavePersonalWidgetDark from './images/EmployeeLeavePersonalWidgetDark.PNG';
import EmployeeLeavePersonalLightWidgetDark from './images/EmployeeLeavePersonalLightWidgetDark.PNG';
import EmployeeLeavePersonalExtraLightWidgetDark from './images/EmployeeLeavePersonalWidgetExtraLightWidgetDark.PNG';
import PersonalCalendarWidgetDark from './images/PersonalCalendarWidgetDark.PNG';
import DirectoryWidgetDark from './images/DirectoryWidgetDark.PNG';
import LinksWidgetDark from './images/LinksWidgetDark.PNG';
import NotificationWidgetDark from './images/NotificationWidgetDark.PNG';
import MealsMenuWidgetDark from './images/MealsMenuWidgetDark.PNG';
import KioskWidgetDark from './images/KioskWidgetDark.PNG';
// =======================
import AssetLightWidgetLight from './images/AssetLightWidgetLight.PNG';
import AssetOrderWidgetLight from './images/AssetOrderWidgetLight.PNG';
import AssetOrderExtraLightWidgetLight from './images/AssetOrderExtraLightWidgetLight.PNG';
import InventoryOrderLightWidgetLight from './images/InventoryOrderLightWidgetLight.PNG';
import InventoryOrderWidgetLight from './images/InventoryOrderWidgetLight.PNG';
import InventoryOrderExtraLightWidgetLight from './images/InventoryOrderExtraLightWidgetLight.PNG';
import ServicesOrderLightWidgetLight from './images/ServicesOrderLightWidgetLight.PNG';
import ServicesOrderWidgetLight from './images/ServicesOrderWidgetLight.PNG';
import ServicesOrderExtraLightWidgetLight from './images/ServicesOrderExtraLightWidgetLight.PNG';
import TravelWarrantPersonalLightWidgetLight from './images/TravelWarrantPersonalLightWidgetLight.PNG';
import TravelWarrantPersonalWidgetLight from './images/TravelWarrantPesonalWidgetLight.PNG';
import TravelWarrantPersonalExtraLightWidgetLight from './images/TravelWarrantPersonalExtraLightWidgetLight.PNG';
import TravelWarrantLightWidgetLight from './images/TravelWarrantLightWidgetLight.PNG';
import TravelWarrantWidgetLight from './images/TravelWarrantWidgetLight.PNG';
import TravelWarrantExtraLightWidgetLight from './images/TravelWarrantExtraLightWidgetLight.PNG';
import EmployeeLeavePersonalWidgetLight from './images/EmployeeLeavePersonalWidgetLight.PNG';
import EmployeeLeavePersonalLightWidgetLight from './images/EmployeeLeavePersonalLightWidgetLight.PNG';
import EmployeeLeavePersonalExtraLightWidgetLight from './images/EmployeeLeavePersonalExtraLightWidgetLight.PNG';
import PersonalCalendarWidgetLight from './images/PersonalCalendarWidgetLight.PNG';
import DirectoryWidgetLight from './images/DirectoryWidgetLight.PNG';
import LinksWidgetLight from './images/LinksWidgetLight.PNG';
import NotificationWidgetLight from './images/NotificationWidgetLight.PNG';
import MealsMenuWidgetLight from './images/MealsMenuWidgetLightLight.PNG';
import KioskWidgetLight from './images/KioskWidgetDark.PNG';
import PersonalNotesWidgetDark from './images/PersonalNotesWidgetDark.PNG';
import PersonalNotesWidgetLight from './images/PersonalNotesWidgetLight.PNG';

const WidgetModal = ({ modalVisible, setModalVisible, dispatch }: any) => {
    const { allWidgets } = useSelector((state: RootState) => state.dashboard);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;
    const [form] = Form.useForm();
    const [selectedWidget, setSelectedWidget] = useState<any>(null);
    const [selectedWidgetAsset, setSelectedWidgetAsset] = useState<any>(null);
    const [selectedWidgetMaterial, setSelectedWidgetMaterial] = useState<any>(null);
    const [selectedWidgetServices, setSelectedWidgetServices] = useState<any>(null);
    const [selectedWidgetTravelPersonal, setSelectedWidgetTravelPersonal] = useState<any>(null);
    const [selectedWidgetTravel, setSelectedWidgetTravel] = useState<any>(null);
    const [selectedWidgetEmployeeLeavePersonal, setSelectedWidgetEmployeeLeavePersonal] = useState<any>(null);
    const [selectedDevice, setSelectedDevice] = useState(undefined);
    const [accessKey, setAccessKey] = useState<string>('');
    const [dsmApi, setDsmApi] = useState<string>('');

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>(undefined);

    useEffect(() => {
        if (modalVisible) {
            getKioskSettings();
        }
    }, [modalVisible]);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 6 },
            { field: 'settingGroup', condition: 'equals', value: 'DSM_SETTINGS' },
        ];
    };

    const getKioskSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DSM_API') {
                                setDsmApi(element.value ? element.value : element.defaultValue);
                            } else if (element.settingKey === 'DSM_API_ACCESS_KEY') {
                                setAccessKey(element.value ? element.value : element.defaultValue);
                            }
                        });
                    }
                }
            }
        );
    };

    const canAddWidget = (key: any) => {
        if (allWidgets) {
            let widget = allWidgets.find((item: any) => item.key == key.key);
            if (widget && ((widget.role && NySession.hasAnyRole(widget.role)) || !widget.role)) {
                return true;
            }
        }
        return false;
    };

    const onDeviceChange = (value: any) => {
        setSelectedDevice(value);
    };

    const save = () => {
        if (selectedWidget) {
            if (selectedWidget == 'KioskWidget') {
                if (selectedDevice) {
                    closeModal();
                    dispatch(addWidget({ key: selectedWidget, data: { device: selectedDevice } }));
                    setSelectedDevice(undefined);
                } else {
                    warningNotification(
                        geti18nText('widgets.select.device'),
                        geti18nText('widgets.select.device.content')
                    );
                }
            } else {
                closeModal();
                dispatch(addWidget({ key: selectedWidget }));
            }
        }
    };

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false);
        setSelectedWidget(null);
    };

    const setWidget = (value: any, field: any) => {
        form.resetFields();
        setSelectedWidget(value);
        form.setFields([{ name: field, value: value }]);
    };

    const handleChangeGeneral = ({ target: { value } }: RadioChangeEvent) => {
        setWidget(value, 'general');
    };
    const handleChangeAsset = (value: string) => {
        setWidget(value, 'asset');
        setSelectedWidgetAsset(value);
    };
    const handleChangeMaterial = (value: string) => {
        setWidget(value, 'material');
        setSelectedWidgetMaterial(value);
    };
    const handleChangeServices = (value: string) => {
        setWidget(value, 'services');
        setSelectedWidgetServices(value);
    };
    const handleChangeTravelPersonal = (value: string) => {
        setWidget(value, 'travelPersonal');
        setSelectedWidgetTravelPersonal(value);
    };
    const handleChangeTravel = (value: string) => {
        setWidget(value, 'travel');
        setSelectedWidgetTravel(value);
    };
    const handleChangeEmployeeLeavePersonal = (value: string) => {
        setWidget(value, 'employeeLeavePersonal');
        setSelectedWidgetEmployeeLeavePersonal(value);
    };

    const returnImage = (imageName?: any) => {
        switch (imageName) {
            case 'AssetLightWidgetLight':
                return AssetLightWidgetLight;
            case 'AssetLightWidgetDark':
                return AssetOrderLightWidgetDark;
            case 'AssetOrderWidgetDark':
                return AssetOrderWidgetDark;
            case 'AssetOrderExtraLightWidgetDark':
                return AssetOrderExtraLightWidgetDark;
            case 'InventoryOrderWidgetDark':
                return InventoryOrderWidgetDark;
            case 'InventoryOrderLightWidgetDark':
                return InventoryOrderLightWidgetDark;
            case 'InventoryOrderExtraLightWidgetDark':
                return InventoryOrderExtraLightWidgetDark;
            case 'ServicesOrderWidgetDark':
                return ServicesOrderWidgetDark;
            case 'ServicesOrderLightWidgetDark':
                return ServicesOrderLightWidgetDark;
            case 'ServicesOrderExtraLightWidgetDark':
                return ServicesOrderExtraLightWidgetDark;
            case 'TravelWarrantPersonalWidgetDark':
                return TravelWarrantPersonalWidgetDark;
            case 'TravelWarrantPersonalLightWidgetDark':
                return TravelWarrantPersonalLightWidgetDark;
            case 'TravelWarrantPersonalExtraLightWidgetDark':
                return TravelWarrantExtraLightWidgetDark;
            case 'TravelWarrantWidgetDark':
                return TravelWarrantWidgetDark;
            case 'TravelWarrantLightWidgetDark':
                return TravelWarrantLightWidgetDark;
            case 'TravelWarrantExtraLightWidgetDark':
                return TravelWarrantExtraLightWidgetDark;
            case 'EmployeeLeavePersonalWidgetDark':
                return EmployeeLeavePersonalWidgetDark;
            case 'EmployeeLeavePersonalLightWidgetDark':
                return EmployeeLeavePersonalLightWidgetDark;
            case 'EmployeeLeavePersonalExtraLightWidgetDark':
                return EmployeeLeavePersonalExtraLightWidgetDark;
            case 'PersonalCalendarWidgetDark':
                return PersonalCalendarWidgetDark;
            case 'DirectoryWidgetDark':
                return DirectoryWidgetDark;
            case 'LinksWidgetDark':
                return LinksWidgetDark;
            case 'NotificationWidgetDark':
                return NotificationWidgetDark;
            case 'MealsMenuWidgetDark':
                return MealsMenuWidgetDark;
            case 'KioskWidgetDark':
                return KioskWidgetDark;
            case 'PersonalNotesWidgetDark':
                return PersonalNotesWidgetDark;

            //light
            case 'AssetLightWidgetLight':
                return AssetLightWidgetLight;
            /*  case 'AssetLightWidgetLight':
                return AssetOrderLightWidgetLight; */
            case 'AssetOrderWidgetLight':
                return AssetOrderWidgetLight;
            case 'AssetOrderExtraLightWidgetLight':
                return AssetOrderExtraLightWidgetLight;
            case 'InventoryOrderWidgetLight':
                return InventoryOrderWidgetLight;
            case 'InventoryOrderLightWidgetLight':
                return InventoryOrderLightWidgetLight;
            case 'InventoryOrderExtraLightWidgetLight':
                return InventoryOrderExtraLightWidgetLight;
            case 'ServicesOrderWidgetLight':
                return ServicesOrderWidgetLight;
            case 'ServicesOrderLightWidgetLight':
                return ServicesOrderLightWidgetLight;
            case 'ServicesOrderExtraLightWidgetLight':
                return ServicesOrderExtraLightWidgetLight;
            case 'TravelWarrantPersonalWidgetLight':
                return TravelWarrantPersonalWidgetLight;
            case 'TravelWarrantPersonalLightWidgetLight':
                return TravelWarrantPersonalLightWidgetLight;
            case 'TravelWarrantPersonalExtraLightWidgetLight':
                return TravelWarrantExtraLightWidgetLight;
            case 'TravelWarrantWidgetLight':
                return TravelWarrantWidgetLight;
            case 'TravelWarrantLightWidgetLight':
                return TravelWarrantLightWidgetLight;
            case 'TravelWarrantExtraLightWidgetLight':
                return TravelWarrantExtraLightWidgetLight;
            case 'EmployeeLeavePersonalWidgetLight':
                return EmployeeLeavePersonalWidgetLight;
            case 'EmployeeLeavePersonalLightWidgetLight':
                return EmployeeLeavePersonalLightWidgetLight;
            case 'EmployeeLeavePersonalExtraLightWidgetLight':
                return EmployeeLeavePersonalExtraLightWidgetLight;
            case 'PersonalCalendarWidgetLight':
                return PersonalCalendarWidgetLight;
            case 'DirectoryWidgetLight':
                return DirectoryWidgetLight;
            case 'LinksWidgetLight':
                return LinksWidgetLight;
            case 'NotificationWidgetLight':
                return NotificationWidgetLight;
            case 'MealsMenuWidgetLight':
                return MealsMenuWidgetLight;
            case 'KioskWidgetLight':
                return KioskWidgetLight;
            case 'PersonalNotesWidgetLight':
                return PersonalNotesWidgetLight;
        }
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    className={selectedWidget == null ? 'default-widget-modal-width' : 'extended-widget-modal-width'}
                    visible={modalVisible}
                    title={geti18nText('dashboard.button.add')}
                    okText={geti18nText('dashboard.button.add')}
                    onOk={save}
                    maskClosable={false}
                    onCancel={closeModal}
                >
                    <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={form}>
                        <div style={{ display: 'inline-flex' }}>
                            <Col
                                className={
                                    selectedWidget == null ? 'default-widget-col-width' : 'extended-widget-col-width'
                                }
                            >
                                <Row gutter={24}>
                                    <Divider orientation="left" plain>
                                        {' '}
                                        {geti18nText('widgets.general')}{' '}
                                    </Divider>
                                    <Col span={24}>
                                        <Space>
                                            <Form.Item name="general">
                                                <Radio.Group
                                                    name="widgets"
                                                    value={selectedWidget}
                                                    onChange={handleChangeGeneral}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Radio
                                                        value={'PersonalCalendarWidget'}
                                                        disabled={!canAddWidget({ key: 'PersonalCalendarWidget' })}
                                                    >
                                                        {geti18nText('widgets.calendar')}
                                                    </Radio>
                                                    <Radio
                                                        value={'DirectoryWidget'}
                                                        disabled={!canAddWidget({ key: 'DirectoryWidget' })}
                                                    >
                                                        {geti18nText('menu.directory')}
                                                    </Radio>
                                                    <Radio
                                                        value={'LinksWidget'}
                                                        disabled={!canAddWidget({ key: 'LinksWidget' })}
                                                    >
                                                        {geti18nText('menu.links')}
                                                    </Radio>
                                                    <Radio
                                                        value={'NotificationWidget'}
                                                        disabled={!canAddWidget({ key: 'NotificationWidget' })}
                                                    >
                                                        {geti18nText('menu.notifications')}
                                                    </Radio>
                                                    <Radio
                                                        value={'MealsMenuWidget'}
                                                        disabled={!canAddWidget({ key: 'MealsMenuWidget' })}
                                                    >
                                                        {geti18nText('meals.menu.calendar')}
                                                    </Radio>
                                                    <Radio
                                                        value={'PersonalNotesWidget'}
                                                        disabled={!canAddWidget({ key: 'PersonalNotesWidget' })}
                                                    >
                                                        {geti18nText('menu.personal.notes')}
                                                    </Radio>
                                                    {dsmApi != undefined &&
                                                        dsmApi != '' &&
                                                        accessKey != undefined &&
                                                        accessKey != '' && (
                                                            <Radio
                                                                value={'KioskWidget'} //
                                                                disabled={!canAddWidget({ key: 'KioskWidget' })}
                                                            >
                                                                {geti18nText('widgets.name.kiosk')}
                                                            </Radio>
                                                        )}
                                                </Radio.Group>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                    {selectedWidget == 'KioskWidget' && (
                                        <Col offset={14} span={9}>
                                            <Form.Item
                                                name="device"
                                                label={geti18nText('menu.device')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    map={{ id: 'id', label: 'name' }}
                                                    searchBy="name"
                                                    url={KIOSK(dsmApi).DEVICE_LIST}
                                                    onChange={onDeviceChange}
                                                    addedData={{ accessKey: accessKey }}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={24}>
                                    <Divider orientation="left" plain>
                                        {geti18nText('widgets.advanced')}
                                    </Divider>
                                    <Col span={8}>
                                        <Form.Item label={geti18nText('menu.asset')} name="asset">
                                            <Select
                                                value={selectedWidgetAsset}
                                                disabled={
                                                    !canAddWidget({ key: 'AssetOrderWidget' }) &&
                                                    !canAddWidget({ key: 'AssetLightWidget' }) &&
                                                    !canAddWidget({ key: 'AssetOrderExtraLightWidget' })
                                                }
                                                onChange={handleChangeAsset}
                                                options={[
                                                    {
                                                        value: 'AssetOrderWidget',
                                                        label: geti18nText('widgets.general'),
                                                        disabled: !canAddWidget({ key: 'AssetOrderWidget' }),
                                                    },
                                                    {
                                                        value: 'AssetLightWidget',
                                                        label: geti18nText('widgets.light'),
                                                        disabled: !canAddWidget({ key: 'AssetLightWidget' }),
                                                    },
                                                    {
                                                        value: 'AssetOrderExtraLightWidget',
                                                        label: geti18nText('widgets.extraLight'),
                                                        disabled: !canAddWidget({ key: 'AssetOrderExtraLightWidget' }),
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={geti18nText('menu.material')} name="material">
                                            <Select
                                                value={selectedWidgetMaterial}
                                                disabled={
                                                    !canAddWidget({ key: 'InventoryOrderWidget' }) &&
                                                    !canAddWidget({ key: 'InventoryOrderLightWidget' }) &&
                                                    !canAddWidget({ key: 'InventoryOrderExtraLightWidget' })
                                                }
                                                onChange={handleChangeMaterial}
                                                options={[
                                                    {
                                                        value: 'InventoryOrderWidget',
                                                        label: geti18nText('widgets.general'),
                                                        disabled: !canAddWidget({ key: 'InventoryOrderWidget' }),
                                                    },
                                                    {
                                                        value: 'InventoryOrderLightWidget',
                                                        label: geti18nText('widgets.light'),
                                                        disabled: !canAddWidget({ key: 'InventoryOrderLightWidget' }),
                                                    },
                                                    {
                                                        value: 'InventoryOrderExtraLightWidget',
                                                        label: geti18nText('widgets.extraLight'),
                                                        disabled: !canAddWidget({
                                                            key: 'InventoryOrderExtraLightWidget',
                                                        }),
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={geti18nText('menu.services')} name="services">
                                            <Select
                                                value={selectedWidgetServices}
                                                disabled={
                                                    !canAddWidget({ key: 'ServicesOrderWidget' }) &&
                                                    !canAddWidget({ key: 'ServicesOrderLightWidget' }) &&
                                                    !canAddWidget({ key: 'ServicesOrderExtraLightWidget' })
                                                }
                                                onChange={handleChangeServices}
                                                options={[
                                                    {
                                                        value: 'ServicesOrderWidget',
                                                        label: geti18nText('widgets.general'),
                                                        disabled: !canAddWidget({ key: 'ServicesOrderWidget' }),
                                                    },
                                                    {
                                                        value: 'ServicesOrderLightWidget',
                                                        label: geti18nText('widgets.light'),
                                                        disabled: !canAddWidget({ key: 'ServicesOrderLightWidget' }),
                                                    },
                                                    {
                                                        value: 'ServicesOrderExtraLightWidget',
                                                        label: geti18nText('widgets.extraLight'),
                                                        disabled: !canAddWidget({
                                                            key: 'ServicesOrderExtraLightWidget',
                                                        }),
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item
                                            label={geti18nText('menu.travelWarrant.personal')}
                                            name="travelPersonal"
                                        >
                                            <Select
                                                value={selectedWidgetTravelPersonal}
                                                disabled={
                                                    !canAddWidget({ key: 'TravelWarrantPersonalWidget' }) &&
                                                    !canAddWidget({ key: 'TravelWarrantPersonalLightWidget' }) &&
                                                    !canAddWidget({ key: 'TravelWarrantPersonalExtraLightWidget' })
                                                }
                                                onChange={handleChangeTravelPersonal}
                                                options={[
                                                    {
                                                        value: 'TravelWarrantPersonalWidget',
                                                        label: geti18nText('widgets.general'),
                                                        disabled: !canAddWidget({ key: 'TravelWarrantPersonalWidget' }),
                                                    },
                                                    {
                                                        value: 'TravelWarrantPersonalLightWidget',
                                                        label: geti18nText('widgets.light'),
                                                        disabled: !canAddWidget({
                                                            key: 'TravelWarrantPersonalLightWidget',
                                                        }),
                                                    },
                                                    {
                                                        value: 'TravelWarrantPersonalExtraLightWidget',
                                                        label: geti18nText('widgets.extraLight'),
                                                        disabled: !canAddWidget({
                                                            key: 'TravelWarrantPersonalExtraLightWidget',
                                                        }),
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={geti18nText('menu.travelWarrant')} name="travel">
                                            <Select
                                                value={selectedWidgetTravel}
                                                disabled={
                                                    !canAddWidget({ key: 'TravelWarrantWidget' }) &&
                                                    !canAddWidget({ key: 'TravelWarrantLightWidget' }) &&
                                                    !canAddWidget({ key: 'TravelWarrantExtraLightWidget' })
                                                }
                                                onChange={handleChangeTravel}
                                                options={[
                                                    {
                                                        value: 'TravelWarrantWidget',
                                                        label: geti18nText('widgets.general'),
                                                        disabled: !canAddWidget({ key: 'TravelWarrantWidget' }),
                                                    },
                                                    {
                                                        value: 'TravelWarrantLightWidget',
                                                        label: geti18nText('widgets.light'),
                                                        disabled: !canAddWidget({ key: 'TravelWarrantLightWidget' }),
                                                    },
                                                    {
                                                        value: 'TravelWarrantExtraLightWidget',
                                                        label: geti18nText('widgets.extraLight'),
                                                        disabled: !canAddWidget({
                                                            key: 'TravelWarrantExtraLightWidget',
                                                        }),
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label={geti18nText('employee.leave.personal')}
                                            name="employeeLeavePersonal"
                                        >
                                            <Select
                                                value={selectedWidgetEmployeeLeavePersonal}
                                                disabled={
                                                    !canAddWidget({ key: 'EmployeeLeavePersonalWidget' }) &&
                                                    !canAddWidget({ key: 'EmployeeLeavePersonalLightWidget' }) &&
                                                    !canAddWidget({ key: 'EmployeeLeavePersonalExtraLightWidget' })
                                                }
                                                onChange={handleChangeEmployeeLeavePersonal}
                                                options={[
                                                    {
                                                        value: 'EmployeeLeavePersonalWidget',
                                                        label: geti18nText('widgets.general'),
                                                        disabled: !canAddWidget({ key: 'EmployeeLeavePersonalWidget' }),
                                                    },
                                                    {
                                                        value: 'EmployeeLeavePersonalLightWidget',
                                                        label: geti18nText('widgets.light'),
                                                        disabled: !canAddWidget({
                                                            key: 'EmployeeLeavePersonalLightWidget',
                                                        }),
                                                    },
                                                    {
                                                        value: 'EmployeeLeavePersonalExtraLightWidget',
                                                        label: geti18nText('widgets.extraLight'),
                                                        disabled: !canAddWidget({
                                                            key: 'EmployeeLeavePersonalExtraLightWidget',
                                                        }),
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            {selectedWidget !== null && (
                                <Col span={6} style={{ marginLeft: '4rem', marginTop: '1rem' }}>
                                    <div>
                                        <Image
                                            width={217}
                                            src={returnImage(selectedWidget.concat(isDarkTheme ? 'Dark' : 'Light'))}
                                        />
                                    </div>
                                </Col>
                            )}
                        </div>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default WidgetModal;
