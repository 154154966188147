import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AssetSimpleIndex from '../../../../../../components/asset-simple/assetSimple';
import NyErrorLogModal from '../../../../../../components/error-log-modal';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import {
    customAssetExtraRenderOption,
    errorNodeNotification,
    errorNotification,
    getSearchFormat,
    okNotification,
    setDateFormat,
} from '../../../../../../utils/Utils';
import { validateNegativeNumber } from '../../../../../../utils/Validation';
import AssetEdit from '../../../../../webshop/views/webshop-asset/asset/edit';

const AssetDepreciationItemsEdit = (props: any) => {
    const [form] = Form.useForm();
    const [editHeader, setEditHeader] = useState(geti18nText('asset.acquisition.item.table.header'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [assetDepreciationId, setAssetDepreciationId] = useState(null);
    const [dataForm, setDataForm] = useState(null);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);

    const [errorLogModalData, setErrorLogModalData] = useState([]);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');

    const onRepeatActions = [
        // { value: 1, label: geti18nText('asset.acquisition.delete'), action:'delete'},
        { value: 3, label: geti18nText('asset.acquisition.recalculate'), action: 'recalculate' },
        { value: 2, label: geti18nText('asset.acquisition.skip'), action: 'skip' },
    ];
    const [repeatAction, setRepeatAction] = useState('recalculate');

    useEffect(() => {
        setAssetDepreciationId(props?.editProps?.assetDepreciationId ?? null);
    }, [props]);

    const notBooked = () => {
        return !props?.editProps?.booked && !props?.editProps?.bookedGeneralLedger;
    };

    const canCreate = () => {
        return props?.editProps?.canCreate && props?.editProps?.assetDepreciationId && notBooked();
    };

    const canEdit = () => {
        return props?.editProps?.canCreate;
    };

    const canSave = () => {
        return ((canEdit() && dataForm) || (canCreate() && !dataForm)) && notBooked();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.asset) {
            if (dataForm?.asset?.assetModel?.name) {
                setEditHeader(dataForm.asset.assetModel.name);
            }
            if (dataForm.asset.depreciationStartDate) {
                dataForm.asset.depreciationStartDate = setDateFormat(dataForm.asset.depreciationStartDate);
            }
            if (dataForm.asset.depreciationEndDate) {
                dataForm.asset.depreciationEndDate = setDateFormat(dataForm.asset.depreciationEndDate);
            }
            dataForm.asset.label = dataForm?.asset?.assetModel?.name;
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        props.setVisible(false);
        setTriggerCloseModal(true);
        form.resetFields();
        setDataForm(null);
        setAssetDepreciationId(null);
        setEditHeader(geti18nText('asset.acquisition.item.table.header'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const changeAmount = () => {
        const base = form.getFieldValue(['base']) ? form.getFieldValue(['base']) : 0;
        const months = form.getFieldValue(['months']) ? form.getFieldValue(['months']) : 0;
        const rate = form.getFieldValue(['rate']) ? form.getFieldValue(['rate']) : 0;
        const amount = (((base * rate) / 100) * months) / 12;
        form.setFieldsValue({ amount: amount });
        changeNetBookValue();
    };

    const changeNetBookValue = () => {
        const acquisitionValue = form.getFieldValue(['acquisitionValue'])
            ? form.getFieldValue(['acquisitionValue'])
            : 0;
        const valueIncrease = form.getFieldValue(['valueIncrease']) ? form.getFieldValue(['valueIncrease']) : 0;
        const valueDecrease = form.getFieldValue(['valueDecrease']) ? form.getFieldValue(['valueDecrease']) : 0;
        const acumulatedDepreciation = form.getFieldValue(['acumulatedDepreciation'])
            ? form.getFieldValue(['acumulatedDepreciation'])
            : 0;
        const amount = form.getFieldValue(['amount']) ? form.getFieldValue(['amount']) : 0;

        const netBookValue = acquisitionValue + valueIncrease - valueDecrease - acumulatedDepreciation - amount;
        form.setFieldsValue({ netBookValue: netBookValue });
    };

    const save = async () => {
        try {
            const row = await form.validateFields();
            addItem(row);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const addItem = (row: any) => {
        if (row) {
            let instance: any = { id: row.id };
            instance.base = row.base;
            instance.months = row.months;
            instance.rate = row.rate;
            instance.amount = row.amount;
            instance.netBookValue = row.netBookValue;

            const assetId = row.asset && row.asset.id ? row.asset.id : null;
            let asset: any = { id: assetId };
            instance.asset = asset;

            NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_DEPRECIATION.ADD_ITEM, undefined, {
                assetDepreciationId: assetDepreciationId,
                onRepeatAction: dataForm ? 'skip' : repeatAction ?? geti18nText('asset.acquisition.recalculate'),
                instance: instance,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    onModalClose();
                    props?.editProps?.setRefreshTable(props?.editProps?.refreshTable + 1);
                } else {
                    if (result?.data?.error?.length > 0) {
                        let data = JSON.parse(result.data.error);
                        errorNodeNotification(
                            <Button
                                type="link"
                                onClick={() => {
                                    setErrorLogModalData(data);
                                    setErrorLogModalVisible(true);
                                    setErrorLogModalTitle(geti18nText('asset.depreciation.error.details.modal'));
                                }}
                            >
                                {geti18nText('asset.depreciation.error.details')}
                            </Button>
                        );
                    } else {
                        errorNotification();
                    }
                }
            });
        }
    };

    const getCustomFooterContent = (
        <>
            {canSave() && (
                <Button key="submit" type="primary" loading={loading} onClick={save}>
                    {geti18nText('app.default.button.save')}
                </Button>
            )}
        </>
    );

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.ASSET_DEPRECIATION_ITEM.EDIT}
                setValues={setValues}
                triggerCloseModal={triggerCloseModal}
                onSave={onModalClose}
                width={900}
                isModal={true}
                form={form}
                paramsId={id}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={true}
                normalize={(values: any) => {
                    values.assetDepreciation = { id: assetDepreciationId };
                    values.asset = getSearchFormat(values.asset);
                    return values;
                }}
                hideSubmitButton={true}
                hideActivationButtons={true}
                customFooterContent={getCustomFooterContent}
                onSaveAndGetID={() => {
                    props?.setRefreshTable && props.setRefreshTable((prevValue: any) => prevValue + 1);
                    props?.editProps?.setGetTotalsRefresh &&
                        props?.editProps?.setGetTotalsRefresh((prevValue: any) => prevValue + 1);
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="asset" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Col span={dataForm ? 24 : 12}>
                                <Form.Item
                                    label={geti18nText('asset.acquisition.item.table.column.asset')}
                                    name="asset"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        style={{ width: '100%' }}
                                        url={CONSTANTS_REQ.ASSET.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'label',
                                            text: 'text',
                                            name: 'name',
                                            serialNumber: 'serialNumber',
                                            inventoryNumber: 'inventoryNumber',
                                            assetModel: 'assetModel',
                                            assetType: 'assetType',
                                            item: 'item',
                                            measureUnit: 'measureUnit',
                                            quantity: 'quantity',
                                        }}
                                        searchBy="assetModel.name || serialNumber || inventoryNumber"
                                        renderOption={customAssetExtraRenderOption}
                                        SearchPopupComponent={<AssetSimpleIndex isFixedAsset={true} />}
                                        AddNewModalComponent={AssetEdit}
                                        AddNewModalComponentAddedData={{ isModal: true }}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        autoFocus
                                        order="assetModel.name"
                                        customListWidth={'1200px'}
                                        mustGetPopupContainer={false}
                                        disabled={!canCreate()}
                                    />
                                </Form.Item>
                            </Col>
                            {!dataForm && (
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('asset.acquisition.button.action')}
                                        name="onRepeatAction"
                                        initialValue={3}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={onRepeatActions}
                                            style={{ width: '100%' }}
                                            onChange={(value) => {
                                                setRepeatAction(
                                                    onRepeatActions
                                                        .filter((action: any) => action.value == value)
                                                        .map((action: any) => {
                                                            return action.action;
                                                        })[0]
                                                );
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        {dataForm && (
                            <>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.acquisitionValue')}
                                            name="acquisitionValue"
                                            initialValue={0}
                                        >
                                            <NyInputNumber disabled={true} style={{ width: '100%' }} isDecimal />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.valueIncrease')}
                                            name="valueIncrease"
                                            initialValue={0}
                                        >
                                            <NyInputNumber disabled={true} style={{ width: '100%' }} isDecimal />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.valueDecrease')}
                                            name="valueDecrease"
                                            initialValue={0}
                                        >
                                            <NyInputNumber disabled={true} style={{ width: '100%' }} isDecimal />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'asset.depreciationItem.table.column.accumulatedDepreciation'
                                            )}
                                            name="acumulatedDepreciation"
                                            initialValue={0}
                                        >
                                            <NyInputNumber disabled={true} style={{ width: '100%' }} isDecimal />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'asset.depreciationItem.table.column.depreciationStartDate'
                                            )}
                                            name={['asset', 'depreciationStartDate']}
                                        >
                                            <NyDatePicker style={{ width: '100%' }} disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText(
                                                'asset.depreciationItem.table.column.depreciationEndDate'
                                            )}
                                            name={['asset', 'depreciationEndDate']}
                                        >
                                            <NyDatePicker style={{ width: '100%' }} disabled={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.base')}
                                            name="base"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    validator: validateNegativeNumber,
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                disabled={!canCreate()}
                                                style={{ width: '100%' }}
                                                isDecimal
                                                onChange={changeAmount}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.months')}
                                            name="months"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    validator: validateNegativeNumber,
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                disabled={!canCreate()}
                                                style={{ width: '100%' }}
                                                onChange={changeAmount}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.rate')}
                                            name="rate"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    validator: validateNegativeNumber,
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                disabled={!canCreate()}
                                                style={{ width: '100%' }}
                                                isDecimal
                                                onChange={changeAmount}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.depreciation')}
                                            name="amount"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    validator: validateNegativeNumber,
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                disabled={!canCreate()}
                                                style={{ width: '100%' }}
                                                isDecimal
                                                onChange={changeNetBookValue}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.depreciationItem.table.column.netBookValue')}
                                            name="netBookValue"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    validator: validateNegativeNumber,
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                disabled={!canCreate()}
                                                style={{ width: '100%' }}
                                                isDecimal
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            </NyDataEdit>
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
        </>
    );
};

export default AssetDepreciationItemsEdit;
