import { Button, Menu, Result, Tooltip } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { NySession, NySpinner, geti18nText } from '@nybble/nyreact';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { userIsNotificationRecipient } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { addToMyMenu, removeFromMyMenu } from '../../slices/menuSlice';
import './index.scss';
import { addHistory } from '../../slices/historySlice';
import defaultMenu from '../layout/menu';

const MenuIndex = ({ moduleMenu, moduleRoutes }: any) => {
    const { employee } = useSelector((state: RootState) => state.employee);
    const { myMenu } = useSelector((state: RootState) => state.menu);
    const { theme } = useSelector((state: RootState) => state.generalSettings);

    const history = useHistory();
    const location: any = useLocation();
    const dispatch = useDispatch();

    const [routes, setRoutes] = useState<any>([]);
    const [menu, setMenu] = useState<any>([]);
    const [roles, setRoles] = useState<any>(NySession.getUser().roles);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);

    useEffect(() => {
        // setSelectedKeys(findKey(moduleMenu, 'menu.meals.dashboard', location.pathname));
        setRoutes(generateRoutes(moduleRoutes));
        setMenu(generateMenu(moduleMenu));
    }, [myMenu]);

    useEffect(() => {
        setSelectedKeys(findKey(moduleMenu, undefined, location.pathname));
        if (location.pathname.split('/').filter(Boolean).length > 1) {
            dispatch(addHistory({ path: location.pathname, i18n: geti18nFromPath(location.pathname) }));
        }
    }, [location.pathname]);

    function getCreatePathI18n(type: string) {
        switch (type) {
            // case 'services-order':
            // case 'asset':
            // case 'asset-order':
            // case 'inventory-order':
            case 'asset-acquisition':
                return 'asset.acquisition.edit.new';
            case 'asset-initial-state':
                return 'asset.initialState.edit.new';
            case 'asset-write-off':
                return 'asset.writeOff.edit.new';
            case 'asset-depreciation':
                return 'asset.depreciation.edit.new';
            case 'fixed-asset':
                return 'fixed.asset.edit.new';

            case 'employee':
                return 'employee.edit.new';
            case 'paymentCalculationIncome':
                return 'payment.calculation.edit.new.1';
            case 'paymentCalculationSecondIncome':
                return 'payment.calculation.edit.new.2';
            case 'paymentCalculationContract':
                return 'payment.calculation.edit.new.3';
            case 'paymentCalculationRoyalties':
                return 'payment.calculation.edit.new.4';
            case 'joppd':
                return 'joppd.edit.new';
            case 'employeeGroup':
                return 'employeeGroup.edit.new';

            case 'travelWarrant':
                return 'travelWarrant.edit.new';

            case 'company':
                return 'company.edit.new';
            // case 'order-view':

            // case 'warehouse-document':
            // case 'warehouse-item':
            case 'warehouse-receipt':
                return 'warehouseReceipt.edit.new';
            // case 'warehouse':
            default:
                return 'app.defalet.route.id.create';
        }
    }

    function geti18nFromPath(path: string): { key: string; hasComponent: boolean; args?: string[] }[] {
        if (path == '') {
            return [];
        }
        const parentPath = path.slice(0, path.lastIndexOf('/'));
        const type = parentPath.slice(parentPath.lastIndexOf('/') + 1);
        const base = path.slice(path.lastIndexOf('/') + 1);
        const i18nTemp: { key: string; hasComponent: boolean; args?: string[] } | undefined = findPath(path);
        const i18n: { key: string; hasComponent: boolean; args?: string[] } =
            i18nTemp && i18nTemp.key
                ? i18nTemp
                : findPath(parentPath + '/:id') && isNaN(Number(base))
                ? { key: getCreatePathI18n(type), hasComponent: true }
                : { key: 'app.default.route.id', hasComponent: true, args: [base] };
        return [...geti18nFromPath(parentPath), i18n];
    }

    function hasAnyRouteRole(roles: string[], userRoles?: any) {
        let res = false;
        roles.forEach((role: any) => {
            if (userRoles.includes(role)) {
                res = true;
                return res;
            }
        });
        return res;
    }

    function generateRoutes(routes: any) {
        let retValue = [];
        let route: any;

        const userRoles: any = NySession.getUser().roles;
        const isUserAuthenticated = NySession.isUserAuthenticated();
        for (route of routes) {
            if (
                (route.role !== undefined &&
                    isUserAuthenticated &&
                    userRoles &&
                    hasAnyRouteRole(route.role, userRoles)) ||
                userIsNotificationRecipient(employee, route.notificationType)
            ) {
                retValue.push(
                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                );
            }
        }

        return retValue;
    }

    function hasAnyRouteRoleMenu(menuRoles: string[]) {
        let res = false;
        if (menuRoles != undefined) {
            menuRoles.forEach((role: any) => {
                if (roles.includes(role)) {
                    res = true;
                    return res;
                }
            });
        }
        return res;
    }

    function isInMyMenu(key: any) {
        return myMenu.find((val: any) => val.key == key) == undefined;
    }

    function generateMenu(menus: any, addedType?: any, tooltipPlacement?: any) {
        let filteredMenus = menus;
        if (!mealSyncTypeHNB) {
            filteredMenus = menus.filter((item: any) => item.isForHnb == undefined);
        } else {
            filteredMenus = menus;
        }
        let retValue: any = [];
        let menu: any;

        for (menu of filteredMenus) {
            if (
                (hasAnyRouteRoleMenu(menu.role) || userIsNotificationRecipient(employee, menu.notificationType)) &&
                (!menu.isForEmployeesOnly || employee)
            ) {
                if (menu.submenu && addedType) {
                    menu.notificationType = addedType;
                }

                retValue.push({
                    label: (
                        <div
                            style={{
                                width: 'auto',
                                display: 'inline-block',
                                paddingRight: '20px',
                            }}
                        >
                            {geti18nText(menu.i18n)}
                            <div className={'menu-pin-container'}>
                                <div style={{ display: 'inline-block', float: 'right' }}>
                                    {!menu.submenu ? (
                                        <Tooltip
                                            placement={tooltipPlacement}
                                            title={geti18nText(
                                                isInMyMenu(menu.key) ? 'menu.pinned.add' : 'menu.pinned.remove'
                                            )}
                                            trigger={['hover', 'click']}
                                        >
                                            {(function (menu) {
                                                return isInMyMenu(menu.key) ? (
                                                    <PushpinOutlined
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            dispatch(
                                                                addToMyMenu({
                                                                    key: menu.key,
                                                                    path: menu.path,
                                                                    icon: menu.icon,
                                                                    i18n: geti18nFromPath(menu.path).map(
                                                                        (val: any) => val.key
                                                                    ),
                                                                    role: menu.role,
                                                                })
                                                            );
                                                        }}
                                                        style={{ background: 'transparent', padding: '10px' }}
                                                    />
                                                ) : (
                                                    <PushpinFilled
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const idx = myMenu.findIndex(
                                                                (val: any) => val.key == menu.key
                                                            );
                                                            dispatch(removeFromMyMenu(idx));
                                                        }}
                                                        style={{ background: 'transparent', padding: '10px' }}
                                                    />
                                                );
                                            })(menu)}
                                        </Tooltip>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    ),
                    key: menu.key,
                    icon: menu.icon,
                    children: menu.submenu ? generateMenu(menu.submenu, undefined, 'right') : undefined,
                });
            }
        }

        return retValue;
    }

    function findPath(path: string, menu?: any): { key: string; hasComponent: boolean } | undefined {
        let item;
        if (path.split('/').filter(Boolean).length == 1) {
            menu = defaultMenu;
        }
        for (item of menu ?? moduleMenu) {
            if (item.path == path) {
                const route = moduleRoutes.find((val: any) => val.path == path);
                return {
                    key: item.i18n,
                    hasComponent: Boolean(route?.component != undefined && NySession.hasAnyRole(route?.role)),
                };
            }
            if (item.submenu && path.split('/').filter(Boolean).length > 1) {
                let submenuMatch: any = findPath(path, item.submenu);
                if (submenuMatch) {
                    return submenuMatch;
                }
            }
        }
        if (!menu) {
            const match = moduleRoutes.find((val: any) => val.path == path);
            return match && { key: match.i18n ?? '', hasComponent: true };
        }
    }

    function findKey(menus: any, key: any, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch: any = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                history.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                return [menu.key];
            }
        }
        return undefined;
    }

    function menuSelected(selected: any) {
        // window.open(selected.path);
        if (selected.hasOwnProperty('keyPath')) {
            setSelectedKeys(findKey(moduleMenu, selected.key));
        } else {
            if (selected.key != undefined) {
                setSelectedKeys([selected.key]);
            }
        }
    }

    return (
        <>
            <Menu
                onClick={menuSelected}
                selectedKeys={selectedKeys}
                mode="horizontal"
                items={menu}
                theme={theme != undefined && theme === 'dark' ? 'dark' : 'light'}
                style={{
                    position: 'fixed',
                    zIndex: '1000',
                    width: 'calc(100vw - var(--sticky-width))',
                }}
                triggerSubMenuAction={'click'}
                className="horizontal-menu-tabs"
            />
            <div style={{ marginTop: '38px', padding: '10px' }}>
                <Suspense fallback={<NySpinner />}>
                    <Switch>
                        {routes}
                        <Route
                            path="*"
                            render={() => (
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle={geti18nText('app.default.404')}
                                    extra={<Button type="primary">{geti18nText('app.default.404.back')}</Button>}
                                />
                            )}
                        />
                    </Switch>
                </Suspense>
            </div>
        </>
    );
};

export default MenuIndex;
