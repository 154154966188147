import { geti18nText } from '@nybble/nyreact';
import { Descriptions, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { getDateFormat } from '../../utils/Utils';
import useEnum from '../../hooks/useEnum';

const { Text } = Typography;

const TravelWarrantAdditionalInfoViewEdit = ({ item, printPdf, travelWarrantOrdinal, isPlane = false }: any) => {
    const enVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const isItemPlane = (item: any) => {
        return (
            item?.travelVehicle?.vehicleType &&
            Object.values([enVehicleType.PLANE, enVehicleType.BOAT, enVehicleType.TRAIN]).includes(
                item.travelVehicle.vehicleType
            )
        );
    };

    return (
        <React.Fragment>
            <Descriptions column={1} bordered size="small" className={printPdf ? 'ant-typography-print-title' : ''}>
                <Descriptions.Item
                    className={printPdf ? 'ant-typography-print' : ''}
                    label={geti18nText('travelWarrantOrganization.table.column.businessUnit.ordinal')}
                >
                    <Text>{travelWarrantOrdinal}</Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={printPdf ? 'ant-typography-print-desc' : ''}
                    label={geti18nText('travelWarrantOrganization.table.column.address')}
                >
                    <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                        {item && item.eventAddress ? item.eventAddress : '-'}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={printPdf ? 'ant-typography-print-desc' : ''}
                    label={geti18nText('travelWarrantOrganization.table.column.accomodationNeeded')}
                >
                    <Text className={printPdf ? 'ant-typography-print-desc' : ''} style={{ whiteSpace: 'nowrap' }}>
                        {item && item?.needAccommodation?.id
                            ? geti18nText('app.enum.TRAVEL_NEED_ACCOMMODATION.' + item.needAccommodation.id)
                            : item && item.needAccommodation && !item.needAccommodation.id
                            ? geti18nText('app.enum.TRAVEL_NEED_ACCOMMODATION.' + item.needAccommodation)
                            : '-'}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={printPdf ? 'ant-typography-print-desc' : ''}
                    label={geti18nText('travelWarrantOrganization.table.column.accomodationOrganizator')}
                >
                    <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                        {item && item.accommodationRecommendation ? item.accommodationRecommendation : '-'}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={printPdf ? 'ant-typography-print-desc' : ''}
                    label={geti18nText('travelWarrantOrganization.table.column.transportNeeded')}
                >
                    <Text className={printPdf ? 'ant-typography-print-desc' : ''} style={{ whiteSpace: 'nowrap' }}>
                        {item && item?.needTransport?.id
                            ? geti18nText('app.enum.TRAVEL_NEED_TRANSPORT.' + item.needTransport.id)
                            : item?.needTransport && !item.needTransport.id
                            ? geti18nText('app.enum.TRAVEL_NEED_TRANSPORT.' + item.needTransport)
                            : '-'}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={printPdf ? 'ant-typography-print-desc' : ''}
                    label={geti18nText('travelWarrantPersonal.edit.travelVehicle')}
                >
                    <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                        {item?.travelVehicle?.name ? item.travelVehicle.name : '-'}
                    </Text>
                </Descriptions.Item>
                {(isItemPlane(item) || isPlane) && (
                    <Descriptions.Item
                        className={printPdf ? 'ant-typography-print-desc' : ''}
                        label={geti18nText('travelWarrantOrganization.table.column.luggage')}
                    >
                        <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                            {item && item?.baggageType?.id
                                ? geti18nText('app.enum.TRAVEL_BAGGAGE_TYPE.' + item.baggageType.id)
                                : item && item.baggageType && !item.baggageType.id
                                ? geti18nText('app.enum.TRAVEL_BAGGAGE_TYPE.' + item.baggageType)
                                : '-'}
                        </Text>
                    </Descriptions.Item>
                )}
                {item && (item.showTime == true || item.showTime == null || item.showTime == undefined) ? (
                    <>
                        <Descriptions.Item
                            className={printPdf ? 'ant-typography-print-desc' : ''}
                            label={geti18nText('travelWarrantOrganization.table.column.arrival')}
                        >
                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                {item && item.arrivalDate ? getDateFormat(item.arrivalDate, 'HH:mm') : '-'}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item
                            className={printPdf ? 'ant-typography-print-desc' : ''}
                            label={geti18nText('travelWarrantOrganization.table.column.return')}
                        >
                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                {item && item.returnDate ? getDateFormat(item.returnDate, 'HH:mm') : '-'}
                            </Text>
                        </Descriptions.Item>
                    </>
                ) : (
                    <>
                        <Descriptions.Item
                            className={printPdf ? 'ant-typography-print-desc' : ''}
                            label={geti18nText('travelWarrantOrganization.table.column.arrivalDayPart')}
                        >
                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                {item && item?.arrivalDayPart?.id
                                    ? geti18nText('app.enum.TRAVEL_DAY_PARTS.' + item.arrivalDayPart.id)
                                    : item && item.arrivalDayPart && !item.arrivalDayPart.id
                                    ? geti18nText('app.enum.TRAVEL_DAY_PARTS.' + item.arrivalDayPart)
                                    : '-'}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item
                            className={printPdf ? 'ant-typography-print-desc' : ''}
                            label={geti18nText('travelWarrantOrganization.table.column.returnDayPart')}
                        >
                            <Text className={printPdf ? 'ant-typography-print-desc' : ''}>
                                {item && item?.returnDayPart?.id
                                    ? geti18nText('app.enum.TRAVEL_DAY_PARTS.' + item.returnDayPart.id)
                                    : item && item.returnDayPart && !item.returnDayPart.id
                                    ? geti18nText('app.enum.TRAVEL_DAY_PARTS.' + item.returnDayPart)
                                    : '-'}
                            </Text>
                        </Descriptions.Item>
                    </>
                )}
            </Descriptions>
        </React.Fragment>
    );
};

export default TravelWarrantAdditionalInfoViewEdit;
