import { NyDataEdit, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HumanCodebooksSearch from '../../../../components/human-codebooks/search';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import SettlementIndex from '../../../administration/views/settlement';
import SettlementEdit from '../../../administration/views/settlement/edit';
import { setSearchFormat } from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EducationalInstitutionEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('educational.institution.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    useEffect(() => {
        if (props?.editProps) {
            setValues();
        }
    }, []);

    function setValues(dataForm?: any) {
        let filteredMergedData: any = dataForm ? dataForm : {};

        let addedValues = props?.addedData?.form?.getFieldsValue()
            ? props?.addedData?.form?.getFieldsValue()
            : props?.editProps?.form?.getFieldsValue();

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (addedValues?.educationLevel) {
            filteredMergedData.educationLevel = setSearchFormat(addedValues.educationLevel, 'name', 'name');
        } else if (props?.addedData?.educationLevel) {
            filteredMergedData.educationLevel = setSearchFormat(dataForm.educationLevel, 'name', 'name');
        }

        if (filteredMergedData.hasOwnProperty('name')) {
            setEditHeader(geti18nText('educational.institution.edit.title') + ' - ' + filteredMergedData.name);
        }

        delete filteredMergedData.active;
        form.setFieldsValue(filteredMergedData);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('educational.institution.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.EDUCATIONAL_INSTITUTION.EDIT}
            setValues={setValues}
            width={900}
            onBeforeSave={(e: any) => {
                props?.editProps?.setMainFormValues({ ...form.getFieldsValue() }, true);
            }}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('educational.institution.table.column.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('educational.institution.table.column.educationLevel')}
                        codebooksType={enCodebookType.EDUCATION_LEVEL}
                        formItemName={'educationLevel'}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('educational.institution.table.column.settlement')}
                        name={'settlement'}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.SETTLEMENT.SEARCH}
                            map={{ id: 'id', label: 'name', text: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<SettlementIndex />}
                            AddNewModalComponent={SettlementEdit}
                            nyTestId="settlement"
                            customListWidth={'1200px'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('educational.institution.table.column.university')}
                        codebooksType={enCodebookType.UNIVERSITY}
                        formItemName={'university'}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EducationalInstitutionEdit;
