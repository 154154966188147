import { NyRequestResolver, NySearchField, NySession, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Collapse, Divider, Form, Image, Input, Popover, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import useEnum from '../../../../../hooks/useEnum';
import { TasksRights } from '../../../../../rights/tasksRights';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../../../../utils/Constants';
import {
    customAssetRenderOption,
    customEmployeeRenderOption,
    getEnumArrayStatus,
    getErrorNotificationMessage,
    onKeyDownTextAreaCustom,
    setEnumFormat,
} from '../../../../../utils/Utils';
import TaskPrioritySearch from '../../task-priority/search';
import TeamSearch from '../../team/search';
import UserIndex from '../../user';
import MetadataIndex from './MetadataIndex';

const { Panel } = Collapse;
const { Text } = Typography;

const NewTaskFromTemplateIndex = ({
    objectMetadataForm,
    metadata,
    metadataTemplate,
    setValues,
    selectedTaskTemplateId,
    collapseActiveKeys,
    setCollapseActiveKeys,
    hasMetadata,
    setHasMetadata,
    showFiles,
    getFiles,
    setMetadataTemplate,
    form,
    selectedUser,
    taskStatusInit,
    selectedTaskId,
    setTaskStatus,
    setSelectedUser,
    taskStatus,
    actionsArray,
    parentTaskId,
    isTemplateView = false,
}: any) => {
    const taskStatusEnum = useEnum('TASK_STATUS');
    const taskTypeEnum = useEnum('TASK_TYPE');
    const enTaskAction = useEnum('TASK_ACTION');
    const [taskTemplateFileId, setTaskTemplateFileId] = useState<any>(null);
    const [taskTemplateDescription, setTaskTemplateDescription] = useState<any>('');
    const [forAssignedAsset, setForAssignedAsset] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (selectedTaskTemplateId && !parentTaskId && !selectedTaskId) {
            openTemplate(selectedTaskTemplateId);
        } else {
            setLoading(false);
        }
    }, [selectedTaskTemplateId]);

    const checkStatus = (status: any) => {
        return taskStatusInit && taskStatusInit == status;
    };

    const isAdmin = () => {
        return TasksRights.isTasksAdmin();
    };

    const isAgent = () => {
        return TasksRights.isTasksAgent();
    };

    const isUser = () => {
        return TasksRights.isTasksUser();
    };

    const canView = () => {
        return !isTemplateView && (isAdmin() || isAgent());
    };

    const isDisabledForm = () => {
        return !(isAdmin() || isAgent() || isUser()) || (selectedTaskId != null && isAgent());
    };

    const isDisabledForm2 = () => {
        return !(isAdmin() || isAgent());
    };

    const setDefaultFilterValueAsset = () => {
        if (selectedUser && selectedUser.id) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'availabilityStatus', condition: 'equals', value: 2 },
                {
                    field: 'users.id',
                    condition: 'equals',
                    value: selectedUser.id,
                },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'availabilityStatus', condition: 'equals', value: 2 },
            ];
        }
    };

    const fetchMetadataTemplate = (templateId: any) => {
        const url = CONSTANTS_REQ.TASK_TEMPLATE.METADATA;
        const urlWithId = url.replace('{taskTemplateId}', templateId);

        let filter = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        NyRequestResolver.requestGet(urlWithId, {
            max: 100,
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content) {
                setHasMetadata(true);
                setMetadataTemplate(result.data.content);
            }
        });
    };

    const openTemplate = (templateId: any) => {
        setLoading(true);
        fetchMetadataTemplate(templateId);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TEMPLATE.EDIT + '/' + templateId, undefined)
            .then(async (result: any) => {
                if (result && result.status === RESPONSE.OK && result.data) {
                    setTaskTemplateFileId(result.data.fileId);
                    setTaskTemplateDescription(result.data.description);
                    setForAssignedAsset(result.data?.forAssignedAsset || result.data?.assignedAsset);
                    await setValues(result.data);
                    form.setFieldsValue({
                        taskTemplate: result.data,
                    });
                } else {
                    getErrorNotificationMessage(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTemplateFormViewTemplateInfo = () => {
        return (
            <>
                {(taskTemplateFileId || taskTemplateDescription) && (
                    <Row gutter={24}>
                        <Col span={6}>
                            <>
                                {taskTemplateFileId ? (
                                    <Popover
                                        title={''}
                                        placement="left"
                                        content={
                                            <Image
                                                style={{
                                                    objectFit: 'cover',
                                                    border: '1px solid #f0f0f0',
                                                    height: '264px',
                                                }}
                                                preview={false}
                                                src={
                                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                                    '/' +
                                                    taskTemplateFileId +
                                                    '?tenant=' +
                                                    NySession.getUser().tenantId
                                                }
                                            />
                                        }
                                    >
                                        <Image
                                            style={{
                                                objectFit: 'cover',
                                                border: '1px solid #f0f0f0',
                                                height: '128px',
                                                width: '128px',
                                            }}
                                            preview={false}
                                            src={
                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                '/' +
                                                taskTemplateFileId +
                                                '?tenant=' +
                                                NySession.getUser().tenantId
                                            }
                                        />
                                    </Popover>
                                ) : (
                                    <Image
                                        style={{
                                            objectFit: 'cover',
                                            border: '1px solid #f0f0f0',
                                            height: '128px',
                                            width: '128px',
                                        }}
                                        src="error"
                                        preview={false}
                                        fallback={FILE_FALLBACK}
                                    />
                                )}
                            </>
                        </Col>

                        {taskTemplateDescription && (
                            <Col span={18}>
                                <Text>{taskTemplateDescription}</Text>
                            </Col>
                        )}
                        <Divider orientation="left" plain />
                    </Row>
                )}
            </>
        );
    };

    const getTemplateForm = () => {
        return (
            <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                <Form
                    form={form}
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxHeight: '64vh' }} //
                >
                    {getTemplateFormViewTemplateInfo()}
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="taskParentId" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="parent" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="type" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="taskTemplate" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="created" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="createdBy" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="updated" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="updatedBy" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="actions" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('task.edit.edit.name')}
                                name="name"
                                style={{ display: canView() ? 'block' : 'none' }}
                            >
                                <Input disabled={isDisabledForm()} />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('task.edit.edit.description')}
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 8, maxRows: 8 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                    disabled={isDisabledForm()}
                                />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('task.edit.edit.status')}
                                name="status"
                                style={{ display: canView() ? 'block' : 'none' }}
                                initialValue={setEnumFormat('TASK_STATUS', taskStatusEnum.NEW)}
                            >
                                <NySearchField
                                    options={getEnumArrayStatus('TASK_STATUS').map((s: any) => {
                                        if (checkStatus(taskStatusEnum.NEW) && isAdmin()) {
                                            return s.id !== taskStatusEnum.NEW &&
                                                s.id !== taskStatusEnum.ASSIGNED &&
                                                s.id !== taskStatusEnum.IN_PROGRESS
                                                ? { ...s, disabled: true }
                                                : s;
                                        } else if (checkStatus(taskStatusEnum.ASSIGNED) && isAdmin()) {
                                            return s.id !== taskStatusEnum.ASSIGNED &&
                                                s.id !== taskStatusEnum.IN_PROGRESS
                                                ? { ...s, disabled: true }
                                                : s;
                                        } else if (
                                            checkStatus(taskStatusEnum.IN_PROGRESS) &&
                                            (isAdmin() || isAgent())
                                        ) {
                                            return s.id !== taskStatusEnum.IN_PROGRESS &&
                                                s.id !== taskStatusEnum.RESOLVED &&
                                                s.id !== taskStatusEnum.NOT_RESOLVED
                                                ? { ...s, disabled: true }
                                                : s;
                                        } else if (checkStatus(taskStatusEnum.RESOLVED) && (isAdmin() || isAgent())) {
                                            return s.id !== taskStatusEnum.CLOSED ? { ...s, disabled: true } : s;
                                        } else {
                                            return { ...s, disabled: true };
                                        }
                                    })}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    autoFocus={true}
                                    nyTestId="task-type"
                                    className=""
                                    style={{ display: canView() ? 'block' : 'none' }}
                                    onChange={(value: any) => {
                                        setTaskStatus(value && value.id > 0 ? value.id : null);
                                    }}
                                    disabled={isDisabledForm() || isAgent()}
                                />
                            </Form.Item>
                            <TaskPrioritySearch
                                formStyle={{ display: canView() ? 'block' : 'none' }}
                                setDefaultFilterValue={() => [
                                    { field: 'active', condition: 'equals_bool', value: 1 },
                                    { field: 'type', condition: 'equals', value: taskTypeEnum.SERVICE },
                                ]}
                                disabled={isDisabledForm()}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('task.edit.edit.admin')}
                                name="user"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.USER.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        employmentRecordId: 'employmentRecordId',
                                        businessUnit: 'businessUnit',
                                    }}
                                    searchBy="user"
                                    itemName={[
                                        ['person', 'firstName'],
                                        ['person', 'lastName'],
                                    ]}
                                    onChange={(value: any) => {
                                        setSelectedUser(value);
                                    }}
                                    renderOption={customEmployeeRenderOption}
                                    customItemNameLabel={'firstName lastName'}
                                    SearchPopupComponent={<UserIndex disabled={true} />}
                                    style={{ width: '100%' }}
                                    addNewItem={false}
                                    order="person.last_name, person.first_name"
                                    disabled={isDisabledForm()}
                                    mustGetPopupContainer={
                                        taskTemplateFileId !== undefined || taskTemplateDescription !== undefined
                                    }
                                />
                            </Form.Item>
                            {forAssignedAsset && (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('task.edit.edit.asset')}
                                            name={'assignedAsset'}
                                            rules={[
                                                {
                                                    required: forAssignedAsset,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                style={{ width: '100%' }}
                                                url={CONSTANTS_REQ.ASSET.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'label',
                                                    text: 'text',
                                                    name: 'name',
                                                    serialNumber: 'serialNumber',
                                                }}
                                                renderOption={customAssetRenderOption}
                                                searchBy="assetModel.name || serialNumber || inventoryNumber"
                                                setDefaultFilterValue={setDefaultFilterValueAsset}
                                                order="assetModel.name"
                                                className=""
                                                customListWidth={'1200px'}
                                                disabled={isDisabledForm()}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('task.edit.edit.assignedUser')}
                                        name="assignedUser"
                                        style={{ display: canView() ? 'block' : 'none' }}
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.USER.SEARCH}
                                            map={{
                                                id: 'id',
                                                label: 'text',
                                                employmentRecordId: 'employmentRecordId',
                                                businessUnit: 'businessUnit',
                                            }}
                                            searchBy="user"
                                            itemName={[
                                                ['person', 'firstName'],
                                                ['person', 'lastName'],
                                            ]}
                                            onChange={(value: any) => {
                                                setSelectedUser(value);
                                            }}
                                            renderOption={customEmployeeRenderOption}
                                            customItemNameLabel={'firstName lastName'}
                                            SearchPopupComponent={<UserIndex disabled={true} />}
                                            style={{ width: '100%' }}
                                            addNewItem={false}
                                            order="person.last_name, person.first_name"
                                            disabled={isDisabledForm()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <TeamSearch
                                disabled={isDisabledForm() || isAgent()}
                                label={geti18nText('task.edit.edit.assignedTeam')}
                                name="assignedTeam"
                                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                                formStyle={{ display: canView() ? 'block' : 'none' }}
                            />
                            <Form.Item
                                label={geti18nText('task.edit.edit.solution')}
                                name="solutionDescription"
                                rules={[
                                    {
                                        required:
                                            taskStatus == taskStatusEnum.RESOLVED ||
                                            taskStatus == taskStatusEnum.NOT_RESOLVED,
                                        message: geti18nText('app.default.required'),
                                        whitespace:
                                            taskStatus == taskStatusEnum.RESOLVED ||
                                            taskStatus == taskStatusEnum.NOT_RESOLVED,
                                    },
                                ]}
                                style={{ display: canView() ? 'block' : 'none' }}
                            >
                                <TextArea
                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                    disabled={isDisabledForm2() && !actionsArray?.includes(enTaskAction.RESOLVE)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    <Row gutter={24}>
                        {hasMetadata ? (
                            <>
                                <Col span={12}>
                                    <Collapse
                                        activeKey={collapseActiveKeys}
                                        onChange={(key: any) => {
                                            setCollapseActiveKeys(key);
                                        }}
                                    >
                                        <Panel header={geti18nText('task.edit.tab.1')} key="1">
                                            {getTemplateForm()}
                                        </Panel>
                                    </Collapse>
                                </Col>
                                <Col span={12}>
                                    <Collapse
                                        activeKey={collapseActiveKeys}
                                        onChange={(key: any) => {
                                            setCollapseActiveKeys(key);
                                        }}
                                    >
                                        <Panel header={geti18nText('task.edit.tab.2')} key="2">
                                            <MetadataIndex
                                                objectMetadataForm={objectMetadataForm}
                                                metadata={metadata}
                                                metadataTemplate={metadataTemplate}
                                            />
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </>
                        ) : (
                            <Col span={24}>{getTemplateForm()}</Col>
                        )}
                    </Row>
                    {showFiles && getFiles(true)}
                </>
            )}
        </>
    );
};

export default NewTaskFromTemplateIndex;
