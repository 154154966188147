import {
    BankOutlined,
    HomeOutlined,
    MailOutlined,
    MobileOutlined,
    OrderedListOutlined,
    PhoneOutlined,
    SolutionOutlined,
    StarFilled,
    StarOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Avatar, Card, Descriptions, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { addEmployee } from '../../slices/favoriteSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';

const EmployeeCard = ({
    employee,
    cardStyle = { width: '100%', marginTop: 10 },
    customFooterContent,
    favorite = false,
    avatar = true,
}: any) => {
    const dispatch = useDispatch();
    const { employees } = useSelector((state: RootState) => state.favorite);

    const openMail = (mail: any) => {
        if (mail) {
            window.open('mailto:' + mail, '_self');
        }
    };

    const callPhone = (number: any) => {
        if (number) {
            window.open('tel:' + number, '_self');
        }
    };

    const favoriteOnClick = (employeeData: any) => {
        dispatch(addEmployee({ employee: employeeData }));
    };

    return (
        <Card style={cardStyle} bodyStyle={{ padding: '15px 16px' }}>
            <Meta
                avatar={
                    avatar &&
                    (employee && employee.person && employee.person.fileId ? (
                        <Avatar
                            size={64}
                            src={
                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                '/' +
                                employee.person.fileId +
                                '?tenant=' +
                                NySession.getUser().tenantId
                            }
                        />
                    ) : (
                        <Avatar size={64} icon={<UserOutlined />} />
                    ))
                }
                title={
                    <>
                        <span>{`${employee && employee.person && employee.person.firstName} ${
                            employee && employee.person && employee.person.lastName
                        }`}</span>
                        {favorite &&
                            (employees && employees.findIndex((row: any) => row.id === employee.id) > -1 ? (
                                <StarFilled
                                    className="ny-favorit-icon-active"
                                    onClick={() => favoriteOnClick(employee)}
                                />
                            ) : (
                                <StarOutlined className="ny-favorit-icon" onClick={() => favoriteOnClick(employee)} />
                            ))}
                    </>
                }
                description={
                    <>
                        <Descriptions
                            column={1}
                            bordered
                            size={'small'}
                            labelStyle={{
                                width: '10px',
                                height: '10px',
                                padding: '0px 8px',
                            }}
                            contentStyle={{ height: '10px', padding: '0px 8px' }}
                        >
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.edit.employment.businessUnit')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <BankOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span>
                                    {employee &&
                                        employee.businessUnits &&
                                        employee.businessUnits
                                            .map((businessUnit: any) => {
                                                return (
                                                    <p style={{ marginBottom: '0px' }}>
                                                        {(businessUnit.code ? `[${businessUnit.code}] ` : '') +
                                                            (businessUnit.name ? businessUnit.name : '')}
                                                    </p>
                                                );
                                            })
                                            .reverse()}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.edit.eployment.record')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <OrderedListOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span>
                                    {employee && employee.employmentRecordId ? employee.employmentRecordId : ''}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.edit.professional.vocation')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <SolutionOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span>{employee && employee.vocation ? employee.vocation.name : ''}</span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.edit.employment.room')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <HomeOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span>{employee && employee.room ? employee.room.name : ''}</span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.contact.edit.phone')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <PhoneOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span
                                    className="ny-contact"
                                    onClick={() =>
                                        callPhone(
                                            employee && employee.contact && employee.contact.phone
                                                ? employee.contact.phone
                                                : null
                                        )
                                    }
                                >
                                    {employee && employee.contact && employee.contact.phone
                                        ? employee.contact.phone
                                        : ''}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.contact.edit.mobile')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <MobileOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span
                                    className="ny-contact"
                                    onClick={() =>
                                        callPhone(
                                            employee && employee.contact && employee.contact.mobile
                                                ? employee.contact.mobile
                                                : null
                                        )
                                    }
                                >
                                    {employee && employee.contact && employee.contact.mobile
                                        ? employee.contact.mobile
                                        : ''}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Tooltip title={geti18nText('employee.contact.edit.email')}>
                                        <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                            <MailOutlined />
                                        </span>
                                    </Tooltip>
                                }
                            >
                                <span
                                    className="ny-contact"
                                    onClick={() =>
                                        openMail(
                                            employee && employee.contact && employee.contact.email
                                                ? employee.contact.email
                                                : null
                                        )
                                    }
                                >
                                    {employee && employee.contact && employee.contact.email
                                        ? employee.contact.email
                                        : ''}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                        {customFooterContent && customFooterContent()}
                    </>
                }
            />
        </Card>
    );
};

export default EmployeeCard;
