import { CheckOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Divider, Tooltip } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { SortableIndex } from '../../../../components/sortable-list/SortableIndex';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookCreatedUser, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import TaskDependencyIndex from './dependency/TaskDependencyIndex';
import TaskTemplateEdit from './edit';

const TaskTemplateIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    filter,
    taskTemplateId,
    taskType,
    idWidth = '5%',
    activeWidth = '5%',
    taskAdminWidth = 'auto',
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const [sortListModalVisible, setSortListModalVisible] = useState<boolean>(false);
    const [parentCategoryId, setParentCategoryId] = useState(null);
    const [parentCategoryName, setParentCategoryName] = useState(null);
    const table = useTableSettings();
    const enTaskType = useEnum('TASK_TYPE');
    const [items, setItems] = useState<any>([]);
    const [refreshItems, setRefreshItems] = useState(0);

    useHelper('task_service/codebooks/subtype');

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const closeModal = () => {
        setSortListModalVisible(false);
        setParentCategoryId(null);
        setParentCategoryName(null);
    };

    const setDefaultFilterValue = () => {
        if (filter) {
            return filter;
        } else {
            return [
                {
                    field: 'active',
                    condition: 'equals_bool',
                    value: 1,
                },
                {
                    field: 'type',
                    condition: 'equals',
                    value: enTaskType.SERVICE,
                },
            ];
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('task.template.table.column.id'),
                dataIndex: 'id',
                width: idWidth,
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('task.template.table.column.code'),
                dataIndex: 'code',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('task.template.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (name: any, record: any) => {
                    if (record && record.name) {
                        return (
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record.name}>
                                <div className={'ny-table-paragraph-div'}>
                                    <p className={'ny-table-paragraph'}>{record && record.name}</p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('task.template.table.column.description'),
                dataIndex: ['description'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (description: any, record: any) => {
                    if (record && record.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '200px' }}
                                title={record.description}
                            >
                                <div className={'ny-table-paragraph-div'}>
                                    <p className={'ny-table-paragraph'}>{record && record.description}</p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('task.template.table.column.taskTypeCategory'),
                dataIndex: ['taskTypeCategory', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(
                    CONSTANTS_REQ.TASK_TYPE_CATEGORY.SEARCH,
                    'taskTypeCategory.id',
                    'name',
                    'name',
                    { id: 'id', label: 'name', text: 'name' }
                ),
            },
            {
                title: geti18nText('task.template.table.column.taskTypeSubtype'),
                dataIndex: ['taskTypeSubtype', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(
                    CONSTANTS_REQ.TASK_TYPE_SUBTYPE.SEARCH,
                    'taskTypeSubtype.id',
                    'name',
                    'name',
                    {
                        id: 'id',
                        label: 'name',
                        text: 'name',
                    }
                ),
            },
            {
                title: geti18nText('task.template.table.column.assignedTeam'),
                dataIndex: ['assignedTeam', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TEAM.SEARCH, 'team.id', 'name', 'name', {
                    id: 'id',
                    label: 'name',
                    name: 'name',
                    code: 'code',
                }),
            },
            {
                title: geti18nText('task.template.table.column.assignedEmployee'),
                dataIndex:
                    "concat(assigned_person.first_name, ' ', assigned_person.last_name, ' ', coalesce(cast(assigned_employee.employment_record_id as varchar),''))",
                width: taskAdminWidth,
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookCreatedUser(
                    'assignedPerson.id',
                    'assignedPerson.lastName assignedPerson.firstName',
                    [
                        ['assignedPerson', 'firstName'],
                        ['assignedPerson', 'lastName'],
                    ]
                ),
                render: (text: any, record: any) => {
                    if (record?.assignedUser?.person) {
                        return record.assignedUser.person.firstName + ' ' + record.assignedUser.person.lastName;
                    }
                },
            },

            {
                title: geti18nText('task.template.table.column.active'),
                dataIndex: 'active',
                width: activeWidth,
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
            },
        ],
        ...(!taskTemplateId
            ? [
                  {
                      title: geti18nText('task.typeCategory.table.column.sort'),
                      width: '6%',
                      align: 'center',
                      render: (record: any) => {
                          return (
                              <Tooltip title={geti18nText('task.typeCategory.table.column.sort.category')}>
                                  <UnorderedListOutlined
                                      style={{ fontSize: '20px' }}
                                      onClick={() => {
                                          setSortListModalVisible(true);
                                          setParentCategoryId(record?.taskTypeCategory?.id);
                                          setParentCategoryName(record?.taskTypeCategory?.name);
                                      }}
                                  />
                              </Tooltip>
                          );
                      },
                  },
              ]
            : []),
    ];

    return (
        <>
            <NyDataTable
                nyId="tasks-task-template-items-table"
                url={CONSTANTS_REQ.TASK_TEMPLATE.LIST}
                addNewButtonText={geti18nText('task.template.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={TaskTemplateEdit}
                editProps={{
                    taskTemplateId: taskTemplateId,
                    taskType: taskType,
                    scroll: scroll,
                    sortOrder: sortOrder,
                }}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={(data: any, params: any) => {
                    table.onDataLoaded(data, params);
                    setItems(data ?? []);
                    setRefreshItems((refreshItems) => refreshItems + 1);
                }}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={!canCreate()}
                shortcuts={true}
                nyTestId="task-type-category-data-table"
                selectOnSave={true}
                headerTitle={geti18nText('menu.task.template')}
            />
            {sortListModalVisible && (
                <SortableIndex
                    closeModal={closeModal}
                    code={geti18nText('task.typeCategory.table.column.code')}
                    description={geti18nText('task.typeCategory.table.column.description')}
                    id={geti18nText('task.typeCategory.table.column.id')}
                    name={geti18nText('task.typeCategory.table.column.name')}
                    parentCategoryId={parentCategoryId}
                    parentCategoryName={parentCategoryName}
                    sortListModalVisible={sortListModalVisible}
                    taskType={enTaskType.SERVICE}
                    type={'task-template'}
                    typeModal="task-template"
                    url={CONSTANTS_REQ.TASK_TEMPLATE.LIST}
                    taskTypeSubtype={geti18nText('task.typeSubtype.table.header')}
                />
            )}
            {taskTemplateId && items?.length > 1 && (
                <div>
                    <Divider>{geti18nText('task.template.edit.tab.dependency')}</Divider>
                    <TaskDependencyIndex
                        taskTemplateId={taskTemplateId}
                        refreshItems={refreshItems}
                        subtaskCount={items?.length ?? 0}
                    />
                </div>
            )}
        </>
    );
};

export default TaskTemplateIndex;
