import { NyDatePicker, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getEnumArray, setDateFormat, setEnumFormat, setSearchFormat } from '../../utils/Utils';
import TravelVehiclePersonalIndex from '../../views/travel-warrant/views/travel-vehicle-personal';
import TravelVehiclePersonalEdit from '../../views/travel-warrant/views/travel-vehicle-personal/edit';
import useEnum from '../../hooks/useEnum';

const TravelWarrantAdditionalInfoEdit = ({
    item,
    form,
    travelWarrantEmployeeId,
    isPlane = false,
    needTransport = false,
    disabled = false,
}: any) => {
    const [showTime, setShowTime] = useState<boolean>(true);
    const [isPlaneAdditional, setIsPlaneAdditional] = useState<boolean>(false);
    const enVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const enNeedTransport = useEnum('TRAVEL_NEED_TRANSPORT');

    useEffect(() => {
        setValues(item);
    }, [item]);

    const setDefaultFilterValue = () => {
        if (travelWarrantEmployeeId !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: travelWarrantEmployeeId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const onTravelVehicleChange = (value: any) => {
        if (value != undefined && value.id >= 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_VEHICLE.EDIT + '/' + value.id).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    if (result.data) {
                        const vehicleType = result.data.vehicleType;
                        if (result.data.vehicleType == enVehicleType.PROVIDED_BY_ORGANIZER) {
                            form.setFieldsValue({
                                needTransport: setEnumFormat(
                                    'TRAVEL_NEED_TRANSPORT',
                                    enNeedTransport.PROVIDED_BY_ORGANIZER
                                ),
                            });
                        }
                        setIsPlaneAdditional(
                            result?.data?.vehicleType &&
                                Object.values([
                                    enVehicleType.PLANE,
                                    enVehicleType.BOAT,
                                    enVehicleType.TRAIN,
                                    enVehicleType.BUS,
                                ]).includes(result.data.vehicleType)
                        );
                        result.data = setSearchFormat(result.data, 'name', 'name');
                        form.setFieldsValue({ travelVehicle: result.data, vehicleType: vehicleType });
                    }
                }
            });
        }
    };

    const setValues = (data: any) => {
        if (data) {
            if (data.needAccommodation) {
                data.needAccommodation = setEnumFormat('TRAVEL_NEED_ACCOMMODATION', data.needAccommodation);
            }
            if (data.needTransport) {
                data.needTransport = setEnumFormat('TRAVEL_NEED_TRANSPORT', data.needTransport);
            } else if (needTransport) {
                data.needTransport = setEnumFormat('TRAVEL_NEED_TRANSPORT', enNeedTransport.PROVIDED_BY_ORGANIZER);
            }
            if (data.baggageType) {
                data.baggageType = setEnumFormat('TRAVEL_BAGGAGE_TYPE', data.baggageType);
            }
            if (data.arrivalDate) {
                data.arrivalDate = setDateFormat(data.arrivalDate, 'yyyy-MM-DD HH:mm');
            }
            if (data.returnDate) {
                data.returnDate = setDateFormat(data.returnDate, 'yyyy-MM-DD HH:mm');
            }
            if (data.arrivalDayPart) {
                data.arrivalDayPart = setEnumFormat('TRAVEL_DAY_PARTS', data.arrivalDayPart);
            }
            if (data.returnDayPart) {
                data.returnDayPart = setEnumFormat('TRAVEL_DAY_PARTS', data.returnDayPart);
            }
            if (data.showTime != undefined) {
                setShowTime(data.showTime);
                data.showTime = data.showTime ? true : false;
            }
            if (data.travelVehicle) {
                setIsPlaneAdditional(
                    data?.travelVehicle?.vehicleType &&
                        Object.values([enVehicleType.PLANE, enVehicleType.BOAT, enVehicleType.TRAIN]).includes(
                            data.travelVehicle.vehicleType
                        )
                        ? true
                        : false
                );
                data.travelVehicle = setSearchFormat(data.travelVehicle, 'name', 'name');
            } else {
                setIsPlaneAdditional(false);
            }

            form.setFieldsValue(data);
        }
    };

    return (
        <>
            <Form layout="vertical" form={form}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantOrganization.table.column.address')}
                                name={'eventAddress'}
                            >
                                <Input disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantOrganization.table.column.accomodationNeeded')}
                                name={'needAccommodation'}
                            >
                                <NySearchField
                                    options={getEnumArray('TRAVEL_NEED_ACCOMMODATION')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    className=""
                                    style={{ width: '100%' }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantOrganization.table.column.accomodationOrganizator')}
                                name={'accommodationRecommendation'}
                            >
                                <Input
                                    placeholder={geti18nText(
                                        'travelWarrantOrganization.accomodationOrganizator.placeholder'
                                    )}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantOrganization.table.column.transportNeeded')}
                                name={'needTransport'}
                            >
                                <NySearchField
                                    options={getEnumArray('TRAVEL_NEED_TRANSPORT')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    className=""
                                    style={{ width: '100%' }}
                                    placeholder={
                                        <span style={{ fontWeight: 'normal' }}>
                                            {geti18nText('travelWarrantOrganization.transportNeeded.placeholder')}
                                        </span>
                                    }
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="vehicleType" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('travelWarrantPersonal.edit.travelVehicle')}
                                name="travelVehicle"
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'name',
                                    }}
                                    searchBy="name"
                                    itemName="model"
                                    SearchPopupComponent={
                                        <TravelVehiclePersonalIndex
                                            vehicleType={null}
                                            newButtonTextPersonal={true}
                                            employeeId={travelWarrantEmployeeId}
                                            sort={true}
                                        />
                                    }
                                    AddNewModalComponent={TravelVehiclePersonalEdit}
                                    nyTestId="travel-vehicle"
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    onChange={onTravelVehicleChange}
                                    order="sort"
                                    orderType="asc"
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        {(isPlane || isPlaneAdditional) && (
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantOrganization.table.column.luggage')}
                                    name={'baggageType'}
                                >
                                    <NySearchField
                                        options={getEnumArray('TRAVEL_BAGGAGE_TYPE')}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        className=""
                                        style={{ width: '100%' }}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantOrganization.table.column.showTime')}
                                name={'showTime'}
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch onChange={(checked: boolean) => setShowTime(checked)} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        {showTime ? (
                            <>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('travelWarrantOrganization.table.column.arrival')}
                                        name={'arrivalDate'}
                                    >
                                        <NyDatePicker
                                            mode="time"
                                            format="HH:mm"
                                            style={{ width: '100%' }}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('travelWarrantOrganization.table.column.return')}
                                        name={'returnDate'}
                                    >
                                        <NyDatePicker
                                            mode="time"
                                            format="HH:mm"
                                            style={{ width: '100%' }}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('travelWarrantOrganization.table.column.arrivalDayPart')}
                                        name={'arrivalDayPart'}
                                    >
                                        <NySearchField
                                            options={getEnumArray('TRAVEL_DAY_PARTS')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            className=""
                                            style={{ width: '100%' }}
                                            placeholder={geti18nText('app.default.dayPart')}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('travelWarrantOrganization.table.column.returnDayPart')}
                                        name={'returnDayPart'}
                                    >
                                        <NySearchField
                                            options={getEnumArray('TRAVEL_DAY_PARTS')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            className=""
                                            style={{ width: '100%' }}
                                            placeholder={geti18nText('app.default.dayPart')}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Col>
                    <Col span={24}>
                        <Checkbox checked disabled={disabled}>
                            <span style={{ fontWeight: 'normal' }}>
                                {geti18nText('travelWarrantWizard.table.column.checked')}
                            </span>
                        </Checkbox>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default TravelWarrantAdditionalInfoEdit;
