import { NyDataTable, NyUtils, getColumnSearch, geti18nText } from '@nybble/nyreact';
import moment from 'moment';
import { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple, getColumnSearchByCodebookUserPerson } from '../../../../../utils/Filters';
import ContractJobsEdit from './edit';

const ContractJobsIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    contractTypeId,
    refresh,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [selectedJob, setSelectedJob] = useState<any>(null);
    const [visible, setVisible] = useState(false);

    useHelper('human/contracts/contract-jobs');

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const columns = [
        {
            title: geti18nText('contract.jobs.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('contract.jobs.table.column.template'),
            dataIndex: ['contractDocumentTemplate', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.SEARCH,
                'contractDocumentTemplate.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
        },
        {
            title: geti18nText('contract.jobs.table.column.duration'),
            dataIndex: 'start',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return (
                    (record.start ? moment(record.start).format('DD.MM.YYYY HH:mm') : ' ') +
                    ' - ' +
                    (record.end ? moment(record.end).format('DD.MM.YYYY HH:mm') : '')
                );
            },
        },
        {
            title: geti18nText('contract.jobs.table.column.user'),
            dataIndex: "concat(person.first_name, ' ', person.last_name)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookUserPerson(),
            render: (text: any, record: any) => {
                if (record.person) {
                    return `${record.person.firstName} ${record.person.lastName}`;
                }
            },
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="human-contract-contract-jobs-table"
                url={CONSTANTS_REQ.CONTRACT_TYPE.LIST_JOBS}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onRowSelect={(data: any) => {
                    setSelectedJob(data);
                    setVisible(true);
                }}
                fetchWhenChange={refresh}
                addedData={{ contractTypeId: contractTypeId }}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                shortcuts={true}
                exportCSV={canExportCSV()}
                csvColumns={csvColumns()}
                CSVFileName={geti18nText('menu.contract.jobs')}
                colCSVCustomization={csvColumnCustomisation()}
            />
            {visible && selectedJob && (
                <ContractJobsEdit
                    job={selectedJob}
                    setJob={setSelectedJob}
                    contractTypeId={contractTypeId}
                    visible={visible}
                    setVisible={setVisible}
                />
            )}
        </>
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.jobs.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.jobs.table.column.template'),
            dataIndex: ['contractDocumentTemplate', 'name'],
        },
        {
            title: geti18nText('contract.jobs.table.column.start'),
            dataIndex: 'start',
        },
        {
            title: geti18nText('contract.jobs.table.column.end'),
            dataIndex: 'end',
        },
        {
            title: geti18nText('contract.jobs.table.column.user'),
            dataIndex: 'person',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            start: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            end: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            user: (value: any) => {
                return value ? value?.firstName + ' ' + value?.lastName : '';
            },
        },
    ];
};

export default ContractJobsIndex;
