import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import MobilizationLocationIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const MobilizationLocationSearch = ({
    label = geti18nText('employee.mobilization.edit.mobilizationLocation'),
    name = 'mobilizationLocation',
    disabled = false,
    AddNewModalComponent = null,
    mustGetPopupContainer = false,
    style=undefined,
    onChange,
}: any) => {
    return (
        <Form.Item label={label} name={name} style={style}>
            <NySearchField
                url={CONSTANTS_REQ.MOBILIZATION_LOCATION.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                nyTestId="mobilizationLocation"
                disabled={disabled}
                onChange={onChange}
                SearchPopupComponent={<MobilizationLocationIndex />}
                AddNewModalComponent={AddNewModalComponent}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default MobilizationLocationSearch;
