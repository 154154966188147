import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../../utils/Filters';
import { customAssetDepreciationGroupRenderOption, getDateFormat, numberFormat } from '../../../../../../utils/Utils';
import { default as AssetInitialStateItemsEdit } from './edit';

const AssetInitialStateItemsIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    assetInitialStateId,
    canCreate,
    booked,
    bookedGeneralLedger,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'asset_initial_state_id',
                condition: 'equals',
                value: assetInitialStateId,
            },
        ];
    };

    const columns: any = [
        {
            title: geti18nText('asset.acquisition.item.table.column.asset'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                serialNumber: 'serialNumber',
                itemName: 'itemName',
                itemCode: 'itemCode',
            }),
            render: (text: any, record: any) => {
                if (record?.asset?.assetModel?.name && record?.asset?.id) {
                    return record.asset.assetModel.name + ' (ID: ' + record.asset.id + ')';
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.serialNumber'),
            dataIndex: ['asset', 'serialNumber'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.inventoryNumber'),
            dataIndex: ['asset', 'serialNumber_2'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.asset && record.asset.serialNumber2) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '300px' }}
                            title={record.asset.serialNumber2}
                        >
                            <p
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginTop: '-0.4rem',
                                    marginBottom: '-0.5rem',
                                }}
                            >
                                {record.asset.serialNumber2}
                            </p>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.depreciationGroup'),
            dataIndex: ['assetDepreciationGroup', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH,
                'assetDepreciationGroup.id',
                'name',
                'name',
                { id: 'id', label: 'name' }
            ),
            render: (text: any, record: any) => {
                if (record.assetDepreciationGroup) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '300px' }}
                            title={customAssetDepreciationGroupRenderOption(record.assetDepreciationGroup)}
                        >
                            <p
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginTop: '-0.4rem',
                                    marginBottom: '-0.5rem',
                                }}
                            >
                                {customAssetDepreciationGroupRenderOption(record.assetDepreciationGroup)}
                            </p>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.company'),
            dataIndex: ['acquisitionCompany', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COMPANY.SEARCH, 'acquisitionCompany.id'),
            render: (text: any, record: any) => {
                if (record.acquisitionCompany && record.acquisitionCompany.name) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '300px' }}
                            title={record.acquisitionCompany.name}
                        >
                            <p
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginTop: '-0.4rem',
                                    marginBottom: '-0.5rem',
                                }}
                            >
                                {record.acquisitionCompany.name}
                            </p>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.acquisitionDate'),
            dataIndex: 'acquisitionDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record && record.acquisitionDate) {
                    return getDateFormat(record.acquisitionDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.quantity'),
            dataIndex: 'quantity',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.price'),
            dataIndex: ['asset', 'acquisitionPrice'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.asset && record.asset.acquisitionPrice) {
                    return numberFormat(record.asset.acquisitionPrice);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.amount'),
            dataIndex: 'acquisitionCostValue',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (acquisitionCostValue: any) => {
                if (acquisitionCostValue) {
                    return numberFormat(acquisitionCostValue);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.valueIncrease'),
            dataIndex: 'valueIncrease',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (valueIncrease: any) => {
                if (valueIncrease) {
                    return numberFormat(valueIncrease);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.valueDecrease'),
            dataIndex: 'valueDecrease',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (valueDecrease: any) => {
                if (valueDecrease) {
                    return numberFormat(valueDecrease);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.acumulatedDepreciation'),
            dataIndex: 'acumulatedDepreciation',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (acumulatedDepreciation: any) => {
                if (acumulatedDepreciation) {
                    return numberFormat(acumulatedDepreciation);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.netBookValue'),
            dataIndex: 'netBookValue',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (netBookValue: any) => {
                if (netBookValue) {
                    return numberFormat(netBookValue);
                } else {
                    return numberFormat(0);
                }
            },
        },
    ];

    useEffect(() => {
        setRefreshTable((prevValue: any) => prevValue + 1);
    }, [assetInitialStateId]);

    return (
        <NyDataTable
            nyId="asset-initial-state-items"
            url={CONSTANTS_REQ.ASSET_INITIAL_STATE_ITEM.LIST}
            addNewButtonText={assetInitialStateId ? geti18nText('asset.acquisition.add.asset') : null}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={AssetInitialStateItemsEdit}
            setDefaultFilterValue={() => setDefaultFilterValue()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            fetchWhenChange={refreshTable || assetInitialStateId}
            hideNewButton={!canCreate || !assetInitialStateId || booked || bookedGeneralLedger}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            shortcuts={true}
            editProps={{
                refreshTable: refreshTable,
                setRefreshTable: setRefreshTable,
                assetInitialStateId: assetInitialStateId,
                canCreate: canCreate && assetInitialStateId && !booked && !bookedGeneralLedger,
            }}
        />
    );
};

export default AssetInitialStateItemsIndex;
