import { geti18nText, NyDatePicker, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { Table } from 'antd';
import CurrencyFlag from 'react-currency-flags';
import { columnSort, columnSearch } from '../../../../utils/Utils';

const HBNExchangeRateTable = () => {
    const [loading, setLoading] = useState<any>(false);
    const [dataSource, setDataSource] = useState([]);
    const [date, setDate] = useState<any>(moment());

    useEffect(
        function () {
            fatch();
        },
        [date]
    );

    const fatch = () => {
        if (date) {
            setLoading(true);
            var parms: any = { date: date.format('YYYY-MM-DD') };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EXCHANGE_RATE.HNB, parms).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    setDataSource(response.data);
                }
                setLoading(false);
            });
        }
    };

    const disabledDate = (current: any) => {
        return current > moment();
    };

    const columns = [
        {
            title: geti18nText('exchangeRate.table.column.country'),
            dataIndex: 'Država',
            sorter: (a: any, b: any) => {
                return columnSort(a['Država'], b['Država']);
            },
            onFilter: (value: any, record: any) => {
                return columnSearch(value.condition, value.value, record['Država']);
            },
            render: (text: any, record: any) => (
                <>
                    <CurrencyFlag currency={record['Valuta']} height="10" />
                    <span style={{ marginLeft: '10px' }}>{record['Država']}</span>
                </>
            ),
        },
        {
            title: geti18nText('exchangeRate.table.column.currency'),
            dataIndex: 'Valuta',
            sorter: (a: any, b: any) => {
                return columnSort(a['Valuta'], b['Valuta']);
            },
            onFilter: (value: any, record: any) => {
                return columnSearch(value.condition, value.value, record['Valuta']);
            },
        },
        {
            title: geti18nText('exchangeRate.table.column.unit'),
            dataIndex: 'Jedinica',
            sorter: (a: any, b: any) => {
                return columnSort(a['Jedinica'], b['Jedinica']);
            },
        },
        {
            title: geti18nText('exchangeRate.table.column.buyingRate'),
            dataIndex: 'Kupovni za devize',
            sorter: (a: any, b: any) => {
                return columnSort(a['Kupovni za devize'], b['Kupovni za devize']);
            },
        },
        {
            title: geti18nText('exchangeRate.table.column.middleRate'),
            dataIndex: 'Srednji za devize',
            sorter: (a: any, b: any) => {
                return columnSort(a['Srednji za devize'], b['Srednji za devize']);
            },
        },
        {
            title: geti18nText('exchangeRate.table.column.sellingRate'),
            dataIndex: 'Prodajni za devize',
            sorter: (a: any, b: any) => {
                return columnSort(a['Prodajni za devize'], b['Prodajni za devize']);
            },
        },
    ];

    return (
        <>
            <NyDatePicker
                value={date}
                onChange={(date: any) => {
                    setDate(date);
                }}
                style={{ margin: '10px' }}
                disabledDate={disabledDate}
            />
            {loading ? (
                <NySpinner />
            ) : (
                <Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
            )}
        </>
    );
};

export default HBNExchangeRateTable;
