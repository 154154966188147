import { combineReducers } from '@reduxjs/toolkit';
import selectedItemReducer from './slices/selectedItemSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import attendanceReducer from './slices/attendanceSlice';
import helperReducer from './slices/helperSlice';
import generalSettingsReducer from './slices/generalSettingsSlice';
import shortcutsSettingsReducer from './slices/shortcutsSettingsSlice';
import employeeGroupReducer from './slices/employeeGroupSlice';
import modalOpenReducer from './slices/modalOpenSlice';
import menuReducer from './slices/menuSlice';
import notificationReducer from './slices/notificationSlice';
import employeeReducer from './slices/employeeSlice';
import favoriteReducer from './slices/favoriteSlice';
import webshopReducer from './slices/webshopSlice';
import propReducer from './slices/propSlice';
import companyReducer from './slices/companySlice';
import dashboardReducer from './slices/dashboardSlice';
import dashboardHRReducer from './slices/dashboardHRslice';
import pinedNotificationsReducer from './slices/pinedNotificationsSlice';
import travelWarrantReducer from './slices/travelWarrantSlice';
import applicationSettingsReducer from './slices/applicationSettingsSlice';
import historyReducer from './slices/historySlice';
import graphColorReducer from './slices/graphColorsSlice';
import workAttendanceReducer from './slices/workAttendanceSlice';

const rootReducer = combineReducers({
    selectedItem: selectedItemReducer,
    attendance: attendanceReducer,
    helper: helperReducer,
    generalSettings: generalSettingsReducer,
    shortcutsSettings: shortcutsSettingsReducer,
    employeeGroup: employeeGroupReducer,
    modalOpen: modalOpenReducer,
    menu: menuReducer,
    notification: notificationReducer,
    employee: employeeReducer,
    favorite: favoriteReducer,
    webshop: webshopReducer,
    prop: propReducer,
    company: companyReducer,
    dashboardHR: dashboardHRReducer,
    dashboard: dashboardReducer,
    pinedNotifications: pinedNotificationsReducer,
    travelWarrant: travelWarrantReducer,
    applicationSettings: applicationSettingsReducer,
    history: historyReducer,
    graphColors: graphColorReducer,
    workAttendance: workAttendanceReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof persistedReducer>;

export default persistedReducer;
