import { CloudDownloadOutlined } from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import RowAction from '../../../../../components/row-action';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import {
    downloadFile,
    errorNotification,
    getDateFormat,
    getEnumArrayStatus,
    numberFormat,
} from '../../../../../utils/Utils';
import PaymentOrdersInitializationEdit from './edit';

const PaymentOrdersInitializationIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    defaultFilterValue,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);

    const table = useTableSettings();

    useHelper('payment_order/initialization');

    const download = (record: any) => {
        message.loading(geti18nText('import.text.loading'), 0);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.PAYMENT_ORDER_INITIALIZATION.XML_DOWNLOAD,
            {
                id: record.id,
            },
            false,
            true
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = `${record.messageId}.xml`;
                downloadFile(result);
            } else {
                errorNotification(geti18nText('app.download.error'));
            }
            message.destroy();
        });
    };

    const columns = [
        {
            title: geti18nText('payment.orders.initialization.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.messageId'),
            dataIndex: 'messageId',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.initiatingPartyName'),
            dataIndex: 'initiatingPartyName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.numberOfTransactions'),
            dataIndex: 'numberOfTransactions',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.controlSum'),
            dataIndex: 'controlSum',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (controlSum: any) => {
                if (controlSum) {
                    return numberFormat(controlSum);
                }
            },
        },
        {
            title: geti18nText('payment.orders.initialization.table.column.status'),
            dataIndex: 'status',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('PAYMENT_ORDER_STATUS_TYPE'), 'in', undefined),
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.PAYMENT_ORDER_STATUS_TYPE.' + record.status);
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <RowAction
                            component={'Download'}
                            record={record}
                            download={download}
                            downloadLabel={'payment.orders.initialization.xml.download'}
                            downloadIcon={
                                <CloudDownloadOutlined
                                    style={{ fontSize: '16px', paddingRight: '10px', float: 'left' }}
                                />
                            }
                        ></RowAction>
                    );
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="payment-orders-initialization-items"
            url={CONSTANTS_REQ.PAYMENT_ORDER_INITIALIZATION.LIST}
            hideNewButton={true}
            //addNewButtonText={geti18nText('payment.orders.initialization.table.add.new')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={PaymentOrdersInitializationEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(defaultFilterValue)}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            fetchWhenChange={refreshItem}
            headerTitle={geti18nText('menu.payment.orders.collective')}
        />
    );
};

export default PaymentOrdersInitializationIndex;
