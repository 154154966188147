import { FilterOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, List, Pagination, Row, Input, Divider, Tree, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useHelper from '../../hooks/useHelper';
import { CONSTANTS_REQ } from '../../utils/Constants';
import EmployeeCard from './EmployeeCard';

const { Search } = Input;

const defaultPagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    locale: {
        items_per_page: '',
    },
    total: 0,
    showTotal: (total: number) => {
        return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
    },
};

const DirectoryIndex = () => {
    const [loading, setLoading] = useState<any>(false);
    const [employees, setEmployees] = useState<any>([]);
    const [businessUnits, setBusinessUnits] = useState<any>([]);
    const [orderType, setOrderType] = useState<any>('asc');
    const [filterBusinessUnits, setFilterBusinessUnits] = useState<any>(null);
    const [filterSearch, setFilterSearch] = useState<any>(null);
    const [pagination, setPagination] = useState<any>(defaultPagination);

    useHelper('directory');
    const location = useLocation();

    useEffect(() => {
        fetch(pagination);
        fatchBusinessUnits();
    }, []);

    useEffect(() => {
        fetch(defaultPagination);
    }, [orderType, filterBusinessUnits, filterSearch]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const fetch = (pageable: any) => {
        setLoading(true);
        let defaultFilter = setDefaultFilterValue();
        if (filterSearch) {
            defaultFilter.push({
                field: 'customSearch',
                condition: 'contains',
                value: filterSearch,
            });
        }
        if (filterBusinessUnits !== null) {
            if (filterBusinessUnits.length > 1) {
                defaultFilter.push({
                    field: 'businessUnit.id',
                    condition: 'in',
                    value: filterBusinessUnits.toString(),
                });
            } else {
                defaultFilter.push({
                    field: 'businessUnit.id',
                    condition: 'equals',
                    value: filterBusinessUnits.toString(),
                });
            }
        }

        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.DIRECTORY_LIST, {
            search: encodeURI(JSON.stringify(defaultFilter)),
            offset: pageable.current ? pageable.current - 1 : pageable.defaultCurrent - 1,
            max: pageable.pageSize ? pageable.pageSize : pageable.defaultPageSize,
            order: "concat(person.last_name,' ', person.first_name)",
            orderType: orderType,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    const responseData: any = result.data;
                    pageable.total = responseData['totalSize'];
                    pageable.current = responseData['pageNumber'] + 1;
                    pageable.pageSize = responseData['size'];

                    setEmployees(responseData.content);
                    setPagination(pageable);
                }
            }
            setLoading(false);
        });
    };

    const fatchBusinessUnits = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.BUSINESS_UNIT.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setBusinessUnits(result.data);
                }
            }
            setLoading(false);
        });
    };

    const onSearch = (value: any) => {
        setFilterSearch(value);
    };

    const onTreeFilterCheck = (checkedKeys: any) => {
        setFilterBusinessUnits(checkedKeys);
    };

    const paginationOnChange = (page: any, pageSize: any) => {
        const pageable = { ...pagination };
        pageable.current = page;
        pageable.pageSize = pageSize;
        setPagination(pageable);
        fetch(pageable);
    };

    const sortOnChange = (value: any) => {
        if (value) {
            setOrderType(value);
        }
    };

    return (
        <>
            <Row gutter={24}>
                <Col xxl={3} xl={4} lg={4} md={6} sm={24} xs={24} style={{ display: 'flex' }}>
                    <div className="ny-tree-div" style={{ marginTop: '10px' }}>
                        <Divider>
                            <FilterOutlined /> {geti18nText('app.default.filter')}
                        </Divider>
                        <Search
                            onSearch={onSearch}
                            placeholder={geti18nText('app.default.search')}
                            allowClear
                            style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }}
                        />
                        <Divider orientation="left" style={{ fontSize: '12px' }}>
                            {geti18nText('app.default.sort')}
                        </Divider>
                        <Select
                            defaultValue="asc"
                            style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }}
                            onChange={sortOnChange}
                        >
                            <Select.Option value="asc">
                                <SortAscendingOutlined style={{ fontSize: '15px' }} />
                            </Select.Option>
                            <Select.Option value="desc">
                                <SortDescendingOutlined style={{ fontSize: '15px' }} />
                            </Select.Option>
                        </Select>
                        <Divider orientation="left" style={{ fontSize: '12px' }}>
                            {geti18nText('businessUnit.table.header')}
                        </Divider>
                        <Tree
                            checkable
                            onCheck={onTreeFilterCheck}
                            treeData={businessUnits}
                            fieldNames={{ title: 'name', key: 'id' }}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Col>
                <Col xxl={21} xl={20} lg={20} md={18} sm={24} xs={24}>
                    <List
                        grid={{
                            gutter: 12,
                            xs: 1,
                            sm: 1,
                            md: 2,
                            lg: 3,
                            xl: 3,
                            xxl: 4,
                        }}
                        loading={loading}
                        dataSource={employees}
                        renderItem={(employee: any) => (
                            <List.Item className="ny-list-item">
                                <EmployeeCard
                                    employee={employee}
                                    cardStyle={{ width: '100%', marginTop: 10, height: '100%' }}
                                />
                            </List.Item>
                        )}
                        className="ny-list"
                    />
                </Col>
            </Row>
            <div className="buttons-sticky">
                <Row gutter={24}>
                    <Col span={24}>
                        <Pagination style={{ width: '100%' }} {...pagination} onChange={paginationOnChange} />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default DirectoryIndex;
