import { NyDatePicker, NyInputNumber, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import { onKeyDownTextAreaCustom, setDateFormat } from '../../../../../utils/Utils';

const MetadataIndex = ({ objectMetadataForm, metadata, metadataTemplate }: any) => {
    const isDisabledMetadata = () => {
        return false;
    };

    useEffect(() => {
        if (metadataTemplate !== undefined && metadataTemplate.length > 0 && metadata && metadata.length > 0) {
            const metadataForm: any = {};

            metadata.map((item: any) => {
                if (item.taskTemplateMetadata !== undefined) {
                    const findItem: any = metadataTemplate.find((row: any) => row.id === item.taskTemplateMetadata.id);
                    if (findItem !== undefined && findItem.objectMetadata !== undefined) {
                        if (findItem.objectMetadata.dataType === 4) {
                            metadataForm[findItem.objectMetadata.name] = setDateFormat(item.value);
                        } else {
                            metadataForm[findItem.objectMetadata.name] = item.value;
                        }
                    }
                }
            });
            objectMetadataForm.setFieldsValue(metadataForm);
        }
    }, [metadataTemplate, metadata]);

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return (
                    <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        disabled={isDisabledMetadata()}
                        onKeyDown={onKeyDownTextAreaCustom}
                    />
                );
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                        disabled={isDisabledMetadata()}
                    />
                );
            case 'BOOL':
                return <Checkbox disabled={isDisabledMetadata()} />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} disabled={isDisabledMetadata()} />;
            default:
                return <Input disabled={isDisabledMetadata()} />;
        }
    };

    return (
        <Form form={objectMetadataForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Row gutter={24}>
                {metadataTemplate &&
                    metadataTemplate.map((item: any) => {
                        return (
                            <Col span={24}>
                                <Form.Item
                                    label={item.objectMetadata.name}
                                    name={item.objectMetadata.name}
                                    rules={[
                                        {
                                            required: item.objectMetadata.mandatory,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    valuePropName={item.objectMetadata.dataType === 3 ? 'checked' : 'value'}
                                >
                                    {getDataTypeInput(item)}
                                </Form.Item>
                            </Col>
                        );
                    })}
            </Row>
        </Form>
    );
};

export default MetadataIndex;
