import { EyeInvisibleOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { geti18nText, NyInputNumber, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Input, Row, Select, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    formatJoppdCodeSearch,
    getEnumArray,
    getEnumFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setEnumFormat,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';
import Text from 'antd/lib/typography/Text';
import useEnum from '../../../../hooks/useEnum';
import TravelWarrantTypeSearch from '../../../travel-warrant/views/travel-warrant-type/search';
import useHelper from '../../../../hooks/useHelper';

const { Panel } = Collapse;

const TravelWarrantSettingsIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();
    let timeout: any = null;
    let miliseconds = 500;

    useHelper('travel_order/settings/travel-warrant-settings');

    const enDataType = useEnum('APPLICATION_SETTINGS_DATA_TYPE');

    const [options, setOptions] = useState<any>([]);
    const [waypoint, setWaypoint] = useState<any>(undefined);
    const [waypoints, setWaypoints] = useState({});

    const [dataForm, setDataForm] = useState<any>(undefined);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1', '2', '3']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['4', '5', '6']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});
    const [travelWarrantTypeID, setTravelWarrantTypeID] = useState<any>();

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '2', '3']);
            setRightCollapseActiveKeys(['4', '5', '6']);
        }

        setCollapseAll(!collapseAll);
    };

    function displayName(obj: any) {
        let display = '';

        if (obj.address.village) {
            display += obj.address.village + ', ';
        }
        if (obj.address.city) {
            display += obj.address.city + ', ';
        }
        if (obj.address.municipality) {
            display += obj.address.municipality + ', ';
        }
        if (obj.address.town) {
            display += obj.address.town + ', ';
        }
        if (obj.address.tourism) {
            display += obj.address.tourism + ', ';
        }
        if (obj.address.county) {
            display += obj.address.county + ', ';
        }
        if (obj.address.road) {
            display += obj.address.road + ', ';
        }
        if (obj.address.house_number) {
            display += obj.address.house_number + ', ';
        }
        if (obj.address.postcode) {
            display += obj.address.postcode + ', ';
        }
        if (obj.address.country) {
            display += obj.address.country + '';
        }
        return display;
    }

    const onSelect = (value: any, option: any) => {
        if (value && option.lat && option.lng) {
            let tmpWaypoints: any = {};
            tmpWaypoints.id = 0;
            tmpWaypoints.lat = option.lat;
            tmpWaypoints.lng = option.lng;
            tmpWaypoints.name = option.name;
            let tmpShortName = option.name.split(',');
            tmpWaypoints.shortName = tmpShortName[0];
            tmpWaypoints.placeId = option.placeId;
            tmpWaypoints.countryCode = option.countryCode;
            tmpWaypoints.city = option.address.city;
            tmpWaypoints.label = option.name;
            setWaypoints(tmpWaypoints);
        }
    };

    const onSearch = (val: any) => {
        if (val) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(async () => {
                await fetch(
                    `https://nominatim.openstreetmap.org/search?q=${val}&format=json&addressdetails=1&accept-language=hr-HR`,
                    {
                        method: 'GET',
                        body: null,
                        headers: {
                            accept: 'application/json',
                        },
                    }
                )
                    .then((response) =>
                        response
                            .json()
                            .then((data) => ({
                                data: data,
                                status: response.status,
                            }))
                            .then(async (res) => {
                                if (res?.data?.length > 0) {
                                    let opt = res.data.map((city: any) => {
                                        return {
                                            text: displayName(city),
                                            id: city.place_id,
                                            lat: city.lat,
                                            lng: city.lon,
                                            countryCode: city.address.country_code,
                                            city:
                                                city?.address?.village ??
                                                city?.address?.city ??
                                                city?.address?.town ??
                                                city?.address?.municipality ??
                                                '',
                                        };
                                    });
                                    setOptions(opt);
                                }
                            })
                    )
                    .catch(() => {
                        // message.destroy();
                        // message.error(`${file?.name} ${geti18nText('import.text.error')}`);
                    })
                    .finally(() => {});
                // addTravelRelation(val);
            }, miliseconds);
        }
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['travelWarrantSettings'] = isChanged;
        setDirty(tmpDirty);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' }];
    };

    useEffect(() => {
        fetchAppSettings();
    }, []);

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    setDefaultData(result.data);
                }
            }
        });
    };

    const formatTravelWarrantTypeSearch = async function (id: any) {
        let temp: any;
        await NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.EDIT + '/' + id, {}).then(
            (result: any) => {
                if (result.data.id) {
                    temp = { id: result.data['id'], name: result.data['name'] };
                } else {
                    temp = { id: 0, name: id };
                }
            }
        );
        return temp?.id != -1 ? temp : undefined;
    };

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setDataForm(dataForm);

        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === enDataType.BOOL) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            } else if (
                element?.dataType === enDataType.CODEBOOK &&
                element?.settingKey &&
                newDataForm[element?.settingKey]
            ) {
                newDataForm[element?.settingKey] = JSON.parse(newDataForm[element?.settingKey]);
            } else if (element.dataType === enDataType.JOPPD) {
                let defFilter = JSON.parse(element.defaultFilter);
                newDataForm[element.settingKey] = await formatJoppdCodeSearch(
                    newDataForm[element.settingKey],
                    defFilter.page,
                    defFilter.node
                );
            } else if (element.dataType === enDataType.ENUM) {
                newDataForm[element.settingKey] = setEnumFormat(
                    element.sourceType,
                    Number(element.value !== undefined ? element.value : element.defaultValue)
                );
            } else if (element.dataType === 13 && newDataForm[element.settingKey] != undefined) {
                newDataForm[element.settingKey] = JSON.parse(newDataForm[element.settingKey]);
                setWaypoint(newDataForm[element.settingKey]);
                setWaypoints(newDataForm[element.settingKey]);
            }
        }

        const travelWarrantTypeId = dataForm.find(
            (item: any) => item.settingKey === 'DEFAULT_TRAVEL_WARRANT_TYPE'
        )?.value;

        if (travelWarrantTypeId != -1) {
            newDataForm.DEFAULT_TRAVEL_WARRANT_TYPE = await formatTravelWarrantTypeSearch(travelWarrantTypeId);

            setTravelWarrantTypeID(newDataForm.DEFAULT_TRAVEL_WARRANT_TYPE);
        }

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];
                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        let val: any = value;
                        if (item.dataType === enDataType.BOOL) {
                            val = val ? '1' : '0';
                        } else if (item.dataType === enDataType.JOPPD) {
                            val = val.code;
                        } else if (item.dataType === enDataType.CODEBOOK) {
                            if (item.settingKey == 'DEFAULT_TRAVEL_WARRANT_TYPE') {
                                val = val.id;
                            } else {
                                val = JSON.stringify(val);
                            }
                        } else if (item.dataType === enDataType.ENUM) {
                            val = getEnumFormat(val);
                        } else if (item.dataType === 13) {
                            val = JSON.stringify(waypoints);
                        }
                        params.push({ id: item.id, value: val ? String(val) : '' });
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'username_exist':
                                        errorNotification(geti18nText('app.default.username_exist'));
                                        return;
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(geti18nText(result.data.error));
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const setJoppdPaymentCodeFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '16.1.');
    };

    const setJoppdTaxfreeCodeFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '15.1.');
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.TRAVEL_WARRANT')} key="1">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_REPORT_TEXT_TEMPLATE'
                                        )}
                                        name="TRAVEL_WARRANT_REPORT_TEXT_TEMPLATE"
                                    >
                                        <Input.TextArea
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            onKeyDown={onKeyDownTextAreaCustom}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_MANDATORY_PURPOSE_AND_ATTACHMENT'
                                        )}
                                        name="TRAVEL_WARRANT_MANDATORY_PURPOSE_AND_ATTACHMENT"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <TravelWarrantTypeSearch
                                        name="DEFAULT_TRAVEL_WARRANT_TYPE"
                                        required={false}
                                        onChange={(e: any) => setTravelWarrantTypeID(e)}
                                        isSearchTable
                                    />
                                </Col>
                            </Row>
                        </Panel>
                        <Panel
                            header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.TRAVEL_WARRANT_ADDITIONAL')}
                            key="2"
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_PRIVATE_CAR_POSITION'
                                        )}
                                        name="TRAVEL_WARRANT_PRIVATE_CAR_POSITION"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_OFFICIAL_CAR_POSITION'
                                        )}
                                        name="TRAVEL_WARRANT_OFFICIAL_CAR_POSITION"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_ENABLE_CARBON_FOOTPRINT'
                                        )}
                                        name="TRAVEL_WARRANT_ENABLE_CARBON_FOOTPRINT"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_VEHICLE_2_ENABLED'
                                        )}
                                        name="TRAVEL_WARRANT_VEHICLE_2_ENABLED"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_ENABLE_CHANGE_ON_STATUS_PAID'
                                        )}
                                        name="TRAVEL_WARRANT_ENABLE_CHANGE_ON_STATUS_PAID"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.ACCOUNTING_COST_CENTER'
                                        )}
                                        name="ACCOUNTING_COST_CENTER"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.ACCOUNTING_COST_OBJECT'
                                        )}
                                        name="ACCOUNTING_COST_OBJECT"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_SHOW_EVENT_NUMBER'
                                        )}
                                        name="TRAVEL_WARRANT_SHOW_EVENT_NUMBER"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.TRAVEL_WARRANT_MAP')} key="3">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_VEHICLE_ALLOWED_DEVIATION'
                                        )}
                                        name="TRAVEL_WARRANT_VEHICLE_ALLOWED_DEVIATION"
                                    >
                                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} decimalPlaces={2} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_DEFAULT_STARTING_POINT'
                                        )}
                                        name="TRAVEL_WARRANT_DEFAULT_STARTING_POINT"
                                    >
                                        <Select
                                            showSearch
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            notFoundContent={options.length > 0 ? true : false}
                                            suffixIcon={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                                            filterOption={false}
                                            labelInValue={true}
                                            value={
                                                waypoint && waypoint.label != '' ? (
                                                    waypoint
                                                ) : (
                                                    <Text>{geti18nText('travelWarrantRelation.placeholder')}</Text>
                                                )
                                            }
                                            placeholder={geti18nText('travelWarrantRelation.placeholder')}
                                        >
                                            {options.map((option: any) => (
                                                <Select.Option
                                                    // waypointId={waypointId}
                                                    key={option.id}
                                                    value={option.id}
                                                    lat={option.lat}
                                                    lng={option.lng}
                                                    name={option.text}
                                                    placeId={option.id}
                                                    countryCode={option.countryCode}
                                                    address={option}
                                                >
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.TRAVEL_WARRANT_CALCULATION')}
                            key="4"
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_VEHICLE_REIMBURSMENT_PRICE'
                                        )}
                                        name="TRAVEL_WARRANT_VEHICLE_REIMBURSMENT_PRICE"
                                    >
                                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.DEFAULT_PAYMENT_TYPE'
                                        )}
                                        name="DEFAULT_PAYMENT_TYPE"
                                    >
                                        <NySearchField
                                            options={getEnumArray('TRAVEL_WARRANT_PAYMENT_TYPE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="payment-type"
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_HALF_WAGE_HOURS'
                                        )}
                                        name="TRAVEL_WARRANT_HALF_WAGE_HOURS"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_FULL_WAGE_HOURS'
                                        )}
                                        name="TRAVEL_WARRANT_FULL_WAGE_HOURS"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.COMPANY_MAX_DAYS'
                                        )}
                                        name="COMPANY_MAX_DAYS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.PAYMENT_IN_DECLARED_CURRENCY'
                                        )}
                                        name="PAYMENT_IN_DECLARED_CURRENCY"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_EXPENSE_DEDUCT_FROM_WAGE_AMOUNT'
                                        )}
                                        name="TRAVEL_EXPENSE_DEDUCT_FROM_WAGE_AMOUNT"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.TRAVEL_WARRANT_JOPPD')} key="5">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_CASH_JOPPD_PAYMENT_CODE'
                                        )}
                                        name="TRAVEL_WARRANT_CASH_JOPPD_PAYMENT_CODE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            map={{ id: 'id', label: 'code' }}
                                            style={{ width: '100%' }}
                                            searchBy={'code'}
                                            setDefaultFilterValue={setJoppdPaymentCodeFilterValue}
                                            SearchPopupComponent={
                                                <JoppdCodeIndex defaultFilteredValue={setJoppdPaymentCodeFilterValue} />
                                            }
                                            itemName="code"
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_BANK_JOPPD_PAYMENT_CODE'
                                        )}
                                        name="TRAVEL_WARRANT_BANK_JOPPD_PAYMENT_CODE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            map={{ id: 'id', label: 'code' }}
                                            style={{ width: '100%' }}
                                            searchBy={'code'}
                                            setDefaultFilterValue={setJoppdPaymentCodeFilterValue}
                                            SearchPopupComponent={
                                                <JoppdCodeIndex defaultFilteredValue={setJoppdPaymentCodeFilterValue} />
                                            }
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_VEHICLE_JOPPD_TAXFREE_CODE'
                                        )}
                                        name="TRAVEL_WARRANT_VEHICLE_JOPPD_TAXFREE_CODE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            map={{ id: 'id', label: 'code' }}
                                            style={{ width: '100%' }}
                                            searchBy={'code'}
                                            setDefaultFilterValue={setJoppdTaxfreeCodeFilterValue}
                                            SearchPopupComponent={
                                                <JoppdCodeIndex defaultFilteredValue={setJoppdTaxfreeCodeFilterValue} />
                                            }
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.TRAVEL_WARRANT_WAGE_JOPPD_TAXFREE_CODE'
                                        )}
                                        name="TRAVEL_WARRANT_WAGE_JOPPD_TAXFREE_CODE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            map={{ id: 'id', label: 'code' }}
                                            style={{ width: '100%' }}
                                            searchBy={'code'}
                                            setDefaultFilterValue={setJoppdTaxfreeCodeFilterValue}
                                            SearchPopupComponent={
                                                <JoppdCodeIndex defaultFilteredValue={setJoppdTaxfreeCodeFilterValue} />
                                            }
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel
                            header={geti18nText('app.setting.group.name.NY_ERP_HUMAN.TRAVEL_WARRANT_PAYMENT')}
                            key="6"
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.PAYMENT_RETURN_RECIPIENT_NAME'
                                        )}
                                        name={'PAYMENT_RETURN_RECIPIENT_NAME'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.PAYMENT_RETURN_RECIPIENT_ACCOUNT_NUMBER'
                                        )}
                                        name={'PAYMENT_RETURN_RECIPIENT_ACCOUNT_NUMBER'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.PAYMENT_RETURN_MODEL'
                                        )}
                                        name={'PAYMENT_RETURN_MODEL'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.PAYMENT_RETURN_REFERENCE_NUMBER'
                                        )}
                                        name={'PAYMENT_RETURN_REFERENCE_NUMBER'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_HUMAN.TRAVEL_WARRANT.PAYMENT_RETURN_PAYMENT_DESCRIPTION'
                                        )}
                                        name={'PAYMENT_RETURN_PAYMENT_DESCRIPTION'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    {canCreate && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default TravelWarrantSettingsIndex;
