import { FilePdfOutlined, HistoryOutlined } from '@ant-design/icons';
import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Modal, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../Constants';
import { fileDownload } from '../Utils';
import useEnum from '../../hooks/useEnum';

const HistoryIndex = ({ contractTypeId }: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const enDocumentTemplateStatus = useEnum('CONTRACT_DOCUMENT_TEMPLATE_STATUS');
    const enFileType = useEnum('CONTRACT_DOCUMENT_TEMPLATE_FILE_TYPE');

    const showHistory = () => {
        getData(contractTypeId);
        setVisible(true);
    };

    const getData = (id: any) => {
        if (id && id != -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.TEMPLATE_HISTORY + '/' + id).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        setData(result?.data);
                    }
                }
            );
        }
    };

    return (
        <>
            <Tooltip title={geti18nText('app.default.history')}>
                <Tooltip title={geti18nText('app.default.history')}>
                    <HistoryOutlined style={{ marginRight: '10px', fontSize: '22px' }} onClick={showHistory} />
                </Tooltip>
            </Tooltip>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('app.default.history')}
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setVisible(false);
                        setData([]);
                    }}
                    width={1200}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    <Table
                        size={'small'}
                        dataSource={data}
                        rowClassName="editable-row"
                        pagination={false}
                        columns={[
                            {
                                title: geti18nText('contractDocumentTemplate.table.column.name'),
                                dataIndex: 'name',
                            },
                            {
                                title: geti18nText('contractDocumentTemplate.table.column.version'),
                                dataIndex: 'version',
                                width: '15%',
                            },
                            {
                                title: geti18nText('contractDocumentTemplate.table.column.fileType'),
                                dataIndex: 'fileType',
                                width: '15%',
                                render: (text: any, record: any) => {
                                    if (record.fileType) {
                                        return geti18nText(
                                            'app.enum.CONTRACT_DOCUMENT_TEMPLATE_FILE_TYPE.' + record.fileType
                                        );
                                    }
                                },
                            },
                            {
                                title: geti18nText('contractDocumentTemplate.table.column.status'),
                                dataIndex: 'status',
                                width: '150px',
                                render: (text: any, record: any) => {
                                    if (record.status) {
                                        return geti18nText(
                                            'app.enum.CONTRACT_DOCUMENT_TEMPLATE_STATUS.' + record.status
                                        );
                                    }
                                },
                            },
                            {
                                title: geti18nText('contractDocumentTemplate.table.column.created'),
                                dataIndex: 'created',
                                width: '15%',
                                render: (text: any, record: any) => {
                                    if (record.created) {
                                        return moment(record.created).format('DD.MM.YYYY HH:mm');
                                    }
                                },
                            },
                            {
                                title: geti18nText('app.default.actions'),
                                dataIndex: 'actions',
                                width: '10%',
                                render: (text: any, record: any) => {
                                    if (record?.fileId) {
                                        return (
                                            <div style={{ textAlign: 'center' }}>
                                                <Button
                                                    icon={<FilePdfOutlined />}
                                                    onClick={() => {
                                                        fileDownload(
                                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                                            null,
                                                            record?.name
                                                        );
                                                    }}
                                                >
                                                    {geti18nText('app.default.download')}
                                                </Button>
                                            </div>
                                        );
                                    }
                                },
                            },
                        ]}
                    />
                </Modal>
            )}
        </>
    );
};

export default HistoryIndex;
