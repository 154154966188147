import { NyDataEdit, NyDatePicker, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEmployeeSelectOption,
    getSearchFormat,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import UserIndex from '../../../../tasks/views/user';
import ContractCategorySearch from '../contract-category/search';

const ContractCategoryRightsEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateContractAdmin();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.contractCategory) {
            dataForm.contractCategory = setSearchFormat(dataForm.contractCategory, 'contractCategory');
        }
        if (dataForm.user) {
            dataForm.user = getEmployeeSelectOption(dataForm.user);
        }
        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={geti18nText('contract.category.rights.edit.new')}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CONTRACT_CATEGORY_RIGHTS.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.user) {
                    values.user = getSearchFormat(values.user);
                }
                if (values.contractCategory) {
                    values.contractCategory = getSearchFormat(values.contractCategory);
                }
                delete values.validFrom;
                delete values.validTo;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('contract.category.rights.edit.user')}
                        name="user"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            style={{ width: '100%' }}
                            placeholder={geti18nText('travelWarrant.edit.employee')}
                            addNewItem={false}
                            order="person.last_name, person.first_name"
                            setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                            disabled={dataForm != null}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <ContractCategorySearch disabled={dataForm != null} required={true} />
                </Col>
            </Row>
            {dataForm != null && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('contract.category.rights.edit.validFrom')} name="validFrom">
                            <NyDatePicker style={{ width: '100%' }} nyTestId="validFrom" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('contract.category.rights.edit.validTo')} name="validTo">
                            <NyDatePicker style={{ width: '100%' }} nyTestId="validTo" disabled />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </NyDataEdit>
    );
};

export default ContractCategoryRightsEdit;
