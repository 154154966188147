import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import HumanCodebooksSearch from '../../../../components/human-codebooks/search';
import useEnum from '../../../../hooks/useEnum';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
    let inputNode: any = undefined;
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    switch (inputType) {
        case 'language':
            inputNode = (
                <HumanCodebooksSearch
                    required={true}
                    style={{
                        margin: 0,
                    }}
                    allowClear={false}
                    formItemName={dataIndex}
                    codebooksType={enCodebookType.LANGUAGE}
                />
            );
            break;
        case 'languageLevel':
            inputNode = (
                <HumanCodebooksSearch
                    required={true}
                    style={{
                        margin: 0,
                    }}
                    allowClear={false}
                    formItemName={dataIndex}
                    codebooksType={enCodebookType.LANGUAGE_LEVEL}
                />
            );
            break;
        case 'skillLevel':
            inputNode = (
                <HumanCodebooksSearch
                    required={true}
                    style={{
                        margin: 0,
                    }}
                    allowClear={false}
                    formItemName={dataIndex}
                    codebooksType={enCodebookType.KNOWLEDGE_ADN_SKILLS_LEVEL}
                />
            );
            break;
        case 'programLevel':
            inputNode = (
                <HumanCodebooksSearch
                    required={true}
                    style={{
                        margin: 0,
                    }}
                    allowClear={false}
                    formItemName={dataIndex}
                    codebooksType={enCodebookType.PROGRAM_LEVEL}
                />
            );
            break;
        default:
            inputNode = (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: geti18nText('app.default.required'),
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            );
    }

    return <td {...restProps}>{editing ? inputNode : children}</td>;
};

export default EditableCell;
