import { InfoCircleOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Descriptions, Popover } from 'antd';
import React from 'react';
import { getDateFormat } from '../../utils/Utils';

const AdditionalInfo = ({
    created,
    createdBy,
    updated,
    updatedBy,
    format = 'DD.MM.YYYY. HH:mm',
    placement = 'top',
    buttonStyle = {},
}: any) => {
    const displayAdditionalInfo = (
        <div style={{ width: '500px' }}>
            <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                <Descriptions.Item label={geti18nText('task.typeCategory.table.column.created')}>
                    {created && getDateFormat(created, format)}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('task.typeCategory.table.column.createdBy')}>
                    {createdBy && createdBy.person && createdBy.person.firstName + ' ' + createdBy.person.lastName}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('task.typeCategory.table.column.updated')}>
                    {updated && getDateFormat(updated, format)}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('task.typeCategory.table.column.updatedBy')}>
                    {updatedBy && updatedBy.person && updatedBy.person.firstName + ' ' + updatedBy.person.lastName}
                </Descriptions.Item>
            </Descriptions>
        </div>
    );

    return (
        <React.Fragment>
            <>
                <Popover
                    content={displayAdditionalInfo}
                    title={geti18nText('task.typeCategory.edit.additionalInfo')}
                    placement={placement}
                    style={{ width: '50%' }}
                >
                    <Button icon={<InfoCircleOutlined />} style={buttonStyle} />
                </Popover>
            </>
        </React.Fragment>
    );
};

export default AdditionalInfo;
