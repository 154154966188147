import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import EmployeeGroupIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const EmployeeGroupSearch = ({
    autoFocus = false,
    className = undefined,
    disabled = false,
    label = geti18nText('attendance.addAttendance.employeeGroup'),
    mode = 'default',
    name = 'employeeGroup',
    required = false,
    SearchPopupComponent = true,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.EMPLOYEE_GROUP.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                mode={mode}
                autoFocus={autoFocus}
                SearchPopupComponent={SearchPopupComponent ? <EmployeeGroupIndex hideNewButton /> : null}
                nyTestId="employee-group"
                disabled={disabled}
                className={className}
            />
        </Form.Item>
    );
};
export default EmployeeGroupSearch;
