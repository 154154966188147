import { geti18nText, NyDatePicker } from '@nybble/nyreact';
import { Row, Col, Form, Typography } from 'antd';
import { getDateFormat } from '../../utils/Utils';

const { Text } = Typography;

const TravelWarrantTypeDates = (
    includeEducationDate: any,
    disabledendEduDate: any = false,
    form: any,
    wizardNew = true,
    disabled = false,
    defaultEndDate: any,
    setDefaultEndDate: any,
    rerenderKey: any,
    setRerenderKey: any
) => {
    const educationStartDate = getDateFormat(form.getFieldValue('educationStartDate'), 'DD.MM.yyyy.');
    const educationEndDate = getDateFormat(form.getFieldValue('educationEndDate'), 'DD.MM.yyyy.');

    const onStartDateChange = (value: any) => {
        setDefaultEndDate(value);
        setRerenderKey((prevKey: any) => prevKey + 1);
    };

    return wizardNew ? (
        <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={form}>
            <Col span={24}>
                <Form.Item
                    label={geti18nText('travelWarrantType.edit.dateFrom')}
                    name="educationStartDate"
                    rules={[
                        {
                            required: includeEducationDate,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    <NyDatePicker
                        autoFocus
                        style={{ width: '100%' }}
                        nyTestId="educationStartDate"
                        mustGetPopupContainer={false}
                        onChange={onStartDateChange}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label={geti18nText('travelWarrantType.edit.dateTo')}
                    name="educationEndDate"
                    rules={[
                        {
                            required: includeEducationDate,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    <NyDatePicker
                        defaultPickerValue={defaultEndDate}
                        key={rerenderKey}
                        style={{ width: '100%' }}
                        nyTestId="educationEndDate"
                        disabledDate={disabledendEduDate}
                        mustGetPopupContainer={false}
                    />
                </Form.Item>
            </Col>
        </Form>
    ) : (
        <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={form}>
            <Row gutter={24}>
                <Col span={24}>
                    {disabled ? (
                        <>
                            <div style={{ width: 'fit-content', marginRight: '8px', marginTop: '4px' }}>
                                {geti18nText('employeeAnnualLeave.report.dateFrom')}
                            </div>
                            <Form.Item name="educationStartDate">
                                <Text strong>{educationStartDate}</Text>
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.report.dateFrom')}
                            name="educationStartDate"
                            rules={[
                                {
                                    required: includeEducationDate,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                autoFocus
                                style={{ width: '100%' }}
                                nyTestId="educationStartDate"
                                mustGetPopupContainer={false}
                                onChange={onStartDateChange}
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    {disabled ? (
                        <>
                            <div style={{ width: 'fit-content', marginRight: '8px', marginTop: '4px' }}>
                                {geti18nText('employeeAnnualLeave.report.dateTo')}
                            </div>
                            <Form.Item name="educationEndDate">
                                <Text strong>{educationEndDate}</Text>
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.report.dateTo')}
                            name="educationEndDate"
                            rules={[
                                {
                                    required: includeEducationDate,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                defaultPickerValue={defaultEndDate}
                                key={rerenderKey}
                                style={{ width: '100%' }}
                                nyTestId="educationEndDate"
                                disabledDate={disabledendEduDate}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default TravelWarrantTypeDates;
