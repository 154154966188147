import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, PlusCircleOutlined, SaveTwoTone } from '@ant-design/icons';
import { NyModalConfirm, NyUtils, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Row, Space, Statistic, Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { numberFormat, warningNotification } from '../../../../../../utils/Utils';
import EditableCell from './EditableCell';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import useApplicationSetting from '../../../../../../hooks/useApplicationSetting';
const { Text } = Typography;

const EducationEstimatedExpenses = ({ items, setItems, disabled = false }: any) => {
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');
    const [total, setTotal] = useState<any>(0);
    const [tempId, setTempId] = useState<any>(0);
    const isEditing = (record: any) => record.tempId === editingKey;

    useEffect(() => {
        setTotal(getTotal());
    }, [items]);

    const getTotal = () => {
        let total = 0;
        if (items && items.length > 0) {
            items.map((item: any) => {
                total = total + (item?.amount ?? 0);
            });
        }
        return total;
    };

    const columns: any = [
        ...[
            {
                title: geti18nText('employee.education.realization.table.type'),
                dataIndex: 'type',
                inputType: 'type',
                width: '50%',
                editable: true,
                render: (type: any, record: any) => {
                    return type?.name;
                },
            },
            {
                title: geti18nText('employee.education.realization.table.amount'),
                dataIndex: 'amount',
                inputType: 'amount',
                width: '30%',
                align: 'right',
                editable: true,
                render: (amount: any) => {
                    return <div style={{ float: 'right' }}>{numberFormat(amount ?? 0)}</div>;
                },
            },
            {
                title: geti18nText('employee.education.realization.table.currency'),
                dataIndex: 'currency',
                width: '15%',
                editable: false,
                render: (currency: any, record: any) => {
                    const editable = isEditing(record);
                    return <div style={{ marginTop: editable ? '10px' : '0px' }}>{defaultCurrencyISO}</div>;
                },
            },
        ],
        ...(!disabled
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '20%',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
        ...[],
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: form,
                };
            },
        };
    });

    const addNewRow = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        form.resetFields();
        let newId =
            items.length > 0
                ? items.reduce(
                      (max: any, element: any) => (element.tempId > max ? element.tempId : max),
                      items[0].tempId
                  ) + 1
                : tempId + 1;
        setTempId(newId);
        const newData = {
            tempId: newId,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };
        form.setFieldsValue(editForm);
        setEditingKey(record.tempId);
    };

    const deleteRow = (row: any) => {
        if (row?.tempId != undefined && row?.tempId >= 0) {
            setItems(items.filter((element: any) => row.tempId !== element.tempId));
        }
    };

    const cancel = (record: any) => {
        setEditingKey('');
        !record?.id && deleteRow(record);
        setAddItemInProcess(false);
    };

    const save = async (record: any) => {
        try {
            let row = await form.validateFields();
            row.tempId = record?.tempId ?? row.tempId;
            if (row?.type?.id < 1) {
                warningNotification(geti18nText('app.default.required.fields'));
            } else {
                const newData = [...items];
                let index: any;

                if (row.tempId == undefined || row.tempId == null) {
                    let tmpIdValue = tempId + 1;
                    setTempId(tmpIdValue);
                    row.tempId = tmpIdValue;
                }
                index = newData.findIndex((object) => object.tempId === row.tempId);

                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...row });
                } else {
                    newData.push(row);
                }

                setItems(newData);
                setEditingKey('');
                setAddItemInProcess(false);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record)}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <NyModalConfirm
                        title={geti18nText('app.default.cancel.confirm')}
                        onConfirm={() => {
                            cancel(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                            <Button type="link">
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                    {record ? (
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <div style={{ width: '44px' }}></div>
                    )}
                    <NyModalConfirm
                        title={geti18nText('app.default.delete.confirm')}
                        onConfirm={() => {
                            deleteRow(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <Button type="link">
                                <DeleteTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#ff7875"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    return (
        <>
            <Form
                layout="vertical"
                form={form}
                onFinishFailed={({ errorFields }) => {
                    form.scrollToField(errorFields[0].name);
                }}
            >
                {!disabled && (
                    <Row gutter={24} style={{ height: '36px' }}>
                        <Col span={24}>
                            <Button
                                style={{ float: 'right', marginRight: '12px' }}
                                icon={<PlusCircleOutlined />}
                                onClick={addNewRow}
                                disabled={addItemInProcess}
                            >
                                {geti18nText('employee.education.realization.table.button.addItem')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <div
                    style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        cursor: 'default',
                    }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form form={form}>
                                <Table
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    size={'small'}
                                    dataSource={items}
                                    columns={mergedColumns}
                                    rowClassName="editable-row"
                                    pagination={false}
                                    summary={(pageData) => {
                                        return (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={1} index={0}>
                                                    <strong>
                                                        {geti18nText(
                                                            'employee.education.realization.table.totalAmount'
                                                        )}
                                                    </strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1} className="rightAlign">
                                                    <Text
                                                        style={{
                                                            color: isDarkTheme ? 'white' : 'black',
                                                            float: 'right',
                                                            fontWeight: 'bold',
                                                            textAlign: 'right',
                                                            marginRight: '-1px',
                                                        }}
                                                    >
                                                        {NyUtils.formatNumber(total ?? 0, 2)}
                                                    </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Text
                                                        style={{
                                                            color: isDarkTheme ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        EUR
                                                    </Text>
                                                </Table.Summary.Cell>
                                                {!disabled && <Table.Summary.Cell index={0}></Table.Summary.Cell>}
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    );
};

export default EducationEstimatedExpenses;
