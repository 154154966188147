import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArray } from '../../../../utils/Utils';
import WarehouseDocumentEdit from './edit';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';

const WarehouseDocumentIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    type = undefined,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    defaultFilterValue,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('warehouse/codebooks/warehouse_document');

    const setDefaultFilterValue = () => {
        if (defaultFilterValue) {
            return defaultFilterValue;
        } else if (type !== undefined) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: type },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseCodebooks();
    };

    const canExportCSV = () => {
        return WarehouseBookkeepingRights.canCsvExportWarehouseCodebook();
    };

    const columns = [
        {
            title: geti18nText('warehouseDocument.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseDocument.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseDocument.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseDocument.table.column.abbreviation'),
            dataIndex: 'abbreviation',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseDocument.table.column.type'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.WAREHOUSE_DOCUMENT_TYPE.' + record.type);
                }
            },
            ...(type !== undefined
                ? getColumnSearchCheckbox(
                      getEnumArray('WAREHOUSE_DOCUMENT_TYPE'),
                      'in',
                      type?.length > 0 ? type.toString() : [type].toString()
                  )
                : getColumnSearchCheckbox(getEnumArray('WAREHOUSE_DOCUMENT_TYPE'))),
        },
        {
            title: geti18nText('warehouseDocument.table.column.active'),
            dataIndex: 'active',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="warehouse-warehouse-documents"
            url={CONSTANTS_REQ.WAREHOUSE_DOCUMENT.LIST}
            addNewButtonText={geti18nText('warehouseDocument.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={WarehouseDocumentEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.warehouseDocument')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.warehouseDocument')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.WAREHOUSE_DOCUMENT_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default WarehouseDocumentIndex;
