import { DeleteTwoTone, EditTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row, Space, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customAssetDepreciationGroupRenderOption,
    getAssetDepreciationGroupSelectOption,
    numberFormat,
    onKeyDownTextAreaCustom,
    onKeyDownTextAreaWarehouseCustom,
    setSearchFormat,
} from '../../../../utils/Utils';
import ItemSearch from '../../../administration/views/item/search';
import { validateNegativeNumber } from '../../../../utils/Validation';
import DepreciationGroupIndex from '../../../accounting/views/assets/asset-depreciation-group';
import AssetModelIndex from '../../../webshop/views/webshop-asset/asset-model';
import AssetModelEdit from '../../../webshop/views/webshop-asset/asset-model/edit';
import AssetTypeIndex from '../../../webshop/views/webshop-asset/asset-type';
import AssetTypeEdit from '../../../webshop/views/webshop-asset/asset-type/edit';
import ItemIndex from '../../../administration/views/item';
import DepreciationGroupEdit from '../../../accounting/views/assets/asset-depreciation-group/edit';

const WarehouseAcquisitionItems = ({ items, setItems, tempId, setTempId, disabledInventoryNumber }: any) => {
    const [form] = Form.useForm();
    const [itemForm] = Form.useForm();
    const [itemTitle, setItemTitle] = useState(geti18nText('asset.acquisition.item.table.new'));
    const [assetItemModalVisible, setAssetItemModalVisible] = useState<boolean>(false);
    const [itemDisabled, setItemDisabled] = useState<boolean>(true);

    const edit = (record: any) => {
        setItemTitle(geti18nText('asset.acquisition.item.table.new'));
        if (record.item) {
            setItemTitle(record.item.name ? record.item.name : geti18nText('asset.acquisition.item.table.new'));
            record.item = setSearchFormat(record.item, 'name', 'name');
        }
        if (record.assetDepreciationGroup) {
            record.assetDepreciationGroup = getAssetDepreciationGroupSelectOption(record.assetDepreciationGroup);
        }
        if (record.assetModel) {
            record.assetModel = setSearchFormat(record.assetModel, 'name', 'name');
        }
        if (record.assetType) {
            record.assetType = setSearchFormat(record.assetType, 'name', 'name');
        }

        itemForm.setFieldsValue(record);
        setAssetItemModalVisible(true);
    };

    const deleteRow = (row: any) => {
        if (row.id != undefined && row.id >= 0) {
            setItems(items.filter((element: any) => row.id !== element.id));
        } else if (row.tempId != undefined && row.tempId >= 0) {
            setItems(items.filter((element: any) => row.tempId !== element.tempId));
        }
    };

    const save = async () => {
        try {
            const row = await itemForm.validateFields();

            const newData = [...items];
            let index: any;
            if (row.id != undefined && row.id >= 0) {
                index = newData.findIndex((object) => object.id === row.id);
            } else {
                if (row.tempId == undefined || row.tempId == null) {
                    let tmpIdValue = tempId + 1;
                    setTempId(tmpIdValue);
                    row.tempId = tmpIdValue;
                }
                index = newData.findIndex((object) => object.tempId === row.tempId);
            }
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
            } else {
                newData.push(row);
            }

            setItems(newData);
            closeModal();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const closeModal = () => {
        setAssetItemModalVisible(false);
        setItemDisabled(true);
        itemForm.resetFields();
        form.resetFields();
    };

    const onItemChange = (value: any) => {
        if (value && value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + value.id, undefined).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    let price = result.data.price
                        ? result.data.price
                        : result.data.netPrice
                        ? result.data.netPrice
                        : result.data.invoicedPrice
                        ? result.data.invoicedPrice
                        : 0;
                    form.setFieldsValue({ price: price });
                }
            });
        }
    };

    const columns: any = [
        ...[
            {
                title: geti18nText('warehouseReceipt.acquisitionItems.item'),
                dataIndex: 'item',
                render: (text: any, record: any) => {
                    if (record.item && record.item.name) {
                        if (record.item.code) {
                            return record.item.name + ' (' + record.item.code + ')';
                        }
                        return record.item.name;
                    }
                },
            },
            {
                title: geti18nText('warehouseReceipt.acquisitionItems.quantity'),
                dataIndex: 'quantity',
                render: (quantity: any) => {
                    return quantity ? quantity : '0';
                },
            },
            {
                title: geti18nText('warehouseReceipt.acquisitionItems.price'),
                dataIndex: 'price',
                render: (price: any) => {
                    if (price) {
                        return numberFormat(price);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('warehouseReceipt.acquisitionItems.assetDepreciationGroup'),
                dataIndex: ['assetDepreciationGroup', 'name'],
                render: (text: any, record: any) => {
                    if (record.assetDepreciationGroup) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                //   overlayStyle={{ maxWidth: '300px' }}
                                title={customAssetDepreciationGroupRenderOption(record.assetDepreciationGroup)}
                            >
                                <div>
                                    <p
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginTop: '-0.4rem',
                                            marginBottom: '-0.5rem',
                                        }}
                                    >
                                        {customAssetDepreciationGroupRenderOption(record.assetDepreciationGroup)}
                                    </p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('warehouseReceipt.acquisitionItems.assetModel'),
                dataIndex: ['assetModel', 'name'],
            },
            {
                title: geti18nText('settings.shortcuts.action'),
                key: 'action',
                width: '50px',
                render: (text: any, record: any) => {
                    if (record) {
                        return (
                            <React.Fragment>
                                <Space size="middle" style={{ textAlign: 'right' }}>
                                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                        <Button type="link" onClick={() => edit(record)}>
                                            <EditTwoTone
                                                style={{
                                                    fontSize: '20px',
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                        <NyModalConfirm
                                            title={geti18nText('app.default.delete.confirm')}
                                            onConfirm={() => {
                                                deleteRow(record);
                                            }}
                                        >
                                            <Button type="link">
                                                <DeleteTwoTone
                                                    style={{
                                                        fontSize: '20px',
                                                    }}
                                                    twoToneColor="#ff7875"
                                                />
                                            </Button>
                                        </NyModalConfirm>
                                    </Tooltip>
                                </Space>
                            </React.Fragment>
                        );
                    }
                },
            },
        ],
    ];

    return (
        <React.Fragment>
            <Form form={form}>
                <Table
                    size={'small'}
                    dataSource={items}
                    rowClassName="editable-row"
                    pagination={false}
                    columns={columns}
                />
            </Form>
            <div style={{ textAlign: 'right' }}>
                <Button
                    icon={<PlusCircleOutlined />}
                    onClick={(e) => {
                        setItemDisabled(false);
                        setAssetItemModalVisible(true);
                        setItemTitle(geti18nText('asset.acquisition.item.table.new'));
                    }}
                    type="primary"
                    style={{ marginLeft: '5px', marginTop: '20px' }}
                >
                    {geti18nText('asset.acquisition.item.table.add')}
                </Button>
            </div>
            {assetItemModalVisible && (
                <Modal
                    title={itemTitle}
                    okText={geti18nText('app.default.button.save')}
                    visible={assetItemModalVisible}
                    onOk={save}
                    width={600}
                    maskClosable={false}
                    onCancel={closeModal}
                >
                    <Form form={itemForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="tempId" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Col span={12}>
                                <ItemSearch
                                    name={'item'}
                                    map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                    searchBy="name, code"
                                    itemName={[['text'], ['code']]}
                                    customItemNameLabel={'(code) text'}
                                    renderOption={(option: any) =>
                                        `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                                    }
                                    disabled={itemDisabled}
                                    onChange={onItemChange}
                                />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseReceipt.acquisitionItems.quantity')}
                                    name="quantity"
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber disabled={itemDisabled} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseReceipt.acquisitionItems.price')}
                                    name="price"
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                        {
                                            validator: validateNegativeNumber,
                                        },
                                    ]}
                                >
                                    <NyInputNumber disabled={true} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseReceipt.acquisitionItems.assetDepreciationGroup')}
                                    name={'assetDepreciationGroup'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'name',
                                            depreciationRate: 'depreciationRate',
                                            code: 'code',
                                        }}
                                        searchBy="_adg.name || _adg.code"
                                        itemName="name"
                                        customItemNameLabel={'(depreciationRate) code name '}
                                        renderOption={customAssetDepreciationGroupRenderOption}
                                        SearchPopupComponent={<DepreciationGroupIndex />}
                                        AddNewModalComponent={DepreciationGroupEdit}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseReceipt.acquisitionItems.assetModel')}
                                    name={'assetModel'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.ASSET_MODEL.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        AddNewModalComponent={AssetModelEdit}
                                        SearchPopupComponent={<AssetModelIndex />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('warehouseReceipt.acquisitionItems.assetType')}
                                    name={'assetType'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        AddNewModalComponent={AssetTypeEdit}
                                        SearchPopupComponent={<AssetTypeIndex />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('warehouseReceipt.acquisitionItems.inventoryNumbers')}
                                    name={'inventoryNumbers'}
                                    rules={[
                                        {
                                            required: !disabledInventoryNumber,
                                            message: geti18nText('app.default.required'),
                                            whitespace: !disabledInventoryNumber,
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        disabled={disabledInventoryNumber}
                                        autoSize={{ minRows: 2, maxRows: 3 }}
                                        onKeyDown={onKeyDownTextAreaWarehouseCustom}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default WarehouseAcquisitionItems;
