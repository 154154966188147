import { NyDataEdit, NyDatePicker, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, getSearchFormat, setDateFormat, setSearchFormat } from '../../../../utils/Utils';

const RewardTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('reward.type.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [dateFrom, setDateFrom] = useState<any>(null);
    const [dateTo, setDateTo] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        setEditHeader(geti18nText('reward.type.edit.title'));
        if (dataForm.rewardType) {
            dataForm.rewardType = setSearchFormat(dataForm.rewardType, 'description');
        }
        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
            setDateFrom(dataForm.dateFrom);
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
            setDateTo(dataForm.dateTo);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setEditHeader(geti18nText('reward.type.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setDataForm(null);
        setDateTo(null);
    };

    const onEndDateChange = (value: any) => {
        setDateTo(value ? moment(value) : moment());
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setDateFrom(value ? moment(value) : moment());
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.REWARD_TYPE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.rewardType) {
                    values.rewardType = getSearchFormat(values.rewardType);
                }
                if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('reward.type.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('reward.type.edit.code')}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('reward.type.edit.dateFrom')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        name="dateFrom"
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onStartDateChange}
                            disabledDate={disabledStartDate}
                            defaultPickerValue={dateFrom}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('reward.type.edit.dateTo')}
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        name="dateTo"
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onEndDateChange}
                            disabledDate={disabledendDate}
                            defaultPickerValue={dateTo}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default RewardTypeEdit;
