import { FilePdfOutlined } from '@ant-design/icons';
import { NyDataTable, NySession, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Button, Modal, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import RowAction from '../../../../components/row-action';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookWorkplace,
    getColumnSearchByCodebookEmployeeWithAlias,
} from '../../../../utils/Filters';
import { fileDownload } from '../../../../utils/Utils';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const EmployeeTable = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const table = useTableSettings();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [employeeId, setEmployeeId] = useState<any>(null);
    const [refresh, setRefresh] = useState(1);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const canCreate = () => {
        return MobilizationRights.canCreateMobilization();
    };

    const openModal = (recordId: any) => {
        setEmployeeId(recordId);
        setModalVisible(true);
    };

    const columns = [
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                        record.businessUnit.name
                    );
                }
            },
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.work.place'),
            dataIndex: ['vocation', 'name'],
            render: (text: any, record: any) => {
                if (record?.workplaceVocation?.name) {
                    return (
                        <div>
                               {record?.workplaceVocation?.name}
                               {record?.workplaceVocationDescription?.name ? ' - ' + record?.workplaceVocationDescription?.name : ' '}
                        </div>
                    );
                }
            },
            sorter: (a: any, b: any) => {},//here custom
            ...getColumnSearchByCodebookWorkplace(),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.name.lastname'),
            dataIndex: "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name) ",
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee) {
                    return (
                        <a
                            style={{ textDecoration: 'underline' }}
                            onClick={() => systematizationModalVisible(record.employee)}
                        >
                            {(record.employee.person.lastName ? record.employee.person.lastName + ', ' : '') +
                                (record.employee.person.parentName ? record.employee.person.parentName + ', ' : '') +
                                (record.employee.person.firstName ? record.employee.person.firstName : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                return <div>{record?.employee?.person?.oib}</div>;
            },
        },
        {
            title: geti18nText('employeeMilitaryRecords.signed.mobilizationCall'),
            dataIndex: 'file_id',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record.file_id && (
                                <Tooltip title={geti18nText('app.default.download')}>
                                    <Button
                                        icon={<FilePdfOutlined />}
                                        onClick={() => {
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.file_id,
                                                null,
                                                geti18nText('employeeMilitaryRecords.signed.mobilizationCall')
                                            );
                                        }}
                                    >
                                        {geti18nText('app.default.download')}
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record && canCreate()) {
                    return <RowAction component={'MobilizationCall'} record={record} doAction={openModal}></RowAction>;
                }
            },
        },
    ];

    const setDefaultFilterValueFiles = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'ref_id', condition: 'equals', value: employeeId },
            { field: 'employee_id', condition: 'equals', value: employeeId },
            { field: 'document_type', condition: 'equals', value: documentTypeEnum.MOBILIZATION_EXECUTION },
        ];
    };

    const closeModal = () => {
        setRefresh((refresh) => refresh + 1);
        setModalVisible(false);
        setEmployeeId(null);
    };

    const systematizationModalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    return (
        <>
            <NyDataTable
                nyId="human-employee-table"
                url={CONSTANTS_REQ.EMPLOYEE_MILITARY_RECORDS.EMPLOYEE_LIST}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                shortcuts={true}
                hideNewButton={true}
                fetchWhenChange={refresh}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
            <Modal
                visible={modalVisible}
                title={geti18nText('employeeMilitaryRecords.upload.signed.mobilizationCall')}
                maskClosable={false}
                onOk={() => {
                    if (uploadFilesRef?.current) {
                        uploadFilesRef.current.filesUpload(employeeId);
                    }
                    closeModal();
                }}
                onCancel={closeModal}
                forceRender={true}
                closable={false}
                width={900}
                okText={geti18nText('app.default.button.save')}
            >
                {employeeId && (
                    <NyFilesUpload
                        documentType={documentTypeEnum.MOBILIZATION_EXECUTION}
                        url={CONSTANTS_REQ.EMPLOYEE_FILE.LIST}
                        editUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                        defaultFilesFilter={setDefaultFilterValueFiles()}
                        ref={uploadFilesRef}
                        canAddNewFile={canCreate()}
                        canDelete={canCreate()}
                        canEdit={canCreate()}
                        id={employeeId}
                        refId={employeeId}
                        employee={employee}
                        maxHeight={'205px'}
                    />
                )}
            </Modal>
        </>
    );
};
export default EmployeeTable;
