import { SearchOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const ModulesEditTab = ({
    modules,
    userId,
    triggerSaveModules,
    setTriggerCloseModal,
    setFetchUsersGroupsWhenChange,
}: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    return (
        <NyDataEdit
            paramsId={'create'}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.USER_AUTH.ADD_USER_TO_GROUPS}
            form={form}
            hideCancelButton={true}
            hideSubmitButton={true}
            triggerSave={triggerSaveModules}
            setValues={undefined}
            setData={undefined}
            normalize={(values: any) => {
                const userGroups = Object.entries(values)
                    .filter((value) => {
                        return value[1];
                    })
                    .map((value) => {
                        return Number(value[0]);
                    });
                values = { userId: userId, groups: userGroups };
                return values;
            }}
            onSaveAndGetData={() => {
                setTriggerCloseModal((prev: number) => prev + 1);
                setFetchUsersGroupsWhenChange((prev: number) => prev + 1);
            }}
        >
            <Form.Item name={'search'}>
                <Input
                    suffix={<SearchOutlined />}
                    onChange={(event: any) => {
                        setSearch(event.target.value);
                    }}
                    placeholder={geti18nText('app.default.button.search')}
                />
            </Form.Item>
            {modules.map((module: any) => {
                return (
                    <>
                        <Divider>{module.name}</Divider>
                        {module.groupList?.map((group: any) => {
                            return (
                                <Row
                                    gutter={24}
                                    style={{
                                        display: group.name.toLowerCase().includes(search.toLowerCase()) ? '' : 'none',
                                    }}
                                >
                                    <Col span={24}>
                                        <Form.Item
                                            name={group.id}
                                            valuePropName={'checked'}
                                            initialValue={group.userId !== undefined}
                                        >
                                            <Checkbox defaultChecked={group.userId !== undefined}>
                                                {group?.key != null && group?.key != ''
                                                    ? geti18nText(group?.key)
                                                    : group.name}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                );
            })}
        </NyDataEdit>
    );
};
export default ModulesEditTab;
