import { NyDataEdit, NyInputNumber, NySearchField, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { formatJoppdCodeSearch, getEnumArray, setJoppdCodeDefaultFilterValue } from '../../../../utils/Utils';

const SickLeaveEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('sickLeave.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [disabled, setDisabled] = useState(false);

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '15.1.');
        }
        if (dataForm.joppdEmployeeCode) {
            dataForm.joppdEmployeeCode = await formatJoppdCodeSearch(dataForm.joppdEmployeeCode, 2, '6.1.');
        }
        if (dataForm.joppdIncomeCode) {
            dataForm.joppdIncomeCode = await formatJoppdCodeSearch(dataForm.joppdIncomeCode, 2, '6.2.');
        }
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('sickLeave.edit.title') + ' - ' + dataForm.name);
        }
        setDisabled(dataForm.founded);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('sickLeave.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };
    const setDefaultFilterValue = (type: String) => {
        return setJoppdCodeDefaultFilterValue(2, type);
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.SICK_LEAVE.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                values.founded = values.founded ?? false;
                values.limit = values.limit ?? false;
                if (values.joppdCode) values.joppdCode = values.joppdCode.code;
                if (values.joppdEmployeeCode) values.joppdEmployeeCode = values.joppdEmployeeCode.code;
                if (values.joppdIncomeCode) values.joppdIncomeCode = values.joppdIncomeCode.code;
                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('sickLeave.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('sickLeave.edit.paidPercent')}
                        name="paidPercent"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} nyTestId="paid-percent" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.remittance')} name="remittance">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.regosCode')} name="regosCode">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.joppdCode')} name="joppdCode">
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={() => setDefaultFilterValue('15.1.')}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppdCode"
                            SearchPopupComponent={
                                <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValue('15.1.')} />
                            }
                            itemName="code"
                            order="code"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.joppdEmployeeCode')} name="joppdEmployeeCode">
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={() => setDefaultFilterValue('6.1.')}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppdEmployeeCode"
                            SearchPopupComponent={
                                <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValue('6.1.')} />
                            }
                            itemName="code"
                            order="code"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.joppdIncomeCode')} name="joppdIncomeCode">
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={() => setDefaultFilterValue('6.2.')}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppdIncomeCode"
                            SearchPopupComponent={
                                <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValue('6.2.')} />
                            }
                            itemName="code"
                            order="code"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.founded')} name="founded" valuePropName="checked">
                        <Checkbox
                            onChange={(val: any) => {
                                form.setFieldsValue({ limit: false, foundationType: null });
                                setDisabled(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('sickLeave.edit.foundationType')}
                        name="foundationType"
                        rules={[
                            {
                                required: disabled,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Select disabled={!disabled} options={getEnumArray('FOUNDATION_TYPE', 'label', 'value')} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('sickLeave.edit.limit')} name="limit" valuePropName="checked">
                        <Checkbox disabled={!disabled} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default SickLeaveEdit;
