import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnSearch,
    getColumnSearchByCodebook,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { useState } from 'react';
import { Empty, Radio } from 'antd';
import React from 'react';
import BusinessUnitsGraph from '../../../../components/business-units-graph';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import NyReportButton from '../../../../components/report-button';
import useEnum from '../../../../hooks/useEnum';

const WarBusinessUnitIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = { y: 510, x: 800 },
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();
    useHelper('common/business_unit');

    const [activeList, setActiveList] = useState<any>(1);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        // dispatch(setProp(e.target.value > 0 ? e.target.value : 1));
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const columns = [
        {
            title: geti18nText('businessUnit.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('war.businessUnit.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('businessUnit.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    const addedButtons = () => {
        return (
            <div style={{ float: 'left' }}>
                {HumanResourcesRights.canGenerateBusinessUnitPortfolioGraph() && (
                    <NyReportButton
                        url={CONSTANTS_REQ.REPORT.BUSINESS_UNIT}
                        subreportType={'BUSINESS_UNIT'}
                        buttoni18nText={'businessUnit.button.generateReport.action'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.BUSINESS_UNIT)}
                    />
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            <Radio.Group
                value={activeList}
                defaultValue={1}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('app.default.button.table')}
                </Radio.Button>
                <Radio.Button value={2} onChange={onChangeActiveList}>
                    {geti18nText('app.default.button.graph')}
                </Radio.Button>
            </Radio.Group>

            {activeList == 1 ? (
                <NyDataTable
                    nyId="human-mobilization-business-unit-table"
                    url={CONSTANTS_REQ.BUSINESS_UNIT.LIST}
                    addNewButtonText={geti18nText('businessUnit.table.add')}
                    buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                    columns={columns}
                    // hideButtons
                    hideNewButton
                    setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                    scroll={scroll}
                    onDataLoaded={table.onDataLoaded}
                    readonly
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    shortcuts={true}
                    exportCSV={canExportCSV()}
                    CSVFileName={geti18nText('menu.businessUnit')}
                    colCSVCustomization={csvColumnCustomisation()}
                    headerTitle={geti18nText('menu.businessUnit')}
                    addedButtons={addedButtons}
                />
            ) : activeList == 2 ? (
                <BusinessUnitsGraph />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default WarBusinessUnitIndex;
