import { NySession } from '@nybble/nyreact';
import { userIsNotificationRecipient } from '../utils/Utils';
export const TravelWarrantRights = (function () {
    return {
        canViewTravelWarrantAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_VIEW']);
        },
        canCreateTravelWarrantAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_EDIT']);
        },
        canCreateTravelWarrantPersonal: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']);
        },
        canCreateOnlyTravelWarrantPersonal: () => {
            return NySession.hasAnyRole(['ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']);
        },
        canViewCo2: (status: any, employee: any, travelWarrantStatusEnum: any, notificationType: any) => {
            return (
                (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                    TravelWarrantRights.canViewTravelWarrantAdmin() ||
                    (employee &&
                        (userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION) ||
                            userIsNotificationRecipient(
                                employee,
                                notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT
                            ) ||
                            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL)))) &&
                status &&
                travelWarrantStatusEnum &&
                !Object.values([
                    travelWarrantStatusEnum.DRAFT,
                    travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
                    travelWarrantStatusEnum.APPROVED,
                    travelWarrantStatusEnum.REJECTED,
                    travelWarrantStatusEnum.WAITING_FOR_CANCELLATION,
                    travelWarrantStatusEnum.CANCELLED,
                ]).includes(status)
            );
        },
        canCreateCo2: (status: any, employee: any, travelWarrantStatusEnum: any, notificationType: any) => {
            return (
                (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                    (employee &&
                        userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION))) &&
                status &&
                travelWarrantStatusEnum &&
                !Object.values([
                    travelWarrantStatusEnum.DRAFT,
                    travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
                    travelWarrantStatusEnum.APPROVED,
                    travelWarrantStatusEnum.REJECTED,
                    travelWarrantStatusEnum.WAITING_FOR_CANCELLATION,
                    travelWarrantStatusEnum.CANCELLED,
                    travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL,
                ]).includes(status)
            );
        },
        canCreateTravelWarrantCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_CODEBOOKS_EDIT']);
        },
        canCreateAppSettings: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_APPLICATION_SETTINGS_EDIT', 'ROLE_APPLICATION_SETTINGS']);
        },
        canOpenTravelWarrantWage: (employee: any, notificationType: any) => {
            return (
                TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                (NySession.hasAnyRole(['ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']) &&
                    userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT))
            );
        },
        canPointBookAdmin: () => {
            return NySession.hasAnyRole(['ROLE_POINT_BOOKING']);
        },
        //files
        canCreateTravelWarrantUserFiles: (employeeId: any, travelWarrantEmployeeId: any, moduleAccessRight: any) => {
            return (
                (employeeId &&
                    travelWarrantEmployeeId &&
                    employeeId == travelWarrantEmployeeId &&
                    TravelWarrantRights.canCreateTravelWarrantUser()) ||
                (employeeId &&
                    travelWarrantEmployeeId &&
                    moduleAccessRight &&
                    moduleAccessRight.includes('TRAVEL_WARRANT'))
            );
        },
        canCreateTravelWarrantActions: (employee: any, notificationType: any) => {
            return (
                TravelWarrantRights.canCreateTravelWarrantPersonal() &&
                employee &&
                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL)
            );
        },
        canCreateTravelWarrantOrganizator: (employee: any, notificationType: any) => {
            return (
                TravelWarrantRights.canCreateTravelWarrantPersonal() &&
                employee &&
                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION)
            );
        },
        canCreateTravelWarrantCompensator: (employee: any, notificationType: any) => {
            return (
                TravelWarrantRights.canCreateTravelWarrantPersonal() &&
                employee &&
                userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
            );
        },
        disablePriceEdit: (employee: any, notificationType: any) => {
            return !(
                NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_EDIT']) ||
                (NySession.hasAnyRole(['ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']) &&
                    userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT))
            );
        },
        canSeePaymentCheckBox: (employee: any, notificationType: any) => {
            return (
                NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_EDIT']) ||
                (NySession.hasAnyRole(['ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']) &&
                    userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT))
            );
        },
        //travel vehicle
        canCreateTravelVehicle: () => {
            return NySession.hasAnyRole([
                'ROLE_ADMIN',
                'ROLE_TRAVEL_CODEBOOKS_EDIT',
                'ROLE_TRAVEL_WARRANT_EDIT',
                'ROLE_TRAVEL_VEHICLE_PERSONAL_ADD',
            ]);
        },
        canEditTravelVehicle: () => {
            return NySession.hasAnyRole([
                'ROLE_ADMIN',
                'ROLE_TRAVEL_CODEBOOKS_EDIT',
                'ROLE_TRAVEL_WARRANT_EDIT',
                'ROLE_TRAVEL_VEHICLE_PERSONAL_EDIT',
            ]);
        },
        canViewTravelWarrantUser: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_PERSONAL_VIEW']);
        },
        canCreateTravelWarrantUser: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']);
        },
        canCreateTravelWarrantOnlyUser: () => {
            return NySession.hasAnyRole(['ROLE_TRAVEL_WARRANT_PERSONAL_EDIT']);
        },
        canCreateTravelWarrant: () => {
            return (
                TravelWarrantRights.canCreateTravelWarrantAdmin() || TravelWarrantRights.canCreateTravelWarrantUser()
            );
        },
        canViewOrCreateTravelWarrant: () => {
            return (
                TravelWarrantRights.canViewTravelWarrantAdmin() ||
                TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                TravelWarrantRights.canViewTravelWarrantUser() ||
                TravelWarrantRights.canCreateTravelWarrantUser()
            );
        },
        canCreateTravelWarrantAll: () => {
            return (
                TravelWarrantRights.canViewTravelWarrantAdmin() ||
                TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                TravelWarrantRights.canCreateTravelWarrantUser()
            );
        },
        //codebooks
        canCsvExportTravelWarrantCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_CODEBOOKS_EXPORT']);
        },
        //settings
        canCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_SETTINGS']);
        },
        canOnlyCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_TRAVEL_SETTINGS']);
        },
        //travel local
        canCreateTravelLocal: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TRAVEL_LOCAL_EDIT']);
        },
        ///////////////////
        //menu
        canViewTravelWarrantModuleMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_TRAVEL_CODEBOOKS_MENU',
                'ROLE_TRAVEL_WARRANT_MENU',
                'ROLE_TRAVEL_LOCAL_MENU',
                'ROLE_TRAVEL_WARRANT_PERSONAL_MENU',
                'ROLE_TRAVEL_SETTINGS',
            ];
        },
        canViewTravelWarrantMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_MENU', 'ROLE_TRAVEL_WARRANT_PERSONAL_MENU'];
        },
        canViewTravelLocalMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_LOCAL_MENU'];
        },
        canViewTravelCostMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_COSTS_MENU'];
        },
        canViewTravelCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_CODEBOOKS_MENU'];
        },
        canViewTravelSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_SETTINGS', 'ROLE_APPLICATION_SETTINGS'];
        },
        canViewTravelSettingsOnlyMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_SETTINGS'];
        },
        //routes
        canViewTravelWarrantRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_VIEW', 'ROLE_TRAVEL_WARRANT_PERSONAL_VIEW'];
        },
        canViewTravelLocalRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_LOCAL_VIEW'];
        },
        canViewTravelCostsRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_COSTS_VIEW'];
        },
        canViewTravelCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_CODEBOOKS_VIEW'];
        },
        canViewTravelWarrantAdminRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_VIEW'];
        },
        canViewTravelWarrantUserRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_TRAVEL_WARRANT_PERSONAL_VIEW'];
        },
    };
})();
