import { BookOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Modal, notification, Row } from 'antd';
import { useState } from 'react';
import NyErrorLogModal from '../../../../../components/error-log-modal';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNodeNotification, errorNotification, infoNotification, okNotification } from '../../../../../utils/Utils';
import DepreciationGroupIndex from '../asset-depreciation-group';
import AssetModelIndex from '../../../../webshop/views/webshop-asset/asset-model';
import BusinessUnitIndex from '../../../../human/views/business-unit';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';

const AddAssetsIntoCalculationModal = ({ dataForm, setLoading, updateTable, setUpdateTable, iconStyle }: any) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [unamortizedOnly, setunamortizedOnly] = useState<any>(false);
    const [errorLogModalData, setErrorLogModalData] = useState([]);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const addAssetIntoCalculation = () => {
        if (dataForm) {
            setLoading(true);
            form.validateFields().then((values: any) => {
                values.assetsIds = [];
                if (values.assets) {
                    values.assetsIds = values.assets.map((val: any) => val.id);
                    delete values.assets;
                }
                const assetDepreciationId = dataForm ? dataForm : null;
                const assetTypeId =
                    values.assetType != null && values.assetType.id != null && values.assetType.id > 0
                        ? values.assetType.id
                        : null;
                // values.assetModel != null && values.assetModel.id != null && values.assetModel.id > 0
                //     ? values.assetModel.id
                //     : null;
                const assetDepreciationGroupId =
                    values.depreciationRateGroup != null &&
                    values.depreciationRateGroup.id != null &&
                    values.depreciationRateGroup.id > 0
                        ? values.depreciationRateGroup.id
                        : null;
                const businessUnitId =
                    values.businessUnit != null && values.businessUnit.id != null && values.businessUnit.id > 0
                        ? values.businessUnit.id
                        : null;

                const unamortizedOnly = values.unamortizedOnly != null ? values.unamortizedOnly : 0;

                NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_DEPRECIATION.ADD_MULTIPLE_ITEMS, undefined, {
                    assetDepreciationId: assetDepreciationId,
                    assetTypeId: assetTypeId,
                    assetDepreciationGroupId: assetDepreciationGroupId,
                    businessUnitId: businessUnitId,
                    unamortizedOnly: unamortizedOnly,
                }).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        setUpdateTable && setUpdateTable((updateTable: any) => updateTable + 1);
                        okNotification(geti18nText('asset.depreciation.button.addAssetToCalculation.success'));
                        closeModal();
                    } else if (result && result.status === RESPONSE.OK) {
                        infoNotification(geti18nText('asset.depreciation.button.addAssetToCalculation.notFound'));
                    } else {
                        if (result.data && result.data.error && result.data.error.length > 0) {
                            let data = JSON.parse(result.data.error);
                            errorNodeNotification(
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setErrorLogModalData(data);
                                        setErrorLogModalVisible(true);
                                        setErrorLogModalTitle(geti18nText('asset.depreciation.error.details.modal'));
                                    }}
                                >
                                    {geti18nText('asset.depreciation.error.details')}
                                </Button>
                            );
                        } else {
                            errorNotification();
                        }
                    }
                    setLoading(false);
                });
            });
        }
    };

    const closeModal = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <>
            <Button
                style={{ width: iconStyle ? '100%' : '' }}
                icon={<BookOutlined style={iconStyle} />}
                onClick={() => setVisible(true)}
            >
                {geti18nText('asset.addAssetToCalculation.calculate')}
            </Button>
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
            {visible && (
                <Modal
                    visible={visible}
                    maskClosable={false}
                    title={geti18nText('asset.addAssetToCalculation.calculate')}
                    onOk={addAssetIntoCalculation}
                    okText={geti18nText('app.default.button.save')}
                    onCancel={(event: any) => {
                        closeModal();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <Form
                        form={form}
                        component={false}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.addAssetsIntoCalculationModal.depreciationRateGroup')}
                                    name="depreciationRateGroup"
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        SearchPopupComponent={<DepreciationGroupIndex />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <BusinessUnitSearch
                                    label={geti18nText('asset.addAssetsIntoCalculationModal.businessUnit')}
                                    formItemName={'businessUnit'}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.addAssetsIntoCalculationModal.assetType')}
                                    name="assetType"
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        SearchPopupComponent={<AssetModelIndex />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('asset.addAssetsIntoCalculationModal.unamortizedOnly')}
                                    name="unamortizedOnly"
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Checkbox
                                        onChange={(event: any) => {
                                            setunamortizedOnly(event.target.checked);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AddAssetsIntoCalculationModal;
