import { FundViewOutlined } from '@ant-design/icons';
import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { Button } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getDateFormat, numberFormat } from '../../../../utils/Utils';
import DownloadExchangeRateModal from './DownloadExchangeRateModal';

const ExchangeRateTable = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();

    const table = useTableSettings();

    const [modalVisible, setModalVisible] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportAdministrationCodebook();
    };

    const columns = [
        {
            title: geti18nText('exchangeRate.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('exchangeRate.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.date) {
                    return getDateFormat(record.date, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('exchangeRate.table.column.currency'),
            dataIndex: ['currency', 'isoCode'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.CURRENCY.SEARCH, 'currency.id', 'nameorCode'),
        },
        {
            title: geti18nText('exchangeRate.table.column.unit'),
            dataIndex: 'unit',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('exchangeRate.table.column.buyingRate'),
            dataIndex: 'buyingRate',
            sorter: (a: any, b: any) => {},
            render: (buyingRate: any) => {
                if (buyingRate) {
                    return numberFormat(buyingRate, 4);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('exchangeRate.table.column.middleRate'),
            dataIndex: 'middleRate',
            sorter: (a: any, b: any) => {},
            render: (middleRate: any) => {
                if (middleRate) {
                    return numberFormat(middleRate, 4);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('exchangeRate.table.column.sellingRate'),
            dataIndex: 'sellingRate',
            sorter: (a: any, b: any) => {},
            render: (sellingRate: any) => {
                if (sellingRate) {
                    return numberFormat(sellingRate, 4);
                }
            },
            ...getColumnSearch('number'),
        },
    ];

    const addedButtons = () => {
        return (
            canCreate() && (
                <Button
                    icon={<FundViewOutlined />}
                    onClick={() => {
                        setModalVisible(true);
                    }}
                    style={{ float: 'right' }}
                >
                    {geti18nText('exchangeRate.edit.downloadExchangeRate')}
                </Button>
            )
        );
    };

    return (
        <>
            <NyDataTable
                nyId="exchange-rates"
                url={CONSTANTS_REQ.EXCHANGE_RATE.LIST}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                showRecordModal={false}
                hideNewButton={true}
                columns={columns}
                readonly={true}
                fetchWhenChange={refresh}
                buttonsClassName="buttons-sticky"
                addedButtons={addedButtons}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.exchange.rate')}
                colCSVCustomization={csvColumnCustomisation()}
            />
            {canCreate() && (
                <DownloadExchangeRateModal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            buyingRate: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 4);
            },
        },
        {
            middleRate: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 4);
            },
        },
        {
            sellingRate: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 4);
            },
        },
    ];
};

export default ExchangeRateTable;
