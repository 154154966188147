import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, setEnumFormat } from '../../../../utils/Utils';

const TravelExpensePaymentMethodEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelPaymentMethod.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('travelPaymentMethod.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.type != null && dataForm.type != undefined) {
            dataForm.type = setEnumFormat('TRAVEL_EXPENSE_PAYMENT_METHOD', dataForm.type);
        }
        if (dataForm.bookExpense !== undefined) {
            dataForm.bookExpense = setEnumFormat('BOOK_OPTIONS', dataForm.bookExpense);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('travelPaymentMethod.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_EXPENSE_PAYMENT_METHOD.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.reimbursed = values.reimbursed ?? false;
                if (values.type) values.type = getEnumFormat(values.type);
                if (values.bookExpense) values.bookExpense = getEnumFormat(values.bookExpense);
                return values;
            }}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('travelPaymentMethod.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelPaymentMethod.edit.reimbursed')}
                        name="reimbursed"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelPaymentMethod.edit.type')}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('TRAVEL_EXPENSE_PAYMENT_METHOD')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                            onChange={(value: any) => {
                                if (value.id === -1) form.setFieldsValue({ type: undefined });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelPaymentMethod.edit.bookExpense')}
                        name="bookExpense"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('BOOK_OPTIONS')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                            onChange={(value: any) => {
                                if (value.id === -1) form.setFieldsValue({ bookExpense: undefined });
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelExpensePaymentMethodEdit;
