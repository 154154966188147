import { useRef, useState } from 'react';
import { Form, Row, Col, Input, Switch } from 'antd';
import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getEnumArray, getEnumFormat, setEnumFormat } from '../../utils/Utils';

const ObjectMetadataEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('objectMetadata.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [hiddenInput, setHiddenInput] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('objectMetadata.edit.title') + ' - ' + dataForm.id);
        }

        if (dataForm.metadataType) {
            dataForm.metadataType = setEnumFormat('METADATA_TYPE', dataForm.metadataType);
        }

        if (dataForm.dataType) {
            setHiddenInput(dataForm.dataType === 2);
            dataForm.dataType = setEnumFormat('OBJECT_METADATA_TYPE', dataForm.dataType);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const resetValue = (value: any) => {
        if (value.id == 2) {
            setHiddenInput(true);
            form.setFieldsValue({ pattern: undefined });
        } else {
            setHiddenInput(false);
        }
    };

    const onModalClose = () => {
        setHiddenInput(false);
        form.resetFields();
        setEditHeader(geti18nText('objectMetadata.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.OBJECT_METADATA.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.metadataType) values.metadataType = getEnumFormat(values.metadataType);
                if (values.dataType) values.dataType = getEnumFormat(values.dataType);
                if (hiddenInput && values.dataType == 2) values.pattern = undefined;
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('objectMetadata.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('objectMetadata.edit.metadataType')}
                        name="metadataType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={setEnumFormat(
                            'METADATA_TYPE',
                            props?.editProps?.metadataType ?? props?.addedData?.metadataType
                        )}
                    >
                        <NySearchField
                            options={getEnumArray('METADATA_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            className=""
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('objectMetadata.edit.dataType')} name="dataType">
                        <NySearchField
                            options={getEnumArray('OBJECT_METADATA_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            className=""
                            onChange={resetValue}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('objectMetadata.edit.pattern')} name="pattern">
                        <Input disabled={hiddenInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('objectMetadata.edit.mandatory')}
                        name="mandatory"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ObjectMetadataEdit;
