import { geti18nText } from '@nybble/nyreact';
import { Col, Radio, Row } from 'antd';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../rootReducer';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import { userIsNotificationRecipient } from '../../../../../utils/Utils';
import EmployeeEducationIndex from '../employee-education';

const EmployeeEducationOverview = () => {
    const { employee } = useSelector((state: RootState) => state.employee);
    const employeeEducationStatus = useEnum('EMPLOYEE_EDUCATION_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const [refresh, setRefresh] = useState<any>(0);
    const [activeList, setActiveList] = useState<any>(1);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);

    useHelper('human/employee_leave');

    const isUser = () => {
        return HumanResourcesRights.isEducationUser();
    };

    const isAdmin = () => {
        return HumanResourcesRights.isEducationAdmin();
    };

    const canViewAll = () => {
        return isUser() && userIsNotificationRecipient(employee, notificationType.EMPLOYEE_EDUCATION_APPROVAL);
    };

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });

            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const setDefaultFilterValueMyListPersonal = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: employee && employee.id },
        ];
    };

    const setDefaultFilterValueAllList = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'not_equals', value: employee && employee.id },
        ];
    };

    const setApprovalFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'equals', value: employeeEducationStatus.DRAFT },
        ];
    };

    const resolveSearch = () => {
        if (activeList == 3 && (canViewAll() || isAdmin())) {
            return setApprovalFilterValue();
        } else if (activeList == 2 && (canViewAll() || isAdmin())) {
            return setDefaultFilterValueAllList();
        } else {
            return setDefaultFilterValueMyListPersonal();
        }
    };

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        setRefresh((refresh: any) => refresh + 1);
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <Row gutter={24}>
                        <Radio.Group
                            value={activeList}
                            buttonStyle="solid"
                            optionType="button"
                            style={{ margin: '5px 5px 10px 10px' }}
                        >
                            {(isUser() || isAdmin()) && (
                                <Radio.Button value={1} onChange={onChangeActiveList}>
                                    {geti18nText('employee.education.personal')}
                                </Radio.Button>
                            )}
                            {(canViewAll() || isAdmin()) && (
                                <Radio.Button value={2} onChange={onChangeActiveList}>
                                    {geti18nText('employee.education.all')}
                                </Radio.Button>
                            )}
                            {canViewAll() && (
                                <Radio.Button value={3} onChange={onChangeActiveList}>
                                    {geti18nText('employee.education.approval')}
                                </Radio.Button>
                            )}
                        </Radio.Group>
                    </Row>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <EmployeeEducationIndex
                        showHeader={false}
                        search={resolveSearch()}
                        refreshView={refresh}
                        activeList={activeList}
                        employeeOverview
                        moduleAccessRight={moduleAccessRight}
                        employee={employee}
                        scroll={{ y: 580, x: 800 }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default EmployeeEducationOverview;
