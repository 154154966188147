import { EyeOutlined } from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, geti18nText } from '@nybble/nyreact';
import { Popconfirm, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../../rights/tasksRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookUserPerson } from '../../../../../utils/Filters';
import { onKeyDown, removeTaskWatcher } from '../../../../../utils/Utils';

const TaskWatchIndex = ({
    selectedTaskId,

    setRefreshTable,
    setIsWached,
    createdBy,
    setRefreshTaskWatchTable,
    refreshTaskWatchTable,
    openTask,
}: any) => {
    const table = useTableSettings();

    const isAdmin = () => {
        return TasksRights.isTasksAdmin();
    };

    const setDefaultFilterValueWorktimeActivity = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const onKeyUp = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    const columns = [
        ...[
            {
                title: geti18nText('task.watcher.table.column.watcher'),
                dataIndex: ['userPerson', 'firstName'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookUserPerson(),
                render: (text: any, record: any) => {
                    if (record) {
                        return (
                            <div
                                style={{ marginLeft: '2px' }}
                            >{`${record?.userPerson?.firstName} ${record?.userPerson?.lastName} (${record?.userEmployee?.employmentRecordId})`}</div>
                        );
                    }
                },
            },

            {
                title: geti18nText('task.watcher.table.column.watch.start'),
                dataIndex: 'created',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(),
                render: (text: any, record: any) => {
                    if (record && record.created) {
                        return moment(record.created).format('DD.MM.yyyy');
                    }
                },
            },
        ],
        ...(isAdmin()
            ? [
                  {
                      title: geti18nText('tasks.list.column.watch'),
                      dataIndex: 'isWatched',
                      width: '6%',
                      render: (text: any, record: { id: any; users: any }) => {
                          return (
                              <Tooltip title={geti18nText('tasks.list.column.watch.hover')}>
                                  <Popconfirm
                                      title={geti18nText('tasks.popup.watched.message.remove.user')}
                                      okText={geti18nText('app.default.button.yes')}
                                      cancelText={geti18nText('app.default.button.no')}
                                      onConfirm={() => {
                                          removeTaskWatcher(
                                              record?.id,
                                              setRefreshTaskWatchTable,
                                              openTask, //refresh edit
                                              setRefreshTable,
                                              selectedTaskId
                                          );
                                      }}
                                  >
                                      <EyeOutlined
                                          style={{
                                              color: 'green',
                                              fontSize: '18px',
                                              marginLeft: '12px',
                                              marginTop: '5px',
                                          }}
                                      />
                                  </Popconfirm>
                              </Tooltip>
                          );
                      },
                  },
              ]
            : []),
    ];

    return (
        <NyDataTable
            nyId="tasks-task-watch-index-table"
            url={CONSTANTS_REQ.TASK_WATCHERS.TASK_WATCHER_LIST.replace('{taskId}', selectedTaskId)}
            addNewButtonText={geti18nText('tasks.list.add')}
            fetchWhenChange={refreshTaskWatchTable}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            columns={columns}
            scroll={{ y: 480, x: 400 }}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValueWorktimeActivity())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={{ order: 'id', orderType: 'desc' }}
            hideNewButton={true}
            shortcuts={true}
        />
    );
};

export default TaskWatchIndex;
