import { DeleteOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    okNotification,
    onKeyDownTextAreaCustom,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';

const { TabPane } = Tabs;

const EmployeeLeaveAdditionalEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employeeLeave.additional.days.tab.title'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [selectedValuesDelete, setSelectedValuesDelete] = useState<any>([]);
    const [hasSelectedDelete, setHasSelectedDelete] = useState(false);
    const [selectedValuesAdd, setSelectedValuesAdd] = useState<any>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const [activeKey, setAcitveKey] = useState('1');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAddListModalVisible, setIsAddListModalVisible] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [allEmployees, setAllEmployees] = useState<any>();
    const [needContract, setNeedContract] = useState<any>();
    const [employeeId, setEmployeeId] = useState(null);
    const [files, setFiles] = useState<any>([]);

    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const table = useTableSettings();

    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.allEmployees) {
            setAllEmployees(true);
            form.setFieldsValue({ allEmployees: dataForm.allEmployees });
        } else {
            setAllEmployees(false);
        }
        if (dataForm.needContract) {
            setNeedContract(true);
            form.setFieldsValue({ needContract: dataForm.needContract });
        } else {
            setNeedContract(false);
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('employeeLeave.additional.days.tab.title') + ' - ' + dataForm.name);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setAcitveKey('1');
        setEditHeader(geti18nText('employeeLeave.additional.days.tab.title'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const setDefaultFilterValueNotInGroup = () => {
        return [
            /*          { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'groupId', condition: 'not_in_select', value: form.getFieldValue('id') },
            { field: 'applicationId', condition: 'equals', value: NySession.getProjectId() }, */
            /*   { field: 'employeeAnnualLeaveRightId', condition: 'equals', value: form.getFieldValue('id') }, */
        ];
    };

    const setDefaultFilterValueInGroup = () => {
        return [
            /*      { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'groupId', condition: 'equals', value: form.getFieldValue('id') },
            { field: 'application.id', condition: 'equals', value: NySession.getProjectId() }, */
            /*    { field: 'employeeAnnualLeaveRightId', condition: 'equals', value: form.getFieldValue('id') }, */
        ];
    };

    const setDefaultFilterValueHistory = () => {
        return [
            /*   { field: 'groupId', condition: 'equals', value: form.getFieldValue('id') },
            { field: 'application.id', condition: 'equals', value: NySession.getProjectId() }, */
            /*    { field: 'employeeAnnualLeaveRightId', condition: 'equals', value: form.getFieldValue('id') }, */
        ];
    };

    let clearKeysDelete: any;

    const setSelectedValuesFuncDelete = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDelete(hasSelected);
        setSelectedValuesDelete(selectedRows);
        clearKeysDelete = clearSelectedRowKeys;
    };

    let clearKeysAdd: any;

    const setSelectedValuesFuncAdd = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAdd(hasSelected);
        setSelectedValuesAdd(selectedRowKeys);
        clearKeysAdd = clearSelectedRowKeys;
    };

    const removeFromGroup = () => {
        const res = selectedValuesDelete.map((value: any) => {
            return value.employee.id;
        });

        NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.REMOVE_EMPLOYEES, undefined, {
            employeeAnnualLeaveRightId: form.getFieldValue('id'),

            employeesId: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDelete) {
                    clearKeysDelete();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {/*    ostavljeno za doradu   {activeKey === '1' && (
                <div style={{ float: 'left' }}>
                    <Button
                        type="primary"
                        style={{ marginLeft: '5px' }}
                        disabled={!needContract}
                        icon={<SolutionOutlined />}
                        onClick={() => {
                            setIsModalVisible(!isModalVisible);
                        }}
                    >
                        {geti18nText('employeeLeave.additional.days.tab.add.employee')}
                    </Button>
                </div>
            )} */}
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisible(true);
                        }}
                    >
                        {geti18nText('employeeLeave.additional.days.tab.add.employee')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('employeeLeave.additional.days.tab.out.remove.confirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDelete}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDelete}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('employeeLeave.additional.days.tab.out.remove')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
            {activeKey === '3' && (
                <div style={{ float: 'left', paddingLeft: '15px', marginRight: '10px' }}>
                    <Button
                        disabled={!hasSelectedAdd}
                        onClick={(e) => {
                            save(e);
                        }}
                    >
                        {geti18nText('app.default.button.add')}
                    </Button>
                </div>
            )}
        </React.Fragment>
    );

    const save = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);

        NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.BULK_ADD, undefined, {
            employeeAnnualLeaveRightId: form.getFieldValue('id'),
            employeesId: selectedValuesAdd,
        }).then((result: any) => {
            if (setLoadingModal) setLoadingModal(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAdd) {
                    clearKeysAdd();
                }

                okNotification();
                //    setIsModalVisible(false);
                setIsAddListModalVisible(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const saveContract = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);

        NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.BULK_ADD, undefined, {
            employeeAnnualLeaveRightId: form.getFieldValue('id'),
            employeesId: selectedValuesAdd,
        }).then((result: any) => {
            if (setLoadingModal) setLoadingModal(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAdd) {
                    clearKeysAdd();
                }

                okNotification();
                setIsModalVisible(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const employeeOnChange = (value: any) => {
        if (value.id === -1) {
            form.setFieldsValue({ employee: undefined });
        }

        setEmployeeId(null);
        form.setFieldsValue({
            travelVehicle: null,
        });
        if (value && value.id > 0) {
            setEmployeeId(value.id);
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data.vocation && result.data.vocation.name) {
                            form.setFieldsValue({ employeeVocation: result.data.vocation.name });
                        }
                    }
                }
            );
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.EDIT}
            setValues={setValues}
            setIsCreate={setIsCreate}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideActivationButtons={activeKey != '1'}
            hideSubmitButton={activeKey != '1'}
            {...props}
            normalize={(values: any) => {
                values.allEmployees = values.allEmployees ?? false;
                values.needContract = values.needContract ?? false;

                return values;
            }}
            customFooterContent={getCustomFooterContent}
            shouldCloseModal={!isCreate}
            fetchWhenChange={refreshTable}
            onSaveAndGetData={(data) => {
                setValues(data);
                if (props && props.record) {
                    props.record.active = true;
                }
                setRefreshTable(refreshTable + 1);

                setIsCreate(false);
                onModalClose();

                props.editProps.setRefreshTable(props.editProps.refreshTable + 1);
            }}
        >
            <Tabs type="card" defaultActiveKey={activeKey} onChange={(key) => setAcitveKey(key)}>
                <TabPane tab={geti18nText('employeeLeave.additional.days.basic')} key="1">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('employeeLeave.additional.days.tab.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('employeeLeave.additional.days.tab.description')}
                                name="description"
                            >
                                <TextArea
                                    rows={4}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                ></TextArea>
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('employeeLeave.additional.days.tab.days')}
                                name="days"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>

                            <Form.Item name="allEmployees" valuePropName={'checked'}>
                                <Checkbox
                                    onChange={(e) => {
                                        setAllEmployees(e.target.checked);
                                    }}
                                >
                                    {geti18nText('employeeLeave.additional.days.tab.all')}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {isModalVisible && (
                        <Modal
                            title={geti18nText('groups.edit.button.addRole')}
                            visible={isModalVisible}
                            okText={geti18nText('app.default.button.add')}
                            onOk={(e) => saveContract(e)}
                            confirmLoading={loadingModal}
                            width={600}
                            onCancel={() => {
                                setIsModalVisible(false);
                            }}
                        >
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.employee')}
                                    name="employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                            businessUnit: 'businessUnit',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="leave-additional-days"/>}
                                        nyTestId="employee"
                                        onChange={employeeOnChange}
                                        renderOption={customEmployeeRenderOption}
                                        order="person.last_name, person.first_name"
                                        addedData={{ employeeAnnualLeaveRightId: form.getFieldValue('id') }}
                                    />
                                </Form.Item>
                                <Col span={24}>
                                    <Form.Item
                                        name="endDate"
                                        label={geti18nText('travelWarrantWizard.step2.endDate')}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker showTime={false} format="DD.MM.YYYY" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="file">
                                        {/*  <FileListIndex
                                            isUploaded={false}
                                            files={files}
                                            setFiles={setFiles}
                                            addNewFile={true}
                                            canDelete={true}
                                            loading={loading}
                                        />*/}
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Modal>
                    )}
                </TabPane>
                <TabPane
                    tab={geti18nText('employeeLeave.additional.days.tab.in.group')}
                    key="2"
                    disabled={isCreate || allEmployees}
                >
                    <NyDataTable
                        nyId="human-employee-anual-additional-days-table"
                        rowKey="employeeId"
                        url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.EMPLOYEES}
                        addedData={{ employeeAnnualLeaveRightId: form.getFieldValue('id') }}
                        showRowSelection
                        readonly
                        hideButtons
                        setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                        scroll={props?.editProps?.scroll}
                        rowSelectionType={'checkbox'}
                        rowSelectionModal={setSelectedValuesFuncDelete as any}
                        setDefaultFilterValue={setDefaultFilterValueInGroup}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        fetchWhenChange={refreshTable}
                        columns={[
                            {
                                title: geti18nText('employeeLeave.additional.days.tab.id'),
                                dataIndex: ['employeeId'],
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('travelWarrant.table.column.employee'),
                                dataIndex:
                                    "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                                render: (person: any, record: any) => {
                                    if (record && record.person) {
                                        return (
                                            <>
                                                {`${record.person.firstName} ${record.person.lastName} `}
                                                {record.employee &&
                                                    record.employee.employmentRecordId &&
                                                    ` (${record.employee.employmentRecordId})`}
                                            </>
                                        );
                                    }
                                },
                            },
                        ]}
                    />
                    {isAddListModalVisible && (
                        <Modal
                            title={geti18nText('employeeLeave.additional.days.tab.add.employee.modal')}
                            visible={isAddListModalVisible}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAdd }}
                            onOk={(e) => save(e)}
                            confirmLoading={loadingModal}
                            width={600}
                            onCancel={() => {
                                setIsAddListModalVisible(false);
                            }}
                        >
                            <NyDataTable
                                nyId="human-employee-leave-additional-days-edit"
                                rowKey="id"
                                url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.NOT_EMPLOYEES}
                                addedData={{ employeeAnnualLeaveRightId: form.getFieldValue('id') }}
                                showRowSelection
                                hideButtons
                                setDefaultPageSize={table.setDefaultPageSize()}
                                readonly
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                scroll={props?.editProps?.scroll}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncAdd as any}
                                setDefaultFilterValue={setDefaultFilterValueHistory}
                                fetchWhenChange={refreshTable}
                                columns={[
                                    {
                                        title: geti18nText('employeeLeave.additional.days.tab.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },

                                    {
                                        title: geti18nText('travelWarrant.table.column.employee'),
                                        dataIndex:
                                            "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employment_record_id as varchar),'')) ",
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                        render: (person: any, record: any) => {
                                            if (record.person) {
                                                if (record.person && record.employmentRecordId) {
                                                    return (
                                                        record.person.firstName +
                                                        ' ' +
                                                        record.person.lastName +
                                                        ' (' +
                                                        record.employmentRecordId +
                                                        ')'
                                                    );
                                                } else {
                                                    return record.person.firstName + ' ' + record.person.lastName;
                                                }
                                            }
                                        },
                                    },
                                ]}
                            />
                        </Modal>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default EmployeeLeaveAdditionalEdit;
