import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button } from 'antd';
import { ReactText, useState } from 'react';
import { useLocation } from 'react-router';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { PayrollRights } from '../../../../../rights/payrollRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { getDateFormat, getEnumArrayStatus, numberFormat } from '../../../../../utils/Utils';
import AddToBulkModal from './AddToBulkModal';
import AddToGroupModal from './AddToGroupModal';
import PaymentOrdersIndividualEdit from './edit';

const PaymentOrdersIndividualIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();

    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const table = useTableSettings();

    useHelper('payment_order/individual');

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    const defaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'equals_float', value: 1 },
        ];
    };

    const columns = [
        {
            title: geti18nText('payment.orders.individual.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.group'),
            dataIndex: ['paymentOrderGroup', 'paymentInformationId'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.PAYMENT_ORDER_GROUP.SEARCH,
                'paymentOrderGroup.id',
                'paymentInformationId',
                'paymentInformationId',
                { id: 'id', label: 'paymentInformationId', text: 'paymentInformationId' }
            ),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.dueDate'),
            dataIndex: 'dueDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dueDate) {
                    return getDateFormat(record.dueDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorName'),
            dataIndex: 'debtorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorAccountIban'),
            dataIndex: 'debtorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorReference'),
            dataIndex: 'debtorReference',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorName'),
            dataIndex: 'creditorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorAccountIban'),
            dataIndex: 'creditorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorReference'),
            dataIndex: 'creditorReference',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.amount'),
            dataIndex: 'amount',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                if (amount) {
                    return numberFormat(amount);
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.paymentDate'),
            dataIndex: 'completedDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.completedDate) {
                    return getDateFormat(record.completedDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.status'),
            dataIndex: 'status',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('PAYMENT_ORDER_STATUS_TYPE'), 'equals'),
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.PAYMENT_ORDER_STATUS_TYPE.' + record.status);
                }
            },
            defaultFilteredValue: defaultFilterValue(),
        },
    ];

    let clearKeys: any;

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    const AddToGroup = (onSave: () => void) => {
        const [modalVisible, setModalVisible] = useState(false);
        const [bulkModalVisible, setBulkModalVisible] = useState(false);
        return (
            <>
                {hasSelected && (
                    <>
                        <Button onClick={() => setModalVisible(true)}>
                            {geti18nText('payment.orders.individual.AddToGroup.title')}
                        </Button>
                        <AddToGroupModal
                            onSave={onSave}
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                            selectedValues={selectedValues}
                            clearKeys={clearKeys}
                        />
                        <Button onClick={() => setBulkModalVisible(true)}>
                            {geti18nText('payment.orders.individual.AddToBulk.title')}
                        </Button>
                        <AddToBulkModal
                            onSave={onSave}
                            modalVisible={bulkModalVisible}
                            setModalVisible={setBulkModalVisible}
                            selectedValues={selectedValues}
                            clearKeys={clearKeys}
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <NyDataTable
            nyId="payment-orders-individual"
            url={CONSTANTS_REQ.PAYMENT_ORDER.LIST}
            addNewButtonText={geti18nText('payment.orders.individual.table.add.new')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={PaymentOrdersIndividualEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={defaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            showRowSelection={true}
            rowSelectionType={'checkbox'}
            rowSelectionModal={setSelectedValuesFunc as any}
            rowSelectionProps={(record: any) => ({
                disabled: !record.paymentOrderGroup ? false : true, // Column configuration not to be checked
            })}
            addedButtons={AddToGroup}
            selectOnSave={false}
            hideNewButton={!canCreate()}
        />
    );
};

export default PaymentOrdersIndividualIndex;
