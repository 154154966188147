import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import EmployeeLeaveAdditionalEdit from './edit';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EmployeeLeaveAdditionaIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = { y: 583, x: 800 },
    sortOrder,
}: any) => {
    const [refreshTable, setRefreshTable] = useState(1);
    const table = useTableSettings();
    useHelper('human/codebooks/employee_simple');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const columns = [
        {
            title: geti18nText('employeeLeave.additional.days.tab.id'),
            dataIndex: 'id',
            width: '3%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeLeave.additional.days.tab.name'),
            dataIndex: ['name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeLeave.additional.days.tab.description'),
            dataIndex: ['description'],
            sorter: (a: any, b: any) => {},
            render: (description: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={description}>
                        {description}
                    </Tooltip>
                );
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employeeLeave.additional.days.tab.days'),
            dataIndex: 'days',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeLeave.additional.days.tab.all'),
            dataIndex: 'allEmployees',
            width: '10%',
            render: (text: any, record: { allEmployees: any }) => {
                if (record.allEmployees) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
        /*      { ugovor -- zakomentirano za doradu
            title: geti18nText('employeeLeave.additional.days.tab.needContract'),
            dataIndex: 'needContract',
            width: '10%',
            render: (text: any, record: { needContract: any }) => {
                if (record.needContract) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(
                ENUMS.ACTIVE(),
                'equals_bool',
                undefined,
                table.checkForFilter('needContract')?.value
            ),
            defaultFilteredValue: table.setDefaultFilterValue(),
        }, */
        {
            title: geti18nText('location.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <div className="unapproved-table">
            <NyDataTable
                nyId="human-employee-leave-additional-days"
                url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_ADDITIONAL.LIST}
                addNewButtonText={geti18nText('employeeLeave.additional.days.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={EmployeeLeaveAdditionalEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                hideNewButton={!canCreate()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('employeeLeave.additional.days.add')}
                colCSVCustomization={csvColumnCustomisation()}
                fetchWhenChange={refreshTable}
                editProps={{
                    setRefreshTable: setRefreshTable,
                    refreshTable: refreshTable,
                    scroll: { y: 400, x: 500 },
                    sortOrder: null,
                }}
            />
        </div>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            needContract: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            allEmployees: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeLeaveAdditionaIndex;
