import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { APPLICATION_KEY, CONSTANTS_REQ } from './Constants';

export interface IEnum {
    enumName:
        | 'ATTENDANCE_TYPE'
        | 'COMPANY_PARTNER_TYPE'
        | 'PAYROLL_INSURANCE_BASE_TYPE'
        | 'PAYROLL_INSURANCE_TYPE'
        | 'PROFESSION_QUALIFICATION_TYPE'
        | 'LOCATION_TYPE'
        | 'FOUNDATION_TYPE'
        | 'EMPLOYEE_LEAVE_TYPE'
        | 'EMPLOYEE_LEAVE_STATUS'
        | 'BANK_ACCOUNT_TYPE'
        | 'SEX_TYPE'
        | 'EMPLOYMENT_TYPE'
        | 'EMPLOYEE_FAMILY_MEMBER_TYPE'
        | 'DEMISSION_TYPE'
        | 'PAYROLL_TAX_DEDUCTION_TYPE'
        | 'PAYROLL_DEDUCTION_TYPE'
        | 'PAYROLL_ENFORCEMENT_TYPE'
        | 'PAYROLL_CONTRACTED_AMOUNT_TYPE'
        | 'MARKET_TYPE'
        | 'EMPLOYEE_GROUP_SHIFT_TYPE'
        | 'APPLICATION_SETTINGS_DATA_TYPE'
        | 'APPLICATION_SETTINGS_MODULE_TYPE'
        | 'NOTIFICATION_TYPE'
        | 'PAYMENT_CALCULATION_TYPE'
        | 'SUBREPORT_TYPE'
        | 'SUBREPORT_HEADER_DIMENSIONS'
        | 'SUBREPORT_FOOTER_DIMENSIONS'
        | 'PAYMENT_ORDER_STATUS_TYPE'
        | 'SEPA_CHARGE_BEARER_TYPE_CODE'
        | 'SEPA_EXTERNAL_PURPOSE_TYPE_CODE'
        | 'SEPA_PAYMENT_METHOD_TYPE_CODE'
        | 'SEPA_PRIORITY_TYPE_CODE'
        | 'SEPA_REMITTANCE_REFERENCE_TYPE_CODE'
        | 'TRAVEL_WARRANT_PAYMENT_TYPE'
        | 'TRAVEL_WARRANT_STATUS'
        | 'OBJECT_METADATA_TYPE'
        | 'METADATA_TYPE'
        | 'DOCUMENT_TYPE'
        | 'ASSET_STATUS'
        | 'ASSET_AVAILABILITY_STATUS'
        | 'INVENTORY_ASSET_STATUS'
        | 'INVENTORY_ASSET_ITEM_STATUS'
        | 'ITEM_AVAILABILITY_STATUS'
        | 'INVENTORY_ORDER_STATUS_TYPE'
        | 'ITEM_TYPE'
        | 'ITEM_GROUP_TYPE'
        | 'NOTIFICATION_TYPE_SUPERVISOR'
        | 'WAREHOUSE_TYPE'
        | 'WAREHOUSE_VALUE_TYPE'
        | 'WAREHOUSE_DOCUMENT_TYPE'
        | 'NOTIFICATION_TYPE_SUPERVISOR'
        | 'TRAVEL_WARRANT_PERSONAL_STATUS'
        | 'TRAVEL_EXPENSE_PAYMENT_METHOD'
        | 'ORDER_VIEW_TYPE'
        | 'ORDER_VIEW_ACCESS_TYPE'
        | 'ACCOUNTING_ENTITY_TYPE'
        | 'ACCOUNTING_ENTITY_TYPE_GROUP'
        | 'ASSET_DEPRECIATION_STATUS'
        | 'ACCOUNT_ENTRY_TYPE'
        | 'ASSET_DEPRECIATION_METHOD_TYPE'
        | 'ASSET_WRITE_OFF_TYPE'
        | 'ASSET_ORDER'
        | 'INVENTORY_ORDER'
        | 'SERVICES_ORDER'
        | 'PAYMENT_CALCULATION'
        | 'TRAVEL_WARRANT_APPROVAL'
        | 'TRAVEL_WARRANT_ORGANISATION'
        | 'TRAVEL_WARRANT_PAYMENT'
        | 'TRAVEL_WARRANT_PAYMENT_APPROVAL'
        | 'TRAVEL_WARRANT_COMPENSATION_PAYMENT'
        | 'TRAVEL_BAGGAGE_TYPE'
        | 'TRAVEL_NEED_TRANSPORT'
        | 'TRAVEL_NEED_ACCOMMODATION'
        | 'TRAVEL_VEHICLE_TYPE'
        | 'MODULE_ACCESS_RIGHT'
        | 'BOOKED_TYPE'
        | 'NOTIFICATION_TYPE_MODULE_TYPE'
        | 'TRAVEL_WARRANT_PAYMENT_STATUS'
        | 'TRAVEL_WARRANT_PAYMENT_SORT'
        | 'EMPLOYEE_ANNUAL_LEAVE_STATUS'
        | 'PAYROLL_BRUT_VALUE_TYPE'
        | 'PAYROLL_BRUT_BASE_TYPE'
        | 'MENU_TYPE'
        | 'MEAL_ORDER_STATUS'
        | 'DELIVERY_TYPE'
        | 'MEALS_GROUP_ORDER_TYPE'
        | 'TASK_TYPE'
        | 'BILLING_STATUS'
        | 'TASK_STATUS'
        | 'SYSTEM_NOTIFICATION_TYPE'
        | 'PROFESSIONAL_SPECIALTY_TYPE'
        | 'DEFENSE_DEPARTMENT_TYPE'
        | 'MOBILIZATION_STATUS'
        | 'MILITARY_WORK_APPROVAL_TYPE'
        | 'MILITARY_WORK_APPROVAL_STATUS'
        | 'EMPLOYEE_MILITARY_RECORDS_ASSIGNED'
        | 'MOBILIZATION_REPORT_TYPE'
        | 'MOBILIZATION_FUNCTION'
        | 'MOBILIZATION_TIME'
        | 'MILITARY_WORK_APPROVAL_FILE_TYPE'
        | 'TRAVEL_WAGE_TYPE'
        | 'TRAVEL_WARRANT_WAGE_TYPE'
        | 'HUMAN_CODEBOOKS'
        | 'QUALIFICATION_LEVEL_TYPE'
        | 'TASK_APPROVAL'
        | 'TASK_APPROVAL_STATUS'
        | 'TASK_ACTION'
        | 'IMPORT_STATUS'
        | 'SERVICE_TASK_TYPES'
        | 'BOOK_OPTIONS'
        | 'BOOKKEEPING_TYPE'
        | 'CONTRACT_PARTY_TWO'
        | 'ADDRESS_TYPE'
        | 'CONTRACT_STATUS'
        | 'CONTRACT_DOCUMENT_STATUS'
        | 'CONTRACT_DOCUMENT_TEMPLATE_STATUS'
        | 'CONTRACT_DOCUMENT_TEMPLATE_FILE_TYPE'
        | 'CONTRACT_NOTIFICATION_RECORD_TYPE'
        | 'CONTRACT_METADATA_CODEBOOK'
        | 'CONTRACT_METADATA'
        | 'CONTRACT_AUTO_FILL_KEY'
        | 'CONTRACT_CONSTANTS_CONTRACT_COLUMN'
        | 'CONTRACT_HR_COLUMN'
        | 'EDUCATION_INSTITUTION_EDUCATION_TYPE'
        | 'FIELD_TRANSLATION_TYPE'
        | 'EMPLOYEE_EDUCATION_STATUS'
        | 'EMPLOYEE_EVALUATION_STATUS'
        | 'EMPLOYEE_EVALUATION_ESTIMATION'
        | 'DEVICE_TYPE'
        | 'ASSET_RELEASE_SPEC_TYPE'
        | 'ASSET_INVENTORY_SPEC_TYPE'
        | 'EXPORT_TYPE'
        | 'ATTENDANCE_REGISTRATION_TYPE'
        | 'WORKPLACE_STATUS'
        | 'TRAVEL_WARRANT_BOOK_STATUS'
        | 'EMPLOYEE_STATUS'
        | 'TRAVEL_WARRANT_TOTALS';
}

export interface IEnumValue extends IEnum {
    value: any;
}

export interface IEnumName extends IEnum {
    name: any;
}

export const GetEnum = ({ enumName }: IEnum) => {
    const en = loadFromSession(APPLICATION_KEY + 'enums');

    if (en !== undefined && en[enumName] !== undefined) {
        return en[enumName];
    } else {
        return undefined;
    }
};

export const LoadEnums = async function () {
    await NyRequestResolver.requestGet(CONSTANTS_REQ.ENUMS).then((response: any) => {
        if (response.status === RESPONSE.OK) {
            sessionStorage.setItem(APPLICATION_KEY + 'enums', JSON.stringify(response.data));
        }
    });
};

export const GetEnumNameForValue = function ({ enumName, value }: IEnumValue) {
    const enums = GetEnum({ enumName: enumName });

    if (enums === undefined) {
        return undefined;
    }

    for (const [key, enumValue] of Object.entries(enums)) {
        if (enumValue === value) {
            return key;
        }
    }

    return undefined;
};

export const GetEnumValueForName = function ({ enumName, name }: IEnumName) {
    const enums = GetEnum({ enumName: enumName });

    if (enums === undefined) {
        return undefined;
    }

    for (const [key, enumValue] of Object.entries(enums)) {
        if (key === name) {
            return enumValue;
        }
    }

    return undefined;
};

function loadFromSession(key: string) {
    const values = sessionStorage.getItem(key);
    if (values && values !== 'undefined') {
        return JSON.parse(values);
    }
    return undefined;
}

export enum EnMediaType {
    VIDEO = 1,
    PICTURE = 2,
    URL = 3,
}
