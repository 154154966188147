import { useState } from 'react';
import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyDatePicker, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, getDateFormat, okNotification } from '../../../../utils/Utils';
import moment from 'moment';

const GenerateJoppdTravelWarrantsModal = ({
    joppdId,
    modalVisible,
    setModalVisible,
    setFetching,
    fetch,
    url,
    title,
}: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.joppdId = joppdId;
                if (values.dateFrom) {
                    values.dateFrom = getDateFormat(values.dateFrom);
                }
                if (values.dateTo) {
                    values.dateTo = getDateFormat(values.dateTo);
                }
                NyRequestResolver.requestPost(url, undefined, values).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.OK) {
                        setTimeout(() => {
                            setFetching(true);
                            fetch();
                        }, 1000);
                        okNotification();
                        setModalVisible(false);
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={title}
                    maskClosable={false}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    confirmLoading={loading}
                    width={400}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('joppd.generateFromTravelWarrants.dateFrom')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().subtract(1, 'months').startOf('month')}
                                >
                                    <NyDatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('joppd.generateFromTravelWarrants.dateTo')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().subtract(1, 'months').endOf('month')}
                                >
                                    <NyDatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default GenerateJoppdTravelWarrantsModal;
