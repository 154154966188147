import { DownOutlined, FileExcelOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Tree, TreeDataNode, TreeProps } from 'antd';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { fileDownload } from '../../../../utils/Utils';

const WorkObligationAttachments = ({}: any) => {
    const mobilizationReportType = useEnum('MOBILIZATION_REPORT_TYPE');
    const rowStyle: any = { marginTop: '5px', marginBottom: '5px' };

    const downloadFileButton = (translation: string, url: string, params: any, filename: string) => {
        return (
            <div>
                <Button
                    style={{ width: '100%' }}
                    icon={<FileExcelOutlined />}
                    onClick={() => {
                        fileDownload(url, params, filename);
                    }}
                >
                    {geti18nText(translation)}
                </Button>
            </div>
        );
    };

    const mapTitle = (translation: string) => {
        return (
            <div>
                <FolderOpenOutlined style={{ marginRight: '5px' }} />
                {geti18nText(translation)}
            </div>
        );
    };

    const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {};

    const treeData: TreeDataNode[] = [
        {
            title: mapTitle('menu.work.obligation.attachment.systematization'),
            key: 'systematization',
            children: [],
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.4.1',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_1 },
                geti18nText('app.enum.MOB_4_1.1') + '.xlsx'
            ),
            key: '4.1',
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.4.2',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_2 },
                geti18nText('app.enum.MOB_4_2.2') + '.xlsx'
            ),
            key: '4.2',
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.4.3.3',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_3_1 },
                geti18nText('app.enum.MOB_4_3_1.3') + '.xlsx'
            ),
            key: '4.3.3',
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.4.3.8',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_3_2 },
                geti18nText('app.enum.MOB_4_3_2.4') + '.xlsx'
            ),
            key: '4.3.8',
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.4.3.24',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_3_3 },
                geti18nText('app.enum.MOB_4_3_3.5') + '.xlsx'
            ),
            key: '4.3.24',
            style: rowStyle,
        },
        {
            title: mapTitle('menu.work.obligation.attachment.4.4'),
            key: '4.4',
            children: [
                {
                    title: downloadFileButton(
                        'app.enum.MOB_4_4_1.6',
                        CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                        { type: mobilizationReportType.MOB_4_4_1 },
                        geti18nText('app.enum.MOB_4_4_1.6') + '.xlsx'
                    ),
                    key: '4.3.3.mob',
                    style: rowStyle,
                },
                {
                    title: downloadFileButton(
                        'app.enum.MOB_4_4_2.7',
                        CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                        { type: mobilizationReportType.MOB_4_4_2 },
                        geti18nText('app.enum.MOB_4_4_2.7') + '.xlsx'
                    ),
                    key: '4.3.8.mob',
                    style: rowStyle,
                },
                {
                    title: downloadFileButton(
                        'app.enum.MOB_4_4_3.8',
                        CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                        { type: mobilizationReportType.MOB_4_4_3 },
                        geti18nText('app.enum.MOB_4_4_3.8') + '.xlsx'
                    ),
                    key: '4.3.24.mob',
                    style: rowStyle,
                },
            ],
            style: rowStyle,
        },
        {
            title: mapTitle('menu.work.obligation.attachment.4.5'),
            key: '4.5',
            children: [],
            style: rowStyle,
        },
        {
            title: mapTitle('menu.work.obligation.attachment.4.6'),
            key: '4.6',
            children: [],
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.4.9',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_9 },
                geti18nText('app.enum.MOB_4_9.9') + '.xlsx'
            ),
            key: '4.9',
            style: rowStyle,
        },
        {
            title: mapTitle('menu.work.obligation.attachment.4.10'),
            key: '4.10',
            children: [],
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.11',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_11 },
                geti18nText('app.enum.MOB_4_11.12') + '.xlsx'
            ),
            key: '4.11',
            style: rowStyle,
        },
        {
            title: downloadFileButton(
                'menu.work.obligation.attachment.12',
                CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                { type: mobilizationReportType.MOB_4_12 },
                geti18nText('app.enum.MOB_4_12.13') + '.xlsx'
            ),
            key: '4.12',
            style: rowStyle,
        },
        {
            title: downloadFileButton('menu.work.obligation.attachment.13', '', {}, ''),
            key: '4.13',
            style: rowStyle,
        },
    ];

    return (
        <>
            <Tree
                showLine
                switcherIcon={<DownOutlined />}
                defaultExpandedKeys={['']}
                onSelect={onSelect}
                treeData={treeData}
                style={{ height: '80vh', overflowY: 'auto', overflowX: 'hidden' }}
            />
        </>
    );
};

export default WorkObligationAttachments;
