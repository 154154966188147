import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useSelector } from 'react-redux';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getEnumArray } from '../../../../utils/Utils';
import TravelVehiclePersonalEdit from './edit';

const TravelVehiclePersonalIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    vehicleType = 1,
    newButtonTextPersonal = false,
    employeeId,
    sort = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const { employee } = useSelector((state: RootState) => state.employee);
    useHelper('travel_order/codebooks/vehicle_personal');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelVehicle();
    };

    const setDefaultFilterValue = () => {
        if (vehicleType !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId ? employeeId : employee.id },
                {
                    field: 'vehicle_type',
                    condition: 'in',
                    value: vehicleType.length > 0 ? vehicleType.toString() : [vehicleType].toString(),
                },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId ? employeeId : employee.id },
            ];
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('travelVehicle.table.column.id'),
                dataIndex: 'id',
                width: '7%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('travelVehicle.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('travelVehicle.table.column.model'),
                dataIndex: 'model',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('travelVehicle.table.column.licencePlate'),
                dataIndex: 'licencePlate',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (licencePlate: any) => {
                    return licencePlate ? licencePlate.toUpperCase() : '';
                },
            },
            {
                title: geti18nText('travelVehicle.table.column.vehicleType'),
                dataIndex: 'vehicleType',
                width: '15%',
                sorter: (a: any, b: any) => {},
                ...(vehicleType !== null
                    ? getColumnSearchCheckbox(
                          getEnumArray('TRAVEL_VEHICLE_TYPE'),
                          'in',
                          vehicleType.length > 0 ? vehicleType.toString() : [vehicleType].toString()
                      )
                    : getColumnSearchCheckbox(getEnumArray('TRAVEL_VEHICLE_TYPE'))),
                render: (vehicleType: any) => {
                    return geti18nText('app.enum.TRAVEL_VEHICLE_TYPE.' + vehicleType);
                },
            },
            {
                title: geti18nText('travelVehicle.table.column.travelFuelType'),
                dataIndex: ['travelFuelType', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TRAVEL_FUEL_TYPE.SEARCH, 'travelFuelType.id'),
            },
        ],
        ...(!showRowSelection
            ? [
                  {
                      title: geti18nText('travelVehicle.table.column.active'),
                      dataIndex: 'active',
                      width: '10%',
                      render: (text: any, record: { active: any }) => {
                          if (record.active) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                      defaultFilteredValue: setDefaultFilterValue(),
                  },
              ]
            : []),
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-vehicle-table"
            url={CONSTANTS_REQ.TRAVEL_VEHICLE.LIST}
            addNewButtonText={
                newButtonTextPersonal
                    ? geti18nText('travelVehicle.table.add.personal')
                    : geti18nText('travelVehicle.table.add')
            }
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            fetchWhenChange={employee.id}
            modalComponent={TravelVehiclePersonalEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? sort ? { order: 'sort', orderType: 'asc' } : table.setDefaultSortOrder()}
            shortcuts={true}
            hideNewButton={!canCreate()}
        />
    );
};

export default TravelVehiclePersonalIndex;
