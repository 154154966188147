import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, FilePdfOutlined, HistoryOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Input, Modal, Radio, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AssetAssignHistoryIndex from '../../../../../components/asset-assign-history';
import NyNoteModal from '../../../../../components/note-modal';
import NyHistory from '../../../../../components/ny-history/NyHistory';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { AssetRights } from '../../../../../rights/assetRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import {
    errorNotification,
    fileDownload,
    getDateFormat,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setEnumFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import ItemSearch from '../../../../administration/views/item/search';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import RoomSearch from '../../../../human/views/room/search';
import AssetCategoryIndex from '../asset-category';
import AssetCategoryEdit from '../asset-category/edit';
import AssetModelIndex from '../asset-model';
import AssetModelEdit from '../asset-model/edit';
import AssetTypeIndex from '../asset-type';
import AssetTypeEdit from '../asset-type/edit';

const { Panel } = Collapse;

const AssetEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('asset.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [status, setStatus] = useState('');
    const [availability, setAvailability] = useState<any>('');
    const [metadata, setMetadata] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [assetType, setAssetType] = useState(undefined);
    const [nfcTag, setNfcTag] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [historyData, setHistoryData] = useState<any>();
    const [historyType, setHistoryType] = useState<any>(1);
    const [isEmployee, setIsEmployee] = useState<any>(undefined);
    const [isBusinessUnit, setIsBusinessUnit] = useState<any>(undefined);
    const [employeeText, setEmployeeText] = useState<any>();
    const [businessUnitText, setBusinessUnitText] = useState<any>();

    const focusInput = useRef<any>(null);
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);

    const [form] = Form.useForm();
    const [objectMetadataForm] = Form.useForm();

    const history = useHistory();
    const { id } = useParams<any>();
    const [disabledInventoryNumber, setDisabledInventoryNumber] = useState(false);

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'ASSET_DEPRECIATION' },
            { field: 'setting_key', condition: 'equals', value: 'AUTO_ASSIGN_INVENTORY_NUMBER' },
        ];
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    useEffect(() => {
        fetchAppSettings();
    }, [dataForm]);

    useEffect(() => {
        if (assetType !== undefined) {
            fetchMetadata(assetType);
        }
    }, [assetType]);

    useEffect(() => {
        if (metadata !== undefined && metadata.length > 0) {
            const metadataForm: any = {};

            if (form.getFieldValue('assetMetadata') !== undefined) {
                form.getFieldValue('assetMetadata').map((item: any) => {
                    if (item.asset_type_metadata !== undefined) {
                        const findItem: any = metadata.find((row: any) => row.id === item.asset_type_metadata.id);
                        if (findItem !== undefined && findItem.objectMetadata !== undefined) {
                            metadataForm[findItem.objectMetadata.name] = item.value;
                        }
                    }
                });
            }

            objectMetadataForm.setFieldsValue(metadataForm);
        }
    }, [metadata]);

    const canCreate = () => {
        return AssetRights.canCreateCodebooks();
    };

    const isAdmin = () => {
        return AdministrationRights.isAdmin();
    };

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    let value =
                        result.data[0] && result.data[0].value
                            ? result.data[0].value
                            : result.data[0].defaultValue
                            ? result.data[0].defaultValue
                            : '0';
                    setDisabledInventoryNumber(value == '1' ? true : false);
                }
            }
        });
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
            <div style={{ float: 'left', marginLeft: '16px' }}>
                <Button icon={<HistoryOutlined />} onClick={() => setHistoryModalVisible(true)}>
                    {geti18nText('app.default.change.history')}
                </Button>
            </div>

            {!isCreate && nfcTag && (
                <div style={{ float: 'left', marginLeft: '20px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.popconfirm.deactive.nfc')}
                        onConfirm={() => {
                            changeNfcTagStatus();
                        }}
                    >
                        <Button icon={<DeleteOutlined />}>{geti18nText('asset.button.deactive.nfc')}</Button>
                    </NyModalConfirm>
                </div>
            )}
        </>
    );

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('asset.edit.title') + ' - ' + dataForm.id);
        }

        if (dataForm.assetType) {
            setAssetType(dataForm.assetType.id);
        }

        if (dataForm?.employee?.room) {
            form.setFieldsValue({ employeeRoom: setSearchFormat(dataForm.employee.room, 'name', 'name') });
        }

        if (dataForm.employee) {
            setIsEmployee(true);
            setEmployeeText(
                <>
                    <div>
                        <strong>{`${dataForm?.employee?.person?.firstName} ${dataForm?.employee?.person?.lastName} (${dataForm?.employee?.employmentRecordId})`}</strong>
                    </div>
                    <div>
                        <strong>{`${dataForm?.employee?.room?.name}`}</strong>
                    </div>
                </>
            );
        } else {
            setIsEmployee(false);
        }

        if (dataForm.room) {
            dataForm.room = setSearchFormat(dataForm.room, 'name', 'name');
        }

        setDataForm(dataForm.id);

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('ASSET_STATUS', dataForm.status);
        }

        if (dataForm.availabilityStatus) {
            setAvailability(dataForm.availabilityStatus);
        }

        if (dataForm.businessUnit) {
            setIsBusinessUnit(true);
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };
            setBusinessUnitText(
                <strong>
                    {dataForm.businessUnit.code
                        ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                        : dataForm.businessUnit.name}
                </strong>
            );

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        } else {
            setIsBusinessUnit(false);
        }

        setNfcTag(dataForm.nfcTag);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        objectMetadataForm.resetFields();
        setMetadata([]);
        setAssetType(undefined);
        setEditHeader(geti18nText('asset.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'assetType.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_TYPE.METADATA, {
            max: 100,
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setMetadata(result.data.content);
                }
            }
        });
    };

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} onKeyDown={onKeyDownTextAreaCustom} />;
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                    />
                );
            case 'BOOL':
                return <Checkbox />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} />;
            default:
                return <Input />;
        }
    };

    const onTypeChange = (value: any) => {
        if (value && value.id > 0) {
            fetchMetadata(value.id);
        }
    };

    const changeNfcTagStatus = () => {
        if (dataForm) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET.NFC_TAG + '/' + dataForm, undefined, {
                id: dataForm,
                nfcTag: false,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification(geti18nText('asset.button.deactive.nfc.success'));
                    props.setVisible(false);
                    onModalClose();
                } else {
                    errorNotification();
                }
            });
        }
    };

    const historyColumns = [
        {
            title: geti18nText('assetRelease.history.column.name'),
            dataIndex: ['file', 'name'],
        },
        {
            title: geti18nText('assetRelease.history.column.uploaded'),
            dataIndex: 'uploaded',
            render: (uploaded: any) => {
                if (uploaded) {
                    return getDateFormat(uploaded, 'DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('assetRelease.history.column.uploadedBy'),
            dataIndex: 'user',
            render: (user: any) => {
                if (user && user.person) {
                    return `${user.person.firstName} ${user.person.lastName}`;
                }
            },
        },
        {
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record.file && record.file.id && (
                                <Tooltip title={geti18nText('assetReturn.button.download')}>
                                    <FilePdfOutlined
                                        style={{ marginLeft: '10px', fontSize: '16px' }}
                                        onClick={() => {
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.file.id,
                                                null,
                                                record.file.name
                                            );
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.ASSET.EDIT}
                setValues={setValues}
                width={1200}
                form={form}
                goBack={() => history.goBack()}
                paramsId={id}
                shortcuts={true}
                onModalOpen={onModalOpen}
                setIsCreate={setIsCreate}
                checkIsFormChanged={false}
                addedData={{ module: 'asset' }}
                customFooterContent={canCreate() ? getCustomFooterContent : undefined}
                {...props}
                normalize={(values: any) => {
                    if (values.status) values.status = getEnumFormat(values.status);
                    if (values.employee) values.employee = getSearchFormat(values.employee);
                    if (values.room) values.room = getSearchFormat(values.room);
                    if (values.item) values.item = getSearchFormat(values.item);

                    if (values.businessUnit) values.businessUnit = getSearchFormat(values.businessUnit);

                    const metadataValues = objectMetadataForm.getFieldsValue();
                    let metadataReturn = [];

                    for (const [key, value] of Object.entries(metadataValues)) {
                        const metadataItem: any = metadata.find(
                            (item: any) => item.objectMetadata && item.objectMetadata.name === key
                        );
                        metadataReturn.push({ asset_type_metadata: { id: metadataItem.id }, value: value });
                    }

                    values.module = 'asset';

                    values.assetMetadata = metadataReturn;

                    return values;
                }}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate()}
            >
                <Row gutter={24}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={leftCollapseActiveKeys}
                            onChange={(key: any) => {
                                setLeftCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('asset.tab.1')}
                                key="1"
                                extra={
                                    <NyNoteModal
                                        note={note}
                                        modalTitle={
                                            geti18nText('asset.edit.title') + ' - ' + geti18nText('app.default.note')
                                        }
                                    />
                                }
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>

                                        <BusinessUnitSearch formStyle={{ display: 'none' }} />
                                        <Form.Item
                                            label={
                                                props && props.addedData && props.addedData.isFixedAsset
                                                    ? geti18nText('asset.acquisition.table.column.assetType')
                                                    : geti18nText('asset.edit.assetType')
                                            }
                                            name="assetType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                itemName="name"
                                                AddNewModalComponent={AssetTypeEdit}
                                                SearchPopupComponent={<AssetTypeIndex />}
                                                onChange={onTypeChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={
                                                props && props.addedData && props.addedData.isFixedAsset
                                                    ? geti18nText('asset.acquisition.table.column.assetModel')
                                                    : geti18nText('asset.edit.assetModel')
                                            }
                                            name="assetModel"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.ASSET_MODEL.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                itemName="name"
                                                AddNewModalComponent={AssetModelEdit}
                                                SearchPopupComponent={<AssetModelIndex />}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.edit.serialNumber')}
                                            name="serialNumber"
                                            rules={[
                                                {
                                                    required: !disabledInventoryNumber,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: !disabledInventoryNumber,
                                                },
                                            ]}
                                        >
                                            <Input disabled={disabledInventoryNumber && !dataForm} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('fixed.asset.edit.inventoryNumber')}
                                            name="serialNumber2"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    {/* <Col span={12}>
                                        <Form.Item label={geti18nText('asset.edit.assetPackage')} name="assetPackage">
                                            <NySearchField
                                                url={CONSTANTS_REQ.ASSET_PACKAGE.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                itemName="name"
                                                addedFilter={{
                                                    field: 'availabilityStatus',
                                                    condition: 'equals',
                                                    value: 1,
                                                }}
                                                AddNewModalComponent={AssetPackageEdit}
                                                SearchPopupComponent={<AssetPackageIndex />}
                                            />
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={12}>
                                        <ItemSearch
                                            addedFilter={{
                                                field: 'type',
                                                condition: 'equals',
                                                value: 4,
                                            }}
                                            addNewItem={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('asset.edit.assetCategory')} name="assetCategory">
                                            <NySearchField
                                                url={CONSTANTS_REQ.ASSET_CATEGORY.SEARCH}
                                                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                                searchBy="name || ' ' || code"
                                                itemName={[['text'], ['code']]}
                                                customItemNameLabel={'text (code)'}
                                                renderOption={(option: any) =>
                                                    `${option.text}${option.code ? ' (' + option.code + ')' : ''}`
                                                }
                                                AddNewModalComponent={AssetCategoryEdit}
                                                SearchPopupComponent={<AssetCategoryIndex />}
                                                setDefaultFilterValue={() => [
                                                    { field: 'active', condition: 'equals_bool', value: 1 },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {!isCreate && (
                                    <Row gutter={24}>
                                        {status.toString() === '3' ? (
                                            <Col span={12}>
                                                <Form.Item label={geti18nText('asset.edit.status')} name="status">
                                                    <NySearchField
                                                        options={getEnumArrayStatus('ASSET_STATUS')}
                                                        map={{ id: 'id', label: 'text' }}
                                                        searchBy="text"
                                                        style={{ width: '100%' }}
                                                        className={''}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col span={12}>
                                                <Form.Item label={geti18nText('asset.edit.status')} name="status">
                                                    <strong>{geti18nText('app.enum.ASSET_STATUS.' + status)}</strong>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('asset.edit.availabilityStatus')}
                                                name="availabilityStatus"
                                            >
                                                <strong>
                                                    {geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + availability)}
                                                </strong>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                {!isCreate && status.toString() === '3' && (
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('asset.edit.statusDescription')}
                                                name="statusDescription"
                                            >
                                                <Input.TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                <Row gutter={24}>
                                    {isEmployee ? (
                                        <Col span={12}>
                                            <Form.Item label={geti18nText('asset.edit.employee')} name="employee">
                                                {employeeText}
                                            </Form.Item>
                                        </Col>
                                    ) : isBusinessUnit ? (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('asset.edit.assignedUnit')}
                                                name="businessUnit"
                                            >
                                                {businessUnitText}
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <Col span={12}>
                                            <Form.Item label={geti18nText('asset.edit.employee')}>
                                                <strong>{geti18nText('asset.edit.storaged')}</strong>
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col span={12}>
                                        <RoomSearch
                                            customItemNameLabel={'name'}
                                            itemName={[['id'], ['name']]}
                                            label={geti18nText('fixed.asset.edit.location')}
                                            name="room"
                                            setDefaultFilterValue={setDefaultFilterValue}
                                        />
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Collapse
                            activeKey={rightCollapseActiveKeys}
                            onChange={(key: any) => {
                                setRightCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('asset.tab.2')} key="2">
                                <Form form={objectMetadataForm} component={false}>
                                    <Row gutter={24}>
                                        {metadata &&
                                            metadata.map((item: any) => {
                                                return (
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={item.objectMetadata.name}
                                                            name={item.objectMetadata.name}
                                                            rules={[
                                                                {
                                                                    required: item.objectMetadata.mandatory,
                                                                    message: geti18nText('app.default.required'),
                                                                },
                                                            ]}
                                                            valuePropName={
                                                                item.objectMetadata.dataType === 3 ? 'checked' : 'value'
                                                            }
                                                        >
                                                            {getDataTypeInput(item)}
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                </Form>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </NyDataEdit>
            {historyModalVisible && (
                <Modal
                    title={geti18nText('asset.history.info')}
                    visible={historyModalVisible}
                    onCancel={() => {
                        setHistoryModalVisible(false);
                    }}
                    okButtonProps={{ hidden: true }}
                    maskClosable={false}
                    width={1200}
                >
                    <Radio.Group
                        value={historyType}
                        buttonStyle="solid"
                        optionType="button"
                        onChange={(e: any) => {
                            setHistoryType(e.target.value);
                        }}
                        style={{ marginBottom: '8px' }}
                    >
                        <Radio.Button value={1}>{geti18nText('asset.history.liabilities.changes.info')}</Radio.Button>
                        <Radio.Button value={2}>{geti18nText('asset.history.equipment.changes.info')}</Radio.Button>
                        {isAdmin() && (
                            <Radio.Button value={3}>{geti18nText('asset.history.all.changes.info')}</Radio.Button>
                        )}
                    </Radio.Group>
                    {historyType != 1 ? (
                        <NyHistory id={dataForm} link={'asset'} type={historyType} />
                    ) : (
                        <AssetAssignHistoryIndex id={dataForm} isHistoryView={true}></AssetAssignHistoryIndex>
                    )}
                </Modal>
            )}
        </>
    );
};

export default AssetEdit;
