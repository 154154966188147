import { CloudDownloadOutlined } from '@ant-design/icons';
import { getColumnSearch, geti18nText, NyDataTable, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import moment from 'moment';
import { message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import RowAction from '../../../../components/row-action';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { downloadFile } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const JoppdIndex = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const history = useHistory();
    const table = useTableSettings();

    useHelper('payroll/joppd');

    const canCreate = () => {
        return PayrollRights.canCreateJoppd();
    };

    const download = (record: any) => {
        message.loading(geti18nText('import.text.loading'), 0);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.JOPPD.XML_DETAILS_DOWNLOAD,
            {
                id: record.id,
            },
            false,
            true
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = `JOPPD_${record.name}_${moment().format('yyyyMMDD')}.xml`;
                downloadFile(result);
            }
            message.destroy();
        });
    };

    const columns = [
        {
            title: geti18nText('joppd.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('joppd.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('joppd.table.column.yearAndDay'),
            dataIndex: 'yearAndDay',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('joppd.table.column.rowsCount'),
            dataIndex: 'rowsCount',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('joppd.table.column.recipientCount'),
            dataIndex: 'recipientCount',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <RowAction
                            component={'Download'}
                            record={record}
                            download={download}
                            downloadLabel={'menu.joppd'}
                            downloadIcon={
                                <CloudDownloadOutlined
                                    style={{ fontSize: '16px', paddingRight: '10px', float: 'left' }}
                                />
                            }
                        ></RowAction>
                    );
                }
            },
        },
    ];

    const onRowSelect = (item: any) => {
        if (item.id !== undefined) {
            history.push(`/joppd/${item.id}`);
        }
    };

    return (
        <NyDataTable
            nyId="human-joppd-table"
            url={CONSTANTS_REQ.JOPPD.LIST}
            buttonsClassName={'buttons-sticky'}
            onRowSelect={onRowSelect}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            hideNewButton={!canCreate()}
        />
    );
};

export default JoppdIndex;
