import { NySession } from '@nybble/nyreact';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, useParams } from 'react-router-dom';
import { setModalOpen } from '../../../slices/modalOpenSlice';

const ReRouteAsset = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (NySession.isUserAuthenticated()) {
            history.push('/webshop/codebooks/webshop-asset/asset/');
            dispatch(
                setModalOpen({
                    modal: 'assetEdit',
                    visible: true,
                    item: id,
                })
            );
            localStorage.removeItem('loginRedirectCustomUrl');
        }
    }, []);

    if (!NySession.isUserAuthenticated()) {
        localStorage.setItem('loginRedirectCustomUrl', '/asset/' + id.toString());
        return <Redirect to="/login" />;
    } else {
        return <></>;
    }
};

export default ReRouteAsset;
