import { geti18nText, NyDatePicker } from '@nybble/nyreact';
import { Alert, Checkbox, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { getDateFormat, setSearchFormat } from '../../utils/Utils';
import travelVehicle from '../../views/travel-warrant/views/travel-vehicle';
import TravelWarrantMap from '../travel-warrant-map/TravelWarrantMap';

const TravelWarrantStartEndIndex = ({
    travelWarrantId,
    isWizard = true,
    isAdmin = false,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isPlane,
    destinationJson,
    setDestinationJson,
    disableEdit = false,
    returnRouteInit = false,
}: any) => {
    const [formTravelWarrant] = Form.useForm();
    const [returnRouteValue, setReturnRouteValue] = useState<any>(false);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [showReturnRouteModal, setShowReturnRouteModal] = useState(false);
    const [returnFields, setReturnFields] = useState(false);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const [returnRouteWaypoints, setReturnRouteWaypoints] = useState<any>([]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);

    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);

    useEffect(() => {
        if (returnRouteInit) {
            setReturnRoute(true);
            formTravelWarrant.setFieldsValue({ returnRoute: true });
        }
    }, [returnRouteInit]);

    useEffect(() => {
        setValuesDates();
        if (isWizard) {
            setShowReturnRouteModal(true);
        }
    }, [travelWarrantId]);

    useEffect(() => {
        if (destinationJson != null) {
            setDestinationValues(destinationJson);
        }
    }, [destinationJson]);

    useEffect(() => {
        setReturnFields(returnRoute);
    }, [returnRoute]);

    const setValuesDates = () => {
        let data: any = {
            startDate: startDate,
            endDate: endDate,
        };
        if (!(getDateFormat(startDate, 'HH:mm') == '00:00' && getDateFormat(endDate, 'HH:mm') == '00:00')) {
            data['startDateTime'] = startDate;
            data['endDateTime'] = endDate;
        }
        formTravelWarrant.setFieldsValue(data);
    };

    function setDestinationValues(destinationJson: any) {
        let waypointsList: any = [];

        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        let initList = [];
        setReturnRoute(returnRouteInit);
        if (lastItem?.id == 9999) {
            const firstItem = waypointsList[0];
            setReturnRoute(true);
            const returnList = waypointsList.filter((x: any) => x.id >= 9000 && x.id != 9999);
            initList = JSON.parse(JSON.stringify(returnList));
            returnIntermediateWaypointsInit.current = initList;
            let list = [firstItem].concat(returnList).concat(lastItem);

            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
            formTravelWarrant.setFieldsValue({ returnRoute: true });

            setReturnWaypoints(JSON.parse(JSON.stringify(list)));
            setReturnRouteWaypoints(JSON.parse(JSON.stringify(list)));
        }
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);

        let data: any = {
            travelVehicle: setSearchFormat(travelVehicle, 'name', 'name'),
            destination: destinationJson[0]?.destination,
            startDate: startDate,
            endDate: endDate,
        };
        if (!(getDateFormat(startDate, 'HH:mm') == '00:00' && getDateFormat(endDate, 'HH:mm') == '00:00')) {
            data['startDateTime'] = startDate;
            data['endDateTime'] = endDate;
        }
        formTravelWarrant.setFieldsValue(data);
    }

    const disabledendDate = (current: any) => {
        if (formTravelWarrant.getFieldValue('startDate')) {
            return moment(current).add(1, 'days') < moment(formTravelWarrant.getFieldValue('startDate')).endOf('day');
        }
    };

    const handleReturnRouteModalClose = (value: any) => {
        formTravelWarrant.setFieldsValue({ returnRoute: value });
        setReturnRouteValue(value);
        setReturnRoute(value);
        setShowReturnRouteModal(false);
    };

    return (
        <>
            <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={formTravelWarrant}>
                <Row gutter={24}>
                    <Col span={24}>
                        {isWizard && (
                            <Alert
                                message={geti18nText('travelWarrantWizard.step2.date.info')}
                                type="info"
                                showIcon
                                style={{ margin: '5px' }}
                            />
                        )}
                        <Row gutter={24}>
                            <Col span={4}>
                                <Form.Item
                                    name="startDate"
                                    label={geti18nText('travelWarrantWizard.step2.startDate')}
                                    initialValue={startDate}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        showTime={false}
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="startDateTime"
                                    label={' '}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        defaultPickerValue={null}
                                        mode="time"
                                        format="HH:mm"
                                        style={{ width: '100%' }}
                                        autoFocus
                                        disabled={!isWizard}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="endDate"
                                    label={geti18nText('travelWarrantWizard.step2.endDate')}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        disabled={!isWizard}
                                        showTime={false}
                                        disabledDate={disabledendDate}
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="endDateTime"
                                    label={' '}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        defaultPickerValue={null}
                                        mode="time"
                                        format="HH:mm"
                                        disabled={!isWizard}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.step2.returnRoute')}
                                    name="returnRoute"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        style={{ width: '30%' }}
                                        checked={returnRoute}
                                        disabled={!isWizard}
                                        onChange={(event: any) => {
                                            setReturnRoute(event.target.checked);
                                            setReturnFields(event.target.checked);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <TravelWarrantMap
                                showMap={true}
                                form={formTravelWarrant}
                                waypoints={waypoints}
                                setWaypoints={setWaypoints}
                                maxWaypointsKey={maxWaypointsKey}
                                setMaxWaypointsKey={setMaxWaypointsKey}
                                nyTestId="map"
                                isPlane={isPlane}
                                returnRoute={returnFields}
                                returnWaypoints={returnWaypoints}
                                setReturnWaypoints={setReturnWaypoints}
                                returnIntermediateWaypointsInit={returnIntermediateWaypointsInit.current}
                                disabled={true}
                                disableEdit={disableEdit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {showReturnRouteModal && (
                <Modal
                    title={geti18nText('travelWarrantWizard.step2.returnRoute')}
                    visible={showReturnRouteModal}
                    width={350}
                    onOk={() => {
                        handleReturnRouteModalClose(true);
                    }}
                    onCancel={() => {
                        handleReturnRouteModalClose(false);
                    }}
                    okText={geti18nText('app.default.button.yes')}
                    cancelText={geti18nText('app.default.button.no')}
                    maskClosable={false}
                    centered={true}
                >
                    {geti18nText('travelWarrantWizard.step2.returnRoute.message')}
                </Modal>
            )}
        </>
    );
};
export default TravelWarrantStartEndIndex;
