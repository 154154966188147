import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
// import { addTab } from '../../../../../slices/tabsSlice';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import {
    errorNotification,
    getDateFormat,
    getFileList,
    getLoginRedirectUrl,
    getOrdinal,
    getWageErrorsMessages,
    numberFormat,
    okNotification,
} from '../../../../../utils/Utils';
import TravelWarrantActionModal from '../modal/TravelWarrantActionModal';

const TravelWarrantTableWaitingForApproval = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    canViewWageErrors = false,
}: any) => {
    const history = useHistory();
    const location: any = useLocation();
    const table = useTableSettings();
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [modalVisible, setModalVisible] = useState(false);
    const [travelWarrantId, setTravelWarrantId] = useState(undefined);
    const [globalForm, setGlobalForm] = useState<any>();
    const [travelEmployee, setTravelEmployee] = useState<any>();
    const [currency, setCurrency] = useState<any>(undefined);
    const [isPlane, setIsPlane] = useState<any>();
    const [files, setFiles] = useState<any>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const id = getLoginRedirectUrl();
        if (id) {
            setTravelWarrantId(id);
            setModalVisible(true);
        }
    }, [location]);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'equals', value: travelWarrantStatusEnum.WAITING_FOR_APPROVAL },
        ];
    };

    const changeStatus = (recordId: any, status: any, info: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: info,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                    okNotification(geti18nText('travelWarrantPersonal.sendToApproval.ok'));
                } else if (status == travelWarrantStatusEnum.REJECTED) {
                    okNotification(geti18nText('travelWarrantPersonal.cancelTrip.ok'));
                }
                setRefresh((refresh: number) => refresh + 1);
            } else {
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const getModalFiles = (cardId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'travel_warrant_id', condition: 'equals', value: cardId },
                ])
            ),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
    };

    const getModalData = (cardId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + cardId).then((result: any) => {
            if (result && result.status === RESPONSE.OK && result.data) {
                setGlobalForm(result.data);
                setTravelEmployee(result.data.employee);
                if (
                    result.data.advancePaymentCurrency &&
                    result.data.advancePaymentCurrency.id &&
                    result.data.advancePaymentCurrency.isoCode
                ) {
                    setCurrency({
                        id: result.data.advancePaymentCurrency.id,
                        text: result.data.advancePaymentCurrency.isoCode,
                    });
                }
                if (result.data.travelVehicle && result.data.travelVehicle.vehicleType == 3) {
                    setIsPlane(true);
                } else {
                    setIsPlane(false);
                }
                getModalFiles(cardId);
            }
        });
    };

    const initialColumns = [
        {
            title: geti18nText('travelWarrant.table.column.ordinal'),
            dataIndex: 'ordinal',
            width: '110px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record && record.ordinal && record.status > travelWarrantStatusEnum.DRAFT) {
                    return (
                        <>
                            <Tooltip title={geti18nText('menu.travel.warrant.details')}>
                                <Button
                                    type="link"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() =>
                                        // dispatch(
                                        //     addTab({
                                        //         title: 'travelWarrant.edit.title.short' + '-',
                                        //         addedData: record.id,
                                        //         path: '/travelWarrant/' + record.id,
                                        //     })
                                        // )
                                        history.push('/travel-warrant/travelWarrant/' + record.id)
                                    }
                                >
                                    {getOrdinal(record)}
                                </Button>
                            </Tooltip>
                            {getWageErrorsMessages(
                                record?.wageErrors,
                                canViewWageErrors && record?.status != travelWarrantStatusEnum.PAID
                            )}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.warrantDate'),
            dataIndex: 'warrantDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (warrantDate: any, record: any) => {
                if (record.warrantDate) {
                    return getDateFormat(record.warrantDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (person: any, record: any) => {
                if (record && record.person) {
                    return (
                        <>
                            {`${record.person.firstName} ${record.person.lastName} `}
                            {record.employee &&
                                record.employee.employmentRecordId &&
                                ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.destination'),
            dataIndex: 'destination',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWarrant.table.column.startDate'),
            dataIndex: 'startDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.travelVehicle'),
            dataIndex: ['travelVehicle', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH,
                'travelVehicle.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
            render: (travelVehicle: any, record: any) => {
                if (record && record.travelVehicle) {
                    return (
                        <Tooltip placement="topLeft" title={record.travelVehicle.name}>
                            {record.travelVehicle.name}
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.purpose'),
            dataIndex: 'purposeInfo',
            innerHeight: '12px',
            outerHeight: '12px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (purposeInfo: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={purposeInfo}>
                        {purposeInfo}
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.advance'),
            dataIndex: 'advancePaymentInCurrency',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('advancePaymentInCurrency'),
            render: (advancePaymentInCurrency: any, record: any) => {
                if (record.advancePaymentCurrency && record.advancePaymentCurrency.isoCode) {
                    return (
                        <div style={{ float: 'right' }}>
                            {advancePaymentInCurrency
                                ? numberFormat(advancePaymentInCurrency) +
                                  ' (' +
                                  record.advancePaymentCurrency.isoCode +
                                  ')'
                                : ''}
                        </div>
                    );
                } else {
                    return (
                        <div style={{ float: 'right' }}>
                            {advancePaymentInCurrency ? numberFormat(advancePaymentInCurrency) : ''}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <RowAction
                            component={'TravelWarrant'}
                            record={record}
                            changeStatus={changeStatus}
                            showTravelAction={() => {
                                setModalVisible(true);
                                setTravelWarrantId(record.id);
                                getModalFiles(record.id);
                                getModalData(record.id);
                            }}
                            fetchAccessRights
                        ></RowAction>
                    );
                }
            },
        },
    ];

    const onRowSelect = (row: any) => {
        setTravelWarrantId(row.id);
        setModalVisible(true);
    };

    return (
        <>
            <div className="unapproved-table">
                <NyDataTable
                    nyId="travel-warrant-travel-warrant-waiting-for-approval-table"
                    url={CONSTANTS_REQ.TRAVEL_WARRANT.LIST}
                    buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                    columns={initialColumns}
                    setDefaultSortOrder={sortOrder}
                    scroll={scroll}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    shortcuts={true}
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    hideNewButton={true}
                    onRowSelect={onRowSelect}
                    fetchWhenChange={refresh}
                />
            </div>
            <TravelWarrantActionModal
                travelWarrantId={travelWarrantId}
                setTravelWarrantId={setTravelWarrantId}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                refresh={refresh}
                setRefresh={setRefresh}
            />
        </>
    );
};

export default TravelWarrantTableWaitingForApproval;
