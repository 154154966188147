import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NyCalendar from '../../../../components/calendar';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const EmployeeCalendarIndex = ({ idProps, readonly = false }: any) => {
    const [canCreate, setCanCreate] = useState(HumanResourcesRights.canCreateEmployee());
    const { id } = useParams<any>();
    const [employeeGroupId, setEmployeeGroupId] = useState(undefined);
    const [shiftData, setShiftData] = useState([]);
    const [shiftType, setShiftType] = useState(undefined);
    const [shiftStartDate, setShiftStartDate] = useState<any>(undefined);

    useEffect(() => {
        if (id && id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_GROUP.GET_BY_EMPLOYEE + '/' + id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            if (result.data.shiftList) setShiftData(result.data.shiftList);
                            if (result.data.shift_type) setShiftType(result.data.shift_type);
                            if (result.data.shift_start_date) setShiftStartDate(moment(result.data.shift_start_date));
                        }
                    } else {
                        setShiftData([]);
                        setShiftType(undefined);
                        setShiftStartDate(undefined);
                    }
                }
            );
        } else {
            setShiftData([]);
            setShiftType(undefined);
            setShiftStartDate(undefined);
        }
    }, [employeeGroupId]);

    return (
        <NyCalendar
            data={shiftData}
            type={shiftType && shiftType === 1 ? 'daily' : 'weekly'}
            startDate={shiftStartDate}
        />
    );
};

export default EmployeeCalendarIndex;
