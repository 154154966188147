import { AdministrationRights } from '../../rights/administrationRights';
import { AssetRights } from '../../rights/assetRights';
import { InventoryRights } from '../../rights/inventoryRights';
import { MealsRights } from '../../rights/mealsRights';
import { ServicesRights } from '../../rights/servicesRights';
import MealIndex from './views/meals';
import DeliverySpotIndex from './views/meals/delivery-spot';
import MealGroupIndex from './views/meals/meal-group';
import MealGroupOrderIndex from './views/meals/meal-group-orders';
import MealLogTableIndex from './views/meals/meal-log-table';
import MealMenusIndex from './views/meals/meal-menus';
import MealOrdersIndex from './views/meals/meal-orders';
import MealPaymentIndex from './views/meals/meal-payment';
import MealsOverviewIndex from './views/meals/meals-overview';
import MealsSettings from './views/meals/meals-settings';
import NotWorkingDaysIndex from './views/meals/not-working-days';
import PriceListIndex from './views/meals/price-list';
import RestaurantIndex from './views/meals/restaurant';
import WebshopOverviewIndex from './views/webshop';
import WebshopAssetIndex from './views/webshop-asset';
import AssetIndex from './views/webshop-asset/asset';
import AssetCategoryIndex from './views/webshop-asset/asset-category';
import AssetInventoryIndex from './views/webshop-asset/asset-inventory';
import AssetModelIndex from './views/webshop-asset/asset-model';
import AssetNotificationSettings from './views/webshop-asset/asset-notification-settings';
import AssetOrderIndex from './views/webshop-asset/asset-order';
import AssetOrderViewIndex from './views/webshop-asset/asset-order-view';
import AssetOrderEdit from './views/webshop-asset/asset-order/edit';
import AssetOverviewIndex from './views/webshop-asset/asset-overview';
import AssetReleaseIndex from './views/webshop-asset/asset-release';
import AssetReturnIndex from './views/webshop-asset/asset-return';
import AssetTypeIndex from './views/webshop-asset/asset-type';
import AssetEdit from './views/webshop-asset/asset/edit';
import EmployeeAssetIndex from './views/webshop-asset/employee-asset/EmployeeAssetIndex';
import EmployeeAssetOrderTable from './views/webshop-asset/employee-asset/EmployeeAssetOrderTable';
import ItemAsset from './views/webshop-asset/item-asset';
import ItemGroupAsset from './views/webshop-asset/item-group-asset';
import WebshopItemIndex from './views/webshop-item';
import EmployeeInventoryOrderTable from './views/webshop-item/employee-item/EmployeeInventoryOrderTable';
import InventoryNotificationSettings from './views/webshop-item/inventory-notification-settings';
import InventoryOrderIndex from './views/webshop-item/inventory-order';
import InventoryOrderViewIndex from './views/webshop-item/inventory-order-view';
import InventoryOrderEdit from './views/webshop-item/inventory-order/edit';
import InventoryReleaseIndex from './views/webshop-item/inventory-release';
import ItemGroupMaterial from './views/webshop-item/item-group-material';
import ItemMaterial from './views/webshop-item/item-material';
import WebshopServicesIndex from './views/webshop-services';
import EmployeeServicesOrderTable from './views/webshop-services/employee-services/EmployeeServicesOrderTable';
import ItemGroupService from './views/webshop-services/item-group-service';
import ItemService from './views/webshop-services/item-service';
import ServicesNotificationSettings from './views/webshop-services/services-notification-settings';
import ServicesOrderIndex from './views/webshop-services/services-order';
import ServicesOrderViewIndex from './views/webshop-services/services-order-view';
import ServicesOrderEdit from './views/webshop-services/services-order/edit';
import ServicesReleaseIndex from './views/webshop-services/services-release';

export const WebshopModuleRoutes: any = [
    {
        path: '/webshop',
        exact: true,
        component: WebshopOverviewIndex,
        role: AssetRights.canViewWebshopModuleRoutes(),
    },
    {
        path: '/webshop/webshop-item',
        exact: true,
        component: WebshopItemIndex,
        role: InventoryRights.canViewRequestMenu(),
    },
    {
        path: '/webshop/webshop-asset',
        exact: true,
        component: WebshopAssetIndex,
        role: AssetRights.canViewRequestMenu(),
    },
    {
        path: '/webshop/webshop-services',
        exact: true,
        component: WebshopServicesIndex,
        role: ServicesRights.canViewRequestMenu(),
    },
    {
        path: '/webshop/codebooks/webshop-services/item-group-service',
        exact: true,
        component: ItemGroupService,
        role: ServicesRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-services/item-service',
        exact: true,
        component: ItemService,
        role: ServicesRights.canViewCodebooks(),
    },
    {
        path: '/webshop/webshop-services/services-release',
        exact: true,
        component: ServicesReleaseIndex,
        role: ServicesRights.canViewReleaseRoutes(),
    },
    {
        path: '/webshop/webshop-services/settings/services-notification-settings',
        exact: true,
        component: ServicesNotificationSettings,
        role: ServicesRights.canCreateSettingsMenu(),
    },
    {
        path: '/webshop/webshop-services/settings/services-order-view',
        exact: true,
        component: ServicesOrderViewIndex,
        role: ServicesRights.canCreateSettingsMenu(),
    },
    {
        path: '/webshop/webshop-services/services-order',
        exact: true,
        component: ServicesOrderIndex,
        notificationType: 9,
        role: ServicesRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-services/services-order/:id',
        exact: true,
        component: ServicesOrderEdit,
        notificationType: 9,
        role: ServicesRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/asset-type',
        exact: true,
        component: AssetTypeIndex,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/asset-model',
        exact: true,
        component: AssetModelIndex,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/asset',
        exact: true,
        component: AssetIndex,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/asset/:id',
        exact: true,
        component: AssetEdit,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/asset-category',
        exact: true,
        component: AssetCategoryIndex,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/item-group-asset',
        exact: true,
        component: ItemGroupAsset,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-asset/item-asset',
        exact: true,
        component: ItemAsset,
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/webshop-asset/settings/asset-order-view',
        exact: true,
        component: AssetOrderViewIndex,
        role: AssetRights.canCreateSettingsMenu(),
    },
    {
        path: '/webshop/webshop-asset/settings/asset-notification-settings',
        exact: true,
        component: AssetNotificationSettings,
        role: AssetRights.canCreateSettingsMenu(),
    },
    {
        path: '/webshop/webshop-asset/asset-order',
        exact: true,
        notificationType: 8,
        component: AssetOrderIndex,
        role: AssetRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-asset/asset-order/:id',
        component: AssetOrderEdit,
        role: AssetRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-asset/asset-release',
        exact: true,
        component: AssetReleaseIndex,
        role: AssetRights.canViewReleaseRoutes(),
    },
    {
        path: '/webshop/webshop-asset/asset-return',
        exact: true,
        component: AssetReturnIndex,
        role: AssetRights.canViewReturnRoutes(),
    },
    {
        path: '/webshop/webshop-asset/asset-overview',
        exact: true,
        component: AssetOverviewIndex,
        role: AssetRights.canViewOverviewRoutes(),
    },
    {
        path: '/webshop/webshop-asset/asset-inventory',
        exact: true,
        component: AssetInventoryIndex,
        role: AssetRights.canViewInventoryRoutes(),
    },
    {
        path: '/webshop/codebooks/webshop-item/item-group-material',
        exact: true,
        component: ItemGroupMaterial,
        role: InventoryRights.canViewCodebooks(),
    },
    {
        path: '/webshop/codebooks/webshop-item/item-material',
        exact: true,
        component: ItemMaterial,
        role: InventoryRights.canViewCodebooks(),
    },
    {
        path: '/webshop/webshop-item/settings/inventory-order-view',
        exact: true,
        component: InventoryOrderViewIndex,
        role: InventoryRights.canCreateSettingsMenu(),
    },
    {
        path: '/webshop/webshop-item/settings/inventory-notification-settings',
        exact: true,
        component: InventoryNotificationSettings,
        role: InventoryRights.canCreateSettingsMenu(),
    },
    {
        path: '/webshop/webshop-item/inventory-order',
        exact: true,
        component: InventoryOrderIndex,
        notificationType: 7,
        role: InventoryRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-item/inventory-order/:id',
        component: InventoryOrderEdit,
        role: InventoryRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-item/inventory-release',
        exact: true,
        component: InventoryReleaseIndex,
        role: InventoryRights.canViewReleaseRoutes(),
    },
    {
        path: '/webshop/codebooks/meals/restaurant',
        i18n: 'menu.meals.restaurant',
        exact: true,
        component: RestaurantIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/codebooks/meals/delivery-spot',
        i18n: 'menu.meals.deliverySpot',
        exact: true,
        component: DeliverySpotIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/meals',
        i18n: 'menu.meals',
        exact: true,
        component: MealsOverviewIndex,
        notificationType: 26,
        role: MealsRights.isOnlyMealsUserMenu(),
    },
    {
        path: '/webshop/meals/meals-settings',
        exact: true,
        component: MealsSettings,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/codebooks/meals/meal',
        exact: true,
        component: MealIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/codebooks/meals/meal-group',
        exact: true,
        component: MealGroupIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/codebooks/meals/price-list',
        exact: true,
        component: PriceListIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/codebooks/meals/not-working-days',
        exact: true,
        component: NotWorkingDaysIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/codebooks/meals/meal-menus',
        exact: true,
        component: MealMenusIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/meals/meal-orders',
        exact: true,
        component: MealOrdersIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/meals/meal-payment',
        exact: true,
        component: MealPaymentIndex,
        role: MealsRights.isMealsAdminMenu(),
        isForHnb: true,
    },
    {
        path: '/webshop/meals/meal-group-orders',
        exact: true,
        component: MealGroupOrderIndex,
        role: MealsRights.isMealsAdminMenu(),
    },
    {
        path: '/webshop/meals/meal-log-table',
        exact: true,
        component: MealLogTableIndex,
        role: AdministrationRights.isAdminMenu(),
    },
    {
        path: '/webshop/webshop-asset/my-asset',
        exact: true,
        component: EmployeeAssetIndex,
        i18n: 'dashboard.statistic.employee.asset.count',
        role: AssetRights.canViewCodebooks(),
    },
    {
        path: '/webshop/webshop-item/my-inventory-order',
        exact: true,
        component: EmployeeInventoryOrderTable,
        i18n: 'dashboard.statistic.inventory.order.count',
        role: InventoryRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-asset/my-asset-order',
        exact: true,
        component: EmployeeAssetOrderTable,
        i18n: 'dashboard.statistic.asset.order.count',
        role: AssetRights.canViewOrderMenu(),
    },
    {
        path: '/webshop/webshop-services/my-services-order',
        exact: true,
        component: EmployeeServicesOrderTable,
        i18n: 'dashboard.statistic.services.order.count',
        role: ServicesRights.canViewOrderMenu(),
    },
];
