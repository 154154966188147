import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import _ from 'lodash';

export interface IApplicationSetting {
    applicationSettingName: any;
}

export const GetApplicationSetting = (
    { applicationSettingName }: IApplicationSetting,
    returnBoolValue: boolean = false
) => {
    const { applicationSettings } = useSelector((state: RootState) => state.applicationSettings);
    if (applicationSettings != undefined && !_.isEmpty(applicationSettings)) {
        const applicationSetting = applicationSettings?.filter(
            (setting: any) => setting.settingKey == applicationSettingName
        );
        if (Array.isArray(applicationSetting)) {
            if (applicationSetting.length == 1) {
                if (returnBoolValue) {
                    return applicationSetting[0]?.value
                        ? applicationSetting[0]?.value == 1
                            ? true
                            : false
                        : applicationSetting[0]?.defaultValue == 1
                        ? true
                        : false;
                }
                return applicationSetting[0]?.value ?? applicationSetting[0]?.defaultValue;
            } else if (applicationSetting.length > 1) {
                let applicationSettingValues = applicationSetting.map((val: any) => {
                    if (returnBoolValue) {
                        return {
                            value: val.value ? (val.value == 1 ? true : false) : val.defaultValue == 1 ? true : false,
                        };
                    } else
                        return {
                            value: val.value ?? val.defaultValue,
                        };
                });
                return applicationSettingValues;
            }
        } else {
            if (returnBoolValue) {
                return applicationSetting.value
                    ? applicationSetting.value == 1
                        ? true
                        : false
                    : applicationSetting.defaultValue == 1
                    ? true
                    : false;
            }
            return applicationSetting.value ?? applicationSetting.defaultValue;
        }
    }
};
