import { geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { customRenderTextOption } from '../../utils/Utils';
import TravelWageEdit from '../travel-wage/edit';
import TravelWageIndex from '../travel-warrant/views/travel-wage';

const TravelWageSearch = ({
    personal = undefined,
    travelWage,
    travelWageOnChange,
    countryId,
    employeeId = null,
    disabled = false,
}: any) => {
    const travelWageFilter = () => {
        if (personal) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'country.id', condition: 'equals', value: countryId },
                { field: 'forEmployee', condition: 'equals_bool', value: 1 },
            ];
        }

        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'country.id', condition: 'equals', value: countryId },
        ];
    };

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    return (
        <Form.Item
            label={geti18nText('travelWarrantWage.edit.travelWage')}
            name="travelWage"
            rules={[
                {
                    required: true,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={personal ? CONSTANTS_REQ.TRAVEL_WAGE.EMPLOYEE_SEARCH : CONSTANTS_REQ.TRAVEL_WAGE.SEARCH}
                map={{ id: 'id', label: 'name', text: 'name', name: 'name', nonStandardWageNr: 'nonStandardWageNr' }}
                searchBy="name"
                renderOption={customRenderTextOption}
                SearchPopupComponent={
                    <TravelWageIndex
                        isSearchIndex={true}
                        readonly={!canCreate()}
                        defaultFilteredValue={travelWageFilter()}
                    />
                }
                AddNewModalComponent={canCreate() ? TravelWageEdit : null}
                nyTestId="travel-wage"
                onChange={travelWageOnChange}
                setDefaultFilterValue={() => {
                    if (employeeId) {
                        return [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'country.id', condition: 'equals', value: countryId },
                            { field: 'employeeId', condition: 'equals', value: employeeId },
                        ];
                    } else {
                        return [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'country.id', condition: 'equals', value: countryId },
                        ];
                    }
                }}
                value={travelWage}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default TravelWageSearch;
