import {
    BankOutlined,
    HomeOutlined,
    MailOutlined,
    MobileOutlined,
    OrderedListOutlined,
    PhoneOutlined,
    SolutionOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Avatar, Card, Tabs } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useParams } from 'react-router-dom';
import CustomHistory from '../../../../components/ny-history/CustomHistory';
import PDFPreview from '../../../../components/pdf-preview';
import NyReportButton from '../../../../components/report-button';
import UserInfo from '../../../../components/user-info';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { updateHistoryI18n } from '../../../../slices/historySlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import EmployeeAssetIndex from '../../../webshop/views/webshop-asset/employee-asset/EmployeeAssetIndex';
import ContractsCTRIndex from '../contract/contractsCTR';
import EmployeeEducationIndex from '../education/employee-education';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';
import FormalEducationIndex from '../formal-education';
import EmployeeHealthCheckup from './employee-health-checkup';
import EmployeeAdditionIndex from './EmployeeAdditionIndex';
import EmployeeCalendarIndex from './EmployeeCalendarIndex';
import EmployeeDeleveragingReportModal from './EmployeeDeleveragingReportModal';
import EmployeeFamilyMemberIndex from './EmployeeFamilyMemberIndex';
import EmployeeGeneralIndex from './EmployeeGeneralIndex';
import EmployeeRewardsIndex from './EmployeeRewardsIndex';
import EmployeeSeniorityIndex from './EmployeeSeniorityIndex';

const { TabPane } = Tabs;

const EmployeeEdit = () => {
    const { id } = useParams<any>();
    const dispatch = useDispatch();
    const location = useLocation();
    useHelper('human/employee');

    const { employee } = useSelector((state: RootState) => state.employee);
    const checkedID = id ?? employee.id;
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [isCreate, setIsCreate] = useState(checkedID && checkedID !== 'create' && checkedID > 0 ? false : true);
    const [tabKey, setTabKey] = useState<any>(isCreate ? 'general' : 'overview');
    const [showTooltip, setShowTooltip] = useState(false);
    const [resultData, setResultData] = useState<any>();
    const [employeeId, setEmployeeId] = useState<any>(checkedID);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const [refresh, setRefresh] = useState<any>(null);

    const showMobilization = () => {
        return !HumanResourcesRights.canViewEDRModal() || HumanResourcesRights.isAdmin();
    };

    const disabledHistoryTab = () => {
        return (
            (isCreate || !HumanResourcesRights.canReadEmployeeChangelog()) &&
            !NySession.hasAnyRole(['ROLE_MOBILIZATION_EMPLOYEE_VIEW'])
        );
    };

    const showTab = () => {
        return !(
            NySession.hasAnyRole(['ROLE_MOBILIZATION_EMPLOYEE_VIEW']) &&
            !NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_EMPLOYEE_VIEW', 'ROLE_HUMAN_RESOURCES_USER'])
        );
    };

    const disableForMobilizationView = !showTab();

    const isHrUser = NySession.hasAnyRole(HumanResourcesRights.canViewHumanResourcesUser());

    const isNotHRAdmin = !(HumanResourcesRights.isAdmin() || HumanResourcesRights.canCreateEmployee());

    const onKeyDown = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
            if (!isMobile) {
                setShowTooltip(true);
            }
        }
    };

    const onKeyUp = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        if (location?.state) {
            const state: any = location.state;
            delete location.state;
            if (state.id && state.activeTab) {
                let disabledTab = isCreate;
                if (state.activeTab === 'history') {
                    disabledTab = disabledHistoryTab();
                }
                if (!disabledTab) {
                    setTabKey(state.activeTab);
                }
            }
        }
    }, [location]);

    useEffect(() => {
        fetchEmployeeData();
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    useEffect(() => {
        if (resultData) {
            let name = resultData.person.firstName + ' ' + resultData.person.lastName;
            name = resultData.employmentRecordId ? name + ' (MB: ' + resultData.employmentRecordId + ')' : name;
            dispatch(updateHistoryI18n({ path: location.pathname, args: [name] }));
        }
    }, [resultData]);

    useEffect(() => {
        setIsCreate(checkedID && checkedID !== 'create' && checkedID > 0 ? false : true);
        fetchEmployeeData();
    }, [checkedID]);

    const iconStyle: any = {
        fontSize: '16px',
        paddingRight: '2px',
        paddingTop: '2px',
        float: 'left',
    };

    const fetchEmployeeData = () => {
        if (!isCreate) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + checkedID).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setResultData(result.data);
                    }
                }
            });
        }
    };

    const getBusinessUnitEngName = (resultData: any) => {
        const translationItems: any = resultData?.businessUnit?.nameFieldTranslationList;
        if (translationItems?.length > 0) {
            const engTranslationItem = translationItems.filter(
                (item: any) =>
                    item?.translationLanguage?.id &&
                    item?.translationLanguage?.active &&
                    Object.values([
                        'Engleski jezik',
                        'engleski jezik',
                        'Eng. jezik',
                        'eng. jezik',
                        'Eng jezik',
                        'eng jezik',
                        'Engleski',
                        'engleski',
                        'English',
                        'english',
                        'Eng',
                        'Eng.',
                        'eng',
                        'eng.',
                    ]).includes(item?.translationLanguage.name)
            );
            return (engTranslationItem && engTranslationItem[0] && engTranslationItem[0].name) ?? '';
        }

        return '';
    };

    const employeeDescription = (
        <div style={{ color: theme == 'light' || theme == 'default' ? 'black' : 'white' }}>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <div>
                    <OrderedListOutlined /> {geti18nText('employee.edit.eployment.record')}:{' '}
                    <strong>{resultData && resultData.employmentRecordId}</strong>
                </div>
                <div>
                    <HomeOutlined /> {geti18nText('employee.edit.employment.room')}:{' '}
                    <strong>{resultData && resultData.room && resultData.room.name}</strong>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <div>
                    <BankOutlined /> {geti18nText('employee.mobilization.edit.businessUnit')}:{' '}
                    <strong>
                        {resultData &&
                            resultData.workplaceDetails &&
                            resultData.workplaceDetails.workplace &&
                            resultData.workplaceDetails.workplace.businessUnit &&
                            resultData.workplaceDetails.workplace.businessUnit.name}
                    </strong>
                </div>
                <div>
                    <BankOutlined /> {geti18nText('employee.mobilization.edit.businessUnit.eng')}:{' '}
                    <strong>{getBusinessUnitEngName(resultData)}</strong>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <div>
                    <SolutionOutlined /> {geti18nText('employee.edit.professional.vocation')}:{' '}
                    <strong>
                        {resultData &&
                            resultData.workplaceDetails &&
                            resultData.workplaceDetails.workplace &&
                            resultData.workplaceDetails.workplace.vocation &&
                            resultData.workplaceDetails.workplace.vocation.name}
                    </strong>
                </div>
                <div>
                    <SolutionOutlined /> {geti18nText('employee.edit.professional.vocation.eng')}:{' '}
                    <strong>
                        {resultData &&
                            resultData.workplaceDetails &&
                            resultData.workplaceDetails.workplace &&
                            resultData.workplaceDetails.workplace.vocation &&
                            resultData.workplaceDetails.workplace.vocation.nameEng}
                    </strong>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <div>
                    <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-comment" />{' '}
                    {geti18nText('employee.edit.professional.vocation.description')}:{' '}
                    <strong>
                        {resultData &&
                            resultData.workplaceDetails &&
                            resultData.workplaceDetails.workplace &&
                            resultData.workplaceDetails.workplace.vocationDescription &&
                            resultData.workplaceDetails.workplace.vocationDescription.name}
                    </strong>
                </div>
                <div>
                    <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-comment" />{' '}
                    {geti18nText('employee.edit.professional.vocation.description.eng')}:{' '}
                    <strong>
                        {resultData &&
                            resultData.workplaceDetails &&
                            resultData.workplaceDetails.workplace &&
                            resultData.workplaceDetails.workplace.vocationDescription &&
                            resultData.workplaceDetails.workplace.vocationDescription.nameEng}
                    </strong>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <div>
                    <PhoneOutlined /> {geti18nText('employee.contact.edit.phone')}:{' '}
                    <strong>
                        {resultData && resultData.contact && resultData.contact.phone ? resultData.contact.phone : '-'}
                    </strong>
                </div>
                <div>
                    <MobileOutlined /> {geti18nText('employee.contact.edit.mobile')}:{' '}
                    <strong>
                        {resultData && resultData.contact && resultData.contact.mobile
                            ? resultData.contact.mobile
                            : '-'}
                    </strong>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <div>
                    <MailOutlined /> {geti18nText('employee.contact.edit.email')}:{' '}
                    <strong>
                        {resultData && resultData.contact && resultData.contact.email ? resultData.contact.email : '-'}
                    </strong>
                </div>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {!isCreate && (
                <div>
                    <Card>
                        <Meta
                            avatar={
                                resultData && resultData.person && resultData.person.fileId ? (
                                    <Avatar
                                        size={64}
                                        src={
                                            CONSTANTS_REQ.FILES.DOWNLOAD +
                                            '/' +
                                            resultData.person.fileId +
                                            '?tenant=' +
                                            NySession.getUser().tenantId
                                        }
                                    />
                                ) : (
                                    <Avatar size={64} icon={<UserOutlined />} />
                                )
                            }
                            title={
                                resultData &&
                                resultData.person &&
                                resultData.person.firstName + ' ' + resultData.person.lastName
                            }
                            description={employeeDescription}
                            style={{
                                display: 'inline-block',
                                marginRight: '10px',
                            }}
                        />
                        <div style={{ float: 'right', display: 'inline-flex' }}>
                            {resultData?.workplaceDetails?.workplace?.id && (
                                <div style={{ marginLeft: '15px' }}>
                                    <PDFPreview
                                        url={CONSTANTS_REQ.REPORT.WORKPLACE}
                                        customParms={{
                                            workplaceId: resultData?.workplaceDetails?.workplace?.id,
                                        }}
                                        subreportType={'WORKPLACE'}
                                        buttoni18nText={'workplace.button.generateReport.action'}
                                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.WORKPLACE)}
                                        customDateFormat={moment().format('yyyyMMDD')}
                                        columns={false}
                                        customButtonText={geti18nText(
                                            'employee.workplace.button.generateReport.action'
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        {!HumanResourcesRights.canViewEDRModal() && (
                            <div style={{ float: 'right', marginLeft: '15px' }}>
                                <EmployeeDeleveragingReportModal employeeId={checkedID} />
                            </div>
                        )}
                        <div style={{ float: 'right', marginLeft: '15px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.CAREER_DEVELOPMENT}
                                customParms={{ employeeIds: Number(employeeId) }}
                                subreportType={'EMPLOYEE_CAREER_DEVELOPMENT'}
                                buttoni18nText={'report.careerDevelopment'}
                                fileName={geti18nText(
                                    'app.enum.SUBREPORT_TYPE.' + EnSubreportType.EMPLOYEE_CAREER_DEVELOPMENT
                                )}
                                customIconStyle={iconStyle}
                            />
                        </div>
                    </Card>
                </div>
            )}
            <Tabs
                activeKey={tabKey}
                type="card"
                className="tabs-sticky"
                id="employee-edit-tabs"
                onChange={(key: any) => setTabKey(key)}
                style={{ maxHeight: '66.8vh' }}
            >
                <TabPane tab={geti18nText('travelWarrantWizard.step.6')} key="overview" disabled={isCreate}>
                    <UserInfo id={employeeId} />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.general')} key="general">
                    <EmployeeGeneralIndex
                        setEmployeeId={setEmployeeId}
                        readonly={isNotHRAdmin || disableForMobilizationView}
                        disableIdentityDocumentUpload={disableForMobilizationView}
                    />
                </TabPane>
                {/*
					<TabPane tab={geti18nText('employee.tab.bank_accounts')} key="bank-account" disabled={isCreate}>
						<EmployeeBankAccountIndex />
						</TabPane>
				*/}
                {showTab() && (
                    <TabPane tab={geti18nText('employee.tab.contract')} key="contract" disabled={isCreate}>
                        {/* <EmploymentContractIndex /> */}
                        <ContractsCTRIndex
                            defaultFilterValue={[
                                { field: 'employeeId', condition: 'equals', value: checkedID },
                                { field: 'annex', condition: 'equals_bool', value: 0 },
                                { field: 'active', condition: 'equals_bool', value: 1 },
                            ]}
                            showHeader={false}
                            employeeId={checkedID}
                            mark={'HR'}
                            scroll={{ y: 300, x: 800 }}
                            disabled={isNotHRAdmin}
                        />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('employee.tab.familyMembers')} key="family" disabled={isCreate}>
                        <EmployeeFamilyMemberIndex idProps={id} readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane
                        tab={geti18nText('employee.tab.salarySupplements')}
                        key="salary-supplements"
                        disabled={isCreate}
                    >
                        <EmployeeAdditionIndex idProps={id && Number(id)} readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {/*
                <TabPane tab={geti18nText('employee.tab.insurance')} key="insurance" disabled={isCreate}>
                    <EmployeePayrollInsuranceIndex />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.addition')} key="addition" disabled={isCreate}>
                    <EmployeePayrollAdditionIndex />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.deduction')} key="deduction" disabled={isCreate}>
                    <EmployeePayrollDeductionIndex />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.tax.deduction')} key="tax-deduction" disabled={isCreate}>
                    <EmployeeTaxDeductionIndex />
                </TabPane>
				*/}
                {showTab() && (
                    <TabPane tab={geti18nText('employee.tab.asset')} key="asset" disabled={isCreate}>
                        <EmployeeAssetIndex readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('employeeGroup.calendar')} key="calendar" disabled={isCreate}>
                        <EmployeeCalendarIndex />
                    </TabPane>
                )}
                {showMobilization() && showTab() && (
                    <TabPane
                        tab={geti18nText('employee.mobilization.edit.tab.2.2')}
                        key="mobilization"
                        disabled={isCreate}
                    >
                        <EmployeeWorkObligationEdit
                            id={checkedID}
                            isEmployeeForm={true}
                            setRefresh={setRefresh}
                            refresh={refresh}
                        />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('employee.tab.seniority')} key="employee-seniority" disabled={isCreate}>
                        <EmployeeSeniorityIndex id={checkedID} readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('employee.tab.health.checkup')} disabled={isCreate}>
                        <EmployeeHealthCheckup readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('formal.education.tab')} key="formal-education" disabled={isCreate}>
                        <FormalEducationIndex readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('rewards.tab')} key="rewards" disabled={isCreate}>
                        <EmployeeRewardsIndex readonly={isNotHRAdmin} />
                    </TabPane>
                )}
                {showTab() && (
                    <TabPane tab={geti18nText('employee.tab.education')} key="education" disabled={isCreate}>
                        <EmployeeEducationIndex
                            showHeader={false}
                            search={[
                                { field: 'active', condition: 'equals_bool', value: 1 },
                                { field: 'employee_id', condition: 'equals', value: checkedID },
                            ]}
                            activeList={1}
                            employeeId={id}
                        />
                    </TabPane>
                )}
                <TabPane tab={geti18nText('employee.tab.history')} key="history" disabled={disabledHistoryTab()}>
                    <CustomHistory
                        url={CONSTANTS_REQ.EMPLOYEE.CHANGELOG}
                        id={id}
                        type="employee"
                        showOpenModalButton={false}
                    />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default EmployeeEdit;
