import { geti18nText, NySearchField, NySpinner } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import useEnum from '../../../../hooks/useEnum';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customEmployeeRenderOption, getEnumArray } from '../../../../utils/Utils';
import EmployeeIndex from '../employee';

const Mobilization = ({ setExecutorVisible, executorVisbile, form, loading }: any) => {
    const enMobilizationFunction = useEnum('MOBILIZATION_FUNCTION');

    const isMobilizationAdmin = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <Form form={form} layout="vertical">
                    {isMobilizationAdmin() && (
                        <>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employee.mobilization.edit.mobilizationFunction')}
                                        name="mobilizationFunction"
                                    >
                                        <NySearchField
                                            options={getEnumArray('MOBILIZATION_FUNCTION')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="mobilizationFunction"
                                            className=""
                                            onChange={(value: any) => {
                                                if (value?.id == enMobilizationFunction.DEPUTY_EXECUTOR) {
                                                    setExecutorVisible(true);
                                                } else {
                                                    setExecutorVisible(false);
                                                }
                                            }}
                                            mustGetPopupContainer={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employee.mobilization.edit.mobilizationTime')}
                                        name="mobilizationTime"
                                    >
                                        <NySearchField
                                            options={getEnumArray('MOBILIZATION_TIME')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="mobilizationTime"
                                            className=""
                                            mustGetPopupContainer={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {executorVisbile && (
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('employee.mobilization.edit.executor')}
                                            name="executor"
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                renderOption={customEmployeeRenderOption}
                                                customItemNameLabel={'firstName lastName'}
                                                order="person.last_name, person.first_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                SearchPopupComponent={
                                                    <EmployeeIndex disabled={true} parentKey="military-records" />
                                                }
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label={geti18nText('employee.contact.edit.email')}
                                name={['contact', 'email']}
                                rules={[
                                    {
                                        type: 'email',
                                        message: geti18nText('app.default.valid.email'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={geti18nText('employee.contact.edit.mobile')} name={['contact', 'mobile']}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={geti18nText('employee.mobilization.edit.phone')}
                                name={['contact', 'phone']}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default Mobilization;
