import { useRef, useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySearchField, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import ProfessionalQualificationIndex from '../professional-qualification';
import ProfessionalQualificationEdit from '../professional-qualification/edit';
import moment from 'moment';
import VocationGroupIndex from '.';
import VocationGroupSearch from './search';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const VocationGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('vocation.group.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);

    const [Id, setId] = useState<any>(undefined);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const setVocationGroupFilterValue = () => {
        return [{ field: 'id', condition: 'not_equals', value: Id }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.id) {
            setId(dataForm.id);
        }

        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('vocation.group.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.parent) {
            dataForm.parent = setSearchFormat(dataForm.parent);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onEndDateChange = (value: any) => {
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('vocation.group.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.VOCATION_GROUP.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
                if (values.validTo) values.validTo = getDateFormat(values.validTo);

                if (values.parent) values.parent = getSearchFormat(values.parent);
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.group.edit.code')}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.group.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <VocationGroupSearch
                        name="parent"
                        label={geti18nText('vocation.group.edit.parent.code')}
                        setDefaultFilterValue={Id ? setVocationGroupFilterValue : undefined}
                    />
                    {/*  <Form.Item label={geti18nText('vocation.group.edit.parent.code')} name="parent">
                        <NySearchField
                            url={CONSTANTS_REQ.VOCATION_GROUP.SEARCH}
                            map={{ id: 'id', label: 'text', text: 'name' }}
                            searchBy="text"
                            customItemNameLabel={'text (code)'}
                            setDefaultFilterValue={Id ? setVocationGroupFilterValue : undefined}
                            nyTestId="vocation"
                            AddNewModalComponent={ canCreate() ? VocationGroupEdit:undefined}
                            SearchPopupComponent={canCreate() ?<VocationGroupIndex />:undefined}
                        />
                    </Form.Item> */}
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.group.edit.validFrom')}
                        name="validFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={moment().startOf('month')}
                    >
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            onChange={onStartDateChange}
                            disabledDate={disabledStartDate}
                            defaultPickerValue={moment()}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.group.edit.validTo')}
                        name="validTo"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={moment()}
                    >
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            onChange={onEndDateChange}
                            disabledDate={disabledendDate}
                            defaultPickerValue={moment()}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default VocationGroupEdit;
