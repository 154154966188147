import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit } from '../../../../utils/Filters';
import { getEnumArray } from '../../../../utils/Utils';
import WarehouseEdit from './edit';

const WarehouseIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    defaultFilterValue,
    orderViewId = undefined,
}: any) => {
    const table = useTableSettings();

    useHelper('warehouse/codebooks/warehouse');

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseCodebooks();
    };

    const canExportCSV = () => {
        return WarehouseBookkeepingRights.canCsvExportWarehouseCodebook();
    };

    const setDefaultFilterValue = () => {
        return defaultFilterValue ? defaultFilterValue : table.setDefaultFilterValue();
    };

    const columns = [
        {
            title: geti18nText('warehouse.table.column.id'),
            dataIndex: 'id',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouse.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouse.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouse.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            ...getColumnSearchByCodebookBusinessUnit(),
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('warehouse.table.column.type'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.WAREHOUSE_TYPE.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('WAREHOUSE_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('warehouse.table.column.warehouseValueType'),
            dataIndex: 'warehouseValueType',
            render: (text: any, record: any) => {
                if (record.warehouseValueType !== undefined) {
                    return geti18nText('app.enum.WAREHOUSE_VALUE_TYPE.' + record.warehouseValueType);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('WAREHOUSE_VALUE_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('warehouse.table.column.active'),
            dataIndex: 'active',
            width: '6%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    const getUrl = () => {
        if (orderViewId && orderViewId > 0) {
            return CONSTANTS_REQ.WAREHOUSE.LIST_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString());
        } else {
            return CONSTANTS_REQ.WAREHOUSE.LIST;
        }
    };

    return (
        <NyDataTable
            nyId="warehouse-warehouse-table"
            url={getUrl()}
            addNewButtonText={geti18nText('warehouse.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={WarehouseEdit}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.warehouse')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.warehouse')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.WAREHOUSE_TYPE.' + value);
            },
        },
        {
            warehouseValueType: (value: any) => {
                return value != undefined && geti18nText('app.enum.WAREHOUSE_VALUE_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default WarehouseIndex;
