import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useSelector } from 'react-redux';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import ContactEdit from './ContactEdit';

const ContactIndex = ({ props, companyId, scroll = { y: 580, x: 800 }, sortOrder }: any) => {
    const { item } = useSelector((state: RootState) => state.selectedItem);
    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'companyId', condition: 'equals', value: companyId },
        ];
    };

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    return (
        <NyDataTable
            nyId="contacts"
            url={CONSTANTS_REQ.COMPANY_CONTACT.LIST}
            addNewButtonText={geti18nText('company.contact.table.add')}
            showRecordModal={true}
            buttonsClassName="buttons-sticky"
            fetchWhenChange={companyId}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            modalComponent={ContactEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            columns={[
                {
                    title: geti18nText('company.contact.table.column.id'),
                    dataIndex: ['contact', 'id'],
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('company.contact.table.column.name'),
                    dataIndex: ['contact', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('company.contact.table.column.email'),
                    dataIndex: ['contact', 'email'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('company.contact.table.column.mobile'),
                    dataIndex: ['contact', 'mobile'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('contact.contact.table.column.phone'),
                    dataIndex: ['contact', 'phone'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('contact.contact.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(
                        ENUMS.ACTIVE(),
                        'equals_bool',
                        setDefaultFilterValue()[0]['value'],
                        undefined,
                        undefined,
                        'company-contact-active'
                    ),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
            shortcuts={true}
            hideNewButton={!canCreate()}
            nyTestId="employee-contact-table"
        />
    );
};

export default ContactIndex;
