import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import SettlementDistrictIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import SettlementDistrictEdit from './edit';

const SettlementDistrictSearch = ({
    label = geti18nText('settlement.district.district'),
    name = ['address', 'settlementDistrict'],
    required = false,
    selectedSettlementId,
    disabled = false,
}: any) => {
    const setSettlementDistrictFilterValue = () => {
        if (selectedSettlementId) {
            return [
                { field: 'settlement.id', condition: 'equals', value: selectedSettlementId },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: false,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.SETTLEMENT_DISTRICT.SEARCH}
                setDefaultFilterValue={setSettlementDistrictFilterValue}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                SearchPopupComponent={<SettlementDistrictIndex selectedSettlementId={selectedSettlementId} />}
                AddNewModalComponent={SettlementDistrictEdit}
                nyTestId="settlementDistrict"
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default SettlementDistrictSearch;
