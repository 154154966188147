import { Col, Divider, Row } from 'antd';
import React from 'react';
import TravelWarrantWageReceivedAmount from '../travel-warrant-wage-received-amount';
import { geti18nText } from '@nybble/nyreact';
import TravelWarrantTypeComponentIndex from '.';
import TravelWarrantTypeDates from './dates';
import useApplicationSetting from '../../hooks/useApplicationSetting';

const TWTypeComponent = ({
    formTravelWarrant,
    setIncludeEducationDate,
    includeEducationDate,
    setPayoutByAnotherOrganization,
    travelWarrantType,
    setTravelWarrantType,
    payoutByAnotherOrganization,
    current,
    disabledendEduDate,
    showSearch,
    isNotEditable,
    defaultEndDate,
    setDefaultEndDate,
    rerenderKey,
    setRerenderKey,
}: any) => {
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    return (
        <Row gutter={24}>
            <Col span={16}>
                {React.cloneElement(
                    TravelWarrantTypeComponentIndex(
                        formTravelWarrant,
                        setIncludeEducationDate,
                        includeEducationDate,
                        setPayoutByAnotherOrganization,
                        travelWarrantType,
                        setTravelWarrantType,
                        showSearch,
                        isNotEditable
                    )
                )}
            </Col>
            <Col span={8}>
                <Col span={24}>
                    {payoutByAnotherOrganization && (
                        <>
                            <Divider>{geti18nText('travelWarrantWizard.step.9.5')}</Divider>
                            <TravelWarrantWageReceivedAmount
                                current={current}
                                currency={defaultCurrencyISO}
                                formTravelWarrant={formTravelWarrant}
                                autoFocus={false}
                                isEditForm={isNotEditable}
                            />
                        </>
                    )}
                </Col>
                {includeEducationDate && (
                    <Col span={24}>
                        <Divider>{geti18nText('travelWarrantType.edit.panel.educationDate')}</Divider>
                        {TravelWarrantTypeDates(
                            includeEducationDate,
                            disabledendEduDate,
                            formTravelWarrant,
                            false,
                            isNotEditable,
                            defaultEndDate,
                            setDefaultEndDate,
                            rerenderKey,
                            setRerenderKey
                        )}
                    </Col>
                )}
            </Col>
        </Row>
    );
};

export default TWTypeComponent;
