import { geti18nText } from '@nybble/nyreact';
import { Switch } from 'antd';
import { useState } from 'react';
import PeacetimeSystematizationEmployeeTable from './PeacetimeSystematizationEmployeeTable';
import PeacetimeSystematizationTable from './PeacetimeSystematizationTable';

const PeacetimeSystematizationIndex = () => {
    const [grouped, setGrouped] = useState<boolean>(false);

    return (
        <>
            <br />
            <Switch onChange={(checked: boolean) => setGrouped(checked)} />{' '}
            {geti18nText('systematization.of.jobs.tab.switch.1')}
            {grouped ? <PeacetimeSystematizationEmployeeTable /> : <PeacetimeSystematizationTable />}
        </>
    );
};

export default PeacetimeSystematizationIndex;
