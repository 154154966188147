import { CloseCircleTwoTone, EditTwoTone, SaveTwoTone } from '@ant-design/icons';
import { geti18nText, NyInputNumber, NyModalConfirm, NySession } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import { useState } from 'react';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import { getEnumBooleanArray, numberFormat } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const EditableCell = ({ editing, dataIndex, title, record, index, children, ...restProps }: any) => {
    const inputNode =
        record && record.dataType === 3 ? <Checkbox /> : <NyInputNumber style={{ width: '100%' }} isDecimal />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    valuePropName={record && record.dataType === 3 ? 'checked' : 'value'}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const PaymentCalculationSettings = ({ dataSource, setDataSource, lock }: any) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;

    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    const edit = (record: any) => {
        form.setFieldsValue({
            ...record,
            value: record && record.dataType === 3 ? Number(record.value).toString() : record.value,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (id: any) => {
        try {
            const row = await form.validateFields();
            const newData = [...dataSource];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                if (item.dataType === 3) {
                    row.value = Number(row.value).toString();
                }
                newData.splice(index, 1, { ...item, ...row });
                setDataSource(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDataSource(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: geti18nText('app.setting.table.column.name'),
            dataIndex: 'settingKey',
            render: (settingKey: any, record: any) => {
                if (record.settingModule) {
                    let sModule = GetEnumNameForValue({
                        enumName: 'APPLICATION_SETTINGS_MODULE_TYPE',
                        value: record.settingModule,
                    });
                    return geti18nText('app.setting.name.' + sModule + '.' + record.settingGroup + '.' + settingKey);
                }
                return '';
            },
        },
        {
            title: geti18nText('app.setting.table.column.value'),
            dataIndex: 'value',
            editable: true,
            render: (value: any, record: any) => {
                if (record.dataType === 3) {
                    let enumBoolArray = getEnumBooleanArray();
                    let boolVal = enumBoolArray.find(
                        (data: any) =>
                            data.id === Number(value !== null && value !== undefined ? value : record.defaultValue)
                    );
                    if (boolVal) {
                        return boolVal.text;
                    }
                } else {
                    return value ? numberFormat(value) : numberFormat(record.defaultValue);
                }
            },
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            width: '100px',
            render: (text: any, record: any) => {
                if (dataSource.length >= 1 && !lock && canCreate()) {
                    const editable = isEditing(record);
                    return editable ? (
                        <>
                            <Space size="middle" style={{ textAlign: 'right' }}>
                                <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                                    <Button type="link" onClick={() => save(record.id)}>
                                        <SaveTwoTone
                                            style={{
                                                fontSize: '20px',
                                            }}
                                            twoToneColor="#52c41a"
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                                    <NyModalConfirm
                                        title={geti18nText('app.default.cancel.confirm')}
                                        onConfirm={() => {
                                            cancel();
                                        }}
                                    >
                                        <Button type="link">
                                            <CloseCircleTwoTone
                                                style={{
                                                    fontSize: '20px',
                                                }}
                                                twoToneColor="#faad14"
                                            />
                                        </Button>
                                    </NyModalConfirm>
                                </Tooltip>
                            </Space>
                        </>
                    ) : (
                        <Space size="middle" style={{ textAlign: 'right' }}>
                            <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                                    <EditTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </Space>
                    );
                }
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                form: form,
            }),
        };
    });

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={dataSource}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                        />
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default PaymentCalculationSettings;
