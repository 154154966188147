import { CheckOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { SortableIndex } from '../../../../components/sortable-list/SortableIndex';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getDateFormat,
    getEnumArrayStatus,
    getEnumBooleanArray,
    getTravelWarrantTypeIcon,
} from '../../../../utils/Utils';
import TravelEventNumberEdit from './edit';

const TravelWarrantTypeIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    scroll = { y: 560, x: 2600 },
    sortOrder,
    isSearchTable = false,
}: any) => {
    const [sortListModalVisible, setSortListModalVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>(0);

    const table = useTableSettings();
    useHelper('travel_order/codebooks/travelWarrantType_SB');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canExportCSV = () => {
        return TravelWarrantRights.canCsvExportTravelWarrantCodebooks();
    };

    const columns = [
        {
            title: geti18nText('travelWarrantType.table.column.id'),
            dataIndex: 'id',
            width: '3%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('holiday.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            render: (name: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={name}>
                        <div>
                            <p className={'overview-cards-paragraph'} style={{ top: '6px', position: 'relative' }}>
                                {name}
                            </p>
                        </div>
                    </Tooltip>
                );
            },
            ...getColumnSearch('string'),
            width: '260px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.wageType'),
            dataIndex: 'wageType',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TRAVEL_WARRANT_WAGE_TYPE')),
            render: (wageType: any) => {
                return getTravelWarrantTypeIcon(wageType);
            },
        },
        {
            title: geti18nText('travelWarrantType.table.column.desc'),
            dataIndex: 'description',
            width: '10%',
            render: (description: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={description}>
                        <div>
                            <p className={'overview-cards-paragraph'} style={{ top: '6px', position: 'relative' }}>
                                {description}
                            </p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.wage'),
            dataIndex: 'includeWage',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeWage: any }) => {
                if (record.includeWage) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.taxable.wage'),
            dataIndex: 'taxableWage',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxableWage: any }) => {
                if (record.taxableWage) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.wage.expenses'),
            dataIndex: 'includeWageExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeWageExpenses: any }) => {
                if (record.includeWageExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        // travelWarrantType.table.column.includeNonStandard // includeNonStandard
        {
            title: geti18nText('travelWarrantType.table.column.includeNonStandard'),
            dataIndex: 'includeNonStandard',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeNonStandard: any }) => {
                if (record.includeNonStandard) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.travel.vehicle.expenses'),
            dataIndex: 'includeTravelVehicleExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeTravelVehicleExpenses: any }) => {
                if (record.includeTravelVehicleExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.taxable.travel.vehicle.expenses'),
            dataIndex: 'taxableTravelVehicleExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxableTravelVehicleExpenses: any }) => {
                if (record.taxableTravelVehicleExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.travel.expenses'),
            dataIndex: 'includeTravelExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeTravelExpenses: any }) => {
                if (record.includeTravelExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '200px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.taxable.travel.expenses'),
            dataIndex: 'taxableTravelExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxableTravelExpenses: any }) => {
                if (record.taxableTravelExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '210px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.payout.by.another.organization'),
            dataIndex: 'payoutByAnotherOrganization',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { payoutByAnotherOrganization: any }) => {
                if (record.payoutByAnotherOrganization) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.education.date'),
            dataIndex: 'includeEducationDate',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeEducationDate: any }) => {
                if (record.includeEducationDate) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.wage.number.by.overnight.stay'),
            dataIndex: 'wageNumberByOvernightStay',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { wageNumberByOvernightStay: any }) => {
                if (record.wageNumberByOvernightStay) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '200px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.created'),
            dataIndex: 'created',
            render: (name: any, record: any) => {
                if (record && record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm');
                }
            },
            ...getColumnDateOption(true),
        },
        {
            title: geti18nText('travelWarrantType.table.column.updated'),
            dataIndex: 'updated',
            render: (name: any, record: any) => {
                if (record && record.updated) {
                    return getDateFormat(record.updated, 'DD.MM.YYYY HH:mm');
                }
            },
            ...getColumnDateOption(true),
        },
        {
            title: geti18nText('task.typeCategory.table.column.sort'),
            width: '2.5%',
            align: 'center',
            render: (record: any) => {
                return (
                    <Tooltip title={geti18nText('task.typeCategory.table.column.sort.category')}>
                        <UnorderedListOutlined
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                                setSortListModalVisible(true);
                            }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('travelWarrantType.table.column.active'),
            dataIndex: 'active',
            width: '3%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    const searchColumns = [
        {
            title: geti18nText('travelWarrantType.table.column.id'),
            dataIndex: 'id',
            width: '60px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('holiday.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            render: (name: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={name}>
                        <div>
                            <p className={'overview-cards-paragraph'} style={{ top: '6px', position: 'relative' }}>
                                {name}
                            </p>
                        </div>
                    </Tooltip>
                );
            },
            ...getColumnSearch('string'),
            width: '260px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.wageType'),
            dataIndex: 'wageType',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TRAVEL_WARRANT_WAGE_TYPE')),
            render: (wageType: any) => {
                return getTravelWarrantTypeIcon(wageType);
            },
        },
        {
            title: geti18nText('travelWarrantType.table.column.desc'),
            dataIndex: 'description',
            width: '140px',
            render: (description: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={description}>
                        <div>
                            <p className={'overview-cards-paragraph'} style={{ top: '6px', position: 'relative' }}>
                                {description}
                            </p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.wage'),
            dataIndex: 'includeWage',
            width: '140px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeWage: any }) => {
                if (record.includeWage) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.taxable.wage'),
            dataIndex: 'taxableWage',
            width: '140px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxableWage: any }) => {
                if (record.taxableWage) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.wage.expenses'),
            dataIndex: 'includeWageExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeWageExpenses: any }) => {
                if (record.includeWageExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        // travelWarrantType.table.column.includeNonStandard // includeNonStandard
        {
            title: geti18nText('travelWarrantType.table.column.includeNonStandard'),
            dataIndex: 'includeNonStandard',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeNonStandard: any }) => {
                if (record.includeNonStandard) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.travel.vehicle.expenses'),
            dataIndex: 'includeTravelVehicleExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeTravelVehicleExpenses: any }) => {
                if (record.includeTravelVehicleExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.taxable.travel.vehicle.expenses'),
            dataIndex: 'taxableTravelVehicleExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxableTravelVehicleExpenses: any }) => {
                if (record.taxableTravelVehicleExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '140px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.travel.expenses'),
            dataIndex: 'includeTravelExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeTravelExpenses: any }) => {
                if (record.includeTravelExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '200px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.taxable.travel.expenses'),
            dataIndex: 'taxableTravelExpenses',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxableTravelExpenses: any }) => {
                if (record.taxableTravelExpenses) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '210px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.payout.by.another.organization'),
            dataIndex: 'payoutByAnotherOrganization',
            width: '140px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { payoutByAnotherOrganization: any }) => {
                if (record.payoutByAnotherOrganization) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.include.education.date'),
            dataIndex: 'includeEducationDate',
            width: '140px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { includeEducationDate: any }) => {
                if (record.includeEducationDate) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelWarrantType.table.column.wage.number.by.overnight.stay'),
            dataIndex: 'wageNumberByOvernightStay',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { wageNumberByOvernightStay: any }) => {
                if (record.wageNumberByOvernightStay) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
            width: '200px',
        },
        {
            title: geti18nText('travelWarrantType.table.column.created'),
            dataIndex: 'created',
            width: '100px',
            render: (name: any, record: any) => {
                if (record && record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm');
                }
            },
            ...getColumnDateOption(true),
        },
        {
            title: geti18nText('travelWarrantType.table.column.updated'),
            dataIndex: 'updated',
            width: '100px',
            render: (name: any, record: any) => {
                if (record && record.updated) {
                    return getDateFormat(record.updated, 'DD.MM.YYYY HH:mm');
                }
            },
            ...getColumnDateOption(true),
        },
        {
            title: geti18nText('task.typeCategory.table.column.sort'),
            width: '100px',
            align: 'center',
            render: (record: any) => {
                return (
                    <Tooltip title={geti18nText('task.typeCategory.table.column.sort.category')}>
                        <UnorderedListOutlined
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                                setSortListModalVisible(true);
                            }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('travelWarrantType.table.column.active'),
            dataIndex: 'active',
            width: '100px',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    const closeModal = () => {
        setSortListModalVisible(false);
        setRefresh((prevValue: any) => prevValue + 1);
    };

    return (
        <>
            <NyDataTable
                nyId="travel-warrant-travel-warrant-type-table"
                url={CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.LIST}
                addNewButtonText={geti18nText('travelWarrantType.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={TravelEventNumberEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={isSearchTable ? searchColumns : columns}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? { order: 'sort', orderType: 'asc' }}
                shortcuts={true}
                hideNewButton={!canCreate()}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.travelWarrantType')}
                colCSVCustomization={csvColumnCustomisation()}
                readonly={readonly}
                hideButtons={readonly}
                headerTitle={geti18nText('menu.travelWarrantType')}
                fetchWhenChange={refresh}
            />
            {sortListModalVisible && (
                <SortableIndex
                    closeModal={closeModal}
                    id={geti18nText('travelWarrantType.table.column.id')}
                    name={geti18nText('holiday.table.column.name')}
                    wageType={geti18nText('travelWarrantType.table.column.wageType')}
                    description={geti18nText('travelWarrantType.table.column.desc')}
                    sortListModalVisible={sortListModalVisible}
                    type={'travel-warrant-type'}
                    typeModal="travel-warrant-type"
                    url={CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.LIST}
                    saveURL={CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.CHANGE_SORT}
                    modalTitle={geti18nText('travelWarrantType.table.sort.modal.title')}
                    order={'sort'}
                />
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default TravelWarrantTypeIndex;
