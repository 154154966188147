import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArray, setEnumFormat } from '../../../../utils/Utils';
import CompanyModalEdit from './CompanyModalEdit';
import CompanyEdit from './edit';

const CompanyIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    isModal = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('common/company');

    const canCreate = () => {
        return AdministrationRights.canCreateCompany();
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportCompany();
    };

    const columns = [
        {
            title: geti18nText('company.table.column.id'),
            dataIndex: 'id',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('company.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('company.table.column.oib'),
            dataIndex: 'oib',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('company.table.column.vatNumber'),
            dataIndex: 'vatNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('company.table.column.partnerType'),
            dataIndex: 'partnerType',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                let type: any = setEnumFormat('COMPANY_PARTNER_TYPE', record.partnerType);
                return type !== undefined && type.text !== undefined && type.text;
            },
            ...getColumnSearchOption(getEnumArray('COMPANY_PARTNER_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('company.table.column.marketType'),
            dataIndex: 'marketType',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                let type: any = setEnumFormat('MARKET_TYPE', record.marketType);
                return type !== undefined && type.text !== undefined && type.text;
            },
            ...getColumnSearchCheckbox(getEnumArray('MARKET_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('company.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="company"
            url={CONSTANTS_REQ.COMPANY.LIST}
            addNewButtonText={geti18nText('company.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            modalComponent={isModal ? CompanyModalEdit : CompanyEdit}
            showRecordModal={isModal}
            columns={columns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.company')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.company')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            partnerType: (value: any) => {
                let type: any = setEnumFormat('COMPANY_PARTNER_TYPE', value);
                return type !== undefined && type.text !== undefined && type.text;
            },
        },
        {
            marketType: (value: any) => {
                let type: any = setEnumFormat('MARKET_TYPE', value);
                return type !== undefined && type.text !== undefined && type.text;
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default CompanyIndex;
