import { NyRequestResolver, NySpinner, NyUtils, RESPONSE, UseRequestSwrGet, geti18nText } from '@nybble/nyreact';
import { Card, Col, Divider, Empty, Form, Row, Statistic, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MealsRights } from '../../../rights/mealsRights';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType, getDateFormat } from '../../../utils/Utils';

const { Title } = Typography;

const MealOrderAdvanceLightWidget = ({ refresh, setAmountExists }: any) => {
    const { settings } = useSelector((state: RootState) => state.notification);
    const widgetColor = getColorFromNotificationType(settings, 'MEALS_ORDER');

    const [advance, setAdvance] = useState<any>(0);
    const [subvention, setSubvention] = useState<any>(0);
    const [currency, setCurrency] = useState<any>(null);

    const canView = () => {
        return MealsRights.isMealsAdminOrUser();
    };

    useEffect(() => {
        getAppCurrency();
    }, []);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 1 },
            { field: 'settingGroup', condition: 'equals', value: 'COMPANY_INFO' },
        ];
    };

    const getAppCurrency = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_CURRENCY_ISO_CODE') {
                                setCurrency(element.value);
                            }
                        });
                    }
                }
            }
        );
    };

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrGet(CONSTANTS_REQ.MEAL_ORDER.BALANCE + '/' + getDateFormat(Date.now(), 'YYYY-MM-DD'), undefined);

    useEffect(() => {
        setAdvance(statisticData?.stanje_predujma ?? 0);
        setSubvention(statisticData?.stanje_subvencije ?? 0);
    }, [statisticData]);

    const getDeliverySpotData = () => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.MEAL_ORDER.BALANCE + '/' + getDateFormat(Date.now(), 'YYYY-MM-DD'),
            undefined
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setAdvance(result.data.stanje_predujma ?? 0);
                setSubvention(result.data.stanje_subvencije ?? 0);
                setAmountExists(result.data.stanje_predujma && result.data.stanje_subvencije ? true : false);
            }
        });
    };

    useEffect(() => {
        getDeliverySpotData();
    }, [refresh]);

    return (
        <>
            <Card
                className="ny-widget-card"
                style={{ minWidth: '80%' }}
                title={
                    <Form>
                        <Row gutter={24}>
                            <Col span={24}>{geti18nText('meal.order.widget.balance')}</Col>
                        </Row>
                    </Form>
                }
                headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33`, height: '50px' }}
                bodyStyle={{
                    height: 'calc(100% - 55px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0px 5px 0px 5px',
                    marginBottom: '5px',
                    width: '100%',
                }}
                type="inner"
            >
                <>
                    <div>
                        {loading ? (
                            <div style={{ paddingTop: 20 }}>
                                <NySpinner />
                            </div>
                        ) : error || !statisticData || !canView() ? (
                            <div style={{ paddingTop: 10 }}>
                                <Empty
                                    className="ny-dashboard-empty"
                                    imageStyle={{ width: '100%' }}
                                    description={<Title level={5}>{geti18nText('app.default.nodata')}</Title>}
                                />
                            </div>
                        ) : (
                            <Row justify="center">
                                <div className={`advance-statistic-color`}>
                                    <Statistic
                                        title={geti18nText('meal.order.widget.advance')}
                                        value={NyUtils.formatNumber(advance, 2)}
                                        valueStyle={{ color: '#3f8600' }}
                                        suffix={currency}
                                    />
                                </div>
                                &nbsp; &nbsp;
                                <Divider type="vertical" style={{ fontSize: '60px' }} />
                                &nbsp; &nbsp;
                                <div className={`subvention-statistic-color`}>
                                    <Statistic
                                        title={geti18nText('meal.order.widget.subvention')}
                                        value={NyUtils.formatNumber(subvention, 2)}
                                        suffix={currency}
                                        className="subvention-statistic-color"
                                    />
                                </div>
                            </Row>
                        )}
                    </div>
                </>
            </Card>
        </>
    );
};

export default MealOrderAdvanceLightWidget;
