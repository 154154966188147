import { NyDataTable, geti18nText } from '@nybble/nyreact';
import { Tag } from 'antd';
import { useState } from 'react';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';

const WarSystematizationEmployeeTable = ({
    scroll = { y: 595, x: 800 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const table = useTableSettings();
    const [refresh, setRefresh] = useState<any>(0);

    const setDefaultFilterValue = () => {
        return [
            { field: 'status', condition: 'in', value: [enWorkplaceStatus.CONFIRMED].toString() },
            { field: 'active', condition: 'equals_bool', value: 1 },
        ];
    };

    const columns = [
        {
            title: geti18nText('assetReturn.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            width: '20%',
            ...getColumnSearchByCodebookBusinessUnit(true),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                        record.businessUnit.name
                    );
                }
            },
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.vocation'),
            dataIndex: ['vocation', 'name'],
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
            dataIndex: ['vocationDescription', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                'vocationDescription.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.nrOfEmployees'),
            dataIndex: 'nrOfEmployees',
            width: '10%',
            render: (record: any, text: any) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Tag
                            color={record > 0 ? 'green' : 'red'}
                            style={{ marginBottom: '2px', marginTop: '2px' }}
                            key={record}
                        >
                            {record}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: geti18nText('notificationSettings.edit.employees'),
            dataIndex: 'employees',
            render: (record: any, text: any) => {
                const elemetns: any = [];

                if (record != undefined) {
                    const data: any = [...record.split('|')];

                    data.map((element: any) => {
                        elemetns.push(
                            <Tag style={{ marginBottom: '2px', marginTop: '2px' }} key={element}>
                                {element}
                            </Tag>
                        );
                    });
                }

                return elemetns;
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-workplace-mobilization-employee-table"
            url={CONSTANTS_REQ.WORKPLACE.MOBILIZATION_EMPLOYEE_LIST}
            buttonsClassName="buttons-sticky"
            readonly
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideButtons
            shortcuts={true}
            fetchWhenChange={refresh}
            // exportCSV={canExportCSV()}
            // CSVFileName={geti18nText('menu.work.obligation.reserve.rank')}
            // colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export default WarSystematizationEmployeeTable;
