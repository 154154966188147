import { NyDataTable, NyUtils, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { ConfigProvider, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import useEnum from '../../hooks/useEnum';
import useTableSettings from '../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../utils/Filters';
import TravelWarrantCo2Edit from './edit';

const TravelWarrantCo2Index = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    travelWarrantId,
    employeeTravelWarrantId,
    travelWarrantStatus,
    printPdf,
    travelVehicle,
    travelAdditionalData,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    isBoldText = false,
}: any) => {
    const table = useTableSettings();
    const { employee } = useSelector((state: RootState) => state.employee);
    const enTravelVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const customizeRenderEmpty = () => <div style={{ textAlign: 'center' }}>{geti18nText('app.default.nodata')}</div>;

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
        ];
    };

    const getVehiclePublic = (value: any) => {
        return Object.values([
            enTravelVehicleType.PLANE,
            enTravelVehicleType.BOAT,
            enTravelVehicleType.BUS,
            enTravelVehicleType.TRAIN,
            enTravelVehicleType.OTHER_PUBLIC,
            enTravelVehicleType.OTHER,
        ]).includes(value);
    };

    const isPublicVehicle = () => {
        const additionalInfoData = travelAdditionalData ?? [];
        let isPublic: boolean = false;
        additionalInfoData.map((item: any) => {
            if (getVehiclePublic(item?.travelVehicle?.vehicleType)) {
                isPublic = true;
            }
        });
        if (getVehiclePublic(travelVehicle?.vehicleType)) {
            isPublic = true;
        }
        return isPublic;
    };

    const initialColumns = [
        {
            title: (
                <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                    {geti18nText('travelWarrantCo2.table.column.travelVehicle')}
                </span>
            ),
            dataIndex: ['travelVehicle', 'name'],
            width: '30%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH,
                'travelVehicle.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: (
                <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                    {geti18nText('travelWarrantCo2.table.column.amount')}
                </span>
            ),
            dataIndex: 'amount',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any, record: any) => {
                return <div style={{ float: 'right' }}>{NyUtils.formatNumber(record.amount ?? 0, 2) + ' kg'}</div>;
            },
        },
        {
            title: (
                <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                    {geti18nText('travelWarrantCo2.table.column.note')}
                </span>
            ),
            dataIndex: 'description',
            width: '50%',
            ...getColumnSearch('string'),
            sorter: (a: any, b: any) => {},
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={record.description}>
                            <div style={{ height: '20px', maxWidth: '400px' }}>
                                <p className={'description-paragraph'}>{record && record.description}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
    ];

    return (
        <>
            <ConfigProvider renderEmpty={printPdf && customizeRenderEmpty}>
                <NyDataTable
                    nyId="treavel-warent-co2"
                    url={CONSTANTS_REQ.TRAVEL_WARRANT_CO2.LIST}
                    addNewButtonText={geti18nText('travelWarrantCo2.table.add')}
                    buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                    showRecordModal={true}
                    modalComponent={TravelWarrantCo2Edit}
                    editProps={{
                        travelWarrantId: travelWarrantId,
                        employeeTravelWarrantId: employeeTravelWarrantId ?? employee.id,
                        travelWarrantStatus: travelWarrantStatus,
                        disabled: disabled,
                    }}
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    columns={initialColumns}
                    hideNewButton={
                        !TravelWarrantRights.canCreateCo2(
                            travelWarrantStatus,
                            employee,
                            travelWarrantStatusEnum,
                            notificationType
                        ) || disabled
                    }
                    setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                    scroll={scroll}
                    hideButtons={printPdf}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    shortcuts={true}
                    exportCSV={false}
                    CSVFileName={geti18nText('travelWarrantCo2.table.header')}
                    showCleanTableFilters={!printPdf}
                    hidePagination={printPdf}
                />
            </ConfigProvider>
        </>
    );
};

export default TravelWarrantCo2Index;
