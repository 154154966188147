import { NyRequestResolver, NySearchField, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Collapse, Divider, Form, Input, Radio, Row, Tooltip } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getEnumArray,
    getEnumFormat,
    getErrorNotificationMessage,
    okNotification,
} from '../../../../../utils/Utils';
import TeamSearch from '../../team/search';
import TaskTemplateApprovalEdit from './edit';

const { Panel } = Collapse;

const TaskTemplateApprovalIndex = ({ taskTemplateId }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<any>(false);
    const [refresh, setRefresh] = useState(0);
    const [items, setItems] = useState<any>([]);
    const [approvalTeams, setApprovalTeams] = useState<any>([]);
    const [approvalItems, setApprovalItems] = useState<any>([]);
    const [selectedTeam, setSelectedTeam] = useState<any>(998);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>([1]);

    useEffect(() => {
        if (taskTemplateId) {
            getItems(taskTemplateId);
        }
    }, [taskTemplateId, refresh]);

    const compare = (a: any, b: any) => {
        if (a?.team?.name.toLowerCase() < b.team?.name.toLowerCase()) {
            return -1;
        }
        if (a.team?.name.toLowerCase() > b.team?.name.toLowerCase()) {
            return 1;
        }
        return 0;
    };

    const getItems = (taskTemplateId: any) => {
        setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.TASK_TEMPLATE.APPROVAL_LIST.replace('{taskTemplateId}', taskTemplateId)
        ).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content) {
                setItemsData(_.uniqBy(result?.data?.content, 'id').sort(compare));
            } else {
                setItems([]);
                setApprovalTeams([]);
                setApprovalItems([]);
                setSelectedTeam(998);
                setLoading(false);
            }
        });
    };

    const setItemsData = (data: any) => {
        if (data && data?.length > 0) {
            let newData: any = [];
            newData.push({ value: 998, label: geti18nText('task.template.approval.noTeam'), number: undefined });
            for (let key in data) {
                if (
                    data.hasOwnProperty(key) &&
                    data[key] &&
                    data[key].team?.id &&
                    newData.filter((dat: any) => dat.value === data[key].team?.id)?.length === 0
                ) {
                    newData.push({ value: data[key].team?.id, label: data[key].team?.name, number: undefined });
                }
            }
            newData.push({ value: 999, label: geti18nText('task.template.approval.allTeams'), number: undefined });

            setApprovalTeams(newData);
            setItems(data);
            setApprovalItemsValue(selectedTeam, data);
        }
        setLoading(false);
    };

    const saveApproval = async () => {
        const row = await form.validateFields();
        if (row && row?.taskApprovalType?.id > 0) {
            row.taskTemplate = { id: taskTemplateId };
            if (row.taskApprovalType) {
                row.taskApprovalType = getEnumFormat(row.taskApprovalType);
            }
            NyRequestResolver.requestPost(CONSTANTS_REQ.TASK_TEMPLATE_APPROVAL.EDIT, undefined, row).then(
                (result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        form.resetFields();
                        setRefresh((refresh) => refresh + 1);
                    } else {
                        getErrorNotificationMessage(result);
                    }
                }
            );
        } else {
            errorNotification(geti18nText('app.default.required.fields'));
        }
    };

    const onChangeTeamValue = (value: any) => {
        setSelectedTeam(value);
        setCollapseActiveKeys([1]);
        setApprovalItemsValue(value, items);
    };

    const setApprovalItemsValue = (value: any, items: any) => {
        let data: any = [];
        if (value && value == 999) {
            data = [...items];
        } else if (value && value == 998) {
            data = [...items.filter((row: any) => row?.team?.id == null || !row?.team?.id)];
        } else if (value && value > 0) {
            data = [...items.filter((row: any) => row?.team?.id === value)];
        }
        const groupedList: any = _(data)
            .filter((approval: any) => approval?.level != null && approval?.level != undefined && approval?.level > 0)
            .groupBy((x) => x.level)
            .map((value: any, key: any) => {
                return {
                    level: key,
                    approvalItems: value,
                };
            })
            .value();
        setApprovalItems(groupedList);
    };

    const getApprovalTeamsTabs = () => (
        <>
            {approvalTeams?.length > 1 && (
                <>
                    <Radio.Group
                        options={approvalTeams}
                        value={selectedTeam}
                        onChange={(e: any) => onChangeTeamValue(e?.target?.value)}
                        buttonStyle="solid"
                        optionType="button"
                        style={{ marginBottom: '5px', marginTop: '5px' }}
                    />
                    {selectedTeam && (
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {approvalItems.map((level: any) => (
                                <>
                                    <Collapse
                                        activeKey={
                                            Object.values(collapseActiveKeys).includes(approvalItems[0].level)
                                                ? collapseActiveKeys
                                                : approvalItems[0].level
                                        }
                                        onChange={(key: any) => {
                                            setCollapseActiveKeys(key);
                                        }}
                                    >
                                        <Panel
                                            header={geti18nText('task.template.approval.level') + ' ' + level?.level}
                                            key={level?.level}
                                        >
                                            {level.approvalItems.map((item: any) => (
                                                <>
                                                    <TaskTemplateApprovalEdit
                                                        taskTemplateId={taskTemplateId}
                                                        item={item}
                                                        setRefresh={setRefresh}
                                                        items={approvalItems}
                                                        setItems={setApprovalItems}
                                                        selectedTeam={selectedTeam}
                                                    />
                                                    <hr />
                                                </>
                                            ))}
                                        </Panel>
                                    </Collapse>
                                </>
                            ))}
                        </div>
                    )}
                </>
            )}
        </>
    );

    return (
        <>
            <Form layout="vertical" form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Row gutter={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={10}>
                        <TeamSearch
                            label={geti18nText('task.template.approval.team')}
                            name="team"
                            setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                            mustGetPopupContainer={false}
                        />
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label={geti18nText('task.template.approval.approvalType')}
                            name="taskApprovalType"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                options={getEnumArray('TASK_APPROVAL_TYPE')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                style={{ width: '100%' }}
                                className={''}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Tooltip placement="top" title={geti18nText('task.template.approval.add')}>
                            <Button onClick={saveApproval} type={'primary'} style={{ marginTop: '25px' }}>
                                {geti18nText('task.template.approval.add')}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <NySpinner />
            ) : (
                <Row gutter={24}>
                    <Col span={24}>{getApprovalTeamsTabs()}</Col>
                </Row>
            )}
        </>
    );
};

export default TaskTemplateApprovalIndex;
