import {
    BookOutlined,
    CheckOutlined,
    CloseOutlined,
    DownOutlined,
    EllipsisOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import {
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Dropdown, Form, Menu, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { errorNotification, getEnumBookedArray, numberFormat, okNotification } from '../../../../../utils/Utils';
import AssetCustomTableFilter from '../CustomFilter';

const AssetAcquisitionIndex = ({ scroll = { y: 480, x: 800 }, sortOrder }: any) => {
    const location = useLocation();
    const [booked, setBooked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [bookAllVisible, setBookAllVisible] = useState<any>(false);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const table = useTableSettings();
    const [filterForm] = Form.useForm();
    useHelper('fixed_asset/case/asset-acquisition');

    useEffect(() => {
        getAppSettings();
    }, []);

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetAcquisition();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCsvExportAssetAcquisition();
    };

    let defaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }];

    const setDefaultFilterValue = () => {
        let currentDefaultFilterValue = [...defaultFilterValue];
        if (filterForm.getFieldValue('asset')) {
            const assetID = filterForm.getFieldValue('asset').id;
            if (assetID != -1) {
                currentDefaultFilterValue.push({
                    field: 'asset.id',
                    condition: 'equals',
                    value: assetID.toString(),
                });
            }
        }
        if (filterForm.getFieldValue('serial_number')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number').toString(),
            });
        }
        if (filterForm.getFieldValue('serial_number2')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number2',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number2').toString(),
            });
        }
        return currentDefaultFilterValue;
    };

    const columns = [
        {
            title: geti18nText('asset.acquisition.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.ord'),
            dataIndex: 'ord',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.table.column.company'),
            dataIndex: ['company', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COMPANY.SEARCH, 'company.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.acquisition.table.column.document'),
            dataIndex: 'documentInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
        },

        {
            title: geti18nText('asset.acquisition.item.table.column.amount'),
            width: '180px',
            dataIndex: 'amount',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                if (amount) {
                    return numberFormat(amount);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.table.column.booked'),
            dataIndex: 'booked',
            width: '10%',
            render: (text: any, record: { booked: any }) => {
                if (record.booked) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '4px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '4px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBookedArray(), 'equals_bool', undefined),
        },
        /*     {  !!!! ostaviti -- kod ce se opet koristiti !!!!
            title: geti18nText('asset.acquisition.table.column.bookedGeneralLedger'),
            dataIndex: 'bookedGeneralLedger',
            width: '10%',
            render: (text: any, record: { bookedGeneralLedger: any }) => {
                if (record.bookedGeneralLedger) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '4px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '4px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBookedArray(), 'equals_bool', undefined),
        }, !!!! ostaviti -- kod ce se opet koristiti !!!! */
        {
            title: geti18nText('app.default.actions'),
            width: '60px',
            render: (text: any, record: any) => {
                if (record.active) {
                    return (
                        <Dropdown
                            key="more"
                            overlay={actionsMenu(record?.booked, record.id, record?.ord, record?.date)}
                            trigger={['hover']}
                        >
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                icon={<ToolOutlined style={{ fontSize: '14px' }} />}
                                style={{ cursor: 'pointer' }}
                            >
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    );
                }
            },
        },
        /*   { ostaviti kod
            title: geti18nText('asset.acquisition.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        }, ostaviti kod */
    ];

    const iconStyle: any = {
        fontSize: '16px',
        paddingTop: '2px',
        float: 'left',
    };

    const actionsMenu = (isBooked: any, recordId: any, ordinal?: any, date?: any) => {
        return (
            <Menu style={{ width: '140px' }}>
                <Menu.ItemGroup title={geti18nText('asset.acquisition.booking')}>
                    {canCreate() && !isBooked && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                                onConfirm={() => {
                                    bookItem(recordId);
                                }}
                            >
                                <Button style={{ width: '100%' }} icon={<BookOutlined style={iconStyle} />}>
                                    {geti18nText('asset.acquisition.button.booking')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canCreate() && isBooked && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                                onConfirm={() => {
                                    debookItem(recordId);
                                }}
                            >
                                <Button style={{ width: '100%' }} icon={<BookOutlined style={iconStyle} />}>
                                    {geti18nText('asset.acquisition.button.deBooking')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                </Menu.ItemGroup>
                <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <NyReportButton
                            style={{ width: '100%' }}
                            url={CONSTANTS_REQ.REPORT.ASSET_ACQUISITION}
                            subreportType={'ASSET_ACQUISITION'}
                            customParms={{ assetAcquisitionId: recordId }}
                            buttoni18nText={'app.default.button.print'}
                            fileName={
                                geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_ACQUISITION) +
                                ' ' +
                                ordinal +
                                '_' +
                                moment(date).format('YYYY')
                            }
                            customIconStyle={iconStyle}
                            customDateFormat={moment().format('yyyyMMDDHHmm')}
                            allowExportTypeChange={true}
                        />
                    </div>
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const bookItem = (id: any) => {
        if (id) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_ACQUISITION.BOOK + '/' + id, undefined, {
                id: id,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefresh((refresh) => refresh + 1);
                    okNotification(geti18nText('asset.acquisition.button.book.success'));
                } else {
                    errorNotification();
                }
            });
        }
    };

    const debookItem = (id: any) => {
        if (id) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_ACQUISITION.DE_BOOK + '/' + id, undefined, {
                id: id,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefresh((refresh) => refresh + 1);
                    okNotification(geti18nText('asset.acquisition.button.debook.success'));
                } else {
                    errorNotification();
                }
            });
        }
    };

    const book = () => {
        setLoading(true);
        message.loading(geti18nText('asset.acquisition.text.booking'));
        NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_ACQUISITION.BATCH_BOOK, undefined, {}).then((result: any) => {
            setLoading(false);
            message.destroy();
            if (result && result.status === RESPONSE.OK) {
                setBooked(true);
                okNotification(geti18nText('asset.acquisition.button.book.success'));
                setRefresh((refresh) => refresh + 1);
            } else {
                errorNotification();
            }
        });
    };

    const addedButtons = () => {
        const actionsMenu = (
            <Menu>
                {bookAllVisible && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <NyModalConfirm
                            title={geti18nText('asset.acquisition.popconfirm.booking.cancel.all')}
                            onConfirm={() => {
                                book();
                            }}
                        >
                            <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                                {geti18nText('asset.acquisition.button.booking.all')}
                            </Button>
                        </NyModalConfirm>
                    </div>
                )}
            </Menu>
        );

        return (
            actionsMenu?.props?.children && (
                <div style={{ float: 'left', paddingLeft: '15px' }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )
        );
    };

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 5 },
            { field: 'settingGroup', condition: 'equals', value: 'ASSET_DEPRECIATION' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'BOOK_ALL_UNBOOKED_ASSET_ACQUISITIONS') {
                                setBookAllVisible(element.value == 1 ? true : false);
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <>
            <AssetCustomTableFilter setRefresh={setRefresh} refresh={refresh} form={filterForm} />
            <NyDataTable
                nyId="asset-acquisition-items-table"
                url={CONSTANTS_REQ.ASSET_ACQUISITION.LIST}
                addNewButtonText={geti18nText('asset.acquisition.table.add')}
                buttonsClassName="buttons-sticky"
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                hideNewButton={!canCreate()}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.assets.acquisition')}
                colCSVCustomization={csvColumnCustomisation()}
                fetchWhenChange={refresh}
                addedButtons={addedButtons}
                headerTitle={geti18nText('menu.assets.acquisition')}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            booked: (value: any) => {
                return value ? geti18nText('app.enum.BOOKED_TYPE.1') : geti18nText('app.enum.BOOKED_TYPE.0');
            },
        },
        {
            bookedGeneralLedger: (value: any) => {
                return value ? geti18nText('app.enum.BOOKED_TYPE.1') : geti18nText('app.enum.BOOKED_TYPE.0');
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AssetAcquisitionIndex;
