import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import TownshipTaxEdit from './edit';
import { numberFormat } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const TownshipTaxIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('payroll/codebooks/township_tax');

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    const columns = [
        {
            title: geti18nText('townshipTax.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('townshipTax.table.column.township'),
            dataIndex: ['township', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TOWNSHIP.SEARCH, 'township.id', 'name'),
        },
        {
            title: geti18nText('townshipTax.table.column.validFrom'),
            dataIndex: 'validFrom',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('townshipTax.table.column.validTo'),
            dataIndex: 'validTo',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('townshipTax.table.column.taxPercent'),
            dataIndex: 'taxPercent',
            sorter: (a: any, b: any) => {},
            render: (taxPercent: any) => {
                return numberFormat(taxPercent);
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('townshipTax.table.column.taxIBAN'),
            dataIndex: 'taxIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('townshipTax.table.column.taxRemittance'),
            dataIndex: 'taxRemittance',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('townshipTax.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-township-tax-table"
            url={CONSTANTS_REQ.TOWNSHIP_TAX.LIST}
            addNewButtonText={geti18nText('townshipTax.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={TownshipTaxEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            headerTitle={geti18nText('menu.township.tax')}
        />
    );
};

export default TownshipTaxIndex;
