import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, DatePicker, Row } from 'antd';
import Form from 'antd/lib/form';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat, setDateFormat } from '../../../../../utils/Utils';

const EmployeeEvaluationPeriodEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.evaluation.period.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [dateFromTo, setDateFromTo] = useState<any>([]);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.dateFrom && dataForm.dateTo) {
            setEditHeader(
                geti18nText('employee.evaluation.period.edit.title') +
                    ' - ' +
                    getDateFormat(dataForm.dateFrom, 'DD.MM.YYYY.') +
                    ' - ' +
                    getDateFormat(dataForm.dateTo, 'DD.MM.YYYY.')
            );
            setDateFromTo([setDateFormat(dataForm.dateFrom), setDateFormat(dataForm.dateTo)]);
            form.setFieldsValue({ dateFromTo: [setDateFormat(dataForm.dateFrom), setDateFormat(dataForm.dateTo)] });
        } else if (dataForm.id) {
            setEditHeader(geti18nText('employee.evaluation.period.edit.title'));
        } else {
            setEditHeader(geti18nText('employee.evaluation.period.edit.new'));
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.evaluation.period.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.EDIT}
            setValues={setValues}
            width={400}
            form={form}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.dateFromTo) {
                    values.dateFrom = getDateFormat(dateFromTo[0]);
                    values.dateTo = getDateFormat(dateFromTo[1]);
                }
                delete values.dateFromTo;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }} />
                    <Form.Item
                        label={geti18nText('employee.evaluation.edit.dateFromTo')}
                        name="dateFromTo"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <DatePicker.RangePicker
                            format="DD.MM.YYYY."
                            style={{ width: '100%' }}
                            onChange={(values: any) => setDateFromTo(values)}
                            autoFocus
                            value={dateFromTo}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeEvaluationPeriodEdit;
