import {
    AuditOutlined,
    BookOutlined,
    DeliveredProcedureOutlined,
    FieldBinaryOutlined,
    FileDoneOutlined,
    FileOutlined,
    HomeOutlined,
    ProfileOutlined,
    ProjectOutlined,
    QrcodeOutlined,
} from '@ant-design/icons';
import { AdministrationRights } from '../../rights/administrationRights';
import { FixedAssetRights } from '../../rights/fixedAssetRights';

const AccountingModuleMenu = [
    {
        key: 'menu.accounting',
        path: '/accounting',
        icon: <HomeOutlined />,
        i18n: 'menu.home',
        role: FixedAssetRights.canViewAccountingOverview(),
    },
    {
        key: 'menu.assets',
        path: '/accounting/assets',
        icon: <QrcodeOutlined />,
        i18n: 'menu.assets',
        role: FixedAssetRights.canViewAssetsMenu(),
        submenu: [
            {
                key: 'menu.assets.fixed.assets',
                path: '/accounting/assets/fixed-asset',
                icon: <QrcodeOutlined />,
                i18n: 'menu.assets.overview',
                role: FixedAssetRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.assets.acquisition',
                path: '/accounting/assets/asset-acquisition',
                icon: <FileOutlined />,
                i18n: 'menu.assets.acquisition',
                role: FixedAssetRights.canViewAcquisitionMenu(),
            },
            {
                key: 'menu.assets.initialState',
                path: '/accounting/assets/asset-initial-state',
                icon: <FileOutlined />,
                i18n: 'menu.assets.initialState',
                role: FixedAssetRights.canViewInitialStateMenu(),
            },
            {
                key: 'menu.assets.writeOff',
                path: '/accounting/assets/asset-write-off',
                icon: <FileOutlined />,
                i18n: 'menu.assets.writeOff',
                role: FixedAssetRights.canViewWriteOffMenu(),
            },
            {
                key: 'menu.assets.depreciation',
                path: '/accounting/assets/asset-depreciation',
                icon: <FileOutlined />,
                i18n: 'menu.assets.depreciation',
                role: FixedAssetRights.canViewDepreciation(),
            },
            {
                key: 'menu.asset.rental.release',
                path: '/accounting/assets/asset-rental-release',
                icon: <DeliveredProcedureOutlined />,
                i18n: 'menu.asset.rental.release',
                role: FixedAssetRights.canViewAssetRent(),
            },
        ],
    },
    {
        key: 'menu.payment.orders',
        path: '/accounting/paymentOrders',
        icon: <AuditOutlined />,
        i18n: 'menu.payment.orders',
        role: FixedAssetRights.canViewPaymentOrder(),
        submenu: [
            {
                key: 'menu.payment.orders.individual',
                path: '/accounting/paymentOrders/paymentOrdersIndividual',
                icon: <FileDoneOutlined />,
                i18n: 'menu.payment.orders.individual',
                role: FixedAssetRights.canViewPaymentOrder(),
            },
            {
                key: 'menu.payment.orders.group',
                path: '/accounting/paymentOrders/paymentOrdersGroup',
                icon: <ProfileOutlined />,
                i18n: 'menu.payment.orders.group',
                role: FixedAssetRights.canViewPaymentOrder(),
            },
            {
                key: 'menu.payment.orders.collective',
                path: '/accounting/paymentOrders/paymentOrdersInitialization',
                icon: <FileDoneOutlined />,
                i18n: 'menu.payment.orders.collective',
                role: FixedAssetRights.canViewPaymentOrder(),
            },
        ],
    },
    {
        key: 'menu.accounting.codebooks',
        path: '/accounting/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.codebooks',
        role: AdministrationRights.canViewAccountingMenu(),
        submenu: [
            {
                key: 'menu.accounting.cost.center',
                path: '/accounting/codebooks/cost-center',
                icon: <FileOutlined />,
                i18n: 'menu.accounting.cost.center',
                role: AdministrationRights.canViewAccountingMenu(),
            },
            {
                key: 'menu.accounting.cost.object',
                path: '/accounting/codebooks/cost-object',
                icon: <FileOutlined />,
                i18n: 'menu.accounting.cost.object',
                role: AdministrationRights.canViewAccountingMenu(),
            },
            {
                key: 'menu.accounting.account',
                path: '/accounting/codebooks/account',
                icon: <FileOutlined />,
                i18n: 'menu.accounting.account',
                role: AdministrationRights.canViewAccountingMenu(),
            },
            {
                key: 'menu.accounting.entity.account',
                path: '/accounting/codebooks/accounting-entity-account',
                icon: <FileOutlined />,
                i18n: 'menu.accounting.entity.account',
                role: AdministrationRights.canViewAccountingMenu(),
            },
            {
                key: 'menu.assets.codebooks',
                path: '/accounting/codebooks/assets',
                icon: <BookOutlined />,
                i18n: 'menu.assets',
                role: FixedAssetRights.canViewCodebooksMenu(),
                submenu: [
                    {
                        key: 'menu.assets.asset.adp',
                        path: '/accounting/codebooks/assets/asset-adp',
                        icon: <FieldBinaryOutlined />,
                        i18n: 'menu.assets.asset.adp',
                        role: FixedAssetRights.canViewCodebooksMenu(),
                    },
                    {
                        key: 'menu.assets.depreciation.group',
                        path: '/accounting/codebooks/assets/asset-depreciation-group',
                        icon: <ProjectOutlined />,
                        i18n: 'menu.assets.depreciation.group',
                        role: FixedAssetRights.canViewCodebooksMenu(),
                    },
                ],
            },
        ],
    },
];
export default AccountingModuleMenu;
