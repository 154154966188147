import { IssuesCloseOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Card, Statistic, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InventoryRights } from '../../rights/inventoryRights';
import { RootState } from '../../rootReducer';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getColorFromNotificationType } from '../../utils/Utils';

const InventoryOrderWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const hasInventoryReleaseRole = () => {
        return InventoryRights.canCreateRelease();
    };

    const widgetColor = getColorFromNotificationType(settings, 'INVENTORY_ORDER');

    function getInventoryKeys() {
        let keys = [];
        keys.push('myInventoryOrder');
        if (hasInventoryReleaseRole()) {
            keys.push('inventoryOrderRequested');
            keys.push('inventoryOrderApproved');
        }

        return {
            keys: keys,
        };
    }

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(CONSTANTS_REQ.DASHBOARD.GET_STATISTICS, undefined, getInventoryKeys(), true, false, {
        myInventoryOrder: 0,
        inventoryOrderRequested: -1,
        inventoryOrderApproved: -1,
    });

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px 10px',
    };

    return (
        <Card
            className="ny-widget-card"
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            title={geti18nText('menu.material')}
            bodyStyle={{
                height: 'calc(100% - 55px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '4px 5px 0px 5px',
                marginTop: '1px',
            }}
            type="inner"
            extra={
                <Tooltip placement="top" title={geti18nText('menu.webshopItem')}>
                    <ShoppingCartOutlined
                        className="ny-card-icon"
                        onClick={() => {
                            history.push('/webshop/webshop-item');
                        }}
                    />
                </Tooltip>
            }
        >
            {loading ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : (
                <>
                    <Card.Grid
                        style={gridStyle}
                        className="statistic-card"
                        onClick={() => history.push('/webshop/webshop-item/my-inventory-order')}
                    >
                        <Statistic
                            title={geti18nText('dashboard.statistic.inventory.order.count')}
                            value={statisticData && statisticData['myInventoryOrder']}
                            prefix={<ShoppingCartOutlined />}
                        />
                    </Card.Grid>
                    {statisticData && statisticData['inventoryOrderRequested'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => history.push('/webshop/webshop-item/inventory-order')}
                        >
                            <Statistic
                                title={geti18nText('dashboard.statistic.inventory.order.requested.count')}
                                value={statisticData['inventoryOrderRequested']}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                    {statisticData && statisticData['inventoryOrderApproved'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => history.push('/webshop/webshop-item/inventory-order')}
                        >
                            <Statistic
                                title={geti18nText('dashboard.statistic.inventory.order.approved.count')}
                                value={statisticData['inventoryOrderApproved']}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                </>
            )}
        </Card>
    );
};

export default InventoryOrderWidget;
