import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import ContractCategoryIndex from '.';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customRenderTextOption } from '../../../../../utils/Utils';
import ContractCategoryEdit from './edit';

const ContractCategorySearch = ({
    label = geti18nText('contract.category.rights.edit.contractCategory'),
    name = 'contractCategory',
    required = false,
    disabled = false,
    onChange = null,
    mustGetPopupContainer = true,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateContractAdmin();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.CONTRACT_CATEGORY.SEARCH}
                map={{
                    id: 'id',
                    label: 'text',
                    text: 'contractCategory',
                    contractCategory: 'contractCategory',
                }}
                searchBy="contractCategory"
                order="contractCategory"
                SearchPopupComponent={<ContractCategoryIndex />}
                AddNewModalComponent={canCreate() ? ContractCategoryEdit : null}
                nyTestId="contractCategory"
                disabled={disabled}
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                renderOption={customRenderTextOption}
                onChange={onChange}
                customItemNameLabel="contractCategory"
                itemName={'contractCategory'}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default ContractCategorySearch;
