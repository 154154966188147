import { BookOutlined, CheckOutlined, CloseOutlined, DownOutlined, ToolOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Dropdown, Form, Menu } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router';
import NyErrorLogModal from '../../../../../components/error-log-modal';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNodeNotification,
    errorNotification,
    getEnumArrayStatus,
    getEnumBooleanArray,
    numberFormat,
    okNotification,
} from '../../../../../utils/Utils';
import AssetCustomTableFilter from '../CustomFilter';
import AddAssetsIntoCalculationModal from './AddAssetsIntoCalculationModal';

const AssetDepreciationIndex = ({ scroll = { y: 460, x: 2800 }, sortOrder }: any) => {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [errorLogModalData, setErrorLogModalData] = useState([]);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const table = useTableSettings();
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const [filterForm] = Form.useForm();

    useHelper('fixed_asset/case/asset-depreciation');

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetDepreciation();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCsvExportAssetDepreciation();
    };

    let defaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }];

    const setDefaultFilterValue = () => {
        let currentDefaultFilterValue = [...defaultFilterValue];
        if (filterForm.getFieldValue('asset')) {
            const assetID = filterForm.getFieldValue('asset').id;
            if (assetID != -1) {
                currentDefaultFilterValue.push({
                    field: 'asset.id',
                    condition: 'equals',
                    value: assetID.toString(),
                });
            }
        }
        if (filterForm.getFieldValue('serial_number')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number').toString(),
            });
        }
        if (filterForm.getFieldValue('serial_number2')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number2',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number2').toString(),
            });
        }
        return currentDefaultFilterValue;
    };

    const columns = [
        {
            title: geti18nText('asset.depreciation.table.column.id'),
            dataIndex: 'id',
            width: '2%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('asset.depreciation.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.depreciation.table.column.ord'),
            dataIndex: 'ord',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.depreciation.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.depreciation.table.column.method'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('ASSET_DEPRECIATION_METHOD_TYPE')),
            render: (text: any, record: any) => {
                if (record.type) {
                    return geti18nText('app.enum.ASSET_DEPRECIATION_METHOD_TYPE.' + record.type);
                }
            },
        },
        {
            title: geti18nText('asset.depreciation.table.column.yearlyCalculation'),
            dataIndex: 'yearly',
            render: (text: any, record: { yearly: any }) => {
                if (record.yearly) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('asset.depreciation.table.column.acceleratedDepreciation'),
            dataIndex: 'accelerated',
            render: (text: any, record: { accelerated: any }) => {
                if (record.accelerated) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('asset.depreciation.table.column.fromDate'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.depreciation.table.column.toDate'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.acquisitionValue'),
            dataIndex: ['totals', 'acquisitionValue'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.acquisitionValue) {
                    return numberFormat(record.totals?.acquisitionValue);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.valueIncrease'),
            dataIndex: ['totals', 'valueIncrease'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.valueIncrease) {
                    return numberFormat(record.totals?.valueIncrease);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.valueDecrease'),
            dataIndex: ['totals', 'valueDecrease'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.valueDecrease) {
                    return numberFormat(record.totals?.valueDecrease);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.accumulatedDepreciation'),
            dataIndex: ['totals', 'accumulatedDepreciation'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.accumulatedDepreciation) {
                    return numberFormat(record.totals?.accumulatedDepreciation);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.amount'),
            dataIndex: ['totals', 'amount'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.amount) {
                    return numberFormat(record.totals?.amount);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.netBookValue'),
            dataIndex: ['totals', 'netBookValue'],
            width: '240px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.netBookValue) {
                    return numberFormat(record.totals?.netBookValue);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.internal.accumulated.depreciation.sum'),
            dataIndex: ['totals', 'internalAccumulatedDepreciation'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.internalAccumulatedDepreciation) {
                    return numberFormat(record.totals?.internalAccumulatedDepreciation);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.internal.amount.sum'),
            dataIndex: ['totals', 'internalAmount'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.internalAmount) {
                    return numberFormat(record.totals?.internalAmount);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.depreciationItem.table.column.internal.netBookValue.sum'),
            dataIndex: ['totals', 'internalNetBookValue'],
            width: '240px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.totals && record.totals?.internalNetBookValue) {
                    return numberFormat(record.totals?.internalNetBookValue);
                } else {
                    return numberFormat(0);
                }
            },
        },

        {
            title: geti18nText('asset.depreciation.table.column.booked'),
            dataIndex: 'booked',
            width: '4%',
            render: (text: any, record: { booked: any }) => {
                if (record.booked) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '4px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '4px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        /*     {
            title: geti18nText('asset.depreciation.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        }, */
        {
            title: geti18nText('app.default.actions'),
            width: '60px',
            render: (text: any, record: any) => {
                if (record.active) {
                    return (
                        <Dropdown
                            key="more"
                            overlay={actionsMenu(record?.booked, record.id, record?.ord, record?.date)}
                            trigger={['hover']}
                        >
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                icon={<ToolOutlined style={{ fontSize: '14px' }} />}
                                style={{ cursor: 'pointer' }}
                            >
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    );
                }
            },
        },
    ];

    const iconStyle: any = {
        fontSize: '16px',
        paddingTop: '2px',
        float: 'left',
    };

    const actionsMenu = (isBooked: any, recordId: any, ordinal?: any, date?: any) => {
        return (
            <Menu style={{ width: !isBooked ? '220px' : '140px' }}>
                <Menu.ItemGroup title={geti18nText('asset.acquisition.booking')}>
                    {canCreate() && !isBooked && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('asset.depreciation.popconfirm.calculate.cancel')}
                                onConfirm={() => {
                                    calculate(recordId);
                                }}
                            >
                                <Button style={{ width: '100%' }} icon={<BookOutlined style={iconStyle} />}>
                                    {geti18nText('asset.depreciation.calculate')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canCreate() && !isBooked && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                                onConfirm={() => {
                                    bookItem(recordId);
                                }}
                            >
                                <Button style={{ width: '100%' }} icon={<BookOutlined style={iconStyle} />}>
                                    {geti18nText('asset.acquisition.button.booking')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canCreate() && isBooked && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                                onConfirm={() => {
                                    debookItem(recordId);
                                }}
                            >
                                <Button style={{ width: '100%' }} icon={<BookOutlined style={iconStyle} />}>
                                    {geti18nText('asset.acquisition.button.deBooking')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canCreate() && !isBooked && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <AddAssetsIntoCalculationModal
                                style={{ width: '100%' }}
                                dataForm={recordId}
                                setLoading={setLoading}
                                iconStyle={iconStyle}
                            />
                        </div>
                    )}
                </Menu.ItemGroup>
                <Menu.ItemGroup title={geti18nText('app.default.button.print')}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <NyReportButton
                            style={{ width: '100%' }}
                            url={CONSTANTS_REQ.REPORT.ASSET_DEPRECIATION}
                            subreportType={'ASSET_DEPRECATION'}
                            customParms={{ assetDepreciationId: recordId }}
                            buttoni18nText={'app.default.button.print'}
                            fileName={
                                geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_DEPRECATION) +
                                ' ' +
                                ordinal +
                                '_' +
                                moment(date).format('YYYY')
                            }
                            customIconStyle={iconStyle}
                            customDateFormat={moment().format('yyyyMMDDHHmm')}
                            allowExportTypeChange={true}
                        />
                    </div>
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const bookItem = (id: any) => {
        if (id) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_DEPRECIATION.BOOK + '/' + id, undefined, {
                id: id,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefresh((refresh) => refresh + 1);
                    okNotification(geti18nText('asset.acquisition.button.book.success'));
                } else {
                    errorNotification(geti18nText('asset.write.off.booked.negative.net.book.value'));
                }
            });
        }
    };

    const debookItem = (id: any) => {
        if (id) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_DEPRECIATION.DE_BOOK + '/' + id, undefined, {
                id: id,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefresh((refresh) => refresh + 1);
                    okNotification(geti18nText('asset.acquisition.button.debook.success'));
                } else {
                    errorNotification();
                }
            });
        }
    };

    const calculate = (id: any) => {
        if (id) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_DEPRECIATION.CALCULATE + '/' + id, undefined, {
                id: id,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification(geti18nText('asset.depreciation.button.calculate.success'));
                } else {
                    if (result.data && result.data.error && result.data.error.length > 0) {
                        let data = JSON.parse(result.data.error);
                        errorNodeNotification(
                            <Button
                                type="link"
                                onClick={() => {
                                    setErrorLogModalData(data);
                                    setErrorLogModalVisible(true);
                                    setErrorLogModalTitle(geti18nText('asset.depreciation.error.details.modal'));
                                }}
                            >
                                {geti18nText('asset.depreciation.error.details')}
                            </Button>
                        );
                    } else {
                        errorNotification();
                    }
                }
                setLoading(false);
            });
        }
    };

    return (
        <>
            <AssetCustomTableFilter setRefresh={setRefresh} refresh={refresh} form={filterForm} />
            <NyDataTable
                nyId="asset-deprecation-table"
                url={CONSTANTS_REQ.ASSET_DEPRECIATION.LIST}
                addNewButtonText={geti18nText('asset.depreciation.table.add')}
                buttonsClassName="buttons-sticky"
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                hideNewButton={!canCreate()}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.assets.depreciation')}
                fetchWhenChange={refresh}
                colCSVCustomization={csvColumnCustomisation()}
                headerTitle={geti18nText('menu.assets.depreciation')}
            />
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_DEPRECIATION_METHOD_TYPE.' + value);
            },
        },
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            dateFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            yearly: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            accelerated: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            booked: (value: any) => {
                return value ? geti18nText('app.enum.BOOKED_TYPE.1') : geti18nText('app.enum.BOOKED_TYPE.0');
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AssetDepreciationIndex;
