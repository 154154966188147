import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple, getColumnSearchByCodebookUserPerson } from '../../../../../utils/Filters';
import ContractCategoryRightsEdit from './edit';

const ContractCategoryRightsIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const table = useTableSettings();

    useHelper('human/codebooks/contracts/contract-category');

    const canCreate = () => {
        return HumanResourcesRights.canCreateContractAdmin();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContractAdmin();
    };

    const columns = [
        {
            title: geti18nText('contract.category.rights.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('contract.category.rights.table.column.contractCategory'),
            dataIndex: ['contractCategory', 'contractCategory'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.CONTRACT_CATEGORY.SEARCH,
                'contractCategory.id',
                'contractCategory',
                'contractCategory',
                {
                    id: 'id',
                    label: 'contractCategory',
                }
            ),
        },
        {
            title: geti18nText('contract.category.rights.table.column.user'),
            dataIndex:
                "concat(user_person.first_name, ' ', user_person.last_name, ' ', coalesce(cast(user_employee.employment_record_id as varchar),''))",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookUserPerson(),
            render: (text: any, record: any) => {
                if (record.user && record.user.person) {
                    return (
                        `${record.user.person.firstName} ${record.user.person.lastName}` +
                        (record.user.employee && record.user.employee.employmentRecordId
                            ? ` (${record.user.employee.employmentRecordId})`
                            : '')
                    );
                }
            },
        },
        {
            title: geti18nText('contract.category.rights.table.column.validFrom'),
            dataIndex: 'validFrom',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('contract.category.rights.table.column.validTo'),
            dataIndex: 'validTo',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('contract.category.rights.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-contract-contract-category-rights-table"
            url={CONSTANTS_REQ.CONTRACT_CATEGORY_RIGHTS.LIST}
            addNewButtonText={geti18nText('contract.category.rights.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={ContractCategoryRightsEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.contract.category.rights')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            headerTitle={geti18nText('menu.contract.category.rights')}
        />
    );
};
export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.category.rights.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.category.rights.table.column.contractCategory'),
            dataIndex: ['contractCategory', 'contractCategory'],
        },
        {
            title: geti18nText('contract.category.rights.table.column.user'),
            dataIndex: 'user',
        },
        {
            title: geti18nText('contract.category.rights.table.column.validFrom'),
            dataIndex: 'validFrom',
        },
        {
            title: geti18nText('contract.category.rights.table.column.validTo'),
            dataIndex: 'validTo',
        },
        {
            title: geti18nText('contract.category.rights.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            user: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ContractCategoryRightsIndex;
