import { valueToNode } from '@babel/types';
import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Switch } from 'antd';
import { useEffect, useState } from 'react';
import NyReportButton from '../../../../../components/report-button';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import AssetTypeIndex from '../../../../webshop/views/webshop-asset/asset-type';
import useEnum from '../../../../../hooks/useEnum';
import { FilePdfOutlined } from '@ant-design/icons';

const DownloadByType = ({ title, assetInitialStateId }: any) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [metadata, setMetadata] = useState([]);
    const [sort, setSort] = useState(true);
    const [assetTypeId, setAssetTypeId] = useState(null);

    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const closeModal = () => {
        setVisible(false);
        form.resetFields();
    };

    const onTypeChange = (value: any) => {
        if (value && value.id > 0) {
            fetchMetadata(value.id);
            setAssetTypeId(value.id);
        } else {
            setAssetTypeId(null);
        }
    };

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'assetType.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_TYPE.METADATA, {
            max: 100,
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setMetadata(result.data.content);
                }
            }
        });
    };

    const handleOnCancel = (event: any) => {
        closeModal();
        event.stopPropagation();
        event.preventDefault();
    };

    const iconStyle: any = {
        fontSize: '16px',
        paddingTop: '2px',
        float: 'left',
    };

    return (
        <>
            <Button
                icon={<FilePdfOutlined style={iconStyle} />}
                style={{ width: '100%' }}
                onClick={() => setVisible(true)}
            >
                {title}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    maskClosable={false}
                    title={title}
                    closable
                    onCancel={closeModal}
                    footer={[
                        <NyReportButton
                            url={CONSTANTS_REQ.REPORT.ASSET_INITIAL_STATE}
                            customParms={{
                                assetInitialStateId: assetInitialStateId,
                                assetTypeId: assetTypeId,
                                sortByInventoryNumber: sort,
                            }}
                            buttoni18nText={'asset.initialState.downloadByType'}
                            fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_INITIAL_STATE)}
                            disabled={!assetTypeId || !assetInitialStateId}
                            allowExportTypeChange={true}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        component={false}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label={geti18nText('asset.initialState.download.sort')} name="sort">
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Checkbox defaultChecked onChange={(e: any) => setSort(e.target.checked)} />
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item label={geti18nText('asset.initialState.download.type')} name="assetType">
                                    <NySearchField
                                        url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        SearchPopupComponent={<AssetTypeIndex hideNew />}
                                        onChange={onTypeChange}
                                        addNewItem={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default DownloadByType;
