import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHelperUrl } from '../slices/helperSlice';

const useHelper = (url: string | undefined) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHelperUrl(url));
    }, []);
};

export default useHelper;
