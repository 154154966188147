import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button } from 'antd';
import { ReactText, useState } from 'react';
import { useDispatch } from 'react-redux';

import PaymentOrdersGroupEdit from '../payment-orders-group/edit';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { refreshItemList } from '../../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import {
    getEnumArray,
    getEnumBooleanArray,
    numberFormat,
    getDateFormat,
    okNotification,
    errorNotification,
} from '../../../../../utils/Utils';
import { PayrollRights } from '../../../../../rights/payrollRights';

const GroupOrdersTable = ({ props, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const dispatch = useDispatch();
    const table = useTableSettings();

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'payment_order_bulk_id', condition: 'equals', value: props.bulkId },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('payment.orders.group.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.paymentInformation'),
            dataIndex: 'paymentInformationId',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.paymentMethod'),
            dataIndex: 'paymentMethod',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('SEPA_PAYMENT_METHOD_TYPE_CODE')),
            render: (text: any, record: any) => {
                if (record.paymentMethod !== undefined) {
                    return geti18nText('app.enum.SEPA_PAYMENT_METHOD_TYPE_CODE.' + record.paymentMethod);
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.batchBooking'),
            dataIndex: 'batchBooking',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { batchBooking: any }) => {
                if (record.batchBooking) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('payment.orders.group.table.column.numberOfTransactions'),
            dataIndex: 'numberOfTransactions',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.controlSum'),
            dataIndex: 'controlSum',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (controlSum: any) => {
                if (controlSum) {
                    return numberFormat(controlSum);
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.priority'),
            dataIndex: 'priority',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('SEPA_PRIORITY_TYPE_CODE')),
            render: (text: any, record: any) => {
                if (record.priority !== undefined) {
                    return geti18nText('app.enum.SEPA_PRIORITY_TYPE_CODE.' + record.priority);
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.requestedExecutionDate'),
            dataIndex: 'requestedExecutionDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.requestedExecutionDate) {
                    return getDateFormat(record.requestedExecutionDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.group.table.column.debtorName'),
            dataIndex: 'debtorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.debtorAccountIban'),
            dataIndex: 'debtorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.group.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue,
        },
    ];

    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    let clearKeys: any;

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    const onDeleteFromGroupClick = (onSave: () => void) => {
        let values = {
            paymentOrderBulkId: props.bulkId,
            paymentGroups: selectedValues,
        };
        NyRequestResolver.requestPut(CONSTANTS_REQ.PAYMENT_ORDER_GROUP.DELETE_FROM_BULK, undefined, values).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    clearKeys();
                    onSave();
                    props.form.resetFields();
                    dispatch(refreshItemList());
                } else {
                    errorNotification();
                }
            }
        );
    };

    const DeleteFromGroup = (onSave: () => void) => {
        return (
            <>
                {hasSelected && canCreate() && (
                    <NyModalConfirm
                        title={geti18nText('payment.orders.initialization.DeleteFromBulk.text')}
                        onConfirm={() => {
                            onDeleteFromGroupClick(onSave);
                        }}
                    >
                        <Button>{geti18nText('payment.orders.initialization.DeleteFromBulk')}</Button>
                    </NyModalConfirm>
                )}
            </>
        );
    };

    return (
        <NyDataTable
            nyId="payment-orders-initialization"
            url={CONSTANTS_REQ.PAYMENT_ORDER_GROUP.LIST}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            showRecordModal={true}
            modalComponent={PaymentOrdersGroupEdit}
            hideNewButton={true}
            columns={initialColumns}
            readonly={props.status === 3}
            showRowSelection={canCreate() && (props.status === 3 ? false : props.status === 2 ? false : true)}
            rowSelectionType={'checkbox'}
            rowSelectionModal={setSelectedValuesFunc as any}
            addedButtons={DeleteFromGroup}
            buttonsClassName="ny-data-table-modal-footer"
        />
    );
};

export default GroupOrdersTable;
