import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
// import { addTab, setActiveTab } from '../../../../../slices/tabsSlice';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import {
    errorNotification,
    getDateFormat,
    getOrdinal,
    getWageErrorsMessages,
    okNotification,
} from '../../../../../utils/Utils';

const TravelWarrantTableNotCalculated = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    canViewWageErrors = false,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'in', value: '7,10' },
        ];
    };

    const changeStatus = (recordId: any, status: any, info: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: info,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh: number) => refresh + 1);
                if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                    okNotification(geti18nText('travelWarrantPersonal.sendToApproval.ok'));
                } else if (status == travelWarrantStatusEnum.REJECTED) {
                    okNotification(geti18nText('travelWarrantPersonal.cancelTrip.ok'));
                }
            } else if (result.data && result.data.error) {
                errorNotification(geti18nText(result.data.error));
            } else if (result.data && result.data instanceof String) {
                errorNotification(result.data);
            } else {
                errorNotification();
            }
        });
    };

    const openTravelWarrant = (recordId: any) => {
        if (recordId) {
            // const panes = tabs.filter((item: any) => item.path === '/travelWarrant/' + recordId);
            // if (panes.length === 0) {
            //     dispatch(
            //         addTab({
            //             title: 'travelWarrantPersonal.edit.title.short' + '-' + recordId,
            //             path: '/travelWarrant/' + recordId,
            //             color: getColorFromNotificationType(settings, 'TRAVEL_WARRANT_APPROVAL'),
            //         })
            //     );
            // } else {
            // dispatch(setActiveTab('/travelWarrant/' + recordId));
            history.push('/travel-warrant/travelWarrant/' + recordId);
            // }
        }
    };

    const initialColumns = [
        {
            title: geti18nText('travelWarrant.table.column.ordinal'),
            dataIndex: 'ordinal',
            width: '110px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record && record.ordinal && record.status > travelWarrantStatusEnum.DRAFT) {
                    return (
                        <>
                            <Tooltip title={geti18nText('menu.travel.warrant.details')}>
                                <Button
                                    type="link"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() =>
                                        // dispatch(
                                        //     addTab({
                                        //         title: 'travelWarrant.edit.title.short' + '-',
                                        //         addedData: record.id,
                                        //         path: '/travelWarrant/' + record.id,
                                        //     })
                                        // )
                                        history.push('/travel-warrant/travelWarrant/' + record.id)
                                    }
                                >
                                    {getOrdinal(record)}
                                </Button>
                            </Tooltip>
                            {getWageErrorsMessages(
                                record?.wageErrors,
                                canViewWageErrors && record?.status != travelWarrantStatusEnum.PAID
                            )}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.warrantDate'),
            dataIndex: 'warrantDate',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (warrantDate: any, record: any) => {
                if (record.warrantDate) {
                    return getDateFormat(record.warrantDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (person: any, record: any) => {
                if (record && record.person) {
                    return (
                        <>
                            {`${record.person.firstName} ${record.person.lastName} `}
                            {record.employee &&
                                record.employee.employmentRecordId &&
                                ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.destination'),
            dataIndex: 'destination',
            width: '16%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWarrant.table.column.startDate'),
            dataIndex: 'startDate',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.calculationDate'),
            dataIndex: 'calculationDate',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (calculationDate: any, record: any) => {
                if (record.calculationDate) {
                    return getDateFormat(record.calculationDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <RowAction
                            component={'TravelWarrant'}
                            record={record}
                            calculateAdminForm={openTravelWarrant}
                            changeStatus={changeStatus}
                            fetchAccessRights
                        ></RowAction>
                    );
                }
            },
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="travel-warrant-travel-warrant-not-calculated-table"
                url={CONSTANTS_REQ.TRAVEL_WARRANT.LIST}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder}
                scroll={scroll}
                fetchWhenChange={refresh}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                shortcuts={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                hideNewButton={true}
            />
        </>
    );
};

export default TravelWarrantTableNotCalculated;
