import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Dropdown, Form, Menu } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getEnumBooleanArray } from '../../../../utils/Utils';
import PaymentCalculationReportModal from './PaymentCalculationReportModal';
import { PayrollRights } from '../../../../rights/payrollRights';

const PaymentCalculationIndex = ({ type, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const table = useTableSettings();
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState<any>(false);

    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'calc_type', condition: 'equals', value: type },
        ];
    };

    const columns = [
        {
            title: geti18nText('payment.calculation.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.calculation.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.calculation.table.column.date'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.created) {
                    return moment(record.created).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('payment.calculation.table.column.payDay'),
            dataIndex: 'payDate',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.payDate) {
                    return moment(record.payDate).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('payment.calculation.table.column.hours'),
            dataIndex: 'workHours',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.calculation.table.column.locked'),
            dataIndex: 'locked',
            width: '10%',
            render: (text: any, record: { locked: any }) => {
                if (record.locked) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
    ];

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button onClick={() => setModalVisible(true)} block>
                        {geti18nText('payment.calculation.report.income.certificate')}
                    </Button>
                </div>
            </div>
        </Menu>
    );

    const FooterButtons = (onSave: () => void) => {
        return (
            canCreate() && (
                <div style={{ float: 'left', paddingLeft: '15px' }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )
        );
    };

    return (
        <>
            <NyDataTable
                nyId="human-payment-calculation-table"
                url={CONSTANTS_REQ.PAYMENT_CALCULATION.LIST}
                addNewButtonText={geti18nText('payment.calculation.table.add.' + type)}
                buttonsClassName="buttons-sticky"
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => setDefaultFilterValue()}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                hideNewButton={!canCreate()}
                addedButtons={FooterButtons}
            />
            <PaymentCalculationReportModal modalVisible={modalVisible} setModalVisible={setModalVisible} type={type} />
        </>
    );
};

export default PaymentCalculationIndex;
