import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { MealsRights } from '../../../../../rights/mealsRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import NotWorkingDaysEdit from './edit';

const NotWorkingDaysIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const table = useTableSettings();
    useHelper('meals/codebooks/not-working-days');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('notWorkingDays.table.column.id'),
            dataIndex: 'id',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('notWorkingDays.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('notWorkingDays.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('notWorkingDays.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <NyDataTable
            nyId="price-list"
            url={CONSTANTS_REQ.NOT_WORKING_DAYS.LIST}
            addNewButtonText={geti18nText('notWorkingDays.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={NotWorkingDaysEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.meals.notWorkingDays')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.meals.notWorkingDays')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default NotWorkingDaysIndex;
