import { geti18nText } from '@nybble/nyreact';
import { Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxQuantity = undefined,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const daysEnum = [
        { value: 1, label: geti18nText('deliverySpot.edit.mon') },
        { value: 2, label: geti18nText('deliverySpot.edit.tue') },
        { value: 3, label: geti18nText('deliverySpot.edit.wed') },
        { value: 4, label: geti18nText('deliverySpot.edit.thu') },
        { value: 5, label: geti18nText('deliverySpot.edit.fri') },
        { value: 6, label: geti18nText('deliverySpot.edit.sat') },
        { value: 7, label: geti18nText('deliverySpot.edit.sun') },
    ];

    const defaultValue = daysEnum.find((dayEnum: any) => {
        return dayEnum.value == record?.day;
    });

    switch (inputType) {
        case 'days':
            inputNode = <Select defaultValue={defaultValue} options={daysEnum} />;
            break;
        case 'time':
            inputNode = <Input />;
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                inputType == 'time' ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                )
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
