import { useState } from 'react';
import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import PaymentOrdersGroupIndex from '../payment-orders-group';
import PaymentOrdersGroupEdit from '../payment-orders-group/edit';
import { errorNotification, getDateFormat, okNotification } from '../../../../../utils/Utils';

const AddToGroupModal = ({ onSave, modalVisible, setModalVisible, selectedValues, clearKeys }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const defaultFilterValue = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'batch_booking', condition: 'equals_bool', value: 0 },
    ];

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.groupId = values.groupId.id;
                values.paymentOrders = selectedValues;
                NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_ORDER.ADD_TO_GROUP, undefined, values).then(
                    (result: any) => {
                        if (setLoading) setLoading(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            okNotification();
                            setModalVisible(false);
                            clearKeys();
                            onSave();
                            form.resetFields();
                        } else {
                            if (result.data && result.data.error) {
                                if (geti18nText(result.data.error) != '') {
                                    errorNotification(geti18nText(result.data.error));
                                } else {
                                    errorNotification(JSON.stringify(result.data.error));
                                }
                            } else {
                                errorNotification();
                            }
                        }
                    }
                );
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    const customRenderOption = (option: any) => (
        <>
            <span style={{ display: 'block' }}>{option.text}</span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>
                {geti18nText('payment.orders.group.table.column.requestedExecutionDate')}:{' '}
                {getDateFormat(option.requestedExecutionDate, 'DD.MM.yyyy')}
            </span>
        </>
    );

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('payment.orders.individual.AddToGroup.title')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.orders.individual.AddToGroup.group')}
                                    name={'groupId'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.PAYMENT_ORDER_GROUP.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'paymentInformationId',
                                            requestedExecutionDate: 'requestedExecutionDate',
                                        }}
                                        searchBy="paymentInformationId"
                                        autoFocus={true}
                                        SearchPopupComponent={
                                            <PaymentOrdersGroupIndex defaultFilterValue={defaultFilterValue} />
                                        }
                                        itemName={'paymentInformationId'}
                                        renderOption={customRenderOption}
                                        AddNewModalComponent={PaymentOrdersGroupEdit}
                                        nyTestId="input-group"
                                        setDefaultFilterValue={() => defaultFilterValue}
                                        order="paymentInformationId"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AddToGroupModal;
