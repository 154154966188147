import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
// import { setActiveTab } from '../../slices/tabsSlice';
import { errorNotification } from '../../utils/Utils';

const DownloadIndex = () => {
    const history = useHistory();
    let { search } = useLocation();
    const query: any = new URLSearchParams(search);
    const [changeStatus, setChangeStatus] = useState<any>(undefined);
    const dispatch = useDispatch();
    const [file, setFile] = useState<any>(undefined);

    useEffect(() => {
        setChangeStatus(true);
    }, []);

    useEffect(() => {
        if (changeStatus) {
            if (query.get('data') != undefined) {
                var customParms = JSON.parse(atob(query.get('data')));
                downloadReport(customParms);
            }
        }
    }, [changeStatus]);

    const downloadReport = (customParms: any) => {
        if (customParms) {
            let url = customParms.url;
            delete customParms.url;

            let parms = {
                lang: NyUtils.getSelectedLanguage(),
                subreportId: undefined,
                useDefault: true,
                ...customParms,
            };

            message.loading(geti18nText('import.text.loading'));
            NyRequestResolver.requestGet(url, parms, false, true).then((result: any) => {
                message.destroy();
                if (result.status === RESPONSE.OK && result.data) {
                    setFile(result);
                } else {
                    errorNotification(geti18nText('app.download.error'));
                }
            });
        }
    };

    if (!NySession.isUserAuthenticated()) {
        // dispatch(setActiveTab(history.location.pathname + history.location.search));
        return <Redirect to="/login" />;
    } else {
        return (
            <>
                {file && (
                    <embed
                        src={window.URL.createObjectURL(file.data.file)}
                        type="application/pdf"
                        height="100%"
                        width="100%"
                    ></embed>
                )}
            </>
        );
    }
};

export default DownloadIndex;
