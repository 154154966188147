import { AppstoreOutlined, BlockOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Affix, Button, Col, Dropdown, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import DirectoryWidget from '../../../dashboard/DirectoryWidget';

import { geti18nText, NySession } from '@nybble/nyreact';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import ActionsWidgetHR from '../../../../components/actions-widget/hrWidget';
import { dashboardInit, layoutChange, setLayoutDefault, setLock } from '../../../../slices/dashboardHRslice';
import NotificationWidget from '../../../dashboard/NotificationWidget';
import ChartAgeRange from './charts/ChartAgeRange';
import ChartBusinessUnitEmployees from './charts/ChartBusinessUnitEmployees';
import PieChartGenderCard from './charts/PieChartGender';

export interface IWidget {
    dataGrid: { [index: string]: string | number | boolean };
    elementKey: string;
    data: any;
    overviewIndex?: boolean;
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const widgetCatalogs: any = {
    DirectoryWidget: DirectoryWidget,
    NotificationWidget: NotificationWidget,
    ActionsWidget: ActionsWidgetHR,
    ChartAgeRangeWidget: ChartAgeRange,
    ChartBusinessUnitEmployeesWidget: ChartBusinessUnitEmployees,
    PieChartGenderCardWidget: PieChartGenderCard,
};

const HumanOverviewIndex = () => {
    const dispatch = useDispatch();
    const { layouts, lastLayout, lock, allWidgets, widgets } = useSelector((state: RootState) => state.dashboardHR);
    const { pinedNotifications } = useSelector((state: RootState) => state.pinedNotifications);
    const [widgetsHR, setWidgetsHR] = useState<any>([]);

    const createElement = (obj: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
        data: any;
    }) => {
        let newLastLayout: { [index: string]: any } | undefined = undefined;
        if (lastLayout && lastLayout.length > 0) {
            const ly = lastLayout.find(({ i }: any) => i === obj.key);
            if (ly != undefined) newLastLayout = { ...ly };
            delete newLastLayout?.i;
        }

        let Tagname = widgetCatalogs[obj.component];
        let settings: IWidget = {
            dataGrid: newLastLayout != undefined ? newLastLayout : obj.dataGrid,
            elementKey: obj.key,
            data: obj.data,
        };

        const w = React.createElement(Tagname, settings, '');

        return React.createElement(
            'div',
            {
                id: 'container_' + obj.key,
                key: obj.key,
                'data-grid': obj.dataGrid,
                className: `dashboard-widget-box ${lock ? '' : 'dashboard-widget-box-unlock'}`,
            },
            w,
            lock && <></>
        );
    };

    useEffect(() => {
        if (widgets) {
            setWidgetsHR(getWidgets);
        }
    }, []);

    useEffect(() => {
        if (widgets) {
            setWidgetsHR(getWidgets);
        }
    }, [widgets, lock]);

    const getWidgets = widgets
        .map((widget: any) => {
            if ((widget.role && NySession.hasAnyRole(widget.role)) || !widget.role) {
                return createElement(widget);
            }
        })
        .filter(Boolean);

    const setDefaultWidget = () => {
        dispatch(dashboardInit());
        dispatch(setLayoutDefault(allWidgets));
        setWidgetsHR(allWidgets.map((widget: any) => createElement(widget)));
    };

    const setLockWidget = () => {
        dispatch(setLock({ widgets: widgetsHR, allWidgets: allWidgets }));
    };

    const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
        dispatch(layoutChange({ lastLayout: currentLayout, layouts: allLayouts }));
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <React.Fragment>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button block icon={<AppstoreOutlined />} onClick={setDefaultWidget}>
                            {geti18nText('dashboard.button.default')}
                        </Button>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button block icon={lock ? <UnlockOutlined /> : <LockOutlined />} onClick={setLockWidget}>
                            {geti18nText(lock ? 'dashboard.button.unlock' : 'dashboard.button.lock')}
                        </Button>
                    </div>
                </React.Fragment>
            </div>
        </Menu>
    );

    return (
        <div style={{ height: '86vh' }}>
            <Row gutter={24}>
                <Col span={24}>
                    <ResponsiveReactGridLayout
                        className="layout"
                        cols={{ lg: 12, md: 12, sm: 4, xs: 2, xxs: 2 }}
                        layouts={layouts}
                        rowHeight={47}
                        margin={[20, 20]}
                        onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
                    >
                        {widgetsHR && widgetsHR.length > 0 && widgetsHR}
                    </ResponsiveReactGridLayout>
                </Col>
            </Row>
            <Affix style={{ position: 'fixed', bottom: 50, right: pinedNotifications == 1 ? 350 : 50 }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['hover']} placement="bottomRight">
                    <Button
                        className="ny-fixed-widgets-button"
                        shape="circle"
                        size="large"
                        icon={<BlockOutlined style={{ fontSize: '20px' }} />}
                    />
                </Dropdown>
            </Affix>
        </div>
    );
};

export default HumanOverviewIndex;
