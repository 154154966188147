import AssetOrderWidget from '../../../dashboard/AssetOrderWidget';
import InventoryOrderWidget from '../../../dashboard/InventoryOrderWidget';
import MealsMenuWidget from '../../../dashboard/MealsMenuWidget';
import ServicesOrderWidget from '../../../dashboard/ServicesOrderWidget';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { NySession } from '@nybble/nyreact';
import { Col, Row } from 'antd';
import { RootState } from '../../../../rootReducer';
import { useLocation } from 'react-router-dom';

export interface IWidget {
    dataGrid: { [index: string]: string | number | boolean };
    elementKey: string;
    data: any;
    overviewIndex?: boolean;
    maxWidgetHeight?: string;
}

const widgetCatalogs: any = {
    AssetOrderWidget: AssetOrderWidget,
    InventoryOrderWidget: InventoryOrderWidget,
    ServicesOrderWidget: ServicesOrderWidget,
    MealsMenuWidget: MealsMenuWidget,
};

const WebshopOverviewIndex = () => {
    const location = useLocation();
    const { allWidgets } = useSelector((state: RootState) => state.dashboard);
    const [widgets, setWidgets] = useState<any>([]);

    useEffect(() => {
        const items = allWidgets.map((widget: any) => {
            if ((widget.role && NySession.hasAnyRole(widget.role)) || !widget.role) {
                return createElement(widget);
            }
        });

        setWidgets(items);
    }, []);

    const createElement = (obj: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
        data: any;
    }) => {
        let Tagname = widgetCatalogs[obj.component];
        let settings: IWidget = {
            dataGrid: obj.dataGrid,
            elementKey: obj.key,
            data: obj.data,
            maxWidgetHeight: '382px',
        };
        if (Tagname) {
            return (
                <Col span={4}>
                    {React.createElement(
                        'div',
                        {
                            id: 'container_' + obj.key,
                            key: obj.key,
                            'data-grid': obj.dataGrid,
                            className: `dashboard-widget-box`,
                        },
                        React.createElement(Tagname, settings)
                    )}
                </Col>
            );
        }
    };

    return <Row gutter={24}>{widgets && widgets.length > 0 && widgets}</Row>;
};

export default WebshopOverviewIndex;
