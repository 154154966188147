import { geti18nText, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import ItemIndex from '../../../../administration/views/item';

const checkQuantity = (rule: any, value: any, callback: any) => {
    if (value != undefined && ((value > 0 && value != '') || (value.id != undefined && value.id > 0))) {
        callback();
    } else {
        callback(geti18nText('app.default.required'));
    }
};

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxQuantity = undefined,
    warehouse = null,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);

    const itemFilter = () => {
        if (warehouse) {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'type', condition: 'equals', value: 8 },
                { field: 'warehouse.id', condition: 'equals', value: warehouse?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'type', condition: 'equals', value: 8 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
    };

    switch (inputType) {
        case 'item':
            inputNode = (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ITEM.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                    setDefaultFilterValue={itemFilter}
                    mustGetPopupContainer={false}
                    SearchPopupComponent={<ItemIndex defaultFilterValue={itemFilter()} />}
                />
            );
            break;
        case 'quantity':
            inputNode = <NyInputNumber leftToRight={numbersWriting} style={{ width: '100%' }} min={1} />;
            break;
        case 'releaseQuantity':
            inputNode = (
                <NyInputNumber leftToRight={numbersWriting} style={{ width: '100%' }} min={0} max={maxQuantity} />
            );
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing && inputType != 'releaseQuantity' ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            validator: checkQuantity,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : editing && inputType == 'releaseQuantity' ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
