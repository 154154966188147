import { NyDataEdit, NyDatePicker, NyInputNumber, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getDateFormat,
    getEnumArrayStatus,
    getEnumFormat,
    setDateFormat,
    setEnumFormat,
} from '../../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const EducationInstitutionEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('educationInstitution.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('educationInstitution.table.header') + ' - ' + dataForm.name);
        }

        if (dataForm.dateFrom) dataForm.dateFrom = setDateFormat(dataForm.dateFrom);

        if (dataForm.dateTo) dataForm.dateTo = setDateFormat(dataForm.dateTo);
        if (dataForm.institutionEducationType) {
            dataForm.institutionEducationType = setEnumFormat(
                'EDUCATION_INSTITUTION_EDUCATION_TYPE',
                dataForm.institutionEducationType
            );
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('educationInstitution.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EDUCATION_INSTITUTION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!HumanResourcesRights.canCreateEducationCodebooks()}
            hideActivationButtons={!HumanResourcesRights.canCreateEducationCodebooks()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                if (values.institutionEducationType)
                    values.institutionEducationType = getEnumFormat(values.institutionEducationType);

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        name={'name'}
                        label={geti18nText('educationInstitution.edit.name')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'organizer'} label={geti18nText('educationInstitution.edit.organizer')}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={'place'} label={geti18nText('educationInstitution.edit.place')}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={'institutionEducationType'}
                        label={geti18nText('educationInstitution.edit.institutionEducationType')}
                    >
                        <NySearchField
                            options={getEnumArrayStatus('EDUCATION_INSTITUTION_EDUCATION_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className=""
                            // onChange={onStatusChangeTravel}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={'dateFrom'} label={geti18nText('educationInstitution.edit.dateFrom')}>
                        <NyDatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'dateTo'} label={geti18nText('educationInstitution.edit.dateTo')}>
                        <NyDatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EducationInstitutionEdit;
