import { NyDataTable, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import useTableSettings from '../../../../hooks/useTableSettings';

const EmployeeAnalyzeTable = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    columns,
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
    setDefaultFilterValue,
    refresh,
    scroll,
    activeListSubTabs,
    activeListTabs,
    externalFiltersConfig,
    filterFormInstance,
}: any) => {
    const table = useTableSettings();
    return (
        <NyDataTable
            nyId={'human-employee-analyze-table' + activeListTabs + activeListSubTabs}
            url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.ANALYZE_LIST}
            addNewButtonText={geti18nText('employeeAnalyze.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            idOpenLink={false}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            hideButtons
            fetchWhenChange={refresh}
            externalFiltersConfig={externalFiltersConfig}
            filterFormInstance={filterFormInstance}
            excludeFromLocalStorage={true}
        />
    );
};

export default EmployeeAnalyzeTable;
