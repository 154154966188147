import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import RowAction from '../../../../components/row-action';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookPerson,
    getColumnSearchByCodebookSimple,
} from '../../../../utils/Filters';
import { GetWorkplaceStatusIcon, getEnumArrayStatus } from '../../../../utils/Utils';
import WorkplaceEdit from './edit';

const WorkplaceSearchIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    defaultFilterValue,
}: any) => {
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const table = useTableSettings();

    useHelper('human/codebooks/workplace');

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const setDefaultFilterValue = () => {
        return defaultFilterValue ? defaultFilterValue : table.setDefaultFilterValue();
    };

    const columns = [
        {
            title: geti18nText('workplace.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('workplace.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('workplace.table.column.vocation'),
            dataIndex: ['vocation', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('workplace.table.column.vocationDescription'),
            dataIndex: ['vocationDescription', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                'vocationDescription.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('workplace.table.column.sector'),
            dataIndex: ['dtoWorkplaceBusinessUnit', 'businessUnitTypeName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.BUSINESS_UNIT_TYPE.SEARCH,
                'dtoWorkplaceBusinessUnit.businessUnitTypeCode',
                'name',
                'name',
                { id: 'code', label: 'name', text: 'name' }
            ),
        },
        {
            title: geti18nText('workplace.table.column.portfolio'),
            dataIndex: "concat(person.firstName, ' ', person.lastName)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookPerson(),
            render: (text: any, record: any) => {
                if (record.person) {
                    return record.person.firstName + ' ' + record.person.lastName;
                }
            },
        },
        {
            title: geti18nText('workplace.table.column.status'),
            dataIndex: 'status',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(
                getEnumArrayStatus('WORKPLACE_STATUS'),
                'in',
                defaultFilterValue ? [enWorkplaceStatus.CONFIRMED].toString() : undefined
            ),
            render: (status: any, record: any) => {
                return GetWorkplaceStatusIcon(record.status);
            },
            defaultFilteredValue: setDefaultFilterValue(),
        },
        {
            title: geti18nText('workplace.table.column.active'),
            dataIndex: 'active',
            width: '6%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: setDefaultFilterValue(),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '80px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <RowAction
                            component="DownloadReport"
                            record={record}
                            reportUrl={CONSTANTS_REQ.REPORT.WORKPLACE}
                            reportCustomParms={{ workplaceId: record.id }}
                            reportSubreportType={'WORKPLACE'}
                            reportText={'workplace.button.generateReport.action'}
                            reportFileNameEnum={EnSubreportType.WORKPLACE}
                        />
                    );
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="workplaceSearchTable"
            url={CONSTANTS_REQ.WORKPLACE.LIST}
            addNewButtonText={geti18nText('workplace.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={WorkplaceEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={columns}
            hideNewButton={true}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.workplace')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.workplace')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.WORKPLACE_STATUS.' + value);
            },
        },
    ];
};

export default WorkplaceSearchIndex;
