import { AdministrationRights } from '../../rights/administrationRights';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { MobilizationRights } from '../../rights/mobilizationRights';
import { PayrollRights } from '../../rights/payrollRights';
import AttendanceBreakIndex from './views/attendance-break-type';
import AttendanceRegistrationIndex from './views/attendance-registration';
import WorkTypeIndex from './views/attendance-work-type';
import BusinessUnitIndex from './views/business-unit';
import BusinessUnitTypeIndex from './views/business-unit-type';
import CallingForPerformanceOfDutyIndex from './views/calling-for-performance-of-duty';
import CitizenshipIndex from './views/citizenship';
import ContractCategoryIndex from './views/contract/contract-category';
import ContractCategoryRightsIndex from './views/contract/contract-category-rights';
import ContractGlobalConstantsIndex from './views/contract/contract-global-constants';
import ContractHrIndex from './views/contract/contract-hr';
import ContractTypeIndex from './views/contract/contract-type';
import ContractTypeGroupIndex from './views/contract/contract-type-group';
import ContractsCTRIndex from './views/contract/contractsCTR';
import DefenseDepartmentIndex from './views/defense-department';
import DeviceIndex from './views/device';
import DocumentTypeIndex from './views/document-type';
import EmployeeEducationOverview from './views/education/employee-education-overview';
import EducationFormIndex from './views/education/form';
import EducationInstitutionIndex from './views/education/institution';
import ProgramCatalogIndex from './views/education/program-catalog';
import EducationPurposeIndex from './views/education/purpose';
import EducationalDomainIndex from './views/educational-domain';
import EducationalInstitutionIndex from './views/educational-institution';
import EducationalProgramIndex from './views/educational-program';
import EducationalTitleIndex from './views/educational-title';
import EmployeeIndex from './views/employee';
import EmployeeAnalyzeWomenIndex from './views/employee-analyze';
import EmployeeEvaluationNeedsPlanIndex from './views/employee-evaluation-needs-plan';
import EmployeeEvaluationCompetencyEvaluationIndex from './views/employee-evaluation/employee-evaluation-competency-evaluation';
import EmployeeEvaluationCompetencyTemplateIndex from './views/employee-evaluation/employee-evaluation-competency-template';
import EmployeeEvaluationCompetencyTypeIndex from './views/employee-evaluation/employee-evaluation-competency-type';
import EmployeeEvaluationIndex from './views/employee-evaluation/employee-evaluation-overview';
import EmployeeGroupIndex from './views/employee-group';
import EmployeeGroupEdit from './views/employee-group/edit';
import EmployeeLeaveIndex from './views/employee-leave';
import EmployeeLeaveOverView from './views/employee-leave-overview';
import EmployeeMilitaryRecords from './views/employee-military-records';
import EmployeeWorkObligationIndex from './views/employee-mobilization';
import EmployeeScheduleIndex from './views/employee-schedule';
import EmployeeSickLeaveIndex from './views/employee-sick-leave';
import EmployeeStatusIndex from './views/employee-status';
import EmployeeTypeIndex from './views/employee-type';
import EmployeePersonalEdit from './views/employee/EmployeePersonalEdit';
import EmployeeEdit from './views/employee/edit';
import EmploymentTypeIndex from './views/employment-type';
import HumanOverviewIndex from './views/human';
import HumanAccessRightIndex from './views/human-access-right';
import HumanAccessRightAttendanceIndex from './views/human-access-right-attendance';
import HumanNotificationSettings from './views/human-notification-settings';
import JoppdIndex from './views/joppd';
import JoppdEdit from './views/joppd/edit';
import LocationIndex from './views/location';
import MobilizationLocationIndex from './views/location-mobilization';
import MilitaryNotObligeesIndex from './views/military-not-obligees';
import MilitaryWithoutScheduledIndex from './views/military-not-scheduled';
import MilitaryObligeesIndex from './views/military-obligees';
import MobilizationSettingsIndex from './views/mobilization-settings';
import NationalityIndex from './views/nationality';
import ContractIndex from './views/payment-calculation-contract';
import ContractEdit from './views/payment-calculation-contract/edit';
import IncomeIndex from './views/payment-calculation-income';
import IncomeEdit from './views/payment-calculation-income/edit';
import RoyaltiesIndex from './views/payment-calculation-royalties';
import RoyaltiesEdit from './views/payment-calculation-royalties/edit';
import SecondIncomeIndex from './views/payment-calculation-second-income';
import SecondIncomeEdit from './views/payment-calculation-second-income/edit';
import PayrollAdditionIndex from './views/payroll-addition';
import PayrollBrutIndex from './views/payroll-brut';
import PayrollExpenseIndex from './views/payroll-expense';
import PayrollInsuranceIndex from './views/payroll-insurance';
import PayrollPointValueIndex from './views/payroll-point-value';
import PayrollTaxIndex from './views/payroll-tax';
import PayrollTaxDeductionIndex from './views/payroll-tax-deduction';
import PayrollTaxReductionIndex from './views/payroll-tax-reduction';
import PointAdditionDecisionIndex from './views/point-addition-decision';
import PortfolioIndex from './views/portfolio';
import ProfessionalQualificationIndex from './views/professional-qualification';
import ProfessionalSpecialtyIndex from './views/professional-specialty';
import QualificationLevelIndex from './views/qualification-level';
import ReserveRankIndex from './views/reserve-rank';
import RewardTypeIndex from './views/reward-type';
import RoomIndex from './views/room';
import ScheduleSuggestionsIndex from './views/schedule-suggestions';
import SecurityCertificateLevelIndex from './views/security-certificate-level';
import ShiftIndex from './views/shift';
import SickLeaveIndex from './views/sick-leave';
import SpecialDutyInMobilizationIndex from './views/special-duty-in-mobilization';
import SystematizationDocumentsIndex from './views/systematization-documents';
import SystematiozationOfJobsIndex from './views/systematization-of-jobs';
import TownshipTaxIndex from './views/township-tax';
import VocationIndex from './views/vocation';
import VocationDescriptionIndex from './views/vocation-description';
import VocationGroupIndex from './views/vocation-group';
import WarBusinessUnitIndex from './views/war-business-unit';
import WorkObligationIndex from './views/work-obligation-call';
import WorkObligationDocumentsIndex from './views/work-obligation-documents';
import WorkplaceIndex from './views/workplace';

export const HumanRoutes: any = [
    {
        path: '/human',
        exact: true,
        component: HumanOverviewIndex,
        role: HumanResourcesRights.canViewHumanOverviewModule(),
    },
    {
        path: '/human/employee',
        exact: true,
        component: EmployeeIndex,
        role: HumanResourcesRights.canViewEmployeeOnlyRoutes(),
    },
    {
        path: '/human/employee/:id',
        exact: true,
        component: EmployeeEdit,
        role: HumanResourcesRights.canViewHumanResRoutes(),
    },
    {
        path: '/human/employeePersonal',
        exact: true,
        component: EmployeePersonalEdit,
        role: HumanResourcesRights.canViewHumanResourcesUser(),
    },
    /*  {
        path: '/human/attendance',
        exact: true,
        component: AttendanceIndex,
        role: HumanResourcesRights.canViewAttendanceRoutes(),
    }, */
    {
        path: '/human/attendance-registration',
        exact: true,
        component: AttendanceRegistrationIndex,
        role: HumanResourcesRights.canViewAttendanceMenu(),
    },
    {
        path: '/human/employeeLeave',
        exact: true,
        component: EmployeeLeaveIndex,
        role: HumanResourcesRights.isAdminMenu(),
    },
    {
        path: '/human/employeeSickLeave',
        exact: true,
        component: EmployeeSickLeaveIndex,
        role: HumanResourcesRights.isAdminMenu(),
    },
    {
        path: '/human/employee-leave-overview',
        exact: true,
        component: EmployeeLeaveOverView,
        role: HumanResourcesRights.canViewMenuEmployeeLeave(),
    },
    {
        path: '/human/codebooks/payroll/payrollInsurance',
        exact: true,
        component: PayrollInsuranceIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollBrut',
        exact: true,
        component: PayrollBrutIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/pointAdditionDecision',
        exact: true,
        component: PointAdditionDecisionIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollTaxDeduction',
        exact: true,
        component: PayrollTaxDeductionIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollTax',
        exact: true,
        component: PayrollTaxIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollAddition',
        exact: true,
        component: PayrollAdditionIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/township-tax',
        exact: true,
        component: TownshipTaxIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollExpense',
        exact: true,
        component: PayrollExpenseIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollPointValue',
        exact: true,
        component: PayrollPointValueIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/codebooks/payroll/payrollTaxReduction',
        exact: true,
        component: PayrollTaxReductionIndex,
        role: PayrollRights.canViewPayrollRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationIncome',
        exact: true,
        component: IncomeIndex,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationIncome/:id',
        exact: true,
        component: IncomeEdit,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationSecondIncome',
        exact: true,
        component: SecondIncomeIndex,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationSecondIncome/:id',
        exact: true,
        component: SecondIncomeEdit,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationContract',
        exact: true,
        component: ContractIndex,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationContract/:id',
        exact: true,
        component: ContractEdit,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationRoyalties',
        exact: true,
        component: RoyaltiesIndex,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/calculation/paymentCalculationRoyalties/:id',
        exact: true,
        component: RoyaltiesEdit,
        role: PayrollRights.canViewCalculationRoutes(),
    },
    {
        path: '/human/payroll/joppd',
        exact: true,
        component: JoppdIndex,
        role: PayrollRights.canViewPayrollJoppdRoutes(),
    },
    {
        path: '/human/payroll/joppd/:id',
        exact: true,
        component: JoppdEdit,
        role: PayrollRights.canViewPayrollJoppdRoutes(),
    },
    {
        path: '/human/work-obligation/war-systematization/war-business-unit',
        exact: true,
        component: WarBusinessUnitIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/war-systematization/systematization-of-jobs',
        exact: true,
        component: SystematiozationOfJobsIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/war-systematization/systematization-documents',
        exact: true,
        component: SystematizationDocumentsIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/employee-mobilization',
        exact: true,
        component: EmployeeWorkObligationIndex,
        role: MobilizationRights.isMobilizationViewMenu(),
    },
    {
        path: '/human/work-obligation/schedule-suggestions',
        exact: true,
        component: ScheduleSuggestionsIndex,
        role: MobilizationRights.isMobilizationViewHumanMenu(),
    },
    // {
    //     path: '/human/work-obligation/schedule-approvals',
    //     exact: true,
    //     component: ScheduleApprovalsIndex,
    //     role: MobilizationRights.isMobilizationViewHumanMenu(),
    // },
    {
        path: '/human/work-obligation/records/military-obligees',
        exact: true,
        component: MilitaryObligeesIndex,
        role: MobilizationRights.isMobilizationViewHumanMenu(),
    },
    {
        path: '/human/work-obligation/records/military-not-obligees',
        exact: true,
        component: MilitaryNotObligeesIndex,
        role: MobilizationRights.isMobilizationViewHumanMenu(),
    },
    {
        path: '/human/work-obligation/records/military-without-schedule',
        exact: true,
        component: MilitaryWithoutScheduledIndex,
        role: MobilizationRights.isMobilizationViewHumanMenu(),
    },
    {
        path: '/human/work-obligation/employee-analyze',
        exact: true,
        component: EmployeeAnalyzeWomenIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/call/work-obligation-call',
        exact: true,
        component: WorkObligationIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/call/special-duty-in-mobilization',
        exact: true,
        component: SpecialDutyInMobilizationIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/call/employee-schedule',
        exact: true,
        component: EmployeeScheduleIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/call/calling-for-performance-of-duty',
        exact: true,
        component: CallingForPerformanceOfDutyIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/work-obligation/employee-military-records',
        exact: true,
        component: EmployeeMilitaryRecords,
        role: MobilizationRights.isMobilizationViewExecutorMenu(),
    },
    {
        path: '/human/work-obligation/call/employee-military-records',
        exact: true,
        component: EmployeeMilitaryRecords,
        role: MobilizationRights.isMobilizationViewExecutorMenu(),
    },

    {
        path: '/human/work-obligation/call/work-obligation-documents',
        exact: true,
        component: WorkObligationDocumentsIndex,
        role: MobilizationRights.isMobilizationViewUserMenu(),
    },
    {
        path: '/human/codebooks/work-obligation/reserve-rank',
        exact: true,
        component: ReserveRankIndex,
        role: MobilizationRights.isMobilizationViewMenu(),
    },
    {
        path: '/human/codebooks/work-obligation/professional-specialty',
        exact: true,
        component: ProfessionalSpecialtyIndex,
        role: MobilizationRights.isMobilizationViewMenu(),
    },
    {
        path: '/human/codebooks/work-obligation/defense-department',
        exact: true,
        component: DefenseDepartmentIndex,
        role: MobilizationRights.isMobilizationViewMenu(),
    },
    {
        path: '/human/codebooks/work-obligation/mobilization-location',
        exact: true,
        component: MobilizationLocationIndex,
        role: MobilizationRights.isMobilizationViewMenu(),
    },
    {
        path: '/human/codebooks/work-obligation/security-certificate-level',
        exact: true,
        component: SecurityCertificateLevelIndex,
        role: MobilizationRights.isMobilizationViewMenu(),
    },
    {
        path: '/human/work-obligation/mobilization-settings',
        exact: true,
        component: MobilizationSettingsIndex,
        role: MobilizationRights.isMobilizationViewSettingsMenu(),
    },
    {
        path: '/human/settings/human-notification-settings',
        exact: true,
        component: HumanNotificationSettings,
        role: HumanResourcesRights.canViewMenuSettings(),
    },
    {
        path: '/human/settings/human-access-right',
        exact: true,
        component: HumanAccessRightIndex,
        role: HumanResourcesRights.canViewMenuSettings(),
    },
    {
        path: '/human/settings/human-access-right-attendance',
        exact: true,
        component: HumanAccessRightAttendanceIndex,
        role: HumanResourcesRights.canViewMenuSettingsAttendance(),
    },
    {
        path: '/human/codebooks/human/portfolio',
        exact: true,
        component: PortfolioIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/employeeGroup',
        exact: true,
        component: EmployeeGroupIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/employeeGroup/:id',
        exact: true,
        component: EmployeeGroupEdit,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/vocation',
        exact: true,
        component: VocationIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/vocation-group',
        exact: true,
        component: VocationGroupIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/vocation-description',
        exact: true,
        component: VocationDescriptionIndex,
        role: HumanResourcesRights.canViewHRCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/shift',
        exact: true,
        component: ShiftIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/room',
        exact: true,
        component: RoomIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/business-unit',
        exact: true,
        component: BusinessUnitIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/business-unit-type',
        exact: true,
        component: BusinessUnitTypeIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/sickLeave',
        exact: true,
        component: SickLeaveIndex,
        role: HumanResourcesRights.canViewCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/employeeEvaluationNeedsPlan',
        exact: true,
        component: EmployeeEvaluationNeedsPlanIndex,
        role: HumanResourcesRights.canViewEvaluationCodebooks(),
    },
    {
        path: '/human/codebooks/attendance/location',
        exact: true,
        component: LocationIndex,
        role: HumanResourcesRights.canViewMenuAttendanceCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/attendance/attendance-break-type',
        exact: true,
        component: AttendanceBreakIndex,
        role: HumanResourcesRights.canViewMenuAttendanceCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/attendance/device',
        exact: true,
        component: DeviceIndex,
        role: HumanResourcesRights.canViewMenuAttendanceCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/attendance/work-type',
        exact: true,
        component: WorkTypeIndex,
        role: HumanResourcesRights.canViewMenuAttendanceCodebooksRoutes(),
    },
    {
        path: '/human/codebooks/human/workplace',
        exact: true,
        component: WorkplaceIndex,
        role: HumanResourcesRights.canViewMenuCodebooksWorkplace(),
    },
    {
        path: '/human/codebooks/human/employee-status',
        exact: true,
        component: EmployeeStatusIndex,
        role: HumanResourcesRights.canViewMenuCodebooks(),
    },
    {
        path: '/human/codebooks/human/professional-qualification',
        exact: true,
        component: ProfessionalQualificationIndex,
        role: HumanResourcesRights.canViewMenuCodebooks(),
    },
    {
        path: '/human/codebooks/human/qualification-level',
        exact: true,
        component: QualificationLevelIndex,
        role: HumanResourcesRights.canViewMenuCodebooks(),
    },
    {
        path: '/human/codebooks/human/educational-program',
        exact: true,
        component: EducationalProgramIndex,
        role: HumanResourcesRights.canViewMenuCodebooks(),
    },
    {
        path: '/human/codebooks/human/employment-type',
        exact: true,
        component: EmploymentTypeIndex,
        role: HumanResourcesRights.canViewMenuCodebooks(),
    },
    {
        path: '/human/codebooks/human/employee-type',
        exact: true,
        component: EmployeeTypeIndex,
        role: HumanResourcesRights.canViewMenuCodebooks(),
    },
    {
        path: '/human/codebooks/human/citizenship',
        exact: true,
        component: CitizenshipIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/nationality',
        exact: true,
        component: NationalityIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/educationalTitle',
        exact: true,
        component: EducationalTitleIndex,
        role: HumanResourcesRights.canViewCodebooksEditRoutes(),
    },
    {
        path: '/human/codebooks/human/educational-domain',
        exact: true,
        component: EducationalDomainIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/educational-institution',
        exact: true,
        component: EducationalInstitutionIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/reward-type',
        exact: true,
        component: RewardTypeIndex,
        role: HumanResourcesRights.canViewEmployeeMenuAdmin(),
    },
    {
        path: '/human/codebooks/human/payrollBrut',
        exact: true,
        component: PayrollBrutIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/pointAdditionDecision',
        exact: true,
        component: PointAdditionDecisionIndex,
        role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    },
    {
        path: '/human/codebooks/human/document-type',
        exact: true,
        component: DocumentTypeIndex,
        role: HumanResourcesRights.canViewEmployeeMenuAdmin(),
    },
    // {
    //     path: '/human/educational-sub-program',
    //     exact: true,
    //     component: EducationalSubProgramIndex,
    //     role: HumanResourcesRights.canViewCodebooksOnlyRoutes(),
    // },
    {
        path: '/human/codebooks/contracts/contract-category',
        exact: true,
        component: ContractCategoryIndex,
        role: HumanResourcesRights.canViewContractsMenuAdmin(),
    },
    {
        path: '/human/contracts/contract-category-rights',
        exact: true,
        component: ContractCategoryRightsIndex,
        role: HumanResourcesRights.canViewContractsMenuAdmin(),
    },
    {
        path: '/human/codebooks/contracts/contract-type-group',
        exact: true,
        component: ContractTypeGroupIndex,
        role: HumanResourcesRights.canViewContractsMenuAll(),
    },
    {
        path: '/human/codebooks/contracts/contract-global-constants',
        exact: true,
        component: ContractGlobalConstantsIndex,
        role: HumanResourcesRights.canViewContractsMenuContract(),
    },
    {
        path: '/human/contracts/contractCTR',
        exact: true,
        component: ContractsCTRIndex,
        role: HumanResourcesRights.canViewContractsMenuAll(),
    },
    {
        path: '/human/contracts/contract-type',
        exact: true,
        component: ContractTypeIndex,
        role: HumanResourcesRights.canViewContractsMenuAll(),
    },
    {
        path: '/human/codebooks/contracts/contract-hr',
        exact: true,
        component: ContractHrIndex,
        role: AdministrationRights.isAdminMenu(),
    },
    {
        path: '/human/codebooks/education/education-form',
        exact: true,
        component: EducationFormIndex,
        role: HumanResourcesRights.canViewEducationCodebooks(),
    },
    {
        path: '/human/codebooks/education/education-institution',
        exact: true,
        component: EducationInstitutionIndex,
        role: HumanResourcesRights.canViewEducationCodebooks(),
    },
    {
        path: '/human/codebooks/education/education-purpose',
        exact: true,
        component: EducationPurposeIndex,
        role: HumanResourcesRights.canViewEducationCodebooks(),
    },
    {
        path: '/human/codebooks/education/program-catalog',
        exact: true,
        component: ProgramCatalogIndex,
        role: HumanResourcesRights.canViewEducationCodebooks(),
    },
    {
        path: '/human/employee-education-overview',
        exact: true,
        component: EmployeeEducationOverview,
        role: HumanResourcesRights.canViewEducationMenu(),
    },
    {
        path: '/human/codebooks/evaluation/competency-template',
        exact: true,
        component: EmployeeEvaluationCompetencyTemplateIndex,
        role: HumanResourcesRights.canViewEvaluationCodebooks(),
    },
    {
        path: '/human/employee-evaluation',
        exact: true,
        component: EmployeeEvaluationIndex,
        role: HumanResourcesRights.canViewEvaluation(),
    },
    {
        path: '/human/codebooks/evaluation/competency-type',
        exact: true,
        component: EmployeeEvaluationCompetencyTypeIndex,
        role: HumanResourcesRights.canViewEvaluationCodebooks(),
    },
    {
        path: '/human/codebooks/evaluation/competency-evaluation',
        exact: true,
        component: EmployeeEvaluationCompetencyEvaluationIndex,
        role: HumanResourcesRights.canViewEvaluationCodebooks(),
    },
];
