import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Alert, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../utils/Constants';
import { getDateFormat, numberFormat } from '../../utils/Utils';
import TravelWarrantExpenseEdit from './edit';
import useTableSettings from '../../hooks/useTableSettings';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import { wageIcon, wageIconSmall } from '../travel-warrant-icons';

const TravelWarrantExpensesIndex = ({
    isAdmin = false,
    isAdminForm = false,
    startDate,
    refreshFiles,
    setRefreshFiles,
    mandatoryFile,
    setMandatoryFile,
    id,
    readonly = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    showPreviewFirstFile,
    canEditStatusAndCalculationDatePaid = false,
}: any) => {
    const table = useTableSettings();
    const [refresh, setRefresh] = useState(0);
    const [refreshId, setRefreshId] = useState(0);
    const paymentInDeclaredCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY');

    const setDefaultFilterValueAppSettingsFiles = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' },
            { field: 'setting_key', condition: 'equals', value: 'TRAVEL_WARRANT_MANDATORY_PURPOSE_AND_ATTACHMENT' },
        ];
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travelWarrant.id', condition: 'equals', value: id },
        ];
    };

    useEffect(() => {
        setRefresh((refresh) => refresh + 1);
        getAppSettingsFiles();
    }, [refreshId]);

    const getAppSettingsFiles = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettingsFiles())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    const element = result.data[0];
                    const value =
                        element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
                    if (value == 1) {
                        setMandatoryFile(true);
                    } else {
                        setMandatoryFile(false);
                    }
                }
            }
        });
    };

    const columns: any = [
        ...[
            {
                title: geti18nText('travelWarrantExpense.table.column.travelExpenseName'),
                dataIndex: ['travelExpense', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record.travelExpense) {
                        if (record.taxable) {
                            return (
                                <>
                                    {record.travelExpense.name} {wageIconSmall}
                                </>
                            );
                        } else {
                            return record.travelExpense.name;
                        }
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.expenseDate'),
                dataIndex: 'expenseDate',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (expenseDate: any, record: any) => {
                    if (record.expenseDate) {
                        return getDateFormat(record.expenseDate, 'DD.MM.YYYY');
                    }
                },
            },
        ],
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record.currency.isoCode;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (exchangeRate: any) => {
                          return exchangeRate ? (
                              <div style={{ float: 'right' }}>{numberFormat(exchangeRate, 6)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      width: '92px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amount: any) => {
                          return amount ? <div style={{ float: 'right' }}>{numberFormat(amount)}</div> : '';
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record.currency.isoCode;
                          }
                      },
                  },
              ]),
        ...[
            {
                title: geti18nText('travelWarrantExpense.table.column.expensePaymentMethod'),
                dataIndex: ['travelExpensePaymentMethod', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record.travelExpensePaymentMethod) {
                        return record.travelExpensePaymentMethod.name;
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.description'),
                dataIndex: 'description',
                width: '30%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record?.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '360px' }}
                                title={record.description}
                            >
                                <div style={{ maxWidth: '450px' }}>
                                    <p
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginTop: '-0.6rem',
                                            marginBottom: '-1rem',
                                        }}
                                    >
                                        {record.description}
                                    </p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
        ],
    ];

    const adminColumns: any = [
        ...[
            {
                title: geti18nText('travelWarrantExpense.table.column.travelExpenseName'),
                dataIndex: ['travelExpense', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record.travelExpense) {
                        if (record.taxable) {
                            return (
                                <>
                                    {record.travelExpense.name} {wageIconSmall}
                                </>
                            );
                        } else {
                            return record.travelExpense.name;
                        }
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.expenseDate'),
                dataIndex: 'expenseDate',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (expenseDate: any, record: any) => {
                    if (record.expenseDate) {
                        return getDateFormat(record.expenseDate, 'DD.MM.YYYY');
                    }
                },
            },
        ],
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record.currency.isoCode;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (exchangeRate: any) => {
                          return exchangeRate ? (
                              <div style={{ float: 'right' }}>{numberFormat(exchangeRate, 6)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      width: '92px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amount: any) => {
                          return amount ? <div style={{ float: 'right' }}>{numberFormat(amount)}</div> : '';
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record.currency.isoCode;
                          }
                      },
                  },
              ]),
        ...[
            {
                title: geti18nText('travelWarrantExpense.table.column.expensePaymentMethod'),
                dataIndex: ['travelExpensePaymentMethod', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record.travelExpensePaymentMethod) {
                        return record.travelExpensePaymentMethod.name;
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.invoiceNr'),
                dataIndex: 'invoiceNr',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.description'),
                dataIndex: 'description',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record?.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '220px' }}
                                title={record.description}
                            >
                                <div style={{ maxWidth: '155px' }}>
                                    <p
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginTop: '-0.4rem',
                                            marginBottom: '-1rem',
                                        }}
                                    >
                                        {record.description}
                                    </p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.active'),
                dataIndex: 'active',
                width: '10%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
            },
        ],
    ];

    return (
        <>
            <Alert
                message={
                    geti18nText('travelWarrantExpense.table.info') +
                    ' ' +
                    geti18nText('travelWarrantExpense.table.info.picture') +
                    ' ' +
                    geti18nText('travelWarrantExpense.table.info.plane')
                }
                type="info"
                showIcon
                style={{ margin: '5px' }}
            />
            <div style={{ marginBottom: '-24px' }}>
                {wageIcon}
                <b>{geti18nText('travelWarrantRecapitulation.table.taxable.wage').split('**')}</b>
            </div>
            <NyDataTable
                nyId="travel-warrent-expenses"
                url={CONSTANTS_REQ.TRAVEL_WARRANT_EXPENSE.LIST}
                addNewButtonText={geti18nText('travelWarrantExpense.table.add')}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                fetchWhenChange={refresh}
                setDefaultPageSize={table.setDefaultPageSize()}
                modalComponent={TravelWarrantExpenseEdit}
                editProps={{
                    refreshFiles: refreshFiles,
                    setRefreshFiles: setRefreshFiles,
                    mandatoryFile,
                    isAdminForm: isAdminForm,
                    disabled: readonly && !canEditStatusAndCalculationDatePaid,
                    showPreviewFirstFile: showPreviewFirstFile,
                    canEditStatusAndCalculationDatePaid: canEditStatusAndCalculationDatePaid,
                }}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'asc' }}
                scroll={scroll}
                columns={isAdmin ? adminColumns : columns}
                shortcuts={true}
                readonly={readonly}
                hideButtons={
                    (readonly && !isAdmin) || (readonly && !canEditStatusAndCalculationDatePaid && isAdminForm)
                }
                nyTestId="travel-expense-table"
                addedData={{ isAdmin: isAdmin, startDate: startDate, id: id }}
                onSaveAndGetID={(returnedId: any) => {
                    setRefreshId(returnedId);
                }}
            />
        </>
    );
};

export default TravelWarrantExpensesIndex;
