import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import RewardTypeIndex from '.';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import RewardTypeEdit from './edit';

const RewardTypeSearch = ({
    label = geti18nText('employee.rewards.edit.rewardType'),
    name = 'rewardType',
    required = false,
    disabled = false,
    onChange = null,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.REWARD_TYPE.SEARCH}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                searchBy="name,code"
                SearchPopupComponent={<RewardTypeIndex />}
                AddNewModalComponent={canCreate() ? RewardTypeEdit : null}
                nyTestId="rewardType"
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                disabled={disabled}
                itemName={[['code'], ['name']]}
                customItemNameLabel={'name (code)'}
                renderOption={(option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`}
                onChange={onChange}
            />
        </Form.Item>
    );
};

export default RewardTypeSearch;
