import { NyDataTable, geti18nText } from '@nybble/nyreact';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import RowAction from '../../../../components/row-action';

const JobsTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [
            { field: 'status', condition: 'in', value: [enWorkplaceStatus.CONFIRMED].toString() },
            { field: 'active', condition: 'equals_bool', value: 1 },
        ];
    };

    const columns = [
        {
            title: geti18nText('assetReturn.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                        record.businessUnit.name
                    );
                }
            },
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.vocation'),
            dataIndex: ['vocation', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
            dataIndex: ['vocationDescription', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                'vocationDescription.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '80px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <RowAction
                            component="DownloadReport"
                            record={record}
                            reportUrl={CONSTANTS_REQ.REPORT.WORKPLACE}
                            reportCustomParms={{ workplaceId: record.id }}
                            reportSubreportType={'WORKPLACE'}
                            reportText={'workplace.button.generateReport.action'}
                            reportFileNameEnum={EnSubreportType.WORKPLACE}
                        />
                    );
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-workplace-table"
            url={CONSTANTS_REQ.WORKPLACE.LIST}
            buttonsClassName="buttons-sticky"
            // showRecordModal={true}
            // modalComponent={SectionEdit}
            readonly
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideButtons
            shortcuts={true}
            // exportCSV={canExportCSV()}
            // CSVFileName={geti18nText('menu.work.obligation.reserve.rank')}
            // colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export default JobsTable;
