import { CloseCircleTwoTone, DeleteOutlined, DeleteTwoTone, EditTwoTone, SaveTwoTone } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyModalConfirm, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NyNoteModal from '../../../../components/note-modal';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { RootState } from '../../../../rootReducer';
import { setItemNote } from '../../../../slices/webshopSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    userIsNotificationRecipient,
} from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';
import RoomSearch from '../../../human/views/room/search';
import EditableCell from './EditableCell';

const WebshopItemEdit = (props: any) => {
    const dispatch = useDispatch();
    const { webshopItemNote } = useSelector((state: RootState) => state.webshop);
    const [editHeader, setEditHeader] = useState(geti18nText('webshopItem.edit.new'));
    const [loading, setLoading] = useState(false);
    const [editingKey, setEditingKey] = useState<any>('');
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const isEditing = (record: any) => record.id === editingKey;
    const [form] = Form.useForm();
    const [itemForm] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);

    const canView = () => {
        return (
            HumanResourcesRights.canViewEmployeeRole() ||
            InventoryRights.canCreateOrder() ||
            userIsNotificationRecipient(employee, 7)
        );
    };

    const disabledEmployee = () => {
        return !isCreate || !canView();
    };

    const onModalClose = () => {
        dispatch(setItemNote(form.getFieldValue('note')));
        form.resetFields();
        itemForm.resetFields();
        setEditingKey('');
        setNote(undefined);
        setEditHeader(geti18nText('webshopItem.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (props.employeeId !== undefined) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + props.employeeId, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            form.setFieldsValue({
                                employee: getEmployeeSelectOption(result.data),
                                room: result.data.room,
                            });
                        }
                    }
                }
            );
        }

        if (webshopItemNote) {
            form.setFieldsValue({ note: webshopItemNote });
            setNote(webshopItemNote);
        }
    };

    const cancel = (record: any) => {
        setEditingKey('');
    };

    const removeFromBasket = () => {
        props.setItemsLength(0);
        props.setItems([]);
        removeNote();
    };

    const removeNote = () => {
        setNote(null);
        dispatch(setItemNote(undefined));
        form.setFieldsValue({ note: null });
    };

    const deleteRow = (id: any) => {
        if (id) {
            const index = props.items.findIndex((item: any) => id === item.id);
            if (index > -1) {
                props.setItemsLength((itemsLength: any) => itemsLength - props.items[index].quantity);
            }
            props.setItems(props.items.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await itemForm.validateFields();
            const newData = [...props.items];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                let oldQty = item.quantity;
                newData.splice(index, 1, { ...item, ...row });
                props.setItems(newData);
                setEditingKey('');
                props.setItemsLength((itemsLength: any) => itemsLength - oldQty + row.quantity);
            } else {
                newData.push(row);
                props.setItems(newData);
                setEditingKey('');
                props.setItemsLength((itemsLength: any) => itemsLength + row.quantity);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)} disabled={!isCreate}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <NyModalConfirm
                            title={geti18nText('app.default.cancel.confirm')}
                            onConfirm={() => {
                                cancel(record);
                            }}
                        >
                            <Button type="link" disabled={!isCreate}>
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </NyModalConfirm>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {isCreate && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={editingKey !== '' || !isCreate} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <NyModalConfirm
                                title={geti18nText('inventory.order.delete.new.confirm')}
                                onConfirm={() => {
                                    deleteRow(record && record.id);
                                }}
                            >
                                <Button type="link" disabled={!isCreate}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </NyModalConfirm>
                        </Tooltip>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        {
            title: geti18nText('webshopItem.edit.code'),
            dataIndex: 'code',
            editable: false,
        },
        {
            title: geti18nText('webshopItem.edit.name'),
            dataIndex: 'name',
            editable: false,
            inputType: 'name',
        },
        {
            title: geti18nText('webshopItem.edit.measureUnit'),
            dataIndex: ['measureUnit', 'abbreviation'],
            editable: false,
        },
        {
            title: geti18nText('webshopItem.edit.quantity'),
            dataIndex: 'quantity',
            editable: true,
            inputType: 'quantity',
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            render: (text: any, record: any) => {
                if (props.items.length >= 1) {
                    const editable = isEditing(record);
                    return editable ? (
                        <ActionEditable text={text} record={record} />
                    ) : (
                        <ActionNotEditable text={text} record={record} />
                    );
                }
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: itemForm,
                };
            },
        };
    });

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };
        itemForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px' }}>
                <NyNoteModal note={note} />
            </div>
            {props.items.length > 0 && (
                <NyModalConfirm
                    title={geti18nText('app.default.destructive.confirm')}
                    onConfirm={() => {
                        removeFromBasket();
                    }}
                >
                    <Button danger icon={<DeleteOutlined />} style={{ marginRight: '10px' }}>
                        {geti18nText('app.default.webshop.deleteFromBasket')}
                    </Button>
                </NyModalConfirm>
            )}
        </React.Fragment>
    );

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            props.setEmployeeId(value.id);
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            if (result.data.room) {
                                form.setFieldsValue({ room: result.data.room });
                            }
                        }
                    }
                }
            );
        } else {
            form.setFieldsValue({ employee: undefined });
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.INVENTORY_ORDER.EDIT}
            width={900}
            hideActivationButtons={!isCreate}
            hideSubmitButton={!isCreate || !(props.items.length > 0) || editingKey !== ''}
            form={form}
            setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={false}
            customFooterContent={getCustomFooterContent}
            normalize={(values: any) => {
                values.employee = getSearchFormat(values.employee);
                values.date = getDateFormat(moment());

                if (values.status) {
                    values.status = 1;
                } else {
                    delete values.status;
                }

                const itemsBasket: any = [];
                if (props.items != undefined) {
                    props.items.map((item: any) => {
                        let obj: any = {};
                        obj.item = { id: item.id };
                        obj.quantity = item.quantity;
                        itemsBasket.push(obj);
                    });
                }

                values.inventoryOrderItem = itemsBasket;

                if (props.orderViewId != null) values.orderView = { id: props.orderViewId };
                return values;
            }}
            onSaveAndGetID={removeNote}
            submitButtonText={geti18nText('webshopItem.edit.save')}
            showSubmitButtonPopconfirm={true}
            submitButtonPopconfirmTitle={geti18nText('webshopItem.edit.save.title')}
        >
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('webshopItem.edit.employee')}
                        name="employee"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="webshop-item-edit" />}
                            disabled={disabledEmployee()}
                            onChange={onEmployeeChange}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <RoomSearch
                        disabled={!isCreate}
                        label={geti18nText('webshopItem.edit.room')}
                        map={{ id: 'id', label: 'name' }}
                        name="room"
                        required={true}
                        searchBy="name"
                        onChange={(value: any) => {
                            if (value?.id === -1) {
                                form.setFieldsValue({ room: undefined });
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={'status'}
                        valuePropName={'checked'}
                        initialValue={true}
                        style={{ marginBottom: '0px' }}
                    >
                        <Checkbox>{geti18nText('webshopItem.edit.status')}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Divider>{geti18nText('inventoryOrder.divider.1')}</Divider>
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={itemForm} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={props.items}
                            scroll={{ y: 680, x: 800 }}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                        />
                    </Form>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default WebshopItemEdit;
