import { useEffect, useState } from 'react';
import { Row, Col, Modal, Table } from 'antd';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const EmployeeInventoryOrderEdit = ({ item, setItem, isModalVisible, setIsModalVisible }: any) => {
    const [items, setItems] = useState<any>(undefined);

    useEffect(() => {
        if (item) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.INVENTORY_ORDER.EDIT + '/' + item.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data.inventoryOrderItem) {
                            setItems(result.data.inventoryOrderItem);
                        }
                    }
                }
            );
        }
    }, [item]);

    const columns = [
        {
            title: geti18nText('webshopItem.edit.name'),
            dataIndex: 'item',
            editable: true,
            inputType: 'item',
            render: (text: any, record: any) => {
                if (record.item != undefined && record.item.name != undefined) {
                    return record.item.name;
                }
            },
        },
        {
            title: geti18nText('webshopItem.edit.quantity'),
            dataIndex: 'quantity',
            editable: true,
            inputType: 'quantity',
        },
    ];

    return (
        <>
            {isModalVisible && (
                <Modal
                    title={geti18nText('item.table.header')}
                    visible={isModalVisible}
                    maskClosable={false}
                    closable={true}
                    width={350}
                    onCancel={() => {
                        setIsModalVisible(false);
                        setItem(undefined);
                        setItems(undefined);
                    }}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Table size={'small'} dataSource={items} columns={columns} pagination={false} />
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default EmployeeInventoryOrderEdit;
