import { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Input, Select } from 'antd';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import moment from 'moment';
import { getEnumArray } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const PayrollTaxDeductionEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('payrollTaxDeduction.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [baseValue, setBaseValue] = useState(2500);
    const [calculatedValue, setCalculatedValue] = useState(0);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [type, setType] = useState(1);

    useEffect(() => {
        getAppSettings();
    }, []);

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 3 },
            { field: 'settingGroup', condition: 'equals', value: 'CALCULATION_PARAMETERS' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'TAX_DEDUCTION_BASE') {
                                setBaseValue(parseFloat(element.value ? element.value : element.defaultValue));
                            }
                        });
                    }
                }
            }
        );
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.validFrom) dataForm.validFrom = moment(dataForm.validFrom);
        if (dataForm.validTo) dataForm.validTo = moment(dataForm.validTo);
        setCalculatedValue(dataForm.value);
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('payrollTaxDeduction.edit.title') + ' - ' + dataForm.name);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payrollTaxDeduction.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PAYROLL_TAX_DEDUCTION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = moment(values.validFrom).format('yyyy-MM-DD HH:mm');
                if (values.validTo) values.validTo = moment(values.validTo).format('yyyy-MM-DD HH:mm');
                values.value = calculatedValue;
                return values;
            }}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('payrollTaxDeduction.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('payrollTaxDeduction.edit.coefficient')}
                        name="coefficient"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            onChange={(cof: any) => {
                                setCalculatedValue(baseValue * cof);
                            }}
                            isDecimal
                            nyTestId="coefficient"
                        />
                    </Form.Item>
                </Col>
                {type === 2 ? (
                    <>
                        <Col span={10}>
                            <Form.Item
                                label={geti18nText('payrollTaxDeduction.edit.type')}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Select
                                    options={getEnumArray('PAYROLL_TAX_DEDUCTION_TYPE', 'label', 'value')}
                                    onChange={(val: any) => setType(val)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={geti18nText('payrollTaxDeduction.edit.orderBy')} name="orderBy">
                                <NyInputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </>
                ) : (
                    <Col span={16}>
                        <Form.Item
                            label={geti18nText('payrollTaxDeduction.edit.type')}
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <Select
                                options={getEnumArray('PAYROLL_TAX_DEDUCTION_TYPE', 'label', 'value')}
                                onChange={(val: any) => setType(val)}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('payrollTaxDeduction.edit.value')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            value={calculatedValue}
                            isDecimal
                            disabled
                            nyTestId="value"
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={geti18nText('payrollTaxDeduction.edit.yearlyValue')} name="yearlyValue">
                        <NyInputNumber style={{ width: '100%' }} isDecimal nyTestId="yearly-value" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={geti18nText('payrollTaxDeduction.edit.validFrom')} name="validFrom">
                        <NyDatePicker style={{ width: '100%' }} nyTestId="valid-from" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={geti18nText('payrollTaxDeduction.edit.validTo')} name="validTo">
                        <NyDatePicker style={{ width: '100%' }} nyTestId="valid-to" />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PayrollTaxDeductionEdit;
