import { CloudDownloadOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Image, Input, Modal, Row, Tooltip, Upload } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../Constants';
import { fileDownload, getBase64, warningNotification } from '../Utils';

const FileEdit = ({
    visible,
    setVisible,
    file,
    setFile,
    files,
    setFiles,
    accept = '*',
    allowedFileSize,
    uploadFile,
    setNewTemplate,
    canEditName = true,
}: any) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>(undefined);
    const emptyData: any = [];

    const getFileName = (file: any) => {
        return file.name && file.name.substring(0, file.name.indexOf('.'))
            ? file.name.substring(0, file.name.indexOf('.'))
            : file.name;
    };

    const setFileName = (file: any) => {
        let name = form.getFieldValue(['name']) ?? file.name;
        let extension = file.name.split('.')[1] ?? '';
        return name.concat('.').concat(extension);
    };

    useEffect(() => {
        if (fileList && fileList[0] && !fileList[0]?.id) {
            setFile(fileList[0]?.file ?? fileList[0]);
        } else if (fileList == emptyData || fileList?.length == 0) {
            form.setFieldsValue({ file: null });
            form.setFieldsValue({ name: '' });
        }
    }, [fileList]);

    useEffect(() => {
        if (file) {
            form.setFieldsValue({ file: file });
            form.setFieldsValue({ name: getFileName(file) });
            setFileList([file]);
        }
    }, [file]);

    const onClose = () => {
        setVisible(false);
        setFileList([]);
        setFile(null);
        form.resetFields();
    };

    const deleteRecord = () => {
        if (file) {
            setFiles((files: any) => {
                const index = files.indexOf(file);
                const newFileList = files.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        }
        if (setNewTemplate) {
            setNewTemplate(true);
        }
        onClose();
    };

    const deleteAction = (file: any) => {
        if (file) {
            setFileList((fileList: any) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        }
    };

    const addFile = (newFile: any) => {
        const found = files.filter((item: any) => item.id == newFile.id);
        if (!found || found?.length < 1) {
            setFiles([newFile]);
        }
        onClose();
    };

    const save = () => {
        form.validateFields()
            .then(async (values: any) => {
                if (fileList && fileList[0]) {
                    if (fileList[0].size) {
                        let newFile: any = {};
                        delete newFile.id;
                        newFile.uploaded = moment.now();
                        newFile.active = true;
                        newFile.name = setFileName(fileList[0]);
                        newFile.file = newFile.file ?? fileList[0].file ?? fileList[0];
                        let base64String: any = '';
                        if (newFile.file) {
                            base64String = await getBase64(newFile.file);
                        }
                        newFile.thumbUrl = base64String;
                        newFile.url = base64String;
                        addFile(newFile);
                    } else {
                        fileList[0].name = setFileName(fileList[0]);
                        addFile(fileList[0]);
                    }
                } else {
                    warningNotification(geti18nText('upload.file.not.selected'));
                }
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo);
            });
    };

    const uploadProps = {
        multiple: true,
        beforeUpload: (file: any) => {
            if (allowedFileSize && file && file.size > allowedFileSize) {
                warningNotification(geti18nText('upload.file.size.too.big'));
                return false;
            } else {
                setFileList([...fileList, file]);
                return true;
            }
        },
        accept,
        fileList: fileList,
        disabled: false,
    };

    const uploadButton = <Button icon={<UploadOutlined />}>{geti18nText('webshopServices.addFile')}</Button>;

    const onPreview = async (file: any) => {
        setPreviewImage(file.url);
        setPreviewVisible(true);
    };

    const getActions = (file: any) => (
        <>
            <div style={{ float: 'right', fontSize: '22px', cursor: 'pointer' }}>
                {(file?.id || file?.fileId) && (
                    <Tooltip title={geti18nText('app.default.download')}>
                        <CloudDownloadOutlined
                            style={{ color: 'green', marginRight: '10px', fontSize: '24px' }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + (file?.id ?? file?.fileId),
                                    null,
                                    file.name
                                );
                            }}
                        />
                    </Tooltip>
                )}
                <Tooltip title={geti18nText('app.default.button.delete')}>
                    <DeleteOutlined style={{ color: 'red', fontSize: '22px' }} onClick={() => deleteAction(file)} />
                </Tooltip>
            </div>
        </>
    );

    return (
        <>
            <Modal
                title={file ? file.name : geti18nText('webshopServices.addFile')}
                visible={visible}
                width={700}
                onCancel={onClose}
                maskClosable={false}
                footer={
                    <>
                        <Button onClick={onClose}>{geti18nText('app.default.button.cancel')}</Button>
                        {file && (
                            <Button type="primary" danger onClick={deleteRecord}>
                                {geti18nText('app.default.remove')}
                            </Button>
                        )}
                        <Button type="primary" onClick={save}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </>
                }
            >
                <Form form={form} component={false} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="user" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="fileId" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="date" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <>
                                <Form.Item
                                    label={geti18nText('travelWarrantFiles.table.column.file.name')}
                                    name="file"
                                    rules={[
                                        {
                                            required: !file,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Upload
                                        {...uploadProps}
                                        onPreview={onPreview}
                                        itemRender={(originNode, file) => (
                                            <div style={{ width: '100%', display: 'inline-block' }}>
                                                {file.name}
                                                {getActions(file)}
                                            </div>
                                        )}
                                    >
                                        {fileList && fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                </Form.Item>
                            </>
                        </Col>
                    </Row>
                    {canEditName && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={'Naziv'}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Image
                        width={'200px'}
                        style={{ display: 'none' }}
                        preview={{
                            visible: previewVisible,
                            src: previewImage,
                            onVisibleChange: (value) => {
                                setPreviewVisible(value);
                            },
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default FileEdit;
