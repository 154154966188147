import { DeleteTwoTone, ExclamationCircleFilled, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import {
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
    UseRequestSwrGet,
    geti18nText,
} from '@nybble/nyreact';
import { Badge, Button, Card, Checkbox, Col, Empty, Form, Input, List, Modal, Row, Tooltip, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AdministrationRights } from '../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { errorNotification, getDateFormat, okNotification, onKeyDownTextAreaCustom } from '../../utils/Utils';

const { Title } = Typography;

const NotificationWidget = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [notifications, setNotifications] = useState<any>(undefined);

    const hasNotificationEditRole = () => {
        return AdministrationRights.canCreateNotifications();
    };

    const {
        data: data,
        isLoading: loading,
        isError,
    } = UseRequestSwrGet(CONSTANTS_REQ.NOTIFICATION.GET_BY_TYPE, {
        notificationType: 10,
    });

    useEffect(() => {
        if (data != undefined) {
            setNotifications(data);
        }
    }, [data]);

    const linkGridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px',
    };

    const saveNotification = () => {
        form.validateFields().then((values: any) => {
            if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);

            NyRequestResolver.requestPost(CONSTANTS_REQ.NOTIFICATION.EDIT, undefined, values).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    form.resetFields();
                    setVisible(false);
                    getNotifications();
                } else {
                    errorNotification(geti18nText(result.data.error));
                }
            });
        });
    };

    const getNotifications = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION.GET_BY_TYPE, { notificationType: 10 }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setNotifications(result.data);
                    }
                }
            }
        );
    };

    const openNotification = (notification: any) => {
        Modal.info({
            style: { minWidth: '40%' },
            icon: null,
            title: notification.name ? notification.name : '',
            content: <div>{notification.note ? notification.note : ''}</div>,
            okText: geti18nText('app.default.button.ok'),
            onOk() {},
        });
    };

    const notificationDelete = (notification: any) => {
        if (notification) {
            const params = {
                id: notification.id,
            };
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.NOTIFICATION.DELETE + '/' + notification.id,
                undefined,
                params
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    form.resetFields();
                    getNotifications();
                }
            });
        }
    };

    const disabledDateTo = (current: any) => {
        if (current) {
            return current < moment().clone();
        } else {
            return current;
        }
    };

    return (
        <>
            <Card
                title={geti18nText('menu.notifications')}
                className="ny-widget-card"
                bodyStyle={{ padding: '0px' }}
                type="inner"
                extra={
                    hasNotificationEditRole() && (
                        <Tooltip placement="top" title={geti18nText('notification.add')}>
                            <PlusCircleOutlined className="ny-card-icon" onClick={() => setVisible(true)} />
                        </Tooltip>
                    )
                }
            >
                <List
                    style={{
                        maxHeight: '325px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        padding: '4px 5px 0px 5px',
                        marginTop: '1px',
                    }}
                    grid={{
                        gutter: 12,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    loading={loading}
                    dataSource={notifications?.content}
                    renderItem={(notification: any) => (
                        <List.Item className="ny-notification-widget-list-item">
                            <Card.Grid className="ny-card-link" style={linkGridStyle}>
                                <Meta
                                    style={{ padding: '5px' }}
                                    title={
                                        <div>
                                            {hasNotificationEditRole() && (
                                                <>
                                                    <Tooltip
                                                        placement="top"
                                                        title={geti18nText('app.default.button.delete')}
                                                    >
                                                        <NyModalConfirm
                                                            title={geti18nText('app.default.delete.confirm')}
                                                            onConfirm={() => {
                                                                notificationDelete(notification);
                                                            }}
                                                        >
                                                            <Button
                                                                type="link"
                                                                style={{ paddingRight: '5px', paddingLeft: '0px' }}
                                                            >
                                                                <DeleteTwoTone
                                                                    style={{
                                                                        fontSize: '12px',
                                                                    }}
                                                                    twoToneColor="#ff7875"
                                                                />
                                                            </Button>
                                                        </NyModalConfirm>
                                                    </Tooltip>
                                                </>
                                            )}
                                            <Badge
                                                className="ny-notification-widget-badge"
                                                count={
                                                    notification?.content?.note && notification?.content?.priority ? (
                                                        <ExclamationCircleFilled
                                                            className="ny-blink"
                                                            style={{ color: '#f5222d' }}
                                                        />
                                                    ) : (
                                                        0
                                                    )
                                                }
                                            >
                                                {' '}
                                            </Badge>
                                            <span onClick={() => openNotification(notification.content)}>
                                                {notification?.content?.name ? (
                                                    <Tooltip placement="top" title={notification?.content?.name}>
                                                        <div style={{ display: 'inline-flex', maxWidth: '190px' }}>
                                                            <span className={'overview-cards-paragraph'}>
                                                                {notification?.content?.name}
                                                            </span>
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}{' '}
                                            </span>
                                        </div>
                                    }
                                    description={
                                        <div onClick={() => openNotification(notification.content)}>
                                            <span className="ny-notification-widget-description">
                                                {notification?.content?.note && notification?.content?.note
                                                    ? notification?.content?.note
                                                    : ''}
                                            </span>
                                            <span className="ny-notification-widget-footer">
                                                <UserOutlined style={{ marginRight: '5px' }} />
                                                {notification?.content?.user &&
                                                notification?.content?.user?.firstName &&
                                                notification?.content?.user?.lastName
                                                    ? notification?.content?.user?.firstName +
                                                      ' ' +
                                                      notification?.content?.user?.lastName
                                                    : ''}
                                                {notification?.content?.createdTs
                                                    ? '  ' +
                                                      getDateFormat(notification?.content?.createdTs, 'DD.MM.YYYY')
                                                    : ''}
                                            </span>
                                        </div>
                                    }
                                />
                            </Card.Grid>
                        </List.Item>
                    )}
                    className="ny-widget-list"
                    locale={{
                        emptyText: (
                            <div style={{ paddingTop: 10 }}>
                                <Empty
                                    className="ny-dashboard-empty"
                                    imageStyle={{ width: '100%' }}
                                    description={<Title level={5}>{geti18nText('notification.noData')}</Title>}
                                />
                            </div>
                        ),
                    }}
                />
            </Card>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('notification.now')}
                    okText={geti18nText('notification.publish')}
                    maskClosable={false}
                    onOk={(event: any) => {
                        saveNotification();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <Form form={form} layout="vertical" {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item
                                    label={geti18nText('notification.name')}
                                    name={'name'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('notification.note')}
                                    name={['note', 'note']}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea onKeyDown={onKeyDownTextAreaCustom} rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col offset={1} span={11}>
                                <Form.Item label={geti18nText('notification.dateTo')} name="dateTo">
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        disabledDate={disabledDateTo}
                                        nyTestId="date-to"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name={['note', 'priority']}
                                    valuePropName={'checked'}
                                    initialValue={false}
                                    style={{ marginBottom: '0px' }}
                                >
                                    <Checkbox>{geti18nText('notification.priority')}</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default NotificationWidget;
