import { geti18nText, NyDatePicker, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customEmployeeRenderOption, errorNotification, getDateFormat, okNotification } from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import EmployeeGroupIndex from '../employee-group';
import EmployeeGroupSearch from '../employee-group/search';

const AnualLeaveModal = ({
    modalVisible,
    setModalVisible,
    url,
    title,
    refreshTable,
    setRefreshTable,
    isCreate = false,
}: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [allEmployee, setAllEmployee] = useState(true);
    const [withChanges, setWithChanges] = useState(false);

    const save = () => {
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.allEmployees = allEmployee ?? false;
                values.employeesId = [];

                if (values.employees) {
                    values.employeesId = values.employees.map((val: any) => val.id);
                    delete values.employees;
                }

                if (isCreate) {
                    values.withChanges = withChanges;
                }

                if (values.employeeGroup) {
                    if (values.employeeGroup.id > 0) {
                        values.employeeGroupId = values.employeeGroup.id;
                    }
                    delete values.employeeGroup;
                }

                if (values.year) {
                    values.year = getDateFormat(values.year, 'YYYY');
                }

                NyRequestResolver.requestPost(url, undefined, values).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.OK) {
                        closeModal();
                        okNotification();
                        setRefreshTable(refreshTable + 1);
                        form.resetFields();
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false);
        setAllEmployee(true);
        setWithChanges(false);
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={title}
                    okText={geti18nText('app.default.button.save')}
                    onOk={save}
                    onCancel={closeModal}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.edit.allEmployee')}
                                    name="allEmployees"
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Checkbox
                                        onChange={(event: any) => {
                                            form.resetFields(['employeeGroup', 'employee']);
                                            setAllEmployee(event.target.checked);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {isCreate && (
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employeeAnnualLeave.edit.withChanges')}
                                        name="withChanges"
                                        valuePropName="withChanges"
                                        initialValue={true}
                                    >
                                        <Checkbox
                                            onChange={(event: any) => {
                                                setWithChanges(event.target.checked);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employeeAnnualLeave.report.year')}
                                    name="year"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment()}
                                >
                                    <NyDatePicker mode="year" picker="year" format="YYYY" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <EmployeeGroupSearch
                                    disabled={allEmployee || withChanges}
                                    label={geti18nText('payment.calculation.edit.employeeGroup')}
                                    name={'employeeGroup'}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('payrollInsurance.modal.employee')} name="employees">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        mode={'multiple'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="anual-leave-modal"/>}
                                        order="person.last_name, person.first_name"
                                        disabled={allEmployee || withChanges}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AnualLeaveModal;
