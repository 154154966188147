import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useEnum from '../../../../../../hooks/useEnum';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { getColumnSearchByHumanCodebookSimple } from '../../../../../../utils/Filters';
import { fileDownload, getEnumBooleanArray } from '../../../../../../utils/Utils';
import EmployeeEmploymentInjuryEdit from './EmployeeEmploymentInjuryEdit';
import { useEffect, useState } from 'react';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeEmploymentInjuryIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    employeeId,
    showHeader = true,
    scroll = { y: 580, x: 800 },
    sortOrder,
    readonly = false,
}: any) => {
    const table = useTableSettings();
    const { id } = useParams<any>();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [refresh, setRefresh] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id ? id : employeeId },
        ];
    };

    const columns: any = [
        {
            title: geti18nText('employee.employmentInjury.table.column.injuryTime'),
            dataIndex: 'injuryTime',
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (text: any, record: any) => {
                if (record.injuryTime) {
                    return moment(record.injuryTime).format('DD.MM.YYYY. HH:mm');
                }
            },
        },
        {
            title: geti18nText('employee.employmentInjury.table.column.location'),
            dataIndex: ['location', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.INJURY_LOCATION, 'location.id'),
        },
        {
            title: geti18nText('employee.employmentInjury.table.column.severity'),
            dataIndex: ['severity', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.INJURY_SEVERITY, 'severity.id'),
        },
        {
            title: geti18nText('employee.employmentInjury.table.column.healthInstApproved'),
            dataIndex: 'healthInstApproved',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (record: { healthInstApproved: any }) => {
                if (record) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
        },
        {
            title: geti18nText('employee.employmentInjury.table.column.active'),
            dataIndex: 'active',
            width: '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
        },
        {
            title: geti18nText('employee.employmentInjury.table.column.fileID'),
            dataIndex: 'fileId',
            width: '6%',
            render: (text: any, record: any) => {
                if (record.fileId) {
                    return (
                        <Button
                            type="primary"
                            ghost
                            icon={<FilePdfOutlined />}
                            style={{
                                marginLeft: '2px',
                            }}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                    null,
                                    geti18nText('employee.employmentInjury.table.header')
                                );
                            }}
                        >
                            {geti18nText('meal.group.order.table.download.pdf')}
                        </Button>
                    );
                }
            },
        },
    ];

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <NyDataTable
            nyId="human-employee-employment-injury-table"
            headerTitle={showHeader ? geti18nText('employee.employmentInjury.table.header') : null}
            url={CONSTANTS_REQ.EMPLOYEE_EMPLOYMENT_INJURY.LIST}
            addNewButtonText={geti18nText('employee.employmentInjury.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            fetchWhenChange={employeeId || id || refresh}
            hideNewButton={!canCreate()}
            readonly={readonly}
            setDefaultFilterValue={setDefaultFilterValue}
            modalComponent={EmployeeEmploymentInjuryEdit}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            shortcuts={true}
            CSVFileName={geti18nText('employee.employmentInjury.table.header')}
            editProps={{ employeeId: id }}
        />
    );
};

export default EmployeeEmploymentInjuryIndex;
