import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import {
    generateUuid,
    getDateFormat,
    getEnumArrayStatus,
    getEnumBooleanArray,
    getOrdinal,
    getTravelWarrantStatusIcon,
    numberFormat,
} from '../../../../utils/Utils';
import { Tooltip } from 'antd';

const TravelOverviewCost = ({ scroll = { y: 500, x: 2200 }, sortOrder }: any) => {
    const location = useLocation();
    useHelper('travel_order/travel_costs_overview');

    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [];
    };

    const columns: any = [
        {
            title: geti18nText('travelOverview.costs.table.column.id'),
            dataIndex: 'ordinal',
            width: '130px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            fixed: 'left',
            render: (text: any, record: any) => {
                if (record && record.ordinal && record.warrantDate) {
                    return getOrdinal(record);
                }
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.status'),
            dataIndex: 'status',
            width: '190px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TRAVEL_WARRANT_STATUS'), 'in', undefined),
            fixed: 'left',
            render: (status: any) => {
                return getTravelWarrantStatusIcon(status);
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.startDate'),
            dataIndex: 'startDate',
            width:'120px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.endDate'),
            dataIndex: 'endDate',
            width:'120px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (endDate: any, record: any) => {
                if (record.endDate) {
                    return getDateFormat(record.endDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.duration'),
            dataIndex: 'duration',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.vehicleName'),
            dataIndex: 'vehicleName',
            width:'160px',
            render: (record: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={record}>
                        <div className={'description-div-small'}>
                            <p className="description-paragraph">{record}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH, 'travelVehicleId', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.power'),
            dataIndex: 'power',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.weight'),
            dataIndex: 'weight',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.co2.emission'),
            dataIndex: 'co2Emission',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.accountingCostCenterName'),
            dataIndex: 'accountingCostCenterName',
            width:'160px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_CENTER.SEARCH,
                'accountingCostCenterId',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.accountingCostCenterCode'),
            dataIndex: 'accountingCostCenterCode',
            width:'160px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_CENTER.SEARCH,
                'accountingCostCenterId',
                'code',
                'code',
                {
                    id: 'id',
                    label: 'code',
                    text: 'code',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.accountingCostObjectName'),
            dataIndex: 'accountingCostObjectName',
            width:'160px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_OBJECT.SEARCH,
                'accountingCostObjectId',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.accountingCostObjectCode'),
            dataIndex: 'accountingCostObjectCode',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_OBJECT.SEARCH,
                'accountingCostObjectId',
                'code',
                'code',
                {
                    id: 'id',
                    label: 'code',
                    text: 'code',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.firstName'),
            dataIndex: 'firstName',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.lastName'),
            dataIndex: 'lastName',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.employmentRecordId'),
            dataIndex: 'employmentRecordId',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.businessUnitName'),
            dataIndex: 'businessUnitName',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.BUSINESS_UNIT.SEARCH, 'businessUnitId', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.businessUnitCode'),
            dataIndex: 'businessUnitCode',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.BUSINESS_UNIT.SEARCH, 'businessUnitId', 'code', 'code', {
                id: 'id',
                label: 'code',
                text: 'code',
            }),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.costType'),
            dataIndex: 'costType',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.costDescription'),
            dataIndex: 'costDescription',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.costDate'),
            dataIndex: 'costDate',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (costDate: any, record: any) => {
                if (record.costDate) {
                    return getDateFormat(record.costDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.amountInCurrency'),
            dataIndex: 'amountInCurrency',
            width:'140px',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amountInCurrency: any) => {
                return (
                    <div style={{ float: 'right', textAlign: 'right' }}>
                        {amountInCurrency ? numberFormat(amountInCurrency) : ''}
                    </div>
                );
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.currency'),
            dataIndex: 'currency',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.CURRENCY.SEARCH, 'currencyId', 'name', 'name', {
                id: 'id',
                label: 'isoCode',
                text: 'isoCode',
            }),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.amount'),
            dataIndex: 'amount',
            align: 'right',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                return <div style={{ float: 'right', textAlign: 'right' }}>{amount ? numberFormat(amount) : ''}</div>;
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.paymentType'),
            dataIndex: 'paymentDescription',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.TRAVEL_EXPENSE_PAYMENT_METHOD.SEARCH,
                'paymentId',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.reimbursed'),
            dataIndex: 'reimbursed',
            width:'140px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { reimbursed: any }) => {
                if (record.reimbursed) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelOverview.costs.table.column.reimbursedAmount'),
            dataIndex: 'reimbursedAmount',
            align: 'right',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (reimbursedAmount: any) => {
                return (
                    <div style={{ float: 'right', textAlign: 'right' }}>
                        {reimbursedAmount ? numberFormat(reimbursedAmount) : ''}
                    </div>
                );
            },
        },
        {
            title: geti18nText('travelOverview.costs.table.column.joppdTaxfreeCode'),
            dataIndex: 'joppdTaxfreeCode',
            width:'140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-cost-overview"
            url={CONSTANTS_REQ.TRAVEL_WARRANT.COST_OVERVIEW_LIST}
            rowKey={`${generateUuid()}`}
            hideButtons
            onRowSelect={() => null}
            columns={columns}
            scroll={scroll ?? { y: 620, x: columns.length * 120 }}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            exportCSV={true}
            CSVFileName={geti18nText('travelOverview.costs.table.header')}
            colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + value);
            },
        },
        {
            startDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            endDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            costDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            reimbursed: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default TravelOverviewCost;
