import { Modal, List, Row, Button, Switch, Popover, Divider } from 'antd';
import { geti18nText } from '@nybble/nyreact';
import { CloseCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import useEnum from '../../hooks/useEnum';
import React, { useState } from 'react';

const NyErrorLogModal = ({
    modalVisible,
    setModalVisible,
    title,
    errorData,
    setErrorData,
    addTabPane,
    errorDataLink,
    setNotificationsVisible,
    notificationSwitchType,
}: any) => {
    const enImportStatus = useEnum('IMPORT_STATUS');
    const [errorStatusVisible, setErrorStatusVisible] = useState<boolean>(true);
    const [infoStatusVisible, setInfoStatusVisible] = useState<boolean>(true);
    const [warningStatusVisible, setWarningStatusVisible] = useState<boolean>(true);
    const errorCheckBoxVisible =
        errorData && errorData.constructor.name == 'Array'
            ? errorData.some((item: any) => item['status'] == enImportStatus.ERROR)
            : false;
    const infoCheckBoxVisible =
        errorData && errorData.constructor.name == 'Array'
            ? errorData.some((item: any) => item['status'] == enImportStatus.INFO)
            : false;
    const warningCheckBoxVisible =
        errorData && errorData.constructor.name == 'Array'
            ? errorData.some((item: any) => item['status'] == enImportStatus.WARNING)
            : false;
    const hasMultipleTypes =
        [errorCheckBoxVisible, infoCheckBoxVisible, warningCheckBoxVisible].filter((flag) => flag).length > 1;

    const assetInitialStateSwitch = () => {
        const onErrorChange = (e: any) => {
            setErrorStatusVisible(e);
        };

        const onInfoChange = (e: any) => {
            setInfoStatusVisible(e);
        };

        const onWarningChange = (e: any) => {
            setWarningStatusVisible(e);
        };

        return (
            <>
                <div style={{ display: 'inline-flex', marginLeft: '16px' }}>
                    {errorCheckBoxVisible && hasMultipleTypes && (
                        <div>
                            {' '}
                            {geti18nText('assetInitialStateImportCSVe.thread.switch.error')}{' '}
                            <Switch
                                defaultChecked={true}
                                size={'small'}
                                style={{ marginRight: '16px', marginBottom: '2px' }}
                                onChange={(e: any) => {
                                    onErrorChange(e);
                                }}
                            />
                        </div>
                    )}
                    {warningCheckBoxVisible && hasMultipleTypes && (
                        <div>
                            {' '}
                            {geti18nText('assetInitialStateImportCSVe.thread.switch.warning')}{' '}
                            <Switch
                                defaultChecked={true}
                                size={'small'}
                                style={{ marginRight: '16px', marginBottom: '2px' }}
                                onChange={(e: any) => {
                                    onWarningChange(e);
                                }}
                            />
                        </div>
                    )}
                    {infoCheckBoxVisible && hasMultipleTypes && (
                        <div>
                            {' '}
                            {geti18nText('assetInitialStateImportCSVe.thread.switch.info')}{' '}
                            <Switch
                                defaultChecked={true}
                                size={'small'}
                                style={{ marginRight: '16px', marginBottom: '2px' }}
                                onChange={(e: any) => {
                                    onInfoChange(e);
                                }}
                            />
                        </div>
                    )}
                </div>
                {hasMultipleTypes && <Divider style={{ marginLeft: '8px', marginTop: '12px', marginBottom: '16px' }} />}
            </>
        );
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={title}
                    onCancel={() => {
                        setModalVisible(false);
                        setErrorData(undefined);
                    }}
                    width={1200}
                    maskClosable={false}
                    centered
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    {notificationSwitchType == 'assetInitialState' && assetInitialStateSwitch()}
                    {errorData && errorData.constructor.name == 'Array' && (
                        <List style={{ maxHeight: '642px', overflow: 'auto' }} size="small">
                            {errorData.map((item: any) => {
                                if (item['error']) {
                                    if (item['entity'] === 'application_settings') {
                                        return (
                                            <List.Item>
                                                <CloseCircleTwoTone
                                                    twoToneColor="red"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {geti18nText('payment.calculation.error.' + item['error'], [
                                                    geti18nText('app.setting.name.' + item['name']),
                                                ])}
                                            </List.Item>
                                        );
                                    } else if (item['entity'] === 'asset_depreciation') {
                                        return (
                                            <List.Item>
                                                <CloseCircleTwoTone
                                                    twoToneColor="red"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {geti18nText('asset.depreciation.error.' + item['error'], [
                                                    (item['referenceId'] ? item['referenceId'] : '') +
                                                        ' ' +
                                                        (item['name'] ? item['name'] : ''),
                                                ])}
                                            </List.Item>
                                        );
                                    } else if (item['entity'] === 'travel_warrant_plan') {
                                        return (
                                            <List.Item>
                                                <CloseCircleTwoTone
                                                    twoToneColor="red"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {geti18nText('travel.warrant.plan.error.' + item['error'], [
                                                    item['name'] ? item['name'].toUpperCase() : '',
                                                ])}
                                            </List.Item>
                                        );
                                    } else if (item['entity'] === 'payroll_brut') {
                                        return (
                                            <List.Item>
                                                <CloseCircleTwoTone
                                                    twoToneColor="red"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {geti18nText('payment.calculation.error.' + item['error'], [
                                                    geti18nText('app.enum.ATTENDANCE_TYPE.' + item['referenceId']),
                                                ])}
                                            </List.Item>
                                        );
                                    } else {
                                        return (
                                            <List.Item>
                                                <CloseCircleTwoTone
                                                    twoToneColor="red"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {geti18nText('payment.calculation.error.' + item['error'], [
                                                    (item['referenceId'] ? item['referenceId'] : '') +
                                                        ' ' +
                                                        (item['name'] ? item['name'] : ''),
                                                ])}
                                            </List.Item>
                                        );
                                    }
                                } else if (
                                    (item['status'] == enImportStatus.ERROR && errorStatusVisible) ||
                                    (item['status'] == enImportStatus.WARNING && warningStatusVisible) ||
                                    (item['status'] == enImportStatus.INFO && infoStatusVisible)
                                ) {
                                    let link = errorDataLink;
                                    const replaceElem = (
                                        <a
                                            style={{ textDecoration: 'underline' }}
                                            onClick={() => {
                                                addTabPane(
                                                    link.path + '/' + item?.data?.id,
                                                    link.key,
                                                    link.id,
                                                    link.notification,
                                                    link.title,
                                                    link.state
                                                );
                                                setModalVisible(false);
                                                setNotificationsVisible(false);
                                            }}
                                        >
                                            {' ' + item?.data?.documentInfo}
                                        </a>
                                    );
                                    const message = geti18nText(item['message'])
                                        ? geti18nText(item['message'], [item?.data?.documentInfo])
                                        : geti18nText('generic.error.message') + ' "' + item['message'] + '"';
                                    const parts = message.split([item?.data?.documentInfo]);

                                    return (
                                        <List.Item>
                                            {item['status'] == enImportStatus.ERROR && (
                                                <Popover placement="top" content={geti18nText('app.default.error')}>
                                                    <CloseCircleTwoTone
                                                        twoToneColor="red"
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                </Popover>
                                            )}
                                            {item['status'] == enImportStatus.WARNING && (
                                                <Popover placement="top" content={geti18nText('app.default.warning')}>
                                                    <WarningTwoTone
                                                        twoToneColor="orange"
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                </Popover>
                                            )}
                                            {item['status'] == enImportStatus.INFO && (
                                                <Popover placement="top" content={geti18nText('app.default.info')}>
                                                    <InfoCircleTwoTone style={{ marginRight: '10px' }} />
                                                </Popover>
                                            )}
                                            <div style={{ display: 'inline-flex' }}>
                                                {item?.data?.documentInfo &&
                                                    parts.map((part: any, index: any) => (
                                                        <React.Fragment key={index}>
                                                            <div style={{ marginRight: '4px', marginLeft: '4px' }}>
                                                                {part}
                                                            </div>
                                                            {` `} {index < parts.length - 1 && replaceElem}{' '}
                                                        </React.Fragment>
                                                    ))}
                                            </div>
                                            {!item?.data?.documentInfo && message}{' '}
                                            {!item?.data?.documentInfo &&
                                                ' - ' +
                                                    geti18nText('assetInitialStateImportCSVe.thread.error.line', [
                                                        item.line,
                                                    ])}
                                        </List.Item>
                                    );
                                }
                            })}
                        </List>
                    )}
                    {errorData && errorData.constructor.name == 'Object' && (
                        <List style={{ maxHeight: '300px', overflow: 'auto' }} size="small">
                            {Object.keys(errorData).map(function (key: any) {
                                return (
                                    <List.Item>
                                        <CloseCircleTwoTone twoToneColor="red" style={{ marginRight: '10px' }} />
                                        {geti18nText('app.default.line') +
                                            ' ' +
                                            key +
                                            '. - ' +
                                            (geti18nText(errorData[key])
                                                ? geti18nText(errorData[key])
                                                : errorData[key])}
                                    </List.Item>
                                );
                            })}
                        </List>
                    )}
                </Modal>
            )}
        </>
    );
};

export default NyErrorLogModal;
