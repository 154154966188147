import { PlusCircleOutlined } from '@ant-design/icons';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Button, Card, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import MealMenuEdit from './edit';
import MealsMenuNyFullCalendar from './MealsMenuNyFullCalendar';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealMenusCalendarIndex = ({ refreshTable, setRefreshTable }: any) => {
    const { employee } = useSelector((state: RootState) => state.employee);

    const [refresh, setRefresh] = useState<any>(0);
    const [menuModal, setMenuModal] = useState(false);

    const canCreate = () => {
        return MealsRights.isMealsUser();
    };

    const canCreateAdmin = () => {
        return MealsRights.isMealsAdmin();
    };

    useEffect(() => {
        setRefreshTable((refreshTable: any) => refreshTable + 1);
    }, [refresh]);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Card
                        style={{ width: '100%', marginTop: '10px' }}
                        className={`ny-widget-card`}
                        bodyStyle={{
                            maxHeight: '640px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            padding: '0px 5px 0px 5px',
                            marginBottom: '5px',
                        }}
                        type="inner"
                    >
                        <MealsMenuNyFullCalendar
                            keys={['menus']}
                            height={'600px'}
                            showHeaderToolbar={false}
                            employee={employee}
                            refreshView={refresh}
                            setRefreshView={setRefresh}
                            canCreate={canCreate() || canCreateAdmin()}
                            canCreateAdmin={canCreateAdmin()}
                        ></MealsMenuNyFullCalendar>
                    </Card>
                </Col>
            </Row>
            {menuModal && (
                <MealMenuEdit
                    editProps={{
                        visible: menuModal,
                        setVisible: setMenuModal,
                        refresh: refresh,
                        setRefresh: setRefresh,
                        scroll: { y: 400, x: 500 },
                        sortOrder: null,
                    }}
                />
            )}
            <div className="overview-buttons-sticky" style={{ zIndex: '2' }}>
                {canCreateAdmin() && (
                    <div style={{ width: '100%', justifyContent: 'end', display: 'flex' }}>
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                setMenuModal(true);
                            }}
                            icon={<PlusCircleOutlined />}
                        >
                            {' '}
                            {geti18nText('meal.menu.add')}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};
export default MealMenusCalendarIndex;
