import { ClearOutlined } from '@ant-design/icons';
import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Input, Row } from 'antd';
import AssetSimpleIndex from '../../../../components/asset-simple/assetSimple';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customAssetRenderOption } from '../../../../utils/Utils';

const AssetCustomTableFilter = ({ form, setRefresh }: any) => {
    const onChange = (values: any = null) => {
        setRefresh((prevValue: any) => prevValue + 1);
    };

    return (
        <>
            <Form form={form} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label={geti18nText('asset.acquisition.item.table.column.asset')} name={'asset'}>
                            <NySearchField
                                style={{ width: '100%' }}
                                url={CONSTANTS_REQ.ASSET.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'label',
                                    text: 'text',
                                    name: 'name',
                                    serialNumber: 'serialNumber',
                                    inventoryNumber: 'inventoryNumber',
                                    assetModel: 'assetModel',
                                    assetType: 'assetType',
                                    item: 'item',
                                    measureUnit: 'measureUnit',
                                    quantity: 'quantity',
                                }}
                                searchBy="assetModel.name || serialNumber || inventoryNumber"
                                renderOption={customAssetRenderOption}
                                order="assetModel.name"
                                mustGetPopupContainer={false}
                                customListWidth={'1200px'}
                                SearchPopupComponent={<AssetSimpleIndex disabled />}
                                onChange={onChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={geti18nText('asset.acquisition.item.table.column.serialNumber')}
                            name={'serial_number'}
                        >
                            <Input onChange={onChange} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={geti18nText('fixed.asset.table.column.serialNumber')} name={'serial_number2'}>
                            <Input onChange={onChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Button
                            style={{
                                float: 'right',
                                marginRight: '8px',
                                marginBottom: '-10px',
                            }}
                            size={'small'}
                            onClick={() => {
                                form.resetFields();
                                onChange();
                            }}
                        >
                            {geti18nText('app.default.filter.clear.filters')}
                            <ClearOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default AssetCustomTableFilter;
