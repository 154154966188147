import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import HumanCodebooksIndex from '.';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getHumanCodebooksUrlByType } from '../../utils/Utils';
import HumanCodebooksEdit from './edit';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';

const HumanCodebooksSearch = ({
    required = false,
    label = '',
    codebooksType = 0,
    formItemName = 'humanCodebook',
    onChange = null,
    value = undefined,
    search = undefined,
    renderOption = (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`,
    map = { id: 'id', label: 'name', code: 'code', text: 'name' },
    customItemNameLabel = 'name (code)',
    customListWidth = '900px',
    showHeader = false,
    style = undefined,
    allowClear = undefined,
    disabled = false,
    defaultValue = undefined,
}: any) => {
    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={formItemName}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
            style={style}
            initialValue={defaultValue}
        >
            <NySearchField
                allowClear={allowClear}
                url={getHumanCodebooksUrlByType(CONSTANTS_REQ.HUMAN_CODEBOOKS.SEARCH, codebooksType.toString())}
                map={map}
                searchBy="name || ' ' || code"
                itemName={[['code'], ['name']]}
                customItemNameLabel={customItemNameLabel}
                renderOption={renderOption}
                SearchPopupComponent={
                    <HumanCodebooksIndex
                        codebooksType={codebooksType}
                        disabled={!canCreate()}
                        search={search}
                        showHeader={showHeader}
                        headerTitle={label}
                        exportCsv={false}
                    />
                }
                AddNewModalComponent={canCreate() ? HumanCodebooksEdit : null}
                AddNewModalComponentAddedData={{
                    codebooksType: codebooksType,
                }}
                nyTestId="human-codebooks"
                onChange={onChange}
                setDefaultFilterValue={setDefaultFilterValue}
                defaultValue={defaultValue}
                value={value}
                mustGetPopupContainer={false}
                customListWidth={customListWidth}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default HumanCodebooksSearch;
