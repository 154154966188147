import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import AssetAssignHistoryIndex from '../../../../../components/asset-assign-history';
import useHelper from '../../../../../hooks/useHelper';
import AllAssetsTable from './AllAssetsTable';
import BusinessUnitTable from './BusinessUnitTable';
import EmployeeTable from './EmployeeTable';

const { TabPane } = Tabs;

const AssetOverviewIndex = () => {
    useHelper('asset/asset_overview');
    return (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('assetOverview.tab.1')} key="1" style={{ marginTop: '8px' }}>
                    <EmployeeTable scroll={{ y: 600, x: 800 }} />
                </TabPane>
                <TabPane tab={geti18nText('assetOverview.tab.2')} key="2" style={{ marginTop: '8px' }}>
                    <BusinessUnitTable scroll={{ y: 600, x: 800 }} />
                </TabPane>
                <TabPane tab={geti18nText('assetOverview.tab.3')} key="3" style={{ marginTop: '8px' }}>
                    <AllAssetsTable />
                </TabPane>
                <TabPane tab={geti18nText('assetOverview.tab.4')} key="4" style={{ marginTop: '8px' }}>
                    <AssetAssignHistoryIndex />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default AssetOverviewIndex;
