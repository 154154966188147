import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import VocationDescriptionEdit from './edit';
import { Tooltip } from 'antd';

const VocationDescriptionIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('human/codebooks/profession');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const columns = [
        {
            title: geti18nText('vocation.description.table.column.id'),
            dataIndex: 'id',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('vocation.description.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.name !== undefined) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={record?.name}>
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>{record?.name}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('vocation.table.column.english.name'),
            dataIndex: 'nameEng',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.nameEng !== undefined) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={record?.nameEng}>
                            <div className={'ny-table-paragraph-wrap'}>
                                <p className={'ny-table-paragraph'}>{record?.nameEng}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('vocation.description.table.column.active'),
            dataIndex: 'active',
            width: '8%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-vocation-description-table"
            url={CONSTANTS_REQ.VOCATION_DESCRIPTION.LIST}
            addNewButtonText={geti18nText('vocation.description.table.add')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={VocationDescriptionEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideButtons={disabled}
            readonly={disabled}
            shortcuts={true}
            // exportCSV={canExportCSV()}
            // CSVFileName={geti18nText('menu.vocation.description')}
            // colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.vocation.description')}
        />
    );
};

export default VocationDescriptionIndex;
