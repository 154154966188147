import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import RoomIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import RoomEdit from './edit';

const RoomSearch = ({
    customItemNameLabel = undefined,
    disabled = false,
    initialValue = undefined,
    itemName = 'name',
    label = geti18nText('fixed.asset.edit.location'),
    map = { id: 'id', label: 'name', text: 'name' },
    mode = 'default',
    name = 'room',
    required = false,
    searchBy = "name || ' ' || id",
    setDefaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }],
    mustGetPopupContainer = true,
    onChange = undefined,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            initialValue={initialValue}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.ROOM.SEARCH}
                map={map}
                searchBy={searchBy}
                itemName={itemName}
                onChange={onChange}
                customItemNameLabel={customItemNameLabel}
                setDefaultFilterValue={() => setDefaultFilterValue}
                AddNewModalComponent={RoomEdit}
                SearchPopupComponent={<RoomIndex />}
                mode={mode}
                disabled={disabled}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default RoomSearch;
