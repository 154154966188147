import { NyDataTable, NySession, getColumnSearch, geti18nText } from '@nybble/nyreact';
import RowAction from '../../../../components/row-action';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { MobilizationRights } from '../../../../rights/mobilizationRights';

const BusinessUnitTable = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    const canCreate = () => {
        return MobilizationRights.canCreateMobilization();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'parent_id', condition: 'is_null', value: '' },
        ];
    };

    const columns = [
        {
            title: geti18nText('businessUnit.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('businessUnit.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('businessUnit.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record?.id && canCreate()) {
                    return <RowAction component={'MobilizationCallBusinessUnit'} record={record}></RowAction>;
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-business-unit"
            url={CONSTANTS_REQ.BUSINESS_UNIT.LIST}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? { order: 'code', orderType: 'asc' }}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            shortcuts={true}
            hideNewButton={true}
            childrenColumnName={'noChildren'}
            onRowSelect={() => {}}
        />
    );
};

export default BusinessUnitTable;
