import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationIndex from '../payment-calculation';

const SecondIncomeIndex = () => {
    useHelper('payroll/calculation/second_income');

    return <PaymentCalculationIndex type={2} />;
};

export default SecondIncomeIndex;
