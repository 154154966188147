import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Alert, Col, Collapse, List, Radio } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import useApplicationSetting from '../../hooks/useApplicationSetting';

const { Panel } = Collapse;

const TravelWarrantTypeComponentIndex = (
    form: any,
    setIncludeEducationDate: any = null,
    includeEducationDate: any = null,
    setPayoutByAnotherOrganization: any = null,
    travelWarrantType: any,
    setTravelWarrantType: any,
    showSearch: any = true,
    isNotEditable: any = false
) => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const primaryColor = useApplicationSetting('PRIMARY_COLOR');

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    useEffect(() => {
        if (data.length > 0 && !checkTravelWarrantType()) {
            setTravelWarrantType(
                data.find((item: any) => {
                    return item.id == 1;
                })
            );
        }
    }, [data]);

    useEffect(() => {
        if (travelWarrantType) {
            setIncludeEducationDate(travelWarrantType.includeEducationDate);
            setPayoutByAnotherOrganization(travelWarrantType.payoutByAnotherOrganization);
        }
    }, [travelWarrantType]);

    const fetchData = () => {
        const setDefaultFilterValue = () => {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        };

        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));

        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.LIST, {
            max: 999,
            search: stringUri,
            order: 'sort',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setLoading(false);
                if (result.data) {
                    setData(result?.data?.content);
                }
            }
        });
    };

    const disabledendEduDate = (current: any) => {
        if (form.getFieldValue('educationStartDate')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('educationStartDate')).endOf('day');
        }
    };

    const onTypeChange = (value: any) => {
        const val = value?.name?.props && value?.name?.props['custom-data'];
        if (val && val.id > -1) {
            if (val.includeEducationDate != undefined) {
                setIncludeEducationDate(val.includeEducationDate);
            }
        } else {
            setIncludeEducationDate(false);
        }
    };

    const onRowSelect = (item: any) => {
        setTravelWarrantType(item);
        setIncludeEducationDate(item?.includeEducationDate);
        setPayoutByAnotherOrganization(item?.payoutByAnotherOrganization);
        form.setFieldsValue({ travelWarrantType: { id: item.id } });
    };

    useEffect(() => {
        if (!includeEducationDate) {
            form.setFieldsValue({ educationStartDate: null });
            form.setFieldsValue({ educationEndDate: null });
        }
    }, [includeEducationDate]);

    const checkTravelWarrantType = () => {
        return data
            .map((val: any) => {
                return val.id;
            })
            .includes(travelWarrantType?.id);
    };

    return (
        <>
            {/* {!showSearch ? ( */}
            <>
                {!isNotEditable && (
                    <div style={{ paddingBottom: '15px' }}>
                        <Alert message={geti18nText('travelWarrantType.wizard.alert')} type="info" showIcon />
                    </div>
                )}
                <div
                    style={{
                        maxHeight: isNotEditable ? '75vh' : '550px',
                        overflowY: 'auto',
                        cursor: 'default',
                    }}
                >
                    <List loading={loading} itemLayout="horizontal" pagination={false}>
                        {data?.length > 1 ? (
                            data?.map((item: any) => {
                                return (
                                    <List.Item
                                        onClick={isNotEditable ? undefined : () => onRowSelect(item)}
                                        style={{
                                            border: isDarkTheme ? '3px solid #111b26' : '3px solid #f3f3f3',
                                            borderRadius: '3px',
                                            backgroundColor:
                                                travelWarrantType?.id === item.id
                                                    ? primaryColor
                                                        ? `rgba(${primaryColor}, 0.2)`
                                                        : isDarkTheme
                                                        ? '#102444'
                                                        : '#e8f4fc'
                                                    : undefined,
                                            transition: 'background-color 0.3s ease',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        <Collapse ghost expandIconPosition="right" style={{ width: '100%' }}>
                                            <Panel
                                                header={
                                                    <span
                                                        style={{
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                            cursor: isNotEditable ? 'default' : 'pointer',
                                                        }}
                                                    >
                                                        <Radio
                                                            checked={travelWarrantType?.id === item.id}
                                                            disabled={isNotEditable}
                                                            style={{ paddingRight: '10px' }}
                                                        />
                                                        {item.name}
                                                    </span>
                                                }
                                                key={item.id}
                                            >
                                                <p>{item.description}</p>
                                            </Panel>
                                        </Collapse>
                                    </List.Item>
                                );
                            })
                        ) : (
                            <List.Item
                                style={{
                                    border: primaryColor
                                        ? `rgba(${primaryColor}, 0.2)`
                                        : isDarkTheme
                                        ? '3px solid #111b26'
                                        : '3px solid #f3f3f3',
                                    borderRadius: '3px',
                                    backgroundColor: isDarkTheme ? '#102444' : '#e8f4fc',
                                    transition: 'background-color 0.3s ease',
                                    paddingLeft: '10px',
                                }}
                            >
                                <Collapse ghost expandIconPosition="right" style={{ width: '100%' }}>
                                    <Panel
                                        header={
                                            <span
                                                style={{
                                                    fontSize: '15px',
                                                    fontWeight: 'bold',
                                                    cursor: 'default',
                                                }}
                                            >
                                                <Radio
                                                    checked={true}
                                                    disabled={true}
                                                    style={{ paddingRight: '10px' }}
                                                />
                                                {data && data[0] && data[0]?.name}
                                            </span>
                                        }
                                        key={data && data[0] && data[0]?.id}
                                    >
                                        <p> {data && data[0] && data[0]?.description}</p>
                                    </Panel>
                                </Collapse>
                            </List.Item>
                        )}
                    </List>
                </div>
            </>
            {/* ) : (
                <>
                    <Col span={24}>
                        <TravelWarrantTypeSearch onChange={onTypeChange} />
                    </Col>
                    {includeEducationDate && TravelWarrantTypeDates(includeEducationDate, disabledendEduDate, form)}
                </>
            )} */}
        </>
    );
};

export default TravelWarrantTypeComponentIndex;
