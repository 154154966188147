import { geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import DepartmentIndex from '.';
import DepartmentEdit from './edit';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const DepartmentSearch = ({
    label = geti18nText('defense.department.edit.department.parent'),
    name = 'parent',
    canCreate = true,
    required = false,
    disabled = false,
    autoFocus = false,
    onChange = undefined,
    isFormItem = true,
    value = undefined,
    ref = undefined,
    mustGetPopupContainer = false,
    mode,
}: any) => {
    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const getSearchField = () => (
        <NySearchField
            url={CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH}
            map={{ id: 'id', label: 'name' }}
            searchBy="name"
            nyTestId="department"
            SearchPopupComponent={<DepartmentIndex />}
            AddNewModalComponent={canCreate ? DepartmentEdit : null}
            setDefaultFilterValue={() => [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: defenseDepartmentType.DEPARTMENT },
            ]}
            style={{ width: '100%' }}
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onChange}
            value={value}
            ref={ref}
            mode={mode}
            mustGetPopupContainer={mustGetPopupContainer}
        />
    );
    return (
        <>
            {isFormItem ? (
                <Form.Item
                    label={label}
                    name={name}
                    rules={
                        required
                            ? [
                                  {
                                      required: required,
                                      message: geti18nText('app.default.required'),
                                  },
                              ]
                            : []
                    }
                >
                    {getSearchField()}
                </Form.Item>
            ) : (
                getSearchField()
            )}
        </>
    );
};

export default DepartmentSearch;
