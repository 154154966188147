import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { errorNotification, okNotification, onKeyDownTextAreaCustom } from '../../utils/Utils';

const TaskEvaluationModal = ({ task, canEvaluate, setRefresh }: any) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const focusInput = useRef<any>(null);
    const [changeStatusConfirmed, setChangeStatusConfirmed] = useState<any>(false);

    const openModal = () => {
        if (focusInput.current) {
            focusInput.current.focus();
        }
        form.setFieldsValue({
            description: task?.taskEvaluation?.description,
            changeStatusConfirmed: task?.taskEvaluation?.changeStatusConfirmed ?? false,
        });
        setChangeStatusConfirmed(task?.taskEvaluation?.changeStatusConfirmed ?? false);
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
        setChangeStatusConfirmed(false);
        form.resetFields();
    };

    const evaluate = () => {
        form.validateFields()
            .then((values: any) => {
                if (task?.id) {
                    let evaluation: any = {
                        id: task?.taskEvaluation?.id ?? task?.id,
                        task: task,
                        description: values.description,
                        created: task?.taskEvaluation?.created ?? null,
                        createdBy: task?.taskEvaluation?.createdBy ?? null,
                        updated: task?.taskEvaluation?.updated ?? null,
                        active: task?.taskEvaluation?.active ?? true,
                        changeStatusConfirmed: changeStatusConfirmed,
                    };

                    NyRequestResolver.requestPut(CONSTANTS_REQ.TASK.EVALUATE + '/' + task.id, undefined, {
                        id: task.id,
                        taskEvaluation: evaluation,
                    }).then((result: any) => {
                        if (result && (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED)) {
                            okNotification();
                            setRefresh((refresh: any) => refresh + 1);
                            closeModal();
                        } else {
                            errorNotification();
                        }
                    });
                }
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo);
            });
    };

    const getSaveButtonText = () =>
        task?.taskEvaluation?.id ? geti18nText('task.evaluate.action.update') : geti18nText('task.evaluate.action.new');

    return (
        <>
            {canEvaluate && (
                <Button type="primary" onClick={openModal}>
                    {getSaveButtonText()}
                </Button>
            )}
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('task.evaluate.header') + ' - ' + task?.name}
                    maskClosable={false}
                    onCancel={closeModal}
                    footer={[
                        <>
                            <Button key="back" onClick={closeModal}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            {canEvaluate && (
                                <Button key="submit" type="primary" onClick={evaluate}>
                                    {getSaveButtonText()}
                                </Button>
                            )}
                        </>,
                    ]}
                >
                    <Form
                        form={form}
                        // onFinishFailed={({ errorFields }) => {
                        //     form.scrollToField(errorFields[0].name);
                        // }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('task.evaluate.description')}
                                    name="description"
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('task.evaluate.changeStatusConfirmed')}
                                    name="changeStatusConfirmed"
                                    labelAlign={'left'}
                                >
                                    <Checkbox
                                        defaultChecked={changeStatusConfirmed}
                                        onChange={(val: any) => {
                                            setChangeStatusConfirmed(val.target.checked);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default TaskEvaluationModal;
