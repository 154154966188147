import { geti18nText, NyDatePicker, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Row } from 'antd';
import _, { range } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { errorNotification, getDateFormat } from '../../utils/Utils';

const TravelWarrantPlanStepsDescription = ({
    travelWarrantPlan,
    setTravelWarrantPlansData,
    index,
    maxIndex,
    isWizard = true,
    startDate,
    endDate,
    isFirst = false,
    isLast = false,
}: any) => {
    const [destination, setDestination] = useState<any>('');
    const [travelWarrantPlanForm] = Form.useForm();
    const [defaultPickerValue, setDefautPickerValue] = useState<any>(undefined);

    useEffect(() => {
        if (travelWarrantPlan) {
            setValues(travelWarrantPlan);
        }
    }, [travelWarrantPlan]);

    function setValues(dataForm: any) {
        let dateStartPicker = dataForm?.startDate ? moment(dataForm?.startDate) : null;
        let dateEndPicker = dataForm?.endDate ? moment(dataForm?.endDate) : null;

        setDefautPickerValue(dateEndPicker == null ? dateStartPicker : dateEndPicker);
        setDestination(travelWarrantPlan.destination ? travelWarrantPlan.destination : '');
        travelWarrantPlanForm.setFieldsValue({ startDate: dateStartPicker, endDate: dateEndPicker });
    }

    const disabledEndDate = (current: any) => {
        if (endDate && startDate) {
            if (travelWarrantPlanForm.getFieldValue('startDate')) {
                return (
                    moment(current).add(1, 'days') <
                        moment(travelWarrantPlanForm.getFieldValue('startDate')).endOf('day') ||
                    moment(current) > moment(endDate).endOf('day') ||
                    moment(current) < moment(startDate).startOf('day')
                );
            }
            return moment(current) > moment(endDate).endOf('day');
        }
    };

    const disabledEndTime = (current: any) => {
        if (!current.isValid()) {
            return;
        }

        let hoursEnd = 0;
        let minutesEnd = 0;

        let hoursStart = 0;
        let minutesStart = 0;

        let hoursStartRow = 0;
        let minutesStartRow = 0;

        let end = false;
        let start = false;
        let startInRow = false;
        if (endDate.isValid()) {
            const endInitial = moment(endDate);
            if (endInitial.format('MM.Do.YYYY') == moment(current).format('MM.Do.YYYY')) {
                end = true;
                hoursEnd = endInitial.hours() ? endInitial.hours() : 0;
                minutesEnd = endInitial.minutes() ? endInitial.minutes() : 0;
            }
        }
        if (travelWarrantPlanForm.getFieldValue('startDate').isValid()) {
            const startDateMoment = moment(travelWarrantPlanForm.getFieldValue('startDate'));
            if (startDateMoment.format('MM.Do.YYYY') == moment(current).format('MM.Do.YYYY')) {
                startInRow = true;
                hoursStartRow = startDateMoment.hours() ? startDateMoment.hours() : 0;
                minutesStartRow = startDateMoment.minutes() ? startDateMoment.minutes() : 0;
            }
        } else if (startDate.isValid()) {
            const startInitial = moment(startDate);
            if (startInitial.format('MM.Do.YYYY') == moment(current).format('MM.Do.YYYY')) {
                start = true;
                hoursStart = startInitial.hours() ? startInitial.hours() : 0;
                minutesStart = startInitial.minutes() ? startInitial.minutes() : 0;
            }
        }
        const hoursCurrent = moment(current).hours() ? moment(current).hours() : 0;

        if (!startInRow) {
            if (end) {
                return {
                    disabledHours: () => range(0, 24).splice(hoursEnd + 1, 24),
                    disabledMinutes: () => range(0, 60).splice(hoursEnd <= hoursCurrent ? minutesEnd + 1 : 60, 60),
                };
            } else if (start && !end) {
                return {
                    disabledHours: () => range(0, 24).splice(0, hoursStart),
                    disabledMinutes: () => range(0, 60).splice(0, hoursStart >= hoursCurrent ? minutesStart : 0),
                };
            }
        } else if (startInRow) {
            if (end) {
                return {
                    disabledHours: () => range(0, 24).splice(hoursEnd + 1, 24),
                    disabledMinutes: () => range(0, 60).splice(hoursEnd <= hoursCurrent ? minutesEnd + 1 : 60, 60),
                };
            } else if (!end) {
                return {
                    disabledHours: () => range(0, 24).splice(0, hoursStartRow),
                    disabledMinutes: () => range(0, 60).splice(0, hoursStartRow >= hoursCurrent ? minutesStartRow : 0),
                };
            }
        }
    };

    const onChangeStartDate = (val: any) => {
        updateTravelWarrantPlan();
    };

    const onChangeEndDate = (val: any) => {
        updateTravelWarrantPlan();
    };

    const checkRangeDates = (val: any) => {
        const startInitial = moment(startDate);
        const endInitial = moment(endDate);
        const start = new Date(startDate);
        const end = new Date(endDate);
        const valDate = new Date(val);
        if (start.getTime() > valDate.getTime()) {
            val = startInitial;
        } else if (end.getTime() < valDate.getTime()) {
            val = endInitial;
        }
        return getDateFormat(val);
    };

    const updateTravelWarrantPlan = () => {
        if (travelWarrantPlan && travelWarrantPlan.id) {
            travelWarrantPlanForm.validateFields().then((values: any) => {
                travelWarrantPlan.startDate = checkRangeDates(values.startDate);
                travelWarrantPlan.endDate = checkRangeDates(values.endDate);
                NyRequestResolver.requestPut(
                    CONSTANTS_REQ.TRAVEL_WARRANT_PLAN.EDIT + '/' + travelWarrantPlan.id,
                    undefined,
                    travelWarrantPlan
                ).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        setTravelWarrantPlansData((travelWarrantPlansData: any) => {
                            const newTravelWarrantPlansData = _.cloneDeep(travelWarrantPlansData);
                            const index = newTravelWarrantPlansData.findIndex((twp: any) => twp.id === result.data.id);
                            if (index >= 0 && index < newTravelWarrantPlansData.length - 1) {
                                newTravelWarrantPlansData[index] = result.data;
                                newTravelWarrantPlansData[index + 1].startDate = result.data.endDate;
                                if (result.data.endDate > newTravelWarrantPlansData[index + 1].endDate) {
                                    newTravelWarrantPlansData[index + 1].endDate = undefined;
                                    // newTravelWarrantPlansData[index + 1].endDate = result.data.endDate;
                                }
                            }
                            return newTravelWarrantPlansData;
                        });
                    } else {
                        errorNotification(geti18nText(result?.data?.error));
                        travelWarrantPlanForm.setFieldsValue({ endDate: undefined });
                    }
                });
            });
        }
    };

    return (
        <div>
            <p style={{ fontWeight: '500' }}>{destination}</p>
            <Form
                id={`travel-warrant-plan-${travelWarrantPlan.id}`}
                form={travelWarrantPlanForm}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinishFailed={({ errorFields }) => {
                    travelWarrantPlanForm.scrollToField(errorFields[0].name);
                }}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText(
                                index === 0
                                    ? 'travelWarrantWage.edit.startDate'
                                    : 'travelWarrantWage.edit.enteringCountry'
                            )}
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                showTime={true}
                                onOk={onChangeStartDate}
                                onBlur={onChangeStartDate}
                                format="DD.MM.YYYY HH:mm"
                                //disabledDate={disabledStartDate}
                                //disabledTime={disabledStartTime}
                                style={{ width: '100%' }}
                                nyTestId="date-time-start"
                                disabled
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText(
                                index === maxIndex
                                    ? 'travelWarrantWage.edit.endDate'
                                    : 'travelWarrantWage.edit.leavingCountry'
                            )}
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                showTime={true}
                                onOk={onChangeEndDate}
                                onBlur={onChangeEndDate}
                                format="DD.MM.YYYY HH:mm"
                                disabledDate={disabledEndDate}
                                //    disabledTime={disabledEndTime}
                                style={{ width: '100%' }}
                                nyTestId="date-time-end"
                                disabled={isLast || !isWizard}
                                autoFocus={isFirst}
                                mustGetPopupContainer={false}
                                defaultPickerValue={defaultPickerValue}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default TravelWarrantPlanStepsDescription;
