import { BulbOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { geti18nText, NyModalConfirm, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Row, Tag, Tooltip, Typography } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import moment from 'moment';
import 'moment/locale/hr';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useEnum from '../../../../../hooks/useEnum';
import { RootState } from '../../../../../rootReducer';
// import { setActiveTab } from '../../../../../slices/tabsSlice';
import { MealsRights } from '../../../../../rights/mealsRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    checkIsHoliday,
    checkIsWeekend,
    errorNotification,
    fileDownload,
    getDateFormat,
    GetMealOrderStatusColor,
    GetMealOrderStatusColorDark,
    getMealOrderStatusIcon,
    getNextWorkday,
    okNotification,
} from '../../../../../utils/Utils';
import OrderItems from './OrderItems';
import MealOrderWizard from './wizard';

const { Link, Text } = Typography;

const MealsNyFullCalendar = ({
    keys = ['meals'],
    initialView = 'dayGridWeek',
    firstDay = 1,
    height = '200px',
    showHeaderToolbar = true,
    defaultAllDay = true,
    navLinks = true,
    stickyHeaderDates = true,
    showNonCurrentDates = true,
    weekends = true,
    editable = false,
    selectable = true,
    expandRows = true,
    isPersonal = true,
    employee,
    refreshView,
    setRefreshView,
    canCreate,
    canCreateAdmin,
    allowOrdersToday = null,
    mealOrderEntryTime = null,
    mealOrderCancelTime = null,
    mealSyncTypeHNB = false,
    mealSubventionValue = 0,
}: any) => {
    const history = useHistory();
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const currentLang = NyUtils.getSelectedLanguage();
    const [keysCalendar, setKeysCalendar] = useState<any>(keys);
    const [eventsMeals, setEventsMeals] = useState<any>([]);

    const [startDate, setStartDate] = useState<any>(moment().startOf('week').format('yyyy-MM-DD HH:mm').toString());
    const [endDate, setEndDate] = useState<any>(moment().endOf('week').format('yyyy-MM-DD HH:mm').toString());

    const deliveryType = useEnum('DELIVERY_TYPE');
    const mealOrderStatus = useEnum('MEAL_ORDER_STATUS');

    const [orderMealModal, setOrderMealModal] = useState<boolean>(false);
    const [selectedDateMeal, setSelectedDateMeal] = useState<any>(null);
    const [mealOrder, setMealOrder] = useState<any>(undefined);
    const [nextWorkDay, setNextWorkDay] = useState<any>(null);
    const [mealsOrderCancellationTime, setMealsOrderCancellationTime] = useState<any>(null);
    const [mealsOrderApprovalTime, setMealsOrderApprovalTime] = useState<any>(null);
    const [checkBox, setCheckBox] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingId, setLoadingId] = useState<any>(false);

    document.querySelectorAll('button.fc-today-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridWeek-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-prev-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-next-button')?.forEach((element) => element.setAttribute('title', ''));

    const [holidays, setHolidays] = useState([]);

    const getHolidayName = (date: any) => {
        let dateValueDate = moment(date).format('DD.MM.YYYY.');
        const holiday: any = holidays.find((day: any) => day.date == dateValueDate);
        if (holiday && holiday.active) {
            return holiday.name;
        } else {
            return false;
        }
    };

    const checkIsToday = (date: any) => {
        let dateValueDate = moment(date).format('DD.MM.YYYY');
        let todayDate = moment().format('DD.MM.YYYY');
        return dateValueDate == todayDate;
    };

    const checkIsNextWorkday = (date: any) => {
        const dateValueDate = moment(date).format('DD.MM.YYYY');
        return dateValueDate == nextWorkDay;
    };

    const canEntryOrConfirm = (date: any) => {
        let dateValueDate = moment(date).format('DD.MM.YYYY');
        let todayMinutes = moment().format('HH:mm');
        if (!checkIsHoliday(date, holidays) && !checkIsWeekend(moment(date))) {
            if (checkIsToday(date)) {
                if (canCreateAdmin) {
                    return canCancelOrder(date);
                } else if (canCreate) {
                    return mealOrderEntryTime > todayMinutes && allowOrdersToday == true;
                } else {
                    return false;
                }
            } else if (
                moment(dateValueDate, 'DD.MM.YYYY').isAfter(moment(nextWorkDay, 'DD.MM.YYYY')) ||
                (checkIsNextWorkday(date) &&
                    ((mealOrderEntryTime > todayMinutes && allowOrdersToday == false) ||
                        allowOrdersToday == true ||
                        canCreateAdmin))
            ) {
                return true;
            }
        }
        return false;
    };

    const canCancelOrder = (orderDate: any) => {
        let todayMinutes = moment().format('HH:mm');
        return !(mealOrderCancelTime < todayMinutes && checkIsToday(orderDate));
    };

    const showEntryCountdown = (date: any) => {
        let todayMinutes = moment().format('HH:mm');
        return (
            (allowOrdersToday === true && checkIsToday(date) && mealOrderEntryTime > todayMinutes) ||
            (allowOrdersToday === false && checkIsNextWorkday(date) && mealOrderEntryTime > todayMinutes)
        );
    };

    const showCancelCountdown = (elementValue: any) => {
        return (
            (elementValue.status == mealOrderStatus.CONFIRMED ||
                elementValue.status == mealOrderStatus.CANCELED ||
                elementValue.status == mealOrderStatus.UNCONFIRMED) &&
            mealOrderCancelTime &&
            canCreate &&
            checkIsToday(elementValue.orderDate) &&
            canCancelOrder(elementValue.orderDate)
        );
    };

    const hasKey = (key: any) => {
        return (
            keysCalendar &&
            keysCalendar.filter((k: any) => k.includes(key)) &&
            keysCalendar.filter((k: any) => k.includes(key)).length > 0
        );
    };

    useEffect(() => {
        fetchHolidays();
        getAppSettings();
    }, []);

    useEffect(() => {
        if (
            startDate &&
            hasKey('meals') &&
            allowOrdersToday != null &&
            mealOrderEntryTime != null &&
            mealOrderCancelTime != null &&
            nextWorkDay != null
        ) {
            fetchMeals();
        }
    }, [refreshView, startDate, allowOrdersToday, mealOrderEntryTime, mealOrderCancelTime, holidays, nextWorkDay]);

    const fetchMeals = async () => {
        const resolvedData: any = [];
        let date = moment(startDate, 'DD.MM.YYYY');
        let dateEnd = moment(endDate, 'DD.MM.YYYY');
        for (let i: number = 1; i <= 7; i++) {
            if (date.isSameOrBefore(dateEnd) && canEntryOrConfirm(date)) {
                const object: any = {};
                object.tempId = Number(date);
                object.type = 'addNewMealOrder';
                object.start = moment(date).format('yyyy-MM-DD');
                object.end = moment(date).format('yyyy-MM-DD');
                await resolvedData.push(object);
            } else {
                const holidayName = getHolidayName(date);
                if (holidayName && holidayName != false) {
                    const object: any = {};
                    object.tempId = Number(date);
                    object.type = 'holiday';
                    object.name = holidayName;
                    object.color = theme === 'dark' ? '#313131' : '#f5f5f5';
                    object.start = moment(date).format('yyyy-MM-DD');
                    object.end = moment(date).format('yyyy-MM-DD');
                    await resolvedData.push(object);
                }
            }
            date = date.add(1, 'days');
        }

        let search = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_id', condition: 'equals', value: employee && employee.id },
                {
                    field: 'orderDate',
                    condition: 'date_range',
                    value: `${getDateFormat(startDate, 'yyyy-MM-DD')}, ${getDateFormat(endDate, 'yyyy-MM-DD')}`,
                },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.LIST, {
            search: search,
        }).then((result: any) => {
            isLoading && setIsLoading(false);
            isLoading && setLoadingId(0);
            if (result.status === RESPONSE.OK && result.data && result.data.content && result.data.content.length > 0) {
                result.data.content.map(async (element: any) => {
                    element.start = moment(element.orderDate).format('yyyy-MM-DD');
                    element.end = moment(element.orderDate).format('yyyy-MM-DD');
                    element.tempId = element.id;
                    element.color =
                        theme === 'dark'
                            ? GetMealOrderStatusColorDark(element.status)
                            : GetMealOrderStatusColor(element.status);
                    element.type = 'mealOrder';

                    const compareDay = Number(moment(element.start));
                    const index = resolvedData.findIndex((object: any) => object.tempId == compareDay);
                    if (index > -1) {
                        const item = resolvedData[index];
                        resolvedData.splice(index, 1, { ...item, ...element });
                    } else {
                        resolvedData.push(element);
                    }
                });
            }
            setEventsMeals(resolvedData);
        });
    };

    const fetchHolidays = () => {
        let active = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOT_WORKING_DAYS.ALL_NOT_WORKING_DAYS, {
            search: active,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    const holidayList = result.data.map((day: any) => {
                        return {
                            date: moment(day.date).format('DD.MM.YYYY.'),
                            name: day.name,
                            active: day.active,
                        };
                    });
                    setHolidays(holidayList);
                    setNextWorkDay(getNextWorkday(holidayList));
                }
            }
        });
    };

    const onCLick = (id: any, type: any, modal: any) => {
        if (id) {
            if (modal) {
                switch (type) {
                    case 'mealOrder':
                        setMealOrder(
                            eventsMeals &&
                                eventsMeals.filter((order: any) => order && order.id == id) &&
                                eventsMeals.filter((order: any) => order.id == id)[0]
                        );
                        setOrderMealModal(true);
                }
            } else {
                // dispatch(setActiveTab('/' + type + '/' + id));
                history.push('/' + type + '/' + id);
            }
        }
    };

    const getTooltipValueMeals = (meal: any) => {
        let orderDate = meal.orderDate ? moment(meal.orderDate).format('DD.MM.YYYY') : '';
        return (
            <>
                {getMealOrderStatusIcon(meal.status)}
                <br />
                {`${meal?.person?.firstName} ${meal?.person?.lastName}`}
                {meal.employee &&
                    meal.employee.employmentRecordId &&
                    ` ${geti18nText('employee.employmentId.abbreviation')}: (${meal.employee.employmentRecordId})`}
                <br />
                {`${orderDate}`}
            </>
        );
    };

    const getDeliveryInfo = (elementValue: any) => {
        if (elementValue.deliverySpotTime && elementValue.deliverySpotTime.type == deliveryType.DELIVERY) {
            return (
                <>
                    {geti18nText('meal.order.edit.deliverySpot')}:{' '}
                    <div style={{ fontWeight: 'bold' }}>
                        {elementValue.deliverySpot && elementValue.deliverySpot.name}
                    </div>
                    {geti18nText('meal.order.edit.deliverySpotTime')}:{' '}
                    <div style={{ fontWeight: 'bold' }}>
                        {elementValue.deliverySpotTime && elementValue.deliverySpotTime.time}
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {geti18nText('meal.order.edit.pickupSpot')}:{' '}
                    <div style={{ fontWeight: 'bold' }}>
                        {elementValue.restaurant && elementValue.restaurant.name}
                        <br />
                        {elementValue.deliverySpotTime && elementValue.deliverySpotTime.time}
                    </div>
                </>
            );
        }
    };

    const gridStyleOne: any = {
        width: '100%',
        cursor: 'pointer',
        display: 'inline-block',
    };

    const gridStyleTwo: any = {
        width: '50%',
        cursor: 'pointer',
        display: 'inline-block',
    };

    const changeStatus = (orderId: any, status: any) => {
        if (orderId && status) {
            setIsLoading(true);
            setLoadingId(orderId);
            NyRequestResolver.requestPut(CONSTANTS_REQ.MEAL_ORDER.CHANGE_STATUS + '/' + orderId, undefined, {
                id: orderId,
                status: status,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefreshView((refreshView: any) => refreshView + 1);
                    okNotification(geti18nText('meal.order.change.status.success'));
                } else {
                    setIsLoading(false);
                    if (result.data && result.data.error) {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification();
                    }
                }
            });
        }
    };

    const getActionButtons = (orderId: any, orderDate: any, status: any, billFileId: any) => {
        const order = moment(orderDate).format('DD.MM.YYYY');
        const now = moment().format('DD.MM.YYYY');
        return (
            <>
                {moment(order, 'DD.MM.YYYY').isSameOrAfter(moment(now, 'DD.MM.YYYY')) && (
                    <>
                        {(status == mealOrderStatus.UNCONFIRMED || status == mealOrderStatus.CANCELED) &&
                        canEntryOrConfirm(moment(orderDate)) &&
                        canCreate ? (
                            <NyModalConfirm
                                title={geti18nText('meals.order.overview.confirm.confirm')}
                                onConfirm={() => changeStatus(orderId, mealOrderStatus.CONFIRMED)}
                            >
                                <Button
                                    type="primary"
                                    loading={isLoading && loadingId == orderId}
                                    className="green-button"
                                    style={billFileId ? gridStyleTwo : gridStyleOne}
                                >
                                    {geti18nText('meals.order.overview.confirm')}
                                </Button>
                            </NyModalConfirm>
                        ) : status == mealOrderStatus.CONFIRMED && canCancelOrder(orderDate) && canCreate ? (
                            <NyModalConfirm
                                title={geti18nText('meals.order.overview.cancel.confirm')}
                                onConfirm={() => changeStatus(orderId, mealOrderStatus.CANCELED)}
                            >
                                <Button
                                    type="primary"
                                    loading={isLoading && loadingId == orderId}
                                    danger
                                    style={billFileId ? gridStyleTwo : gridStyleOne}
                                >
                                    {geti18nText('meals.order.overview.cancel')}
                                </Button>
                            </NyModalConfirm>
                        ) : (
                            <></>
                        )}
                    </>
                )}
                {billFileId && (
                    <Tooltip
                        title={geti18nText('meal.delivery.table.column.deliverySpot.bill')}
                        style={{ textAlign: 'center' }}
                    >
                        <Button
                            style={
                                (((status == mealOrderStatus.UNCONFIRMED || status == mealOrderStatus.CANCELED) &&
                                    canEntryOrConfirm(moment(orderDate)) &&
                                    canCreate) ||
                                    (status == mealOrderStatus.CONFIRMED && canCancelOrder(orderDate) && canCreate)) &&
                                moment(order, 'DD.MM.YYYY').isSameOrAfter(moment(now, 'DD.MM.YYYY'))
                                    ? gridStyleTwo
                                    : gridStyleOne
                            }
                            icon={<FilePdfOutlined />}
                            onClick={() => {
                                fileDownload(
                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + billFileId,
                                    null,
                                    geti18nText('meal.delivery.table.column.deliverySpot.bill')
                                );
                            }}
                        >
                            {geti18nText('meal.delivery.table.column.deliverySpot.bill')}
                        </Button>
                    </Tooltip>
                )}
            </>
        );
    };

    const getEntryTimeCountdown = (elementValue: any) => {
        if (showEntryCountdown(elementValue.tempId)) {
            const hours = mealOrderEntryTime.substring(0, mealOrderEntryTime.indexOf(':')) ?? 0;
            const minutes = mealOrderEntryTime.substring(mealOrderEntryTime.indexOf(':') + 1) ?? 0;
            let today = moment().hours(hours).minutes(minutes).format('YYYY-MM-DD HH:mm').toString();
            const deadline = Date.parse(today);
            return (
                <Row gutter={24}>
                    <Col span={24}>
                        <Countdown
                            style={{ textAlign: 'center' }}
                            title={'(' + geti18nText('meals.overview.mealOrderTodayEntryAllowed.countdown') + ')'}
                            value={deadline}
                        />
                    </Col>
                </Row>
            );
        } else {
            return <></>;
        }
    };

    const getTimeCountdown = (elementValue: any) => {
        if (
            elementValue.status == mealOrderStatus.CONFIRMED ||
            (elementValue.status == mealOrderStatus.CANCELED && canCreateAdmin) ||
            (elementValue.status == mealOrderStatus.UNCONFIRMED && canCreateAdmin)
        ) {
            if (showCancelCountdown(elementValue)) {
                const hours = mealOrderCancelTime.substring(0, mealOrderCancelTime.indexOf(':')) ?? 0;
                const minutes = mealOrderCancelTime.substring(mealOrderCancelTime.indexOf(':') + 1) ?? 0;
                let today = moment().hours(hours).minutes(minutes).format('YYYY-MM-DD HH:mm').toString();
                const deadline = Date.parse(today);
                return (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Countdown
                                style={{ textAlign: 'center' }}
                                title={
                                    elementValue.status == mealOrderStatus.CONFIRMED
                                        ? '(' + geti18nText('meals.overview.mealOrderCancelTime.countdown') + ')'
                                        : '(' + geti18nText('meals.overview.mealOrderConfirmTime.countdown') + ')'
                                }
                                value={deadline}
                            />
                        </Col>
                    </Row>
                );
            } else {
                return <></>;
            }
        } else {
            if (showEntryCountdown(elementValue.orderDate)) {
                const hours = mealOrderEntryTime.substring(0, mealOrderEntryTime.indexOf(':')) ?? 0;
                const minutes = mealOrderEntryTime.substring(mealOrderEntryTime.indexOf(':') + 1) ?? 0;
                let today = moment().hours(hours).minutes(minutes).format('YYYY-MM-DD HH:mm').toString();
                const deadline = Date.parse(today);
                return (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Countdown
                                style={{ textAlign: 'center' }}
                                title={'(' + geti18nText('meals.overview.mealOrderTodayConfirmAllowed.countdown') + ')'}
                                value={deadline}
                            />
                        </Col>
                    </Row>
                );
            } else {
                return <></>;
            }
        }
    };

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 7 },
            { field: 'settingGroup', condition: 'equals', value: 'MEALS_ORDER' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'MEALS_ORDER_CANCELLATION_TIME') {
                                setMealsOrderCancellationTime(element.value);
                            }
                            if (element.settingKey === 'MEALS_ORDER_ENTRY_TIME') {
                                setMealsOrderApprovalTime(element.value);
                            }
                            if (element.settingKey === 'MEALS_ORDER_TODAY_ENTRY_ALLOW') {
                                setCheckBox(element.value);
                            }
                        });
                    }
                }
            }
        );
    };

    const resolveIsMealsUser = () => {
        return MealsRights.isOnlyMealsUser();
    };

    const resolveIsMealsAdmin = () => {
        return MealsRights.isMealsAdmin();
    };

    const checkPreviousWorkday = (date: any) => {
        date = moment(date).subtract(1, 'days');
        while (moment(date)) {
            if (!checkIsHoliday(date, holidays) && !checkIsWeekend(moment(date))) {
                return moment(date).format('DD.MM.YYYY.');
            }
            date = moment(date).subtract(1, 'days');
        }
    };

    const getOrderCancellationTime = (orderDate: any, status: any) => {
        const order = moment(orderDate).format('DD.MM.YYYY.');
        const orderBefore = moment(orderDate).subtract(1, 'days').format('DD.MM.YYYY.');
        const now = moment().format('DD.MM.YYYY.');
        return (
            <>
                <Text
                    style={{
                        fontSize: '12px',
                        whiteSpace: 'pre-line',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {status == mealOrderStatus.CONFIRMED
                        ? geti18nText('meals.overview.mealOrderCancellation')
                        : status == mealOrderStatus.UNCONFIRMED
                        ? geti18nText('meals.overview.mealOrderConfirmation')
                        : status == mealOrderStatus.CANCELED
                        ? geti18nText('meals.overview.mealOrderConfirmation')
                        : null}
                </Text>
                <Text
                    style={{
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        lineHeight: '20px',
                    }}
                >
                    {resolveIsMealsAdmin()
                        ? order + ' ' + mealsOrderCancellationTime
                        : resolveIsMealsUser() && checkBox == 1 && status == mealOrderStatus.CONFIRMED
                        ? order + ' ' + mealsOrderCancellationTime
                        : resolveIsMealsUser() && checkBox == 1
                        ? order + ' ' + mealsOrderApprovalTime
                        : resolveIsMealsUser() && checkBox == 0 && status == mealOrderStatus.CONFIRMED
                        ? order + ' ' + mealsOrderCancellationTime
                        : resolveIsMealsUser() && checkBox == 0 && status == mealOrderStatus.CANCELED
                        ? checkPreviousWorkday(orderDate) + ' ' + mealsOrderApprovalTime
                        : resolveIsMealsUser() && checkBox == 0 && status == mealOrderStatus.UNCONFIRMED
                        ? orderBefore + ' ' + mealsOrderApprovalTime
                        : resolveIsMealsUser() && checkBox == 0
                        ? now + ' ' + mealsOrderApprovalTime
                        : ' '}
                </Text>
            </>
        );
    };

    const resolveRenderContentType = (elementValue: any) => {
        switch (elementValue.type) {
            case 'addNewMealOrder':
                return (
                    <Button
                        icon={<PlusOutlined />}
                        disabled={!canCreate}
                        style={{ width: '100%', height: '551px' }}
                        onClick={() => {
                            setSelectedDateMeal(elementValue.id);
                            setOrderMealModal(true);
                        }}
                    >
                        <br />
                        {geti18nText('meal.order.add')}
                        <br />
                        {getEntryTimeCountdown(elementValue)}
                    </Button>
                );
            case 'mealOrder':
                return (
                    <div
                        style={{
                            height: '551px',
                            fontSize: '14px',
                            overflowX: 'hidden',
                            overflowY: 'hidden',
                            padding: '5px',
                            width: '100%',
                        }}
                    >
                        <Link onClick={() => onCLick(elementValue.id, elementValue.type, true)}>
                            <div style={{ height: '441px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', height: '20px' }}>
                                    <Text>{getMealOrderStatusIcon(elementValue.status)}</Text>
                                </div>
                                <div style={{ height: '170px', overflowY: 'auto' }}>
                                    <Text>
                                        {canCreateAdmin && (
                                            <>
                                                {geti18nText('meal.menu.edit.restaurant')}:{' '}
                                                <p
                                                    style={{
                                                        marginBottom: '0px',
                                                        whiteSpace: 'pre-line',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {elementValue.restaurant && elementValue.restaurant.name}
                                                </p>
                                            </>
                                        )}

                                        <p style={{ marginBottom: '0px', whiteSpace: 'pre-line' }}>
                                            {getDeliveryInfo(elementValue)}
                                        </p>
                                    </Text>
                                </div>
                                <div>
                                    <Text>
                                        <OrderItems orderId={elementValue.id} mealSyncTypeHNB={mealSyncTypeHNB} />
                                    </Text>
                                </div>
                            </div>
                        </Link>
                        <div style={{ height: '110px' }}>
                            <Link onClick={() => onCLick(elementValue.id, elementValue.type, true)}>
                                <div style={{ height: '70px' }}>
                                    {moment(elementValue.orderDate).isAfter(moment())
                                        ? getOrderCancellationTime(elementValue.orderDate, elementValue.status)
                                        : getTimeCountdown(elementValue)}
                                </div>
                            </Link>

                            <div style={{ height: '40px' }}>
                                <Text>
                                    {getActionButtons(
                                        elementValue.id,
                                        elementValue.orderDate,
                                        elementValue.status,
                                        elementValue.billFileId
                                    )}
                                </Text>
                            </div>
                        </div>
                    </div>
                );
            case 'holiday':
                return (
                    <div style={{ width: '100%', height: '551px', textAlign: 'center' }}>
                        <Tooltip placement="topLeft" title={elementValue.name}>
                            <Tag
                                icon={<BulbOutlined />}
                                style={{
                                    fontSize: '12px',
                                    margin: '10px',
                                    padding: '15 5 15 5',
                                    textAlign: 'center',
                                    maxWidth: '90%',
                                    width: 'fit-content',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {elementValue.name}
                            </Tag>
                        </Tooltip>
                    </div>
                );
            default:
                return <div></div>;
        }
    };

    function renderEventContent(eventInfo: any) {
        let elementValue: any = null;
        if (eventInfo && eventInfo.event && eventInfo.event._def && eventInfo.event._def.extendedProps) {
            elementValue = { ...eventInfo.event._def.extendedProps };
            if (!elementValue.id && elementValue.tempId) {
                elementValue.id = elementValue.tempId;
            }
            if (elementValue) {
                return resolveRenderContentType(elementValue);
            }
        }
    }

    return (
        <React.Fragment>
            <div style={{ fontSize: '10px' }}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView={initialView}
                    events={[...eventsMeals]}
                    locale={currentLang}
                    headerToolbar={
                        showHeaderToolbar
                            ? {
                                  left: 'prev,next today',
                                  center: 'title',
                                  right: 'dayGridMonth,dayGridWeek,listMonth',
                              }
                            : {
                                  left: 'prev,next today',
                                  center: 'title',
                                  right: 'dayGridWeek',
                              }
                    }
                    buttonText={{
                        today: geti18nText('app.default.filter.range.today'),
                        month: geti18nText('app.default.filter.range.month'),
                        week: geti18nText('app.default.filter.range.week'),
                        day: geti18nText('app.default.filter.range.day'),
                        list: geti18nText('app.default.list'),
                    }}
                    defaultAllDay={defaultAllDay}
                    firstDay={firstDay}
                    navLinks={navLinks}
                    stickyHeaderDates={stickyHeaderDates}
                    showNonCurrentDates={showNonCurrentDates}
                    height={height}
                    weekends={weekends}
                    editable={editable}
                    selectable={selectable}
                    expandRows={expandRows}
                    eventContent={renderEventContent}
                    datesSet={(dateInfo) => {
                        setStartDate(dateInfo.start);
                        setEndDate(dateInfo.end);
                    }}
                />
            </div>
            {orderMealModal && (
                <MealOrderWizard
                    visible={orderMealModal}
                    setVisible={setOrderMealModal}
                    date={selectedDateMeal}
                    refresh={refreshView}
                    setRefresh={setRefreshView}
                    value={mealOrder}
                    setValue={setMealOrder}
                    canCancelOrder={canCancelOrder}
                    mealSubventionValue={mealSubventionValue}
                    allowOrdersToday={allowOrdersToday}
                    mealOrderEntryTime={mealOrderEntryTime}
                    mealOrderCancelTime={mealOrderCancelTime}
                    isAdminForm={false}
                    theme={theme}
                />
            )}
        </React.Fragment>
    );
};

export default MealsNyFullCalendar;
