import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArray, getEnumBooleanArray } from '../../../../utils/Utils';
import TravelExpenseEdit from './edit';

const TravelExpenseIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    showAllColumns = true,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('travel_order/codebooks/travelExpense');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canExportCSV = () => {
        return TravelWarrantRights.canCsvExportTravelWarrantCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        ...[
            {
                title: geti18nText('travelExpense.table.column.id'),
                dataIndex: 'id',
                width: showAllColumns ? '4%' : '7%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('travelExpense.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(showAllColumns === true
            ? [
                  {
                      title: geti18nText('travelExpense.table.column.reimbursed'),
                      dataIndex: 'reimbursed',
                      sorter: (a: any, b: any) => {},
                      render: (text: any, record: { reimbursed: any }) => {
                          if (record.reimbursed) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
                  },
                  {
                      title: geti18nText('travelExpense.table.column.includeInJoppd'),
                      dataIndex: 'includeInJoppd',
                      width: '7%',
                      sorter: (a: any, b: any) => {},
                      render: (text: any, record: { includeInJoppd: any }) => {
                          if (record.includeInJoppd) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
                  },
                  {
                      title: geti18nText('travelExpense.table.column.joppdTaxFreeCode'),
                      dataIndex: 'joppdTaxFreeCode',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
                  {
                      title: geti18nText('travelExpense.table.column.joppdPaymentCode'),
                      dataIndex: 'joppdPaymentCode',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
                  {
                      title: geti18nText('travelExpense.table.column.bookExpense'),
                      dataIndex: 'bookExpense',
                      render: (text: any, record: any) => {
                          if (record.bookExpense !== undefined) {
                              return geti18nText('app.enum.BOOK_OPTIONS.' + record.bookExpense);
                          }
                      },
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchCheckbox(getEnumArray('BOOK_OPTIONS')),
                  },
                  {
                      title: geti18nText('travelExpense.table.column.sort'),
                      dataIndex: 'sort',
                      width: showAllColumns ? '7%' : '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
                  {
                      title: geti18nText('travelExpense.table.column.active'),
                      dataIndex: 'active',
                      width: showAllColumns ? '7%' : '10%',
                      render: (text: any, record: { active: any }) => {
                          if (record.active) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                      defaultFilteredValue: table.setDefaultFilterValue(),
                  },
              ]
            : []),
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-expance-table"
            url={CONSTANTS_REQ.TRAVEL_EXPENSE.LIST}
            addNewButtonText={geti18nText('travelExpense.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={TravelExpenseEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.travelExpense')}
            colCSVCustomization={csvColumnCustomisation()}
            readonly={readonly}
            hideButtons={readonly}
            headerTitle={geti18nText('menu.travelExpense')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            reimbursed: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            includeInJoppd: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            bookExpense: (value: any) => {
                return value != undefined && geti18nText('app.enum.BOOK_OPTIONS.' + value);
            },
        },
    ];
};

export default TravelExpenseIndex;
