import { useRef, useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { geti18nText, NyDataEdit, NyInputNumber, NyDatePicker, NySearchField, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import moment from 'moment';
import { RootState } from '../../../../rootReducer';
import { useSelector } from 'react-redux';
import { formatJoppdCodeSearch, setJoppdCodeDefaultFilterValue } from '../../../../utils/Utils';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { PayrollRights } from '../../../../rights/payrollRights';

const PayrollAdditionEdit = (props: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [editHeader, setEditHeader] = useState(geti18nText('payrollAddition.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '15.1.');
        }
        if (dataForm.validFrom) dataForm.validFrom = moment(dataForm.validFrom);
        if (dataForm.validTo) dataForm.validTo = moment(dataForm.validTo);
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('payrollAddition.edit.title') + ' - ' + dataForm.name);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payrollAddition.edit.new'));
    };

    const setDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '15.1.');
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PAYROLL_ADDITION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = moment(values.validFrom).format('yyyy-MM-DD HH:mm');
                if (values.validTo) values.validTo = moment(values.validTo).format('yyyy-MM-DD HH:mm');
                if (values.joppdCode) values.joppdCode = values.joppdCode.code;
                return values;
            }}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('payrollAddition.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('payrollAddition.edit.value')}
                        name="value"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            leftToRight={numbersWriting}
                            isDecimal={true}
                            style={{ width: '100%' }}
                            nyTestId="value"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('payrollAddition.edit.joppdCode')} name="joppdCode">
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={setDefaultFilterValue}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppdCode"
                            SearchPopupComponent={<JoppdCodeIndex defaultFilteredValue={setDefaultFilterValue} />}
                            itemName="code"
                            addNewItem={true}
                            order="code"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('payrollAddition.edit.validFrom')}
                        name="validFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker style={{ width: '100%' }} nyTestId="valid-from" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('payrollAddition.edit.validTo')} name="validTo">
                        <NyDatePicker style={{ width: '100%' }} nyTestId="valid-to" />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PayrollAdditionEdit;
