import { useState } from 'react';
import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyDatePicker, NyInputNumber, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, getDateFormat, okNotification } from '../../../../utils/Utils';

const GenerateJoppdInsuranceInterestModal = ({ calculationId, modalVisible, setModalVisible }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.calculationId = calculationId;
                if (values.date) {
                    values.date = getDateFormat(values.date);
                }
                NyRequestResolver.requestPost(
                    CONSTANTS_REQ.JOPPD.GENERATE_JOPPD_INSURANCE_INTEREST,
                    undefined,
                    values
                ).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.OK) {
                        okNotification();
                        setModalVisible(false);
                        form.resetFields();
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('payment.calculation.generateJoppdInsuranceInterest.title')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    confirmLoading={loading}
                    width={400}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generateJoppdInsuranceInterest.amount')}
                                    name={'amount'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyInputNumber isDecimal={true} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generateJoppdInsuranceInterest.date')}
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default GenerateJoppdInsuranceInterestModal;
