import {
    AuditOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    InteractionOutlined,
    LockOutlined,
    PlusSquareOutlined,
    ReloadOutlined,
    UnlockOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Dropdown, Form, Input, Menu, message, Modal, Row, Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { ReactText, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NyErrorLogModal from '../../../../components/error-log-modal';
import NyNoteModal from '../../../../components/note-modal';
import NyReportButton from '../../../../components/report-button';
import { selectedItemSet } from '../../../../slices/selectedItemSlice';
// import { addTab /* , removeTab, updateTabTitle */ } from '../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNodeNotification,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getWorkingDays,
    infoNotification,
    okNotification,
    setDateFormat,
} from '../../../../utils/Utils';
import EmployeePaymentIndex from '../employee-payment';
import EmployeePaymentAddition from '../employee-payment/EmployeePaymentAddition';
import AddEmployee from './AddEmployee';
import GenerateJoppdInsuranceInterestModal from './GenerateJoppdInsuranceInterestModal';
import PaymentCalculationSettings from './PaymentCalculationSettings';
import useEnum from '../../../../hooks/useEnum';
import { updateHistoryI18n } from '../../../../slices/historySlice';
import { PayrollRights } from '../../../../rights/payrollRights';

const { Panel } = Collapse;

const PaymentCalculationEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [employeePaymentIndexRefresh, setEmployeePaymentIndexRefresh] = useState(0);

    const { id } = useParams<any>();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [addEmployeeForm] = Form.useForm();
    const [paymentOrderForm] = Form.useForm();
    const history = useHistory();
    const location = useLocation();

    const [topCollapseActiveKeys, setTopCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['4']);
    const [bottomCollapseActiveKeys, setBottomCollapseActiveKeys] = useState<any>([]);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [modalAddAdditionVisible, setModalAddAdditionVisible] = useState(false);
    const [addPaymentAdditionsData, setAddPaymentAdditionsData] = useState([]);
    const [modalAddEmployeeVisible, setModalAddEmployeeVisible] = useState(false);
    const [modalPaymentOrderVisible, setModalPaymentOrderVisible] = useState(false);
    const [errorLogModalData, setErrorLogModalData] = useState([]);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');

    const [isCreated, setIsCreated] = useState(false);
    const [fromDate, setFromDate] = useState<any>(undefined);
    const [toDate, setToDate] = useState<any>(undefined);
    const [note, setNote] = useState<any>(undefined);
    const [workHours, setWorkHours] = useState<any>(undefined);
    const [calcSettings, setCalcSettings] = useState<any>([]);
    const [calcSettingsBrut, setCalcSettingsBrut] = useState<any>([]);
    const [lock, setLock] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [hasSelected, setHasSelected] = useState(false);
    const [allEmployee, setAllEmployee] = useState(true);
    const [modalGenerateJoppdInsuranceInterestVisible, setModalGenerateJoppdInsuranceInterestVisible] =
        useState<any>(false);
    const [title, setTitle] = useState('');
    const [createdId, setCreatedId] = useState('create');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const focusInput = useRef<any>(null);
    useEffect(function () {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    useEffect(() => {
        dispatch(selectedItemSet({ item: dataForm, refreshItem: 0, employeeId: undefined }));
    }, [dataForm]);

    useEffect(() => {
        calculateMonthWorkingHours();
    }, [fromDate, toDate]);

    useEffect(() => {
        setRefresh((val) => val + 1);
        setCalcSettings([]);
        if (id && id !== 'create') {
            setIsCreated(true);
            setBottomCollapseActiveKeys(['2']);
        } else {
            setWorkHours(undefined);
            getAppSettings(null, 'CALCULATION_PARAMETERS');
            getAppSettings(null, 'PAYROLL_AUTOMATIC_CALC');
            setIsCreated(false);
            setBottomCollapseActiveKeys([]);
            setLock(false);
            form.resetFields();
        }
    }, [id]);

    useEffect(() => {
        if (allEmployee) {
            form.setFieldsValue({ employeeGroup: [], employee: [] });
        }
    }, [allEmployee]);

    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    const setDefaultFilterValue = (type: string = 'CALCULATION_PARAMETERS') => {
        return [
            { field: 'settingModule', condition: 'equals', value: 3 },
            { field: 'settingGroup', condition: 'equals', value: type },
        ];
    };

    const getAppSettings = (data: any = null, type: string) => {
        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue(type)));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (data) {
                            let newCalcSettings = [...result.data];
                            newCalcSettings.forEach((element: any) => {
                                if (data.hasOwnProperty(element.settingKey)) {
                                    element.value = data[element.settingKey];
                                }
                            });
                            if (type === 'CALCULATION_PARAMETERS') {
                                setCalcSettings(newCalcSettings);
                            } else if (type === 'PAYROLL_AUTOMATIC_CALC') {
                                setCalcSettingsBrut(newCalcSettings);
                            }
                        } else {
                            if (type === 'CALCULATION_PARAMETERS') {
                                setCalcSettings(result.data);
                            } else if (type === 'PAYROLL_AUTOMATIC_CALC') {
                                setCalcSettingsBrut(result.data);
                            }
                        }
                    }
                }
            }
        );
    };

    const reloadCalcSettings = (type: string) => (
        <Tooltip
            title={geti18nText(
                type === 'CALCULATION_PARAMETERS'
                    ? 'payment.calculation.reloadCalcSettings'
                    : 'payment.calculation.reloadCalcSettingsBrut'
            )}
            placement="topRight"
        >
            <ReloadOutlined
                className="ny-note-modal-icon"
                onClick={(event: any) => {
                    event.stopPropagation();
                    getAppSettings(null, type);
                }}
            />
        </Tooltip>
    );

    /// FORM DATA
    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setDataForm(dataForm.id);

        if (dataForm.name) setTitle(dataForm.name);

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.periodFrom) {
            dataForm.periodFrom = setDateFormat(dataForm.periodFrom);
            setFromDate(setDateFormat(dataForm.periodFrom, 'yyyy-MM-DD'));
        }
        if (dataForm.periodTo) {
            dataForm.periodTo = setDateFormat(dataForm.periodTo);
            setToDate(setDateFormat(dataForm.periodTo, 'yyyy-MM-DD'));
        }
        if (dataForm.payDate) dataForm.payDate = setDateFormat(dataForm.payDate);
        if (dataForm.partialPayDate) dataForm.partialPayDate = setDateFormat(dataForm.partialPayDate);

        if (dataForm.employeeGroup) {
            let employeeGroup = dataForm.employeeGroup.map((val: any) => {
                return { key: val.id, id: val.id, label: val.name };
            });
            dataForm.employeeGroup = employeeGroup;
            setAllEmployee(false);
        } else {
            dataForm.employeeGroup = [];
        }

        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
            setAllEmployee(false);
        } else {
            dataForm.employee = [];
        }

        if (dataForm.workHours) setWorkHours(dataForm.workHours);

        if (dataForm.calcSettings) {
            let calcSettings = JSON.parse(dataForm.calcSettings);
            getAppSettings(calcSettings['CALCULATION_PARAMETERS'], 'CALCULATION_PARAMETERS');
            getAppSettings(calcSettings['PAYROLL_AUTOMATIC_CALC'], 'PAYROLL_AUTOMATIC_CALC');
        } else {
            setCalcSettings([]);
            setCalcSettingsBrut([]);
        }

        setLock(dataForm.locked ? true : false);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        setBottomCollapseActiveKeys(['2']);
        setRightCollapseActiveKeys([]);
    }

    const onModalClose = () => {
        form.resetFields();
    };
    /// END FORM DATA

    /// COLLAPSE
    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setTopCollapseActiveKeys([]);
            setBottomCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setTopCollapseActiveKeys(['1']);
            setBottomCollapseActiveKeys(isCreated ? ['2'] : []);
            setRightCollapseActiveKeys(isCreated ? ['3', '5'] : ['3', '4', '5']);
        }

        setCollapseAll(!collapseAll);
    };

    const generateJOPPD = () => {
        if (id !== 'create') {
            let parms: any = {};
            parms['paymentCalculationId'] = Number(id);
            const key = 'joppd';
            message.loading({ content: geti18nText('payment.calculation.generateJOPPD.loading'), key });
            NyRequestResolver.requestPost(
                CONSTANTS_REQ.JOPPD.GENERATE_JOPPD_PAYMENT_CALCULATION,
                undefined,
                parms
            ).then((result: any) => {
                message.destroy(key);
                if (result.status === RESPONSE.OK) {
                    okNotification();
                } else if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {canCreate() && !lock && id !== 'create' && (
                    <>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('app.default.lock.confirm')}
                                onConfirm={() => {
                                    lockUnlockPaymentCalculation('lock');
                                }}
                            >
                                <Button block icon={<LockOutlined />}>
                                    {geti18nText('app.default.lock')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                block
                                icon={<UserAddOutlined />}
                                onClick={() => {
                                    setAllEmployee(false);
                                    setModalAddEmployeeVisible(true);
                                }}
                            >
                                {geti18nText(
                                    props.type === 1
                                        ? 'payment.calculation.addEmployees'
                                        : 'payment.calculation.addEmployee'
                                )}
                            </Button>
                        </div>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('app.default.destructive.confirm')}
                                onConfirm={() => {
                                    payrollDeleteAll();
                                }}
                            >
                                <Button block icon={<DeleteOutlined />} disabled={!hasSelected}>
                                    {geti18nText('payment.calculation.deleteAll')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                        {props.type === 1 && (
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    block
                                    icon={<PlusSquareOutlined />}
                                    disabled={!hasSelected}
                                    onClick={() => setModalAddAdditionVisible(true)}
                                >
                                    {geti18nText('payment.calculation.addAddition')}
                                </Button>
                            </div>
                        )}
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                block
                                icon={<InteractionOutlined />}
                                onClick={() => {
                                    setModalGenerateJoppdInsuranceInterestVisible(true);
                                }}
                            >
                                {geti18nText('payment.calculation.generateJoppdInsuranceInterest.title')}
                            </Button>
                        </div>
                    </>
                )}
                {canCreate() && lock && id !== 'create' && (
                    <React.Fragment>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyModalConfirm
                                title={geti18nText('app.default.unlock.confirm')}
                                onConfirm={() => {
                                    lockUnlockPaymentCalculation('unlock');
                                }}
                            >
                                <Button block icon={<UnlockOutlined />}>
                                    {geti18nText('app.default.unlock')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    </React.Fragment>
                )}
                {id !== 'create' && (
                    <React.Fragment>
                        {canCreate() && (
                            <React.Fragment>
                                <div style={{ display: 'block', margin: '5px' }}>
                                    <Button
                                        block
                                        icon={<AuditOutlined />}
                                        onClick={() => {
                                            setModalPaymentOrderVisible(true);
                                        }}
                                    >
                                        {geti18nText('payment.calculation.generatePaymentOrder')}
                                    </Button>
                                </div>

                                <div style={{ display: 'block', margin: '5px' }}>
                                    <Button block icon={<InteractionOutlined />} onClick={generateJOPPD}>
                                        {geti18nText('payment.calculation.generateJOPPD')}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}

                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.PAYROLL_IP_SUMMARY_REPORT}
                                customParms={{ calculationId: Number(id) }}
                                subreportType={'PAYROLL_IP_SUMMARY_FORM'}
                                buttoni18nText={'payment.calculation.getPayrollIpSummary'}
                                fileName={geti18nText(
                                    'app.enum.SUBREPORT_TYPE.' + EnSubreportType.PAYROLL_IP_SUMMARY_FORM
                                )}
                            />
                        </div>

                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.PAYROLL_IP_SEPCIFICATION_REPORT}
                                customParms={{ calculationId: Number(id) }}
                                subreportType={'PAYROLL_IP_SEPCIFICATION_REPORT_FORM'}
                                buttoni18nText={'payment.calculation.report.ipSpecification'}
                                fileName={geti18nText(
                                    'app.enum.SUBREPORT_TYPE.' + EnSubreportType.PAYROLL_IP_SPECIFICATION
                                )}
                            />
                        </div>

                        <div style={{ display: 'block', margin: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.IP1_REPORTS}
                                customParms={{ employeePaymentIds: selectedValues }}
                                subreportType={'IP1_FORM'}
                                buttoni18nText={'report.ip1Download'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.IP1_FORM)}
                                disabled={!hasSelected}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']} disabled={dataForm ? false : true}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        </>
    );
    /// END COLLAPSE

    const lockUnlockPaymentCalculation = (type: string) => {
        if (id !== 'create') {
            let url =
                type === 'lock' ? CONSTANTS_REQ.PAYMENT_CALCULATION.LOCK : CONSTANTS_REQ.PAYMENT_CALCULATION.UNLOCK;
            NyRequestResolver.requestPut(url + '/' + id, undefined, {}).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    //goBack();
                    setRefresh((val) => val + 1);
                } else if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const payrollDeleteAll = () => {
        if (id !== 'create') {
            NyRequestResolver.requestPut(CONSTANTS_REQ.PAYMENT_CALCULATION.PAYROLL_DELETE_ALL + '/' + id, undefined, {
                employeePayments: selectedValues,
            }).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    setEmployeePaymentIndexRefresh((val) => val + 1);
                } else if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const payrollSaveAddition = () => {
        if (id !== 'create' && addPaymentAdditionsData) {
            setConfirmLoading(true);
            let additions = addPaymentAdditionsData.filter(
                (val: any) => val.payrollAddition && val.payrollAddition.id > 0 && val.amount > 0
            );
            if (additions.length > 0) {
                NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_CALCULATION.ADD_ADDITIONS, undefined, {
                    paymentCalculationId: Number(id),
                    employeePayments: selectedValues,
                    employeePaymentAdditions: additions,
                }).then((result: any) => {
                    setConfirmLoading(false);
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data.message) {
                            infoNotification(geti18nText(result.data.message));
                        } else {
                            setEmployeePaymentIndexRefresh((val) => val + 1);
                            okNotification();
                        }
                    } else {
                        customErrorNotification(result);
                    }
                });
            } else {
                errorNotification(geti18nText('payment.calculation.edit.saveAdditionError'));
                setConfirmLoading(false);
            }
        } else {
            errorNotification(geti18nText('payment.calculation.edit.saveAdditionError'));
            setConfirmLoading(false);
        }
    };

    const payrollAddEmployees = (data: any) => {
        if (
            id !== 'create' &&
            (allEmployee ||
                (data.employee && ((props.type === 1 && data.employee.length > 0) || data.employee.id)) ||
                (data.employeeGroup && data.employeeGroup.length > 0))
        ) {
            let parms: any = {};
            parms['paymentCalculation'] = { id: Number(id) };
            parms.allEmployee = allEmployee ?? false;
            if (props.type === 1) {
                parms['employeeIds'] = data.employee ? data.employee.map((val: any) => val.id) : null;
                parms['employeeGroupIds'] = data.employeeGroup ? data.employeeGroup.map((val: any) => val.id) : null;
            } else if (data.employee) {
                parms['employeeIds'] = [data.employee.id];
            }
            NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_CALCULATION.ADD_EMPLOYEES, undefined, parms).then(
                (result: any) => {
                    setConfirmLoading(false);
                    if (result.status === RESPONSE.OK) {
                        setModalAddEmployeeVisible(false);
                        addEmployeeForm.resetFields();
                        if (result.data && result.data.message) {
                            infoNotification(geti18nText(result.data.message));
                        } else {
                            setEmployeePaymentIndexRefresh((val) => val + 1);
                            okNotification();
                        }
                    } else {
                        customErrorNotification(result);
                    }
                }
            );
        } else {
            errorNotification(geti18nText('payment.calculation.edit.addEmployeeError'));
            setConfirmLoading(false);
        }
    };

    const generatePaymentOrders = (data: any) => {
        if (id !== 'create') {
            let parms: any = {};
            parms['calculationId'] = Number(id);
            parms['calcNet'] = data.calcNet;
            parms['calcSickLeave'] = data.calcSickLeave;
            parms['calcInsurance'] = data.calcInsurance;
            parms['calcDeduction'] = data.calcDeduction;
            parms['calcTax'] = data.calcTax;
            parms['lang'] = NyUtils.getSelectedLanguage();
            NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_ORDER.GENERATE_PAYMENT_ORDERS, undefined, parms).then(
                (result: any) => {
                    setConfirmLoading(false);
                    if (result.status === RESPONSE.CREATED) {
                        okNotification();
                        paymentOrderForm.resetFields();
                        setModalPaymentOrderVisible(false);
                    } else {
                        if (result.data && result.data.length > 0) {
                            errorNodeNotification(
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setErrorLogModalData(result.data);
                                        setErrorLogModalVisible(true);
                                        setErrorLogModalTitle(
                                            geti18nText('payment.calculation.error.payment.order.details.modal')
                                        );
                                    }}
                                >
                                    {geti18nText('payment.calculation.error.details')}
                                </Button>
                            );
                        } else {
                            errorNotification(geti18nText(result.data.error));
                        }
                    }
                }
            );
        } else {
            setConfirmLoading(false);
        }
    };

    const disabledDateTo = (current: any) => {
        if (fromDate) {
            return current.isBefore(fromDate.clone()) || current > fromDate.clone().endOf('month');
        } else {
            return current;
        }
    };

    const disabledPayDay = (current: any) => {
        if (toDate) {
            return current < toDate.clone();
        } else {
            return current;
        }
    };

    const calculateMonthWorkingHours = () => {
        if (fromDate && toDate) {
            let days = getWorkingDays(toDate.clone(), fromDate.clone());
            let workingHours = days * 8;
            form.setFieldsValue({ workHours: workingHours });
            setWorkHours(workingHours);
        }
    };

    const goBack = () => {
        history.goBack();
        // dispatch(removeTab(location.pathname));
    };

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
    };

    const customErrorNotification = (result: any) => {
        if (result.status === RESPONSE.OK) {
            if (result.data) {
                if (result.data.message) {
                    infoNotification(geti18nText(result.data.message));
                } else {
                    okNotification();
                }
                if (result.data.paymentCalculation && result.data.paymentCalculation.id) {
                    // dispatch(removeTab(location.pathname));
                    changeId(result.data.paymentCalculation.id);
                }
            }
            //goBack();
        } else if (result.data && result.data.error && result.data.error.length > 0) {
            let data = JSON.parse(result.data.error);
            errorNodeNotification(
                <Button
                    type="link"
                    onClick={() => {
                        setErrorLogModalData(data);
                        setErrorLogModalVisible(true);
                        setErrorLogModalTitle(geti18nText('payment.calculation.error.details.modal'));
                    }}
                >
                    {geti18nText('payment.calculation.error.details')}
                </Button>
            );
        } else if (result.data && result.data.error) {
            errorNotification(geti18nText(result.data.error));
        } else if (result.data && result.data instanceof String) {
            errorNotification(result.data);
        } else {
            errorNotification();
        }
    };

    const changeId = (returnedId: any) => {
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        setCreatedId(returnedId);
        let pathname = '';
        switch (props && props.type) {
            case 1:
                pathname = '/human/payroll/calculation/paymentCalculationIncome/';
                break;
            case 2:
                pathname = '/human/payroll/calculation/paymentCalculationSecondIncome/';
                break;
            case 3:
                pathname = '/human/payroll/calculation/paymentCalculationContract/';
                break;
            case 4:
                pathname = '/human/payroll/calculation/paymentCalculationRoyalties/';
                break;
        }
        if (pathname != '') pathname = pathname + returnedId;
        // dispatch(
        //     addTab({
        //         title: 'app.enum.PAYMENT_CALCULATION_TYPE.' + props && props.type,
        //         addedData: returnedId,
        //         path: pathname,
        //     })
        // );
        history.push(pathname);
    };

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.PAYMENT_CALCULATION.EDIT}
                setValues={setValues}
                width={1200}
                fetchWhenChange={refresh}
                form={form}
                goBack={goBack}
                paramsId={id}
                buttonsClassName="buttons-sticky"
                changeCollapseVisibility={changeCollapseVisibility}
                customFooterContent={getCustomFooterContent}
                checkIsFormChanged={true}
                {...props}
                normalize={(values: any) => {
                    values.calcType = props.type;
                    var calcSettingsObject = calcSettings.reduce(
                        (obj: any, item: any) => Object.assign(obj, { [item.settingKey]: item.value }),
                        {}
                    );
                    var calcSettingsBrutObject = calcSettingsBrut.reduce(
                        (obj: any, item: any) => Object.assign(obj, { [item.settingKey]: item.value }),
                        {}
                    );
                    values.calcSettings = JSON.stringify({
                        CALCULATION_PARAMETERS: calcSettingsObject,
                        PAYROLL_AUTOMATIC_CALC: calcSettingsBrutObject,
                    });
                    if (values.periodFrom) values.periodFrom = getDateFormat(values.periodFrom);
                    if (values.periodTo) values.periodTo = getDateFormat(values.periodTo);
                    if (values.payDate) values.payDate = getDateFormat(values.payDate);
                    if (values.partialPayDate) values.partialPayDate = getDateFormat(values.partialPayDate);
                    if (values.employee && values.employee.constructor.name === 'Number') {
                        values.employee = [values.employee];
                    }
                    if (values.employeeGroup && values.employeeGroup.constructor.name === 'Number') {
                        values.employeeGroup = [values.employeeGroup];
                    }
                    values.allEmployee = allEmployee ?? false;
                    return values;
                }}
                hideActivationButtons={lock || !canCreate()}
                hideSubmitButton={lock || !canCreate()}
                shortcuts={true}
                customErrorNotification={customErrorNotification}
            >
                <Row gutter={24} style={{ marginBottom: '12px' }}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Collapse
                            activeKey={topCollapseActiveKeys}
                            onChange={(key: any) => {
                                setTopCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('payment.calculation.edit.basicData')}
                                key="1"
                                //  extra={<NyNoteModal note={note} disabled={lock} />}
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('payment.calculation.edit.name')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input ref={focusInput} disabled={lock} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('payment.calculation.edit.periodFrom')}
                                            name="periodFrom"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                onChange={(date: any) => setFromDate(date)}
                                                disabled={lock}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('payment.calculation.edit.periodTo')}
                                            name="periodTo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                disabledDate={disabledDateTo}
                                                onChange={(date: any) => setToDate(date)}
                                                disabled={lock}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('payment.calculation.edit.payDay')}
                                            name="payDate"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                disabledDate={disabledPayDay}
                                                disabled={lock}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={geti18nText('payment.calculation.edit.partialPayDay')}
                                            name="partialPayDate"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                disabledDate={disabledPayDay}
                                                disabled={lock}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} offset={6}>
                                        <Form.Item
                                            label={geti18nText('payment.calculation.edit.hours')}
                                            name="workHours"
                                        >
                                            <h2
                                                style={{
                                                    marginBottom: '0px',
                                                    textAlign: 'right',
                                                    paddingRight: '30px',
                                                }}
                                            >
                                                {workHours ? workHours : ''}
                                            </h2>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Collapse
                            activeKey={rightCollapseActiveKeys}
                            onChange={(key: any) => {
                                setRightCollapseActiveKeys(key);
                            }}
                        >
                            {props.type === 1 && (
                                <Panel
                                    collapsible={!isCreated ? undefined : 'disabled'}
                                    header={geti18nText('payment.calculation.edit.addEmployee')}
                                    key="4"
                                >
                                    <AddEmployee
                                        allEmployee={allEmployee}
                                        setAllEmployee={setAllEmployee}
                                        lock={lock}
                                        showAllEmployee={true}
                                    />
                                </Panel>
                            )}
                            <Panel
                                header={geti18nText('payment.calculation.edit.calcSettings')}
                                key="3"
                                extra={canCreate() && (lock ? () => {} : reloadCalcSettings('CALCULATION_PARAMETERS'))}
                            >
                                <PaymentCalculationSettings
                                    dataSource={calcSettings}
                                    setDataSource={setCalcSettings}
                                    lock={lock}
                                />
                            </Panel>
                            {props.type === 1 && (
                                <Panel
                                    header={geti18nText('payment.calculation.edit.calcSettingsBrut')}
                                    key="5"
                                    extra={
                                        canCreate() && (lock ? () => {} : reloadCalcSettings('PAYROLL_AUTOMATIC_CALC'))
                                    }
                                >
                                    <PaymentCalculationSettings
                                        dataSource={calcSettingsBrut}
                                        setDataSource={setCalcSettingsBrut}
                                        lock={lock}
                                    />
                                </Panel>
                            )}
                        </Collapse>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                            activeKey={bottomCollapseActiveKeys}
                            onChange={(key: any) => {
                                setBottomCollapseActiveKeys(key);
                            }}
                            collapsible={isCreated ? undefined : 'disabled'}
                        >
                            <Panel header={geti18nText('payment.calculation.edit.employees')} key="2">
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <EmployeePaymentIndex
                                            showRowSelection={true}
                                            rowSelectionType={'checkbox'}
                                            rowSelectionModal={setSelectedValuesFunc}
                                            refreshTable={employeePaymentIndexRefresh}
                                        />
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </NyDataEdit>
            {modalAddAdditionVisible && (
                <Modal
                    visible={modalAddAdditionVisible}
                    title={geti18nText('payment.calculation.title.addAdditions')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={payrollSaveAddition}
                    onCancel={() => {
                        setModalAddAdditionVisible(false);
                        setAddPaymentAdditionsData([]);
                    }}
                    confirmLoading={confirmLoading}
                    width={800}
                    maskClosable={false}
                >
                    <EmployeePaymentAddition
                        dataSource={addPaymentAdditionsData}
                        setDataSource={setAddPaymentAdditionsData}
                    />
                </Modal>
            )}
            {modalAddEmployeeVisible && (
                <Modal
                    visible={modalAddEmployeeVisible}
                    maskClosable={false}
                    title={geti18nText(
                        props.type === 1 ? 'payment.calculation.addEmployees' : 'payment.calculation.addEmployee'
                    )}
                    okText={geti18nText('app.default.button.save')}
                    onOk={() => {
                        setConfirmLoading(true);
                        payrollAddEmployees(addEmployeeForm.getFieldsValue(true));
                    }}
                    onCancel={() => {
                        setModalAddEmployeeVisible(false);
                        addEmployeeForm.resetFields();
                    }}
                    confirmLoading={confirmLoading}
                    width={800}
                >
                    <Form
                        form={addEmployeeForm}
                        layout="vertical"
                        {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                    >
                        <AddEmployee
                            lock={lock}
                            showAllEmployee={true}
                            multiple={props.type === 1 ? true : false}
                            allEmployeeInitialValue={false}
                            allEmployee={allEmployee}
                            setAllEmployee={setAllEmployee}
                        />
                    </Form>
                </Modal>
            )}
            {modalPaymentOrderVisible && (
                <Modal
                    visible={modalPaymentOrderVisible}
                    maskClosable={false}
                    title={geti18nText('payment.calculation.generatePaymentOrder.loading')}
                    okText={geti18nText('app.default.button.generate')}
                    onOk={() => {
                        setConfirmLoading(true);
                        generatePaymentOrders(paymentOrderForm.getFieldsValue(true));
                    }}
                    onCancel={() => {
                        setModalPaymentOrderVisible(false);
                        paymentOrderForm.resetFields();
                    }}
                    confirmLoading={confirmLoading}
                    width={800}
                >
                    <Form
                        form={paymentOrderForm}
                        layout="vertical"
                        {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generatePaymentOrder.net')}
                                    name="calcNet"
                                    initialValue={true}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generatePaymentOrder.sickLeave')}
                                    name="calcSickLeave"
                                    initialValue={true}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generatePaymentOrder.insurance')}
                                    name="calcInsurance"
                                    initialValue={true}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generatePaymentOrder.deduction')}
                                    name="calcDeduction"
                                    initialValue={true}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.generatePaymentOrder.tax')}
                                    name="calcTax"
                                    initialValue={true}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
            <GenerateJoppdInsuranceInterestModal
                calculationId={Number(id)}
                modalVisible={modalGenerateJoppdInsuranceInterestVisible}
                setModalVisible={setModalGenerateJoppdInsuranceInterestVisible}
            />
        </>
    );
};

export default PaymentCalculationEdit;
