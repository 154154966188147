import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Dropdown, Empty, Menu, Radio } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { getEnumBooleanArray, isAdmin } from '../../../../../utils/Utils';
import AssetReportModal from './AssetReportModal';
import AssetReleaseEdit from './edit';

const BusinessUnitTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const location = useLocation();
    const table = useTableSettings();
    const dispatch = useDispatch();

    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const [orderViewId, setOrderViewId] = useState<any>(null);
    const [orderViewLabel, setOrderViewLabel] = useState<any>('');
    const [orderViewList, setOrderViewList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState<any>(0);

    const canCreate = () => {
        return AssetRights.canCreateRelease();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    useEffect(() => {
        setLoading(true);
        fetchOrderView();
    }, []);

    let setDefaultFilterValue = () => {
        if (orderViewId != undefined) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_id', condition: 'is_null', value: '' },
                { field: 'business_unit_id', condition: 'is_not_null', value: '' },
                {
                    field: 'orderView.id',
                    condition: 'equals',
                    value: orderViewId,
                },
            ];
        }

        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'is_null', value: '' },
            { field: 'business_unit_id', condition: 'is_not_null', value: '' },
        ];
    };

    const setDefaultFilterValueOrderView = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 1 },
            { field: 'orderViewAccessType', condition: 'equals', value: 3 },
        ];
    };

    const fetchOrderView = () => {
        const emptyData: any = [];
        if (orderViewList == emptyData || orderViewList.length < 1) {
            let defaultFilterOrder = setDefaultFilterValueOrderView();
            NyRequestResolver.requestGet(CONSTANTS_REQ.ORDER_VIEW.USER_SEARCH, {
                search: encodeURI(JSON.stringify(defaultFilterOrder)),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data[0]) {
                            if (result.data[0].id) setOrderViewId(result.data[0].id);
                            if (result.data[0].name) setOrderViewLabel(result.data[0].name);
                        }

                        let oView: any = [];
                        for (let key in result.data) {
                            if (result.data.hasOwnProperty(key)) {
                                let order = { value: undefined, label: undefined, number: undefined };
                                order.value = result.data[key].id;
                                order.label = result.data[key].name;
                                oView.push(order);
                            }
                        }
                        setOrderViewList(oView);
                    }
                }
                setLoading(false);
            });
        }
    };

    const onChangeOrderView = (e: any) => {
        setOrderViewId(e.target.value > 0 ? e.target.value : null);
    };

    const setDefaultSortOrder = { order: 'dateRelease', orderType: 'desc' };

    const initialColumns = [
        {
            title: geti18nText('assetRelease.table.column.id'),
            width: '4%',
            dataIndex: 'id',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('assetRelease.table.column.ord'),
            dataIndex: [`concat(_ar.ord, '/', date_part('year', _ar.date_release))`],
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.dateRelease) {
                    return record.ord + '/' + moment(record.dateRelease).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.warehouseCode'),
            dataIndex: ['warehouse', 'code'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.WAREHOUSE.SEARCH, 'warehouse.id', 'code', 'code', {
                id: 'id',
                label: 'code',
                text: 'code',
            }),
        },
        {
            title: geti18nText('assetRelease.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.dateRelease'),
            dataIndex: 'dateRelease',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateRelease) {
                    return moment(record.dateRelease).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.dateTo'),
            dataIndex: 'dateTo',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.createdBy'),
            dataIndex: [`concat(userPerson.first_name,' ', userPerson.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (person: any, record: any) => {
                if (record.userPerson) {
                    return record.userPerson.firstName + ' ' + record.userPerson.lastName;
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.bookedStock'),
            dataIndex: 'booked',
            width: '9%',
            sorter: (a: any, b: any) => {},
            render: (booked: any) => {
                if (booked) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('assetRelease.table.column.order'),
            dataIndex: ['assetOrder'],
            width: '5%',
            render: (text: any, record: any) => {
                if (
                    record &&
                    record.assetOrder &&
                    record.assetOrder.id &&
                    record.assetOrder.ord &&
                    record.assetOrder.date
                ) {
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'assetOrderEdit',
                                        visible: true,
                                        item: record.assetOrder.id,
                                    })
                                )
                            }
                        >
                            {record.assetOrder.ord + '/' + moment(record.assetOrder.date).format('YYYY')}
                        </Button>
                    );
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'Release'} record={record}></RowAction>;
                }
            },
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <AssetReportModal
                            reportType={'ASSET_RELEASE_SPECIFICATION'}
                            reportTitle={'assetRelease.report.assetReleaseSpecification'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_RELEASE_SPECIFICATION
                            )}
                            url={CONSTANTS_REQ.REPORT.ASSET_RELEASE_SPECIFICATION}
                            searchFieldType={enAssetReleaseSpecType.ORG_UNIT}
                        />
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <AssetReportModal
                            reportType={'ASSET_RELEASE_RECAPITULATION'}
                            reportTitle={'assetRelease.report.assetReleaseRecapitulation'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_RELEASE_RECAPITULATION
                            )}
                            url={CONSTANTS_REQ.REPORT.ASSET_RELEASE_RECAPITULATION}
                            searchFieldType={enAssetReleaseSpecType.ORG_UNIT}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                options={orderViewList}
                value={orderViewId}
                onChange={onChangeOrderView}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '15px' }}
            />
            {isAdmin() || (orderViewList && orderViewList.length > 0) ? (
                <NyDataTable
                    nyId="asset-release-business-unit-table"
                    url={CONSTANTS_REQ.ASSET_RELEASE.LIST}
                    addNewButtonText={geti18nText('assetRelease.table.add')}
                    buttonsClassName="buttons-sticky"
                    showRecordModal={true}
                    modalComponent={AssetReleaseEdit}
                    columns={initialColumns}
                    setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                    scroll={scroll}
                    setDefaultFilterValue={setDefaultFilterValue}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    shortcuts={true}
                    editProps={{ refresh: refresh, setRefresh: setRefresh }}
                    addedData={{ type: enAssetReleaseSpecType.ORG_UNIT, orderViewId: orderViewId }}
                    hideNewButton={!canCreate()}
                    addedButtons={addedButtons}
                    fetchWhenChange={orderViewId || refresh}
                    exportCSV={canExportCSV()}
                    CSVFileName={geti18nText('assetRelease.table.header')}
                    colCSVCustomization={csvColumnCustomisation()}
                    csvColumns={csvColumns()}
                />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            userPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            date: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY HH:mm');
            },
        },
        {
            assetOrder: (value: any) => {
                return (
                    value != undefined &&
                    value.ord != undefined &&
                    value.date != undefined &&
                    value.ord + '/' + moment(value.date).format('YYYY')
                );
            },
        },
        {
            booked: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetRelease.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('assetRelease.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('assetRelease.table.column.warehouseCode'),
            dataIndex: ['warehouse', 'code'],
        },
        {
            title: geti18nText('assetRelease.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('assetRelease.table.column.dateRelease'),
            dataIndex: 'dateRelease',
        },
        {
            title: geti18nText('assetRelease.table.column.dateTo'),
            dataIndex: 'dateTo',
        },
        {
            title: geti18nText('assetRelease.table.column.createdBy'),
            dataIndex: 'userPerson',
        },
        {
            title: geti18nText('assetRelease.table.column.bookedStock'),
            dataIndex: 'booked',
        },
        {
            title: geti18nText('assetRelease.table.column.order'),
            dataIndex: 'assetOrder',
        },
    ];
};

export default BusinessUnitTable;
