import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row, Tabs } from 'antd';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ItemGroupIndex from '.';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    getEnumArray,
    getEnumBooleanArray,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    setEnumFormat,
} from '../../../../utils/Utils';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import ObjectMetadataIndex from '../../../object-metadata';
import ObjectMetadataEdit from '../../../object-metadata/edit';
import ItemGroupSearch from './search';

const { TabPane } = Tabs;

const ItemGroupEdit = (props: any) => {
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState(geti18nText('itemGroup.edit.new'));
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [activeKey, setAcitveKey] = useState('1');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [hasSelected, setHasSelected] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const [fileList, setFileList] = useState<any>([]);
    const [dataForm, setDataForm] = useState(null);
    const enMetadataType = useEnum('METADATA_TYPE');
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');

    const focusInput = useRef<any>(null);
    const fileUploadRef = useRef<any>();

    const [form] = Form.useForm();
    const [formModal] = Form.useForm();

    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        switch (props.addedData.type) {
            case 0:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
            case 1:
                //item-group-asset
                return AssetRights.canCreateCodebooks();
            case 2:
                //item-group-material
                return InventoryRights.canCreateCodebooks();
            case 3:
                //item-group-service
                return ServicesRights.canCreateCodebooks();
            default:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('itemGroup.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.type) {
            dataForm.type = setEnumFormat('ITEM_GROUP_TYPE', dataForm.type);
        }

        if (dataForm.fileId) {
            let files = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: dataForm.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(files);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setAcitveKey('1');
        setEditHeader(geti18nText('itemGroup.edit.new'));
        setFileList([]);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'itemGroup.id', condition: 'equals', value: dataForm },
            { field: 'active', condition: 'equals_bool', value: 1 },
        ];
    };

    const setDefaultFilterValueSearch = () => {
        if (props.addedData.type) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: props.addedData.type },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('objectMetadata.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('objectMetadata.table.column.name'),
            dataIndex: ['objectMetadata', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('objectMetadata.table.column.dataType'),
            dataIndex: ['objectMetadata', 'dataType'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('OBJECT_METADATA_TYPE')),
            render: (text: any, recrod: any) => {
                if (recrod.objectMetadata.dataType) {
                    return geti18nText('app.enum.OBJECT_METADATA_TYPE.' + recrod.objectMetadata.dataType);
                }
            },
        },
        {
            title: geti18nText('objectMetadata.table.column.mandatory'),
            dataIndex: ['objectMetadata', 'mandatory'],
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.objectMetadata.mandatory) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('objectMetadata.table.column.format'),
            dataIndex: ['objectMetadata', 'pattern'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
    ];

    const deleteAttributes = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.ITEM_GROUP.DELETE_METADATA, undefined, {
            itemGroup: { id: form.getFieldValue('id') },
            objectMetadataIds: selectedValues,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeys) {
                    clearKeys();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                if (result.data && result.data.error) {
                    errorNotification(`${geti18nText('itemGroup.modal.deleteAttributes.error')}: ${result.data.error}`);
                } else {
                    errorNotification();
                }
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button onClick={() => setIsModalVisible(true)} icon={<PlusOutlined />}>
                        {geti18nText('app.default.button.add')}
                    </Button>
                    <NyModalConfirm
                        title={geti18nText('itemGroup.modal.deleteAttributes.confirm')}
                        onConfirm={() => {
                            deleteAttributes();
                        }}
                    >
                        <Button style={{ marginLeft: '5px' }} disabled={!hasSelected} danger icon={<DeleteOutlined />}>
                            {geti18nText('itemGroup.modal.deleteAttributes')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const save = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);
        formModal
            .validateFields()
            .then((values: any) => {
                const attributeIds = values.attributes.map((item: any) => item.id);
                NyRequestResolver.requestPost(CONSTANTS_REQ.ITEM_GROUP.ADD_METADATA, undefined, {
                    itemGroup: { id: form.getFieldValue('id') },
                    objectMetadataIds: attributeIds,
                }).then((result: any) => {
                    if (setLoadingModal) setLoadingModal(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        setIsModalVisible(false);
                        setRefreshTable((refreshTable) => refreshTable + 1);
                        formModal.resetFields();
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoadingModal) setLoadingModal(false);
            });
    };

    let clearKeys: any;
    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    return (
        <NyDataEdit
            layout="vertical"
            //formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.ITEM_GROUP.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={false}
            onSaveAndGetID={(id: any) => {
                if (fileUploadRef?.current) {
                    fileUploadRef.current.filesUpload(id);
                }
            }}
            normalize={(values: any) => {
                if (values.parent) values.parent = getSearchFormat(values.parent);
                if (!values.parent || values.parent.id === -1) {
                    delete values.parent;
                }
                if (props.addedData.type != 0) values.type = props.addedData.type;
                if (values.type) values.type = getEnumFormat(values.type);
                if (fileList.length > 0 && fileList[0]) {
                    values.fileId = fileList[0].id;
                }
                return values;
            }}
            customFooterContent={canCreate() ? getCustomFooterContent : undefined}
            setIsCreate={setIsCreate}
            hideSubmitButton={!canCreate() || activeKey == '2'}
            hideActivationButtons={!canCreate() || activeKey == '2'}
            leaveNewModalOpen={true}
        >
            <Tabs defaultActiveKey={activeKey} onChange={(key) => setAcitveKey(key)} type="card">
                <TabPane tab={geti18nText('itemGroup.tab.1')} key="1">
                    <Row gutter={24}>
                        <Col span={4}>
                            <NyImageUpload
                                documentType={documentTypeEnum.ITEM_GROUP_IMAGE}
                                files={fileList}
                                setFiles={setFileList}
                                ref={fileUploadRef}
                            />
                        </Col>
                        <Col span={20}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('itemGroup.edit.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <ItemGroupSearch
                                        label={geti18nText('itemGroup.edit.parent')}
                                        name="parent"
                                        setDefaultFilterValue={setDefaultFilterValueSearch}
                                        type={props.addedData.type ? props.addedData.type : 0}
                                        AddNewModalComponent
                                        AddNewModalComponentAddedData={{
                                            type: props.addedData.type ? props.addedData.type : 0,
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    {props && props.addedData && props.addedData.type == 0 && (
                                        <Form.Item
                                            label={geti18nText('itemGroup.edit.type')}
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                options={getEnumArray('ITEM_GROUP_TYPE')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                style={{ width: '100%' }}
                                                className={''}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('itemGroup.tab.2')} key="2" disabled={isCreate}>
                    <NyDataTable
                        nyId="item-group-edit"
                        rowKey="id"
                        url={CONSTANTS_REQ.ITEM_GROUP.METADATA}
                        columns={columns}
                        scroll={{ y: 680, x: 800 }}
                        hideNewButton
                        onRowSelect={() => null}
                        rowSelectionType={'checkbox'}
                        rowSelectionModal={setSelectedValuesFunc as any}
                        setDefaultFilterValue={setDefaultFilterValue}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        shortcuts={true}
                        fetchWhenChange={refreshTable}
                        showRowSelection={canCreate()}
                    />
                    {isModalVisible && (
                        <Modal
                            title={geti18nText('itemGroup.modal.attributes')}
                            visible={isModalVisible}
                            maskClosable={false}
                            okText={geti18nText('app.default.button.save')}
                            onOk={(e) => save(e)}
                            confirmLoading={loadingModal}
                            onCancel={() => {
                                formModal.resetFields();
                                setIsModalVisible(false);
                            }}
                        >
                            <Form
                                layout="vertical"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                form={formModal}
                                onFinishFailed={({ errorFields }) => {
                                    form.scrollToField(errorFields[0].name);
                                }}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label={geti18nText('itemGroup.edit.attributes')} name={'attributes'}>
                                            <NySearchField
                                                url={CONSTANTS_REQ.OBJECT_METADATA.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                mode={'multiple'}
                                                autoFocus={true}
                                                SearchPopupComponent={
                                                    <ObjectMetadataIndex metadataType={enMetadataType.ITEM_TYPE} />
                                                }
                                                AddNewModalComponent={ObjectMetadataEdit}
                                                AddNewModalComponentAddedData={{
                                                    metadataType: enMetadataType.ITEM_TYPE,
                                                }}
                                                addedFilter={{
                                                    field: 'metadataType',
                                                    condition: 'equals',
                                                    value: enMetadataType.ITEM_TYPE,
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default ItemGroupEdit;
