import { NyDataEdit, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getEnumArray,
    getEnumArrayWithRawData,
    getEnumArrayWithRawDataText,
    getEnumFormat,
    setEnumFormat,
    setEnumFormatRaw,
    setSearchFormat,
} from '../../../../../utils/Utils';

import { GetEnum } from '../../../../../utils/Enums';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { InfoCircleOutlined } from '@ant-design/icons';

const ContractMetadataEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [editHeader, setEditHeader] = useState(geti18nText('metadata.edit.new'));

    const isAdmin = () => {
        return AdministrationRights.isAdmin();
    };

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [showCodebookSelect, setShowCodebookSelect] = useState(false);
    const [showPattern, setShowPattern] = useState(false);
    const [metadataType, setMetadataType] = useState<any>(0);
    const enMetadataTypes = GetEnum({ enumName: 'METADATA_TYPE' });
    const enContractMetadata = GetEnum({ enumName: 'CONTRACT_METADATA' });

    const [IDValue, setIDValue] = useState<any>(null);

    useEffect(() => {
        setIDValue(props.value);
    }, []);

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks() || HumanResourcesRights.canCreateContractAdmin();
    };

    const resetValue = (value: any) => {
        if (value.id == enContractMetadata.CODEBOOK) {
            setShowCodebookSelect(true);
        } else {
            setShowCodebookSelect(false);
            form.setFieldsValue({ codebook: undefined });
        }
        setMetadataType(value.id);
        if (value.id == enContractMetadata.NUMBER || value.id == enContractMetadata.DATE) {
            setShowPattern(true);
        } else {
            setShowPattern(false);
            form.setFieldsValue({ pattern: undefined });
        }
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('metadata.edit.title'));

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('metadata.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.metadataType) {
            dataForm.metadataType = setEnumFormat('METADATA_TYPE', dataForm.metadataType);
        }

        if (dataForm.dataType) {
            setMetadataType(dataForm.dataType);
            setShowCodebookSelect(dataForm.dataType === enContractMetadata.CODEBOOK);
            setShowPattern(
                dataForm.dataType == enContractMetadata.NUMBER || dataForm.dataType == enContractMetadata.DATE
            );
            dataForm.dataType = setEnumFormat('CONTRACT_METADATA', dataForm.dataType);
        }

        if (dataForm.codebook) {
            dataForm.codebook = setEnumFormat('CONTRACT_METADATA_CODEBOOK', dataForm.codebook);
        }

        if (dataForm.autoFillKey) {
            dataForm.autoFillKey = setEnumFormatRaw('CONTRACT_AUTO_FILL_KEY', dataForm.autoFillKey);
        }

        if (dataForm.contractHrColumn) {
            dataForm.contractHrColumn = setEnumFormat('CONTRACT_HR_COLUMN', dataForm.contractHrColumn);
        }

        if (dataForm.contractCategory) {
            dataForm.contractCategory = setSearchFormat(dataForm.contractCategory, 'contractCategory');
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setIDValue(undefined);
        form.resetFields();
        setDataForm(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            isModal={true}
            loading={loading}
            value={props.value}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CONTRACT_METADATA.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.dataType) values.dataType = getEnumFormat(values.dataType);
                if (values.autoFillKey) values.autoFillKey = getEnumFormat(values.autoFillKey);
                if (values.contractHrColumn) values.contractHrColumn = getEnumFormat(values.contractHrColumn);

                if (values.codebook) values.codebook = values?.codebook?.id;

                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('metadata.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input maxLength={100} autoFocus />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('metadata.edit.documentPlaceholder')}
                        name="documentPlaceholder"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('objectMetadata.edit.dataType')}
                        name="dataType"
                        style={{ paddingRight: '0px!important', paddingLeft: '0px!important' }}
                        initialValue={setEnumFormat('CONTRACT_METADATA', enContractMetadata.TEXT)}
                    >
                        <NySearchField
                            style={{ width: '100%' }} //here
                            options={getEnumArray('CONTRACT_METADATA')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="id"
                            className=""
                            onChange={resetValue}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    {showCodebookSelect && (
                        <Form.Item
                            label={geti18nText('metadata.edit.codebook')}
                            name="codebook"
                            style={{ paddingRight: '0px!important', paddingLeft: '0px!important' }}
                            rules={[
                                {
                                    required: showCodebookSelect,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                options={getEnumArrayWithRawDataText('CONTRACT_METADATA_CODEBOOK')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="id"
                                className=""
                                allowClear={false}
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    {showPattern && (
                        <Form.Item
                            label={
                                <>
                                    <span style={{ marginRight: '10px' }}>{geti18nText('metadata.edit.pattern')}</span>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            metadataType == enContractMetadata.NUMBER
                                                ? geti18nText('metadata.edit.pattern.exampleNumber')
                                                : geti18nText('metadata.edit.pattern.exampleDate')
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </>
                            }
                            name="pattern"
                        >
                            <Input maxLength={100} />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            {isAdmin() && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('metadata.edit.autoFillKey')}
                            name="autoFillKey"
                            style={{ paddingRight: '0px!important', paddingLeft: '0px!important' }}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                options={getEnumArrayWithRawData('CONTRACT_AUTO_FILL_KEY')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="id"
                                className=""
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {isAdmin() && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('metadata.edit.contractHrColumn')}
                            name="contractHrColumn"
                            style={{ paddingRight: '0px!important', paddingLeft: '0px!important' }}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                options={getEnumArray('CONTRACT_HR_COLUMN')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="id"
                                className=""
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </NyDataEdit>
    );
};

export default ContractMetadataEdit;
