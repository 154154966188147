import { DeleteOutlined, DeleteTwoTone, PlusCircleOutlined, SaveTwoTone } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, getErrorNotificationMessage, okNotification } from '../../../../../utils/Utils';
import EditableCell from './EditableCell';

const TaskDependencyIndex = ({ taskTemplateId, refreshItems, subtaskCount = 0 }: any) => {
    const [dependencyForm] = Form.useForm();
    const [items, setItems] = useState<any>([]);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;
    const [task, setTask] = useState<any>(null);
    const [refresh, setRefresh] = useState(0);

    const disableAddNewItem = () => {
        return addItemInProcess || (subtaskCount == 2 && items?.length == 1);
    };

    useEffect(() => {
        if (taskTemplateId) {
            getItems(taskTemplateId);
        }
    }, [taskTemplateId, refresh, refreshItems]);

    const getItems = (taskTemplateId: any) => {
        setItems([]);
        let url = CONSTANTS_REQ.TASK_TEMPLATE.DEPENDENCIES_LIST;
        const urlWithTaskTemplateId = url.replace('{taskTemplateId}', taskTemplateId);
        NyRequestResolver.requestGet(urlWithTaskTemplateId, {
            id: taskTemplateId,
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'taskTemplateId', condition: 'equals', value: taskTemplateId },
                ])
            ),
        }).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setItemsData(result.data);
            }
        });
    };

    function compare(a: any, b: any) {
        if (a?.taskTemplate?.id < b.taskTemplate?.id) {
            return -1;
        }
        if (a.taskTemplate?.id > b.taskTemplate?.id) {
            return 1;
        }
        return 0;
    }

    const setItemsData = (data: any) => {
        if (data && data?.length > 0) {
            let newData = data.sort(compare);
            newData.map((item: any) => {
                const taskTemplateList = data.filter(
                    (itemTemplate: any) => itemTemplate?.taskTemplate?.id == item?.taskTemplate?.id
                );
                const taskTemplateFirstItem = taskTemplateList && taskTemplateList.at(0);
                item.hideDependsOn = item?.id == taskTemplateFirstItem?.id ? false : true;
                const taskTemplateLastItem = taskTemplateList.at(-1);
                item.hideActions = item?.id == taskTemplateLastItem?.id ? false : true;
            });
            setItems(newData);
        }
    };

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };

        dependencyForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const addNewRowDependency = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        const newData = {
            id: null,
            taskTemplate: undefined,
            dependsOn: undefined,
            hideDependsOn: false,
            hideActions: false,
            active: true,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const addNewSubRowDependency = (record: any) => {
        setAddItemInProcess(true);
        if (record.taskTemplate) {
            setTask(record.taskTemplate);
            const newItems = [...items];
            const lastItem: any = items.filter((item: any) => item?.taskTemplate?.id == record.taskTemplate?.id).at(-1);
            const lastIndex = items.findIndex((item: any) => item.id == lastItem.id);
            if (lastIndex > -1) {
                const newData = {
                    id: null,
                    taskTemplate: record.taskTemplate,
                    dependsOn: undefined,
                    hideDependsOn: true,
                    hideActions: true,
                    active: true,
                };

                const newList: any = [...newItems.slice(0, lastIndex + 1), newData, ...newItems.slice(lastIndex + 1)];
                setItems(newList);
                edit(newData);
            }
        } else {
            const newData = {
                id: null,
                taskTemplate: task,
                dependsOn: undefined,
                hideDependsOn: true,
                hideActions: true,
                active: true,
            };
            setItems([...items, newData]);
            edit(newData);
        }
    };

    const saveItem = (value: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_TEMPLATE.DEPENDENCIES, undefined, value).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                setEditingKey('');
                setAddItemInProcess(false);
                setRefresh((refresh) => refresh + 1);
            } else {
                getErrorNotificationMessage(result);
            }
        });
    };

    const deleteRow = (record: any) => {
        if (record?.id) {
            saveItem({
                id: record.id,
                taskTemplate: record.taskTemplate,
                dependsOn: record.dependsOn,
                active: false,
            });
        } else {
            setItems(items.filter((row: any) => row.id !== null));
            setAddItemInProcess(false);
        }
    };

    const save = async () => {
        const row = await dependencyForm.validateFields();
        if (row && row?.taskTemplate?.id > 0 && row?.dependsOn?.id > 0) {
            saveItem({
                id: row.id,
                taskTemplate: row.taskTemplate,
                dependsOn: row.dependsOn,
                active: true,
            });
        } else {
            errorNotification(geti18nText('app.default.required.fields'));
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={save}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                        <Button
                            type="link"
                            onClick={() => {
                                deleteRow(record);
                            }}
                        >
                            <DeleteTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#ff7875"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('app.default.delete.confirm')}
                        onConfirm={() => {
                            deleteRow(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            {addItemInProcess ? (
                                <Button type="link" disabled={addItemInProcess}>
                                    <DeleteOutlined
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            ) : (
                                <Button type="link" disabled={addItemInProcess}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            )}
                        </Tooltip>
                    </NyModalConfirm>
                    {!record.hideActions && (
                        <Button
                            type="link"
                            onClick={() => addNewSubRowDependency(record)}
                            disabled={disableAddNewItem()}
                        >
                            <PlusCircleOutlined
                                style={{
                                    fontSize: '20px',
                                }}
                            />
                        </Button>
                    )}
                </Space>
            </React.Fragment>
        );
    };

    const columns = [
        {
            title: geti18nText('task.template.dependency.template'),
            dataIndex: 'taskTemplate',
            width: '40%',
            editable: true,
            inputType: 'taskTemplate',
            render: (taskTemplate: any, record: any) => {
                if (!record.hideDependsOn) {
                    return taskTemplate?.name ?? '' + (taskTemplate?.code ? ' (' + taskTemplate?.code + ')' : '');
                }
            },
        },
        {
            title: geti18nText('task.template.dependency.dependsOn'),
            dataIndex: 'dependsOn',
            width: '40%',
            editable: true,
            inputType: 'dependsOn',
            render: (dependsOn: any) => {
                return dependsOn?.name ?? '' + (dependsOn?.code ? ' (' + dependsOn?.code + ')' : '');
            },
        },
        {
            key: 'action',
            editable: false,
            render: (text: any, record: any) => {
                if (items.length >= 1) {
                    const editable = isEditing(record);
                    return editable ? (
                        <ActionEditable text={text} record={record} />
                    ) : (
                        <ActionNotEditable text={text} record={record} />
                    );
                }
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: dependencyForm,
                    taskTemplateId: taskTemplateId,
                    task: task,
                    setTask: setTask,
                };
            },
        };
    });

    return (
        <>
            <Row gutter={24} style={{ height: '36px' }}>
                <Col span={24}>
                    <Button
                        style={{ float: 'right', marginRight: '12px' }}
                        icon={<PlusCircleOutlined />}
                        onClick={addNewRowDependency}
                        disabled={disableAddNewItem()}
                    >
                        {geti18nText('task.template.dependency.add')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={dependencyForm} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={items}
                            scroll={{ y: 680, x: 800 }}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                        />
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default TaskDependencyIndex;
