import { useEffect, useState } from 'react';
import { Col, Row, Modal, Form, Checkbox } from 'antd';
import { geti18nText, NyDatePicker, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customEmployeeRenderOption, fileDownload, getDateFormat } from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import moment from 'moment';
import { GetEnum } from '../../../../utils/Enums';

const PaymentCalculationReportModal = ({ modalVisible, setModalVisible, type }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [subreportTypeEnums] = useState(GetEnum({ enumName: 'SUBREPORT_TYPE' }));
    const [subreports, setSubreports] = useState([]);

    useEffect(
        function () {
            if (modalVisible) {
                geSubReports();
            }
        },
        [modalVisible]
    );

    const setDefaulSubReportFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'type',
                condition: 'equals',
                value: subreportTypeEnums['PAYROLL_INCOME_CERTIFICATE'],
            },
        ];
    };

    const geSubReports = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.SUBREPORT.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaulSubReportFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setSubreports(
                        result.data.map((report: any) => {
                            return { id: report.id, text: report.name };
                        })
                    );
                }
            }
        });
    };

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                let url = CONSTANTS_REQ.REPORT.PAYROLL_INCOME_CERTIFICATE_REPORT;

                let filename = 'payrollIncomeCertificateReport';

                let parms: any = { lang: NyUtils.getSelectedLanguage(), useDefault: true };
                if (values.employee) {
                    parms.employeeIds = values.employee.map((val: any) => val.id);
                } else {
                    parms.employeeIds = null;
                }

                parms.calcTypes = [];
                if (values.income) {
                    parms.calcTypes = [...parms.calcTypes, 1];
                }
                if (values.secondIncome) {
                    parms.calcTypes = [...parms.calcTypes, 2];
                }
                if (values.contract) {
                    parms.calcTypes = [...parms.calcTypes, 3];
                }
                if (values.royalties) {
                    parms.calcTypes = [...parms.calcTypes, 4];
                }

                parms.year = getDateFormat(values.year, 'YYYY');

                if (values.subreport) {
                    parms.subreportId = values.subreport.id;
                    parms.useDefault = false;
                }

                fileDownload(url, parms, `${filename}_${moment().format('yyyyMMDD')}.pdf`);
                setLoading(false);
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('payment.calculation.report.income.certificate')}
                    okText={geti18nText('payment.calculation.report.generate')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                        setSubreports([]);
                    }}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.report.year')}
                                    name="year"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker mode="year" picker="year" format="YYYY" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('app.enum.PAYMENT_CALCULATION_TYPE.1')}
                                    name="income"
                                    valuePropName="checked"
                                    initialValue={type === 1 ? true : false}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('app.enum.PAYMENT_CALCULATION_TYPE.2')}
                                    name="secondIncome"
                                    valuePropName="checked"
                                    initialValue={type === 2 ? true : false}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('app.enum.PAYMENT_CALCULATION_TYPE.3')}
                                    name="contract"
                                    valuePropName="checked"
                                    initialValue={type === 3 ? true : false}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('app.enum.PAYMENT_CALCULATION_TYPE.4')}
                                    name="royalties"
                                    valuePropName="checked"
                                    initialValue={type === 4 ? true : false}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('payment.calculation.report.employee')} name="employee">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        mode={'multiple'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="payment-calc-report-modal"/>}
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.calculation.report.subreportId')}
                                    name="subreport"
                                >
                                    <NySearchField
                                        options={subreports}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        className=""
                                        defaultValue={
                                            subreports && subreports.length == 1 && subreports[0] ? subreports[0] : null
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default PaymentCalculationReportModal;
