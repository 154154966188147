import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Empty, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BusinessUnitsGraph from '../../../../components/business-units-graph';
import NyReportButton from '../../../../components/report-button';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import BusinessUnitEdit from './edit';

const BusinessUnitIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    mobilization = false,
    showRadioButtons = true,
    readonly = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    hideNewButton = false,
    defaultFilterValue,
    expandedRows = true,
}: any) => {
    const location: any = useLocation();
    const table = useTableSettings();
    useHelper('common/business_unit');
    const [activeList, setActiveList] = useState<any>(1);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useEffect(() => {
        if (location?.state?.activeList) {
            setActiveList(location?.state?.activeList);
        }
    }, [location]);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
    };
    const setDefaultFilterValue = () => {
        return defaultFilterValue ? defaultFilterValue : table.setDefaultFilterValue();
    };

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const columns2 = [
        {
            title: geti18nText('war.businessUnit.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('businessUnit.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('businessUnit.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    const columns = [
        {
            title: geti18nText('businessUnit.table.column.code'),
            dataIndex: 'code',
            width: '250px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        // {
        //     title: geti18nText('businessUnit.table.column.id'),
        //     dataIndex: 'id',
        //     width: '250px',
        //     sorter: (a: any, b: any) => {},
        //     ...getColumnSearch('number'),
        // },
        {
            title: geti18nText('businessUnit.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('businessUnit.table.column.cost.center'),
            dataIndex: ['accountingCostCenter', 'code'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_CENTER.SEARCH,
                'accountingCostCenter.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('businessUnit.table.column.active'),
            dataIndex: 'active',
            width: '100px',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    const addedButtons = () => {
        return (
            <div style={{ float: 'left' }}>
                {HumanResourcesRights.canGenerateBusinessUnitPortfolioGraph() && (
                    <NyReportButton
                        url={CONSTANTS_REQ.REPORT.BUSINESS_UNIT}
                        subreportType={'BUSINESS_UNIT'}
                        buttoni18nText={'businessUnit.button.generateReport.action'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.BUSINESS_UNIT)}
                    />
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            {showRadioButtons ? (
                <Radio.Group
                    value={activeList}
                    defaultValue={1}
                    buttonStyle="solid"
                    optionType="button"
                    style={{ marginBottom: '20px', marginTop: '5px' }}
                >
                    <Radio.Button value={1} onChange={onChangeActiveList}>
                        {geti18nText('app.default.button.table')}
                    </Radio.Button>
                    <Radio.Button value={2} onChange={onChangeActiveList}>
                        {geti18nText('app.default.button.graph')}
                    </Radio.Button>
                </Radio.Group>
            ) : null}

            {activeList == 1 ? (
                <NyDataTable
                    nyId="human-business-unit-table"
                    url={CONSTANTS_REQ.BUSINESS_UNIT.LIST}
                    addNewButtonText={geti18nText('businessUnit.table.add')}
                    buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                    showRecordModal={true}
                    modalComponent={BusinessUnitEdit}
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    setDefaultFilterValue={setDefaultFilterValue}
                    columns={mobilization ? columns2 : columns}
                    hideNewButton={hideNewButton ? hideNewButton : !canCreate()}
                    setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                    scroll={scroll}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    shortcuts={true}
                    exportCSV={canExportCSV()}
                    CSVFileName={geti18nText('menu.businessUnit')}
                    colCSVCustomization={csvColumnCustomisation()}
                    expandedRows={expandedRows}
                    readonly={readonly}
                    hideButtons={readonly}
                    addedButtons={addedButtons}
                />
            ) : activeList == 2 ? (
                <BusinessUnitsGraph />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default BusinessUnitIndex;
