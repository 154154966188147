import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import VocationDescriptionIndex from '.';
import VocationDescriptionEdit from './edit';

export const VocationDescriptionSearch = ({
    label = geti18nText('workplace.edit.vocationDescription'),
    formItemName = 'vocationDescription',
    onChange,
    disabled = false,
    formStyle,
    mode,
    autoFocus = false,
    setDefaultFilterValue,
    required = false,
    mustGetPopupContainer = true,
    disabledIndex = false,
    clearSearchField,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={formItemName}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                itemName="name"
                AddNewModalComponent={VocationDescriptionEdit}
                SearchPopupComponent={<VocationDescriptionIndex disabled={disabledIndex} />}
                mustGetPopupContainer={mustGetPopupContainer}
                onChange={onChange}
                disabled={disabled}
                style={formStyle}
                mode={mode}
                autoFocus={autoFocus}
                setDefaultFilterValue={setDefaultFilterValue}
                AddNewModalComponentAddedData={{ clearSearchField: clearSearchField }}
            />
        </Form.Item>
    );
};

export default VocationDescriptionSearch;
