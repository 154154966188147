import { geti18nText, NyDataEdit, NyInputNumber, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../rights/administrationRights';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../utils/Utils';
import CountrySearch from '../administration/views/country/search';
import CurrencySearch from '../administration/views/currency/search';
import { BusinessUnitSearch } from '../human/views/business-unit/search';

const TravelWageEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelWage.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [dataForm, setDataForm] = useState(null);
    const [isStandardWage, setIsStandardWage] = useState<any>(true);
    const [standardExists, setStandardExists] = useState<any>(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canCreateBusinessUnit = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canCreateCodebooks = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('travelWage.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.country) dataForm.country = setSearchFormat(dataForm.country);
        if (dataForm.currency) dataForm.currency = setSearchFormat(dataForm.currency, 'isoCode');
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }
        setIsStandardWage(dataForm.standard ? true : false);
        if (dataForm.standard) {
            dataForm.businessUnit = undefined;
        }
        checkStandardExists(dataForm.country, dataForm.id);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setIsStandardWage(true);
        setStandardExists(false);
        form.resetFields();
        setEditHeader(geti18nText('travelWage.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onStandardChange = (value: any) => {
        setIsStandardWage(value.target.checked);
        if (value.target.checked) {
            form.setFieldsValue({ businessUnit: undefined });
        }
    };

    const onCountryChange = (value: any) => {
        checkStandardExists(value, dataForm);
    };

    const checkStandardExists = (country: any, wageId: any) => {
        setStandardExists(false);
        if (country && country.id) {
            let filter = encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'country_id', condition: 'equals', value: country.id },
                    { field: 'standard', condition: 'equals_bool', value: 1 },
                ])
            );
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WAGE.SEARCH, {
                search: filter,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data.length && result.data.length > 0) {
                        if (result.data[0].id == wageId) {
                            setStandardExists(false);
                        } else {
                            setStandardExists(true);
                            setIsStandardWage(false);
                        }
                    }
                }
            });
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_WAGE.EDIT}
            setValues={setValues}
            width={620}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.standard = isStandardWage ? true : false;
                if (values.country) {
                    values.country = getSearchFormat(values.country);
                    if (values.country.id < 1) {
                        delete values.country;
                    }
                }
                if (values.currency) {
                    values.currency = getSearchFormat(values.currency);
                    if (values.currency.id < 1) {
                        delete values.currency;
                    }
                }
                if (values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    if (values.businessUnit.id < 1) {
                        delete values.businessUnit;
                    }
                }
                return values;
            }}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('travelWage.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWage.edit.amount')}
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} nyTestId="travel-wage-amount" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <CountrySearch
                        label={geti18nText('travelWage.edit.country')}
                        name="country"
                        required={true}
                        onChange={onCountryChange}
                        setDefaultFilterValue={setDefaultFilterValue}
                        edit={canCreateCodebooks()}
                    />
                </Col>
                <Col span={12}>
                    <CurrencySearch
                        label={geti18nText('travelWage.edit.currency')}
                        nyTestId="travel-wage-currency"
                        noClassName={true}
                        edit={canCreateCodebooks()}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWage.edit.standardWage')} name="standard">
                        <Checkbox checked={isStandardWage} onChange={onStandardChange} disabled={standardExists} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <BusinessUnitSearch
                        formItemName={'businessUnit'}
                        disabled={isStandardWage}
                        required={!isStandardWage}
                        setDefaultFilterValue={setDefaultFilterValue}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelWageEdit;
