import { useRef, useState } from 'react';
import { Form, Row, Col, Input, Tabs, Select } from 'antd';
import { geti18nText, NyDataEdit, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import DeliverySpotIndex from '../delivery-spot';
import DeliverySpotTime from '../../../../../components/delivery-spot-time';
import useEnum from '../../../../../hooks/useEnum';
import { normalizeEmailFormat, setEmailFormat } from '../../../../../utils/Utils';
import { MealsRights } from '../../../../../rights/mealsRights';

const RestaurantEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('restaurant.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [activeKey, setActiveKey] = useState('1');
    const [isCreate, setIsCreate] = useState<any>(false);
    const { id } = useParams<any>();
    const [deliveryDays, setDeliveryDays] = useState<any>([]);
    const [restaurantProp, setRestaurantProp] = useState<any>(undefined);
    const [emails, setEmails] = useState<any>([]);

    const [form] = Form.useForm();
    const history = useHistory();

    const { TabPane } = Tabs;

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const defaultFilterValue = [
        { field: 'restaurant.id', condition: 'equals', value: dataForm },
        { field: 'active', condition: 'equals_bool', value: 1 },
    ];

    function setValues(dataForm: any) {
        setRestaurantProp({ name: dataForm.name, id: dataForm.id });
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.email) {
            dataForm.email = setEmailFormat(dataForm.email);
            setEmails(dataForm.email);
        }

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('restaurant.edit.title') + ' - ' + dataForm.name);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('restaurant.edit.new'));
        setActiveKey('1');
    };

    const enDeliveryType = useEnum('DELIVERY_TYPE');

    const onEmailChange = (value: any) => {
        let isAllValid: boolean = true;
        value.map((val: any) => {
            if (!isValidEmail(val)) {
                form.setFieldsValue({ email: emails });
                setEmails(emails);
                isAllValid = false;
            }
        });
        if (isAllValid) {
            setEmails(value);
        }
    };

    const isValidEmail = (email: any) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.RESTAURANT.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            setIsCreate={setIsCreate}
            leaveNewModalOpen={true}
            isModal={true}
            {...props}
            normalize={(values: any) => {
                if (values.email || emails) {
                    values.email = normalizeEmailFormat(emails);
                }

                return values;
            }}
        >
            <Tabs type="card" defaultActiveKey={activeKey} onChange={(key) => setActiveKey(key)}>
                <TabPane tab={geti18nText('restaurant.edit.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('restaurant.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('restaurant.edit.email')} name="email">
                                <Select mode="tags" style={{ width: '100%' }} onChange={onEmailChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('restaurant.edit.cashRegister')}
                                name="cashRegister"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('restaurant.edit.tab.deliverySpot')} key="2" disabled={isCreate}>
                    {restaurantProp && (
                        <DeliverySpotIndex
                            isCreatedInModal={true}
                            restaurant={restaurantProp}
                            defaultFilterValue={defaultFilterValue}
                        />
                    )}
                </TabPane>
                <TabPane tab={geti18nText('restaurant.edit.tab.pickupTime')} key="3" disabled={isCreate}>
                    <DeliverySpotTime
                        id={dataForm}
                        type={enDeliveryType.PICKUP}
                        visible={props.visible}
                        setDeliveryDays={setDeliveryDays}
                        deliveryDays={deliveryDays}
                    />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default RestaurantEdit;
