import { useState, useEffect } from 'react';
import { Row, Modal, Tooltip, Col, Tabs, Table, Divider } from 'antd';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { ScheduleOutlined } from '@ant-design/icons';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, numberFormat } from '../../../../utils/Utils';
import EmployeeAttendance from './EmployeeAttendance';

const { TabPane } = Tabs;

const EmployeeAttendanceModal = ({ employeeId, employeePaymentId, periodFrom, periodTo }: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [dataSourceBrut, setDataSourceBrut] = useState<any>([]);

    useEffect(() => {
        if (employeeId && visible) {
            var parms: any = {
                employeeId: employeeId,
                fromPeriod: `${getDateFormat(periodFrom)}`,
                toPeriod: `${getDateFormat(periodTo)}`,
            };
            NyRequestResolver.requestGet(CONSTANTS_REQ.ATTENDANCE.SUMMARY, parms).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        let data: any = [];
                        result.data.forEach((element: any) => {
                            data.push({
                                name: geti18nText('app.enum.ATTENDANCE_TYPE.' + element.type),
                                hours: element.hours,
                            });
                        });
                        setDataSource(data);
                    }
                }
            });
        }

        if (employeePaymentId && visible) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.EMPLOYEE_PAYMENT.BRUTS + '/' + employeePaymentId,
                undefined
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data) {
                        let data: any = [];
                        result.data.forEach((element: any) => {
                            data.push({ name: element.payrollBrut.name, hours: element.hours });
                        });
                        setDataSourceBrut(data);
                    }
                }
            });
        }
    }, [visible]);

    const columns = [
        {
            title: geti18nText('employee.payment.brut.hours'),
            dataIndex: 'name',
            width: '50%',
        },
        {
            title: geti18nText('employee.payment.brut.hours'),
            dataIndex: 'hours',
            render: (hours: any) => {
                return numberFormat(hours);
            },
        },
    ];

    return (
        <>
            <Tooltip title={geti18nText('attendance.edit.title')}>
                <ScheduleOutlined
                    onClick={(event: any) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setVisible(true);
                    }}
                    style={{ marginRight: '20px', fontSize: '16px' }}
                />
            </Tooltip>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('attendance.edit.title')}
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    okText={geti18nText('app.default.button.ok')}
                    width={900}
                    closable={true}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    maskClosable={false}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Tabs type="card" className="tabs-sticky">
                                <TabPane tab={geti18nText('attendance.edit.summary')} key="EmployeeAttendanceSummary">
                                    <Divider orientation="left">
                                        {geti18nText('attendance.employeeAttendence.sumAttendence')}
                                    </Divider>
                                    <Table
                                        size={'small'}
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={false}
                                    />
                                    <Divider orientation="left">
                                        {geti18nText('attendance.employeeAttendence.sumBrut')}
                                    </Divider>
                                    <Table
                                        size={'small'}
                                        dataSource={dataSourceBrut}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </TabPane>
                                <TabPane tab={geti18nText('attendance.edit.daily')} key="EmployeeAttendanceDaily">
                                    <EmployeeAttendance
                                        employeeId={employeeId}
                                        periodFrom={periodFrom}
                                        periodTo={periodTo}
                                    />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default EmployeeAttendanceModal;
