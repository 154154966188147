import {
    CaretLeftOutlined,
    CaretRightOutlined,
    CloseCircleTwoTone,
    FullscreenOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Descriptions,
    Form,
    Input,
    List,
    Modal,
    Popover,
    Row,
    Steps,
    message,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import NyReportButton from '../../../../../components/report-button';
import StatusHistoryIndex from '../../../../../components/status-history/StatusHistory';
import TravelWarrantExpensesIndex from '../../../../../components/travel-warrant-expenses';
import TravelWarrantMap from '../../../../../components/travel-warrant-map/TravelWarrantMap';
import TravelWarrantPlanSteps from '../../../../../components/travel-warrant-plan-steps';
import TWTypeComponent from '../../../../../components/travel-warrant-type/TravelWarrantTypeComponent';
import TravelWarrantVehicleIndex from '../../../../../components/travel-warrant-vehicle';
import TravelWarrantWageIndex from '../../../../../components/travel-warrant-wage';
import TravelWarrantWageSummaryIndex from '../../../../../components/travel-warrant-wage-summary';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, TRAVEL_WARRANT_WIZARD_EDIT_STEPS } from '../../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import {
    errorCustomNotification,
    errorNotification,
    getDateFormat,
    getFileList,
    getSearchFormat,
    getTravelWarrantStatusIcon,
    getTravelWizardEditDocsUrl,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import NyFilesUpload from '../../../../../utils/ny-file-upload-custom';
import './_index.scss';

const { Step } = Steps;

const TravelWarrantPersonalWizardEdit = ({
    ordinal,
    warrantDate,
    visible,
    setVisible,
    travelVehicle,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    files,
    setFiles,
    travelWarrantStatus,
    dataForm,
    isPersonalVehicle,
    destinationJson,
    setDestinationJson,
    isPlane = false,
    report,
    resetDataForm,
    isOverview = false,
    setSummaryModalVisible,
    refresh,
    setRefresh,
    showCheckboxesDestinationName = true,
}: any) => {
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const enExportType = useEnum('EXPORT_TYPE');
    const { employee } = useSelector((state: RootState) => state.employee);
    const history = useHistory();
    const uploadFilesRef = useRef<any>(null);
    const [current, setCurrent] = useState(0);
    const [currency, setCurrency] = useState<any>(undefined);
    const [travelWarrant, setTravelWarrant] = useState<any>(null);
    const [employeeTravelWarrant, setEmployeeTravelWarrant] = useState<any>(null);
    const [note, setNote] = useState<any>(undefined);
    const [formTravelWarrant] = Form.useForm();
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [formReport] = Form.useForm();
    const [loading, setLoading] = useState<any>(false);
    const [mandatoryFile, setMandatoryFile] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [reportData, setReportData] = useState('');
    const [modalTitle, setModalTitle] = useState(geti18nText('travelWarrantWizard.header.finish'));
    const [PDFModalTitle, setPDFModalTitle] = useState(geti18nText('travelWarrantWizard.PDFheader'));
    const [file, setFile] = useState<any>(undefined);
    const [loadingFile, setLoadingFile] = useState<boolean>(false);
    const [refreshWageSummary, setRefreshWageSummary] = useState(0);
    const componentRefPlan = useRef<any>();
    const componentRef = useRef<any>();
    const componentSummaryRef = useRef<any>();
    const componentDaysRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const [printPdfPlan, setPrintPdfPlan] = useState(false);
    const [resetWageExpenses, setResetWageExpenses] = useState(0);
    const [showReturnRouteModal, setShowReturnRouteModal] = useState(false);
    const [returnFields, setReturnFields] = useState(false);
    const [PDFModalVisible, setPDFModalVisible] = useState(false);
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isDateChange, setisDateChange] = useState(false);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const showCalculationDocument = useApplicationSetting('TRAVEL_WARRANT_SHOW_CALCULATION_DOCUMENT');
    const [includeEducationDate, setIncludeEducationDate] = useState<boolean>(false);
    const [payoutByAnotherOrganization, setPayoutByAnotherOrganization] = useState<boolean>(false);
    const [defaultEndDate, setDefaultEndDate] = useState<any>(moment());
    const [rerenderKey, setRerenderKey] = useState<any>(0);
    const WIZARD_STEP = TRAVEL_WARRANT_WIZARD_EDIT_STEPS;
    const [helpModalVisible, setHelpModalVisible] = useState(false);

    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [initWaypoints, setInitWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [travelWarrantType, setTravelWarrantType] = useState<any>(null);
    const [travelWarrantTypes, setTravelWarrantTypes] = useState<any>([]);
    const [wageAmountReceived, setWageAmountReceived] = useState<any>(0);
    const [changed, setChanged] = useState<boolean>(false);

    useEffect(() => {
        fetchTypes();
        getModuleAccessRights();
    }, []);

    useEffect(() => {
        if (!formReport.getFieldValue(['report'])) {
            formReport.setFieldsValue({ report: report });
            setReportData(report);
        }
    }, [report]);

    useEffect(() => {
        setReturnFields(returnRoute);
    }, [returnRoute]);

    useEffect(() => {
        if (dataForm != null && dataForm != undefined && dataForm >= 0) {
            setTravelWarrantValues(dataForm);
        } else {
            setShowReturnRouteModal(false);
        }
    }, [dataForm]);

    useEffect(() => {
        if (ordinal && travelVehicle) {
            setModalTitle(
                geti18nText('travelWarrantWizard.header.finish') +
                    ' - ' +
                    ordinal +
                    '/' +
                    warrantDate +
                    ' - ' +
                    travelVehicle.name
            );
        }
    }, [ordinal, travelVehicle]);

    useEffect(() => {
        if (destinationJson != null) {
            setDestinationValues(destinationJson);
            getInitWaypoints(destinationJson);
        }
    }, [destinationJson]);

    useEffect(() => {
        if (dataForm && dataForm > 0) {
            setValuesFiles(dataForm);
        }
    }, [refreshFiles]);

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    function setValuesFiles(travelWarrantId: any) {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(travelWarrantId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
        setLoading(false);
    }

    const setDefaultFilterValueFiles = (travelWarrantId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
        ];
    };

    const handleOk = (goBack: any = true) => {
        setVisible(false);
        if (!isOverview) {
            if (goBack) {
                history.goBack();
            }
        } else {
            setSummaryModalVisible(false);
        }
        if (setRefresh) setRefresh((refresh: number) => refresh + 1);
    };

    const handleCancel = () => {
        setVisible(false);
        setCurrency(null);
        setStartDate(null);
        setEndDate(null);
        setIncludeEducationDate(false);
    };

    const MissingWageList: any = (countries: any) => {
        return (
            <List style={{ maxHeight: '300px', overflow: 'auto' }} size="small">
                {countries.map((country: any) => {
                    return (
                        <List.Item>
                            <CloseCircleTwoTone twoToneColor="red" style={{ marginRight: '10px' }} />
                            {country}
                        </List.Item>
                    );
                })}
            </List>
        );
    };

    const generateTravelWarrantWage = async () => {
        const result = await NyRequestResolver.requestPost(
            CONSTANTS_REQ.TRAVEL_WARRANT.GENERATE_TRAVEL_WARRANT_WAGE_RECAPITULATION,
            undefined,
            {
                id: Number(dataForm),
            }
        )
            .then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    return true;
                } else {
                    let content = result.data.error;
                    const [error, error2] = result.data.error.split(' | ');
                    const [error3, error4] = result.data.error.split('|');
                    if (error && error2) {
                        const error2Parts = error2.split(/(?=ISO)/);
                        content = [geti18nText(error), MissingWageList(error2Parts)];
                    } else if (error3 && error4) {
                        content = [geti18nText(error3), ' ', error4];
                    }
                    console.log(content);

                    errorCustomNotification(geti18nText('app.default.save.nok'), content);
                    return false;
                }
            })
            .catch((error: any) => {
                console.log(error);
                return false;
            });
        return result;
    };

    const checkPlanValues: any = async () => {
        let save = true;
        await NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_PLAN.LIST_ALL, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'travelWarrant.id', condition: 'equals', value: dataForm },
                ])
            ),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    result.data.map((plan: any) => {
                        if (
                            !plan.startDate ||
                            plan.startDate == null ||
                            plan.startDate == undefined ||
                            !plan.endDate ||
                            plan.endDate == null ||
                            plan.endDate == undefined
                        ) {
                            save = false;
                        }
                    });
                }
            }
        });
        return save;
    };

    const updateEducationDates = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_EDUCATION_DATE + '/' + dataForm, undefined, {
            id: dataForm,
            educationStartDate: getDateFormat(formTravelWarrant.getFieldValue(['educationStartDate'])),
            educationEndDate: getDateFormat(formTravelWarrant.getFieldValue(['educationEndDate'])),
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                okNotification();
            } else {
                errorNotification();
            }
        });
    };

    const setType = () => {
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_TRAVEL_WARRANT_TYPE + '/' + dataForm,
            undefined,
            {
                id: dataForm,
                travelWarrantTypeId: travelWarrantType?.id,
            }
        )
            .then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (payoutByAnotherOrganization) {
                        saveIncludedAmountReceived();
                    }
                    if (includeEducationDate) {
                        updateEducationDates();
                    }
                } else {
                    const message = result?.data?._embedded?.errors[0]?.message.replace('Internal Server Error: ', '');
                    errorNotification(geti18nText(message));
                }
            })
            .finally(() => {
                setCurrent(current + 1);
            });
    };

    const next = async () => {
        if (steps[current]?.key == WIZARD_STEP.TYPE) {
            if (!checkDates()) {
                setShowReturnRouteModal(true);
            }
            if (includeEducationDate) {
                formTravelWarrant.getFieldValue(['educationStartDate']) &&
                formTravelWarrant.getFieldValue(['educationEndDate'])
                    ? setType()
                    : errorCustomNotification(
                          geti18nText('app.default.required'),
                          geti18nText('travelWarrantPersonal.notification.form')
                      );
            } else {
                setType();
            }
        } else {
            if (steps[current]?.key == WIZARD_STEP.DESTINATION_AND_TRANSPORT) {
                if (formTravelWarrant.getFieldValue('returnRoute') == undefined) {
                    formTravelWarrant.setFieldsValue({ returnRoute: false });
                }
                if (isDateChange) {
                    setResetWageExpenses((resetWageExpenses) => resetWageExpenses + 1);
                    setisDateChange(false);
                }

                const isSaveOk = await saveTravelWarrant();
                if (isSaveOk === false) return;
            }
            if (steps[current]?.key == WIZARD_STEP.TRIP_COURSE) {
                const isSaveOk = await checkPlanValues();
                if (isSaveOk === false) {
                    errorCustomNotification(
                        geti18nText('app.default.required'),
                        geti18nText('travelWarrantPersonal.notification.form')
                    );
                    return;
                } else if (travelWarrantType?.includeWage === true) {
                    const isSaveOkWage = await generateTravelWarrantWage();
                    if (isSaveOkWage === false) {
                        return;
                    }
                }
            }

            //rekapitulacija
            if (steps[current + 1]?.key == WIZARD_STEP.RECAPITULATION) {
                calculateRecapitulationData();
            }

            setCurrent(current + 1);
        }
    };

    const prev = () => {
        if (
            (steps[current]?.key == WIZARD_STEP.TRAVEL_BILL && showCalculationDocument > 0) ||
            (steps[current]?.key == WIZARD_STEP.RECAPITULATION && showCalculationDocument > 0) ||
            (steps[current - 1]?.key == WIZARD_STEP.RECAPITULATION && showCalculationDocument <= 0)
        ) {
            calculateRecapitulationData();
        }
        setCurrent(current - 1);
    };

    const checkDates = () => {
        return formTravelWarrant.getFieldValue('startDateTime') && formTravelWarrant.getFieldValue('endDateTime');
    };

    const saveReport = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_REPORT + '/' + dataForm, undefined, {
            id: dataForm,
            report: reportData,
        })
            .then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    handleCancel();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
            })
            .finally(() => setIsSaveLoading(false));
    };

    const onChange = (value: number) => {
        if (value == 6) {
            calculateRecapitulationData();
        }
    };

    const handleReturnRouteModalClose = (value: any) => {
        formTravelWarrant.setFieldsValue({ returnRoute: value });
        setReturnRoute(value);
        setShowReturnRouteModal(false);
    };

    const setFormValues = (resultData: any) => {
        setTravelWarrantType(resultData.travelWarrantType);
        setPayoutByAnotherOrganization(resultData.travelWarrantType?.payoutByAnotherOrganization);
        setIncludeEducationDate(resultData.travelWarrantType?.includeEducationDate);
        setNote(resultData.note && resultData.note.note ? resultData.note.note : undefined);
        if (resultData.employee && resultData.employee.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + resultData.employee.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setEmployeeTravelWarrant(result.data);
                        }
                    }
                }
            );
        }

        setCurrency(
            resultData.advancePaymentCurrency ? setSearchFormat(resultData.advancePaymentCurrency, 'isoCode') : null
        );
        if (resultData.startDate) {
            resultData.startDate = setDateFormat(resultData.startDate, 'yyyy-MM-DD HH:mm');
            setStartDate(resultData.startDate);
        } else {
            setStartDate(null);
        }
        if (resultData.endDate) {
            resultData.endDate = setDateFormat(resultData.endDate, 'yyyy-MM-DD HH:mm');
            setEndDate(resultData.endDate);
            endDate = resultData.endDate;
        } else {
            setEndDate(null);
        }

        if (resultData.educationStartDate) {
            resultData.educationStartDate = setDateFormat(resultData.educationStartDate);
        }
        if (resultData.educationEndDate) {
            resultData.educationEndDate = setDateFormat(resultData.educationEndDate);
        }

        let data: any = {
            startDate: resultData.startDate,
            endDate: resultData.endDate,
            wageAmountReceived: resultData.wageAmountReceived,
            travelVehicleExpenseAmountReceived: resultData.travelVehicleExpenseAmountReceived,
            educationStartDate: resultData.educationStartDate,
            educationEndDate: resultData.educationEndDate,
        };
        if (
            !(
                getDateFormat(resultData.startDate, 'HH:mm') == '00:00' &&
                getDateFormat(resultData.endDate, 'HH:mm') == '00:00'
            )
        ) {
            data['startDateTime'] = resultData.startDate;
            data['endDateTime'] = resultData.endDate;
        } else {
            if (travelWarrantTypes.length === 1) {
                setShowReturnRouteModal(true);
            }
            data['startDateTime'] = null;
            data['endDateTime'] = null;
        }
        formTravelWarrant.setFieldsValue(data);
    };

    const setTravelWarrantValues = (travelWarrantId: any) => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + travelWarrantId, undefined).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setTravelWarrant(result.data);
                    setFormValues(result.data);
                    formReport.setFieldsValue({ report: result.data?.report });
                }
                setLoading(false);
            }
        );
    };

    const calculateRecapitulationData = () => {
        if (dataForm) {
            setLoadingFile(true);
            setRefreshWageSummary((refreshWageSummary) => refreshWageSummary + 1);
            let parms = {
                lang: NyUtils.getSelectedLanguage(),
                subreportId: undefined,
                useDefault: true,
                exportType: enExportType.PDF,
                ...{ id: Number(dataForm) },
                v: Date.now(),
            };
            NyRequestResolver.requestGet(CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION, parms, false, true).then(
                (result: any) => {
                    message.destroy();
                    if (result.status === RESPONSE.OK && result.data) {
                        result.data.filename =
                            geti18nText('travel.warrant.print.pr') + moment().format('yyyyMMDD') + '.pdf';
                        setFile(result);
                        setLoadingFile(false);
                    } else {
                        setFile(undefined);
                        setLoadingFile(false);
                    }
                }
            );
            // NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT.CALCULATE_WITHOUT_STATUS_CHANGE, undefined, {
            //     id: dataForm,
            //     lang: NyUtils.getSelectedLanguage(),
            // }).then((result: any) => {
            //     if (result.status === RESPONSE.CREATED) {
            //         setRefreshWageSummary((refreshWageSummary) => refreshWageSummary + 1);
            //  setTravelWarrantValues(dataForm);

            //     } else if (result.data && result.data.error) {
            //         errorCustomNotification(geti18nText('app.default.error'), geti18nText(result.data.error));
            //     } else if (result.data && result.data instanceof String) {
            //         errorCustomNotification(geti18nText('app.default.error'), result.data);
            //     } else {
            //         errorCustomNotification(geti18nText('app.default.error'), '');
            //     }
            // });
        }
    };

    const saveIncludedAmountReceived = () => {
        {
            formTravelWarrant.validateFields().then((values: any) => {
                let saveValues: any = {};
                saveValues.id = dataForm;
                saveValues.wageAmountReceived = values.wageAmountReceived;
                saveValues.travelVehicleExpenseAmountReceived = values.travelVehicleExpenseAmountReceived;

                NyRequestResolver.requestPut(
                    CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_AMOUNT_RECEIVED + '/' + dataForm,
                    undefined,
                    saveValues
                ).then((result: any) => {
                    if (result && result.status === RESPONSE.OK) {
                        setWageAmountReceived(saveValues.wageAmountReceived);
                        setTravelWarrantValues(dataForm);
                        okNotification();
                    } else {
                        errorCustomNotification(geti18nText('app.default.save.nok'), '');
                    }
                });
            });
        }
    };

    const endTrip = () => {
        formReport.validateFields().then((values: any) => {
            if (!values.hasOwnProperty('report')) {
                values.report = reportData;
            }
            values.id = dataForm;
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TRAVEL_WARRANT.END_TRIP + '/' + dataForm,
                undefined,
                values
            ).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    if (setRefresh != undefined) setRefresh((prevValue: any) => prevValue + 1);

                    okNotification();
                    handleOk(false);
                } else {
                    errorCustomNotification(geti18nText('app.default.save.nok'), '');
                }
            });
        });
    };

    const getInitWaypoints = (destinationJson: any) => {
        let waypointsList: any = [];
        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );
        const lastItem = waypointsList[waypointsList.length - 1];
        if (lastItem?.id == 9999) {
            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
        }
        setInitWaypoints(waypointsList);
    };

    function setDestinationValues(destinationJson: any) {
        let waypointsList: any = [];
        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        let initList = [];
        setReturnRoute(false);
        if (lastItem?.id == 9999) {
            const firstItem = waypointsList[0];
            setReturnRoute(true);
            const returnList = waypointsList.filter((x: any) => x.id >= 9000 && x.id != 9999);
            initList = JSON.parse(JSON.stringify(returnList));
            returnIntermediateWaypointsInit.current = initList;
            let list = [firstItem].concat(returnList).concat(lastItem);

            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
            formTravelWarrant.setFieldsValue({ returnRoute: true });

            setReturnWaypoints(JSON.parse(JSON.stringify(list)));
        }
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);

        let data: any = {
            travelVehicle: setSearchFormat(travelVehicle, 'name', 'name'),
            destination: destinationJson[0]?.destination,
            startDate: startDate,
            endDate: endDate,
        };

        if (
            !(getDateFormat(startDate, 'HH:mm') == '00:00' && getDateFormat(endDate, 'HH:mm') == '00:00') &&
            isInitialRender == true
        ) {
            data['startDateTime'] = startDate;
            data['endDateTime'] = endDate;
            setIsInitialRender(false);
        }

        formTravelWarrant.setFieldsValue(data);
    }

    const normalizeTravelWarrantValues = (values: any, travelWarrantPlans: any) => {
        const travelWarrantPlansList: any = [];
        const newData = [...travelWarrantPlans];
        if (newData !== undefined && newData.length > 0) {
            var index = 0;
            newData.map((element: any) => {
                const nextItem = newData[index + 1];
                if (nextItem != undefined) {
                    const object: any = newData[index + 1];
                    let planItem: any = {};
                    if (object.countryId) planItem.country = getSearchFormat(object.countryId);
                    if (element.shortName && object.shortName)
                        planItem.destination = element.shortName + ' - ' + object.shortName;
                    if (values.travelVehicle) planItem.travelVehicle = values.travelVehicle;
                    if (element && object) planItem.relationJson = JSON.stringify([element, object]);
                    travelWarrantPlansList.push(planItem);
                }
                index += 1;
            });
        }
        setDestinationJson(travelWarrantPlansList);
        values.travelWarrantPlans = travelWarrantPlansList;
        return values;
    };

    const MissingCountryList: any = (countries: any) => {
        return (
            <List style={{ maxHeight: '300px', overflow: 'auto' }} size="small">
                {countries.map((country: any) => {
                    return (
                        <List.Item>
                            <CloseCircleTwoTone twoToneColor="red" style={{ marginRight: '10px' }} />
                            {geti18nText('travel.warrant.plan.error.country_not_found', [country])}
                        </List.Item>
                    );
                })}
            </List>
        );
    };

    const saveTravelWarrant: any = async () => {
        const formValues: any = await formTravelWarrant.validateFields();

        setIsLoading(true);

        let waypointsList = waypoints.filter(
            (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
        );
        let waypointsRes = JSON.parse(JSON.stringify(waypointsList));

        const first = waypointsList.at(0);
        const last = waypointsRes.at(-1);

        const isFirstNotEmpty = first && first.placeId != undefined;

        let name: any = '';

        if (isFirstNotEmpty && last) {
            waypointsList.map((item: any) => {
                const isLast = item.id == last.id;

                if (
                    item.id != 0 &&
                    (isLast ||
                        (!isLast &&
                            (!showCheckboxesDestinationName ||
                                (showCheckboxesDestinationName && item.showDestinationName))))
                ) {
                    if (item.city != item.shortName) {
                        let city = item.city ? item.city : '';
                        let short = item.shortName ? item.shortName : '';
                        name += city != '' ? city + ' - ' + short : short;
                    } else {
                        name += item.city;
                    }

                    if (!isLast) {
                        name += ', ';
                    }
                }
            });
        }

        if (isFirstNotEmpty && name == '') {
            name = formValues.name;
        }

        if (returnRoute == true) {
            let waypointsListReturn = returnWaypoints.filter(
                (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
            );
            let returnWaypointsRes = JSON.parse(JSON.stringify(waypointsListReturn));

            if (
                waypointsRes[0] != undefined &&
                waypointsRes[0].lat == returnWaypointsRes[returnWaypointsRes.length - 1]?.lat &&
                waypointsRes[0].lng == returnWaypointsRes[returnWaypointsRes.length - 1]?.lng
            ) {
                let i = 9000;
                returnWaypointsRes.forEach((element: any) => {
                    element.id = i;
                    i = i + 1;
                });

                returnWaypointsRes = returnWaypointsRes.splice(1);
                returnWaypointsRes[returnWaypointsRes.length - 1].id = 9999;
                waypointsRes = waypointsRes.concat(returnWaypointsRes);
            }
            if (returnWaypointsRes.length == 0 && waypointsRes[0]) {
                const start = JSON.parse(JSON.stringify(waypointsRes[0]));
                start.id = 9999;
                waypointsRes = waypointsRes.concat([start]);
            }
        }

        const startDate1 = getDateFormat(formValues.startDate, 'yyyy-MM-DD');
        const startDateTime1 = getDateFormat(formValues.startDateTime, 'HH:mm');
        const start = moment(startDate1 + ' ' + startDateTime1);

        const endDate1 = getDateFormat(formValues.endDate, 'yyyy-MM-DD');
        const endDateTime1 = getDateFormat(formValues.endDateTime, 'HH:mm');
        const end = moment(endDate1 + ' ' + endDateTime1);

        if (waypointsRes != undefined && waypointsRes.length > 0) {
            const isoCodes = waypointsRes
                .map((element: any) => element.countryCode)
                .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
                .filter((item: any) => item != null && item != '');

            let countryList: any = [];
            const result: any = await NyRequestResolver.requestPost(CONSTANTS_REQ.COUNTRY.GET_ISO_BY_CODES, undefined, {
                isoCodes: isoCodes,
            });

            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    countryList = result.data;
                }
            } else {
                errorCustomNotification(
                    geti18nText('app.default.save.nok'),
                    geti18nText('travel.warrant.plan.wizard.update.message.error')
                );
                setIsLoading(false);
                return false;
            }

            let missingCountriesList: any = [];
            const resWaypoints = waypointsRes.map((element: any) => {
                if (element.countryCode) {
                    element.countryId = countryList.find(
                        (x: any) => x.isoCode.toUpperCase() == element.countryCode.toUpperCase()
                    )?.id;
                    if (
                        element.countryId == undefined &&
                        !missingCountriesList.find((x: any) => x == element.countryCode.toUpperCase())
                    ) {
                        missingCountriesList.push(element.countryCode.toUpperCase());
                    }
                }
                return element;
            });

            if (missingCountriesList.length > 0) {
                errorCustomNotification(geti18nText('app.default.save.nok'), MissingCountryList(missingCountriesList));
                setIsLoading(false);
                return false;
            }
            let values = normalizeTravelWarrantValues(formValues, resWaypoints);

            values.destination = name;
            values.id = dataForm;

            if (waypoints[0]?.shortName) {
                values.startingPoint = waypoints[0]?.shortName;
            }

            values.startDate = getDateFormat(start, 'yyyy-MM-DD HH:mm');

            values.endDate = getDateFormat(end, 'yyyy-MM-DD HH:mm');

            setStartDate(start);
            setEndDate(end);
            values.lang = NyUtils.getSelectedLanguage();
            delete values.endDateTime;
            delete values.startDateTime;
            values.change = changed;
            const isSaved = await callSaveTravelWarrant(values); // here
            setIsLoading(false);
            setChanged(false);
            return isSaved;
        }
        return false;
    };

    const normalizeValues = (values: any, valuesPlans: any) => {
        if (values.startDate) {
            values.startDate = getDateFormat(moment(values.startDate).format('yyyy-MM-DD'));
        }
        if (values.endDate) {
            values.endDate = getDateFormat(moment(values.endDate).format('yyyy-MM-DD'));
        }
        if (values.warrantDate) {
            values.warrantDate = getDateFormat(moment(values.warrantDate).format('yyyy-MM-DD'));
        }
        if (values.educationStartDate) {
            values.educationStartDate = getDateFormat(moment(values.educationStartDate).format('yyyy-MM-DD'));
        }
        if (values.educationEndDate) {
            values.educationEndDate = getDateFormat(moment(values.educationEndDate).format('yyyy-MM-DD'));
        }
        values.destination = valuesPlans?.destination;
        return values;
    };

    async function callSaveTravelWarrant(values: any) {
        const result = await NyRequestResolver.requestPut(
            CONSTANTS_REQ.TRAVEL_WARRANT.EDIT_TRAVEL_WARRANT_PLANS + '/' + dataForm,
            undefined,
            values
        )
            .then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    okNotification(
                        geti18nText('app.default.save.ok'),
                        geti18nText('travel.warrant.plan.wizard.update.message.success')
                    );
                    return true;
                } else {
                    errorCustomNotification(
                        geti18nText('app.default.save.nok'),
                        geti18nText('travel.warrant.plan.wizard.update.message.error')
                    );
                    return false;
                }
            })
            .catch((error: any) => {
                console.log(error);
                return false;
            });
        return result;
    }

    const resetFirstStep = () => {
        resetDataForm();
        setFormValues(travelWarrant);
        setRefreshWageSummary((refreshWageSummary) => refreshWageSummary + 1);
        setShowReturnRouteModal(true);
    };

    const reset = () => {
        switch (current) {
            case 0:
                resetFirstStep();
                break;
            case 2:
                setResetWageExpenses((resetWageExpenses) => resetWageExpenses + 1);
                break;
            default:
                resetFirstStep();
                break;
        }
    };

    const disabledendDate = (current: any) => {
        if (formTravelWarrant.getFieldValue('startDate')) {
            return moment(current).add(1, 'days') < moment(formTravelWarrant.getFieldValue('startDate')).endOf('day');
        }
    };

    const disabledendEduDate = (current: any) => {
        if (formTravelWarrant.getFieldValue('educationStartDate')) {
            return (
                moment(current).add(1, 'days') <
                moment(formTravelWarrant.getFieldValue('educationStartDate')).endOf('day')
            );
        }
    };

    const onEndDateChange = (value: any) => {
        const changedDate = setDateFormat(value, 'yyyy-MM-DD HH:mm');
        setEndDate(changedDate);
        setisDateChange(true);
    };

    const fetchTypes = () => {
        const setDefaultFilterValue = () => {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        };

        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));

        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.LIST, {
            max: 999,
            search: stringUri,
            order: 'sort',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setLoading(false);
                if (result.data) {
                    setTravelWarrantTypes(result.data.content);
                    if (result.data.content.length === 1) {
                        setTravelWarrantType(result.data.content[0]);
                    }
                }
            }
        });
    };

    const steps2 = [
        ...[
            {
                title: geti18nText('travelWarrantWizard.step.2'),
                content: (
                    <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={formTravelWarrant}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('travelWarrantWizard.step2.date.info')}
                                type="info"
                                showIcon
                                style={{ margin: '5px' }}
                            />
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Form.Item
                                        name="startDate"
                                        label={geti18nText('travelWarrantWizard.step2.startDate')}
                                        initialValue={startDate}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            defaultPickerValue={startDate}
                                            showTime={false}
                                            format="DD.MM.YYYY"
                                            style={{ width: '100%' }}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="startDateTime"
                                        label={' '}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            defaultPickerValue={null}
                                            mode="time"
                                            format="HH:mm"
                                            style={{ width: '100%' }}
                                            autoFocus
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="endDate"
                                        label={geti18nText('travelWarrantWizard.step2.endDate')}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            defaultPickerValue={endDate}
                                            disabled={false}
                                            showTime={false}
                                            disabledDate={disabledendDate}
                                            format="DD.MM.YYYY"
                                            style={{ width: '100%' }}
                                            onChange={onEndDateChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="endDateTime"
                                        label={' '}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            defaultPickerValue={null}
                                            mode="time"
                                            format="HH:mm"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={geti18nText('travelWarrantWizard.step2.returnRoute')}
                                        name="returnRoute"
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            style={{ width: '30%' }}
                                            checked={returnRoute}
                                            onChange={(event: any) => {
                                                setReturnRoute(event.target.checked);
                                                setReturnFields(event.target.checked);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <TravelWarrantMap
                                    showMap={true}
                                    form={formTravelWarrant}
                                    waypoints={waypoints}
                                    setWaypoints={setWaypoints}
                                    maxWaypointsKey={maxWaypointsKey}
                                    setMaxWaypointsKey={setMaxWaypointsKey}
                                    nyTestId="map"
                                    isPlane={travelWarrant?.travelVehicle?.vehicleType == 3 || isPlane}
                                    returnRoute={returnFields}
                                    returnWaypoints={returnWaypoints}
                                    setReturnWaypoints={setReturnWaypoints}
                                    returnIntermediateWaypointsInit={returnIntermediateWaypointsInit.current}
                                    componentRefProp={componentRefPlan}
                                    printPdfProp={printPdfPlan}
                                    setPrintPdfProp={setPrintPdfPlan}
                                    printPdfHeader={modalTitle}
                                    hidePrint={true}
                                    setChanged={setChanged}
                                    showSetChanged
                                    showCheckboxesDestinationName={false}
                                    // initDestinationNameValues={initWaypoints}
                                    //destination={travelWarrant?.destination}
                                />
                            </Form.Item>
                        </Col>
                    </Form>
                ),
                key: WIZARD_STEP.DESTINATION_AND_TRANSPORT,
            },
            {
                title: geti18nText('travelWarrantWizard.step.8'),
                content: (
                    <TravelWarrantPlanSteps
                        travelWarrantId={dataForm}
                        isPlane={isPlane}
                        isWizard={true}
                        startDate={startDate}
                        endDate={endDate}
                    />
                ),
                key: WIZARD_STEP.TRIP_COURSE,
            },
        ],
        ...(travelWarrantType?.includeWage === true
            ? [
                  {
                      title: geti18nText('travelWarrantWizard.step.10'),
                      content: (
                          <TravelWarrantWageIndex
                              id={dataForm}
                              isWizard={true}
                              travelWarrant={travelWarrant}
                              componentDaysRef={componentDaysRef}
                              printPdf={printPdf}
                              travelWarrantType={travelWarrantType}
                              wageAmountReceived={wageAmountReceived}
                          />
                      ),
                      key: WIZARD_STEP.WAGES,
                  },
              ]
            : []),
        ...[],
        ...(isPersonalVehicle === true && travelWarrantType?.includeTravelVehicleExpenses === true
            ? [
                  {
                      title: geti18nText('travelWarrantWizard.step.11'),
                      content: (
                          <TravelWarrantVehicleIndex
                              isPersonalVehicle={isPersonalVehicle}
                              id={dataForm}
                              travelWarrantType={travelWarrantType}
                              travelWarrant={travelWarrant}
                          />
                      ),
                      key: WIZARD_STEP.PERSONAL_VEHICLE_EXPENSE,
                  },
              ]
            : []),
        ...(travelWarrantType?.includeTravelExpenses === true
            ? [
                  {
                      title: geti18nText('travelWarrantWizard.step.12'),
                      content: (
                          <TravelWarrantExpensesIndex
                              startDate={startDate}
                              refreshFiles={refreshFiles}
                              setRefreshFiles={setRefreshFiles}
                              mandatoryFile={mandatoryFile}
                              setMandatoryFile={setMandatoryFile}
                              id={dataForm}
                              canEditStatusAndCalculationDatePaid={false}
                          />
                      ),
                      key: WIZARD_STEP.OTHER_TRAVEL_WARRANT_EXPENSES,
                  },
              ]
            : []),
        ...[],
        ...[
            {
                title: geti18nText('travelWarrantWizard.step.13'),
                content: (
                    <>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Alert
                                    message={
                                        geti18nText('travelWarrantWizard.step.12.info') +
                                        (mandatoryFile ? ' ' + geti18nText('travelWarrantWizard.step.12.info2') : '')
                                    }
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: '5px' }}
                                />
                                <Alert
                                    message={geti18nText('travelWarrantWizard.table.column.files.deliver')}
                                    type="warning"
                                    showIcon
                                    style={{ marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <NyFilesUpload
                                    documentType={documentTypeEnum.TRAVEL_WARRANT_FILE}
                                    editUrl={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.EDIT}
                                    dataSourceFiles={files}
                                    setDataSourceFiles={setFiles}
                                    ref={uploadFilesRef}
                                    canAddNewFile={
                                        travelWarrantStatus &&
                                        travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL
                                    }
                                    canEdit={
                                        travelWarrantStatus &&
                                        travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL
                                    }
                                    canDelete={
                                        travelWarrantStatus &&
                                        travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL
                                    }
                                    id={dataForm}
                                    refId={dataForm}
                                    deleteValidationField="travelWarrantExpense"
                                    employee={employee}
                                    employeeTravelWarrantId={employeeTravelWarrant?.id}
                                    setRefreshDataSourceFiles={setRefreshFiles}
                                    showDocumentTypeColumn={true}
                                    isTravelWarrantEdit={true}
                                    columnStyleClass="description-div-large"
                                    showHeader={false}
                                    maxHeight={'455px'}
                                    isTravelWarrant={true}
                                    moduleAccessRight={moduleAccessRight}
                                    isBoldText
                                />
                            </Col>
                        </Row>
                    </>
                ),
                key: WIZARD_STEP.VIEW_ATTACHED_FILES,
            },
            {
                title: geti18nText('travelWarrantWizard.step.14'),
                content: (
                    <>
                        <div ref={componentRef}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <div ref={componentSummaryRef}>
                                        <TravelWarrantWageSummaryIndex
                                            travelWarrantId={dataForm}
                                            refreshWageSummary={refreshWageSummary}
                                            setRefreshWageSummary={setRefreshWageSummary}
                                            printPdf={printPdf}
                                        />
                                    </div>
                                </Col>
                                {/* <Col span={24}>
                                    <Descriptions
                                        style={{ marginTop: '10px' }}
                                        column={1}
                                        title={geti18nText('travelWarrantRelation.destination')}
                                        size="small"
                                    />
                                    <Form
                                        layout="vertical"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        form={formTravelWarrant}
                                    >
                                        <Form.Item>
                                            <TravelWarrantMap
                                                hidePrint={true}
                                                showMap={true}
                                                form={formTravelWarrant}
                                                waypoints={waypoints}
                                                setWaypoints={setWaypoints}
                                                maxWaypointsKey={maxWaypointsKey}
                                                setMaxWaypointsKey={setMaxWaypointsKey}
                                                nyTestId="map"
                                                isPlane={isPlane}
                                                displayOnly={true}
                                                returnRoute={returnRoute}
                                                returnWaypoints={returnWaypoints}
                                                setReturnWaypoints={setReturnWaypoints}
                                                returnIntermediateWaypointsInit={
                                                    returnIntermediateWaypointsInit.current
                                                }
                                            />
                                        </Form.Item>
                                    </Form>
                                </Col> F11086 - uklonjena karta*/}
                            </Row>
                        </div>
                        {showCalculationDocument > 0 ? null : (
                            <Row gutter={24} style={{ height: '30vh', marginTop: '10px', marginBottom: '-20px' }}>
                                <Col span={24}>
                                    <Descriptions
                                        column={1}
                                        title={geti18nText('travelWarrantRelation.calculationPdf')}
                                        size="small"
                                    />
                                    {file && (
                                        <>
                                            <Button
                                                icon={<FullscreenOutlined />}
                                                onClick={() => setPDFModalVisible(true)}
                                                style={{ marginBottom: '-30px', marginRight: '17px', float: 'right' }}
                                                ghost
                                            />
                                            <embed
                                                src={window.URL.createObjectURL(file.data.file).concat(
                                                    `#toolbar=0&navpanes=0&scrollbar=0&zoom=110&v=${Date.now()}`
                                                )}
                                                type="application/pdf"
                                                height="100%"
                                                width="100%"
                                            ></embed>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </>
                ),
                key: WIZARD_STEP.RECAPITULATION,
            },
        ],
        ...(showCalculationDocument > 0
            ? [
                  {
                      title: geti18nText('travelWarrantWizard.step.14.5'),
                      content: (
                          <div style={{ height: '65vh' }}>
                              <Row gutter={24}>
                                  <Col span={24}>
                                      <Descriptions
                                          column={1}
                                          title={geti18nText('travelWarrantRelation.calculationPdf')}
                                          size="small"
                                      />
                                      {loadingFile ? (
                                          <NySpinner />
                                      ) : (
                                          file && (
                                              <div style={{ height: '60vh' }}>
                                                  <embed
                                                      src={window.URL.createObjectURL(file.data.file).concat(
                                                          `#toolbar=0&navpanes=0&scrollbar=0&zoom=110&v=${Date.now()}`
                                                      )}
                                                      type="application/pdf"
                                                      height="100%"
                                                      width="100%"
                                                  ></embed>
                                              </div>
                                          )
                                      )}
                                  </Col>
                              </Row>
                          </div>
                      ),
                      key: WIZARD_STEP.TRAVEL_BILL,
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('travelWarrantWizard.step.15'),
                content: (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form
                                id={`repor-form-${dataForm}`}
                                form={formReport}
                                layout="vertical"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                onFinishFailed={({ errorFields }) => {
                                    formReport.scrollToField(errorFields[0].name);
                                }}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Alert
                                            message={geti18nText('travelWarrantPersonal.edit.report.info')}
                                            type="info"
                                            showIcon
                                            style={{ marginBottom: '5px', marginLeft: '12px', marginRight: '12px' }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.report')}
                                            name="report"
                                            initialValue={report}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                autoSize={{ minRows: 8, maxRows: 8 }}
                                                onKeyDown={onKeyDownTextAreaCustom}
                                                autoFocus
                                                onBlur={(e) => setReportData(e.target.value)}
                                                placeholder={geti18nText('travelWarrantPersonal.edit.report.mandatory')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                ),
                key: WIZARD_STEP.TRIP_REPORT,
            },
        ],
    ];

    const steps = [
        ...[
            ...(travelWarrantTypes.length > 1 || payoutByAnotherOrganization || includeEducationDate
                ? [
                      {
                          title: geti18nText('travelWarrantWizard.step.1.5'),
                          content: (
                              <>
                                  <TWTypeComponent
                                      formTravelWarrant={formTravelWarrant}
                                      setIncludeEducationDate={setIncludeEducationDate}
                                      includeEducationDate={includeEducationDate}
                                      setPayoutByAnotherOrganization={setPayoutByAnotherOrganization}
                                      travelWarrantType={travelWarrantType}
                                      setTravelWarrantType={setTravelWarrantType}
                                      payoutByAnotherOrganization={payoutByAnotherOrganization}
                                      disabledendEduDate={disabledendEduDate}
                                      showSearch={false}
                                      defaultEndDate={defaultEndDate}
                                      setDefaultEndDate={setDefaultEndDate}
                                      rerenderKey={rerenderKey}
                                      setRerenderKey={setRerenderKey}
                                      wageAmountReceived={wageAmountReceived}
                                      setWageAmountReceived={setWageAmountReceived}
                                  />
                              </>
                          ),
                          key: WIZARD_STEP.TYPE,
                      },
                  ]
                : []),
        ],
        ...steps2,
        ...[],
    ];

    const getTravelWarrantHistory = (status: any) => {
        return (
            <div>
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="topLeft"
                    content={<StatusHistoryIndex id={dataForm} type="travelWarrant" />}
                    style={{ marginLeft: '4rem' }}
                >
                    <div style={{ marginTop: '5px' }}> {getTravelWarrantStatusIcon(status)}</div>
                </Popover>
            </div>
        );
    };

    const getCustomFooterContent = (
        <>
            <div style={{ marginBottom: '30px' }}>
                <div style={{ float: 'left' }}>{getTravelWarrantHistory(travelWarrantStatus)}</div>
                <div style={{ float: 'right' }}>
                    <div className="steps-action">
                        {steps[current]?.key == WIZARD_STEP.DESTINATION_AND_TRANSPORT && (
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button key={'print'} icon={<PrinterOutlined />}>
                                            {geti18nText('travelWarrant.action.pdf.short')}
                                        </Button>
                                    );
                                }}
                                onBeforeGetContent={() => {
                                    setPrintPdfPlan(true);
                                    return Promise.resolve();
                                }}
                                onAfterPrint={() => {
                                    setPrintPdfPlan(false);
                                }}
                                content={() => componentRefPlan.current}
                                pageStyle={`@page {size: auto; margin: 10mm;}`}
                            />
                        )}
                        {steps[current]?.key == WIZARD_STEP.WAGES && (
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button
                                            key={'print'}
                                            style={{ display: 'inline-block' }}
                                            icon={<PrinterOutlined />}
                                        >
                                            {geti18nText('travelWarrant.action.pdf.short2')}
                                        </Button>
                                    );
                                }}
                                onBeforeGetContent={() => {
                                    setPrintPdf(true);
                                    return Promise.resolve();
                                }}
                                onAfterPrint={() => {
                                    setPrintPdf(false);
                                }}
                                content={() => componentDaysRef.current}
                                pageStyle={`@page {size: landscape;margin: 10mm;}`}
                            />
                        )}

                        {steps[current]?.key == WIZARD_STEP.RECAPITULATION && !printPdf && (
                            <>
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <Button
                                                key={'print'}
                                                style={{ display: 'inline' }}
                                                icon={<PrinterOutlined />}
                                            >
                                                {geti18nText('travelWarrant.action.pdf.short2')}
                                            </Button>
                                        );
                                    }}
                                    onBeforeGetContent={() => {
                                        setPrintPdf(true);
                                        return Promise.resolve();
                                    }}
                                    onAfterPrint={() => {
                                        setPrintPdf(false);
                                    }}
                                    content={() => componentSummaryRef.current}
                                    pageStyle={`@page {size: auto;margin: 10mm;}`}
                                />
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '8px',
                                        marginRight: '8px',
                                    }}
                                >
                                    <NyReportButton
                                        url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION}
                                        subreportType={'TRAVEL_WARRANT_CALCULATION'}
                                        customParms={{ id: Number(dataForm) }} //record id
                                        buttoni18nText={'travelWarrant.action.pdf.calculation'}
                                        fileName={geti18nText(
                                            'app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT_CALCULATION
                                        )}
                                        fileNameAddition={ordinal}
                                        type={'button'}
                                    />
                                </div>
                            </>
                        )}
                        {steps[current]?.key == WIZARD_STEP.TRAVEL_BILL && (
                            <div
                                style={{
                                    display: 'inline-block',
                                    marginLeft: '8px',
                                    marginRight: '8px',
                                }}
                            >
                                <NyReportButton
                                    url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION}
                                    subreportType={'TRAVEL_WARRANT_CALCULATION'}
                                    customParms={{ id: Number(dataForm) }} //record id
                                    buttoni18nText={'travelWarrant.action.pdf.calculation'}
                                    fileName={geti18nText(
                                        'app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT_CALCULATION
                                    )}
                                    fileNameAddition={ordinal}
                                    type={'button'}
                                />
                            </div>
                        )}
                        {(steps[current]?.key == WIZARD_STEP.DESTINATION_AND_TRANSPORT ||
                            steps[current]?.key == WIZARD_STEP.PAID_EXPENSES_MEALS) && (
                            <Button onClick={reset}>{geti18nText('travelWarrantWizard.reset')}</Button>
                        )}
                        <Button onClick={handleCancel}>{geti18nText('app.default.button.cancel')}</Button>
                        {current > 0 && (
                            <Button onClick={() => prev()} icon={<CaretLeftOutlined />}>
                                {geti18nText('travelWarrantWizard.previous')}
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button loading={isLoading} type="primary" onClick={() => next()}>
                                {geti18nText('travelWarrantWizard.next')}
                                <CaretRightOutlined />
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button
                                loading={isSaveLoading}
                                style={{ display: 'inline' }}
                                type="primary"
                                onClick={() => {
                                    saveReport();
                                    setIsSaveLoading(true);
                                }}
                            >
                                {geti18nText('app.default.button.save')}
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <NyModalConfirm
                                title={geti18nText('travelWarrantPersonal.send.confirm')}
                                onConfirm={() => {
                                    endTrip();
                                }}
                            >
                                <Button style={{ display: 'inline' }} type="primary">
                                    {geti18nText('travelWarrantPersonal.send')}
                                </Button>
                            </NyModalConfirm>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <React.Fragment>
            {showReturnRouteModal && (
                <Modal
                    title={
                        <span style={{ fontSize: '15px' }}>{geti18nText('travelWarrantWizard.step2.returnRoute')}</span>
                    }
                    visible={showReturnRouteModal}
                    width={350}
                    onOk={() => {
                        handleReturnRouteModalClose(true);
                    }}
                    onCancel={() => {
                        handleReturnRouteModalClose(false);
                    }}
                    okText={geti18nText('app.default.button.yes')}
                    cancelText={geti18nText('app.default.button.no')}
                    maskClosable={false}
                    centered={true}
                >
                    {geti18nText('travelWarrantWizard.step2.returnRoute.message')}
                </Modal>
            )}
            {visible && (
                <Modal
                    title={
                        <>
                            <span style={{ fontSize: '15px' }}>{modalTitle}</span>{' '}
                            {/* <DocumentationModal documentUrl={getTravelWizardEditDocsUrl(steps[current]?.key)} /> */}
                            <div
                                style={{
                                    display: 'block',
                                    float: 'right',
                                    marginRight: '24px',
                                    marginTop: '-2.7px',
                                }}
                            >
                                {getTravelWizardEditDocsUrl(steps[current]?.key) != undefined && (
                                    <Button
                                        onClick={() => setHelpModalVisible(!helpModalVisible)}
                                        icon={<QuestionCircleOutlined />}
                                        style={{ border: 'none' }}
                                    />
                                )}
                            </div>
                        </>
                    }
                    visible={visible}
                    onOk={handleOk}
                    width={helpModalVisible ? 2000 : 1600}
                    onCancel={handleCancel}
                    footer={getCustomFooterContent}
                    maskClosable={false}
                    style={{ top: '50px' }}
                >
                    <Row>
                        <Col span={helpModalVisible ? 18 : 24} style={helpModalVisible ? { paddingRight: '10px' } : {}}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    width: '100%',
                                    height: '75vh',
                                }}
                            >
                                <Steps
                                    current={current}
                                    onChange={onChange}
                                    size="small"
                                    progressDot
                                    responsive
                                    style={{ width: '100%' }}
                                >
                                    {steps.map((item: any) => (
                                        <Step
                                            key={item.title}
                                            title={item.title}
                                            style={{ pointerEvents: 'none', cursor: 'default' }}
                                        />
                                    ))}
                                </Steps>
                                {current === steps.length - 1 ? (
                                    <div className="steps-content">{steps[current].content}</div>
                                ) : loading ? (
                                    <NySpinner />
                                ) : (
                                    <div className="steps-content">{steps[current].content}</div>
                                )}
                            </div>
                        </Col>
                        {helpModalVisible && (
                            <Col span={6}>
                                <div
                                    style={{
                                        maxHeight: '75vh',
                                        overflowY: 'auto',
                                        overflowX: 'auto',
                                    }}
                                >
                                    <iframe
                                        style={{
                                            width: '100%',
                                            height: '60vh',
                                            border: '2px solid #bfbfbf',
                                        }}
                                        src={
                                            NySession.getSetting('docs') +
                                            getTravelWizardEditDocsUrl(steps[current]?.key)
                                        }
                                    ></iframe>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Modal>
            )}
            {PDFModalVisible && (
                <Modal
                    title={PDFModalTitle}
                    visible={PDFModalVisible}
                    width={1200}
                    onCancel={() => setPDFModalVisible(false)}
                    maskClosable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    style={{ top: '20px' }}
                >
                    <div style={{ height: '80vh' }}>
                        {file && (
                            <embed
                                src={window.URL.createObjectURL(file.data.file).concat(
                                    `#toolbar=0&navpanes=0&scrollbar=0&zoom=120&v=${Date.now()}`
                                )}
                                type="application/pdf"
                                height="100%"
                                width="100%"
                                style={{ borderRadius: '3px' }}
                            ></embed>
                        )}
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default TravelWarrantPersonalWizardEdit;
