import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';

interface IFavoriteState {
    employee?: any;
    employees?: any;
}

const initialState: IFavoriteState = {
    employee: undefined,
    employees: [],
};

const favorite = createSlice({
    name: 'favorite',
    initialState,
    reducers: {
        favoriteInit(state) {
            state.employee = undefined;
            state.employees = [];
        },
        setUserFavorites(state, action: PayloadAction<IFavoriteState>) {
            const { employees } = action.payload;
            state.employees = employees;
        },
        addEmployee(state, action: PayloadAction<IFavoriteState>) {
            const { employee } = action.payload;
            const index = state.employees.findIndex((row: any) => row.id === employee.id);
            if (index > -1) {
                state.employees = state.employees.filter((row: any) => row.id !== employee.id);
            } else {
                state.employees.push(employee);
            }

            let settingsValue = { employees: state.employees };
            let parms: any = {};
            parms.settingKey = 'favorites';
            parms.settingValue = JSON.stringify(settingsValue);

            NyRequestResolver.requestPost(CONSTANTS_REQ.USER_SESTTINGS.EDIT, undefined, parms);
        },
    },
});

export const { favoriteInit, setUserFavorites, addEmployee } = favorite.actions;

export default favorite.reducer;

export const fetchUserFavorites = (): AppThunk => async (dispatch) => {
    const settingKey = 'favorites';
    const result: any = await NyRequestResolver.requestGet(
        CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey,
        { settingKey: settingKey }
    );
    if (result.status == RESPONSE.OK) {
        let data = result.data;
        if (data && data.settingValue) {
            let settingsValue = JSON.parse(data.settingValue);
            delete settingsValue.id;
            dispatch(setUserFavorites({ ...settingsValue }));
        }
    }
};
