import { geti18nText, NyDatePicker, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, getDateFormat, okNotification } from '../../../../utils/Utils';
import CurrencySearch from '../currency/search';

const DownloadExchangeRateModal = ({ modalVisible, setModalVisible, refresh, setRefresh }: any) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);

    const disabledDateFrom = (current: any) => {
        return current > moment();
    };

    const disabledDateTo = (current: any) => {
        let dateFrom = form.getFieldValue(['dateFrom']);
        if (dateFrom && current) {
            return current < dateFrom.clone() || current > moment();
        } else {
            return current > moment();
        }
    };

    const downloadExchangeRate = () => {
        form.validateFields().then((values: any) => {
            setConfirmLoading(true);
            if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
            if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
            if (values.currency) {
                values.currencyIds = values.currency.map((val: any) => val.id);
            }

            NyRequestResolver.requestPost(CONSTANTS_REQ.EXCHANGE_RATE.SAVE, undefined, values).then((result: any) => {
                setConfirmLoading(false);
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    form.resetFields();
                    setModalVisible(false);
                    setRefresh(() => refresh + 1);
                } else {
                    errorNotification(geti18nText(result.data.error));
                }
            });
        });
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('exchangeRate.edit.downloadExchangeRate')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={() => {
                        downloadExchangeRate();
                    }}
                    onCancel={() => {
                        setModalVisible(false);
                        form.resetFields();
                    }}
                    confirmLoading={confirmLoading}
                    width={600}
                    maskClosable={false}
                >
                    <Form form={form} layout="vertical" {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <CurrencySearch
                                    label={geti18nText('exchangeRate.edit.currency')}
                                    name="currency"
                                    required={true}
                                    mode={'multiple'}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('exchangeRate.edit.dateFrom')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        disabledDate={disabledDateFrom}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('exchangeRate.edit.dateTo')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        disabledDate={disabledDateTo}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default DownloadExchangeRateModal;
