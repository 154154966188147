import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Form, Modal, Row } from 'antd';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import ItemSearch from '../../../../administration/views/item/search';

const ItemReplaceModal = ({
    orderId,
    itemEditData,
    setItemEditData,
    newItemModalVisible,
    setNewItemVisible,
    setItems,
    setEditingKey,
    type,
}: any) => {
    const [modalForm] = Form.useForm();
    const [loading, setLoading] = useState<any>(false);

    const replaceInventoryItem = (newItam: any) => {
        newItam.replaceble = true;
        let url = type === 4 ? CONSTANTS_REQ.ASSET_ORDER.REPLACE_ITEM : CONSTANTS_REQ.INVENTORY_ORDER.REPLACE_ITEM;
        NyRequestResolver.requestPut(url + '/' + orderId, undefined, {
            id: orderId,
            oldItemId: itemEditData.id,
            newItemId: newItam.id,
        })
            .then((result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    setItems((items: any) => {
                        const newData = [...items];
                        const index = items.findIndex((item: any) => itemEditData.id === item.item.id);
                        if (index > -1) {
                            const item = newData[index];
                            newData.splice(index, 1, { ...item, ...{ item: newItam } });
                            return newData;
                        }
                    });
                    setEditingKey('');
                    setItemEditData(undefined);
                    setNewItemVisible(false);
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <Modal
            visible={newItemModalVisible}
            title={geti18nText('assetOrder.itemReplace.title')}
            onOk={(event: any) => {
                event.stopPropagation();
                event.preventDefault();
                setLoading(true);
                modalForm
                    .validateFields()
                    .then((values: any) => {
                        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + values.item.id, undefined).then(
                            (result: any) => {
                                if (result.status === RESPONSE.OK) {
                                    if (result.data && itemEditData) {
                                        replaceInventoryItem(result.data);
                                    }
                                }
                            }
                        );
                    })
                    .catch((errorInfo: any) => {
                        console.log(errorInfo);
                    });
            }}
            onCancel={(event: any) => {
                setNewItemVisible(false);
                event.stopPropagation();
                event.preventDefault();
            }}
            forceRender={true}
            closable={false}
            okText={geti18nText('app.default.button.save')}
            confirmLoading={loading}
            maskClosable={false}
        >
            <Form form={modalForm} component={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <ItemSearch
                            itemIndexType={type}
                            itemGroupType={1}
                            itemIndexDisabled={true}
                            addedFilter={[
                                { field: 'type', condition: 'equals', value: type },
                                { field: 'availability_status', condition: 'equals', value: 1 },
                            ]}
                            required={true}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ItemReplaceModal;
