import {
    BankOutlined,
    CarOutlined,
    EditOutlined,
    HomeOutlined,
    MailOutlined,
    MobileOutlined,
    OrderedListOutlined,
    PhoneOutlined,
    SolutionOutlined,
    StarFilled,
    UserOutlined,
} from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Avatar, Card, Col, Descriptions, List, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../rootReducer';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TravelVehiclePersonalEdit from '../travel-warrant/views/travel-vehicle-personal/edit';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';

const GeneralIndex = ({ employee }: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active } = useSelector((state: RootState) => state.tabs);
    const { employees } = useSelector((state: RootState) => state.favorite);
    const [personalVehicles, setPersonalVehicles] = useState<any>([]);
    const [refresh, setRefresh] = useState(0);
    const [visible, setVisible] = useState<boolean>(false);
    const [value, setValue] = useState<any>(null);

    const canViewTravelWarrant = () => {
        return TravelWarrantRights.canViewOrCreateTravelWarrant();
    };

    useEffect(() => {
        fetchPersonalVehicleData();
    }, [employee, refresh]);

    const setDefaultFilterValue = (employeeId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employeeId', condition: 'equals', value: employeeId },
            { field: 'vehicle_type', condition: 'equals', value: 1 },
        ];
    };

    const fetchPersonalVehicleData = () => {
        if (employee && employee.id && canViewTravelWarrant()) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_VEHICLE.LIST, {
                search: encodeURI(JSON.stringify(setDefaultFilterValue(employee.id))),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data && result.data.content) {
                    setPersonalVehicles(result.data.content);
                }
            });
        }
    };

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '10px',
    };

    const openMail = (mail: any) => {
        if (mail) {
            window.open('mailto:' + mail, '_self');
        }
    };

    const callPhone = (number: any) => {
        if (number) {
            window.open('tel:' + number, '_self');
        }
    };

    const openEmployee = () => {
        history.push('/human/employee');
    };

    const openPersonalVehicle = (value: any) => {
        setValue(value);
        setVisible(true);
    };

    const openDirectory = () => {
        history.push('/directory');
    };

    return (
        <>
            <Row gutter={16}>
                <Col span={8}>
                    <Card
                        bordered={false}
                        title={
                            <>
                                {geti18nText('my.profile.personal.info')}
                                <div style={{ float: 'right', display: 'inline' }} onClick={openEmployee}>
                                    <Tooltip title={geti18nText('menu.employee')}>
                                        <UserOutlined />
                                    </Tooltip>
                                </div>
                            </>
                        }
                    >
                        <>
                            <Descriptions
                                column={1}
                                bordered
                                size={'small'}
                                style={{
                                    maxHeight: '200px',
                                    minHeight: '200px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                labelStyle={{ width: '10px', height: '10px', padding: '0px 8px' }}
                                contentStyle={{ height: '10px', padding: '0px 8px' }}
                            >
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.edit.eployment.record')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <OrderedListOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        {employee && employee.employmentRecordId ? employee.employmentRecordId : ''}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.edit.professional.vocation')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <SolutionOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span>{employee && employee.vocation ? employee.vocation.name : ''}</span>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.edit.employment.room')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <HomeOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span>{employee && employee.room ? employee.room.name : ''}</span>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.contact.edit.phone')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <PhoneOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span
                                        className="ny-contact"
                                        onClick={() =>
                                            callPhone(
                                                employee && employee.contact && employee.contact.phone
                                                    ? employee.contact.phone
                                                    : null
                                            )
                                        }
                                    >
                                        {employee && employee.contact && employee.contact.phone
                                            ? employee.contact.phone
                                            : ''}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.contact.edit.mobile')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <MobileOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span
                                        className="ny-contact"
                                        onClick={() =>
                                            callPhone(
                                                employee && employee.contact && employee.contact.mobile
                                                    ? employee.contact.mobile
                                                    : null
                                            )
                                        }
                                    >
                                        {employee && employee.contact && employee.contact.mobile
                                            ? employee.contact.mobile
                                            : ''}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.contact.edit.email')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <MailOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span
                                        className="ny-contact"
                                        onClick={() =>
                                            openMail(
                                                employee && employee.contact && employee.contact.email
                                                    ? employee.contact.email
                                                    : null
                                            )
                                        }
                                    >
                                        {employee && employee.contact && employee.contact.email
                                            ? employee.contact.email
                                            : ''}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Tooltip title={geti18nText('employee.edit.employment.businessUnit')}>
                                            <span style={{ fontWeight: 'bold', cursor: 'help' }}>
                                                <BankOutlined />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        {employee &&
                                            employee.businessUnits &&
                                            employee.businessUnits
                                                .map((businessUnit: any) => {
                                                    return (
                                                        <p style={{ marginBottom: '0px' }}>
                                                            {(businessUnit.code ? `[${businessUnit.code}] ` : '') +
                                                                (businessUnit.name ? businessUnit.name : '')}
                                                        </p>
                                                    );
                                                })
                                                .reverse()}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    </Card>
                </Col>
                {canViewTravelWarrant() && (
                    <Col span={8}>
                        <Card
                            bordered={false}
                            title={
                                <>
                                    {geti18nText('my.profile.my.vehicle')}
                                    <div
                                        style={{ float: 'right', display: 'inline' }}
                                        onClick={() => openPersonalVehicle(null)}
                                    >
                                        <Tooltip title={geti18nText('travelVehiclePersonal.table.header')}>
                                            <CarOutlined />
                                        </Tooltip>
                                    </div>
                                </>
                            }
                        >
                            <Card.Grid style={gridStyle}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <List
                                            style={{
                                                maxHeight: '200px',
                                                minHeight: '200px',
                                                overflowY: 'auto',
                                                overflowX: 'hidden',
                                            }}
                                            grid={{
                                                gutter: 12,
                                                xs: 1,
                                                sm: 1,
                                                md: 1,
                                                lg: 1,
                                                xl: 1,
                                                xxl: 1,
                                            }}
                                            dataSource={personalVehicles}
                                            renderItem={(personalVehicle: any) => (
                                                <List.Item
                                                    actions={[
                                                        <EditOutlined
                                                            onClick={() => openPersonalVehicle(personalVehicle)}
                                                            style={{ display: 'inline' }}
                                                        />,
                                                    ]}
                                                    className="ny-list-item"
                                                >
                                                    <CarOutlined style={{ display: 'inline', marginRight: '5px' }} />
                                                    {personalVehicle && personalVehicle.name}
                                                </List.Item>
                                            )}
                                            className="ny-directory-widget-list"
                                        />
                                    </Col>
                                </Row>
                            </Card.Grid>
                        </Card>
                    </Col>
                )}
                <Col span={8}>
                    <Card
                        bordered={false}
                        title={
                            <>
                                {geti18nText('menu.directory')}
                                <div style={{ float: 'right', display: 'inline' }} onClick={openDirectory}>
                                    <Tooltip title={geti18nText('menu.directory')}>
                                        <StarFilled style={{ color: '#ffd903' }} />
                                        {employees ? ' ' + employees.length : 0}
                                    </Tooltip>
                                </div>
                            </>
                        }
                    >
                        <Card.Grid className="ny-card-static" style={gridStyle}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <List
                                        style={{
                                            maxHeight: '200px',
                                            minHeight: '200px',
                                            overflowY: 'auto',
                                            overflowX: 'hidden',
                                        }}
                                        grid={{
                                            gutter: 24,
                                            xs: 1,
                                            sm: 1,
                                            md: 1,
                                            lg: 1,
                                            xl: 1,
                                            xxl: 1,
                                        }}
                                        dataSource={employees}
                                        renderItem={(employee: any) => (
                                            <List.Item>
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            employee && employee.person && employee.person.fileId ? (
                                                                <Avatar
                                                                    src={
                                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                        '/' +
                                                                        employee.person.fileId +
                                                                        '?tenant=' +
                                                                        NySession.getUser().tenantId
                                                                    }
                                                                />
                                                            ) : (
                                                                <Avatar icon={<UserOutlined />} />
                                                            )
                                                        }
                                                        title={
                                                            employee.person &&
                                                            employee.person.firstName + ' ' + employee.person.lastName
                                                        }
                                                        description={
                                                            <span>
                                                                {employee && employee.contact && employee.contact.id && (
                                                                    <Descriptions
                                                                        column={1}
                                                                        bordered
                                                                        size={'small'}
                                                                        style={{
                                                                            overflowY: 'auto',
                                                                            overflowX: 'hidden',
                                                                        }}
                                                                        labelStyle={{
                                                                            width: '10px',
                                                                            height: '10px',
                                                                            padding: '0px 8px',
                                                                        }}
                                                                        contentStyle={{
                                                                            height: '10px',
                                                                            padding: '0px 8px',
                                                                        }}
                                                                    >
                                                                        {employee &&
                                                                            employee.vocation &&
                                                                            employee.vocation.name && (
                                                                                <Descriptions.Item
                                                                                    label={
                                                                                        <Tooltip
                                                                                            title={geti18nText(
                                                                                                'employee.edit.professional.vocation'
                                                                                            )}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 'bold',
                                                                                                    cursor: 'help',
                                                                                                }}
                                                                                            >
                                                                                                <SolutionOutlined />
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span>
                                                                                        {employee && employee.vocation
                                                                                            ? employee.vocation.name
                                                                                            : ''}
                                                                                    </span>
                                                                                </Descriptions.Item>
                                                                            )}
                                                                        {employee &&
                                                                            employee.contact &&
                                                                            employee.contact.mobile && (
                                                                                <Descriptions.Item
                                                                                    label={
                                                                                        <Tooltip
                                                                                            title={geti18nText(
                                                                                                'employee.contact.edit.mobile'
                                                                                            )}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 'bold',
                                                                                                    cursor: 'help',
                                                                                                }}
                                                                                            >
                                                                                                <MobileOutlined />
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span
                                                                                        className="ny-contact"
                                                                                        onClick={() =>
                                                                                            callPhone(
                                                                                                employee.contact.mobile
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {employee.contact.mobile}
                                                                                    </span>
                                                                                </Descriptions.Item>
                                                                            )}
                                                                        {employee &&
                                                                            employee.contact &&
                                                                            employee.contact.email && (
                                                                                <Descriptions.Item
                                                                                    label={
                                                                                        <Tooltip
                                                                                            title={geti18nText(
                                                                                                'employee.contact.edit.email'
                                                                                            )}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 'bold',
                                                                                                    cursor: 'help',
                                                                                                }}
                                                                                            >
                                                                                                <MailOutlined />
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span
                                                                                        className="ny-contact"
                                                                                        onClick={() =>
                                                                                            openMail(
                                                                                                employee.contact.email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {employee.contact.email}
                                                                                    </span>
                                                                                </Descriptions.Item>
                                                                            )}
                                                                    </Descriptions>
                                                                )}
                                                            </span>
                                                        }
                                                    />
                                                </List.Item>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card.Grid>
                    </Card>
                </Col>
            </Row>
            {visible && (
                <TravelVehiclePersonalEdit
                    isModal={true}
                    visible={visible}
                    setVisible={setVisible}
                    value={value}
                    onSaveAndGetID={() => setRefresh((refresh) => refresh + 1)}
                />
            )}
        </>
    );
};
export default GeneralIndex;
