import {
    geti18nText,
    NyColorPicker,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NySearchField,
    NySession,
} from '@nybble/nyreact';
import { Col, Descriptions, Form, Input, Row, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import {
    formatJoppdCodeSearch,
    getEnumArray,
    isValidJson,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';
import { regexCheck } from '../../../../utils/Validation';
import { CronInput } from './CronInput';
import { AdministrationRights } from '../../../../rights/administrationRights';

const ApplicationSettingsEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('app.setting.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [enumDataType, setEnumDataType] = useState('');
    const [pattern, setPattern] = useState<any>('');
    const [value, setValue] = useState<any>('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [group, setGroup] = useState('');
    const [sourceType, setSourceType] = useState<any>('');
    const [defaultFilter, setDefaultFilter] = useState<any>('');
    const [codebookSourceFirstParam, setCodebookSourceFirstParam] = useState<string>('');
    const [codebookSourceSecondParam, setCodebookSourceSecondParam] = useState<any>('');
    const [settingKey, setSettingKey] = useState<any>(null);
    const [cronErrors, setCronErrors] = useState(0);
    const [cronChanged, setCronChanged] = useState(false);

    function getCronErrorArray(errors: number) {
        let array = [];
        for (let i = 0; i < 5; i++) {
            if (errors & (1 << i)) {
                array.push(geti18nText('app.setting.cron.info.message.' + i));
            }
        }
        return array;
    }

    const EnNotificationType = useEnum('NOTIFICATION_TYPE');

    async function setValues(dataForm: any) {
        if ((dataForm.value === undefined || dataForm.value === null) && dataForm.defaultValue !== undefined)
            dataForm.value = dataForm.defaultValue;

        let en = GetEnumNameForValue({
            enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
            value: dataForm.dataType,
        });

        switch (en) {
            case 'TEXT':
                setValue(dataForm.value);
                dataForm.value = dataForm.value;
                setPattern(dataForm.pattern);
                break;
            case 'NUMBER':
                setValue(dataForm.value);
                dataForm.value = dataForm.value;
                if (dataForm.pattern) setPattern(JSON.parse(dataForm.pattern));
                break;
            case 'BOOL':
                setValue(dataForm.value === '1');
                dataForm.value = dataForm.value == '1';
                setPattern(dataForm.pattern);
                break;
            case 'DATE':
            case 'TIMESTAMP':
                setValue(moment(dataForm.value, dataForm.pattern));
                dataForm.value = moment(dataForm.value, dataForm.pattern);
                setPattern(dataForm.pattern);
                break;
            case 'TIME':
                setValue(moment(dataForm.value, dataForm.pattern ? dataForm.pattern : 'HH:mm'));
                dataForm.value = moment(dataForm.value, dataForm.pattern ? dataForm.pattern : 'HH:mm');
                setPattern(dataForm.pattern ? dataForm.pattern : 'HH:mm');
                break;
            case 'ENUM':
                setValue(Number(dataForm.value));
                setPattern(dataForm.pattern);
                setSourceType(dataForm.sourceType);
                dataForm.value = Number(dataForm.value);
                break;
            case 'CODEBOOK':
                if (isValidJson(dataForm.value)) {
                    setValue(JSON.parse(dataForm.value));
                    dataForm.value = JSON.parse(dataForm.value);
                } else {
                    setValue(dataForm.value);
                    dataForm.value = dataForm.value;
                }
                setSourceType(dataForm.sourceType);
                splitSourceType(dataForm.sourceType);
                setPattern(dataForm.pattern);
                break;
            case 'JOPPD':
                let defFilter = JSON.parse(dataForm.defaultFilter);
                dataForm.value = await formatJoppdCodeSearch(dataForm.value, defFilter.page, defFilter.node);
                setValue(dataForm.value);
                setSourceType(dataForm.sourceType);
                splitSourceType(dataForm.sourceType);
                setPattern(dataForm.pattern);
                setDefaultFilter(defFilter);
                break;
            case 'ENUM_MULTI':
                let values = dataForm.value;
                if (values) {
                    values = values.split(',').map((value: any) => Number(value));
                }
                setValue(values);
                setPattern(dataForm.pattern);
                setSourceType(dataForm.sourceType);
                dataForm.value = values;
                break;
            case 'CRON':
                setValue(dataForm.value);
                dataForm.value = dataForm.value;
                setPattern(dataForm.pattern);
                break;
        }

        setEnumDataType(en ? en : '');

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(dataForm.id);
        }

        let sModule = GetEnumNameForValue({
            enumName: 'APPLICATION_SETTINGS_MODULE_TYPE',
            value: dataForm.settingModule,
        });

        setDescription('app.setting.description.' + sModule + '.' + dataForm.settingGroup + '.' + dataForm.settingKey);
        setName('app.setting.name.' + sModule + '.' + dataForm.settingGroup + '.' + dataForm.settingKey);
        setGroup('app.setting.group.name.' + sModule + '.' + dataForm.settingGroup);
        setSettingKey(dataForm.settingKey);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('country.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const splitSourceType = (data: string) => {
        let temp: string[] = data.split('.', 2);
        setCodebookSourceFirstParam(temp[0]);
        setCodebookSourceSecondParam(temp[1]);
    };

    const validateRegex = (rule: any, value: any, callback: any) => {
        if (
            enumDataType === 'TEXT' &&
            value !== undefined &&
            pattern !== undefined &&
            value !== '' &&
            !regexCheck(value, pattern)
        ) {
            return callback(geti18nText('app.setting.edit.text.not.correct'));
        } else if (enumDataType === 'CRON' && value !== undefined && cronChanged) {
            if (cronErrors) {
                return callback(
                    geti18nText('app.setting.cron.error.message', [getCronErrorArray(cronErrors).join(', ')])
                );
            }
        }
        return callback();
    };

    const filterAllowedTypes = () => {
        let types = getEnumArray(sourceType, 'label', 'value');
        //Onemogući direktno odobrenje zahtjeva iz e - maila za tipove notifikacija == opcija u polju 'Službena putovanja'
        if (settingKey == 'APPROVE_REQUEST_FROM_EMAIL_FOR_NOTIFICATION_TYPE') {
            return types.filter((item: any) =>
                Object.values([
                    EnNotificationType.TRAVEL_WARRANT_APPROVAL,
                    EnNotificationType.TRAVEL_WARRANT_PAYMENT,
                    EnNotificationType.TRAVEL_WARRANT_PAYMENT_APPROVAL,
                ]).includes(item.value)
            );
        } else {
            return types;
        }
    };

    const getDataTypeInput = () => {
        switch (enumDataType) {
            case 'TEXT':
                return <Input />;
            case 'NUMBER':
                let min, max, dec;
                if (pattern && pattern.min) min = pattern.min;
                if (pattern && pattern.max) max = pattern.max;
                if (pattern && pattern.dec) dec = pattern.dec;
                return <NyInputNumber min={min} max={max} decimalPlaces={dec} isDecimal={dec !== undefined} />;
            case 'BOOL':
                return (
                    <Checkbox
                        defaultChecked={value}
                        onChange={(val: any) => {
                            setValue(val.target.checked);
                        }}
                    />
                );
            case 'DATE':
                return <NyDatePicker format={pattern} />;
            case 'TIME':
                return <NyDatePicker mode={'time'} format={pattern ? pattern : 'HH:mm'} />;
            case 'TIMESTAMP':
                return <NyDatePicker showTime={true} format={pattern} />;
            case 'ENUM':
                return <Select options={getEnumArray(sourceType, 'label', 'value')} autoFocus={true} />;
            case 'CODEBOOK':
                return (
                    <NySearchField
                        url={CONSTANTS_REQ[codebookSourceFirstParam][codebookSourceSecondParam]}
                        map={{ id: 'id', label: pattern }}
                        style={{ width: '100%' }}
                        searchBy={pattern}
                        order={pattern}
                        className=""
                        onChange={(data: any) => {
                            if (!data.isoCode) {
                                setValue(data);
                            } else {
                                setValue(data.isoCode);
                            }
                        }}
                        defaultValue={value}
                    />
                );
            case 'JOPPD':
                const seteDefaultFilterValue = () => {
                    return setJoppdCodeDefaultFilterValue(defaultFilter.page, defaultFilter.node);
                };
                return (
                    <NySearchField
                        url={CONSTANTS_REQ[codebookSourceFirstParam][codebookSourceSecondParam]}
                        map={{ id: 'id', label: pattern }}
                        style={{ width: '100%' }}
                        searchBy={pattern}
                        onChange={(data: any) => {
                            setValue(data);
                        }}
                        setDefaultFilterValue={seteDefaultFilterValue}
                        SearchPopupComponent={<JoppdCodeIndex defaultFilteredValue={seteDefaultFilterValue} />}
                    />
                );
            case 'ENUM_MULTI':
                return (
                    <Select
                        options={filterAllowedTypes()}
                        //options={getEnumArray(sourceType, 'label', 'value')}
                        mode="multiple"
                        onChange={(value: any) => {
                            setValue(value);
                        }}
                        autoFocus={true}
                    />
                );
            case 'COLOR':
                return <NyColorPicker></NyColorPicker>;
            case 'CRON':
                return (
                    <CronInput
                        value={value}
                        setValue={setValue}
                        cronErrors={cronErrors}
                        setCronErrors={setCronErrors}
                        setCronChanged={setCronChanged}
                        autoFocus={true}
                    ></CronInput>
                );
        }
    };

    const canCreate = () => {
        return AdministrationRights.canCreateSettingsAdmin();
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.APPLICATION_SETTINGS.EDIT}
            setValues={setValues}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={true}
            width={400}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (enumDataType == 'ENUM_MULTI') {
                    values.value = value.join(',') + '';
                } else if (values.value !== undefined) {
                    if (enumDataType == 'DATE' || enumDataType == 'TIME' || enumDataType == 'TIMESTAMP') {
                        values.value = moment(values.value).format(pattern);
                    } else if (enumDataType == 'CODEBOOK') {
                        if (!values.value.isoCode) {
                            values.value = JSON.stringify(value);
                        } else {
                            values.value = value;
                        }
                    } else if (enumDataType == 'BOOL') {
                        form.setFieldsValue({ value: value });
                        if (value) {
                            values.value = '1';
                        } else {
                            values.value = '0';
                        }
                    } else if (enumDataType == 'JOPPD') {
                        values.value = value.code;
                    } else if (enumDataType == 'CRON') {
                        form.setFieldsValue({ value: value });
                        values.value = value;
                    } else {
                        values.value = values.value + '';
                    }
                }
                return {
                    id: values.id,
                    value: values.value,
                };
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Descriptions column={1}>
                        <Descriptions.Item
                            label={<span style={{ fontWeight: 'bold' }}>{geti18nText('app.setting.edit.name')}</span>}
                        >
                            {geti18nText(name)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <span style={{ fontWeight: 'bold' }}>
                                    {geti18nText('app.setting.edit.description')}
                                </span>
                            }
                        >
                            {geti18nText(description)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={<span style={{ fontWeight: 'bold' }}>{geti18nText('app.setting.edit.group')}</span>}
                        >
                            {geti18nText(group)}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Descriptions column={1}>
                        <Descriptions.Item
                            label={
                                <span style={{ fontWeight: 'bold', float: 'left' }}>
                                    {geti18nText('app.setting.edit.value')}
                                </span>
                            }
                        >
                            {''}
                        </Descriptions.Item>
                    </Descriptions>
                    <Form.Item
                        style={{ marginLeft: '-16px', marginRight: '-16px' }}
                        name="value"
                        rules={[
                            {
                                validator: validateRegex,
                            },
                        ]}
                    >
                        {getDataTypeInput()}
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ApplicationSettingsEdit;
