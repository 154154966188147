import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import {
    generateUuid,
    getDateFormat,
    getEnumArray,
    getEnumArrayStatus,
    getEnumBooleanArray,
    getOrdinal,
    getTravelWarrantBookingStatusTag,
    getTravelWarrantStatusIcon,
    numberFormat,
} from '../../../../utils/Utils';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { useEffect } from 'react';

const TravelOverviewGeneral = ({ scroll = { y: 500, x: 5600 }, sortOrder }: any) => {
    const location = useLocation();
    useHelper('travel_order/travel_costs_overview');

    const travelWarrantSyncType = useApplicationSetting('TRAVEL_WARRANT_SYNC_TYPE');
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [];
    };

    const columns: any = [
        {
            title: geti18nText('travelOverview.general.table.column.id'),
            dataIndex: 'ordinal',
            width: '130px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            fixed: 'left',
            render: (text: any, record: any) => {
                if (record && record.ordinal && record.warrantDate) {
                    return getOrdinal(record);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.status'),
            dataIndex: 'status',
            width: '190px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TRAVEL_WARRANT_STATUS')),
            fixed: 'left',
            render: (status: any) => {
                return getTravelWarrantStatusIcon(status);
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.employee'),
            dataIndex: 'employeeName',
            width: '160px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.type'),
            dataIndex: 'typeName',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.warrant.date'),
            dataIndex: 'warrantDate',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (warrantDate: any, record: any) => {
                if (record.warrantDate) {
                    return getDateFormat(record.warrantDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.startDate'),
            dataIndex: 'startDate',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.endDate'),
            dataIndex: 'endDate',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (endDate: any, record: any) => {
                if (record.endDate) {
                    return getDateFormat(record.endDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.duration'),
            dataIndex: 'duration',
            width: '160px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.destination'),
            dataIndex: 'destination',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.purpose'),
            dataIndex: 'purposeInfo',
            width: '160px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (purposeInfo: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={purposeInfo}>
                        {purposeInfo}
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.accountingCostCenterCode'),
            dataIndex: 'accountingCostCenterCode',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_CENTER.SEARCH,
                'accountingCostCenterId',
                'code',
                'code',
                {
                    id: 'id',
                    label: 'code',
                    text: 'code',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.general.table.column.accountingCostCenterName'),
            dataIndex: 'accountingCostCenterName',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_CENTER.SEARCH,
                'accountingCostCenterId',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.general.table.column.accountingCostObjectCode'),
            dataIndex: 'accountingCostObjectCode',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_OBJECT.SEARCH,
                'accountingCostObjectId',
                'code',
                'code',
                {
                    id: 'id',
                    label: 'code',
                    text: 'code',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.general.table.column.accountingCostObjectName'),
            dataIndex: 'accountingCostObjectName',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.COST_OBJECT.SEARCH,
                'accountingCostObjectId',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.general.table.column.travelEventNumber'),
            dataIndex: 'evenNumberName',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.TRAVEL_EVENT_NUMBER.SEARCH,
                'evenNumberId',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelOverview.general.table.column.vehicleName'),
            dataIndex: 'vehicleName',
            width: '180px',
            render: (record: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={record}>
                        <div className={'description-div-small'}>
                            <p className="description-paragraph">{record}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH, 'travelVehicleId', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('travelOverview.general.table.column.power'),
            dataIndex: 'power',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.weight'),
            dataIndex: 'weight',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.co2.emission'),
            dataIndex: 'co2Emission',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.co2.emission.sum'),
            dataIndex: 'co2EmissionSumAmount',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelOverview.general.table.column.calculation.date'),
            dataIndex: 'calculationDate',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (calculationDate: any) => {
                if (calculationDate) {
                    return getDateFormat(calculationDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.advance'),
            dataIndex: 'advance',
            width: '140px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (advance: any) => {
                if (advance) {
                    return numberFormat(advance);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.advance.paid'),
            dataIndex: 'advancePaid',
            width: '140px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { advancePaid: any }) => {
                if (record.advancePaid) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelOverview.general.table.column.days.wage'),
            dataIndex: 'totalDaysWage',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (totalDaysWage: any) => {
                if (totalDaysWage) {
                    return numberFormat(totalDaysWage);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.wage'),
            dataIndex: 'totalWage',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (totalWage: any) => {
                if (totalWage) {
                    return numberFormat(totalWage);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.vehicle.expense'),
            dataIndex: 'travelVehicleExpenseAmountReceived',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (travelVehicleExpenseAmountReceived: any) => {
                if (travelVehicleExpenseAmountReceived) {
                    return numberFormat(travelVehicleExpenseAmountReceived);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.total.expense'),
            dataIndex: 'totalExpenses',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (totalExpenses: any) => {
                if (totalExpenses != undefined) {
                    return numberFormat(totalExpenses);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.payment'),
            dataIndex: 'payment',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (payment: any) => {
                if (payment) {
                    return numberFormat(payment);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.payment.paid'),
            dataIndex: 'paymentPaid',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { paymentPaid: any }) => {
                if (record.paymentPaid) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelOverview.general.table.column.payment.return'),
            dataIndex: 'paymentReturn',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (paymentReturn: any) => {
                if (paymentReturn) {
                    return numberFormat(paymentReturn);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.payment.return.paid'),
            dataIndex: 'paymentReturnPaid',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { paymentReturnPaid: any }) => {
                if (record.paymentReturnPaid) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelOverview.general.table.column.taxable.expenses'),
            dataIndex: 'taxableExpenses',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (taxableExpenses: any) => {
                if (taxableExpenses) {
                    return numberFormat(taxableExpenses);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.taxable.expenses.paid'),
            dataIndex: 'taxablePaid',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { taxablePaid: any }) => {
                if (record.taxablePaid) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelOverview.general.table.column.not.paid.expenses'),
            dataIndex: 'notPaidExpenses',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (notPaidExpenses: any) => {
                if (notPaidExpenses) {
                    return numberFormat(notPaidExpenses);
                }
            },
        },
        {
            title: geti18nText('travelOverview.general.table.column.expenses.total'),
            dataIndex: 'total',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (total: any) => {
                if (total) {
                    return numberFormat(total);
                }
            },
        },
        ...(travelWarrantSyncType == 'point'
            ? [
                  {
                      title: geti18nText('travelOverview.general.table.column.bookTimestamp'),
                      dataIndex: 'bookTimestamp',
                      sorter: (a: any, b: any) => {},
                      ...getColumnDateOption(false),
                      render: (bookTimestamp: any) => {
                          if (bookTimestamp) {
                              return getDateFormat(bookTimestamp, 'DD.MM.YYYY');
                          }
                      },
                  },
                  {
                      title: geti18nText('travelOverview.general.table.column.bookStatus'),
                      dataIndex: 'bookStatus',
                      width: '160px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchOption(getEnumArray('TRAVEL_WARRANT_BOOK_STATUS')),
                      render: (text: any, record: any) => {
                          if (record.bookStatus !== undefined) {
                              return getTravelWarrantBookingStatusTag(record.bookStatus, record.bookResponseError);
                          }
                      },
                  },
              ]
            : []),
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-warrant-overview"
            url={CONSTANTS_REQ.TRAVEL_WARRANT.OVERVIEW_LIST}
            rowKey={`${generateUuid()}`}
            hideButtons
            onRowSelect={() => null}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll ?? { y: 620, x: columns.length * 120 }}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            exportCSV={true}
            CSVFileName={geti18nText('travelOverview.costs.table.header')}
            colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + value);
            },
        },
        {
            startDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            endDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            costDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            warrantDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            calculationDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            advancePaid: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            taxableExpensesPaid: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            paymentPaid: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            paymentReturnPaid: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            advance: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            wage: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            travelVehicleExpenseAmountReceived: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            payment: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            return: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            taxableExpenses: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            notPaidExpenses: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            totalDaysWage: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            total: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value ?? 0, 2);
            },
        },
    ];
};

export default TravelOverviewGeneral;
