import { InfoCircleOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyInputNumber, NySearchField, NySession } from '@nybble/nyreact';
import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEmployeeSelectOption,
    getSearchFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';
import TravelFuelTypeSearch from '../travel-fuel-type/search';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelVehiclePersonalEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelVehiclePersonal.table.header'));
    const enableCarbonFootprint = useApplicationSetting('TRAVEL_WARRANT_ENABLE_CARBON_FOOTPRINT');
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);
    const [dataForm, setDataForm] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isPrivate, setIsPrivate] = useState<Boolean>(true);

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelVehicle();
    };

    const canEdit = () => {
        return TravelWarrantRights.canEditTravelVehicle();
    };

    const hideButtons = () => {
        return (!dataForm && !canCreate()) || (dataForm && !canEdit());
    };

    useEffect(() => {
        if (employee != undefined) {
            form.setFieldsValue({
                employee: getEmployeeSelectOption(employee),
                isPrivate: true,
                // name: geti18nText('travelVehiclePersonal.edit.name'),
            });
        }
    }, [employee]);

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.model) {
            let title = dataForm.model;
            if (dataForm.licencePlate) {
                title = title + ' [' + dataForm.licencePlate + ']';
            }
            setEditHeader(geti18nText('travelVehicle.edit.title') + ' - ' + title.toUpperCase());
        }
        if (dataForm.travelFuelType) {
            dataForm.travelFuelType = setSearchFormat(dataForm.travelFuelType, 'name', 'name');
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setIsPrivate(true);
        setEditHeader(geti18nText('travelVehicle.edit.new'));
        setDataForm(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const generateVehicleName = () => {
        const type = geti18nText('app.enum.TRAVEL_VEHICLE_TYPE.1');
        const model = form.getFieldValue('model');
        const licencePlate = form.getFieldValue('licencePlate');

        let name = '';
        if (type) {
            name += type;
        }
        if (model) {
            if (name) {
                name += ' - ';
            }
            name += model;
        }
        if (licencePlate) {
            if (name) {
                name += ' ';
            }
            name += '[' + licencePlate + ']';
        }

        form.setFieldsValue({ name: name });
    };

    const capitalizeLicencePlate = (e: any) => {
        const type = geti18nText('app.enum.TRAVEL_VEHICLE_TYPE.1');
        const model = form.getFieldValue('model');
        if (e.target.value) {
            form.setFieldsValue({ licencePlate: e.target.value.toUpperCase() });
        }

        let name = '';
        if (type) {
            name += type;
        }
        if (model) {
            if (name) {
                name += ' - ';
            }
            name += model;
        }
        if (e.target.value) {
            if (name) {
                name += ' ';
            }
            name += '[' + e.target.value.toUpperCase() + ']';
        }
        form.setFieldsValue({ name: name });
    };

    const licencePlateText = () => {
        return (
            <Tooltip title={geti18nText('travelVehicle.edit.licensePlate.tooltip')}>
                {geti18nText('travelVehicle.edit.licencePlate')}
                <Button icon={<InfoCircleOutlined />} type="text" size="small" style={{ pointerEvents: 'none' }} />
            </Tooltip>
        );
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_VEHICLE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.vehicleType = 1;
                if (values.employee) values.employee = getSearchFormat(values.employee);
                if (values.travelFuelType) values.travelFuelType = getSearchFormat(values.travelFuelType);

                return values;
            }}
            hideSubmitButton={hideButtons()}
            hideActivationButtons={hideButtons()}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'none' }}
                        label={geti18nText('travelVehicle.edit.employee')}
                        name="employee"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            disabled={true}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="travel-vehicle-personal-edit"/>}
                            nyTestId="employee"
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('travelVehicle.edit.model')}
                        name="model"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} disabled={!isPrivate} onChange={() => generateVehicleName()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={licencePlateText()}
                        name="licencePlate"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                            {
                                //validator: validateLicencePlate,
                            },
                        ]}
                    >
                        <Input
                            onBlur={(e) => capitalizeLicencePlate(e)}
                            maxLength={8}
                            defaultValue={''}
                            disabled={!isPrivate}
                            onChange={() => generateVehicleName()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('travelVehicle.edit.name')}
                        name="name"
                        // initialValue={geti18nText('travelVehiclePersonal.edit.name')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <TravelFuelTypeSearch required={enableCarbonFootprint == 1} />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelVehicle.edit.averageFuelConsumption')}
                        name="averageFuelConsumption"
                        rules={[
                            {
                                required: enableCarbonFootprint == 1,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            min={0}
                            max={99.99}
                            isDecimal={true}
                            decimalPlaces={2}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelVehicle.edit.weight')} name="weight">
                        <NyInputNumber
                            style={{ width: '100%' }}
                            min={0}
                            max={9999.99}
                            isDecimal={true}
                            decimalPlaces={2}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={geti18nText('travelVehicle.edit.power')} name="power">
                        <NyInputNumber
                            style={{ width: '100%' }}
                            min={0}
                            max={999.99}
                            isDecimal={true}
                            decimalPlaces={2}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelVehicle.edit.co2.emission')}
                        tooltip={
                            <div style={{ marginLeft: '5px' }}>
                                {geti18nText('travelVehicle.edit.co2.emission.label')}
                            </div>
                        }
                        name="co2Emission"
                    >
                        <NyInputNumber
                            style={{ width: '100%' }}
                            min={0}
                            max={999.99}
                            isDecimal={true}
                            decimalPlaces={2}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TravelVehiclePersonalEdit;
