import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue, GetEnumValueForName } from '../../../../utils/Enums';
import {
    customAccountRenderOption,
    customRenderTextOption,
    getAssetSelectOption,
    getEnumArray,
    getEnumArrayWithGroup,
    getEnumArrayWithKey,
    getEnumFormat,
    getNySearchFieldEnumInitValue,
    getSearchFormat,
    setEnumFormat,
    setEnumFormatSubgroup,
    setEnumFormatWithGroupAndKey,
    setSearchFormat,
} from '../../../../utils/Utils';
import AccountIndex from '../account';
import AccountEntity from './account-entity';
import AccountEdit from '../account/edit';
import TravelWarrantTypeSearch from '../../../travel-warrant/views/travel-warrant-type/search';
import { AdministrationRights } from '../../../../rights/administrationRights';
import AccountSearch from '../assets/asset-adp/search';

const AccountingEntityAccountEdit = (props: any) => {
    const enAccountEntityTypeGroup = useEnum('ACCOUNTING_ENTITY_TYPE_GROUP');
    const enAccountEntryType = useEnum('ACCOUNT_ENTRY_TYPE');
    const [editHeader, setEditHeader] = useState(geti18nText('accountingEntityAccount.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState<any>(null);
    const [selectedType, setSelectedType] = useState<any>(null);
    const [assetName, setAssetName] = useState('');
    const focusInput = useRef<any>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [entityTypeArray, setEntityTypeArray] = useState(
        getEnumArrayWithGroup('ACCOUNTING_ENTITY_TYPE', 'accounting_entity_type_group')
    );
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (selectedGroup) {
            const groupKey = getKey(enAccountEntityTypeGroup, selectedGroup);
            setGroupFilter(groupKey);
        }
    }, [selectedGroup]);

    const isAccountTypeDebitOrCredit = (value: any) => {
        return Object.values([enAccountEntryType.DEBIT, enAccountEntryType.CREDIT]).includes(value);
    };

    const canCreate = () => {
        return AdministrationRights.canCreateAccounting();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const disabledAccountEntity = (refTypeList: any = []) => {
        return (
            (isEdit && props?.addedData?.refIdValue != null) ||
            Object.values(refTypeList).includes(props?.addedData?.refTypeValue) ||
            !canCreate()
        );
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('accountingEntityAccount.edit.title'));
        if (dataForm.id) {
            setIsEdit(true);
            setEditHeader(geti18nText('accountingEntityAccount.edit.title') + ' (ID: ' + dataForm.id + ')');
        }
        if (dataForm.accountingEntityTypeGroup) {
            setEntityArray({ id: dataForm.accountingEntityTypeGroup });
            setSelectedGroup(dataForm.accountingEntityTypeGroup);
            dataForm.accountingEntityTypeGroup = setEnumFormat(
                'ACCOUNTING_ENTITY_TYPE_GROUP',
                dataForm.accountingEntityTypeGroup
            );
        }
        if (dataForm.accountingEntityType) {
            const tempValue: any = setEnumFormatSubgroup('ACCOUNTING_ENTITY_TYPE', dataForm.accountingEntityType);
            dataForm.accountingEntityType = { id: tempValue?.id?.value, text: tempValue?.text };
            setSelectedType(dataForm?.accountingEntityType?.id);
        }
        if (dataForm.travelWarrantType) {
            dataForm.travelWarrantType = setSearchFormat(dataForm.travelWarrantType);
        }

        if (dataForm.travelWarrantTypeId) {
            dataForm.travelWarrantType = setSearchFormat(dataForm.travelWarrantTypeId);
        }

        if (dataForm.account) {
            let name = '';
            if (dataForm.account.code) {
                name = name.concat('(' + dataForm.account.code + ') ');
            }
            if (dataForm.account.name) {
                name = name.concat(dataForm.account.name);
            }
            dataForm.account.name = name;
            dataForm.account = setSearchFormat(dataForm.account, 'name', 'name');
        }

        if (dataForm.refId && dataForm.accountingEntityTypeGroup) {
            getEntity(dataForm.refId, dataForm.accountingEntityTypeGroup, dataForm.accountingEntityType);
        }
        if (dataForm.type && isAccountTypeDebitOrCredit(dataForm.type)) {
            dataForm.type = setEnumFormat('ACCOUNT_ENTRY_TYPE', dataForm.type);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setSelectedGroup(null);
        setSelectedType(null);
        setAssetName('');
        setDataForm(null);
        setIsEdit(false);
        resetFormValues();
        setEditHeader(geti18nText('accountingEntityAccount.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (props.value == undefined || props.value == null || props.value.id == undefined || props.value.id == null) {
            resolvePropValues();
        }
    };

    const resolvePropValues = () => {
        if (props && props.addedData && props.addedData.refIdValue != null && props.addedData.refTypeValue != null) {
            const tempValue: any = setEnumFormatSubgroup('ACCOUNTING_ENTITY_TYPE', props?.addedData?.extraRefTypeValue);
            setSelectedType(tempValue?.id);
            if (
                props?.addedData?.extraRefIdValue != null &&
                props?.addedData?.extraRefTypeValue != null &&
                props?.addedData?.extraRefTypeValue == 'ASSET_DEPRECIATION_GROUP'
            ) {
                getEntityValue(props.addedData.extraRefIdValue, 'DEPRECIATION_GROUP');
            } else {
                getEntityValue(
                    props.addedData.refIdValue,
                    props.addedData.refTypeValue ==
                        GetEnumNameForValue({
                            enumName: 'ACCOUNTING_ENTITY_TYPE_GROUP',
                            value: enAccountEntityTypeGroup.TRAVEL_WARRANT,
                        })
                        ? props.addedData.extraRefTypeValue
                        : props.addedData.refTypeValue
                );
            }

            if (props.addedData.refTypeValue == 'DEPRECIATION_GROUP' && !props.addedData.extraRefIdValue) {
                setSelectedGroup(enAccountEntityTypeGroup.ASSET_DEPRECIATION_GROUP);
            } else {
                setSelectedGroup(
                    GetEnumValueForName({
                        enumName: 'ACCOUNTING_ENTITY_TYPE_GROUP',
                        name: props.addedData.refTypeValue,
                    })
                );
            }
        }
    };

    const groupOptions = () => {
        if (
            selectedGroup == enAccountEntityTypeGroup.ASSET &&
            (props?.addedData?.isIndex === false || !props?.addedData?.isIndex)
        ) {
            return getEnumArrayWithKey('ACCOUNTING_ENTITY_TYPE_GROUP').filter((en: any) => {
                return Object.values([
                    enAccountEntityTypeGroup.ASSET,
                    enAccountEntityTypeGroup.ASSET_DEPRECIATION_GROUP,
                ]).includes(en.id);
            });
        } else {
            return getEnumArrayWithKey('ACCOUNTING_ENTITY_TYPE_GROUP');
        }
    };

    const getKey = (map: any, targetValue: any) => {
        for (const [key, value] of Object.entries(map)) {
            if (value === targetValue) {
                return key;
            }
        }
        return undefined;
    };

    const setGroupFilter = (groupKey: any) => {
        setEntityTypeArray(
            getEnumArrayWithGroup('ACCOUNTING_ENTITY_TYPE', 'accounting_entity_type_group').filter((val: any) => {
                return (
                    val?.accounting_entity_type_group && val.accounting_entity_type_group.includes(groupKey.toString())
                );
            })
        );
    };

    const setEntityArray = (value: any) => {
        let groupKey: any = null;
        form.setFieldsValue({ accountingEntityType: null });
        if (value?.text?.props && value?.text?.props['custom-data'].key) {
            groupKey = value?.text?.props['custom-data'].key;
        } else if (value.id != -1) {
            groupKey = getKey(enAccountEntityTypeGroup, value.id);
        }

        if (value.id == -1) {
            setEntityTypeArray(getEnumArrayWithGroup('ACCOUNTING_ENTITY_TYPE', 'accounting_entity_type_group'));
        } else if (groupKey) {
            setGroupFilter(groupKey);
        }
    };

    const resetFormValues = () => {
        form.setFieldsValue({ asset: null });
        form.setFieldsValue({ depreciationGroup: null });
        form.setFieldsValue({ travelExpense: null });
        form.setFieldsValue({ travelWage: null });
    };

    const onGroupChange = (value: any) => {
        if (value && value.id > 0) {
            setSelectedGroup(value.id);
            setSelectedType(null);
            setEntityArray(value);
            resetFormValues();

            if (dataForm == undefined || dataForm == null) {
                resolvePropValues();
            }
        } else {
            setSelectedGroup(null);
            setSelectedType(null);
            form.setFieldsValue({ accountingEntityType: null });
            setEntityTypeArray(getEnumArrayWithGroup('ACCOUNTING_ENTITY_TYPE', 'accounting_entity_type_group'));
            resetFormValues();
            if (dataForm == undefined || dataForm == null) {
                resolvePropValues();
            }
        }
    };

    const getEntity = (refId: any, group: any, type: any = null) => {
        switch (group?.id) {
            case 1:
                getEntityValue(refId, 'ASSET');
                break;
            case 2:
                getEntityValue(refId, 'DEPRECIATION_GROUP');
                break;
            case 3:
                switch (type?.id) {
                    case 6:
                        getEntityValue(refId, 'TRAVEL_WAGE');
                        break;
                    case 8:
                        getEntityValue(refId, 'TRAVEL_EXPENSE');
                        break;
                }

                break;
            default:
                break;
        }
    };

    const getEntityValue = (refId: any, route: any) => {
        if (refId && refId > -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ[route]['EDIT'] + '/' + refId).then((result: any) => {
                if (result.data) {
                    if (
                        result.data.assetModel &&
                        route ==
                            GetEnumNameForValue({
                                enumName: 'ACCOUNTING_ENTITY_TYPE_GROUP',
                                value: enAccountEntityTypeGroup.ASSET,
                            })
                    ) {
                        form.setFieldsValue({ asset: getAssetSelectOption(result.data) });
                        setAssetName(result.data.assetModel.name);
                    } else if (route == 'DEPRECIATION_GROUP') {
                        form.setFieldsValue({ depreciationGroup: setSearchFormat(result.data, 'name', 'name') });
                    } else if (route == 'TRAVEL_WAGE') {
                        form.setFieldsValue({ travelWage: setSearchFormat(result.data, 'name', 'name') });
                    } else if (route == 'TRAVEL_EXPENSE') {
                        form.setFieldsValue({ travelExpense: setSearchFormat(result.data, 'name', 'name') });
                    }
                }
            });
        }
    };

    const onAssetChange = (value: any) => {
        if (value && value.id > 0) {
            const group: any = { id: 1 };
            getEntity(value.id, group);
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ACCOUNTING_ENTITY_ACCOUNT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.accountingEntityTypeGroup) {
                    values.accountingEntityTypeGroup = getEnumFormat(values.accountingEntityTypeGroup);
                }
                if (values.accountingEntityType) {
                    values.accountingEntityType = getEnumFormat(values.accountingEntityType);
                }
                if (values.type) {
                    values.type = getEnumFormat(values.type);
                }
                if (values.account) {
                    values.account = getSearchFormat(values.account);
                }
                if (values.travelExpense) {
                    values.travelExpense = getSearchFormat(values.travelExpense);
                }
                if (values.travelWarrantType) {
                    values.travelWarrantType = getSearchFormat(values.travelWarrantType);
                    values.travelWarrantTypeId = values.travelWarrantType.id;
                }
                if (values.travelWage) {
                    values.travelWage = getSearchFormat(values.travelWage);
                }
                if (selectedGroup == enAccountEntityTypeGroup.ASSET) {
                    if (values.asset) {
                        values.refId = values.asset;
                        values.refName = assetName;
                    }
                    delete values.asset;
                } else if (selectedGroup == enAccountEntityTypeGroup.ASSET_DEPRECIATION_GROUP) {
                    if (values.depreciationGroup) {
                        values.refId = values.depreciationGroup.id;
                        values.refName = values.depreciationGroup.name;
                    }
                    delete values.depreciationGroup;
                }
                if (selectedGroup == enAccountEntityTypeGroup.TRAVEL_WARRANT) {
                    if (props?.addedData?.extraRefTypeValue == 'TRAVEL_EXPENSE' && values.travelExpense) {
                        values.refId = values.travelExpense.id;
                        values.refName = values.travelExpense.name;
                        delete values.travelExpense;
                    } else if (props?.addedData?.extraRefTypeValue == 'TRAVEL_WAGE' && values.travelWage) {
                        values.refId = values.travelWage.id;
                        values.refName = values.travelWage.name;
                        delete values.travelWage;
                    }
                }

                if (selectedGroup == enAccountEntityTypeGroup.TRAVEL_WARRANT) {
                    if (values.taxable == null) {
                        values.taxable = false;
                    }
                } else {
                    delete values.taxable;
                }

                delete values.refIdValue;
                delete values.refTypeValue;
                delete values.extraRefIdValue;
                delete values.extraRefTypeValue;
                delete values.travelWarrantType;

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('accountingEntityAccount.edit.group')}
                        name="accountingEntityTypeGroup"
                        initialValue={getNySearchFieldEnumInitValue(
                            'ACCOUNTING_ENTITY_TYPE_GROUP',
                            props?.addedData?.refTypeValue == 'DEPRECIATION_GROUP'
                                ? 'ASSET_DEPRECIATION_GROUP'
                                : props?.addedData?.refTypeValue,
                            null
                        )}
                    >
                        <NySearchField
                            options={groupOptions()}
                            map={{ id: 'id', label: 'text', key: 'key' }}
                            renderOption={customRenderTextOption}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                            onChange={onGroupChange}
                            mustGetPopupContainer={false}
                            disabled={disabledAccountEntity(['DEPRECIATION_GROUP', 'TRAVEL_WARRANT'])}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('accountingEntityAccount.edit.type')}
                        name="accountingEntityType"
                        initialValue={setEnumFormatWithGroupAndKey(
                            'ACCOUNTING_ENTITY_TYPE',
                            props?.addedData?.extraRefTypeValue,
                            null,
                            getEnumArrayWithGroup('ACCOUNTING_ENTITY_TYPE', 'accounting_entity_type_group')
                        )}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField // filter
                            options={entityTypeArray}
                            map={{ id: 'id', label: 'text' }}
                            renderOption={customRenderTextOption}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                            mustGetPopupContainer={false}
                            disabled={disabledAccountEntity(['TRAVEL_WARRANT'])}
                            onChange={(value: any) => setSelectedType(value?.id)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <AccountSearch
                        label={geti18nText('accountingEntityAccount.edit.account')}
                        name="account"
                        required={true}
                        AddNewModalComponent
                        setDefaultFilterValue={setDefaultFilterValue}
                        disabled={!canCreate()}
                        onChange={(value: any) => {
                            if (value.id != 0 && typeof value.name === 'object') {
                                const children =
                                    value && value.name && value.name.props && value.name.props.children
                                        ? value?.name?.props?.children
                                        : null;
                                const type = children[1] && children[1]?.props && children[1]?.props?.children;
                                form.setFieldsValue({
                                    type: isAccountTypeDebitOrCredit(type)
                                        ? setEnumFormat('ACCOUNT_ENTRY_TYPE', type)
                                        : null,
                                });
                            }
                        }}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('accountingEntityAccount.edit.accountType')} name="type">
                        <NySearchField
                            options={getEnumArray('ACCOUNT_ENTRY_TYPE').filter((en: any) => {
                                return isAccountTypeDebitOrCredit(en.id);
                            })}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {selectedGroup == enAccountEntityTypeGroup.TRAVEL_WARRANT && (
                <Row gutter={24}>
                    <Col span={12}>
                        <TravelWarrantTypeSearch required={false} style={{ width: '100%' }} isSearchTable />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('accountingEntityAccount.edit.taxable')}
                            name="taxable"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Row gutter={24}>
                <Col span={12}>
                    <AccountEntity
                        selectedGroup={selectedGroup}
                        selectedType={selectedType}
                        refIdValue={props?.addedData?.refIdValue}
                        refTypeValue={props?.addedData?.refTypeValue}
                        extraRefIdValue={props?.addedData?.extraRefIdValue}
                        extraRefTypeValue={props?.addedData?.extraRefTypeValue}
                        canCreate={canCreate()}
                        onAssetChange={onAssetChange}
                        form={form}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AccountingEntityAccountEdit;
