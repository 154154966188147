import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnDateOption, getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Form } from 'antd';
import moment from 'moment';
import { ReactText, useState } from 'react';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookUserPerson,
} from '../../../../utils/Filters';
import { errorNotification, getEnumArray, getEnumBooleanArray, okNotification } from '../../../../utils/Utils';
import AccessRightEdit from './edit';

const AccessRightIndex = ({
    accessRightType = undefined,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const moduleAccessRightsEnum = useEnum('MODULE_ACCESS_RIGHT');
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);
    const [filterDate, setFilterDate] = useState(undefined);
    const [selectedValuesDelete, setSelectedValuesDelete] = useState<any>([]);
    const [hasSelectedDelete, setHasSelectedDelete] = useState(false);

    const setDefaultFilterValue = () => {
        let filter = table
            .setDefaultFilterValue()
            .filter((item: any) => item.field !== 'filter_date' && item.field !== 'active');

        if (filterDate) {
            filter.push({
                field: 'filter_date',
                condition: 'equals',
                value: moment(filterDate).format('YYYY-MM-DD HH:mm'),
            });
        } else {
            filter.push({ field: 'active', condition: 'equals_bool', value: 1 });
        }
        return filter;
    };

    const canCreate = () => {
        if (AdministrationRights.isAdmin()) {
            return true;
        } else if (accessRightType == moduleAccessRightsEnum.TRAVEL_WARRANT) {
            return TravelWarrantRights.canCreateAppSettings();
        } else if (
            accessRightType == moduleAccessRightsEnum.LEAVE ||
            accessRightType == moduleAccessRightsEnum.ATTENDANCE_REGISTRATION
        ) {
            return HumanResourcesRights.canCreateAttendanceandLeaveSettings();
        } else {
            return AdministrationRights.canCreateSettings();
        }
    };

    let clearKeysDelete: any;

    const setSelectedValuesFuncDelete = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDelete(hasSelected);
        setSelectedValuesDelete(selectedRowKeys);
        clearKeysDelete = clearSelectedRowKeys;
    };

    const initialColumns = [
        ...[
            {
                title: geti18nText('menu.access.rights.table.id'),
                dataIndex: 'id',
                width: '5%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
        ],
        ...(accessRightType == undefined
            ? [
                  {
                      title: geti18nText('menu.access.rights.table.type'),
                      dataIndex: 'accessRightType',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchCheckbox(
                          getEnumArray('MODULE_ACCESS_RIGHT', undefined, undefined, 'MODULE_ACCESS_RIGHT')
                      ),
                      render: (text: any, record: any) => {
                          if (record.accessRightType !== undefined) {
                              return geti18nText('app.enum.MODULE_ACCESS_RIGHT.' + record.accessRightType);
                          }
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('menu.access.rights.table.employee'),
                dataIndex:
                    "concat(user_person.first_name, ' ', user_person.last_name, ' ', coalesce(cast(user_employee.employment_record_id as varchar),''))",
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookUserPerson(),
                render: (text: any, record: any) => {
                    if (record.user && record.user.person) {
                        return (
                            `${record.user.person.firstName} ${record.user.person.lastName}` +
                            (record.user.employee && record.user.employee.employmentRecordId
                                ? ` (${record.user.employee.employmentRecordId})`
                                : '')
                        );
                    }
                },
            },
            {
                title: geti18nText('menu.access.rights.table.for.employee'),
                dataIndex:
                    "concat(employee_person.first_name, ' ', employee_person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),''))",
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookEmployee(),
                render: (text: any, record: any) => {
                    if (record.employee && record.employee.person) {
                        return (
                            `${record.employee.person.firstName} ${record.employee.person.lastName}` +
                            (record.employee.employmentRecordId ? ` (${record.employee.employmentRecordId})` : '')
                        );
                    }
                },
            },
            {
                title: geti18nText('menu.access.rights.table.for.unit'),
                dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookBusinessUnit(),
                render: (text: any, record: any) => {
                    if (record.businessUnit) {
                        return (
                            record.businessUnit.name +
                            (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                        );
                    }
                },
            },
            {
                title: geti18nText('menu.access.rights.table.readOnly'),
                dataIndex: 'readOnly',
                width: '8%',
                render: (text: any, record: { readOnly: any }) => {
                    if (record.readOnly) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            },
            {
                title: geti18nText('menu.access.rights.table.from'),
                dataIndex: 'created',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.created) {
                        return moment(record.created).format('DD.MM.YYYY HH:mm');
                    }
                },
            },
            {
                title: geti18nText('menu.access.rights.table.to'),
                dataIndex: 'updated',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (!record.active && record.updated) {
                        return moment(record.updated).format('DD.MM.YYYY HH:mm');
                    }
                },
            },
        ],
    ];

    const OnFilterDateChange = (value: any) => {
        setFilterDate(value);
        setRefreshTable(refreshTable + 1);
    };

    const deleteAccessRights = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ACCESS_RIGHTS.DELETE, undefined, {
            ids: selectedValuesDelete,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDelete) {
                    clearKeysDelete();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification();
            }
        });
    };

    const addedButtons = (onSave: () => void) => {
        return (
            canCreate() && (
                <div style={{ float: 'left', paddingLeft: '15px' }}>
                    <NyModalConfirm
                        title={geti18nText('app.default.destructive.confirm')}
                        onConfirm={() => {
                            deleteAccessRights();
                        }}
                    >
                        <Button type="primary" disabled={!hasSelectedDelete} danger icon={<DeleteOutlined />}>
                            {geti18nText('app.default.button.deactivate')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )
        );
    };

    const getListUrl = () => {
        let url = '';
        if (accessRightType == moduleAccessRightsEnum.TRAVEL_WARRANT) {
            url = CONSTANTS_REQ.ACCESS_RIGHTS.LIST_TRAVEL;
        } else if (accessRightType == moduleAccessRightsEnum.LEAVE) {
            url = CONSTANTS_REQ.ACCESS_RIGHTS.LIST_HR;
        } else if (accessRightType == moduleAccessRightsEnum.ATTENDANCE_REGISTRATION) {
            url = CONSTANTS_REQ.ACCESS_RIGHTS.LIST_ATTENDANCE;
        } else {
            url = CONSTANTS_REQ.ACCESS_RIGHTS.LIST;
        }
        return url;
    };

    return (
        <>
            <Form.Item label={geti18nText('menu.access.rights.table.valid.on.date')}>
                <NyDatePicker
                    value={filterDate}
                    showTime
                    format="DD.MM.YYYY HH:mm"
                    style={{ width: '100%', maxWidth: '150px' }}
                    onChange={(value: any) => {
                        OnFilterDateChange(value);
                    }}
                    mustGetPopupContainer={false}
                />
            </Form.Item>
            <NyDataTable
                nyId="access-rights"
                addNewButtonText={geti18nText('menu.access.rights.table.add')}
                url={getListUrl()}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                modalComponent={AccessRightEdit}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                hideNewButton={!canCreate()}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                fetchWhenChange={refreshTable}
                setDefaultFilterValue={setDefaultFilterValue}
                shortcuts={true}
                CSVFileName={geti18nText('menu.access.rights')}
                rowSelectionModal={setSelectedValuesFuncDelete as any}
                showRowSelection={canCreate()}
                addedButtons={addedButtons}
                editProps={{
                    accessRightType: accessRightType,
                }}
            />
        </>
    );
};

export default AccessRightIndex;
