import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationEdit from '../payment-calculation/edit';

const IncomeEdit = () => {
    useHelper('payroll/calculation/income');

    return <PaymentCalculationEdit type={1} />;
};

export default IncomeEdit;
