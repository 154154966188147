import {
    NyDataTable,
    NyRequestResolver,
    NySession,
    NySpinner,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Descriptions } from 'antd';
import moment from 'moment';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import EmployeeSeniorityEdit from './EmployeeSeniorityEdit';
import { useEffect, useState } from 'react';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EmployeeSeniorityIndex = ({ id, readonly, scroll = { y: 580, x: 800 }, sortOrder }: any) => {
    const table = useTableSettings();
    const [seniorityData, setSeniorityData] = useState<any>(null);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id },
        ];
    };

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    const columns = [
        {
            title: geti18nText('employee.seniority.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.seniority.table.column.dateTo'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.seniority.table.column.years'),
            dataIndex: 'years',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.seniority.table.column.months'),
            dataIndex: 'months',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.seniority.table.column.days'),
            dataIndex: 'days',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.seniority.table.column.company'),
            dataIndex: 'company',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
    ];

    const getHeaderInfo = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_SENIORITY.TOTAL_LIST + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setSeniorityData(result?.data);
                    setLoading(false);
                }
            }
        });
    };

    const formatHeader = (duration: String) => {
        return (
            <span>
                <b>
                    {duration.split(',')[0] +
                        ' ' +
                        geti18nText('employee.seniority.edit.years.short') +
                        ' ' +
                        duration.split(',')[1] +
                        ' ' +
                        geti18nText('employee.seniority.edit.months.short') +
                        ' ' +
                        duration.split(',')[2] +
                        ' ' +
                        geti18nText('employee.seniority.edit.days.short')}
                </b>
            </span>
        );
    };

    useEffect(() => {
        getHeaderInfo();
    }, []);

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    const header = (
        <Descriptions column={3}>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.1')}>
                {seniorityData?.previousSeniorityOutsideCurrentCompany
                    ? formatHeader(seniorityData.previousSeniorityOutsideCurrentCompany)
                    : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.2')}>
                {seniorityData?.totalSeniority ? formatHeader(seniorityData.totalSeniority) : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.3')}>
                {seniorityData?.totalSeniorityByTheEndOfYear
                    ? formatHeader(seniorityData.totalSeniorityByTheEndOfYear)
                    : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.4')}>
                {seniorityData?.previousSeniorityInCurrentCompany
                    ? formatHeader(seniorityData.previousSeniorityInCurrentCompany)
                    : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.5')}>
                {seniorityData?.totalSeniorityInCurrentCompany
                    ? formatHeader(seniorityData.totalSeniorityInCurrentCompany)
                    : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.6')}>
                {seniorityData?.totalSeniorityByTheEndOfYearInCurrentCompany
                    ? formatHeader(seniorityData.totalSeniorityByTheEndOfYearInCurrentCompany)
                    : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.7')}>
                {seniorityData?.totalPreviousSeniority ? formatHeader(seniorityData.totalPreviousSeniority) : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={geti18nText('employee.seniority.table.column.8')}>
                {seniorityData?.jubileeSeniority ? formatHeader(seniorityData.jubileeSeniority) : '-'}
            </Descriptions.Item>
        </Descriptions>
    );

    return (
        <>
            {loading ? <NySpinner /> : header}
            <NyDataTable
                nyId="human-employee-seniority-table"
                url={CONSTANTS_REQ.EMPLOYEE_SENIORITY.LIST}
                addNewButtonText={geti18nText('employee.seniority.edit.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                fetchWhenChange={id || refresh}
                readonly={readonly}
                hideButtons={readonly}
                hideNewButton={!canCreate()}
                setDefaultSortOrder={sortOrder ?? { order: 'dateTo', orderType: 'desc' }}
                scroll={scroll}
                setDefaultPageSize={table.setDefaultPageSize()}
                modalComponent={EmployeeSeniorityEdit}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={columns}
                editProps={{ employeeId: id }}
                onDataLoaded={() => {
                    getHeaderInfo();
                }}
            />
        </>
    );
};

export default EmployeeSeniorityIndex;
