import { NyDataEdit, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { selectedItemSet } from '../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, setEnumFormat } from '../../../../utils/Utils';
import { oibCheck } from '../../../../utils/Validation';

const CompanyEdit = (props: any) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();

    const [editHeader, setEditHeader] = useState(geti18nText('company.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [marketType, setMarketType] = useState<any>(1);
    const [companyId, setCompanyId] = useState<any>();
    const [gottenOib, setGottenOib] = useState<any>(undefined);

    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateCompany();
    };

    function setValues(dataForm: any) {
        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(dataForm.id);
        }
        setDataForm(dataForm.id);

        if (dataForm.partnerType) {
            dataForm.partnerType = setEnumFormat('COMPANY_PARTNER_TYPE', dataForm.partnerType);
        }

        if (dataForm.marketType) {
            setMarketType(dataForm.marketType);
            dataForm.marketType = setEnumFormat('MARKET_TYPE', dataForm.marketType);
        }

        if (dataForm.oib) {
            setGottenOib(dataForm.oib);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    useEffect(() => {
        dispatch(selectedItemSet({ item: dataForm, refreshItem: 0, employeeId: undefined }));
    }, [dataForm]);

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('company.edit.new'));
    };

    /// VALIDATION
    const validateOib = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '' && gottenOib != value) {
            if (oibCheck(value)) {
                var parms: any = { oib: value };
                if (companyId !== null && companyId !== undefined && companyId !== 'create') {
                    parms.companyId = companyId;
                }
                NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EXISTS_BY_OIB, parms).then((result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            callback(geti18nText('company.edit.oib.exists'));
                        } else {
                            callback();
                        }
                    } else {
                        callback();
                    }
                });
            } else {
                callback(geti18nText('company.edit.oib.not.correct'));
            }
        } else {
            callback();
        }
    };

    const validateVatNumber = async (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            var parms: any = { vatNumber: value };
            if (companyId !== null && companyId !== undefined && companyId !== 'create') {
                parms.companyId = companyId;
            }
            NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EXISTS_BY_VAT_NUMBER, parms).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        callback(geti18nText('company.edit.vatNumber.exists'));
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            });
        }
    };

    const validateLocalVatNumber = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('company.edit.vat.not.correct'));
            }
        } else {
            callback();
        }
    };
    /// END VALIDATION

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.COMPANY.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.partnerType) values.partnerType = getEnumFormat(values.partnerType);
                if (values.marketType) values.marketType = getEnumFormat(values.marketType);
                return values;
            }}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            shortcuts={true}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('company.edit.marketType')}
                        name="marketType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={setEnumFormat('MARKET_TYPE', 1)}
                    >
                        <NySearchField
                            options={getEnumArray('MARKET_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={(data: any) => {
                                setMarketType(data.id);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('company.edit.partnerType')}
                        name="partnerType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('COMPANY_PARTNER_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={16}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('company.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('company.edit.name2')} name="name2">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {marketType === 1 ? (
                    <Col span={8}>
                        <Form.Item
                            label={geti18nText('company.edit.oib')}
                            name="oib"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                                {
                                    validator: validateOib,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                ) : (
                    <Col span={8}>
                        <Form.Item
                            label={geti18nText('company.edit.vatNumber')}
                            name="vatNumber"
                            rules={[
                                {
                                    required: marketType === 2 ? true : false,
                                    message: geti18nText('app.default.required'),
                                    whitespace: marketType === 2 ? true : false,
                                },
                                {
                                    validator: validateVatNumber,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                )}

                <Col span={8}>
                    <Form.Item label={geti18nText('company.edit.mb')} name="mb">
                        <Input />
                    </Form.Item>
                </Col>
                {marketType === 1 && (
                    <Col span={8}>
                        <Form.Item
                            label={geti18nText('company.edit.vatNumber')}
                            name="vatNumber"
                            rules={[
                                {
                                    required: false,
                                    message: geti18nText('app.default.required'),
                                },
                                {
                                    validator: validateLocalVatNumber,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </NyDataEdit>
    );
};

export default CompanyEdit;
