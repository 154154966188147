import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from './Constants';

export const getLocationWithIp = async () => {
    let params: any = {};
    if ('geolocation' in navigator) {
        try {
            const result: any = await new Promise((success, error) => {
                navigator.geolocation.getCurrentPosition(success, error);
            });

            if (result.coords) {
                params.lat = result.coords.latitude;
                params.lng = result.coords.longitude;
            }
        } catch (e) {
            console.log(e);
        }
        return await fetchLocation(params);
    } else {
        return await fetchLocation(params);
    }
};

async function fetchLocation(params: any) {
    const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.UTIL.LOCATION, params);
    if (result.status == RESPONSE.OK) {
        let location: any = {};
        if (result.data.ipAddress) {
            location.ipAddress = result.data.ipAddress;
        }
        if (result.data.geoReverse) {
            const geoReverse = JSON.parse(result.data.geoReverse);
            location.lat = geoReverse.lat;
            location.lng = geoReverse.lon;
            location.address = geoReverse.display_name;
        }
        return location;
    }
    return undefined;
}
