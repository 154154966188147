import {
    BookOutlined,
    CarOutlined,
    ContactsOutlined,
    ControlOutlined,
    CreditCardOutlined,
    FolderViewOutlined,
    HomeOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';

const TravelWarrantModuleMenu = [
    {
        key: 'menu.travel.warrant',
        path: '/travel-warrant',
        icon: <HomeOutlined />,
        i18n: 'menu.home',
        role: TravelWarrantRights.canViewTravelWarrantModuleMenu(),
    },
    {
        key: 'menu.travelWarrant',
        path: '/travel-warrant/travelWarrant',
        icon: <CarOutlined />,
        i18n: 'menu.travelWarrant',
        role: TravelWarrantRights.canViewTravelWarrantMenu(),
    },
    {
        key: 'menu.employeeTravelRelation',
        path: '/travel-warrant/employeeTravelRelation',
        icon: <CarOutlined />,
        i18n: 'menu.employeeTravelRelation',
        role: TravelWarrantRights.canViewTravelLocalMenu(),
    },
    {
        key: 'menu.travelCostsOverview',
        path: '/travel-warrant/travelCostsOverview',
        icon: <UnorderedListOutlined />,
        i18n: 'menu.travelCostsOverview',
        role: TravelWarrantRights.canViewTravelCostMenu(),
    },
    {
        key: 'menu.travel.warrant.codebooks',
        path: '/travel-warrant/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.codebooks',
        role: TravelWarrantRights.canViewTravelCodebooksMenu(),
        submenu: [
            {
                key: 'menu.travelVehicle',
                path: '/travel-warrant/codebooks/travelVehicle',
                icon: <CarOutlined />,
                i18n: 'menu.travelVehicle',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travel.fuel.type',
                path: '/travel-warrant/codebooks/travelFuelType',
                icon: (
                    <i
                        style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                        className="las la-gas-pump"
                    />
                ),
                i18n: 'menu.travel.fuel.type',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travelWage',
                path: '/travel-warrant/codebooks/travelWage',
                icon: <ContactsOutlined />,
                i18n: 'menu.travelWage',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travelWageExpense',
                path: '/travel-warrant/codebooks/travelWageExpense',
                icon: <WalletOutlined />,
                i18n: 'menu.travelWageExpense',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travelExpense',
                path: '/travel-warrant/codebooks/travelExpense',
                icon: <WalletOutlined />,
                i18n: 'menu.travelExpense',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travelPaymentMethod',
                path: '/travel-warrant/codebooks/travelPaymentMethod',
                icon: <CreditCardOutlined />,
                i18n: 'menu.travelPaymentMethod',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travelEventNumber',
                path: '/travel-warrant/codebooks/travelEventNumber',
                icon: <i className="las la-calendar-plus" />,
                i18n: 'menu.travelEventNumber',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
            {
                key: 'menu.travelWarrantType',
                path: '/travel-warrant/codebooks/travelWarrantType',
                icon: <i className="las la-stamp" />,
                i18n: 'menu.travelWarrantType',
                role: TravelWarrantRights.canViewTravelCodebooksMenu(),
            },
        ],
    },
    {
        key: 'menu.travel.warrant.settings',
        path: '/travel-warrant/settings',
        icon: <SettingOutlined />,
        i18n: 'menu.settings',
        role: TravelWarrantRights.canViewTravelSettingsMenu(),
        submenu: [
            {
                key: 'menu.travelWarrant.settings',
                path: '/travel-warrant/settings/travel-warrant-settings',
                icon: <SettingOutlined />,
                i18n: 'menu.travelWarrant.settings',
                role: TravelWarrantRights.canViewTravelSettingsMenu(),
            },
            {
                key: 'menu.travel.notification.settings',
                path: '/travel-warrant/settings/travel-notification-settings',
                icon: <ControlOutlined />,
                i18n: 'menu.notification.settings',
                role: TravelWarrantRights.canViewTravelSettingsOnlyMenu(),
            },
            {
                key: 'menu.travel.access.rights',
                path: '/travel-warrant/settings/travel-access-right',
                icon: <FolderViewOutlined />,
                i18n: 'menu.access.rights',
                role: TravelWarrantRights.canViewTravelSettingsOnlyMenu(),
            },
        ],
    },
];

export default TravelWarrantModuleMenu;
