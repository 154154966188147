import { NyDataEdit, NySession, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import { MobilizationRights } from '../../../../rights/mobilizationRights';

const ReserveRankEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('reserve.rank.edit.header'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [fileList, setFileList] = useState<any>([]);

    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const history = useHistory();
    const focusInput = useRef<any>(null);

    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('reserve.rank.edit.header') + ' - ' + dataForm.name);
        }

        if (dataForm.fileId) {
            let filesImage = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: dataForm.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(filesImage);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('reserve.rank.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.RESERVE_RANK.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            normalize={(values: any) => {
                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }
                if (fileList.length > 0 && fileList[0]) {
                    values.fileId = fileList[0].id;
                }
                return values;
            }}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="reserveRank">
                        <NyImageUpload files={fileList} setFiles={setFileList} saveOnUpload={true} />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('reserve.rank.table.table.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ReserveRankEdit;
