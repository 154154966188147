import { geti18nText, NyDataEdit, NySearchField, NySession } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, setEnumFormat } from '../../../../utils/Utils';
import { validateOnlyNumber } from '../../../../utils/Validation';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { PayrollRights } from '../../../../rights/payrollRights';

const { TextArea } = Input;

const AccountEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('account.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const travelWarrantSyncType = useApplicationSetting('TRAVEL_WARRANT_SYNC_TYPE');
    const isPointSync: boolean = travelWarrantSyncType === 'point' ? true : false;

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('account.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.type) {
            dataForm.type = setEnumFormat('ACCOUNT_ENTRY_TYPE', dataForm.type);
        }
        if (dataForm.bookkeepingType) {
            dataForm.bookkeepingType = setEnumFormat('BOOKKEEPING_TYPE', dataForm.bookkeepingType);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('account.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ACCOUNT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.type) {
                    values.type = getEnumFormat(values.type);
                }
                if (values.code) {
                    values.code = values.code.toString();
                }
                if (values.type) {
                    values.type = getEnumFormat(values.type);
                }
                if (values.bookkeepingType) {
                    values.bookkeepingType = getEnumFormat(values.bookkeepingType);
                }
                if (values.company == null) {
                    delete values.company;
                }
                if (values.businessUnit == null) {
                    delete values.businessUnit;
                }
                if (values.accountingCostCenter == null) {
                    delete values.accountingCostCenter;
                }
                if (values.accountingCostObject == null) {
                    delete values.accountingCostObject;
                }
                if (values.employee == null) {
                    delete values.employee;
                }
                if (values.document == null) {
                    delete values.document;
                }
                if (values.workOrder == null) {
                    delete values.workOrder;
                }
                return values;
            }}
        >
            <>
                {!isPointSync ? (
                    <>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('account.edit.code')}
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                        {
                                            validator: validateOnlyNumber,
                                        },
                                    ]}
                                >
                                    <Input ref={focusInput} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('account.edit.type')}
                                    name="type"
                                    initialValue={setEnumFormat('ACCOUNT_ENTRY_TYPE', 4)}
                                >
                                    <NySearchField
                                        options={getEnumArray('ACCOUNT_ENTRY_TYPE')}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        style={{ width: '100%' }}
                                        className={''}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('account.edit.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('account.edit.code')}
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                        {
                                            validator: validateOnlyNumber,
                                        },
                                    ]}
                                >
                                    <Input ref={focusInput} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('account.edit.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('account.edit.type')}
                                    name="type"
                                    initialValue={setEnumFormat('ACCOUNT_ENTRY_TYPE', 4)}
                                >
                                    <NySearchField
                                        options={getEnumArray('ACCOUNT_ENTRY_TYPE')}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        style={{ width: '100%' }}
                                        className={''}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('account.edit.bookkeepingType')}
                                    name="bookkeepingType"
                                    initialValue={setEnumFormat('BOOKKEEPING_TYPE', 1)}
                                >
                                    <NySearchField
                                        options={getEnumArray('BOOKKEEPING_TYPE')}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        style={{ width: '100%' }}
                                        className={''}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.employee')}
                                    name="employee"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.businessUnit')}
                                    name="businessUnit"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.company')}
                                    name="company"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.accountingCostCenter')}
                                    name="accountingCostCenter"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.accountingCostObject')}
                                    name="accountingCostObject"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.document')}
                                    name="document"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={geti18nText('account.edit.workOrder')}
                                    name="workOrder"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        </NyDataEdit>
    );
};

export default AccountEdit;
