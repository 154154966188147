import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, SaveTwoTone } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, NySearchField, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Checkbox, Col, Form, Modal, Row, Space, Table, Tag, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import NyDescription from '../../../../../components/ny-description';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    GetEmployeeEvaluationGradeColors,
    errorNotification,
    getEnumArrayStatus,
    okNotification,
    onKeyDownTextAreaCustom,
    setEnumFormat,
} from '../../../../../utils/Utils';
import EditableCell from './EditableCell';

const ResultListIndex = ({
    id,
    form,
    isUser = false,
    isManager = false,
    disabledForm = false,
    activeKey,
    overallEstimation,
    setOverallEstimation,
    overallEstimationManager,
    setOverallEstimationManager,
    superiorManagerIntermediary,
    setSuperiorManagerIntermediary,
    setShowAlert,
    showAlert,
    disabledGoals = false,
    saveEvaluation,
    superiorManager,
    isResultListManagerCommentMandatory,
    status,
    dataFormObject,
}: any) => {
    const enEmployeeEvaluationEstimation = useEnum('EMPLOYEE_EVALUATION_ESTIMATION');
    const enEmployeeEvaluationStatus = useEnum('EMPLOYEE_EVALUATION_STATUS');
    const focusInput = useRef<any>(null);
    const [editForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey || record.tempId === editingKey;
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [isNewInProcess, setIsNewInProcess] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>(0);
    const [items, setItems] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [editRecordForm] = Form.useForm();
    const [editRecord, setEditRecord] = useState<any>(null);
    const [managerCommentResultMandatory, setManagerCommentResultMandatory] = useState<boolean>(false);
    const [loadingIntermediary, setLoadingIntermediary] = useState<any>(false);

    const requiredResultManagerComment = () => {
        return isManager;
    };

    const requiredRemark = () => {
        return (
            isManager &&
            ((overallEstimationManager && overallEstimation && overallEstimationManager != overallEstimation) ||
                (overallEstimationManager && !overallEstimation) ||
                overallEstimation == enEmployeeEvaluationEstimation.UNDEFINED ||
                overallEstimationManager == enEmployeeEvaluationEstimation.UNDEFINED)
        );
    };

    const showRemark = () => {
        return requiredRemark() || form.getFieldValue('remark')?.length > 0;
    };

    useEffect(() => {
        setAddItemInProcess(false);
        setEditingKey('');
        if (isNewInProcess) {
            setItems(items.filter((element: any) => element.id != null && element?.id != undefined));
        }
    }, [activeKey]);

    useEffect(() => {
        calculate();
    }, [refresh]);

    useEffect(() => {
        getItems();
    }, [refresh]);

    const calculate = () => {
        if (id) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.EMPLOYEE_EVALUATION.CALCULATE_OVERALL_ESTIMATION + '/' + id,
                undefined,
                undefined
            ).then((result: any) => {
                const estimationData = result.status === RESPONSE.OK && result.data ? result.data : null;
                setOverallEstimation(estimationData);
                form.setFieldValue(
                    'overallEvaluationEstimation',
                    setEnumFormat('EMPLOYEE_EVALUATION_ESTIMATION', overallEstimationManager ?? estimationData)
                );
            });
        }
    };

    const getItems = () => {
        setLoading(true);
        if (id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION_RESULT.LIST, {
                search: encodeURI(
                    JSON.stringify([
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'employee_evaluation_id', condition: 'equals', value: id },
                    ])
                ),
                order: 'id',
                orderType: 'asc',
                max: 1000,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK && result?.data?.content) {
                    const items = result.data.content.map((item: any) => {
                        if (item.resultEstimation) {
                            item.resultEstimation = setEnumFormat(
                                'EMPLOYEE_EVALUATION_ESTIMATION',
                                item.resultEstimation
                            );
                        }
                        return item;
                    });
                    let allFilledResult: boolean = true;
                    items.every((item: any) => {
                        if (!item.resultEstimation || isResultListManagerCommentMandatory(item)) {
                            allFilledResult = false;
                        }
                        return item;
                    });

                    if (isManager) {
                        setShowAlert(!allFilledResult);
                    }
                    setItems(items);
                } else {
                    setItems([]);
                }
                setLoading(false);
            });
        }
    };
    const addNewRow = (event: any) => {
        setIsNewInProcess(true);
        event.stopPropagation();
        editForm.resetFields();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            tempId: newId,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const edit = (record: any) => {
        let row = undefined;
        row = { ...record };
        editForm.setFieldsValue(row);
        setAddItemInProcess(true);
        setEditingKey(row.id ?? row.tempId);
    };

    const deleteRow = (row: any) => {
        if (row.id) {
            row.active = false;
            saveItem(row);
            setItems(items.filter((element: any) => row.id !== element.id));
        } else if (row?.tempId != undefined && row?.tempId >= 0) {
            setItems(items.filter((element: any) => row.tempId !== element.tempId));
        }
    };

    const cancel = (record: any) => {
        deleteRow(record);
        setEditingKey('');
        setAddItemInProcess(false);
        setIsNewInProcess(false);
    };

    const afterSaveAction = (result: any) => {
        if (result?.status === RESPONSE.CREATED || result?.status === RESPONSE.OK) {
            okNotification();
            setEditingKey('');
            setAddItemInProcess(false);
            setIsNewInProcess(false);
            if (result?.data?.resultEstimation) {
                setRefresh((refresh: any) => refresh + 1);
            }
            if (result?.data?.active == true) {
                const newData = [...items];
                let index: any = newData.findIndex((object) => object.id === result.data.id);

                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...result.data });
                } else {
                    newData.push(result.data);
                }
                let allFilledResult: boolean = true;
                newData.every((item: any) => {
                    if (!item.resultEstimation || isResultListManagerCommentMandatory(item)) {
                        allFilledResult = false;
                    }
                    return item;
                });

                if (isManager) {
                    setShowAlert(!allFilledResult);
                }
                setItems(newData.filter((element: any) => element.id != null && element.id != undefined));
            }
        } else {
            errorNotification();
        }
    };

    const saveItem = (record: any) => {
        record.employeeEvaluation = { id: id };
        if (record.resultEstimation) {
            record.resultEstimation = record.resultEstimation.id;
        }
        if (record.id) {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.EMPLOYEE_EVALUATION_RESULT.EDIT + '/' + record.id,
                undefined,
                record
            ).then((result: any) => {
                afterSaveAction(result);
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_EVALUATION_RESULT.EDIT, undefined, record).then(
                (result: any) => {
                    afterSaveAction(result);
                }
            );
        }
    };

    const save = async () => {
        try {
            let row = await editForm.validateFields();
            saveItem(row);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const getEditForm = (
        record: any,
        value: any,
        dataIndex: any,
        title: string,
        disabled: boolean,
        mandatory: boolean = false
    ) => (
        <Row gutter={24}>
            <Col span={21}>
                {mandatory && !disabled ? (
                    <p style={{ marginTop: '10px', width: '100%' }}>
                        <Alert
                            message={geti18nText('app.default.required')}
                            type="error"
                            onClick={() => {
                                setTimeout(() => {
                                    if (focusInput.current) {
                                        focusInput.current.focus();
                                    }
                                });
                                setEditRecord({
                                    ...record,
                                    dataIndex: dataIndex,
                                    title: title,
                                    value: value,
                                });
                                editRecordForm.setFieldsValue({ editRecord: value });
                            }}
                        />
                    </p>
                ) : (
                    <p style={{ marginTop: '10px' }}>
                        <NyDescription title={value} maxWidth={'370px'} />
                    </p>
                )}
            </Col>
            {!disabled && (
                <Col span={3}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                        <Button
                            type="link"
                            onClick={() => {
                                setTimeout(() => {
                                    if (focusInput.current) {
                                        focusInput.current.focus();
                                    }
                                });
                                setEditRecord({
                                    ...record,
                                    dataIndex: dataIndex,
                                    title: title,
                                    value: value,
                                });
                                editRecordForm.setFieldsValue({ editRecord: value });
                            }}
                            disabled={disabled}
                            style={{ float: 'right' }}
                        >
                            <EditTwoTone
                                style={{
                                    fontSize: '20px',
                                    marginTop: '8px',
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Col>
            )}
        </Row>
    );

    const columns = [
        ...[
            {
                title: geti18nText('employee.evaluation.edit.result.column.achievedResultDescription'),
                dataIndex: 'achievedResultDescription',
                inputType: 'achievedResultDescription',
                editable: !disabledForm,
                required: true,
                render: (text: any, record: any) => {
                    if (disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.achievedResultDescription} maxWidth={'500px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.achievedResultDescription,
                            'achievedResultDescription',
                            geti18nText('employee.evaluation.edit.result.column.achievedResultDescription'),
                            disabledForm || addItemInProcess
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.result.column.resultEstimation'),
                dataIndex: ['resultEstimation', 'text'],
                inputType: 'resultEstimation',
                editable: isManager && !disabledForm,
                required: isManager,
                render: (text: any, record: any) => {
                    if (!isManager || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                {record?.resultEstimation?.text && record?.resultEstimation?.id && (
                                    <Tag
                                        color={GetEmployeeEvaluationGradeColors(record?.resultEstimation?.id, true)}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {record?.resultEstimation?.text}
                                    </Tag>
                                )}
                            </p>
                        );
                    } else {
                        let name = record?.id ? record?.id.toString() : record?.tempId ? record?.tempId.toString() : '';
                        name = name.concat('resultEstimation');
                        return (
                            <>
                                <Form.Item
                                    label={''}
                                    name={name}
                                    rules={[
                                        {
                                            required: isManager,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={record.resultEstimation}
                                >
                                    <NySearchField
                                        options={getEnumArrayStatus('EMPLOYEE_EVALUATION_ESTIMATION')}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        nyTestId={name}
                                        className=""
                                        renderOption={(option: any) => `${option.text}`}
                                        mustGetPopupContainer={false}
                                        onChange={(value: any) => {
                                            saveItem({ ...record, resultEstimation: value });
                                        }}
                                        value={record.resultEstimation}
                                        allowClear={false}
                                        style={{
                                            marginTop: '15px',
                                            border: record?.resultEstimation?.id
                                                ? '1px solid #91d5ff'
                                                : '1px solid red',
                                        }}
                                    />
                                </Form.Item>
                            </>
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.result.column.managerComment'),
                dataIndex: 'managerComment',
                inputType: 'managerComment',
                editable: isManager && !disabledForm,
                required: false,
                render: (text: any, record: any) => {
                    if (!isManager || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.managerComment} maxWidth={'500px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.managerComment,
                            'managerComment',
                            geti18nText('employee.evaluation.edit.result.column.managerComment'),
                            !isManager || disabledForm || addItemInProcess,
                            isResultListManagerCommentMandatory(record)
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.result.column.employeeComment'),
                dataIndex: 'employeeComment',
                inputType: 'employeeComment',
                editable: isUser && !disabledForm,
                required: isUser,
                render: (text: any, record: any) => {
                    if (!isUser || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.employeeComment} maxWidth={'500px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.employeeComment,
                            'employeeComment',
                            geti18nText('employee.evaluation.edit.result.column.employeeComment'),
                            !isUser || disabledForm || addItemInProcess
                        );
                    }
                },
            },
        ],
        ...(items.length >= 1 && !disabledForm
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '5%',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
        ...[],
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable || disabledForm) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    required: col.required,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: editForm,
                    managerCommentResultMandatory: managerCommentResultMandatory,
                    setManagerCommentResultMandatory: setManagerCommentResultMandatory,
                };
            },
        };
    });

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={save} disabled={disabledForm} style={{ marginRight: '-15px' }}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button
                            type="link"
                            disabled={disabledForm}
                            onClick={() => {
                                cancel(record);
                            }}
                        >
                            <CloseCircleTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#faad14"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                    {isManager && (
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={!isManager || disabledForm || addItemInProcess}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    )}
                </Space>
            </React.Fragment>
        );
    };

    return (
        <>
            <Form layout="vertical" form={form}>
                {disabledGoals && isUser && (
                    <Row gutter={24} style={{ marginTop: '4px', marginBottom: '12px' }}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('employee.evaluation.edit.resultDisabled')}
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>
                )}
                {status == enEmployeeEvaluationStatus.ESTIMATION && isManager && (
                    <Alert
                        message={geti18nText('employee.evaluation.edit.result.managerComment.mandatory')}
                        type="info"
                        showIcon
                        style={{ margin: '5px' }}
                    />
                )}
                <Row
                    gutter={24}
                    style={{
                        marginBottom: '10px',
                        marginTop: '5px',
                    }}
                >
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={editForm}
                            onFinishFailed={({ errorFields }) => {
                                editForm.scrollToField(errorFields[0].name);
                            }}
                        >
                            {loading ? (
                                <NySpinner />
                            ) : (
                                <Table
                                    rowClassName="editable-row"
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    dataSource={items}
                                    columns={mergedColumns}
                                    pagination={false}
                                    size="small"
                                    sticky
                                    bordered
                                    style={{ maxHeight: '410px', overflowY: 'auto', overflowX: 'hidden' }}
                                />
                            )}
                        </Form>
                    </Col>
                </Row>
                {!addItemInProcess && id && !disabledForm && !disabledGoals && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Button style={{ display: 'inline', float: 'right' }} type="primary" onClick={addNewRow}>
                                {geti18nText('employee.evaluation.edit.result.add')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col span={showRemark() ? 12 : 24}>
                        <Form.Item
                            label={geti18nText('employee.evaluation.edit.overallEvaluationEstimation')}
                            name="overallEvaluationEstimation"
                            rules={[
                                {
                                    required: isManager,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                options={getEnumArrayStatus('EMPLOYEE_EVALUATION_ESTIMATION')}
                                map={{ id: 'id', label: 'text' }}
                                onChange={(value: any) => {
                                    setOverallEstimationManager(value?.id ? value.id : null);
                                    saveEvaluation(superiorManagerIntermediary, value?.id ? value.id : null);
                                }}
                                searchBy="text"
                                renderOption={(option: any) => `${option.text}`}
                                className=""
                                disabled={!isManager || disabledForm}
                                allowClear={false}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                        {requiredRemark() && (
                            <Alert
                                message={geti18nText('employee.evaluation.edit.result.info.remark')}
                                type="info"
                                showIcon
                                style={{ margin: '5px' }}
                            />
                        )}
                    </Col>
                    {showRemark() && (
                        <Col span={12}>
                            <Form.Item
                                name="remark"
                                label={geti18nText('employee.evaluation.edit.remark')}
                                rules={[
                                    {
                                        required: requiredRemark(),
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    allowClear={false}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                    disabled={!isManager || disabledForm}
                                    onBlur={(val: any) => {
                                        const value = val?.target?.value;
                                        if (
                                            (dataFormObject?.remark?.length > 0 &&
                                                value?.length > 0 &&
                                                dataFormObject.remark != value) ||
                                            !dataFormObject?.remark ||
                                            dataFormObject?.remark?.length < 1
                                        ) {
                                            saveEvaluation();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="resultManagerComment"
                            label={geti18nText('employee.evaluation.edit.resultManagerComment')}
                            rules={[
                                {
                                    required: requiredResultManagerComment(),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                onKeyDown={onKeyDownTextAreaCustom}
                                allowClear={false}
                                disabled={!requiredResultManagerComment() || disabledForm}
                                onBlur={(val: any) => {
                                    const value = val?.target?.value;
                                    if (
                                        (dataFormObject?.resultManagerComment?.length > 0 &&
                                            value?.length > 0 &&
                                            dataFormObject.resultManagerComment != value) ||
                                        !dataFormObject?.resultManagerComment ||
                                        dataFormObject?.resultManagerComment?.length < 1
                                    ) {
                                        saveEvaluation();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="resultEmployeeComment"
                            label={geti18nText('employee.evaluation.edit.resultEmployeeComment')}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                onKeyDown={onKeyDownTextAreaCustom}
                                allowClear={false}
                                disabled={!isUser || disabledForm}
                                onBlur={(val: any) => {
                                    const value = val?.target?.value;
                                    if (
                                        (dataFormObject?.resultEmployeeComment?.length > 0 &&
                                            value?.length > 0 &&
                                            dataFormObject.resultEmployeeComment != value) ||
                                        !dataFormObject?.resultEmployeeComment ||
                                        dataFormObject?.resultEmployeeComment?.length < 1
                                    ) {
                                        saveEvaluation();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {isUser && (
                    <Row gutter={24}>
                        <Col span={4}>
                            <NyModalConfirm
                                title={
                                    (superiorManagerIntermediary
                                        ? geti18nText('employee.evaluation.edit.superiorManagerIntermediary.confirm.no')
                                        : geti18nText('employee.evaluation.edit.superiorManagerIntermediary.confirm')) +
                                    ' ' +
                                    (superiorManager?.person?.firstName + ' ' + superiorManager?.person?.lastName) +
                                    ' ?'
                                }
                                onConfirm={() => {
                                    setSuperiorManagerIntermediary(!superiorManagerIntermediary);
                                    saveEvaluation(
                                        superiorManagerIntermediary == true ? false : true,
                                        null,
                                        setLoadingIntermediary
                                    );
                                }}
                            >
                                {superiorManagerIntermediary ? (
                                    <Button disabled={!isUser || disabledForm} loading={loadingIntermediary}>
                                        {geti18nText('employee.evaluation.edit.superiorManagerIntermediary.no')}
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={!isUser || disabledForm}
                                        type={'primary'}
                                        loading={loadingIntermediary}
                                    >
                                        {geti18nText('employee.evaluation.edit.superiorManagerIntermediary')}
                                    </Button>
                                )}
                            </NyModalConfirm>
                        </Col>
                    </Row>
                )}
            </Form>
            <Modal
                open={editRecord != null}
                title={editRecord?.title}
                okText={geti18nText('app.default.button.save')}
                onOk={() => {
                    saveItem(editRecord);
                    setEditRecord(null);
                }}
                onCancel={() => setEditRecord(null)}
            >
                <Form form={editRecordForm}>
                    <Form.Item name="editRecord">
                        <TextArea
                            ref={focusInput}
                            allowClear={false}
                            onBlur={(value: any) => {
                                if (value?.target?.value?.length > 0) {
                                    if (editRecord?.dataIndex == 'managerComment') {
                                        setEditRecord({ ...editRecord, managerComment: value?.target?.value });
                                    } else if (editRecord?.dataIndex == 'employeeComment') {
                                        setEditRecord({ ...editRecord, employeeComment: value?.target?.value });
                                    } else if (editRecord?.dataIndex == 'achievedResultDescription') {
                                        setEditRecord({
                                            ...editRecord,
                                            achievedResultDescription: value?.target?.value,
                                        });
                                    }
                                }
                            }}
                            autoSize={{ minRows: 3, maxRows: 4 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default ResultListIndex;
