import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';
import { Form } from 'antd';
import CountyIndex from '.';
import CountyEdit from './edit';
import TownshipDistrictEdit from './edit';
import TownshipDistrictIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { MobilizationRights } from '../../../../rights/mobilizationRights';

const TownshipDistrictSearch = ({
    label = geti18nText('township.district.district'),
    name = ['address', 'townshipDistrict'],
    onChange,
    nyTestId = 'townshipDistrict',
    searchBy = 'name',
    setDefaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }],
    selectedTownshipId,
    AddNewModalComponentAddedData,
    disabled = false,
}: any) => {
    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    return (
        <Form.Item label={label} name={name}>
            <NySearchField
                url={CONSTANTS_REQ.TOWNSHIP_DISTRICT.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                SearchPopupComponent={
                    canCreate() ? <TownshipDistrictIndex selectedTownshipId={selectedTownshipId} /> : null
                }
                AddNewModalComponent={canCreate() ? TownshipDistrictEdit : null}
                nyTestId={nyTestId}
                onChange={onChange}
                setDefaultFilterValue={setDefaultFilterValue}
                AddNewModalComponentAddedData={AddNewModalComponentAddedData}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default TownshipDistrictSearch;
