import {
    CloseCircleOutlined,
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    PlusCircleOutlined,
    SaveTwoTone,
} from '@ant-design/icons';
import { NyRequestResolver, RESPONSE, geti18nText, NyModalConfirm } from '@nybble/nyreact';
import { List, Col, Form, Select, Input, Button, Table, Space, Tooltip, Row } from 'antd';
import React from 'react';
import { useState, useEffect } from 'react';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { errorNotification, okNotification } from '../../utils/Utils';
import EditableCell from './EditableCell';

const DeliverySpotTime = ({ id, type, visible, setDeliveryDays, deliveryDays }: any) => {
    const [day, setDay] = useState<any>(1);
    const [time, setTime] = useState<any>(undefined);
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;

    const [assetForm] = Form.useForm();

    const deliveryType = useEnum('DELIVERY_TYPE');

    useEffect(() => {
        if (id) {
            getDeliveryDays(id);
        }
    }, [id, visible]);

    const getDeliveryDays = (id: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DELIVERY_SPOT_TIME.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'refId', condition: 'equals', value: id },
                    {
                        field: 'type',
                        condition: 'equals',
                        value: type,
                    },
                ])
            ),
            max: 1000,
            order: 'day',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content) {
                setDeliveryDays(result.data.content);
            }
        });
    };

    const days = [
        { value: 1, label: geti18nText('deliverySpot.edit.mon') },
        { value: 2, label: geti18nText('deliverySpot.edit.tue') },
        { value: 3, label: geti18nText('deliverySpot.edit.wed') },
        { value: 4, label: geti18nText('deliverySpot.edit.thu') },
        { value: 5, label: geti18nText('deliverySpot.edit.fri') },
        { value: 6, label: geti18nText('deliverySpot.edit.sat') },
        { value: 7, label: geti18nText('deliverySpot.edit.sun') },
    ];

    const addDays = () => {
        const nextId = -1;
        const newDay = { id: nextId, deliverySpotId: id, day: day, time: time, active: true, new: true };
        setEditingKey(nextId);
        setDeliveryDays((prevDays: any) => [...prevDays, newDay]);
    };

    const removeDays = (id: number) => {
        const updatedDays = deliveryDays.map((d: any) => {
            if (d.id === id) {
                removeReq(d);
                return { ...d, active: false };
            }
            return d;
        });
        setDeliveryDays(updatedDays);
    };

    const columns: any = [
        {
            title: geti18nText('deliverySpot.edit.days'),
            dataIndex: 'days',
            inputType: 'days',
            editable: true,
            render: (day: any, record: any) => {
                if (record && record.day) {
                    return geti18nText('app.enum.MENU_DAY.' + record.day);
                }
            },
            width: '250px',
        },
        {
            title: geti18nText('deliverySpot.edit.hoursRange'),
            dataIndex: 'time',
            editable: true,
            inputType: 'time',
            width: '400px',
            render: (time: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={time}>
                        <div className={'description-div-large'}>
                            <p className="description-paragraph">{time}</p>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            width: '100px',
            render: (text: any, record: any) => {
                if (deliveryDays.length >= 1) {
                    const editable = isEditing(record);
                    return editable ? (
                        <ActionEditable text={text} record={record} />
                    ) : (
                        <ActionNotEditable text={text} record={record} />
                    );
                }
            },
        },
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    editing: isEditing(record),
                    form: assetForm,
                };
            },
        };
    });

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <NyModalConfirm
                            title={geti18nText('app.default.cancel.confirm')}
                            onConfirm={() => {
                                cancel(record);
                            }}
                        >
                            <Button type="link">
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </NyModalConfirm>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                    {
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    }
                    <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                removeDays(record.id);
                            }}
                        >
                            <Button type="link">
                                <DeleteTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#ff7875"
                                />
                            </Button>
                        </NyModalConfirm>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };
        assetForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const save = async (id: any) => {
        try {
            const row = await assetForm.validateFields();
            const newData = [...deliveryDays];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                if (item.new == true) {
                    const dayRow = { day: row.days ? row.days : 1, time: row.time };
                    addNewRequest({ ...item, ...dayRow });
                    newData.splice(index, 1, { ...item, ...dayRow });
                } else if (row.days) {
                    const dayRow = { day: row.days, time: row.time };
                    editRequest({ ...item, ...dayRow });
                    newData.splice(index, 1, { ...item, ...dayRow });
                } else {
                    editRequest({ ...item, ...row });
                    newData.splice(index, 1, { ...item, ...row });
                }
                setDeliveryDays(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDeliveryDays(newData);
                addNewRequest(row);
                setEditingKey('');
            }
            assetForm.resetFields();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (record.delivery == undefined) {
            deleteRow(record.id);
        }
    };

    const deleteRow = (id: any) => {
        if (id) {
            setDeliveryDays(deliveryDays.filter((row: any) => row.id !== id));
        }
    };

    const removeReq = (day: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.DELIVERY_SPOT_TIME.EDIT + '/' + day.id, undefined, {
            id: day.id,
            type: type,
            day: day.day,
            time: day.time,
            refId: id,
            active: false,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const editRequest = (day: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.DELIVERY_SPOT_TIME.EDIT + '/' + day.id, undefined, {
            id: day.id,
            type: type,
            day: day.day,
            time: day.time,
            refId: id,
            active: true,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const addNewRequest = (day: any) => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DELIVERY_SPOT_TIME.EDIT, undefined, {
            id: undefined,
            type: type,
            day: day.day,
            time: day.time,
            refId: id,
            active: true,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                getDeliveryDays(id);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    return (
        <>
            <Row gutter={24} style={{ height: '36px' }}>
                <Col span={24}>
                    <Button
                        style={{ float: 'right', marginRight: '12px' }}
                        icon={<PlusCircleOutlined />}
                        onClick={addDays}
                        disabled={editingKey != ''}
                    >
                        {geti18nText('deliverySpot.edit.addDays')}
                    </Button>
                </Col>
            </Row>
            <Form form={assetForm} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    size={'small'}
                    dataSource={deliveryDays.filter((deliveryDay: any) => {
                        return deliveryDay.active != false;
                    })}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                />
            </Form>
        </>
    );
};

export default DeliverySpotTime;
