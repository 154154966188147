import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row, Select } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    formatJoppdCodeSearch,
    getEnumArray,
    setDateFormat,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const PayrollBrutEdit = (props: any) => {
    const history = useHistory();
    const { id } = useParams<any>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [editHeader, setEditHeader] = useState(geti18nText('payrollBrut.edit.new'));
    const [dataForm, setDataForm] = useState<any>(null);
    const [isUpdated, setIsUpdated] = useState<any>(null);

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks() || HumanResourcesRights.canCreateCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('payrollBrut.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '6.2.');
        }
        if (dataForm.joppdEmployeeCode) {
            dataForm.joppdEmployeeCode = await formatJoppdCodeSearch(dataForm.joppdEmployeeCode, 2, '6.1.');
        }

        if (dataForm.createdBy) {
            dataForm.createdBy = dataForm.createdBy.person.firstName + ' ' + dataForm.createdBy.person.lastName;
        }

        if (dataForm.updatedBy) {
            setIsUpdated(true);
            dataForm.updatedBy = dataForm.updatedBy.person.firstName + ' ' + dataForm.updatedBy.person.lastName;
        }

        if (dataForm.created) {
            dataForm.created = setDateFormat(dataForm.created);
        }

        if (dataForm.updated) {
            dataForm.updated = setDateFormat(dataForm.updated);
        }
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payrollBrut.edit.new'));
        setDataForm(null);
        setIsUpdated(false);
    };

    const setJoppdDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '6.2.');
    };

    const setJoppdEmployeeDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '6.1.');
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            paramsId={id}
            form={form}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PAYROLL_BRUT.EDIT}
            setValues={setValues}
            width={600}
            goBack={() => history.goBack()}
            shortcuts={true}
            onModalOpen={onModalOpen}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.joppdCode) values.joppdCode = values.joppdCode.code;
                if (values.joppdEmployeeCode) values.joppdEmployeeCode = values.joppdEmployeeCode.code;

                values.addition = false;
                delete values.created;
                delete values.updated;
                delete values.createdBy;
                delete values.updatedBy;
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }} hidden>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('payrollBrut.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('payrollBrut.edit.coefficient')}
                        name="coefficient"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber isDecimal style={{ width: '100%' }} nyTestId="coefficient" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('payrollBrut.edit.attendanceType')}
                        name="attendanceType"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={2}
                    >
                        <Select options={getEnumArray('ATTENDANCE_TYPE', 'label', 'value')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('payrollBrut.edit.joppdEmployeeCode')}
                        name="joppdEmployeeCode"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={setJoppdEmployeeDefaultFilterValue}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppd-employee-code"
                            SearchPopupComponent={
                                <JoppdCodeIndex defaultFilteredValue={setJoppdEmployeeDefaultFilterValue} />
                            }
                            itemName="code"
                            addNewItem={true}
                            order="code"
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('payrollBrut.edit.joppdCode')}
                        name="joppdCode"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={setJoppdDefaultFilterValue}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppd-income-code"
                            SearchPopupComponent={<JoppdCodeIndex defaultFilteredValue={setJoppdDefaultFilterValue} />}
                            itemName="code"
                            addNewItem={true}
                            order="code"
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {dataForm && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('payrollBrut.edit.created')} name="created">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                disabled
                                format="DD.MM.YYYY HH:mm"
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('payrollBrut.edit.createdBy')} name="createdBy">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    {isUpdated && (
                        <Col span={12}>
                            <Form.Item label={geti18nText('payrollBrut.edit.updated')} name="updated">
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    disabled
                                    format="DD.MM.YYYY HH:mm"
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {isUpdated && (
                        <Col span={12}>
                            <Form.Item label={geti18nText('payrollBrut.edit.updatedBy')} name="updatedBy">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            )}
        </NyDataEdit>
    );
};

export default PayrollBrutEdit;
