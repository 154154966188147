import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import EmployeeEvaluationPeriodEdit from './period-edit';
import EmployeeEvaluationPeriodIndex from './period-index';

const EmployeeEvaluationPeriodSearch = ({
    label = geti18nText('employee.evaluation.edit.duration'),
    map = {
        id: 'id',
        key: 'id',
        label: 'label',
        dateFrom: 'dateFrom',
        dateTo: 'dateTo',
        text: 'text',
        employeeEvaluatioDraftsCount: 'employeeEvaluatioDraftsCount',
    },
    mustGetPopupContainer = false,
    name = 'employeeEvaluationPeriod',
    required = true,
    onChange = undefined,
    placeholder = undefined,
    canAddNew = false,
}: any) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const customRenderOption = (option: any) => {
        if (option?.dateFrom && option?.dateTo)
            return (
                <>
                    <span>
                        {moment(option.dateFrom).format('DD.MM.YYYY.')} - {moment(option.dateTo).format('DD.MM.YYYY.')}
                    </span>
                    <span style={{ display: 'none' }}>{option.employeeEvaluatioDraftsCount}</span>
                </>
            );
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.SEARCH}
                map={map}
                searchBy="dateFrom, dateTo"
                order="id"
                orderType="desc"
                itemName={[['dateFrom'], ['dateTo']]}
                AddNewModalComponent={canAddNew ? EmployeeEvaluationPeriodEdit : null}
                SearchPopupComponent={<EmployeeEvaluationPeriodIndex sortOrder={{ order: 'id', orderType: 'desc' }} />}
                mustGetPopupContainer={mustGetPopupContainer}
                setDefaultFilterValue={setDefaultFilterValue}
                renderOption={customRenderOption}
                onChange={onChange}
                placeholder={placeholder}
                addNewItem={false}
            />
        </Form.Item>
    );
};

export default EmployeeEvaluationPeriodSearch;
