import { PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, message, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getDateFormat } from '../../utils/Utils';
import NyReportButton from '../report-button';
import TravelWarrantMap from '../travel-warrant-map/TravelWarrantMap';
import TravelWarrantWageSummaryIndex from '../travel-warrant-wage-summary';
import moment from 'moment';

const TravelWarrantRecapitulationIndex = ({ dataForm, isPlane, activeKey, showIsDirtyWage = false }: any) => {
    const showCalculationDocument = useApplicationSetting('TRAVEL_WARRANT_SHOW_CALCULATION_DOCUMENT');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const enExportType = useEnum('EXPORT_TYPE');
    const componentRef = useRef<any>();
    const [formTravelWarrant] = Form.useForm();
    const [printPdf, setPrintPdf] = useState(false);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [file, setFile] = useState<any>(undefined);
    const [travelWarrant, setTravelWarrant] = useState<any>(null);
    const [destinationJson, setDestinationJson] = useState(null);
    const [recapitulationDataLoaded, setRecapitulationDataLoaded] = useState<boolean>(false);
    const [refreshRecapitulationData, setRefreshRecapitulationData] = useState<any>(0);

    useEffect(() => {
        if (dataForm && dataForm >= 0 && activeKey == 'recapitulation') {
            setTravelWarrantValues(dataForm);
            setRefreshRecapitulationData(refreshRecapitulationData + 1);
        }
    }, [dataForm, activeKey]);

    useEffect(() => {
        if (destinationJson != null) {
            setDestinationValues(destinationJson);
        }
    }, [destinationJson]);

    function setDestinationValues(destinationJson: any) {
        let waypointsList: any = [];

        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        let initList = [];
        setReturnRoute(false);
        if (lastItem?.id == 9999) {
            const firstItem = waypointsList[0];
            setReturnRoute(true);
            const returnList = waypointsList.filter((x: any) => x.id >= 9000 && x.id != 9999);
            initList = JSON.parse(JSON.stringify(returnList));
            returnIntermediateWaypointsInit.current = initList;
            let list = [firstItem].concat(returnList).concat(lastItem);

            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
            formTravelWarrant.setFieldsValue({ returnRoute: true });

            setReturnWaypoints(JSON.parse(JSON.stringify(list)));
        }
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);
    }

    const getTravelWwarrantCalculationFile = (travelWarrantId: any) => {
        let parms = {
            lang: NyUtils.getSelectedLanguage(),
            subreportId: undefined,
            useDefault: true,
            exportType: enExportType.PDF,
            ...{ id: Number(dataForm) },
            v: moment(),
        };
        NyRequestResolver.requestGet(CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION, parms, false, true).then(
            (result: any) => {
                message.destroy();
                if (result.status === RESPONSE.OK && result.data) {
                    result.data.filename =
                        geti18nText('travel.warrant.print.pr') + moment().format('yyyyMMDD') + '.pdf';
                    setFile(result);
                }
            }
        );
    };

    const setTravelWarrantValues = (travelWarrantId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + travelWarrantId, undefined).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result?.data) {
                    setTravelWarrant(result.data);
                    if (result.data.destinationJson) {
                        setDestinationJson(JSON.parse(result.data.destinationJson));
                    }

                    let data: any = {
                        startDate: result.data.startDate,
                        endDate: result.data.endDate,
                    };
                    if (
                        !(
                            getDateFormat(result.data.startDate, 'HH:mm') == '00:00' &&
                            getDateFormat(result.data.endDate, 'HH:mm') == '00:00'
                        )
                    ) {
                        data['startDateTime'] = result.data.startDate;
                        data['endDateTime'] = result.data.endDate;
                    }
                    formTravelWarrant.setFieldsValue(data);
                    getTravelWwarrantCalculationFile(travelWarrantId);
                }
            }
        );
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <div ref={componentRef}>
                        <TravelWarrantWageSummaryIndex
                            travelWarrantId={dataForm}
                            printPdf={printPdf}
                            setRecapitulationDataLoaded={setRecapitulationDataLoaded}
                            refreshWageSummary={refreshRecapitulationData}
                            setRefreshWageSummary={setRefreshRecapitulationData}
                            showIsDirtyWage={showIsDirtyWage}
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Descriptions
                        style={{ marginTop: '10px' }}
                        column={1}
                        title={geti18nText('travelWarrantRelation.destination')}
                        size="small"
                    />
                    <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={formTravelWarrant}>
                        <Form.Item>
                            <TravelWarrantMap
                                hidePrint={true}
                                showMap={true}
                                form={formTravelWarrant}
                                waypoints={waypoints}
                                setWaypoints={setWaypoints}
                                maxWaypointsKey={maxWaypointsKey}
                                setMaxWaypointsKey={setMaxWaypointsKey}
                                nyTestId="map"
                                isPlane={isPlane}
                                displayOnly={true}
                                returnRoute={returnRoute}
                                returnWaypoints={returnWaypoints}
                                setReturnWaypoints={setReturnWaypoints}
                                returnIntermediateWaypointsInit={returnIntermediateWaypointsInit.current}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            {recapitulationDataLoaded ? (
                <Row gutter={24}>
                    <Col span={24} style={{ marginTop: '30px', marginBottom: '-20px' }}>
                        <div style={{ float: 'right' }}>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button key={'print'} style={{ display: 'inline' }} icon={<PrinterOutlined />}>
                                            {geti18nText('travelWarrant.action.pdf.short')}
                                        </Button>
                                    );
                                }}
                                onBeforeGetContent={() => {
                                    setPrintPdf(true);
                                    return Promise.resolve();
                                }}
                                onAfterPrint={() => {
                                    setPrintPdf(false);
                                }}
                                content={() => componentRef.current}
                                pageStyle={`@page {size: auto;margin: 10mm;}`}
                            />
                            <div style={{ display: 'inline-block', marginLeft: '8px', marginRight: '8px' }}>
                                <NyReportButton
                                    url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION}
                                    subreportType={'TRAVEL_WARRANT_CALCULATION'}
                                    customParms={{ id: Number(dataForm) }} //record id
                                    buttoni18nText={'travelWarrant.action.pdf.calculation'}
                                    fileName={geti18nText(
                                        'app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT_CALCULATION
                                    )}
                                    fileNameAddition={travelWarrant?.ordinal ? travelWarrant?.ordinal : ''}
                                    type={'button'}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <NySpinner />
            )}
            {showCalculationDocument > 0 && (
                <Row gutter={24} style={{ height: '600px', marginTop: '10px', marginBottom: '10px' }}>
                    <Col span={24}>
                        <Descriptions
                            column={1}
                            title={geti18nText('travelWarrantRelation.calculationPdf')}
                            size="small"
                        />
                        {file && (
                            <embed
                                src={window.URL.createObjectURL(file.data.file).concat(
                                    '#toolbar=0&navpanes=0&scrollbar=0&zoom=120'
                                )}
                                type="application/pdf"
                                height="100%"
                                width="100%"
                            ></embed>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default TravelWarrantRecapitulationIndex;
