import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import SickLeaveIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import SickLeaveEdit from './edit';

const SickLeaveSearch = ({
    label = geti18nText('employeeSickLeave.edit.sickLeave'),
    name = 'sickLeave',
    required = false,
    validator = undefined,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
                {
                    validator: validator,
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.SICK_LEAVE.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                AddNewModalComponent={SickLeaveEdit}
                SearchPopupComponent={<SickLeaveIndex />}
                nyTestId="sick-leave"
                customListWidth={'1400px'}
            />
        </Form.Item>
    );
};

export default SickLeaveSearch;
