import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import WorkObligationCallIndex from './WorkObligationCallIndex';
import WorkObligationAttachments from './WorkObligationAttachments';
const { TabPane } = Tabs;

const WorkObligationIndex = ({}: any) => {
    const [activeKey, setActiveKey] = useState<string>('1');
    useHelper('human/work-obligation/mobilization');

    return (
        <>
            <React.Fragment>
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        setActiveKey(key);
                    }}
                >
                    <TabPane tab={geti18nText('menu.work.obligation.call.table.header')} key="1">
                        {activeKey == '1' && <WorkObligationCallIndex scroll={{ y: 580, x: 800 }} />}
                    </TabPane>
                    <TabPane tab={geti18nText('menu.work.obligation.tab.2')} key="2">
                        {activeKey == '2' && <div></div>}
                    </TabPane>
                    <TabPane tab={geti18nText('menu.work.obligation.tab.3')} key="3">
                        {activeKey == '3' && <div></div>}
                    </TabPane>
                    <TabPane tab={geti18nText('menu.work.obligation.tab.4')} key="4">
                        {activeKey == '4' && <div></div>}
                    </TabPane>
                    <TabPane tab={geti18nText('menu.work.obligation.tab.5')} key="5">
                        {activeKey == '5' && <WorkObligationAttachments />}
                    </TabPane>
                </Tabs>
            </React.Fragment>
        </>
    );
};

export default WorkObligationIndex;
