import { createSlice } from '@reduxjs/toolkit';

interface IPinedNotificationsSlice {
    pinedNotifications: number;
}

const initialState: IPinedNotificationsSlice = {
    pinedNotifications: 0,
};

const pinedNotifications = createSlice({
    name: 'pinedNotifications',
    initialState,
    reducers: {
        pinedNotificationsInit(state) {
            state.pinedNotifications = 0;
        },
        setPinedNotifications(state, action) {
            state.pinedNotifications = action.payload;
        },
    },
});

export const { pinedNotificationsInit, setPinedNotifications } = pinedNotifications.actions;

export default pinedNotifications.reducer;
