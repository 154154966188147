import { IssuesCloseOutlined, ShoppingCartOutlined, TabletOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Card, Statistic, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssetRights } from '../../rights/assetRights';
import { RootState } from '../../rootReducer';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getColorFromNotificationType } from '../../utils/Utils';

const AssetOrderWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);
    const widgetColor = getColorFromNotificationType(settings, 'ASSET_ORDER');

    const hasAssetReleaseRole = () => {
        return AssetRights.canCreateRelease();
    };

    function getAssertKeys() {
        let assertKeys = [];
        assertKeys.push('myAsset');
        assertKeys.push('myOrderAsset');
        assertKeys.push('orderAssetRequested');
        if (hasAssetReleaseRole()) {
            assertKeys.push('orderAssetApproved');
        }
        return {
            keys: assertKeys,
        };
    }

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(CONSTANTS_REQ.DASHBOARD.GET_STATISTICS, undefined, getAssertKeys(), true, false, {
        myAsset: 0,
        myOrderAsset: 0,
        orderAssetRequested: -1,
        orderAssetApproved: -1,
    });

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px 10px',
    };

    return (
        <Card
            className="ny-widget-card"
            title={geti18nText('menu.asset')}
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            bodyStyle={{
                height: 'calc(100% - 55px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '4px 5px 0px 5px',
                marginTop: '1px',
            }}
            type="inner"
            extra={
                <Tooltip placement="top" title={geti18nText('menu.webshopAsset')}>
                    <ShoppingCartOutlined
                        className="ny-card-icon"
                        onClick={() => {
                            history.push('/webshop/webshop-asset');
                        }}
                    />
                </Tooltip>
            }
        >
            {loading ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : (
                <>
                    <Card.Grid
                        style={gridStyle}
                        className="statistic-card"
                        onClick={() => history.push('/webshop/webshop-asset/my-asset')}
                    >
                        <Statistic
                            title={geti18nText('dashboard.statistic.employee.asset.count')}
                            value={statisticData && statisticData['myAsset']}
                            prefix={<TabletOutlined />}
                        />
                    </Card.Grid>
                    <Card.Grid
                        style={gridStyle}
                        className="statistic-card"
                        onClick={() => history.push('/webshop/webshop-asset/my-asset-order')}
                    >
                        <Statistic
                            title={geti18nText('dashboard.statistic.asset.order.count')}
                            value={statisticData && statisticData['myOrderAsset']}
                            prefix={<ShoppingCartOutlined />}
                        />
                    </Card.Grid>
                    {statisticData && statisticData['orderAssetRequested'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => history.push('/webshop/webshop-asset/asset-order')}
                        >
                            <Statistic
                                title={geti18nText('dashboard.statistic.asset.order.requested.count')}
                                value={statisticData['orderAssetRequested']}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                    {statisticData && statisticData['orderAssetApproved'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => history.push('/webshop/webshop-asset/asset-order')}
                        >
                            <Statistic
                                title={geti18nText('dashboard.statistic.asset.order.approved.count')}
                                value={statisticData['orderAssetApproved']}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                </>
            )}
        </Card>
    );
};

export default AssetOrderWidget;
