import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Dropdown, Empty, Menu, Radio } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { getEnumBooleanArray, isAdmin } from '../../../../../utils/Utils';
import InventoryReportModal from '../../webshop-item/inventory-release/InventoryReportModal';
import ServicesReleaseEdit from './edit';
import { ServicesRights } from '../../../../../rights/servicesRights';

const ServicesReleaseIndex = ({ scroll = { y: 520, x: 800 }, sortOrder }: any) => {
    const enAssetInventorySpecType = useEnum('ASSET_INVENTORY_SPEC_TYPE');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const table = useTableSettings();
    const dispatch = useDispatch();
    useHelper('inventory/inventory_release');

    const [orderViewId, setOrderViewId] = useState<any>(null);
    const [orderViewLabel, setOrderViewLabel] = useState<any>('');
    const [orderViewList, setOrderViewList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState<any>(0);

    const canExportCSV = () => {
        return ServicesRights.canCsvExportCodebooks();
    };

    useEffect(() => {
        setLoading(true);
        fetchOrderView();
    }, []);

    let setDefaultFilterValue = () => {
        if (orderViewId != undefined) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'orderView.id',
                    condition: 'equals',
                    value: orderViewId,
                },
            ];
        }

        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultFilterValueOrderView = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 3 },
            { field: 'orderViewAccessType', condition: 'equals', value: 3 },
        ];
    };

    const fetchOrderView = () => {
        const emptyData: any = [];
        if (orderViewList == emptyData || orderViewList.length < 1) {
            let defaultFilterOrder = setDefaultFilterValueOrderView();
            NyRequestResolver.requestGet(CONSTANTS_REQ.ORDER_VIEW.USER_SEARCH, {
                search: encodeURI(JSON.stringify(defaultFilterOrder)),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data[0]) {
                            if (result.data[0].id) setOrderViewId(result.data[0].id);
                            if (result.data[0].name) setOrderViewLabel(result.data[0].name);
                        }

                        let oView: any = [];
                        for (let key in result.data) {
                            if (result.data.hasOwnProperty(key)) {
                                let order = { value: undefined, label: undefined, number: undefined };
                                order.value = result.data[key].id;
                                order.label = result.data[key].name;
                                oView.push(order);
                            }
                        }
                        setOrderViewList(oView);
                    }
                }
                setLoading(false);
            });
        }
    };

    const onChangeOrderView = (e: any) => {
        setOrderViewId(e.target.value > 0 ? e.target.value : null);
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    const columns = [
        {
            title: geti18nText('inventoryRelease.table.column.id'),
            width: '5%',
            dataIndex: 'id',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('inventoryRelease.table.column.ord'),
            dataIndex: [`concat(_ir.ord, '/', date_part('year', _ir.date))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryRelease.table.column.warehouseCode'),
            dataIndex: ['warehouse', 'code'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.WAREHOUSE.SEARCH, 'warehouse.id', 'code', 'code', {
                id: 'id',
                label: 'code',
                text: 'code',
            }),
        },
        {
            title: geti18nText('inventoryRelease.table.column.documentAbbreviation'),
            dataIndex: ['warehouseDocument', 'abbreviation'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.WAREHOUSE_DOCUMENT.SEARCH,
                'warehouseDocument.id',
                'name',
                'name',
                { id: 'id', label: 'name', text: 'name' }
            ),
        },
        {
            title: geti18nText('inventoryRelease.table.column.receivedBy'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (person: any, record: any) => {
                if (record.employee && record.employee.person) {
                    return record.employee.person.firstName + ' ' + record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryRelease.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryRelease.table.column.createdBy'),
            dataIndex: [`concat(userPerson.first_name,' ', userPerson.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (person: any, record: any) => {
                if (record.userPerson) {
                    return record.userPerson.firstName + ' ' + record.userPerson.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryRelease.table.column.bookedStock'),
            dataIndex: 'bookedStock',
            sorter: (a: any, b: any) => {},
            render: (bookedStock: any) => {
                if (bookedStock) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('inventoryRelease.table.column.bookedCost'),
            dataIndex: 'bookedCosts',
            sorter: (a: any, b: any) => {},
            render: (bookedCosts: any) => {
                if (bookedCosts) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('inventoryRelease.table.column.order'),
            dataIndex: ['inventoryOrder'],
            render: (text: any, record: any) => {
                if (
                    record &&
                    record.inventoryOrder &&
                    record.inventoryOrder.id &&
                    record.inventoryOrder.ord &&
                    record.inventoryOrder.date
                ) {
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'inventoryOrderEdit',
                                        visible: true,
                                        item: record.inventoryOrder.id,
                                    })
                                )
                            }
                        >
                            {record.inventoryOrder.ord + '/' + moment(record.inventoryOrder.date).format('YYYY')}
                        </Button>
                    );
                } else if (
                    record &&
                    record.servicesOrder &&
                    record.servicesOrder.id &&
                    record.servicesOrder.ord &&
                    record.servicesOrder.date
                ) {
                    return (
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'servicesOrderEdit',
                                        visible: true,
                                        item: record.servicesOrder.id,
                                    })
                                )
                            }
                        >
                            {record.servicesOrder.ord + '/' + moment(record.servicesOrder.date).format('YYYY')}
                        </Button>
                    );
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'Release'} record={record}></RowAction>;
                }
            },
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <InventoryReportModal
                            reportType={'INVENTORY_RELEASE_SPECIFICATION'}
                            reportTitle={'inventoryRelease.report.inventoryReleaseSpecification'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.INVENTORY_RELEASE_SPECIFICATION
                            )}
                            url={CONSTANTS_REQ.REPORT.INVENTORY_RELEASE_SPECIFICATION}
                            type={enAssetInventorySpecType.SERVICES}
                        />
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <InventoryReportModal
                            reportType={'INVENTORY_RELEASE_RECAPITULATION'}
                            reportTitle={'inventoryRelease.report.inventoryReleaseRecapitulation'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.INVENTORY_RELEASE_RECAPITULATION
                            )}
                            url={CONSTANTS_REQ.REPORT.INVENTORY_RELEASE_RECAPITULATION}
                            type={enAssetInventorySpecType.SERVICES}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                options={orderViewList}
                value={orderViewId}
                onChange={onChangeOrderView}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '15px' }}
            />
            {isAdmin() || (orderViewList && orderViewList.length > 0) ? (
                <NyDataTable
                    nyId="webshop-services-services-release-table"
                    url={CONSTANTS_REQ.INVENTORY_RELEASE.LIST}
                    addNewButtonText={geti18nText('assetRelease.table.add')}
                    buttonsClassName="buttons-sticky"
                    showRecordModal={true}
                    modalComponent={ServicesReleaseEdit}
                    editProps={{ refresh: refresh, setRefresh: setRefresh }}
                    columns={columns}
                    setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                    scroll={scroll}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    shortcuts={true}
                    hideNewButton={true}
                    addedButtons={addedButtons}
                    fetchWhenChange={refresh || orderViewId}
                    addedData={{ orderViewId: orderViewId }}
                    headerTitle={geti18nText('menu.services.release')}
                    exportCSV={canExportCSV()}
                    CSVFileName={geti18nText('menu.services.release')}
                    colCSVCustomization={csvColumnCustomisation()}
                    csvColumns={csvColumns()}
                />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            date: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY HH:mm');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + value);
            },
        },
        {
            userPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            employee: (value: any) => {
                return value?.person && value.person.firstName + ' ' + value.person.lastName;
            },
        },
        {
            bookedStock: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            bookedCosts: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            inventoryOrder: (value: any) => {
                return value && value.ord && value.date
                    ? value.ord.toString() + '/' + moment(value.date).format('YYYY')
                    : '';
            },
        },
        {
            servicesOrder: (value: any) => {
                return value && value.ord && value.date
                    ? value.ord.toString() + '/' + moment(value.date).format('YYYY')
                    : '';
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('inventoryRelease.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('inventoryRelease.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('inventoryRelease.table.column.warehouseCode'),
            dataIndex: ['warehouse', 'code'],
        },
        {
            title: geti18nText('inventoryRelease.table.column.documentAbbreviation'),
            dataIndex: ['warehouseDocument', 'abbreviation'],
        },
        {
            title: geti18nText('inventoryRelease.table.column.receivedBy'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('inventoryRelease.table.column.date'),
            dataIndex: 'date',
        },
        {
            title: geti18nText('inventoryRelease.table.column.createdBy'),
            dataIndex: 'userPerson',
        },
        {
            title: geti18nText('inventoryRelease.table.column.bookedStock'),
            dataIndex: 'bookedStock',
        },
        {
            title: geti18nText('inventoryRelease.table.column.bookedCost'),
            dataIndex: 'bookedCosts',
        },
        {
            title: geti18nText('inventoryRelease.table.column.order'),
            dataIndex: 'inventoryOrder',
        },
        {
            title: geti18nText('inventoryRelease.table.column.order'),
            dataIndex: 'servicesOrder',
        },
    ];
};

export default ServicesReleaseIndex;
