import { NyDataTable, NySession, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const PeacetimeSystematizationTable = ({
    scroll = { y: 595, x: 800 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const table = useTableSettings();

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('assetReturn.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            width: '20%',
            ...getColumnSearchByCodebookBusinessUnit(true),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                        record.businessUnit.name
                    );
                }
            },
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.vocation'),
            dataIndex: ['vocation', 'name'],
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
            dataIndex: ['vocationDescription', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                'vocationDescription.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
        },
        {
            title: geti18nText('payrollBrut.table.column.employee'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            ...getColumnSearch('string'),
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.person) {
                    return (
                        <>
                            {record.employee ? (
                                <a
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => modalVisible(record.employee)}
                                >
                                    {record.person.firstName + ' ' + record.person.lastName}
                                </a>
                            ) : (
                                <>{record.person.firstName + ' ' + record.person.lastName}</>
                            )}
                        </>
                    );
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    return (
        <>
            <NyDataTable
                nyId="human-peacetime-systematization-table"
                url={CONSTANTS_REQ.WORKPLACE_DETAILS.LIST}
                buttonsClassName="buttons-sticky"
                readonly
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideButtons
                shortcuts={true}
                fetchWhenChange={refresh}
                // exportCSV={canExportCSV()}
                // CSVFileName={geti18nText('menu.work.obligation.reserve.rank')}
                // colCSVCustomization={csvColumnCustomisation()}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default PeacetimeSystematizationTable;
