import { geti18nText, NyDataEdit, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Row, Select, Upload } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum } from '../../../../utils/Enums';
import { errorNotification, getDateFormat, getEnumArray, setDateFormat } from '../../../../utils/Utils';

const ReportSettingsEdit = (props: any) => {
    const [dataForm, setDataForm] = useState<any>(null);
    const [editHeader, setEditHeader] = useState(geti18nText('report.setting.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [subreportTypeEnums] = useState(GetEnum({ enumName: 'SUBREPORT_TYPE' }));
    const [enumTypes, setEnumTypes] = useState([]);
    const [xmlString, setXmlString] = useState('');
    const [defaultReport, setDefaultReport] = useState(false);
    const [autoUpdate, setAutoUpdate] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [value, setValue] = useState<any>('');
    const [type, setType] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);

    useEffect(() => {
        filterAllowedTypes();
    }, []);

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);
        if (dataForm.xmlString) {
            setXmlString(dataForm.xmlString);
        }
        setType(dataForm.type);
        setDefaultReport(dataForm.defaultReport);
        setAutoUpdate(dataForm.autoUpdate);
        if (dataForm.created) {
            dataForm.created = setDateFormat(dataForm.created);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setValue(form.getFieldValue('value'));
        setDefaultReport(false);
        setAutoUpdate(false);
        setXmlString('');
        setType(undefined);
        form.resetFields();
        setEditHeader(geti18nText('report.setting.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const filterAllowedTypes = () => {
        let types = getEnumArray('SUBREPORT_TYPE', 'label', 'value').filter(
            (item: any) => item.value !== subreportTypeEnums['HEADER'] && item.value !== subreportTypeEnums['FOOTER']
        );
        setEnumTypes(types);
    };

    const upload = {
        name: 'file',
        action: CONSTANTS_REQ.SUBREPORT.UPLOAD,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                if (info.file.response) {
                    const result = info.file.response;
                    if (result.xmlString !== undefined) {
                        setXmlString(result.xmlString);
                    } else {
                        setXmlString('');
                    }
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    const getDefaultForType = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.SUBREPORT.GET_DEFAULT_FOR_TYPE, {
            type: type,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.xmlString) {
                    message.destroy();
                    message.success(`${geti18nText('report.setting.reset.success')}`);
                    setXmlString(result.data.xmlString);
                    setAutoUpdate(false);
                } else {
                    setXmlString('');
                }
            } else {
                errorNotification(geti18nText('app.default.error'));
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.SUBREPORT.EDIT}
            setValues={setValues}
            setIsCreate={setIsCreate}
            hideActivationButtons={false}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                values.defaultReport = defaultReport;
                values.autoUpdate = autoUpdate;
                values.xmlString = xmlString;
                if (values.created) {
                    values.created = getDateFormat(values.created);
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="xmlString" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="dimensions" style={{ display: 'none' }}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="createdBy" style={{ display: 'none' }}>
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('report.setting.edit.name')}
                        name={['name']}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('report.setting.edit.type')}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Select
                            options={enumTypes}
                            onChange={(data: any) => {
                                setType(data);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {!isCreate && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('report.setting.edit.created')} name="created">
                            <DatePicker format="DD.MM.YYYY HH:mm" style={{ width: '100%' }} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('report.setting.edit.createdBy')} name="createdByName">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('report.setting.edit.hasContent')}>
                        <Checkbox checked={xmlString !== undefined && xmlString !== ''} disabled />
                        <Upload {...upload} data={{}}>
                            <Button style={{ marginLeft: '10px' }} disabled={loading}>
                                {geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_UPLOAD')}
                            </Button>
                        </Upload>
                        {type != undefined && (
                            <Button style={{ marginLeft: '10px' }} disabled={loading} onClick={getDefaultForType}>
                                {geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.RESET_BY_TYPE')}
                            </Button>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('report.setting.edit.defaultReport')} name="defaultReport">
                        <Checkbox
                            checked={defaultReport}
                            onChange={(e) => {
                                setDefaultReport(e.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('report.setting.edit.autoUpdate')} name="autoUpdate">
                        <Checkbox
                            checked={autoUpdate}
                            onChange={(e) => {
                                setAutoUpdate(e.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ReportSettingsEdit;
