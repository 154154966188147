import { DeleteTwoTone } from '@ant-design/icons';
import {
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../../utils/Filters';
import { errorNotification, getDateFormat, numberFormat, okNotification } from '../../../../../../utils/Utils';
import { default as AssetDepreciationItemsEdit } from './edit';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { setActiveTab } from '../../../../../../slices/tabsSlice';

const AssetDepreciationItemsActionIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    assetDepreciationId,
    canCreate,
    booked,
    bookedGeneralLedger,
    updateTable,
    setDataLength,
    setGetTotalsRefresh,
    getTotalsRefresh,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        setRefreshTable((refreshTable) => refreshTable + 1);
    }, [updateTable]);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'asset_depreciation_id',
                condition: 'equals',
                value: assetDepreciationId,
            },
        ];
    };

    const deleteRow = (row: any) => {
        if (row?.id >= 0) {
            let instance: any = { id: row.id };
            const assetId = row.asset && row.asset.id ? row.asset.id : null;
            let asset: any = { id: assetId };
            instance.asset = asset;
            NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_DEPRECIATION.ADD_ITEM, undefined, {
                assetDepreciationId: assetDepreciationId,
                instance: instance,
                onRepeatAction: 'delete',
            }).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification(geti18nText('app.default.delete.ok.desc'));
                    setRefreshTable((refreshTable) => refreshTable + 1);
                    setGetTotalsRefresh((prevValue: any) => prevValue + 1);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const columns: any = [
        ...[
            {
                title: geti18nText('asset.depreciationItem.table.column.asset'),
                dataIndex: ['assetModel', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                    id: 'id',
                    label: 'name',
                    serialNumber: 'serialNumber',
                    itemName: 'itemName',
                    itemCode: 'itemCode',
                }),
                render: (text: any, record: any) => {
                    if (record?.asset?.assetModel?.name && record?.asset?.id) {
                        return (
                            <div
                                style={{ textDecoration: 'underline', fontWeight: '400' }}
                                className="ant-btn-link"
                                onClick={() => {
                                    // dispatch(setActiveTab('/fixed-asset/' + record?.asset?.id));
                                    history.push('/accounting/assets/fixed-asset/' + record?.asset?.id);
                                }}
                            >
                                {record.asset.assetModel.name + ' (ID: ' + record.asset.id + ')'}{' '}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.acquisitionValue'),
                dataIndex: 'acquisitionValue',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.acquisitionValue) {
                        return numberFormat(record.acquisitionValue);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.valueIncrease'),
                dataIndex: 'valueIncrease',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.valueIncrease) {
                        return numberFormat(record.valueIncrease);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.valueDecrease'),
                dataIndex: 'valueDecrease',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.valueDecrease) {
                        return numberFormat(record.valueDecrease);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.accumulatedDepreciation'),
                dataIndex: 'acumulatedDepreciation',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.acumulatedDepreciation) {
                        return numberFormat(record.acumulatedDepreciation);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.base'),
                dataIndex: 'base',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.base) {
                        return numberFormat(record.base);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.depreciationStartDate'),
                dataIndex: ['asset', 'depreciationStartDate'],
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.asset && record.asset.depreciationStartDate) {
                        return getDateFormat(record.asset.depreciationStartDate, 'DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.depreciationEndDate'),
                dataIndex: ['asset', 'depreciationEndDate'],
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.asset && record.asset.depreciationEndDate) {
                        return getDateFormat(record.asset.depreciationEndDate, 'DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.months'),
                dataIndex: 'months',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.rate'),
                dataIndex: 'rate',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.rate) {
                        return numberFormat(record.rate);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.amount'),
                dataIndex: 'amount',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.amount) {
                        return numberFormat(record.amount);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
            {
                title: geti18nText('asset.depreciationItem.table.column.netBookValue'),
                dataIndex: 'netBookValue',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                align: 'right',
                render: (text: any, record: any) => {
                    if (record.netBookValue) {
                        return numberFormat(record.netBookValue);
                    } else {
                        return numberFormat(0);
                    }
                },
            },
        ],
        ...(canCreate && assetDepreciationId && !booked && !bookedGeneralLedger
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '5%',
                      render: (text: any, record: any) => {
                          if (record) {
                              return (
                                  <NyModalConfirm
                                      title={geti18nText('app.default.delete.confirm')}
                                      onConfirm={() => {
                                          deleteRow(record);
                                      }}
                                  >
                                      <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                          <Button type="link">
                                              <DeleteTwoTone
                                                  style={{
                                                      fontSize: '20px',
                                                  }}
                                                  twoToneColor="#ff7875"
                                              />
                                          </Button>
                                      </Tooltip>
                                  </NyModalConfirm>
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    useEffect(() => {
        setRefreshTable((prevValue: any) => prevValue + 1);
    }, [assetDepreciationId]);

    return (
        <>
            <NyDataTable
                nyId="asset-deprecation-items"
                showSizeChanger={false}
                url={CONSTANTS_REQ.ASSET_DEPRECIATION_ITEM.LIST}
                addNewButtonText={geti18nText('asset.acquisition.add.asset')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                onSaveAndGetID={() => {
                    setRefreshTable((refreshTable) => refreshTable + 1);
                    setGetTotalsRefresh(getTotalsRefresh + 1);
                }}
                modalComponent={AssetDepreciationItemsEdit}
                setDefaultFilterValue={() => setDefaultFilterValue()}
                onDataLoaded={(data: any) => {
                    if (data) {
                        setDataLength(data?.length);
                    } else {
                        setDataLength(null);
                    }
                }}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                fetchWhenChange={refreshTable || assetDepreciationId}
                hideNewButton={!canCreate || !assetDepreciationId || booked || bookedGeneralLedger}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                shortcuts={true}
                editProps={{
                    assetDepreciationId: assetDepreciationId,
                    canCreate: canCreate,
                    getTotalsRefresh: getTotalsRefresh,
                    setGetTotalsRefresh: setGetTotalsRefresh,
                    booked: booked,
                    bookedGeneralLedger: bookedGeneralLedger,
                    refreshTable: refreshTable,
                    setRefreshTable: setRefreshTable,
                }}
            />
        </>
    );
};

export default AssetDepreciationItemsActionIndex;
