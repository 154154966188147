import { geti18nText } from '@nybble/nyreact';
import { Card, Empty } from 'antd';
import { Axis, Tooltip as BizTooltip, Chart, Interval, Legend } from 'bizcharts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';

const EmployeeEvaluationChart = ({ data, title, portal = false }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';

    const scale = {
        count: {
            min: 0,
            tickInterval: 1,
        },
    };

    const truncateLabel = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const axisConfig = {
        label: {
            autoHide: false,
            style: {
                textAlign: 'center' as const,
                fill: isDarkTheme ? '#ccc' : 'black',
            },
            formatter: (text: string) => truncateLabel(text, 13),
        },
        line: {
            style: {
                stroke: '#ccc',
                lineDash: [3, 3],
            },
        },
        grid: {
            line: {
                style: {
                    stroke: '#ccc',
                    lineDash: [3, 3],
                },
            },
        },
    };

    return (
        <div
            style={{
                width: portal ? '38vw' : '800px',
                paddingLeft: '12px',
                paddingRight: '12px',
                marginTop: '8px',
                height: '500px',
            }}
        >
            <Card
                className="ny-widget-card"
                title={title ? title : geti18nText('employee.evaluation.graph.label.employeeEvaluations')}
                bodyStyle={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '4px 5px 0px 5px',
                    marginTop: '1px',
                }}
                type="inner"
            >
                {data?.length > 0 ? (
                    <Chart
                        data={data}
                        padding={[40, 40, 70, 60]}
                        scale={scale}
                        autoFit
                        height={400}
                        className="custom-chart"
                    >
                        <Axis name="item" {...axisConfig} />
                        <Interval position="item*count" color={['item', data.map((item: any) => item.color)]} />
                        <BizTooltip showTitle={false} />
                        <Legend
                            itemName={{
                                style: {
                                    fill: isDarkTheme ? 'white' : 'black',
                                },
                            }}
                            itemStates={{
                                active: {
                                    nameStyle: {
                                        opacity: 0.8,
                                    },
                                },
                                unchecked: {
                                    nameStyle: {
                                        fill: 'gray',
                                    },
                                    markerStyle: {
                                        fill: 'gray',
                                    },
                                },
                            }}
                        />
                    </Chart>
                ) : (
                    <Empty />
                )}
            </Card>
        </div>
    );
};

export default EmployeeEvaluationChart;
