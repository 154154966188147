import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import ContractTypeGroupEdit from './edit';
import { getEnumArrayStatus } from '../../../../../utils/Utils';

const ContractTypeGroupIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    useHelper('human/codebooks/contracts/contract-type-group');

    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const columns = [
        {
            title: geti18nText('contract.type.group.table.column.id'),
            dataIndex: 'id',
            width: '72px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('contract.type.group.table.column.name'),
            dataIndex: 'name',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('contract.category.rights.table.column.contractCategory'),
            dataIndex: ['contractCategory', 'contractCategory'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.CONTRACT_CATEGORY.SEARCH,
                'contractCategory.id',
                'contractCategory',
                'contractCategory',
                {
                    id: 'id',
                    label: 'contractCategory',
                }
            ),
        },
        {
            title: geti18nText('contract.type.group.table.column.party.two.category'),
            dataIndex: 'partyTwoCategory',
            render: (text: any, record: any) => {
                if (record.partyTwoCategory !== undefined) {
                    return geti18nText('app.enum.CONTRACT_PARTY_TWO.' + record.partyTwoCategory);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('CONTRACT_PARTY_TWO'), 'equals'),
        },
        {
            title: geti18nText('contract.category.rights.table.column.active'),
            dataIndex: 'active',
            width: '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-contract-contract-type-group-table"
            url={CONSTANTS_REQ.CONTRACT_TYPE_GROUP.LIST}
            addNewButtonText={geti18nText('contract.type.group.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={ContractTypeGroupEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.contract.type.group')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            headerTitle={geti18nText('menu.contract.type.group')}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
        />
    );
};
export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.type.group.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.type.group.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('contract.category.rights.table.column.contractCategory'),
            dataIndex: ['contractCategory', 'contractCategory'],
        },
        {
            title: geti18nText('contract.type.group.table.column.party.two.category'),
            dataIndex: 'partyTwoCategory',
        },
        {
            title: geti18nText('contract.category.rights.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            partyTwoCategory: (value: any) => {
                return value != undefined && geti18nText('app.enum.CONTRACT_PARTY_TWO.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ContractTypeGroupIndex;
