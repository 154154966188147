import {
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button } from 'antd';
import { ReactText, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { refreshItemList } from '../../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import {
    getDateFormat,
    numberFormat,
    getEnumArrayStatus,
    okNotification,
    errorNotification,
} from '../../../../../utils/Utils';
import { PayrollRights } from '../../../../../rights/payrollRights';

const GroupOrdersTable = ({ props, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const dispatch = useDispatch();
    const table = useTableSettings();

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'paymentOrderGroup.id', condition: 'equals', value: props.groupId },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('payment.orders.individual.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.dueDate'),
            dataIndex: 'dueDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dueDate) {
                    return getDateFormat(record.dueDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorName'),
            dataIndex: 'debtorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorAccountIban'),
            dataIndex: 'debtorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorName'),
            dataIndex: 'creditorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorAccountIban'),
            dataIndex: 'creditorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorRemittanceInfo'),
            dataIndex: 'creditorRemittanceInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.amount'),
            dataIndex: 'amount',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                if (amount) {
                    return numberFormat(amount);
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.paymentDate'),
            dataIndex: 'completedDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.completedDate) {
                    return getDateFormat(record.completedDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.status'),
            dataIndex: 'status',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('PAYMENT_ORDER_STATUS_TYPE')),
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.PAYMENT_ORDER_STATUS_TYPE.' + record.status);
                }
            },
        },
    ];

    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    let clearKeys: any;

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    const onDeleteFromGroupClick = (onSave: () => void) => {
        let values = {
            groupId: props.groupId,
            paymentOrders: selectedValues,
        };
        NyRequestResolver.requestPut(CONSTANTS_REQ.PAYMENT_ORDER.DELETE_FROM_GROUP, undefined, values).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    clearKeys();
                    onSave();
                    props.form.resetFields();
                    dispatch(refreshItemList());
                } else {
                    errorNotification();
                }
            }
        );
    };

    const DeleteFromGroup = (onSave: () => void) => {
        return (
            <>
                {hasSelected && canCreate() && (
                    <NyModalConfirm
                        title={geti18nText('payment.orders.group.DeleteFromGroup.text')}
                        onConfirm={() => {
                            onDeleteFromGroupClick(onSave);
                        }}
                    >
                        <Button>{geti18nText('payment.orders.group.DeleteFromGroup')}</Button>
                    </NyModalConfirm>
                )}
            </>
        );
    };

    return (
        <NyDataTable
            nyId="payment-orders-table"
            url={CONSTANTS_REQ.PAYMENT_ORDER.LIST}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            showRecordModal={false}
            hideNewButton={true}
            columns={initialColumns}
            setDefaultPageSize={table.setDefaultPageSize()}
            readonly={true}
            showRowSelection={props.paymentOrderStatusNone && canCreate()}
            rowSelectionType={'checkbox'}
            rowSelectionModal={setSelectedValuesFunc as any}
            addedButtons={DeleteFromGroup}
            buttonsClassName="ny-data-table-modal-footer"
        />
    );
};

export default GroupOrdersTable;
