import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import ProfessionalQualificationIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import ProfessionalQualificationEdit from './edit';

const ProfessionalQualificationSearch = ({
    label = geti18nText('employee.edit.professional.qualification'),
    name = 'professionalQualification',
    required = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH}
                map={{ id: 'id', label: 'text', text: 'name' }}
                searchBy="text"
                nyTestId="qualification"
                AddNewModalComponent={ProfessionalQualificationEdit}
                SearchPopupComponent={<ProfessionalQualificationIndex customIdWidth={'86px'} />}
            />
        </Form.Item>
    );
};

export default ProfessionalQualificationSearch;
