import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Button, Collapse, Empty, List, Modal, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getEnumArray } from '../../utils/Utils';

const { Panel } = Collapse;

const CustomHistory = ({ editHeader = undefined, url, id, type, showOpenModalButton = true }: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [changes, setChanges] = useState<any>(null);
    const [codebooksCache, setCodebooksCache] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);

    const pendingRequests: any = {};

    function getCodebookValue(url: string): any {
        if (codebooksCache[url]) {
            return codebooksCache[url];
        }

        if (pendingRequests[url]) {
            return;
        }

        pendingRequests[url] = true;

        NyRequestResolver.requestGet(url, undefined, true)
            .then((result: any) => {
                if (result.status === RESPONSE.OK && result?.data) {
                    codebooksCache[url] = result.data;
                    setCodebooksCache({ ...codebooksCache });
                }
            })
            .finally(() => {
                delete pendingRequests[url];
            });
        return;
    }

    function getDisplayValue(typeName: string, cdoId: number) {
        const baseUrl = typeNameMap[typeName];
        if (!baseUrl) return '';

        const codebookValue = getCodebookValue(`${baseUrl}/${cdoId}`);

        return typeName === 'Person'
            ? `${codebookValue?.firstName || ''} ${codebookValue?.lastName || ''}`
            : codebookValue?.name || '';
    }

    const getChangeLog = () => {
        setLoading(true);
        NyRequestResolver.requestGet(url + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const object: any = Object.values(result.data).flat()[0];
                let values = Object.values(object?.changes ?? {});
                values.map((value: any) => {
                    value.listChanges = value.listChanges.filter((change: any) => {
                        return !['employee.contact', 'employee.privateContact'].includes(type + '.' + change.property);
                    });
                });
                values = values.filter((value: any) => {
                    return value.listChanges.length;
                });
                setChanges(values);
            }
        });
        setLoading(false);
    };

    const typeNameMap: any = {
        Person: CONSTANTS_REQ.PERSON.EDIT,
        ProfessionalQualification: CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.EDIT,
        EmploymentType: CONSTANTS_REQ.EMPLOYMENT_TYPE.EDIT,
        EmployeeGroup: CONSTANTS_REQ.EMPLOYEE_GROUP.EDIT,
        Room: CONSTANTS_REQ.ROOM.EDIT,
        Vocation: CONSTANTS_REQ.VOCATION.EDIT,
        WorkplaceDetails: CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT,
        VocationDescription: CONSTANTS_REQ.VOCATION_DESCRIPTION.EDIT,
        EmployeeStatus: CONSTANTS_REQ.EMPLOYEE_STATUS.EDIT,
        BusinessUnit: CONSTANTS_REQ.BUSINESS_UNIT.EDIT,
        BusinessUnitType: CONSTANTS_REQ.BUSINESS_UNIT_TYPE.EDIT,
        Location: CONSTANTS_REQ.LOCATION.EDIT,
        Portfolio: CONSTANTS_REQ.PORTFOLIO.EDIT,
        AccountingCostCenter: CONSTANTS_REQ.COST_CENTER.EDIT,
    };

    const formatDate = (dateArray: number[]) => {
        if (dateArray.length === 5) {
            const [year, month, day, hour, minute] = dateArray;
            return moment({ year, month: month - 1, day, hour, minute }).format('DD.MM.YYYY.');
        }
        return '';
    };

    const renderValue = (value: any, propertyType: any, property: any) => {
        if (value === undefined) return undefined;
        switch (propertyType) {
            case 'boolean':
                return value ? geti18nText('app.enum.boolean.yes') : geti18nText('app.enum.boolean.no');
            case 'string':
                return value;
            case 'date':
                return formatDate(value);
            case 'number':
                switch (type + '.' + property) {
                    case 'employee.type' || 'employee.employmentType':
                        return getEnumArray('EMPLOYEE_TYPE')[value].text;
                    case 'employee.demissionType':
                        return getEnumArray('DEMISSION_TYPE')[value].text;
                    default:
                        return '';
                }
            case 'object':
                const typeName = value.typeName?.split('.').pop();
                return typeName ? getDisplayValue(typeName, value.cdoId) : value.cdoId ?? value;
            default:
                return '';
        }
    };

    useEffect(() => {
        if ((showModal || !showOpenModalButton) && id) {
            getChangeLog();
        }
    }, [showModal, showOpenModalButton]);

    const columns = [
        {
            title: geti18nText('changelog.table.column.field'),
            dataIndex: 'property',
            key: 'property',
            render: (text: any) => (
                <strong>
                    {geti18nText('changelog.edit.' + type + '.' + text) !== ''
                        ? geti18nText('changelog.edit.' + type + '.' + text)
                        : text}
                </strong>
            ),
        },
        {
            title: geti18nText('changelog.table.column.change'),
            dataIndex: 'changes',
            key: 'changes',
            width: '80%',
            render: (changes: any, record: any) => {
                const oldValue = renderValue(record.old, record.propertyType, record.property);
                const newValue = renderValue(record.new, record.propertyType, record.property);

                return (
                    <>
                        <strong>
                            <span>{newValue !== undefined && oldValue && oldValue + ' '}</span>
                        </strong>
                        {newValue !== undefined
                            ? oldValue
                                ? geti18nText('changelog.changed.from.to')
                                : geti18nText('changelog.changed.to')
                            : geti18nText('changelog.deleted')}{' '}
                        <strong>
                            <span>{newValue !== undefined ? newValue : oldValue}</span>
                        </strong>
                    </>
                );
            },
        },
    ];

    const getHistoryData = () => {
        return (
            <div style={{ maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
                {loading ? (
                    <NySpinner />
                ) : changes ? (
                    <List
                        dataSource={changes}
                        renderItem={(data: any) => {
                            const dataSource = data.listChanges.map((change: any, index: any) => ({
                                key: index,
                                property: change.property,
                                propertyType: change.propertyType,
                                new: change.new,
                                old: change.old,
                                changes: `${change.new ? change.new.toString() : ''} ${
                                    change.old ? change.old.toString() : ''
                                }`,
                            }));
                            return (
                                <List.Item>
                                    <div style={{ width: '100%' }}>
                                        <Collapse>
                                            <Panel
                                                header={
                                                    <>
                                                        {geti18nText('changelog.author')} <strong>{data.author}</strong>{' '}
                                                        <strong>{data.date}</strong>
                                                    </>
                                                }
                                                key="1"
                                            >
                                                <Table
                                                    dataSource={dataSource}
                                                    columns={columns}
                                                    pagination={false}
                                                    size="small"
                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                ) : (
                    <Empty />
                )}
            </div>
        );
    };

    return (
        <>
            {showOpenModalButton ? (
                <div>
                    <span>{editHeader}</span>
                    <div style={{ float: 'right' }}>
                        <Button onClick={() => setShowModal(true)}>{geti18nText('changelog.table.title')}</Button>
                    </div>
                </div>
            ) : (
                <>{getHistoryData()}</>
            )}
            <Modal
                open={showModal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={geti18nText('app.default.button.ok')}
                onCancel={() => setShowModal(false)}
                title={geti18nText('changelog.table.title')}
                maskClosable={false}
            >
                {getHistoryData()}
            </Modal>
        </>
    );
};

export default CustomHistory;
