import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import EmployeeStatusIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import EmployeeStatusEdit from './edit';

const EmployeeStatusSearch = ({
    label = geti18nText('employee.edit.employeeStatus'),
    name = 'employeeStatus',
    required = false,
    disabled = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.EMPLOYEE_STATUS.SEARCH}
                map={{ id: 'id', label: 'name', text: 'name' }}
                searchBy="name"
                nyTestId="employeeStatus"
                SearchPopupComponent={<EmployeeStatusIndex />}
                AddNewModalComponent={EmployeeStatusEdit}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default EmployeeStatusSearch;
