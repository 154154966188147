import { CheckCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Tag, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getDateFormat } from '../../../../utils/Utils';
import FormalEducationEdit from './edit';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const FormalEducationIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = { /*  x: 2500, */ y: '65vh' },
    sortOrder,
    readonly = false,
}: any) => {
    const location = useLocation();
    const { id } = useParams<any>();
    const [refresh, setRefresh] = useState(0);

    const table = useTableSettings();

    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    useHelper('human/codebooks/formalEducation');

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
            { field: 'employee.id', condition: 'in', value: id },
        ];
    };

    const setDefaultSortOrder = {
        order: 'completionDate',
        orderType: 'desc',
    };

    const columns = [
        {
            title: geti18nText('formal.education.table.actual'),
            dataIndex: ['latest'],
            width: '90px',
            render: (latest: any, record: { latest: any }) => {
                if (record.latest) {
                    return (
                        <Tag color="blue">
                            <CheckCircleOutlined style={{ color: '#2b90e5', fontSize: '14px' }} />{' '}
                            {geti18nText('formal.education.table.actual')}
                        </Tag>
                    );
                }
            },
        },
        {
            title: geti18nText('educational.program.table.column.qualification.level'),
            dataIndex: ['qualificationLevel', 'name'],
            render: (qualificationLevel: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '300px' }}
                        title={record?.qualificationLevel?.name}
                    >
                        <div>
                            <p className={'ny-table-paragraph'}>{record?.qualificationLevel?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.QUALIFICATION_LEVEL.SEARCH,
                'qualificationLevel.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('formal.education.table.column.academic.title'),
            dataIndex: ['educationalTitle', 'name'],
            render: (educationalTitle: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '200px' }}
                        title={record?.educationalTitle?.name}
                    >
                        <div>
                            <p className={'ny-table-paragraph'}>{record?.educationalTitle?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.EDUCATIONAL_TITLE.SEARCH,
                'educationalTitle.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('educational.program.table.column.educational.institution'),
            dataIndex: ['educationalInstitution', 'name'],
            render: (educationalInstitution: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '300px' }}
                        title={record?.educationalInstitution?.name}
                    >
                        <div>
                            <p className={'ny-table-paragraph'}>{record?.educationalInstitution?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.EDUCATIONAL_INSTITUTION.SEARCH,
                'educationalInstitution.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('formal.education.table.column.educational.program'),
            dataIndex: ['educationalProgram', 'name'],
            render: (educationalProgram: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '300px' }}
                        title={record?.educationalProgram?.name}
                    >
                        <div>
                            <p className={'ny-table-paragraph'}>{record?.educationalProgram?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.EDUCATIONAL_PROGRAM.SEARCH,
                'educationalProgram.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                }
            ),
        },
        {
            title: geti18nText('formal.education.table.column.completion.date'),
            dataIndex: 'completionDate',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (completionDate: any, record: any) => {
                if (record.completionDate != null) {
                    return getDateFormat(record?.completionDate, 'DD.MM.YYYY');
                }
            },
        },
        /*     !!! ostaviti - zakomentirano prema tasku F13698 !!!    {
            title: geti18nText('formal.education.table.column.completion.country.title'),
            dataIndex: ['completionCountry', 'name'],
            render: (completionCountry: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '300px' }}
                        title={record?.completionCountry?.name}
                    >
                        <div className={'ny-table-paragraph-div-wide'}>
                            <p className={'ny-table-paragraph'}>{record?.completionCountry?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            //...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COUNTRY.SEARCH, 'completionCountry.id'),
        },
        {
            title: geti18nText('formal.education.table.column.completion.settlement'),
            dataIndex: ['completionSettlement', 'name'],
            render: (completionSettlement: any, record: any) => {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '300px' }}
                        title={record?.completionSettlement?.name}
                    >
                        <div className={'ny-table-paragraph-div-wide'}>
                            <p className={'ny-table-paragraph'}>{record?.completionSettlement?.name}</p>
                        </div>
                    </Tooltip>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            // ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.SETTLEMENT.SEARCH, 'completionSettlement.id'),
        },  !!! ostaviti - zakomentirano prema tasku F13698 !!!  */
        {
            title: geti18nText('professional.qualification.table.column.active'),
            dataIndex: 'active',
            width: '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '4px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '4px' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <NyDataTable
            nyId="human-formal-education-table"
            url={CONSTANTS_REQ.FORMAL_EDUCATION.LIST}
            addNewButtonText={geti18nText('formal.education.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={FormalEducationEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
            scroll={scroll}
            readonly={readonly}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            hideButtons={!canCreate()}
            shortcuts={true}
            fetchWhenChange={id || refresh}
        />
    );
};

export default FormalEducationIndex;
