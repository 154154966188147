import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import EducationProgramCatalogEdit from './edit';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { fileDownload, getEnumBooleanArray } from '../../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import RowAction from '../../../../../components/row-action';
import { Tooltip, Button } from 'antd';

const ProgramCatalogIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    useHelper('human/codebooks/educational-domain');

    const columns = [
        {
            title: geti18nText('programCatalog.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('programCatalog.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('programCatalog.table.column.location'),
            dataIndex: 'location',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('programCatalog.table.column.place'),
            dataIndex: 'place',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('programCatalog.table.column.country'),
            dataIndex: ['country', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COUNTRY.SEARCH, 'country.id'),
        },
        {
            title: geti18nText('programCatalog.table.column.completed'),
            dataIndex: 'completed',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { completed: any }) => {
                if (record.completed) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('programCatalog.table.column.applicationDueDate'),
            dataIndex: 'applicationDueDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.applicationDueDate) {
                    return moment(record.applicationDueDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('programCatalog.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('programCatalog.table.column.dateTo'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '8%',
            render: (text: any, record: any) => {
                if (record?.fileId) {
                    return (
                        <Tooltip title={geti18nText('employee.education.edit.file')}>
                            <Button
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    fileDownload(
                                        CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record?.fileId,
                                        null,
                                        geti18nText('employee.education.edit.file') + '_' + record?.file?.name
                                    );
                                }}
                            >
                                {geti18nText('app.default.download')}
                            </Button>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('programCatalog.table.column.active'),
            dataIndex: 'active',
            width: '6%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-education-program-catalog-table"
            url={CONSTANTS_REQ.PROGRAM_CATALOG.LIST}
            addNewButtonText={geti18nText('programCatalog.table.add')}
            headerTitle={geti18nText('menu.education.programCatalog')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={EducationProgramCatalogEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!HumanResourcesRights.canCreateEducationCodebooks()}
            shortcuts={true}
            exportCSV={HumanResourcesRights.canCreateEducationCodebooks()}
            CSVFileName={geti18nText('menu.education.programCatalog')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
        />
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('programCatalog.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('programCatalog.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('programCatalog.table.column.location'),
            dataIndex: 'location',
        },
        {
            title: geti18nText('programCatalog.table.column.place'),
            dataIndex: 'place',
        },
        {
            title: geti18nText('programCatalog.table.column.country'),
            dataIndex: ['country', 'name'],
        },
        {
            title: geti18nText('programCatalog.table.column.completed'),
            dataIndex: 'completed',
        },
        {
            title: geti18nText('programCatalog.table.column.applicationDueDate'),
            dataIndex: 'applicationDueDate',
        },
        {
            title: geti18nText('programCatalog.table.column.dateFrom'),
            dataIndex: 'dateFrom',
        },
        {
            title: geti18nText('programCatalog.table.column.dateTo'),
            dataIndex: 'dateTo',
        },
        {
            title: geti18nText('programCatalog.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            completed: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            applicationDueDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            dateFrom: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export default ProgramCatalogIndex;
