import { geti18nText, NyDataEdit, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AssetSimpleIndex from '../../../../../../components/asset-simple/assetSimple';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { customAssetExtraRenderOption, getSearchFormat } from '../../../../../../utils/Utils';
import { validateNegativeNumber } from '../../../../../../utils/Validation';
import AssetEdit from '../../../../../webshop/views/webshop-asset/asset/edit';
import FixedAssetEdit from '../../fixed-asset/edit';

const AssetAcquisitionItemsEdit = (props: any) => {
    const [form] = Form.useForm();
    const [editHeader, setEditHeader] = useState(geti18nText('asset.acquisition.item.table.header.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [assetAcquisitionId, setAssetAcquisitionId] = useState(null);

    useEffect(() => {
        setAssetAcquisitionId(props?.editProps?.assetAcquisitionId ?? null);
    }, [props]);

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    const canCreate = () => {
        return props?.editProps?.canCreate;
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.ordinal) {
            setEditHeader(
                geti18nText('asset.acquisition.item.table.header.edit', [
                    dataForm?.ordinal,
                    props?.editProps?.dataLength,
                ])
            );
        }
        dataForm.asset.label = dataForm?.asset?.assetModel?.name;

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('asset.acquisition.item.table.header.new'));
        setAssetAcquisitionId(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const changeAmount = () => {
        const quantity = form.getFieldValue(['quantity']) ? form.getFieldValue(['quantity']) : 0;
        const price = form.getFieldValue(['price']) ? form.getFieldValue(['price']) : 0;
        form.setFieldsValue({ amount: quantity * price });
    };

    const changePrice = () => {
        const quantity = form.getFieldValue(['quantity']) ? form.getFieldValue(['quantity']) : 0;
        const amount = form.getFieldValue(['amount']) ? form.getFieldValue(['amount']) : 0;
        if (quantity > 0) {
            form.setFieldsValue({ price: amount / quantity });
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ASSET_ACQUISITION_ITEM.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            hideSubmitButton={!canCreate()}
            paramsId={id}
            isModal={true}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.assetAcquisition = { id: assetAcquisitionId };
                values.asset = getSearchFormat(values.asset);
                return values;
            }}
            hideActivationButtons={true}
            onSaveAndGetID={() => {
                props?.setRefreshTable && props.setRefreshTable((prevValue: any) => prevValue + 1);
                props?.editProps?.setGetTotalsRefresh &&
                    props?.editProps?.setGetTotalsRefresh((prevValue: any) => prevValue + 1);
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="asset" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.asset')}
                                name="asset"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    style={{ width: '100%' }}
                                    url={CONSTANTS_REQ.ASSET.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'label',
                                        text: 'text',
                                        name: 'name',
                                        serialNumber: 'serialNumber',
                                        inventoryNumber: 'inventoryNumber',
                                        assetModel: 'assetModel',
                                        assetType: 'assetType',
                                        item: 'item',
                                        measureUnit: 'measureUnit',
                                        quantity: 'quantity',
                                    }}
                                    searchBy="assetModel.name || serialNumber || inventoryNumber"
                                    renderOption={customAssetExtraRenderOption}
                                    SearchPopupComponent={<AssetSimpleIndex isFixedAsset={true} />}
                                    AddNewModalComponent={props.editProps.isFixedAsset ? FixedAssetEdit : AssetEdit}
                                    AddNewModalComponentAddedData={{ isModal: true }}
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    autoFocus
                                    order="assetModel.name"
                                    customListWidth={'1200px'}
                                    mustGetPopupContainer={false}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.quantity')}
                                name="quantity"
                                initialValue={1}
                                rules={[
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    ref={focusInput}
                                    disabled={!canCreate()}
                                    style={{ width: '100%' }}
                                    onChange={changeAmount}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.price')}
                                name="price"
                                initialValue={0}
                                rules={[
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    disabled={!canCreate()}
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changeAmount}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.amount')}
                                name="amount"
                                initialValue={0}
                                rules={[
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    disabled={!canCreate()}
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changePrice}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AssetAcquisitionItemsEdit;
