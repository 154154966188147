import { BookOutlined, FileSearchOutlined, PrinterOutlined, UserOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySession, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Avatar, Button, Card, Col, Divider, Empty, Form, Modal, Row, Steps, StepsProps, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import RowAction from '../../../../components/row-action';
import TravelWarrantSummaryIndex from '../../../../components/travel-warrant-summary';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    getCardTransportationIcon,
    getDateFormat,
    getFileList,
    getOrdinal,
    getTravelWarrantIcon,
    getTravelWarrantOverviewCardColor,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import TravelWarrantPersonalWizardEdit from '../travel-warrant/wizard/wizard-edit';
import TravelWarrantPersonalWizardNew from '../travel-warrant/wizard/wizard-new';

const { Step } = Steps;

const UserView = ({ refresh, setRefresh, setDefaultFilterValueMyList, resolveDestination }: any) => {
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [cardsData, setCardsData] = useState<any>(undefined);
    const [summaryModalVisible, setSummaryModalVisible] = useState(false);
    const [travelEmployee, setTravelEmployee] = useState<any>();
    const [globalForm, setGlobalForm] = useState<any>();
    const [isPlane, setIsPlane] = useState<any>(false);
    const [files, setFiles] = useState<any>([]);
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(travelWarrantStatusEnum.DRAFT);
    const [warrantId, setWarrantId] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const [destinationJson, setDestinationJson] = useState(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [travelWarrantId, setTravelWarrantId] = useState<any>(null);
    const [travelVehicle, setTravelVehicle] = useState(null);
    const [isPersonalVehicle, setIsPersonalVehicle] = useState(false);
    const [isPersonalVehicle2, setIsPersonalVehicle2] = useState(false);
    const [isPersonal, setIsPersonal] = useState(false);
    const [formSummary] = Form.useForm();
    const [currency, setCurrency] = useState<any>(undefined);
    const [person, setPerson] = useState<any>({});
    const [reportForm] = Form.useForm();
    const [report, setReport] = useState(null);
    const [ordinal, setOrdinal] = useState(null);
    const [warrantDate, setWarrantDate] = useState<any>('');
    const [personalVehicleFinal, setPersonalVehicleFinal] = useState<any>(false);
    const [loading, setLoading] = useState(true);
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const customDot: StepsProps['progressDot'] = (dot, { status, index }) => <>{dot}</>;
    const history = useHistory();
    const [dataFormCopy, setDataFormCopy] = useState(null);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [wizard, setWizard] = useState<boolean>(false);
    const [recordId, setRecordId] = useState<any>(null);

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' },
            { field: 'setting_key', condition: 'equals', value: 'TRAVEL_WARRANT_REPORT_TEXT_TEMPLATE' },
        ];
    };

    useEffect(() => {
        setPersonalVehicleFinal(isPersonal || isPersonalVehicle || isPersonalVehicle2);
    }, [isPersonalVehicle]);

    useEffect(() => {
        getCardData();
    }, []);

    useEffect(() => {
        getCardData();
    }, [refresh]);

    useEffect(() => {
        cardsData && generateCards();
    }, [cardsData]);

    useEffect(() => {
        if (warrantId) {
            getModalFiles(warrantId);
        }
    }, [refreshFiles]);

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    const element = result.data[0];
                    setReport(
                        element.value !== null && element.value !== undefined ? element.value : element.defaultValue
                    );
                    if (!reportForm.getFieldValue('report')) {
                        reportForm.setFieldsValue({
                            report:
                                element.value !== null && element.value !== undefined
                                    ? element.value
                                    : element.defaultValue,
                        });
                    }
                }
            }
        });
    };

    const openDetails = () => {
        history.push('/travel-warrant/travelWarrant/' + warrantId);
    };

    const handleCancel = () => {
        setSummaryModalVisible(false);
        setCurrency(undefined);
        setTravelWarrantStatus(travelWarrantStatusEnum.DRAFT);
        setIsPlane(false);
        setFiles([]);
        setGlobalForm(undefined);
    };

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '0px!important',
        padding: '5px 10px',
    };

    const compare = (a: any, b: any) => {
        if (a.status < b.status) {
            return -1;
        }
        if (a.status > b.status) {
            return 1;
        }
        return 0;
    };

    const getCardData = () => {
        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValueMyList()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.MY_LIST, {
            search: stringUri,
            max: 5,
            order: 'warrantDate',
            orderType: 'desc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setCardsData(result.data.content.sort(compare));
                }
            }
            setLoading(false);
        });
    };

    const resolveStartDestination = (card: any) => {
        let shortName =
            card &&
            card.destinationJson &&
            JSON.parse(card.destinationJson) &&
            JSON.parse(card.destinationJson) !== undefined &&
            JSON.parse(card.destinationJson)[0] &&
            JSON.parse(card.destinationJson)[0]['relationJson'] &&
            JSON.parse(JSON.parse(card.destinationJson)[0]['relationJson']) &&
            JSON.parse(JSON.parse(card.destinationJson)[0]['relationJson'])[0] &&
            JSON.parse(JSON.parse(card.destinationJson)[0]['relationJson'])[0]['shortName'] &&
            JSON.parse(JSON.parse(card.destinationJson)[0]['relationJson'])[0]['shortName'].split(/[,.-]/).at(0);
        if (!shortName || shortName == undefined || shortName == null || shortName == '') {
            shortName =
                card &&
                card.destinationJson &&
                JSON.parse(card.destinationJson) &&
                JSON.parse(card.destinationJson) !== undefined &&
                JSON.parse(card.destinationJson)[0] &&
                JSON.parse(card.destinationJson)[0][0] &&
                JSON.parse(card.destinationJson)[0][0]['shortName'];
        }
        return shortName ? shortName : '';
    };

    const getModalFiles = (cardId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'travel_warrant_id', condition: 'equals', value: cardId },
                ])
            ),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
    };

    const getModalData = (cardId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + cardId).then((result: any) => {
            if (result && result.status === RESPONSE.OK && result.data) {
                setGlobalForm(result.data);
                setTravelWarrantStatus(result.data.status);
                setTravelEmployee(result.data.employee);
                if (
                    result.data.advancePaymentCurrency &&
                    result.data.advancePaymentCurrency.id &&
                    result.data.advancePaymentCurrency.isoCode
                ) {
                    setCurrency({
                        id: result.data.advancePaymentCurrency.id,
                        text: result.data.advancePaymentCurrency.isoCode,
                    });
                }
                setIsPlane(
                    result.data?.travelVehicle?.vehicleType &&
                        (result.data.travelVehicle.vehicleType === 3 ||
                            result.data.travelVehicle.vehicleType === 4 ||
                            result.data.travelVehicle.vehicleType === 6)
                );
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                }
                getModalFiles(cardId);
            }
        });
    };

    const setValues = (data: any) => {
        setTravelWarrantStatus(data.status);
        setTravelEmployee(data.employee);

        if (data.ordinal) {
            setOrdinal(data.ordinal);
        }

        if (data.warrantDate) {
            setWarrantDate(moment(data.warrantDate).format('YYYY'));
        }

        if (data.advancePaymentCurrency && data.advancePaymentCurrency.id && data.advancePaymentCurrency.isoCode) {
            setCurrency({ id: data.advancePaymentCurrency.id, text: data.advancePaymentCurrency.isoCode });
        }

        if (data.startDate) {
            data.startDate = setDateFormat(data.startDate, 'yyyy-MM-DD HH:mm');
            setStartDate(data.startDate);
        }
        if (data.endDate) {
            data.endDate = setDateFormat(data.endDate, 'yyyy-MM-DD HH:mm');
            setEndDate(data.endDate);
        }
        if (data.travelVehicle) {
            setIsPersonalVehicle(data.travelVehicle.vehicleType && data.travelVehicle.vehicleType === 1);

            setIsPlane(
                data.travelVehicle.vehicleType &&
                    (data.travelVehicle.vehicleType === 3 ||
                        data.travelVehicle.vehicleType === 4 ||
                        data.travelVehicle.vehicleType === 6)
            );
            data.travelVehicle = setSearchFormat(data.travelVehicle, 'name', 'name');

            setTravelVehicle(data.travelVehicle);
        } else {
            setIsPlane(false);
        }
        if (data.travelVehicle2) {
            setIsPersonalVehicle2(data.travelVehicle2.vehicleType && data.travelVehicle2.vehicleType === 1);
            data.travelVehicle2 = setSearchFormat(data.travelVehicle2, 'name', 'name');
        } else {
            setIsPersonalVehicle2(false);
        }
        if (data.destinationJson) {
            setDestinationJson(JSON.parse(data.destinationJson));
        }

        if (data && data.content) {
            setFiles(getFileList(data.content));
        }
        getModalFiles(data.id);
        setIsPersonal(isPersonalVehicle || isPersonalVehicle2);
        setDataFormCopy(data);
    };

    function resetDataForm() {
        setValues(dataFormCopy);
    }

    const getModalDataComplete = async (recordId: any) => {
        if (recordId) {
            setTravelWarrantId(recordId);
            return await NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + recordId).then(
                (result: any) => {
                    if (result && result.status === RESPONSE.OK && result.data) {
                        setValues(result.data);
                    }
                }
            );
        }
    };

    const changeStatus = (recordId: any, status: any, info: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            mail: false,
            status: status,
            info: info,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh: number) => refresh + 1);
                if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                    okNotification(geti18nText('travelWarrantPersonal.sendToApproval.ok'));
                } else if (status == travelWarrantStatusEnum.REJECTED) {
                    okNotification(geti18nText('travelWarrantPersonal.cancelTrip.ok'));
                }
            } else {
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const showTravelWizardComplete = async (recordId: any) => {
        const res = await getModalDataComplete(recordId);
        setVisible(true);
        fetchAppSettings();
    };

    const generateActions = (card: any) => {
        let arr = [];
        arr.push(
            <RowAction
                component={'TravelWarrantPersonalPrint'}
                record={card}
                travelWarrantCardButton={true}
                printTravelWarrant={true}
            ></RowAction>
        );

        if (
            Object.values([
                travelWarrantStatusEnum.DRAFT,
                travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
                travelWarrantStatusEnum.APPROVED,
                travelWarrantStatusEnum.REJECTED,
                travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION,
                travelWarrantStatusEnum.ORGANIZED,
                travelWarrantStatusEnum.REPORT_FIX_NEEDED,
            ]).includes(card.status)
        ) {
            arr.push(
                <RowAction
                    component={'TravelWarrantPersonalAction'}
                    record={card}
                    changeStatus={changeStatus}
                    travelWarrantCardButton={true}
                    showTravelWizard={showTravelWizardComplete}
                    setWizard={setWizard}
                    setWarrantID={setRecordId}
                    fetchAccessRights
                ></RowAction>
            );
        }

        return arr;
    };

    const generateCards = () => {
        let result;

        result = (
            <Col span={24}>
                <Row justify="start">
                    {cardsData.map((card: any) => {
                        return (
                            <Col span={4} className={'travel-warrant-cards-widgets-mid'}>
                                <Card
                                    headStyle={{
                                        borderBottom: `2px solid ${getTravelWarrantOverviewCardColor(card.status)}`,
                                        background: `${getTravelWarrantOverviewCardColor(card.status)}11`,
                                    }}
                                    className="ny-travel-warrant-card"
                                    title={[
                                        getTravelWarrantIcon(card.status, '20px'),
                                        '   ',
                                        geti18nText('travelWarrant.edit.title.short'),
                                        card.ordinal && card.status > travelWarrantStatusEnum.DRAFT
                                            ? ` - ${getOrdinal(card)}`
                                            : '',
                                    ]}
                                    bodyStyle={{
                                        height: 'calc(100% - 55px)',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        padding: '4px 5px 0px 5px',
                                        marginTop: '1px',
                                    }}
                                    extra={
                                        <Tooltip placement="bottom" title={geti18nText('menu.travel.warrant.details')}>
                                            <FileSearchOutlined
                                                className="extraFile"
                                                style={{
                                                    fontSize: '22px',
                                                    marginRight: '0.6rem',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setWarrantId(card.id);
                                                    getModalData(card.id);
                                                    setSummaryModalVisible(true);
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                    type="inner"
                                    actions={generateActions(card)}
                                >
                                    <Card.Grid hoverable={false} style={gridStyle} className="statistic-card-items">
                                        <div style={{ display: 'flex' }}>
                                            <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                <Tooltip
                                                    placement="top"
                                                    title={geti18nText('travelWarrant.table.column.employee')}
                                                >
                                                    {card.person && card.person.fileId ? (
                                                        <Avatar
                                                            size={24}
                                                            src={
                                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                '/' +
                                                                card.person.fileId +
                                                                '?tenant=' +
                                                                NySession.getUser().tenantId
                                                            }
                                                        />
                                                    ) : (
                                                        <UserOutlined style={{ fontSize: '22px' }} />
                                                    )}
                                                </Tooltip>
                                            </b>
                                            <p className={'overview-cards-paragraph'} style={{ fontSize: '14px' }}>
                                                {' '}
                                                {card && ` ${card.person.firstName} ${card.person.lastName}`}
                                            </p>
                                        </div>
                                        {card && card.travelVehicle && (
                                            <div style={{ display: 'flex', margin: '0px' }}>
                                                <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                    <Tooltip
                                                        placement="top"
                                                        title={geti18nText('travelWarrant.table.column.travelVehicle ')}
                                                    >
                                                        {getCardTransportationIcon(card.vehicleType)}
                                                    </Tooltip>
                                                </b>
                                                <Tooltip
                                                    placement="topLeft"
                                                    overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                    title={card && card.travelVehicle && card.travelVehicle.name}
                                                >
                                                    <div className={'overview-cards-paragraph-div'}>
                                                        <p
                                                            className={'overview-cards-paragraph'}
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            {card && card.travelVehicle && card.travelVehicle.name}
                                                        </p>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )}
                                        <div
                                            className={'overview-cards-paragraph-div'}
                                            style={{ display: 'flex', margin: '0px' }}
                                        >
                                            <b style={{ font: 'bold', paddingRight: '4px' }}>
                                                <Tooltip
                                                    placement="top"
                                                    title={geti18nText('travelWarrant.edit.purposeInfo')}
                                                >
                                                    <BookOutlined style={{ fontSize: '22px' }} />
                                                </Tooltip>
                                            </b>
                                            <Tooltip
                                                placement="topLeft"
                                                overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                title={card && card.purposeInfo}
                                            >
                                                <div className={'overview-cards-paragraph-div'}>
                                                    <p
                                                        className={'overview-cards-paragraph'}
                                                        style={{ fontSize: '14px' }}
                                                    >
                                                        {card && card.purposeInfo}
                                                    </p>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Card.Grid>
                                    <Card.Grid
                                        hoverable={false}
                                        style={gridStyle}
                                        className="statistic-card-items-path"
                                    >
                                        <Steps current={2} progressDot={customDot}>
                                            <Step
                                                className={'overview-cards-step'}
                                                title={
                                                    <Tooltip
                                                        placement="topLeft"
                                                        overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                        title={resolveDestination(card)}
                                                    >
                                                        <div className={'overview-cards-paragraph-div'}>
                                                            <p
                                                                className={'overview-cards-step-paragraph'}
                                                                style={{ fontSize: '14px' }}
                                                            >
                                                                {resolveDestination(card)}
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                }
                                                description={getDateFormat(card.startDate, 'DD.MM.YYYY')}
                                            ></Step>
                                            {
                                                <Step
                                                    className={'overview-cards-step'}
                                                    title={
                                                        <Tooltip
                                                            placement="topLeft"
                                                            overlayStyle={{ fontSize: '14px', maxWidth: '300px' }}
                                                            title={resolveDestination(card, true)}
                                                        >
                                                            <div className={'overview-cards-paragraph-div'}>
                                                                <p
                                                                    className={'overview-cards-step-paragraph'}
                                                                    style={{ fontSize: '14px' }}
                                                                >
                                                                    {resolveDestination(card, true)}
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    description={getDateFormat(card.endDate, 'DD.MM.YYYY')}
                                                >
                                                    {' '}
                                                </Step>
                                            }
                                        </Steps>
                                    </Card.Grid>
                                    <Card.Grid hoverable={false} style={gridStyle} className="statistic-card-buttons">
                                        <Col span={20}>
                                            <Row justify="space-around">
                                                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}></div>
                                            </Row>
                                        </Col>
                                    </Card.Grid>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Col>
        );
        return result;
    };

    const iconStyle: any = {
        fontSize: '16px',
        paddingRight: '10px',
        paddingTop: '2px',
        float: 'left',
    };

    const getCustomFooterContent = (
        <div>
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button key={'print'} icon={<PrinterOutlined />}>
                            {geti18nText('travelWarrant.action.pdf.short')}
                        </Button>
                    );
                }}
                onBeforeGetContent={() => {
                    setPrintPdf(true);
                    return Promise.resolve();
                }}
                onAfterPrint={() => {
                    setPrintPdf(false);
                }}
                content={() => componentRef.current}
                pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                bodyClass="print-style"
            />
            <Button type="primary" onClick={openDetails}>
                {geti18nText('travelWarrant.overview.details')}
            </Button>
            <Button onClick={handleCancel}>{geti18nText('app.default.button.ok')}</Button>
        </div>
    );

    return (
        <>
            <Divider orientation="left" style={{ fontWeight: 'normal', marginTop: '1.5rem', marginBottom: '0rem' }}>
                {geti18nText('travelWarrant.status.title.0')}
            </Divider>
            {cardsData && !loading ? generateCards() : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {loading && <NySpinner />}
            {summaryModalVisible && (
                <Modal
                    visible={summaryModalVisible}
                    maskClosable={false}
                    title={
                        <b>
                            {geti18nText('menu.travel.warrant.details')}
                            {' - '}
                            {getOrdinal(globalForm)}
                        </b>
                    }
                    width={900}
                    onOk={handleCancel}
                    onCancel={handleCancel}
                    footer={getCustomFooterContent}
                >
                    <TravelWarrantSummaryIndex
                        componentRefProp={componentRef}
                        printPdfProp={printPdf}
                        setPrintPdfProp={setPrintPdf}
                        employeeTravelWarrant={travelEmployee}
                        globalForm={globalForm}
                        formSummary={formSummary}
                        isPlane={isPlane}
                        currency={currency}
                        files={files}
                        setFiles={setFiles}
                        printPdfHeader={geti18nText('menu.travel.warrant.details') + ' - ' + getOrdinal(globalForm)}
                        showPrintButton={false}
                        refId={warrantId}
                        id={warrantId}
                        setRefreshFiles={setRefreshFiles}
                        travelWarrantStatus={travelWarrantStatus}
                    ></TravelWarrantSummaryIndex>
                </Modal>
            )}
            {wizard && (
                <TravelWarrantPersonalWizardNew
                    visible={wizard}
                    setVisible={setWizard}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    dataForm={recordId}
                />
            )}
            {visible && (
                <TravelWarrantPersonalWizardEdit
                    ordinal={ordinal}
                    warrantDate={warrantDate}
                    visible={visible}
                    setVisible={setVisible}
                    travelVehicle={travelVehicle}
                    startDate={startDate}
                    endDate={endDate}
                    files={files}
                    setFiles={setFiles}
                    person={person}
                    travelWarrantStatus={travelWarrantStatus}
                    dataForm={travelWarrantId}
                    report={report}
                    isPersonalVehicle={isPersonal || isPersonalVehicle || isPersonalVehicle2 || personalVehicleFinal}
                    destinationJson={destinationJson}
                    isPlane={isPlane}
                    setDestinationJson={setDestinationJson}
                    resetDataForm={resetDataForm}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    isOverview={true}
                    setSummaryModalVisible={setSummaryModalVisible}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            )}
        </>
    );
};

export default UserView;
