import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import ReserveRankIndex from '.';
import ReserveRankEdit from './edit';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const ReserveRankSearch = ({
    label = geti18nText('employee.mobilization.edit.reserveRank'),
    name = 'reserveRank',
    canCreate = false,
    required = false,
    disabled = false,
    autoFocus = false,
    onChange,
    mustGetPopupContainer = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: required,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.RESERVE_RANK.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                nyTestId="reserveRank"
                SearchPopupComponent={<ReserveRankIndex />}
                AddNewModalComponent={canCreate ? ReserveRankEdit : null}
                autoFocus={autoFocus}
                disabled={disabled}
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                style={{ width: '100%' }}
                onChange={onChange}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default ReserveRankSearch;
