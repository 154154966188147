import { geti18nText } from '@nybble/nyreact';
import { Form, Select } from 'antd';
import { useState } from 'react';
import { getEnumArray } from '../../../../utils/Utils';
import CodebookInitializationEdit from './edit';

const CodebookInitializationIndex = () => {
    const [dataType, setDataType] = useState<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [formSelect] = Form.useForm();

    const options = getEnumArray('CODEBOOK_DATA_TYPE', 'label', 'value');

    function onModalClose() {
        formSelect.resetFields();
    }

    return (
        <>
            <Form form={formSelect}>
                <Form.Item label={geti18nText('codebook.data.type.select')} name={'type'}>
                    <Select
                        options={options}
                        onSelect={(val) => {
                            setDataType(val);
                            setVisible(true);
                        }}
                        style={{ width: '300px' }}
                    ></Select>
                </Form.Item>
            </Form>
            {visible && (
                <CodebookInitializationEdit
                    dataType={dataType}
                    visible={visible}
                    setVisible={setVisible}
                    onModalClose={onModalClose}
                />
            )}
        </>
    );
};

export default CodebookInitializationIndex;
