import HumanCodebooksIndex from '../../../../../components/human-codebooks';
import useEnum from '../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const EmployeeEvaluationCompetencyEvaluation = () => {
    const canCreate = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    return (
        <HumanCodebooksIndex
            codebooksType={enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION}
            disabled={!canCreate()}
            canExportCSV
        />
    );
};

export default EmployeeEvaluationCompetencyEvaluation;
