import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getEnumArray } from '../../../../utils/Utils';
import TravelVehicleEdit from './edit';

const TravelVehicleIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    defaultFilterValue,
    defaultSortOrder = { order: 'sort', orderType: 'asc' },
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('travel_order/codebooks/travelVehicle');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelVehicle();
    };

    const canExportCSV = () => {
        return TravelWarrantRights.canCsvExportTravelWarrantCodebooks();
    };

    const defaultFilter = () => {
        return defaultFilterValue ? defaultFilterValue : table.setDefaultFilterValue();
    };

    const columns = [
        {
            title: geti18nText('travelVehicle.table.column.id'),
            dataIndex: 'id',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelVehicle.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return (
                        <>
                            {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                            {record.employee.employmentRecordId && ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelVehicle.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelVehicle.table.column.model'),
            dataIndex: 'model',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelVehicle.table.column.licencePlate'),
            dataIndex: 'licencePlate',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelVehicle.table.column.sort'),
            dataIndex: 'sort',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelVehicle.table.column.vehicleType'),
            dataIndex: 'vehicleType',
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArray('TRAVEL_VEHICLE_TYPE')),
            render: (vehicleType: any) => {
                return geti18nText('app.enum.TRAVEL_VEHICLE_TYPE.' + vehicleType);
            },
        },
        {
            title: geti18nText('travelVehicle.table.column.travelFuelType'),
            dataIndex: ['travelFuelType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.TRAVEL_FUEL_TYPE.SEARCH,
                'travelFuelType.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
        },
        {
            title: geti18nText('travelVehicle.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
            defaultFilteredValue: defaultFilter,
        },
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-vehicle-items-table"
            url={CONSTANTS_REQ.TRAVEL_VEHICLE.LIST}
            addNewButtonText={geti18nText('travelVehicle.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={TravelVehicleEdit}
            editProps={{ scroll: { y: 400, x: 500 }, sortOrder: sortOrder }}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={defaultFilter}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? defaultSortOrder ? defaultSortOrder : table.setDefaultSortOrder}
            shortcuts={true}
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.travelVehicle')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.travelVehicle')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            isPrivate: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default TravelVehicleIndex;
