import { NySession } from '@nybble/nyreact';
export const InventoryRights = (function () {
    return {
        //settings
        canCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_INVENTORY_SETTINGS']);
        },
        //codebooks
        canCreateCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_INVENTORY_CODEBOOKS_EDIT']);
        },
        canCsvExportCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_INVENTORY_CODEBOOKS_EXPORT']);
        },
        //order
        canViewOrder: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_INVENTORY_ORDER_VIEW']);
        },
        canCreateOrder: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_INVENTORY_ORDER_EDIT', 'ROLE_INVENTORY_REQUEST_EDIT']);
        },
        //release
        canCreateRelease: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_INVENTORY_RELEASE_EDIT', 'ROLE_SERVICES_RELEASE_EDIT']);
        },
        ///////////////////
        //menu
        canCreateSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_SETTINGS'];
        },
        canViewInventoryShopMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_INVENTORY_REQUEST_MENU',
                'ROLE_INVENTORY_ORDER_MENU',
                'ROLE_INVENTORY_RELEASE_MENU',
            ];
        },
        canViewOrderMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_ORDER_VIEW'];
        },
        canViewRequestMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_REQUEST_MENU'];
        },
        canViewOrderViewMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_ORDER_MENU'];
        },
        canViewReleaseMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_RELEASE_MENU'];
        },
        canViewInventoryCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_CODEBOOKS_MENU'];
        },
        //routes
        canViewInventoryRequestRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_REQUEST_VIEW'];
        },
        canViewCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_CODEBOOKS_VIEW'];
        },
        canViewReleaseRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_INVENTORY_RELEASE_VIEW'];
        },
    };
})();
