import { NySession } from '@nybble/nyreact';
export const WarehouseBookkeepingRights = (function () {
    return {
        //codebooks
        canCreateWarehouseCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_WAREHOUSE_CODEBOOKS_EDIT']);
        },
        canCsvExportWarehouseCodebook: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_WAREHOUSE_CODEBOOKS_EXPORT']);
        },
        //warehouse item
        canCreateWarehouseItem: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_WAREHOUSE_ITEM_EDIT']);
        },
        canCsvExportWarehouseItem: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_WAREHOUSE_ITEM_EXPORT']);
        },
        //warehouse receipt
        canCreateWarehouseReceipt: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_WAREHOUSE_RECEIPT_EDIT']);
        },
        canCsvExportWarehouseReceipt: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_WAREHOUSE_RECEIPT_EXPORT']);
        },
        canCreateWarehouseReceiptAssetAcquisition: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ASSET_CODEBOOKS_EDIT', 'ASSET_ACQUISITION_EDIT']);
        },
        ///////////////////
        //menu
        canViewCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_CODEBOOKS_MENU'];
        },
        canViewReceiptMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_RECEIPT_MENU'];
        },
        canViewItemMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_ITEM_MENU'];
        },
        canViewWarehouseOverviewModule: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_WAREHOUSE_CODEBOOKS_MENU',
                'ROLE_WAREHOUSE_ITEM_MENU',
                'ROLE_WAREHOUSE_RECEIPT_MENU',
            ];
        },
        //routes
        canViewCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_CODEBOOKS_VIEW'];
        },
        canViewReceiptRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_RECEIPT_VIEW'];
        },
        canViewItemRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_ITEM_VIEW'];
        },
        canViewWarehouseRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_WAREHOUSE_CODEBOOKS_VIEW'];
        },
    };
})();
