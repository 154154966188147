import {
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    PlusCircleOutlined,
    ProfileOutlined,
    SaveTwoTone,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Divider, Form, Input, Row, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import { ServicesRights } from '../../../../../rights/servicesRights';
import { RootState } from '../../../../../rootReducer';
import { refreshItemList } from '../../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import NyFilesUpload from '../../../../../utils/ny-file-upload-custom';
import {
    customEmployeeRenderOption,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    userIsNotificationRecipient,
} from '../../../../../utils/Utils';
import EmployeeIndex from '../../../../human/views/employee';
import RoomSearch from '../../../../human/views/room/search';
import WarehouseIndex from '../../../../warehouse/views/warehouse';
import EditableCell from './EditableCell';
import InventoryReleaseTable from './InventoryReleaseTable';
import MetadataModal from './MetadataModal';

const ServicesOrderEdit = (props: any) => {
    const dispatch = useDispatch();
    const [editHeader, setEditHeader] = useState(geti18nText('webshopItem.edit.new'));
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [metadataModalVisible, setMetadataModalVisible] = useState(false);
    const [metadata, setMetadata] = useState([]);
    const [itemTitle, setItemTitle] = useState<any>({});
    const [items, setItems] = useState<any>([]);
    const [status, setStatus] = useState<any>(undefined);
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;
    const [disabelMetadata, setDisableMetadata] = useState<any>(false);
    const [servicesOrderId, setServicesOrderId] = useState<any>(undefined);
    const [showDecision, setShowDecision] = useState(true);
    const [orderViewId, setOrderViewId] = useState<any>(
        props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null
    );

    const { employee } = useSelector((state: RootState) => state.employee);
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [warehouse, setWarehouse] = useState<any>(undefined);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const canCreate = () => {
        return ServicesRights.canCreateOrder() || userIsNotificationRecipient(employee, 9);
    };

    useEffect(() => {
        getWarehouse(orderViewId);
    }, [orderViewId]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getWarehouse = (orderViewId: any) => {
        if (orderViewId > 0) {
            setWarehouse(null);
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.WAREHOUSE.LIST_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString()),
                {
                    search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                }
            ).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.content?.length > 0 && result?.data.content[0]) {
                    const warehouseValue: any = setSearchFormat(result.data.content[0], 'name', 'name', 'code');
                    setWarehouse(warehouseValue);
                    form.setFieldsValue({ warehouse: warehouseValue });
                }
            });
        }
    };

    useEffect(() => {
        if (props.value == undefined) {
            if (employee && employee.id !== undefined && servicesOrderId == null) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + employee.id, undefined).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            if (result.data && result.data) {
                                form.setFieldsValue({
                                    employee: getEmployeeSelectOption(result.data),
                                    room: result.data.room,
                                });
                            }
                        }
                    }
                );
            }
        }
    }, [props.value]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setEditHeader(
                geti18nText('menu.inventory.order.single') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
        } else {
            setEditHeader(geti18nText('menu.inventory.order.single'));
        }

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.servicesOrderItems) {
            setItems(dataForm.servicesOrderItems);
        }

        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }

        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }

        setShowDecision(false);
        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('INVENTORY_ORDER_STATUS_TYPE', dataForm.status);
            if (dataForm.status.id == 1 && canCreate()) {
                setShowDecision(true);
            }
        }

        if (dataForm.orderView && dataForm.orderView.id) {
            setOrderViewId(dataForm.orderView.id);
        }
        if (dataForm.warehouse) {
            setWarehouse(setSearchFormat(dataForm.warehouse, 'name', 'name', 'code'));
        }

        setServicesOrderId(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        assetForm.resetFields();
        setItems([]);
        setNote(undefined);
        setEditHeader(geti18nText('webshopItem.edit.new'));
        setStatus(undefined);
        setServicesOrderId(undefined);
        setEditingKey('');
        setServicesOrderId(undefined);
        setShowDecision(false);
        setOrderViewId(null);
        setWarehouse(null);
    };

    const onModalOpen = () => {
        setShowDecision(false);
        setOrderViewId(props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const fetchMetadata = (id: any, isNew: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'itemGroup.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.METADATA, {
            max: 100,
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setMetadata(result.data.content);
                    if (isNew) {
                        setMetadataModalVisible(true);
                    }
                } else {
                    setMetadata([]);
                }
            }
        });
    };

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (record.item == undefined) {
            deleteRow(record.id);
        }
        setAddItemInProcess(false);
    };

    const saveRow = async (id: any) => {
        try {
            const row = await assetForm.validateFields();
            row.id = id;
            const newData = [...items];
            const index = newData.findIndex((item) => id === item.id);

            if (row && row.item) {
                let itemId = row.item.id;
                let itemData: any = await getItemData(itemId);
                if (itemData) {
                    row.item = itemData;
                }
            }

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }

            if (row != undefined && row.item && row.item.itemGroup != undefined) {
                fetchMetadata(row.item.itemGroup.id, true);
                setItemTitle(row);
            }
            setAddItemInProcess(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    async function getItemData(id: any) {
        const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + id, undefined);
        if (result.status === RESPONSE.OK) {
            if (result.data) {
                return result.data;
            }
        }
        return undefined;
    }

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => saveRow(record.id)}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <NyModalConfirm
                        title={geti18nText('app.default.cancel.confirm')}
                        onConfirm={() => {
                            cancel(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                            <Button type="link">
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {(isCreate || !status || status === 2 || status === 3) && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" onClick={() => edit(record, false)} disabled={editingKey !== ''}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record.id);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={editingKey !== ''}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        ...[
            {
                title: geti18nText('webshopItem.edit.code'),
                dataIndex: ['item', 'code'],
                editable: false,
                width: '250px',
            },
            {
                title: geti18nText('webshopItem.edit.name'),
                dataIndex: 'item',
                editable: !status,
                inputType: 'item',
                width: '350px',
                render: (text: any, record: any) => {
                    if (record.item != undefined && record.item.name != undefined) {
                        return record.item.name;
                    }
                },
            },
            {
                title: geti18nText('webshopItem.edit.measureUnit'),
                dataIndex: ['item', 'measureUnit', 'abbreviation'],
                editable: false,
                width: '250px',
            },
            {
                title: geti18nText('webshopItem.edit.quantity'),
                dataIndex: 'quantity',
                editable: !status,
                inputType: 'quantity',
                width: '250px',
            },
        ],
        ...(!status || status === 2
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : [
                  {
                      key: 'metadata',
                      render: (text: any, record: any) => {
                          return (
                              <Button type="link" onClick={() => edit(record, true)}>
                                  <ProfileOutlined
                                      style={{
                                          fontSize: '20px',
                                      }}
                                  />
                              </Button>
                          );
                      },
                  },
              ]),
    ];

    const edit = (record: any, disableMeta: any) => {
        setAddItemInProcess(true);
        setDisableMetadata(disableMeta);
        if (record != undefined && record.item && record.item.itemGroup != undefined) {
            fetchMetadata(record.item.itemGroup.id, false);
            setItemTitle(record);
            setMetadataModalVisible(true);
        } else {
            let editForm = undefined;
            editForm = { ...record };
            assetForm.setFieldsValue(editForm);
            setEditingKey(record.id);
        }
    };

    const addNewRowAsset = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            item: undefined,
            quantity: 1,
        };
        setItems([...items, newData]);
        edit(newData, false);
    };

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    warehouse: warehouse,
                    form: assetForm,
                };
            },
        };
    });

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px' }}>
                <NyNoteModal disabled={status >= 6} note={note} />
            </div>
            {!isCreate && (
                <div style={{ float: 'left', paddingLeft: '35px' }}>
                    <NyReportButton
                        url={CONSTANTS_REQ.REPORT.SERVICES_ORDER}
                        customParms={{ servicesOrderId: servicesOrderId, status: status }}
                        subreportType={'SERVICES_ORDER'}
                        buttoni18nText={'report.servicesOrder.print'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.SERVICES_ORDER)}
                        allowExportTypeChange={true}
                    />
                </div>
            )}

            {showDecision == true && canCreate() && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('inventoryOrder.button.popconfirm.reject')}
                        onConfirm={() => {
                            saveDecision(2);
                        }}
                    >
                        <Button key="submit" style={{ color: 'white', backgroundColor: 'red' }}>
                            {geti18nText('inventoryOrder.button.reject')}
                        </Button>
                    </NyModalConfirm>
                    <NyModalConfirm
                        title={geti18nText('inventoryOrder.button.popconfirm.approve')}
                        onConfirm={() => {
                            saveDecision(3);
                        }}
                    >
                        <Button key="submit" style={{ color: 'white', backgroundColor: 'green' }}>
                            {geti18nText('inventoryOrder.button.approve')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const saveDecision = (selectedDecision: any) => {
        setLoading(true);
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.SERVICES_ORDER.CHANGE_STATUS + '/' + form.getFieldValue('id'),
            undefined,
            {
                id: form.getFieldValue('id'),
                status: selectedDecision,
            }
        )
            .then((result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    okNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 15);
                    props.setVisible(false);
                    dispatch(refreshItemList());
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            if (result.data.room) {
                                form.setFieldsValue({ room: result.data.room });
                            } else {
                                form.setFieldsValue({ room: undefined });
                            }
                        }
                    }
                }
            );
        }
    };

    return (
        <React.Fragment>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.SERVICES_ORDER.EDIT}
                setValues={setValues}
                width={1400}
                hideActivationButtons={(!isCreate || !canCreate() || showDecision) && status != undefined}
                hideSubmitButton={
                    !canCreate() || status >= 6 || !(items.length > 0) || editingKey !== '' || showDecision
                }
                form={form}
                setIsCreate={setIsCreate}
                goBack={() => history.goBack()}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={false}
                customFooterContent={canCreate() ? getCustomFooterContent : undefined}
                onSaveAndGetID={(id: any) => {
                    if (uploadFilesRef?.current) {
                        uploadFilesRef.current.filesUpload(id);
                    }
                }}
                normalize={(values: any) => {
                    values.warehouse = warehouse ? getSearchFormat(warehouse) : null;
                    values.employee = getSearchFormat(values.employee);
                    values.room = getSearchFormat(values.room);
                    values.date = getDateFormat(values.date);

                    const itemsBasket: any = [];
                    if (items != undefined) {
                        items.map((item: any) => {
                            let obj: any = {};
                            obj.id = item.id ? item.id : null;
                            obj.item = { id: item.item.id };
                            obj.quantity = item.quantity;
                            obj.servicesOrderItemMetadata = item.servicesOrderItemMetadata;
                            itemsBasket.push(obj);
                        });
                    }

                    if (orderViewId != null) values.orderView = { id: orderViewId };
                    values.servicesOrderItems = itemsBasket;
                    if (values?.status?.id > 0) {
                        values.status = getEnumFormat(values.status);
                    } else {
                        values.status = null;
                    }
                    return values;
                }}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="createdBy" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="approvedBy" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="ord" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.date')}
                            name="date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                disabled={!isCreate}
                                ref={focusInput}
                                style={{ width: '100%' }}
                                format={'DD.MM.YYYY HH:mm'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('inventoryRelease.edit.warehouse')}
                            name="warehouse"
                            initialValue={warehouse}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                url={CONSTANTS_REQ.WAREHOUSE.SEARCH_BY_ORDER_VIEW.replace(
                                    '{orderViewId}',
                                    orderViewId?.toString()
                                )}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={true}
                                mustGetPopupContainer={false}
                                defaultValue={warehouse}
                                SearchPopupComponent={<WarehouseIndex orderViewId={orderViewId} />}
                                setDefaultFilterValue={setDefaultFilterValue}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.status')}
                            name="status"
                            rules={[
                                {
                                    required: status,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                options={getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE').map((s: any) => {
                                    if (!status) {
                                        return s.id > 1 ? { ...s, disabled: true } : s;
                                    } else if (status === 1) {
                                        return s.id !== 2 && s.id !== 3 ? { ...s, disabled: true } : s;
                                    } else if (status === 2) {
                                        return s.id !== 1 ? { ...s, disabled: true } : s;
                                    } else if (status === 3) {
                                        return s.id !== 5 ? { ...s, disabled: true } : s;
                                    } else if (status === 5) {
                                        return s.id !== 4 ? { ...s, disabled: true } : s;
                                    } else if (status === 4) {
                                        return s.id !== 6 ? { ...s, disabled: true } : s;
                                    } else {
                                        return s;
                                    }
                                })}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                style={{ width: '100%' }}
                                className={''}
                                disabled={status === 6 || showDecision}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('webshopItem.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="services-order-edit" />}
                                disabled={!isCreate}
                                onChange={onEmployeeChange}
                                order="person.last_name, person.first_name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <RoomSearch
                            disabled={!isCreate}
                            label={geti18nText('webshopItem.edit.room')}
                            map={{ id: 'id', label: 'name' }}
                            name="room"
                            required={true}
                            searchBy="name"
                        />
                    </Col>
                </Row>
                <Divider>{geti18nText('inventoryOrder.divider.1')}</Divider>
                {(isCreate || !status || status === 2) && (
                    <Row gutter={24} style={{ height: '36px' }}>
                        <Col span={24}>
                            <Button
                                style={{ float: 'right', marginRight: '12px' }}
                                icon={<PlusCircleOutlined />}
                                onClick={addNewRowAsset}
                                disabled={addItemInProcess}
                            >
                                {geti18nText('inventoryOrder.button.addItem')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col span={24}>
                        <Form form={assetForm} component={false}>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                size={'small'}
                                dataSource={items}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                            />
                        </Form>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ maxHeight: '204px' }}>
                        <NyFilesUpload
                            documentType={documentTypeEnum.SERVICES_ORDER}
                            url={CONSTANTS_REQ.SERVICES_ORDER_FILE.LIST}
                            editUrl={CONSTANTS_REQ.SERVICES_ORDER_FILE.EDIT}
                            defaultFilesFilter={[
                                { field: 'active', condition: 'equals_bool', value: 1 },
                                { field: 'services_order_id', condition: 'equals', value: servicesOrderId },
                            ]}
                            ref={uploadFilesRef}
                            canAddNewFile={canCreate() && (isCreate || !status || status != 6)}
                            canEdit={canCreate() && (isCreate || !status || status != 6)}
                            canDelete={canCreate() && (isCreate || !status || status != 6)}
                            id={servicesOrderId}
                            refId={servicesOrderId}
                            employee={employee}
                            maxHeight={'205px'}
                        />
                    </Col>
                    {status >= 4 && (
                        <Col span={24}>
                            <Divider>{geti18nText('inventoryRelease.title')}</Divider>
                            <InventoryReleaseTable
                                servicesOrderId={
                                    props && props.value && props.value.id ? props.value.id : servicesOrderId
                                }
                                form={form}
                                status={status}
                                orderViewId={orderViewId}
                            />
                        </Col>
                    )}
                </Row>
            </NyDataEdit>
            <MetadataModal
                itemTitle={itemTitle}
                metadataModalVisible={metadataModalVisible}
                setMetadataModalVisible={setMetadataModalVisible}
                disabelMetadata={disabelMetadata}
                metadata={metadata}
                setEditingKey={setEditingKey}
                items={items}
                setItems={setItems}
            />
        </React.Fragment>
    );
};

export default ServicesOrderEdit;
