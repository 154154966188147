import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { Col, Modal } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';

import CSS from 'csstype';

const MealLogTableIndex = ({
    type = 'meals',
    url = CONSTANTS_REQ.MEAL_LOG.LIST,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const [refreshTable, setRefreshTable] = useState(1);

    const [visible, setVisible] = useState(false);
    const [log, setLog] = useState<any>(undefined);

    const table = useTableSettings();

    const openLog = (log: any) => {
        setLog(log);
        setVisible(true);
    };

    useHelper('meals/hnbSyncLog');

    const setDefaultFilterValue = () => {
        return [{ field: 'id', condition: 'is_not_null', value: '' }];
    };

    const columns = [
        ...[
            {
                title: geti18nText('meals.meal.logs.table.column.id'),
                dataIndex: 'id',
                width: '4%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            ...(type == 'meals'
                ? [
                      {
                          title: geti18nText('meals.meal.logs.table.column.orderId'),
                          dataIndex: 'orderId',
                          width: '8%',
                          sorter: (a: any, b: any) => {},
                          ...getColumnSearch('number'),
                      },
                      {
                          title: geti18nText('meals.meal.logs.table.column.user'),
                          width: '9%',
                          sorter: (a: any, b: any) => {},
                          ...getColumnSearch('string'),
                          dataIndex: [`concat(person.first_name,' ', person.last_name)`],
                          render: (text: any, record: any) => {
                              if (record.person) {
                                  return (
                                      record.person.firstName +
                                      ' ' +
                                      record.person.lastName +
                                      ' (' +
                                      record.person.id +
                                      ')'
                                  );
                              }
                          },
                      },
                  ]
                : []),

            ...[
                {
                    title: geti18nText('meals.meal.logs.table.column.timestamp'),
                    dataIndex: 'timestamp',
                    width: '8%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(false),
                    render: (name: any, record: any) => {
                        if (record && record.timestamp) {
                            return moment(record.timestamp).format('DD.MM.YYYY HH:mm:ss');
                        }
                    },
                },
                {
                    title: geti18nText('meals.meal.logs.table.column.response.status'),
                    dataIndex: 'responseStatus',
                    width: type == 'meals' ? '9%' : '7%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('meals.meal.logs.table.column.requestBody'),
                    dataIndex: ['requestBody'],
                    width: '20%',
                    ...getColumnSearch('string'),
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <JsonData
                                    onClick={() => openLog(record.requestBody)}
                                    data={record.requestBody}
                                ></JsonData>
                            </div>
                        );
                    },
                },
                {
                    title: geti18nText('meals.meal.logs.table.column.endpoint'),
                    dataIndex: ['requestEndpoint'],
                    width: '18%',
                    ...getColumnSearch('string'),
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <JsonData data={record.requestEndpoint}></JsonData>
                            </div>
                        );
                    },
                },
                {
                    title: geti18nText('meals.meal.logs.table.column.responseBody'),
                    dataIndex: ['responseBody'],
                    width: '24%',
                    ...getColumnSearch('string'),
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <JsonData
                                    onClick={() => openLog(record.responseBody)}
                                    data={record.responseBody}
                                ></JsonData>
                            </div>
                        );
                    },
                },
            ],
        ],
    ];

    function ToJson(str: string) {
        if (str) {
            if (str.substring(0, 3) == 'Dto') {
                str = str.substring(str.indexOf('{'));
            }

            return str;
        }
    }

    function formatForView(str: string) {
        if (str.substring(0, 3) == 'Dto') {
            str = str.substring(str.indexOf('{'));
        }

        const formatted = str.replaceAll(',', '\n');

        if (formatted) {
            try {
                return formatted;
            } catch (e) {
                return formatted;
            }
        }
    }

    const JsonData = (data: any) => {
        const [showAll, setShowAll] = useState(false);

        const styleSmall: CSS.Properties = {
            width: 'inherit',
            maxHeight: '300px',
            overflowY: 'hidden',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        };

        return (
            <Col
                onClick={() => {
                    setShowAll(!showAll);
                    openLog(data.data);
                }}
            >
                <div style={{ maxWidth: '900px', maxHeight: '50px' }}>
                    <p className={'ny-table-paragraph'}>{ToJson(data.data)}</p>
                </div>
            </Col>
        );
    };

    const JsonDataModal = (data: any) => {
        const [showAll, setShowAll] = useState(false);

        const styleAll: CSS.Properties = {
            width: '45vw',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        };

        return (
            <Col
                style={styleAll}
                onClick={() => {
                    setShowAll(!showAll);
                    openLog(data.data);
                }}
            >
                <pre>{formatForView(data.data)}</pre>
            </Col>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="meal-log-table"
                url={url}
                // buttonsClassName="buttons-sticky"
                showRecordModal={true}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                shortcuts={true}
                colCSVCustomization={csvColumnCustomisation()}
                fetchWhenChange={refreshTable}
                editProps={{
                    setRefreshTable: setRefreshTable,
                    refreshTable: refreshTable,
                }}
                headerTitle={geti18nText('menu.meals.meal.logs')}
            />

            {visible && (
                <Modal
                    title={geti18nText('meals.meal.logs.error.view')}
                    visible={visible}
                    width={600}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    footer={null}
                >
                    <JsonDataModal data={log}></JsonDataModal>
                </Modal>
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            day: (value: any) => {
                return value != undefined && geti18nText('app.enum.MENU_DAY.' + value);
            },
        },
    ];
};

export default MealLogTableIndex;
