import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { Form } from 'antd';
import WarehouseDocumentIndex from '.';
import WarehouseDocumentEdit from './edit';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';

const WarehouseDocumentSearch = ({
    label = geti18nText('inventoryRelease.edit.document'),
    name = 'warehouseDocument',
    searchBy = 'name',
    map = { id: 'id', label: 'name' },
    setDefaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }],
    disabled = false,
    required = false,
    indexType = undefined,
    addNewItem = false,
    mustGetPopupContainer = true,
}: any) => {
    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.WAREHOUSE_DOCUMENT.SEARCH}
                map={map}
                searchBy={searchBy}
                setDefaultFilterValue={setDefaultFilterValue}
                disabled={disabled}
                addNewItem={addNewItem}
                SearchPopupComponent={
                    <WarehouseDocumentIndex type={indexType} defaultFilterValue={setDefaultFilterValue()} />
                }
                AddNewModalComponent={canCreate() ? WarehouseDocumentEdit : null}
                nyTestId="warehouse-document"
                mustGetPopupContainer={mustGetPopupContainer}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default WarehouseDocumentSearch;
