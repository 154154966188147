import { CheckOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearchByCodebook,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import RowAction from '../../../../../components/row-action';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import { GetEmployeeEvaluationStatusIcon, getEnumArrayStatus, getEnumBooleanArray } from '../../../../../utils/Utils';
import EmployeeEvaluationEditModal from '../edit-modal/edit';
import EmployeeEvaluationRequestModal from '../request-modal/EmployeeEvaluationRequestModal';
import SendEvaluationModal from '../request-modal/SendEvaluationModal';
import useEnum from '../../../../../hooks/useEnum';

const EmployeeEvaluationTable = ({
    showRowSelection,
    refresh,
    setRefresh,
    activeList,
    search,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
    selectedPeriod = undefined,
    isAdmin = false,
}: any) => {
    const table = useTableSettings();
    const enEmployeeEvaluationStatus = useEnum('EMPLOYEE_EVALUATION_STATUS');
    const [requestModalVisible, setRequestModalVisible] = useState<boolean>(false);
    const [sendEvaluationModalVisible, setSendEvaluationModalVisible] = useState<boolean>(false);
    const [dateOptions, setDateOptions] = useState<any>([]);
    const [evaluationDraftCount, setEvaluationDraftCount] = useState<any>(0);

    const canCreate = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const dates = () => {
        let params: any = {
            businessUnitId: null,
            draftOnly: null,
        };
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.GET_DISTINCT_EVALUATION_PERIODS,
            params
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                const formatedDates = result.data.map((element: any) => {
                    const dateFrom = element.dateFrom ? moment(element.dateFrom).format('DD.MM.YYYY.') : '';
                    const dateTo = element.dateTo ? moment(element.dateTo).format('DD.MM.YYYY.') : '';
                    const dates = dateFrom + ' - ' + dateTo;

                    return { id: element.id, text: dates };
                });
                setDateOptions(formatedDates);
            }
        });
    };

    useEffect(() => {
        dates();
    }, []);

    const getDraftDataCount = (data: any) => {
        dates();
        const result =
            data?.length > 0
                ? data.filter((element: any) => element?.status == enEmployeeEvaluationStatus.DRAFT).length
                : 0;
        setEvaluationDraftCount(result);
    };

    const columns = [
        ...(activeList !== 2
            ? [
                  {
                      title: geti18nText('employee.evaluation.table.column.employee'),
                      dataIndex:
                          "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                      width: '12%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebookEmployee(),
                      render: (text: any, record: any) => {
                          if (record.employee && record.employee.person) {
                              return (
                                  `${record.employee.person.firstName} ${record.employee.person.lastName}` +
                                  (record.employee.employmentRecordId ? ` (${record.employee.employmentRecordId})` : '')
                              );
                          }
                      },
                  },
              ]
            : []),
        {
            title: geti18nText('employee.evaluation.table.column.businessUnit'),
            dataIndex: [`concat(employeeBusinessUnit.name,' ', coalesce(employeeBusinessUnit.code, ''))`],
            width: '16%',
            render: (text: any, record: any) => {
                if (record.employeeBusinessUnit) {
                    return (
                        (record.employeeBusinessUnit.code ? '(' + record.employeeBusinessUnit.code + ') ' : '') +
                        record.employeeBusinessUnit.name
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebook(
                CONSTANTS_REQ.BUSINESS_UNIT.SEARCH,
                'employeeBusinessUnit.id',
                'name,code',
                'name',
                { id: 'id', label: 'name', code: 'code', text: 'name' },
                //'name, code',
                '(code) name',
                [['code'], ['name']],
                (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
            ),
        },
        {
            title: geti18nText('employee.evaluation.table.column.directManager'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(directManager.employment_record_id as varchar),'')) ",
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebook(
                CONSTANTS_REQ.EMPLOYEE.SEARCH,
                'directManager.id',
                "person.first_name || ' ' || person.last_name",
                'person.last_name, person.first_name',
                { id: 'id', label: 'name', employmentRecordId: 'employmentRecordId', text: 'text' },
                //'person.last_name, person.first_name',
                'firstName lastName (employmentRecordId)',
                [
                    ['person', 'firstName'],
                    ['person', 'lastName'],
                ],
                (option: any) =>
                    `${option.text} ${option.employmentRecordId ? '(' + option.employmentRecordId + ')' : ''}`
            ),
            render: (text: any, record: any) => {
                if (record.directManager && record.directManager.person) {
                    return (
                        `${record.directManager.person.firstName} ${record.directManager.person.lastName}` +
                        (record.directManager.employmentRecordId ? ` (${record.directManager.employmentRecordId})` : '')
                    );
                }
            },
        },
        {
            title: geti18nText('employee.evaluation.table.column.dateFromTo'),
            dataIndex: ['employeeEvaluationPeriod', 'id'],
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(dateOptions),
            render: (text: any, record: any) => {
                if (record.employeeEvaluationPeriod) {
                    return (
                        <>
                            {moment(record.employeeEvaluationPeriod.dateFrom).format('DD.MM.YYYY.')} -{' '}
                            {moment(record.employeeEvaluationPeriod.dateTo).format('DD.MM.YYYY.')}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('employee.evaluation.table.column.dueDate'),
            dataIndex: 'dueDate',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dueDate) {
                    return moment(record.dueDate).format('DD.MM.YYYY.');
                }
            },
        },
        {
            title: geti18nText('employee.evaluation.table.column.estimation'),
            dataIndex: 'estimation',
            width: '11%',
            render: (text: any, record: any) => {
                if (record.estimation) {
                    return geti18nText('app.enum.EMPLOYEE_EVALUATION_ESTIMATION.' + record.estimation);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('EMPLOYEE_EVALUATION_ESTIMATION'), 'in'),
        },

        {
            title: geti18nText('employee.evaluation.table.column.intermediationNeeded'),
            dataIndex: 'superiorManagerIntermediary',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            render: (variable: any) => {
                if (variable) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                }
            },
        },
        {
            title: geti18nText('employee.evaluation.table.column.status'),
            dataIndex: 'status',
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(
                getEnumArrayStatus('EMPLOYEE_EVALUATION_STATUS'),
                'in',
                isAdmin ? [1, 3, 4, 5, 6, 7, 8].toString() : undefined
            ),
            render: (text: any, record: any) => {
                if (record.status) {
                    return GetEmployeeEvaluationStatusIcon(record.status);
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '5%',
            render: (text: any, record: any) => {
                if (record.id && record?.status == enEmployeeEvaluationStatus.COMPLETED) {
                    return <RowAction component={'EmployeeEvaluation'} record={record}></RowAction>;
                }
            },
        },
    ];

    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const addedButtons = () => {
        if (activeList === 3 && canCreate()) {
            return (
                <>
                    <div style={{ float: 'left' }}>
                        <Button onClick={() => setSendEvaluationModalVisible(true)} disabled={evaluationDraftCount < 1}>
                            {geti18nText('employee.evaluation.edit.button.sendToEvaluation')}
                        </Button>
                    </div>
                    <Button onClick={() => setRequestModalVisible(true)} type="primary">
                        {geti18nText('employee.evaluation.table.button.add')}
                    </Button>
                </>
            );
        }
    };

    return (
        <>
            {dateOptions?.length > 0 && (
                <>
                    <NyDataTable
                        nyId="human-evaluation-employee-evaluation-table"
                        key={activeList}
                        url={CONSTANTS_REQ.EMPLOYEE_EVALUATION.LIST}
                        buttonsClassName="buttons-sticky"
                        showRecordModal={true}
                        columns={columns}
                        modalComponent={EmployeeEvaluationEditModal}
                        editProps={{
                            setRefresh: setRefresh,
                            refresh: refresh,
                        }}
                        scroll={scroll}
                        setDefaultFilterValue={setDefaultFilterValue}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                        showRowSelection={showRowSelection}
                        hideNewButton
                        addedButtons={addedButtons}
                        shortcuts={true}
                        nyTestId="employee-evaluation-table"
                        fetchWhenChange={refresh}
                        exportCSV={true}
                        CSVFileName={geti18nText('menu.evaluation.codebooks')}
                        colCSVCustomization={csvColumnCustomisation()}
                        csvColumns={csvColumns()}
                        onDataLoaded={getDraftDataCount}
                    />
                    <EmployeeEvaluationRequestModal
                        requestModalVisible={requestModalVisible}
                        setRequestModalVisible={setRequestModalVisible}
                        setRefresh={setRefresh}
                        selectedPeriod={selectedPeriod}
                    />
                    <SendEvaluationModal
                        sendEvaluationModalVisible={sendEvaluationModalVisible}
                        setSendEvaluationModalVisible={setSendEvaluationModalVisible}
                        setRefresh={setRefresh}
                        defaultPeriod={selectedPeriod}
                    />
                </>
            )}
        </>
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('employee.evaluation.table.column.employee'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('employee.evaluation.table.column.businessUnit'),
            dataIndex: 'employeeBusinessUnit',
        },
        {
            title: geti18nText('employee.evaluation.table.column.directManager'),
            dataIndex: 'directManager',
        },
        {
            title: geti18nText('employee.evaluation.table.column.dateFromTo'),
            dataIndex: 'employeeEvaluationPeriod',
        },
        {
            title: geti18nText('employee.evaluation.table.column.dueDate'),
            dataIndex: 'dueDate',
        },
        {
            title: geti18nText('employee.evaluation.table.column.estimation'),
            dataIndex: 'estimation',
        },

        {
            title: geti18nText('employee.evaluation.table.column.intermediationNeeded'),
            dataIndex: 'superiorManagerIntermediary',
        },
        {
            title: geti18nText('employee.evaluation.table.column.status'),
            dataIndex: 'status',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            employee: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            employeeBusinessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            directManager: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            employeeEvaluationPeriod: (value: any) => {
                return (
                    (value.dateFrom != undefined &&
                        new Date(value.dateFrom).toLocaleDateString(NyUtils.getSelectedLocale())) +
                    ' - ' +
                    (value.dateTo != undefined &&
                        new Date(value.dateTo).toLocaleDateString(NyUtils.getSelectedLocale()))
                );
            },
        },
        {
            dueDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            estimation: (value: any) => {
                return value != undefined && geti18nText('app.enum.EMPLOYEE_EVALUATION_ESTIMATION.' + value);
            },
        },
        {
            superiorManagerIntermediary: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.' + value);
            },
        },
    ];
};

export default EmployeeEvaluationTable;
