import React, { useRef, useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { geti18nText, NyDataEdit, NyDatePicker, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeBloodDonationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.tab.blood.donation.add'));

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        if (dataForm.donationDate) dataForm.donationDate = moment(dataForm.donationDate);
        setEditHeader(geti18nText('employee.tab.blood.donation.edit'));

        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.tab.blood.donation.add'));
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_BLOOD_DONATION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.donationDate) values.donationDate = moment(values.donationDate).format('yyyy-MM-DD HH:mm');
                if (props.addedData.employeeId) values.employee = { id: props.addedData.employeeId };

                return values;
            }}
            shortcuts={true}
            nyTestId="blood-donation-edit"
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employee.table.blood.donation.date')}
                                    name="donationDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        format="DD.MM.YYYY"
                                        nyTestId="blood-donation-from"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default EmployeeBloodDonationEdit;
