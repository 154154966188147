import { geti18nText, NyDatePicker, NyInputNumber, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import AssetSimpleIndex from '../../../../../components/asset-simple/assetSimple';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customAssetExtraRenderOption } from '../../../../../utils/Utils';

const EditableCell = ({
    form,
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxQuantity = undefined,
    mandatoryReceipt,
    setMandatoryReceipt,
    warehouse = null,
    ...restProps
}: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    let inputNode: any = undefined;

    const onAssetChange = (value: any) => {
        if (value?.id > 0) {
            const itemId =
                value?.label?.props?.children &&
                value?.label?.props?.children[1] &&
                value?.label?.props?.children[1]?.props?.children;
            if (itemId > 0) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + itemId, undefined).then((result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        setMandatoryReceipt(result?.data?.mandatoryReceipt === true ? true : false);
                        if (result.data.mandatoryReceipt) {
                            form.setFieldsValue({ quantity: 1 });
                        }
                    }
                });
            }
        }
    };

    const assetFilter = () => {
        if (warehouse?.id) {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'warehouse.id', condition: 'equals', value: warehouse?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
    };

    switch (inputType) {
        case 'asset':
            inputNode = (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ASSET.SEARCH}
                    map={{
                        id: 'id',
                        label: 'label',
                        text: 'text',
                        name: 'name',
                        serialNumber: 'serialNumber',
                        inventoryNumber: 'inventoryNumber',
                        assetModel: 'assetModel',
                        assetType: 'assetType',
                        item: 'item',
                        measureUnit: 'measureUnit',
                        quantity: 'quantity',
                    }}
                    searchBy="assetModel.name || serialNumber || inventoryNumber"
                    renderOption={customAssetExtraRenderOption}
                    SearchPopupComponent={<AssetSimpleIndex defaultFilterValue={assetFilter()} disabled />}
                    setDefaultFilterValue={assetFilter}
                    order="assetModel.name"
                    customListWidth={'1200px'}
                    mustGetPopupContainer={false}
                    onChange={onAssetChange}
                />
            );
            break;
        case 'dateTo':
            inputNode = <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />;
            break;
        case 'quantity':
            inputNode = (
                <NyInputNumber
                    leftToRight={numbersWriting}
                    style={{ width: '100%' }}
                    min={1}
                    max={maxQuantity}
                    defaultValue={1}
                    disabled={mandatoryReceipt}
                />
            );
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: dataIndex == 'package' || dataIndex == 'asset',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
