import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getEnumBooleanArray } from '../../../../utils/Utils';

const WarehouseReceiptIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const table = useTableSettings();

    useHelper('warehouse/documents/warehouse_receipt');

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseReceipt();
    };

    const canExportCSV = () => {
        return WarehouseBookkeepingRights.canCsvExportWarehouseReceipt();
    };

    const columns = [
        {
            title: geti18nText('warehouseReceipt.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.ord'),
            dataIndex: 'ord',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('warehouseReceipt.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('warehouseReceipt.table.column.warehouse'),
            dataIndex: ['warehouse', 'code'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.WAREHOUSE.SEARCH, 'warehouse.id', 'code', 'code', {
                id: 'id',
                label: 'code',
                text: 'code',
            }),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.document'),
            dataIndex: ['warehouseDocument', 'abbreviation'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.WAREHOUSE_DOCUMENT.SEARCH,
                'warehouseDocument.id',
                'name',
                'name',
                { id: 'id', label: 'name', text: 'name' }
            ),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.company'),
            dataIndex: ['company', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COMPANY.SEARCH, 'company.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                code: 'code',
                text: 'name',
            }),
        },

        {
            title: geti18nText('warehouseReceipt.table.column.orderInfo'),
            dataIndex: 'orderInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.deliveryInfo'),
            dataIndex: 'deliveryNoteInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.invoiceInfo'),
            dataIndex: 'invoiceInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.bookedStock'),
            dataIndex: 'bookedStock',
            sorter: (a: any, b: any) => {},
            render: (bookedStock: any) => {
                if (bookedStock) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('warehouseReceipt.table.column.bookedCost'),
            dataIndex: 'bookedCosts',
            sorter: (a: any, b: any) => {},
            render: (bookedCosts: any) => {
                if (bookedCosts) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
    ];

    return (
        <NyDataTable
            nyId="warehouse-warehouse-receipt-table"
            url={CONSTANTS_REQ.WAREHOUSE_RECEIPT.LIST}
            addNewButtonText={geti18nText('warehouseReceipt.table.add')}
            buttonsClassName="buttons-sticky"
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.warehouseReceipt')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.warehouseReceipt')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            bookedStock: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            bookedCosts: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default WarehouseReceiptIndex;
