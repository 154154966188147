import { FilePdfOutlined, HistoryOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { NyDataEdit, NyDatePicker, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Descriptions, Divider, Form, Input, InputNumber, Modal, Popover, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NyHistory from '../../../../components/ny-history/NyHistory';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    fileDownload,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumArrayStatusNotDraft,
    getEnumFormat,
    getSearchFormat,
    setDateFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';

const { Text } = Typography;

const EmployeeAnnualLeaveEdit = (props: any) => {
    const employeeAnnualLEaveStatusEnum = useEnum('EMPLOYEE_ANNUAL_LEAVE_STATUS');
    const [editHeader, setEditHeader] = useState(geti18nText('employeeAnnualLeave.edit.new'));
    const [loading, setLoading] = useState(false);
    const [empId, setEmpId] = useState<any>(undefined);
    const [leaveYear, setLeaveYear] = useState<any>(undefined);
    const [dataForm, setDataForm] = useState(null);
    const [fileId, setFileId] = useState(undefined);
    const [status, setStatus] = useState(employeeAnnualLEaveStatusEnum.IN_PREPARATION);
    const [usedDays, setUsedDays] = useState(undefined);
    const [usedOldDays, setUsedOldDays] = useState(undefined);
    const [approvedOldDays, setApprovedOldDays] = useState(undefined);
    const [approvedDays, setApprovedDays] = useState(undefined);
    const [description, setDescription] = useState<any>(undefined);
    const [commentReuqired, setCommentRequired] = useState(false);
    const [conditionChanged, setConditionChanged] = useState<boolean>(false);
    const [usedDaysChangelog, setUsedDaysChangelog] = useState<any>(null);
    const [isCreate, setIsCreate] = useState<any>(true);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const resolveDisabled = () => {
        return dataForm != null && props && props.editProps && !props.editProps.isHumanResources;
    };

    const resolveDisabledActivatedAndEdit = () => {
        return resolveDisabled() || dataForm != null || status == employeeAnnualLEaveStatusEnum.ACTIVATED;
    };

    const resolveDisabledActivated = () => {
        return resolveDisabled() || status == employeeAnnualLEaveStatusEnum.ACTIVATED;
    };

    function setValues(dataForm: any, employeeChange: any = false) {
        if (dataForm.employee && dataForm.employee.person && dataForm.annualLeaveYear) {
            setEditHeader(
                geti18nText('employeeAnnualLeave.edit.title') +
                    ' - ' +
                    dataForm.employee.person.firstName +
                    ' ' +
                    dataForm.employee.person.lastName +
                    ' (' +
                    dataForm.annualLeaveYear +
                    ')'
            );
        } else if (dataForm.id) {
            setEditHeader(geti18nText('employeeAnnualLeave.edit.title') + ' - ' + dataForm.id);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
            setEmpId(dataForm.employee.id);
        }
        if (dataForm.annualLeaveYear) {
            dataForm.annualLeaveYear = setDateFormat(dataForm.annualLeaveYear, 'YYYY');
            setLeaveYear(getDateFormat(dataForm.annualLeaveYear, 'YYYY'));
        }

        if (dataForm.annualLeaveDate) {
            dataForm.annualLeaveDate = setDateFormat(dataForm.annualLeaveDate);
        }
        if (dataForm.dateFrom != undefined) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
        }
        if (dataForm.oldDaysLimit) {
            dataForm.oldDaysLimit = setDateFormat(dataForm.oldDaysLimit);
        }

        if (dataForm.fileId != undefined) {
            setFileId(dataForm.fileId);
        }
        if (dataForm.usedDays != undefined) {
            setUsedDays(dataForm.usedDays);
        }
        if (dataForm.usedOldDays != undefined) {
            setUsedOldDays(dataForm.usedOldDays);
        }
        if (dataForm.approvedOldDays != undefined) {
            setApprovedOldDays(dataForm.approvedOldDays);
        }
        if (dataForm.approvedDays != undefined) {
            setApprovedDays(dataForm.approvedDays);
        }
        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('EMPLOYEE_ANNUAL_LEAVE_STATUS', dataForm.status);
        } else {
            setStatus(employeeAnnualLEaveStatusEnum.IN_PREPARATION);
        }
        if (dataForm.description) {
            setDescription(JSON.parse(dataForm.description));
        }
        if (dataForm.conditionChanged) {
            setConditionChanged(dataForm.conditionChanged);
        }
        if (dataForm.usedDaysChangelog) {
            setUsedDaysChangelog(dataForm.usedDaysChangelog);
        }
        if (!employeeChange) setDataForm(dataForm.id);
        delete dataForm.usedDaysComment;
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employeeAnnualLeave.edit.new'));
        setFileId(undefined);
        setStatus(undefined);
        setUsedDays(undefined);
        setUsedOldDays(undefined);
        setApprovedDays(undefined);
        setApprovedOldDays(undefined);
        setDescription(undefined);
        setStatus(employeeAnnualLEaveStatusEnum.IN_PREPARATION);
        setDataForm(null);
        setConditionChanged(false);
        setUsedDaysChangelog(null);
        setCommentRequired(false);
        setIsCreate(null);
    };

    const fatchEmployeeData = () => {
        let employee = form.getFieldValue(['employee']);
        let annualLeaveYear = form.getFieldValue(['annualLeaveYear']);
        setLeaveYear(annualLeaveYear.format('yyyy'));

        if (annualLeaveYear && employee && employee.id > 0) {
            var parms: any = {
                employeeId: employee.id,
                year: annualLeaveYear.format('yyyy'),
            };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.GET_BY_EMPLOYEE_AND_YEAR, parms).then(
                async (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setValues(result.data, true);
                        }
                    }
                }
            );
        }
    };

    const employeeOnChange = (value: any) => {
        if (value.id > 0) {
            fatchEmployeeData();
        }
    };

    const yearOnChange = (value: any) => {
        if (value) {
            fatchEmployeeData();
        }
    };

    const onUsedDaysChange = (value: any) => {
        if (value != undefined) {
            if (value != usedDays) {
                setCommentRequired(true);
            } else {
                if (form.getFieldValue('usedDays') == usedDays) {
                    setCommentRequired(false);
                }
            }
        }
    };

    const onUsedOldDaysChange = (value: any) => {
        if (value != undefined) {
            if (value != usedOldDays) {
                setCommentRequired(true);
            } else {
                if (form.getFieldValue('usedOldDays') == usedOldDays) {
                    setCommentRequired(false);
                }
            }
        }
    };

    const onApprovedOldDaysChange = (value: any) => {
        if (value != undefined) {
            if (value != approvedOldDays) {
                setCommentRequired(true);
            } else {
                if (form.getFieldValue('approvedOldDays') == approvedOldDays) {
                    setCommentRequired(false);
                }
            }
        }
    };

    const onApprovedDaysChange = (value: any) => {
        if (value != undefined) {
            if (value != approvedDays) {
                setCommentRequired(true);
            } else {
                if (form.getFieldValue('approvedDays') == approvedDays) {
                    setCommentRequired(false);
                }
            }
        }
    };

    const descirptionContent = () => {
        let items = [];
        if (description != undefined) {
            for (const [key, value] of Object.entries(description) as any) {
                if (
                    key == 'employeeAnnualLeave.description.developmentalDisabilities' ||
                    key == 'employeeAnnualLeave.description.singleParent' ||
                    key == 'employeeAnnualLeave.description.child'
                ) {
                    const numbers = value.split('|');
                    if (numbers != undefined) {
                        items.push(
                            <Descriptions.Item
                                label={
                                    geti18nText(key) +
                                    ' ' +
                                    numbers[1] +
                                    ' ' +
                                    geti18nText('app.default.filter.range.year').toLowerCase()
                                }
                            >
                                <Text strong>
                                    {numbers[0] +
                                        ' (' +
                                        numbers[2] +
                                        ' x ' +
                                        numbers[3] +
                                        ') ' +
                                        (parseInt(numbers[0]) == 1
                                            ? geti18nText('app.default.filter.range.day').toLowerCase()
                                            : geti18nText('employeeLeave.table.column.days').toLowerCase())}
                                </Text>
                            </Descriptions.Item>
                        );
                    }
                } else {
                    items.push(
                        <Descriptions.Item label={geti18nText(key) ? geti18nText(key) : key}>
                            <Text strong>
                                {value +
                                    ' ' +
                                    (parseInt(value) == 1
                                        ? geti18nText('app.default.filter.range.day').toLowerCase()
                                        : geti18nText('employeeLeave.table.column.days').toLowerCase())}
                            </Text>
                        </Descriptions.Item>
                    );
                }
            }
        }

        return (
            <Descriptions bordered column={1} style={{ width: '700px' }}>
                {items}
            </Descriptions>
        );
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.EDIT}
                setValues={setValues}
                width={600}
                goBack={() => {
                    history.goBack();
                }}
                setIsCreate={setIsCreate}
                form={form}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={true}
                fetchWhenChange={id}
                paramsId={id}
                buttonsClassName="buttons-sticky"
                isModal={true}
                {...props}
                normalize={(values: any) => {
                    if (values.employee) values.employee = getSearchFormat(values.employee);
                    if (values.annualLeaveYear) values.annualLeaveYear = getDateFormat(values.annualLeaveYear, 'YYYY');
                    if (values.annualLeaveDate) values.annualLeaveDate = getDateFormat(values.annualLeaveDate);
                    if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                    if (values.oldDaysLimit) values.oldDaysLimit = getDateFormat(values.oldDaysLimit);

                    if (!values.approvedOldDays) values.approvedOldDays = 0;
                    if (!values.approvedDays) values.approvedDays = 0;
                    if (!values.usedDays) values.usedDays = 0;
                    if (!values.usedOldDays) values.usedOldDays = 0;

                    values.status = getEnumFormat(values.status ? values.status : status);
                    values.fileId = fileId;
                    if (values.usedDaysComment) {
                        values.usedDaysChangelog = values.usedDaysChangelog
                            ? values.usedDaysChangelog + ' ' + values.usedDaysComment
                            : values.usedDaysComment;
                    }
                    //values.description = description;
                    // values.usedDaysChangelog
                    values.conditionChanged = conditionChanged;

                    return values;
                }}
                shortcuts={true}
                checkIsFormChanged={true}
                customFooterContent={
                    <div style={{ float: 'left' }}>
                        {fileId && status == 2 && (
                            <Button
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    fileDownload(
                                        CONSTANTS_REQ.FILES.DOWNLOAD + '/' + fileId,
                                        null,
                                        geti18nText('employeeAnnualLeave.edit.title')
                                    );
                                }}
                            >
                                {geti18nText('employeeAnnualLeave.edit.vacation.pdf')}
                            </Button>
                        )}
                        <Button onClick={showModal} disabled={isCreate}>
                            <HistoryOutlined />
                            {geti18nText('employeeAnnualLeave.edit.usedDaysChangelog')}
                        </Button>
                    </div>
                }
            >
                <Row gutter={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="anual-leave-edit" />}
                                nyTestId="employee"
                                autoFocus={true}
                                onChange={employeeOnChange}
                                order="person.last_name, person.first_name"
                                disabled={resolveDisabledActivatedAndEdit()}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.annualLeaveYear')}
                            name="annualLeaveYear"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={moment()}
                        >
                            <NyDatePicker
                                mode="year"
                                picker="year"
                                format="YYYY"
                                style={{ width: '100%' }}
                                onChange={yearOnChange}
                                disabled={resolveDisabledActivatedAndEdit()}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('travelWarrantPayment.edit.status')}
                            name="status"
                            initialValue={setEnumFormat(
                                'EMPLOYEE_ANNUAL_LEAVE_STATUS',
                                employeeAnnualLEaveStatusEnum.IN_PREPARATION
                            )}
                        >
                            <NySearchField
                                options={getEnumArrayStatusNotDraft('EMPLOYEE_ANNUAL_LEAVE_STATUS')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                nyTestId="employee-annual-leave-status"
                                className=""
                                style={{ width: '100%' }}
                                mustGetPopupContainer={false}
                                disabled={resolveDisabledActivated()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.approvedDays')}
                            name="approvedDays"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={0}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={resolveDisabled()}
                                onChange={onApprovedDaysChange}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {description != undefined && (
                            <Popover
                                content={descirptionContent}
                                title={geti18nText('employeeAnnualLeave.edit.action.calculationHelp')}
                            >
                                <Button icon={<InfoCircleOutlined />}>
                                    {geti18nText('employeeAnnualLeave.edit.action.calculationHelp')}
                                </Button>
                            </Popover>
                        )}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.approvedOldDays')}
                            name="approvedOldDays"
                            initialValue={0}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={resolveDisabled()}
                                onChange={onApprovedOldDaysChange}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employee.leave.days.expire')} name="oldDaysLimit">
                            <NyDatePicker style={{ width: '100%' }} disabled={resolveDisabled()} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.annualLeaveDate')}
                            name="annualLeaveDate"
                            initialValue={moment()}
                        >
                            <NyDatePicker style={{ width: '100%' }} disabled={resolveDisabled()} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeLeave.edit.from')}
                            name="dateFrom"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={moment()}
                        >
                            <NyDatePicker style={{ width: '100%' }} nyTestId="date-from" disabled={resolveDisabled()} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.usedDays')}
                            name="usedDays"
                            initialValue={0}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={resolveDisabled()}
                                min={0}
                                onChange={onUsedDaysChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeAnnualLeave.edit.usedOldDays')}
                            name="usedOldDays"
                            initialValue={0}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={resolveDisabled()}
                                min={0}
                                onChange={onUsedOldDaysChange}
                            />
                        </Form.Item>
                    </Col>
                    {commentReuqired && (
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantOrganization.table.column.note')}
                                name="usedDaysComment"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    disabled={resolveDisabled()}
                                ></TextArea>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </NyDataEdit>
            {dataForm != undefined && isModalOpen && (
                <Modal
                    title={geti18nText('employeeAnnualLeave.edit.usedDaysChangelog')}
                    visible={isModalOpen}
                    onCancel={() => {
                        setIsModalOpen(false);
                    }}
                    okButtonProps={{ hidden: true }}
                    maskClosable={false}
                >
                    <NyHistory id={dataForm} link={'employeeAnnualLeave'} />
                </Modal>
            )}
        </>
    );
};

export default EmployeeAnnualLeaveEdit;
