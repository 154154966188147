import { NySession, geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TravelWageIndex from '../travel-wage';
import TravelWageEdit from '../travel-wage/edit';
import { Form } from 'antd';
import { customTravelWageRenderOption } from '../../../../utils/Utils';
import useEnum from '../../../../hooks/useEnum';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelWageSearch = ({
    personal = undefined,
    travelWage,
    travelWageOnChange,
    countryId,
    employeeId = null,
    disabled = false,
    label = geti18nText('travelWarrantWage.edit.travelWage'),
    required = true,
    map = {
        id: 'id',
        label: 'name',
        text: 'text',
        name: 'name',
        nonStandardWageNr: 'nonStandardWageNr',
        type: 'type',
        amount: 'amount',
        validFrom: 'validFrom',
        validTo: 'validTo',
        currency: 'currency',
    },
}: any) => {
    const travelWageType = useEnum('TRAVEL_WAGE_TYPE');

    const travelWageFilter = () => {
        if (countryId) {
            if (personal) {
                return [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'country.id', condition: 'equals', value: countryId },
                    { field: 'forEmployee', condition: 'equals_bool', value: 1 },
                    { field: 'type', condition: 'not_equals', value: travelWageType.NON_STANDARD },
                ];
            }

            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'country.id', condition: 'equals', value: countryId },
                { field: 'type', condition: 'not_equals', value: travelWageType.NON_STANDARD },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'not_equals', value: travelWageType.NON_STANDARD },
            ];
        }
    };

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name="travelWage"
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.TRAVEL_WAGE.EMPLOYEE_SEARCH}
                map={map}
                searchBy="name"
                renderOption={customTravelWageRenderOption}
                SearchPopupComponent={
                    <TravelWageIndex
                        isSearchIndex={true}
                        readonly={!canCreate()}
                        defaultFilteredValue={travelWageFilter()}
                        sortOrder={{ order: 'name', orderType: 'asc' }}
                    />
                }
                AddNewModalComponent={canCreate() ? TravelWageEdit : null}
                nyTestId="travel-wage"
                onChange={travelWageOnChange}
                setDefaultFilterValue={() => travelWageFilter()}
                value={travelWage}
                disabled={disabled}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default TravelWageSearch;
