import { useRef, useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { geti18nText, NyDataEdit, NySearchField, NyDatePicker, NyInputNumber, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TownshipIndex from '../../../administration/views/township';
import { RootState } from '../../../../rootReducer';
import { useSelector } from 'react-redux';
import { getDateFormat, setDateFormat, setSearchFormat } from '../../../../utils/Utils';
import { validateIBAN } from '../../../../utils/Validation';
import { PayrollRights } from '../../../../rights/payrollRights';

const TownshipTaxEdit = (props: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [editHeader, setEditHeader] = useState(geti18nText('townshipTax.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('townshipTax.edit.title') + ' - ' + dataForm.id);
        }

        if (dataForm.township) dataForm.township = setSearchFormat(dataForm.township);
        if (dataForm.validFrom) dataForm.validFrom = setDateFormat(dataForm.validFrom);
        if (dataForm.validTo) dataForm.validTo = setDateFormat(dataForm.validTo);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('townshipTax.edit.new'));
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TOWNSHIP_TAX.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
                if (values.validTo) values.validTo = getDateFormat(values.validTo);
                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('townshipTax.edit.township')}
                        name="township"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.TOWNSHIP.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<TownshipIndex />}
                            nyTestId="township"
                            autoFocus={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('townshipTax.edit.taxIBAN')}
                        name="taxIban"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                            {
                                validator: validateIBAN,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('townshipTax.edit.validFrom')} name="validFrom">
                        <NyDatePicker style={{ width: '80%' }} nyTestId="valid-from" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('townshipTax.edit.validTo')} name="validTo">
                        <NyDatePicker style={{ width: '80%' }} nyTestId="valid-to" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('townshipTax.edit.taxPercent')} name="taxPercent">
                        <NyInputNumber leftToRight={numbersWriting} style={{ width: '80%' }} nyTestId="tax-percent" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('townshipTax.edit.taxRemittance')} name="taxRemittance">
                        <Input style={{ width: '80%' }} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TownshipTaxEdit;
