import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import PostOfficeIndex from '.';
import PostOfficeEdit from './edit';
import { AdministrationRights } from '../../../../rights/administrationRights';

const PostOfficeSearch = ({
    label = geti18nText('settlement.edit.postOffice'),
    name = ['address', 'postOffice'],
    onChange,
    nyTestId = 'post-office',
    searchBy = "name || ' ' || zipCode",
    setDefaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }],
    itemName = [['zipCode'], ['name']],
    required = false,
    disabled = false,
}: any) => {
    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.POST_OFFICE.SEARCH}
                map={{ id: 'id', label: 'text' }}
                searchBy={searchBy}
                SearchPopupComponent={<PostOfficeIndex />}
                AddNewModalComponent={canCreate() ? PostOfficeEdit : null}
                nyTestId={nyTestId}
                onChange={onChange}
                itemName={itemName}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default PostOfficeSearch;
