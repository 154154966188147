import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import DocumentTypeEdit from './edit';
import DocumentTypeIndex from './index';

const DocumentTypeSearch = ({
    classname = undefined,
    disabled = false,
    label = geti18nText('menu.document.type'),
    map = { id: 'id', label: 'name' },
    mustGetPopupContainer = true,
    name = 'documentType',
    nyTestId = 'documentType',
    renderOption = undefined,
    required = false,
    setDefaultFilterValue = null,
    onChange = null,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.DOCUMENT_TYPE.SEARCH}
                map={map}
                searchBy="name"
                mustGetPopupContainer={mustGetPopupContainer}
                SearchPopupComponent={<DocumentTypeIndex />}
                AddNewModalComponent={canCreate() ? DocumentTypeEdit : null}
                nyTestId={nyTestId}
                setDefaultFilterValue={setDefaultFilterValue}
                className={classname}
                renderOption={renderOption}
                disabled={disabled}
                onChange={onChange}
            />
        </Form.Item>
    );
};

export default DocumentTypeSearch;
