import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArrayStatus, getOrdinal, getTravelWarrantStatusIcon, numberFormat } from '../../../../utils/Utils';
import EmployeeTravelRelationPaymentEdit from './EmployeeTravelRelationPaymentEdit';

const EmployeeTravelRelationPaymentTable = ({ scroll = { y: 500, x: 800 }, sortOrder }: any) => {
    const { id } = useParams<any>();
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const table = useTableSettings();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelLocal();
    };

    return (
        <NyDataTable
            nyId="travel-warrant-employee-travel-relation-payment"
            url={CONSTANTS_REQ.EMPLOYEE_TRAVEL_RELATION_PAYMENT.LIST}
            addNewButtonText={geti18nText('employeeTravelRelationPayment.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            fetchWhenChange={id || refreshItem}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultPageSize={table.setDefaultPageSize()}
            modalComponent={EmployeeTravelRelationPaymentEdit}
            columns={[
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.id'),
                    dataIndex: 'id',
                    width: '6%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.ordinal'),
                    dataIndex: 'ordinal',
                    width: '6%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: any, record: any) => {
                        if (record) {
                            return getOrdinal(record);
                        }
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.calculationDate'),
                    dataIndex: 'calculationDate',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(false),
                    width: '8%',
                    render: (text: any, record: any) => {
                        if (record.calculationDate) {
                            return moment(record.calculationDate).format('DD.MM.YYYY');
                        }
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.employee'),
                    dataIndex:
                        "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    render: (employee: any, record: any) => {
                        if (record && record.employee && record.employee.person) {
                            return (
                                <>
                                    {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                                    {record.employee &&
                                        record.employee.employmentRecordId &&
                                        ` (${record.employee.employmentRecordId})`}
                                </>
                            );
                        }
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.periodFrom'),
                    dataIndex: 'periodFrom',
                    width: '7%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(false),
                    render: (text: any, record: any) => {
                        if (record.periodFrom) {
                            return moment(record.periodFrom).format('DD.MM.YYYY');
                        }
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.periodTo'),
                    dataIndex: 'periodTo',
                    width: '7%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(false),
                    render: (text: any, record: any) => {
                        if (record.periodTo) {
                            return moment(record.periodTo).format('DD.MM.YYYY');
                        }
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.totalDistance'),
                    dataIndex: 'totalDistance',
                    width: '7%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.totalPrice'),
                    dataIndex: 'totalPrice',
                    width: '7%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (totalPrice: any) => {
                        return totalPrice ? numberFormat(totalPrice) : '';
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.totalAmount'),
                    dataIndex: 'totalAmount',
                    width: '8%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                    render: (totalAmount: any) => {
                        return (
                            <div style={{ float: 'right', textAlign: 'right' }}>
                                {totalAmount ? numberFormat(totalAmount) : ''}
                            </div>
                        );
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.payDate'),
                    dataIndex: 'payDate',
                    width: '6%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(false),
                    render: (text: any, record: any) => {
                        if (record.payDate) {
                            return moment(record.payDate).format('DD.MM.YYYY');
                        }
                    },
                },
                {
                    title: geti18nText('employeeTravelRelationPayment.table.column.status'),
                    dataIndex: 'status',
                    width: '220px',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchCheckbox(getEnumArrayStatus('TRAVEL_WARRANT_STATUS')),
                    render: (status: any) => {
                        return getTravelWarrantStatusIcon(status);
                    },
                },
            ]}
            shortcuts={true}
            hideNewButton={!canCreate()}
        />
    );
};

export default EmployeeTravelRelationPaymentTable;
