import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationEdit from '../payment-calculation/edit';

const SecondIncomeEdit = () => {
    useHelper('payroll/calculation/second_income');

    return <PaymentCalculationEdit type={2} />;
};

export default SecondIncomeEdit;
