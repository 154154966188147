import { DeleteOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Row, Tabs } from 'antd';
import moment from 'moment';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import {
    errorNotification,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import { BusinessUnitSearch } from '../business-unit/search';
import PayrollBrutSearch from '../payroll-brut/search';
import PointAdditionDecisionSearch from './search';

const PointAdditionDecisionEdit = (props: any) => {
    const table = useTableSettings();
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [editHeader, setEditHeader] = useState(geti18nText('pointAdditionDecision.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [dataForm, setDataForm] = useState<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [validFrom, setValidFrom] = useState<any>(null);
    const [workRelated, setWorkRelated] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<any>(null);
    const [isCreate, setIsCreate] = useState(false);

    const [activeKey, setActiveKey] = useState('1');
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const [employeeForm] = Form.useForm();
    const [isAddListModalVisible, setIsAddListModalVisible] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(false);
    const [hasSelectedDelete, setHasSelectedDelete] = useState(false);
    const [selectedValuesDelete, setSelectedValuesDelete] = useState<any>([]);
    const [selectedValuesAdd, setSelectedValuesAdd] = useState<any>([]);
    const [createdByUser, setCreatedByUser] = useState<any>(null);
    const [initialDecision, setInitialDecision] = useState<any>(null);
    const [selectedDecision, setSelectedDecision] = useState<any>(null);

    const employeesUrl = CONSTANTS_REQ.POINT_ADDITION_DECISION.EMPLOYEES;
    const notEmployeesUrl = CONSTANTS_REQ.POINT_ADDITION_DECISION.NOT_EMPLOYEES;

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks() || HumanResourcesRights.canCreateCodebooks();
    };

    const isPersonal = () => {
        return props?.editProps?.isPersonal;
    };

    const getEmployeeId = () => {
        return props?.addedData?.employeeId > 0 ? props?.addedData?.employeeId : null;
    };

    const onlySelectData = () => {
        return getEmployeeId();
    };

    const disabledTabs = () => {
        return isCreate || !dataForm;
    };

    const setDefaultFilterValue = () => {
        if (dataForm) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'id', condition: 'not_equals', value: dataForm.toString() },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('decisionNumber')) {
            setEditHeader(geti18nText('pointAdditionDecision.edit.title') + ' - ' + dataForm.decisionNumber);
        } else if (dataForm?.pointAdditionDecision?.decisionNumber) {
            setEditHeader(
                geti18nText('pointAdditionDecision.edit.title') +
                    ' - ' +
                    dataForm?.pointAdditionDecision?.decisionNumber
            );
        }
        setWorkRelated(dataForm.workRelated);

        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom, 'yyyy-MM-DD');
            setValidFrom(dataForm.validFrom);
        }
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }
        if (dataForm.replacementDecision) {
            dataForm.replacementDecision = setSearchFormat(
                dataForm.replacementDecision,
                'decisionNumber',
                'decisionNumber'
            );
        }

        if (dataForm.fileId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.FILES.INFO + '/' + dataForm.fileId, undefined, undefined).then(
                (result: any) => {
                    if (result && result.status == RESPONSE.OK && result.data) {
                        let filesImage = [
                            {
                                id: dataForm.fileId,
                                uid: dataForm.fileId,
                                name: result.data.name,
                                url:
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    dataForm.fileId +
                                    '?lastmod=' +
                                    new Date().valueOf(),
                            },
                        ];
                        setFiles(filesImage);
                    }
                }
            );
        }

        if (dataForm.createdBy) {
            setCreatedByUser(dataForm.createdBy);
            dataForm.createdBy = dataForm.createdBy?.person?.firstName + ' ' + dataForm.createdBy?.person?.lastName;
        }
        if (dataForm.updatedBy) {
            setIsUpdated(true);
            dataForm.updatedBy = dataForm.updatedBy?.person?.firstName + ' ' + dataForm.updatedBy?.person?.lastName;
        }
        if (dataForm.created) {
            dataForm.created = setDateFormat(dataForm.created);
        }
        if (dataForm.updated) {
            dataForm.updated = setDateFormat(dataForm.updated);
        }

        if (!isPersonal() && !getEmployeeId()) {
            dataForm.pointAdditionDecision = setSearchFormat(dataForm, 'decisionNumber', 'decisionNumber');
        }
        setInitialDecision(dataForm.pointAdditionDecision);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('pointAdditionDecision.edit.new'));
        setDataForm(null);
        setFiles([]);
        setWorkRelated(false);
        setValidFrom(null);
        setIsUpdated(false);
        setCreatedByUser(null);
        setInitialDecision(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getSelectData: any = (
        <>
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <PointAdditionDecisionSearch
                        label={geti18nText('pointAdditionDecision.edit.title')}
                        name={'pointAdditionDecision'}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        required
                        onChange={(value: any) => setSelectedDecision(value)}
                    />
                </Col>
            </Row>
        </>
    );

    const getGeneralEditData: any = (
        <>
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('pointAdditionDecision.edit.decisionNumber')}
                        name="decisionNumber"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <PayrollBrutSearch
                        label={geti18nText('pointAdditionDecision.edit.payrollBrut')}
                        name="payrollBrut"
                        addition={true}
                        setDefaultFilterValue={() => [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'addition', condition: 'equals_bool', value: 1 },
                        ]}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label={geti18nText('pointAdditionDecision.edit.validFrom')} name="validFrom">
                        <NyDatePicker
                            defaultPickerValue={validFrom}
                            style={{ width: '100%' }}
                            format="DD.MM.YYYY"
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={geti18nText('pointAdditionDecision.edit.workRelated')} name="workRelated">
                        <Checkbox
                            checked={workRelated}
                            onChange={(e: any) => {
                                setWorkRelated(e.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label={geti18nText('pointAdditionDecision.edit.amount')} name="amount">
                        <NyInputNumber decimalPlaces={2} isDecimal={true} min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        label={geti18nText('menu.currency')}
                        name="currency"
                        initialValue={defaultCurrencyISO ? defaultCurrencyISO : 'EUR'}
                    >
                        <Input defaultValue={defaultCurrencyISO ? defaultCurrencyISO : 'EUR'} disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <BusinessUnitSearch
                        label={geti18nText('pointAdditionDecision.edit.businessUnit')}
                        formItemName={'businessUnit'}
                    />
                </Col>
                <Col span={12}>
                    <PointAdditionDecisionSearch
                        label={geti18nText('pointAdditionDecision.edit.replacementDecision')}
                        name={'replacementDecision'}
                        setDefaultFilterValue={setDefaultFilterValue}
                        id={dataForm}
                    />
                </Col>
            </Row>
            {dataForm && (
                <Row gutter={24}>
                    <Col span={isUpdated ? 6 : 12}>
                        <Form.Item label={geti18nText('pointAdditionDecision.edit.created')} name="created">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                disabled
                                format="DD.MM.YYYY HH:mm"
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={isUpdated ? 6 : 12}>
                        <Form.Item label={geti18nText('pointAdditionDecision.edit.createdBy')} name="createdBy">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    {isUpdated && (
                        <Col span={6}>
                            <Form.Item label={geti18nText('pointAdditionDecision.edit.updated')} name="updated">
                                <NyDatePicker
                                    style={{ width: '100%' }}
                                    disabled
                                    format="DD.MM.YYYY HH:mm"
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {isUpdated && (
                        <Col span={6}>
                            <Form.Item label={geti18nText('pointAdditionDecision.edit.updatedBy')} name="updatedBy">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <Divider>{geti18nText('pointAdditionDecision.edit.fileId')}</Divider>
                    <Form.Item name="file">
                        <NyImageUpload
                            files={files}
                            setFiles={setFiles}
                            saveOnUpload={true}
                            accept={'*'}
                            cropShape={null}
                            listType={'text'}
                            maxImage={1}
                            canDeleteFile={canCreate()}
                            canUpload={canCreate()}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );

    let clearKeysDelete: any;

    const setSelectedValuesFuncDelete = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDelete(hasSelected);
        setSelectedValuesDelete(selectedRows);
        clearKeysDelete = clearSelectedRowKeys;
    };

    let clearKeysAdd: any;

    const setSelectedValuesFuncAdd = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAdd(hasSelected);
        setSelectedValuesAdd(selectedRowKeys);
        clearKeysAdd = clearSelectedRowKeys;
    };

    const save = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.POINT_ADDITION_DECISION_EMPLOYEE.ADD_EMPLOYEES, undefined, {
            pointAdditionDecision: { id: dataForm },
            employeeList: selectedValuesAdd,
        }).then((result: any) => {
            if (setLoadingModal) setLoadingModal(false);
            if (result && (result.status === RESPONSE.CREATED || result.status === RESPONSE.OK)) {
                if (clearKeysAdd) {
                    clearKeysAdd();
                }
                okNotification();
                setIsAddListModalVisible(false);
                setRefreshTable((refreshTable: any) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromGroup = () => {
        const res = selectedValuesDelete.map((value: any) => {
            return { id: value.id };
        });

        NyRequestResolver.requestPost(CONSTANTS_REQ.POINT_ADDITION_DECISION_EMPLOYEE.REMOVE_EMPLOYEES, undefined, {
            pointAdditionDecision: { id: dataForm },
            employeeList: res,
        }).then((result: any) => {
            if (result && (result.status === RESPONSE.CREATED || result.status === RESPONSE.OK)) {
                if (clearKeysDelete) {
                    clearKeysDelete();
                }
                okNotification();
                setRefreshTable((refreshTable: any) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && canCreate() && !getEmployeeId() && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisible(true);
                        }}
                    >
                        {geti18nText('payrollBrut.table.addEmployee')}
                    </Button>
                    <Popconfirm
                        title={geti18nText('pointAdditionDecision.table.remove.confirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDelete}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDelete}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('payrollBrut.table.remove')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    const getEmployees: any = (
        <>
            <NyDataTable
                nyId="point-addition-employees"
                rowKey="id"
                url={employeesUrl.replace('{id}', dataForm)}
                showRowSelection
                readonly
                hideButtons
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                scroll={{ y: 400, x: 500 }}
                rowSelectionType={'checkbox'}
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                rowSelectionModal={setSelectedValuesFuncDelete as any}
                fetchWhenChange={refreshTable}
                columns={[
                    {
                        title: geti18nText('payrollBrut.table.column.id'),
                        dataIndex: ['employee', 'id'],
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payrollBrut.table.column.employee'),
                        dataIndex:
                            "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (person: any, record: any) => {
                            if (record && record.person) {
                                return (
                                    <>
                                        {`${record.person.firstName} ${record.person.lastName} `}
                                        {record.employee &&
                                            record.employee.employmentRecordId &&
                                            ` (${record.employee.employmentRecordId})`}
                                    </>
                                );
                            }
                        },
                    },
                ]}
            />
            {isAddListModalVisible && (
                <Modal
                    title={geti18nText('payrollBrut.table.addEmployee')}
                    visible={isAddListModalVisible}
                    okText={geti18nText('app.default.button.add')}
                    okButtonProps={{ disabled: !hasSelectedAdd }}
                    onOk={(e) => save(e)}
                    confirmLoading={loadingModal}
                    width={600}
                    onCancel={() => {
                        setIsAddListModalVisible(false);
                    }}
                >
                    <NyDataTable
                        nyId="point-addition-not-employees"
                        rowKey="id"
                        url={notEmployeesUrl.replace('{id}', dataForm)}
                        showRowSelection
                        readonly
                        hideButtons
                        setDefaultPageSize={table.setDefaultPageSize()}
                        setDefaultSortOrder={table.setDefaultSortOrder()}
                        scroll={{ y: 400, x: 500 }}
                        rowSelectionType={'checkbox'}
                        rowSelectionModal={setSelectedValuesFuncAdd as any}
                        fetchWhenChange={refreshTable}
                        setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        columns={[
                            {
                                title: geti18nText('payrollBrut.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('payrollBrut.table.column.employee'),
                                dataIndex:
                                    "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employment_record_id as varchar),'')) ",
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                                render: (person: any, record: any) => {
                                    if (record.person) {
                                        if (record.person && record.employmentRecordId) {
                                            return (
                                                record.person.firstName +
                                                ' ' +
                                                record.person.lastName +
                                                ' (' +
                                                record.employmentRecordId +
                                                ')'
                                            );
                                        } else {
                                            return record.person.firstName + ' ' + record.person.lastName;
                                        }
                                    }
                                },
                            },
                        ]}
                    />
                </Modal>
            )}
        </>
    );

    const getTabs: any = (
        <>
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <Tabs.TabPane tab={geti18nText('pointAdditionDecision.tab.general')} key="1">
                    {getGeneralEditData}
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('pointAdditionDecision.tab.details')} key="2" disabled={disabledTabs()}>
                    {getEmployees}
                </Tabs.TabPane>
            </Tabs>
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={
                isPersonal() || getEmployeeId()
                    ? CONSTANTS_REQ.POINT_ADDITION_DECISION_EMPLOYEE.EDIT
                    : CONSTANTS_REQ.POINT_ADDITION_DECISION.EDIT
            }
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={
                !canCreate() ||
                (getEmployeeId() &&
                    (initialDecision?.id == selectedDecision?.id || !selectedDecision || selectedDecision?.id < 0))
            }
            hideActivationButtons={!canCreate()}
            setIsCreate={setIsCreate}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            {...props}
            normalize={(values: any) => {
                if (getEmployeeId()) {
                    if (values.pointAdditionDecision) {
                        values.pointAdditionDecision = getSearchFormat(values.pointAdditionDecision);
                        if (values.pointAdditionDecision.id < 1) {
                            delete values.pointAdditionDecision;
                        }
                    }
                    return {
                        id: dataForm,
                        pointAdditionDecision: values.pointAdditionDecision,
                        employee: { id: getEmployeeId() },
                        active: values.active === false ? false : true,
                    };
                } else {
                    if (values.payrollBrut) {
                        values.payrollBrut = getSearchFormat(values.payrollBrut);
                        if (values.payrollBrut.id < 1) {
                            delete values.payrollBrut;
                        }
                    }
                    if (values.businessUnit) {
                        values.businessUnit = getSearchFormat(values.businessUnit);
                        if (values.businessUnit.id < 1) {
                            delete values.businessUnit;
                        }
                    }
                    if (values.replacementDecision) {
                        values.replacementDecision = getSearchFormat(values.replacementDecision);
                        if (values.replacementDecision.id < 1) {
                            delete values.replacementDecision;
                        }
                    }
                    if (values.validFrom) {
                        values.validFrom = moment(values.validFrom).format('yyyy-MM-DD HH:mm');
                    }

                    values.workRelated = workRelated;
                    if (files.length > 0 && files[0]) {
                        values.fileId = files[0].id;
                    }
                    if (dataForm) {
                        values.updatedBy = { id: NySession.getUser().id };
                    } else {
                        values.createdBy = { id: NySession.getUser().id };
                    }
                    delete values.file;
                    delete values.currency;
                    delete values.created;
                    delete values.updated;

                    if (dataForm) {
                        values.updatedBy = values.updatedBy ? values.updatedBy : { id: NySession.getUser().id };
                        values.createdBy = createdByUser;
                    } else {
                        values.createdBy = values.createdBy ? values.createdBy : { id: NySession.getUser().id };
                    }

                    return values;
                }
            }}
        >
            {onlySelectData() ? getSelectData : getEmployeeId() ? getGeneralEditData : getTabs}
        </NyDataEdit>
    );
};

export default PointAdditionDecisionEdit;
