import { InfoCircleTwoTone } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Divider, Switch, Table, Tooltip, Typography } from 'antd';
import _, { round } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { errorNotification, numberFormat } from '../../utils/Utils';
import AdditionalInfo from '../additional-info';
import { wageIcon, wageIconSmall } from '../travel-warrant-icons';
import TravelWarrantWageRecapitulationEdit from './edit';

const { Title, Text } = Typography;

const TravelWarrantWageRecapitulationTable = ({
    id,
    refresh,
    setRefresh,
    printPdf,
    travelWarrant,
    hideDivider = false,
    canEditWage = false,
    isWizard = false,
    disabledSwitches = false,
    showTaxable = false,
    disabled = false,
}: any) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;
    const enTravelWageType = useEnum('TRAVEL_WAGE_TYPE');
    const paymentInDeclaredCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY', true);
    const [wageRecapitulationModalVisible, setWageRecapitulationModalVisible] = useState<boolean>(false);
    const [wageRecapitulation, setWageRecapitulation] = useState<any>(null);
    const [amounts, setAmounts] = useState(0);
    const [defaultCurrency, setDefaultCurrency] = useState(null);

    const isNonStandardWage = (record: any) => {
        return record?.travelWage?.type !== enTravelWageType.NON_STANDARD;
    };

    const isManuallyAdded = (record: any) => (
        <>
            {record && (record?.createdBy || record?.updatedBy) && (
                <AdditionalInfo
                    created={record?.created}
                    createdBy={record?.createdBy}
                    updated={record?.updated}
                    updatedBy={record?.updatedBy}
                    placement={'topRight'}
                    buttonStyle={{ color: 'yellow', marginLeft: '5px' }}
                />
            )}
        </>
    );

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id, refresh]);

    const fetchData = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.RECAPITULATION_LIST_NEW + '/' + id).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setData(result.data);
                    }
                }
                setLoading(false);
            }
        );
    };

    const changeStatus = (item: any) => {
        setLoading(true);
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TRAVEL_WARRANT_WAGE_EXPENSES.CHANGE_STATUS + '/' + item.id,
            undefined,
            {
                id: item.id,
            }
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (setRefresh) {
                    setRefresh((refresh: any) => refresh + 1);
                }
            } else {
                setLoading(false);
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const columns: any = [
        ...[
            // {
            //     title: geti18nText('travelWarrantWageRecapitulation.date'),
            //     dataIndex: 'date',
            //     key: 'date',
            //     width: '10%',
            //     render: (text: any, record: any) => {
            //         return (
            //             <>
            //                 {record.date && (
            //                     <>
            //                         {isNonStandardWage(record) ? (
            //                             getDateFormat(record.date, 'DD.MM.YYYY')
            //                         ) : (
            //                             <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
            //                                 {getDateFormat(record.date, 'DD.MM.YYYY')}
            //                             </span>
            //                         )}
            //                     </>
            //                 )}
            //                 {isManuallyAdded(record)}
            //             </>
            //         );
            //     },
            // },
            {
                title: geti18nText('travelWarrantWageRecapitulation.index'),
                width: '10%',
                dataIndex: 'index',
                render: (text: any, record: any, index: any) => {
                    return ++index;
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.day'),
                dataIndex: 'wageNumber',
                key: 'wageNumber',
                width: '10%',
                render: (wageNumber: any, record: any) => {
                    if (isNonStandardWage(record)) {
                        return <span>{numberFormat(wageNumber ?? 0)}</span>;
                    } else
                        return (
                            <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {numberFormat(wageNumber ?? 0)}
                            </span>
                        );
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.travelWageName'),
                dataIndex: ['travelWage', 'name'],
                key: 'travelWageName',
                render: (text: any, record: any) => {
                    if (record.travelWage && record.travelWage.name) {
                        if (isNonStandardWage(record)) {
                            if (record.taxable) {
                                return (
                                    <>
                                        {record.travelWage.name} {wageIconSmall}
                                    </>
                                );
                            } else {
                                return record.travelWage.name;
                            }
                        } else {
                            if (record.taxable) {
                                return (
                                    <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                        {record.travelWage.name} {wageIconSmall}
                                    </span>
                                );
                            } else {
                                return (
                                    <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                        {record.travelWage.name}
                                    </span>
                                );
                            }
                        }
                    }
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.travelWageAmount'),
                dataIndex: ['travelWage', 'amount'],
                key: 'travelWageAmount',
                align: 'right',
                width: '9%',
                render: (travelWage: any, record: any) => {
                    if (isNonStandardWage(record)) {
                        return <span style={{ float: 'right' }}>{numberFormat(record.travelWage?.amount ?? 0)}</span>;
                    } else
                        return (
                            <span style={{ float: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {numberFormat(record.travelWage?.amount ?? 0)}
                            </span>
                        );
                },
            },
            {
                title: (
                    <div style={{ display: 'grid' }}>
                        <div style={{ justifySelf: 'center' }}>
                            {geti18nText('travelWarrantWageRecapitulation.wageExpensesName')}
                            <Tooltip title={geti18nText('travelWarrantWageRecapitulation.wageExpensesName.info')}>
                                <InfoCircleTwoTone style={{ marginLeft: '5px' }} />
                            </Tooltip>
                        </div>
                    </div>
                ),
                dataIndex: 'travelWarrantWageRecapitulation',
                key: 'travelWarrantWageRecapitulation',
                render: (wageExpensesName: any, record: any) => {
                    if (
                        record.travelWarrantWageExpensesList !== null &&
                        record.travelWarrantWageExpensesList !== undefined &&
                        record.travelWarrantWageExpensesList.length > 0
                    ) {
                        return (
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                {record.travelWarrantWageExpensesList.map((element: any) => {
                                    return (
                                        <Switch
                                            style={{ marginRight: '5px' }}
                                            checkedChildren={element?.travelWageExpenses?.name}
                                            unCheckedChildren={element?.travelWageExpenses?.name}
                                            checked={element?.active}
                                            onChange={() => changeStatus(element)}
                                            disabled={disabledSwitches || (!isWizard && !canEditWage) || disabled}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                    return '';
                },
            },
            {
                title: paymentInDeclaredCurrency
                    ? geti18nText('travelWarrantWage.table.column.amountInCurrency')
                    : geti18nText('travelWarrantWageRecapitulation.amount'),
                key: 'amountInCurrency',
                dataIndex: 'amountInCurrency',
                align: 'right',
                width: '7%',
                render: (amount: any, record: any) => {
                    if (isNonStandardWage(record)) {
                        return <span style={{ float: 'right' }}>{numberFormat(record.amountInCurrency ?? 0)}</span>;
                    } else
                        return (
                            <span style={{ float: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {numberFormat(record.amountInCurrency ?? 0)}
                            </span>
                        );
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.travelWageCurrency'),
                dataIndex: 'travelWageCurrency',
                key: 'travelWageCurrency',
                width: '5%',
                render: (text: any, record: any) => {
                    const travelWageCurrency = record?.currency.isoCode;
                    if (isNonStandardWage(record)) {
                        return <span>{travelWageCurrency}</span>;
                    } else
                        return <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>{travelWageCurrency}</span>;
                },
            },
        ],
        ...(paymentInDeclaredCurrency
            ? [
                  {
                      title: geti18nText('travelWarrantRecapitulation.table.exchangeRate'),
                      key: 'exchangeRate',
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      width: '7%',
                      render: (amount: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return (
                                  <span style={{ float: 'right', display: 'inline' }}>
                                      {numberFormat(record.exchangeRate ?? 0, 5)}
                                  </span>
                              );
                          } else
                              return (
                                  <span
                                      style={{
                                          float: 'right',
                                          display: 'inline',
                                          color: isDarkTheme ? '#7ce7ac' : '#23c76c',
                                      }}
                                  >
                                      {numberFormat(record.exchangeRate ?? 0, 5)}
                                  </span>
                              );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWageRecapitulation.amount'),
                      key: 'amount',
                      dataIndex: 'amount',
                      align: 'right',
                      width: '8%',
                      render: (amount: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return (
                                  <span style={{ float: 'right', display: 'inline' }}>
                                      {numberFormat(record.amount ?? 0)}
                                  </span>
                              );
                          } else
                              return (
                                  <span
                                      style={{
                                          float: 'right',
                                          display: 'inline',
                                          color: isDarkTheme ? '#7ce7ac' : '#23c76c',
                                      }}
                                  >
                                      {numberFormat(record.amount ?? 0)}
                                  </span>
                              );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWageRecapitulation.travelWageCurrency'),
                      dataIndex: 'defaultCurrency',
                      key: 'defaultCurrency',
                      width: '5%',
                      render: (text: any, record: any) => {
                          const travelWageCurrency = record?.defaultCurrency?.isoCode;
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return <span>{travelWageCurrency}</span>;
                          } else
                              return (
                                  <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                      {travelWageCurrency}
                                  </span>
                              );
                      },
                  },
              ]
            : []),
    ];

    const openRecordRecapitulation = (record: any) => {
        if (!isWizard && ((record?.id && canEditWage) || !record)) {
            setWageRecapitulationModalVisible(true);
            setWageRecapitulation(record);
        }
    };

    return (
        <>
            <div style={printPdf ? { position: 'fixed', top: '0' } : {}}>
                {!hideDivider && (
                    <Divider orientation="left" plain>
                        <Title level={5}>{geti18nText('travelWarrantWageRecapitulation.title')}</Title>
                    </Divider>
                )}
                {showTaxable && (
                    <div style={{ marginBottom: '12px' }}>
                        {wageIcon}
                        <b style={{ marginLeft: '5px' }}>
                            {geti18nText('travelWarrantRecapitulation.table.taxable.wage').split('**')}
                        </b>
                    </div>
                )}
                <Table
                    size="small"
                    bordered
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: (event) => {
                                openRecordRecapitulation(record);
                            },
                        };
                    }}
                    style={{
                        maxHeight: printPdf ? undefined : '260px',
                        overflowY: printPdf ? 'hidden' : 'auto',
                        maxWidth: printPdf ? '1050px' : undefined,
                    }}
                    sticky
                    summary={(data: any) => {
                        const groupedList: any = _(data)
                            .filter(
                                (wage: any) =>
                                    wage?.travelWage.currency != null &&
                                    wage?.travelWage.currency != undefined &&
                                    wage?.travelWage.currency != '' &&
                                    isNonStandardWage(wage)
                            )
                            .groupBy((x) => x?.currency?.isoCode)
                            .map((value: any, key: any) => {
                                let totalAmount = 0;
                                let totalAmountInCurrency = 0;
                                let defaultCurrency = null;
                                let exchangeRate = 0;
                                value.map((val: any) => {
                                    exchangeRate = val.exchangeRate;
                                    totalAmount = totalAmount + (val.amount ?? 0);
                                    totalAmountInCurrency = totalAmountInCurrency + (val.amountInCurrency ?? 0);
                                    setDefaultCurrency(val.defaultCurrency.isoCode);
                                });
                                return {
                                    currency: key,
                                    exchangeRate: exchangeRate,
                                    totalAmount: round(totalAmount, 2),
                                    totalAmountInCurrency: round(totalAmountInCurrency, 2),
                                };
                            })
                            .value();

                        const totalAmountSum = groupedList.reduce(
                            (sum: any, item: any) => sum + (item.totalAmount ?? 0),
                            0
                        );
                        setAmounts(round(totalAmountSum, 2));

                        return (
                            <>
                                {groupedList.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <Table.Summary.Row key={index}>
                                                {index == 0 && (
                                                    <Table.Summary.Cell
                                                        colSpan={5}
                                                        index={0}
                                                        rowSpan={groupedList.length}
                                                    >
                                                        <strong style={{ position: 'absolute', top: 4, right: 4 }}>
                                                            {index == 0 ? geti18nText('app.default.total') : ''}
                                                        </strong>
                                                    </Table.Summary.Cell>
                                                )}

                                                <Table.Summary.Cell index={1}>
                                                    <Text style={{ float: 'right' }} strong>
                                                        {numberFormat(item.totalAmountInCurrency)}
                                                    </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Text style={{ float: 'left' }} strong>
                                                        {item.currency}
                                                    </Text>
                                                </Table.Summary.Cell>
                                                {paymentInDeclaredCurrency && (
                                                    <Table.Summary.Cell index={1}>
                                                        <Text style={{ float: 'right' }} strong>
                                                            {numberFormat(item.exchangeRate ?? 0, 5)}
                                                        </Text>
                                                    </Table.Summary.Cell>
                                                )}
                                                {paymentInDeclaredCurrency && (
                                                    <Table.Summary.Cell index={1}>
                                                        <Text style={{ float: 'right' }} strong>
                                                            {numberFormat(item.totalAmount)}
                                                        </Text>
                                                    </Table.Summary.Cell>
                                                )}
                                                {paymentInDeclaredCurrency && (
                                                    <Table.Summary.Cell index={4}>
                                                        <Text style={{ float: 'left' }} strong>
                                                            {defaultCurrency}
                                                        </Text>
                                                    </Table.Summary.Cell>
                                                )}
                                            </Table.Summary.Row>
                                        </>
                                    );
                                })}
                                {/* {paymentInDeclaredCurrency && (
                                    <Table.Summary.Row>
                                        <>
                                            <Table.Summary.Cell colSpan={8} index={0}>
                                                <strong style={{ float: 'right', marginRight: '2px' }}>
                                                    {//   {geti18nText('app.default.total')} //}
                                                </strong>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell index={3}>
                                                <Text style={{ float: 'right' }} strong>
                                                    {numberFormat(amounts)}
                                                </Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}>
                                                <Text style={{ float: 'left' }} strong>
                                                    {defaultCurrency}
                                                </Text>
                                            </Table.Summary.Cell>
                                        </>
                                    </Table.Summary.Row>
                                )} */}
                            </>
                        );
                    }}
                />
                <Button
                    style={{ float: 'right', margin: '10px 10px 0px 0px' }}
                    key="submit"
                    type="primary"
                    hidden={!canEditWage || isWizard}
                    disabled={disabled}
                    onClick={() => openRecordRecapitulation(null)}
                >
                    {geti18nText('app.default.button.add')}
                </Button>
            </div>
            {wageRecapitulationModalVisible && (
                <TravelWarrantWageRecapitulationEdit
                    value={wageRecapitulation}
                    setValue={setWageRecapitulation}
                    visible={wageRecapitulationModalVisible}
                    setVisible={setWageRecapitulationModalVisible}
                    canEditWage={canEditWage && !disabled}
                    setRefreshTable={setRefresh}
                    travelWarrantId={travelWarrant.id}
                    travelwarrantStartDate={travelWarrant.startDate}
                    travelwarrantEndDate={travelWarrant.endDate}
                />
            )}
        </>
    );
};

export default TravelWarrantWageRecapitulationTable;
