import { NyDataEdit, NyInputNumber, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption, getEmployeeSelectOption, getSearchFormat } from '../../../../../utils/Utils';
import UserIndex from '../../../../tasks/views/user';
import { GetEnum } from '../../../../../utils/Enums';

const ContractNotificationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('contract.notification.type.edit.title'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const enContractNotificationType = GetEnum({ enumName: 'CONTRACT_NOTIFICATION_RECORD_TYPE' });

    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.user) {
            dataForm.user = getEmployeeSelectOption(dataForm.user);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.CONTRACT_NOTIFICATION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.user) {
                    values.user = getSearchFormat(values.user);
                }
                values.recordType = enContractNotificationType.TYPE;
                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('contract.notification.type.edit.days')}
                        name="day"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber ref={focusInput} style={{ width: '100%' }} min={1} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('contract.notification.type.edit.user')}
                        name="user"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            setDefaultFilterValue={setDefaultFilterValue}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ContractNotificationEdit;
