import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Dropdown, Menu, Modal, Radio } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import AssetFlowSpecificationReportModal from '../../../../../components/asset-flow-specification-report-modal';
import NyErrorLogModal from '../../../../../components/error-log-modal';
import ImportCSVModal from '../../../../../components/import-csv-modal';
import NyHistory from '../../../../../components/ny-history/NyHistory';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookSimple,
} from '../../../../../utils/Filters';
import { getEnumArrayStatus, isAdmin, numberFormat } from '../../../../../utils/Utils';
import AssetFlowSummaryReportModal from './AssetFlowSummaryReportModal';

const FixedAssetIndex = ({ showRowSelection, rowSelectionType, rowSelectionModal, setDefaultSelectedRowKeys }: any) => {
    const table = useTableSettings();
    const [errorLogModalData, setErrorLogModalData] = useState(undefined);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const [loading, setLoading] = useState(false);
    const [recordId, setRecordId] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [historyType, setHistoryType] = useState<any>(4);
    const [assetFlowSpecificationReportModalVisible, setAssetFlowSpecificationReportModalVisible] =
        useState<boolean>(false);

    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useHelper('assets/codebooks/fixedAsset');

    const canCreate = () => {
        return FixedAssetRights.canCreateFixedAssetCodebooks();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCsvExportFixedAssetCodebook();
    };

    const columns = [
        {
            title: geti18nText('fixed.asset.table.column.id'),
            dataIndex: 'id',
            fixed: 'left',
            width: 100,
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('fixed.asset.table.column.inventoryNumber'),
            dataIndex: 'serialNumber',
            fixed: 'left',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('fixed.asset.table.column.name'),
            dataIndex: ['assetModel', 'name'],
            fixed: 'left',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('fixed.asset.table.column.serialNumber'),
            dataIndex: 'serial_number_2',
            fixed: 'left',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (serialNumber2: any, record: any) => {
                return record && record.serialNumber2 ? record.serialNumber2 : '';
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationRate'),
            dataIndex: ['assetDepreciationGroup', 'name'],
            width: '170px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH,
                'assetDepreciationGroup.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
            render: (assetDepreciationGroup: any, record: any) => {
                if (record.assetDepreciationGroup) {
                    if (
                        record.assetDepreciationGroup &&
                        assetDepreciationGroup.name &&
                        assetDepreciationGroup.depreciationTime
                    ) {
                        return (
                            record.assetDepreciationGroup.name +
                            ' (' +
                            record.assetDepreciationGroup.depreciationTime +
                            ' ' +
                            geti18nText('fixed.asset.months') +
                            ')'
                        );
                    } else {
                        return record.assetDepreciationGroup.name;
                    }
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.aquisitionCostValue'),
            dataIndex: 'aquisitionCostValue',
            width: '170px',
            sorter: (a: any, b: any) => {},
            render: (aquisitionCostValue: any) => {
                if (aquisitionCostValue) {
                    return numberFormat(aquisitionCostValue);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('fixed.asset.table.column.valueIncrease'),
            dataIndex: 'valueIncrease',
            width: '170px',
            sorter: (a: any, b: any) => {},
            render: (valueIncrease: any) => {
                if (valueIncrease) {
                    return numberFormat(valueIncrease);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('fixed.asset.table.column.valueDecrease'),
            dataIndex: 'valueDecrease',
            width: '170px',
            sorter: (a: any, b: any) => {},
            render: (valueDecrease: any) => {
                if (valueDecrease) {
                    return numberFormat(valueDecrease);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('fixed.asset.table.column.acumulatedDepreciation'),
            dataIndex: 'accumulatedDepreciation',
            width: '190px',
            sorter: (a: any, b: any) => {},
            render: (accumulatedDepreciation: any) => {
                if (accumulatedDepreciation) {
                    return numberFormat(accumulatedDepreciation);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('fixed.asset.table.column.netBookValue'),
            dataIndex: 'netBookValue',
            width: '170px',
            sorter: (a: any, b: any) => {},
            render: (netBookValue: any) => {
                if (netBookValue) {
                    return numberFormat(netBookValue);
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationStartType'),
            dataIndex: 'depreciationStartType',
            width: '170px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.depreciationStartType !== undefined) {
                    return geti18nText('app.enum.DEPRECIATION_START_TYPE.' + record.depreciationStartType);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('DEPRECIATION_START_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('fixed.asset.table.column.assetDepreciationStatus'),
            dataIndex: 'assetDepreciationStatus',
            width: '200px',
            render: (text: any, record: any) => {
                if (record.assetDepreciationStatus !== undefined) {
                    return geti18nText('app.enum.ASSET_DEPRECIATION_STATUS.' + record.assetDepreciationStatus);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('ASSET_DEPRECIATION_STATUS'), 'in', undefined),
        },
        {
            title: geti18nText('fixed.asset.table.column.acquisitionDate'),
            dataIndex: 'aquisitionDate',
            width: '130px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.aquisitionDate) {
                    return moment(record.aquisitionDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationStartDate'),
            dataIndex: 'depreciationStartDate',
            width: '170px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.depreciationStartDate) {
                    return moment(record.depreciationStartDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.releaseDate'),
            dataIndex: 'releaseDate',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.releaseDate) {
                    return moment(record.releaseDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.returnDate'),
            dataIndex: 'returnDate',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.returnDate) {
                    return moment(record.returnDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationEndDate'),
            dataIndex: 'depreciationEndDate',
            width: '170px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.depreciationEndDate) {
                    return moment(record.depreciationEndDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.writeOffDate'),
            dataIndex: 'writeOfDate',
            width: '160px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.writeOfDate) {
                    return moment(record.writeOfDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.valueDepreciationDate'),
            dataIndex: 'expendedDate',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.expendedDate) {
                    return moment(record.expendedDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.company'),
            dataIndex: ['company', 'name'],
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COMPANY.SEARCH, 'company.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
            render: (company: any, record: any) => {
                if (record.company) {
                    if (record.company && record.company.name) {
                        return record.company.name;
                    }
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return (
                        <>
                            {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                            {record.employee.employmentRecordId && ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.organisationUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            width: '150px',
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.location'),
            dataIndex: ['room', 'name'],
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ROOM.SEARCH, 'room.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('fixed.asset.table.column.item'),
            dataIndex: ['item', 'code'],
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('fixed.asset.table.column.active'),
            dataIndex: 'active',
            width: '90px',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <>
                            <RowAction
                                component={'FixedAsset'}
                                record={record}
                                setAssetFlowSpecificationReportModalVisible={(value: boolean) => {
                                    setAssetFlowSpecificationReportModalVisible(value);
                                    setRecordId(record.id);
                                }}
                                setHistoryModalVisible={(value: boolean) => {
                                    setHistoryModalVisible(value);
                                    setRecordId(record.id);
                                }}
                            />
                        </>
                    );
                }
            },
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <AssetFlowSummaryReportModal
                            reportType={'ASSET_FLOW_SUMMARY'}
                            reportTitle={'fixed.asset.report.assetFlowSummary'}
                            reportFileName={geti18nText(
                                'app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_FLOW_SUMMARY
                            )}
                            url={CONSTANTS_REQ.REPORT.ASSET_FLOW_SUMMARY}
                        />
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <ImportCSVModal
                            url={CONSTANTS_REQ.ASSET.IMPORT_CSV}
                            onSave={onSave}
                            disabled={loading}
                            //customInfoMessage={geti18nText('assetImportCSV.upload.info')} -- za buduću doradu
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="asset-fixed-asset"
                url={CONSTANTS_REQ.ASSET.LIST}
                addNewButtonText={geti18nText('fixed.asset.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                scroll={{ x: 2500, y: '59vh' }}
                hideNewButton={!canCreate()}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                shortcuts={true}
                addedButtons={addedButtons}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.assets.fixed.assets')}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
                headerTitle={geti18nText('menu.assets.fixed.assets')}
            />
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
            <AssetFlowSpecificationReportModal
                visible={assetFlowSpecificationReportModalVisible}
                setVisible={setAssetFlowSpecificationReportModalVisible}
                assetId={recordId}
                reportType={'ASSET_FLOW_SPECIFICATION'}
                reportFileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_FLOW_SPECIFICATION)}
                url={CONSTANTS_REQ.REPORT.ASSET_FLOW_SPECIFICATION}
            />
            <Modal
                title={geti18nText('asset.history.info')}
                open={historyModalVisible}
                onCancel={() => {
                    setHistoryModalVisible(false);
                }}
                okButtonProps={{ hidden: true }}
                maskClosable={false}
                width={1200}
            >
                <Radio.Group
                    value={historyType}
                    buttonStyle="solid"
                    optionType="button"
                    onChange={(e: any) => {
                        setHistoryType(e.target.value);
                    }}
                    style={{ marginBottom: '8px' }}
                >
                    <Radio.Button value={4}>{geti18nText('asset.history.fixed.asset.changes.info')}</Radio.Button>
                    {isAdmin() && (
                        <Radio.Button value={3}>{geti18nText('asset.history.all.changes.info')}</Radio.Button>
                    )}
                </Radio.Group>
                <NyHistory id={recordId} link={'fixed.asset'} type={historyType} />
            </Modal>
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            aquisitionCostValue: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            valueIncrease: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            valueDecrease: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            accumulatedDepreciation: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            netBookValue: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            aquisitionDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            depreciationStartDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            releaseDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            depreciationEndDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            returnDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            expendedDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            writeOfDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            assetDepreciationStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_DEPRECIATION_STATUS.' + value);
            },
        },
        {
            aquisitionCostValue: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            valueIncrease: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            valueDecrease: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            accumulatedDepreciation: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            netBookValue: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('fixed.asset.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('fixed.asset.table.column.name'),
            dataIndex: ['assetModel', 'name'],
        },
        {
            title: geti18nText('fixed.asset.table.column.inventoryNumber'),
            dataIndex: 'serialNumber',
        },
        {
            title: geti18nText('fixed.asset.table.column.assetDepreciationStatus'),
            dataIndex: 'assetDepreciationStatus',
        },
        {
            title: geti18nText('fixed.asset.table.column.item'),
            dataIndex: ['item', 'code'],
        },
        {
            title: geti18nText('fixed.asset.table.column.employee'),
            dataIndex: ['person.firstName'],
        },
        {
            title: geti18nText('fixed.asset.table.column.location'),
            dataIndex: ['room', 'name'],
        },
        {
            title: geti18nText('fixed.asset.table.column.organisationUnit'),
            dataIndex: ['businessUnit', 'name'],
        },
        {
            title: geti18nText('fixed.asset.table.column.company'),
            dataIndex: ['company', 'name'],
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationRate'),
            dataIndex: ['assetDepreciationGroup', 'name'],
        },
        {
            title: geti18nText('fixed.asset.table.column.aquisitionCostValue'),
            dataIndex: 'aquisitionCostValue',
        },
        {
            title: geti18nText('fixed.asset.table.column.valueIncrease'),
            dataIndex: 'valueIncrease',
        },
        {
            title: geti18nText('fixed.asset.table.column.valueDecrease'),
            dataIndex: 'valueDecrease',
        },
        {
            title: geti18nText('fixed.asset.table.column.acumulatedDepreciation'),
            dataIndex: 'accumulatedDepreciation',
        },
        {
            title: geti18nText('fixed.asset.table.column.netBookValue'),
            dataIndex: 'netBookValue',
        },
        {
            title: geti18nText('fixed.asset.table.column.acquisitionDate'),
            dataIndex: 'aquisitionDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationStartDate'),
            dataIndex: 'depreciationStartDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.releaseDate'),
            dataIndex: 'releaseDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.depreciationEndDate'),
            dataIndex: 'depreciationEndDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.returnDate'),
            dataIndex: 'returnDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.valueDepreciationDate'),
            dataIndex: 'expendedDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.writeOffDate'),
            dataIndex: 'writeOfDate',
        },
        {
            title: geti18nText('fixed.asset.table.column.active'),
            dataIndex: 'active',
        },
    ];
};
export default FixedAssetIndex;
