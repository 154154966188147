import { geti18nText, NyDatePicker, NyInputNumber, NySearchField, NySpinner } from '@nybble/nyreact';
import { Alert, Checkbox, Col, Collapse, Divider, Form, Input, Radio, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import NyNoteModal from '../../../../components/note-modal';
import TravelWarrantAdditionalInfoEdit from '../../../../components/travel-warrant-additional-info/edit';
import TravelWarrantCo2Index from '../../../../components/travel-warrant-co2';
import TravelWarrantMapModal from '../../../../components/travel-warrant-map/TravelWarrantMapModal';
import useEnum from '../../../../hooks/useEnum';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEnumArrayStatus,
    onKeyDownTextAreaCustom,
    setEnumFormat,
    userIsNotificationRecipient,
} from '../../../../utils/Utils';
import { validateObjectId } from '../../../../utils/Validation';
import CurrencySearch from '../../../administration/views/currency/search';
import EmployeeIndex from '../../../human/views/employee';
import TravelEventNumberSearch from '../travel-event-number/search';
import TravelVehiclePersonalIndex from '../travel-vehicle-personal';
import TravelVehiclePersonalEdit from '../travel-vehicle-personal/edit';
import CostCenterSearch from '../../../accounting/views/cost-center/search';
import CostObjectSearch from '../../../accounting/views/cost-object/search';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { AdministrationRights } from '../../../../rights/administrationRights';
import TextArea from 'antd/lib/input/TextArea';

const { Panel } = Collapse;

const GeneralEditData = ({
    isAdmin,
    leftCollapseActiveKeys,
    setLeftCollapseActiveKeys,
    currencyOnChange,
    paymentOnChange,
    calculateTotalAdvancePayment,
    note,
    disabled,
    canCreate,
    onChangeDestination,
    waypoints,
    setWaypoints,
    maxWaypointsKey,
    setMaxWaypointsKey,
    destination,
    setDestination,
    form,
    setTravelWarrantPlans,
    accountingCostCenterRequired,
    accountingCostObjectRequired,
    showAccountingCostCenter,
    showAccountingCostObject,
    onChangeCostCenter,
    onChangeCostObject,
    disabledTable,
    setDefaultFilterValue,
    setDefaultFilterValuePersonalOrOfficial,
    status,
    employeeOnChange,
    moduleAccessRight,
    onTravelVehicleChange,
    onTravelVehicle2Change,
    isPlane = false,
    onStartDateChange,
    onEndDateChange,
    disabledStartDate,
    disabledendDate,
    dataForm = null,
    travelVehicle,
    isPersonalOrOfficial,
    showAnotherVehicle,
    setShowAnotherVehicle,
    vehicle2Enabled = false,
    warrantDateOnChange,
    advance,
    vehicle,
    vehicle2,
    statusOnChange,
    focusInputReport,
    canView,
    canEdit,
    canEditPersonal,
    getTravelWarrantHistory,
    rerenderKey,
    employeeId,
    loadingAdditional,
    dataAdditional,
    destinationsAdditional,
    destinationAdditional,
    onChangeDestinationAdditional,
    itemAdditional,
    formAdditional,
    needTransport,
    travelVehicleType,
    isDisabledPaidWarrant,
    getDefaultStartingPoint,
    showCheckboxesDestinationName = true,
    initWaypoints = null,
    setInitWaypoints,
    returnWaypoints,
    setReturnWaypoints,
    returnRoute = false,

    returnIntermediateWaypointsInit,
}: any) => {
    const { employee } = useSelector((state: RootState) => state.employee);
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const enableTravelWarrantChangeOnStatusPaid: any = useApplicationSetting(
        'TRAVEL_WARRANT_ENABLE_CHANGE_ON_STATUS_PAID'
    );

    const showCo2Component = () => {
        return TravelWarrantRights.canViewCo2(status, employee, travelWarrantStatusEnum, notificationType);
    };

    const setEmployeeFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'moduleAccessRightReadOnly', condition: 'equals', value: 1 },
        ];
    };

    const canSaveAdmin = isAdmin || canEditPersonal;

    const canEditStatusAndCalculationDatePaid = () => {
        return (
            AdministrationRights.isAdmin() ||
            (status == travelWarrantStatusEnum.PAID && enableTravelWarrantChangeOnStatusPaid == 1 && canSaveAdmin)
        );
    };

    return (
        <React.Fragment>
            <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={form}>
                <Row gutter={24}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={leftCollapseActiveKeys}
                            onChange={(key: any) => {
                                setLeftCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={
                                    <>
                                        {geti18nText('travelWarrant.panel.generalData.personal')}
                                        {getTravelWarrantHistory(status)}
                                    </>
                                }
                                key="1"
                                extra={
                                    <NyNoteModal
                                        note={note}
                                        disabled={disabled || !canCreate || isDisabledPaidWarrant}
                                    />
                                }
                            >
                                {isAdmin ||
                                    (status > travelWarrantStatusEnum.DRAFT && (
                                        <Row gutter={24}>
                                            {dataForm != null && dataForm != undefined && dataForm > 0 ? (
                                                <Col
                                                    span={12}
                                                    style={
                                                        status > travelWarrantStatusEnum.DRAFT
                                                            ? {}
                                                            : { display: 'none' }
                                                    }
                                                >
                                                    <Form.Item
                                                        label={geti18nText('travelWarrant.edit.ordinal')}
                                                        name="ordinal"
                                                    >
                                                        <Input disabled={disabledTable} style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                            ) : (
                                                <div></div>
                                            )}
                                            {isAdmin ? (
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={geti18nText('travelWarrant.edit.warrantDate')}
                                                        name="warrantDate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: geti18nText('app.default.required'),
                                                            },
                                                        ]}
                                                        initialValue={moment()}
                                                    >
                                                        <NyDatePicker
                                                            onChange={warrantDateOnChange}
                                                            style={{ width: '100%' }}
                                                            nyTestId="warrant-date"
                                                            autoFocus={true}
                                                            disabled={disabled || !canCreate}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ) : (
                                                <div></div>
                                            )}
                                        </Row>
                                    ))}
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="destinationJson" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="approvedBy" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="approved" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="createdBy" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.employee')}
                                            name="employee"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.EMPLOYEE.TRAVEL_SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                    businessUnit: 'businessUnit',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                customItemNameLabel={'firstName lastName'}
                                                SearchPopupComponent={
                                                    <EmployeeIndex
                                                        customFilter={setEmployeeFilterValue}
                                                        disabled={true}
                                                        filteredForTravel
                                                        parentKey="travel-warrant-general-edit"
                                                    />
                                                }
                                                nyTestId="employee"
                                                disabled={
                                                    disabledTable ||
                                                    (moduleAccessRight == null &&
                                                        !moduleAccessRight.includes('TRAVEL_WARRANT'))
                                                }
                                                onChange={employeeOnChange}
                                                renderOption={customEmployeeRenderOption}
                                                order="person.last_name, person.first_name"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.vocation')}
                                            name="employeeVocation"
                                        >
                                            <Input disabled={disabledTable}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrant.edit.status')}
                                            name="status"
                                            initialValue={setEnumFormat(
                                                'TRAVEL_WARRANT_STATUS',
                                                travelWarrantStatusEnum.WAITING_FOR_APPROVAL
                                            )}
                                            hidden={
                                                !(
                                                    canEdit ||
                                                    userIsNotificationRecipient(
                                                        employee,
                                                        notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT
                                                    )
                                                )
                                            }
                                        >
                                            <NySearchField
                                                options={getEnumArrayStatus('TRAVEL_WARRANT_STATUS').map((s: any) => {
                                                    if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                                                        return s.id !== travelWarrantStatusEnum.WAITING_FOR_APPROVAL &&
                                                            s.id !== travelWarrantStatusEnum.APPROVED &&
                                                            s.id !== travelWarrantStatusEnum.REJECTED
                                                            ? { ...s, disabled: true }
                                                            : s;
                                                    } else if (canEditPersonal && !canEdit) {
                                                        return s.id !== travelWarrantStatusEnum.REPORT_APPROVED &&
                                                            s.id !== travelWarrantStatusEnum.CALCULATED &&
                                                            s.id !== travelWarrantStatusEnum.NOT_CALCULATED
                                                            ? { ...s, disabled: true }
                                                            : s;
                                                    } else {
                                                        return s.id == travelWarrantStatusEnum.DRAFT
                                                            ? { ...s, disabled: true }
                                                            : s;
                                                    }
                                                })}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                nyTestId="travel-warrant-status"
                                                className=""
                                                style={{ width: '100%' }}
                                                disabled={
                                                    (!canEdit && !canEditPersonal) ||
                                                    !canEditStatusAndCalculationDatePaid()
                                                }
                                                onChange={statusOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    {showAccountingCostCenter && (
                                        <Col span={12}>
                                            <CostCenterSearch
                                                label={geti18nText('travelWarrant.edit.cost.center')}
                                                name="accountingCostCenter"
                                                readonly={true}
                                                onChange={(data: any) => {
                                                    onChangeCostCenter(data);
                                                }}
                                                disabled={disabledTable}
                                                required={accountingCostCenterRequired}
                                                validator={validateObjectId}
                                            />
                                        </Col>
                                    )}
                                    {showAccountingCostObject && (
                                        <Col span={12}>
                                            <CostObjectSearch
                                                onChange={(data: any) => {
                                                    onChangeCostObject(data);
                                                }}
                                                validator={validateObjectId}
                                                required={accountingCostObjectRequired}
                                                disabled={disabledTable}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.startDate')}
                                            name="startDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={moment()}
                                        >
                                            <NyDatePicker
                                                disabled={disabledTable}
                                                style={{ width: '100%' }}
                                                nyTestId="start-date"
                                                autoFocus
                                                onChange={onStartDateChange}
                                                disabledDate={disabledStartDate}
                                                showTime={dataForm}
                                                format={'DD.MM.YYYY'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantWizard.table.column.endDate')}
                                            name="endDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                key={rerenderKey}
                                                disabled={
                                                    (disabledTable || !isAdmin) &&
                                                    !Object.values([
                                                        travelWarrantStatusEnum.REPORT_FIX_NEEDED,
                                                        travelWarrantStatusEnum.DRAFT,
                                                    ]).includes(status)
                                                }
                                                style={{ width: '100%' }}
                                                onChange={onEndDateChange}
                                                nyTestId="end-date"
                                                disabledDate={disabledendDate}
                                                showTime={dataForm}
                                                format={'DD.MM.YYYY'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.duration')}
                                            name="duration"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={1}
                                        >
                                            <NyInputNumber
                                                isDecimal={false}
                                                style={{ width: '100%' }}
                                                min={1}
                                                max={30}
                                                disabled
                                                nyTestId="duration"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.purposeInfo')}
                                            name="purposeInfo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                autoSize={{ minRows: 2, maxRows: 3 }}
                                                onKeyDown={onKeyDownTextAreaCustom}
                                                disabled={disabledTable}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <TravelEventNumberSearch disabled={disabledTable} />
                                    </Col>
                                </Row>
                                <Divider orientation="left" plain>
                                    {geti18nText('travelWarrantWizard.step.2')}
                                </Divider>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.travelVehicle')}
                                            name="travelVehicle"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'name',
                                                }}
                                                searchBy="name"
                                                SearchPopupComponent={
                                                    <TravelVehiclePersonalIndex
                                                        vehicleType={null}
                                                        newButtonTextPersonal={true}
                                                        sort={true}
                                                    />
                                                }
                                                AddNewModalComponent={TravelVehiclePersonalEdit}
                                                nyTestId="travel-vehicle"
                                                disabled={
                                                    (dataForm && status && status != travelWarrantStatusEnum.DRAFT) ||
                                                    disabledTable
                                                }
                                                setDefaultFilterValue={setDefaultFilterValue}
                                                onChange={onTravelVehicleChange}
                                                order="sort"
                                                orderType="asc"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        {!disabledTable ? (
                                            <div style={{ width: '100%' }}>
                                                <Form.Item
                                                    style={{ width: '90%', float: 'left' }}
                                                    label={geti18nText('travelWarrantPersonal.edit.destination')}
                                                    name="destination"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        disabled={
                                                            !(
                                                                isAdmin ||
                                                                userIsNotificationRecipient(
                                                                    employee,
                                                                    notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT
                                                                )
                                                            )
                                                        }
                                                        onChange={onChangeDestination}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ width: '10%', float: 'left' }}
                                                    label={' '}
                                                    name="destinationInput"
                                                >
                                                    <TravelWarrantMapModal
                                                        form={form}
                                                        waypoints={waypoints}
                                                        setWaypoints={setWaypoints}
                                                        maxWaypointsKey={maxWaypointsKey}
                                                        setMaxWaypointsKey={setMaxWaypointsKey}
                                                        destination={destination}
                                                        setDestination={setDestination}
                                                        setTravelWarrantPlans={setTravelWarrantPlans}
                                                        modalHeader={geti18nText(
                                                            'travelWarrantPersonal.edit.destination'
                                                        )}
                                                        index={false}
                                                        isPlane={isPlane}
                                                        getDefaultStartingPoint={getDefaultStartingPoint}
                                                        showCheckboxesDestinationName={showCheckboxesDestinationName}
                                                        initDestinationNameValues={initWaypoints}
                                                        setInitWaypoints={setInitWaypoints}
                                                        returnWaypoints={returnWaypoints}
                                                        setReturnWaypoints={setReturnWaypoints}
                                                        returnRoute={returnRoute}
                                                        returnIntermediateWaypointsInit={
                                                            returnIntermediateWaypointsInit
                                                        }
                                                    ></TravelWarrantMapModal>
                                                </Form.Item>
                                            </div>
                                        ) : (
                                            <Form.Item
                                                label={geti18nText('travelWarrantPersonal.edit.destination')}
                                                name="destination"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <Input disabled={disabledTable} onChange={onChangeDestination} />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                                {vehicle2Enabled && travelVehicle && !isPersonalOrOfficial && (
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('travel.warrant.edit.use.another.vehicle')}
                                                name="showAnotherVehicle"
                                            >
                                                <Checkbox
                                                    disabled={disabledTable}
                                                    checked={showAnotherVehicle}
                                                    onChange={(event: any) =>
                                                        setShowAnotherVehicle(event?.target.checked)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        {showAnotherVehicle && (
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('travelWarrant.edit.travelVehicle2')}
                                                    name="travelVehicle2"
                                                >
                                                    <NySearchField
                                                        disabled={disabledTable}
                                                        autoFocus
                                                        url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                                        map={{
                                                            id: 'id',
                                                            label: 'name',
                                                        }}
                                                        searchBy="name"
                                                        SearchPopupComponent={
                                                            <TravelVehiclePersonalIndex
                                                                vehicleType={[1, 2]}
                                                                newButtonTextPersonal={true}
                                                                sort={true}
                                                            />
                                                        }
                                                        AddNewModalComponent={TravelVehiclePersonalEdit}
                                                        nyTestId="travel-vehicle2"
                                                        setDefaultFilterValue={setDefaultFilterValuePersonalOrOfficial}
                                                        onChange={onTravelVehicle2Change}
                                                        order="sort"
                                                        orderType="asc"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                )}
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantWizard.table.column.other.vehicle')}
                                            name={'otherVehicle'}
                                        >
                                            <TextArea rows={3} disabled={disabledTable}></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider orientation="left" plain>
                                    {geti18nText('travelWarrantWizard.step.4')}
                                </Divider>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.advancePaymentInCurrency', [
                                                defaultCurrencyIsoCode,
                                            ])}
                                            name="advancePaymentInCurrency"
                                        >
                                            <NyInputNumber
                                                isDecimal={true}
                                                style={{ width: '100%' }}
                                                onChange={paymentOnChange}
                                                disabled={disabledTable}
                                                max={999999.99}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ display: 'none' }}>
                                        <CurrencySearch
                                            label={geti18nText('travelWarrantPersonal.edit.advancePaymentCurrency')}
                                            name="advancePaymentCurrency"
                                            required={true}
                                            onChange={currencyOnChange}
                                            nyTestId="advance-currency"
                                            readonly={true}
                                            disabled={disabledTable}
                                        />
                                    </Col>
                                </Row>
                                {isAdmin && (
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('travelWarrant.edit.advancePaymentExchangeRate')}
                                                name="advancePaymentExchangeRate"
                                            >
                                                <NyInputNumber
                                                    isDecimal={true}
                                                    onChange={calculateTotalAdvancePayment}
                                                    style={{ width: '100%' }}
                                                    // disabled={disabled || !canCreate || isDisabledPaidWarrant}
                                                    disabled={true}
                                                    decimalPlaces={6}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('travelWarrant.edit.totalAdvancePayment')}
                                                name="totalAdvancePayment"
                                            >
                                                <NyInputNumber
                                                    isDecimal={true}
                                                    style={{ width: '100%' }}
                                                    // disabled={disabled || !canCreate || isDisabledPaidWarrant}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantPersonal.edit.advancePaymentComment')}
                                            name={'advancePaymentComment'}
                                            labelAlign={'left'}
                                        >
                                            <TextArea rows={4} maxLength={300} disabled={disabledTable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={leftCollapseActiveKeys}
                            onChange={(key: any) => {
                                setLeftCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('travelWarrantWizard.step.3')} key="2">
                                <Row gutter={24}>
                                    <Col span={24}>
                                        {loadingAdditional ? (
                                            <NySpinner />
                                        ) : (
                                            <>
                                                {dataAdditional?.length > 1 && (
                                                    <Radio.Group
                                                        options={destinationsAdditional}
                                                        value={destinationAdditional}
                                                        onChange={(e: any) =>
                                                            onChangeDestinationAdditional(e?.target?.value)
                                                        }
                                                        buttonStyle="solid"
                                                        optionType="button"
                                                        style={{ marginBottom: '15px' }}
                                                    />
                                                )}
                                                <TravelWarrantAdditionalInfoEdit
                                                    item={itemAdditional}
                                                    form={formAdditional}
                                                    travelWarrantEmployeeId={employeeId}
                                                    isPlane={isPlane}
                                                    needTransport={needTransport}
                                                    disabled={isDisabledPaidWarrant}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                        {showCo2Component() && (
                            <Collapse
                                activeKey={leftCollapseActiveKeys}
                                onChange={(key: any) => {
                                    setLeftCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('travelWarrantCo2.table.header')} key="4">
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <TravelWarrantCo2Index
                                                travelWarrantId={dataForm}
                                                employeeTravelWarrantId={employeeId}
                                                travelWarrantStatus={status}
                                                travelVehicle={form.getFieldValue('travelVehicle')}
                                                travelAdditionalData={dataAdditional}
                                                disabled={isDisabledPaidWarrant}
                                            />
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        )}
                        {(canView || canEdit || canEditPersonal) &&
                            (status == travelWarrantStatusEnum.REPORT_APPROVED ||
                                status == travelWarrantStatusEnum.CALCULATED ||
                                status == travelWarrantStatusEnum.NOT_CALCULATED ||
                                status == travelWarrantStatusEnum.PAID) && (
                                <Collapse
                                    activeKey={leftCollapseActiveKeys}
                                    onChange={(key: any) => {
                                        setLeftCollapseActiveKeys(key);
                                    }}
                                >
                                    <Panel header={geti18nText('travelWarrant.panel.totalExpenses')} key="3">
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('travelWarrant.edit.calculationDate')}
                                                    name="calculationDate"
                                                >
                                                    <NyDatePicker
                                                        style={{ width: '100%' }}
                                                        nyTestId="calculation-date"
                                                        disabled={
                                                            (!canEdit && !canEditPersonal) ||
                                                            !canEditStatusAndCalculationDatePaid()
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {status && status >= travelWarrantStatusEnum.NOT_CALCULATED && (
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={geti18nText('travelWarrant.edit.report')}
                                                        name="report"
                                                    >
                                                        <Input.TextArea
                                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                                            onKeyDown={onKeyDownTextAreaCustom}
                                                            ref={focusInputReport}
                                                            disabled={isDisabledPaidWarrant}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('travel.warrant.edit.approve.advance')}
                                                    name="advanceApproved"
                                                    valuePropName={'checked'}
                                                >
                                                    <Checkbox
                                                        disabled={!canCreate || advance || isDisabledPaidWarrant}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('travel.warrant.edit.approve.vehicle')}
                                                    name="vehicleApproved"
                                                    valuePropName={'checked'}
                                                >
                                                    <Checkbox
                                                        disabled={!canCreate || vehicle || isDisabledPaidWarrant}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {travelVehicle && !isPersonalOrOfficial && showAnotherVehicle && (
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={geti18nText('travel.warrant.edit.approve.vehicle2')}
                                                        name="vehicleApproved2"
                                                        valuePropName={'checked'}
                                                    >
                                                        <Checkbox
                                                            disabled={!canCreate || vehicle2 || isDisabledPaidWarrant}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                    </Panel>
                                </Collapse>
                            )}
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

export default GeneralEditData;
