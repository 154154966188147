import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import EmploymentTypeIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import EmploymentTypeEdit from './edit';

const EmploymentTypeSearch = ({
    label = geti18nText('employee.edit.employment.type'),
    name = 'employmentType',
    required = false,
    disabled = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.EMPLOYMENT_TYPE.SEARCH}
                map={{ id: 'id', label: 'text', text: 'name' }}
                searchBy="text"
                nyTestId="employment-type"
                AddNewModalComponent={EmploymentTypeEdit}
                SearchPopupComponent={<EmploymentTypeIndex />}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default EmploymentTypeSearch;
