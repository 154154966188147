import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setDateFormat, setSearchFormat } from '../../../../utils/Utils';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import CostCenterEdit from '../../../accounting/views/cost-center/edit';
import CostCenterSearch from '../../../accounting/views/cost-center/search';
import CurrencySearch from '../../../administration/views/currency/search';
import RewardTypeSearch from '../reward-type/search';

const EmployeeRewardsEdit = (props: any) => {
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [editHeader, setEditHeader] = useState(geti18nText('employee.rewards.edit.new'));
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [entryDate, setEntryDate] = useState<any>(null);
    const [paymentDate, setPaymentDate] = useState<any>(null);
    const [net, setNet] = useState(false);
    const [approved, setApproved] = useState(false);
    const [files, setFiles] = useState<any>([]);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('employee.rewards.edit.title'));
        if (dataForm.rewardType) {
            dataForm.rewardType = setSearchFormat(dataForm.rewardType, 'name', 'name', 'code');
        }
        if (dataForm.accountingCostCenter) {
            dataForm.accountingCostCenter = setSearchFormat(dataForm.accountingCostCenter, 'name', 'name', 'code');
        }
        if (dataForm.entryDate) {
            dataForm.entryDate = setDateFormat(dataForm.entryDate);
            setEntryDate(dataForm.entryDate);
        }
        if (dataForm.paymentDate) {
            dataForm.paymentDate = setDateFormat(dataForm.paymentDate);
            setPaymentDate(dataForm.paymentDate);
        }
        if (dataForm.net) {
            setNet(dataForm.net);
        }

        if (dataForm.currency) {
            dataForm.currency = setSearchFormat(dataForm.currency, 'isoCode');
        }

        if (dataForm.domicileCurrency) {
            dataForm.domicileCurrency = setSearchFormat(dataForm.domicileCurrency, 'isoCode');
        }

        if (dataForm.approved) {
            setApproved(dataForm.approved);
        }

        if (dataForm.fileId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.FILES.INFO + '/' + dataForm.fileId, undefined, undefined).then(
                (result: any) => {
                    if (result && result.status == RESPONSE.OK && result.data) {
                        let filesImage = [
                            {
                                id: dataForm.fileId,
                                uid: dataForm.fileId,
                                name: result.data.name,
                                url:
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    dataForm.fileId +
                                    '?lastmod=' +
                                    new Date().valueOf() +
                                    '&tenant=' +
                                    NySession.getUser().tenantId,
                            },
                        ];
                        setFiles(filesImage);
                    }
                }
            );
        }

        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.rewards.edit.new'));
        setEntryDate(null);
        setPaymentDate(null);
        setNet(false);
        setApproved(false);
    };

    const onEndDateChange = (value: any) => {
        setPaymentDate(value ? moment(value) : moment());
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setEntryDate(value ? moment(value) : moment());
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    const amountOnChange = (value: any) => {
        const currency = form.getFieldValue('currency');
        calculateDomicileAmount(value ?? 0, currency?.id > 0 ? currency : null);
    };

    const currencyOnChange = (value: any) => {
        calculateDomicileAmount(form.getFieldValue('amount') ?? 0, value?.id > 0 ? value : null);
    };

    const calculateDomicileAmount = (amount: any, currency: any) => {
        if (amount && currency?.id > 0) {
            if (currency?.text == defaultCurrencyISO) {
                currency.isoCode = currency.text;
                form.setFieldsValue({
                    amountInCurrency: amount ?? 0,
                    domicileCurrency: setSearchFormat(currency, 'isoCode'),
                });
            } else {
                NyRequestResolver.requestGet(CONSTANTS_REQ.REWARDS.DOMICILE_AMOUNT, {
                    amount: amount,
                    currencyId: currency.id,
                }).then((result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        form.setFieldsValue({
                            amountInCurrency: result.data.amountInDomicileCurrency ?? 0,
                            domicileCurrency: setSearchFormat(result.data.domicileCurrency, 'isoCode'),
                        });
                    }
                });
            }
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.REWARDS.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.rewardType) {
                    values.rewardType = getSearchFormat(values.rewardType);
                }
                if (values.accountingCostCenter) {
                    values.accountingCostCenter = getSearchFormat(values.accountingCostCenter);
                }
                if (values.entryDate) {
                    values.entryDate = moment(values.entryDate).format('yyyy-MM-DD HH:mm');
                }
                if (values.paymentDate) {
                    values.paymentDate = moment(values.paymentDate).format('yyyy-MM-DD HH:mm');
                }
                if (values.year && Number(values.year)) {
                    values.year = Number(values.year);
                }

                values.net = net;
                values.approved = approved;
                if (files.length > 0 && files[0]) {
                    values.fileId = files[0].id;
                }
                delete values.file;
                if (!values?.employee?.id) {
                    values.employee = { id: props?.addedData?.employeeId };
                }

                return values;
            }}
            shortcuts={true}
            nyTestId="blood-donation-edit"
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <React.Fragment>
                <Row gutter={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['employee', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={6}>
                        <RewardTypeSearch required />
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employee.rewards.edit.year')}
                            name="year"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    //   whitespace: true, --> maknuto, blokira spremanje dok se polje ne editira
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employee.rewards.edit.entryDate')}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            name="entryDate"
                        >
                            <NyDatePicker
                                style={{ width: '100%' }}
                                onChange={onStartDateChange}
                                disabledDate={disabledStartDate}
                                defaultPickerValue={entryDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employee.rewards.edit.paymentDate')} name="paymentDate">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                onChange={onEndDateChange}
                                disabledDate={disabledendDate}
                                nyTestId="valid-to"
                                defaultPickerValue={paymentDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <CostCenterSearch
                            label={geti18nText('employee.rewards.edit.accountingCostCenter')}
                            name="accountingCostCenter"
                            AddNewModalComponent={canCreate() ? CostCenterEdit : null}
                            mustGetPopupContainer={false}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employee.rewards.edit.neto')} name="net">
                            <Checkbox
                                checked={net}
                                onChange={(e: any) => {
                                    setNet(e.target.checked);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employee.rewards.edit.approved')} name="approved">
                            <Checkbox
                                checked={approved}
                                onChange={(e: any) => {
                                    setApproved(e.target.checked);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employee.rewards.edit.amount')} name="amount">
                            <NyInputNumber
                                style={{ width: '100%' }}
                                isDecimal={true}
                                decimalPlaces={2}
                                max={99999}
                                min={0}
                                onChange={amountOnChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <CurrencySearch
                            label={geti18nText('employee.rewards.edit.currency')}
                            name="currency"
                            onChange={currencyOnChange}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employee.rewards.edit.amountInCurrency')}
                            name="amountInCurrency"
                        >
                            <NyInputNumber
                                style={{ width: '100%' }}
                                isDecimal={true}
                                decimalPlaces={2}
                                max={99999}
                                min={0}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <CurrencySearch
                            label={geti18nText('employee.rewards.edit.domicileCurrency')}
                            name="domicileCurrency"
                            disabled
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>{geti18nText('employee.rewards.edit.file')}</Divider>
                        <Form.Item name="file">
                            <NyImageUpload
                                files={files}
                                setFiles={setFiles}
                                saveOnUpload={true}
                                accept={'*'}
                                cropShape={null}
                                listType={'text'}
                                maxImage={1}
                                canDeleteFile={!HumanResourcesRights.canViewEDRModal()}
                                canUpload={!HumanResourcesRights.canViewEDRModal()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={geti18nText('employee.rewards.edit.note')} name="remark">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default EmployeeRewardsEdit;
