import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import SettlementEdit from './edit';

const SettlementDistrictIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    selectedSettlementId,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('common/settlement');

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportAdministrationCodebookSettlement();
    };

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const setSettlementFilterValue = () => {
        if (selectedSettlementId) {
            return [
                { field: 'settlement.id', condition: 'equals', value: selectedSettlementId },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns = [
        {
            title: geti18nText('settlement.district.table.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('settlement.district.table.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('settlement.district.table.settlement.id'),
            dataIndex: ['settlement', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.SETTLEMENT.SEARCH, 'settlement.id', 'name'),
        },
        {
            title: geti18nText('settlement.district.table.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="settlement-district"
            url={CONSTANTS_REQ.SETTLEMENT_DISTRICT.LIST}
            addNewButtonText={geti18nText('settlement.district.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={SettlementEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            hideNewButton={!canCreate()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={selectedSettlementId ? setSettlementFilterValue : setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('settlement.district.district')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            headerTitle={geti18nText('settlement.district.district')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('settlement.district.table.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('settlement.district.table.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('settlement.district.table.settlement.id'),
            dataIndex: ['settlement', 'name'],
        },

        {
            title: geti18nText('settlement.district.table.active'),
            dataIndex: 'active',
        },
    ];
};

export default SettlementDistrictIndex;
