import { createSlice } from '@reduxjs/toolkit';
import { getNotificationData } from '../components/notifications/getNotificationData';
import { GetEnumNameForValue } from '../utils/Enums';
import { setEnumFormat, showNotification } from '../utils/Utils';

interface INotificationState {
    count: any;
    settings: any;
    refreshNotification: number;
}

const initialState: INotificationState = {
    count: {
        ALL: 0,
    },
    settings: {},
    refreshNotification: 0,
};

const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        notificationInit(state) {
            state.count.ALL = 0;
            state.refreshNotification = 0;
        },
        addNotification(state, action) {
            let all = action.payload && action.payload[0] && action.payload[0].notificationType == 0;
            delete action.payload[0];
            if (all) {
                state.count['ALL'] = 0;
                for (const [key, value] of Object.entries(action.payload)) {
                    //key == 10 -> DASBOARD_NOTIFICATION
                    if (key !== '10') {
                        let enumKey: any = GetEnumNameForValue({
                            enumName: 'NOTIFICATION_TYPE',
                            value: parseInt(key),
                        });
                        state.count[enumKey] = action.payload[key]?.unread;
                        state.count['ALL'] = state.count['ALL'] + state.count[enumKey];
                    }
                }
            } else {
                for (const [key, value] of Object.entries(action.payload)) {
                    let enumKey: any = GetEnumNameForValue({
                        enumName: 'NOTIFICATION_TYPE',
                        value: parseInt(key),
                    });
                    if (key !== '10') {
                        state.count[enumKey] = (state.count[enumKey] ?? 0) + action.payload[key].content?.length;
                        state.count['ALL'] = state.count['ALL'] + action.payload[key].content?.length;
                        let typeName: any = setEnumFormat('NOTIFICATION_TYPE', Number(key));
                        let newNotifications: any = value;
                        newNotifications.content?.forEach((element: any) => {
                            let notificationData: any = getNotificationData(element);

                            showNotification(typeName && typeName.text ? typeName.text : '', notificationData.content);

                            if (element.notificationType == 16) {
                                let noteData = element.note && element.note.note ? JSON.parse(element.note.note) : {};
                                if (noteData.id) {
                                    state.refreshNotification = state.refreshNotification + 1;
                                }
                            }
                        });
                    }
                }
            }
        },
        addSettings(state, action) {
            state.settings = action.payload;
        },
    },
});

export const { notificationInit, addNotification, addSettings } = notification.actions;

export default notification.reducer;
