import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const ShiftSearch = ({
    isForm = true,
    label = geti18nText('attendance.addAttendance.shift'),
    name = 'shift',
    onChange = null,
    style = undefined,
    mustGetPopupContainer = undefined,
}: any) => {
    return (
        <>
            {isForm ? (
                <Form.Item label={label} name={name}>
                    <NySearchField
                        url={CONSTANTS_REQ.SHIFT.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        style={style}
                        nyTestId="shift"
                        onChange={onChange}
                    />
                </Form.Item>
            ) : (
                <NySearchField
                    url={CONSTANTS_REQ.SHIFT.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                    style={style}
                    nyTestId="input-shift"
                    onChange={onChange}
                    mustGetPopupContainer={mustGetPopupContainer}
                />
            )}
        </>
    );
};

export default ShiftSearch;
