import { DeleteOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Alert, Col, Empty, Input, List, Radio, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat, setSearchFormat } from '../../../../../utils/Utils';
const { Search } = Input;

const ProgramCatalogSelectIndex = ({
    disabled = false,
    form,
    programCatalog = null,
    setProgramCatalog = null,
    refresh,
    setDateFrom,
    setDateTo,
    setFiles,
}: any) => {
    const [data, setData] = useState<any>([]);
    const [initData, setInitData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setData(initData);
    }, [refresh]);

    const fetchData = () => {
        setLoading(true);
        const setDefaultFilterValue = () => {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'applicationDueDate',
                    condition: 'date_range',
                    value: `${getDateFormat(moment(), 'yyyy-MM-DD')}, ${getDateFormat(
                        moment().add(10, 'years'),
                        'yyyy-MM-DD'
                    )}`,
                },
            ];
        };

        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.PROGRAM_CATALOG.LIST, { max: 999, search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result?.data?.content) {
                    setData(result?.data?.content);
                    setInitData(result?.data?.content);
                    if (result?.data?.content?.length < 1) {
                        onRowSelect(null);
                    }
                }
            }
        );
        setLoading(false);
    };

    const filterList = (value: any) => {
        let filteredData = initData;
        if (value && value != '' && value?.length > 0) {
            filteredData = initData.filter(
                (item: any) => item?.name && item.name.toLowerCase().includes(value.toLowerCase())
            );
        }
        setData(filteredData);
        if (filteredData?.length < 1) {
            onRowSelect(null);
        }
    };

    const onRowSelect = (item: any) => {
        let selectedItem = item;
        if (item?.id == programCatalog?.id) {
            selectedItem = null;
        }
        setProgramCatalog(selectedItem);
        setDateFrom(selectedItem?.dateFrom ? moment(selectedItem.dateFrom) : null);
        setDateTo(selectedItem?.dateTo ? moment(selectedItem.dateTo) : null);

        if (selectedItem?.fileId) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.FILES.INFO + '/' + selectedItem.fileId,
                undefined,
                undefined
            ).then((result: any) => {
                if (result?.status == RESPONSE.OK && result?.data) {
                    let filesImage = [
                        {
                            id: selectedItem.fileId,
                            uid: selectedItem.fileId,
                            name: result?.data?.name,
                            url:
                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                '/' +
                                selectedItem.fileId +
                                '?lastmod=' +
                                new Date().valueOf() +
                                '&tenant=' +
                                NySession.getUser().tenantId,
                        },
                    ];
                    setFiles(filesImage);
                }
            });
        }

        form.setFieldsValue({
            programCatalog: { id: selectedItem?.id },
            name: selectedItem?.name,
            applicationDueDate: selectedItem?.applicationDueDate ? moment(selectedItem.applicationDueDate) : null,
            dateFrom: selectedItem?.dateFrom ? moment(selectedItem.dateFrom) : null,
            dateTo: selectedItem?.dateTo ? moment(selectedItem.dateTo) : null,
            location: selectedItem?.location,
            place: selectedItem?.place,
            country: selectedItem?.country && setSearchFormat(selectedItem.country),
        });
    };

    const getDescription = (item: any) => (
        <>
            <div>
                <span style={{ paddingLeft: '10px' }}>
                    {geti18nText('employee.education.edit.location')}
                    {': '}
                    {item?.location ?? ''}
                    {item?.location && item?.place && ', '}
                    {item?.place ?? ''}
                    {item?.place && item?.country?.name && ', '}
                    {item?.location && !item?.place && item?.country?.name && ', '}
                    {item?.country?.name ?? ''}
                </span>
            </div>
            {item?.applicationDueDate && (
                <div>
                    <span style={{ paddingLeft: '10px' }}>
                        {geti18nText('employee.education.edit.applicationDueDate')}
                        {': '}
                        {getDateFormat(item.applicationDueDate, 'DD.MM.YYYY.')}
                    </span>
                </div>
            )}
            {(item?.dateFrom || item?.dateTo) && (
                <div>
                    <span style={{ paddingLeft: '10px' }}>
                        {geti18nText('employee.education.edit.duration')}
                        {': '}
                        {item.dateFrom && getDateFormat(item.dateFrom, 'DD.MM.YYYY.')}
                        {item.dateFrom && item.dateTo && ' - '}
                        {item.dateTo && getDateFormat(item.dateTo, 'DD.MM.YYYY.')}
                    </span>
                </div>
            )}
        </>
    );

    return (
        <>
            {!disabled && (
                <>
                    <div style={{ paddingBottom: '15px' }}>
                        <Alert
                            message={geti18nText('employee.education.program.catalog.choose')}
                            type="info"
                            showIcon
                        />
                    </div>
                    {initData?.length > 1 && (
                        <Row gutter={24}>
                            <Col span={22} offset={1}>
                                <Search
                                    placeholder={geti18nText('app.default.search')}
                                    allowClear
                                    size="large"
                                    onSearch={(value: any) => filterList(value)}
                                    autoFocus
                                />
                            </Col>
                            <Col span={1}>
                                <Tooltip title={geti18nText('app.default.button.delete')}>
                                    <DeleteOutlined
                                        style={{
                                            fontSize: '22px',
                                            color: 'grey',
                                            marginLeft: '-10px',
                                            marginTop: '5px',
                                        }}
                                        onClick={() => onRowSelect(null)}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <div
                style={{
                    maxHeight: '350px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    cursor: 'default',
                    marginTop: '25px',
                }}
            >
                {data?.length > 0 ? (
                    <List loading={loading} itemLayout="horizontal" pagination={false}>
                        {disabled ? (
                            <>
                                <List.Item
                                    style={{
                                        border: isDarkTheme ? '3px solid #111b26' : '3px solid #f3f3f3',
                                        borderRadius: '3px',
                                        backgroundColor: isDarkTheme ? '#102444' : '#e8f4fc',
                                        transition: 'background-color 0.3s ease',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    <Radio checked={true} disabled={true} style={{ paddingRight: '10px' }} />
                                    <List.Item.Meta
                                        title={<div style={{ fontSize: '15px' }}>{programCatalog?.name}</div>}
                                        description={getDescription(data[0])}
                                        style={{ cursor: 'default' }}
                                    />
                                </List.Item>
                            </>
                        ) : (
                            data.map((item: any) => {
                                return (
                                    <List.Item
                                        onClick={() => onRowSelect(item)}
                                        style={{
                                            border: isDarkTheme ? '3px solid #111b26' : '3px solid #f3f3f3',
                                            borderRadius: '3px',
                                            backgroundColor:
                                                programCatalog?.id === item.id
                                                    ? isDarkTheme
                                                        ? '#102444'
                                                        : '#e8f4fc'
                                                    : undefined,
                                            transition: 'background-color 0.3s ease',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        <Radio
                                            checked={programCatalog?.id === item.id}
                                            style={{ paddingRight: '10px' }}
                                        />
                                        <List.Item.Meta
                                            title={<div style={{ fontSize: '15px' }}>{item.name}</div>}
                                            description={getDescription(item)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </List.Item>
                                );
                            })
                        )}
                    </List>
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};

export default ProgramCatalogSelectIndex;
