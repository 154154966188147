import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, Descriptions, Progress, Row, Tabs, Typography } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import PointAdditionDecisionIndex from '../point-addition-decision';
import EmployeeSalarySupplementsIndex from './EmployeeSalarySupplementsIndex';
const { Text } = Typography;

const EmployeeAdditionIndex = ({ idProps, readonly }: any) => {
    const [tabKey, setTabKey] = useState<any>('payrollBrut');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [points, setPoints] = useState<any>(null);

    useEffect(() => {
        if (idProps > 0) {
            getPoints();
        }
    }, []);

    const getPoints = () => {
        setPoints(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_POINTS + '/' + idProps, { employeeId: idProps }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setPoints(result.data);
                }
            }
        );
    };

    const getAdditionsOverview: any = () => {
        const contractPoint = points ? Number(((points?.contractPoint / points?.pointTo) * 100).toFixed(2)) : 0;
        const totalPoint = points ? Number(((points?.totalPoint / points?.pointTo) * 100).toFixed(2)) : 0;

        return (
            <>
                {points && (
                    <Row gutter={24} style={{ marginBottom: '10px' }}>
                        <Col span={12}>
                            <Descriptions column={2} bordered size="small" labelStyle={{ width: '210px' }}>
                                <Descriptions.Item label={geti18nText('pointAdditionDecision.points.contractPoint')}>
                                    <Text>{points?.contractPoint}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('pointAdditionDecision.points.totalPoint')}>
                                    <Text>{points?.totalPoint}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('pointAdditionDecision.points.additionPoint')}>
                                    <Text>{points?.additionPoint}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('pointAdditionDecision.points.range')}>
                                    <Text>{points?.pointFrom + ' - ' + points?.pointTo}</Text>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={10} style={{ paddingTop: '10px' }}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Text>
                                        {points?.contractPoint +
                                            ' (' +
                                            geti18nText('pointAdditionDecision.points.contractPoint') +
                                            ') + ' +
                                            points?.additionPoint +
                                            ' (' +
                                            geti18nText('pointAdditionDecision.points.additionPoint') +
                                            ') = ' +
                                            points?.totalPoint +
                                            ' / ' +
                                            points?.pointTo}
                                    </Text>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Progress percent={totalPoint} success={{ percent: contractPoint }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    return (
        <>
            {getAdditionsOverview()}
            <Tabs
                activeKey={tabKey}
                type="card"
                className="tabs-sticky"
                id="employee-edit-additions-tabs"
                onChange={(key: any) => setTabKey(key)}
            >
                <TabPane tab={geti18nText('employee.tab.payrollBrut')} key="payrollBrut">
                    <EmployeeSalarySupplementsIndex idProps={idProps} readonly={readonly} scroll={{ y: 300, x: 800 }} />
                </TabPane>
                <TabPane tab={geti18nText('employee.tab.pointAdditionDecisions')} key="pointAdditionDecisions">
                    <PointAdditionDecisionIndex
                        employeeId={idProps}
                        isPersonal={employee?.id === idProps}
                        readonly={readonly}
                        scroll={{ y: 300, x: 800 }}
                    />
                </TabPane>
            </Tabs>
        </>
    );
};

export default EmployeeAdditionIndex;
