import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { useLocation } from 'react-router';
import useTableSettings from '../../../../hooks/useTableSettings';
import LocationMobilizationEdit from './edit';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const MobilizationLocationIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const columns = [
        {
            title: geti18nText('mobilization.location.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('mobilization.location.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('mobilization.location.table.column.active'),
            dataIndex: 'active',
            width: '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-location-mobilization"
            url={CONSTANTS_REQ.MOBILIZATION_LOCATION.LIST}
            addNewButtonText={geti18nText('mobilization.location.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={LocationMobilizationEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            headerTitle={geti18nText('menu.work.obligation.mobilization.location')}
            // exportCSV={canExportCSV()}
            // CSVFileName={geti18nText('menu.location')}
            // colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export default MobilizationLocationIndex;
