import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import MealMenusCalendarIndex from './MealMenusCalendarIndex';
import MealMenusTableIndex from './MealMenusTableIndex';

const { TabPane } = Tabs;

const MealMenusIndex = () => {
    useHelper('meals/codebooks/meal-menus');
    const [refreshTable, setRefreshTable] = useState<any>(1);

    return (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('meal.menu.tab.calendar')} key="1">
                    <MealMenusCalendarIndex refreshTable={refreshTable} setRefreshTable={setRefreshTable} />
                </TabPane>
                <TabPane tab={geti18nText('meal.menu.tab.table')} key="2" style={{ marginTop: '10px' }}>
                    <MealMenusTableIndex
                        refreshTable={refreshTable}
                        setRefreshTable={setRefreshTable}
                        scroll={{ y: 575, x: 800 }}
                    />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default MealMenusIndex;
