import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Dropdown, Menu } from 'antd';
import { useLocation } from 'react-router';
import ImportCSVModal from '../../../../components/import-csv-modal';
import NyDescription from '../../../../components/ny-description';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArrayStatus, getEnumBooleanArray } from '../../../../utils/Utils';
import AccountEdit from './edit';
import React, { useState } from 'react';

const AccountIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const travelWarrantSyncType = useApplicationSetting('TRAVEL_WARRANT_SYNC_TYPE');
    const isPointSync: boolean = travelWarrantSyncType === 'point' ? true : false;
    const [visible, setVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>(null);
    const [value, setValue] = useState<any>(false);

    const table = useTableSettings();

    useHelper('accounting/codebooks/account');

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    const canExportCSV = () => {
        return PayrollRights.canCsvExportPayrollCodebook();
    };

    const initialColumns = [
        ...[
            // {
            //     title: geti18nText('account.table.column.id'),
            //     dataIndex: 'id',
            //     width: !isPointSync ? '10%' : '5%',
            //     sorter: (a: any, b: any) => {},
            //     ...getColumnSearch('number'),
            // },
            {
                title: geti18nText('account.table.column.code'),
                dataIndex: 'code',
                width: '6%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (text: any, record: any) => {
                    if (record.id && record.code) {
                        return (
                            <Button
                                type="link"
                                onClick={() => {
                                    setValue(record);
                                    setVisible(true);
                                }}
                                record-id={record['id']}
                                id={record['id']}
                            >
                                <span style={{ textDecoration: 'underline' }}>{record.code}</span>
                            </Button>
                        );
                    }
                },
            },
            {
                title: geti18nText('account.table.column.name'),
                dataIndex: 'name',
                width: '25%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (description: any, record: any) => {
                    if (record && record.name) {
                        return <NyDescription title={record.name} maxWidth={!isPointSync ? '400px' : '350px'} />;
                    }
                },
            },
            {
                title: geti18nText('account.table.column.type'),
                dataIndex: 'type',
                width: '9%',
                render: (text: any, record: any) => {
                    if (record.type !== undefined) {
                        return geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + record.type);
                    }
                },
                ...getColumnSearchCheckbox(getEnumArrayStatus('ACCOUNT_ENTRY_TYPE')),
            },
        ],
        ...(isPointSync
            ? [
                  {
                      title: geti18nText('account.table.column.employee'),
                      dataIndex: 'employee',
                      width: '6%',
                      render: (text: any, record: { employee: any }) => {
                          if (record.employee) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
                  {
                      title: geti18nText('account.table.column.businessUnit'),
                      dataIndex: 'businessUnit',
                      render: (text: any, record: { businessUnit: any }) => {
                          if (record.businessUnit) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
                  {
                      title: geti18nText('account.table.column.company'),
                      dataIndex: 'company',
                      width: '5%',
                      render: (text: any, record: { company: any }) => {
                          if (record.company) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
                  {
                      title: geti18nText('account.table.column.accountingCostCenter'),
                      dataIndex: 'accountingCostCenter',
                      width: '8%',
                      render: (text: any, record: { accountingCostCenter: any }) => {
                          if (record.accountingCostCenter) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
                  {
                      title: geti18nText('account.table.column.accountingCostObject'),
                      dataIndex: 'accountingCostObject',
                      render: (text: any, record: { accountingCostObject: any }) => {
                          if (record.accountingCostObject) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
                  {
                      title: geti18nText('account.table.column.document'),
                      dataIndex: 'document',
                      width: '8%',
                      render: (text: any, record: { document: any }) => {
                          if (record.document) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
                  {
                      title: geti18nText('account.table.column.workOrder'),
                      dataIndex: 'workOrder',
                      width: '7%',
                      render: (text: any, record: { workOrder: any }) => {
                          if (record.workOrder) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
                  },
              ]
            : []),
        ...[],
        {
            title: geti18nText('account.table.column.active'),
            dataIndex: 'active',
            width: !isPointSync ? '10%' : '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <ImportCSVModal
                            url={CONSTANTS_REQ.ACCOUNT.IMPORT_CSV}
                            onSave={onSave}
                            customInfoMessage={geti18nText('accountImportCSV.upload.info')}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px', paddingRight: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="accounting-table"
                url={CONSTANTS_REQ.ACCOUNT.LIST}
                addNewButtonText={geti18nText('account.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={AccountEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={initialColumns}
                hideNewButton={!canCreate()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.accounting.account')}
                colCSVCustomization={csvColumnCustomisation()}
                addedButtons={canCreate() ? addedButtons : undefined}
                headerTitle={geti18nText('menu.accounting.account')}
                fetchWhenChange={refresh}
            />
            {React.createElement(
                AccountEdit,
                {
                    isModal: true,
                    visible: visible,
                    setVisible: setVisible,
                    value: value,
                    onSave: () => {
                        setVisible(false);
                        setValue(undefined);
                    },
                    onSaveAndGetID: () => {
                        setVisible(false);
                        setValue(undefined);
                    },
                    onSaveAndGetData: () => {
                        setRefresh((prevValue: any) => prevValue + 1);
                        setVisible(false);
                        setValue(undefined);
                    },
                    onModalClose: () => {
                        setVisible(false);
                        setValue(undefined);
                    },
                },
                null
            )}
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            company: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            businessUnit: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            accountingCostCenter: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            accountingCostObject: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            employee: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            document: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            workOrder: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AccountIndex;
