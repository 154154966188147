import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import EducationFormIndex from '.';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import EducationFormEdit from './edit';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const EducationFormSearch = ({
    label = geti18nText('employee.education.edit.educationForm'),
    name = 'educationForm',
    required = false,
    onChange,
    autoFocus,
    disabled = false,
    setDefaultFilterValue,
    renderOption = (option: any) => `${option.text}${option.code ? ' (' + option.code + ')' : ''}`,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateEducationCodebooks();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.EDUCATION_FORM.SEARCH}
                map={{ id: 'id', label: 'description', code: 'code', text: 'description' }}
                searchBy="description,code"
                itemName={[['code'], ['description']]}
                customItemNameLabel={'description (code)'}
                order={'description'}
                renderOption={renderOption}
                SearchPopupComponent={<EducationFormIndex searchComponent />}
                AddNewModalComponent={canCreate() ? EducationFormEdit : null}
                nyTestId="educationForm"
                onChange={onChange}
                autoFocus={autoFocus}
                disabled={disabled}
                setDefaultFilterValue={setDefaultFilterValue ? setDefaultFilterValue : null}
                mustGetPopupContainer={false}
            />
        </Form.Item>
    );
};

export default EducationFormSearch;
