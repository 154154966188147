import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import ItemIndex from '.';
import ItemEdit from './edit';
import { AssetRights } from '../../../../rights/assetRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { AdministrationRights } from '../../../../rights/administrationRights';

const ItemSearch = ({
    label = geti18nText('asset.edit.item'),
    name = 'item',
    onChange,
    searchBy = 'name',
    addedFilter,
    addNewItem = false,
    setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    },
    itemIndexType = 0,
    itemGroupType,
    map = { id: 'id', label: 'name' },
    required = false,
    addedData,
    style = {},
    itemIndexDisabled = false,
    customItemNameLabel,
    itemName,
    renderOption,
    autoFocus,
    validator = undefined,
    isEditableCell = false,
    mode = 'default',
    mustGetPopupContainer = false,
}: any) => {
    const canCreate = () => {
        switch (itemIndexType) {
            case 0:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
            case 2:
                //item-service
                return ServicesRights.canCreateCodebooks();
            case 8:
                //item-material
                return InventoryRights.canCreateCodebooks();
            case 4:
                //item-asset
                return AssetRights.canCreateCodebooks();
            default:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
        }
    };

    return !isEditableCell ? (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
                {
                    validator: validator
                        ? validator
                        : async () => {
                              return;
                          },
                },
            ]}
        >
            <NySearchField
                style={style}
                url={CONSTANTS_REQ.ITEM.SEARCH}
                map={map}
                searchBy={searchBy}
                SearchPopupComponent={
                    <ItemIndex
                        type={itemIndexType}
                        itemGroupType={itemGroupType}
                        disabled={itemIndexDisabled}
                        defaultFilterValue={setDefaultFilterValue()}
                    />
                }
                addNewItem={addNewItem}
                AddNewModalComponent={canCreate() && !addNewItem ? ItemEdit : null}
                addedData={{ type: itemIndexType, itemGroupType: itemGroupType }}
                AddNewModalComponentAddedData={{ type: itemIndexType, itemGroupType: itemGroupType }}
                onChange={onChange}
                itemName={itemName}
                customItemNameLabel={customItemNameLabel}
                renderOption={renderOption}
                setDefaultFilterValue={setDefaultFilterValue}
                addedFilter={addedFilter}
                autoFocus={autoFocus}
                mode={mode}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    ) : (
        <NySearchField
            style={style}
            url={CONSTANTS_REQ.ITEM.SEARCH}
            map={map}
            searchBy={searchBy}
            addNewItem={addNewItem}
            onChange={onChange}
            itemName={itemName}
            customItemNameLabel={customItemNameLabel}
            renderOption={renderOption}
            setDefaultFilterValue={setDefaultFilterValue}
            addedFilter={addedFilter}
            autoFocus={autoFocus}
            mode={mode}
            mustGetPopupContainer={mustGetPopupContainer}
        />
    );
};

export default ItemSearch;
