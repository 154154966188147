import { BookOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Menu, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
import { RootState } from '../../../../../rootReducer';
// import { addTab /* , removeTab, setIsDirtyTab, updateTabTitle */ } from '../../../../../slices/tabsSlice';
import useEnum from '../../../../../hooks/useEnum';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { updateHistoryI18n } from '../../../../../slices/historySlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import NyFilesUpload from '../../../../../utils/ny-file-upload-custom';
import {
    getDateFormat,
    getErrorNotificationMessage,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import CompanySearch from '../../../../administration/views/company/search';
import AssetAcquisitionItemsIndex from './asset-acquisition-items';
import AssetAcquisitionItemsActionIndex from './asset-acquisition-items/actionIndex';

const { Panel } = Collapse;

const AssetAcquisitionEdit = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isFormChange, setIsFormChange] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const { employee } = useSelector((state: RootState) => state.employee);
    const { id } = useParams<any>();
    const location = useLocation();
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1']);
    const uploadFilesRef = useRef<any>(null);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [note, setNote] = useState<any>(undefined);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [booked, setBooked] = useState(false);
    const [bookedGeneralLedger, setBookedGeneralLedger] = useState(false);
    const [title, setTitle] = useState('');
    const [showNewButton, setShowNewButton] = useState<any>(geti18nText('app.default.button.file.add2'));
    const [form] = Form.useForm();
    const [formItems] = Form.useForm();
    const dispatch = useDispatch();
    const focusInput = useRef<any>(null);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const [refresh, setRefresh] = useState<any>(0);
    const [getTotalsRefresh, setGetTotalsRefresh] = useState<any>(0);
    const [amount, setAmount] = useState<any>(undefined);
    const [ordinal, setOrdinal] = useState<any>(undefined);
    const [date, setDate] = useState<any>(undefined);
    const [dataLength, setDataLength] = useState<any>(null);

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    // useEffect(() => {
    //     dispatch(setIsDirtyTab({ path: location.pathname, isDirty: isFormChange }));
    // }, [isFormChange]);

    useEffect(() => {
        if (dataForm) {
            setCollapseActiveKeys(['1', '2', '3']);
        }
    }, [dataForm]);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetAcquisition();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.ord) {
            setOrdinal(dataForm.ord);
        }
        if (dataForm.date) {
            setDate(dataForm.date);
        }

        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setTitle(dataForm.ord + '/' + moment(dataForm.date).format('YYYY'));
        }
        setIsEdit(dataForm.id ? true : false);
        if (dataForm.booked) {
            setIsCreate(false);
            setIsFormChange(false);
            setBooked(true);
        } else {
            setIsCreate(true);
            setIsFormChange(false);
            setBooked(false);
        }

        setBookedGeneralLedger(dataForm.bookedGeneralLedger ? true : false);
        if (dataForm.company) {
            dataForm.company = setSearchFormat(dataForm.company, 'name', 'name');
        }
        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }

        if (dataForm.amount) {
            setAmount(dataForm.amount);
        }
        setNote(dataForm.note ? dataForm.note : undefined);
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3']);
        }
        setCollapseAll(!collapseAll);
    };

    const changeId = (returnedId: any) => {
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        const pathname = CONSTANTS_REQ.ASSET_ACQUISITION.PATHNAME + returnedId;
        // dispatch(addTab({ title: 'asset.acquisition.edit.title', addedData: returnedId, path: pathname }));
        history.push(pathname);
    };

    const book = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_ACQUISITION.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result?.status === RESPONSE.OK) {
                    setIsCreate(false);
                    setBooked(true);
                    okNotification(geti18nText('asset.acquisition.button.book.success'));
                } else {
                    getErrorNotificationMessage(result);
                }
                setLoading(false);
            });
        }
    };

    const debook = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_ACQUISITION.DE_BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result?.status === RESPONSE.OK) {
                    setIsCreate(true);
                    setBooked(false);
                    okNotification(geti18nText('asset.acquisition.button.debook.success'));
                } else {
                    getErrorNotificationMessage(result);
                }
                setLoading(false);
            });
        }
    };

    const actionsMenu = (
        <Menu>
            {canCreate() && isCreate && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                        onConfirm={() => {
                            book();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.acquisition.button.booking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {canCreate() && !isCreate && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                        onConfirm={() => {
                            debook();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.acquisition.button.deBooking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            <div style={{ display: 'block', margin: '5px' }}>
                <NyReportButton
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.REPORT.ASSET_ACQUISITION}
                    subreportType={'ASSET_ACQUISITION'}
                    customParms={{ assetAcquisitionId: dataForm }}
                    buttoni18nText={'app.default.button.download.pdf'}
                    fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_ACQUISITION)}
                    allowExportTypeChange={true}
                />
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    )}
                </Tooltip>
            </div>
            <div style={{ display: 'contents' }}>
                {dataForm && (
                    <>
                        {canCreate() && isCreate && (
                            <div style={{ display: 'inline-flex', marginRight: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                                    onConfirm={() => {
                                        book();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.acquisition.button.booking')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        {canCreate() && !isCreate && (
                            <div style={{ display: 'inline-flex', marginRight: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                                    onConfirm={() => {
                                        debook();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.acquisition.button.deBooking')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        <div style={{ display: 'inline-flex', marginRight: '12px' }}>
                            <NyReportButton
                                disabled={dataLength < 1}
                                style={{ width: '100%' }}
                                url={CONSTANTS_REQ.REPORT.ASSET_ACQUISITION}
                                subreportType={'ASSET_ACQUISITION'}
                                customParms={{ assetAcquisitionId: dataForm }}
                                buttoni18nText={'app.default.button.print'}
                                fileName={
                                    geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_ACQUISITION) +
                                    ' ' +
                                    ordinal +
                                    '_' +
                                    moment(date).format('YYYY')
                                }
                                customDateFormat={moment().format('yyyyMMDDHHmm')}
                                allowExportTypeChange={true}
                            />
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    useEffect(() => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_ACQUISITION.GET_TOTALS.replace('{id}', id), {}).then(
            (result: any) => {
                if (result.status == RESPONSE.OK && result.data) {
                    if (result.data) {
                        setAmount(result?.data?.amount);
                    }
                }
            }
        );
    }, [getTotalsRefresh]);

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.ASSET_ACQUISITION.EDIT}
                setValues={setValues}
                width={1200}
                fetchWhenChange={refresh || id}
                form={form}
                goBack={() => {
                    //     dispatch(removeTab(location.pathname));
                    setIsFormChange(false);
                    history.goBack();
                }}
                onSaveAndGetID={(returnedId: any) => {
                    changeId(returnedId);
                    setDataForm(returnedId);
                    if (id === 'create') {
                        // dispatch(removeTab(location.pathname));
                        changeId(returnedId);
                        setIsFormChange(false);
                    } else {
                        // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
                        setIsFormChange(false);
                    }
                }}
                paramsId={id}
                buttonsClassName="buttons-sticky"
                changeCollapseVisibility={changeCollapseVisibility}
                customFooterContent={getCustomFooterContent}
                checkIsFormChanged={true}
                setIsFormChange={setIsFormChange}
                hideActivationButtons={!canCreate() || !isCreate}
                disableSubmitButton={!canCreate() || !isFormChange}
                shortcuts={true}
                {...props}
                normalize={(values: any) => {
                    if (values.date) values.date = getDateFormat(values.date);
                    if (values.company) {
                        values.company = getSearchFormat(values.company);
                        if (values.company.id < 1) {
                            delete values.company;
                        }
                    }
                    values.booked = booked ? true : false;
                    values.bookedGeneralLedger = bookedGeneralLedger ? true : false;
                    return values;
                }}
            >
                <Row gutter={24}>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('asset.acquisition.tab.1')}
                                key="1"
                                extra={
                                    <NyNoteModal
                                        ref={focusInput}
                                        note={note}
                                        modalTitle={
                                            geti18nText('asset.acquisition.edit.title') +
                                            ' - ' +
                                            geti18nText('app.default.note')
                                        }
                                    />
                                }
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label={geti18nText('asset.acquisition.edit.ord')} name="ord">
                                            <NyInputNumber
                                                style={{ width: '100%' }}
                                                nyTestId="ord"
                                                disabled={!canCreate() || !isCreate || !isEdit}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <CompanySearch
                                            disabled={!canCreate() || !isCreate}
                                            label={geti18nText('asset.acquisition.edit.company')}
                                            isModal={true}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('asset.acquisition.edit.document')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                            name="documentInfo"
                                        >
                                            <Input disabled={!canCreate() || !isCreate} ref={focusInput} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('warehouseReceipt.edit.date')}
                                            name="date"
                                            initialValue={moment()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                nyTestId="date"
                                                disabled={!canCreate() || !isCreate}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {isEdit && (
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item name="booked" valuePropName={'checked'} initialValue={false}>
                                                <strong>{geti18nText('asset.acquisition.edit.booked') + ': '}</strong>
                                                <strong>
                                                    {booked
                                                        ? geti18nText('app.default.button.yes')
                                                        : geti18nText('app.default.button.no')}
                                                </strong>
                                            </Form.Item>
                                        </Col>
                                        {/* !!! ostaviti kod  !!!!  <Col span={12}>
                                        <Form.Item
                                            name="bookedGeneralLedger"
                                            valuePropName={'checked'}
                                            initialValue={false}
                                        >
                                            <strong>
                                                {geti18nText('asset.acquisition.edit.bookedGeneralLedger') + ': '}
                                            </strong>
                                            <strong>
                                                {bookedGeneralLedger
                                                    ? geti18nText('app.default.button.yes')
                                                    : geti18nText('app.default.button.no')}
                                            </strong>
                                        </Form.Item>
                                    </Col> !!! ostaviri kod !!!! */}
                                        <Col span={12}>
                                            <Form.Item name="amount" valuePropName={'checked'} initialValue={false}>
                                                <strong>
                                                    {geti18nText('asset.acquisition.item.table.column.amount') + ': '}
                                                </strong>
                                                <strong>{amount && NyUtils.formatNumber(amount, 2)}</strong>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                    <Form component={false} form={formItems}>
                        {isEdit && (
                            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
                                <Collapse
                                    activeKey={collapseActiveKeys}
                                    onChange={(key: any) => {
                                        setCollapseActiveKeys(key);
                                    }}
                                >
                                    <Panel header={geti18nText('asset.acquisition.tab.2')} key="2">
                                        <NyFilesUpload
                                            documentType={documentTypeEnum.ASSET_ACQUISITION}
                                            url={CONSTANTS_REQ.ASSET_ACQUISITION_FILE.LIST}
                                            editUrl={CONSTANTS_REQ.ASSET_ACQUISITION_FILE.EDIT}
                                            defaultFilesFilter={[
                                                { field: 'active', condition: 'equals_bool', value: 1 },
                                                { field: 'asset_acquisition_id', condition: 'equals', value: dataForm },
                                            ]}
                                            ref={uploadFilesRef}
                                            canAddNewFile={canCreate() && showNewButton}
                                            canDelete={canCreate() && showNewButton}
                                            canEdit={canCreate() && showNewButton}
                                            customUploadButtonTitle={showNewButton}
                                            id={dataForm}
                                            refId={dataForm}
                                            showHeader={false}
                                            employee={employee}
                                            maxHeight={'104px'}
                                        />
                                    </Panel>
                                </Collapse>
                            </Col>
                        )}
                        {dataForm && (
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Collapse
                                    style={{ marginTop: '1em' }}
                                    activeKey={collapseActiveKeys}
                                    onChange={(key: any) => {
                                        setCollapseActiveKeys(key);
                                    }}
                                >
                                    <Panel header={geti18nText('asset.acquisition.tab.3')} key="3" disabled={!dataForm}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                {!booked && !bookedGeneralLedger ? (
                                                    <AssetAcquisitionItemsActionIndex
                                                        assetAcquisitionId={dataForm}
                                                        canCreate={canCreate()}
                                                        booked={booked}
                                                        bookedGeneralLedger={bookedGeneralLedger}
                                                        isFixedAsset={true}
                                                        setGetTotalsRefresh={setGetTotalsRefresh}
                                                        getTotalsRefresh={getTotalsRefresh}
                                                        setDataLength={setDataLength}
                                                        dataLength={dataLength}
                                                        scroll={{ y: 180, x: 800 }}
                                                    />
                                                ) : (
                                                    <AssetAcquisitionItemsIndex
                                                        assetAcquisitionId={dataForm}
                                                        canCreate={canCreate()}
                                                        booked={booked}
                                                        bookedGeneralLedger={bookedGeneralLedger}
                                                        setDataLength={setDataLength}
                                                        dataLength={dataLength}
                                                        scroll={{ y: 180, x: 800 }}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                        )}
                    </Form>
                </Row>
            </NyDataEdit>
            <Prompt message={''} when={isFormChange} />
        </>
    );
};
export default AssetAcquisitionEdit;
