import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    formatJoppdCodeSearch,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    getWorkingDays,
    numberFormat,
    setDateFormat,
    setJoppdCodeDefaultFilterValue,
    setSearchFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import SickLeaveSearch from '../sick-leave/search';
import R1Modal from './R1Modal';

const EmployeeSickLeaveEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employeeSickLeave.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [minHzzoAmount, setMinHzzoAmount] = useState(831.5);
    const [maxHzzoAmount, setMaxHzzoAmount] = useState(4257.28);
    const [monthWorkingHours, setMonthWorkingHours] = useState(160);
    const [firstDay, setFirstDay] = useState<any>(undefined);
    const [fromDate, setFromDate] = useState<any>(undefined);
    const [toDate, setToDate] = useState<any>(undefined);
    const [joppdCode, setJoppdCode] = useState('');
    const [limit, setLimit] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [employeeId, setEmployeeId] = useState(undefined);
    const [initialDateFrom, setInitialDateFrom] = useState<any>(undefined);
    const [initialDateTo, setInitialDateTo] = useState<any>(undefined);
    const [remittance, setRemittance] = useState<any>(undefined);

    useEffect(() => {
        getAppSettings();
    }, []);

    useEffect(() => {
        if (props && props.addedData && props.addedData.employee) {
            form.setFieldsValue({
                employee: getEmployeeSelectOption(props && props.addedData && props.addedData.employee),
            });
        }
    }, [props]);

    useEffect(() => {
        calculateWorkingDays();
        calculateMonthWorkingHours();
    }, [fromDate, toDate]);

    useEffect(() => {
        form.setFieldsValue({ joppdCode: joppdCode });
    }, [joppdCode]);

    useEffect(() => {
        if (firstDay) {
            let numberOfMonth = remittance && remittance.includes('B0') ? 0 : 5;
            let startDate = firstDay.clone().subtract(2, 'months');
            setInitialDateTo(startDate.clone().endOf('month'));
            setInitialDateFrom(startDate.clone().subtract(numberOfMonth, 'months').startOf('month'));
        }
    }, [firstDay, remittance]);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 3 },
            { field: 'settingGroup', condition: 'equals', value: 'CALCULATION_PARAMETERS' },
        ];
    };

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'MINIMUM_SICK_LEAVE_REIMBURSEMENT') {
                                setMinHzzoAmount(parseFloat(element.value ? element.value : element.defaultValue));
                            } else if (element.settingKey === 'MAXIMUM_SICK_LEAVE_REIMBURSEMENT') {
                                setMaxHzzoAmount(parseFloat(element.value ? element.value : element.defaultValue));
                            }
                        });
                    }
                }
            }
        );
    };

    async function setValues(dataForm: any) {
        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom, 'yyyy-MM-DD');
            setFromDate(setDateFormat(dataForm.dateFrom, 'yyyy-MM-DD'));
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo, 'yyyy-MM-DD');
            setToDate(setDateFormat(dataForm.dateTo, 'yyyy-MM-DD'));
        }
        if (dataForm.firstDay) {
            dataForm.firstDay = setDateFormat(dataForm.firstDay, 'yyyy-MM-DD');
            setFirstDay(setDateFormat(dataForm.firstDay, 'yyyy-MM-DD'));
        }

        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '8.');
        }

        if (dataForm.employee) {
            dataForm.employee = {
                id: dataForm.employee.id,
                text:
                    dataForm.employee.person.firstName +
                    ' ' +
                    dataForm.employee.person.lastName +
                    ' (' +
                    dataForm.employee.person.oib +
                    ')',
            };
            setEmployeeId(dataForm.employee.id);
        }

        if (dataForm.sickLeave) {
            setRemittance(dataForm.remittance ? dataForm.remittance : undefined);
            setLimit(dataForm.sickLeave.limit);
        }
        if (dataForm.hasOwnProperty('employee')) {
            setEditHeader(geti18nText('employeeSickLeave.edit.title') + ' - ' + dataForm.employee.text);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        if (props && props.addedData && props.addedData.value) {
            let data = props.addedData.value;
            let newDataForm: any = {};

            if (data.employee) {
                if (data?.employee?.text?.props?.children[0]?.props?.children) {
                    setEditHeader(
                        geti18nText('employeeSickLeave.edit.title') +
                            ' - ' +
                            data.employee.text.props.children[0].props.children
                    );
                } else {
                    setEditHeader(geti18nText('employeeSickLeave.edit.title') + ' - ' + data.employee.text);
                }
                newDataForm.employee = data.employee;
                setEmployeeId(data.employee.id);
            }
            if (data.dateFrom) {
                newDataForm.dateFrom = setDateFormat(data.dateFrom, 'yyyy-MM-DD');
                setFromDate(setDateFormat(data.dateFrom, 'yyyy-MM-DD'));

                newDataForm.firstDay = setDateFormat(data.dateFrom, 'yyyy-MM-DD');
                setFirstDay(setDateFormat(data.dateFrom, 'yyyy-MM-DD'));
            }
            if (data.dateTo) {
                newDataForm.dateTo = setDateFormat(data.dateTo, 'yyyy-MM-DD');
                setToDate(setDateFormat(data.dateTo, 'yyyy-MM-DD'));
            }
            form.setFieldsValue(newDataForm);
        }
    };

    const onModalClose = () => {
        setFromDate(undefined);
        setToDate(undefined);
        setJoppdCode('');
        setDataForm(null);
        form.resetFields();
        setEmployeeId(undefined);
        setEditHeader(geti18nText('employeeSickLeave.edit.new'));
    };

    const calculateMonthWorkingHours = () => {
        if (fromDate) {
            let days = getWorkingDays(fromDate.clone().endOf('month'), fromDate.clone().startOf('month'));
            let workingHours = (days - 1) * 8;
            setMonthWorkingHours(workingHours);
        }
    };

    const calculateWorkingDays = () => {
        if (fromDate && toDate) {
            let days = getWorkingDays(toDate, fromDate);
            form.setFieldsValue({ workingDays: days });
            calculateAmount();
        }
    };

    const calculateAmount = () => {
        let days = form.getFieldValue(['workingDays']);
        let hourlyRate = form.getFieldValue(['hourlyRate']);
        let hoursPerDay = form.getFieldValue(['hoursPerDay']);
        if (days && hourlyRate) {
            let amount = days * hoursPerDay * hourlyRate;
            form.setFieldsValue({ amount: amount });
        }
    };

    const calculateHourlyRate = () => {
        let days = form.getFieldValue(['workingDays']);
        let amount = form.getFieldValue(['amount']);
        let hoursPerDay = form.getFieldValue(['hoursPerDay']);
        if (days && amount) {
            let hourlyRate = amount / (days * hoursPerDay);
            form.setFieldsValue({ hourlyRate: hourlyRate });
        }
    };

    const hourlyRateValidator = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '' && limit) {
            let maxHzzoHourlyRate = maxHzzoAmount / monthWorkingHours;
            let minHzzoHourlyRate = minHzzoAmount / monthWorkingHours;

            if (value > maxHzzoHourlyRate) {
                callback(geti18nText('employeeSickLeave.validator.max') + ' ' + numberFormat(maxHzzoHourlyRate));
            } else if (value < minHzzoHourlyRate) {
                callback(geti18nText('employeeSickLeave.validator.min') + ' ' + numberFormat(minHzzoHourlyRate));
            } else {
                callback();
            }
        } else {
            callback();
        }
    };

    const sickLeaveValidator = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            let sickLeave: any = setSearchFormat(value);
            if (sickLeave.hasOwnProperty('id')) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.SICK_LEAVE.EDIT + '/' + sickLeave.id, undefined).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            if (result.data) {
                                setRemittance(result.data.remittance ? result.data.remittance : undefined);
                                setLimit(result.data.limit);
                                if (dataForm === null) {
                                    setJoppdCode(result.data.joppdCode);
                                }
                                let startDate = form.getFieldValue(['firstDay']);
                                let endDate = form.getFieldValue(['dateFrom']);
                                if (startDate && endDate) {
                                    let days = getWorkingDays(endDate, startDate);
                                    if (!result.data.founded && days > 42) {
                                        callback(geti18nText('employeeSickLeave.validator.founded'));
                                    } else {
                                        callback();
                                    }
                                } else {
                                    callback();
                                }
                            } else {
                                callback();
                            }
                        } else {
                            callback();
                        }
                    }
                );
            } else {
                callback();
            }
        } else {
            callback();
        }
    };

    const dateFromValidator = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (disabledDateFrom(value)) {
                callback(geti18nText('employeeSickLeave.validator.dateFrom'));
            } else {
                callback();
            }
        } else {
            callback();
        }
    };

    const disabledDateFrom = (current: any) => {
        if (form.getFieldValue('firstDay')) {
            return current < moment(form.getFieldValue('firstDay'));
        }
    };

    const dateToValidator = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (disabledDateTo(value)) {
                callback(geti18nText('employeeSickLeave.validator.dateTo'));
            } else {
                callback();
            }
        } else {
            callback();
        }
    };

    const disabledDateTo = (current: any) => {
        if (form.getFieldValue('dateFrom')) {
            return (
                moment(current) < moment(form.getFieldValue('dateFrom')) ||
                moment(current) > moment(form.getFieldValue('dateFrom')).endOf('month')
            );
        }
    };

    const setDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '8.');
    };

    const getCustomFooterContent = canCreate() && (
        <div style={{ float: 'left' }}>
            <Button onClick={() => setModalVisible(true)} block disabled={!employeeId}>
                {geti18nText('report.r1Download')}
            </Button>
        </div>
    );

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_SICK_LEAVE.EDIT}
                setValues={setValues}
                width={800}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate()}
                form={form}
                buttonsClassName="buttons-sticky"
                goBack={() => {
                    history.goBack();
                }}
                paramsId={id}
                {...props}
                normalize={(values: any) => {
                    if (values.employee) values.employee = getSearchFormat(values.employee);
                    if (values.sickLeave) values.sickLeave = getSearchFormat(values.sickLeave);
                    if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                    if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                    if (values.firstDay) values.firstDay = getDateFormat(values.firstDay);
                    if (values.joppdCode) values.joppdCode = values.joppdCode.code;
                    return values;
                }}
                shortcuts={true}
                nyTestId="employee-sick-leave-edit"
                checkIsFormChanged={true}
                customFooterContent={getCustomFooterContent}
            >
                <Row gutter={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="sick-leave" />}
                                nyTestId="employee"
                                autoFocus={true}
                                onChange={(value: any) => {
                                    setEmployeeId(value && value.id > 0 ? value.id : undefined);
                                }}
                                order="person.last_name, person.first_name"
                                disabled={props && props.addedData && props.addedData.employee}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <SickLeaveSearch
                            label={geti18nText('employeeSickLeave.edit.sickLeave')}
                            name="sickLeave"
                            required={true}
                            validator={sickLeaveValidator}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employeeSickLeave.edit.physicianCode')} name="physicianCode">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employeeSickLeave.edit.joppdCode')} name="joppdCode">
                            <NySearchField
                                url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                setDefaultFilterValue={setDefaultFilterValue}
                                map={{ id: 'id', label: 'code' }}
                                searchBy="code"
                                nyTestId="joppdCode"
                                SearchPopupComponent={<JoppdCodeIndex defaultFilteredValue={setDefaultFilterValue} />}
                                itemName="code"
                                order="code"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={geti18nText('employeeSickLeave.edit.diagnosis')} name="diagnosis">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.remittance')}
                            name="remittance"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.firstDay')}
                            name="firstDay"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                style={{ width: '100%' }}
                                onChange={(date: any) => setFirstDay(date)}
                                nyTestId="date-first"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.from')}
                            name="dateFrom"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                                {
                                    validator: dateFromValidator,
                                },
                            ]}
                        >
                            <NyDatePicker
                                style={{ width: '100%' }}
                                disabledDate={disabledDateFrom}
                                onChange={(date: any) => setFromDate(date)}
                                nyTestId="date-from"
                                defaultPickerValue={firstDay}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.to')}
                            name="dateTo"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                                {
                                    validator: dateToValidator,
                                },
                            ]}
                        >
                            <NyDatePicker
                                style={{ width: '100%' }}
                                disabledDate={disabledDateTo}
                                onChange={(date: any) => setToDate(date)}
                                nyTestId="date-to"
                                defaultPickerValue={fromDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.days')}
                            name="workingDays"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyInputNumber
                                disabled
                                style={{ width: '100%' }}
                                onChange={calculateWorkingDays}
                                nyTestId="workdays"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.hoursPerDay')}
                            name="hoursPerDay"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={8}
                        >
                            <NyInputNumber
                                style={{ width: '100%' }}
                                onChange={calculateWorkingDays}
                                nyTestId="hoursperday"
                                min={1}
                                max={8}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('employeeSickLeave.edit.hourlyRate')}
                            name="hourlyRate"
                            rules={[
                                {
                                    validator: hourlyRateValidator,
                                },
                            ]}
                        >
                            <NyInputNumber
                                isDecimal={true}
                                style={{ width: '100%' }}
                                onChange={calculateAmount}
                                nyTestId="hourly-rate"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employeeSickLeave.edit.amount')} name="amount">
                            <NyInputNumber
                                isDecimal={true}
                                style={{ width: '100%' }}
                                onChange={calculateHourlyRate}
                                nyTestId="amount"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </NyDataEdit>
            <R1Modal
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                employeeId={employeeId}
                initialDateFrom={initialDateFrom}
                initialDateTo={initialDateTo}
            />
        </>
    );
};

export default EmployeeSickLeaveEdit;
