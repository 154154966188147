import { geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import TravelVehiclePersonalIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TravelVehiclePersonalEdit from './edit';

const TravelVehiclePersonalSearch = ({
    required = true,
    onChange,
    disabled = false,
    setDefaultFilterValueVehicle,
    vehicleType,
    travelWarrantEmployee,
}: any) => {
    return (
        <Form.Item
            label={geti18nText('travelWarrantCo2.table.column.travelVehicle')}
            name="travelVehicle"
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                autoFocus
                url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                map={{
                    id: 'id',
                    label: 'name',
                }}
                searchBy="name"
                SearchPopupComponent={
                    <TravelVehiclePersonalIndex
                        vehicleType={vehicleType}
                        newButtonTextPersonal={true}
                        sort={true}
                        employeeId={travelWarrantEmployee}
                    />
                }
                AddNewModalComponent={TravelVehiclePersonalEdit}
                nyTestId="travel-vehicle"
                setDefaultFilterValue={setDefaultFilterValueVehicle}
                order="sort"
                orderType="asc"
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default TravelVehiclePersonalSearch;
