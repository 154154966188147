import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationIndex from '../payment-calculation';

const IncomeIndex = () => {
    useHelper('payroll/calculation/income');

    return <PaymentCalculationIndex type={1} />;
};

export default IncomeIndex;
