import { geti18nText } from '@nybble/nyreact';
import { Button, Card, Col, Divider, Empty, Popover, Radio, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';

import {
    BookOutlined,
    CoffeeOutlined,
    MedicineBoxOutlined,
    OrderedListOutlined,
    QuestionCircleFilled,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import IconsModal from '../../../../components/icons-modal/IconsModal';
import NyFullCalendar from '../../../../components/ny-full-calendar/NyFullCalendar';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { setPropOverviewEmployeeLeave } from '../../../../slices/propSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import { getLocationStateOpenModal, userIsNotificationRecipient } from '../../../../utils/Utils';
import EmployeeLeavePersonalLightWidget from '../../../dashboard/light-widgets/EmployeeLeavePersonalLightWidget';
import EmployeeAnnualLeaveIndex from '../employee-leave/EmployeeAnnualLeaveIndex';
import EmployeeLeaveReqEdit from '../employee-leave/EmployeeLeaveReqEdit';
import EmployeeLeaveReqIndex from '../employee-leave/EmployeeLeaveReqIndex';
import ActionsView from './ActionsView';

const EmployeeLeaveOverView = ({ hideRadioButtons = false }: any) => {
    const location: any = useLocation();
    const dispatch = useDispatch();

    const { employee } = useSelector((state: RootState) => state.employee);

    const { propOverviewEmployeeLeave } = useSelector((state: RootState) => state.prop);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const employeeLeaveType = useEnum('EMPLOYEE_LEAVE_TYPE');

    useHelper('human/employee_leave');

    const [listView, setListView] = useState<any>(0);
    const [refresh, setRefresh] = useState<any>(0);
    const [isVacation, setIsVacation] = useState(true);
    const [activeList, setActiveList] = useState<any>(propOverviewEmployeeLeave);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const [employeeLeaveModal, setEmployeeLeaveModal] = useState(false);

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    useEffect(() => {
        if (location?.state?.openModal) {
            const vacationLocation = location?.state?.isVacation;
            const openModalLocation = location?.state?.openModal;
            setIsVacation(vacationLocation);
            setEmployeeLeaveModal(openModalLocation);
        }
        if (location?.state?.activeTab) {
            setListView(location?.state?.activeTab);
        }
        getLocationStateOpenModal(location, setEmployeeLeaveModal);
    }, [location.state]);

    useEffect(() => {
        if (propOverviewEmployeeLeave > 1) {
            setActiveList(propOverviewEmployeeLeave);
        } else {
            setActiveList(1);
        }
    }, [propOverviewEmployeeLeave]);

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeLeave();
    };

    const canCreateAllLeave = () => {
        return HumanResourcesRights.isAdmin() || (moduleAccessRight && moduleAccessRight.includes('LEAVE'));
    };

    const canCreateActions = () => {
        return (
            HumanResourcesRights.isAdmin() ||
            userIsNotificationRecipient(employee, notificationType.EMPLOYEE_PAID_LEAVE_APPROVAL) ||
            userIsNotificationRecipient(employee, notificationType.EMPLOYEE_ANNUAL_LEAVE_APPROVAL)
        );
    };

    const setDefaultFilterValueMyListPersonal = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: employee && employee.id },
        ];
    };

    const setDefaultFilterValueMyList = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'not_equals', value: employee && employee.id },
            {
                field: 'status',
                condition: 'in',
                value: [employeeLeaveStatus.REQUESTED, employeeLeaveStatus.CONFIRMED].toString(),
            },
        ];
    };

    const setApprovalFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'equals', value: employeeLeaveStatus.REQUESTED },
            {
                field: 'type',
                condition: 'in',
                value: [
                    employeeLeaveType.ANNUAL_LEAVE,
                    employeeLeaveType.PAID_LEAVE,
                    employeeLeaveType.UNPAID_LEAVE,
                ].toString(),
            },
        ];
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });

            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const initialSelectedOptionsEmployeeLeave = () => {
        if (activeList == 3 && canCreateActions()) {
            return [employeeLeaveStatus.REQUESTED];
        } else if (activeList == 2) {
            return [employeeLeaveStatus.REQUESTED, employeeLeaveStatus.CONFIRMED];
        } else if (activeList == 1) {
            return [employeeLeaveStatus.REQUESTED, employeeLeaveStatus.CONFIRMED, employeeLeaveStatus.REJECTED];
        }
    };

    const resolveSearch = () => {
        if (activeList == 2 && (canCreateAllLeave() || canCreateActions())) {
            return setDefaultFilterValueMyList();
        } else if (activeList == 3 && canCreateActions()) {
            return setApprovalFilterValue();
        } else {
            return setDefaultFilterValueMyListPersonal();
        }
    };

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        dispatch(setPropOverviewEmployeeLeave(e.target.value > 0 ? e.target.value : 1));
    };

    const resolveEmployeeLeaveTitle = () => {
        if (activeList == 2) {
            return geti18nText('employee.leave.all');
        } else if (activeList == 3) {
            return geti18nText('employee.leave.actions');
        } else {
            return geti18nText('employee.leave.personal');
        }
    };

    return (
        <>
            {listView == 0 ? (
                <>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Row gutter={24}>
                                {hideRadioButtons ? (
                                    <> </>
                                ) : (
                                    <Radio.Group
                                        value={activeList}
                                        buttonStyle="solid"
                                        optionType="button"
                                        style={{ margin: '5px 5px 10px 10px' }}
                                    >
                                        <Radio.Button value={1} onChange={onChangeActiveList}>
                                            {geti18nText('employee.leave.personal')}
                                        </Radio.Button>
                                        {canCreateAllLeave() && (
                                            <Radio.Button value={2} onChange={onChangeActiveList}>
                                                {geti18nText('employee.leave.all')}
                                            </Radio.Button>
                                        )}
                                        {canCreateActions() && (
                                            <Radio.Button value={3} onChange={onChangeActiveList}>
                                                {geti18nText('employee.leave.actions')}
                                            </Radio.Button>
                                        )}
                                    </Radio.Group>
                                )}
                            </Row>
                            {activeList == 1 && (
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <EmployeeLeavePersonalLightWidget
                                            isOverview
                                            setIsVacation={setIsVacation}
                                            setAddAbsenceModalVisible={setEmployeeLeaveModal}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Divider orientation="left" style={{ fontWeight: 'normal', marginTop: '1rem' }}>
                            {resolveEmployeeLeaveTitle()}
                        </Divider>
                        <Col span={24}>
                            {activeList != 3 ? (
                                <Card
                                    style={{ width: '100%', marginTop: activeList == 1 ? '10px' : '0px' }}
                                    className={`ny-widget-card`}
                                    bodyStyle={{
                                        maxHeight: activeList == 1 ? '580px' : '640px',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        padding: '0px 5px 0px 5px',
                                        marginBottom: '5px',
                                    }}
                                    type="inner"
                                >
                                    <NyFullCalendar
                                        absenceCalendar={true}
                                        keys={['employeeLeave']}
                                        initialView={'dayGridMonth'}
                                        height={activeList == 1 ? '490px' : '590px'}
                                        initialSelectedOptions={JSON.stringify([
                                            {
                                                keyType: 'employeeLeave',
                                                selectedOptions: initialSelectedOptionsEmployeeLeave(),
                                            },
                                        ])}
                                        isAdmin={canCreateActions()}
                                        search={resolveSearch()}
                                        isPersonal={activeList == 1 ? true : false}
                                        activeList={activeList}
                                        refreshView={refresh}
                                        employeeUrl={CONSTANTS_REQ.EMPLOYEE.EMPLOYEE_LEAVE_SEARCH}
                                        isVacation={isVacation}
                                        canCreate={canCreate() || canCreateAllLeave() || canCreateActions()}
                                        canCreateAdmin={canCreateActions()}
                                    ></NyFullCalendar>
                                </Card>
                            ) : (
                                <ActionsView
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    setApprovalFilterValue={setApprovalFilterValue()}
                                />
                            )}
                        </Col>
                    </Row>
                </>
            ) : listView == 1 ? (
                <>
                    <EmployeeLeaveReqIndex
                        search={resolveSearch()}
                        employeeOverview={true}
                        listView={listView}
                        setListView={setListView}
                        activeList={activeList}
                        refreshView={refresh}
                        setRefreshView={setRefresh}
                        isHumanResources={false}
                        defaultStatusFilter={initialSelectedOptionsEmployeeLeave()?.toString()}
                        defaultFilterType={[
                            employeeLeaveType.ANNUAL_LEAVE,
                            employeeLeaveType.PAID_LEAVE,
                            employeeLeaveType.UNPAID_LEAVE,
                        ].toString()}
                        scroll={{ y: 620, x: 800 }}
                    />
                </>
            ) : listView == 2 ? (
                <>
                    <EmployeeAnnualLeaveIndex
                        isOverview={true}
                        listView={listView}
                        setListView={setListView}
                        employeeOverview={true}
                        refreshView={refresh}
                        setRefreshView={setRefresh}
                        isPersonal={false}
                        employeeId={employee && employee.id}
                        isHumanResources={false}
                        activeList={activeList}
                        scroll={{ y: 620, x: 800 }}
                    />
                </>
            ) : (
                <Empty />
            )}
            <EmployeeLeaveReqEdit
                editProps={{
                    visible: employeeLeaveModal,
                    setVisible: setEmployeeLeaveModal,
                    refresh: refresh,
                    setRefresh: setRefresh,
                    canCreate: canCreate() || canCreateAllLeave() || canCreateActions(),
                    canCreateAdmin: canCreateActions(),
                    editHeader: isVacation
                        ? geti18nText('employee.leave.new.request')
                        : geti18nText('sickLeave.edit.new'),
                    submitButtonText: isVacation
                        ? geti18nText('employee.leave.send.request')
                        : geti18nText('sickLeave.table.add'),
                    isHumanResources: false,
                    isVacation: isVacation,
                }}
            />
            {listView == 0 && (
                <div className="overview-buttons-sticky" style={{ zIndex: '2' }}>
                    <Popover
                        title={geti18nText('travelWarrantPlan.overview.legend')}
                        placement="topLeft"
                        content={
                            <>
                                {activeList == 3 ? (
                                    <IconsModal
                                        enumType="EMPLOYEE_LEAVE_TYPE"
                                        textType="app.enum.EMPLOYEE_LEAVE_TYPE."
                                    />
                                ) : (
                                    <IconsModal
                                        enumType="EMPLOYEE_LEAVE_STATUS"
                                        textType="app.enum.EMPLOYEE_LEAVE_STATUS."
                                    />
                                )}
                            </>
                        }
                        style={{ marginLeft: '4rem' }}
                    >
                        <Button
                            size="large"
                            style={{ margin: '0.6rem' }}
                            icon={<QuestionCircleFilled className="travel-warrant-overview-legend" />}
                        />
                    </Popover>

                    <div style={{ width: '100%', justifyContent: 'end', display: 'flex' }}>
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                setIsVacation(true);
                                setEmployeeLeaveModal(true);
                            }}
                            icon={<CoffeeOutlined />}
                        >
                            {' '}
                            {geti18nText('employee.leave.add.vacation')}
                        </Button>

                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                setIsVacation(false);
                                setEmployeeLeaveModal(true);
                            }}
                            icon={<MedicineBoxOutlined />}
                        >
                            {geti18nText('employee.leave.add.medical.leave')}
                        </Button>

                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => setListView(1)}
                            icon={<OrderedListOutlined />}
                            hidden={listView === 1}
                        >
                            {geti18nText('employee.leave.list')}
                        </Button>

                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => setListView(2)}
                            icon={<BookOutlined />}
                        >
                            {geti18nText('employee.leave.annualList')}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default EmployeeLeaveOverView;
