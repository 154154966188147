import { DeleteTwoTone, FieldTimeOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row, Space, Tooltip, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../../rights/tasksRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookUserPerson } from '../../../../../utils/Filters';
import {
    getDateFormat,
    getErrorNotificationMessage,
    getRowDateFormatFromTimestamp,
    okNotification,
    onKeyDown,
    setDateFormat,
} from '../../../../../utils/Utils';
const { Text } = Typography;

const WorkActivityIndex = ({
    selectedTaskId,
    parentTaskIdState,
    isIndex = false,
    setRefreshWorktimeTable,
    refreshWorktimeTable,
    activeKey,
    scroll = { y: 480, x: 400 },
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [worktimeActivityForm] = Form.useForm();
    const [worktimeActivityId, setWorktimeActivityId] = useState<any>(-1);
    const [spentTimeModalVisible, setSpentTimeModalVisible] = useState(false);
    const [disabledWorktimeActivity, setDisabledWorktimeActivity] = useState<boolean>(false);

    const [worktimeUserEmployeeId, setWorktimeUserEmployeeId] = useState<any>(-1);
    const { employee } = useSelector((state: RootState) => state.employee);
    const [totalWorkHoursLoading, setTotaWorkHoursLoading] = useState<boolean>(false);
    const [totalWorkHours, setTotaWorkHours] = useState<any>(0);

    const setDefaultFilterValueWorktimeActivity = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const onKeyUp = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    useEffect(() => {
        getTotalWorkTime();
    }, []);

    useEffect(() => {
        getTotalWorkTime();
    }, [refreshWorktimeTable, parentTaskIdState]);

    const getTotalWorkTime = () => {
        setTotaWorkHoursLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.TASK_WORKTIME_ACTIVITY.TOTAL.replace(
                '{parentTaskId}',
                parentTaskIdState != -1 ? parentTaskIdState : selectedTaskId
            ),
            undefined
        )
            .then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setTotaWorkHours(result.data.totalHours);
                }
            })
            .finally(() => {
                setTotaWorkHoursLoading(false);
            });
    };

    const closeWorktimeActivityModal = (event?: any) => {
        setWorktimeActivityId(-1);
        setDisabledWorktimeActivity(false);
        setSpentTimeModalVisible(false);
        worktimeActivityForm.resetFields();
        event?.stopPropagation();
        event?.preventDefault();
    };

    const saveWorktimeActivity = (deleteWorktime?: boolean) => {
        worktimeActivityForm.validateFields();
        if (worktimeActivityForm.getFieldValue('id')) {
            updateWorktimeActivity(deleteWorktime);
        } else {
            createWorktimeActivity();
        }
    };

    const normalizeWorktimeActivity = (deleteWorktime?: boolean) => {
        let values: any = {
            task: { id: '' },
            date: {},
            remark: {},
            hours: {},
            active: true,
        };

        if (deleteWorktime) {
            values.active = false;
        }
        values.date = getDateFormat(worktimeActivityForm.getFieldValue('date'));
        values.remark = worktimeActivityForm.getFieldValue('remark');
        values.hours = worktimeActivityForm.getFieldValue('hours');
        values.id = worktimeActivityForm.getFieldValue('id');
        values.task.id = selectedTaskId;

        return values;
    };

    const createWorktimeActivity = () => {
        let values: any = normalizeWorktimeActivity();
        worktimeActivityForm
            .validateFields()
            .then(() => {
                NyRequestResolver.requestPost(CONSTANTS_REQ.TASK_WORKTIME_ACTIVITY.EDIT, undefined, {
                    ...values,
                }).then((result: any) => {
                    if ((result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) && result.data) {
                        okNotification();
                        setRefreshWorktimeTable((prevValue: number) => prevValue + 1);
                        closeWorktimeActivityModal();
                    } else {
                        getErrorNotificationMessage(result);
                        setTotaWorkHoursLoading(false);
                    }
                });
            })
            .catch(() => {
                if (setTotaWorkHoursLoading) {
                    setTotaWorkHoursLoading(false);
                }
            });
    };

    const updateWorktimeActivity = (deleteWorktime?: boolean) => {
        let values: any = normalizeWorktimeActivity(deleteWorktime);
        worktimeActivityForm
            .validateFields()
            .then(() => {
                NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_WORKTIME_ACTIVITY.EDIT + '/' + values.id, undefined, {
                    ...values,
                }).then((result: any) => {
                    if ((result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) && result.data) {
                        okNotification();
                        setRefreshWorktimeTable((prevValue: number) => prevValue + 1);
                        closeWorktimeActivityModal();
                    } else {
                        getErrorNotificationMessage(result);
                        setTotaWorkHoursLoading(false);
                    }
                });
            })
            .catch(() => {
                if (setTotaWorkHoursLoading) {
                    setTotaWorkHoursLoading(false);
                }
            });
    };

    const DeleteWorktimeAction = (record: any) => {
        return (
            <React.Fragment>
                {(TasksRights.canDeleteOwnWorktimeActivity() || TasksRights.canDeleteAllWorktimeActivity()) && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <NyModalConfirm
                                title={geti18nText('team.edit.work.time.delete.confirm')}
                                onConfirm={() => {
                                    deleteWorktimeActivity(record.record);
                                }}
                            >
                                <Button type="link" disabled={false}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </NyModalConfirm>
                        </Tooltip>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const loggedTimeColumns = [
        {
            title: geti18nText('team.table.column.work.time.date'),
            dataIndex: 'date',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record && record.date) {
                    return moment(record.date).format('DD.MM.yyyy');
                }
            },
        },
        {
            title: geti18nText('team.table.column.work.time.createdBy'),
            dataIndex: 'userPerson',
            width: '14%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookUserPerson(),
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div
                            style={{ marginLeft: '2px' }}
                        >{`${record?.userPerson?.firstName} ${record?.userPerson?.lastName}`}</div>
                    );
                }
            },
        },
        {
            title: geti18nText('team.table.column.work.time.spent.time'),
            dataIndex: 'hours',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (description: any, record: any) => {
                if (record && record.hours) {
                    return <div style={{ float: 'right', marginRight: '8px' }}>{record.hours.toFixed(2)}</div>;
                }
            },
        },
        {
            title: geti18nText('team.table.column.work.time.note'),
            dataIndex: 'remark',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.remark) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '620px' }} title={record.remark}>
                                <div style={{ maxWidth: '100%' }}>
                                    <p className={'ny-table-paragraph'}>{record && record.remark} </p>
                                </div>
                            </Tooltip>
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('team.table.column.work.time.delete'),
            width: '5%',
            render: (record: any) => {
                if (record) {
                    return (
                        ((TasksRights?.canDeleteOwnWorktimeActivity() && record?.userPerson?.id == employee?.id) ||
                            TasksRights?.canDeleteAllWorktimeActivity()) && <DeleteWorktimeAction record={record} />
                    );
                }
            },
        },
    ];

    const deleteWorktimeActivity = (data: any) => {
        let values: any = { task: { id: '' } };
        values.date = getDateFormat(data.date);
        values.remark = data.remark;
        values.hours = data.hours;
        values.id = data.id;
        values.task.id = data.task.id;
        values.active = false;

        NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_WORKTIME_ACTIVITY.EDIT + '/' + values.id, undefined, {
            ...values,
        })
            .then((result: any) => {
                if ((result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) && result.data) {
                    okNotification();
                    setRefreshWorktimeTable((prevValue: number) => prevValue + 1);
                    closeWorktimeActivityModal();
                } else {
                    getErrorNotificationMessage(result);
                }
            })
            .finally(() => setTotaWorkHoursLoading(false));
    };

    const getWorktimeActivityFooterContent = (
        <>
            {worktimeActivityId != -1 &&
                ((TasksRights.canDeleteOwnWorktimeActivity() && worktimeUserEmployeeId == employee.id) ||
                    TasksRights.canDeleteAllWorktimeActivity()) && (
                    <Button
                        style={{ marginLeft: '8px', float: 'left' }}
                        type="primary"
                        danger
                        onClick={(e: any) => {
                            saveWorktimeActivity(true);
                        }}
                    >
                        {geti18nText('app.default.button.delete')}
                    </Button>
                )}
            <Button
                onClick={(e: any) => {
                    closeWorktimeActivityModal(e);
                }}
            >
                {geti18nText('app.default.button.close')}
            </Button>
            {!disabledWorktimeActivity && (
                <Button
                    type="primary"
                    onClick={(e: any) => {
                        if (worktimeActivityForm.getFieldValue('hours') < 0.1) {
                            return;
                        }
                        worktimeActivityForm.validateFields();
                        saveWorktimeActivity();
                    }}
                >
                    {geti18nText('app.default.button.save')}
                </Button>
            )}
        </>
    );

    return (
        <>
            {totalWorkHoursLoading ? (
                <NySpinner />
            ) : (
                <>
                    {isIndex && (
                        <>
                            <Text strong>
                                {totalWorkHoursLoading ? (
                                    <div style={{ float: 'left' }}>
                                        <NySpinner />
                                    </div>
                                ) : (
                                    geti18nText('team.tab.work.time.total', [totalWorkHours.toFixed(2)])
                                )}
                            </Text>
                            <div style={{ marginTop: totalWorkHoursLoading ? '0px' : '-18px' }}>
                                <NyDataTable
                                    nyId="tasks-task-worktime-activity-table"
                                    url={CONSTANTS_REQ.TASK_WORKTIME_ACTIVITY.LIST.replace(
                                        '{parentTaskId}',
                                        selectedTaskId
                                    )}
                                    addNewButtonText={geti18nText('tasks.list.add')}
                                    fetchWhenChange={refreshWorktimeTable}
                                    buttonsClassName="buttons-sticky"
                                    showRecordModal={true}
                                    onRowSelect={(data: any) => {
                                        if (employee.id == data.userEmployee.id) {
                                            setDisabledWorktimeActivity(false);
                                        } else {
                                            setDisabledWorktimeActivity(true);
                                        }
                                        setSpentTimeModalVisible(true);

                                        setWorktimeUserEmployeeId(data.userEmployee.id);
                                        setWorktimeActivityId(data.id);

                                        worktimeActivityForm.setFieldsValue({
                                            remark: data.remark,
                                            date: setDateFormat(getRowDateFormatFromTimestamp(data.date)),
                                            hours: data.hours,
                                            id: data.id,
                                        });
                                    }}
                                    columns={loggedTimeColumns}
                                    setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
                                    scroll={scroll}
                                    onDataLoaded={table.onDataLoaded}
                                    setDefaultFilterValue={() =>
                                        table.setDefaultFilterValue(setDefaultFilterValueWorktimeActivity())
                                    }
                                    setDefaultPageSize={table.setDefaultPageSize()}
                                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                                    hideNewButton={true}
                                    shortcuts={true}
                                />
                            </div>
                        </>
                    )}
                    {!isIndex && (
                        <>
                            {activeKey != '5' ? (
                                <Tooltip placement="top" title={geti18nText('team.edit.add.spent.time')}>
                                    <Button
                                        type="primary"
                                        style={{ marginLeft: '5px' }}
                                        onClick={() => {
                                            setSpentTimeModalVisible(true);
                                        }}
                                        icon={<FieldTimeOutlined />}
                                    ></Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    type="primary"
                                    style={{ marginLeft: '5px' }}
                                    onClick={() => {
                                        setSpentTimeModalVisible(true);
                                    }}
                                    icon={<FieldTimeOutlined />}
                                >
                                    {geti18nText('team.edit.add.spent.time')}
                                </Button>
                            )}
                        </>
                    )}
                    {spentTimeModalVisible && (
                        <Modal
                            title={
                                <div style={{ textAlign: 'left' }}>
                                    {geti18nText('team.table.edit.work.time.title')}
                                </div>
                            }
                            open={spentTimeModalVisible}
                            onCancel={(e: any) => {
                                closeWorktimeActivityModal(e);
                            }}
                            footer={getWorktimeActivityFooterContent}
                            onOk={() => {
                                createWorktimeActivity();
                                setSpentTimeModalVisible(false);
                            }}
                            maskClosable={false}
                            width={600}
                            getContainer={false}
                        >
                            <Form
                                form={worktimeActivityForm}
                                layout="vertical"
                                {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('team.table.edit.work.time.date')}
                                            name="date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={moment()}
                                        >
                                            <NyDatePicker
                                                autoFocus={true}
                                                defaultPickerValue={moment()}
                                                format="DD.MM.YYYY"
                                                disabled={disabledWorktimeActivity}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('team.table.edit.work.time.spent.time')}
                                            name={'hours'}
                                            labelAlign={'left'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                disabled={disabledWorktimeActivity}
                                                defaultValue={''}
                                                isDecimal={true}
                                                decimalPlaces={2}
                                                min={0.1}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col offset={0} span={24}>
                                        <Form.Item
                                            label={geti18nText('team.table.edit.work.time.note')}
                                            name={'remark'}
                                            labelAlign={'left'}
                                        >
                                            <TextArea
                                                disabled={disabledWorktimeActivity}
                                                // onKeyDown={onKeyDownTextAreaCustom}
                                                rows={4}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default WorkActivityIndex;
