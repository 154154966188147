import { PlusCircleOutlined } from '@ant-design/icons';
import { NyInputNumber, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useEnum from '../../../../hooks/useEnum';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setEnumFormat,
} from '../../../../utils/Utils';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import MeasureUnitIndex from '../measure-unit';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';

const RequestedItem = ({ addItemInBasket, type }: any) => {
    const [modalForm] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [fileList, setFileList] = useState<any>([]);
    const fileUploadRef = useRef<any>();
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);

    const saveItem = () => {
        modalForm
            .validateFields()
            .then((values: any) => {
                values.type = type;
                if (values.availabilityStatus) values.availabilityStatus = getEnumFormat(values.availabilityStatus);
                if (values.measureUnit) {
                    values.measureUnit = getSearchFormat(values.measureUnit);
                    if (values.measureUnit.id < 1) {
                        delete values.measureUnit;
                    }
                }
                if (fileList.length > 0 && fileList[0]) {
                    values.fileId = fileList[0].id;
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.ITEM.EDIT, undefined, values).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        if (uploadFilesRef?.current) {
                            uploadFilesRef.current.filesUpload(result?.data?.id);
                        }
                        if (fileUploadRef?.current) {
                            fileUploadRef.current.filesUpload(result?.data?.id);
                        }
                        okNotification();
                        setVisible(false);
                        modalForm.resetFields();
                        if (addItemInBasket) {
                            result.data.quantity = values.quantity ? values.quantity : 1;
                            addItemInBasket(result.data);
                        }
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo);
            });
    };

    return (
        <>
            <Button
                icon={<PlusCircleOutlined />}
                style={{ textAlign: 'center', marginLeft: '10px' }}
                onClick={() => setVisible(true)}
            >
                {geti18nText('webshopItem.requestedItem.button')}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('webshopItem.requestedItem.title')}
                    maskClosable={false}
                    onOk={(event: any) => {
                        event.stopPropagation();
                        event.preventDefault();
                        saveItem();
                    }}
                    onCancel={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    forceRender={true}
                    closable={false}
                    width={900}
                    okText={geti18nText('app.default.button.save')}
                >
                    <Form form={modalForm} component={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <NyImageUpload
                                    documentType={documentTypeEnum.ITEM_IMAGE}
                                    files={fileList}
                                    setFiles={setFileList}
                                    ref={fileUploadRef}
                                />
                            </Col>
                            <Col span={12}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('item.edit.availabilityStatus')}
                                            name="availabilityStatus"
                                            initialValue={setEnumFormat('ITEM_AVAILABILITY_STATUS', 5)}
                                        >
                                            <NySearchField
                                                options={getEnumArray('ITEM_AVAILABILITY_STATUS')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                style={{ width: '100%' }}
                                                className={''}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('item.edit.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('item.edit.description')} name="description">
                                    <Input.TextArea
                                        autoSize={{ minRows: 2, maxRows: 3 }}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('webshopItem.edit.measureUnit')}
                                    name="measureUnit"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.MEASURE_UNITE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        SearchPopupComponent={<MeasureUnitIndex disabled />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('webshopItem.edit.quantity')}
                                    name="quantity"
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyInputNumber min={1} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <NyFilesUpload
                            documentType={documentTypeEnum.ITEM_REQUEST}
                            ref={uploadFilesRef}
                            employee={employee}
                            maxHeight={'205px'}
                        />
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default RequestedItem;
