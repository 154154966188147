import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import useHelper from '../../../../hooks/useHelper';

import React, { useState } from 'react';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import AttendanceListIndex from './AttendanceListIndex';
import UserCardIndex from './UserCardIndex';
const { TabPane } = Tabs;

const AttendanceRegistrationIndex = () => {
    const [activeKey, setActiveKey] = useState<string>('1');

    useHelper('human/attendance-registration');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    return (
        <>
            <React.Fragment>
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        setActiveKey(key);
                    }}
                >
                    <TabPane tab={geti18nText('menu.attendance')} key="1">
                        {activeKey == '1' && <AttendanceListIndex scroll={{ y: 580, x: 800 }} />}
                    </TabPane>
                    {canCreate() && (
                        <TabPane tab={geti18nText('attendance.card.tab.title')} key="2">
                            {activeKey == '2' && <UserCardIndex scroll={{ y: 580, x: 800 }} />}
                        </TabPane>
                    )}
                </Tabs>
            </React.Fragment>
        </>
    );
};

export default AttendanceRegistrationIndex;
