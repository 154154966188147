import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Tooltip } from 'antd';
import moment from 'moment';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getEnumArray, getTravelWarrantTypeIcon, numberFormat } from '../../../../utils/Utils';
import TravelWageEdit from './edit';

const TravelWageIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    defaultFilteredValue,
    fetchWhenChange,
    isSearchIndex = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('travel_order/codebooks/travelWage');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canExportCSV = () => {
        return TravelWarrantRights.canCsvExportTravelWarrantCodebooks();
    };

    const defaultFilter = () => {
        return defaultFilteredValue ? defaultFilteredValue : table.setDefaultFilterValue();
    };

    const columns = [
        {
            title: geti18nText('travelWage.table.column.id'),
            dataIndex: 'id',
            width: isSearchIndex ? '5%' : '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWage.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWage.table.column.country'),
            dataIndex: ['country', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COUNTRY.SEARCH, 'country.id', 'name'),
        },
        {
            title: geti18nText('travelWage.table.column.amount'),
            dataIndex: 'amount',
            width: '7%',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                return numberFormat(amount);
            },
        },
        {
            title: geti18nText('travelWage.table.column.currency'),
            dataIndex: ['currency', 'isoCode'],
            width: isSearchIndex ? '7%' : 'auto',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.CURRENCY.SEARCH, 'currency.id', 'isoCode', 'isoCode'),
        },
        {
            title: geti18nText('travelWarrantType.table.column.wageType'),
            dataIndex: 'type',
            width: isSearchIndex ? '12%' : '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArray('TRAVEL_WAGE_TYPE')),
            render: (wageType: any) => {
                return getTravelWarrantTypeIcon(wageType);
            },
        },
        {
            title: geti18nText('travelWage.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '400px' }}
                            title={`${record.businessUnit.name}  ${
                                record?.businessUnit?.code ? '(' + record?.businessUnit?.code + ')' : ''
                            }`}
                        >
                            <div className={'description-div-small'}>
                                <p className="description-paragraph">
                                    {record.businessUnit.name + ' '}
                                    {record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : ''}
                                </p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrantType.table.column.validFrom'),
            dataIndex: ['validFrom'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrantType.table.column.validTo'),
            dataIndex: ['validTo'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWage.table.column.active'),
            dataIndex: 'active',
            width: isSearchIndex ? '6%' : '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
            // defaultFilteredValue: defaultFilter,
        },
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-wage-table"
            url={CONSTANTS_REQ.TRAVEL_WAGE.LIST}
            addNewButtonText={geti18nText('travelWage.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={TravelWageEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={defaultFilter}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            nyTestId="travel-wage-table"
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.travelWage')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            readonly={readonly}
            hideButtons={readonly}
            fetchWhenChange={fetchWhenChange}
            headerTitle={geti18nText('menu.travelWage')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.TRAVEL_WAGE_TYPE.' + value);
            },
        },
        {
            businessUnit: (value: any) => {
                return value != undefined && value.name;
            },
        },
        {
            amount: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('travelWage.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('travelWage.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('travelWage.table.column.country'),
            dataIndex: ['country', 'name'],
        },
        {
            title: geti18nText('travelWage.table.column.currency'),
            dataIndex: ['currency', 'isoCode'],
        },
        {
            title: geti18nText('travelWage.table.column.amount'),
            dataIndex: 'amount',
        },
        {
            title: geti18nText('travelWarrantType.table.column.wageType'),
            dataIndex: 'type',
        },
        {
            title: geti18nText('travelWage.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('travelWarrantType.table.column.validFrom'),
            dataIndex: 'validFrom',
        },
        {
            title: geti18nText('travelWarrantType.table.column.validTo'),
            dataIndex: 'validTo',
        },
        {
            title: geti18nText('travelWage.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export default TravelWageIndex;
