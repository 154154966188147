import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import {
    Button,
    Col,
    Collapse,
    Descriptions,
    Form,
    Image,
    Input,
    Modal,
    Popover,
    Row,
    Tooltip,
    Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import StatusHistoryIndex from '../../components/status-history/StatusHistory';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../utils/Constants';
import {
    getDateFormat,
    getErrorNotificationMessage,
    getTaskStatusIcon,
    okNotification,
    onKeyDownTextAreaCustom,
} from '../../utils/Utils';
import TaskEvaluationModal from './TaskEvaluationModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const { Panel } = Collapse;

const CreateTaskUserIndex = ({
    selectedTaskId,
    setSelectedTaskId,
    visible,
    setVisible,
    value,
    setRefreshTable,
}: any) => {
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<any>(false);
    const [data, setData] = useState<any>(null);
    const [metadata, setMetadata] = useState([]);
    const [metadataTemplate, setMetadataTemplate] = useState<any>([]);
    const [hasMetadata, setHasMetadata] = useState<boolean>(false);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<any>(true);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [form] = Form.useForm();
    const [taskId, setTaskId] = useState(null);
    const [metadataValueIsSet, setMetadataValueIsSet] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const enTaskStatus = useEnum('TASK_STATUS');
    const [refresh, setRefresh] = useState(0);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;

    const { Text } = Typography;
    const { TextArea } = Input;
    const user = NySession.getUser();

    const canEvaluate = () => {
        return (
            data?.enableEvaluation &&
            Object.values([data?.user?.id, data?.createdBy]).includes(user?.id) &&
            Object.values([enTaskStatus.RESOLVED, enTaskStatus.PARTIALLY_RESOLVED, enTaskStatus.NOT_RESOLVED]).includes(
                data?.status
            )
        );
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2']);
        }
        setCollapseAll(!collapseAll);
    };

    useEffect(() => {
        if (visible) {
            setVisibleModal(visible);
        }
    }, [visible]);

    useEffect(() => {
        if (value) {
            setTaskId(value.id);
            openTask(value.id);
        }
    }, [value, refresh]);

    useEffect(() => {
        if (metadataTemplate && metadataTemplate.length > 0 && metadata && metadata.length > 0 && !metadataValueIsSet) {
            let newData = [...metadataTemplate];
            metadata.map((item: any) => {
                if (item.value) {
                    const findItem: any = newData.find((row: any) => row.id === item.taskTemplateMetadata.id);
                    const index = newData.findIndex((row: any) => row.id === item.taskTemplateMetadata.id);
                    if (findItem && index > -1) {
                        newData.splice(index, 1, { ...findItem, ...{ value: item.value } });
                    }
                }
            });
            setMetadataValueIsSet(true);
            setMetadataTemplate(newData);
        }
    }, [metadataTemplate, metadata]);

    const fetchMetadataTemplate = (templateId: any) => {
        if (templateId) {
            const url = CONSTANTS_REQ.TASK_TEMPLATE.METADATA;
            const urlWithId = url.replace('{taskTemplateId}', templateId);

            let filter = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
            NyRequestResolver.requestGet(urlWithId, {
                max: 100,
                search: filter,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data && result.data.content) {
                    setHasMetadata(true);
                    setMetadataTemplate(result.data.content);
                }
            });
        }
    };

    const openTask = (taskId: any) => {
        setLoading(true);
        setVisibleModal(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK.EDIT + '/' + taskId, undefined).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setData(result.data);
                if (result.data.taskMetadataList && result.data.taskMetadataList.length > 0) {
                    setHasMetadata(true);
                    setMetadata(result.data.taskMetadataList);
                    fetchMetadataTemplate(result.data?.taskTemplate?.id);
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (selectedTaskId) {
            setTaskId(selectedTaskId);
            openTask(selectedTaskId);
        }
    }, [selectedTaskId, refresh]);

    const cancelation = () => {
        let info = form.getFieldValue(['info']);
        setLoading(true);
        NyRequestResolver.requestPut(CONSTANTS_REQ.TASK.CHANGE_STATUS + '/' + taskId, undefined, {
            id: taskId,
            status: enTaskStatus.CANCELLED,
            info: info,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                setConfirmationModalVisible(false);
                closeModal();
                okNotification();
                setRefreshTable((prevValue: number) => prevValue + 1);
            } else {
                getErrorNotificationMessage(result);
            }
            setLoading(false);
            setButtonLoading(false);
        });
    };

    const closeModal = () => {
        setVisibleModal(false);
        setHasMetadata(false);
        form.setFieldsValue({ info: null });
        setData(null);
        setMetadata([]);
        setMetadataTemplate([]);
        setLoading(false);
        if (setSelectedTaskId) setSelectedTaskId(null);
        if (setVisible) setVisible(false);
        setMetadataValueIsSet(false);
    };

    const getTemplateFormViewTemplateInfo = () => {
        return (
            <>
                {data?.taskTemplate?.fileId || data?.taskTemplate?.description ? (
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={geti18nText('reportProblem.fileId')}>
                                <>
                                    {data?.taskTemplate?.fileId ? (
                                        <Popover
                                            title={''}
                                            placement="left"
                                            content={
                                                <Image
                                                    style={{
                                                        objectFit: 'cover',
                                                        height: '264px',
                                                    }}
                                                    preview={true}
                                                    src={
                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                        '/' +
                                                        data?.taskTemplate?.fileId +
                                                        '?tenant=' +
                                                        NySession.getUser().tenantId
                                                    }
                                                />
                                            }
                                        >
                                            <Image
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '128px',
                                                    width: '128px',
                                                }}
                                                preview={true}
                                                src={CONSTANTS_REQ.FILES.DOWNLOAD + '/' + data?.taskTemplate?.fileId}
                                            />
                                        </Popover>
                                    ) : (
                                        <Image
                                            style={{
                                                objectFit: 'cover',
                                                height: '128px',
                                                width: '128px',
                                            }}
                                            preview={false}
                                            src="error"
                                            fallback={FILE_FALLBACK}
                                        />
                                    )}
                                </>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Text className={'ant-typography-print-text'} strong>
                                {data?.taskTemplate.description}
                            </Text>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </>
        );
    };

    const getTemplateForm = () => {
        return (
            <>
                {getTemplateFormViewTemplateInfo()}
                <Row gutter={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={24}>
                        <Descriptions
                            column={1}
                            bordered
                            size="small"
                            style={{ color: isDarkTheme ? 'white' : 'black' }}
                        >
                            <Descriptions.Item
                                className={'ant-typography-print-desc'}
                                label={geti18nText('reportProblem.name')}
                            >
                                <Text className={'ant-typography-print-text'} strong>
                                    {data?.name}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className={'ant-typography-print-desc'}
                                label={geti18nText('reportProblem.description')}
                            >
                                <Text strong className={'ant-typography-print-text'}>
                                    {data?.description}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className={'ant-typography-print-desc'}
                                label={geti18nText('reportProblem.status')}
                            >
                                <Text className={'ant-typography-print-text'} strong>
                                    {data?.status && geti18nText('app.enum.TASK_STATUS.' + data.status)}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className={'ant-typography-print-desc'}
                                label={geti18nText('reportProblem.user')}
                            >
                                <Text strong className={'ant-typography-print-text'}>
                                    {data?.user.person.firstName} {data?.user.person.lastName}
                                </Text>
                            </Descriptions.Item>
                            {data?.taskTemplate?.forAssignedAsset ? (
                                <Descriptions.Item
                                    className={'ant-typography-print-desc'}
                                    label={geti18nText('reportProblem.forAssignedAsset')}
                                >
                                    <Text strong className={'ant-typography-print-text'}>
                                        {data?.assignedAsset?.assetModel?.name} ({data?.assignedAsset?.inventoryNumber})
                                    </Text>
                                </Descriptions.Item>
                            ) : null}
                            {data?.status &&
                                (data?.status == enTaskStatus.RESOLVED ||
                                    data?.status == enTaskStatus.CLOSED ||
                                    data?.status == enTaskStatus.CLOSED_AND_RECEIPT ||
                                    data?.status == enTaskStatus.PARTIALLY_RESOLVED) && (
                                    <Descriptions.Item
                                        className={'ant-typography-print-desc'}
                                        label={geti18nText('reportProblem.solutionDescription')}
                                    >
                                        <Text strong className={'ant-typography-print-text'}>
                                            {data?.solutionDescription}
                                        </Text>
                                    </Descriptions.Item>
                                )}

                            <Descriptions.Item
                                className={'ant-typography-print-desc'}
                                label={geti18nText('task.typeCategory.table.column.created')}
                            >
                                <Text className={'ant-typography-print-text'} strong>
                                    {data?.created ? getDateFormat(data.created, 'DD.MM.YYYY') : '-'}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className={'ant-typography-print-desc'}
                                label={geti18nText('task.typeCategory.table.column.createdBy')}
                            >
                                <Text className={'ant-typography-print-text'} strong>
                                    {data?.createdBy?.person?.firstName + ' ' + data?.createdBy?.person?.lastName}
                                </Text>
                            </Descriptions.Item>
                            {data?.updated && (
                                <Descriptions.Item
                                    className={'ant-typography-print-desc'}
                                    label={geti18nText('task.typeCategory.table.column.updated')}
                                >
                                    <Text className={'ant-typography-print-text'} strong>
                                        {data?.updated ? getDateFormat(data.updated, 'DD.MM.YYYY') : '-'}
                                    </Text>
                                </Descriptions.Item>
                            )}
                            {data?.updatedBy && (
                                <Descriptions.Item
                                    className={'ant-typography-print-desc'}
                                    label={geti18nText('task.typeCategory.table.column.updatedBy')}
                                >
                                    <Text className={'ant-typography-print-text'} strong>
                                        {data?.updatedBy?.person?.firstName + ' ' + data?.updatedBy?.person?.lastName}
                                    </Text>
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Col>
                </Row>
            </>
        );
    };

    const getItemMetadataFormatValue = (item: any) => {
        const dataType = item?.objectMetadata?.dataType ?? 0;
        switch (dataType) {
            case 3:
                return item.value == true
                    ? geti18nText('app.default.button.yes')
                    : geti18nText('app.default.button.no');
            default:
                return item.value;
        }
    };

    const getTaskStatusChangeHistory = (id: any) => {
        return (
            <div>
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="bottomLeft"
                    content={
                        <StatusHistoryIndex
                            id={id}
                            url={CONSTANTS_REQ.TASK.TASK_STATUS_HISTORY + '/' + id}
                            type="taskStatus"
                        />
                    }
                    style={{ marginLeft: '4rem' }}
                >
                    <div style={{ marginLeft: '5px' }}>{getTaskStatusIcon(data?.status)}</div>
                </Popover>
            </div>
        );
    };

    return (
        <>
            {visibleModal && (
                <Modal
                    visible={visibleModal}
                    onCancel={closeModal}
                    width={hasMetadata ? 1200 : 600}
                    maskClosable={false}
                    title={
                        <div style={{ display: 'inline-flex' }}>
                            {geti18nText('reportProblem.title') + ' - ' + data?.name}
                            {taskId && getTaskStatusChangeHistory(taskId)}
                        </div>
                    }
                    okText={geti18nText('app.default.button.save')}
                    footer={
                        <>
                            <div style={{ float: 'left', display: 'inline-flex' }}>
                                {hasMetadata && (
                                    <Tooltip
                                        placement="top"
                                        visible={showTooltip}
                                        title={geti18nText('app.default.shortcuts.collapse')}
                                    >
                                        {!collapseAll ? (
                                            <EyeOutlined
                                                onClick={changeCollapseVisibility}
                                                className="ny-custom-button"
                                            />
                                        ) : (
                                            <EyeInvisibleOutlined
                                                onClick={changeCollapseVisibility}
                                                className="ny-custom-button"
                                            />
                                        )}
                                    </Tooltip>
                                )}
                            </div>
                            <div>
                                <Button key="cancel" onClick={closeModal}>
                                    {geti18nText('app.default.button.close')}
                                </Button>
                                {data?.status &&
                                    (data?.status == enTaskStatus.NEW || data?.status == enTaskStatus.ASSIGNED) && (
                                        <Button
                                            key="cancelation"
                                            type="primary"
                                            danger
                                            onClick={() => {
                                                setConfirmationModalVisible(true);
                                            }}
                                        >
                                            {geti18nText('reportProblem.button.cancel')}
                                        </Button>
                                    )}
                                {canEvaluate() && (
                                    <TaskEvaluationModal
                                        task={data}
                                        canEvaluate={canEvaluate}
                                        setRefresh={setRefresh}
                                    />
                                )}
                            </div>
                        </>
                    }
                >
                    <>
                        <Row gutter={24}>
                            {hasMetadata ? (
                                <>
                                    <Col span={12}>
                                        <Collapse
                                            activeKey={collapseActiveKeys}
                                            onChange={(key: any) => {
                                                setCollapseActiveKeys(key);
                                            }}
                                        >
                                            <Panel header={geti18nText('task.edit.tab.1')} key="1">
                                                {getTemplateForm()}
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                    <Col span={12}>
                                        <Collapse
                                            activeKey={collapseActiveKeys}
                                            onChange={(key: any) => {
                                                setCollapseActiveKeys(key);
                                            }}
                                        >
                                            <Panel header={geti18nText('task.edit.tab.2')} key="2">
                                                <Descriptions
                                                    column={1}
                                                    bordered
                                                    size="small"
                                                    style={{ color: isDarkTheme ? 'white' : 'black' }}
                                                >
                                                    {metadataTemplate &&
                                                        metadataTemplate.map((item: any) => {
                                                            return (
                                                                <Descriptions.Item label={item.objectMetadata.name}>
                                                                    {getItemMetadataFormatValue(item)}
                                                                </Descriptions.Item>
                                                            );
                                                        })}
                                                </Descriptions>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </>
                            ) : (
                                <Col span={24}>{getTemplateForm()}</Col>
                            )}
                        </Row>
                    </>
                </Modal>
            )}
            {confirmationModalVisible && (
                <Modal
                    visible={confirmationModalVisible}
                    title={geti18nText('reportProblem.button.cancel.confirm')}
                    onCancel={() => {
                        setConfirmationModalVisible(false);
                    }}
                    footer={
                        <>
                            <Button
                                onClick={() => {
                                    setConfirmationModalVisible(false);
                                }}
                            >
                                {geti18nText('app.default.button.close')}
                            </Button>
                            <Button
                                key="cancelation"
                                type="primary"
                                onClick={() => {
                                    cancelation();
                                    setButtonLoading(true);
                                }}
                                loading={buttonLoading}
                            >
                                {geti18nText('app.default.button.ok')}
                            </Button>
                        </>
                    }
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name="info"
                                    labelAlign={'left'}
                                >
                                    <TextArea rows={8} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default CreateTaskUserIndex;
