import { DeleteOutlined } from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Tabs } from 'antd';
import moment from 'moment';
import React, { ReactText, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import {
    errorNotification,
    getDateFormat,
    getMealPriceWithSubvention,
    okNotification,
    onKeyDownTextAreaCustom,
} from '../../../../utils/Utils';
import MealGroupEdit from './meal-group/edit';
import PriceListEdit from './price-list/edit';
import { MealsRights } from '../../../../rights/mealsRights';

const MealEdit = (props: any) => {
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState(geti18nText('meal.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [isCreate, setIsCreate] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [refreshTable, setRefreshTable] = useState(1);
    const [activeKey, setActiveKey] = useState('1');
    const [fileList, setFileList] = useState<any>([]);
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const appSettingMealSubvention = useApplicationSetting('MEALS_SUBVENTION');

    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [selectedValuesAddGroup, setSelectedValuesAddGroup] = useState<any>([]);
    const [hasSelectedAddGroup, setHasSelectedAddGroup] = useState(false);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);

    const [teamCreated, setTeamCreated] = useState(false);
    const [allowedDiscount, setAllowedDiscount] = useState<any>(null);
    const now = moment().format('DD.MM.YYYY');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canEdit = () => {
        return MealsRights.mealSyncRight();
    };

    const activeFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const priceListFilter = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'meal_id', condition: 'equals', value: form.getFieldValue('id') },
        ];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('meal.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.fileId) {
            let filesImage = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: dataForm.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(filesImage);
        }

        if (dataForm.allowedDiscount) {
            setAllowedDiscount(dataForm.allowedDiscount);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setFileList([]);
        setActiveKey('1');
        setEditHeader(geti18nText('meal.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    let clearKeysDeleteGroup: any;

    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    let clearKeysAddGroup: any;

    const setSelectedValuesFuncAddGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddGroup(hasSelected);
        setSelectedValuesAddGroup(selectedRowKeys);
        clearKeysAddGroup = clearSelectedRowKeys;
    };

    const saveToGroup = (e: any) => {
        e.preventDefault();
        setLoadingModalGroup(true);
        const url = CONSTANTS_REQ.MEAL.ADD_IN_GROUP;
        const urlWithMealId = url.replace('{mealId}', dataForm);

        NyRequestResolver.requestPost(urlWithMealId, undefined, {
            mealId: dataForm,
            groupIds: selectedValuesAddGroup,
        }).then((result: any) => {
            if (setLoadingModalGroup) setLoadingModalGroup(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddGroup) {
                    clearKeysAddGroup();
                }

                okNotification();
                setIsAddListModalVisibleGroup(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const removeFromGroup = () => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.id;
        });
        const url = CONSTANTS_REQ.MEAL.REMOVE_FROM_GROUP;
        const urlWithMealId = url.replace('{mealId}', dataForm);

        NyRequestResolver.requestPost(urlWithMealId, undefined, {
            mealId: dataForm,
            groupIds: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('meal.edit.add.meal.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('meal.edit.remove.meal.from.group.comfirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('meal.edit.remove.meal.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.MEAL.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            normalize={(values: any) => {
                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }
                if (fileList.length > 0 && fileList[0]) {
                    values.fileId = fileList[0].id;
                }
                return values;
            }}
            leaveNewModalOpen={true}
            hideActivationButtons={!canCreate() || teamCreated || activeKey != '1'}
        >
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <Tabs.TabPane tab={geti18nText('meal.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="meal">
                                <NyImageUpload files={fileList} setFiles={setFileList} saveOnUpload={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('meal.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('meal.edit.allergen')} name="allergen">
                                <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('meal.edit.description')} name="description">
                                <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('meal.edit.minOrder')} name="minOrder">
                                <InputNumber style={{ width: '100%' }} min={0.1} step={0.1} decimalSeparator={','} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('meal.tab.meal.group')} key="2" disabled={isCreate}>
                    {!isCreate && (
                        <NyDataTable
                            nyId="meals-in-meal"
                            rowKey="id"
                            url={CONSTANTS_REQ.MEAL_GROUP.IN_MEAL + '/' + dataForm}
                            addedData={{ mealId: form.getFieldValue('id') }}
                            showRowSelection
                            readonly
                            hideButtons
                            scroll={props?.editProps?.scroll}
                            setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                            rowSelectionType={'checkbox'}
                            rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                            setDefaultFilterValue={activeFilter}
                            fetchWhenChange={refreshTable}
                            setDefaultPageSize={table.setDefaultPageSize()}
                            columns={[
                                {
                                    title: geti18nText('meal.group.table.column.id'),
                                    dataIndex: 'id',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('number'),
                                },
                                {
                                    title: geti18nText('meal.group.table.column.name'),
                                    dataIndex: 'name',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('meal.group.table.column.minOrder'),
                                    dataIndex: 'minOrder',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('number'),
                                },
                                {
                                    title: geti18nText('meal.group.table.column.maxOrder'),
                                    dataIndex: 'maxOrder',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('number'),
                                },
                            ]}
                        />
                    )}
                    {isAddListModalVisibleGroup && (
                        <Modal
                            title={geti18nText('meal.edit.add.meal.in.group.add')}
                            visible={isAddListModalVisibleGroup}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAddGroup }}
                            onOk={(e) => saveToGroup(e)}
                            confirmLoading={loadingModalGroup}
                            width={900}
                            onCancel={() => {
                                setIsAddListModalVisibleGroup(false);
                            }}
                        >
                            <NyDataTable
                                nyId="meals-not-in-meal"
                                rowKey="id"
                                url={CONSTANTS_REQ.MEAL_GROUP.NOT_IN_MEAL + '/' + dataForm}
                                addedData={{ mealId: form.getFieldValue('id') }}
                                addNewButtonText={geti18nText('meal.group.table.add')}
                                modalComponent={MealGroupEdit}
                                showRecordModal={true}
                                hideNewButton={!canCreate()}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                showRowSelection
                                readonly
                                scroll={props?.editProps?.scroll}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncAddGroup as any}
                                setDefaultFilterValue={activeFilter}
                                fetchWhenChange={refreshTable}
                                onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                columns={[
                                    {
                                        title: geti18nText('meal.group.table.column.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('meal.group.table.column.name'),
                                        dataIndex: 'name',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('meal.group.table.column.minOrder'),
                                        dataIndex: 'minOrder',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('meal.group.table.column.maxOrder'),
                                        dataIndex: 'maxOrder',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                ]}
                            />
                        </Modal>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('meal.tab.priceList')} key="3" disabled={isCreate}>
                    {!isCreate && (
                        <NyDataTable
                            nyId="meals-price-list"
                            rowKey="id"
                            url={CONSTANTS_REQ.PRICE_LIST.LIST}
                            addedData={{ mealId: form.getFieldValue('id') }}
                            addNewButtonText={geti18nText('price.list.table.add')}
                            modalComponent={PriceListEdit}
                            setDefaultPageSize={table.setDefaultPageSize()}
                            editProps={{
                                mealId: form.getFieldValue('id'),
                                mealName: form.getFieldValue('name'),
                                allowedDiscount: allowedDiscount,
                            }}
                            showRecordModal={true}
                            hideNewButton={!canCreate()}
                            scroll={props?.editProps?.scroll}
                            setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                            setDefaultFilterValue={priceListFilter}
                            fetchWhenChange={refreshTable}
                            columns={[
                                ...[
                                    {
                                        title: geti18nText('price.list.table.column.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('price.list.table.column.validFrom'),
                                        dataIndex: 'validFrom',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnDateOption(),
                                        render: (text: any, record: any) => {
                                            if (record.validFrom) {
                                                return moment(record.validFrom).format('DD.MM.YYYY');
                                            }
                                        },
                                    },
                                    {
                                        title: geti18nText('price.list.table.column.validTo'),
                                        dataIndex: 'validTo',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnDateOption(),
                                        render: (text: any, record: any) => {
                                            if (record.validTo) {
                                                return moment(record.validTo).format('DD.MM.YYYY');
                                            }
                                        },
                                    },
                                    {
                                        title: geti18nText('price.list.table.column.price'),
                                        dataIndex: ['price'],
                                        align: 'right',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                        render: (text: any, record: any) => {
                                            if (record) {
                                                return (
                                                    (record.price ? NyUtils.formatNumber(record.price, 2) : '0,00') +
                                                    ' ' +
                                                    (record.currency && record.currency.isoCode
                                                        ? record.currency.isoCode
                                                        : '')
                                                );
                                            }
                                        },
                                    },
                                ],
                                ...(mealSyncTypeHNB === true
                                    ? [
                                          {
                                              title: geti18nText('price.list.table.column.priceWithSubvention'),
                                              dataIndex: ['priceWithSubvention'],
                                              align: 'right',
                                              render: (text: any, record: any) => {
                                                  if (
                                                      record &&
                                                      ((record.validTo &&
                                                          moment(
                                                              getDateFormat(record.validTo, 'DD.MM.YYYY'),
                                                              'DD.MM.YYYY'
                                                          ).isSameOrAfter(moment(now, 'DD.MM.YYYY'))) ||
                                                          !record.validTo)
                                                  ) {
                                                      return (
                                                          getMealPriceWithSubvention(
                                                              appSettingMealSubvention,
                                                              allowedDiscount ?? 0,
                                                              record?.price ?? 0
                                                          ) +
                                                          ' ' +
                                                          (record.currency && record.currency.isoCode
                                                              ? record.currency.isoCode
                                                              : '')
                                                      );
                                                  }
                                              },
                                          },
                                      ]
                                    : []),
                            ]}
                        />
                    )}
                </Tabs.TabPane>
                {mealSyncTypeHNB ? (
                    <Tabs.TabPane tab={geti18nText('meal.tab.syncData')} key={'4'}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('meal.edit.syncData.ref_id')} name={'refId'}>
                                    <Input disabled={!canEdit()} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('meal.edit.syncData.ppot')} name={'ppot'}>
                                    <Input disabled={!canEdit()} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('meal.edit.syncData.tax')} name={'pdv'}>
                                    <Input disabled={!canEdit()} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('meal.edit.syncData.place_of_cost')} name={'placeOfCost'}>
                                    <Input disabled={!canEdit()} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('meal.edit.syncData.allowed_discount')}
                                    name={'allowedDiscount'}
                                >
                                    <Input disabled={!canEdit()} suffix={'%'} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                ) : null}
            </Tabs>
        </NyDataEdit>
    );
};

export default MealEdit;
