import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../components/report-button';
import { getDateFormat } from '../../utils/Utils';
import CostCenterSearch from '../../views/accounting/views/cost-center/search';
import { BusinessUnitSearch } from '../../views/human/views/business-unit/search';
import useEnum from '../../hooks/useEnum';
import ReportExportType from '../report-export-type/ReportExportType';

const TravelWarrantReportModal = ({
    reportType,
    reportTitle,
    reportFileName,
    url,
    allowExportTypeChange = false,
}: any) => {
    const [form] = Form.useForm();
    const enExportType = useEnum('EXPORT_TYPE');
    const [visible, setVisible] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('month'));
    const [dateTo, setDateTo] = useState<any>(moment());
    const [businessUnitId, setBusinessUnitId] = useState<any>(null);
    const [accountingCostCenterId, setAccountingCostCenterId] = useState<any>(null);
    const [exportType, setExportType] = useState(enExportType.PDF);

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values.dateFrom));
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText(reportTitle)}
            </Button>
            {visible && (
                <Modal
                    maskClosable={false}
                    visible={visible}
                    title={geti18nText(reportTitle)}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        setBusinessUnitId(null);
                        setDateFrom(moment().startOf('month'));
                        setDateTo(moment());
                        setExportType(enExportType.PDF);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    footer={[
                        <NyReportButton
                            url={url}
                            customParms={
                                reportType && reportType === 'TRAVEL_COSTS_BY_CENTER_SUMMARY'
                                    ? {
                                          dateFrom: getDateFormat(dateFrom),
                                          dateTo: getDateFormat(dateTo),
                                          businessUnitId: businessUnitId,
                                          accountingCostCenterId: accountingCostCenterId,
                                          exportType: exportType ? exportType : enExportType.PDF,
                                      }
                                    : {
                                          dateFrom: getDateFormat(dateFrom),
                                          dateTo: getDateFormat(dateTo),
                                          businessUnitId: businessUnitId,
                                          exportType: exportType ? exportType : enExportType.PDF,
                                      }
                            }
                            subreportType={reportType}
                            buttoni18nText={reportTitle}
                            fileName={reportFileName}
                            checkBeforeSave={checkBeforeSave}
                            selectedExportType={exportType}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {allowExportTypeChange && (
                            <ReportExportType exportType={exportType} setExportType={setExportType} />
                        )}
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('travelWarrant.report.dateFrom')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().startOf('month')}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateFrom(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('travelWarrant.report.dateTo')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment()}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateTo(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <BusinessUnitSearch
                                    formItemName={'businessUnit'}
                                    onChange={(value: any) => {
                                        setBusinessUnitId(value && value.id > 0 ? value.id : null);
                                    }}
                                />
                            </Col>
                        </Row>
                        {reportType && reportType === 'TRAVEL_COSTS_BY_CENTER_SUMMARY' && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <CostCenterSearch
                                        label={geti18nText('travelWarrant.report.accountingCostCenter')}
                                        name="accountingCostCenter"
                                        onChange={(value: any) => {
                                            setAccountingCostCenterId(value && value.id > 0 ? value.id : null);
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default TravelWarrantReportModal;
