import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import BankIndex from '.';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import BankEdit from './edit';

const BankSearch = ({ label, name, required, onChange }: any) => {
    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    return (
        <Form.Item
            label={label ? label : geti18nText('bankAccount.edit.bank')}
            name={name ? name : 'bank'}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.BANK.SEARCH}
                onChange={onChange ? onChange : null}
                map={{ id: 'id', label: 'text' }}
                searchBy="name"
                AddNewModalComponent={canCreate() ? BankEdit : null}
                SearchPopupComponent={<BankIndex readonly={!canCreate()} />}
                autoFocus={true}
                nyTestId="bank"
            />
        </Form.Item>
    );
};

export default BankSearch;
