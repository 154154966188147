import { geti18nText } from '@nybble/nyreact';
import { Button, Col, Modal, Row, Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import EmployeeEvaluationGraph from './EmployeeEvaluationGraph';
import EmployeeEvaluationTable from './EmployeeEvaluationTable';
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';

const { TabPane } = Tabs;

const EmployeeEvaluationPeriodsEdit = ({
    visible,
    setVisible,
    selectedPeriod,
    setSelectedPeriod,
    refresh,
    setRefresh,
    activeList,
    isAdmin,
}: any) => {
    const [activeKey, setActiveKey] = useState('evaluations');
    const [refreshView, setRefreshView] = useState<any>(0);
    const customRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);

    const setDefaultFilterValue = () => {
        if (selectedPeriod?.id) {
            if (isAdmin) {
                return [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'employee_evaluation_period_id', condition: 'equals', value: selectedPeriod.id },
                    { field: 'status', condition: 'in', value: [1, 3, 4, 5, 6, 7, 8].toString() },
                ];
            } else {
                return [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'employee_evaluation_period_id', condition: 'equals', value: selectedPeriod.id },
                ];
            }
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const closeEditModal = () => {
        setVisible(false);
        setSelectedPeriod(null);
        setActiveKey('evaluations');
    };

    const getTitle = () => {
        if (!selectedPeriod?.id) {
            return geti18nText('employee.evaluation.all');
        } else {
            const dateFrom = selectedPeriod?.dateFrom ? moment(selectedPeriod.dateFrom).format('DD.MM.YYYY.') : '';
            const dateTo = selectedPeriod?.dateTo ? moment(selectedPeriod.dateTo).format('DD.MM.YYYY.') : '';
            return geti18nText('employee.evaluation.all.period') + ' ' + dateFrom + ' - ' + dateTo;
        }
    };

    useEffect(() => {
        if (visible) {
            setRefreshView(refreshView + 1);
        }
    }, [refresh, visible]);

    const customFooter = (
        <>
            <div style={{ float: 'left' }}>
                {activeKey == 'graph' && (
                    <ReactToPrint
                        trigger={() => {
                            return (
                                <Button key={'print'} icon={<PrinterOutlined />}>
                                    {geti18nText('travelWarrant.action.pdf.short')}
                                </Button>
                            );
                        }}
                        onBeforeGetContent={() => {
                            setPrintPdf(true);
                            return Promise.resolve();
                        }}
                        onAfterPrint={() => {
                            setPrintPdf(false);
                        }}
                        content={() => customRef.current}
                        pageStyle={`@page {
                            size: landscape;
                            margin: 5mm;
                        }`}
                        documentTitle={
                            geti18nText('employee.evaluation.list') +
                            ' (' +
                            moment(selectedPeriod.dateFrom).format('DD.MM.YYYY.') +
                            ' - ' +
                            moment(selectedPeriod.dateTo).format('DD.MM.YYYY.') +
                            ')'
                        }
                    />
                )}
            </div>
            <Button onClick={closeEditModal}>{geti18nText('app.default.button.close')}</Button>
        </>
    );

    return (
        <>
            <Modal
                open={visible}
                title={getTitle()}
                width={1900}
                okText={geti18nText('app.default.button.close')}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={closeEditModal}
                onOk={closeEditModal}
                footer={customFooter}
            >
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    id="travel-warrant-edit-tabs"
                    style={{ overflowY: 'auto', paddingBottom: '10px' }}
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        setActiveKey(key);
                    }}
                >
                    <TabPane tab={geti18nText('employee.evaluation.list')} key="evaluations">
                        <Row gutter={24}>
                            <Col span={24}>
                                <EmployeeEvaluationTable
                                    refresh={refreshView}
                                    setRefresh={setRefresh}
                                    activeList={activeList}
                                    search={setDefaultFilterValue()}
                                    selectedPeriod={selectedPeriod}
                                    scroll={{ y: 400 }}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={geti18nText('app.default.button.graph')} key="graph">
                        <EmployeeEvaluationGraph
                            showSearch={false}
                            period={selectedPeriod}
                            showPrintButton={false}
                            customRef={customRef}
                            printPdf={printPdf}
                            setPrintPdf={setPrintPdf}
                        />
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default EmployeeEvaluationPeriodsEdit;
