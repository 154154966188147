import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import AccountIndex from '.';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customAccountRenderOption } from '../../../../../utils/Utils';
import AccountEdit from '../../account/edit';

const AccountSearch = ({
    label = geti18nText('accountingEntityAccount.edit.account'),
    name = 'account',
    required = false,
    setDefaultFilterValue = null,
    disabled = false,
    onChange = null,
    addNewModalComponent = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.ACCOUNT.SEARCH}
                setDefaultFilterValue={setDefaultFilterValue}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name', type: 'type' }}
                searchBy="name || ' ' || code"
                itemName={'name'}
                customItemNameLabel={'(code) text'}
                SearchPopupComponent={<AccountIndex />}
                addNewItem={false}
                disabled={disabled}
                renderOption={customAccountRenderOption}
                AddNewModalComponent={addNewModalComponent ? AccountEdit : null}
                mustGetPopupContainer={false}
                onChange={onChange}
            />
        </Form.Item>
    );
};

export default AccountSearch;
