import { geti18nText } from '@nybble/nyreact';
import { Form, Tabs } from 'antd';
import React, { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import AssetCustomTableFilter from '../CustomFilter';
import AssetRentalReleaseIndex from './RentAssetTable';
import AssetRentalReturnIndex from './ReturnAssetTable';

const { TabPane } = Tabs;

const AssetRentalIndex = () => {
    const [refresh, setRefresh] = useState(0);
    const [filterForm] = Form.useForm();
    useHelper('asset/asset_release_rental');

    let defaultFilterValue = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'company_id', condition: 'is_not_null', value: '' },
    ];

    const setDefaultFilterValue = () => {
        let currentDefaultFilterValue = [...defaultFilterValue];
        if (filterForm.getFieldValue('asset')) {
            const assetID = filterForm.getFieldValue('asset').id;
            if (assetID != -1) {
                currentDefaultFilterValue.push({
                    field: 'asset.id',
                    condition: 'equals',
                    value: assetID.toString(),
                });
            }
        }
        if (filterForm.getFieldValue('serial_number')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number').toString(),
            });
        }
        if (filterForm.getFieldValue('serial_number2')) {
            currentDefaultFilterValue.push({
                field: 'asset.serial_number2',
                condition: 'equals',
                value: filterForm.getFieldValue('serial_number2').toString(),
            });
        }
        return currentDefaultFilterValue;
    };

    return (
        <React.Fragment>
            <AssetCustomTableFilter setRefresh={setRefresh} refresh={refresh} form={filterForm} />
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('menu.asset.rental.release')} key="1">
                    <AssetRentalReleaseIndex
                        scroll={{ y: 500, x: 800 }}
                        refreshFilters={refresh}
                        setDefaultFilterValue={setDefaultFilterValue}
                    />
                </TabPane>
                <TabPane tab={geti18nText('menu.asset.return')} key="2">
                    <AssetRentalReturnIndex
                        scroll={{ y: 500, x: 800 }}
                        refreshFilters={refresh}
                        setDefaultFilterValue={setDefaultFilterValue}
                    />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default AssetRentalIndex;
