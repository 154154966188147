import { BookOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
// import { addTab /* , removeTab, setIsDirtyTab, updateTabTitle */ } from '../../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getPersonSelectOption,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
} from '../../../../../utils/Utils';
import PersonIndex from '../../../../human/views/person';
import PersonEdit from '../../../../human/views/person/edit';
import AssetWriteOffItemsIndex from './asset-write-off-items';
import AssetWriteOffItemsActionIndex from './asset-write-off-items/actionIndex';
import useEnum from '../../../../../hooks/useEnum';
import CompanySearch from '../../../../administration/views/company/search';
import { updateHistoryI18n } from '../../../../../slices/historySlice';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';

const { Panel } = Collapse;

const AssetWriteOffEdit = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isFormChange, setIsFormChange] = useState(false);
    const [items, setItems] = useState<any>([]);
    const [isCreate, setIsCreate] = useState(true);
    const [refresh, setRefresh] = useState(0);

    const { id } = useParams<any>();
    const location = useLocation();

    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [note, setNote] = useState<any>(undefined);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [booked, setBooked] = useState(false);
    const [bookedGeneralLedger, setBookedGeneralLedger] = useState(false);
    const [title, setTitle] = useState('');
    const [showNewButton, setShowNewButton] = useState(null);
    const [tempId, setTempId] = useState<any>(0);
    const [createdId, setCreatedId] = useState('create');
    const [getTotalsRefresh, setGetTotalsRefresh] = useState<any>(0);
    const [amount, setAmount] = useState<any>(undefined);
    const [ordinal, setOrdinal] = useState<any>(undefined);
    const [date, setDate] = useState<any>(undefined);
    const [dataLength, setDataLength] = useState<any>(null);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const focusInput = useRef<any>(null);

    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    // useEffect(() => {
    //     dispatch(setIsDirtyTab({ path: location.pathname, isDirty: isFormChange }));
    // }, [isFormChange]);

    useEffect(() => {
        if (dataForm) {
            setCollapseActiveKeys(['1', '2']);
        }
    }, [dataForm]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetWriteOff();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setTitle(dataForm.ord + '/' + moment(dataForm.date).format('YYYY'));
        }
        setIsEdit(dataForm.id ? true : false);
        if (dataForm.booked) {
            setIsCreate(false);
            setBooked(true);

            setIsFormChange(false);
            // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
            setShowNewButton(null);
        } else {
            setIsCreate(true);
            setBooked(false);
            setIsFormChange(false);
            // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
            setShowNewButton(geti18nText('asset.acquisition.file.table.add'));
        }
        setBookedGeneralLedger(dataForm.bookedGeneralLedger ? true : false);
        if (dataForm.type) {
            dataForm.type = setEnumFormat('ASSET_WRITE_OFF_TYPE', dataForm.type);
        }

        if (dataForm.ord) {
            setOrdinal(dataForm.ord);
        }
        if (dataForm.date) {
            setDate(dataForm.date);
        }

        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }

        if (dataForm?.assetWriteOffTotals?.acquisitionCostValue) {
            setAmount(dataForm?.assetWriteOffTotals?.acquisitionCostValuev);
        }

        if (dataForm.person) {
            dataForm.person = getPersonSelectOption(dataForm.person);
        }
        setNote(dataForm.note ? dataForm.note : undefined);
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2']);
        }
        setCollapseAll(!collapseAll);
    };

    const changeId = (returnedId: any) => {
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        setCreatedId(returnedId);
        const pathname = CONSTANTS_REQ.ASSET_WRITE_OFF.PATHNAME + returnedId;
        // dispatch(addTab({ title: 'asset.writeOff.edit.title', addedData: returnedId, path: pathname }));
        history.push(pathname);
        setTempId(0);
    };

    const book = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_WRITE_OFF.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setIsCreate(false);
                    setBooked(true);
                    setShowNewButton(null);
                    okNotification(geti18nText('asset.acquisition.button.book.success'));
                } else {
                    errorNotification(geti18nText('asset.write.off.booked.negative.net.book.value'));
                }
                setLoading(false);
            });
        }
    };

    const debook = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_WRITE_OFF.DE_BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setIsCreate(true);
                    setBooked(false);
                    setShowNewButton(geti18nText('asset.acquisition.file.table.add'));
                    okNotification(geti18nText('asset.acquisition.button.debook.success'));
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    /*  const actionsMenu = (
        <Menu>
            {canCreate() && isCreate && !bookedGeneralLedger && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                        onConfirm={() => {
                            book();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.acquisition.button.booking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {canCreate() && !isCreate && !bookedGeneralLedger && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                        onConfirm={() => {
                            debook();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.acquisition.button.deBooking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            <div style={{ display: 'block', margin: '5px' }}>
                <NyReportButton
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.REPORT.ASSET_WRITE_OFF}
                    subreportType={'ASSET_WRITE_OFF'}
                    customParms={{ assetWriteOffId: dataForm }}
                    buttoni18nText={'app.default.button.download.pdf'}
                    fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_WRITE_OFF)}
                            allowExportTypeChange={true}
                />
            </div>
        </Menu>
    ); */

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    )}
                </Tooltip>
                {/*  {dataForm && (
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                )} */}
            </div>
            <div style={{ display: 'contents' }}>
                {dataForm && (
                    <>
                        {canCreate() && isCreate && !bookedGeneralLedger && (
                            <div style={{ display: 'inline-flex', margin: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                                    onConfirm={() => {
                                        book();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.acquisition.button.booking')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        {canCreate() && !isCreate && !bookedGeneralLedger && (
                            <div style={{ display: 'inline-flex', margin: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                                    onConfirm={() => {
                                        debook();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.acquisition.button.deBooking')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        <div style={{ display: 'inline-flex', marginRight: '12px' }}>
                            <NyReportButton
                                style={{ width: '100%' }}
                                url={CONSTANTS_REQ.REPORT.ASSET_WRITE_OFF}
                                subreportType={'ASSET_WRITE_OFF'}
                                customParms={{ assetWriteOffId: dataForm }}
                                buttoni18nText={'app.default.button.print'}
                                fileName={
                                    geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_WRITE_OFF) +
                                    ' ' +
                                    ordinal +
                                    '_' +
                                    moment(date).format('YYYY')
                                }
                                customDateFormat={moment().format('yyyyMMDDHHmm')}
                                disabled={dataLength < 1}
                                allowExportTypeChange={true}
                            />
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    useEffect(() => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_WRITE_OFF.GET_TOTALS.replace('{id}', id), {}).then(
            (result: any) => {
                if (result.status == RESPONSE.OK && result.data) {
                    if (result.data) {
                        setAmount(result?.data?.acquisitionCostValue);
                    }
                }
            }
        );
    }, [getTotalsRefresh]);

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.ASSET_WRITE_OFF.EDIT}
                setValues={setValues}
                width={1200}
                fetchWhenChange={id || refresh}
                form={form}
                goBack={() => {
                    //     dispatch(removeTab(location.pathname));
                    //     setTempId(0);
                    setIsFormChange(false);
                    history.goBack();
                }}
                onSaveAndGetID={(returnedId: any) => {
                    changeId(returnedId);
                    setDataForm(returnedId);
                    if (id === 'create') {
                        // dispatch(removeTab(location.pathname));
                        changeId(returnedId);
                        setIsFormChange(false);
                    } else {
                        // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
                        setIsFormChange(false);
                    }
                }}
                paramsId={id}
                buttonsClassName="buttons-sticky"
                changeCollapseVisibility={changeCollapseVisibility}
                customFooterContent={getCustomFooterContent}
                checkIsFormChanged={true}
                setIsFormChange={setIsFormChange}
                hideActivationButtons={!canCreate() || !isCreate}
                disableSubmitButton={!canCreate() || !isFormChange}
                shortcuts={true}
                {...props}
                normalize={(values: any) => {
                    if (values.date) values.date = getDateFormat(values.date);
                    if (values.company) {
                        values.company = getSearchFormat(values.company);
                        if (values.company.id < 1) {
                            delete values.company;
                        }
                    }
                    if (values.person) {
                        values.person = getSearchFormat(values.person);
                        if (values.person.id < 1) {
                            delete values.person;
                        }
                    }
                    if (values.type) {
                        values.type = getEnumFormat(values.type);
                        if (values.type.id < 1) {
                            delete values.type;
                        }
                    }
                    values.booked = booked ? true : false;
                    values.bookedGeneralLedger = bookedGeneralLedger ? true : false;
                    return values;
                }}
            >
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('asset.writeOff.tab.1')}
                                key="1"
                                extra={
                                    <NyNoteModal
                                        ref={focusInput}
                                        note={note}
                                        modalTitle={
                                            geti18nText('asset.acquisition.edit.title') +
                                            ' - ' +
                                            geti18nText('app.default.note')
                                        }
                                    />
                                }
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item name="id" style={{ display: 'none' }}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item label={geti18nText('asset.acquisition.edit.ord')} name="ord">
                                                    <NyInputNumber
                                                        style={{ width: '100%' }}
                                                        nyTestId="ord"
                                                        disabled={!canCreate() || !isCreate || !isEdit}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.acquisition.edit.document')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                    name="documentInfo"
                                                >
                                                    <Input disabled={!canCreate() || !isCreate} ref={focusInput} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.date')}
                                                    name="date"
                                                    initialValue={moment()}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyDatePicker
                                                        style={{ width: '100%' }}
                                                        nyTestId="date"
                                                        disabled={!canCreate() || !isCreate}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label={geti18nText('account.edit.type')} name="type">
                                                    <NySearchField
                                                        options={getEnumArray('ASSET_WRITE_OFF_TYPE')}
                                                        map={{ id: 'id', label: 'text' }}
                                                        searchBy="text"
                                                        style={{ width: '100%' }}
                                                        className={''}
                                                        disabled={!canCreate() || !isCreate}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <CompanySearch
                                                    disabled={!canCreate() || !isCreate}
                                                    label={geti18nText('asset.writeOff.table.column.company')}
                                                    isModal={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.writeOff.table.column.person')}
                                                    name="person"
                                                >
                                                    <NySearchField
                                                        url={CONSTANTS_REQ.PERSON.SEARCH}
                                                        map={{ id: 'id', label: 'text' }}
                                                        searchBy="firstName || lastName"
                                                        customItemNameLabel={'firstName lastName'}
                                                        addNewItem={true}
                                                        disabled={!canCreate() || !isCreate}
                                                        setDefaultFilterValue={setDefaultFilterValue}
                                                        AddNewModalComponent={PersonEdit} //
                                                        SearchPopupComponent={<PersonIndex disabled={true} />}
                                                        order="lastName, firstName"
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {isEdit && (
                                            <Row gutter={24}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="booked"
                                                        valuePropName={'checked'}
                                                        initialValue={false}
                                                    >
                                                        <strong>
                                                            {geti18nText('asset.acquisition.edit.booked') + ': '}
                                                        </strong>
                                                        <strong>
                                                            {booked
                                                                ? geti18nText('app.default.button.yes')
                                                                : geti18nText('app.default.button.no')}
                                                        </strong>
                                                    </Form.Item>
                                                </Col>
                                                {/*   <Col span={12}>
                                                <Form.Item
                                                    name="bookedGeneralLedger"
                                                    valuePropName={'checked'}
                                                    initialValue={false}
                                                >
                                                    <strong>
                                                        {geti18nText('asset.acquisition.edit.bookedGeneralLedger') +
                                                            ': '}
                                                    </strong>
                                                    <strong>
                                                        {bookedGeneralLedger
                                                            ? geti18nText('app.default.button.yes')
                                                            : geti18nText('app.default.button.no')}
                                                    </strong>
                                                </Form.Item>
                                            </Col> */}
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="amount"
                                                        valuePropName={'checked'}
                                                        initialValue={false}
                                                    >
                                                        <strong>
                                                            {geti18nText('asset.acquisition.item.table.column.amount') +
                                                                ': '}
                                                        </strong>
                                                        <strong>{amount && NyUtils.formatNumber(amount, 2)}</strong>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    {dataForm && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Collapse
                                style={{ marginTop: '1em' }}
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('asset.writeOff.divider.2')} key="2" disabled={!dataForm}>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            {!booked && !bookedGeneralLedger ? (
                                                <AssetWriteOffItemsActionIndex
                                                    assetWriteOffId={dataForm}
                                                    canCreate={canCreate()}
                                                    booked={booked}
                                                    bookedGeneralLedger={bookedGeneralLedger}
                                                    setGetTotalsRefresh={setGetTotalsRefresh}
                                                    getTotalsRefresh={getTotalsRefresh}
                                                    dataLength={dataLength}
                                                    setDataLength={setDataLength}
                                                    scroll={{ y: 200, x: 800 }}
                                                />
                                            ) : (
                                                <AssetWriteOffItemsIndex
                                                    assetWriteOffId={dataForm}
                                                    canCreate={canCreate()}
                                                    booked={booked}
                                                    bookedGeneralLedger={bookedGeneralLedger}
                                                    dataLength={dataLength}
                                                    setDataLength={setDataLength}
                                                    scroll={{ y: 200, x: 800 }}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                    )}
                </Row>
            </NyDataEdit>
            <Prompt message={''} when={isFormChange} />
        </>
    );
};
export default AssetWriteOffEdit;
