import { BookOutlined, FileOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { TasksRights } from '../../rights/tasksRights';

const TasksModuleMenu = [
    {
        key: 'menu.tasks',
        path: '/tasks',
        icon: <HomeOutlined />,
        i18n: 'menu.home',
        role: TasksRights.canViewTasksMenuUser(),
    },
    {
        key: 'menu.task.template',
        path: '/tasks/task-template',
        icon: <FileOutlined />,
        i18n: 'menu.task.template',
        role: TasksRights.canViewTasksCodebooksMenu(),
    },
    {
        key: 'menu.team',
        path: '/tasks/team',
        icon: <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-headset" />,
        i18n: 'menu.team',
        role: TasksRights.canViewTasksCodebooksMenu(),
    },
    {
        key: 'menu.task.service.codebooks',
        path: '/tasks/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.task.service.codebooks',
        role: TasksRights.canViewTasksCodebooksMenu(),
        submenu: [
            {
                key: 'menu.task.service.category',
                path: '/tasks/codebooks/task-type-category',
                icon: <FileOutlined />,
                i18n: 'menu.task.service.category',
                role: TasksRights.canViewTasksCodebooksMenu(),
            },
            {
                key: 'menu.task.service.subtype',
                path: '/tasks/codebooks/task-type-subtype',
                icon: <FileOutlined />,
                i18n: 'menu.task.service.subtype',
                role: TasksRights.canViewTasksCodebooksMenu(),
            },
            {
                key: 'menu.task.priority',
                path: '/tasks/codebooks/task-priority',
                icon: <FileOutlined />,
                i18n: 'menu.task.priority',
                role: TasksRights.canViewTasksCodebooksMenu(),
            },
        ],
    },
    {
        key: 'menu.task.service.settings',
        path: '/tasks/task-settings',
        icon: <SettingOutlined />,
        i18n: 'menu.task.service.settings',
        role: TasksRights.canViewTasksSettingsMenu(),
    },
];

export default TasksModuleMenu;
