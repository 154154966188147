import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import CountryIndex from '.';
import CountryEdit from './edit';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const CountrySearch = ({
    label,
    name,
    required,
    readonly = true,
    onChange,
    autoFocus,
    disabled = false,
    setDefaultFilterValue,
    edit = true,
}: any) => {
    return (
        <Form.Item
            label={label ? label : geti18nText('travelWarrantPlan.edit.country')}
            name={name ? name : 'country'}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.COUNTRY.SEARCH}
                map={{ id: 'id', label: 'text' }}
                searchBy="name"
                SearchPopupComponent={<CountryIndex readonly={readonly} />}
                AddNewModalComponent={edit ? CountryEdit : null}
                nyTestId="country"
                onChange={onChange ? onChange : null}
                autoFocus={autoFocus ? autoFocus : false}
                disabled={disabled}
                setDefaultFilterValue={setDefaultFilterValue ? setDefaultFilterValue : null}
            />
        </Form.Item>
    );
};

export default CountrySearch;
