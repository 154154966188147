import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationEdit from '../payment-calculation/edit';

const RoyaltiesEdit = () => {
    useHelper('payroll/calculation/royalties');

    return <PaymentCalculationEdit type={4} />;
};

export default RoyaltiesEdit;
