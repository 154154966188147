import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { NySpinner, NyUtils, geti18nText } from '@nybble/nyreact';
import { Empty, Switch, Table, Tooltip, Typography } from 'antd';
import _, { round } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    fileDownload,
    getDateFormat,
    getTravelWarrantPaymentSortColor,
    getTravelWarrantPaymentStatusIcon,
    numberFormat,
} from '../../utils/Utils';
import { wageIconSmall } from '../travel-warrant-icons';

const { Text } = Typography;

const NyHistoryCustomTable = ({ row }: any) => {
    const columnStyleClass = 'description-div-small';
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;

    const paymentInDeclaredCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY');
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');

    const travelWarrantPaymentStatusEnum = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');
    const travelWarrantPaymentSortEnum = useEnum('TRAVEL_WARRANT_PAYMENT_SORT');
    const enTravelWageType = useEnum('TRAVEL_WAGE_TYPE');

    const [loading, setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>(null);
    const [items, setItems] = useState<any>([]);

    const [amounts, setAmounts] = useState(0);
    const [defaultCurrency, setDefaultCurrency] = useState(null);

    useEffect(() => {
        setValues(row);
    }, [row]);

    const setValues = (row: any) => {
        setLoading(true);
        if (row && row?.property && row?.propertyType === 'object' && row?.new) {
            resolvePropertyType(row.property);
            setItems(row.new);
        } else {
            setColumns([]);
            setItems([]);
            setLoading(false);
        }
    };
    const resolvePropertyType = (property: any) => {
        let columnsValue = null;
        switch (property) {
            case 'travelWarrantTotalsList':
                columnsValue = travelWarrantTotalsListColumns;
                break;
            case 'travelWarrantWageList':
                columnsValue = travelWarrantWageListColumns;
                break;
            case 'travelWarrantWageExpensesList':
                columnsValue = travelWarrantWageExpensesListColumns;
                break;
            case 'travelWarrantWageRecapitulationList':
                columnsValue = travelWarrantWageRecapitulationListColumns;
                break;
            case 'travelWarrantAdditionalInfoList':
                columnsValue = travelWarrantAdditionalInfoListColumns;
                break;
            case 'travelWarrantExpenseList':
                columnsValue = travelWarrantExpenseListColumns;
                break;
            case 'travelWarrantPaymentList':
                columnsValue = travelWarrantPaymentListColumns;
                break;
            case 'travelWarrantPlanList':
                columnsValue = travelWarrantPlanListColumns;
                break;
            case 'travelWarrantRelationList':
                columnsValue = travelWarrantRelationListColumns;
                break;
            case 'travelWarrantCo2List':
                columnsValue = travelWarrantCo2ListColumns;
                break;
            case 'travelWarrantFileList':
                columnsValue = travelWarrantFileListColumns;
                break;
            case 'travelWarrantNoteList':
                columnsValue = travelWarrantNoteListColumns;
                break;
            default:
                columnsValue = [];
                break;
        }
        setColumns(columnsValue);
        setLoading(false);
    };

    const edit = (record: any) => {};

    const travelWarrantTotalsListColumns: any = [
        {
            title: geti18nText('travelOverview.costs.table.column.costType'),
            dataIndex: 'totalType',
            render: (text: any, record: any) => {
                if (record.totalType) {
                    return geti18nText('app.enum.TOTAL_TYPE.' + record.totalType);
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.amountInCurrency'),
            dataIndex: 'totalValueInCurrency',
            align: 'right',
            render: (totalValueInCurrency: any) => {
                return <Text>{numberFormat(totalValueInCurrency ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.isoCode'),
            dataIndex: ['currency', 'isoCode'],
            render: (text: any, record: any) => {
                if (record.currency) {
                    return record?.currency?.isoCode;
                }
            },
        },
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantWage.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      render: (exchangeRate: any) => {
                          return <Text>{numberFormat(exchangeRate ?? 0, 6)}</Text>;
                      },
                  },
                  {
                      title: geti18nText('travelWageExpense.edit.amount'),
                      dataIndex: 'totalValue',
                      align: 'right',
                      render: (totalValue: any) => {
                          return <Text>{numberFormat(totalValue ?? 0)}</Text>;
                      },
                  },
              ]
            : []),
        {
            title: geti18nText('travelWarrantWage.table.column.taxable'),
            dataIndex: 'taxable',
            render: (text: any, record: { taxable: any }) => {
                if (record.taxable) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
    ];

    const isNonStandardWage = (record: any) => {
        return record?.travelWage?.type !== enTravelWageType.NON_STANDARD;
    };

    const travelWarrantWageListColumns: any = [
        {
            title: geti18nText('travelWarrantWage.table.column.id'),
            dataIndex: 'id',
            width: '5%',
        },
        {
            title: geti18nText('travelWarrantWage.table.column.name'),
            dataIndex: ['travelWage', 'name'],
            render: (text: any, record: any) => {
                if (record.travelWage && record.travelWage.name) {
                    if (isNonStandardWage(record)) {
                        if (record.taxable) {
                            return (
                                <>
                                    {record.travelWage.name} {wageIconSmall}
                                </>
                            );
                        } else {
                            return record.travelWage.name;
                        }
                    } else {
                        if (record.taxable) {
                            return (
                                <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                    {record.travelWage.name} {wageIconSmall}
                                </span>
                            );
                        } else {
                            return (
                                <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                    {record.travelWage.name}
                                </span>
                            );
                        }
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.startDate'),
            dataIndex: 'startDate',
            width: '12%',
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.endDate'),
            dataIndex: 'endDate',
            width: '12%',
            render: (endDate: any, record: any) => {
                if (record.endDate) {
                    return getDateFormat(record.endDate, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.totalHours'),
            dataIndex: 'totalHours',
            render: (totalHours: any) => {
                return <Text>{numberFormat(totalHours ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.totalDays'),
            dataIndex: 'totalDays',
            width: '7%',
            render: (totalDays: any) => {
                return <Text>{numberFormat(totalDays ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.amountInCurrency'),
            dataIndex: 'amountInCurrency',
            align: 'right',
            width: '10%',
            render: (amountInCurrency: any) => {
                return <div style={{ float: 'right' }}>{numberFormat(amountInCurrency ?? 0)}</div>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.isoCode'),
            dataIndex: ['currency', 'isoCode'],
            width: '7%',
            render: (text: any, record: any) => {
                if (record.currency) {
                    return record?.currency?.isoCode;
                }
            },
        },
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantWage.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      width: '7%',
                      render: (exchangeRate: any) => {
                          return <Text>{numberFormat(exchangeRate ?? 0, 6)}</Text>;
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWage.table.column.amount'),
                      dataIndex: 'amount',
                      width: '10%',
                      align: 'right',
                      render: (amount: any) => {
                          return <div style={{ float: 'right' }}>{numberFormat(amount ?? 0)}</div>;
                      },
                  },
              ]
            : []),
        {
            title: geti18nText('travelWarrantWage.table.column.taxable'),
            dataIndex: 'taxable',
            width: '7%',
            render: (text: any, record: { taxable: any }) => {
                if (record.taxable) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
    ];

    const travelWarrantWageExpensesListColumns: any = [
        ...[
            {
                title: geti18nText('travelWarrantWageRecapitulation.date'),
                dataIndex: 'date',
                key: 'date',
                width: '10%',
                render: (text: any, record: any) => {
                    return (
                        <>
                            {record.date && (
                                <>
                                    {isNonStandardWage(record) ? (
                                        getDateFormat(record.date, 'DD.MM.YYYY')
                                    ) : (
                                        <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                            {getDateFormat(record.date, 'DD.MM.YYYY')}
                                        </span>
                                    )}
                                </>
                            )}
                        </>
                    );
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.day'),
                dataIndex: 'wageNumber',
                key: 'wageNumber',
                width: '10%',
                render: (wageNumber: any, record: any) => {
                    if (isNonStandardWage(record)) {
                        return <span>{numberFormat(wageNumber ?? 0)}</span>;
                    } else
                        return (
                            <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {numberFormat(wageNumber ?? 0)}
                            </span>
                        );
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.travelWageName'),
                dataIndex: ['travelWage', 'name'],
                key: 'travelWageName',
                render: (text: any, record: any) => {
                    if (record.travelWage && record.travelWage.name) {
                        if (isNonStandardWage(record)) {
                            if (record.taxable) {
                                return (
                                    <>
                                        {record.travelWage.name} {wageIconSmall}
                                    </>
                                );
                            } else {
                                return record.travelWage.name;
                            }
                        } else {
                            if (record.taxable) {
                                return (
                                    <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                        {record.travelWage.name} {wageIconSmall}
                                    </span>
                                );
                            } else {
                                return (
                                    <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                        {record.travelWage.name}
                                    </span>
                                );
                            }
                        }
                    }
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.travelWageAmount'),
                dataIndex: ['travelWage', 'amount'],
                key: 'travelWageAmount',
                align: 'right',
                width: '9%',
                render: (travelWage: any, record: any) => {
                    if (isNonStandardWage(record)) {
                        return <span style={{ float: 'right' }}>{numberFormat(record.travelWage?.amount ?? 0)}</span>;
                    } else
                        return (
                            <span style={{ float: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {numberFormat(record.travelWage?.amount ?? 0)}
                            </span>
                        );
                },
            },
            {
                title: (
                    <div style={{ display: 'grid' }}>
                        <div style={{ justifySelf: 'center' }}>
                            {geti18nText('travelWarrantWageRecapitulation.wageExpensesName')}
                            <Tooltip title={geti18nText('travelWarrantWageRecapitulation.wageExpensesName.info')}>
                                <InfoCircleTwoTone style={{ marginLeft: '5px' }} />
                            </Tooltip>
                        </div>
                    </div>
                ),
                dataIndex: 'travelWageExpenses',
                key: 'travelWarrantWageRecapitulation',
                render: (wageExpensesName: any, record: any) => {
                    return (
                        <Switch
                            style={{ marginRight: '5px' }}
                            checkedChildren={record?.travelWageExpenses?.name}
                            unCheckedChildren={record?.travelWageExpenses?.name}
                            defaultChecked={record?.active}
                            disabled={true}
                        />
                    );
                },
            },
            {
                title: paymentInDeclaredCurrency
                    ? geti18nText('travelWarrantWage.table.column.amountInCurrency')
                    : geti18nText('travelWarrantWageRecapitulation.amount'),
                key: 'amountInCurrency',
                dataIndex: 'amountInCurrency',
                align: 'right',
                width: '7%',
                render: (amount: any, record: any) => {
                    if (isNonStandardWage(record)) {
                        return <span style={{ float: 'right' }}>{numberFormat(record.amountInCurrency ?? 0)}</span>;
                    } else
                        return (
                            <span style={{ float: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {numberFormat(record.amountInCurrency ?? 0)}
                            </span>
                        );
                },
            },
            {
                title: geti18nText('travelWarrantWageRecapitulation.travelWageCurrency'),
                dataIndex: 'travelWageCurrency',
                key: 'travelWageCurrency',
                width: '5%',
                render: (text: any, record: any) => {
                    const travelWageCurrency = record?.currency?.isoCode ?? '';
                    if (isNonStandardWage(record)) {
                        return <span>{travelWageCurrency}</span>;
                    } else
                        return <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>{travelWageCurrency}</span>;
                },
            },
        ],
        ...(paymentInDeclaredCurrency
            ? [
                  {
                      title: geti18nText('travelWarrantRecapitulation.table.exchangeRate'),
                      key: 'exchangeRate',
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      width: '7%',
                      render: (amount: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return (
                                  <span style={{ float: 'right', display: 'inline' }}>
                                      {numberFormat(record.exchangeRate ?? 0, 5)}
                                  </span>
                              );
                          } else
                              return (
                                  <span
                                      style={{
                                          float: 'right',
                                          display: 'inline',
                                          color: isDarkTheme ? '#7ce7ac' : '#23c76c',
                                      }}
                                  >
                                      {numberFormat(record.exchangeRate ?? 0, 5)}
                                  </span>
                              );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWageRecapitulation.amount'),
                      key: 'amount',
                      dataIndex: 'amount',
                      align: 'right',
                      width: '8%',
                      render: (amount: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return (
                                  <span style={{ float: 'right', display: 'inline' }}>
                                      {numberFormat(record.amount ?? 0)}
                                  </span>
                              );
                          } else
                              return (
                                  <span
                                      style={{
                                          float: 'right',
                                          display: 'inline',
                                          color: isDarkTheme ? '#7ce7ac' : '#23c76c',
                                      }}
                                  >
                                      {numberFormat(record.amount ?? 0)}
                                  </span>
                              );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWageRecapitulation.travelWageCurrency'),
                      dataIndex: 'defaultCurrency',
                      key: 'defaultCurrency',
                      width: '5%',
                      render: (text: any, record: any) => {
                          const travelWageCurrency = record?.defaultCurrency?.isoCode ?? '';
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return <span>{travelWageCurrency}</span>;
                          } else
                              return (
                                  <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                      {travelWageCurrency}
                                  </span>
                              );
                      },
                  },
              ]
            : []),
    ];

    const travelWarrantWageRecapitulationListColumns: any = [
        {
            title: geti18nText('travelWarrantRecapitulation.table.time'),
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            render: (text: any, record: any) => {
                if (record.startDate && record.endDate) {
                    if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                        return (
                            getDateFormat(record.startDate, `DD.MM.YY' HH:mm`) +
                            ' - ' +
                            getDateFormat(record.endDate, `DD.MM.YY' HH:mm`)
                        );
                    } else {
                        return (
                            <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {getDateFormat(record.startDate, `DD.MM.YY' HH:mm`) +
                                    ' - ' +
                                    getDateFormat(record.endDate, `DD.MM.YY' HH:mm`)}
                            </span>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.country'),
            dataIndex: 'country',
            key: 'country',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.country && record.country.name) {
                    if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                        return <span style={{ wordBreak: 'keep-all' }}>{record.country.name}</span>;
                    } else {
                        return (
                            <span style={{ wordBreak: 'keep-all', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {record.country.name}
                            </span>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.hours'),
            dataIndex: 'formattedHours',
            width: '8%',
            key: 'formattedHours',
            render: (formattedHours: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return formattedHours ?? 0;
                } else {
                    return <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>{formattedHours ?? 0}</span>;
                }
            },
        },
        {
            title: (
                <div style={{ wordBreak: 'keep-all' }}>
                    {geti18nText('travelWarrantRecapitulation.table.hoursForWage')}
                </div>
            ),
            key: 'wageHours',
            dataIndex: 'wageHours',
            width: '8%',
            render: (wageHours: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return numberFormat(wageHours ?? 0);
                } else {
                    return (
                        <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {numberFormat(wageHours ?? 0)}
                        </span>
                    );
                }
            },
        },
        {
            title: <div style={{ wordBreak: 'keep-all' }}>{geti18nText('travelWarrantRecapitulation.table.days')}</div>,
            dataIndex: 'days',
            width: '7%',
            key: 'days',
            render: (days: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return days != undefined ? days : '0';
                } else {
                    return (
                        <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {days != undefined ? days : '0'}
                        </span>
                    );
                }
            },
        },
        {
            title: (
                <div style={{ wordBreak: 'keep-all' }}>
                    {geti18nText('travelWarrantRecapitulation.table.wageAmount')}
                </div>
            ),
            key: 'wageAmount',
            dataIndex: 'wageAmount',
            align: 'right',
            width: '8%',
            render: (wageAmount: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return (
                        <div style={{ float: 'right', textAlign: 'right' }}>
                            {numberFormat(wageAmount ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                } else {
                    return (
                        <div style={{ float: 'right', textAlign: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {numberFormat(wageAmount ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.wageExpenses'),
            key: 'wageExpenses',
            dataIndex: 'wageExpenses',
            align: 'right',
            width: '13%',
            render: (wageExpenses: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return (
                        <div style={{ float: 'right', textAlign: 'right' }}>
                            {numberFormat(wageExpenses ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                } else {
                    return (
                        <div style={{ float: 'right', textAlign: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {numberFormat(wageExpenses ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                }
            },
        },
        {
            title: paymentInDeclaredCurrency
                ? geti18nText('travelWarrantRecapitulation.table.amountInCurrency')
                : geti18nText('travelWarrantRecapitulation.table.amount'),
            key: 'amountInCurrency',
            dataIndex: 'amountInCurrency',
            align: 'right',
            width: '10%',
            render: (text: any, record: any) => {
                const travelWageCurrency = record?.currency?.isoCode ?? defaultCurrencyISO;
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return (
                        <div style={{ float: 'right', textAlign: 'right', marginRight: '4px' }}>
                            {numberFormat(record.amountInCurrency ?? 0)} {travelWageCurrency}
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                float: 'right',
                                textAlign: 'right',
                                marginRight: '4px',
                                color: isDarkTheme ? '#7ce7ac' : '#23c76c',
                            }}
                        >
                            {numberFormat(record.amountInCurrency ?? 0)} {travelWageCurrency}
                        </div>
                    );
                }
            },
        },
        ...(paymentInDeclaredCurrency == true
            ? [
                  {
                      title: geti18nText('travelWarrantRecapitulation.table.exchangeRate'),
                      key: 'exchangeRate',
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      width: '10%',
                      render: (exchangeRate: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return <span style={{ float: 'right' }}>{numberFormat(exchangeRate ?? 1, 6)} </span>;
                          } else {
                              return (
                                  <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c', float: 'right' }}>
                                      {numberFormat(exchangeRate ?? 1, 6)}
                                  </span>
                              );
                          }
                      },
                  },
                  {
                      title:
                          geti18nText('travelWarrantRecapitulation.table.amount') +
                          (paymentInDeclaredCurrency ? ' (' + defaultCurrencyISO + ')' : ''),
                      key: 'amount',
                      dataIndex: 'amount',
                      align: 'right',
                      width: '10%',
                      render: (amount: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return (
                                  <div style={{ float: 'right', marginRight: '3px' }}>
                                      {numberFormat(amount ?? 0)} {paymentInDeclaredCurrency ? '' : defaultCurrencyISO}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ float: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                      {numberFormat(amount ?? 0)} {paymentInDeclaredCurrency ? '' : defaultCurrencyISO}
                                  </div>
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    const travelWarrantAdditionalInfoListColumns: any = [
        {
            title: geti18nText('travelWarrantRelation.edit.travelRelation'),
            dataIndex: ['destination'],
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.address'),
            dataIndex: ['eventAddress'],
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.accomodationNeeded'),
            dataIndex: ['needAccommodation'],
            render: (text: any, record: any) => {
                if (record.needAccommodation) {
                    return geti18nText('app.enum.TRAVEL_NEED_ACCOMMODATION.' + record.needAccommodation);
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.accomodationOrganizator'),
            dataIndex: ['accommodationRecommendation'],
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.transportNeeded'),
            dataIndex: ['needTransport'],
            render: (text: any, record: any) => {
                if (record.needTransport) {
                    return geti18nText('app.enum.TRAVEL_NEED_TRANSPORT.' + record.needTransport);
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.travelVehicle'),
            dataIndex: ['travelVehicle', 'name'],
            render: (text: any, record: any) => {
                if (record.travelVehicle) {
                    return record.travelVehicle.name;
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.luggage'),
            dataIndex: ['baggageType'],
            render: (text: any, record: any) => {
                if (record.baggageType) {
                    return geti18nText('app.enum.TRAVEL_BAGGAGE_TYPE.' + record.baggageType);
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.showTime'),
            dataIndex: ['showTime'],
            render: (text: any, record: { showTime: any }) => {
                if (record.showTime) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.arrival'),
            dataIndex: ['arrivalDate'],
            render: (arrivalDate: any, record: any) => {
                if (record.arrivalDate) {
                    return getDateFormat(record.arrivalDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.return'),
            dataIndex: ['returnDate'],
            render: (returnDate: any, record: any) => {
                if (record.returnDate) {
                    return getDateFormat(record.returnDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.arrivalDayPart'),
            dataIndex: ['arrivalDayPart'],
            render: (text: any, record: any) => {
                if (record.arrivalDayPart) {
                    return geti18nText('app.enum.TRAVEL_DAY_PARTS.' + record.arrivalDayPart);
                }
            },
        },
        {
            title: geti18nText('travelWarrantOrganization.table.column.returnDayPart'),
            dataIndex: ['returnDayPart'],
            render: (text: any, record: any) => {
                if (record.returnDayPart) {
                    return geti18nText('app.enum.TRAVEL_DAY_PARTS.' + record.returnDayPart);
                }
            },
        },
    ];

    const travelWarrantExpenseListColumns: any = [
        ...[
            {
                title: geti18nText('travelWarrantExpense.table.column.travelExpenseName'),
                dataIndex: ['travelExpense', 'name'],
                render: (text: any, record: any) => {
                    if (record.travelExpense) {
                        if (record.taxable) {
                            return (
                                <>
                                    {record.travelExpense.name} {wageIconSmall}
                                </>
                            );
                        } else {
                            return record.travelExpense.name;
                        }
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.expenseDate'),
                dataIndex: 'expenseDate',
                width: '6%',
                render: (expenseDate: any, record: any) => {
                    if (record.expenseDate) {
                        return getDateFormat(record.expenseDate, 'DD.MM.YYYY');
                    }
                },
            },
        ],
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      width: '6%',
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record?.currency?.isoCode;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      width: '6%',
                      render: (exchangeRate: any) => {
                          return exchangeRate ? (
                              <div style={{ float: 'right' }}>{numberFormat(exchangeRate, 6)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      width: '6%',
                      render: (amount: any) => {
                          return amount ? <div style={{ float: 'right' }}>{numberFormat(amount)}</div> : '';
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      width: '128px',
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record?.currency?.isoCode;
                          }
                      },
                  },
              ]),
        ...[
            {
                title: geti18nText('travelWarrantExpense.table.column.expensePaymentMethod'),
                dataIndex: ['travelExpensePaymentMethod', 'name'],
                render: (text: any, record: any) => {
                    if (record.travelExpensePaymentMethod) {
                        return record.travelExpensePaymentMethod.name;
                    }
                },
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.invoiceNr'),
                dataIndex: 'invoiceNr',
            },
            {
                title: geti18nText('travelWarrantExpense.table.column.description'),
                dataIndex: 'description',
                render: (text: any, record: any) => {
                    if (record?.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '220px' }}
                                title={record.description}
                            >
                                <div style={{ maxWidth: '155px' }}>
                                    <p
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginTop: '-0.4rem',
                                            marginBottom: '-1rem',
                                        }}
                                    >
                                        {record.description}
                                    </p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
        ],
    ];

    const travelWarrantPaymentListColumns: any = [
        ...[
            {
                title: geti18nText('travelWarrantPayment.table.column.id'),
                dataIndex: 'id',
                width: '5%',
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.paymentType'),
                dataIndex: 'paymentType',
                render: (text: any, record: any) => {
                    if (record.paymentType) {
                        return geti18nText('app.enum.TRAVEL_WARRANT_PAYMENT_TYPE.' + record.paymentType);
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.paymentDate'),
                dataIndex: 'paymentDate',
                render: (paymentDate: any, record: any) => {
                    if (record.paymentDate) {
                        return getDateFormat(record.paymentDate, 'DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.advance'),
                dataIndex: 'advance',
                render: (text: any, record: { advance: any }) => {
                    if (record.advance) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.sort'),
                dataIndex: 'sort',
                render: (text: any, record: any) => {
                    if (record.sort) {
                        return getTravelWarrantPaymentSortColor(record.sort, travelWarrantPaymentSortEnum);
                    }
                },
            },
            {
                title: geti18nText('travelWarrantPayment.table.column.description'),
                dataIndex: 'description',
                render: (description: any, record: any) => {
                    if (record && record.description) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '200px' }}
                                title={record.description}
                            >
                                <div style={{ height: '20px', maxWidth: '200px' }}>
                                    <p className={'description-paragraph'}>{record && record.description}</p>
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
        ],
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record?.currency?.isoCode;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      render: (exchangeRate: any) => {
                          return exchangeRate ? (
                              <div style={{ float: 'right' }}>{numberFormat(exchangeRate, 6)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      render: (amount: any) => {
                          return amount ? <div style={{ float: 'right' }}>{numberFormat(amount)}</div> : '';
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('travelWarrantExpense.table.column.amountInCurrency'),
                      dataIndex: 'amountInCurrency',
                      align: 'right',
                      render: (amountInCurrency: any) => {
                          return amountInCurrency ? (
                              <div style={{ float: 'right' }}>{numberFormat(amountInCurrency)}</div>
                          ) : (
                              ''
                          );
                      },
                  },
                  {
                      title: geti18nText('travelWarrantExpense.table.column.isoCode'),
                      dataIndex: ['currency', 'isoCode'],
                      render: (text: any, record: any) => {
                          if (record.currency) {
                              return record?.currency?.isoCode;
                          }
                      },
                  },
              ]),
        ...[
            {
                title: geti18nText('travelWarrantPayment.table.column.status'),
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    if (record.status) {
                        return getTravelWarrantPaymentStatusIcon(record.status, travelWarrantPaymentStatusEnum);
                    }
                },
            },
        ],
    ];

    const travelWarrantPlanListColumns: any = [
        {
            title: geti18nText('travelWarrantRelation.edit.travelRelation'),
            dataIndex: ['destination'],
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.country'),
            dataIndex: ['country', 'name'],
        },
        {
            title: geti18nText('travelWarrantWage.table.column.startDate'),
            dataIndex: 'startDate',
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.endDate'),
            dataIndex: 'endDate',
            render: (endDate: any, record: any) => {
                if (record.endDate) {
                    return getDateFormat(record.endDate, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.travelVehicleName'),
            dataIndex: ['travelVehicle', 'name'],
            render: (text: any, record: any) => {
                if (record.travelVehicle) {
                    return record.travelVehicle.name;
                }
            },
        },
    ];

    const getDestinationName = (name: any) => {
        const returnName = name.replaceAll(' - Granični prijelaz', '');
        return returnName;
    };

    const travelWarrantRelationListColumns: any = [
        {
            title: geti18nText('travelWarrantRelation.table.column.travelRelation'),
            dataIndex: 'name',
            render: (text: any, record: any) => {
                if (record.name) {
                    if (record.taxable) {
                        return (
                            <>
                                {getDestinationName(record.name)} {wageIconSmall}
                            </>
                        );
                    } else {
                        return getDestinationName(record.name);
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.travelVehicleName'),
            dataIndex: ['travelVehicle', 'name'],
            render: (text: any, recrod: any) => {
                if (recrod.travelVehicle) {
                    return recrod.travelVehicle.name;
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.counterStart'),
            dataIndex: 'counterStart',
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.counterEnd'),
            dataIndex: 'counterEnd',
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.distance'),
            dataIndex: 'distance',
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.amount'),
            dataIndex: 'amount',
            align: 'right',
            render: (amount: any) => {
                return amount ? (
                    <div style={{ float: 'right' }}>{numberFormat(amount)}</div>
                ) : (
                    <div style={{ float: 'right' }}>{numberFormat(0)}</div>
                );
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.currency'),
            render: (text: any) => {
                return defaultCurrencyISO;
            },
        },
    ];

    const travelWarrantCo2ListColumns: any = [
        {
            title: geti18nText('travelWarrantCo2.table.column.travelVehicle'),
            dataIndex: ['travelVehicle', 'name'],
            width: '30%',
        },
        {
            title: geti18nText('travelWarrantCo2.table.column.amount'),
            dataIndex: 'amount',
            width: '20%',
            render: (amount: any, record: any) => {
                return <div style={{ float: 'right' }}>{NyUtils.formatNumber(record.amount ?? 0, 2) + ' kg'}</div>;
            },
        },
        {
            title: geti18nText('travelWarrantCo2.table.column.note'),
            dataIndex: 'description',
            width: '50%',
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={record.description}>
                            <div style={{ height: '20px', maxWidth: '400px' }}>
                                <p className={'description-paragraph'}>{record && record.description}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
    ];

    const travelWarrantFileListColumns: any = [
        {
            title: geti18nText('fileList.table.column.file.name'),
            dataIndex: 'name',
            width: '20%',
            render: (name: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={name}>
                        <div className={columnStyleClass}>
                            <p className="description-paragraph">{name}</p>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('fileList.table.column.description'),
            dataIndex: 'description',
            width: '20%',
            render: (description: any) => {
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={description}>
                        <div className={'description-div-small'}>
                            <p className="description-paragraph">{description}</p>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('fileList.table.column.uploaded'),
            dataIndex: 'uploaded',
            render: (text: any, record: any) => {
                if (record.uploaded) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '400px' }}
                            title={moment(record.uploaded).format('DD.MM.YYYY HH:mm')}
                        >
                            <div className={'description-div-small'}>
                                <p className="description-paragraph">
                                    {moment(record.uploaded).format('DD.MM.YYYY HH:mm')}
                                </p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('fileList.table.column.type'),
            dataIndex: 'documentType',
            render: (text: any, record: any) => {
                let title = '';
                if (record?.travelWarrantExpense?.id) {
                    title = geti18nText('travelWarrantOrganization.file.list.column.payment.other');
                } else if (record?.travelWarrantAdditionalInfo?.id) {
                    title = geti18nText('travelWarrantOrganization.file.list.column.payment.default');
                } else if (record.documentType) {
                    title = geti18nText('menu.work.obligation.call.table.enum.' + record.documentType);
                }
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={title}>
                        <div className={'description-div-small'}>
                            <p className="description-paragraph">{title}</p>
                        </div>
                    </Tooltip>
                );
            },
        },

        {
            title: geti18nText('fileList.table.column.user.name'),
            dataIndex: ['person.firstName'],
            render: (text: any, record: any) => {
                const personValue = record?.employee?.person
                    ? record.employee.person.firstName + ' ' + record.employee.person.lastName
                    : record.person
                    ? record.person.firstName + ' ' + record.person.lastName
                    : record.userPerson
                    ? record.userPerson.firstName + ' ' + record.userPerson.lastName
                    : record?.user?.person
                    ? record.user.person.firstName + ' ' + record.user.person.lastName
                    : '';
                return (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={personValue}>
                        <div className={'description-div-small'}>
                            <p className="description-paragraph">{personValue}</p>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        {(record?.fileId || record?.file?.id || record?.url) && (
                            <Tooltip title={geti18nText('app.default.download')}>
                                <CloudDownloadOutlined
                                    style={{ marginLeft: '10px', fontSize: '20px', color: 'green' }}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.FILES.DOWNLOAD +
                                                '/' +
                                                (record?.fileId ?? record?.file?.fileId ?? record?.file?.file?.id),
                                            null,
                                            record?.file?.name
                                        );
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    const travelWarrantNoteListColumns: any = [
        {
            title: geti18nText('travelWarrantStatusHistory.table.column.note'),
            dataIndex: ['note', 'note'],
            width: '30%',
            render: (note: any, record: any) => {
                if (record?.note?.note) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={record.note.note}>
                            <div style={{ height: '20px', maxWidth: '400px' }}>
                                <p className={'description-paragraph'}>{record.note.note}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('notificationSettings.edit.created'),
            dataIndex: ['note', 'created'],
            width: '12%',
            render: (note: any, record: any) => {
                if (record?.note?.created) {
                    return getDateFormat(record.note.created, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('notificationSettings.edit.updated'),
            dataIndex: ['note', 'modified'],
            width: '12%',
            render: (note: any, record: any) => {
                if (record?.note?.travelWarrantWage) {
                    return getDateFormat(record.note.travelWarrantWage, 'DD.MM.YYYY HH:mm');
                }
            },
        },
    ];

    const travelWarrantWageExpensesListSummary = (data: any) => {
        const groupedList: any =
            data?.length > 0 &&
            _(data)
                .filter(
                    (wage: any) =>
                        wage?.travelWage?.currency != null &&
                        wage?.travelWage?.currency != undefined &&
                        wage?.travelWage?.currency != '' &&
                        isNonStandardWage(wage)
                )
                .groupBy((x) => x?.currency?.isoCode)
                .map((value: any, key: any) => {
                    let totalAmount = 0;
                    let totalAmountInCurrency = 0;
                    let exchangeRate = 0;
                    value.map((val: any) => {
                        exchangeRate = val?.exchangeRate;
                        totalAmount = totalAmount + (val?.amount ?? 0);
                        totalAmountInCurrency = totalAmountInCurrency + (val?.amountInCurrency ?? 0);
                        setDefaultCurrency(val?.defaultCurrency?.isoCode);
                    });
                    return {
                        currency: key,
                        exchangeRate: exchangeRate,
                        totalAmount: round(totalAmount, 2),
                        totalAmountInCurrency: round(totalAmountInCurrency, 2),
                    };
                })
                .value();

        const totalAmountSum = groupedList.reduce((sum: any, item: any) => sum + (item?.totalAmount ?? 0), 0);
        setAmounts(round(totalAmountSum, 2));

        return (
            <>
                {groupedList.map((item: any, index: number) => {
                    return (
                        <>
                            <Table.Summary.Row key={index}>
                                {index == 0 && (
                                    <Table.Summary.Cell colSpan={5} index={0} rowSpan={groupedList?.length}>
                                        <strong style={{ position: 'absolute', top: 4, right: 4 }}>
                                            {index == 0 ? geti18nText('app.default.total') : ''}
                                        </strong>
                                    </Table.Summary.Cell>
                                )}

                                <Table.Summary.Cell index={1}>
                                    <Text style={{ float: 'right' }} strong>
                                        {numberFormat(item?.totalAmountInCurrency)}
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Text style={{ float: 'left' }} strong>
                                        {item.currency}
                                    </Text>
                                </Table.Summary.Cell>
                                {paymentInDeclaredCurrency && (
                                    <Table.Summary.Cell index={1}>
                                        <Text style={{ float: 'right' }} strong>
                                            {numberFormat(item?.exchangeRate ?? 0, 5)}
                                        </Text>
                                    </Table.Summary.Cell>
                                )}
                                {paymentInDeclaredCurrency && (
                                    <Table.Summary.Cell index={1}>
                                        <Text style={{ float: 'right' }} strong>
                                            {numberFormat(item?.totalAmount)}
                                        </Text>
                                    </Table.Summary.Cell>
                                )}
                                {paymentInDeclaredCurrency && (
                                    <Table.Summary.Cell index={4}>
                                        <Text style={{ float: 'left' }} strong>
                                            {defaultCurrency}
                                        </Text>
                                    </Table.Summary.Cell>
                                )}
                            </Table.Summary.Row>
                        </>
                    );
                })}
                {paymentInDeclaredCurrency && (
                    <Table.Summary.Row>
                        <>
                            <Table.Summary.Cell colSpan={8} index={0}>
                                <strong style={{ float: 'right', marginRight: '2px' }}>
                                    {/*   {geti18nText('app.default.total')} */}
                                </strong>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={3}>
                                <Text style={{ float: 'right' }} strong>
                                    {numberFormat(amounts)}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <Text style={{ float: 'left' }} strong>
                                    {defaultCurrency}
                                </Text>
                            </Table.Summary.Cell>
                        </>
                    </Table.Summary.Row>
                )}
            </>
        );
    };

    const summary = (data: any) => {
        if (row?.property === 'travelWarrantWageExpensesList') {
            return travelWarrantWageExpensesListSummary(data);
        } else {
            return null;
        }
    };

    return (
        <>
            {loading || !columns ? (
                <NySpinner />
            ) : columns?.length == 0 || items?.length == 0 ? (
                <Empty />
            ) : (
                <>
                    <Table
                        size={'small'}
                        dataSource={items}
                        columns={columns}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => {
                                    edit(record);
                                },
                            };
                        }}
                        style={{ maxHeight: '260px', overflowY: 'auto' }}
                        sticky
                        summary={summary}
                    />
                </>
            )}
        </>
    );
};

export default NyHistoryCustomTable;
