import { geti18nText } from '@nybble/nyreact';
import { Col, Collapse, Descriptions, Divider, Row, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import moment from 'moment';
import { useState } from 'react';
import ContractsCTRIndex from '.';
import NyHistory from '../../../../../components/ny-history/NyHistory';
import useEnum from '../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const SummaryData = (props: any) => {
    const enContractPartyTwo = useEnum('CONTRACT_PARTY_TWO');
    const [activeKey, setActiveKey] = useState<any>(['1']);
    const getFormItemValue = (item: any) => {
        return props.form.getFieldValue([item]);
    };
    const { Panel } = Collapse;

    const getEmployee: any = () => {
        const employee = getFormItemValue('employee');
        if (employee?.text?.props?.children && employee?.text?.props?.children[0]) {
            return employee.text.props.children[0].props?.children;
        }
    };

    const dateFormat = (dateToFormat: any) => {
        const date = getFormItemValue(dateToFormat);
        return date ? moment(date).format('DD.MM.YYYY.') : '';
    };

    const getAnexData = () => (
        <ContractsCTRIndex
            defaultFilterValue={[
                { field: 'annex', condition: 'equals_bool', value: 1 },
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'contract.id', condition: 'equals', value: props.contractId },
            ]}
            contractId={props.contractId}
            contractMetadataEmployee={props?.contractEmployee}
            annex
            scroll={{ y: 300, x: 700 }}
        />
    );

    const getHistoryData = () => <NyHistory id={props.contractId} link={'contract'} />;

    const getItemMetadataFormatValue = (item: any) => {
        const dataType = item?.contractMetadata?.dataType ?? 0;
        switch (dataType) {
            case 3:
                return item.value == true
                    ? geti18nText('app.default.button.yes')
                    : geti18nText('app.default.button.no');
            case 4:
                return item?.value && moment(item.value).format('DD.MM.YYYY.');
            default:
                return item.value;
        }
    };

    return (
        <>
            <Tabs
                type="card"
                activeKey={props.tabKey}
                className="tabs-sticky"
                onChange={(key: any) => props.setTabKey(key)}
            >
                <TabPane tab={geti18nText('travelWarrantWizard.step.6')} key={'overview'}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Descriptions bordered size="small" column={1}>
                                <Descriptions.Item label={geti18nText('contract.edit.name')}>
                                    {getFormItemValue('name')}
                                </Descriptions.Item>
                                {props.partyTwo && props.partyTwo == enContractPartyTwo.EMPLOYEE && (
                                    <Descriptions.Item label={geti18nText('contract.edit.employee')}>
                                        {getEmployee()}
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label={geti18nText('contractsCTR.table.column.dateOfConclusion')}>
                                    {dateFormat('dateOfConclusion')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('contractsCTR.table.column.contractNumber')}>
                                    {getFormItemValue('contractNumber')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('contractsCTR.table.column.validFrom')}>
                                    {dateFormat('validFrom')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('contractsCTR.table.column.validTo')}>
                                    {dateFormat('validTo')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('contract.edit.remark')}>
                                    {getFormItemValue('remark')}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    {props?.metadata?.length > 0 && (
                        <>
                            <Divider />
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Collapse
                                        activeKey={activeKey}
                                        onChange={(key: any) => {
                                            setActiveKey(key);
                                        }}
                                    >
                                        <Panel header={geti18nText('contract.edit.metadata')} key="1">
                                            <Descriptions bordered size="small" column={1}>
                                                {props?.metadata &&
                                                    props.metadata.map((item: any) => {
                                                        return (
                                                            <Descriptions.Item label={item.contractMetadata.name}>
                                                                {getItemMetadataFormatValue(item)}
                                                            </Descriptions.Item>
                                                        );
                                                    })}
                                            </Descriptions>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </>
                    )}
                </TabPane>
                <TabPane tab={geti18nText('contract.type.tab.anex')} key={'anex'}>
                    {getAnexData()}
                </TabPane>
                {HumanResourcesRights.canCreateContract() && (
                    <TabPane tab={geti18nText('app.default.change.history')} key={'history'}>
                        {getHistoryData()}
                    </TabPane>
                )}
            </Tabs>
        </>
    );
};

export default SummaryData;
