import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { ThunkAction } from 'redux-thunk';
import persistedReducer, { RootState } from './rootReducer';

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
});

const store = configureStore({
    reducer: persistedReducer,
    middleware: customizedMiddleware,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
