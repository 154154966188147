import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { validateIBAN } from '../../../../utils/Validation';
import BankSearch from './search';

const BankAccountEdit = (props: any) => {
    const { item } = useSelector((state: RootState) => state.selectedItem);

    const [editHeader, setEditHeader] = useState(geti18nText('bankAccount.edit.new'));
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    function setValues(dataForm: any) {
        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(dataForm.id);
        }

        delete dataForm.active;
        dataForm.company = { id: item };
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('bankAccount.edit.new'));
    };

    const onBankChange = (value: any) => {};

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.BANK_ACCOUNT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            nyTestId="bank-account-edit"
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['bankAccount', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <BankSearch
                        label={geti18nText('bankAccount.edit.bank')}
                        name={'bank'}
                        required={false}
                        onChange={onBankChange}
                    />
                    <Form.Item
                        label={geti18nText('bankAccount.edit.iban')}
                        name="iban"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                            {
                                validator: validateIBAN,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default BankAccountEdit;
