import { geti18nText, NyModalConfirm, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
// import { removeTab } from '../../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, okNotification, onKeyDownTextAreaCustom } from '../../../../../utils/Utils';

const SendTripModal = ({ visible, setVisible }: any) => {
    const { id } = useParams<any>();
    const location = useLocation();
    const dispatch = useDispatch();
    const [modalForm] = Form.useForm();
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        fetchAppSettings();
    }, []);

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' },
            { field: 'setting_key', condition: 'equals', value: 'TRAVEL_WARRANT_REPORT_TEXT_TEMPLATE' },
        ];
    };

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    const element = result.data[0];
                    if (!modalForm.getFieldValue('report')) {
                        modalForm.setFieldsValue({
                            report:
                                element.value !== null && element.value !== undefined
                                    ? element.value
                                    : element.defaultValue,
                        });
                    }
                }
            }
        });
    };

    const save = () => {
        modalForm.validateFields().then((values: any) => {
            values.id = id;
            NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.END_TRIP + '/' + id, undefined, values).then(
                (result: any) => {
                    if (loading) {
                        setLoading(false);
                    }
                    if (result && result.status === RESPONSE.OK) {
                        okNotification();
                        setVisible(false);
                        modalForm.resetFields();
                        // dispatch(removeTab(location.pathname));
                    } else {
                        errorNotification();
                    }
                }
            );
        });
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <div style={{ display: 'block', margin: '5px' }}>
                <Button block onClick={() => setVisible(true)}>
                    {geti18nText('travelWarrantPlan.edit.sendReport')}
                </Button>
            </div>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('travelWarrantPlan.edit.sendReport')}
                    onOk={(event: any) => {
                        setLoading(true);
                        event.stopPropagation();
                        event.preventDefault();
                        save();
                    }}
                    onCancel={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    okText={geti18nText('app.default.button.send')}
                    confirmLoading={loading}
                    maskClosable={false}
                    footer={
                        <>
                            <Button style={{ display: 'inline' }} onClick={() => setVisible(false)}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <NyModalConfirm
                                title={geti18nText('travelWarrantPlan.edit.sendReport.confirm')}
                                onConfirm={() => {
                                    save();
                                }}
                            >
                                <Button style={{ display: 'inline' }} type="primary">
                                    {geti18nText('app.default.button.send')}
                                </Button>
                            </NyModalConfirm>
                        </>
                    }
                >
                    <Form form={modalForm} component={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row
                            gutter={24}
                            style={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: '70vh',
                            }}
                        >
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.report')}
                                    name="report"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        autoSize={{ minRows: 4, maxRows: 8 }}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                        autoFocus
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default SendTripModal;
