import { NyDataTable, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../../utils/Filters';
import { numberFormat } from '../../../../../../utils/Utils';
import AssetWriteOffItemsEdit from './edit';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { setActiveTab } from '../../../../../../slices/tabsSlice';

const AssetWriteOffItemsIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    assetWriteOffId,
    canCreate,
    booked,
    bookedGeneralLedger,
    setDataLength,
    dataLength,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);
    const dispatch = useDispatch();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'asset_write_off_id',
                condition: 'equals',
                value: assetWriteOffId,
            },
        ];
    };

    const columns: any = [
        {
            title: geti18nText('asset.acquisition.item.table.column.asset'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                serialNumber: 'serialNumber',
                itemName: 'itemName',
                itemCode: 'itemCode',
            }),
            render: (text: any, record: any) => {
                if (record?.asset?.assetModel?.name && record?.asset?.id) {
                    return record.asset.assetModel.name + ' (ID: ' + record.asset.id + ')';
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.serialNumber'),
            dataIndex: ['asset', 'serialNumber'],
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record?.asset?.serialNumber) {
                    return (
                        <Button
                            onClick={() => {
                                // dispatch(setActiveTab('/fixed-asset/' + record?.asset?.id));
                                history.push('/accounting/assets/fixed-asset/' + record?.asset?.id);
                            }}
                            type="link"
                            style={{ textDecoration: 'underline' }}
                        >
                            <div style={{ marginLeft: '-12px' }}> {record.asset.serialNumber} </div>
                        </Button>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.inventoryNumber'),
            dataIndex: ['asset', 'serialNumber_2'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.asset && record.asset.serialNumber2) {
                    return (
                        <Tooltip
                            placement="topLeft"
                            overlayStyle={{ maxWidth: '300px' }}
                            title={record.asset.serialNumber2}
                        >
                            <p
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginTop: '-0.4rem',
                                    marginBottom: '-0.5rem',
                                }}
                            >
                                {record.asset.serialNumber2}
                            </p>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.quantity'),
            dataIndex: 'quantity',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.price'),
            dataIndex: ['asset', 'aquisitionPrice'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (text: any, record: any) => {
                if (record.asset && record.asset.aquisitionPrice) {
                    return numberFormat(record.asset.aquisitionPrice);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.acquisition.item.table.column.amount'),
            dataIndex: 'acquisitionCostValue',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (acquisitionCostValue: any) => {
                if (acquisitionCostValue) {
                    return numberFormat(acquisitionCostValue);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.valueIncrease'),
            dataIndex: 'valueIncrease',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (valueIncrease: any) => {
                if (valueIncrease) {
                    return numberFormat(valueIncrease);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.valueDecrease'),
            dataIndex: 'valueDecrease',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (valueDecrease: any) => {
                if (valueDecrease) {
                    return numberFormat(valueDecrease);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.initialState.item.table.column.acumulatedDepreciation'),
            dataIndex: 'acumulatedDepreciation',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (acumulatedDepreciation: any) => {
                if (acumulatedDepreciation) {
                    return numberFormat(acumulatedDepreciation);
                } else {
                    return numberFormat(0);
                }
            },
        },
        {
            title: geti18nText('asset.writeOff.item.table.column.amount'),
            dataIndex: 'amount',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            align: 'right',
            render: (amount: any) => {
                if (amount) {
                    return numberFormat(amount);
                } else {
                    return numberFormat(0);
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="asset-write-off-items"
            url={CONSTANTS_REQ.ASSET_WRITE_OFF_ITEM.LIST}
            addNewButtonText={geti18nText('asset.acquisition.add.asset')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={AssetWriteOffItemsEdit}
            onDataLoaded={(data: any) => {
                if (data) {
                    setDataLength(data?.length);
                } else {
                    setDataLength(null);
                }
            }}
            setDefaultFilterValue={() => setDefaultFilterValue()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            fetchWhenChange={refreshTable || assetWriteOffId}
            hideNewButton={!canCreate || !assetWriteOffId || booked || bookedGeneralLedger}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            shortcuts={true}
            editProps={{
                refreshTable: refreshTable,
                setRefreshTable: setRefreshTable,
                assetWriteOffId: assetWriteOffId,
                canCreate: canCreate && assetWriteOffId && !booked && !bookedGeneralLedger,
            }}
        />
    );
};

export default AssetWriteOffItemsIndex;
