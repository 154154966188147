import { geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const NyNotifNoteModal = ({ modalVisible, setModalVisible, title, data, setData, notifKey, setNotifKey }: any) => {
    const AssetAsignRequest = () => {
        let noteMap = data.note ? JSON.parse(data.note) : data ? data : {};

        return (
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col offset={1} span={22}>
                        <Form.Item label={geti18nText('notification.message.asset.assign.modal.createdBy')}>
                            <Input value={noteMap.createdBy} disabled={true} />
                        </Form.Item>{' '}
                    </Col>
                    <Col offset={1} span={22}>
                        <Form.Item label={geti18nText('notification.message.asset.assign.modal.decription')}>
                            <TextArea value={noteMap.description} rows={4} disabled={true} />
                        </Form.Item>{' '}
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={title}
                    onCancel={() => {
                        setModalVisible(false);
                        setData(undefined);
                        setNotifKey(undefined);
                    }}
                    width={600}
                    maskClosable={false}
                    centered
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    {data && notifKey === 'ASSET_ASSIGN_REQUEST' && <AssetAsignRequest />}
                    {data && notifKey === undefined && (
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item label={geti18nText('app.default.note')} labelAlign={'left'}>
                                    <TextArea value={data.note} rows={4} disabled={true} />
                                </Form.Item>{' '}
                            </Col>
                        </Row>
                    )}
                </Modal>
            )}
        </>
    );
};

export default NyNotifNoteModal;
