import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Alert, Col, Empty, Input, List, Radio, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
const { Search } = Input;

const ContractTypeSelectIndex = (
    form: any,
    contractType: any = null,
    setContractType: any = null,
    refresh: any,
    annex: boolean = false,
    mark: any = undefined,
    search = false
) => {
    const [data, setData] = useState<any>([]);
    const [initData, setInitData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setData(initData);
    }, [refresh]);

    const fetchData = () => {
        setLoading(true);
        const setDefaultFilterValue = () => {
            return [
                { field: 'documentTemplateId', condition: 'is_not_null', value: '' },
                { field: 'annex', condition: 'equals_bool', value: annex ? 1 : 0 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        };

        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));

        const url = !mark
            ? CONSTANTS_REQ.CONTRACT_TYPE.LIST
            : CONSTANTS_REQ.CONTRACT_TYPE.LIST_MARK.replace('{mark}', mark);
        NyRequestResolver.requestGet(url, { max: 999, search: stringUri }).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content) {
                if (search) {
                    const filteredObjects = Object.values(result?.data?.content).filter((item: any) =>
                        item.hasOwnProperty('documentTemplate')
                    );

                    setData(filteredObjects);
                    setInitData(filteredObjects);
                    if (result?.data?.content?.length == 1) {
                        onRowSelect(filteredObjects[0]);
                    } else if (result?.data?.content?.length < 1) {
                        onRowSelect(null);
                    }
                } else {
                    setData(result?.data?.content);
                    setInitData(result?.data?.content);
                    if (result?.data?.content?.length == 1) {
                        onRowSelect(result?.data?.content[0]);
                    } else if (result?.data?.content?.length < 1) {
                        onRowSelect(null);
                    }
                }
            }
        });
        setLoading(false);
    };

    const filterList = (value: any) => {
        let filteredData = initData;
        if (value && value != '' && value?.length > 0) {
            filteredData = initData.filter(
                (item: any) => item?.name && item.name.toLowerCase().includes(value.toLowerCase())
            );
        }
        setData(filteredData);
        if (filteredData?.length == 1) {
            onRowSelect(filteredData[0]);
        } else if (filteredData?.length < 1) {
            onRowSelect(null);
        }
    };

    const onRowSelect = (item: any) => {
        setContractType(item);
        form.setFieldsValue({ contractType: { id: item?.id } });
        form.setFieldsValue({ name: item?.name });
    };

    return (
        <>
            <div style={{ paddingBottom: '15px' }}>
                <Alert
                    message={
                        annex
                            ? geti18nText('contract.contractType.alert.type')
                            : geti18nText('contract.contractType.alert')
                    }
                    type="info"
                    showIcon
                />
            </div>

            <Row gutter={24}>
                <Col span={22} offset={1}>
                    <Search
                        placeholder={geti18nText('app.default.search')}
                        allowClear
                        size="large"
                        onSearch={(value: any) => filterList(value)}
                        autoFocus
                    />
                </Col>
            </Row>
            <div
                style={{
                    maxHeight: '500px',
                    overflowY: 'auto',
                    cursor: 'default',
                    marginTop: '25px',
                }}
            >
                {data != null && data?.length > 0 ? (
                    <List loading={loading} itemLayout="horizontal" pagination={false}>
                        {data?.length > 1 ? (
                            data?.map((item: any) => {
                                return (
                                    <List.Item
                                        onClick={() => onRowSelect(item)}
                                        style={{
                                            border: isDarkTheme ? '3px solid #111b26' : '3px solid #f3f3f3',
                                            borderRadius: '3px',
                                            backgroundColor:
                                                contractType?.id === item.id
                                                    ? isDarkTheme
                                                        ? '#102444'
                                                        : '#e8f4fc'
                                                    : undefined,
                                            transition: 'background-color 0.3s ease',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        <Radio
                                            checked={contractType?.id === item.id}
                                            style={{ paddingRight: '10px' }}
                                        />
                                        <List.Item.Meta
                                            title={<div style={{ fontSize: '15px' }}>{item.name}</div>}
                                            description={
                                                <span style={{ paddingLeft: '10px' }}>{item.description}</span>
                                            }
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </List.Item>
                                );
                            })
                        ) : (
                            <List.Item
                                style={{
                                    border: isDarkTheme ? '3px solid #111b26' : '3px solid #f3f3f3',
                                    borderRadius: '3px',
                                    backgroundColor: isDarkTheme ? '#102444' : '#e8f4fc',
                                    transition: 'background-color 0.3s ease',
                                    paddingLeft: '10px',
                                }}
                            >
                                <Radio checked={true} disabled={true} style={{ paddingRight: '10px' }} />
                                <List.Item.Meta
                                    title={<div style={{ fontSize: '15px' }}>{data[0]?.name}</div>}
                                    description={<span style={{ paddingLeft: '10px' }}>{data[0]?.description}</span>}
                                    style={{ cursor: 'default' }}
                                />
                            </List.Item>
                        )}
                    </List>
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};

export default ContractTypeSelectIndex;
