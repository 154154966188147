import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import PointAdditionDecisionIndex from '.';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import PointAdditionDecisionEdit from './edit';

const PointAdditionDecisionSearch = ({
    label = geti18nText('menu.pointAdditionDecision'),
    name,
    required = false,
    mustGetPopupContainer = false,
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    disabled = false,
    id,
    onChange = undefined,
}: any) => {
    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    return (
        <>
            <Form.Item
                label={label}
                name={name}
                rules={[
                    {
                        required: required,
                        message: geti18nText('app.default.required'),
                    },
                ]}
            >
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.POINT_ADDITION_DECISION.SEARCH}
                    map={{
                        id: 'id',
                        label: 'decisionNumber',
                        decisionNumber: 'decisionNumber',
                    }}
                    order={'decisionNumber'}
                    searchBy="decisionNumber"
                    mustGetPopupContainer={mustGetPopupContainer}
                    renderOption={(option: any) => option.decisionNumber}
                    setDefaultFilterValue={setDefaultFilterValue}
                    SearchPopupComponent={
                        <PointAdditionDecisionIndex id={id} nyId={'point-addition-decision-search'} />
                    }
                    AddNewModalComponent={canCreate() ? PointAdditionDecisionEdit : null}
                    disabled={disabled}
                    customListWidth={'1600px'}
                    onChange={onChange}
                />
            </Form.Item>
        </>
    );
};

export default PointAdditionDecisionSearch;
