import {
    CloseOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    FlagOutlined,
    ForwardOutlined,
    FrownOutlined,
    HistoryOutlined,
    LockOutlined,
    ToolOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { NyRequestResolver, NySession, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Form, Modal, Popconfirm, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AdditionalInfo from '../../../../components/additional-info';
import NyHistory from '../../../../components/ny-history/NyHistory';
import useEnum from '../../../../hooks/useEnum';
import { TasksRights } from '../../../../rights/tasksRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getAssetSelectOption,
    getDateFormat,
    getErrorNotificationMessage,
    getSearchFormat,
    getUserSelectOption,
    getUserSelectOptionFromSession,
    okNotification,
    setEnumFormat,
    setSearchFormat,
    setTaskWatch,
    unsetTaskWatch,
} from '../../../../utils/Utils';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { uploadFiles } from '../../../../utils/ny-file-upload-custom/FilesFunctions';
import ExistingTaskIndex from './task-modal/ExistingTaskIndex';
import NewTaskFromTemplateIndex from './task-modal/NewTaskFromTemplateIndex';
import WorkActivityIndex from './task-modal/WorkActivityIndex';

const CreateTaskIndex = ({
    selectedTaskTemplateId,
    setSelectedTaskTemplateId,
    selectedTaskId,
    setSelectedTaskId,
    refreshTable,
    setRefreshTable,
    visible,
    setVisible,
    value,
    parentTaskId,
    taskType,
    isTemplateView = false,
    // footer akcije
    getTaskStatusChangeHistory,
    setAssignModalVisible,
    setStartTaskVisible,
    setCancelTaskVisible,
    setResolveTaskVisible,
    setCannotResolveTaskVisible,
    setPartiallyResolveTaskVisible,
    setAddDescriptionVisible,
    setCloseTaskVisible,
    taskActionForm,
    listType = '',
}: any) => {
    const taskTypeEnum = useEnum('TASK_TYPE');
    const taskStatusEnum = useEnum('TASK_STATUS');
    const enTaskAction = useEnum('TASK_ACTION');
    const [form] = Form.useForm();

    const [objectMetadataForm] = Form.useForm();
    const { employee } = useSelector((state: RootState) => state.employee);

    const [activeKey, setActiveKey] = useState('1');
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2', '3']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [title, setTitle] = useState(geti18nText('task.edit.new'));
    const [taskStatus, setTaskStatus] = useState<any>(taskStatusEnum.NEW);
    const [taskStatusInit, setTaskStatusInit] = useState<any>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [parentTaskIdState, setParentTaskIdState] = useState<any>(-1);
    const [actions, setActions] = useState<any>([]);
    //metadata
    const [metadata, setMetadata] = useState([]);
    const [metadataTemplate, setMetadataTemplate] = useState([]);
    const [hasMetadata, setHasMetadata] = useState<boolean>(false);
    //files
    const uploadFilesRef = useRef<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [refreshFiles, setRefreshFiles] = useState(0);
    //history modal
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    //note modal
    const [visibleNoteModal, setVisibleNoteModal] = useState<any>(false);
    const [taskNoteId, setTaskNoteId] = useState<any>(false);
    const [taskNoteData, setTaskNoteData] = useState<any>({});
    //add subtask modal
    const [visibleSubTask, setVisibleSubTask] = useState<any>(false);
    const [selectedSubTaskId, setSelectedSubTaskId] = useState<any>(null);
    const [refreshWorktimeTable, setRefreshWorktimeTable] = useState<number>(0);
    const [refreshTaskWatchTable, setRefreshTaskWatchTable] = useState<number>(0);
    const [isWatched, setIsWatched] = useState<boolean>(false);
    const [watchLoading, setWatchLoading] = useState<boolean>(false);

    const getTaskType = () => {
        return taskType ? taskType : taskTypeEnum.SERVICE;
    };

    const getParentId = () => {
        return parentTaskId ? parentTaskId : null;
    };

    const canSave = () => {
        return TasksRights.canCreateAdminOrAgent() || TasksRights.isTasksUser();
    };

    const canSaveTemplateOrNewTask = () => {
        return canSave() && (isTemplateView || (!selectedTaskId && !selectedTaskTemplateId));
    };

    const getTaskTimeButtonVisible = () => {
        const values = form.getFieldsValue();
        return values?.type == taskTypeEnum.SUBTASK;
    };

    const getActionsArray = (type: any = null) => {
        return type ? actions && actions?.includes(type) : actions;
    };

    useEffect(() => {
        if (value != undefined) {
            setSelectedTaskId(value.id);
        }
    }, [value]);

    useEffect(() => {
        if (isTemplateView || listType == 'personal') {
            const user = NySession.getUser();
            if (user && !selectedTaskId && !value) {
                setSelectedUser(user);
                form.setFieldsValue({
                    user: getUserSelectOptionFromSession(user),
                });
            }
        }
    }, [selectedTaskTemplateId, listType]);

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3']);
        }
        setCollapseAll(!collapseAll);
    };

    const closeModal = () => {
        form.resetFields();
        objectMetadataForm.resetFields();
        if (setSelectedTaskId) setSelectedTaskId(null);
        if (setSelectedTaskTemplateId) setSelectedTaskTemplateId(null);
        setHasMetadata(false);
        setMetadata([]);
        setMetadataTemplate([]);
        setTaskStatus(taskStatusEnum.NEW);
        setTaskStatusInit(null);
        setSelectedUser(null);
        setVisible(false);
        setActiveKey('1');
    };

    const setValues = (dataForm: any) => {
        setTitle(dataForm.name);

        if (dataForm?.parent?.id) {
            setParentTaskIdState(dataForm?.parent?.id);
        }
        if (dataForm.user) {
            dataForm.user = getUserSelectOption(dataForm.user);
            setSelectedUser(dataForm.user);
        }
        if (selectedTaskTemplateId) {
            dataForm.description = null;
        }
        if (dataForm.status) {
            setTaskStatusInit(dataForm.status);
            setTaskStatus(dataForm.status);
            dataForm.status = setEnumFormat('TASK_STATUS', dataForm.status);
        }
        if (dataForm.assignedAsset) {
            dataForm.assignedAsset = getAssetSelectOption(dataForm.assignedAsset);
        }
        if (dataForm.taskPriority) {
            dataForm.taskPriority = setSearchFormat(dataForm.taskPriority, 'name', 'name');
        }
        if (dataForm.assignedTeam) {
            dataForm.assignedTeam = setSearchFormat(dataForm.assignedTeam, 'name', 'name');
        }
        if (dataForm.assignedUser) {
            dataForm.assignedUser = getUserSelectOption(dataForm.assignedUser);
        }
        if (dataForm.actions) {
            setActions(dataForm.actions);
        }

        if (dataForm.isWatched) {
            setIsWatched(true);
        } else {
            setIsWatched(false);
        }

        form.setFieldsValue(dataForm);
    };

    const savedAndGetId = (result: any) => {
        if (result && result.status === RESPONSE.CREATED) {
            closeModal();
            okNotification();
            if (refreshTable && setRefreshTable) {
                setRefreshTable((refreshTable: any) => refreshTable + 1);
            }
        } else {
            getErrorNotificationMessage(result);
        }
    };

    const saveRequest = async (values: any, valuesMetadata: any) => {
        if (selectedTaskId) {
            values = await normalizeValues(values, valuesMetadata);
            values.id = selectedTaskId;
            NyRequestResolver.requestPut(CONSTANTS_REQ.TASK.EDIT + '/' + selectedTaskId, undefined, values).then(
                (result: any) => {
                    savedAndGetId(result);
                }
            );
        } else {
            values.taskFileList = await uploadFilesTemp(files).then(async (value: any) => {
                values = await normalizeValues(values, valuesMetadata);
                values.taskFileList = value;
                values.id = null;
                NyRequestResolver.requestPost(CONSTANTS_REQ.TASK.EDIT, undefined, values).then((result: any) => {
                    savedAndGetId(result);
                });
            });
        }
    };

    const saveTask = () => {
        form.validateFields().then((values: any) => {
            if (values) {
                if (hasMetadata) {
                    objectMetadataForm.validateFields().then((valuesMetadata: any) => {
                        saveRequest(values, valuesMetadata);
                    });
                } else {
                    saveRequest(values, null);
                }
            }
        });
    };

    const uploadFilesTemp = async (files: any) => {
        return files?.length > 0
            ? await Promise.all(
                  (
                      await uploadFiles(
                          files,
                          null,
                          null,
                          null,
                          false,
                          CONSTANTS_REQ.FILES.UPLOAD,
                          null,
                          CONSTANTS_REQ.FILES.DELETE_BY_IDS,
                          null,
                          true,
                          undefined
                      )
                  ).map((value: any) => {
                      return {
                          file: value.id.toString(),
                          description: value.description,
                          name: value.file.name,
                      };
                  })
              )
            : [];
    };

    const normalizeValues = async (values: any, metadataValues: any) => {
        let metadataReturn: any = [];
        if (metadataValues) {
            for (const [key, value] of Object.entries(metadataValues)) {
                if (key != undefined && value != undefined) {
                    const metadataItem: any = metadataTemplate.find(
                        (item: any) => item.objectMetadata && item.objectMetadata.name === key
                    );
                    if (metadataItem.objectMetadata.dataType === 4) {
                        metadataReturn.push({
                            taskTemplateMetadata: { id: metadataItem.id },
                            value: getDateFormat(value, 'yyyy-MM-DD HH:mm:ss'),
                        });
                    } else {
                        metadataReturn.push({
                            taskTemplateMetadata: { id: metadataItem.id },
                            value: value,
                        });
                    }
                }
            }
        }
        values.taskMetadataList = metadataReturn;
        values.status = taskStatus;
        values.type = getTaskType();
        values.parent = { id: values?.parent?.id ?? getParentId() };
        if (values.taskPriority) values.taskPriority = getSearchFormat(values.taskPriority);
        if (values.assignedAsset) values.assignedAsset = getSearchFormat(values.assignedAsset);
        if (values.assignedTeam) values.assignedTeam = getSearchFormat(values.assignedTeam);
        if (values.assignedUser) values.assignedUser = getSearchFormat(values.assignedUser);
        if (values.user) values.user = getSearchFormat(values.user);
        return values;
    };

    const getCustomFooterContent = (
        <>
            {activeKey === '1' ? (
                <>
                    <div style={{ float: 'left', display: 'inline-flex' }}>
                        {hasMetadata && (
                            <Tooltip
                                placement="top"
                                visible={showTooltip}
                                title={geti18nText('app.default.shortcuts.collapse')}
                            >
                                {!collapseAll ? (
                                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                                ) : (
                                    <EyeInvisibleOutlined
                                        onClick={changeCollapseVisibility}
                                        className="ny-custom-button"
                                    />
                                )}
                            </Tooltip>
                        )}
                        {selectedTaskId && (
                            <div style={{ marginLeft: '5px' }}>
                                <AdditionalInfo
                                    created={form.getFieldValue('created')}
                                    createdBy={form.getFieldValue('createdBy')}
                                    updated={form.getFieldValue('updated')}
                                    updatedBy={form.getFieldValue('updatedBy')}
                                />
                            </div>
                        )}
                        {!isTemplateView && (
                            <Button
                                style={{ marginLeft: '5px' }}
                                icon={<HistoryOutlined />}
                                onClick={() => setHistoryModalVisible(true)}
                            >
                                {geti18nText('app.default.change.history')}
                            </Button>
                        )}
                        {selectedTaskId && (
                            <WorkActivityIndex
                                selectedTaskId={selectedTaskId}
                                parentTaskIdState={parentTaskIdState}
                                setRefreshWorktimeTable={setRefreshWorktimeTable}
                                refreshWorktimeTable={refreshWorktimeTable}
                                activeKey={activeKey}
                            />
                        )}
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        {getActionsArray(enTaskAction.ASSIGN) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        onClick={() => {
                                            setAssignModalVisible(true);
                                            saveTask();
                                        }}
                                        icon={<UserAddOutlined />}
                                        type="primary"
                                    >
                                        {geti18nText('task.status.action.1')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {getActionsArray(enTaskAction.START) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        onClick={() => {
                                            setStartTaskVisible(true);
                                        }}
                                        icon={<ForwardOutlined />}
                                    >
                                        {geti18nText('task.status.action.2')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {getActionsArray(enTaskAction.RESOLVE) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        onClick={() => {
                                            setAddDescriptionVisible(true);
                                            setResolveTaskVisible(true);
                                            taskActionForm.setFieldsValue({
                                                solutionDescription: form.getFieldValue('solutionDescription'),
                                            });
                                        }}
                                        icon={<FlagOutlined />}
                                    >
                                        {geti18nText('task.status.action.3')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {getActionsArray(enTaskAction.CANNOT_RESOLVE) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        onClick={() => {
                                            setCannotResolveTaskVisible(true);
                                            taskActionForm.setFieldsValue({
                                                solutionDescription: form.getFieldValue('solutionDescription'),
                                            });
                                        }}
                                        icon={<FrownOutlined />}
                                    >
                                        {geti18nText('task.status.action.4')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {getActionsArray(enTaskAction.PARTIALLY_RESOLVE) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        onClick={() => {
                                            setPartiallyResolveTaskVisible(true);
                                            taskActionForm.setFieldsValue({
                                                solutionDescription: form.getFieldValue('solutionDescription'),
                                            });
                                        }}
                                        icon={<ToolOutlined />}
                                    >
                                        {geti18nText('task.status.action.5')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {getActionsArray(enTaskAction.CLOSE) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        onClick={() => {
                                            setCloseTaskVisible(true);
                                        }}
                                        icon={<LockOutlined />}
                                    >
                                        {geti18nText('task.status.action.6')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {getActionsArray(enTaskAction.CANCEL) && (
                            <div style={{ padding: '0px', textAlign: 'left' }}>
                                <div style={{ display: 'block', marginLeft: '5px' }}>
                                    <Button
                                        danger
                                        onClick={() => {
                                            setCancelTaskVisible(true);
                                        }}
                                        icon={<CloseOutlined />}
                                        type="primary"
                                    >
                                        {geti18nText('task.status.action.7')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    {canSaveTemplateOrNewTask() && (
                        <div style={{ display: 'inline-flex' }}>
                            <Button onClick={saveTask} type={'primary'} style={{ marginLeft: '5px' }}>
                                {isTemplateView
                                    ? geti18nText('app.default.button.send')
                                    : geti18nText('app.default.button.save')}
                            </Button>
                        </div>
                    )}
                </>
            ) : activeKey == '2' ? (
                <div>
                    <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            setVisibleSubTask(true);
                            setSelectedSubTaskId(null);
                        }}
                    >
                        {geti18nText('tasks.list.add')}
                    </Button>
                </div>
            ) : activeKey == '3' ? (
                <div>
                    <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            setVisibleNoteModal(true);
                            setTaskNoteData(undefined);
                            setTaskNoteId(undefined);
                        }}
                    >
                        {geti18nText('tasks.list.add')}
                    </Button>
                </div>
            ) : activeKey == '5' ? (
                <div>
                    <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
                    {selectedTaskId && (
                        <WorkActivityIndex
                            selectedTaskId={selectedTaskId}
                            parentTaskIdState={parentTaskIdState}
                            refreshWorktimeTable={refreshWorktimeTable}
                            setRefreshWorktimeTable={setRefreshWorktimeTable}
                            activeKey={activeKey}
                        />
                    )}
                </div>
            ) : (
                <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
            )}

            {!isTemplateView &&
                activeKey === '1' &&
                (isWatched ? (
                    !watchLoading ? (
                        <Tooltip title={geti18nText('tasks.list.column.watch.hover.stop')}>
                            <Popconfirm
                                title={geti18nText('tasks.popup.watched.message.cancel')}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onConfirm={() => {
                                    setWatchLoading(true);
                                    unsetTaskWatch(
                                        form.getFieldValue('id'),
                                        setRefreshTable,
                                        setIsWatched,
                                        setWatchLoading,
                                        setRefreshTaskWatchTable
                                    );
                                }}
                            >
                                <EyeOutlined
                                    style={{
                                        color: 'green',
                                        fontSize: '18px',
                                        marginLeft: '8px',
                                        marginTop: '5px',
                                        float: 'left',
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    ) : (
                        <div
                            style={{
                                width: '8px',
                                display: 'inline-flex',
                                float: 'left',
                                marginLeft: '14px',
                                marginTop: '5px',
                            }}
                        >
                            {' '}
                            <NySpinner size="small" />
                        </div>
                    )
                ) : !watchLoading ? (
                    <Tooltip title={geti18nText('tasks.list.column.watch.hover')}>
                        <EyeInvisibleOutlined
                            style={{
                                color: '#1890ff',
                                fontSize: '18px',
                                marginLeft: '8px',
                                marginTop: '5px',
                                float: 'left',
                            }}
                            onClick={() => {
                                setWatchLoading(true);
                                setTaskWatch(
                                    form.getFieldValue('id'),
                                    setRefreshTable,
                                    setIsWatched,
                                    setWatchLoading,
                                    setRefreshTaskWatchTable
                                );
                            }}
                        />
                    </Tooltip>
                ) : (
                    <div
                        style={{
                            width: '8px',
                            display: 'inline-flex',
                            float: 'left',
                            marginLeft: '14px',
                            marginTop: '5px',
                        }}
                    >
                        {' '}
                        <NySpinner size="small" />{' '}
                    </div>
                ))}
        </>
    );

    const getFiles = (showHeader: boolean = false) => (
        <NyFilesUpload
            editUrl={CONSTANTS_REQ.TASK.FILES_EDIT}
            manageUploadUrl={CONSTANTS_REQ.TASK.FILES_EDIT}
            manageDeleteUrl={CONSTANTS_REQ.TASK.FILES_DEACTIVATE}
            id={selectedTaskId}
            refId={selectedTaskId}
            ref={uploadFilesRef}
            dataSourceFiles={files}
            setDataSourceFiles={setFiles}
            employee={employee}
            showDocumentTypeColumn={false}
            maxHeight={'205px'}
            showHeader={showHeader}
            isTaskList
            setRefreshDataSourceFiles={setRefreshFiles}
        />
    );

    return (
        <>
            {visible && (
                <>
                    <Modal
                        visible={visible}
                        title={
                            <div style={{ display: 'inline-flex' }}>
                                {title}
                                {selectedTaskId && getTaskStatusChangeHistory(selectedTaskId, taskStatusInit)}
                            </div>
                        }
                        width={hasMetadata || activeKey != '1' ? 1200 : 900}
                        maskClosable={false}
                        footer={getCustomFooterContent}
                        onOk={saveTask}
                        onCancel={closeModal}
                    >
                        <>
                            {isTemplateView ? (
                                <NewTaskFromTemplateIndex
                                    selectedTaskTemplateId={selectedTaskTemplateId}
                                    form={form}
                                    setValues={setValues}
                                    collapseActiveKeys={collapseActiveKeys}
                                    setCollapseActiveKeys={setCollapseActiveKeys}
                                    objectMetadataForm={objectMetadataForm}
                                    setHasMetadata={setHasMetadata}
                                    metadata={metadata}
                                    metadataTemplate={metadataTemplate}
                                    setMetadataTemplate={setMetadataTemplate}
                                    hasMetadata={hasMetadata}
                                    showFiles={true}
                                    getFiles={getFiles}
                                    selectedUser={selectedUser}
                                    selectedTaskId={selectedTaskId}
                                    taskStatusInit={taskStatusInit}
                                    taskStatus={taskStatus}
                                    setTaskStatus={setTaskStatus}
                                    setSelectedUser={setSelectedUser}
                                    actionsArray={getActionsArray()}
                                    parentTaskId={parentTaskId}
                                    isTemplateView={isTemplateView}
                                />
                            ) : (
                                <ExistingTaskIndex
                                    setValues={setValues}
                                    selectedTaskId={selectedTaskId}
                                    getFiles={getFiles}
                                    activeKey={activeKey}
                                    setActiveKey={setActiveKey}
                                    collapseActiveKeys={collapseActiveKeys}
                                    setCollapseActiveKeys={setCollapseActiveKeys}
                                    hasMetadata={hasMetadata}
                                    taskNoteId={taskNoteId}
                                    setVisibleNoteModal={setVisibleNoteModal}
                                    setTaskNoteId={setTaskNoteId}
                                    setTaskNoteData={setTaskNoteData}
                                    visibleNoteModal={visibleNoteModal}
                                    taskTimeButtonVisible={getTaskTimeButtonVisible()}
                                    parentTaskIdState={parentTaskIdState}
                                    setParentTaskIdState={setParentTaskIdState}
                                    taskNoteData={taskNoteData}
                                    visibleSubTask={visibleSubTask}
                                    setVisibleSubTask={setVisibleSubTask}
                                    selectedSubTaskId={selectedSubTaskId}
                                    setSelectedSubTaskId={setSelectedSubTaskId}
                                    setMetadata={setMetadata}
                                    refreshFiles={refreshFiles}
                                    setRefreshFiles={setRefreshFiles}
                                    setFiles={setFiles}
                                    form={form}
                                    objectMetadataForm={objectMetadataForm}
                                    setHasMetadata={setHasMetadata}
                                    metadata={metadata}
                                    metadataTemplate={metadataTemplate}
                                    setMetadataTemplate={setMetadataTemplate}
                                    showFiles={false}
                                    selectedUser={selectedUser}
                                    taskStatusInit={taskStatusInit}
                                    taskStatus={taskStatus}
                                    setTaskStatus={setTaskStatus}
                                    setSelectedUser={setSelectedUser}
                                    actionsArray={getActionsArray()}
                                    parentTaskId={parentTaskId}
                                    setRefreshWorktimeTable={setRefreshWorktimeTable}
                                    refreshWorktimeTable={refreshWorktimeTable}
                                    isTemplateView={isTemplateView}
                                    setRefreshTable={setRefreshTable}
                                    setIsWached={setIsWatched}
                                    createdBy={form.getFieldValue('createdBy')}
                                    setRefreshTaskWatchTable={setRefreshTaskWatchTable}
                                    refreshTaskWatchTable={refreshTaskWatchTable}
                                />
                            )}
                        </>
                    </Modal>
                    {historyModalVisible && (
                        <Modal
                            title={geti18nText('task.edit.history')}
                            visible={historyModalVisible}
                            onCancel={() => {
                                setHistoryModalVisible(false);
                            }}
                            okButtonProps={{ hidden: true }}
                            maskClosable={false}
                            width={1200}
                        >
                            <NyHistory id={selectedTaskId} link={'task.edit'} />
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default CreateTaskIndex;
