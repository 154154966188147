import { NyDataEdit, NyDatePicker, NyInputNumber, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, getSearchFormat, setDateFormat, setSearchFormat } from '../../../../utils/Utils';
import SalarySupplementsIndex from '../payroll-addition';
import SalarySupplementsEdit from '../payroll-addition/edit';
import PayrollAdditionSearch from '../payroll-addition/search';

const EmployeeSalarySupplementsEdit = (props: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [editHeader, setEditHeader] = useState(geti18nText('employeeSalarySupplements.edit.new'));
    const [loading, setLoading] = useState(false);
    const [validFrom, setValidFrom] = useState<any>(null);
    const [validTo, setValidTo] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    function setValues(dataForm: any) {
        if (dataForm?.payrollAddition?.name) {
            setEditHeader(
                geti18nText('employeeSalarySupplements.edit.header') +
                    ' : ID(' +
                    dataForm.id +
                    ') - ' +
                    dataForm.payrollAddition.name
            );
        }
        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
            setValidFrom(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
            setValidTo(dataForm.validTo);
        }
        if (dataForm.payrollAddition) {
            dataForm.payrollAddition = setSearchFormat(dataForm.payrollAddition, 'name', 'name');
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const fetchSalarySupplements = async (id: any) => {
        if (id) {
            const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.PAYROLL_ADDITION.EDIT + '/' + id);
            if (result.status === RESPONSE.OK && result.data) {
                const pa = result.data;

                if (pa.value === undefined) {
                    pa.value = 0;
                }
                form.setFieldsValue({ value: pa.value });
            }
        }
    };

    const onSalarySupplementsChange = (data: any) => {
        if (data && data.id > 0) {
            fetchSalarySupplements(data.id);
        } else {
            form.setFieldsValue({ value: 0 });
        }
    };

    const onModalClose = () => {
        form.resetFields();
        setValidFrom(null);
        setValidTo(null);
        setEditHeader(geti18nText('employeeSalarySupplements.edit.new'));
    };

    const onEndDateChange = (value: any) => {
        setValidTo(value ? moment(value) : moment());
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setValidFrom(value ? moment(value) : moment());
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_PAYROLL_ADDITION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                return {
                    id: values.id,
                    employee: { id: Number(id) },
                    payrollAddition: getSearchFormat(values.payrollAddition),
                    validFrom: values.validFrom ? getDateFormat(values.validFrom) : null,
                    validTo: values.validTo ? getDateFormat(values.validTo) : null,
                    value: values.value,
                    active: values.active,
                };
            }}
            shortcuts={true}
            nyTestId="employee-payroll-addition-edit"
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <PayrollAdditionSearch
                        AddNewModalComponent={SalarySupplementsEdit}
                        label={geti18nText('employeeSalarySupplements.edit.SalarySupplements')}
                        name="payrollAddition"
                        onChange={onSalarySupplementsChange}
                        required
                        SearchPopupComponent={<SalarySupplementsIndex />}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employeeSalarySupplements.edit.value')}
                        name="value"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            leftToRight={numbersWriting}
                            isDecimal={true}
                            style={{ width: '100%' }}
                            nyTestId="amount"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employeeSalarySupplements.edit.validFrom')}
                        name="validFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onStartDateChange}
                            disabledDate={disabledStartDate}
                            defaultPickerValue={validFrom}
                            nyTestId="addition-valid-from"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('employeeSalarySupplements.edit.validTo')} name="validTo">
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onEndDateChange}
                            disabledDate={disabledendDate}
                            defaultPickerValue={validTo}
                            nyTestId="addition-valid-to"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeSalarySupplementsEdit;
