import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Radio, RadioChangeEvent, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../../../../components/report-button';
import { AssetRights } from '../../../../../rights/assetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption, getDateFormat } from '../../../../../utils/Utils';
import CompanySearch from '../../../../administration/views/company/search';
import ItemSearch from '../../../../administration/views/item/search';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import EmployeeIndex from '../../../../human/views/employee';
import AssetTypeIndex from '../asset-type';
import AssetTypeEdit from '../asset-type/edit';
import useEnum from '../../../../../hooks/useEnum';
import ReportExportType from '../../../../../components/report-export-type/ReportExportType';

const AssetReportModal = ({
    reportType,
    reportTitle,
    reportFileName,
    url,
    showOnlyDates = false,
    searchFieldType,
    allFormItemsMultipleSelectionMode = true,
    allowExportTypeChange = true,
}: any) => {
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const enExportType = useEnum('EXPORT_TYPE');
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('month'));
    const [dateTo, setDateTo] = useState<any>(moment());
    const [assetTypeId, setAssetTypeId] = useState<any>(null);
    const [itemId, setItemId] = useState<any>(null);
    const [employeeId, setEmployeeId] = useState<any>(null);
    const [personId, setPersonId] = useState<any>(null);
    const [businessUnitId, setBusinessUnitId] = useState<any>(null);
    const [companyId, setCompanyId] = useState<any>(null);
    const [exportType, setExportType] = useState(enExportType.PDF);

    const mode = allFormItemsMultipleSelectionMode ? 'multiple' : 'default';

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values.dateFrom));
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    const setDefaultFilterValueInventoryItem = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 4 },
        ];
    };

    const getDateFrom = () => (
        <Form.Item
            label={geti18nText('assetRelease.report.dateFrom')}
            name="dateFrom"
            rules={[
                {
                    required: true,
                    message: geti18nText('app.default.required'),
                },
            ]}
            initialValue={moment().startOf('month')}
        >
            <NyDatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                onChange={(value: any) => {
                    setDateFrom(value);
                }}
                mustGetPopupContainer={false}
            />
        </Form.Item>
    );

    const getDateTo = () => (
        <Form.Item
            label={geti18nText('assetRelease.report.dateTo')}
            name="dateTo"
            rules={[
                {
                    required: true,
                    message: geti18nText('app.default.required'),
                },
            ]}
            initialValue={moment()}
        >
            <NyDatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                onChange={(value: any) => {
                    setDateTo(value);
                }}
                mustGetPopupContainer={false}
            />
        </Form.Item>
    );

    const getFormattedValue = (value: any) => {
        if (allFormItemsMultipleSelectionMode) {
            const valueIds =
                value &&
                value?.length > 0 &&
                value.map((element: any) => {
                    return element.id;
                });
            return valueIds.length > 0 ? valueIds : null;
        } else {
            return value && value.id > 0 ? value.id : null;
        }
    };

    const getFormItems = () => (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('assetRelease.report.assetType')} name="assetType">
                        <NySearchField
                            url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            itemName="name"
                            SearchPopupComponent={<AssetTypeIndex />}
                            onChange={(value: any) => {
                                setAssetTypeId(getFormattedValue(value));
                            }}
                            AddNewModalComponent={AssetRights.canCreateCodebooks() ? AssetTypeEdit : null}
                            mode={mode}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <ItemSearch
                        setDefaultFilterValue={setDefaultFilterValueInventoryItem}
                        onChange={(value: any) => {
                            setItemId(getFormattedValue(value));
                        }}
                        itemIndexType={4}
                        itemGroupType={1}
                        // addNewItem={true}
                        mode={mode}
                        mustGetPopupContainer={false}
                    />
                </Col>
            </Row>
            {searchFieldType == enAssetReleaseSpecType.COMPANY ? (
                <Row gutter={24}>
                    <Col span={12}>
                        <CompanySearch
                            formItemName={'company'}
                            onChange={(value: any) => {
                                setCompanyId(getFormattedValue(value));
                            }}
                            mode={mode}
                            isModal={true}
                            mustGetPopupContainer={false}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('assetRelease.report.employee')} name="employee">
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="asset-report-modal"/>}
                                onChange={(value: any) => {
                                    setEmployeeId(getFormattedValue(value));
                                }}
                                order="person.last_name, person.first_name"
                                mode={mode}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <Row gutter={24}>
                    <Col span={24}>
                        <BusinessUnitSearch
                            formItemName={'businessUnit'}
                            onChange={(value: any) => {
                                setBusinessUnitId(getFormattedValue(value));
                            }}
                            mode={mode}
                            mustGetPopupContainer={false}
                        />
                    </Col>
                </Row>
            )}
            {searchFieldType !== enAssetReleaseSpecType.COMPANY && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('assetRelease.report.receivedBy')} name="person">
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                onChange={(value: any) => {
                                    setPersonId(getFormattedValue(value));
                                }}
                                order="person.last_name, person.first_name"
                                mode={mode}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('assetRelease.report.employee')} name="employee">
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                onChange={(value: any) => {
                                    setEmployeeId(getFormattedValue(value));
                                }}
                                order="person.last_name, person.first_name"
                                mode={mode}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </>
    );

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText(reportTitle)}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText(reportTitle)}
                    maskClosable={false}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        setDateFrom(moment().startOf('month'));
                        setDateTo(moment());
                        setAssetTypeId(null);
                        setItemId(null);
                        setEmployeeId(null);
                        setPersonId(null);
                        setBusinessUnitId(null);
                        setCompanyId(null);
                        setExportType(enExportType.PDF);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    footer={[
                        <NyReportButton
                            url={url}
                            customParms={{
                                type: searchFieldType,
                                dateFrom: getDateFormat(dateFrom),
                                dateTo: getDateFormat(dateTo),
                                assetTypeId: assetTypeId,
                                itemId: itemId,
                                employeeId: employeeId,
                                personId: personId,
                                businessUnitId: businessUnitId,
                                companyId: companyId,
                                exportType: exportType ? exportType : enExportType.PDF,
                            }}
                            subreportType={reportType}
                            buttoni18nText={reportTitle}
                            fileName={reportFileName}
                            checkBeforeSave={checkBeforeSave}
                            selectedExportType={exportType}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {allowExportTypeChange && (
                            <ReportExportType exportType={exportType} setExportType={setExportType} />
                        )}
                        {!showOnlyDates ? (
                            <>
                                <Row gutter={24}>
                                    <Col span={12}>{getDateFrom()}</Col>
                                    <Col span={12}>{getDateTo()}</Col>
                                </Row>
                                {getFormItems()}
                            </>
                        ) : (
                            <>
                                <Row gutter={24}>
                                    <Col span={24}>{getDateFrom()}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>{getDateTo()}</Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AssetReportModal;
