import { geti18nText } from '@nybble/nyreact';
import { Col, Empty, Radio, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { TasksRights } from '../../../../rights/tasksRights';
import { RootState } from '../../../../rootReducer';
import { setPropOverviewTasks } from '../../../../slices/propSlice';
import TaskListIndexAll from './tables/TaskListIndexAll';
import TaskListIndexApproval from './tables/TaskListIndexApproval';
import TaskListIndexPersonal from './tables/TaskListIndexPersonal';
import TaskListIndexRequests from './tables/TaskListIndexRequests';
import TaskListIndexWatched from './tables/TaskListIndexWatched';

const TaskListIndex = () => {
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const dispatch = useDispatch();
    const taskTypeEnum = useEnum('TASK_TYPE');
    const [refresh, setRefresh] = useState<any>(0);
    const { propOverviewTasks } = useSelector((state: RootState) => state.prop);

    const getPropValue = () => {
        if (propOverviewTasks == 4 && TasksRights.canViewTaskApproval()) {
            return 4;
        } else {
            return propOverviewTasks ?? 1;
        }
    };
    const [activeList, setActiveList] = useState<any>(getPropValue());

    const location: any = useLocation();

    // useEffect(() => {
    //     const tab = tabs.filter((tab: any) => tab.path === active.toString()).at(0);
    //     if (tab?.state?.activeTab) {
    //         setActiveList(tab?.state?.activeTab);
    //     }
    //     // dispatch(setPropOverviewTasks(1));
    // }, [active, tabs]);

    useEffect(() => {
        setActiveList(propOverviewTasks ?? 1);
    }, [propOverviewTasks]);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        setRefresh((prevValue: any) => prevValue + 1);
    };

    return (
        <>
            <Row gutter={24}>
                <Radio.Group
                    value={activeList}
                    buttonStyle="solid"
                    optionType="button"
                    style={{ margin: '5px 5px 20px 10px' }}
                >
                    <Radio.Button value={1} onChange={onChangeActiveList}>
                        {geti18nText('tasks.list.radioButton.1')}
                    </Radio.Button>
                    <Radio.Button value={2} onChange={onChangeActiveList}>
                        {geti18nText('tasks.list.radioButton.2')}
                    </Radio.Button>
                    <Radio.Button value={3} onChange={onChangeActiveList}>
                        {geti18nText('tasks.list.radioButton.3')}
                    </Radio.Button>
                    {TasksRights.canViewTaskApproval() && (
                        <Radio.Button value={4} onChange={onChangeActiveList}>
                            {geti18nText('tasks.list.radioButton.4')}
                        </Radio.Button>
                    )}
                    <Radio.Button value={5} onChange={onChangeActiveList}>
                        {geti18nText('tasks.list.radioButton.5')}
                    </Radio.Button>
                </Radio.Group>
            </Row>
            <Row gutter={24}>
                {activeList == 1 ? (
                    <Col>
                        <TaskListIndexPersonal
                            setRefresh={setRefresh}
                            refresh={refresh}
                            listType={'personal'}
                            type={taskTypeEnum.SUBTASK}
                        />
                    </Col>
                ) : activeList == 2 ? (
                    <Col>
                        <TaskListIndexAll
                            setRefresh={setRefresh}
                            refresh={refresh}
                            listType={'all'}
                            type={taskTypeEnum.SUBTASK}
                        />
                    </Col>
                ) : activeList == 3 ? (
                    <Col>
                        <TaskListIndexRequests
                            setRefresh={setRefresh}
                            refresh={refresh}
                            listType={'requests'}
                            type={taskTypeEnum.SERVICE}
                        />
                    </Col>
                ) : activeList == 4 && TasksRights.canViewTaskApproval() ? (
                    <Col>
                        <TaskListIndexApproval
                            setRefresh={setRefresh}
                            refresh={refresh}
                            columnsType={'all'}
                            type={taskTypeEnum.SUBTASK}
                        />
                    </Col>
                ) : activeList == 5 ? (
                    <Col>
                        <TaskListIndexWatched
                            setRefresh={setRefresh}
                            refresh={refresh}
                            listType={'personal'}
                            type={taskTypeEnum.SUBTASK}
                        />
                    </Col>
                ) : (
                    <Empty />
                )}
            </Row>
        </>
    );
};

export default TaskListIndex;
