import useEnum from '../../../../../hooks/useEnum';
import TaskListIndexGeneral from './TaskListIndexGeneral';

const TaskListIndexRequests = ({ setRefresh, refresh, listType, type }: any) => {
    const taskStatusEnum = useEnum('TASK_STATUS');
    const setDefaultFilterValue = [
        {
            field: 'active',
            condition: 'equals_bool',
            value: 1,
        },
        {
            field: 'status',
            condition: 'in',
            value: [taskStatusEnum.NEW, taskStatusEnum.ASSIGNED, taskStatusEnum.IN_PROGRESS].toString(),
        },
    ];
    return (
        <TaskListIndexGeneral
            setRefresh={setRefresh}
            refresh={refresh}
            listType={listType}
            type={type}
            setDefaultFilterValue={setDefaultFilterValue}
        />
    );
};

export default TaskListIndexRequests;
