import { FullscreenOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySpinner, RESPONSE, UseRequestSwrGet } from '@nybble/nyreact';
import { Button, Card, Carousel, Image, Modal, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ, KIOSK } from '../../utils/Constants';
import { EnMediaType } from '../../utils/Enums';

const KioskWidget = ({ data }: any) => {
    const [mediaList, setMediaList] = useState([]);
    const [mediaListFullscreen, setMediaListFullscreen] = useState([]);
    const [accessKey, setAccessKey] = useState<string>('');
    const [dsmApi, setDsmApi] = useState<string>('');
    const [autoplaySpeed, setAutoplaySpeed] = useState<number>(10000);

    useEffect(() => {
        getKioskSettings();
    }, []);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 6 },
            { field: 'settingGroup', condition: 'equals', value: 'DSM_SETTINGS' },
        ];
    };

    const getKioskSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DSM_API') {
                                setDsmApi(element.value ? element.value : element.defaultValue);
                            } else if (element.settingKey === 'DSM_API_ACCESS_KEY') {
                                setAccessKey(element.value ? element.value : element.defaultValue);
                            } else if (element.settingKey === 'DSM_AUTOPLAY_SPEED') {
                                setAutoplaySpeed(
                                    element.value ? element.value : element.defaultValue ? element.defaultValue : 10000
                                );
                            }
                        });
                    }
                }
            }
        );
    };

    const customFetcher = async () => {
        if (dsmApi != '' && accessKey != '' && data?.device?.id) {
            const res: any = await NyRequestResolver.requestGet(
                KIOSK(dsmApi != '' && accessKey != '' ? dsmApi : '').DEVICE_OR_CAMPAIGN_DATA,
                {
                    accessKey: accessKey,
                    deviceId: data.device.id,
                    returnJson: true,
                    returnBlob: false,
                }
            );
            if (res.status !== RESPONSE.CREATED && res.status !== RESPONSE.OK) throw new Error(res.data?.error);
            return res.data ? res.data : undefined;
        }
    };

    const {
        data: kioskData,
        isLoading: loading,
        isError: error,
    } = UseRequestSwrGet(KIOSK(dsmApi != '' && accessKey != '' ? dsmApi : '').DEVICE_OR_CAMPAIGN_DATA, {
        accessKey: accessKey,
        customFetcher: customFetcher,
        deviceId: data != undefined && data.device != undefined && data.device.id != undefined && data.device.id,
    });

    useEffect(() => {
        if (kioskData && kioskData.length > 0) {
            setMediaList(generateMedia('normal'));
            setMediaListFullscreen(generateMedia('fullscreen'));
        }
    }, [kioskData]);

    const generateMedia = (type: any) => {
        const list: any = [];
        const listFullscreen: any = [];

        if (kioskData) {
            kioskData.map((item: any) => {
                item?.media?.map((media: any) => {
                    switch (media.type) {
                        case EnMediaType.PICTURE:
                            list.push(
                                <Image
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                    width="100%"
                                    height="325px"
                                    src={KIOSK(dsmApi).MEDIA_LIST + '?accessKey=' + accessKey + '&uuid=' + media.uuid}
                                />
                            );
                            listFullscreen.push(
                                <Image
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                    width="100%"
                                    height="auto"
                                    src={KIOSK(dsmApi).MEDIA_LIST + '?accessKey=' + accessKey + '&uuid=' + media.uuid}
                                />
                            );
                            break;
                        case EnMediaType.VIDEO:
                            list.push(
                                <video controls style={{ width: '100%', height: '325px', objectFit: 'contain' }}>
                                    <source
                                        src={
                                            KIOSK(dsmApi).MEDIA_LIST + '?accessKey=' + accessKey + '&uuid=' + media.uuid
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            );
                            listFullscreen.push(
                                <video controls style={{ width: '100%', height: 'auto', objectFit: 'contain' }}>
                                    <source
                                        src={
                                            KIOSK(dsmApi).MEDIA_LIST + '?accessKey=' + accessKey + '&uuid=' + media.uuid
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            );
                            break;
                        default:
                            break;
                    }
                });
            });
        }

        if (type == 'normal') {
            return list;
        } else {
            return listFullscreen;
        }
    };

    const KioskPlayer = ({ type }: any) => {
        return loading ? (
            <div style={{ margin: '30px' }}>
                <NySpinner />
            </div>
        ) : mediaList && mediaListFullscreen && type == 'normal' ? (
            <Carousel draggable accessibility dots autoplay autoplaySpeed={autoplaySpeed}>
                {mediaList.map((item: any) => (
                    <div style={{ margin: 0 }}>{item}</div>
                ))}
            </Carousel>
        ) : (
            <Carousel draggable accessibility dots autoplay autoplaySpeed={autoplaySpeed}>
                {mediaListFullscreen.map((item: any) => (
                    <div style={{ margin: 0 }}>{item}</div>
                ))}
            </Carousel>
        );
    };

    return (
        <Card
            title={data && data.device && data.device.name ? data.device.name : ''}
            className="ny-widget-card"
            bodyStyle={{ maxHeight: '330px', overflowY: 'auto', overflowX: 'hidden', padding: '0px 0px 0px 0px' }}
            type="inner"
        >
            <div>
                <KioskPlayer type="normal" />
            </div>
        </Card>
    );
};

export default KioskWidget;
