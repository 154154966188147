import { LinkOutlined } from '@ant-design/icons';
import { geti18nText, UseRequestSwrGet } from '@nybble/nyreact';
import { Card, Empty, Image, List, Tooltip, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { AdministrationRights } from '../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getValidUrl } from '../../utils/Utils';
const { Title } = Typography;

const LinksWidget = () => {
    const history = useHistory();
    const search: any = [{ field: 'active', condition: 'equals_bool', value: 1 }];
    const {
        data: links,
        isLoading: loading,
        isError,
    } = UseRequestSwrGet(CONSTANTS_REQ.LINKS.LIST_ALL, {
        search: encodeURI(JSON.stringify(search)),
    });

    const hasLinkEditRole = () => {
        return AdministrationRights.canCreateLinks();
    };

    const linkGridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px',
    };

    return (
        <Card
            title={geti18nText('menu.links')}
            className="ny-widget-card"
            bodyStyle={{ padding: '0px' }}
            type="inner"
            extra={
                hasLinkEditRole() && (
                    <Tooltip placement="top" title={geti18nText('menu.links')}>
                        <LinkOutlined
                            className="ny-card-icon"
                            onClick={() => {
                                history.push('/administration/codebooks/links');
                            }}
                        />
                    </Tooltip>
                )
            }
        >
            <List
                style={{
                    maxHeight: '325px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '4px 5px 0px 5px',
                    marginTop: '1px',
                }}
                grid={{
                    gutter: 12,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                }}
                loading={loading}
                dataSource={links}
                renderItem={(link: any) => {
                    let url = link.url ? getValidUrl(link.url) : '';
                    return (
                        <List.Item className="ny-notification-widget-list-item">
                            <Card.Grid
                                className="ny-card-link"
                                style={linkGridStyle}
                                onClick={() => window.open(url, '_blank')}
                            >
                                <Image
                                    className="ny-card-link-img"
                                    src={`${url}/favicon.ico`}
                                    preview={false}
                                    fallback={`https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`}
                                />
                                <span>{link.name ? link.name : ''}</span>
                            </Card.Grid>
                        </List.Item>
                    );
                }}
                className="ny-widget-list"
                locale={{
                    emptyText: (
                        <div style={{ paddingTop: 10 }}>
                            <Empty
                                className="ny-dashboard-empty"
                                imageStyle={{ width: '100%' }}
                                description={<Title level={5}>{geti18nText('notification.noLink')}</Title>}
                            />
                        </div>
                    ),
                }}
            />
        </Card>
    );
};

export default LinksWidget;
