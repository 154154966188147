import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AssetSimpleIndex from '../../../../../../components/asset-simple/assetSimple';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import {
    customAssetDepreciationGroupRenderOption,
    customAssetExtraRenderOption,
    getAssetDepreciationGroupSelectOption,
    getDateFormat,
    getSearchFormat,
    setDateFormat,
} from '../../../../../../utils/Utils';
import { validateNegativeNumber } from '../../../../../../utils/Validation';
import CompanySearch from '../../../../../administration/views/company/search';
import AssetEdit from '../../../../../webshop/views/webshop-asset/asset/edit';
import DepreciationGroupIndex from '../../asset-depreciation-group';

const AssetInitialStateItemsEdit = (props: any) => {
    const [form] = Form.useForm();
    const [editHeader, setEditHeader] = useState(geti18nText('asset.acquisition.item.table.header'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [assetInitialStateId, setAssetInitialStateId] = useState(null);

    useEffect(() => {
        setAssetInitialStateId(props?.editProps?.assetInitialStateId ?? null);
    }, [props]);

    const canCreate = () => {
        return props?.editProps?.canCreate;
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.asset) {
            if (dataForm?.asset?.assetModel?.name) {
                setEditHeader(dataForm.asset.assetModel.name);
            }
            dataForm.asset.label = dataForm?.asset?.assetModel?.name;
        }
        if (dataForm.acquisitionDate) {
            dataForm.acquisitionDate = setDateFormat(dataForm.acquisitionDate);
        }
        if (dataForm.assetDepreciationGroup) {
            dataForm.assetDepreciationGroup = getAssetDepreciationGroupSelectOption(dataForm.assetDepreciationGroup);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('asset.acquisition.item.table.header'));
        setAssetInitialStateId(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const changeAmount = () => {
        const quantity = form.getFieldValue(['quantity']) ? form.getFieldValue(['quantity']) : 0;
        const asset = form.getFieldValue(['asset']) ? form.getFieldValue(['asset']) : null;
        const price = asset && asset.acquisitionPrice ? asset.acquisitionPrice : 0;
        const acquisitionCostValue = quantity * price;
        form.setFieldsValue({ acquisitionCostValue: acquisitionCostValue });
        changeNetBookValue();
    };

    const changeNetBookValue = () => {
        const acquisitionCostValue = form.getFieldValue(['acquisitionCostValue'])
            ? form.getFieldValue(['acquisitionCostValue'])
            : 0;
        const valueIncrease = form.getFieldValue(['valueIncrease']) ? form.getFieldValue(['valueIncrease']) : 0;
        const valueDecrease = form.getFieldValue(['valueDecrease']) ? form.getFieldValue(['valueDecrease']) : 0;
        const acumulatedDepreciation = form.getFieldValue(['acumulatedDepreciation'])
            ? form.getFieldValue(['acumulatedDepreciation'])
            : 0;
        const netBookValue = acquisitionCostValue + valueIncrease - valueDecrease - acumulatedDepreciation;
        form.setFieldsValue({ netBookValue: netBookValue });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ASSET_INITIAL_STATE_ITEM.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            isModal={true}
            hideSubmitButton={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.acquisitionDate) values.acquisitionDate = getDateFormat(values.acquisitionDate);
                if (values.assetDepreciationGroup)
                    values.assetDepreciationGroup = getSearchFormat(values.assetDepreciationGroup);

                values.assetInitialState = { id: assetInitialStateId };
                values.asset = getSearchFormat(values.asset);
                return values;
            }}
            hideActivationButtons={true}
            onSaveAndGetID={() => props.setRefreshTable && props.setRefreshTable((prevValue: any) => prevValue + 1)}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="asset" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.asset')}
                                name="asset"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                {' '}
                                <NySearchField
                                    style={{ width: '100%' }}
                                    url={CONSTANTS_REQ.ASSET.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'label',
                                        text: 'text',
                                        name: 'name',
                                        serialNumber: 'serialNumber',
                                        inventoryNumber: 'inventoryNumber',
                                        assetModel: 'assetModel',
                                        assetType: 'assetType',
                                        item: 'item',
                                        measureUnit: 'measureUnit',
                                        quantity: 'quantity',
                                    }}
                                    searchBy="assetModel.name || serialNumber || inventoryNumber"
                                    renderOption={customAssetExtraRenderOption}
                                    SearchPopupComponent={<AssetSimpleIndex isFixedAsset={true} />}
                                    AddNewModalComponent={AssetEdit}
                                    AddNewModalComponentAddedData={{ isModal: true }}
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    autoFocus
                                    order="assetModel.name"
                                    customListWidth={'1200px'}
                                    mustGetPopupContainer={false}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.depreciationGroup')}
                                name={'assetDepreciationGroup'}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'name',
                                        depreciationRate: 'depreciationRate',
                                        code: 'code',
                                    }}
                                    searchBy="_adg.name,_adg.code"
                                    itemName="name"
                                    customItemNameLabel={'(depreciationRate) code name '}
                                    renderOption={customAssetDepreciationGroupRenderOption}
                                    SearchPopupComponent={<DepreciationGroupIndex />}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <CompanySearch
                                disabled={!canCreate()}
                                label={geti18nText('asset.acquisition.edit.company')}
                                formName={'acquisitionCompany'}
                                isModal={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.acquisitionDate')}
                                name="acquisitionDate"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NyDatePicker format="DD.MM.YYYY" style={{ width: '100%' }} disabled={!canCreate()} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.quantity')}
                                name="quantity"
                                initialValue={1}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    onChange={changeAmount}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.price')}
                                name={['asset', 'acquisitionPrice']}
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changeAmount}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.acquisition.item.table.column.amount')}
                                name="acquisitionCostValue"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changeNetBookValue}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.initialState.item.table.column.valueIncrease')}
                                name="valueIncrease"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changeNetBookValue}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.initialState.item.table.column.valueDecrease')}
                                name="valueDecrease"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changeNetBookValue}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.initialState.item.table.column.acumulatedDepreciation')}
                                name="acumulatedDepreciation"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    isDecimal
                                    onChange={changeNetBookValue}
                                    disabled={!canCreate()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.initialState.item.table.column.netBookValue')}
                                name="netBookValue"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: validateNegativeNumber,
                                    },
                                ]}
                            >
                                <NyInputNumber style={{ width: '100%' }} isDecimal disabled={!canCreate()} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AssetInitialStateItemsEdit;
