import { OrderedListOutlined, PlusOutlined, QuestionCircleFilled, UnorderedListOutlined } from '@ant-design/icons';
import { NySession, geti18nText } from '@nybble/nyreact';
import { Button, Card, Col, Popover, Radio, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import IconsModal from '../../../../components/icons-modal/IconsModal';
import NyFullCalendar from '../../../../components/ny-full-calendar/NyFullCalendar';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import { RootState } from '../../../../rootReducer';
import { setProp, setPropOverviewTravelWarrant } from '../../../../slices/propSlice';
// import { addTab, setActiveTab } from '../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import { userIsNotificationRecipient } from '../../../../utils/Utils';
import TravelWarrantLightWidget from '../../../dashboard/light-widgets/TravelWarrantLightWidget';
import TravelWarrantPersonalLightWidget from '../../../dashboard/light-widgets/TravelWarrantPersonalLightWidget';
import TravelWarrantPersonalWizardNew from '../travel-warrant/wizard/wizard-new';
import ActionsView from './ActionsView';
import AdminView from './AdminView';
import UserView from './UserView';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelWarrantOverview = ({ hideRadioButtons = false }: any) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { employee } = useSelector((state: RootState) => state.employee);
    const { propOverviewTravelWarrant } = useSelector((state: RootState) => state.prop);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantStatus = useEnum('TRAVEL_WARRANT_STATUS');
    const [wizard, setWizard] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>(0);
    const [adminRefresh, setAdminRefresh] = useState<any>(0);
    const [activeList, setActiveList] = useState<any>(propOverviewTravelWarrant);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);

    document.querySelectorAll('button.fc-today-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridWeek-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridMonth-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-listMonth-button')?.forEach((element) => element.setAttribute('title', ''));
    document
        .querySelectorAll('button.fc-prev-button')
        ?.forEach((element) => element.setAttribute('title', geti18nText('app.default.filter.range.prevWeek')));
    document
        .querySelectorAll('button.fc-next-button')
        ?.forEach((element) => element.setAttribute('title', geti18nText('app.default.filter.range.nextWeek')));

    useHelper('travel_order/travel_warrant');

    useEffect(() => {
        getModuleAccessRights();
        dispatch(setPropOverviewTravelWarrant(1));
    }, []);

    useEffect(() => {
        if (propOverviewTravelWarrant > 1 && canCreate()) {
            setActiveList(propOverviewTravelWarrant);
        } else {
            setActiveList(1);
        }
    }, [propOverviewTravelWarrant]);

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const canCreateAllWarrant = () => {
        return canCreate() || (moduleAccessRight && moduleAccessRight.includes('TRAVEL_WARRANT'));
    };

    const canCreateActions = () => {
        return (
            canCreate() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const setDefaultFilterValueMyListPersonal = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: employee && employee.id },
        ];
    };

    const setDefaultFilterValueMyList = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'not_equals', value: employee && employee.id },
        ];
    };

    const setDefaultFilterValueGeneral = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'not_equals', value: travelWarrantStatus.DRAFT },
        ];
    };

    const initialSelectedOptions = () => {
        return [
            travelWarrantStatus.WAITING_FOR_APPROVAL,
            travelWarrantStatus.APPROVED,
            travelWarrantStatus.REJECTED,
            travelWarrantStatus.WAITING_FOR_ORGANIZATION,
            travelWarrantStatus.ORGANIZED,
            travelWarrantStatus.WAITING_FOR_REPORT_APPROVAL,
            travelWarrantStatus.REPORT_APPROVED,
            travelWarrantStatus.REPORT_FIX_NEEDED,
            travelWarrantStatus.CALCULATED,
            travelWarrantStatus.NOT_CALCULATED,
            travelWarrantStatus.PAID,
        ];
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const resolveSearch = () => {
        if (activeList == 2 && canCreateAllWarrant()) {
            return setDefaultFilterValueMyList();
        } else if (activeList == 3 && canCreateActions()) {
            return setDefaultFilterValueGeneral();
        } else {
            return setDefaultFilterValueMyListPersonal();
        }
    };

    // const addNewTab = (i18n: string, path: string) => {
    //     if (path && path !== active) {
    //         const panes = tabs.filter((item: any) => item.path === path);
    //         if (panes.length === 0) {
    //             dispatch(addTab({ title: i18n, path: path, color: widgetColor }));
    //         } else {
    //             dispatch(setActiveTab(path));
    //         }
    //     }
    // };

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
    };

    const resolveDestination = (card: any, end: boolean = false) => {
        let waypointsList: any = [];
        if (card?.destinationJson) {
            const destJSON = JSON.parse(card.destinationJson);
            destJSON.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                }
            });
        }
        waypointsList = waypointsList
            .filter((value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id))
            .filter((x: any) => x.id < 9000);

        if (end) {
            const lastItem = waypointsList[waypointsList.length - 1];
            return lastItem?.shortName ?? '';
        } else {
            const firstItem = waypointsList[0];
            return firstItem?.shortName ?? '';
        }
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <Row gutter={24}>
                        {hideRadioButtons ? (
                            <></>
                        ) : (
                            <Radio.Group
                                value={activeList}
                                buttonStyle="solid"
                                optionType="button"
                                style={{ margin: '5px 5px 20px 10px' }}
                            >
                                <Radio.Button value={1} onChange={onChangeActiveList}>
                                    {geti18nText('travelWarrantPersonal.table.header')}
                                </Radio.Button>
                                {canCreateAllWarrant() && (
                                    <Radio.Button value={2} onChange={onChangeActiveList}>
                                        {geti18nText('travel.warrant.general')}
                                    </Radio.Button>
                                )}
                                {canCreateActions() && (
                                    <Radio.Button value={3} onChange={onChangeActiveList}>
                                        {geti18nText('travelWarrant.overview.actions')}
                                    </Radio.Button>
                                )}
                            </Radio.Group>
                        )}
                    </Row>
                    <Row gutter={24}>
                        {activeList == 1 ? (
                            <Col className={'travel-warrant-cards-widgets'}>
                                <TravelWarrantPersonalLightWidget setWizard={setWizard} />
                            </Col>
                        ) : activeList == 2 && canCreateAllWarrant() ? (
                            <Col className={'travel-warrant-cards-widgets'}>
                                {/* TODO TravelWarrantLightWidget za Sva službena putovanja */}
                                {/* <TravelWarrantLightWidget /> */}
                            </Col>
                        ) : activeList == 3 && canCreateActions() ? (
                            <Col className={'travel-warrant-cards-widgets'}>
                                <TravelWarrantLightWidget />
                            </Col>
                        ) : (
                            <div></div>
                        )}
                    </Row>
                </Col>
                <Col span={16}>
                    <Row justify="end" gutter={24} style={{ marginRight: '2rem', width: '100%' }}>
                        <Card
                            style={{ width: '100%' }}
                            className={`ny-widget-card`}
                            bodyStyle={{
                                maxHeight: '250px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                padding: '0px 5px 0px 5px',
                                marginTop: '5px',
                                marginBottom: '5px',
                            }}
                            type="inner"
                        >
                            <NyFullCalendar
                                keys={['travelWarrant']}
                                initialSelectedOptions={JSON.stringify([
                                    { keyType: 'travelWarrant', selectedOptions: initialSelectedOptions() },
                                ])}
                                isAdmin={canCreateAllWarrant()}
                                search={resolveSearch()}
                                isPersonal={activeList == 1 ? true : false}
                                activeList={activeList}
                                employeeUrl={CONSTANTS_REQ.EMPLOYEE.TRAVEL_SEARCH}
                            ></NyFullCalendar>
                        </Card>
                    </Row>
                </Col>
            </Row>
            {activeList == 1 ? (
                <UserView
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setDefaultFilterValueMyList={setDefaultFilterValueMyListPersonal}
                    resolveDestination={resolveDestination}
                />
            ) : activeList == 2 && canCreateAllWarrant() ? (
                <AdminView
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setDefaultFilterValueMyList={setDefaultFilterValueMyList}
                    resolveDestination={resolveDestination}
                />
            ) : activeList == 3 && canCreateActions() ? (
                <ActionsView
                    setDefaultFilterValue={setDefaultFilterValueGeneral}
                    resolveDestination={resolveDestination}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            ) : (
                <div></div>
            )}
            {wizard && (
                <TravelWarrantPersonalWizardNew
                    visible={wizard}
                    setVisible={setWizard}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    adminRefresh={adminRefresh}
                    setAdminRefresh={setAdminRefresh}
                />
            )}

            <div className="overview-buttons-sticky">
                <Popover
                    title={geti18nText('travelWarrantPlan.overview.legend')}
                    placement="topLeft"
                    content={<IconsModal enumType="TRAVEL_WARRANT_STATUS" textType="app.enum.TRAVEL_WARRANT_STATUS." />}
                    style={{ marginLeft: '4rem' }}
                >
                    <Button size="large" style={{ margin: '0.6rem' }}>
                        <QuestionCircleFilled className="travel-warrant-overview-legend" />
                    </Button>
                </Popover>

                <div style={{ width: '100%', justifyContent: 'end', display: 'flex' }}>
                    {(activeList == 1 || activeList == 2) && (
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                setWizard(true);
                            }}
                        >
                            <PlusOutlined />
                            {geti18nText('travelWarrantPersonal.table.add')}
                        </Button>
                    )}
                    {activeList == 1 ? (
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                dispatch(setProp(1));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <UnorderedListOutlined />
                            {geti18nText('travelWarrantPersonal.table.footer')}
                        </Button>
                    ) : activeList == 2 && canCreateAllWarrant() ? (
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                dispatch(setProp(12));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <OrderedListOutlined />
                            {geti18nText('travel.warrant.general.footer')}
                        </Button>
                    ) : activeList == 3 && canCreateActions() ? (
                        <Button
                            type="primary"
                            style={{ margin: '0.6rem' }}
                            onClick={() => {
                                dispatch(setProp(2));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <OrderedListOutlined />
                            {geti18nText('travelWarrant.overview.actions')}
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TravelWarrantOverview;
