import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { ReactText, useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getEnumArrayStatus, getEnumBooleanArray } from '../../../../../utils/Utils';
import AssetInventoryItemEdit from './edit';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';

const AssetInventoryItemIndex = ({
    inventoryAssetId,
    isBooked,
    refresh,
    setRefresh,
    setHasSelected,
    hasSelected,
    setSelectedValues,
    scroll = { y: 620, x: 800 },
    sortOrder,
}: any) => {
    const [item, setItem] = useState<any>(undefined);
    const table = useTableSettings();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    let clearKeys: any;

    const canCreate = () => {
        return AssetRights.canCreateInventory();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'inventory_asset_id', condition: 'equals', value: inventoryAssetId },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('assetInventoryItem.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('assetInventoryItem.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },

        {
            title: geti18nText('assetInventoryItem.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('assetInventoryItem.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.firstName;
                }
            },
        },
        {
            title: geti18nText('assetInventoryItem.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('assetInventoryItem.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('fixed.asset.table.column.location'),
            dataIndex: ['room', 'name'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('assetInventoryItem.table.column.nfcTag'),
            dataIndex: 'nfcTag',
            width: '7%',
            render: (text: any, record: { nfcTag: any }) => {
                if (record.nfcTag) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('assetInventoryItem.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            width: '9%',
            ...getColumnSearchOption(getEnumArrayStatus('ASSET_AVAILABILITY_STATUS')),
            render: (text: any, record: any) => {
                if (record.availabilityStatus != undefined) {
                    return geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
        },
        {
            title: geti18nText('assetInventoryItem.table.column.inventoryAssetItemStatus'),
            dataIndex: 'inventoryAssetItemStatus',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('INVENTORY_ASSET_ITEM_STATUS')),
            render: (text: any, record: any) => {
                if (record.inventoryAssetItemStatus != undefined) {
                    return geti18nText('app.enum.INVENTORY_ASSET_ITEM_STATUS.' + record.inventoryAssetItemStatus);
                }
            },
        },
    ];

    useEffect(() => {
        if (hasSelected && clearKeys) {
            clearKeys();
        }
    }, [refresh]);

    const onRowSelect = (item: any) => {
        if (item && !isBooked) {
            setItem(item);
            setIsModalVisible(true);
        }
    };

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    return (
        <>
            <NyDataTable
                nyId="webshop-asset-inventory-items"
                url={CONSTANTS_REQ.INVENTORY_ASSET_ITEM.LIST}
                hideNewButton={true}
                showRecordModal={true}
                buttonsClassName={''}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                shortcuts={true}
                onRowSelect={onRowSelect}
                setDefaultPageSize={table.setDefaultPageSize()}
                fetchWhenChange={refresh}
                readonly={isBooked}
                showRowSelection={!isBooked && canCreate()}
                rowSelectionType={'checkbox'}
                rowSelectionModal={setSelectedValuesFunc as any}
                exportCSV={canCreate()}
                CSVFileName={geti18nText('menu.asset')}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
            />
            <AssetInventoryItemEdit
                item={item}
                setItem={setItem}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setRefresh={setRefresh}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            availabilityStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + value);
            },
        },
        {
            inventoryAssetItemStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ASSET_ITEM_STATUS.' + value);
            },
        },
        {
            nfcTag: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetInventoryItem.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
        },
        {
            title: geti18nText('assetInventoryItem.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
        },

        {
            title: geti18nText('assetInventoryItem.table.column.serialNumber'),
            dataIndex: 'serialNumber',
        },
        {
            title: geti18nText('assetInventoryItem.table.column.firstName'),
            dataIndex: ['employee', 'person', 'firstName'],
        },
        {
            title: geti18nText('assetInventoryItem.table.column.lastName'),
            dataIndex: ['employee', 'person', 'lastName'],
        },
        {
            title: geti18nText('assetInventoryItem.table.column.businessUnit'),
            dataIndex: ['businessUnit', 'name'],
        },
        {
            title: geti18nText('fixed.asset.table.column.location'),
            dataIndex: ['room', 'name'],
        },
        {
            title: geti18nText('assetInventoryItem.table.column.nfcTag'),
            dataIndex: 'nfcTag',
        },
        {
            title: geti18nText('assetInventoryItem.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
        },
        {
            title: geti18nText('assetInventoryItem.table.column.inventoryAssetItemStatus'),
            dataIndex: 'inventoryAssetItemStatus',
        },
    ];
};

export default AssetInventoryItemIndex;
