import { NySpinner, UseRequestSwrGet, geti18nText } from '@nybble/nyreact';
import { Badge, Card, Empty, Switch } from 'antd';
import { Axis, Chart, Interval, Legend, Tooltip, getTheme } from 'bizcharts';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { setGraphColors } from '../../../../../slices/graphColorsSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const ChartBusinessUnitEmployees = ({ width = '587px', height = 370, myPortal = false }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const [showEmployees, setShowEmployees] = useState<boolean>(false);
    const colorsState = useSelector((state: RootState) => state.graphColors.colors);
    const isDarkTheme = theme === 'dark';
    const dispatch = useDispatch();

    const canViewCharts = () => {
        return HumanResourcesRights.canViewCharts();
    };

    const {
        data: dataHRTotal,
        isError: error,
        isLoading: loading,
    } = canViewCharts()
        ? UseRequestSwrGet(CONSTANTS_REQ.HUMAN_RESOURCES_DATA.TOTAL)
        : { data: [], isError: false, isLoading: false };

    const ColumnChartBusinessUnitEmployees = ({ dataHRTotal }: any) => {
        const getRandomColor = () => {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        };

        const generateRandomColors = (length: any) => {
            const colors = [];
            for (let i = 0; i < length; i++) {
                colors.push(getRandomColor());
            }
            return colors;
        };

        const colors = colorsState.length > 0 ? colorsState : generateRandomColors(dataHRTotal?.length);

        dispatch(setGraphColors(colorsState.length > 0 ? colorsState : colors));

        const data = dataHRTotal?.map((obj: any, index: number) => {
            const value = obj.counted;
            const totalCount = dataHRTotal.reduce((sum: any, item: any) => sum + item.counted, 0);
            const percentage = (value / totalCount) * 100;
            return {
                item: obj.name,
                item2: obj.name,
                radnici: value,
                label: value,
                color: colorsState[index],
                percent: percentage.toFixed(2),
                employeeList: obj.employeeList,
            };
        });

        const axisConfig = {
            label: {
                autoHide: true,
                style: {
                    textAlign: 'center' as const,
                    fill: isDarkTheme ? '#ccc' : 'black',
                },
                formatter: (text: string) => truncateLabel(text, 13),
            },
            line: {
                style: {
                    stroke: '#ccc',
                    lineDash: [3, 3],
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: '#ccc',
                        lineDash: [3, 3],
                    },
                },
            },
            tickLine: {
                style: {
                    lineWidth: 3,
                },
            },
        };

        const truncateLabel = (text: string, maxLength: number) => {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        };

        const axisTitleConfig = {
            title: {
                text: geti18nText('businessUnit.graph.age.hover'),
                style: {
                    fontSize: 12,
                    textAlign: 'center' as const,
                    fill: isDarkTheme ? '#ccc' : 'black',
                },
                position: 'center',
            },
        };

        return error ? (
            <Empty />
        ) : loading ? (
            <NySpinner />
        ) : (
            <Chart height={height} padding={[10, 10, 60, 60]} autoFit data={data}>
                <Interval
                    position="item*radnici"
                    style={{ lineWidth: 0, stroke: getTheme().colors10[0] }}
                    color={['item2', data?.map((item: any) => item.color)]}
                />
                <Axis name="item" {...axisConfig} />
                <Axis name="radnici" {...axisTitleConfig} />

                <Tooltip offset={showEmployees ? 0 : undefined} enterable={showEmployees} style={{ padding: '16px' }}>
                    {(title: any, items: any) => {
                        const employeeInfo = items[0].data.employeeList.map((employee: any) => {
                            return (
                                <div style={{ marginTop: '2px' }}>
                                    {employee?.person?.firstName} {employee?.person?.lastName}{' '}
                                    {employee?.employmentRecordId ? ' (' + employee.employmentRecordId + ')' : ''}
                                </div>
                            );
                        });
                        const color = items[0].color;
                        return (
                            <div style={{ padding: '8px' }}>
                                <div style={{ right: '-8px', top: '-2px', position: 'relative', fontWeight: '500' }}>
                                    {title}
                                </div>
                                <div style={{ fontWeight: '500' }}>
                                    <Badge
                                        dot={true}
                                        style={{
                                            marginTop: '-8px',
                                            right: '14px',
                                            background: color,
                                        }}
                                    />
                                    {`${geti18nText('businessUnit.graph.age.hover')}: ${items.at(0)?.data?.label} - ${
                                        items.at(0)?.data.percent
                                    }% `}
                                </div>
                                {showEmployees && (
                                    <div
                                        style={{
                                            right: '-8px',
                                            position: 'relative',
                                            maxHeight: '150px',
                                            overflowY: 'auto',
                                            overflowX: 'hidden',
                                            padding: '2px',
                                        }}
                                    >
                                        {employeeInfo}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </Tooltip>
                <Legend
                    itemName={{
                        style: {
                            fill: isDarkTheme ? 'white' : 'black',
                        },
                    }}
                    itemStates={{
                        active: {
                            nameStyle: {
                                opacity: 0.8,
                            },
                        },
                        unchecked: {
                            nameStyle: {
                                fill: 'gray',
                            },
                            markerStyle: {
                                fill: 'gray',
                            },
                        },
                    }}
                />
            </Chart>
        );
    };

    return (
        <div
            style={
                myPortal
                    ? {
                          width: width,
                          paddingLeft: '12px',
                          paddingRight: '12px',
                          marginTop: '8px',
                          height: '100%',
                      }
                    : {}
            }
        >
            <Card
                className="ny-graph-card"
                title={
                    <>
                        <div style={{ float: 'left' }}>{geti18nText('businessUnit.graph.department.employees')}</div>
                        <div style={{ float: 'right' }}>
                            {geti18nText('businessUnit.graph.showModalEmployees')}
                            <Switch
                                checked={showEmployees}
                                onChange={(checked: any) => setShowEmployees(checked)}
                                style={{ marginLeft: '10px' }}
                                checkedChildren={geti18nText('app.default.button.yes')}
                                unCheckedChildren={geti18nText('app.default.button.no')}
                            />
                        </div>
                    </>
                }
                bodyStyle={{
                    height: 'calc(100% - 35px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '4px 5px 0px 5px',
                    marginTop: '26px',
                }}
                type="inner"
            >
                {dataHRTotal?.length > 0 && (
                    <ColumnChartBusinessUnitEmployees style={{ marginTop: '28px' }} dataHRTotal={dataHRTotal} />
                )}
            </Card>
        </div>
    );
};

export default ChartBusinessUnitEmployees;
