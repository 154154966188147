import { geti18nText, NySearchField, NyDatePicker, NyInputNumber, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, Row, Form, Input, Collapse, Checkbox } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { formatCurrencyIsoCodeSearch, getEnumArray, setEnumFormat } from '../../../../../utils/Utils';
import { validateBIC } from '../../../../../utils/Validation';
import CurrencySearch from '../../../../administration/views/currency/search';

const { Panel } = Collapse;

const GeneralEditData = ({ form, isCreate }: any) => {
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2']);
    const [companyId, setCompanyId] = useState<any>(undefined);
    const [currencyCode, setCurrencyCode] = useState<any>(undefined);

    const focusInput = useRef<any>(null);
    useEffect(function () {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    useEffect(() => {
        getAppSettings();
    }, []);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 1 },
            { field: 'settingGroup', condition: 'equals', value: 'COMPANY_INFO' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_CURRENCY_ISO_CODE') {
                                getCurrencyIsoCode(element.value ? element.value : element.defaultValue);
                            } else if (element.settingKey === 'COMPANY_ID') {
                                setCompanyId(element.value ? element.value : element.defaultValue);
                            }
                        });
                    }
                }
            }
        );
    };

    async function getCurrencyIsoCode(code: any) {
        const currencyCode = await formatCurrencyIsoCodeSearch(code);
        setCurrencyCode(currencyCode);
        if (form && isCreate) {
            form.setFieldsValue({ debtorAccountCurrencyCode: currencyCode });
        }
    }

    return (
        <React.Fragment>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('payment.orders.group.groupData')} key="1" forceRender={true}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.paymentInformationId')}
                                        name="paymentInformationId"
                                    >
                                        <Input maxLength={36} ref={focusInput} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.batch')}
                                        name="batchBooking"
                                        valuePropName="checked"
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.paymentMethod')}
                                        name="paymentMethod"
                                        initialValue={setEnumFormat('SEPA_PAYMENT_METHOD_TYPE_CODE', 1)}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            options={getEnumArray('SEPA_PAYMENT_METHOD_TYPE_CODE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="payment-method"
                                            style={{ width: '100%' }}
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.priority')}
                                        name="priority"
                                        initialValue={setEnumFormat('SEPA_PRIORITY_TYPE_CODE', 0)}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            options={getEnumArray('SEPA_PRIORITY_TYPE_CODE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="priority"
                                            style={{ width: '100%' }}
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.requestedExecutionDate')}
                                        name="requestedExecutionDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker nyTestId="requested-execution-date" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.purposeCode')}
                                        name="purposeCode"
                                    >
                                        <NySearchField
                                            options={getEnumArray('SEPA_EXTERNAL_PURPOSE_TYPE_CODE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="purpose"
                                            style={{ width: '100%' }}
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('payment.orders.group.debtorData')} key="2" forceRender={true}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorName')}
                                        name="debtorName"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input maxLength={70} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorCountryCode')}
                                        name="debtorCountryCode"
                                    >
                                        <Input maxLength={2} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorAddressLine')}
                                        name="debtorAddressLine1"
                                    >
                                        <Input maxLength={70} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorAddressLine2')}
                                        name="debtorAddressLine2"
                                    >
                                        <Input maxLength={70} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorAccountIban')}
                                        name="debtorAccountIban"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.SEARCH}
                                            setDefaultFilterValue={() => [
                                                { field: 'companyId', condition: 'equals', value: companyId },
                                            ]}
                                            map={{ id: 'id', label: 'iban' }}
                                            searchBy="bankAccount.iban"
                                            nyTestId="iban"
                                            className={''}
                                            order="bankAccount.iban"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <CurrencySearch
                                        label={geti18nText('payment.orders.group.edit.debtorAccountCurrencyCode')}
                                        name="debtorAccountCurrencyCode"
                                        nyTestId="currency-iso-code"
                                        noClassName={true}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorAgentBic')}
                                        name="debtorAgentBic"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                            {
                                                validator: validateBIC,
                                            },
                                        ]}
                                    >
                                        <Input maxLength={8} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('payment.orders.group.otherData')} key="3" forceRender={true}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.debtorReference')}
                                        name="debtorReference"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.creditorReference')}
                                        name="creditorReference"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label={geti18nText('payment.orders.group.edit.created')} name="created">
                                        <NyDatePicker style={{ width: '100%' }} disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.numberOfTransactions')}
                                        name="numberOfTransactions"
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('payment.orders.group.edit.controlSum')}
                                        name="controlSum"
                                    >
                                        <NyInputNumber isDecimal disabled style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default GeneralEditData;
