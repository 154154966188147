import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Radio } from 'antd';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useHelper from '../../../../../hooks/useHelper';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import EmployeeEvaluationAll from './EmployeeEvaluationAll';
import EmployeeEvaluationGraph from './EmployeeEvaluationGraph';
import EmployeeEvaluationPeriodsView from './EmployeeEvaluationPeriodsView';
import EmployeeEvaluationPersonal from './EmployeeEvaluationPersonal';

const EmployeeEvaluationIndex = ({ myPortal, hideRadioButtons = false }: any) => {
    const dispatch = useDispatch();
    const { employee } = useSelector((state: RootState) => state.employee);
    const [refresh, setRefresh] = useState<any>(0);
    const [activeList, setActiveList] = useState<any>(hideRadioButtons ? 2 : 1);
    const [count, setCount] = useState<any>(0);
    const { id } = useParams<any>();
    const history = useHistory();
    const [printPdf, setPrintPdf] = useState(false);

    // useEffect(() => {
    //     if (id) {
    //         history.push('/human/employee-evaluation');
    //         dispatch(
    //             setModalOpen({
    //                 modal: 'employeeEvaluationEdit',
    //                 visible: true,
    //                 item: id,
    //             })
    //         );
    //     }
    // }, [id]);

    useHelper('human/employee_evaluation');

    const isUser = () => {
        return HumanResourcesRights.isEvaluationUser();
    };

    const isAdmin = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        setRefresh((refresh: any) => refresh + 1);
    };

    const setDefaultFilterValuePersonal = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: employee?.id },
        ];
    };

    const setDefaultFilterValue = () => {
        if (!isAdmin() && isUser()) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'myEvaluations', condition: 'equals', value: 1 },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    useEffect(() => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                setCount(result?.data?.content?.length);
                if (result?.data?.empty) {
                    setActiveList(2);
                }
            } else {
                setCount(0);
                setActiveList(2);
            }
        });
    }, []);

    return (
        <>
            {hideRadioButtons ? (
                <></>
            ) : (
                <Radio.Group
                    value={activeList}
                    buttonStyle="solid"
                    optionType="button"
                    style={{ margin: '5px 5px 10px 10px' }}
                >
                    {count > 0 && (
                        <Radio.Button value={1} onChange={onChangeActiveList}>
                            {geti18nText('menu.travelCostsOverview')}
                        </Radio.Button>
                    )}
                    {(isUser() || isAdmin()) && (
                        <Radio.Button value={2} onChange={onChangeActiveList}>
                            {geti18nText('employee.evaluation.personal')}
                        </Radio.Button>
                    )}
                    {(isAdmin() || (isUser() && count > 0)) && (
                        <Radio.Button value={3} onChange={onChangeActiveList}>
                            {geti18nText('employee.evaluation.all')}
                        </Radio.Button>
                    )}
                </Radio.Group>
            )}
            <>
                {activeList == 1 ? (
                    <EmployeeEvaluationGraph printPdf={printPdf} setPrintPdf={setPrintPdf} isMyPortal={myPortal} />
                ) : activeList == 2 ? (
                    <EmployeeEvaluationPersonal
                        refresh={refresh}
                        setRefresh={setRefresh}
                        activeList={activeList}
                        search={setDefaultFilterValuePersonal()}
                    />
                ) : (
                    <>
                        {isAdmin() ? (
                            <EmployeeEvaluationPeriodsView
                                refresh={refresh}
                                setRefresh={setRefresh}
                                activeList={activeList}
                                search={setDefaultFilterValue()}
                            />
                        ) : (
                            <EmployeeEvaluationAll
                                refresh={refresh}
                                setRefresh={setRefresh}
                                activeList={activeList}
                                search={setDefaultFilterValue()}
                                isAdmin={isAdmin()}
                            />
                        )}
                    </>
                )}
            </>
        </>
    );
};

export default EmployeeEvaluationIndex;
