import { useState, useEffect } from 'react';
import { Col, Row, Tooltip, Calendar, Tag, Select, Radio } from 'antd';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { FieldTimeOutlined, LeftOutlined, RightOutlined, WarningTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../utils/Constants';
import 'moment/locale/hr';

const NyCalendar = ({ data = [], type = 'weekly', startDate }: any) => {
    const mode = 'month';
    let shiftIndex = 0;
    const [currDate, setCurrDate] = useState<any>(moment());
    const [listData, setListData] = useState([]);
    const [holidays, setHolidays] = useState([]);

    useEffect(() => {
        setListData(data.filter((val: any) => val.workdays));
    }, [data]);

    useEffect(() => {
        fatchHolidays();
    }, []);

    const fatchHolidays = () => {
        let active = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        NyRequestResolver.requestGet(CONSTANTS_REQ.HOLIDAY.LIST_ALL, {
            search: active,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setHolidays(
                        result.data.map((day: any) => {
                            return {
                                date: moment(day.date, 'yyyy-MM-DD'),
                                name: day.name,
                                active: day.active,
                            };
                        })
                    );
                }
            }
        });
    };

    const checkIsWeekend = (value: any) => {
        if (value.day() === 0 || value.day() === 6) {
            return true;
        }
        return false;
    };

    const checkIsHoliday = (value: any) => {
        let val = moment(value.format('yyyy-MM-DD'), 'yyyy-MM-DD');
        const holiday: any = holidays.find((day: any) => day.date.isSame(val));
        if (holiday && holiday.active) {
            return holiday;
        }
        return null;
    };

    const checkIsCurrentMonth = (value: any) => {
        if (currDate.format('MM') === value.format('MM')) {
            return true;
        }
        return false;
    };

    const checkIsToday = (value: any) => {
        let today = moment(moment().format('yyyy-MM-DD'), 'yyyy-MM-DD');
        let val = moment(value.format('yyyy-MM-DD'), 'yyyy-MM-DD');
        if (val.isSame(today)) {
            return true;
        }
        return false;
    };

    const checkOverlap = (date: any, day: any, shift: any) => {
        let overlaps = false;
        if (type === 'weekly') {
            if (day === 7) {
                let nextShiftIndex = shiftIndex + 1;
                if (nextShiftIndex > listData.length - 1) {
                    nextShiftIndex = 0;
                }
                let nextShift: any = listData[nextShiftIndex];
                let tmpDate = date.clone();
                let holiday = checkIsHoliday(tmpDate.add(1, 'day'));
                let nextIsHoliday = holiday ? true : false;
                if (nextShift.holidays) {
                    overlaps = shift.overlaps && nextShift.overlaps ? true : false;
                } else {
                    overlaps = shift.overlaps && nextShift.overlaps && !nextIsHoliday ? true : false;
                }
            } else if (day === 1) {
                let prevShiftIndex = shiftIndex - 1;
                if (prevShiftIndex < 0) {
                    prevShiftIndex = listData.length - 1;
                }
                let prevShift: any = listData[prevShiftIndex];
                let tmpDate = date.clone();
                let holiday = checkIsHoliday(tmpDate.subtract(1, 'day'));
                let prevIsHoliday = holiday ? true : false;
                if (prevShift.holidays) {
                    overlaps = shift.overlaps && prevShift.overlaps ? true : false;
                } else {
                    overlaps = shift.overlaps && prevShift.overlaps && !prevIsHoliday ? true : false;
                }
            }
        } else if (type === 'daily') {
            let prevShiftIndex = shiftIndex - 1;
            if (prevShiftIndex < 0) {
                prevShiftIndex = listData.length - 1;
            }
            let prevShift: any = listData[prevShiftIndex];
            let tmpDate = date.clone();
            let holiday = checkIsHoliday(tmpDate.subtract(1, 'day'));
            let prevIsHoliday = holiday ? true : false;
            let prevtDay = day - 1 < 1 ? 7 : day - 1;
            if (prevShift && prevShift.workdays.includes(prevtDay)) {
                if (prevShift.holidays) {
                    overlaps = shift.overlaps && prevShift.overlaps ? true : false;
                } else {
                    overlaps = shift.overlaps && prevShift.overlaps && !prevIsHoliday ? true : false;
                }
            }

            if (!overlaps) {
                let nextShiftIndex = shiftIndex + 1;
                if (nextShiftIndex > listData.length - 1) {
                    nextShiftIndex = 0;
                }
                let nextShift: any = listData[nextShiftIndex];
                let tmpDate = date.clone();
                let holiday = checkIsHoliday(tmpDate.add(1, 'day'));
                let nextIsHoliday = holiday ? true : false;
                let nextDay = day + 1 > 7 ? 1 : day + 1;
                if (nextShift && nextShift.workdays.includes(nextDay)) {
                    if (nextShift.holidays) {
                        overlaps = shift.overlaps && nextShift.overlaps ? true : false;
                    } else {
                        overlaps = shift.overlaps && nextShift.overlaps && !nextIsHoliday ? true : false;
                    }
                }
            }
        }

        return overlaps;
    };

    const dateFullCellRender = (value: any) => {
        let dayText = value.clone().format('DD');
        let isToday = checkIsToday(value.clone());
        let isCurrentMonth = checkIsCurrentMonth(value.clone());
        let isWeekend = checkIsWeekend(value.clone());
        let holiday = checkIsHoliday(value.clone());
        let isHoliday = holiday ? true : false;

        let shiftName = '';
        let shiftWorkHours = '';
        let color = '';
        let overlaps = false;

        startDate = startDate ? moment(startDate.format('yyyy-MM-DD'), 'yyyy-MM-DD') : undefined;
        if (startDate && value.isSameOrAfter(startDate)) {
            let tmpStartDate = startDate.clone();
            let numberOfShit = listData.length;

            if (type === 'weekly') {
                let numberOfWeek = value.clone().diff(tmpStartDate.startOf('week'), 'week');
                shiftIndex = numberOfWeek % numberOfShit;
            } else if (type === 'daily') {
                let numberOfDay = value.clone().diff(tmpStartDate, 'day');
                shiftIndex = numberOfDay % numberOfShit;
            }

            let day = value.day() === 0 ? 7 : value.day();
            let shift: any = listData[shiftIndex];

            if (shift && shift.workdays.includes(day) && (shift.holidays || shift.holidays === isHoliday)) {
                shiftName = shift.name;
                shiftWorkHours =
                    (shift.hours_from ? shift.hours_from : '') + ' - ' + (shift.hours_to ? shift.hours_to : '');
                color = shift.color;
                overlaps = checkOverlap(value, day, shift);
            }
        }

        return (
            <div
                className={`ant-picker-cell ant-picker-calendar-date ${!isCurrentMonth ? 'ant-picker-cell-opacity' : ''
                    } ${isToday
                        ? 'ny-today-border'
                        : isHoliday
                            ? 'ant-picker-cell-holiday ny-holiday-border'
                            : isWeekend
                                ? 'ant-picker-cell-weekend ny-weekend-border'
                                : ''
                    }`}
            >
                <div className="ant-picker-calendar-date-value">
                    {isHoliday ? <span className="ant-picker-cell-holiday-name">{holiday.name}</span> : ''}
                    <b style={{ paddingRight: '5px' }}>{dayText}</b>
                </div>
                <div className="ant-picker-calendar-date-content">
                    <div className="events">
                        <div className="events" style={{ padding: '10px' }}>
                            {shiftName !== '' && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Tag className="ny-calendar-tag" color={color}>
                                                <b>{shiftName}</b>
                                                {overlaps && (
                                                    <Tooltip
                                                        placement="top"
                                                        title={geti18nText('app.default.overlap.warning')}
                                                    >
                                                        <WarningTwoTone
                                                            twoToneColor="#fa1414"
                                                            style={{ paddingLeft: '5px', marginRight: '0px' }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <br />{' '}
                                                {shiftWorkHours && shiftWorkHours !== '' && shiftWorkHours !== ' - ' && (
                                                    <>
                                                        <FieldTimeOutlined />
                                                        <span>{shiftWorkHours}</span>
                                                    </>
                                                )}
                                            </Tag>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Calendar
            value={currDate}
            onChange={(val) => setCurrDate(val)}
            dateFullCellRender={dateFullCellRender}
            mode={mode}
            fullscreen={false}
            headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];

                const current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                }

                for (let index = start; index < end; index++) {
                    monthOptions.push(
                        <Select.Option className="month-item" value={`${index}`} key={`${index}`}>
                            {months[index]}
                        </Select.Option>
                    );
                }
                const month = value.month();

                const year = value.year();
                const options = [];
                for (let i = year - 5; i < year + 5; i += 1) {
                    options.push(
                        <Select.Option key={`${i}`} value={`${i}`} className="year-item">
                            {i}
                        </Select.Option>
                    );
                }

                return (
                    <div style={{ padding: 8 }}>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Select
                                    dropdownMatchSelectWidth={false}
                                    onChange={(newYear: any) => {
                                        const now = value.clone().year(newYear);
                                        onChange(now);
                                    }}
                                    value={String(year)}
                                >
                                    {options}
                                </Select>
                                <Select
                                    style={{ marginLeft: '5px' }}
                                    dropdownMatchSelectWidth={false}
                                    value={String(month)}
                                    onChange={(selectedMonth) => {
                                        const newValue = value.clone();
                                        newValue.month(parseInt(selectedMonth, 10));
                                        onChange(newValue);
                                    }}
                                >
                                    {monthOptions}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Radio.Group style={{ float: 'right' }}>
                                    <Radio.Button
                                        value="subtractMonth"
                                        className="ny-calendar-radio-button"
                                        onClick={() => {
                                            const newValue = value.clone();
                                            newValue.subtract(1, 'month');
                                            onChange(newValue);
                                        }}
                                    >
                                        <LeftOutlined />
                                    </Radio.Button>
                                    <Radio.Button
                                        value="addMonth"
                                        className="ny-calendar-radio-button"
                                        onClick={() => {
                                            const newValue = value.clone();
                                            newValue.add(1, 'month');
                                            onChange(newValue);
                                        }}
                                    >
                                        <RightOutlined />
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>
                );
            }}
        />
    );
};

export default NyCalendar;
