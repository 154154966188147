import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyDataEdit, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NyNoteModal from '../../../../components/note-modal';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { updateHistoryI18n } from '../../../../slices/historySlice';
// import { removeTab, updateTabTitle } from '../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, getSearchFormat, setEnumFormat, setSearchFormat } from '../../../../utils/Utils';
import { oibCheck } from '../../../../utils/Validation';

import PostOfficeSearch from '../post-office/search';
import SettlementIndex from '../settlement';
import SettlementEdit from '../settlement/edit';
import PostOfficeIndex from '../post-office';
import PostOfficeEdit from '../post-office/edit';
import SettlementSearch from '../settlement/search';

const { Panel } = Collapse;

const GeneralEditTab = ({
    props,
    companyId,
    setEditHeader,
    setDataForm,
    setLoading,
    loading,
    setIsCreate,
    setIsFormChange,
}: any) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [companyNote, setCompanyNote] = useState<any>(false);
    const [addressNote, setAddressNote] = useState<any>(false);
    const [marketType, setMarketType] = useState<any>(1);
    const [title, setTitle] = useState('');
    const focusInput = useRef<any>(null);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    const canCreate = () => {
        return AdministrationRights.canCreateCompany();
    };

    /// FORM DATA
    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);

        if (dataForm.name) setTitle(dataForm.name);

        setCompanyNote(dataForm.note ? dataForm.note : undefined);

        setAddressNote(dataForm.address && dataForm.address.note ? dataForm.address.note : undefined);

        if (dataForm.partnerType) {
            dataForm.partnerType = setEnumFormat('COMPANY_PARTNER_TYPE', dataForm.partnerType);
        }

        if (dataForm.marketType) {
            setMarketType(dataForm.marketType);
            dataForm.marketType = setEnumFormat('MARKET_TYPE', dataForm.marketType);
        }

        if (dataForm.address) {
            if (dataForm.address.settlement) dataForm.address.settlement = setSearchFormat(dataForm.address.settlement);
            if (dataForm.address.postOffice) {
                dataForm.address.postOffice = {
                    id: dataForm.address.postOffice.id,
                    text:
                        (dataForm.address.postOffice.zipCode ? dataForm.address.postOffice.zipCode : '') +
                        ' ' +
                        dataForm.address.postOffice.name,
                };
            }
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('company.edit.new'));
    };
    /// END FORM DATA

    /// COLLAPSE
    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );
    /// END COLLAPSE

    /// VALIDATION
    const validateOib = (rule: any, value: any, callback: any) => {
        if ((value = undefined && value !== '')) {
            if (oibCheck(value)) {
                var parms: any = { oib: value };
                if (companyId !== null && companyId !== undefined && companyId !== 'create') {
                    parms.companyId = companyId;
                }
                NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EXISTS_BY_OIB, parms).then((result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            callback(geti18nText('company.edit.oib.exists'));
                        } else {
                            callback();
                        }
                    } else {
                        callback();
                    }
                });
            } else {
                callback(geti18nText('company.edit.oib.not.correct'));
            }
        } else {
            callback();
        }
    };

    const validateVatNumber = async (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            var parms: any = { vatNumber: value };
            if (companyId !== null && companyId !== undefined && companyId !== 'create') {
                parms.companyId = companyId;
            }
            NyRequestResolver.requestGet(CONSTANTS_REQ.COMPANY.EXISTS_BY_VAT_NUMBER, parms).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        callback(geti18nText('company.edit.vatNumber.exists'));
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            });
        }
    };

    const validateLocalVatNumber = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('company.edit.vat.not.correct'));
            }
        } else {
            callback();
        }
    };
    /// END VALIDATION

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.COMPANY.EDIT}
            setValues={setValues}
            width={1200}
            setIsCreate={setIsCreate}
            fetchWhenChange={companyId}
            form={form}
            goBack={() => {
                history.goBack();
                // dispatch(removeTab(location.pathname));
            }}
            onSaveAndGetID={() => {
                history.goBack();
                // dispatch(removeTab(location.pathname));
            }}
            paramsId={companyId}
            buttonsClassName="buttons-sticky"
            changeCollapseVisibility={changeCollapseVisibility}
            customFooterContent={getCustomFooterContent}
            checkIsFormChanged={true}
            setIsFormChange={setIsFormChange}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
            normalize={(values: any) => {
                if (values.partnerType) values.partnerType = getEnumFormat(values.partnerType);
                if (values.marketType) values.marketType = getEnumFormat(values.marketType);

                if (values.address.settlement) values.address.settlement = getSearchFormat(values.address.settlement);
                if (values.address.postOffice) values.address.postOffice = getSearchFormat(values.address.postOffice);

                return values;
            }}
            shortcuts={true}
        >
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('company.edit.basicData')}
                            key="1"
                            extra={
                                <NyNoteModal
                                    note={companyNote}
                                    modalTitle={
                                        geti18nText('company.edit.basicData') + ' - ' + geti18nText('app.default.note')
                                    }
                                />
                            }
                        >
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('company.edit.marketType')}
                                        name="marketType"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={setEnumFormat('MARKET_TYPE', 1)}
                                    >
                                        <NySearchField
                                            options={getEnumArray('MARKET_TYPE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            onChange={(data: any) => {
                                                setMarketType(data.id);
                                            }}
                                            autoFocus={true}
                                            nyTestId="market-type"
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('company.edit.partnerType')}
                                        name="partnerType"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            options={getEnumArray('COMPANY_PARTNER_TYPE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="company-partner-type"
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={16}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('company.edit.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('company.edit.name2')} name="name2">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                {marketType === 1 ? (
                                    <Col span={8}>
                                        <Form.Item
                                            label={geti18nText('company.edit.oib')}
                                            name="oib"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                                {
                                                    validator: validateOib,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                ) : (
                                    <Col span={8}>
                                        <Form.Item
                                            label={geti18nText('company.edit.vatNumber')}
                                            name="vatNumber"
                                            rules={[
                                                {
                                                    required: marketType === 2 ? true : false,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: marketType === 2 ? true : false,
                                                },
                                                {
                                                    validator: validateVatNumber,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                )}

                                <Col span={8}>
                                    <Form.Item label={geti18nText('company.edit.mb')} name="mb">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                {marketType === 1 && (
                                    <Col span={8}>
                                        <Form.Item
                                            label={geti18nText('company.edit.vatNumber')}
                                            name="vatNumber"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: geti18nText('app.default.required'),
                                                },
                                                {
                                                    validator: validateLocalVatNumber,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('company.edit.addressData')}
                            key="2"
                            extra={
                                <NyNoteModal
                                    note={addressNote}
                                    modalTitle={
                                        geti18nText('company.edit.addressData') +
                                        ' - ' +
                                        geti18nText('app.default.note')
                                    }
                                    idFieldNamePath={['address', 'note', 'id']}
                                    noteFieldNamePath={['address', 'note', 'note']}
                                    noteFieldPriorityPath={['address', 'note', 'priority']}
                                />
                            }
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('company.edit.street')}
                                        name={['address', 'street']}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <SettlementSearch
                                        label={geti18nText('company.edit.settlement')}
                                        name={['address', 'settlement']}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <PostOfficeSearch required={true} />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default GeneralEditTab;
