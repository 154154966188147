import useHelper from '../../../../../hooks/useHelper';
import { DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import ItemGroupIndex from '../../../../administration/views/item-group';

const ItemGroupAsset = () => {
    useHelper('asset/codebooks/item-group-asset');

    return <ItemGroupIndex type={1} scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />;
};

export default ItemGroupAsset;
