import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import SettlementIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import SettlementEdit from './edit';

const SettlementSearch = ({
    label = geti18nText('company.edit.settlement'),
    name = 'settlement',
    required = false,
    onChange = null,
    disabled = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.SETTLEMENT.SEARCH}
                map={{ id: 'id', label: 'text' }}
                searchBy="name"
                SearchPopupComponent={<SettlementIndex />}
                AddNewModalComponent={SettlementEdit}
                nyTestId="settlement"
                onChange={onChange}
                disabled={disabled}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default SettlementSearch;
