import useHelper from '../../../../../hooks/useHelper';
import { DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import ItemGroupIndex from '../../../../administration/views/item-group';

const ItemGroupService = () => {
    useHelper('services/codebooks/item-group-service');

    return <ItemGroupIndex type={3} scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />;
};

export default ItemGroupService;
