import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import WarehouseIndex from '../../../warehouse/views/warehouse';

const OrderViewWarehouseEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('warehouse.edit.new'));
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(true);
    const focusInput = useRef<any>(null);

    const [warehouseForm] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('warehouse.edit.title'));
        if (dataForm.id) setEditable(false);
        if (dataForm.warehouse) dataForm.warehouse = setSearchFormat(dataForm.warehouse, 'name', 'name');
        delete dataForm.active;
        warehouseForm.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setEditable(true);
        warehouseForm.resetFields();
        setEditHeader(geti18nText('warehouse.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.ORDER_VIEW_WAREHOUSE.EDIT}
            setValues={setValues}
            width={400}
            form={warehouseForm}
            paramsId={id}
            {...props}
            shortcuts={true}
            nyTestId="order-view-warehouse-edit"
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.warehouse) {
                    values.warehouse = getSearchFormat(values.warehouse);
                    if (values.warehouse.id < 1) {
                        delete values.warehouse;
                    }
                }
                values.orderView = { id: props.addedData.orderViewId };
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={warehouseForm}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={geti18nText('warehouse.edit.title')} name="warehouse">
                            <NySearchField
                                url={CONSTANTS_REQ.WAREHOUSE.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                addNewItem={true}
                                disabled={!editable}
                                setDefaultFilterValue={setDefaultFilterValue}
                                SearchPopupComponent={<WarehouseIndex />}
                                customListWidth={'1200px'}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default OrderViewWarehouseEdit;
