import useHelper from '../../../../../hooks/useHelper';
import { DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import ItemIndex from '../../../../administration/views/item';

const ItemService = () => {
    useHelper('service/codebooks/services');

    return <ItemIndex type={2} itemGroupType={3} scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />;
};

export default ItemService;
