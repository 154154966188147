import { AppstoreOutlined } from '@ant-design/icons';
import { NyDataEdit, NyModalConfirm, NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Badge, Button, Col, Divider, Form, Input, Modal, Row, Tabs, Tooltip, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ContractTypeIndex from '.';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getCanCreateContract,
    getErrorNotificationMessage,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setSearchFormat,
} from '../../../../../utils/Utils';
import NyImageUpload from '../../../../../utils/ny-file-upload-image';
import ContractGlobalConstantsIndex from '../contract-global-constants';
import ContractJobsIndex from '../contract-jobs';
import ContractMetadataIndex from '../contract-metadata';
import ContractNotificationsIndex from '../contract-notifications';
import ContractTypeGroupSearch from '../contract-type-group/search';
import ContractsCTRIndex from '../contractsCTR';

const { TabPane } = Tabs;
const { Title } = Typography;

const ContractTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(
        props?.editProps?.annex
            ? geti18nText('contract.type.edit.new.annex.type')
            : geti18nText('contract.type.edit.new')
    );
    const [canCreate, setCanCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [globalConstantsModalVisible, setGlobalConstantsModalVisible] = useState<boolean>(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [activeKey, setActiveKey] = useState('general');
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [files, setFiles] = useState<any>([]);
    const [contractCount, setContractCount] = useState(0);
    const [differentDocumentTemplateContractCount, setDifferentDocumentTemplateContractCount] = useState(0);
    const [differentMetadataVersionContractCount, setDifferentMetadataVersionContractCount] = useState(0);
    const [contractDocumentTemplate, setContractDocumentTemplate] = useState<any>(null);
    const [newTemplate, setNewTemplate] = useState<boolean>(true);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>(0);
    const [contractCategory, setContractCategory] = useState<any>(null);
    // metadata
    const [contractMetadata, setContractMetadata] = useState<any>(undefined);
    const [selectedMetadataKeys, setSelectedMetadataKeys] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(true);
    const [triggerGetKeys, setTriggerGetKeys] = useState<any>(0);

    useEffect(() => {
        getCanCreate();
    }, []);

    const getCanCreate = async () => {
        getCanCreateContract(setCanCreate, props?.editProps?.mark, 'HUMAN_RESOURCES');
    };

    const onChangeTab = (key: string) => {
        setActiveKey(key);
    };

    const iconStyle = {
        cursor: 'pointer',
        fontSize: '22px',
        height: '100%',
        display: 'inline-block',
        marginTop: '20px',
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            let label = props?.editProps?.annex
                ? geti18nText('contract.type.edit.title.annex.type')
                : geti18nText('contract.type.edit.title');
            setEditHeader(label + ' - ' + dataForm.name);
        }
        if (dataForm.contractCategory) {
            dataForm.contractCategory = setSearchFormat(dataForm.contractCategory, 'contractCategory');
        }

        if (dataForm.contractTypeMetadataList) {
            setContractMetadata(dataForm.contractTypeMetadataList);
        } else {
            setContractMetadata([]);
        }

        if (dataForm.contractTypeGroup) {
            dataForm.contractTypeGroup = setSearchFormat(dataForm.contractTypeGroup);
        }
        if (dataForm.contractCount) {
            setContractCount(dataForm.contractCount);
        }
        if (dataForm.differentDocumentTemplateContractCount) {
            setDifferentDocumentTemplateContractCount(dataForm.differentDocumentTemplateContractCount);
        }
        if (dataForm.differentMetadataVersionContractCount) {
            setDifferentMetadataVersionContractCount(dataForm.differentMetadataVersionContractCount);
        }
        setContractDocumentTemplate(dataForm?.documentTemplate ?? null);
        setNewTemplate(dataForm?.documentTemplate?.id ? false : true);
        if (dataForm?.documentTemplate?.fileId) {
            let filesTemp = [
                {
                    id: dataForm.documentTemplate.fileId,
                    uid: dataForm.documentTemplate.fileId,
                    name: dataForm.documentTemplate.name,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.documentTemplate.fileId +
                        '?lastmod=' +
                        new Date().valueOf(),
                },
            ];
            setFiles(filesTemp);
        } else {
            setFiles([]);
        }
        setContractCategory(dataForm?.setContractCategory);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setEditHeader(
            props?.editProps?.annex
                ? geti18nText('contract.type.edit.title.annex.type')
                : geti18nText('contract.type.edit.new')
        );
        setContractCount(0);
        setDifferentDocumentTemplateContractCount(0);
        setDifferentMetadataVersionContractCount(0);
        setContractDocumentTemplate(null);
        setFiles([]);
        setNewTemplate(true);
        setContractCategory(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const generateAllWithNewTemplate = () => {
        setRefresh((prevValue: any) => prevValue + 1);
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.CONTRACT_TYPE.GENERATE_ALL_WITH_NEW_TEMPLATE, undefined, {
            contractTypeId: dataForm,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                okNotification();
            } else {
                getErrorNotificationMessage(result);
            }
            setLoading(false);
        });
    };

    const gridStyle: any = {
        width: '30%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '10px',
        height: '100%',
        display: 'inline-block',
    };

    const getCounts = () => (
        <div style={{ marginTop: '35px', marginLeft: '20px' }}>
            <>
                <div style={gridStyle}>
                    <Tooltip title={geti18nText('contract.type.edit.contractCount')}>
                        <Badge count={contractCount} showZero size="small">
                            <i
                                style={{ fontSize: '2em', marginLeft: '-3px', marginTop: '-2px' }}
                                className={`las la-file-signature statistic-card-light`}
                            ></i>
                        </Badge>
                    </Tooltip>
                </div>
                <div style={gridStyle}>
                    {differentDocumentTemplateContractCount > 0 && canCreate ? (
                        <NyModalConfirm
                            title={geti18nText('contract.type.edit.generate.message')}
                            onConfirm={generateAllWithNewTemplate}
                        >
                            <Tooltip
                                title={
                                    <>
                                        {geti18nText('contract.type.edit.differentDocumentTemplateContractCount')}
                                        <br /> {geti18nText('contract.type.edit.generate')}
                                    </>
                                }
                            >
                                <Badge count={differentDocumentTemplateContractCount} showZero size="small">
                                    <i
                                        style={{
                                            fontSize: '2em',
                                            marginLeft: '-3px',
                                            marginTop: '-2px',
                                        }}
                                        className={`las la-file-archive statistic-card-light`}
                                    ></i>
                                </Badge>
                            </Tooltip>
                        </NyModalConfirm>
                    ) : (
                        <Tooltip
                            title={<>{geti18nText('contract.type.edit.differentDocumentTemplateContractCount')}</>}
                        >
                            <Badge count={differentDocumentTemplateContractCount} showZero size="small">
                                <i
                                    style={{
                                        fontSize: '2em',
                                        marginLeft: '-3px',
                                        marginTop: '-2px',
                                    }}
                                    className={`las la-file-archive statistic-card-light`}
                                ></i>
                            </Badge>
                        </Tooltip>
                    )}
                </div>
                <div style={gridStyle}>
                    <Tooltip title={geti18nText('contract.type.edit.differentMetadataVersionContractCount')}>
                        <Badge count={differentMetadataVersionContractCount} showZero size="small">
                            <i
                                style={{ fontSize: '2em', marginLeft: '-3px', marginTop: '-2px' }}
                                className={`las la-file-medical-alt statistic-card-light`}
                            ></i>
                        </Badge>
                    </Tooltip>
                </div>
            </>
        </div>
    );

    const getGeneralEditData = () => (
        <Form layout="vertical" form={form}>
            <Row gutter={24}>
                <Col span={props?.editProps?.annex ? 23 : 12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('contract.type.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Col>
                {!props?.editProps?.annex && (
                    <Col span={11}>
                        <ContractTypeGroupSearch mustGetPopupContainer={false} />
                    </Col>
                )}
                <Col span={1}>
                    <div style={iconStyle}>
                        <Tooltip title={geti18nText('menu.contract.global.constants')}>
                            <AppstoreOutlined
                                className={`las la-file-archive statistic-card-light`}
                                onClick={() => setGlobalConstantsModalVisible(true)}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={props?.editProps?.annex || !dataForm ? 24 : 20}>
                    <Form.Item label={geti18nText('contract.type.edit.description')} name="description">
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 4 }} onKeyDown={onKeyDownTextAreaCustom} />
                    </Form.Item>
                </Col>
                {dataForm && !props?.editProps?.annex && <Col span={4}>{getCounts()}</Col>}
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Divider orientation="left" plain style={{ marginBottom: '-5px' }}>
                        <Title level={5}>{geti18nText('contract.type.edit.documentTemplate')}</Title>
                    </Divider>
                    <NyImageUpload
                        files={files}
                        setFiles={setFiles}
                        saveOnUpload={true}
                        accept={'.docx'}
                        cropShape={null}
                        listType={'text'}
                        maxImage={1}
                        showHistoryId={dataForm}
                        isContractTypeView
                        canEditFile={canCreate}
                        setNewTemplate={setNewTemplate}
                        canDeleteFile={canCreate}
                        canUpload={canCreate}
                    />
                </Col>
            </Row>
            {(contractMetadata || isCreate) && (
                <Row gutter={24}>
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <ContractMetadataIndex
                            usedMetadataKeys={contractMetadata}
                            selectedMetadataKeys={selectedMetadataKeys}
                            setSelectedMetadataKeys={setSelectedMetadataKeys}
                            triggerGetKeys={triggerGetKeys}
                            style={{ width: '1800px' }}
                        />
                    </Col>
                </Row>
            )}
            <Modal
                open={globalConstantsModalVisible}
                title={geti18nText('menu.contract.global.constants')}
                onOk={(event: any) => {
                    setGlobalConstantsModalVisible(false);
                    event.stopPropagation();
                    event.preventDefault();
                }}
                onCancel={() => {
                    setGlobalConstantsModalVisible(false);
                }}
                width={1200}
                forceRender={true}
                closable={true}
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={geti18nText('app.default.button.ok')}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <ContractGlobalConstantsIndex showHeader={false} />
                    </Col>
                </Row>
            </Modal>
        </Form>
    );

    const getAnexData = () => (
        <ContractTypeIndex
            filter={[
                { field: 'annex', condition: 'equals_bool', value: 1 },
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'contractType.id', condition: 'equals', value: dataForm },
            ]}
            annex={true}
            contractTypeId={dataForm}
            scroll={{ y: 400, x: 700 }}
        />
    );

    const getContractData = () => (
        <ContractsCTRIndex
            defaultFilterValue={[
                { field: 'annex', condition: 'equals_bool', value: 0 },
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'contractType.id', condition: 'equals', value: dataForm },
            ]}
            showHeader={false}
            documentTemplateFromContractType={contractDocumentTemplate}
            contractType={{ id: dataForm, name: form.getFieldValue(['name']) }}
            mark={props?.editProps?.mark}
            scroll={{ y: 400, x: 700 }}
            hideNewButton={props?.editProps?.annex}
        />
    );

    const getNotificationData = () => (
        <ContractNotificationsIndex contractTypeId={dataForm} refresh={refresh} scroll={{ y: 400, x: 700 }} />
    );

    const getJobsData = () => (
        <ContractJobsIndex contractTypeId={dataForm} refresh={refresh} scroll={{ y: 400, x: 700 }} />
    );

    const normalize = (values: any) => {
        if (values.contractCategory) {
            values.contractCategory = getSearchFormat(values.contractCategory);
        }
        if (values.contractTypeGroup) {
            values.contractTypeGroup = getSearchFormat(values.contractTypeGroup);
        }
        values.annex = props?.editProps?.annex;
        if (props?.editProps?.contractTypeId) {
            values.contractType = { id: props.editProps.contractTypeId };
        }

        if (selectedMetadataKeys) {
            values.contractTypeMetadataList = selectedMetadataKeys.map((item: any, index: number) => {
                const correspondingGottenMetadata = contractMetadata?.find(
                    (gottenItem: any) => gottenItem?.contractMetadata?.id === item?.id
                );

                return {
                    documentPlaceholder: item.documentPlaceholder,
                    mandatory: item?.mandatory ?? false,
                    contractMetadata: { id: item?.id },
                    ...(correspondingGottenMetadata && {
                        id: correspondingGottenMetadata.id,
                    }),
                    order: index + 1,
                };
            });
        }
        return values;
    };

    const normalizeTemplate = async () => {
        if (files?.length > 0 && files[0]) {
            let templateId = contractDocumentTemplate?.id ?? null;
            if (contractDocumentTemplate?.id && files[0] && !newTemplate) {
                await NyRequestResolver.requestPut(
                    CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.EDIT + '/' + templateId,
                    undefined,
                    {
                        id: contractDocumentTemplate.id,
                        fileId: files[0].id,
                        name: files[0].name,
                        status: 2,
                        parent: contractDocumentTemplate?.parent?.id
                            ? { id: contractDocumentTemplate?.parent?.id }
                            : null,
                        version: contractDocumentTemplate?.version ? contractDocumentTemplate?.version + 1 : 1,
                    }
                );
            } else if (newTemplate) {
                await NyRequestResolver.requestPost(CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.EDIT, undefined, {
                    fileId: files[0].id,
                    name: files[0].name,
                    status: 2,
                    parent: contractDocumentTemplate?.id ? { id: contractDocumentTemplate?.id } : null,
                }).then((result: any) => {
                    templateId = result?.data?.id;
                });
            }

            return {
                id: templateId,
                fileId: files[0].id,
                name: files[0].name,
            };
        } else {
            return {
                id: contractDocumentTemplate?.id,
            };
        }
    };

    const setFilterValueCategory = (mark: any) => {
        return [
            { field: 'mark', condition: 'equals', value: mark.toString() },
            { field: 'active', condition: 'equals_bool', value: 1 },
        ];
    };

    const normalizeCategory = async () => {
        if (contractCategory?.id) {
            return getSearchFormat(contractCategory);
        } else if (props?.editProps?.mark) {
            let categoryMark: any = null;
            await NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_CATEGORY.LIST, {
                search: encodeURI(JSON.stringify(setFilterValueCategory(props?.editProps?.mark))),
            }).then((result: any) => {
                if (result.status == RESPONSE.OK && result?.data?.content && result?.data?.content[0]) {
                    categoryMark = result?.data?.content[0];
                }
            });
            return categoryMark ? getSearchFormat(categoryMark) : undefined;
        }
    };

    const save = () => {
        setLoading(true);
        form.validateFields().then(async (values: any) => {
            if (values?.name?.length > 0) {
                values = normalize(values);
                //template
                const documentTemplate: any = await normalizeTemplate();
                values.documentTemplate = documentTemplate;
                //category
                const category: any = await normalizeCategory();
                values.contractCategory = category;

                if (values.id) {
                    NyRequestResolver.requestPut(CONSTANTS_REQ.CONTRACT_TYPE.EDIT + '/' + values.id, undefined, values)
                        .then((result: any) => {
                            if (result && result.status === RESPONSE.CREATED) {
                                okNotification();
                                setTriggerCloseModal(true);
                                props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
                            } else {
                                getErrorNotificationMessage(result);
                            }
                        })
                        .finally(() => setLoading(false));
                } else {
                    NyRequestResolver.requestPost(CONSTANTS_REQ.CONTRACT_TYPE.EDIT, undefined, values)
                        .then((result: any) => {
                            if (result && result.status === RESPONSE.CREATED) {
                                okNotification();
                                setValues(result.data);
                                props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
                            } else {
                                getErrorNotificationMessage(result);
                            }
                        })
                        .finally(() => setLoading(false));
                }
            } else {
                errorNotification(geti18nText('employee.education.notification.form'));
                setLoading(false);
            }
        });
    };

    const getCustomFooterContent = (
        <>
            {activeKey == 'general' && canCreate && (
                <Button onClick={save} style={{ marginLeft: '-2px', float: 'right' }} type="primary" loading={loading}>
                    {geti18nText('app.default.button.save')}
                </Button>
            )}
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CONTRACT_TYPE.EDIT}
            setValues={setValues}
            width={1800}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={true}
            hideActivationButtons={!canCreate || activeKey != 'general'}
            paramsId={id}
            shortcuts={true}
            leaveNewModalOpen={true}
            customFooterContent={getCustomFooterContent}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            setIsCreate={setIsCreate}
            onBeforeSave={() => {
                setTriggerGetKeys(triggerGetKeys + 1);
            }}
            onSaveAndGetID={(id: any) => {
                setDataForm(id);
            }}
            triggerCloseModal={triggerCloseModal}
            normalize={normalize}
            modalStyle={{ top: '5px' }}
            {...props}
        >
            <div
                style={{
                    maxHeight: '84vh',
                }}
            >
                <>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <>
                            {!dataForm && props?.editProps?.annex ? (
                                getGeneralEditData()
                            ) : (
                                <Tabs
                                    type="card"
                                    id="contract-type-edit-tabs"
                                    style={{ paddingBottom: '10px' }}
                                    defaultActiveKey={activeKey}
                                    activeKey={activeKey}
                                    onChange={onChangeTab}
                                >
                                    <TabPane tab={geti18nText('contract.type.tab.general')} key="general">
                                        <div style={{ maxHeight: '600px' }} className="scrollbar-on-hover">
                                            {getGeneralEditData()}
                                        </div>
                                    </TabPane>
                                    <TabPane
                                        tab={geti18nText('contract.type.tab.contracts')}
                                        key="contracts"
                                        disabled={!dataForm}
                                    >
                                        {getContractData()}
                                    </TabPane>
                                    {!props?.editProps?.annex && (
                                        <TabPane
                                            tab={geti18nText('contract.type.tab.anex.type')}
                                            key="anex"
                                            disabled={!dataForm}
                                        >
                                            {getAnexData()}
                                        </TabPane>
                                    )}
                                    <TabPane tab={geti18nText('menu.contract.jobs')} key="jobs" disabled={!dataForm}>
                                        {getJobsData()}
                                    </TabPane>
                                    <TabPane
                                        tab={geti18nText('contract.type.tab.notif')}
                                        key="notif"
                                        disabled={!dataForm}
                                    >
                                        {getNotificationData()}
                                    </TabPane>
                                </Tabs>
                            )}
                        </>
                    )}
                </>
            </div>
        </NyDataEdit>
    );
};

export default ContractTypeEdit;
