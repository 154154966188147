import { DeleteOutlined, DeleteTwoTone, SaveTwoTone } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, getErrorNotificationMessage, okNotification } from '../../../../../utils/Utils';
import EditableCell from './EditableCell';

const TaskTemplateApprovalUserIndex = ({
    taskTemplateApprovalId = null,
    addNewItem = 0,
    addItemInProcess,
    setAddItemInProcess,
}: any) => {
    const [form] = Form.useForm();
    const [refresh, setRefresh] = useState(0);
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey;
    const [items, setItems] = useState<any>([]);

    useEffect(() => {
        if (taskTemplateApprovalId) {
            getApprovalUserItems(taskTemplateApprovalId);
        }
    }, [taskTemplateApprovalId, refresh]);

    useEffect(() => {
        if (addNewItem > 0) {
            setAddItemInProcess(true);
            const newData = {
                id: null,
                taskTemplateApproval: { id: taskTemplateApprovalId },
                user: undefined,
                parent: undefined,
                active: true,
            };
            setItems([...items, newData]);
            edit(newData);
        }
    }, [addNewItem]);

    const getApprovalUserItems = (taskTemplateApprovalId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TEMPLATE_APPROVAL.EDIT + '/' + taskTemplateApprovalId).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result?.data?.taskTemplateApprovalUserList) {
                    setItems(
                        result?.data?.taskTemplateApprovalUserList.sort((a: any, b: any) => {
                            return a.id - b.id;
                        })
                    );
                } else {
                    setItems([]);
                }
            }
        );
    };

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };

        form.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const saveApprovalUser = async () => {
        const row = await form.validateFields();
        if (row?.user?.id) {
            const values: any = {
                taskTemplateApprovalId: taskTemplateApprovalId,
                userId: row.user.id,
                parentId: row?.parent?.id,
            };
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TASK_TEMPLATE_APPROVAL.ADD_USER_TO_TASK_TEMPLATE_APPROVAL + '/' + taskTemplateApprovalId,
                undefined,
                values
            ).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                    setEditingKey('');
                    setAddItemInProcess(false);
                    setRefresh((refresh) => refresh + 1);
                } else {
                    getErrorNotificationMessage(result);
                }
            });
        } else {
            errorNotification(geti18nText('app.default.required.fields'));
        }
    };

    const deactivateApprovalUser = async (record: any) => {
        if (record?.id) {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TASK_TEMPLATE_APPROVAL.DEACTIVATE_TASK_TEMPLATE_APPROVAL_USER + '/' + record.id,
                undefined,
                { taskTemplateApprovalUserId: record.id }
            ).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                    setRefresh((refresh) => refresh + 1);
                } else {
                    getErrorNotificationMessage(result);
                }
            });
        } else {
            setItems(items.filter((row: any) => row.id !== null));
            setAddItemInProcess(false);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={saveApprovalUser}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                        <Button
                            type="link"
                            onClick={() => {
                                deactivateApprovalUser(record);
                            }}
                        >
                            <DeleteTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#ff7875"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('app.default.delete.confirm')}
                        onConfirm={() => {
                            deactivateApprovalUser(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            {addItemInProcess ? (
                                <Button type="link" disabled={addItemInProcess}>
                                    <DeleteOutlined
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            ) : (
                                <Button type="link" disabled={addItemInProcess}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            )}
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const columns = [
        {
            title: geti18nText('task.template.approval.user'),
            dataIndex: 'user',
            width: '49%',
            editable: true,
            inputType: 'user',
            render: (text: any, record: any) => {
                if (record?.user?.person) {
                    return `${record.user.person.firstName} ${record.user.person.lastName}`;
                }
            },
        },
        {
            title: geti18nText('task.template.approval.parent'),
            dataIndex: 'parent',
            width: '49%',
            editable: true,
            inputType: 'parent',
            render: (text: any, record: any) => {
                if (record?.parent?.user?.person) {
                    return `${record.parent.user.person.firstName} ${record.parent.user.person.lastName}`;
                }
            },
        },
        {
            key: 'action',
            editable: false,
            render: (text: any, record: any) => {
                if (items.length >= 1) {
                    const editable = isEditing(record);
                    return editable ? (
                        <ActionEditable text={text} record={record} />
                    ) : (
                        <ActionNotEditable text={text} record={record} />
                    );
                }
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: form,
                    taskTemplateApprovalId: taskTemplateApprovalId,
                    showParent: items?.length > 0 && items[0] && items[0].id,
                };
            },
        };
    });

    return (
        <>
            <Row gutter={24}>
                <Col span={2}></Col>
                <Col span={22}>
                    <Form form={form} component={false}>
                        {items?.length > 0 && (
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                size={'small'}
                                dataSource={items}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                pagination={false}
                            />
                        )}
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default TaskTemplateApprovalUserIndex;
