import { DownloadOutlined } from '@ant-design/icons';
import { NyDataTable, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import RowAction from '../../../../components/row-action';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { message } from 'antd';
import { downloadFile, errorNotification } from '../../../../utils/Utils';

const HumanLogIndex = ({
    url = CONSTANTS_REQ.LOGS.LIST,
    downloadUrl = CONSTANTS_REQ.LOGS.DOWNLOADLOGFILE,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    function exportData(data: any) {
        message.loading(geti18nText('import.text.loading'), 0);
        NyRequestResolver.requestGet(downloadUrl, { name: data.name }, false, true).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                result.data.filename = data.name;
                downloadFile(result);
            } else {
                console.log(JSON.stringify(result.data));
                errorNotification(JSON.stringify(result.data));
            }
            message.destroy();
        });
    }

    return (
        <NyDataTable
            nyId="human-log"
            url={url}
            showRecordModal={false}
            setDefaultPageSize={table.setDefaultPageSize()}
            hideButtons={true}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            columns={[
                {
                    title: geti18nText('log.table.column.date'),
                    dataIndex: 'date',
                },
                {
                    title: geti18nText('log.table.column.name'),
                    dataIndex: 'name',
                },
                {
                    title: geti18nText('app.default.actions'),
                    dataIndex: 'actions',
                    width: '100px',
                    render: (text: any, record: any) => {
                        if (record) {
                            return (
                                <RowAction
                                    component={'Download'}
                                    record={record}
                                    download={exportData}
                                    downloadLabel={'app.default.download'}
                                    downloadIcon={
                                        <DownloadOutlined
                                            style={{ fontSize: '16px', paddingRight: '10px', float: 'left' }}
                                        />
                                    }
                                ></RowAction>
                            );
                        }
                    },
                },
            ]}
            headerTitle={geti18nText('menu.logs')}
        />
    );
};

export default HumanLogIndex;
