import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, SaveTwoTone } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Col, Collapse, Form, Modal, Row, Space, Table, Tag, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import HumanCodebooksSearch from '../../../../../components/human-codebooks/search';
import NyDescription from '../../../../../components/ny-description';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, getSearchFormat, okNotification } from '../../../../../utils/Utils';
import EditableCell from './EditableCell';

const { Panel } = Collapse;

const CompetencyListIndex = ({
    id,
    form,
    isUser = false,
    isManager = false,
    disabledForm = false,
    activeKey,
    typeList = [],
    setShowAlert,
    showAlert,
    setShowAlertEmployee,
    showAlertEmployee,
    isCompetencyListManagerCommentMandatory,
    status,
    dataFormObject,
}: any) => {
    const focusInput = useRef<any>(null);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const enEmployeeEvaluationStatus = useEnum('EMPLOYEE_EVALUATION_STATUS');
    const [editForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey || record.tempId === editingKey;
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [isNewInProcess, setIsNewInProcess] = useState<boolean>(false);
    const [items, setItems] = useState<any>([]);
    const [typeListGrouped, setTypeListGrouped] = useState<any>([]);
    const [editRecordForm] = Form.useForm();
    const [editRecord, setEditRecord] = useState<any>(null);

    useEffect(() => {
        setAddItemInProcess(false);
        setEditingKey('');
        if (isNewInProcess) {
            setItems(items.filter((element: any) => element.id != null && element?.id != undefined));
        }
    }, [activeKey]);

    useEffect(() => {
        getItems();
    }, [typeList]);

    const resolveGroupedData = (data: any = []) => {
        let allFilledCompetency: boolean = true;
        data.every((item: any) => {
            if (!item.managerEstimation?.id || isCompetencyListManagerCommentMandatory(item)) {
                allFilledCompetency = false;
            }
            return item;
        });

        if (isManager) {
            setShowAlert(!allFilledCompetency);
        }

        const showItemAlertEmployee: any = data.every((item: any) => {
            return item.employeeEstimation !== undefined;
        });

        if (isUser) {
            setShowAlertEmployee(!showItemAlertEmployee);
        }

        setItems(data);
        let groupedList: any =
            typeList?.length > 0
                ? typeList.map((type: any) => {
                      type.items =
                          data?.length > 0 ? data.filter((item: any) => item?.templateType?.id == type?.id) : [];
                      return type;
                  })
                : [];
        setTypeListGrouped(groupedList);
    };

    const getFilter = () => {
        if (isUser) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_evaluation_id', condition: 'equals', value: id },
                { field: 'templateType.code', condition: 'not_equals_string', value: '1' },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_evaluation_id', condition: 'equals', value: id },
            ];
        }
    };

    const getItems = () => {
        if (id) {
            if (typeList?.length > 0) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION_COMPETENCY.LIST, {
                    search: encodeURI(JSON.stringify(getFilter())),
                    order: 'id',
                    orderType: 'asc',
                    max: 1000,
                }).then((result: any) => {
                    if (result.status === RESPONSE.OK && result?.data?.content) {
                        const data: any = result?.data?.content;
                        resolveGroupedData(data);
                    } else {
                        setTypeListGrouped([]);
                        setItems([]);
                    }
                });
            }
        }
    };

    const addNewRow = (event: any) => {
        setIsNewInProcess(true);
        event.stopPropagation();
        editForm.resetFields();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            tempId: newId,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const edit = (record: any) => {
        let row = undefined;
        row = { ...record };
        editForm.setFieldsValue(row);
        setAddItemInProcess(true);
        setEditingKey(row.id ?? row.tempId);
    };

    const deleteRow = (row: any) => {
        if (row.id) {
            row.active = false;
            saveItem(row);
            setItems(items.filter((element: any) => row.id !== element.id));
        } else if (row?.tempId != undefined && row?.tempId >= 0) {
            setItems(items.filter((element: any) => row.tempId !== element.tempId));
        }
    };

    const cancel = (record: any) => {
        deleteRow(record);
        setEditingKey('');
        setAddItemInProcess(false);
        setIsNewInProcess(false);
    };

    const afterSaveAction = (result: any) => {
        if (result?.status === RESPONSE.CREATED || result?.status === RESPONSE.OK) {
            okNotification();
            setEditingKey('');
            setAddItemInProcess(false);
            setIsNewInProcess(false);

            if (result?.data?.active == true) {
                const newData = [...items];
                let index: any = newData.findIndex((object) => object.id === result.data.id);

                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...result.data });
                } else {
                    newData.push(result.data);
                }
                const filteredData: any =
                    newData?.length > 0
                        ? newData.filter((element: any) => element.id != null && element.id != undefined)
                        : [];

                let allFilledCompetency: boolean = true;
                newData.every((item: any) => {
                    if (!item.managerEstimation?.id || isCompetencyListManagerCommentMandatory(item)) {
                        allFilledCompetency = false;
                    }
                    return item;
                });

                if (isManager) {
                    setShowAlert(!allFilledCompetency);
                }

                const showItemAlertEmployee: any = newData.every((item: any) => {
                    return item.employeeEstimation !== undefined;
                });

                if (isUser) {
                    setShowAlertEmployee(!showItemAlertEmployee);
                }
                resolveGroupedData(filteredData);
            }
        } else {
            errorNotification();
        }
    };

    const saveItem = (record: any) => {
        record.employeeEvaluation = { id: id };
        if (record.managerEstimation) {
            record.managerEstimation = { id: record.managerEstimation.id };
        }
        if (record.employeeEstimation) {
            record.employeeEstimation = { id: record.employeeEstimation.id };
        }
        if (record.employeeEvaluationCompetencyTemplate) {
            record.employeeEvaluationCompetencyTemplate = getSearchFormat(record.employeeEvaluationCompetencyTemplate);
        }
        if (record.employeeEvaluationEmployee) {
            record.employeeEvaluationEmployee = getSearchFormat(record.employeeEvaluationEmployee);
        }
        if (record.employeeEvaluationPerson) {
            record.employeeEvaluationPerson = getSearchFormat(record.employeeEvaluationPerson);
        }
        if (record.templateType) {
            record.templateType = getSearchFormat(record.templateType);
        }
        if (record.id) {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.EMPLOYEE_EVALUATION_COMPETENCY.EDIT + '/' + record.id,
                undefined,
                record
            ).then((result: any) => {
                afterSaveAction(result);
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_EVALUATION_COMPETENCY.EDIT, undefined, record).then(
                (result: any) => {
                    afterSaveAction(result);
                }
            );
        }
    };

    const save = async () => {
        try {
            let row = await editForm.validateFields();
            saveItem(row);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const getEditForm = (
        record: any,
        value: any,
        dataIndex: any,
        title: string,
        disabled: boolean,
        maxWidth: any,
        mandatory: boolean = false
    ) => (
        <Row gutter={24}>
            <Col span={21}>
                {mandatory && !disabled ? (
                    <p style={{ marginTop: '10px', width: '100%' }}>
                        <Alert
                            message={geti18nText('app.default.required')}
                            type="error"
                            onClick={() => {
                                setTimeout(() => {
                                    if (focusInput.current) {
                                        focusInput.current.focus();
                                    }
                                });
                                setEditRecord({
                                    ...record,
                                    dataIndex: dataIndex,
                                    title: title,
                                    value: value,
                                });
                                editRecordForm.setFieldsValue({ editRecord: value });
                            }}
                        />
                    </p>
                ) : (
                    <p style={{ marginTop: '10px' }}>
                        <NyDescription title={value} maxWidth={maxWidth} />
                    </p>
                )}
            </Col>
            {!disabled && (
                <Col span={3}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                        <Button
                            type="link"
                            onClick={() => {
                                setTimeout(() => {
                                    if (focusInput.current) {
                                        focusInput.current.focus();
                                    }
                                });
                                setEditRecord({
                                    ...record,
                                    dataIndex: dataIndex,
                                    title: title,
                                    value: value,
                                });
                                editRecordForm.setFieldsValue({ editRecord: value });
                            }}
                            disabled={disabled}
                            style={{ float: 'right' }}
                        >
                            <EditTwoTone
                                style={{
                                    fontSize: '20px',
                                    marginTop: '8px',
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Col>
            )}
        </Row>
    );

    const columns = [
        ...[
            ...(addItemInProcess
                ? [
                      {
                          title: geti18nText('employee.evaluation.edit.competency.column.type'),
                          dataIndex: ['employeeEvaluationCompetencyTemplate', 'type'],
                          inputType: 'employeeEvaluationCompetencyTemplateType',
                          editable: !disabledForm,
                          required: true,
                          render: (text: any, record: any) => {
                              return (
                                  <p style={{ marginTop: '10px' }}>
                                      <NyDescription
                                          title={record?.employeeEvaluationCompetencyTemplate?.type?.name}
                                          maxWidth={'350px'}
                                      />
                                  </p>
                              );
                          },
                      },
                  ]
                : []),
            {
                title: geti18nText('employee.evaluation.edit.competency.column.description'),
                dataIndex: ['employeeEvaluationCompetencyTemplate', 'description'],
                inputType: 'employeeEvaluationCompetencyTemplateDescription',
                editable: false,
                required: false,
                render: (text: any, record: any) => {
                    return (
                        <p style={{ marginTop: '10px' }}>
                            <NyDescription
                                title={record?.employeeEvaluationCompetencyTemplate?.description}
                                maxWidth={'350px'}
                            />
                        </p>
                    );
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.competency.column.managerEstimation'),
                dataIndex: 'managerEstimation',
                inputType: 'managerEstimation',
                editable: isManager && !disabledForm,
                required: isManager,
                render: (text: any, record: any) => {
                    if (!isManager || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                {record?.managerEstimation?.name && (
                                    <Tag color={'blue'} style={{ fontSize: '14px' }}>
                                        {record?.managerEstimation?.name}
                                    </Tag>
                                )}
                            </p>
                        );
                    } else {
                        let name = record?.id ? record?.id.toString() : record?.tempId ? record?.tempId.toString() : '';
                        name = name.concat('managerEstimation');
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <HumanCodebooksSearch
                                    value={record.managerEstimation}
                                    defaultValue={record.managerEstimation}
                                    required={isManager}
                                    label={''}
                                    codebooksType={enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION}
                                    formItemName={name}
                                    mustGetPopupContainer={false}
                                    renderOption={(option: any) => `${option.text}`}
                                    allowClear={false}
                                    onChange={(value: any) => {
                                        saveItem({ ...record, managerEstimation: value });
                                    }}
                                    style={{
                                        outline: record?.managerEstimation?.id ? '1px solid #91d5ff' : '1px solid red',
                                        border: '3px solid white',
                                    }}
                                />
                            </p>
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.competency.column.employeeEstimation'),
                dataIndex: 'employeeEstimation',
                inputType: 'employeeEstimation',
                editable: isUser && !disabledForm,
                required: isUser,
                render: (text: any, record: any) => {
                    if (!isUser || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                {record?.employeeEstimation?.name && (
                                    <Tag color={'blue'} style={{ fontSize: '14px' }}>
                                        {record?.employeeEstimation?.name}
                                    </Tag>
                                )}
                            </p>
                        );
                    } else {
                        let name = record?.id ? record?.id.toString() : record?.tempId ? record?.tempId.toString() : '';
                        name = name.concat('employeeEstimation');
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <HumanCodebooksSearch
                                    value={record.employeeEstimation}
                                    defaultValue={record.employeeEstimation}
                                    required={isUser}
                                    label={''}
                                    codebooksType={enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION}
                                    formItemName={name}
                                    mustGetPopupContainer={false}
                                    renderOption={(option: any) => `${option.text}`}
                                    allowClear={false}
                                    onChange={(value: any) => {
                                        saveItem({ ...record, employeeEstimation: value });
                                    }}
                                    style={{
                                        outline: record?.employeeEstimation?.id ? '1px solid #91d5ff' : '1px solid red',
                                        border: '3px solid white',
                                    }}
                                />
                            </p>
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.competency.column.managerComment'),
                dataIndex: 'managerComment',
                inputType: 'managerComment',
                editable: isManager && !disabledForm,
                required: isManager,
                render: (text: any, record: any) => {
                    if (!isManager || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.managerComment} maxWidth={'350px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.managerComment,
                            'managerComment',
                            geti18nText('employee.evaluation.edit.competency.column.managerComment'),
                            !isManager || disabledForm || addItemInProcess,
                            '300px',
                            isCompetencyListManagerCommentMandatory(record)
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.competency.column.employeeComment'),
                dataIndex: 'employeeComment',
                inputType: 'employeeComment',
                editable: isUser && !disabledForm,
                required: isUser,
                render: (text: any, record: any) => {
                    if (!isUser || disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.employeeComment} maxWidth={'350px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.employeeComment,
                            'employeeComment',
                            geti18nText('employee.evaluation.edit.competency.column.employeeComment'),
                            !isUser || disabledForm || addItemInProcess,
                            '300px'
                        );
                    }
                },
            },
        ],
        ...(items.length >= 1 && !disabledForm && addItemInProcess
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '5%',
                      render: (text: any, record: any) => {
                          if (items.length >= 1 && isEditing(record)) {
                              return <ActionEditable text={text} record={record} />;
                          }
                      },
                  },
              ]
            : []),
        ...[],
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable || disabledForm) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    required: col.required,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: editForm,
                };
            },
        };
    });

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={save} disabled={disabledForm} style={{ marginRight: '-15px' }}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button
                            type="link"
                            disabled={disabledForm}
                            onClick={() => {
                                cancel(record);
                            }}
                        >
                            <CloseCircleTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#faad14"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                    {isManager && (
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={!isManager || disabledForm || addItemInProcess}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    )}
                </Space>
            </React.Fragment>
        );
    };

    return (
        <>
            <Form layout="vertical" form={form}>
                {showAlertEmployee && isUser && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                type="info"
                                showIcon
                                message={geti18nText('employee.evaluation.edit.competency.alertEmployee')}
                            />
                        </Col>
                    </Row>
                )}
                {status == enEmployeeEvaluationStatus.ESTIMATION && isManager && (
                    <Alert
                        message={geti18nText('employee.evaluation.edit.competency.managerComment.mandatory')}
                        type="info"
                        showIcon
                        style={{ margin: '5px' }}
                    />
                )}
                <Row
                    gutter={24}
                    style={{
                        marginBottom: '10px',
                        marginTop: '5px',
                    }}
                >
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={editForm}
                            onFinishFailed={({ errorFields }) => {
                                editForm.scrollToField(errorFields[0].name);
                            }}
                        >
                            <div style={{ maxHeight: '560px', overflowY: 'auto', overflowX: 'hidden' }}>
                                {typeListGrouped?.length > 0 &&
                                    typeListGrouped.map((type: any) => (
                                        <>
                                            {type?.items?.length > 0 && (
                                                <Collapse activeKey={typeListGrouped.map((element: any) => element.id)}>
                                                    <Panel header={type?.name} key={type?.id}>
                                                        <Table
                                                            rowClassName="editable-row"
                                                            components={{
                                                                body: {
                                                                    cell: EditableCell,
                                                                },
                                                            }}
                                                            dataSource={type?.items?.length > 0 ? type.items : []}
                                                            columns={mergedColumns}
                                                            pagination={false}
                                                            size="small"
                                                            sticky
                                                            bordered
                                                            style={{
                                                                maxHeight: '450px',
                                                                overflowY: 'auto',
                                                                overflowX: 'hidden',
                                                            }}
                                                        />
                                                    </Panel>
                                                </Collapse>
                                            )}
                                        </>
                                    ))}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Form>
            <Modal
                open={editRecord != null}
                title={editRecord?.title}
                okText={geti18nText('app.default.button.save')}
                onOk={() => {
                    saveItem(editRecord);
                    setEditRecord(null);
                }}
                onCancel={() => setEditRecord(null)}
            >
                <Form form={editRecordForm}>
                    <Form.Item name="editRecord">
                        <TextArea
                            ref={focusInput}
                            allowClear={false}
                            onBlur={(value: any) => {
                                if (value?.target?.value?.length > 0) {
                                    if (editRecord?.dataIndex == 'managerComment') {
                                        setEditRecord({ ...editRecord, managerComment: value?.target?.value });
                                    } else if (editRecord?.dataIndex == 'employeeComment') {
                                        setEditRecord({ ...editRecord, employeeComment: value?.target?.value });
                                    }
                                }
                            }}
                            autoSize={{ minRows: 3, maxRows: 4 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default CompetencyListIndex;
