import { EllipsisOutlined, FileExcelOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Dropdown, Empty, Menu, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { fileDownload } from '../../../../utils/Utils';
import InsideCompanyTable from './InsideCompanyTable';
import NoWarScheduleTable from './NoWarScheduleTable';
import OutsideCompanyTable from './OutsideCompanyTable';
import TotalTable from './TotalTable';

const EmployeeScheduleIndex = () => {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [activeList, setActiveList] = useState<any>(1);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        // dispatch(setProp(e.target.value > 0 ? e.target.value : 1));
    };

    const [departments, setDepartments] = useState<any>(undefined); //odjel
    const [sections, setSections] = useState<any>(undefined); //odsjek

    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const mobilizationReportType = useEnum('MOBILIZATION_REPORT_TYPE');

    useEffect(() => {
        fetchDepartments(setDefaultFilterDepartmensValue());
        fetchDepartments(setDefaultFilterSectionsValue());
        generateView();
    }, []);

    const setDefaultFilterDepartmensValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: defenseDepartmentType.DEPARTMENT },
        ];
    };

    const setDefaultFilterSectionsValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'type',
                condition: 'equals',
                value: defenseDepartmentType.SECTION,
            },
        ];
    };

    const fetchDepartments = (filter: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEFENSE_DEPARTMENT.LIST, {
            max: 100,
            orderType: 'desc',
            search: encodeURI(JSON.stringify(filter)),
        }).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content) {
                filter[1].value == defenseDepartmentType.DEPARTMENT && setDepartments(result.data.content);
                filter[1].value == defenseDepartmentType.SECTION && setSections(result.data.content);
            }
        });
    };

    const downloadDocument = (type: any, fileName: any, departmentId?: any) => {
        let params: any = { type: type };
        if (departmentId) {
            params.refId = departmentId;
        }
        departments.map((department: any) => {
            if (department.id == departmentId) {
                fileDownload(
                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                    params,
                    fileName + ' - ' + department.name + '.xlsx'
                );
            }
        });
    };

    const downloadSectionDocument = (type: any, fileName: any, sectionId?: any) => {
        let params: any = { type: type };
        if (sectionId) {
            params.refId = sectionId;
        }
        sections.map((section: any) => {
            if (section.id == sectionId) {
                fileDownload(
                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                    params,
                    fileName + ' - ' + section.name + '.xlsx'
                );
            }
        });
    };

    const addedButtons = () => {
        const actionsMenu = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                {departments &&
                    departments.length > 0 &&
                    departments.map((department: any) => {
                        return (
                            <div style={{ display: 'block', margin: '5px' }}>
                                {activeList == 2 ? (
                                    <Button
                                        block
                                        icon={<FileExcelOutlined />}
                                        onClick={() =>
                                            downloadDocument(
                                                mobilizationReportType.MOB_4_10_1,
                                                geti18nText('app.enum.MOB_4_10_1.11'),
                                                department.id
                                            )
                                        }
                                    >
                                        {geti18nText('app.enum.MOB_4_10_1.11') + ' - ' + department.name}
                                    </Button>
                                ) : (
                                    <Button
                                        block
                                        icon={<FileExcelOutlined />}
                                        onClick={() =>
                                            downloadDocument(
                                                mobilizationReportType.MOB_4_11,
                                                geti18nText('app.enum.MOB_4_11_1.12'),
                                                department.id
                                            )
                                        }
                                    >
                                        {geti18nText('app.enum.MOB_4_11_1.12') + ' - ' + department.name}
                                    </Button>
                                )}
                            </div>
                        );
                    })}
            </Menu>
        );

        const sectionsMenu = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                {sections &&
                    sections.length > 0 &&
                    sections.map((section: any) => {
                        return (
                            <div style={{ display: 'block', margin: '5px' }}>
                                {activeList == 2 ? (
                                    <Button
                                        block
                                        icon={<FileExcelOutlined />}
                                        onClick={() =>
                                            downloadSectionDocument(
                                                mobilizationReportType.MOB_4_10_1,
                                                geti18nText('app.enum.MOB_4_10_1.12'),
                                                section.id
                                            )
                                        }
                                    >
                                        {geti18nText('app.enum.MOB_4_10_1.12') + ' - ' + section.name}
                                    </Button>
                                ) : (
                                    <Button
                                        block
                                        icon={<FileExcelOutlined />}
                                        onClick={() =>
                                            downloadSectionDocument(
                                                mobilizationReportType.MOB_4_11,
                                                geti18nText('app.enum.MOB_4_11_2.12'),
                                                section.id
                                            )
                                        }
                                    >
                                        {geti18nText('app.enum.MOB_4_11_2.12') + ' - ' + section.name}
                                    </Button>
                                )}
                            </div>
                        );
                    })}
            </Menu>
        );

        const actionsMenuMain = (
            <Menu style={{ maxHeight: '150px', overflow: 'scroll' }}>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    {activeList == 2 && (
                        <>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    block
                                    icon={<FileExcelOutlined />}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                            { type: mobilizationReportType.MOB_4_10 },
                                            geti18nText('app.enum.MOB_4_10.10') + '.xlsx'
                                        );
                                    }}
                                >
                                    {geti18nText('app.enum.MOB_4_10.10')}
                                </Button>
                            </div>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                                    <Button icon={<FileExcelOutlined />} block>
                                        {geti18nText('app.enum.MOB_4_10_1.11')}
                                        <EllipsisOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Dropdown key="more" overlay={sectionsMenu} trigger={['click']}>
                                    <Button icon={<FileExcelOutlined />} block>
                                        {geti18nText('app.enum.MOB_4_10_1.12')}
                                        <EllipsisOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        </>
                    )}
                    {activeList == 3 && (
                        <>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Button
                                    block
                                    icon={<FileExcelOutlined />}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                                            { type: mobilizationReportType.MOB_4_11 },
                                            geti18nText('app.enum.MOB_4_11.12') + '.xlsx'
                                        );
                                    }}
                                >
                                    {geti18nText('app.enum.MOB_4_11.12')}
                                </Button>
                            </div>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                                    <Button icon={<FileExcelOutlined />} block>
                                        {geti18nText('app.enum.MOB_4_11_1.12')}
                                        <EllipsisOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div style={{ display: 'block', margin: '5px' }}>
                                <Dropdown key="more" overlay={sectionsMenu} trigger={['click']}>
                                    <Button icon={<FileExcelOutlined />} block>
                                        {geti18nText('app.enum.MOB_4_11_2.12')}
                                        <EllipsisOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        </>
                    )}
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown
                    key="more"
                    overlay={activeList != 1 && activeList != 4 ? actionsMenuMain : <></>}
                    trigger={['click']}
                >
                    <Button
                        hidden={activeList == 5}
                        type="primary"
                        onClick={() => {
                            generateDocument();
                        }}
                    >
                        {geti18nText('schedule.suggestions.generate.document')}
                        {(activeList == 2 || activeList == 3) && <EllipsisOutlined />}
                    </Button>
                </Dropdown>
            </div>
        );
    };

    const generateDocument = () => {
        switch (activeList) {
            case 1:
                fileDownload(
                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                    { type: mobilizationReportType.MOB_4_9 },
                    geti18nText('app.enum.MOB_4_9.9') + '.xlsx'
                );
                break;
            // case 3:
            //     fileDownload(
            //         CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
            //         { type: mobilizationReportType.MOB_4_11 },
            //         geti18nText('app.enum.MOB_4_11.12') + '.xlsx'
            //     );
            //     break;
            case 4:
                fileDownload(
                    CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
                    { type: mobilizationReportType.MOB_4_12 },
                    geti18nText('app.enum.MOB_4_12.13') + '.xlsx'
                );
                break;
            default:
                break;
        }
    };

    const generateView = () => {
        switch (activeList) {
            case 1:
                return <TotalTable addedButtons={addedButtons} />;
            case 2:
                return <InsideCompanyTable addedButtons={addedButtons} obligated={1} />;
            case 3 /* !!! ostaviti <div>, prop se ne šalje !!! */:
                return (
                    <div>
                        <InsideCompanyTable addedButtons={addedButtons} obligated={0} />
                    </div>
                );
            case 4:
                return <OutsideCompanyTable addedButtons={addedButtons} />;
            case 5:
                return <NoWarScheduleTable addedButtons={addedButtons} />;
            default:
                return <Empty />;
        }
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                value={activeList}
                defaultValue={1}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('employee.schedule.tab.1')}
                </Radio.Button>
                <Radio.Button value={2} onChange={onChangeActiveList}>
                    {geti18nText('employee.schedule.tab.2')}
                </Radio.Button>
                <Radio.Button value={3} onChange={onChangeActiveList}>
                    {geti18nText('employee.schedule.tab.3')}
                </Radio.Button>
                <Radio.Button value={4} onChange={onChangeActiveList}>
                    {geti18nText('employee.schedule.tab.4')}
                </Radio.Button>
                <Radio.Button value={5} onChange={onChangeActiveList}>
                    {geti18nText('employee.schedule.tab.5')}
                </Radio.Button>
            </Radio.Group>
            <div>{generateView()}</div>
        </React.Fragment>
    );
};

export default EmployeeScheduleIndex;
