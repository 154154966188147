import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';

export const SortableItem = (props: any) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });

    const style: any = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Item
            className={isDragging ? 'sortable-item sortable-item-dragged' : 'sortable-item'}
            ref={setNodeRef}
            style={style}
            listeners={listeners}
            name={props.name}
            overlaps={props.overlaps ? props.overlaps.toString() : undefined}
            id={props.id}
            {...attributes}
            {...props}
        />
    );
};
