import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { setEmailFormat } from '../../../../../utils/Utils';
import RestaurantEdit from './edit';
import { Tag } from 'antd';
import { MealsRights } from '../../../../../rights/mealsRights';

const RestaurantIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    useHelper('meals/restaurant');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const columns = [
        {
            title: geti18nText('restaurant.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('restaurant.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot'),
            dataIndex: 'deliveriesSpot',
            render: (record: any, text: any) => {
                const data: any = record ? [...JSON.parse(record)] : [];
                const elements: any = [];

                if (record && data?.length > 0) {
                    data.map((element: any) => {
                        if (element.active) {
                            elements.push(
                                <Tag style={{ marginBottom: '2px', marginTop: '2px' }} key={element}>
                                    {element.name}
                                </Tag>
                            );
                        }
                    });
                }

                return elements;
            },
        },
        {
            title: geti18nText('restaurant.table.column.email'),
            dataIndex: 'email',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (record: any, text: any) => {
                const data: any = record ? [...setEmailFormat(record)] : [];
                const elements: any = [];

                if (record && data?.length > 0) {
                    data.map((element: any) => {
                        elements.push(
                            <Tag style={{ marginBottom: '2px', marginTop: '2px' }} key={element}>
                                {element}
                            </Tag>
                        );
                    });
                }

                return elements;
            },
        },
        {
            title: geti18nText('restaurant.table.column.active'),
            width: '10%',
            dataIndex: 'active',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="restaurants"
            url={CONSTANTS_REQ.RESTAURANT.LIST}
            addNewButtonText={geti18nText('restaurant.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={RestaurantEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideButtons={disabled}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.meals.restaurant')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.meals.restaurant')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default RestaurantIndex;
