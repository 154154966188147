import { useEffect, useState } from 'react';
import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyDatePicker, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ, getReportDocumentExtension } from '../../../../utils/Constants';
import { customEmployeeRenderOption, fileDownload, getDateFormat } from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import EmployeeGroupIndex from '../employee-group';
import moment from 'moment';
import { GetEnum } from '../../../../utils/Enums';
import EmployeeGroupSearch from '../employee-group/search';
import useEnum from '../../../../hooks/useEnum';
import ReportExportType from '../../../../components/report-export-type/ReportExportType';

const EmployeeAnnualLeaveReportModal = ({
    modalVisible,
    setModalVisible,
    type,
    allowExportTypeChange = false,
}: any) => {
    const enExportType = useEnum('EXPORT_TYPE');
    const [exportType, setExportType] = useState(enExportType.PDF);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [subreportTypeEnums] = useState(GetEnum({ enumName: 'SUBREPORT_TYPE' }));
    const [subreports, setSubreports] = useState([]);

    useEffect(
        function () {
            if (modalVisible) {
                getPayrollSummaryReports();
            }
        },
        [modalVisible]
    );

    const setDefaultPayrollSummaryReportFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'type',
                condition: 'equals',
                value: subreportTypeEnums[
                    type === 'generateApprovedAnnualLeaveReport'
                        ? 'APPROVED_ANNUAL_LEAVE_FORM'
                        : type === 'generateUsedAnnualLeaveSummaryReport'
                        ? 'USED_ANNUAL_LEAVE_SUMMARY_FORM'
                        : 'USED_ANNUAL_LEAVE_SPECIFICATION_FORM'
                ],
            },
        ];
    };

    const getPayrollSummaryReports = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.SUBREPORT.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultPayrollSummaryReportFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setSubreports(
                        result.data.map((report: any) => {
                            return { id: report.id, text: report.name };
                        })
                    );
                    if (result.data && result.data.length == 1 && result.data[0]) {
                        form.setFieldsValue({ subreport: { id: result.data[0].id, text: result.data[0].name } });
                    }
                }
            }
        });
    };

    const getDocumentExtension = () => {
        return getReportDocumentExtension(exportType);
    };

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                let url =
                    type === 'generateApprovedAnnualLeaveReport'
                        ? CONSTANTS_REQ.REPORT.APPROVED_ANNUAL_LEVE_REPORT
                        : type === 'generateUsedAnnualLeaveSummaryReport'
                        ? CONSTANTS_REQ.REPORT.USED_ANNUAL_LEVE_SUMMARY_REPORT
                        : CONSTANTS_REQ.REPORT.USED_ANNUAL_LEVE_SPECIFICATION_REPORT;

                let filename =
                    type === 'generateApprovedAnnualLeaveReport'
                        ? 'ApprovedAnnualLeaveReport'
                        : type === 'generateUsedAnnualLeaveSummaryReport'
                        ? 'UsedAnnualLeaveSummaryReport'
                        : 'UsedAnnualLeaveSpecificationReport';

                let parms: any = {
                    lang: NyUtils.getSelectedLanguage(),
                    useDefault: true,
                    exportType: exportType ? exportType : enExportType.PDF,
                };
                if (values.employee) {
                    parms.employeeId = values.employee.id;
                }
                if (values.employeeGroup) {
                    parms.employeeGroupId = values.employeeGroup.id;
                }
                if (type !== 'generateUsedAnnualLeaveSpecificationReport') {
                    parms.year = getDateFormat(values.year, 'YYYY');
                } else {
                    parms.dateFrom = getDateFormat(values.dateFrom);
                    parms.dateTo = getDateFormat(values.dateTo);
                }
                if (values.subreport) {
                    parms.subreportId = values.subreport.id;
                    parms.useDefault = false;
                }
                const fileDownloadName = `${filename}_${moment().format('yyyyMMDD')}` + getDocumentExtension();
                fileDownload(url, parms, fileDownloadName);
                setLoading(false);
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('employeeAnnualLeave.report.' + type)}
                    okText={geti18nText('employeeAnnualLeave.report.generate')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                        setSubreports([]);
                    }}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        {allowExportTypeChange && (
                            <ReportExportType exportType={exportType} setExportType={setExportType} />
                        )}
                        <Row gutter={24}>
                            {type !== 'generateUsedAnnualLeaveSpecificationReport' && (
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employeeAnnualLeave.report.year')}
                                        name="year"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            mode="year"
                                            picker="year"
                                            format="YYYY"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {type === 'generateUsedAnnualLeaveSpecificationReport' && (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('employeeAnnualLeave.report.dateFrom')}
                                            name="dateFrom"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('employeeAnnualLeave.report.dateTo')}
                                            name="dateTo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <EmployeeGroupSearch
                                    label={geti18nText('employeeAnnualLeave.report.employeeGroup')}
                                    name={'employeeGroup'}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('employeeAnnualLeave.report.employee')} name="employee">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="anual-leave-report"/>}
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employeeAnnualLeave.report.subreportId')}
                                    name="subreport"
                                >
                                    <NySearchField
                                        options={subreports}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        className=""
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default EmployeeAnnualLeaveReportModal;
