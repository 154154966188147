import { NyRequestResolver, NySession, NyUtils } from '@nybble/nyreact';
import { Base64 } from 'js-base64';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import { RootState } from '../../rootReducer';
import { getDashboardWidgetsForUser } from '../../slices/dashboardSlice';
import { getDashboardWidgetsForUserHR } from '../../slices/dashboardHRslice';
import { clearHistory } from '../../slices/historySlice';
import { setProp } from '../../slices/propSlice';
import {
    getLoginRedirectCustomUrlFromStorage,
    getLoginRedirectFromStorage,
    getLoginRedirectTabFromStorage,
} from '../../utils/Utils';
import { CONSTANTS_REQ } from '../../utils/Constants';

const Login = () => {
    // const { active } = useSelector((state: RootState) => state.tabs);
    const { links } = useSelector((state: RootState) => state.history);

    let { search } = useLocation();
    const history = useHistory();
    const query: any = new URLSearchParams(search);
    const dispatch = useDispatch();

    const getRedirectUrl = () => {
        const loginRedirectCustomUrl = getLoginRedirectCustomUrlFromStorage();
        const loginRedirectUrl = getLoginRedirectFromStorage();
        const loginRedirectTab = getLoginRedirectTabFromStorage();
        if (loginRedirectCustomUrl) {
            history.push({ pathname: loginRedirectCustomUrl });
        } else if (loginRedirectUrl) {
            const values = JSON.parse(loginRedirectUrl);
            if (values && values[0]) {
                if (values[0].queryData) {
                    history.push({ pathname: '/approval/data=' + values[0].queryData });
                } else {
                    if (values[0].propValue) {
                        dispatch(setProp(values[0].propValue));
                    }
                    const url = values[0].returnType ? values[0].returnType : '/';
                    const id = values[0].id ? values[0].id : null;
                    history.push({ pathname: url, state: { openModal: true, id: id } });
                }
            }
        } else if (loginRedirectTab) {
            const values = JSON.parse(loginRedirectTab);
            if (values) {
                history.push({ pathname: '/open/data=' + values });
            } else {
                history.push('/');
            }
        } else {
            dispatch(setProp(1));
            history.push('/');
        }
    };

    useEffect(() => {
        if (query.get('auth') != undefined) {
            const auth = JSON.parse(Base64.decode(query.get('auth')));
            let session: any = NyUtils.loadSession();
            session.auth = auth.auth;
            session.user = auth.user;
            NyUtils.saveSession(session);
            if (auth.user && (!auth.user.roles || !session.user.roles.length)) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.USER_AUTH.ROLES).then((result: any) => {
                    session.user.roles = result;
                    NyUtils.saveSession(session);
                });
            }
            dispatch(getDashboardWidgetsForUser());
            dispatch(getDashboardWidgetsForUserHR());
            setTimeout(() => {
                getRedirectUrl();
            });
        } else {
            const object = {
                applicationName: NySession.getSetting('application-name'),
                redirectUrl: window.location.href,
                applicationKey: NySession.getSetting('application-key'),
            };

            const timeout = setTimeout(() => {
                dispatch(clearHistory());
                window.location.href =
                    NySession.getSetting('nyauth') + `#/login?auth=${Base64.encodeURI(JSON.stringify(object))}`;
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, []);

    if (NySession.isUserAuthenticated() && links && links[0]?.path && !query.get('tenantSwitching')) {
        return <Redirect to={links[0].path} />;
    } else {
        return (
            <div className="spin-container">
                <div className="spin">
                    <div className="ant-spin ant-spin-lg ant-spin-spinning centered">
                        <span className="ant-spin-dot ant-spin-dot-spin">
                            <i className="ant-spin-dot-item"></i>
                            <i className="ant-spin-dot-item"></i>
                            <i className="ant-spin-dot-item"></i>
                            <i className="ant-spin-dot-item"></i>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
};

export default Login;
