import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataEdit,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row, Tabs } from 'antd';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { errorNotification, getEnumArray, getEnumBooleanArray, okNotification } from '../../../../../utils/Utils';
import ObjectMetadataIndex from '../../../../object-metadata';
import ObjectMetadataEdit from '../../../../object-metadata/edit';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';

const { TabPane } = Tabs;

const AssetTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('assetType.edit.new'));
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [activeKey, setAcitveKey] = useState('1');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [hasSelected, setHasSelected] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const table = useTableSettings();
    const enMetadataType = useEnum('METADATA_TYPE');

    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const [formModal] = Form.useForm();

    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AssetRights.canCreateCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('assetType.edit.title') + ' - ' + dataForm.id);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setAcitveKey('1');
        setEditHeader(geti18nText('assetType.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'assetType.id', condition: 'equals', value: form.getFieldValue('id') },
            { field: 'active', condition: 'equals_bool', value: 1 },
        ];
    };

    const columns = [
        {
            title: geti18nText('objectMetadata.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('objectMetadata.table.column.name'),
            dataIndex: ['objectMetadata', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('objectMetadata.table.column.dataType'),
            dataIndex: ['objectMetadata', 'dataType'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArray('OBJECT_METADATA_TYPE')),
            render: (text: any, recrod: any) => {
                if (recrod.objectMetadata.dataType) {
                    return geti18nText('app.enum.OBJECT_METADATA_TYPE.' + recrod.objectMetadata.dataType);
                }
            },
        },
        {
            title: geti18nText('objectMetadata.table.column.mandatory'),
            dataIndex: ['objectMetadata', 'mandatory'],
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.objectMetadata.mandatory) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('objectMetadata.table.column.format'),
            dataIndex: ['objectMetadata', 'pattern'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('objectMetadata.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue,
        },
    ];

    const deleteAttributes = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_TYPE.DELETE_METADATA, undefined, {
            assetType: { id: form.getFieldValue('id') },
            objectMetadataIds: selectedValues,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeys) {
                    clearKeys();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification();
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '2' && canCreate() && (
                <div style={{ float: 'left' }}>
                    <Button onClick={() => setIsModalVisible(true)} icon={<PlusOutlined />}>
                        {geti18nText('app.default.button.add')}
                    </Button>
                    <NyModalConfirm
                        title={geti18nText('assetType.modal.deleteAttributes.confirm')}
                        onConfirm={() => {
                            deleteAttributes();
                        }}
                    >
                        <Button style={{ marginLeft: '5px' }} disabled={!hasSelected} danger icon={<DeleteOutlined />}>
                            {geti18nText('assetType.modal.deleteAttributes')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const save = (e: any) => {
        e.preventDefault();
        setLoadingModal(true);
        formModal
            .validateFields()
            .then((values: any) => {
                const attributeIds = values.attributes.map((item: any) => item.id);
                NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_TYPE.ADD_METADATA, undefined, {
                    assetType: { id: form.getFieldValue('id') },
                    objectMetadataIds: attributeIds,
                }).then((result: any) => {
                    if (setLoadingModal) setLoadingModal(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        setIsModalVisible(false);
                        setRefreshTable((refreshTable) => refreshTable + 1);
                        formModal.resetFields();
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoadingModal) setLoadingModal(false);
            });
    };

    let clearKeys: any;
    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ASSET_TYPE.EDIT}
            setValues={setValues}
            setIsCreate={setIsCreate}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={false}
            customFooterContent={getCustomFooterContent}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
        >
            <Tabs defaultActiveKey={activeKey} onChange={(key) => setAcitveKey(key)} type="card">
                <TabPane tab={geti18nText('assetType.tab.1')} key="1">
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('assetType.edit.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('assetType.tab.2')} key="2" disabled={isCreate}>
                    <NyDataTable
                        nyId="webshop-asset-type-metadata"
                        rowKey="objectMetadataId"
                        url={CONSTANTS_REQ.ASSET_TYPE.METADATA}
                        columns={columns}
                        setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                        scroll={props?.editProps?.scroll}
                        hideNewButton
                        showRowSelection={canCreate()}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        onRowSelect={() => null}
                        rowSelectionType={'checkbox'}
                        rowSelectionModal={setSelectedValuesFunc as any}
                        setDefaultFilterValue={setDefaultFilterValue}
                        shortcuts={true}
                        fetchWhenChange={refreshTable}
                    />
                    {isModalVisible && (
                        <Modal
                            title={geti18nText('assetType.modal.attributes')}
                            visible={isModalVisible}
                            okText={geti18nText('app.default.button.save')}
                            onOk={(e) => save(e)}
                            confirmLoading={loadingModal}
                            maskClosable={false}
                            onCancel={() => {
                                formModal.resetFields();
                                setIsModalVisible(false);
                            }}
                        >
                            <Form
                                layout="vertical"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                form={formModal}
                                onFinishFailed={({ errorFields }) => {
                                    form.scrollToField(errorFields[0].name);
                                }}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label={geti18nText('assetType.edit.attributes')} name={'attributes'}>
                                            <NySearchField
                                                url={CONSTANTS_REQ.OBJECT_METADATA.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                mode={'multiple'}
                                                autoFocus={true}
                                                SearchPopupComponent={
                                                    <ObjectMetadataIndex metadataType={enMetadataType.ASSET_TYPE} />
                                                }
                                                AddNewModalComponent={ObjectMetadataEdit}
                                                AddNewModalComponentAddedData={{
                                                    metadataType: enMetadataType.ASSET_TYPE,
                                                }}
                                                addedFilter={{
                                                    field: 'metadataType',
                                                    condition: 'equals',
                                                    value: enMetadataType.ASSET_TYPE,
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default AssetTypeEdit;
