import { NyDataEdit, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TextArea from 'antd/lib/input/TextArea';
import { getEnumArray, getEnumFormat, setEnumFormat, setSearchFormat } from '../../../../utils/Utils';
import HumanCodebooksSearch from '../../../../components/human-codebooks/search';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const QualificationLevelEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('qualification.level.edit.new'));
    const [loading, setLoading] = useState(false);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    useEffect(() => {
        if (props?.editProps) {
            setValues();
        }
    }, []);

    function setValues(dataForm?: any) {
        let filteredMergedData: any = dataForm ? dataForm : {};

        let addedValues = props?.addedData?.form?.getFieldsValue()
            ? props?.addedData?.form?.getFieldsValue()
            : props?.editProps?.form?.getFieldsValue();

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (addedValues?.qualificationLevelGroup) {
            filteredMergedData.qualificationLevelGroup = setSearchFormat(
                addedValues.qualificationLevelGroup,
                'name',
                'name',
                'code'
            );
        }

        if (filteredMergedData?.type) {
            filteredMergedData.type = setEnumFormat('QUALIFICATION_LEVEL_TYPE', filteredMergedData.type);
        }

        if (filteredMergedData?.hasOwnProperty('name')) {
            setEditHeader(geti18nText('qualification.level.tab.qualification') + ' - ' + filteredMergedData.name);
        }

        delete filteredMergedData?.active;
        form.setFieldsValue(filteredMergedData);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('menu.professional.qualification'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.QUALIFICATION_LEVEL.EDIT}
            setValues={setValues}
            width={820}
            form={form}
            onBeforeSave={(e: any) => {
                let filteredForm: any = {};
                const formData = { ...form.getFieldsValue() };
                Object.keys(formData).forEach((key: any) => {
                    if (key != 'name') {
                        filteredForm[key] = formData[key];
                    }
                });

                props?.addedData?.setMainFormValues({ ...filteredForm }, true);
                props?.editProps?.setMainFormValues({ ...filteredForm }, true);
            }}
            goBack={() => history.goBack()}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            {...props}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.type) values.type = getEnumFormat(values.type);
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('qualification.level.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('qualification.level.edit.qualification')}
                        name="qualification"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('qualification.level.edit.ects')}
                        name="ects"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('qualification.level.edit.level')}
                        name="level"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('qualification.level.edit.type')} name={'type'}>
                        <NySearchField
                            options={getEnumArray('QUALIFICATION_LEVEL_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            className=""
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <HumanCodebooksSearch
                        label={geti18nText('qualification.level.edit.qualificationLevelGroup')}
                        codebooksType={enCodebookType.QUALIFICATION_LEVEL_GROUP}
                        formItemName={'qualificationLevelGroup'}
                        customItemNameLabel={'name'}
                        onChange={(e: any) => {
                            props?.addedData?.setQualificationLevelGroup &&
                                props?.addedData?.setQualificationLevelGroup(e);
                        }}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={geti18nText('qualification.level.edit.description')} name="description">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default QualificationLevelEdit;
