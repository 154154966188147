import { CarOutlined, CheckCircleOutlined, InfoCircleOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Alert, Badge, Card, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TravelWarrantRights } from '../../../rights/travelWarrantRights';
import { RootState } from '../../../rootReducer';
import { setProp } from '../../../slices/propSlice';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType } from '../../../utils/Utils';

const { Title } = Typography;

const TravelWarrantPersonalLightWidget = ({ setWizard }: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const widgetColor = getColorFromNotificationType(settings, 'TRAVEL_WARRANT_APPROVAL');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrant();
    };

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(
        CONSTANTS_REQ.DASHBOARD.GET_STATISTICS,
        undefined,
        { keys: ['myTravelWarrantRequested', 'myTravelWarrantsApproved', 'myTravelWarrantsConfirmed'] },
        true,
        false,
        {
            myTravelWarrantRequested: 0,
            myTravelWarrantsApproved: -1,
            myTravelWarrantsConfirmed: -1,
        }
    );

    // const addNewTab = (i18n: string, path: string) => {
    //     if (path && path !== active) {
    //         // const panes = tabs.filter((item: any) => item.path === path);
    //         // if (panes.length === 0) {
    //         //     dispatch(addTab({ title: i18n, path: path, color: widgetColor }));
    //         // } else {
    //         dispatch(setActiveTab(path));
    //         // }
    //     }
    // };
    //
    // const openTab = (propValue: any, tabName: any, tabUrl: any) => {
    //     dispatch(setProp(propValue + 1));
    //     addNewTab(tabName, tabUrl);
    // };

    const gridStyle: any = {
        width: '25%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '14px',
        paddingTop: '4px',
        height: '100%',
        display: 'inline-block',
    };

    return (
        <Card
            className="ny-widget-card"
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            title={geti18nText('menu.travelWarrant.personal')}
            bodyStyle={{ height: '45%', overflowY: 'hidden', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
            type="inner"
            extra={
                <>
                    {canCreate() && (
                        <Tooltip placement="top" title={geti18nText('travelWarrantPersonal.table.add')}>
                            <CarOutlined
                                className="ny-card-icon"
                                onClick={() => {
                                    setWizard
                                        ? setWizard(true)
                                        : history.push({
                                              pathname: '/travel-warrant/travelWarrant',
                                              state: { openModal: true },
                                          });
                                }}
                            />
                        </Tooltip>
                    )}
                </>
            }
        >
            {loading ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : error ||
              (statisticData != null &&
                  statisticData['myTravelWarrantRequested'] == -1 &&
                  statisticData['myTravelWarrantsApproved'] == -1 &&
                  statisticData['myTravelWarrantsConfirmed'] == -1) ? (
                <div style={{ paddingTop: 10 }}>
                    <Alert
                        className="ny-dashboard-light-empty"
                        icon={<InfoCircleOutlined style={{ color: `${widgetColor}` }} />}
                        showIcon
                        style={{ background: `${widgetColor}11` }}
                        description={
                            <>
                                <Title level={5}>{geti18nText('app.default.nodata')}</Title>
                            </>
                        }
                    />
                </div>
            ) : (
                <>
                    {statisticData != null && statisticData['myTravelWarrantRequested'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travelWarrantPersonal.requests')}>
                                <Badge count={statisticData['myTravelWarrantRequested']} showZero size="small">
                                    <CarOutlined
                                        style={{ fontSize: '2em' }}
                                        className="statistic-card-light"
                                        onClick={() => {
                                            dispatch(setProp(1));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {statisticData != null && statisticData['myTravelWarrantsApproved'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travelWarrantPersonal.approved')}>
                                <Badge count={statisticData['myTravelWarrantsApproved']} showZero size="small">
                                    <CheckCircleOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => {
                                            dispatch(setProp(1));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {statisticData != null && statisticData['myTravelWarrantsConfirmed'] >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travelWarrantPersonal.confirmed')}>
                                <Badge count={statisticData['myTravelWarrantsConfirmed']} showZero size="small">
                                    <IssuesCloseOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => {
                                            dispatch(setProp(1));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

export default TravelWarrantPersonalLightWidget;
