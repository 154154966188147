import Icon, { DeleteOutlined, FileJpgOutlined } from '@ant-design/icons';
import { NyModalConfirm, NySpinner, geti18nText } from '@nybble/nyreact';
import { Card, Col, Divider, List, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { checkIsFileImage } from '../Utils';

const CustomView = ({
    files,
    type,
    items,
    setItems,
    loading,
    setLoading,
    refreshFiles,
    downloadFileCustom,
    datePadding,
    canDelete,
    onPreview,
    dividerTitle = null,
    removeFilesCustomView,
    fontSize = '20px',
    printPdf = false,
}: any) => {
    useEffect(() => {
        if (type != 'default') {
            setLoading(true);
            setItems([]);
            if (files && files.length > 0) {
                if (type == 'listNamedExpense') {
                    let listNamedExpense = files.filter((file: any) => file && file.travelWarrantExpense);
                    if (listNamedExpense.length > 0) {
                        setItems(listNamedExpense);
                    }
                }
                if (type == 'listDefaultExpense') {
                    let listDefaultExpense = files.filter((file: any) => file && file.travelWarrantAdditionalInfo);
                    if (listDefaultExpense.length > 0) {
                        setItems(listDefaultExpense);
                    }
                }
                if (type == 'undefinedExpense') {
                    let undefinedExpense = files.filter(
                        (file: any) => file && !file.travelWarrantAdditionalInfo && !file.travelWarrantExpense
                    );
                    if (undefinedExpense.length > 0) {
                        setItems(undefinedExpense);
                    }
                }
            }
            setLoading(false);
        }
    }, [refreshFiles, files]);

    const getSmallView = (item: any) => {
        return (
            <>
                <Col span={11} style={{ textAlign: 'right' }}>
                    <Row style={{ float: 'right' }}>
                        <span
                            style={{
                                position: 'relative',
                                textAlign: 'right',
                                fontSize: '12px',
                            }}
                        >
                            {moment(item.uploaded).format('DD.MM.YYYY HH:mm:ss')}
                        </span>
                    </Row>
                    {(checkIsFileImage(item) || canDelete(item)) && !printPdf && (
                        <Row style={{ float: 'right', marginTop: '5px', paddingRight: '10px' }}>
                            {checkIsFileImage(item) && (
                                <>
                                    <FileJpgOutlined
                                        onClick={() => onPreview(item)}
                                        style={{
                                            paddingRight: '5px',
                                            fontSize: fontSize,
                                        }}
                                    />
                                </>
                            )}
                            {canDelete(item) && !printPdf && (
                                <>
                                    <Icon
                                        style={{
                                            fontSize: fontSize,
                                            color: 'red',
                                        }}
                                        type="delete"
                                    />
                                    <NyModalConfirm
                                        title={geti18nText('app.default.delete.file.confirm')}
                                        onConfirm={() => {
                                            removeFilesCustomView(item);
                                        }}
                                    >
                                        <Tooltip title={geti18nText('app.default.remove')}>
                                            <DeleteOutlined
                                                style={{
                                                    fontSize: fontSize,
                                                    color: 'red',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </Tooltip>
                                    </NyModalConfirm>
                                </>
                            )}
                        </Row>
                    )}
                </Col>
            </>
        );
    };

    const getView = (item: any) => {
        return (
            <>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <span
                        style={{
                            position: 'relative',
                            top: '22px',
                            textAlign: 'right',

                            fontSize: '12px',
                        }}
                    >
                        {moment(item.uploaded).format('DD.MM.YYYY HH:mm:ss')}
                    </span>
                </Col>

                {(checkIsFileImage(item) || canDelete(item)) && !printPdf && (
                    <Col span={5} style={{ textAlign: 'right' }}>
                        {checkIsFileImage(item) && (
                            <>
                                <FileJpgOutlined
                                    onClick={() => onPreview(item)}
                                    style={{
                                        position: 'relative',
                                        top: '23px',
                                        paddingRight: '15%',
                                        fontSize: fontSize,
                                    }}
                                />
                            </>
                        )}
                        {canDelete(item) && !printPdf && (
                            <>
                                <Icon
                                    style={{
                                        fontSize: fontSize,
                                        color: 'red',
                                    }}
                                    type="delete"
                                />
                                <NyModalConfirm
                                    title={geti18nText('app.default.delete.file.confirm')}
                                    onConfirm={() => {
                                        removeFilesCustomView(item);
                                    }}
                                >
                                    <Tooltip title={geti18nText('app.default.remove')}>
                                        <DeleteOutlined
                                            style={{
                                                position: 'relative',
                                                top: '23px',
                                                fontSize: fontSize,
                                                color: 'red',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                </NyModalConfirm>
                            </>
                        )}
                    </Col>
                )}
            </>
        );
    };

    return (
        <>
            {items && items.length > 0 ? (
                <>
                    {dividerTitle && <Divider orientation="left">{dividerTitle}</Divider>}
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <List
                            grid={{
                                gutter: 6,
                                column: 4,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 2,
                                xl: 3,
                                xxl: 3,
                            }}
                            dataSource={items}
                            renderItem={(item: any, index) => (
                                <Col span={24}>
                                    <List.Item
                                        key={index}
                                        style={{
                                            border: '0.5px solid #80808054',
                                            borderRadius: '3px',
                                        }}
                                    >
                                        <Tooltip
                                            placement="topLeft"
                                            overlayStyle={{ fontSize: '14px', maxWidth: '420px' }}
                                            title={[
                                                item && item.name && item.name,
                                                <br />,
                                                item &&
                                                    item.person &&
                                                    item.person.firstName &&
                                                    item.person.lastName &&
                                                    item.person.firstName + ' ' + item.person.lastName,
                                                <br />,
                                                item && item.description && item.description,
                                            ]}
                                        >
                                            <Card
                                                id="draggable"
                                                className="download-card"
                                                style={{
                                                    minHeight: '92px',
                                                }}
                                            >
                                                <Row justify="space-around">
                                                    <>
                                                        <Col span={9}>
                                                            <div
                                                                onClick={() => {
                                                                    downloadFileCustom(item);
                                                                }}
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    position: 'relative',
                                                                    top: datePadding ? '0px' : '23px',
                                                                    cursor: 'pointer',
                                                                    wordWrap: 'break-word',
                                                                    color: '#178efc',
                                                                }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        </Col>
                                                        {datePadding ? getSmallView(item) : getView(item)}
                                                    </>
                                                </Row>
                                            </Card>
                                        </Tooltip>
                                    </List.Item>
                                </Col>
                            )}
                        />
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default CustomView;
