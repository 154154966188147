import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Input, Modal, Row, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getBase64, onKeyDownTextAreaCustom, warningNotification } from '../Utils';

const FileEdit = ({
    title = geti18nText('webshopServices.addFile'),
    okText = geti18nText('app.default.button.save'),
    deleteText = geti18nText('app.default.remove'),
    width = 700,
    visible,
    setVisible,
    file,
    setFile,
    files,
    setFiles,
    note,
    accept = '*',
    requiredAllFields = false,
    listType = 'text', //picture
    refId,
    uploadFile,
    updateFile,
    removeFile,
    canDelete,
    hasNoRefId = false,
    customUploadButton,
    customUploadButtonTitle,
    uploadType,
    setUploadType,
    employee,
    allowedFileSize,
    fontSize,
    isOrganizationModal = false,
    travelWarrantAdditionalData = [],
}: any) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [additionalDataList, setAdditionalDataList] = useState([]);

    const getFileName = (file: any) => {
        let lastDotIndex = file.name.lastIndexOf('.');
        let baseName = lastDotIndex !== -1 ? file.name.substring(0, lastDotIndex) : file.name;
        return baseName;
    };

    const setFileName = (file: any) => {
        let baseName = form.getFieldValue(['name']) ?? file.name;
        let lastDotIndex = file.name.lastIndexOf('.');
        let extension = lastDotIndex !== -1 ? file.name.substring(lastDotIndex) : '';
        return baseName.concat(extension);
    };

    useEffect(() => {
        if (travelWarrantAdditionalData?.length > 0) {
            const newData = travelWarrantAdditionalData.map((item: any) => {
                return { id: item.id, text: item.destination };
            });
            setAdditionalDataList(newData);
            if (!file) {
                form.setFieldsValue({ travelWarrantAdditionalInfo: newData[0] });
            }
        }
    }, [travelWarrantAdditionalData]);

    useEffect(() => {
        if (file) {
            form.setFieldsValue({ name: getFileName(file) });
            form.setFieldsValue({ description: file.description });
            if (isOrganizationModal && travelWarrantAdditionalData?.length > 0) {
                if (file?.travelWarrantAdditionalInfo?.destination && file?.travelWarrantAdditionalInfo?.id) {
                    form.setFieldsValue({
                        travelWarrantAdditionalInfo: {
                            id: file?.travelWarrantAdditionalInfo?.id,
                            text: file?.travelWarrantAdditionalInfo?.destination,
                        },
                    });
                } else if (travelWarrantAdditionalData?.length > 0) {
                    const index = travelWarrantAdditionalData.findIndex(
                        (row: any) => row.id == file?.travelWarrantAdditionalInfo?.id
                    );
                    form.setFieldsValue({
                        travelWarrantAdditionalInfo: {
                            id: travelWarrantAdditionalData[index]?.id,
                            text: travelWarrantAdditionalData[index]?.destination,
                        },
                    });
                }
            }
        }
    }, [file]);

    const onClose = () => {
        setVisible(false);
        setFileList([]);
        setFile(null);
        form.resetFields();
        setUploadType(null);
    };

    const deleteFile = () => {
        removeFile(file);
        onClose();
    };

    const addFile = (newFile: any) => {
        if (refId || hasNoRefId) {
            if (newFile.fileId) {
                updateFile(file);
            } else {
                if (isOrganizationModal) {
                    uploadFile(newFile?.travelWarrantAdditionalInfo?.id, [newFile]);
                } else {
                    uploadFile(refId, [newFile]);
                }
            }
        } else {
            const newData = [...files];
            const index = newData.findIndex((tempFile: any) => tempFile.id == newFile.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...newFile });
                setFiles(newData);
            } else {
                setFiles((files: any) => [...files, newFile]);
            }
        }
        onClose();
    };

    const save = () => {
        form.validateFields()
            .then(async (values: any) => {
                if (
                    isOrganizationModal &&
                    travelWarrantAdditionalData?.length > 0 &&
                    (file?.travelWarrantAdditionalInfo?.id || !file) &&
                    (!values?.travelWarrantAdditionalInfo ||
                        !values?.travelWarrantAdditionalInfo?.id ||
                        values?.travelWarrantAdditionalInfo?.id == -1)
                ) {
                    warningNotification(geti18nText('upload.file.organization.destination.not.selected'));
                } else {
                    if (file) {
                        file.name = setFileName(file);
                        file.description = values.description ?? file.description;
                        if (isOrganizationModal && travelWarrantAdditionalData?.length > 0) {
                            file.travelWarrantAdditionalInfo = { id: values?.travelWarrantAdditionalInfo?.id };
                        }
                        if (!file.fileId && file?.file?.id) {
                            file.fileId = file.file.id;
                        }
                        addFile(file);
                    } else if (fileList && fileList[0]) {
                        let newFile: any = {};
                        newFile.id = fileList[0].id ?? Math.floor(Math.random() * -1000000);
                        newFile.uploaded = moment.now();
                        newFile.active = true;
                        newFile.name = setFileName(fileList[0]);
                        newFile.description = values.description ?? fileList[0].description;
                        newFile.documentType = uploadType;
                        if (isOrganizationModal && travelWarrantAdditionalData?.length > 0) {
                            newFile.travelWarrantAdditionalInfo = { id: values?.travelWarrantAdditionalInfo?.id };
                        }
                        if (employee && employee.person) {
                            newFile.employee = employee;
                            newFile.person = employee.person;
                        }
                        newFile.file = newFile.file ?? fileList[0].file ?? fileList[0];
                        let base64String: any = '';
                        if (newFile.file) {
                            base64String = await getBase64(newFile.file);
                        }
                        newFile.thumbUrl = base64String;
                        newFile.url = base64String;

                        addFile(newFile);
                    } else {
                        warningNotification(geti18nText('upload.file.not.selected'));
                    }
                }
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo);
            });
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: accept,
        onRemove: (file: any) => {
            setFileList((fileList: any) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        beforeUpload: (file: any) => {
            if (allowedFileSize && file && file.size > allowedFileSize) {
                warningNotification(geti18nText('upload.file.size.too.big'));
            } else {
                form.setFieldsValue({ name: getFileName(file) });
                setFileList([...fileList, file]);
            }
            return false;
        },
        fileList,
        showUploadList: {
            showDownloadIcon: false,
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined style={{ color: 'red', fontSize: fontSize }} />,
        },
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
    };
    const uploadButton = (
        <Button icon={<UploadOutlined />}>
            <span style={{ fontWeight: '500' }}>
                {customUploadButtonTitle ?? geti18nText('webshopServices.addFile')}
            </span>
        </Button>
    );

    const getCustomUpload = () => {
        return customUploadButton.map((upload: any) => {
            return (
                <>
                    <Button
                        icon={<UploadOutlined />}
                        onClick={() => {
                            setUploadType(upload.documentTypeValue);
                            //  uploadButton.current.click();
                        }}
                    >
                        {upload.label}
                    </Button>
                </>
            );
        });
    };

    return (
        <>
            <Modal
                title={file ? file.name : title}
                visible={visible}
                width={width}
                onCancel={onClose}
                maskClosable={false}
                footer={
                    <>
                        <Button onClick={onClose}>{geti18nText('app.default.button.cancel')}</Button>
                        {file && canDelete(file) && (
                            <Button type="primary" danger onClick={deleteFile}>
                                {deleteText}
                            </Button>
                        )}
                        <Button type="primary" onClick={save}>
                            {okText}
                        </Button>
                    </>
                }
            >
                <Form form={form} component={false} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    {note && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Alert
                                    showIcon
                                    type="warning"
                                    message={note}
                                    style={{
                                        fontSize: '9px!important',
                                        marginBottom: '5px',
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="user" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="fileId" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="date" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                style={file ? { display: 'none' } : { fontWeight: '500' }}
                                label={geti18nText('travelWarrantFiles.table.column.file.name')}
                                name="file"
                                rules={[
                                    {
                                        required: !file,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Upload listType={listType} {...uploadProps}>
                                    {fileList?.length >= 1
                                        ? null
                                        : customUploadButton
                                        ? getCustomUpload()
                                        : uploadButton}
                                    {/* {fileList && fileList.length >= 1 ? null : uploadButton} */}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={'Naziv'}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                                style={{ fontWeight: '500' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    {isOrganizationModal &&
                        travelWarrantAdditionalData?.length > 0 &&
                        (file?.travelWarrantAdditionalInfo?.id || !file) && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={'Destinacija'}
                                        name="travelWarrantAdditionalInfo"
                                        rules={[
                                            {
                                                required: isOrganizationModal,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        style={{ fontWeight: '500' }}
                                    >
                                        <NySearchField
                                            options={additionalDataList}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            className=""
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantFiles.table.column.description')}
                                name="description"
                                rules={[
                                    {
                                        required: requiredAllFields,
                                        message: geti18nText('app.default.required'),
                                        whitespace: requiredAllFields,
                                    },
                                ]}
                                style={{ fontWeight: '500' }}
                            >
                                <TextArea autoSize={{ minRows: 4, maxRows: 8 }} onKeyDown={onKeyDownTextAreaCustom} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default FileEdit;
