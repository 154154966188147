import { EyeInvisibleOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { NyDataEdit, NyDatePicker, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NyCalendar from '../../../../components/calendar';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { updateHistoryI18n } from '../../../../slices/historySlice';
// import { removeTab, updateTabTitle } from '../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, getEnumArray, getEnumFormat, setDateFormat, setEnumFormat } from '../../../../utils/Utils';
import { Item } from './Item';
import { SortableItem } from './SortableItem';

const { Panel } = Collapse;

const GeneralEditTab = ({
    props,
    setEditHeader,
    setDataForm,
    setLoading,
    loading,
    setIsCreate,
    setIsFormChange,
}: any) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();

    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [bottomCollapseActiveKeys, setBottomCollapseActiveKeys] = useState<any>(['3']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);

    const [maxShiftKey, setMaxShiftKey] = useState<any>(6);
    const [activeId, setActiveId] = useState(null);
    const [activeName, setActiveName] = useState('');
    const [activeColor, setActiveColor] = useState('#d9d9d9');
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const [shiftData, setShiftData] = useState<any>([]);
    const [shiftType, setShiftType] = useState<number>();
    const [shiftStartDate, setShiftStartDate] = useState<any>(undefined);
    const [title, setTitle] = useState('');

    useEffect(function () {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    useEffect(
        function () {
            if (id === 'create') {
                setShiftData([]);
            }
        },
        [id]
    );

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    async function checkOverlap(shifts: any, shiftType: number | undefined) {
        let shiftList: any = [];
        shifts.forEach((element: any) => {
            shiftList.push(element.shift_id);
        });

        if (shiftType && shiftList.length > 0) {
            const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_GROUP.CHECK_OVERLAP, {
                shiftList: shiftList,
                shiftType: shiftType,
            });
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    let shiftOverlapList = result.data;
                    let shiftList: any = [...shifts];
                    if (shiftOverlapList) {
                        shiftOverlapList.forEach((element: any, index: any) => {
                            element.shift_id = element.id;
                            element.id = shifts[index].id;
                            shiftList[index] = element;
                        });
                    }
                    setShiftData(shiftList);
                }
            }
        }
    }

    /// FORM DATA
    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);

        if (dataForm.name) setTitle(dataForm.name);

        if (dataForm.shift_start_date) {
            dataForm.shift_start_date = setDateFormat(dataForm.shift_start_date);
            setShiftStartDate(setDateFormat(dataForm.shift_start_date));
        }

        let shiftList: any = [];
        let index = 1;
        if (dataForm.shiftList) {
            dataForm.shiftList.forEach((element: any) => {
                let temp = element;
                temp.shift_id = temp.id;
                temp.id = index;
                shiftList.push(temp);
                index++;
            });
            checkOverlap(shiftList, dataForm.shift_type);
            //setShiftData(shiftList);
        }
        setMaxShiftKey(index);

        if (dataForm.shift_type) {
            setShiftType(dataForm.shift_type);
            dataForm.shift_type = setEnumFormat('EMPLOYEE_GROUP_SHIFT_TYPE', dataForm.shift_type);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    /// END FORM DATA

    /// COLLAPSE
    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
            setBottomCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
            setBottomCollapseActiveKeys(['3']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );
    /// END COLLAPSE

    /// BUTTONS ACTION
    const addNewShift = () => (
        <Tooltip title={geti18nText('employeeGroup.edit.addNewShift')} placement="topRight">
            <PlusCircleOutlined
                className="ny-note-modal-icon"
                onClick={(event: any) => {
                    event.stopPropagation();
                    setShiftData([
                        ...shiftData,
                        {
                            id: maxShiftKey,
                        },
                    ]);
                    setMaxShiftKey(maxShiftKey + 1);
                }}
            />
        </Tooltip>
    );

    const handleChangeShift = (shift: any, id: any) => {
        let newData = [...shiftData];
        const index = shiftData.findIndex((item: any) => item.id === id);
        if (index >= 0) {
            newData[index].shift_id = shift.id;
            newData[index].name = shift.name;
        }
        setShiftData(newData);
        checkOverlap(newData, shiftType);
    };

    const handleDeleteShift = (id: any) => {
        let newData = shiftData.filter((row: any) => row.id !== id);
        setShiftData(newData);
        checkOverlap(newData, shiftType);
    };

    const handleDragStart = (event: any) => {
        const { active } = event;

        setActiveId(active.id);
        const index = shiftData.findIndex((item: any) => item.id === active.id);
        setActiveName(shiftData[index].name);
        setActiveColor(shiftData[index].color);
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = shiftData.findIndex((item: any) => item.id === active.id);
            const newIndex = shiftData.findIndex((item: any) => item.id === over.id);

            let sorted = arrayMove(shiftData, oldIndex, newIndex);
            checkOverlap(sorted, shiftType);
            setShiftData(sorted);
        }

        setActiveId(null);
        setActiveName('');
        setActiveColor('#d9d9d9');
    };

    /// END BUTTONS ACTION

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.EMPLOYEE_GROUP.EDIT}
            setValues={setValues}
            width={1200}
            setIsCreate={setIsCreate}
            fetchWhenChange={id}
            form={form}
            goBack={() => {
                history.goBack();
                // dispatch(removeTab(location.pathname));
            }}
            onSaveAndGetID={() => {
                history.goBack();
                // dispatch(removeTab(location.pathname));
            }}
            paramsId={id}
            buttonsClassName="buttons-sticky"
            changeCollapseVisibility={changeCollapseVisibility}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            customFooterContent={getCustomFooterContent}
            checkIsFormChanged={true}
            setIsFormChange={setIsFormChange}
            {...props}
            normalize={(values: any) => {
                if (values.shift_type) {
                    setShiftType(values.shift_type);
                    values.shift_type = getEnumFormat(values.shift_type);
                }
                if (values.shift_start_date) values.shift_start_date = getDateFormat(values.shift_start_date);
                let shiftList: any = [];
                if (shiftData) {
                    shiftData.forEach((element: any) => {
                        if (element.shift_id) {
                            shiftList.push({ id: element.shift_id });
                        }
                    });
                    values.shiftList = shiftList;
                }

                return values;
            }}
            shortcuts={true}
            nyTestId="employee-group-edit"
        >
            <Row gutter={24} style={{ marginBottom: '12px' }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('employeeGroup.general')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('employeeGroup.edit.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employeeGroup.edit.shiftType')}
                                        name="shift_type"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            options={getEnumArray('EMPLOYEE_GROUP_SHIFT_TYPE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            onChange={(value: any) => {
                                                setShiftType(value.id);
                                                checkOverlap(shiftData, value.id);
                                            }}
                                            nyTestId="shift-type"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employeeGroup.edit.shift.start.date')}
                                        name="shift_start_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            onChange={(date: any) => setShiftStartDate(date)}
                                            nyTestId="start-date"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('employeeGroup.shift')}
                            key="2"
                            extra={canCreate() ? addNewShift() : null}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <DndContext
                                        onDragStart={handleDragStart}
                                        onDragEnd={handleDragEnd}
                                        collisionDetection={closestCenter}
                                        sensors={sensors}
                                        onDragCancel={() => {
                                            setActiveId(null);
                                            setActiveName('');
                                            setActiveColor('#d9d9d9');
                                        }}
                                    >
                                        <SortableContext
                                            items={shiftData.map((item: any) => item.id)}
                                            strategy={verticalListSortingStrategy}
                                        >
                                            {shiftData.map((value: any, index: any) => (
                                                <SortableItem
                                                    key={value.id}
                                                    id={value.id}
                                                    name={value.name}
                                                    color={value.color}
                                                    overlaps={value.overlaps ? value.overlaps.toString() : undefined}
                                                    handleDeleteShift={handleDeleteShift}
                                                    handleChangeShift={handleChangeShift}
                                                    index={index}
                                                />
                                            ))}
                                        </SortableContext>
                                        <DragOverlay>
                                            {activeId ? (
                                                <Item id={activeId} name={activeName} color={activeColor} />
                                            ) : null}
                                        </DragOverlay>
                                    </DndContext>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse
                        activeKey={bottomCollapseActiveKeys}
                        onChange={(key: any) => {
                            setBottomCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('employeeGroup.calendar')} key="3">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <NyCalendar
                                        data={shiftData}
                                        type={shiftType && shiftType === 1 ? 'daily' : 'weekly'}
                                        startDate={shiftStartDate}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default GeneralEditTab;
