import { InfoCircleOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Popover } from 'antd';

import { forwardRef } from 'react';

export const TitleItem = forwardRef((props: any, ref) => {
    return props.typeModal == 'task-type-category' ? (
        <>
            <div className="title-item" style={{ cursor: 'default' }} {...props} ref={ref}>
                {props.id && (
                    <>
                        <span style={{ width: '7.5%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.id}</span>
                    </>
                )}
                {props.picture && (
                    <>
                        <span style={{ width: '10.5%', fontSize: '13px', fontWeight: '500' }}>
                            &nbsp;{props.picture}
                        </span>
                    </>
                )}
                {props.code && (
                    <>
                        <span style={{ width: '15%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.code}</span>
                    </>
                )}
                {props.name && (
                    <>
                        <span style={{ width: '20%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.name}</span>
                    </>
                )}
                {props.description && (
                    <>
                        <span style={{ fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.description}</span>
                    </>
                )}
            </div>
        </>
    ) : props.typeModal == 'task-template' ? (
        <>
            <div className="title-item" style={{ cursor: 'default' }} {...props} ref={ref}>
                {props.id && (
                    <>
                        <span style={{ width: '5.5%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.id}</span>
                    </>
                )}
                {props.code && (
                    <>
                        <span style={{ width: '10.5%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.code}</span>
                    </>
                )}
                {props.name && (
                    <>
                        <span style={{ width: '28%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.name}</span>
                    </>
                )}
                {props.description && (
                    <>
                        <span style={{ width: '28%', fontSize: '13px', fontWeight: '500' }}>
                            &nbsp;{props.description}
                        </span>
                    </>
                )}

                {props.taskTypeSubtype && (
                    <>
                        <span style={{ fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.taskTypeSubtype}</span>
                    </>
                )}
            </div>
        </>
    ) : props.typeModal == 'my-profile-settings' ? (
        <div
            className="title-item2"
            style={{
                cursor: 'default',
            }}
        >
            <span style={{ width: '47%', fontSize: '13px', fontWeight: '500', paddingLeft: '34px' }}>
                &nbsp;{geti18nText('notification.edit.header.type')}
            </span>
            <span style={{ width: '15.5%', fontSize: '13px', fontWeight: '500' }}>
                &nbsp;{geti18nText('notification.edit.header.web')}
            </span>
            <span style={{ width: '18%', fontSize: '13px', fontWeight: '500' }}>
                &nbsp;{geti18nText('notification.edit.header.mobile')}
            </span>
            <span style={{ width: '10.5%', fontSize: '13px', fontWeight: '500' }}>
                &nbsp;{geti18nText('notification.edit.header.email')}
            </span>
            <span style={{ fontSize: '13px', fontWeight: '500' }}>
                &nbsp;{geti18nText('notification.edit.header.language')}
            </span>
        </div>
    ) : props.typeModal === 'travel-warrant-type' ? (
        <div className="title-item" style={{ cursor: 'default' }} {...props} ref={ref}>
            {props.id && (
                <>
                    <span style={{ width: '5%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.id}</span>
                </>
            )}
            {props.name && (
                <>
                    <span style={{ width: '30%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.name}</span>
                </>
            )}
            {props.wageType && (
                <>
                    <span
                        style={{
                            marginLeft: '15px',
                            width: '12%',
                            fontSize: '13px',
                            fontWeight: '500',
                        }}
                    >
                        &nbsp;{props.wageType}
                    </span>
                </>
            )}
            {props.description && (
                <>
                    <span style={{ fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.description}</span>
                </>
            )}
        </div>
    ) : (
        <div className="title-item" style={{ cursor: 'default' }} {...props} ref={ref}>
            {props.id && (
                <>
                    <span style={{ width: '2%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.id}</span>
                </>
            )}
            {props.name && (
                <>
                    <span style={{ width: '28%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.name}</span>
                </>
            )}
            {props.createdPerson && (
                <>
                    <span style={{ width: '18%', fontSize: '13px', fontWeight: '500' }}>
                        &nbsp;{props.createdPerson}
                    </span>
                </>
            )}
            {props.updatedPerson && (
                <>
                    <span style={{ width: '18%', fontSize: '13px', fontWeight: '500' }}>
                        &nbsp;{props.updatedPerson}
                    </span>
                </>
            )}

            {props.created && (
                <>
                    <span style={{ width: '12%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.created}</span>
                </>
            )}
            {props.updated && (
                <>
                    <span style={{ width: '20%', fontSize: '13px', fontWeight: '500' }}>&nbsp;{props.updated}</span>
                </>
            )}
            <div style={{ float: 'right' }}>
                <span style={{ width: '2%' }}>
                    <div className="item-table-info-content">
                        <Popover
                            content={geti18nText('task.priority.column.info')}
                            placement="top"
                            style={{ width: '50%' }}
                        >
                            <Button icon={<InfoCircleOutlined />} />
                        </Popover>
                    </div>
                </span>
            </div>
        </div>
    );
});
