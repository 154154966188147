import { geti18nText, NyDatePicker, NyInputNumber } from '@nybble/nyreact';
import { Form, Input, InputNumber, Switch } from 'antd';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';

const checkQuantity = (rule: any, value: any, callback: any) => {
    if (value != undefined && ((value > 0 && value != '') || (value.id != undefined && value.id > 0))) {
        callback();
    } else {
        callback(geti18nText('app.default.required'));
    }
};

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxQuantity = undefined,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    switch (inputType) {
        case 'amount':
            inputNode = <NyInputNumber style={{ width: '100%' }} isDecimal decimalPlaces={2} min={0} />;
            break;
        case 'date':
            inputNode = <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />;
            break;
        case 'taxIncluded':
            inputNode = (
                <Switch
                    style={{ float: 'right', marginLeft: '4px' }}
                    defaultChecked={record?.taxIncluded}
                    checkedChildren={geti18nText('app.default.button.yes')}
                    unCheckedChildren={geti18nText('app.default.button.no')}
                />
            );
            break;
        case 'type':
            inputNode = (
                <div style={{ marginTop: '-10px' }}>
                    <HumanCodebooksSearch
                        label={null}
                        codebooksType={enCodebookType.EMPLOYEE_EDUCATION_EXPENSE_TYPE}
                        formItemName={'type'}
                        required
                    />
                </div>
            );
            break;
        case 'participationAmount':
            inputNode = <NyInputNumber style={{ width: '100%' }} isDecimal decimalPlaces={2} min={0} />;
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps} style={{ paddingBottom: '10px', paddingTop: '-5px' }}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    rules={[
                        {
                            required: inputType === 'amount',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
