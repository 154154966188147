import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useSelector } from 'react-redux';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumBooleanArray } from '../../../../utils/Utils';
import BankAccountEdit from './BankAccountEdit';

const BankAccountIndex = ({ scroll = { y: 580, x: 800 }, sortOrder }: any) => {
    const table = useTableSettings();
    const { item } = useSelector((state: RootState) => state.selectedItem);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'companyId', condition: 'equals', value: item },
        ];
    };

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    return (
        <NyDataTable
            nyId="bank-account"
            url={CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.LIST}
            addNewButtonText={geti18nText('bankAccount.table.add')}
            showRecordModal={true}
            buttonsClassName="buttons-sticky"
            fetchWhenChange={item}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            modalComponent={BankAccountEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            hideNewButton={!canCreate()}
            columns={[
                {
                    title: geti18nText('bankAccount.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('bankAccount.table.column.name'),
                    dataIndex: ['bank', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    render: (text: any, record: any) => {
                        if (record.bankAccount && record.bankAccount.bank) {
                            return record.bankAccount.bank.name;
                        }
                    },
                },
                {
                    title: geti18nText('bankAccount.table.column.iban'),
                    dataIndex: ['bankAccount', 'iban'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('bankAccount.table.column.swift'),
                    dataIndex: 'swift',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('bankAccount.table.column.main'),
                    dataIndex: 'main',
                    width: '10%',
                    render: (text: any, record: { main: any }) => {
                        if (record.main) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(getEnumBooleanArray()),
                },
                {
                    title: geti18nText('bankAccount.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
            shortcuts={true}
            nyTestId="bank-account-table"
        />
    );
};

export default BankAccountIndex;
