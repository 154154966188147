import { CheckOutlined, CloseOutlined, EyeTwoTone } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Modal, Row, Tabs, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../../rights/tasksRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple, getColumnSearchByCodebookUserPerson } from '../../../../../utils/Filters';
import {
    getDateFormat,
    getEnumArrayStatus,
    getErrorNotificationMessage,
    getFileList,
    getTaskStatusIcon,
    okNotification,
    onKeyDownTextAreaCustom,
} from '../../../../../utils/Utils';
import CreateTaskIndex from '../CreateTaskIndex';
import NewTaskFromTemplateIndex from './NewTaskFromTemplateIndex';
import WorkActivityIndex from './WorkActivityIndex';
import TaskWatchIndex from './TaskWatchIndex';

const ExistingTaskIndex = ({
    setValues,
    activeKey,
    setActiveKey,
    selectedTaskId,
    getFiles,
    collapseActiveKeys,
    setCollapseActiveKeys,
    hasMetadata,
    taskNoteId,
    setVisibleNoteModal,
    setTaskNoteId,
    setTaskNoteData,
    visibleNoteModal,
    taskTimeButtonVisible,
    parentTaskIdState,
    setParentTaskIdState,
    taskNoteData,
    visibleSubTask,
    setVisibleSubTask,
    selectedSubTaskId,
    setSelectedSubTaskId,
    setMetadata,
    refreshFiles,
    setRefreshFiles,
    setFiles,
    objectMetadataForm,
    metadata,
    metadataTemplate,
    setHasMetadata,
    setMetadataTemplate,
    form,
    selectedUser,
    taskStatusInit,
    setTaskStatus,
    setSelectedUser,
    taskStatus,
    actionsArray,
    parentTaskId,
    setRefreshWorktimeTable,
    refreshWorktimeTable,
    isTemplateView = false,
    setRefreshTable,
    setIsWached,
    createdBy,
    setRefreshTaskWatchTable,
    refreshTaskWatchTable,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const taskTypeEnum = useEnum('TASK_TYPE');
    const [noteForm] = Form.useForm();
    const { employee } = useSelector((state: RootState) => state.employee);
    const [selectedTaskTemplateId, setSelectedTaskTemplateId] = useState<any>(null);
    const [disabledNote, setDisabledNote] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [refreshTableSubTask, setRefreshTableSubTask] = useState<any>(0);
    const [refreshNoteTable, setRefreshNoteTable] = useState<any>(0);

    useEffect(() => {
        if (selectedTaskId && !parentTaskId) {
            openTask(selectedTaskId);
        } else {
            setLoading(false);
        }
    }, [selectedTaskId]);

    useEffect(() => {
        if (selectedTaskId && activeKey == '4') {
            setValuesFiles(selectedTaskId);
        }
    }, [selectedTaskId, refreshFiles]);

    const setDefaultFilterValueFiles = (selectedTaskId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'taskId', condition: 'equals', value: selectedTaskId },
        ];
    };

    function setValuesFiles(selectedTaskId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK.FILES_LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(selectedTaskId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    if (!TasksRights.isTasksAdmin() && !TasksRights.isTasksAgent() && TasksRights.isTasksUser()) {
                        setFiles(
                            getFileList(result.data.content.filter((row: any) => employee?.id == row?.employee?.id))
                        );
                    } else {
                        setFiles(getFileList(result.data.content));
                    }
                } else {
                    setFiles([]);
                }
            }
        });
    }

    const openTask = (selectedTaskId: any) => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK.EDIT + '/' + selectedTaskId, undefined)
            .then(async (result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    await setValues(result.data);
                    if (result.data?.taskTemplate?.id) {
                        setSelectedTaskTemplateId(result.data.taskTemplate.id);
                    }
                    if (result.data.taskMetadataList && result.data.taskMetadataList.length > 0) {
                        setMetadata(result.data.taskMetadataList);
                    }
                    setLoading(false);
                } else {
                    getErrorNotificationMessage(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const setDefaultFilterValueSubtask = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
            {
                field: 'parentId',
                condition: 'equals',
                value: selectedTaskId,
            },
        ];
    };

    const setDefaultFilterValueNotes = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
            {
                field: 'taskId',
                condition: 'equals',
                value: selectedTaskId,
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('team.table.column.id'),
            dataIndex: 'id',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('team.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.name) {
                    return record.name;
                }
            },
        },
        {
            title: geti18nText('team.table.column.description'),
            dataIndex: 'description',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '200px' }} title={record.description}>
                            <div className={'ny-table-paragraph-div'}>
                                <p className={'ny-table-paragraph'}>{record && record.description}</p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.user'),
            dataIndex: [`concat(userPerson.first_name,' ', userPerson.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.userPerson) {
                    return record.userEmployee
                        ? record.userPerson?.firstName +
                              ' ' +
                              record.userPerson?.lastName +
                              ' (' +
                              record.userEmployee?.employmentRecordId +
                              ')'
                        : record.userPerson?.firstName + ' ' + record.userPerson?.lastName;
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.assignedUser'),
            dataIndex: [`concat(assignedPerson.first_name,' ', assignedPerson.last_name)`],
            width: '12%',
            ...getColumnSearch('string'),
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.assignedEmployee) {
                    return record.assignedEmployee
                        ? record.assignedPerson?.firstName +
                              ' ' +
                              record.assignedPerson?.lastName +
                              ' (' +
                              record.assignedEmployee?.employmentRecordId +
                              ')'
                        : record.assignedPerson?.firstName + ' ' + record.assignedPerson?.lastName;
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.assignedTeam'),
            dataIndex: ['assignedTeam', 'name'],
            sorter: (a: any, b: any) => {},
            width: '12%',
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TEAM.SEARCH, 'assignedTeam.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                name: 'name',
                code: 'code',
            }),
            render: (text: any, record: any) => {
                if (record.assignedTeam) {
                    return record.assignedTeam.name + ' (' + record.assignedTeam.id + ')';
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.taskPriority'),
            dataIndex: ['taskPriority', 'name'],
            sorter: (a: any, b: any) => {},
            width: '12%',
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.taskPriority) {
                    return record.taskPriority.name;
                }
            },
        },
        {
            title: geti18nText('tasks.list.column.status'),
            dataIndex: 'status',
            width: '188px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TASK_STATUS')),
            render: (text: any, record: any) => {
                if (record.status) {
                    return getTaskStatusIcon(record.status);
                }
            },
        },
        {
            title: geti18nText('team.table.column.active'),
            dataIndex: 'active',
            width: '6%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '1rem' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '1rem' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValueSubtask()),
        },
    ];

    const noteColumns = [
        {
            title: geti18nText('team.table.column.notes.createdBy'),
            dataIndex: 'createdBy',
            width: '14%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookUserPerson(),
            render: (text: any, record: any) => {
                if (record) {
                    return <div style={{ marginLeft: '2px' }}>{`${record.firstName} ${record.lastName}`}</div>;
                }
            },
        },
        {
            title: geti18nText('team.table.column.notes.createdDate'),
            dataIndex: ['note', 'created'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (description: any, record: any) => {
                if (record && record.note) {
                    return getDateFormat(record.note.created, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('team.table.column.notes'),
            dataIndex: ['note', 'note'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.note) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {' '}
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '620px' }} title={record.note.note}>
                                <div style={{ maxWidth: record.isPrivate ? 'calc(100% - 28px)' : '100%' }}>
                                    <p className={'ny-table-paragraph'}>{record && record.note.note} </p>
                                </div>
                            </Tooltip>
                            {record.isPrivate && (
                                <div
                                    style={{
                                        float: 'right',
                                        fontSize: '18px',
                                        marginTop: '-4px',
                                        marginBottom: '-4px',
                                    }}
                                >
                                    <Tooltip
                                        placement="top"
                                        overlayStyle={{ maxWidth: '300px' }}
                                        title={geti18nText('team.edit.column.note.explained')}
                                    >
                                        <EyeTwoTone twoToneColor="#52c41a" />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const closeNoteModal = (event: any) => {
        setVisibleNoteModal(false);
        setTaskNoteId(undefined);
        setDisabledNote(false);
        noteForm.resetFields();
        event.stopPropagation();
        event.preventDefault();
    };

    const saveNoteModalEvent = (event: any, deactivate?: boolean) => {
        saveTaskNote(deactivate);
        event.stopPropagation();
        event.preventDefault();
    };

    const saveTaskNote = (deactivate?: boolean) => {
        noteForm.validateFields();
        let values: any = { note: { note: '' }, task: { id: '' }, isPrivate: false };
        if (taskNoteData != undefined) {
            values = taskNoteData;
        }

        values.note.note = noteForm.getFieldValue('note');
        values.task.id = selectedTaskId;
        values.isPrivate = noteForm.getFieldValue('isPrivate');
        if (deactivate) {
            values.active = false;
        }

        if (taskNoteId != undefined) {
            updateTaskNote(values);
        } else {
            createTaskNote(values);
        }
    };

    const updateTaskNote = (values: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_NOTE.EDIT + '/' + taskNoteId, undefined, {
            ...values,
        }).then((result: any) => {
            if ((result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) && result.data) {
                okNotification();
                setVisibleNoteModal(false);
                setDisabledNote(false);
                setTaskNoteData({});
                setTaskNoteId(undefined);
                noteForm.resetFields();
                setRefreshNoteTable((refeshNoteTable: any) => refeshNoteTable + 1);
            } else {
                getErrorNotificationMessage(result);
                setLoading(false);
            }
        });
    };

    const createTaskNote = (values: any) => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.TASK_NOTE.EDIT, undefined, {
            ...values,
        }).then((result: any) => {
            if ((result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) && result.data) {
                okNotification();
                setVisibleNoteModal(false);
                setDisabledNote(false);
                setTaskNoteData({});
                setTaskNoteId(undefined);
                noteForm.resetFields();
                setRefreshNoteTable((refeshNoteTable: any) => refeshNoteTable + 1);
            } else {
                getErrorNotificationMessage(result);
                setLoading(false);
            }
        });
    };

    const getNotesFooterContent = (
        <>
            {!disabledNote && taskNoteId && (
                <Button
                    style={{ marginLeft: '8px', float: 'left' }}
                    type="primary"
                    danger
                    onClick={(e: any) => {
                        saveNoteModalEvent(e, true);
                    }}
                >
                    {geti18nText('app.default.button.delete')}
                </Button>
            )}

            <Button
                onClick={(e: any) => {
                    closeNoteModal(e);
                }}
            >
                {geti18nText('app.default.button.close')}
            </Button>

            {!disabledNote && (
                <Button
                    type="primary"
                    onClick={(e: any) => {
                        noteForm.getFieldValue('note') != null && saveNoteModalEvent(e);
                    }}
                >
                    {geti18nText('app.default.button.save')}
                </Button>
            )}
        </>
    );

    useEffect(() => {
        if (activeKey == '2') {
            setRefreshTableSubTask((prevValue: any) => prevValue + 1);
        }
        if (activeKey == '3') {
            setRefreshNoteTable((prevValue: any) => prevValue + 1);
        }
        if (activeKey == '4') {
            setRefreshFiles((prevValue: any) => prevValue + 1);
        }
        if (activeKey == '5') {
            setRefreshWorktimeTable((prevValue: any) => prevValue + 1);
        }
    }, [visibleSubTask, activeKey]);

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        setActiveKey(key);
                    }}
                >
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.general')} key="1">
                        <NewTaskFromTemplateIndex
                            parentTaskId={parentTaskId}
                            selectedTaskTemplateId={selectedTaskTemplateId}
                            form={form}
                            setValues={setValues}
                            loading={loading}
                            setLoading={setLoading}
                            collapseActiveKeys={collapseActiveKeys}
                            setCollapseActiveKeys={setCollapseActiveKeys}
                            objectMetadataForm={objectMetadataForm}
                            setHasMetadata={setHasMetadata}
                            metadata={metadata}
                            metadataTemplate={metadataTemplate}
                            setMetadataTemplate={setMetadataTemplate}
                            hasMetadata={hasMetadata}
                            showFiles={false}
                            getFiles={getFiles}
                            selectedUser={selectedUser}
                            taskStatusInit={taskStatusInit}
                            selectedTaskId={selectedTaskId}
                            setTaskStatus={setTaskStatus}
                            setSelectedUser={setSelectedUser}
                            taskStatus={taskStatus}
                            actionsArray={actionsArray}
                            isTemplateView={isTemplateView}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.tasks')} key="2" disabled={!selectedTaskId}>
                        <NyDataTable
                            nyId="tasks-existing-task-index-table"
                            url={CONSTANTS_REQ.TASK.LIST.replace('{type}', taskTypeEnum.SUBTASK)}
                            addNewButtonText={geti18nText('tasks.list.add')}
                            fetchWhenChange={refreshTableSubTask}
                            buttonsClassName="buttons-sticky"
                            showRecordModal={true}
                            onRowSelect={(data: any) => {
                                setSelectedSubTaskId(data && data.id);
                                setVisibleSubTask(true);
                            }}
                            editProps={{ refreshTable: refreshTableSubTask, setRefreshTable: setRefreshTableSubTask }}
                            columns={columns}
                            setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
                            scroll={scroll}
                            onDataLoaded={table.onDataLoaded}
                            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValueSubtask())}
                            setDefaultPageSize={table.setDefaultPageSize()}
                            setDefaultCurrentPage={table.setDefaultCurrentPage()}
                            hideNewButton={true}
                            shortcuts={true}
                        />
                        {visibleSubTask && (
                            <CreateTaskIndex
                                selectedTaskId={selectedSubTaskId}
                                setSelectedTaskId={setSelectedSubTaskId}
                                refreshTable={refreshTableSubTask}
                                setRefreshTable={setRefreshTableSubTask}
                                visible={visibleSubTask}
                                setVisible={setVisibleSubTask}
                                parentTaskId={selectedTaskId}
                                taskType={taskTypeEnum.SUBTASK}
                                isTemplateView={isTemplateView}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('task.template.edit.tab.notes')} key="3" disabled={!selectedTaskId}>
                        <NyDataTable
                            nyId="tasks-existing-task-index-task-note-table"
                            url={CONSTANTS_REQ.TASK_NOTE.LIST}
                            addNewButtonText={geti18nText('tasks.list.add')}
                            fetchWhenChange={refreshNoteTable}
                            buttonsClassName="buttons-sticky"
                            showRecordModal={true}
                            editProps={{ refreshTable: refreshNoteTable, setRefreshTable: setRefreshNoteTable }}
                            onRowSelect={(data: any) => {
                                if (employee.id == data.employee.id) {
                                    setDisabledNote(false);
                                    data.isPrivate && noteForm.setFieldsValue({ isPrivate: true });
                                } else {
                                    setDisabledNote(true);
                                }
                                setVisibleNoteModal(true);
                                setTaskNoteId(data && data.id);
                                setTaskNoteData(data);
                                noteForm.setFieldsValue({ note: data.note.note });
                            }}
                            columns={noteColumns}
                            setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'desc' }}
                            scroll={scroll}
                            onDataLoaded={table.onDataLoaded}
                            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValueNotes())}
                            setDefaultPageSize={table.setDefaultPageSize()}
                            setDefaultCurrentPage={table.setDefaultCurrentPage()}
                            hideNewButton={true}
                            shortcuts={true}
                        />
                        {visibleNoteModal && (
                            <Modal
                                visible={visibleNoteModal}
                                title={geti18nText('team.edit.column.note')}
                                maskClosable={false}
                                footer={getNotesFooterContent}
                                onCancel={(event: any) => {
                                    closeNoteModal(event);
                                }}
                            >
                                <Form
                                    form={noteForm}
                                    layout="vertical"
                                    {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                                >
                                    <Row gutter={24}>
                                        <Col offset={1} span={22}>
                                            <Form.Item
                                                label={geti18nText('team.edit.column.note')}
                                                name={'note'}
                                                labelAlign={'left'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    disabled={disabledNote}
                                                    onKeyDown={onKeyDownTextAreaCustom}
                                                    rows={8}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {(!taskNoteId || !disabledNote) && (
                                        <Row gutter={24}>
                                            <Col offset={1} span={22}>
                                                <Form.Item
                                                    name={'isPrivate'}
                                                    valuePropName={'checked'}
                                                    initialValue={false}
                                                    style={{ marginBottom: '0px' }}
                                                >
                                                    <Checkbox>{geti18nText('app.default.note.private')}</Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </Form>
                            </Modal>
                        )}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={geti18nText('app.default.files')} key="4">
                        {getFiles()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('team.tab.work.time.title')} key="5" disabled={!selectedTaskId}>
                        <WorkActivityIndex
                            isIndex={true}
                            selectedTaskId={selectedTaskId}
                            parentTaskIdState={parentTaskIdState}
                            setRefreshWorktimeTable={setRefreshWorktimeTable}
                            refreshWorktimeTable={refreshWorktimeTable}
                            activeKey={activeKey}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('team.tab.work.time.watch')} key="6" disabled={!selectedTaskId}>
                        <TaskWatchIndex
                            isIndex={true}
                            selectedTaskId={selectedTaskId}
                            parentTaskIdState={parentTaskIdState}
                            setRefreshWorktimeTable={setRefreshWorktimeTable}
                            refreshWorktimeTable={refreshWorktimeTable}
                            activeKey={activeKey}
                            setIsWached={setIsWached}
                            setRefreshTable={setRefreshTable}
                            createdBy={createdBy}
                            setRefreshTaskWatchTable={setRefreshTaskWatchTable}
                            refreshTaskWatchTable={refreshTaskWatchTable}
                            openTask={openTask}
                        />
                    </Tabs.TabPane>
                </Tabs>
            )}
        </>
    );
};

export default ExistingTaskIndex;
