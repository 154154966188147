import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import PayrollInsuranceEdit from './edit';
import moment from 'moment';
import { useLocation } from 'react-router';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getEnumArray, numberFormat } from '../../../../utils/Utils';
import useHelper from '../../../../hooks/useHelper';
import PayrollInsuranceModal from './PayrollInsuranceModal';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { PayrollRights } from '../../../../rights/payrollRights';

const PayrollInsuranceIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('payroll/codebooks/insurance');

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    const columns = [
        {
            title: geti18nText('payrollInsurance.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payrollInsurance.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payrollInsurance.table.column.percent'),
            dataIndex: 'percent',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (percent: any) => {
                return numberFormat(percent);
            },
        },
        {
            title: geti18nText('payrollInsurance.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.PAYROLL_INSURANCE_TYPE.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('PAYROLL_INSURANCE_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('payrollInsurance.table.column.validFrom'),
            dataIndex: 'validFrom',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('payrollInsurance.table.column.validTo'),
            dataIndex: 'validTo',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('payrollInsurance.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-payroll-insurance-table"
            url={CONSTANTS_REQ.PAYROLL_INSURANCE.LIST}
            addNewButtonText={geti18nText('payrollInsurance.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={PayrollInsuranceEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            addedButtons={(save: any) => {
                return <PayrollInsuranceModal />;
            }}
            headerTitle={geti18nText('menu.payrollInsurance')}
        />
    );
};

export default PayrollInsuranceIndex;
