import { CarOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Card, Empty, Statistic, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { setProp } from '../../slices/propSlice';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getColorFromNotificationType } from '../../utils/Utils';

const { Title } = Typography;

const TravelWarrantPersonalWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const widgetColor = getColorFromNotificationType(settings, 'TRAVEL_WARRANT_APPROVAL');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrant();
    };

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(
        CONSTANTS_REQ.DASHBOARD.GET_STATISTICS,
        undefined,
        { keys: ['myTravelWarrantRequested', 'myTravelWarrantsApproved', 'myTravelWarrantsConfirmed'] },
        true,
        false,
        {
            myTravelWarrantRequested: 0,
            myTravelWarrantsApproved: -1,
            myTravelWarrantsConfirmed: -1,
        }
    );

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px 10px',
    };

    return (
        <Card
            className="ny-widget-card"
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            title={geti18nText('menu.travelWarrant.personal')}
            bodyStyle={{
                height: 'calc(100% - 55px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '4px 5px 0px 5px',
                marginTop: '1px',
            }}
            type="inner"
            extra={
                <>
                    {canCreate() && (
                        <Tooltip placement="top" title={geti18nText('travelWarrantPersonal.table.add')}>
                            <CarOutlined
                                className="ny-card-icon"
                                onClick={() => {
                                    history.push({
                                        pathname: '/travel-warrant/travelWarrant',
                                        state: { openModal: true },
                                    });
                                }}
                            />
                        </Tooltip>
                    )}
                </>
            }
        >
            {loading ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : error ||
              (statisticData != null &&
                  statisticData['myTravelWarrantRequested'] == -1 &&
                  statisticData['myTravelWarrantsApproved'] == -1 &&
                  statisticData['myTravelWarrantsConfirmed'] == -1) ? (
                <div style={{ paddingTop: 10 }}>
                    <Empty
                        className="ny-dashboard-empty"
                        imageStyle={{ width: '100%' }}
                        description={<Title level={5}>{geti18nText('app.default.nodata')}</Title>}
                    />
                </div>
            ) : (
                <>
                    {statisticData != null && statisticData['myTravelWarrantRequested'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className="statistic-card"
                            onClick={() => {
                                dispatch(setProp(1));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travelWarrantPersonal.requests')}
                                value={statisticData['myTravelWarrantRequested']}
                                prefix={<CarOutlined />}
                            />
                        </Card.Grid>
                    )}
                    {statisticData != null && statisticData['myTravelWarrantsApproved'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => {
                                dispatch(setProp(1));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travelWarrantPersonal.approved')}
                                value={statisticData['myTravelWarrantsApproved']}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                    {statisticData != null && statisticData['myTravelWarrantsConfirmed'] >= 0 && (
                        <Card.Grid
                            style={gridStyle}
                            className={`statistic-card statistic-card-warning`}
                            onClick={() => {
                                dispatch(setProp(1));
                                history.push('/travel-warrant/travelWarrant');
                            }}
                        >
                            <Statistic
                                title={geti18nText('travelWarrantPersonal.confirmed')}
                                value={statisticData['myTravelWarrantsConfirmed']}
                                prefix={<IssuesCloseOutlined />}
                            />
                        </Card.Grid>
                    )}
                </>
            )}
        </Card>
    );
};

export default TravelWarrantPersonalWidget;
