import { getColumnSearch, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NyReportButton from '../../../../components/report-button';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { numberFormat } from '../../../../utils/Utils';
import EmployeeAttendanceModal from './EmployeeAttendanceModal';
import EmployeePaymentEdit from './edit';
import { PayrollRights } from '../../../../rights/payrollRights';

const EmployeePaymentIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    refreshTable,
    setDefaultSelectedRowKeys,
}: any) => {
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const { refreshNotification } = useSelector((state: RootState) => state.notification);

    const { id } = useParams<any>();
    const [refresh, setRefresh] = useState(0);
    const table = useTableSettings();
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const setDefaultFilterValue = () => {
        return [{ field: 'payment_calculation_id', condition: 'equals', value: id }];
    };

    useEffect(() => {
        setRefresh((val) => val + 1);
    }, [id, refreshItem, refreshTable, refreshNotification]);

    const canCreate = () => {
        return PayrollRights.canCreateCalculation();
    };

    return (
        <NyDataTable
            nyId="human-employee-payment-list"
            url={CONSTANTS_REQ.EMPLOYEE_PAYMENT.LIST}
            addNewButtonText={geti18nText('payment.calculation.edit.add.employee.payment')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            fetchWhenChange={refresh}
            scroll={{ y: 580, x: 800 }}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            setDefaultPageSize={table.setDefaultPageSize()}
            modalComponent={EmployeePaymentEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('payment.calculation.employee.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payment.calculation.employee.table.column.firstName'),
                    dataIndex: ['person', 'firstName'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    render: (text: any, record: any) => {
                        if (record.employee) {
                            return record.employee.person.firstName;
                        }
                    },
                },
                {
                    title: geti18nText('payment.calculation.employee.table.column.lastName'),
                    dataIndex: ['person', 'lastName'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    render: (text: any, record: any) => {
                        if (record.employee) {
                            return record.employee.person.lastName;
                        }
                    },
                },
                {
                    title: geti18nText('payment.calculation.employee.table.column.workHours'),
                    dataIndex: 'workHours',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payment.calculation.employee.table.column.hourlyRate'),
                    dataIndex: 'hourlyRate',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (hourlyRate: any) => {
                        if (hourlyRate) {
                            return numberFormat(hourlyRate);
                        }
                    },
                },
                {
                    title: geti18nText('payment.calculation.employee.table.column.brut'),
                    dataIndex: 'brut',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (brut: any) => {
                        if (brut) {
                            return numberFormat(brut);
                        }
                    },
                },
                {
                    dataIndex: 'actions',
                    width: '100px',
                    render: (text: any, record: any) => {
                        if (record.id) {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <EmployeeAttendanceModal
                                        employeePaymentId={record.id}
                                        employeeId={record.employee.id}
                                        periodFrom={record.paymentCalculation.periodFrom}
                                        periodTo={record.paymentCalculation.periodTo}
                                    />
                                    <NyReportButton
                                        url={CONSTANTS_REQ.REPORT.IP1_REPORT}
                                        customParms={{ employeePaymentId: Number(record.id) }}
                                        subreportType={'IP1_FORM'}
                                        buttoni18nText={'report.ip1Download'}
                                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.IP1_FORM)}
                                        type={'list'}
                                    />
                                </div>
                            );
                        }
                    },
                },
            ]}
            shortcuts={true}
            hideNewButton={true}
        />
    );
};

export default EmployeePaymentIndex;
