import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useHelper from '../../hooks/useHelper';
import useTableSettings from '../../hooks/useTableSettings';
import { AssetRights } from '../../rights/assetRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../utils/Constants';
import { getEnumArrayStatus } from '../../utils/Utils';
import FixedAssetEdit from '../../views/accounting/views/assets/fixed-asset/edit';
import AssetEdit from '../../views/webshop/views/webshop-asset/asset/edit';

const AssetSimpleIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    isFixedAsset = false,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
    defaultFilterValue,
    availableOnly = false,
}: any) => {
    const table = useTableSettings();

    useHelper('asset/codebooks/asset');

    const canCreate = () => {
        return AssetRights.canCreateCodebooks();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const setDefaultFilterValue = () => {
        return defaultFilterValue ? defaultFilterValue : table.setDefaultFilterValue();
    };

    const initialColumns = [
        {
            title: geti18nText('asset.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.status'),
            dataIndex: 'status',
            width: '160px',
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.ASSET_STATUS.' + record.status);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('ASSET_STATUS'), 'equals', undefined),
        },
        ...(availableOnly == true
            ? [
                  {
                      title: geti18nText('asset.table.column.availabilityStatus'),
                      dataIndex: 'availabilityStatus',
                      render: (text: any, record: any) => {
                          if (record.availabilityStatus !== undefined) {
                              return geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + record.availabilityStatus);
                          }
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('asset.table.column.availabilityStatus'),
                      dataIndex: 'availabilityStatus',
                      render: (text: any, record: any) => {
                          if (record.availabilityStatus !== undefined) {
                              return geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + record.availabilityStatus);
                          }
                      },
                      ...getColumnSearchOption(getEnumArrayStatus('ASSET_AVAILABILITY_STATUS'), 'equals', 1),
                  },
              ]),
        {
            title: geti18nText('asset.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
        },
    ];

    const fixedAssetColumns = [
        {
            title: geti18nText('asset.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.acquisition.table.column.assetDepreciationStatus'),
            dataIndex: 'assetDepreciationStatus',
            render: (text: any, record: any) => {
                if (record.assetDepreciationStatus !== undefined) {
                    return geti18nText('app.enum.ASSET_DEPRECIATION_STATUS.' + record.assetDepreciationStatus);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('ASSET_DEPRECIATION_STATUS'), 'equals', undefined),
        },
        {
            title: geti18nText('asset.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
        },
        table,
    ];

    return (
        <NyDataTable
            nyId="asset-simple"
            url={CONSTANTS_REQ.ASSET.LIST}
            addNewButtonText={
                isFixedAsset ? geti18nText('asset.acquisition.add.asset') : geti18nText('asset.table.add')
            }
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            modalComponent={isFixedAsset ? FixedAssetEdit : AssetEdit}
            showRecordModal={true}
            columns={isFixedAsset ? fixedAssetColumns : initialColumns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate() || disabled}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            readonly={disabled}
            exportCSV={canExportCSV()}
            CSVFileName={
                isFixedAsset ? geti18nText('asset.depreciationItem.table.column.asset') : geti18nText('menu.asset')
            }
            addedData={{ isFixedAsset: isFixedAsset }}
            colCSVCustomization={isFixedAsset ? csvColumnCustomisationFixedAsset() : csvColumnCustomisation()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_STATUS.' + value);
            },
        },
        {
            availabilityStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumnCustomisationFixedAsset: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_STATUS.' + value);
            },
        },
        {
            assetDepreciationStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_DEPRECIATION_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AssetSimpleIndex;
