import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumBooleanArray } from '../../../../utils/Utils';
import AttendanceBreakEdit from './edit';

const AttendanceBreakIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('human/codebooks/attendance-break-type');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canViewAttendanceRegistrationCodebooks();
    };

    const columns = [
        {
            title: geti18nText('attendance.break.table.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.break.table.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.break.table.description'),
            dataIndex: 'description',
            width: '60%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record?.description) {
                    return (
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '360px' }} title={record.description}>
                            <div style={{ maxWidth: '450px' }}>
                                <p
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginTop: '-0.6rem',
                                        marginBottom: '-1rem',
                                    }}
                                >
                                    {record.description}
                                </p>
                            </div>
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('work.type.table.column.default'),
            dataIndex: 'defaultType',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            render: (text: any, record: any) => {
                if (record.defaultType) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('attendance.break.table.active'),
            dataIndex: 'active',
            width: '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-attendance-break-type"
            url={CONSTANTS_REQ.ATTENDANCE_BREAK.LIST}
            addNewButtonText={geti18nText('app.default.button.add')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={AttendanceBreakEdit}
            columns={columns}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.attendance.break.type')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.attendance.break.type')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AttendanceBreakIndex;
