import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../../../../components/report-button';
import { InventoryRights } from '../../../../../rights/inventoryRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption, getDateFormat } from '../../../../../utils/Utils';
import ItemGroupSearch from '../../../../administration/views/item-group/search';
import ItemSearch from '../../../../administration/views/item/search';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import EmployeeIndex from '../../../../human/views/employee';
import useEnum from '../../../../../hooks/useEnum';
import ReportExportType from '../../../../../components/report-export-type/ReportExportType';

const InventoryReportModal = ({
    reportType,
    reportTitle,
    reportFileName,
    url,
    allFormItemsMultipleSelectionMode = true,
    type,
    allowExportTypeChange = true,
}: any) => {
    const enExportType = useEnum('EXPORT_TYPE');
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('month'));
    const [dateTo, setDateTo] = useState<any>(moment());
    const [itemGroupId, setItemGroupId] = useState<any>(null);
    const [itemId, setItemId] = useState<any>(null);
    const [employeeId, setEmployeeId] = useState<any>(null);
    const [personId, setPersonId] = useState<any>(null);
    const [businessUnitId, setBusinessUnitId] = useState<any>(null);
    const [exportType, setExportType] = useState(enExportType.PDF);

    const mode = allFormItemsMultipleSelectionMode ? 'multiple' : 'default';

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values.dateFrom));
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    const setDefaultFilterValueInventoryItemGroup = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 2 },
        ];
    };

    const setDefaultFilterValueInventoryItem = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 8 },
        ];
    };

    const getFormattedValue = (value: any) => {
        if (allFormItemsMultipleSelectionMode) {
            const valueIds =
                value &&
                value?.length > 0 &&
                value.map((element: any) => {
                    return element.id;
                });
            return valueIds.length > 0 ? valueIds : null;
        } else {
            return value && value.id > 0 ? value.id : null;
        }
    };

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText(reportTitle)}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText(reportTitle)}
                    maskClosable={false}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        setItemGroupId(null);
                        setItemId(null);
                        setEmployeeId(null);
                        setPersonId(null);
                        setBusinessUnitId(null);
                        setDateFrom(moment().startOf('month'));
                        setDateTo(moment());
                        setExportType(enExportType.PDF);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    footer={[
                        <NyReportButton
                            url={url}
                            customParms={{
                                type: type,
                                dateFrom: getDateFormat(dateFrom),
                                dateTo: getDateFormat(dateTo),
                                itemGroupId: itemGroupId,
                                itemId: itemId,
                                employeeId: employeeId,
                                personId: personId,
                                businessUnitId: businessUnitId,
                                exportType: exportType ? exportType : enExportType.PDF,
                            }}
                            subreportType={reportType}
                            buttoni18nText={reportTitle}
                            fileName={reportFileName}
                            checkBeforeSave={checkBeforeSave}
                            selectedExportType={exportType}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {allowExportTypeChange && (
                            <ReportExportType exportType={exportType} setExportType={setExportType} />
                        )}
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('inventoryRelease.report.dateFrom')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().startOf('month')}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateFrom(value);
                                        }}
                                        mustGetPopupContainer={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('inventoryRelease.report.dateTo')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment()}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateTo(value);
                                        }}
                                        mustGetPopupContainer={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <ItemGroupSearch
                                    label={geti18nText('inventoryRelease.report.itemGroup')}
                                    setDefaultFilterValue={setDefaultFilterValueInventoryItemGroup}
                                    type={2}
                                    onChange={(value: any) => {
                                        setItemGroupId(getFormattedValue(value));
                                    }}
                                    AddNewModalComponent={InventoryRights.canCreateCodebooks()}
                                    AddNewModalComponentAddedData={{ type: 2 }}
                                    addedData={{ type: 2 }}
                                    mode={mode}
                                    mustGetPopupContainer={false}
                                />
                            </Col>
                            <Col span={12}>
                                <ItemSearch
                                    setDefaultFilterValue={setDefaultFilterValueInventoryItem}
                                    itemIndexType={8}
                                    itemGroupType={2}
                                    onChange={(value: any) => {
                                        setItemId(getFormattedValue(value));
                                    }}
                                    mode={mode}
                                    mustGetPopupContainer={false}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <BusinessUnitSearch
                                    formItemName={'businessUnit'}
                                    onChange={(value: any) => {
                                        setBusinessUnitId(getFormattedValue(value));
                                    }}
                                    mode={mode}
                                    mustGetPopupContainer={false}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('inventoryRelease.report.receivedBy')} name="person">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                            businessUnit: 'businessUnit',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="inventory-report-modal"/>}
                                        onChange={(value: any) => {
                                            setPersonId(getFormattedValue(value));
                                        }}
                                        order="person.last_name, person.first_name"
                                        mode={mode}
                                        mustGetPopupContainer={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('inventoryRelease.report.employee')} name="employee">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                            businessUnit: 'businessUnit',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                        onChange={(value: any) => {
                                            setEmployeeId(getFormattedValue(value));
                                        }}
                                        order="person.last_name, person.first_name"
                                        mode={mode}
                                        mustGetPopupContainer={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default InventoryReportModal;
