import { geti18nText } from '@nybble/nyreact';
import { Col, Radio, Row } from 'antd';
import { useState } from 'react';
import NyHistory from '../ny-history/NyHistory';

const TravelWarrantHistoryIndex = ({
    id,
    link = 'travelWarrant',
    refresh,
    showHistorySnapshotByStatus = false,
}: any) => {
    const [activeList, setActiveList] = useState(1);

    const getHistoryGeneral = () => <NyHistory id={id} link={link} refresh={refresh} />;
    const getHistoryByPeriod = () => <NyHistory id={id} link={link} refresh={refresh} isPeriod />;

    const getTabsView = () => (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Radio.Group
                        value={activeList}
                        buttonStyle="solid"
                        optionType="button"
                        style={{ marginBottom: '20px', marginTop: '5px' }}
                    >
                        <Radio.Button value={1} onChange={(e: any) => setActiveList(e?.target?.value)}>
                            {geti18nText('travelWarrantPersonal.tab.history.warrant')}
                        </Radio.Button>
                        <Radio.Button value={2} onChange={(e: any) => setActiveList(e?.target?.value)}>
                            {geti18nText('travelWarrantPersonal.tab.history.period')}
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>{activeList === 2 ? getHistoryByPeriod() : getHistoryGeneral()}</Col>
            </Row>
        </>
    );

    return <>{showHistorySnapshotByStatus ? getTabsView() : getHistoryGeneral()}</>;
};

export default TravelWarrantHistoryIndex;
