import { FilterOutlined, FullscreenOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Card, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NyFullCalendar from '../../components/ny-full-calendar/NyFullCalendar';
import useEnum from '../../hooks/useEnum';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { GetEnumNameForValue } from '../../utils/Enums';
import { userIsNotificationRecipient } from '../../utils/Utils';

const PersonalCalendarWidget = () => {
    const [filterDialogVisible, setFilterDialogVisible] = useState<boolean>(false);
    const [fullScreenDialogVisible, setFullScreenDialogVisible] = useState<boolean>(false);
    const { employee } = useSelector((state: RootState) => state.employee);
    const travelWarrantStatus = useEnum('TRAVEL_WARRANT_STATUS');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    const isAdmin = () => {
        return isAdminTravel() || isAdminLeave();
    };

    const isAdminTravel = () => {
        return (
            TravelWarrantRights.canCreateTravelWarrantAdmin() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const isAdminLeave = () => {
        return HumanResourcesRights.isAdmin() || (moduleAccessRight && moduleAccessRight.includes('LEAVE'));
    };

    const initialSelectedOptionsTravelWarrant = () => {
        return [
            travelWarrantStatus.WAITING_FOR_APPROVAL,
            travelWarrantStatus.APPROVED,
            travelWarrantStatus.REJECTED,
            travelWarrantStatus.WAITING_FOR_ORGANIZATION,
            travelWarrantStatus.ORGANIZED,
            travelWarrantStatus.WAITING_FOR_REPORT_APPROVAL,
            travelWarrantStatus.REPORT_APPROVED,
            travelWarrantStatus.REPORT_FIX_NEEDED,
            travelWarrantStatus.CALCULATED,
            travelWarrantStatus.NOT_CALCULATED,
            travelWarrantStatus.PAID,
        ];
    };

    const initialSelectedOptionsEmployeeLeave = () => {
        return [employeeLeaveStatus.CONFIRMED];
    };

    const setDefaultFilterValue = () => {
        if (isAdmin()) {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: employee && employee.id },
        ];
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    return (
        <Card
            title={geti18nText('travel.warrant.calendar')}
            extra={
                <div>
                    <Tooltip title={geti18nText('app.default.filter')}>
                        <Button icon={<FilterOutlined />} onClick={() => setFilterDialogVisible(true)} />
                    </Tooltip>

                    <Tooltip title={geti18nText('app.default.fullScreen')}>
                        <Button
                            icon={<FullscreenOutlined />}
                            onClick={() => setFullScreenDialogVisible(true)}
                            style={{ float: 'right' }}
                        />
                    </Tooltip>
                </div>
            }
            className="ny-widget-card"
            bodyStyle={{ maxHeight: '330px', overflowY: 'auto', overflowX: 'hidden', padding: '0px 5px 0px 5px' }}
            type="inner"
        >
            <div>
                <NyFullCalendar
                    keys={['travelWarrant', 'employeeLeave']}
                    toolbarRight={'dayGridMonth,timeGridWeek,timeGridDay,listWeek fullScreenButton'}
                    height="310px"
                    isCard={true}
                    filterDialogVisible={filterDialogVisible}
                    setFilterDialogVisible={setFilterDialogVisible}
                    fullScreenDialogVisible={fullScreenDialogVisible}
                    setFullScreenDialogVisible={setFullScreenDialogVisible}
                    initialSelectedOptions={JSON.stringify([
                        { keyType: 'travelWarrant', selectedOptions: initialSelectedOptionsTravelWarrant() },
                        { keyType: 'employeeLeave', selectedOptions: initialSelectedOptionsEmployeeLeave() },
                    ])}
                    isAdmin={isAdmin()}
                    isPersonal={!isAdmin()}
                    search={setDefaultFilterValue()}
                    isDashboard={true}
                ></NyFullCalendar>
            </div>
        </Card>
    );
};

export default PersonalCalendarWidget;
