import { DeleteOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { ReactText, useEffect, useState } from 'react';
import JoppdCodeIndex from '../../../../components/joppd-code';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import {
    numberFormat,
    getDateFormat,
    setJoppdCodeDefaultFilterValue,
    customEmployeeRenderOption,
    okNotification,
    errorNotification,
    getEnumArray,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import { PayrollRights } from '../../../../rights/payrollRights';

const { Panel } = Collapse;

const PageB = ({
    id,
    detailsDataDefinition,
    detailsDataDefinitionGrouped,
    joppdActive,
    deactivateJoppd,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [formB] = Form.useForm();
    const [showTooltip, setShowTooltip] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [isCreate, setIsCreate] = useState(true);
    const [itemHeaderName, setItemHeaderName] = useState<any>(<p></p>);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [tableRefresh, setTableRefresh] = useState(0);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>([]);

    useEffect(() => {
        if (detailsDataDefinition) {
            detailsDataDefinition.map((item: any) => {
                let enumDataType = GetEnumNameForValue({
                    enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
                    value: item.dataType,
                });

                switch (enumDataType) {
                    case 'NUMBER':
                        columns.push({
                            title: item.node + ' ' + item.name,
                            dataIndex: item.tag,
                            key: item.id,
                            width: '170px',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                            render: (val: any) => {
                                if (item.pattern) {
                                    let pattern = JSON.parse(item.pattern);
                                    if (pattern && pattern.dec) {
                                        return numberFormat(val, pattern.dec);
                                    }
                                }
                                return val;
                            },
                        });
                        break;
                    case 'DATE':
                    case 'TIME':
                    case 'TIMESTAMP':
                        columns.push({
                            title: item.node + ' ' + item.name,
                            dataIndex: item.tag,
                            key: item.id,
                            width: '170px',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                            render: (val: any) => {
                                if (val) {
                                    return getDateFormat(val, item.pattern);
                                }
                            },
                        });
                        break;
                    default:
                        columns.push({
                            title: item.node + ' ' + item.name,
                            dataIndex: item.tag,
                            key: item.id,
                            width: '170px',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        });
                }
            });
        }
    }, []);

    useEffect(() => {
        setTableRefresh(tableRefresh + 1);
    }, [id]);

    const canCreate = () => {
        return PayrollRights.canCreateJoppd();
    };

    const setDefaultFilterValue = () => {
        return [];
    };

    const setDefaultFilterValueJOPPD = (node: string) => {
        return setJoppdCodeDefaultFilterValue(2, node);
    };

    const onRowSelect = (item: any) => {
        setIsCreate(false);

        let values = { ...item };

        Object.entries(detailsDataDefinition).forEach(([k, v]: any) => {
            if (v.tag.split('.')) {
                let enumDataType = GetEnumNameForValue({
                    enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
                    value: v.dataType,
                });

                if (_.get(values, v.tag.split('.'))) {
                    if (enumDataType === 'CODEBOOK') {
                        values = _.set(values, v.tag.split('.'), {
                            id: -1,
                            code: _.get(values, v.tag.split('.')),
                        });
                    } else if (enumDataType === 'DATE' || enumDataType === 'TIME' || enumDataType === 'TIMESTAMP') {
                        values = _.set(values, v.tag.split('.'), moment(_.get(values, v.tag.split('.')), v.pattern));
                    } else if (enumDataType === 'JOPPD_EMPLOYEE') {
                        let employee: any = {};
                        employee.text = _.get(values, v.tag.split('.'));
                        employee.oib = values['P4'] ? values['P4'] : '';
                        values = _.set(values, v.tag.split('.'), {
                            id: -1,
                            text: customEmployeeRenderOption(employee),
                        });
                    }
                }
            }
        });

        formB.setFieldsValue(values);
        if (item.P5) {
            setItemHeaderName(item.P5);
        }
        setIsModalVisible(true);
    };

    const setValues = () => {
        setIsCreate(true);

        let values = {};

        Object.entries(detailsDataDefinition).forEach(([k, v]: any) => {
            if (v.tag.split('.')) {
                let enumDataType = GetEnumNameForValue({
                    enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
                    value: v.dataType,
                });

                if (v.defaultValue) {
                    if (enumDataType === 'CODEBOOK') {
                        values = _.set(values, v.tag.split('.'), {
                            id: -1,
                            code: v.defaultValue,
                        });
                    } else if (enumDataType === 'DATE' || enumDataType === 'TIME' || enumDataType === 'TIMESTAMP') {
                        values = _.set(values, v.tag.split('.'), moment(v.defaultValue, v.pattern));
                    }
                }
            }
        });

        formB.setFieldsValue(values);
        setIsModalVisible(true);
    };

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);
        setSelectedValues(selectedRowKeys);
    };

    const deleteUserFromJoppd = () => {
        if (id !== 'create') {
            NyRequestResolver.requestDelete(CONSTANTS_REQ.JOPPD.XML_DETAILS_LIST + '/' + id, undefined, {
                id: parseInt(id),
                recordIds: selectedValues,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    setTableRefresh(tableRefresh + 1);
                } else if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const onModalSave = () => {
        setLoading(true);
        formB
            .validateFields()
            .then((values: any) => {
                Object.entries(values).forEach(([k, v]: any) => {
                    if (moment.isMoment(v)) {
                        values[k] = getDateFormat(v, 'yyyy-MM-DD');
                    }
                });

                let dataForm: any = { ...values };
                dataForm.id = parseInt(id);
                dataForm.recordId = parseInt(dataForm['P1']);

                Object.entries(detailsDataDefinition).forEach(([k, v]: any) => {
                    if (v.tag.split('.')) {
                        let enumDataType = GetEnumNameForValue({
                            enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
                            value: v.dataType,
                        });
                        if (enumDataType === 'CODEBOOK') {
                            if (_.get(dataForm, v.tag.split('.')) !== undefined) {
                                dataForm = _.set(dataForm, v.tag.split('.'), _.get(dataForm, v.tag.split('.')).code);
                            }
                        } else if (enumDataType === 'JOPPD_EMPLOYEE') {
                            if (_.get(dataForm, v.tag.split('.')) !== undefined) {
                                let employee = _.get(dataForm, v.tag.split('.'));
                                if (
                                    employee.text &&
                                    employee.text.props &&
                                    employee.text.props.children.length > 0 &&
                                    employee.text.props.children[0] &&
                                    employee.text.props.children[0].props &&
                                    employee.text.props.children[0].props.children
                                ) {
                                    dataForm = _.set(
                                        dataForm,
                                        v.tag.split('.'),
                                        employee.text.props.children[0].props.children
                                    );
                                }
                            }
                        }
                    }
                });

                if (!isCreate) {
                    NyRequestResolver.requestPut(CONSTANTS_REQ.JOPPD.XML_DETAILS_LIST + '/' + id, undefined, {
                        ...dataForm,
                    }).then((result: any) => {
                        if (setLoading) setLoading(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            if (setLoading) setLoading(false);
                            setTableRefresh(tableRefresh + 1);
                            okNotification();
                            onModalClose();
                        } else {
                            if (result && result.status === RESPONSE.BAD_REQUEST) {
                                if (result.data && result.data.field) {
                                    switch (result.data.field) {
                                        case 'username_exist':
                                            errorNotification(geti18nText('app.default.username_exist'));
                                            return;
                                        case 'mandatory_fields_required':
                                            errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                            return;
                                        default:
                                            break;
                                    }
                                }
                            }
                            if (result.data && result.data.error) {
                                if (geti18nText(result.data.error) != '') {
                                    errorNotification(geti18nText(result.data.error));
                                } else {
                                    errorNotification(JSON.stringify(result.data.error));
                                }
                            } else {
                                errorNotification();
                                console.log(result);
                            }
                        }
                    });
                } else {
                    NyRequestResolver.requestPost(CONSTANTS_REQ.JOPPD.XML_DETAILS_LIST + '/' + id, undefined, {
                        ...dataForm,
                    }).then((result: any) => {
                        if (setLoading) setLoading(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            if (setLoading) setLoading(false);
                            setTableRefresh(tableRefresh + 1);
                            okNotification();
                            onModalClose();
                        } else {
                            if (result && result.status === RESPONSE.BAD_REQUEST) {
                                if (result.data && result.data.field) {
                                    switch (result.data.field) {
                                        case 'username_exist':
                                            errorNotification(geti18nText('app.default.username_exist'));
                                            return;
                                        case 'mandatory_fields_required':
                                            errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                            return;
                                        default:
                                            break;
                                    }
                                }
                            }
                            if (result.data && result.data.error) {
                                if (geti18nText(result.data.error) != '') {
                                    errorNotification(geti18nText(result.data.error));
                                } else {
                                    errorNotification(JSON.stringify(result.data.error));
                                }
                            } else {
                                errorNotification();
                                console.log(result);
                            }
                        }
                    });
                }
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const onModalClose = () => {
        formB.resetFields();
        setItemHeaderName(<p></p>);
        setLeftCollapseActiveKeys(['1']);
        setRightCollapseActiveKeys([]);
        setIsModalVisible(false);
    };

    const employeeOnChange = (value: any) => {
        if (value && value.id > 0) {
            let parms: any = {
                employeeId: value.id,
            };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.JOPPD, parms).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        let employeeData: any = {};
                        if (result.data['P2']) {
                            employeeData['P2'] = { id: -1, code: result.data['P2'] };
                        }
                        if (result.data['P3']) {
                            employeeData['P3'] = { id: -1, code: result.data['P3'] };
                        }
                        if (result.data['P9']) {
                            employeeData['P9'] = { id: -1, code: result.data['P9'] };
                        }
                        if (result.data['P61']) {
                            employeeData['P61'] = { id: -1, code: result.data['P61'] };
                        }
                        if (result.data['P4']) {
                            employeeData['P4'] = result.data['P4'];
                        }
                        formB.setFieldsValue(employeeData);
                    }
                }
            });
        }
    };

    const getDataTypeInput = (item: any) => {
        let enumDataType = GetEnumNameForValue({
            enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
            value: item.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return <Input id={item.node} />;
            case 'NUMBER':
                let patternN;
                if (item.pattern) {
                    patternN = JSON.parse(item.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                        disabled={item.tag === 'P1'}
                        nyTestId={item.node}
                    />
                );
            case 'BOOL':
                return <Checkbox id={item.node} />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} nyTestId={item.node} />;
            case 'TIME':
                return (
                    <NyDatePicker mode={'time'} format={item.pattern} style={{ width: '100%' }} nyTestId={item.node} />
                );
            case 'TIMESTAMP':
                return (
                    <NyDatePicker
                        showTime={true}
                        format={item.pattern}
                        style={{ width: '100%' }}
                        nyTestId={item.node}
                    />
                );
            case 'ENUM':
                return (
                    <Select
                        options={getEnumArray(item.sourceType, 'label', 'value')}
                        autoFocus={true}
                        style={{ width: '100%' }}
                    />
                );
            case 'CODEBOOK':
                let temp: string[] = item.sourceType.split('.', 2);
                return (
                    <NySearchField
                        url={CONSTANTS_REQ[temp[0]][temp[1]]}
                        map={{ id: 'id', label: item.pattern }}
                        setDefaultFilterValue={() => setDefaultFilterValueJOPPD(item.node)}
                        searchBy={item.pattern}
                        itemName={item.pattern}
                        SearchPopupComponent={
                            <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValueJOPPD(item.node)} />
                        }
                        style={{ width: '100%' }}
                        addNewItem={true}
                        nyTestId={item.node}
                    />
                );
            case 'JOPPD_EMPLOYEE':
                return (
                    <NySearchField
                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                        map={{ id: 'id', label: 'text', oib: 'person.oib' }}
                        searchBy="person.first_name || ' ' || person.last_name"
                        itemName={[
                            ['person', 'firstName'],
                            ['person', 'lastName'],
                        ]}
                        renderOption={customEmployeeRenderOption}
                        customItemNameLabel={'firstName lastName'}
                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="pageB"/>}
                        nyTestId={item.node}
                        onChange={employeeOnChange}
                        order="person.last_name, person.first_name"
                    />
                );
            default:
                return <Input id={item.node} />;
        }
    };

    const generateForm = () => {
        let itemsLeft: any = [];
        let itemsRight: any = [];

        if (detailsDataDefinitionGrouped) {
            for (let key: any = 1; key < 2; key++) {
                if (
                    detailsDataDefinitionGrouped &&
                    detailsDataDefinitionGrouped[key] &&
                    detailsDataDefinitionGrouped[key].length > 0
                ) {
                    itemsLeft.push(
                        <Panel
                            header={detailsDataDefinitionGrouped[key][0].joppdNodeGroup.name}
                            key={key}
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                {detailsDataDefinitionGrouped[key].map((item: any) => {
                                    return (
                                        <Col span={12}>
                                            <Form.Item
                                                label={item.node + ' ' + item.name}
                                                name={item.tag.split('.')}
                                                rules={[
                                                    {
                                                        required: item.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Panel>
                    );
                }
            }

            for (let key: any = 2; key < 4; key++) {
                if (
                    detailsDataDefinitionGrouped &&
                    detailsDataDefinitionGrouped[key] &&
                    detailsDataDefinitionGrouped[key].length > 0
                ) {
                    itemsRight.push(
                        <Panel
                            header={detailsDataDefinitionGrouped[key][0].joppdNodeGroup.name}
                            key={key}
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                {detailsDataDefinitionGrouped[key].map((item: any) => {
                                    return (
                                        <Col span={12}>
                                            <Form.Item
                                                label={item.node + ' ' + item.name}
                                                name={item.tag.split('.')}
                                                rules={[
                                                    {
                                                        required: item.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Panel>
                    );
                }
            }
        }

        return (
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        {itemsLeft}
                    </Collapse>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        {itemsRight}
                    </Collapse>
                </Col>
            </Row>
        );
    };

    return (
        <React.Fragment>
            <NyDataTable
                nyId="human-page-b-table"
                rowKey="P1"
                url={CONSTANTS_REQ.JOPPD.XML_DETAILS_LIST}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={columns}
                addedData={{ id: id }}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onRowSelect={onRowSelect}
                hideNewButton
                fetchWhenChange={tableRefresh}
                showRowSelection={canCreate()}
                rowSelectionModal={setSelectedValuesFunc as any}
                rowSelectionType="checkbox"
                setDefaultPageSize={table.setDefaultPageSize()}
            />
            {isModalVisible && (
                <Modal
                    title={itemHeaderName}
                    visible={isModalVisible}
                    width={1200}
                    maskClosable={false}
                    centered
                    okText={geti18nText('app.default.button.save')}
                    onOk={onModalSave}
                    okButtonProps={{ style: { display: canCreate() ? 'inline' : 'none' } }}
                    onCancel={onModalClose}
                    confirmLoading={loading}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={formB}
                        onFinishFailed={({ errorFields }) => {
                            formB.scrollToField(errorFields[0].name);
                        }}
                    >
                        {generateForm()}
                    </Form>
                </Modal>
            )}
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {canCreate() && (
                        <NyModalConfirm
                            title={geti18nText('app.default.destructive.confirm')}
                            onConfirm={() => {
                                deleteUserFromJoppd();
                            }}
                        >
                            <Button icon={<DeleteOutlined />} disabled={!hasSelected}>
                                {geti18nText('app.default.button.delete')}
                            </Button>
                        </NyModalConfirm>
                    )}
                    {joppdActive && canCreate() && (
                        <NyModalConfirm
                            title={geti18nText('app.default.destructive.confirm')}
                            onConfirm={() => {
                                deactivateJoppd(false);
                            }}
                        >
                            <Button style={{ marginRight: '1em' }} key="deactivate" type="primary" danger>
                                {geti18nText('app.default.button.deactivate')}
                            </Button>
                        </NyModalConfirm>
                    )}
                    {!joppdActive && canCreate() && (
                        <Tooltip
                            placement="top"
                            visible={showTooltip}
                            title={geti18nText('app.default.shortcuts.activate')}
                        >
                            <Button
                                key="activate"
                                style={{ backgroundColor: 'green', color: 'white', marginRight: '1em' }}
                                disabled={loading}
                                onClick={() => deactivateJoppd(true)}
                            >
                                {geti18nText('app.default.button.activate')}
                            </Button>
                        </Tooltip>
                    )}

                    {canCreate() && (
                        <Button style={{ marginRight: '1em' }} key="submit" type="primary" onClick={setValues}>
                            {geti18nText('app.default.button.add')}
                        </Button>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PageB;
