import { EllipsisOutlined } from '@ant-design/icons';
import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { numberFormat } from '../../../../utils/Utils';
import EmployeeTravelRelationEdit from './EmployeeTravelRelationEdit';
import GenerateEmployeeTravelRelationPaymentModal from './GenerateEmployeeTravelRelationPaymentModal';

const EmployeeTravelRelationTable = ({
    disabled,
    employeeTravelRelationPayment,
    scroll = { y: 500, x: 800 },
    sortOrder,
}: any) => {
    const { id } = useParams<any>();
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const [modalVisible, setModalVisible] = useState(false);
    const table = useTableSettings();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelLocal();
    };

    const setDefaultFilterValue = () => {
        return employeeTravelRelationPayment
            ? [
                  { field: 'active', condition: 'equals_bool', value: 1 },
                  {
                      field: 'employee_travel_relation_payment_id',
                      condition: 'equals',
                      value: employeeTravelRelationPayment,
                  },
              ]
            : [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button onClick={() => setModalVisible(true)} block>
                        {geti18nText('employeeTravelRelation.generateEmployeeTravelRelationPayment')}
                    </Button>
                </div>
            </div>
        </Menu>
    );

    const actionButtons = (onSave: () => void) => {
        return (
            canCreate() && (
                <div style={{ float: 'left', paddingLeft: '15px' }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )
        );
    };

    return (
        <>
            <NyDataTable
                nyId="travel-warrant-employee-travel-relation"
                url={CONSTANTS_REQ.EMPLOYEE_TRAVEL_RELATION.LIST}
                addNewButtonText={geti18nText('employeeTravelRelation.table.add')}
                buttonsClassName={disabled ? '' : 'buttons-sticky'}
                hideButtons={disabled}
                showRecordModal={!disabled}
                readonly={disabled}
                fetchWhenChange={id || refreshItem}
                scroll={scroll}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultFilterValue={setDefaultFilterValue}
                modalComponent={EmployeeTravelRelationEdit}
                addedButtons={actionButtons}
                columns={[
                    {
                        title: geti18nText('employeeTravelRelation.table.column.id'),
                        dataIndex: 'id',
                        width: '5%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.travelDate'),
                        dataIndex: 'travelDate',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(false),
                        render: (text: any, record: any) => {
                            if (record.travelDate) {
                                return moment(record.travelDate).format('DD.MM.YYYY');
                            }
                        },
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.employee'),
                        dataIndex:
                            "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (employee: any, record: any) => {
                            if (record && record.employee && record.employee.person) {
                                return (
                                    <>
                                        {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                                        {record.employee.employmentRecordId &&
                                            ` (${record.employee.employmentRecordId})`}
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.travelRelation'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.travelVehicle'),
                        dataIndex: ['travelVehicle', 'name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchByCodebookSimple(
                            CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH,
                            'travelVehicle.id',
                            'name',
                            'name',
                            {
                                id: 'id',
                                label: 'name',
                                text: 'name',
                            }
                        ),
                        render: (text: any, recrod: any) => {
                            if (recrod.travelVehicle) {
                                return recrod.travelVehicle.name;
                            }
                        },
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.counterStart'),
                        dataIndex: 'counterStart',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.counterEnd'),
                        dataIndex: 'counterEnd',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.distance'),
                        dataIndex: 'distance',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.price'),
                        dataIndex: 'price',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (price: any) => {
                            return price ? numberFormat(price) : '';
                        },
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.amount'),
                        dataIndex: 'amount',
                        align: 'right',
                        width: '6%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (amount: any) => {
                            return (
                                <div style={{ float: 'right', textAlign: 'right' }}>
                                    {amount ? numberFormat(amount) : ''}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('employeeTravelRelation.table.column.ordinal'),
                        dataIndex: 'employee_travel_relation_payment_id',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (id: any, data: any) => {
                            return data.employeeTravelRelationPayment && data.employeeTravelRelationPayment.ordinal
                                ? data.employeeTravelRelationPayment.ordinal
                                : '';
                        },
                    },
                ]}
                shortcuts={true}
                hideNewButton={!canCreate()}
                headerTitle={geti18nText('menu.employeeTravelRelation')}
            />
            <GenerateEmployeeTravelRelationPaymentModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
        </>
    );
};

export default EmployeeTravelRelationTable;
