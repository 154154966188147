import { EyeInvisibleOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
    NyLanguageSelector,
    NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleItem } from '../../../components/sortable-list/TitleItem';
import { RootState } from '../../../rootReducer';
import { setFont, setGeneralSettings, setTheme } from '../../../slices/generalSettingsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import DEFAULT_NOTIFICATION_SETTINGS from '../../../utils/NotificationSettings';
import { errorNotification, getEnumArray, getEnumFormat, okNotification } from '../../../utils/Utils';
import ShortcutsEdit from './ShortcutsEdit';
import { SortableItem } from './SortableItem';

const { Panel } = Collapse;

const SettingsIndex = (props: any) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const shortcutRef = useRef<any>();
    const settingKey = 'general';
    const buttonsClassName = 'buttons-sticky';
    const [colorChange, setColorChange] = useState(false);

    const themeEnums = [
        { id: 'default', text: geti18nText('settings.general.theme.default') },
        { id: 'dark', text: geti18nText('settings.general.theme.dark') },
        { id: 'light', text: geti18nText('settings.general.theme.light') },
    ];
    const fontEnums = [
        { id: 14, text: '14px' },
        { id: 16, text: '16px' },
    ];
    const languageEnums = NySession.getAppValue('I18N').language.available.map((value: any) => {
        return { id: value, text: value.toUpperCase() };
    });
    const numbersWritingEnums = [
        { id: 'true', text: geti18nText('settings.general.numbersWriting.leftToRight') },
        { id: 'false', text: geti18nText('settings.general.numbersWriting.rightToLeft') },
    ];
    const itemGroupViewEnums = [
        { id: 'tree', text: geti18nText('webshopItem.itemGroup.tree') },
        { id: 'folder', text: geti18nText('webshopItem.itemGroup.folder') },
    ];

    const [settingsId, setSettingsId] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1', '3']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [notificationSettings, setNotificationSettings] = useState<any>([]);
    const [defNotificationLanguage, setDefNotificationLanguage] = useState<any>('');
    const [sendWithEmail, setSendWithEmail] = useState(false);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [defTheme, setDefTheme] = useState<any>('default');
    const [defFont, setDefFont] = useState<any>(14);
    const { theme, font } = useSelector((state: RootState) => state.generalSettings);

    /// FETCH AND SET DATA
    useEffect(() => {
        fetch();
        getNotificationSettings();
    }, []);

    const hasDifferentLanguages = (notificationSettings: any) => {
        const uniqueLanguages = new Set();

        for (const item of notificationSettings) {
            if (uniqueLanguages.has(item.language)) {
                return false;
            }
            uniqueLanguages.add(item.language);
        }
        return true;
    };

    useEffect(() => {
        const languages = notificationSettings.map((item: any) => item.language);

        if (!hasDifferentLanguages(notificationSettings)) {
            setDefNotificationLanguage(
                languages.every((val: any, i: any, arr: any) => val === arr[0]) ? languages[0] : ''
            );
        }
    }, [notificationSettings]);

    const fetch = () => {
        var parms: any = { settingKey: settingKey };
        if (settingKey) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey,
                parms
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setValues(result.data);
                    }
                }
            });
        }
    };

    const getNotificationSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION_SETTINGS.LIST, { max: 100 }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                let notifications: any = [];
                if (result.data && result.data.content) {
                    getEnumArray('NOTIFICATION_TYPE').map((item: any) => {
                        result.data.content.map((setting: any) => {
                            if (item.id === setting.notificationType) {
                                notifications.push({
                                    ...item,
                                    id: setting.id,
                                    color: setting.color,
                                    sort: setting.sort,
                                    notificationType: setting.notificationType,
                                    mail: setting.mail,
                                    mobile: setting.mobile,
                                    web: setting.web,
                                    language: setting.language,
                                });
                            }
                        });
                    });
                    setNotificationSettings(
                        notifications.sort((a: any, b: any) => {
                            return a.sort - b.sort;
                        })
                    );
                } else {
                    let notifications: any = [];
                    getEnumArray('NOTIFICATION_TYPE').map((item: any) => {
                        DEFAULT_NOTIFICATION_SETTINGS.map((setting: any) => {
                            if (item.id === setting.notificationType) {
                                notifications.push({
                                    ...item,
                                    color: setting.color,
                                    sort: setting.sort,
                                    notificationType: setting.notificationType,
                                    mail: setting.mail,
                                    mobile: setting.mobile,
                                    web: setting.web,
                                    language: setting.language,
                                });
                            }
                        });
                    });
                    setNotificationSettings(
                        notifications.sort((a: any, b: any) => {
                            return a.sort - b.sort;
                        })
                    );
                }
            } else {
                let notifications: any = [];
                getEnumArray('NOTIFICATION_TYPE').map((item: any) => {
                    DEFAULT_NOTIFICATION_SETTINGS.map((setting: any) => {
                        if (item.id === setting.notificationType) {
                            notifications.push({
                                ...item,
                                color: setting.color,
                                sort: setting.sort,
                                notificationType: setting.notificationType,
                                mail: setting.mail,
                                mobile: setting.mobile,
                                web: setting.web,
                                language: setting.language,
                            });
                        }
                    });
                });
                setNotificationSettings(
                    notifications.sort((a: any, b: any) => {
                        return a.sort - b.sort;
                    })
                );
            }
        });
    };

    function setValues(dataForm: any) {
        let newDataForm: any = {};

        setSettingsId(dataForm.id);
        newDataForm['id'] = dataForm.id;

        let settingsValue = JSON.parse(dataForm.settingValue);
        if (settingsValue) {
            if (settingsValue.language) {
                newDataForm['language'] = languageEnums.find((lang: any) => lang.id === settingsValue.language);
            }
            if (settingsValue.theme) {
                newDataForm['theme'] = themeEnums.find((theme: any) => theme.id === settingsValue.theme);
                setDefTheme(newDataForm['theme']);
            }
            if (settingsValue.font) {
                newDataForm['font'] = fontEnums.find((theme: any) => theme.id === settingsValue.font);
                setDefFont(newDataForm['font']);
            }
            if (settingsValue.numbersWriting) {
                newDataForm['numbersWriting'] = numbersWritingEnums.find(
                    (type: any) => type.id === settingsValue.numbersWriting
                );
            }
            if (settingsValue.itemGroupView) {
                newDataForm['itemGroupView'] = itemGroupViewEnums.find(
                    (type: any) => type.id === settingsValue.itemGroupView
                );
            }
        }

        form.setFieldsValue(newDataForm);
    }
    /// END FETCH AND SET DATA

    /// SAVE DATA
    const save = (event: any) => {
        event.preventDefault();
        saveNotifications();
        form.validateFields()
            .then((values: any) => {
                if (shortcutRef !== undefined) {
                    shortcutRef.current.saveShortcut(event);
                }
                let settingsValue = { ...values };
                delete settingsValue.id;
                if (settingsValue.language) settingsValue.language = getEnumFormat(settingsValue.language);
                if (settingsValue.theme) {
                    settingsValue.theme = getEnumFormat(settingsValue.theme);
                    setDefTheme(settingsValue.theme);
                }
                if (settingsValue.font) {
                    settingsValue.font = getEnumFormat(settingsValue.font);
                    setDefFont(settingsValue.font);
                }
                if (settingsValue.numbersWriting)
                    settingsValue.numbersWriting = getEnumFormat(settingsValue.numbersWriting);
                if (settingsValue.itemGroupView)
                    settingsValue.itemGroupView = getEnumFormat(settingsValue.itemGroupView);
                let parms: any = {};
                parms.id = values.id;
                parms.settingKey = settingKey;
                parms.settingValue = JSON.stringify(settingsValue);
                NyRequestResolver.requestPut(
                    CONSTANTS_REQ.USER_SESTTINGS.EDIT + '/' + settingsId,
                    undefined,
                    parms
                ).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        dispatch(setGeneralSettings(settingsValue));
                        okNotification();
                        window.location.reload();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'username_exist':
                                        errorNotification(geti18nText('app.default.username_exist'));
                                        return;
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(geti18nText(result.data.error));
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                            console.log(result);
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const saveNotifications = () => {
        let parms = { notificationSettingsList: notificationSettings };
        NyRequestResolver.requestPost(CONSTANTS_REQ.NOTIFICATION_SETTINGS.SAVE, undefined, parms);
    };
    /// END SAVE DATA

    /// COLLAPSE
    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '3']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );
    /// END COLLAPSE

    /// BUTTONS ACTION
    const onCancel = () => {
        if (defTheme != null) {
            let settingsValue: any = {};
            settingsValue.theme = getEnumFormat(defTheme);
            dispatch(setTheme(settingsValue));
        }
        if (defFont != null) {
            let settingsValue: any = {};
            settingsValue.font = getEnumFormat(defFont);
            dispatch(setFont(settingsValue));
        }
        history.goBack();
        // dispatch(removeTab(location.pathname));
    };
    const addNewShortcut = () => (
        <Tooltip title={geti18nText('settings.shortcuts.addNew')} placement="topRight">
            <PlusCircleOutlined
                className="ny-note-modal-icon"
                onClick={(event: any) => {
                    event.stopPropagation();
                    if (shortcutRef !== undefined) {
                        shortcutRef.current.addNewShortcut();
                    }
                }}
            />
        </Tooltip>
    );
    /// END BUTTONS ACTIO

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        const notificationData = [...notificationSettings];
        const oldIndex = notificationData.findIndex((item: any) => item.id === active.id);
        const newIndex = notificationData.findIndex((item: any) => item.id === over.id);

        let sorted = arrayMove(notificationData, oldIndex, newIndex);
        sorted = sorted.map((element: any, index: any) => {
            return { ...element, sort: index + 1 };
        });
        setNotificationSettings(sorted);
    };

    const handleChangeColor = (color: any, id: any) => {
        let newData = [...notificationSettings];
        const index = notificationSettings.findIndex((item: any) => item.id === id);
        if (index >= 0) {
            newData[index].color = color;
        }
        setNotificationSettings(newData);
    };

    const handleChangeWeb = (web: any, id: any) => {
        let newData = [...notificationSettings];
        const index = notificationSettings.findIndex((item: any) => item.id === id);
        if (index >= 0) {
            newData[index].web = web ? true : false;
        }
        setNotificationSettings(newData);
    };
    const handleChangeMobile = (mobile: any, id: any) => {
        let newData = [...notificationSettings];
        const index = notificationSettings.findIndex((item: any) => item.id === id);
        if (index >= 0) {
            newData[index].mobile = mobile ? true : false;
        }
        setNotificationSettings(newData);
    };
    const handleChangeMail = (mail: any, id: any) => {
        let newData = [...notificationSettings];
        const index = notificationSettings.findIndex((item: any) => item.id === id);
        if (index >= 0) {
            newData[index].mail = mail ? true : false;
        }
        setNotificationSettings(newData);
    };
    const handleChangeLanguage = (language: any, id: any) => {
        let newData = [...notificationSettings];
        const index = notificationSettings.findIndex((item: any) => item.id === id);
        if (index >= 0) {
            newData[index].language = language.lang;
        }
        setNotificationSettings(newData);
    };

    const { switcher, currentTheme, status, themes } = useThemeSwitcher();

    useEffect(() => {
        themeSwitcher();
    }, [theme, font]);

    function themeSwitcher() {
        if (font === 16) {
            switcher({ theme: themes[`${theme}_${font}`] });
        } else {
            switcher({ theme: themes[`${theme}`] });
        }
    }

    const onThemeChange = (value: any) => {
        if (value !== undefined) {
            let settingsValue: any = {};
            settingsValue.theme = getEnumFormat(value);
            dispatch(setTheme(settingsValue));
        }
    };

    const onFontChange = (value: any) => {
        if (value !== undefined) {
            let settingsValue: any = {};
            settingsValue.font = getEnumFormat(value);
            console.log(settingsValue);
            dispatch(setFont(settingsValue));
        }
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
            {...props}
        >
            <Row gutter={24}>
                <Col xs={32} sm={32} md={32} lg={16} xl={16}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('settings.general')} key="1">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('settings.general.language')}
                                        name="language"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={languageEnums.find((lang: any) => lang.id === 'hr')}
                                    >
                                        <NySearchField
                                            options={languageEnums}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            autoFocus={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('settings.general.theme')}
                                        name="theme"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={themeEnums.find((theme: any) => theme.id === 'default')}
                                    >
                                        <NySearchField
                                            options={themeEnums}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            onChange={onThemeChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('settings.general.font')}
                                        name="font"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={fontEnums.find((theme: any) => theme.id === 14)}
                                    >
                                        <NySearchField
                                            options={fontEnums}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            onChange={onFontChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('settings.general.numbersWriting')}
                                        name="numbersWriting"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={numbersWritingEnums.find((type: any) => type.id === true)}
                                    >
                                        <NySearchField
                                            options={numbersWritingEnums}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('webshopItem.itemGroup.title')}
                                        name="itemGroupView"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={itemGroupViewEnums.find((type: any) => type.id === 'tree')}
                                    >
                                        <NySearchField
                                            options={itemGroupViewEnums}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.notification.order')} key="3">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('notification.email.language.label')}>
                                        <NyLanguageSelector
                                            disabled={!sendWithEmail}
                                            selectedLanguage={defNotificationLanguage}
                                            value={defNotificationLanguage}
                                            languages={NySession.getAppValue('I18N').language.available}
                                            onSelect={(lang: any) => {
                                                setDefNotificationLanguage(lang.lang);
                                                let newData = [...notificationSettings];
                                                newData.map((item) => (item.language = lang.lang));
                                                setNotificationSettings(newData);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <TitleItem className={'title-item'} typeModal={'my-profile-settings'} />
                                    <DndContext
                                        onDragEnd={handleDragEnd}
                                        collisionDetection={closestCenter}
                                        sensors={sensors}
                                    >
                                        <SortableContext
                                            items={notificationSettings.map((item: any) => item.id)}
                                            strategy={verticalListSortingStrategy}
                                        >
                                            <Form>
                                                {notificationSettings &&
                                                    notificationSettings.map((item: any, index: any) => {
                                                        if (item.mail && !sendWithEmail) {
                                                            setSendWithEmail(true);
                                                        }
                                                        return (
                                                            <SortableItem
                                                                key={item.id}
                                                                id={item.id}
                                                                name={item.text}
                                                                color={item.color}
                                                                index={index}
                                                                handleChangeColor={handleChangeColor}
                                                                web={item.web}
                                                                mobile={item.mobile}
                                                                mail={item.mail}
                                                                language={item.language}
                                                                handleChangeWeb={handleChangeWeb}
                                                                handleChangeMobile={handleChangeMobile}
                                                                handleChangeMail={handleChangeMail}
                                                                handleChangeLanguage={handleChangeLanguage}
                                                                colorChange={colorChange}
                                                                setColorChange={setColorChange}
                                                                defNotificationLanguage={defNotificationLanguage}
                                                                setDefNotificationLanguage={setDefNotificationLanguage}
                                                            />
                                                        );
                                                    })}
                                            </Form>
                                        </SortableContext>
                                    </DndContext>
                                    {notificationSettings.length < 1 && <NySpinner />}
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('settings.shortcuts')} key="2" extra={addNewShortcut()}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <ShortcutsEdit
                                        {...props}
                                        ref={shortcutRef}
                                        colorChange={colorChange}
                                        setColorChange={setColorChange}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={buttonsClassName}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    <Button style={{ marginRight: '1em' }} onClick={onCancel}>
                        {geti18nText('app.default.button.cancel')}
                    </Button>
                    <Button
                        style={{ marginRight: '1em' }}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={(e) => save(e)}
                    >
                        {geti18nText('app.default.button.save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SettingsIndex;
