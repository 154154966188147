import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker } from '@nybble/nyreact';
import { Modal, Form, Row, Col, Button, Checkbox } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../components/report-button';
import { getDateFormat } from '../../utils/Utils';
import useEnum from '../../hooks/useEnum';
import ReportExportType from '../report-export-type/ReportExportType';

const AssetFlowSpecificationReportModal = ({
    assetId,
    reportType,
    reportTitle,
    reportFileName,
    url,
    allowExportTypeChange = true,
    visible,
    setVisible,
}: any) => {
    const [form] = Form.useForm();
    const enExportType = useEnum('EXPORT_TYPE');
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('month'));
    const [dateTo, setDateTo] = useState<any>(moment());
    const [releaseReverse, setReleaseReverse] = useState<any>(false);
    const [exportType, setExportType] = useState(enExportType.PDF);

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values.dateFrom));
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    return (
        <>
            <Modal
                visible={visible}
                title={geti18nText(reportTitle)}
                onCancel={(event: any) => {
                    setVisible(false);
                    form.resetFields();
                    setDateFrom(moment().startOf('month'));
                    setDateTo(moment());
                    setReleaseReverse(false);
                    setExportType(enExportType.PDF);
                    event.stopPropagation();
                    event.preventDefault();
                }}
                maskClosable={false}
                footer={[
                    <NyReportButton
                        url={url}
                        customParms={{
                            assetId: assetId,
                            dateFrom: getDateFormat(dateFrom),
                            dateTo: getDateFormat(dateTo),
                            releaseReverse: `${releaseReverse}`,
                            exportType: exportType ? exportType : enExportType.PDF,
                        }}
                        subreportType={reportType}
                        buttoni18nText={reportTitle}
                        fileName={reportFileName}
                        checkBeforeSave={checkBeforeSave}
                        selectedExportType={exportType}
                    />,
                ]}
            >
                <Form
                    form={form}
                    onFinishFailed={({ errorFields }) => {
                        form.scrollToField(errorFields[0].name);
                    }}
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {allowExportTypeChange && (
                        <ReportExportType exportType={exportType} setExportType={setExportType} />
                    )}
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.report.dateFrom')}
                                name="dateFrom"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                initialValue={moment().startOf('month')}
                            >
                                <NyDatePicker
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => {
                                        setDateFrom(value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.report.dateTo')}
                                name="dateTo"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                initialValue={moment()}
                            >
                                <NyDatePicker
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }}
                                    onChange={(value: any) => {
                                        setDateTo(value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('asset.report.releaseReverse')} name="releaseReverse">
                                <Checkbox
                                    defaultChecked={releaseReverse}
                                    onChange={(val: any) => {
                                        setReleaseReverse(val.target.checked);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default AssetFlowSpecificationReportModal;
