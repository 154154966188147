import { BookOutlined, EllipsisOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Dropdown, Form, Input, Menu, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../components/note-modal';
import NyReportButton from '../../../../components/report-button';
import useEnum from '../../../../hooks/useEnum';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';
import { updateHistoryI18n } from '../../../../slices/historySlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setSearchFormat,
    warningNotification,
} from '../../../../utils/Utils';
import CompanySearch from '../../../administration/views/company/search';
import WarehouseIndex from '../warehouse';
import WarehouseDocumentSearch from '../warehouse-document/search';
import WarehouseEdit from '../warehouse/edit';
import CreateAssetAcquisitionModal from './CreateAssetAcquisitionModal';
import ItemsIndex from './ItemsIndex';

const { Panel } = Collapse;

const WarehouseReceiptEdit = (props: any) => {
    const history = useHistory();
    const location = useLocation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams<any>();
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const [editHeader, setEditHeader] = useState(geti18nText('warehouseReceipt.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isItemChange, setIsItemChange] = useState(false);
    const [items, setItems] = useState<any>([]);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>([]);
    const [bottomCollapseActiveKeys, setBottomCollapseActiveKeys] = useState<any>(['1']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [note, setNote] = useState<any>(undefined);
    const [showTooltip, setShowTooltip] = useState(false);
    const [bookedStock, setBookedStock] = useState<boolean>(false);
    const [bookedCost, setBookedCost] = useState<boolean>(false);
    const [bookedAsset, setBookedAsset] = useState<boolean>(false);
    const [title, setTitle] = useState('');
    const [warehouseId, setWarehouseId] = useState<any>(null);
    const [isFormChange, setIsFormChange] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(0);
    const [ord, setOrd] = useState<any>(null);
    const [warehouseCompany, setWarehouseCompany] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [warehouseDocument, setWarehouseDocument] = useState<any>(null);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    useEffect(() => {
        form.resetFields();
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    useEffect(() => {
        if (dataForm == null && id == 'create') {
            let filterWarehouseDocument = setDefaultFilterValueWarehouseDocument();
            filterWarehouseDocument.push({
                field: 'predefined',
                condition: 'equals_bool',
                value: 1,
            });
            NyRequestResolver.requestGet(CONSTANTS_REQ.WAREHOUSE_DOCUMENT.SEARCH, {
                search: encodeURI(JSON.stringify(filterWarehouseDocument)),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data.length && result.data.length > 0 && result.data[0]) {
                        form.setFieldsValue({ warehouseDocument: setSearchFormat(result.data[0], 'name', 'name') });
                    }
                }
            });
        }
    }, [dataForm]);

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseReceipt();
    };

    const canCreateAcquisition = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseReceiptAssetAcquisition();
    };

    const disabledAssetBooked = () => {
        return dataForm && (!canCreate() || bookedStock || bookedAsset);
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultFilterValueWarehouseDocument = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 1 },
        ];
    };

    function setValues(dataForm: any) {
        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setTitle(dataForm.ord + '/' + moment(dataForm.date).format('YYYY'));
            setEditHeader(
                geti18nText('warehouseReceipt.edit.title') +
                    ' ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
        }
        setOrd(dataForm.ord ? dataForm.ord : null);
        setBookedStock(dataForm.bookedStock);
        setBookedCost(dataForm.bookedCost);

        if (dataForm.warehouse) {
            setWarehouseId(dataForm.warehouse.id);
            dataForm.warehouse = setSearchFormat(dataForm.warehouse, 'name', 'name');
        }
        if (dataForm.company) {
            setWarehouseCompany(dataForm.company);
            dataForm.company = setSearchFormat(dataForm.company, 'name', 'name');
        }
        if (dataForm.transportCompany) {
            dataForm.transportCompany = setSearchFormat(dataForm.transportCompany, 'name', 'name');
        }
        if (dataForm.date) {
            setDate(dataForm.date);
            dataForm.date = setDateFormat(dataForm.date);
        }
        if (dataForm.orderDate) {
            dataForm.orderDate = setDateFormat(dataForm.orderDate);
        }
        if (dataForm.deliveryNoteDate) {
            dataForm.deliveryNoteDate = setDateFormat(dataForm.deliveryNoteDate);
        }
        if (dataForm.invoiceDate) {
            dataForm.invoiceDate = setDateFormat(dataForm.invoiceDate);
        }
        if (dataForm.warehouseReceiptItems) {
            setItems(dataForm.warehouseReceiptItems);
        }
        if (dataForm.warehouseDocument) {
            setWarehouseDocument(dataForm.warehouseDocument.name ? dataForm.warehouseDocument.name : null);
            dataForm.warehouseDocument = setSearchFormat(dataForm.warehouseDocument, 'name', 'name');
            form.setFieldsValue({ warehouseDocument: setSearchFormat(dataForm.warehouseDocument, 'name', 'name') });
        }
        setBookedAsset(dataForm.bookedAsset ? dataForm.bookedAsset : false);

        setNote(dataForm.note ? dataForm.note : undefined);
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
            setBottomCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys([]);
            setBottomCollapseActiveKeys(['1']);
        }
        setCollapseAll(!collapseAll);
    };

    const book = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.WAREHOUSE_RECEIPT.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setBookedStock(true);
                    okNotification(geti18nText('warehouseReceipt.button.book.success'));
                    setRefresh((prevValue: any) => prevValue + 1);
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const debook = () => {
        if (dataForm) {
            setLoading(true);
            if (bookedAsset) {
                warningNotification(geti18nText('warehouseReceipt.button.bookedAsset'));
            }
            NyRequestResolver.requestPut(CONSTANTS_REQ.WAREHOUSE_RECEIPT.DE_BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setBookedStock(false);
                    okNotification(geti18nText('warehouseReceipt.button.debook.success'));
                    setRefresh((prevValue: any) => prevValue + 1);
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const actionsMenu = (
        <Menu>
            {canCreate() && (
                <div style={{ display: 'block', margin: '5px' }}>
                    {!bookedStock ? (
                        <NyModalConfirm
                            title={geti18nText('warehouseReceipt.popconfirm.booking.cancel')}
                            onConfirm={() => {
                                book();
                            }}
                        >
                            <Button icon={<BookOutlined />} style={{ width: '100%' }} disabled={isFormChange}>
                                {geti18nText('warehouseReceipt.button.booking')}
                            </Button>
                        </NyModalConfirm>
                    ) : (
                        <NyModalConfirm
                            title={geti18nText('warehouseReceipt.popconfirm.deBooking.cancel')}
                            onConfirm={() => {
                                debook();
                            }}
                        >
                            <Button icon={<BookOutlined />} style={{ width: '100%' }} disabled={isFormChange}>
                                {geti18nText('warehouseReceipt.button.totalBooking')}
                            </Button>
                        </NyModalConfirm>
                    )}
                </div>
            )}
            {canCreate() && canCreateAcquisition() && bookedStock && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <CreateAssetAcquisitionModal
                        warehouseReceiptId={dataForm}
                        warehouseId={warehouseId}
                        warehouseItems={items}
                        ord={ord}
                        company={warehouseCompany}
                        document={warehouseDocument}
                        date={date}
                        bookedAsset={bookedAsset}
                        setBookedAsset={setBookedAsset}
                        disabled={isFormChange}
                    />
                </div>
            )}
            <div style={{ display: 'block', margin: '5px' }}>
                <NyReportButton
                    url={CONSTANTS_REQ.REPORT.WAREHOUSE_RECEIPT}
                    customParms={{
                        warehouseReceiptId: dataForm,
                    }}
                    subreportType={'WAREHOUSE_RECEIPT'}
                    buttoni18nText={'warehouseReceipt.edit.generateReport.print'}
                    fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.WAREHOUSE_RECEIPT)}
                    checkBeforeSave={false}
                    disabled={isFormChange}
                />
            </div>
        </Menu>
    );

    const getActionsButton: any = (
        <Button type="primary" disabled={isFormChange}>
            {geti18nText('app.default.actions')}
            <EllipsisOutlined />
        </Button>
    );

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    )}
                </Tooltip>
                {dataForm && (
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']} overlayStyle={{ zIndex: '1' }}>
                        {isFormChange ? (
                            <Tooltip title={geti18nText('warehouseReceipt.must.save.changes')} placement="topRight">
                                {getActionsButton}
                            </Tooltip>
                        ) : (
                            getActionsButton
                        )}
                    </Dropdown>
                )}
            </div>
        </React.Fragment>
    );

    const changeId = (returnedId: any) => {
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        const pathname = '/warehouse/warehouse-receipt/' + returnedId;
        history.push(pathname);
    };

    return (
        <NyDataEdit
            layout="vertical"
            editHeader={editHeader}
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.WAREHOUSE_RECEIPT.EDIT}
            setValues={setValues}
            width={1200}
            fetchWhenChange={refresh}
            form={form}
            goBack={() => {
                history.goBack();
            }}
            onSaveAndGetID={(returnedId: any) => {
                setRefresh((prevValue: any) => prevValue + 1);
                changeId(returnedId);
                if (id === 'create') {
                    changeId(returnedId);
                }
            }}
            paramsId={id}
            buttonsClassName="buttons-sticky"
            changeCollapseVisibility={changeCollapseVisibility}
            customFooterContent={getCustomFooterContent}
            checkIsFormChanged={true}
            setIsFormChange={setIsFormChange}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            shortcuts={true}
            {...props}
            normalize={(values: any) => {
                if (values.date) values.date = getDateFormat(values.date);
                if (values.orderDate) values.orderDate = getDateFormat(values.orderDate);
                if (values.deliveryNoteDate) values.deliveryNoteDate = getDateFormat(values.deliveryNoteDate);
                if (values.invoiceDate) values.invoiceDate = getDateFormat(values.invoiceDate);

                if (values.warehouse) {
                    values.warehouse = getSearchFormat(values.warehouse);
                    if (values.warehouse.id < 1) {
                        delete values.warehouse;
                    }
                }
                if (values.warehouseDocument) {
                    values.warehouseDocument = getSearchFormat(values.warehouseDocument);
                    if (values.warehouseDocument.id < 1) {
                        delete values.warehouseDocument;
                    }
                }
                if (values.company) {
                    values.company = getSearchFormat(values.company);
                    if (values.company.id < 1) {
                        delete values.company;
                    }
                }
                if (values.transportCompany) {
                    values.transportCompany = getSearchFormat(values.transportCompany);
                    if (values.transportCompany.id < 1) {
                        delete values.transportCompany;
                    }
                }
                values.bookedAsset = bookedAsset ? true : false;

                const receiptItems: any = [];
                if (items != undefined && items.length > 0) {
                    items.map((element: any) => {
                        receiptItems.push(element);
                    });
                }
                values.warehouseReceiptItems = receiptItems;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('warehouseReceipt.tab.1')}
                            key="1"
                            extra={
                                <NyNoteModal
                                    note={note}
                                    modalTitle={
                                        geti18nText('warehouseReceipt.edit.title') +
                                        ' - ' +
                                        geti18nText('app.default.note')
                                    }
                                />
                            }
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label={geti18nText('warehouseReceipt.edit.ord')} name="ord">
                                        <NyInputNumber
                                            style={{ width: '100%' }}
                                            nyTestId="ord"
                                            disabled={disabledAssetBooked() || !dataForm}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('warehouseReceipt.edit.date')}
                                        name="date"
                                        initialValue={moment()}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NyDatePicker
                                            style={{ width: '100%' }}
                                            nyTestId="date"
                                            disabled={disabledAssetBooked()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('warehouseReceipt.edit.warehouse')}
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        name="warehouse"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.WAREHOUSE.SEARCH}
                                            map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                            addNewItem={false}
                                            disabled={disabledAssetBooked()}
                                            searchBy="name || ' ' || code"
                                            itemName={[['text'], ['code']]}
                                            customItemNameLabel={'(code) text'}
                                            renderOption={(option: any) =>
                                                `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                                            }
                                            setDefaultFilterValue={setDefaultFilterValue}
                                            AddNewModalComponent={WarehouseEdit}
                                            SearchPopupComponent={<WarehouseIndex />}
                                            onChange={(data: any) => {
                                                setWarehouseId(data && data.id > 0 ? data.id : null);
                                            }}
                                            customListWidth={'1200px'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <WarehouseDocumentSearch
                                        required={true}
                                        disabled={disabledAssetBooked()}
                                        setDefaultFilterValue={setDefaultFilterValueWarehouseDocument}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <CompanySearch
                                        disabled={disabledAssetBooked()}
                                        label={geti18nText('warehouseReceipt.edit.company')}
                                        isModal={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <CompanySearch
                                        disabled={disabledAssetBooked()}
                                        label={geti18nText('warehouseReceipt.edit.transportingCompany')}
                                        formName={'transportCompany'}
                                        isModal={true}
                                    />
                                </Col>
                            </Row>
                            {dataForm && (
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="bookedStock">
                                            <strong>{geti18nText('warehouseReceipt.edit.bookedStock') + ': '}</strong>
                                            <strong>
                                                {bookedStock
                                                    ? geti18nText('app.default.button.yes')
                                                    : geti18nText('app.default.button.no')}
                                            </strong>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="bookedCosts">
                                            <strong>{geti18nText('warehouseReceipt.edit.bookedCost') + ': '}</strong>
                                            <strong>
                                                {bookedCost
                                                    ? geti18nText('app.default.button.yes')
                                                    : geti18nText('app.default.button.no')}
                                            </strong>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('warehouseReceipt.tab.2')} key="2">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('warehouseReceipt.edit.orderDate')} name="orderDate">
                                        <NyDatePicker
                                            style={{ width: '100%' }}
                                            nyTestId="order-date"
                                            disabled={!canCreate()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('warehouseReceipt.edit.orderInfo')} name="orderInfo">
                                        <Input.TextArea
                                            rows={2}
                                            onKeyDown={onKeyDownTextAreaCustom}
                                            disabled={!canCreate()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('warehouseReceipt.tab.3')} key="3">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('warehouseReceipt.edit.deliveryDate')}
                                        name="deliveryNoteDate"
                                    >
                                        <NyDatePicker
                                            style={{ width: '100%' }}
                                            nyTestId="delivery-note-date"
                                            disabled={!canCreate()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('warehouseReceipt.edit.deliveryInfo')}
                                        name="deliveryNoteInfo"
                                    >
                                        <Input.TextArea
                                            rows={2}
                                            onKeyDown={onKeyDownTextAreaCustom}
                                            disabled={!canCreate()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('warehouseReceipt.tab.4')} key="4">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('warehouseReceipt.edit.invoiceDate')}
                                        name="invoiceDate"
                                    >
                                        <NyDatePicker
                                            style={{ width: '100%' }}
                                            nyTestId="invoice-date"
                                            disabled={!canCreate()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('warehouseReceipt.edit.invoiceInfo')}
                                        name="invoiceInfo"
                                    >
                                        <Input.TextArea
                                            rows={2}
                                            onKeyDown={onKeyDownTextAreaCustom}
                                            disabled={!canCreate()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <Collapse
                        activeKey={bottomCollapseActiveKeys}
                        onChange={(key: any) => {
                            setBottomCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('warehouseReceipt.divider.2')} key="1">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <ItemsIndex
                                        props={props}
                                        id={id}
                                        bookedStock={bookedStock}
                                        items={items}
                                        setItems={setItems}
                                        setIsItemChange={setIsItemChange}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Prompt message={''} when={isItemChange || isFormChange} />
        </NyDataEdit>
    );
};

export default WarehouseReceiptEdit;
