import { NyColorPicker, NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Switch, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getTransparentColor, onKeyDownTextAreaCustom } from '../../../../utils/Utils';

const { TextArea } = Input;

const WorkTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('work.type.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [color, setColor] = useState<any>(null);
    const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
    const [showOnTablet, setShowOnTablet] = useState<boolean>(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('work.type.edit.title') + ' - ' + dataForm.id);
        }

        setDefaultChecked(dataForm.defaultType);
        setShowOnTablet(dataForm.show);
        setColor(dataForm.color);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('work.type.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.WORK_TYPE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                values.defaultType = defaultChecked;
                values.show = showOnTablet;
                if (values.color) {
                    values.color = values.color;
                } else {
                    values.color = '#000000';
                }
                return values;
            }}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('work.type.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label={geti18nText('work.type.edit.default')} name="defaultType">
                        <Checkbox
                            checked={defaultChecked}
                            onChange={(val: any) => {
                                setDefaultChecked(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={geti18nText('work.type.edit.showOnTablet')} name="show">
                        <Switch
                            checked={showOnTablet}
                            onChange={(checked: any) => {
                                setShowOnTablet(checked);
                            }}
                            checkedChildren={geti18nText('app.default.button.yes')}
                            unCheckedChildren={geti18nText('app.default.button.no')}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label={
                            <Tooltip
                                title={
                                    <div>
                                        <div style={{ marginBottom: '5px' }}>
                                            {geti18nText('work.type.edit.colorHint')}
                                        </div>
                                        <div style={{ display: 'inline-flex' }}>
                                            <div
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    background: 'white',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        left: '12px',
                                                        top: '12px',
                                                        width: '24px',
                                                        height: '24px',
                                                        backgroundColor: getTransparentColor(color, 0.3),
                                                        border: `1px solid ${getTransparentColor(color, 0.5)}`,
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    background: '#121e2d',
                                                    marginLeft: '5px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        left: '12px',
                                                        top: '12px',
                                                        width: '24px',
                                                        height: '24px',
                                                        backgroundColor: getTransparentColor(color, 0.15),
                                                        border: `1px solid ${getTransparentColor(color, 0.25)}`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                {geti18nText('work.type.edit.color')}
                            </Tooltip>
                        }
                        name="color"
                    >
                        <NyColorPicker popover onChange={(value: any) => setColor(value)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('work.type.edit.description')} name="description">
                        <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} maxLength={200} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default WorkTypeEdit;
