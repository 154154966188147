import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColorFromNotificationType, getEnumArray } from '../../../../utils/Utils';
import ItemGroupEdit from './edit';

const ItemGroupIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    type = 0,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    expandedRows = true,
    search = false,
}: any) => {
    const location = useLocation();
    const table = useTableSettings();

    const { settings } = useSelector((state: RootState) => state.notification);

    const tabColor = () => {
        switch (type) {
            case 0:
                //item
                return undefined;
            case 1:
                //item-asset
                return getColorFromNotificationType(settings, 'ASSET_ORDER');
            case 2:
                //item-material
                return getColorFromNotificationType(settings, 'INVENTORY_ORDER');
            case 3:
                //item-service
                return getColorFromNotificationType(settings, 'SERVICES_ORDER');
            default:
                return undefined;
        }
    };

    useHelper('common/item_group');

    const canCreate = () => {
        switch (type) {
            case 0:
                //item-group
                return AdministrationRights.canCreateAdministrationCodebooks();
            case 1:
                //item-group-asset
                return AssetRights.canCreateCodebooks();
            case 2:
                //item-group-material
                return InventoryRights.canCreateCodebooks();
            case 3:
                //item-group-service
                return ServicesRights.canCreateCodebooks();
            default:
                //item-group
                return AdministrationRights.canCreateAdministrationCodebooks();
        }
    };

    const canExportCSV = () => {
        switch (type) {
            case 0:
                //item-group
                return AdministrationRights.canCsvExportAdministrationCodebook();
            case 1:
                //item-group-asset
                return AssetRights.canCsvExportCodebooks();
            case 2:
                //item-group-material
                return InventoryRights.canCsvExportCodebooks();
            case 3:
                //item-group-service
                return ServicesRights.canCsvExportCodebooks();
            default:
                //item-group
                return AdministrationRights.canCsvExportAdministrationCodebook();
        }
    };

    const setCsvName = () => {
        switch (type) {
            case 0:
                return geti18nText('menu.item.group');
            case 1:
                return geti18nText('itemGroup.csv.asset');
            case 2:
                return geti18nText('itemGroup.csv.material');
            case 3:
                return geti18nText('itemGroup.csv.services');
            default:
                return geti18nText('menu.item.group');
        }
    };

    const setDefaultFilterValue = () => {
        if (type !== 0) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: type },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const initialColumns = [
        ...(search == true
            ? [
                  {
                      title: geti18nText('itemGroup.table.column.id'),
                      dataIndex: 'id',
                      width: '15%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
              ]
            : [
                  {
                      title: geti18nText('itemGroup.table.column.id'),
                      dataIndex: 'id',
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
              ]),
        {
            title: geti18nText('itemGroup.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('itemGroup.table.column.type'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.ITEM_GROUP_TYPE.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('ITEM_GROUP_TYPE')),
        },
        {
            title: geti18nText('itemGroup.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const modifiedColumns = [
        ...(search == true
            ? [
                  {
                      title: geti18nText('itemGroup.table.column.id'),
                      dataIndex: 'id',
                      width: '15%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
              ]
            : [
                  {
                      title: geti18nText('itemGroup.table.column.id'),
                      dataIndex: 'id',
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
              ]),
        {
            title: geti18nText('itemGroup.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('itemGroup.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="item-group-table"
            url={CONSTANTS_REQ.ITEM_GROUP.LIST}
            addNewButtonText={geti18nText('itemGroup.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={ItemGroupEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={type == 0 ? initialColumns : modifiedColumns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            hideButtons={disabled}
            readonly={disabled}
            addedData={{ type: type }}
            exportCSV={canExportCSV()}
            CSVFileName={setCsvName()}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={setCsvName()}
            expandedRows={expandedRows}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ITEM_GROUP_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ItemGroupIndex;
