import { EyeInvisibleOutlined, EyeOutlined, UserAddOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Input, Modal, Row, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Address from '../../../../components/address';
import AddressHistoryIndex from '../../../../components/address/addressHistoryIndex';
import EmployeeExistsModal from '../../../../components/employee-exists-modal';
import IdentityDocumentsIndex from '../../../../components/identity-documents';
import JoppdCodeIndex from '../../../../components/joppd-code';
import NyNoteModal from '../../../../components/note-modal';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    empServiceYmdFormatting,
    errorNotification,
    formatJoppdCodeSearch,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    getWorkplaceSelectOption,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setJoppdCodeDefaultFilterValue,
    setSearchFormat,
    toBinary,
} from '../../../../utils/Utils';
import { JMBGCheck, oibCheck } from '../../../../utils/Validation';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import CountryIndex from '../../../administration/views/country';
import CountryEdit from '../../../administration/views/country/edit';
import SettlementIndex from '../../../administration/views/settlement';
import SettlementEdit from '../../../administration/views/settlement/edit';
import { BusinessUnitSearch } from '../business-unit/search';
import CitizenshipSearch from '../citizenship/search';
import EmployeeGroupSearch from '../employee-group/search';
import EmployeeStatusSearch from '../employee-status/search';
import EmployeeTypeSearch from '../employee-type/search';
import EmploymentTypeSearch from '../employment-type/search';
import NationalitySearch from '../nationality/search';
import RoomSearch from '../room/search';
import VocationSearch from '../vocation/search';
import WorkplaceSearch from '../workplace/search';

const { TextArea } = Input;
const { Panel } = Collapse;

const EmployeeGeneralIndex = ({
    props,
    readonly = false,
    setEmployeeId,
    disableIdentityDocumentUpload = false,
}: any) => {
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [employeeType, setEmployeeType] = useState<any>();
    const [isTypeEmployee, setIsTypeEmployee] = useState<any>(false);
    const [leaveDaysInput, setLeaveDaysInput] = useState<any>(false);
    const [personNote, setPersonNote] = useState<any>(false);
    const [addressNote, setAddressNote] = useState<any>(false);
    const [employeeNote, setEmployeeNote] = useState<any>(false);
    const [contactNote, setContactNote] = useState<any>(false);
    const [privateContactNote, setPrivateContactNote] = useState<any>(false);
    const [isUser, setIsUser] = useState<any>(true);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1', '2', '3', '8']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['4', '5', '7', '9']);
    const [collapseAll, setCollapseAll] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [userFrameVisible, setUserFrameVisible] = useState(false);
    const [personExistsModalVisible, setPersonExistsModalVisible] = useState(false);
    const [employeeExistsModalVisible, setEmployeeExistsModalVisible] = useState(false);
    const [employeeData, setEmployeeData] = useState<any>({});
    const [personData, setPersonData] = useState<any>({});
    const [isEmployee, setIsEmployee] = useState<any>(false);
    const history = useHistory();
    const [herlperUrl, setHelperUrl] = useState<any>(undefined);
    const [isSingleParent, setIsSingleParent] = useState<boolean>(false);
    const fileUploadRef = useRef<any>();
    const [fileList, setFileList] = useState<any>([]);
    const uploadFilesRef = useRef<any>(null);
    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const dispatch = useDispatch();
    const [hasEmail, setHasEmail] = useState(false);
    const [forPayroll, setForPayroll] = useState<boolean>(false);
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    useHelper('human/employee');
    const focusInput = useRef<any>(null);
    const [selectedSettlementId, setSelectedSettlementId] = useState<any>(undefined);
    const [selectedResidenceId, setSelectedResidenceId] = useState<any>(undefined);
    const [refresh, setRefresh] = useState(0);
    const { employee } = useSelector((state: RootState) => state.employee);
    const addressType = useEnum('ADDRESS_TYPE');
    const [fullTime, setFullTime] = useState<boolean>(false);
    const [partnerInOrganization, setPartnerInOrganization] = useState<boolean>(false);
    const [receivesChildBenefit, setReceivesChildBenefit] = useState<boolean>(false);
    const [employmentRecordID, setEmploymentRecordID] = useState<any>(null);
    const [workplace, setWorkplace] = useState<any>(null);
    const [workplaceDetails, setWorkplaceDetails] = useState<any>(null);
    const [businessUnit, setBusinessUnit] = useState<any>(undefined);

    const isBusinessUnitSelected = () => {
        return businessUnit?.id > 0;
    };

    const setDefaultFilterValueFiles = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'ref_id', condition: 'equals', value: dataForm },
            { field: 'employee_id', condition: 'equals', value: dataForm },
            { field: 'document_type', condition: 'equals', value: documentTypeEnum.SINGLE_PARENT_CERTIFICATE },
        ];
    };

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        getAppSettings();
        if (id == 'create') {
            setSelectedSettlementId(-1);
            setSelectedResidenceId(-1);
        }
    }, []);

    const setAppSettingsHRGroupFilter = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 2 },
            { field: 'settingGroup', condition: 'equals', value: 'HUMAN_RESOURCES' },
        ];
    };

    const setDefaultFilterValue = (type: String) => {
        return setJoppdCodeDefaultFilterValue(2, type);
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsHRGroupFilter()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'HR_EMPLOYEE_DATA_REQUIRED_FOR_PAYROLL') {
                                setForPayroll(
                                    parseInt(element.value ? element.value : element.defaultValue) != 1 ? false : true
                                );
                            }
                        });
                    }
                }
            }
        );
    };

    async function setValues(dataForm: any) {
        form.resetFields();

        let object: any = {};
        let externalUserData: any = {};

        checkUser(dataForm.id);

        externalUserData.employeeId = dataForm.id;
        externalUserData.applicationKey = NySession.getSetting('application-key');

        if (dataForm.person) {
            object.firstName = dataForm.person.firstName;
            object.lastName = dataForm.person.lastName;
        }

        if (dataForm.employmentRecordId) {
            externalUserData.refId = dataForm.employmentRecordId;
            setEmploymentRecordID(dataForm.employmentRecordId);
        }

        if (dataForm.employmentDate) dataForm.employmentDate = setDateFormat(dataForm.employmentDate);

        if (dataForm.demissionDate) dataForm.demissionDate = setDateFormat(dataForm.demissionDate);

        if (dataForm.person.birthDate) dataForm.person.birthDate = setDateFormat(dataForm.person.birthDate);

        if (dataForm.employeeStatusValidFrom)
            dataForm.employeeStatusValidFrom = setDateFormat(dataForm.employeeStatusValidFrom);

        if (dataForm.employeeStatusValidTo)
            dataForm.employeeStatusValidTo = setDateFormat(dataForm.employeeStatusValidTo);

        if (dataForm.person.sex) dataForm.person.sex = setEnumFormat('SEX_TYPE', dataForm.person.sex);

        if (dataForm.type) {
            dataForm.type = setSearchFormat(dataForm.type, 'name', 'name');
            setEmployeeType(dataForm.type.id);
            if (dataForm.type.id === 1) {
                setIsTypeEmployee(true);
            }
        }
        if (dataForm.demissionType) dataForm.demissionType = setEnumFormat('DEMISSION_TYPE', dataForm.demissionType);

        if (dataForm.person.birthSettlement)
            dataForm.person.birthSettlement = setSearchFormat(dataForm.person.birthSettlement);
        //if (dataForm.birthTownship) dataForm.birthTownship = setSearchFormat(dataForm.birthTownship);
        if (dataForm.person.birthCountry) dataForm.person.birthCountry = setSearchFormat(dataForm.person.birthCountry);

        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            setBusinessUnit(dataForm.businessUnit);
        }

        if (dataForm?.address?.settlement) {
            dataForm.address.settlement = setSearchFormat(dataForm.address.settlement);
        }
        setSelectedSettlementId(dataForm?.address?.settlement?.id ?? -1);

        if (dataForm?.employeeAddress?.address?.settlement) {
            dataForm.employeeAddress.address.settlement = setSearchFormat(dataForm.employeeAddress.address.settlement);
        }
        setSelectedResidenceId(dataForm?.employeeAddress?.address.settlement?.id ?? -1);
        if (dataForm.address?.postOffice) {
            dataForm.address.postOffice = setSearchFormat(dataForm.address?.postOffice, 'name');
        }
        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '6.1.');
        }
        if (dataForm.joppdWorkHoursCode) {
            dataForm.joppdWorkHoursCode = await formatJoppdCodeSearch(dataForm.joppdWorkHoursCode, 2, '9.');
        }
        if (dataForm.vocation) {
            dataForm.vocaiton = setSearchFormat(dataForm.vocation);
        }

        if (dataForm.person && dataForm.person.fileId) {
            let filesImage = [
                {
                    id: dataForm.person.fileId,
                    uid: dataForm.person.fileId,
                    name: dataForm.person.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.person.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(filesImage);
        }
        if (dataForm.person.nationality) {
            dataForm.person.nationality = setSearchFormat(dataForm.person.nationality);
        }
        if (dataForm.person.citizenship) {
            dataForm.person.citizenship = setSearchFormat(dataForm.person.citizenship);
        }
        if (dataForm.professionalQualification) {
            dataForm.professionalQualification = setSearchFormat(dataForm.professionalQualification);
        }
        if (dataForm.employeeStatus) {
            dataForm.employeeStatus = setSearchFormat(dataForm.employeeStatus, 'name', 'name');
        }
        if (dataForm.employmentType) {
            dataForm.employmentType = setSearchFormat(dataForm.employmentType);
        }
        if (dataForm?.workplaceDetails?.active) {
            if (dataForm.workplaceDetails.workplace) {
                dataForm.workplace = getWorkplaceSelectOption(dataForm.workplaceDetails.workplace);
                setWorkplace(dataForm.workplace);
            }
            setWorkplaceDetails(dataForm.workplaceDetails);
        } else {
            delete dataForm?.workplaceDetails;
        }
        setPersonNote(dataForm.person && dataForm.person.note ? dataForm.person.note : undefined);
        setAddressNote(dataForm.address && dataForm.address.note ? dataForm.address.note : undefined);
        setEmployeeNote(dataForm.note ? dataForm.note : undefined);
        setContactNote(dataForm.contact && dataForm.contact.note ? dataForm.contact.note : undefined);
        setPrivateContactNote(
            dataForm.privateContact && dataForm.privateContact.note ? dataForm.privateContact.note : undefined
        );
        setHasEmail(dataForm.contact && dataForm.contact.email ? true : false);

        if (dataForm.contact && dataForm.contact.email) {
            object.email = dataForm.contact.email;
        }
        setIsSingleParent(dataForm.singleParent == true ? true : false);
        object.externalUserData = externalUserData;
        setFullTime(dataForm.fullTime == true || dataForm.workHoursFull === 8 ? true : false);
        setPartnerInOrganization(dataForm?.partnerInOrganization?.id ? true : false);
        setReceivesChildBenefit(dataForm.receivesChildBenefit == true ? true : false);
        //  setWorkHoursFull(dataForm.workHoursFull == true ? true : false);

        setHelperUrl(object);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const clearSettlementDistrictField = () => {
        form.setFieldsValue({ address: { settlementDistrict: '' } });
    };

    const checkUser = (id: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_IS_USER + '/' + id, undefined).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (
                    result.data != undefined &&
                    result.data != null &&
                    result.data.id != undefined &&
                    result.data.id != null
                ) {
                    setIsUser(true);
                    if (result.data.username != null && result.data.username != undefined) {
                        let formUser: any = {};
                        formUser.user = {};
                        formUser.user.username = result.data.username;
                        form.setFieldsValue(formUser);
                    }
                } else {
                    setIsUser(false);
                }
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setIsUser(true);
        setFileList([]);
        setIsSingleParent(false);
        setForPayroll(false);
        setLeaveDaysInput(false);
        setFullTime(false);
        setPartnerInOrganization(false);
        setReceivesChildBenefit(false);
        setEmploymentRecordID(null);
        setWorkplace(null);
        setWorkplaceDetails(null);
    };

    const validateOib = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== null && value !== '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.oib.not.correct'));
            }
        } else {
            callback();
        }
    };

    const validateJMBG = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== null && value !== '') {
            if (JMBGCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.jmbg.not.correct'));
            }
        } else {
            callback();
        }
    };

    const PersonExistsOk = () => {
        setPersonExistsModalVisible(false);
        form.setFieldsValue({
            person: {
                birthCountry: setSearchFormat(personData?.birthCountry),
                birthDate: setDateFormat(personData?.birthDate),
                birthSettlement: setSearchFormat(personData?.birthSettlement),
                birthTownship: setSearchFormat(personData?.birthTownship),
                citizenship: setSearchFormat(personData?.citizenship),
                fileId: personData?.fileId,
                firstName: personData?.firstName,
                id: personData?.id,
                lastName: personData?.lastName,
                jmbg: personData?.jmbg,
                maidenName: personData?.maidenName,
                motherMaidenName: personData?.motherMaidenName,
                motherName: personData?.motherName,
                nationality: setSearchFormat(personData?.nationality),
                oib: personData?.oib,
                parentName: personData?.parentName,
                sex: setEnumFormat('SEX_TYPE', personData?.sex),
            },
        });
    };

    const PersonExistsCancel = () => {
        setPersonExistsModalVisible(false);
        form.setFieldsValue({ person: { oib: null } });
    };

    const EmployeeExistsOk = () => {
        setEmployeeExistsModalVisible(false);
        dispatch(history.push('/human/employee/' + employeeData?.id));
        if (setEmployeeId) {
            setEmployeeId({ id: employeeData?.id });
        }
    };

    const EmployeeExistsCancel = () => {
        setEmployeeExistsModalVisible(false);
        form.setFieldsValue({ person: { oib: null } });
    };

    const oibOnchange = (event: any) => {
        var oib = event.target.value;
        if (oib !== undefined && oib !== '' && oibCheck(oib)) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_EMPLOYEE_BY_ID + '/' + oib).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result?.data?.person?.id) {
                        if (result.data?.id) {
                            setEmployeeData(result?.data);
                            setEmployeeExistsModalVisible(true);
                            setIsEmployee(true);
                        } else {
                            setPersonData(result?.data?.person);
                            setEmployeeData(result?.data);
                            setPersonExistsModalVisible(true);
                            setIsEmployee(false);
                        }
                    }
                }
            });
        }
    };

    const generateEmploymentRecordId = (type: any) => {
        setEmployeeType(type);
        if (type === 1) {
            if (employeeType !== type) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GENERATE_EMPLOYMENT_RECORD_ID).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            let employmentRecord = result.data;
                            form.setFieldsValue({ employmentRecordId: employmentRecord });
                        }
                    }
                );
            }
        } else {
            form.setFieldsValue({ employmentRecordId: employmentRecordID });
        }
    };

    const validateEmploymentRecordId = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '' && employeeType === 1) {
            value = Number(String(value).split('.').join(''));
            var parms: any = { employmentRecordId: value };
            if (dataForm !== null && dataForm !== undefined) {
                parms.employeeId = dataForm;
            }
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.VALIDATE_EMPLOYMENT_RECORD_ID, parms).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            callback();
                        } else {
                            callback(geti18nText('employee.edit.employmentRecordId.exists'));
                        }
                    } else {
                        callback();
                    }
                }
            );
        } else {
            callback();
        }
    };

    const validateYmd = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            let ymd = value.split('-');

            let y = Number(ymd[0]);
            if (!isNaN(y)) {
                if (y > 50) {
                    callback(geti18nText('employee.edit.validateYmd.y'));
                }
            }

            let m = Number(ymd[1]);
            if (!isNaN(m)) {
                if (m > 11) {
                    callback(geti18nText('employee.edit.validateYmd.m'));
                }
            }

            let d = Number(ymd[2]);
            if (!isNaN(d)) {
                if (d > 29) {
                    callback(geti18nText('employee.edit.validateYmd.d'));
                }
            }
            callback();
        } else {
            callback();
        }
    };

    const createUserFromEmployee = () => {
        setUserFrameVisible(true);
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '2', '3', '8']);
            setRightCollapseActiveKeys(['4', '5', '6', '7', '9']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined
                        onClick={changeCollapseVisibility}
                        className="ny-custom-button"
                        style={{ paddingRight: '15px' }}
                    />
                ) : (
                    <EyeInvisibleOutlined
                        onClick={changeCollapseVisibility}
                        className="ny-custom-button"
                        style={{ paddingRight: '15px' }}
                    />
                )}
            </Tooltip>
            {!readonly && !isUser && id !== 'create' && hasEmail && (
                <Button onClick={createUserFromEmployee} icon={<UserAddOutlined />}>
                    {geti18nText('employee.edit.createUser')}
                </Button>
            )}
        </div>
    );

    const onModalFrameClose = () => {
        checkUser(id);
        setUserFrameVisible(false);
    };

    useEffect(() => {
        setFileList([]);
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    const saveWorkplaceDetails = (employeeId: any) => {
        if (employeeId > 0) {
            if (workplaceDetails?.id) {
                let data: any =
                    workplace?.id > 0
                        ? {
                              employee: { id: employeeId },
                              id: workplaceDetails.id,
                              workplace: { id: workplace.id },
                              active: businessUnit?.id > 0,
                          }
                        : {
                              employee: { id: employeeId },
                              id: workplaceDetails.id,
                              workplace: { id: workplaceDetails?.workplace?.id },
                              active: false,
                          };
                if (
                    ((!workplace?.id || workplace?.id < 1) && workplaceDetails?.workplace?.id) ||
                    workplace?.id != workplaceDetails?.workplace?.id
                ) {
                    NyRequestResolver.requestPut(
                        CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT + '/' + workplaceDetails.id,
                        undefined,
                        data
                    ).then((result: any) => {
                        if (!result || result?.status !== RESPONSE.CREATED) {
                            errorNotification(geti18nText('app.default.save.nok'));
                        }
                    });
                }
            } else if (workplace?.id > 0) {
                NyRequestResolver.requestPost(CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT, undefined, {
                    employee: { id: employeeId },
                    workplace: { id: workplace.id },
                }).then((result: any) => {
                    if (!result || result?.status !== RESPONSE.CREATED) {
                        errorNotification(geti18nText('app.default.save.nok'));
                    }
                });
            }
        }
    };
    const onChangeBusinessUnit = (value: any) => {
        setBusinessUnit(value);
        form.setFieldsValue({ workplace: undefined });
        setWorkplace(null);
    };

    return (
        <React.Fragment>
            <div style={{ height: '71vh' }}>
                <NyDataEdit
                    layout="vertical"
                    formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                    loading={loading}
                    setLoading={setLoading}
                    onModalClose={onModalClose}
                    url={CONSTANTS_REQ.EMPLOYEE.EDIT}
                    setValues={setValues}
                    fetchWhenChange={id || refresh}
                    checkIsFormChanged={true}
                    width={1200}
                    form={form}
                    buttonsClassName="buttons-sticky"
                    hideSubmitButton={readonly}
                    hideActivationButtons={readonly}
                    goBack={() => {
                        history.goBack();
                    }}
                    onSaveAndGetID={(id: any) => {
                        if (fileUploadRef?.current) {
                            fileUploadRef.current.filesUpload(id);
                        }
                        if (uploadFilesRef?.current) {
                            uploadFilesRef.current.filesUpload(id);
                        }
                        saveWorkplaceDetails(id);
                        history.goBack();
                    }}
                    paramsId={id}
                    normalize={(values: any) => {
                        if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                            delete values.id;
                        }
                        if (values.person.sex) values.person.sex = getEnumFormat(values.person.sex);
                        if (values.type) values.type = getSearchFormat(values.type);
                        if (values.demissionType) values.demissionType = getEnumFormat(values.demissionType);

                        if (values.employmentDate) values.employmentDate = getDateFormat(values.employmentDate);
                        if (values.demissionDate) values.demissionDate = getDateFormat(values.demissionDate);
                        if (values.person.birthDate) values.person.birthDate = getDateFormat(values.person.birthDate);

                        if (values.employeeStatusValidFrom)
                            values.employeeStatusValidFrom = getDateFormat(values.employeeStatusValidFrom);
                        if (values.employeeStatusValidTo)
                            values.employeeStatusValidTo = getDateFormat(values.employeeStatusValidTo);

                        if (fileList.length > 0 && fileList[0]) {
                            values.person.fileId = fileList[0].id;
                        }
                        values.singleParent = isSingleParent ? true : false;
                        values.fullTime = fullTime || values.workHoursFull === 8 ? true : false;
                        values.receivesChildBenefit = partnerInOrganization ? receivesChildBenefit : null;

                        if (values.employeeStatus) {
                            if (values.employeeStatus.id === -1) {
                                delete values.employeeStatus;
                            } else {
                                values.employeeStatus = getSearchFormat(values.employeeStatus);
                            }
                        }
                        if (values.employmentType) {
                            if (values.employmentType.id === -1) {
                                delete values.employmentType;
                            } else {
                                values.employmentType = getSearchFormat(values.employmentType);
                            }
                        }
                        if (values.employeeGroup) {
                            if (values.employeeGroup.id === -1) {
                                delete values.employeeGroup;
                            } else {
                                values.employeeGroup = getSearchFormat(values.employeeGroup);
                            }
                        }
                        if (values.nationality) {
                            if (values.nationality.id === -1) {
                                delete values.nationality;
                            } else {
                                values.nationality = getSearchFormat(values.nationality);
                            }
                        }
                        if (values.citizenship) {
                            if (values.citizenship.id === -1) {
                                delete values.citizenship;
                            } else {
                                values.citizenship = getSearchFormat(values.citizenship);
                            }
                        }
                        if (values.birthSettlement) {
                            if (values.birthSettlement.id === -1) {
                                delete values.birthSettlement;
                            } else {
                                values.birthSettlement = getSearchFormat(values.birthSettlement);
                            }
                        }
                        if (values.birthCountry) {
                            if (values.birthCountry.id === -1) {
                                delete values.birthCountry;
                            } else {
                                values.birthCountry = getSearchFormat(values.birthCountry);
                            }
                        }
                        if (values.birthTownship) {
                            if (values.birthTownship.id === -1) {
                                delete values.birthTownship;
                            } else {
                                values.birthTownship = getSearchFormat(values.birthTownship);
                            }
                        }
                        if (values.vocation) {
                            if (values.vocation.id === -1) {
                                delete values.vocation;
                            } else {
                                values.vocation = getSearchFormat(values.vocation);
                            }
                        }
                        //address
                        if (values?.address?.settlement?.id > 0) {
                            values.address.settlement = getSearchFormat(values.address.settlement);
                        } else {
                            delete values.address.settlement;
                        }
                        if (values?.address?.postOffice?.id > 0) {
                            values.address.postOffice = getSearchFormat(values.address.postOffice);
                        } else {
                            delete values.address.postOffice;
                        }
                        if (!values.address.street) {
                            delete values.address.street;
                        }
                        if (!values.address.houseNumber) {
                            delete values.address.houseNumber;
                        }
                        //person
                        if (values?.person) {
                            if (values.person?.birthCountry?.id === -1) {
                                delete values.person.birthCountry;
                            }
                            if (values.person?.birthSettlement?.id === -1) {
                                delete values.person.birthSettlement;
                            }
                            if (values.person?.citizenship?.id === -1) {
                                delete values.person.citizenship;
                            }
                            if (values.person?.nationality?.id === -1) {
                                delete values.person.nationality;
                            }
                        }
                        //employee address
                        if (values?.employeeAddress?.address?.settlement?.id > 0) {
                            values.employeeAddress.address.settlement = getSearchFormat(
                                values.employeeAddress.address.settlement
                            );
                        } else {
                            delete values.employeeAddress.address.settlement;
                        }
                        if (!values.employeeAddress.address.street) {
                            delete values.employeeAddress.address.street;
                        }
                        if (!values.employeeAddress.address.houseNumber) {
                            delete values.employeeAddress.address.houseNumber;
                        }
                        if (values.joppdCode) {
                            if (values.joppdCode.id === -1) {
                                delete values.joppdCode;
                            } else {
                                values.joppdCode = values.joppdCode.code;
                            }
                        }
                        if (values.joppdWorkHoursCode) {
                            if (values.joppdWorkHoursCode.id === -1) {
                                delete values.joppdWorkHoursCode;
                            } else {
                                values.joppdWorkHoursCode = values.joppdWorkHoursCode.code;
                            }
                        }
                        if (values.businessUnit) {
                            if (values.businessUnit.id === -1) {
                                delete values.businessUnit;
                            } else {
                                values.businessUnit = getSearchFormat(values.businessUnit);
                            }
                        }
                        if (values.room) {
                            if (values.room.id === -1) {
                                delete values.room;
                            } else {
                                values.room = getSearchFormat(values.room);
                            }
                        }
                        if (workplace && workplace?.text?.props && workplace?.text?.props['custom-data'])
                            values.vocationDescription = {
                                id: workplace?.text?.props['custom-data']?.vocationDescriptionID,
                            };
                        delete values.workplace;
                        return values;
                    }}
                    changeCollapseVisibility={changeCollapseVisibility}
                    {...props}
                    customFooterContent={getCustomFooterContent}
                    shortcuts={true}
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Collapse
                                activeKey={leftCollapseActiveKeys}
                                onChange={(key: any) => {
                                    setLeftCollapseActiveKeys(key);
                                }}
                            >
                                <Panel
                                    header={geti18nText('employee.edit.personalData')}
                                    key="1"
                                    extra={
                                        !readonly && (
                                            <NyNoteModal
                                                note={personNote}
                                                modalTitle={
                                                    geti18nText('employee.edit.personalData') +
                                                    ' - ' +
                                                    geti18nText('app.default.note')
                                                }
                                                idFieldNamePath={['person', 'note', 'id']}
                                                noteFieldNamePath={['person', 'note', 'note']}
                                                noteFieldPriorityPath={['person', 'note', 'priority']}
                                            />
                                        )
                                    }
                                    forceRender={false}
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <NyImageUpload
                                                documentType={documentTypeEnum.PERSON_IMAGE}
                                                files={fileList}
                                                setFiles={setFileList}
                                                cropShape={'round'}
                                                ref={fileUploadRef}
                                                canDeleteFile={!HumanResourcesRights.canViewEDRModal()}
                                                canUpload={!HumanResourcesRights.canViewEDRModal()}
                                                showRemoveIcon={!HumanResourcesRights.canViewEDRModal()}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item name="id" style={{ display: 'none' }}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name={['person', 'id']} style={{ display: 'none' }}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={geti18nText('person.edit.firstName')}
                                                        name={['person', 'firstName']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: geti18nText('app.default.required'),
                                                                whitespace: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input disabled={readonly} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={geti18nText('person.edit.lastName')}
                                                        name={['person', 'lastName']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: geti18nText('app.default.required'),
                                                                whitespace: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input disabled={readonly} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={geti18nText('person.edit.maidenName')}
                                                        name={['person', 'maidenName']}
                                                    >
                                                        <Input disabled={readonly} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('person.edit.oib')}
                                                name={['person', 'oib']}
                                                rules={[
                                                    {
                                                        required: forPayroll,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: forPayroll,
                                                    },
                                                    {
                                                        validator: validateOib,
                                                    },
                                                ]}
                                            >
                                                <Input onChange={oibOnchange} ref={focusInput} disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('person.edit.jmbg')}
                                                name={['person', 'jmbg']}
                                                rules={[
                                                    {
                                                        validator: validateJMBG,
                                                    },
                                                ]}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={partnerInOrganization ? 3 : 6}>
                                            <Form.Item
                                                label={geti18nText('person.edit.sex')}
                                                name={['person', 'sex']}
                                                rules={[
                                                    {
                                                        required: forPayroll,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    options={getEnumArray('SEX_TYPE')}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    nyTestId="sex"
                                                    className=""
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={partnerInOrganization ? 4 : 6}>
                                            <Form.Item
                                                label={geti18nText('person.edit.singleParent')}
                                                name={'singleParent'}
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    disabled={readonly}
                                                    checked={isSingleParent}
                                                    onChange={(e) => {
                                                        setIsSingleParent(e.target.checked);
                                                    }}
                                                ></Checkbox>
                                            </Form.Item>
                                        </Col>
                                        {partnerInOrganization && (
                                            <Col span={5}>
                                                <Form.Item
                                                    label={geti18nText('person.edit.receivesChildBenefit')}
                                                    name={'receivesChildBenefit'}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox
                                                        disabled={readonly}
                                                        checked={isSingleParent}
                                                        onChange={(e) => {
                                                            setIsSingleParent(e.target.checked);
                                                        }}
                                                    ></Checkbox>
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('person.edit.parentName')}
                                                name={['person', 'parentName']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('person.edit.motherName')}
                                                name={['person', 'motherName']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('person.edit.motherMaidenName')}
                                                name={['person', 'motherMaidenName']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <IdentityDocumentsIndex
                                        employeeId={id}
                                        disableIdentityDocumentUpload={disableIdentityDocumentUpload}
                                    />
                                    {isSingleParent && (
                                        <NyFilesUpload
                                            documentType={documentTypeEnum.SINGLE_PARENT_CERTIFICATE}
                                            url={CONSTANTS_REQ.EMPLOYEE_FILE.LIST}
                                            editUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                                            defaultFilesFilter={setDefaultFilterValueFiles()}
                                            ref={uploadFilesRef}
                                            canAddNewFile={!readonly}
                                            canEdit={!readonly}
                                            canDelete={!readonly}
                                            refId={dataForm}
                                            id={dataForm}
                                            employee={employee}
                                            maxHeight={'205px'}
                                        />
                                    )}
                                </Panel>
                                <Panel header={geti18nText('employee.edit.birthData')} key="2" forceRender={true}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('person.edit.birthDate')}
                                                name={['person', 'birthDate']}
                                                rules={[
                                                    {
                                                        required: forPayroll,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NyDatePicker disabled={readonly} nyTestId="birth-date" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.birth.settlement')}
                                                name={['person', 'birthSettlement']}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.SETTLEMENT.SEARCH}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="name"
                                                    SearchPopupComponent={<SettlementIndex />}
                                                    AddNewModalComponent={SettlementEdit}
                                                    nyTestId="birth-settlement"
                                                    disabled={readonly}
                                                    customListWidth={'1200px'}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.birth.country')}
                                                name={['person', 'birthCountry']}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.COUNTRY.SEARCH}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="name"
                                                    disabled={readonly}
                                                    SearchPopupComponent={<CountryIndex />}
                                                    AddNewModalComponent={CountryEdit}
                                                    nyTestId="birth-country"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <NationalitySearch
                                                label={geti18nText('employee.edit.birth.nationality')}
                                                name={['person', 'nationality']}
                                                disabled={readonly}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <CitizenshipSearch
                                                label={geti18nText('employee.edit.birth.citizenship')}
                                                name={['person', 'citizenship']}
                                                disabled={readonly}
                                            />
                                        </Col>
                                    </Row>
                                </Panel>
                                <Panel
                                    header={
                                        <>
                                            {geti18nText('employee.edit.addressData')}
                                            <div
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                style={{ float: 'right' }}
                                            >
                                                <AddressHistoryIndex
                                                    employeeId={id}
                                                    type={addressType.PERMANENT_RESIDENCE}
                                                    buttonStyle={{
                                                        float: 'right',
                                                        marginRight: '7px',
                                                        marginTop: '2px',
                                                    }}
                                                    extraHeader={geti18nText('employee.edit.addressData')}
                                                />
                                            </div>
                                        </>
                                    }
                                    key="3"
                                    extra={
                                        !readonly && (
                                            <>
                                                <NyNoteModal
                                                    note={addressNote}
                                                    modalTitle={
                                                        geti18nText('employee.edit.addressData') +
                                                        ' - ' +
                                                        geti18nText('app.default.note')
                                                    }
                                                    idFieldNamePath={['address', 'note', 'id']}
                                                    noteFieldNamePath={['address', 'note', 'note']}
                                                    noteFieldPriorityPath={['address', 'note', 'priority']}
                                                />
                                            </>
                                        )
                                    }
                                    forceRender={false}
                                >
                                    {selectedSettlementId != undefined && (
                                        <Address
                                            form={form}
                                            settlementId={selectedSettlementId}
                                            disabled={readonly}
                                            employeeForm
                                        />
                                    )}
                                </Panel>
                                <Panel
                                    header={
                                        <>
                                            {geti18nText('employee.edit.residence')}
                                            <div
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                style={{ float: 'right' }}
                                            >
                                                <AddressHistoryIndex
                                                    employeeId={id}
                                                    type={addressType.TEMPORARY_RESIDENCE}
                                                    buttonStyle={{
                                                        float: 'right',
                                                        marginRight: '7px',
                                                        marginTop: '2px',
                                                    }}
                                                    extraHeader={geti18nText('employee.edit.residence')}
                                                />
                                            </div>
                                        </>
                                    }
                                    key="8"
                                    extra={
                                        !readonly && (
                                            <NyNoteModal
                                                note={addressNote}
                                                modalTitle={
                                                    geti18nText('employee.edit.residence') +
                                                    ' - ' +
                                                    geti18nText('app.default.note')
                                                }
                                                idFieldNamePath={['residence', 'note', 'id']}
                                                noteFieldNamePath={['residence', 'note', 'note']}
                                                noteFieldPriorityPath={['residence', 'note', 'priority']}
                                            />
                                        )
                                    }
                                    forceRender={false}
                                >
                                    {selectedResidenceId != undefined && (
                                        <Address
                                            form={form}
                                            settlementResidenceId={selectedResidenceId}
                                            disabled={readonly}
                                            employeeForm
                                        />
                                    )}
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Collapse
                                activeKey={rightCollapseActiveKeys}
                                onChange={(key: any) => {
                                    setRightCollapseActiveKeys(key);
                                }}
                            >
                                <Panel
                                    header={geti18nText('employee.edit.employeeData')}
                                    key="5"
                                    extra={
                                        !readonly && (
                                            <NyNoteModal
                                                note={employeeNote}
                                                modalTitle={
                                                    geti18nText('employee.edit.employeeData') +
                                                    ' - ' +
                                                    geti18nText('app.default.note')
                                                }
                                            />
                                        )
                                    }
                                    forceRender={false}
                                >
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.eployment.record')}
                                                name={'employmentRecordId'}
                                                rules={[
                                                    {
                                                        required: isTypeEmployee && forPayroll,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NyInputNumber
                                                    leftToRight={numbersWriting}
                                                    min={0}
                                                    nyTestId="employment-record-id"
                                                    style={{ width: '100%' }}
                                                    formatter={false}
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.healthSecurityNumber')}
                                                name={'healthSecurityNumber'}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <EmployeeStatusSearch
                                                label={geti18nText('employee.edit.employeeStatus')}
                                                name={'employeeStatus'}
                                                disabled={readonly}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <EmployeeTypeSearch
                                                required={forPayroll}
                                                onChange={(type: any) => {
                                                    generateEmploymentRecordId(type?.id);
                                                }}
                                                disabled={readonly}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <EmploymentTypeSearch
                                                label={geti18nText('employee.edit.employment.type')}
                                                name={'employmentType'}
                                                required={forPayroll}
                                                disabled={readonly}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <RoomSearch
                                                label={geti18nText('employee.edit.employment.room')}
                                                map={{ id: 'id', label: 'name' }}
                                                name={['room']}
                                                searchBy="name"
                                                disabled={readonly}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <BusinessUnitSearch
                                                formItemName={'businessUnit'}
                                                disabled={readonly}
                                                onChange={onChangeBusinessUnit}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <VocationSearch
                                                label={geti18nText('employee.edit.professional.vocation')}
                                                map={{ id: 'id', label: 'name' }}
                                                name={'vocation'}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <WorkplaceSearch
                                                label={geti18nText('employee.edit.workplace')}
                                                onChange={(value: any) => {
                                                    setWorkplace(value);
                                                    if (
                                                        value?.text?.props &&
                                                        value?.text?.props['custom-data'] &&
                                                        value?.text?.props['custom-data']?.vocationID &&
                                                        value?.text?.props['custom-data']?.vocation
                                                    ) {
                                                        form.setFieldsValue({
                                                            vocation: {
                                                                id: value.text.props['custom-data'].vocationID,
                                                                name: value.text.props['custom-data'].vocation,
                                                            },
                                                        });
                                                    }
                                                }}
                                                disabled={readonly || !isBusinessUnitSelected()}
                                                setDefaultFilterValue={() => [
                                                    {
                                                        field: 'businessUnit.id',
                                                        condition: 'equals',
                                                        value: businessUnit?.id,
                                                    },
                                                    {
                                                        field: 'status',
                                                        condition: 'in',
                                                        value: [enWorkplaceStatus.CONFIRMED].toString(),
                                                    },
                                                    { field: 'active', condition: 'equals_bool', value: 1 },
                                                ]}
                                                AddNewModalComponent
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.service')}
                                                name={'empServiceYmd'}
                                                rules={[
                                                    {
                                                        validator: validateYmd,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="yy-mm-dd"
                                                    onChange={(val: any) => {
                                                        let input = val.target.value;
                                                        setLeaveDaysInput(input.length > 0);
                                                    }}
                                                    onKeyPress={(val: any) => {
                                                        empServiceYmdFormatting(val, form, 'empServiceYmd');
                                                    }}
                                                    maxLength={8}
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.work.hours.full')}
                                                name={'workHoursFull'}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.work.hours.weekly')}
                                                name={'workHoursWeekly'}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <EmployeeGroupSearch
                                                className=""
                                                label={geti18nText('employee.edit.employee.group')}
                                                name={'employeeGroup'}
                                                SearchPopupComponent={false}
                                                disabled={readonly}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label={geti18nText('employee.edit.joppdCode')} name="joppdCode">
                                                <NySearchField
                                                    url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                                    setDefaultFilterValue={() => setDefaultFilterValue('6.1.')}
                                                    map={{ id: 'id', label: 'code' }}
                                                    searchBy="code"
                                                    nyTestId="joppdCode"
                                                    SearchPopupComponent={
                                                        <JoppdCodeIndex
                                                            defaultFilteredValue={() => setDefaultFilterValue('6.1.')}
                                                        />
                                                    }
                                                    itemName="code"
                                                    addNewItem={true}
                                                    order="code"
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.joppdWorkHoursCode')}
                                                name="joppdWorkHoursCode"
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                                    setDefaultFilterValue={() => setDefaultFilterValue('9.')}
                                                    map={{ id: 'id', label: 'code' }}
                                                    searchBy="code"
                                                    nyTestId="joppdWorkHoursCode"
                                                    SearchPopupComponent={
                                                        <JoppdCodeIndex
                                                            defaultFilteredValue={() => setDefaultFilterValue('9.')}
                                                        />
                                                    }
                                                    itemName="code"
                                                    addNewItem={true}
                                                    order="code"
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.date')}
                                                name={'employmentDate'}
                                                rules={[
                                                    {
                                                        required: (isTypeEmployee && forPayroll) || leaveDaysInput,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NyDatePicker
                                                    nyTestId="employment-date"
                                                    style={{ width: '100%' }}
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.validFrom')}
                                                name={'employeeStatusValidFrom'}
                                            >
                                                <NyDatePicker disabled={readonly} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.validTo')}
                                                name={'employeeStatusValidTo'}
                                            >
                                                <NyDatePicker disabled={readonly} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.demission')}
                                                name={'demissionDate'}
                                            >
                                                <NyDatePicker disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.description')}
                                                name="employeeStatusDescription"
                                            >
                                                <TextArea
                                                    rows={3}
                                                    onKeyDown={onKeyDownTextAreaCustom}
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                                <Panel
                                    header={geti18nText(
                                        isUser ? 'employee.edit.contactUserData' : 'employee.edit.contactData'
                                    )}
                                    key="7"
                                    extra={
                                        !readonly && (
                                            <NyNoteModal
                                                note={contactNote}
                                                modalTitle={
                                                    geti18nText('employee.edit.contactData') +
                                                    ' - ' +
                                                    geti18nText('app.default.note')
                                                }
                                                idFieldNamePath={['contact', 'note', 'id']}
                                                noteFieldNamePath={['contact', 'note', 'note']}
                                                noteFieldPriorityPath={['contact', 'note', 'priority']}
                                            />
                                        )
                                    }
                                    forceRender={false}
                                >
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Form.Item
                                                label={geti18nText('employee.contact.edit.email')}
                                                name={['contact', 'email']}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: geti18nText('app.default.valid.email'),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label={geti18nText('employee.contact.edit.mobile')}
                                                name={['contact', 'mobile']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label={geti18nText('employee.contact.edit.phone')}
                                                name={['contact', 'phone']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label={geti18nText('employee.contact.edit.contact')}
                                                name={['contact', 'shortNumber']}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {isUser && (
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('employee.user.edit.username')}
                                                    name={['user', 'username']}
                                                >
                                                    <Input disabled={readonly} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('employee.user.edit.password')}
                                                    name={['user', 'password']}
                                                >
                                                    <Input.Password disabled={readonly} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </Panel>
                                <Panel
                                    header={geti18nText('employee.edit.privateContact')}
                                    key="9"
                                    extra={
                                        !readonly && (
                                            <NyNoteModal
                                                note={privateContactNote}
                                                modalTitle={
                                                    geti18nText('employee.edit.privateContact') +
                                                    ' - ' +
                                                    geti18nText('app.default.note')
                                                }
                                                idFieldNamePath={['privateContact', 'note', 'id']}
                                                noteFieldNamePath={['privateContact', 'note', 'note']}
                                                noteFieldPriorityPath={['privateContact', 'note', 'priority']}
                                            />
                                        )
                                    }
                                    forceRender={false}
                                >
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.privateContact.edit.email')}
                                                name={['privateContact', 'email']}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: geti18nText('app.default.valid.email'),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.privateContact.edit.mobile')}
                                                name={['privateContact', 'mobile']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employee.privateContact.edit.phone')}
                                                name={['privateContact', 'phone']}
                                            >
                                                <Input disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                                <Panel header={geti18nText('employee.edit.demissionData')} key="6" forceRender={false}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.demission')}
                                                name={'demissionDate'}
                                            >
                                                <NyDatePicker disabled={readonly} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.demission.type')}
                                                name={['demissionType']}
                                            >
                                                <NySearchField
                                                    options={getEnumArray('DEMISSION_TYPE')}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    nyTestId="demissionId"
                                                    disabled={readonly}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employee.edit.employment.demission.service')}
                                                name={'demServiceYmd'}
                                                rules={[
                                                    {
                                                        validator: validateYmd,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="yy-mm-dd"
                                                    disabled={readonly}
                                                    onKeyPress={(val: any) => {
                                                        empServiceYmdFormatting(val, form, 'demServiceYmd');
                                                    }}
                                                    maxLength={8}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </NyDataEdit>
                {userFrameVisible && (
                    <Modal
                        visible={userFrameVisible}
                        width={900}
                        centered={true}
                        onOk={() => setUserFrameVisible(false)}
                        onCancel={onModalFrameClose}
                        footer={null}
                        maskClosable={false}
                    >
                        {herlperUrl !== undefined && (
                            <iframe
                                id="new-user-frame"
                                style={{ width: '100%', height: '60vh', border: 'none', marginTop: '20px' }}
                                src={
                                    NySession.getSetting('nyauth') +
                                    `#/frame/new-user?data=${btoa(toBinary(JSON.stringify(herlperUrl)))}`
                                }
                            ></iframe>
                        )}
                    </Modal>
                )}
                {(personExistsModalVisible || employeeExistsModalVisible) && (
                    <EmployeeExistsModal
                        visible={
                            personExistsModalVisible
                                ? personExistsModalVisible
                                : employeeExistsModalVisible
                                ? employeeExistsModalVisible
                                : false
                        }
                        onOk={
                            employeeExistsModalVisible
                                ? EmployeeExistsOk
                                : personExistsModalVisible
                                ? PersonExistsOk
                                : null
                        }
                        onCancel={
                            employeeExistsModalVisible
                                ? EmployeeExistsCancel
                                : personExistsModalVisible
                                ? PersonExistsCancel
                                : null
                        }
                        title={
                            employeeExistsModalVisible
                                ? geti18nText('employee.edit.employeeExists.title')
                                : personExistsModalVisible
                                ? geti18nText('employee.edit.personExists.title')
                                : null
                        }
                        content={
                            employeeExistsModalVisible
                                ? geti18nText('employee.edit.employeeExists.content')
                                : personExistsModalVisible
                                ? geti18nText('employee.edit.personExists.content')
                                : null
                        }
                        employeeInfo={employeeData}
                        employee={isEmployee}
                    />
                )}
            </div>
            {/* <Prompt message={''} when={isFormChange} /> */}
        </React.Fragment>
    );
};

export default EmployeeGeneralIndex;
