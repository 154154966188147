import { NySession } from '@nybble/nyreact';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, useParams } from 'react-router-dom';
import TravelWarrantEdit from '../../../views/travel-warrant/views/travel-warrant/edit';
import App from '../../../App';

const ReRouteTravelWarrant = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (NySession.isUserAuthenticated()) {
            history.push('/travel-warrant/travelWarrant/' + id);
            localStorage.removeItem('loginRedirectCustomUrl');
        }
    }, []);

    if (!NySession.isUserAuthenticated()) {
        localStorage.setItem('loginRedirectCustomUrl', '/travel-warrant/travelWarrant/' + id.toString());
        return <Redirect to="/login" />;
    } else {
        return <></>;
    }
};

export default ReRouteTravelWarrant;
