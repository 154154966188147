import {
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getErrorNotificationMessage,
    getSearchFormat,
    getTravelWageSelectOption,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../utils/Utils';
import TravelWageSearch from '../../views/travel-warrant/views/travel-wage/search';

const TravelWarrantWageRecapitulationEdit = ({
    value = null,
    setValue,
    visible,
    setVisible,
    canEditWage = false,
    setRefreshTable,
    travelWarrantId,
    travelwarrantStartDate,
    travelwarrantEndDate,
}: any) => {
    const [form] = Form.useForm();
    const [wageDate, setWageDate] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [countryId, setCountryId] = useState<any>(null);
    const [currencyId, setCurrencyId] = useState<any>(null);
    const [disabledAmount, setDisabledAmount] = useState<any>(false);
    const [initWage, setInitWage] = useState<any>(null);
    const [date, setDate] = useState<any>(undefined);

    useEffect(() => {
        if (value?.id && visible) {
            fetch(value.id);
        }
    }, [value, visible]);

    const fetch = (id: any) => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_WAGE_RECAPITULATION.EDIT + '/' + id)
            .then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setValues(result.data);
                }
            })
            .finally(() => setLoading(false));
    };

    function setValues(dataForm: any) {
        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
            setWageDate(getDateFormat(dataForm.date, 'DD.MM.YYYY'));
        }
        if (dataForm.travelWage) {
            dataForm.travelWage = setSearchFormat(dataForm.travelWage, 'name', 'name');
            setInitWage(dataForm.travelWage);
        }
        if (dataForm.country) {
            setCountryId(dataForm.country.id);
        }
        if (dataForm.currency) {
            setCurrencyId(dataForm.country.id);
        }
        if (dataForm.date) {
            setDate(dataForm.date);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        travelWageOnChange(dataForm?.travelWage, dataForm.amount);
    }

    const onModalClose = () => {
        form.resetFields();
        setWageDate('');
        setCountryId(null);
        setInitWage(null);
        setDisabledAmount(false);
        setDate(undefined);
        if (setValue) {
            setValue(null);
        }
        if (setVisible) {
            setVisible(false);
        }
    };

    const afterSave = (result: any) => {
        if (result && (result.status === RESPONSE.CREATED || result.status === RESPONSE.OK)) {
            okNotification();
            onModalClose();
            if (setRefreshTable) {
                setRefreshTable((refreshTable: any) => refreshTable + 1);
            }
        } else {
            getErrorNotificationMessage(result);
        }
        setLoading(false);
    };

    const save = (deactivate: boolean = false) => {
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                if (values.date) values.date = getDateFormat(values.date, 'yyyy-MM-DD');
                if (values.travelWage) {
                    values.travelWage = getSearchFormat(values.travelWage);
                    //values.travelWage.currency = { id: currencyId };
                    //values.travelWage.amount = values.amount;
                }
                if (values.currency) values.currency = getSearchFormat(values.currency);
                if (values.country) values.country = getSearchFormat(values.country);
                values.travelWarrant = { id: travelWarrantId };
                values.id = value?.id ?? null;
                if (deactivate) {
                    values.active = false;
                }
                if (currencyId) {
                    values.currency = { id: currencyId };
                }
                delete values.travelWarrantWageExpensesList;

                if (values?.id) {
                    NyRequestResolver.requestPut(
                        CONSTANTS_REQ.TRAVEL_WARRANT_WAGE_RECAPITULATION.EDIT + '/' + values.id,
                        undefined,
                        values
                    ).then((result: any) => {
                        afterSave(result);
                    });
                } else {
                    NyRequestResolver.requestPost(
                        CONSTANTS_REQ.TRAVEL_WARRANT_WAGE_RECAPITULATION.EDIT,
                        undefined,
                        values
                    ).then((result: any) => {
                        afterSave(result);
                    });
                }
            })
            .catch((errorInfo: any) => {
                setLoading(false);
                errorNotification();
            });
    };

    const travelWageOnChange = (valueDropdown: any, amount: any = null) => {
        if (value?.id && initWage) {
            setDisabledAmount(valueDropdown && valueDropdown.id > 0 && valueDropdown.id != initWage?.id);
        }

        if (valueDropdown && valueDropdown.id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WAGE.EDIT + '/' + valueDropdown.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        form.setFieldsValue({
                            amount: amount ? amount : result.data.amount ? result.data.amount : 0,
                            travelWage: getTravelWageSelectOption(result.data),
                        });
                        setCurrencyId(result?.data?.currency?.id);
                    }
                }
            );
        }
    };

    return (
        <>
            {visible && (
                <Modal
                    width={600}
                    title={geti18nText('travelWarrantWageRecapitulation.title.day') + ' ' + wageDate}
                    maskClosable={false}
                    footer={[
                        <Button onClick={onModalClose}>{geti18nText('app.default.button.cancel')}</Button>,
                        <>
                            {value?.id && canEditWage && (
                                <NyModalConfirm
                                    title={geti18nText('app.default.destructive.confirm')}
                                    onConfirm={() => save(true)}
                                >
                                    <Button type="primary" danger disabled={!canEditWage}>
                                        {geti18nText('app.default.button.deactivate')}
                                    </Button>
                                </NyModalConfirm>
                            )}
                            <Button key="submit" type="primary" onClick={() => save(false)} disabled={!canEditWage}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                        </>,
                    ]}
                    open={visible}
                    onOk={() => save(false)}
                    onCancel={onModalClose}
                >
                    <>
                        {loading ? (
                            <NySpinner />
                        ) : (
                            <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('travelWarrantWageRecapitulation.date')}
                                            name="date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={moment()}
                                        >
                                            <NyDatePicker
                                                showTime={true}
                                                format="DD.MM.YYYY"
                                                style={{ width: '100%' }}
                                                nyTestId="date"
                                                disabled={!canEditWage}
                                                defaultPickerValue={date ?? moment()}
                                                onChange={(value: any) => setDate(value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantWageRecapitulation.day')}
                                            name="wageNumber"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={0}
                                        >
                                            <NyInputNumber
                                                isDecimal={true}
                                                style={{ width: '100%' }}
                                                decimalPlaces={1}
                                                min={0}
                                                max={1}
                                                disabled={!canEditWage}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <TravelWageSearch
                                            countryId={countryId}
                                            disabled={!canEditWage}
                                            travelWageOnChange={travelWageOnChange}
                                            label={geti18nText('travelWarrantWageRecapitulation.travelWageName')}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    {value?.id && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('travelWarrantWageRecapitulation.amount')}
                                                name="amount"
                                            >
                                                <NyInputNumber isDecimal={true} style={{ width: '100%' }} disabled />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            </Form>
                        )}
                    </>
                </Modal>
            )}
        </>
    );
};

export default TravelWarrantWageRecapitulationEdit;
