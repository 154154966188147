import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import useEnum from '../../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { userIsNotificationRecipient } from '../../../../../../utils/Utils';
import AdminEducationWizard from './adminEducationWizard';
import EmployeeEducationEditWizard from './educationEditWizard';
import EmployeeEducationNewWizard from './educationNewWizard';
import EmployeeEducationSummary from './educationSummary';

const EmployeeEducationEdit = ({
    visible,
    setVisible,
    value,
    setValue,
    setRefresh,
    isPersonalView,
    moduleAccessRight,
    employee,
    employeeId,
}: any) => {
    const employeeEducationStatus = useEnum('EMPLOYEE_EDUCATION_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const [employeeEducation, setEmployeeEducation] = useState<any>(null);

    const isUser = () => {
        return HumanResourcesRights.isEducationUser();
    };

    const isAdmin = () => {
        return HumanResourcesRights.isEducationAdmin();
    };

    const canViewAll = () => {
        return isUser() && userIsNotificationRecipient(employee, notificationType.EMPLOYEE_EDUCATION_APPROVAL);
    };

    const openNewWizard = () => {
        return (
            !isAdmin() &&
            (((!employeeEducation?.id || !employeeEducation?.status) && isUser()) ||
                (canViewAll() && employeeEducation?.status == employeeEducationStatus.DRAFT))
        );
    };

    const openRealization = () => {
        return (
            !isAdmin() &&
            ((employee?.id == employeeEducation?.employee?.id && isUser()) || canViewAll()) &&
            employeeEducation.status == employeeEducationStatus.REALIZATION
        );
    };

    useEffect(() => {
        if (value?.id) {
            getEmployeeEducation(value.id);
        }
    }, [value]);

    const getEmployeeEducation = (id: any) => {
        setEmployeeEducation(null);
        if (id && id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EDUCATION.EDIT + '/' + id).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setEmployeeEducation(result.data);
                }
            });
        }
    };

    const getAdminForm = () => (
        <AdminEducationWizard
            id={employeeEducation?.id}
            employeeEducation={employeeEducation}
            setEmployeeEducation={setEmployeeEducation}
            setRefresh={setRefresh}
            isPersonalView={isPersonalView}
            visible={visible}
            setVisible={setVisible}
            setValue={setValue}
            employeeId={employeeId}
        />
    );

    const getNewWizard = () => (
        <EmployeeEducationNewWizard
            id={employeeEducation?.id}
            employeeEducation={employeeEducation}
            setEmployeeEducation={setEmployeeEducation}
            setRefresh={setRefresh}
            isPersonalView={isPersonalView}
            visible={visible}
            setVisible={setVisible}
            setValue={setValue}
            employeeId={employeeId}
        />
    );

    const getEditWizard = () => (
        <EmployeeEducationEditWizard
            id={employeeEducation?.id}
            employeeEducation={employeeEducation}
            setEmployeeEducation={setEmployeeEducation}
            setRefresh={setRefresh}
            isPersonalView={isPersonalView}
            visible={visible}
            setVisible={setVisible}
            setValue={setValue}
        />
    );

    const getSummary = () => (
        <EmployeeEducationSummary
            id={employeeEducation?.id}
            employeeEducation={employeeEducation}
            setEmployeeEducation={setEmployeeEducation}
            setRefresh={setRefresh}
            isPersonalView={isPersonalView}
            visible={visible}
            setVisible={setVisible}
            setValue={setValue}
        />
    );

    return (
        <>
            {isAdmin()
                ? getAdminForm()
                : openNewWizard()
                ? getNewWizard()
                : openRealization()
                ? getEditWizard()
                : getSummary()}
        </>
    );
};
export default EmployeeEducationEdit;
