import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import EmployeeLeaveSettingsIndex from '../../../administration/views/application-settings/EmployeeLeaveSettingsIndex';
import EmployeeLeaveAdditionaIndex from '../employee-leave-additional-days';
import EmployeePaidLeaveTypeIndex from '../employee-paid-leave-type';
import EmployeeSeniorityAnnualIndex from '../employee-seniority-annual-leave';
import EmployeeAnnualLeaveIndex from './EmployeeAnnualLeaveIndex';
import EmployeeLeaveReqIndex from './EmployeeLeaveReqIndex';

const { TabPane } = Tabs;

const EmployeeLeaveIndex = () => {
    const location: any = useLocation();
    const [activeKey, setActiveKey] = useState('2');

    useEffect(() => {
        if (location.state && location.state.activeTabKey) {
            setActiveKey(location.state.activeTabKey.toString());
        }
        if (location?.state?.activeTab) {
            setActiveKey(location?.state?.activeTab.toString());
        }
    }, [location.state]);

    useHelper('human/employee_leave');

    return (
        <React.Fragment>
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <TabPane tab={geti18nText('employeeLeave.tab.2')} key="2">
                    <EmployeeLeaveReqIndex showMonthlyReport />
                </TabPane>
                <TabPane tab={geti18nText('employeeLeave.tab.1')} key="1">
                    <EmployeeAnnualLeaveIndex />
                </TabPane>
                <TabPane tab={geti18nText('menu.employeePaidLeaveType')} key="3">
                    <EmployeePaidLeaveTypeIndex />
                </TabPane>
                <TabPane tab={geti18nText('menu.employeeLeave.additional.days')} key="4">
                    <EmployeeLeaveAdditionaIndex />
                </TabPane>
                <TabPane tab={geti18nText('menu.employee.seniority.leave')} key="5">
                    <EmployeeSeniorityAnnualIndex />
                </TabPane>
                <TabPane tab={geti18nText('settings.title')} key="6">
                    <EmployeeLeaveSettingsIndex dirty={false} />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default EmployeeLeaveIndex;
