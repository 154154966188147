import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

import AdditionalInfo from '../../../../components/additional-info';
import { TasksRights } from '../../../../rights/tasksRights';
import { GetEnum } from '../../../../utils/Enums';

const TaskPriorityEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('task.priority.edit.title'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [createdById, setCreatedById] = useState<Number>(-1);
    const focusInput = useRef<any>(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);

    const [taskTypeEnums] = useState(GetEnum({ enumName: 'TASK_TYPE' }));

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('task.priority.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.createdBy) {
        }

        if (dataForm.created) {
            setCreatedDate(dataForm.created);
        }

        if (dataForm.createdBy) {
            setCreatedById(dataForm.createdBy.id);
            setCreatedBy(dataForm.createdBy);
            dataForm.createdBy = dataForm.createdBy.person.firstName + ' ' + dataForm.createdBy.person.lastName;
        }

        if (dataForm.updated) {
            setUpdatedDate(dataForm.updated);
        }

        if (dataForm.updatedBy) {
            setUpdatedBy(dataForm.updatedBy);
        }
        if (props?.addedData?.editPosition) {
            form.setFieldsValue({ position: props.addedData.editPosition });
            //  form.setFieldValue({ position: props.addedData.editPosition });
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        if (props?.propsData?.setItemEditData) {
            props?.propsData?.setItemEditData(undefined);
        }
        setEditHeader(geti18nText('task.priority.edit.title'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getCustomFooterContent = (
        <>
            {dataForm ? (
                <div style={{ float: 'left' }}>
                    <AdditionalInfo
                        created={createdDate}
                        createdBy={createdBy}
                        updated={updatedDate}
                        updatedBy={updatedBy}
                    />
                </div>
            ) : null}
        </>
    );

    return (
        <NyDataEdit
            closeModalButtonText={geti18nText('app.default.button.close')}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TASK_PRIORITY.EDIT}
            setValues={setValues}
            width={400}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            {...props}
            normalize={(values: any) => {
                if (props?.addedData?.editPosition) {
                    values.position = props.addedData.editPosition;
                }
                values.type = taskTypeEnums.SERVICE;
                delete values.updated;

                delete values.created;
                if (createdById != -1) {
                    values.createdBy = { id: createdById };
                }

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="position" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('room.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TaskPriorityEdit;
