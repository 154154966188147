import { NySpinner, geti18nText } from '@nybble/nyreact';
import { Empty, Radio } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import BusinessUnitTable from './BusinessUnitTable';
import EmployeeTable from './EmployeeTable';
import { DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';

const EmployeeMilitaryRecords = () => {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [activeList, setActiveList] = useState<any>(1);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                value={activeList}
                defaultValue={1}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('employeeMilitaryRecords.tab.1')}
                </Radio.Button>
                <Radio.Button value={2} onChange={onChangeActiveList}>
                    {geti18nText('employeeMilitaryRecords.tab.2')}
                </Radio.Button>
            </Radio.Group>

            {activeList == 1 ? (
                <BusinessUnitTable scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />
            ) : activeList == 2 ? (
                <EmployeeTable scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export default EmployeeMilitaryRecords;
