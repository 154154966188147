import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Switch } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, getEnumFormat, setEnumFormat } from '../../../../utils/Utils';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';

const WarehouseDocumentEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('warehouseDocument.edit.new'));
    const [loading, setLoading] = useState(false);
    const [predefined, setPredefined] = useState(false);
    const focusInput = useRef<any>(null);
    const [predefinedByTypeExists, setPredefinedByTypeExists] = useState<boolean>(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('warehouseDocument.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.type) {
            dataForm.type = setEnumFormat('WAREHOUSE_DOCUMENT_TYPE', dataForm.type);
            onTypeChange(dataForm.id, dataForm.type);
        }
        setPredefined(false);
        if (dataForm.predefined) {
            setPredefined(true);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setPredefinedByTypeExists(false);
        form.resetFields();
        setEditHeader(geti18nText('warehouseDocument.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onTypeChange = (id: any, value: any) => {
        setPredefinedByTypeExists(false);
        if (value && value.id) {
            let filter = encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'type', condition: 'equals', value: value.id },
                    { field: 'predefined', condition: 'equals_bool', value: 1 },
                ])
            );
            NyRequestResolver.requestGet(CONSTANTS_REQ.WAREHOUSE_DOCUMENT.SEARCH, {
                search: filter,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data.length && result.data.length > 0) {
                        if (id && result.data[0].id == id) {
                            setPredefinedByTypeExists(false);
                        } else {
                            setPredefinedByTypeExists(true);
                        }
                    }
                }
            });
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.WAREHOUSE_DOCUMENT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            nyTestId="warehouse-document-edit"
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.type) values.type = getEnumFormat(values.type);
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item label={geti18nText('warehouseDocument.edit.code')} name="code">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('warehouseDocument.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('warehouseDocument.edit.type')} name="type">
                        <NySearchField
                            options={getEnumArray('WAREHOUSE_DOCUMENT_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                            onChange={onTypeChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('warehouseDocument.edit.abbreviation')} name="abbreviation">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="predefined" valuePropName={'checked'} initialValue={false}>
                        <Checkbox disabled={predefinedByTypeExists}>
                            {geti18nText('warehouseDocument.edit.predefined')}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default WarehouseDocumentEdit;
