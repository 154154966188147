import { geti18nText } from '@nybble/nyreact';
import { Button, Radio } from 'antd';

export interface ColumnFilterItem {
    text: React.ReactNode;
    value: string | number | boolean;
    children?: ColumnFilterItem[];
}

export interface FilterDropdownProps {
    prefixCls: string;
    setSelectedKeys: (selectedKeys: { [index: string]: any }[]) => void;
    selectedKeys: { [index: string]: any }[];
    confirm: () => void;
    clearFilters: () => void;
    filters?: ColumnFilterItem[];
    visible: boolean;
}

export const getColumnFilterIsNullCheck = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
            <Radio.Group
                value={getValue(selectedKeys, undefined, undefined)}
                onChange={(e) => {
                    console.log('on change e ', e);
                    if (e?.target?.value == 0) {
                        setSelectedKeys([{ condition: 'is_not_null', value: 0 }]);
                    } else if (e?.target?.value == 1) {
                        setSelectedKeys([{ condition: 'is_null', value: 1 }]);
                    }

                    handleSearch(confirm);
                }}
            >
                <Radio style={{ display: 'block', height: '30px' }} value={0}>
                    {geti18nText('location.table.column.coordinates.exist')}
                </Radio>
                <Radio style={{ display: 'block', height: '30px' }} value={1}>
                    {geti18nText('location.table.column.coordinates.dont.exist')}
                </Radio>
            </Radio.Group>
            <Button
                style={{ display: 'block', marginTop: '2px', width: '100%' }}
                size="small"
                onClick={() => {
                    setSelectedKeys([]);

                    handleSearch(confirm);
                }}
            >
                {geti18nText('app.default.clear')}
            </Button>
        </div>
    ),
    defaultFilteredValue: null,
    defaultSortOrder: undefined,
});

function getValue(
    selectedKeys: { [index: string]: any }[],
    defaultValue: string | number | undefined,
    initialValue: string | number | undefined
) {
    return selectedKeys && selectedKeys[0]
        ? selectedKeys[0].value
        : initialValue != undefined
        ? initialValue
        : defaultValue
        ? defaultValue
        : undefined;
}

function handleSearch(confirm: () => void) {
    confirm();
}
