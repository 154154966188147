import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Layout, Menu, Badge, Divider, Dropdown, Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
// import { addTab, setActiveTab } from '../../slices/tabsSlice';
import { INyLayoutMenu } from './types';
import {
    CloseOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    StarFilled,
    StarOutlined,
    SwapOutlined,
} from '@ant-design/icons';
import { removeFromMyMenu, setMenuCollapsed } from '../../slices/menuSlice';
import './index.scss';
import { getColorFromType, userIsNotificationRecipient } from '../../utils/Utils';
import { cloneDeep } from 'lodash';
import defaultMenu from '../layout/menu';
import { Link, useHistory } from 'react-router-dom';
import MenuDivider from 'antd/lib/menu/MenuDivider';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { MultitenancyRights } from '../../rights/multitenancyRights';
import { getDashboardWidgetsForUser } from '../../slices/dashboardSlice';
import { getDashboardWidgetsForUserHR } from '../../slices/dashboardHRslice';
import { Base64 } from 'js-base64';

const { Sider } = Layout;
const { SubMenu } = Menu;

const LayoutMenu = ({
    menuHistory,
    menuItems,
    menuLocation,
    menuLogo,
    menuTheme = 'light',
    menuWidth = 200,
    siderClassName,
    menuClassName,
    noRoles = false,
}: INyLayoutMenu) => {
    const history = useHistory();
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [breakpoint, setBreakpoint] = useState<boolean>(false);

    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { collapsed, myMenu } = useSelector((state: RootState) => state.menu);
    const { employee } = useSelector((state: RootState) => state.employee);
    const { settings } = useSelector((state: RootState) => state.notification);
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);

    const dispatch = useDispatch();

    let root = document.documentElement;

    const [menu, setMenu] = useState<any>([]);
    const [userTenants, setUserTenants] = useState<string[] | undefined>(undefined);
    const roles = NySession.getUser().roles;

    function hasAnyRouteRole(menuRoles: string[]) {
        let res = false;
        if (menuRoles != undefined) {
            menuRoles.forEach((role: any) => {
                if (roles.includes(role)) {
                    res = true;
                    return res;
                }
            });
        }
        return res;
    }
    function getUserTenants() {
        if (MultitenancyRights.isMultitenantUser()) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.USER_AUTH.GET_TENANTS, undefined, true).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    let tenantList: string[] = result.data?.tenantList;
                    tenantList = tenantList?.filter((tenant) => {
                        return tenant !== NySession.getUser().tenantId;
                    });
                    setUserTenants(tenantList);
                }
            });
        }
    }
    function tenantSwitch(tenant: string) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.USER_AUTH.TENANT_SWITCH, undefined, {
            refreshToken: NySession.getUser().refresh_token,
            tenant: tenant,
        }).then((result: any) => {
            if (result.status == RESPONSE.OK && result.data) {
                let session: any = NyUtils.loadSession();
                let auth = { user: result.data, auth: session.auth };
                window.location.href = '#/login?auth=' + Base64.encodeURI(JSON.stringify(auth)) + '&tenantSwitching=1';
            }
        });
    }
    useEffect(() => {
        setSelectedKeys(findKey(menuItems, undefined, menuLocation.pathname));
        checkOverflow();
        dispatch(setMenuCollapsed(collapsed));
        if (collapsed) {
            root.style.setProperty('--sticky-width', '80px');
        } else {
            root.style.setProperty('--sticky-width', '200px');
        }
        var items = cloneDeep(menuItems);
        setMenu(generateMenu(items));
        getUserTenants();
    }, []);

    useEffect(() => {
        setSelectedKeys(findKey(menuItems, undefined, menuLocation.pathname.split('/').splice(0, 2).join('/')));
    }, [menuLocation.pathname]);

    useEffect(() => {
        var items = cloneDeep(menuItems);
        setMenu(generateMenu(items));
        setTimeout(checkOverflow, 500);
    }, [collapsed, settings, menuItems, employee, myMenu]);

    const checkOverflow = () => {
        let elements = Array.from(document.getElementsByClassName('menu-element'));
        elements.forEach((element: any) => {
            if (0 > element.clientWidth - element.scrollWidth) {
                element.classList.add('marquee');
            }
        });
    };

    function generateMenu(menus: any, addedType?: any) {
        let filteredMenus;
        if (!mealSyncTypeHNB) {
            filteredMenus = menus.filter((item: any) => item.isForHnb == undefined);
        } else {
            filteredMenus = menus;
        }
        let retValue = [];
        let menu: any;
        for (menu of filteredMenus) {
            if ((hasAnyRouteRole(menu.role) || noRoles || userIsNotificationRecipient(employee, menu.notificationType)) && (!menu.isForEmployeesOnly||employee)) {
                if (menu.submenu) {
                    if (addedType) {
                        menu.notificationType = addedType;
                    }
                    if (collapsed) {
                        retValue.push(
                            <SubMenu
                                className="menu-items-generated-color"
                                key={menu.key}
                                icon={
                                    menu.path != undefined ? (
                                        <Link className="menu-links" to={menu.path}>
                                            {menu.icon}
                                        </Link>
                                    ) : (
                                        menu.icon
                                    )
                                }
                                onTitleClick={menuSelected}
                                title={
                                    menu.path != undefined ? (
                                        <Link className="menu-links" to={menu.path}>
                                            {geti18nText(menu.i18n)}
                                        </Link>
                                    ) : (
                                        geti18nText(menu.i18n)
                                    )
                                }
                            >
                                <Menu.ItemGroup
                                    className="menu-items-generated-color"
                                    title={
                                        <div
                                            className="collapsed-menu-title"
                                            // style={{
                                            //     borderBottom:
                                            //         settings && getColorFromType(settings, menu.notificationType)
                                            //             ? `2px solid ${getColorFromType(
                                            //                   settings,
                                            //                   menu.notificationType
                                            //               )}`
                                            //             : `2px solid #2c7be5`,
                                            // }}
                                        >
                                            {geti18nText(menu.i18n)}
                                        </div>
                                    }
                                >
                                    {generateMenu(menu.submenu, menu.notificationType)}
                                </Menu.ItemGroup>
                            </SubMenu>
                        );
                    } else {
                        retValue.push(
                            <SubMenu
                                className="menu-items-generated-color"
                                key={menu.key}
                                icon={
                                    menu.path != undefined ? (
                                        <Link className="menu-links" to={menu.path}>
                                            {menu.icon}
                                        </Link>
                                    ) : (
                                        menu.icon
                                    )
                                }
                                onTitleClick={menuSelected}
                                title={
                                    menu.path != undefined ? (
                                        <Link className="menu-links" to={menu.path}>
                                            <div
                                                className="menu-element"
                                                // style={{ width: menu.width ? `${menu.width}px` : '100px' }}
                                            >
                                                <span>
                                                    <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                                        {geti18nText(menu.i18n)}
                                                    </span>
                                                </span>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div
                                            className="menu-element"
                                            // style={{ width: menu.width ? `${menu.width}px` : '100px' }}
                                        >
                                            <span>
                                                <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                                    {geti18nText(menu.i18n)}
                                                </span>
                                            </span>
                                        </div>
                                    )
                                }
                            >
                                {generateMenu(menu.submenu)}
                            </SubMenu>
                        );
                    }
                } else {
                    retValue.push(
                        <Menu.Item
                            className="menu-items-generated-color"
                            key={menu.key}
                            icon={menu.icon}
                            menu-path={menu.path}
                            menu-i18n={menu.i18n}
                            onMouseDown={(e: any) => {
                                if (e.button === 1) {
                                    addNewTab(
                                        e.currentTarget.getAttribute('menu-i18n'),
                                        e.currentTarget.getAttribute('menu-path')
                                    );
                                }
                            }}
                        >
                            {/* {geti18nText(menu.i18n)} */}
                            {collapsed ? (
                                geti18nText(menu.i18n)
                            ) : (
                                <div
                                    className="menu-element"
                                    // style={{ width: menu.width ? `${menu.width}px` : '100px' }}
                                >
                                    <span>
                                        <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                            {geti18nText(menu.i18n)}
                                        </span>
                                    </span>
                                </div>
                            )}
                        </Menu.Item>
                    );
                }
            }
        }
        return retValue;
    }

    function menuSelected(selected: any) {
        const key = findKey(menuItems, selected.key);
        if (key) {
            setSelectedKeys(key);
        }

        setTimeout(checkOverflow, 500);
    }

    function findKey(menus: any, key: any, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch: any = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                menuHistory.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                return [menu.key];
            }
        }
        return undefined;
    }

    const addNewTab = (i18n: string, path: string) => {
        if (path /*  && path !== active */) {
            // const panes = tabs.filter((item: any) => item.path === path);
            // if (panes.length === 0) {
            //     dispatch(addTab({ title: i18n, path: path }));
            // } else {
            // dispatch(setActiveTab(path));
            // }
            history.push(path);
        }
    };
    const items = (idx: any) => [
        {
            key: '1',
            label: (
                <div
                    onClick={() => {
                        dispatch(removeFromMyMenu(idx));
                    }}
                >
                    {geti18nText('menu.pinned.remove')}
                </div>
            ),
        },
    ];

    return (
        <Sider
            // breakpoint="lg"
            collapsed={collapsed}
            collapsible
            width={menuWidth}
            theme={menuTheme}
            className={siderClassName}
            onCollapse={(collapsed) => {
                dispatch(setMenuCollapsed(collapsed));
                if (collapsed) {
                    root.style.setProperty('--sticky-width', '80px');
                } else {
                    root.style.setProperty('--sticky-width', '200px');
                }
            }}
            onBreakpoint={(broken) => {
                setBreakpoint(broken);
            }}
            style={{
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            {!collapsed && menuLogo}
            <Menu
                theme={menuTheme}
                mode="inline"
                className={menuClassName}
                onClick={menuSelected}
                selectedKeys={selectedKeys}
                style={{ height: collapsed ? 'calc(100% - 100px)' : 'calc(100% - 130px)' }}
            >
                {/* {collapsed && (
                    <Menu.Item
                        key="menu.search"
                        onClick={() => {
                            if (collapsed) {
                                dispatch(setMenuCollapsed(false));
                                root.style.setProperty('--sticky-width', '200px');
                            } else {
                                setMenu(defaultMenu);
                            }
                        }}
                        icon={<SearchOutlined />}
                    ></Menu.Item>
                )} */}
                {userTenants && userTenants.length && (
                    <Menu.SubMenu title={geti18nText('menu.tenant.switch')} key="tenants" icon={<SwapOutlined />}>
                        {userTenants.map((tenant) => {
                            return (
                                <Menu.Item
                                    key={'tenant-' + tenant}
                                    // disabled={tenant === NySession.getUser().tenantId}
                                    onClick={() => tenantSwitch(tenant)}
                                >
                                    {tenant}
                                </Menu.Item>
                            );
                        })}
                    </Menu.SubMenu>
                )}
                <MenuDivider />
                <Menu.SubMenu title={geti18nText('menu.my.menu')} key="favorites" icon={<StarOutlined />}>
                    {myMenu?.map((item: any, idx: any) => {
                        return (
                            <Menu.Item key={item.key} icon={<StarFilled />}>
                                <Tooltip
                                    title={item.i18n
                                        .slice(0, -1)
                                        .map((val: any) => geti18nText(val))
                                        .join(' > ')}
                                    placement={'right'}
                                    overlayStyle={!collapsed ? { paddingLeft: '40px' } : {}}
                                >
                                    <Dropdown
                                        menu={{
                                            items: items(idx),
                                        }}
                                        trigger={['contextMenu']}
                                    >
                                        <Link to={item.path}>
                                            <div
                                                className="menu-element"
                                                style={{
                                                    height: '32px',
                                                    width: collapsed ? 'auto' : '110px',
                                                    display: collapsed ? 'inline-block' : '',
                                                }}
                                            >
                                                <span>
                                                    <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                                        {geti18nText(item.i18n.at(-1))}
                                                    </span>
                                                </span>
                                            </div>
                                        </Link>
                                    </Dropdown>
                                </Tooltip>
                            </Menu.Item>
                        );
                    })}
                </Menu.SubMenu>
                <MenuDivider />
                {menu}
            </Menu>
            <div style={{ display: 'grid', justifyItems: 'center', paddingTop: '5px' }}>
                {/* <Image width={collapsed ? 50 : 100} preview={false} src={nybble} /> */}
                <b
                    style={{ textAlign: 'center', color: '#585858', fontSize: collapsed ? '8px' : '10px' }}
                    className="version-menu"
                >
                    {geti18nText('navbar.version') + ' ' + NySession.getAppValue('VERSION')}
                </b>
            </div>
        </Sider>
    );
};

export default LayoutMenu;
