import { BoxPlotOutlined, FileOutlined, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import ActionsWidget from '.';

const ActionsWidgetHR = () => {
    const actions = [
        {
            label: geti18nText('notificationSettings.edit.employees'),
            path: '/human/employee',
            icon: <UsergroupAddOutlined />,
        },
        {
            label: geti18nText('employee.table.add'),
            path: '/human/employee/create',
            icon: <UserAddOutlined />,
        },
        {
            label: geti18nText('businessUnit.table.add'),
            path: '/human/codebooks/human/business-unit',
            modalName: 'businessUnitEdit',
            icon: <UsergroupAddOutlined />,
        },
        {
            label: geti18nText('workplace.table.add'),
            path: '/human/codebooks/human/workplace',
            modalName: 'workplaceEdit',
            icon: <FileOutlined />,
        },
        {
            label:
                geti18nText('menu.work.obligation.businessUnit') +
                ' - ' +
                geti18nText('app.default.button.graph').toLowerCase(),
            path: '/human/codebooks/human/business-unit',
            state: { activeList: 2 },
            icon: <BoxPlotOutlined />,
        },
    ];

    return <ActionsWidget actions={actions} maxHeight={'200px'} />;
};

export default ActionsWidgetHR;
