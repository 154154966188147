import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import moment from 'moment';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getColumnSearchByCodebookAssetCategory,
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookSimple,
} from '../../../../../utils/Filters';

const EmployeeTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'business_unit_id', condition: 'is_not_null', value: '' },
            { field: 'employee_id', condition: 'is_null', value: '' },
        ];
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const initialColumns = [
        {
            title: geti18nText('asset.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('asset.table.column.room'),
            dataIndex: ['room', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ROOM.SEARCH, 'room.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('employee.table.column.assetCategory'),
            dataIndex: ['assetCategory', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookAssetCategory(),
            render: (text: any, record: any) => {
                if (record.assetCategory) {
                    return (
                        record.assetCategory.name +
                        (record.assetCategory.code ? ' (' + record.assetCategory.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_TYPE.SEARCH, 'assetType.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.releaseDate'),
            dataIndex: 'releaseDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.releaseDate) {
                    return moment(record.releaseDate).format('DD.MM.YYYY');
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="asset-overview-butinsess-unit-table"
            url={CONSTANTS_REQ.ASSET.LIST}
            hideButtons
            onRowSelect={() => null}
            columns={initialColumns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('assetOverview.table.header.tab.2')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
        />
    );
};

export default EmployeeTable;

export const csvColumnCustomisation: any = () => {
    return [
        {
            assetType: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            assetModel: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            releaseDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('employee.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('asset.table.column.room'),
            dataIndex: ['room', 'name'],
        },
        {
            title: geti18nText('employee.table.column.assetCategory'),
            dataIndex: ['assetCategory', 'name'],
        },
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
        },
        {
            title: geti18nText('asset.table.column.releaseDate'),
            dataIndex: 'releaseDate',
        },
    ];
};
