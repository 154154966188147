import { geti18nText } from '@nybble/nyreact';
import { Alert, Col, Form, Modal, Radio, Row, Typography } from 'antd';
import useEnum from '../../hooks/useEnum';

const { Text } = Typography;

const ReportExportType = ({
    exportType,
    setExportType,
    isModal = false,
    visible,
    setVisible,
    onOk,
    onCancelExtraAction,
    title = geti18nText('report.servicesOrder.print'),
    okText = geti18nText('report.servicesOrder.print'),
}: any) => {
    const [inputForm] = Form.useForm();
    const enExportType = useEnum('EXPORT_TYPE');
    const options = [
        { label: 'PDF', value: enExportType.PDF },
        { label: 'Excel (xlsx)', value: enExportType.XLS },
    ];

    const getForm = () => (
        <Row gutter={24}>
            <Col span={24}>
                <Form form={inputForm} layout="vertical">
                    <Form.Item label={geti18nText('report.exportType')}>
                        <Radio.Group
                            options={options}
                            defaultValue={exportType ? exportType : enExportType.PDF}
                            value={exportType ? exportType : enExportType.PDF}
                            onChange={(e: any) => setExportType(e?.target?.value)}
                            optionType="button"
                            buttonStyle="solid"
                        ></Radio.Group>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );

    const onCancel = () => {
        setVisible(false);
        setExportType(enExportType.PDF);
        if (onCancelExtraAction) {
            onCancelExtraAction();
        }
    };

    const getModal = () => (
        <Modal
            open={visible}
            width={600}
            onOk={onOk}
            onCancel={onCancel}
            okText={okText}
            cancelText={geti18nText('app.default.button.cancel')}
            maskClosable={false}
            closable={false}
            title={title}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Alert
                        message={geti18nText('report.exportType.choose')}
                        type="info"
                        showIcon
                        style={{ marginBottom: '15px' }}
                    />
                </Col>
            </Row>
            {getForm()}
        </Modal>
    );

    return <>{isModal ? getModal() : getForm()}</>;
};

export default ReportExportType;
