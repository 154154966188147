import {
    BookOutlined,
    CheckOutlined,
    CloseCircleTwoTone,
    CloseOutlined,
    DeleteTwoTone,
    DownloadOutlined,
    EditTwoTone,
    FilePdfOutlined,
    PlusCircleOutlined,
    SaveTwoTone,
    StopOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Divider, Form, Input, Row, Space, Table, Tooltip, Upload, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NyHistoryModal from '../../../../../components/history';
import NyNoteModal from '../../../../../components/note-modal';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import { AssetRights } from '../../../../../rights/assetRights';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customAssetRenderOption,
    customEmployeeRenderOption,
    errorNotification,
    fileDownload,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import { manageFileUpload } from '../../../../../utils/ny-file-upload-custom/FilesFunctions';
import CompanySearch from '../../../../administration/views/company/search';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import EmployeeIndex from '../../../../human/views/employee';
import RoomSearch from '../../../../human/views/room/search';
import WarehouseIndex from '../../../../warehouse/views/warehouse';
import EditableCell from './EditableCell';

const AssetReleaseEdit = (props: any) => {
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const enableAssetRental = useApplicationSetting('ENABLE_ASSET_RENTAL');
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const enDocumentType = useEnum('DOCUMENT_TYPE');
    const [type, setType] = useState(enAssetReleaseSpecType.EMPLOYEE);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [editHeader, setEditHeader] = useState(geti18nText('assetRelease.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [editingKey, setEditingKey] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [uploadFile, setUploadFile] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [assetReleaseFiles, setAssetReleaseFiles] = useState<any>(undefined);
    const [refreshFiles, setRefreshFiles] = useState<any>(0);
    const [orderViewId, setOrderViewId] = useState<any>(props?.addedData?.orderViewId);
    const [isBooked, setIsBooked] = useState<boolean>(false);
    const [mandatoryReceipt, setMandatoryReceipt] = useState<boolean>(true);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);
    const [warehouse, setWarehouse] = useState<any>(null);

    const isEditing = (record: any) => record.id === editingKey;

    const isCompanyType = () => {
        return type && type == enAssetReleaseSpecType.COMPANY;
    };

    const canCreate = () => {
        return AssetRights.canCreateRelease() || (FixedAssetRights.canCreateAssetRent() && isCompanyType());
    };

    const showBookAction = () => {
        return canCreate() && !isCreate && !isBooked && !isCompanyType();
    };

    useEffect(() => {
        getAssetReleaseFiles();
    }, [refreshFiles]);

    useEffect(() => {
        if (props?.addedData && props?.addedData?.type) {
            setType(props?.addedData?.type);
        }
    }, [props?.addedData]);

    useEffect(() => {
        getWarehouse(orderViewId);
    }, [orderViewId]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getWarehouse = (orderViewId: any) => {
        if (orderViewId > 0) {
            setWarehouse(null);
            form.setFieldsValue({ warehouse: null });
            let filterWarehouse = setDefaultFilterValue();
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.WAREHOUSE.LIST_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString()),
                {
                    search: encodeURI(JSON.stringify(filterWarehouse)),
                }
            ).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.content?.length > 0 && result?.data.content[0]) {
                    const warehouseValue: any = setSearchFormat(result.data.content[0], 'name', 'name', 'code');
                    setWarehouse(warehouseValue);
                    form.setFieldsValue({ warehouse: warehouseValue });
                }
            });
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        setIsCreate(false);
        setIsBooked(dataForm.booked ? true : false);
        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('dateRelease')) {
            setEditHeader(
                geti18nText('assetRelease.edit.title') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.dateRelease).format('YYYY')
            );
        } else {
            setEditHeader(geti18nText('assetRelease.edit.title'));
        }

        if (dataForm.assetReleaseFiles != undefined) {
            setAssetReleaseFiles(dataForm.assetReleaseFiles);
            let uploadedFile = dataForm.assetReleaseFiles.find((file: any) => file.active);
            if (uploadedFile) {
                setUploadFile(uploadedFile.file.id);
            }
        }

        if (dataForm.file != undefined) {
            setFile(dataForm.file.id);
        }

        if (dataForm.businessUnit) {
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            setType(enAssetReleaseSpecType.ORG_UNIT);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
            setType(enAssetReleaseSpecType.EMPLOYEE);
        }
        if (dataForm.company) {
            dataForm.company = setSearchFormat(dataForm.company, 'name', 'name');
            setType(enAssetReleaseSpecType.COMPANY);
        }

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.assetReleaseItem) {
            setItems(dataForm.assetReleaseItem);
        }

        if (dataForm.dateRelease) {
            dataForm.dateRelease = setDateFormat(dataForm.dateRelease);
        }

        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
        }
        
        if (dataForm.orderView && dataForm.orderView.id) {
            setOrderViewId(dataForm.orderView.id);
        }

        if (dataForm.warehouse) {
            setWarehouse(setSearchFormat(dataForm.warehouse, 'name', 'name', 'code'));
            dataForm.warehouse = setSearchFormat(dataForm.warehouse, 'name', 'name', 'code');
        }  

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        assetForm.resetFields();
        setItems([]);
        setEditingKey('');
        setFile(undefined);
        setUploadFile(undefined);
        setNote(undefined);
        setAssetReleaseFiles(undefined);
        setEditHeader(geti18nText('assetRelease.edit.new'));
        setOrderViewId(null);
        setIsBooked(false);
        setWarehouse(null);
    };

    const onModalOpen = () => {
        setOrderViewId(props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (
            !(record.assetGeneral && record.assetGeneral.id != undefined) &&
            (!(record.asset && record.asset.id != undefined) ||
                !(record.assetPackage && record.assetPackage.id != undefined)) &&
            !(record.dateTo != undefined)
        ) {
            deleteRow(record.id);
        }
        setAddItemInProcess(false);
    };

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const normalizeRowFormValues = (values: any) => {
        const asset = values?.assetGeneral?.name?.props?.children;
        const assetNew = values?.assetGeneral?.label?.props?.children;
        const returnValue = asset
            ? {
                  asset: {
                      assetModel: { name: asset[0].props?.children },
                      serialNumber: asset[3].props?.children[1]?.props?.children,
                      inventoryNumber: asset[4].props?.children[1]?.props?.children,
                      id: asset[2]?.props?.children,
                  },
                  item: {
                      name: asset[5].props?.children[1]?.props?.children,
                      code: asset[6].props?.children[1]?.props?.children,
                      measureUnit: { abbreviation: asset[7]?.props?.children[1]?.props?.children },
                      mandatoryReceipt: mandatoryReceipt,
                  },
                  quantity: asset[8].props?.children[1]?.props?.children,
                  ...values,
              }
            : assetNew
            ? {
                  asset: {
                      assetModel: { name: assetNew[0]?.props?.children },
                      serialNumber: assetNew[2]?.props?.children[1]?.props?.children,
                      inventoryNumber: assetNew[4]?.props?.children[1]?.props?.children,
                      id: assetNew[1]?.props?.children,
                  },
                  item: {
                      name: assetNew[5]?.props?.children[1]?.props?.children,
                      code: assetNew[4]?.props?.children[1]?.props?.children,
                      measureUnit: { abbreviation: assetNew[6]?.props?.children[1]?.props?.children },
                      mandatoryReceipt: mandatoryReceipt,
                  },
                  quantity: assetNew[7].props?.children[1].props.children,
                  ...values,
              }
            : values;
        return returnValue;
    };

    const save = async (id: any) => {
        try {
            let row = await assetForm.validateFields();
            row = await normalizeRowFormValues(row);
            const newData = [...items];
            const index = newData.findIndex((item) => id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }
            setAddItemInProcess(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)} disabled={!isCreate}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button
                            type="link"
                            disabled={!isCreate}
                            onClick={() => {
                                cancel(record);
                            }}
                        >
                            <CloseCircleTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#faad14"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {isCreate && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={editingKey !== '' || !isCreate} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record.id);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={editingKey !== '' || !isCreate}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const checkIfIsAsset = (item: any) => {
        if (item != undefined) {
            return 1;
        } else {
            return 0;
        }
    };

    const assetName = (record: any) => {
        return (
            <>
                <span style={{ display: 'block' }}>
                    {record.assetGeneral?.itemName?.props?.children[0]?.props?.children}
                </span>
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>
                    {record.assetGeneral?.itemName?.props?.children[1]?.props?.children}
                </span>
            </>
        );
    };

    const columns = [
        {
            title: geti18nText('inventoryOrder.edit.code'),
            dataIndex: ['item', 'code'],
            editable: false,
            width: '250px',
            render: (text: any, record: any) => {
                return record?.item?.code
                    ? record?.item?.code
                    : record?.asset?.item?.code
                    ? record?.asset.item?.code
                    : '';
            },
        },
        {
            title: geti18nText('inventoryOrder.edit.name'),
            dataIndex: 'item',
            editable: false,
            inputType: 'item',
            render: (text: any, record: any) => {
                return record?.item?.name
                    ? record?.item?.name
                    : record?.asset?.item?.name
                    ? record?.asset.item?.name
                    : '';
            },
        },
        {
            title: geti18nText('webshopItem.edit.measureUnit'),
            dataIndex: ['item', 'measureUnit', 'abbreviation'],
            editable: false,
            width: '130px',
            render: (text: any, record: any) => {
                return record?.item?.measureUnit?.abbreviation
                    ? record?.item?.measureUnit?.abbreviation
                    : record?.measureUnit?.abbreviation
                    ? record?.measureUnit?.abbreviation
                    : record?.asset?.item?.measureUnit?.abbreviation
                    ? record?.asset?.item?.measureUnit?.abbreviation
                    : record?.asset?.measureUnit?.abbreviation
                    ? record?.asset?.measureUnit?.abbreviation
                    : '';
            },
        },

        {
            title: geti18nText('webshopItem.edit.mandatoryReceipt'),
            dataIndex: ['item', 'mandatoryReceipt'],
            width: '150px',
            render: (text: any, record: any) => {
                if (record?.item?.id && (record.asset || record.type === 'Oprema')) {
                    if (record.item.mandatoryReceipt) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                }
            },
        },
        {
            title: geti18nText('webshopItem.edit.quantity'),
            dataIndex: 'quantity',
            editable: true,
            width: '130px',
            inputType: 'quantity',
            render: (text: any, record: any) => {
                return record?.quantity ? record.quantity : record?.asset?.quantity ? record?.asset?.quantity : '1';
            },
        },
        ...(Object.values([enAssetReleaseSpecType.EMPLOYEE, enAssetReleaseSpecType.ORG_UNIT]).includes(type)
            ? [
                  {
                      title: geti18nText('assetRelease.table.column.type'),
                      dataIndex: 'type',
                      render: (text: any, record: any) => {
                          if (record.asset || record.assetPackage) {
                              return checkIfIsAsset(record.asset) ? 'Oprema' : 'Paket';
                          }

                          return text;
                      },
                  },
              ]
            : []),

        {
            title: geti18nText('assetRelease.table.column.asset'),
            dataIndex: 'assetGeneral',
            editable: true,
            inputType: 'asset',
            render: (text: any, record: any) => {
                if (record.asset != undefined || record.assetPackage != undefined) {
                    return checkIfIsAsset(record.asset)
                        ? customAssetRenderOption({
                              text: record.asset.assetModel.name,
                              serialNumber: record.asset.serialNumber,
                              inventoryNumber: record.asset.inventoryNumber,
                          })
                        : record.assetPackage.name;
                } else {
                    return (
                        record.assetGeneral && (record.assetGeneral.name ? record.assetGeneral.name : assetName(record))
                    );
                }
            },
        },
        {
            title: geti18nText('assetRelease.table.column.dateTo'),
            dataIndex: 'dateTo',
            editable: true,
            inputType: 'dateTo',
            width: '140px',
            render: (text: any, record: any) => {
                if (text) {
                    return getDateFormat(text, 'DD.MM.YYYY');
                }
            },
        },
        ...(!dataForm
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '110px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                let canEditRecord: boolean = isEditing(record);
                if (col?.dataIndex == 'quantity') {
                    const isMandatory: boolean = record?.item?.mandatoryReceipt;
                    canEditRecord = isEditing(record) && (record.asset || record.type === 'Oprema') && !isMandatory;
                }

                if (col.dataIndex === 'assetGeneral') {
                    let newInputType = undefined;

                    if (record.assetPackage || record.type === 'Paket') {
                        newInputType = 'package';
                    }
                    if (record.asset || record.type === 'Oprema') {
                        newInputType = 'asset';
                    }

                    return {
                        record,
                        inputType: newInputType,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        quantity: 1,
                        editing: canEditRecord,
                        form: assetForm,
                        mandatoryReceipt: mandatoryReceipt,
                        warehouse: warehouse,
                        setMandatoryReceipt: setMandatoryReceipt,
                    };
                }

                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    quantity: 1,
                    editing: canEditRecord,
                    form: assetForm,
                    mandatoryReceipt: mandatoryReceipt,
                    warehouse: warehouse,
                    setMandatoryReceipt: setMandatoryReceipt,
                };
            },
        };
    });

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };
        const isMandatory: boolean = record?.item?.mandatoryReceipt === true ? true : false;
        setMandatoryReceipt(isMandatory || !record?.item);

        if (editForm.dateTo) {
            editForm.dateTo = setDateFormat(editForm.dateTo);
        }

        if (editForm.assetPackage) {
            editForm.assetGeneral = editForm.assetPackage;
        }

        if (editForm?.asset?.id) {
            const name = record?.asset?.name
                ? record.asset.name
                : record?.asset?.assetModel?.name
                ? record.asset.assetModel.name
                : '';
            editForm.assetGeneral = {
                id: editForm?.asset?.id,
                name: name,
                serialNumber: record?.asset?.serialNumber
                    ? record.asset.serialNumber
                    : record.asset.assetModel.serialNumber
                    ? record.asset.assetModel.serialNumber
                    : '',
                text: name,
                label: name,
            };
        }

        if (record.asset || record.assetPackage) {
            editForm.type = checkIfIsAsset(record.asset) ? 'Oprema' : 'Paket';
        }

        assetForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const addNewRowAsset = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            type: 'Oprema',
            assetGeneral: undefined,
            dateTo: form.getFieldValue('dateTo'),
            quantity: 1,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const addNewRowPackage = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            type: 'Paket',
            assetGeneral: undefined,
            dateTo: form.getFieldValue('dateTo'),
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const book = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_RELEASE.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setIsBooked(true);
                    okNotification(geti18nText('inventoryRelease.button.book.success'));
                    props?.editProps?.setRefresh((prev: number) => prev + 1);
                    onModalClose();
                    setTriggerCloseModal(true);
                } else {
                    errorNotification(geti18nText('asset.release.booked'));
                }
                setLoading(false);
            });
        }
    };

    const upload = {
        name: 'file',
        action: CONSTANTS_REQ.FILES.UPLOAD,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                if (info.file.response) {
                    const result = info.file.response;
                    info.file.id = result;
                    manageFileUpload(
                        info.file,
                        result,
                        dataForm,
                        dataForm,
                        enDocumentType.RELEASE,
                        null,
                        CONSTANTS_REQ.FILES.MANAGE_UPLOAD,
                        CONSTANTS_REQ.FILES.DELETE_BY_IDS,
                        CONSTANTS_REQ.FILES.MANAGE_DELETE,
                        setRefreshFiles
                    );
                    setUploadFile(result);
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    const getAssetReleaseFiles = () => {
        if (dataForm) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_RELEASE.FILES, {
                id: dataForm,
            }).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    setAssetReleaseFiles(result.data);
                }
            });
        }
    };

    const cancelAssetRelease = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_RELEASE.DEACTIVATE + '/' + dataForm, undefined, {
            id: dataForm,
        }).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification();
                props?.setVisible(false);
                props?.onSave();
                onModalClose();
            } else if (result.data && result.data.error) {
                errorNotification(geti18nText(result.data.error));
            } else if (result.data && result.data instanceof String) {
                errorNotification(result.data);
            } else {
                errorNotification();
            }
        });
    };

    const historyColumns = [
        {
            title: geti18nText('assetRelease.history.column.name'),
            dataIndex: ['file', 'name'],
        },
        {
            title: geti18nText('assetRelease.history.column.uploaded'),
            dataIndex: 'uploaded',
            render: (uploaded: any) => {
                if (uploaded) {
                    return getDateFormat(uploaded, 'DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('assetRelease.history.column.uploadedBy'),
            dataIndex: 'user',
            render: (user: any) => {
                if (user && user.person) {
                    return `${user.person.firstName} ${user.person.lastName}`;
                }
            },
        },
        {
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record.file && record.file.id && (
                                <Tooltip title={geti18nText('assetRelease.button.download')}>
                                    <FilePdfOutlined
                                        style={{ marginLeft: '10px', fontSize: '16px' }}
                                        onClick={() => {
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.file.id,
                                                null,
                                                record.file.name
                                            );
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const downloadUploadedFile = () => {
        if (assetReleaseFiles && assetReleaseFiles.length > 0 && uploadFile != null && uploadFile != undefined) {
            const lastUploadedFile = assetReleaseFiles.at(-1);
            if (lastUploadedFile && lastUploadedFile.file && lastUploadedFile.file.id && lastUploadedFile.file.name) {
                fileDownload(
                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + lastUploadedFile.file.id,
                    null,
                    lastUploadedFile.file.name
                );
            }
        } else {
            fileDownload(
                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file,
                null,
                geti18nText('app.default.assetReleasePrint', [moment().format('yyyyMMDD')])
            );
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ float: 'left', marginTop: '2px' }}>
                <NyNoteModal note={note} />
            </div>
            {showBookAction() && (
                <div style={{ float: 'left', marginLeft: '30px' }}>
                    <NyModalConfirm
                        title={geti18nText('assetRelease.popconfirm.booking.cancel')}
                        onConfirm={() => {
                            book();
                        }}
                    >
                        <Button type={'primary'} icon={<BookOutlined />} disabled={isBooked} loading={loading}>
                            {geti18nText('assetRelease.button.booking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            <div style={{ float: 'left', marginLeft: showBookAction() ? '5px' : '25px' }}>
                {file !== undefined && (
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => {
                            fileDownload(
                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file,
                                null,
                                geti18nText('app.default.assetReleasePrint', [moment().format('yyyyMMDD')])
                            );
                        }}
                    >
                        {geti18nText('assetRelease.button.downloadNew')}
                    </Button>
                )}
                {uploadFile !== undefined && (
                    <Button icon={<DownloadOutlined />} onClick={downloadUploadedFile}>
                        {geti18nText('assetRelease.button.download')}
                    </Button>
                )}
                {assetReleaseFiles && (
                    <NyHistoryModal
                        columns={historyColumns}
                        dataSource={assetReleaseFiles}
                        customColumns={true}
                        buttonStyle={{ marginLeft: '0px', top: '-1px' }}
                    />
                )}
                {!isCreate && canCreate() && (
                    <Upload {...upload} data={{ documentType: 1, id: dataForm }}>
                        <Button icon={<UploadOutlined />} disabled={loading} style={{ marginLeft: '5px' }}>
                            {geti18nText('assetRelease.button.upload')}
                        </Button>
                    </Upload>
                )}
            </div>
            {showBookAction() && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('assetRelease.popconfirm.cancel')}
                        onConfirm={() => {
                            cancelAssetRelease();
                        }}
                    >
                        <Button icon={<StopOutlined />} type="primary" danger>
                            {geti18nText('assetRelease.button.cancel')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            if (result.data.room) {
                                form.setFieldsValue({ room: result.data.room });
                            }
                        }
                    }
                }
            );
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ASSET_RELEASE.EDIT}
            setValues={setValues}
            width={1800}
            hideActivationButtons={!isCreate || !canCreate()}
            hideSubmitButton={
                !isCreate ||
                !(items.length > 0) ||
                editingKey !== '' ||
                !canCreate() ||
                (enableAssetRental != '1' && isCompanyType())
            }
            form={form}
            setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={false}
            customFooterContent={getCustomFooterContent}
            triggerCloseModal={triggerCloseModal}
            normalize={(values: any) => {
                values.warehouse = warehouse ? getSearchFormat(warehouse) : null;
                if (values.dateRelease) values.dateRelease = getDateFormat(values.dateRelease);
                if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                if (type === enAssetReleaseSpecType.EMPLOYEE && values.employee) {
                    values.employee = getSearchFormat(values.employee);
                    delete values.businessUnit;
                } else if (type === enAssetReleaseSpecType.ORG_UNIT && values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    delete values.employee;
                } else if (type === enAssetReleaseSpecType.COMPANY && values.company) {
                    values.company = getSearchFormat(values.company);
                }

                if (orderViewId != null) values.orderView = { id: orderViewId };

                const itemsAsset: any = [];
                if (items != undefined) {
                    items.map((item: any) => {
                        let obj: any = {};
                        if (item.asset) {
                            obj.asset = { id: item.asset.id };
                            if (item.dateTo) {
                                obj.dateTo = getDateFormat(item.dateTo);
                            }
                        }

                        if (item.assetPackage) {
                            obj.assetPackage = { id: item.assetPackage.id };
                            if (item.dateTo) {
                                obj.dateTo = getDateFormat(item.dateTo);
                            }
                        }

                        if (item.assetGeneral) {
                            if (item.type === 'Oprema') {
                                obj.asset = { id: item.assetGeneral.id };
                            }
                            if (item.type === 'Paket') {
                                obj.assetPackage = { id: item.assetGeneral.id };
                            }

                            if (item.dateTo) {
                                obj.dateTo = getDateFormat(item.dateTo);
                            }
                        }
                        if (item.item && enAssetReleaseSpecType.COMPANY) {
                            obj.item = item.item;
                        }
                        obj.quantity = item.quantity ?? 1;
                        itemsAsset.push(obj);
                    });
                }

                values.assetReleaseItem = itemsAsset;

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={4}>
                    <Form.Item
                        label={
                            type === enAssetReleaseSpecType.COMPANY
                                ? geti18nText('assetRelease.edit.dateRelease.rental')
                                : geti18nText('assetRelease.edit.dateRelease')
                        }
                        name="dateRelease"
                        initialValue={moment()}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            disabled={!isCreate}
                            ref={focusInput}
                            style={{ width: '100%' }}
                            format={isCompanyType() ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label={
                            type === enAssetReleaseSpecType.COMPANY
                                ? geti18nText('assetRelease.edit.dateTo.rental')
                                : geti18nText('assetRelease.edit.dateTo')
                        }
                        name="dateTo"
                    >
                        <NyDatePicker disabled={!isCreate} style={{ width: '100%' }} mustGetPopupContainer={false} />
                    </Form.Item>
                </Col>
                {!isCompanyType() && (
                    <Col span={4}>
                        <Form.Item
                            label={geti18nText('inventoryRelease.edit.warehouse')}
                            name="warehouse"
                            initialValue={warehouse}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                url={CONSTANTS_REQ.WAREHOUSE.SEARCH_BY_ORDER_VIEW.replace(
                                    '{orderViewId}',
                                    orderViewId?.toString()
                                )}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={true}
                                mustGetPopupContainer={false}
                                defaultValue={warehouse}
                                SearchPopupComponent={<WarehouseIndex orderViewId={orderViewId} />}
                                setDefaultFilterValue={setDefaultFilterValue}
                            />
                        </Form.Item>
                    </Col>
                )}
                {type === enAssetReleaseSpecType.EMPLOYEE ? (
                    <React.Fragment>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('assetRelease.edit.employee')}
                                name="employee"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        employmentRecordId: 'employmentRecordId',
                                        businessUnit: 'businessUnit',
                                    }}
                                    searchBy="person.first_name || ' ' || person.last_name || ' ' || employment_record_id "
                                    itemName={[
                                        ['person', 'firstName'],
                                        ['person', 'lastName'],
                                    ]}
                                    renderOption={customEmployeeRenderOption}
                                    customItemNameLabel={'firstName lastName'}
                                    SearchPopupComponent={
                                        <EmployeeIndex disabled={true} parentKey="asset-release-edit" />
                                    }
                                    disabled={!isCreate}
                                    onChange={onEmployeeChange}
                                    order="person.last_name, person.first_name"
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <RoomSearch
                                disabled={!isCreate}
                                label={geti18nText('assetRelease.edit.room')}
                                map={{ id: 'id', label: 'name' }}
                                name="room"
                                searchBy="name"
                                mustGetPopupContainer={false}
                            />
                        </Col>
                    </React.Fragment>
                ) : type === enAssetReleaseSpecType.ORG_UNIT ? (
                    <Col span={6}>
                        <BusinessUnitSearch formItemName={'businessUnit'} disabled={!isCreate} required={true} />
                    </Col>
                ) : type === enAssetReleaseSpecType.COMPANY ? (
                    <Col span={6}>
                        <CompanySearch
                            label={geti18nText('assetRelease.rental.edit.company')}
                            disabled={!isCreate}
                            isModal={true}
                            required={true}
                        />
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            <Divider>{geti18nText('assetRelease.divider.1')}</Divider>
            {isCreate && (
                <Row gutter={24} style={{ height: '36px' }}>
                    <Col span={24}>
                        <Button
                            style={{ float: 'right', marginRight: '12px' }}
                            icon={<PlusCircleOutlined />}
                            onClick={addNewRowAsset}
                            disabled={addItemInProcess}
                        >
                            {geti18nText('assetRelease.button.addAsset')}
                        </Button>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={assetForm} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={items}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                            style={{ maxHeight: '400px', overflowY: 'auto' }}
                        />
                    </Form>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AssetReleaseEdit;
