import { EditTwoTone } from '@ant-design/icons';
import { NyDatePicker, NyModalConfirm, NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Divider, Form, Input, Modal, Row, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    getDateFormat,
    getErrorNotificationMessage,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../utils/Utils';
import DocumentTypeSearch from '../../views/human/views/document-type/search';
import NyDescription from '../ny-description';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';

const IdentityDocumentsIndex = ({ employeeId,disableIdentityDocumentUpload=false }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [identityDocuments, setIdentityDocuments] = useState<any>([]);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [editHeader, setEditHeader] = useState(geti18nText('identityDocuments.edit.new'));
    const [refresh, setRefresh] = useState(0);
    const [validFrom, setValidFrom] = useState<any>(moment());
    const [validTo, setValidTo] = useState<any>(moment());

    useEffect(() => {
        if (employeeId) {
            fetch(employeeId);
        }
    }, [employeeId, refresh]);

    const fetch = (employeeId: any) => {
        setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.IDENTITY_DOCUMENTS.GET_BY_EMPLOYEE + '/' + employeeId,
            undefined
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setIdentityDocuments(result.data);
            }
            setLoading(false);
        });
    };

    const edit = (record: any) => {
        if (record?.id) {
            if (record.documentType) {
                setEditHeader(geti18nText('identityDocuments.edit.title') + ' - ' + record.documentType.name);
                record.documentType = setSearchFormat(record.documentType, 'name', 'name');
            }
            if (record.validFrom) {
                record.validFrom = setDateFormat(record.validFrom);
                setValidFrom(record.validFrom);
            }
            if (record.validTo) {
                record.validTo = setDateFormat(record.validTo);
                setValidTo(record.validTo);
            }
            form.setFieldsValue(record);
            setVisible(true);
        }
    };

    const closeModal = () => {
        setVisible(false);
        setEditHeader(geti18nText('identityDocuments.edit.new'));
        form.resetFields();
        setValidFrom(moment());
        setValidTo(moment());
    };

    const onEndDateChange = (value: any) => {
        setValidTo(value ? moment(value) : moment());
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setValidFrom(value ? moment(value) : moment());
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    const columns: any = [
        ...[{
            title: geti18nText('identityDocuments.table.column.documentType'),
            dataIndex: ['documentType', 'name'],
            width: '25%',
            render: (description: any, record: any) => {
                if (record?.documentType?.name) {
                    return <NyDescription title={record?.documentType?.name} maxWidth={'100px'} />;
                }
            },
        },
        {
            title: geti18nText('identityDocuments.table.column.value'),
            dataIndex: 'value',
            width: '15%',
            render: (description: any, record: any) => {
                if (record?.value) {
                    return <NyDescription title={record?.value} maxWidth={'100px'} />;
                }
            },
        },
        {
            title: geti18nText('identityDocuments.table.column.validFrom'),
            dataIndex: 'validFrom',
            width: '12%',
            render: (text: any, record: any) => {
                return moment(record.validFrom).format('DD.MM.YYYY');
            },
        },
        {
            title: geti18nText('identityDocuments.table.column.validTo'),
            dataIndex: 'validTo',
            width: '12%',
            render: (text: any, record: any) => {
                return moment(record.validTo).format('DD.MM.YYYY');
            },
        },
        {
            title: geti18nText('identityDocuments.table.column.issuedBy'),
            dataIndex: 'issuedBy',
            width: '25%',
            render: (description: any, record: any) => {
                if (record?.issuedBy) {
                    return <NyDescription title={record?.issuedBy} maxWidth={'100px'} />;
                }
            },
        },],
        ...(!HumanResourcesRights.canViewEDRModal() && !disableIdentityDocumentUpload) ? [{
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            width: '50px',
            render: (text: any, record: any) => {
                return (
                    <>
                        {(
                            <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                <Button type="link" onClick={() => edit(record)}>
                                    <EditTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },]:[]
    ];

    const afterSaveAction = (result: any) => {
        if (result && result.status === RESPONSE.CREATED) {
            okNotification();
            setRefresh(refresh + 1);
            closeModal();
        } else {
            getErrorNotificationMessage(result);
        }
    };

    const saveIdentityDocument = (values: any, deactivate: boolean = false) => {
        setLoading(true);
        if (values.documentType) values.documentType = getSearchFormat(values.documentType);
        if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
        if (values.validTo) values.validTo = getDateFormat(values.validTo);
        if (!values.employee.id) {
            values.employee = { id: employeeId };
        }
        values.active = !deactivate;

        if (values) {
            if (values.id) {
                NyRequestResolver.requestPut(
                    CONSTANTS_REQ.IDENTITY_DOCUMENTS.EDIT + '/' + values.id,
                    undefined,
                    values
                ).then((result: any) => {
                    afterSaveAction(result);
                });
            } else {
                NyRequestResolver.requestPost(CONSTANTS_REQ.IDENTITY_DOCUMENTS.EDIT, undefined, values).then(
                    (result: any) => {
                        afterSaveAction(result);
                    }
                );
            }
        }
        setLoading(false);
    };

    const save = (deactivate: boolean = false) => {
        form.validateFields()
            .then((values: any) => {
                saveIdentityDocument(values, deactivate);
            })
            .catch((errorInfo: any) => {});
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Divider>{geti18nText('identityDocuments.table.header')}</Divider>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <>
                            <Table
                                size={'small'}
                                dataSource={identityDocuments}
                                columns={columns}
                                pagination={false}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onDoubleClick: (event) => {
                                            if (!HumanResourcesRights.canViewEDRModal()) {
                                                edit(record);
                                            }
                                        },
                                    };
                                }}
                            />
                            {(!HumanResourcesRights.canViewEDRModal() && !disableIdentityDocumentUpload) && (
                                <Button
                                    type="primary"
                                    onClick={() => setVisible(true)}
                                    style={{ float: 'right', marginTop: '10px' }}
                                >
                                    {geti18nText('identityDocuments.edit.new')}
                                </Button>
                            )}
                        </>
                    )}
                </Col>
            </Row>
            {visible && (
                <Modal
                    title={editHeader}
                    visible={visible}
                    width={600}
                    onCancel={closeModal}
                    maskClosable={false}
                    footer={[
                        <Button onClick={closeModal}>{geti18nText('app.default.button.cancel')}</Button>,
                        <NyModalConfirm
                            title={geti18nText('app.default.destructive.confirm')}
                            onConfirm={() => save(true)}
                        >
                            <Button type="primary" danger>
                                {geti18nText('app.default.button.deactivate')}
                            </Button>
                        </NyModalConfirm>,
                        <Button key="submit" type="primary" onClick={() => save(false)}>
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                >
                    <Form layout="vertical" form={form}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['employee', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <DocumentTypeSearch
                                    label={geti18nText('identityDocuments.edit.documentType')}
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('identityDocuments.edit.value')}
                                    name="value"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('identityDocuments.edit.validFrom')} name="validFrom">
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        onChange={onStartDateChange}
                                        disabledDate={disabledStartDate}
                                        defaultPickerValue={validFrom}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('identityDocuments.edit.validTo')} name="validTo">
                                    <NyDatePicker
                                        style={{ width: '100%' }}
                                        onChange={onEndDateChange}
                                        disabledDate={disabledendDate}
                                        nyTestId="valid-to"
                                        defaultPickerValue={validTo}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('identityDocuments.edit.issuedBy')} name="issuedBy">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default IdentityDocumentsIndex;
