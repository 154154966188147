import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

const InModalFilePreview = ({ previewID, setShowPreview, customColSpan = 10, setPreviewID }: any) => {
    const [fileType, setFileType] = useState<any>(null);
    const [file, setFile] = useState<any>(null);
    const [textContent, setTextContent] = useState<any>(null);
    useEffect(() => {
        if (previewID != null) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + previewID + '?v=' + new Date().valueOf(),
                undefined,
                false,
                true
            ).then((result: any) => {
                if (result?.status === RESPONSE.OK) {
                    result.data.filename = previewID + '?v=' + new Date().valueOf();
                    setFileType(result.data?.file?.type);
                    if (result.data?.file?.type?.includes('image') || result.data?.file?.type === 'application/pdf') {
                        setFile(result);
                        setShowPreview(true);
                    } else if (result.data?.file?.type === 'text/plain') {
                        setFile(result);
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            setTextContent(e.target?.result as string);
                            setShowPreview(true);
                        };
                        reader.readAsText(result.data.file);
                    } else {
                        setFile(null);
                        setShowPreview(false);
                    }
                }
            });
        }
    }, [previewID]);

    return (
        <>
            {file && (
                <Col span={customColSpan}>
                    <div>
                        <Button
                            onClick={() => {
                                setShowPreview(false);
                                setFile(null);
                                setPreviewID(null);
                            }}
                            size="small"
                            style={{ float: 'right' }}
                            danger
                        >
                            X
                        </Button>
                    </div>
                    <div style={{ marginTop: '25px' }}>
                        {fileType === 'application/pdf' ? (
                            <embed
                                src={window.URL.createObjectURL(file?.data?.file).concat(
                                    `#toolbar=0&navpanes=0&scrollbar=0&zoom=80&v=${Date.now()}`
                                )}
                                type="application/pdf"
                                height="650px"
                                width="100%"
                            />
                        ) : fileType?.includes('image') ? (
                            <div style={{ width: '100%', overflow: 'auto' }}>
                                <img
                                    src={window.URL.createObjectURL(file?.data?.file)}
                                    style={{ maxHeight: '650px' }}
                                />
                            </div>
                        ) : fileType === 'text/plain' ? (
                            <div style={{ maxHeight: '650px', overflow: 'auto' }}>
                                <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{textContent}</span>
                            </div>
                        ) : null}
                    </div>
                </Col>
            )}
        </>
    );
};

export default InModalFilePreview;
