import { MenuOutlined } from '@ant-design/icons';
import { forwardRef } from 'react';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../utils/Constants';
import { Image, Tooltip } from 'antd';
import { NySession } from '@nybble/nyreact';
import { getTravelWarrantTypeIcon } from '../../utils/Utils';

export const Item = forwardRef((props: any, ref) => {
    return props?.type == 'task-template' ? (
        <div className="sortable-item" style={{ cursor: 'grab', ...props.style }} {...props} ref={ref}>
            {
                <>
                    <span className="sortable-item-table" style={{ width: '5.5%' }}>
                        &nbsp;{props?.item?.id ? props?.item?.id : ''}
                    </span>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.code}>
                        <span
                            className="sortable-item-table"
                            style={{ cursor: 'grab', overflow: 'hidden', width: '10.5%', fontSize: '13px' }}
                        >
                            &nbsp;{props?.item?.code ? props?.item?.code : ''}
                        </span>
                    </Tooltip>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.name}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '28%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.name ? props?.item?.name : ''}
                        </span>
                    </Tooltip>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.description}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '28%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.description ? props?.item?.description : ''}
                        </span>
                    </Tooltip>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.taskTypeSubtype?.name}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.taskTypeSubtype?.name ? props?.item?.taskTypeSubtype?.name : ''}
                        </span>
                    </Tooltip>
                </>
            }
            <span className="sorted-item-span">
                <MenuOutlined className="ny-sort-icon" {...props?.listeners} />
            </span>
        </div>
    ) : props?.type == 'task-type-category' ? (
        <div className="sortable-item" style={{ cursor: 'grab', ...props.style }} {...props} ref={ref}>
            {
                <>
                    <span className="sortable-item-table" style={{ width: '8%' }}>
                        &nbsp;{props?.item?.id ? props?.item?.id : ''}
                    </span>
                </>
            }
            {
                <div style={{ width: '10%' }}>
                    {props?.item?.fileId ? (
                        <Image
                            style={{
                                objectFit: 'cover',
                                height: '35px',
                                width: '35px',
                                borderRadius: '5px',
                            }}
                            preview={true}
                            src={
                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                '/' +
                                props?.item.fileId +
                                '?tenant=' +
                                NySession.getUser().tenantId
                            }
                        />
                    ) : (
                        <Image
                            style={{
                                objectFit: 'cover',
                                height: '35px',
                                width: '35px',
                                borderRadius: '5px',
                            }}
                            preview={false}
                            src={FILE_FALLBACK}
                        />
                    )}
                </div>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.code}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '15%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.code ? props?.item?.code : ''}
                        </span>
                    </Tooltip>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.name}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '20%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.name ? props?.item?.name : ''}
                        </span>
                    </Tooltip>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.description}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '40%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.description ? props?.item?.description : ''}
                        </span>
                    </Tooltip>
                </>
            }
            <span className="sorted-item-span">
                <MenuOutlined className="ny-sort-icon" {...props?.listeners} />
            </span>
        </div>
    ) : props.type == 'travel-warrant-type' ? (
        <div
            className="sortable-item-list"
            style={{ cursor: 'grab', ...props.style }}
            {...props}
            ref={ref}
            {...props.listeners}
        >
            {
                <>
                    <span className="sortable-item-table" style={{ width: '5%' }}>
                        &nbsp;{props.id ? props.id : ''}
                    </span>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.name}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '30%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props.name ? props.name : ''}
                        </span>
                    </Tooltip>
                </>
            }
            {
                <>
                    <span
                        className="sortable-item-table"
                        style={{
                            marginLeft: '15px',
                            cursor: 'grab',
                            overflow: 'hidden',
                            width: '12%',
                            fontSize: '13px',
                        }}
                    >
                        &nbsp;{props.wageType ? getTravelWarrantTypeIcon(props.wageType) : ''}
                    </span>
                </>
            }
            {
                <>
                    <Tooltip placement="topLeft" title={props?.item?.description}>
                        <span
                            className="sortable-item-table"
                            style={{
                                cursor: 'grab',
                                overflow: 'hidden',
                                width: '46%',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            &nbsp;{props?.item?.description ? props?.item?.description : ''}
                        </span>
                    </Tooltip>
                </>
            }

            <span style={{ marginLeft: '15px', width: '2%' }}>
                <MenuOutlined className="item-sort-icon" {...props?.listeners} />
            </span>
        </div>
    ) : (
        <div
            className="sortable-item-list"
            style={{ cursor: 'grab', ...props.style }}
            {...props}
            ref={ref}
            {...props.listeners}
        >
            {
                <>
                    <span className="sortable-item-table" style={{ width: '2%' }}>
                        &nbsp;{props.id ? props.id : ''}
                    </span>
                </>
            }
            {
                <>
                    <span
                        className="sortable-item-table"
                        style={{ cursor: 'grab', overflow: 'hidden', width: '28%', fontSize: '13px' }}
                    >
                        &nbsp;{props.name ? props.name : ''}
                    </span>
                </>
            }
            {
                <>
                    <span
                        className="sortable-item-table"
                        style={{ cursor: 'grab', overflow: 'hidden', width: '18%', fontSize: '13px' }}
                    >
                        &nbsp;{props.createdPerson ? props.createdPerson : ''}
                    </span>
                </>
            }
            {
                <>
                    <span
                        className="sortable-item-table"
                        style={{ cursor: 'grab', overflow: 'hidden', width: '18%', fontSize: '13px' }}
                    >
                        &nbsp;{props.updatedPerson ? props.updatedPerson : ''}
                    </span>
                </>
            }

            {
                <>
                    <span
                        className="sortable-item-table"
                        style={{ cursor: 'grab', overflow: 'hidden', width: '12%', fontSize: '13px' }}
                    >
                        &nbsp;{props.created ? props.created : ''}
                    </span>
                </>
            }
            {
                <>
                    <span
                        className="sortable-item-table"
                        style={{ cursor: 'grab', overflow: 'hidden', width: '20%', fontSize: '13px' }}
                    >
                        &nbsp;{props.updated ? props.updated : ''}
                    </span>
                </>
            }

            <span style={{ width: '2%' }}>
                <MenuOutlined className="item-sort-icon" {...props?.listeners} />
            </span>
        </div>
    );
});
