import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import AddToGroup from './AddToGroup';

const EmployeeWithoutGroupTable = ({ props, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const { id } = useParams<any>();
    const table = useTableSettings();
    const { refreshTable } = useSelector((state: RootState) => state.employeeGroup);

    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        setRefresh(refreshTable);
    }, [refreshTable]);

    useEffect(() => {
        setRefresh(() => refresh + 1);
    }, [id]);

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_group_id', condition: 'is_null', value: '' },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('employee.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employee.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employee.table.column.employmentRecordId'),
            dataIndex: 'employmentRecordId',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-employee-without-group-table"
            url={CONSTANTS_REQ.EMPLOYEE.LIST}
            setDefaultFilterValue={setDefaultFilterValue}
            showRowSelection={canCreate()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultPageSize={table.setDefaultPageSize()}
            rowSelectionModal={AddToGroup}
            fetchWhenChange={refresh}
            showRecordModal={true}
            hideNewButton={true}
            columns={initialColumns}
            buttonsClassName="buttons-sticky"
        />
    );
};

export default EmployeeWithoutGroupTable;
