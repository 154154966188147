import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import moment from 'moment';
import { useState } from 'react';
import RowAction from '../../../../../components/row-action';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getEnumArrayStatus, getOrderStatusIcon } from '../../../../../utils/Utils';
import AssetOrderEdit from '../asset-order/edit';
import EmployeeAssetOrderEdit from './EmployeeAssetOrderEdit';

const EmployeeAssetOrderTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const [item, setItem] = useState<any>(undefined);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const table = useTableSettings();
    useHelper('my_orders');

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'forDashboard', condition: 'equals_bool', value: 1 },
        ];
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    const columns = [
        {
            title: geti18nText('inventoryOrder.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('inventoryOrder.table.column.ord'),
            dataIndex: 'ord',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.firstName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE'), 'equals'),
            render: (status: any) => {
                return getOrderStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'AssetOrder'} record={record}></RowAction>;
                }
            },
        },
    ];

    const onRowSelect = (item: any) => {
        if (item) {
            setItem(item);
            if (item.status && item.status != 2) {
                setIsModalVisible(true);
            } else {
                setIsEditModalVisible(true);
            }
        }
    };

    return (
        <div style={{ marginTop: '8px' }}>
            <NyDataTable
                nyId="webshop-asset-other-asset-table"
                url={CONSTANTS_REQ.ASSET_ORDER.LIST}
                buttonsClassName="buttons-sticky"
                showRecordModal={false}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                hideNewButton={true}
                onRowSelect={onRowSelect}
                fetchWhenChange={refreshTable}
                exportCSV
                CSVFileName={geti18nText('menu.webshopAsset')}
            />
            <EmployeeAssetOrderEdit
                item={item}
                setItem={setItem}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
            <AssetOrderEdit
                isModal={true}
                visible={isEditModalVisible}
                setVisible={setIsEditModalVisible}
                value={item}
                onSave={() => {
                    setRefreshTable((refresh: number) => refresh + 1);
                }}
            />
        </div>
    );
};

export default EmployeeAssetOrderTable;
