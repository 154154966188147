import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import InventoryReleaseEdit from '../../webshop-item/inventory-release/edit';
import useTableSettings from '../../../../../hooks/useTableSettings';

const InventoryReleaseTable = ({
    servicesOrderId,
    form,
    status,
    orderViewId,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'servicesOrderId', condition: 'equals', value: servicesOrderId },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('inventoryRelease.table.column.ord'),
            dataIndex: 'ord',
            width: '50%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryRelease.table.column.date'),
            dataIndex: 'date',
            width: '50%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
    ];

    const addedButtons = () => {
        return (
            <>
                {status === 5 && (
                    <Button type="primary" style={{ float: 'right' }} onClick={() => setIsEditModalVisible(true)}>
                        {geti18nText('inventoryOrder.createInventoryRelease')}
                    </Button>
                )}
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="webshop-services-other-inventory-release"
                url={CONSTANTS_REQ.INVENTORY_RELEASE.LIST}
                showRecordModal={true}
                modalComponent={InventoryReleaseEdit}
                columns={initialColumns}
                setDefaultPageSize={table.setDefaultPageSize()}
                hideNewButton={true}
                setDefaultFilterValue={setDefaultFilterValue}
                fetchWhenChange={refreshTable}
                addedButtons={addedButtons}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
            />
            <InventoryReleaseEdit
                isModal={true}
                visible={isEditModalVisible}
                setVisible={setIsEditModalVisible}
                onSave={() => {
                    setRefreshTable((refresh: number) => refresh + 1);
                }}
                editing={true}
                employee={form.getFieldValue('employee')}
                room={form.getFieldValue('room')}
                servicesOrderId={servicesOrderId}
                type={8}
                addedData={{ orderViewId: orderViewId }}
                isNew={true}
            />
        </>
    );
};

export default InventoryReleaseTable;
