import { geti18nText } from '@nybble/nyreact';
import { Radio } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import TravelOverviewCost from './TravelOverviewCost';
import TravelOverviewGeneral from './TravelOverviewGeneral';

const TravelCostsOverviewIndex = () => {
    useHelper('travel_order/travel_costs_overview');
    const [activeList, setActiveList] = useState<number>(1);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
    };

    return (
        <>
            <Radio.Group
                value={activeList}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('travelOverview.tab.general')}
                </Radio.Button>
                <Radio.Button value={2} onChange={onChangeActiveList}>
                    {geti18nText('travelOverview.tab.costs')}
                </Radio.Button>
            </Radio.Group>
            {activeList == 1 ? <TravelOverviewGeneral /> : <TravelOverviewCost />}
        </>
    );
};

export default TravelCostsOverviewIndex;
