import { useEffect } from 'react';
import EmployeeEdit from './edit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { useHistory } from 'react-router';

const EmployeePersonalEdit = () => {
    const history = useHistory();
    const { employee } = useSelector((state: RootState) => state.employee);
    useEffect(() => {
        history.push('/human/employee/' + employee?.id);
    }, []);
    return <></>;
};

export default EmployeePersonalEdit;
