import { NySession } from '@nybble/nyreact';
export const AssetRights = (function () {
    return {
        //settings
        canCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_ASSET_SETTINGS']);
        },
        //codebooks
        canCreateCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_CODEBOOKS_EDIT']);
        },
        canCsvExportCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_CODEBOOKS_EXPORT']);
        },
        //order
        canViewOrder: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_ORDER_VIEW']);
        },
        canViewOrderAsset: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_ORDER_VIEW', 'ROLE_EQUIPMENT_ORDER_VIEW']);
        },
        canCreateOrder: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_ORDER_EDIT', 'ROLE_EQUIPMENT_REQUEST_EDIT']);
        },
        //release
        canCreateRelease: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_RELEASE_EDIT', 'ROLE_ASSET_RENT']);
        },
        //return
        canCreateReturn: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_RETURN_EDIT']);
        },
        //inventory
        canCreateInventory: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_INVENTORY_EDIT']);
        },
        //rental
        canCreateRentalRelease: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EQUIPMENT_RETURN_EDIT']);
        },
        ///////////////////
        //menu
        canViewOrderMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_ORDER_VIEW'];
        },
        canCreateSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_SETTINGS'];
        },
        canViewWebshopMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_INVENTORY_REQUEST_MENU',
                'ROLE_EQUIPMENT_REQUEST_MENU',
                'ROLE_SERVICES_REQUEST_MENU',
                'ROLE_MEALS',
                'ROLE_MEALS_ADMIN',
            ];
        },
        canViewAssetShopMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_EQUIPMENT_REQUEST_MENU',
                'ROLE_EQUIPMENT_ORDER_MENU',
                'ROLE_EQUIPMENT_RELEASE_MENU',
                'ROLE_EQUIPMENT_RETURN_MENU',
                'ROLE_EQUIPMENT_OVERVIEW_MENU',
                'ROLE_EQUIPMENT_INVENTORY_MENU',
            ];
        },
        canViewRequestMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_REQUEST_MENU'];
        },
        canViewOrderViewMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_ORDER_MENU'];
        },
        canViewReleaseMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_RELEASE_MENU'];
        },
        canViewReturnMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_RETURN_MENU'];
        },
        canViewOverviewMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_OVERVIEW_MENU'];
        },
        canViewInventoryMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_INVENTORY_MENU'];
        },
        canViewWebshopCodebooksMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_INVENTORY_CODEBOOKS_MENU',
                'ROLE_EQUIPMENT_CODEBOOKS_MENU',
                'ROLE_SERVICES_CODEBOOKS_MENU',
                'ROLE_MEALS_ADMIN',
            ];
        },
        canViewAssetCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_CODEBOOKS_MENU'];
        },
        canViewWebshopOverviewModule: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_INVENTORY_REQUEST_MENU',
                'ROLE_EQUIPMENT_REQUEST_MENU',
                'ROLE_SERVICES_REQUEST_MENU',
                'ROLE_MEALS',
                'ROLE_MEALS_ADMIN',
            ];
        },
        //routes
        canViewWebshopModuleRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_INVENTORY_REQUEST_MENU',
                'ROLE_EQUIPMENT_REQUEST_MENU',
                'ROLE_SERVICES_REQUEST_MENU',
                'ROLE_MEALS',
                'ROLE_MEALS_ADMIN',
            ];
        },
        canViewAssetRequestRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_REQUEST_VIEW'];
        },
        canViewWebshopRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_INVENTORY_REQUEST_MENU',
                'ROLE_EQUIPMENT_REQUEST_MENU',
                'ROLE_SERVICES_REQUEST_MENU',
                'ROLE_MEALS',
                'ROLE_MEALS_ADMIN',
            ];
        },
        canViewCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_CODEBOOKS_VIEW'];
        },
        canViewReleaseRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_RELEASE_VIEW'];
        },
        canViewReturnRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_RETURN_VIEW'];
        },
        canViewOverviewRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_OVERVIEW_VIEW'];
        },
        canViewInventoryRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_EQUIPMENT_INVENTORY_VIEW'];
        },
    };
})();
