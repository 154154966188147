import {
    ExclamationCircleOutlined,
    FilterOutlined,
    PlusCircleOutlined,
    ShoppingCartOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
} from '@ant-design/icons';
import {
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySession,
    NySpinner,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Empty,
    Form,
    Image,
    Input,
    List,
    Modal,
    Radio,
    Row,
    Select,
    Tag,
    Tree,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ItemDetails from '../../../../components/item-details';
import ItemGroupFolder from '../../../../components/item-group-folder';
import NyReportButton from '../../../../components/report-button';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import { RootState } from '../../../../rootReducer';
import {
    setServicesBasket,
    setServicesBasketLength,
    setServicesNote,
    setServicesOrderView,
} from '../../../../slices/webshopSlice';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import { getDateFormat, isAdmin, onKeyDownTextAreaCustom } from '../../../../utils/Utils';
import WebshopServicesEdit from './edit';

const { Search } = Input;
const { Meta } = Card;

let timeout: any = null;

const WebshopServicesIndex = ({ myPortal = false }: any) => {
    const dispatch = useDispatch();
    const { itemGroupView } = useSelector((state: RootState) => state.generalSettings);
    const { employeeId } = useSelector((state: RootState) => state.attendance);
    const { webshopServicesBasket, webshopServicesBasketLength, webshopServicesOrderView } = useSelector(
        (state: RootState) => state.webshop
    );
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const [objectMetadataForm] = Form.useForm();
    const [total, setTotal] = useState<any>(0);
    const [shown, setShown] = useState<any>(0);
    const [tempId, setTempId] = useState<any>(0);
    const [loadMore, setLoadMore] = useState<any>(true);
    const [loading, setLoading] = useState<any>(false);
    const [items, setItems] = useState<any>([]);
    const [itemGroups, setItemGroups] = useState<any>([]);
    const [itemGroupsData, setItemGroupsData] = useState<any>([]);
    const [orderType, setOrderType] = useState<any>('asc');
    const [filterItemGroups, setFilterItemGroups] = useState<any>(null);
    const [filterSearch, setFilterSearch] = useState<any>(null);
    const [orderViewLabel, setOrderViewLabel] = useState<any>('');
    const [orderViewList, setOrderViewList] = useState<any>([]);
    const [pagination, setPagination] = useState<any>({
        defaultCurrent: 1,
        defaultPageSize: 24,
        showSizeChanger: true,
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });
    const [itemsBasket, setItemsBasket] = useState<any>([]);
    const [itemsBasketLength, setItemsBasketLength] = useState(0);
    const [basketVisible, setBasketVisible] = useState(false);
    const [cartEmployeeId, setCartEmployeeId] = useState<any>(null);
    const [pdfDefaultFilter, setPdfDefaultFilter] = useState<any>(undefined);
    const [metadataModalVisible, setMetadataModalVisible] = useState(false);
    const [metadata, setMetadata] = useState([]);
    const [itemTitle, setItemTitle] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>(false);
    const [isMapLoading, setisMapLoading] = useState<any>(false);
    const [breadcrumbData, setBreadcrumbData] = useState<any>([]);
    const [showItemGroupFolder, setShowItemGroupFolder] = useState<any>(itemGroupView === 'folder' ? true : false);
    const initialRender = useRef(true);
    const resetGroup = useRef(false);

    useHelper('webshop_services');

    useEffect(() => {
        setItemsBasket(webshopServicesBasket);
        setItemsBasketLength(webshopServicesBasketLength);
    }, []);

    useEffect(() => {
        if (employeeId) {
            setCartEmployeeId(employeeId);
        }
    }, [employeeId]);

    useEffect(() => {
        setLoadMore(true);
        if (shown == total) setLoadMore(false);
    }, [shown, total]);

    useEffect(() => {
        fetchItemGroup();
        setFilterItemGroups([]);
        resetGroup.current = true;
    }, [webshopServicesOrderView]);

    useEffect(() => {
        fetch(pagination, resetGroup.current);
        if (resetGroup.current) resetGroup.current = false;
    }, [orderType, filterItemGroups, filterSearch, webshopServicesOrderView]);

    useEffect(() => {
        dispatch(setServicesBasket(itemsBasket));
    }, [itemsBasket]);

    useEffect(() => {
        dispatch(setServicesBasketLength(itemsBasketLength));
    }, [itemsBasketLength]);

    const setDefaultFilterValueItem = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 2 },
            { field: 'availabilityStatus', condition: 'equals', value: 1 },
            { field: 'warehouseItem.active', condition: 'equals_bool', value: 1 },
        ];
    };

    const setDefaultFilterValueOrderView = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 3 },
            { field: 'orderViewAccessType', condition: 'equals', value: 1 },
        ];
    };

    const fetch = (pageable: any, resetGroup: Boolean) => {
        if (resetGroup === true) return;
        setLoading(true);
        setisMapLoading(true);
        pageable.current = 1;
        pageable.pageSize = pagination.defaultPageSize;
        let defaultFilter = setDefaultFilterValueItem();

        const emptyData: any = [];
        if ((orderViewList == emptyData || orderViewList.length < 1) && initialRender.current) {
            initialRender.current = false;
            let defaultFilterOrder = setDefaultFilterValueOrderView();
            NyRequestResolver.requestGet(
                isAdmin() ? CONSTANTS_REQ.ORDER_VIEW.USER_SEARCH_LIST_ALL : CONSTANTS_REQ.ORDER_VIEW.USER_SEARCH,
                {
                    search: encodeURI(JSON.stringify(defaultFilterOrder)),
                }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        let oView: any = [];
                        for (let key in result.data) {
                            if (result.data.hasOwnProperty(key)) {
                                let order = { value: undefined, label: undefined, number: undefined };
                                order.value = result.data[key].id;
                                order.label = result.data[key].name;
                                oView.push(order);
                            }
                        }
                        setOrderViewList(oView);

                        if (result.data[0]) {
                            if (webshopServicesOrderView) {
                                const webshopOrderViewName = oView.filter(
                                    (item: any) => item.value == webshopServicesOrderView
                                );
                                if (webshopOrderViewName && webshopOrderViewName[0]?.label) {
                                    setOrderViewLabel(webshopOrderViewName[0].label);
                                }
                            } else {
                                if (result.data[0].id) {
                                    dispatch(setServicesOrderView(result.data[0].id));
                                }
                                if (result.data[0].name) {
                                    setOrderViewLabel(result.data[0].name);
                                }
                            }
                        }
                    }
                }
            });
        }

        if (webshopServicesOrderView) {
            defaultFilter.push({
                field: 'orderView.id',
                condition: 'equals',
                value: webshopServicesOrderView,
            });
        }

        if (filterSearch) {
            defaultFilter.push({
                field: 'name',
                condition: 'contains',
                value: filterSearch,
            });
        }

        if (filterItemGroups !== null) {
            if (filterItemGroups.length > 1) {
                defaultFilter.push({
                    field: 'itemGroup.id',
                    condition: 'in',
                    value: filterItemGroups.toString(),
                });
            } else {
                defaultFilter.push({
                    field: 'itemGroup.id',
                    condition: 'equals',
                    value: filterItemGroups.toString(),
                });
            }
        }

        setPdfDefaultFilter(encodeURI(JSON.stringify(defaultFilter)));

        if (webshopServicesOrderView) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.ORDER_VIEW_LIST, {
                search: encodeURI(JSON.stringify(defaultFilter)),
                offset: pageable.current ? pageable.current - 1 : pageable.defaultCurrent - 1,
                max: pageable.pageSize ? pageable.pageSize : pagination.defaultPageSize,
                order: 'name',
                orderType: orderType,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        const responseData: any = result.data;
                        pageable.total = responseData['totalSize'];
                        pageable.current = responseData['pageNumber'] + 1;
                        pageable.pageSize = responseData['size'];

                        setTotal(responseData['totalSize']);
                        setItems(responseData.content);
                        setShown(responseData['numberOfElements']);
                        setPagination(pageable);
                    }
                }
                setLoading(false);
                setisMapLoading(false);
            });
        } else {
            setLoading(false);
            setisMapLoading(false);
        }
    };

    const onLoadMore = () => {
        const pageable = { ...pagination };
        pageable.current = pageable.current + 1;
        pageable.pageSize = pagination.defaultPageSize;

        setLoading(true);
        let defaultFilter = setDefaultFilterValueItem();
        if (filterSearch) {
            defaultFilter.push({
                field: 'name',
                condition: 'contains',
                value: filterSearch,
            });
        }

        if (webshopServicesOrderView) {
            defaultFilter.push({
                field: 'orderView.id',
                condition: 'equals',
                value: webshopServicesOrderView,
            });
        }

        if (filterItemGroups !== null) {
            if (filterItemGroups.length > 1) {
                defaultFilter.push({
                    field: 'itemGroup.id',
                    condition: 'in',
                    value: filterItemGroups.toString(),
                });
            } else {
                defaultFilter.push({
                    field: 'itemGroup.id',
                    condition: 'equals',
                    value: filterItemGroups.toString(),
                });
            }
        }
        setPdfDefaultFilter(encodeURI(JSON.stringify(defaultFilter)));

        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.ORDER_VIEW_LIST, {
            search: encodeURI(JSON.stringify(defaultFilter)),
            offset: pageable.current ? pageable.current - 1 : pageable.defaultCurrent - 1,
            max: pageable.pageSize ? pageable.pageSize : pagination.defaultPageSize,
            order: 'name',
            orderType: orderType,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    const responseData: any = result.data;
                    pageable.total = responseData['totalSize'];
                    pageable.current = responseData['pageNumber'] + 1;
                    pageable.pageSize = responseData['size'];

                    setTotal(responseData['totalSize']);
                    setItems(items.concat(responseData.content));
                    setShown(items.length + responseData['numberOfElements']);
                    setPagination(pageable);
                }
            }
            setLoading(false);
        });
    };

    const fetchItemGroup = () => {
        var parms: any = {};
        if (webshopServicesOrderView) {
            parms.orderViewId = webshopServicesOrderView;
            parms.type = 2;
            NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.FIND_BY_ORDER_VIEW, parms).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        const responseData: any = result.data;
                        setItemGroups(responseData);
                        setItemGroupsData(responseData);
                    }
                }
            });
        }
    };

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'itemGroup.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.METADATA, {
            max: 100,
            search: filter,
            order: 'id',
            orderType: 'asc',
        }).then((result: any) => {
            setIsLoading(false);
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setMetadata(result.data.content);
                    setMetadataModalVisible(true);
                } else {
                    addToBasket(false);
                }
            }
        });
    };

    const fetchMetadataItem = (item: any) => {
        if (item && item.itemGroup && item.itemGroup.id) {
            let filter = encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'itemGroup.id', condition: 'equals', value: item.itemGroup.id },
                ])
            );
            NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.METADATA, {
                max: 100,
                search: filter,
            }).then((result: any) => {
                setIsLoading(false);
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data.content) {
                        setMetadata(result.data.content);
                        setMetadataModalVisible(true);
                    } else {
                        addToBasket(false);
                    }
                }
            });
        } else {
            addToBasket(false);
        }
    };

    const onSearch = (value: any) => {
        timeoutClear();
        const pageable = { ...pagination };
        pageable.current = 1;
        pageable.pageSize = pagination.defaultPageSize;
        setPagination(pageable);
        setFilterSearch(value);
    };

    const onChange = (value: any) => {
        timeoutClear();
        timeout = setTimeout(() => {
            onSearch(value.target.value);
        }, 1000);
    };

    const timeoutClear = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    useEffect(() => {
        if (itemTitle && itemTitle.itemGroup) {
            fetchMetadata(itemTitle.itemGroup.id);
        }
    }, [itemTitle]);

    const onTreeFilterCheck = (checkedKeys: any) => {
        setFilterItemGroups(checkedKeys);
    };

    const sortOnChange = (value: any) => {
        if (value) {
            setOrderType(value);
        }
    };

    const onChangeOrderView = (value: any, label: any) => {
        if (itemsBasketLength > 0) {
            Modal.warning({
                title: geti18nText('app.default.warning'),
                content: geti18nText('app.default.webshop.warning'),
                onOk() {
                    setOrderViewLabel(
                        label != undefined && label != null && label.label != null && label.label != undefined
                            ? label.label
                            : null
                    );
                    setItemsBasket([]);
                    setItemsBasketLength(0);
                    dispatch(setServicesOrderView(value > 0 ? value : null));
                    dispatch(setServicesNote(undefined));
                },
                okText: geti18nText('app.default.button.ok'),
                closable: true,
            });
        } else {
            setOrderViewLabel(
                label != undefined && label != null && label.label != null && label.label != undefined
                    ? label.label
                    : null
            );
            setItemsBasket([]);
            setItemsBasketLength(0);
            dispatch(setServicesOrderView(value > 0 ? value : null));
            dispatch(setServicesNote(undefined));
        }
    };

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} onKeyDown={onKeyDownTextAreaCustom} />;
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                    />
                );
            case 'BOOL':
                return <Checkbox />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} />;
            default:
                return <Input />;
        }
    };

    const addItemInBasket = (item: any) => {
        if (item != undefined && item.itemGroup != undefined) {
            setIsLoading(true);
            setItemTitle(item);
        }
    };

    const addToBasket = (validate: any) => {
        let itemsBasketCopy: any = [...itemsBasket];
        setTempId(tempId + 1);
        itemTitle.tempId = tempId;
        if (validate) {
            objectMetadataForm
                .validateFields()
                .then((metadataValues: any) => {
                    let metadataReturn = [];

                    for (const [key, value] of Object.entries(metadataValues)) {
                        const metadataItem: any = metadata.find(
                            (item: any) => item.objectMetadata && item.objectMetadata.name === key
                        );
                        if (metadataItem.objectMetadata.dataType === 4) {
                            metadataReturn.push({
                                item_group_metadata: { id: metadataItem.id },
                                value: getDateFormat(value, 'yyyy-MM-DD HH:mm:ss'),
                            });
                        } else {
                            metadataReturn.push({ item_group_metadata: { id: metadataItem.id }, value: value });
                        }
                    }

                    itemsBasketCopy.push({ ...itemTitle, servicesOrderItemMetadata: metadataReturn, quantity: 1 });
                    setBasketValues(itemsBasketCopy);
                    objectMetadataForm.resetFields();
                })
                .catch((errorInfo: any) => {
                    console.log('Validate Failed:', errorInfo);
                });
        } else {
            itemsBasketCopy.push({ ...itemTitle, quantity: 1 });
            setBasketValues(itemsBasketCopy);
        }
    };

    const setBasketValues = (itemsBasketCopy: any[]) => {
        setItemsBasket(itemsBasketCopy);
        setItemsBasketLength(itemsBasketLength + 1);
        setMetadataModalVisible(false);
        setMetadata([]);
        setItemTitle({});
    };

    const showItemQuantity = (item: any) => {
        let count = 0;
        itemsBasket.map((element: any) => {
            if (element.id == item.id) {
                count = count + 1;
            }
        });
        // const findItem = itemsBasket.find((basket: any) => basket.id == item.id);

        // if (findItem) {
        //     return findItem.quantity;
        // }

        return count;
    };

    return (
        <>
            <Row gutter={24}>
                <Col xxl={3} xl={4} lg={4} md={6} sm={24} xs={24}>
                    <div className={`ny-tree-div webshop-actions-div`}>
                        <Divider orientation="left" style={{ fontSize: '12px' }}>
                            {geti18nText('app.default.category')}
                        </Divider>
                        <Select
                            className={'webshop-actions-select-category'}
                            options={orderViewList}
                            value={orderViewLabel}
                            onChange={onChangeOrderView}
                        />
                        <Divider>
                            <FilterOutlined /> {geti18nText('app.default.filter')}
                        </Divider>
                        <Search
                            onSearch={onSearch}
                            onChange={onChange}
                            placeholder={geti18nText('app.default.search')}
                            allowClear
                            className={'webshop-actions-select'}
                        />
                        <Divider orientation="left" style={{ fontSize: '12px' }}>
                            {geti18nText('app.default.sort')}
                        </Divider>
                        <Select defaultValue="asc" className={'webshop-actions-select'} onChange={sortOnChange}>
                            <Select.Option value="asc">
                                <SortAscendingOutlined style={{ fontSize: '15px' }} />
                            </Select.Option>
                            <Select.Option value="desc">
                                <SortDescendingOutlined style={{ fontSize: '15px' }} />
                            </Select.Option>
                        </Select>
                        <Divider orientation="left" style={{ fontSize: '12px' }}>
                            {geti18nText('webshopItem.itemGroup.title')}
                        </Divider>
                        <Radio.Group
                            className="ny-webshop-radio-group"
                            options={[
                                { value: 'tree', label: geti18nText('webshopItem.itemGroup.tree') },
                                { value: 'folder', label: geti18nText('webshopItem.itemGroup.folder') },
                            ]}
                            onChange={(event: any) => {
                                setShowItemGroupFolder(event.target.value === 'folder' ? true : false);
                                setBreadcrumbData([]);
                                setFilterItemGroups([]);
                                fetchItemGroup();
                            }}
                            value={showItemGroupFolder ? 'folder' : 'tree'}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        {!showItemGroupFolder && (
                            <>
                                <Divider orientation="left" style={{ fontSize: '12px' }}>
                                    {geti18nText('webshopItem.divider.itemGroup')}
                                </Divider>
                                <Tree
                                    checkable
                                    onCheck={onTreeFilterCheck}
                                    treeData={itemGroups}
                                    fieldNames={{ title: 'name', key: 'id' }}
                                    style={{ width: '100%' }}
                                    checkedKeys={filterItemGroups}
                                />
                            </>
                        )}
                        <Divider orientation="left" style={{ fontSize: '12px' }}>
                            {geti18nText('report.catalog')}
                        </Divider>
                        <div style={{ padding: '5px' }}>
                            <NyReportButton
                                url={CONSTANTS_REQ.REPORT.ITEM_CATALOG}
                                subreportType={'ITEM_CATALOG'}
                                customParms={{
                                    itemType: 2,
                                    search: pdfDefaultFilter,
                                    order: 'name',
                                    orderType: orderType,
                                }}
                                buttoni18nText={'app.default.button.download.pdf'}
                                fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ITEM_CATALOG)}
                                showLink={true}
                                showTooltip={myPortal}
                                allowExportTypeChange={true}
                            />
                        </div>
                    </div>
                </Col>
                <Col xxl={21} xl={20} lg={20} md={18} sm={24} xs={24}>
                    <Row gutter={24}>
                        {showItemGroupFolder && (
                            <ItemGroupFolder
                                breadcrumbData={breadcrumbData}
                                setBreadcrumbData={setBreadcrumbData}
                                setFilterItemGroups={setFilterItemGroups}
                                loading={isMapLoading}
                                setLoading={setisMapLoading}
                                itemGroupsData={itemGroupsData}
                                setItemGroupsData={setItemGroupsData}
                                fetchItemGroup={fetchItemGroup}
                            />
                        )}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '50px' }}>
                            <Tag icon={<ExclamationCircleOutlined />} color="default">
                                {geti18nText('webshopItem.image.warning')}
                            </Tag>
                            <Divider orientation="left" style={{ fontSize: '12px' }}>
                                {geti18nText('webshopItem.item')}
                            </Divider>
                            <List
                                style={{ position: 'sticky', top: '0', marginBottom: loadMore ? '50px' : '' }}
                                grid={{
                                    gutter: 21,
                                    xs: 1,
                                    sm: 2,
                                    md: 3,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 8,
                                }}
                                loading={loading}
                                dataSource={items}
                                renderItem={(item: any) => (
                                    <List.Item>
                                        <Card
                                            hoverable
                                            style={{ width: '100%' }}
                                            cover={
                                                item.fileId ? (
                                                    <Image
                                                        style={{ objectFit: 'cover', border: '1px solid #f0f0f0' }}
                                                        height="150px"
                                                        src={
                                                            CONSTANTS_REQ.FILES.DOWNLOAD +
                                                            '/' +
                                                            item.fileId +
                                                            '?tenant=' +
                                                            NySession.getUser().tenantId
                                                        }
                                                    />
                                                ) : (
                                                    <Image
                                                        style={{ objectFit: 'cover', border: '1px solid #f0f0f0' }}
                                                        height="150px"
                                                        src="error"
                                                        fallback={FILE_FALLBACK}
                                                    />
                                                )
                                            }
                                            actions={[
                                                <Badge count={showItemQuantity(item)}>
                                                    <Button
                                                        icon={<PlusCircleOutlined />}
                                                        onClick={() => addItemInBasket(item)}
                                                        size="small"
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        {geti18nText('webshopItem.addToBasket')}
                                                    </Button>
                                                </Badge>,
                                            ]}
                                        >
                                            <Meta
                                                title={<ItemDetails item={item} addItemInBasket={addItemInBasket} />}
                                                description={item.code ? item.code : ''}
                                            />
                                        </Card>
                                    </List.Item>
                                )}
                            />
                            <Row gutter={24} style={{ position: 'absolute', bottom: '0', width: '100%' }}>
                                <Col span={11}> </Col>
                                <Col span={13}>
                                    <Button type="primary" onClick={() => onLoadMore()} hidden={!loadMore}>
                                        {geti18nText('app.default.load.more')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="buttons-sticky">
                <Row gutter={24}>
                    <Col span={3} style={{ textAlign: 'left' }}>
                        <Badge count={itemsBasketLength}>
                            <Button
                                type="primary"
                                icon={<ShoppingCartOutlined />}
                                style={{ textAlign: 'center', width: '100%' }}
                                onClick={() => setBasketVisible(true)}
                            >
                                {geti18nText('webshopItem.basket')}
                            </Button>
                        </Badge>
                    </Col>
                    <Col span={21}>
                        <span>
                            {geti18nText('app.default.total')} {shown} {geti18nText('app.default.of')} {total}{' '}
                            {geti18nText('app.default.records')}
                        </span>
                    </Col>
                </Row>
            </div>
            {React.createElement(
                WebshopServicesEdit,
                {
                    isModal: true,
                    visible: basketVisible,
                    setVisible: setBasketVisible,
                    value: undefined,
                    onSave: () => {
                        setItemsBasket([]);
                        setItemsBasketLength(0);
                        setBasketVisible(false);
                    },
                    onSaveAndGetID: () => {
                        setItemsBasket([]);
                        setItemsBasketLength(0);
                        setBasketVisible(false);
                    },
                    onSaveAndGetData: () => {
                        setItemsBasket([]);
                        setItemsBasketLength(0);
                        setBasketVisible(false);
                    },
                    orderViewId: webshopServicesOrderView,
                    addedData: undefined,
                    items: itemsBasket,
                    setItems: setItemsBasket,
                    setItemsLength: setItemsBasketLength,
                    employeeId: cartEmployeeId,
                    setEmployeeId: setCartEmployeeId,
                    setTempId: setTempId,
                },
                null
            )}
            {metadataModalVisible && (
                <Modal
                    title={itemTitle && itemTitle.name}
                    okText={geti18nText('webshopItem.addToBasket')}
                    visible={metadataModalVisible}
                    maskClosable={false}
                    onOk={() => addToBasket(true)}
                    onCancel={() => {
                        setMetadataModalVisible(false);
                        setItemTitle({});
                    }}
                >
                    <Form layout="vertical" form={objectMetadataForm}>
                        <Row gutter={24}>
                            {metadata &&
                                metadata.length >= 1 &&
                                metadata.map((item: any) => {
                                    return (
                                        <Col span={24}>
                                            <Form.Item
                                                label={item.objectMetadata.name}
                                                name={item.objectMetadata.name}
                                                rules={[
                                                    {
                                                        required: item.objectMetadata.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                                valuePropName={item.objectMetadata.dataType === 3 ? 'checked' : 'value'}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            {(!metadata || metadata.length === 0) && (
                                <Empty style={{ margin: 'auto' }} description={geti18nText('objectMetadata.empty')} />
                            )}
                        </Row>
                    </Form>
                </Modal>
            )}
            {isLoading && (
                <div>
                    <div className="ant-modal-mask"></div>
                    <div
                        style={{
                            zIndex: '1000',
                            position: 'fixed',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                        }}
                    >
                        <NySpinner size="large" />
                    </div>
                </div>
            )}
        </>
    );
};

export default WebshopServicesIndex;
