import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArray, getEnumBooleanArray, setEnumFormat } from '../../../../utils/Utils';
import TravelExpensePaymentMethodEdit from './edit';

const TravelExpensePaymentMethodIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    defaultFilterValue,
}: any) => {
    const table = useTableSettings();
    useHelper('travel_order/codebooks/travelPaymentMethod');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canExportCSV = () => {
        return TravelWarrantRights.canCsvExportTravelWarrantCodebooks();
    };

    const setDefaultFilterValue = () => {
        if (defaultFilterValue) {
            return defaultFilterValue;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns = [
        {
            title: geti18nText('travelPaymentMethod.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelPaymentMethod.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelPaymentMethod.table.column.type'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.TRAVEL_EXPENSE_PAYMENT_METHOD.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('TRAVEL_EXPENSE_PAYMENT_METHOD'), 'in', undefined),
        },
        {
            title: geti18nText('travelPaymentMethod.table.column.reimbursed'),
            dataIndex: 'reimbursed',
            width: '12%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { reimbursed: any }) => {
                if (record.reimbursed) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('travelPaymentMethod.table.column.bookExpense'),
            dataIndex: 'bookExpense',
            width: '12%',
            render: (text: any, record: any) => {
                if (record.bookExpense !== undefined) {
                    return geti18nText('app.enum.BOOK_OPTIONS.' + record.bookExpense);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArray('BOOK_OPTIONS')),
        },
        {
            title: geti18nText('travelPaymentMethod.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-travel-expense-payment-method"
            url={CONSTANTS_REQ.TRAVEL_EXPENSE_PAYMENT_METHOD.LIST}
            addNewButtonText={geti18nText('travelPaymentMethod.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={TravelExpensePaymentMethodEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            nyTestId="travel-expense-payment-method-table"
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.travelPaymentMethod')}
            colCSVCustomization={csvColumnCustomisation()}
            readonly={readonly}
            hideButtons={readonly}
            headerTitle={geti18nText('menu.travelPaymentMethod')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            reimbursed: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            type: (value: any) => {
                let type: any = setEnumFormat('TRAVEL_EXPENSE_PAYMENT_METHOD', value);
                return type !== undefined && type.text !== undefined && type.text;
            },
        },
        {
            bookExpense: (value: any) => {
                return value != undefined && geti18nText('app.enum.BOOK_OPTIONS.' + value);
            },
        },
    ];
};

export default TravelExpensePaymentMethodIndex;
