import { AppstoreOutlined, EditOutlined, FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { NyDatePicker, NySearchField, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Col, Divider, Dropdown, Form, Input, Menu, Modal, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption, fileDownload, onKeyDownTextAreaCustom } from '../../../../../utils/Utils';
import NyImageUpload from '../../../../../utils/ny-file-upload-image';
import EmployeeIndex from '../../employee';
import ContractGlobalConstantsIndex from '../contract-global-constants';
import TextArea from 'antd/lib/input/TextArea';

const GeneralEditData = ({
    form,
    canCreate,
    files,
    setFiles,
    contractType,
    partyTwo,
    validFrom,
    setValidFrom,
    validTo,
    setValidTo,
    dateOfConclusion,
    setDateOfConclusion,
    setNewTemplate,
    employeeOnChange,
    contractId,
    employeeId = undefined,
}: any) => {
    const focusInput = useRef<any>(null);
    const enContractPartyTwo = useEnum('CONTRACT_PARTY_TWO');
    const [visible, setVisible] = useState<boolean>(false);
    const [globalConstantsModalVisible, setGlobalConstantsModalVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>(0);

    const iconStyle = {
        cursor: 'pointer',
        fontSize: '22px',
        height: '100%',
        display: 'inline-block',
        marginTop: '20px',
    };

    const iconStyleDropdown: any = {
        fontSize: '16px',
        paddingRight: '10px',
        paddingTop: '2px',
        float: 'left',
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    const onEndDateChange = (value: any) => {
        setValidTo(value ? moment(value) : moment());
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setValidFrom(value ? moment(value) : moment());
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    return (
        <>
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="status" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="metadataVersion" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={contractId ? 22 : 23}>
                    <Form.Item
                        label={geti18nText('contract.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                {contractId && (
                    <Col span={1}>
                        <div style={iconStyle}>
                            <Tooltip title={geti18nText('menu.contract.global.constants')}>
                                <AppstoreOutlined
                                    className={`las la-file-archive statistic-card-light`}
                                    onClick={() => setGlobalConstantsModalVisible(true)}
                                />
                            </Tooltip>
                        </div>
                    </Col>
                )}
                <Col span={1}>
                    <Space size="middle">
                        <Dropdown
                            overlay={
                                <Menu>
                                    <div style={{ padding: '0px', textAlign: 'left' }}>
                                        {files[0]?.id && (
                                            <div style={{ display: 'block', margin: '5px' }}>
                                                <Button
                                                    icon={<FilePdfOutlined style={iconStyleDropdown} />}
                                                    style={{ width: '100%' }}
                                                    onClick={() => {
                                                        fileDownload(
                                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + files[0]?.id,
                                                            null,
                                                            files[0]?.name ?? geti18nText('contract.edit.template')
                                                        );
                                                    }}
                                                >
                                                    {geti18nText('app.default.download')}
                                                </Button>
                                            </div>
                                        )}
                                        {canCreate && (
                                            <div style={{ display: 'block', margin: '5px' }}>
                                                <Button
                                                    icon={<EditOutlined style={iconStyleDropdown} />}
                                                    style={{ width: '100%' }}
                                                    onClick={() => setVisible(true)}
                                                >
                                                    {geti18nText('app.default.button.edit')}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Menu>
                            }
                        >
                            <div style={iconStyle}>
                                <Tooltip title={geti18nText('contract.type.edit.documentTemplate')}>
                                    <FileTextOutlined className={`las la-file-archive statistic-card-light`} />
                                </Tooltip>
                            </div>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('contract.edit.contractNumber')}
                        name="contractNumber"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('contract.edit.dateOfConclusion')}
                        name="dateOfConclusion"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            defaultPickerValue={dateOfConclusion}
                            onChange={(value: any) => {
                                setDateOfConclusion(value ? moment(value) : moment());
                            }}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('contract.edit.validFrom')}
                        name="validFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onStartDateChange}
                            disabledDate={disabledStartDate}
                            defaultPickerValue={validFrom}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('contract.edit.validTo')} name="validTo">
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onEndDateChange}
                            disabledDate={disabledendDate}
                            defaultPickerValue={validTo}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {partyTwo && partyTwo == enContractPartyTwo.EMPLOYEE && (
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('contract.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: partyTwo == enContractPartyTwo.EMPLOYEE,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                setDefaultFilterValue={setDefaultFilterValue}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="contractsCTR" />}
                                order="person.last_name, person.first_name"
                                onChange={employeeOnChange}
                                mustGetPopupContainer={false}
                                disabled={employeeId}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col span={partyTwo && partyTwo == enContractPartyTwo.EMPLOYEE ? 12 : 24}>
                    <Form.Item name="remark" label={geti18nText('contract.edit.remark')}>
                        <TextArea rows={3} allowClear={false} onKeyDown={onKeyDownTextAreaCustom} />
                    </Form.Item>
                </Col>
            </Row>
            {visible && (
                <Modal
                    open={visible}
                    title={geti18nText('contract.type.edit.documentTemplate')}
                    onOk={(event: any) => {
                        setVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    width={1200}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    {files.length < 1 && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Alert
                                    message={
                                        'Kao predložak ovog ugovora se koristi predložak iz odabrane vrste ugovora'
                                    }
                                    showIcon
                                    type="info"
                                />
                            </Col>
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Col span={24}>
                            <NyImageUpload
                                files={files}
                                setFiles={setFiles}
                                saveOnUpload={true}
                                accept={'.docx'}
                                cropShape={null}
                                listType={'text'}
                                maxImage={1}
                                showHistoryId={contractType?.id}
                                isContractTypeView
                                canEditFile
                                setNewTemplate={setNewTemplate}
                            />
                        </Col>
                    </Row>
                </Modal>
            )}
            <Modal
                open={globalConstantsModalVisible}
                title={geti18nText('menu.contract.global.constants')}
                onOk={(event: any) => {
                    setGlobalConstantsModalVisible(false);
                    event.stopPropagation();
                    event.preventDefault();
                }}
                onCancel={() => {
                    setGlobalConstantsModalVisible(false);
                }}
                width={1200}
                forceRender={true}
                closable={true}
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={geti18nText('app.default.button.ok')}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <ContractGlobalConstantsIndex
                            defaultFilterValue={[
                                { field: 'active', condition: 'equals_bool', value: 1 },
                                { field: 'contract_column', condition: 'is_null', value: '' },
                                { field: 'contract_id', condition: 'equals', value: contractId },
                            ]}
                            contractId={contractId}
                            showHeader={false}
                            showAlert
                            showDates={false}
                            button2
                            url={CONSTANTS_REQ.CONTRACT_GLOBAL_CONSTANTS.VALID_TODAY_LIST}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            showTabs={false}
                            activeTab="contract_column_null"
                        />
                    </Col>
                </Row>
                <Divider>{geti18nText('menu.contract.global.constants')}</Divider>
                <Row gutter={24}>
                    <Col span={24}>
                        <ContractGlobalConstantsIndex
                            showHeader={false}
                            showDates={false}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            showTabs={false}
                            activeTab="contract_column_null"
                            showNewButton={false}
                            alertMessage={geti18nText('contract.global.constants.header.all')}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default GeneralEditData;
