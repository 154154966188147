import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NySpinner,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, InputNumber, List, Slider, Tag } from 'antd';
import { useEffect, useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, okNotification } from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EmployeeSeniorityAnnualIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
}: any) => {
    const [listData, setListData] = useState<any>(undefined);
    const [changedListData, setChangedListData] = useState<any>(undefined);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState<any>(1);
    const table = useTableSettings();
    useHelper('human/codebooks/employee_simple');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    useEffect(() => {
        listData && changedListData && generateList();
    }, [listData]);

    useEffect(() => {
        getSeniorityLeaveRangeData(true);
    }, []);

    useEffect(() => {
        getSeniorityLeaveRangeData(true);
    }, [refresh]);

    const setDefaultFilterValueMyList = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const generateList = () => {
        let resultList;

        resultList = (
            <List
                style={{ marginTop: '10px', marginBottom: '20px' }}
                itemLayout="horizontal"
                dataSource={changedListData}
                header={
                    <div className={'seniority-header'}>
                        <div style={{ marginLeft: '12px', marginRight: '12px' }}>
                            {geti18nText('seniority.table.days')}
                        </div>{' '}
                        <div style={{ marginLeft: '48px', marginRight: '12px' }}>
                            {geti18nText('seniority.table.range.add')}
                        </div>
                    </div>
                }
                renderItem={(record: any) => (
                    <List.Item
                        actions={[
                            <div style={{ width: '10%', marginRight: '3rem' }}>
                                <NyModalConfirm
                                    title={geti18nText('app.default.destructive.confirm')}
                                    onConfirm={() => {
                                        const newList = listData.filter((item: any) => {
                                            return record.id == item.id;
                                        });

                                        deactivateRecord(record);
                                    }}
                                >
                                    <Button danger key="list-loadmore-edit">
                                        {geti18nText('app.default.button.delete')}
                                    </Button>
                                </NyModalConfirm>
                            </div>,
                        ]}
                    >
                        <InputNumber
                            disabled={!canCreate()}
                            style={{ marginLeft: '2rem', width: '3rem', marginRight: '1rem' }}
                            min={0}
                            max={10}
                            value={record.days}
                            onStep={(e) => {
                                updateRecordValues(record, e);
                            }}
                            onBlur={(e: any) => {
                                checkDays(record, e.target.value);
                            }}
                        />

                        <div style={{ width: '80%' }}>
                            <Slider
                                disabled={!canCreate()}
                                key={record.id}
                                id={record.id}
                                handleStyle={[
                                    { background: 'rgb(255 89 0)', borderColor: 'rgb(253 137 74)' },
                                    { background: 'rgb(255 89 0)', borderColor: 'rgb(253 137 74)' },
                                ]}
                                value={[record.yearFrom, record.yearTo]}
                                onChange={(e: any) => {
                                    const changedValue = {
                                        id: record.id,
                                        yearFrom: e.at(0),
                                        yearTo: e.at(1),
                                        days: record.days,
                                    };
                                    const newList = listData.map((item: any) => {
                                        return record.id != item.id ? item : changedValue;
                                    });
                                    setChangedListData(newList);
                                }}
                                onAfterChange={(e: any) => {
                                    checkDiffernce(e, record.yearFrom, record.yearTo, record);
                                }}
                                trackStyle={[{ backgroundColor: 'rgb(247 123 18)' }]}
                                className="years-range"
                                range={{ draggableTrack: true }}
                                marks={marks}
                                min={0}
                                max={50}
                            />
                        </div>
                    </List.Item>
                )}
            />
        );

        return resultList;
    };

    const getSeniorityLeaveRangeData = (initial?: any) => {
        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValueMyList()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_SENIOIRITY.LIST, {
            search: stringUri,
            max: 100,
            order: 'yearTo',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    initial && setChangedListData(result.data.content);
                    setListData(result.data.content);
                }
            }
            setLoading(false);
        });
    };

    const deactivateRecord = (record: any) => {
        record.active = false;
        NyRequestResolver.requestPut(CONSTANTS_REQ.EMPLOYEE_SENIOIRITY.EDIT + '/' + record.id, undefined, record).then(
            (result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    setRefresh(refresh + 1);
                } else {
                    setRefresh(refresh + 1);
                    if (result.data && result.data.error) {
                        errorNotification(geti18nText(result.data.error));
                    } else {
                        errorNotification();
                    }
                }
            }
        );
    };

    const createRecord = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_SENIOIRITY.EDIT, undefined, {
            id: null,
            days: 1,
            yearFrom: 0,
            yearTo: 0,
        }).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification();
                setRefresh(refresh + 1);
            } else {
                setRefresh(refresh + 1);
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const checkDiffernce = (eventValues?: any, yearFrom?: any, yearTo?: any, record?: any) => {
        if (
            eventValues.at(0) != listData.find((item: any) => item.id === record.id).yearTo ||
            eventValues.at(1) != listData.find((item: any) => item.id === record.id).yearFrom
        ) {
            record.yearFrom = eventValues.at(0);
            record.yearTo = eventValues.at(1);
            updateRecordValues(record);
        }
    };

    const checkDays = (record?: any, days?: any) => {
        if (days != record.days) {
            record.days = days;

            updateRecordValues(record, days);
        }
    };

    const updateRecordValues = (record: any, days?: any) => {
        if (days) {
            record.days = days;
        }

        NyRequestResolver.requestPut(CONSTANTS_REQ.EMPLOYEE_SENIOIRITY.EDIT + '/' + record.id, undefined, record).then(
            (result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    setRefresh(refresh + 1);
                } else {
                    setRefresh(refresh + 1);
                    if (result.data && result.data.error) {
                        setChangedListData(listData);
                        errorNotification(geti18nText(result.data.error));
                    } else {
                        setChangedListData(listData);
                        errorNotification();
                    }
                }
            }
        );
    };

    const marks = {
        0: '0',
        5: {
            style: {
                fontSize: '10px',
                marginTop: '2px',
            },
            label: 5,
        },
        10: '10',
        15: {
            style: {
                fontSize: '10px',
                marginTop: '2px',
            },
            label: 15,
        },
        20: '20',
        25: {
            style: {
                fontSize: '10px',
                marginTop: '2px',
            },
            label: 25,
        },
        30: '30',
        35: {
            style: {
                fontSize: '10px',
                marginTop: '2px',
            },
            label: 35,
        },
        40: '40',
        45: {
            style: {
                fontSize: '10px',
                marginTop: '2px',
            },
            label: 45,
        },
        50: '50',
    };

    const columns = [
        {
            title: geti18nText('seniority.table.days'),
            width: '5%',
            dataIndex: ['days'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (record: any) => {
                if (record) {
                    return (
                        <Tag color="blue" style={{ marginLeft: '2rem', marginRight: '1rem' }}>
                            {record}
                        </Tag>
                    );
                }
            },
        },
        {
            title: geti18nText('seniority.table.range.add'),
            width: '80%',
            dataIndex: ['yearTo'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ marginLeft: '1rem', marginRight: '2rem' }}>
                            <Slider
                                key={record.id}
                                id={record.id}
                                value={[record.yearFrom, record.yearTo]}
                                handleStyle={[{ background: 'rgb(255 89 0)' }, { background: 'rgb(255 89 0)' }]}
                                className="years-range"
                                range={{ draggableTrack: true }}
                                disabled={true}
                                marks={marks}
                                min={0}
                                max={50}
                            />
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('seniority.table.range.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <>
            {!loading ? generateList() : <NySpinner />}
            <div className="buttons-sticky">
                <Button
                    type="primary"
                    disabled={!canCreate()}
                    style={{ float: 'right', marginRight: '12px' }}
                    onClick={createRecord}
                >
                    {geti18nText('app.default.button.add')}
                </Button>
            </div>
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeSeniorityAnnualIndex;
