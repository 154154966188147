import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import ItemGroupIndex from '.';
import ItemGroupEdit from './edit';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const ItemGroupSearch = ({
    label = geti18nText('inventoryRelease.report.itemGroup'),
    name = 'itemGroup',
    required = false,
    setDefaultFilterValue = null,
    disabled = false,
    type = null,
    AddNewModalComponent = false,
    AddNewModalComponentAddedData = null,
    onChange = null,
    mode = 'default',
    addedData = null,
    mustGetPopupContainer = true,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.ITEM_GROUP.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                itemName="name"
                setDefaultFilterValue={setDefaultFilterValue}
                SearchPopupComponent={<ItemGroupIndex disabled={disabled} type={type} search />}
                AddNewModalComponent={AddNewModalComponent ? ItemGroupEdit : null}
                AddNewModalComponentAddedData={AddNewModalComponentAddedData}
                onChange={onChange}
                addedData={addedData}
                mode={mode}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default ItemGroupSearch;
