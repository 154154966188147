import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import PayrollTaxReductionEdit from './edit';
import { useLocation } from 'react-router';
import useTableSettings from '../../../../hooks/useTableSettings';
import { getDateFormat, numberFormat } from '../../../../utils/Utils';
import useHelper from '../../../../hooks/useHelper';
import moment from 'moment';
import { PayrollRights } from '../../../../rights/payrollRights';

const PayrollTaxReductionIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('payroll/codebooks/payroll_tax_reduction');

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    const columns = [
        {
            title: geti18nText('payrollTaxReduction.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payrollTaxReduction.table.column.name'),
            dataIndex: 'name',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payrollTaxReduction.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payrollTaxReduction.table.column.percent'),
            dataIndex: 'percent',
            sorter: (a: any, b: any) => {},
            render: (percent: any) => {
                return numberFormat(percent);
            },
            ...getColumnSearch('number'),
        },

        {
            title: geti18nText('payrollTaxReduction.table.column.validFrom'),
            dataIndex: 'validFrom',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return getDateFormat(record.validFrom, 'DD.MM.yyyy');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('payrollTaxReduction.table.column.validTo'),
            dataIndex: 'validTo',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return getDateFormat(record.validTo, 'DD.MM.yyyy');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('payrollTaxReduction.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.created) {
                    return moment(record.created).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },

        {
            title: geti18nText('payrollTaxReduction.table.column.updated'),
            dataIndex: 'updated',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record && record.updated) {
                    return moment(record.updated).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
    ];

    return (
        <NyDataTable
            nyId="human-payroll-tax-reduction-table"
            url={CONSTANTS_REQ.PAYROLL_TAX_REDUCTION.LIST}
            addNewButtonText={geti18nText('payrollTaxReduction.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={PayrollTaxReductionEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            nyTestId="payroll-tax-table"
            hideNewButton={!canCreate()}
            headerTitle={geti18nText('menu.payrollTaxReduction')}
        />
    );
};

export default PayrollTaxReductionIndex;
