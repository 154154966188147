import { geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { Form } from 'antd';

const TaskTemplateSearch = ({
    label = undefined,
    name = 'selectedTaskTemplate',
    placeholder = undefined,
    required = false,
    searchBy = 'name, description, code',
    itemName = [
        ['task_template', 'name'],
        ['task_template', 'code'],
    ],
    map = {
        id: 'id',
        name: 'name',
        code: 'code',
        description: 'description',
        fileId: 'fileId',
    },
    customItemNameLabel = 'name code description fileId',
    onChange,
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    renderOption,
    mode = 'default',
    style,
    disabled = false,
    addedData,
    className = 'ny-search-field-select',
    formItemStyle,
    offsetToFetchData = false,
    bordered = true,
    size,
    isFormItem = true,
    mustGetPopupContainer = true,
}: any) => {
    const getFormItem = () => (
        <NySearchField
            url={CONSTANTS_REQ.TASK_TEMPLATE.SEARCH}
            map={map}
            searchBy={searchBy}
            itemName={itemName}
            renderOption={renderOption}
            customItemNameLabel={customItemNameLabel}
            setDefaultFilterValue={setDefaultFilterValue}
            placeholder={placeholder}
            style={style}
            onChange={onChange}
            mode={mode}
            size={size}
            addedData={addedData}
            className={className}
            disabled={disabled}
            bordered={bordered}
            offsetToFetchData={offsetToFetchData}
            mustGetPopupContainer={mustGetPopupContainer}
        />
    );
    return (
        <>
            {isFormItem ? (
                <Form.Item
                    style={formItemStyle}
                    label={label}
                    name={name}
                    rules={[
                        {
                            required: required,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {getFormItem()}
                </Form.Item>
            ) : (
                getFormItem()
            )}
        </>
    );
};

export default TaskTemplateSearch;
