import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
// import { addTab } from '../../../../../slices/tabsSlice';
import { TravelWarrantRights } from '../../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { GetEnumValueForName } from '../../../../../utils/Enums';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import {
    errorNotification,
    getEnumBooleanArray,
    getOrdinal,
    getWageErrorsMessages,
    numberFormat,
    okNotification,
    userIsNotificationRecipient,
} from '../../../../../utils/Utils';
import useEnum from '../../../../../hooks/useEnum';

const TravelWarrantTableCancelRequests = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    canViewWageErrors = false,
}: any) => {
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const history = useHistory();
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);
    const { employee } = useSelector((state: RootState) => state.employee);

    const confirmCancelation = (recordId: any, note: any, organizationConfirmed?: any, advanceConfirmed?: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CANCELATION_EDIT + '/' + recordId, undefined, {
            id: recordId,
            note: { note: note },
            organizationConfirmed: organizationConfirmed,
            advanceConfirmed: advanceConfirmed,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) {
                okNotification();
                setRefreshTable((prevValue) => prevValue + 1);
            } else {
                errorNotification();
            }
        });
    };

    const onRowSelect = (record: any) => {
        history.push('/travel-warrant/travelWarrant/' + record.travelWarrant.id);
    };

    const initialColumns = [
        {
            title: geti18nText('travelWarrant.table.column.ordinal'),
            dataIndex: ['travelWarrant', 'ordinal'],
            width: '110px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <>
                            <Tooltip title={geti18nText('menu.travel.warrant.details')}>
                                <Button
                                    type="link"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => onRowSelect(record)}
                                >
                                    {getOrdinal(record.travelWarrant)}
                                </Button>
                            </Tooltip>
                            {getWageErrorsMessages(
                                record?.wageErrors,
                                canViewWageErrors && record?.status != travelWarrantStatusEnum.PAID
                            )}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (person: any, record: any) => {
                if (record) {
                    if (
                        record.travelWarrant &&
                        record.travelWarrant.employee &&
                        record.travelWarrant.employee.employmentRecordId
                    ) {
                        return (
                            record.travelWarrant.employee.person.firstName +
                            ' ' +
                            record.travelWarrant.employee.person.lastName +
                            ' (' +
                            record.travelWarrant.employee.employmentRecordId +
                            ')'
                        );
                    } else {
                        return (
                            record.travelWarrant.employee.person.firstName +
                            ' ' +
                            record.travelWarrant.employee.person.lastName
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.advanceRequested'),
            dataIndex: 'advanceRequested',
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            // sorter: (a: any, b: any) => {},
            render: (text: any, record: { advanceRequested: any }) => {
                if (record.advanceRequested == true) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('travelWarrantWizard.table.column.advancePaymentInCurrency'),
            dataIndex: ['travelWarrant', 'advancePaymentInCurrency'],
            align: 'right',
            sorter: (a: any, b: any) => {},
            render: (text: any, advancePaymentInCurrency: any) => {
                if (advancePaymentInCurrency) {
                    if (
                        advancePaymentInCurrency.travelWarrant.advancePaymentCurrency &&
                        advancePaymentInCurrency.travelWarrant.advancePaymentCurrency?.isoCode
                    ) {
                        return (
                            <div style={{ float: 'right' }}>
                                {advancePaymentInCurrency.travelWarrant.advancePaymentInCurrency
                                    ? numberFormat(advancePaymentInCurrency.travelWarrant.advancePaymentInCurrency) +
                                      ' (' +
                                      advancePaymentInCurrency.travelWarrant.advancePaymentCurrency?.isoCode +
                                      ')'
                                    : ''}
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ float: 'right' }}>
                                {advancePaymentInCurrency.travelWarrant.advancePaymentInCurrency
                                    ? numberFormat(advancePaymentInCurrency.travelWarrant.advancePaymentInCurrency)
                                    : ''}
                            </div>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.advanceConfirmed'),
            dataIndex: 'advanceConfirmed',
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            // sorter: (a: any, b: any) => {},
            render: (text: any, record: { advanceConfirmed: any }) => {
                if (record.advanceConfirmed) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.organizationRequested'),
            dataIndex: 'organizationRequested',
            // sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            render: (text: any, record: { organizationRequested: any }) => {
                if (record.organizationRequested) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.organizationConfirmed'),
            dataIndex: 'organizationConfirmed',
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            // sorter: (a: any, b: any) => {},
            render: (text: any, record: { organizationConfirmed: any }) => {
                if (record.organizationConfirmed) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    if (
                        (record.advanceRequested &&
                            !record.advanceConfirmed &&
                            (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                                userIsNotificationRecipient(
                                    employee,
                                    GetEnumValueForName({
                                        enumName: 'NOTIFICATION_TYPE',
                                        name: 'TRAVEL_WARRANT_PAYMENT',
                                    })
                                ))) ||
                        (record.organizationRequested &&
                            !record.organizationConfirmed &&
                            (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                                userIsNotificationRecipient(
                                    employee,
                                    GetEnumValueForName({
                                        enumName: 'NOTIFICATION_TYPE',
                                        name: 'TRAVEL_WARRANT_ORGANISATION',
                                    })
                                )))
                    ) {
                        return (
                            <RowAction
                                component={'TravelWarrantCancelRequest'}
                                approveTravelWarrant={true}
                                cancelTravelWarrantAction={true}
                                record={record}
                                printTravelWarrant={true}
                                changeStatus={confirmCancelation}
                            ></RowAction>
                        );
                    }
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="travel-warrant-cancelation-table"
            url={CONSTANTS_REQ.TRAVEL_WARRANT.CANCELATION_LIST}
            onRowSelect={onRowSelect}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            columns={initialColumns}
            setDefaultSortOrder={sortOrder}
            scroll={scroll}
            setDefaultPageSize={table.setDefaultPageSize()}
            modalComponent={undefined}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideNewButton={true}
            fetchWhenChange={refreshTable}
        />
    );
};

export default TravelWarrantTableCancelRequests;
