import { useState } from 'react';
import { Col, Row, Modal, Form } from 'antd';
import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import PaymentOrdersGroupIndex from '../payment-orders-group';
import PaymentOrdersGroupEdit from '../payment-orders-group/edit';
import { errorNotification, getDateFormat, okNotification } from '../../../../../utils/Utils';
import PaymentOrdersInitializationIndex from '../payment-orders-initialization';
import PaymentOrdersInitializationEdit from '../payment-orders-initialization/edit';

const AddToBulkModal = ({ onSave, modalVisible, setModalVisible, selectedValues, clearKeys }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState<any>(undefined);

    const defaultFilterValue = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'batch_booking', condition: 'equals_bool', value: 0 },
        { field: 'payment_method', condition: 'equals', value: 1 },
    ];

    const bulkDefaultFilterValue = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'status', condition: 'equals_float', value: 1 },
    ];

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                values.groupId = values.groupId.id;
                values.paymentOrders = selectedValues;

                let bulkData: any = {};
                bulkData.paymentOrderBulkId = values.paymentOrderBulkId.id;
                bulkData.paymentGroups = [values.groupId];
                delete values.paymentOrderBulkId;

                NyRequestResolver.requestPost(CONSTANTS_REQ.PAYMENT_ORDER.ADD_TO_GROUP, undefined, values).then(
                    (result: any) => {
                        if (result && result.status === RESPONSE.CREATED) {
                            NyRequestResolver.requestPost(
                                CONSTANTS_REQ.PAYMENT_ORDER_GROUP.ADD_TO_BULK,
                                undefined,
                                bulkData
                            ).then((result: any) => {
                                if (setLoading) setLoading(false);
                                if (result && result.status === RESPONSE.CREATED) {
                                    okNotification();
                                    setModalVisible(false);
                                    clearKeys();
                                    onSave();
                                    form.resetFields();
                                } else {
                                    if (result.data && result.data.error) {
                                        if (geti18nText(result.data.error) != '') {
                                            errorNotification(geti18nText(result.data.error));
                                        } else {
                                            errorNotification(JSON.stringify(result.data.error));
                                        }
                                    } else {
                                        errorNotification();
                                    }
                                }
                            });
                        } else {
                            if (setLoading) setLoading(false);
                            errorNotification();
                        }
                    }
                );
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    const groupOnChange = (value: any) => {
        setGroup(value.id > 0 ? value : undefined);
        if (value.id < 0) {
            form.setFieldsValue({ groupId: undefined });
            form.setFieldsValue({ paymentOrderBulkId: undefined });
        }
    };

    const customRenderOption = (option: any) => (
        <>
            <span style={{ display: 'block' }}>{option.text}</span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>
                {geti18nText('payment.orders.group.table.column.requestedExecutionDate')}:{' '}
                {getDateFormat(option.requestedExecutionDate, 'DD.MM.yyyy')}
            </span>
        </>
    );

    const customBulkRenderOption = (option: any) => (
        <>
            <span>
                {option.text}
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>[{option.messageId}]</span>
            </span>
        </>
    );

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('payment.orders.individual.AddToBulk.title')}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(e) => save(e)}
                    onCancel={() => {
                        form.resetFields();
                        setModalVisible(false);
                    }}
                    confirmLoading={loading}
                    width={600}
                    maskClosable={false}
                >
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.orders.individual.AddToBulk.group')}
                                    name={'groupId'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.PAYMENT_ORDER_GROUP.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'paymentInformationId',
                                            requestedExecutionDate: 'requestedExecutionDate',
                                        }}
                                        searchBy="paymentInformationId"
                                        autoFocus={true}
                                        SearchPopupComponent={
                                            <PaymentOrdersGroupIndex defaultFilterValue={defaultFilterValue} />
                                        }
                                        itemName={'paymentInformationId'}
                                        renderOption={customRenderOption}
                                        AddNewModalComponent={PaymentOrdersGroupEdit}
                                        nyTestId="input-group"
                                        setDefaultFilterValue={() => defaultFilterValue}
                                        onChange={groupOnChange}
                                        order="paymentInformationId"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('payment.orders.individual.AddToBulk.bulk')}
                                    name={'paymentOrderBulkId'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.PAYMENT_ORDER_INITIALIZATION.SEARCH}
                                        map={{ id: 'id', label: 'name', messageId: 'messageId' }}
                                        searchBy="name"
                                        SearchPopupComponent={
                                            <PaymentOrdersInitializationIndex
                                                defaultFilterValue={bulkDefaultFilterValue}
                                            />
                                        }
                                        itemName={'name'}
                                        renderOption={customBulkRenderOption}
                                        AddNewModalComponent={PaymentOrdersInitializationEdit}
                                        nyTestId="input-group"
                                        setDefaultFilterValue={() => bulkDefaultFilterValue}
                                        disabled={group ? false : true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AddToBulkModal;
