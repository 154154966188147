import { NyDataEdit, NyDatePicker, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat, getEnumArray, setDateFormat, setEnumFormat, setSearchFormat } from '../../../../../utils/Utils';

const ContractGlobalConstantsEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [editHeader, setEditHeader] = useState(geti18nText('contract.global.constants.edit.new'));

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('contract.global.constants.edit.title'));

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.contractCategory) {
            dataForm.contractCategory = setSearchFormat(dataForm.contractCategory, 'contractCategory');
        }

        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
        }

        if (dataForm.contractColumn) {
            dataForm.contractColumn = setEnumFormat('CONTRACT_CONSTANTS_CONTRACT_COLUMN', dataForm.contractColumn);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onStartDateChange = (value: any) => {
        if (form.getFieldValue('validTo')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validTo').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
    };

    const onEndDateChange = (value: any) => {
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('validTo')) {
            return current > moment(form.getFieldValue('validTo')).endOf('day');
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CONTRACT_GLOBAL_CONSTANTS.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                props?.editProps?.setRefresh2(props?.editProps?.refresh + 1);
                if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
                if (values.validTo) values.validTo = getDateFormat(values.validTo);
                if (props?.editProps?.contractId) {
                    values.contract = { id: props?.editProps?.contractId };
                }
                if (values.contractColumn) values.contractColumn = values?.contractColumn?.id;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {props?.editProps?.activeTab === 'contract_column_null' ? (
                        <Form.Item
                            label={geti18nText('contract.global.constants.edit.name')}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label={geti18nText('contract.global.constants.table.column.contractDocument')}
                            name="contractColumn"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                options={getEnumArray('CONTRACT_CONSTANTS_CONTRACT_COLUMN')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="id"
                                className=""
                                allowClear={false}
                                autoFocus
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('contract.global.constants.edit.documentPlaceholder')}
                        name="documentPlaceholder"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            {props?.editProps?.activeTab === 'contract_column_null' && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('contract.global.constants.edit.value')}
                            name="value"
                            rules={[
                                {
                                    required: false,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('contract.category.rights.edit.validFrom')} name="validFrom">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                nyTestId="validFrom"
                                onChange={onStartDateChange}
                                disabledDate={disabledStartDate}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('contract.category.rights.edit.validTo')} name="validTo">
                            <NyDatePicker
                                style={{ width: '100%' }}
                                nyTestId="validTo"
                                onChange={onEndDateChange}
                                disabledDate={disabledendDate}
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            }
        </NyDataEdit>
    );
};

export default ContractGlobalConstantsEdit;
