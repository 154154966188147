import { NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Descriptions, Form, Modal, Popover, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import {
    GetEmployeeEducationStatusIcon,
    fileDownload,
    getTravelWarrantStatusIcon,
} from '../../../../../../utils/Utils';
import EducationEstimatedExpenses from './educationEstimatedExpenses';
import StatusHistoryIndex from '../../../../../../components/status-history/StatusHistory';

const EmployeeEducationSummary = ({ id, setVisible, visible, employeeEducation, setValue }: any) => {
    const [form] = Form.useForm();
    const [files, setFiles] = useState<any>([]);
    const [estimatedExpenses, setEstimatedExpenses] = useState<any>([]);

    const handleOk = () => {
        setVisible(false);
        setValue && setValue(null);
        form.resetFields();
    };

    useEffect(() => {
        fileSet();
        getEstimatedExpenses(employeeEducation?.id);
    }, []);

    const setExpensesFilter = (id: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_education_id', condition: 'equals', value: id },
            { field: 'estimation', condition: 'equals_bool', value: 1 },
        ];
    };

    const getEstimatedExpenses = (id: any) => {
        setEstimatedExpenses([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EDUCATION_EXPENSE.LIST, {
            search: encodeURI(JSON.stringify(setExpensesFilter(id))),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content) {
                setEstimatedExpenses(
                    result.data.content.map((element: any) => {
                        element.tempId = element.id;
                        return element;
                    })
                );
            }
        });
    };

    const employee =
        employeeEducation?.employee?.person?.firstName +
        ' ' +
        employeeEducation?.employee?.person?.lastName +
        ' (' +
        employeeEducation?.employee?.employmentRecordId +
        ')';

    const realized = employeeEducation?.realized
        ? geti18nText('app.default.button.yes')
        : geti18nText('app.default.button.no');

    const unrealizationReason = employeeEducation?.unrealizationReason?.name;

    const country = employeeEducation?.country?.name;

    const educationFormCode = employeeEducation?.educationForm?.code;

    const educationFormDescription = employeeEducation?.educationForm?.description ?? '-';

    const educationForm = educationFormCode
        ? '(' + educationFormCode + ') ' + educationFormDescription
        : educationFormDescription;

    const programCatalog = employeeEducation?.programCatalog?.name;

    const type = employeeEducation?.type?.name;

    const educationField = employeeEducation?.educationField?.name;

    const presentation = employeeEducation?.presentation
        ? geti18nText('app.default.button.yes')
        : geti18nText('app.default.button.no');

    const educationInstitution = employeeEducation?.educationInstitution?.name;

    const educationPurpose = employeeEducation?.educationPurpose?.name;

    const guestLecturer = employeeEducation?.guestLecturer
        ? geti18nText('app.default.button.yes')
        : geti18nText('app.default.button.no');

    const fileSet = () => {
        if (employeeEducation?.fileId) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.FILES.INFO + '/' + employeeEducation.fileId,
                undefined,
                undefined
            ).then((result: any) => {
                if (result && result.status == RESPONSE.OK && result.data) {
                    let filesImage = [
                        {
                            id: employeeEducation.fileId,
                            uid: employeeEducation.fileId,
                            name: result.data.name,
                            url:
                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                '/' +
                                employeeEducation.fileId +
                                '?lastmod=' +
                                new Date().valueOf() +
                                '&tenant=' +
                                NySession.getUser().tenantId,
                        },
                    ];
                    setFiles(filesImage);
                }
            });
        }
    };

    const getEducationHistory = (status: any) => {
        return (
            <div>
                {geti18nText('employee.education.summary.title')} - {employeeEducation?.name} &nbsp;
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="bottomLeft"
                    content={
                        <StatusHistoryIndex
                            id={id}
                            type="education"
                            url={CONSTANTS_REQ.EMPLOYEE_EDUCATION_STATUS_HISTORY.LIST_OF_STATUS_HISTORY}
                            params={{ educationEmployeeId: id }}
                        />
                    }
                    style={{ marginLeft: '4rem' }}
                >
                    {GetEmployeeEducationStatusIcon(status)}
                </Popover>
            </div>
        );
    };

    return (
        <>
            <Modal
                open={visible}
                okText={geti18nText('app.default.button.ok')}
                title={getEducationHistory(employeeEducation?.status)}
                width={900}
                onOk={handleOk}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={handleOk}
                maskClosable={false}
            >
                <div
                    style={{
                        maxHeight: '550px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        cursor: 'default',
                    }}
                >
                    <Form layout="vertical" form={form}>
                        <Descriptions
                            bordered
                            column={2}
                            size="small"
                            title={geti18nText('employee.education.step.2')}
                            style={{ marginBottom: '15px' }}
                        >
                            <Descriptions.Item label={geti18nText('employee.education.edit.employee')}>
                                {employee ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.applicationDueDate')}>
                                {moment(employeeEducation?.applicationDueDate).format('DD.MM.YYYY.') ?? '-'}
                            </Descriptions.Item>
                            {employeeEducation?.fileId && files && files[0] && (
                                <Descriptions.Item label={geti18nText('employee.education.edit.file')} span={2}>
                                    <a
                                        onClick={() =>
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + files[0].id,
                                                undefined,
                                                files[0].name
                                            )
                                        }
                                        style={{ textDecorationLine: 'underline' }}
                                    >
                                        {files[0].name}
                                    </a>
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                        <Descriptions
                            bordered
                            column={2}
                            size="small"
                            title={geti18nText('employee.education.step.3')}
                            style={{ marginBottom: '15px' }}
                        >
                            <Descriptions.Item label={geti18nText('employee.education.edit.programCatalog')}>
                                {programCatalog ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.name')}>
                                {employeeEducation?.name ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.place')}>
                                {employeeEducation?.place ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.country')}>
                                {country ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.dateFrom')}>
                                {moment(employeeEducation?.dateFrom).format('DD.MM.YYYY.') ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.dateTo')}>
                                {moment(employeeEducation?.dateTo).format('DD.MM.YYYY.') ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} style={{ height: '15px' }}>
                                {''}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.type')}>
                                {type ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.educationForm')}>
                                {educationForm ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.organizer')}>
                                {employeeEducation?.organizer ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.guestLecturer')}>
                                {guestLecturer}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.remark')}>
                                {employeeEducation?.remark ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.educationField')}>
                                {educationField ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.presentationDate')}>
                                {moment(employeeEducation?.presentationDate).format('DD.MM.YYYY.') ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.presentation')}>
                                {presentation}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.educationInstitution')}>
                                {educationInstitution ?? '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('employee.education.edit.educationPurpose')}>
                                {educationPurpose ?? '-'}
                            </Descriptions.Item>
                        </Descriptions>

                        <Descriptions
                            bordered
                            column={1}
                            size="small"
                            title={geti18nText('employee.education.step.4')}
                        />
                        <Row gutter={24}>
                            <Col span={24}>
                                <EducationEstimatedExpenses
                                    items={estimatedExpenses}
                                    setItems={setEstimatedExpenses}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default EmployeeEducationSummary;
