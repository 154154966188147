import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { BusinessUnitSearch } from '../business-unit/search';

type CustomFormItemProps = {
    filterForm: any;
    form: any;
    onChange?: (selectedId: any) => void;
    condition: string;
    setDefaultFilterValue: any;
};

const CustomFormItem: React.FC<CustomFormItemProps> = ({
    filterForm,
    form,
    onChange,
    condition,
    setDefaultFilterValue,
}) => {
    const [types, setTypes] = useState<any>(undefined);
    useEffect(() => {
        if (!types) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.BUSINESS_UNIT_TYPE.LIST,
                { search: encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }])) },
                true
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data && result.data.content) {
                    setTypes(result.data.content.sort((a: any, b: any) => a.level - b.level));
                }
            });
        }
    }, []);

    const change = (value: any, formName: string) => {
        const newValue =
            condition === 'in' ? (value?.length >= 1 ? value : undefined) : value?.id > 0 ? value : undefined;
        if (typeof onChange === 'function') {
            filterForm.setFieldsValue({ hierarchy_business_unit_id: newValue });
            onChange(newValue);
        }
        types.map((value: any) => {
            if (value.name === formName) {
                form.setFieldValue(formName, newValue);
                return;
            }
            form.setFieldValue(value.name, undefined);
        });
    };

    const customFilterBusinessUnitType = (name: string, label: string, id: number) => {
        return (
            <BusinessUnitSearch
                formItemName={name}
                label={label}
                onChange={(value: any) => change(value, name)}
                readonly={true}
                mode={'multiple'}
                setDefaultFilterValue={() => [
                    ...setDefaultFilterValue(),
                    {
                        field: 'business_unit_type_id',
                        condition: 'equals',
                        value: id,
                    },
                ]}
                placeholder={label}
                expandedRows={false}
            />
        );
    };

    return !types ? (
        <NySpinner />
    ) : (
        <Row gutter={20}>
            {types.map((type: any) => {
                return <Col span={4}>{customFilterBusinessUnitType(type.name, type.name, type.id)}</Col>;
            })}
        </Row>
    );
};

const HierarchyTableFilter = (
    triggerFiltering: any,
    filterForm: any,
    form: any,
    name: string,
    label: string = '',
    condition: string,
    setDefaultFilterValue: any
) => {
    return (
        <Form.Item name={name} label={label} onReset={() => form.resetFields()}>
            <Form form={form}>
                <Form.Item name={name}>
                    <CustomFormItem
                        filterForm={filterForm}
                        form={form}
                        onChange={triggerFiltering}
                        condition={condition}
                        setDefaultFilterValue={setDefaultFilterValue}
                    />
                </Form.Item>
            </Form>
        </Form.Item>
    );
};

export default HierarchyTableFilter;
