import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../../../components/report-button';
import ReportExportType from '../../../../components/report-export-type/ReportExportType';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customEmployeeRenderOption, getDateFormat, getEnumArray } from '../../../../utils/Utils';
import EmployeeIndex from '../employee';

const MonthlyReportEmployeeLeaveModal = ({
    url = CONSTANTS_REQ.REPORT.GENERATE_EMPLOYEE_LEAVE,
    reportType = 'GENERATE_EMPLOYEE_LEAVE',
    reportTitle = geti18nText('employee.leave.generateEmployeeLeave.monthly.document'),
    buttonTitle = 'employee.leave.generateEmployeeLeave.monthly',
    multiple = true,
    allowExportTypeChange = true,
}: any) => {
    const enExportType = useEnum('EXPORT_TYPE');
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('month'));
    const [dateTo, setDateTo] = useState<any>(moment().endOf('month'));
    const [types, setTypes] = useState<any>(null);
    const [employeeIds, setEmployeeIds] = useState<any>(null);
    const [exportType, setExportType] = useState(enExportType.PDF);

    const mode = multiple ? 'multiple' : 'default';

    const generateReportFileName = () => {
        return (
            reportTitle +
            '_' +
            getDateFormat(dateFrom, 'DD.MM.yyyy') +
            '-' +
            (dateFrom && dateTo && moment(dateTo)
                ? getDateFormat(dateTo, 'DD.MM.yyyy')
                : getDateFormat(moment(dateFrom).endOf('month'), 'DD.MM.yyyy'))
        );
    };

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values?.dateFrom));
                setDateTo(getDateFormat(values?.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    const onEndDateChange = (value: any) => {
        setDateTo(value ? moment(value) : null);
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('dateFrom')) {
            return current ? moment(current) < moment(form.getFieldValue('dateFrom')).endOf('day') : true;
        }
    };

    const onStartDateChange = (value: any) => {
        setDateFrom(value ? moment(value) : null);
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('dateTo')) {
            return current ? current > moment(form.getFieldValue('dateTo')).endOf('day') : true;
        }
    };

    const getDateFrom = () => (
        <Form.Item
            label={geti18nText('assetRelease.report.dateFrom')}
            name="dateFrom"
            rules={[
                {
                    required: true,
                    message: geti18nText('app.default.required'),
                },
            ]}
            initialValue={moment().startOf('month')}
        >
            <NyDatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                onChange={onStartDateChange}
                disabledDate={disabledStartDate}
                mustGetPopupContainer={false}
                // defaultPickerValue={dateFrom}
            />
        </Form.Item>
    );

    const getDateTo = () => (
        <Form.Item
            label={geti18nText('assetRelease.report.dateTo')}
            name="dateTo"
            initialValue={moment().endOf('month')}
        >
            <NyDatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                onChange={onEndDateChange}
                disabledDate={disabledendDate}
                mustGetPopupContainer={false}
                //  defaultPickerValue={dateTo}
            />
        </Form.Item>
    );

    const getFormattedValue = (value: any) => {
        if (multiple) {
            const valueIds =
                value &&
                value?.length > 0 &&
                value.map((element: any) => {
                    return element.id;
                });
            return valueIds.length > 0 ? valueIds : null;
        } else {
            return value && value.id > 0 ? value.id : null;
        }
    };

    const getFormItems = () => (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('employee.leave.generateEmployeeLeave.monthly.employees')}
                        name="employeeIds"
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={
                                <EmployeeIndex disabled={true} parentKey="monthly-report-employee-leave-modal" />
                            }
                            onChange={(value: any) => {
                                setEmployeeIds(getFormattedValue(value));
                            }}
                            order="person.last_name, person.first_name"
                            mode={mode}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('employee.leave.generateEmployeeLeave.monthly.types')} name="types">
                        <NySearchField
                            options={getEnumArray('EMPLOYEE_LEAVE_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            nyTestId="employee-leave-type"
                            className=""
                            onChange={(value: any) => {
                                setTypes(getFormattedValue(value));
                            }}
                            mode={mode}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText(buttonTitle)}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={reportTitle}
                    maskClosable={false}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    footer={[
                        <NyReportButton
                            url={url}
                            customParms={{
                                dateFrom: getDateFormat(dateFrom),
                                dateTo: getDateFormat(dateTo),
                                types: types,
                                employeeIds: employeeIds,
                                exportType: exportType ? exportType : enExportType.PDF,
                            }}
                            subreportType={reportType}
                            buttoni18nText={buttonTitle}
                            fileName={generateReportFileName()}
                            checkBeforeSave={checkBeforeSave}
                            selectedExportType={exportType}
                            disabled={!dateFrom}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {allowExportTypeChange && (
                            <ReportExportType exportType={exportType} setExportType={setExportType} />
                        )}
                        <>
                            <Row gutter={24}>
                                <Col span={12}>{getDateFrom()}</Col>
                                <Col span={12}>{getDateTo()}</Col>
                            </Row>
                            {getFormItems()}
                        </>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default MonthlyReportEmployeeLeaveModal;
