import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';
import { getDateFormat } from '../../utils/Utils';

export const SortableItem = (props: any) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });
    const style: any = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <>
            <Item
                {...attributes}
                {...props}
                className={isDragging ? 'sortable-item sortable-item-dragged' : 'sortable-item'}
                created={props?.item?.created ? getDateFormat(props?.item?.created, 'DD.MM.YYYY HH:mm') : ''}
                createdPerson={
                    props?.item?.createdEmployee
                        ? props?.item?.createdPerson?.firstName +
                          ' ' +
                          props?.item?.createdPerson?.lastName +
                          ' (' +
                          props?.item?.createdEmployee?.employmentRecordId +
                          ')'
                        : props?.item?.createdPerson
                        ? props?.item?.createdPerson?.firstName + ' ' + props?.item?.createdPerson?.lastName
                        : ''
                }
                id={props?.item?.id}
                key={props?.item?.key}
                listeners={listeners}
                mail={props?.item?.mail}
                name={props?.item?.name}
                overlaps={props?.overlaps ? props?.overlaps.toString() : undefined}
                position={props?.item?.position}
                ref={setNodeRef}
                style={style}
                type={props?.type}
                updated={props?.item?.updated ? getDateFormat(props?.item?.updated, 'DD.MM.YYYY HH:mm') : ''}
                updatedPerson={
                    props?.item?.updatedEmployee
                        ? props?.item?.updatedPerson?.firstName +
                          ' ' +
                          props?.item?.updatedPerson?.lastName +
                          ' (' +
                          props?.item?.updatedEmployee?.employmentRecordId +
                          ')'
                        : props?.item?.updatedPerson
                        ? props?.item?.updatedPerson?.firstName + ' ' + props?.item?.updatedPerson?.lastName
                        : ''
                }
            />
        </>
    );
};
