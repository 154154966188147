import { createSlice } from '@reduxjs/toolkit';

export const graphColorsSlice = createSlice({
    name: 'graphColors',
    initialState: {
        colors: [],
    },
    reducers: {
        setGraphColors: (state: any, action: any) => {
            state.colors = action.payload;
        },
    },
});

export const { setGraphColors } = graphColorsSlice.actions;

export default graphColorsSlice.reducer;
