import {
    NyDataEdit,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat, setDateFormat, setSearchFormat } from '../../../../../utils/Utils';
import CountryIndex from '../../../../administration/views/country';
import CountryEdit from '../../../../administration/views/country/edit';
import NyImageUpload from '../../../../../utils/ny-file-upload-image';
import moment from 'moment';

const EducationProgramCatalogEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('programCatalog.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [completed, setCompleted] = useState<boolean>(false);
    const focusInput = useRef<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [dateFrom, setDateFrom] = useState<any>(null);
    const [dateTo, setDateTo] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('programCatalog.table.header') + ' - ' + dataForm.id);
        }

        if (dataForm.country) {
            dataForm.country = setSearchFormat(dataForm.country);
        }

        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
            setDateFrom(dataForm.dateFrom);
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
            setDateTo(dataForm.dateTo);
        }
        if (dataForm.applicationDueDate) dataForm.applicationDueDate = setDateFormat(dataForm.applicationDueDate);

        setCompleted(dataForm.completed);

        if (dataForm.fileId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.FILES.INFO + '/' + dataForm.fileId, undefined, undefined).then(
                (result: any) => {
                    if (result && result.status == RESPONSE.OK && result.data) {
                        let filesImage = [
                            {
                                id: dataForm.fileId,
                                uid: dataForm.fileId,
                                name: result.data.name,
                                url:
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    dataForm.fileId +
                                    '?lastmod=' +
                                    new Date().valueOf() +
                                    '&tenant=' +
                                    NySession.getUser().tenantId,
                            },
                        ];
                        setFiles(filesImage);
                    }
                }
            );
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setFiles([]);
        setDataForm(null);
        setCompleted(false);
        setDateFrom(null);
        setDateTo(null);
        setEditHeader(geti18nText('programCatalog.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };
    const onEndDateChange = (value: any) => {
        setDateTo(value ? moment(value) : moment());
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('dateFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('dateFrom')).endOf('day');
        }
    };

    const onStartDateChange = (value: any) => {
        setDateFrom(value ? moment(value) : moment());
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('dateTo')) {
            return current > moment(form.getFieldValue('dateTo')).endOf('day');
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PROGRAM_CATALOG.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!HumanResourcesRights.canCreateEducationCodebooks()}
            hideActivationButtons={!HumanResourcesRights.canCreateEducationCodebooks()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                if (values.applicationDueDate) values.applicationDueDate = getDateFormat(values.applicationDueDate);
                values.completed = completed;

                if (files.length > 0 && files[0]) {
                    values.fileId = files[0].id;
                } else {
                    values.fileId = null;
                }

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        name={'name'}
                        label={geti18nText('programCatalog.edit.name')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'location'} label={geti18nText('programCatalog.edit.location')}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={'place'} label={geti18nText('programCatalog.edit.place')}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'country'} label={geti18nText('programCatalog.edit.country')}>
                        <NySearchField
                            url={CONSTANTS_REQ.COUNTRY.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<CountryIndex readonly={true} />}
                            AddNewModalComponent={CountryEdit}
                            nyTestId="country"
                            setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('programCatalog.edit.completed')} name="completed">
                        <Checkbox
                            checked={completed}
                            onChange={(val: any) => {
                                setCompleted(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.education.edit.applicationDueDate')}
                        name="applicationDueDate"
                    >
                        <NyDatePicker style={{ width: '100%' }} mustGetPopupContainer={false} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={'dateFrom'} label={geti18nText('programCatalog.edit.dateFrom')}>
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onStartDateChange}
                            disabledDate={disabledStartDate}
                            defaultPickerValue={dateFrom}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'dateTo'} label={geti18nText('programCatalog.edit.dateTo')}>
                        <NyDatePicker
                            style={{ width: '100%' }}
                            onChange={onEndDateChange}
                            disabledDate={disabledendDate}
                            defaultPickerValue={dateTo}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Divider>{geti18nText('employee.education.edit.file')}</Divider>
                    <NyImageUpload
                        files={files}
                        setFiles={setFiles}
                        saveOnUpload={true}
                        accept={'*'}
                        cropShape={null}
                        listType={'text'}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EducationProgramCatalogEdit;
