import { geti18nText, NyInputNumber } from '@nybble/nyreact';
import { Col, Form, Row, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

const TravelWarrantWageReceivedAmount = ({
    isEditForm = false,
    formTravelWarrant,
    currency,
    autoFocus = true,
}: any) => {
    return (
        <React.Fragment>
            {
                <>
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        form={formTravelWarrant}
                        // style={{ height: isEditForm && '63vh' }}
                    >
                        <Row style={{ marginLeft: isEditForm ? '24px' : '12px', marginTop: isEditForm && '24px' }}>
                            <Col span={24}>
                                <Row>
                                    <div style={{ width: 'fit-content', marginRight: '8px', marginTop: '4px' }}>
                                        {geti18nText('travelWarrantAmountReceived.daily')}
                                    </div>
                                    <Form.Item name={'wageAmountReceived'}>
                                        {isEditForm ? (
                                            <Text strong>
                                                {formTravelWarrant.getFieldValue('wageAmountReceived') +
                                                    ' ' +
                                                    formTravelWarrant.getFieldValue('advancePaymentCurrency')?.text}
                                            </Text>
                                        ) : (
                                            <NyInputNumber
                                                autoFocus={autoFocus}
                                                isDecimal={true}
                                                decimalPlaces={2}
                                                min={0}
                                                style={{ width: '100%' }}
                                                nyTestId="wageAmountReceived"
                                                onChange={(e: any) => {
                                                    formTravelWarrant.validateFields();
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                    {!isEditForm && (
                                        <div style={{ marginLeft: '8px', marginTop: '4px' }}>{currency}</div>
                                    )}
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <div
                                        style={{
                                            width: 'fit-content',
                                            marginRight: '14px',
                                            marginTop: '4px',
                                        }}
                                    >
                                        {geti18nText('travelWarrantAmountReceived.travel.costs')}
                                    </div>
                                    <Form.Item name={'travelVehicleExpenseAmountReceived'}>
                                        {isEditForm ? (
                                            <Text strong>
                                                {formTravelWarrant.getFieldValue('travelVehicleExpenseAmountReceived') +
                                                    ' ' +
                                                    formTravelWarrant.getFieldValue('advancePaymentCurrency')?.text}
                                            </Text>
                                        ) : (
                                            <NyInputNumber
                                                isDecimal={true}
                                                decimalPlaces={2}
                                                min={0}
                                                style={{ width: '100%' }}
                                                nyTestId="travelVehicleExpenseAmountReceived"
                                                onChange={(e: any) => {
                                                    formTravelWarrant.validateFields();
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                    {!isEditForm && (
                                        <div style={{ marginLeft: '8px', marginTop: '4px' }}>{currency}</div>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </>
            }
        </React.Fragment>
    );
};

export default TravelWarrantWageReceivedAmount;
