import { useRef, useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, setDateFormat } from '../../../../utils/Utils';
import { PayrollRights } from '../../../../rights/payrollRights';

const PayrollExpenseEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('payrollExpense.edit.new'));
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const focusInput = useRef<any>(null);

    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.validFrom) dataForm.validFrom = setDateFormat(dataForm.validFrom);
        if (dataForm.validTo) dataForm.validTo = setDateFormat(dataForm.validTo);
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('payrollExpense.edit.title') + ' - ' + dataForm.name);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('payrollExpense.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };
    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.PAYROLL_EXPENSE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
                if (values.validTo) values.validTo = getDateFormat(values.validTo);
                return values;
            }}
            shortcuts={true}
            nyTestId="payrollExpenseEdit"
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('payrollExpense.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('payrollExpense.edit.percent')}
                        name="percent"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber isDecimal style={{ width: '100%' }} nyTestId="percent" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('payrollExpense.edit.validFrom')} name="validFrom">
                        <NyDatePicker style={{ width: '100%' }} nyTestId="valid-from" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('payrollExpense.edit.validTo')} name="validTo">
                        <NyDatePicker style={{ width: '100%' }} nyTestId="valid-to" />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PayrollExpenseEdit;
