import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, SaveTwoTone } from '@ant-design/icons';
import {
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Alert, Button, Col, Form, Input, Modal, Row, Space, Table, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import NyDescription from '../../../../../components/ny-description';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, getDateFormat, okNotification, onKeyDownTextAreaCustom } from '../../../../../utils/Utils';
import EditableCell from './EditableCell';

const GoalPlanIndex = ({
    id,
    form,
    isUser = false,
    isManager = false,
    disabledForm = false,
    activeKey,
    disabledGoals = false,
    saveEvaluation,
    dataFormObject,
}: any) => {
    const focusInput = useRef<any>(null);
    const [editForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');
    const isEditing = (record: any) => record.id === editingKey || record.tempId === editingKey;
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [isNewInProcess, setIsNewInProcess] = useState<boolean>(false);
    const [items, setItems] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [editRecordForm] = Form.useForm();
    const [editRecord, setEditRecord] = useState<any>(null);

    useEffect(() => {
        setAddItemInProcess(false);
        setEditingKey('');
        if (isNewInProcess) {
            setItems(items.filter((element: any) => element.id != null && element?.id != undefined));
        }
    }, [activeKey]);

    useEffect(() => {
        getItems();
    }, []);

    const getItems = () => {
        setLoading(true);
        if (id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION_GOAL_PLAN.LIST, {
                search: encodeURI(
                    JSON.stringify([
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'employee_evaluation_id', condition: 'equals', value: id },
                    ])
                ),
                order: 'id',
                orderType: 'asc',
                max: 1000,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK && result?.data?.content) {
                    const data: any = result.data.content.map((item: any) => {
                        if (item.dueDate) {
                            item.dueDate = getDateFormat(item.dueDate);
                        }
                        return item;
                    });
                    setItems(data);
                } else {
                    setItems([]);
                }
                setLoading(false);
            });
        }
    };

    const addNewRow = (event: any) => {
        setIsNewInProcess(true);
        event.stopPropagation();
        editForm.resetFields();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            tempId: newId,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const edit = (record: any) => {
        let row = undefined;
        row = { ...record };
        editForm.setFieldsValue(row);
        setAddItemInProcess(true);
        setEditingKey(row.id ?? row.tempId);
    };

    const deleteRow = (row: any) => {
        if (row.id) {
            row.active = false;
            saveItem(row);
            setItems(items.filter((element: any) => row.id !== element.id));
        } else if (row?.tempId != undefined && row?.tempId >= 0) {
            setItems(items.filter((element: any) => row.tempId !== element.tempId));
        }
    };

    const cancel = (record: any) => {
        deleteRow(record);
        setEditingKey('');
        setAddItemInProcess(false);
        setIsNewInProcess(false);
    };

    const afterSaveAction = (result: any) => {
        if (result?.status === RESPONSE.CREATED || result?.status === RESPONSE.OK) {
            okNotification();
            setEditingKey('');
            setAddItemInProcess(false);
            setIsNewInProcess(false);

            if (result?.data?.active == true) {
                const newData = [...items];
                let index: any = newData.findIndex((object) => object.id === result.data.id);

                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...result.data });
                } else {
                    newData.push(result.data);
                }
                setItems(newData.filter((element: any) => element.id != null && element.id != undefined));
            }
        } else {
            errorNotification();
        }
    };

    const saveItem = (record: any) => {
        record.employeeEvaluation = { id: id };
        if (record.dueDate) {
            record.dueDate = getDateFormat(record.dueDate, 'yyyy-MM-DD HH:mm');
        }
        if (record.id) {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.EMPLOYEE_EVALUATION_GOAL_PLAN.EDIT + '/' + record.id,
                undefined,
                record
            ).then((result: any) => {
                afterSaveAction(result);
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.EMPLOYEE_EVALUATION_GOAL_PLAN.EDIT, undefined, record).then(
                (result: any) => {
                    afterSaveAction(result);
                }
            );
        }
    };

    const save = async () => {
        try {
            let row = await editForm.validateFields();
            saveItem(row);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const getEditForm = (record: any, value: any, dataIndex: any, title: string, disabled: boolean, maxWidth: any) => (
        <Row gutter={24}>
            <Col span={21}>
                <p style={{ marginTop: '10px' }}>
                    <NyDescription title={value} maxWidth={maxWidth} />
                </p>
            </Col>
            {!disabled && (
                <Col span={3}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                        <Button
                            type="link"
                            onClick={() => {
                                setTimeout(() => {
                                    if (focusInput.current) {
                                        focusInput.current.focus();
                                    }
                                });
                                setEditRecord({
                                    ...record,
                                    dataIndex: dataIndex,
                                    title: title,
                                    value: value,
                                });
                                editRecordForm.setFieldsValue({ editRecord: value });
                            }}
                            disabled={disabled}
                            style={{ float: 'right' }}
                        >
                            <EditTwoTone
                                style={{
                                    fontSize: '20px',
                                    marginTop: '8px',
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Col>
            )}
        </Row>
    );

    const columns = [
        ...[
            {
                title: geti18nText('employee.evaluation.edit.goalPlan.column.goalDescription'),
                dataIndex: 'goalDescription',
                inputType: 'goalDescription',
                editable: !disabledForm,
                required: true,
                render: (text: any, record: any) => {
                    if (disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.goalDescription} maxWidth={'1100px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.goalDescription,
                            'goalDescription',
                            geti18nText('employee.evaluation.edit.goalPlan.column.goalDescription'),
                            disabledForm || addItemInProcess,
                            '950px'
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.goalPlan.column.quality'),
                dataIndex: 'quality',
                inputType: 'quality',
                editable: !disabledForm,
                required: true,
                width: '25%',
                render: (text: any, record: any) => {
                    if (disabledForm) {
                        return (
                            <p style={{ marginTop: '10px' }}>
                                <NyDescription title={record?.quality} maxWidth={'450px'} />
                            </p>
                        );
                    } else {
                        return getEditForm(
                            record,
                            record?.quality,
                            'quality',
                            geti18nText('employee.evaluation.edit.goalPlan.column.quality'),
                            disabledForm || addItemInProcess,
                            '400px'
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.goalPlan.column.dueDate'),
                dataIndex: 'dueDate',
                inputType: 'dueDate',
                editable: !disabledForm,
                required: true,
                width: '8%',
                render: (text: any, record: any) => {
                    if (disabledForm) {
                        return moment(record.dueDate).format('DD.MM.YYYY');
                    } else {
                        return (
                            <>
                                <NyDatePicker
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }}
                                    mustGetPopupContainer={false}
                                    value={record.dueDate ? moment(record.dueDate) : undefined}
                                    allowClear={false}
                                    onChange={(value: any) => saveItem({ ...record, dueDate: value })}
                                />
                            </>
                        );
                    }
                },
            },
            {
                title: geti18nText('employee.evaluation.edit.goalPlan.column.quantity'),
                dataIndex: 'quantity',
                inputType: 'quantity',
                editable: !disabledForm,
                required: false,
                width: '8%',
                render: (text: any, record: any) => {
                    if (disabledForm) {
                        return record.quantity;
                    } else {
                        return (
                            <>
                                <NyInputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    value={record.quantity}
                                    onChange={(value: any) => saveItem({ ...record, quantity: value })}
                                />
                            </>
                        );
                    }
                },
            },
        ],
        ...(items.length >= 1 && !disabledForm
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '5%',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
        ...[],
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable || disabledForm) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    required: col.required,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: editForm,
                };
            },
        };
    });

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={save} disabled={disabledForm} style={{ marginRight: '-15px' }}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button
                            type="link"
                            disabled={disabledForm}
                            onClick={() => {
                                cancel(record);
                            }}
                        >
                            <CloseCircleTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#faad14"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                    {isManager && (
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={!isManager || disabledForm || addItemInProcess}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    )}
                </Space>
            </React.Fragment>
        );
    };

    return (
        <>
            <Form layout="vertical" form={form}>
                {disabledGoals && isUser && (
                    <Row gutter={24} style={{ marginTop: '4px', marginBottom: '12px' }}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('employee.evaluation.edit.plansDisabled')}
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>
                )}
                <Row
                    gutter={24}
                    style={{
                        marginBottom: '10px',
                        marginTop: '5px',
                    }}
                >
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={editForm}
                            onFinishFailed={({ errorFields }) => {
                                editForm.scrollToField(errorFields[0].name);
                            }}
                        >
                            {loading ? (
                                <NySpinner />
                            ) : (
                                <Table
                                    rowClassName="editable-row"
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    dataSource={items}
                                    columns={mergedColumns}
                                    pagination={false}
                                    size="small"
                                    sticky
                                    bordered
                                    style={{ maxHeight: '610px', overflowY: 'auto', overflowX: 'hidden' }}
                                />
                            )}
                        </Form>
                    </Col>
                </Row>
                {!addItemInProcess && id && !disabledForm && !disabledGoals && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Button style={{ display: 'inline', float: 'right' }} type="primary" onClick={addNewRow}>
                                {geti18nText('employee.evaluation.edit.goalPlan.add')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="goalPlanManagerComment"
                            label={geti18nText('employee.evaluation.edit.goalPlanManagerComment')}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                onKeyDown={onKeyDownTextAreaCustom}
                                disabled={!isManager || disabledForm}
                                allowClear={false}
                                onBlur={(val: any) => {
                                    const value = val?.target?.value;
                                    if (
                                        (dataFormObject?.goalPlanManagerComment?.length > 0 &&
                                            value?.length > 0 &&
                                            dataFormObject.goalPlanManagerComment != value) ||
                                        !dataFormObject?.goalPlanManagerComment ||
                                        dataFormObject?.goalPlanManagerComment?.length < 1
                                    ) {
                                        saveEvaluation();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="goalPlanEmployeeComment"
                            label={geti18nText('employee.evaluation.edit.goalPlanEmployeeComment')}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                onKeyDown={onKeyDownTextAreaCustom}
                                disabled={!isUser || disabledForm}
                                allowClear={false}
                                onBlur={(val: any) => {
                                    const value = val?.target?.value;
                                    if (
                                        (dataFormObject?.goalPlanEmployeeComment?.length > 0 &&
                                            value?.length > 0 &&
                                            dataFormObject.goalPlanEmployeeComment != value) ||
                                        !dataFormObject?.goalPlanEmployeeComment ||
                                        dataFormObject?.goalPlanEmployeeComment?.length < 1
                                    ) {
                                        saveEvaluation();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                open={editRecord != null}
                title={editRecord?.title}
                okText={geti18nText('app.default.button.save')}
                onOk={() => {
                    saveItem(editRecord);
                    setEditRecord(null);
                }}
                onCancel={() => setEditRecord(null)}
            >
                <Form form={editRecordForm}>
                    <>
                        {editRecord?.dataIndex == 'goalDescription' ? (
                            <Form.Item name="editRecord">
                                <TextArea
                                    ref={focusInput}
                                    onBlur={(value: any) => {
                                        if (value?.target?.value?.length > 0) {
                                            setEditRecord({ ...editRecord, goalDescription: value?.target?.value });
                                        }
                                    }}
                                    autoSize={{ minRows: 3, maxRows: 4 }}
                                    allowClear={false}
                                />
                            </Form.Item>
                        ) : editRecord?.dataIndex == 'quality' ? (
                            <Form.Item name="editRecord">
                                <Input
                                    ref={focusInput}
                                    onBlur={(value: any) => {
                                        if (value?.target?.value?.length > 0) {
                                            setEditRecord({ ...editRecord, quality: value?.target?.value });
                                        }
                                    }}
                                    allowClear={false}
                                />
                            </Form.Item>
                        ) : (
                            <></>
                        )}
                    </>
                </Form>
            </Modal>
        </>
    );
};
export default GoalPlanIndex;
