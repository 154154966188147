import {
    AppstoreOutlined,
    CloudDownloadOutlined,
    DatabaseOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    ReadOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Divider, Empty, Image, Modal, Row, Switch, Table, Tooltip, Upload, message } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useEnum from '../../hooks/useEnum';
import useTableSettings from '../../hooks/useTableSettings';
import { TasksRights } from '../../rights/tasksRights';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../Constants';
import { checkIsFileImage, fileDownload, warningNotification } from '../Utils';
import CustomView from './CustomView';
import { deactivateFiles, deleteFiles, deleteFilesWithRecordId, uploadFiles } from './FilesFunctions';
import FileEdit from './edit';
import { INyFilesUpload } from './types';

const NyFilesUpload = forwardRef(
    (
        {
            id,
            refId,
            url,
            editUrl,
            downloadUrl = CONSTANTS_REQ.FILES.DOWNLOAD,
            uploadUrl = CONSTANTS_REQ.FILES.UPLOAD,
            manageUploadUrl = CONSTANTS_REQ.FILES.MANAGE_UPLOAD,
            deleteUrl = CONSTANTS_REQ.FILES.DELETE_BY_IDS,
            manageDeleteUrl = CONSTANTS_REQ.FILES.MANAGE_DELETE,
            deactivateUrl,
            documentType,
            defaultFilesFilter = [{ field: 'active', condition: 'equals_bool', value: 1 }],
            columns,
            maxFiles,
            maxHeight = null,
            deleteValidationField,
            setCanContinue,
            canAddNewFile = true,
            canDelete = true,
            canEdit = true,
            defaultType = 'table', //list, nyDataTable
            listType = 'picture', // "picture-card", "text"
            uploadClassName = 'upload-list-inline', //"avatar-uploader"
            openWindowOnDownload = false,
            showSwitchView = true,
            showHeader = true,
            showDocumentTypeColumn = false,
            showColorOnIcons = true,
            datePadding = false,
            onModalClose,
            refreshOnUpload = true,
            customUploadButton,
            customUploadButtonTitle,
            actionDeactivateRecords = false,
            hasNoRefId = false,
            morhType,
            refresh,
            //NyDataTable
            CSVFileName,
            exportCSV = false,
            defaultPageSize,
            defaultFilter,
            //TravelWarrant
            moduleAccessRight = null,
            employeeTravelWarrantId = null,
            isTravelWarrantEdit = false,
            dataSourceFiles = null,
            setDataSourceFiles = null,
            setRefreshDataSourceFiles = null,
            isOrganizationModal = false,
            travelWarrantAdditionalData = [],
            printPdf = false,
            //edit modal
            editTitle = geti18nText('webshopServices.addFile'),
            editOkText = geti18nText('app.default.button.save'),
            editDeleteText = geti18nText('app.default.remove'),
            editWidth = 700,
            note,
            accept = '*',
            requiredAllFields = false,
            editListType = 'picture', //"text"
            employee,
            allowedFileSize,
            fontSize = '20px',
            columnStyleClass = 'description-div-small',
            readonly = false,
            //type
            isTravelWarrant = false,
            isMobilization = false,
            isTaskList = false,
            customFilesTableWidth = 800,
            isBoldText = false,
            scroll = undefined,
            showPreview = false,
            setPreviewID = undefined,
            showPreviewFirstFile = false,
        }: INyFilesUpload,
        ref
    ) => {
        const [files, setFiles] = useState<any>(dataSourceFiles ?? []);
        const [loading, setLoading] = useState(false);
        const [type, setType] = useState<any>(defaultType);
        const [refreshFiles, setRefreshFiles] = useState(0);
        const [selectedFile, setSelectedFile] = useState<any>(null);
        const [editModalVisible, setEditModalVisible] = useState<any>(false);
        const [uploadType, setUploadType] = useState<any>();

        const [previewVisible, setPreviewVisible] = useState(false);
        const [previewImage, setPreviewImage] = useState<any>(undefined);

        const [listNamedExpense, setListNamedExpense] = useState<any>([]);
        const [listDefaultExpense, setDefaultExpense] = useState<any>([]);
        const [undefinedExpense, setUndefinedExpense] = useState<any>([]);
        const notificationType = useEnum('NOTIFICATION_TYPE');
        const documentTypeEnum = useEnum('DOCUMENT_TYPE');

        const showCustomView = () => {
            return isTravelWarrant || isMobilization || isTaskList;
        };

        const isTravelWarrantAdmin = () => {
            return TravelWarrantRights.canCreateTravelWarrantAdmin();
        };

        const isTravelWarrantUser = () => {
            return TravelWarrantRights.canCreateTravelWarrantUserFiles(
                employee?.id,
                employeeTravelWarrantId,
                moduleAccessRight
            );
        };

        const isTravelWarrantOrganizator = () => {
            return TravelWarrantRights.canCreateTravelWarrantOrganizator(employee, notificationType);
        };

        const isTravelWarrantCompensator = () => {
            return TravelWarrantRights.canCreateTravelWarrantCompensator(employee, notificationType);
        };

        const canAddFile = () => {
            if (!readonly) {
                if (!isTravelWarrant) {
                    return canAddNewFile;
                } else {
                    if (isTravelWarrantAdmin() || isTravelWarrantCompensator()) {
                        return true;
                    } else if (isOrganizationModal) {
                        return isTravelWarrantOrganizator();
                    } else if (isTravelWarrantUser()) {
                        return canAddNewFile;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        };

        const canEditFile = (file: any) => {
            if (!readonly) {
                if (isTaskList) {
                    if (TasksRights.isTasksAdmin()) {
                        return true;
                    } else if (TasksRights.isTasksAgent() || TasksRights.isTasksUser()) {
                        return (
                            canEdit &&
                            Object.values([file?.employee?.id, file?.userEmployee?.id]).includes(employee?.id)
                        );
                    } else {
                        return false;
                    }
                } else if (isTravelWarrant) {
                    if (isTravelWarrantAdmin() || isTravelWarrantCompensator()) {
                        return true;
                    } else if (isOrganizationModal) {
                        return (
                            isTravelWarrantOrganizator() &&
                            (file?.travelWarrantAdditionalInfo?.id ||
                                Object.values([file?.employee?.id, file?.userEmployee?.id]).includes(employee?.id))
                        );
                    } else if (isTravelWarrantUser()) {
                        return (
                            canEdit &&
                            Object.values([file?.employee?.id, file?.userEmployee?.id]).includes(employee?.id) &&
                            !file?.travelWarrantAdditionalInfo &&
                            documentType != documentTypeEnum.TRAVEL_WARRANT_ORGANIZATION
                        );
                    } else {
                        return false;
                    }
                } else {
                    return canEdit;
                }
            } else {
                return false;
            }
        };

        const canDeleteFile = (file: any) => {
            if (!readonly) {
                if (isTaskList) {
                    if (TasksRights.isTasksAdmin()) {
                        return true;
                    } else if (TasksRights.isTasksAgent() || TasksRights.isTasksUser()) {
                        return (
                            canDelete &&
                            Object.values([file?.employee?.id, file?.userEmployee?.id]).includes(employee?.id)
                        );
                    } else {
                        return false;
                    }
                } else if (isTravelWarrant) {
                    if (isTravelWarrantAdmin() || isTravelWarrantCompensator()) {
                        return true;
                    } else if (isOrganizationModal) {
                        return (
                            isTravelWarrantOrganizator() &&
                            (file?.travelWarrantAdditionalInfo?.id ||
                                Object.values([file?.employee?.id, file?.userEmployee?.id]).includes(employee?.id))
                        );
                    } else if (isTravelWarrantUser()) {
                        return (
                            !isOrganizationModal &&
                            canDelete &&
                            Object.values([file?.employee?.id, file?.userEmployee?.id]).includes(employee?.id) &&
                            !file?.travelWarrantAdditionalInfo &&
                            documentType != documentTypeEnum.TRAVEL_WARRANT_ORGANIZATION
                        );
                    } else {
                        return false;
                    }
                } else if (isMobilization) {
                    return (
                        canDelete && Object.values([file?.person?.id, file?.person?.id]).includes(employee?.person?.id)
                    );
                } else {
                    return canDelete;
                }
            } else {
                return false;
            }
        };

        const showAddNewFile = () => {
            return !readonly && canAddFile() && (!maxFiles || (maxFiles && files?.length < maxFiles)) && !printPdf;
        };

        const getDocumentType = (fileDocumentType: any) => {
            return fileDocumentType ?? uploadType ?? documentType;
        };

        const uploadFile = async (returnedId: any, files: any) => {
            console.log('returnedId', returnedId);
            console.log('files', files);
            setLoading(true);
            let dataForm = returnedId;
            let refId = null;
            if (id && returnedId) {
                dataForm = id;
                refId = returnedId;
            } else if (!id && returnedId) {
                dataForm = returnedId;
                refId = returnedId;
            }
            const result = await uploadFiles(
                files,
                dataForm,
                refId,
                getDocumentType(null),
                hasNoRefId,
                uploadUrl,
                manageUploadUrl,
                deleteUrl,
                manageDeleteUrl,
                undefined,
                morhType
            ).then((res: any) => {
                if (res) {
                    const timeout = setTimeout(() => {
                        setLoading(false);
                        if (refreshOnUpload) {
                            setRefreshFiles((refresh: any) => refresh + 1);
                        }
                        if (onModalClose) {
                            onModalClose();
                        }
                    }, 1000);
                }
            });
        };

        useImperativeHandle(ref, () => ({
            filesUpload(returnedId: any) {
                uploadFile(returnedId, files);
            },
        }));

        useEffect(() => {
            if (setCanContinue) {
                setCanContinue(files?.length >= 1);
            }
            if (setDataSourceFiles && (!setRefreshDataSourceFiles || isTaskList)) {
                setDataSourceFiles(files);
            }
        }, [files]);

        useEffect(() => {
            if (dataSourceFiles?.length > 0) {
                setFiles(dataSourceFiles);
            }
        }, [dataSourceFiles]);

        useEffect(() => {
            if (url && !dataSourceFiles && ((refId && refId > 0) || isOrganizationModal || hasNoRefId)) {
                getFiles();
            }
            if (setRefreshDataSourceFiles) {
                setRefreshDataSourceFiles((refreshDataSourceFiles: any) => refreshDataSourceFiles + 1);
            }
        }, [refId, url, refreshFiles, refresh]);

        function getFiles() {
            NyRequestResolver.requestGet(url, {
                search: encodeURI(JSON.stringify(defaultFilesFilter)),
                max: 1000,
            }).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.content) {
                    setFiles(result.data.content);
                }
            });
        }

        const updateFile = (file: any) => {
            let refIdTemp = refId;
            if (isOrganizationModal && file?.travelWarrantAdditionalInfo?.id) {
                refIdTemp = file?.travelWarrantAdditionalInfo?.id;
            }
            let params: any = {
                id: file.id,
                description: file.description,
                active: true,
                documentType: file.documentType,
                name: file.name,
                fileId: file.fileId,
                employee: file.employee,
                refId: refIdTemp,
                person: file.person,
                user: { id: NySession.getUser()?.id },
            };
            if (isTaskList) {
                params.file = file.fileId;
            } else {
                params.file = { id: file.fileId };
            }

            params.assetAcquisition = { id: refId };
            params.mobilization = { id: refId };
            params.servicesOrder = { id: refId };
            params.travelWarrant = { id: id };

            if (file?.travelWarrantExpense?.id || documentType == documentTypeEnum.TRAVEL_WARRANT_EXPENSE) {
                params.travelWarrantExpense = { id: file?.travelWarrantExpense?.id ?? refId };
            } else if (file?.travelWarrantAdditionalInfo?.id) {
                params.travelWarrantAdditionalInfo = { id: file?.travelWarrantAdditionalInfo?.id };
            }
            if (file?.users?.id) {
                params.user = { id: file.users.id };
            }
            if (file?.task?.id) {
                params.task = { id: file.task.id };
            }

            NyRequestResolver.requestPut(editUrl + '/' + file.id, undefined, params).then((result: any) => {
                if (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED) {
                    message.destroy();
                    message.success(`${file.name} ${geti18nText('update.text.done')}`);
                    setRefreshFiles((refresh: any) => refresh + 1);
                }
            });
        };

        const iconStyle = (color: any = null) => {
            if (showColorOnIcons && color) {
                return { marginLeft: '10px', fontSize: fontSize, color: color };
            } else {
                return { marginLeft: '10px', fontSize: fontSize };
            }
        };

        const getDocumentTypeColumn = (record: any) => {
            let title = '';
            if (record?.travelWarrantExpense?.id || documentType == documentTypeEnum.TRAVEL_WARRANT_EXPENSE) {
                title = geti18nText('travelWarrantOrganization.file.list.column.payment.other');
            } else if (record?.travelWarrantAdditionalInfo?.id) {
                title = geti18nText('travelWarrantOrganization.file.list.column.payment.default');
            } else if (record.documentType) {
                title = geti18nText('menu.work.obligation.call.table.enum.' + record.documentType);
            }
            return (
                <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={title}>
                    <div className={columnStyleClass}>
                        <p className="description-paragraph">{title}</p>
                    </div>
                </Tooltip>
            );
        };

        const getUserColumn = (record: any) => {
            if (record?.employee?.person) {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '400px' }}
                        title={record.employee.person.firstName + ' ' + record.employee.person.lastName}
                    >
                        <div className={columnStyleClass}>
                            <p className="description-paragraph">
                                {record.employee.person.firstName + ' ' + record.employee.person.lastName}
                            </p>
                        </div>
                    </Tooltip>
                );
            } else if (record.person) {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '400px' }}
                        title={record.person.firstName + ' ' + record.person.lastName}
                    >
                        <div className={columnStyleClass}>
                            <p className="description-paragraph">
                                {record.person.firstName + ' ' + record.person.lastName}
                            </p>
                        </div>
                    </Tooltip>
                );
            } else if (record.userPerson) {
                return (
                    <Tooltip
                        placement="topLeft"
                        overlayStyle={{ maxWidth: '400px' }}
                        title={record.userPerson.firstName + ' ' + record.userPerson.lastName}
                    >
                        <div className={columnStyleClass}>
                            <p className="description-paragraph">
                                {record.userPerson.firstName + ' ' + record.userPerson.lastName}
                            </p>
                        </div>
                    </Tooltip>
                );
            }
        };

        const getActionColumn = (record: any) => {
            if (record && !printPdf) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        {showPreview && record?.fileId && (
                            <Tooltip title={geti18nText('app.default.previewFile')}>
                                <ReadOutlined
                                    style={iconStyle('#1890ff')}
                                    onClick={() => {
                                        setPreviewID(record.fileId);
                                    }}
                                />
                            </Tooltip>
                        )}
                        {(record?.fileId || record?.file?.id || record?.url) && (
                            <Tooltip title={geti18nText('app.default.download')}>
                                <CloudDownloadOutlined
                                    style={iconStyle('green')}
                                    onClick={() => {
                                        downloadFileCustom(record);
                                    }}
                                />
                            </Tooltip>
                        )}
                        {canEditFile(record) && (
                            <Tooltip title={geti18nText('app.default.button.edit')}>
                                <EditOutlined
                                    style={iconStyle()}
                                    onClick={() => {
                                        setSelectedFile(record);
                                        setEditModalVisible(true);
                                    }}
                                />
                            </Tooltip>
                        )}
                        {canDeleteFile(record) && (
                            <NyModalConfirm
                                title={geti18nText('app.default.delete.file.confirm')}
                                onConfirm={() => {
                                    removeFile(record);
                                }}
                            >
                                <Tooltip title={geti18nText('app.default.remove')}>
                                    <DeleteOutlined style={{ marginLeft: '10px', fontSize: fontSize, color: 'red' }} />
                                </Tooltip>
                            </NyModalConfirm>
                        )}
                    </div>
                );
            }
        };

        const defaultColumns: any = [
            ...[
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.file.name')}
                        </span>
                    ),
                    dataIndex: 'name',
                    width: '20%',
                    render: (name: any) => {
                        return (
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={name}>
                                <div className={columnStyleClass}>
                                    <p className="description-paragraph">{name}</p>
                                </div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.description')}
                        </span>
                    ),
                    dataIndex: 'description',
                    width: '20%',
                    render: (description: any) => {
                        return (
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={description}>
                                <div className={columnStyleClass}>
                                    <p className="description-paragraph">{description}</p>
                                </div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.uploaded')}
                        </span>
                    ),
                    dataIndex: 'uploaded',
                    render: (text: any, record: any) => {
                        if (record.uploaded) {
                            return (
                                <Tooltip
                                    placement="topLeft"
                                    overlayStyle={{ maxWidth: '400px' }}
                                    title={moment(record.uploaded).format('DD.MM.YYYY HH:mm')}
                                >
                                    <div className={columnStyleClass}>
                                        <p className="description-paragraph">
                                            {moment(record.uploaded).format('DD.MM.YYYY HH:mm')}
                                        </p>
                                    </div>
                                </Tooltip>
                            );
                        }
                    },
                },
            ],
            ...(showDocumentTypeColumn === true
                ? [
                      {
                          title: (
                              <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                                  {geti18nText('fileList.table.column.type')}
                              </span>
                          ),
                          dataIndex: 'documentType',
                          render: (text: any, record: any) => {
                              return getDocumentTypeColumn(record);
                          },
                      },
                  ]
                : []),
            ...[
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.user.name')}
                        </span>
                    ),
                    dataIndex: ['person.firstName'],
                    render: (text: any, record: any) => {
                        return getUserColumn(record);
                    },
                },
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('app.default.actions')}
                        </span>
                    ),
                    dataIndex: 'actions',
                    width: '130px',
                    render: (text: any, record: any) => {
                        return getActionColumn(record);
                    },
                },
            ],
        ].filter((x) => x !== null);

        const defaultColumnsNyDataTable: any = [
            ...[
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.file.name')}
                        </span>
                    ),
                    dataIndex: 'name',
                    width: '20%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    render: (name: any) => {
                        return (
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={name}>
                                <div className={columnStyleClass}>
                                    <p className="description-paragraph">{name}</p>
                                </div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.description')}
                        </span>
                    ),
                    dataIndex: 'description',
                    width: '20%',
                    ...getColumnSearch('string'),
                    sorter: (a: any, b: any) => {},
                    render: (description: any) => {
                        return (
                            <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={description}>
                                <div className={columnStyleClass}>
                                    <p className="description-paragraph">{description}</p>
                                </div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.uploaded')}
                        </span>
                    ),
                    dataIndex: 'uploaded',
                    ...getColumnDateOption(),
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        if (record.uploaded) {
                            return (
                                <Tooltip
                                    placement="topLeft"
                                    overlayStyle={{ maxWidth: '400px' }}
                                    title={moment(record.uploaded).format('DD.MM.YYYY HH:mm')}
                                >
                                    <div className={columnStyleClass}>
                                        <p className="description-paragraph">
                                            {moment(record.uploaded).format('DD.MM.YYYY HH:mm')}
                                        </p>
                                    </div>
                                </Tooltip>
                            );
                        }
                    },
                },
            ],
            ...(showDocumentTypeColumn === true
                ? [
                      {
                          title: (
                              <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                                  {geti18nText('fileList.table.column.type')}
                              </span>
                          ),
                          dataIndex: 'documentType',
                          ...getColumnSearch('string'),
                          sorter: (a: any, b: any) => {},
                          render: (text: any, record: any) => {
                              return getDocumentTypeColumn(record);
                          },
                      },
                  ]
                : []),
            ...[
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('fileList.table.column.user.name')}
                        </span>
                    ),
                    dataIndex: ['person.firstName'],
                    ...getColumnSearch('string'),
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        return getUserColumn(record);
                    },
                },
                {
                    title: (
                        <span style={{ fontWeight: isBoldText ? '500' : undefined }}>
                            {geti18nText('app.default.actions')}
                        </span>
                    ),
                    dataIndex: 'actions',
                    width: '130px',
                    render: (text: any, record: any) => {
                        return getActionColumn(record);
                    },
                },
            ],
        ].filter((x) => x !== null);

        const removeFiles = (file: any, fileIds: any, id: any, documentType: any, actionDeactivateRecords: any) => {
            if (actionDeactivateRecords) {
                deactivateFiles(fileIds, id, deactivateUrl, setRefreshFiles);
            } else {
                if (isTaskList) {
                    deleteFilesWithRecordId(file?.id, file?.fileId, manageDeleteUrl + '/' + file?.id, deleteUrl, {
                        id: file?.id,
                    });
                }
                if (isMobilization) {
                    deleteFilesWithRecordId(
                        file?.id,
                        file?.fileId,
                        manageDeleteUrl.replace('{militaryWorkApprovalId}', file?.id).replace('{fileId}', file?.fileId),
                        deleteUrl
                    );
                } else {
                    deleteFiles(fileIds, id, documentType, deleteUrl, manageDeleteUrl);
                }
            }
        };

        const removeFile = async (file: any) => {
            if (deleteValidationField && file[deleteValidationField]) {
                warningNotification(geti18nText('travelWarrantWizard.step.12.info2'));
            } else if (file && file.id) {
                const newData: any = [...files];
                setFiles(newData.filter((fil: any) => fil.id != file.id));
                if (file.fileId || file?.file?.id) {
                    if (hasNoRefId || refId) {
                        await removeFiles(
                            file,
                            [file.fileId ?? file?.file?.id],
                            id ?? file?.id,
                            getDocumentType(file.documentType),
                            actionDeactivateRecords
                        );
                    } else {
                        const newData = [...files];
                        const index = newData.findIndex((item: any) => file.id === item.id);
                        newData.splice(index, 1);
                        setFiles(newData);
                    }
                }
                if (hasNoRefId) {
                    setRefreshFiles((refresh: any) => refresh + 1);
                }
            }
        };

        const removeFilesCustomView = (item: any) => {
            removeFile(item);
            setLoading(false);
        };

        const onPreview = async (file: any) => {
            if (checkIsFileImage(file)) {
                let url = await file.thumbUrl;
                setPreviewImage(file.fileId ? downloadUrl + '/' + file.fileId : url);
                setPreviewVisible(true);
            } else {
                downloadFileCustom(file);
            }
        };

        const downloadFileCustom = (file: any) => {
            if (file.fileId || file?.file?.id) {
                fileDownload(downloadUrl + '/' + (file?.fileId ?? file?.file?.id), null, file.name);
            } else {
                if (openWindowOnDownload) {
                    if (file) {
                        if (file.url) {
                            window.open(file.url, '_blank');
                        } else if (file.fileId) {
                            window.open(downloadUrl + '/' + file.fileId, '_blank');
                        }
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (file) {
                        const url = window.URL.createObjectURL(new Blob([file.file]));
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        if (file.name) {
                            a.download = file.name;
                        } else {
                            a.download = 'download';
                        }
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        };

        const getColumns = () => {
            return columns ?? type == 'nyDataTable' ? defaultColumnsNyDataTable : defaultColumns;
        };

        const getSwitch = () => (
            <>
                {showSwitchView && files && files.length > 0 && !printPdf && (
                    <Switch
                        defaultChecked={type == 'list' ? true : false}
                        checkedChildren={<DatabaseOutlined />}
                        unCheckedChildren={<AppstoreOutlined />}
                        onChange={(checked: any) => setType(checked ? 'list' : 'table')}
                        style={{ marginBottom: '10px' }}
                    />
                )}
            </>
        );

        useEffect(() => {
            if (files?.length > 0 && showPreviewFirstFile) {
                setPreviewID(files[0].fileId);
            }
        }, [files]);

        const getTableView = () => (
            <div
                style={
                    maxHeight && !printPdf
                        ? {
                              maxHeight: maxHeight,
                              overflowY: 'auto',
                              overflowX: 'hidden',
                          }
                        : {}
                }
            >
                {files && files.length > 0 ? (
                    <Table
                        scroll={getScroll()}
                        size={'small'}
                        dataSource={files}
                        columns={getColumns()}
                        rowClassName="editable-row"
                        pagination={false}
                        sticky
                        style={{ fontWeight: isBoldText ? '500' : undefined }}
                    />
                ) : (
                    <Empty description={geti18nText('app.default.no.files')} />
                )}
            </div>
        );

        const csvColumnCustomisation: any = () => {
            return [
                {
                    active: (value: any) => {
                        return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
                    },
                },
                {
                    uploaded: (value: any) => {
                        return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
                    },
                },
                {
                    person: (value: any) => {
                        return value?.firstName + ' ' + value?.lastName;
                    },
                },
            ];
        };

        const csvColumns: any = () => {
            return [
                {
                    title: geti18nText('fileList.table.column.file.name'),
                    dataIndex: 'name',
                },
                {
                    title: geti18nText('fileList.table.column.description'),
                    dataIndex: 'description',
                },
                {
                    title: geti18nText('fileList.table.column.uploaded'),
                    dataIndex: 'uploaded',
                },
                {
                    title: geti18nText('fileList.table.column.user.name'),
                    dataIndex: 'person',
                },
            ];
        };

        const getScroll = () => {
            let y = maxHeight && !printPdf ? maxHeight.replace("'", '').replace('px', '') : 680;
            return { y: y, x: customFilesTableWidth };
        };

        const table = useTableSettings();

        const getCustomViewTable = () => (
            <div
                style={
                    maxHeight && !printPdf
                        ? {
                              maxHeight: maxHeight,
                              overflowY: 'auto',
                              overflowX: 'hidden',
                          }
                        : {}
                }
            >
                {url ? (
                    <NyDataTable
                        nyId="ny-file-upload-custom"
                        url={url}
                        buttonsClassName="ny-travel-warrant-buttons"
                        showRecordModal={true}
                        fetchWhenChange={refreshFiles}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        setDefaultFilterValue={() => table.setDefaultFilterValue(defaultFilesFilter)}
                        columns={getColumns()}
                        shortcuts={true}
                        hideButtons={true}
                        CSVFileName={CSVFileName}
                        exportCSV={exportCSV}
                        colCSVCustomization={csvColumnCustomisation()}
                        csvColumns={csvColumns()}
                        scroll={scroll ? scroll : getScroll()}
                    />
                ) : (
                    <Empty description={geti18nText('app.default.no.files')} />
                )}
            </div>
        );

        const uploadPropsListView = {
            multiple: true,
            onRemove: (file: any) => {
                showConfirm(file);
            },
            onDownload: (file: any) => {
                if (file && file.file) {
                    fileDownload(downloadUrl + '/' + file.file.id, null, file.file.name);
                }
            },
            showUploadList: {
                showDownloadIcon: !printPdf,
                downloadIcon: <DownloadOutlined style={{ cursor: 'pointer', fontSize: fontSize }} />,
                showRemoveIcon: canDeleteFile(null) && !printPdf,
                removeIcon: canDeleteFile(null) && !printPdf && <DeleteOutlined style={{ color: 'red' }} />,
            },
            files,
            headers: {
                Authorization: 'Bearer ' + NySession.getUserToken(),
            },
        };

        const getListView = () => (
            <div
                style={
                    maxHeight && !printPdf
                        ? {
                              maxHeight: maxHeight,
                              overflowY: 'auto',
                              overflowX: 'hidden',
                          }
                        : {}
                }
            >
                {files && files.length > 0 ? (
                    <>
                        {showCustomView() ? (
                            <CustomView
                                files={files}
                                type={'default'}
                                items={files}
                                setItems={setFiles}
                                loading={loading}
                                setLoading={setLoading}
                                refreshFiles={refreshFiles}
                                downloadFileCustom={downloadFileCustom}
                                datePadding={datePadding}
                                canDelete={canDeleteFile}
                                onPreview={onPreview}
                                removeFilesCustomView={removeFilesCustomView}
                                fontSize={fontSize}
                                printPdf={printPdf}
                            />
                        ) : (
                            <Upload
                                listType={listType}
                                fileList={files}
                                {...uploadPropsListView}
                                onPreview={onPreview}
                                className={uploadClassName}
                            />
                        )}
                    </>
                ) : (
                    <Empty description={geti18nText('app.default.no.files')} />
                )}
            </div>
        );

        const getTravelWarrantView = () => (
            <div
                style={
                    maxHeight && !printPdf
                        ? {
                              maxHeight: maxHeight,
                              overflowY: 'auto',
                              overflowX: 'hidden',
                          }
                        : {}
                }
            >
                <CustomView
                    files={files}
                    type={'undefinedExpense'}
                    items={undefinedExpense}
                    setItems={setUndefinedExpense}
                    loading={loading}
                    setLoading={setLoading}
                    refreshFiles={refreshFiles}
                    downloadFileCustom={downloadFileCustom}
                    datePadding={datePadding}
                    canDelete={canDeleteFile}
                    onPreview={onPreview}
                    removeFilesCustomView={removeFilesCustomView}
                    fontSize={fontSize}
                    printPdf={printPdf}
                />
                <CustomView
                    files={files}
                    type={'listNamedExpense'}
                    items={listNamedExpense}
                    setItems={setListNamedExpense}
                    loading={loading}
                    setLoading={setLoading}
                    refreshFiles={refreshFiles}
                    downloadFileCustom={downloadFileCustom}
                    datePadding={datePadding}
                    canDelete={canDeleteFile}
                    onPreview={onPreview}
                    dividerTitle={geti18nText('travelWarrantOrganization.file.list.column.payment.other')}
                    removeFilesCustomView={removeFilesCustomView}
                    fontSize={fontSize}
                    printPdf={printPdf}
                />

                <CustomView
                    files={files}
                    type={'listDefaultExpense'}
                    items={listDefaultExpense}
                    setItems={setDefaultExpense}
                    loading={loading}
                    setLoading={setLoading}
                    refreshFiles={refreshFiles}
                    downloadFileCustom={downloadFileCustom}
                    datePadding={datePadding}
                    canDelete={canDeleteFile} //canDeleteORganization
                    onPreview={onPreview}
                    dividerTitle={geti18nText('travelWarrantOrganization.file.list.column.payment.default')}
                    removeFilesCustomView={removeFilesCustomView}
                    fontSize={fontSize}
                    printPdf={printPdf}
                />
            </div>
        );

        const { confirm } = Modal;

        const showConfirm = (file: any) => {
            confirm({
                title: geti18nText('app.default.delete.file.confirm'),
                icon: <ExclamationCircleOutlined />,
                onOk() {
                    removeFile(file);
                },
                okText: geti18nText('app.default.button.yes'),
                cancelText: geti18nText('app.default.button.no'),
                className: 'modal-borderless',
            });
        };

        return (
            <>
                <Row gutter={24} style={{ marginBottom: '10px' }}>
                    <Col span={24}>
                        {showHeader && <Divider>{geti18nText('app.default.files')}</Divider>}
                        {loading ? (
                            <NySpinner />
                        ) : (
                            <>
                                {files?.length == 0 ? (
                                    <>
                                        {printPdf ? (
                                            <div style={{ textAlign: 'center' }}>
                                                {geti18nText('app.default.nodata')}
                                            </div>
                                        ) : (
                                            <Empty />
                                        )}
                                    </>
                                ) : (
                                    <div>
                                        {getSwitch()}
                                        {type === 'table' || printPdf ? (
                                            getTableView()
                                        ) : isTravelWarrantEdit ? (
                                            getTravelWarrantView()
                                        ) : type === 'list' ? (
                                            getListView()
                                        ) : type === 'nyDataTable' ? (
                                            getCustomViewTable()
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                )}
                                <>
                                    {showAddNewFile() && (
                                        <Button
                                            style={{ marginTop: '10px', float: 'right' }}
                                            type="primary"
                                            icon={<UploadOutlined />}
                                            onClick={() => setEditModalVisible(true)}
                                        >
                                            {customUploadButtonTitle ?? geti18nText('webshopServices.addFile')}
                                        </Button>
                                    )}
                                    {editModalVisible && (
                                        <FileEdit
                                            title={editTitle}
                                            okText={editOkText}
                                            deleteText={editDeleteText}
                                            width={editWidth}
                                            visible={editModalVisible}
                                            setVisible={setEditModalVisible}
                                            file={selectedFile}
                                            setFile={setSelectedFile}
                                            files={files}
                                            setFiles={setFiles}
                                            note={note}
                                            accept={accept}
                                            requiredAllFields={requiredAllFields}
                                            listType={editListType}
                                            refId={refId}
                                            uploadFile={uploadFile}
                                            updateFile={updateFile}
                                            removeFile={removeFile}
                                            canDelete={canDeleteFile}
                                            hasNoRefId={hasNoRefId}
                                            customUploadButton={customUploadButton}
                                            customUploadButtonTitle={customUploadButtonTitle}
                                            uploadType={uploadType}
                                            setUploadType={setUploadType}
                                            employee={employee}
                                            allowedFileSize={allowedFileSize}
                                            fontSize={fontSize}
                                            isOrganizationModal={isOrganizationModal}
                                            travelWarrantAdditionalData={travelWarrantAdditionalData}
                                            isBoldText={isBoldText}
                                        />
                                    )}
                                </>

                                <Image
                                    width={200}
                                    style={{ display: 'none' }}
                                    preview={{
                                        visible: previewVisible,
                                        src: previewImage,
                                        onVisibleChange: (value) => {
                                            setPreviewVisible(value);
                                        },
                                    }}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </>
        );
    }
);

export default NyFilesUpload;
