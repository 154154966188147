import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Dropdown, Menu } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import NyErrorLogModal from '../../../../components/error-log-modal';
import ImportCSVModal from '../../../../components/import-csv-modal';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getColorFromNotificationType, getEnumArray, getEnumBooleanArray } from '../../../../utils/Utils';
import ItemEdit from './edit';

const ItemIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSortOrder,
    setDefaultSelectedRowKeys,
    type = 0,
    itemGroupType = null,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    defaultFilterValue,
}: any) => {
    const table = useTableSettings();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [errorLogModalData, setErrorLogModalData] = useState(undefined);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const [modalVisible, setModalVisible] = useState<any>(false);

    const { settings } = useSelector((state: RootState) => state.notification);

    const tabColor = () => {
        switch (type) {
            case 0:
                //item
                return undefined;
            case 2:
                //item-service
                return getColorFromNotificationType(settings, 'SERVICES_ORDER');
            case 8:
                //item-material
                return getColorFromNotificationType(settings, 'INVENTORY_ORDER');
            case 4:
                //item-asset
                return getColorFromNotificationType(settings, 'ASSET_ORDER');
            default:
                return undefined;
        }
    };

    useHelper('common/item');

    const canCreate = () => {
        switch (type) {
            case 0:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
            case 2:
                //item-service
                return ServicesRights.canCreateCodebooks();
            case 8:
                //item-material
                return InventoryRights.canCreateCodebooks();
            case 4:
                //item-asset
                return AssetRights.canCreateCodebooks();
            default:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
        }
    };

    const canExportCSV = () => {
        switch (type) {
            case 0:
                //item
                return AdministrationRights.canCsvExportAdministrationCodebook();
            case 2:
                //item-service
                return ServicesRights.canCsvExportCodebooks();
            case 8:
                //item-material
                return InventoryRights.canCsvExportCodebooks();
            case 4:
                //item-asset
                return AssetRights.canCsvExportCodebooks();
            default:
                //item
                return AdministrationRights.canCsvExportAdministrationCodebook();
        }
    };

    const setCsvName = () => {
        switch (type) {
            case 0:
                return geti18nText('menu.item');
            case 2:
                return geti18nText('item.csv.services');
            case 8:
                return geti18nText('item.csv.material');
            case 4:
                return geti18nText('item.csv.asset');
            default:
                return geti18nText('menu.item');
        }
    };

    const setDefaultFilterValue = () => {
        if (defaultFilterValue) {
            return defaultFilterValue;
        } else if (type !== 0) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
                { field: 'type', condition: 'equals', value: type },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'availabilityStatus', condition: 'equals', value: 1 },
            ];
        }
    };

    const initialColumns = [
        {
            title: geti18nText('item.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('item.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.type'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.ITEM_TYPE.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('ITEM_TYPE')),
        },
        {
            title: geti18nText('item.table.column.itemGroup'),
            dataIndex: ['itemGroup', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ITEM_GROUP.SEARCH, 'itemGroup.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('item.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            render: (text: any, record: any) => {
                if (record.availabilityStatus !== undefined) {
                    return geti18nText('app.enum.ITEM_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('ITEM_AVAILABILITY_STATUS'), 'in', [1].toString()),
        },
        {
            title: geti18nText('item.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const modifiedColumns = [
        {
            title: geti18nText('item.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('item.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.itemGroup'),
            dataIndex: ['itemGroup', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ITEM_GROUP.SEARCH, 'itemGroup.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('item.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            render: (text: any, record: any) => {
                if (record.availabilityStatus !== undefined) {
                    return geti18nText('app.enum.ITEM_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('ITEM_AVAILABILITY_STATUS'), 'in', [1].toString()),
        },

        {
            title: geti18nText('item.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const assetColumns = [
        {
            title: geti18nText('item.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('item.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('item.table.column.itemGroup'),
            dataIndex: ['itemGroup', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ITEM_GROUP.SEARCH, 'itemGroup.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('item.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            render: (text: any, record: any) => {
                if (record.availabilityStatus !== undefined) {
                    return geti18nText('app.enum.ITEM_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('ITEM_AVAILABILITY_STATUS'), 'in', [1].toString()),
        },

        {
            title: geti18nText('item.table.column.mandatoryReceipt'),
            dataIndex: 'mandatoryReceipt',
            width: '10%',
            render: (text: any, record: { mandatoryReceipt: any }) => {
                if (record.mandatoryReceipt) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
        },

        {
            title: geti18nText('item.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const data = new FormData();
        data.append('type', type);
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <ImportCSVModal
                            url={CONSTANTS_REQ.ITEM.IMPORT_CSV}
                            onSave={onSave}
                            disabled={loading}
                            data={data}
                            showUpdate={false}
                            customInfoMessage={geti18nText('itemImportCSV.upload.info')}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px', marginRight: '10px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="items-table"
                url={CONSTANTS_REQ.ITEM.LIST}
                addNewButtonText={geti18nText('item.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={ItemEdit}
                columns={type == 0 ? initialColumns : type == 4 ? assetColumns : modifiedColumns}
                scroll={scroll}
                setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                shortcuts={true}
                hideNewButton={!canCreate()}
                addedButtons={canCreate() || !disabled ? addedButtons : undefined}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                onRowSelected={() => setModalVisible(true)}
                addedData={{ type: type, itemGroupType: itemGroupType }}
                exportCSV={canExportCSV()}
                CSVFileName={setCsvName()}
                colCSVCustomization={csvColumnCustomisation()}
                hideButtons={disabled}
                readonly={disabled}
                headerTitle={setCsvName()}
            />
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ITEM_TYPE.' + value);
            },
        },
        {
            availabilityStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ItemIndex;
