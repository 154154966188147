import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useEnum from '../../../../hooks/useEnum';
import Table from './Table';

const TaskSettings = (props: any) => {
    const location = useLocation();
    const taskTypeEnum = useEnum('TASK_TYPE');

    const [editingKey, setEditingKey] = useState<any>('');

    return (
        <>
            {editingKey == '' ? (
                <>
                    <div />
                    <Table setEditingKey={setEditingKey} editingKey={editingKey} type={taskTypeEnum.SERVICE} />
                </>
            ) : (
                <>
                    <Table setEditingKey={setEditingKey} editingKey={editingKey} type={taskTypeEnum.SERVICE} />
                    <div />
                </>
            )}
            {editingKey == '' ? (
                <>
                    <div />
                    <Table setEditingKey={setEditingKey} editingKey={editingKey} type={taskTypeEnum.SUBTASK} />
                </>
            ) : (
                <>
                    <Table setEditingKey={setEditingKey} editingKey={editingKey} type={taskTypeEnum.SUBTASK} />
                    <div />
                </>
            )}
        </>
    );
};

export default TaskSettings;
