import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../../hooks/useHelper';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { InventoryRights } from '../../../../../rights/inventoryRights';
import { RootState } from '../../../../../rootReducer';
import { getColorFromNotificationType, userIsNotificationRecipient } from '../../../../../utils/Utils';
import InventoryOrderApproval from './InventoryOrderApproval';
import InventoryOrderTable from './InventoryOrderTable';

const { TabPane } = Tabs;

const InventoryOrderIndex = () => {
    const location = useLocation();

    const { settings } = useSelector((state: RootState) => state.notification);
    const { employee } = useSelector((state: RootState) => state.employee);

    useHelper('inventory/inventory_order');

    return (
        <Tabs type="card">
            {InventoryRights.canViewOrder() && (
                <TabPane tab={geti18nText('servicesOrder.tab.1')} key="1">
                    <InventoryOrderTable scroll={{ y: 540, x: 800 }} />
                </TabPane>
            )}
            {(AdministrationRights.isAdmin() || userIsNotificationRecipient(employee, 7)) && (
                <TabPane tab={geti18nText('servicesOrder.tab.2')} key="2">
                    <InventoryOrderApproval scroll={{ y: 583, x: 800 }} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default InventoryOrderIndex;
