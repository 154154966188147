import { geti18nText, NyDataEdit, NyDatePicker, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import NyImageUpload from '../../../../../../utils/ny-file-upload-image';
import { getDateFormat, setDateFormat, warningNotification } from '../../../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeEmploymentInjuryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.employmentInjury.edit.new'));
    const [loading, setLoading] = useState(false);
    const [healthInstApproved, setHealthInstApproved] = useState<any>(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [files, setFiles] = useState<any>([]);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const uploadFilesRef = useRef<any>(null);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        setEditHeader(
            geti18nText('employee.employmentInjury.edit.header') +
                ' - ' +
                getDateFormat(dataForm.injuryTime, 'DD.MM.YYYY. HH:mm')
        );
        if (dataForm.injuryTime) {
            dataForm.injuryTime = setDateFormat(dataForm.injuryTime);
        }
        setHealthInstApproved(dataForm.healthInstApproved == true ? true : false);

        if (dataForm.fileId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.FILES.INFO + '/' + dataForm.fileId, undefined, undefined).then(
                (result: any) => {
                    if (result && result.status == RESPONSE.OK && result.data) {
                        let filesImage = [
                            {
                                id: dataForm.fileId,
                                uid: dataForm.fileId,
                                name: result.data.name,
                                url:
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    dataForm.fileId +
                                    '?lastmod=' +
                                    new Date().valueOf() +
                                    '&tenant=' +
                                    NySession.getUser().tenantId,
                            },
                        ];
                        setFiles(filesImage);
                    }
                }
            );
        }

        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setFiles([]);
        setEditHeader(geti18nText('employee.employmentInjury.edit.new'));
    };

    const checkBeforeCancel = () => {
        if (files?.length > 0 && files[0]?.id != form.getFieldValue(['fileId'])) {
            warningNotification(geti18nText('travelWarrantWizard.step2.date.save'));
            return false;
        } else {
            return true;
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_EMPLOYMENT_INJURY.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            onSaveAndGetID={(id: any) => {
                if (uploadFilesRef?.current) {
                    uploadFilesRef.current.filesUpload(id);
                }
            }}
            normalize={(values: any) => {
                if (values.injuryTime) values.injuryTime = getDateFormat(values.injuryTime);
                values.employee = { id: props?.editProps?.employeeId };
                values.healthInstApproved = healthInstApproved;

                if (files.length > 0 && files[0]) {
                    values.fileId = files[0].id;
                }
                return values;
            }}
            shortcuts={true}
            nyTestId="employee-employment-injury-edit"
            isModal={true}
            checkBeforeCancel={checkBeforeCancel}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="fileId" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.employmentInjury.table.column.injuryTime')}
                        name="injuryTime"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker format="DD.MM.YYYY. HH:mm" style={{ width: '100%' }} showTime />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('employee.employmentInjury.table.column.location')}
                        codebooksType={enCodebookType.INJURY_LOCATION}
                        formItemName={'location'}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('employee.employmentInjury.table.column.severity')}
                        codebooksType={enCodebookType.INJURY_SEVERITY}
                        formItemName={'severity'}
                    />
                </Col>
                {/* <Col span={12}>
                    <Form.Item label={geti18nText('employee.employmentInjury.edit.days')} name="days">
                        <NyInputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col> treba provjeriti s Mariom */}
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.employmentInjury.table.column.healthInstApproved')}
                        name="healthInstApproved"
                    >
                        <Checkbox
                            checked={healthInstApproved}
                            onChange={(val: any) => {
                                setHealthInstApproved(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('employee.employmentInjury.edit.description')} name="description">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider>{geti18nText('employee.employmentInjury.table.column.fileID')}</Divider>
            <Form.Item name={'fileId'}>
                <NyImageUpload
                    files={files}
                    setFiles={setFiles}
                    saveOnUpload={true}
                    accept={'*'}
                    cropShape={null}
                    listType={'text'}
                    canDeleteFile={!HumanResourcesRights.canViewEDRModal()}
                    canUpload={!HumanResourcesRights.canViewEDRModal()}
                />
            </Form.Item>
        </NyDataEdit>
    );
};

export default EmployeeEmploymentInjuryEdit;
