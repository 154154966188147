import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Divider, Form, Input, Modal, Radio, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import NyReportButton from '../../../../components/report-button';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat } from '../../../../utils/Utils';
import useEnum from '../../../../hooks/useEnum';

const { Paragraph, Text } = Typography;

const EmployeeDeleveragingReportModal = ({ employeeId }: any) => {
    const [modalForm] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [itemData, setItemData] = useState<any>(null);
    const [itemId, setItemId] = useState<any>(undefined);
    const [name, setName] = useState<any>('');
    const [vocation, setVocation] = useState<any>('');
    const [businessUnit, setBusinessUnit] = useState<any>('');
    const [showDateFrom, setShowDateFrom] = useState<any>(true);
    const [showDateTo, setShowDateTo] = useState<any>(false);
    const [dateFromString, setDateFromString] = useState<any>('');
    const [showOtherReason, setShowOtherReason] = useState<any>(false);

    const [reason, setReason] = useState<any>(1);
    const [dateFrom, setDateFrom] = useState<any>(undefined);
    const [dateTo, setDateTo] = useState<any>(undefined);
    const [otherReason, setOtherReason] = useState<any>(undefined);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const openReportModal = () => {
        if (itemId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + itemId, undefined).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result && result.data) {
                        setItemData(result.data);
                        setFields(result.data);
                        initFormFields();
                        setModalVisible(true);
                    }
                }
            });
        }
    };

    const setFields = (employeeData: any) => {
        if (
            employeeData &&
            employeeData.id &&
            employeeData.person &&
            employeeData.person.firstName &&
            employeeData.person.lastName
        ) {
            setName(employeeData.id + ' ' + employeeData.person.firstName + ' ' + employeeData.person.lastName);
        }
        if (employeeData && employeeData.vocation && employeeData.vocation.name) {
            setVocation(employeeData.vocation.name);
        }
        if (
            employeeData &&
            employeeData.businessUnit &&
            employeeData.businessUnit.code &&
            employeeData.businessUnit.name
        ) {
            setBusinessUnit('(' + employeeData.businessUnit.code + ') ' + employeeData.businessUnit.name);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setItemData(undefined);
        initFormFields();
    };

    const initFormFields = () => {
        modalForm.resetFields();
        let dataForm: any = {};
        dataForm.reason = 1;
        dataForm.dateFrom = moment();
        dataForm.dateTo = moment();
        modalForm.setFieldsValue(dataForm);

        setReason(1);
        setDateFrom(moment());
        setDateTo(moment());
        setOtherReason(undefined);

        setShowOtherReason(false);
        setShowDateTo(false);
        setDateFromString(geti18nText('employee.edit.date'));
    };

    const onChangeReason = (selectedValue: any) => {
        setReason(selectedValue.target.value);
        switch (selectedValue.target.value) {
            case 1:
            case 3:
                setShowOtherReason(false);
                setShowDateTo(false);
                setDateFromString(geti18nText('employee.edit.date'));
                break;
            case 4:
                setShowOtherReason(true);
                setShowDateTo(false);
                setDateFromString(geti18nText('employee.edit.date'));
                break;
            case 2:
                setShowOtherReason(false);
                setShowDateTo(true);
                setDateFromString(geti18nText('employee.edit.dateFrom'));
                break;
            default:
                setShowOtherReason(false);
                setShowDateTo(false);
                setDateFromString(geti18nText('employee.edit.date'));
                break;
        }
    };

    useEffect(
        function () {
            if (employeeId !== undefined && employeeId != null) {
                setItemId(employeeId);
            }
        },
        [employeeId]
    );

    return (
        <React.Fragment>
            <Button onClick={openReportModal} icon={<FilePdfOutlined />} type="primary">
                {geti18nText('employee.edit.generateReport')}
            </Button>
            {modalVisible && (
                <Modal
                    width={550}
                    centered={true}
                    maskClosable={false}
                    visible={modalVisible}
                    onCancel={closeModal}
                    title={geti18nText('employee.edit.generateReport')}
                    footer={[
                        <NyReportButton
                            url={CONSTANTS_REQ.REPORT.EMPLOYEE_DELEVERAGING}
                            customParms={{
                                dateTo: getDateFormat(dateTo),
                                dateFrom: getDateFormat(dateFrom),
                                reasonId: reason,
                                otherReason: otherReason,
                                employeeId: itemId,
                            }}
                            subreportType={'EMPLOYEE_DELEVERAGING'}
                            buttoni18nText={'employee.edit.generateReport'}
                            fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.EMPLOYEE_DELEVERAGING)}
                            checkBeforeSave={false}
                        />,
                    ]}
                >
                    <Form form={modalForm} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Divider>{geti18nText('employee.divider.1')}</Divider>
                        <Row>
                            <Paragraph>
                                <Text strong>{geti18nText('employee.edit.generateReport.name')}: </Text> {name}
                            </Paragraph>
                        </Row>
                        <Row>
                            <Paragraph>
                                <Text strong>{geti18nText('employee.edit.generateReport.vocation')}: </Text> {vocation}
                            </Paragraph>
                        </Row>
                        <Row>
                            <Paragraph>
                                <Text strong>{geti18nText('employee.edit.generateReport.businesUnit')}: </Text>{' '}
                                {businessUnit}
                            </Paragraph>
                        </Row>
                        <Divider>{geti18nText('employee.divider.2')}</Divider>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employee.divider.2')}
                                    name={'reason'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeReason} value={reason}>
                                        <Space direction="vertical">
                                            <Radio value={1}>
                                                {geti18nText('employee.edit.generateReport.option.1')}
                                            </Radio>
                                            <Radio value={2}>
                                                {geti18nText('employee.edit.generateReport.option.2')}
                                            </Radio>
                                            <Radio value={3}>
                                                {geti18nText('employee.edit.generateReport.option.3')}
                                            </Radio>
                                            <Radio value={4}>
                                                {geti18nText('employee.edit.generateReport.option.4')}
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Row>
                                    {showDateFrom && (
                                        <Form.Item
                                            label={dateFromString}
                                            name={'dateFrom'}
                                            rules={[
                                                {
                                                    required: showDateFrom,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                nyTestId="dateFrom"
                                                onChange={(value: any) => {
                                                    setDateFrom(value && getDateFormat(value));
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                </Row>
                                <Row>
                                    {showDateTo && (
                                        <Form.Item
                                            label={geti18nText('employee.edit.dateTo')}
                                            name={'dateTo'}
                                            rules={[
                                                {
                                                    required: showDateTo,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                nyTestId="dateTo"
                                                onChange={(value: any) => {
                                                    setDateTo(value && getDateFormat(value));
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={24} style={{ height: '75px' }}>
                            <Col span={24}>
                                {showOtherReason && (
                                    <Form.Item
                                        label={geti18nText('employee.edit.reason')}
                                        name={'otherReason'}
                                        rules={[
                                            {
                                                required: showOtherReason,
                                                message: geti18nText('app.default.required'),
                                                whitespace: showOtherReason,
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                            onChange={(value: any) => {
                                                setOtherReason(value.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </React.Fragment>
    );
};
export default EmployeeDeleveragingReportModal;
