import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { formatJoppdCodeSearch, setJoppdCodeDefaultFilterValue } from '../../../../utils/Utils';

const TownshipEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('township.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [allowDistrict, setAllowDistrict] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.joppdCode) {
            dataForm.joppdCode = await formatJoppdCodeSearch(dataForm.joppdCode, 2, '2.');
        }
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('township.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.allowDistrict) {
            setAllowDistrict(dataForm.allowDistrict);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('township.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const setDefaultFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '2.');
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TOWNSHIP.EDIT}
            setValues={setValues}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                if (values.joppdCode) values.joppdCode = values.joppdCode.code;
                values.allowDistrict = allowDistrict;
                return values;
            }}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={16}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('township.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('township.edit.taxCode')}
                        name="taxCode"
                        rules={[
                            {
                                validator: async (_, taxCode) => {
                                    if (taxCode && taxCode != '' && taxCode.length != 3) {
                                        return Promise.reject(
                                            new Error(geti18nText('township.edit.taxCode.not.valid'))
                                        );
                                    }
                                },
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('township.edit.regosCode')} name="regosCode">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('township.edit.joppdCode')} name="joppdCode">
                        <NySearchField
                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                            setDefaultFilterValue={setDefaultFilterValue}
                            map={{ id: 'id', label: 'code' }}
                            searchBy="code"
                            nyTestId="joppdCode"
                            SearchPopupComponent={<JoppdCodeIndex defaultFilteredValue={setDefaultFilterValue} />}
                            itemName="code"
                            addNewItem={true}
                            order="code"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('township.table.column.allowDistrict')} name="allowDistrict">
                        <Checkbox
                            checked={allowDistrict}
                            onChange={(e: any) => {
                                setAllowDistrict(e.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default TownshipEdit;
