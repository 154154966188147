import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import NyDescription from '../../../../../components/ny-description';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByHumanCodebookSimple } from '../../../../../utils/Filters';
import EmployeeEvaluationCompetencyTemplateEdit from './edit';

const EmployeeEvaluationCompetencyTemplateIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('human/codebooks/employee-evaluation-competency-template');
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const canExportCSV = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const canCreate = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const columns = [
        {
            title: geti18nText('evaluation.competency.template.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('evaluation.competency.template.table.column.type'),
            dataIndex: ['type', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(
                enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE,
                'type.id',
                undefined,
                false
            ),
            render: (text: any, record: any) => {
                if (record.type) {
                    return record.type.name;
                }
            },
        },
        {
            title: geti18nText('evaluation.competency.template.table.column.description'),
            dataIndex: 'description',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record?.description) {
                    return <NyDescription title={record?.description} maxWidth={'300px'} />;
                }
            },
        },
        {
            title: geti18nText('evaluation.competency.template.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('evaluation.competency.template.table.column.dateTo'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('evaluation.competency.template.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-evaluation-competency-template-table"
            url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_COMPETENCY_TEMPLATE.LIST}
            addNewButtonText={geti18nText('evaluation.competency.template.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            columns={columns}
            modalComponent={EmployeeEvaluationCompetencyTemplateEdit}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.evaluation.competency.template')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.evaluation.competency.template')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value?.name ? value?.name + (value?.code ? ' (' + value.code + ') ' : '') : '';
            },
        },
        {
            dateFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default EmployeeEvaluationCompetencyTemplateIndex;
