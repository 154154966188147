import {
    CarOutlined,
    CloudDownloadOutlined,
    EllipsisOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import {
    geti18nText,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Dropdown, Form, Input, Menu, Row, Select, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import NyReportButton from '../../../../components/report-button';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import {
    onKeyDown,
    setJoppdCodeDefaultFilterValue,
    fileDownload,
    getDateFormat,
    okNotification,
    errorNotification,
    getEnumArray,
} from '../../../../utils/Utils';
import GenerateJoppdTravelWarrantsModal from './GenerateJoppdTravelWarrantsModal';
import { PayrollRights } from '../../../../rights/payrollRights';

const { Panel } = Collapse;

const PageA = ({
    id,
    headerData,
    headerDataDefinition,
    headerDataDefinitionGrouped,
    setDirty,
    dirty,
    deactivateJoppd,
    joppdName = '',
    joppdActive,
    fetching,
    setFetching,
    fetch,
}: any) => {
    const [formA] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [changedData, setChangedData] = useState({});
    const [showTooltip, setShowTooltip] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>([]);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [modalGenerateJoppdTravelWarrantsVisible, setModalGenerateJoppdTravelWarrantsVisible] = useState<any>(false);
    const [modalUrl, setModalUrl] = useState<any>(CONSTANTS_REQ.JOPPD.GENERATE_JOPPD_TRAVEL_WARRANT);
    const [modalTitle, setModalTitle] = useState<any>(geti18nText('joppd.generateFromTravelWarrants.title'));
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useEffect(() => {
        // setValues();

        //Enter event
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    useEffect(() => {
        if (headerData) {
            setValues();
        }
    }, [headerData]);

    const canCreate = () => {
        return PayrollRights.canCreateJoppd();
    };

    const setValues = () => {
        if (headerData) {
            let values = { ...headerData };

            if (id === 'create') {
                values.DatumIzvjesca = moment().format('YYYY-MM-DD').toString();
            }

            Object.entries(headerDataDefinition).forEach(([k, v]: any) => {
                if (v.tag.split('.')) {
                    if (v.dataType === 8) {
                        values = _.set(values, v.tag.split('.'), {
                            id: -1,
                            code: _.get(values, v.tag.split('.')),
                        });
                    }

                    if (v.dataType === 4 || v.dataType === 5 || v.dataType === 6) {
                        values = _.set(values, v.tag.split('.'), moment(_.get(values, v.tag.split('.')), v.pattern));
                    }

                    if (v.defaultValue) {
                        if (v.dataType === 8 && _.get(values, v.tag.split('.')).code === undefined) {
                            values = _.set(values, v.tag.split('.'), {
                                id: -1,
                                code: v.defaultValue,
                            });
                        }

                        if (
                            (v.dataType === 4 || v.dataType === 5 || v.dataType === 6) &&
                            _.get(values, v.tag.split('.')) === undefined
                        ) {
                            values = _.set(values, v.tag.split('.'), moment(v.defaultValue, v.pattern));
                        }
                    }
                }
            });

            formA.setFieldsValue(values);
        }
        setFetching(false);
    };

    const setDefaultFilterValue = (node: string) => {
        return setJoppdCodeDefaultFilterValue(1, node);
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, headerData);
        setDirty(isChanged);
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '2']);
            setRightCollapseActiveKeys(['3', '4', '5']);
        }

        setCollapseAll(!collapseAll);
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        block
                        icon={<CloudDownloadOutlined />}
                        onClick={() => {
                            fileDownload(
                                CONSTANTS_REQ.JOPPD.XML_DETAILS_DOWNLOAD,
                                { id: id },
                                joppdName ? `${joppdName}.xml` : `JOPPD_${moment().format('yyyyMMDD')}.xml`
                            );
                        }}
                    >
                        {geti18nText('joppd.actions.xmlDownload')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyReportButton
                        url={CONSTANTS_REQ.JOPPD.GENERATE_JOPPD_REPORT}
                        customParms={{ id: id }}
                        buttoni18nText={'app.default.button.download.pdf'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.JOPPD_A)}
                    />
                </div>
                {canCreate() && (
                    <>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                block
                                icon={<CarOutlined />}
                                onClick={() => {
                                    setModalUrl(CONSTANTS_REQ.JOPPD.GENERATE_JOPPD_TRAVEL_WARRANT);
                                    setModalTitle(geti18nText('joppd.generateFromTravelWarrants.title'));
                                    setModalGenerateJoppdTravelWarrantsVisible(true);
                                }}
                            >
                                {geti18nText('joppd.actions.generateFromTravelWarrants')}
                            </Button>
                        </div>
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button
                                block
                                icon={<CarOutlined />}
                                onClick={() => {
                                    setModalUrl(CONSTANTS_REQ.JOPPD.GENERATE_JOPPD_EMPLOYEE_TRAVEL_RELATION_PAYMENT);
                                    setModalTitle(geti18nText('joppd.generateFromEmployeeTravelRelationPayment.title'));
                                    setModalGenerateJoppdTravelWarrantsVisible(true);
                                }}
                            >
                                {geti18nText('joppd.actions.generateFromEmployeeTravelRelationPayment')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            id="page-a-eye-button"
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            id="page-a-no-eye-button"
                        />
                    )}
                </Tooltip>
            </div>
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']} disabled={id === 'create'}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        </>
    );

    const savePageA = (event: any) => {
        event.preventDefault();
        formA
            .validateFields()
            .then((values: any) => {
                let dataForm: any = { ...values };

                Object.entries(dataForm).forEach(([k, v]: any) => {
                    if (k === 'DatumIzvjesca') {
                        if (moment.isMoment(v)) {
                            dataForm[k] = getDateFormat(v, 'yyyy-MM-DD');
                        }
                    }

                    if (k === 'OznakaIzvjesca') {
                        if (moment.isMoment(v)) {
                            dataForm[k] = getDateFormat(v, 'YYDDD');
                        }
                    }
                });

                Object.entries(headerDataDefinition).forEach(([k, v]: any) => {
                    if (v.tag.split('.')) {
                        if (v.dataType === 8) {
                            if (_.get(dataForm, v.tag.split('.')) !== undefined) {
                                dataForm = _.set(dataForm, v.tag.split('.'), _.get(dataForm, v.tag.split('.')).code);
                            }
                        }
                    }
                });

                if (id !== 'create') {
                    NyRequestResolver.requestPut(CONSTANTS_REQ.JOPPD.XML_DETAILS_HEADER_SAVE + '/' + id, undefined, {
                        ...dataForm,
                    }).then((result: any) => {
                        if (setLoading) setLoading(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            if (setLoading) setLoading(false);
                            okNotification();
                            setDirty(false);
                            // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: dirty }));
                        } else {
                            if (result && result.status === RESPONSE.BAD_REQUEST) {
                                if (result.data && result.data.field) {
                                    switch (result.data.field) {
                                        case 'username_exist':
                                            errorNotification(geti18nText('app.default.username_exist'));
                                            return;
                                        case 'mandatory_fields_required':
                                            errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                            return;
                                        default:
                                            break;
                                    }
                                }
                            }
                            if (result.data && result.data.error) {
                                if (geti18nText(result.data.error) != '') {
                                    errorNotification(geti18nText(result.data.error));
                                } else {
                                    errorNotification(JSON.stringify(result.data.error));
                                }
                            } else {
                                errorNotification();
                            }
                        }
                    });
                } else {
                    NyRequestResolver.requestPost(CONSTANTS_REQ.JOPPD.EDIT, undefined, {
                        name: dataForm.name,
                        StranaA: { ...dataForm },
                    }).then((result: any) => {
                        if (setLoading) setLoading(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            if (setLoading) setLoading(false);
                            okNotification();
                            setDirty(false);
                            // dispatch(removeTab(location.pathname));
                            // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: dirty }));
                            if (result.data.id !== undefined) {
                                history.push(`/joppd/${result.data.id}`);
                            }
                        } else {
                            if (result.data && result.data.error) {
                                if (geti18nText(result.data.error) != '') {
                                    errorNotification(geti18nText(result.data.error));
                                } else {
                                    errorNotification(JSON.stringify(result.data.error));
                                }
                            } else {
                                errorNotification();
                                console.log(result);
                            }
                        }
                    });
                }
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'APPLICATION_SETTINGS_DATA_TYPE',
            value: item.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return <Input id={item.node} />;
            case 'NUMBER':
                let patternN;
                if (item.pattern) {
                    patternN = JSON.parse(item.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                        nyTestId={item.node}
                    />
                );
            case 'BOOL':
                return <Checkbox id={item.node} />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} nyTestId={item.node} />;
            case 'TIME':
                return (
                    <NyDatePicker mode={'time'} format={item.pattern} style={{ width: '100%' }} nyTestId={item.node} />
                );
            case 'TIMESTAMP':
                return (
                    <NyDatePicker
                        showTime={true}
                        format={item.pattern}
                        style={{ width: '100%' }}
                        nyTestId={item.node}
                    />
                );
            case 'ENUM':
                return (
                    <Select
                        options={getEnumArray(item.sourceType, 'label', 'value')}
                        autoFocus={true}
                        style={{ width: '100%' }}
                        id={item.node}
                    />
                );
            case 'CODEBOOK':
                let temp: string[] = item.sourceType.split('.', 2);
                return (
                    <NySearchField
                        url={CONSTANTS_REQ[temp[0]][temp[1]]}
                        map={{ id: 'id', label: item.pattern }}
                        setDefaultFilterValue={() => setDefaultFilterValue(item.node)}
                        searchBy={item.pattern}
                        itemName={item.pattern}
                        SearchPopupComponent={
                            <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValue(item.node)} />
                        }
                        style={{ width: '100%' }}
                        addNewItem={true}
                        nyTestId={item.node}
                    />
                );
            default:
                return <Input id={item.node} />;
        }
    };

    const generateAPage = () => {
        let itemsLeft: any = [];
        let itemsRight: any = [];

        if (headerDataDefinitionGrouped) {
            for (let key: any = 1; key < 3; key++) {
                if (
                    headerDataDefinitionGrouped &&
                    headerDataDefinitionGrouped[key] &&
                    headerDataDefinitionGrouped[key].length > 0
                ) {
                    itemsLeft.push(
                        <Panel
                            header={headerDataDefinitionGrouped[key][0].joppdNodeGroup.name}
                            key={key}
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                {headerDataDefinitionGrouped[key].map((item: any) => {
                                    return (
                                        <Col span={12}>
                                            <Form.Item
                                                label={item.node + ' ' + item.name}
                                                name={item.tag.split('.')}
                                                rules={[
                                                    {
                                                        required: item.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Panel>
                    );
                }
            }

            for (let key: any = 3; key < 6; key++) {
                if (
                    headerDataDefinitionGrouped &&
                    headerDataDefinitionGrouped[key] &&
                    headerDataDefinitionGrouped[key].length > 0
                ) {
                    itemsRight.push(
                        <Panel
                            header={headerDataDefinitionGrouped[key][0].joppdNodeGroup.name}
                            key={key}
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                {headerDataDefinitionGrouped[key].map((item: any) => {
                                    return (
                                        <Col span={12}>
                                            <Form.Item
                                                label={item.node + ' ' + item.name}
                                                name={item.tag.split('.')}
                                                rules={[
                                                    {
                                                        required: item.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Panel>
                    );
                }
            }
        }

        return (
            <React.Fragment>
                {id === 'create' && (
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('joppd.table.column.name')} name="name">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={leftCollapseActiveKeys}
                            onChange={(key: any) => {
                                setLeftCollapseActiveKeys(key);
                            }}
                        >
                            {itemsLeft}
                        </Collapse>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={rightCollapseActiveKeys}
                            onChange={(key: any) => {
                                setRightCollapseActiveKeys(key);
                            }}
                        >
                            {itemsRight}
                        </Collapse>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Form
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={formA}
                onValuesChange={(value: any) => {
                    checkIsDirty(value);
                }}
                onFinishFailed={({ errorFields }) => {
                    formA.scrollToField(errorFields[0].name);
                }}
            >
                {fetching ? <NySpinner /> : generateAPage()}

                <Row className={'buttons-sticky'}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        {getCustomFooterContent}
                        {id !== 'create' && joppdActive && canCreate() && (
                            <NyModalConfirm
                                title={geti18nText('app.default.destructive.confirm')}
                                onConfirm={() => {
                                    deactivateJoppd(false);
                                }}
                            >
                                <Button style={{ marginRight: '1em' }} key="deactivate" type="primary" danger>
                                    {geti18nText('app.default.button.deactivate')}
                                </Button>
                            </NyModalConfirm>
                        )}
                        {!joppdActive && canCreate() && (
                            <Tooltip
                                placement="top"
                                visible={showTooltip}
                                title={geti18nText('app.default.shortcuts.activate')}
                            >
                                <Button
                                    key="activate"
                                    style={{ backgroundColor: 'green', color: 'white', marginRight: '1em' }}
                                    disabled={loading}
                                    onClick={() => deactivateJoppd(true)}
                                >
                                    {geti18nText('app.default.button.activate')}
                                </Button>
                            </Tooltip>
                        )}
                        {canCreate() && (
                            <Button
                                style={{ marginRight: '1em' }}
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={(e) => savePageA(e)}
                            >
                                {geti18nText('app.default.button.save')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>
            <GenerateJoppdTravelWarrantsModal
                joppdId={Number(id)}
                modalVisible={modalGenerateJoppdTravelWarrantsVisible}
                setModalVisible={setModalGenerateJoppdTravelWarrantsVisible}
                setFetching={setFetching}
                fetch={fetch}
                url={modalUrl}
                title={modalTitle}
            />
        </React.Fragment>
    );
};

export default PageA;
