import { DeleteTwoTone, EditTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyUtils, geti18nText } from '@nybble/nyreact';
import { Button, Form, Space, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { AdministrationRights } from '../../../../rights/administrationRights';
import WarehouseItemModal from './WarehouseItemModal';

const ItemsIndex = ({ bookedStock, items, setItems, setIsItemChange }: any) => {
    const [form] = Form.useForm();
    const [itemTitle, setItemTitle] = useState('');
    const [warehouseReceiptItem, setWarehouseReceiptItem] = useState(null);
    const [itemModalVisible, setItemModalVisible] = useState<boolean>(false);

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks() && !bookedStock;
    };

    const edit = (record: any) => {
        setIsItemChange(true);
        if (record && record.item && record.item.id) {
            if (record.item.name) {
                setItemTitle(record.item.name);
            } else {
                setItemTitle(geti18nText('item.edit.new'));
            }
            setWarehouseReceiptItem(record);
            setItemModalVisible(true);
        }
    };

    const deleteRow = (id: any) => {
        if (id) {
            setIsItemChange(true);
            setItems(items.filter((row: any) => row.item.id !== id));
        }
    };

    return (
        <React.Fragment>
            <Form form={form} component={false}>
                {items && (
                    <Table
                        size={'small'}
                        dataSource={items}
                        rowClassName="editable-row"
                        pagination={false}
                        columns={[
                            {
                                title: geti18nText('warehouseReceipt.table.column.itemId'),
                                dataIndex: ['item', 'id'],
                                width: '5%',
                            },
                            {
                                title: geti18nText('warehouseReceipt.table.column.itemCode'),
                                dataIndex: ['item', 'code'],
                                width: '10%',
                                render: (text: any, record: any) => {
                                    if (record.item != undefined && record.item.code != undefined) {
                                        return record.item.code;
                                    }
                                },
                            },
                            {
                                title: geti18nText('warehouseReceipt.table.column.itemName'),
                                dataIndex: ['item', 'name'],
                                render: (text: any, record: any) => {
                                    if (record.item != undefined && record.item.name != undefined) {
                                        return record.item.name;
                                    }
                                },
                            },
                            {
                                title: geti18nText('warehouseReceipt.table.column.abbreviation'),
                                dataIndex: ['item', 'measureUnit', 'abbreviation'],
                                width: '10%',
                                render: (text: any, record: any) => {
                                    if (
                                        record.item.measureUnit != undefined &&
                                        record.item.measureUnit.name != undefined
                                    ) {
                                        return record.item.measureUnit.name;
                                    }
                                },
                            },
                            {
                                title: geti18nText('warehouseReceipt.table.column.quantity'),
                                dataIndex: 'quantity',
                                align: 'right',
                                width: '10%',
                                render: (text: any, record: any) => {
                                    if (record.quantity != undefined) {
                                        return (
                                            <div style={{ float: 'right' }}>
                                                {NyUtils.formatNumber(record.quantity ?? 0, 0, 12)}
                                            </div>
                                        );
                                    }
                                },
                            },
                            {
                                //netAmount
                                title: geti18nText('warehouseReceipt.table.column.invoicedPrice'),
                                dataIndex: 'invoicedPrice',
                                align: 'right',
                                width: '10%',
                                render: (text: any, record: any) => {
                                    return (
                                        <div style={{ float: 'right' }}>
                                            {NyUtils.formatNumber(record.invoicedPrice ?? 0, 2, 4)}
                                        </div>
                                    );
                                },
                            },
                            {
                                title: geti18nText('warehouseReceipt.table.column.netAmount'),
                                dataIndex: 'netAmount',
                                align: 'right',
                                width: '10%',
                                render: (text: any, record: any) => {
                                    return (
                                        <div style={{ float: 'right' }}>
                                            {NyUtils.formatNumber(record.netAmount ?? 0, 2)}
                                        </div>
                                    );
                                },
                            },
                            {
                                title: geti18nText('settings.shortcuts.action'),
                                key: 'action',
                                width: '5%',
                                render: (text: any, record: any) => {
                                    if (record) {
                                        return (
                                            <React.Fragment>
                                                {canCreate() && (
                                                    <Space size="middle" style={{ textAlign: 'right' }}>
                                                        <Tooltip
                                                            placement="top"
                                                            title={geti18nText('app.default.button.edit')}
                                                        >
                                                            <Button type="link" onClick={() => edit(record)}>
                                                                <EditTwoTone
                                                                    style={{
                                                                        fontSize: '20px',
                                                                    }}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip
                                                            placement="top"
                                                            title={geti18nText('app.default.button.delete')}
                                                        >
                                                            <NyModalConfirm
                                                                title={geti18nText('app.default.delete.confirm')}
                                                                onConfirm={() => {
                                                                    deleteRow(record.item.id);
                                                                }}
                                                            >
                                                                <Button type="link">
                                                                    <DeleteTwoTone
                                                                        style={{
                                                                            fontSize: '20px',
                                                                        }}
                                                                        twoToneColor="#ff7875"
                                                                    />
                                                                </Button>
                                                            </NyModalConfirm>
                                                        </Tooltip>
                                                    </Space>
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                },
                            },
                        ]}
                    />
                )}
            </Form>
            {canCreate() && (
                <div className="ny-order-view-access-settings-buttons">
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => setItemModalVisible(true)}
                        type="primary"
                        style={{ marginLeft: '5px', marginTop: '20px' }}
                    >
                        {geti18nText('item.table.add')}
                    </Button>
                </div>
            )}

            {itemModalVisible && (
                <WarehouseItemModal
                    itemTitle={itemTitle}
                    setItemTitle={setItemTitle}
                    items={items}
                    setItems={setItems}
                    warehouseReceiptItem={warehouseReceiptItem}
                    setWarehouseReceiptItem={setWarehouseReceiptItem}
                    itemModalVisible={itemModalVisible}
                    setItemModalVisible={setItemModalVisible}
                />
            )}
        </React.Fragment>
    );
};

export default ItemsIndex;
