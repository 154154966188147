import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import ContractCategorySearch from '../contract-category/search';

const ContractTypeGroupEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.contractCategory) {
            dataForm.contractCategory = setSearchFormat(dataForm.contractCategory, 'contractCategory');
        }

        if (dataForm.partyTwoCategory) {
            dataForm.partyTwoCategory = setEnumFormat('CONTRACT_PARTY_TWO', dataForm.partyTwoCategory);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={geti18nText('contract.type.group.edit.new')}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CONTRACT_TYPE_GROUP.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.contractCategory) {
                    values.contractCategory = getSearchFormat(values.contractCategory);
                }

                if (values.partyTwoCategory) values.partyTwoCategory = getEnumFormat(values.partyTwoCategory);

                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={geti18nText('contract.type.group.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('contract.type.group.edit.party.two.category')}
                        name="partyTwoCategory"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        // initialValue={setEnumFormat('CONTRACT_PARTY_TWO', 1)}
                    >
                        <NySearchField
                            options={getEnumArray('CONTRACT_PARTY_TWO')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            nyTestId="market-type"
                            className=""
                            //allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <ContractCategorySearch required={false} />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ContractTypeGroupEdit;
