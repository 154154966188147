import { FormOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Col, Divider, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum } from '../../../../utils/Enums';
import { getDateFormat, onKeyDownTextAreaCustom, setDateFormat } from '../../../../utils/Utils';
import BankAccountEdit from '../../../administration/views/bank/BankAccountEdit';
import CompanySearch from '../../../administration/views/company/search';
import CurrencySearch from '../../../administration/views/currency/search';

const { TextArea } = Input;

const EmployeePayrollDeductionEdit = (props: any) => {
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [editHeader, setEditHeader] = useState(geti18nText('employeePayrollDeduction.edit.new'));
    const [loading, setLoading] = useState(false);
    const aTypes = GetEnum({ enumName: 'PAYROLL_DEDUCTION_TYPE' });

    const [employeePayrollDeductionModal, setEmployeePayrollDeductionModal] = useState<any>(false);
    const [hasNote, setHasNote] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<any>();

    const [url, setUrl] = useState<any>(CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.SEARCH);
    const [filter, setFilter] = useState<any>(null);
    const [hiddenEnforcementType, setHiddenEnforcementType] = useState<any>(true);
    const [hiddenCompany, setHiddenCompany] = useState<any>(false);
    const [hiddenRepaymentCount, setHiddenRepaymentCount] = useState<any>(false);

    const [epdTypes, setEpdTypes] = useState([]);
    const [enforcementTypes, setEnforcementTypes] = useState([]);
    const [type, setType] = useState(undefined);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('employeePayrollDeduction.edit.payrollDeduction') + ' - ' + dataForm.id);
        if (dataForm.date) dataForm.date = setDateFormat(dataForm.date);
        if (dataForm.repaymentFrom) dataForm.repaymentFrom = setDateFormat(dataForm.repaymentFrom);
        if (dataForm.repaymentTo) dataForm.repaymentTo = setDateFormat(dataForm.repaymentTo);
        if (dataForm.company) onCompanySelect(dataForm.company);
        if (dataForm.type) {
            setType(getType(dataForm.type));
        }
        if (dataForm.note && dataForm.note.note) {
            setHasNote(true);
        } else {
            setHasNote(false);
        }
        delete dataForm.active;
        dataForm.employee = { id: id };
        form.setFieldsValue(dataForm);
    }

    const openEmployeePayrollDeductionModal = () => (
        <Tooltip title={geti18nText('employeePayrollDeduction.edit.note')}>
            <FormOutlined
                className="ny-note-modal-icon"
                onClick={(event: any) => {
                    setEmployeePayrollDeductionModal(true);
                    event.stopPropagation();
                }}
            />
        </Tooltip>
    );

    useEffect(() => {
        setEpdTypeEnums();
        setEnforcementTypeEnums();
    }, []);

    function setEpdTypeEnums() {
        let types: any = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { value: undefined, label: undefined };
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.PAYROLL_DEDUCTION_TYPE.' + ret.value);
                types.push(ret);
            }
        }
        setEpdTypes(types);
    }

    function setEnforcementTypeEnums() {
        const enforcementTypes = GetEnum({ enumName: 'PAYROLL_ENFORCEMENT_TYPE' });
        let types: any = [];
        for (var key in enforcementTypes) {
            if (enforcementTypes.hasOwnProperty(key)) {
                let ret = { value: undefined, label: undefined };
                ret.value = enforcementTypes[key];
                ret.label = geti18nText('app.enum.PAYROLL_ENFORCEMENT_TYPE.' + ret.value);
                types.push(ret);
            }
        }
        setEnforcementTypes(types);
    }

    const onCompanySelect = (data: any) => {
        if (data && data.id > 0) {
            setSelectedCompanyId(data.id);
            setFilter([{ field: 'companyId', condition: 'equals', value: data.id }]);
        } else {
            setSelectedCompanyId(undefined);
            form.setFieldsValue({ bankAccount: undefined });
        }
    };

    const getType = (data: any) => {
        let typeKey: any = undefined;

        for (var key in aTypes) {
            if (aTypes[key] === data) {
                typeKey = key;
            }
        }
        return typeKey;
    };

    const onTypeChange = (data: any) => {
        form.setFieldsValue({ bankAccount: undefined });
        setType(getType(data));
    };

    useEffect(() => {
        if (type) {
            let tempHiddenCompany = false;
            let tempHiddenRepaymentCount = false;
            let tempHiddenEnforcementType = true;

            let tempUrl = CONSTANTS_REQ.COMPANY_BANK_ACCOUNT.SEARCH;
            let tempFilter: any = [];
            if (type === 'LOAN' || type === 'INSURANCE') {
                tempFilter = [{ field: 'companyId', condition: 'equals', value: selectedCompanyId }];
            } else if (type === 'EXECUTION') {
                tempUrl = CONSTANTS_REQ.EMPLOYEE_BANK_ACCOUNT.SEARCH;
                tempFilter = [
                    { field: 'employee.id', condition: 'equals', value: id },
                    { field: 'bankAccount.type', condition: 'equals', value: 1 },
                ];
                tempHiddenCompany = true;
                tempHiddenRepaymentCount = true;
                tempHiddenEnforcementType = false;
            }

            setUrl(tempUrl);
            setFilter(tempFilter);
            setHiddenCompany(tempHiddenCompany);
            setHiddenEnforcementType(tempHiddenEnforcementType);
            setHiddenRepaymentCount(tempHiddenRepaymentCount);
        }
    }, [type]);

    const setDefaultFilterValue = () => {
        return filter;
    };

    const onModalClose = () => {
        setSelectedCompanyId(null);
        setFilter(null);
        setHiddenCompany(false);
        setHiddenEnforcementType(true);
        setHiddenRepaymentCount(false);
        form.resetFields();
        setEditHeader(geti18nText('employeePayrollDeduction.edit.new'));
    };

    const noteButtonOnClick = () => {
        setEmployeePayrollDeductionModal(true);
    };

    const getCustomFooterContent = canCreate() && (
        <Tooltip title={geti18nText('employeePayrollDeduction.edit.note')}>
            <FormOutlined
                onClick={noteButtonOnClick}
                className="ny-custom-button"
                style={{
                    color: hasNote ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)',
                }}
            />
        </Tooltip>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_PAYROLL_DEDUCTION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (hiddenCompany) values.company = undefined;
                if (hiddenEnforcementType) values.enforcementType = undefined;
                if (hiddenRepaymentCount) values.repaymentCount = undefined;
                return {
                    id: values.id,
                    employee: {
                        id:
                            props.addedData && props.addedData.employee_id
                                ? Number(props.addedData.employee_id)
                                : Number(id),
                    },
                    company: values.company,
                    date: values.date ? getDateFormat(values.date) : null,
                    value: values.value,
                    type: values.type,
                    enforcementType: values.enforcementType,
                    repaymentCount: values.repaymentCount,
                    repaymentFrom: values.repaymentFrom ? getDateFormat(values.repaymentFrom) : null,
                    repaymentTo: values.repaymentTo ? getDateFormat(values.repaymentTo) : null,
                    active: values.active,
                    note: values.note,
                    bankAccount: values.bankAccount,
                    remittance: values.remittance,
                    currency: values.currency,
                    employeePayrollDeductionBankAccountId: values.employeePayrollDeductionBankAccountId,
                };
            }}
            customFooterContent={getCustomFooterContent}
            shortcuts={true}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={'id'} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeduction.edit.type')}
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Select options={epdTypes} onChange={onTypeChange} autoFocus={true} />
                                </Form.Item>
                            </Col>
                            {type === 'EXECUTION' && (
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employeePayrollDeduction.edit.enforcementType')}
                                        name="enforcementType"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <Select options={enforcementTypes} />
                                    </Form.Item>
                                </Col>
                            )}
                            {type !== 'EXECUTION' && (
                                <Col span={12}>
                                    <CompanySearch
                                        label={geti18nText('employeePayrollDeduction.edit.company.name')}
                                        isModal={true}
                                        onChange={(data: any) => onCompanySelect(data)}
                                        required={true}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeduction.edit.value')}
                                    name={'value'}
                                    rules={[
                                        {
                                            required: type !== 'EXECUTION',
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyInputNumber
                                        leftToRight={numbersWriting}
                                        isDecimal={true}
                                        style={{ width: '100%' }}
                                        nyTestId="amount"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <CurrencySearch
                                    label={geti18nText('employeePayrollDeduction.edit.currency')}
                                    name="currency"
                                    nyTestId="post-office"
                                    noClassName={true}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('employeePayrollDeduction.edit.date')} name={'date'}>
                                    <NyDatePicker nyTestId="date" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            {type === 'LOAN' && (
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('employeePayrollDeduction.edit.repaymentCount')}
                                        name={'repaymentCount'}
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            nyTestId="repayment-count"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeduction.edit.repaymentFrom')}
                                    name={'repaymentFrom'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NyDatePicker nyTestId="deduction-valid-from" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeduction.edit.repaymentTo')}
                                    name={'repaymentTo'}
                                >
                                    <NyDatePicker nyTestId="deduction-valid-to" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider plain>{geti18nText('employeePayrollDeduction.edit.bankAccount')}</Divider>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={'employeePayrollDeductionBankAccountId'} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeduction.edit.bankAccount.account')}
                                    name={'bankAccount'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={url}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        map={{ id: 'id', label: 'iban' }}
                                        searchBy="bankAccount.iban"
                                        AddNewModalComponent={BankAccountEdit}
                                        itemName={'iban'}
                                        nyTestId="iban"
                                        disabled={
                                            !hiddenEnforcementType || (!hiddenCompany && selectedCompanyId)
                                                ? false
                                                : true
                                        }
                                        order="bankAccount.iban"
                                        className=""
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeduction.edit.bankAccount.remittance')}
                                    name={'remittance'}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {employeePayrollDeductionModal && (
                    <Modal
                        visible={employeePayrollDeductionModal}
                        title={
                            geti18nText('employeePayrollDeduction.edit.payrollDeduction') +
                            ' - ' +
                            geti18nText('employeePayrollDeductionModal.edit.note')
                        }
                        onOk={() => {
                            setEmployeePayrollDeductionModal(false);
                        }}
                        forceRender={true}
                        closable={false}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        maskClosable={false}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('employeePayrollDeductionModal.edit.note')}
                                    labelAlign={'left'}
                                    name={['note', 'note']}
                                >
                                    <TextArea
                                        rows={4}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                        onChange={(event: any) => {
                                            setHasNote(event.target.value !== '' ? true : false);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>
                )}
            </React.Fragment>
        </NyDataEdit>
    );
};

export default EmployeePayrollDeductionEdit;
