import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { SortableItem } from '../../../../components/sortable-list/SortableItem';
import { TitleItem } from '../../../../components/sortable-list/TitleItem';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat } from '../../../../utils/Utils';
import TaskPriorityEdit from './edit';
import { TasksRights } from '../../../../rights/tasksRights';

const TaskPriorityIndex = ({}: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);
    const [data, setData] = useState<any>([]);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [visible, setVisible] = useState<boolean>(false);
    const [editPosition, setEditPosition] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<any>('2');

    const table = useTableSettings();
    useHelper('human/codebooks/room');
    const [itemEditData, setItemEditData] = useState<any>();

    const canCreate = () => {
        return TasksRights.canCreateAdminOrAgent();
    };

    useEffect(() => {
        getData();
    }, [selectedType, setSelectedType]);

    const getData = () => {
        const searchFilter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: selectedType },
            ])
        );

        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_PRIORITY.LIST, {
            order: 'position',
            orderType: 'asc',
            search: searchFilter,
            max: 150,
        }).then((response) => {
            if (response && response.status === RESPONSE.OK) {
                if (response.data && typeof response.data !== 'string') {
                    const responseData: { [index: string]: any } | undefined = response.data;
                    if (responseData?.content) {
                        setData(
                            responseData.content.sort((a: any, b: any) => {
                                return a.position - b.position;
                            })
                        );
                    }
                }
            } else {
                setData([]);
            }
        });
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        const notificationData = [...data];
        const oldIndex = notificationData.findIndex((item: any) => item.id === active.id);
        const newIndex = notificationData.findIndex((item: any) => item.id === over.id);

        let sorted = arrayMove(notificationData, oldIndex, newIndex);
        sorted = sorted.map((element: any, index: any) => {
            return { ...element, position: index + 1 };
        });
        setData(sorted);
        savePriorities(sorted);
    };

    const savePriorities = (sorted: any) => {
        const sortedFiltered = sorted.map((item: any) => {
            return { id: item.id, position: item.position, type: item.type };
        });
        const url = CONSTANTS_REQ.TASK_PRIORITY.SAVE;
        const urlType = url.replace('{type}', 2);

        let parms = { taskPriorityList: sortedFiltered, type: 2 };
        NyRequestResolver.requestPut(urlType, undefined, parms).then(() => getData());
    };

    const modalComponentOnSaveAndGetData = (positionItem: any) => {
        if (positionItem) {
            const newData = [...data];
            const index = newData.findIndex((item) => positionItem.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...positionItem });
                setData(newData);
                setItemEditData(undefined);
            }
        }
        getData();
    };

    return (
        <>
            <TitleItem
                className={'title-item'}
                id={geti18nText('task.priority.column.id')}
                type={
                    geti18nText('task.priority.column.type') + ':' + geti18nText('app.enum.TASK_TYPE.' + selectedType)
                }
                name={geti18nText('task.priority.column.name')}
                createdPerson={geti18nText('task.priority.column.createdPerson')}
                updatedPerson={geti18nText('task.priority.column.updatedPerson')}
                created={geti18nText('task.priority.column.created')}
                updated={geti18nText('task.priority.column.updated')}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                typeModal={'task-priority'}
            />
            {data && (
                <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                    <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter} sensors={sensors}>
                        <SortableContext
                            items={data.map((item: any) => item.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            {data &&
                                data.map((item: any, index: any) => {
                                    return (
                                        <SortableItem
                                            onDoubleClick={(e: any) => {
                                                setVisible(true);
                                                setItemEditData(item);
                                                setEditPosition(item?.position);
                                            }}
                                            key={item?.id}
                                            id={item?.id}
                                            name={item?.name}
                                            type="task-priority"
                                            position={item?.position}
                                            createdPerson={
                                                item.createdEmployee
                                                    ? item?.createdPerson?.firstName +
                                                      ' ' +
                                                      item?.createdPerson?.lastName +
                                                      ' (' +
                                                      item?.createdEmployee?.employmentRecordId +
                                                      ')'
                                                    : item.createdPerson
                                                    ? item?.createdPerson?.firstName +
                                                      ' ' +
                                                      item?.createdPerson?.lastName
                                                    : ''
                                            }
                                            updatedPerson={
                                                item.updatedEmployee
                                                    ? item?.updatedPerson?.firstName +
                                                      ' ' +
                                                      item?.updatedPerson?.lastName +
                                                      ' (' +
                                                      item?.updatedEmployee?.employmentRecordId +
                                                      ')'
                                                    : item.updatedPerson
                                                    ? item?.updatedPerson?.firstName +
                                                      ' ' +
                                                      item?.updatedPerson?.lastName
                                                    : ''
                                            }
                                            created={
                                                item?.created ? getDateFormat(item.created, 'DD.MM.YYYY HH:mm') : ''
                                            }
                                            updated={
                                                item?.updated ? moment(item.updated).format('DD.MM.YYYY HH:mm') : ''
                                            }
                                            index={index}
                                            mail={item.mail}
                                            item={item}
                                        />
                                    );
                                })}
                        </SortableContext>
                    </DndContext>
                </div>
            )}
            <div style={{ marginBottom: '1rem' }}></div>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        style={{ marginRight: '1em' }}
                        key="submit"
                        type="primary"
                        hidden={!canCreate()}
                        onClick={(e) => setVisible(true)}
                    >
                        {geti18nText('app.default.button.add')}
                    </Button>
                </Col>
            </Row>
            {visible && (
                <>
                    {React.createElement(
                        TaskPriorityEdit,
                        {
                            isModal: true,
                            visible: visible,
                            setVisible: setVisible,
                            value: itemEditData,
                            onSaveAndGetData: modalComponentOnSaveAndGetData,
                            addedData: { editPosition: editPosition },
                            propsData: { setItemEditData: setItemEditData },
                        },
                        null
                    )}
                </>
            )}
        </>
    );
};

export default TaskPriorityIndex;
