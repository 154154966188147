import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useEnum from '../../../../../../hooks/useEnum';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { getColumnSearchByHumanCodebookSimple } from '../../../../../../utils/Filters';
import { fileDownload } from '../../../../../../utils/Utils';
import { useEffect, useState } from 'react';
import EmployeeDisabilityEdit from './edit';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeDisabilityIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    employeeId,
    showHeader = true,
    scroll = { y: 580, x: 800 },
    sortOrder,
    readonly = false,
}: any) => {
    const table = useTableSettings();
    const { id } = useParams<any>();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [refresh, setRefresh] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id ? id : employeeId },
        ];
    };

    const columns: any = [
        {
            title: geti18nText('employee.disability.table.column.type'),
            dataIndex: ['disabilityType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByHumanCodebookSimple(enCodebookType.DISABILITY_TYPE, 'disabilityType.id'),
            render: (text: any, record: any) => {
                return record?.disabilityType?.name;
            },
        },
        {
            title: geti18nText('employee.disability.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.disability.table.column.percent'),
            dataIndex: 'percentage',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (text) {
                    return text + '%';
                }
            },
        },
    ];

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <NyDataTable
            nyId="human-employee-disability-table"
            headerTitle={showHeader ? geti18nText('employee.disability.table.header') : null}
            url={CONSTANTS_REQ.EMPLOYEE_DISABILITY.LIST}
            addNewButtonText={geti18nText('employee.disability.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            fetchWhenChange={employeeId || id || refresh}
            hideNewButton={!canCreate()}
            readonly={readonly}
            setDefaultFilterValue={setDefaultFilterValue}
            modalComponent={EmployeeDisabilityEdit}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            shortcuts={true}
            CSVFileName={geti18nText('employee.disability.table.header')}
            editProps={{ employeeId: id }}
        />
    );
};

export default EmployeeDisabilityIndex;
