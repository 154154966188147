import useHelper from '../../../../hooks/useHelper';
import PaymentCalculationEdit from '../payment-calculation/edit';

const ContractEdit = () => {
    useHelper('payroll/calculation/contract');

    return <PaymentCalculationEdit type={3} />;
};

export default ContractEdit;
