import { getColumnDateOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import moment from 'moment';
import useTableSettings from '../../../../../../hooks/useTableSettings';
import EmployeeBloodDonationEdit from './edit';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../../utils/Constants';
import { useEffect, useState } from 'react';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeBloodDonationIndex = ({ id, scroll = DEFAULT_TABLE_SCROLL, sortOrder, readonly = false }: any) => {
    const table = useTableSettings();
    const [refresh, setRefresh] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id },
        ];
    };

    const columns = [
        {
            title: geti18nText('employee.table.blood.donation.date'),
            dataIndex: 'donationDate',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.donationDate) {
                    return moment(record.donationDate).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
    ];

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <NyDataTable
            nyId="human-employee-blood-donation-table"
            url={CONSTANTS_REQ.EMPLOYEE_BLOOD_DONATION.LIST}
            addNewButtonText={geti18nText('employee.tab.blood.donation.add')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={EmployeeBloodDonationEdit}
            columns={columns}
            readonly={readonly}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            hideNewButton={!canCreate()}
            addedData={{ employeeId: id }}
            shortcuts={true}
            fetchWhenChange={id || refresh}
        />
    );
};

export default EmployeeBloodDonationIndex;
