import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import AddItemIndex from './AddItemIndex';
import TaskPriorityEdit from './edit';

const TaskPrioritySearch = ({
    label = geti18nText('task.edit.edit.priority'),
    name = 'taskPriority',
    formStyle = undefined,
    setDefaultFilterValue = undefined,
    disabled = false,
}: any) => {
    return (
        <Form.Item label={label} name={name} style={formStyle}>
            <NySearchField
                url={CONSTANTS_REQ.TASK_PRIORITY.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                SearchPopupComponent={<AddItemIndex />}
                AddNewModalComponent={TaskPriorityEdit}
                nyTestId="task-priority"
                setDefaultFilterValue={setDefaultFilterValue}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default TaskPrioritySearch;
