import { PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NyModalConfirm, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Divider, Form, Modal, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import TravelWarrantSummaryIndex from '../../../../../components/travel-warrant-summary';
import useEnum from '../../../../../hooks/useEnum';
import { refreshItemList } from '../../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getFileList,
    okNotification,
    onKeyDownTextAreaCustom,
    warningNotification,
} from '../../../../../utils/Utils';

const { Text } = Typography;

const TravelWarrantActionModal = ({
    travelWarrantId,
    setTravelWarrantId,
    modalVisible,
    setModalVisible,
    refresh,
    setRefresh,
    canApprove = true,
    canReject = true,
    isTravelWarrantEdit = false,
}: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalForm] = Form.useForm();
    const [data, setData] = useState<any>(undefined);
    const [ordinal, setOrdinal] = useState<any>('');
    const [loading, setLoading] = useState(false);
    const [employee, setEmployee] = useState<any>();
    const [isPlane, setIsPlane] = useState(false);
    const [currency, setCurrency] = useState<any>(undefined);
    const [isVehicle2Set, setIsVehicle2Set] = useState(false);
    const [vehicleApproved, setVehicleApproved] = useState(false);
    const [vehicleApproved2, setVehicleApproved2] = useState(false);
    const [advanceApproved, setAdvanceApproved] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const [formSummary] = Form.useForm();
    const componentRefModal = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [success, setSuccess] = useState<any>(true);
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(null);

    useEffect(() => {
        if (modalVisible) {
            fetch();
        }
    }, [modalVisible]);

    useEffect(() => {
        if (travelWarrantId) {
            getModalFiles();
        }
    }, [refreshFiles]);

    const getModalFiles = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
                ])
            ),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
    };

    const fetch = () => {
        if (travelWarrantId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + travelWarrantId, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        setData(result.data);
                        setTravelWarrantStatus(result.data.status);
                        if (result.data.status != travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                            setSuccess(false);
                        }
                        if (result.data.status > travelWarrantStatusEnum.DRAFT) {
                            setOrdinal(
                                result.data.ordinal && result.data.warrantDate
                                    ? result.data.ordinal + '/' + moment(result.data.warrantDate).format('YYYY')
                                    : null
                            );
                        }
                        setEmployee(result.data.employee);
                        if (result.data.advancePaymentCurrency.id && result.data.advancePaymentCurrency.isoCode) {
                            setCurrency({
                                id: result.data.advancePaymentCurrency.id,
                                text: result.data.advancePaymentCurrency.isoCode,
                            });
                        }
                        if (result.data.travelVehicle && result.data.travelVehicle.vehicleType == 3) {
                            setIsPlane(true);
                        }
                        if (result.data.travelVehicle2) {
                            setIsVehicle2Set(true);
                        }
                        if (result.data && result.data.content) {
                            setFiles(getFileList(result.data.content));
                        }
                        setVehicleApproved(true);
                        setVehicleApproved2(result.data.travelVehicle2 ? true : false);
                        setAdvanceApproved(result.data.advancePaymentInCurrency ? true : false);

                        let data: any = {};
                        data.advanceApproved = result.data.advancePaymentInCurrency ? true : false;
                        data.vehicleApproved = true;
                        data.vehicleApproved2 = result.data.travelVehicle2 ? true : false;
                        modalForm.setFieldsValue(data);
                        getModalFiles();
                    }
                }
            );
        }
    };

    const save = (event: any, selectedDecision: any) => {
        if (event) event.preventDefault();
        setLoading(true);
        let message = modalForm.getFieldValue(['message']) ? modalForm.getFieldValue(['message']) : null;
        if (!message && selectedDecision == travelWarrantStatusEnum.REJECTED) {
            warningNotification(geti18nText('travelWarrantOrganization.note.info'));
            setLoading(false);
        } else {
            saveDecision(selectedDecision);
        }
    };

    const saveDecision = (selectedDecision: any) => {
        modalForm.validateFields().then((values: any) => {
            setLoading(true);
            values.id = travelWarrantId;
            values.status = selectedDecision;
            if (isVehicle2Set) {
                values.vehicleApproved2 = values.vehicleApproved2 ?? false;
            }
            values.info = modalForm.getFieldValue(['message']) ? modalForm.getFieldValue(['message']) : null;
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + travelWarrantId,
                undefined,
                values
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    dispatch(refreshItemList());
                    closeModal();
                    if (setRefresh) setRefresh(() => refresh + 1);
                } else {
                    if (result.data && result.data.error) {
                        errorNotification(geti18nText(result.data.error));
                    } else {
                        errorNotification();
                    }
                }
                setLoading(false);
            });
        });
    };

    const getOrdinal = () => {
        if (ordinal) return ' - ' + ordinal;
        return '';
    };

    const openDetails = () => {
        history.push('/travel-warrant/travelWarrant/' + travelWarrantId);
    };

    const closeModal = () => {
        setModalVisible(false);
        modalForm.resetFields();
        setOrdinal('');
        setLoading(false);
        setEmployee(null);
        setIsPlane(false);
        setCurrency(undefined);
        setIsVehicle2Set(false);
        setVehicleApproved(false);
        setVehicleApproved2(false);
        setAdvanceApproved(false);
        setFiles([]);
        setPrintPdf(false);
        setIsVehicle2Set(false);
        setSuccess(true);
        if (setTravelWarrantId) {
            setTravelWarrantId(null);
        }
        setTravelWarrantStatus(null);
        setData(null);
    };

    const getCustomFooterContent = (
        <>
            <div>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button key={'print'} icon={<PrinterOutlined />}>
                                {geti18nText('travelWarrant.action.pdf.short')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(true);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(false);
                    }}
                    content={() => componentRefModal.current}
                    pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                />
                {!isTravelWarrantEdit && (
                    <Button type="primary" onClick={openDetails}>
                        {geti18nText('travelWarrant.overview.details')}
                    </Button>
                )}
                <Button key="cancel" onClick={closeModal}>
                    {geti18nText('app.default.button.cancel')}
                </Button>
                {canReject && success && (
                    <NyModalConfirm
                        title={geti18nText('travelWarrant.overview.reject.confirm')}
                        onConfirm={() => {
                            save(null, travelWarrantStatusEnum.REJECTED);
                        }}
                    >
                        <Button key="reject" style={{ color: 'white', backgroundColor: '#f3af28' }} loading={loading}>
                            {geti18nText('travelWarrant.edit.button.reportFixNeeded')}
                        </Button>
                    </NyModalConfirm>
                )}
                {canReject && success && (
                    <NyModalConfirm
                        title={geti18nText('travelWarrant.overview.reject.confirm')}
                        onConfirm={() => {
                            save(null, travelWarrantStatusEnum.REJECTED);
                        }}
                    >
                        <Button key="reject" style={{ color: 'white', backgroundColor: 'red' }} loading={loading}>
                            {geti18nText('app.default.button.reject.it')}
                        </Button>
                    </NyModalConfirm>
                )}
                {canApprove && success && (
                    <NyModalConfirm
                        title={geti18nText('travelWarrant.overview.approve.confirm')}
                        onConfirm={() => {
                            save(null, travelWarrantStatusEnum.APPROVED);
                        }}
                    >
                        <Button key="submit" style={{ color: 'white', backgroundColor: 'green' }} loading={loading}>
                            {geti18nText('app.default.button.approve')}
                        </Button>
                    </NyModalConfirm>
                )}
            </div>
        </>
    );

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={geti18nText('travelWarrant.panel.generalData') + getOrdinal()}
                    onOk={(event: any) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    width={900}
                    onCancel={closeModal}
                    confirmLoading={loading}
                    maskClosable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    footer={getCustomFooterContent}
                >
                    <Row
                        gutter={24}
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '70vh',
                        }}
                    >
                        <Col span={24}>
                            <Form form={modalForm} component={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <div ref={componentRefModal}>
                                    {printPdf && (
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Divider orientation="left">
                                                    <b>
                                                        {geti18nText('travelWarrant.panel.generalData')}
                                                        {' - '}
                                                        {ordinal}
                                                    </b>
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <TravelWarrantSummaryIndex
                                                printPdfProp={printPdf}
                                                setPrintPdfProp={setPrintPdf}
                                                employeeTravelWarrant={employee}
                                                globalForm={data}
                                                formSummary={formSummary}
                                                isPlane={isPlane}
                                                currency={currency}
                                                files={files}
                                                setFiles={setFiles}
                                                showPrintButton={false}
                                                refId={travelWarrantId}
                                                id={travelWarrantId}
                                                setRefreshFiles={setRefreshFiles}
                                                travelWarrantStatus={travelWarrantStatus}
                                                message={modalForm.getFieldValue(['message'])}
                                            ></TravelWarrantSummaryIndex>
                                        </Col>
                                    </Row>
                                    <Divider plain></Divider>
                                    <Row gutter={24}>
                                        <Col span={8} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                                            <Form.Item
                                                label={geti18nText('travel.warrant.edit.approve.advance')}
                                                name="advanceApproved"
                                                valuePropName={'checked'}
                                            >
                                                {printPdf ? (
                                                    <Text>
                                                        {advanceApproved
                                                            ? geti18nText('app.default.button.yes')
                                                            : geti18nText('app.default.button.no')}
                                                    </Text>
                                                ) : (
                                                    <Checkbox disabled={true} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                                            <Form.Item
                                                label={geti18nText('travel.warrant.edit.approve.vehicle')}
                                                name="vehicleApproved"
                                                valuePropName={'checked'}
                                                initialValue={true}
                                            >
                                                {printPdf ? (
                                                    <Text>
                                                        {vehicleApproved
                                                            ? geti18nText('app.default.button.yes')
                                                            : geti18nText('app.default.button.no')}
                                                    </Text>
                                                ) : (
                                                    <Checkbox disabled={true} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {isVehicle2Set && (
                                            <Col span={8} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                                                <Form.Item
                                                    label={geti18nText('travel.warrant.edit.approve.vehicle2')}
                                                    name="vehicleApproved2"
                                                    valuePropName={'checked'}
                                                    initialValue={true}
                                                >
                                                    {printPdf ? (
                                                        <Text>
                                                            {vehicleApproved2
                                                                ? geti18nText('app.default.button.yes')
                                                                : geti18nText('app.default.button.no')}
                                                        </Text>
                                                    ) : (
                                                        <Checkbox
                                                            onChange={(event: any) => {
                                                                setVehicleApproved2(
                                                                    event.target.checked ? true : false
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                    {!printPdf && (
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="message"
                                                    label={geti18nText(
                                                        'travelWarrantOrganization.table.column.message'
                                                    )}
                                                >
                                                    <TextArea
                                                        rows={4}
                                                        onKeyDown={onKeyDownTextAreaCustom}
                                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                                    ></TextArea>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
        </div>
    );
};

export default TravelWarrantActionModal;
