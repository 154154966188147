import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Layout, Layouts } from 'react-grid-layout';
import { HumanResourcesRights } from '../rights/humanResourcesRights';
import { AppThunk } from '../store';
import { CONSTANTS_REQ } from '../utils/Constants';
import { okNotification, stringGenerator } from '../utils/Utils';

const allWidgets = [
    {
        key: 'ActionsWidget',
        component: 'ActionsWidget',
        dataGrid: { w: 2, h: 4, x: 0, y: 0, minW: 2, minH: 4, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.isAdminDashboardHR(),
    },
    {
        key: 'DirectoryWidget',
        component: 'DirectoryWidget',
        dataGrid: { w: 2, h: 4, x: 2, y: 0, minW: 2, minH: 4, maxW: 6, maxH: 6, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.isAdminDashboardHR(),
    },
    {
        key: 'NotificationWidget',
        component: 'NotificationWidget',
        dataGrid: { w: 2, h: 4, x: 4, y: 0, minW: 2, minH: 4, maxW: 6, maxH: 7, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.isAdminDashboardHR(),
    },
    {
        key: 'ChartAgeRangeWidget',
        component: 'ChartAgeRangeWidget',
        dataGrid: { w: 4, h: 7, x: 0, y: 4, minW: 4, minH: 7, maxW: 12, maxH: 7, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.showCharts(),
    },
    {
        key: 'ChartBusinessUnitEmployeesWidget',
        component: 'ChartBusinessUnitEmployeesWidget',
        dataGrid: { w: 4, h: 7, x: 4, y: 4, minW: 4, minH: 7, maxW: 12, maxH: 7, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.showCharts(),
    },
    {
        key: 'PieChartGenderCardWidget',
        component: 'PieChartGenderCardWidget',
        dataGrid: { w: 4, h: 7, x: 8, y: 4, minW: 4, minH: 7, maxW: 12, maxH: 7, static: true },
        onResizeRedraw: true,
        role: HumanResourcesRights.showCharts(),
    },
];

interface IEmployeeState {
    widgets?: any;
    allWidgets?: any;
    layouts?: Layouts;
    lastLayout?: Layout[];
    lock?: Boolean;
    key?: string;
}

const initialState: IEmployeeState = {
    widgets: [],
    allWidgets: [...allWidgets],
    layouts: {},
    lastLayout: [],
    lock: true,
    key: '',
};

const dashboardHR = createSlice({
    name: 'dashboardHR',
    initialState,
    reducers: {
        dashboardInit(state) {
            state.allWidgets = [...allWidgets];
            state.widgets = [...allWidgets];
            state.layouts = {};
            state.lastLayout = [];
            state.lock = true;
            state.key = '';
        },
        widgetsInit(state) {
            state.widgets = [...allWidgets];
        },
        widgetsSetDefaultValue(state, action: PayloadAction<IEmployeeState>) {
            const { widgets } = action.payload;
            state.widgets = widgets;
        },
        setLayouts(state, action: PayloadAction<IEmployeeState>) {
            const { layouts } = action.payload;
            state.layouts = layouts;
        },
        setLastLayouts(state, action: PayloadAction<IEmployeeState>) {
            const { lastLayout } = action.payload;
            state.lastLayout = lastLayout;
        },
        setLock(state, action: PayloadAction<IEmployeeState>) {
            state.lock = !state.lock;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }
            if (state.lastLayout) {
                let newLastLayout = state.lastLayout.map((val) => {
                    return { ...val, static: !val.static };
                });
                state.lastLayout = newLastLayout;
                if (state.lock) {
                    save(
                        state.lastLayout,
                        action.payload && action.payload.widgets,
                        action.payload && action.payload.allWidgets
                    );
                }
            }
        },
        setDefaultLayout(state, action: PayloadAction<IEmployeeState>) {
            state.lock = true;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }

            if (state.lastLayout) {
                let newLastLayout = state.lastLayout.map((val) => {
                    return { ...val, static: !val.static };
                });
                state.lastLayout = newLastLayout;
                if (state.lock) {
                    save(
                        state.lastLayout,
                        action.payload && action.payload.widgets,
                        action.payload && action.payload.allWidgets
                    );
                }
            }
        },
        removeWidget(state, action: PayloadAction<IEmployeeState>) {
            const { key } = action.payload;
            state.widgets = state.widgets.filter((wid: any) => wid.key !== key);
        },
        addWidget(state, action) {
            const { key, data } = action.payload;
            let widget = allWidgets?.find((item: any) => item.key === key);
            if (widget) {
                let newWidget: any = _.cloneDeep(widget);
                newWidget.key = newWidget.key + '_' + stringGenerator(5);
                if (data) {
                    newWidget.data = data;
                }
                state.widgets = [...state.widgets, newWidget];
            }
        },
        layoutChange(state, action: PayloadAction<IEmployeeState>) {
            const { lastLayout, layouts } = action.payload;
            state.lastLayout = lastLayout;
            state.layouts = layouts;
            let widgetList = [...state.widgets];
            widgetList.forEach((w: any) => {
                let position = lastLayout && lastLayout.find(({ i }) => i === w.key);
                let clonePosition: { [index: string]: any } = { ...position };
                if (clonePosition.i != null) delete clonePosition.i;
                w.dataGrid = clonePosition;
            });
            state.widgets = widgetList;
        },
        setLayoutForUser(state, action: PayloadAction<IEmployeeState>) {
            const widgetsForUser = action.payload;
            state.widgets = widgetsForUser;
        },
        setLayoutDefault(state, action: PayloadAction<IEmployeeState>) {
            const widgetsForUser = action.payload ?? allWidgets;
            let lastLayout: any = action.payload ?? allWidgets;
            state.widgets = widgetsForUser;
            state.allWidgets = allWidgets;
            state.lock = true;
            if (state.layouts) {
                let newLayouts: Layouts = {};

                for (let [key, value] of Object.entries(state.layouts)) {
                    newLayouts[key] = value.map((val) => {
                        return { ...val, static: !val.static };
                    });
                }
                state.layouts = newLayouts;
            }
            if (lastLayout) {
                let newLastLayout = lastLayout.map((val: any) => {
                    return { ...val, static: !val.static };
                });
                lastLayout = newLastLayout;
                state.lastLayout = newLastLayout;

                if (state.lock) {
                    save(lastLayout, widgetsForUser, allWidgets, true);
                }
            }
        },
    },
});

const normalizeDefault = (lastLayout: any, widgets: any, allWidgets: any) => {
    const settingsValue: any = [];
    if (lastLayout && widgets && allWidgets) {
        lastLayout.map((layout: any) => {
            let widgetData = widgets?.find((item: any) => item.key === layout.key);
            const component = widgetData.component;
            let widget = allWidgets?.find((item: any) => item.component === component);
            let data = layout.data ?? null;
            if (widget) {
                let role = widget.role ?? null;
                let layoutValue: any = {
                    key: layout.key,
                    component: component,
                    dataGrid: layout.dataGrid,
                    role: role,
                    data: data,
                    onResizeRedraw: true,
                };
                if (layoutValue.role == null) {
                    delete layoutValue.role;
                }
                if (layoutValue.data == null) {
                    delete layoutValue.data;
                }
                settingsValue.push(layoutValue);
            }
        });
    }
    return settingsValue;
};

const normalize = (lastLayout: any, widgets: any, allWidgets: any) => {
    const settingsValue: any = [];
    if (lastLayout && widgets && allWidgets) {
        lastLayout.map((layout: any) => {
            let widgetData = widgets?.find((item: any) => item.key === layout.i);
            let widget = allWidgets?.find((item: any) => item?.key === widgetData?.key);
            const component = widget?.component;
            let data = widgetData?.data ?? null;
            if (widget) {
                let role = widget.role ?? null;
                let layoutValue: any = {
                    key: layout.i,
                    component: component,
                    dataGrid: {
                        w: layout.w,
                        h: layout.h,
                        x: layout.x,
                        y: layout.y,
                        minW: layout.minW,
                        minH: layout.minH,
                        maxW: layout.maxW,
                        maxH: layout.maxH,
                        static: layout.static,
                    },
                    role: role,
                    data: data,
                    onResizeRedraw: true,
                };
                if (layoutValue.role == null) {
                    delete layoutValue.role;
                }
                if (layoutValue.data == null) {
                    delete layoutValue.data;
                }
                settingsValue.push(layoutValue);
            }
        });
    }
    return settingsValue;
};

const save = (lastLayout: any, widgets: any, allWidgets: any, defaultLayout: boolean = false) => {
    let settingsValue: any = [];
    if (defaultLayout) {
        settingsValue = normalizeDefault(lastLayout, widgets, allWidgets);
    } else {
        settingsValue = normalize(lastLayout, widgets, allWidgets);
    }
    if (settingsValue) {
        let parms: any = {};
        parms.settingKey = 'dashboardHR';
        parms.settingValue = JSON.stringify(settingsValue);
        NyRequestResolver.requestPost(CONSTANTS_REQ.USER_SESTTINGS.EDIT, undefined, parms).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification();
            }
        });
    }
};

export const {
    dashboardInit,
    widgetsInit,
    setLayouts,
    setLastLayouts,
    setLock,
    layoutChange,
    setLayoutForUser,
    setLayoutDefault,
} = dashboardHR.actions;

export default dashboardHR.reducer;

export const setDefaultWidgetsValue = (): AppThunk => async (dispatch) => {
    dispatch(dashboardInit());
};

export const getDashboardWidgetsForUserHR = (): AppThunk => async (dispatch) => {
    dispatch(dashboardInit());
    const settingKey = 'dashboardHR';
    NyRequestResolver.requestGet(CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey, {
        settingKey: settingKey,
    }).then((result: any) => {
        if (result.status == RESPONSE.OK && result.data && result.data.settingValue) {
            let settingsValue = JSON.parse(result.data.settingValue);
            if (settingsValue?.length > 0) {
                dispatch(setLayoutForUser(settingsValue));
            } else {
                dispatch(setDefaultWidgetsValue());
            }
        } else {
            dispatch(setDefaultWidgetsValue());
        }
    });
};
