import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption } from '../../../../../utils/Utils';
import UserIndex from '../../user';

const EditableCell = ({
    record,
    inputType,
    dataIndex,
    title,
    editing,
    form,
    index,
    taskTemplateApprovalId,
    showParent,
    children,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    switch (inputType) {
        case 'user':
            inputNode = (
                <NySearchField
                    url={CONSTANTS_REQ.USER.SEARCH}
                    mustGetPopupContainer={false}
                    map={{
                        id: 'id',
                        label: 'text',
                        employmentRecordId: 'employmentRecordId',
                        businessUnit: 'businessUnit',
                    }}
                    searchBy="user"
                    itemName={[
                        ['person', 'firstName'],
                        ['person', 'lastName'],
                    ]}
                    disabled={false}
                    renderOption={customEmployeeRenderOption}
                    customItemNameLabel={'firstName lastName'}
                    SearchPopupComponent={<UserIndex disabled={true} />}
                    order="person.last_name, person.first_name"
                />
            );
            break;
        case 'parent':
            inputNode = (
                <NySearchField
                    url={CONSTANTS_REQ.TASK_TEMPLATE_PARENT_USER.SEARCH.replace(
                        '{taskTemplateApprovalId}',
                        taskTemplateApprovalId
                    )}
                    mustGetPopupContainer={false}
                    map={{
                        id: 'id',
                        label: 'text',
                        employmentRecordId: 'employmentRecordId',
                        businessUnit: 'businessUnit',
                    }}
                    searchBy="user"
                    itemName={[
                        ['person', 'firstName'],
                        ['person', 'lastName'],
                    ]}
                    disabled={false}
                    renderOption={customEmployeeRenderOption}
                    customItemNameLabel={'firstName lastName'}
                    SearchPopupComponent={
                        <UserIndex
                            disabled={true}
                            url={CONSTANTS_REQ.TASK_TEMPLATE_PARENT_USER.LIST.replace(
                                '{taskTemplateApprovalId}',
                                taskTemplateApprovalId
                            )}
                        />
                    }
                    order="person.last_name, person.first_name"
                />
            );
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                        display: inputType === 'parent' ? (showParent ? 'block' : 'none') : 'block',
                    }}
                    rules={[
                        {
                            required: inputType === 'user',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
