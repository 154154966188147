import { NyDataEdit, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import CountryIndex from '../country';
import CountryEdit from '../country/edit';

const PostOfficeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('postOffice.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('postOffice.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.country) {
            dataForm.country = setSearchFormat(dataForm.country);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('postOffice.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.POST_OFFICE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.country) values.country = getSearchFormat(values.country);

                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('postOffice.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('postOffice.edit.zipCode')}
                        name="zipCode"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('postOffice.edit.country')} name="country">
                        <NySearchField
                            url={CONSTANTS_REQ.COUNTRY.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<CountryIndex />}
                            AddNewModalComponent={CountryEdit}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PostOfficeEdit;
