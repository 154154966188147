import { NySession } from '@nybble/nyreact';
export const TasksRights = (function () {
    return {
        isTasksAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TASKS_ADMIN']);
        },
        isTasksAgent: () => {
            return NySession.hasAnyRole(['ROLE_TASKS_AGENT']);
        },
        isTasksUser: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TASKS']);
        },
        isTasksApprover: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TASKS_APPROVAL']);
        },
        isTasksApproverOnly: () => {
            return NySession.hasAnyRole(['ROLE_TASKS_APPROVAL']);
        },
        canCreateAdminOrAgent: () => {
            return TasksRights.isTasksAdmin() || TasksRights.isTasksAgent();
        },
        canCreateAdminOrUser: () => {
            return TasksRights.isTasksAdmin() || TasksRights.isTasksUser();
        },
        canViewTaskApproval: () => {
            return TasksRights.isTasksAdmin() || TasksRights.isTasksApprover();
        },
        // korisnički dio aplikacije
        canCreateRequest: () => {
            return TasksRights.isTasksAdmin() || TasksRights.isTasksUser(); // za type service
        },
        canViewRequest: () => {
            return TasksRights.isTasksAdmin() || TasksRights.isTasksAgent() || TasksRights.isTasksUser(); // za type service
        },
        canViewApprovals: () => {
            return TasksRights.isTasksAdmin() || TasksRights.isTasksUser() || TasksRights.isTasksApprover(); // za type servise, subtask
        },
        //worktime
        canDeleteOwnWorktimeActivity: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TASK_EDIT', 'ROLE_TASKS_AGENT']);
        },
        canDeleteAllWorktimeActivity: () => {
            return TasksRights.isTasksAdmin();
        },
        // šifrarnici
        canCreateTasksCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_TASKS_CODEBOOKS_EDIT']);
        },
        ///////////////////
        //menu
        canViewTask: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_ADMIN', 'ROLE_TASKS_AGENT'];
        },
        canViewTasksCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_CODEBOOKS_VIEW'];
        },
        canViewTasksCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_CODEBOOKS_MENU'];
        },
        canViewTasksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_ADMIN', 'ROLE_TASKS_AGENT', 'ROLE_TASKS_CODEBOOKS_MENU'];
        },
        canViewTasksMenuApproval: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_ADMIN', 'ROLE_TASKS_AGENT', 'ROLE_TASKS_APPROVAL'];
        },
        canViewTasksMenuUser: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_ADMIN', 'ROLE_TASKS'];
        },
        canViewTasksSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_TASKS_SETTINGS'];
        },
    };
})();
