import { FilePdfOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import NyReportButton from '../../../../../components/report-button';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getDateFormat } from '../../../../../utils/Utils';
import DepreciationGroupIndex from '../asset-depreciation-group';
import AssetTypeIndex from '../../../../webshop/views/webshop-asset/asset-type';
import BusinessUnitIndex from '../../../../human/views/business-unit';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import useEnum from '../../../../../hooks/useEnum';
import ReportExportType from '../../../../../components/report-export-type/ReportExportType';

const AssetFlowSummaryReportModal = ({
    reportType,
    reportTitle,
    reportFileName,
    url,
    allowExportTypeChange = true,
}: any) => {
    const [form] = Form.useForm();
    const enExportType = useEnum('EXPORT_TYPE');
    const [visible, setVisible] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment().startOf('year'));
    const [dateTo, setDateTo] = useState<any>(moment());
    const [businessUnitIds, setBusinessUnitIds] = useState<any>(null);
    const [depreciationGroupIds, setDepreciationGroupIds] = useState<any>(null);
    const [assetTypeIds, setAssetTypeIds] = useState<any>(null);
    const [exportType, setExportType] = useState(enExportType.PDF);

    const checkBeforeSave = () => {
        return form
            .validateFields()
            .then((values: any) => {
                setDateFrom(getDateFormat(values.dateFrom));
                setDateTo(getDateFormat(values.dateTo));
                return true;
            })
            .catch((errorInfo: any) => {
                return false;
            });
    };

    return (
        <>
            <Button block icon={<FilePdfOutlined />} onClick={() => setVisible(true)}>
                {geti18nText(reportTitle)}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText(reportTitle)}
                    maskClosable={false}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        setBusinessUnitIds(null);
                        setDepreciationGroupIds(null);
                        setAssetTypeIds(null);
                        setDateFrom(moment().startOf('year'));
                        setDateTo(moment());
                        setExportType(enExportType.PDF);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    footer={[
                        <NyReportButton
                            url={url}
                            customParms={{
                                dateFrom: getDateFormat(dateFrom),
                                dateTo: getDateFormat(dateTo),
                                exportType: exportType ? exportType : enExportType.PDF,
                                businessUnitIds: JSON.stringify(
                                    businessUnitIds?.map((value: any) => {
                                        return value.id;
                                    })
                                ),
                                depreciationGroupIds: JSON.stringify(
                                    depreciationGroupIds?.map((value: any) => {
                                        return value.id;
                                    })
                                ),
                                assetTypeIds: JSON.stringify(
                                    assetTypeIds?.map((value: any) => {
                                        return value.id;
                                    })
                                ),
                            }}
                            subreportType={reportType}
                            buttoni18nText={reportTitle}
                            fileName={reportFileName}
                            checkBeforeSave={checkBeforeSave}
                            selectedExportType={exportType}
                        />,
                    ]}
                >
                    <Form
                        form={form}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {allowExportTypeChange && (
                            <ReportExportType exportType={exportType} setExportType={setExportType} />
                        )}
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('fixed.asset.report.dateFrom')}
                                    name="dateFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().startOf('year')}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateFrom(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('fixed.asset.report.dateTo')}
                                    name="dateTo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment()}
                                >
                                    <NyDatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(value: any) => {
                                            setDateTo(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <BusinessUnitSearch
                                    formItemName={'businessUnit'}
                                    mode="multiple"
                                    onChange={(value: any) => {
                                        setBusinessUnitIds(value && value.length > 0 ? value : null);
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('fixed.asset.report.depreciationGroup')}
                                    name="depreciationGroup"
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.DEPRECIATION_GROUP.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        SearchPopupComponent={<DepreciationGroupIndex />}
                                        mode="multiple"
                                        onChange={(value: any) => {
                                            setDepreciationGroupIds(value && value.length > 0 ? value : null);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('assetType.table.header')} name="assetType">
                                    <NySearchField
                                        url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        itemName="name"
                                        SearchPopupComponent={<AssetTypeIndex />}
                                        mode="multiple"
                                        onChange={(value: any) => {
                                            setAssetTypeIds(value && value.length > 0 ? value : null);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AssetFlowSummaryReportModal;
