import { getColumnSearchByCodebook } from '@nybble/nyreact';
import { CONSTANTS_REQ } from './Constants';
import {
    customEmployeeMobilizationRenderOption,
    customWorkplaceMobilizationRenderOption,
    getHumanCodebooksUrlByType,
} from './Utils';

export const getColumnSearchByCodebookSimple = (
    URL: string,
    searchId: string = 'id',
    searchBy: string = 'name',
    searchOrder: string = 'name',
    searchMap: object = {
        id: 'id',
        label: 'name',
        text: 'text',
    }
) => {
    return getColumnSearchByCodebook(URL, searchId, searchBy, searchOrder, searchMap);
};

export const getColumnSearchByHumanCodebookSimple = (
    humanCodebookType: any,
    id: string,
    URL: string = CONSTANTS_REQ.HUMAN_CODEBOOKS.SEARCH,
    showCode = true
) => {
    if (showCode) {
        return getColumnSearchByCodebook(
            getHumanCodebooksUrlByType(URL, humanCodebookType.toString()),
            id,
            'name || code',
            'name',
            { id: 'id', label: 'name', code: 'code', text: 'name' },
            //'name, code',
            '(code) name',
            [['code'], ['name']],
            (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
        );
    } else {
        return getColumnSearchByCodebook(
            getHumanCodebooksUrlByType(URL, humanCodebookType.toString()),
            id,
            'name',
            'name',
            { id: 'id', label: 'name', text: 'name' },
            //'name, code',
            'name',
            ['name'],
            (option: any) => `${option.text}`
        );
    }
};

export const getColumnSearchByCodebookSimpleWithAddedData = (URL: string, id: string, defaultFilterValue?: any) => {
    return getColumnSearchByCodebook(
        URL,
        id,
        'name',
        'name',
        {
            id: 'id',
            label: 'name',
        },
        // undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        () => defaultFilterValue
    );
};

export const getColumnSearchByCodebookEmployee = (condition: string = "person.first_name || ' ' || person.last_name") =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.EMPLOYEE.SEARCH,
        'employee.id',
        condition,
        'person.last_name, person.first_name',
        { id: 'id', label: 'name', employmentRecordId: 'employmentRecordId', text: 'text' },
        //'person.last_name, person.first_name',
        'firstName lastName (employmentRecordId)',
        [
            ['person', 'firstName'],
            ['person', 'lastName'],
        ],
        (option: any) => `${option.text} ${option.employmentRecordId ? '(' + option.employmentRecordId + ')' : ''}`
    );

export const getColumnSearchByCodebookPerson = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.PERSON.SEARCH,
        'person.id',
        "_p.first_name || ' ' || last_name",
        'lastName, firstName',
        { id: 'id', label: 'name', text: 'text' },
        // 'lastName, firstName',
        'firstName lastName',
        [
            ['person', 'firstName'],
            ['person', 'lastName'],
        ],
        (option: any) => `${option.text}`
    );

export const getColumnSearchByCodebookPersonWithAlias = (aliasId: any, searchBy?: any) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.EMPLOYEE.SEARCH,
        aliasId,
        'person.firstName || person.lastName' + (searchBy ? searchBy : ''),
        'person.lastName, person.firstName',
        { id: 'id', label: 'text' },
        //'person.lastName, person.firstName',
        'person.lastName person.firstName',
        [
            ['createdBy', 'person.firstName'],
            ['createdBy', 'person.lastName'],
        ],
        (option: any) => `${option.text}`
    );

export const getColumnSearchByCodebookEmployeeWithAlias = (aliasId: any) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.EMPLOYEE.SEARCH,
        aliasId,
        'person.lastName,person.parent_name,person.firstName',
        'person.lastName, person.firstName, person.parent_name',
        {
            id: 'id',
            label: 'name',
            text: 'text',
            firstName: 'person.firstName',
            lastName: 'person.lastName',
            parentName: 'person.parentName',
        },
        //'person.lastName, person.firstName, person.parent_name ',
        'person.lastName person.firstName',
        [
            ['createdBy', 'person.firstName'],
            ['createdBy', 'person.lastName'],
        ],
        (option: any) => customEmployeeMobilizationRenderOption(option)
    );

export const getColumnSearchByCodebookBusinessUnit = (obligation = false) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.BUSINESS_UNIT.SEARCH,
        'businessUnit.id',
        'name,code',
        'name',
        { id: 'id', label: 'name', code: 'code', text: 'name' },
        //'name, code',
        '(code) name',
        [['code'], ['name']],
        (option: any) =>
            obligation
                ? `${option.code ? '(' + option.code + ') ' : ''} ${option.text}`
                : `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
    );

    export const getColumnSearchByCodebookBusinessUnitEmployee = (obligation = false) =>
        getColumnSearchByCodebook(
            CONSTANTS_REQ.BUSINESS_UNIT.SEARCH,
            'employee.businessUnitId',
            'name,code',
            'name',
            { id: 'id', label: 'name', code: 'code', text: 'name' },
            //'name, code',
            '(code) name',
            [['code'], ['name']],
            (option: any) =>
                obligation
                    ? `${option.code ? '(' + option.code + ') ' : ''} ${option.text}`
                    : `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
        );
    

export const getColumnSearchByCodebookVocationGroup = (id: any) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.VOCATION_GROUP.SEARCH,
        id,
        'name,code',
        'name',
        { id: 'id', label: 'name', code: 'code', text: 'name' },
        //'name, code',
        '(code) name',
        [['code'], ['name']],
        (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
    );

export const getColumnSearchByCodebookAssetCategory = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.ASSET_CATEGORY.SEARCH,
        'assetCategory.id',
        'name,code',
        'name',
        { id: 'id', label: 'name', code: 'code', text: 'name' },
        //'name, code',
        '(code) name',
        [['code'], ['name']],
        (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
    );
export const getColumnSearchByCodebookUserPerson = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.USER.SEARCH,
        'users.id',
        'user',
        'person.lastName, person.firstName',
        { id: 'id', label: 'name', text: 'text' },
        //'lastName, firstName',
        'firstName lastName',
        [
            ['person', 'firstName'],
            ['person', 'lastName'],
        ],
        (option: any) => `${option.text}`,
        undefined,
        () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
        undefined
    );

export const getColumnSearchByCodebookCreatedUser = (
    aliasId: any,
    customItemNameLabel: any,
    itemName: any,
    searchBy?: any
) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.PERSON.SEARCH,
        aliasId,
        '_p.firstName || _p.lastName' + (searchBy ? searchBy : ''),
        '_p.lastName, _p.firstName',
        { id: 'id', label: 'name', text: 'text' },
        customItemNameLabel,
        itemName,
        (option: any) => `${option.text}`
    );

export const getColumnSearchByCodebookUserPersonWithAlias = (aliasId: any) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.USER.SEARCH,
        aliasId,
        'user',
        'person.lastName, person.firstName',
        { id: 'id', label: 'name', text: 'text' },
        // 'lastName, firstName',
        'firstName lastName',
        [
            ['person', 'firstName'],
            ['person', 'lastName'],
        ],
        (option: any) => `${option.text}`
    );


export const getColumnSearchByCodebookWorkplace = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.WORKPLACE.SEARCH,
        'workplace.id',
        "vocation.name || ' ' || vocationDescription.name",
        'vocation.name, vocationDescription.name',
        {
            id: 'id',
            vocationName: 'vocation.name',
            vocationDescriptionName: 'vocationDescription.name',
        },
        'vocation.name vocationDescription.name',
        [
            ['vocation', 'name'],
            ['vocationDescription', 'name'],
        ],
        (option: any) => customWorkplaceMobilizationRenderOption(option)
    );

export const getColumnSearchWorkplaceDetails = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.WORKPLACE_DETAILS.SEARCH,
        'workplaceDetails.id',
        'vocation.name',
        'vocation.name',
        { id: 'id', label: 'text', vocationName: 'vocation.name', vocationDescription: 'vocationDescription.name' },
        //'person.last_name, person.first_name',
        undefined,
        undefined,

        (option: any) => option.vocationName + (option.vocationDescription ? ' - ' + option.vocationDescription : '')
    );

export const getColumnSearchByCodebookEmployeeNotification = (
    condition: string = "person.first_name || ' ' || person.last_name"
) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.EMPLOYEE.SEARCH,
        'notificationEmployee.id',
        "person.first_name || ' ' || person.last_name",
        'person.last_name, person.first_name',
        { id: 'id', label: 'name', employmentRecordId: 'employmentRecordId', text: 'text' },
        //'person.last_name, person.first_name',
        'firstName lastName (employmentRecordId)',
        [
            ['person', 'firstName'],
            ['person', 'lastName'],
        ],
        (option: any) => `${option.text} ${option.employmentRecordId ? '(' + option.employmentRecordId + ')' : ''}`
    );

export const getColumnSearchByCodebookCurrency = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.CURRENCY.SEARCH,
        'currency.id',
        'isoCode',
        'isoCode',
        { id: 'id', label: 'isoCode', isoCode: 'isoCode', text: 'text' },
        'isoCode',
        'isoCode',
        (option: any) => `${option.text}`,
        undefined,
        () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
        undefined
    );

export const getColumnSearchWithCode = (URL: string, id: any) =>
    getColumnSearchByCodebook(
        URL,
        id,
        'name,code',
        'name',
        { id: 'id', label: 'name', code: 'code', text: 'name' },
        //'name, code',
        '(code) name',
        [['code'], ['name']],
        (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
    );

export const getColumnSearchWithCodeAndDescription = (URL: string, id: any) =>
    getColumnSearchByCodebook(
        URL,
        id,
        'description,code',
        'description',
        { id: 'id', label: 'description', code: 'code', text: 'description' },
        //'name, code',
        '(code) description',
        [['code'], ['description']],
        (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
    );

export const getColumnSearchPointAdditionDecision = () =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.POINT_ADDITION_DECISION.SEARCH,
        'replacementDecision.id',
        'decisionNumber',
        'decisionNumber',
        {
            id: 'id',
            label: 'text',
            decisionNumber: 'decisionNumber',
        },
        // 'person.last_name, person.first_name',
        undefined,
        undefined,
        (option: any) => option.decisionNumber,
        undefined,
        () => [{ field: 'active', condition: 'equals_bool', value: 1 }]
    );

export const getColumnSearchPayrollBrut = (addition: boolean = false) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.PAYROLL_BRUT.SEARCH,
        'payrollBrut.id',
        'name',
        'name',
        { id: 'id', label: 'name', text: 'name' },
        // 'person.last_name, person.first_name',
        undefined,
        undefined,
        undefined,
        undefined,
        () => [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'addition', condition: 'equals_bool', value: addition ? 1 : 0 },
        ]
    );

export const getColumnSearchByCodebookCreatedEmployee = (
    id: string = 'createdByEmployee.id',
    condition: string = "person.first_name || ' ' || person.last_name || ' ' || employmentRecordId",
    firstNameLastName = 'person.last_name, person.first_name',
    itemName = [
        ['person', 'firstName'],
        ['person', 'lastName'],
    ]
) =>
    getColumnSearchByCodebook(
        CONSTANTS_REQ.EMPLOYEE.SEARCH,
        id,
        condition,
        firstNameLastName,
        { id: 'id', label: 'name', employmentRecordId: 'employmentRecordId', text: 'text' },
        'firstName lastName (employmentRecordId)',
        itemName,
        (option: any) => `${option.text} ${option.employmentRecordId ? '(' + option.employmentRecordId + ')' : ''}`
    );
