import { useState, useRef, useEffect } from 'react';
import { Form, Tabs } from 'antd';
import { geti18nText, NyDataEdit, NyRequestResolver, NySession } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import GeneralEditData from './GeneralEditData';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import GroupOrdersTable from './GroupOrdersTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    setDateFormat,
    setEnumFormat,
    formatCurrencyIsoCodeSearch,
    getDateFormat,
    getEnumFormat,
} from '../../../../../utils/Utils';
import { PayrollRights } from '../../../../../rights/payrollRights';

const { TabPane } = Tabs;

const PaymentOrdersGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('payment.orders.group.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [isCreate, setIsCreate] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isFormChange, setIsFormChange] = useState(false);
    const [disabledTable, setDisabledTable] = useState(true);
    const [groupId, setGroupId] = useState(undefined);
    const [paymentOrderStatusNone, setPaymentOrderStatusNone] = useState(true);
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);

    useEffect(() => {
        if (!props.value && props.visible) {
            getCompanyInfo();
        }
    }, [props]);

    const canCreate = () => {
        return PayrollRights.canCreatePaymentOrder();
    };

    function getCompanyInfo() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.PAYMENT_ORDER.GET_DEBTOR, {}).then((result: any) => {
            if (result.data) {
                let debtorData: any = {};
                debtorData['debtorName'] = result.data.name ? result.data.name : '';
                debtorData['debtorAddressLine1'] = result.data.addressLine1 ? result.data.addressLine1 : '';
                debtorData['debtorAddressLine2'] = result.data.addressLine2 ? result.data.addressLine2 : '';
                debtorData['debtorAccountIban'] = result.data.accountIban
                    ? { id: result.data.accountId, iban: result.data.accountIban }
                    : '';
                debtorData['debtorReference'] = result.data.reference ? result.data.reference : '';
                debtorData['debtorAgentBic'] = result.data.agentBic ? result.data.agentBic : '';

                form.setFieldsValue(debtorData);
            }
        });
    }

    async function setValues(dataForm: any) {
        setDisabledTable(dataForm.id ? false : true);
        setGroupId(dataForm.id);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('debtorName')) {
            setEditHeader(geti18nText('payment.orders.group.edit.title') + ' - ' + dataForm.debtorName);
        }

        if (dataForm.requestedExecutionDate)
            dataForm.requestedExecutionDate = setDateFormat(dataForm.requestedExecutionDate);
        if (dataForm.created) dataForm.created = setDateFormat(dataForm.created);

        if (dataForm.purposeCode !== null && dataForm.purposeCode !== undefined) {
            dataForm.purposeCode = setEnumFormat('SEPA_EXTERNAL_PURPOSE_TYPE_CODE', dataForm.purposeCode);
        }
        if (dataForm.paymentMethod) {
            dataForm.paymentMethod = setEnumFormat('SEPA_PAYMENT_METHOD_TYPE_CODE', dataForm.paymentMethod);
        }
        if (dataForm.priority !== undefined) {
            dataForm.priority = setEnumFormat('SEPA_PRIORITY_TYPE_CODE', dataForm.priority);
        }
        if (dataForm.debtorAccountIban) dataForm.debtorAccountIban = { id: -1, iban: dataForm.debtorAccountIban };
        if (dataForm.debtorAccountCurrencyCode) {
            dataForm.debtorAccountCurrencyCode = await formatCurrencyIsoCodeSearch(dataForm.debtorAccountCurrencyCode);
        }
        if (dataForm.paymentOrderBulk && dataForm.paymentOrderBulk.status) {
            setPaymentOrderStatusNone(dataForm.paymentOrderBulk.status === 1 ? true : false);
        } else {
            setPaymentOrderStatusNone(true);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setDisabledTable(true);
        setIsFormChange(false);
        setIsCreate(null);
        form.resetFields();
        setEditHeader(geti18nText('payment.orders.group.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            setIsCreate={setIsCreate}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.PAYMENT_ORDER_GROUP.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            shortcuts={true}
            checkIsFormChanged={true}
            setIsFormChange={setIsFormChange}
            {...props}
            normalize={(values: any) => {
                values.batchBooking = values.batchBooking ?? false;
                if (values.requestedExecutionDate)
                    values.requestedExecutionDate = getDateFormat(values.requestedExecutionDate);
                if (values.created) delete values.created;
                if (values.paymentMethod) values.paymentMethod = getEnumFormat(values.paymentMethod);
                if (values.priority) values.priority = getEnumFormat(values.priority);
                if (values.debtorAccountCurrencyCode)
                    values.debtorAccountCurrencyCode = values.debtorAccountCurrencyCode.isoCode;
                if (values.debtorAccountIban) values.debtorAccountIban = values.debtorAccountIban.iban;
                if (values.purposeCode) values.purposeCode = getEnumFormat(values.purposeCode);
                return values;
            }}
            hideActivationButtons={!paymentOrderStatusNone || !canCreate()}
            hideSubmitButton={!paymentOrderStatusNone || !canCreate()}
            fetchWhenChange={refreshItem}
        >
            <Tabs type="card">
                <TabPane
                    tab={
                        <span>
                            {geti18nText('payment.orders.group.general')}
                            {isFormChange ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="general"
                >
                    <GeneralEditData {...props} form={form} isCreate={isCreate} />
                </TabPane>
                <TabPane tab={geti18nText('payment.orders.group.orders')} key="orders" disabled={disabledTable}>
                    <GroupOrdersTable groupId={groupId} paymentOrderStatusNone={paymentOrderStatusNone} form={form} />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default PaymentOrdersGroupEdit;
