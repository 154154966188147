import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../utils/Constants';
import useTableSettings from '../../hooks/useTableSettings';

const JoppdCodeIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    defaultFilteredValue,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const initialColumns = [
        {
            title: geti18nText('joppdCode.table.column.id'),
            dataIndex: 'id',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('joppdCode.table.column.code'),
            dataIndex: 'code',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('joppdCode.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('joppdCode.table.column.page'),
            dataIndex: 'page',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('joppdCode.table.column.node'),
            dataIndex: 'node',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('joppdCode.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
            defaultFilteredValue: defaultFilteredValue,
        },
    ];

    return (
        <NyDataTable
            nyId="joppd-code"
            url={CONSTANTS_REQ.JOPPD_CODE.LIST}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            setDefaultPageSize={table.setDefaultPageSize()}
            hideNewButton={true}
            columns={initialColumns}
            scroll={scroll}
            setDefaultFilterValue={defaultFilteredValue}
            setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'asc' }}
            shortcuts={true}
            readonly={true}
        />
    );
};

export default JoppdCodeIndex;
