import { CheckCircleOutlined, ExclamationCircleOutlined, HighlightOutlined, SendOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Col, Modal, Row, Switch, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookUserPerson } from '../../../../utils/Filters';
import { JsonData, errorNotification, getEnumArrayStatus, okNotification } from '../../../../utils/Utils';
import { AdministrationRights } from '../../../../rights/administrationRights';

const NotificationOverviewIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();
    const table = useTableSettings();
    const [refresh, setRefresh] = useState(0);
    const [title, setTitle] = useState<any>(null);
    const [message, setMessage] = useState<any>(null);
    const [showCustomMessage, setShowCustomMessage] = useState<any>(false);
    const [customMessage, setCustomMessage] = useState<any>('');
    const [showSwitch, setShowSwitch] = useState<boolean>(false);
    const [messageModal, setMessageModal] = useState<boolean>(false);
    const [showHTML, setShowHTML] = useState<boolean>(false);
    const [showJSON, setShowJSON] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useHelper('common/notification_settings');

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };
    const canCreate = () => {
        return AdministrationRights.canCreateNotifications();
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportNotifications();
    };

    const closeModal = () => {
        setMessageModal(false);
        setShowHTML(false);
        setShowSwitch(false);
        setShowJSON(false);
        setTitle(null);
        setMessage(null);
        setCustomMessage(null);
        setShowCustomMessage(false);
        setMessageModal(false);
    };

    const getMailMessage = (id: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION.OVERVIEW + '/' + id, {}).then((result: any) => {
            if (result.status == RESPONSE.OK && result?.data?.content && result?.data?.content[0]?.mailMessage) {
                setMessage(result.data.content[0].mailMessage);
                setShowSwitch(true);
            } else {
                setMessage('No message');
            }
            setTitle(geti18nText('notifications.overview.table.column.mailMessage'));
            setMessageModal(true);
        });
    };

    const resend = (record: any) => {
        if (record?.id) {
            setLoading(true);
            NyRequestResolver.requestPost(CONSTANTS_REQ.NOTIFICATION.RESEND + '/' + record?.id, undefined, {
                notificationId: record?.id,
            }).then((result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                    setRefresh((refresh) => refresh + 1);
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
            });
        }
    };

    const createResend = (record: any) => {
        if (record?.id) {
            setLoading(true);
            NyRequestResolver.requestPost(CONSTANTS_REQ.NOTIFICATION.CREATE_RESEND + '/' + record?.id, undefined, {
                notificationId: record?.id,
            }).then((result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                    setRefresh((refresh) => refresh + 1);
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
            });
        }
    };

    const columns = [
        {
            title: geti18nText('notifications.overview.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('notifications.overview.table.column.created'),
            dataIndex: 'created',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return moment(record.created).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('notifications.overview.table.column.type'),
            dataIndex: 'notificationType',
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('NOTIFICATION_TYPE')),
            render: (text: any, record: any) => {
                if (record.notificationType) {
                    return geti18nText('app.enum.NOTIFICATION_TYPE.' + record.notificationType);
                }
            },
        },
        {
            title: geti18nText('notifications.overview.table.column.user'),
            dataIndex: "concat(person.first_name, ' ', person.last_name)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookUserPerson(),
            render: (text: any, record: any) => {
                if (record.user && record.user.person) {
                    return `${record.user.person.firstName} ${record.user.person.lastName}`;
                }
            },
        },
        {
            title: geti18nText('notifications.overview.table.column.mailStatus'),
            dataIndex: 'mailStatus',
            width: '10%',
            ...getColumnSearchOption(
                [{ id: '0', text: geti18nText('notifications.overview.status.not.ok') }],
                'not_equals_string'
            ),
            sorter: (a: any, b: any) => {},
            render: (mailStatus: any, record: any) => {
                if (mailStatus == '0') {
                    return <CheckCircleOutlined style={{ color: 'green', fontSize: '16px' }} />;
                } else {
                    if (record.mail) {
                        return (
                            <Tooltip title={geti18nText('app.default.details')}>
                                <ExclamationCircleOutlined
                                    style={{ color: 'red', fontSize: '16px' }}
                                    onClick={() => {
                                        setTitle(geti18nText('notifications.overview.table.column.mailStatus'));
                                        setMessage(mailStatus);
                                        setMessageModal(true);
                                    }}
                                />
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Tooltip title={geti18nText('app.default.details')}>
                                <ExclamationCircleOutlined
                                    style={{ color: 'orange', fontSize: '16px' }}
                                    onClick={() => {
                                        setTitle(geti18nText('notifications.overview.table.column.mailStatus'));
                                        setShowCustomMessage(true);
                                        if (record.mobile) {
                                            setCustomMessage(
                                                geti18nText('notifications.overview.table.column.messageNotSent')
                                            );
                                        } else {
                                            setCustomMessage(
                                                geti18nText(
                                                    'notifications.overview.table.column.mobileMailMessageNotSent'
                                                )
                                            );
                                        }
                                        setMessageModal(true);
                                    }}
                                />
                            </Tooltip>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('notifications.overview.table.column.mailAddress'),
            dataIndex: 'mailAddress',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('notifications.overview.table.column.mailMessage'),
            dataIndex: 'mailMessage',
            width: '7%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.mail) {
                    return (
                        <Tooltip title={geti18nText('app.default.details')}>
                            <CheckCircleOutlined
                                style={{ color: 'green', fontSize: '16px' }}
                                onClick={() => {
                                    getMailMessage(record?.id);
                                }}
                            />
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('notifications.overview.table.column.mobileMessage'),
            dataIndex: 'mobileMessage',
            width: '7%',
            sorter: (a: any, b: any) => {},
            render: (mobileMessage: any, record: any) => {
                if (mobileMessage) {
                    if (record.mobile) {
                        return <CheckCircleOutlined style={{ color: 'green', fontSize: '16px' }} />;
                    }
                }
            },
        },
        {
            title: geti18nText('notifications.overview.table.column.content'),
            dataIndex: 'content',
            width: '7%',
            sorter: (a: any, b: any) => {},
            render: (content: any) => {
                if (content) {
                    return (
                        <Tooltip title={geti18nText('app.default.details')}>
                            <CheckCircleOutlined
                                style={{ color: 'green', fontSize: '16px' }}
                                onClick={() => {
                                    setTitle(geti18nText('notifications.overview.table.column.content'));
                                    setMessage(content?.value);
                                    setMessageModal(true);
                                    setShowJSON(true);
                                }}
                            />
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                return (
                    <>
                        {canCreate() && (
                            <>
                                {(record.mail || record.mobile) && (
                                    <NyModalConfirm
                                        title={geti18nText('notifications.overview.resend.confirm')}
                                        onConfirm={() => {
                                            resend(record);
                                        }}
                                    >
                                        <Tooltip title={geti18nText('notifications.overview.resend')}>
                                            <SendOutlined style={{ marginRight: '10px', fontSize: '16px' }} />
                                        </Tooltip>
                                    </NyModalConfirm>
                                )}
                                <NyModalConfirm
                                    title={geti18nText('notifications.overview.createResend.confirm')}
                                    onConfirm={() => {
                                        createResend(record);
                                    }}
                                >
                                    <Tooltip title={geti18nText('notifications.overview.createResend')}>
                                        <HighlightOutlined style={{ fontSize: '16px' }} />
                                    </Tooltip>
                                </NyModalConfirm>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <NyDataTable
                    nyId="notification-overview"
                    url={CONSTANTS_REQ.NOTIFICATION.OVERVIEW}
                    showRecordModal={false}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    hideButtons={true}
                    fetchWhenChange={refresh}
                    addNewButtonText={undefined}
                    columns={columns}
                    setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                    scroll={scroll}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    shortcuts={true}
                    nyTestId="notifications-overview-table"
                    exportCSV={canExportCSV()}
                    CSVFileName={geti18nText('notifications.overview.table.header')}
                    colCSVCustomization={csvColumnCustomisation()}
                    csvColumns={csvColumns()}
                    readonly={true}
                    headerTitle={geti18nText('notifications.overview.table.header')}
                />
            )}
            {messageModal && (
                <Modal
                    width={900}
                    title={title}
                    maskClosable={false}
                    visible={messageModal}
                    okText={geti18nText('app.default.button.ok')}
                    onCancel={closeModal}
                    onOk={closeModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Row gutter={24}>
                        {showCustomMessage ? (
                            <Col span={24}>{customMessage}</Col>
                        ) : (
                            <Col span={24}>
                                {showJSON ? (
                                    <JsonData data={message}></JsonData>
                                ) : (
                                    <>
                                        {showSwitch && (
                                            <Switch
                                                style={{ marginBottom: '10px' }}
                                                onChange={(checked: boolean) => setShowHTML(checked)}
                                                checkedChildren={geti18nText('notifications.overview.text')}
                                                unCheckedChildren={geti18nText('notifications.overview.html')}
                                            />
                                        )}
                                        {showHTML || !showSwitch ? (
                                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>{message}</div>
                                        ) : (
                                            <div
                                                style={{ maxHeight: '500px', overflowY: 'auto' }}
                                                dangerouslySetInnerHTML={{ __html: message }}
                                            />
                                        )}
                                    </>
                                )}
                            </Col>
                        )}
                    </Row>
                </Modal>
            )}
        </>
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('notifications.overview.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('notifications.overview.table.column.created'),
            dataIndex: 'created',
        },
        {
            title: geti18nText('notifications.overview.table.column.type'),
            dataIndex: 'notificationType',
        },
        {
            title: geti18nText('notifications.overview.table.column.user'),
            dataIndex: 'user',
        },
        {
            title: geti18nText('notifications.overview.table.column.mailStatus'),
            dataIndex: 'mailStatus',
        },
        {
            title: geti18nText('notifications.overview.table.column.mailAddress'),
            dataIndex: 'mailAddress',
        },
        {
            title: geti18nText('notifications.overview.table.column.mailMessage'),
            dataIndex: 'mailMessage',
        },
        {
            title: geti18nText('notifications.overview.table.column.mobileMessage'),
            dataIndex: 'mobileMessage',
        },
        {
            title: geti18nText('notifications.overview.table.column.content'),
            dataIndex: 'content',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            created: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            user: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            notificationType: (value: any) => {
                return value != undefined && geti18nText('app.enum.NOTIFICATION_TYPE.' + value);
            },
        },
        {
            mailStatus: (value: any) => {
                return value == 0 || value == '0' ? geti18nText('app.default.button.ok') : value;
            },
        },
        {
            mobileMessage: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            content: (value: any) => {
                return value?.value;
            },
        },
    ];
};

export default NotificationOverviewIndex;
