import { CarOutlined, CheckCircleOutlined, InfoCircleOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrGet, geti18nText } from '@nybble/nyreact';
import { Alert, Badge, Card, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useEnum from '../../../hooks/useEnum';
import { TravelWarrantRights } from '../../../rights/travelWarrantRights';
import { RootState } from '../../../rootReducer';
import { setProp } from '../../../slices/propSlice';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType } from '../../../utils/Utils';

const { Title } = Typography;
const TravelWarrantLightWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const widgetColor = getColorFromNotificationType(settings, 'TRAVEL_WARRANT_APPROVAL');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const {
        data: countWaitingForApproval,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
    });

    const {
        data: travelWarrantsApprovedAdvance,
        isError: errorWarrantsApprovedAdvance,
        isLoading: loadingWarrantsApprovedAdvance,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.APPROVED,
        advanceApproved: true,
    });

    const {
        data: travelWarrantsApproved,
        isError: errorWarrantsApproved,
        isLoading: loadingWarrantsApproved,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION,
    });

    const {
        data: travelWarrantsNotCalculated,
        isError: errorWarrantsNotCalculated,
        isLoading: loadingWarrantsNotCalculated,
    } = UseRequestSwrGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
        status: travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL,
    });

    const gridStyle: any = {
        width: '25%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '14px',
        paddingTop: '4px',
        height: '100%',
        display: 'inline-block',
    };

    return (
        <Card
            className="ny-widget-card"
            title={geti18nText('menu.travelWarrant')}
            headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
            bodyStyle={{ height: '45%', overflowY: 'hidden', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
            type="inner"
            extra={
                <>
                    {canCreate() && (
                        <Tooltip placement="top" title={geti18nText('travelWarrant.table.add')}>
                            <CarOutlined
                                className="ny-card-icon"
                                onClick={() => {
                                    history.push('/travel-warrant/travelWarrant/create');
                                }}
                            />
                        </Tooltip>
                    )}
                </>
            }
        >
            {loading || loadingWarrantsApprovedAdvance || loadingWarrantsApproved || loadingWarrantsNotCalculated ? (
                <div style={{ paddingTop: 20 }}>
                    <NySpinner />
                </div>
            ) : error ||
              errorWarrantsApprovedAdvance ||
              errorWarrantsApproved ||
              errorWarrantsNotCalculated ||
              (countWaitingForApproval &&
                  countWaitingForApproval.ALL < 0 &&
                  travelWarrantsApprovedAdvance &&
                  travelWarrantsApprovedAdvance.ALL < 0 &&
                  travelWarrantsApproved &&
                  travelWarrantsApproved.ALL < 0 &&
                  travelWarrantsNotCalculated &&
                  travelWarrantsNotCalculated.ALL < 0) ? (
                <div style={{ paddingTop: 10 }}>
                    <Alert
                        className="ny-dashboard-light-empty"
                        icon={<InfoCircleOutlined style={{ color: `${widgetColor}` }} />}
                        showIcon
                        style={{ background: `${widgetColor}11` }}
                        description={
                            <>
                                <Title level={5}>{geti18nText('app.default.nodata')}</Title>
                            </>
                        }
                    />
                </div>
            ) : (
                <>
                    {countWaitingForApproval && countWaitingForApproval.ALL >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travelWarrantPersonal.requests')}>
                                <Badge
                                    count={countWaitingForApproval && countWaitingForApproval.ALL}
                                    showZero
                                    size="small"
                                >
                                    <CarOutlined
                                        style={{ fontSize: '2em' }}
                                        className="statistic-card-light"
                                        onClick={() => {
                                            dispatch(setProp(3));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {travelWarrantsApprovedAdvance && travelWarrantsApprovedAdvance.ALL >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travel.warrant.approved.advances')}>
                                <Badge
                                    count={travelWarrantsApprovedAdvance && travelWarrantsApprovedAdvance.ALL}
                                    showZero
                                    size="small"
                                >
                                    <CheckCircleOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => {
                                            dispatch(setProp(4));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {travelWarrantsApproved && travelWarrantsApproved.ALL >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travel.warrant.approved')}>
                                <Badge
                                    count={travelWarrantsApproved && travelWarrantsApproved.ALL}
                                    showZero
                                    size="small"
                                >
                                    <IssuesCloseOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => {
                                            dispatch(setProp(5));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    {travelWarrantsNotCalculated && travelWarrantsNotCalculated.ALL >= 0 && (
                        <div style={gridStyle}>
                            <Tooltip title={geti18nText('travel.warrant.waitingForReportApproval')}>
                                <Badge
                                    count={travelWarrantsNotCalculated && travelWarrantsNotCalculated.ALL}
                                    showZero
                                    size="small"
                                >
                                    <InfoCircleOutlined
                                        style={{ fontSize: '2em' }}
                                        className={`statistic-card-light statistic-card-warning-light`}
                                        onClick={() => {
                                            dispatch(setProp(6));
                                            history.push('/travel-warrant/travelWarrant');
                                        }}
                                    />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

export default TravelWarrantLightWidget;
