import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getEnumArray } from '../../../../../utils/Utils';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import MealMenuEdit from './edit';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealMenusTableIndex = ({ refreshTable, setRefreshTable, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const table = useTableSettings();

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const dayOptions = [
        {
            id: 1,
            text: geti18nText('app.enum.MENU_DAY.' + 1),
        },
        {
            id: 2,
            text: geti18nText('app.enum.MENU_DAY.' + 2),
        },
        {
            id: 3,
            text: geti18nText('app.enum.MENU_DAY.' + 3),
        },
        {
            id: 4,
            text: geti18nText('app.enum.MENU_DAY.' + 4),
        },
        {
            id: 5,
            text: geti18nText('app.enum.MENU_DAY.' + 5),
        },
        {
            id: 6,
            text: geti18nText('app.enum.MENU_DAY.' + 6),
        },
        {
            id: 7,
            text: geti18nText('app.enum.MENU_DAY.' + 7),
        },
    ];

    const columns = [
        {
            title: geti18nText('meal.menu.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.menu.table.column.name'),
            dataIndex: 'name',
            width: '24%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('meal.menu.table.column.restaurant'),
            dataIndex: ['restaurant', 'name'],
            width: '24%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.RESTAURANT.SEARCH, 'restaurant.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
            render: (name: any, record: any) => {
                if (record && record.restaurant && record.restaurant.name) {
                    return record.restaurant.name;
                }
            },
        },
        {
            title: geti18nText('meal.menu.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArray('MENU_TYPE')),
            render: (type: any, record: any) => {
                if (record && record.type) {
                    return geti18nText('app.enum.MENU_TYPE.' + record.type);
                }
            },
        },
        {
            title: geti18nText('meal.menu.table.column.dayOfWeek'),
            dataIndex: 'day',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(dayOptions),
            render: (day: any, record: any) => {
                if (record && record.day) {
                    return geti18nText('app.enum.MENU_DAY.' + record.day);
                }
            },
        },
        {
            title: geti18nText('meal.menu.table.column.validFrom'),
            dataIndex: 'validFrom',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validFrom) {
                    return moment(record.validFrom).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('meal.menu.table.column.validTo'),
            dataIndex: 'validTo',
            width: '8%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.validTo) {
                    return moment(record.validTo).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('meal.menu.table.column.active'),
            dataIndex: 'active',
            width: '8%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="meal-menus"
                url={CONSTANTS_REQ.MEAL_MENU.LIST}
                addNewButtonText={geti18nText('meal.menu.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                modalComponent={MealMenuEdit}
                columns={columns}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? { order: 'validFrom', orderType: 'desc' }}
                hideNewButton={!canCreate()}
                shortcuts={true}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.meals.meal.menu')}
                colCSVCustomization={csvColumnCustomisation()}
                editProps={{ setRefreshTable, refreshTable, scroll: { y: 400, x: 500 }, sortOrder: null }}
                fetchWhenChange={refreshTable}
            />
        </>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            day: (value: any) => {
                return value != undefined && geti18nText('app.enum.MENU_DAY.' + value);
            },
        },
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.MENU_TYPE.' + value);
            },
        },
    ];
};

export default MealMenusTableIndex;
