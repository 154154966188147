import { geti18nText } from '@nybble/nyreact';
import { Col, List, Row, Tooltip } from 'antd';
import { numberFormat } from '../../../../utils/Utils';
import { WarningTwoTone } from '@ant-design/icons';

const EmployeePaymentGeneral = ({ generalData }: any) => {
    return (
        <>
            <Row gutter={24}>
                <Col offset={2} span={20}>
                    <List
                        itemLayout="horizontal"
                        dataSource={generalData}
                        size="small"
                        renderItem={(item: any) => (
                            <List.Item>
                                <List.Item.Meta title={geti18nText('employee.payment.edit.' + item.title)} />
                                <span>{numberFormat(item.value)}</span>
                                <span style={{ width: '10px' }}>
                                    {item.warning && (
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <ul style={{ marginBottom: '0px', paddingLeft: '20px' }}>
                                                    {item.warning.split(';').map((element: any) => {
                                                        return (
                                                            <li>
                                                                {geti18nText('employee.payment.validation.' + element)}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            }
                                        >
                                            <WarningTwoTone
                                                twoToneColor="#faad14"
                                                style={{ paddingLeft: '5px', fontSize: 'medium' }}
                                            />
                                        </Tooltip>
                                    )}
                                </span>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default EmployeePaymentGeneral;
