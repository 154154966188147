import { geti18nText } from '@nybble/nyreact';
import { Card, Col, Empty, Row, Table, Tabs } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { AssetRights } from '../../../../../rights/assetRights';
import { RootState } from '../../../../../rootReducer';
import { getOrderStatusIcon, userIsNotificationRecipient } from '../../../../../utils/Utils';
import AssetOrderApproval from './AssetOrderApproval';
import AssetOrderTable from './AssetOrderTable';

const { TabPane } = Tabs;

const AssetOrderIndex = () => {
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const { employee } = useSelector((state: RootState) => state.employee);

    useHelper('asset/asset_order');

    const itemColumns: any = [
        {
            title: geti18nText('inventoryOrder.edit.code'),
            dataIndex: ['items', 'code'],
            width: '150px',
        },
        {
            title: geti18nText('inventoryOrder.edit.name'),
            dataIndex: ['items', 'name'],
            width: '300px',
        },
        {
            title: geti18nText('inventoryOrder.edit.orderQuantity'),
            dataIndex: ['asset_order_items', 'quantity'],
        },
        {
            title: geti18nText('inventoryOrder.edit.releasedQuantity'),
            dataIndex: ['asset_order_items', 'released_quantity'],
            render: (text: any, record: any) => {
                return record?.asset_order_items?.released_quantity > 0
                    ? record.asset_order_items.released_quantity
                    : 0;
            },
        },
    ];

    const getItemsContent = (record: any) => {
        const itemsList = record?.assetOrderItemList ? JSON.parse(record.assetOrderItemList) : [];
        return (
            <Card
                title={
                    <>
                        <div style={{ float: 'left' }}>
                            {geti18nText('inventoryOrder.divider.2')}
                            {record.ord && record.date && (
                                <>
                                    {' '}
                                    {' ('}
                                    {geti18nText('inventoryOrder.table.column.ord')}{' '}
                                    {record.ord + '/' + moment(record.date).format('YYYY')}
                                    {')'}
                                </>
                            )}
                        </div>
                        <div style={{ float: 'right' }}>{getOrderStatusIcon(record?.status)}</div>
                    </>
                }
                className="ny-widget-card"
                type="inner"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        {record?.id ? (
                            <Table size={'small'} dataSource={itemsList} columns={itemColumns} pagination={false} />
                        ) : (
                            <Empty />
                        )}
                    </Col>
                </Row>
            </Card>
        );
    };

    return (
        <Tabs type="card">
            {AssetRights.canViewOrderAsset() && (
                <TabPane tab={geti18nText('servicesOrder.tab.1')} key="1">
                    <AssetOrderTable getItemsContent={getItemsContent} scroll={{ y: 540, x: 800 }} />
                </TabPane>
            )}
            {(AdministrationRights.isAdmin() ||
                userIsNotificationRecipient(employee, notificationType.ASSET_ORDER)) && (
                <TabPane tab={geti18nText('servicesOrder.tab.2')} key="2">
                    <AssetOrderApproval getItemsContent={getItemsContent} scroll={{ y: 583, x: 800 }} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default AssetOrderIndex;
