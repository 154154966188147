import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getDateFormat, getEnumArrayStatus, numberFormat } from '../../../../../utils/Utils';
import useTableSettings from '../../../../../hooks/useTableSettings';

const PaymentOrdersTable = ({ props, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const table = useTableSettings();
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'paymentOrderBulk.id', condition: 'equals', value: props.bulkId },
        ];
    };

    const initialColumns = [
        {
            title: geti18nText('payment.orders.individual.table.column.id'),
            dataIndex: 'id',
            width: '80px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.dueDate'),
            dataIndex: 'dueDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.dueDate) {
                    return getDateFormat(record.dueDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorName'),
            dataIndex: 'debtorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.debtorAccountIban'),
            dataIndex: 'debtorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorName'),
            dataIndex: 'creditorName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorAccountIban'),
            dataIndex: 'creditorAccountIban',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.creditorRemittanceInfo'),
            dataIndex: 'creditorRemittanceInfo',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('payment.orders.individual.table.column.amount'),
            dataIndex: 'amount',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                if (amount) {
                    return numberFormat(amount);
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.paymentDate'),
            dataIndex: 'completedDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.completedDate) {
                    return getDateFormat(record.completedDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('payment.orders.individual.table.column.status'),
            dataIndex: 'status',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('PAYMENT_ORDER_STATUS_TYPE')),
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.PAYMENT_ORDER_STATUS_TYPE.' + record.status);
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="payment-orders-items-table"
            url={CONSTANTS_REQ.PAYMENT_ORDER.LIST}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            showRecordModal={false}
            hideNewButton={true}
            columns={initialColumns}
            setDefaultPageSize={table.setDefaultPageSize()}
            readonly={true}
        />
    );
};

export default PaymentOrdersTable;
