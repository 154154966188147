import { NyDataEdit, geti18nText, NySearchField, NyDatePicker } from '@nybble/nyreact';
import { Col, Form, Input, Row, Typography } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, setDateFormat } from '../../../../utils/Utils';
import moment from 'moment';
import HumanCodebooksSearch from '../../../../components/human-codebooks/search';
import useEnum from '../../../../hooks/useEnum';

const EmployeeEvaluationNeedsPlanEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.evaluation.needs.plan.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.dueDate) {
            dataForm.dueDate = setDateFormat(dataForm.dueDate);
        }

        if (dataForm.employee) {
            setEditHeader(
                geti18nText('employee.evaluation.needs.plan.edit.title') +
                    ' - ' +
                    ` ${dataForm.employee?.person?.firstName ? ` ${dataForm.employee.person.firstName} ` : ``}  ${
                        dataForm?.employee?.person?.lastName ? ` ${dataForm.employee.person.lastName} ` : ``
                    } 
    ${dataForm.employee?.employmentRecordId ? ` (${dataForm.employee.employmentRecordId}) ` : ``}`
            );
        } else {
            setEditHeader(geti18nText('employee.evaluation.needs.plan.edit.title'));
        }

        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.evaluation.needs.plan.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_NEEDS_PLAN.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.dueDate) values.dueDate = getDateFormat(values.dueDate);

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="active" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="employeeEvaluation" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>

                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.evaluation.needs.plan.edit.field')}
                        name="field"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.evaluation.needs.plan.edit.support')}
                        name="support"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.evaluation.needs.plan.edit.dueDate')}
                        name="dueDate"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        initialValue={moment().startOf('day')}
                    >
                        <NyDatePicker style={{ width: '100%' }} nyTestId="input-due-date" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        label={geti18nText('employee.evaluation.needs.plan.edit.type')}
                        codebooksType={enCodebookType.EDUCATION_LEVEL}
                        formItemName={'type'}
                        required={true}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeEvaluationNeedsPlanEdit;
