import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Descriptions, Form, Row } from 'antd';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customEmployeeRenderOption } from '../../../../../utils/Utils';
import EmployeeIndex from '../../employee';

const EvaluationSummary = ({
    id,
    evaluation,
    form,
    superiorManagerIntermediary = false,
    superiorManager = undefined,
    setSuperiorManager,
    setDirectManager,
    canChangeManagers = false,
    save,
}: any) => {
    const directManager =
        evaluation?.directManager?.text?.props?.children &&
        evaluation?.directManager?.text?.props?.children[0] &&
        evaluation?.directManager?.text?.props?.children[0]?.props?.children;

    const directManagerERID =
        evaluation?.directManager?.text?.props?.children &&
        evaluation?.directManager?.text?.props?.children[1] &&
        evaluation?.directManager?.text?.props?.children[1]?.props?.children &&
        evaluation?.directManager?.text?.props?.children[1]?.props?.children[1] &&
        evaluation?.directManager?.text?.props?.children[1]?.props?.children[1]?.props?.children &&
        evaluation?.directManager?.text?.props?.children[1]?.props?.children[1]?.props?.children[1].split(': ');

    const labelStyle = { width: '250px' };
    const style = { marginBottom: '15px' };

    return (
        <>
            {id && evaluation && (
                <Form layout="vertical" form={form}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Descriptions
                                bordered
                                column={1}
                                size="small"
                                title={geti18nText('employee.evaluation.edit.employee')}
                                style={style}
                                labelStyle={labelStyle}
                            >
                                <Descriptions.Item label={geti18nText('employee.evaluation.edit.firstNameLastName')}>
                                    {evaluation?.employee?.person?.firstName +
                                        ' ' +
                                        evaluation?.employee?.person?.lastName}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.evaluation.edit.employmentRecordId')}>
                                    {evaluation?.employee?.employmentRecordId}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.evaluation.edit.businessUnit')}>
                                    {evaluation?.employee?.businessUnit?.name +
                                        (evaluation?.employee?.businessUnit?.code
                                            ? ' (' + evaluation?.employee?.businessUnit?.code + ')'
                                            : '')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.evaluation.edit.vocation')}>
                                    {evaluation?.employee?.vocation?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.evaluation.edit.vocationDescription')}>
                                    {evaluation?.employee?.vocationDescription?.name}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={12}>
                            <Descriptions
                                bordered
                                column={1}
                                size="small"
                                title={geti18nText('employee.evaluation.tab.general')}
                                style={style}
                                labelStyle={labelStyle}
                            >
                                <Descriptions.Item label={geti18nText('employee.evaluation.edit.dueDate')}>
                                    {evaluation?.dueDate && moment(evaluation?.dueDate).format('DD.MM.YYYY.')}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={geti18nText('employee.evaluation.edit.superiorManagerIntermediary')}
                                >
                                    {superiorManagerIntermediary
                                        ? geti18nText('app.default.button.yes')
                                        : geti18nText('app.default.button.no')}
                                </Descriptions.Item>
                            </Descriptions>

                            {canChangeManagers(false, true) ? (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={
                                                <b style={{ fontSize: '14px' }}>
                                                    {geti18nText('employee.evaluation.edit.directManager')}
                                                </b>
                                            }
                                            name="directManager"
                                            rules={[
                                                {
                                                    required: canChangeManagers(false, true),
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                    businessUnit: 'businessUnit',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                customItemNameLabel={'firstName lastName'}
                                                SearchPopupComponent={
                                                    <EmployeeIndex disabled={true} filteredForTravel parentKey="evaluation-summary"/>
                                                }
                                                nyTestId="employee"
                                                renderOption={customEmployeeRenderOption}
                                                order="person.last_name, person.first_name"
                                                mustGetPopupContainer={false}
                                                onChange={(value: any) => {
                                                    setDirectManager(value?.id > 0 ? value : null);
                                                    save();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Descriptions
                                    bordered
                                    column={1}
                                    size="small"
                                    title={geti18nText('employee.evaluation.edit.directManager')}
                                    style={style}
                                    labelStyle={labelStyle}
                                >
                                    <Descriptions.Item label={geti18nText('employee.evaluation.edit.directManager')}>
                                        {directManager}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={geti18nText('employee.evaluation.edit.employmentRecordId')}
                                    >
                                        {directManagerERID && directManagerERID[1]}
                                    </Descriptions.Item>
                                </Descriptions>
                            )}
                            {canChangeManagers(true, false) ? (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={
                                                <b style={{ fontSize: '14px' }}>
                                                    {geti18nText('employee.evaluation.edit.superiorManager')}
                                                </b>
                                            }
                                            name="superiorManager"
                                            rules={[
                                                {
                                                    required: canChangeManagers(true, false),
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                    businessUnit: 'businessUnit',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                customItemNameLabel={'firstName lastName'}
                                                SearchPopupComponent={
                                                    <EmployeeIndex disabled={true} filteredForTravel />
                                                }
                                                nyTestId="employee"
                                                renderOption={customEmployeeRenderOption}
                                                order="person.last_name, person.first_name"
                                                mustGetPopupContainer={false}
                                                onChange={(value: any) => {
                                                    setSuperiorManager(value?.id > 0 ? value : null);
                                                    save();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                superiorManagerIntermediary && (
                                    <Descriptions
                                        bordered
                                        column={1}
                                        size="small"
                                        title={geti18nText('employee.evaluation.edit.superiorManager')}
                                        style={style}
                                        labelStyle={labelStyle}
                                    >
                                        <Descriptions.Item
                                            label={geti18nText('employee.evaluation.edit.superiorManager')}
                                        >
                                            {superiorManager &&
                                                superiorManager.person &&
                                                superiorManager.person.firstName +
                                                    ' ' +
                                                    superiorManager.person.lastName}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={geti18nText('employee.evaluation.edit.employmentRecordId')}
                                        >
                                            {superiorManager && superiorManager.employmentRecordId}
                                        </Descriptions.Item>
                                    </Descriptions>
                                )
                            )}
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};
export default EvaluationSummary;
