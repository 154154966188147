import { ExclamationCircleFilled } from '@ant-design/icons';
import { NyDataTable, NyRequestResolver, RESPONSE, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Modal, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getColumnSearchByCodebookPerson } from '../../../../utils/Filters';
import { errorNotification, okNotification } from '../../../../utils/Utils';

const UserCardIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
    moduleAccessRight = [],
}: any) => {
    const table = useTableSettings();
    const [data, setData] = useState<any>();
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
    const [refreshTable, setRefreshTable] = useState<any>(0);
    const [form] = Form.useForm();

    useHelper('human/attendance-registration');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    const columns = [
        {
            title: geti18nText('attendance.card.table.column.employee'),
            dataIndex: "concat(person.first_name,' ', person.last_name)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookPerson(),
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div>
                            {record?.person?.firstName +
                                ' ' +
                                record?.person?.lastName +
                                (record?.employee?.employmentRecordId
                                    ? ' (' + record.employee.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('attendance.card.table.column.cardNumber'),
            dataIndex: ['attendanceUserCard', 'cardNumber'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.card.table.column.pin'),
            dataIndex: ['attendanceUserCard', 'pin'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
    ];

    const save = (event: any) => {
        let cardNumber = form.getFieldValue('cardNumber');

        NyRequestResolver.requestPost(CONSTANTS_REQ.ATTENDANCE_USER_CARD.EDIT, undefined, {
            cardNumber: cardNumber ?? '',
            user: { id: data.id },
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                form.resetFields();
                okNotification();
                setEditModalVisible(false);
                setConfirmVisible(false);
                setData(undefined);
                setRefreshTable((prev: any) => prev + 1);
            } else {
                errorNotification(result.data);
            }
        });
    };

    return (
        <>
            <NyDataTable
                nyId="user-card-index"
                url={CONSTANTS_REQ.DEVICE.USER_CARD_LIST}
                addNewButtonText={geti18nText('attendance.card.table.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                onRowSelect={(data: any) => {
                    form.setFieldsValue({ cardNumber: data?.attendanceUserCard?.cardNumber });
                    form.setFieldsValue({ pin: data?.attendanceUserCard?.pin });
                    setData(data);
                    setEditModalVisible(true);
                }}
                onDataLoaded={table.onDataLoaded}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                columns={columns}
                scroll={scroll}
                addedData={{ data: data }}
                fetchWhenChange={refreshTable}
                hideNewButton={true}
                shortcuts={true}
                CSVFileName={geti18nText('attendance.card.tab.title')}
                exportCSV={canExportCSV()}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
            />
            {editModalVisible && (
                <Modal
                    open={editModalVisible}
                    title={geti18nText('attendance.card.edit.title')}
                    onOk={(event: any) => {
                        if (!form.getFieldValue('cardNumber')) {
                            setConfirmVisible(true);
                        } else {
                            save(event);
                        }
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={(event: any) => {
                        form.resetFields();
                        setData(undefined);
                        setEditModalVisible(false);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    style={{ display: 'vertical' }}
                    okText={geti18nText('app.default.button.save')}
                >
                    <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Form.Item name={['user']} style={{ display: 'none' }}>
                                <Input defaultValue={data?.user} />
                            </Form.Item>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('attendance.card.edit.employee')}
                                    name="user"
                                    rules={[
                                        {
                                            required: false,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Title level={5}>
                                        {`${data?.person?.firstName ?? ``} ${data?.person?.lastName ?? ``} ${
                                            data?.employee?.employmentRecordId
                                                ? `(` + data?.employee?.employmentRecordId + `)`
                                                : ``
                                        }`}
                                    </Title>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('attendance.card.edit.cardNumber')} name="cardNumber">
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('attendance.card.edit.pin')} name="pin">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
            {confirmVisible && (
                <Modal
                    closable={true}
                    open={confirmVisible}
                    onOk={(event: any) => {
                        save(event);

                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    width={400}
                    onCancel={() => {
                        setConfirmVisible(false);
                    }}
                    cancelText={geti18nText('app.default.button.no')}
                    okText={geti18nText('app.default.button.yes')}
                >
                    <div style={{ fontSize: 16, fontStyle: 'bold', display: 'flex', marginTop: '8px' }}>
                        {' '}
                        <ExclamationCircleFilled style={{ color: 'orange', fontSize: 22 }} />{' '}
                        <div style={{ marginLeft: '8px' }}>{geti18nText('attendance.card.edit.delete.confirm')}</div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default UserCardIndex;

export const csvColumnCustomisation: any = () => {
    return [
        {
            person: (value: any) => {
                return value ? value?.firstName + ' ' + value?.lastName : '';
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('attendance.card.table.column.employee'),
            dataIndex: 'person',
        },
        {
            title: geti18nText('attendance.table.column.employmentRecordId'),
            dataIndex: ['employee', 'employmentRecordId'],
        },
        {
            title: geti18nText('attendance.card.table.column.cardNumber'),
            dataIndex: ['attendanceUserCard', 'cardNumber'],
        },
    ];
};
