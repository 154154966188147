import { geti18nText } from '@nybble/nyreact';

var IBAN = require('iban');

export const JMBGCheck = function (JMBG: String) {
    let output = [];
    let number = JMBG.toString();

    for (let i = 0, len = number.length; i < len; i += 1) {
        output.push(+number.charAt(i));
    }

    let sum =
        (7 * output[0] +
            6 * output[1] +
            5 * output[2] +
            4 * output[3] +
            3 * output[4] +
            2 * output[5] +
            7 * output[6] +
            6 * output[7] +
            5 * output[8] +
            4 * output[9] +
            3 * output[10] +
            2 * output[11]) %
        11;

    if ((sum > 1 && 11 - sum == output[12]) || (11 - sum && output[12]) == 0) {
        return true;
    } else {
        return false;
    }
};

export const oibCheck = function (oib: String) {
    let checkDigit, num;

    let code = oib.toString();

    if (code.length === 13 && code.substr(0, 2).toUpperCase() === 'HR') {
        code = code.substr(2, 11);
    } else if (code.length !== 11) {
        return false;
    }

    let numCheck = parseInt(code, 10);
    if (isNaN(numCheck)) {
        return false;
    }

    num = 10;
    for (let i = 0; i < 10; i++) {
        num = num + parseInt(code.substr(i, 1), 10);
        num = num % 10;
        if (num === 0) {
            num = 10;
        }
        num *= 2;
        num = num % 11;
    }

    checkDigit = 11 - num;
    if (checkDigit === 10) {
        checkDigit = 0;
    }

    return parseInt(code.substr(10, 1), 10) === checkDigit;
};

export const IBANCheck = function (iban: String) {
    if (IBAN.isValid(iban)) {
        return true;
    }

    return false;
};

export const bicCheck = function (bic: string) {
    const BIC_REGEX = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    return BIC_REGEX.test(bic);
};

export const licenceCheck = function (licence: string) {
    const LICENCE_REGEX = /^[A-Za-zĆČĐŠŽćčđšž]{2}[0-9]{3,4}[A-Za-zĆČĐŠŽćčđšž]{1,2}$/;
    return LICENCE_REGEX.test(licence);
};

export const itemCodeCheck = function (code: string) {
    if (code.length > 20) return false;
    return true;
};

export const onlyNumberCheck = function (str: string) {
    let countZero = 0;
    for (let x = 0, length = str.length; x < length; x++) {
        var letter = str.charAt(x)
        if (letter == '0') {
            countZero++;
        }
    }
    if (Number(str) || str.length == countZero) return true;
    return false;
};

export const negativeNumberCheck = function (str: string) {
    if (Number(str) >= 0) return true;
    return false;
};

export const regexCheck = function (data: string, pattern: string) {
    let reg = new RegExp(pattern);
    return reg.test(data);
};

export const validateIBAN = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != '') {
        if (IBANCheck(value)) {
            callback();
        } else {
            callback(geti18nText('bankAccount.edit.iban.not.correct'));
        }
    } else {
        callback();
    }
};

export const validateBIC = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != '') {
        if (bicCheck(value)) {
            callback();
        } else {
            callback(geti18nText('validate.bic.not.correct'));
        }
    } else {
        callback();
    }
};

export const validateLicencePlate = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != null && value != '') {
        if (licenceCheck(value)) {
            callback();
        } else {
            callback(geti18nText('validate.licence.not.correct'));
        }
    } else {
        callback();
    }
};

export const validateItemCode = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != '') {
        if (itemCodeCheck(value)) {
            callback();
        } else {
            callback(geti18nText('validate.item.code.not.correct'));
        }
    } else {
        callback();
    }
};

export const validateOnlyNumber = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != '') {
        if (onlyNumberCheck(value)) {
            callback();
        } else {
            callback(geti18nText('validate.str.only.numbers'));
        }
    } else {
        callback();
    }
};

export const validateNegativeNumber = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != null) {
        if (negativeNumberCheck(value)) {
            callback();
        } else {
            callback(geti18nText('validate.str.only.positive.numbers'));
        }
    } else {
        callback();
    }
};

export const validateObjectId = (rule: any, value: any, callback: any) => {
    if (value != undefined && value != '' && (!value.id || value.id == -1)) {
        callback(geti18nText('travelWarrantPersonal.notification.form'));
    } else {
        callback();
    }
};
