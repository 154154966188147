import { NySession } from '@nybble/nyreact';
export const FixedAssetRights = (function () {
    return {
        //codebooks
        canCreateFixedAssetCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_CODEBOOKS_EDIT']);
        },
        canCsvExportFixedAssetCodebook: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_CODEBOOKS_EXPORT']);
        },
        //documents
        //asset-acquisition
        canCreateAssetAcquisition: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_ACQUISITION_EDIT']);
        },
        canCsvExportAssetAcquisition: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_ACQUISITION_EXPORT']);
        },
        //asset-initialState
        canCreateAssetInitialState: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_INITIAL_STATE_EDIT']);
        },
        canCsvExportAssetInitialState: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_INITIAL_STATE_EXPORT']);
        },
        //asset-write-off
        canCreateAssetWriteOff: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_WRITE_OFF_EDIT']);
        },
        canCsvExportAssetWriteOff: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_WRITE_OFF_EXPORT']);
        },
        //asset-depreciation
        canCreateAssetDepreciation: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_DEPRECIATION_EDIT']);
        },
        canCsvExportAssetDepreciation: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_DEPRECIATION_EXPORT']);
        },
        canCreateAssetRent: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ASSET_RENT']);
        },
        ///////////////////
        //menu
        canViewAssetsMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_ASSET_CODEBOOKS_MENU',
                'ROLE_ASSET_ACQUISITION_MENU',
                'ROLE_ASSET_INITIAL_STATE_MENU',
                'ROLE_ASSET_WRITE_OFF_MENU',
                'ROLE_ASSET_DEPRECIATION_MENU',
                'ROLE_ASSET_RENT',
            ];
        },
        canViewCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_CODEBOOKS_MENU'];
        },
        canViewAcquisitionMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_ACQUISITION_MENU'];
        },
        canViewInitialStateMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_INITIAL_STATE_MENU'];
        },
        canViewWriteOffMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_WRITE_OFF_MENU'];
        },
        canViewDepreciation: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_DEPRECIATION_MENU'];
        },
        canViewPaymentOrder: () => {
            return ['ROLE_ADMIN', 'ROLE_PAYMENT_ORDERS_READ'];
        },
        canViewAssetRent: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_RENT'];
        },
        //routes
        canViewCodebooksAssetRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_CODEBOOKS_VIEW'];
        },
        canViewOverviewRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_VIEW'];
        },
        canViewCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_VIEW'];
        },
        canViewCodebooksCompanyRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_VIEW', 'ROLE_ASSET_RENT'];
        },
        canViewAcquisitionRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_ACQUISITION_VIEW'];
        },
        canViewInitialStateRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_INITIAL_STATE_VIEW'];
        },
        canViewWriteOffRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_WRITE_OFF_VIEW'];
        },
        canViewDepreciationRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ASSET_DEPRECIATION_VIEW'];
        },
        canViewPaymentOrderRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_PAYMENT_ORDERS_READ'];
        },
        canViewAccountingOverview: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_MENU', 'ROLE_ASSET_RENT'];
        },
    };
})();
