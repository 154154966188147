export const wageIcon = (
    <i
        style={{
            fontSize: '23px',
            marginTop: '-2px',
            top: '3px',
            position: 'relative',
            color: '#faad14',
        }}
        className="las la-file-invoice-dollar"
    />
);

export const wageIconSmall = (
    <i
        style={{
            fontSize: '18px',
            marginLeft: '-2px',
            marginTop: '-2px',
            top: '1px',
            position: 'relative',
            marginRight: '0.25em',
            color: '#faad14',
        }}
        className="las la-file-invoice-dollar"
    />
);

export const wageAmountReceivedIcon = (
    <i
        style={{
            fontSize: '23px',
            marginLeft: '-2px',
            marginTop: '-2px',
            top: '2px',
            position: 'relative',
            color: '#52c41a',
        }}
        className="las la-hand-holding-usd"
    />
);

export const carIcon = (
    <i
        style={{
            fontSize: '23px',
            marginLeft: '-2px',
            marginTop: '-2px',
            marginRight: '0.25em',
            top: '3px',
            position: 'relative',
            color: '#177ddc',
        }}
        className="las la-car-side"
    />
);
