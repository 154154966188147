import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import TaskTemplateSearch from '../search';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    taskTemplateId,
    task,
    setTask,
    ...restProps
}: any) => {
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'parentId', condition: 'equals', value: taskTemplateId },
        ];
    };

    const setDefaultFilterValueDependsOn = () => {
        if (task?.id) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'parentId', condition: 'equals', value: taskTemplateId },
                { field: 'id', condition: 'not_equals', value: task.id },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'parentId', condition: 'equals', value: taskTemplateId },
            ];
        }
    };

    let inputNode: any = undefined;
    switch (inputType) {
        case 'taskTemplate':
            inputNode = (
                <TaskTemplateSearch
                    isFormItem={false}
                    label={''}
                    setDefaultFilterValue={setDefaultFilterValue}
                    onChange={(value: any) => {
                        setTask(value?.id > 0 ? value : null);
                    }}
                    style={record.hideDependsOn ? { display: 'none' } : {}}
                    mustGetPopupContainer={false}
                    itemName={[['name'], ['code']]}
                    renderOption={(option: any) => `${option.name}${option.code ? ' (' + option.code + ')' : ''}`}
                    name={inputType}
                />
            );
            break;
        case 'dependsOn':
            inputNode = (
                <TaskTemplateSearch
                    isFormItem={false}
                    label={''}
                    setDefaultFilterValue={setDefaultFilterValueDependsOn}
                    disabled={!task}
                    mustGetPopupContainer={false}
                    itemName={[['name'], ['code']]}
                    renderOption={(option: any) => `${option.name}${option.code ? ' (' + option.code + ')' : ''}`}
                    name={inputType}
                />
            );
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: inputType === 'taskTemplate' || inputType === 'dependsOn',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
