import { NyDataEdit, NyDatePicker, NyInputNumber, NySession, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Descriptions, Form, Input, Row } from 'antd';
import moment, { duration } from 'moment';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getDateFormat, setDateFormat } from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const EmployeeSeniorityEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.seniority.edit.new'));
    const [loading, setLoading] = useState(false);
    const [parentCompany, setParentCompany] = useState<any>(false);
    const [jubileeAward, setJubileeAward] = useState<any>(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('employee.seniority.edit.header') + ' - ' + dataForm.id);

        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
        }

        setParentCompany(dataForm.parentCompany ? true : false);
        setJubileeAward(dataForm.jubileeAward ? true : false);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.seniority.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const CalculateDiff = () => {
        let date1 = form.getFieldValue(['dateFrom']);
        let date2 = form.getFieldValue(['dateTo']);

        let dateDiff = duration(date2 - date1);

        let resultYears = dateDiff.years();
        let resultMonths = dateDiff.months();
        let resultDays = dateDiff.days();

        form.setFieldsValue({ years: resultYears });
        form.setFieldsValue({ months: resultMonths });
        form.setFieldsValue({ days: resultDays });
    };

    const disabledDateFrom = (current: any) => {
        const dateTo = form.getFieldValue('dateTo');
        if (dateTo) {
            return current > moment(dateTo);
        }
    };

    const disabledDateTo = (current: any) => {
        const dateFrom = form.getFieldValue('dateFrom');
        if (dateFrom) {
            return current.isBefore(dateFrom);
        } else {
            return current;
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.EMPLOYEE_SENIORITY.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!(canCreate() || HumanResourcesRights.canViewEDRModal())}
            hideActivationButtons={!(canCreate() || HumanResourcesRights.canViewEDRModal())}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                values.parentCompany = parentCompany;
                values.jubileeAward = jubileeAward;
                values.employee = { id: props?.editProps?.employeeId };
                return values;
            }}
            shortcuts={true}
            nyTestId="employee-seniority-edit"
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['employee', 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.seniority.edit.company')}
                        name="company"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.seniority.edit.hours')}
                        name="hours"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber style={{ width: '100%' }} nyTestId="hours" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.seniority.edit.dateFrom')}
                        name="dateFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            onChange={(value: any) => {
                                if (form.getFieldValue(['dateTo'])) {
                                    CalculateDiff();
                                }
                            }}
                            disabledDate={disabledDateFrom}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.seniority.edit.dateTo')}
                        name="dateTo"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            onChange={(value: any) => {
                                if (form.getFieldValue(['dateFrom'])) {
                                    CalculateDiff();
                                }
                            }}
                            disabledDate={disabledDateTo}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col span={24}>
                    <Descriptions column={3} layout="horizontal" bordered size="small" style={{ fontWeight: 'bold' }}>
                        <Descriptions.Item
                            contentStyle={{ padding: '0px' }}
                            label={geti18nText('employee.seniority.edit.years')}
                        >
                            <Form.Item name="years" style={{ padding: '0px', margin: '0px' }}>
                                <NyInputNumber style={{ width: '100%', border: 'none' }} min={0} isDecimal={false} />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item
                            contentStyle={{ padding: '0px' }}
                            label={geti18nText('employee.seniority.edit.months')}
                        >
                            <Form.Item name="months" style={{ padding: '0px', margin: '0px' }}>
                                <NyInputNumber style={{ width: '100%', border: 'none' }} min={0} max={12} />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item
                            contentStyle={{ padding: '0px', margin: '0px' }}
                            label={geti18nText('employee.seniority.edit.days')}
                        >
                            <Form.Item name="days" style={{ padding: '0px', margin: '0px' }}>
                                <NyInputNumber style={{ width: '100%', border: 'none' }} min={0} max={31} />
                            </Form.Item>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12} style={{ paddingLeft: '0px' }}>
                    <Form.Item label={geti18nText('employee.seniority.edit.parentCompany')} name="parentCompany">
                        <Checkbox
                            checked={parentCompany}
                            onChange={(val: any) => {
                                setParentCompany(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: '0px' }}>
                    <Form.Item label={geti18nText('employee.seniority.edit.jubileeAward')} name="jubileeAward">
                        <Checkbox
                            checked={jubileeAward}
                            onChange={(val: any) => {
                                setJubileeAward(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeSeniorityEdit;
