import { BookOutlined, EllipsisOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Descriptions, Dropdown, Form, Input, Menu, Row, Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import NyErrorLogModal from '../../../../../components/error-log-modal';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNodeNotification,
    errorNotification,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    numberFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
} from '../../../../../utils/Utils';
import AddAssetsIntoCalculationModal from './AddAssetsIntoCalculationModal';
import AssetDepreciationItemsIndex from './asset-depreciation-items';
import AssetDepreciationItemsActionIndex from './asset-depreciation-items/actionIndex';
import useEnum from '../../../../../hooks/useEnum';
import { updateHistoryI18n } from '../../../../../slices/historySlice';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';

const { Panel } = Collapse;

const AssetDepreciationEdit = (props: any) => {
    const history = useHistory();
    const { id } = useParams<any>();
    const location = useLocation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const focusInput = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isFormChange, setIsFormChange] = useState(false);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [note, setNote] = useState<any>(undefined);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [booked, setBooked] = useState(false);
    const [bookedGeneralLedger, setBookedGeneralLedger] = useState(false);
    const [title, setTitle] = useState('');
    const [updateTable, setUpdateTable] = useState(1);
    const [errorLogModalData, setErrorLogModalData] = useState([]);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const [isCreate, setIsCreate] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const [ordinal, setOrdinal] = useState<any>(undefined);
    const [date, setDate] = useState<any>(undefined);
    const [dataLength, setDataLength] = useState<any>(null);
    const [getTotalsRefresh, setGetTotalsRefresh] = useState<any>(0);
    const [totals, setTotals] = useState<any>(undefined);

    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    useEffect(() => {
        if (dataForm) {
            setCollapseActiveKeys(['1', '2']);
        }
    }, [dataForm]);

    // useEffect(() => {
    //     dispatch(setIsDirtyTab({ path: location.pathname, isDirty: isFormChange }));
    // }, [isFormChange]);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetDepreciation();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setTitle(dataForm.ord + '/' + moment(dataForm.date).format('YYYY'));
        }
        setIsEdit(dataForm.id ? true : false);
        setBookedGeneralLedger(dataForm.bookedGeneralLedger ? true : false);
        setBooked(dataForm.booked ? true : false);
        setIsCreate(dataForm.booked ? false : true);
        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }
        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
        }
        if (dataForm.ord) {
            setOrdinal(dataForm.ord);
        }
        if (dataForm.date) {
            setDate(dataForm.date);
        }

        if (dataForm.type) {
            dataForm.type = setEnumFormat('ASSET_DEPRECIATION_METHOD_TYPE', dataForm.type);
        }
        if (dataForm.netBookRate) {
            dataForm.netBookRate = setEnumFormat('DEPRECIATION_NET_BOOK_RATE', dataForm.netBookRate);
        }
        setIsFormChange(false);
        setNote(dataForm.note ? dataForm.note : undefined);
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2']);
        }
        setCollapseAll(!collapseAll);
    };

    const changeId = (returnedId: any) => {
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        const pathname = CONSTANTS_REQ.ASSET_DEPRECIATION.PATHNAME + returnedId;
        // dispatch(addTab({ title: 'asset.depreciation.edit.title', addedData: returnedId, path: pathname }));
        history.push(pathname);
    };

    const book = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_DEPRECIATION.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setBooked(true);
                    setIsCreate(false);
                    okNotification(geti18nText('asset.acquisition.button.book.success'));
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const debook = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_DEPRECIATION.DE_BOOK + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setBooked(false);
                    setIsCreate(true);
                    okNotification(geti18nText('asset.acquisition.button.debook.success'));
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const calculate = () => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_DEPRECIATION.CALCULATE + '/' + dataForm, undefined, {
                id: dataForm,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setUpdateTable((updateTable) => updateTable + 1);
                    okNotification(geti18nText('asset.depreciation.button.calculate.success'));
                } else {
                    if (result.data && result.data.error && result.data.error.length > 0) {
                        let data = JSON.parse(result.data.error);
                        errorNodeNotification(
                            <Button
                                type="link"
                                onClick={() => {
                                    setErrorLogModalData(data);
                                    setErrorLogModalVisible(true);
                                    setErrorLogModalTitle(geti18nText('asset.depreciation.error.details.modal'));
                                }}
                            >
                                {geti18nText('asset.depreciation.error.details')}
                            </Button>
                        );
                    } else {
                        errorNotification();
                    }
                }
                setLoading(false);
            });
        }
    };

    const actionsMenu = (
        <Menu>
            {canCreate() && (!booked || !bookedGeneralLedger) && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.depreciation.popconfirm.calculate.cancel')}
                        onConfirm={() => {
                            calculate();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.depreciation.calculate')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {canCreate() && isCreate && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                        onConfirm={() => {
                            book();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.acquisition.button.booking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {canCreate() && !isCreate && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                        onConfirm={() => {
                            debook();
                        }}
                    >
                        <Button style={{ width: '100%' }} icon={<BookOutlined />}>
                            {geti18nText('asset.acquisition.button.deBooking')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {canCreate() && (!booked || !bookedGeneralLedger) && (
                <div style={{ display: 'block', margin: '5px' }}>
                    <AddAssetsIntoCalculationModal
                        style={{ width: '100%' }}
                        dataForm={dataForm}
                        setLoading={setLoading}
                        updateTable={updateTable}
                        setUpdateTable={setUpdateTable}
                    />
                </div>
            )}
            <div style={{ display: 'block', margin: '5px' }}>
                <NyReportButton
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.REPORT.ASSET_DEPRECIATION}
                    subreportType={'ASSET_DEPRECATION'}
                    customParms={{ assetDepreciationId: dataForm }}
                    buttoni18nText={'app.default.button.download.pdf'}
                    fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_DEPRECATION)}
                    allowExportTypeChange={true}
                />
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    )}
                </Tooltip>
                {/*    {dataForm && (
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                )} */}
            </div>
            <div style={{ display: 'contents' }}>
                {dataForm && (
                    <>
                        {canCreate() && (!booked || !bookedGeneralLedger) && (
                            <div style={{ display: 'inline-flex', margin: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.depreciation.popconfirm.calculate.cancel')}
                                    onConfirm={() => {
                                        calculate();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.depreciation.calculate')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        {canCreate() && isCreate && (
                            <div style={{ display: 'inline-flex', margin: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.acquisition.popconfirm.booking.cancel')}
                                    onConfirm={() => {
                                        book();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.acquisition.button.booking')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        {canCreate() && !isCreate && (
                            <div style={{ display: 'inline-flex', margin: '0px' }}>
                                <NyModalConfirm
                                    title={geti18nText('asset.acquisition.popconfirm.deBooking.cancel')}
                                    onConfirm={() => {
                                        debook();
                                    }}
                                >
                                    <Button disabled={dataLength < 1} style={{ width: '100%' }} icon={<BookOutlined />}>
                                        {geti18nText('asset.acquisition.button.deBooking')}
                                    </Button>
                                </NyModalConfirm>
                            </div>
                        )}
                        {canCreate() && (!booked || !bookedGeneralLedger) && (
                            <div style={{ display: 'inline-flex', margin: '0px' }}>
                                <AddAssetsIntoCalculationModal
                                    style={{ width: '100%' }}
                                    dataForm={dataForm}
                                    setLoading={setLoading}
                                    updateTable={updateTable}
                                    setUpdateTable={setUpdateTable}
                                />
                            </div>
                        )}
                        <div style={{ display: 'inline-flex', marginRight: '12px' }}>
                            <NyReportButton
                                style={{ width: '100%' }}
                                url={CONSTANTS_REQ.REPORT.ASSET_DEPRECIATION}
                                subreportType={'ASSET_DEPRECATION'}
                                customParms={{ assetDepreciationId: dataForm }}
                                buttoni18nText={'app.default.button.print'}
                                fileName={
                                    geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_DEPRECATION) +
                                    ' ' +
                                    ordinal +
                                    '_' +
                                    moment(date).format('YYYY')
                                }
                                customDateFormat={moment().format('yyyyMMDDHHmm')}
                                disabled={dataLength < 1}
                                allowExportTypeChange={true}
                            />
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    useEffect(() => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_DEPRECIATION.GET_TOTALS.replace('{id}', id), {}).then(
            (result: any) => {
                if (result.status == RESPONSE.OK && result.data) {
                    if (result.data) {
                        setTotals(result?.data);
                    }
                }
            }
        );
    }, [getTotalsRefresh]);

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.ASSET_DEPRECIATION.EDIT}
                setValues={setValues}
                width={1200}
                fetchWhenChange={refresh || id}
                form={form}
                goBack={() => {
                    //     dispatch(removeTab(location.pathname));
                    setIsFormChange(false);
                    history.goBack();
                }}
                onSaveAndGetID={(returnedId: any) => {
                    changeId(returnedId);
                    setDataForm(returnedId);
                    if (id === 'create') {
                        // dispatch(removeTab(location.pathname));
                        changeId(returnedId);
                    } else {
                        // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
                    }
                }}
                paramsId={id}
                buttonsClassName="buttons-sticky"
                changeCollapseVisibility={changeCollapseVisibility}
                customFooterContent={getCustomFooterContent}
                checkIsFormChanged={true}
                setIsFormChange={setIsFormChange}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate() || booked}
                disableSubmitButton={!canCreate() || !isFormChange}
                shortcuts={true}
                {...props}
                normalize={(values: any) => {
                    if (values.date) values.date = getDateFormat(values.date);
                    if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                    if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                    if (values.type) {
                        values.type = getEnumFormat(values.type);
                        if (values.type.id < 1) {
                            delete values.type;
                        }
                    }
                    if (values.netBookRate) {
                        values.netBookRate = getEnumFormat(values.netBookRate);
                        if (values.netBookRate < 1) {
                            delete values.netBookRate;
                        }
                    }
                    values.booked = booked ? true : false;
                    values.bookedGeneralLedger = bookedGeneralLedger ? true : false;
                    return values;
                }}
            >
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('asset.depreciation.divider.1')}
                                key="1"
                                extra={
                                    <NyNoteModal
                                        ref={focusInput}
                                        note={note}
                                        modalTitle={
                                            geti18nText('asset.depreciation.edit.title') +
                                            ' - ' +
                                            geti18nText('app.default.note')
                                        }
                                    />
                                }
                            >
                                <Row gutter={24}>
                                    <Col span={7}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item name="id" style={{ display: 'none' }}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label={geti18nText('asset.depreciation.table.column.ord')}
                                                    name="ord"
                                                >
                                                    <NyInputNumber
                                                        style={{ width: '100%' }}
                                                        nyTestId="ord"
                                                        disabled={!canCreate() || booked || !isEdit}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('shift.edit.name')}
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input ref={focusInput} disabled={!canCreate() || booked} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.depreciation.table.column.date')}
                                                    name="date"
                                                    initialValue={moment()}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyDatePicker
                                                        style={{ width: '100%' }}
                                                        nyTestId="date"
                                                        disabled={!canCreate() || booked}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.depreciation.table.column.method')}
                                                    name="type"
                                                    initialValue={setEnumFormat('ASSET_DEPRECIATION_METHOD_TYPE', 1)}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NySearchField
                                                        options={getEnumArray('ASSET_DEPRECIATION_METHOD_TYPE')}
                                                        map={{ id: 'id', label: 'text' }}
                                                        searchBy="text"
                                                        style={{ width: '100%' }}
                                                        className={''}
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="netBookRate"
                                                    label={geti18nText('asset.depreciation.netBookRate')}
                                                >
                                                    <NySearchField
                                                        options={getEnumArray('DEPRECIATION_NET_BOOK_RATE')}
                                                        map={{ id: 'id', label: 'text' }}
                                                        searchBy="label"
                                                        style={{ width: '100%' }}
                                                        className={''}
                                                        disabled={!canCreate() || booked}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {isEdit && (
                                                <>
                                                    {/*   <Row gutter={24}> */}
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="booked"
                                                            valuePropName={'checked'}
                                                            initialValue={false}
                                                        >
                                                            <b>{geti18nText('asset.acquisition.edit.booked') + ': '}</b>
                                                            <b>
                                                                {booked
                                                                    ? geti18nText('app.default.button.yes')
                                                                    : geti18nText('app.default.button.no')}
                                                            </b>
                                                        </Form.Item>
                                                    </Col>

                                                    {/*   <Col span={12}>
                                                <Form.Item
                                                    name="bookedGeneralLedger"
                                                    valuePropName={'checked'}
                                                    initialValue={false}
                                                >
                                                    <b>
                                                        {geti18nText('asset.acquisition.edit.bookedGeneralLedger') +
                                                            ': '}
                                                    </b>
                                                    <b>
                                                        {bookedGeneralLedger
                                                            ? geti18nText('app.default.button.yes')
                                                            : geti18nText('app.default.button.no')}
                                                    </b>
                                                </Form.Item>
                                            </Col> */}
                                                    {/*    </Row> */}
                                                </>
                                            )}
                                        </Row>
                                    </Col>
                                    <Col span={7}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText(
                                                        'asset.depreciation.table.column.yearlyCalculation'
                                                    )}
                                                    name="yearly"
                                                    valuePropName="checked"
                                                    initialValue={false}
                                                >
                                                    <Checkbox disabled={!canCreate() || booked} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText(
                                                        'asset.depreciation.table.column.acceleratedDepreciation'
                                                    )}
                                                    name="accelerated"
                                                    valuePropName="checked"
                                                    initialValue={false}
                                                >
                                                    <Checkbox disabled={!canCreate() || booked} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.depreciation.table.column.fromDate')}
                                                    name="dateFrom"
                                                    initialValue={moment()}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyDatePicker
                                                        style={{ width: '100%' }}
                                                        nyTestId="date"
                                                        disabled={!canCreate() || booked}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.depreciation.table.column.toDate')}
                                                    name="dateTo"
                                                    initialValue={moment()}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyDatePicker
                                                        style={{ width: '100%' }}
                                                        nyTestId="date"
                                                        disabled={!canCreate() || booked}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={10}>
                                        <Descriptions
                                            column={2}
                                            labelStyle={{ fontWeight: 'bold' }}
                                            contentStyle={{ paddingBottom: '15px' }}
                                        >
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.acquisitionValue'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.acquisitionValue ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText('asset.depreciationItem.table.column.valueIncrease')}
                                            >
                                                <b>{numberFormat(totals?.valueIncrease ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText('asset.depreciationItem.table.column.valueDecrease')}
                                            >
                                                <b>{numberFormat(totals?.valueDecrease ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.accumulatedDepreciation'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.accumulatedDepreciation ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText('asset.depreciationItem.table.column.amount')}
                                            >
                                                <b>{numberFormat(totals?.amount ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText('asset.depreciationItem.table.column.netBookValue')}
                                            >
                                                <b>{numberFormat(totals?.netBookValue ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.acceleratedAmount'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.acceleratedAmount ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.acceleratedNetBookValue'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.acceleratedNetBookValue ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.internalAccumulatedDepreciation'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.internalAccumulatedDepreciation ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.internalAmount'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.internalAmount ?? 0)}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText(
                                                    'asset.depreciationItem.table.column.internalNetBookValue'
                                                )}
                                            >
                                                <b>{numberFormat(totals?.internalNetBookValue ?? 0)}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    {dataForm && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Collapse
                                style={{ marginTop: '1em' }}
                                activeKey={collapseActiveKeys}
                                onChange={(key: any) => {
                                    setCollapseActiveKeys(key);
                                }}
                            >
                                <Panel header={geti18nText('asset.acquisition.divider.2')} key="2">
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            {!booked && !bookedGeneralLedger ? (
                                                <AssetDepreciationItemsActionIndex
                                                    assetDepreciationId={dataForm}
                                                    canCreate={canCreate()}
                                                    booked={booked}
                                                    bookedGeneralLedger={bookedGeneralLedger}
                                                    updateTable={updateTable}
                                                    dataLength={dataLength}
                                                    setDataLength={setDataLength}
                                                    setGetTotalsRefresh={setGetTotalsRefresh}
                                                    getTotalsRefresh={getTotalsRefresh}
                                                    scroll={{ y: 145, x: 800 }}
                                                />
                                            ) : (
                                                <AssetDepreciationItemsIndex
                                                    assetDepreciationId={dataForm}
                                                    canCreate={canCreate()}
                                                    booked={booked}
                                                    bookedGeneralLedger={bookedGeneralLedger}
                                                    updateTable={updateTable}
                                                    dataLength={dataLength}
                                                    setDataLength={setDataLength}
                                                    scroll={{ y: 145, x: 800 }}
                                                />
                                            )}
                                        </Col>
                                        <NyErrorLogModal
                                            modalVisible={errorLogModalVisible}
                                            setModalVisible={setErrorLogModalVisible}
                                            title={errorLogModalTitle}
                                            errorData={errorLogModalData}
                                            setErrorData={setErrorLogModalData}
                                        />
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                    )}
                </Row>
            </NyDataEdit>
            <Prompt message={''} when={isFormChange} />
        </>
    );
};
export default AssetDepreciationEdit;
