import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
// import { addTab } from '../../../../../slices/tabsSlice';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import {
    errorNotification,
    getDateFormat,
    getLoginRedirectUrl,
    getOrdinal,
    getWageErrorsMessages,
    numberFormat,
    okNotification,
} from '../../../../../utils/Utils';
import TravelWarrantUnpaidModal from '../modal/TravelWarrantUnpaidModal';

const TravelWarrantTableUnpaid = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    setDefaultFilterValue,
    tab,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    canViewWageErrors = false,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const location: any = useLocation();
    const [refreshTable, setRefreshTable] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [travelWarrantId, setTravelWarrantId] = useState(undefined);

    const actionComponent =
        tab == 9 ? 'TravelWarrantUnpaidWaitingForApproval' : tab == 4 ? 'TravelWarrant' : 'TravelWarrantUnpaid';

    useEffect(() => {
        const id = getLoginRedirectUrl();
        if (id) {
            setTravelWarrantId(id);
            setModalVisible(true);
        }
    }, [location]);

    useEffect(() => {
        if (setDefaultFilterValue) {
            setRefreshTable((prevValue: any) => prevValue + 1);
        }
    }, [setDefaultFilterValue]);

    const onRowSelect = (row: any) => {
        setTravelWarrantId(row.id);
        setModalVisible(true);
    };

    const changeStatus = (recordId: any, status: any, info: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: info,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh: number) => refresh + 1);
                if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                    okNotification(geti18nText('travelWarrantPersonal.sendToApproval.ok'));
                } else if (status == travelWarrantStatusEnum.REJECTED) {
                    okNotification(geti18nText('travelWarrantPersonal.cancelTrip.ok'));
                }
            } else {
                if (result.data && result.data.error) {
                    errorNotification(geti18nText(result.data.error));
                } else {
                    errorNotification();
                }
            }
        });
    };

    const initialColumns = [
        {
            title: geti18nText('travelWarrant.table.column.ordinal'),
            dataIndex: 'ordinal',
            width: '110px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record && record.ordinal && record.status > -1) {
                    return (
                        <>
                            <Tooltip title={geti18nText('menu.travel.warrant.details')}>
                                <Button
                                    type="link"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() =>
                                        // dispatch(
                                        //     addTab({
                                        //         title: 'travelWarrant.edit.title.short' + '-',
                                        //         addedData: record.id,
                                        //         path: '/travelWarrant/' + record.id,
                                        //     })
                                        // )
                                        history.push('/travel-warrant/travelWarrant/' + record.id)
                                    }
                                >
                                    {getOrdinal(record)}
                                </Button>
                            </Tooltip>
                            {getWageErrorsMessages(
                                record?.wageErrors,
                                canViewWageErrors && record?.status != travelWarrantStatusEnum.PAID
                            )}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.warrantDate'),
            dataIndex: 'warrantDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (warrantDate: any, record: any) => {
                if (record.warrantDate) {
                    return getDateFormat(record.warrantDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrant.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (person: any, record: any) => {
                if (record && record.person) {
                    return (
                        <>
                            {`${record.person.firstName} ${record.person.lastName} `}
                            {record.employee.employmentRecordId && ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrantPersonal.table.column.destination'),
            dataIndex: 'destination',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('travelWarrant.table.column.approvedAdvance'),
            dataIndex: 'advancePaymentInCurrency',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (advancePaymentInCurrency: any, record: any) => {
                if (record?.advancePaymentCurrency?.isoCode) {
                    return (
                        <div style={{ float: 'right' }}>
                            {numberFormat(advancePaymentInCurrency ?? 0) +
                                ' (' +
                                record.advancePaymentCurrency.isoCode +
                                ')'}
                        </div>
                    );
                } else {
                    return <div style={{ float: 'right' }}>{numberFormat(advancePaymentInCurrency ?? 0)}</div>;
                }
            },
        },
        ...(tab != 4
            ? [
                  {
                      title: geti18nText('travelWarrant.table.column.paymentAmount'),
                      dataIndex: 'paymentAmount',
                      align: 'right',
                      render: (paymentAmount: any, record: any) => {
                          if (record?.paymentAmount?.isoCode) {
                              return (
                                  <div style={{ float: 'right' }}>
                                      {numberFormat(paymentAmount ?? 0) + ' (' + record.paymentAmount.isoCode + ')'}
                                  </div>
                              );
                          } else {
                              return <div style={{ float: 'right' }}>{numberFormat(paymentAmount ?? 0)}</div>;
                          }
                      },
                  },
                  {
                      title: geti18nText('travelWarrant.table.column.returnAmount'),
                      dataIndex: 'returnAmount',
                      align: 'right',
                      render: (returnAmount: any, record: any) => {
                          if (record?.returnAmount?.isoCode) {
                              return (
                                  <div style={{ float: 'right' }}>
                                      {numberFormat(returnAmount ?? 0) + ' (' + record.returnAmount.isoCode + ')'}
                                  </div>
                              );
                          } else {
                              return <div style={{ float: 'right' }}>{numberFormat(returnAmount ?? 0)}</div>;
                          }
                      },
                  },
              ]
            : []),
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <RowAction
                            component={actionComponent}
                            record={record}
                            changeStatusUnpaid={() => {
                                setModalVisible(true);
                                setTravelWarrantId(record.id);
                            }}
                            changeStatus={changeStatus}
                            fetchAccessRights={actionComponent === 'TravelWarrant'}
                        ></RowAction>
                    );
                }
            },
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="travel-warrant-travel-warrant-unpaid-table"
                url={CONSTANTS_REQ.TRAVEL_WARRANT.LIST}
                key={tab}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                shortcuts={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                hideNewButton={true}
                fetchWhenChange={refreshTable}
                onRowSelect={onRowSelect}
            />
            {modalVisible && (
                <TravelWarrantUnpaidModal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    travelWarrantId={travelWarrantId}
                    setTravelWarrantId={setTravelWarrantId}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    tab={tab}
                />
            )}
        </>
    );
};

export default TravelWarrantTableUnpaid;
