import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import CountryIndex from '../country';
import CountryEdit from '../country/edit';
import CountyIndex from '../county';
import CountyEdit from '../county/edit';
import PostOfficeIndex from '../post-office';
import PostOfficeEdit from '../post-office/edit';
import TownshipIndex from '../township';
import TownshipEdit from '../township/edit';
import CountySearch from '../county/search';
import PostOfficeSearch from '../post-office/search';

const SettlementEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('settlement.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [allowDistrict, setAllowDistrict] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('settlement.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.postOffice) {
            dataForm.postOffice = {
                id: dataForm.postOffice.id,
                text: (dataForm.postOffice.zipCode ? dataForm.postOffice.zipCode : '') + ' ' + dataForm.postOffice.name,
            };
        }
        if (dataForm.township) {
            dataForm.township = setSearchFormat(dataForm.township);
        }
        if (dataForm.county) {
            dataForm.county = setSearchFormat(dataForm.county);
        }
        if (dataForm.country) {
            dataForm.country = setSearchFormat(dataForm.country);
        }
        if (dataForm.allowDistrict) {
            setAllowDistrict(dataForm.allowDistrict);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('settlement.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.SETTLEMENT.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            {...props}
            normalize={(values: any) => {
                if (values.postOffice) values.postOffice = getSearchFormat(values.postOffice);
                if (values.township) values.township = getSearchFormat(values.township);
                if (values.county) values.county = getSearchFormat(values.county);
                if (values.country) values.country = getSearchFormat(values.country);
                values.allowDistrict = allowDistrict;

                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label={geti18nText('settlement.edit.name')}
                    name="name"
                    style={{ width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: geti18nText('app.default.required'),
                            whitespace: true,
                        },
                    ]}
                >
                    <Input ref={focusInput} />
                </Form.Item>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <PostOfficeSearch name={'postOffice'} />
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('settlement.edit.township')} name="township">
                        <NySearchField
                            url={CONSTANTS_REQ.TOWNSHIP.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<TownshipIndex />}
                            AddNewModalComponent={TownshipEdit}
                            nyTestId="township"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <CountySearch />
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('settlement.edit.country')} name="country">
                        <NySearchField
                            url={CONSTANTS_REQ.COUNTRY.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<CountryIndex />}
                            AddNewModalComponent={CountryEdit}
                            nyTestId="country"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('settlement.table.column.allowDistrict')} name="allowDistrict">
                        <Checkbox
                            checked={allowDistrict}
                            onChange={(e: any) => {
                                setAllowDistrict(e.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default SettlementEdit;
