import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Button, Dropdown, Menu } from 'antd';
import { useLocation } from 'react-router';
import ImportCSVModal from '../../../../../components/import-csv-modal';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getEnumArrayStatus } from '../../../../../utils/Utils';
import DepreciationGroupEdit from './edit';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';

const DepreciationGroupIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const table = useTableSettings();
    useHelper('assets/codebooks/depreciationGroup');

    const canCreate = () => {
        return FixedAssetRights.canCreateFixedAssetCodebooks();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCsvExportFixedAssetCodebook();
    };

    const isInternalRateEnabled = useApplicationSetting('ENABLE_DEPRECIATION_INTERNAL_RATE');

    const columns = [
        {
            title: geti18nText('depreciation.group.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('depreciation.group.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('depreciation.group.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('depreciation.group.table.column.period'),
            dataIndex: 'depreciationTime',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('depreciation.group.table.column.depreciationStartType'),
            dataIndex: 'depreciationStartType',
            width: '170px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.depreciationStartType !== undefined) {
                    return geti18nText('app.enum.DEPRECIATION_START_TYPE.' + record.depreciationStartType);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('DEPRECIATION_START_TYPE'), 'equals', undefined),
        },
        {
            title: geti18nText('depreciation.group.table.column.anual'),
            dataIndex: 'depreciationRate',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('depreciation.group.table.column.accelerated'),
            dataIndex: 'depreciationIncreasedRate',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        ...(isInternalRateEnabled == 1
            ? [
                  {
                      title: geti18nText('depreciation.group.table.column.internal'),
                      dataIndex: 'depreciationInternalRate',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                  },
              ]
            : []),
        {
            title: geti18nText('depreciation.group.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <ImportCSVModal
                            url={CONSTANTS_REQ.DEPRECIATION_GROUP.IMPORT_CSV}
                            onSave={onSave}
                            customInfoMessage={geti18nText('assetDepreciationGroup.upload.info')}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <NyDataTable
            nyId="asset-deprecation-group"
            url={CONSTANTS_REQ.DEPRECIATION_GROUP.LIST}
            addNewButtonText={geti18nText('depreciation.group.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={DepreciationGroupEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            hideNewButton={!canCreate()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.assets.depreciation.group')}
            colCSVCustomization={csvColumnCustomisation()}
            addedButtons={canCreate() ? addedButtons : undefined}
            headerTitle={geti18nText('menu.assets.depreciation.group')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default DepreciationGroupIndex;
