import { geti18nText, NyDatePicker, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import HumanCodebooksSearch from '../../../../../components/human-codebooks/search';
import useEnum from '../../../../../hooks/useEnum';
import { getEnumArrayStatus } from '../../../../../utils/Utils';

const EditableCell = ({
    form,
    editing,
    dataIndex,
    title,
    inputType,
    required,
    record,
    index,
    children,
    maxQuantity = undefined,
    managerCommentResultMandatory,
    setManagerCommentResultMandatory,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const isHumanCodebooks: boolean =
        inputType &&
        Object.values([
            'resultEstimation',
            'typeName',
            'employeeEvaluationCompetencyTemplateType',
            'managerEstimation',
        ]).includes(inputType)
            ? true
            : false;

    switch (inputType) {
        case 'achievedResultDescription':
        case 'managerComment':
        case 'employeeComment':
        case 'goalDescription':
        case 'employeeEvaluationCompetencyTemplateDescription':
            inputNode = <TextArea autoFocus autoSize={{ minRows: 1, maxRows: 4 }} allowClear={false} />;
            break;
        case 'resultEstimation':
            inputNode = (
                <Form.Item
                    label={''}
                    name="resultEstimation"
                    rules={[
                        {
                            required: required,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    <NySearchField
                        options={getEnumArrayStatus('EMPLOYEE_EVALUATION_ESTIMATION')}
                        map={{ id: 'id', label: 'text' }}
                        searchBy="text"
                        autoFocus={true}
                        nyTestId="resultEstimation"
                        className=""
                        mustGetPopupContainer={false}
                        renderOption={(option: any) => `${option.text}`}
                        allowClear={false}
                        style={{ border: '1px solid #91d5ff' }}
                        onChange={(val: any) => setManagerCommentResultMandatory(val?.id > 0 && val?.id != 2)}
                    />
                </Form.Item>
            );
            break;
        case 'employeeEvaluationCompetencyTemplateType':
            inputNode = (
                <HumanCodebooksSearch
                    required={required}
                    label={''}
                    codebooksType={enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE}
                    formItemName={['employeeEvaluation', 'type']}
                    mustGetPopupContainer={false}
                    renderOption={(option: any) => `${option.text}`}
                    allowClear={false}
                />
            );
            break;
        case 'managerEstimation':
        case 'employeeEstimation':
            inputNode = (
                <HumanCodebooksSearch
                    required={required}
                    label={''}
                    codebooksType={enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION}
                    formItemName={inputNode}
                    mustGetPopupContainer={false}
                    renderOption={(option: any) => `${option.text}`}
                    allowClear={false}
                    style={{ border: '1px solid #91d5ff' }}
                />
            );
            break;
        case 'dueDate':
        case 'dueDate':
            inputNode = (
                <NyDatePicker
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                    mustGetPopupContainer={false}
                    allowClear={false}
                />
            );
            break;
        case 'quantity':
            inputNode = <NyInputNumber style={{ width: '100%' }} min={0} />;
            break;
        default:
            inputNode = <Input allowClear={false} />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <>
                    {isHumanCodebooks ? (
                        <div style={{ marginTop: '15px' }}>{inputNode}</div>
                    ) : (
                        <Form.Item
                            style={{ marginTop: '15px' }}
                            name={dataIndex}
                            rules={[
                                {
                                    required:
                                        required || (managerCommentResultMandatory && dataIndex == 'managerComment'),
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            {inputNode}
                        </Form.Item>
                    )}
                </>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
