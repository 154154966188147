import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import EducationPurposeEdit from './edit';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const EducationPurposeIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    searchComponent = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();

    useHelper('human/codebooks/educational-domain');

    const columns = [
        {
            title: geti18nText('educationPurpose.table.column.id'),
            dataIndex: 'id',
            width: searchComponent ? '9%' : '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('educationPurpose.table.column.description'),
            dataIndex: 'description',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educationPurpose.table.column.code'),
            dataIndex: 'code',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('educationPurpose.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateFrom) {
                    return moment(record.dateFrom).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('educationPurpose.table.column.dateTo'),
            dataIndex: 'dateTo',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateTo) {
                    return moment(record.dateTo).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('educationPurpose.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-education-education-purpose-table"
            url={CONSTANTS_REQ.EDUCATION_PURPOSE.LIST}
            addNewButtonText={geti18nText('educationPurpose.table.add')}
            headerTitle={geti18nText('menu.education.educationPurpose')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={EducationPurposeEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!HumanResourcesRights.canCreateEducationCodebooks()}
            shortcuts={true}
            exportCSV={HumanResourcesRights.canCreateEducationCodebooks()}
            CSVFileName={geti18nText('menu.education.educationPurpose')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
        />
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('educationPurpose.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('educationPurpose.table.column.description'),
            dataIndex: 'description',
        },
        {
            title: geti18nText('educationPurpose.table.column.code'),
            dataIndex: 'code',
        },
        {
            title: geti18nText('educationPurpose.table.column.dateFrom'),
            dataIndex: 'dateFrom',
        },
        {
            title: geti18nText('educationPurpose.table.column.dateTo'),
            dataIndex: 'dateTo',
        },
        {
            title: geti18nText('educationPurpose.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            dateFrom: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export default EducationPurposeIndex;
