import { PrinterOutlined } from '@ant-design/icons';
import { NyDataEdit, NyDatePicker, NyInputNumber, NySearchField, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import TravelWarrantMap from '../../../../components/travel-warrant-map/TravelWarrantMap';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorCustomNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    setDateFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';
import TravelVehiclePersonalIndex from '../travel-vehicle-personal';
import TravelVehiclePersonalEdit from '../travel-vehicle-personal/edit';

const EmployeeTravelRelationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employeeTravelRelation.edit.new'));
    const [loading, setLoading] = useState(false);
    const maxDistanceForTravelRelation = 30;
    const maxDistanceErrorText =
        geti18nText('employeeTravelRelation.edit.max.distance') +
        ' ' +
        maxDistanceForTravelRelation.toString() +
        ' km.';
    const vehileReimbursmentPrice = useApplicationSetting('TRAVEL_WARRANT_VEHICLE_REIMBURSMENT_PRICE');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [employeeId, setEmployeeId] = useState<any>(undefined);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [initialWaypoints, setInitialWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const [distance, setDistance] = useState<any>(undefined);
    const [mapDistance, setMapDistance] = useState<any>(undefined);
    const [dataForm, setDataForm] = useState<any>(null);

    useEffect(() => {
        setEmployeeValues(employee);
    }, [employee]);

    useEffect(() => {
        if (distance > maxDistanceForTravelRelation) {
            errorCustomNotification(maxDistanceErrorText);
        }
    }, [distance]);

    useEffect(() => {
        if (mapDistance >= 0) {
            setDistance(mapDistance);
            form.setFieldsValue({ distance: mapDistance });
        }
    }, [mapDistance]);

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelLocal();
    };

    const setEmployeeValues = (employeeData: any) => {
        setEmployeeId(employeeData.id);
        let formUser: any = {};
        formUser.employee = {};
        formUser.employee = getEmployeeSelectOption(employeeData);
        form.setFieldsValue(formUser);
    };

    const getDestinationName = (name: any) => {
        const returnName = name.replaceAll(' - Granični prijelaz', '');
        return returnName;
    };

    function setValues(dataForm: any) {
        if (dataForm.travelVehicle) {
            dataForm.travelVehicle = setSearchFormat(dataForm.travelVehicle, 'name', 'name');
        }
        if (dataForm.employee) {
            dataForm.originalEmployee = dataForm.employee;
            dataForm.employee = getEmployeeSelectOption(dataForm.originalEmployee);
            setEmployeeId(dataForm.employee.id);
        }
        if (dataForm.travelDate) {
            dataForm.travelDate = setDateFormat(dataForm.travelDate);
        }
        if (dataForm.distance) {
            setDistance(dataForm.distance);
        }
        if (dataForm.relationJson) {
            let relation = JSON.parse(dataForm.relationJson);
            setInitialWaypoints(relation);
            setWaypoints(relation);
            setMaxWaypointsKey(relation.length);
        }
        if (dataForm.name) {
            dataForm.name = getDestinationName(dataForm.name);
        }

        setEditHeader(geti18nText('employeeTravelRelation.edit.title') + ' - ' + dataForm.name);
        setDataForm(dataForm);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setEditHeader(geti18nText('employeeTravelRelation.edit.new'));
        setEmployeeId(undefined);
        setWaypoints([
            { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
            { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        ]);
        setInitialWaypoints([
            { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
            { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        ]);
        setMaxWaypointsKey(2);
        setMapDistance(undefined);
        setMapDistance(undefined);
    };

    const setDefaultFilterValueVehicle = () => {
        if (employeeId !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId },
                { field: 'vehicleType', condition: 'in', value: '1' },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'vehicleType', condition: 'in', value: '1,2' },
            ];
        }
    };

    const travelVehicleOnChange = (value: any) => {
        form.setFieldsValue({ price: 0 });
        if (value && value.id > 0) {
            form.setFieldsValue({ price: vehileReimbursmentPrice });
            calculateAmount();
        }
    };

    const calculateDistance = () => {
        let currentDistance = 0;
        let counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        let counterEnd = form.getFieldValue(['counterEnd']) ? form.getFieldValue(['counterEnd']) : 0;

        if (counterEnd > 0) {
            currentDistance = Math.abs(counterEnd - counterStart);
        } else {
            currentDistance = Math.abs(counterEnd);
        }
        setDistance(currentDistance);
        form.setFieldsValue({ distance: currentDistance });
        calculateAmount();
    };

    const calculateAmount = () => {
        let amount = 0;
        let price = form.getFieldValue(['price']);
        if (distance && price) {
            amount = distance * price;
        }
        form.setFieldsValue({ amount: amount });
    };

    const counterStartOnChange = () => {
        let counterEnd = form.getFieldValue(['counterEnd']) ? form.getFieldValue(['counterEnd']) : 0;
        const currentDistance = distance ? distance : mapDistance ? mapDistance : 0;
        const counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        if (currentDistance > 0 && counterStart >= 0 && !counterEnd) {
            form.setFieldsValue({ counterEnd: counterStart + currentDistance });
        }
    };

    const counterEndOnChange = () => {
        const currentDistance = distance ? distance : mapDistance ? mapDistance : 0;
        let counterStart = form.getFieldValue(['counterStart']) ? form.getFieldValue(['counterStart']) : 0;
        let counterEnd = form.getFieldValue(['counterEnd']) ? form.getFieldValue(['counterEnd']) : 0;
        if (currentDistance > 0 && counterEnd > 0 && counterEnd < counterStart) {
            if (counterEnd - currentDistance >= 0) {
                form.setFieldsValue({ counterStart: counterEnd - currentDistance });
            } else {
                form.setFieldsValue({ counterStart: 0 });
            }
        }
        if (counterEnd == 0) {
            setDistance(0);
            form.setFieldsValue({ distance: 0 });
        }
        calculateDistance();
    };

    const employeeOnChange = (value: any) => {
        form.setFieldsValue({ travelVehicle: null });
        if (value && value.id > 0) {
            setEmployeeId(value.id);
        } else {
            setEmployeeId(null);
            form.setFieldsValue({ employee: undefined });
        }
    };

    const validateDistance = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (value > maxDistanceForTravelRelation) {
                callback(maxDistanceErrorText);
            } else if (value < 0) {
                callback(geti18nText('app.default.min.number') + ' 0');
            } else {
                callback();
            }
        } else {
            callback();
        }
    };

    const reset = async () => {
        let waypointsTemp: any = [];
        if (dataForm) {
            waypointsTemp = initialWaypoints;
        } else {
            waypointsTemp = [
                { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
                { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
            ];
        }
        form.resetFields();
        setWaypoints(JSON.parse(JSON.stringify(waypointsTemp)));
        if (dataForm) {
            let tempData: any = { ...dataForm };
            tempData.employee = tempData.originalEmployee;
            setValues(tempData);
        }
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button key={'print'} icon={<PrinterOutlined />}>
                                {geti18nText('travelWarrant.action.pdf.short')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(true);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(false);
                    }}
                    content={() => componentRef.current}
                    pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                />
            </div>

            <Button onClick={reset} style={{ marginRight: '15px' }}>
                {geti18nText('travel.warrant.edit.map.reset.waypoints')}
            </Button>
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_TRAVEL_RELATION.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            customFooterContent={getCustomFooterContent}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.travelVehicle) values.travelVehicle = getSearchFormat(values.travelVehicle);
                if (values.employee) values.employee = getSearchFormat(values.employee);
                if (values.travelDate) values.travelDate = getDateFormat(values.travelDate);

                let waypointsList = waypoints.filter(
                    (waypoint: any) => waypoint?.lat != '' && waypoint?.lng != '' && waypoint?.name != ''
                );
                values.relationJson = JSON.stringify(waypointsList);

                return values;
            }}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                    <TravelWarrantMap
                        showMap={true}
                        form={form}
                        waypoints={waypoints}
                        setWaypoints={setWaypoints}
                        maxWaypointsKey={maxWaypointsKey}
                        setMaxWaypointsKey={setMaxWaypointsKey}
                        componentRefProp={componentRef}
                        printPdfProp={printPdf}
                        setPrintPdfProp={setPrintPdf}
                        printPdfHeader={editHeader}
                        hidePrint={true}
                        setMapDistance={setMapDistance}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('employeeTravelRelation.edit.travelRelation')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('employeeTravelRelation.edit.employee')}
                                name="employee"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        employmentRecordId: 'employmentRecordId',
                                        businessUnit: 'businessUnit',
                                    }}
                                    searchBy="person.first_name || ' ' || person.last_name"
                                    itemName={[
                                        ['person', 'firstName'],
                                        ['person', 'lastName'],
                                    ]}
                                    customItemNameLabel={'firstName lastName'}
                                    SearchPopupComponent={<EmployeeIndex disabled={true} filteredForTravel parentKey="employee-travel-relation-edit"/>}
                                    nyTestId="employee"
                                    onChange={employeeOnChange}
                                    renderOption={customEmployeeRenderOption}
                                    order="person.last_name, person.first_name"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('employeeTravelRelation.edit.travelVehicle')}
                                name="travelVehicle"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'name',
                                    }}
                                    searchBy="name"
                                    itemName="model"
                                    SearchPopupComponent={
                                        <TravelVehiclePersonalIndex
                                            vehicleType={1}
                                            newButtonTextPersonal={true}
                                            employeeId={employeeId}
                                            sort={true}
                                        />
                                    }
                                    AddNewModalComponent={TravelVehiclePersonalEdit}
                                    nyTestId="travel-vehicle"
                                    disabled={!employeeId}
                                    onChange={travelVehicleOnChange}
                                    setDefaultFilterValue={setDefaultFilterValueVehicle}
                                    order="sort"
                                    orderType="asc"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employeeTravelRelation.edit.travelDate')}
                                name="travelDate"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                initialValue={moment()}
                            >
                                <NyDatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('employeeTravelRelation.edit.price')} name="price">
                                <NyInputNumber
                                    onChange={calculateAmount}
                                    isDecimal={true}
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employeeTravelRelation.edit.counterStart')}
                                name="counterStart"
                            >
                                <NyInputNumber
                                    onChange={counterStartOnChange}
                                    style={{ width: '100%' }}
                                    nyTestId="counter-start"
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('employeeTravelRelation.edit.counterEnd')} name="counterEnd">
                                <NyInputNumber
                                    min={0}
                                    onChange={counterEndOnChange}
                                    style={{ width: '100%' }}
                                    nyTestId="counter-end"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employeeTravelRelation.edit.distance')}
                                name="distance"
                                rules={[
                                    {
                                        validator: validateDistance,
                                    },
                                ]}
                                validateStatus={distance > maxDistanceForTravelRelation ? 'error' : ''}
                            >
                                <NyInputNumber
                                    min={0}
                                    onChange={(value: any) => {
                                        setDistance(value);
                                        calculateAmount();
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('employeeTravelRelation.edit.amount')} name="amount">
                                <NyInputNumber isDecimal={true} style={{ width: '100%' }} min={0} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeTravelRelationEdit;
