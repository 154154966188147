import { geti18nText, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import ItemIndex from '../../../../administration/views/item';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    type,
    warehouse,
    ...restProps
}: any) => {
    let inputNode: any = undefined;
    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);

    const itemFilter = () => {
        if (warehouse) {
            return [
                { field: 'warehouse.id', condition: 'equals', value: warehouse?.id },
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: type },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: type },
            ];
        }
    };

    switch (inputType) {
        case 'item':
            inputNode = (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ITEM.SEARCH}
                    map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                    searchBy="name || ' ' || code"
                    itemName={[['text'], ['code']]}
                    customItemNameLabel={'text [code]'}
                    renderOption={(option: any) => `${option.text}${option.code ? ' [' + option.code + ']' : ''}`}
                    setDefaultFilterValue={itemFilter}
                    SearchPopupComponent={<ItemIndex type={type} disabled={true} defaultFilterValue={itemFilter()} />}
                />
            );
            break;
        case 'quantity':
            inputNode = <NyInputNumber leftToRight={numbersWriting} style={{ width: '100%' }} />;
            break;
        default:
            inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: inputType === 'quantity' ? false : true,
                            message: geti18nText('app.default.required'),
                            // whitespace: ['quantity', 'item'].includes(inputType) ? false : true,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
