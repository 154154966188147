import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { geti18nText, NyInputNumber, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, InputNumber, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    formatJoppdCodeSearch,
    okNotification,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';

const { Panel } = Collapse;

const PayrollIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();

    const { numbersWriting } = useSelector((state: RootState) => state.generalSettings);
    const [dataForm, setDataForm] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1', '3', '5', '7']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2', '4', '6']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '3', '5', '7']);
            setRightCollapseActiveKeys(['2', '4', '6']);
        }

        setCollapseAll(!collapseAll);
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['payroll'] = isChanged;
        setDirty(tmpDirty);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const setDefaultFilterValue = (type: string = 'CALCULATION_PARAMETERS') => {
        return [{ field: 'setting_group', condition: 'equals', value: type }];
    };

    const setJoppdApplicantCodeFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(1, 'III.1.5.');
    };

    const setJoppdTownshipCodeFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '2.');
    };

    const setJoppdCodeMio2InterestFilterValue = () => {
        return setJoppdCodeDefaultFilterValue(2, '6.2.');
    };

    useEffect(() => {
        fetch('CALCULATION_PARAMETERS');
        fetch('PAYROLL_AUTOMATIC_CALC');
        fetch('PAYROLL');
    }, []);

    async function fetch(type: string = 'CALCULATION_PARAMETERS') {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue(type))),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    let newData: any = [...defaultData, ...result.data];
                    setDefaultData(newData);
                }
            }
        });
    }

    const [currentData, setCurrentData] = useState<any>(undefined);

    useEffect(() => {
        if (currentData) {
            setDataForm([...dataForm, ...currentData]);
        }
    }, [currentData]);

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setCurrentData(dataForm);

        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 3) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            } else if (element.dataType === 8 && newDataForm[element.settingKey] != undefined) {
                newDataForm[element.settingKey] = JSON.parse(newDataForm[element.settingKey]);
            } else if (element.dataType === 9 && element.defaultFilter) {
                let defFilter = JSON.parse(element.defaultFilter);
                newDataForm[element.settingKey] = await formatJoppdCodeSearch(
                    newDataForm[element.settingKey],
                    defFilter.page,
                    defFilter.node
                );
            }
        }

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        let val: any = value;
                        if (item.dataType === 3) {
                            val = val ? '1' : '0';
                        } else if (item.dataType === 9) {
                            val = val.code;
                        } else if (item.dataType === 8) {
                            val = JSON.stringify(val);
                        }
                        params.push({ id: item.id, value: val ? String(val) : '' });
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'username_exist':
                                        errorNotification(geti18nText('app.default.username_exist'));
                                        return;
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(geti18nText(result.data.error));
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.1')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.BRUT_BASE'
                                        )}
                                        name="BRUT_BASE"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MINIMUM_WAGE_BRUT'
                                        )}
                                        name="MINIMUM_WAGE_BRUT"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MINIMUM_WAGE_NET'
                                        )}
                                        name="MINIMUM_WAGE_NET"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.LEGAL_ENFORCEMENT_AVERAGE_NET'
                                        )}
                                        name="LEGAL_ENFORCEMENT_AVERAGE_NET"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.3')} key="3">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MINIMUM_SICK_LEAVE_REIMBURSEMENT'
                                        )}
                                        name="MINIMUM_SICK_LEAVE_REIMBURSEMENT"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MAXIMUM_SICK_LEAVE_REIMBURSEMENT'
                                        )}
                                        name="MAXIMUM_SICK_LEAVE_REIMBURSEMENT"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.5')} key="5">
                            <Row gutter={24}>
                                <Col span={7}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.PAST_SERVICE_REWARD'
                                        )}
                                        name="PAST_SERVICE_REWARD"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} min={0} max={100} isDecimal={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.WORK_CONDITIONS'
                                        )}
                                        name="WORK_CONDITIONS"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} min={0} max={100} isDecimal={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.LOYALTY_REWARD'
                                        )}
                                        name="LOYALTY_REWARD"
                                    >
                                        <NyInputNumber style={{ width: '100%' }} min={0} max={100} isDecimal={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.7')} key="7">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.WORKTIME_REDISTRIBUTION'
                                        )}
                                        name="WORKTIME_REDISTRIBUTION"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.WORKTIME_REDISTRIBUTION_OVERTIME'
                                        )}
                                        name="WORKTIME_REDISTRIBUTION_OVERTIME"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.WORKTIME_REDISTRIBUTION_HOURS'
                                        )}
                                        name="WORKTIME_REDISTRIBUTION_HOURS"
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.2')} key="2">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MINIMUM_INSURANCE_BASE'
                                        )}
                                        name="MINIMUM_INSURANCE_BASE"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MAXIMUM_INSURANCE_BASE'
                                        )}
                                        name="MAXIMUM_INSURANCE_BASE"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.MAXIMUM_YEARLY_INSURANCE_BASE'
                                        )}
                                        name="MAXIMUM_YEARLY_INSURANCE_BASE"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.4')} key="4">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.TAX_FREE_INCOME'
                                        )}
                                        name="TAX_FREE_INCOME"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.TAX_DEDUCTION_BASE'
                                        )}
                                        name="TAX_DEDUCTION_BASE"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.TAX_FREE_INSURANCE_POLICY'
                                        )}
                                        name="TAX_FREE_INSURANCE_POLICY"
                                    >
                                        <NyInputNumber
                                            leftToRight={numbersWriting}
                                            isDecimal
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.payroll.panel.6')} key="6">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.JOPPD_CODE_APPLICANT'
                                        )}
                                        name="JOPPD_CODE_APPLICANT"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            setDefaultFilterValue={setJoppdApplicantCodeFilterValue}
                                            map={{ id: 'id', label: 'code' }}
                                            searchBy="code"
                                            nyTestId="joppdCode"
                                            SearchPopupComponent={
                                                <JoppdCodeIndex
                                                    defaultFilteredValue={setJoppdApplicantCodeFilterValue}
                                                />
                                            }
                                            itemName="code"
                                            addNewItem={true}
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.JOPPD_CODE_TOWNSHIP'
                                        )}
                                        name="JOPPD_CODE_TOWNSHIP"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            setDefaultFilterValue={setJoppdTownshipCodeFilterValue}
                                            map={{ id: 'id', label: 'code' }}
                                            searchBy="code"
                                            nyTestId="joppdCode"
                                            SearchPopupComponent={
                                                <JoppdCodeIndex
                                                    defaultFilteredValue={setJoppdTownshipCodeFilterValue}
                                                />
                                            }
                                            itemName="code"
                                            addNewItem={true}
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.CALCULATION_PARAMETERS.JOPPD_CODE_MIO2_INTEREST'
                                        )}
                                        name="JOPPD_CODE_MIO2_INTEREST"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                            setDefaultFilterValue={setJoppdCodeMio2InterestFilterValue}
                                            map={{ id: 'id', label: 'code' }}
                                            searchBy="code"
                                            nyTestId="joppdCode"
                                            SearchPopupComponent={
                                                <JoppdCodeIndex
                                                    defaultFilteredValue={setJoppdCodeMio2InterestFilterValue}
                                                />
                                            }
                                            itemName="code"
                                            addNewItem={true}
                                            order="code"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('payment.calculation.edit.calcSettingsBrut')} key="8">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_REGULAR_WORK'
                                        )}
                                        name="AUTO_REGULAR_WORK"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_HOLIDAYS'
                                        )}
                                        name="AUTO_HOLIDAYS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_OVERTIME'
                                        )}
                                        name="AUTO_OVERTIME"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_SICKLEAVE'
                                        )}
                                        name="AUTO_SICKLEAVE"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_LEAVE'
                                        )}
                                        name="AUTO_LEAVE"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_PAST_SERVICE'
                                        )}
                                        name="AUTO_PAST_SERVICE"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_LOYALTY_REWARD'
                                        )}
                                        name="AUTO_LOYALTY_REWARD"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_WORK_CONDITIONS'
                                        )}
                                        name="AUTO_WORK_CONDITIONS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_ADDITIONS'
                                        )}
                                        name="AUTO_ADDITIONS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.AUTO_DEDUCTIONS'
                                        )}
                                        name="AUTO_DEDUCTIONS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL_AUTOMATIC_CALC.TRAVEL_EXPENSES_ATTENDED_DAYS_ONLY'
                                        )}
                                        name="TRAVEL_EXPENSES_ATTENDED_DAYS_ONLY"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('payment.calculation.edit.calcSettingsPayroll')} key="9">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_ABSENCE')}
                                        name="PAYROLL_ABSENCE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_DAILY_REST'
                                        )}
                                        name="PAYROLL_DAILY_REST"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_FIELD_WORK'
                                        )}
                                        name="PAYROLL_FIELD_WORK"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_HOLIDAY')}
                                        name="PAYROLL_HOLIDAY"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_INABILITY')}
                                        name="PAYROLL_INABILITY"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_LOCKOUT')}
                                        name="PAYROLL_LOCKOUT"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_MATERNITY_LEAVE'
                                        )}
                                        name="PAYROLL_MATERNITY_LEAVE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_NIGHT_WORK'
                                        )}
                                        name="PAYROLL_NIGHT_WORK"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_OVERTIME')}
                                        name="PAYROLL_OVERTIME"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_PAID_VACATION'
                                        )}
                                        name="PAYROLL_PAID_VACATION"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_REGULAR_WORK'
                                        )}
                                        name="PAYROLL_REGULAR_WORK"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_SICK_LEAVE'
                                        )}
                                        name="PAYROLL_SICK_LEAVE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_SICK_LEAVE_REFOUNDED'
                                        )}
                                        name="PAYROLL_SICK_LEAVE_REFOUNDED"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_STANDSTILL'
                                        )}
                                        name="PAYROLL_STANDSTILL"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_STAND_BY_REDINESS'
                                        )}
                                        name="PAYROLL_STAND_BY_REDINESS"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_STRIKE')}
                                        name="PAYROLL_STRIKE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_UNJUSTIFIED_ABSENCE'
                                        )}
                                        name="PAYROLL_UNJUSTIFIED_ABSENCE"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_WORK_ON_FREE_HOLIDAY'
                                        )}
                                        name="PAYROLL_WORK_ON_FREE_HOLIDAY"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_WORK_ON_HOLIDAY'
                                        )}
                                        name="PAYROLL_WORK_ON_HOLIDAY"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_PAYROLL.PAYROLL.PAYROLL_WORK_ON_SUNDAY'
                                        )}
                                        name="PAYROLL_WORK_ON_SUNDAY"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            style={{ width: '100%' }}
                                            searchBy={'name'}
                                            className=""
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    {canCreate() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default PayrollIndex;
