import { NyDataTable, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { Button, Progress } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import EmployeeEvaluationRequestModal from '../request-modal/EmployeeEvaluationRequestModal';
import SendEvaluationModal from '../request-modal/SendEvaluationModal';
import EmployeeEvaluationPeriodsEdit from './EmployeeEvaluationPeriodsEdit';

const EmployeeEvaluationPeriodsView = ({
    showRowSelection,
    refresh,
    setRefresh,
    activeList,
    search,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
    const [requestModalVisible, setRequestModalVisible] = useState<boolean>(false);
    // const [sendEvaluationModalVisible, setSendEvaluationModalVisible] = useState<boolean>(false);

    const canCreate = () => {
        return HumanResourcesRights.isEvaluationAdmin();
    };

    const columns = [
        {
            title: geti18nText('employee.evaluation.period.table.column.period'),
            dataIndex: 'period',
            render: (text: any, record: any) => {
                return (
                    <>
                        {record?.dateFrom && moment(record.dateFrom).format('DD.MM.YYYY.')} -{' '}
                        {record?.dateTo && moment(record.dateTo).format('DD.MM.YYYY.')}
                    </>
                );
            },
        },
        {
            title: geti18nText('employee.evaluation.period.table.column.employeeEvaluationTotalCount'),
            children: [
                {
                    dataIndex: 'employeeEvaluatioCompletedCount',
                    align: 'right',
                },
                {
                    dataIndex: 'employeeEvaluationTotalCountProgress',
                    render: (text: any, record: any) => {
                        const percent =
                            (record.employeeEvaluatioCompletedCount / record.employeeEvaluationTotalCount) * 100;
                        const percentFormated = Number(percent.toFixed(2));

                        return (
                            <div>
                                <Progress percent={percentFormated} showInfo={false} />
                            </div>
                        );
                    },
                },
                {
                    dataIndex: 'employeeEvaluationTotalCount',
                    align: 'left',
                },
            ],
        },
    ];

    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const addedButtons = () => {
        if (activeList === 3 && canCreate()) {
            return (
                <>
                    {/* <div style={{ float: 'left' }}>
                        <Button onClick={() => setSendEvaluationModalVisible(true)}>
                            {geti18nText('employee.evaluation.edit.button.sendToEvaluation')}
                        </Button>
                    </div> */}
                    <Button onClick={() => setRequestModalVisible(true)} type="primary">
                        {geti18nText('employee.evaluation.table.button.add')}
                    </Button>
                </>
            );
        }
    };

    return (
        <>
            <NyDataTable
                nyId="human-evaluation-employee-evaluation-period-table"
                key={activeList}
                url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.LIST}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                columns={columns}
                editProps={{
                    setRefresh: setRefresh,
                }}
                onRowSelect={(record: any) => {
                    if (record?.id) {
                        setSelectedPeriod(record);
                        setEditModalVisible(true);
                    }
                }}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                showRowSelection={showRowSelection}
                hideNewButton
                shortcuts={true}
                nyTestId="employee-evaluation-table"
                fetchWhenChange={refresh}
                exportCSV={true}
                addedButtons={addedButtons}
                CSVFileName={geti18nText('menu.evaluation.codebooks')}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
            />
            <EmployeeEvaluationPeriodsEdit
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                refresh={refresh}
                setRefresh={setRefresh}
                activeList={activeList}
                isAdmin={canCreate}
            />

            <EmployeeEvaluationRequestModal
                requestModalVisible={requestModalVisible}
                setRequestModalVisible={setRequestModalVisible}
                setRefresh={setRefresh}
                selectedPeriod={selectedPeriod}
            />
            {/* <SendEvaluationModal
                sendEvaluationModalVisible={sendEvaluationModalVisible}
                setSendEvaluationModalVisible={setSendEvaluationModalVisible}
                setRefresh={setRefresh}
                defaultPeriod={selectedPeriod}
            /> */}
        </>
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('employee.evaluation.period.table.column.dateFrom'),
            dataIndex: 'dateFrom',
        },
        {
            title: geti18nText('employee.evaluation.period.table.column.dateTo'),
            dataIndex: 'dateTo',
        },
        {
            title: geti18nText('employee.evaluation.period.table.column.employeeEvaluatioCompletedCount'),
            dataIndex: 'employeeEvaluatioCompletedCount',
        },
        {
            title: geti18nText('employee.evaluation.period.table.column.employeeEvaluationTotalCount'),
            dataIndex: 'employeeEvaluationTotalCount',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            dateFrom: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            dateTo: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export default EmployeeEvaluationPeriodsView;
