import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    getDateFormat,
    GetEmployeeEducationStatusIcon,
    getTaskStatusIcon,
    getTravelWarrantPaymentStatusIcon,
    getTravelWarrantStatusIcon,
} from '../../utils/Utils';

const StatusHistoryIndex = ({ id, url = undefined, type = '', params = undefined }: any) => {
    const [data, setData] = useState<any>([]);
    const travelWarrantPaymentStatus = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');

    useEffect(() => {
        if (id) importData();
    }, [id]);

    const getIconByType = (status: any) => {
        return type == 'travelWarrant'
            ? getTravelWarrantStatusIcon(status)
            : type == 'travelWarrantPayment'
            ? getTravelWarrantPaymentStatusIcon(status, travelWarrantPaymentStatus)
            : type == 'taskStatus'
            ? getTaskStatusIcon(status)
            : type == 'education'
            ? GetEmployeeEducationStatusIcon(status)
            : null;
    };

    const columns: any = [
        {
            title: geti18nText('travelWarrantStatusHistory.table.column.status'),
            dataIndex: 'status',
            render: (status: any) => {
                return getIconByType(status);
            },
        },
        {
            title: geti18nText('travelWarrantStatusHistory.table.column.note'),
            dataIndex: 'note',
            render: (note: any) => {
                return (
                    <Tooltip placement="top" title={note}>
                        {' '}
                        <div style={{ display: 'inline-flex', maxWidth: '400px' }}>
                            <span className={'overview-cards-paragraph'}>{note}</span>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: geti18nText('travelWarrantStatusHistory.table.column.created'),
            dataIndex: 'created',
            render: (created: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('travelWarrantStatusHistory.table.column.createdBy'),
            dataIndex: "concat(person.first_name, ' ', person.last_name) ",
            render: (person: any, record: any) => {
                if (record.person) {
                    return record.person.firstName + ' ' + record.person.lastName;
                }
            },
        },
    ];

    const importData = () => {
        let defaultUrl = CONSTANTS_REQ.TRAVEL_WARRANT.TRAVEL_WARRANT_STATUS_HISTORY + '/' + id;
        if (url != undefined) {
            defaultUrl = url;
        }
        if (id) {
            NyRequestResolver.requestGet(defaultUrl, params).then((result: any) => {
                if (result.status == RESPONSE.OK && result.data) {
                    setData(result.data);
                }
            });
        }
    };

    return <Table size={'small'} dataSource={data} columns={columns} rowClassName="editable-row" pagination={false} />;
};

export default StatusHistoryIndex;
