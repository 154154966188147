import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { useEffect, useState } from 'react';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useTableSettings from '../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../utils/Constants';
import { numberFormat } from '../../utils/Utils';
import { carIcon, wageIcon, wageIconSmall } from '../travel-warrant-icons';
import TravelWarrantRelationEdit from './edit';

const TravelWarrantVehicleIndex = ({
    isPersonalVehicle = false,
    isAdmin = false,
    id,
    isWizard = true,
    readonly = false,
    travelWarrantType = undefined,
    travelWarrant = undefined,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const [refresh, setRefresh] = useState(0);
    const table = useTableSettings();
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');

    const [visible, setVisible] = useState<boolean>(false);
    const [relation, setRelation] = useState<any>(undefined);
    const [wholeTripIsPersonalVehicle, setWholeTripIsPersonalVehicle] = useState<boolean>(true);
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');

    useEffect(() => {
        if (isWizard) {
            getTravelWarrantWageData();
        }
    }, [id]);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travelWarrant.id', condition: 'equals', value: id },
        ];
    };

    const getDestinationName = (name: any) => {
        const returnName = name.replaceAll(' - Granični prijelaz', '');
        return returnName;
    };

    const columns: any = [
        {
            title: geti18nText('travelWarrantRelation.table.column.travelRelation'),
            dataIndex: 'name',
            width: '23%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.name) {
                    if (record.taxable) {
                        return (
                            <>
                                {getDestinationName(record.name)} {wageIconSmall}
                            </>
                        );
                    } else {
                        return getDestinationName(record.name);
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.travelVehicleName'),
            dataIndex: ['travelVehicle', 'name'],
            width: '16%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, recrod: any) => {
                if (recrod.travelVehicle) {
                    return recrod.travelVehicle.name;
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.counterStart'),
            dataIndex: 'counterStart',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (counterStart: any) => {
                return counterStart ? Intl.NumberFormat('hr-HR', {}).format(counterStart) : 0;
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.counterEnd'),
            dataIndex: 'counterEnd',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (counterEnd: any) => {
                return counterEnd ? Intl.NumberFormat('hr-HR', {}).format(counterEnd) : 0;
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.distance'),
            dataIndex: 'distance',
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (distance: any) => {
                return distance ? Intl.NumberFormat('hr-HR', {}).format(distance) : 0;
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.amount'),
            dataIndex: 'amount',
            width: '5%',
            sorter: (a: any, b: any) => {},
            align: 'right',
            ...getColumnSearch('number'),
            render: (amount: any) => {
                return amount ? (
                    <div style={{ float: 'right' }}>{numberFormat(amount)}</div>
                ) : (
                    <div style={{ float: 'right', color: 'red' }}>{numberFormat(0)}</div>
                );
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.currency'),
            width: '4%',
            render: (text: any) => {
                return defaultCurrencyISO;
            },
        },
    ];

    const adminColumns: any = [
        {
            title: geti18nText('travelWarrantRelation.table.column.travelRelation'),
            dataIndex: 'name',
            width: '23%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.name) {
                    if (record.taxable) {
                        return (
                            <>
                                {getDestinationName(record.name)} {wageIconSmall}
                            </>
                        );
                    } else {
                        return getDestinationName(record.name);
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.travelVehicleName'),
            dataIndex: ['travelVehicle', 'name'],
            width: '16%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, recrod: any) => {
                if (recrod.travelVehicle) {
                    return recrod.travelVehicle.name;
                }
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.counterStart'),
            dataIndex: 'counterStart',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.counterEnd'),
            dataIndex: 'counterEnd',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.distance'),
            dataIndex: 'distance',
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.amount'),
            dataIndex: 'amount',
            width: '5%',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any) => {
                return amount ? (
                    <div style={{ float: 'right' }}>{numberFormat(amount)}</div>
                ) : (
                    <div style={{ float: 'right' }}>{numberFormat(0)}</div>
                );
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.currency'),
            width: '4%',
            render: (text: any) => {
                return defaultCurrencyISO;
            },
        },
        {
            title: geti18nText('travelWarrantRelation.table.column.active'),
            dataIndex: 'active',
            width: '5%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const getTravelWarrantWageData = () => {
        if (id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_RELATION.LIST, {
                search: encodeURI(JSON.stringify(setDefaultFilterValue())),
            }).then((res: any) => {
                if (res.status === RESPONSE.OK && res.data && res.data.content && res.data.content.length > 0) {
                    if (isPersonalVehicle) {
                        setRelation(res.data.content[0]);
                    }
                    if (!res.data.content[0].counterStart || !res.data.content[0].counterEnd) {
                        setVisible(true);
                    }
                } else {
                    if (isPersonalVehicle) {
                        NyRequestResolver.requestPost(
                            CONSTANTS_REQ.TRAVEL_WARRANT.GENERATE_TRAVEL_WARRANT_RELATION,
                            undefined,
                            {
                                id: id,
                            }
                        ).then((result: any) => {
                            if (result.status === RESPONSE.CREATED && result.data && result.data.length > 0) {
                                setRelation(result.data[0]);
                                setVisible(true);
                                setRefresh((refresh) => refresh + 1);
                            }
                        });
                    }
                }
            });
        }
    };

    return (
        <>
            <div style={{ marginBottom: '-24px' }}>
                {wageIcon}
                <b>{geti18nText('travelWarrantRecapitulation.table.taxable.wage').split('**')}</b>
                {travelWarrantType?.payoutByAnotherOrganization && (
                    <b style={{ marginLeft: '2.25em', top: '2px', position: 'relative' }}>
                        {carIcon}
                        {geti18nText('travelWarrantRecapitulation.travel.costs.received', [
                            numberFormat(travelWarrant?.travelVehicleExpenseAmountReceived),
                            defaultCurrencyIsoCode,
                        ]).split('***')}
                    </b>
                )}
            </div>

            <NyDataTable
                nyId="travel-warrent-vehicle"
                url={CONSTANTS_REQ.TRAVEL_WARRANT_RELATION.LIST}
                addNewButtonText={
                    isAdmin || !wholeTripIsPersonalVehicle || !isPersonalVehicle
                        ? geti18nText('travelWarrantRelation.table.add')
                        : null
                }
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                fetchWhenChange={refresh}
                setDefaultPageSize={table.setDefaultPageSize()}
                modalComponent={TravelWarrantRelationEdit}
                readonly={readonly}
                hideButtons={readonly}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'asc' }}
                scroll={scroll}
                columns={isAdmin ? adminColumns : columns}
                editProps={{
                    wholeTripIsPersonalVehicle: wholeTripIsPersonalVehicle,
                    setWholeTripIsPersonalVehicle: setWholeTripIsPersonalVehicle,
                    isWizard: isWizard,
                }}
                shortcuts={true}
                nyTestId="travel-relation-table"
                addedData={{ isWizard: isAdmin, id: id }}
                onSaveAndGetID={() => setRefresh((refresh) => refresh + 1)}
            />
            <TravelWarrantRelationEdit
                isModal={true}
                visible={visible}
                setVisible={setVisible}
                value={relation}
                addedData={{ isAdmin: isAdmin, id: id }}
                editProps={{
                    wholeTripIsPersonalVehicle: wholeTripIsPersonalVehicle,
                    setWholeTripIsPersonalVehicle: setWholeTripIsPersonalVehicle,
                    disabled: readonly,
                    isWizard: isWizard,
                }}
                onSaveAndGetID={() => setRefresh((refresh) => refresh + 1)}
            />
        </>
    );
};

export default TravelWarrantVehicleIndex;
