import {
    NyDataEdit,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NyNoteModal from '../../components/note-modal';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    getSearchFormat,
    getTravelWageSelectOption,
    okNotification,
    setSearchFormat,
    userIsNotificationRecipient,
} from '../../utils/Utils';
import CountryIndex from '../../views/administration/views/country';
import CountryEdit from '../../views/administration/views/country/edit';
import CurrencySearch from '../../views/administration/views/currency/search';
import TravelWageSearch from '../../views/travel-wage/search';

const TravelWarrantWageEdit = (props: any) => {
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const enTravelWageType = useEnum('TRAVEL_WAGE_TYPE');
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [form] = Form.useForm();
    const history = useHistory();
    const isAdmin = props?.editProps?.isAdmin ? true : false;
    const isWizard = props?.editProps?.isWizard ? true : false;
    const { employee } = useSelector((state: RootState) => state.employee);
    const [editHeader, setEditHeader] = useState(geti18nText('travelWarrantWage.edit.new'));
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [nonStandardWage, setNonStandardWage] = useState<any>(null);
    const [countryId, setCountryId] = useState(undefined);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);
    const [wageType, setWageType] = useState<any>(null);
    const [includeNonStandard, setIncludeNonStandard] = useState<boolean>(false);
    const [nonStandardWageNr, setNonStandardWageNr] = useState<any>(0);
    const [taxable, setTaxable] = useState<boolean>(false);

    const disabledForm: boolean = props?.editProps?.disabled;

    useEffect(() => {
        if (countryId) {
            getNonStandardWage(countryId);
        }
    }, [countryId]);

    const canEditWage = () => {
        return isAdmin && !isWizard && !disabledForm;
    };

    const canCreate = () => {
        return (
            !disabledForm &&
            (TravelWarrantRights.canCreateTravelWarrantAdmin() ||
                TravelWarrantRights.canOpenTravelWarrantWage(employee, notificationType))
        );
    };

    const canSelectNonStandardWage = () => {
        return TravelWarrantRights.canCreateTravelWarrantUser() && nonStandardWage;
    };

    const getWageFilter = (countryId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'country.id', condition: 'equals', value: countryId },
            //    / { field: 'employeeId', condition: 'equals', value: props?.editProps?.employeeId },
        ];
    };

    const getNonStandardWage = (countryId: any) => {
        const stringUriNonStandard = encodeURI(JSON.stringify(getWageFilter(countryId)));
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WAGE.EMPLOYEE_SEARCH, { search: stringUriNonStandard }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setNonStandardWage(result.data);
                }
            }
        );
    };

    function setValues(dataForm: any) {
        if (dataForm?.travelWage?.name) {
            setEditHeader(geti18nText('travelWarrantWage.edit.title') + ' ' + dataForm?.travelWage?.name);
        }
        if (dataForm.currency) {
            dataForm.currency = setSearchFormat(dataForm.currency, 'isoCode');
        }
        if (dataForm.country) {
            setCountryId(dataForm.country.id);
            dataForm.country = setSearchFormat(dataForm.country);
        }

        setNote(dataForm.note ? dataForm.note : undefined);
        setWageType(dataForm.travelWage?.type);
        setIncludeNonStandard(dataForm.includeNonStandard);
        setTaxable(dataForm.taxable);
        setNonStandardWageNr(dataForm.nonStandardWageNr);

        if (dataForm.travelWage) {
            dataForm.travelWageAmount = dataForm.travelWage.amount;
            dataForm.travelWage = setSearchFormat(dataForm.travelWage, 'name', 'name');
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        travelWageOnChange(dataForm?.travelWage);
    }

    const onModalClose = () => {
        setTriggerCloseModal(true);
        form.resetFields();
        setNote(undefined);
        setEditHeader(geti18nText('travelWarrantWage.edit.new'));
        setNonStandardWage(null);
        setIncludeNonStandard(false);
        setTaxable(false);
        setWageType(null);
    };

    const travelWageOnChange = (value: any) => {
        if (value && value.id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WAGE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        form.setFieldsValue({
                            currency: result.data.currency
                                ? { id: result.data.currency.id, text: result.data.currency.isoCode }
                                : null,
                            travelWageAmount: result.data.amount ? result.data.amount : 0,
                            travelWage: getTravelWageSelectOption(result.data),
                        });
                    }
                }
            );
        }
    };

    const countryOnChange = (value: any) => {
        if (value) {
            form.setFieldsValue({ travelWage: null });
            setCountryId(value.id);
        }
    };

    const normalize = (values: any, deactivate: boolean = false) => {
        if (values.travelWage) values.travelWage = getSearchFormat(values.travelWage);
        if (values.currency) values.currency = getSearchFormat(values.currency);
        if (values.country) values.country = getSearchFormat(values.country);

        values.travelWarrant = { id: props?.editProps?.id };
        if (deactivate) {
            values.active = false;
        }
        return values;
    };

    const afterSaveAction = (result: any) => {
        if (result && result.status === RESPONSE.CREATED) {
            okNotification();
            props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
            onModalClose();
        }
    };
    const saveWage = (values: any, deactivate: boolean = false) => {
        setLoading(true);
        values = normalize(values, deactivate);
        if (values) {
            if (values.id) {
                NyRequestResolver.requestPut(
                    CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.EDIT + '/' + values.id,
                    undefined,
                    values
                ).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        afterSaveAction(result);
                    }
                });
            } else {
                NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.EDIT, undefined, values).then(
                    (result: any) => {
                        afterSaveAction(result);
                    }
                );
            }
        }
        setLoading(false);
    };

    const save = (deactivate: boolean = false) => {
        form.validateFields()
            .then((values: any) => {
                saveWage(values, deactivate);
            })
            .catch((errorInfo: any) => {});
    };

    const calculateAmount = () => {
        let amount = 0;
        let exchangeRate = form.getFieldValue(['exchangeRate']);
        let amountInCurrency = form.getFieldValue(['amountInCurrency']);
        if (exchangeRate && amountInCurrency) {
            amount = amountInCurrency * exchangeRate;
        }
        form.setFieldsValue({ amount: amount });
    };

    const getCustomFooterContent = (
        <>
            <div className="ny-modal-footer-content">
                <NyNoteModal note={note} disabled={!isAdmin || disabledForm} />
            </div>
            {canCreate() && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm title={geti18nText('app.default.destructive.confirm')} onConfirm={() => save(true)}>
                        <Button type="primary" danger>
                            {geti18nText('app.default.button.deactivate')}
                        </Button>
                    </NyModalConfirm>
                    <Button key="submit" type="primary" onClick={() => save(false)}>
                        {geti18nText('app.default.button.save')}
                    </Button>
                </div>
            )}
        </>
    );

    return (
        <NyDataEdit
            value={props?.editProps?.value}
            visible={props?.editProps?.visible}
            setVisible={props?.editProps?.setVisible}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            onSave={() => props?.editProps?.setRefreshTable(props?.editProps?.refreshTable + 1)}
            goBack={() => history.goBack()}
            paramsId={props?.editProps?.id}
            {...props}
            isModal={true}
            customFooterContent={getCustomFooterContent}
            shortcuts={true}
            normalize={normalize}
            nyTestId="wage-data-edit"
            hideSubmitButton={true}
            hideActivationButtons
            triggerCloseModal={triggerCloseModal}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item label={geti18nText('settlement.edit.country')} name="country">
                        <NySearchField
                            url={CONSTANTS_REQ.COUNTRY.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<CountryIndex readonly={true} />}
                            AddNewModalComponent={CountryEdit}
                            nyTestId="country"
                            onChange={countryOnChange}
                            autoFocus={true}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <TravelWageSearch
                        personal={props?.addedData?.personal}
                        travelWage={props?.value?.travelWage}
                        travelWageOnChange={travelWageOnChange}
                        countryId={countryId}
                        employeeId={props?.editProps?.employeeId}
                        disabled={!canEditWage()}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWarrantWage.edit.totalDays')} name="totalDays">
                        <NyInputNumber isDecimal={false} style={{ width: '100%' }} disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWarrantWage.edit.travelWageAmount')} name="travelWageAmount">
                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWarrantWage.edit.amountInCurrency')} name="amountInCurrency">
                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} onChange={calculateAmount} disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <CurrencySearch
                        label={geti18nText('travelWarrantWage.edit.currency')}
                        name="currency"
                        readonly={true}
                        disabled
                    />
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWarrantWage.edit.exchangeRate')} name="exchangeRate">
                        <NyInputNumber
                            isDecimal={true}
                            style={{ width: '100%' }}
                            onChange={calculateAmount}
                            decimalPlaces={6}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('travelWarrantWage.edit.amount', [defaultCurrencyISO])} name="amount">
                        <NyInputNumber isDecimal={true} style={{ width: '100%' }} disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('travelWarrantWageRecapitulation.taxable')}
                        name="taxable"
                        valuePropName="checked"
                    >
                        <Checkbox
                            style={{ width: '30%' }}
                            checked={taxable}
                            onChange={(event: any) => {
                                setTaxable(event.target.checked);
                            }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
                {TravelWarrantRights.canOpenTravelWarrantWage(employee, notificationType) &&
                    wageType === enTravelWageType.STANDARD &&
                    nonStandardWageNr > 0 && (
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantWageRecapitulation.useNonStandard')}
                                name="includeNonStandard"
                                valuePropName="checked"
                            >
                                <Checkbox
                                    style={{ width: '30%' }}
                                    checked={includeNonStandard}
                                    onChange={(event: any) => {
                                        setIncludeNonStandard(event.target.checked);
                                    }}
                                    disabled={disabledForm}
                                />
                            </Form.Item>
                        </Col>
                    )}
            </Row>
        </NyDataEdit>
    );
};

export default TravelWarrantWageEdit;
