import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookBusinessUnitEmployee,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchByCodebookWorkplace,
} from '../../../../utils/Filters';
import { getDateFormat } from '../../../../utils/Utils';
import NyDescription from '../../../../components/ny-description';
import { useState } from 'react';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const OutsideCompanyTable = ({
    addedButtons,
    scroll = { y: 570, x: 3200 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const table = useTableSettings();

    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const employeeMilitaryRecordsAssigned = useEnum('EMPLOYEE_MILITARY_RECORDS_ASSIGNED');
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const columns = [
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit.id'),
            dataIndex: ['businessUnit', 'code'],
            render: (text: any, record: any) => {
                return record?.employee?.businessUnit?.code;
            },
            width: '70px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnitEmployee(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.bussiness.unit'),
            dataIndex: ['businessUnit', 'name'],
            render: (text: any, record: any) => {
                return record?.employee?.businessUnit?.name;
            },
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnitEmployee(true),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.name.lastname'),
            dataIndex: "concat(person.last_name,' ', person.first_name)",
            width: '120px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.employee)}>
                            {(record.employee.person && record.employee.person.lastName
                                ? record.employee.person.lastName + ', '
                                : '') +
                                (record.employee.person && record.employee.person.parentName
                                    ? record.employee.person.parentName + ', '
                                    : '') +
                                (record.employee.person && record.employee.person.firstName
                                    ? record.employee.person.firstName
                                    : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.work.place'),
            width: '200px',
            dataIndex: ['vocation', 'name'],
            render: (text: any, record: any) => {
                if (record?.vocation?.name) {
                    return (
                        <div>
                               {record?.vocation?.name}
                               {record?.vocationDescription?.name ? ' - ' + record.vocationDescription.name : ' '}
                        </div>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookWorkplace(),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.oib) {
                    return record.employee.person.oib;
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.birthdate'),
            dataIndex: ['person', 'birthDate'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.employee && record.employee.person && record.employee.person.birthDate) {
                    return getDateFormat(record.employee.person.birthDate, 'DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.qualifications'),
            dataIndex: ['employeeProfessionalQualification', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                'employeeVocationProfessionalQualification.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                    text: 'name',
                    code: 'code',
                }
            ),
           render: (created: any, record: any) => {
                if (record.employee && record?.employee?.vocation?.professionalQualification) {
                    return record.employee.vocation.professionalQualification;
                }
            },
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.professionalSpecialty'),
            dataIndex: ['professionalSpecialty', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return record?.professionalSpecialty?.name;
            },
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.PROFESSIONAL_SPECIALTY.SEARCH,
                'professionalSpecialty.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            )
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.department.defense'),
            dataIndex: ['defenseDepartment', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseDepartment.id',
                setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'),
            dataIndex: ['defenseSection', 'name'],
            width: '120px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimpleWithAddedData(
                CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                'defenseSection.id',
                setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
            ),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.address.full'),
            dataIndex: [`concat(address.street,' ', settlement.name)`],
            width: '120px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.address) {
                    return (
                        <div>
                            {(record.address.street ? record.address.street + ', ' : '') +
                                (record.address.settlement ? record.address.settlement.name : '')}
                        </div>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobilization.location'),
            dataIndex: ['mobilizationLocation', 'name'],
            width: '160px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                return record?.mobilizationLocation?.name;
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.mobile.full'),
            dataIndex: ['contact', 'mobile'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.email.full'),
            dataIndex: ['contact', 'email'],
            width: '100px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('schedule.suggestions.modal.employees.table.column.remark'),
            dataIndex: 'remark',
            width: '150px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (remark: any, record: any) => {
                if (record?.remark) {
                    return <NyDescription title={record?.remark} maxWidth={'150px'} />;
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'assign',
                condition: 'equals',
                value: employeeMilitaryRecordsAssigned.OUTSIDE_ORGANIZATION,
            },
            { field: 'obligated', condition: 'equals_bool', value: 1 },
        ];
    };

    return (
        <>
            <NyDataTable
                nyId="human-outside-company-table"
                url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.LIST}
                buttonsClassName="buttons-sticky"
                columns={columns}
                fetchWhenChange={refresh}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                addedButtons={addedButtons}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default OutsideCompanyTable;
