import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEmployeeSelectOption,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import BusinessUnitIndex from '../../../human/views/business-unit';
import EmployeeIndex from '../../../human/views/employee';
import { BusinessUnitSearch } from '../../../human/views/business-unit/search';

const OrderViewAccessSettingsEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('orderViewAccessSettings.edit.new'));
    const [loading, setLoading] = useState(false);
    const [employeeDisabled, setEmployeeDisabled] = useState<any>(false);
    const [businessUnitDisabled, setBusinessUnitDisabled] = useState<any>(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('orderViewAccessSettings.edit.title'));

        if (dataForm.type) {
            dataForm.type = setEnumFormat('ORDER_VIEW_ACCESS_TYPE', dataForm.type);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
            setEmployeeDisabled(false);
            setBusinessUnitDisabled(true);
        }
        if (dataForm.businessUnit) {
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            setEmployeeDisabled(true);
            setBusinessUnitDisabled(false);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEmployeeDisabled(false);
        setBusinessUnitDisabled(false);
        setEditHeader(geti18nText('orderViewAccessSettings.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.ORDER_VIEW_ACCESS_SETTINGS.EDIT}
            setValues={setValues}
            width={400}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            nyTestId="order-view-access-settings-edit"
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.type) values.type = getEnumFormat(values.type);

                if (values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    if (values.businessUnit.id < 1) {
                        delete values.businessUnit;
                    }
                }
                if (values.employee) {
                    values.employee = getSearchFormat(values.employee);
                    if (values.employee.id < 1) {
                        delete values.employee;
                    }
                }
                values.orderView = { id: props.addedData.orderViewId };
                if (values.active == null) {
                    values.active = true;
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="active" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="createdBy" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('orderViewAccessSettings.edit.type')}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('ORDER_VIEW_ACCESS_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            style={{ width: '100%' }}
                            className={''}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('orderViewAccessSettings.edit.employee')} name="employee">
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="access-settings" />}
                            disabled={employeeDisabled}
                            onChange={(value: any) => {
                                setBusinessUnitDisabled(value && value.id > 0 ? true : false);
                            }}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <BusinessUnitSearch
                        formItemName={'businessUnit'}
                        disabled={businessUnitDisabled}
                        onChange={(value: any) => {
                            setEmployeeDisabled(value && value.id > 0 ? true : false);
                        }}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default OrderViewAccessSettingsEdit;
