import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookAssetCategory, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { getEnumArrayStatus } from '../../../../../utils/Utils';
import AssetEdit from '../asset/edit';

const AllAssetsTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const table = useTableSettings();
    const dispatch = useDispatch();

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const customCsvRecord = (record: any) => {
        if (record.employee) {
            const customRecord = {
                ...record,
                debtor: {
                    ...(record.employee ? { employee: record.employee } : {}),
                },
            };

            return customRecord;
        } else if (record.businessUnit) {
            const customRecord = {
                ...record,
                debtor: {
                    ...(record.businessUnit ? { businessUnit: record.businessUnit } : {}),
                },
            };

            return customRecord;
        } else {
            return record;
        }
    };

    const initialColumns = [
        {
            title: geti18nText('asset.all.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record && record.serialNumber && record.id) {
                    let assetOrderText = record.serialNumber;
                    return (
                        <Tooltip title={assetOrderText}>
                            <Button
                                type="link"
                                style={{ textDecoration: 'underline' }}
                                onClick={() =>
                                    dispatch(
                                        setModalOpen({
                                            modal: 'assetEdit',
                                            visible: true,
                                            item: record.id,
                                        })
                                    )
                                }
                            >
                                <div style={{ maxWidth: '170px' }}>
                                    <p
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {assetOrderText}
                                    </p>
                                </div>
                            </Button>
                        </Tooltip>
                    );
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.all.table.column.employee'),
            dataIndex: 'employeeOrBusinessUnitName',
            width: '12%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.employee != null) {
                    return (
                        <div>
                            {(record.employee.person && record.employee.person.firstName
                                ? record.employee.person.firstName
                                : '') +
                                ' ' +
                                (record.employee.person && record.employee.person.lastName
                                    ? record.employee.person.lastName
                                    : '') +
                                ' ' +
                                (record.employee.employmentRecordId && '(' + record.employee.employmentRecordId + ')')}
                        </div>
                    );
                } else if (record.employee == null && record.businessUnit != null) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                } else if (record.employee == null && record.businessUnit == null) {
                    return geti18nText('asset.table.column.asset.in.storage');
                }
            },
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.all.table.column.employee.room'),
            dataIndex: ['employeeRoom', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ROOM.SEARCH, 'employeeRoom.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
            render: (text: any, record: any) => {
                if (record.employee && record.employee.room) {
                    return record.employee.room.name;
                } else if (record?.room?.name) {
                    return record.room.name;
                }
            },
        },
        {
            title: geti18nText('asset.all.table.column.room'),
            dataIndex: ['room', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ROOM.SEARCH, 'room.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.all.table.column.assetCategory'),
            dataIndex: ['assetCategory', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookAssetCategory(),
            render: (text: any, record: any) => {
                if (record.assetCategory) {
                    return (
                        record.assetCategory.name +
                        (record.assetCategory.code ? ' (' + record.assetCategory.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('asset.all.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_TYPE.SEARCH, 'assetType.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.all.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },

        {
            title: geti18nText('asset.all.table.column.releaseDate'),
            dataIndex: 'releaseDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.releaseDate) {
                    return moment(record.releaseDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('asset.all.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            render: (text: any, record: any) => {
                if (record.availabilityStatus !== undefined) {
                    return geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('ASSET_AVAILABILITY_STATUS'), 'equals', undefined),
        },
    ];

    return (
        <NyDataTable
            nyId="all-assets-table"
            url={CONSTANTS_REQ.ASSET.LIST}
            hideButtons
            showRecordModal={true}
            modalComponent={AssetEdit}
            columns={initialColumns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('assetOverview.tab.3')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            customCsvRecord={customCsvRecord}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            assetType: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            assetModel: (value: any) => {
                const regex1 = /"/g;
                const regex2 = /;/g;

                if (value != undefined && value.name != undefined) {
                    value.name = value.name.replace(regex1, '""');
                    value.name = value.name.replace(regex2, ' ');
                }

                return value != undefined && value.name != undefined && value;
            },
        },
        {
            availabilityStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + value);
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            releaseDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            debtor: (value: any) => {
                if (value && value?.employee) {
                    return value?.employee?.person?.firstName + ' ' + value?.employee?.person?.lastName;
                } else if (value && value?.businessUnit) {
                    return value?.businessUnit?.name + '(' + value?.businessUnit?.code + ')';
                }
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
        },
        {
            title: geti18nText('asset.all.table.column.employee'),
            dataIndex: 'debtor',
        },
        {
            title: geti18nText('asset.all.table.column.employmentRecordId'),
            dataIndex: ['employee', 'employmentRecordId'],
        },
        {
            title: geti18nText('employee.table.column.room'),
            dataIndex: ['employee', 'room', 'name'],
        },
        {
            title: geti18nText('asset.all.table.column.room'),
            dataIndex: ['room', 'name'],
        },
        {
            title: geti18nText('asset.table.column.assetCategory'),
            dataIndex: ['assetCategory', 'name'],
        },
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
        },

        {
            title: geti18nText('asset.table.column.releaseDate'),
            dataIndex: 'releaseDate',
        },
        {
            title: geti18nText('asset.all.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
        },
    ];
};

export default AllAssetsTable;
