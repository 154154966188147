import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import TeamIndex from '.';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TeamEdit from './edit';

const TeamSearch = ({
    classname = undefined,
    disabled = false,
    formStyle = null,
    label = geti18nText('task.edit.edit.assignedTeam'),
    map = { id: 'id', label: 'name' },
    mustGetPopupContainer = true,
    name = 'team',
    nyTestId = 'team',
    renderOption = undefined,
    required = false,
    setDefaultFilterValue = null,
    style = undefined,
}: any) => {
    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            style={formStyle}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.TEAM.SEARCH}
                map={map}
                searchBy="name"
                mustGetPopupContainer={mustGetPopupContainer}
                SearchPopupComponent={<TeamIndex />}
                AddNewModalComponent={canCreate() ? TeamEdit : null}
                nyTestId={nyTestId}
                setDefaultFilterValue={setDefaultFilterValue}
                disabled={disabled}
                className={classname}
                renderOption={renderOption}
                style={style}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default TeamSearch;
