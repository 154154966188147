import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const TownshipTaxSearch = ({ isForm = true, label, name, required = false, onChange = null }: any) => {
    return (
        <>
            {isForm ? (
                <Form.Item
                    label={label}
                    name={name}
                    rules={[
                        {
                            required: required,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    <NySearchField
                        style={{ width: '100%' }}
                        url={CONSTANTS_REQ.TOWNSHIP_TAX.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="township.name"
                        onChange={onChange}
                        order="township.name"
                    />
                </Form.Item>
            ) : (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.TOWNSHIP_TAX.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="township.name"
                    onChange={onChange}
                    order="township.name"
                />
            )}
        </>
    );
};

export default TownshipTaxSearch;
