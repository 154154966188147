import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Checkbox, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../components/note-modal';
import useEnum from '../../../../hooks/useEnum';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import {
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import { validateItemCode } from '../../../../utils/Validation';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import ItemGroupIndex from '../item-group';
import ItemGroupEdit from '../item-group/edit';
import MeasureUnitIndex from '../measure-unit';
import MeasureUnitEdit from '../measure-unit/edit';
import ItemGroupSearch from '../item-group/search';

const { Panel } = Collapse;

const ItemEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('item.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [availability, setAvailability] = useState('');
    const [metadata, setMetadata] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [itemGroup, setItemGroup] = useState(undefined);
    const [fileList, setFileList] = useState<any>([]);
    const [isService, setIsService] = useState(false);
    const [isAsset, setIsAsset] = useState(false);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');

    const focusInput = useRef<any>(null);
    const fileUploadRef = useRef<any>();

    const [form] = Form.useForm();
    const [objectMetadataForm] = Form.useForm();

    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        switch (props.addedData.type) {
            case 0:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
            case 2:
                //item-service
                return ServicesRights.canCreateCodebooks();
            case 8:
                //item-material
                return InventoryRights.canCreateCodebooks();
            case 4:
                //item-asset
                return AssetRights.canCreateCodebooks();
            default:
                //item
                return AdministrationRights.canCreateAdministrationCodebooks();
        }
    };

    const resolvePermissionByItemType = () => {
        if ((props && props.addedData && props.addedData.type == 2) || isService) return true;
        return false;
    };

    const checkIsService = (value: any) => {
        setIsService(false);
        setIsAsset(false);
        if (value != undefined && value.id == 2) {
            setIsService(true);
        } else if (value != undefined && value.id == 4) {
            setIsAsset(true);
        }
    };

    useEffect(() => {
        if (itemGroup !== undefined) {
            fetchMetadata(itemGroup);
        }
    }, [itemGroup]);

    useEffect(() => {
        if (metadata !== undefined && metadata.length > 0) {
            const metadataForm: any = {};

            if (form.getFieldValue('itemMetadata') !== undefined) {
                form.getFieldValue('itemMetadata').map((item: any) => {
                    if (item.item_group_metadata !== undefined) {
                        const findItem: any = metadata.find((row: any) => row.id === item.item_group_metadata.id);
                        if (findItem !== undefined && findItem.objectMetadata !== undefined) {
                            if (findItem.objectMetadata.dataType === 4) {
                                metadataForm[findItem.objectMetadata.name] = setDateFormat(item.value);
                            } else {
                                metadataForm[findItem.objectMetadata.name] = item.value;
                            }
                        }
                    }
                });
            }

            objectMetadataForm.setFieldsValue(metadataForm);
        }
    }, [metadata]);

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
        </>
    );

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('item.edit.title') + ' - ' + dataForm.id);
        }

        if (dataForm.itemGroup) {
            setItemGroup(dataForm.itemGroup.id);
        }

        setDataForm(dataForm.id);

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.availabilityStatus) {
            setAvailability(dataForm.availabilityStatus);
            dataForm.availabilityStatus = setEnumFormat('ITEM_AVAILABILITY_STATUS', dataForm.availabilityStatus);
        }

        if (dataForm.type) {
            dataForm.type = setEnumFormat('ITEM_TYPE', dataForm.type);
            checkIsService(dataForm.type);
        }

        if (dataForm.fileId) {
            let files = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: dataForm.fileId,
                    url:
                        CONSTANTS_REQ.FILES.DOWNLOAD +
                        '/' +
                        dataForm.fileId +
                        '?lastmod=' +
                        new Date().valueOf() +
                        '&tenant=' +
                        NySession.getUser().tenantId,
                },
            ];
            setFileList(files);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        objectMetadataForm.resetFields();
        setMetadata([]);
        setItemGroup(undefined);
        setEditHeader(geti18nText('item.edit.new'));
        setIsService(false);
        setFileList([]);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'itemGroup.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.METADATA, {
            max: 100,
            search: filter,
            order: 'id',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setMetadata(result.data.content);
                }
            }
        });
    };

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return (
                    <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        disabled={resolvePermissionByItemType()}
                        onKeyDown={onKeyDownTextAreaCustom}
                    />
                );
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                        disabled={resolvePermissionByItemType()}
                    />
                );
            case 'BOOL':
                return <Checkbox disabled={resolvePermissionByItemType()} />;
            case 'DATE':
                return (
                    <NyDatePicker
                        format={item.pattern}
                        style={{ width: '100%' }}
                        disabled={resolvePermissionByItemType()}
                    />
                );
            default:
                return <Input disabled={resolvePermissionByItemType()} />;
        }
    };

    const onTypeChange = (value: any) => {
        if (value && value.id > 0) {
            fetchMetadata(value.id);
        }
    };

    const setDefaultFilterValueSearch = () => {
        if (props.addedData.itemGroupType) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: props.addedData.itemGroupType },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ITEM.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            setIsCreate={setIsCreate}
            checkIsFormChanged={false}
            customFooterContent={getCustomFooterContent}
            onSaveAndGetID={(id: any) => {
                if (fileUploadRef?.current) {
                    fileUploadRef.current.filesUpload(id);
                }
            }}
            {...props}
            normalize={(values: any) => {
                const metadataValues = objectMetadataForm.getFieldsValue();
                let metadataReturn = [];

                for (const [key, value] of Object.entries(metadataValues)) {
                    if (key != undefined && value != undefined) {
                        const metadataItem: any = metadata.find(
                            (item: any) => item.objectMetadata && item.objectMetadata.name === key
                        );
                        if (metadataItem.objectMetadata.dataType === 4) {
                            metadataReturn.push({
                                item_group_metadata: { id: metadataItem.id },
                                value: getDateFormat(value, 'yyyy-MM-DD HH:mm:ss'),
                            });
                        } else {
                            metadataReturn.push({ item_group_metadata: { id: metadataItem.id }, value: value });
                        }
                    }
                }
                if (fileList.length > 0 && fileList[0]) {
                    values.fileId = fileList[0].id;
                }

                values.itemMetadata = metadataReturn;

                if (props.addedData.type != 0) values.type = props.addedData.type;

                if (values.availabilityStatus) values.availabilityStatus = getEnumFormat(values.availabilityStatus);
                if (values.type) values.type = getEnumFormat(values.type);

                if (values.secondMeasureUnit) {
                    values.secondMeasureUnit = getSearchFormat(values.secondMeasureUnit);
                    if (values.secondMeasureUnit.id < 1) {
                        delete values.secondMeasureUnit;
                    }
                }
                if (values.measureUnit) {
                    values.measureUnit = getSearchFormat(values.measureUnit);
                    if (values.measureUnit.id < 1) {
                        delete values.measureUnit;
                    }
                }

                return values;
            }}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel
                            header={geti18nText('item.tab.1')}
                            key="1"
                            extra={
                                <NyNoteModal
                                    note={note}
                                    modalTitle={
                                        geti18nText('item.edit.title') + ' - ' + geti18nText('app.default.note')
                                    }
                                />
                            }
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <NyImageUpload
                                        documentType={documentTypeEnum.ITEM_IMAGE}
                                        files={fileList}
                                        setFiles={setFileList}
                                        ref={fileUploadRef}
                                        saveOnUpload
                                    />
                                </Col>
                                <Col span={12}>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item name="id" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <ItemGroupSearch
                                                label={geti18nText('item.edit.itemGroup')}
                                                required
                                                setDefaultFilterValue={setDefaultFilterValueSearch}
                                                type={props.addedData.itemGroupType ? props.addedData.itemGroupType : 0}
                                                AddNewModalComponent
                                                AddNewModalComponentAddedData={{
                                                    type: props.addedData.itemGroupType
                                                        ? props.addedData.itemGroupType
                                                        : 0,
                                                }}
                                                onChange={onTypeChange}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('item.edit.code')}
                                                name="code"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: true,
                                                    },
                                                    {
                                                        validator: validateItemCode,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('item.edit.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('item.edit.shortName')} name="shortName">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('item.edit.description')} name="description">
                                        <Input.TextArea
                                            autoSize={{ minRows: 2, maxRows: 3 }}
                                            onKeyDown={onKeyDownTextAreaCustom}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('item.edit.availabilityStatus')}
                                        name="availabilityStatus"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={setEnumFormat('ITEM_AVAILABILITY_STATUS', 1)}
                                    >
                                        <NySearchField
                                            options={getEnumArray('ITEM_AVAILABILITY_STATUS')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            style={{ width: '100%' }}
                                            className={''}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    {props && props.addedData && props.addedData.type == 0 && (
                                        <Form.Item
                                            label={geti18nText('item.edit.type')}
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                options={getEnumArray('ITEM_TYPE')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                style={{ width: '100%' }}
                                                className={''}
                                                onChange={checkIsService}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col span={8}>
                                    {((props && props.addedData && props.addedData.type == 4) || isAsset) && (
                                        <Form.Item
                                            label={geti18nText('item.edit.mandatoryReceipt')}
                                            name="mandatoryReceipt"
                                            valuePropName={'checked'}
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label={geti18nText('item.edit.measureUnit')} name="measureUnit">
                                        <NySearchField
                                            url={CONSTANTS_REQ.MEASURE_UNITE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            itemName="name"
                                            AddNewModalComponent={MeasureUnitEdit}
                                            SearchPopupComponent={<MeasureUnitIndex />}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('item.edit.secondMeasureUnit')}
                                        name="secondMeasureUnit"
                                    >
                                        <NySearchField
                                            url={CONSTANTS_REQ.MEASURE_UNITE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            itemName="name"
                                            AddNewModalComponent={MeasureUnitEdit}
                                            SearchPopupComponent={<MeasureUnitIndex />}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('item.edit.itemRank')}
                                        name="itemRank"
                                        initialValue={1}
                                    >
                                        <NyInputNumber min={1} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('item.tab.2')} key="2">
                            <Form form={objectMetadataForm} component={false}>
                                <Row gutter={24}>
                                    {metadata &&
                                        metadata.map((item: any) => {
                                            return (
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={item.objectMetadata.name}
                                                        name={item.objectMetadata.name}
                                                        rules={[
                                                            {
                                                                required: item.objectMetadata.mandatory,
                                                                message: geti18nText('app.default.required'),
                                                            },
                                                        ]}
                                                        valuePropName={
                                                            item.objectMetadata.dataType === 3 ? 'checked' : 'value'
                                                        }
                                                    >
                                                        {getDataTypeInput(item)}
                                                    </Form.Item>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ItemEdit;
