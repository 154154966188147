import {
    CheckOutlined,
    EditOutlined,
    HourglassOutlined,
    MobileOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit } from '../../../../../utils/Filters';
import { getEnumArrayStatus } from '../../../../../utils/Utils';
import AssetInventoryEdit from './edit';

const AssetInventoryIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const table = useTableSettings();
    useHelper('asset/inventory');

    const canCreate = () => {
        return AssetRights.canCreateInventory();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const getInventoryAssetStatusIcon = (status: any) => {
        let icon: any = <EditOutlined />;
        let color: any = 'processing';
        status = status ? status : -1;
        switch (status) {
            case -1:
                icon = <EditOutlined />;
                color = 'processing';
                break;
            case 1:
                icon = <HourglassOutlined />;
                color = 'warning';
                break;
            case 2:
                icon = <CheckOutlined />;
                color = 'success';
                break;
        }
        return (
            <Tag icon={icon} color={color} style={{ fontSize: '14px' }}>
                {geti18nText('app.enum.INVENTORY_ASSET_STATUS.' + status)}
            </Tag>
        );
    };

    const columns = [
        {
            title: geti18nText('assetInventory.table.column.id'),
            dataIndex: 'id',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('assetInventory.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('assetInventory.table.column.created'),
            dataIndex: 'created',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.created) {
                    return moment(record.created).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetInventory.table.column.dueDate'),
            dataIndex: 'dueDate',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dueDate) {
                    return moment(record.dueDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('assetInventory.table.column.inventoryAssetStatus'),
            dataIndex: 'inventoryAssetStatus',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.inventoryAssetStatus != undefined) {
                    return getInventoryAssetStatusIcon(record.inventoryAssetStatus);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('INVENTORY_ASSET_STATUS'), 'equals', undefined),
        },
        {
            title: geti18nText('assetInventory.table.column.scanned'),
            dataIndex: 'inventoryAssetRooms',
            width: '7%',
            render: (text: any, record: any) => {
                if (record) {
                    if (record.scannedAssetItem == 0) {
                        return (
                            <Tag color="default">{record.scannedAssetItem + '/' + record.totalInventoryAssetItem}</Tag>
                        );
                    } else if (record.scannedAssetItem < record.totalInventoryAssetItem) {
                        return (
                            <Tag color="orange">{record.scannedAssetItem + '/' + record.totalInventoryAssetItem}</Tag>
                        );
                    } else if (record.scannedAssetItem == record.totalInventoryAssetItem) {
                        return (
                            <Tag color="green">{record.scannedAssetItem + '/' + record.totalInventoryAssetItem}</Tag>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('assetInventory.table.column.note'),
            dataIndex: 'note',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.note) {
                    if (record.note.length > 100) {
                        return <Tooltip title={record.note}>{`${record.note.slice(0, 100)}...`}</Tooltip>;
                    } else {
                        return record.note;
                    }
                }
            },
        },
        {
            title: geti18nText('assetInventory.table.column.assetTypes'),
            dataIndex: 'inventoryAssetTypes',
            width: '7%',
            render: (text: any, record: any) => {
                if (record.inventoryAssetTypes) {
                    return (
                        <Tooltip title={record.inventoryAssetTypes}>
                            <MobileOutlined style={{ color: 'green' }} />
                        </Tooltip>
                    );
                } else {
                    return <MobileOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('assetInventory.table.column.rooms'),
            dataIndex: 'inventoryAssetRooms',
            width: '7%',
            render: (text: any, record: any) => {
                if (record.inventoryAssetRooms) {
                    return (
                        <Tooltip title={record.inventoryAssetRooms}>
                            <UsergroupAddOutlined style={{ color: 'green' }} />
                        </Tooltip>
                    );
                } else {
                    return <UsergroupAddOutlined style={{ color: 'red' }} />;
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="webshop-asset-inventory-items-table"
            fetchWhenChange={refreshTable}
            url={CONSTANTS_REQ.INVENTORY_ASSET.LIST}
            addNewButtonText={geti18nText('assetInventory.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={AssetInventoryEdit}
            editProps={{ setRefreshTable, refreshTable }}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.asset.inventory')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            created: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            dueDate: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
        {
            inventoryAssetStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ASSET_STATUS.' + value);
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetInventory.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('assetInventory.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('assetInventory.table.column.created'),
            dataIndex: 'created',
        },
        {
            title: geti18nText('assetInventory.table.column.dueDate'),
            dataIndex: 'dueDate',
        },
        {
            title: geti18nText('assetInventory.table.column.inventoryAssetStatus'),
            dataIndex: 'inventoryAssetStatus',
        },
        {
            title: geti18nText('assetInventory.table.column.scanned'),
            dataIndex: 'scannedAssetItem',
        },
        {
            title: geti18nText('assetInventory.table.column.scannedTotal'),
            dataIndex: 'totalInventoryAssetItem',
        },
        {
            title: geti18nText('assetInventory.table.column.note'),
            dataIndex: 'note',
        },
        {
            title: geti18nText('assetInventory.table.column.assetTypes'),
            dataIndex: 'inventoryAssetTypes',
        },
        {
            title: geti18nText('assetInventory.table.column.rooms'),
            dataIndex: 'inventoryAssetRooms',
        },
    ];
};

export default AssetInventoryIndex;
