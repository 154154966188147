import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, Collapse, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Address from '../../../../components/address';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import { validateIBAN } from '../../../../utils/Validation';

const { Panel } = Collapse;

const BankEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('bank.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [selectedSettlementId, setSelectedSettlementId] = useState<any>(undefined);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2']);

    const [dataForm, setDataForm] = useState<any>();
    const setSettlementDistrictFilterValue = () => {
        return [{ field: 'settlement.id', condition: 'equals', value: selectedSettlementId }];
    };

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('bank.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.address) {
            if (dataForm.address.settlement) {
                setSelectedSettlementId(dataForm.address.settlement.id);
                dataForm.address.settlement = setSearchFormat(dataForm.address.settlement);
            }
            if (dataForm.address.townshipDistrict) {
                dataForm.address.townshipDistrict = setSearchFormat(dataForm.address.townshipDistrict, 'name', 'name');
            }
            if (dataForm.address.postOffice) {
                dataForm.address.postOffice = {
                    id: dataForm.address.postOffice.id,
                    text:
                        (dataForm.address.postOffice.zipCode ? dataForm.address.postOffice.zipCode : '') +
                        ' ' +
                        dataForm.address.postOffice.name,
                };
            }
        }

        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('bank.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const clearSettlementDistrictField = () => {
        form.setFieldsValue({ address: { settlementDistrict: '' } });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.BANK.EDIT}
            setValues={setValues}
            width={660}
            form={form}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            nyTestId="bank-edit"
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.address?.settlement && values.address?.settlement?.id != -1) {
                    values.address.settlement = getSearchFormat(values.address.settlement);
                } else {
                    delete values.address?.settlement;
                }
                if (!values.address?.street) {
                    delete values.address?.street;
                }
                if (values.address?.settlementDistrict) {
                    values.address.settlementDistrict = getSearchFormat(values.address.settlementDistrict);
                } else {
                    delete values.address?.settlementDistrict;
                }
                if (values.address?.townshipDistrict) {
                    values.address.townshipDistrict = getSearchFormat(values.address.townshipDistrict);
                } else {
                    delete values.address?.townshipDistrict;
                }
                if (values.address?.postOffice) {
                    values.address.postOffice = getSearchFormat(values.address.postOffice);
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Collapse
                        activeKey={collapseActiveKeys}
                        onChange={(key: any) => {
                            setCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('bank.edit.panel.basic')} key={1}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label={geti18nText('bank.edit.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>

                                    <Form.Item
                                        label={geti18nText('bank.edit.iban')}
                                        name="iban"
                                        rules={[
                                            {
                                                validator: validateIBAN,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('bank.edit.bic')} name="bic">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label={geti18nText('bank.table.column.taxNumber')} name="vatNumber">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        {selectedSettlementId ? (
                            <Panel header={geti18nText('bank.edit.panel.address')} key={2}>
                                <Address form={form} settlementId={selectedSettlementId} />
                            </Panel>
                        ) : null}
                    </Collapse>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default BankEdit;
