import { DeleteOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Divider, Empty, Form, Input, Modal, Row, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NyNoteModal from '../../../../components/note-modal';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { RootState } from '../../../../rootReducer';
import { setServicesNote } from '../../../../slices/webshopSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import {
    customEmployeeRenderOption,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    onKeyDownTextAreaCustom,
    setDateFormat,
    userIsNotificationRecipient,
} from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';
import RoomSearch from '../../../human/views/room/search';

const WebshopServicesEdit = (props: any) => {
    const dispatch = useDispatch();
    const { webshopServicesNote } = useSelector((state: RootState) => state.webshop);
    const [editHeader, setEditHeader] = useState(geti18nText('webshopItem.edit.new'));
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [metadataModalVisible, setMetadataModalVisible] = useState(false);
    const [metadata, setMetadata] = useState([]);
    const [itemTitle, setItemTitle] = useState<any>({});
    const [files, setFiles] = useState<any>([]);
    const [form] = Form.useForm();
    const [objectMetadataForm] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);

    const canView = () => {
        return (
            HumanResourcesRights.canViewEmployeeRole() ||
            ServicesRights.canCreateOrder() ||
            userIsNotificationRecipient(employee, 9)
        );
    };

    const disabledEmployee = () => {
        return !isCreate || !canView();
    };

    useEffect(() => {
        if (metadata !== undefined && metadata.length > 0) {
            const metadataForm: any = {};
            if (itemTitle != undefined && itemTitle.servicesOrderItemMetadata != undefined) {
                itemTitle.servicesOrderItemMetadata.map((item: any) => {
                    if (item.item_group_metadata !== undefined) {
                        const findItem: any = metadata.find((row: any) => row.id === item.item_group_metadata.id);
                        if (findItem !== undefined && findItem.objectMetadata !== undefined) {
                            if (findItem.objectMetadata.dataType === 4) {
                                metadataForm[findItem.objectMetadata.name] = setDateFormat(item.value);
                            } else {
                                metadataForm[findItem.objectMetadata.name] = item.value;
                            }
                        }
                    }
                });
            }

            objectMetadataForm.setFieldsValue(metadataForm);
        }
    }, [metadata]);

    const onModalClose = () => {
        dispatch(setServicesNote(form.getFieldValue('note')));
        form.resetFields();
        objectMetadataForm.resetFields();
        setNote(undefined);
        setEditHeader(geti18nText('webshopItem.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (props.employeeId !== undefined) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + props.employeeId, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            form.setFieldsValue({
                                employee: getEmployeeSelectOption(result.data),
                                room: result.data.room,
                            });
                        }
                    }
                }
            );
        }

        if (webshopServicesNote) {
            form.setFieldsValue({ note: webshopServicesNote });
            setNote(webshopServicesNote);
        }
    };

    const removeFromBasket = () => {
        props.setItemsLength(0);
        props.setItems([]);
        removeNote();
    };

    const removeNote = () => {
        setNote(undefined);
        dispatch(setServicesNote(undefined));
        form.setFieldsValue({ note: null });
    };

    const fetchMetadata = (id: any) => {
        let filter = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'itemGroup.id', condition: 'equals', value: id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM_GROUP.METADATA, {
            max: 100,
            order: 'id',
            orderType: 'asc',
            search: filter,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setMetadata(result.data.content);
                }
            }
        });
    };

    const deleteRow = (record: any) => {
        if (record.tempId >= 0) {
            const index = props.items.findIndex((item: any) => record.tempId === item.tempId);
            if (index > -1) {
                props.setItemsLength((itemsLength: any) => itemsLength - props.items[index].quantity);
            }
            props.setItems(props.items.filter((row: any) => row.tempId !== record.tempId));
        }
    };

    const save = async () => {
        objectMetadataForm
            .validateFields()
            .then((metadataValues: any) => {
                try {
                    const newData = [...props.items];
                    const index = newData.findIndex((item) => itemTitle.id === item.id);
                    let metadataReturn = [];

                    for (const [key, value] of Object.entries(metadataValues)) {
                        const metadataItem: any = metadata.find(
                            (item: any) => item.objectMetadata && item.objectMetadata.name === key
                        );
                        if (metadataItem.objectMetadata.dataType === 4) {
                            metadataReturn.push({
                                item_group_metadata: { id: metadataItem.id },
                                value: getDateFormat(value, 'yyyy-MM-DD HH:mm:ss'),
                            });
                        } else {
                            metadataReturn.push({ item_group_metadata: { id: metadataItem.id }, value: value });
                        }
                    }

                    if (index > -1) {
                        newData.splice(index, 1, {
                            ...itemTitle,
                            servicesOrderItemMetadata: metadataReturn,
                        });
                        props.setItems(newData);
                    }

                    setMetadataModalVisible(false);
                    objectMetadataForm.resetFields();
                    props.setTempId(0);
                } catch (errInfo) {
                    console.log('Validate Failed:', errInfo);
                }
            })
            .catch((errorInfo: any) => {
                console.log('Validate Failed:', errorInfo);
            });
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {isCreate && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={!isCreate} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <NyModalConfirm
                                title={geti18nText('app.default.delete.confirm')}
                                onConfirm={() => {
                                    deleteRow(record);
                                }}
                            >
                                <Button type="link" disabled={!isCreate}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </NyModalConfirm>
                        </Tooltip>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        {
            title: geti18nText('webshopItem.edit.code'),
            dataIndex: 'code',
            editable: false,
        },
        {
            title: geti18nText('webshopItem.edit.name'),
            dataIndex: 'name',
            editable: false,
            inputType: 'name',
        },
        {
            title: geti18nText('webshopItem.edit.measureUnit'),
            dataIndex: ['measureUnit', 'abbreviation'],
            editable: false,
        },
        {
            title: geti18nText('webshopItem.edit.quantity'),
            dataIndex: 'quantity',
            editable: true,
            inputType: 'quantity',
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            render: (text: any, record: any) => {
                if (props.items.length >= 1) {
                    return <ActionNotEditable text={text} record={record} />;
                }
            },
        },
    ];

    const edit = (record: any) => {
        if (record != undefined && record.itemGroup != undefined) {
            fetchMetadata(record.itemGroup.id);
            setItemTitle(record);
            setMetadataModalVisible(true);
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px' }}>
                <NyNoteModal note={note} />
            </div>
            {props.items.length > 0 && (
                <NyModalConfirm
                    title={geti18nText('app.default.destructive.confirm')}
                    onConfirm={() => {
                        removeFromBasket();
                    }}
                >
                    <Button danger icon={<DeleteOutlined />} style={{ marginRight: '10px' }}>
                        {geti18nText('app.default.webshop.deleteFromBasket')}
                    </Button>
                </NyModalConfirm>
            )}
        </React.Fragment>
    );

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            props.setEmployeeId(value.id);
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            if (result.data.room) {
                                form.setFieldsValue({ room: result.data.room });
                            }
                        }
                    }
                }
            );
        } else {
            form.setFieldsValue({ employee: undefined });
        }
    };

    const getDataTypeInput = (data: any) => {
        let item = { ...data };
        let enumDataType = GetEnumNameForValue({
            enumName: 'OBJECT_METADATA_TYPE',
            value: item.objectMetadata.dataType,
        });

        switch (enumDataType) {
            case 'TEXT':
                return <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} onKeyDown={onKeyDownTextAreaCustom} />;
            case 'NUMBER':
                let patternN;
                if (item.objectMetadata.pattern) {
                    patternN = JSON.parse(item.objectMetadata.pattern);
                }
                let min, max, dec;
                if (patternN && patternN.min) min = patternN.min;
                if (patternN && patternN.max) max = patternN.max;
                if (patternN && patternN.dec) dec = patternN.dec;
                return (
                    <NyInputNumber
                        min={min}
                        max={max}
                        decimalPlaces={dec}
                        isDecimal={dec !== undefined}
                        style={{ width: '100%' }}
                    />
                );
            case 'BOOL':
                return <Checkbox />;
            case 'DATE':
                return <NyDatePicker format={item.pattern} style={{ width: '100%' }} />;
            default:
                return <Input />;
        }
    };

    return (
        <React.Fragment>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.SERVICES_ORDER.EDIT}
                width={900}
                hideActivationButtons={!isCreate}
                hideSubmitButton={!isCreate || !(props.items.length > 0)}
                form={form}
                setIsCreate={setIsCreate}
                goBack={() => history.goBack()}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={false}
                customFooterContent={getCustomFooterContent}
                onSaveAndGetID={(id: any) => {
                    removeNote();
                    if (uploadFilesRef?.current) {
                        uploadFilesRef.current.filesUpload(id);
                    }
                }}
                normalize={(values: any) => {
                    values.employee = getSearchFormat(values.employee);
                    values.date = getDateFormat(moment());

                    if (values.status) {
                        values.status = 1;
                    } else {
                        delete values.status;
                    }

                    const itemsBasket: any = [];
                    if (props.items != undefined) {
                        props.items.map((item: any) => {
                            let obj: any = {};
                            obj.item = { id: item.id };
                            obj.quantity = item.quantity;
                            obj.servicesOrderItemMetadata = item.servicesOrderItemMetadata;
                            itemsBasket.push(obj);
                        });
                    }

                    values.servicesOrderItems = itemsBasket;
                    if (props.orderViewId != null) values.orderView = { id: props.orderViewId };
                    return values;
                }}
                submitButtonText={geti18nText('webshopItem.edit.save')}
                showSubmitButtonPopconfirm={true}
                submitButtonPopconfirmTitle={geti18nText('webshopItem.edit.save.title')}
            >
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('webshopItem.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={
                                    <EmployeeIndex disabled={true} parentKey="webshop-services-edit" />
                                }
                                disabled={disabledEmployee()}
                                onChange={onEmployeeChange}
                                order="person.last_name, person.first_name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <RoomSearch
                            disabled={!isCreate}
                            label={geti18nText('webshopItem.edit.room')}
                            map={{ id: 'id', label: 'name' }}
                            name="room"
                            required={true}
                            searchBy="name"
                            onChange={(value: any) => {
                                if (value?.id === -1) {
                                    form.setFieldsValue({ room: undefined });
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'status'}
                            valuePropName={'checked'}
                            initialValue={true}
                            style={{ marginBottom: '0px' }}
                        >
                            <Checkbox>{geti18nText('webshopItem.edit.status')}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>{geti18nText('inventoryOrder.divider.1')}</Divider>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            size={'small'}
                            dataSource={props.items}
                            scroll={{ y: 680, x: 800 }}
                            columns={columns}
                            rowClassName="editable-row"
                        />
                    </Col>
                </Row>
                <NyFilesUpload
                    documentType={documentTypeEnum.SERVICES_ORDER}
                    url={CONSTANTS_REQ.SERVICES_ORDER_FILE.LIST}
                    editUrl={CONSTANTS_REQ.SERVICES_ORDER_FILE.EDIT}
                    ref={uploadFilesRef}
                    employee={employee}
                    maxHeight={'205px'}
                />
            </NyDataEdit>
            {metadataModalVisible && (
                <Modal
                    title={itemTitle && itemTitle.name}
                    visible={metadataModalVisible}
                    maskClosable={false}
                    okText={geti18nText('app.default.button.save')}
                    onOk={save}
                    onCancel={() => {
                        setMetadataModalVisible(false);
                        objectMetadataForm.resetFields();
                    }}
                >
                    <Form layout="vertical" form={objectMetadataForm}>
                        <Row gutter={24}>
                            {metadata &&
                                metadata.length >= 1 &&
                                metadata.map((item: any) => {
                                    return (
                                        <Col span={24}>
                                            <Form.Item
                                                label={item.objectMetadata.name}
                                                name={item.objectMetadata.name}
                                                rules={[
                                                    {
                                                        required: item.objectMetadata.mandatory,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                                valuePropName={item.objectMetadata.dataType === 3 ? 'checked' : 'value'}
                                            >
                                                {getDataTypeInput(item)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            {(!metadata || metadata.length === 0) && (
                                <Empty style={{ margin: 'auto' }} description={geti18nText('objectMetadata.empty')} />
                            )}
                        </Row>
                    </Form>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default WebshopServicesEdit;
