import { UploadOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, message, Modal, Row, Upload } from 'antd';
import { useState } from 'react';
import { FILE_SIZE_5_MB } from '../../utils/Constants';
import { errorNodeNotification, infoNotification, warningNotification } from '../../utils/Utils';
import NyErrorLogModal from '../error-log-modal';

const ImportCSVModal = ({
    url,
    modalTitle = geti18nText('import.csv.title'),
    onSave,
    data,
    maxFiles = 1,
    accept = '.csv',
    disabled = false,
    customInfoMessage = undefined,
    showUpdate = true,
    showSaveToNotBooked = false,
}: any) => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [filesList, setFilesList] = useState<any>([]);
    const [errorLogModalData, setErrorLogModalData] = useState(undefined);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');

    const onModalClose = () => {
        form.resetFields();
        setFilesList([]);
        setModalVisible(false);
    };

    const save = async () => {
        if (filesList && filesList[0]) {
            setLoading(true);
            let file = filesList[0];
            if (!customInfoMessage) {
                message.loading(`${file.name} ${geti18nText('import.text.uploading')}`);
            } else {
                infoNotification(customInfoMessage);
            }
            const formData = data ?? new FormData();
            showUpdate && formData.append('update', form.getFieldValue(['update']));
            showSaveToNotBooked && formData.append('saveToNotBooked', form.getFieldValue(['saveToNotBooked']));
            formData.append('file', file);

            const result: any = await NyRequestResolver.requestPostFile(url, undefined, formData);
            message.destroy();
            if (result.status === RESPONSE.CREATED) {
                setLoading(false);
                message.destroy();
                if (!customInfoMessage) {
                    message.success(`${file.name} ${geti18nText('import.text.done')}`);
                }
                setFilesList([]);
                onModalClose();
                if (onSave) {
                    onSave();
                }
            } else {
                setLoading(false);
                message.destroy();
                if (result.status === RESPONSE.INTERNAL_SERVER_ERROR && result.data) {
                    errorNodeNotification(
                        <Button
                            type="link"
                            onClick={() => {
                                setErrorLogModalData(result.data);
                                setErrorLogModalVisible(true);
                                setErrorLogModalTitle(geti18nText('asset.import.details.modal'));
                            }}
                        >
                            {geti18nText('asset.import.error.details')}
                        </Button>,
                        'import.text.error'
                    );
                } else {
                    if (!customInfoMessage) {
                        message.error(`${file.name} ${geti18nText('import.text.error')}`);
                    }
                }
            }
        } else {
            warningNotification(geti18nText('upload.file.not.selected'));
        }
    };

    const uploadProps = {
        name: 'file',
        showUploadList: true,
        multiple: false,
        accept: accept,
        className: 'ny-upload',
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onRemove: (file: any) => {
            setFilesList((files: any) => {
                const index = files.indexOf(file);
                const newFileList = files.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        beforeUpload: (file: any) => {
            if (file && file.size > FILE_SIZE_5_MB) {
                warningNotification(geti18nText('upload.file.size.too.big'));
            } else {
                setFilesList([...filesList, file]);
            }
            return false;
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                if (!customInfoMessage) {
                    message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                }
                onSave();
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                if (info.file.response) {
                    errorNodeNotification(
                        <Button
                            type="link"
                            onClick={() => {
                                setErrorLogModalData(info.file.response);
                                setErrorLogModalVisible(true);
                                setErrorLogModalVisible(geti18nText('asset.import.details.modal'));
                            }}
                        >
                            {geti18nText('asset.import.error.details')}
                        </Button>,
                        'import.text.error'
                    );
                }
                message.error(`${info.file.name}`);
            }
        },
        filesList,
    };

    const uploadButton = <Button icon={<UploadOutlined />}>{geti18nText('webshopServices.addFile')}</Button>;

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Button
                block
                icon={<UploadOutlined />}
                disabled={loading}
                style={{ width: '100%' }}
                onClick={() => setModalVisible(true)}
            >
                {geti18nText('import.csv.title')}
            </Button>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={modalTitle}
                    onOk={save}
                    onCancel={onModalClose}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    okText={geti18nText('import.csv.ok')}
                    confirmLoading={loading}
                >
                    <Form
                        form={form}
                        component={false}
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('import.csv.file.name')} name="file">
                                    <div style={{ paddingTop: '10px' }}>
                                        <Upload
                                            listType="text"
                                            fileList={filesList}
                                            {...uploadProps}
                                            disabled={disabled}
                                        >
                                            {filesList && filesList.length >= maxFiles ? null : uploadButton}
                                        </Upload>
                                    </div>
                                </Form.Item>
                            </Col>
                            {showUpdate && showSaveToNotBooked ? (
                                <Col span={12}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="update"
                                            valuePropName={'checked'}
                                            initialValue={true}
                                            style={{ marginBottom: '0px' }}
                                        >
                                            <Checkbox>{geti18nText('import.csv.update')}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="saveToNotBooked"
                                            valuePropName={'checked'}
                                            initialValue={true}
                                            style={{ marginBottom: '0px' }}
                                        >
                                            <Checkbox>{geti18nText('import.csv.saveToNotBooked')}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Col>
                            ) : showUpdate ? (
                                <Col span={12}>
                                    <Form.Item
                                        name="update"
                                        valuePropName={'checked'}
                                        initialValue={true}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        <Checkbox>{geti18nText('import.csv.update')}</Checkbox>
                                    </Form.Item>
                                </Col>
                            ) : showSaveToNotBooked ? (
                                <Col span={12}>
                                    <Form.Item
                                        name="saveToNotBooked"
                                        valuePropName={'checked'}
                                        initialValue={true}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        <Checkbox>{geti18nText('import.csv.saveToNotBooked')}</Checkbox>
                                    </Form.Item>
                                </Col>
                            ) : (
                                <></>
                            )}
                        </Row>
                    </Form>
                </Modal>
            )}
            {errorLogModalVisible && (
                <NyErrorLogModal
                    modalVisible={errorLogModalVisible}
                    setModalVisible={setErrorLogModalVisible}
                    title={errorLogModalTitle}
                    errorData={errorLogModalData}
                    setErrorData={setErrorLogModalData}
                />
            )}
        </div>
    );
};

export default ImportCSVModal;
