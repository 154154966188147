import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NyUtils, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import {
    getColorFromNotificationType,
    getEnumArray,
    getEnumBooleanArray,
    numberFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import SickLeaveEdit from './edit';

const SickLeaveIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();
    useHelper('human/codebooks/sick_leave');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportEmployeeCodebooks();
    };

    const columns = [
        {
            title: geti18nText('sickLeave.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('sickLeave.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('sickLeave.table.column.remittance'),
            dataIndex: 'remittance',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('sickLeave.table.column.regosCode'),
            dataIndex: 'regosCode',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('sickLeave.table.column.joppdCode'),
            dataIndex: 'joppdCode',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('sickLeave.table.column.paidPercent'),
            dataIndex: 'paidPercent',
            align: 'right',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (paidPercent: any) => {
                return <div style={{ float: 'right' }}>{numberFormat(paidPercent)}</div>;
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('sickLeave.table.column.founded'),
            dataIndex: 'founded',
            width: '12%',
            render: (text: any, record: { founded: any }) => {
                if (record.founded) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('sickLeave.table.column.foundationType'),
            dataIndex: 'foundationType',
            sorter: (a: any, b: any) => {},
            render: (foundationType: any) => {
                if (foundationType) {
                    let type: any = setEnumFormat('FOUNDATION_TYPE', foundationType);
                    return type ? type.text : '';
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('FOUNDATION_TYPE'), 'in', undefined),
        },
        {
            title: geti18nText('sickLeave.table.column.active'),
            dataIndex: 'active',
            width: '6%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(
                ENUMS.ACTIVE(),
                'equals_bool',
                undefined,
                undefined,
                undefined,
                'sick-leave-active'
            ),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-sick-leave-table"
            url={CONSTANTS_REQ.SICK_LEAVE.LIST}
            addNewButtonText={geti18nText('sickLeave.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={SickLeaveEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            nyTestId="sick-leave-data-table"
            selectOnSave={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.sickLeave')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.sickLeave')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            paidPercent: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            founded: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            foundationType: (value: any) => {
                let type: any = setEnumFormat('FOUNDATION_TYPE', value);
                return type !== undefined && type.text !== undefined && type.text;
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default SickLeaveIndex;
