import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArray } from '../../../../utils/Utils';
import OrderViewEdit from './edit';

const OrderViewIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    notificationType = undefined,
    orderViewType = undefined,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    useHelper('common/orderView');

    const canCreate = () => {
        if (AdministrationRights.isAdmin()) {
            return true;
        } else if (orderViewType == 1) {
            return AssetRights.canCreateSettings();
        } else if (orderViewType == 2) {
            return InventoryRights.canCreateSettings();
        } else if (orderViewType == 3) {
            return ServicesRights.canCreateSettings();
        } else {
            return AdministrationRights.canCreateOrderView();
        }
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportOrderViewCodebook();
    };

    const setDefaultFilterValue = () => {
        let filter = [{ field: 'active', condition: 'equals_bool', value: 1 }];
        if (notificationType == 7) {
            filter.push({ field: 'type', condition: 'equals', value: 2 });
        } else if (notificationType == 8) {
            filter.push({ field: 'type', condition: 'equals', value: 1 });
        } else if (notificationType == 9) {
            filter.push({ field: 'type', condition: 'equals', value: 3 });
        }
        return filter;
    };

    const columns = [
        ...[
            {
                title: geti18nText('orderView.table.column.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('orderView.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(orderViewType == undefined
            ? [
                  {
                      title: geti18nText('orderView.table.column.type'),
                      dataIndex: 'type',
                      render: (text: any, record: any) => {
                          if (record.type !== undefined) {
                              return geti18nText('app.enum.ORDER_VIEW_TYPE.' + record.type);
                          }
                      },
                      ...getColumnSearchCheckbox(getEnumArray('ORDER_VIEW_TYPE'), 'in', undefined),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('orderView.table.column.active'),
                dataIndex: 'active',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: setDefaultFilterValue,
            },
        ],
    ];

    const getListUrl = () => {
        let url = '';
        if (orderViewType == 1) {
            url = CONSTANTS_REQ.ORDER_VIEW.LIST_ASSET;
        } else if (orderViewType == 2) {
            url = CONSTANTS_REQ.ORDER_VIEW.LIST_INVENTORY;
        } else if (orderViewType == 3) {
            url = CONSTANTS_REQ.ORDER_VIEW.LIST_SERVICES;
        } else {
            url = CONSTANTS_REQ.ORDER_VIEW.LIST;
        }
        return url;
    };

    return (
        <NyDataTable
            nyId="order-view-table"
            url={getListUrl()}
            addNewButtonText={geti18nText('orderView.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={OrderViewEdit}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            fetchWhenChange={notificationType}
            shortcuts={true}
            hideNewButton={!canCreate()}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.order.view')}
            headerTitle={geti18nText('menu.order.view')}
            colCSVCustomization={csvColumnCustomisation()}
            editProps={{
                orderViewType: orderViewType,
            }}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ORDER_VIEW_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default OrderViewIndex;
