import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchByCodebook,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Empty, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import {
    fileDownload,
    getCanCreateContract,
    getContractStatusIcon,
    getEnumArrayStatus,
    getUrlContract,
} from '../../../../../utils/Utils';
import ContractCTREdit from './edit';
import { useLocation } from 'react-router-dom';

const ContractsCTRIndex = ({
    defaultFilterValue = undefined,
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    showHeader = true,
    annex = false,
    contractId,
    documentTemplateFromContractType = undefined,
    contractType = undefined,
    employeeId = undefined,
    mark = 'HR',
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    contractMetadataEmployee = undefined,
    hideNewButton = false,
}: any) => {
    const location: any = useLocation();
    const table = useTableSettings();
    const [refresh, setRefresh] = useState<any>(0);
    const enContractStatus = useEnum('CONTRACT_STATUS');
    const [url, setUrl] = useState<any>(undefined);
    const [canCreate, setCanCreate] = useState(false);
    const { employee } = useSelector((state: RootState) => state.employee);

    useEffect(() => {
        if (employeeId && employee?.id && employee?.id == employeeId) {
            setUrl(CONSTANTS_REQ.CONTRACT_CTR.MY_CONTRACTS);
        } else {
            getUrl();
        }
    }, [mark]);

    const getUrl = async () => {
        getUrlContract(setUrl, mark, CONSTANTS_REQ.CONTRACT_CTR.LIST, CONSTANTS_REQ.CONTRACT_CTR.LIST_MARK);
    };

    useEffect(() => {
        getCanCreate();
    }, []);

    const getCanCreate = async () => {
        getCanCreateContract(setCanCreate, mark, 'HUMAN_RESOURCES');
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const setDefaultFilterValue = () => {
        if (location?.state?.contractType) {
            return [
                { field: 'contract_type_id', condition: 'equals', value: location?.state?.contractType?.id },
                { field: 'annex', condition: 'equals_bool', value: 0 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        } else if (defaultFilterValue) {
            return defaultFilterValue;
        } else {
            return [
                { field: 'annex', condition: 'equals_bool', value: 0 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('contractsCTR.table.column.id'),
                dataIndex: 'id',
                width: '5%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('contractsCTR.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(!employeeId && !annex
            ? [
                  {
                      title: geti18nText('contractsCTR.table.column.employee'),
                      dataIndex: [`concat(person.first_name,' ', person.last_name)`],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebookEmployee(),
                      render: (text: any, record: any) => {
                          if (record.employee?.person) {
                              return (
                                  <div>
                                      {record.employee.person?.firstName +
                                          ' ' +
                                          record.employee.person?.lastName +
                                          (record.employee.employmentRecordId
                                              ? ' (' + record.employee.employmentRecordId + ')'
                                              : '')}
                                  </div>
                              );
                          }
                      },
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('contractsCTR.table.column.contractNumber'),
                dataIndex: 'contractNumber',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(!contractType?.id
            ? [
                  {
                      title: geti18nText('contractsCTR.table.column.contractType'),
                      dataIndex: ['contractType', 'name'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebook(
                          CONSTANTS_REQ.CONTRACT_TYPE.SEARCH,
                          'contractType.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          },
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          table.setDefaultFilterValue
                      ),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('contractsCTR.table.column.dateOfConclusion'),
                dataIndex: 'dateOfConclusion',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(),
                render: (text: any, record: any) => {
                    if (record.dateOfConclusion) {
                        return moment(record.dateOfConclusion).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('contractsCTR.table.column.validFrom'),
                dataIndex: 'validFrom',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(),
                render: (text: any, record: any) => {
                    if (record.validFrom) {
                        return moment(record.validFrom).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('contractsCTR.table.column.validTo'),
                dataIndex: 'validTo',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(),
                render: (text: any, record: any) => {
                    if (record.validTo) {
                        return moment(record.validTo).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('contractsCTR.table.column.status'),
                dataIndex: 'status',
                width: '180px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchCheckbox(getEnumArrayStatus('CONTRACT_STATUS'), 'in', undefined),
                render: (text: any, record: any) => {
                    if (record.status) {
                        return getContractStatusIcon(record.status);
                    }
                },
            },
            {
                title: geti18nText('contractsCTR.table.column.active'),
                dataIndex: 'active',
                width: '7%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(),
            },
            {
                title: geti18nText('app.default.actions'),
                dataIndex: 'actions',
                width: '8%',
                render: (text: any, record: any) => {
                    let uploadedFileId = record.fileId ?? '';
                    let uploadedFileName = record?.fileName ?? '';
                    if (record?.contractDocuments) {
                        uploadedFileId =
                            record?.contractDocuments &&
                            record.contractDocuments.substring(0, record.contractDocuments.indexOf(' '));
                        uploadedFileName =
                            record?.contractDocuments &&
                            record.contractDocuments.slice(record.contractDocuments.indexOf('|') + 2);
                    }
                    if (uploadedFileId && record?.status == enContractStatus.SIGNED) {
                        return (
                            <Tooltip title={geti18nText('contract.edit.contract.signed')}>
                                <Button
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + uploadedFileId,
                                            null,
                                            uploadedFileName ?? geti18nText('contract.edit.contract.signed')
                                        );
                                    }}
                                >
                                    {geti18nText('app.default.download')}
                                </Button>
                            </Tooltip>
                        );
                    }
                },
            },
        ],
    ];

    const addedButtons = () => (
        <>
            {location?.state?.contractType && (
                <Button
                    onClick={() => {
                        delete location.state;
                        setRefresh((refresh: any) => refresh + 1);
                    }}
                    style={{ float: 'left' }}
                >
                    {geti18nText('contract.type.table.header.contract.list')}
                </Button>
            )}
        </>
    );

    return (
        <>
            {url ? (
                <NyDataTable
                    nyId={'human-contract-contract-ctr-table-' + (contractId > 0 ? contractId.toString() : 'index')}
                    url={url}
                    addNewButtonText={
                        annex ? geti18nText('contract.type.table.add.annex') : geti18nText('contractsCTR.table.add')
                    }
                    headerTitle={
                        showHeader && location?.state?.contractType
                            ? geti18nText('contract.type.table.header.contractType') +
                              ': ' +
                              location?.state?.contractType?.name
                            : !annex
                            ? geti18nText('menu.contractsCTR')
                            : null
                    }
                    buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                    showRecordModal={true}
                    modalComponent={ContractCTREdit}
                    editProps={{
                        annex: annex,
                        contractId: contractId,
                        refresh: refresh,
                        setRefresh: setRefresh,
                        documentTemplateFromContractType: documentTemplateFromContractType,
                        contractType: contractType
                            ? contractType
                            : location?.state?.contractType
                            ? location?.state?.contractType
                            : null,
                        employeeId: employeeId,
                        mark: mark,
                        contractMetadataEmployee: contractMetadataEmployee,
                    }}
                    fetchWhenChange={refresh}
                    columns={columns}
                    showRowSelection={showRowSelection}
                    rowSelectionType={rowSelectionType}
                    rowSelectionModal={rowSelectionModal}
                    setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    setDefaultSortOrder={sortOrder ?? { order: 'validTo', orderType: 'desc' }}
                    scroll={scroll}
                    hideButtons={disabled}
                    readonly={disabled}
                    hideNewButton={!canCreate || hideNewButton}
                    shortcuts={true}
                    exportCSV={canExportCSV()}
                    CSVFileName={
                        annex ? geti18nText('contract.type.table.add.annex') : geti18nText('menu.contractsCTR')
                    }
                    colCSVCustomization={csvColumnCustomisation()}
                    csvColumns={csvColumns()}
                    addedButtons={addedButtons}
                />
            ) : (
                <Empty description={geti18nText('contractsCTR.noAccessRight.message')} />
            )}
        </>
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contractsCTR.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contractsCTR.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('contractsCTR.table.column.employee'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('contractsCTR.table.column.contractNumber'),
            dataIndex: 'contractNumber',
        },
        {
            title: geti18nText('contractsCTR.table.column.contractType'),
            dataIndex: ['contractType', 'name'],
        },
        {
            title: geti18nText('contractsCTR.table.column.dateOfConclusion'),
            dataIndex: 'dateOfConclusion',
        },
        {
            title: geti18nText('contractsCTR.table.column.validFrom'),
            dataIndex: 'validFrom',
        },
        {
            title: geti18nText('contractsCTR.table.column.validTo'),
            dataIndex: 'validTo',
        },
        {
            title: geti18nText('contractsCTR.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('contractsCTR.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            employee: (value: any) => {
                return value?.person ? value?.person?.firstName + ' ' + value?.person?.lastName : '';
            },
        },
        {
            dateOfConclusion: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validFrom: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            validTo: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.CONTRACT_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default ContractsCTRIndex;
