import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customMealRenderOption,
    getDateFormat,
    getMealPriceWithSubvention,
    getSearchFormat,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import MealEdit from './edit';
import CurrencySearch from '../../../../administration/views/currency/search';
import MealIndex from '..';
import { MealsRights } from '../../../../../rights/mealsRights';

const PriceListEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('price.list.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [isCreate, setIsCreate] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [disabledMeal, setDisabledMeal] = useState<boolean>(false);
    const [initialCurrency, setInitialCurrency] = useState<any>(false);
    const [dateFrom, setDateFrom] = useState<any>(moment());
    const [dateTo, setDateTo] = useState<any>(undefined);

    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const appSettingMealSubvention = useApplicationSetting('MEALS_SUBVENTION');
    const [allowedDiscount, setAllowedDiscount] = useState<any>(null);
    const [price, setPrice] = useState<any>(null);
    const [priceWithSubvention, setPriceWithSubvention] = useState<any>(null);
    const now = moment().format('DD.MM.YYYY');

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 1 },
            { field: 'settingGroup', condition: 'equals', value: 'COMPANY_INFO' },
        ];
    };

    useEffect(() => {
        if (props && props.editProps && props.editProps.mealId && props.editProps.mealName) {
            let meal: any = { id: props.editProps.mealId, name: props.editProps.mealName };
            meal = setSearchFormat(meal, 'name', 'name');
            form.setFieldsValue({ meal: meal });
            setDisabledMeal(true);
        }
    }, [props]);

    useEffect(() => {
        if (props && props.editProps && props.editProps.allowedDiscount) {
            setAllowedDiscount(props.editProps.allowedDiscount);
        }
    }, [props]);

    useEffect(() => {
        if (mealSyncTypeHNB) {
            let priceSubventionValue: any = 0;
            if (
                (dateTo &&
                    moment(getDateFormat(dateTo, 'DD.MM.YYYY'), 'DD.MM.YYYY').isSameOrAfter(
                        moment(now, 'DD.MM.YYYY')
                    )) ||
                !dateTo
            ) {
                priceSubventionValue = getMealPriceWithSubvention(
                    appSettingMealSubvention ?? 0,
                    allowedDiscount ?? 0,
                    price ?? 0,
                    false
                );
            }
            setPriceWithSubvention(priceSubventionValue);
            form.setFieldsValue({ priceWithSubvention: priceSubventionValue });
        }
    }, [appSettingMealSubvention, allowedDiscount, dateTo, price]);

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
            setDateFrom(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
            setDateTo(dataForm.validTo);
        }
        if (dataForm.meal) {
            setEditHeader(geti18nText('price.list.edit.title') + ' - ' + dataForm.meal.name);
            setAllowedDiscount(dataForm.meal.allowedDiscount);
            dataForm.meal = setSearchFormat(dataForm.meal, 'name', 'name');
        }
        if (dataForm.price) {
            setPrice(dataForm.price);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDisabledMeal(false);
        setEditHeader(geti18nText('price.list.edit.new'));
        setAllowedDiscount(null);
        setPriceWithSubvention(null);
        setPrice(null);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setAllowedDiscount(allowedDiscount);
        getAppSettings();
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_CURRENCY_ISO_CODE') {
                                getCurrencyIsoCode(element.value ? element.value : element.defaultValue);
                            }
                        });
                    }
                }
            }
        );
    };

    async function getCurrencyIsoCode(code: any) {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.ISO_CODE, { code }).then((result: any) => {
            if (result.data.id) {
                setInitialCurrency(setSearchFormat(result.data, 'isoCode'));
                form.setFieldsValue({ currency: setSearchFormat(result.data, 'isoCode') });
            } else {
                setInitialCurrency(setSearchFormat({ id: 0, text: code }));
                form.setFieldsValue({ currency: setSearchFormat({ id: 0, text: code }) });
            }
        });
    }

    const disabledDateTo = (current: any) => {
        if (dateFrom && current) {
            return current.isBefore(dateFrom.clone());
        } else {
            return current;
        }
    };

    const disabledDateFrom = (current: any) => {
        if (dateTo) {
            return current > moment(dateTo);
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.PRICE_LIST.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = moment(values.validFrom).format('yyyy-MM-DD');
                if (values.validTo) values.validTo = moment(values.validTo).format('yyyy-MM-DD');
                if (values.meal) values.meal = getSearchFormat(values.meal);
                if (values.currency) values.currency = getSearchFormat(values.currency);
                delete values.mealId;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('price.list.table.column.meal')}
                        name="meal"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.MEAL.SEARCH}
                            map={{ id: 'id', label: 'name', name: 'name', allowedDiscount: 'allowedDiscount' }}
                            searchBy="name"
                            setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                            SearchPopupComponent={<MealIndex />}
                            AddNewModalComponent={MealEdit}
                            disabled={disabledMeal}
                            renderOption={customMealRenderOption}
                            onChange={(value: any) => {
                                setAllowedDiscount(value?.name?.props?.children[1]?.props?.children ?? 0);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={mealSyncTypeHNB ? 5 : 9}>
                    <Form.Item
                        label={geti18nText('price.list.table.column.price')}
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            isDecimal={true}
                            style={{ width: '100%' }}
                            onChange={(value: any) => setPrice(value)}
                        />
                    </Form.Item>
                </Col>
                {mealSyncTypeHNB && (
                    <Col span={4}>
                        <Form.Item
                            label={geti18nText('price.list.table.column.priceWithSubvention')}
                            name="priceWithSubvention"
                        >
                            <NyInputNumber
                                isDecimal={true}
                                style={{ width: '100%' }}
                                disabled
                                value={priceWithSubvention}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col span={3}>
                    <CurrencySearch
                        label={geti18nText('travelWarrantExpense.edit.isoCode')}
                        name="currency"
                        initialValue={initialCurrency}
                        noClassName={true}
                        search={false}
                        edit={false}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('price.list.table.column.validFrom')}
                        name="validFrom"
                        initialValue={moment()}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            style={{ width: '100%' }}
                            nyTestId="valid-from"
                            onChange={(value: any) => {
                                setDateFrom(moment(value) ? moment(value, 'DD.MM.YYYY') : null);
                                if (dateTo && dateTo.isBefore(moment(value))) {
                                    setDateTo(null);
                                    form.setFieldsValue({ validTo: null });
                                }
                            }}
                            disabledDate={disabledDateFrom}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('price.list.table.column.validTo')} name="validTo">
                        <NyDatePicker
                            style={{ width: '100%' }}
                            nyTestId="valid-to"
                            onChange={(value: any) => {
                                setDateTo(value);
                                if (value && value.isBefore(dateFrom)) {
                                    setDateTo(null);
                                    form.setFieldsValue({ validTo: null });
                                }
                            }}
                            disabledDate={disabledDateTo}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PriceListEdit;
