import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import { PayrollRights } from '../../../../rights/payrollRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import PayrollBrutAdditionEdit from './PayrollBrutAdditionEdit';
import PayrollBrutEdit from './PayrollBrutEdit';
import PayrollBrutTable from './PayrollBrutTable';

const PayrollBrutSearch = ({
    label = geti18nText('pointAdditionDecision.table.column.payrollBrut'),
    name,
    required = false,
    onChange = null,
    mustGetPopupContainer = false,
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    disabled = false,
    addition = false,
}: any) => {
    const canCreate = () => {
        return PayrollRights.canCreatePayrollCodebooks();
    };
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                style={{ width: '100%' }}
                url={CONSTANTS_REQ.PAYROLL_BRUT.SEARCH}
                map={{
                    id: 'id',
                    label: 'name',
                }}
                searchBy="name"
                onChange={onChange}
                mustGetPopupContainer={mustGetPopupContainer}
                setDefaultFilterValue={setDefaultFilterValue}
                SearchPopupComponent={<PayrollBrutTable addition={addition} />}
                AddNewModalComponent={canCreate() ? (addition ? PayrollBrutAdditionEdit : PayrollBrutEdit) : null}
                disabled={disabled}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default PayrollBrutSearch;
