import EmployeeEducationNewWizard from './educationNewWizard';

const AdminEducationWizard = ({
    id = undefined,
    employeeEducation = undefined,
    setEmployeeEducation = null,
    setRefresh = null,
    isPersonalView = false,
    visible,
    setVisible,
    setValue,
    employeeId,
}: any) => {
    return (
        <>
            <EmployeeEducationNewWizard
                id={id}
                employeeEducation={employeeEducation}
                setEmployeeEducation={setEmployeeEducation}
                setRefresh={setRefresh}
                isPersonalView={isPersonalView}
                visible={visible}
                setVisible={setVisible}
                setValue={setValue}
                isAdminForm={true}
                employeeId={employeeId}
            />
        </>
    );
};
export default AdminEducationWizard;
