import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import moment from 'moment';
import RowAction from '../../../../../components/row-action';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import AssetReturnEdit from '../../../../webshop/views/webshop-asset/asset-return/edit';

const AssetRentalReturnIndex = ({
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
    refreshFilters,
    setDefaultFilterValue,
}: any) => {
    const table = useTableSettings();
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const enableAssetRental = useApplicationSetting('ENABLE_ASSET_RENTAL');

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetRent();
    };

    const canExportCSV = () => {
        return FixedAssetRights.canCreateAssetRent();
    };

    const initialColumns = [
        {
            title: geti18nText('assetReturn.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('assetReturn.table.column.ord'),
            dataIndex: 'ord',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.dateReturn) {
                    return record.ord + '/' + moment(record.dateReturn).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('assetReturn.table.column.company'),
            dataIndex: ['company', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.COMPANY.SEARCH, 'company.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
            render: (company: any, record: any) => {
                if (record.company) {
                    if (record.company && record.company.name) {
                        return record.company.name;
                    }
                }
            },
        },
        {
            title: geti18nText('assetReturn.table.column.dateReturn'),
            dataIndex: 'dateReturn',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.dateReturn) {
                    return moment(record.dateReturn).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'Return'} record={record}></RowAction>;
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="return-asset-table"
            url={CONSTANTS_REQ.ASSET_RETURN.LIST}
            addNewButtonText={geti18nText('assetReturn.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={AssetReturnEdit}
            columns={initialColumns}
            scroll={scroll}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            addedData={{ type: enAssetReleaseSpecType.COMPANY }}
            hideNewButton={!canCreate() || enableAssetRental !== '1'}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.asset.return')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            fetchWhenChange={refreshFilters}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            userPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            dateRelease: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('assetReturn.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('assetReturn.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('assetReturn.table.column.company'),
            dataIndex: ['company', 'name'],
        },
        {
            title: geti18nText('assetReturn.table.column.dateReturn'),
            dataIndex: 'dateReturn',
        },
    ];
};

export default AssetRentalReturnIndex;
