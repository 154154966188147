import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../rootReducer';
import { useState } from 'react';
import { NySpinner, geti18nText } from '@nybble/nyreact';
import React from 'react';
import { Empty, Radio } from 'antd';
import MobilizationExecutorAndTheirDeputiesTable from './MobilizationExecutorAndTheirDeputiesTable';
import DriversToCallEmployeesTable from './DriversToCallEmployeesTable';

const SpecialDutyInMobilizationIndex = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { prop } = useSelector((state: RootState) => state.prop);

    const [loading, setLoading] = useState(false);
    const [activeList, setActiveList] = useState<any>(1);
    const [refresh, setRefresh] = useState(0);

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        // dispatch(setProp(e.target.value > 0 ? e.target.value : 1));
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                value={activeList}
                defaultValue={1}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('special.duty.in.mobilization.tab.1')}
                </Radio.Button>
                <Radio.Button value={2} onChange={onChangeActiveList}>
                    {geti18nText('special.duty.in.mobilization.tab.2')}
                </Radio.Button>
            </Radio.Group>

            {activeList == 1 ? (
                <MobilizationExecutorAndTheirDeputiesTable />
            ) : activeList == 2 ? (
                <DriversToCallEmployeesTable />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export default SpecialDutyInMobilizationIndex;
