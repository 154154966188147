import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Badge, Empty, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NyFullCalendar from '../../../../components/ny-full-calendar/NyFullCalendar';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { RootState } from '../../../../rootReducer';
import { setProp } from '../../../../slices/propSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import { userIsNotificationRecipient } from '../../../../utils/Utils';
import TravelWarrantPersonalIndex from './table/TravelWarrantPersonal';
import TravelWarrantTableActions from './table/TravelWarrantTableActions';
import TravelWarrantTableCancelRequests from './table/TravelWarrantTableCancelRequests';
import TravelWarrantTableGeneral from './table/TravelWarrantTableGeneral';
import TravelWarrantTableNotCalculated from './table/TravelWarrantTableNotCalculated';
import TravelWarrantTableUnpaid from './table/TravelWarrantTableUnpaid';
import TravelWarrantTableWaitingForApproval from './table/TravelWarrantTableWaitingForApproval';
import TravelWarrantTableWaitingForOrganiztion from './table/TravelWarrantTableWaitingForOrganiztion';
import TravelWarrantTableWaitingForReportApproval from './table/TravelWarrantTableWaitingForReportApproval';

const TravelWarrantIndex = () => {
    const dispatch = useDispatch();
    const { employee } = useSelector((state: RootState) => state.employee);
    const { prop } = useSelector((state: RootState) => state.prop);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantStatus = useEnum('TRAVEL_WARRANT_STATUS');
    const travelWarrantPayment = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');

    const [loading, setLoading] = useState(false);
    const [activeList, setActiveList] = useState<any>(prop);
    const [refresh, setRefresh] = useState(0);

    const [countWaitingForApproval, setCountWaitingForApproval] = useState(0);
    const [countUnpaid, setCountUnpaid] = useState(0);
    const [countWaitingForOrganiztion, setCountWaitingForOrganiztion] = useState(0);
    const [countWaitingForReportApproval, setCountWaitingForReportApproval] = useState(0);
    const [countNotCalculated, setCountNotCalculated] = useState(0);
    const [countUnapprovedPayments, setCountUnapprovedPayments] = useState(0);
    const [countCancelRequests, setCountCancelRequests] = useState(0);
    const [countUnpaidTrips, setCountUnpaidTrips] = useState(0);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);

    const [notCalculated, setNotCalculated] = useState(0);
    const [reportApproved, setReportApproved] = useState(0);

    document.querySelectorAll('button.fc-today-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridWeek-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridMonth-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-listMonth-button')?.forEach((element) => element.setAttribute('title', ''));
    document
        .querySelectorAll('button.fc-prev-button')
        ?.forEach((element) => element.setAttribute('title', geti18nText('app.default.filter.range.prevWeek')));
    document
        .querySelectorAll('button.fc-next-button')
        ?.forEach((element) => element.setAttribute('title', geti18nText('app.default.filter.range.nextWeek')));

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const canViewGeneral = () => {
        return canCreate() || (moduleAccessRight && moduleAccessRight.includes('TRAVEL_WARRANT'));
    };

    const canViewActions = () => {
        return (
            canCreate() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const canViewWaitingForApproval = () => {
        return canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL);
    };

    const canViewUnpaid = () => {
        return canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT);
    };

    const canViewWaitingForOrganization = () => {
        return canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION);
    };

    const canViewWaitingFoReportApproval = () => {
        return canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL);
    };

    const canViewNotCalculated = () => {
        return (
            canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const canViewUnapprovedPayments = () => {
        return canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT_APPROVAL);
    };

    const canViewUnpaidTrips = () => {
        return (
            canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const canViewCanceled = () => {
        return (
            canCreate() ||
            TravelWarrantRights.canCreateTravelWarrantAdmin() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT)
        );
    };

    const isCalendarAdmin = () => {
        return (
            canCreate() ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_ORGANISATION) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_PAYMENT) ||
            userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    const canViewWageErrors = () => {
        return (
            canCreate() || userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT)
        );
    };

    useHelper('travel_order/travel_warrant');

    useEffect(() => {
        setCountNotCalculated(notCalculated + reportApproved);
    }, [notCalculated, reportApproved]);

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    useEffect(() => {
        getCount();
    }, [refresh]);

    useEffect(() => {
        setActiveList(prop);
    }, [prop]);

    const getCount = () => {
        if (canViewWaitingForApproval()) {
            getWaitingForApprovalBadge();
        }
        if (canViewUnpaid()) {
            getUnpaidBadge();
        }
        if (canViewWaitingForOrganization()) {
            getWaitingForOrganiztionBadge();
        }
        if (canViewWaitingFoReportApproval()) {
            getWaitingForReportApprovalBadge();
        }
        if (canViewNotCalculated()) {
            getNotCalculatedBadge();
        }
        if (canViewUnapprovedPayments()) {
            getUnapprovedPaymentsBagde();
        }
        if (canViewUnpaidTrips()) {
            getUnpaidTripsBadge();
        }
        if (canViewCanceled()) {
            getCancelRequestsBadge();
        }
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const getWaitingForApprovalBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.WAITING_FOR_APPROVAL,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountWaitingForApproval(result.data.ALL);
                } else {
                    setCountWaitingForApproval(0);
                }
            }
        });
    };

    const getUnpaidBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            advanceApproved: true,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountUnpaid(result.data.ALL);
                } else {
                    setCountUnpaid(0);
                }
            }
        });
    };

    const getWaitingForOrganiztionBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.WAITING_FOR_ORGANIZATION,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountWaitingForOrganiztion(result.data.ALL);
                } else {
                    setCountWaitingForOrganiztion(0);
                }
            }
        });
    };

    const getWaitingForReportApprovalBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.WAITING_FOR_REPORT_APPROVAL,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountWaitingForReportApproval(result.data.ALL);
                } else {
                    setCountWaitingForReportApproval(0);
                }
            }
        });
    };

    const getNotCalculatedBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.NOT_CALCULATED,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setNotCalculated(result.data.ALL);
                } else {
                    setNotCalculated(0);
                }
            }
        });
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.REPORT_APPROVED,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setReportApproved(result.data.ALL);
                } else {
                    setReportApproved(0);
                }
            }
        });
    };

    const getUnapprovedPaymentsBagde = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.CALCULATED,
            paymentStatus: travelWarrantPayment.WAITING_FOR_APPROVAL,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountUnapprovedPayments(result.data.ALL);
                } else {
                    setCountUnapprovedPayments(0);
                }
            }
        });
    };

    const getUnpaidTripsBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.COUNT, {
            status: travelWarrantStatus.CALCULATED,
            paymentStatus: travelWarrantPayment.APPROVED,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountUnpaidTrips(result.data.ALL);
                } else {
                    setCountUnpaidTrips(0);
                }
            }
        });
    };

    const getCancelRequestsBadge = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.CANCELATION_COUNT).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.ALL) {
                    setCountCancelRequests(result.data.ALL);
                } else {
                    setCountCancelRequests(0);
                }
            }
        });
    };

    const onChangeActiveList = (e: any) => {
        setActiveList(e.target.value > 0 ? e.target.value : 1);
        dispatch(setProp(e.target.value > 0 ? e.target.value : 1));
    };

    const filterForUnpaid = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'unpaidAdvance', condition: 'equals_bool', value: 1 },
            { field: 'advanceApproved', condition: 'equals_bool', value: 1 },
        ];
    };

    const filterForUnapprovedPayments = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'equals', value: travelWarrantStatus.CALCULATED },
            { field: 'paymentStatus', condition: 'equals', value: travelWarrantPayment.WAITING_FOR_APPROVAL },
        ];
    };

    const filterForUnpaidTrips = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'status', condition: 'equals', value: travelWarrantStatus.CALCULATED },
            { field: 'paymentStatus', condition: 'equals', value: travelWarrantPayment.APPROVED },
        ];
    };

    const initialSelectedOptions = () => {
        return [
            travelWarrantStatus.WAITING_FOR_APPROVAL,
            travelWarrantStatus.APPROVED,
            travelWarrantStatus.REJECTED,
            travelWarrantStatus.WAITING_FOR_ORGANIZATION,
            travelWarrantStatus.ORGANIZED,
            travelWarrantStatus.WAITING_FOR_REPORT_APPROVAL,
            travelWarrantStatus.REPORT_APPROVED,
            travelWarrantStatus.REPORT_FIX_NEEDED,
            travelWarrantStatus.CALCULATED,
            travelWarrantStatus.NOT_CALCULATED,
            travelWarrantStatus.PAID,
        ];
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                value={activeList}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '20px', marginTop: '5px' }}
            >
                <Radio.Button value={1} onChange={onChangeActiveList}>
                    {geti18nText('travelWarrantPersonal.table.header')}
                </Radio.Button>
                {canViewGeneral() && (
                    <Radio.Button value={12} onChange={onChangeActiveList}>
                        {geti18nText('travel.warrant.general')}
                    </Radio.Button>
                )}
                {canViewActions() && (
                    <Radio.Button value={2} onChange={onChangeActiveList}>
                        {geti18nText('travelWarrant.overview.actions')}
                    </Radio.Button>
                )}
                {canViewWaitingForApproval() && (
                    <Radio.Button value={3} onChange={onChangeActiveList}>
                        <Badge count={countWaitingForApproval} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.waitingForApproval')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewUnpaid() && (
                    <Radio.Button value={4} onChange={onChangeActiveList}>
                        <Badge count={countUnpaid} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.unpaid')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewWaitingForOrganization() && (
                    <Radio.Button value={5} onChange={onChangeActiveList}>
                        <Badge count={countWaitingForOrganiztion} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.waitingForOrganiztion')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewWaitingFoReportApproval() && (
                    <Radio.Button value={6} onChange={onChangeActiveList}>
                        <Badge count={countWaitingForReportApproval} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.waitingForReportApproval')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewNotCalculated() && (
                    <Radio.Button value={7} onChange={onChangeActiveList}>
                        <Badge count={countNotCalculated} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.notCalculated')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewUnapprovedPayments() && (
                    <Radio.Button value={9} onChange={onChangeActiveList}>
                        <Badge count={countUnapprovedPayments} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.unapprovedPayments')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewUnpaidTrips() && (
                    <Radio.Button value={10} onChange={onChangeActiveList}>
                        <Badge count={countUnpaidTrips} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.unpaidTrips')}
                        </Badge>
                    </Radio.Button>
                )}
                {canViewCanceled() && (
                    <Radio.Button value={11} onChange={onChangeActiveList}>
                        <Badge count={countCancelRequests} showZero overflowCount={999} offset={[2, -10]}>
                            {geti18nText('travel.warrant.cancelRequests')}
                        </Badge>
                    </Radio.Button>
                )}
                <Radio.Button value={8} onChange={onChangeActiveList}>
                    {geti18nText('travel.warrant.calendar')}
                </Radio.Button>
            </Radio.Group>

            {activeList == 1 ? (
                <TravelWarrantPersonalIndex
                    refresh={refresh}
                    setRefresh={setRefresh}
                    employee={employee}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 2 ? (
                <TravelWarrantTableActions
                    refresh={refresh}
                    setRefresh={setRefresh}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 3 ? (
                <TravelWarrantTableWaitingForApproval
                    refresh={refresh}
                    setRefresh={setRefresh}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 4 ? (
                <div>
                    <TravelWarrantTableUnpaid
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setDefaultFilterValue={filterForUnpaid}
                        tab={4}
                        canViewWageErrors={canViewWageErrors()}
                    />
                </div>
            ) : activeList == 5 ? (
                <TravelWarrantTableWaitingForOrganiztion
                    refresh={refresh}
                    setRefresh={setRefresh}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 6 ? (
                <TravelWarrantTableWaitingForReportApproval
                    refresh={refresh}
                    setRefresh={setRefresh}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 7 ? (
                <TravelWarrantTableNotCalculated
                    refresh={refresh}
                    setRefresh={setRefresh}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 8 ? (
                <NyFullCalendar
                    keys={['travelWarrant']}
                    height={'700px'}
                    isFullScreenCalendar={true}
                    initialSelectedOptions={JSON.stringify([
                        { keyType: 'travelWarrant', selectedOptions: initialSelectedOptions() },
                    ])}
                    isAdmin={isCalendarAdmin()}
                    isPersonal={isCalendarAdmin() ? false : true}
                    employeeUrl={CONSTANTS_REQ.EMPLOYEE.TRAVEL_SEARCH}
                />
            ) : activeList == 9 ? (
                <TravelWarrantTableUnpaid
                    refresh={refresh}
                    setRefresh={setRefresh}
                    tabKey={activeList}
                    setDefaultFilterValue={filterForUnapprovedPayments}
                    tab={9}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 10 ? (
                <div>
                    <TravelWarrantTableUnpaid
                        refresh={refresh}
                        setRefresh={setRefresh}
                        tabKey={activeList}
                        setDefaultFilterValue={filterForUnpaidTrips}
                        tab={10}
                        canViewWageErrors={canViewWageErrors()}
                    />
                </div>
            ) : activeList == 11 ? (
                <TravelWarrantTableCancelRequests
                    refresh={refresh}
                    setRefresh={setRefresh}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : activeList == 12 ? (
                <TravelWarrantTableGeneral
                    refresh={refresh}
                    setRefresh={setRefresh}
                    employee={employee}
                    canViewWageErrors={canViewWageErrors()}
                />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export default TravelWarrantIndex;
