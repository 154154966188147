import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import PayrollAdditionIndex from '.';
import PayrollAdditionEdit from './edit';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const PayrollAdditionSearch = ({
    AddNewModalComponent = PayrollAdditionEdit,
    autoFocus,
    isForm = true,
    itemName = 'name',
    label = geti18nText('employeePayrollAddition.edit.payrollAddition'),
    name = 'payrollAddition',
    onChange = null,
    required = false,
    SearchPopupComponent = <PayrollAdditionIndex />,
    style = undefined,
}: any) => {
    return (
        <>
            {isForm ? (
                <Form.Item
                    label={label}
                    name={name}
                    rules={[
                        {
                            required: required,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    <NySearchField
                        autoFocus={autoFocus}
                        url={CONSTANTS_REQ.PAYROLL_ADDITION.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        itemName={itemName}
                        AddNewModalComponent={AddNewModalComponent}
                        SearchPopupComponent={SearchPopupComponent}
                        onChange={onChange}
                        style={style}
                    />
                </Form.Item>
            ) : (
                <NySearchField
                    autoFocus={autoFocus}
                    url={CONSTANTS_REQ.PAYROLL_ADDITION.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                    itemName={itemName}
                    AddNewModalComponent={AddNewModalComponent}
                    SearchPopupComponent={SearchPopupComponent}
                    onChange={onChange}
                    style={style}
                />
            )}
        </>
    );
};

export default PayrollAdditionSearch;
