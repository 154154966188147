import { NyDatePicker, NyRequestResolver, NySearchField, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Radio, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    okNotification,
    warningNotification,
} from '../../../../../utils/Utils';
import { BusinessUnitSearch } from '../../business-unit/search';
import EmployeeIndex from '../../employee';
import EmployeeEvaluationPeriodSearch from './period-search';

const EmployeeEvaluationRequestModal = ({
    requestModalVisible,
    setRequestModalVisible,
    setRefresh,
    selectedPeriod = undefined,
}: any) => {
    const [form] = Form.useForm();
    const [activeList, setActiveList] = useState<any>(1);
    const employeeEvaluationStatusEnum = useEnum('EMPLOYEE_EVALUATION_STATUS');
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        if (requestModalVisible) {
            if (selectedPeriod?.id) {
                const dateFrom = selectedPeriod?.dateFrom ? moment(selectedPeriod.dateFrom).format('DD.MM.YYYY.') : '';
                const dateTo = selectedPeriod?.dateTo ? moment(selectedPeriod.dateTo).format('DD.MM.YYYY.') : '';
                const dates = dateFrom + ' - ' + dateTo;
                selectedPeriod.text = dates;
                selectedPeriod.label = dates;
                form.setFieldsValue({ employeeEvaluationPeriod: selectedPeriod });
            } else {
                form.setFieldsValue({ employeeEvaluationPeriod: null });
            }
        }
    }, [selectedPeriod, requestModalVisible]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const closeModal = () => {
        setRequestModalVisible(false);
        setActiveList(1);
        form.resetFields();
    };

    const normalize = (values: any, status: any) => {
        if (values.employee) {
            values.employee = getSearchFormat(values.employee);
            if (values.employee.id < 1) {
                delete values.employee;
            }
        }
        if (values.directManager && activeList === 1) {
            values.directManager = getSearchFormat(values.directManager);
            if (values.directManager.id < 1) {
                delete values.directManager;
            }
        }
        if (values.superiorManager && activeList === 1) {
            values.superiorManager = getSearchFormat(values.superiorManager);
            if (values.superiorManager.id < 1) {
                delete values.superiorManager;
            }
        }
        if (values.businessUnit) {
            values.businessUnit = getSearchFormat(values.businessUnit);
            if (values.businessUnit?.id < 1) {
                delete values.businessUnit;
            }
        }
        if (values.dueDate) values.dueDate = getDateFormat(values.dueDate);
        if (values.employeeEvaluationPeriod) {
            values.employeeEvaluationPeriod = getSearchFormat(values.employeeEvaluationPeriod);
            if (values.employeeEvaluationPeriod?.id < 1) {
                delete values.employeeEvaluationPeriod;
            }
        } else {
            values.employeeEvaluationPeriod = { id: selectedPeriod.id };
        }
        values.status = status;

        return values;
    };

    const normalizeMultiple = (values: any) => {
        let object: any = {
            employeeEvaluationPeriodId: selectedPeriod
                ? selectedPeriod.id
                : values?.employeeEvaluationPeriod?.id > 0
                ? values?.employeeEvaluationPeriod?.id
                : null,
        };
        if (values?.businessUnit?.id > 0) {
            object = { ...object, businessUnitId: values?.businessUnit?.id };
        }
        if (values?.dueDate) {
            object = { ...object, dueDate: getDateFormat(values.dueDate) };
        }
        return object;
    };

    const onSave = (status: any = employeeEvaluationStatusEnum.DRAFT) => {
        form.validateFields().then((values: any) => {
            if (values?.employeeEvaluationPeriod?.id || selectedPeriod) {
                setLoading(true);
                const url =
                    activeList === 2
                        ? CONSTANTS_REQ.EMPLOYEE_EVALUATION.GENERATE_MULTIPLE_EVALUATIONS
                        : CONSTANTS_REQ.EMPLOYEE_EVALUATION.EDIT;
                const valuesNormalized = activeList == 2 ? normalizeMultiple(values) : normalize(values, status);
                NyRequestResolver.requestPost(url, undefined, valuesNormalized)
                    .then((result: any) => {
                        if (result.status === RESPONSE.CREATED && activeList === 1) {
                            okNotification();
                            closeModal();
                            setRefresh((prevValue: any) => prevValue + 1);
                        } else if (result.status === RESPONSE.OK && activeList === 2) {
                            okNotification();
                            closeModal();
                            setRefresh((prevValue: any) => prevValue + 1);
                        } else {
                            errorNotification();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                warningNotification(geti18nText('employee.evaluation.period.select'));
            }
        });
    };

    const footer = (
        <div style={{ float: 'right', display: 'contents' }}>
            <Button onClick={closeModal}>{geti18nText('app.default.button.close')}</Button>
            <Button type="primary" onClick={() => onSave(employeeEvaluationStatusEnum.DRAFT)} loading={loading}>
                {geti18nText('app.default.button.generate')}
            </Button>
        </div>
    );

    const employeeOnChange = (value: any) => {
        if (value && value.id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_MANAGERS + '/' + value.id, undefined).then(
                (response: any) => {
                    if (response?.status == RESPONSE.OK && response?.data) {
                        form.resetFields(['directManager']);
                        if (response.data[0] && response.data[0]?.directManager?.id) {
                            form.setFieldValue(
                                'directManager',
                                getEmployeeSelectOption(response?.data[0]?.directManager)
                            );
                        }
                        form.resetFields(['superiorManager']);
                        if (response.data[1] && response.data[1]?.superiorManager?.id) {
                            form.setFieldValue(
                                'superiorManager',
                                getEmployeeSelectOption(response?.data[1]?.superiorManager)
                            );
                        }
                    }
                }
            );
        }
    };

    return (
        <Modal
            open={requestModalVisible}
            onCancel={closeModal}
            maskClosable={false}
            title={
                <>
                    {selectedPeriod
                        ? geti18nText('employee.evaluation.edit.modalTitle') +
                          ' (' +
                          moment(selectedPeriod?.dateFrom).format('DD.MM.YYYY.') +
                          ' - ' +
                          moment(selectedPeriod?.dateTo).format('DD.MM.YYYY.') +
                          ')'
                        : geti18nText('employee.evaluation.edit.modalTitle')}
                </>
            }
            width={600}
            forceRender={true}
            closable={true}
            footer={footer}
        >
            <Radio.Group
                style={{ marginRight: '5px', marginBottom: '15px' }}
                buttonStyle="solid"
                onChange={(e: any) => {
                    setActiveList(e.target.value);
                }}
                value={activeList}
            >
                <Radio.Button value={1}>{geti18nText('employee.evaluation.edit.tab.1')}</Radio.Button>
                <Radio.Button value={2}>{geti18nText('employee.evaluation.edit.tab.2')}</Radio.Button>
            </Radio.Group>
            <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                {activeList === 1 && (
                    <>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employee.evaluation.edit.employee')}
                                    name={'employee'}
                                    rules={[
                                        {
                                            required: activeList === 1,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                            businessUnit: 'businessUnit',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="evaluation-request-modal"/>}
                                        order="person.last_name, person.first_name"
                                        onChange={employeeOnChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employee.evaluation.edit.directManager')}
                                    name={'directManager'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employee.evaluation.edit.superiorManager')}
                                    name={'superiorManager'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                {activeList === 2 && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <BusinessUnitSearch
                                style={{ width: '100%' }}
                                searchPopupComponent={false}
                                formItemName="businessUnit"
                                placeholder={geti18nText('employee.evaluation.edit.allBusinessUnits')}
                            />
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    {selectedPeriod ? (
                        <></>
                    ) : (
                        <Col span={12}>
                            <EmployeeEvaluationPeriodSearch canAddNew />
                        </Col>
                    )}
                    <Col span={selectedPeriod ? 24 : 12}>
                        <Form.Item label={geti18nText('employee.evaluation.edit.dueDate')} name="dueDate">
                            <NyDatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EmployeeEvaluationRequestModal;
