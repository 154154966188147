import { useMap } from 'react-leaflet';
import { polyline } from 'leaflet';
import { useEffect } from 'react';

const LineMap = ({ waypoints, isPlane = false }: any) => {
    const map = useMap();
    let linePath: any;

    useEffect(() => {
        map.eachLayer((layer: any) => {
            if (layer && layer.options && (layer.options.attribution == 'map-line' || layer.options.color || layer.options.icon)) {
                map.removeLayer(layer);
            }
        });
        if (!isPlane) {
            return;
        }

        if (waypoints && waypoints.length > 0 && waypoints[1] && waypoints[1].lat) {
            const cords: any = [];

            waypoints.map((item: any) => {
                if (item.lat && item.lng) {
                    cords.push([Number(item.lat), Number(item.lng)]);
                }
            });

            if (cords) {
                linePath = polyline(cords, {
                    color: 'red',
                    fill: false,
                    attribution: 'map-line',
                });
            }
            //    map.panTo(pathFive.center);
            map.addLayer(linePath);
        }
    }, [waypoints, isPlane]);

    return <></>;
};

export default LineMap;
