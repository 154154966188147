import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySession } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import moment from 'moment';
import { getDateFormat, setDateFormat } from '../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const BusinessUnitTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('businessUnitType.edit.new'));
    const [loading, setLoading] = useState(false);
    const [validFrom, setValidFrom] = useState<any>(undefined);
    const [validTo, setValidTo] = useState<any>(undefined);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('businessUnitType.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('businessUnitType.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.BUSINESS_UNIT_TYPE.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={false}
            normalize={(values: any) => {
                if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
                if (values.validTo) values.validTo = getDateFormat(values.validTo);

                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('businessUnitType.edit.code')}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('businessUnitType.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('businessUnitType.edit.dateFrom')}
                        name="validFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            onChange={(value: any) => {
                                setValidFrom(value);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('businessUnitType.edit.dateTo')} name="validTo">
                        <NyDatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            onChange={(value: any) => {
                                setValidTo(value);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('businessUnitType.edit.level')} name="level">
                        <NyInputNumber style={{ width: '100%' }} min={1} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default BusinessUnitTypeEdit;
