import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import EmployeeTravelRelationIndex from './views/employee-travel-relation';
import TravelAccessRightIndex from './views/travel-access-right';
import TravelCostsOverviewIndex from './views/travel-costs-overview';
import TravelEventNumberIndex from './views/travel-event-number';
import TravelExpenseIndex from './views/travel-expense';
import TravelExpensePaymentMethodIndex from './views/travel-expense-payment-method';
import TravelFuelTypeIndex from './views/travel-fuel-type';
import TravelNotificationSettings from './views/travel-notification-settings';
import TravelVehicleIndex from './views/travel-vehicle';
import TravelWageIndex from './views/travel-wage';
import TravelWageExpenseIndex from './views/travel-wage-expense';
import TravelWarrantIndex from './views/travel-warrant';
import TravelWarrantOverviewIndex from './views/travel-warrant-overview';
import TravelWarrantSettings from './views/travel-warrant-settings';
import TravelWarrantTypeIndex from './views/travel-warrant-type';
import TravelWarrantEdit from './views/travel-warrant/edit';

export const TravelWarrantModuleRoutes: any = [
    {
        path: '/travel-warrant',
        exact: true,
        component: TravelWarrantOverviewIndex,
        notificationType: 12,
        role: TravelWarrantRights.canViewTravelWarrantRoutes(),
    },
    {
        path: '/travel-warrant/employeeTravelRelation',
        exact: true,
        component: EmployeeTravelRelationIndex,
        role: TravelWarrantRights.canViewTravelLocalRoutes(),
    },
    {
        path: '/travel-warrant/travelWarrant',
        exact: true,
        component: TravelWarrantIndex,
        role: TravelWarrantRights.canViewTravelWarrantRoutes(),
    },
    {
        path: '/travel-warrant/travelWarrant/:id',
        component: TravelWarrantEdit,
        role: TravelWarrantRights.canViewTravelWarrantRoutes(),
    },
    {
        path: '/travel-warrant/travelCostsOverview',
        exact: true,
        component: TravelCostsOverviewIndex,
        role: TravelWarrantRights.canViewTravelCostsRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelVehicle',
        exact: true,
        component: TravelVehicleIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelFuelType',
        exact: true,
        component: TravelFuelTypeIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelWage',
        exact: true,
        component: TravelWageIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelWageExpense',
        exact: true,
        component: TravelWageExpenseIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelExpense',
        exact: true,
        component: TravelExpenseIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelPaymentMethod',
        exact: true,
        component: TravelExpensePaymentMethodIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelEventNumber',
        exact: true,
        component: TravelEventNumberIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/codebooks/travelWarrantType',
        exact: true,
        component: TravelWarrantTypeIndex,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/settings/travel-warrant-settings',
        exact: true,
        component: TravelWarrantSettings,
        role: TravelWarrantRights.canViewTravelCodebooksRoutes(),
    },
    {
        path: '/travel-warrant/settings/travel-notification-settings',
        exact: true,
        component: TravelNotificationSettings,
        role: TravelWarrantRights.canViewTravelSettingsOnlyMenu(),
    },
    {
        path: '/travel-warrant/settings/travel-access-right',
        exact: true,
        component: TravelAccessRightIndex,
        role: TravelWarrantRights.canViewTravelSettingsOnlyMenu(),
    },
];
