import { ClearOutlined } from '@ant-design/icons';
import { NyDataTable, NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { BusinessUnitSearch } from '../business-unit/search';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';
import { VocationDescriptionSearch } from '../vocation-description/search';
import VocationSearch from '../vocation/search';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const ComparativeViewTable = ({
    scroll = { y: 490, x: 800 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const table = useTableSettings();
    const [refresh, setRefresh] = useState<any>(0);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [item, setItem] = useState<any>();
    const [isNew, setIsNew] = useState(false);
    const [employeeCount, setEmployeeCount] = useState<any>(0);
    const [mobilizationEmployeeCount, setMobilizationEmployeeCount] = useState<any>(0);
    const themeStorage = JSON.parse(localStorage['persist:root']);
    const isDarkTheme = themeStorage != undefined && JSON.parse(themeStorage.generalSettings).theme === 'dark';
    const [filterForm] = Form.useForm();

    let defaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }];

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    useEffect(() => {
        getComparativeCount();
    }, []);

    const onChange = (values: any = null) => {
        setRefresh((prevValue: any) => prevValue + 1);
        getComparativeCount();
    };

    const getComparativeCount = () => {
        let sSearch: any = {};
        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));
        if (stringUri.length > 0) {
            sSearch = { search: stringUri };
        }
        NyRequestResolver.requestGet(CONSTANTS_REQ.WORKPLACE_DETAILS.COMPARATIVE_MOBILIZATION_LIST_COUNT, {
            ...sSearch,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                setEmployeeCount(result?.data?.employeeCount);
                setMobilizationEmployeeCount(result?.data?.mobilizationEmployeeCount);
            }
        });
    };

    const setDefaultFilterValue = () => {
        let currentDefaultFilterValue = [...defaultFilterValue];
        if (filterForm.getFieldValue('businessUnit') && filterForm.getFieldValue('businessUnit').length > 0) {
            let ids = filterForm.getFieldValue('businessUnit').map((val: any) => val.id);
            currentDefaultFilterValue.push({ field: 'businessUnit.id', condition: 'in', value: ids.toString() });
        }
        if (filterForm.getFieldValue('vocation') && filterForm.getFieldValue('vocation').length > 0) {
            let ids = filterForm.getFieldValue('vocation').map((val: any) => val.id);
            currentDefaultFilterValue.push({ field: 'vocation.id', condition: 'in', value: ids.toString() });
        }
        if (
            filterForm.getFieldValue('vocationDescription') &&
            filterForm.getFieldValue('vocationDescription').length > 0
        ) {
            let ids = filterForm.getFieldValue('vocationDescription').map((val: any) => val.id);
            currentDefaultFilterValue.push({ field: 'vocationDescription.id', condition: 'in', value: ids.toString() });
        }
        if (filterForm.getFieldValue('person')) {
            currentDefaultFilterValue.push({
                field: "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name, mobilizationPerson.first_name, ' ', mobilizationPerson.last_name, ' ', mobilizationPerson.parent_name) ",
                condition: 'contains',
                value: filterForm.getFieldValue('person'),
            });
        }
        return currentDefaultFilterValue;
    };

    const columns = [
        {
            title: <div style={{ float: 'left' }}>{geti18nText('systematization.of.jobs.table.bank.tab')}</div>,
            children: [
                {
                    title: geti18nText('assetReturn.table.column.businessUnit'),
                    dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        if (record.businessUnit) {
                            return (
                                (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                                record.businessUnit.name
                            );
                        }
                    },
                },
                {
                    title: geti18nText('work.obligation.employee.table.column.vocation'),
                    dataIndex: ['vocation', 'name'],
                    sorter: (a: any, b: any) => {},
                },
                {
                    title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
                    dataIndex: ['vocationDescription', 'name'],
                    sorter: (a: any, b: any) => {},
                },
                {
                    title: geti18nText('employee.mobilization.edit.lastParentFirstName'),
                    dataIndex: "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name) ",
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        if (record?.person) {
                            return (
                                <a
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => onEmployeeSelected(record?.employee?.id)}
                                >
                                    {(record?.person?.lastName ? record?.person?.lastName + ', ' : '') +
                                        (record?.person?.parentName
                                            ? record?.person?.parentName + ', '
                                            : record?.person?.firstName
                                            ? ' -, '
                                            : '') +
                                        (record?.person?.firstName ? record?.person?.firstName : '')}
                                </a>
                            );
                        }
                    },
                },
            ],
        },
        {
            title: (
                <div
                    style={{
                        background: isDarkTheme ? '#123759' : '#bae7ff',
                        borderTop: '2px',
                        borderBottom: '2px',
                    }}
                >
                    {''}
                </div>
            ),
            children: [
                {
                    width: '12px',
                    render: () => ({
                        props: {
                            style: {
                                background: isDarkTheme ? '#123759' : '#bae7ff',
                                borderTop: '2px',
                                borderBottom: '2px',
                            },
                        },
                    }),
                },
            ],
        },
        {
            title: <div style={{ float: 'left' }}>{geti18nText('menu.work.obligation.war.systematization')}</div>,
            children: [
                {
                    title: geti18nText('assetReturn.table.column.businessUnit'),
                    dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        if (record.businessUnit) {
                            return (
                                (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                                record.businessUnit.name
                            );
                        }
                    },
                },
                {
                    title: geti18nText('work.obligation.employee.table.column.vocation'),
                    dataIndex: ['vocation', 'name'],
                    sorter: (a: any, b: any) => {},
                },
                {
                    title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
                    dataIndex: ['vocationDescription', 'name'],
                    sorter: (a: any, b: any) => {},
                },
                {
                    title: geti18nText('employee.mobilization.edit.lastParentFirstName'),
                    dataIndex:
                        "concat(mobilizationPerson.first_name, ' ', mobilizationPerson.last_name, ' ', mobilizationPerson.parent_name) ",
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        if (record?.mobilizationPerson) {
                            return (
                                <a
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => onEmployeeSelected(record?.mobilizationEmployee?.id)}
                                >
                                    {(record?.mobilizationPerson?.lastName
                                        ? record?.mobilizationPerson?.lastName + ', '
                                        : '') +
                                        (record?.mobilizationPerson?.parentName
                                            ? record?.mobilizationPerson?.parentName + ', '
                                            : record?.mobilizationPerson?.firstName
                                            ? ' -, '
                                            : '') +
                                        (record?.mobilizationPerson?.firstName
                                            ? record?.mobilizationPerson?.firstName
                                            : '')}
                                </a>
                            );
                        }
                    },
                },
            ],
        },
    ];

    const onEmployeeSelected = (id: any) => {
        if (id) {
            setItem(id);
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <Form form={filterForm} layout="vertical">
                <Row gutter={24}>
                    <Col span={6}>
                        <BusinessUnitSearch formItemName={'businessUnit'} onChange={onChange} mode={'multiple'} />
                    </Col>
                    <Col span={6}>
                        <VocationSearch formItemName={'vocation'} onChange={onChange} mode={'multiple'} />
                    </Col>
                    <Col span={6}>
                        <VocationDescriptionSearch
                            formItemName={'vocationDescription'}
                            onChange={onChange}
                            mode={'multiple'}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item label={geti18nText('employee.mobilization.edit.lastParentFirstName')} name="person">
                            <Input onChange={onChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Button
                            style={{
                                float: 'right',
                                marginRight: '8px',
                                marginBottom: '10px',
                            }}
                            size={'small'}
                            onClick={() => {
                                filterForm.resetFields();
                                onChange();
                            }}
                        >
                            {geti18nText('app.default.filter.clear.filters')}
                            <ClearOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div style={{ display: 'flex', fontSize: '15px' }}>
                <div style={{ marginLeft: '674px' }}>
                    {geti18nText('app.default.total')}: <span style={{ fontWeight: '500' }}>{employeeCount}</span>
                </div>
                <div style={{ marginLeft: '822px' }}>
                    {geti18nText('app.default.total')}:{' '}
                    <span style={{ fontWeight: '500' }}>{mobilizationEmployeeCount}</span>
                </div>
            </div>
            <NyDataTable
                nyId="human-comparative-mobilization-table"
                url={CONSTANTS_REQ.WORKPLACE_DETAILS.COMPARATIVE_MOBILIZATION_LIST}
                buttonsClassName="buttons-sticky"
                readonly
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideButtons
                shortcuts={true}
                fetchWhenChange={refresh}
                excludeFromLocalStorage={true}
                showCleanTableFilters={false}
            />
            {isModalVisible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default ComparativeViewTable;
