import { getColumnDateOption, getColumnSearch, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { Button, Popover } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import RowAction from '../../../../../components/row-action';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { RootState } from '../../../../../rootReducer';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookCreatedEmployee,
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookSimple,
} from '../../../../../utils/Filters';
import { getOrderStatusIcon } from '../../../../../utils/Utils';
import AssetOrderEdit from './edit';

const AssetOrderApproval = ({ scroll = { y: 620, x: 800 }, sortOrder, getItemsContent }: any) => {
    const table = useTableSettings();
    const dispatch = useDispatch();
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);

    const canCreate = () => {
        return false;
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    let setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    const initialColumns = [
        {
            title: geti18nText('inventoryOrder.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                return (
                    <Popover
                        placement="right"
                        content={getItemsContent(record)}
                        trigger="hover"
                        overlayClassName="ny-popover"
                    >
                        <Button
                            type="link"
                            style={{ textDecoration: 'underline' }}
                            onClick={() =>
                                dispatch(
                                    setModalOpen({
                                        modal: 'assetOrderEdit',
                                        visible: true,
                                        item: record.id,
                                    })
                                )
                            }
                        >
                            {record.id}
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.ord'),
            dataIndex: [`concat(_ao.ord, '/', date_part('year', _ao.date))`],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.edit.employee'),
            dataIndex: [`concat(person.first_name,' ', person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record.employee?.person) {
                    return (
                        <div>
                            {record.employee.person?.firstName +
                                ' ' +
                                record.employee.person?.lastName +
                                (record.employee.employmentRecordId
                                    ? ' (' + record.employee.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.createdByPerson'),
            dataIndex: [`concat(created_by_person.first_name,' ', created_by_person.last_name)`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookCreatedEmployee(),
            render: (text: any, record: any) => {
                if (record.createdByPerson) {
                    return record.createdByPerson.firstName + ' ' + record.createdByPerson.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.orderView'),
            dataIndex: ['orderView', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ORDER_VIEW.SEARCH, 'orderView.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('inventoryOrder.table.column.date'),
            dataIndex: 'date',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            render: (status: any) => {
                return getOrderStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'AssetOrder'} record={record}></RowAction>;
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="asset-order-approveal"
            url={CONSTANTS_REQ.ASSET_ORDER.LIST_REQUESTED}
            addNewButtonText={geti18nText('inventoryOrder.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={AssetOrderEdit}
            columns={initialColumns}
            setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
            scroll={scroll}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            shortcuts={true}
            hideNewButton={!canCreate()}
            fetchWhenChange={refreshItem}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('asset.table.csv')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={csvColumns()}
            idOpenLink={false}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + value);
            },
        },
        {
            employee: (value: any) => {
                return (
                    value != undefined &&
                    value.person != undefined &&
                    value.person.firstName + ' ' + value.person.lastName
                );
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            createdByPerson: (value: any) => {
                return value && value.firstName + ' ' + value.lastName;
            },
        },
        {
            orderView: (value: any) => {
                return value != null && value.name != null && value.name;
            },
        },
        {
            date: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('inventoryOrder.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('inventoryOrder.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('inventoryOrder.table.column.name'),
            dataIndex: 'employee',
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('inventoryOrder.table.column.createdByPerson'),
            dataIndex: 'createdByPerson',
        },
        {
            title: geti18nText('inventoryOrder.table.column.orderView'),
            dataIndex: 'orderView',
        },
        {
            title: geti18nText('inventoryOrder.table.column.date'),
            dataIndex: 'date',
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
        },
    ];
};

export default AssetOrderApproval;
