import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { okNotification, errorNotification, getEnumFormat } from '../../../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeEducationEditWizard = ({ visible, setVisible, id, employeeEducation, setRefresh, setValue }: any) => {
    const [form] = Form.useForm();
    const [realized, setRealized] = useState(false);
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const isUser = () => {
        return HumanResourcesRights.isEducationUser();
    };

    const isAdmin = () => {
        return HumanResourcesRights.isEducationAdmin();
    };

    const disabled: boolean = !isUser() && !isAdmin();

    useEffect(() => {
        if (realized) {
            form.setFieldsValue({ unrealizationReason: null });
        }
    }, [realized]);

    const normalize = (values: any) => {
        values.realized = realized;
        if (!realized) {
            values.unrealizationReason = { id: getEnumFormat(values.unrealizationReason) };
        }
        values.unrealizationRemark = values.unrealizationRemark;
        values.id = id;
        values.employee = { id: employeeEducation?.employee?.id };
        values.status = employeeEducation.status;
        values.employeeEducation = employeeEducation;

        return values;
    };

    const handleOk = () => {
        form.validateFields().then((values: any) => {
            values = normalize(values);

            NyRequestResolver.requestPut(
                CONSTANTS_REQ.EMPLOYEE_EDUCATION.EMPLOYEE_UPDATE + '/' + id,
                undefined,
                values
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    setVisible(false);
                    setValue && setValue(null);
                    setRefresh((prevValue: any) => prevValue + 1);
                    setRealized(false);
                    form.resetFields();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
            });
        });
    };

    const handleCancel = () => {
        setVisible(false);
        setRealized(false);
        form.resetFields();
    };

    const getCustomFooterContent = (
        <div style={{ marginBottom: '30px' }}>
            <div style={{ float: 'left' }}></div>
            <div style={{ float: 'right' }}>
                <Button onClick={handleCancel}>{geti18nText('app.default.button.cancel')}</Button>
                <Button style={{ display: 'inline' }} type="primary" disabled={disabled} onClick={handleOk}>
                    {geti18nText('app.default.button.save')}
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <Modal
                open={visible}
                okText={geti18nText('app.default.button.save')}
                title={geti18nText('employee.education.realization.step.1') + ' - ' + employeeEducation?.name}
                width={900}
                footer={getCustomFooterContent}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <Form layout="vertical" form={form}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="realized" label={geti18nText('employee.education.edit2.realized')}>
                                <Checkbox
                                    checked={realized}
                                    onChange={(value: any) => setRealized(value.target.checked)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <HumanCodebooksSearch
                                label={geti18nText('employee.education.edit2.unrealizationReason')}
                                codebooksType={enCodebookType.UNREALIZATION_REASON}
                                formItemName={'unrealizationReason'}
                                disabled={realized}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="unrealizationRemark"
                                label={geti18nText('employee.education.edit2.unrealizationRemark')}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
export default EmployeeEducationEditWizard;
