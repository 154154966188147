import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router';
import RowAction from '../../../../../components/row-action';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getEnumArrayStatus, getOrderStatusIcon, okNotification, errorNotification } from '../../../../../utils/Utils';
import InventoryOrderEdit from '../inventory-order/edit';
import EmployeeInventoryOrderEdit from './EmployeeInventoryOrderEdit';

const { TextArea } = Input;

const EmployeeInventoryOrderTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const [item, setItem] = useState<any>(undefined);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const [cancelOrderModal, setCancelOrderModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(undefined);
    const location = useLocation();

    const table = useTableSettings();
    useHelper('my_orders');
    const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);

    const [noteForm] = Form.useForm();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'forDashboard', condition: 'equals_bool', value: 1 },
        ];
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    const columns = [
        {
            title: geti18nText('inventoryOrder.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('inventoryOrder.table.column.ord'),
            dataIndex: 'ord',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(
                getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE').filter((item: any) => item.id != 5 && item.id != 6)
            ),
            render: (status: any) => {
                return getOrderStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record != undefined && record.id != undefined) {
                    return (
                        <RowAction
                            component={'MyInventoryOrder'}
                            record={record}
                            changeStatus={openCancelOrderModal}
                        ></RowAction>
                    );
                }
            },
        },
    ];

    const onRowSelect = (item: any) => {
        if (item) {
            setItem(item);
            if (item.status && item.status != 2) {
                setIsModalVisible(true);
            } else {
                setIsEditModalVisible(true);
            }
        }
    };

    const openCancelOrderModal = (id: any) => {
        setSelectedOrderId(id);
        setCancelOrderModal(true);
    };

    const cancelOrder = () => {
        noteForm.validateFields().then((values) => {
            NyRequestResolver.requestPut(CONSTANTS_REQ.INVENTORY_ORDER.CANCEL + '/' + selectedOrderId, undefined, {
                id: selectedOrderId,
                note: { note: noteForm.getFieldValue('note') },
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                    setCancelOrderModal(false);
                    noteForm.resetFields();
                    setRefreshTable(refreshTable + 1);
                } else {
                    errorNotification();
                }
            });
        });
    };

    const closeCancelOrderModal = () => {
        noteForm.resetFields();
        setCancelOrderModal(false);
    };

    return (
        <div style={{ marginTop: '8px' }}>
            <NyDataTable
                nyId="employee-inventory-order"
                url={CONSTANTS_REQ.INVENTORY_ORDER.LIST}
                buttonsClassName="buttons-sticky"
                showRecordModal={false}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                hideNewButton={true}
                onRowSelect={onRowSelect}
                fetchWhenChange={refreshTable}
                exportCSV
                CSVFileName={geti18nText('menu.webshopItem')}
            />
            <EmployeeInventoryOrderEdit
                item={item}
                setItem={setItem}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
            <InventoryOrderEdit
                isModal={true}
                visible={isEditModalVisible}
                setVisible={setIsEditModalVisible}
                value={item}
                onSave={() => {
                    setRefreshTable((refresh: number) => refresh + 1);
                }}
            />
            {cancelOrderModal && (
                <Modal
                    title={geti18nText('inventoryOrder.cancel.title')}
                    visible={cancelOrderModal}
                    onOk={cancelOrder}
                    onCancel={closeCancelOrderModal}
                    footer={[
                        <>
                            <Button key="back" onClick={closeCancelOrderModal}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>

                            <Button key="submit" type="primary" danger onClick={() => setIsPopconfirmOpen(true)}>
                                {geti18nText('inventoryOrder.cancel')}
                            </Button>
                            {isPopconfirmOpen && (
                                <Modal
                                    visible={isPopconfirmOpen}
                                    title={<b>{geti18nText('report.inventoryOrder.removeAll.comfirm')}</b>}
                                    okText={geti18nText('app.default.button.yes')}
                                    cancelText={geti18nText('app.default.button.no')}
                                    onOk={() => {
                                        cancelOrder();
                                        setIsPopconfirmOpen(false);
                                    }}
                                    onCancel={() => {
                                        setIsPopconfirmOpen(false);
                                    }}
                                />
                            )}
                        </>,
                    ]}
                >
                    <Form form={noteForm} layout="vertical">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Alert
                                    message={geti18nText('inventoryOrder.cancel.order.info')}
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: '10px' }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={'note'}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default EmployeeInventoryOrderTable;
