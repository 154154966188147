import { NyDataTable, getColumnSearch, geti18nText } from '@nybble/nyreact';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookUserPerson } from '../../../../../utils/Filters';
import ContractNotificationsEdit from './edit';

const ContractNotificationsIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    contractTypeId,
    refresh,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const columns = [
        {
            title: geti18nText('contract.notification.type.table.column.id'),
            dataIndex: 'id',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('contract.notification.type.table.column.days'),
            dataIndex: 'day',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record.day) {
                    return record.day;
                }
            },
        },
        {
            title: geti18nText('contract.notification.type.table.column.user'),
            dataIndex: [`concat(userPerson.first_name,' ', userPerson.last_name)`],
            width: '60%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookUserPerson(),
            render: (text: any, record: any) => {
                if (record.userPerson) {
                    return record.userEmployee?.employmentRecordId
                        ? record.userPerson?.firstName +
                              ' ' +
                              record.userPerson?.lastName +
                              ' (' +
                              record.userEmployee?.employmentRecordId +
                              ')'
                        : record.userPerson?.firstName + ' ' + record.userPerson?.lastName;
                }
            },
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="human-contract-contract-notification-table"
                url={CONSTANTS_REQ.CONTRACT_NOTIFICATION.LIST}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                modalComponent={ContractNotificationsEdit}
                editProps={{
                    contractTypeId: contractTypeId,
                }}
                fetchWhenChange={refresh}
                addedData={{ externalId: contractTypeId }}
                setDefaultFilterValue={() => [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'externalId', condition: 'equals', value: contractTypeId },
                ]}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                shortcuts={true}
                hideNewButton={!canCreate()}
                exportCSV={canExportCSV()}
                csvColumns={csvColumns()}
                CSVFileName={geti18nText('contract.notification.type.edit.title')}
                colCSVCustomization={csvColumnCustomisation()}
            />
        </>
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.notification.type.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.notification.type.edit.days'),
            dataIndex: 'day',
        },
        {
            title: geti18nText('contract.notification.type.edit.user'),
            dataIndex: 'userPerson',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            userPerson: (value: any) => {
                return value ? value?.firstName + ' ' + value?.lastName : '';
            },
        },
    ];
};

export default ContractNotificationsIndex;
