import { NyDataEdit, NyDatePicker, NyInputNumber, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Switch, Tag, Typography } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    customRenderTextOption,
    customRenderNameOption,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    setDateFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import QualificationLevelIndex from '../qualification-level';
import QualificationLevelEdit from '../qualification-level/edit';
import EducationalProgramIndex from '../educational-program';
import EducationalProgramEdit from '../educational-program/edit';
import HumanCodebooksSearch from '../../../../components/human-codebooks/search';
import useEnum from '../../../../hooks/useEnum';
import CountryIndex from '../../../administration/views/country';
import CountryEdit from '../../../administration/views/country/edit';
import SettlementIndex from '../../../administration/views/settlement';
import SettlementEdit from '../../../administration/views/settlement/edit';
import TextArea from 'antd/lib/input/TextArea';
import EducationalInstitutionIndex from '../educational-institution';
import EducationalInstitutionEdit from '../educational-institution/edit';
import EducationalInstitutionSearch from '../educational-institution/search';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
const { Title, Text } = Typography;

const FormalEducationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState<any>(geti18nText('formal.education.table.new'));
    const [loading, setLoading] = useState(false);
    const [educationalTitle, setEducationalTitle] = useState<any>('');
    const [educationalTitleShort, setEducationalTitleShort] = useState<any>('');

    const [educationalProgramId, setEducationalProgramId] = useState<any>();
    const [educationLevelId, setEducationLevelId] = useState<any>(-1);
    const [educationalInstitutionId, setEducationalInstitutionId] = useState<any>(-1);
    const [qualificationLevelGroupId, setQualificationLevelGroupId] = useState<any>(-1);
    const [refresh, setRefresh] = useState<any>(0);
    const { id } = useParams<any>();

    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();

    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    function setValues(dataForm: any, isForFormMerge?: boolean) {
        let filteredMergedData: any = {};

        if (isForFormMerge) {
            const oldFormData = form.getFieldsValue();
            const mergedData = { ...form.getFieldsValue(), ...dataForm };

            Object.keys(mergedData).forEach((key) => {
                if (mergedData[key] !== null && mergedData[key] !== undefined) {
                    filteredMergedData[key] = mergedData[key];
                }
            });
        } else {
            filteredMergedData = dataForm;
        }
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (filteredMergedData?.completionDate) {
            filteredMergedData.completionDate = setDateFormat(filteredMergedData.completionDate);
        }

        if (filteredMergedData?.academicTitle) {
            filteredMergedData.academicTitle = setSearchFormat(
                filteredMergedData.academicTitle,
                'name',
                'name',
                'code'
            );
        }

        if (filteredMergedData?.qualificationLevelGroup) {
            filteredMergedData?.qualificationLevelGroupId?.id != undefined &&
                setQualificationLevelGroupId(filteredMergedData?.qualificationLevelGroupId?.id);
            filteredMergedData.qualificationLevelGroup = setSearchFormat(
                filteredMergedData.qualificationLevelGroup,
                'name',
                'name',
                'code'
            );
        } else if (filteredMergedData?.qualificationLevel?.qualificationLevelGroup) {
            filteredMergedData?.qualificationLevelGroupId?.id != undefined &&
                setQualificationLevelGroupId(filteredMergedData?.qualificationLevel?.qualificationLevelGroup?.id);
            filteredMergedData.qualificationLevelGroup = setSearchFormat(
                filteredMergedData.qualificationLevel.qualificationLevelGroup,
                'name',
                'name',
                'code'
            );
        }

        if (filteredMergedData?.qualificationLevel) {
            filteredMergedData.qualificationLevel = setSearchFormat(
                filteredMergedData.qualificationLevel,
                'name',
                'name'
            );
        }

        if (filteredMergedData?.completionCountry && !isForFormMerge) {
            filteredMergedData.completionCountry = setSearchFormat(
                filteredMergedData.completionCountry,
                'name',
                'text'
            );
        }

        if (filteredMergedData?.completionSettlement && !isForFormMerge) {
            filteredMergedData.completionSettlement = setSearchFormat(
                filteredMergedData.completionSettlement,
                'name',
                'text'
            );
        }

        if (filteredMergedData?.educationalProgram?.educationalTitle) {
            setEducationalTitle(filteredMergedData?.educationalProgram?.educationalTitle);
            filteredMergedData.educationalTitle = filteredMergedData?.educationalProgram?.educationalTitle;
            setEducationalTitleShort(filteredMergedData?.educationalProgram?.educationalTitle?.shortName);
        } else if (filteredMergedData?.educationalTitle) {
            setEducationalTitle(filteredMergedData?.educationalTitle);

            filteredMergedData.educationalTitle = filteredMergedData?.educationalTitle;
            setEducationalTitleShort(filteredMergedData?.educationalTitle?.shortName);
        }

        if (filteredMergedData?.educationalProgram) {
            setEducationalProgramId(filteredMergedData.educationalProgram.id);
            filteredMergedData.educationalProgram = setSearchFormat(
                filteredMergedData.educationalProgram,
                'name',
                'name'
            );
        }

        if (filteredMergedData?.educationalInstitution?.educationLevel) {
            setEducationLevelId(filteredMergedData.educationalInstitution.educationLevel.id);
            filteredMergedData.educationLevel = setSearchFormat(
                filteredMergedData.educationalInstitution.educationLevel,
                'name',
                'name'
                //  'code'
            );
        }

        if (filteredMergedData?.educationalInstitution) {
            setEducationalInstitutionId(filteredMergedData.educationalInstitution.id);
            filteredMergedData.educationalInstitution = setSearchFormat(
                filteredMergedData.educationalInstitution,
                'name',
                'name'
            );
        }

        if (filteredMergedData?.employee) {
            filteredMergedData.employee = getEmployeeSelectOption(filteredMergedData.employee);
        }

        if (filteredMergedData?.hasOwnProperty('id')) {
            setEditHeader(geti18nText('formal.education.tab'));
        }

        delete filteredMergedData.active;
        form.setFieldsValue(filteredMergedData);
    }

    const onEducationalProgramChange = (e: any) => {
        e?.id == '' && e?.name == '' && setEducationalTitleShort(undefined);
        if (e?.id == -1) {
            form.setFieldsValue({
                qualificationLevel: undefined,
            });

            form.setFieldsValue({
                qualificationLevelGroup: undefined,
            });

            form.setFieldsValue({
                ects: undefined,
            });
            setEducationalTitle(undefined);

            e?.name?.props &&
                e?.name?.props['custom-data']?.educationalTitle.shortName != '' &&
                setEducationalTitleShort(undefined);
        }

        if (e == null) {
            return;
        }
        setEducationalProgramId(e.id);
        if (e.id != -1 && e?.name?.props && e?.name?.props['custom-data'] != undefined) {
            setEducationalTitle(e?.name?.props['custom-data']?.educationalTitle);
            e?.id != '' &&
                e?.name != '' &&
                e?.name?.props['custom-data']?.educationalTitle.shortName &&
                setEducationalTitleShort(e?.name?.props['custom-data']?.educationalTitle.shortName);
            form.setFieldsValue({ ects: e?.name?.props['custom-data']?.ects });
            form.setFieldsValue({
                educationalInstitution: setSearchFormat(
                    e?.name?.props['custom-data']?.educationalInstitution,
                    'name',
                    'name',
                    'code'
                ),
            });
            e?.name?.props['custom-data']?.educationLevel != '' &&
                form.setFieldsValue({
                    educationLevel: setSearchFormat(
                        e?.name?.props['custom-data']?.educationLevel,
                        'name',
                        'name'
                        //    'code'
                    ),
                });
            e?.name?.props['custom-data']?.qualificationLevelGroup != '' &&
                form.setFieldsValue({
                    qualificationLevelGroup: setSearchFormat(
                        e?.name?.props['custom-data']?.qualificationLevelGroup,
                        'name',
                        'name'
                        //  'code'
                    ),
                });
            e?.name?.props['custom-data']?.qualificationLevel != '' &&
                form.setFieldsValue({
                    qualificationLevel: setSearchFormat(
                        e?.name?.props['custom-data']?.qualificationLevel,
                        'name',
                        'name'
                    ),
                });
        }
    };

    const onQualificationLevelChange = (e: any) => {
        if (e == null) {
            return;
        }

        if (e.id != -1 && e?.name?.props && e?.name?.props['custom-data']) {
            form.setFieldsValue({
                qualificationLevelGroup: setSearchFormat(
                    e?.name?.props['custom-data']?.qualificationLevelGroup,
                    'name',
                    'name'
                    //  'code'
                ),
            });
        }
    };

    const onQualificationLevelGroupChange = (e: any) => {
        if (e == null) {
            return;
        }

        setQualificationLevelGroupId(e.id);
        form.setFieldsValue({
            qualificationLevel: undefined,
        });
    };

    const onEducationalInstitutionChange = (e: any) => {
        if (e == null) {
            return;
        }

        setEducationalTitle(undefined);
        setEducationalTitleShort(undefined);
        setEducationalInstitutionId(e.id);
        if (e.id != -1 && e?.name?.props && e?.name?.props['custom-data']) {
            form.setFieldsValue({ educationalInstituion: { id: e?.id, name: e?.name?.props['custom-data']?.name } });
            form.setFieldsValue({
                educationLevel: setSearchFormat(e?.name?.props['custom-data']?.educationLevel, 'name', 'name', 'code'),
            });
        }
        form.setFieldsValue({ educationalProgram: undefined });
    };

    const onEducationLevelChange = (e: any) => {
        if (e == null) {
            return;
        }

        setEducationLevelId(e.id);
        setEducationalInstitutionId(-1);
        setEducationalTitle(undefined);
        setEducationalTitleShort(undefined);
        form.setFieldsValue({
            educationalInstitution: undefined,
        });
        form.setFieldsValue({
            educationalProgram: undefined,
        });
        form.setFieldsValue({
            qualificationLevel: undefined,
        });
        form.setFieldsValue({
            qualificationLevelGroup: undefined,
        });
        form.setFieldsValue({
            ects: undefined,
        });
    };

    const setEducationalInstitutionFilterValue = () => {
        if (educationLevelId != undefined && educationLevelId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'educationLevelId', condition: 'equals', value: educationLevelId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const setQualificationLevelFilterValue = () => {
        if (qualificationLevelGroupId != undefined && qualificationLevelGroupId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'qualificationLevelGroupId', condition: 'equals', value: qualificationLevelGroupId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const setEducationalProgramFilterValue = () => {
        if (educationalInstitutionId != undefined && educationalInstitutionId != -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'educationalInstitutionId', condition: 'equals', value: educationalInstitutionId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('menu.professional.qualification'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            ref={focusInput}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.FORMAL_EDUCATION.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            {...props}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (values.completionDate) values.completionDate = getDateFormat(values.completionDate, 'YYYY-MM-DD');

                if (values.academicTitle) {
                    values.academicTitle = getSearchFormat(values.academicTitle);
                }
                if (values.latest === null || values.latest === undefined) {
                    values.latest = false;
                }

                if (values.qualificationLevel) {
                    values.qualificationLevel = getSearchFormat(values.qualificationLevel);
                }

                if (values.completionCountry) {
                    values.completionCountry = getSearchFormat(values.completionCountry);
                }

                if (values.completionSettlement) {
                    values.completionSettlement = getSearchFormat(values.completionSettlement);
                }

                if (values.qualificationLevel) {
                    values.qualificationLevel = getSearchFormat(values.qualificationLevel);
                }

                if (values.educationalProgram) {
                    values.educationalProgram = getSearchFormat(values.educationalProgram);
                    values.educationalProgram.educationalTitle = values.educationalTitle;
                }

                if (values.educationalSubProgram) {
                    values.educationalSubProgram = getSearchFormat(values.educationalSubProgram);
                }

                if (values.educationalInstitution) {
                    values.educationalInstitution = getSearchFormat(values.educationalInstitution);
                }

                if (values.ects) {
                    values.ects = Number(values.ects);
                }

                values.employee = { id: id };

                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }

                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={false}
                        label={geti18nText('formal.education.edit.educational.institution.group.and.name')}
                        codebooksType={enCodebookType.EDUCATION_LEVEL}
                        formItemName={'educationLevel'}
                        customItemNameLabel={'name'}
                        onChange={onEducationLevelChange}
                        renderOption={(option: any) => `${option.text}`}
                    />
                </Col>
                <Col span={12}>
                    <EducationalInstitutionSearch
                        style={{ width: '100%' }}
                        map={{
                            id: 'id',
                            label: 'name',
                            name: 'name',
                            educationLevel: 'educationLevel',
                        }}
                        renderOption={customRenderNameOption}
                        required={true}
                        onChange={onEducationalInstitutionChange}
                        setDefaultFilterValue={setEducationalInstitutionFilterValue}
                        SearchPopupComponent={
                            <EducationalInstitutionIndex
                                educationLevelId={educationLevelId}
                                addedData={{
                                    form: form,
                                    setMainFormValues: setValues,
                                    refresh: refresh,
                                    setRefresh: setRefresh,
                                }}
                            />
                        }
                        AddNewModalComponent={EducationalInstitutionEdit}
                        AddNewModalComponentAddedData={{
                            form: form,
                            setMainFormValues: setValues,
                            refresh: refresh,
                            setRefresh: setRefresh,
                        }}
                        customListWidth={'1400px'}
                        dropdownMatchSelectWidth={false}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>

                <Col span={24}>
                    <Form.Item
                        label={geti18nText('formal.education.edit.educational.program')}
                        name="educationalProgram"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EDUCATIONAL_PROGRAM.SEARCH}
                            map={{
                                id: 'id',
                                label: 'name',
                                name: 'name',
                                code: 'code',
                                educationalTitle: 'educationalTitle',
                                ects: 'ects',
                                educationalInstitution: 'educationalInstitution',
                                educationLevel: 'educationLevel',
                                qualificationLevel: 'qualificationLevel',
                                qualificationLevelGroup: 'qualificationLevelGroup',
                            }}
                            searchBy="name"
                            onChange={onEducationalProgramChange}
                            setDefaultFilterValue={setEducationalProgramFilterValue}
                            renderOption={customRenderNameOption}
                            SearchPopupComponent={
                                <EducationalProgramIndex
                                    educationalInstitutionId={educationalInstitutionId}
                                    addedData={{
                                        form: form,
                                        setMainFormValues: setValues,
                                        refresh: refresh,
                                        setRefresh: setRefresh,
                                    }}
                                />
                            }
                            AddNewModalComponent={EducationalProgramEdit}
                            addedData={{
                                educationalInstitutionId: educationalInstitutionId,
                            }}
                            AddNewModalComponentAddedData={{
                                form: form,
                                setMainFormValues: setValues,
                                refresh: refresh,
                                setRefresh: setRefresh,
                            }}
                            customListWidth={'1400px'}
                            dropdownMatchSelectWidth={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <HumanCodebooksSearch
                        label={geti18nText('formal.education.edit.qualificationLevelGroup')}
                        codebooksType={enCodebookType.QUALIFICATION_LEVEL_GROUP}
                        formItemName={'qualificationLevelGroup'}
                        customItemNameLabel={'name'}
                        renderOption={(option: any) => `${option.text}`}
                        map={{ id: 'id', label: 'name', text: 'name' }}
                        onChange={onQualificationLevelGroupChange}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('educational.program.edit.qualification.level')}
                        name={'qualificationLevel'}
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.QUALIFICATION_LEVEL.SEARCH}
                            map={{
                                id: 'id',
                                label: 'name',
                                name: 'name',
                                qualificationLevelGroup: 'qualificationLevelGroup',
                            }}
                            customItemNameLabel={'name'}
                            searchBy="name"
                            SearchPopupComponent={
                                <QualificationLevelIndex
                                    qualificationLevelGroupId={qualificationLevelGroupId}
                                    addedData={{
                                        form: form,
                                        setMainFormValues: setValues,
                                        refresh: refresh,
                                        setRefresh: setRefresh,
                                    }}
                                />
                            }
                            customListWidth={'1400px'}
                            AddNewModalComponent={QualificationLevelEdit}
                            setDefaultFilterValue={setQualificationLevelFilterValue}
                            renderOption={customRenderNameOption}
                            onChange={onQualificationLevelChange}
                            AddNewModalComponentAddedData={{
                                form: form,
                                setMainFormValues: setValues,
                                refresh: refresh,
                                setRefresh: setRefresh,
                            }}
                            nyTestId="qualificationLevel"
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}></Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('formal.education.edit.educational.title.short')}
                        name={'educationalTitleShort'}
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Text strong>{educationalTitleShort}</Text>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('educational.program.edit.educational.title')}
                        name={'educationalTitle'}
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Text strong>{educationalTitle?.name}</Text>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={false}
                        label={geti18nText('formal.education.edit.academic.title')}
                        codebooksType={enCodebookType.ACADEMIC_TITLE}
                        formItemName={'academicTitle'}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('qualification.level.edit.ects')}
                        name="ects"
                        rules={[
                            {
                                required: false,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {/* !!! ostaviti - zakomentirano prema tasku F13698 !!!  <Col span={12}>
                    <Form.Item label={geti18nText('formal.education.edit.graduation.thesis')} name="graduationThesis">
                        <TextArea rows={4} style={{ width: '100%' }} autoSize={{ minRows: 4, maxRows: 4 }}></TextArea>
                    </Form.Item>
                </Col> */}
                {/*  <Col span={12}> */}
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('formal.education.edit.gpa')}
                        // !!! ostaviti - zakomentirano prema tasku F13698 !!! style={{ marginLeft: '-12px', marginRight: '-12px' }}
                        name="gpa"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyInputNumber
                            max={5}
                            isDecimal={true}
                            decimalPlaces={2}
                            style={{ width: '100%' }}
                            nyTestId="amount"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('formal.education.edit.completion.date')}
                        name="completionDate"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        /* !!! ostaviti - zakomentirano prema tasku F13698 !!!  style={{
                            marginLeft: '-12px',
                            marginRight: '-12px',
                            marginTop: '-8px',
                        }} */
                    >
                        <NyDatePicker style={{ width: '100%' }} nyTestId="completion-date" format="DD.MM.YYYY" />
                    </Form.Item>
                </Col>
                {/*  </Col> */}
            </Row>
            <Row gutter={24}>
                {/* !!! ostaviti - zakomentirano prema tasku F13698 !!!  <Col span={12}>
                      <Form.Item
                        label={geti18nText('formal.education.edit.completion.country.title')}
                        name={'completionCountry'}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.COUNTRY.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<CountryIndex />}
                            AddNewModalComponent={CountryEdit}
                            nyTestId="completion-country"
                        />
                    </Form.Item> *
                </Col> */}

                {/*  !!! ostaviti - zakomentirano prema tasku F13698 !!!     <Col span={12}>
                        <Form.Item
                        label={geti18nText('company.edit.settlement')}
                        name={'completionSettlement'}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.SETTLEMENT.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<SettlementIndex />}
                            AddNewModalComponent={SettlementEdit}
                            nyTestId="settlement"
                        />
                    </Form.Item> 
                </Col> */}
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('formal.education.edit.remark')} name="remark">
                        <TextArea rows={4} style={{ width: '100%' }} autoSize={{ minRows: 4, maxRows: 5 }}></TextArea>
                    </Form.Item>
                </Col>
            </Row>
            <Row
                style={{
                    marginTop: '-8px',
                    marginBottom: '-16px',
                }}
            >
                <Tag
                    style={{
                        display: 'inline-flex',
                        width: 'fit-content',
                    }}
                    color="blue"
                >
                    <Form.Item
                        name="latest"
                        valuePropName="checked"
                        style={{ marginBottom: '0px', paddingBottom: '0px' }}
                    >
                        <Checkbox style={{ marginBottom: '-10px', paddingBottom: '0px' }} />
                    </Form.Item>
                    <div style={{ marginLeft: '4px', paddingRight: '2px', marginTop: '5px' }}>
                        <b>{geti18nText('formal.education.edit.actual')}</b>
                    </div>
                </Tag>
            </Row>
        </NyDataEdit>
    );
};

export default FormalEducationEdit;
