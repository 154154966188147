import { CheckOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Image, Tooltip } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import NyDescription from '../../../../components/ny-description';
import { SortableIndex } from '../../../../components/sortable-list/SortableIndex';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, FILE_FALLBACK } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import TaskTypeCategoryEdit from './edit';

const TaskTypeCategoryIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const table = useTableSettings();
    const enTaskType = useEnum('TASK_TYPE');
    const [sortListModalVisible, setSortListModalVisible] = useState<boolean>(false);
    const [parentCategoryId, setParentCategoryId] = useState(null);
    const [parentCategoryName, setParentCategoryName] = useState(null);

    const closeModal = () => {
        setSortListModalVisible(false);
        setParentCategoryId(null);
        setParentCategoryName(null);
    };

    useHelper('task_service/codebooks/category');

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    const columns = [
        {
            title: geti18nText('task.typeCategory.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('task.typeCategory.table.column.fileId'),
            dataIndex: 'fileId',
            width: '8%',
            render: (fileId: any, record: any) => {
                return (
                    <>
                        {record?.fileId ? (
                            <Image
                                style={{
                                    objectFit: 'cover',
                                    height: '48px',
                                    width: '48px',
                                    borderRadius: '5px',
                                }}
                                preview={true}
                                src={
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    record.fileId +
                                    '?tenant=' +
                                    NySession.getUser().tenantId
                                }
                            />
                        ) : (
                            <Image
                                style={{
                                    objectFit: 'cover',
                                    height: '48px',
                                    width: '48px',
                                    borderRadius: '5px',
                                }}
                                src="error"
                                fallback={FILE_FALLBACK}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: geti18nText('task.typeCategory.table.column.code'),
            dataIndex: 'code',
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('task.typeCategory.table.column.name'),
            dataIndex: 'name',
            width: '25%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('task.typeCategory.table.column.description'),
            dataIndex: 'description',
            width: '35%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record && record.description) {
                    return <NyDescription title={record.description} maxWidth={'450px'} />;
                }
            },
        },
        {
            title: geti18nText('task.typeCategory.table.column.parent'),
            dataIndex: ['parent', 'name'],
            width: '20%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.TASK_TYPE_CATEGORY.SEARCH, 'parent.id', 'name', 'name', {
                id: 'id',
                label: 'name',
                text: 'name',
            }),
        },
        {
            title: geti18nText('task.typeCategory.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
        {
            title: geti18nText('task.typeCategory.table.column.sort'),
            width: '9%',
            align: 'center',
            render: (record: any) => {
                return (
                    <Tooltip title={geti18nText('task.typeCategory.table.column.sort.category')}>
                        <UnorderedListOutlined
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                                setSortListModalVisible(true);
                                setParentCategoryId(record?.parent?.id);
                                setParentCategoryName(record?.parent?.name);
                            }}
                        />
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            <NyDataTable
                nyId="tasks-task-type-category-items-table"
                url={CONSTANTS_REQ.TASK_TYPE_CATEGORY.LIST}
                addNewButtonText={geti18nText('task.typeCategory.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={TaskTypeCategoryEdit}
                editProps={{ scroll: scroll, sortOrder: sortOrder }}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                scroll={scroll}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={!canCreate()}
                shortcuts={true}
                nyTestId="task-type-category-data-table"
                selectOnSave={true}
                headerTitle={geti18nText('menu.task.service.category')}
            />
            {sortListModalVisible && (
                <SortableIndex
                    closeModal={closeModal}
                    code={geti18nText('task.typeCategory.table.column.code')}
                    description={geti18nText('task.typeCategory.table.column.description')}
                    id={geti18nText('task.typeCategory.table.column.id')}
                    name={geti18nText('task.typeCategory.table.column.name')}
                    parentCategoryId={parentCategoryId}
                    parentCategoryName={parentCategoryName}
                    picture={geti18nText('task.typeCategory.table.column.fileId')}
                    sortListModalVisible={sortListModalVisible}
                    taskType={enTaskType.SERVICE}
                    type={'task-type-category'}
                    typeModal="task-type-category"
                    url={CONSTANTS_REQ.TASK_TYPE_CATEGORY.LIST}
                />
            )}
        </>
    );
};

export default TaskTypeCategoryIndex;
