import { ExclamationCircleFilled, FormOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Badge, Checkbox, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { onKeyDownTextAreaCustom } from '../../utils/Utils';

const { TextArea } = Input;

const NyNoteModal = ({
    note = undefined,
    modalTitle = geti18nText('app.default.note'),
    idFieldNamePath = ['note', 'id'],
    noteFieldNamePath = ['note', 'note'],
    noteFieldPriorityPath = ['note', 'priority'],
    className = 'ny-note-modal-icon',
    disabled = false,
    required = false,
}: any) => {
    const noteTextArea = useRef<any>(null);

    const [visible, setVisible] = useState<boolean>(false);
    const [hasNote, setHasNote] = useState<boolean>(note && note.note && note.note.length > 0 ? true : false);
    const [priority, setPriority] = useState<boolean>(true);
    const [important, setImportant] = useState<boolean>(false);
    const themeStorage = JSON.parse(localStorage['persist:root']);

    useEffect(() => {
        if (note?.priority != undefined && note?.priority != null) {
            setPriority(note?.priority == true ? true : false);
        }
    }, [note]);

    useEffect(() => {
        setHasNote(note && note.note && note.note.length > 0 ? true : hasNote ? true : false);
        checkImportant();
    }, [priority, hasNote, note]);

    const checkImportant = () => {
        setImportant(hasNote && priority ? true : false);
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title={geti18nText('app.default.note')}>
                <Badge
                    count={
                        important ? <ExclamationCircleFilled className="ny-blink" style={{ color: '#f5222d' }} /> : 0
                    }
                >
                    <FormOutlined
                        className={className}
                        onClick={(event: any) => {
                            setVisible(true);
                            setTimeout(() => {
                                if (noteTextArea.current) {
                                    noteTextArea.current.focus();
                                }
                            });

                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        style={
                            themeStorage != undefined && JSON.parse(themeStorage.generalSettings).theme === 'dark'
                                ? { color: hasNote ? 'rgb(64, 169, 255)' : 'rgba(255, 255, 255, 0.85)' }
                                : { color: hasNote ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)' }
                        }
                    />
                </Badge>
            </Tooltip>
            <Modal
                visible={visible}
                title={modalTitle}
                onOk={(event: any) => {
                    setVisible(false);
                    event.stopPropagation();
                    event.preventDefault();
                    checkImportant();
                }}
                onCancel={(event: any) => {
                    setVisible(false);
                    event.stopPropagation();
                    event.preventDefault();
                }}
                forceRender={true}
                closable={false}
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={geti18nText('app.default.button.ok')}
            >
                <Row gutter={24}>
                    <Col offset={1} span={22}>
                        <Form.Item
                            name={noteFieldPriorityPath}
                            valuePropName={'checked'}
                            initialValue={true}
                            style={{ marginBottom: '0px' }}
                        >
                            <Checkbox
                                disabled={disabled}
                                onChange={(event: any) => {
                                    setPriority(event.target.checked);
                                }}
                            >
                                {geti18nText('app.default.note.priority')}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col offset={1} span={22}>
                        <Form.Item name={idFieldNamePath} style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('app.default.note')}
                            name={noteFieldNamePath}
                            labelAlign={'left'}
                            rules={[
                                {
                                    required: required,
                                    message: geti18nText('app.default.required'),
                                    whitespace: required,
                                },
                            ]}
                        >
                            <TextArea
                                rows={4}
                                onKeyDown={onKeyDownTextAreaCustom}
                                onBlur={(event: any) => {
                                    setHasNote(event.target.value.length > 0 ? true : false);
                                }}
                                ref={noteTextArea}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default NyNoteModal;
