import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TravelWarrantTypeIndex from '.';
import TravelWarrantTypeEdit from './edit';
import { customRenderTextOption } from '../../../../utils/Utils';

const TravelWarrantTypeSearch = ({
    label = geti18nText('travelWarrantType.edit.title'),
    name = 'travelWarrantType',
    required = false,
    disabled = false,
    onChange = null,
    autoFocus = false,
    style = undefined,
    isSearchTable = false,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.SEARCH}
                map={{
                    id: 'id',
                    label: 'name',
                    text: 'name',
                    includeEducationDate: 'includeEducationDate',
                    description: 'description',
                }}
                searchBy="name"
                itemName={[['name']]}
                style={style}
                customItemNameLabel={'name'}
                renderOption={customRenderTextOption}
                disabled={disabled}
                AddNewModalComponent={TravelWarrantTypeEdit}
                SearchPopupComponent={<TravelWarrantTypeIndex isSearchTable={isSearchTable} />}
                customListWidth={'1200px'}
                onChange={onChange ? onChange : null}
                nyTestId="travel-warrant-type"
                mustGetPopupContainer={false}
                autoFocus={autoFocus}
            />
        </Form.Item>
    );
};

export default TravelWarrantTypeSearch;
