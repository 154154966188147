import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import PayrollBrutTable from './PayrollBrutTable';

const { TabPane } = Tabs;

const PayrollBrutIndex = () => {
    const location = useLocation();

    useHelper('payroll/codebooks/brut');
    return (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky">
                <TabPane tab={geti18nText('payrollBrut.table.payroll')} key="1">
                    <PayrollBrutTable addition={false} scroll={{ y: 580, x: 800 }} />
                </TabPane>
                <TabPane tab={geti18nText('payrollBrut.table.addition')} key="2">
                    <PayrollBrutTable addition={true} scroll={{ y: 580, x: 800 }} />
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
};

export default PayrollBrutIndex;
