import { NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { Form } from 'antd';
import VocationGroupIndex from '.';
import VocationGroupEdit from './edit';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const VocationGroupSearch = ({
    setDefaultFilterValue = undefined,
    name = 'vocationGroup',
    label = geti18nText('vocation.group.edit.title'),
    required = false,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.VOCATION_GROUP.SEARCH}
                map={{ id: 'id', label: 'text', text: 'name' }}
                searchBy="text"
                setDefaultFilterValue={setDefaultFilterValue ? setDefaultFilterValue : undefined}
                nyTestId="vocation"
                AddNewModalComponent={canCreate() ? VocationGroupEdit : undefined}
                SearchPopupComponent={canCreate() ? <VocationGroupIndex customIdWidth={'86px'} /> : undefined}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default VocationGroupSearch;
