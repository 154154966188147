import { LeftCircleOutlined, SafetyOutlined, SearchOutlined, SolutionOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySearchField, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Card, Col, Form, Image, List, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../utils/Constants';
import { customTaskTemplateRenderOption, customTaskTypeCategoryRenderOption } from '../../utils/Utils';
import CreateTaskIndex from '../tasks/views/task-list/CreateTaskIndex';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import TaskTemplateSearch from '../tasks/views/task-template/search';
import { TasksRights } from '../../rights/tasksRights';
import { addHistory } from '../../slices/historySlice';

const { Meta } = Card;

const TaskServiceIndex = (props: any) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const taskTypeEnum = useEnum('TASK_TYPE');
    const [loading, setLoading] = useState<any>(false);
    const [form] = Form.useForm();
    const { collapsed } = useSelector((state: RootState) => state.menu);

    const [visible, setVisible] = useState(false);

    //categories
    const [total, setTotal] = useState<any>(0);
    const [shown, setShown] = useState<any>(0);
    const [loadMore, setLoadMore] = useState<any>(true);
    const [categories, setCategories] = useState<any>([]);
    const [categoryId, setCategoryId] = useState<any>(null);
    //subcategories
    const [subcategoryIds, setSubcategoryIds] = useState<any>(null);
    const [responseDataSubcategoryIds, setResponseDataSubcategoryIds] = useState<any>(null);
    //taskTemplates
    const [taskTemplates, setTaskTemplates] = useState<any>([]);
    const [totalTemplates, setTotalTemplates] = useState<any>(0);
    const [shownTemplates, setShownTemplates] = useState<any>(0);
    const [loadMoreTemplates, setLoadMoreTemplates] = useState<any>(true);
    const [selectedTaskTemplateId, setSelectedTaskTemplateId] = useState<any>(null);
    //pagination
    const initPagination = {
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    };
    const [pagination, setPagination] = useState<any>(initPagination);
    const [paginationTemplates, setPaginationTemplates] = useState<any>(initPagination);

    useEffect(() => {
        dispatch(
            addHistory({
                path: '/task-service',
                i18n: [
                    {
                        key: 'menu.task.service',
                        hasComponent: true,
                    },
                ],
            })
        );
    }, []);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: taskTypeEnum.SERVICE },
        ];
    };

    const setDefaultFilterValueTemplate = () => {
        if (categoryId) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: taskTypeEnum.SERVICE },
                { field: 'taskTypeCategoryId', condition: 'in', value: getTaskTypeCategories(true).toString() },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: taskTypeEnum.SERVICE },
            ];
        }
    };

    const getCategoriesUrl = () => {
        const url = CONSTANTS_REQ.TASK_TYPE_CATEGORY.SEARCH_TASK_TYPE_CATEGORY;
        const urlWithCategoryId = url.replace('{taskCategoryId}', categoryId);
        return urlWithCategoryId;
    };

    const getTaskTypeCategories = (isMainSearch: boolean = false) => {
        let categoryIds: any = [];
        if (categoryId) {
            if (isMainSearch) {
                categoryIds.push(categoryId);
                if (responseDataSubcategoryIds && responseDataSubcategoryIds.length > 0) {
                    responseDataSubcategoryIds.map((subcategoryId: any) => {
                        categoryIds.push(subcategoryId);
                    });
                }
            } else {
                if (subcategoryIds && subcategoryIds.length > 0) {
                    subcategoryIds.map((subcategoryId: any) => {
                        categoryIds.push(subcategoryId);
                    });
                } else {
                    categoryIds.push(categoryId);
                    if (responseDataSubcategoryIds && responseDataSubcategoryIds.length > 0) {
                        responseDataSubcategoryIds.map((subcategoryId: any) => {
                            categoryIds.push(subcategoryId);
                        });
                    }
                }
            }
        }
        return categoryIds;
    };

    useEffect(() => {
        if (shown == total) {
            setLoadMore(false);
        } else {
            setLoadMore(true);
        }
    }, [shown, total]);

    useEffect(() => {
        if (shownTemplates == totalTemplates) {
            setLoadMoreTemplates(false);
        } else {
            setLoadMoreTemplates(true);
        }
    }, [shownTemplates, totalTemplates]);

    useEffect(() => {
        if (categoryId) {
            fetchSubcategories();
            fetchTemplates(paginationTemplates);
        } else {
            fetch(pagination);
        }
    }, [categoryId]);

    useEffect(() => {
        if (categoryId) {
            fetchTemplates(paginationTemplates);
        }
    }, [subcategoryIds]);

    useEffect(() => {
        if (!selectedTaskTemplateId) {
            form.setFieldsValue({ selectedTaskTemplate: null });
        }
    }, [selectedTaskTemplateId]);

    const fetch = (pageable: any) => {
        setCategories([]);
        setLoading(true);
        let defaultFilter = setDefaultFilterValue();
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TYPE_CATEGORY.ROOT_LIST, {
            search: encodeURI(JSON.stringify(defaultFilter)),
            offset: pageable.current ? pageable.current - 1 : pageable.defaultCurrent - 1,
            max: pageable.pageSize ? pageable.pageSize : pagination.defaultPageSize,
            order: 'position',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    const responseData: any = result.data;
                    pageable.total = responseData['totalSize'];
                    pageable.current = responseData['pageNumber'] + 1;
                    pageable.pageSize = responseData['size'];

                    setTotal(responseData['totalSize']);
                    setCategories(responseData.content);
                    setShown(responseData['numberOfElements']);
                    setPagination(pageable);
                }
            }
            setLoading(false);
        });
    };

    const onLoadMore = () => {
        const pageable = { ...pagination };
        pageable.current = pageable.current + 1;
        pageable.pageSize = pagination.defaultPageSize;

        setLoading(true);
        let defaultFilter = setDefaultFilterValue();
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TYPE_CATEGORY.ROOT_LIST, {
            search: encodeURI(JSON.stringify(defaultFilter)),
            offset: pageable.current ? pageable.current - 1 : pageable.defaultCurrent - 1,
            max: pageable.pageSize ? pageable.pageSize : pagination.defaultPageSize,
            order: 'position',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    const responseData: any = result.data;
                    pageable.total = responseData['totalSize'];
                    pageable.current = responseData['pageNumber'] + 1;
                    pageable.pageSize = responseData['size'];

                    setTotal(responseData['totalSize']);
                    setCategories(categories.concat(responseData.content));
                    setShown(categories.length + responseData['numberOfElements']);
                    setPagination(pageable);
                }
            }
            setLoading(false);
        });
    };

    const fetchSubcategories = () => {
        setResponseDataSubcategoryIds(null);
        let defaultFilter = setDefaultFilterValue();
        NyRequestResolver.requestGet(getCategoriesUrl(), {
            search: encodeURI(JSON.stringify(defaultFilter)),
            order: 'position',
            orderType: 'asc',
        }).then(async (result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const responseData: any = result.data;
                const responseDataIds: any = [];
                await responseData.map((responseDataCat: any) => {
                    responseDataIds.push(responseDataCat.id);
                });
                await setResponseDataSubcategoryIds(responseDataIds);
            }
        });
    };

    const fetchTemplates = (pageableTemplates: any) => {
        setTaskTemplates([]);
        setPaginationTemplates(initPagination);
        setLoading(true);
        let defaultFilterTemplates = setDefaultFilterValue();

        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TEMPLATE.LIST_IN_CATEGORIES, {
            search: encodeURI(JSON.stringify(defaultFilterTemplates)),
            offset: pageableTemplates.current ? pageableTemplates.current - 1 : pageableTemplates.defaultCurrent - 1,
            max: pageableTemplates.pageSize ? pageableTemplates.pageSize : paginationTemplates.defaultPageSize,
            order: 'position',
            orderType: 'asc',
            taskTypeCategoryIds: getTaskTypeCategories(),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    const responseData: any = result.data;
                    pageableTemplates.total = responseData['totalSize'];
                    pageableTemplates.current = responseData['pageNumber'] + 1;
                    pageableTemplates.pageSize = responseData['size'];

                    setTotalTemplates(responseData['totalSize']);
                    setTaskTemplates(responseData.content);
                    setShownTemplates(responseData['numberOfElements']);
                    setPaginationTemplates(pageableTemplates);
                }
            }
            setLoading(false);
        });
    };

    const onLoadMoreTemplates = () => {
        const pageableTemplates = { ...paginationTemplates };
        pageableTemplates.current = pageableTemplates.current + 1;
        pageableTemplates.pageSize = paginationTemplates.defaultPageSize;

        setLoading(true);
        let defaultFilterTemplates = setDefaultFilterValue();
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK_TEMPLATE.LIST_IN_CATEGORIES, {
            search: encodeURI(JSON.stringify(defaultFilterTemplates)),
            offset: pageableTemplates.current ? pageableTemplates.current - 1 : pageableTemplates.defaultCurrent - 1,
            max: pageableTemplates.pageSize ? pageableTemplates.pageSize : paginationTemplates.defaultPageSize,
            order: 'position',
            orderType: 'asc',
            taskTypeCategoryIds: getTaskTypeCategories(),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    const responseData: any = result.data;
                    pageableTemplates.total = responseData['totalSize'];
                    pageableTemplates.current = responseData['pageNumber'] + 1;
                    pageableTemplates.pageSize = responseData['size'];

                    setTotalTemplates(responseData['totalSize']);
                    setTaskTemplates(taskTemplates.concat(responseData.content));
                    setShownTemplates(taskTemplates.length + responseData['numberOfElements']);
                    setPaginationTemplates(pageableTemplates);
                }
            }
            setLoading(false);
        });
    };

    const onChangeSubcategory = (value: any) => {
        let subcategoriesIds: any = null;
        if (value && value.length > 0) {
            subcategoriesIds = value.map((val: any) => val.id);
        }
        setSelectedTaskTemplateId(null);
        form.setFieldsValue({ selectedTaskTemplate: '' });
        setSubcategoryIds(subcategoriesIds);
    };

    const onChangeTemplate = (value: any) => {
        setSelectedTaskTemplateId(value && value.id > 0 ? value.id : null);
        setVisible(true);
    };

    const resetForm = () => {
        setCategoryId(null);
        setSubcategoryIds(null);
        setSelectedTaskTemplateId(null);
        setResponseDataSubcategoryIds(null);
        form.resetFields();
    };

    return (
        <>
            <div style={{ minHeight: '700px', marginTop: '20px' }}>
                <Form form={form}>
                    <Row gutter={24} style={{ marginBottom: '10px' }}>
                        <Col span={2}>
                            {categoryId && (
                                <Tooltip title={geti18nText('meals.back')}>
                                    <LeftCircleOutlined
                                        onClick={resetForm}
                                        style={{ margin: '10px', fontSize: '30px', float: 'right' }}
                                    />
                                </Tooltip>
                            )}
                        </Col>
                        <Col span={20}>
                            <Row gutter={24} justify="space-between" style={{ flexWrap: 'nowrap' }}>
                                <div style={{ width: '100%' }}>
                                    <TaskTemplateSearch
                                        name="selectedTaskTemplate"
                                        placeholder={
                                            <>
                                                <SearchOutlined /> {geti18nText('app.default.search')}
                                            </>
                                        }
                                        setDefaultFilterValue={setDefaultFilterValueTemplate}
                                        order={'name'}
                                        addNewItem={false}
                                        bordered={false}
                                        size={'large'}
                                        offsetToFetchData={true}
                                        className="ny-select"
                                        style={{
                                            position: 'sticky',
                                            zIndex: selectedTaskTemplateId ? '' : '999',
                                            marginLeft: '40px',
                                            marginRight: '20px',
                                        }}
                                        onChange={onChangeTemplate}
                                        renderOption={customTaskTemplateRenderOption}
                                        customItemNameLabel={'name code description fileId'}
                                    />
                                </div>
                                <Tooltip title={geti18nText('task.edit.edit.myTasks')}>
                                    <SolutionOutlined
                                        onClick={() => {
                                            history.push('/my-task-list');
                                            dispatch(
                                                addHistory({
                                                    path: '/my-task-list',
                                                    i18n: [
                                                        {
                                                            key: 'task.edit.edit.myTasks',
                                                            hasComponent: true,
                                                        },
                                                    ],
                                                })
                                            );
                                        }}
                                        style={{ fontSize: '62px', marginRight: '40px', marginTop: '-25px' }}
                                    />
                                </Tooltip>
                                {TasksRights.canViewTaskApproval() && (
                                    <Tooltip title={geti18nText('tasks.list.radioButton.4')}>
                                        <SafetyOutlined
                                            onClick={() => {
                                                history.push('/approval-task-list');
                                                dispatch(
                                                    addHistory({
                                                        path: '/approval-task-list',
                                                        i18n: [
                                                            {
                                                                key: 'tasks.list.radioButton.4',
                                                                hasComponent: true,
                                                            },
                                                        ],
                                                    })
                                                );
                                            }}
                                            style={{ fontSize: '62px', marginRight: '40px', marginTop: '-25px' }}
                                        />
                                    </Tooltip>
                                )}
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    {categoryId ? (
                                        <>
                                            <Form.Item name="subcategories">
                                                <NySearchField
                                                    url={getCategoriesUrl()}
                                                    map={{
                                                        id: 'id',
                                                        name: 'name',
                                                        code: 'code',
                                                        description: 'description',
                                                        fileId: 'fileId',
                                                    }}
                                                    searchBy="name"
                                                    placeholder={
                                                        <>
                                                            <SearchOutlined /> {geti18nText('task.typeCategory.search')}
                                                        </>
                                                    }
                                                    setDefaultFilterValue={setDefaultFilterValue}
                                                    order={'position'}
                                                    addNewItem={false}
                                                    bordered={true}
                                                    size={'large'}
                                                    mode={'multiple'}
                                                    className=""
                                                    style={{
                                                        width: '100%',
                                                        position: 'sticky',
                                                        top: '40px',
                                                        fontSize: '18px',
                                                        margin: '30px',
                                                        zIndex: selectedTaskTemplateId ? '' : '99',
                                                    }}
                                                    onChange={onChangeSubcategory}
                                                    itemName={[
                                                        ['task_type_category', 'name'],
                                                        ['task_type_category', 'code'],
                                                    ]}
                                                    renderOption={customTaskTypeCategoryRenderOption}
                                                    customItemNameLabel={'name code description fileId'}
                                                />
                                            </Form.Item>
                                            {categoryId && taskTemplates && taskTemplates.length > 0 && (
                                                <div
                                                    style={{
                                                        height: '500px',
                                                        overflowY: 'auto',
                                                        margin: '30px',
                                                    }}
                                                >
                                                    <List
                                                        loading={loading}
                                                        dataSource={taskTemplates}
                                                        renderItem={(template: any) => (
                                                            <a>
                                                                <List.Item
                                                                    key={template.id}
                                                                    style={{ width: '100%' }}
                                                                    onClick={() => {
                                                                        onChangeTemplate(template);
                                                                    }}
                                                                >
                                                                    {customTaskTemplateRenderOption(template)}
                                                                </List.Item>
                                                            </a>
                                                        )}
                                                    />
                                                    <Row
                                                        gutter={24}
                                                        style={{ position: 'absolute', bottom: '0', width: '100%' }}
                                                    >
                                                        <Col span={11}> </Col>
                                                        <Col span={13}>
                                                            <Button
                                                                type="primary"
                                                                onClick={() => onLoadMoreTemplates()}
                                                                hidden={!loadMoreTemplates}
                                                            >
                                                                {geti18nText('app.default.load.more')}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <List
                                                grid={{
                                                    gutter: 22,
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 3,
                                                    lg: 4,
                                                    xl: 4,
                                                    xxl: 4,
                                                }}
                                                loading={loading}
                                                dataSource={categories}
                                                renderItem={(category: any) => (
                                                    <List.Item
                                                        key={category.id}
                                                        style={{ margin: 30, textAlign: 'center' }}
                                                    >
                                                        <Tooltip title={category.description}>
                                                            <Card
                                                                hoverable
                                                                onClick={() => setCategoryId(category.id)}
                                                                cover={
                                                                    <>
                                                                        {category.fileId ? (
                                                                            <Image
                                                                                style={{
                                                                                    objectFit: 'cover',
                                                                                    border: '1px solid #f0f0f0',
                                                                                }}
                                                                                height="150px"
                                                                                preview={false}
                                                                                src={
                                                                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                                    '/' +
                                                                                    category.fileId +
                                                                                    '?tenant=' +
                                                                                    NySession.getUser().tenantId
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <Image
                                                                                style={{
                                                                                    objectFit: 'cover',
                                                                                    border: '1px solid #f0f0f0',
                                                                                }}
                                                                                height="150px"
                                                                                src="error"
                                                                                preview={false}
                                                                                fallback={FILE_FALLBACK}
                                                                            />
                                                                        )}
                                                                    </>
                                                                }
                                                            >
                                                                <Meta
                                                                    title={
                                                                        <div className="inline">
                                                                            <div className="task-service-card-title">
                                                                                <div className="wrap">
                                                                                    <p>{category.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                />
                                                            </Card>
                                                        </Tooltip>
                                                    </List.Item>
                                                )}
                                            />
                                            <Row
                                                gutter={24}
                                                style={{ position: 'absolute', bottom: '0', width: '100%' }}
                                            >
                                                <Col span={11}> </Col>
                                                <Col span={13}>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => onLoadMore()}
                                                        hidden={!loadMore}
                                                    >
                                                        {geti18nText('app.default.load.more')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {selectedTaskTemplateId && (
                        <CreateTaskIndex
                            selectedTaskTemplateId={selectedTaskTemplateId}
                            setSelectedTaskTemplateId={setSelectedTaskTemplateId}
                            visible={visible}
                            setVisible={setVisible}
                            isTemplateView
                        />
                    )}
                </Form>
            </div>
        </>
    );
};
export default TaskServiceIndex;
