import { createSlice } from '@reduxjs/toolkit';

interface IEmployeeGroupSlice {
    refreshTable: number;
}

const initialState: IEmployeeGroupSlice = {
    refreshTable: 0,
};

const employeeGroupSlice = createSlice({
    name: 'employeeGroupSlice',
    initialState,
    reducers: {
        employeeGroupInit(state) {
            state.refreshTable = 0;
        },
        refreshEmployeeGroupTable(state) {
            state.refreshTable++;
        },
    },
});

export const { employeeGroupInit, refreshEmployeeGroupTable } = employeeGroupSlice.actions;

export default employeeGroupSlice.reducer;
