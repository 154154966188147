import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NySession,
    NyUtils,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Dropdown, Menu } from 'antd';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { numberFormat } from '../../../../utils/Utils';
import WarehouseItemReportModal from './WarehouseItemReportModal';
import WarehouseItemEdit from './edit';
import { WarehouseBookkeepingRights } from '../../../../rights/warehouseBookkeepingRights';

const WarehouseItemIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();

    useHelper('warehouse/warehouse_item');

    const canCreate = () => {
        return WarehouseBookkeepingRights.canCreateWarehouseItem();
    };

    const canExportCSV = () => {
        return WarehouseBookkeepingRights.canCsvExportWarehouseItem();
    };

    const columns = [
        {
            title: geti18nText('warehouseItem.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItem.table.column.warehouseId'),
            dataIndex: ['warehouse', 'code'],
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.WAREHOUSE.SEARCH, 'warehouse.id', 'code', 'code', {
                id: 'id',
                label: 'code',
                text: 'code',
            }),
        },
        {
            title: geti18nText('warehouseItem.table.column.code'),
            dataIndex: ['item', 'code'],
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseItem.table.column.name'),
            dataIndex: ['item', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseItem.table.column.abbreviation'),
            dataIndex: ['measureUnit', 'abbreviation'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('warehouseItem.table.column.quantity'),
            dataIndex: 'quantity',
            align: 'right',
            width: '15%',
            sorter: (a: any, b: any) => {},
            render: (quantity: any) => {
                if (quantity) {
                    return <div style={{ float: 'right' }}>{numberFormat(quantity, 4)}</div>;
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('warehouseItem.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="warehouse-warehouse-items"
            url={CONSTANTS_REQ.WAREHOUSE_ITEM.LIST}
            addNewButtonText={geti18nText('warehouseItem.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={WarehouseItemEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            readonly={disabled}
            addedButtons={addedButtons}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.warehouseItem')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.warehouseItem')}
        />
    );
};

const addedButtons = (onSave: () => void) => {
    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <WarehouseItemReportModal />
                </div>
            </div>
        </Menu>
    );

    return (
        <div style={{ float: 'left', paddingLeft: '15px' }}>
            <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                <Button type="primary">
                    {geti18nText('app.default.actions')}
                    <EllipsisOutlined />
                </Button>
            </Dropdown>
        </div>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            quantity: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 4);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default WarehouseItemIndex;
