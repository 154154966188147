import { FixedAssetRights } from '../../rights/fixedAssetRights';
import AccountIndex from './views/account';
import AccountingEntityAccountIndex from './views/accounting-entity-account';
import AssetAcquisitionIndex from './views/assets/asset-acquisition';
import AssetAcquisitionEdit from './views/assets/asset-acquisition/edit';
import AssetAdpIndex from './views/assets/asset-adp';
import AssetDepreciationIndex from './views/assets/asset-depreciation';
import DepreciationGroupIndex from './views/assets/asset-depreciation-group';
import AssetDepreciationEdit from './views/assets/asset-depreciation/edit';
import AssetInitialStateIndex from './views/assets/asset-initial-state';
import AssetInitialStateEdit from './views/assets/asset-initial-state/edit';
import AssetRentalIndex from './views/assets/asset-rental-release';
import AssetWriteOffIndex from './views/assets/asset-write-off';
import AssetWriteOffEdit from './views/assets/asset-write-off/edit';
import FixedAssetIndex from './views/assets/fixed-asset';
import FixedAssetEdit from './views/assets/fixed-asset/edit';
import CostCenterIndex from './views/cost-center';
import CostObjectIndex from './views/cost-object';
import PaymentOrdersGroupIndex from './views/payment-orders/payment-orders-group';
import PaymentOrdersIndividualIndex from './views/payment-orders/payment-orders-individual';
import PaymentOrdersInitializationIndex from './views/payment-orders/payment-orders-initialization';

export const AccountingModuleRoutes: any = [
    {
        path: '/accounting',
        exact: true,
        component: () => <></>,
        role: FixedAssetRights.canViewCodebooksCompanyRoutes(),
    },
    {
        path: '/accounting/assets/fixed-asset',
        exact: true,
        component: FixedAssetIndex,
        role: FixedAssetRights.canViewCodebooksAssetRoutes(),
    },
    {
        path: '/accounting/assets/fixed-asset/:id',
        exact: true,
        component: FixedAssetEdit,
        role: FixedAssetRights.canViewCodebooksAssetRoutes(),
    },
    {
        path: '/accounting/assets/asset-acquisition',
        exact: true,
        component: AssetAcquisitionIndex,
        role: FixedAssetRights.canViewAcquisitionRoutes(),
    },
    {
        path: '/accounting/assets/asset-acquisition/:id',
        exact: true,
        component: AssetAcquisitionEdit,
        role: FixedAssetRights.canViewAcquisitionRoutes(),
    },
    {
        path: '/accounting/assets/asset-initial-state',
        exact: true,
        component: AssetInitialStateIndex,
        role: FixedAssetRights.canViewInitialStateRoutes(),
    },
    {
        path: '/accounting/assets/asset-initial-state/:id',
        exact: true,
        component: AssetInitialStateEdit,
        role: FixedAssetRights.canViewInitialStateRoutes(),
    },
    {
        path: '/accounting/assets/asset-write-off',
        exact: true,
        component: AssetWriteOffIndex,
        role: FixedAssetRights.canViewWriteOffRoutes(),
    },
    {
        path: '/accounting/assets/asset-write-off/:id',
        exact: true,
        component: AssetWriteOffEdit,
        role: FixedAssetRights.canViewWriteOffRoutes(),
    },
    {
        path: '/accounting/assets/asset-depreciation',
        exact: true,
        component: AssetDepreciationIndex,
        role: FixedAssetRights.canViewDepreciationRoutes(),
    },
    {
        path: '/accounting/assets/asset-depreciation/:id',
        exact: true,
        component: AssetDepreciationEdit,
        role: FixedAssetRights.canViewDepreciationRoutes(),
    },
    {
        path: '/accounting/paymentOrders/paymentOrdersIndividual',
        exact: true,
        component: PaymentOrdersIndividualIndex,
        role: FixedAssetRights.canViewPaymentOrderRoutes(),
    },
    {
        path: '/accounting/paymentOrders/paymentOrdersInitialization',
        exact: true,
        component: PaymentOrdersInitializationIndex,
        role: FixedAssetRights.canViewPaymentOrderRoutes(),
    },
    {
        path: '/accounting/paymentOrders/paymentOrdersGroup',
        exact: true,
        component: PaymentOrdersGroupIndex,
        role: FixedAssetRights.canViewPaymentOrderRoutes(),
    },
    {
        path: '/accounting/codebooks/cost-center',
        exact: true,
        component: CostCenterIndex,
        role: FixedAssetRights.canViewCodebooksRoutes(),
    },
    {
        path: '/accounting/codebooks/cost-object',
        exact: true,
        component: CostObjectIndex,
        role: FixedAssetRights.canViewCodebooksRoutes(),
    },
    {
        path: '/accounting/codebooks/account',
        exact: true,
        component: AccountIndex,
        role: FixedAssetRights.canViewCodebooksRoutes(),
    },
    {
        path: '/accounting/codebooks/accounting-entity-account',
        exact: true,
        component: AccountingEntityAccountIndex,
        role: FixedAssetRights.canViewCodebooksRoutes(),
    },
    {
        path: '/accounting/codebooks/assets/asset-adp',
        exact: true,
        component: AssetAdpIndex,
        role: FixedAssetRights.canViewCodebooksAssetRoutes(),
    },
    {
        path: '/accounting/codebooks/assets/asset-depreciation-group',
        exact: true,
        component: DepreciationGroupIndex,
        role: FixedAssetRights.canViewCodebooksAssetRoutes(),
    },
    {
        path: '/accounting/assets/asset-rental-release',
        exact: true,
        component: AssetRentalIndex,
        role: FixedAssetRights.canViewAssetRent(),
    },
];
