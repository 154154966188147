import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnSearch,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import { getEnumArray, getEnumArrayRetIDValue } from '../../../../utils/Utils';
import AccountingEntityAccountEdit from './edit';

const AccountingEntityAccountIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refId = null,
    refType = null,
    extraRefId = null,
    extraRefType = null,
    isIndex = true,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const location = useLocation();
    const enAccountEntryType = useEnum('ACCOUNT_ENTRY_TYPE');
    const table = useTableSettings();

    useHelper('accounting/codebooks/accountingEntityAccount');

    const canCreate = () => {
        return AdministrationRights.canCreateAccounting();
    };

    const canExportCSV = () => {
        return AdministrationRights.canCsvExportAccounting();
    };

    const showRefGroup = () => {
        return !Object.values(['TRAVEL_WARRANT', 'DEPRECIATION_GROUP']).includes(refType);
    };

    const showRefType = () => {
        return !Object.values(['TRAVEL_WARRANT']).includes(refType);
    };

    const setDefaultFilterValue = () => {
        if (refId != null && refType != null) {
            if (refType == 'TRAVEL_WARRANT' && extraRefType != null) {
                return [
                    { field: extraRefType, condition: 'equals', value: refId },
                    { field: 'active', condition: 'equals_bool', value: 1 },
                ];
            } else {
                if (extraRefId != null && extraRefType != null) {
                    return [
                        { field: refType, condition: 'equals', value: refId },
                        { field: extraRefType, condition: 'equals', value: extraRefId },
                        { field: 'active', condition: 'equals_bool', value: 1 },
                    ];
                } else {
                    return [
                        { field: refType, condition: 'equals', value: refId },
                        { field: 'active', condition: 'equals_bool', value: 1 },
                    ];
                }
            }
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const initialColumns = [
        ...[
            {
                title: geti18nText('accountingEntityAccount.table.column.id'),
                dataIndex: 'id',
                width: '8%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
        ],
        ...(showRefGroup()
            ? [
                  {
                      title: geti18nText('accountingEntityAccount.table.column.group'),
                      dataIndex: 'accountingEntityTypeGroup',
                      width: '18%',
                      render: (text: any, record: any) => {
                          if (record.accountingEntityTypeGroup !== undefined) {
                              return geti18nText(
                                  'app.enum.ACCOUNTING_ENTITY_TYPE_GROUP.' + record.accountingEntityTypeGroup
                              );
                          }
                      },
                      ...getColumnSearchOption(getEnumArray('ACCOUNTING_ENTITY_TYPE_GROUP'), 'equals'),
                  },
              ]
            : []),
        ...[],
        ...(showRefType()
            ? [
                  {
                      title: geti18nText('accountingEntityAccount.table.column.type'),
                      dataIndex: 'accountingEntityType',
                      width: '16%',
                      render: (text: any, record: any) => {
                          if (record.accountingEntityType !== undefined) {
                              return geti18nText('app.enum.ACCOUNTING_ENTITY_TYPE.' + record.accountingEntityType);
                          }
                      },
                      ...getColumnSearchOption(getEnumArrayRetIDValue('ACCOUNTING_ENTITY_TYPE'), 'equals'),
                  },
              ]
            : []),
        ...[],
        {
            title: geti18nText('accountingEntityAccount.table.column.accountCode'),
            dataIndex: ['account', 'code'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ACCOUNT.SEARCH, 'account.id', 'code', 'code', {
                id: 'id',
                label: 'code',
                text: 'code',
                code: 'code',
            }),
        },
        {
            title: geti18nText('accountingEntityAccount.table.column.accountName'),
            dataIndex: ['account', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ACCOUNT.SEARCH, 'account.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('accountingEntityAccount.table.column.accountType'),
            dataIndex: 'type',
            render: (text: any, record: any) => {
                if (record?.type !== undefined) {
                    return geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(
                getEnumArray('ACCOUNT_ENTRY_TYPE').filter((en: any) => {
                    return Object.values([enAccountEntryType.DEBIT, enAccountEntryType.CREDIT]).includes(en.id);
                })
            ),
        },
        {
            title: geti18nText('accountingEntityAccount.table.column.refName'),
            dataIndex: 'refName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('accountingEntityAccount.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="accounting-entity-account"
            url={CONSTANTS_REQ.ACCOUNTING_ENTITY_ACCOUNT.LIST}
            addNewButtonText={geti18nText('accountingEntityAccount.table.add')}
            buttonsClassName={showRowSelection ? 'ny-order-view-access-settings-buttons' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={AccountingEntityAccountEdit}
            showRowSelection={false}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={initialColumns}
            addedData={{
                refIdValue: refId,
                refTypeValue: refType,
                extraRefIdValue: extraRefId,
                extraRefTypeValue: extraRefType,
                isIndex: isIndex,
            }}
            hideNewButton={!canCreate()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.accounting.entity.account')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.accounting.entity.account')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNT_ENTRY_TYPE.' + value);
            },
        },
        {
            accountingEntityTypeGroup: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNTING_ENTITY_TYPE_GROUP.' + value);
            },
        },
        {
            accountingEntityType: (value: any) => {
                return value != undefined && geti18nText('app.enum.ACCOUNTING_ENTITY_TYPE.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default AccountingEntityAccountIndex;
