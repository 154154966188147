import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { getMealSubventionAmount } from '../../../../../utils/Utils';

const OrderItems = ({ orderId, mealSyncTypeHNB = false }: any) => {
    const [data, setData] = useState<any>([]);
    const [totalPrice, setTotalPrice] = useState<any>(0);
    const [totalAdvance, setTotalAdvance] = useState<any>(0);
    const [currency, setCurrency] = useState<any>('');

    const [totalSubvention, setTotalSubvention] = useState<any>(0);
    const mealSubventionValue = useApplicationSetting('MEALS_SUBVENTION');

    useEffect(() => {
        if (orderId) {
            importData();
        }
    }, [orderId]);

    const importData = () => {
        setData([]);
        setTotalPrice(0);
        setTotalAdvance(0);
        setCurrency('');
        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.EDIT + '/' + orderId, undefined).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setTotalPrice(result.data.totalPrice ?? 0);
                setTotalAdvance(result.data.totalAdvance ?? 0);
                setData(result.data.orderItem);
                if (
                    result.data.orderItem &&
                    result.data.orderItem[0] &&
                    result.data.orderItem[0].currency &&
                    result.data.orderItem[0].currency.isoCode
                ) {
                    setCurrency(result.data.orderItem[0].currency.isoCode);
                }
                if (mealSyncTypeHNB) {
                    setTotalSubvention(result.data.totalSubvention);
                }
            }
        });
    };

    const getItemValue = (item: any) => {
        if (item && item.id) {
            return (
                <span style={{ display: 'block', whiteSpace: 'pre-line' }}>
                    {item.meal && item.meal.name}
                    <span
                        style={{
                            paddingLeft: '10px',
                            color: 'gray',
                            fontSize: 'x-small',
                            display: 'block',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {mealSyncTypeHNB ? (
                            <span style={{ display: 'block' }}>
                                ({NyUtils.formatNumber(item.quantity, 2)} {geti18nText('meal.order.edit.pcs')} x{' '}
                                {NyUtils.formatNumber(item.price, 2)} {item.currency && item.currency.isoCode}
                                {totalSubvention > 0 ? (
                                    <>
                                        {' '}
                                        (
                                        {geti18nText('meal.delivery.edit.subvention') +
                                            ' -' +
                                            getMealSubventionAmount(
                                                mealSubventionValue,
                                                item.meal?.allowedDiscount ?? 0,
                                                true
                                            ) +
                                            '%'}
                                        )
                                    </>
                                ) : null}{' '}
                                = {NyUtils.formatNumber(item.advance, 2)} {item.currency && item.currency.isoCode})
                            </span>
                        ) : (
                            <span style={{ display: 'block' }}>
                                ({item.quantity} {geti18nText('meal.order.edit.pcs')} x{' '}
                                {NyUtils.formatNumber(item.price, 2)} {item.currency && item.currency.isoCode} ={' '}
                                {NyUtils.formatNumber(item.quantity * item.price, 2)}{' '}
                                {item.currency && item.currency.isoCode})
                            </span>
                        )}
                    </span>
                </span>
            );
        }
    };

    const getPriceValue = () => {
        if (mealSyncTypeHNB) {
            return <>{NyUtils.formatNumber(totalAdvance ?? 0, 2)}</>;
        } else {
            return <>{NyUtils.formatNumber(totalPrice ?? 0, 2)}</>;
        }
    };

    return (
        <>
            {data && data.length > 0 && (
                <>
                    <div
                        style={{
                            height: '200px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        <ul>
                            {data.map((item: any) => {
                                return <li>{getItemValue(item)}</li>;
                            })}
                        </ul>
                    </div>
                    <Divider style={{ fontSize: '14px' }} orientation={'left'}>
                        {geti18nText('app.default.total')}: {getPriceValue()} {currency}
                    </Divider>
                </>
            )}
        </>
    );
};

export default OrderItems;
