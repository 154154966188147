import { useEffect, useState } from 'react';
import { DEFAULT_FILTER, DEFAULT_PAGE_SIZE } from '../utils/Constants';

const useTableSettings = () => {
    useEffect(() => {}, []);
    const [settings, setSettings] = useState<{ [index: string]: any } | undefined>({});
    return {
        onDataLoaded: (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
            setSettings(params);
        },
        refresh: () => {
            setSettings(undefined);
        },
        setDefaultSortOrder(): { order: string; orderType: string } {
            return {
                order: settings?.order,
                orderType: settings?.orderType,
            };
        },
        setDefaultPageSize(defaultValue: any = DEFAULT_PAGE_SIZE): number {
            return settings?.max != undefined ? settings?.max : defaultValue;
        },
        checkForSearchItem(customFilter: any) {
            let res = settings?.search ? JSON.parse(decodeURI(settings?.search)) : customFilter;
            return res;
        },
        setDefaultFilterValue(defaultFilter: any = DEFAULT_FILTER) {
            return defaultFilter;
        },
        /*   setDefaultFilterValue(defaultFilter: any = DEFAULT_FILTER) {
            return settings?.search ? JSON.parse(decodeURI(settings?.search)) : defaultFilter;
        }, */

        setDefaultCurrentPage(): number {
            return settings?.offset ? settings?.offset + 1 : 1;
        },
    };
};

export default useTableSettings;
