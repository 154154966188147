import { geti18nText } from '@nybble/nyreact';
import { Col, Descriptions, Modal, Row } from 'antd';
import {
    getDateFormat,
    getEnumFormat,
    getSearchFormat,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../utils/Utils';

const EmployeeExistsModal = ({ visible, onOk, onCancel, title, content, employeeInfo, employee }: any) => {
    const personInfo = employeeInfo.person;
    const sex: any = setEnumFormat('SEX_TYPE', personInfo?.sex);

    const employeeInfoDesc = (
        <>
            <strong>{geti18nText('employee.divider.1')}</strong>
            <Row gutter={24}>
                <Col span={12}>
                    <Descriptions column={1} bordered size="small" labelStyle={{ width: '10rem' }}>
                        <Descriptions.Item label={geti18nText('person.edit.firstName')}>
                            {personInfo?.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.lastName')}>
                            {personInfo?.lastName}
                        </Descriptions.Item>
                        {employee && (
                            <>
                                <Descriptions.Item label={geti18nText('employee.edit.employment.businessUnit')}>
                                    {employeeInfo?.businessUnit
                                        ? employeeInfo?.businessUnit?.name +
                                          (employeeInfo?.businessUnit?.code
                                              ? ' (' + employeeInfo?.businessUnit?.code + ')'
                                              : '')
                                        : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.edit.eployment.record')}>
                                    {employeeInfo?.employmentRecordId ? employeeInfo?.employmentRecordId : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.edit.professional.vocation')}>
                                    {employeeInfo?.vocation?.name
                                        ? employeeInfo?.vocation?.name +
                                          (employeeInfo?.vocationDescription?.name
                                              ? ' ' + employeeInfo?.vocationDescription?.name
                                              : '')
                                        : '-'}
                                </Descriptions.Item>
                            </>
                        )}
                        <Descriptions.Item label={geti18nText('person.edit.oib')}>
                            {personInfo?.oib ? personInfo?.oib : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.jmbg')}>
                            {personInfo?.jmbg ? personInfo?.jmbg : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.sex')}>
                            {sex?.text ? sex?.text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('employee.edit.birth.citizenship')}>
                            {personInfo?.citizenship?.name ? personInfo?.citizenship?.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('employee.edit.birth.nationality')}>
                            {personInfo?.nationality?.name ? personInfo?.nationality?.name : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={12}>
                    <Descriptions column={1} bordered size="small" labelStyle={{ width: '10rem' }}>
                        <Descriptions.Item label={geti18nText('person.edit.birthCountry')}>
                            {personInfo?.birthCountry?.name ? personInfo?.birthCountry?.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.birthDate')}>
                            {personInfo?.birthDate ? getDateFormat(personInfo?.birthDate, 'DD.MM.YYYY.') : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.birthSettlement')}>
                            {personInfo?.birthSettlement?.name ? personInfo?.birthSettlement?.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.birthTownship')}>
                            {personInfo?.birthTownship?.name ? personInfo?.birthTownship?.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.maidenName')}>
                            {personInfo?.maidenName ? personInfo?.maidenName : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.motherMaidenName')}>
                            {personInfo?.motherMaidenName ? personInfo?.motherMaidenName : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.motherName')}>
                            {personInfo?.motherName ? personInfo?.motherName : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('person.edit.parentName')}>
                            {personInfo?.parentName ? personInfo?.parentName : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </>
    );

    return (
        <Modal
            visible={visible}
            width={900}
            centered={true}
            onOk={onOk}
            onCancel={onCancel}
            okText={geti18nText('app.default.button.yes')}
            cancelText={geti18nText('app.default.button.no')}
            maskClosable={false}
            closable={false}
            title={title}
        >
            {content}
            <br />
            <br />
            {employeeInfo && employeeInfoDesc}
        </Modal>
    );
};

export default EmployeeExistsModal;
