import useHelper from '../../../../../hooks/useHelper';
import { DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import ItemIndex from '../../../../administration/views/item';

const ItemMaterial = () => {
    useHelper('inventory/codebooks/item');

    return <ItemIndex type={8} itemGroupType={2} scroll={DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS} />;
};

export default ItemMaterial;
