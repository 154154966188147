import { DeleteOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';

import { Button, Col, Form, Input, Modal, Popconfirm, Row, Tabs } from 'antd';
import React, { ReactText, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdditionalInfo from '../../../../components/additional-info';

import useTableSettings from '../../../../hooks/useTableSettings';
import { TasksRights } from '../../../../rights/tasksRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    customTeamRenderOption,
    errorNotification,
    getSearchFormat,
    getTeamSelectOption,
    getUserSelectOption,
    okNotification,
    onKeyDownTextAreaCustom,
} from '../../../../utils/Utils';
import UserIndex from '../user';
import TeamSearch from './search';

const TeamEdit = (props: any) => {
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState(geti18nText('team.edit.header.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [dataForm, setDataForm] = useState(null);

    const [isCreate, setIsCreate] = useState(false);
    const [teamCreated, setTeamCreated] = useState(false);

    const [refreshTable, setRefreshTable] = useState(1);
    const [activeKey, setActiveKey] = useState('1');

    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [selectedValuesAddGroup, setSelectedValuesAddGroup] = useState<any>([]);
    const [hasSelectedAddGroup, setHasSelectedAddGroup] = useState(false);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);
    const [teamId, setTeamId] = useState<Number>();
    const [createdDate, setCreatedDate] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return TasksRights.canCreateTasksCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.user) {
            if (dataForm.employee) {
                dataForm.employee.id = dataForm.user.id;
                dataForm.employee.businessUnit = {
                    ...dataForm.employee.businessUnit,
                    employmentRecordId: dataForm.employee.employmentRecordId,
                };
            }
            if (dataForm.employee) {
                dataForm.user = getUserSelectOption(dataForm.employee);
            }
        }

        if (dataForm.parent) {
            dataForm.parent = getTeamSelectOption(dataForm.parent);
        }

        if (dataForm.hasOwnProperty('name') || dataForm.name) {
            setEditHeader(geti18nText('team.edit.header') + ' - ' + dataForm.name);
        }

        if (dataForm.created) {
            setCreatedDate(dataForm.created);
        }

        if (dataForm.createdBy) {
            setCreatedBy(dataForm.createdBy);
        }

        if (dataForm.updated) {
            setUpdatedDate(dataForm.updated);
        }

        if (dataForm.updatedBy) {
            setUpdatedBy(dataForm.updatedBy);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('team.edit.header.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const activeFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    let clearKeysDeleteGroup: any;

    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    let clearKeysAddGroup: any;

    const setSelectedValuesFuncAddGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedAddGroup(hasSelected);
        setSelectedValuesAddGroup(selectedRowKeys);
        clearKeysAddGroup = clearSelectedRowKeys;
    };

    const saveToGroup = (e: any) => {
        e.preventDefault();
        setLoadingModalGroup(true);
        const url = CONSTANTS_REQ.TEAM.ADD_MEMBERS;
        const urlWithTeamId = form.getFieldValue('id')
            ? url.replace('{teamId}', form.getFieldValue('id'))
            : url.replace('{teamId}', teamId);

        NyRequestResolver.requestPost(urlWithTeamId, undefined, {
            teamId: form.getFieldValue('id'),
            usersId: selectedValuesAddGroup,
        }).then((result: any) => {
            if (setLoadingModalGroup) setLoadingModalGroup(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysAddGroup) {
                    clearKeysAddGroup();
                }

                okNotification();
                setIsAddListModalVisibleGroup(false);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };
    const removeFromGroup = () => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.userId;
        });
        const url = CONSTANTS_REQ.TEAM.REMOVE_MEMBERS;
        const urlWithTeamId = form.getFieldValue('id')
            ? url.replace('{teamId}', form.getFieldValue('id'))
            : url.replace('{teamId}', teamId);

        NyRequestResolver.requestPost(urlWithTeamId, undefined, {
            teamId: form.getFieldValue('id'),
            usersId: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {activeKey === '1' ? (
                <div style={{ float: 'left' }}>
                    <AdditionalInfo
                        created={createdDate}
                        createdBy={createdBy}
                        updated={updatedDate}
                        updatedBy={updatedBy}
                    />
                </div>
            ) : null}
            {canCreate() && activeKey === '2' && (
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('team.edit.add.in.group')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('team.edit.remove.from.group.comfirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('team.edit.remove.from.group')}
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <NyDataEdit
            layout="vertical"
            //   formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TEAM.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate() || activeKey != '1'}
            hideActivationButtons={!canCreate() || teamCreated || activeKey != '1'}
            setIsCreate={setIsCreate}
            leaveNewModalOpen={true}
            normalize={(values: any) => {
                if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0) {
                    delete values.id;
                }

                if (values.user) {
                    values.user = getSearchFormat(values.user);
                }

                if (values.parent) {
                    values.parent = getSearchFormat(values.parent);
                }
                if (values.parent == null) {
                    delete values.parent;
                }
                return values;
            }}
            customFooterContent={getCustomFooterContent}
        >
            <Tabs
                type="card"
                className="tabs-sticky"
                activeKey={activeKey}
                onChange={(key: any) => {
                    setActiveKey(key);
                }}
            >
                <Tabs.TabPane tab={geti18nText('team.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('team.edit.column.name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input ref={focusInput} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('team.edit.column.code')}
                                name="code"
                                rules={[
                                    {
                                        required: false,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('team.edit.column.lead')}
                                name="user"
                                rules={[
                                    {
                                        required: false,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.USER.SEARCH}
                                    mustGetPopupContainer={false}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        employmentRecordId: 'employmentRecordId',
                                        businessUnit: 'businessUnit',
                                    }}
                                    searchBy="user"
                                    itemName={[
                                        ['person', 'firstName'],
                                        ['person', 'lastName'],
                                    ]}
                                    disabled={false}
                                    renderOption={customEmployeeRenderOption}
                                    customItemNameLabel={'firstName lastName'}
                                    SearchPopupComponent={<UserIndex disabled={true} />}
                                    order="person.last_name, person.first_name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <TeamSearch
                                className=""
                                label={geti18nText('team.edit.column.parent.team')}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    name: 'name',
                                    code: 'code',
                                    description: 'description',
                                }}
                                mustGetPopupContainer={false}
                                name="parent"
                                renderOption={customTeamRenderOption}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('team.edit.column.description')} name="description">
                                <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={geti18nText('team.edit.tab.users')} key="2" disabled={!(!isCreate || teamCreated)}>
                    {(!isCreate || teamCreated) && (
                        <NyDataTable
                            nyId="tasks-team-members-table"
                            rowKey="id"
                            url={
                                form.getFieldValue('id')
                                    ? CONSTANTS_REQ.TEAM.MEMBERS.replace('{teamId}', form.getFieldValue('id'))
                                    : CONSTANTS_REQ.TEAM.MEMBERS.replace('{teamId}', teamId)
                            }
                            showRowSelection
                            readonly
                            hideButtons
                            setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                            scroll={props?.editProps?.scroll}
                            rowSelectionType={'checkbox'}
                            rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                            setDefaultFilterValue={activeFilter}
                            fetchWhenChange={refreshTable}
                            setDefaultPageSize={table.setDefaultPageSize()}
                            columns={[
                                {
                                    title: geti18nText('team.table.column.id'),
                                    dataIndex: 'userId',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('number'),
                                },
                                {
                                    title: geti18nText('employee.table.column.firstName'),
                                    dataIndex: ['person', 'firstName'],
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('employee.table.column.lastName'),
                                    dataIndex: ['person', 'lastName'],
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('employee.table.column.employmentRecordId'),
                                    dataIndex: ['employee', 'employmentRecordId'],
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('number'),
                                },
                            ]}
                        />
                    )}
                    {isAddListModalVisibleGroup && (
                        <Modal
                            title={geti18nText('team.edit.tab.users.add')}
                            visible={isAddListModalVisibleGroup}
                            okText={geti18nText('app.default.button.add')}
                            okButtonProps={{ disabled: !hasSelectedAddGroup }}
                            onOk={(e) => saveToGroup(e)}
                            confirmLoading={loadingModalGroup}
                            width={900}
                            onCancel={() => {
                                setIsAddListModalVisibleGroup(false);
                            }}
                        >
                            <NyDataTable
                                nyId="tasks-team-not-members-table"
                                rowKey="id"
                                url={
                                    form.getFieldValue('id')
                                        ? CONSTANTS_REQ.TEAM.NOT_MEMBERS.replace('{teamId}', form.getFieldValue('id'))
                                        : CONSTANTS_REQ.TEAM.NOT_MEMBERS.replace('{teamId}', teamId)
                                }
                                showRecordModal={true}
                                hideNewButton={true}
                                showRowSelection
                                setDefaultPageSize={table.setDefaultPageSize()}
                                readonly
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                scroll={props?.editProps?.scroll}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncAddGroup as any}
                                fetchWhenChange={refreshTable}
                                onSaveAndGetID={() => setRefreshTable((refreshTable) => refreshTable + 1)}
                                columns={[
                                    {
                                        title: geti18nText('team.table.column.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('employee.table.column.firstName'),
                                        dataIndex: ['person', 'firstName'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('employee.table.column.lastName'),
                                        dataIndex: ['person', 'lastName'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('employee.table.column.employmentRecordId'),
                                        dataIndex: ['employee', 'employmentRecordId'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                ]}
                            />
                        </Modal>
                    )}
                </Tabs.TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default TeamEdit;
