import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import ContractTypeIndex from '.';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { customContractTypeRenderOption } from '../../../../../utils/Utils';
import ContractTypeEdit from './edit';

const ContractTypeSearch = ({
    label = geti18nText('menu.contract.type'),
    name = 'contractType',
    required = false,
    disabled = false,
    onChange = null,
    anex = false,
    mustGetPopupContainer = true,
    hide = false,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateContract();
    };
    return (
        <Form.Item
            style={hide ? { display: 'none' } : {}}
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={anex ? CONSTANTS_REQ.CONTRACT_TYPE.SEARCH_ANEX : CONSTANTS_REQ.CONTRACT_TYPE.SEARCH}
                map={{
                    id: 'id',
                    label: 'text',
                    text: 'name',
                    name: 'name',
                    description: 'description',
                }}
                searchBy="name || description"
                order="name"
                SearchPopupComponent={<ContractTypeIndex />}
                AddNewModalComponent={canCreate() ? ContractTypeEdit : null}
                nyTestId="contractType"
                disabled={disabled}
                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                renderOption={customContractTypeRenderOption}
                onChange={onChange}
                customItemNameLabel="name description"
                itemName={[['name'], ['description']]}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default ContractTypeSearch;
