import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import { getDateFormat, setDateFormat, warningNotification } from '../../../../../../utils/Utils';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';

const EmployeeDisabilityEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.disability.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('employee.disability.edit.header'));
        if (dataForm.dateFrom) {
            dataForm.dateFrom = setDateFormat(dataForm.dateFrom);
        }
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.disability.edit.new'));
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_DISABILITY.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                values.employee = { id: props?.editProps?.employeeId };
                values.disabilityType = { id: values.disabilityType.id };
                values.percentage = values.percentage ?? undefined;
                if (values.disabilityType.id == -1) {
                    delete values.disabilityType;
                }
                return values;
            }}
            shortcuts={true}
            nyTestId="employee-disability-edit"
            isModal={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('employee.disability.table.column.type')}
                        codebooksType={enCodebookType.DISABILITY_TYPE}
                        formItemName={'disabilityType'}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.disability.table.column.dateFrom')}
                        name="dateFrom"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('employee.disability.table.column.percent')} name={'percentage'}>
                        <InputNumber
                            className={'ny-input-number'}
                            max={100}
                            min={0}
                            controls={false}
                            onFocus={(event) => event.target.select()}
                            style={{ width: '100%' }}
                        ></InputNumber>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeDisabilityEdit;
