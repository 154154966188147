import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const EmploymentTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('menu.employment.type.edit.new'));
    const [loading, setLoading] = useState(false);
    const [refId, setRefId] = useState<any>(null);

    const focusInput = useRef<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('menu.employment.type') + ' - ' + dataForm.name);
        }
        if (dataForm.refId) {
            setRefId(dataForm.refId);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('menu.employment.type.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.EMPLOYMENT_TYPE.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            {...props}
            hideActivationButtons={!canCreate()}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('employment.type.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            {refId != null ? (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={geti18nText('employment.type.edit.refId')} name="refId">
                            <Input ref={focusInput} disabled />
                        </Form.Item>
                    </Col>
                </Row>
            ) : null}
        </NyDataEdit>
    );
};

export default EmploymentTypeEdit;
