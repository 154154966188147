import { NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { message } from 'antd';
import { getErrorNotificationMessage } from '../Utils';

export const uploadFiles = async (
    files: any,
    id: any,
    refId: any,
    documentType: any,
    hasNoRefId: any = false,
    uploadUrl: any,
    manageUploadUrl: any,
    deleteUrl: any,
    manageDeleteUrl: any,
    onlyReturnFileId: boolean = false,
    type: any
) => {
    const resultFileUpload = await Promise.all(
        files.map(async (file: any) => {
            if (!file.id || file.id < 0) {
                message.loading(`${file?.name} ${geti18nText('import.text.uploading')}`);
                const formData = new FormData();
                formData.append('file', file.file ?? file);

                await fetch(uploadUrl, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: 'Bearer ' + NySession.getUserToken(),
                    },
                })
                    .then((response) =>
                        response
                            .text()
                            .then((data) => ({
                                data: data,
                                status: response.status,
                            }))
                            .then(async (res) => {
                                if (res.data && res.status === RESPONSE.CREATED) {
                                    if (onlyReturnFileId) {
                                        file.id = res.data;
                                    } else if (refId || hasNoRefId) {
                                        manageFileUpload(
                                            file,
                                            res.data,
                                            id,
                                            refId,
                                            documentType,
                                            file.description,
                                            manageUploadUrl,
                                            deleteUrl,
                                            manageDeleteUrl,
                                            undefined,
                                            type
                                        );
                                    }
                                }
                            })
                    )
                    .catch(() => {
                        message.destroy();
                        message.error(`${file?.name} ${geti18nText('import.text.error')}`);
                    })
                    .finally(() => {});
            }
            return file;
        })
    );

    return resultFileUpload;
};

export const uploadImage = async (
    id: any,
    files: any,
    documentType: any,
    uploadUrl: any,
    manageUploadUrl: any,
    downloadUrl: any,
    deleteUrl: any,
    manageDeleteUrl: any
) => {
    const resultFileUpload = await Promise.all(
        files.map(async (file: any) => {
            if (!file.id || file.id < 0) {
                message.loading(`${file?.name} ${geti18nText('import.text.uploading')}`);
                const formData = new FormData();
                formData.append('file', file.file ?? file);

                await fetch(uploadUrl, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: 'Bearer ' + NySession.getUserToken(),
                    },
                })
                    .then((response) =>
                        response
                            .text()
                            .then((data) => ({
                                data: data,
                                status: response.status,
                            }))
                            .then(async (res) => {
                                file.id = res.data;
                                file.url =
                                    downloadUrl +
                                    '/' +
                                    res.data +
                                    '?lastmod=' +
                                    new Date().valueOf() +
                                    '&tenant=' +
                                    NySession.getUser().tenantId;
                                if (res.data && res.status === RESPONSE.CREATED && id) {
                                    manageFileUpload(
                                        file,
                                        res.data,
                                        id,
                                        id,
                                        documentType,
                                        file.description,
                                        manageUploadUrl,
                                        deleteUrl,
                                        manageDeleteUrl,
                                        undefined,
                                        undefined
                                    );
                                }
                            })
                    )
                    .catch(() => {
                        message.destroy();
                        message.error(`${file?.name} ${geti18nText('import.text.error')}`);
                    })
                    .finally(() => {});
            }
            return file;
        })
    );

    return resultFileUpload;
};

export const manageFileUpload = async (
    file: any,
    uuid: any,
    id: any,
    refId: any,
    documentType: any,
    description: any,
    manageUploadUrl: any,
    deleteUrl: any,
    manageDeleteUrl: any,
    setRefresh: any = undefined,
    type: any = undefined
) => {
    const params: any = {
        fileId: uuid,
        file: uuid,
        id: id,
        refId: refId,
        documentType: documentType ?? file?.documentType,
        description: description,
        fileName: file.name,
        name: file.name,
        mobilization: { id: id },
        militaryWorkApprovalId: id,
        task: { id: id },
        militaryWorkApprovalFile: {
            fileId: uuid,
            name: file.name,
            description: description,
            type: type, //sentToMorh ? militaryWorkApprovalFileType.EXPORT : militaryWorkApprovalFileType.IMPORT,
        },
    };
    NyRequestResolver.requestPost(manageUploadUrl, undefined, params).then((result: any) => {
        if (result && (result.status === RESPONSE.CREATED || result.status === RESPONSE.OK)) {
            message.destroy();
            message.success(`${file.name} ${geti18nText('import.text.done')}`);
        } else {
            getErrorNotificationMessage(result);
            deleteFiles([file.id], id, documentType, deleteUrl, manageDeleteUrl);
        }
        if (setRefresh) {
            setRefresh((refresh: any) => refresh + 1);
        }
    });
};

export const deleteFiles = (deleteFilesIds: any, id: any, documentType: any, deleteUrl: any, manageDeleteUrl: any) => {
    if (deleteFilesIds && deleteFilesIds.length >= 1) {
        if (id) {
            manageFileDelete(deleteFilesIds, id, documentType, manageDeleteUrl, deleteUrl);
        } else {
            deleteFilesAction(deleteFilesIds, deleteUrl);
        }
    }
};

export const deleteFilesAction = (deleteFilesIds: any, deleteUrl: any) => {
    const params = { filesId: deleteFilesIds };
    NyRequestResolver.requestPut(deleteUrl, undefined, params).then((result: any) => {
        if (result && (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED)) {
            message.destroy();
            message.success(`${geti18nText('app.default.file.delete.ok')}`);
        } else {
            getErrorNotificationMessage(result);
        }
    });
};

export const manageFileDelete = (
    deleteFilesIds: any,
    id: any,
    documentType: any,
    manageDeleteUrl: any,
    deleteUrl: any
) => {
    const params = {
        documentType: documentType,
        id: id,
        filesId: deleteFilesIds,
    };
    NyRequestResolver.requestPut(manageDeleteUrl + '/' + id, undefined, params).then((result: any) => {
        if (result && (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED)) {
            if (id) {
                deleteFilesAction(deleteFilesIds, deleteUrl);
            }
        } else {
            getErrorNotificationMessage(result);
        }
    });
};

export const deactivateFiles = (deleteFilesIds: any, id: any, deactivateUrl: any, setRefresh: any) => {
    if (deleteFilesIds && deleteFilesIds.length >= 1) {
        const params = { id: id, active: false };
        NyRequestResolver.requestPut(deactivateUrl + '/' + id, undefined, params).then((result: any) => {
            if (result && (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED)) {
                setRefresh((prevValue: any) => prevValue + 1);
            } else {
                getErrorNotificationMessage(result);
            }
        });
    }
};

export const deleteFilesWithRecordId = (
    id: any,
    fileId: any,
    manageDeleteUrl: any,
    deleteUrl: any,
    params: any = {}
) => {
    NyRequestResolver.requestPut(manageDeleteUrl, undefined, params).then((result: any) => {
        if (result && (result.status === RESPONSE.OK || result.status === RESPONSE.CREATED)) {
            if (id) {
                deleteFilesAction([fileId], deleteUrl);
            }
        } else {
            getErrorNotificationMessage(result);
        }
    });
};
