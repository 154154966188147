import { geti18nText } from '@nybble/nyreact';
import NySearchField from '@nybble/nyreact/build/ny-search-field';
import { Form } from 'antd';
import SectionIndex from '.';
import SectionEdit from './edit';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const SectionSearch = ({
    label = geti18nText('employee.mobilization.edit.defenseSection'),
    name = 'defenseSection',
    canCreate = true,
    required = false,
    disabled = false,
    autoFocus = false,
    onChange = undefined,
    setDefaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }],
    isFormItem = true,
    value = undefined,
    ref = undefined,
    mode,
    departmentId = null,
    nyTestId = 'section',
    mustGetPopupContainer = false,
}: any) => {
    const getSearchField = () => (
        <NySearchField
            url={CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH}
            map={{ id: 'id', label: 'name' }}
            searchBy="name"
            nyTestId={nyTestId}
            SearchPopupComponent={<SectionIndex departmentId={[departmentId]} />}
            AddNewModalComponent={canCreate ? SectionEdit : null}
            autoFocus={autoFocus}
            disabled={disabled}
            setDefaultFilterValue={setDefaultFilterValue}
            style={{ width: '100%' }}
            onChange={onChange}
            value={value}
            ref={ref}
            mode={mode}
            mustGetPopupContainer={mustGetPopupContainer}
        />
    );
    return (
        <>
            {isFormItem ? (
                <Form.Item
                    label={label}
                    name={name}
                    rules={
                        required
                            ? [
                                  {
                                      required: required,
                                      message: geti18nText('app.default.required'),
                                  },
                              ]
                            : []
                    }
                >
                    {getSearchField()}
                </Form.Item>
            ) : (
                getSearchField()
            )}
        </>
    );
};

export default SectionSearch;
