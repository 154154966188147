import { geti18nText } from '@nybble/nyreact';
import { Button, Form } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { getTransparentColor } from '../../utils/Utils';

const NyCustomSelectButtons = ({
    label = geti18nText('attendance.break.edit.title'),
    name = 'attendanceBreakType',
    selectOptions = [],
    selectedValue,
    required = true,
    onChange = null,
    customStyle = undefined,
    buttonSize = 'middle',
    selectedButton = null,
}: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;
    return (
        <>
            {selectOptions?.length > 0 && (
                <Form.Item
                    label={label}
                    name={name}
                    rules={[
                        {
                            required: required,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                >
                    {selectOptions
                        .filter((option: any) => option.id !== selectedButton)
                        .map((option: any) => {
                            const buttonStyle = {
                                margin: '3px',
                                backgroundColor: getTransparentColor(
                                    option?.color || '#ff0000',
                                    isDarkTheme ? 0.15 : 0.3
                                ),
                                border: `1px solid ${getTransparentColor(
                                    option?.color || '#ff0000',
                                    isDarkTheme ? 0.25 : 0.5
                                )}`,
                                color: isDarkTheme ? '#fff' : '#000',
                                ...customStyle,
                            };
                            return (
                                <Button
                                    onClick={() => onChange(option)}
                                    style={buttonStyle}
                                    ghost={option?.id === selectedValue?.id}
                                    type={option?.id === selectedValue?.id ? 'primary' : 'default'}
                                    size={buttonSize}
                                >
                                    {option.name}
                                </Button>
                            );
                        })}
                </Form.Item>
            )}
        </>
    );
};

export default NyCustomSelectButtons;
