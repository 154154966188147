import { WarehouseBookkeepingRights } from '../../rights/warehouseBookkeepingRights';
import WarehouseIndex from './views/warehouse';
import WarehouseDocumentIndex from './views/warehouse-document';
import WarehouseDocumentEdit from './views/warehouse-document/edit';
import WarehouseItemIndex from './views/warehouse-item';
import WarehouseItemEdit from './views/warehouse-item/edit';
import WarehouseReceiptIndex from './views/warehouse-receipt';
import WarehouseReceiptEdit from './views/warehouse-receipt/edit';
import WarehouseEdit from './views/warehouse/edit';

export const WarehouseModuleRoutes: any = [
    {
        path: '/warehouse',
        exact: true,
        component: WarehouseIndex,
        role: WarehouseBookkeepingRights.canViewCodebooksRoutes(),
    },
    {
        path: '/warehouse/codebooks/warehouse-document',
        exact: true,
        component: WarehouseDocumentIndex,
        role: WarehouseBookkeepingRights.canViewCodebooksRoutes(),
    },
    {
        path: '/warehouse/codebooks/warehouse-document/:id',
        exact: true,
        component: WarehouseDocumentEdit,
        role: WarehouseBookkeepingRights.canViewCodebooksRoutes(),
    },
    {
        path: '/warehouse/warehouse-item',
        exact: true,
        component: WarehouseItemIndex,
        role: WarehouseBookkeepingRights.canViewItemRoutes(),
    },
    {
        path: '/warehouse/warehouse-item/:id',
        exact: true,
        component: WarehouseItemEdit,
        role: WarehouseBookkeepingRights.canViewItemRoutes(),
    },
    {
        path: '/warehouse/warehouse-receipt',
        exact: true,
        component: WarehouseReceiptIndex,
        role: WarehouseBookkeepingRights.canViewReceiptRoutes(),
    },
    {
        path: '/warehouse/warehouse-receipt/:id',
        exact: true,
        component: WarehouseReceiptEdit,
        role: WarehouseBookkeepingRights.canViewReceiptRoutes(),
    },
    {
        path: '/warehouse/:id',
        exact: true,
        component: WarehouseEdit,
        role: WarehouseBookkeepingRights.canViewCodebooksRoutes(),
    },
];
