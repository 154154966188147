import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { useState } from 'react';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { numberFormat } from '../../../../../utils/Utils';
import MealGroupEdit from './edit';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealGroupIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const [refreshTable, setRefreshTable] = useState<any>(1);

    const table = useTableSettings();
    useHelper('meals/codebooks/meal-group');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('meal.group.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.group.table.column.sortBy'),
            dataIndex: 'sortBy',
            width: '10%',
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('meal.group.table.column.name'),
            dataIndex: 'name',
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('meal.group.table.column.minOrder'),
            dataIndex: 'minOrder',
            ...getColumnSearch('number'),
            render: (minOrder: any) => {
                return minOrder ? numberFormat(minOrder) : '';
            },
        },
        {
            title: geti18nText('meal.group.table.column.maxOrder'),
            dataIndex: 'maxOrder',
            ...getColumnSearch('number'),
            render: (maxOrder: any) => {
                return maxOrder ? numberFormat(maxOrder) : '';
            },
        },
        {
            title: geti18nText('meal.group.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(setDefaultFilterValue()),
        },
    ];

    return (
        <NyDataTable
            nyId="meals-group-table"
            fetchWhenChange={refreshTable}
            url={CONSTANTS_REQ.MEAL_GROUP.LIST}
            addNewButtonText={geti18nText('meal.group.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={MealGroupEdit}
            columns={columns}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={{ order: 'sortBy', orderType: 'asc' }}
            hideNewButton={!canCreate()}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.meals.meal.group')}
            colCSVCustomization={csvColumnCustomisation()}
            editProps={{ setRefreshTable, refreshTable, scroll: { y: 400, x: 500 }, sortOrder: null }}
            headerTitle={geti18nText('menu.meals.meal.group')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            maxOrder: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            minOrder: (value: any) => {
                return value != undefined && NyUtils.formatNumber(value, 2);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default MealGroupIndex;
