import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IEmployeeState {
    employee?: any;
}

const initialState: IEmployeeState = {
    employee: undefined,
};

const employee = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        employeeInit(state) {
            state.employee = undefined;
        },
        setEmployee(state, action: PayloadAction<IEmployeeState>) {
            const { employee } = action.payload;
            state.employee = employee;
        },
    },
});

export const { employeeInit, setEmployee } = employee.actions;

export default employee.reducer;
