import { TasksRights } from '../../rights/tasksRights';
import TaskListIndex from './views/task-list';
import TaskPriorityIndex from './views/task-priority';
import TaskSettings from './views/task-settings';
import TaskTemplateIndex from './views/task-template';
import TaskTypeCategoryIndex from './views/task-type-category';
import TaskTypeSubtypeIndex from './views/task-type-subtype';
import TeamIndex from './views/team';

export const TasksModuleRoutes: any = [
    {
        path: '/tasks',
        exact: true,
        component: TaskListIndex,
        role: TasksRights.canViewTask(),
    },
    {
        path: '/tasks/task-template',
        exact: true,
        component: TaskTemplateIndex,
        role: TasksRights.canViewTasksCodebooks(),
    },
    {
        path: '/tasks/team',
        exact: true,
        component: TeamIndex,
        role: TasksRights.canViewTasksCodebooks(),
    },
    {
        path: '/tasks/codebooks/task-type-category',
        exact: true,
        component: TaskTypeCategoryIndex,
        role: TasksRights.canViewTasksCodebooks(),
    },
    {
        path: '/tasks/codebooks/task-type-subtype',
        exact: true,
        component: TaskTypeSubtypeIndex,
        role: TasksRights.canViewTasksCodebooks(),
    },
    {
        path: '/tasks/codebooks/task-priority',
        exact: true,
        component: TaskPriorityIndex,
        role: TasksRights.canViewTasksCodebooks(),
    },
    {
        path: '/tasks/task-settings',
        exact: true,
        component: TaskSettings,
        role: TasksRights.canViewTasksSettingsMenu(),
    },
];
