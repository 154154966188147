import { geti18nText } from '@nybble/nyreact';
import { useLocation } from 'react-router';
import useHelper from '../../../../hooks/useHelper';
import { Tabs } from 'antd';
import HBNExchangeRateTable from './HBNExchangeRateTable';
import ExchangeRateTable from './ExchangeRateTable';

const { TabPane } = Tabs;

const ExchangeRateIndex = () => {
    const location = useLocation();

    useHelper('common/exchange_rate');

    return (
        <Tabs type="card" className="tabs-sticky" defaultActiveKey="1">
            <TabPane tab={geti18nText('exchangeRate.tab.1')} key="1" style={{ paddingTop: 12 }}>
                <ExchangeRateTable />
            </TabPane>
            <TabPane tab={geti18nText('exchangeRate.tab.2')} key="2">
                <HBNExchangeRateTable />
            </TabPane>
        </Tabs>
    );
};

export default ExchangeRateIndex;
