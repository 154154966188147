import { useRef, useState } from 'react';
import { Form, Row, Col, Input, Modal, Tooltip } from 'antd';
import { geti18nText, NyDataEdit, NySearchField, NyDatePicker } from '@nybble/nyreact';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray, onKeyDownTextAreaCustom } from '../../../../utils/Utils';
import { FormOutlined } from '@ant-design/icons';
import moment from 'moment';
import { oibCheck, JMBGCheck } from '../../../../utils/Validation';

const { TextArea } = Input;

const PersonEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('person.edit.new'));
    const [loading, setLoading] = useState(false);

    const [noteVisible, setNoteVisible] = useState(false);
    const [hasNote, setHasNote] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.birthDate) dataForm.birthDate = moment(dataForm.birthDate);
        if (dataForm.sex) {
            dataForm.sex = getTypeData(dataForm.sex);
        }
        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(dataForm.id);
        }
        if (dataForm.name) {
            form.setFieldsValue({ firstName: dataForm.name });
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const getTypeData = (typeId: any) => {
        return getEnumArray('SEX_TYPE').find((type: any) => {
            return type.id === typeId;
        });
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('person.edit.new'));
    };

    const noteButtonOnClick = () => {
        setNoteVisible(true);
    };

    const getCustomFooterContent = (
        <Tooltip title={geti18nText('person.edit.note')}>
            <FormOutlined
                onClick={noteButtonOnClick}
                className="ny-custom-button"
                style={{
                    color: hasNote ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)',
                }}
            />
        </Tooltip>
    );

    const validateOib = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.oib.not.correct'));
            }
        } else {
            callback();
        }
    };

    const validateJMBG = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (JMBGCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.jmbg.not.correct'));
            }
        } else {
            callback();
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.PERSON.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.birthDate) values.birthDate = moment(values.birthDate).format('yyyy-MM-DD HH:mm');
                if (values.sex) {
                    values.sex = values.sex.constructor === Number ? values.sex : values.sex.id;
                }
                return values;
            }}
            customFooterContent={getCustomFooterContent}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('person.edit.firstName')}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('person.edit.lastName')}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('person.edit.oib')}
                        name="oib"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                            {
                                validator: validateOib,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('person.edit.jmbg')}
                        name="jmbg"
                        rules={[
                            {
                                validator: validateJMBG,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('person.edit.birthDate')} name="birthDate">
                        <NyDatePicker format="DD.MM.YYYY" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('person.edit.parentName')} name="parentName">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('person.edit.sex')} name="sex">
                        <NySearchField
                            options={getEnumArray('SEX_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={onchange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {noteVisible && (
                <Modal
                    visible={noteVisible}
                    title={geti18nText('employmentContract.edit.note')}
                    onOk={() => {
                        setNoteVisible(false);
                    }}
                    forceRender={true}
                    closable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    maskClosable={false}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('employmentContract.edit.note')}
                                labelAlign={'left'}
                                name={['note']}
                            >
                                <TextArea
                                    rows={4}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                    onChange={(event: any) => {
                                        setHasNote(event.target.value !== '' ? true : false);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            )}
        </NyDataEdit>
    );
};

export default PersonEdit;
