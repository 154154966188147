import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import CompanyIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import CompanyModalEdit from './CompanyModalEdit';
import { AdministrationRights } from '../../../../rights/administrationRights';

const CompanySearch = ({
    disabled = false,
    label,
    formName,
    isModal,
    onChange,
    required = false,
    mode = 'default',
    mustGetPopupContainer = false,
}: any) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const canCreate = () => {
        return AdministrationRights.canCreateCompany();
    };

    return (
        <Form.Item
            label={label ? label : geti18nText('fixed.asset.edit.company')}
            name={formName ? formName : 'company'}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
        >
            <NySearchField
                url={CONSTANTS_REQ.COMPANY.SEARCH}
                map={{ id: 'id', oib: 'oib', label: 'name', text: 'name' }}
                searchBy="name || ' ' || oib"
                itemName={[['oib'], ['name']]}
                customItemNameLabel={'(oib) name'}
                renderOption={(option: any) => `${option.oib ? '(' + option.oib + ') ' : ''}${option.text}`}
                disabled={disabled}
                setDefaultFilterValue={setDefaultFilterValue}
                AddNewModalComponent={canCreate() ? CompanyModalEdit : null}
                mustGetPopupContainer={mustGetPopupContainer}
                SearchPopupComponent={<CompanyIndex isModal={isModal} />}
                onChange={onChange ? onChange : null}
                nyTestId="company"
                mode={mode}
                customListWidth={'1200px'}
            />
        </Form.Item>
    );
};

export default CompanySearch;
