import { NySession, NyTranslateEditor } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';

const TranslationEditorIndex = () => {
    return (
        <NyTranslateEditor
            CONSTANTS_REQ={CONSTANTS_REQ}
            languages={NySession.getAppValue('I18N').language.available}
            getUrl={CONSTANTS_REQ.TRANSLATION.LIST}
            updateTranslationUrl={CONSTANTS_REQ.TRANSLATION.EDIT}
        ></NyTranslateEditor>
    );
};

export default TranslationEditorIndex;
