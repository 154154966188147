import { WarningTwoTone } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Alert, Button, Descriptions, Modal, Table, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { RootState } from '../../rootReducer';
import { formatTime, getDateFormat, numberFormat } from '../../utils/Utils';
import { wageAmountReceivedIcon } from '../travel-warrant-icons';
import TravelWarrantWageRecapitulationTable from '../travel-warrant-wage-recapitulation';

const { Text } = Typography;

const TravelWarrantWageSummaryIndexTable = ({
    recapitulationData,
    printPdf,
    refreshWageSummary,
    setRefreshWageSummary,
    getTotalFromType,
    getExchangeRateFromTotalsType,
    loading = false,
    showRecapitulationTable = true,
    showIsDirtyWage = false,
}: any) => {
    const paymentInDeclaredCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY', true);
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const enTravelWageType = useEnum('TRAVEL_WAGE_TYPE');
    const enTravelWarrantTotals = useEnum('TRAVEL_WARRANT_TOTALS');
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;
    const [wageRecapitulationModalVisible, setWageRecapitulationModalVisible] = useState<any>(undefined);

    const getWageWarning = (record: any) => {
        return (
            <>
                {showIsDirtyWage && record?.isDirty && record?.wageNumber >= 0 && (
                    <Tooltip
                        placement="top"
                        title={<>{geti18nText('travelWarrantRecapitulation.table.wage.warning.short')}</>}
                        overlayStyle={{ maxWidth: '450px' }}
                    >
                        <WarningTwoTone
                            twoToneColor="#fa1414"
                            style={{ marginRight: '5px', marginTop: '5px', float: 'right' }}
                        />
                    </Tooltip>
                )}
            </>
        );
    };

    const columns: any = [
        {
            title: geti18nText('travelWarrantRecapitulation.table.time'),
            dataIndex: 'date',
            key: 'date',
            width: printPdf ? '150px' : '16%',
            render: (text: any, record: any) => {
                if (record.startDate && record.endDate) {
                    if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                        return !printPdf
                            ? getDateFormat(record.startDate, 'DD.MM.YYYY HH:mm:ss') +
                                  ' - ' +
                                  getDateFormat(record.endDate, 'DD.MM.YYYY HH:mm:ss')
                            : getDateFormat(record.startDate, `DD.MM.YY' HH:mm`) +
                                  ' - ' +
                                  getDateFormat(record.endDate, `DD.MM.YY' HH:mm`);
                    } else {
                        return (
                            <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {!printPdf
                                    ? getDateFormat(record.startDate, 'DD.MM.YYYY HH:mm:ss') +
                                      ' - ' +
                                      getDateFormat(record.endDate, 'DD.MM.YYYY HH:mm:ss')
                                    : getDateFormat(record.startDate, `DD.MM.YY' HH:mm`) +
                                      ' - ' +
                                      getDateFormat(record.endDate, `DD.MM.YY' HH:mm`)}
                            </span>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.country'),
            dataIndex: 'country',
            key: 'country',
            width: printPdf ? '1%' : !paymentInDeclaredCurrency ? '28%' : '22%',
            render: (text: any, record: any) => {
                if (record.country && record.country.name) {
                    if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                        return <span style={{ wordBreak: 'keep-all' }}>{record.country.name}</span>;
                    } else {
                        return (
                            <span style={{ wordBreak: 'keep-all', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                {record.country.name}
                            </span>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.hours'),
            dataIndex: 'formattedHours',
            width: '6%',
            key: 'formattedHours',
            render: (formattedHours: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return formattedHours ? formattedHours : '00:00';
                } else {
                    return (
                        <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {formattedHours ? formattedHours : '00:00'}
                        </span>
                    );
                }
            },
        },
        {
            title: (
                <div style={{ wordBreak: 'keep-all' }}>
                    {geti18nText('travelWarrantRecapitulation.table.hoursForWage')}
                </div>
            ),
            dataIndex: 'formattedWageHours',
            width: !printPdf ? '7%' : '6%',
            key: 'formattedWageHours',
            render: (formattedWageHours: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return formattedWageHours ? formattedWageHours : '00:00';
                } else {
                    return (
                        <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {formattedWageHours ? formattedWageHours : '00:00'}
                        </span>
                    );
                }
            },
        },
        {
            title: <div style={{ wordBreak: 'keep-all' }}>{geti18nText('travelWarrantRecapitulation.table.days')}</div>,
            dataIndex: 'days',
            width: '6%',
            key: 'days',
            render: (days: any, record: any) => {
                let daysRender: any = <></>;
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    daysRender = days != undefined ? days : '0';
                } else {
                    daysRender = (
                        <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {days != undefined ? days : '0'}
                        </span>
                    );
                }
                return (
                    <>
                        {daysRender}
                        {getWageWarning(record)}
                    </>
                );
            },
        },
        {
            title: (
                <div style={{ wordBreak: 'keep-all' }}>
                    {geti18nText('travelWarrantRecapitulation.table.wageAmount')}
                </div>
            ),
            key: 'wageAmount',
            dataIndex: 'wageAmount',
            align: 'right',
            width: !paymentInDeclaredCurrency ? '50px' : '60px',
            render: (wageAmount: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return (
                        <div style={{ float: 'right', textAlign: 'right' }}>
                            {numberFormat(wageAmount ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                } else {
                    return (
                        <div style={{ float: 'right', textAlign: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {numberFormat(wageAmount ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrantRecapitulation.table.wageExpenses'),
            key: 'wageExpenses',
            dataIndex: 'wageExpenses',
            align: 'right',
            width: !paymentInDeclaredCurrency ? '80px' : '120px',
            render: (wageExpenses: any, record: any) => {
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return (
                        <div style={{ float: 'right', textAlign: 'right' }}>
                            {numberFormat(wageExpenses ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                } else {
                    return (
                        <div style={{ float: 'right', textAlign: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                            {numberFormat(wageExpenses ?? 0)} {record?.currency?.isoCode}
                        </div>
                    );
                }
            },
        },
        {
            title: paymentInDeclaredCurrency
                ? geti18nText('travelWarrantRecapitulation.table.amountInCurrency')
                : geti18nText('travelWarrantRecapitulation.table.amount'),
            key: 'amountInCurrency',
            dataIndex: 'amountInCurrency',
            align: 'right',
            width: printPdf ? '90px' : !paymentInDeclaredCurrency ? '50px' : '70px',
            render: (text: any, record: any) => {
                const travelWageCurrency = record?.currency?.isoCode ?? defaultCurrencyIsoCode;
                if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                    return (
                        <div style={{ float: 'right', textAlign: 'right', marginRight: '4px' }}>
                            {numberFormat(record.amountInCurrency ?? 0)} {travelWageCurrency}
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                float: 'right',
                                textAlign: 'right',
                                marginRight: '4px',
                                color: isDarkTheme ? '#7ce7ac' : '#23c76c',
                            }}
                        >
                            {numberFormat(record.amountInCurrency ?? 0)} {travelWageCurrency}
                        </div>
                    );
                }
            },
        },
        ...(paymentInDeclaredCurrency == true
            ? [
                  {
                      title: geti18nText('travelWarrantRecapitulation.table.exchangeRate'),
                      key: 'exchangeRate',
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      width: !printPdf ? '60px' : '100px',
                      render: (exchangeRate: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return <span style={{ float: 'right' }}>{numberFormat(exchangeRate ?? 1, 6)} </span>;
                          } else {
                              return (
                                  <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c', float: 'right' }}>
                                      {numberFormat(exchangeRate ?? 1, 6)}
                                  </span>
                              );
                          }
                      },
                  },
                  {
                      title:
                          geti18nText('travelWarrantRecapitulation.table.amount') +
                          (paymentInDeclaredCurrency ? ' (' + defaultCurrencyIsoCode + ')' : ''),
                      key: 'amount',
                      dataIndex: 'amount',
                      align: 'right',
                      width: !printPdf ? '70px' : '144px',
                      render: (amount: any, record: any) => {
                          if (record.travelWage?.type !== enTravelWageType.NON_STANDARD) {
                              return (
                                  <div style={{ float: 'right', marginRight: '3px' }}>
                                      {numberFormat(amount ?? 0)}{' '}
                                      {paymentInDeclaredCurrency ? '' : defaultCurrencyIsoCode}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ float: 'right', color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                      {numberFormat(amount ?? 0)}{' '}
                                      {paymentInDeclaredCurrency ? '' : defaultCurrencyIsoCode}
                                  </div>
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    const getTitle = (showAsHeaderOnly: boolean = false) => {
        return (
            <>
                {showAsHeaderOnly ? (
                    <h3>
                        <b>{geti18nText('travelWarrantRelation.calculation')}</b>
                    </h3>
                ) : (
                    <span>{geti18nText('travelWarrantRelation.calculation')}</span>
                )}

                {recapitulationData?.travelWarrant?.travelWarrantType?.wageType !== enTravelWageType.PER_DIEM && (
                    <p>
                        <Alert
                            message={
                                recapitulationData?.travelWarrant?.travelWarrantType?.includeWage == true
                                    ? geti18nText('travelWarrantWage.table.info.wage', [
                                          formatTime(recapitulationData?.travelWarrant?.totalHours ?? 0),
                                          recapitulationData?.travelWarrant?.totalWageDays ?? 0,
                                      ])
                                    : geti18nText('travelWarrantWage.table.info.noWage', [
                                          formatTime(recapitulationData?.travelWarrant?.totalHours ?? 0),
                                          recapitulationData?.travelWarrant?.totalWageDays ?? 0,
                                          numberFormat(
                                              recapitulationData?.travelWarrant?.totalNotCalculatedHours != undefined &&
                                                  recapitulationData?.travelWarrant?.totalNotCalculatedHours > 0
                                                  ? recapitulationData?.travelWarrant?.totalNotCalculatedHours
                                                  : 0
                                          ),
                                      ])
                            }
                            type="info"
                            showIcon
                            style={{ margin: '5px', fontWeight: '400' }}
                        />
                    </p>
                )}
            </>
        );
    };

    const getModal = (
        <>
            {wageRecapitulationModalVisible && (
                <Modal
                    title={geti18nText('travelWarrantWageRecapitulation.title')}
                    visible={wageRecapitulationModalVisible}
                    width={1200}
                    onCancel={() => setWageRecapitulationModalVisible(false)}
                    maskClosable={false}
                    footer={
                        <Button onClick={() => setWageRecapitulationModalVisible(false)}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>
                    }
                >
                    <TravelWarrantWageRecapitulationTable
                        id={recapitulationData?.travelWarrant?.id}
                        refresh={refreshWageSummary}
                        setRefresh={setRefreshWageSummary}
                        travelWarrant={recapitulationData?.travelWarrant}
                        hideDivider={true}
                        isWizard={true}
                        disabledSwitches
                        showTaxable
                    />
                </Modal>
            )}
        </>
    );

    const showWageAmountReceived = () => {
        return (
            recapitulationData?.travelWarrant?.travelWarrantType?.payoutByAnotherOrganization === true &&
            recapitulationData?.travelWarrant?.wageAmountReceived > 0
        );
    };

    const getWageAmountReceived = (showCurrency: boolean) => {
        let wageAmountReceived = recapitulationData?.travelWarrant?.wageAmountReceived;
        const totals: any = recapitulationData?.travelWarrant.travelWarrantTotalsList;
        const items = totals?.length > 0 && totals.filter((item: any) => item.totalType === enTravelWarrantTotals.WAGE);
        const item = items?.length > 0 && items[0];

        return (
            <span>
                {wageAmountReceived > 0 ? numberFormat(wageAmountReceived) : '0,00'}{' '}
                {showCurrency && item?.currency?.isoCode ? item.currency.isoCode : ''}
                <br />
            </span>
        );
    };

    const getWageTotal = (showCurrency: boolean) => {
        let totalWageAmount = recapitulationData?.totalWageAmount;
        const totals: any = recapitulationData?.travelWarrant.travelWarrantTotalsList;
        const items = totals?.length > 0 && totals.filter((item: any) => item.totalType === enTravelWarrantTotals.WAGE);
        const item = items?.length > 0 && items[0];
        return (
            <span>
                {totalWageAmount > 0 ? numberFormat(totalWageAmount) : '0,00'}{' '}
                {showCurrency && item?.currency?.isoCode ? item.currency.isoCode : ''}
                <br />
            </span>
        );
    };

    return (
        <>
            {showRecapitulationTable ? (
                <>
                    <Descriptions
                        column={1}
                        bordered
                        title={<>{getTitle()}</>}
                        size="small"
                        style={{ marginBottom: '-1px', position: 'relative' }}
                        labelStyle={{
                            width: '4rem',
                        }}
                    >
                        {recapitulationData?.travelWarrant?.travelWarrantType?.includeWage == true && (
                            <Descriptions.Item
                                label={
                                    <div style={{ padding: '4px 8px' }}>
                                        {geti18nText('travelWarrantRecapitulation.table.column.wages')}
                                        {!printPdf && (
                                            <Tooltip title={geti18nText('travel.warrant.plan.error.details')}>
                                                <Button
                                                    style={{ marginTop: '5px' }}
                                                    onClick={() => {
                                                        setWageRecapitulationModalVisible(true);
                                                    }}
                                                >
                                                    {geti18nText('app.default.details')}
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                                span={1}
                                style={{
                                    padding: '0px',
                                }}
                                labelStyle={{ display: printPdf && 'none' }}
                            >
                                {recapitulationData?.travelWarrantWageCalculationJson ? (
                                    <>
                                        <Table
                                            loading={loading}
                                            size="small"
                                            bordered
                                            pagination={false}
                                            columns={columns}
                                            dataSource={recapitulationData?.travelWarrantWageCalculationJson}
                                            summary={(pageData: any) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell colSpan={7} index={0}>
                                                                <b
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 7,
                                                                        right: 7,
                                                                        fontWeight: '500',
                                                                    }}
                                                                >
                                                                    {geti18nText(
                                                                        'travelWarrantRecapitulation.table.wage.amount.calculated.short'
                                                                    )}
                                                                </b>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1} className="rightAlign">
                                                                <Text
                                                                    style={{
                                                                        color: isDarkTheme ? 'white' : 'black',
                                                                        float: 'right',
                                                                        textAlign: 'right',
                                                                        marginRight: '-1px',
                                                                    }}
                                                                    strong={!paymentInDeclaredCurrency}
                                                                >
                                                                    {''}
                                                                </Text>
                                                            </Table.Summary.Cell>
                                                            {paymentInDeclaredCurrency ? (
                                                                <>
                                                                    <Table.Summary.Cell
                                                                        index={2}
                                                                        className="rightAlign"
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                color: isDarkTheme ? 'white' : 'black',
                                                                                float: 'right',
                                                                                textAlign: 'right',
                                                                                marginRight: '-3px',
                                                                            }}
                                                                        >
                                                                            {''}
                                                                        </Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell
                                                                        index={3}
                                                                        className="rightAlign"
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                color: isDarkTheme ? 'white' : 'black',
                                                                                float: 'right',
                                                                                textAlign: 'right',
                                                                                marginRight: '-1px',
                                                                            }}
                                                                            strong
                                                                        >
                                                                            {getWageTotal(false)}
                                                                        </Text>
                                                                    </Table.Summary.Cell>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Table.Summary.Row>
                                                        {showWageAmountReceived() && (
                                                            <Table.Summary.Row>
                                                                <Table.Summary.Cell colSpan={7} index={0}>
                                                                    <Text
                                                                        style={{
                                                                            color: isDarkTheme ? 'white' : 'black',
                                                                            float: 'right',
                                                                            textAlign: 'right',
                                                                            marginRight: '-1px',
                                                                        }}
                                                                        strong
                                                                    >
                                                                        {geti18nText(
                                                                            'travelWarrantRecapitulation.table.wage.amount.received.short'
                                                                        )}
                                                                    </Text>
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1} className="rightAlign">
                                                                    {!paymentInDeclaredCurrency && (
                                                                        <div
                                                                            style={{
                                                                                float: 'left',
                                                                                marginLeft: '4px',
                                                                            }}
                                                                        >
                                                                            {wageAmountReceivedIcon}
                                                                        </div>
                                                                    )}
                                                                    <Text
                                                                        style={{
                                                                            color: isDarkTheme ? 'white' : 'black',
                                                                            float: 'right',
                                                                            textAlign: 'right',
                                                                            marginRight: '-1px',
                                                                        }}
                                                                        strong={!paymentInDeclaredCurrency}
                                                                    >
                                                                        {''}
                                                                    </Text>
                                                                </Table.Summary.Cell>
                                                                {paymentInDeclaredCurrency ? (
                                                                    <>
                                                                        <Table.Summary.Cell
                                                                            index={2}
                                                                            className="rightAlign"
                                                                        >
                                                                            <Text
                                                                                style={{
                                                                                    color: isDarkTheme
                                                                                        ? 'white'
                                                                                        : 'black',
                                                                                    float: 'right',
                                                                                    textAlign: 'right',
                                                                                    marginRight: '-3px',
                                                                                }}
                                                                            >
                                                                                {''}
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell
                                                                            index={3}
                                                                            className="rightAlign"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    float: 'left',
                                                                                    marginLeft: '4px',
                                                                                }}
                                                                            >
                                                                                {wageAmountReceivedIcon}
                                                                            </div>
                                                                            <Text
                                                                                style={{
                                                                                    color: isDarkTheme
                                                                                        ? 'white'
                                                                                        : 'black',
                                                                                    float: 'right',
                                                                                    textAlign: 'right',
                                                                                    marginRight: '-1px',
                                                                                }}
                                                                                strong
                                                                            >
                                                                                {getWageAmountReceived(
                                                                                    !paymentInDeclaredCurrency
                                                                                )}
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </Table.Summary.Row>
                                                        )}
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell colSpan={7} index={0}>
                                                                <Text
                                                                    style={{
                                                                        color: isDarkTheme ? 'white' : 'black',
                                                                        float: 'right',
                                                                        textAlign: 'right',
                                                                        marginRight: '-1px',
                                                                    }}
                                                                    strong
                                                                >
                                                                    {geti18nText(
                                                                        'travelWarrantRecapitulation.travel.costs.total.wages'
                                                                    )}
                                                                </Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1} className="rightAlign">
                                                                <Text
                                                                    style={{
                                                                        color: isDarkTheme ? 'white' : 'black',
                                                                        float: 'right',
                                                                        textAlign: 'right',
                                                                        marginRight: '-1px',
                                                                    }}
                                                                    strong={!paymentInDeclaredCurrency}
                                                                >
                                                                    {getTotalFromType(
                                                                        enTravelWarrantTotals.WAGE,
                                                                        true,
                                                                        true
                                                                    )}
                                                                </Text>
                                                            </Table.Summary.Cell>
                                                            {paymentInDeclaredCurrency ? (
                                                                <>
                                                                    <Table.Summary.Cell
                                                                        index={2}
                                                                        className="rightAlign"
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                color: isDarkTheme ? 'white' : 'black',
                                                                                float: 'right',
                                                                                textAlign: 'right',
                                                                                marginRight: '-3px',
                                                                            }}
                                                                        >
                                                                            {getExchangeRateFromTotalsType(
                                                                                enTravelWarrantTotals.WAGE
                                                                            )}
                                                                        </Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell
                                                                        index={3}
                                                                        className="rightAlign"
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                color: isDarkTheme ? 'white' : 'black',
                                                                                float: 'right',
                                                                                textAlign: 'right',
                                                                                marginRight: '-1px',
                                                                            }}
                                                                            strong
                                                                        >
                                                                            {getTotalFromType(
                                                                                enTravelWarrantTotals.WAGE,
                                                                                false
                                                                            )}
                                                                        </Text>
                                                                    </Table.Summary.Cell>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Table.Summary.Row>
                                                    </>
                                                );
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Text style={{ color: isDarkTheme ? 'white' : 'black', float: 'right' }} strong>
                                            {'0,00'}
                                        </Text>
                                    </>
                                )}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </>
            ) : (
                getTitle(true)
            )}
            {getModal}
        </>
    );
};

export default TravelWarrantWageSummaryIndexTable;
