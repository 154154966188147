import { PrinterOutlined } from '@ant-design/icons';
import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Empty, Form, Row, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { GetEmployeeEvaluationCardColor, GetEmployeeEvaluationGradeColors } from '../../../../../utils/Utils';
import EmployeeEvaluationPeriodSearch from '../request-modal/period-search';
import EmployeeEvaluationChart from './EmployeeEvaluationChart';
import EmployeeEvaluationPie from './EmployeeEvaluationPie';

const EmployeeEvaluationGraph = ({
    showSearch = true,
    period,
    showPrintButton = true,
    customRef = undefined,
    printPdf,
    setPrintPdf,
    isMyPortal,
}: any) => {
    const [form] = Form.useForm();
    const [data, setData] = useState<any>(null);
    const [data2, setData2] = useState<any>(null);
    const [chartSwitch, setChartSwitch] = useState<any>(false);
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
    const graphRef = useRef<any>();

    const getStatusCount = (period: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION.STATUS_COUNT + '/' + period).then(
            (result: any) => {
                if (result.status == RESPONSE.OK) {
                    const graphData = result?.data?.map((item: any) => {
                        return {
                            item: geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.' + item.name),
                            count: item.counted,
                            color: GetEmployeeEvaluationCardColor(Number(item.name), false),
                        };
                    });
                    setData(graphData);
                }
            }
        );
    };

    const getEstimationCount = (period: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION.ESTIMATION_COUNT + '/' + period).then(
            (result: any) => {
                if (result.status == RESPONSE.OK) {
                    const graphData = result?.data?.map((item: any) => {
                        return {
                            item: geti18nText('app.enum.EMPLOYEE_EVALUATION_ESTIMATION.' + item.name),
                            count: item.counted,
                            color: GetEmployeeEvaluationGradeColors(Number(item.name)),
                        };
                    });
                    setData2(graphData);
                }
            }
        );
    };

    useEffect(() => {
        if (showSearch) {
            setPeriod();
        } else {
            getStatusCount(period?.id);
            getEstimationCount(period?.id);
        }
    }, [showSearch, period]);

    const setPeriod = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.LIST, {
            order: 'id',
            orderType: 'desc',
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                if (result?.data?.content && result?.data?.content[0]) {
                    const dateFrom = result.data.content[0].dateFrom
                        ? moment(result.data.content[0].dateFrom).format('DD.MM.YYYY.')
                        : '';
                    const dateTo = result.data.content[0].dateTo
                        ? moment(result.data.content[0].dateTo).format('DD.MM.YYYY.')
                        : '';
                    const dates = dateFrom + ' - ' + dateTo;
                    result.data.content[0].label = dates;
                    form.setFieldsValue({ periodSearch: result?.data?.content[0] });
                    setSelectedPeriod(result?.data?.content[0]);
                    getStatusCount(result?.data?.content[0].id);
                    getEstimationCount(result?.data?.content[0].id);
                }
            }
        });
    };

    const getDocumentTitle = () => {
        const dateFrom = period?.dateFrom
            ? moment(period?.dateFrom).format('DD.MM.YYYY.')
            : !period && selectedPeriod?.dateFrom
            ? moment(selectedPeriod?.dateFrom).format('DD.MM.YYYY.')
            : !period &&
              selectedPeriod?.label?.props?.children &&
              selectedPeriod?.label?.props?.children[0]?.props?.children &&
              selectedPeriod?.label?.props?.children[0]?.props?.children
            ? selectedPeriod?.label?.props?.children[0]?.props?.children[0]
            : '';
        const dateTo = period?.dateTo
            ? moment(period?.dateTo).format('DD.MM.YYYY.')
            : !period && selectedPeriod?.dateTo
            ? moment(selectedPeriod?.dateTo).format('DD.MM.YYYY.')
            : !period &&
              selectedPeriod?.label?.props?.children &&
              selectedPeriod?.label?.props?.children[0]?.props?.children &&
              selectedPeriod?.label?.props?.children[0]?.props?.children
            ? selectedPeriod?.label?.props?.children[0]?.props?.children[2]
            : '';
        return geti18nText('employee.evaluation.list') + ' (' + dateFrom + ' - ' + dateTo + ')';
    };

    return (
        <div style={{ height: showSearch ? '81vh' : undefined }}>
            {showSearch && (
                <Form form={form}>
                    <Row gutter={24}>
                        <Col span={5}>
                            <EmployeeEvaluationPeriodSearch
                                required={false}
                                onChange={(value: any) => {
                                    getStatusCount(value.id);
                                    getEstimationCount(value.id);
                                    setSelectedPeriod(value);
                                }}
                                name="periodSearch"
                                label={geti18nText('employee.evaluation.edit.duration')}
                                placeholder={geti18nText('app.default.dayPart')}
                            />
                        </Col>
                        {data?.length > 0 && data2?.length > 0 && (
                            <Col span={3}>
                                <Switch
                                    onChange={(checked: boolean) => setChartSwitch(checked)}
                                    checked={chartSwitch}
                                    checkedChildren={geti18nText('employee.evaluation.graph.button.1')}
                                    unCheckedChildren={geti18nText('employee.evaluation.graph.button.2')}
                                />
                            </Col>
                        )}
                    </Row>
                </Form>
            )}

            <Row gutter={24} ref={customRef ? customRef : graphRef}>
                {printPdf && <p style={{ fontSize: '30px', marginLeft: '15px' }}>{getDocumentTitle()}</p>}
                {data?.length > 0 || data2?.length > 0 ? (
                    <>
                        {chartSwitch ? (
                            <div style={{ display: 'flex' }}>
                                <Col span={12}>
                                    <EmployeeEvaluationPie
                                        data={data}
                                        title={geti18nText('employee.evaluation.graph.1')}
                                        portal={isMyPortal}
                                    />
                                </Col>
                                <Col span={12}>
                                    <EmployeeEvaluationPie
                                        data={data2}
                                        title={geti18nText('employee.evaluation.graph.2')}
                                        portal={isMyPortal}
                                    />
                                </Col>
                            </div>
                        ) : (
                            <div style={{ display: 'flex' }}>
                                <Col span={12}>
                                    <EmployeeEvaluationChart
                                        data={data}
                                        title={geti18nText('employee.evaluation.graph.1')}
                                        portal={isMyPortal}
                                    />
                                </Col>
                                <Col span={12}>
                                    <EmployeeEvaluationChart
                                        data={data2}
                                        title={geti18nText('employee.evaluation.graph.2')}
                                        portal={isMyPortal}
                                    />
                                </Col>
                            </div>
                        )}
                    </>
                ) : (
                    <Col span={24}>
                        <Empty />
                    </Col>
                )}
            </Row>
            <Row gutter={24} style={{ marginTop: '12px' }}>
                <Col span={6}>
                    {showPrintButton && data?.length > 0 && data2?.length > 0 && (
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Button key={'print'} icon={<PrinterOutlined />}>
                                        {geti18nText('travelWarrant.action.pdf.short')}
                                    </Button>
                                );
                            }}
                            onBeforeGetContent={() => {
                                setPrintPdf(true);
                                return Promise.resolve();
                            }}
                            onAfterPrint={() => {
                                setPrintPdf(false);
                            }}
                            content={() => (customRef ? customRef.current : graphRef.current)}
                            pageStyle={`@page {
                                size: landscape;
                                margin: 5mm;
                            }`}
                            documentTitle={getDocumentTitle()}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default EmployeeEvaluationGraph;
