import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import BusinessUnitIndex from '.';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import BusinessUnitEdit from './edit';

export const BusinessUnitSearch = ({
    label,
    formItemName = 'businessUnit',
    onChange,
    isWorkObligation = false,
    disabled = false,
    formStyle,
    mode = 'default',
    autoFocus = false,
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    required = false,
    placeholder = undefined,
    canAddNewModalComponent = true,
    canAddSearchPopupComponent = true,
    scroll = undefined,
    mustGetPopupContainer = false,
    readonly = false,
    hideNewButton,
    expandedRows,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateCodebooks();
    };

    return (
        <Form.Item
            label={label ? label : geti18nText('app.default.businessUnit')}
            name={formItemName}
            style={formStyle}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.BUSINESS_UNIT.SEARCH}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                searchBy="name,code"
                itemName={[['code'], ['name']]}
                mode={mode}
                customItemNameLabel={isWorkObligation ? '(code) name' : 'name (code)'}
                renderOption={
                    isWorkObligation
                        ? (option: any) => `${option.code ? '(' + option.code + ') ' : ''}${option.text}`
                        : (option: any) => `${option.text} ${option.code ? '(' + option.code + ') ' : ''}`
                }
                AddNewModalComponent={canCreate() && canAddNewModalComponent ? BusinessUnitEdit : null}
                SearchPopupComponent={
                    canAddSearchPopupComponent ? (
                        <BusinessUnitIndex
                            mobilization={isWorkObligation}
                            showRadioButtons={false}
                            readonly={readonly ? readonly : !canCreate()}
                            scroll={scroll}
                            hideNewButton={hideNewButton}
                            defaultFilterValue={setDefaultFilterValue()}
                            expandedRows={expandedRows}
                        />
                    ) : null
                }
                customListWidth={1400}
                disabled={disabled}
                onChange={onChange}
                autoFocus={autoFocus}
                setDefaultFilterValue={setDefaultFilterValue}
                mustGetPopupContainer={mustGetPopupContainer}
                placeholder={placeholder}
            />
        </Form.Item>
    );
};
