import { CheckOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyUtils,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button } from 'antd';
import moment from 'moment';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { fileDownload, getEnumArrayStatus } from '../../../../../utils/Utils';

const ContractJobDocumentIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    jobId,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();

    useHelper('human/contracts/contract-job-documents');

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateContract() || HumanResourcesRights.canOnlyViewContract();
    };

    const columns = [
        {
            title: geti18nText('contract.job.documents.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('contract.job.documents.table.column.template'),
            dataIndex: ['documentTemplate', 'name'],
            ...getColumnSearchByCodebookSimple(
                CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.SEARCH,
                'documentTemplate.id',
                'name',
                'name',
                {
                    id: 'id',
                    label: 'name',
                }
            ),
        },
        {
            title: geti18nText('contract.job.documents.table.column.status'),
            dataIndex: 'status',
            width: '180px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumArrayStatus('CONTRACT_DOCUMENT_STATUS')),
            render: (text: any, record: any) => {
                if (record.status) {
                    return geti18nText('app.enum.CONTRACT_DOCUMENT_STATUS.' + record.status);
                }
            },
        },
        {
            title: geti18nText('contract.job.documents.table.column.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return moment(record.created).format('DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('contract.job.documents.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '15%',
            render: (text: any, record: any) => {
                if (record?.fileId) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    fileDownload(
                                        CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                        null,
                                        record?.fileName
                                    );
                                }}
                            >
                                {geti18nText('app.default.download')}
                            </Button>
                        </div>
                    );
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-contract-contract-type-job-documents-table"
            url={CONSTANTS_REQ.CONTRACT_TYPE.LIST_JOB_DOCUMENTS}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            columns={columns}
            addedData={{ jobId: jobId }}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            shortcuts={true}
            exportCSV={canExportCSV()}
            csvColumns={csvColumns()}
            CSVFileName={geti18nText('menu.contract.job.documents')}
            colCSVCustomization={csvColumnCustomisation()}
            headerTitle={geti18nText('menu.contract.job.documents')}
        />
    );
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('contract.job.documents.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('contract.job.documents.table.column.template'),
            dataIndex: ['documentTemplate', 'name'],
        },
        {
            title: geti18nText('contract.job.documents.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('contract.job.documents.table.column.created'),
            dataIndex: 'created',
        },
        {
            title: geti18nText('contract.job.documents.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            created: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
    ];
};

export default ContractJobDocumentIndex;
