import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyDatePicker, NyInputNumber, NySearchField, NySession } from '@nybble/nyreact';
import { Alert, Button, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import TravelWarrantList from '../../../../components/travel-warrant-list';
import useEnum from '../../../../hooks/useEnum';
// import { addTab } from '../../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    getTravelWarrantStatusIcon,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import AccountingEntityAccountIndex from '../../../accounting/views/accounting-entity-account';
import { BusinessUnitSearch } from '../../../human/views/business-unit/search';
import CountryIndex from '../../../administration/views/country';
import CountryEdit from '../../../administration/views/country/edit';
import CurrencySearch from '../../../administration/views/currency/search';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const { Panel } = Collapse;

const TravelWageEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelWage.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [dataForm, setDataForm] = useState(null);
    const [isNonStandardWage, setIsNonStandardWage] = useState<any>(false);
    const [type, setType] = useState<any>(null);
    const enTravelWageType = useEnum('TRAVEL_WAGE_TYPE');
    const [isDisabled, setIsDisabled] = useState<any>(false);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2']);
    const [showTooltip, setShowTooltip] = useState(false);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const canCreateCodebooks = () => {
        return AdministrationRights.canCreateAdministrationCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setCollapseActiveKeys(['1', '2']);
            setEditHeader(geti18nText('travelWage.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.country) dataForm.country = setSearchFormat(dataForm.country);
        if (dataForm.currency) dataForm.currency = setSearchFormat(dataForm.currency, 'isoCode');
        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
        }
        if (dataForm.type) {
            setType(dataForm.type);
            setIsNonStandardWage(dataForm.type === enTravelWageType.NON_STANDARD);
            if (dataForm.type !== enTravelWageType.NON_STANDARD) {
                dataForm.businessUnit = undefined;
            }
            dataForm.type = setEnumFormat('TRAVEL_WAGE_TYPE', dataForm.type);
        }
        dataForm.validFrom = dataForm.validFrom ? setDateFormat(dataForm.validFrom, 'yyyy-MM-DD') : null;
        dataForm.validTo = dataForm.validTo ? setDateFormat(dataForm.validTo, 'yyyy-MM-DD') : null;

        setIsDisabled(dataForm.travelWarrantCount > 0 ? true : false);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setIsNonStandardWage(false);
        form.resetFields();
        setEditHeader(geti18nText('travelWage.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2']);
        }

        setCollapseAll(!collapseAll);
    };

    const wageTypeOnChange = (value: any) => {
        setIsNonStandardWage(value.id === enTravelWageType.NON_STANDARD);
        setType(value.id);
        if (value.id !== enTravelWageType.NON_STANDARD) {
            form.setFieldsValue({ businessUnit: undefined });
        }
    };

    const validateAmount = (rule: any, value: any, callback: any) => {
        return value <= 0 ? callback(geti18nText('travelWage.edit.amount.error')) : callback();
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_WAGE.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            hideSubmitButton={!canCreate() || isDisabled}
            hideActivationButtons={!canCreate()}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            normalize={(values: any) => {
                if (values.type) {
                    values.type = type;
                    if (values.type < 1) {
                        delete values.type;
                    }
                }
                if (values.country) {
                    values.country = getSearchFormat(values.country);
                    if (values.country.id < 1) {
                        delete values.country;
                    }
                }
                if (values.currency) {
                    values.currency = getSearchFormat(values.currency);
                    if (values.currency.id < 1) {
                        delete values.currency;
                    }
                }
                if (values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    if (values.businessUnit.id < 1) {
                        delete values.businessUnit;
                    }
                }
                if (values.travelWarrantList) {
                    delete values.travelWarrantList;
                }
                values.validFrom = values.validFrom ? getDateFormat(values.validFrom, 'yyyy-MM-DD') : null;
                values.validTo = values.validTo ? getDateFormat(values.validTo, 'yyyy-MM-DD') : null;
                return values;
            }}
        >
            <div style={{ maxHeight: '650px', overflowY: 'auto', overflowX: 'hidden' }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('orderView.divider.1')} key="1">
                                {isDisabled && (
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <TravelWarrantList url={CONSTANTS_REQ.TRAVEL_WAGE.TW_LIST} id={dataForm} />
                                        </Col>
                                    </Row>
                                )}
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="travelWarrantList" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('travelWage.edit.name')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input ref={focusInput} disabled={isDisabled} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWage.edit.amount')}
                                            name="amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                                {
                                                    validator: validateAmount,
                                                },
                                            ]}
                                        >
                                            <NyInputNumber
                                                isDecimal={true}
                                                min={0}
                                                style={{ width: '100%' }}
                                                nyTestId="travel-wage-amount"
                                                disabled={isDisabled}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWage.edit.country')}
                                            name="country"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.COUNTRY.SEARCH}
                                                onChange={(val: any) => {
                                                    form.setFieldsValue({ country: val?.id == -1 ? null : val });
                                                    form.validateFields();
                                                }}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="name"
                                                SearchPopupComponent={<CountryIndex />}
                                                AddNewModalComponent={canCreateCodebooks() ? CountryEdit : null}
                                                nyTestId="travel-wage-country"
                                                setDefaultFilterValue={setDefaultFilterValue}
                                                disabled={isDisabled}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <CurrencySearch
                                            required={true}
                                            onChange={(val: any) => {
                                                form.setFieldsValue({ currency: val?.id == -1 ? null : val });
                                                form.validateFields();
                                            }}
                                            label={geti18nText('travelWage.edit.currency')}
                                            nyTestId="travel-wage-currency"
                                            noClassName={false}
                                            edit={canCreateCodebooks()}
                                            disabled={isDisabled}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantType.table.column.wageType')}
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={setEnumFormat('TRAVEL_WARRANT_WAGE_TYPE', 1)}
                                        >
                                            <NySearchField
                                                options={getEnumArray('TRAVEL_WAGE_TYPE')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                className=""
                                                style={{ width: '100%' }}
                                                onChange={wageTypeOnChange}
                                                disabled={isDisabled}
                                                allowClear={false}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {isNonStandardWage ? (
                                        <Col span={12}>
                                            <BusinessUnitSearch
                                                formItemName={'businessUnit'}
                                                disabled={isDisabled}
                                                required={true}
                                                setDefaultFilterValue={setDefaultFilterValue}
                                            />
                                        </Col>
                                    ) : (
                                        ''
                                    )}
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantType.table.column.validFrom')}
                                            name="validFrom"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                nyTestId="addition-valid-from"
                                                disabled={isDisabled}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelWarrantType.table.column.validTo')}
                                            name="validTo"
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                nyTestId="addition-valid-to"
                                                disabled={isDisabled}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('fixed.asset.panel.account')} key="2">
                                {dataForm ? (
                                    <AccountingEntityAccountIndex
                                        refId={dataForm}
                                        refType={'TRAVEL_WARRANT'}
                                        extraRefType={'TRAVEL_WAGE'}
                                        showRowSelection={true}
                                        isIndex={false}
                                        scroll={{ y: 250, x: 800 }}
                                    ></AccountingEntityAccountIndex>
                                ) : (
                                    <div></div>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        </NyDataEdit>
    );
};

export default TravelWageEdit;
