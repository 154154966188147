import { IssuesCloseOutlined, ShoppingCartOutlined, ToolOutlined } from '@ant-design/icons';
import { NySpinner, UseRequestSwrPost, geti18nText } from '@nybble/nyreact';
import { Avatar, Card, Popover, Statistic, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ServicesRights } from '../../../rights/servicesRights';
import { RootState } from '../../../rootReducer';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getColorFromNotificationType } from '../../../utils/Utils';

const ServicesOrderExtraLightWidget = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);

    const widgetColor = getColorFromNotificationType(settings, 'SERVICES_ORDER');

    const hasServicesReleaseRole = () => {
        return ServicesRights.canCreateOrder();
    };

    function getServiceOrderKeys() {
        let keys = ['myServicesOrder'];
        if (hasServicesReleaseRole()) {
            keys.push('servicesOrderApproved');
            keys.push('servicesOrderRequested');
        }
        let parms = {
            keys: keys,
        };
        return parms;
    }

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrPost(CONSTANTS_REQ.DASHBOARD.GET_STATISTICS, undefined, getServiceOrderKeys(), true, false, {
        myServicesOrder: 0,
        servicesOrderRequested: -1,
        servicesOrderApproved: -1,
    });

    const gridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px 10px',
    };

    const popoverContent = (
        <div style={{ width: '200px' }}>
            <Card
                className="ny-widget-card"
                headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
                title={geti18nText('menu.services')}
                bodyStyle={{ height: '55%', overflowY: 'auto', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
                type="inner"
                extra={
                    <Tooltip placement="top" title={geti18nText('menu.webshopServices')}>
                        <ShoppingCartOutlined
                            className="ny-card-icon"
                            onClick={() => {
                                history.push('/webshop/webshop-services');
                            }}
                        />
                    </Tooltip>
                }
            >
                {loading ? (
                    <div style={{ paddingTop: 20 }}>
                        <NySpinner />
                    </div>
                ) : (
                    <>
                        <Card.Grid
                            style={gridStyle}
                            className="statistic-card"
                            onClick={() => history.push('/webshop/webshop-services/my-services-order')}
                        >
                            <Statistic
                                title={geti18nText('dashboard.statistic.services.order.count')}
                                value={statisticData && statisticData['myServicesOrder']}
                                prefix={<ShoppingCartOutlined />}
                            />
                        </Card.Grid>
                        {statisticData && statisticData['servicesOrderRequested'] >= 0 && (
                            <Card.Grid
                                style={gridStyle}
                                className={`statistic-card statistic-card-warning`}
                                onClick={() => history.push('/webshop/webshop-services/services-order')}
                            >
                                <Statistic
                                    title={geti18nText('dashboard.statistic.services.order.requested.count')}
                                    value={statisticData['servicesOrderRequested']}
                                    prefix={<IssuesCloseOutlined />}
                                />
                            </Card.Grid>
                        )}
                        {statisticData && statisticData['servicesOrderApproved'] >= 0 && (
                            <Card.Grid
                                style={gridStyle}
                                className={`statistic-card statistic-card-warning`}
                                onClick={() => history.push('/webshop/webshop-services/services-order')}
                            >
                                <Statistic
                                    title={geti18nText('dashboard.statistic.services.order.approved.count')}
                                    value={statisticData['servicesOrderApproved']}
                                    prefix={<IssuesCloseOutlined />}
                                />
                            </Card.Grid>
                        )}
                    </>
                )}
            </Card>
        </div>
    );

    return (
        <>
            <Popover
                placement="topLeft"
                content={popoverContent}
                trigger="click"
                className={`ny-widget-card statistic-card-light`}
                overlayClassName="ny-popover-widget"
            >
                <Tooltip placement="top" title={geti18nText('menu.webshopServices')}>
                    <Avatar
                        shape="square"
                        className="ny-widget-card-extra-light"
                        icon={<ToolOutlined />}
                        style={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
                    />
                </Tooltip>
            </Popover>
        </>
    );
};

export default ServicesOrderExtraLightWidget;
