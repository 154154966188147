import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { geti18nText } from '@nybble/nyreact';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectedItemSet } from '../../../../slices/selectedItemSlice';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import GeneralEditTab from './GeneralEditTab';
import EmployeeInGroupTable from './EmployeeInGroupTable';
import EmployeeWithoutGroupTable from './EmployeeWithoutGroupTable';
import { employeeGroupInit, refreshEmployeeGroupTable } from '../../../../slices/employeeGroupSlice';
import useHelper from '../../../../hooks/useHelper';
import { RootState } from '../../../../rootReducer';
import { getColorFromNotificationType } from '../../../../utils/Utils';

const { TabPane } = Tabs;

const EmployeeGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employeeGroup.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [isFormChange, setIsFormChange] = useState(false);

    const [activeKey, setActiveKey] = useState(1);
    const { settings } = useSelector((state: RootState) => state.notification);

    const { id } = useParams<any>();
    const dispatch = useDispatch();

    const location = useLocation();
    useHelper('human/codebooks/employee_group');

    useEffect(() => {
        dispatch(selectedItemSet({ item: dataForm, refreshItem: 0, employeeId: undefined }));
        dispatch(employeeGroupInit());
    }, [dataForm]);

    useEffect(() => {
        dispatch(refreshEmployeeGroupTable());
    }, [activeKey]);

    return (
        <Tabs
            type="card"
            className="tabs-sticky"
            onChange={(key: any) => {
                setActiveKey(key);
            }}
            style={{ maxHeight: '85vh' }}
        >
            <TabPane
                tab={
                    <span>
                        {geti18nText('employeeGroup.tab.general')}
                        {isFormChange ? (
                            <ExclamationCircleTwoTone
                                twoToneColor="#faad14"
                                style={{ paddingLeft: '5px', marginRight: '0px' }}
                            />
                        ) : (
                            ''
                        )}
                    </span>
                }
                key="1"
            >
                <GeneralEditTab
                    props={props}
                    setEditHeader={setEditHeader}
                    setDataForm={setDataForm}
                    setLoading={setLoading}
                    loading={loading}
                    setIsCreate={setIsCreate}
                    setIsFormChange={setIsFormChange}
                />
            </TabPane>
            <TabPane tab={geti18nText('employeeGroup.tab.employeeGroup')} key="2" disabled={isCreate || loading}>
                <EmployeeInGroupTable scroll={{ y: 550, x: 800 }} />
            </TabPane>
            <TabPane tab={geti18nText('employeeGroup.tab.employeeWithoutGroup')} key="3" disabled={isCreate || loading}>
                <EmployeeWithoutGroupTable scroll={{ y: 550, x: 800 }} />
            </TabPane>
        </Tabs>
    );
};

export default EmployeeGroupEdit;
