import { useState } from 'react';
import TravelWarrantSettingsIndex from '../../../administration/views/application-settings/TravelWarrantSettingsIndex';
import { useLocation } from 'react-router';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelWarrantSettings = () => {
    const [dirty, setDirty] = useState<any>({
        companyInformation: false,
        employeeSettings: false,
        payroll: false,
        emailSettings: false,
        asset: false,
        travelWarrantSettings: false,
        printSettings: false,
        employeeLeaveSettings: false,
    });

    const location = useLocation();

    return (
        <TravelWarrantSettingsIndex
            setDirty={setDirty}
            dirty={dirty}
            canCreate={TravelWarrantRights.canCreateAppSettings()}
        />
    );
};

export default TravelWarrantSettings;
