import { BulbOutlined, PlusOutlined } from '@ant-design/icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Tag, Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_FILTER } from '../../../../../utils/Constants';
import { checkIsHoliday, checkIsWeekend } from '../../../../../utils/Utils';
import MealMenuEdit from './edit';
import MenuItems from './MenuItems';

const MealsMenuNyFullCalendar = ({
    keys = ['menus'],
    initialView = 'dayGridWeek',
    firstDay = 1,
    height = '200px',
    showHeaderToolbar = true,
    defaultAllDay = true,
    navLinks = true,
    stickyHeaderDates = true,
    showNonCurrentDates = true,
    weekends = true,
    editable = false,
    selectable = true,
    expandRows = true,
    employee,
    refreshView,
    setRefreshView,
    canCreate,
    canCreateAdmin,
}: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const currentLang = NyUtils.getSelectedLanguage();
    const [keysCalendar, setKeysCalendar] = useState<any>(keys);
    const [eventsMenus, setEventsMenus] = useState<any>([]);

    const [startDate, setStartDate] = useState<any>(moment().startOf('week').format('yyyy-MM-DD HH:mm').toString());
    const [endDate, setEndDate] = useState<any>(moment().endOf('week').format('yyyy-MM-DD HH:mm').toString());

    const [menuModal, setMenuModal] = useState<boolean>(false);
    const [menu, setMenu] = useState<any>(undefined);
    const [selectedDateMenu, setSelectedDateMenu] = useState<any>(null);
    const [holidays, setHolidays] = useState([]);

    document.querySelectorAll('button.fc-today-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridWeek-button')?.forEach((element) => element.setAttribute('title', ''));
    document
        .querySelectorAll('button.fc-prev-button')
        ?.forEach((element) => element.setAttribute('title', geti18nText('app.default.filter.range.prevWeek')));
    document
        .querySelectorAll('button.fc-next-button')
        ?.forEach((element) => element.setAttribute('title', geti18nText('app.default.filter.range.nextWeek')));

    const getHolidayName = (date: any) => {
        let dateValueDate = moment(date).format('DD.MM.YYYY');
        const holiday: any = holidays.find((day: any) => day.date == dateValueDate);
        if (holiday && holiday.active) {
            return holiday.name;
        } else {
            return false;
        }
    };

    const hasKey = (key: any) => {
        return (
            keysCalendar &&
            keysCalendar.filter((k: any) => k.includes(key)) &&
            keysCalendar.filter((k: any) => k.includes(key)).length > 0
        );
    };

    const getNoMenusInHistory = (date: any) => {
        const dateValueDate = moment(date).format('DD.MM.YYYY');
        const todayDate = moment().format('DD.MM.YYYY');
        return (
            moment(dateValueDate, 'DD.MM.YYYY').isSameOrAfter(moment(todayDate, 'DD.MM.YYYY')) &&
            !checkIsHoliday(date, holidays) &&
            !checkIsWeekend(date)
        );
    };

    useEffect(() => {
        fetchHolidays();
    }, []);

    useEffect(() => {
        if (startDate && hasKey('menus')) {
            fetchMenus();
        }
    }, [refreshView, startDate, holidays]);

    const fetchMenus = async () => {
        const resolvedData: any = [];
        let date = moment(startDate, 'DD.MM.YYYY');
        let dateEnd = moment(endDate, 'DD.MM.YYYY');
        for (let i: number = 1; i <= 7; i++) {
            if (date.isSameOrBefore(dateEnd) && getNoMenusInHistory(date)) {
                const object: any = {};
                object.tempId = Number(date);
                object.type = 'addNewMenu';
                object.start = moment(date).format('yyyy-MM-DD');
                object.end = moment(date).format('yyyy-MM-DD');
                object.startDate = moment(date).format('yyyy-MM-DD');
                await resolvedData.push(object);
            } else {
                const holidayName = getHolidayName(date);
                if (holidayName && holidayName != false) {
                    const object: any = {};
                    object.tempId = Number(date);
                    object.type = 'holiday';
                    object.name = holidayName;
                    object.color = '#D9FFE1';
                    object.start = moment(date).format('yyyy-MM-DD');
                    object.end = moment(date).format('yyyy-MM-DD');
                    await resolvedData.push(object);
                }
            }
            date = date.add(1, 'days');
        }

        let dateFrom = moment(startDate).format('yyyy-MM-DD HH:mm');
        let dateTo = moment(endDate).format('yyyy-MM-DD HH:mm');

        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_MENU.CALENDAR, {
            dateFrom: dateFrom,
            dateTo: dateTo,
            search: encodeURI(JSON.stringify(DEFAULT_FILTER)),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                Object.entries(result.data).map((key: any, value: any) => {
                    let element: any = {};
                    element.start = moment(key[0]).format('yyyy-MM-DD');
                    element.end = moment(key[0]).format('yyyy-MM-DD');
                    element.color = theme === 'dark' ? '#192a3f' : 'white';
                    element.type = 'menu';
                    element.menus = key[1];
                    const compareDay = Number(moment(element.start));
                    const index = resolvedData.findIndex((object: any) => object.tempId == compareDay);
                    if (index > -1) {
                        const item = resolvedData[index];
                        element.dateFrom = item.start;
                        resolvedData.splice(index, 1, { ...item, ...element });
                    } else {
                        resolvedData.push(element);
                    }
                });
            }
            setEventsMenus(resolvedData);
        });
    };

    const fetchHolidays = () => {
        let active = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOT_WORKING_DAYS.ALL_NOT_WORKING_DAYS, {
            search: active,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setHolidays(
                        result.data.map((day: any) => {
                            return {
                                date: moment(day.date).format('DD.MM.YYYY.'),
                                name: day.name,
                                active: day.active,
                            };
                        })
                    );
                }
            }
        });
    };

    const canAddNewMenu = (date: any) => {
        return date && getNoMenusInHistory(moment(date));
    };

    const resolveRenderContentType = (elementValue: any) => {
        switch (elementValue.type) {
            case 'addNewMenu':
                return (
                    <Button
                        icon={<PlusOutlined />}
                        disabled={!canCreate}
                        style={{ width: '100%', height: '525px' }}
                        onClick={() => {
                            if (canCreateAdmin) {
                                setSelectedDateMenu(elementValue.startDate);
                                setMenuModal(true);
                            }
                        }}
                    />
                );
            case 'menu':
                return (
                    <>
                        <div style={{ display: 'flex' }}>
                            <div
                                style={{
                                    fontSize: '14px',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    padding: '5px',
                                    width: '100%',
                                    height: canAddNewMenu(elementValue.dateFrom) ? '486px' : '511px',
                                }}
                            >
                                <MenuItems menus={elementValue.menus} setMenu={setMenu} setMenuModal={setMenuModal} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: ' center' }}>
                            {canAddNewMenu(elementValue.dateFrom) && (
                                <Button
                                    icon={<PlusOutlined />}
                                    disabled={!canCreate}
                                    style={{ width: '25px', height: '25px' }}
                                    onClick={() => {
                                        if (canCreateAdmin) {
                                            setSelectedDateMenu(elementValue.dateFrom);
                                            setMenuModal(true);
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div style={{ height: '10px' }} />
                    </>
                );
            case 'holiday':
                return (
                    <div style={{ width: '100%', height: '525px', textAlign: 'center' }}>
                        <Tooltip
                            placement="topLeft"
                            title={
                                <Tag icon={<BulbOutlined style={{ fontSize: '12px' }} />} style={{ fontSize: '12px' }}>
                                    {elementValue.name}
                                </Tag>
                            }
                        >
                            <Tag
                                icon={<BulbOutlined />}
                                style={{ fontSize: '12px', margin: '10px', padding: '15 5 15 5', textAlign: 'center' }}
                            >
                                {elementValue.name}
                            </Tag>
                        </Tooltip>
                    </div>
                );
            default:
                return <div></div>;
        }
    };

    function renderEventContent(eventInfo: any) {
        let elementValue: any = null;
        if (eventInfo && eventInfo.event && eventInfo.event._def && eventInfo.event._def.extendedProps) {
            elementValue = { ...eventInfo.event._def.extendedProps };
            if (!elementValue.id && elementValue.tempId) {
                elementValue.id = elementValue.tempId;
            }
            if (elementValue) {
                return resolveRenderContentType(elementValue);
            }
        }
    }

    return (
        <React.Fragment>
            <div style={{ fontSize: '10px' }}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView={initialView}
                    events={[...eventsMenus]}
                    locale={currentLang}
                    headerToolbar={
                        showHeaderToolbar
                            ? {
                                  left: 'prev,next today',
                                  center: 'title',
                                  right: 'dayGridMonth,dayGridWeek,listMonth',
                              }
                            : {
                                  left: 'prev,next today',
                                  center: 'title',
                                  right: 'dayGridWeek',
                              }
                    }
                    buttonText={{
                        today: geti18nText('app.default.filter.range.today'),
                        month: geti18nText('app.default.filter.range.month'),
                        week: geti18nText('app.default.filter.range.week'),
                        day: geti18nText('app.default.filter.range.day'),
                        list: geti18nText('app.default.list'),
                    }}
                    defaultAllDay={defaultAllDay}
                    firstDay={firstDay}
                    navLinks={navLinks}
                    stickyHeaderDates={stickyHeaderDates}
                    showNonCurrentDates={showNonCurrentDates}
                    height={height}
                    weekends={weekends}
                    editable={editable}
                    selectable={selectable}
                    expandRows={expandRows}
                    eventContent={renderEventContent}
                    datesSet={(dateInfo) => {
                        setStartDate(dateInfo.start);
                        setEndDate(dateInfo.end);
                    }}
                />
            </div>
            {menuModal && (
                <MealMenuEdit
                    editProps={{
                        visible: menuModal,
                        setVisible: setMenuModal,
                        value: menu,
                        setValue: setMenu,
                        refresh: refreshView,
                        setRefresh: setRefreshView,
                        selectedDateMenu: selectedDateMenu,
                        scroll: { y: 400, x: 500 },
                        sortOrder: null,
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default MealsMenuNyFullCalendar;
