import {
    AppstoreOutlined,
    BlockOutlined,
    BorderOuterOutlined,
    CloseOutlined,
    LockOutlined,
    PlusCircleOutlined,
    UnlockOutlined,
} from '@ant-design/icons';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Affix, Button, Col, Dropdown, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import { useLocation } from 'react-router-dom';
import useHelper from '../../hooks/useHelper';
import { RootState } from '../../rootReducer';
import {
    defineDefaultLayout,
    layoutChange,
    removeWidget,
    setDefaultWidgetsValue,
    setLock,
} from '../../slices/dashboardSlice';
import AssetOrderWidget from './AssetOrderWidget';
import DirectoryWidget from './DirectoryWidget';
import EmployeeLeavePersonalWidget from './EmployeeLeavePersonalWidget';
import InventoryOrderWidget from './InventoryOrderWidget';
import KioskWidget from './KioskWidget';
import AssetOrderExtraLightWidget from './light-widgets/AssetOrderExtraLightWidget';
import AssetLightWidget from './light-widgets/AssetOrderLightWidget';
import EmployeeLeavePersonalExtraLightWidget from './light-widgets/EmployeeLeavePersonalExtraLightWidget';
import EmployeeLeavePersonalLightWidget from './light-widgets/EmployeeLeavePersonalLightWidget';
import InventoryOrderExtraLightWidget from './light-widgets/InventoryOrderExtraLightWidget';
import InventoryOrderLightWidget from './light-widgets/InventoryOrderLightWidget';
import ServicesOrderExtraLightWidget from './light-widgets/ServicesOrderExtraLightWidget';
import ServicesOrderLightWidget from './light-widgets/ServicesOrderLightWidget';
import TravelWarrantExtraLightWidget from './light-widgets/TravelWarrantExtraLightWidget';
import TravelWarrantLightWidget from './light-widgets/TravelWarrantLightWidget';
import TravelWarrantPersonalExtraLightWidget from './light-widgets/TravelWarrantPersonalExtraLightWidget';
import TravelWarrantPersonalLightWidget from './light-widgets/TravelWarrantPersonalLightWidget';
import LinksWidget from './LinksWidget';
import MealsMenuWidget from './MealsMenuWidget';
import NotificationWidget from './NotificationWidget';
import PersonalCalendarWidget from './PersonalCalendarWidget';
import PersonalNotesWidget from './PersonalNotesWidget';
import ServicesOrderWidget from './ServicesOrderWidget';
import TravelWarrantPersonalWidget from './TravelWarrantPersonalWidget';
import TravelWarrantWidget from './TravelWarrantWidget';
import WidgetModal from './WidgetModal';
import { AdministrationRights } from '../../rights/administrationRights';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface IWidget {
    dataGrid: { [index: string]: string | number | boolean };
    elementKey: string;
    data: any;
    overviewIndex?: boolean;
}

const widgetCatalogs: any = {
    AssetLightWidget: AssetLightWidget,
    AssetOrderWidget: AssetOrderWidget,
    DirectoryWidget: DirectoryWidget,
    InventoryOrderWidget: InventoryOrderWidget,
    AssetOrderExtraLightWidget: AssetOrderExtraLightWidget,
    InventoryOrderExtraLightWidget: InventoryOrderExtraLightWidget,
    InventoryOrderLightWidget: InventoryOrderLightWidget,
    ServicesOrderExtraLightWidget: ServicesOrderExtraLightWidget,
    ServicesOrderLightWidget: ServicesOrderLightWidget,
    TravelWarrantExtraLightWidget: TravelWarrantExtraLightWidget,
    TravelWarrantLightWidget: TravelWarrantLightWidget,
    TravelWarrantPersonalExtraLightWidget: TravelWarrantPersonalExtraLightWidget,
    TravelWarrantPersonalLightWidget: TravelWarrantPersonalLightWidget,
    EmployeeLeavePersonalExtraLightWidget: EmployeeLeavePersonalExtraLightWidget,
    EmployeeLeavePersonalLightWidget: EmployeeLeavePersonalLightWidget,
    LinksWidget: LinksWidget,
    NotificationWidget: NotificationWidget,
    PersonalCalendarWidget: PersonalCalendarWidget,
    PersonalNotesWidget: PersonalNotesWidget,
    ServicesOrderWidget: ServicesOrderWidget,
    TravelWarrantPersonalWidget: TravelWarrantPersonalWidget,
    TravelWarrantWidget: TravelWarrantWidget,
    EmployeeLeavePersonalWidget: EmployeeLeavePersonalWidget,
    KioskWidget: KioskWidget,
    MealsMenuWidget: MealsMenuWidget,
};

const DashboardIndex = ({ canEdit = true, widgetsToUse = null, customStyle = false }: any) => {
    const dispatch = useDispatch();
    const { layouts, lastLayout, lock, widgets, allWidgets } = useSelector((state: RootState) => state.dashboard);
    const { collapsed } = useSelector((state: RootState) => state.menu);
    const { pinedNotifications } = useSelector((state: RootState) => state.pinedNotifications);
    const [addWidgetModal, setAddWidgetModal] = useState<any>(false);
    const [userWidgets, setUserWidgets] = useState<any>([]);

    useHelper('dashboard');

    const canDefine = () => {
        return AdministrationRights.canCreateDashboardSettings();
    };

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [pinedNotifications]);

    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
    }, [collapsed]);

    useEffect(() => {
        const tempWidgets: any = widgetsToUse?.length > 0 ? widgetsToUse : widgets?.length > 0 ? widgets : null;
        if (tempWidgets) {
            const items = tempWidgets.map((el: any) => {
                if ((el.role && NySession.hasAnyRole(el.role)) || !el.role) {
                    return createElement(el);
                }
            });

            setUserWidgets(items);
        }
    }, [widgets, widgetsToUse]);

    const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
        if (!widgetsToUse?.length) {
            dispatch(layoutChange({ lastLayout: currentLayout, layouts: allLayouts }));
        }
    };

    const onRemoveItem = (key: string) => {
        dispatch(removeWidget({ key: key }));
    };

    const createElement = (obj: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
        data: any;
    }) => {
        let newLastLayout: { [index: string]: any } | undefined = undefined;
        if (lastLayout && lastLayout.length > 0) {
            const ly = lastLayout.find(({ i }: any) => i === obj.key);
            if (ly != undefined) newLastLayout = { ...ly };
            delete newLastLayout?.i;
        }
        let overviewIndex = true;

        let Tagname = widgetCatalogs[obj.component];
        let settings: IWidget = {
            dataGrid: newLastLayout != undefined ? newLastLayout : obj.dataGrid,
            elementKey: obj.key,
            data: obj.data,
        };

        let useMarquee: boolean = settings.elementKey.startsWith('MealsMenuWidget') && settings?.dataGrid?.w <= 2;

        const w = React.createElement(
            Tagname,
            useMarquee ? { ...settings, overviewIndex: overviewIndex } : settings,
            ''
        );

        const remove = React.createElement(
            'span',
            {
                className: 'ny-widget-remove',
                onClick: () => onRemoveItem(obj.key),
            },
            <CloseOutlined style={{ fontSize: '16px' }} />
        );
        return React.createElement(
            'div',
            {
                id: 'container_' + obj.key,
                key: obj.key,
                'data-grid': obj.dataGrid,
                className: `dashboard-widget-box ${lock ? '' : 'dashboard-widget-box-unlock'}`,
            },
            w,
            !lock ? remove : <></>,
            overviewIndex
        );
    };

    const setDefaultWidget = async () => {
        dispatch(setDefaultWidgetsValue());
    };

    const setLockWidget = () => {
        dispatch(setLock({ widgets: widgets, allWidgets: allWidgets }));
    };

    const defineLayout = () => {
        if (canDefine()) {
            dispatch(defineDefaultLayout({ widgets: widgets, allWidgets: allWidgets }));
        }
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <React.Fragment>
                    {!lock && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button block icon={<PlusCircleOutlined />} onClick={() => setAddWidgetModal(true)}>
                                {geti18nText('dashboard.button.add')}
                            </Button>
                        </div>
                    )}
                    {!lock && canDefine() && (
                        <div style={{ display: 'block', margin: '5px' }}>
                            <Button block icon={<BorderOuterOutlined />} onClick={defineLayout}>
                                {geti18nText('dashboard.button.define')}
                            </Button>
                        </div>
                    )}
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button block icon={<AppstoreOutlined />} onClick={setDefaultWidget}>
                            {geti18nText('dashboard.button.default')}
                        </Button>
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button block icon={lock ? <UnlockOutlined /> : <LockOutlined />} onClick={setLockWidget}>
                            {geti18nText(lock ? 'dashboard.button.unlock' : 'dashboard.button.lock')}
                        </Button>
                    </div>
                </React.Fragment>
            </div>
        </Menu>
    );

    return (
        <React.Fragment>
            <Row
                gutter={24}
                style={{ marginLeft: customStyle ? '-32px' : undefined, marginTop: customStyle ? '-20px' : undefined }}
            >
                <Col span={24}>
                    <ResponsiveReactGridLayout
                        className="layout"
                        cols={{ lg: 12, md: 12, sm: 4, xs: 2, xxs: 2 }}
                        layouts={layouts}
                        rowHeight={47}
                        margin={[20, 20]}
                        onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
                    >
                        {userWidgets && userWidgets.length > 0 && userWidgets}
                    </ResponsiveReactGridLayout>
                </Col>
            </Row>
            {canEdit && (
                <Affix style={{ position: 'fixed', bottom: 50, right: pinedNotifications == 1 ? 350 : 50 }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['hover']} placement="bottomRight">
                        <Button
                            className="ny-fixed-widgets-button"
                            shape="circle"
                            size="large"
                            icon={<BlockOutlined style={{ fontSize: '20px' }} />}
                        />
                    </Dropdown>
                </Affix>
            )}
            {addWidgetModal && (
                <WidgetModal modalVisible={addWidgetModal} setModalVisible={setAddWidgetModal} dispatch={dispatch} />
            )}
        </React.Fragment>
    );
};

export default DashboardIndex;
