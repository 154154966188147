import { NyDataTable, NyUtils, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookSimple, getColumnSearchWithCode } from '../../../../utils/Filters';
import EmployeeRewardsEdit from './EmployeeRewardsEdit';

const EmployeeRewardsIndex = ({ readonly = false }) => {
    const table = useTableSettings();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee.id', condition: 'equals', value: id },
        ];
    };

    const columns = [
        {
            title: geti18nText('employee.rewards.table.rewardType'),
            dataIndex: ['rewardType', 'name'],
            width: '10%',
            ...getColumnSearchWithCode(CONSTANTS_REQ.REWARD_TYPE.SEARCH, 'rewardType.id'),
            sorter: (a: any, b: any) => {},
            render: (description: any, record: any) => {
                if (record && record.rewardType) {
                    return record.rewardType.name + (record.rewardType.code ? ' (' + record.rewardType.code + ')' : '');
                }
            },
        },
        {
            title: geti18nText('employee.rewards.table.year'),
            dataIndex: 'year',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employee.rewards.table.entryDate'),
            dataIndex: 'entryDate',
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.entryDate) {
                    return moment(record.entryDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.rewards.table.paymentDate'),
            dataIndex: 'paymentDate',
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.paymentDate) {
                    return moment(record.paymentDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.rewards.table.accountingCostCenter'),
            dataIndex: ['accountingCostCenter', 'name'],
            width: '18%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchWithCode(CONSTANTS_REQ.COST_CENTER.SEARCH, 'accountingCostCenter.id'),
            render: (description: any, record: any) => {
                if (record && record.accountingCostCenter) {
                    return (
                        record.accountingCostCenter.name +
                        (record.accountingCostCenter.code ? ' (' + record.accountingCostCenter.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('employee.rewards.table.amount'),
            dataIndex: 'amount',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any, record: any) => {
                return <div style={{ float: 'right' }}>{NyUtils.formatNumber(record?.amount ?? 0, 2)}</div>;
            },
        },
        {
            title: geti18nText('employee.rewards.table.currency'),
            dataIndex: ['currency', 'isoCode'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('employee.rewards.table.amountInCurrency'),
            dataIndex: 'amountInCurrency',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amount: any, record: any) => {
                return <div style={{ float: 'right' }}>{NyUtils.formatNumber(record?.amountInCurrency ?? 0, 2)}</div>;
            },
        },
        {
            title: geti18nText('employee.rewards.table.domicileCurrency'),
            dataIndex: ['domicileCurrency', 'isoCode'],
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
    ];

    return (
        <NyDataTable
            nyId="human-employee-rewards-table"
            url={CONSTANTS_REQ.REWARDS.LIST}
            addNewButtonText={geti18nText('employee.rewards.table.add')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={EmployeeRewardsEdit}
            columns={columns}
            scroll={{ y: 680, x: 800 }}
            onDataLoaded={table.onDataLoaded}
            readonly={readonly}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            hideNewButton={!canCreate()}
            addedData={{ employeeId: id }}
            shortcuts={true}
        />
    );
};

export default EmployeeRewardsIndex;
