import { CloseOutlined, EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver } from '@nybble/nyreact';
import { Button, Checkbox, Col, Row, Select, Timeline, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';

const { Text } = Typography;

const TimelineItem = ({
    waypointId,
    onSelect,
    onClear,
    last,
    form,
    index,
    defaultValue,
    disabled = false,
    returnRoute = false,
    onNameChange = undefined,
    isPrint = false,
    disableEdit = false,
    showCheckboxesDestinationName = false,
    waypointShowDestinationName = false,
    onWaypointCheckboxChange = undefined,
}: any) => {
    let timeout: any = null;
    let miliseconds = 500;

    const [options, setOptions] = useState<any>([]);
    const [value, setValue] = useState<any>(defaultValue);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const getUniqueValues = (array: any) => {
        return array.filter((obj: any, index: any, self: any) => {
            return index === self.findIndex((t: any) => t.text === obj.text);
        });
    };

    function displayName(obj: any) {
        let display = '';

        if (obj.address.village) {
            display += obj.address.village + ', ';
        }
        if (obj.address.city) {
            display += obj.address.city + ', ';
        }
        if (obj.address.municipality) {
            display += obj.address.municipality + ', ';
        }
        if (obj.address.town) {
            display += obj.address.town + ', ';
        }
        if (obj.address.tourism) {
            display += obj.address.tourism + ', ';
        }
        if (obj.address.county) {
            display += obj.address.county + ', ';
        }
        if (obj.address.road) {
            display += obj.address.road + ', ';
        }
        if (obj.address.house_number) {
            display += obj.address.house_number + ', ';
        }
        if (obj.address.postcode) {
            display += obj.address.postcode + ', ';
        }
        if (obj.address.country) {
            display += obj.address.country + '';
        }
        return display;
    }

    const onSearch = (val: any) => {
        if (val) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(async () => {
                await fetch(
                    `https://nominatim.openstreetmap.org/search?q=${val}&format=json&addressdetails=1&accept-language=hr-HR`,
                    {
                        method: 'GET',
                        body: null,
                        headers: {
                            accept: 'application/json',
                        },
                    }
                )
                    .then((response) =>
                        response
                            .json()
                            .then((data) => ({
                                data: data,
                                status: response.status,
                            }))
                            .then(async (res) => {
                                if (res?.data?.length > 0) {
                                    let opt = res.data.map((city: any) => {
                                        return {
                                            text: displayName(city),
                                            id: city.place_id,
                                            lat: city.lat,
                                            lng: city.lon,
                                            countryCode: city.address.country_code,
                                            city:
                                                city?.address?.village ??
                                                city?.address?.city ??
                                                city?.address?.town ??
                                                city?.address?.municipality ??
                                                '',
                                        };
                                    });
                                    setOptions(getUniqueValues(opt));
                                }
                            })
                    )
                    .catch(() => {
                        // message.destroy();
                        // message.error(`${file?.name} ${geti18nText('import.text.error')}`);
                    })
                    .finally(() => {});
                // addTravelRelation(val);
                if (onNameChange != undefined) {
                    onNameChange(val, index, returnRoute);
                }
            }, miliseconds);
        }
    };

    const capitalize = (string: any) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const addTravelRelation = (name: any) => {
        name = name === '' ? ' ' : capitalize(name);

        let travelRelation = form.getFieldValue(['name']) ? form.getFieldValue(['name']) : '';
        if (travelRelation === '') {
            travelRelation = name;
        } else {
            let travelRelationArray = travelRelation.split(' - ');
            travelRelationArray[index] = name;
            travelRelation = travelRelationArray.join(' - ');
        }
        form.setFieldsValue({ name: travelRelation });
    };

    const clearTravelRelation = () => {
        let travelRelation = form.getFieldValue(['name']) ? form.getFieldValue(['name']) : '';
        if (travelRelation !== '') {
            let travelRelationArray = travelRelation.split(' - ');
            delete travelRelationArray[index];
            travelRelationArray = travelRelationArray.filter((relation: any) => relation !== undefined);
            travelRelation = travelRelationArray.join(' - ');
        }
        form.setFieldsValue({ name: travelRelation });
    };

    const getItemLabel = (index: any) => {
        const intermediate = geti18nText('travelWarrantRelation.intermediate') + ' ' + index + '.';
        return (
            <span style={{ fontWeight: '500' }}>
                {last === true
                    ? geti18nText('travelWarrantRelation.destination')
                    : waypointId > 0
                    ? intermediate
                    : geti18nText('travelWarrantRelation.startLocation')}
            </span>
        );
    };

    const getItemReturnRouteLabel = (index: any) => {
        const intermediate = geti18nText('travelWarrantWizard.step2.returnRoute.returnPoint') + ' ' + (index + 1) + '.';
        return (
            <span style={{ fontWeight: '500' }}>
                {index === -1 ? geti18nText('travelWarrantWizard.step2.returnRoute.returnPoint.end') : intermediate}
            </span>
        );
    };

    return (
        <Timeline.Item
            dot={
                last === true && returnRoute == false ? <EnvironmentOutlined style={{ fontSize: '16px' }} /> : undefined
            }
        >
            <Row gutter={24}>
                <Col span={24}>
                    {returnRoute == false ? getItemLabel(index) : getItemReturnRouteLabel(index)}
                    {((returnRoute == false && waypointId > 1 && !last && !disabled) ||
                        (returnRoute == true && index != -1)) && (
                        <>
                            <Tooltip placement="top" title={geti18nText('travelWarrantRelation.removeDestination')}>
                                <Button
                                    style={{ float: 'right', marginRight: '-5px' }}
                                    type="link"
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        onClear(waypointId);
                                        //clearTravelRelation();
                                    }}
                                >
                                    {!isPrint && <CloseOutlined style={{ color: '#ff7875' }} />}
                                </Button>
                            </Tooltip>
                            {showCheckboxesDestinationName && (
                                <Tooltip
                                    placement="top"
                                    title={geti18nText('travelWarrantRelation.showDestinationNameInFormField')}
                                >
                                    <Checkbox
                                        style={{ float: 'right', marginTop: '5px', marginRight: '5px' }}
                                        checked={waypointShowDestinationName}
                                        onChange={(e: any) => {
                                            onWaypointCheckboxChange(waypointId, e.target.checked);
                                        }}
                                    ></Checkbox>
                                </Tooltip>
                            )}
                        </>
                    )}
                    <Select
                        bordered={false}
                        autoFocus={false}
                        style={{ borderBottom: '#1890ff solid 2px', borderRadius: '0px', width: '100%' }}
                        showSearch
                        onSearch={onSearch}
                        onSelect={onSelect}
                        notFoundContent={options.length > 0 ? true : false}
                        suffixIcon={!isPrint ? <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} /> : ''}
                        filterOption={false}
                        labelInValue={true}
                        disabled={disableEdit}
                        value={
                            value && value.label != '' ? (
                                value
                            ) : (
                                <Text>{geti18nText('travelWarrantRelation.placeholder')}</Text>
                            )
                        }
                        placeholder={geti18nText('travelWarrantRelation.placeholder')}
                    >
                        {options.map((option: any) => (
                            <Select.Option
                                waypointId={waypointId}
                                key={option.id}
                                value={option.id}
                                lat={option.lat}
                                lng={option.lng}
                                name={option.text}
                                placeId={option.id}
                                countryCode={option.countryCode}
                                address={option}
                            >
                                {option.text}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
        </Timeline.Item>
    );
};

export default TimelineItem;
