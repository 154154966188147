import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { NyDataEdit, NyInputNumber, NySearchField, NySession, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JoppdCodeIndex from '../../../../components/joppd-code';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    formatJoppdCodeSearch,
    getEnumArray,
    getEnumFormat,
    setEnumFormat,
    setJoppdCodeDefaultFilterValue,
} from '../../../../utils/Utils';
import AccountingEntityAccountIndex from '../../../accounting/views/accounting-entity-account';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const { Panel } = Collapse;

const TravelExpenseEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('travelExpense.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1']);
    const [showTooltip, setShowTooltip] = useState(false);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [dataForm, setDataForm] = useState(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setCollapseActiveKeys(['1', '2']);
            setEditHeader(geti18nText('travelExpense.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.joppdTaxFreeCode) {
            dataForm.joppdTaxFreeCode = await formatJoppdCodeSearch(dataForm.joppdTaxFreeCode, 2, '15.1.');
        }
        if (dataForm.joppdPaymentCode) {
            dataForm.joppdPaymentCode = await formatJoppdCodeSearch(dataForm.joppdPaymentCode, 2, '16.1.');
        }
        if (dataForm.bookExpense !== undefined) {
            dataForm.bookExpense = setEnumFormat('BOOK_OPTIONS', dataForm.bookExpense);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setDataForm(null);
        form.resetFields();
        setEditHeader(geti18nText('travelExpense.edit.new'));
    };

    const onModalOpen = async () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const setDefaultFilterValue = (type: String) => {
        return setJoppdCodeDefaultFilterValue(2, type);
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    ) : (
                        <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                    )}
                </Tooltip>
            </div>
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_EXPENSE.EDIT}
            setValues={setValues}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            customFooterContent={getCustomFooterContent}
            onBeforeSave={() => {
                if (!form.getFieldValue('bookExpense') || form.getFieldValue('bookExpense')?.id == -1) {
                    form.setFieldsValue({ bookExpense: undefined });
                    form.validateFields();
                    return;
                }
            }}
            normalize={(values: any) => {
                values.reimbursed = values.reimbursed ?? false;
                values.includeInJoppd = values.includeInJoppd ?? false;
                if (values.joppdTaxFreeCode) values.joppdTaxFreeCode = values.joppdTaxFreeCode.code;
                if (values.joppdPaymentCode) values.joppdPaymentCode = values.joppdPaymentCode.code;
                if (values.bookExpense) values.bookExpense = getEnumFormat(values.bookExpense);
                return values;
            }}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            leaveNewModalOpen={true}
        >
            <div style={{ maxHeight: '650px', overflowY: 'auto', overflowX: 'hidden' }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('orderView.divider.1')} key="1">
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={geti18nText('travelExpense.edit.name')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input ref={focusInput} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item
                                            label={geti18nText('travelExpense.edit.reimbursed')}
                                            name="reimbursed"
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label={geti18nText('travelExpense.edit.includeInJoppd')}
                                            name="includeInJoppd"
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelExpense.edit.joppdTaxFreeCode')}
                                            name="joppdTaxFreeCode"
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                                setDefaultFilterValue={() => setDefaultFilterValue('15.1.')}
                                                map={{ id: 'id', label: 'code' }}
                                                searchBy="code"
                                                nyTestId="joppdTaxFreeCode"
                                                SearchPopupComponent={
                                                    <JoppdCodeIndex
                                                        defaultFilteredValue={() => setDefaultFilterValue('15.1.')}
                                                    />
                                                }
                                                itemName="code"
                                                order="code"
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelExpense.edit.joppdPaymentCode')}
                                            name="joppdPaymentCode"
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                                setDefaultFilterValue={() => setDefaultFilterValue('16.1.')}
                                                map={{ id: 'id', label: 'code' }}
                                                searchBy="code"
                                                nyTestId="joppdPaymentCode"
                                                SearchPopupComponent={
                                                    <JoppdCodeIndex
                                                        defaultFilteredValue={() => setDefaultFilterValue('16.1.')}
                                                    />
                                                }
                                                itemName="code"
                                                order="code"
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('travelExpense.edit.bookExpense')}
                                            name="bookExpense"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                options={getEnumArray('BOOK_OPTIONS')}
                                                map={{ id: 'id', label: 'text' }}
                                                searchBy="text"
                                                style={{ width: '100%' }}
                                                className={''}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('travelExpense.edit.sort')} name="sort">
                                            <NyInputNumber style={{ width: '100%' }} min={1} nyTestId="sort" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('fixed.asset.panel.account')} key="2">
                                {dataForm ? (
                                    <AccountingEntityAccountIndex
                                        refId={dataForm}
                                        refType={'TRAVEL_WARRANT'}
                                        extraRefType={'TRAVEL_EXPENSE'}
                                        showRowSelection={true}
                                        isIndex={false}
                                        scroll={{ y: 250, x: 800 }}
                                    ></AccountingEntityAccountIndex>
                                ) : (
                                    <div></div>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        </NyDataEdit>
    );
};

export default TravelExpenseEdit;
