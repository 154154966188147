import { NyDataTable, geti18nText } from '@nybble/nyreact';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import EmployeeEvaluationPeriodEdit from './period-edit';
import moment from 'moment';

const EmployeeEvaluationPeriodIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = { y: 680, x: 400 },
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const columns = [
        {
            title: geti18nText('employee.evaluation.edit.duration'),
            dataIndex: 'dateFrom',
            width: '95%',
            render: (text: any, record: any) => {
                if (record.dateFrom && record.dateTo) {
                    return (
                        <>
                            {moment(record.dateFrom).format('DD.MM.YYYY.')} -{' '}
                            {moment(record.dateTo).format('DD.MM.YYYY.')}
                        </>
                    );
                }
            },
        },
    ];

    return (
        <NyDataTable
            nyId="human-evaluation-period-table"
            url={CONSTANTS_REQ.EMPLOYEE_EVALUATION_PERIOD.LIST}
            buttonsClassName="buttons-sticky"
            showRecordModal
            columns={columns}
            modalComponent={EmployeeEvaluationPeriodEdit}
            scroll={scroll}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            shortcuts
            nyTestId="employee-evaluation-period-table"
            showCleanTableFilters={false}
        />
    );
};

export default EmployeeEvaluationPeriodIndex;
