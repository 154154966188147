import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { okNotification } from '../../utils/Utils';
import { SortableItem } from './SortableItem';
import { TitleItem } from './TitleItem';

export const SortableIndex = ({
    closeModal,
    code,
    description,
    id,
    name,
    parentCategoryId,
    parentCategoryName,
    picture,
    sortListModalVisible,
    taskType,
    type,
    typeModal,
    url,
    taskTypeSubtype,
    wageType,
    modalTitle,
    order = 'position',
    saveURL,
}: any) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [items, setItems] = useState<any>([]);

    useEffect(() => {
        getItems();
    }, []);

    const getItems = () => {
        let searchFilter = null;
        if (type == 'task-type-category') {
            if (parentCategoryId) {
                searchFilter = encodeURI(
                    JSON.stringify([
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'childParentId', condition: 'equals', value: parentCategoryId },
                    ])
                );
            } else {
                searchFilter = encodeURI(
                    JSON.stringify([
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'parent.id', condition: 'is_null', value: '' },
                    ])
                );
            }
        } else if (type == 'task-template') {
            if (parentCategoryId) {
                searchFilter = encodeURI(
                    JSON.stringify([
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'type', condition: 'equals', value: taskType },
                        { field: 'childTaskTypeCategoryId', condition: 'equals', value: parentCategoryId },
                    ])
                );
            } else {
                searchFilter = encodeURI(
                    JSON.stringify([
                        { field: 'active', condition: 'equals_bool', value: 1 },
                        { field: 'type', condition: 'equals', value: taskType },
                        { field: 'taskTypeCategory.id', condition: 'is_null', value: '' },
                    ])
                );
            }
        } else {
            searchFilter = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        }

        NyRequestResolver.requestGet(url, {
            order: order,
            orderType: 'asc',
            search: searchFilter,
            max: 150,
        }).then((response) => {
            if (response && response.status === RESPONSE.OK) {
                if (response.data && typeof response.data !== 'string') {
                    const responseData: { [index: string]: any } | undefined = response.data;
                    setItems(
                        responseData.content
                            .map((element: any, index: any) => {
                                return { ...element, position: index + 1 };
                            })
                            .sort((a: any, b: any) => {
                                return a.position - b.position;
                            })
                    );
                }
            } else {
                setItems([]);
            }
        });
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        const itemsData = [...items];
        const oldIndex = itemsData.findIndex((item: any) => item.id === active.id);
        const newIndex = itemsData.findIndex((item: any) => item.id === over.id);

        let sorted = arrayMove(itemsData, oldIndex, newIndex);
        sorted = sorted.map((element: any, index: any) => {
            return { ...element, position: index + 1 };
        });
        setItems(sorted);
    };

    const save = () => {
        const url =
            type == 'task-type-category' ? CONSTANTS_REQ.TASK_TYPE_CATEGORY.SAVE : CONSTANTS_REQ.TASK_TEMPLATE.SAVE;
        const urlType = saveURL ? saveURL : url.replace('{type}', taskType);

        const itemsList = items.map((item: any) => {
            if (parentCategoryId) {
                if (type == 'task-type-category') {
                    return { id: item.id, position: item.position, type: taskType, parent: { id: parentCategoryId } };
                } else if (type == 'task-template') {
                    return {
                        id: item.id,
                        position: item.position,
                        type: taskType,
                        taskTypeCategory: { id: parentCategoryId },
                    };
                }
            } else if (type === 'travel-warrant-type') {
                return {
                    id: item.id,
                    sort: item.sort,
                };
            } else {
                return { id: item.id, position: item.position, type: taskType };
            }
        });

        let parms: any =
            type == 'task-type-category'
                ? { taskTypeCategoryList: itemsList, type: taskType }
                : type === 'travel-warrant-type'
                ? { travelWarrantTypeList: itemsList }
                : { taskTemplateList: itemsList, type: taskType };

        if (parentCategoryId) {
            if (type == 'task-type-category') {
                parms.parentId = parentCategoryId;
            } else if (type == 'task-template') {
                parms.taskTypeCategoryId = parentCategoryId;
            }
        }

        NyRequestResolver.requestPut(urlType, undefined, parms).then((result: any) => {
            if (type === 'travel-warrant-type') {
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    closeModal();
                }
            } else if (result.status === RESPONSE.CREATED) {
                okNotification();
                closeModal();
            }
        });
    };

    return (
        <>
            {sortListModalVisible && (
                <Modal
                    width={900}
                    title={
                        modalTitle
                            ? modalTitle
                            : parentCategoryName
                            ? geti18nText('task.typeCategory.table.column.sort.in.category') + ' ' + parentCategoryName
                            : geti18nText('task.typeCategory.table.column.sort.in.category')
                    }
                    maskClosable={false}
                    open={sortListModalVisible}
                    onOk={save}
                    onCancel={closeModal}
                    cancelText={geti18nText('app.default.button.close')}
                    okText={geti18nText('app.default.button.save')}
                >
                    <TitleItem
                        className={'title-item'}
                        id={id}
                        picture={picture}
                        code={code}
                        name={name}
                        description={description}
                        typeModal={typeModal}
                        taskTypeSubtype={taskTypeSubtype}
                        wageType={wageType}
                    />

                    <Row gutter={24} style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        <Col span={24}>
                            {items && items.length > 0 ? (
                                <DndContext
                                    onDragEnd={handleDragEnd}
                                    collisionDetection={closestCenter}
                                    sensors={sensors}
                                >
                                    <SortableContext
                                        items={items.map((item: any) => item.id)}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {items &&
                                            items.map((item: any, index: any) => {
                                                return (
                                                    <SortableItem
                                                        assignedPerson={
                                                            item?.assignedUser?.person
                                                                ? item?.assignedUser?.person?.firstName +
                                                                  ' ' +
                                                                  item?.assignedUser?.person?.lastName
                                                                : null
                                                        }
                                                        assignedTeam={item?.assignedTeam?.name}
                                                        code={item?.code}
                                                        description={item?.description}
                                                        fileId={item?.fileId}
                                                        id={item?.id}
                                                        index={index}
                                                        item={item}
                                                        key={item?.id}
                                                        name={item?.name}
                                                        parent={item?.parent?.name}
                                                        position={item?.position}
                                                        taskTypeCategory={item?.taskTypeCategory?.name}
                                                        taskTypeSubtype={item?.taskTypeSubtype?.name}
                                                        type={type}
                                                        wageType={item?.wageType}
                                                    />
                                                );
                                            })}
                                    </SortableContext>
                                </DndContext>
                            ) : (
                                <NySpinner />
                            )}
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};
